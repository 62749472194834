/**
 * Replace complex characters with their plain latin analogs.
 */
export function normalizeString(str: string): string {
  const normalized = str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/ł/g, "l")
    .replace(/ß/g, "ss")
    .replace(/æ/g, "ae");
  return normalized;
}
