import { useTranslation } from "react-i18next";
import BEM from "utils/BEM";
import { Formik, Form } from "formik";
import { Range as RangeType } from "react-input-range";
import Button from "components/UI/Button";
import GoogleMapPreview from "components/UI/GoogleMap/Preview";
import Range from "components/UI/Range";
import LocationSearchInput from "components/LocationSearchInput";
import { Location } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import "./GPS.scss";
import { editLocation } from "utils/apiHelpers";
import { NotificationType } from "types/common";

const b = BEM.b("validation-gps-form");

interface ValidationGPSFormProps {
  currentLocation: Location;
  onCancel: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const ValidationGPSForm = ({ currentLocation, onCancel, setNotification, onYes }: ValidationGPSFormProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);

  const updateLocation = async (data) => {
    try {
      await editLocation(currentLocation.id, {
        body: { location: data },
      });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  return (
    <Formik
      key="new"
      initialValues={{
        address_attributes: {
          formatted: currentLocation?.formatted_address || "",
          lat: currentLocation?.lat,
          lng: currentLocation?.lng,
          country: currentLocation?.country,
          error: null,
        },
        radius: currentLocation?.radius || 50,
      }}
      validate={() => ({})}
      onSubmit={(values) => {
        void updateLocation({ gps_verification_enabled: true, ...values });
      }}
      render={({ values, setFieldValue }) => (
        <Form className={b()}>
          <h2 className={b("heading")}>{t("GPS Setup")}</h2>
          <label className={b("form-field-wrapper")}>
            <span className={b("form-label")}>{t("Address")}</span>

            <LocationSearchInput
              address={values.address_attributes}
              isValid={!values.address_attributes.error}
              onChange={(val: typeof values.address_attributes) => setFieldValue("address_attributes", val)}
            />
          </label>

          <div>
            <GoogleMapPreview
              lat={values.address_attributes.lat}
              lng={values.address_attributes.lng}
              radius={values.radius}
            />
            <div className={b("radius-wrapper")}>
              <div className={b("radius-label")}>
                <label>{t("Select a range")}</label>
                <span>
                  {values.radius} {t("m")}
                </span>
              </div>
              <Range
                minValue={10}
                maxValue={2000}
                step={1}
                value={values.radius}
                onChange={(val: number | RangeType) => setFieldValue("radius", val)}
              />
              <div className={b("radius-hint")}>
                <span>10 {t("meters")}</span>
                <span>2000 {t("meters")}</span>
              </div>
            </div>
          </div>

          <div className={b("action-buttons")}>
            <Button label={t("Cancel")} modifier="bordered" className={b("action-button")} onClick={onCancel} />
            <Button type="submit" className={b("action-button")} label={t("Create")} />
          </div>
        </Form>
      )}
    />
  );
};

export default ValidationGPSForm;
