import { EmployeeListUserProfile } from "./userProfile";
import { Client } from "./client";
import { LocationInReports } from "./location";
import { ProjectTaskAssignee } from "../../components/Projects/projectsApiTypes";

export enum ServiceStatus {
  archived = "archived",
  active = "active",
}

export type Service = {
  name: string;
  uuid: string;
  status: ServiceStatus;
  createdAt: string;
  createdBy: string;
  startDate?: string;
  updatedAt: string;
  updatedBy?: string;
  companyUuid: string;
  costPerHour: number;
  statusChangedAt?: string;
};

export enum CustomFieldType {
  numeric = "numeric",
  input = "input",
}

export type CustomFieldSetting = {
  uuid: string;
  name: string;
  fieldType: CustomFieldType;
  active: boolean;
  required: boolean;
  deletedAt?: string;
};

export enum ProjectStatus {
  active = "active",
  archived = "archived",
  deleted = "deleted",
}

export type Project = {
  uuid: string;
  companyUuid: string;
  id: string;
  name: string;
  clientUuid: string;
  ownerUuid: string;
  locationUuids: string[];
  restrictBasedOnSchedule: boolean;
  status: ProjectStatus;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  estimatedHours: number;
  estimatedCost: number;
  workedMinutes: number;
  spentServiceCost: number;
  spentEmployeeCost: number;
  dependenciesUpdatedAt: string;
  customFieldsSettings: CustomFieldSetting[];
  deletedAt?: string;
  integrationApplicationUuid: string;
  externalId: string;
  isPublic: boolean;
};

export type Task = {
  uuid: string;
  name: string;
  projectUuid: string;
  services: Service[];
  userProfilesTasks: { userProfileUuid: string }[];
};

export type ActivitiesReportByLocation = {
  uuid: string;
  companyUuid: string;
  userProfileUuid: string;
  date: string;
  startTime: number;
  endTime: number;
  duration: number;
  locationUuid: string;
  taskUuid: string;
  projectUuid: string;
  status: string;
  service: Service;
  createdAt: string;
  createdBy: string;
  statusChangedAt?: string;
  updatedBy?: string;
  updatedAt: string;
  project: Project;
  task: Task;
  location: LocationInReports;
  userProfile: EmployeeListUserProfile;
  client: Client;
  employeeCost: string;
  customFieldsMap: {
    [key: string]: {
      name: string;
      value: string | number;
    };
  };
};

export type ActivitiesReportAllProjects = {
  project: Project;
  client: Client;
  userProfilesCount: number;
  totalMinutes: number;
  totalCost: number;
  totalEmployeeCost: number;
};

export type ActivitiesReportAllProjectsMetadata = {
  totalMinutes: number;
  totalCost: number;
  totalEmployeeCost: string;
};

export type ActivitiesReportTasks = {
  totalCost: string;
  totalEmployeeCost: string;
  totalMinutes: number;
  userProfilesCount: number;
  task: {
    uuid: string;
    name: string;
  };
  project: {
    uuid: string;
    id: string;
    name: string;
    clientUuid: string;
  };
  client: {
    uuid: string;
    name: string;
  };
};

export type ActivitiesReportTags = {
  totalCost: string;
  totalEmployeeCost: string;
  totalMinutes: number;
  userProfilesCount: number;
  task: {
    uuid: string;
    name: string;
  };
  project: {
    uuid: string;
    id: string;
    name: string;
    clientUuid: string;
  };
  client: {
    uuid: string;
    name: string;
  };
  tag: {
    uuid: string;
    name: string;
  };
};

export type ActivitiesReportTaskFeatureMetadata = {
  totalCost: string;
  totalEmployeeCost: string;
  totalMinutes: number;
};

export type ActivitiesReportAllClients = {
  client: Client;
  userProfilesCount: number;
  totalMinutes: number;
  totalCost: number;
  totalEmployeeCost: string;
};

export type ActivitiesReportAllClientsMetadata = {
  totalMinutes: number;
  totalCost: number;
  totalEmployeeCost: string;
};

export type ActivitiesReportSingleProject = {
  userProfile: {
    fullName: string;
    cpf: string;
    matricula: string;
  };
  totalTasks: number;
  totalMinutes: number;
  totalCost: string;
  totalEmployeeCost: string;
};

export type ActivitiesReportSingleEmployee = {
  date: string;
  startTime: number;
  endTime: number;
  activity: string;
  duration: number;
  schedule: {
    name: string;
    uuid: string;
  } | null;
  project: {
    name: string;
    uuid: string;
  } | null;
  service: {
    name: string;
    uuid: string;
  } | null;
  location: {
    name: string;
    uuid: string;
  } | null;
  client: {
    name: string;
    uuid: string;
  } | null;
  task: {
    name: string;
    uuid: string;
  } | null;
  customFieldsMap: {
    [key: string]: {
      name: string;
      value: string | number;
    };
  };
  employeeCost: string;
};

export interface CustomFieldError {
  name?: string;
}

export interface CustomField {
  uuid?: string;
  name: string;
  value?: string | null;
  fieldType: CustomFieldType;
  active: boolean;
  required: boolean;
  deletedAt?: string;
}

export type ProjectSettings = {
  uuid: string;
  companyUuid: string;
  id: string;
  name: string;
  clientUuid: string | null;
  ownerUuid: string;
  locationUuids: string[];
  restrictBasedOnSchedule: boolean;
  customFieldsSettings: CustomFieldSetting[]; // TODO maybe
  status: ProjectStatus;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string | null;
  dependenciesUpdatedAt: string;
  deletedAt: string | null;
  externalId: string | null;
  integrationApplicationUuid: string | null;
  estimatedHours: number;
  estimatedCost: number;
};

export type ServiceSettings = {
  uuid: string;
  companyUuid: string;
  name: string;
  costPerHour: number;
  startDate: string;
  status: ServiceStatus;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  statusChangedAt: string | null;
  externalId: string | null;
  isDefault: boolean;
};

export type TaskWithAssignees = Task & {
  assignees: ProjectTaskAssignee[];
};
