import { Component, Fragment } from "react";
import {
  getDataTable,
  updateTableData,
  enableDsrDay,
  clearDayRegular,
  copyToNextDayRegular,
  removeBreak,
  addAdditionalEntryExit,
  removeAdditionalEntryExit,
  removeDay,
  addDay,
  toggleDay,
  getScheduleObjRegular,
  getRulesFromConfines,
  getConfinesFromRules,
  validateScheduleRegular,
  validateEvents,
  getScheduleObjFlexible,
  getExceptionDaysFromTableData,
  populateScheduleWithBreakData,
} from "utils/scheduleHelpers";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import FieldsGroup from "components/UI/FieldsGroup";
import ModalDialog from "components/UI/ModalDialog";
import Sentry from "utils/sentryUtils";
import CheckboxControl from "components/UI/NewCheckbox";
import styled from "styled-components";
import Select from "components/UI/Select";
import RichTooltip from "components/UI/RichTooltip";
import { getTitle, minsToHrsMins, hrsMinsToMins } from "utils/common";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import schedulesMoc from "utils/schedule";
import { createNewSchedule, updateSchedule, getCompanyRules } from "utils/apiHelpers";
import * as images from "components/svg-images";
import ScheduleShiftsTable from "components/Schedules/ScheduleShiftsTable";
import ScheduleWeeklyTable from "components/Schedules/ScheduleWeeklyTable";
import ScheduleTemplatesDropdown from "components/Schedules/ScheduleTemplatesDropdown";
import ScheduleFlexibleTable from "components/Schedules/ScheduleFlexibleTable";
import ScheduleLimits from "components/Schedules/ScheduleLimits";
import ConfirmPopupMessage from "components/Schedules/ScheduleConfirmPopupMessage";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControl";
import "components/UI/Page/Page.scss";
import "styles/schedule-create.scss";
import Button from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { getCustomBreaksList } from "utils/api/schedule";
import { BreakStatusOptions } from "utils/api/types";
import TimeControl from "components/controls/TimeControl";
import { CompanyRuleNames } from "types/models/companyRules";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const b = BEM.b("schedule-create");
const p = BEM.b("page");

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;

  div:first-child {
    margin-inline-end: 10px;
  }
`;

const Subtitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin: 32px 0 16px 0;
  display: flex;
  align-items: center;
`;

const DsrInHoursWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;

  .limit {
    width: 80px;
    margin-inline-start: 10px;
    margin-top: 0;
  }

  div:first-child {
    margin-inline-end: 10px;
  }
`;

class ScheduleCreate extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;

    this.unusedBreak = [
      { value: "useCompanyBusinessRules", label: t("Use Company Business Rules") },
      { value: "ignore", label: t("Ignore") },
      { value: "moveToExtraHours", label: t("Move to Extra Hours") },
    ];

    this.defaultTemplate = { value: "custom", label: t("Custom") };

    const schedule = this.props.schedule || { ...schedulesMoc.regularEmpty };
    const scheduleDays = [];

    if (schedule.options.businessRules && schedule.options.businessRules.nonWorkingScheduleDays) {
      scheduleDays.push({
        working: true,
        type: schedule.nonWorkingScheduleDay.scheduleType,
        hoursPerDay:
          schedule.nonWorkingScheduleDay.scheduleType === "flexible"
            ? minsToHrsMins(
                schedule.nonWorkingScheduleDay.events[schedule.nonWorkingScheduleDay.events.length - 1].duration,
              )
            : "",
        events: schedule.nonWorkingScheduleDay.events,
      });
    } else {
      scheduleDays.push({
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            tolerance: null,
            validity: null,
            obligatory: true,
          },
          {
            type: "break_start",
            time: null,
            duration: null,
            tolerance: null,
            validity: null,
            obligatory: false,
          },
          {
            type: "break_end",
            time: null,
            duration: null,
            tolerance: null,
            validity: null,
            obligatory: false,
          },
          {
            type: "exit",
            time: null,
            duration: null,
            tolerance: null,
            validity: null,
            obligatory: true,
          },
        ],
        type: "flexible",
      });
    }

    const hasBusinessRules = schedule?.options?.businessRules;

    this.state = {
      name: this.props.schedule?.name || "",
      errors: null,
      skipHolidays: hasBusinessRules && schedule.options.businessRules.skipHolidays,
      schedule,
      externalId: schedule.externalId || "",
      nonWorkingScheduleDays: hasBusinessRules && schedule.options.businessRules.nonWorkingScheduleDays,
      automaticBreak: hasBusinessRules && schedule.options.businessRules.automaticBreak,
      hideBreaks: hasBusinessRules && schedule.options.businessRules.hideBreaks,
      a48sRule: hasBusinessRules && schedule.options.businessRules.automaticBreakOnNonPlannedAndScheduleExceptionDays,
      nightShiftExtendToFullRange: hasBusinessRules && schedule.options.businessRules.nightShiftExtendToFullRange,
      tableData: [],
      unusedBreak: (hasBusinessRules && schedule.options.businessRules.unusedBreak) || "useCompanyBusinessRules",
      selectedTemplate: this.defaultTemplate,
      customBreaksList: [],
      rules: getRulesFromConfines(schedule.options ? schedule.options.confines : {}),
      showDsrInReports: schedule.options.businessRules?.showDsrInReports,
      countDsrInHours: schedule.options.businessRules?.countDsrInHours,
      dsrDayDuration: schedule.options.businessRules?.dsrDayDuration || 0,
      dsrEnabled: false,
    };

    document.title = getTitle(t("Schedules"));
  }

  async componentDidMount() {
    const { schedule } = this.state;
    const company = await this.context.getCompany();
    const [businessRules, customBreaksResp] = await Promise.all([
      getCompanyRules(),
      getCustomBreaksList({
        perPage: 300,
        page: 1,
        statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
        companyUuid: company.uuid,
        requestedBy: window.global_store.profile.uuid,
      }),
    ]);

    const dsrEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;
    this.setState({ dsrEnabled });

    await this.getState(getDataTable(schedule), customBreaksResp?.content || []);
  }

  async getState(tableData, customBreaks) {
    this.setState((state) => {
      const newTableData = populateScheduleWithBreakData(tableData, customBreaks, state.automaticBreak);
      return { customBreaksList: customBreaks, tableData: newTableData };
    });
  }

  tabChange = (tab) => {
    let { schedule } = this.state;
    if (schedule.scheduleType !== tab) {
      schedule = { ...schedulesMoc[tab] };
      this.setState({
        schedule,
        selectedTemplate: this.defaultTemplate,
        rules: getRulesFromConfines(schedule.options ? schedule.options.confines : {}),
        tableData: getDataTable(schedule),
      });
    }
  };

  validateFlexibleSchedule = (_, tableData) => {
    const { t } = this.props;
    let error = "";

    if (tableData.length === 0) {
      error = t("Please add at least one day");
    } else {
      const week = [...Array(7)].map((e, i) => ({ id: i + 1 }));

      tableData.map((r) => {
        r.daysOfWeek.map((d) => {
          if (d.working && week[d.id - 1].working) {
            error = t("Can't define different hours for the same day, Please update your schedule");
          } else if (d.working) {
            week[d.id - 1].working = true;
          }
          return "";
        });

        if (!r.hoursPerDay.value) {
          error = t("Hours for day can't be empty");
        }

        return "";
      });

      return "";
    }

    return error;
  };

  validateSchedule = (schedule, tableData) => {
    const { t } = this.props;
    const { name } = this.state;
    let errors = {};

    if (!name) {
      errors.name = t("Schedule name can't be empty");
    }

    if (schedule.scheduleType === "flexible") {
      const flexibleTableError = this.validateFlexibleSchedule(schedule, tableData);

      if (flexibleTableError) {
        errors.generalError = flexibleTableError;
      }
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }

    return errors;
  };

  hasNoBreaks = (scheduleObj) =>
    scheduleObj.scheduleDays.filter((d) => d.events && d.events.filter((e) => e.type === "break_end").length).length ===
    0;

  getBackendErrorMsg = (e) => {
    const { t } = this.props;
    let error = e;
    let generalError = t("Something went wrong");

    if (e?.originalRequest?.errors?.[0]?.message) {
      error = e.originalRequest.errors[0].message.replace(/\[.*\] /, "");
      generalError = t(error);

      if (e.originalRequest.errors[0].userProfilesUuids) {
        listUserProfilesWIthFilters(
          window.global_store.company.uuid,
          baseByUuidPayload(window.global_store.profile.uuid, e.originalRequest?.errors[0].userProfilesUuids),
        ).then((r) => {
          const employees = r.content.map((u) => u.fullName);
          if (employees.length) {
            generalError += `: ${employees.join(", ")}`;
          }
        });
      }
    } else if (e?.originalRequest?.errors?.message === "Record with externalId already exists") {
      const errorDetails = e?.originalRequest?.errors?.details;
      const error = t("Schedule with such external id exists");
      const link = `/schedules/${errorDetails.scheduleUuid}`;
      generalError = (
        <>
          {error}&#58;&nbsp;
          <a href={link} target="_blank" rel="noreferrer">
            {errorDetails.name}
          </a>
        </>
      );
    }

    return generalError;
  };

  onSaveScheduleClick = () => {
    const { schedule, tableData } = this.state;
    const errors = this.validateSchedule(schedule, tableData);
    const validationObj =
      schedule && schedule.scheduleType === "flexible"
        ? { valid: true, tableData } // TODO: Add validation for flexible schedule
        : validateScheduleRegular(tableData);

    if (!errors && validationObj.valid) {
      const scheduleObj =
        schedule.scheduleType === "flexible"
          ? getScheduleObjFlexible(tableData, schedule)
          : getScheduleObjRegular(tableData, schedule);
      const error = validateEvents(scheduleObj, this.props.t);

      if (error) {
        this.setState({ errors: { generalError: error }, tableData: validationObj.tableData });
        return;
      }

      if (this.props.schedule) {
        // show confirmation popup
        this.setState({ confirmationVisible: true, errors, tableData: validationObj.tableData });
      } else {
        // create schedule
        this.saveSchedule();
      }
    } else {
      this.setState({ errors, tableData: validationObj.tableData });
    }
  };

  onSaveScheduleConfirmed = (startDate) => {
    this.saveSchedule(startDate);
  };

  saveSchedule = (startDate) => {
    const {
      schedule,
      tableData,
      rules,
      skipHolidays,
      name,
      nonWorkingScheduleDays,
      unusedBreak,
      hideBreaks,
      a48sRule,
      externalId,
      showDsrInReports,
      countDsrInHours,
      dsrDayDuration,
      nightShiftExtendToFullRange,
    } = this.state;
    const { flags } = this.props;

    schedule.name = name;

    const body =
      schedule.scheduleType === "flexible"
        ? getScheduleObjFlexible(tableData, schedule)
        : getScheduleObjRegular(tableData, schedule);

    body.externalId = externalId || null;

    if (nonWorkingScheduleDays && this.props.schedule && this.props.schedule.nonWorkingScheduleDay) {
      body.nonWorkingScheduleDay = this.props.schedule.nonWorkingScheduleDay;
    }

    const confines = getConfinesFromRules(rules);
    body.options.confines = confines;

    if (schedule.scheduleType === "flexible") {
      body.options.confines.toleranceEntryLate = 0;
    }

    body.options.businessRules = {
      unusedBreak,
      skipHolidays,
      nonWorkingScheduleDays,
      hideBreaks,
      showDsrInReports,
      countDsrInHours,
      dsrDayDuration,
      nightShiftExtendToFullRange,
      automaticBreakOnNonPlannedAndScheduleExceptionDays: a48sRule,
    };

    if (flags.moveExtendedNightShiftToSchedule) {
      body.options.businessRules.nightShiftExtendToFullRange = nightShiftExtendToFullRange;
    }

    const currentProfileUuid = window.global_store.profile.uuid;
    body.createdBy = currentProfileUuid;

    this.setState({ isLoading: true });

    if (this.props.schedule) {
      updateSchedule({
        body: { content: body },
        companyUuid: window.global_store.company.uuid,
        startDate,
        scheduleUUID: this.props.schedule.uuid,
      })
        .then(() => {
          this.props.history.push("/schedules");
        })
        .catch((e) => {
          this.setState({ isLoading: false });

          const generalError = this.getBackendErrorMsg(e);

          Sentry.sendError(e);
          this.setState({ errors: { generalError }, confirmationVisible: false });
        });
    } else {
      createNewSchedule({ body: { content: body }, companyUuid: window.global_store.company.uuid })
        .then((r) => {
          this.props.history.push(`/schedules/${r.content.uuid}?addEmployees=true`);
        })
        .catch((e) => {
          this.setState({ isLoading: false });

          const generalError = this.getBackendErrorMsg(e);

          Sentry.sendError(e);
          this.setState({ errors: { generalError } });
        });
    }
  };

  parseTemplate = (template) => {
    if (!template.obj) {
      this.setState({
        selectedTemplate: template,
      });
    } else {
      const { automaticBreak, customBreaksList } = this.state;
      const newTableData = getDataTable(template.obj);
      const tableDataWithDefaultBreak = populateScheduleWithBreakData(newTableData, customBreaksList, automaticBreak);

      const state = {
        tableData: tableDataWithDefaultBreak,
        selectedTemplate: template,
        rules: getRulesFromConfines(),
      };
      this.setState(state);
    }
  };

  reset = () => {
    const schedule = { ...schedulesMoc[this.state.schedule.scheduleType] };

    this.setState({
      tableData: getDataTable(schedule),
      selectedTemplate: this.defaultTemplate,
      schedule,
      rules: getRulesFromConfines(schedule.options ? schedule.options.confines : {}),
    });
  };

  onScheduleTableChange = (dayId, dayData) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: updateTableData(dayId, dayData, tableData) });
  };

  onEnableDsrDay = (dayId, dayData) => {
    const { tableData } = this.state;
    this.setState({ tableData: enableDsrDay(dayId, tableData) });
  };

  onCopyToNextDay = (currentDayId) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: copyToNextDayRegular(currentDayId, tableData) });
  };

  onClearDay = (dayId) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: clearDayRegular(dayId, tableData) });
  };

  onRemoveBreak = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: removeBreak(dayId, tableData, breakId) });
  };

  onAddAdditionalEntryExit = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({
      selectedTemplate: this.defaultTemplate,
      tableData: addAdditionalEntryExit(dayId, tableData, breakId),
    });
  };

  onRemoveAdditionalEntryExit = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({
      selectedTemplate: this.defaultTemplate,
      tableData: removeAdditionalEntryExit(dayId, tableData, breakId),
    });
  };

  onRemoveDay = (dayId) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: removeDay(dayId, tableData) });
  };

  onAddDay = (isWorkDay) => {
    const { tableData } = this.state;
    this.setState({
      selectedTemplate: this.defaultTemplate,
      tableData: addDay(isWorkDay, tableData),
    });
  };

  onToggleDay = (rowId, dayId) => {
    const { tableData } = this.state;
    this.setState({ selectedTemplate: this.defaultTemplate, tableData: toggleDay(rowId, dayId, tableData) });
  };

  getTotal = (_, tableData) => {
    let total = 0;
    tableData.map((d) => {
      const rowTotal = d.total;

      if (rowTotal) {
        total += rowTotal;
      }

      return d;
    });

    return total;
  };

  getScheduleTable = () => {
    let content = null;
    const { schedule, tableData, dsrEnabled } = this.state;

    switch (schedule.scheduleType) {
      case "regular":
        content = (
          <ScheduleWeeklyTable
            tableData={tableData}
            enableDsrDay={this.onEnableDsrDay}
            onChange={this.onScheduleTableChange}
            onClearDay={this.onClearDay}
            onRemoveBreak={this.onRemoveBreak}
            onAddAdditionalEntryExit={this.onAddAdditionalEntryExit}
            onRemoveAdditionalEntryExit={this.onRemoveAdditionalEntryExit}
            onCopyToNextDay={this.onCopyToNextDay}
          />
        );
        break;
      case "flexible":
        content = (
          <ScheduleFlexibleTable
            tableData={tableData}
            enableDsrDay={this.onEnableDsrDay}
            onChange={this.onScheduleTableChange}
            onRemoveBreak={this.onRemoveBreak}
            onRemoveDay={this.onRemoveDay}
            onToggleDay={this.onToggleDay}
            onAddAdditionalEntryExit={this.onAddAdditionalEntryExit}
            onRemoveAdditionalEntryExit={this.onRemoveAdditionalEntryExit}
            onAddDay={this.onAddDay}
          />
        );
        break;
      case "shifts":
        content = (
          <ScheduleShiftsTable
            tableData={tableData}
            onChange={this.onScheduleTableChange}
            onRemoveBreak={this.onRemoveBreak}
            onRemoveDay={this.onRemoveDay}
            onAddDay={this.onAddDay}
          />
        );
        break;

      default:
        break;
    }

    return content;
  };

  getWrapper = () => {
    if (!this.props.schedule) {
      return SettingsLayout;
    }

    // <ScheduleDetails /> already has SettingsLayout wrapper
    return Fragment;
  };

  render() {
    const {
      schedule,
      errors,
      selectedTemplate,
      tableData,
      rules,
      skipHolidays,
      name,
      confirmationVisible,
      nonWorkingScheduleDays,
      unusedBreak,
      hideBreaks,
      a48sRule,
      isLoading,
      externalId,
      countDsrInHours,
      showDsrInReports,
      dsrDayDuration,
      dsrEnabled,
      nightShiftExtendToFullRange,
    } = this.state;
    const { t, history, flags } = this.props;
    const scheduleType = schedule.scheduleType || "weekly";
    const totalHours = this.getTotal(scheduleType, tableData);

    const automaticBreak = tableData.some((item) => item.breaks.some((b) => b.automatic));

    const Wrapper = this.getWrapper();

    const wrapperProps = !this.props.schedule
      ? {
          title: t("Create a new Schedule"),
          backButtonTitle: t("Schedules"),
          backButtonOnclick: () => history.push("/schedules/"),
        }
      : {};

    return (
      <Wrapper {...wrapperProps}>
        <div className={p(["schedules"])}>
          {errors && errors.generalError && (
            <div style={{ color: "#ED413D", display: "inline-block" }}>{errors.generalError}</div>
          )}

          <div style={{ marginTop: "20px" }}>
            <div className={b("label")}>{t("Schedule Name")}</div>
            <div>
              <input
                type="text"
                size="60"
                maxLength="60"
                placeholder={t("Example Name")}
                className={b("name-field", { error: errors ? errors.name : "" })}
                onChange={(ev) => {
                  if (errors) errors.name = "";
                  this.setState({ name: ev.target.value, errors });
                }}
                value={name}
              />
            </div>

            {errors && errors.name && <div className={b("error")}>{t("Please enter your Schedule name")}</div>}

            <CheckboxControl
              checked={skipHolidays}
              label={t("Skip Holidays")}
              onChange={(checked) => this.setState({ skipHolidays: checked })}
            />

            <div>
              <div className={b("label", ["type"])}>{t("Type")}</div>
              {this.props.schedule ? (
                <div>{t(schedule.scheduleType)}</div>
              ) : (
                <div className={b("types")}>
                  <div
                    className={b("type", { active: schedule.scheduleType === "regular" })}
                    onClick={this.tabChange.bind(this, "regular")}
                  >
                    {t("Weekly")}
                  </div>
                  <div
                    className={b("type", { active: schedule.scheduleType === "flexible" })}
                    onClick={this.tabChange.bind(this, "flexible")}
                  >
                    {t("Flexible")}
                  </div>
                  <div
                    className={b("type", { active: schedule.scheduleType === "shifts" })}
                    onClick={this.tabChange.bind(this, "shifts")}
                  >
                    {t("Shift")}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={b("set-times", { "no-templates": schedule.scheduleType === "flexible" })}>
            {t("Set times")}
          </div>

          <div className={b("templates-row")} key={`schedule-template-${scheduleType}`}>
            <ScheduleTemplatesDropdown
              onChange={this.parseTemplate}
              defaultItem={this.defaultTemplate}
              newSchedule={!this.props.schedule}
              scheduleType={scheduleType}
              value={selectedTemplate}
              label={t("Templates")}
            />

            <div className={b("reset-total")}>
              <div className={b("button-reset")} onClick={this.reset}>
                {images.resetIcon}
                {t("Reset")}
              </div>
              <div className={b("total")}>
                {t("Weekly Total")}:
                <span className={b("total-value")}>
                  {totalHours ? minsToHrsMins(totalHours) : "0"} {t("hs")}
                </span>
              </div>
            </div>
          </div>

          {this.getScheduleTable(schedule.scheduleType)}
          <div className={b("rules-title")}>{t("Rules")}</div>

          <ScheduleLimits
            disableToleranceEntryLate={schedule.scheduleType === "flexible"}
            rules={rules}
            onChange={(val) => this.setState({ rules: val, selectedTemplate: this.defaultTemplate })}
          />

          <CheckboxControl
            className="user-checkbox"
            checked={nonWorkingScheduleDays}
            label={t("Allow punches in days without a schedule")}
            onChange={(checked) => this.setState({ nonWorkingScheduleDays: checked })}
          />

          <Subtitle>
            {t("Advanced Breaks Management")} &nbsp;&nbsp;
            <RichTooltip text={t("breaks-management-info")} />
          </Subtitle>

          <FieldsGroup label={t("UNFINISHED BREAK")}>
            <div style={{ width: "230px", marginTop: "12px" }}>
              <Select
                modifiers={{ field: true }}
                value={unusedBreak}
                onChange={(val) => this.setState({ unusedBreak: val })}
                options={this.unusedBreak}
              />
            </div>
          </FieldsGroup>

          {automaticBreak && (
            <FieldsGroup label={t("AUTOMATIC BREAK")}>
              <CheckboxWrapper>
                <CheckboxControl
                  checked={hideBreaks}
                  label={t("Block employees from punching breaks")}
                  onChange={(checked) => this.setState({ hideBreaks: checked })}
                />
              </CheckboxWrapper>

              <CheckboxWrapper>
                <CheckboxControl
                  checked={a48sRule}
                  label={t("Automatic Breaks on Non-Planned and Schedule Exception days")}
                  onChange={(checked) => this.setState({ a48sRule: checked })}
                />
              </CheckboxWrapper>
            </FieldsGroup>
          )}

          {flags.moveExtendedNightShiftToSchedule && (
            <FieldsGroup label={t("Night shift")}>
              <CheckboxWrapper>
                <CheckboxControl
                  checked={nightShiftExtendToFullRange}
                  label={t("Enable extended nightshift")}
                  onChange={(checked) => this.setState({ nightShiftExtendToFullRange: checked })}
                />
              </CheckboxWrapper>
            </FieldsGroup>
          )}

          {dsrEnabled && (
            <FieldsGroup label="DSR">
              <CheckboxWrapper>
                <CheckboxControl
                  checked={showDsrInReports}
                  label={t("Display DSR on the employee report")}
                  onChange={(checked) => this.setState({ showDsrInReports: checked })}
                />
              </CheckboxWrapper>

              <DsrInHoursWrapper>
                <CheckboxControl
                  checked={countDsrInHours}
                  label={t("Count DSR in hours")}
                  onChange={(checked) =>
                    this.setState({
                      countDsrInHours: checked,
                      dsrDayDuration: 0,
                    })
                  }
                />
                <RichTooltip text={t("dsr-in-hours-info")} />
                {countDsrInHours && (
                  <TimeControl
                    className="limit"
                    disabled={!countDsrInHours}
                    value={minsToHrsMins(dsrDayDuration || 0)}
                    onTimeChange={(val) => this.setState({ dsrDayDuration: hrsMinsToMins(val.value) })}
                    error={errors?.dsrDayDuration}
                    placeholder="00:00"
                  />
                )}
              </DsrInHoursWrapper>
            </FieldsGroup>
          )}

          <FieldWrapper fieldName={t("External ID")} wrapperWidth="150px" width="100%">
            <TextInputControl value={externalId} onChange={(value) => this.setState({ externalId: value })} />
          </FieldWrapper>

          <div className={b("save")}>
            <Button
              state="primary"
              style={{ width: "136px" }}
              loading={isLoading}
              onClick={this.onSaveScheduleClick}
              value={t("Save Schedule")}
            />
          </div>

          <ModalDialog isOpen={confirmationVisible} onClose={() => this.setState({ confirmationVisible: false })}>
            <ConfirmPopupMessage
              onClose={() => this.setState({ confirmationVisible: false })}
              onYes={this.onSaveScheduleConfirmed}
            />
          </ModalDialog>
        </div>
      </Wrapper>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation(TranslationNamespaces.schedules)(ScheduleCreate)));
