import {
  FilteredEmployeePayload,
  FilteredEmployeeProfile,
  FilteredEmployeesExternalFields,
} from "types/models/userProfile";
import { listUserProfilesWIthFilters } from "utils/api/company";
import sentryUtils from "utils/sentryUtils";
import { PermissionSectionName } from "types/models/permissions";

const LIMIT = 300;

const DEFAULT_LIST_EMPLOYEES_REQUEST_PARAMS: FilteredEmployeePayload = {
  requestedBy: "",
  skip: 0,
  limit: LIMIT,
  fields: [
    "id",
    "uuid",
    "fullName",
    "lastLockDate",
    "subsidiary.id",
    "subsidiary.uuid",
    "subsidiary.name",
    "department.id",
    "department.uuid",
    "department.name",
    "team.uuid",
    "team.name",
    "position.id",
    "position.title",
    "avatarId",
  ],
  externalFields: [
    FilteredEmployeesExternalFields.BusinessRulesGroups,
    FilteredEmployeesExternalFields.PayrollGroups,
    FilteredEmployeesExternalFields.Schedules,
  ],
  filterModel: {
    employee_status: { filterType: "set", values: ["active"] },
  },
};

export const getEmployees = async (
  companyUuid: string,
  permissionSection?: PermissionSectionName,
): Promise<FilteredEmployeeProfile[]> => {
  let result: FilteredEmployeeProfile[] = [];

  const requestedBy = window.global_store.profile.uuid;

  try {
    const {
      content: data,
      metadata: { total },
    } = await listUserProfilesWIthFilters(companyUuid, {
      ...DEFAULT_LIST_EMPLOYEES_REQUEST_PARAMS,
      permissionName: permissionSection,
      requestedBy,
      skip: 0,
      limit: LIMIT,
    });

    const numberOfRequests = total ? Math.ceil(total / LIMIT) - 1 : 0;

    const restData = await Promise.all(
      Array(numberOfRequests)
        .fill(1)
        .map(async (_, index) => {
          const requestNumber = index + 1;
          const skip = requestNumber * LIMIT;

          const { content } = await listUserProfilesWIthFilters(companyUuid, {
            ...DEFAULT_LIST_EMPLOYEES_REQUEST_PARAMS,
            requestedBy,
            skip,
            limit: LIMIT,
          });

          return content;
        }),
    );

    result = [...data, ...restData.flat()];
  } catch (e) {
    sentryUtils.sendError(e);
  }

  return result;
};
