import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import { SuperpunchShiftEvent } from "types/models/shift";
import { useBreaks } from "components/Schedules/Breaks/hooks/useBreaks";
import Tooltip from "components/UI/Tooltip";
import { SuperpunchDate, SuperpunchPunchCellData } from "types/models/superpunch";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_BREAK_KEY, getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import { BreakPair, getBreakPairs } from "components/Schedules/helpers";
import Select from "components/UI/Select";
import SelectComponent from "components/UI/SelectComponent";
import { PunchKey, PunchType } from "types/models/punches";
import { useState } from "react";
import { SelectOption } from "types/ui";
import Cell from "./Cell";
import DropdownHeader from "./CellDropdown/DropdownHeader";
import CellDropdown from "./CellDropdown";
import NoPunches from "./CellDropdown/NoPunches";
import DropdownFooter from "./CellDropdown/DropdownFooter";

const BreakRows = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;
const BreakRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid var(--colors-surface-100);
  padding: 8px 0;
  color: var(--colors-surface-900-p, #434e6c);
  font-size: 14px;
  font-weight: var(--typography-font-weight-normal);
  line-height: 130%;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;
const BreakNameCellWrapper = styled.div`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
`;
const BreakEventCell = styled.div`
  width: 78px;
  height: 44px;
  flex-shrink: 0;
`;
const SelectWrapper = styled.div`
  width: 100%;
  text-align: start;
`;

const getShiftEventSequenceNumbers = (array: SuperpunchShiftEvent[]): Record<string, number> => {
  const order: Record<string, number> = {}; // This will store the order numbers of each break type
  const breakTypeCounter: Record<string, number> = {}; // This will keep track of the counts for each break type

  array.forEach((item) => {
    const { key, type, breakTypeUuid } = item;
    if (type === PunchType.breakStart) {
      // If breakTypeUuid hasn't been encountered yet, initialize its counter
      if (!breakTypeCounter[breakTypeUuid!]) {
        breakTypeCounter[breakTypeUuid!] = 1;
      } else {
        breakTypeCounter[breakTypeUuid!] += 1;
      }

      // Store the order number in the result object
      order[key] = breakTypeCounter[breakTypeUuid!];
    }
  });

  return order;
};

const BreakNameCell = ({ name }: { name: string }) => {
  const id = uuidv4();

  return (
    <BreakNameCellWrapper data-tip data-for={id}>
      {name}
      <Tooltip id={id} wrapperStyle={{ zIndex: 1 }}>
        {name}
      </Tooltip>
    </BreakNameCellWrapper>
  );
};

type AdditionalBreaksDropdownProps = {
  shiftEvents: SuperpunchShiftEvent[];
  breakEventsWithPunches: Record<string, SuperpunchPunchCellData>;
  rowDate: SuperpunchDate;
  onSelectShiftEvent: ({
    breakName,
    shiftEventKey,
    cellData,
  }: {
    breakName: string;
    shiftEventKey: string;
    cellData: SuperpunchPunchCellData;
  }) => void;
  hiddenBreakKeys: string[];
  onAddBreakRow: (keyId: string) => void;
};

const AdditionalBreaksDropdown = ({
  shiftEvents,
  breakEventsWithPunches,
  rowDate,
  onSelectShiftEvent,
  hiddenBreakKeys,
  onAddBreakRow,
}: AdditionalBreaksDropdownProps) => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.schedules]);
  const [breaksDropdownVisible, setBreaksDropdownVisible] = useState(false);
  const { data: breaks, isLoading } = useBreaks();

  if (isLoading)
    return (
      <CellDropdown header={<DropdownHeader title={t("Additional breaks")} />} content={<div>{t("Loading...")}</div>} />
    );

  const breakNamesMap = getBreaksNamesMap(breaks || []);
  let breakPairs = getBreakPairs(shiftEvents); // TODO: no need for future story with hidden breaks
  const hiddenBreaksSet = new Set<string>();
  const visibleShiftEvents: SuperpunchShiftEvent[] = [];

  const hiddenBreaksMode = true; // TODO: will be implemented in next story
  const shiftEventSequenceNumbers = getShiftEventSequenceNumbers(shiftEvents);

  if (hiddenBreaksMode) {
    shiftEvents.forEach((ev) => {
      if (ev.type === PunchType.breakStart || ev.type === PunchType.breakEnd) {
        const [, , keyId] = ev.key.match(/(break_start|break_end)(\d+)/) || [];

        // split visibble and hidden breaks
        if (ev.breakTypeUuid && keyId && hiddenBreakKeys.includes(keyId)) {
          // avoid duplicates of breakTypeUuid. Add only first key
          if (!Array.from(hiddenBreaksSet).some((el) => el.includes(ev.breakTypeUuid!))) {
            hiddenBreaksSet.add(`${keyId}_${ev.breakTypeUuid}`);
          }
        } else {
          visibleShiftEvents.push(ev);
        }
      }
    });

    breakPairs = getBreakPairs(visibleShiftEvents);
  }

  const optionsForNewBreak: SelectOption[] = Array.from(hiddenBreaksSet).map((key) => {
    const [keyId, breakTypeUuid] = key.split("_");

    return {
      label: breakNamesMap[breakTypeUuid],
      value: keyId,
    };
  });

  return (
    <CellDropdown
      header={<DropdownHeader title={t("Additional breaks")} />}
      content={
        <BreakRows>
          {!breakPairs.length && <NoPunches>{t("No created punches yet")}</NoPunches>}
          {breakPairs.map((breakPair, i) => (
            <BreakRow key={breakPair[0]?.key || breakPair[1]?.key}>
              <BreakNameCell
                name={
                  `${shiftEventSequenceNumbers[breakPair[0]!.key]} - ${
                    breakNamesMap[breakPair[0]?.breakTypeUuid || DEFAULT_BREAK_KEY]
                  }` || breakNamesMap[DEFAULT_BREAK_KEY]
                }
              />
              <>
                {breakPair.map((breakItem) => (
                  <BreakEventCell key={breakItem!.key}>
                    <Cell
                      inactive={!!rowDate.isDayOff}
                      date={rowDate.raw}
                      punch={breakEventsWithPunches[breakItem!.key]}
                      onClick={() => {
                        onSelectShiftEvent({
                          breakName:
                            breakNamesMap[breakItem!.breakTypeUuid || DEFAULT_BREAK_KEY] ||
                            breakNamesMap[DEFAULT_BREAK_KEY],
                          shiftEventKey: breakItem!.key,
                          cellData: breakEventsWithPunches[breakItem!.key],
                        });
                      }}
                      requestFailed={false}
                      disabled={false}
                    />
                  </BreakEventCell>
                ))}
              </>
            </BreakRow>
          ))}
        </BreakRows>
      }
      footer={
        optionsForNewBreak.length ? (
          <>
            {!breaksDropdownVisible && (
              <DropdownFooter label={t("Add break")} onClick={() => setBreaksDropdownVisible(true)} />
            )}
            {breaksDropdownVisible && (
              <SelectWrapper>
                <Select
                  modifiers={{ field: true }}
                  onChange={(val) => {
                    onAddBreakRow(val);
                  }}
                  value=""
                  placeholder={t("Select break")}
                  options={optionsForNewBreak}
                />
              </SelectWrapper>
            )}
          </>
        ) : undefined
      }
    />
  );
};

export default AdditionalBreaksDropdown;
