import FullPage from "components/Layout/FullPage";
import NotificationRow from "components/NotificationRow";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getTitle } from "utils/common";
import { RouteComponentProps } from "react-router-dom";
import { Notification } from "types/common";
import PasswordTab from "./PasswordTab";

const title = "Edit your security settings";
const metaTitle = title;

const PasswordPage = (props: RouteComponentProps) => {
  const [{ notification, notificationType }, setNotification] = useState<Notification>({
    notification: null,
    notificationType: null,
  });
  const { t } = useTranslation([TranslationNamespaces.companySecurity, TranslationNamespaces.sidebar]);
  document.title = getTitle(t(metaTitle));

  return (
    <FullPage title={t(`${TranslationNamespaces.common}|Settings`)}>
      {notification && (
        <NotificationRow
          style={{ marginBottom: "20px", marginTop: 0 }}
          employeesPage
          withCloseButton={false}
          type={notificationType}
          message={notification}
        />
      )}

      <PasswordTab onSave={(n) => setNotification(n)} />
    </FullPage>
  );
};

export default PasswordPage;
