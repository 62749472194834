import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";

import LocationsPage from "components/Locations/LocationsPage";
import LocationPage from "components/Locations/LocationPage";

const RouteCompanyJobSites = ({ match: { path } }: RouteComponentProps): JSX.Element => (
  <Switch>
    <Redirect strict exact from={path} to={`${path}/`} />
    <Redirect strict from={`${path}/:locationId/`} to={`${path}/:locationId`} />

    <Route exact strict path={`${path}/`} render={(props) => <LocationsPage isJobSites {...props} />} />
    <Route path={`${path}/:locationId`} render={(props) => <LocationPage isJobSites {...props} />} />
  </Switch>
);

export default RouteCompanyJobSites;
