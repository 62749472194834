import { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-create.scss";
import { minsToHrsMins } from "utils/common";
import { withTranslation } from "react-i18next";
import ClickOutside from "react-click-outside";
import ClearButton from "components/controls/ClearButton";
import ExtraNightTag from "components/UI/ExtraNightTag";
import CheckboxControl from "components/UI/NewCheckbox";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRuleNames } from "types/models/companyRules";
import TableCommon from "../TableCommon";
import TimeControl from "../controls/TimeControl";
import * as images from "../svg-images";
import { BreaksCell } from "./Breaks/BreaksCell";

const b = BEM.b("schedule-create");

class ScheduleShiftsTable extends Component {
  static defaultProps = {
    onChange: () => {},
    onRemoveBreak: () => {},
    onRemoveDay: () => {},
    onAddDay: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      tableColumns: [],
      isFetching: false,
      addPopupActive: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const businessRules = await getCompanyRules();
    const dsrEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;

    this.setState({
      tableColumns: this.getTableColumns(dsrEnabled),
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(ev) {
    if (this.state.addPopupActive && this.wrapperRef && !this.wrapperRef.contains(ev.target)) {
      this.setState({ addPopupActive: false });
    }
  }

  updateDay = (dayId, dayData) => {
    this.props.onChange(dayId, dayData);
  };

  getTableColumns(dsrEnabled) {
    const { t, onRemoveBreak, onRemoveDay, readOnly } = this.props;

    const columns = [
      {
        Header: t("Days"),
        accessor: "id",
        Cell: (r) => `${t("Day")} ${r.value}`,
        style: {
          textAlign: "left",
          fontWeight: "700",
          fontSize: "14px",
          color: "#525F7F",
          lineHeight: "38px",
          textTransform: "uppercase",
          paddingLeft: "15px",
        },
        width: 80,
      },
      {
        Header: t("Entry"),
        accessor: "entry",
        Cell: (r) => {
          if (!r.original.working) {
            return <span className={b("day-off")}>{t("Day off")}</span>;
          }
          const entry = r.value;
          if (readOnly || (entry.value && !entry.error && r.original.id !== this.state.selected)) {
            return entry.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("from")}
              onTimeChange={(val) => this.updateDay(r.original.id, { entry: val })}
              error={entry.error}
              value={entry.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 100,
      },
      {
        Header: t("Exit"),
        accessor: "exit",
        Cell: (r) => {
          if (!r.original.working) {
            return "";
          }
          const exit = r.value;
          if (readOnly || (exit.value && !exit.error && r.original.id !== this.state.selected)) {
            return exit.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("to")}
              onTimeChange={(val) => this.updateDay(r.original.id, { exit: val })}
              error={exit.error}
              value={exit.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 100,
      },
      {
        Header: t("Extra Night"),
        accessor: "extraNight",
        Cell: (r) => {
          if (!r.original.working) {
            return "";
          }
          return r.value ? <ExtraNightTag value={`${minsToHrsMins(r.value)} hs`} /> : "";
        },
        width: 240,
      },
      dsrEnabled
        ? {
            Header: "DSR",
            accessor: "isDsrDay",
            style: { textAlign: "center", justifyContent: "center", display: "flex" },
            headerStyle: { textAlign: "center" },
            Cell: (r) =>
              !r.original?.exit?.value && (
                <CheckboxControl
                  checked={r.value}
                  onChange={(checked) => this.updateDay(r.original.id, { isDsrDay: checked })}
                />
              ),
            width: 50,
          }
        : null,
      {
        Header: t("Break"),
        accessor: "breaks",
        Cell: (r) => {
          if (!r.original.working) {
            return "";
          }

          return (
            <BreaksCell
              row={r}
              readOnly={readOnly}
              breaks={r.value}
              selected={this.state.selected}
              onRemoveBreak={onRemoveBreak}
              updateDay={this.updateDay}
            />
          );
        },
        minWidth: 400,
      },
      {
        Header: t("Total"),
        accessor: "total",
        minWidth: 240,
        Cell: (r) => {
          if (!r.original.working) {
            return "";
          }
          return r.value ? (
            <div>
              {r.value ? minsToHrsMins(r.value) : ""} {t("hs")}
            </div>
          ) : (
            ""
          );
        },
        headerStyle: {
          textAlign: "center",
        },
        style: {
          textAlign: "center",
          fontWeight: 700,
          fontSize: "14px",
          color: "#525F7F",
        },
      },
    ];
    const colOptions = {
      Header: "",
      sortable: false,
      resizable: false,
      minWidth: 80,
      Cell: (r) => {
        if (r.original.id !== this.state.selected) {
          return (
            <span className={b("clear-schedule")}>
              <ClearButton onClick={() => onRemoveDay(r.original.id)} />
            </span>
          );
        }
        return null;
      },
      style: { textAlign: "center" },
    };
    if (!readOnly) {
      columns.push(colOptions);
    }
    return columns;
  }

  onAddDayClick = () => {
    this.setState({ addPopupActive: true });
  };

  onAddWorkDayClick = (ev) => {
    ev.stopPropagation();
    this.props.onAddDay(true);
    this.setState({ addPopupActive: false });
  };

  onAddDayOffClick = (ev) => {
    ev.stopPropagation();
    this.props.onAddDay(false);
    this.setState({ addPopupActive: false });
  };

  unselect() {
    this.setState({ selected: null });
  }

  render() {
    const { tableColumns } = this.state;
    const { t, tableData, readOnly } = this.props;

    return (
      <div className={b()}>
        <ClickOutside
          onClickOutside={(e) => {
            // don't unselect row if clicked inside the popup
            if (!e.target.closest(".PopupWrapper")) {
              this.unselect();
            }
          }}
        >
          <TableCommon
            noScroll
            rows={tableData}
            columns={tableColumns}
            showPagination={false}
            pages={1}
            className={`table-common ${readOnly ? "table-common_readonly" : ""}`}
            defaultPageSize={1000}
            loading={this.state.isFetching}
            manual
            showPageSizeOptions={false}
            getTrProps={(s, r) => {
              const selected = this.state.selected === r.original.id;
              const style = {};
              if (selected) {
                style.backgroundColor = "#F7F9FC";
              }
              return {
                style,
                onClick: () => {
                  if (!readOnly) {
                    this.setState({ selected: r.original.id });
                  }
                },
              };
            }}
          />
          {!readOnly ? (
            <div className={b("add-day")} onClick={this.onAddDayClick}>
              {images.addIcon} {t("Add a New Day")}
              <div className={b("add-day-popup", { active: this.state.addPopupActive })} ref={this.setWrapperRef}>
                <div className={b("add-day-popup-item")} onClick={this.onAddWorkDayClick}>
                  {t("Work Day")}
                </div>
                <div className={b("add-day-popup-item")} onClick={this.onAddDayOffClick}>
                  {t("Day off")}
                </div>
              </div>
            </div>
          ) : null}
        </ClickOutside>
      </div>
    );
  }
}
export default withTranslation("schedules")(ScheduleShiftsTable);
