import { Component, ContextType, PropsWithChildren } from "react";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getMyDigitalSignatureReport, getCompanyRules } from "utils/apiHelpers";
import { Title } from "components/styled/Page";
import { minsToHrsMins } from "utils/common";
import * as momentTz from "moment-timezone";
import styled from "styled-components";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import { getDateColumn, getDateCellData, iDateCellData } from "utils/reportsHelpers";
import GlobalContext from "context/global-context";
import Snackbar from "components/styled/Snackbar";
import DownloadSnacks from "components/styled/DownloadSnacks";
import moment from "moment";
import NotificationRow from "components/NotificationRow";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { EmployeeInfo } from "types/models/userProfile";
import { ReportSummaryDay } from "types/models/reports/summary";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PhasesType, getPhasesColumnsForSingleReport } from "components/Reports/helpers";
import { ReportDetailedActivePhase } from "types/models/businessRulesGroup";
import { CompanyRuleNames } from "types/models/companyRules";
import FullPage from "components/Layout/FullPage";
import DownloadDigitalSignaturesReportButton from "./DownloadDigitalSignaturesReportButton";
import TablePage from "../TablePage";
import SignReportRow from "./SignReportRow";
import ReportSignedSuccessfully from "./ReportSignedSuccessfully";
import * as images from "../svg-images";

const tc = BEM.b("table-common");

const Wrapper = styled.div`
  padding: 94px 165px 140px 165px;
  height: 100vh;
  overflow: auto;
  .table-page__details-row {
    border-top: none;
  }
`;
const SignBlock = styled.div`
  background-color: var(--colors-surface-50);
  height: 140px;
  width: 100%;
  position: fixed;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
`;
const ReportHeader = styled.div``;
const ReportTitle = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 18px;
  line-height: 26px;
  color: var(--colors-surface-900-p);
`;
const ReportSubtitle = styled.div`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-400);
  margin-top: 2px;
`;

type ReportTableData = {
  missedDay: boolean;
  nightShiftMinutes: Record<string, number>;
  date: iDateCellData;
  scheduleException: string;
  holiday: string;
  schedule: string;
  observation: string | null;
  onCallMinutes: string;
  status: string;
  crossShiftsInterval: string;
  cumulativeHoursBankMinutes: string;
  missedMinutes: string;
  hoursBankMinutes: string;
  earlyLeaveMinutes: string;
  lateEntryMinutes: string;
  debitMinutes: string;
  businessRulesGroup: string;
  breakMinutes: string;
  nightMinutes: string;
  workedMinutes: string;
  extraHoursMinutes: string;
  plannedMinutes: string;
};

interface MyDigitalSignatureReportProps
  extends RouteComponentProps<{ reportHash: string; digitalSignatureUuid: string }>,
    WithTranslation {}

interface MyDigitalSignatureReportState {
  isMadanRashiEnabled: boolean;
  reportWasSigned: boolean;
  isFetching: boolean;
  shiftEventKeys: string[];
  activeNightShifts: { name: string }[];
  selectedColumns: string;
  totalWorkedMinutes?: number;
  totalBreakMinutes?: number;
  totalPlannedMinutes?: number;
  totalExtraHoursMinutes?: number;
  totalMissedMinutes?: number;
  totalEarlyLeaveMinutes?: number;
  totalLateEntryMinutes?: number;
  totalDebitMinutes: number;
  totalCrossShiftsIntervalDiffMinutes?: number;
  totalNightShiftMinutes?: Record<string, number>;
  totalNightReducedMinutes?: number;
  totalOnCallMinutes?: {
    activated: number;
    onCall: number;
    reducingActivated: number;
  };
  employeeInfo?: EmployeeInfo;
  dates?: ReportSummaryDay[]; // TODO
  startDate?: string;
  endDate?: string;
  notification: string | null;
  notificationType: NotificationType | null;
  activePhases: ReportDetailedActivePhase[] | undefined;
  totalExtraHoursPhases: {
    [uuid: string]: number;
  };
  totalHoursBankPhases: {
    [uuid: string]: number;
  };
  isNightReducedHoursColumnAllowed: boolean;
}

class MyDigitalSignatureReport extends Component<
  PropsWithChildren<MyDigitalSignatureReportProps>,
  MyDigitalSignatureReportState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: MyDigitalSignatureReportProps) {
    super(props);

    this.state = {
      isMadanRashiEnabled: false,
      reportWasSigned: false,
      activeNightShifts: [],
      isFetching: false,
      shiftEventKeys: [],
      selectedColumns: getSelectedColumns(
        "date,punches,worked_hours,break_hours,night_hours,cumulative_hours_bank,holiday,observation",
        "MyDigitalSignatureReport",
      ),
      notification: null,
      notificationType: null,
      activePhases: [],
      totalExtraHoursPhases: {},
      totalHoursBankPhases: {},
      isNightReducedHoursColumnAllowed: false,
    };
  }

  componentDidMount(): void {
    void this.getReportData();
  }

  getReportData = async () => {
    this.setState({ isFetching: true });

    const [company, businessRules] = await Promise.all([this.context.getCompany(), await getCompanyRules()]);

    const isNightReducedHoursColumnAllowed = businessRules?.business_rules?.find(
      (rule) => rule.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    )?.value;

    const isMadanRashiEnabled = businessRules?.business_rules?.find(
      (rule) => rule.name === CompanyRuleNames.MADAN_RASHI_REPORT_AS_DIGITAL_SINGATURE,
    )?.value;
    const { digitalSignatureUuid, reportHash } = this.props.match.params;

    try {
      const response = await getMyDigitalSignatureReport({
        companyUuid: company.uuid,
        userProfileUuid: window.global_store.profile.uuid,
        digitalSignatureUuid,
        reportHash,
        requestedBy: window.global_store.profile.uuid,
      });
      const resp = response.content;

      this.setState({
        ...resp,
        isMadanRashiEnabled,
        isNightReducedHoursColumnAllowed,
        shiftEventKeys: response?.metadata?.shiftEventKeys || [],
        startDate: response?.metadata?.startDate || "",
        endDate: response?.metadata?.endDate || "",
        activeNightShifts: response?.metadata?.activeNightShifts || [],
        selectedColumns: response.metadata.selectedColumns || this.state.selectedColumns,
        activePhases: response.metadata.activePhases,
        isFetching: false,
      });
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log("Error", error);
      let message = "";
      if (error?.message) {
        message = this.props.t(error.message);
      } else {
        message = this.props.t("Failed to generate report");
      }
      this.setState({
        notification: message,
        notificationType: NotificationType.error,
      });
    }
  };

  getPunchesColumns(): iColumn<ReportTableData>[] {
    const { shiftEventKeys } = this.state;
    const { t } = this.props;
    const defaultKeys = ["entry", "break_start", "break_end", "exit"];
    const punchesColumns: iColumn<ReportTableData>[] = [];

    shiftEventKeys.map((key) => {
      const newKey = defaultKeys.indexOf(key) > -1 ? `${key}0` : key;
      punchesColumns.push({
        accessor: newKey,
        rubyAccessor: "punches",
        groupLabel: t("Punches"),
        label: `${t(newKey.substring(0, newKey.length - 1))} ${newKey.slice(-1) === "0" ? "" : newKey.slice(-1)}`,
        locked: true,
        minWidth: 100,
        align: "right" as ColumnAlign,
      });
      return key;
    });
    return punchesColumns;
  }

  getColumns() {
    const { t } = this.props;
    const { activeNightShifts } = this.state;
    const {
      totalWorkedMinutes = 0,
      totalBreakMinutes = 0,
      totalPlannedMinutes = 0,
      totalExtraHoursMinutes = 0,
      totalMissedMinutes = 0,
      totalEarlyLeaveMinutes = 0,
      totalLateEntryMinutes = 0,
      totalDebitMinutes = 0,
      totalCrossShiftsIntervalDiffMinutes = 0,
      totalNightShiftMinutes = {},
      totalNightReducedMinutes = 0,
      totalOnCallMinutes = {
        activated: 0,
        onCall: 0,
        reducingActivated: 0,
      },
      activePhases,
      totalExtraHoursPhases = {},
      totalHoursBankPhases = {},
    } = this.state;
    const punchesColumns = this.getPunchesColumns();
    const nightShiftColumns = activeNightShifts.length
      ? activeNightShifts.map((ns) => ({
          accessor: `nightShiftMinutes${ns.name}`,
          rubyAccessor: "night_hours",
          groupLabel: t("nightShiftMinutes"),
          label: `${t("ns-col")} ${ns.name}%`,
          Footer: minsToHrsMins(totalNightShiftMinutes[ns.name] || 0),
          style: { fontWeight: "500" },
          Cell: (row: iCellInfo<ReportTableData>) => {
            const hours = row.original.nightShiftMinutes[ns.name] || 0;
            return minsToHrsMins(hours);
          },
          align: "right" as ColumnAlign,
          minWidth: 100,
        }))
      : [];
    const nightReducedHoursColumn = this.state.isNightReducedHoursColumnAllowed
      ? [
          {
            accessor: "nightReducedMinutes",
            rubyAccessor: "night_reduced_hours",
            label: t("ns-col-reduced-hours"),
            style: { fontWeight: "500" },
            Footer: minsToHrsMins(totalNightReducedMinutes),
            align: "right" as ColumnAlign,
            minWidth: 130,
          },
        ]
      : [];

    const ehPhasesColumns = activePhases
      ? getPhasesColumnsForSingleReport(activePhases, t, totalExtraHoursPhases, PhasesType.extraHours)
      : [];

    const hbPhasesColumns = activePhases
      ? getPhasesColumnsForSingleReport(activePhases, t, totalHoursBankPhases, PhasesType.hoursBank)
      : [];

    const columns = [
      getDateColumn(t),
      {
        accessor: "schedule",
        rubyAccessor: "schedule",
        Cell: (r: iCellInfo<ReportTableData>) =>
          r.original.scheduleException ? (
            <span className={tc("holiday")}>
              {t("Exception")}
              <div className="hint" style={{ top: "-4px" }}>
                {["ApprovedOvertimeRequest", "enableDay", "ApprovedOvertimeNonWorkingDayRequest"].includes(
                  r.original.scheduleException,
                )
                  ? t(r.original.scheduleException)
                  : r.original.scheduleException}
              </div>
            </span>
          ) : (
            r.value
          ),
        label: t("Schedule"),
        minWidth: 100,
      },
      {
        accessor: "businessRulesGroup",
        rubyAccessor: "business_rules_group",
        label: t("Business Rules Group"),
        minWidth: 100,
      },
      ...punchesColumns,
      {
        accessor: "plannedMinutes",
        rubyAccessor: "planned_hours",
        label: t("Planned Hours"),
        Footer: minsToHrsMins(totalPlannedMinutes),
        style: { fontWeight: "500" },
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        label: t("Worked Hours"),
        Footer: minsToHrsMins(totalWorkedMinutes),
        minWidth: 100,
        style: { fontWeight: "500" },
        align: "right" as ColumnAlign,
      },
      {
        accessor: "breakMinutes",
        rubyAccessor: "break_hours",
        label: t("Break hours"),
        Footer: minsToHrsMins(totalBreakMinutes),
        minWidth: 100,
        style: { fontWeight: "500" },
        align: "right" as ColumnAlign,
      },
      ...nightShiftColumns,
      ...nightReducedHoursColumn,
      {
        accessor: "lateEntryMinutes",
        rubyAccessor: "late_entry_hours",
        Footer: minsToHrsMins(totalLateEntryMinutes),
        style: { fontWeight: "500" },
        label: t("Late Arrival"),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "earlyLeaveMinutes",
        rubyAccessor: "early_leave_hours",
        Footer: minsToHrsMins(totalEarlyLeaveMinutes),
        style: { fontWeight: "500" },
        label: t("Late Leave"),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      ...ehPhasesColumns,
      {
        accessor: "extraHoursMinutes",
        rubyAccessor: "extra_hours",
        label: t("Extra Hours"),
        Footer: minsToHrsMins(totalExtraHoursMinutes),
        style: { fontWeight: "500" },
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      ...hbPhasesColumns,
      {
        accessor: "hoursBankMinutes",
        rubyAccessor: "hours_bank",
        label: t("Hours Bank"),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "missedMinutes",
        rubyAccessor: "missed_hours",
        label: t("Missed Minutes"),
        Footer: minsToHrsMins(totalMissedMinutes),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "cumulativeHoursBankMinutes",
        rubyAccessor: "cumulative_hours_bank",
        label: t("Accumulated Hours Bank"),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "crossShiftsInterval",
        rubyAccessor: "cross_shifts_interval_diff_hours",
        label: t("Cross Shift Interval"),
        style: { fontWeight: "500" },
        Footer: minsToHrsMins(totalCrossShiftsIntervalDiffMinutes),
        minWidth: 100,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "holiday",
        rubyAccessor: "holiday",
        label: t("Holiday"),
        Header: (
          <div className={tc("icon")}>
            {images.holidayIcon}
            <span className={tc("tooltip")}>{t("Holiday")}</span>
          </div>
        ),
        minWidth: 90,
        Cell: (row: iCellInfo<ReportTableData>) =>
          row.original.holiday ? (
            <span className={tc("holiday")}>
              {images.holidayIcon}
              <div className="hint">{row.value}</div>
            </span>
          ) : (
            ""
          ),
        style: { overflow: "visible", fontWeight: "500" },
        align: "center" as ColumnAlign,
      },
      {
        accessor: "observation",
        rubyAccessor: "observation",
        label: t("OBS"),
        minWidth: 100,
        Cell: (row: iCellInfo<ReportTableData>) => {
          if (row.value) {
            return t(`${TranslationNamespaces.requestReasons}|${row.value}`);
          }
          return row.original.missedDay ? t("Missing") : null;
        },
      },
      {
        accessor: "onCallMinutes",
        rubyAccessor: "on_call_hours",
        label: t("On Call Minutes"),
        Footer: minsToHrsMins(totalOnCallMinutes.onCall),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "onCallActivatedMinutes",
        rubyAccessor: "on_call_activated_hours",
        Footer: minsToHrsMins(totalOnCallMinutes.activated),
        label: t("On Call Activated Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "debitMinutes",
        rubyAccessor: "debit_hours",
        Footer: minsToHrsMins(totalDebitMinutes),
        label: t("Debit Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "right" as ColumnAlign,
      },
      {
        accessor: "onCallReducingActivatedMinutes",
        rubyAccessor: "on_call_reducing_activated_hours",
        Footer: minsToHrsMins(totalOnCallMinutes.reducingActivated),
        label: t("On Call Reducing Activated Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
    ];

    return columns.filter((col) => col);
  }

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem("customColumns_MyDigitalSignatureReport", selectedColumns.join());
    }
    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render() {
    const {
      notification,
      notificationType,
      employeeInfo,
      dates = [],
      isFetching,
      selectedColumns,
      reportWasSigned,
      startDate,
      endDate,
      isMadanRashiEnabled,
    } = this.state;
    const { t } = this.props;

    if (reportWasSigned && startDate && endDate) {
      const { digitalSignatureUuid, reportHash } = this.props.match.params;
      return (
        <Wrapper style={{ height: "100vh" }}>
          <Snackbar />
          <ReportSignedSuccessfully
            selectedColumns={selectedColumns}
            startDate={startDate}
            endDate={endDate}
            digitalSignatureUuid={digitalSignatureUuid}
            reportHash={reportHash}
          />
          <DownloadSnacks />
        </Wrapper>
      );
    }

    const columns = this.getColumns();
    let data: ReportTableData[] = [];

    if (!isMadanRashiEnabled) {
      data = dates.map((d) => {
        const punches2: Record<string, string> = {};
        let entryI = 0;
        let breakStartI = 0;
        let breakEndI = 0;
        let exitI = 0;
        d.punches.map((p, i) => {
          let { key } = p;

          if (!key) {
            switch (p.description) {
              case "entry":
                key = `${p.description}${(entryI += 1)}`;
                break;
              case "break_start":
                key = `${p.description}${(breakStartI += 1)}`;
                break;
              case "break_end":
                key = `${p.description}${(breakEndI += 1)}`;
                break;
              case "exit":
                key = `${p.description}${(exitI += 1)}`;
                break;
              default:
                break;
            }
          } else {
            switch (p.description) {
              case "entry":
                entryI += 1;
                break;
              case "break_start":
                breakStartI += 1;
                break;
              case "break_end":
                breakEndI += 1;
                break;
              case "exit":
                exitI += 1;
                break;
              default:
                break;
            }
          }
          punches2[key] = p.time ? momentTz.tz(p.time, p.timezone).format("HH:mm") : "00:00";
          return p;
        });

        return {
          date: getDateCellData(d),
          ...punches2,
          extraHoursPhases: d.extraHoursPhases,
          hoursBankPhases: d.hoursBankPhases,
          workedMinutes: d.workedMinutes ? minsToHrsMins(d.workedMinutes) : "00:00",
          extraHoursMinutes: d.extraHoursMinutes ? minsToHrsMins(d.extraHoursMinutes) : "00:00",
          plannedMinutes: d.plannedMinutes ? minsToHrsMins(d.plannedMinutes) : "00:00",
          breakMinutes: d.breakMinutes ? minsToHrsMins(d.breakMinutes) : "00:00",
          nightMinutes: d.nightMinutes ? minsToHrsMins(d.nightMinutes) : "00:00",
          nightReducedMinutes: d.nightReducedMinutes ? minsToHrsMins(d.nightReducedMinutes) : "00:00",
          businessRulesGroup: d.businessRulesGroup ? d.businessRulesGroup.name : "",
          lateEntryMinutes: d.lateEntryMinutes ? minsToHrsMins(d.lateEntryMinutes) : "00:00",
          debitMinutes: d.debitMinutes ? minsToHrsMins(d.debitMinutes) : "00:00",
          earlyLeaveMinutes: d.earlyLeaveMinutes ? minsToHrsMins(d.earlyLeaveMinutes) : "00:00",
          hoursBankMinutes: d.hoursBankMinutes ? minsToHrsMins(d.hoursBankMinutes) : "00:00",
          missedMinutes: d.missedMinutes ? minsToHrsMins(d.missedMinutes) : "00:00",
          cumulativeHoursBankMinutes: d.cumulativeHoursBankMinutes
            ? minsToHrsMins(d.cumulativeHoursBankMinutes)
            : "00:00",
          crossShiftsInterval: d.crossShiftsInterval ? minsToHrsMins(d.crossShiftsInterval.diffMinutes) : "00:00",
          nightShiftMinutes: d.nightShiftMinutes || {},
          holiday: d.holiday as string,
          schedule: d.schedule ? d.schedule.name || "" : "",
          scheduleException: d.scheduleException?.name ? d.scheduleException.name : "",
          missedDay: d.missedDay,
          observation: d.observation,
          onCallMinutes: d.onCallMinutes.onCall ? minsToHrsMins(d.onCallMinutes.onCall) : "00:00",
          onCallActivatedMinutes:
            d.onCallMinutes && d.onCallMinutes.activated ? minsToHrsMins(d.onCallMinutes.activated) : "00:00",
          status: d.status,
        };
      });
    }

    const { digitalSignatureUuid, reportHash } = this.props.match.params;

    return (
      <FullPage minimal>
        <Wrapper>
          <Snackbar />
          <Title isRTL={window.global_store.isRTL}>{t("Detailed report")}</Title>
          <br />
          <br />
          {notification && (
            <NotificationRow employeesPage withCloseButton type={notificationType} message={notification} />
          )}
          <br />
          {!isMadanRashiEnabled ? (
            <TablePage<ReportTableData>
              rows={data}
              columns={columns}
              loading={isFetching}
              customColumnsAvailable
              selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
              onColumnsChange={this.onColumnsChange}
              tableDetails={
                employeeInfo && startDate ? (
                  <ReportHeader>
                    <ReportTitle>{employeeInfo?.fullName || ""}</ReportTitle>
                    <ReportSubtitle>
                      {moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} -
                      {moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </ReportSubtitle>
                  </ReportHeader>
                ) : null
              }
              interactive={false}
            />
          ) : (
            <DownloadDigitalSignaturesReportButton
              reportHash={reportHash}
              digitalSignatureUuid={digitalSignatureUuid}
            />
          )}
          <SignBlock>
            <SignReportRow
              digitalSignatureUuid={digitalSignatureUuid}
              reportHash={reportHash}
              onSign={() =>
                this.setState({
                  reportWasSigned: true,
                  notificationType: NotificationType.success,
                  notification: t("The report has been signed succsessfully"),
                })
              }
              onError={(err: string) => {
                // eslint-disable-next-line no-console
                console.log(err);
                this.setState({ notification: err, notificationType: NotificationType.error });
              }}
            />
          </SignBlock>
          <DownloadSnacks />
        </Wrapper>
      </FullPage>
    );
  }
}

export default withRouter(
  withTranslation([
    TranslationNamespaces.reportsPage,
    TranslationNamespaces.requestReasons,
    TranslationNamespaces.phases,
  ])(MyDigitalSignatureReport),
);
