import CheckboxControl from "components/UI/NewCheckbox";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import { useTranslation } from "react-i18next";
import { LockAction, SelectedLockReportEmployee } from "./types";

const ActionsBarRow = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  left: 0;
  bottom: 5.12%;
`;

const ActionsBarWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  height: 60px;
  width: 100%;
  max-width: 693px;
  border-radius: 58px;
  background: var(--colors-mainText);
  color: #fff;
  box-shadow: 0px 5px 30px rgba(129, 147, 171, 0.2);
`;

const SelectedCount = styled.div`
  display: flex;
  align-items: center;

  .checkbox-label {
    color: #fff;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .styled-button {
    color: #fff;
    border-color: #fff;
  }
`;

const Spacer = styled.div`
  width: 16px;
`;

interface LockReportActionsBarProps {
  selectedEmployees: SelectedLockReportEmployee[];
  onUncheckAll: () => void;
  onYes: (lock: LockAction) => void;
}

const LockReportActionsBar = ({ selectedEmployees, onUncheckAll, onYes }: LockReportActionsBarProps): JSX.Element => {
  const { t } = useTranslation("payment");
  const employeesToLock = [];
  const employeesToUnlock = [];

  selectedEmployees.forEach((se) =>
    se.action === LockAction.lock ? employeesToLock.push(se) : employeesToUnlock.push(se),
  );

  return (
    <ActionsBarRow>
      <ActionsBarWrapper>
        <SelectedCount>
          <CheckboxControl
            label={`${selectedEmployees.length} ${t("Employee Selected")}`}
            checked
            onChange={onUncheckAll}
          />
        </SelectedCount>
        <ActionButtons>
          {!!employeesToLock.length && (
            <Button
              value={`(${employeesToLock.length}) ${t("Lock")}`}
              style={{ width: "150px" }}
              state={ButtonState.recommend}
              onClick={() => onYes(LockAction.lock)}
            />
          )}

          {!!employeesToLock.length && !!employeesToUnlock.length && <Spacer />}

          {!!employeesToUnlock.length && (
            <Button
              style={{ width: "150px" }}
              value={`(${employeesToUnlock.length}) ${t("Unlock")}`}
              state={ButtonState.recommend}
              onClick={() => onYes(LockAction.unlock)}
            />
          )}
        </ActionButtons>
      </ActionsBarWrapper>
    </ActionsBarRow>
  );
};

export default LockReportActionsBar;
