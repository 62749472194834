export type TimesheetRow = {
  taskUuid: string;
  taskName: string;
  projectName: string;
  projectUuid: string;
  dates: number[];
};

export type ParsedTimesheetDataSummary = {
  actualWorkMinutes: number;
  dailyActivitiesMinutes: number;
  plannedWorkMinutes: number;
  gt24hPerDayNumDays: number;
  overtimeNumDays: number;
  activityMoreThanWorkingTimeDays: number;
  overallActivityMoreThanWorkingTimeDays: number;
};

export type ParsedTimesheetData = {
  dates: string[];
  workingHours: number[];
  unassignedMinutes: number[];
  tasksData: TimesheetRow[];
  isDayPlannedArray: boolean[];
  additionalTasks: {
    taskUuid: string;
    taskName: string;
    projectUuid: string;
    projectName: string;
  }[];
  summary: ParsedTimesheetDataSummary;
};

export enum TimesheetApprovalStatus {
  pending = "pending",
  approved = "approved",
  declined = "declined",
  revoked = "revoked",
  canceled = "canceled",
}

export type ParsedTimesheetWeekData = {
  dates: string[];
  isDayPlannedArray: boolean[];
  tasksData: TimesheetRow[];
  startDayOffset: number;
};

export type TimesheetsListItem = {
  uuid: string;
  approvalStatus: TimesheetApprovalStatus;
  startDate: string;
  endDate: string;
  userProfileUuid: string;
  declinedBy: string;
  declineReason: string;
  workedMinutes: number;
  activitiesMinutes: number;
  overtimedDays: number;
  exceededDays: number;
  submittedAt: string;
  submittedBy: string;
  updatedAt: string;
};

export type TimeForTaskOnDatePayload = {
  date: string;
  taskUuid: string;
  updatedBy: string;
  activitiesMinutes: number;
  locationUuid?: string;
};

export type TimeForTaskOnDateQuery = {
  companyUuid: string;
  body: TimeForTaskOnDatePayload;
  userProfileUuid: string;
};

export type TimesheetDataQuery = {
  companyUuid: string;
  from: string;
  /** Including */
  to: string;
  requestedBy: string;
  userProfileUuid: string;
  status?: string;
};

type TimesheetActivityByTask = {
  activitiesMinutes: number;
};

export type TimesheetDate = {
  /** format "YYYY-MM-DD" */
  date: string;
  /** totalWorkingTime from dayily summary
   * 0 by default
   */
  dailyActivitiesTime: number;
  dailyActivitiesMinutes: number;
  plannedWorkMinutes: number;
  actualWorkMinutes: number;
  isDayPlanned: boolean;
  activitiesMinutesByTasks: Record<string, TimesheetActivityByTask>;
};

export type TimesheetResponseContent = {
  days: TimesheetDate[];
};

type Task = {
  name: string;
  project: {
    name: string;
    uuid: string;
  };
};

export type TimesheetResponseMetadata = {
  tasksByUuid: Record<string, Task>;
  projects: Record<string, string>;
  /** Tasks that were assigned to employee but is not in TimesheetResponseContent */
  unassignedTasks: string[];
  timesheet: TimesheetsListItem;
};

export type TimesheetResponse = {
  content: TimesheetResponseContent;
  metadata: TimesheetResponseMetadata;
};
