import styled from "styled-components";

export const Subtitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--colors-secondary);
  margin-top: 22px;
`;

export const Subheader = styled.div`
  margin-top: 32px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  color: var(--colors-mainText);
  letter-spacing: -0.33px;
  line-height: 26px;
`;

export const SettingsBlock = styled.div`
  width: 100%;
  max-width: 420px;
`;

export const LabelRow = styled.div`
  margin-top: 5px;
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 20px;
  color: var(--colors-surface-900-p);
  text-align: start;
  letter-spacing: 0;
  vertical-align: middle;

  .disabled {
    color: var(--colors-surface-400);
  }
`;
