import { Component } from "react";
import { TFunction } from "react-i18next";
import BEM from "utils/BEM";
import FieldWrapper from "components/UI/FieldWrapper";
import TextareaControl from "components/controls/TextareaControl";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import "styles/lightbox.scss";

const b = BEM.b("lightbox");

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;

const Spacer2 = styled.div`
  height: 39px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 67px;
`;

interface DeclineReasonPopupProps {
  t: TFunction;
  title: string;
  text: string;
  buttonYesTitle: string;
  loading?: boolean;
  buttonCancelTitle: string;
  onClose: () => void;
  onYes: (declineReason: string) => void;
}

interface DeclineReasonPopupState {
  declineReason: string;
}

class DeclineReasonPopup extends Component<DeclineReasonPopupProps, DeclineReasonPopupState> {
  readonly state: Readonly<DeclineReasonPopupState> = {
    declineReason: "",
  };

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = () => this.closePopup();

  onYes = () => this.props.onYes(this.state.declineReason);

  render() {
    const { t, title, text, buttonYesTitle, loading, buttonCancelTitle } = this.props;
    const { declineReason } = this.state;
    let buttonProps = {};

    if (loading) {
      buttonProps = { disabled: "disabled" };
    }

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        <div className={b("text")}>{text}</div>
        <Spacer2 />

        <FieldWrapper fieldName={t("Reason")} width="100%">
          <TextareaControl
            placeholder={t("My reason...")}
            rows={5}
            value={declineReason}
            onChange={(ev) => {
              this.setState({ declineReason: ev.target.value });
            }}
          />
        </FieldWrapper>
        <Buttons>
          <Button value={buttonCancelTitle} state={ButtonState.secondary} {...buttonProps} onClick={this.onCancel} />
          <Spacer />
          <Button value={buttonYesTitle} state={ButtonState.primary} {...buttonProps} onClick={this.onYes} />
        </Buttons>
      </div>
    );
  }
}

export default DeclineReasonPopup;
