/* eslint-disable no-return-assign */
import React, { Component } from "react";
import BEM from "utils/BEM";
import "./styles/popup-message.scss";
import { withTranslation } from "react-i18next";
import TextInputControl from "../controls/TextInputControl";

const b = BEM.b("teams-popup-message");

class TeamsPopupMessage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      title: this.props.title,
      name: { value: this.props.item.name || "", error: "" },
      buttonCancelTitle: t("common|Cancel"),
      buttonYesTitle: t("common|Confirm"),
    };
  }
  componentDidMount() {
    this.getContent();
  }

  closePopup() {
    this.props.onClose();
  }

  getContent() {
    this.setState({
      title: this.props.title,
      name: { value: this.props.item.name || "", error: "" },
      buttonCancelTitle: this.props.t("Cancel"),
      buttonYesTitle: this.props.buttonYesTitle,
    });
  }
  onCancel() {
    this.closePopup();
  }
  onYes() {
    const name = { value: this.name.getValue(), error: "" };
    const { item } = this.props;

    if (!this.name.getValue()) {
      name.error = this.props.t("Team name can't be empty");
      this.setState({ name });
    } else {
      const obj = {};

      obj.id = item.id;
      obj.name = this.name.getValue();

      this.props.onYes && this.props.onYes(obj);
    }
  }

  render() {
    const { title, name, buttonYesTitle, buttonCancelTitle } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>

        <div className={b("label")}>{t("Name")}</div>
        <TextInputControl size={60} value={name.value} error={name.error} onRef={(ref) => (this.name = ref)} />
        {name.error && <div className={b("error")}>{name.error}</div>}

        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.onCancel.bind(this)}>
            {buttonCancelTitle}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes.bind(this)}>
            {buttonYesTitle}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("teams")(TeamsPopupMessage);
