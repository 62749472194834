import styled from "styled-components";

export const GroupNameWrapper = styled.div<{ isLocked?: boolean }>`
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding-top: 5px;
  max-width: 100%;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(p) => {
    if (p.isLocked) {
      return `
        padding-inline-end: 20px;

        &:after{
          inset-inline-end: 0;
        }
      `;
    }

    return "";
  }}

  ${(p) => {
    if (p.isLocked) {
      return `
        &:after{
          position: absolute;
          top: 13px;
          width: 11px;
          height: 13px;
          content: "";
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath d='M10.083 5.493V4.375C10.083 1.963 8.027 0 5.5 0S.917 1.963.917 4.375v1.118C.37 5.797 0 6.355 0 7v5.25C0 13.215.822 14 1.833 14h7.334c1.01 0 1.833-.785 1.833-1.75V7c0-.645-.371-1.203-.917-1.507zM5.5 1.75c1.516 0 2.75 1.177 2.75 2.625v.875h-5.5v-.875c0-1.448 1.234-2.625 2.75-2.625zm.917 8.41v1.215H4.583v-1.216a1.28 1.28 0 01-.458-.971c0-.725.616-1.313 1.375-1.313.76 0 1.375.588 1.375 1.313 0 .387-.179.731-.458.971z' fill='%238093AC' fill-rule='nonzero'/%3E%3C/svg%3E");
        }
      `;
    }

    return "";
  }}
`;
