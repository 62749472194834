import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useMemo, useState } from "react";
import { normalizeString } from "components/views/Select";
import ClickOutside from "react-click-outside";
import { StoreApi, UseBoundStore } from "zustand/esm";
import styled from "styled-components";
import { ProjRes } from "services/oitchau-api";
import { ClickOutsideType } from "types/common";
import { TrackTimeStore } from "../TrackTime.store";
import { CreateProject } from "./CreateProject";
import { OverlayDropdown } from "./OverlayDropdown";
import { SearchBar } from "./SearchBar";

const ProjectRowWrapper = styled.div`
  width: 100%;
  .ProjectRow {
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    cursor: pointer;
    & > svg path {
      fill: var(--colors-surface-500);
    }
    &:hover {
      svg path {
        fill: var(--colors-surface-900-p);
      }
    }
    &.expanded {
      background: var(--colors-surface-50);
    }
    .Title {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      font-size: var(--typography-font-size-default);
      height: 52px;
      .Name {
        display: flex;
        align-items: center;
        flex: 1 0 0;
        color: var(--colors-surface-900-p);
        font-weight: var(--typography-font-weight-medium);
        .Client {
          color: var(--colors-surface-500);
          font-weight: var(--typography-font-weight-default);
        }
      }
    }
    &.simple {
      border: none;
      .Title {
        height: 46px;
        background: var(--colors-surface-50);
      }
    }
  }
  .SearchResults {
    margin: 4px 0;
  }
`;

export const SelectProject = ({
  createTask,
  canAddTask,
  store,
}: {
  createTask: (taskName: string, project: ProjRes) => void;
  canAddTask: boolean;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const [searchProjectValue, setSearchProjectValue] = useState<string>("");
  const { setShowSelectProject, projects, setTargetProject, searchValue, setSearchValue, setShowTaskPicker } = store();

  const options = useMemo(
    () =>
      projects.map((opt) => ({
        ...opt,
        searchTerms: [normalizeString(opt.project.name)],
      })),
    [projects],
  );

  const foundOptions = useMemo(() => {
    const str = normalizeString(searchProjectValue);
    if (str === "") return options;

    return options.filter((p) => p.searchTerms?.some((term: string) => term.includes(str)));
  }, [options, searchProjectValue]);

  const onCreateProject = async (project: ProjRes) => {
    if (searchValue) {
      await createTask(searchValue, project);
      setSearchValue("");
      setShowTaskPicker(false);
    }
    setShowSelectProject(false);
  };

  return (
    <ClickOutside<ClickOutsideType>
      onClickOutside={() => {
        setShowSelectProject(false);
        setShowTaskPicker(false);
      }}
    >
      <OverlayDropdown
        header={
          <SearchBar
            placeholder={t("Search projects")}
            searchValue={searchProjectValue}
            setSearchValue={setSearchProjectValue}
            additionalAction={() => setShowSelectProject(false)}
          />
        }
        content={
          <ProjectRowWrapper className="ProjectRowWrapper">
            {foundOptions.map((prjOpt) => (
              <div
                key={prjOpt.project.uuid}
                className="ProjectRow with-hover"
                onClick={async () => {
                  setTargetProject(prjOpt.project);
                  await onCreateProject(prjOpt.project);
                }}
              >
                <div className="Title">
                  <div className="Name">
                    <div>{prjOpt.project.name}</div>
                    {prjOpt.project.client?.name != null && (
                      <div className="Client">&nbsp;/&nbsp;{prjOpt.project.client.name}</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ProjectRowWrapper>
        }
        footer={
          canAddTask ? (
            <div className="Footer">
              <CreateProject
                onCreate={async (project) => {
                  await onCreateProject(project);
                }}
                store={store}
              />
            </div>
          ) : null
        }
      />
    </ClickOutside>
  );
};
