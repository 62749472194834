import { SubscriptionObject } from "components/Billing/PricingContainer";
import { ApplicationType } from "types/models/application";
import { PermissionRoleName } from "types/models/permissions";
import { PunchType } from "types/models/punches";
import { RequestTypeName } from "types/models/request";
import { PaymentType, Price, Product, PromotionCode, Tier } from "types/models/subscription";

export enum GaBoolean {
  true = "yes",
  false = "no",
}

export enum GaProduct {
  Bundle = "Bundle",
  TA = "Time and Attendance",
  Project = "Project Time Tracking",
}

export type GaAmpliObject = {
  plan: "Monthly" | "Yearly" | "Trial" | "";
  product: GaProduct | "";
  platform: "Web App";
  integration_name: "";
  user_role: PermissionRoleName;
  signup_date: string;
  active_paying_client: GaBoolean | "";
  company_name: string;
  company_id: string;
  company_uuid: string;
  user_profile_id: string;
  user_profile_uuid: string;
  user_id: string;
};

export enum GaSpPageType {
  departments = "Departments",
  teams = "Teams",
  employees = "Employees",
  individual = "Individual",
}

export enum GaCreateAccountMethod {
  email = "Email",
  phone = "phone",
  google = "google",
}

export type GaCreateAccountPayload = {
  method: GaCreateAccountMethod;
  full_name: string;
  email: string;
  phone: string;
  user_role: PermissionRoleName | "";
  signup_date: string;
  user_id: string;
};

export enum GaLoginMethod {
  email = "Email",
  sso = "SSO",
}

export type GaCreateCompanyPayload = {
  company_name: string;
  company_address: string;
  company_category: string;
  number_of_employees: string;
  company_id?: string;
  company_uuid?: string;
  user_id?: string;
  user_profile_uuid?: string;
};

export enum GaIntegrationStatus {
  success = "success",
  fail = "fail",
  skip = "skip",
}

export type GaSelectIntegrationPayload = {
  integration_name: ApplicationType | "";
  status: GaIntegrationStatus | "";
};

export enum GaInstallExtensionPopupStatus {
  cancel = "Click “Cancel”",
  install = "Click “Install”",
}

export type GaPurchaseItem = {
  product: Product;
  price: Price;
  selectedTier: Tier;
};

export type GaBeginCheckoutPayload = {
  totalPrice: number;
  currency: string;
  promotionCode: PromotionCode | null;
  items: GaPurchaseItem[];
};

export enum GaPurchasePaymentType {
  card = "credit card",
  boleto = "boleto",
}

export type GaPurchasePayload = {
  totalPrice: number;
  currency: string;
  promotionCode: PromotionCode | null;
  items: GaPurchaseItem[];
  activeSubscription: SubscriptionObject | undefined;
  paymentType: PaymentType;
  paymentIntentId: string;
};

export enum GaApprovePunchButtonLocation {
  punchDetails = "Details Sidebar",
  massActions = "Mass Actions",
  superPunch = "Super Punch",
  employeesPunchDetails = "Employee Page Punch Details Sidebar",
}

export type GaApprovePunchPayload = {
  punch_type: PunchType | "multiple types";
  button_location: GaApprovePunchButtonLocation;
};

export enum GaCreatePunchButtonLocation {
  punchAdjustment = "Punch Adjustment Sidebar",
  superPunch = "Super Punch",
}

export type GaCreatePunchPayload = {
  punch_type: PunchType;
  button_location: GaCreatePunchButtonLocation;
};

export type GaChangePunchCategory = {
  punch_type: PunchType;
  punch_type_changed_to: PunchType;
};

export enum GaAddTimeButtonLocation {
  sidebar = "Sidebar",
  punchNow = "Web Tracker Box",
  topBar = "Top Bar",
}

export enum GaStartTimeButtonLocation {
  timeTrackerPage = "Time Tracker Page",
  punchNow = "Web Tracker Box",
  topBar = "Top Bar",
}

export type GaCreateNewProjectPayload = {
  estimated_hours: number;
  estimated_cost: string;
  is_public: GaBoolean;
  project_id: string;
  project_name: string;
};

export type GaRequestSubmitPayload = {
  request_category: RequestTypeName;
  request_sub_category: string;
};

export enum GaIntegrationState {
  Skip = "skip",
  Stay = "stay",
}

export enum DownloadExtensionPage {
  WebTrackerBox = "Web Tracker Box",
  ApplicationsPage = "Applications page",
  IntegrationSetupOnboarding = "Integration setup (Registration flow)",
  IntegrationSetupAppsPage = "Integration setup (Applications page)",
}

export enum MassActionLocations {
  Punches = "Punches",
  Requests = "Requests",
  Schedules = "Schedules",
  ScheduleExceptions = "Schedule exceptions",
  PayPolicies = "Pay policies",
  OnCall = "On call",
  Holidays = "Holidays",
  PayrollGroups = "Payroll groups",
  EmployeeProfile = "Employee profile",
}
