import { Component } from "react";
import { SingleDatePicker } from "react-dates";
import styled from "styled-components";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import {
  DatePickerCommonOverrides,
  CalendarInsideNext,
  CelandarInsidePrev,
} from "components/controls/DatePicker/styled";

const Wrapper = styled(DatePickerCommonOverrides)`
  width: 100%;
  margin-top: 5px;
  .DateInput,
  .SingleDatePickerInput,
  .SingleDatePicker {
    width: 100%;
  }

  .DateInput_input {
    border: 1px solid var(--colors-surface-400);
    padding: 7.5px 10px;
    width: 100%;
    font-size: 15px;
    line-height: 14px;
    border-radius: var(--shapes-border-radius-default);
    font-size: 15px;
    color: var(--colors-mainText);
    font-weight: var(--typography-font-weight-default);

    &:focus {
      border: 1px solid var(--colors-primary);
      outline: none;
    }

    &::placeholder {
      font-size: var(--typography-font-size-default);
      font-family: 400;
      color: var(--colors-surface-400);
    }
    &:placeholder-shown {
      border: 1px solid var(--colors-surface-150);
    }
  }

  &.error .DateInput_input {
    border: 1px solid var(--colors-error2);
  }

  &.disabled .DateInput_input {
    font-weight: var(--typography-font-weight-default);
    color: var(--colors-surface-400);
    border: 1px solid var(--colors-surface-150);
    background-color: var(--colors-unknown6);
    font-style: normal;
    cursor: default;
  }
  &.locked .DateInput_input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 15'%3E%3Cpath d='M11 5.886V4.687C11 2.103 8.757 0 6 0S1 2.103 1 4.688v1.198C.405 6.21 0 6.809 0 7.5v5.625C0 14.159.897 15 2 15h8c1.103 0 2-.84 2-1.875V7.5c0-.691-.405-1.29-1-1.614zM6 1.875c1.654 0 3 1.262 3 2.813v.937H3v-.938c0-1.55 1.346-2.812 3-2.812zm1 9.01v1.303H5v-1.303a1.361 1.361 0 01-.5-1.041c0-.777.672-1.406 1.5-1.406s1.5.63 1.5 1.406c0 .415-.195.784-.5 1.041z' fill='%23D2DEEE' fill-opacity='.8'/%3E%3C/svg%3E");
    background-size: 12px 15px;
    background-repeat: no-repeat;
    background-position-y: 50%;
    ${(p) => (p.theme.dir === "ltr" ? "background-position-x: calc(100% - 17px);" : "background-position-x: 17px;")}
    background-color: var(--colors-default);
    padding-inline-end: 29px;
    color: var(--colors-mainText);
    border: 1px solid var(--colors-surface-400);
    cursor: default;
  }
`;

interface SingleDatePickerControlProps {
  numberOfMonths: number;
  isOutsideRange?: (date: moment.Moment) => boolean;
  t: WithTranslation["t"];
  onChange?: (date: moment.Moment) => void;
  value: moment.Moment | null;
  error?: boolean;
  disabled?: boolean;
  locked?: boolean;
}

interface SingleDatePickerControlState {
  focused: boolean;
}

/**
 * Common control to select a single date
 * @deprecated Use SingleDatePicker from components/controls/DatePicker/SingleDatePicker
 */
class SingleDatePickerControl extends Component<SingleDatePickerControlProps, SingleDatePickerControlState> {
  static defaultProps = {
    numberOfMonths: 2,
  };

  readonly state: SingleDatePickerControlState = {
    focused: false,
  };

  onChange = (value: moment.Moment | null) => {
    const { onChange } = this.props;
    const yearsLimit = window.global_store.beta ? 5 : 1;
    let date = value && moment(value).isValid() ? moment(value).clone() : moment().clone();

    if (date.isAfter(moment().clone().add(yearsLimit, "year"))) {
      date = moment().clone().add(yearsLimit, "year");
    } else if (date.isBefore(moment().clone().subtract(yearsLimit, "year"))) {
      date = moment().clone().subtract(yearsLimit, "year");
    }

    if (onChange) {
      onChange(date);
    }
  };

  getClasses = ({ error, disabled, locked }: Record<string, boolean | undefined>) => {
    const classes = [];

    if (disabled) {
      classes.push("disabled");
    }
    if (locked) {
      classes.push("locked");
    }
    if (error) {
      classes.push("error");
    }

    return classes.join(" ");
  };

  render() {
    const { value, error, disabled, locked, isOutsideRange, numberOfMonths, t } = this.props;
    const { focused } = this.state;

    return (
      <Wrapper className={this.getClasses({ disabled, locked, error })}>
        <SingleDatePicker
          id="single-date-picker"
          isRTL={window.global_store.isRTL}
          anchorDirection={window.global_store.isRTL ? "right" : "left"}
          date={value}
          disabled={disabled}
          required
          daySize={32}
          horizontalMargin={10}
          verticalSpacing={10}
          hideKeyboardShortcutsPanel
          phrases={{}}
          navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
          navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
          displayFormat="MMM D, YYYY"
          weekDayFormat="ddd"
          monthFormat="MMMM YYYY"
          noBorder
          small
          isOutsideRange={isOutsideRange}
          onDateChange={this.onChange}
          focused={focused}
          placeholder={t("Date")}
          numberOfMonths={numberOfMonths}
          onFocusChange={(val) => {
            this.setState({ focused: val.focused });
          }}
        />
      </Wrapper>
    );
  }
}

export default withTranslation()(SingleDatePickerControl);
