import { Container } from "unstated";
import {
  Coupon,
  Feature,
  Interval,
  Price,
  PriceType,
  Product,
  Subscription,
  SubscriptionStatus,
  Tier,
} from "types/models/subscription";
import { TFunction } from "i18next";
import { SubscriptionApi } from "utils/api/subscription";
import { GlobalStoreCompany } from "types/models/company";
import { BillingService } from "./BillingService";

export const BOOK_A_CALL_URL_BR = "https://meetings.hubspot.com/cassiosagawa/agenda-apresentacao-do-oitchau-website";
export const BOOK_A_CALL_URL =
  "http://e.day.io/cafevirtual?__hstc=230837056.001b705406113ecb14a5e5accf2b0c54.16[…]662980039602.74&__hssc=230837056.4.1662980039602&__hsfp=13091307";

export type CheckedProduct = Product & { usedInSubscription?: boolean };
export type ProductItem = {
  product: CheckedProduct;
  price: Price;
  tierSelector?: { value: number; label: string }[];
  selectedTier: Tier;
};
export type ExistingItem = {
  price: Price;
  seats: number;
  type: Feature;
  tier: Tier;
};

export type SubscriptionObject = Subscription & {
  items: {
    feature: Feature;
    maxSeats: number;
    interval?: Interval;
    priceUuid?: string;
    price?: Price;
  }[];
};

export type PricingContainerState = {
  companyUuid: string;
  products: CheckedProduct[];
  coupon: Coupon | null;
  activeProducts: CheckedProduct[];
  activeSubscription: SubscriptionObject | undefined;
  productItems: Map<string, ProductItem>;
  currentSeatsNumber: number;
  interval: Interval;
  usedSeats?: { [Feature.TA]: number | undefined; [Feature.Project]: number | undefined };
  defaultSeats: { [Feature.TA]: number; [Feature.Project]: number };
};

class PricingContainer extends Container<PricingContainerState> {
  state: PricingContainerState = {
    companyUuid: null!,
    products: [],
    activeProducts: [],
    productItems: new Map(),
    coupon: null,
    activeSubscription: undefined,
    currentSeatsNumber: undefined!,
    interval: undefined!,
    usedSeats: undefined,
    defaultSeats: { [Feature.TA]: 0, [Feature.Project]: 0 },
  };

  async getProducts() {
    const content = await SubscriptionApi.getProductPlansForCompany(this.state.companyUuid);
    const prodData = this.state.activeSubscription
      ? content.map((p) => ({
          ...p,
          usedInSubscription: (this.state.activeSubscription! as SubscriptionObject).items.find(
            (i) => i.feature === p.features[0] && !!i.price,
          ),
        }))
      : content;
    await this.setState({ products: prodData });
  }

  async setCoupon(coupon: Coupon | null) {
    await this.setState({ coupon });
  }

  async setCompanyUuid(companyUuid: string) {
    await this.setState({ companyUuid });
  }

  async selectProduct(feature: string) {
    const { products } = this.state;
    if (feature === Feature.Bundle) {
      await this.setState({
        activeProducts: products,
      });
    } else {
      const product = products.find((p: Product) => p.features.includes(feature as Feature))!;
      await this.setState({
        activeProducts: [product],
      });
    }
  }

  contactSupport() {
    window.zE("messenger", "open");
  }

  async getSubscriptions() {
    const subs = localStorage.getItem("gs_subscriptions");
    const subscriptions = subs ? JSON.parse(subs) : [];
    const existingSubscription =
      BillingService.findSubscriptions(subscriptions, SubscriptionStatus.PastDue) ||
      BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Active);
    if (existingSubscription && existingSubscription.externalId) {
      const activeSubscription = {
        ...existingSubscription,
        items: existingSubscription!.items.map((i) => ({
          feature: i.feature,
          maxSeats: i.maxSeats,
          priceUuid: i.price?.uuid,
          interval: i.price?.interval,
          price: i.price,
        })),
      };
      await this.setState({
        activeSubscription,
        interval: activeSubscription?.items[0].price?.interval || Interval.Year,
      });
    } else {
      await this.setState({ interval: Interval.Year });
    }
  }
  async initPricingPage(company: GlobalStoreCompany) {
    const { employee_count } = company;
    if (!employee_count) return;
    const maxNumber = employee_count.toString().match(/\d+/g)!;
    if (maxNumber?.length < 1) return;
    await this.setState({
      defaultSeats: {
        [Feature.TA]: +maxNumber[maxNumber.length - 1],
        [Feature.Project]: +maxNumber[maxNumber.length - 1],
      },
    });
  }

  async selectPrice(
    interval: Interval,
    t: TFunction,
    currentSeatsNumber: number | undefined,
    initial = false,
    initiatedByTabChange = false,
  ) {
    const { activeSubscription, activeProducts } = this.state;
    let tmpSeats = currentSeatsNumber || this.state.currentSeatsNumber;

    const items = activeProducts.reduce((acc: Map<string, ProductItem>, prod: CheckedProduct) => {
      let price;
      let tier;
      let selectedTier;
      if (initial && activeSubscription) {
        const givenSubscriptionObject = (activeSubscription as SubscriptionObject).items.find(
          (i) => i.feature === prod.features[0],
        );
        price =
          prod.prices.find((p) => givenSubscriptionObject?.price && givenSubscriptionObject.price.uuid === p.uuid) ||
          prod.prices.find((p) => p.interval === interval && p.type === PriceType.Premium)!;
        tier = this.convertTierToSelectOptions(price.tiers, t);
        tmpSeats =
          givenSubscriptionObject?.maxSeats ||
          currentSeatsNumber ||
          this.state.defaultSeats[prod.features[0]] ||
          price.tiers[0].up_to!;
        selectedTier = price?.tiers.find((tr) => tr.up_to === tmpSeats) || price.tiers[0];
      } else {
        price = prod.prices.find((p) => p.interval === interval && p.type === PriceType.Premium)!;
        const availableTiers = this.getAvailableTiers(
          price.tiers,
          initiatedByTabChange ? 0 : currentSeatsNumber,
          (this.state.usedSeats && this.state.usedSeats[prod.features[0] as Feature.TA | Feature.Project]) || 0,
        );
        tier = this.convertTierToSelectOptions(availableTiers, t);
        selectedTier =
          availableTiers.find((tr) => tr.up_to! >= this.state.defaultSeats[prod.features[0]]) ||
          availableTiers.reverse().filter((i) => i.up_to !== null)[0] ||
          price?.tiers.find((tr) => tr.up_to === currentSeatsNumber) ||
          availableTiers[0];
      }
      acc.set(prod.uuid, {
        product: prod,
        price,
        tierSelector: tier,
        selectedTier: selectedTier!,
      });
      return acc;
    }, new Map());

    await this.setState({ productItems: items, currentSeatsNumber: tmpSeats });
  }

  convertTierToSelectOptions = (tiers: Tier[], t: TFunction) =>
    tiers
      .filter((i) => i.up_to && i.up_to <= 250)
      .map((item) => ({ value: item.up_to!, label: `${item.up_to} ${t("seats")}` }));

  get productItems(): ProductItem[] {
    return [...this.state.productItems.values()];
  }
  get coupon(): Coupon | null {
    return this.state.coupon;
  }

  async changeTier(item: ProductItem, val: unknown) {
    const { productItems } = this.state;
    const target = item.price!.tiers.find((ti) => ti.up_to === val)!;
    const obj = productItems.get(item.product.uuid)!;
    obj.selectedTier = target;
    productItems.set(item.product.uuid, obj);
    await this.setState({
      productItems,
      currentSeatsNumber: val as number,
      defaultSeats: { ...this.state.defaultSeats, [item.product.features[0]]: val as number },
    });
  }

  async setInterval(interval: Interval, t: TFunction) {
    await this.setState({ interval });
    await this.selectPrice(interval, t, this.state.currentSeatsNumber, false, true);
  }

  bookCall = (inNewTab = false) => {
    const url = BillingService.isBrazilCompany() ? BOOK_A_CALL_URL_BR : BOOK_A_CALL_URL;
    if (inNewTab) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  async getSubscriptionCoupon() {
    if (this.state.activeSubscription) {
      await SubscriptionApi.getSubscriptionCoupon(
        this.state.companyUuid,
        (this.state.activeSubscription as SubscriptionObject).uuid,
      )
        .then((coupon) => this.setCoupon(coupon))
        .catch((err) => this.setCoupon(null));
    }
  }

  async getCurrentSeatsUsed() {
    await SubscriptionApi.getCompanyAssociatedProducts().then(async (res) => {
      await this.setState({
        usedSeats: res,
      });
    });
  }

  getAvailableTiers(tierSelector: Tier[], currentSeatsNumber = 0, usedSeatsNumber = 0): Tier[] {
    return tierSelector!.filter((tiers) => tiers.up_to! >= currentSeatsNumber && tiers.up_to! >= usedSeatsNumber);
  }
}

export default new PricingContainer();
