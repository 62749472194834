import { useState } from "react";
import { useTranslation } from "react-i18next";
import PunchTypeBox from "components/controls/PunchTypeBox";
import CheckboxControl from "components/UI/NewCheckbox/NewCheckbox";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import ConfirmationModal from "components/UI/ConfirmationModalNew";
import BEM from "utils/BEM";
import { UserPunchSettingRules } from "utils/api/types";
import { translateEmployeeTerm } from "utils/translationHelpers";

const cr = BEM.b("company-rules");

const Wrapper = styled.div`
  padding: 20px 0;
`;
const PunchesTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 448px;
`;

const PunchesTypesTitle = styled.div`
  margin-bottom: 24px;
  line-height: 26px;
  font-size: 15px;
  font-weight: var(--typography-font-weight-medium);
  color: ${(props) => props.theme.colors.mainText};
`;

const CheckboxRowWrapper = styled.div`
  margin-top: 16px;
`;

type AssociateDeviceSettingsPopupProps = {
  onClose: (uncheck?: boolean) => void;
  onYes: (settings: UserPunchSettingRules) => void;
};

export const AssociateDeviceSettingsPopup = ({ onClose, onYes }: AssociateDeviceSettingsPopupProps) => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage]);
  const [isSaving, setIsSaving] = useState(false);
  const [settings, setSettings] = useState<UserPunchSettingRules>({
    allow_clear_compilation: false,
    allow_face_recognition: false,
    allow_only_manual_punch_on_mobile: false,
    allow_mobile_punch: true,
    allow_web_punch: true,
    allow_tablet_punch: true,
  });

  return (
    <ConfirmationModal
      title={t("Device settings")}
      isOpen
      width={518}
      onClose={onClose}
      onYes={async () => {
        setIsSaving(true);
        await onYes(settings);
        onClose(true);
      }}
      yesDisabled={isSaving}
      btnYesTitle={t("Save")}
      btnCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
    >
      <Wrapper>
        <div>
          <div>
            <>
              <PunchesTypesTitle>
                {translateEmployeeTerm(
                  t,
                  TranslationNamespaces.punchesPage,
                  "custom-allow-users-to-punch",
                  "Allow users to punch using:",
                )}
              </PunchesTypesTitle>
              <PunchesTypes>
                <PunchTypeBox
                  active={settings.allow_mobile_punch}
                  onClick={() => {
                    setSettings({ ...settings, allow_mobile_punch: !settings.allow_mobile_punch });
                  }}
                  type="app"
                  label={t("App")}
                />
                <PunchTypeBox
                  active={settings.allow_tablet_punch}
                  onClick={() => {
                    setSettings({ ...settings, allow_tablet_punch: !settings.allow_tablet_punch });
                  }}
                  type="tablet"
                  label={t("Tablet")}
                />
                <PunchTypeBox
                  active={settings.allow_web_punch}
                  onClick={() => {
                    setSettings({ ...settings, allow_web_punch: !settings.allow_web_punch });
                  }}
                  type="web"
                  label={t("Web")}
                />
              </PunchesTypes>
            </>

            <div className={cr("settings")}>
              {(settings.allow_tablet_punch || settings.allow_mobile_punch) && <div className={cr("separator")} />}
              {(settings.allow_tablet_punch || settings.allow_mobile_punch) && (
                <PunchesTypesTitle>{t("Advanced Settings")}</PunchesTypesTitle>
              )}
              {settings.allow_mobile_punch && (
                <>
                  <CheckboxRowWrapper>
                    <CheckboxControl
                      checked={settings.allow_only_manual_punch_on_mobile}
                      label={t("Allow punch adjustments only")}
                      onChange={(value) => setSettings({ ...settings, allow_only_manual_punch_on_mobile: value })}
                    />
                  </CheckboxRowWrapper>
                  <CheckboxRowWrapper>
                    <CheckboxControl
                      checked={settings.allow_clear_compilation}
                      label={t("allow_clear_compilation")}
                      onChange={(value) => setSettings({ ...settings, allow_clear_compilation: value })}
                    />
                  </CheckboxRowWrapper>
                </>
              )}
              {settings.allow_tablet_punch && (
                <CheckboxRowWrapper>
                  <CheckboxControl
                    checked={settings.allow_face_recognition}
                    label={t("Enable Facial Biometry")}
                    onChange={(value) => setSettings({ ...settings, allow_face_recognition: value })}
                  />
                </CheckboxRowWrapper>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </ConfirmationModal>
  );
};
