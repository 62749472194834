import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { urlParam } from "utils/common";
import CreateCompanyPage from "components/Onboarding/CreateCompanyPage";
import CreateHouseholdPage from "components/Onboarding/CreateHouseholdPage";
import { useHubspot } from "hooks/useHubspot";

const AccountTypeNew = () => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path="/account-type"
          render={(props) => {
            const refCode = urlParam("referral_code");
            let trafficSource = "";
            let email = "";
            let trafficMedium = "";
            let trafficCampaing = "";

            if (urlParam("utm_source")) {
              trafficSource = String(urlParam("utm_source"));
            }
            if (urlParam("email")) {
              email = String(urlParam("email"));
            }
            if (urlParam("utm_medium")) {
              trafficMedium = String(urlParam("utm_medium"));
            }
            if (urlParam("utm_campaign")) {
              trafficCampaing = String(urlParam("utm_campaign"));
            }

            if (urlParam("type")) {
              return (
                <CreateHouseholdPage
                  refCode={refCode}
                  trafficSource={trafficSource}
                  email={email}
                  trafficMedium={trafficMedium}
                  trafficCampaing={trafficCampaing}
                />
              );
            }

            return <CreateCompanyPage {...props} refCode={refCode || null} />;
          }}
        />
      </Switch>
    </Suspense>
  );
};

export default AccountTypeNew;
