import React from "react";
import { getNextDueDateBRGroup } from "utils/apiHelpers";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import { Label, LabelRow } from "./styled";

class NextDueDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dueDate: "" };
  }

  componentDidMount() {
    this.getNextDueDate(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldUpdate = this.shouldUpdate(nextProps, this.props);
    if (shouldUpdate) {
      this.getNextDueDate(nextProps);
    }
  }

  shouldUpdate = (nextProps, thisProps) => {
    if (!nextProps.startDate || !thisProps.startDate) {
      return false;
    }
    if (nextProps.startDate.format("YYYY-MM-DD") !== thisProps.startDate.format("YYYY-MM-DD")) {
      return true;
    }
    if (nextProps.hoursBankStartDateOverride !== thisProps.hoursBankStartDateOverride) {
      return true;
    }
    if (nextProps.hoursBankRecurrenceInterval !== thisProps.hoursBankRecurrenceInterval) {
      return true;
    }
    if (
      (thisProps.hoursBankStartDateNew && !nextProps.hoursBankStartDateNew) ||
      (!thisProps.hoursBankStartDateNew && nextProps.hoursBankStartDateNew)
    ) {
      return true;
    }
    if (!nextProps.hoursBankStartDateNew || !thisProps.hoursBankStartDateNew) {
      return false;
    }
    if (nextProps.hoursBankStartDateNew.format("YYYY-MM-DD") !== thisProps.hoursBankStartDateNew.format("YYYY-MM-DD")) {
      return true;
    }
    return false;
  };

  getNextDueDate(props) {
    const { startDate, hoursBankStartDateOverride, hoursBankStartDateNew, hoursBankRecurrenceInterval } = props;
    const data = {
      body: {
        content: {
          startDate: startDate.format("YYYY-MM-DD"), // "2019-11-20",
          hoursBankStartDateNew: hoursBankStartDateNew ? hoursBankStartDateNew.format("YYYY-MM-DD") : null, // null,
          hoursBankStartDateOverride: hoursBankStartDateOverride || false,
          hoursBankRecurrenceInterval,
          hoursBankRecurrencePeriod: "month",
        },
      },
    };
    getNextDueDateBRGroup(data).then((r) => {
      const dueDate = moment(r.content.nextDueDate).format("DD/MM/YYYY");
      this.setState({ dueDate });
    });
  }

  render() {
    const { dueDate } = this.state;
    const { disabled, t } = this.props;

    return (
      <>
        {dueDate ? (
          <LabelRow>
            <Label className={disabled ? "disabled" : ""}>
              {t("Next End Date")} {dueDate}
            </Label>
          </LabelRow>
        ) : null}
      </>
    );
  }
}

export default withTranslation("company-rules")(NextDueDate);
