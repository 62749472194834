import React, { Component } from "react";
import Select from "components/UI/Select";
import { getLocations } from "utils/apiHelpers";

export default class LocationsDropdownControl extends Component {
  static defaultProps = {
    fieldView: true, //
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      locations: [],
    };
  }
  componentDidMount() {
    this.updateLocations();
  }

  updateLocations() {
    if (!window.global_store.locations) {
      this.setState({ loading: true });
      getLocations().then((r) => {
        window.global_store.locations = r.locations;
        this.setState({
          loading: false,
          locations: this.getLocationsList(r.locations),
        });
      });
    } else {
      this.setState({
        loading: false,
        locations: this.getLocationsList(window.global_store.locations),
      });
    }
  }
  getLocationsList = (locationsList) => {
    const { withEmpty } = this.props;
    let locations = locationsList.map((location) => ({
      label: location.name,
      value: location.uuid,
      uuid: location.uuid,
      timeZone: location.time_zone,
    }));
    if (withEmpty) {
      locations = [{ label: this.props.placeholder, value: "" }].concat(locations);
    }
    return locations;
  };
  onChange = (val) => {
    const { locations } = this.state;
    const { onChange } = this.props;

    const selectedLocation = locations.find((loc) => loc.value === val);
    onChange(selectedLocation);
  };
  render() {
    const { locations, loading } = this.state;
    const { value, fieldView } = this.props;

    if (locations.length > 0) {
      return (
        <Select
          className={loading ? "loading" : ""}
          modifiers={{ field: fieldView, notfilled: !value }}
          value={value || ""}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          options={locations}
        />
      );
    }
    return null;
  }
}
