import React from "react";

type UseStateResponse<T> = [T, (partialSate: Partial<T>) => void]; // Define interface for hook return value

const useState = <S>(initialState: S): UseStateResponse<S> => {
  const [state, updateState] = React.useState<S>(initialState); // Create state using React hook
  const setState = (newState: Partial<S>) => {
    updateState({ ...state, ...newState }); // Merge changes with current state
  };
  return [state, setState]; // Return current state and update function
};

export default useState;
