import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";
import { HeaderAction } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory } from "react-router-dom";
import Breaks from "./Breaks";

const BreaksPage = () => {
  const { t } = useTranslation(TranslationNamespaces.schedules);
  const flags = useFlags();
  const history = useHistory();
  const [addNewBreakPopupVisible, setAddNewBreakPopupVisible] = useState(false);

  if (!flags.breakTypes) {
    history.push("/schedules/");
  }

  return (
    <FullPage
      title={t("Schedule Breaks")}
      headerAction={
        <HeaderAction>
          <HeaderActionButtonAdd
            state={ButtonState.secondary}
            title={t("New Custom Break")}
            onClick={() => setAddNewBreakPopupVisible(true)}
          />
        </HeaderAction>
      }
    >
      <Breaks
        addNewBreakPopupVisible={addNewBreakPopupVisible}
        closeNewBreakPopup={() => setAddNewBreakPopupVisible(false)}
      />
    </FullPage>
  );
};

export default BreaksPage;
