import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Suspense } from "react";
import { useHubspot } from "hooks/useHubspot";
import { IntegrationPage } from "../components/Onboarding/IntegrationPage";
import { IntegrationStepsPage } from "../components/Onboarding/IntegrationStepsPage";

const RouteIntegrations = ({ match: { path } }: RouteComponentProps) => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Redirect exact strict from={`${path}/`} to={path} />
        <Redirect exact strict from={`${path}/:integrationName/`} to={`${path}/:integrationName`} />

        <Route exact strict path={path} component={IntegrationPage} />
        <Route exact strict path={`${path}/:integrationName`} component={IntegrationStepsPage} />
      </Switch>
    </Suspense>
  );
};

export default RouteIntegrations;
