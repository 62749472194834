import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  padding: 24px 24px var(--component-menu-item-toggle-indent-hpad, 32px) 24px;

  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  flex: 1 0 0;
`;

const Main = ({ children }: PropsWithChildren) => <Wrapper>{children}</Wrapper>;

export default Main;
