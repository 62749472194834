import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TablePage from "components/TablePage";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import { strIncludesCheck, getTitle } from "utils/common";
import { TableButtons, TableButton } from "components/styled/Page";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import CellWithEdit from "components/controls/CellWithEdit";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { TaskWithAssignees } from "types/models/projects";
import { iColumn } from "components/TableCommon";
import { iCellInfo } from "utils/tableHelpers";
import { GlobalContextEmployee } from "types/models/userProfile";
import { getTasksList, deactivateTask, updateTask, updateTaskAssignees } from "./projectsApiUtils";
import CellWithPopup from "./CellWithPopup";
import { OnSaveProp } from "./AssigneesDialog";
import { ProjectTaskAssignee } from "./projectsApiTypes";

const title = "Services Library";
const metaTitle = title;

const Wrapper = styled.div`
  margin-top: 32px;
  .rt-table .rt-th div {
    padding-inline-start: 15px;
  }
`;

const ServicesCellWrapper = styled.div`
  position: relative;

  & .cell-with-edit,
  & .cell-with-popup {
    display: inline-block;
    min-width: 230px;
    max-width: 530px;
  }
  & .buttons {
    top: 4px;
    margin-top: 0;
  }
`;

interface ProjectTasksTabProps extends WithTranslation {
  projectUuid: string;
  onNotification: (message: string | null, notificationType: NotificationType) => void;
}

interface ProjectTasksTabState {
  tasks: TaskWithAssignees[];
  selectedItem: TaskWithAssignees | null;
  confirmationPopupVisible: boolean;
  loaded: boolean;
  searchValue: string | null;
}

class ProjectTasksTab extends Component<ProjectTasksTabProps, ProjectTasksTabState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ProjectTasksTabProps) {
    super(props);
    const { t } = props;
    this.state = {
      tasks: [],
      selectedItem: null,
      confirmationPopupVisible: false,
      loaded: false,
      searchValue: null,
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    this.setState({ loaded: false });
    const { projectUuid, t } = this.props;
    const company = await this.context.getCompany();
    const [tasksRes] = await Promise.all([
      getTasksList({
        companyUuid: company.uuid,
        projectUuid,
      }),
    ]);
    const tasks = tasksRes?.content || [];
    const userProfilesByUuid: Record<string, EmployeeListUserProfile> = tasksRes?.metadata?.userProfilesByUuid || {};

    const tasksWithAssignees: TaskWithAssignees[] = tasks.map((task) => {
      const assignees: ProjectTaskAssignee[] = [
        ...task.userProfilesTasks.map((up) => {
          const userProfile = userProfilesByUuid[up.userProfileUuid];
          return {
            uuid: userProfile.uuid,
            title: userProfile.fullName,
            avatarId: userProfile.avatarId,
            subtitle: userProfile.position?.title || "",
            isServiceGroup: false,
          };
        }),
        ...task.services.map((service) => ({
          uuid: service.uuid,
          title: service.name,
          subtitle: t("Service"),
          isServiceGroup: true,
        })),
      ];
      return { ...task, assignees };
    });

    this.setState({
      tasks: tasksWithAssignees,
      loaded: true,
    });
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: ev.target.value });
  };

  updateTask = async ({ uuid, name, serviceUuids }: { uuid: string; name: string; serviceUuids: string[] }) => {
    const { projectUuid, t, onNotification } = this.props;
    const company = await this.context.getCompany();

    try {
      await updateTask({
        companyUuid: company.uuid,
        projectUuid,
        taskUuid: uuid,
        body: {
          content: {
            name,
            serviceUuids,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });
      onNotification(t("task-update-success", { taskName: name }), NotificationType.success);
    } catch (error: any) {
      onNotification(t("task-update-failed", { taskName: name }), NotificationType.error);
      throw new Error(error);
    }
  };

  shouldUpdateName = (name: string, originalName: string) => {
    const { t } = this.props;

    if (!name) {
      return [false, new Error(t(`${TranslationNamespaces.common}|Name can't be empty`))];
    }

    if (name === originalName) {
      return [false, null];
    }

    return [true, null];
  };

  onNameBlur = (name: string, task: TaskWithAssignees) => {
    const trimmedName = name.trim();
    const { uuid, services, name: originalName } = task;

    const [shouldUpdate, error] = this.shouldUpdateName(trimmedName, originalName);

    if (!shouldUpdate) {
      return error;
    }

    // update table data
    this.setState({
      tasks: this.state.tasks.map((val) => (val.uuid === uuid ? { ...val, name: trimmedName } : val)),
    });

    this.updateTask({
      uuid,
      name: trimmedName,
      serviceUuids: services.map((s) => s.uuid),
    }).catch(() =>
      // bring the original value back if error
      this.setState({
        tasks: this.state.tasks.map((val) => (val.uuid === uuid ? { ...val, name: originalName } : val)),
      }),
    );

    return null;
  };

  removeItem = async (item: TaskWithAssignees | null) => {
    const { t, onNotification } = this.props;
    this.setState({ selectedItem: null, confirmationPopupVisible: false });

    if (!item) {
      return;
    }

    try {
      await deactivateTask({
        companyUuid: window.global_store.company.uuid,
        projectUuid: item.projectUuid,
        taskUuid: item.uuid,
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });

      setTimeout(async () => {
        await this.getState();
      }, 1000);

      onNotification(t("task-delete-success", { taskName: item.name }), NotificationType.success);
    } catch (error) {
      onNotification(t("task-delete-failed", { taskName: item.name }), NotificationType.error);
    }
  };

  async onUpdateTaskAssignees(taskUuid: string, assignees: OnSaveProp): Promise<void> {
    const { t, onNotification } = this.props;
    try {
      await updateTaskAssignees({
        taskUuid,
        companyUuid: window.global_store.company.uuid,
        body: {
          content: {
            ...assignees,
            requestedBy: window.global_store.profile.uuid,
          },
        },
      });
      onNotification(t("task-assign-success"), NotificationType.success);
    } catch (error) {
      onNotification(t("task-assign-failed"), NotificationType.error);
      console.log(error);
    }
  }

  getTableColumns = (): iColumn<TaskWithAssignees>[] => {
    const { t } = this.props;

    const columns = [
      {
        label: t(`${TranslationNamespaces.common}|Name`),
        accessor: "name",
        style: { lineHeight: "36px", overflow: "initial" },
        Cell: (row: iCellInfo<TaskWithAssignees>) => (
          <CellWithEdit
            type="input"
            onBlur={(val: string) => this.onNameBlur(val, row.original)}
            initialValue={row.value}
            id={row.original.uuid}
            inputProps={{ placeholder: t(`${TranslationNamespaces.common}|Name`) }}
          />
        ),
      },
      {
        label: t("Assignees"),
        accessor: "assignees",
        style: { overflow: "visible" },
        Cell: (row: iCellInfo<TaskWithAssignees, ProjectTaskAssignee[]>) => (
          <ServicesCellWrapper>
            <CellWithPopup
              assignees={row.value || []}
              onUpdateTaskAssignees={(assignees) => this.onUpdateTaskAssignees(row.original.uuid, assignees)}
              id={row.original.uuid}
            />

            <TableButtons className="buttons">
              <TableButton
                onClick={() =>
                  this.setState({
                    selectedItem: row.original,
                    confirmationPopupVisible: true,
                  })
                }
              >
                {t(`${TranslationNamespaces.common}|Delete`)}
              </TableButton>
            </TableButtons>
          </ServicesCellWrapper>
        ),
      },
    ];
    return columns;
  };

  render() {
    const { tasks, searchValue, loaded, confirmationPopupVisible, selectedItem } = this.state;
    const { t } = this.props;
    const noItemsTitle = "No Tasks are available";

    const filteredItems = tasks.filter((s) => strIncludesCheck(s.name, searchValue || ""));

    return (
      <Wrapper>
        <TablePage
          rows={filteredItems}
          filters={
            <SearchInput
              modifiers={["filter"]}
              onChange={this.onSearch}
              placeholder={t(`${TranslationNamespaces.common}|Search`)}
              value={searchValue || ""}
            />
          }
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          columns={this.getTableColumns()}
          className="projects-table"
          loading={!loaded}
          noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          noScroll
        />
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Delete Task")}
            text={t("delete-task-confirmation")}
            buttonYesTitle={t("Yes, delete")}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            onClose={() => {
              this.setState({
                selectedItem: null,
                confirmationPopupVisible: false,
              });
            }}
            onYes={() => this.removeItem(selectedItem)}
          />
        </ModalDialog>
      </Wrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ProjectTasksTab);
