import { Component, MouseEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { createLoginRequest } from "utils/ssoApiUtils";
import { isDomainValid, urlParam } from "utils/common";
import TextInput from "components/controls/TextInputControlNew";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga, { GaLoginMethod } from "utils/ga";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";
import LoginButtons from "./LoginButtons";

interface SsoLoginPageProps extends RouteComponentProps, WithTranslation {}

interface SsoLoginPageState {
  domain: string;
  errors: Record<string, string> | null;
  loading: boolean;
}

class SsoLoginPage extends Component<SsoLoginPageProps, SsoLoginPageState> {
  readonly state: Readonly<SsoLoginPageState> = {
    domain: "",
    errors: null,
    loading: false,
  };

  componentDidMount() {
    const { t } = this.props;
    document.title = t("SSO Login");
  }

  onNextClick = (ev: MouseEvent) => {
    ev.preventDefault();
    const { domain: stateDomain } = this.state;
    let domain = stateDomain;
    if (stateDomain.match(/@/g)) {
      this.validateEmail();
      domain = domain.replace(/.*@/, "");
    }
    let errors: SsoLoginPageState["errors"] = {};

    if (!domain || !isDomainValid(domain)) {
      errors.domain = "Domain is invalid";
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
      this.submitForm(domain);
    } else {
      this.setState({ errors });
    }
  };

  validateEmail = () => {
    const { domain } = this.state;
    const { t } = this.props;

    let error: string | null = null;
    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (!domain) {
      error = t("Email Can't be Empty");
    } else if (!validate(domain)) {
      error = t("Email is incorrect");
    }

    return error;
  };

  submitForm = (domain: string) => {
    const { t } = this.props;

    this.setState({ loading: true, errors: null }, async () => {
      try {
        const params = urlParam("sso_debug") ? "debug=1" : "";
        const redirectUrl = localStorage.getItem("oitchau_redirect");
        const res = await createLoginRequest({ domainName: domain, callbackPath: redirectUrl || "/punches", params });

        if (res?.content?.loginUrl) {
          ga.trackLogin(GaLoginMethod.sso);

          localStorage.removeItem("oitchau_redirect");
          window.location = res.content.loginUrl as unknown as Location;
        } else {
          this.setState({ loading: false, errors: { domain: t("common|Something went wrong") } });
        }
      } catch (err) {
        const error = err as any;

        this.setState({
          errors: { domain: error?.message ? t(error.message) : t("common|Something went wrong") },
          loading: false,
        });
      }
    });
  };

  render() {
    const { domain, errors, loading } = this.state;
    const { t, history } = this.props;
    return (
      <MainPage
        topRowText={t("Don't have an account?")}
        topRowButtonText={t("GET STARTED")}
        topRowButtonClick={() => history.push("/signup")}
        customButtons={<LoginButtons primaryButtonText={t("Login")} onPrimaryButtonClick={this.onNextClick} />}
        pageTitle={t("SSO Login")}
        loading={loading}
        formParams={{ noValidate: true }}
      >
        <OnboardingFieldWrapper fieldName={t("DOMAIN NAME OR EMAIL")}>
          <TextInput
            error={!!errors?.domain}
            name="domain"
            onboarding
            value={domain}
            type="string"
            onChange={(ev) => this.setState({ domain: ev.target.value })}
          />
          {!!errors?.domain && <ErrorLabel>{errors.domain}</ErrorLabel>}
        </OnboardingFieldWrapper>
      </MainPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(SsoLoginPage);
