import styled from "styled-components";
import * as images from "components/svg-images";
import { useEffect, useState } from "react";

const Nav = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  inset-inline-end: 0;
  margin-inline-end: 5px;
  height: 36px;
  justify-content: space-between;
  align-items: center;
`;

const NavButton = styled.div<{ hasValue: boolean; isRTL: boolean; left?: boolean; right?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  cursor: ${(props) => (props.hasValue ? "pointer" : "default")};
  opacity: ${(props) => (props.hasValue ? "1" : "0.5")};
  border-radius: var(--shapes-border-radius-default);
  ${(props) => (props.left ? "padding-inline-end: 3px;" : "")}
  ${(props) => (props.right ? "padding-inline-start: 3px;" : "")}
  ${(props) => (props.isRTL ? "transform: rotate(180deg);" : "")}
  ${(props) =>
    props.hasValue
      ? `
  &:hover {
    background: #f7f9fc;
  }`
      : ""}
`;

const Separator = styled.div`
  height: 15px;
  background: #bac7db;
  width: 1px;
  margin: 0 8px;
`;

const LoadingIcon = styled.div`
  position: absolute;
  top: 0;
  inset-inline-end: 23px;
  width: 16px;
  height: 36px;
  background-image: ${(p) => p.theme.svg.loading_small};
  background-repeat: no-repeat;
  background-position: center;
  animation: loaderRoation 1s infinite linear;
`;

interface SearchNavProps<T> {
  prev: T | null;
  next: T | null;
  onClick: (data: T) => void;
  isLoading?: boolean;
}

const SearchNav = <T extends unknown>({ prev, next, onClick, isLoading }: SearchNavProps<T>) => {
  const { isRTL } = window.global_store;

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <Nav>
      <NavButton
        hasValue={!!prev}
        left={!isRTL}
        isRTL={isRTL}
        onClick={() => {
          if (prev) {
            onClick(prev);
          }
        }}
      >
        {images.calArrowLeft}
      </NavButton>
      <Separator />
      <NavButton
        hasValue={!!next}
        right={!isRTL}
        isRTL={isRTL}
        onClick={() => {
          if (next) {
            onClick(next);
          }
        }}
      >
        {images.calArrowRight}
      </NavButton>
    </Nav>
  );
};

export default SearchNav;

export const SearchNavClicker = <T extends unknown>({
  total,
  onClick,
  isLoading,
  initialIndex,
}: {
  total: number;
  onClick: (cursorIndex: number) => void;
  isLoading: boolean;
  initialIndex?: number;
}) => {
  const { isRTL } = window.global_store;
  const [cursor, setCursor] = useState(0);
  // reset cursor on each total change, which means that data set was changed
  useEffect(() => {
    setCursor(initialIndex && initialIndex > -1 ? initialIndex : 0);
  }, [total, initialIndex]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <Nav>
      <NavButton
        hasValue={cursor > 0}
        left={!isRTL}
        isRTL={isRTL}
        onClick={() => {
          if (cursor > 0) {
            const i = cursor - 1;
            onClick(i);
            setCursor(i);
          }
        }}
      >
        {images.calArrowLeft}
      </NavButton>
      <Separator />
      <NavButton
        hasValue={cursor < total - 1}
        right={!isRTL}
        isRTL={isRTL}
        onClick={() => {
          if (cursor < total - 1) {
            const i = cursor + 1;
            onClick(i);
            setCursor(i);
          }
        }}
      >
        {images.calArrowRight}
      </NavButton>
    </Nav>
  );
};
