enum Periods {
  thisYear = "thisYear",
  pastYear = "pastYear",
  thisMonth = "thisMonth",
  lastMonth = "lastMonth",
  last3Month = "last3Month",
  last6Month = "last6Month",
  last12Month = "last12Month",
  last24Month = "last24Month",
}

enum Groups {
  company = "company",
  subsidiaries = "subsidiary",
  departments = "department",
  postions = "postion",
  teams = "team",
}

enum AggregationGroupType {
  company = "company",
  userProfile = "userProfile",
  subsidiaries = "subsidiary",
  departments = "department",
  teams = "team",
}

enum Frequency {
  day = "day",
  week = "week",
  month = "month",
  none = "none",
  // payrollPeriods = "payrollPeriods",
}

enum DashboardFrequency {
  day = "day",
  week = "week",
  month = "month",
}

enum AggrerationType {
  sum = "sum",
  average = "average",
}

type PeriodAggregationData = {
  extraHoursMinutes: number;
  hoursBankMinutes: number;
  missedMinutes: number;
  plannedMinutes: number;
  workedMinutes: number;
  missedDay: number;
  weekend: number;
  paidAbsenceMinutes: number;
  invalidPunchesCount: number;
  pendingPunchesCount: number;
};

type PeriodAggregation = {
  periodLabel: string | null;
  groupUuid: string;
  groupName: string;
  groupSize: number;
  data: PeriodAggregationData;
};

type DashboardProcessedData<K extends keyof PeriodAggregationData> = {
  categories: { label: string }[];
  dataset: { [seriesname: string]: Pick<PeriodAggregationData, K>[] };
};

type DashboardChartData = {
  categories: { category: { label: string }[] }[];
  dataset: { seriesname: string; data: { value: number | string | undefined }[] }[];
  noData: boolean;
};

export { Periods, Groups, Frequency, DashboardFrequency, AggrerationType, AggregationGroupType };

export type { PeriodAggregation, DashboardProcessedData, PeriodAggregationData, DashboardChartData };
