// TODO: Replace it with our new Select component
import { Component } from "react";
import ClickOutside from "react-click-outside";
import styled from "styled-components";

import BEM from "utils/BEM";
import Option from "./Option";
import "./Select.scss";

const b = BEM.b("ui-select");

const Wrapper = styled.div`
  .ui-select__input {
    background-color: #fff;
    border: 1px solid var(--colors-surface-150);
    padding-inline-end: 24px;
    padding-inline-start: 16px;

    &:after {
      inset-inline-end: 16px;
    }
  }
  .ui-select__option_checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleOpen(open) {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;
    const alignDropdownRight =
      isOpen && this.refs.container && this.refs.container.getBoundingClientRect().right + 50 > window.innerWidth;

    this.setState({ isOpen, alignDropdownRight });
  }

  selectValue(optionValue) {
    let newValue;
    const { onChange, value = "" } = this.props;
    if (value.indexOf(optionValue) > -1) {
      newValue = value.filter((i) => i !== optionValue);
    } else {
      newValue = [...value, optionValue];
    }
    newValue = this.props.options.map((op) => (newValue.indexOf(op.value) > -1 ? op.value : "")).filter((op) => op);
    onChange && typeof onChange === "function" && onChange(newValue);
  }

  isActive(optionValue) {
    const { value } = this.props;
    return value && value.indexOf(optionValue) > -1;
  }

  renderSelectedLabel() {
    const { options } = this.props;
    const selectedOptions = options.filter((option) => this.isActive(option.value));
    return selectedOptions.map((option) => option.label || option.value).join(", ");
  }

  render() {
    const { value, placeholder, options, icon, disabled, locked } = this.props;
    const { alignDropdownRight, isOpen } = this.state;

    return (
      <Wrapper className={b({ icon: !!icon })} ref="container">
        <ClickOutside onClickOutside={() => this.toggleOpen(false)}>
          <div
            className={b("input", {
              icon: !!icon,
              open: isOpen,
              disabled,
              locked,
            })}
            style={{
              backgroundPosition: `calc(100% - 16px)`,
            }}
            onClick={() => !disabled && !locked && this.toggleOpen()}
          >
            {icon || this.renderSelectedLabel() || placeholder}
          </div>
          {isOpen && (
            <div className={b("option-list", { icon: !!icon, right: alignDropdownRight })}>
              {options.map((option) => (
                <Option
                  key={option.value}
                  onSelect={() => this.selectValue(option.value)}
                  label={option.label}
                  checkbox
                  disabled={option.disabled}
                  active={this.isActive(option.value)}
                />
              ))}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

// Select.propTypes = {
//   placeholder: PropTypes.string,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string,
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//     }),
//   ).isRequired,
//   value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
// };

export default Select;
