import { Component, createRef } from "react";
import ReactTooltip from "react-tooltip";
import TextInputControl from "components/controls/TextInputControlNew";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { searchLens } from "components/svg-images";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";

const TOOLTIP_DELAY = 1500;
const REQUEST_DELAY = 500;

const SearchControlWrapper = styled.div`
  position: relative;

  input {
    width: 240px;
    padding-inline-start: 40px;
  }

  input:placeholder-shown {
    background-color: var(--colors-surface-50);
    border-color: var(--colors-surface-50);
  }

  input::placeholder {
    color: var(--colors-activitiInfoBlockValue);
  }

  svg {
    position: absolute;
    top: 10px;
    inset-inline-start: 8px;
  }
`;

class EmployeeSearchControl extends Component {
  inputRef = createRef();
  state = {
    searchTerm: "",
    active: false,
  };
  changeTimeout = null;

  onChange = (searchTerm) => {
    const shouldMakeRequest = searchTerm.length === 0 || searchTerm.length > 2;
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout);
    }

    this.changeTimeout = setTimeout(
      () => {
        if (shouldMakeRequest) {
          this.props.onChange(searchTerm);
        } else {
          ReactTooltip.show(this.inputRef);
        }

        this.changeTimeout = null;
      },
      shouldMakeRequest ? REQUEST_DELAY : TOOLTIP_DELAY,
    );

    ReactTooltip.hide(this.inputRef);
    this.setState({ searchTerm });
  };

  onFocus = () => {
    this.setState({ active: true });
  };

  onBlur = () => {
    this.setState({ active: false });
  };

  render() {
    const { t } = this.props;
    const { searchTerm, active } = this.state;

    return (
      <SearchControlWrapper active={active}>
        <ReactTooltip id="employee-search-tooltip" place="top" effect="solid" event="none">
          {t("Add {{count}} more charaters", { count: 3 - searchTerm.length })}
        </ReactTooltip>

        <TextInputControl
          innerRef={(ref) => {
            this.inputRef = ref;
          }}
          data-tip
          data-for="employee-search-tooltip"
          placeholder={translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-search-employees",
            `${TranslationNamespaces.common}|Search Employee`,
          )}
          value={searchTerm}
          onChange={(e) => this.onChange(e.target.value)}
        />
        {searchLens}
      </SearchControlWrapper>
    );
  }
}

export default withTranslation()(EmployeeSearchControl);
