import { Component } from "react";
import ClickOutside from "react-click-outside";
import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox";
import * as images from "components/svg-images";
import rtl from "styled-components-rtl";

const Wrapper = styled.div<{ marginStart: number }>`
  position: relative;
  ${(p): string => `margin-inline-start: ${p.marginStart}px`};
  z-index: 1;
`;

const Icon = styled.div`
  background: #f3f5fa;
  border-radius: var(--shapes-border-radius-default);
  width: 36px;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ecf2fa;
  }
`;

const Dropdown = styled.div`
  padding: 24px 16px;
  position: absolute;
  top: 53px;
  ${rtl`
    left: 0;
  `}
  background: var(--colors-default);
  box-shadow: -3px 10px 30px 0 rgba(129, 147, 171, 0.2);
  border-radius: 3px;
  width: 300px;
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    ${rtl`
      left: 7px;
    `}
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--colors-default);
  }
`;

const FieldRow = styled.div<{ withoutPadding: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props): string => (props.withoutPadding ? "0" : "22px")};
`;

const CheckboxWrapper = styled.div`
  margin-inline-end: 10px;
`;

interface AdditionalFiltersOptions {
  label: string;
  checked: boolean | undefined;
  onChange: (checked: boolean) => void;
  hide?: boolean;
}

interface AdditionalFiltersControlProps {
  options: AdditionalFiltersOptions[];
  closeOnChange: boolean;
  marginStart?: number;
}

interface AdditionalFiltersControlState {
  isOpen: boolean;
}

class AdditionalFiltersControl extends Component<AdditionalFiltersControlProps, AdditionalFiltersControlState> {
  static defaultProps: {
    closeOnChange: true;
  };

  readonly state = {
    isOpen: false,
  };

  toggleOpen = (open?: boolean): void => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    this.setState({ isOpen });
  };

  render(): JSX.Element {
    const { isOpen } = this.state;
    const { options, closeOnChange, marginStart = 20 } = this.props;

    if (!options?.length) return null;

    return (
      <Wrapper marginStart={marginStart}>
        <ClickOutside onClickOutside={(): void => this.toggleOpen(false)}>
          <Icon onClick={(): void => this.toggleOpen()}>{images.filtersIcon}</Icon>
          {isOpen && (
            <Dropdown>
              {options
                .filter((o) => !o.hide)
                .map((o, i, filteredOptions) => (
                  <FieldRow key={o.label} withoutPadding={i === filteredOptions.length - 1}>
                    <CheckboxWrapper>
                      <CheckboxControl
                        checked={o.checked}
                        label={o.label}
                        onChange={(checked: boolean): void => {
                          if (closeOnChange) {
                            this.toggleOpen(false);
                          }

                          o.onChange(checked);
                        }}
                      />
                    </CheckboxWrapper>
                  </FieldRow>
                ))}
            </Dropdown>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default AdditionalFiltersControl;
