import { stylesheet } from "astroturf";

export const styles = stylesheet`
.Wrapper {
  position: absolute;
  height: 10px;
  width: 10px;
  bottom: 10px;
  inset-inline-end: 10px;
  background-color: var(--colors-borderColor2);
  border-radius: 50%;
}
`;

export function SyncIndicator() {
  return <div className={styles.Wrapper} />;
}
