import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  BusinessRuleGroup,
  BusinessRuleGroupStatus,
  BusinessRuleType,
  OnCallsCompensationType,
} from "types/models/businessRulesGroup";
import { NotificationType } from "types/common";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import FieldWrapper from "components/UI/FieldWrapper";
import { updateBRGroupSettings } from "utils/apiHelpers";
import { Subheader } from "./styled";

const Wrapper = styled.div`
  width: 420px;
`;

interface PunchingRulesGroupOnCallProps {
  id: string;
  onSave: (data: { notification: string; notificationType: NotificationType }) => void;
  group: BusinessRuleGroup | null;
}

const PunchingRulesGroupOnCall = ({ id, onSave, group }: PunchingRulesGroupOnCallProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  const onCallsRule = group?.businessRules?.[0]?.rules?.onCalls;
  const [compensationType, setCompensationType] = useState<OnCallsCompensationType | "">(
    onCallsRule?.compensation || "",
  );
  const [isLoading, setIsloading] = useState(false);

  const inactive = !group || group.status !== BusinessRuleGroupStatus.active || group.isLocked;
  const compensationOptions = [
    { value: OnCallsCompensationType.noCompensation, label: t("No compensation") },
    { value: OnCallsCompensationType.extraHours, label: t("Convert to Extra Hours") },
  ];

  const onSubmit = async () => {
    setIsloading(true);

    try {
      await updateBRGroupSettings({
        companyUuid: window.global_store.company.uuid,
        businessRulesType: BusinessRuleType.onCalls,
        // no save button and onSubmit when group is null
        brGroupUuid: (group as BusinessRuleGroup).uuid,
        body: {
          content: {
            // save button is disabled when compensationType is empty string
            compensation: compensationType as OnCallsCompensationType,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });

      onSave({ notification: t("Group has been updated!"), notificationType: NotificationType.success });
    } catch (e) {
      onSave({ notification: (e as Error)?.message || String(e), notificationType: NotificationType.error });
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Wrapper>
      <Subheader style={{ margin: "0" }}>{t("On-Call Working Time")}</Subheader>

      <FieldWrapper fieldName={t("Compensation")} width="330px" fieldTitleMarginTop={16} marginBottom={24}>
        <Select
          value={compensationType}
          options={compensationOptions}
          onChange={setCompensationType}
          disabled={inactive}
          locked={false}
          modifiers={{ field: true }}
        />
      </FieldWrapper>

      {!inactive && (
        <Button
          style={{ width: 155 }}
          state={ButtonState.primary}
          loading={isLoading}
          onClick={onSubmit}
          value={t("Save")}
          disabled={!compensationType}
        />
      )}
    </Wrapper>
  );
};

export default PunchingRulesGroupOnCall;
