import styled from "styled-components";
import Avatar from "../views/Avatar";

const Wrapper = styled.div``;

const NamePosition = styled.div`
  display: inline-block;
  margin-inline-start: 20px;
  width: 77%;
  line-height: 18px;
  vertical-align: middle;
`;

const Name = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-surface-800);
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
`;

const Position = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-unknown10);
  font-size: var(--typography-font-size-default);
`;

interface TableUserProfileProps {
  position?: { title: string } | null;
  fullName?: string;
  name?: string;
  avatarId: string | null;
}

const TableUserProfile = <P extends TableUserProfileProps>({ position, fullName, name, avatarId }: P) => (
  <Wrapper>
    <Avatar user={{ fullName: fullName || name, avatarId }} />
    <NamePosition>
      <Name>{fullName || name}</Name>
      {position && <Position>{position.title}</Position>}
    </NamePosition>
  </Wrapper>
);

export default TableUserProfile;
