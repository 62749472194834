import { sendRequest } from "utils/apiHelpers";
import { APIEmptyResponse, APIResponse } from "utils/api/common";
import CONFIG from "../config";
import { CreateLoginRequestRequestData, CreateLoginRequestResponseData } from "./api/types";

type TODOrequestData = never;
type TODOresponseData = Promise<unknown>;

export async function createDomain({ domainName, companyUuid, createdBy }: TODOrequestData): APIEmptyResponse {
  try {
    await sendRequest(
      {
        content: {
          domainName,
          createdBy,
        },
      },
      "POST",
      `/companies/${companyUuid}/domains`,
      CONFIG.client_admin_api,
    );
  } catch (error) {
    console.log(error);
  }
}
export async function deleteDomain({ domainName, companyUuid, deletedBy }: TODOrequestData): APIEmptyResponse {
  try {
    await sendRequest(
      {
        content: {
          deletedBy,
        },
      },
      "DELETE",
      `/companies/${companyUuid}/domains/${domainName}`,
      CONFIG.client_admin_api,
    );
  } catch (error) {
    console.log(error);
  }
}

export function listDomains({ companyUuid }: TODOrequestData): TODOresponseData {
  return sendRequest({}, "GET", `/companies/${companyUuid}/domains`, CONFIG.client_admin_api);
}

export function getIdentityProviderByDomainName({ companyUuid, domainName }: TODOrequestData): TODOresponseData {
  return sendRequest({}, "GET", `/companies/${companyUuid}/identity_providers/${domainName}`, CONFIG.client_admin_api);
}

export function upsertIdentityProviderByDomainName({
  companyUuid,
  domainName,
  settings,
  updatedBy,
}: TODOrequestData): TODOresponseData {
  return sendRequest(
    {
      content: {
        method: "SAML",
        settings,
        updatedBy,
      },
    },
    "PUT",
    `/companies/${companyUuid}/identity_providers/${domainName}`,
    CONFIG.client_admin_api,
  );
}

export function createLoginRequest({
  domainName,
  callbackPath,
  params,
}: CreateLoginRequestRequestData): APIResponse<CreateLoginRequestResponseData> {
  return sendRequest(
    {
      content: {
        callbackPath,
      },
    },
    "POST",
    `/login/${domainName}/login_request${params ? `?${params}` : ""}`,
    `${CONFIG.api_admin}/v2/public/sso`,
  );
}

export function getDownloadCertificateUrl({ domainName, format }: TODOrequestData): void {
  window.location = `${CONFIG.protocol}${CONFIG.api_admin}/v2/public/sso/certificates/${domainName}/${format}`;
}

export function handleGoogleSignUp(access_token: string): APIResponse<{
  email: string;
  userToken?: string;
  fullName: string;
  id: number;
  createdAt: string;
}> {
  return sendRequest({ access_token }, "POST", `/v2/public/sso/provider/google/signup-callback`, CONFIG.api_admin);
}

export function handleGoogleLogin(access_token: string): APIResponse<{
  token?: string;
  email: string;
  userToken?: string;
}> {
  return sendRequest({ access_token }, "POST", `/v2/public/sso/provider/google/login-callback`, CONFIG.api_admin);
}

export function checkEmailExistence(email: string): APIResponse<boolean> {
  return sendRequest({ email }, "POST", `/v2/public/sso/user/check-existence`, CONFIG.api_admin);
}
