import momentTz from "moment-timezone";
import { WithTranslation, TFunction } from "react-i18next";
import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";
import CONFIG from "config";
import { CompanyTaxPayerTypes, EmployeeTaxPayerTypes } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import getSymbolFromCurrency from "currency-symbol-map";
import { PhaseDayType } from "types/models/businessRulesGroup";
import { DSR_PHASE_KEY } from "components/Payroll/types";
import i18n from "i18next";

export { PermissionSectionName } from "types/models/permissions";

export function formatPhone(value: string, formatInternational?: boolean): string {
  const phoneNumber = parsePhoneNumber(value);
  let parsedPhoneNumber = value;

  if (phoneNumber) {
    parsedPhoneNumber = formatInternational ? phoneNumber.formatInternational() : phoneNumber.formatNational();
  }

  return parsedPhoneNumber;
}

export function formatCnpj(value = ""): string {
  let formattedValue = "";
  // XX.XXX.XXX/XXXX-XX
  // 42.184.381/0001-56
  formattedValue = value.replace(/(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, (_, p1, p2, p3, p4, p5) => {
    let output = "";
    if (p1) output = `${p1}.`;
    if (p2) output += `${p2}.`;
    if (p3) output += `${p3}/`;
    if (p4) output += `${p4}-`;
    if (p5) output += `${p5}`;
    return output;
  });

  return formattedValue;
}

/**
 * Function to covert HH:mm time to minutes
 * @param time in format "00:00"
 * @returns time in minutes
 */
export function hrsMinstoMins(time: string): number {
  if (time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
    return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
  }
  return 0;
}

/**
 * Take integer number of minutes from 00:00 (can be negative).
 * Return HH:mm formatted time string.
 */
export function minsToHrsMins(mins: number, skipZero = false): string {
  let prefix = "";
  let totalMins = mins;

  if (totalMins < 0) {
    totalMins = Math.abs(mins);
    prefix = "-";
  }

  const hours = Math.floor(totalMins / 60);
  // if (h > 24) {
  //   h = h - 24
  // }
  const minutes = totalMins % 60;
  // if (h === 24 && m > 0) {
  //   h = 0
  // }
  const hoursPrefix = skipZero ? "" : "0";
  const h = hours < 10 ? `${hoursPrefix}${hours}` : hours;
  const m = minutes < 10 ? `0${minutes}` : minutes;

  return `${prefix}${h}:${m}`;
}

export function minsToHrsMinsLong(mins: number, t: TFunction): string {
  const HH = Math.floor(mins / 60);
  const mm = mins % 60;
  const str = t("{{HH}}h {{mm}}m", { HH, mm });
  return str;
}

export function hrsMinsToMins(hrsMins: string): number {
  const sign = hrsMins[0] === "-" ? -1 : 1;
  const [h, m] = hrsMins.split(":");
  const hours = Number.parseInt(h, 10);
  const minutes = Number.parseInt(m, 10);
  const totalMins = hours * 60 + minutes * sign;
  return totalMins;
}

export function formatNumberShort(num: number, t: TFunction) {
  if (num === 0) {
    return "0";
  }
  const sizes = ["", "K", "M", "B", "T", "Qa", "Qi"];
  const k = 1000;
  const i = Math.floor(Math.log(num) / Math.log(k));

  const short = num / k ** i;
  const size = sizes[i];
  const str = t(`{{number}}${size}`, { number: short.toLocaleString(undefined, { maximumFractionDigits: 1 }) });
  return str;
}

/**
 * Examples:
 * 2022-01-27, 2022-01-29, today 2022-01-05 -> 27 - 29 Jan
 * 2022-01-27, 2022-02-20, today 2022-01-05 -> 27 Jan - 20 Feb
 * 2021-01-27, 2021-02-20, today 2022-01-05 -> 27 Jan - 20 Feb 2021
 * 2020-01-27, 2021-02-20, today 2021-01-05 -> 27 Jan 2020 - 20 Feb 2021
 * 2020-01-27, 2021-02-20, today 2020-01-05 -> 27 Jan 2020 - 20 Feb 2021
 */
export function formatRange(startDateStr: string, endDateStr: string, todayDateStr: string) {
  const startDate = moment(startDateStr, "YYYY-MM-DD");
  const endDate = moment(endDateStr, "YYYY-MM-DD");
  const todayDate = moment(todayDateStr, "YYYY-MM-DD");

  const isSameYear = startDate.isSame(endDate, "year");
  const isSameMonth = startDate.isSame(endDate, "month");
  const isCurrentYear = isSameYear && startDate.isSame(todayDate, "year");

  if (isCurrentYear && isSameYear && isSameMonth) {
    return `${startDate.format("D")} – ${endDate.format("D MMM")}`;
  }
  if (isCurrentYear && isSameYear && !isSameMonth) {
    return `${startDate.format("D MMM")} – ${endDate.format("D MMM")}`;
  }
  if (!isCurrentYear && isSameYear && isSameMonth) {
    return `${startDate.format("D")} – ${endDate.format("D MMM YYYY")}`;
  }
  if (!isCurrentYear && isSameYear && !isSameMonth) {
    return `${startDate.format("D MMM")} – ${endDate.format("D MMM YYYY")}`;
  }
  return `${startDate.format("D MMM YYYY")} – ${endDate.format("D MMM YYYY")}`;
}

/**
 * Format date to a short format without year if in current year depending on locale.
 */
export function formatDateShort(date: string, currentYear: string = moment().format("YYYY")) {
  const m = moment(date, "YYYY-MM-DD");
  const short = m
    .format("ll")
    .split(", ")
    .filter((part) => part !== currentYear)
    .join(", ");
  return short;
}

export function getDateWithTZ(date: string, tz: string): moment.Moment {
  const dateWithTz = momentTz.tz(date, tz);

  // momentTZ doesn't support locale.
  // adding utc offset to operate with regular moment object
  return moment(dateWithTz.format()).utcOffset(dateWithTz.utcOffset());
}

export function urlParam(name: string): string | 0 | null {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);

  if (results == null) {
    return null;
  }

  return decodeURIComponent(results[1]) || 0;
}

export function getImgUrl(imgId: string, transformations = ""): string {
  return CONFIG.cloudinaryHost + transformations + imgId;
}

function fallbackCopyTextToClipboard(text: string): void {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback. Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string): void {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  void navigator.clipboard.writeText(text);
}

export function fireEvent<D = Record<string, unknown>>(eventName: string, data?: D): void {
  const event = new CustomEvent<D>(eventName, { bubbles: true, detail: data || ({} as D) });
  // it is not asynchronous
  document.dispatchEvent(event);
}

// TODO =interface=
interface iRole {
  predefined: boolean;
  name: string;
}

export function translateRole(t: WithTranslation["t"], role: iRole): string {
  if (role.predefined) {
    return t(role.name);
  }

  return role.name;
}

export function hasAdminAccess(): boolean {
  return (
    window.global_store.profile.role === "employer" ||
    window.global_store.profile.role === "supervisor" ||
    (window.global_store.profile.permission_roles &&
      (window.global_store.profile.permission_roles.some((r) => r.name.toLowerCase() === "admin") ||
        window.global_store.profile.permission_roles.some((r) => r.name.toLowerCase() === "hr")))
  );
}

export function isSupervisor(): boolean {
  return (
    window.global_store.profile?.role === "supervisor" ||
    window.global_store.profile?.permission_roles?.some?.((pr) => pr.name.toLocaleLowerCase() === "supervisor")
  );
}

type tDayMasksObj = { [key: string]: string };
export function getPhasesDays({
  t,
  isDayTypeBasedOnSchedule,
  withDsrDay = true,
}: {
  t: WithTranslation["t"];
  isDayTypeBasedOnSchedule: boolean;
  withDsrDay?: boolean;
}): { daysMasksObj: tDayMasksObj; order: string[] } {
  let daysMaskOrDaysType = [];

  if (isDayTypeBasedOnSchedule) {
    daysMaskOrDaysType = [PhaseDayType.working, PhaseDayType.nonWorking, PhaseDayType.dsr];
  } else {
    // Each char represents day of the week, starting from Monday. The last one represents "holidays".
    daysMaskOrDaysType = [
      "11111000",
      "11111100",
      "11110010",
      "11111111",
      "00000001",
      "00001100",
      "00000011",
      "00000111",
      "10000000",
      "01000000",
      "00100000",
      "00010000",
      "00001000",
      "00000100",
      "00000010",
    ];
    if (withDsrDay) {
      daysMaskOrDaysType.push(DSR_PHASE_KEY);
    }
  }

  const daysMasksObj: tDayMasksObj = {};

  daysMaskOrDaysType.map((d) => {
    daysMasksObj[d] = t(`${TranslationNamespaces.phases}|${d}`);
    return d;
  });

  return { daysMasksObj, order: daysMaskOrDaysType };
}

export function showSnackbar({
  text,
  notificationStyle,
}: {
  text: string;
  notificationStyle?: "notice" | "error";
}): null {
  const x = document.getElementById("snackbar");
  if (!x) {
    return null;
  }

  (x.children[0] as HTMLElement).innerText = text;

  x.classList.remove("style-notice", "style-error");

  if (notificationStyle) {
    x.classList.add(`style-${notificationStyle}`);
  }

  x.classList.add("show");
  setTimeout(() => {
    x.classList.remove("show");
  }, 3000);

  return null;
}

export function validatePhoneNumber(value: string, canBeEmpty?: boolean): string {
  let error = "";

  if (!canBeEmpty && !value) {
    error = "Phone can't be empty";
  } else if (value.length <= 5) {
    error = "Phone number is incorrect";
  } else if (value.indexOf("55") === 0 && value.length < 13) {
    error = "Phone number is incorrect";
  } else if (value.indexOf("55") === 0 && value.substr(4, 1) !== "9") {
    error = "Please put a valid Mobile Number";
  } else {
    error = "";
  }

  return error;
}

export function isDomainValid(domainName: string): boolean {
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domainName);
}

// TODO: use it in all places where we have email validation
export function isEmailValid(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function getSystemName(): string {
  return CONFIG.globalMode ? "Day.io" : "Oitchau";
}

export function getTitle(value: string | JSX.Element): string {
  return `${value} - ${getSystemName()}`;
}

export function hasPermisionAccess(permission: PermissionSectionName): boolean {
  const { profile } = window.global_store;

  return profile?.permissions?.includes && profile.permissions.includes(permission);
}

export function hasEmployeesAccess(): boolean {
  return hasPermisionAccess(PermissionSectionName.employees);
}

export function hasProjectsAccess(): boolean {
  return hasPermisionAccess(PermissionSectionName.projects);
}

export interface EmployeeWithLastLockDate {
  last_lock_date?: string;
  lastLockDate?: string;
}
export function getLastLockDate<E extends EmployeeWithLastLockDate>(employee: E): string | undefined {
  return employee?.last_lock_date || employee?.lastLockDate;
}

export function strIncludesCheck(fullString: string, partString: string): boolean {
  const cleanedFullString = fullString
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
  const cleanedPartString = partString
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

  return cleanedFullString.indexOf(cleanedPartString) > -1;
}

export function getStoredAuthToken(): string | null {
  return CONFIG.newAuth ? localStorage.getItem("oi_auth") : "";
}

// TODO =interface=
interface iAuthorizationToken {
  token: string;
  userProfileUuid: string;
}

type ApiResponse = {
  authorization_tokens: iAuthorizationToken[];
  selected_user_profile_uuid?: string;
};

export function getAuthTokenFromApiResponse(resp: ApiResponse): string {
  let token = "";

  if (
    CONFIG.newAuth &&
    resp.authorization_tokens &&
    resp.selected_user_profile_uuid &&
    resp.authorization_tokens.filter
  ) {
    const match = resp.authorization_tokens.filter(
      (pair) => pair.userProfileUuid === resp.selected_user_profile_uuid,
    )[0];

    if (match) {
      token = match.token;
    }
  }

  return token;
}

export type iUrlParamsData = {
  page: any; // TODO type
  perPage: number;
  employeeId: string | number; // todo should be number
  per_page: any; // TODO type
  employeeUuid: string;
  teamUuid: string;
  departmentUuid: string;
  subsidiaryUuid: string;
  punch_type: string; // TODO punch types
  request_type: string; // TODO request types
  verificationMethod: string; // TODO verificationMethod types
  is_manual: boolean;
  status: string; // TODO status types
  from: string;
  to: string;
  scope: string;
  format: string;
  locationUuid: string;
  projectUuid: string;
  clientUuid: string;
  groupUuid: string;
  newHierarchyPermissions: boolean;
};

export function generateUrlParams(data: Partial<iUrlParamsData> = {}): string {
  let url = "";
  if (typeof data.page !== "undefined") {
    url += `page=${data.page}&perPage=${data.perPage || 20}&`;
  }
  if (data.employeeId) {
    url += `employee_id=${data.employeeId}&`;
  }
  if (data.per_page) {
    url += `per_page=${data.per_page}&`;
  }
  if (data.employeeUuid) {
    url += `user_profile_uuid=${data.employeeUuid}&`;
  }
  if (data.teamUuid) {
    url += `team_uuid=${data.teamUuid}&`;
  }
  if (data.departmentUuid) {
    url += `department_uuid=${data.departmentUuid}&`;
  }
  if (data.subsidiaryUuid) {
    url += `subsidiary_uuid=${data.subsidiaryUuid}&`;
  }
  if (data.punch_type) {
    url += `punch_type=${data.punch_type}&`;
  }
  if (data.request_type) {
    url += `request_type=${data.request_type}&`;
  }
  if (data.verificationMethod && data.verificationMethod === "manual") {
    url += "is_manual=true&";
  }
  if (data.verificationMethod && data.verificationMethod !== "manual") {
    url += `verified_by_type=${data.verificationMethod}&`;
  }
  if (data.is_manual) {
    url += `is_manual=${data.is_manual}&`;
  }
  if (data.status) {
    url += `status=${data.status}&`;
  }
  if (data.from) {
    url += `from=${data.from}&`;
  } else {
    url += "from=1970-01-01&";
  }
  if (data.to) {
    url += `to=${data.to}&`;
  } else {
    url += `to=${moment().format("YYYY-MM-DD")}&`;
  }
  if (data.scope) {
    url += `scope=${data.scope}&`;
  }
  if (data.format) {
    url += `report_format=${data.format}&`;
  }
  if (data.locationUuid) {
    url += `location_uuid=${data.locationUuid}&`;
  }
  if (data.projectUuid) {
    url += `project_uuid=${data.projectUuid}&`;
  }
  if (data.clientUuid) {
    url += `client_uuid=${data.clientUuid}&`;
  }
  if (data.groupUuid) {
    url += `group_uuid=${data.groupUuid}&`;
  }
  if (data.newHierarchyPermissions) {
    url += "new_hierarchy_permissions=true";
  }

  return url;
}

export async function sleep(interval: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, interval);
  });
}

export function getCompanyTaxPayerType(country: string): CompanyTaxPayerTypes {
  const defaultCompanyTaxPayerField = CompanyTaxPayerTypes.cnpj;

  const taxPayerIdsMap: Record<string, CompanyTaxPayerTypes> = {
    BR: CompanyTaxPayerTypes.cnpj,
    MX: CompanyTaxPayerTypes.mxid,
    CO: CompanyTaxPayerTypes.coid,
    IL: CompanyTaxPayerTypes.hp,
    US: CompanyTaxPayerTypes.ein,
    ES: CompanyTaxPayerTypes.cif,
  };

  return taxPayerIdsMap[country] || defaultCompanyTaxPayerField;
}

export function getCompanyTaxIdTranslation(taxPayerIdKey: CompanyTaxPayerTypes, t: WithTranslation["t"]): string {
  if (taxPayerIdKey === CompanyTaxPayerTypes.coid) {
    return t(`${TranslationNamespaces.common}|CO tax id`);
  }
  if (taxPayerIdKey === CompanyTaxPayerTypes.mxid) {
    return t(`${TranslationNamespaces.common}|MX tax id`);
  }
  return taxPayerIdKey.toUpperCase();
}

export function getEmployeeTaxPayerType(country: string): EmployeeTaxPayerTypes {
  const defaultEmployeeTaxPayerField = EmployeeTaxPayerTypes.tpid;

  const taxPayerIdsMap: Record<string, EmployeeTaxPayerTypes> = {
    BR: EmployeeTaxPayerTypes.cpf,
    MX: EmployeeTaxPayerTypes.rfc,
    CO: EmployeeTaxPayerTypes.nit,
    IL: EmployeeTaxPayerTypes.tz,
    US: EmployeeTaxPayerTypes.ssn,
    ES: EmployeeTaxPayerTypes.ss,
  };

  return taxPayerIdsMap[country] || defaultEmployeeTaxPayerField;
}

export function getEmployeeTaxIdTranslation(taxPayerIdKey: EmployeeTaxPayerTypes, t: WithTranslation["t"]): string {
  return t(`${TranslationNamespaces.common}|${taxPayerIdKey.toUpperCase()}`);
}

export function chunk(array: any[], size: number): any[] {
  if (!array) return [];
  const firstChunk = array.slice(0, size);
  if (!firstChunk.length) {
    return array;
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}

export function getValueWithCurrency(val?: string | number) {
  if (val) {
    return `${
      getSymbolFromCurrency(window.global_store.company.currency) || window.global_store.company.currency
    } ${val}`;
  }
  return "";
}

export const getCrossShiftsPhaseLabel = (name: string, limit: string) => {
  const phaseLabel =
    limit === "-1"
      ? i18n.t(`${TranslationNamespaces.payroll}|cross-shifts-phase-unlimited`, { name })
      : i18n.t(`${TranslationNamespaces.payroll}|cross-shifts-phase`, {
          name,
          limit: minsToHrsMins(parseInt(limit, 10)),
        });

  return phaseLabel;
};

export const appendScript = (options: Partial<HTMLElementTagNameMap["script"]> & { callback?: () => void }) => {
  const { async, src, id, defer, callback } = options;

  if (id && document.getElementById(id)) {
    return;
  }

  const script = document.createElement("script");
  script.src = src!;
  script.async = !!async;
  script.defer = !!defer;

  if (id) {
    script.id = id;
  }

  if (callback) {
    script.onload = callback;
  }

  document.body.appendChild(script);
};
