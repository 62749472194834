import { ChangeEvent, Component, FocusEvent } from "react";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import { detectCountry } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import { formatPhone } from "../utils/common";
import BEM from "../utils/BEM";
import CONFIG from "../config";
import "react-phone-input-2/lib/style.css";

const input = BEM.b("text-input-control");

const Wrapper = styled.div`
  direction: ltr !important;
  .search {
    display: flex;
    justify-content: flex-start;
    padding-inline-end: 0;
    padding-inline-start: 10px;
    margin-inline-start: 6px;
    margin-inline-end: 0;
  }

  .react-tel-input input {
    font-family: var(--typography-font-family-default);
  }

  .search-box {
    margin: 0;
    margin-left: 0;
    margin-inline-start: 0;
    max-width: 90%;
  }

  .country .flag {
    margin-inline-end: 7px;
  }

  .dial-code {
    margin: 0 4px;
  }

  .country {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .flag-dropdown {
    .selected-flag {
      background: #fff;
    }
  }
`;

interface PhoneNumberFieldProps {
  value: string;
  countryCode?: string;
  isValid: boolean;
  onRef?: (ref: PhoneNumberField) => void;
  detectCountryCode?: unknown;
  /** country is available only with newField prop */
  onPhoneChange?: (value: string, country?: string) => void;
  onBlur?: (ev: FocusEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  name?: string;
  newField?: boolean;
  disabled?: boolean;
  onboarding?: boolean;
}

interface PhoneNumberFieldState {
  detectedCountry: string | undefined;
  value: string;
  formattedPhone: string;
  isValid: boolean;
}

class PhoneNumberField extends Component<PhoneNumberFieldProps, PhoneNumberFieldState> {
  constructor(props: PhoneNumberFieldProps) {
    super(props);
    const value = props.value || "";

    let formattedPhone = "";
    if (value) {
      formattedPhone = formatPhone(value);
    }

    let detectedCountry = CONFIG.globalMode ? "us" : "br";
    if (props.countryCode) {
      detectedCountry = props.countryCode;
    }

    this.state = {
      detectedCountry,
      value,
      formattedPhone,
      isValid: props.isValid,
    };
  }

  componentDidMount() {
    const { detectCountryCode, onRef } = this.props;
    onRef?.(this);

    if (detectCountryCode) {
      void this.getDetectedCountry();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PhoneNumberFieldProps) {
    if (nextProps.value !== this.state.value || nextProps.isValid !== this.state.isValid) {
      const state: Partial<PhoneNumberFieldState> = {
        value: nextProps.value,
        isValid: nextProps.isValid,
      };

      if (nextProps.value) {
        state.formattedPhone = formatPhone(nextProps.value);
      }

      this.setState(state);
    }

    if (this.props.countryCode !== nextProps.countryCode) {
      this.setState({ detectedCountry: nextProps.countryCode });
    }
  }

  getDetectedCountry = async () => {
    try {
      const { country_code } = await detectCountry();

      this.setState({ detectedCountry: country_code.toLowerCase() });
    } catch (error) {
      Sentry.sendError(error);

      this.setState({ detectedCountry: CONFIG.globalMode ? "us" : "br" });
    }
  };

  getValue = () => this.state.value;

  valueChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { onPhoneChange } = this.props;
    const value = ev.target.value.replace(/\D/g, "").substring(0, 11);
    const formattedPhone = formatPhone(value);

    this.setState({
      value,
      formattedPhone,
    });
    onPhoneChange?.(value);
  };

  onChange = (value: string, country: string) => {
    const { onPhoneChange } = this.props;
    /*
      type Country = {
        name: "Ukraine",
        dialCode: "380",
        countryCode: "ua",
        format: "+... (..) ... .. ..",
      }
     */

    this.setState({
      value,
      formattedPhone: value,
    });

    onPhoneChange?.(value, country);
  };

  render() {
    const { formattedPhone, isValid, detectedCountry } = this.state;
    const { autoComplete, name, newField, disabled, onboarding, onBlur } = this.props;
    const classNameArr = ["form-control"];

    if (!isValid) {
      classNameArr.push("field-error");
    }
    if (onboarding) {
      classNameArr.push("onboarding");
    }
    const className = classNameArr.join(" ");

    if (newField) {
      return (
        <Wrapper>
          <PhoneInput
            containerClass={onboarding ? "onboarding" : ""}
            inputProps={{
              className,
              required: true,
              name: name || "phone",
            }}
            countryCodeEditable={false}
            autoFormat
            enableSearch
            disableSearchIcon
            excludeCountries={["ru"]}
            country={detectedCountry}
            value={formattedPhone}
            disabled={disabled}
            onChange={this.onChange}
            onBlur={(ev) => {
              if (onBlur) {
                onBlur(ev);
              }
            }}
          />
        </Wrapper>
      );
    }

    return (
      <input
        className={input({ error: !isValid, onboarding, "has-value": !!formattedPhone })}
        onBlur={(ev) => {
          if (onBlur) {
            onBlur(ev);
          }
        }}
        autoComplete={autoComplete}
        type="text"
        name={name || "phone"}
        value={formattedPhone}
        onChange={this.valueChange}
        placeholder="(xx) xxxxx-xxxx"
      />
    );
  }
}
export default PhoneNumberField;
