import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import CONFIG from "config";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAsyncCallback } from "utils/useAsyncEffect";
import moment, { Moment } from "moment";
import { GlobalContext } from "context/GlobalContextProvider";
import { ActivityStatuses } from "types/models/activity";
import { GlobalStoreCompany } from "types/models/company";
import { PermissionSectionName } from "types/models/permissions";
import SearchControl, { AllSearchObjects, SearchControlOnChangeData } from "components/UI/SearchControlNew";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { dowDict } from "utils/get-week-start";
import { getSchedulesForUserProfile } from "utils/api/schedule";
import { getAllPunches, getEmployee } from "utils/apiHelpers";
import { RequestApprovalFlowStatus } from "types/models/request";
import { getOnCalls, getRequestsList } from "utils/api/company";
import { googleCalendarSync } from "@mobiscroll/calendar-integration";
import { translateEmployeeTerm } from "utils/translationHelpers";
import TimesheetDateRangePicker from "../Timesheets/components/TimesheetDateRangePicker";
import { useActivitiesStore } from "./activities.store";
import { createActivities, getActivitiesList } from "../Projects/projectsApiUtils";
import CheckboxControl from "../UI/CheckboxControl";
import { ReactComponent as DotsIcon } from "./icons/dots.svg";
import { ModeControlsDropdown } from "./components/ModeControlsDropdown";
import { ActivityCalendar } from "./components/Calendar/ActivityCalendar";
import SidePopupOverlay from "../UI/SidePopupOverlay";
import AddActivities from "./AddActivities";
import { ActivityInCreatePayload } from "../Projects/projectsApiTypes";
import { listTimesheets, ListTimesheetsRequest, TimesheetApprovalStatus } from "../Timesheets";
import { ReactComponent as IconClose } from "./components/Calendar/icons/cross.svg";
import { useActivityStore } from "./TimeTracker/TrackTime.store";

// todo move them to scss file later
const styles = stylesheet`
.Wrapper {
  padding-bottom: 0px;

  :global(.activities-row) {
    position: relative;
  }

  .FiltersWrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 32px;
    :global(.DateInput) {
      width: 55px;
    }
  }
  .FilersControls {
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.Switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px;
  padding: 10px 8px;
  gap: 12px;
  font-size: var(--typography-font-size-default);
}
.CalendarConnectedToast {
  position: fixed;
  bottom: 90px;
  inset-inline-end: 24px;
  background: var(--colors-success-600-p);
  padding: 9px 24px;
  display: flex;
  color: var(--colors-surface-0);
  font-size: var(--typography-font-size-default);
  gap: 16px;
  z-index: 20;
  border-radius: var(--shapes-border-radius-default);
  align-items: center;
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg path {
      fill: var(--colors-surface-0);
    }
  }
}
`;

const updateUrlWithParams = (
  {
    from,
    to,
    userProfileUuid,
  }: {
    from: moment.Moment;
    to: moment.Moment;
    userProfileUuid: string;
  },
  history: RouteComponentProps["history"],
) => {
  const params = [];
  params.push(`from=${from.format(CONFIG.apiDateFormat)}`);
  params.push(`to=${to.format(CONFIG.apiDateFormat)}`);
  params.push(`user_profile_uuid=${userProfileUuid}`);
  history.push(`?${params.join("&")}`);
};

export const ActivitiesCalendarPage = ({
  startDate,
  endDate,
  selectedEmployee,
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedEmployee: { uuid: string; full_name: string; id: number };
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const context = useContext(GlobalContext);
  const activitiesStore = useActivitiesStore();
  const { selectEmployee } = useActivityStore();
  const history = useHistory();

  const [popupAddPunchVisible, setPopupAddPunchVisible] = useState(false);
  const [showGACalendarEvents, setShowGACalendarEvents] = useState(true);
  const [searchValue, setSearchValue] = useState<string>(selectedEmployee?.full_name);
  const [showCalendarConnectionToast, setShowCalendarConnectionToast] = useState(false);
  const isCurrentUserSelected = useMemo(
    () => selectedEmployee?.uuid === window.global_store.profile.uuid,
    [selectedEmployee],
  );

  // GET DATA CB
  const [getActivities] = useAsyncCallback(async () => {
    const company = await context.getCompany();

    const response = await getActivitiesList({
      employeeUuid: selectedEmployee!.uuid,
      from: startDate.clone().subtract(5, "days").format(CONFIG.apiDateFormat),
      to: endDate.clone().format(CONFIG.apiDateFormat),
      status: [ActivityStatuses.pending, ActivityStatuses.approved, ActivityStatuses.submitted].join(","),
      requestedBy: window.global_store.profile.uuid,
      companyUuid: company.uuid,
    });
    activitiesStore.loadActivities(response.content);
    return response.content;
  }, [startDate, endDate, selectedEmployee]);

  const [getRequests] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const response = await getRequestsList({
      companyUUID: company.uuid,
      userProfileUuid: selectedEmployee!.uuid,
      params: {
        from: startDate.clone().format(CONFIG.apiDateFormat),
        to: endDate.clone().format(CONFIG.apiDateFormat),
        requested_by: window.global_store.profile.uuid,
        page: String(1),
        perPage: String(100),
      },
    });
    const activeRequests = response.content.filter((r) =>
      [RequestApprovalFlowStatus.approved, RequestApprovalFlowStatus.pending].includes(r.approvalStatus),
    );
    activitiesStore.loadRequests(activeRequests);
    return activeRequests;
  }, [startDate, endDate, selectedEmployee]);

  const [getPunches] = useAsyncCallback(async () => {
    const response = await getAllPunches({
      page: String(1),
      per_page: String(100),
      employeeUuid: String(selectedEmployee.uuid),
      from: startDate.clone().format(CONFIG.apiDateFormat),
      to: endDate.clone().format(CONFIG.apiDateFormat),
    });
    activitiesStore.loadPunches(response.punches);
    return response.punches;
  }, [startDate, endDate, selectedEmployee]);

  const [getOnCallEvents] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const response = await getOnCalls({
      companyUuid: company?.uuid,
      params: {
        page: String(1),
        perPage: String(100),
        userProfileUuid: selectedEmployee.uuid,
        from: startDate.clone().format(CONFIG.apiDateFormat),
        to: endDate.clone().format(CONFIG.apiDateFormat),
        requestedBy: window.global_store.profile.uuid,
      },
    });

    activitiesStore.loadOnCallEvents(response.content);
    return response.content;
  }, [startDate, endDate, selectedEmployee]);

  const [getSchedules] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const response = await getSchedulesForUserProfile(company.uuid, selectedEmployee.uuid, {
      from: startDate.clone().format(CONFIG.apiDateFormat),
      to: endDate.clone().format(CONFIG.apiDateFormat),
      requestedBby: window.global_store.profile.uuid,
    });
    activitiesStore.loadSchedules(response.content.map((s) => ({ ...s.Schedule, startDate: s.startDate })));
    return response.content;
  }, [selectedEmployee, startDate, endDate]);

  const [getTimesheets] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const req: ListTimesheetsRequest = {
      companyUuid: company.uuid,
      requestedBy: window.global_store.profile.uuid,
      userProfileUuid: selectedEmployee.uuid,
      approvalStatus: [TimesheetApprovalStatus.approved, TimesheetApprovalStatus.pending],
      from: moment(startDate).subtract(2, "month").format(CONFIG.apiDateFormat),
      to: moment(endDate).add(2, "month").format(CONFIG.apiDateFormat),
    };
    const { content, metadata } = await listTimesheets(req);
    activitiesStore.loadTimesheets(content);
    // this endpoint will return users data only if they have activities in given period
    if (metadata?.userProfilesByUuid[selectedEmployee.uuid]) {
      activitiesStore.loadLastLockDate(metadata.userProfilesByUuid[selectedEmployee.uuid].last_lock_date);
    } else {
      const employee = await getEmployee({ id: selectedEmployee.id, newHierarchyPermissions: false });
      activitiesStore.loadLastLockDate(employee.user_profile.last_lock_date);
    }
    return content;
  }, [selectedEmployee, startDate, endDate]);
  // END GET DATA CB

  // GOOGLE CALENDAR CONFIGS
  const [calendarIds, setCalendarIds] = useState<string[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // todo finish with error display
  const onError = useCallback((resp) => {
    console.log("on error cb", resp);
  }, []);

  const signIn = useCallback(() => {
    googleCalendarSync
      .signIn()
      .catch(onError)
      .then(() => setShowCalendarConnectionToast(true));
  }, [onError]);
  const debounce = useRef();
  const signOut = useCallback(() => {
    googleCalendarSync.signOut().catch(onError);
  }, [onError]);

  const [getGoogleEvents] = useAsyncCallback(async () => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      if (isCurrentUserSelected && googleCalendarSync.isSignedIn()) {
        googleCalendarSync
          .getEvents(
            calendarIds,
            startDate.clone().subtract(7, "days").toDate(),
            endDate.clone().add(7, "days").toDate(),
          )
          .then((events) => {
            activitiesStore.loadGoogleEvents(events);
          })
          .catch(onError);
      } else {
        activitiesStore.loadGoogleEvents([]);
      }
    }, 200);
  }, [calendarIds, selectedEmployee, startDate, endDate]);

  useEffect(() => {
    const onSignedIn = () => {
      setIsLoggedIn(true);
      googleCalendarSync
        .getCalendars()
        .then((calendars) => {
          calendars.sort((c) => (c.primary ? -1 : 1));
          const primaryCalId = calendars[0].id;

          setCalendarIds([primaryCalId]);
          return googleCalendarSync.getEvents([primaryCalId], startDate.toDate(), endDate.toDate());
        })
        .then((events) => {
          activitiesStore.loadGoogleEvents(events);
        })
        .catch(onError);
    };

    const onSignedOut = () => {
      setIsLoggedIn(false);
      setCalendarIds([]);
      activitiesStore.loadGoogleEvents([]);
    };

    googleCalendarSync.init({
      clientId: CONFIG.googleOAuthKey,
      auth: "server",
      authUrl: `https://${CONFIG.api_admin}/v2/public/sso/provider/google/calendar-auth`,
      refreshUrl: `https://${CONFIG.api_admin}/v2/public/sso/provider/google/calendar-refresh`,
      onSignedIn,
      onSignedOut,
    });
  }, [startDate, endDate]);
  // END GOOGLE CALENDAR CONFIG

  useEffect(() => {
    void getTimesheets();
    void getActivities();
    // Temporary disabled getting requests, on call, schedules and punches
    // void getRequests();
    // void getPunches();
    // void getOnCallEvents();
    // void getSchedules();
    void getGoogleEvents();
  }, [getActivities, getRequests, getPunches, getOnCallEvents, getSchedules, getGoogleEvents, getTimesheets]);
  useEffect(() => {
    selectEmployee(selectedEmployee);
  }, [selectedEmployee]);

  const onAddActivities = async (activities: ActivityInCreatePayload[]) => {
    try {
      const company = await context.getCompany();
      await createActivities({
        companyUuid: company.uuid,
        body: {
          content: {
            activities,
            createdBy: window.global_store.profile.uuid,
          },
        },
      });

      await getActivities();
      setPopupAddPunchVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  // FILTERS
  const onSearchValue = (value: AllSearchObjects) => {
    setSearchValue((value as SearchControlOnChangeData).label!);
    updateUrlWithParams(
      {
        from: startDate,
        to: endDate,
        userProfileUuid: (value as SearchControlOnChangeData).uuid!,
      },
      history,
    );
  };

  const onSearchDatesChange = (sDate: Moment | null, eDate: Moment | null) => {
    if (sDate && eDate) {
      updateUrlWithParams(
        {
          from: sDate,
          to: eDate,
          userProfileUuid: selectedEmployee.uuid,
        },
        history,
      );
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.FiltersWrapper}>
        <div style={{ marginInlineEnd: "24px" }}>
          <SearchControl
            value={searchValue}
            onChange={onSearchValue}
            placeholder={translateEmployeeTerm(
              t,
              TranslationNamespaces.common,
              "custom-search-employees",
              `${TranslationNamespaces.common}|Search Employee`,
            )}
            permissionSection={PermissionSectionName.activities}
          />
        </div>
        <TimesheetDateRangePicker
          onChange={onSearchDatesChange}
          startDate={startDate}
          endDate={endDate}
          labelFormat="MMM DD"
        />
        <div className={styles.FilersControls}>
          {isCurrentUserSelected ? (
            <ModeControlsDropdown
              hideDropdownIcon
              headerComponent={
                <div className="Icon">
                  <DotsIcon />
                </div>
              }
              optionsComponent={
                <>
                  {isLoggedIn ? (
                    <>
                      <div className={styles.Switcher}>
                        <div>{t("Show imported events")}</div>
                        <div className={styles.IntervalSwitch}>
                          <CheckboxControl
                            modifiers={{ small: true }}
                            checked={showGACalendarEvents}
                            onChange={(checked: boolean) => {
                              if (checked) {
                                void getGoogleEvents();
                              } else {
                                activitiesStore.loadGoogleEvents([]);
                              }
                              setShowGACalendarEvents(checked);
                            }}
                          />
                        </div>
                      </div>
                      <div className="Divider" />
                      <div className="Option Danger">
                        <div onClick={signOut}>{t("disconnect_google_calendar")}</div>
                      </div>
                    </>
                  ) : (
                    <div className="Option">
                      <div onClick={signIn}>{t("connect_google_calendar")}</div>
                    </div>
                  )}
                </>
              }
            />
          ) : null}
        </div>
      </div>
      <ActivityCalendar
        key={startDate?.format("DD-MM-YYYY") + selectedEmployee?.uuid} // if key is not provided, after changing date or employee, calendar will not be rerendered. (PB-1820) TODO: ivestigate this behaviour
        forceFetchActivities={getActivities}
        forceFetchRequests={getRequests}
        forceReloadOnCalls={getOnCallEvents}
        showWeekends // Option was present in old navigation. In new navigation it's absent. Can we remove this prop?
        selectedDate={startDate
          .startOf("day")
          .add(moment().hours() - 2 <= 0 ? 0 : moment().hours() - 2, "hours")
          .toDate()}
        firstDayOfWeek={dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day]}
        selectedEmployee={selectedEmployee}
      />
      {popupAddPunchVisible && (
        <SidePopupOverlay
          width={614}
          header={t("Create Multiple Activities")}
          isOpen={popupAddPunchVisible}
          onClose={() => setPopupAddPunchVisible(false)}
          contentOverflow
          paddingBottom
        >
          <AddActivities
            isTimesheetEnabled
            prefillProfile={selectedEmployee || null}
            onYes={onAddActivities}
            onClose={() => setPopupAddPunchVisible(false)}
          />
        </SidePopupOverlay>
      )}
      {showCalendarConnectionToast ? (
        <div className={styles.CalendarConnectedToast}>
          <span>{t("Calendar successfully connected")}</span>
          <div onClick={() => setShowCalendarConnectionToast(false)}>
            <IconClose style={{ width: "20px", height: "20px" }} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
