import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { languages } from "utils/languages";
import { setUserLocale } from "utils/apiHelpers";
import { LocalStorageKeys } from "utils/localStorageUtils";
import { getLocale, saveLocale } from "utils/translationHelpers";
import * as Images from "./menu-svg-icons";

const Wrapper = styled.div``;

const Dropdown = styled.div`
  padding: 8px 4px;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-surface-0);
  box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
`;

const DropdownOption = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: 180px;
  padding: 0 12px;
  line-height: 32px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

interface LanguageSwitcherNewProps {
  isOpened: boolean;
  onClose: () => void;
}

const LanguageSwitcherNew = ({ isOpened, onClose }: LanguageSwitcherNewProps) => {
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const currentLocale = getLocale();

  const changeLanguage = async (ev: React.MouseEvent<HTMLDivElement> | null, language: string): Promise<void> => {
    ev?.stopPropagation();

    if (localStorage.getItem(LocalStorageKeys.loginAsMode) !== "true") {
      try {
        await setUserLocale({ id: window.global_store.profile.id, locale: language });
      } catch (error) {
        console.log(error);
      }
    }

    saveLocale(language);
    window.location.reload();
  };

  const langs = languages.map((lang) => ({ languageKey: lang.key, languageTranslation: t(lang.name) }));
  langs.sort((a, a1) => {
    const nameA = a.languageTranslation.toUpperCase();
    const nameB = a1.languageTranslation.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  return (
    <Wrapper className="lang-switch">
      {isOpened && (
        <ClickOutside<ClickOutsideType> onClickOutside={onClose}>
          <Dropdown>
            {langs.map((l) => (
              <DropdownOption key={l.languageKey} onClick={(e) => void changeLanguage(e, l.languageKey)}>
                {l.languageTranslation}
                {currentLocale === l.languageKey && <Images.CheckMark />}
              </DropdownOption>
            ))}
          </Dropdown>
        </ClickOutside>
      )}
    </Wrapper>
  );
};

export default LanguageSwitcherNew;
