import { PropsWithChildren } from "react";
import c from "classnames";

import BEM from "utils/BEM";
import "./Button.scss";

const b = BEM.b("ui-button");

interface UIButtonProps extends PropsWithChildren {
  label?: string;
  type?: "submit" | "link";
  modifier?: string;
  className?: string;
}

const Button = ({ label, type, modifier, children, className, ...props }: UIButtonProps | any) => {
  const classNameCombined = c(b([modifier]), className);
  switch (type) {
    case "submit": {
      return <input type="submit" className={classNameCombined} {...props} value={label || "Submit"} />;
    }

    case "link": {
      return (
        <a className={classNameCombined} {...props}>
          {children || label}
        </a>
      );
    }

    default: {
      return (
        <button type="button" className={classNameCombined} {...props}>
          {label || children}
        </button>
      );
    }
  }
};

export default Button;
