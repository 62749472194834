import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import useState from "hooks/useState";
import calendarLogo from "./calendar_logo.png";
import CalendarModal from "./CalendarModal";
import { LinkWithIcon, SectionTitle } from "./styled";
import SystemIntegrationModal from "./SystemIntegrationModal";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 37px;
  flex-direction: column;
  gap: 5px;
`;

const CalendarRow = styled.div`
  display: flex;
  padding: 12px 8px;
  padding-inline-start: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--shapes-border-radius-default);
  &:hover {
    cursor: pointer;
    background-color: var(--colors-surface-50);
  }
  button:hover {
    box-shadow: 0px 3px 5px rgba(129, 147, 171, 0.2);
  }
`;

const Img = styled.img`
  width: 36px;
  height: 36px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline-start: 16px;
  margin-inline-end: 28px;
  gap: 4px;
`;

const TextTitle = styled.p`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);
  margin: 0;
`;

const TextDescription = styled.p`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-700);
  margin: 0;
`;

const LinksRow = styled.div`
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--colors-surface-150);
  padding-top: 18px;
  margin-top: 16px;
`;

interface InternalToolsRowState {
  isCalendarPopupVisible: boolean;
  isIntegrationPopupVisible: boolean;
}

const InternalToolsRow = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);

  const [{ isCalendarPopupVisible, isIntegrationPopupVisible }, setState] = useState<InternalToolsRowState>({
    isCalendarPopupVisible: false,
    isIntegrationPopupVisible: false,
  });

  return (
    <Wrapper>
      <SectionTitle>{t("Internal Tools")}</SectionTitle>
      <CalendarRow>
        <Img src={calendarLogo} />
        <Text>
          <TextTitle>{t("calendars-title")}</TextTitle>
          <TextDescription>{t("calendars-description")}</TextDescription>
        </Text>
        <Button
          value={t("Manage calendars")}
          style={{ width: "auto", whiteSpace: "nowrap", padding: "6px 8px", height: "30px" }}
          state={ButtonState.enableDay}
          onClick={() => setState({ isCalendarPopupVisible: true })}
        />
      </CalendarRow>
      <CalendarModal
        isOpen={isCalendarPopupVisible}
        onClose={() =>
          setState({
            isCalendarPopupVisible: false,
          })
        }
      />
      <SystemIntegrationModal
        isOpen={isIntegrationPopupVisible}
        onClose={() =>
          setState({
            isIntegrationPopupVisible: false,
          })
        }
      />
      <LinksRow>
        <LinkWithIcon
          className="app-access"
          onClick={() =>
            setState({
              isIntegrationPopupVisible: true,
            })
          }
        >
          {t("Application access")}
        </LinkWithIcon>
        <LinkWithIcon className="api" href="https://docs.api.oitchau.com/" target="_blank">
          {t("Learn about our API")}
        </LinkWithIcon>
      </LinksRow>
    </Wrapper>
  );
};

export default InternalToolsRow;
