import moment from "moment-timezone";
import { ActivityRes, CreateActivityReq, Location, ProjRes } from "services/oitchau-api";
import { CustomFieldSetting } from "types/models/projects";
import { ProjectsEventTracking } from "services/projects-event-tracking";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { hrsMinsToMins, minsToHrsMins } from "utils/common";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { useCallback, useEffect, useState } from "react";
import { useProfile } from "context/user-profile-context";
import { useProjectsService } from "utils/useServices";
import { ActivityRunningDialog, MandatoryFieldsDialog } from "../ModalDialog";
import { ActivityEditView, ActivityEditViewProps } from "../ActivityEditView";

function makeEmptyActivityCustomFields(customFieldsSettings: CustomFieldSetting[] = []) {
  const customFields = customFieldsSettings
    .filter((cfs) => cfs.active && cfs.deletedAt == null)
    .map((cfs) => {
      const cf = {
        fieldType: cfs.fieldType,
        name: cfs.name,
        required: cfs.required,
        uuid: cfs.uuid,
        value: null,
      };
      return cf;
    });
  return customFields;
}
function mergeCustomFields(a: ActivityRes["customFields"], b: ActivityRes["customFields"]) {
  const cfs = [...a];
  for (const bcf of b) {
    const isPresent = cfs.find((cf) => cf.uuid === bcf.uuid);
    if (!isPresent) cfs.push(bcf);
  }
  return cfs;
}
function mapActivity(raw: ActivityRes, projects: ProjRes[], locations: Location[]): ActivityEditViewProps["activity"] {
  const pr = raw.project || (raw.projectUuid == null ? null : projects.find((p) => p.uuid === raw.projectUuid));
  const ta = raw.task || (raw.taskUuid == null ? null : pr?.tasks?.find((t) => t.uuid === raw.taskUuid));
  const lo = raw.location || (raw.locationUuid == null ? null : locations.find((l) => l.uuid === raw.locationUuid));
  const cl = pr?.client;
  const projEmptyCfs = makeEmptyActivityCustomFields(pr?.customFieldsSettings || []);
  const cfs = mergeCustomFields(raw.customFields || [], projEmptyCfs || []);
  const act = {
    attachments: raw.attachments || [],
    client: cl?.name,
    customFields: cfs,
    date: raw.date,
    duration: minsToHrsMins(raw.duration),
    endTime: minsToHrsMins(raw.endTime),
    isRunning: raw.status === "running" && raw.stopReason == null,
    location: lo?.name,
    project: pr?.name,
    startTime: minsToHrsMins(raw.startTime),
    stopReason: raw.stopReason,
    task: ta?.name,
  };
  return act;
}

type ActivityUpdates = Omit<
  CreateActivityReq,
  "locationVerification" | "companyUuid" | "createdBy" | "userProfileUuid" | "status"
>;

export type ActivityEditProps = React.HTMLProps<HTMLDivElement> & {
  activity: ActivityRes;
  projects: ProjRes[];
  locations: Location[];
  lastLockDate?: string;
  isNew: boolean;
  isInvalid?: boolean;
  isRepeat?: boolean;
  loading: boolean;
  doSkipDetails: boolean;
  warning?: React.ReactElement | string | null;
  onBackClick(): void;
  onStopClick(activity: ActivityUpdates, task?: { projectUuid: string; name: string }, proj?: { name: string }): void;
  onSaveClick(activity: ActivityUpdates, task?: { projectUuid: string; name: string }, proj?: { name: string }): void;
  onDeleteClick(activity: { uuid: string }): void;
  projectsEventTracking: ProjectsEventTracking;
};

export function ActivityEdit(props: ActivityEditProps) {
  const {
    activity: activityProp,
    projects: projectsProp,
    locations: locationsProp,
    lastLockDate,
    warning,
    isNew,
    isInvalid: invalidProp,
    isRepeat,
    doSkipDetails,
    loading,
    onBackClick,
    onStopClick,
    onSaveClick,
    onDeleteClick,

    projectsEventTracking,
    ...restProps
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => projectsEventTracking.trackViewActivity(activityProp), []);
  const profile = useProfile()!;
  const projectsService = useProjectsService();
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  const [projects, setProjects] = useState(projectsProp);
  useEffect(() => setProjects(projectsProp), [projectsProp]);

  const [activity, setActivity] = useState<ActivityEditViewProps["activity"]>(() =>
    mapActivity(activityProp, projects, locationsProp),
  );
  const [projectUuid, setProjectUuid] = useState<string>(activityProp.project?.uuid);
  const [taskUuid, setTaskUuid] = useState<string>(activityProp.task?.uuid);
  const [locationUuid, setLocationUuid] = useState<string>(activityProp.location?.uuid);
  const [isTouched, setIsTouched] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    setActivity(mapActivity(activityProp, projectsProp, locationsProp));
    setProjectUuid(activityProp.projectUuid);
    setTaskUuid(activityProp.taskUuid);
    setLocationUuid(activityProp.locationUuid);
    setIsTouched(false);
    const isLocked =
      lastLockDate && moment(activityProp.date, "YYYY-MM-DD").isSameOrBefore(moment(lastLockDate, "YYYY-MM-DD"));
    const isEdtbl =
      (activityProp.status === "running" || activityProp.status === "pending") &&
      (activityProp.approvalStatus == null || activityProp.approvalStatus === "declined") &&
      !isLocked;
    setIsEditable(isEdtbl);
  }, [projectsEventTracking, activityProp, locationsProp, lastLockDate, projectsProp]);

  // --- time
  const [date, setDate] = useState(new Date());
  const ticking = activity.isRunning;
  useEffect(() => {
    if (!ticking) return undefined;
    const intervalId = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, [ticking]);
  const [duration, setDuration] = useState<string>("");
  const [durationSeconds, setDurationSeconds] = useState<string>("");
  const [today, setToday] = useState<string>(moment(date).format("YYYY-MM-DD"));
  useEffect(() => {
    const m = moment(date);
    setToday(m.format("YYYY-MM-DD"));
    const createdAt = new Date(activityProp.createdAt);
    const seconds = createdAt.getSeconds();
    const start = moment(
      `${activity.date} ${activity.startTime}:${Number.isNaN(seconds) ? "00" : seconds}`,
      "YYYY-MM-DD HH:mm:ss",
    );
    const diffTotalSecs = m.diff(start, "seconds");
    const durSeconds = diffTotalSecs % 60;
    const dur = minsToHrsMins(Math.floor(diffTotalSecs / 60));
    setDuration(dur);
    setDurationSeconds(`:${String(durSeconds).padStart(2, "0")}`);
  }, [date, activity, activityProp]);

  // --- prepare locations
  const [locations, setLocations] = useState<ActivityEditViewProps["locations"]>([]);
  useEffect(() => {
    const proj = projects?.find((prj) => prj.uuid === projectUuid);
    const projLocs =
      proj?.locationUuids
        .map((locUuid) => locationsProp?.find((loc) => loc.uuid === locUuid))
        .filter((loc) => loc?.active) || [];
    const availLocs = projLocs.length === 0 ? locationsProp : (projLocs as Location[]);
    const locs = availLocs?.map((loc) => ({
      address: loc.formatted_address,
      id: loc.uuid,
      name: loc.name,
      uuid: loc.uuid,
    })) ?? [];
    setLocations(locs);
  }, [projectUuid, projects, locationsProp]);

  // --- handle changes
  const handleDateChange = useCallback(
    (dat: string) => {
      if (dat == null) return;
      const act = { ...activity, date: dat };
      projectsEventTracking.trackChangeDate(activity, act);
      setIsTouched(true);
      setActivity(act);
    },
    [projectsEventTracking, activity],
  );
  const handleStartTimeChange = useCallback(
    (start: string) => {
      const now = moment();
      const maxStartMins = now.hours() * 60 + now.minutes() - 1;
      const startMins = Math.min(hrsMinsToMins(start), maxStartMins);
      const endMins = Math.max(startMins, hrsMinsToMins(activity.endTime!));
      const durationMins = endMins - startMins;
      const upd = {
        duration: minsToHrsMins(durationMins),
        endTime: minsToHrsMins(endMins),
        startTime: minsToHrsMins(startMins),
      };
      setIsTouched(true);
      setActivity({ ...activity, ...upd });
      return upd.startTime;
    },
    [activity],
  );
  const handleEndTimeChange = useCallback(
    (end: string) => {
      const endMins = Math.max(hrsMinsToMins(end), 1);
      const startMins = Math.min(endMins, hrsMinsToMins(activity.startTime));
      const durationMins = endMins - startMins;
      const upd = {
        duration: minsToHrsMins(durationMins),
        endTime: minsToHrsMins(endMins),
        startTime: minsToHrsMins(startMins),
        stopReason: null,
      };
      setIsTouched(true);
      setActivity({ ...activity, ...upd });
      return upd.endTime;
    },
    [activity],
  );
  const handleDurationChange = useCallback(
    (dur: string) => {
      const durationMins = Math.max(hrsMinsToMins(dur), 0);
      const startMins = hrsMinsToMins(activity.startTime);
      const endMins = startMins + durationMins;
      const upd = {
        duration: minsToHrsMins(durationMins),
        endTime: minsToHrsMins(endMins),
        startTime: minsToHrsMins(startMins),
        stopReason: null,
      };
      setIsTouched(true);
      setActivity({ ...activity, ...upd });
      return upd.duration;
    },
    [activity],
  );
  const handleTaskChange = useCallback(
    (proj: ProjRes, task: ProjRes["tasks"][0]) => {
      if (proj == null || task.uuid === taskUuid) return;
      setProjectUuid(proj.uuid);
      setTaskUuid(task.uuid);
      const projLocs =
        proj.locationUuids
          .map((locUuid) => locationsProp.find((loc) => loc.uuid === locUuid))
          .filter((loc) => loc?.active) || [];
      const availLocs = projLocs.length === 0 ? locationsProp : (projLocs as Location[]);
      const locat = availLocs.find((loc) => loc.uuid === locationUuid) || availLocs[0];
      setLocationUuid(locat?.uuid);
      const customFields =
        proj.uuid === projectUuid ? activity.customFields : makeEmptyActivityCustomFields(proj.customFieldsSettings);
      const act = {
        ...activity,
        client: proj.client?.name,
        customFields,
        location: locat?.name,
        project: proj.name,
        task: task.name,
      };
      projectsEventTracking.trackChangeTask(activity, act);
      setIsTouched(true);
      setActivity(act);
    },
    [projectsEventTracking, activity, locationUuid, locationsProp, taskUuid, projectUuid],
  );
  const handleAddTask = useCallback(
    async (taskData: { name: string; projectUuid: string }, shouldAddProj?: boolean) => {
      const proj =
        shouldAddProj == null
          ? projects.find((p) => p.uuid === taskData.projectUuid)!
          : {
              locationUuids: [],
              name: t("Personal project"),
              tasks: [],
              uuid: `newproj-${Math.random().toFixed(10).slice(2)}`,
            };
      const newTask = {
        name: taskData.name,
        uuid: `newtask-${Math.random().toFixed(10).slice(2)}`,
      };
      const updProj = {
        ...proj,
        tasks: [...proj.tasks, newTask],
      };

      const projs = projects.map((p) => (p.uuid !== taskData.projectUuid ? p : updProj));
      if (shouldAddProj != null) {
        projs.push(updProj);
      }
      setProjects(projs);
      handleTaskChange(updProj, newTask);
    },
    [handleTaskChange, projects, t],
  );

  const handleLocationChange: ActivityEditViewProps["onLocationChange"] = useCallback(
    (loc) => {
      if (loc == null || loc.id === locationUuid) return;
      setLocationUuid(loc.id);
      const act = { ...activity, location: loc.name };
      projectsEventTracking.trackChangeLocation(activity, act);
      setIsTouched(true);
      setActivity(act);
    },
    [projectsEventTracking, activity, locationUuid],
  );
  const handleCustomFieldChange = useCallback(
    (cfUuid: string, newVal: string) => {
      const newCfs = activity.customFields.map((cf) => (cf.uuid !== cfUuid ? cf : { ...cf, value: newVal }));
      setIsTouched(true);
      setActivity({ ...activity, customFields: newCfs });
    },
    [activity],
  );

  const isDateOutsideRange = useCallback(
    (d: string) => {
      const dateMom = moment(d, "YYYY-MM-DD");
      const todayMom = moment(today, "YYYY-MM-DD");
      const isOutside =
        dateMom.isAfter(todayMom, "day") ||
        Boolean(lastLockDate && dateMom.isSameOrBefore(moment(lastLockDate, "YYYY-MM-DD"), "day"));
      return isOutside;
    },
    [today, lastLockDate],
  );
  const [getUnavailDates] = useAsyncCallback(
    async (month: string) => {
      const from = moment(month, "YYYY-MM").format("YYYY-MM-DD");
      const unavailDates = await projectsService.getUnavailableDates({
        from,
        userProfileUuid: profile.uuid,
      });
      return unavailDates;
    },
    [profile, projectsService],
  );

  // --- apply changes - save/stop/delete
  const handleSave = useCallback(() => {
    const actToSave = {
      attachments: activity.attachments,
      customFields: activity.customFields,
      date: activity.date,
      endTime: activity.isRunning ? 0 : hrsMinsToMins(activity.endTime!),
      locationUuid: locationUuid || null,
      projectUuid,
      startTime: hrsMinsToMins(activity.startTime),
      taskUuid,
      stopReason: activity.stopReason,
      uuid: activityProp.uuid,
    };
    const proj = projects.find((p) => p.uuid === projectUuid)!;
    const task = proj?.tasks.find((t) => t.uuid === taskUuid);
    const taskToCreate = taskUuid?.startsWith("newtask-") ? { name: task!.name, projectUuid } : undefined;
    const projToCreate = projectUuid?.startsWith("newproj-") ? { name: proj.name } : undefined;
    onSaveClick(actToSave, taskToCreate, projToCreate);
  }, [activity, activityProp, projectUuid, taskUuid, locationUuid, onSaveClick, projects]);

  const handleDelete = useCallback(() => {
    const actToDelete = {
      uuid: activityProp.uuid,
    };
    onDeleteClick(actToDelete);
  }, [activityProp, onDeleteClick]);

  const handleStop = useCallback(() => {
    const actToSave = {
      attachments: activity.attachments,
      customFields: activity.customFields,
      date: activity.date,
      endTime: activity.endTime != null ? hrsMinsToMins(activity.endTime) : undefined,
      locationUuid: locationUuid || null,
      projectUuid,
      startTime: hrsMinsToMins(activity.startTime),
      taskUuid,
      stopReason: activity.stopReason,
      uuid: activityProp.uuid,
    };
    const proj = projects.find((p) => p.uuid === projectUuid)!;
    const task = proj?.tasks.find((t) => t.uuid === taskUuid);
    const taskToCreate = taskUuid.startsWith("newtask-") ? { name: task!.name, projectUuid } : undefined;
    const projToCreate = projectUuid?.startsWith("newproj-") ? { name: proj.name } : undefined;
    onStopClick(actToSave, taskToCreate, projToCreate);
  }, [activity, activityProp, projectUuid, taskUuid, locationUuid, onStopClick, projects]);

  // --- validate
  const [errors, setErrors] = useState<ActivityEditViewProps["errors"]>();
  const [invalid, setInvalid] = useState(false);
  const validate = useCallback(
    (forbidNoTask = false) => {
      const errs: ActivityEditViewProps["errors"] = {};
      if (taskUuid == null && (forbidNoTask || !activity.isRunning)) {
        errs.task = true;
      }
      for (const cf of activity.customFields) {
        if (cf.required && cf.value == null) {
          errs.customFields = errs.customFields || {};
          errs.customFields[cf.uuid] = true;
        }
      }
      if (Object.keys(errs).length === 0) {
        setErrors(undefined);
        return true;
      }
      setErrors(errs);
      setInvalid(true);
      return false;
    },
    [activity, taskUuid],
  );
  useEffect(() => {
    if (invalid || invalidProp) validate(true);
  }, [invalid, activity, validate, invalidProp]);

  const [showMissingDetailsMessage, setShowMissingDetailsMessage] = useState(false);
  const [showRunningMessage, setShowRunningMessage] = useState(false);
  useEffect(() => {
    invalidProp && !isRepeat && setShowMissingDetailsMessage(true);
    invalidProp && isRepeat && setShowRunningMessage(true);
  }, [invalidProp, isRepeat]);

  const cantGoBack = activityProp.status === "running" && activityProp.stopReason != null;

  return (
    <>
      <ActivityEditView
        {...restProps}
        activity={activity}
        projects={projects}
        taskUuid={taskUuid}
        projectUuid={projectUuid}
        locations={locations}
        locationUuid={locationUuid}
        today={today}
        duration={duration}
        durationSeconds={durationSeconds}
        onBackClick={!cantGoBack ? onBackClick : undefined}
        onStopClick={handleStop}
        onSaveClick={handleSave}
        onDeleteClick={handleDelete}
        onDateChange={handleDateChange}
        isDateOutsideRange={isDateOutsideRange}
        getUnavailableDates={getUnavailDates}
        onStartTimeChange={handleStartTimeChange}
        onEndTimeChange={handleEndTimeChange}
        onDurationChange={handleDurationChange}
        onTaskChange={handleTaskChange}
        onLocationChange={handleLocationChange}
        onCustomFieldChange={handleCustomFieldChange}
        onAddTask={handleAddTask}
        isTouched={isTouched}
        isNew={isNew}
        isEditable={isEditable}
        validate={validate}
        errors={errors}
        warning={warning}
        loading={loading}
        doSkipDetails={doSkipDetails}
      />
      {showMissingDetailsMessage && <MandatoryFieldsDialog onResult={() => setShowMissingDetailsMessage(false)} />}
      {showRunningMessage && <ActivityRunningDialog onResult={() => setShowRunningMessage(false)} />}
    </>
  );
}
