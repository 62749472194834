import { WidgetProjectsService } from "services/widget-projects-service";
import { ProjectsService } from "services/projects-service";
import { AppStorageClientAdmin } from "services/app-storage.client-admin";
import { KvStorageWapp } from "services/kv-storage.wapp";
import { OitchauApi, OitchauAuthedApi } from "services/oitchau-api";
import { RequestAxiosService } from "services/request-service.axios";
import { AuthService } from "services/auth.client-admin";
import { LocationsService } from "services/locations-service";
import { PunchService } from "services/punch-service";
import amplitude from "utils/amplitude";
import ga from "utils/ga";
import { PunchEventTracking } from "services/punch-event-tracking";
import { ProjectsEventTracking } from "services/projects-event-tracking";
import { ExtensionService } from "services/extension.client-admin";
import { ProjectsWsService } from "services/projects-ws-service";
import { InjectionCommands } from "services/injection-commands";
import { KvStorageIdb } from "services/kv-storage-idb";

import config from "config";

export const apiConfig = {
  clientAdminApiUrl: `https://${config.client_admin_api!}`,
  rubyApiUrl: `https://${config.api!}`,
};

export class MainModule {
  kvStorage = new KvStorageWapp();
  kvStorageIdb = new KvStorageIdb({ dbName: "dayio_kv" });
  appStorage = new AppStorageClientAdmin(this.kvStorage);
  requestService = new RequestAxiosService();
  oitchauApi = new OitchauApi(this.requestService, apiConfig);
  authService = new AuthService();
  oitchauAuthedApi = new OitchauAuthedApi(this.oitchauApi, this.authService);
  projectsService = new ProjectsService(this.oitchauAuthedApi, this.appStorage);
  punchNowService = new PunchService(this.oitchauAuthedApi, this.appStorage);
  locationsService = new LocationsService(this.oitchauAuthedApi, this.appStorage);
  widgetProjectsService = new WidgetProjectsService(
    this.projectsService,
    this.locationsService,
    this.appStorage,
    this.oitchauAuthedApi,
  );
  punchEventTracking = new PunchEventTracking(amplitude, ga);
  projectsEventTracking = new ProjectsEventTracking(amplitude, ga);
  extensionService = new ExtensionService();
  projectsWsService = new ProjectsWsService(this.appStorage);
  injectionCommands = new InjectionCommands();
}

export const services = new MainModule();
