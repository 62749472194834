import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import { EmployeesCenter } from "components/Employees/EmployeesCenter";
import EmployeePage from "components/Employees/EmployeePage";

type RouteEmployeesProps = RouteComponentProps;

const RouteEmployees = ({ match: { path } }: RouteEmployeesProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict exact from={path} to={`${path}/`} />
      <Redirect strict from={`${path}/:employeeId/`} to={`${path}/:employeeId`} />

      <Route exact strict path={`${path}/`} component={EmployeesCenter} />
      <Route path={`${path}/:employeeId`} component={EmployeePage} />
    </Switch>
  </Suspense>
);

export default RouteEmployees;
