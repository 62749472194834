import { useState } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { copyTextToClipboard } from "utils/common";

const InputField = styled.input`
  display: inline-block;
  padding-inline-start: 15px;
  width: 358px;
  line-height: 34px;
  font-size: 16px;
  text-overflow: ellipsis;
  color: var(--colors-mainText);
  border: 1px solid #dde5ee;
  background-color: var(--colors-default);
  border-end-start-radius: 4px;
  border-end-end-radius: 0;
  border-start-end-radius: 0;
  border-start-start-radius: 4px;
  border-inline-end: none;
`;
const CopyButton = styled.button<{ disabled: boolean }>`
  width: 94px;
  line-height: 34px;
  font-size: var(--typography-font-size-default);
  font-weight: var(--typography-font-weight-medium);
  text-align: center;
  color: var(--colors-default);
  outline: none;
  ${(p) => (p.disabled ? "" : "cursor: pointer;")}
  border-end-start-radius: 0;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
  border-start-start-radius: 0;
  background: var(--colors-primary);
  border: 1px solid var(--colors-primary);
`;

const CopyButtonCopied = styled.div`
  height: 38px;
  width: 94px;
  line-height: 34px;
  font-size: var(--typography-font-size-default);
  text-transform: uppercase;
  border-radius: 0 4px 4px 0;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.137 8.065L2.003 5.913C1.02 5.097-.526 6.049.178 7.271l2.648 4.457c.42.545 1.405 1.09 2.389 0 .42-.544 8.426-10.199 8.426-10.199.985-1.087-.28-2.038-1.123-1.223L4.137 8.065z' fill='%23fff'/%3E%3C/svg%3E");
  background-color: #00ca73;
  border: 1px solid #00ca73;
  background-repeat: no-repeat;
  background-position: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`;

const Tooltip = styled.div<{ visible: boolean }>`
  opacity: ${(p) => (p.visible ? "1" : "0")};
  position: absolute;
  inset-inline-start: calc(100% + 12px);
  padding: 6px 12px;
  line-height: 15px;
  font-size: 12px;
  background: #525f7f;
  border-radius: var(--shapes-border-radius-default);
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-default);
  white-space: nowrap;
  transition: opacity 1s;
`;

interface InputFieldWithCopyProps extends WithTranslation {
  value?: string;
  copyButtonEnabled?: boolean;
}

const InputFieldWithCopy = ({ t, value, copyButtonEnabled }: InputFieldWithCopyProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <Wrapper>
      <InputField value={value} disabled type="text" />
      {copied ? (
        <CopyButtonCopied />
      ) : (
        <CopyButton
          disabled={!copyButtonEnabled}
          onClick={() => {
            copyTextToClipboard(value || "");

            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
          }}
        >
          {t("Copy")}
        </CopyButton>
      )}

      <Tooltip visible={copied}>{t("Copied to the clipboard")}</Tooltip>
    </Wrapper>
  );
};

export default withTranslation()(InputFieldWithCopy);
