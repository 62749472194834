import { Component, createRef } from "react";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { WithTranslation, withTranslation } from "react-i18next";
import { updateRequest } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RequestTypeName, Request, CreateRequestPayload } from "types/models/request";
import RequestsAddEditPopup from "./RequestAddEditPopup";
import { getRequestTypeName } from "./requestUtils";

interface EditRequestScreenProps extends WithTranslation {
  isOpen: boolean;
  onClose: () => void;
  request: Request | null;
  onEditRequest: (error?: boolean) => void;
}

class EditRequestScreen extends Component<EditRequestScreenProps> {
  requestStateRef = createRef<{ loading: boolean }>();

  onEditRequest = async (request: CreateRequestPayload) => {
    const { onEditRequest } = this.props;

    const content = {
      startTime: request.startTime,
      endTime: request.endTime,
      allDay: request.allDay,
      updatedBy: window.global_store.profile.uuid,
    };

    if (request.attachments?.length) {
      content.attachments = request.attachments;
    }

    if (request.validityType) {
      content.validity = request.validity;
      content.validityType = request.validityType;
    }

    if (request.subtypeSpecificFields.length) {
      content.subtypeSpecificFields = request.subtypeSpecificFields;
    }

    try {
      await updateRequest({
        requestUuid: request.uuid,
        companyUuid: window.global_store.company.uuid,
        body: { content },
      });

      onEditRequest();
    } catch (err) {
      onEditRequest(true);
    }

    this.onClose();
  };

  onClose = () => {
    const { t, onClose } = this.props;

    if (this.requestStateRef?.current?.loading) {
      const isConfirmed = window.confirm(t("Cancel attachment upload?"));

      if (!isConfirmed) {
        return;
      }
    }

    onClose();
  };

  getHeader() {
    const { t, request } = this.props;

    if (!request) {
      return "";
    }

    switch (request?.requestType) {
      case RequestTypeName.medical:
        return t("Medical Request");
      case RequestTypeName.vacation:
        return t("Vacation Request");
      case RequestTypeName.other:
        return t("Custom Request");
      case RequestTypeName.overtime:
        return t("Schedule Limit Request");
      case RequestTypeName.hbConvert:
        return t("Extra Hour Conversion");

      default:
        return getRequestTypeName(request, t);
    }
  }

  render() {
    const { isOpen, request } = this.props;

    if (!request) {
      return null;
    }

    return (
      <SidePopupOverlay isOpen={isOpen} header={this.getHeader()} onClose={this.onClose} contentOverflow>
        <RequestsAddEditPopup
          stateRef={this.requestStateRef}
          isEditMode
          requestSettings={{ ...request, subtypes: [request.requestSubtypeState] }}
          prefillProfile={null}
          onClose={this.onClose}
          onYes={this.onEditRequest}
        />
      </SidePopupOverlay>
    );
  }
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(EditRequestScreen);
