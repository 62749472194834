import { Component, MouseEvent, PropsWithChildren } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { AggregationGroupType } from "types/models/dashboard";
import { paramsToUrlParams } from "utils/apiHelpers";
import { PermissionSectionName, hasPermisionAccess } from "utils/common";
import { PermissionRoleName } from "types/models/permissions";
import { UserProfileRole } from "types/models/userProfile";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import TabsComponent from "components/UI/TabsComponent";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import * as Icons from "./menu-svg-icons";

const Wrapper = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TitleAndBackWrapper = styled.div`
  padding: 16px 24px 0;
  margin-bottom: 8px;
`;

const BackButton = styled.div`
  width: fit-content;
  margin-bottom: 8px;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 100%;
  color: var(--colors-surface-700);
  cursor: pointer;

  svg {
    transform: rotate(${() => (window.global_store.isRTL ? 90 : 270)}deg);
    fill: var(--colors-surface-700);
  }
`;

const Title = styled.div<{ $withBackButton: boolean }>`
  height: 36px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  color: var(--colors-surface-900-p);
`;

const Controls = styled.div<{ $withoutBorder?: boolean }>`
  padding: 0 24px 0;
  border-bottom: 1px solid;
  border-bottom-color: ${(p) => (p.$withoutBorder ? "transparent" : "var(--colors-surface-150)")};
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  inset-inline-end: 24px;
  bottom: 0px;
  display: flex;
  height: 54px;
  align-items: center;
`;

type Tab = {
  show: boolean;
  name: string;
  path: string;
  active: boolean;
};

interface PageControlsProps extends PropsWithChildren, RouteComponentProps, WithTranslation, AllFlagsLDClient {
  title?: string;
  backButtonTitle?: string;
  backButtonOnclick?: (e: MouseEvent) => void;
}

class HeaderAction extends Component<PageControlsProps> {
  getSuperpunchPath = () => {
    const { full_name: fullName, id, uuid, teams } = window.global_store?.profile || {};
    let path = "";

    const hasSuperpunchPermission = hasPermisionAccess(PermissionSectionName.superpunch);

    if (hasSuperpunchPermission && fullName && id && uuid) {
      let params: Record<string, string | number> = {};

      params = {
        type: "group-edit",
        group_type: AggregationGroupType.teams,
      };

      // supervisor
      const team = teams[0];
      if (team) {
        params.group_uuid = team.uuid;
        params.search_value = team.name;
      }

      path = `/punches${paramsToUrlParams(params)}`;
    }

    return path;
  };

  getTabs = (): Tab[] | null => {
    const { location, t, flags } = this.props;

    if (/^\/(punches|on-calls)/.test(location.pathname)) {
      const superpunchPath = this.getSuperpunchPath();

      return [
        {
          show: !!superpunchPath,
          name: t("Super Punch"),
          path: superpunchPath,
          active: /^\/punches/.test(location.pathname) && /\?type=(edit|group-edit)/.test(location.search),
        },
        {
          show:
            hasPermisionAccess(PermissionSectionName.viewPunches) ||
            hasPermisionAccess(PermissionSectionName.managePunches),
          name: t("Punches"),
          path: "/punches/",
          active:
            /^\/punches\/$/.test(location.pathname) ||
            (/^\/punches$/.test(location.pathname) && /\?label=/.test(location.search)),
        },
        {
          show:
            hasPermisionAccess(PermissionSectionName.onCallManagement) ||
            hasPermisionAccess(PermissionSectionName.onCallRequest),
          name: t("On Calls"),
          path: "/on-calls/",
          active: /^\/on-calls/.test(location.pathname),
        },
      ];
    }

    if (/^\/(timesheets\/(list|time)|activities)/.test(location.pathname)) {
      return [
        {
          show: true,
          name: t("Calendar"),
          path: "/activities/calendar",
          active: /^\/activities\/calendar/.test(location.pathname),
        },
        {
          show: true,
          name: t("Timesheet"),
          path: "/timesheets",
          active: /^\/timesheets\/time/.test(location.pathname),
        },
        {
          show: true,
          name: t("Time log"),
          path: "/activities",
          active: /^\/activities$/.test(location.pathname),
        },
        {
          show: true,
          name: t("Approvals"),
          path: "/timesheets/list",
          active: /^\/timesheets\/list/.test(location.pathname),
        },
      ];
    }

    if (/^\/company\/(locations|job-sites)\/$/.test(location.pathname)) {
      return [
        {
          show: hasPermisionAccess(PermissionSectionName.locations),
          name: t("Locations"),
          path: "/company/locations",
          active: /^\/company\/locations\//.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.jobSides),
          name: t("Job Sites"),
          path: "/company/job-sites",
          active: /^\/company\/job-sites\//.test(location.pathname),
        },
      ];
    }

    if (
      /^\/payroll\/(payroll-groups|lock-periods|lock-periods-dectivated-employees|digital-signatures|export)(\/|$)$/.test(
        location.pathname,
      )
    ) {
      return [
        {
          show: true,
          name: t("Payroll Groups"),
          path: "/payroll/payroll-groups/",
          active: /^\/payroll\/payroll-groups\/$/.test(location.pathname),
        },
        {
          show: true,
          name: t("Locking Groups"),
          path: "/payroll/lock-periods/",
          active: /^\/payroll\/lock-periods\//.test(location.pathname),
        },
        {
          show: true,
          name: t("Deactivated Members"),
          path: "/payroll/lock-periods-dectivated-employees/",
          active: /^\/payroll\/lock-periods-dectivated-employees\//.test(location.pathname),
        },
        {
          show: true,
          name: t("Digital Signatures"),
          path: "/payroll/digital-signatures",
          active: /^\/payroll\/digital-signatures/.test(location.pathname),
        },
        {
          show: true,
          name: t("Layouts"),
          path: "/payroll/export",
          active: /^\/payroll\/export/.test(location.pathname),
        },
      ];
    }

    if (/^\/company\/(subsidiaries|departments|teams|positions)/.test(location.pathname)) {
      return [
        {
          show: hasPermisionAccess(PermissionSectionName.subsidiaries),
          name: t("Subsidiaries"),
          path: "/company/subsidiaries/",
          active: /^\/company\/subsidiaries/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.departments),
          name: t("Departments"),
          path: "/company/departments/",
          active: /^\/company\/departments\//.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.teams),
          name: t("Teams"),
          path: "/company/teams/",
          active: /^\/company\/teams/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.positions),
          name: t("Positions"),
          path: "/company/positions/",
          active: /^\/company\/positions/.test(location.pathname),
        },
      ];
    }

    if (/^\/schedules\/($|exceptions|breaks$)/.test(location.pathname)) {
      const schedulesRoutes = [
        {
          show: hasPermisionAccess(PermissionSectionName.schedules),
          name: t("Schedules"),
          path: "/schedules/",
          active: /^\/schedules\/$/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.schedules),
          name: t("Exceptions"),
          path: "/schedules/exceptions",
          active: /^\/schedules\/exceptions/.test(location.pathname),
        },
      ];

      if (flags.breakTypes) {
        schedulesRoutes.push({
          show: hasPermisionAccess(PermissionSectionName.schedules),
          name: t("Breaks"),
          path: "/schedules/breaks",
          active: /^\/schedules\/breaks/.test(location.pathname),
        });
      }

      return schedulesRoutes;
    }

    if (
      /^\/company\/(profile|date-time|sso|password|permissions)/.test(location.pathname) ||
      /^\/data-management\/(files)/.test(location.pathname) ||
      /^\/pegador\/(import|$)/.test(location.pathname) ||
      /^\/time-clocks\/$/.test(location.pathname)
    ) {
      return [
        {
          show: hasPermisionAccess(PermissionSectionName.companyProfile),
          name: t("Company Profile"),
          path: "/company/profile",
          active: /^\/company\/profile$/.test(location.pathname),
        },
        // {
        //   show: !!window.global_store.beta,
        //   name: t("Date & Time"),
        //   path: "/company/date-time",
        //   active: /^\/company\/date-time$/.test(location.pathname),
        // },
        {
          show: hasPermisionAccess(PermissionSectionName.security),
          name: t("Sso"),
          path: "/company/sso",
          active: /^\/company\/sso$/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.security),
          name: t("Password"),
          path: "/company/password",
          active: /^\/company\/password$/.test(location.pathname),
        },
        {
          show: window.global_store.profile.permission_roles?.some(() =>
            hasPermisionAccess(PermissionSectionName.managePermissions),
          ),
          name: t("Permissions"),
          path: "/company/permissions",
          active: /^\/company\/permissions$/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.timeClocks),
          name: t("REPs List"),
          path: "/time-clocks/",
          active: /^\/time-clocks\/$/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.timeClocks),
          name: t("Import AFD/AFDT"),
          path: "/pegador/import",
          active: /^\/pegador\/import$/.test(location.pathname),
        },
        {
          show: hasPermisionAccess(PermissionSectionName.dataManagement),
          name: t("Import Files"),
          path: "/data-management/files",
          active: /^\/data-management\/files$/.test(location.pathname),
        },
      ];
    }

    if (/^\/billing\/(summary|information)/.test(location.pathname)) {
      return [
        {
          show: window.global_store.profile.role === UserProfileRole.employer,
          name: t("Billing Summary"),
          path: "/billing/summary",
          active: /^\/billing\/summary$/.test(location.pathname),
        },
        {
          show: window.global_store.profile.role === UserProfileRole.employer,
          name: t("Billing address"),
          path: "/billing/information",
          active: /^\/billing\/information$/.test(location.pathname),
        },
      ];
    }

    return null;
  };

  render() {
    const { children, title, backButtonTitle, backButtonOnclick, history } = this.props;
    const tabs = this.getTabs();

    if (!children && !tabs && !title && !backButtonTitle) {
      return null;
    }

    const withBackButton = !!(backButtonOnclick && backButtonTitle);

    return (
      <Wrapper className="page-controls">
        {(withBackButton || title) && (
          <TitleAndBackWrapper>
            {withBackButton && (
              <BackButton onClick={backButtonOnclick}>
                <span>{backButtonTitle}</span>
                <Icons.ArrowDown />
              </BackButton>
            )}

            {title && <Title $withBackButton={withBackButton}>{title}</Title>}
          </TitleAndBackWrapper>
        )}

        <Controls $withoutBorder={withBackButton || !tabs?.length}>
          {tabs?.length && (
            <TabsComponent
              tabs={tabs
                .filter((tab) => tab.show)
                .map((tab) => ({
                  name: tab.path,
                  label: tab.name,
                  isActive: tab.active,
                }))}
              onChange={(activeTabPath) => {
                history.push(activeTabPath);
              }}
            />
          )}
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </Controls>
      </Wrapper>
    );
  }
}

export default withLDConsumer()(withTranslation(TranslationNamespaces.sidebar)(withRouter(HeaderAction)));
