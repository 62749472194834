import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import GlobalContext from "context/global-context";
import { v4 as uuidv4 } from "uuid";
import { fireEvent } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { getTasksListForUserProfile } from "components/Projects/projectsApiUtils";
import CONFIG from "config";
import { stylesheet } from "astroturf";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { UserProfileTask } from "components/Projects/projectsApiTypes";
import { FilteredEmployeeProfile, RequestUserProfile } from "types/models/userProfile";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import HeaderRow from "../components/HeaderRow";
import ActivityRow from "../components/ActivityRow";
import WorkingHoursRow from "../components/WorkingHoursRow";
import TotalsRow from "../components/TotalsRow";
import TimesheetSnackbar from "../components/TimesheetSnackbar";
import {
  cancelActivitiesForTaskInRange,
  getTimesheet,
  ListTimesheetsResponse,
  TimesheetDataQuery,
  TimesheetResponse,
} from "../timesheet-api.service";
import AddNewTaskActivitiesRow from "../components/AddNewTaskActivitiesRow";
import { ParsedTimesheetData, TimesheetApprovalStatus, TimesheetsListItem } from "../timesheet.types";
import {
  convertTimesheetsIntoLockedDatesArray,
  createDatesArrayFromTodayToGivenDate,
  createDatesStartEndDate,
  parseTimesheet,
  populateResponseWithEmptyTasks,
} from "../timesheet.helpers";
import { TimesheetContext } from "../timesheets.context";
import Loader from "../../styled/Loader";

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  .common-text {
    font-weight: var(--typography-font-weight-medium);
    font-size: 12px;
    line-height: 15px;
    color: var(--colors-surface-900-p);
  }
  .unasigned-cell {
    font-size: var(--typography-font-size-default);
  }
  .task-cell {
    width: 100%;
    min-width: 235px;
    justify-content: flex-start;
    padding-inline-end: 13px;
  }
  .common-cell {
    justify-content: flex-start;
    width: 93px;
    min-width: 93px;
  }
  .total-cell {
    justify-content: flex-start;
    width: 93px;
    min-width: 93px;
    padding-inline-end: 4px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-bold);
  }
`;

const styles = stylesheet`
  .Status {
    position: absolute;
    top: -47px;
    inset-inline-end: 0;
    display: flex;
    gap: 10px;
    align-items: center;

    .StatusHint {
      height: 20px;
      fill: var(--colors-surface-500);

      .StatusHintText {
        transition: all 200ms;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        bottom: 30px;
        inset-inline-end: 0;
        padding: 24px;
        width: max-content;
        color: var(--colors-surface-600);
        background: var(--colors-surface-0);
        box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
        border-radius: var(--shapes-border-radius-default);
        max-width: 100vh;
        overflow-wrap: break-word;
        white-space: break-spaces;
        text-overflow: ellipsis;
        max-height: 8em;
        overflow: auto;
      }

      &:hover {
        .StatusHintText {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .ButtonsRow {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .End {
      display: flex;
      gap: 12px;

      button {
        padding: 1px 16px;
      }
    }
  }
  .NoActivity {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px;
    color: var(--colors-surface-600);
    font-size: 15px;
    border-top: 1px solid var(--colors-surface-150);
  }
`;

interface TimesheetTableProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  userProfile: RequestUserProfile;
}

export default ({ startDate, endDate, userProfile }: TimesheetTableProps): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const context = useContext(GlobalContext);
  const timesheetContext = useContext(TimesheetContext);
  const [timesheets, setTimesheets] = useState<TimesheetsListItem[]>();
  const [lockedDateEnd, setLastLockedDateEnd] = useState<string | null>(null);
  const [showAddNewTasksRow, setShowAddNewTasksRow] = useState<boolean>(false);
  const [timesheetDataRaw, setData] = useState<TimesheetResponse | null>(null);
  const [tasksList, setTasksList] = useState<UserProfileTask[]>([]);
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [taskRowToRemove, setTaskRowToRemove] = useState<string | null>(null);

  const onTaskRowRemove = async (taskUuid: string, parsedData: ParsedTimesheetData): Promise<void> => {
    if (!timesheetDataRaw) {
      return;
    }

    const rowByTaskUuid = parsedData.tasksData.find((e) => e.taskUuid === taskUuid);

    if (rowByTaskUuid) {
      const total = rowByTaskUuid.dates.reduce((partialSum, a) => partialSum + a, 0);
      // if totals for row is 0 then just remove it from local object. Otherwise call api
      if (!total) {
        const newTimesheetData: TimesheetResponse = { ...timesheetDataRaw };
        newTimesheetData.content.days.forEach((day) => {
          delete day.activitiesMinutesByTasks[taskUuid];
        });
        setData(newTimesheetData);

        return;
      }
    }

    const company = await context.getCompany();
    const response = await cancelActivitiesForTaskInRange({
      companyUuid: company.uuid,
      userProfileUuid: userProfile.uuid,
      body: {
        from: startDate.format(CONFIG.apiDateFormat),
        to: endDate.format(CONFIG.apiDateFormat),
        taskUuid,
        updatedBy: window.global_store.profile.uuid,
      },
    });

    if (response) {
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message: t("All pending activities were successfully declined"),
        status: "success",
      });

      let responseData = { content: response.content.data, metadata: response.content.metadata };
      responseData = responseData && populateResponseWithEmptyTasks(responseData, parsedData);

      setData(responseData);
    }
  };

  const onActivityCellChange = (taskUuid: string, date: string, value: number) => {
    if (!timesheetDataRaw) {
      return;
    }

    setData({
      ...timesheetDataRaw,
      content: {
        ...timesheetDataRaw.content,
        days: timesheetDataRaw.content.days.map((day) => {
          if (day.date === date) {
            if (day.activitiesMinutesByTasks[taskUuid]) {
              day.activitiesMinutesByTasks[taskUuid].activitiesMinutes = value;
            } else {
              day.activitiesMinutesByTasks[taskUuid] = {
                activitiesMinutes: value,
              };
            }
            day.dailyActivitiesMinutes = 0;
            for (const task of Object.keys(day.activitiesMinutesByTasks)) {
              day.dailyActivitiesMinutes += day.activitiesMinutesByTasks[task].activitiesMinutes;
            }
          }

          return day;
        }),
      },
    });
  };
  const [loadTask] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const { content } = await getTasksListForUserProfile({
      companyUuid: company.uuid,
      userProfileUuid: userProfile.uuid,
      requestedBy: window.global_store.profile.uuid,
    });

    setTasksList(content || []);
  }, [userProfile.uuid]);

  const [loadData, loadingData] = useAsyncCallback(
    async () => {
      const company = await context.getCompany();
      const requestArgs: TimesheetDataQuery = {
        from: startDate.format(CONFIG.apiDateFormat),
        to: endDate.format(CONFIG.apiDateFormat),
        userProfileUuid: userProfile.uuid,
        requestedBy: window.global_store.profile.uuid,
        companyUuid: company.uuid,
      };

      await timesheetContext.setDates(startDate.clone().subtract(45, "days"), endDate);

      try {
        const promises: [Promise<TimesheetResponse>, Promise<ListTimesheetsResponse>] = [
          getTimesheet(requestArgs),
          timesheetContext.fetchTimeSheets(false, [TimesheetApprovalStatus.pending, TimesheetApprovalStatus.approved]),
        ];
        const response = await Promise.all(promises);
        setData(response[0]);
        setTimesheets(response[1].content);
        const lastLockDate =
          response[1].metadata.find((up: FilteredEmployeeProfile) => up.uuid === userProfile.uuid)?.lastLockDate ??
          (userProfile as unknown as RequestUserProfile)?.lastLockDate;
        setLastLockedDateEnd(lastLockDate);

        let lockedDates = (
          lastLockDate ? createDatesStartEndDate(startDate.format(CONFIG.apiDateFormat), lastLockDate) : []
        ).concat(convertTimesheetsIntoLockedDatesArray(response[1].content));

        if (!timesheetContext.settings?.allowFutureActivities) {
          lockedDates = lockedDates.concat(createDatesArrayFromTodayToGivenDate(endDate));
        }

        setUnavailableDates(lockedDates);
      } catch (error) {
        fireEvent("timesheet_message", {
          uuid: uuidv4(),
          message: t("Failed to get activities for employee"),
          status: "error",
        });
        console.log(error);
      }

      setShowAddNewTasksRow(false);
    },
    [startDate, endDate, userProfile, t],
    true,
  );

  useEffect(() => void loadData(), [loadData]);
  useEffect(() => void loadTask(), [loadTask]);
  useEffect(() => void timesheetContext.setReloadDataCallback(loadData), [startDate, endDate, userProfile.uuid]);

  const [parsedData, setParsedData] = useState<ParsedTimesheetData | null>(null);

  useEffect(() => {
    const prsdData = timesheetDataRaw ? parseTimesheet(timesheetDataRaw, tasksList) : null;
    setParsedData(prsdData);
  }, [timesheetDataRaw, tasksList]);

  if (!timesheetDataRaw || !parsedData || loadingData) {
    return <Loader />;
  }

  const canEdit = parsedData.dates.some((d) => !unavailableDates.includes(d));

  return (
    <TableWrapper>
      <HeaderRow dates={parsedData.dates} isDayPlannedArray={parsedData.isDayPlannedArray} loading={loadingData} />
      {!!parsedData.workingHours.filter((e) => e !== 0).length && (
        <WorkingHoursRow
          loading={loadingData}
          dates={parsedData.dates}
          data={parsedData.unassignedMinutes}
          isDayPlannedArray={parsedData.isDayPlannedArray}
          highlighted
          label={t("Unassigned working time")}
        />
      )}

      {parsedData.tasksData.length > 0 || showAddNewTasksRow ? (
        parsedData.tasksData.map((data, i) => (
          <ActivityRow
            key={data.taskUuid}
            data={data}
            loading={loadingData}
            tasksList={tasksList!}
            userProfile={userProfile}
            isDayPlannedArray={parsedData.isDayPlannedArray}
            unavailableDates={unavailableDates}
            dates={parsedData.dates}
            forceRefetchTable={loadData}
            onCellTimeChange={(taskUuid, date, value) => {
              onActivityCellChange(taskUuid, date, value);
            }}
            onRemoveRow={(taskUuid) => {
              setTaskRowToRemove(taskUuid);
            }}
            settings={timesheetContext.settings}
          />
        ))
      ) : (
        <div className={styles.NoActivity}>{t("No hours worked yet")}</div>
      )}

      {showAddNewTasksRow && !!parsedData.additionalTasks?.length && (
        <AddNewTaskActivitiesRow
          onAddTaskToTable={(taskUuid) => {
            if (!timesheetDataRaw) {
              return;
            }
            const newTimesheetData: TimesheetResponse = { ...timesheetDataRaw };
            newTimesheetData.content.days.forEach((day) => {
              if (!day.activitiesMinutesByTasks[taskUuid]) {
                day.activitiesMinutesByTasks[taskUuid] = {
                  activitiesMinutes: 0,
                };
              }
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newTimesheetData.metadata.tasksByUuid[taskUuid] = tasksList.find((tl) => tl.uuid === taskUuid)!;
            setData(newTimesheetData);
            setShowAddNewTasksRow(false);
          }}
          onRemoveRow={() => setShowAddNewTasksRow(false)}
          tasksOptions={parsedData!.additionalTasks}
        />
      )}

      <TotalsRow
        workingHours={parsedData!.workingHours}
        loading={loadingData}
        data={parsedData!.tasksData}
        dates={parsedData!.dates}
        timesheets={timesheets}
        lockedDateEnd={lockedDateEnd}
        settings={timesheetContext.settings}
      />
      <ModalDialog
        isOpen={!!taskRowToRemove}
        onClose={() => {
          setTaskRowToRemove(null);
        }}
      >
        <Lightbox
          title={t("remove-timesheet-row-confirmation-title")}
          text={t("remove-timesheet-row-confirmation-description")}
          buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onClose={() => {
            setTaskRowToRemove(null);
          }}
          onYes={() => {
            if (taskRowToRemove) {
              void onTaskRowRemove(taskRowToRemove, parsedData);
            }

            setTaskRowToRemove(null);
          }}
        />
      </ModalDialog>
      <TimesheetSnackbar />
      <div className={styles.ButtonsRow}>
        <div className={styles.Start}>
          {!!parsedData!.additionalTasks?.length && canEdit && (
            <AddRemoveLink
              label={t("Add task activity")}
              onClick={() => {
                setShowAddNewTasksRow(true);
              }}
            />
          )}
        </div>
        <div className={styles.End} />
      </div>
    </TableWrapper>
  );
};
