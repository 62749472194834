import axios from "axios";
import { getHeaders } from "./common";
import CONFIG from "../../config";

export function importAfdAfdt(data, onUploadProgress) {
  const formData = new FormData();
  formData.append("upload", data.attachment);
  formData.append("companyId", data.companyId);
  formData.append("repExternalId", data.repExternalId);
  formData.append("repSerialNum", data.serialNumber);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  return axios.post(`${CONFIG.protocol}${CONFIG.api_management}/exporting/companies/${data.companyUUID}`, formData, {
    headers,
    onUploadProgress,
  });
}
