import { MouseEventHandler, PropsWithChildren, ReactElement } from "react";
import classnames from "classnames";
import { Oval } from "react-loader-spinner";

import "./Button.scss";

interface CommonUIButtonProps extends PropsWithChildren {
  label?: string;
  size?: "small" | "large";
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  startIcon?: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

interface UIButtonProps extends CommonUIButtonProps {
  color?: "main" | "positive" | "negative";
  variant?: "primary";
}

interface UIButtonSecondaryProps extends CommonUIButtonProps {
  color?: "neutral";
  variant?: "secondary";
}

interface UIButtonTertiaryProps extends CommonUIButtonProps {
  color?: "main" | "negative" | "neutral";
  variant?: "tertiary";
}

type Props = UIButtonProps | UIButtonSecondaryProps | UIButtonTertiaryProps;

const noop = () => {};

const ButtonComponent = ({
  label,
  color = "main",
  size = "small",
  variant = "tertiary",
  disabled = false,
  loading = false,
  startIcon,
  children,
  className,
  onClick,
}: Props): ReactElement => {
  const classNameCombined = classnames(
    className,
    "ui-button-component",
    `ui-button-component_color-${color}`,
    `ui-button-component_size-${size}`,
    `ui-button-component_variant-${variant}`,
    {
      "ui-button-component_disabled": disabled,
      "ui-button-component_loading": loading,
    },
  );

  const content = !loading ? label || children : <></>;
  const onClickHandler = disabled || loading ? noop : onClick;

  return (
    <button type="button" className={classNameCombined} onClick={onClickHandler}>
      {startIcon && !loading && <span className="ui-button-component_start-icon">{startIcon}</span>} {content}
      <Oval visible={loading} strokeWidth="4" wrapperClass="ui-button-component_loader-wrapper" />
    </button>
  );
};

export default ButtonComponent;
