import React, { Component, useState } from "react";
import ClickOutside from "react-click-outside";
import BEM from "utils/BEM";
import "./Select.scss";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import c from "classnames";
import { stylesheet } from "astroturf";
import Option from "./SelectOption";
import { ReactComponent as ChevronIcon } from "./img/select-arrow.svg";
import { SearchBar } from "../../Activities/TimeTracker/components/SearchBar";

const b = BEM.b("ui-select");

const styles = stylesheet`
  .SearchBar > div {
    height: 36px;
    border-radius: var(--shapes-border-radius-default);
    border: 1px solid var(--colors-surface-150);
    .SearchIcon {
      margin-top: 0;
    }
  }
`;

const GroupLabel = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a1b2cf;
  margin: 16px 6px 8px 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  padding-inline-end: 20px;

  .icon {
    position: absolute;
    inset-inline-end: 0;
    top: 50%;
    ${(p) => (p.$opened ? "transform: translate(-50%, -50%) rotate(180deg);" : "transform: translate(-50%, -50%);")}
  }
`;
const Wrapper = styled.div`
  .ui-select__input {
    ${rtl`
    padding: 0 28px 0 16px;
  `}
  }
  .ui-select__input::after {
    ${rtl`
    right: 16px;
  `}
  }

  .ui-select__option-list {
    width: 100%;
  }

  .ui-select__option-list > div:first-child > div {
    margin-top: 6px;
  }
`;

const GroupSelect = ({ group, preselected, returnOption, selectValue, value }) => {
  const [opened, setOpened] = useState(false);
  const isActive = (optionValue) => optionValue === value;

  return (
    <div key={group.label}>
      <GroupLabel $opened={opened} onClick={() => setOpened(!opened)}>
        {group.label}
        <ChevronIcon className="icon" />
      </GroupLabel>
      {opened
        ? group.options.map((option) => (
            <Option
              key={option.value}
              preselected={preselected && option.value === preselected.value}
              onSelect={() => {
                if (returnOption) {
                  selectValue(option);
                } else {
                  selectValue(option.value);
                }
              }}
              label={option.label}
              active={isActive(option.value)}
            />
          ))
        : null}
    </div>
  );
};

class GroupedSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      preselected: null,
      searchValue: "",
      filteredValues: props.groupedOptions,
    };
  }

  toggleOpen(open) {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;
    const preselected = !isOpen ? null : this.state.preselected;

    // eslint-disable-next-line react/no-string-refs
    if (isOpen) this.refs.inputKeyHandler.focus();

    this.setState({ isOpen, preselected });
  }

  handleKeyPress(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const { groupedOptions, returnOption } = this.props;
    let options = [];
    groupedOptions.forEach((group) => {
      options = options.concat(group.options);
    });
    if (options && options.length) {
      if (ev.which === 27) {
        this.toggleOpen(false);
      } else if (ev.which === 38 || ev.which === 40) {
        this.preselectValue(ev.which === 38);
      } else if (ev.which === 13) {
        if (this.state.preselected) {
          if (returnOption) {
            this.selectValue(this.state.preselected);
          } else {
            this.selectValue(this.state.preselected.value);
          }
        } else {
          this.toggleOpen(false);
        }
      }
    }
    return false;
  }

  preselectValue(isUp) {
    const { groupedOptions } = this.props;
    let options = [];
    groupedOptions.forEach((group) => {
      options = options.concat(group.options);
    });
    const { preselected } = this.state;
    if (preselected) {
      let nextIndex = options.map((e) => e.value).indexOf(preselected.value);
      if (options.length > 1) {
        nextIndex = isUp ? nextIndex - 1 : nextIndex + 1;
        if (nextIndex < 0) {
          nextIndex = options.length - 1;
        } else if (nextIndex > options.length - 1) {
          nextIndex = 0;
        }
      }
      this.setState({ preselected: options[nextIndex] });
    } else {
      this.setState({ preselected: options[0] });
    }
  }

  search(value) {
    this.setState({ searchValue: value });
    if (value) {
      const filteredTasks = this.props.groupedOptions
        .map((go) => ({
          ...go,
          options: go.options?.filter((o) => new RegExp(value, "gi").test(o.label)),
        }))
        .filter((go) => go.options.length > 0);
      this.setState({
        filteredValues: filteredTasks,
      });
    } else {
      this.setState({
        filteredValues: this.props.groupedOptions,
      });
    }
  }

  selectValue(value) {
    const { onChange } = this.props;
    if (onChange && typeof onChange === "function") {
      onChange(value);
    }
    this.toggleOpen(false);
  }

  renderSelectedLabel() {
    const { value, groupedOptions, placeholder } = this.props;
    let options = [];
    groupedOptions.forEach((group) => {
      options = options.concat(group.options);
    });
    const selectedOption =
      value === "0" || value === 0 || value || value === "" ? options.find((option) => option.value === value) : "";
    return selectedOption ? selectedOption.label : placeholder || value;
  }

  render() {
    const { disabled, modifiers, groupedOptions, returnOption, className, withSearch } = this.props;
    const { preselected, filteredValues, searchValue } = this.state;

    return (
      <Wrapper className={c(b("wrapper", modifiers), className)}>
        <ClickOutside onClickOutside={() => this.toggleOpen(false)}>
          <input
            type="text"
            onKeyDown={this.handleKeyPress.bind(this)}
            style={{ zIndex: 0, position: "absolute", border: "none", width: "100%", left: 0 }}
            // eslint-disable-next-line react/no-string-refs
            ref="inputKeyHandler"
          />
          <div
            className={b("input", { open: this.state.isOpen, disabled })}
            onClick={() => !disabled && this.toggleOpen()}
          >
            {this.renderSelectedLabel()}
          </div>
          {this.state.isOpen && (
            <div className={b("option-list")}>
              {withSearch ? (
                <div className={styles.SearchBar}>
                  <SearchBar placeholder="" searchValue={searchValue} setSearchValue={(value) => this.search(value)} />
                </div>
              ) : null}
              {filteredValues.map((group) => (
                <GroupSelect
                  group={group}
                  preselected={preselected}
                  returnOption={returnOption}
                  selectValue={(value) => this.selectValue(value)}
                  value={this.props.value}
                />
              ))}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default GroupedSelect;
