import { APIResponse, sendRequest } from "./common";
import { AddPositionRequestData, AddPositionResponseData, GetPositionsResponseData } from "./types";

type TODORequestData = any;

type TODOResponseData = Promise<unknown>;

export function getPositions(): APIResponse<GetPositionsResponseData> {
  return sendRequest({}, "GET", "/positions/");
}

export function addPosition(data: AddPositionRequestData): APIResponse<AddPositionResponseData> {
  return sendRequest(data.body, "POST", "/positions");
}

export function updatePosition(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PUT", `/positions/${data.id}`);
}

export function deletePosition(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "DELETE", `/positions/${data.id}`);
}

export function addEmployeesToPosition(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/positions/${data.uuid}/add_employees`);
}
