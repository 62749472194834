import { Suspense, useEffect } from "react";
import theme from "styles/mainTheme";
import i18n from "i18next";
import { ThemeProvider } from "styled-components";
import { asyncWithLDProvider, AsyncProviderConfig } from "launchdarkly-react-client-sdk";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { UserProfile } from "types/models/userProfile";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GlobalContextProvider from "../context/GlobalContextProvider";
import CONFIG from "../config";
import SubscriptionContextProvider from "./Billing/context/subscription-context.provider";

const getLDProvider = async (profile: UserProfile | undefined) => {
  const user: AsyncProviderConfig["user"] = {};

  if (profile?.company) {
    user.key = profile.ffid;
    user.custom = {
      companyName: profile.company.name,
      companyUuid: profile.company.uuid,
    };
  }

  return await asyncWithLDProvider({
    clientSideID: CONFIG.ldClientId || "",
    user,
  });
};

const getAppWithProviders = async (profile: UserProfile | undefined, router: JSX.Element) => {
  const queryClient = new QueryClient();
  // launchdarkly feature flags
  const LDProvider = await getLDProvider(profile);

  if (CONFIG.appEnv === "development" && window.HubSpotConversations) {
    // remove redundant chat
    window.HubSpotConversations.widget.remove();
  }

  return (
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={CONFIG.googleOAuthKey!}>
          <ThemeProvider theme={{ ...theme, dir: i18n.dir() }}>
            <Suspense fallback={<div />}>
              <GlobalContextProvider>
                <SubscriptionContextProvider>{router}</SubscriptionContextProvider>
              </GlobalContextProvider>
            </Suspense>
          </ThemeProvider>
        </GoogleOAuthProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </LDProvider>
  );
};

export default getAppWithProviders;
