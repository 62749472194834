import { KvStorage } from "services/kv-storage";
import { Injectable } from "services/di";

async function idbPut<T = any>(db: IDBDatabase, osName: string, value: T, key: IDBValidKey) {
  const promise = new Promise<IDBValidKey>((res, rej) => {
    const tx = db.transaction([osName], "readwrite");
    const os = tx.objectStore(osName);
    const putReq = os.put(value, key);
    putReq.addEventListener("success", () => res(putReq.result), { once: true });
    putReq.addEventListener("error", rej, { once: true });
  });
  return promise;
}

async function idbGet<T = any>(db: IDBDatabase, osName: string, key: IDBValidKey) {
  const promise = new Promise<T>((res, rej) => {
    const tx = db.transaction([osName], "readonly");
    const os = tx.objectStore(osName);
    const getReq = os.get(key);
    getReq.addEventListener("success", () => res(getReq.result), { once: true });
    getReq.addEventListener("error", rej, { once: true });
  });
  return promise;
}

export type OpenOptions = {
  /** Database name */
  dbName: string;
};

/**
 * Open connection on first usage.
 */
@Injectable()
export class KvStorageIdb implements KvStorage {
  db?: IDBDatabase;
  protected osName = "kv-storage-idb";

  constructor(private opts: OpenOptions) {}

  protected async open() {
    const { dbName } = this.opts;
    const dbPromise = new Promise<IDBDatabase>((res, rej) => {
      const openReq = globalThis.indexedDB.open(dbName);
      openReq.addEventListener("success", () => res(openReq.result), { once: true });
      openReq.addEventListener("error", rej, { once: true });
      openReq.addEventListener(
        "upgradeneeded",
        () => {
          openReq.result.createObjectStore(this.osName); // use out-of-line keys
        },
        { once: true },
      );
    });
    const db = await dbPromise;
    this.db = db;
  }

  protected openPromise?: Promise<void>;
  protected async safeOpen() {
    if (this.openPromise == null) {
      this.openPromise = this.open();
    }
    await this.openPromise;
  }

  async setItem<T = any>(key: IDBValidKey, value: T) {
    await this.safeOpen();
    await idbPut(this.db!, this.osName, value, key);
  }

  async getItem<T = any>(key: IDBValidKey): Promise<T> {
    await this.safeOpen();
    const value = await idbGet(this.db!, this.osName, key);
    return value;
  }
}
