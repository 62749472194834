import styled from "styled-components";
import { useState } from "react";
import TextInputControl from "components/controls/TextInputControlNew";
import NewCheckbox from "../NewCheckbox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TextInputWrapper = styled.div`
  max-width: 200px;
`;

type SwitchableTextFieldProps = {
  value: string | null;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  locked?: boolean;
  hasError?: boolean;
  onChange: (val: string | null) => void;
};

const SwitchableTextField = ({
  value,
  label,
  placeholder,
  disabled,
  locked,
  hasError,
  onChange,
}: SwitchableTextFieldProps) => {
  const [isChecked, setChecked] = useState(!!value);

  return (
    <Wrapper>
      <NewCheckbox
        ios
        small
        checked={isChecked}
        disabled={disabled || locked}
        label={label}
        onChange={(checked) => {
          setChecked(checked);
          if (!checked) {
            onChange(null);
          }
        }}
      />

      {isChecked && (
        <TextInputWrapper>
          <TextInputControl
            value={value || ""}
            placeholder={placeholder}
            disabled={disabled || locked}
            locked={locked}
            type="text"
            error={hasError}
            onChange={(e) => {
              onChange(e.target.value || null);
            }}
          />
        </TextInputWrapper>
      )}
    </Wrapper>
  );
};

export default SwitchableTextField;
