import React, { Component } from "react";
import styled from "styled-components";
import * as images from "components/svg-images";

const BoxWrapper = styled.button`
  outline: none;
  width: 136px;
  min-width: 136px;
  height: 136px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: var(--shapes-border-radius-default);
  opacity: ${(props) => (props.active ? "1" : "0.6")};
  position: relative;
  justify-content: center;
  padding-bottom: 36px;
  cursor: pointer;
  background: none;
`;
const Icon = styled.div``;
const Label = styled.div`
  height: 36px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.mainText};
  border-top: 1px solid ${(props) => props.theme.colors.secondary};
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
`;
const Check = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: ${(props) => (props.active ? props.theme.colors.success3 : props.theme.colors.inputMaskText)};
`;

class PunchTypeBox extends Component {
  getPunchIcon(type) {
    switch (type) {
      case "tablet":
        return images.punchIconTablet;
      case "web":
        return images.punchIconComp;
      case "app":
        return images.punchIconMobile;
      case "rep":
        return images.punchIconBiometric;

      default:
        return "";
    }
  }
  render() {
    const { type, label, active, onClick } = this.props;
    return (
      <BoxWrapper active={active} onClick={onClick}>
        <Check active={active}>{images.checkMark}</Check>
        <Icon>{this.getPunchIcon(type)}</Icon>
        <Label>{label}</Label>
      </BoxWrapper>
    );
  }
}
export default PunchTypeBox;
