import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import { urlParam } from "utils/common";
import ActivitiesPage from "components/Activities/ActivitiesPage";
import { ActivitiesCalendarPageWrapper } from "./ActivitiesCallendarPageWrapper";
import TimesheetProvider, { TimesheetContext } from "../Timesheets/timesheets.context";

const RouteActivities = ({ match: { path }, location: { search } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <TimesheetProvider>
      <Switch>
        <Redirect exact strict from={`${path}/${search}`} to={`${path}${search}`} />
        <Route
          exact
          path={`${path}`}
          render={(props) => (
            <TimesheetContext.Consumer>
              {(timesheetContext) => (
                <ActivitiesPage
                  timesheetContext={timesheetContext}
                  uuid={urlParam("uuid")}
                  id={urlParam("id")}
                  label={urlParam("label")}
                  startDate={urlParam("startDate")}
                  endDate={urlParam("endDate")}
                  {...props}
                />
              )}
            </TimesheetContext.Consumer>
          )}
        />

        <Route
          exact
          path={`${path}/calendar`}
          render={(props) => (
            <ActivitiesCalendarPageWrapper
              from={urlParam("from") as string | undefined}
              to={urlParam("to") as string | undefined}
              userProfileUuid={urlParam("user_profile_uuid") as string | undefined}
              {...props}
            />
          )}
        />
      </Switch>
    </TimesheetProvider>
  </Suspense>
);
export default RouteActivities;
