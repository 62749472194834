import FullPage from "components/Layout/FullPage";
import { useEffect, useState } from "react";
import BEM from "utils/BEM";
import { Notification } from "types/common";
import NotificationRow from "components/NotificationRow";
import "components/UI/Page/Page.scss";
import "styles/punches-page.scss";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { SpacerVertical } from "components/styled/Page";
import PunchSettings from "./PunchSettings";

const p = BEM.b("page");

const PunchSettingsPage = () => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const [notification, setNotification] = useState<Notification>({ notification: null, notificationType: null });

  useEffect(() => {
    document.title = t("Punches - Punches Settings");
  }, []);

  return (
    <FullPage>
      <div className={p()}>
        <div className={p("header", { reports: true })}>
          <div className={p("title")}>{t("Punches Settings")}</div>
        </div>

        {notification.notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notification.notificationType}
            onClose={() => setNotification({ notification: null, notificationType: null })}
            message={notification.notification}
          />
        )}

        <SpacerVertical height={38} />

        <PunchSettings setNotification={setNotification} />
      </div>
    </FullPage>
  );
};

export default PunchSettingsPage;
