import { Component } from "react";
import { withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import SearchInput from "components/UI/SearchInput";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import BEM from "utils/BEM";
import { getTitle, strIncludesCheck } from "utils/common";
import { getPositions, updatePosition, addPosition, deletePosition } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import NotificationRow from "../NotificationRow";
import PopupMessage from "./PositionsPopupMessage";
import NoContent from "../NoContent";
import * as images from "../svg-images";
import "../UI/Page/Page.scss";
import "./styles/positions-page.scss";

const b = BEM.b("positions-page");

class PositionsPage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      notification: "",
      searchValue: "",
      isFetching: false,
      selectedItem: null,
      popupVisible: false,
      confirmationPopupVisible: false,
    };
    document.title = getTitle(t("Positions"));
  }
  componentDidMount() {
    this.getState();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState();
  }
  getState(props) {
    if (!this.state.isFetching) {
      getPositions().then((r) => {
        this.setState({
          loaded: true,
          items: r.positions || r || [],
          isFetching: false,
          confirmationPopupVisible: false,
          selectedItem: null,
          popupVisible: false,
        });
      });
      this.setState({ isFetching: true });
    }
  }
  onRemoveItemClick = (item) => {
    this.setState({ confirmationPopupVisible: true, selectedItem: item });
  };
  removeItem(item) {
    const { t } = this.props;
    deletePosition({ id: item.id })
      .then(() => {
        this.setState({ notification: `${t("You deleted")} ${item.title}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  onEditItemClick = (item) => {
    this.setState({ popupVisible: true, selectedItem: item });
  };
  saveItem(item) {
    const { t } = this.props;
    updatePosition({
      id: item.id,
      body: {
        position: {
          title: item.title,
        },
      },
    })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.title}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  addItem = (item) => {
    const { t } = this.props;
    addPosition({ body: { position: { title: item.title } } })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.title}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  };
  addItemClick = () => {
    this.setState({ popupVisible: true, selectedItem: null });
  };
  onSearch(ev) {
    this.setState({ searchValue: ev.target.value });
  }

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Id"),
        accessor: "id",
        width: 100,
        style: { lineHeight: "50px" },
      },
      {
        label: t("Positions"),
        accessor: "title",
        minWidth: 100,
      },
      {
        label: t("employees"),
        accessor: "employees_count",
        minWidth: 50,
      },
      // TODO fix options
      {
        label: "",
        accessor: "options",
        minWidth: 50,
        className: b("options-td"),
        Cell: (row) => (
          <TableButtons className="buttons">
            <TableButton
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.onEditItemClick(row.original);
              }}
            >
              {t("common|Edit")}
            </TableButton>
            <TableButton
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.onRemoveItemClick(row.original);
              }}
            >
              {images.trashIcon}
            </TableButton>
          </TableButtons>
        ),
      },
    ];
    return columns;
  }
  render() {
    const { items, searchValue, selectedItem, popupVisible, confirmationPopupVisible, notification } = this.state;
    const { t } = this.props;

    const filteredItems = items.filter((s) => {
      const filter = strIncludesCheck(s.title, searchValue);
      return filter;
    });

    return (
      <SettingsLayout
        title={t(`${TranslationNamespaces.sidebar}|Company Structure`)}
        headerAction={
          <HeaderActionButtonAdd state={ButtonState.primary} title={t("Add a Position")} onClick={this.addItemClick} />
        }
      >
        <PageWrapper className={b()}>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={notification} />
          )}

          <TablePage
            columnSelectorOnFiltersRow
            filters={
              <SearchInput
                modifiers={["filter"]}
                onChange={this.onSearch.bind(this)}
                placeholder={t("Search")}
                value={searchValue}
              />
            }
            rows={filteredItems}
            columns={this.getTableColumns()}
            className="positions-table"
            loading={this.state.isFetching}
            noContentComponent={<NoContent>{t("You don’t have Positions")}</NoContent>}
          />

          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              title={selectedItem ? t("Edit a Position") : t("Add a new Position")}
              item={selectedItem || {}}
              allItems={items}
              buttonYesTitle={selectedItem ? t("Save") : t("Add")}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={selectedItem ? this.saveItem.bind(this) : this.addItem.bind(this)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Remove Position.")}
              text={t("Are you sure to remove the Position for your company?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({ selectedItem: null, confirmationPopupVisible: false });
              }}
              onYes={this.removeItem.bind(this, selectedItem)}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}
export default withTranslation([TranslationNamespaces.positions, TranslationNamespaces.sidebar])(PositionsPage);
