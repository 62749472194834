import { Component } from "react";
import styled from "styled-components";
import moment from "moment";

export enum CellTotalType {
  extraHoursMinutes = "extraHoursMinutes",
  hoursBankMinutes = "hoursBankMinutes",
  missedMinutes = "missedMinutes",
  debitMinutes = "debitMinutes",
}

const CellWrapper = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: none;
  color: ${(p) => (p.active ? "var(--colors-surface-800)" : "var(--colors-surface-400)")};
`;

interface CellTotalProps {
  date: moment.MomentInput;
  type: CellTotalType;
  total: string;
  active: boolean;
}

interface CellTotalState {
  total: string;
}

class CellTotal extends Component<CellTotalProps, CellTotalState> {
  constructor(props: CellTotalProps) {
    super(props);

    this.state = {
      total: props.total,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }

  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }

  getCellKey = () => {
    const { date, type } = this.props;

    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}${type}`;
  };

  handleDataLoad = (ev: any) => {
    this.setState({
      total: ev.detail.total ? ev.detail.total : this.state.total,
    });
  };

  render() {
    const { active } = this.props;
    const { total } = this.state;

    return <CellWrapper active={active}>{total}</CellWrapper>;
  }
}

export default CellTotal;
