import styled from "styled-components";

export default styled.div<{ width?: number; height?: number }>`
  position: relative;
  overflow: hidden;
  width: ${(p) => p.width || "100px"};
  height: ${(p) => p.height || "12px"};
  background-color: var(--colors-unknown34);

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: bar-loading 1.5s infinite;
  }

  @keyframes bar-loading {
    100% {
      transform: translateX(100%);
    }
  }
`;
