/* eslint-disable camelcase */
import { EmployeeListUserProfilePosition, RequestUserProfilePosition, UserProfilePosition } from "./position";
import { EmployeeListUserProfileDepartment, UserProfileDepartment } from "./department";
import { EmployeeListUserProfileSubsidiary, UserProfileSubsidiary } from "./subsidiary";
import { EmployeeBusinessRulesGroup } from "./businessRulesGroup";
import { HolidayGroup } from "./holidayGroup";
import { PayrollGroupEmployee } from "./payrollGroup";
import { Schedule } from "./schedule";
import { UserProfileCompany } from "./company";
import { Location, LocationInSearchEmployee } from "./location";
import { Feature } from "./subscription";
import { PermissionRoleName, PermissionSectionName, ProfilePermissionRole } from "./permissions";
import { UserProfileTeam } from "./team";

type CommonEmployeeGroup = {
  startDate: string;
  endDate: string;
  isDefault?: boolean;
};

type EmployeePayrollGroup = CommonEmployeeGroup & PayrollGroupEmployee;

type EmployeeSchedule = CommonEmployeeGroup & Schedule;

type EmployeeHolidayGroup = CommonEmployeeGroup & HolidayGroup;

type EmployeesBusinessRulesGroup = CommonEmployeeGroup & EmployeeBusinessRulesGroup;

type EmployeeListUserProfile = {
  active: boolean;
  avatarId?: string;
  id: number;
  uuid: string;
  userProfileUuid: string;
  fullName: string;
  name: string;
  email: string;
  position: EmployeeListUserProfilePosition;
  defaultTimeZone: string;
  cpf: string;
  pis: string;
  matricula: string;
  department: EmployeeListUserProfileDepartment;
  legacyRoleName: string;
  permissionRoles: {
    name: ProfilePermissionRole;
  }[];
  phone: {
    phoneNumber: string;
  };
  team?: {
    supervisor?: {
      fullName: string;
    };
  };
  lastLoginDate: string;
  createdAt: string;
  sex: string;
  status: string;
  subsidiary: EmployeeListUserProfileSubsidiary;
  payrollGroups: EmployeePayrollGroup[];
  businessRulesGroups: EmployeesBusinessRulesGroup[];
  holidayGroups: EmployeeHolidayGroup[];
  schedules: EmployeeSchedule[];
  faceId: string | null;
  faceVersion: string | null;
  hasBiometricProfile: boolean;
};

enum UserProfileStatus {
  active = "active",
  deactivated = "deactivated",
  invited = "invited",
}

enum UserProfileRole {
  employer = "employer",
  employee = "employee",
  supervisor = "supervisor",
}

export enum UserProfileEmployeeStatus {
  active = "active",
  deactivated = "deactivated",
}

type UserProfileSupervisor = {
  avatar_id: string | null;
  avatar_url: string | null;
  company_name: string;
  id: number;
  job_description: string | null;
  last_lock_date: string | null;
  name: string;
  position_id: unknown | null; // todo
  uuid: string;
};

// e.g. current user profile
type UserProfile = {
  allow_web_punch: boolean;
  avatar_id: string | null;
  avatar_url: string | null;
  birth_date: string | null;
  company: UserProfileCompany;
  cpf: string;
  name?: string;
  created_at: string;
  default_time_zone: string;
  department: UserProfileDepartment | null;
  department_id: number | null;
  email: string;
  employee_status: UserProfileEmployeeStatus;
  ffid: string;
  /** display name */
  full_name: string;
  real_name: string;
  social_name: string;
  use_social_name: boolean;
  id: number;
  /** Activities creation before or on this date is not allowed */
  last_lock_date: string;
  locale: string;
  locations: Location[];
  matricula: string;
  permission_roles: ProfilePermissionRole[];
  permissions: PermissionSectionName[];
  phone_number: string | null;
  pis: string;
  position: UserProfilePosition | null;
  position_id: number | null;
  referral_code: string;
  role: UserProfileRole;
  start_date: string | null;
  subsidiary: UserProfileSubsidiary | null;
  subsidiary_id: number | null;
  /** user's supervisor */
  supervisor: UserProfileSupervisor | null;
  /** user's supervisor team (team where a user is a member, not a supervisor) */
  team: UserProfileTeam | null;
  /** NOT RELIABLE. DO NO USE! in some cases it is a team id of a team where an user is supervisor, in others - where an user is a member */
  team_id: number | null;
  /** a team where an user is the supervisor (can be empty or with only one element) */
  teams: [UserProfileTeam?];
  uuid: string;
  job_description: string;
  associate_products: Exclude<Feature, Feature.Bundle>[];
  user_id: number;
  /** float number as string */
  project_employee_cost: string;
};

type EmployeeInfo = {
  avatarId: string | null;
  fullName: string;
  department: string;
  schedule: string;
  matricula: string;
  pis: string;
  cpf: string;
  positionTitle: string;
  supervisorFullName: string;
};

type RequestUserProfileSupervisor = {
  fullName: string;
  id: number;
  matricula: string;
  pis: string;
  uuid: string;
};

type RequestUserProfile = {
  active: boolean;
  avatarId: string | null;
  avatarVersion: unknown | null; // todo
  birthDate: string | null;
  companyUuid: string;
  cpf: string;
  defaultTimeZone: string;
  department: UserProfileDepartment | null;
  externalId: string | null;
  fullName: string;
  id: number;
  jobTitle: string | null;
  lastLockDate: string | null;
  last_lock_date?: string;
  matricula: string;
  ownTeamUuid: string | null;
  pis: string;
  position: RequestUserProfilePosition | null;
  role: UserProfileRole;
  roleUuids: string[];
  startDate: string | null;
  supervisor: RequestUserProfileSupervisor | null;
  team: UserProfileTeam | null;
  teamUuid: string | null;
  updatedAt: string;
  uuid: string;
};

type BusinessGroupUserProfile = {
  businessRulesGroupUuid: string;
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  endDate: string | null;
  startDate: string;
  updatedAt: string;
  updatedBy: string | null;
  userProfileUuid: string;
  uuid: string;
};

type SearchEmployeeUserProfile = {
  active: boolean;
  avatarId: string | null; // todo
  avatarUrl: unknown | null; // todo
  id: number;
  legacyRoleName: UserProfileRole; // TODO not sure
  locations: LocationInSearchEmployee[];
  matricula: string;
  name: string;
  permissionRoles: ProfilePermissionRole[];
  status: UserProfileStatus;
  uuid: string;
  lastLockDate?: string;
  position: {
    title: string;
  };
  // === added on frontend start
  full_name: string;
  avatar_id: string | null;
  employee_status: UserProfileStatus;
  job_description: UserProfileRole; // TODO not sure
  // === added on frontend end
};

type GroupSearchUserProfile = {
  id: number;
  full_name: string;
  job_description: string | null;
  position_id: unknown | null;
  uuid: string;
  avatar_id: string | null;
  employee_status: UserProfileStatus;
};

// TODO move entities to model type files
type GlobalContextEmployee = {
  active: boolean;
  avatarId: string | null;
  avatarUrl: string | null;
  businessRulesGroups: {
    endDate: string | null;
    isDefault: boolean;
    name: string;
    startDate: string;
    uuid: string;
  }[];
  companyUuid: string;
  cpf: string;
  createdAt: string;
  defaultTimeZone: string;
  department: {
    code: string;
    id: number;
    name: string;
    uuid: string;
  } | null;
  email: string;
  externalId: string | null;
  faceId: string | null;
  faceVersion: string | null;
  facialRecognition: {
    hasBiometricProfile: number[];
    photo_url: string | null;
    faceModelId: unknown | null;
    enabled: boolean;
  };
  hasBiometricProfile: boolean;
  holidayGroups: {
    endDate: string | null;
    isDefault: boolean;
    name: string;
    startDate: string | null;
    status: "active"; // TODO
    uuid: string;
  }[]; // todo
  id: number;
  lastLockDate: string;
  lastLoginDate: string | null;
  legacyRoleName: PermissionRoleName;
  // locations: LocationInGlobalContextEmployee[]; PROD-12493 remove locations from the employee list
  matricula: string;
  name: string;
  payrollGroups: {
    endDate: string | null;
    isDefault: boolean;
    name: string;
    startDate: string | null;
    status: "active"; // TODO
    unassignedAt: string | null;
    uuid: string;
  }[];
  permissionRoles: ProfilePermissionRole[];
  phone: string | null;
  pis: string;
  position: {
    external_id: string | null;
    id: number;
    title: string;
    uuid: string;
  } | null;
  schedules: {
    endDate: string | null;
    name: string;
    startDate: string;
    uuid: string;
  }[];
  sex: string | null;
  status: UserProfileStatus;
  subsidiary: {
    code: string;
    id: number;
    name: string;
    uuid: string;
  } | null;
  supervisor: {
    fullName: string;
    id: number;
    matricula: string;
    pis: string;
    uuid: string;
  } | null;
  team: {
    name: string;
    uuid: string;
    supervisor: GlobalContextEmployee["supervisor"];
  } | null;
  teamUuid: string | null;
  type: number; // BUG ?
  updatedAt: string;
  uuid: string;
};

type ActiveUserProfile = {
  id: number;
  uuid: string;
  can_login: boolean;
  full_name: string;
  employee_status: UserProfileStatus;
  role: UserProfileRole;
  avatar_id: string | null;
  avatar_url: string | null;
  project_employee_cost: string;
  company: {
    id: number;
    uuid: string;
    name: string;
    logo_version: string | null;
    logo_id: string | null;
    country: string;
  };
};

/** it is user entity, not user profile entity */
type SignUpUser = {
  /** user id */
  id: number;
  sex: null;
  tax_payer_id: null;
  language: null;
  /** always true */
  active: boolean;
  created_at: string;
  updated_at: string;
  address_id: null;
  /** user account entity id (not user profile entity) */
  user_account_id: number;
  birth_date: null;
  full_name: string;
  source_platform: "unknown"; // always unknown ?
  source_type: "unknown"; // always unknown ?
  /** user account entity uuid (not user profile entity) */
  uuid: string;
  user_account: {
    /** same as user_account_id */
    id: number;
    email: string;
    created_at: string;
    updated_at: string;
    avatar: {
      url: null;
      thumb: {
        url: string;
      };
      profile: {
        url: string;
      };
    };
    google_id_token: null;
    authentication_token: string;
    selected_user_profile_id: null;
    login_as_token: null;
  };
  authorization_tokens: never[];
};

/** for window.d.ts. Can't import directly in window.d.ts */
declare global {
  type WindowGlobalStoreProfile = UserProfile;
  type WindowGlobalStoreSignUpUser = SignUpUser | undefined;
}

export type {
  EmployeeListUserProfile,
  EmployeePayrollGroup,
  EmployeeBusinessRulesGroup,
  EmployeesBusinessRulesGroup,
  EmployeeSchedule,
  EmployeeHolidayGroup,
  UserProfile,
  EmployeeInfo,
  RequestUserProfile,
  SearchEmployeeUserProfile,
  GlobalContextEmployee,
  UserProfileSupervisor,
  GroupSearchUserProfile,
  UserProfileTeam,
  ActiveUserProfile,
  SignUpUser,
  BusinessGroupUserProfile,
};

export { UserProfileRole, UserProfileStatus };

// types related to employee center
export enum FilteredEmployeesExternalFields {
  Schedules = "schedules",
  HolidayGroups = "holidayGroups",
  BusinessRulesGroups = "businessRulesGroups",
  PayrollGroups = "payrollGroups",
}
export type PossibleEmployeeFieldsForFilterRequest =
  | "id"
  | "uuid"
  | "fullName"
  | "avatarId"
  | "role"
  | "pis"
  | "matricula"
  | "lastSignInAt"
  | "lastLockDate"
  | "createdAt"
  | "jobDescription"
  | "faceId"
  | "faceVersion"
  | "faceTemplate"
  | "externalId"
  | "employeeStatus"
  | "user.devices.phoneNumber"
  | "user.userAccount.email"
  | "taxPayerId"
  | "user.sex"
  | "user.active"
  | "position.id"
  | "position.title"
  | "subsidiary.uuid"
  | "subsidiary.id"
  | "subsidiary.name"
  | "subsidiary.taxPayerId"
  | "department.id"
  | "department.uuid"
  | "department.name"
  | "ownTeam.uuid"
  | "team.name"
  | "team.uuid"
  | "team.supervisor.fullName"
  | "team.supervisor.uuid"
  | "locations.uuid"
  | "locations.name"
  | "locations.active"
  | "permissionRoles.name";

export interface FilteredEmployeePayload {
  requestedBy: string;
  skip: number;
  limit: number;
  fields: PossibleEmployeeFieldsForFilterRequest[];
  externalFields: FilteredEmployeesExternalFields[];
  filterModel?: any;
  sortModel?: any;
  excludeTotal?: boolean;
  includeSelf?: boolean;
  global?: boolean;
  permissionName?: PermissionSectionName;
}

export interface FilteredEmployeeProfile {
  id: number;
  uuid: string;
  fullName: string;
  avatarId: string | null;
  role: string;
  pis: string | null;
  matricula: string | null;
  lastSignInAt: string | null;
  createdAt: string;
  faceId: string | null;
  faceVersion: string | null;
  faceTemplate: string | null;
  externalId: string;
  employeeStatus: string;
  lastLockDate: string | null;
  jobDescription?: string;
  taxPayerId: string;
  user: {
    devices: {
      phoneNumber: string;
    };
    userAccount: {
      email: string;
    };
    sex: string;
    active: boolean;
  };
  position: { title: string };
  subsidiary?: {
    id: string;
    uuid: string;
    name: string;
    taxPayerId: string | null;
  };
  department?: {
    uuid: string;
    name: string;
  };
  ownTeam: {
    uuid: string;
    supervisor: {
      uuid: string;
      fullName: string;
    };
  };
  team?: {
    uuid: string;
    name: string;
    supervisor?: {
      uuid: string;
      fullName: string;
    };
  };
  locations: {
    uuid: string;
    name: string;
    active: boolean;
  }[];
  permissionRoles: { name: string }[];
  schedules: EmployeeSchedule[];
  businessRulesGroups: EmployeesBusinessRulesGroup[];
  holidayGroups: EmployeeHolidayGroup[];
  payrollGroups: EmployeePayrollGroup[];
}
