import { useTranslation } from "react-i18next";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import Chip from "components/UI/Chip";
import Button from "components/UI/Button";
import BEM from "utils/BEM";
import { Location, LocationValidation } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { editLocation } from "utils/apiHelpers";
import "../Validations.scss";

const b = BEM.b("locations-validations");

interface ValidationsGPSProps {
  currentLocation: Location;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const ValidationsGPS = ({ currentLocation, setNotification, onYes }: ValidationsGPSProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);
  const { verification_methods, radius, id } = currentLocation;

  const updateLocation = async (data) => {
    try {
      await editLocation(id, {
        body: { location: data },
      });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  const items =
    verification_methods?.length && verification_methods.indexOf(LocationValidation.gps) > -1
      ? [{ radius: `${radius} ${t("meters")}`, active: true }]
      : [];

  const columns = [
    {
      label: t("Radius"),
      accessor: "radius",
    },
    {
      label: t("Status"),
      accessor: "active",
      Cell: ({ value }: { value: boolean }) => (
        <div>
          <div className={b("item-status")}>{value && <Chip intent="success">Active</Chip>}</div>
          <div className={b("item-actions")}>
            <Button
              className={b("item-action-button", ["remove"])}
              onClick={() => updateLocation({ gps_verification_enabled: false })}
            />
          </div>
        </div>
      ),
      maxWidth: 200,
    },
  ];

  return (
    <div className={b()}>
      <h3 className={b("list-header")}>GPS</h3>
      <TablePage
        rows={items}
        columns={columns}
        columnSelectorOnFiltersRow
        customColumnsAvailable={false}
        className="departments-table"
        loading={!currentLocation}
        noContentComponent={<NoContent>{t("This location has no GPS validations")}</NoContent>}
      />
    </div>
  );
};

export default ValidationsGPS;
