import { Component } from "react";
import BEM from "utils/BEM";
import "../Positions/styles/popup-message.scss";
import { withTranslation } from "react-i18next";
import Button from "components/controls/StyledButton";
import styled from "styled-components";
import TextInputControl from "../controls/TextInputControl";

const b = BEM.b("positions-popup-message");

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;
const Buttons = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;
class PositionsPopupMessage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      name: { value: "", error: "" },
      buttonCancelTitle: t("Cancel"),
      buttonYesTitle: t("Create"),
      loading: false,
    };
  }

  closePopup() {
    this.props.onClose();
  }

  getContent() {
    this.setState({
      name: { value: "", error: "" },
      buttonCancelTitle: this.props.t("Cancel"),
      buttonYesTitle: this.props.buttonYesTitle,
    });
  }

  onCancel = () => {
    this.closePopup();
  };

  onYes = () => {
    const name = { value: this.name.getValue(), error: "" };

    if (!this.name.getValue()) {
      name.error = this.props.t("Group name can't be empty");
      this.setState({ name });
    } else {
      this.setState({ loading: true, name });
      this.props.onYes && this.props.onYes(this.name.getValue());
    }
  };

  render() {
    const { name, buttonYesTitle, loading, buttonCancelTitle } = this.state;
    const { t } = this.props;
    return (
      <div className={b()}>
        <div className={b("title")}>{t("Create new group")}</div>

        <div className={b("label")}>{t("Name")}</div>
        <TextInputControl
          size={60}
          value={name.value}
          error={name.error}
          onRef={(ref) => {
            this.name = ref;
          }}
        />
        {name.error && <div className={b("error")}>{name.error}</div>}
        <Buttons>
          <Button state="secondary" onClick={this.onCancel} value={buttonCancelTitle} />
          <Spacer />
          <Button
            state="primary"
            loading={loading}
            onClick={this.onYes}
            onDoubleClick={this.onYes}
            value={buttonYesTitle}
          />
        </Buttons>
      </div>
    );
  }
}
export default withTranslation("company-rules")(PositionsPopupMessage);
