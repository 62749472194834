import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getSelectedColumns, getTrPropsClassName } from "utils/tableHelpers";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import Pagination from "components/Pagination";
import { getEmployeeNameColumn, mapTableData } from "utils/reportsHelpers";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport, getCompanyRules } from "utils/apiHelpers";
import { minsToHrsMins, getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import { EmployeeTaxPayerTypes } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { CompanyRuleNames } from "types/models/companyRules";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import TablePage from "../TablePage";
import ReportsNoContent from "./ReportsNoContent";
import "styles/reports-extra-hour-table.scss";
import { sliceReportIntoChunks } from "./helpers";

const b = BEM.b("reports-extra-hour-table");

class ReportsSummary extends Component {
  state = {
    activeNightShifts: [],
    employees: [[]],
    loading: false,
    selectedColumns: getSelectedColumns(
      "name,worked_days,absense_days,planned_hours,worked_hours,schedules,business_rules_groups",
      "ReportsFrequencyMapTableAllEmployees",
    ),
    page: 1,
    perPage: 500,
    totalRecords: 0,
    isNightReducedHoursColumnAllowed: false,
  };

  async componentDidMount() {
    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );
    this.setState({ isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value });

    this.getReportData();
  }

  getReportData = async () => {
    const { perPage } = this.state;
    const {
      startDate,
      endDate,
      searchObj,
      showInactiveEmploees,
      skipSupervisors,
      onlyNonZeroLines,
      oldReport,
      updateRecalculatingMessage,
      setIsLoading,
      t,
      flags,
    } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, () => setIsLoading(true));

      try {
        const response = await getReport(
          {
            showInactiveEmploees,
            skipSupervisors,
            onlyNonZeroLines,
            groupUUID: searchObj.uuid || null,
            groupType: searchObj.type || null,
            startDate,
            endDate,
            companyUUID,
            oldReport,
            type: "summary",
            newApi: flags.newDomainApiSummaryReport,
          },
          true,
        );

        const resp = response.content;

        if (!oldReport && resp) {
          const allDaysCount = resp.length || 0;
          const completedDaysCount = resp.filter((d) => d.status === "completed").length;
          const recalculatingInProgress =
            allDaysCount !== completedDaysCount || response?.metadata?.status === "pending";

          if (!resp.length || !recalculatingInProgress) {
            updateRecalculatingMessage(false);
          } else {
            const title = t("Report is being updated");
            const text = t("The highlighted rows are still calculating, please refresh");
            const lastUpdatedTime = response?.metadata?.lastUpdatedAt || null;

            updateRecalculatingMessage(recalculatingInProgress, { title, text, lastUpdatedTime });
          }
        }

        const employees = sliceReportIntoChunks(resp, perPage);

        this.setState({
          activeNightShifts: response.metadata.activeNightShifts || [],
          employees: employees.length ? employees : [[]],
          totalRecords: resp.length,
          error: false,
          loading: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({
          error: this.props.t("Failed to generate report"),
        });
      }
    }
  };

  getColumns = () => {
    const { t } = this.props;
    const { activeNightShifts } = this.state;

    const nightShiftColumns = activeNightShifts.length
      ? activeNightShifts.map((ns) => ({
          accessor: `nightShiftMinutes${ns.name}`,
          rubyAccessor: "night_hours",
          groupLabel: t("nightShiftMinutes"),
          label: `${t("ns-col")} ${ns.name}%`,
          Cell: (row) => {
            const hours = row.original.totalNightShiftMinutes[ns.name] || 0;
            return minsToHrsMins(hours);
          },
          align: "end",
          minWidth: 100,
        }))
      : [];
    const nightReducedHoursColumn = this.state.isNightReducedHoursColumnAllowed
      ? [
          {
            accessor: "totalNightReducedMinutes",
            rubyAccessor: "total_night_reduced_hours",
            label: t("ns-col-reduced-hours"),
            minWidth: 130,
            align: "end",
            Cell: (r) => minsToHrsMins(r.value ?? ""),
          },
        ]
      : [];

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns = [
      getEmployeeNameColumn(t),
      {
        accessor: "cpf",
        rubyAccessor: "cpf",
        label: employeeTaxIdLabel,
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
        minWidth: 100,
      },
      {
        accessor: "pis",
        rubyAccessor: "pis",
        label: t("PIS"),
        minWidth: 100,
      },

      {
        accessor: "schedules",
        rubyAccessor: "schedules",
        label: t("Schedule"),
        Cell: (r) => <span title={r.value}>{r.value}</span>,
        minWidth: 100,
      },
      {
        accessor: "businessRulesGroups",
        rubyAccessor: "business_rules_groups",
        Cell: (row) => (row.value === "default" ? t("common|default") : <span title={row.value}>{row.value}</span>),
        label: t("Business Rules Group"),
        minWidth: 100,
      },
      {
        accessor: "supervisorFullName",
        rubyAccessor: "supervisor",
        label: t("Supervisor"),
        Cell: (r) => <span title={r.value}>{r.value}</span>,
        minWidth: 100,
      },
      {
        accessor: "positionTitle",
        rubyAccessor: "position_name",
        label: t("Position"),
        Cell: (r) => <span title={r.value}>{r.value}</span>,
        minWidth: 100,
      },
      {
        accessor: "department",
        rubyAccessor: "department",
        label: t("Department"),
        Cell: (r) => <span title={r.value}>{r.value}</span>,
        minWidth: 100,
      },
      {
        accessor: "workedDays",
        rubyAccessor: "worked_days",
        Cell: (row) => row.value || 0,
        label: t("Worked Days"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "missedDays",
        rubyAccessor: "absense_days",
        Cell: (row) => row.value || 0,
        label: t("Absence Days"),
        minWidth: 130,
        align: "end",
      },
      ...nightShiftColumns,
      ...nightReducedHoursColumn,
      {
        accessor: "totalPlannedMinutes",
        rubyAccessor: "planned_hours",
        label: t("Planned hours"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalWorkedMinutes",
        locked: true,
        rubyAccessor: "worked_hours",
        label: t("Worked hours"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalLateEntryMinutes",
        rubyAccessor: "total_late_entry_hours",
        label: t("Late Arrival"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalLateArrivals",
        rubyAccessor: "total_late_arrivals",
        label: t("Total Late Arrivals"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "lateArrivalsWithoutBreaks",
        rubyAccessor: "late_arrivals_without_breaks",
        label: t("Late Arrivals w/o Breaks"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalCrossShiftsIntervalDiffMinutes",
        rubyAccessor: "total_cross_shifts_interval_diff_hours",
        label: t("Cross Shift Interval"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalEarlyLeaveMinutes",
        rubyAccessor: "total_early_leave_hours",
        label: t("Early Leave"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalEarlyLeaves",
        rubyAccessor: "total_early_leaves",
        label: t("Total Early Leaves"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "earlyLeavesWithoutBreaks",
        rubyAccessor: "early_leaves_without_breaks",
        label: t("Early Leaves w/o Breaks"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalExtraHoursMinutes",
        rubyAccessor: "total_extra_hours",
        label: t("Extra Hours"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "cumulativeHoursBankMinutes",
        rubyAccessor: "cumulative_hours_bank_hours",
        label: t("Accumulated Hours Bank"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalMissedMinutes",
        rubyAccessor: "total_missed_hours",
        label: t("Missed Minutes"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalBreakMinutes",
        rubyAccessor: "total_break_hours",
        label: t("Total Break hours"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalOnCallMinutes",
        rubyAccessor: "total_on_call_hours",
        label: t("On Call Minutes"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalOnCallActivatedMinutes",
        rubyAccessor: "total_on_call_activated_hours",
        label: t("On Call Activated Minutes"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalOnCallReducingActivatedMinutes",
        rubyAccessor: "total_on_call_reducing_activated_hours",
        label: t("On Call Reducing Activated Minutes"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalDebitMinutes",
        rubyAccessor: "total_debit_hours",
        label: t("Debit Minutes"),
        minWidth: 130,
        align: "end",
      },
      {
        accessor: "totalUnusedBreakMinutes",
        rubyAccessor: "total_unused_break_hours",
        label: t("Unused Break"),
        minWidth: 130,
        align: "end",
      },
    ];

    return columns;
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsFrequencyMapTableAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPageLimitChange = (pageLimit) => {
    const { employees } = this.state;

    this.setState({ page: 1, perPage: pageLimit, employees: sliceReportIntoChunks(employees.flat(), pageLimit) });
  };

  render() {
    const { error, employees, loading, selectedColumns, page, perPage, totalRecords } = this.state;
    const { t, startDate, endDate, searchObj, skipSupervisors, onlyNonZeroLines, showInactiveEmploees } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    const data = mapTableData(
      [
        "fullName",
        "cpf",
        "totalNightReducedMinutes",
        "matricula",
        "pis",
        "positionTitle",
        "supervisorFullName",
        "totalNightShiftMinutes",
        "department",
        "workedDays",
        "missedDays",
        "trHeading",
        "schedules",
        "businessRulesGroups",
        "totalCrossShiftsIntervalDiffMinutes",
        "totalLateEntryMinutes",
        "totalLateArrivals",
        "lateArrivalsWithoutBreaks",
        "totalEarlyLeaveMinutes",
        "totalEarlyLeaves",
        "earlyLeavesWithoutBreaks",
        "cumulativeHoursBankMinutes",
        "totalExtraHoursMinutes",
        "totalBreakMinutes",
        "totalWorkedMinutes",
        "totalPlannedMinutes",
        "totalMissedMinutes",
        "totalOnCallMinutes",
        "totalOnCallActivatedMinutes",
        "totalOnCallReducingActivatedMinutes",
        "totalDebitMinutes",
        "totalUnusedBreakMinutes",
        "status",
      ],
      employees[page - 1],
    );

    const columns = this.getColumns();

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj && searchObj.type && searchObj.type !== "all" && searchObj.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    return (
      <div className={b()}>
        <TablePage
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  searchObj,
                  startDate,
                  endDate,
                  showInactiveEmploees,
                  skipSupervisors,
                  onlyNonZeroLines,
                  oldReport: this.props.oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: selectedColumns ? "summary_all" : "summary",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className: getTrPropsClassName(this.props, rowInfo),
          })}
          rows={data}
          columns={columns}
          loading={loading}
          interactive={false}
          className="reports-hoursbank-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsSummary)));
