import { stylesheet } from "astroturf";
import cx from "classnames";
import { IconArrowDown } from "../styled";

const iconDropdownStyles = stylesheet`
.IconDropdown {
  fill: var(--colors-unknown39);

  &.expanded {
    transform: rotate(-180deg);
  }
}
`;

export type IconDropdownProps = React.HTMLProps<HTMLDivElement> & { expanded?: boolean };
export function IconDropdown(props: IconDropdownProps) {
  const { className, expanded } = props;
  return (
    <IconArrowDown
      className={cx(iconDropdownStyles.IconDropdown, { [iconDropdownStyles.expanded]: expanded }, className)}
    />
  );
}
