import { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import BEM from "utils/BEM";
import Tooltip from "components/UI/Tooltip";
import { TFunction } from "react-i18next";
import { SuperpunchComment, SuperpunchDate } from "types/models/superpunch";
import ReporsDsrLabel from "components/Reports/ReporsDsrLabel";

const InconsictencyIcon = styled.div`
  position: absolute;
  right: -22px;
  margin-top: 2px;
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 14A7 7 0 107 0a7 7 0 000 14z' fill='%23FE6664'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 7.643C8 7.84 7.776 8 7.5 8h-1c-.276 0-.5-.16-.5-.357V3.357C6 3.16 6.224 3 6.5 3h1c.276 0 .5.16.5.357v4.286zM8 10.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
`;

const tc = BEM.b("table-common");

interface CellDateProps {
  t: TFunction;
  date: SuperpunchDate;
  comments: SuperpunchComment[];
  isLostDsrDay: boolean;
  isPossibleDsrDay: boolean;
  showDsrInReports: boolean;
}

interface CellDateState {
  comments: SuperpunchComment[];
}

class CellDate extends Component<CellDateProps, CellDateState> {
  constructor(props: CellDateProps) {
    super(props);

    this.state = {
      comments: props.comments,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }

  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }

  getCellKey = () => {
    const { date } = this.props;

    return `sp_${moment(date.raw, "YYYY-MM-DD").format("YYYYMMDD")}_date`;
  };

  handleDataLoad = (ev) => {
    this.setState({
      comments: ev.detail.comments ? ev.detail.comments : this.state.comments,
    });
  };

  render() {
    const { t, date, isLostDsrDay, isPossibleDsrDay, showDsrInReports } = this.props;
    const { comments } = this.state;

    return (
      <span className={tc("date", { "day-off": date.isDayOff })} style={{ position: "relative" }}>
        <span className={tc("day-of-week")}>{date.dayOfWeek}</span>{" "}
        <span className={tc("day-month")}>{date.dayMonth}</span>
        <ReporsDsrLabel
          isLostDsrDay={isLostDsrDay}
          isPossibleDsrDay={isPossibleDsrDay}
          showDsrInReports={showDsrInReports}
        />
        {!!comments?.length &&
          comments.some(
            (c) =>
              c.name === "entry_exit_in_different_locations" || c.name === "nightshift_end_is_before_nightshift_start",
          ) && (
            <>
              <InconsictencyIcon data-tip data-for={`cell-punch-inconsistent${date.dayOfWeek}${date.dayMonth}`} />
              <Tooltip id={`cell-punch-inconsistent${date.dayOfWeek}${date.dayMonth}`}>
                {comments.map((c) => (
                  <div key={c.name}>{t(c.name)}</div>
                ))}
              </Tooltip>
            </>
          )}
      </span>
    );
  }
}

export default CellDate;
