import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";

import LocationsPage from "components/Locations/LocationsPage";
import LocationPage from "components/Locations/LocationPage";

const RouteCompanyLocations = ({ match: { path } }: RouteComponentProps): JSX.Element => (
  <Switch>
    <Redirect strict exact from={path} to={`${path}/`} />
    <Redirect strict from={`${path}/:locationId/`} to={`${path}/:locationId`} />

    <Route exact strict path={`${path}/`} component={LocationsPage} />
    <Route path={`${path}/:locationId`} component={LocationPage} />
  </Switch>
);

export default RouteCompanyLocations;
