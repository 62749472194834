import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Item, { SearchControlItem } from "components/UI/SearchControlNew/Item";
import { TranslationNamespaces } from "types/translationNamespaces";
import { GroupSearchUserProfile } from "types/models/userProfile";
import { GroupSearchDepartment } from "types/models/department";
import { SearchEntity } from "./types";

const DropdownWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 8px;
  background-color: var(--colors-surface-0);
  box-shadow: 0 10px 30px 0 rgb(129 147 171 / 20%);
  z-index: 10;
`;

interface SearchResultsDropdownProps {
  searchValue: string;
  searchResults: SearchControlItem[];
  onClick: (entity: SearchEntity) => void;
}

const SearchResultsDropdown = ({ searchValue, searchResults, onClick }: SearchResultsDropdownProps) => {
  const { t } = useTranslation(TranslationNamespaces.common);

  return (
    <DropdownWrapper className="search-results-dropdown">
      {searchResults.map((sr) => (
        <Item
          textToHighlight={searchValue}
          item={sr}
          preselected={false}
          key={sr.value}
          t={t}
          onItemClick={() =>
            onClick({
              id: sr.obj && sr.obj.id,
              uuid: sr.obj && sr.obj.uuid,
              name: (sr.obj as GroupSearchUserProfile).full_name || (sr.obj as GroupSearchDepartment).name,
              type: sr.type,
            })
          }
        />
      ))}
    </DropdownWrapper>
  );
};

export default SearchResultsDropdown;
