import styled from "styled-components";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { chunk, minsToHrsMins } from "utils/common";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Blocks = styled.div`
  display: flex;
`;

const BlocksRow = styled.div`
  display: flex;
  margin-bottom: 17px;
`;

const ServicesBlock = styled.div<{ noStartPadding: boolean; noEndBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding-inline-end: 50px;
  margin-inline-start: ${(p): string => (p.noStartPadding ? "0" : "40px")};
  border-inline-end: ${(p): string => (p.noEndBorder ? "none" : "1px solid #e5ebf2")};
`;

const Totals = styled.div`
  margin-top: 17px;
`;

const SummaryTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--colors-secondary);
  margin-bottom: 16px;
`;

const Row = styled.div`
  font-size: 16px;
  line-height: 32px;
  color: var(--colors-mainText2);
`;

type Service = {
  name: string;
  uuid: string;
  status: string;
  createdAt: string;
  createdBy: string;
  startDate: string | null;
  updatedAt: string;
  updatedBy: string | null;
  companyUuid: string;
  costPerHour: number;
  statusChangedAt: string | null;
};

type ServicesSummaryRow = {
  service: Service;
  totalMinutes: number;
  totalCost: string;
};

export type ServicesSummary = {
  report: ServicesSummaryRow[];
  metadata: {
    totalMinutes: number;
    totalCost: string;
    totalEmployeeCost: string;
    customFieldsMap: {
      [key: string]: {
        name: string;
        value: string | number;
      };
    };
  };
};

type ReportsDetailedServicesSummaryProps = {
  servicesSummary: ServicesSummary;
  summaryWithCosts: boolean;
  currency: string;
};

const ServicesRow = ({
  serviceSummaryRow,
  summaryWithCosts,
  currency,
}: {
  serviceSummaryRow: ServicesSummaryRow;
  summaryWithCosts: ReportsDetailedServicesSummaryProps["summaryWithCosts"];
  currency: ReportsDetailedServicesSummaryProps["currency"];
}): JSX.Element => {
  let totals = `${serviceSummaryRow.service.name}:  <b>${minsToHrsMins(serviceSummaryRow.totalMinutes)}</b>`;
  if (summaryWithCosts) {
    totals += ` - ${getSymbolFromCurrency(currency)} ${serviceSummaryRow.totalCost}`;
  }

  return (
    <Row
      dangerouslySetInnerHTML={{
        __html: totals,
      }}
    />
  );
};

export default ({ servicesSummary, summaryWithCosts, currency }: ReportsDetailedServicesSummaryProps): JSX.Element => {
  const { t } = useTranslation(["reports-page"]);
  const serviceSummaryRows = servicesSummary.report;
  let totals = `<b>${t("Total")}: ${minsToHrsMins(servicesSummary.metadata.totalMinutes)}`;
  if (summaryWithCosts) {
    totals += ` ${getSymbolFromCurrency(currency)} ${servicesSummary.metadata.totalCost}</b>`;
  }
  const chunkedArray = chunk(chunk(serviceSummaryRows, 2), 4);

  return (
    <Wrapper>
      <SummaryTitle>{t("Worked hours per service")}</SummaryTitle>
      <Blocks>
        {chunkedArray.map((row) => (
          <BlocksRow>
            {row.map((block: ServicesSummaryRow[], i: number) => (
              <ServicesBlock noStartPadding={i === 0} noEndBorder={i === row.length - 1}>
                {block.map((serviceSummaryRow: ServicesSummaryRow) => (
                  <ServicesRow
                    key={serviceSummaryRow.service.uuid}
                    serviceSummaryRow={serviceSummaryRow}
                    currency={currency}
                    summaryWithCosts={summaryWithCosts}
                  />
                ))}
              </ServicesBlock>
            ))}
          </BlocksRow>
        ))}
      </Blocks>
      <Totals>
        <Row
          dangerouslySetInnerHTML={{
            __html: totals,
          }}
        />
      </Totals>
    </Wrapper>
  );
};
