import { Component } from "react";
import BEM from "utils/BEM";
import "../UI/Page/Page.scss";
import { useTranslation, withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import TablePage from "components/TablePage";
import ScheduleAdditionalEntryExit from "components/controls/ScheduleAdditionalEntryExit";
import Tabs from "components/UI/Tabs";
import * as moment from "moment";
import styled from "styled-components";
import { getTitle, minsToHrsMins } from "utils/common";
import { getDataTable, getEventsByType } from "utils/scheduleHelpers";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getScheduleException, toggleScheduleExceptionDay } from "utils/apiHelpers";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import DayOfWeek from "components/UI/Table/DayOfWeek";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import { getCustomBreaksList } from "utils/api/schedule";
import { BreakStatusOptions } from "utils/api/types";
import { BreaksCell } from "./Breaks/BreaksCell";
import { BasedOn } from "./Breaks/BreakModal";

const p = BEM.b("page");
const sp = BEM.b("schedules-page");
const tb = BEM.b("table-common");

const ExceptionTabTable = styled.div`
  margin-top: 25px;
`;

class ScheduleExceptionPage extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      loaded: false,
      isFetching: false,
      activeTab: "exception",
    };

    document.title = getTitle(t("Schedule"));
  }

  componentDidMount() {
    this.getState({ ...this.props });
  }

  getState = async (props) => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, loaded: false });
      const company = await this.context.getCompany();
      const companyUuid = company.uuid;

      const [{ content: breaksContent }, { content }] = await Promise.all([
        await getCustomBreaksList({
          perPage: 300,
          page: 1,
          statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
          companyUuid: company.uuid,
          requestedBy: window.global_store.profile.uuid,
        }),
        await getScheduleException({ companyUuid, exceptionUuid: props.exceptionUuid }),
      ]);

      const { content: userProfiles } = await listUserProfilesWIthFilters(company.uuid, {
        ...baseByUuidPayload(window.global_store.profile.uuid, [content.UserProfileUuid, content.createdBy]),
        fields: ["id", "uuid", "fullName", "lastLockDate"],
      });

      const userProfile = userProfiles.find((item) => item.uuid === content.UserProfileUuid);
      const createdByUserProfile = userProfiles.find((item) => item.uuid === content.createdBy);

      if (content.scheduleDays) {
        const defaultBreak = breaksContent.find((c) => c.default === true);
        content.scheduleDays.forEach((day) => {
          const startEvents = getEventsByType(day.events, "break_start");
          startEvents.forEach((e) => {
            const customBreak = breaksContent.find((c) => c.uuid === e.breakTypeUuid);
            if (e.breakMode === undefined) {
              e.breakMode = e.time === null ? BasedOn.duration : BasedOn.range;
            }
            if (e.breakTypeUuid === undefined) {
              e.breakTypeUuid = defaultBreak.uuid;
            }
            e.isWorkingHours = !!customBreak?.isWorkingHours;
            e.breakTypeName = customBreak?.name || defaultBreak.name;
          });
        });
      }

      this.setState({
        days: content.scheduleDays || [],
        name: content.name,
        assignee: userProfile?.fullName || "",
        lastLockDate: userProfile?.lastLockDate,
        startDate: content.startDate,
        endDate: content.endDate,
        createdBy: createdByUserProfile?.fullName || "",
        status: content.status,
        scheduleExceptionDays: content.ScheduleExceptionDays,
        isFetching: false,
        loaded: true,
      });
    }
  };

  getTitle = () => {
    const { t } = this.props;
    const { name, status } = this.state;

    return (
      <div
        className={p("title")}
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {["ApprovedOvertimeRequest", "enableDay", "ApprovedOvertimeNonWorkingDayRequest"].includes(name)
          ? t(name)
          : name}
        <StatusTag
          value={status === "active" ? t("active") : t("inactive")}
          type={status === "active" ? StatusTagType.active : StatusTagType.default}
        />
      </div>
    );
  };

  render() {
    const { startDate, endDate, days, createdBy, assignee, loaded, activeTab, scheduleExceptionDays, lastLockDate } =
      this.state;
    const { t, history } = this.props;

    if (!loaded) {
      return t("Loading...");
    }

    return (
      <SettingsLayout
        title={this.getTitle()}
        backButtonOnclick={() => history.push("/schedules/exceptions/")}
        backButtonTitle={t("Schedule Exceptions")}
      >
        <div className={p()}>
          <Tabs
            tabs={[
              {
                name: "exception",
                label: t("Exception"),
              },
              {
                name: "details",
                label: t("Details"),
              },
            ]}
            t={t}
            value={activeTab}
            onChange={(tab) => this.setState({ activeTab: tab })}
          />

          <div className={p("content")}>
            <Assignee>{assignee}</Assignee>
            <Details>
              {moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} -{" "}
              {moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - {t("Created by")} - {createdBy}
            </Details>
            {activeTab === "exception" ? (
              <ExceptionTab tableData={getDataTable({ scheduleDays: days }, true)} />
            ) : activeTab === "details" ? (
              <ExceptionDetailsTab
                lastLockDate={lastLockDate}
                days={scheduleExceptionDays}
                employeeFullname={assignee}
                onChange={({ action }) => {
                  this.getState({ ...this.props });
                }}
              />
            ) : null}
          </div>
        </div>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.schedules)(ScheduleExceptionPage);

/// /////////////

const Assignee = styled.div`
  font-size: 20px;
  color: ${(prop) => prop.theme.colors.mainText};
  line-height: 27px;
`;
const Details = styled.div`
  margin-top: 5px;
  font-size: 15px;
  color: ${(prop) => prop.theme.colors.secondary};
`;

const ExceptionTab = ({ tableData }) => {
  const { t } = useTranslation("schedules");
  const days = ["", t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
  const columns = [
    {
      label: "",
      accessor: "id",
      Cell: (r) => <DayOfWeek dayName={days[r.value]} />,
      minWidth: 70,
      style: {
        fontWeight: "700",
        fontSize: "14px",
        color: "#525F7F",
        textTransform: "uppercase",
        paddingInlineStart: "15px",
        lineHeight: "36px",
      },
    },
    {
      label: t("Schedule type"),
      accessor: "type",
      Cell: (row) => t(row.value),
      minWidth: 70,
    },
    {
      label: t("Entry"),
      accessor: "entry",
      Cell: (r) => (r.value ? r.value.value : null),
      minWidth: 70,
    },
    {
      label: t("Exit"),
      accessor: "exit",
      Cell: (r) =>
        r.original.type !== "flexible"
          ? r.value
            ? r.value.value
            : ""
          : `${minsToHrsMins(r.original.total)} ${t("hours per day")}`,
      minWidth: 70,
    },
    {
      label: t("Break"),
      accessor: "breaks",
      minWidth: 70,
      Cell: (r) => <BreaksCell row={r} breaks={r.value} readOnly disableRemove onRemoveBreak={() => {}} />,
    },
  ];
  if (window.global_store.beta) {
    columns.push({
      label: t("Additional Entry/Exit"),
      accessor: "extraEntryExit",
      minWidth: 70,
      Cell: (r) => {
        if (r.value && r.value.length > 0) {
          return (
            <div>
              {r.value.map((e, i) => {
                const entryExit = { ...e };
                return (
                  <ScheduleAdditionalEntryExit
                    key={i}
                    multiple
                    readOnly
                    item={entryExit}
                    onChange={(ee) => {}}
                    onAddBreak={() => {}}
                    onRemoveBreak={(ee) => {}}
                  />
                );
              })}
            </div>
          );
        }
        return null;
      },
    });
  }
  columns.push({
    label: t("Total"),
    accessor: "total",
    minWidth: 64,
    Cell: (r) =>
      r.value ? (
        <div>
          {r.value ? minsToHrsMins(r.value) : ""} {t("hs")}
        </div>
      ) : null,
  });

  return (
    <ExceptionTabTable>
      <TablePage
        rows={tableData}
        columns={columns}
        columnSelectorOnFiltersRow
        noContentComponent={<div />}
        interactive={false}
      />
    </ExceptionTabTable>
  );
};

const toggleException = ({ userProfileUuid, scheduleExceptionUuid, date, action, callBack }) => {
  toggleScheduleExceptionDay({
    companyUuid: window.global_store.company.uuid,
    requestedByUuid: window.global_store.profile.uuid,
    userProfileUuid,
    date,
    scheduleExceptionUuid,
    action,
  })
    .then((r) => {
      callBack({ action });
    })
    .catch((e) => console.log(e.message));
};

const ExceptionDetailsTab = ({ days, lastLockDate, employeeFullname = "", onChange }) => {
  const { t } = useTranslation("schedules");

  const columns = [
    {
      label: t("Name"),
      accessor: "date",
      Cell: (row) =>
        `${moment(row.value, "YYYY-MM-DD").format("dddd")} ${moment(row.value, "YYYY-MM-DD").format("DD/MM")}`,
      minWidth: 70,
      style: {
        fontWeight: "500",
        fontSize: "14px",
        color: "#525F7F",
        lineHeight: "36px",
      },
    },
    {
      label: t("Employees"),
      accessor: "UserProfile",
      Cell: (r) => (r.value ? r.value.fullName : employeeFullname),
      minWidth: 70,
    },

    {
      label: t("Status"),
      accessor: "status",
      locked: true,
      align: "center",
      Cell: (row) => (
        <div>
          <span className={sp("status-button", { inactive: row.value !== "active" })}>
            {t(row.value === "active" ? "active" : "deactivated")}
          </span>
          {!lastLockDate || moment(row.original.date).isAfter(moment(lastLockDate), "day") ? (
            <div className={tb("buttons")}>
              {row.value === "active" ? (
                <div
                  className={tb("button", ["deactivate"])}
                  onClick={toggleException.bind(this, {
                    scheduleExceptionUuid: row.original.ScheduleExceptionUuid,
                    userProfileUuid: row.original.UserProfileUuid,
                    date: row.original.date,
                    action: "deactivate",
                    callBack: onChange,
                  })}
                >
                  {t("Deactivate")}
                </div>
              ) : (
                <div
                  className={tb("button", ["activate"])}
                  onClick={toggleException.bind(this, {
                    scheduleExceptionUuid: row.original.ScheduleExceptionUuid,
                    userProfileUuid: row.original.UserProfileUuid,
                    date: row.original.date,
                    action: "activate",
                    callBack: onChange,
                  })}
                >
                  {t("Activate")}
                </div>
              )}
            </div>
          ) : null}
        </div>
      ),
      minWidth: 120,
    },
  ];

  return (
    <div>
      <ExceptionTabTable>
        <TablePage rows={days} columns={columns} columnSelectorOnFiltersRow noContentComponent={<div />} />
      </ExceptionTabTable>
    </div>
  );
};
