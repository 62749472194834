import { Component, ContextType, createContext, PropsWithChildren } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import moment from "moment";
import GlobalContext from "context/global-context";
import { SP_DATE_FORMAT } from "context/SuperpunchProvider";
import { TranslationNamespaces } from "types/translationNamespaces";
import { GlobalStoreCompany } from "types/models/company";
import { getCompanyRules } from "utils/api/businessRule";
import { CompanyRule } from "types/models/companyRules";
import { v4 as uuidv4 } from "uuid";
import { TFunction } from "i18next";
import { fireEvent } from "utils/common";
import CONFIG from "config";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import { LocalStorageKeys } from "utils/localStorageUtils";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { CompanyTimesheetSettings } from "../Projects/projectsApiTypes";
import { ParsedTimesheetDataSummary, TimesheetApprovalStatus, TimesheetsListItem } from "./timesheet.types";
import { getCompanyRulesForProjects } from "../Projects/projectsApiUtils";
import {
  approveTimesheets,
  declineTimesheets,
  ListTimesheetsRequest,
  ListTimesheetsResponse,
  listTimesheetsV2,
  submitTimesheet,
  SubmitTimesheetRequest,
} from "./timesheet-api.service";

type TimesheetsProviderState = {
  companyUuid: string;
  currentUserUuid: string;
  userProfile?: {
    uuid: string;
    name: string;
    id: string | number;
  };
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  settings?: CompanyTimesheetSettings;
  timesheets?: Array<
    TimesheetsListItem & {
      submittedByUser: FilteredEmployeeProfile;
      declinedByUser?: FilteredEmployeeProfile;
    }
  >;
  isTimesheetEnabled: boolean;
  reloadCallback?: () => void;
};

interface TimesheetProviderProps extends PropsWithChildren, WithTranslation {
  // RouteComponentProps
  startDate?: moment.Moment | null;
  endDate?: moment.Moment | null;
}
interface TimesheetContextInterface {
  submitTimesheet: (
    summary: ParsedTimesheetDataSummary,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ) => Promise<void>;
  setUserProfile: (userProfile: { uuid: string; name: string; id: string | number }) => Promise<void>;
  setDates: (startDate?: moment.Moment, endDate?: moment.Moment) => Promise<void>;
  getTimesheetEnabledFlag: () => Promise<boolean>;
  fetchSettings: () => Promise<CompanyTimesheetSettings>;
  fetchTimeSheets: (skipDateRange?: boolean, statuses?: TimesheetApprovalStatus[]) => Promise<ListTimesheetsResponse>;
  approveTimesheet: (timesheetsUuids: string[]) => Promise<void>;
  declineTimesheet: (timesheetsUuids: string[], declineReason: string) => Promise<void>;
  setReloadDataCallback: (callback: () => void) => void;
}
type TimesheetContextValue = TimesheetsProviderState & TimesheetContextInterface;

export const TimesheetContext = createContext<TimesheetContextValue>({} as TimesheetContextValue);

class TimesheetProvider
  extends Component<TimesheetProviderProps, TimesheetsProviderState>
  implements TimesheetContextInterface
{
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;
  t: TFunction;

  constructor(props: TimesheetProviderProps) {
    super(props);
    const { startDate, endDate } = this.props;
    this.t = props.t;
    this.state = {
      companyUuid: window.global_store.profile.company.uuid!,
      startDate: startDate ? moment(startDate, SP_DATE_FORMAT) : moment().clone().subtract(1, "month"),
      endDate: endDate ? moment(endDate, SP_DATE_FORMAT) : moment().clone(),
      isTimesheetEnabled: localStorage.getItem(LocalStorageKeys.isTimesheetEnabled) === "true" || false,
      currentUserUuid: window.global_store.profile.uuid,
    };
  }
  componentDidMount = () => {
    void this.context.getCompany().then((company) => this.setState({ companyUuid: company.uuid }));
    void this.getTimesheetEnabledFlag();
    void this.fetchSettings();
  };

  setUserProfile = async (userProfile: { uuid: string; name: string; id: string | number }) => {
    this.setState({ userProfile });
  };

  setDates = async (startDate?: moment.Moment, endDate?: moment.Moment) => {
    this.setState({ startDate, endDate });
  };

  getTimesheetEnabledFlag = async () => {
    const businessRulesResp = await getCompanyRules();
    const companyRules: CompanyRule[] = businessRulesResp?.business_rules || [];

    const isEnabled = !!companyRules.find((rule: CompanyRule) => rule.name === "is_timesheet_enabled")?.value;
    this.setState({ isTimesheetEnabled: isEnabled });
    localStorage.setItem(LocalStorageKeys.isTimesheetEnabled, isEnabled.toString());
    return isEnabled;
  };

  setReloadDataCallback = (callback: () => void) => {
    this.setState({ reloadCallback: callback });
  };

  fetchSettings = async () => {
    const { content } = await getCompanyRulesForProjects({ companyUuid: this.state.companyUuid });
    this.setState({ settings: content });
    return content;
  };

  fetchTimeSheets = async (skipDateRange = false, statuses?: TimesheetApprovalStatus[]) => {
    const req: ListTimesheetsRequest = {
      companyUuid: this.state.companyUuid,
      requestedBy: window.global_store.profile.uuid,
      userProfileUuid: this.state.userProfile?.uuid,
      approvalStatus: statuses,
      from: skipDateRange ? undefined : this.state.startDate?.format(CONFIG.apiDateFormat),
      to: skipDateRange ? undefined : this.state.endDate?.format(CONFIG.apiDateFormat),
    };
    const res = await listTimesheetsV2(req);
    const employeeUuids = res.reduce((acc, ts) => {
      acc.add(ts.userProfileUuid);
      acc.add(ts.submittedBy);
      if (ts.declinedBy) acc.add(ts.declinedBy);
      return acc;
    }, new Set<string>());
    const { content: employees } = await listUserProfilesWIthFilters(
      window.global_store.company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, [...employeeUuids]),
    );
    const timesheetsData = res.map(ts => {
      return {
        ...ts,
        userProfile: employees.find((e) => e.uuid === ts.userProfileUuid),
        submittedByUser: employees.find((e) => e.uuid === ts.submittedBy)!,
        declinedByUser: ts.declinedBy ? employees.find((e) => e.uuid === ts.declinedBy) : undefined,
      };
    }).sort((a, b) => (a.submittedAt < b.submittedAt ? 1 : -1));
    this.setState({ timesheets: timesheetsData });
    return { content: timesheetsData, metadata: employees };
  };

  submitTimesheet = async (summary: ParsedTimesheetDataSummary, startDate: moment.Moment, endDate: moment.Moment) => {
    const req: SubmitTimesheetRequest = {
      userProfileUuid: this.state.userProfile?.uuid || this.state.currentUserUuid,
      companyUuid: this.state.companyUuid,
      body: {
        submittedBy: window.global_store.profile.uuid,
        startDate: startDate!.format(CONFIG.apiDateFormat),
        endDate: endDate!.format(CONFIG.apiDateFormat),
        activitiesMinutes: summary.dailyActivitiesMinutes,
        workedMinutes: summary.actualWorkMinutes,
        overtimedDays: summary.overtimeNumDays,
        exceededDays: summary.gt24hPerDayNumDays,
      },
    };
    await submitTimesheet(req);
  };

  approveTimesheet = async (timesheetsUuids: string[]) => {
    try {
      const req = {
        companyUuid: this.state.companyUuid,
        requestedBy: this.state.currentUserUuid,
        timesheetsUuids,
      };
      await approveTimesheets(req);
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message:
          timesheetsUuids.length > 1
            ? this.t("All timesheets were successfully approved")
            : this.t("The timesheet was successfully approved"),
        status: "success",
      });
      // void loadData();
    } catch (err) {
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message:
          timesheetsUuids.length > 1
            ? this.t("Failed to approve the timesheets")
            : this.t("Failed to approve the timesheet"),
        status: "error",
      });
      console.error(err);
    }
  };

  declineTimesheet = async (timesheetsUuids: string[], declineReason: string) => {
    try {
      const req = {
        companyUuid: window.global_store.profile.company.uuid,
        requestedBy: window.global_store.profile.uuid,
        timesheetsUuids,
        declineReason,
      };
      await declineTimesheets(req);
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message:
          timesheetsUuids.length > 1
            ? this.t("All timesheets were successfully declined")
            : this.t("The timesheet was successfully declined"),
        status: "success",
      });
      // void loadData();
    } catch (err) {
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message:
          timesheetsUuids.length > 1
            ? this.t("Failed to decline the timesheets")
            : this.t("Failed to decline the timesheet"),
        status: "error",
      });
      console.error(err);
    }
  };

  render() {
    return (
      <TimesheetContext.Provider
        value={{
          submitTimesheet: this.submitTimesheet,
          setUserProfile: this.setUserProfile,
          setDates: this.setDates,
          fetchSettings: this.fetchSettings,
          fetchTimeSheets: this.fetchTimeSheets,
          getTimesheetEnabledFlag: this.getTimesheetEnabledFlag,
          declineTimesheet: this.declineTimesheet,
          approveTimesheet: this.approveTimesheet,
          setReloadDataCallback: this.setReloadDataCallback,
          ...this.state,
        }}
      >
        {this.props.children}
      </TimesheetContext.Provider>
    );
  }
}

export default withTranslation([TranslationNamespaces.timesheets])(TimesheetProvider);
