import { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-create.scss";
import { minsToHrsMins } from "utils/common";
import { withTranslation } from "react-i18next";
import ScheduleAdditionalEntryExit from "components/controls/ScheduleAdditionalEntryExit";
import ClickOutside from "react-click-outside";
import Select from "components/UI/Select";
import TableCommon from "components/TableCommon";
import TimeControl from "components/controls/TimeControl";
import DayOfWeek from "components/UI/Table/DayOfWeek";
import ExtraNightTag from "components/UI/ExtraNightTag";
import { BreaksCell } from "./Breaks/BreaksCell";

const b = BEM.b("schedule-create");

class ScheduleTableException extends Component {
  static defaultProps = {
    onChange: () => {},
    onAddBreak: () => {},
    onRemoveBreak: () => {},
    onAddAdditionalEntryExit: () => {},
    onRemoveAdditionalEntryExit: () => {},
    onRemoveDay: () => {},
    onClearDay: () => {},
    onCopyToNextDay: () => {},
  };
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.TYPES = [
      { label: t("No Exception"), value: "noexception" },
      { label: t("Regular"), value: "regular" },
      { label: t("Flexible"), value: "flexible" },
      { label: t("Non working"), value: "nonworking" },
    ];
    this.state = {
      tableColumns: [],
      isFetching: false,
    };
  }
  componentDidMount() {
    this.setState({
      tableColumns: this.getTableColumns(),
    });
  }

  updateDay = (dayId, dayData) => {
    this.props.onChange(dayId, dayData);
  };

  hasData(schedule) {
    return schedule.entry.value || schedule.exit.value || schedule.breaks.length > 0;
  }

  getTableColumns() {
    const { t, onRemoveBreak, onAddBreak, onAddAdditionalEntryExit, onRemoveAdditionalEntryExit } = this.props;
    const days = ["", t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
    const columns = [
      {
        Header: "",
        accessor: "id",
        Cell: (r) => <DayOfWeek dayName={days[r.value]} />,
        style: {
          fontWeight: "700",
          fontSize: "14px",
          color: "#525F7F",
          lineHeight: "38px",
          textTransform: "uppercase",
          paddingInlineStart: "15px",
        },
        width: 80,
      },
      {
        Header: t("Schedule type"),
        accessor: "type",
        Cell: (r) => (
          <Select
            modifiers={{ field: true }}
            value={r.value}
            onChange={(val) => this.updateDay(r.original.id, { type: val })}
            options={this.TYPES}
          />
        ),
        width: 150,
      },
      {
        Header: t("Entry"),
        accessor: "entry",
        Cell: (r) => {
          const entry = r.value || {};
          if (r.original.type === "nonworking" || r.original.type === "noexception") {
            return null;
          }
          if (r.original.type === "flexible") {
            if (r.original.hoursPerDay && r.original.id !== this.state.selected) {
              return `${r.original.hoursPerDay} ${t("hours per day")}`;
            }
            return (
              <input
                className="time-control"
                type="text"
                maxLength="5"
                size="5"
                onChange={(ev) => {
                  this.updateDay(r.original.id, { hoursPerDay: ev.target.value });
                }}
                placeholder={t("Hours per day")}
                value={r.original.hoursPerDay || ""}
              />
            );
          }
          if (entry && entry.value && !entry.error && r.original.id !== this.state.selected) {
            return entry.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("from")}
              onTimeChange={(val) => this.updateDay(r.original.id, { entry: val })}
              error={entry.error}
              value={entry.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 130,
      },
      {
        Header: t("Exit"),
        accessor: "exit",
        Cell: (r) => {
          const exit = r.value || {};
          if (r.original.type === "nonworking" || r.original.type === "noexception" || r.original.type === "flexible") {
            return null;
          }
          if (exit.value && !exit.error && r.original.id !== this.state.selected) {
            return exit.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("to")}
              onTimeChange={(val) => this.updateDay(r.original.id, { exit: val })}
              error={exit.error}
              value={exit.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 100,
      },
      {
        Header: t("Extra Night"),
        accessor: "extraNight",
        Cell: (r) => (r.value ? <ExtraNightTag value={`${minsToHrsMins(r.value)} hs`} /> : null),
        width: 240,
      },
      {
        Header: t("Break"),
        accessor: "breaks",
        Cell: (r) => {
          if (r.original.type === "nonworking" || r.original.type === "noexception") {
            return null;
          }

          return (
            <BreaksCell
              row={r}
              breaks={r.value}
              selected={this.state.selected}
              updateDay={this.updateDay}
              onRemoveBreak={onRemoveBreak}
            />
          );
        },
        minWidth: 400,
      },
    ];
    if (window.global_store.beta) {
      columns.push({
        Header: t("Additional Entry/Exit"),
        accessor: "extraEntryExit",
        Cell: (r) => {
          if (r.value && r.value.length > 0) {
            return (
              <div>
                {r.value.map((e, i) => {
                  const entryExit = { ...e };
                  return (
                    <ScheduleAdditionalEntryExit
                      key={i}
                      multiple
                      item={entryExit}
                      onChange={(eNeX) => {
                        const entryExits = Object.assign([], r.value);
                        entryExits[i] = eNeX;
                        this.updateDay(r.original.id, { extraEntryExit: entryExits });
                      }}
                      onAddItem={() => onAddAdditionalEntryExit(r.original.id, i)}
                      onRemoveItem={() => onRemoveAdditionalEntryExit(r.original.id, i)}
                    />
                  );
                })}
              </div>
            );
          }
          return (
            <div className={b("break-link")} onClick={() => onAddAdditionalEntryExit(r.original.id)}>
              {t("Add extra entry")}
            </div>
          );
        },
        style: { textAlign: "center", overflow: "visible" },
        headerStyle: { textAlign: "center" },
        minWidth: 460,
      });
    }
    columns.push({
      Header: t("Total"),
      accessor: "total",
      minWidth: 240,
      Cell: (r) =>
        r.value ? (
          <div>
            {r.value ? minsToHrsMins(r.value) : ""} {t("hs")}
          </div>
        ) : null,
      headerStyle: { textAlign: "center" },
      style: {
        textAlign: "center",
        fontWeight: "700",
        fontSize: "14px",
        color: "#525F7F",
      },
    });
    return columns;
  }
  copyToNextDay(id, ev) {
    ev.stopPropagation();
    this.setState({ selected: id + 1 }, () => this.props.onCopyToNextDay(id));
  }
  unselect() {
    this.setState({ selected: null });
  }
  render() {
    const { tableColumns } = this.state;
    const { tableData } = this.props;

    return (
      <div className={b()}>
        <ClickOutside
          onClickOutside={(e) => {
            // don't unselect row if clicked inside the popup
            if (!e.target.closest(".PopupWrapper")) {
              this.unselect();
            }
          }}
        >
          <TableCommon
            noScroll
            rows={tableData}
            columns={tableColumns}
            showPagination={false}
            pages={1}
            className="exception-table table-common"
            defaultPageSize={1000}
            loading={this.state.isFetching}
            manual
            showPageSizeOptions={false}
            getTrProps={(s, r) => {
              const selected = this.state.selected === r.original.id;
              const style = {};
              if (selected) {
                style.backgroundColor = "var(--cp-table-row-hovered-bg-color, var(--colors-surface-50))";
              }
              return {
                style,
                onClick: () => {
                  this.setState({ selected: r.original.id });
                },
              };
            }}
          />
        </ClickOutside>
      </div>
    );
  }
}
export default withTranslation("schedules")(ScheduleTableException);
