import { ColDef, GridReadyEvent } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import CheckboxControl from "../NewCheckbox/NewCheckbox";

const Dropdown = styled.div`
  position: relative;
  .DropdownControl {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--surface-colors-surface-600);
    font-size: 15px;
    cursor: pointer;
    border-radius: var(--shapes-border-radius-default);
    background: var(--colors-surface-50);
    .Icon {
      display: flex;
      margin-inline-end: 4px;
      svg path {
        fill: var(--colors-surface-600);
      }
    }
    &:hover {
      background: var(--colors-surface-100);
    }
  }
  .DropdownOptions {
    position: absolute;
    background: var(--colors-surface-0);
    z-index: 150;
    top: 40px;
    right: 0;
    width: 240px;
    border-radius: var(--shapes-border-radius-default);
    box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
    border: 1px solid var(--colors-surface-150);
    .Options {
      padding: 6px 0;
    }
    .Text {
      color: var(--colors-surface-900-p);
      font-size: var(--typography-font-size-default);
      margin-inline-end: auto;
    }
    .Description {
      color: var(--colors-surface-700);
    }
    .Option {
      margin: 8px;
      display: flex;
      height: 32px;
      padding: 10px 8px;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      font-size: var(--typography-font-size-default);
      svg path {
        fill: var(--colors-surface-600);
      }
      &.Active {
        cursor: default;
        color: var(--colors-primary-500-p);
        svg path {
          fill: var(--colors-primary-500-p);
        }
      }
      &.Danger {
        color: var(--colors-danger-600-p);
      }
    }
    .Divider {
      border-top: 1px solid var(--colors-surface-100);
    }
    .Switcher {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px;
      padding: 10px 8px;
      gap: 12px;
      font-size: var(--typography-font-size-default);
    }
    &.ColumnSelection {
      .Search {
        border: none;
        outline: none;
        width: 100%;
        height: 44px;
        padding: 0 12px;
        color: var(--colors-surface-600);
        &::placeholder {
          color: var(--colors-surface-600);
        }
        border-bottom: 1px solid var(--colors-surface-150);
      }
      .ColumnRow {
        padding: 0 12px;
        height: 32px;
      }
      .Footer {
        display: flex;
        height: 48px;
        padding: 8px 12px;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        border-top: 1px solid var(--colors-surface-150);
        color: var(--colors-surface-800);
        background: rgba(247, 249, 252, 0.88);
        font-size: var(--typography-font-size-default);
      }
    }
  }
`;
const ColumnIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.5 1.66675H2.49996C2.27895 1.66675 2.06698 1.75455 1.9107 1.91083C1.75442 2.06711 1.66663 2.27907 1.66663 2.50008V17.5001C1.66663 17.7211 1.75442 17.9331 1.9107 18.0893C2.06698 18.2456 2.27895 18.3334 2.49996 18.3334H17.5C17.721 18.3334 17.9329 18.2456 18.0892 18.0893C18.2455 17.9331 18.3333 17.7211 18.3333 17.5001V2.50008C18.3333 2.27907 18.2455 2.06711 18.0892 1.91083C17.9329 1.75455 17.721 1.66675 17.5 1.66675ZM6.66663 16.6667H3.33329V3.33341H6.66663V16.6667ZM11.6666 16.6667H8.33329V3.33341H11.6666V16.6667ZM16.6666 16.6667H13.3333V3.33341H16.6666V16.6667Z"
      fill="#525F7F"
    />
  </svg>
);

type ColumnSelectorToolPanelProps = {
  gridApi?: GridReadyEvent;
};
export const ColumnSelectorToolPanel = (props: ColumnSelectorToolPanelProps) => {
  const [opened, setOpen] = useState(false);
  const { t } = useTranslation(TranslationNamespaces.employeesPageAllTable);
  const [columns, setColumns] = useState<ColDef[]>([]);
  const [searchValue, setSearchValue] = useState<string>();

  const open = useCallback(
    (value: boolean) => {
      setOpen(value);
      if (value) {
        setColumns(props.gridApi?.api.getColumnDefs() || []);
      }
    },
    [props.gridApi],
  );

  const selectedCols = useMemo(() => columns.filter((c) => !c.hide), [columns]);
  const filteredColumns = useMemo(
    () =>
      searchValue ? columns.filter((c) => c.headerName?.toLowerCase().includes(searchValue.toLowerCase())) : columns,
    [columns, searchValue],
  );
  const toggleColumn = (col: ColDef, value: boolean) => {
    const arr = [...columns];
    const target = arr.find((c) => c.colId === col.colId);
    if (target) {
      target.hide = !value;
      setColumns(arr);
      props.gridApi?.api.setColumnDefs(arr);
    }
  };

  return (
    <ClickOutside
      onClickOutside={() => {
        setOpen(false);
      }}
    >
      <Dropdown>
        <div onClick={() => open(!opened)} className="DropdownControl">
          {ColumnIcon}
        </div>
        {opened ? (
          <div className="DropdownOptions ColumnSelection">
            <input
              className="Search"
              placeholder={t("Search properties")}
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {filteredColumns.map((col) => (
              <CheckboxControl
                key={col.headerName}
                className="ColumnRow"
                checked={!col.hide}
                label={col.headerName}
                onChange={(checked) => toggleColumn(col, checked)}
              />
            ))}
            <div className="Footer">
              {t("{{activeColumns}} of {{allColumns}} shown", {
                activeColumns: selectedCols.length,
                allColumns: columns.length,
              })}
            </div>
          </div>
        ) : null}
      </Dropdown>
    </ClickOutside>
  );
};
