const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const retryUntilTrue = (fn, interval = 10_000, retryCount = 30) => {
  let isCancelled = false;
  return {
    cancel: () => {
      isCancelled = true;
    },
    run: async () => {
      for (let i = 0; i < retryCount; i += 1) {
        if (isCancelled) {
          return false;
        }

        // eslint-disable-next-line no-await-in-loop
        const result = await fn();

        if (result) {
          return result;
        }

        // eslint-disable-next-line no-await-in-loop
        await sleep(interval);
      }

      return false;
    },
  };
};

const retryUntilResolved = <T>(fn: () => Promise<T>, interval = 5_000, retryCount = 3) => {
  let isCancelled = false;
  return {
    cancel: () => {
      isCancelled = true;
    },
    run: async () => {
      for (let i = 0; i < retryCount; i += 1) {
        if (isCancelled) {
          return false;
        }

        // eslint-disable-next-line no-await-in-loop
        try {
          const data = await fn();

          if (isCancelled) {
            return false;
          }

          return data;
        } catch {
          // pass
        }

        // eslint-disable-next-line no-await-in-loop
        await sleep(interval);
      }

      return false;
    },
  };
};

export { retryUntilTrue, retryUntilResolved };
