import {
  RequestSubTypeApprovalFlow,
  RequestApprovalFlowApproverType,
  RequestSubTypeLimitType,
  Request,
} from "types/models/request";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import { getDateWithTZ } from "./common";

type ApprovalFlowToStateParams = {
  approvalFlow: RequestSubTypeApprovalFlow[];
  employees: FilteredEmployeeProfile[];
};

export const approvalFlowToState = ({
  approvalFlow,
  employees,
}: ApprovalFlowToStateParams): RequestSubTypeApprovalFlow[] => {
  const defaultApprovalLevel: RequestSubTypeApprovalFlow = {
    stage: 0,
    approver: {
      type: RequestApprovalFlowApproverType.role,
      label: "",
      uuid: null,
    },
    autoApproval: false,
    limitType: RequestSubTypeLimitType.month,
    limit: null,
  };

  return [...Array(3)].map((_, i) => {
    const level = approvalFlow?.[i] || null;

    if (level) {
      level.limit = level.limit || null;
      const approver = employees.find((e) => e.uuid === level.approver.uuid);

      if (level.approver.type === RequestApprovalFlowApproverType.userProfile && approver) {
        level.approver.label = approver.fullName;
      }

      return level;
    }

    return { ...defaultApprovalLevel, stage: i };
  });
};

export const getRequestDate = (request: Request) => {
  let str = "";
  let startDate;
  let endDate;
  if (request.startTime) {
    startDate = getDateWithTZ(request.startTime, request.timezone);
  }

  if (request.endTime) {
    endDate = getDateWithTZ(request.endTime, request.timezone);
  }

  if (startDate && endDate && startDate.isSame(endDate, "day") && request.allDay) {
    str = startDate.format("ddd, DD MMM");
  } else if (startDate && endDate && startDate.isSame(endDate, "day") && !request.allDay) {
    str = `${startDate.format("ddd, DD MMM")} ${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
  } else if (startDate && endDate && !startDate.isSame(endDate, "day") && request.allDay) {
    str = `${startDate.format("ddd, DD MMM")} - ${endDate.format("ddd, DD MMM")}`;
  } else if (startDate && endDate && !startDate.isSame(endDate, "day") && !request.allDay) {
    str = `${startDate.format("ddd, DD MMM")} ${startDate.format("HH:mm")} - ${endDate.format(
      "ddd, DD MMM",
    )} ${endDate.format("HH:mm")}`;
  } else if (endDate) {
    str = endDate.format("HH:mm ddd DD/MM/YYYY");
  }

  return str;
};
