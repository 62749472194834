import { Fragment } from "react";
import styled from "styled-components";

const TypeSwitchWrapper = styled.div`
  height: 36px;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.inputBorderDefault};
  border-radius: var(--shapes-border-radius-default);
  width: fit-content;
`;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: ${(props) => props.theme.colors.inputBorderDefault};
`;

interface iTabProps {
  theme: any;
  active: boolean;
}

// eslint-disable-next-line
const Tab = styled.div<iTabProps>`
  display: flex;
  align-items: center;
  ${(props) => (props.active ? `background: ${props.theme.colors.activitiCellScheduleItemHover}` : "")}
  font-size: var(--typography-font-size-default);
  color: ${(props) => (props.active ? "var(--colors-primary)" : "var(--colors-mainText)")};
  line-height: 14px;
  height: 100%;
  cursor: pointer;
  padding: 0 18px;
`;

interface iOption {
  label: string;
  value: string;
}

interface iTypeSwitchProps {
  options: Array<iOption>;
  value: string;
  onChange: (o: iOption) => void;
}

const TypeSwitch = ({ options = [], value, onChange }: iTypeSwitchProps): JSX.Element => (
  <TypeSwitchWrapper className="type-switch">
    {options.map((o, i) => (
      <Fragment key={o.label}>
        <Tab active={value === o.value} onClick={() => onChange(o)}>
          {o.label}
        </Tab>
        {i < options.length - 1 && <Separator />}
      </Fragment>
    ))}
  </TypeSwitchWrapper>
);

export default TypeSwitch;
