import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import Sentry from "utils/sentryUtils";
import { SuperpunchContext } from "context/SuperpunchProvider";
import { OnPunchActionData, OnPunchDeclineData } from "context/SuperpunchProvider/types";
import { Punch, DailySummaryPunch } from "types/models/punches";
import { SuperpunchPunchCellData } from "types/models/superpunch";
import { TranslationNamespaces } from "types/translationNamespaces";
import ShiftEventsLogsScreen from "./CellDropdown/ShiftEventsLogsScreen";
import LockedDayScreen from "./CellDropdown/LockedDayScreen";
import DropdownHeaderWithActionButton from "./CellDropdown/DropdownHeaderWithActionButton";
import CellDropdown from "./CellDropdown";
import PunchesList from "./CellDropdown/PunchesList";
import DropdownFooter from "./CellDropdown/DropdownFooter";
import NoPunches from "./CellDropdown/NoPunches";
import DropdownHeader from "./CellDropdown/DropdownHeader";

interface CellPunchDropdownProps extends WithTranslation {
  date: moment.Moment;
  value: SuperpunchPunchCellData;
  employeeUuid: string;
  eventKey: string;
  isLockedDay: boolean;
  title?: string;
  onTitleBackClick?: () => void;
  onChange: (punch?: DailySummaryPunch | Record<string, never>) => void;
  onPunchApprove: (data: OnPunchActionData) => void;
  onPunchValidate: (data: OnPunchActionData) => void;
  onPunchDecline: (data: OnPunchDeclineData) => void;
  hideDropdown: () => void;
}

interface CellPunchDropdownState {
  punches: Punch[] | null;
  showLogs: boolean;
}

class CellPunchDropdown extends Component<CellPunchDropdownProps, CellPunchDropdownState> {
  static contextType = SuperpunchContext;
  context!: ContextType<typeof SuperpunchContext>;

  readonly state: Readonly<CellPunchDropdownState> = {
    punches: null,
    showLogs: false,
  };

  async UNSAFE_componentWillMount() {
    const punches: Punch[] = (await this.getData()) || [];

    this.setState({ punches });
  }

  getData = async () => {
    const { date } = this.props;
    const from = date.clone().subtract(1, "day").format("YYYY-MM-DD");
    const to = date.clone().add(1, "day").format("YYYY-MM-DD");

    try {
      const resp = await this.context.fetchPunches({ from, to });

      return resp;
    } catch (er) {
      Sentry.sendError(er);
      return [];
    }
  };

  render() {
    const {
      t,
      value,
      date,
      onChange,
      onPunchApprove,
      onPunchValidate,
      onPunchDecline,
      employeeUuid,
      hideDropdown,
      eventKey,
      isLockedDay,
      onTitleBackClick,
      title,
    } = this.props;
    const { punches, showLogs } = this.state;
    let selectedPunch: Punch | null = null;

    if (!showLogs && isLockedDay) {
      return (
        <CellDropdown
          header={<DropdownHeader title={t("Log History")} onBackClick={onTitleBackClick} />}
          content={<LockedDayScreen onViewLogs={() => this.setState({ showLogs: true })} />}
        />
      );
    }

    if (showLogs && eventKey && date) {
      return (
        <ShiftEventsLogsScreen
          punches={punches || []}
          userProfineUuid={employeeUuid}
          shiftEventId={`${date.format("YYYY-MM-DD")}-1`}
          eventKey={eventKey}
          onPunchApprove={onPunchApprove}
          onPunchValidate={onPunchValidate}
          onPunchDecline={onPunchDecline}
          onBack={() => this.setState({ showLogs: false })}
        />
      );
    }

    if (punches) {
      selectedPunch = value.raw ? punches.find((p) => p.uuid === value.raw?.uuid) || null : null;
    }

    return (
      <CellDropdown
        header={
          <DropdownHeaderWithActionButton
            title={title || t("Select a punch")}
            onBackClick={onTitleBackClick}
            onHistoryClick={() => this.setState({ showLogs: true })}
            employeeUuid={employeeUuid}
            onPunchApprove={onPunchApprove}
            onPunchValidate={onPunchValidate}
            cellData={value}
            date={date}
            selectedPunch={selectedPunch}
            onPunchClear={value?.raw ? () => onChange({}) : undefined}
          />
        }
        content={
          punches ? (
            <PunchesList
              punches={punches}
              date={date}
              employeeUuid={employeeUuid}
              onChange={onChange}
              onPunchApprove={onPunchApprove}
              onPunchValidate={onPunchValidate}
              onPunchDecline={onPunchDecline}
              hideDropdown={hideDropdown}
              cellData={value}
            />
          ) : (
            <NoPunches loading />
          )
        }
        footer={<DropdownFooter label={t("New Punch")} onClick={() => onChange()} />}
      />
    );
  }
}

export default withTranslation(TranslationNamespaces.punchesPage)(CellPunchDropdown);
