import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { stylesheet } from "astroturf";
import { getTitle } from "utils/common";
import { Feature, FeatureLabel } from "types/models/subscription";
import GlobalContext from "context/global-context";
import Tabs from "components/UI/Tabs";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Subscribe } from "unstated";
import Button, { ButtonState } from "components/controls/StyledButton";
import styled from "styled-components";
import * as images from "components/svg-images";
import { useAsyncCallback } from "utils/useAsyncEffect";
import Loader from "components/styled/Loader";
import { GlobalStoreCompany } from "types/models/company";
import ga from "utils/ga";
import ProductSelector from "./ProductSelector";
import PricingContainer from "../PricingContainer";
import { BillingService } from "../BillingService";

const QA = [
  {
    question: "Question1",
    answer: "Answer1",
  },
  {
    question: "Question2",
    answer: "Answer2",
  },
  {
    question: "Question3",
    answer: "Answer3",
  },
];

const styles = stylesheet`
  .Layout {
    width: 100%;

    .ContentWrapper, .TopWrapper {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
    }
    .ContentWrapper {
      padding-bottom: 100px;
    }
    .TopWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      text-align: center;
    }

    .TopBlock {
      width: 100%;
      border-bottom: 2px solid rgba(30, 151, 247, 0.15);
      margin: 64px auto 40px;

      .Tabs {
        margin-top: 72px;
        display: inline-block;
        ul {
          border-bottom: none;
          li {
            padding: 0 16px 20px;
            height: auto;
          }
        }
      }
    }
    .Title {
      font-size: 30px;
      line-height: 38px;
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-surface-900-p);
      margin-bottom: 16px;
      margin-top: 0;

    }
    .Description {
      font-size: 20px;
      color: var(--colors-surface-700);
    }
    .QA {
      margin: 80px auto 0;
      max-width: 824px;

      .QATitle {
        font-size: 25px;
        color: var(--colors-surface-900-p);
        margin-bottom: 24px;
        text-align: center;
      }
      .QAMore {
        display: flex;
        flex-direction: column;
        background: var(--colors-surface-50);
        padding: 40px;
        margin: 40px 0;
        border-radius: 8px;
        color: var(--colors-surface-900-p);
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-bottom: 1px solid var(--colors-surface-150);
  padding: 32px 0;
  & > p {
    margin: 10px 0 0;
    font-size: var(--typography-font-size-default);
    color: var(--colors-surface-700);
  }
`;

const IconWrapper = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  & svg {
    transform: rotate(${(p) => (p.isOpened ? "270deg" : "90deg")});
    path {
      fill: var(--colors-surface-800);
    }
  }
`;
const Label = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--colors-surface-900-p);
  font-size: 20px;
  font-weight: var(--typography-font-weight-default);
`;

type AccordionProps = PropsWithChildren & {
  label: string;
};

function Accordion(props: AccordionProps) {
  const [isOpened, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpened);

  return (
    <>
      <Wrapper onClick={toggleOpen}>
        <Label isOpened={isOpened}>
          <div>{props.label}</div>
          <IconWrapper isOpened={isOpened}>{images.calArrowRight}</IconWrapper>
        </Label>
        {isOpened ? props.children : null}
      </Wrapper>
    </>
  );
}

const SubscriptionPlansPage = () => {
  const context = useContext(GlobalContext);
  const { t } = useTranslation(TranslationNamespaces.subscription);
  document.title = getTitle(t("Plans & pricing"));
  const [activeTab, setActiveTab] = useState<string>();

  const [init, loadData] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    await BillingService.getInitialCustomerData(company.uuid);
    await PricingContainer.setCompanyUuid(company.uuid);
    await PricingContainer.getSubscriptions();
    await PricingContainer.getProducts();
    await PricingContainer.getSubscriptionCoupon();
    await PricingContainer.getCurrentSeatsUsed();
    await PricingContainer.initPricingPage(company);
    await selectTab(BillingService.subscriptionPlan);
  }, []);
  useEffect(() => void init(), [init]);

  const selectTab = async (tab: string) => {
    setActiveTab(tab);
    await PricingContainer.selectProduct(tab);
    await PricingContainer.selectPrice(
      PricingContainer.state.interval,
      t,
      PricingContainer.state.currentSeatsNumber,
      true,
      true,
    );

    ga.trackPricingPageTab(tab as Feature);
  };

  const tabs = [
    {
      name: Feature.TA,
      label: t(FeatureLabel[Feature.TA]),
    },
    {
      name: Feature.Project,
      label: t(FeatureLabel[Feature.Project]),
    },
    {
      name: Feature.Bundle,
      label: t("Bundle"),
    },
  ];

  return (
    <Subscribe to={[PricingContainer]}>
      {(pricing: typeof PricingContainer) => (
        <div className={styles.Layout}>
          <div className={styles.TopBlock}>
            <div className={styles.TopWrapper}>
              <h1 className={styles.Title}>{t("Plans & Pricing")}</h1>
              <div className={styles.Description}>{t("Flexible plans that scale with your company")}</div>
              <div className={styles.Tabs}>
                <Tabs tabs={tabs} value={activeTab} onChange={(tab) => selectTab(tab!)} />
              </div>
            </div>
          </div>

          <div className={styles.ContentWrapper}>
            {loadData ? <Loader /> : <ProductSelector />}
            <div className={styles.QA}>
              <div className={styles.QATitle}>{t("Frequently Asked Questions")}</div>
              {QA.map((block) => (
                <Accordion key={`accordion_${block.question}`} label={t(block.question)}>
                  <p>{t(block.answer)}</p>
                </Accordion>
              ))}
              {BillingService.isBrazilCompany() ? (
                <div className={styles.QAMore}>
                  <div className={styles.QATitle}>{t("Have more questions?")}</div>
                  <Button
                    value={t("Contact Sales")}
                    style={{ width: "160px", margin: "0 auto" }}
                    state={ButtonState.enableDay}
                    onClick={() => pricing.bookCall(true)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Subscribe>
  );
};

export default SubscriptionPlansPage;
