import { useTranslation } from "react-i18next";

import CheckboxControl from "components/UI/NewCheckbox";
import User from "components/User";
import { FilteredEmployeeProfile } from "types/models/userProfile";

import multipleEmployeesSearch from "../../img/multiple-employees-search.png";
import LazyListLoading from "./LazyListLoading";
import {
  Arrow,
  AvailableEmployeesHeader,
  AvailableEmployeesWrapper,
  MoveAll,
  MoveEmployee,
  SearchImg,
  SearchImgWrapper,
  SelectAllWrapper,
  UserItem,
} from "./styled";

interface AvailableEmployeesProps {
  availableEmployees: FilteredEmployeeProfile[];
  checkedEmployees: string[];
  onSelect: (value: FilteredEmployeeProfile) => void;
  onToggle: (value: FilteredEmployeeProfile) => void;
  moveAll: () => void;
  toggleAll: (value: boolean) => void;
  checkedAll: boolean;
}

export const AvailableEmployees = ({
  availableEmployees,
  checkedEmployees,
  onSelect,
  onToggle,
  moveAll,
  toggleAll,
  checkedAll,
}: AvailableEmployeesProps) => {
  const { t } = useTranslation("common");

  return (
    <AvailableEmployeesWrapper>
      <AvailableEmployeesHeader>
        {availableEmployees.length ? (
          <SelectAllWrapper>
            <CheckboxControl
              className="user-checkbox"
              checked={checkedAll}
              label={t("Select All")}
              onChange={toggleAll}
            />
          </SelectAllWrapper>
        ) : null}
        {checkedEmployees.length ? <MoveAll onClick={moveAll}>{t("Move")}</MoveAll> : null}
      </AvailableEmployeesHeader>
      {!availableEmployees.length ? (
        <SearchImgWrapper>
          <SearchImg src={multipleEmployeesSearch} />
        </SearchImgWrapper>
      ) : (
        <LazyListLoading>
          {availableEmployees.map((e) => (
            <UserItem
              key={e.uuid}
              onClick={(ev) => {
                const target = ev.target as HTMLElement;
                return target.tagName === "DIV" ? onToggle(e) : null;
              }}
            >
              <CheckboxControl
                className="user-checkbox"
                checked={checkedEmployees.indexOf(e.uuid) !== -1}
                onChange={() => onToggle(e)}
              />
              <User
                user={{
                  fullName: e.fullName,
                  avatarId: e.avatarId,
                  position: e.position ? e.position.title : "",
                }}
              />
              <MoveEmployee
                className="arrow-add"
                onClick={(ev) => {
                  ev.stopPropagation();
                  onSelect(e);
                }}
              >
                <Arrow isRTL={window.global_store.isRTL} />
              </MoveEmployee>
            </UserItem>
          ))}
        </LazyListLoading>
      )}
    </AvailableEmployeesWrapper>
  );
};
