import { Component, Context, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import { addSubsidiary, getSubsidiaries } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Subsidiary } from "types/models/subsidiary";
import sentryUtils from "utils/sentryUtils";
import { GlobalContext } from "context/GlobalContextProvider";
import ModalDialog from "./UI/ModalDialog";
import SubsidiariesPopupMessage from "./Subsidiaries/SubsidiariesPopupMessage";

type Option = {
  label: string;
  value: string | number;
};

interface SubsidiariesDropdownControlProps {
  t: WithTranslation["t"];
  /** Subsidiary uuid or id as an option value */
  uuid?: boolean;
  value: string | number | null;
  /** Subsidiary uuid or id */
  onChange: (value: string | number | null) => void;
}

interface SubsidiariesDropdownControlState {
  isLoading: boolean;
  options: Option[];
  createPopupVisible: boolean;
  subsidiaries: Subsidiary[];
}

class SubsidiariesDropdownControl extends Component<
  SubsidiariesDropdownControlProps,
  SubsidiariesDropdownControlState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<SubsidiariesDropdownControlState> = {
    isLoading: true,
    options: [],
    createPopupVisible: false,
    subsidiaries: [],
  };

  async componentDidMount() {
    const { t, uuid } = this.props;

    const subsidiaries = (await this.context.getSubsidiaries()) || [];

    let options: Option[] = [{ label: t("none-option"), value: "" }];

    if (subsidiaries.length > 0) {
      options = options.concat(subsidiaries.map((s) => ({ label: s.name, value: uuid ? s.uuid : s.id })));
    }

    this.setState({ options, isLoading: false, subsidiaries });
  }

  createSubsidiary = async ({ name, code, taxPayerId }: { name: string; code: string; taxPayerId: string }) => {
    const { onChange, uuid } = this.props;
    const requestData = {
      body: {
        subsidiary: {
          name,
          code,
          tax_payer_id: taxPayerId,
        },
      },
    };

    try {
      const { subsidiary } = await addSubsidiary(requestData);

      this.setState(
        {
          options: [...this.state.options, { label: subsidiary.name, value: uuid ? subsidiary.uuid : subsidiary.id }],
        },
        () => onChange(uuid ? subsidiary.uuid : subsidiary.id),
      );
    } catch (err) {
      sentryUtils.sendError(err);
    } finally {
      this.setState({ createPopupVisible: false });
    }
  };

  render() {
    const { t, value, onChange } = this.props;
    const { options, isLoading, createPopupVisible, subsidiaries } = this.state;

    return (
      <>
        <SelectControl<string | number>
          options={options}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          isSearchable
          placeholder={t("Select subsidiary")}
          withCreateOption
          onCreateOption={() => this.setState({ createPopupVisible: true })}
          createOptionText={t("Create new subsidiary")}
        />

        <ModalDialog isOpen={createPopupVisible} onClose={() => this.setState({ createPopupVisible: false })}>
          <SubsidiariesPopupMessage
            title={t(`${TranslationNamespaces.subsidiaries}|New Subsidiary Company`)}
            item={{}}
            allItems={subsidiaries}
            buttonYesTitle={t(`${TranslationNamespaces.subsidiaries}|Submit`)}
            onClose={() => this.setState({ createPopupVisible: false })}
            onYes={this.createSubsidiary}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.subsidiaries])(
  SubsidiariesDropdownControl,
);
