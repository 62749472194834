import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import Pagination from "components/Pagination";
import BEM from "utils/BEM";
import { getSelectedColumns, getTrPropsClassName } from "utils/tableHelpers";
import { getEmployeeNameColumn, mapTableData } from "utils/reportsHelpers";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { minsToHrsMins } from "utils/common";
import TablePage from "components/TablePage";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import ReportsNoContent from "./ReportsNoContent";
import { sliceReportIntoChunks, getPhasesLabel, PhasesType } from "./helpers";
import "styles/reports-hoursbank-table.scss";

const b = BEM.b("reports-hoursbank-table");

class ReportsHoursbankTableAllEmployees extends Component {
  state = {
    employees: [[]],
    activePhases: [],
    isFetching: false,
    selectedColumns: getSelectedColumns(
      "name,total_worked_hours,missed_hours,cumulative_hours_bank",
      "ReportsHoursbankTableAllEmployees",
    ),
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const { perPage } = this.state;
    const {
      t,
      startDate,
      endDate,
      searchObj,
      showInactiveEmploees,
      oldReport,
      updateRecalculatingMessage,
      onlyNonZeroPhases,
      onlyNonZeroLines,
      skipSupervisors,
      setIsLoading,
    } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ isFetching: true }, () => setIsLoading(true));

      try {
        const response = await getReport(
          {
            showInactiveEmploees,
            groupUUID: searchObj.uuid,
            groupType: searchObj.type,
            startDate,
            endDate,
            companyUUID,
            onlyNonZeroPhases,
            onlyNonZeroLines,
            skipSupervisors,
            oldReport,
            type: "hours_bank",
          },
          true,
        );

        const resp = response.content;

        if (!oldReport && resp) {
          const allDaysCount = resp.length || 0;
          const completedDaysCount = resp.filter((d) => d.status === "completed").length;

          if (!resp.length) {
            updateRecalculatingMessage(false);
          } else {
            updateRecalculatingMessage(allDaysCount !== completedDaysCount || response?.metadata?.status === "pending");
          }
        }

        const employees = sliceReportIntoChunks(resp, perPage);

        this.setState({
          employees: employees.length ? employees : [[]],
          totalRecords: resp.length,
          activePhases: response.metadata.activePhases,
          error: false,
          isFetching: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({ error: t("Failed to generate report") });
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = (activePhases = []) => {
    const { t } = this.props;

    const phasesColumns = activePhases.length
      ? activePhases.map((phase) => ({
          accessor: phase.uuid,
          rubyAccessor: "phases",
          groupLabel: t("Phases"),
          label: getPhasesLabel(t, PhasesType.hoursBank, phase),
          Cell: (row) => {
            const hours = row.original.totalHoursBankPhases[phase.uuid] || 0;
            return minsToHrsMins(hours);
          },
          style: { fontWeight: "500" },
          align: "end",
          minWidth: 100,
        }))
      : [];

    const columns = [
      getEmployeeNameColumn(t),
      ...phasesColumns,
      {
        accessor: "totalWorkedMinutes",
        rubyAccessor: "total_worked_hours",
        label: t("Total Worked Hours"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "right",
      },
      {
        accessor: "cumulativeHoursBankMinutes",
        rubyAccessor: "cumulative_hours_bank",
        label: t("Cumulative Hours Bank"),
        minWidth: 130,
        align: "right",
      },
      {
        accessor: "totalMissedMinutes",
        rubyAccessor: "missed_hours",
        label: t("Missed Minutes"),
        minWidth: 100,
        align: "right",
      },
    ];

    return columns;
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsHoursbankTableAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPageLimitChange = (pageLimit) => {
    const { employees } = this.state;

    this.setState({ page: 1, perPage: pageLimit, employees: sliceReportIntoChunks(employees.flat(), pageLimit) });
  };

  render() {
    const { error, employees, isFetching, selectedColumns, activePhases, page, perPage, totalRecords } = this.state;
    const {
      t,
      onlyNonZeroPhases,
      onlyNonZeroLines,
      skipSupervisors,
      showInactiveEmploees,
      startDate,
      endDate,
      searchObj,
      oldReport,
    } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    const data = mapTableData(
      [
        "fullName",
        "totalWorkedMinutes",
        "totalMissedMinutes",
        "cumulativeHoursBankMinutes",
        "totalHoursBankPhases",
        "status",
        "trHeading",
      ],
      employees[page - 1],
    );

    const columns = this.getColumns(activePhases);

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj && searchObj.type && searchObj.type !== "all" && searchObj.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    return (
      <div className={b()}>
        <TablePage
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          downloadControl={
            <DownloadControlWithEvents
              withHeaderTooltip
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  searchObj,
                  startDate,
                  showInactiveEmploees,
                  onlyNonZeroPhases,
                  onlyNonZeroLines,
                  skipSupervisors,
                  endDate,
                  oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: selectedColumns ? "hours_bank_all" : "hours_bank",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className: getTrPropsClassName(this.props, rowInfo),
          })}
          rows={data}
          columns={columns}
          loading={isFetching}
          interactive={false}
          className="reports-hoursbank-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation(["reports-page", "phases"])(ReportsHoursbankTableAllEmployees));
