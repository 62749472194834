import React, { Component, ContextType, Dispatch, SetStateAction } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { RequestSubType, RequestType, RequestTypeName } from "types/models/request";
import GlobalContext from "context/global-context";
import { requestTypeToggle, requestTypeCreate, requestTypeDelete } from "utils/apiHelpers";
import RequestSettingsSubPage from "components/Requests/RequestSettingsSubPage";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import arrowRight from "components/Employees/img/arrowRight.svg";
import { TranslationNamespaces } from "types/translationNamespaces";
import { showSnackbar } from "utils/common";
import Tooltip from "components/UI/Tooltip";
import NewCategory from "./RequestSettingsNewCatergoryControl";
import RequestSettingsEditableTitleControl from "./RequestSettingsEditableTitleControl";
import DefaultPage from "./RequestSettingsDefaultPage";
import ListItemCategory from "../UI/ListItemCategory";
import NewRequestCategory from "./NewRequestCategory";
import BasicTooltip from "../UI/BasicTooltip";

const PageWrapper = styled.div`
  padding-top: 0px;
`;

const PageContent = styled.div`
  margin-top: 0px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  display: flex;
  width: 280px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-inline-end: 1px solid var(--component-modal-border-color, #e1e3eb);
  overflow-y: auto;
  max-height: calc(85vh - 200px);
`;

const Column = styled.div`
  padding: 24px 36px;
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(85vh - 200px);

  &:first-child {
    width: 264px;
    border-inline-end: 2px solid var(--colors-borderPermissions);
    padding: 24px 0px;
    padding-inline-end: 36px;
  }
  &:last-child {
    width: calc(100% - 264px);
    padding-inline-end: 0px;
  }
`;

const ColumnTitle = styled.div<{ $noPadding?: boolean }>`
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  ${(p) => (!p.$noPadding ? "padding-inline-start: 12px;" : "")}
  color: var(--colors-surface-900-p);
  margin-bottom: 18px;
  position: relative;
`;

const BackButton = styled.div`
  position: absolute;
  width: 11px;
  height: 11px;
  inset-inline-start: -22px;
  top: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.629 11.028a.889.889 0 01-1.258 0l-4.4-4.4a.889.889 0 010-1.257l4.4-4.4A.889.889 0 016.63 2.23L2.857 6 6.63 9.771a.889.889 0 010 1.257z' fill='%23BAC7DC'/%3E%3C/svg%3E");
  ${(p) => (p.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
  cursor: pointer;
`;

const Item = styled.div`
  font-size: var(--typography-font-size-default);
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-800);
  line-height: 35px;
  padding: 0;
  padding-inline-end: 32px;
  padding-inline-start: 12px;
  border-radius: var(--shapes-border-radius-default);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background: none;
  cursor: pointer;

  &:after {
    content: "";
    background-repeat: no-repeat;
    background-image: url("${arrowRight}");
    ${(p) => (p.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
    width: 11px;
    height: 8px;
    position: absolute;
    inset-inline-end: 16px;
    top: calc(50% - 4px);
    display: none;
  }

  &:hover {
    background: #f3f5fa;

    &:after {
      display: block;
    }
  }
`;

const ItemWithToggle = styled.div<{ $active: boolean; $customType: boolean }>`
  font-size: var(--typography-font-size-default);
  font-weight: ${(props) => (props.$active ? "500" : "400")};
  color: var(--colors-surface-800);
  line-height: 35px;
  padding: 0;
  padding-inline-end: 32px;
  padding-inline-start: 12px;
  border-radius: var(--shapes-border-radius-default);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  background: ${(props) => (props.$active ? `var(--colors-activitiCellScheduleItemHover)` : "none")};
  cursor: pointer;

  .checkbox-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline-start: 16px;
  }

  ${(p) =>
    !p.$customType
      ? `
    &:after {
    content: "";
    background-repeat: no-repeat;
    background-image: url("${arrowRight}");
    ${p.theme.dir === "rtl" ? "transform: rotate(180deg);" : ""}
    width: 11px;
    height: 8px;
    position: absolute;
    inset-inline-end: 16px;
    top: calc(50% - 4px);
    display: ${p.$active ? "block" : "none"};
  }
  `
      : ""}

  &:hover {
    background: var(--colors-activitiCellScheduleItemHover);
    .remove-button {
      display: block;
    }
    &:after {
      display: block;
    }
  }
`;

const RemoveButton = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  inset-inline-end: 8px;
  top: 6px;
  z-index: 1;
  display: none;
  width: 24px;
  height: 24px;
  border-radius: var(--shapes-border-radius-default);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%23A1B2CF' d='M4.667 11a.666.666 0 0 0 .666-.667v-4a.667.667 0 1 0-1.333 0v4a.667.667 0 0 0 .667.667Zm6.666-8H8.667v-.667a2 2 0 0 0-2-2H5.333a2 2 0 0 0-2 2V3H.667a.667.667 0 0 0 0 1.333h.666v7.334a2 2 0 0 0 2 2h5.334a2 2 0 0 0 2-2V4.333h.666a.667.667 0 1 0 0-1.333Zm-6.666-.667a.667.667 0 0 1 .666-.666h1.334a.667.667 0 0 1 .666.666V3H4.667v-.667Zm4.666 9.334a.667.667 0 0 1-.666.666H3.333a.667.667 0 0 1-.666-.666V4.333h6.666v7.334Zm-2-.667A.666.666 0 0 0 8 10.333v-4a.667.667 0 0 0-1.333 0v4a.667.667 0 0 0 .666.667Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-color: var(--colors-surface-100);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%23434E6C' d='M4.667 11a.666.666 0 0 0 .666-.667v-4a.667.667 0 1 0-1.333 0v4a.667.667 0 0 0 .667.667Zm6.666-8H8.667v-.667a2 2 0 0 0-2-2H5.333a2 2 0 0 0-2 2V3H.667a.667.667 0 0 0 0 1.333h.666v7.334a2 2 0 0 0 2 2h5.334a2 2 0 0 0 2-2V4.333h.666a.667.667 0 1 0 0-1.333Zm-6.666-.667a.667.667 0 0 1 .666-.666h1.334a.667.667 0 0 1 .666.666V3H4.667v-.667Zm4.666 9.334a.667.667 0 0 1-.666.666H3.333a.667.667 0 0 1-.666-.666V4.333h6.666v7.334Zm-2-.667A.666.666 0 0 0 8 10.333v-4a.667.667 0 0 0-1.333 0v4a.667.667 0 0 0 .666.667Z'/%3E%3C/svg%3E");
  }
`;

interface RequestSettingsProps extends WithTranslation {
  setOnSaveFn?: Dispatch<SetStateAction<{ fn: (() => Promise<boolean>) | null; disabled: boolean }>>;
}

interface RequestSettingsState {
  loading: boolean;
  requestTypes: RequestType[];
  selectedRequestType: RequestType | null;
  selectedRequestSubType: RequestSubType | null;
  togglingRequestType: RequestType | null;
  deletingRequestType: RequestType | null;
}

class RequestSettings extends Component<RequestSettingsProps, RequestSettingsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  requestTimeout = null;

  state: RequestSettingsState = {
    loading: true,
    requestTypes: [],
    selectedRequestType: null,
    selectedRequestSubType: null,
    togglingRequestType: null,
    deletingRequestType: null,
  };

  componentDidMount() {
    void this.getData();
  }

  getData = async (force?: boolean) => {
    const requestTypes = (await this.context.getRequestTypes({ force })) as RequestType[];

    const { selectedRequestType, selectedRequestSubType } = this.state;
    const selectedRequestTypeNew = requestTypes.find((rt) => rt.uuid === selectedRequestType?.uuid) || null;

    const selectedRequestSubTypeNew =
      (selectedRequestTypeNew &&
        selectedRequestTypeNew.subtypes.find((rt) => rt.uuid === selectedRequestSubType?.uuid)) ||
      null;

    this.setState({
      loading: false,
      requestTypes,
      selectedRequestType: selectedRequestTypeNew,
      selectedRequestSubType: selectedRequestSubTypeNew,
      deletingRequestType: null,
    });
  };

  onRequestTypeClick = (val: RequestType) => {
    const selectedRequestType = val;
    let selectedRequestSubType = null;

    if ([RequestTypeName.vacation, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(val.name)) {
      [selectedRequestSubType] = val.subtypes;
    }

    this.setState({
      selectedRequestType,
      selectedRequestSubType,
    });
  };

  requestTypeToggle = async (requestType: RequestType) => {
    await requestTypeToggle({
      action: requestType.active ? "deactivate" : "activate",
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
      companyUUID: window.global_store.company.uuid,
      requestType: requestType.name,
    });

    this.setState({ togglingRequestType: null }, () => {
      void this.getData(true);
    });
  };

  addRequestType = async (requestTypeName: string) => {
    await requestTypeCreate({
      companyUuid: window.global_store.company.uuid,
      body: {
        content: {
          createdBy: window.global_store.profile.uuid,
          name: requestTypeName,
        },
      },
    });

    void this.getData(true);
  };

  removeRequestType = async (requestTypeUuid: string) => {
    await requestTypeDelete({
      companyUuid: window.global_store.company.uuid,
      requestTypeUuid,
      body: {
        content: {
          deletedBy: window.global_store.profile.uuid,
        },
      },
    });

    void this.getData(true);
  };

  getRightPanelContent = () => {
    const { selectedRequestType, selectedRequestSubType, requestTypes } = this.state;

    const { t, setOnSaveFn } = this.props;

    if (!selectedRequestType) {
      return <DefaultPage />;
    }

    if (selectedRequestType && !selectedRequestSubType) {
      return (
        <Column>
          <ColumnTitle>{t("Category")}</ColumnTitle>
          {selectedRequestType.subtypes
            .sort((a, b) => {
              const aRequestSubtypeName = a.translationKey ? t(a.translationKey) : a.name;
              const bRequestSubtypeName = b.translationKey ? t(b.translationKey) : b.name;
              return aRequestSubtypeName.localeCompare(bRequestSubtypeName);
            })
            .map((subtype) => (
              <>
                <Tooltip id={subtype.uuid} offset={{ top: -8 }}>
                  {subtype.translationKey ? t(subtype.translationKey) : subtype.name}
                </Tooltip>
                <Item
                  key={subtype.uuid}
                  data-tip
                  data-for={subtype.uuid}
                  onClick={() => {
                    this.setState({ selectedRequestSubType: subtype });
                  }}
                >
                  {subtype.translationKey ? t(subtype.translationKey) : subtype.name}
                </Item>
              </>
            ))}

          {![
            RequestTypeName.vacation,
            RequestTypeName.overtime,
            RequestTypeName.hbConvert,
            RequestTypeName.scheduleAssignment,
          ].includes(selectedRequestType.name) && (
            <NewCategory
              key="new"
              allCategories={requestTypes}
              typeName={selectedRequestType.name}
              onCategoryAdded={() => {
                void this.getData(true);
              }}
            >
              {t("Add Category")}
            </NewCategory>
          )}
        </Column>
      );
    }

    if (selectedRequestSubType) {
      return (
        <Column key={selectedRequestSubType.uuid}>
          <ColumnTitle $noPadding>
            {(![
              RequestTypeName.vacation,
              RequestTypeName.hbConvert,
              RequestTypeName.overtime,
              RequestTypeName.scheduleAssignment,
            ].includes(selectedRequestType.name) ||
              selectedRequestType.name === RequestTypeName.overtime) && (
              <BackButton onClick={() => this.setState({ selectedRequestSubType: null })} />
            )}
            <RequestSettingsEditableTitleControl
              onChange={(value: string) =>
                this.setState({
                  selectedRequestSubType: {
                    ...selectedRequestSubType,
                    name: value,
                  },
                })
              }
              value={
                selectedRequestSubType.translationKey
                  ? t(selectedRequestSubType.translationKey)
                  : selectedRequestSubType.name
              }
              editable={!selectedRequestSubType.translationKey && !selectedRequestSubType.externalId}
            />
          </ColumnTitle>

          <RequestSettingsSubPage
            setOnSaveFn={setOnSaveFn}
            onChange={() => {
              showSnackbar({
                text: t("Category was saved"),
                notificationStyle: "notice",
              });
              void this.getData(true);
            }}
            refetchData={() => this.getData(true)}
            requestSubType={selectedRequestSubType}
          />
        </Column>
      );
    }

    return null;
  };

  render() {
    const { loading, requestTypes, selectedRequestType, togglingRequestType, deletingRequestType } = this.state;
    const { t } = this.props;

    if (loading) {
      return <div>{t(`${TranslationNamespaces.common}|default-loading-text`)}</div>;
    }

    return (
      <PageWrapper>
        <PageContent>
          <ColumnsWrapper>
            <Sidebar>
              {requestTypes.map((requestType) => (
                <ListItemCategory
                  key={requestType.uuid}
                  checked={requestType.active}
                  canDelete={!requestType.requestTypeTemplateUuid}
                  onDelete={async (ev) => {
                    ev.stopPropagation();
                    this.setState({ deletingRequestType: requestType });
                  }}
                  onChange={() => {
                    this.setState({
                      togglingRequestType: requestType,
                    });
                  }}
                  onClick={(ev) => {
                    if (!ev.target || (ev.target as HTMLInputElement).tagName !== "INPUT") {
                      this.onRequestTypeClick(requestType);
                    }
                  }}
                  selected={selectedRequestType?.uuid === requestType.uuid}
                  label={
                    <>
                      <BasicTooltip id={requestType.uuid} offset={{ top: -8 }} wrapperStyle={{ zIndex: 1000 }}>
                        {requestType.requestTypeTemplateUuid ? t(requestType.name) : requestType.name}
                      </BasicTooltip>
                      <span className="checkbox-label" data-tip data-for={requestType.uuid}>
                        {requestType.requestTypeTemplateUuid ? t(requestType.name) : requestType.name}
                      </span>
                    </>
                  }
                />
              ))}
              <NewRequestCategory
                label={t("Create new category")}
                applyRegex={/[^A-Za-z0-9]/g} // only letters and numbers
                onApply={async (val) => {
                  try {
                    await this.addRequestType(val.value);
                    val.callBackSuccess();
                  } catch (error) {
                    if (error?.message) {
                      showSnackbar({
                        text: t(error.message as string),
                        notificationStyle: "notice",
                      });
                    } else {
                      console.error(error);
                    }
                    val.callBackFail();
                  }
                }}
              />
              <BasicTooltip id="tooltip-for-delete-request-type" delayShow={0} wrapperStyle={{ zIndex: 1000 }}>
                <span>{t(`${TranslationNamespaces.common}|Delete`)}</span>
              </BasicTooltip>
            </Sidebar>

            {this.getRightPanelContent()}
          </ColumnsWrapper>
        </PageContent>

        {!!togglingRequestType && (
          <ModalDialog isOpen={!!togglingRequestType} onClose={() => this.setState({ togglingRequestType: null })}>
            <Lightbox
              title={togglingRequestType.active ? t("Deactivate request type") : t("Activate request type")}
              text={
                togglingRequestType.active
                  ? t("Are you sure you want to disable this request type?")
                  : t("Are you sure you want to enable this request type?")
              }
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({ togglingRequestType: null });
              }}
              onYes={() => {
                void this.requestTypeToggle(togglingRequestType as RequestType);
              }}
            />
          </ModalDialog>
        )}
        {!!deletingRequestType && (
          <ModalDialog isOpen={!!deletingRequestType} onClose={() => this.setState({ togglingRequestType: null })}>
            <Lightbox
              title={t("delete-request-type-confirmation-title")}
              text={t("delete-request-type-confirmation-text")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ deletingRequestType: null });
              }}
              onYes={async () => {
                try {
                  await this.removeRequestType(deletingRequestType.uuid);
                } catch (error) {
                  if (error?.message) {
                    showSnackbar({
                      text: error.message as string,
                      notificationStyle: "notice",
                    });
                  } else {
                    console.error(error);
                  }
                  this.setState({ deletingRequestType: null });
                }
              }}
            />
          </ModalDialog>
        )}
      </PageWrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp])(RequestSettings);
