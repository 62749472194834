import Button, { ButtonState } from "components/controls/StyledButton";
import { CSSProperties } from "react";
import styled from "styled-components";
import * as images from "../svg-images";

const Wrapper = styled.div`
  button {
    padding: 0 16px;
  }
`;

const IconWrapper = styled.div`
  margin-inline-end: 13px;
  display: flex;
`;

interface HeaderActionButtonProps {
  title: string;
  onClick: () => void;
  icon?: JSX.Element;
  state?: ButtonState;
  style?: CSSProperties;
  className?: string;
}

const HeaderActionButton = ({
  title,
  onClick,
  icon,
  state,
  style = {},
  className = "",
}: HeaderActionButtonProps): JSX.Element => (
  <Wrapper style={style} className={className}>
    <Button
      state={state || ButtonState.secondary}
      value={
        <>
          <IconWrapper>{icon || images.plusBigBlue}</IconWrapper> {title}
        </>
      }
      onClick={onClick}
    />
  </Wrapper>
);

export default HeaderActionButton;
