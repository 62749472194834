import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import BEM from "utils/BEM";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import Chip from "components/UI/Chip";
import Button from "components/UI/Button";
import { Location, WifiValidation } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { iColumn } from "components/TableCommon";
import { iCellInfo } from "utils/tableHelpers";
import { editWiFiValidation, getWiFiValidations } from "utils/apiHelpers";
import "../Validations.scss";

const b = BEM.b("locations-validations");

interface ValidationsWiFiProps extends WithTranslation {
  currentLocation: Location;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

interface ValidationsWiFiState {
  wifiValidations: WifiValidation[];
}

class ValidationsWiFi extends Component<ValidationsWiFiProps, ValidationsWiFiState> {
  readonly state = {
    wifiValidations: [],
  };

  async componentDidMount() {
    const { currentLocation } = this.props;

    const { wifi_points } = await getWiFiValidations(currentLocation.id);

    this.setState({ wifiValidations: wifi_points });
  }

  editWiFiValidation = async (validationId: number, wifi_point: Partial<WifiValidation>) => {
    const { t, setNotification, onYes } = this.props;

    try {
      await editWiFiValidation(validationId, { body: { wifi_point } });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  render() {
    const { wifiValidations } = this.state;
    const { t } = this.props;

    const columns: iColumn<WifiValidation>[] = [
      {
        label: t("ID"),
        accessor: "id",
        maxWidth: 120,
      },
      {
        label: t("common|Name"),
        accessor: "title",
      },
      {
        label: t("SSID"),
        accessor: "ssid",
      },
      {
        label: t("BSSID"),
        accessor: "bssid_ids",
      },
      {
        label: t("Added On"),
        accessor: "created_at",
        Cell: ({ value }: iCellInfo<WifiValidation>) => moment(value).format("DD/MM/YYYY"),
        maxWidth: 200,
      },
      {
        label: t("Status"),
        accessor: "active",
        Cell: ({ value, original }: iCellInfo<WifiValidation>) => (
          <div>
            <div className={b("item-status")}>{value && <Chip intent="success">{t("Active")}</Chip>}</div>
            <div className={b("item-actions")}>
              <Button
                className={b("item-action-button")}
                onClick={() =>
                  this.editWiFiValidation(original.id, {
                    active: !original.active,
                  })
                }
              >
                {original.active ? t("Disable") : t("Enable")}
              </Button>
              <Button
                className={b("item-action-button", ["remove"])}
                onClick={() =>
                  this.editWiFiValidation(original.id, {
                    deleted_at: new Date().toISOString(),
                  })
                }
              />
            </div>
          </div>
        ),
        align: "center",
        maxWidth: 200,
      },
    ];

    return (
      <div className={b()}>
        <h3 className={b("list-header")}>{t("Wi-Fi")}</h3>
        <TablePage<WifiValidation>
          rows={wifiValidations}
          columns={columns}
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          className="departments-table"
          noContentComponent={<NoContent>{t("This location has no WiFi validations")}</NoContent>}
        />
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(ValidationsWiFi);
