import { translateEmployeeTerm } from "utils/translationHelpers";
import noReports from "img/no-reports.png";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import NoContent from "components/NoContent";

const ReportsNoContent = () => {
  const { t } = useTranslation(TranslationNamespaces.reportsPage);

  return (
    <NoContent img={noReports}>
      {translateEmployeeTerm(
        t,
        TranslationNamespaces.reportsPage,
        "custom-select-employee-to-view-report",
        `Please select an employee to view a report.`,
      )}
    </NoContent>
  );
};

export default ReportsNoContent;
