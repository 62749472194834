import { Component } from "react";
import { withTranslation } from "react-i18next";
import { getClient, updateClient } from "utils/apiHelpers";
import SettingsLayout from "components/Layout/SettingsLayout";
import NotificationRow from "components/NotificationRow";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { getTitle, validatePhoneNumber } from "utils/common";
import { PageWrapper } from "components/styled/Page";
import SearchControl from "components/UI/SearchControlNew";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControl";
import { ErrorLabel } from "components/UI/TextLabels";
import PhoneNumberField from "components/PhoneNumberField";
import Button from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";

const IdNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Spacer = styled.div`
  width: 16px;
`;
const Content = styled.div`
  width: 100%;
  max-width: 366px;
`;
const RowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-top: 32px;
  margin-bottom: 4px;
`;
const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;
class ClientsPage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      notification: "",
      notificationType: "success",
      confirmationPopupVisible: false,
      isFetching: false,
      id: "",
      name: "",
      contact_person: "",
    };
    document.title = getTitle(t("Client"));
  }
  componentDidMount() {
    this.getState(this.props);
  }

  getState = async (props) => {
    const uuid = props?.match?.params?.uuid;
    if (uuid && !this.state.isFetching) {
      this.setState({ isFetching: true });
      const response = await getClient({ uuid });
      const client = response.client || response || {};

      this.setState({
        ...client,
        manager: client.managers && client.managers.length ? client.managers[0].uuid : null,
        managerName: client.managers && client.managers.length ? client.managers[0].name : "",
        isFetching: false,
        confirmationPopupVisible: false,
      });
    }
  };

  saveItem = async (item) => {
    const { t } = this.props;
    try {
      await updateClient({
        id: item.id,
        body: {
          client: item,
        },
      });
      this.setState({ notification: `${t("You saved")} ${item.name}`, notificationType: "success", loading: false });
    } catch (error) {
      this.setState({
        notification: t(`${TranslationNamespaces.common}|Something went wrong`),
        notificationType: "error",
        loading: false,
      });
      console.log(error);
    }
  };

  onSubmit = () => {
    const { t } = this.props;
    const { id, phone_number, name, contact_person, email, manager, confirmOnSave } = this.state;
    if (confirmOnSave) {
      this.setState({ confirmationPopupVisible: true });
      return;
    }
    let errors = {};
    const phoneError = validatePhoneNumber(phone_number);

    if (!name) {
      errors.name = t("Client name can't be empty");
    } else if (phoneError && phoneError !== "Phone can't be empty") {
      errors.phone_number = t(phoneError);
    } else if (this.getEmailError(email)) {
      errors.email = t("Email is invalid");
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
      this.setState({ errors, loading: true });
      const obj = {};

      obj.id = id;
      obj.name = name;
      obj.contact_person = contact_person;
      obj.phone_number = phone_number;
      obj.email = email;
      obj.manager_ids = manager ? [manager] : [];
      this.saveItem(obj);
    }
    this.setState({ errors });
  };

  getEmailError(email) {
    const { t } = this.props;
    let error = "";
    const value = email;
    const validate = (str) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };
    if (!value) {
      error = "";
    } else if (!validate(value)) {
      error = t("Email is incorrect");
    } else {
      error = "";
    }
    return error;
  }

  render() {
    const {
      confirmationPopupVisible,
      notificationType,
      notification,
      loading,
      isFetching,
      id,
      name,
      email,
      errors,
      contact_person,
      phone_number = "",
      managerName,
    } = this.state;
    const { t, history } = this.props;

    return (
      <SettingsLayout
        title={name || t("Clients")}
        backButtonTitle={t("Clients")}
        backButtonOnclick={() => history.push("/company/clients")}
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}
          <br />
          <Content>
            <IdNameWrapper>
              <FieldWrapper fieldName="ID" wrapperWidth="60px" width="100%">
                <TextInputControl value={id} disabled onChange={(value) => this.setState({ id: value })} />
              </FieldWrapper>
              <Spacer />
              <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Name`)} wrapperWidth="100%" width="100%">
                <TextInputControl
                  value={name}
                  error={errors && errors.name}
                  onChange={(value) => this.setState({ name: value })}
                />
              </FieldWrapper>
            </IdNameWrapper>
            {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}

            <FieldWrapper fieldName={t("Phone Number")} width="100%">
              <PhoneNumberField
                newField
                value={phone_number}
                isValid={!errors?.phone_number}
                onPhoneChange={(val, country) => {
                  this.setState({
                    phone_number: val === country.dialCode ? "" : val,
                  });
                }}
              />
            </FieldWrapper>

            {errors?.phone_number && <ErrorLabel>{errors.phone_number}</ErrorLabel>}
            <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Email`)} width="100%">
              <TextInputControl
                type="email"
                name="email"
                value={email}
                error={errors?.email}
                onChange={(val) => {
                  this.setState({
                    email: val,
                  });
                }}
              />
            </FieldWrapper>
            {errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}

            <FieldWrapper fieldName={t("Contact Name")} width="100%">
              <TextInputControl
                size={60}
                value={contact_person}
                onChange={(val) => {
                  this.setState({
                    contact_person: val,
                  });
                }}
              />
            </FieldWrapper>
            <RowTitle>{t("Client Manager")}</RowTitle>
            <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Employee`)} width="100%">
              <SearchControl
                onChange={(searchObj) => {
                  this.setState({
                    manager: searchObj.uuid,
                    managerName: searchObj.value,
                    confirmOnSave: !searchObj?.employee?.permissionRoles?.filter((pr) => pr.name === "ClientManager")
                      .length,
                  });
                }}
                skipEmployer
                onlyActive
                value={managerName}
                onClear={() => this.setState({ manager: null, confirmOnSave: false, managerName: "" })}
                placeholder={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-search-employees",
                  `${TranslationNamespaces.common}|Search Employees`,
                )}
                permissionSection={PermissionSectionName.clients}
              />
            </FieldWrapper>
          </Content>
          <ButtonWrapper>
            <Button
              loading={loading || isFetching}
              value={t(`${TranslationNamespaces.common}|Save`)}
              state="primary"
              onClick={this.onSubmit}
            />
          </ButtonWrapper>

          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("client-manager-confirmation")}
              text={t("client-manager-confirmation-text")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Yes`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ confirmationPopupVisible: false });
              }}
              onYes={() => {
                this.setState({ confirmOnSave: false, confirmationPopupVisible: false }, this.onSubmit);
              }}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}
export default withTranslation(TranslationNamespaces.clients)(ClientsPage);
