import { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { WithTranslation } from "react-i18next";
import { fireEvent, getDateWithTZ } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import { requestsBulkChangeStatus } from "utils/apiHelpers";
import PunchesAddPopup from "components/Punches/PunchesAddPopup";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import RequestDetails from "components/Requests/RequestDetailsNew";
import { SuperpunchCustomEvents, SuperpunchOpenRequestsEventData } from "context/SuperpunchProvider/types";
import { AddPunchPunch, PunchKey, PunchType } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { DailySummaryRequest, RequestApprovalFlowStatus } from "types/models/request";
import { RequestBulkChangeStatusRequestData } from "utils/api/types";
import { DailySummaryEmployeeInfo } from "types/models/superpunch";
import { LocationDropdownOption } from "components/LocationsDropdown";
import { getRequestTypeName } from "components/Requests/requestUtils";
import { generateEventKey } from "context/SuperpunchProvider/helpers";
import PunchDetails from "../Punches/PunchDetails";
import RequestsList from "./RequestsList";

const RequestDetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface SuperpunchOverlayProps {
  t: WithTranslation["t"];
}

interface SuperpunchOverlayState {
  header: string;
  selectedRequest: (DailySummaryRequest & { employee: DailySummaryEmployeeInfo }) | null;
  selectedDate: moment.Moment | null;
  employeeInfo: unknown | null; // todo

  punchDetailsDialog: boolean;
  punch: unknown | null; // todo
  onPunchDetailsApprove: (() => void) | null;
  onPunchDetailsValidate: (() => void) | null;
  onPunchDetailsDecline: ((declineReason: unknown) => void) | null; // todo

  newPunchDialog: boolean;
  selectedType: PunchType | null;
  selectedEventKey: PunchKey | null;
  /** WIP PROD-12360 */
  selectedLocation: LocationDropdownOption<string> | null;

  requestsDialog: boolean;
  selectedDayRequests: DailySummaryRequest[] | null;
}

class SuperPunchOverlay extends Component<SuperpunchOverlayProps, SuperpunchOverlayState> {
  readonly state: Readonly<SuperpunchOverlayState> = {
    header: "",
    selectedRequest: null,
    selectedDate: null,
    employeeInfo: null,
    // ===
    punchDetailsDialog: false,
    punch: null,
    onPunchDetailsApprove: null,
    onPunchDetailsValidate: null,
    onPunchDetailsDecline: null,
    // ===
    newPunchDialog: false,
    selectedType: null,
    selectedEventKey: null,
    selectedLocation: null,
    // ===
    requestsDialog: false,
    selectedDayRequests: null,
  };

  UNSAFE_componentWillMount() {
    document.addEventListener(SuperpunchCustomEvents.openNewPunchOverlay, this.handleOpenNewPunch);
    document.addEventListener(SuperpunchCustomEvents.openPunchDetails, this.handleOpenPunchDetails);
    document.addEventListener(SuperpunchCustomEvents.openRequests, this.handleOpenRequests);
    document.addEventListener(SuperpunchCustomEvents.closeOverlay, this.handleCloseOverlay);
  }

  componentWillUnmount() {
    document.removeEventListener(SuperpunchCustomEvents.openNewPunchOverlay, this.handleOpenNewPunch);
    document.removeEventListener(SuperpunchCustomEvents.openPunchDetails, this.handleOpenPunchDetails);
    document.removeEventListener(SuperpunchCustomEvents.openRequests, this.handleOpenRequests);
    document.removeEventListener(SuperpunchCustomEvents.closeOverlay, this.handleCloseOverlay);
  }

  handleOpenPunchDetails = (ev) => {
    const { punch, onPunchDetailsApprove, onPunchDetailsValidate, onPunchDetailsDecline } = ev.detail;
    const { t } = this.props;

    this.setState({
      punchDetailsDialog: true,
      header: t("Punch Details"),
      punch,
      onPunchDetailsApprove,
      onPunchDetailsValidate,
      onPunchDetailsDecline,
    });
  };

  handleOpenNewPunch = (ev) => {
    const { selectedDate, selectedType, selectedEventKey, employeeInfo, selectedLocation } = ev.detail;
    const { t } = this.props;

    this.setState({
      newPunchDialog: true,
      header: t("Add a Punch"),
      employeeInfo,
      selectedDate,
      selectedType,
      selectedEventKey,
      selectedLocation,
    });
  };

  handleCloseOverlay = () => {
    this.setState({
      newPunchDialog: false,
      punchDetailsDialog: false,
      punch: null,
      requestsDialog: false,
      selectedDate: null,
      selectedType: null,
      selectedEventKey: null,
      header: "",
      selectedRequest: null,
    });
  };

  handleOpenRequests = (ev: CustomEvent<SuperpunchOpenRequestsEventData>) => {
    const { selectedDate, selectedDayRequests, employeeInfo } = ev.detail;
    const { t } = this.props;

    this.setState({
      requestsDialog: true,
      selectedDate,
      selectedDayRequests,
      employeeInfo,
      header: t("Requests from ") + selectedDate.format("DD/MM/YYYY"),
    });
  };

  onAddPunch = (punch: AddPunchPunch, fullName = "", event: AddPunchMappedEvent["event"], employeeUuid: string) => {
    fireEvent(SuperpunchCustomEvents.addPunch, {
      punch,
      fullName,
      event,
      employeeUuid,
    });
  };

  selectRequest = (request: DailySummaryRequest & { employee: DailySummaryEmployeeInfo }) => {
    this.setState({ selectedRequest: request });
  };

  onChangeRequestStatus = async ({
    request,
    status,
    declineReason,
  }: {
    request: DailySummaryRequest & { employee: DailySummaryEmployeeInfo };
    status: Exclude<RequestApprovalFlowStatus, RequestApprovalFlowStatus.pending>;
    declineReason: string;
  }) => {
    const requestUuid = request.uuid;
    const body: RequestBulkChangeStatusRequestData["body"] = {
      content: {
        approved: [],
        declined: [],
        ignored: [],
        updatedBy: window.global_store.profile.uuid,
      },
    };

    if (status === RequestApprovalFlowStatus.declined) {
      body.content[status] = [{ uuid: requestUuid, reason: declineReason || "" }];
    } else {
      body.content[status] = [requestUuid];
    }

    try {
      await requestsBulkChangeStatus({
        companyUUID: window.global_store.company.uuid,
        body,
        params: {
          from: getDateWithTZ(request.startTime, request.timezone).format("YYYY-MM-DD"),
          to: getDateWithTZ(request.endTime, request.timezone).format("YYYY-MM-DD"),
          user_profile_uuid: request.employee.uuid,
        },
      });
    } catch (error) {
      sentryUtils.sendError(error);
    }

    let startDate = getDateWithTZ(request.startTime, request.timezone).clone();

    while (startDate.isSameOrBefore(getDateWithTZ(request.endTime, request.timezone), "day")) {
      fireEvent(generateEventKey(SuperpunchCustomEvents.workedHours, startDate.format("YYYYMMDD")), {
        isUpdating: true,
      });
      startDate = startDate.add(1, "day");
    }

    fireEvent(SuperpunchCustomEvents.updateData);

    this.handleCloseOverlay();
  };

  render() {
    const { t } = this.props;
    const {
      newPunchDialog,
      requestsDialog,
      employeeInfo,
      selectedType,
      selectedEventKey,
      selectedDate,
      selectedDayRequests,
      header,
      selectedRequest,
      punchDetailsDialog,
      punch,
      selectedLocation,
    } = this.state;

    return (
      <SidePopupOverlay
        isOpen={newPunchDialog || requestsDialog || punchDetailsDialog}
        header={selectedRequest ? getRequestTypeName(selectedRequest, t) : header}
        headerStyle={punch?.status || ""}
        contentOverflow
        onBack={() => {
          if (selectedRequest) {
            this.setState({
              selectedRequest: null,
              header: t("Requests from ") + selectedDate?.format?.("DD/MM/YYYY"),
            });
          }
        }}
        onClose={this.handleCloseOverlay}
      >
        {newPunchDialog && (
          <PunchesAddPopup
            superPunch
            location={selectedLocation} // WIP PROD-12360
            onClose={this.handleCloseOverlay}
            onYes={this.onAddPunch}
            employeeInfo={employeeInfo}
            punchTypeToSelect={selectedType || undefined}
            eventKeyToSelect={selectedEventKey || undefined}
            punchDate={selectedDate}
          />
        )}

        {punchDetailsDialog && (
          <PunchDetails
            punchId={punch?.id || null}
            employeeUuid={punch?.employee?.uuid || null}
            spApprovePunch={() => {
              const { onPunchDetailsApprove } = this.state;

              if (onPunchDetailsApprove) {
                onPunchDetailsApprove();
              }

              this.setState({
                onPunchDetailsApprove: null,
                onPunchDetailsValidate: null,
                onPunchDetailsDecline: null,
                punchDetailsDialog: false,
                punch: null,
              });
            }}
            spValidatePunch={() => {
              const { onPunchDetailsValidate } = this.state;
              if (onPunchDetailsValidate) {
                onPunchDetailsValidate();
              }

              this.setState({
                onPunchDetailsApprove: null,
                onPunchDetailsValidate: null,
                onPunchDetailsDecline: null,
                punchDetailsDialog: false,
                punch: null,
              });
            }}
            spDeclinePunch={(declineReason) => {
              const { onPunchDetailsDecline } = this.state;
              if (onPunchDetailsDecline) {
                onPunchDetailsDecline(declineReason);
              }

              this.setState({
                onPunchDetailsApprove: null,
                onPunchDetailsValidate: null,
                onPunchDetailsDecline: null,
                punchDetailsDialog: false,
                punch: null,
              });
            }}
          />
        )}

        {requestsDialog && selectedRequest && (
          <RequestDetailsWrapper>
            <RequestDetails request={selectedRequest} onStatusChanged={this.onChangeRequestStatus} />
          </RequestDetailsWrapper>
        )}

        {requestsDialog && !selectedRequest && (
          <RequestsList
            t={t}
            requests={selectedDayRequests}
            onSelectRequest={this.selectRequest}
            employeeInfo={employeeInfo}
          />
        )}
      </SidePopupOverlay>
    );
  }
}

export default SuperPunchOverlay;
