import { UserProfile } from "./userProfile";

enum EmployeeExistsCheckResult {
  inUse = "in_use",
  notInUse = "not_in_use",
}

type EmployeeTeamTableData = {
  employee: {
    fullName: UserProfile["full_name"];
    avatarId: string;
    role: UserProfile["role"];
    position: UserProfile["position"];
    id: UserProfile["id"];
    team: UserProfile["team"];
  };
  supervisorName: string;
  phone: string;
  email: UserProfile["email"];
  cpf: UserProfile["cpf"];
  options: UserProfile["id"];
  id: UserProfile["id"];
};

type PunchDetailsEmployee = {
  id: number;
  uuid: string;
  name: string;
  avatar_id: string | null;
  avatar_url: string | null;
  position_id: number | null;
  job_description: string;
  last_lock_date: string;
  project_employee_cost: string;
  company_name: string;
  cpf: string | null;
};

export type { EmployeeTeamTableData, PunchDetailsEmployee };

export { EmployeeExistsCheckResult };
