import { Component, ContextType, MouseEvent, PropsWithChildren, ReactNode, Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import { getSystemName } from "utils/common";
import { UserProfileRole } from "types/models/userProfile";
import Snackbar from "components/styled/Snackbar";
import DownloadSnacks from "components/styled/DownloadSnacks";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SuperAdminPanel from "../SuperAdminPanel";
import { InitialDiscountNotification } from "../Billing/components/InitialDiscountNotification";
import NavigationMenu from "./NavigationMenu";
import TopRowNew from "./TopRowNew";
import HeaderAction from "./HeaderAction";
import TrialBar from "./TrialBar";

const Layout = styled.div`
  width: 100%;
`;

const MainPanel = styled.div`
  position: relative;
  top: 0px;
  bottom: 0;
  inset-inline-start: 0px;
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  justify-content: flex-start;

  scroll-margin-top: 56px;

  .navigation-menu {
    flex-shrink: 0;
  }
`;

const PageContent = styled.div<{ $minimal?: boolean }>`
  overflow: overlay;
  width: 100%;
  height: calc(100vh - 56px); // header height
  max-width: 100%;

  &
    > div:not(.page-controls):not([class^="InitialDiscountNotification"]):not(.project-details):not(.employee-details-page):not(.subscription-lock-page) {
    padding: 0 24px;
  }

  & > div[class^="InitialDiscountNotification"] {
    margin: 16px;
    width: calc(100% - 32px);
  }
`;

interface FullPageProps extends PropsWithChildren, RouteComponentProps, WithTranslation {
  minimal?: boolean;
  headerAction?: JSX.Element | null;
  title?: ReactNode;
  backButtonTitle?: string;
  backButtonOnclick?: (e: MouseEvent) => void;
  className?: string;
}

class FullPage extends Component<FullPageProps> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: FullPageProps) {
    super(props);

    const { t } = props;
    document.title = `${getSystemName()} - ${t("Admin control panel")}`;
  }

  async componentDidMount() {
    const company = await this.context.getCompany();

    if (company) {
      window.global_store.company = company;
    }
  }

  initEmbed = () => {
    window.parent.postMessage(
      {
        action: "pageLoaded",
      },
      "*",
    );

    window.addEventListener("message", (event) => {
      if (event.data?.action === "navigate" && event.data?.url) {
        this.props.history.push(event.data?.url);
      }
    });
  };

  render() {
    const { minimal, children, headerAction, title, backButtonTitle, backButtonOnclick, className } = this.props;
    const { profile, beta, embedded } = window.global_store;
    const isAdmin = profile.role === UserProfileRole.employer;

    return (
      <Layout className="full-page">
        {!embedded && <TopRowNew minimal={minimal} />}
        <MainPanel className="main-panel">
          {!embedded && !minimal && <NavigationMenu />}

          <Suspense fallback={<div />}>
            <PageContent $minimal={minimal} className="page-content custom-scroll-on-hover">
              {!minimal && <TrialBar />}
              {!minimal && <InitialDiscountNotification />}
              <HeaderAction backButtonOnclick={backButtonOnclick} backButtonTitle={backButtonTitle} title={title}>
                {headerAction}
              </HeaderAction>

              <div className={`page-content-children${className ? ` ${className}` : ""}`}>{children}</div>
            </PageContent>
          </Suspense>
        </MainPanel>

        <div id="containerForPopup" />

        {isAdmin && beta && <SuperAdminPanel />}
        <Snackbar />
        <DownloadSnacks />
      </Layout>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.fullPage)(FullPage));
