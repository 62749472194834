import React, { useState, useCallback, forwardRef, ForwardedRef } from "react";
import { searchLens, searchClear } from "components/svg-images";
import { IconSearchLens } from "components/views/icons";
import { stylesheet } from "astroturf";
import cx from "classnames";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding: 8px;
  background: var(--colors-surface-50);
  border-radius: 8px;

  .IconLens {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Input {
    width: 100%;
    padding: 0;
    font-size: 15px;
    line-height: 100%;
    font-weight: var(--typography-font-weight-default);
    color: var(--colors-surface-900-p);
    background: none;
    border: none;
    outline: none;

    &::placeholder {
      color: var(--colors-activitiInfoBlockValue);
      opacity: 0.7;
    }
  }

  .IconClear {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
`;

export type SearchInputProps = React.HTMLProps<HTMLInputElement>;
export const SearchInput = forwardRef((props: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { onChange: onChangeProp, value: valueProp, autoFocus, className, ...restProps } = props;
  const [value, setValue] = useState(valueProp || "");

  const onChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChangeProp && onChangeProp(evt);
      setValue(evt.target.value);
    },
    [onChangeProp],
  );

  const clear = useCallback(() => {
    setValue("");
    onChangeProp && onChangeProp({ target: { value: "" } }); // TODO: come up with a better way
  }, [onChangeProp]);

  return (
    <div className={cx(styles.Wrapper, className)}>
      <div className={styles.IconLens}>{searchLens}</div>
      <input
        ref={ref}
        className={styles.Input}
        onChange={onChange}
        value={value}
        autoFocus={autoFocus}
        {...restProps}
      />
      {value !== "" && (
        <div className={styles.IconClear} onClick={clear}>
          {searchClear}
        </div>
      )}
    </div>
  );
});
