import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import BEM from "utils/BEM";
import { getRequestsList, requestsBulkChangeStatus } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { getDateWithTZ } from "utils/common";
import { getRequestDate } from "utils/requestsHelpers";
import { RequestBulkChangeStatusRequestData } from "utils/api/types";
import { TranslationNamespaces } from "types/translationNamespaces";
import { EmployeeRequestListTableData, Request, RequestApprovalFlowStatus } from "types/models/request";
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import RequestDetails from "components/Requests/RequestDetailsNew";
import TablePage from "components/TablePage";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { iColumn } from "components/TableCommon";
import "components/UI/Page/Page.scss";
import "styles/table-common.scss";
import { getSelectedColumns } from "utils/tableHelpers";
import { getRequestTypeName } from "components/Requests/requestUtils";

const p = BEM.b("page");
const b = BEM.b("table-common");

interface EmployeeRequestsProps extends WithTranslation {
  employeeUuid: string;
}

interface EmployeeRequestsState {
  loaded: boolean;
  requests: Request[];
  notification: string | null;
  notificationType: NotificationType | null;
  selectedRequest: Request | null;
  selectedColumns: string;
}

class EmployeeRequests extends Component<EmployeeRequestsProps, EmployeeRequestsState> {
  // TODO: implement pagination, columns in the future
  readonly state: Readonly<EmployeeRequestsState> = {
    loaded: false,
    requests: [],
    notification: null,
    notificationType: null,
    selectedRequest: null,
    selectedColumns: getSelectedColumns("category,subCategory,when,requestedOn,status", "EmployeeRequests"),
  };

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    try {
      const res = await getRequestsList({
        companyUUID: window.global_store.company.uuid,
        userProfileUuid: this.props.employeeUuid,
        params: {
          side: "subject",
          requested_by: window.global_store.profile.uuid,
          // TODO: implement pagination in future
          page: "1",
          perPage: "100",
        },
      });

      const requests = res.content;
      this.setState({
        requests,
        loaded: true,
      });
    } catch (e) {
      sentryUtils.sendError(e);
      this.setState({ loaded: true });
    }
  };

  onNotificationReceived = (notification: string, notificationType: NotificationType) => {
    this.setState({ notification, notificationType });
  };

  onNotificationClose = () => {
    this.setState({ notification: null, notificationType: null });
  };

  // TODO move it to request details
  onChangeRequestStatus = async ({
    request,
    status,
    declineReason,
  }: {
    request: Request;
    status: Omit<RequestApprovalFlowStatus, RequestApprovalFlowStatus.pending>;
    declineReason?: string;
  }) => {
    const { t } = this.props;
    let notification = "";
    let notificationType = NotificationType.success;

    const body: RequestBulkChangeStatusRequestData["body"] = {
      content: {
        approved: [],
        declined: [],
        ignored: [],
        ranges: [
          {
            userProfileUuid: request.userProfileUuid,
            from: getDateWithTZ(request.startTime, request.timezone).format("YYYY-MM-DD"),
            to: getDateWithTZ(request.endTime, request.timezone).format("YYYY-MM-DD"),
          },
        ],
        updatedBy: window.global_store.profile.uuid,
      },
    };

    body.content[status].push(
      status === RequestApprovalFlowStatus.declined
        ? { uuid: request.uuid, reason: declineReason ?? "" }
        : request.uuid,
    );

    try {
      const res = await requestsBulkChangeStatus({
        companyUUID: window.global_store.company.uuid,
        body,
      });

      if (res?.content?.failed?.length) {
        notification = t(res.content.failed[0].issues[0]);
        notificationType = NotificationType.error;
      } else {
        notification = t(`You ${status} this request`);
        notificationType = NotificationType.success;
      }
    } catch (e) {
      const error = e as any;
      notificationType = NotificationType.error;

      if (error?.content?.failed?.[0]?.errorMessage) {
        notification = t(error.content.failed[0].errorMessage);
      } else if (error?.message) {
        notification = t(error.message);
      } else {
        notification = t("Something went wrong");
      }
    }

    this.setState(
      {
        selectedRequest: null,
        notification,
        notificationType,
      },
      () => this.getState(),
    );
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_EmployeeRequests", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  getTableData = (): EmployeeRequestListTableData[] => {
    const { t } = this.props;
    const { requests } = this.state;

    return requests.map((e) => ({
      uuid: e.uuid,
      employee: e.employee,
      category: e.requestType,
      subCategory: e.requestSubtypeState.translationKey
        ? t(`${TranslationNamespaces.requestsPageTmp}|${e.requestSubtypeState.translationKey}`)
        : e.requestSubtypeState.name,
      startDate: e.startTime,
      timezone: e.timezone,
      when: getRequestDate(e),
      requestedOn: e.createdAt,
      status: e.approvalStatus,
    }));
  };

  getTableColumns = (): iColumn<EmployeeRequestListTableData>[] => {
    const { t } = this.props;

    return [
      {
        label: t("Category"),
        accessor: "category",
        Cell: (row) => t(`${TranslationNamespaces.requestsPageTmp}|${row.value}`),
        minWidth: 137,
      },
      {
        label: t("Sub Category"),
        accessor: "subCategory",
        Cell: (row) => t(`${TranslationNamespaces.requestsPageTmp}|${row.value}`),
        minWidth: 164,
      },
      {
        label: t("When"),
        accessor: "when",
        minWidth: 189,
        locked: true,
      },
      {
        label: t("Requested On"),
        accessor: "requestedOn",
        Cell: (row) => <span>{row.value && moment(row.value).utc().format("HH:mm ddd DD/MM/YYYY")}</span>,
        minWidth: 189,
      },
      {
        label: t("Status"),
        accessor: "status",
        Cell: (row) => (
          <span className={b("status-button", [row.value])}>
            {t(`${TranslationNamespaces.requestsPageTmp}|${row.value}`)}
          </span>
        ),
        minWidth: 130,
        align: "center",
        locked: true,
      },
    ];
  };

  openRequestDetails = (requestUuid: string) => {
    const { requests } = this.state;
    const request = requests.find((r) => r.uuid === requestUuid);

    if (!request) {
      return;
    }

    this.setState({ selectedRequest: request });
  };

  render() {
    const { notification, notificationType, loaded, selectedRequest, selectedColumns } = this.state;
    const { t } = this.props;

    return (
      <div>
        {notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notificationType}
            onClose={this.onNotificationClose}
            message={notification}
          />
        )}
        {loaded ? (
          <div className={p("content")}>
            <div className={b()}>
              <TablePage<EmployeeRequestListTableData>
                selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
                onColumnsChange={this.onColumnsChange}
                rows={this.getTableData()}
                columns={this.getTableColumns()}
                className="employee-table"
                defaultPageSize={1000}
                getTrProps={(_, rowInfo) => ({
                  style: {
                    height: "52px",
                  },
                  onClick: () => {
                    if (!rowInfo) {
                      return;
                    }
                    this.openRequestDetails(rowInfo.original.uuid);
                  },
                })}
              />
            </div>
          </div>
        ) : (
          <div>{t(`${TranslationNamespaces.common}|Loading...`)}</div>
        )}

        <SidePopupOverlay
          requestDetails
          header={selectedRequest ? getRequestTypeName(selectedRequest, t) : null}
          switchButton={null}
          isOpen={!!selectedRequest}
          contentOverflow
          onClose={() =>
            this.setState({
              selectedRequest: null,
            })
          }
        >
          <RequestDetails request={selectedRequest} onStatusChanged={this.onChangeRequestStatus} />
        </SidePopupOverlay>
      </div>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.requestsPageTmp])(
  EmployeeRequests,
);
