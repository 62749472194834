import { AvailableLocales } from "./translationHelpers";

export const languages = [
  {
    key: AvailableLocales.pt,
    name: "Português (Brasil)",
  },
  {
    key: AvailableLocales.es,
    name: "Español (Spain)",
  },
  {
    key: AvailableLocales.en,
    name: "English (USA)",
  },
  {
    key: AvailableLocales.he,
    name: "Hebrew (Israel)",
  },
].sort((a, b) => (a.key > b.key ? 1 : -1));
