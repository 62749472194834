import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getSelectedColumns } from "utils/tableHelpers";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getDateColumn, getDateCellData } from "utils/reportsHelpers";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import { minsToHrsMins } from "utils/common";
import ReportUserInfo from "./ReportUserInfo";
import "styles/reports-extra-hour-table.scss";
import ReportsNoContent from "./ReportsNoContent";
import { PhasesType, getPhasesLabel } from "./helpers";

const b = BEM.b("reports-extra-hour-table");

class ReportsExtraHourTable extends Component {
  state = {
    dates: [],
    totalExtraHoursPhases: [],
    activePhases: [],
    loading: true,
    selectedColumns: getSelectedColumns(
      "date,worked_hours,phases,adjustments,reason,extra_hours",
      "ReportsExtraHourTable",
    ),
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = () => {
    const { t, searchObj, startDate, endDate, onlyNonZeroPhases, oldReport, updateRecalculatingMessage, setIsLoading } =
      this.props;
    const companyUUID = window.global_store.company.uuid;

    if (searchObj && searchObj.employee && startDate && endDate && companyUUID) {
      this.setState({ loading: true }, async () => {
        setIsLoading(true);

        try {
          const response = await getReport(
            {
              onlyNonZeroPhases,
              UserProfileUUID: searchObj.employee.uuid,
              startDate,
              endDate,
              companyUUID,
              oldReport,
              type: "extra_hours",
            },
            true,
          );

          const resp = response.content;
          if (!oldReport && resp.dates) {
            const allDaysCount = resp.dates.length || 0;
            const completedDaysCount = resp.dates.filter((d) => d.status === "completed").length;

            if (!resp.dates.length) {
              updateRecalculatingMessage(false);
            } else {
              updateRecalculatingMessage(
                allDaysCount !== completedDaysCount || response?.metadata?.status === "pending",
              );
            }
          }

          this.setState({
            ...resp,
            activePhases: response.metadata.activePhases,
            loading: false,
          });
        } catch (e) {
          console.log("Error", e);

          this.setState({ loading: false, error: t("Failed to generate report") });
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  getColumns = () => {
    const { t } = this.props;
    const {
      totalDebitMinutes,
      totalExtraHoursMinutes,
      totalMissedMinutes,
      totalWorkedMinutes,
      totalExtraHoursPhases = {},
      activePhases = [],
      totalOnCallMinutes = {
        activated: 0,
        onCall: 0,
      },
    } = this.state;

    const phasesColumns = activePhases.length
      ? activePhases.map((phase) => ({
          accessor: phase.uuid,
          rubyAccessor: "phases",
          groupLabel: t("Phases"),
          label: getPhasesLabel(t, PhasesType.extraHours, phase),
          Cell: (row) => {
            const hours = row.original.extraHoursPhases[phase.uuid] || 0;
            return minsToHrsMins(hours);
          },
          Footer: minsToHrsMins(totalExtraHoursPhases[phase.uuid] || 0),
          align: "end",
          minWidth: 100,
        }))
      : [];

    const columns = [
      getDateColumn(t),
      {
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        label: t("Worked Hours"),
        Footer: totalWorkedMinutes ? minsToHrsMins(totalWorkedMinutes) : "00:00",
        minWidth: 100,
        align: "right",
      },
      {
        accessor: "missedMinutes",
        rubyAccessor: "missed_hours",
        label: t("Missed Minutes"),
        Footer: totalMissedMinutes ? minsToHrsMins(totalMissedMinutes) : "00:00",
        minWidth: 100,
        align: "right",
      },
      ...phasesColumns,
      {
        accessor: "adjustment",
        rubyAccessor: "adjustments",
        label: t("Adjustments"),
        Cell: (r) => {
          if (Array.isArray(r.value)) {
            return r.value.map((adj, i) => (
              <div
                key={`${adj}${i}`}
                style={{
                  color: adj > 0 ? "#00CA73" : adj < 0 ? "#FE6764" : "inherit",
                }}
              >
                {adj > 0 ? "+" : ""}
                {minsToHrsMins(adj)} hs
              </div>
            ));
          }
          if (r.value) {
            return (
              <span
                style={{
                  color: r.value > 0 ? "#00CA73" : r.value < 0 ? "#FE6764" : "inherit",
                }}
              >
                {r.value > 0 ? "+" : ""}
                {minsToHrsMins(r.value)} hs
              </span>
            );
          }
          return "";
        },
        minWidth: 100,
      },
      {
        accessor: "reason",
        rubyAccessor: "reason",
        label: t("Reason"),
        Cell: (r) => {
          if (Array.isArray(r.value)) {
            return r.value.map((adj, i) => <div key={`${adj}${i}`}>{adj}</div>);
          }
          if (r.value) {
            return r.value;
          }
          return "";
        },
        minWidth: 100,
      },
      {
        accessor: "extraHoursMinutes",
        rubyAccessor: "extra_hours",
        label: t("Total Extra"),
        Footer: totalExtraHoursMinutes ? minsToHrsMins(totalExtraHoursMinutes) : "00:00",
        minWidth: 130,
        align: "right",
      },
      {
        accessor: "debitMinutes",
        rubyAccessor: "debit_hours",
        label: t("Debit Minutes"),
        Footer: totalDebitMinutes ? minsToHrsMins(totalDebitMinutes) : "00:00",
        minWidth: 130,
        align: "end",
      },
    ];

    columns.push({
      accessor: "onCallMinutes",
      rubyAccessor: "on_call_hours",
      label: t("On Call Minutes"),
      Footer: minsToHrsMins(totalOnCallMinutes.onCall),
      style: { fontWeight: "500" },
      minWidth: 130,
      align: "right",
    });
    columns.push({
      accessor: "onCallActivatedMinutes",
      rubyAccessor: "on_call_activated_hours",
      Footer: minsToHrsMins(totalOnCallMinutes.activated),
      label: t("On Call Activated Minutes"),
      style: { fontWeight: "500" },
      minWidth: 130,
      align: "right",
    });

    return columns;
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsExtraHourTable", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render() {
    const { loading, error, employeeInfo, dates, selectedColumns } = this.state;
    const { t, searchObj, startDate, endDate, oldReport, onlyNonZeroPhases } = this.props;
    let lastScheduleName;

    if (error) {
      return <div>{error}</div>;
    }

    const data =
      dates.length > 0
        ? dates.map((d) => {
            if (d.schedule) {
              lastScheduleName = d.schedule.name;
            }

            const dataObj = {
              date: getDateCellData(d),
              extraHoursPhases: d.extraHoursPhases,
              adjustment: d.adjustments.filter((adj) => adj.convertToExtraHours).map((adj) => Math.abs(adj.adjustment)),
              reason: d.adjustments.filter((adj) => adj.convertToExtraHours).map((adj) => adj.reason),
              missedMinutes: d.missedMinutes ? minsToHrsMins(d.missedMinutes) : "00:00",
              workedMinutes: d.workedMinutes ? minsToHrsMins(d.workedMinutes) : "00:00",
              extraHoursMinutes: d.extraHoursMinutes ? minsToHrsMins(d.extraHoursMinutes) : "00:00",
              debitMinutes: d.debitMinutes ? minsToHrsMins(d.debitMinutes) : "00:00",
              onCallMinutes:
                d.onCallMinutes && d.onCallMinutes.onCall ? minsToHrsMins(d.onCallMinutes.onCall) : "00:00",
              onCallActivatedMinutes:
                d.onCallMinutes && d.onCallMinutes.activated ? minsToHrsMins(d.onCallMinutes.activated) : "00:00",
              status: d.status,
            };

            return dataObj;
          })
        : [];

    const columns = this.getColumns();

    return (
      <div className={b()}>
        <TablePage
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          rows={data}
          columns={columns}
          loading={loading}
          className="reports-hoursbank-table"
          tableDatailsClassName="table-details-ai-start"
          tableDetails={<ReportUserInfo employeeInfo={{ ...employeeInfo, schedule: lastScheduleName }} />}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  onlyNonZeroPhases,
                  searchObj,
                  startDate,
                  endDate,
                  reportType: "extra_hours",
                  oldReport,
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className:
              // eslint-disable-next-line no-underscore-dangle
              !oldReport && rowInfo.row._original.status && rowInfo.row._original.status !== "completed"
                ? // eslint-disable-next-line no-underscore-dangle
                  `row-incomplete row-incomplete_${rowInfo.row._original.status}`
                : "",
          })}
          noContentComponent={<ReportsNoContent />}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation(["reports-page", "phases"])(ReportsExtraHourTable));
