import { ChangeEvent, Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CheckboxControl from "components/UI/NewCheckbox";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import { TranslationNamespaces } from "types/translationNamespaces";

interface LockAfterDaysProps extends WithTranslation {
  value: unknown;
  onChange: (val: string | null) => void;
}

interface LockAfterDaysState {
  dontAllow: boolean;
}

class LockAfterDays extends Component<LockAfterDaysProps, LockAfterDaysState> {
  constructor(props: LockAfterDaysProps) {
    super(props);

    this.state = {
      dontAllow: !!props.value,
    };
  }

  render() {
    const { onChange, t, value } = this.props;
    const { dontAllow } = this.state;

    return (
      <>
        <div style={{ margin: "8px 0" }}>
          <CheckboxControl
            checked={dontAllow}
            label={t("Don't allow attachments after")}
            onChange={(checked) =>
              this.setState({ dontAllow: checked }, () => {
                onChange(null);
              })
            }
          />
        </div>
        <div>
          <FieldWrapper fieldName={t("Days")} width="100%" fieldTitleMarginTop={0} fieldTitleMarginBottom={8}>
            <TextInputControl
              placeholder=""
              value={value || 0}
              disabled={!dontAllow}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const val = ev.target.value.replace(/[^0-9]*/g, "");

                onChange(val);
              }}
            />
          </FieldWrapper>
        </div>
      </>
    );
  }
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(LockAfterDays);
