import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import styled from "styled-components";
import { ChartControls, ChartDataPlot } from "../styled";

charts(FusionCharts);

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

// TODO change to common LoadingBar
export const ControlLoader = styled.div`
  position: relative;
  overflow: hidden;
  margin: 12px 0;
  width: 120px;
  height: 12px;
  background-color: var(--colors-unknown34);

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: chart-loading 1.5s infinite;
  }

  @keyframes chart-loading {
    100% {
      transform: translateX(100%);
    }
  }
`;

const DEFAULT_DATA_SOURCE = {
  width: "100%",
  dataFormat: "json",
  type: "column2d",
  dataSource: {
    data: [
      { value: 38 },
      { value: 28 },
      { value: 18 },
      { value: 28 },
      { value: 38 },
      { value: 28 },
      { value: 18 },
      { value: 28 },
      { value: 38 },
      { value: 28 },
      { value: 18 },
      { value: 28 },
    ],
    chart: {
      theme: "oitchauChartTheme",
      maxColWidth: 40,
      showLabels: 0,
      showTooltip: 0,
      showHoverEffect: 0,
      paletteColors: "#ECF2FA",
      drawcrossline: 0,
      showlegend: 0,
      showYAxisValues: 0,
      showPlotBorder: 0,
    },
    line: {
      displayValue: 0,
      valueOnRight: false,
      color: "#00FF00",
    },
  },
};

interface ChartLoadingStateProps {}

const ChartLoadingState = (props: ChartLoadingStateProps) => (
  <Wrapper>
    <ChartControls>
      <ControlLoader />
      <ControlLoader />
      <ControlLoader />
    </ChartControls>

    <ReactFusioncharts {...DEFAULT_DATA_SOURCE} />

    <ChartDataPlot>
      <ControlLoader />
      <ControlLoader />
      <ControlLoader />
    </ChartDataPlot>
  </Wrapper>
);

export default ChartLoadingState;
