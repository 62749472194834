import CONFIG from "config";
import { RouteComponentProps } from "react-router-dom";
import { AggregationGroupType } from "types/models/dashboard";

type Params = {
  type: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  group_type?: AggregationGroupType;
  group_uuid?: string;
  search_value?: string;
};

export const updateUrlWithParams = (params: Params, history: RouteComponentProps["history"]) => {
  const { type, startDate, endDate, group_type, group_uuid, search_value } = params;

  const queryParams = [];
  queryParams.push(`type=${type}`);
  queryParams.push(`startDate=${startDate.format(CONFIG.apiDateFormat)}`);
  queryParams.push(`endDate=${endDate.format(CONFIG.apiDateFormat)}`);

  if (group_type) {
    queryParams.push(`group_type=${group_type}`);
  }
  if (group_uuid) {
    queryParams.push(`group_uuid=${group_uuid}`);
  }
  if (search_value) {
    queryParams.push(`search_value=${search_value}`);
  }

  history.push(`?${queryParams.join("&")}`);
};
