import styled from "styled-components";
import Avatar from "components/views/Avatar";
import { PunchLogActor } from "types/models/punches";

const Wrapper = styled.div`
  margin-top: 32px;
  display: flex;
`;

const AvatarBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-inline-end: 5px;
`;

const ReasonBlock = styled.div`
  position: relative;
  margin-inline-start: 16px;
  padding: 16px 11px 16px 16px;
  width: 100%;
  background-color: var(--colors-unknown45);
  border-radius: var(--shapes-border-radius-default);

  &:before {
    position: absolute;
    left: -16px;
    bottom: 0;
    width: 19px;
    height: 36px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.282 34.994C11.522 34.59 16.69 12.188 18 .935V35c-5.036-.011-14.866-.026-16.718-.006z' fill='%23EFF4FA'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.523 21.852c2.716-7.006 4.328-15.375 4.98-20.974l.997.057v34.566l-.501-.001c-5.032-.011-14.84-.026-16.705-.006A7.773 7.773 0 011 35.5l-.025-1c.052-.002.156-.004.294-.005 2.361-.096 4.482-1.459 6.377-3.73 1.895-2.275 3.519-5.408 4.877-8.913zM4.831 34.484c3.669-.002 9.134.007 12.669.015V7.479c-.881 4.694-2.214 10.012-4.044 14.735-1.378 3.553-3.048 6.799-5.042 9.19-1.084 1.3-2.277 2.363-3.583 3.08z' fill='%23EFF4FA'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeclinerName = styled.div`
  width: 160px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-surface-400);
`;

const DeclineDate = styled.div`
  line-height: 16px;
  font-size: 13px;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-400);
`;

const ReasonMassage = styled.div`
  margin-top: 6px;
  line-height: 19px;
  font-size: 15px;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-unknown15);
`;

interface DeclineReasonRowProps {
  employee: PunchLogActor;
  declineReason: string | null;
  date: string;
}

const DeclineReasonRow = ({ employee, declineReason, date }: DeclineReasonRowProps) => (
  <Wrapper className="decline-reason-row">
    <AvatarBlock>
      <Avatar user={employee} />
    </AvatarBlock>
    <ReasonBlock>
      <DetailsRow>
        <DeclinerName>{employee.fullName}</DeclinerName>
        <DeclineDate>{date}</DeclineDate>
      </DetailsRow>
      <ReasonMassage>{declineReason}</ReasonMassage>
    </ReasonBlock>
  </Wrapper>
);

export default DeclineReasonRow;
