import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Coupon, FeatureLabel, Interval, Subscription, SubscriptionStatus } from "types/models/subscription";
import { SubscriptionApi } from "utils/api/subscription";
import { BillingService } from "../BillingService";
import { ProductItem } from "../PricingContainer";

const styles = stylesheet`@import "../billing.scss"`;
type UpgradeSeatsModalProps = {
  close: () => void;
  companyUuid: string;
  interval: Interval;
  coupon: Coupon | null;
  productItems: ProductItem[];
  subscription: Subscription;
};

const UpgradeBundleSeatsModal = (props: UpgradeSeatsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getUpgradeDescription = () => {
    const price = props.productItems.reduce((acc, pi) =>
      acc + BillingService.calculateTotalPrice(pi.selectedTier!, props.interval, props.coupon, pi.product.externalId)
    , 0);
    if (props.interval === Interval.Year) {
      return (
        <p className={styles.UpgradePopupTerms}>
          {t("upgrade_seats_description_annual {{price}} {{date}}", {
            price: price.toLocaleString("en-US", { style: "currency", currency: props.productItems[0].price.currency }),
            date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
          })}
        </p>
      );
    }
    return (
      <p className={styles.UpgradePopupTerms}>
        {t("upgrade_seats_description_monthly {{price}} {{date}}", {
          price: price.toLocaleString("en-US", { style: "currency", currency: props.productItems[0].price.currency }),
          date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
        })}
      </p>
    );
  };
  const activeSubscription = BillingService.findSubscriptions(BillingService.getCompanySubscriptions(), SubscriptionStatus.PastDue) || BillingService.findSubscriptions(BillingService.getCompanySubscriptions(), SubscriptionStatus.Active);
  const sameTiersAsNow = () => {
    if (!activeSubscription) return false;
    return props.productItems.every((pi) =>
      !!activeSubscription.items.find((i) => i.feature === pi.product.features[0] && i.maxSeats === pi.selectedTier.up_to)
    )
  };
  const submit = async () => {
    setError(null);
    setLoading(true);
    const payload = {
      items: props.productItems.map((pi) => ({
        seats: pi.selectedTier.up_to!,
        priceUuid: pi.price.uuid,
      })),
    }
    await SubscriptionApi.upgradePlan(props.companyUuid, props.subscription.uuid, payload)
      .then(() => {
        window.location.href = `/billing/payment-success/upgrade/${props.interval}`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <div>
      <div className={styles.UpgradePopupTitle}>{t("Add seats")}</div>
      {
        props.productItems.map((pi) => (
          <div className={styles.MarginBottom24}>
            <FieldWrapper
              className={styles.WhiteSelect}
              width="100%"
              fieldName={t(FeatureLabel[pi.product.features[0]])}
              fieldTitleMarginBottom={0}
              fieldTitleMarginTop={0}
            >
              <Select
                disabled
                value={pi.selectedTier.up_to}
                options={[
                  { label: `${pi.selectedTier.up_to} ${t("seats")}`, value: pi.selectedTier.up_to },
                ]}
              />
            </FieldWrapper>
          </div>
        ))
      }

      {getUpgradeDescription()}
      <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
        <Button
          disabled={loading}
          value={t("Cancel")}
          style={{ width: "48%" }}
          state={ButtonState.enableDay}
          onClick={props.close}
        />
        <Button
          disabled={loading || sameTiersAsNow()}
          value={t("Confirm Upgrade")}
          style={{ width: "48%" }}
          state={ButtonState.primary}
          onClick={submit}
        />
      </div>
      {error ? <div className={styles.PaymentError}>{t(error)}</div> : null}
    </div>
  );
};

export default UpgradeBundleSeatsModal;
