import { getImgUrl } from "utils/common";
import BEM from "../../../utils/BEM";
import "../../../styles/avatar.scss";

const b = BEM.b("avatar");

type AvatarUserProfile = {
  fullName?: string;
  avatarId?: string | null;
};

interface AvatarProps<T> {
  user: T;
  modifiers?: { big?: boolean; injected?: boolean; large?: boolean };
  status?: AvatarUserStatus;
  className?: string;
}

const Avatar = <T extends AvatarUserProfile>({ user, status, className, modifiers }: AvatarProps<T>) => {
  const cloudinaryTransformation = "f_auto,q_auto/h_100,w_100,c_thumb,g_face,fl_progressive.force_strip/";

  if (!user) {
    return null;
  }

  const fullName = user.fullName || "";
  const initials = fullName
    .split(" ")
    .map((w: string) => w[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();
  const avatarUrl = user.avatarId ? getImgUrl(user.avatarId, cloudinaryTransformation) : "";

  return (
    <div
      className={
        b({
          online: status && status.isOnline,
          offline: status && !status.isOnline,
          ...modifiers,
        }) + (className ? ` ${className}` : "")
      }
    >
      {avatarUrl ? (
        <img alt="" src={avatarUrl} className={b("profile-avatar", { ...modifiers })} />
      ) : (
        <div className={b("no-avatar", { ...modifiers })}>{initials}</div>
      )}
    </div>
  );
};
export default Avatar;
