import * as Sentry from "@sentry/browser";
import { Integrations as ApmIntegrations } from "@sentry/apm";
import CONFIG from "../config";

const getEnv = () => {
  switch (CONFIG.appEnv) {
    case "production":
      return "prod";
    case "staging":
      return "stg";

    default:
      return "dev";
  }
};
export default {
  init: () => {
    // don't log in development mode
    if (CONFIG.appEnv === "production") {
      const initObj = {
        dsn: "https://744402c4e9b0425989e31c2dca4517b2@sentry.io/1241447",
        environment: getEnv(),
        ignoreErrors: [
          `Cannot read properties of undefined (reading 'identify')`,
          `undefined is not an object (evaluating 'window.userGuiding.identify')`,
          `window.userGuiding is undefined`,
          `Server Error Network Error`,
          `Error during logout on 401: Error during logout`,
        ],
        integrations: [
          new ApmIntegrations.Tracing({
            tracingOrigins: ["*.oitchau.com.br", "*.day.io"],
          }),
        ],
        allowUrls: [/https?:\/\/(www\.)?admin\.day\.io/, /https?:\/\/(www\.)?admin\.oitchau\.com\.br/],
        tracesSampleRate: CONFIG.appEnv === "production" ? 0.1 : 0.1, // Be sure to lower this in production
        beforeBreadcrumb(breadcrumb, hint) {
          /** Filter out not needed breadcrumbs */
          if (
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("Initializing Beamer") === 0) ||
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("[LaunchDarkly]") === 0) ||
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("**************") === 0) ||
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("* This is an evaluation only") === 0) ||
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("* All AG Grid Enterprise") === 0) ||
            (breadcrumb.category === "console" && breadcrumb.message.indexOf("aplitude call") === 0) ||
            (breadcrumb.category === "console" &&
              breadcrumb.message.indexOf("* If you want to hide the watermark") === 0) ||
            (breadcrumb.category === "fetch" && breadcrumb.message.indexOf("oitchau.com.br") === -1)
          ) {
            return null;
          }

          return breadcrumb;
        },
      };

      if (CONFIG.version) {
        initObj.release = `oithau-admin@${CONFIG.version}`;
      }

      Sentry.init(initObj);
    }
  },
  setUser: (profile) => {
    Sentry.configureScope((scope) => {
      const companyId = profile?.company?.id || "";
      const companyName = profile?.company?.name || "";
      const id = profile?.id || "";
      const fullName = profile?.full_name || "";

      scope.setUser({ id, companyId, fullName, companyName });
    });
  },
  addBreadcrumb: (message, data) => {
    Sentry.addBreadcrumb({
      category: "ui",
      message,
      level: "info",
      data: data || {},
    });
  },
  sendError: (error) => {
    console.error("ERROR:", error?.message ? error.message : JSON.stringify(error));
    Sentry.addBreadcrumb({
      category: "url",
      message: window.location.href,
      level: "info",
    });
    Sentry.captureException(error);
  },
  sendMessage: (error) => {
    console.log("MESSAGE:", JSON.stringify(error));
    Sentry.captureMessage({
      message: error.message ? `${error.status}: ${error.message}` : error,
    });
  },
};
