import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import deepEqual from "fast-deep-equal";
import {
  approveActivity,
  declineActivity,
  deleteActivityAttachment,
  getActivityByUuid,
  getTasksListForUserProfile,
  removeActivity,
  updateAtivity,
  uploadActivityAttachment,
} from "components/Projects/projectsApiUtils";
import BEM from "utils/BEM";
import { hasPermisionAccess, minsToHrsMins } from "utils/common";
import Avatar from "components/views/Avatar";
import "styles/punch-details.scss";
import * as momentTz from "moment-timezone";
import { DateRange, extendMoment } from "moment-range";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import StatusBadge from "components/controls/StatusBadge";
import { NotificationType } from "types/common";
import { PermissionRole, PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { OnattachmentsChange, RemoveAttachment, UploadAttachment } from "components/Requests/Attachments";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ActivityStatuses } from "types/models/activity";
import { RequestAttachmentsRowProps } from "components/Requests/RequestAttachmentsRow";
import { Project, Service, Task } from "types/models/projects";
import { ActivityCustomField } from "components/Projects/projectsApiTypes";
import {
  getActivityStatuBadgeType,
  getActivityStatusBadgeLabel,
  isDateInTimesheet,
} from "components/Activities/activitiesHelpers";
import ApproveDeclineRow from "components/Punches/ApproveDeclineRow";
import { listTimesheets } from "components/Timesheets";
import ActivityEdit, { ActivityEditProps } from "./ActivityEdit";
import { makeEmptyActivityCustomFields } from "./utils";

interface TaskWithProject {
  uuid: string;
  name: string;
  projectUuid: string;
  projectName: string;
  project: Project & { client: { name: string } };
}

const moment = extendMoment(momentTz);

const b = BEM.b("punch-details");

const Wrapper = styled.div`
  .time-control {
    margin-top: 0;
  }
`;
const PopupHeaderText = styled.div`
  border-bottom: 1px solid var(--colors-surface-150);
  padding: 0 0 24px 0;
  display: flex;
  width: 100%;

  .punch-details__name-position {
    width: 100%;
  }
`;
const NameStatusWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`;

type ActivityEmployee = {
  uuid: string;
  avatarId: string;
  name: string;
  fullName?: string;
  lastLockDate: string | null;
  position: {
    title: string;
  };
};

type ActivityFromProps = {
  uuid: string;
  employee: ActivityEmployee;
  status: ActivityStatuses;
  date: string;
  startTime: number;
  endTime: number;
  duration: number;
  attachments: RequestAttachmentsRowProps["files"];
  project: Project & { client: { name: string } };
  task: Task;
  taskUuid: string;
  projectUuid: string;
  service: Service;
  location: {
    name: string;
  };
  locationVerification: ActivityEditProps["locationVerification"];
  coordinates: ActivityEditProps["coordinates"];
  locationUuid: string;
  customFields: ActivityCustomField[];
  userProfileUuid: string;
};

interface GroupedTask {
  label: string;
  options: {
    label: string;
    value: string;
    projectUuid: string;
    task: Task;
    project: Project & { client: { name: string } };
  }[];
}

interface ActivityDetailsProps extends WithTranslation {
  stateRef: React.RefObject<{ loading: boolean; changed: boolean }>;
  activity: ActivityFromProps;
  onPopupAction: (param: { notification: string; notificationType: NotificationType }) => void;
  onActivityUpdate: () => void;
  isTimesheetEnabled: boolean;
  allowFutureActivities: boolean;
}

interface ActivityDetailsState {
  deleteConfirmationVisible: boolean;
  fetching: boolean;
  declineConfirmationPopupVisible: boolean;
  loading: boolean;
  isActivityLocked: boolean;
  groupedOptions: GroupedTask[];
  activity: ActivityFromProps;
  timesheetRanges: DateRange[];
  dateError: string | null;
}

class ActivityDetails extends Component<ActivityDetailsProps, ActivityDetailsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ActivityDetailsProps) {
    super(props);
    const { activity } = this.props;

    this.state = {
      deleteConfirmationVisible: false,
      fetching: false,
      declineConfirmationPopupVisible: false,
      loading: false,
      isActivityLocked: !!(
        activity.employee?.lastLockDate &&
        moment(activity.date, "YYYY-MM-DD").isSameOrBefore(moment(activity.employee?.lastLockDate), "day")
      ),
      groupedOptions: [],
      activity,
      timesheetRanges: [],
      dateError: null,
    };
    if (props.stateRef) {
      props.stateRef.current = { loading: false, changed: false }; // TODO fix it
    }
  }

  async componentDidMount() {
    const { activity } = this.state;
    const [tasks, timesheetRanges] = await Promise.all([
      this.getEmployeeTasks(activity.employee.uuid, activity.locationUuid),
      this.getTimesheets({
        userProfileUuid: activity.employee.uuid,
        activitiesDate: moment(activity.date),
      }),
    ]);

    this.setState({ groupedOptions: this.mapTaskProjects(tasks), timesheetRanges });
  }

  mapTaskProjects = (tasks: TaskWithProject[]) => {
    const grouped: GroupedTask[] = [];
    const projects: { [uuid: string]: TaskWithProject[] } = {};
    tasks.forEach((task) => {
      if (projects[task.projectUuid]) {
        projects[task.projectUuid].push(task);
      } else {
        projects[task.projectUuid] = [task];
      }
    });
    Object.keys(projects).forEach((proj) => {
      const projectTasks = projects[proj];
      grouped.push({
        label: projectTasks[0].projectName,
        options: projectTasks.map((pt) => ({
          label: pt.name,
          value: pt.uuid,
          projectUuid: pt.projectUuid,
          project: pt.project,
          task: {
            name: pt.name,
            uuid: pt.uuid,
          },
        })),
      });
    });
    return grouped;
  };

  getTimesheets = async ({ userProfileUuid, activitiesDate }: { userProfileUuid: string; activitiesDate: Moment }) => {
    const company = await this.context.getCompany();
    const from = activitiesDate.clone().subtract(1, "year").format("YYYY-MM-DD");
    const to = activitiesDate.clone().add(2, "month").format("YYYY-MM-DD");

    const response = await listTimesheets({
      companyUuid: company.uuid,
      userProfileUuid,
      requestedBy: window.global_store.profile.uuid,
      from,
      to,
    });

    const timesheetRanges = response.content
      .filter((r) => r.approvalStatus === "pending" || r.approvalStatus === "approved")
      .map((r) => moment.range(moment(r.startDate).startOf("day"), moment(r.endDate).endOf("day")));

    return timesheetRanges;
  };

  getEmployeeTasks = async (userProfileUuid: string, locationUuid: string): Promise<TaskWithProject[]> => {
    const company = await this.context.getCompany();

    const tasks = await getTasksListForUserProfile({
      companyUuid: company.uuid,
      requestedBy: window.global_store.profile.uuid,
      locationUuid,
      userProfileUuid,
    });

    return tasks?.content ? (tasks.content as TaskWithProject[]) : [];
  };

  onDeclineClick = () => {
    this.setState({ declineConfirmationPopupVisible: true });
  };

  onRemoveActivityConfirmationClicked = async () => {
    const { t, onPopupAction } = this.props;
    const { activity } = this.state;

    this.setState({ loading: true, deleteConfirmationVisible: false });
    try {
      const company = await this.context.getCompany();
      await removeActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
        activityUuid: activity.uuid,
      });

      onPopupAction({
        notificationType: NotificationType.success,
        notification: t("task-remove-success"),
      });
    } catch (error) {
      onPopupAction({
        notificationType: NotificationType.error,
        notification: t("task-remove-failed"),
      });
    }
  };

  onDeclineActivityConfirmationClicked = async () => {
    const { t, onPopupAction } = this.props;
    const { activity } = this.state;

    this.setState({ loading: true, declineConfirmationPopupVisible: false });
    try {
      const company = await this.context.getCompany();
      await declineActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
        activityUuid: activity.uuid,
      });
      onPopupAction({
        notificationType: NotificationType.success,
        notification: t("task-decline-success"),
      });
    } catch (error) {
      onPopupAction({
        notificationType: NotificationType.error,
        notification: t("task-decline-failed"),
      });
    }
  };

  onApproveClick = async () => {
    const { t, onPopupAction } = this.props;
    const { activity } = this.state;

    this.setState({ loading: true });
    try {
      const company = await this.context.getCompany();
      await approveActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
        activityUuid: activity.uuid,
      });
      onPopupAction({
        notificationType: NotificationType.success,
        notification: t("task-approve-success"),
      });
    } catch (error) {
      onPopupAction({
        notificationType: NotificationType.error,
        notification: t("task-approve-failed"),
      });
    }
  };

  uploadAttachment: UploadAttachment = async (file, onUploadProgress, cancelToken) => {
    const { activity } = this.state;
    if (this.props.stateRef?.current) {
      this.props.stateRef.current.changed = true;
    }

    const response = await uploadActivityAttachment(
      {
        createdBy: window.global_store.profile.uuid,
        companyUuid: window.global_store.company.uuid,
        attachment: file,
        activityUuid: activity.uuid,
      },
      onUploadProgress,
      cancelToken,
    );
    const attachment = response.data.content;
    return attachment;
  };

  removeAttachment: RemoveAttachment = async (uuid) => {
    const { activity } = this.state;
    if (this.props.stateRef?.current) {
      this.props.stateRef.current.changed = true;
    }

    await deleteActivityAttachment({
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
      activityUuid: activity.uuid,
      companyUuid: window.global_store.company.uuid,
      attachmentUuid: uuid,
    });
  };

  onAttachmentsChange: OnattachmentsChange = (event) => {
    const isSomeLoading = event.attachments.some((a) => a.loading);

    if (this.props.stateRef?.current) {
      this.props.stateRef.current.loading = isSomeLoading;
    }

    this.setState({ loading: isSomeLoading });
  };

  parseTime = (time: string) => {
    if (time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
      return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
    }
    return 0;
  };

  onActivityUpdate = async (value: Partial<ActivityFromProps>) => {
    const { onActivityUpdate } = this.props;
    const { activity } = this.state;
    const assumedActivityAfterUpdate = { ...activity, ...value };
    this.setState({ activity: assumedActivityAfterUpdate });

    const { project, task, ...update } = value;
    try {
      await updateAtivity({
        body: {
          content: {
            taskUuid: activity.taskUuid,
            projectUuid: activity.projectUuid,
            startTime: activity.startTime,
            endTime: activity.endTime,
            date: activity.date,
            customFields: activity.customFields,
            ...update,
            updatedBy: window.global_store.profile.uuid,
          },
        },
        updatedBy: window.global_store.profile.uuid,
        activityUuid: activity.uuid,
        companyUuid: window.global_store.company.uuid,
      });

      const resp: { content: ActivityFromProps } = await getActivityByUuid({
        activityUuid: activity.uuid,
        companyUuid: window.global_store.company.uuid,
        requestedBy: window.global_store.profile.uuid,
      });
      onActivityUpdate();
      const activityAfterUpdate = { ...activity, ...resp.content };
      const wasAssumptionCorrect = deepEqual(
        { ...assumedActivityAfterUpdate, updatedAt: null },
        { ...activityAfterUpdate, updatedAt: null },
      );
      if (!wasAssumptionCorrect) {
        this.setState({ activity: activityAfterUpdate });
      }
    } catch (error) {
      alert(error.message);
    }
  };

  render() {
    const {
      fetching,
      isActivityLocked,
      groupedOptions,
      declineConfirmationPopupVisible,
      deleteConfirmationVisible,
      activity,
      timesheetRanges,
      dateError,
    } = this.state;
    const { t, isTimesheetEnabled, allowFutureActivities } = this.props;

    if (fetching) {
      return <div>{t(`${TranslationNamespaces.common}|Loading...`)}</div>;
    }

    const isOwn = activity.employee.uuid === window.global_store.profile.uuid;
    const canEdit =
      activity.status === ActivityStatuses.pending &&
      !isActivityLocked &&
      (isOwn || hasPermisionAccess(PermissionSectionName.approveActivities));

    const activityDetails = {
      onDateChange: (date: string) => {
        if (date && date !== activity.date) {
          if (isTimesheetEnabled && isDateInTimesheet(timesheetRanges, moment(date))) {
            this.setState({
              dateError: t("Selected date {{selectedDate}} is in timesheet range", {
                selectedDate: moment(date).format("DD-MM-YYYY"),
              }),
            });
          } else {
            this.setState({ dateError: null });
            void this.onActivityUpdate({ date });
          }
        }
      },
      onStartTimeChange: (val: string) => {
        const startTime = this.parseTime(val);
        if (startTime > activity.endTime) {
          return minsToHrsMins(activity.startTime);
        }
        if (startTime !== activity.startTime) {
          const duration = activity.endTime - startTime;
          void this.onActivityUpdate({ startTime, duration });
        }
        return undefined;
      },
      onEndTimeChange: (val: string) => {
        const endTime = this.parseTime(val);
        if (endTime < activity.startTime) {
          return minsToHrsMins(activity.endTime);
        }
        if (endTime !== activity.endTime) {
          const duration = endTime - activity.startTime;
          void this.onActivityUpdate({ endTime, duration });
        }
        return undefined;
      },
      isDateOutsideRange: (day: string) => {
        const m = moment(day, "YYYY-MM-DD");

        return (
          (!allowFutureActivities && m.isAfter(moment(), "day")) ||
          Boolean(activity.employee?.lastLockDate && m.isSameOrBefore(moment(activity.employee.lastLockDate), "day"))
        );
      },
      onTaskChange: (val: GroupedTask["options"]["0"]) => {
        const taskUuid = val.value;
        if (taskUuid !== activity.taskUuid) {
          const projectUuid = val.project.uuid;
          const customFields =
            projectUuid === this.props.activity.projectUuid
              ? this.props.activity.customFields
              : makeEmptyActivityCustomFields(val.project.customFieldsSettings);
          void this.onActivityUpdate({ taskUuid, projectUuid, customFields, project: val.project, task: val.task });
        }
      },
      uploadAttachment: this.uploadAttachment,
      removeAttachment: this.removeAttachment,
      onAttachmentsChange: this.onAttachmentsChange,
      onCustomFieldsChange: (customFields: ActivityCustomField[]) => {
        void this.onActivityUpdate({ customFields });
      },
      date: activity.date,
      startTime: minsToHrsMins(activity.startTime),
      endTime: activity.duration >= 0 ? minsToHrsMins(activity.endTime) : "-",
      duration: activity.duration >= 0 ? minsToHrsMins(activity.duration) : "-",
      client: activity.project?.client?.name,
      location: activity.location,
      locationVerification: activity.locationVerification,
      coordinates: activity.coordinates,
      customFields: activity.customFields,
      attachments: activity.attachments,
      task: activity.task,
      availableTasks: groupedOptions,
      projectName: activity.project.name,
      disabled: !canEdit,
    };

    const { loading } = this.state;
    let buttonProps = {};
    if (loading) {
      buttonProps = { disabled: "disabled" };
    }

    const canUpdateActivityStatus =
      (!isOwn ||
        window?.global_store?.profile?.permission_roles?.some(
          (r: PermissionRole) => r.name === PermissionRoleName.owner || r.name === PermissionRoleName.admin,
        )) &&
      !isActivityLocked &&
      hasPermisionAccess(PermissionSectionName.approveActivities) &&
      hasPermisionAccess(PermissionSectionName.projects) &&
      activity.status === ActivityStatuses.pending;

    const showApproveDeclineButtons =
      !isTimesheetEnabled && canUpdateActivityStatus && activity.status === ActivityStatuses.pending;

    return (
      <Wrapper className={b({ popup: true })}>
        <PopupHeaderText>
          <Avatar
            user={{
              fullName: activity.employee.fullName || activity.employee.name,
              avatarId: activity.employee.avatarId,
            }}
            modifiers={{ big: true }}
          />
          <div className={b("name-position")}>
            <NameStatusWrapper>
              <div className={b("name")}>{activity.employee.fullName || activity.employee.name}</div>
              <StatusBadge
                value={t(
                  `${TranslationNamespaces.common}|${getActivityStatusBadgeLabel(activity.status, isTimesheetEnabled)}`,
                )}
                type={getActivityStatuBadgeType(activity.status, isTimesheetEnabled)}
              />
            </NameStatusWrapper>
            {activity.employee?.position && <div className={b("position")}>{activity.employee.position.title}</div>}
          </div>
        </PopupHeaderText>

        <ActivityEdit
          {...activityDetails}
          dateError={dateError}
          style={{ position: "relative", insetInlineStart: "-25px", width: "400px" }}
        />

        {canUpdateActivityStatus && (
          <>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid var(--colors-surface-150)",
                marginBottom: "25px",
              }}
            />
            <ApproveDeclineRow
              buttonProps={buttonProps}
              onDeclineClick={showApproveDeclineButtons && this.onDeclineClick}
              onApproveClick={showApproveDeclineButtons && this.onApproveClick}
              onDeleteClick={() => this.setState({ deleteConfirmationVisible: true })}
            />
          </>
        )}

        <ModalDialog
          isOpen={deleteConfirmationVisible}
          onClose={() => this.setState({ deleteConfirmationVisible: false })}
        >
          <Lightbox
            title={t("remove-activity-title")}
            text={t("remove-activity-description")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            onClose={() => {
              this.setState({ deleteConfirmationVisible: false });
            }}
            onYes={this.onRemoveActivityConfirmationClicked}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={declineConfirmationPopupVisible}
          onClose={() => this.setState({ declineConfirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("decline-activity-title")}
            text={t("decline-activity-description")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            onClose={() => {
              this.setState({ declineConfirmationPopupVisible: false });
            }}
            onYes={this.onDeclineActivityConfirmationClicked}
          />
        </ModalDialog>
      </Wrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.punchesPage)(ActivityDetails);
