import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SettingsLayout from "components/Layout/SettingsLayout";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import { PageWrapper, HeaderAction } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import HeaderActionButton from "components/controls/HeaderActionButton";
import { urlParam } from "utils/common";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ButtonState } from "components/controls/StyledButton";
import NotificationRow from "../NotificationRow";
import Schedules from "./SchedulesPage";
import Exceptions from "./ScheduleExceptions";
import GlobalContext from "../../context/global-context";
import { importFiles } from "../svg-images";
import "../UI/Page/Page.scss";
import "styles/schedules-page.scss";

export enum SchedulesManagementPageTabs {
  schedules = "schedules",
  exceptions = "exceptions",
}

interface SchedulesManagementPageProps extends WithTranslation, RouteComponentProps, AllFlagsLDClient {}

interface SchedulesManagementPageState {
  activeTab: SchedulesManagementPageTabs;
  notification: string;
  notificationType: NotificationType;
}

class SchedulesManagementPage extends Component<SchedulesManagementPageProps, SchedulesManagementPageState> {
  static contextType = GlobalContext;

  readonly state: SchedulesManagementPageState = {
    activeTab: (urlParam("tab") as SchedulesManagementPageTabs | null) || SchedulesManagementPageTabs.schedules,
    notification: "",
    notificationType: NotificationType.success,
  };

  onNotification = (notification: string) => {
    this.setState({ notification });
  };

  getHeaderAction = () => {
    const { t, history, flags } = this.props;
    const { activeTab } = this.state;

    return (
      <HeaderAction>
        {flags.importShifts && (
          <HeaderActionButton
            title={t("Import Files")}
            onClick={() => history.push("/schedules/import")}
            icon={importFiles}
            state={ButtonState.enableDay}
          />
        )}
        <HeaderActionButtonAdd
          state={ButtonState.primary}
          title={
            activeTab === SchedulesManagementPageTabs.schedules ? t("Create a Schedule") : t("Create an Exception")
          }
          onClick={() => {
            if (activeTab === SchedulesManagementPageTabs.schedules) {
              history.push("/schedules/create");
            } else {
              history.push("/schedules/create-exception");
            }
          }}
        />
      </HeaderAction>
    );
  };

  render() {
    const { notification, activeTab, notificationType } = this.state;
    const { t } = this.props;

    return (
      <SettingsLayout title={t("Schedules Managent")} headerAction={this.getHeaderAction()}>
        <PageWrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType}
              onClose={() => this.setState({ notification: "" })}
              message={notification}
            />
          )}

          <div style={{ display: activeTab === SchedulesManagementPageTabs.schedules ? "block" : "none" }}>
            <Schedules onNotification={this.onNotification} />
          </div>

          {activeTab === SchedulesManagementPageTabs.exceptions && (
            <div>
              <Exceptions />
            </div>
          )}
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation(TranslationNamespaces.schedules)(SchedulesManagementPage)));
