import Select from "components/UI/Select";
import CheckboxControl from "components/UI/NewCheckbox";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import moment, { Moment } from "moment";
import { getPhasesDays } from "utils/common";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PayedOvertimePhase, PhaseType } from "types/models/businessRulesGroup";
import { RequestsAddEditPopupErrors } from "../RequestAddEditPopup";

type HoursBankCovertFieldsProps = {
  startDate: Moment;
  errors: RequestsAddEditPopupErrors;
  onDateChange: (date: Moment) => void;
  lockedDate: moment.Moment | null;
  selectedPhase: PayedOvertimePhase | null;
  onSelectPhaseChange: (checked: boolean) => void;
  phases: PayedOvertimePhase[];
  onPhaseChange: (phaseUuid: string) => void;
};

const HoursBankCovertFields: React.FC<HoursBankCovertFieldsProps> = ({
  startDate,
  lockedDate,
  errors,
  onDateChange,
  selectedPhase,
  onSelectPhaseChange,
  phases,
  onPhaseChange,
}) => {
  const flags = useFlags();
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <>
      <FieldWrapper fieldName={t("Date")} width="100%" fieldTitleMarginBottom={0}>
        <SingleDatePickerControl
          numberOfMonths={1}
          error={!!errors?.startDate}
          value={startDate}
          onChange={onDateChange}
          isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : day.isAfter(moment()))}
        />
      </FieldWrapper>

      {flags.hoursBankRequestPhases && (
        <FieldWrapper width="100%" fieldTitleMarginTop={16}>
          <CheckboxControl label={t("Select Phases")} ios checked={!!selectedPhase} onChange={onSelectPhaseChange} />
        </FieldWrapper>
      )}

      {errors?.hoursBankMins && <ErrorLabel>{errors.hoursBankMins}</ErrorLabel>}
      {errors?.phases && <ErrorLabel>{errors.phases}</ErrorLabel>}

      {!!selectedPhase && (
        <FieldWrapper width="100%" fieldTitleMarginTop={16}>
          <Select
            modifiers={{ field: true }}
            value={selectedPhase.uuid}
            onChange={onPhaseChange}
            options={(() => {
              const { daysMasksObj } = getPhasesDays({
                t,
                isDayTypeBasedOnSchedule: !!phases[0].dayTypeBasedOnSchedule,
              });

              return phases.map((p) => {
                if (p.customName) {
                  return {
                    value: p.uuid,
                    label: p.customName,
                  };
                }
                let label = `${daysMasksObj[(p.dayTypeBasedOnSchedule || p.daysMask) as string]} ${p.name}%`;
                if (p.type !== PhaseType.combined) {
                  label += ` (${t(p.type)})`;
                }
                return {
                  value: p.uuid,
                  label,
                };
              });
            })()}
          />
        </FieldWrapper>
      )}
    </>
  );
};

export default HoursBankCovertFields;
