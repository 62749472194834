import { Amplitude } from "services/amplitude";
import { getErrorText } from "utils/get-error-text";
import { ActivityRes } from "services/oitchau-api";
import { minsToHrsMins } from "utils/common";
import { Injectable } from "services/di";
import { GoogleAnalytics } from "utils/ga/GoogleAnalytics";
import { GaAddTimeButtonLocation, GaStartTimeButtonLocation } from "utils/ga";

type Activity = ActivityRes;

@Injectable()
export class ProjectsEventTracking {
  constructor(protected eventTracking: Amplitude, protected gaEventTracking?: GoogleAnalytics) {}

  trackInit() {
    this.eventTracking.tsheetScreen({});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackError(err: any) {
    const msg = getErrorText(err);
    this.eventTracking.tsheetErrorLightbox({ error: msg });
  }

  trackStartActivity(activity: Activity, actionSource: string) {
    if (this.gaEventTracking && actionSource === "start btn") {
      this.gaEventTracking.trackStartTimeTracking(GaStartTimeButtonLocation.punchNow);
      return;
    }

    this.eventTracking.tsheetStartTracking({});
  }

  trackStopActivity(activity: Activity) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackStopTimeTracking();
      return;
    }

    this.eventTracking.tsheetStop({ taskDuration: minsToHrsMins(activity.endTime - activity.startTime) });
  }

  trackAddActivity(activity: Activity) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackAddTime(GaAddTimeButtonLocation.punchNow);
      return;
    }

    this.eventTracking.tsheetAddTime({});
  }

  trackDeleteActivity(activity: Activity) {
    this.eventTracking.tsheetDelete({});
  }

  trackUpdateActivity(activity: Activity) {
    this.eventTracking.tsheetUpdate({});
  }

  trackExpandActivitiesGroup(numItems: number) {
    this.eventTracking.tsheetExpandView({ numItems });
  }

  trackViewActivity(activity: Activity) {
    this.eventTracking.tsheetViewDetails({});
  }

  trackChangeDate(from: Activity, to: Activity) {
    this.eventTracking.tsheetChangeDate({});
  }

  trackChangeTask(from: Activity, to: Activity) {
    this.eventTracking.tsheetChangeTask({});
  }

  trackChangeLocation(from: Activity, to: Activity) {
    this.eventTracking.tsheetChangeLocation({});
  }
}
