import { Injectable } from "services/di";
import { KvStorage } from "services/kv-storage";

@Injectable()
export class AppStorageClientAdmin {
  constructor(protected kv: KvStorage) {}

  async getProfile() {
    return window.global_store.profile;
  }

  async getLastUsedLocation(): Promise<string | undefined> {
    return this.kv.getItem("lastUsedLocation");
  }

  async setLastUsedLocation(location: string) {
    return this.kv.setItem("lastUsedLocation", location);
  }

  async getDownloadExtensionPopupSilenced() {
    return this.kv.getItem<boolean>("downloadExtensionDontShowAgain");
  }

  async setDownloadExtensionPopupSilenced(dontShowAgain: boolean) {
    return this.kv.setItem("downloadExtensionDontShowAgain", dontShowAgain);
  }
}
