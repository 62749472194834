import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Frequency, Groups, Periods, AggrerationType } from "types/models/dashboard";
import { Subsidiary } from "types/models/subsidiary";
import { Department } from "types/models/department";
import { Position } from "types/models/position";
import { Team } from "types/models/team";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getStoredParams, storeParams } from "./utils/getStoredParams";
import { ChartWrapper, ChartTitle, ChartControls, ChartNoData, FusionChartsWrapper } from "../styled";
import LoadingState from "./ChartLoadingState";
import GroupBySelector from "./GroupBySelector";
import GroupSelector from "./GroupSelector";
import PeroidSelector from "./PeroidSelector";
import AggregationTypeSelector from "./AggregationTypeSelector";
import FrequencySelector from "./FrequencySelector";
import { AggregationParameters } from "./chartDataHooks/usePeriodAggregation";
import ChartLegend from "./ChartLegend";

charts(FusionCharts);

interface ChartProps {
  title: string;
  useChartData: (params: AggregationParameters) => { isLoading: boolean; data: FusionCharts.ChartObject };
  groups: {
    subsidiaries: Subsidiary[];
    departments: Department[];
    positions: Position[];
    teams: Team[];
  };
}

const Chart = ({ title, useChartData, groups }: ChartProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const storedParams = getStoredParams(title);

  const ld = useLDClient();

  const [params, setParamsToState] = useState<AggregationParameters>(storedParams);
  const { isLoading, data } = useChartData(params);

  // setters
  const setParams = (newParams: AggregationParameters) => {
    setParamsToState(newParams);
    storeParams(title, newParams);
  };
  const setGroupBy = (groupBy: Groups) => setParams({ ...params, groupBy });
  const setGroupUuids = (groupUuids: string[]) => setParams({ ...params, groupUuids });
  const setPeriod = (period: Periods) => {
    let frequency = Frequency.month;

    if ([Periods.thisMonth, Periods.lastMonth].includes(period)) {
      frequency = Frequency.day;
    }
    if (Periods.last3Month === period) {
      frequency = Frequency.week;
    }

    setParams({ ...params, period, frequency });
  };
  const setFrequency = (frequency: Frequency) => setParams({ ...params, frequency });
  const setAggrerationType = (aggregationType: AggrerationType) => setParams({ ...params, aggregationType });

  return (
    <ChartWrapper>
      <ChartTitle>{title}</ChartTitle>
      {isLoading && <LoadingState />}

      {!isLoading && (
        <>
          <ChartControls>
            <GroupBySelector value={params.groupBy} onSelect={setGroupBy} />
            {ld?.allFlags().dashboard_filter && (
              <>
                <GroupSelector
                  value={params.groupUuids}
                  groups={groups}
                  groupBy={params.groupBy}
                  onSelect={setGroupUuids}
                />
                <AggregationTypeSelector value={params.aggregationType} onSelect={setAggrerationType} />
                <FrequencySelector value={params.frequency} onSelect={setFrequency} />
                <PeroidSelector value={params.period} onSelect={setPeriod} />
              </>
            )}
          </ChartControls>
          <FusionChartsWrapper>
            <ReactFusioncharts {...data} />
            {data.dataSource.noData && <ChartNoData>{t("No data to show")}</ChartNoData>}
          </FusionChartsWrapper>
          {!data.dataSource.noData && (
            <ChartLegend seriesNames={data.dataSource.dataset.map((d) => d.seriesname) as string[]} />
          )}
        </>
      )}
    </ChartWrapper>
  );
};

export default Chart;
