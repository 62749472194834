import BEM from "../utils/BEM";
import "../styles/progress-bar.scss";

const b = BEM.b("progress-bar");

interface ProgressBarProps {
  /** progress percents */
  width: number;
  modifiers?: {
    danger?: boolean;
  };
  popup?: boolean;
}

const ProgressBar = ({ width, popup, modifiers = {} }: ProgressBarProps) => (
  <div className={b()}>
    <div className={b("bar-wrapper", { popup })}>
      <span className={b("bar")}>
        {!!width && <span className={b("progress", modifiers)} style={{ width: `${width || 0}%` }} />}
      </span>
    </div>
  </div>
);

export default ProgressBar;
