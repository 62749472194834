import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as moment from "moment";
import FieldWrapper from "components/UI/FieldWrapper";
import GlobalContext from "context/global-context";
import { getSchedules, getLockGroupsList, getBRGroups, getTeams } from "utils/apiHelpers";
import { listHolidaysGroups } from "components/Holidays/holidayApiUtils";
import SelectControl from "components/UI/SelectControl";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import { useTranslation } from "react-i18next";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { useQuery } from "react-query";

const Wrapper = styled.div``;

const FETCH_ITEMS = {
  schedules: getSchedules,
  businessRulesGroups: getBRGroups,
  payrollGroups: getLockGroupsList,
  holidayGroups: listHolidaysGroups,
  teams: getTeams,
};

const FIELD_TITLES = {
  schedules: "Schedule",
  businessRulesGroups: "Business Rules Group",
  payrollGroups: "Payroll Group",
  holidayGroups: "Holiday Group",
  teams: "Teams",
};

export default function ({ onClose, onYes, type }) {
  const { t } = useTranslation("employees-page-all-table");
  const [state, setState] = useState({
    startDate: moment(),
    isLoading: true,
    selectedGroup: "remove",
    isSaving: false,
    groupsList: [],
  });
  const { startDate, isLoading, selectedGroup, isSaving, groupsList } = state;
  const context = useContext(GlobalContext);

  const result = useQuery(
    `rq_${type}`,
    async () => {
      const company = await context.getCompany();
      const response = await FETCH_ITEMS[type]({ companyUuid: company.uuid, params: { perPage: 1000 } });

      return response;
    },
    { staleTime: 60 * 1000 },
  );
  const { data } = result;

  useEffect(() => {
    const payload = type === "teams" ? data?.teams : data?.content;

    if (!result.isLoading && payload) {
      let group = state.selectedGroup;
      let groups = [];

      if (type === "teams") {
        groups = payload.map((t) => ({
          label: t.name,
          value: t.id,
        }));
      } else {
        groups = payload
          .filter((item) => item.active || item.status === "active")
          .map((gr) => ({
            label: `${type !== "schedules" && gr.name === "default" ? t("common|default") : gr.name}${
              gr.isDefault ? ` [${t("common|default")}]` : ""
            }`,
            value: gr.uuid,
          }));

        if (type === "schedules" && groups.length) {
          groups.unshift({
            label: t("Remove schedule"),
            value: "remove",
          });
        }

        if (type !== "schedules" && groups.length > 0) {
          group = groups[0].value;
        }
      }

      setState({ ...state, groupsList: groups, selectedGroup: group, isLoading: false });
    }
  }, [data, type]);

  let title = t("Change group");
  if (type === "schedule") {
    title = t("Change schedule");
  } else if (type === "teams") {
    title = t("Change team");
  }

  return (
    <ModalDialog isOpen onClose={onClose} isLoading={isLoading || isSaving}>
      <Lightbox
        onClose={onClose}
        yesDisabled={groupsList.length <= 1}
        onYes={async () => {
          setState({ ...state, isSaving: true });
          await onYes({ selectedGroup, startDate });
          onClose(true);
        }}
        buttonYesTitle={t("common|Confirm")}
        buttonCancelTitle={t("common|Cancel")}
        title={title}
      >
        <Wrapper>
          <FieldWrapper fieldName={t(FIELD_TITLES[type])} loading={isLoading} width="100%">
            <SelectControl
              options={groupsList}
              onChange={(val) => setState({ ...state, selectedGroup: val })}
              isLoading={isLoading}
              value={selectedGroup}
              isSearchable
            />
          </FieldWrapper>
          {!["payrollGroups", "teams"].includes(type) && (
            <FieldWrapper
              fieldName={t("common|Start Date")}
              loading={isLoading}
              width="100%"
              fieldTitleMarginBottom={0}
            >
              <SingleDatePickerControl
                value={startDate}
                onChange={(val) => setState({ ...state, startDate: val })}
                numberOfMonths={1}
                isOutsideRange={(day) => day.isBefore(moment().subtract(12, "month"))}
              />
            </FieldWrapper>
          )}
        </Wrapper>
      </Lightbox>
    </ModalDialog>
  );
}
