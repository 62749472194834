import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import { ImportFilesSettingsPage } from "components/DataManagement/ImportFilesSettingsPage";

const RouteDataManagement = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect exact strict from={`${path}/files/`} to={`${path}/files`} />

      <Route exact strict path={`${path}/files`} component={ImportFilesSettingsPage} />
    </Switch>
  </Suspense>
);

export default RouteDataManagement;
