import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import { getPositions, addPosition } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Position } from "types/models/position";
import sentryUtils from "utils/sentryUtils";
import PopupMessage from "./Positions/PositionsPopupMessage";
import ModalDialog from "./UI/ModalDialog";

type Option = {
  label: string;
  value: string | number;
};

interface PositionsDropdownControlProps {
  t: WithTranslation["t"];
  /** position uuid or id as an option value */
  uuid?: boolean;
  value: string | number | null;
  /** position uuid or id */
  onChange: (value: string | number | null) => void;
}

interface PositionsDropdownControlState {
  isLoading: boolean;
  options: Option[];
  createPopupVisible: boolean;
  positions: Position[];
}

class PositionsDropdownControl extends Component<PositionsDropdownControlProps, PositionsDropdownControlState> {
  readonly state: Readonly<PositionsDropdownControlState> = {
    isLoading: true,
    options: [],
    createPopupVisible: false,
    positions: [],
  };

  async componentDidMount() {
    const { t, uuid } = this.props;

    const response = await getPositions();
    const positions = response?.positions || [];

    let options: Option[] = [{ label: t("none-option"), value: "" }];

    if (positions.length > 0) {
      options = options.concat(positions.map((p) => ({ label: p.title, value: uuid ? p.uuid : p.id })));
    }

    this.setState({ options, isLoading: false, positions });
  }

  createPosition = async ({ title }: { title: string }) => {
    const { onChange, uuid } = this.props;
    const requestData = {
      body: {
        position: {
          title,
        },
      },
    };

    try {
      const { position } = await addPosition(requestData);

      this.setState(
        {
          options: [...this.state.options, { label: position.title, value: uuid ? position.uuid : position.id }],
        },
        () => onChange(uuid ? position.uuid : position.id),
      );
    } catch (err) {
      sentryUtils.sendError(err);
    } finally {
      this.setState({ createPopupVisible: false });
    }
  };

  render() {
    const { t, value, onChange } = this.props;
    const { options, isLoading, createPopupVisible, positions } = this.state;

    return (
      <>
        <SelectControl<string | number>
          options={options}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          isSearchable
          placeholder={t("Select position")}
          withCreateOption
          onCreateOption={() => this.setState({ createPopupVisible: true })}
          createOptionText={t("Create new position")}
        />

        <ModalDialog isOpen={createPopupVisible} onClose={() => this.setState({ createPopupVisible: false })}>
          <PopupMessage
            title={t(`${TranslationNamespaces.positions}|Add a new Position`)}
            item={{}}
            allItems={positions}
            buttonYesTitle={t(`${TranslationNamespaces.positions}|Add`)}
            onClose={() => this.setState({ createPopupVisible: false })}
            onYes={this.createPosition}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.positions])(
  PositionsDropdownControl,
);
