import styled from "styled-components";
import Avatar from "components/views/Avatar";
import { UserProfile } from "types/models/userProfile";

const Header = styled.div`
  height: 64px;
  margin-bottom: 8px;
  padding: 0 24px;
`;

const Title = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UserName = styled.div`
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.44px;
  color: var(--colors-surface-900-p);
`;

const UserPosition = styled.div`
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-700);
`;

interface EmployeePageHeaderNewProps {
  employee: UserProfile;
}

const EmployeePageHeaderNew = ({ employee }: EmployeePageHeaderNewProps) => (
  <Header>
    <Title>
      <Avatar
        user={{
          fullName: employee.full_name,
          avatarId: employee.avatar_id,
        }}
        modifiers={{ large: true }}
      />

      <UserInfo>
        <UserName>{employee.full_name}</UserName>
        {employee.position && <UserPosition>{employee.position.title}</UserPosition>}
      </UserInfo>
    </Title>
  </Header>
);

export default EmployeePageHeaderNew;
