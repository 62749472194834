import { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Applications from "components/Applications/Applications";

const RouteApplications = () => (
  <Suspense fallback={<div />}>
    <Switch>
      <Route path="/apps/" component={Applications} />
      <Redirect from="/apps" to="/apps/" />
    </Switch>
  </Suspense>
);

export default RouteApplications;
