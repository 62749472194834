import React, { Component } from "react";
import BEM from "utils/BEM";
import "styles/download-control.scss";
import { showSnackbar } from "utils/common";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import * as images from "./svg-images";

const b = BEM.b("download-control");
const Value = styled.div`
  border-radius: var(--shapes-border-radius-default);
  font-size: var(--typography-font-size-default);
  vertical-align: middle;
  cursor: pointer;
  min-height: 36px;
  padding-inline-end: 30px;
  padding-inline-start: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  color: #525f7f;
  letter-spacing: 0;
  text-align: center;
  line-height: 14px;
  width: auto;
  svg {
    margin-inline-end: 9px;
  }
`;
const Arrow = styled.span`
  ${rtl`
    right: 17px;
  `}
  svg {
    margin-inline-end: 0;
  }
`;
const Option = styled.div``;
const OPTIONS = [
  { label: "CSV", value: "csv" },
  { label: "PDF", value: "pdf" },
  { label: "XLSX", value: "xlsx.xlsx" },
];
class DownloadControl extends Component {
  static defaultProps = {
    modifiers: {},
    placeholder: "Download",
  };
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      open: false,
      options: this.props.options || OPTIONS,
      value: this.props.value || { value: "", label: "" },
      disabled: this.props.disabled,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options || OPTIONS,
      value: nextProps.value || { value: "", label: "" },
      disabled: nextProps.disabled,
    });
  }
  handleClickOutside(ev) {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target)) {
      this.setState({ open: false });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  toggleDropdown = () => {
    if (!this.state.disabled) {
      this.setState({ open: !this.state.open });
    }
  };
  selectValue = async (value) => {
    const { withNotification, t } = this.props;
    let { disabled } = this.state;
    if (withNotification) {
      disabled = true;
      showSnackbar({
        text: t("Preparing file for download…"),
        notificationStyle: "notice",
      });
    }
    this.setState({ value, disabled, open: false });
    if (this.props.onChange) {
      await this.props.onChange(value.value);
      this.setState({ disabled: false });
    }
  };
  render() {
    const { options, open, value } = this.state;
    const { style, modifiers } = this.props;
    return (
      <div className={b({ open, ...modifiers })} ref={this.setWrapperRef} style={{ ...style }}>
        <Value onClick={this.toggleDropdown}>
          {images.downloadIcon}
          {this.props.placeholder}
          <Arrow className={b("arrow", { open, download: true })}>{images.smallArrowUp()}</Arrow>
          <Arrow className={b("arrow", { open: !open, download: true })}>{images.smallArrowDown()}</Arrow>
        </Value>
        <div className={b("menu", { open, ...modifiers })}>
          {options.map((option) => (
            <Option
              className={b("option", { active: value.value === option.value })}
              key={option.value}
              onClick={() => this.selectValue(option)}
            >
              {option.label}
            </Option>
          ))}
        </div>
      </div>
    );
  }
}
export default withTranslation()(DownloadControl);
