import { BasedOn } from "components/Schedules/Breaks/BreakModal";
import { PunchKey, PunchType } from "./punches";
import { ScheduleBusinessRules } from "./businessRules";

/** Schedule common */
enum ScheduleType {
  shifts = "shifts",
  flexible = "flexible",
  regular = "regular",
}

/** Schedule common */
type ScheduleDayName = "monday" | "tuesday" | "wednesday" | "thursday" | "firday" | "saturday" | "sunday";

/** Schedule common */
type ScheduleDayEvent = {
  automatic?: boolean;
  duration: number | null;
  key: PunchKey;
  obligatory: boolean;
  time: number | null;
  tolerance: number;
  type: PunchType;
  breakTypeUuid: string;
  validity: number | null;
  isWorkingHours?: boolean;
  breakMode: BasedOn;
  /**  added by FE in Punches/helpers.ts, PunchAddPopup. Does not exist in API rsponse */
  shiftId?: string;
  /** Used only on UI for flexible breaks, Does not exist in API rsponse */
  eventsCount?: number;
};

/** Schedule Details */
enum ScheduleRecurrencePeriod {
  daily = "daily",
  weekly = "weekly",
}

/** Schedule Details */
type ScheduleOptionConfines = {
  toleranceBreakEndLate: number;
  toleranceBreakStartEarly: number;
  toleranceEntryLate: number;
  toleranceExitEarly: number;
  validityEntryEarly: number | null;
  validityExitLate: number | null;
};

/** Schedule Details */
type ScheduleOptions = {
  businessRules: ScheduleBusinessRules;
  confines: ScheduleOptionConfines;
  recurrencePeriod: ScheduleRecurrencePeriod;
};

/** Schedule Details */
export type ScheduleDay = {
  dayId: number;
  dayName: ScheduleDayName;
  events: ScheduleDayEvent[];
  maxEndTime: number;
  minStartTime: number;
  shiftDuration: number;
  working: boolean;
};

/** Schedule Details */
type NonWorkingScheduleDay = {
  events: ScheduleDayEvent[];
  scheduleType: ScheduleType;
};

/** Schedule Details */
type Schedule = {
  CompanyUuid: string;
  active: boolean;
  createdAt: string;
  createdBy: string;
  legacyId: number;
  name: string;
  nonWorkingScheduleDay: NonWorkingScheduleDay | null;
  options: ScheduleOptions;
  scheduleDays: ScheduleDay[];
  scheduleType: ScheduleType;
  totalDuration: number;
  uuid: string;
};

/**  Schedule List */
enum ScheduleListScheduleStatus {
  active = "active",
  inactive = "inactive",
}

/**  Schedule List */
type ScheduleListScheduleDay = {
  working: boolean;
  dayId: string;
};

/**  Schedule List */
type ScheduleListScheduleRules = {
  schedule: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
};

/**  Schedule List */
type ScheduleListSchedule = {
  legacyId: string;
  uuid: string;
  name: string;
  totalDuration: number;
  totalDurationWithWorkingBreaks: number;
  scheduleType: ScheduleType;
  type: ScheduleType;
  activeUserProfilesCount: number;
  active: boolean;
  scheduleDays: ScheduleListScheduleDay[];
  rules: ScheduleListScheduleRules;
};

/** Schedule Create */
type ScheduleTemplate = {
  createdAt: string;
  description: string;
  name: string;
  options: {
    confines: ScheduleOptionConfines;
  };
  recurrencePeriod: ScheduleRecurrencePeriod;
  scheduleDays: ScheduleListScheduleDay[];
  scheduleType: ScheduleType;
  totalDuration: number;
  uuid: string;
};

/** Superpunch */
type DailySummarySchedule = {
  name: string;
  uuid: string;
};

/** Superpunch */
type DailySummaryScheduleException = {
  name: string;
  uuid: string;
};

/** Schedule Import */
enum ScheduleFileImportTypes {
  shift = "shift",
}

/** Schedule Import */
enum ScheduleFileImportStatus {
  imported = "imported",
  inProgress = "in_progress",
  failed = "failed",
}

type ScheduleFileImport = {
  company: {
    country: string;
    id: number;
    logo_id: string;
    logo_version: string | null;
    name: string;
    uuid: string;
  };
  data: unknown | null;
  error_message: string;
  id: number;
  import_type: ScheduleFileImportTypes;
  log: unknown | null; // TODO
  // TODO is it Partial ?
  manager: {
    avatar_id: string | null;
    avatar_url: string | null;
    id: number;
    job_description: string | null;
    name: string;
    position_id: number | null;
    uuid: string;
  };
  status: ScheduleFileImportStatus;
  status_changed_at: string | null;
  uuid: string;
  file_name: string;
  created_at: string;
};

export enum ShiftEventLogAction {
  ClearPunch = "clear_punch",
  AssignPunch = "assign_punch",
  NewPunch = "new_punch",
}

export enum ShiftEventLogTrigger {
  UserProfileTrigger = "user_profile",
  ScheduleUpdate = "schedule_update",
  RequestChange = "request_change",
  PunchCancel = "punch_cancel",
}

export type ShiftEventLog = {
  action: ShiftEventLogAction;
  source: "ManagementApi.SuperPunch" | string;
  punch_id: number;
  punch_uuid: string;
  triggeredAt: string; // UTC,
  triggeredBy: {
    trigger: ShiftEventLogTrigger;
    uuid?: string;
    name?: string;
  };
};

export { ScheduleType, ScheduleListScheduleStatus, ScheduleFileImportStatus, ScheduleFileImportTypes };

export type {
  Schedule,
  ScheduleListScheduleDay,
  ScheduleListScheduleRules,
  ScheduleListSchedule,
  ScheduleDayName,
  ScheduleDayEvent,
  ScheduleTemplate,
  DailySummarySchedule,
  DailySummaryScheduleException,
  ScheduleFileImport,
};
