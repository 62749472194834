import { Component, createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import PayrollPopupWithLayouts, { PayrollPopupWithLayoutsOnYesObj } from "components/Reports/PayrollPopupWithLayouts";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { PayrollLock, PayrollLockStatus } from "types/models/payrollLocks";
import GlobalContext from "context/global-context";
import { lockPayrollLock, unlockPayrollLock } from "utils/apiHelpers";
import { Notification, NotificationType } from "types/common";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import {
  cancelDigitalSignatureGroup,
  dismissDigitalSignatureGroup,
  downloadDigitalSignaturesReports,
  requestDigitalSignatures,
  resendlDigitalSignatureGroup,
} from "utils/api/company";
import { TranslationNamespaces } from "types/translationNamespaces";
import { DigitalSignatureStatus } from "types/models/digitalSignature";
import { LockAction } from "./types";
import DeactivatedEmployeesTableOld from "./DeactivatedEmployeesTableOld";
import DeactivatedEmployeesTable from "./DeactivatedEmployeesTable";

interface DeactivatedEmployeesProps extends RouteComponentProps, WithTranslation, AllFlagsLDClient {
  setNotification: (notification: Notification) => void;
  showUlockedWarningPopUp: () => void;
}

interface DeactivatedEmployeesState {
  action: LockAction | null;
  confirmationPopupVisible: boolean;
  exportDialogVisible: boolean;
  selectedItem: PayrollLock | null;
  selectedItemUuid: string | null;
  withForceRecalculate: boolean;
  resendConfirmationPopupVisible: boolean;
  cancelPendingDSConfirmationPopupVisible: boolean;
  downloadConfirmationPopupVisible: boolean;
  dismissConfirmationPopupVisible: boolean;
}

class DeactivatedEmployees extends Component<DeactivatedEmployeesProps, DeactivatedEmployeesState> {
  static contextType = GlobalContext;
  private tableRef: React.RefObject<any> = createRef();

  readonly state: DeactivatedEmployeesState = {
    action: null,
    confirmationPopupVisible: false,
    exportDialogVisible: false,
    selectedItem: null,
    selectedItemUuid: null,
    withForceRecalculate: false,
    resendConfirmationPopupVisible: false,
    cancelPendingDSConfirmationPopupVisible: false,
    downloadConfirmationPopupVisible: false,
    dismissConfirmationPopupVisible: false,
  };

  exportPayroll = (obj: PayrollPopupWithLayoutsOnYesObj): void => {
    this.props.history.replace(
      `/payroll/export/view-${obj.layoutUuid}?from=${obj.from}&to=${obj.to}&groupType=payroll_lock&groupUuid=${obj.payrollGroupUuid}&skipEmptyValues=${obj.skipEmptyValues}`,
    );
  };

  toggleLock = async (item: string, action: LockAction): Promise<void> => {
    const { t, setNotification } = this.props;
    const params = {
      companyUuid: window.global_store.company.uuid,
      payrollLockUuid: item,
      requestedBy: window.global_store.profile.uuid,
    };
    let notification = "";

    try {
      if (action === LockAction.unlock) {
        await unlockPayrollLock(params);
        notification = t("You unlocked period");
      } else {
        await lockPayrollLock(params);
        notification = t("You locked period");
      }

      this.setState(
        {
          confirmationPopupVisible: false,
          selectedItemUuid: null,
          action: null,
        },
        () => setNotification({ notification, notificationType: NotificationType.success }),
      );

      this.tableRef.current.getState();
    } catch (e) {
      const err: any = e;
      let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);

      if (err.message) {
        errorMessage = t(err.message);
      } else if (err.originalRequest?.errors[0]?.message) {
        errorMessage = t(err.originalRequest.errors[0].message);
      }

      this.setState(
        {
          confirmationPopupVisible: false,
          selectedItemUuid: null,
          action: null,
        },
        () => setNotification({ notification: errorMessage, notificationType: NotificationType.error }),
      );
    }
  };

  resendDigitalSignatureRequest = async (payrollLockUuid: string) => {
    const { t, setNotification } = this.props;
    try {
      await resendlDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        selectedItem: null,
        resendConfirmationPopupVisible: false,
      });
      setNotification({
        notification: t("You sent digital signature request"),
        notificationType: NotificationType.success,
      });
      await this.tableRef.current.getState();
    } catch (error) {
      this.setState({
        selectedItem: null,
        resendConfirmationPopupVisible: false,
      });
      setNotification({
        notification: t(`${TranslationNamespaces.common}|Something went wrong`),
        notificationType: NotificationType.error,
      });
    }
  };

  cancelPendingDigitalSignature = async (item: PayrollLock) => {
    const { t, setNotification } = this.props;
    try {
      await cancelDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid: item.uuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelPendingDSConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: t("You canceled digital signature for {{employeeName}}", {
          employeeName: item.deactivatedEmployeeProfile?.fullName || "",
        }),
        notificationType: NotificationType.success,
      });
      await this.tableRef.current.getState();
    } catch (e) {
      let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);
      if (e.message) {
        errorMessage = t(e.message);
      } else if (e.originalRequest?.errors[0]?.message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        cancelPendingDSConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }
  };

  downloadDigitalSignatureRequest = async (payrollLockUuid: string) => {
    const { t, setNotification } = this.props;
    try {
      await downloadDigitalSignaturesReports(
        window.global_store.company.uuid,
        payrollLockUuid,
        window.global_store.profile.uuid,
      );
      this.setState({
        downloadConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: t("Reports will be sent to email after generation"),
        notificationType: NotificationType.success,
      });
    } catch (error) {
      this.setState({
        downloadConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: t(`${TranslationNamespaces.common}|Something went wrong`),
        notificationType: NotificationType.error,
      });
    }
  };
  dismissDigitalSignaturesRequest = async (payrollLockUuid: string) => {
    const { t, setNotification } = this.props;

    try {
      await dismissDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: t("You dismissed signed digital signatures"),
        notificationType: NotificationType.success,
      });
      await this.tableRef.current.getState();
    } catch (e: any) {
      let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);
      if (e.message) {
        errorMessage = t(e.message);
      } else if (e.originalRequest?.errors[0]?.message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
      });
      setNotification({
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }
  };
  getTableButtons = (payrollLock: PayrollLock) => {
    const { showUlockedWarningPopUp, flags, t } = this.props;
    const { status: lockStatus, digitalSignatureStatus } = payrollLock;
    const LOCK_UNLOCK_BUTTON = {
      label: lockStatus === PayrollLockStatus.locked ? t("Unlock") : t("Lock"),
      onClick: () => {
        this.setState({
          confirmationPopupVisible: true,
          action: lockStatus === PayrollLockStatus.locked ? LockAction.unlock : LockAction.lock,
          selectedItemUuid: payrollLock.uuid,
        });
      },
    };
    const EXPORT_BUTTON = {
      label: t("Export"),
      onClick: () => {
        if (lockStatus === PayrollLockStatus.unlocked) {
          showUlockedWarningPopUp();
        } else {
          this.setState({ exportDialogVisible: true, selectedItem: payrollLock });
        }
      },
    };
    const REQUEST_DS_BUTTON = {
      label: t("Request Digital Signatures"),
      onClick: async () => {
        await requestDigitalSignatures({
          payrollLockUuid: payrollLock.uuid,
          body: {
            content: {
              createdBy: window.global_store.profile.uuid,
            },
          },
          companyUuid: window.global_store.company.uuid,
        });
        this.tableRef.current.getState();
      },
    };
    const SEND_TO_PAYROLL_BUTTON = {
      label: t("Send to payroll"),
      onClick: () => {
        if ([PayrollLockStatus.unlocked, PayrollLockStatus.partial].includes(payrollLock.status)) {
          showUlockedWarningPopUp();
        } else {
          this.setState({
            exportDialogVisible: true,
            selectedItem: payrollLock,
            withForceRecalculate: true,
          });
        }
      },
    };
    const DOWNLOAD_BUTTON = {
      label: t("digital_signature_Download_CTA"),
      onClick: () =>
        this.setState({
          selectedItem: payrollLock,
          downloadConfirmationPopupVisible: true,
        }),
    };
    const DISMISS_DS_BUTTON = {
      label: t("digital_signature_Dismiss signed_CTA"),
      onClick: () =>
        this.setState({
          selectedItem: payrollLock,
          dismissConfirmationPopupVisible: true,
        }),
    };
    const RESEND_DS_BUTTON = {
      label: t("digital_signature_Resend_CTA"),
      onClick: () =>
        this.setState({
          selectedItem: payrollLock,
          resendConfirmationPopupVisible: true,
        }),
    };
    const CANCEL_PENDING_DS_BUTTON = {
      label: t("digital_signature_Cancel_CTA"),
      onClick: () =>
        this.setState({
          selectedItem: payrollLock,
          cancelPendingDSConfirmationPopupVisible: true,
        }),
    };

    const visibleButtons = [];
    const dropdownButtons = [LOCK_UNLOCK_BUTTON];

    if (lockStatus === PayrollLockStatus.locked) {
      visibleButtons.push(EXPORT_BUTTON);
      if (flags.payrollExportRecalculate) {
        dropdownButtons.push(SEND_TO_PAYROLL_BUTTON);
      }
    }
    if (lockStatus === PayrollLockStatus.unlocked && !digitalSignatureStatus) {
      dropdownButtons.push(REQUEST_DS_BUTTON);
    } else if (lockStatus === PayrollLockStatus.locked) {
      if (digitalSignatureStatus === DigitalSignatureStatus.canceled) {
        dropdownButtons.push(RESEND_DS_BUTTON);
      } else if (digitalSignatureStatus === DigitalSignatureStatus.pending) {
        dropdownButtons.push(CANCEL_PENDING_DS_BUTTON);
        dropdownButtons.push(RESEND_DS_BUTTON);
      } else if (digitalSignatureStatus === DigitalSignatureStatus.signRequestInProggress) {
        dropdownButtons.push(CANCEL_PENDING_DS_BUTTON);
      } else if (digitalSignatureStatus === DigitalSignatureStatus.signed) {
        dropdownButtons.push(DISMISS_DS_BUTTON);
        dropdownButtons.push(DOWNLOAD_BUTTON);
      } else if (!digitalSignatureStatus) {
        dropdownButtons.push(REQUEST_DS_BUTTON);
      }
    }

    return { dropdownButtons, visibleButtons };
  };

  render(): JSX.Element {
    const { t, flags } = this.props;
    const {
      confirmationPopupVisible,
      exportDialogVisible,
      selectedItem,
      action,
      selectedItemUuid,
      withForceRecalculate,
      resendConfirmationPopupVisible,
      cancelPendingDSConfirmationPopupVisible,
      downloadConfirmationPopupVisible,
      dismissConfirmationPopupVisible,
    } = this.state;

    const TableComponent = flags.deactivatedEmployeesTable ? DeactivatedEmployeesTable : DeactivatedEmployeesTableOld;

    return (
      <>
        <TableComponent ref={this.tableRef} {...this.props} getTableButtons={this.getTableButtons} />

        <ModalDialog
          isOpen={exportDialogVisible}
          onClose={(): void => this.setState({ exportDialogVisible: false, selectedItem: null })}
        >
          <PayrollPopupWithLayouts
            title={t("Export Payroll")}
            payrollGroup={selectedItem}
            withForceRecalculate={withForceRecalculate}
            onClose={(): void => {
              this.setState({ exportDialogVisible: false, selectedItem: null, withForceRecalculate: false });
            }}
            onYes={this.exportPayroll}
          />
        </ModalDialog>

        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={(): void => this.setState({ confirmationPopupVisible: false, action: null, selectedItemUuid: null })}
        >
          <Lightbox
            title={t("Are you sure?")}
            text={t("Are you sure you want to {{actionItem}} this period?", {
              actionItem: action === LockAction.lock ? t("Lock").toLowerCase() : t("Unlock").toLowerCase(),
            })}
            buttonYesTitle={action === LockAction.lock ? t("Lock") : t("Unlock")}
            buttonCancelTitle={t("Cancel")}
            onClose={(): void => {
              this.setState({ selectedItemUuid: null, action: null, confirmationPopupVisible: false });
            }}
            onYes={(): Promise<void> => this.toggleLock(selectedItemUuid as string, action as LockAction)}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={resendConfirmationPopupVisible}
          onClose={() => this.setState({ resendConfirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Resend Request")}
            text={t("Are you sure to re-send a request to digital signature?")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
            onClose={() => {
              this.setState({
                selectedItem: null,
                resendConfirmationPopupVisible: false,
              });
            }}
            onYes={() => selectedItem && this.resendDigitalSignatureRequest(selectedItem.uuid)}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={cancelPendingDSConfirmationPopupVisible}
          onClose={() =>
            this.setState({
              cancelPendingDSConfirmationPopupVisible: false,
              selectedItem: null,
            })
          }
        >
          <Lightbox
            title={t("Cancel Request")}
            text={t("Are you sure to cancel a request to digital signature?")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
            onClose={() => {
              this.setState({
                selectedItem: null,
                cancelPendingDSConfirmationPopupVisible: false,
              });
            }}
            onYes={() => selectedItem && this.cancelPendingDigitalSignature(selectedItem)}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={downloadConfirmationPopupVisible}
          onClose={() => this.setState({ downloadConfirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Download Reports")}
            text={t("Are you sure to download the digital signatures?")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
            onClose={() => {
              this.setState({
                selectedItem: null,
                downloadConfirmationPopupVisible: false,
              });
            }}
            onYes={() => selectedItem && this.downloadDigitalSignatureRequest(selectedItem.uuid)}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={dismissConfirmationPopupVisible}
          onClose={() => this.setState({ dismissConfirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Dismiss signed Request")}
            text={t("Are you sure to dismiss a requests to digital signature?")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
            onClose={() => {
              this.setState({
                selectedItem: null,
                dismissConfirmationPopupVisible: false,
              });
            }}
            onYes={() => selectedItem && this.dismissDigitalSignaturesRequest(selectedItem.uuid)}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation("payment")(DeactivatedEmployees)));
