import { Location, LocationValidation } from "types/models/location";
import { useState } from "react";
import { NotificationType } from "types/common";
import TypeSwitch from "./TypeSwitch";
import ValidationGPSForm from "../GPS/ValidationGPSForm";
import ValidationWiFiForm from "../WiFi/ValidationWiFiForm";
import ValidationIBeaconForm from "../IBeacon/ValidationIBeaconForm";
import ValidationIPForm from "../IP/ValidationIPForm";

interface AddNewValidationProps {
  currentLocation: Location;
  onClose: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const AddNewValidation = ({ currentLocation, onClose, setNotification, onYes }: AddNewValidationProps) => {
  const [newValidationType, setNewValidationType] = useState<LocationValidation | null>(null);

  return (
    <div>
      {!newValidationType && (
        <TypeSwitch
          onSubmit={(type: LocationValidation) => setNewValidationType(type)}
          onCancel={onClose}
          otherAvailable={
            currentLocation?.verification_methods &&
            !currentLocation.verification_methods.includes(LocationValidation.gps)
          }
          gpsAwailable={
            currentLocation?.verification_methods &&
            !currentLocation.verification_methods.find(
              (i) => i === LocationValidation.beacons || i === LocationValidation.wifiPoints,
            )
          }
        />
      )}
      {newValidationType === LocationValidation.gps && (
        <ValidationGPSForm
          currentLocation={currentLocation}
          onCancel={onClose}
          setNotification={setNotification}
          onYes={onYes}
        />
      )}
      {newValidationType === LocationValidation.wifi && (
        <ValidationWiFiForm
          currentLocation={currentLocation}
          onCancel={onClose}
          setNotification={setNotification}
          onYes={onYes}
        />
      )}
      {newValidationType === LocationValidation.ibeacon && (
        <ValidationIBeaconForm
          currentLocation={currentLocation}
          onCancel={onClose}
          setNotification={setNotification}
          onYes={onYes}
        />
      )}
      {newValidationType === LocationValidation.ip && (
        <ValidationIPForm currentLocation={currentLocation} onCancel={onClose} onYes={onYes} />
      )}
    </div>
  );
};

export default AddNewValidation;
