import axios from "axios";
import cookie from "react-cookies";
import { getAuthTokenFromApiResponse } from "utils/common";
import { clearLocalStorage } from "utils/localStorageUtils";
import Sentry from "utils/sentryUtils";
import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  CreateAccountPayload,
  CreateAccountRequestData,
  CreateAccountResponseData,
  GetPasswordValidationsReturnData,
  GetPasswordValidationsResponseData,
  LoginRequestData,
  LoginResponseData,
} from "./types";

type TODORequestData = unknown;

type TODOResponseData = unknown;

export function login(email: string, password: string) {
  cleanAuthData();

  return new Promise<LoginResponseData>((res, rej) => {
    sendRequest<LoginRequestData, LoginResponseData>(
      {
        email,
        password,
      },
      "post",
      "/sessions",
    )
      .then((resp) => {
        const token = getAuthTokenFromApiResponse(resp);
        if (token) {
          localStorage.setItem("oi_auth", token);
        } else {
          cookie.save("userEmail", resp.email, { path: "/", secure: true });
          cookie.save("userToken", resp.authentication_token, { path: "/", secure: true });
        }

        res(resp);
      })
      .catch(() => {
        rej(new Error("Username and password don’t match"));
      });
  });
}

export function adminLoginAs(employeeEmail: string, adminToken: string, systemAdminUuid: string) {
  cleanAuthData();

  return new Promise<TODOResponseData>((res, rej) => {
    sendRequest<TODORequestData, TODOResponseData>(
      {
        email: employeeEmail,
        login_token: adminToken,
        system_admin_uuid: systemAdminUuid,
      },
      "post",
      "/sessions",
    )
      .then((resp) => {
        const authToken = getAuthTokenFromApiResponse(resp);
        if (authToken) {
          localStorage.setItem("oi_auth", authToken);
        } else {
          cookie.save("userEmail", resp.email, { path: "/", secure: true });
          cookie.save("userToken", resp.authentication_token, { path: "/", secure: true });
        }
        res("success");
      })
      .catch((err) => {
        rej(err);
      });
  });
}

export function logOut() {
  return new Promise<"success">((res, rej) => {
    sendRequest({}, "delete", "/sessions")
      .then(() => {
        res("success");
      })
      .catch((e) => {
        Sentry.addBreadcrumb("ErrorDuringLogout", e);
        rej(new Error("Error during logout"));
      })
      .finally(() => {
        cleanAuthData();
        clearLocalStorage();

        window.location = "/" as unknown as Location;
      });
  });
}

export function resetPassword(email: string): APIResponse<TODOResponseData> {
  return sendRequest({}, "get", `/password/reset_password?email=${encodeURIComponent(email)}`);
}

export function changePassword(password: string, resetPasswordToken: string): APIResponse<TODOResponseData> {
  return sendRequest(
    {
      password,
      reset_password_token: resetPasswordToken,
    },
    "post",
    "/password/change_password",
  );
}

export async function getPasswordValidation(email: string): Promise<GetPasswordValidationsReturnData> {
  const params = `?${new URLSearchParams({ email }).toString()}`;

  // without token
  const resp = await axios({
    url: `${CONFIG.protocol}${CONFIG.api}/password/password_validation${params}`,
  });
  const data: GetPasswordValidationsResponseData = resp?.data || {};
  const { entire_password: entirePassword, validations } = data;

  return { entirePassword, validations };
}

export function createAccount(data: CreateAccountRequestData) {
  cleanAuthData();

  return new Promise<CreateAccountResponseData>((res, rej) => {
    sendRequest<CreateAccountPayload, CreateAccountResponseData>(
      {
        user: {
          full_name: data.fullName,
          user_account_attributes: {
            password: data.password,
            email: data.email,
          },
          devices_attributes: [{ phone_number: `+${data.phoneNumber}` }],
        },
        token: data.token,
      },
      "post",
      "/register",
    )
      .then((r) => {
        if (r?.user_account) {
          cookie.save("userEmail", r.user_account.email, { path: "/", secure: true });
          cookie.save("userToken", r.user_account.authentication_token, {
            path: "/",
            secure: true,
          });

          res(r);
        } else {
          rej(r);
        }
      })
      .catch((e) => {
        rej(e);
      });
  });
}
export function cleanAuthData() {
  localStorage.removeItem("gs_profile");
  cookie.remove("userProfile", { path: "/" });
  cookie.remove("userEmail", { path: "/" });
  cookie.remove("userToken", { path: "/" });
  localStorage.removeItem("oi_auth");
}
