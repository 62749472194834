import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import * as images from "components/svg-images";
import { Periods } from "types/models/dashboard";
import { ControlWrapper, ControlSelectedValue } from "../styled";

interface PeroidSelectorProps {
  value: Periods;
  onSelect: (value: Periods) => void;
}

const PeroidSelector = ({ onSelect, value }: PeroidSelectorProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const options = [
    { value: Periods.thisYear, label: t("This year") },
    { value: Periods.pastYear, label: t("Past year") },
    { value: Periods.thisMonth, label: t("This month") },
    { value: Periods.lastMonth, label: t("Last month") },
    { value: Periods.last3Month, label: t("Last 3 month") },
    { value: Periods.last6Month, label: t("Last 6 month") },
    { value: Periods.last12Month, label: t("Last 12 month") },
    { value: Periods.last24Month, label: t("Last 24 month") },
  ];
  const label = options.find((o) => o.value === value)?.label || "";

  return (
    <ControlWrapper>
      <SimpleMenu onChange={onSelect} options={options} useClick>
        <ControlSelectedValue>
          <span>{label}</span> {images.smallArrowDown({ width: "7px", height: "4px" })}
        </ControlSelectedValue>
      </SimpleMenu>
    </ControlWrapper>
  );
};

export default PeroidSelector;
