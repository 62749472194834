import { useTranslation } from "react-i18next";
import { stylesheet } from "astroturf";
import { Fragment, useEffect } from "react";
import { TranslationNamespaces } from "types/translationNamespaces";
import Tooltip from "components/UI/Tooltip";
import ga, { GaIntegrationStatus } from "utils/ga";
import { useHistory } from "react-router-dom";
import { ApplicationsService, IntegrationObject } from "../Applications/ApplicationsService";
import MainPage from "./MainPage";

const styles = stylesheet`
  .Wrapper {
    margin-top: 180px;
  }
  .ContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 60px 0;
  }
  .Content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .Title {
    margin: 0;
    font-weight: var(--typography-font-weight-default);
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    color: var(--colors-surface-900-p);
  }
  .SubTitle {
    margin: 8px 30px 32px;
    text-align: center;
    font-weight: var(--typography-font-weight-default);
    font-size: 16px;
    color: var(--colors-surface-600);
  }
  .List {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .ListItem {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      border: 2px solid var(--colors-surface-150);
      border-radius: var(--shapes-border-radius-default);
      position: relative;
      cursor: pointer;
      &:hover {
        background: var(--colors-surface-50);
      }
      .AppCommingSoon {
        position: absolute;
        top: 18px;
        inset-inline-end: 20px;
        background: var(--colors-primary-50);
        color: var(--colors-primary-500-p);
        padding: 7px 12px;
        border-radius: 40px;
        font-weight: var(--typography-font-weight-bold);
        font-size: 10px;
        text-transform: uppercase;
      }
    }
    &.ListGrid {
      grid-template-columns: 1fr 1fr;
      .soon {
        cursor: not-allowed;
        background: inherit;
        &:hover {
          background: inherit;
        }
      }
    }
  }
  .Link {
    cursor: pointer;
    margin-top: 32px;
    display: inline-block;
  }
  .BtnGroup {
    display: grid;
    margin: 48px 0;
  }
  .SkipButton, .ContinueButton {
    margin: 0 auto;
    width: 50%;
    border-radius: 100px;
    height: 48px;
    text-align: center;
    outline: none;
    border: none;
    font-weight: var(--typography-font-weight-bold);
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    &:disabled, &[disabled] {
      cursor: default;
      opacity: .5;
    }
  }
  .SkipButton {
    background: var(--colors-surface-100);
    color: var(--colors-surface-900-p);
  }
  .ContinueButton {
    background: var(--colors-primary-500-p);
    color: var(--colors-surface-0);
  }
`;

export const IntegrationPage = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const history = useHistory();
  const integrations = ApplicationsService.integrationsArray;
  const skip = () => {
    localStorage.removeItem("onboarding_integration");
    window.location.href = "/";
  };

  useEffect(() => {
    const pendingIntegration = localStorage.getItem("onboarding_integration");
    if (pendingIntegration) {
      history.push(`/integration/${pendingIntegration}`);
    }
  }, []);

  const openIntegration = async (integration: IntegrationObject) => {
    if (integration.commingSoon) return;
    localStorage.setItem("onboarding_integration", integration.name.toLowerCase());
    history.push(`/integration/${integration.name.toLowerCase()}`);
  };

  return (
    <>
      <MainPage topRowText="" customContent>
        <div className={styles.Wrapper}>
          <section className={styles.ContentWrapper}>
            <div className={styles.Content}>
              <h1 className={styles.Title}>{t("Integrate with")}</h1>
              <h3 className={styles.SubTitle}>{t("Integration_page_description")}</h3>
              <div className={`${styles.List} ${styles.ListGrid}`}>
                {integrations.map((integration) => (
                  <Fragment key={integration.name}>
                    <div
                      data-tip
                      data-for={integration.commingSoon ? "integration-soon" : null}
                      key={integration.name}
                      className={`${styles.ListItem} ${integration.commingSoon ? styles.soon : ""}`}
                      onClick={() => openIntegration(integration)}
                    >
                      {integration.logoComponent}
                    </div>
                  </Fragment>
                ))}
                <Tooltip id="integration-soon" offset={{ top: -8 }}>
                  {t("Will be available soon")}
                </Tooltip>
              </div>
              <div className={styles.BtnGroup}>
                <button
                  type="button"
                  className={styles.SkipButton}
                  onClick={() => {
                    ga.trackSelectIntegration({
                      integration_name: "",
                      status: GaIntegrationStatus.skip,
                    });
                    skip();
                  }}
                >
                  {t("skip_btn_text")}
                </button>
              </div>
            </div>
          </section>
        </div>
      </MainPage>
    </>
  );
};
