import FieldWrapper from "components/UI/FieldWrapper";
import styled from "styled-components";
import { Request, RequestSubtypeSpecificFieldTypes } from "types/models/request";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { minsToHrsMins } from "utils/common";

const FieldValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
`;

const FieldsWrapper = styled.div`
  display: flex;
  gap: 25px;
`;

type SubtypeSpecificFieldsOvetimeEnableDayProps = {
  request: Request;
};

const SubtypeSpecificFieldsOvetimeEnableDay = ({ request }: SubtypeSpecificFieldsOvetimeEnableDayProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  if (!request.subtypeSpecificFields?.find((field) => field.name === RequestSubtypeSpecificFieldTypes.dayRange)) {
    return null;
  }

  const dataRangeField = request.subtypeSpecificFields.find(
    (field) => field.name === RequestSubtypeSpecificFieldTypes.dayRange,
  )!;

  const { from, to } = dataRangeField.value;

  return (
    <FieldsWrapper>
      <FieldWrapper fieldName={t("From")} width="100%" fieldTitleMarginTop={20}>
        <FieldValue>{minsToHrsMins(from)}</FieldValue>
      </FieldWrapper>
      <FieldWrapper fieldName={t("To")} width="100%" fieldTitleMarginTop={20}>
        <FieldValue>{minsToHrsMins(to)}</FieldValue>
      </FieldWrapper>
    </FieldsWrapper>
  );
};

export default SubtypeSpecificFieldsOvetimeEnableDay;
