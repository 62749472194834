import React, { Component } from "react";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import { stylesheet } from "astroturf";
import { SubscriptionApi } from "utils/api/subscription";
import { Coupon, Feature, FeatureLabel, Interval, SubscriptionStatus } from "types/models/subscription";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import CheckboxControl from "components/UI/CheckboxControl";
import FieldWrapper from "components/UI/FieldWrapper";
import { Subscribe } from "unstated";
import ModalDialog from "components/UI/ModalDialog";
import ga from "utils/ga";
import Tooltip from "components/UI/Tooltip";
import ModalDialogFullPage from "components/UI/ModalDialogFullPage";
import { GlobalStoreCompany } from "types/models/company";
import { ReactComponent as IconSuccessBig } from "../icons/feature_check_ic.svg";
import { BillingService, BUNDLE_PROJECTS_DISCOUNT_PERCENT } from "../BillingService";
import PricingContainer, { ProductItem } from "../PricingContainer";
import UpgradePlanModal from "../Modals/UpgradePlanModal";
import UpgradeSeatsModal from "../Modals/UpgradeSeatsModal";
import NeedOwnerPermissionsModal from "../Modals/NeedOwnerPermissionsModal";
import SwitchToAnnualModal from "../Modals/SwitchToAnnualModal";
import UpgradeBundleSeatsModal from "../Modals/UpgradeBundleSeatsModal";

const Payment = React.lazy(() => import("../Payment/Payment"));

const styles = stylesheet`@import "../billing.scss"`;

type ProductSelectorProps = WithTranslation;

type ProductSelectorState = {
  paymentPopupVisible: boolean;
  updatePlanPopupVisible: boolean;
  updateSeatsPopupVisible: boolean;
  switchToAnnualPopupVisible: boolean;
  upgradeBundlePopupVisible: boolean;
  paymentError: string | null;
  publicKey?: string;
  loading: boolean;
};

type CardMainInfoProps = {
  interval: Interval;
  tiersArr: ProductItem[];
  coupon: Coupon | null;
  pricing: any;
  initialDiscount: boolean;
};

const isFreeTier = (tiers: ProductItem[]) =>
  tiers.every(
    (tier) => [null, 0].includes(tier.selectedTier?.unit_amount) && [null, 0].includes(tier.selectedTier?.flat_amount),
  );
const isTargetPlan = (productItem: ProductItem, feature: Feature) => productItem.product.features[0] === feature;
const checkForBundleTALessProjectsError = (tiersArr: ProductItem[]) => {
  if (tiersArr.length !== 2) return false;
  const taPlan = tiersArr.find((tier) => isTargetPlan(tier, Feature.TA));
  const projectsPlan = tiersArr.find((tier) => isTargetPlan(tier, Feature.Project));
  return !!taPlan && !!projectsPlan && taPlan.selectedTier.up_to! < projectsPlan.selectedTier.up_to!;
};

const CardMainInfo = ({ interval, tiersArr, coupon, pricing, initialDiscount }: CardMainInfoProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);

  const isFree = isFreeTier(tiersArr);
  const isBundle = tiersArr.length === 2;
  const defaultPrice = initialDiscount
    ? tiersArr.reduce((acc, tier) => {
        return acc + BillingService.calculateUnitPrice(tier!.selectedTier, interval, null, tier.product.externalId);
      }, 0)
    : 0;
  const price = tiersArr.reduce((acc, tier) => {
    if (isBundle && !initialDiscount) {
      const bundleDiscount = isTargetPlan(tier, Feature.Project) ? BUNDLE_PROJECTS_DISCOUNT_PERCENT : 0;
      return (
        acc + BillingService.calculateUnitPrice(tier!.selectedTier, interval, { percentOff: bundleDiscount } as Coupon)
      );
    }
    return (
      acc +
      BillingService.calculateUnitPrice(
        tier!.selectedTier,
        interval,
        initialDiscount ? ({ percentOff: 15 } as Coupon) : coupon,
        tier.product.externalId,
      )
    );
  }, 0);
  const totalPrice = tiersArr.reduce((acc, tier) => {
    if (isBundle && !initialDiscount) {
      const bundleDiscount = isTargetPlan(tier, Feature.Project) ? BUNDLE_PROJECTS_DISCOUNT_PERCENT : 0;
      return (
        acc +
        BillingService.calculateTotalPerMonth(tier.selectedTier, interval, { percentOff: bundleDiscount } as Coupon)
      );
    }
    return (
      acc +
      BillingService.calculateTotalPerMonth(
        tier.selectedTier,
        interval,
        initialDiscount ? ({ percentOff: 15 } as Coupon) : coupon,
        tier.product.externalId,
      )
    );
  }, 0);
  const activeSubscription =
    BillingService.findSubscriptions(BillingService.getCompanySubscriptions(), SubscriptionStatus.PastDue) ||
    BillingService.findSubscriptions(BillingService.getCompanySubscriptions(), SubscriptionStatus.Active);

  const getFreeSeatsNumber = () => {
    if (isBundle) return "10/5";
    return isTargetPlan(tiersArr[0], Feature.TA) ? 10 : 5;
  };

  const availableTiers = (productItem: ProductItem) => {
    if (!activeSubscription) return productItem.tierSelector;
    const usedSeatsNumber =
      activeSubscription.items.find((i) => productItem.product.features[0] === i.feature)?.maxSeats || 0;
    return productItem.tierSelector!.filter((tiers) => tiers.value >= usedSeatsNumber);
  };

  return (
    <>
      <div className={`${styles.CardMainInfo}`}>
        {initialDiscount ? <div className={styles.InitialDiscount}>{t("initial_discount_label")}</div> : null}
        {initialDiscount && defaultPrice ? (
          <div className={styles.DefaultPrice}>
            {defaultPrice.toLocaleString("en-US", {
              style: "currency",
              currency: tiersArr[0].price?.currency,
            })}
          </div>
        ) : null}
        <div className={`${styles.Flex} ${styles.JustifyCenter}`}>
          <span className={styles.CardPrice}>
            {price.toLocaleString("en-US", {
              style: "currency",
              currency: tiersArr[0].price?.currency,
            })}
          </span>
          <span className={`${styles.CardPriceSeats} ${styles.TextSecondary}`}>
            {t("seat")} / {t("month")}
          </span>
        </div>
        {!isFree ? (
          <div className={styles.MarginTop24}>
            {t("Total")}{" "}
            {totalPrice.toLocaleString("en-US", {
              style: "currency",
              currency: tiersArr[0].price?.currency,
            })}{" "}
            / {t("month")}
          </div>
        ) : (
          <div className={styles.MarginTop24}>
            {isBundle
              ? t("free_subscription_label_bundle")
              : t("free_subscription_label {{planFreeSeats}}", {
                  interpolation: { escapeValue: false },
                  planFreeSeats: getFreeSeatsNumber(),
                })}
          </div>
        )}
        {!isFree ? (
          <div className={`${styles.TextSecondary} ${styles.MarginTop4}`}>
            {interval === Interval.Year ? t("Billed Annually") : t("Billed Monthly")}
          </div>
        ) : null}
      </div>
      {isBundle ? (
        <div className={styles.BundleSelectsWrapper}>
          {tiersArr.map((tier) => (
            <div
              key={tier.product.uuid}
              className={`${styles.BundleSeats} ${styles.WhiteSelect} ${styles.Horizontal} ${
                isTargetPlan(tier, Feature.Project) && checkForBundleTALessProjectsError(tiersArr)
                  ? styles.SelectError
                  : ""
              }`}
            >
              <div
                data-tip
                data-for={
                  isTargetPlan(tier, Feature.Project) && checkForBundleTALessProjectsError(tiersArr)
                    ? "bundle-size-error"
                    : null
                }
              >
                <Select
                  disabled={!tier.tierSelector || tier.tierSelector.length < 1}
                  value={tier.selectedTier?.up_to}
                  onChange={(val) => {
                    pricing.changeTier(tier, val);
                  }}
                  options={availableTiers(tier)!}
                />
              </div>
              <div className={styles.Label}>{t(FeatureLabel[tier.product.features[0]])}</div>
              {isTargetPlan(tier, Feature.Project) && !initialDiscount ? (
                <div className={styles.Badge}>-{BUNDLE_PROJECTS_DISCOUNT_PERCENT}%</div>
              ) : null}
              {isTargetPlan(tier, Feature.Project) &&
              !initialDiscount &&
              checkForBundleTALessProjectsError(tiersArr) ? (
                <Tooltip className={styles.BundleErrorTooltip} id="bundle-size-error">
                  {t("pricing_bundle_ta_less_projects_error")}
                </Tooltip>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

const CTAButton = ({
  loading,
  config,
  disabled,
}: {
  loading: boolean;
  config: { action: () => void; state: ButtonState; label: string; disabled?: boolean } | null;
  disabled: boolean;
}) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  if (!config) return <div style={{ marginBottom: "8px", lineHeight: "36px", marginTop: "32px" }}>&nbsp;</div>;
  return (
    <Button
      disabled={loading || disabled || config.disabled}
      value={t(config.label)}
      style={{ width: "100%", marginBottom: "8px", marginTop: "32px" }}
      state={config.state}
      onClick={config.action}
    />
  );
};

class ProductSelector extends Component<ProductSelectorProps, ProductSelectorState> {
  constructor(props: ProductSelectorProps) {
    super(props);

    this.state = {
      loading: false,
      paymentPopupVisible: false,
      updatePlanPopupVisible: false,
      updateSeatsPopupVisible: false,
      switchToAnnualPopupVisible: false,
      upgradeBundlePopupVisible: false,
      paymentError: null,
    };
  }

  buy = async () => {
    this.setState({ loading: true });
    await SubscriptionApi.getPublicKey(PricingContainer.state.companyUuid)
      .then((content) => {
        this.setState({
          paymentPopupVisible: true,
          publicKey: content,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          paymentError: error.message,
          loading: false,
        });
      });
  };

  render() {
    const { t } = this.props;
    const {
      paymentPopupVisible,
      updateSeatsPopupVisible,
      updatePlanPopupVisible,
      switchToAnnualPopupVisible,
      upgradeBundlePopupVisible,
      publicKey,
      loading,
      paymentError,
    } = this.state;
    const { coupon, activeSubscription, companyUuid } = PricingContainer.state;
    const { productItems } = PricingContainer;

    const isCurrentPlan = productItems[0]?.product.usedInSubscription;
    const triallingSubscription = BillingService.findSubscriptions(
      BillingService.getCompanySubscriptions(),
      SubscriptionStatus.Trialing,
    );
    const actionDisabled = checkForBundleTALessProjectsError(productItems);
    const buttonConfig = () => {
      if (BillingService.isEnterpriseCustomer()) {
        return {
          action: () => {
            PricingContainer.bookCall();
            ga.trackLead(Feature.Bundle);
          },
          label: "Contact Sales",
          state: ButtonState.primary,
        };
      }
      if (
        activeSubscription &&
        productItems.length === 1 &&
        (productItems as ProductItem[])[0].product.usedInSubscription
      ) {
        return {
          action: () => this.setState({ updateSeatsPopupVisible: true }),
          label: "Add seats",
          state: ButtonState.primary,
        };
      }
      if (
        activeSubscription &&
        productItems.length === 1 &&
        !(productItems as ProductItem[])[0].product.usedInSubscription
      ) {
        return {
          action: () => this.setState({ updatePlanPopupVisible: true }),
          label: "Add to subscription",
          state: ButtonState.primary,
        };
      }
      if (activeSubscription && productItems.length === 2) {
        return {
          action: () => this.setState({ upgradeBundlePopupVisible: true }),
          label: "Add seats",
          state: ButtonState.primary,
        };
      }

      if (!activeSubscription && productItems.some((pi) => pi.selectedTier?.up_to === 250)) {
        return {
          action: () => {
            PricingContainer.bookCall();
            ga.trackLead(productItems[0].product.features[0]);
          },
          label: "Contact Sales",
          state: ButtonState.primary,
        };
      }

      if (!triallingSubscription && isFreeTier(productItems)) {
        return {
          action: () => this.buy(),
          label: "Select this plan",
          state: ButtonState.primary,
          disabled: true,
        };
      }

      return {
        action: () => this.buy(),
        label: "Select this plan",
        state: ButtonState.primary,
      };
    };
    const isOwner = BillingService.checkIsCompanyAdmin(true);
    const openSwitchToAnnualModal = () => this.setState({ switchToAnnualPopupVisible: true });
    const subscriptions = BillingService.getSubscriptionsFromLocalStorage;
    const trialSubscription = subscriptions?.find(
      (subscription) =>
        [SubscriptionStatus.Trialing, SubscriptionStatus.Free].includes(subscription.status) &&
        !subscription.externalId,
    );
    const usedTrialItem =
      productItems.length > 0 &&
      trialSubscription?.items.find((item) => item.feature === productItems[0].product.features[0]);
    const showTrialUpgradeBtn = productItems.length === 1 && trialSubscription && !usedTrialItem;
    const addToTrial = async () => {
      const payload = {
        ta: 10,
        projects: 5,
      };
      await SubscriptionApi.setTrialSubscription(companyUuid, payload);
      await BillingService.getInitialCustomerData(companyUuid);
      await PricingContainer.getSubscriptions();
    };
    const initialDiscount = BillingService.isInitialDiscountApplicable(
      (window.global_store.company as GlobalStoreCompany)?.employee_count?.toString() as string,
    );

    return (
      <Subscribe to={[PricingContainer]}>
        {(pricing: typeof PricingContainer) => (
          <>
            <div className={`${styles.Flex} ${styles.JustifyBetween} ${styles.TopSelectors}`}>
              {pricing.productItems.length === 1 && !pricing.productItems[0].product.usedInSubscription ? (
                <FieldWrapper
                  className={`${styles.SelectSeats} ${styles.JustifyBetween} ${styles.Flex} ${styles.WhiteSelect} ${styles.Horizontal}`}
                  width="190px"
                  fieldName={t("Choose team size")}
                  fieldTitleMarginBottom={0}
                  fieldTitleMarginTop={0}
                >
                  <Select
                    disabled={!pricing.productItems[0].tierSelector || pricing.productItems[0].tierSelector.length < 1}
                    value={pricing.productItems[0].selectedTier?.up_to}
                    onChange={(val) => pricing.changeTier(pricing.productItems[0], val)}
                    options={pricing.productItems[0].tierSelector!}
                  />
                </FieldWrapper>
              ) : (
                <div />
              )}
              {!activeSubscription ? (
                <div className={styles.Flex}>
                  <div className={styles.IntervalSwitch}>
                    <span
                      className={`${styles.SwitchLabel} ${
                        pricing.state.interval === Interval.Month ? styles.SwitchActive : ""
                      }`}
                    >
                      {t("Monthly")}
                    </span>
                    <CheckboxControl
                      id="billingSelectorAnnual"
                      disabled={activeSubscription}
                      checked={pricing.state.interval === Interval.Year}
                      onChange={(isAnnual: boolean) =>
                        pricing.setInterval(isAnnual ? Interval.Year : Interval.Month, t)
                      }
                    />
                    <span
                      className={`${styles.SwitchLabel} ${
                        pricing.state.interval === Interval.Year ? styles.SwitchActive : ""
                      }`}
                    >
                      {t("Annual")}
                      <span className={styles.DiscountLabel}>{t("discount_label_with_percent")}</span>
                    </span>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>

            {pricing.productItems.length > 0 ? (
              <div
                style={{ marginTop: "40px" }}
                className={`${styles.Flex} ${styles.JustifyCenter} ${styles.AlignStretch}`}
              >
                <div style={{ marginInlineEnd: "16px" }} className={`${styles.Card} ${styles.BaseCardBorder}`}>
                  <div className={styles.CurrentPlan}>
                    {isCurrentPlan && !BillingService.isEnterpriseCustomer() ? t("Current plan") : ""}
                  </div>
                  <div className={`${styles.CardTitle} ${styles.Active}`}>{t("Professional")}</div>
                  <CardMainInfo
                    interval={pricing.state.interval}
                    tiersArr={pricing.productItems}
                    coupon={coupon}
                    pricing={pricing}
                    initialDiscount={initialDiscount}
                  />
                  <CTAButton loading={loading} config={buttonConfig()} disabled={actionDisabled} />
                  {pricing.state.interval === Interval.Month &&
                    pricing.productItems[0].product.usedInSubscription &&
                    pricing.productItems.length === 1 && (
                      <a className={styles.Link} onClick={() => openSwitchToAnnualModal()}>
                        {t("Switch to Annual")}
                      </a>
                    )}
                  {showTrialUpgradeBtn ? (
                    <a className={styles.Link} onClick={() => addToTrial()}>
                      {t("add_product_to_trial_CTA")}
                    </a>
                  ) : null}
                  <ul className={styles.CardFeatures}>
                    {pricing.productItems
                      .reduce((acc: string[], pi) => [...acc, ...pi.product.uiFeatures], [])
                      .map((f) => (
                        <li key={f}>
                          <IconSuccessBig />
                          {t(f)}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className={`${styles.Card} ${styles.CardEnterprise}`}>
                  <div className={styles.CurrentPlan}>
                    {isCurrentPlan && BillingService.isEnterpriseCustomer() ? t("Current plan") : ""}
                  </div>
                  <div className={styles.CardTitle}>{t("Enterprise")}</div>
                  <div className={`${styles.CardMainInfo}`}>
                    <div className={styles.EnterpriseTitle}>{t("Contact us")}</div>
                    <div className={styles.EnterpriseSubTitle}>
                      {t("All the features you need to keep your business running efficiently and effectively")}
                    </div>
                  </div>
                  <Button
                    value={t("pricing_enterprise_CTA")}
                    style={{ width: "100%", marginBottom: "8px", marginTop: "32px" }}
                    state={ButtonState.secondary}
                    onClick={() => {
                      const feature =
                        pricing.productItems.length > 1 ? Feature.Bundle : pricing.productItems[0].product.features[0];

                      ga.trackEnterpriseLead(feature);
                      pricing.bookCall();
                    }}
                  />
                  {((pricing.state.interval === Interval.Month &&
                    pricing.productItems[0].product.usedInSubscription &&
                    pricing.productItems.length === 1) ||
                    showTrialUpgradeBtn) && <div className={styles.Link}>&nbsp;</div>}
                  <ul className={styles.CardFeatures}>
                    {[
                      ...pricing.productItems.reduce((acc: Set<string>, pi) => {
                        pi.product.enterpriseFeatures.map((f) => acc.add(t(f)));
                        return acc;
                      }, new Set<string>()),
                    ].map((f) => (
                      <li key={f}>
                        <IconSuccessBig />
                        {t(f)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            {isOwner ? (
              <ModalDialogFullPage
                isOpen={paymentPopupVisible}
                onClose={() => this.setState({ paymentPopupVisible: false })}
              >
                <Payment
                  activeSubscription={activeSubscription}
                  companyUuid={pricing.state.companyUuid}
                  publicKey={publicKey!}
                  items={pricing.productItems}
                  interval={pricing.state.interval!}
                />
              </ModalDialogFullPage>
            ) : (
              <ModalDialog
                top="120px"
                preventOutsideClose
                onClose={() => this.setState({ paymentPopupVisible: false })}
                isOpen={paymentPopupVisible}
                width={464}
              >
                <NeedOwnerPermissionsModal close={() => this.setState({ paymentPopupVisible: false })} />
              </ModalDialog>
            )}

            <ModalDialog
              onClose={() => this.setState({ paymentError: null })}
              isOpen={!!paymentError}
              width={320}
              top="120px"
            >
              <div className={`${styles.Flex} ${styles.JustifyCenter} ${styles.ErrorModal}`}>{t(paymentError!)}</div>
            </ModalDialog>

            <ModalDialog
              top="120px"
              preventOutsideClose
              onClose={() => this.setState({ updatePlanPopupVisible: false })}
              isOpen={updatePlanPopupVisible}
              width={464}
            >
              {updatePlanPopupVisible && (
                <>
                  {isOwner ? (
                    <UpgradePlanModal
                      companyUuid={pricing.state.companyUuid}
                      subscription={activeSubscription!}
                      productItem={pricing.productItems[0]}
                      interval={pricing.state.interval}
                      coupon={coupon}
                      close={() => this.setState({ updatePlanPopupVisible: false })}
                    />
                  ) : (
                    <NeedOwnerPermissionsModal close={() => this.setState({ updatePlanPopupVisible: false })} />
                  )}
                </>
              )}
            </ModalDialog>

            <ModalDialog
              top="120px"
              preventOutsideClose
              onClose={() => this.setState({ upgradeBundlePopupVisible: false })}
              isOpen={upgradeBundlePopupVisible}
              width={464}
            >
              {upgradeBundlePopupVisible && (
                <>
                  {isOwner ? (
                    <UpgradeBundleSeatsModal
                      companyUuid={pricing.state.companyUuid}
                      subscription={activeSubscription!}
                      productItems={pricing.productItems}
                      interval={pricing.state.interval}
                      coupon={coupon}
                      close={() => this.setState({ upgradeBundlePopupVisible: false })}
                    />
                  ) : (
                    <NeedOwnerPermissionsModal close={() => this.setState({ upgradeBundlePopupVisible: false })} />
                  )}
                </>
              )}
            </ModalDialog>

            <ModalDialog
              top="120px"
              preventOutsideClose
              onClose={() => this.setState({ updateSeatsPopupVisible: false })}
              isOpen={updateSeatsPopupVisible}
              width={464}
            >
              {updateSeatsPopupVisible && (
                <>
                  {isOwner ? (
                    <UpgradeSeatsModal
                      companyUuid={pricing.state.companyUuid}
                      coupon={coupon}
                      close={() => this.setState({ updateSeatsPopupVisible: false })}
                      productItem={pricing.productItems[0]}
                      currentSeatsNumber={pricing.state.currentSeatsNumber!}
                      interval={pricing.state.interval}
                      subscription={activeSubscription!}
                    />
                  ) : (
                    <NeedOwnerPermissionsModal close={() => this.setState({ updateSeatsPopupVisible: false })} />
                  )}
                </>
              )}
            </ModalDialog>
            <ModalDialog
              top="120px"
              preventOutsideClose
              onClose={() => this.setState({ switchToAnnualPopupVisible: false })}
              isOpen={switchToAnnualPopupVisible}
              width={464}
            >
              {switchToAnnualPopupVisible && (
                <>
                  {isOwner ? (
                    <SwitchToAnnualModal
                      companyUuid={PricingContainer.state.companyUuid}
                      coupon={PricingContainer.state.coupon}
                      close={() => this.setState({ switchToAnnualPopupVisible: false })}
                      products={PricingContainer.state.products}
                      subscription={PricingContainer.state.activeSubscription!}
                    />
                  ) : (
                    <NeedOwnerPermissionsModal close={() => this.setState({ switchToAnnualPopupVisible: false })} />
                  )}
                </>
              )}
            </ModalDialog>
          </>
        )}
      </Subscribe>
    );
  }
}

export default withTranslation(TranslationNamespaces.subscription)(ProductSelector);
