import { PropsWithChildren } from "react";
import styled from "styled-components";

const Dialog = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  inset-inline-end: ${(props) => (props.isOpen ? "0" : "-401px")};
  width: 0;
  height: 100%;
  overflow-y: auto;
  background: var(--colors-default);
  transition: width 0.15s ease;

  // PROD-10444 print punch details receipt
  @media print {
    inset-inline-start: ${(props) => (props.isOpen ? "0" : "-401px")};
  }
`;

interface SidePopupDialogProps extends PropsWithChildren {
  width: number;
  isOpen: boolean;
}

const SidePopupDialog = ({ isOpen, children, width = 400 }: SidePopupDialogProps) => (
  <Dialog isOpen={isOpen} style={{ width }}>
    {children}
  </Dialog>
);

export default SidePopupDialog;
