import { iColumn } from "components/TableCommon";
import Tooltip from "components/UI/Tooltip";
import styled from "styled-components";
import { iCellInfo } from "utils/tableHelpers";

interface ColumnHeaderWithDescrProps<T> {
  data?: iCellInfo<T>;
  column: iColumn<T>;
  description: string;
}

const Label = styled.div``;

/**
 * Show tooltip on hover. Do not forget to set pointer-events in .table-common__th div to auto
 */
const ColumnHeaderWithDescr = <T extends any>({ data, column, description }: ColumnHeaderWithDescrProps<T>) => {
  const tooltipId = `header-descr-${column.label}`;

  return (
    <>
      <Label data-tip data-for={tooltipId}>
        {column.label}
      </Label>
      <Tooltip id={tooltipId}>{description}</Tooltip>
    </>
  );
};

export default ColumnHeaderWithDescr;
