import { Component, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import NotificationRow from "components/NotificationRow";
import { PageWrapper, Header, Title } from "components/styled/Page";
import { updateCompany, getCurrentCompany, uploadCompanyLogo, uploadCompanyDocumentLogo } from "utils/apiHelpers";
import { CNPJ } from "cpf_cnpj";
import { getTitle, getCompanyTaxPayerType } from "utils/common";
import { CompanyTaxPayerTypes, NotificationType } from "types/common";
import { CompanyCurrency } from "types/models/company";
import { UpdateCompanyRequestData } from "utils/api/types";
import * as oitchauDb from "utils/oitchauDb";
import { TranslationNamespaces } from "types/translationNamespaces";
import CompanyProfileLogoUploader from "./CompanyProfileLogoUploader";
import GeneralTab, { OnChangePayload, CompanyProfileGeneralTabErrors } from "./GeneralTab";

enum CompanyProfileTab {
  general = "general",
  datetime = "datetime",
}

type CompanyProfileProps = WithTranslation;

interface CompanyProfileState {
  activeTab: CompanyProfileTab;
  notification: string | null;
  notificationType: NotificationType | null;
  isLoading: boolean;
  errors: CompanyProfileGeneralTabErrors;
  name: string;
  fullName: string;
  taxPayerId: string;
  category: string;
  currency: CompanyCurrency;
  logo: string;
  documentLogo: string;
  uploadedLogo: File | null;
  uploadedDocumentLogo: File | null;
}

class Group extends Component<CompanyProfileProps, CompanyProfileState> {
  constructor(props: CompanyProfileProps) {
    super(props);
    const { t } = props;

    this.state = {
      activeTab: CompanyProfileTab.general,
      notification: null,
      notificationType: null,
      isLoading: true,
      errors: null,
      name: "",
      fullName: "",
      taxPayerId: "",
      category: "",
      currency: CompanyCurrency.USD,
      logo: "",
      documentLogo: "",
      uploadedLogo: null,
      uploadedDocumentLogo: null,
    };

    document.title = getTitle(t("Edit your company profile"));
  }

  componentDidMount = (): void => {
    void this.fetchData();
  };

  fetchData = async (): Promise<void> => {
    const company = await getCurrentCompany(true);

    if (company) {
      this.setState({
        isLoading: false,
        name: company.name,
        taxPayerId: company.tax_payer_id || "",
        logo: company.logo_id,
        documentLogo: company.document_logo_id,
        fullName: company.legal_name,
        category: company.industry_type_id,
        currency: company.currency,
      });
    }
  };

  getContent = (activeTab: CompanyProfileTab): ReactNode => {
    let content = null;
    const {
      name,
      isLoading,
      taxPayerId,
      logo,
      documentLogo,
      fullName,
      category,
      errors,
      uploadedDocumentLogo,
      currency,
    } = this.state;

    switch (activeTab) {
      case CompanyProfileTab.general:
        content = (
          <GeneralTab
            onChange={this.onCompanyFieldsChange}
            onSave={this.onSave}
            isLoading={isLoading}
            errors={errors}
            uploadedDocumentLogo={uploadedDocumentLogo}
            company={{
              name,
              taxPayerId,
              logo,
              documentLogo,
              fullName,
              category,
              currency,
            }}
          />
        );
        break;
      case CompanyProfileTab.datetime:
        content = <div>Date and Time</div>;
        break;
      default:
        content = null;
        break;
    }

    return content;
  };

  onCompanyFieldsChange = async (state: OnChangePayload): Promise<void> => {
    if (state) {
      this.setState(state);
    }
  };

  onSave = async (): Promise<void> => {
    const { name, fullName, taxPayerId, category, uploadedDocumentLogo, uploadedLogo, documentLogo, currency } =
      this.state;
    const { t } = this.props;
    const country = window.global_store.profile?.company?.country;
    const companyTaxId = getCompanyTaxPayerType(country);

    let errors: CompanyProfileGeneralTabErrors = {};

    if (!name) {
      errors.name = t("Please enter Company Short Name");
    } else if (!taxPayerId) {
      errors.taxPayerId = t("Please enter CNPJ");
    } else if (companyTaxId === CompanyTaxPayerTypes.cnpj && !CNPJ.isValid(taxPayerId)) {
      errors.taxPayerId = t("Entered CNPJ is invalid");
    } else {
      errors = null;

      const body: UpdateCompanyRequestData["body"] = {
        company: {
          name,
          currency,
          company_legal_detail_attributes: {
            legal_name: fullName,
            tax_payer_id: taxPayerId,
            industry_type_id: category,
          },
        },
      };

      if (!uploadedDocumentLogo && !documentLogo) {
        body.company.document_logo_id = null;
      }

      this.setState({ isLoading: true });

      try {
        if (uploadedLogo) {
          await uploadCompanyLogo({ attachment: uploadedLogo }, (_: any, progressEvent: any): void => {
            console.log(`Logo uploading... ${progressEvent}`);
          });
        }

        if (uploadedDocumentLogo) {
          await uploadCompanyDocumentLogo({ attachment: uploadedDocumentLogo }, (_: any, progressEvent: any): void => {
            console.log(`Document Logo uploading... ${progressEvent}`);
          });
        }
        const resp = await updateCompany({
          body,
        });
        await oitchauDb.system.setCompany(resp.company);
        window.global_store.company = resp.company;

        this.setState({
          isLoading: false,
          notification: t("Your company profile has been updated!"),
          notificationType: NotificationType.success,
        });
      } catch (error) {
        this.setState({
          isLoading: false,
          notification: t("company-update-failed"),
          notificationType: NotificationType.error,
        });
      }
    }

    this.setState({ errors });
  };

  render(): JSX.Element {
    const { name, notification, notificationType = "success", activeTab, logo, uploadedLogo, isLoading } = this.state;
    const { t } = this.props;

    const tabs = [{ name: CompanyProfileTab.general, label: t(`${TranslationNamespaces.common}|General`) }];

    if (window.global_store.beta) {
      tabs.push({ name: CompanyProfileTab.datetime, label: t("Date & Time") });
    }

    return (
      <SettingsLayout title={t(`${TranslationNamespaces.common}|Settings`)}>
        <PageWrapper>
          <Header>
            <CompanyProfileLogoUploader
              logoId={logo}
              file={uploadedLogo}
              onDrop={(file): void => {
                this.setState({ uploadedLogo: file });
              }}
            />
            <Title
              isRTL={window.global_store.isRTL}
              style={{ margin: "0", marginInlineStart: "24px" }}
              className={isLoading ? "loading" : ""}
            >
              {name}
            </Title>
          </Header>

          {notification && (
            <NotificationRow
              style={{ marginBottom: "20px", marginTop: 0 }}
              employeesPage
              withCloseButton={false}
              type={notificationType}
              message={notification}
            />
          )}

          {this.getContent(activeTab)}
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withTranslation([TranslationNamespaces.companyProfile, TranslationNamespaces.sidebar])(Group);
