import { AggrerationType, Frequency, Groups, Periods } from "types/models/dashboard";
import { AggregationParameters } from "../chartDataHooks/usePeriodAggregation";

const DEFAULT_AGGREGATION_PARAMS: AggregationParameters = Object.freeze({
  groupBy: Groups.company,
  groupUuids: [],
  period: Periods.last6Month,
  frequency: Frequency.month,
  aggregationType: AggrerationType.sum,
});

const DASHBOARD_PARAMS_KEY = "dashboardParams";

const getParamsKey = (chartTitle: string) => DASHBOARD_PARAMS_KEY + chartTitle.replace(" ", "");

const getStoredParams = (chartTitle: string): AggregationParameters => {
  const paramsKey = getParamsKey(chartTitle);
  let params; /* = localStorage.getItem(paramsKey); */

  // if (!params) {
  params = JSON.stringify(DEFAULT_AGGREGATION_PARAMS);
  localStorage.setItem(paramsKey, params);
  // }

  return JSON.parse(params);
};

const storeParams = (chartTitle: string, params: AggregationParameters) => {
  const paramsKey = getParamsKey(chartTitle);

  const paramsStr = JSON.stringify(params);
  localStorage.setItem(paramsKey, paramsStr);
};

export { getStoredParams, storeParams };
