import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import SelectControl from "components/UI/SelectControl";
import GlobalContext from "context/global-context";
import ModalDialog from "components/UI/ModalDialog";
import { SelectOption } from "types/ui";
import { getServicesList } from "./projectsApiUtils";
import ServicesPopupMessage from "./ServicesPopupMessage";

interface ServicesDropdownControlProps extends WithTranslation {
  onChange: (val: string[]) => void;
  value: string[];
  disabled?: boolean;
}

interface ServicesDropdownControlState {
  /** value = uuid */
  options: SelectOption[];
  createPopupVisible: boolean;
  isLoading: boolean;
}

class ServicesDropdownControl extends Component<ServicesDropdownControlProps, ServicesDropdownControlState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  state = {
    isLoading: true,
    options: [],
    createPopupVisible: false,
  };

  async componentDidMount() {
    const company = await this.context.getCompany();
    const response = await getServicesList({ companyUuid: company.uuid, status: "active" });

    const services = response.content || [];

    const items = services.map((item) => ({ label: item.name, value: item.uuid }));

    this.setState({ options: items, isLoading: false });
  }

  render() {
    const { t, onChange, value, disabled } = this.props;
    const { options, isLoading, createPopupVisible } = this.state;

    return (
      <>
        <SelectControl
          disabled={disabled}
          options={options}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          isSearchable
          isMulti
          placeholder={t("Please select services")}
          withCreateOption
          onCreateOption={() => this.setState({ createPopupVisible: true })}
          createOptionText={t("Create new service")}
        />
        <ModalDialog isOpen={createPopupVisible} onClose={() => this.setState({ createPopupVisible: false })}>
          <ServicesPopupMessage
            title={t("Create Service")}
            services={[]}
            service={null}
            onClose={() => {
              this.setState({ createPopupVisible: false });
            }}
            onYes={(created?: { uuid: string; name: string }) => {
              this.setState({ createPopupVisible: false });
              this.setState({ ...this.state, options: [...options, { label: created.name, value: created.uuid }] });
              if (created) {
                onChange([...value, created.uuid]);
              } else {
                onChange([...value]);
              }
            }}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ServicesDropdownControl);
