import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  cancelDigitalSignatureGroup,
  getDigitalSignatures,
  requestDigitalSignatures,
  resendlDigitalSignatureGroup,
} from "utils/apiHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FullPage from "components/Layout/FullPage";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import StatusBadge from "components/controls/StatusBadge";
import { PageWrapper } from "components/styled/Page";
import moment from "moment";
import { getTitle, strIncludesCheck } from "utils/common";
import styled from "styled-components";
import Select from "components/UI/Select";
import TableButtonsControl from "components/styled/TableButtonsControl";
import { TranslationNamespaces } from "types/translationNamespaces";
import { dismissDigitalSignatureGroup, downloadDigitalSignaturesReports } from "utils/api/company";
import GlobalContext from "../../context/global-context";
import RequestDigitalSignaturesPopup from "./RequestDigitalSignaturesPopup";
import noReports from "../../img/no-signratures.png";
import { DigitalSignatureResponse, DigitalSignatureStatus, PayrollGroupName } from "./types";
import { NotificationType } from "../../types/common";
import { iColumn } from "../TableCommon";

const title = "Digital Signatures";
const metaTitle = title;
const StatusFilterWrapper = styled.div`
  margin: 0 16px;
  width: 120px;
`;

interface DigitalSignaturesListProps extends RouteComponentProps, WithTranslation {}

interface DigitalSignaturesListState {
  items: DigitalSignatureResponse[];
  selectedItem: any;
  searchStatus: string;
  notification: string;
  notificationType?: NotificationType;
  searchValue: string;
  newPopupVisible: boolean;
  cancelConfirmationPopupVisible: boolean;
  resendConfirmationPopupVisible: boolean;
  downloadConfirmationPopupVisible: boolean;
  dismissConfirmationPopupVisible: boolean;
  isFetching: boolean;
  endDate: moment.Moment;
  startDate: moment.Moment;
}

class DigitalSignaturesList extends Component<DigitalSignaturesListProps, DigitalSignaturesListState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: DigitalSignaturesListProps) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      selectedItem: null,
      searchStatus: "",
      notification: "",
      searchValue: "",
      newPopupVisible: false,
      cancelConfirmationPopupVisible: false,
      resendConfirmationPopupVisible: false,
      downloadConfirmationPopupVisible: false,
      dismissConfirmationPopupVisible: false,
      isFetching: true,
      endDate: moment(),
      startDate: moment().clone().subtract(2, "weeks"),
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    this.setState({ isFetching: true });
    // all actions on BE are async, so we need to add delay to get fresh data
    setTimeout(async () => {
      const company = await this.context.getCompany();
      const digitalSignatures = await getDigitalSignatures({
        companyUuid: company.uuid,
      });

      this.setState({
        items: digitalSignatures ? digitalSignatures.content : [],
        isFetching: false,
      });
    }, 3000);
  };

  resendDigitalSignatureRequest = async (item: { payrollLockUuid: string }) => {
    const { t } = this.props;
    try {
      await resendlDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid: item.payrollLockUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.success,
        notification: t("You sent digital signature request"),
      });
      await this.getState();
    } catch (error) {
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.error,
        notification: t(`${TranslationNamespaces.common}|Something went wrong`),
      });
    }
  };
  downloadDigitalSignatureRequest = async (item: { payrollLockUuid: string }) => {
    const { t } = this.props;
    try {
      await downloadDigitalSignaturesReports(
        window.global_store.company.uuid,
        item.payrollLockUuid,
        window.global_store.profile.uuid,
      );
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.success,
        notification: t("Reports will be sent to email after generation"),
      });
    } catch (error) {
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.error,
        notification: t(`${TranslationNamespaces.common}|Something went wrong`),
      });
    }
  };
  cancelDigitalSignaturesRequest = async (item: { payrollLockUuid: string }) => {
    const { t } = this.props;

    try {
      await cancelDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid: item.payrollLockUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.success,
        notification: t("You cancelled pending digital signatures"),
      });
      await this.getState();
    } catch (e: any) {
      let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);
      if (e.message) {
        errorMessage = t(e.message);
      }
      if (e.originalRequest.errors?.length && e.originalRequest.errors[0].message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }
  };
  dismissDigitalSignaturesRequest = async (item: { payrollLockUuid: string }) => {
    const { t } = this.props;

    try {
      await dismissDigitalSignatureGroup({
        companyUuid: window.global_store.company.uuid,
        payrollLockUuid: item.payrollLockUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: NotificationType.success,
        notification: t("You dismissed signed digital signatures"),
      });
      await this.getState();
    } catch (e: any) {
      let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);
      if (e.message) {
        errorMessage = t(e.message);
      }
      if (e.originalRequest.errors?.length && e.originalRequest.errors[0].message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        downloadConfirmationPopupVisible: false,
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }
  };

  requestDigitalSignatures = ({
    payrollLockUuid,
    selectedColumns,
  }: {
    payrollLockUuid: string;
    selectedColumns: string;
  }) => {
    const { t } = this.props;

    requestDigitalSignatures({
      payrollLockUuid,
      body: {
        content: {
          selectedColumns,
          createdBy: window.global_store.profile.uuid,
        },
      },
      companyUuid: window.global_store.company.uuid,
    })
      .then(() => {
        this.setState(
          {
            newPopupVisible: false,
            notification: `${t("The Digital Signature requests were sent to all the employees.")}`,
            notificationType: NotificationType.success,
          },
          () => this.getState(),
        );
      })
      .catch((e: any) => {
        let errorMessage = t(`${TranslationNamespaces.common}|Something went wrong`);
        if (e.message) {
          errorMessage = t(e.message);
        }
        if (
          e.originalRequest &&
          e.originalRequest.errors &&
          e.originalRequest.errors.length &&
          e.originalRequest.errors[0].message
        ) {
          errorMessage = t(e.originalRequest.errors[0].message);
        }
        this.setState({
          notification: errorMessage,
          notificationType: NotificationType.error,
          newPopupVisible: false,
        });
      });
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns(rows: DigitalSignatureResponse[] = []): iColumn<DigitalSignatureResponse>[] {
    const { t } = this.props;
    return [
      {
        label: t("Payroll group"),
        accessor: "payrollGroupName",
        minWidth: 160,
        Cell: (row) => {
          if (row.value === PayrollGroupName.default) {
            return t(`${TranslationNamespaces.common}|Default`);
          }
          if (row.value === PayrollGroupName.deactivatedProfile) {
            return t(row.value);
          }
          return row.value;
        },
        style: { lineHeight: "36px" },
      },
      {
        label: t("Period"),
        accessor: "endDate",
        Cell: (row) =>
          `${moment(row.original.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
            row.value,
            "YYYY-MM-DD",
          ).format("DD/MM/YYYY")}`,
      },
      {
        label: t("Signatures"),
        accessor: "totalCount",
        Cell: (row) =>
          `${parseInt(row.original.canceledCount, 10) + parseInt(row.original.signedCount, 10)}/${
            parseInt(row.original.totalCount, 10) -
            parseInt(row.original.canceledCount, 10) -
            parseInt(row.original.signedCount, 10)
          }`,
      },
      {
        label: t(`${TranslationNamespaces.common}|Status`),
        accessor: "status",
        minWidth: 50,
        align: "center",
        Cell: (row) => {
          let status = row.value;
          if (row.original.totalCount === row.original.canceledCount + row.original.dismissedCount) {
            status = DigitalSignatureStatus.canceled;
          } else if (
            row.original.totalCount !== row.original.signedCount + row.original.forceSignedCount &&
            row.original.totalCount !== row.original.pendingCount
          ) {
            status = DigitalSignatureStatus.partial;
          }

          return (
            <div>
              <StatusBadge
                value={
                  status === DigitalSignatureStatus.pending ? t(`${TranslationNamespaces.common}|Pending`) : t(status)
                }
                type={status}
              />
              <TableButtonsControl
                dropToTop={row.viewIndex === rows.length - 1}
                visibleButtons={this.getTableButtons({ row, t })}
              />
            </div>
          );
        },
      },
    ];
  }

  getTableButtons = ({ row, t }) => {
    const buttons = [];
    const cancelledCount = parseInt(row.original.canceledCount, 10) + parseInt(row.original.dismissedCount, 10);
    const processedCount = parseInt(row.original.signedCount, 10) + parseInt(row.original.forceSignedCount, 10);
    const totalCount = parseInt(row.original.totalCount, 10);
    const remainCount = totalCount - processedCount - cancelledCount;

    if (processedCount > 0) {
      buttons.push({
        label: t("digital_signature_Download_CTA"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            downloadConfirmationPopupVisible: true,
          }),
      });
      buttons.push({
        label: t("digital_signature_Dismiss signed_CTA"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            dismissConfirmationPopupVisible: true,
          }),
      });
    }
    if (processedCount !== totalCount) {
      buttons.push({
        label: t("digital_signature_Resend_CTA"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            resendConfirmationPopupVisible: true,
          }),
      });
    }
    if (remainCount > 0) {
      buttons.push({
        label: t("digital_signature_Cancel_CTA"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            cancelConfirmationPopupVisible: true,
          }),
      });
    }

    return buttons;
  };

  render() {
    const {
      items,
      searchValue,
      isFetching,
      selectedItem,
      notification,
      notificationType = NotificationType.success,
      cancelConfirmationPopupVisible,
      resendConfirmationPopupVisible,
      downloadConfirmationPopupVisible,
      dismissConfirmationPopupVisible,
      searchStatus,
      newPopupVisible,
    } = this.state;
    const { t } = this.props;
    const actionButtonTitle = "Signatures";
    const noItemsTitle = "You don't have any Signature Request yet.";
    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.payrollGroupName, searchValue);
      if (filter && searchStatus) {
        filter = filter && s.status === searchStatus;
      }

      return filter;
    });

    return (
      <FullPage
        title={t(`${TranslationNamespaces.sidebar}|Payroll`)}
        headerAction={
          <HeaderActionButtonAdd
            title={t(actionButtonTitle)}
            state={ButtonState.primary}
            onClick={() => this.setState({ newPopupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <TablePage
            rows={filteredItems}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t(`${TranslationNamespaces.common}|Search`)}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select
                    value={searchStatus}
                    onChange={(val) => this.setState({ searchStatus: val })}
                    options={[
                      { value: "", label: t(`${TranslationNamespaces.common}|Status`) },
                      { value: DigitalSignatureStatus.pending, label: t(`${TranslationNamespaces.common}|Pending`) },
                      {
                        value: DigitalSignatureStatus.completed,
                        label: t(`${TranslationNamespaces.common}|Completed`),
                      },
                      { value: DigitalSignatureStatus.canceled, label: t("Canceled") },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            columns={this.getTableColumns(items)}
            getTrProps={(state, rowInfo) => ({
              onClick: (e) => {
                const original = rowInfo.row._original;
                if (original.payrollLockUuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/payroll/digital-signatures/${original.payrollLockUuid}`,
                    }).click();
                  } else {
                    this.props.history.push(`/payroll/digital-signatures/${original.payrollLockUuid}`);
                  }
                }
              },
            })}
            className="groups-table"
            loading={isFetching}
            noContentComponent={<NoContent img={noReports}>{t(noItemsTitle)}</NoContent>}
          />
          <ModalDialog
            isOpen={cancelConfirmationPopupVisible}
            onClose={() => this.setState({ cancelConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Cancel Request")}
              text={t("Are you sure to cancel a request to digital signature?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  cancelConfirmationPopupVisible: false,
                });
              }}
              onYes={this.cancelDigitalSignaturesRequest.bind(this, selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={dismissConfirmationPopupVisible}
            onClose={() => this.setState({ dismissConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Dismiss signed Request")}
              text={t("Are you sure to dismiss a requests to digital signature?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  dismissConfirmationPopupVisible: false,
                });
              }}
              onYes={this.dismissDigitalSignaturesRequest.bind(this, selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={resendConfirmationPopupVisible}
            onClose={() => this.setState({ resendConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Resend Request")}
              text={t("Are you sure to re-send a request to digital signature?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  resendConfirmationPopupVisible: false,
                });
              }}
              onYes={this.resendDigitalSignatureRequest.bind(this, selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={downloadConfirmationPopupVisible}
            onClose={() => this.setState({ downloadConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Download Reports")}
              text={t("Are you sure to download the digital signatures?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|No`)}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  downloadConfirmationPopupVisible: false,
                });
              }}
              onYes={this.downloadDigitalSignatureRequest.bind(this, selectedItem)}
            />
          </ModalDialog>

          {newPopupVisible && (
            <ModalDialog isOpen={newPopupVisible} onClose={() => this.setState({ newPopupVisible: false })}>
              <RequestDigitalSignaturesPopup
                onClose={() => this.setState({ newPopupVisible: false })}
                onYes={this.requestDigitalSignatures}
              />
            </ModalDialog>
          )}
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withRouter(
  withTranslation([TranslationNamespaces.payment, TranslationNamespaces.sidebar])(DigitalSignaturesList),
);
