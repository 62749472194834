import { Component, ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { NotificationType } from "types/common";
import FullPage from "components/Layout/FullPage";
import NotificationRow from "components/NotificationRow";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getTitle } from "utils/common";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { createLock } from "utils/apiHelpers";
import { ButtonState } from "components/controls/StyledButton";
import DeactivatedEmployeesList from "./DeactivatedEmployees";
import NewPreparationPopupMessage, { AddLock } from "./NewPreparationPopupMessage";

const title = "Lock Periods";
const metaTitle = title;

interface LockPeriodDeactivatedEmployeesPageProps extends RouteComponentProps, WithTranslation {}

interface LockPeriodDeactivatedEmployeesPageState {
  notification: ReactNode | null;
  notificationType: NotificationType | null;
  popupUnlockedWarningVisible: boolean;
  newPopupVisible: boolean;
}

class LockPeriodDeactivatedEmployeesPage extends Component<
  LockPeriodDeactivatedEmployeesPageProps,
  LockPeriodDeactivatedEmployeesPageState
> {
  readonly state: Readonly<LockPeriodDeactivatedEmployeesPageState> = {
    notification: null,
    notificationType: null,
    popupUnlockedWarningVisible: false,
    newPopupVisible: false,
  };

  constructor(props: LockPeriodDeactivatedEmployeesPageProps) {
    super(props);

    document.title = getTitle(props.t(metaTitle));
  }

  addLock = async ({
    payGroupUuid,
    startDate,
    endDate,
    createDigitalSignatures,
    selectedColumns,
  }: AddLock): Promise<void> => {
    const { t } = this.props;

    try {
      if (!window.global_store.company) {
        throw new Error("Create Lock: No company data");
      }

      await createLock({
        body: {
          content: {
            createDigitalSignatures,
            payrollGroupUuid: payGroupUuid,
            companyUuid: window.global_store.company.uuid,
            createdBy: window.global_store.profile.uuid,
            startDate,
            endDate,
            selectedColumns,
          },
        },
        companyUuid: window.global_store.company.uuid,
      });

      this.setState({ notification: `${t("You added lock")}`, notificationType: NotificationType.success });
    } catch (e) {
      const err: any = e;
      let errorMessage = t("common|Something went wrong");

      if (err.message) {
        errorMessage = t(err.message);
      }

      if (
        err.originalRequest &&
        err.originalRequest.errors &&
        err.originalRequest.errors.length &&
        err.originalRequest.errors[0].message
      ) {
        errorMessage = t(err.originalRequest.errors[0].message);
      }

      this.setState({
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }

    this.setState({ newPopupVisible: false });
  };

  render() {
    const { t } = this.props;
    const { notification, notificationType, popupUnlockedWarningVisible, newPopupVisible } = this.state;

    return (
      <FullPage
        title={t(`${TranslationNamespaces.sidebar}|Payroll`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("New Lock")}
            onClick={(): void => this.setState({ newPopupVisible: true })}
          />
        }
      >
        {notification && (
          <NotificationRow
            style={{ marginBottom: "32px" }}
            employeesPage
            withCloseButton={false}
            type={notificationType}
            message={notification}
          />
        )}

        <DeactivatedEmployeesList
          setNotification={(n) => this.setState({ notification: n.notification, notificationType: n.notificationType })}
          showUlockedWarningPopUp={() => this.setState({ popupUnlockedWarningVisible: true })}
        />

        <ModalDialog isOpen={newPopupVisible} onClose={() => this.setState({ newPopupVisible: false })}>
          <NewPreparationPopupMessage onClose={() => this.setState({ newPopupVisible: false })} onYes={this.addLock} />
        </ModalDialog>

        <ModalDialog
          isOpen={popupUnlockedWarningVisible}
          onClose={() => this.setState({ popupUnlockedWarningVisible: false })}
        >
          <Lightbox
            title={t("Period is not locked")}
            text={t("You have to lock a period for exporting")}
            buttonYesTitle="Ok"
            noCancelButton
            onClose={() => this.setState({ popupUnlockedWarningVisible: false })}
            onYes={() => this.setState({ popupUnlockedWarningVisible: false })}
          />
        </ModalDialog>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.payment, TranslationNamespaces.sidebar])(
  LockPeriodDeactivatedEmployeesPage,
);
