import moment from "moment-timezone";
import { ModalPunchPunch, PunchType } from "types/models/punches";
import { Shift, ShiftCompilation, ScheduleType } from "types/models/shift";

type ScheduledEvent = {
  shiftId: string;
  key: string;
  type: PunchType;
  shiftDate: string;
  /** Time is null for any event in flexible schedule. */
  time: number | null;
  duration: number | null;
};
function getScheduledEvents(shifts: Shift[]) {
  const events = [];
  for (const shift of shifts) {
    const isFlexible = shift.scheduleType === ScheduleType.flexible;
    const hasTimelessEvents = shift.events.some((sEvt) => sEvt.time == null);
    for (const sEvt of shift.events) {
      // skip breakStart events
      if (shift.businessRules.hideBreaks && sEvt.type === PunchType.breakStart && sEvt.automatic) {
        continue;
      }
      // if type is break_end search for breakStart event and check if it's automatic
      if (
        shift.businessRules.hideBreaks &&
        sEvt.type === PunchType.breakEnd &&
        shift.events.find(
          (e) => e.type === PunchType.breakStart && e.key === `${e.type}${sEvt.key.substring(sEvt.key.length - 1)}`,
        )?.automatic
      ) {
        continue;
      }

      const event: ScheduledEvent = {
        shiftId: shift.shiftId,
        key: sEvt.key,
        type: sEvt.type,
        shiftDate: shift.date,
        time: isFlexible || hasTimelessEvents ? null : sEvt.time,
        duration: sEvt.duration,
      };
      events.push(event);
    }
  }
  return events;
}

interface PunchedEvent {
  shiftId: string;
  key: string;
  punchDate?: Date;
}
function getPunchedEvents(shiftCompilations: ShiftCompilation[], punches: ModalPunchPunch[]) {
  const events = [];
  for (const shiftComp of shiftCompilations) {
    for (const sEvt of shiftComp.shift_events || []) {
      const punch = punches.find((p) => p.uuid === sEvt.uuid);
      if (!punch) continue;

      const event: PunchedEvent = {
        shiftId: shiftComp.shift_id,
        key: sEvt.key,
        punchDate: punch.device_datetime ? new Date(punch.device_datetime) : undefined,
      };
      events.push(event);
    }
  }
  return events;
}

type MatchedEvent = ScheduledEvent & Partial<PunchedEvent>;
function matchEvents(scheduledEvents: ScheduledEvent[], punchedEvents: PunchedEvent[]) {
  const events = [];
  for (const sch of scheduledEvents) {
    const pun = punchedEvents.find((p) => sch.shiftId === p.shiftId && sch.key === p.key);
    const event: MatchedEvent = { ...sch, ...pun };
    events.push(event);
  }
  return events;
}

function backfindStartEvent(
  events: MatchedEvent[],
  pairWith: MatchedEvent,
  startIndex: number,
): MatchedEvent | undefined {
  for (let i = startIndex; i >= 0; i -= 1) {
    const event = events[i];
    if (event.shiftId !== pairWith.shiftId) return undefined;
    if (pairWith.type === PunchType.exit && event.type === PunchType.entry) {
      return event;
    }
    if (pairWith.type === PunchType.breakEnd && event.type === PunchType.breakStart) {
      return event;
    }
  }
  return undefined;
}

type PairedEvent = MatchedEvent & {
  startEvent?: MatchedEvent;
};
function pairEvents(matchedEvents: MatchedEvent[]) {
  const events = [];
  for (let i = 0; i < matchedEvents.length; i += 1) {
    const curr = matchedEvents[i];
    const event: PairedEvent = { ...curr };
    if (curr.type === PunchType.breakEnd || curr.type === PunchType.exit) {
      event.startEvent = backfindStartEvent(events, event, i - 1);
    }
    events.push(event);
  }
  return events;
}

/**
 * Return the same time for breakStart and breakEnd events.
 * This is aimed to fix the case when user tries to punch breakStart closer to breakEnd time and we detect breakEnd.
 * If the times are the same then we will pick the first event (which will be breakStart assuming the events are ordered correctly).
 */
function getPlannedTime(evt: PairedEvent, timezone: string) {
  if (evt.punchDate) {
    return null;
  }
  if (evt.time === null) {
    return moment(evt.shiftDate, "YYYY-MM-DD", timezone).toDate();
  }
  if (evt.type === PunchType.breakEnd && evt.startEvent) {
    if (evt.startEvent.punchDate) {
      return moment(evt.startEvent.punchDate).toDate();
    }
    return moment(evt.startEvent.shiftDate, "YYYY-MM-DD", timezone).add(evt.startEvent.time, "minutes").toDate();
  }
  return moment(evt.shiftDate, "YYYY-MM-DD", timezone).add(evt.time, "minutes").toDate();
}

export interface ExpectedEvent {
  shiftId?: string;
  key: string;
  shiftDate: string;
  type: PunchType;
  punchDate?: Date;
  startEvent?: MatchedEvent;
  expectedDate: Date | null;
}
function expectEvent(evt: PairedEvent, timezone: string) {
  const expectedDate = getPlannedTime(evt, timezone);
  const event: ExpectedEvent = { ...evt, expectedDate };
  return event;
}

export function getExpectedEvents(
  timezone: string,
  punches: ModalPunchPunch[],
  shifts: Shift[],
  shiftCompilations: ShiftCompilation[],
): ExpectedEvent[] {
  const scheduledEvents = getScheduledEvents(shifts);
  const punchedEvents = getPunchedEvents(shiftCompilations, punches);
  const matchedEvents = matchEvents(scheduledEvents, punchedEvents);
  const pairedEvents = pairEvents(matchedEvents);
  const expectedEvents = pairedEvents.map((evt) => expectEvent(evt, timezone));
  return expectedEvents;
}

function findFirstUnpunchedAfterLastPunched(events: ExpectedEvent[]): ExpectedEvent | null {
  let isPunchedFound = false;
  for (const evt of events) {
    if (evt.punchDate != null) {
      isPunchedFound = true;
      continue;
    }
    if (isPunchedFound) return evt;
  }
  return events.find((evt) => evt.punchDate == null) || null;
}

function findPunchedDuration(events: ExpectedEvent[]): number | null {
  const first = events.find((evt) => evt.punchDate != null);
  if (first == null) return null;
  const last = events
    .slice()
    .reverse()
    .find((evt) => evt.punchDate != null)!;
  const durMs = moment(last.punchDate).diff(first.punchDate);
  const dur = Math.floor(durMs / 1000 / 60);
  return dur;
}

export function getBestEvent(
  now: Date,
  timezone: string,
  expectedEvents: ExpectedEvent[],
  shifts: Shift[],
): ExpectedEvent {
  const m = moment(now).tz(timezone);
  const todayShift = expectedEvents.filter((evt) => evt.shiftDate === m.clone().format("YYYY-MM-DD"));
  const isTodayShiftStarted = todayShift.some((evt) => evt.punchDate != null);
  const yestShift = expectedEvents.filter((evt) => evt.shiftDate === m.clone().subtract(1, "day").format("YYYY-MM-DD"));
  const isYestShiftStarted = yestShift.some((evt) => evt.punchDate != null);
  const isYestShiftEnded = yestShift.slice(-1)[0]?.punchDate != null;

  const yestShiftShift = shifts.find((sh) => sh.date === m.clone().subtract(1, "day").format("YYYY-MM-DD"));
  const yestShiftExpectedDuration = yestShiftShift?.shiftDuration;
  const yestShiftStart = yestShift.find((evt) => evt.punchDate != null)?.punchDate;
  const yestShiftTimeSinceStart = yestShiftStart ? Math.floor(m.diff(yestShiftStart) / 1000 / 60) : null;
  const isPotentialYestShiftTooLong =
    yestShiftTimeSinceStart != null &&
    yestShiftExpectedDuration != null &&
    yestShiftTimeSinceStart > yestShiftExpectedDuration * 1.4;

  const shouldFinishYestShift =
    yestShift.length !== 0 &&
    isYestShiftStarted &&
    !isYestShiftEnded &&
    !isTodayShiftStarted &&
    !isPotentialYestShiftTooLong;
  if (shouldFinishYestShift) {
    return findFirstUnpunchedAfterLastPunched(yestShift)!;
  }
  const missingTodayEvt = findFirstUnpunchedAfterLastPunched(todayShift);
  return missingTodayEvt || todayShift[0];
}

/**
 * All events in flexible schedules within one day are expected to have the same expectedDate.
 * This will lead to the first unpunched event to be picked as closest by time.
 */
export function getEventClosestByTime(now: Date, expectedEvents: ExpectedEvent[]) {
  let event = null;
  let minDiff = Infinity;
  for (const evt of expectedEvents) {
    if (!evt.expectedDate) continue;

    const diff = Math.abs(moment(now).diff(evt.expectedDate, "ms"));
    if (diff < minDiff) {
      event = evt;
      minDiff = diff;
    }
  }
  return event;
}
