import styled from "styled-components";
import { Key } from "react";
import { stylesheet } from "astroturf";

const styles = stylesheet`
  .Empty {
    padding: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 30px;
  background: var(--colors-default);
`;

const OptWrapper = styled.div`
  display: flex;
  align-options: center;
  cursor: pointer;
  padding: 12px;
  border-radius: var(--shapes-border-radius-default);

  ${(p) => p.selected && `color: var(--colors-primary);`}

  &:hover {
    background: var(--colors-bgHover);
  }
`;

export type Option<T = any> = T & {
  id: Key;
};

export type OptionsGroup<T = any> = T & {
  id: Key;
  options: (Option | OptionsGroup)[];
};

export interface OptionProps<T = any> {
  levels: [...groups: OptionsGroup<T>[], option: Option<T>];
  onSelect(): void;
}
function Opt(props: OptionProps) {
  const { onSelect, levels } = props;
  const item = levels.slice(-1)[0];
  return (
    <OptWrapper
      style={{ paddingInlineStart: `${12 * (levels.length - 1)}px` }}
      selected={item.selected}
      onClick={() => onSelect()}
    >
      {item.label}
    </OptWrapper>
  );
}

const GrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 0;
`;

const GrLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: var(--typography-font-weight-bold);
  line-height: 15px;
  color: var(--colors-secondary);
  text-transform: uppercase;
  text-transform: uppercase;
`;

const GrOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface GroupProps<T = any> {
  levels: OptionsGroup<T>[];
  children: React.ReactNode;
}
function Gr({ children, levels }: GroupProps) {
  const group = levels.slice(-1)[0];
  return (
    <GrWrapper>
      <GrLabel style={{ paddingInlineStart: `${12 * (levels.length - 1)}px` }}>{group.label}</GrLabel>
      <GrOptions>{children}</GrOptions>
    </GrWrapper>
  );
}

function Empt() {
  return <div className={styles.Empty}>No items</div>;
}

function OptOrGr({ OptionComponent, GroupComponent, onChange, levels }) {
  const item = levels.slice(-1)[0];
  const isGroup = item.options != null;
  return isGroup ? (
    <GroupComponent levels={levels} onSelect={() => onChange(levels)}>
      {item.options.map((opt) => (
        <OptOrGr
          key={opt.id}
          OptionComponent={OptionComponent}
          GroupComponent={GroupComponent}
          onChange={onChange}
          levels={[...levels, opt]}
        />
      ))}
    </GroupComponent>
  ) : (
    <OptionComponent onSelect={() => onChange(levels)} levels={levels} />
  );
}

export interface SelectListProps {
  options: (Option | OptionsGroup)[];
  onChange?(levels: [...parents: OptionsGroup[], opt: Option]): void;
  Group?: React.JSXElementConstructor<GroupProps>;
  Option?: React.JSXElementConstructor<OptionProps>;
}
export function SelectList({ options, Group = Gr, Option = Opt, onChange, ...props }: SelectListProps) {
  return (
    <Wrapper {...props}>
      {options.length === 0 ? (
        <Empt />
      ) : (
        options.map((opt) => (
          <OptOrGr key={opt.id} OptionComponent={Option} GroupComponent={Group} onChange={onChange} levels={[opt]} />
        ))
      )}
    </Wrapper>
  );
}
