import { ChangeEvent, Component, ContextType } from "react";
import TextInputControl from "components/controls/TextInputControlNew";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Button, { ButtonState } from "components/controls/StyledButton";
import moment, { Moment } from "moment";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import getSymbolFromCurrency from "currency-symbol-map";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Service } from "types/models/projects";
import GlobalContext from "context/global-context";
import config from "config";
import { createService, updateService } from "./projectsApiUtils";
import { CreateServiceRequestData } from "./projectsApiTypes";

const Title = styled.div`
  font-size: 25px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  button {
    max-width: 155px;
  }
`;

const RatesWrapper = styled.div<{ currencySign: string }>`
  display: flex;
  align-items: center;
  .SingleDatePicker {
    margin-top: 0;
  }
  & > div:first-child {
    position: relative;
    input {
      padding-inline-end: 20px;
    }

    &:after {
      content: ${(p) => `'${p.currencySign}'`};
      position: absolute;
      inset-inline-end: 8px;
      top: 29px;
      font-size: 15px;
      line-height: 19px;
      color: var(--colors-unknown39);
    }
  }
`;

const Spacer = styled.div`
  width: 16px;
`;

interface ServicesPopupMessageProps extends WithTranslation {
  service: Service | null;
  title: string;
  services: Service[];
  onClose: () => void;
  onYes: (val?: { uuid: string; name: string }) => void;
}

interface ServicesPopupMessageState {
  name: string;
  costPerHour: string;
  startDate: Moment;
  loading: boolean;
  errors: Record<string, string> | null;
}

const parseCostPerHour = (val: string) =>
  Math.round(parseFloat(typeof val === "string" ? val.replace(",", ".") : val) * 100);

class ServicesPopupMessage extends Component<ServicesPopupMessageProps, ServicesPopupMessageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  state: ServicesPopupMessageState = {
    name: "",
    costPerHour: "",
    startDate: moment(),
    loading: false,
    errors: null,
  };

  UNSAFE_componentWillMount() {
    const { service } = this.props;

    if (service) {
      const { name, costPerHour, startDate } = service;
      this.setState({
        name,
        costPerHour: (parseFloat(costPerHour.toString()) / 100).toString(),
        startDate: startDate ? moment(startDate, config.apiDateFormat) : moment(),
      });
    }
  }

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = () => {
    this.closePopup();
  };

  saveGroup = async () => {
    const { service, onYes } = this.props;
    const { name, costPerHour, startDate } = this.state;
    this.setState({ loading: true });
    const requestDetails: CreateServiceRequestData = {
      companyUuid: window.global_store.company.uuid,
      body: {
        content: {
          name,
          costPerHour: costPerHour ? parseCostPerHour(costPerHour) : 0,
        },
      },
    };

    if (!service) {
      requestDetails.body.content.createdBy = window.global_store.profile.uuid;
      const createRes = await createService(requestDetails);
      const uuid = createRes.content;

      onYes({ uuid, name });

      return;
    }
    requestDetails.serviceUuid = service.uuid;
    requestDetails.body.content.updatedBy = window.global_store.profile.uuid;
    requestDetails.body.content.startDate = startDate.format(config.apiDateFormat);

    await updateService(requestDetails);
    onYes();
  };

  onYes = () => {
    const errors = this.validate();

    if (!errors) {
      void this.saveGroup();
    } else {
      this.setState({ errors });
    }
  };

  validate = () => {
    const { name, costPerHour, startDate } = this.state;
    const { t, service, services } = this.props;
    let errors: Record<string, string> | null = {};

    if (!name.trim()) {
      errors.name = t(`${TranslationNamespaces.common}|Name can't be empty`);
    }

    const existingService = services.filter(
      (s) => s.status === "active" && s.name.toLowerCase() === name.toLowerCase(),
    )[0];

    if (
      existingService &&
      (!service || service.uuid !== existingService.uuid) &&
      existingService.name.toLowerCase() === name.toLowerCase()
    ) {
      errors.name = t("Service is already exists");
    }

    if (!costPerHour && costPerHour !== "") {
      errors.costPerHour = t("Cost per hour can't be empty");
    } else if (costPerHour && parseCostPerHour(costPerHour) > 2147483647) {
      errors.costPerHour = t("Cost per hour can't be greater than 2147483647");
    }

    if (service && !startDate) {
      errors.startDate = t("Start date can't be empty");
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  render() {
    const { name, costPerHour, errors, loading, startDate } = this.state;
    const { t, service, title: titleProp } = this.props;
    const title = titleProp || (!service ? t("Create Service") : t("Edit Service"));
    const saveButtonText = !service
      ? t(`${TranslationNamespaces.common}|Create`)
      : t(`${TranslationNamespaces.common}|Save`);

    return (
      <div>
        <Title>{title}</Title>
        <div>
          <FieldWrapper fieldName={t("Service Offered")} width="100%">
            <TextInputControl
              placeholder={t("Service Offered")}
              value={name}
              error={errors?.name}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => this.setState({ name: ev.target.value })}
            />
          </FieldWrapper>
        </div>
        {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        <RatesWrapper
          currencySign={
            (window?.global_store?.company?.currency && getSymbolFromCurrency(window.global_store.company.currency)) ||
            "$"
          }
        >
          <FieldWrapper fieldName={t("Cost per Hour")} width="100%">
            <TextInputControl
              placeholder="100"
              value={costPerHour}
              error={errors && errors.costPerHour}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const val = ev.target.value.replace(/[^0-9^,^.]*/g, "");
                this.setState({ costPerHour: val });
              }}
            />
          </FieldWrapper>
          <Spacer />
          {service && (
            <FieldWrapper fieldName={t("From Date")} width="100%">
              <SingleDatePickerControl
                numberOfMonths={1}
                value={startDate}
                onChange={(val) => {
                  this.setState({ startDate: val });
                }}
                isOutsideRange={() => false}
              />
            </FieldWrapper>
          )}
        </RatesWrapper>
        {errors?.costPerHour && <ErrorLabel>{errors.costPerHour}</ErrorLabel>}
        {errors?.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={loading}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Button
            value={saveButtonText}
            state={ButtonState.primary}
            disabled={!name}
            onClick={this.onYes}
            loading={loading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ServicesPopupMessage);
