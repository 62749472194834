import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import {
  ActivitiesReportTags,
  ActivitiesReportTasks,
  ActivitiesReportTaskFeatureMetadata,
  Project,
} from "types/models/projects";
import "styles/reports-extra-hour-table.scss";
import { iColumn } from "components/TableCommon";
import { getValueWithCurrency, hasPermisionAccess, minsToHrsMins, PermissionSectionName } from "utils/common";
import { OnError, SetIsLoading } from "types/reports";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { getMinsToHours, getMinsToHoursDecimal } from "utils/reportsHelpers";
import { getSelectedColumns } from "utils/tableHelpers";
import ReportsNoContent from "./ReportsNoContent";
import { TableDetailsTitle, TableDetailsTitleDates } from "./reportsStyledComponents";
import { ReportActvitiesStatus } from "./helpers";
import { getProjectsList } from "../Projects/projectsApiUtils";

interface ReportsActivitiesTaskFeatureProps extends RouteComponentProps, WithTranslation {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  isFeatureReport: boolean;
  showInactiveEmployees?: boolean;
  setIsLoading: SetIsLoading;
  onError: OnError;
  projectUuid: string;
  activityStatus: ReportActvitiesStatus;
}

type ReportRow = {
  projectName: string;
  projectId: string;
  entityName: string;
  clientName: string;
  employees: number;
  totalHours: string;
  totalCost: string;
  totalEmployeeCost: string;
};

interface ReportsActivitiesTaskFeatureState {
  loading: boolean;
  activities: ReportRow[];
  metadata: ActivitiesReportTaskFeatureMetadata | null;
  selectedColumns: string;
  projectName: string | null;
}

function mapTasksSummaryTableData(inputData: ActivitiesReportTasks[]): ReportRow[] {
  return inputData.map((row) => ({
    projectName: row.project.name,
    projectId: row.project.id,
    entityName: row.task.name,
    clientName: row.client?.name || "",
    employees: row.userProfilesCount,
    totalHours: getMinsToHours(row.totalMinutes),
    totalHoursDecimal: getMinsToHoursDecimal(row.totalMinutes),
    totalCost: getValueWithCurrency(row.totalCost) || "0",
    totalEmployeeCost: getValueWithCurrency(row.totalEmployeeCost) || "0",
  }));
}

function mapTasksByTagsSummaryTableData(inputData: ActivitiesReportTags[]): ReportRow[] {
  return inputData.map((row) => ({
    projectName: row.project.name,
    projectId: row.project.id,
    entityName: row.tag.name || row.task.name,
    clientName: row.client?.name || "",
    employees: row.userProfilesCount,
    totalHours: getMinsToHours(row.totalMinutes),
    totalHoursDecimal: getMinsToHoursDecimal(row.totalMinutes),
    totalCost: getValueWithCurrency(row.totalCost) || "0",
    totalEmployeeCost: getValueWithCurrency(row.totalEmployeeCost) || "0",
  }));
}

class ReportsActivitiesTaskFeature extends Component<
  ReportsActivitiesTaskFeatureProps,
  ReportsActivitiesTaskFeatureState
> {
  constructor(props: ReportsActivitiesTaskFeatureProps) {
    super(props);
    this.state = {
      metadata: null,
      loading: true,
      projectName: null,
      selectedColumns: getSelectedColumns(
        "entity_name,project_name,client_name,employees,total_hours,total_hours_decimal,total_cost",
        "ReportsActivitiesTaskFeature",
      ),
      activities: [],
    };
  }

  componentDidMount(): void {
    this.getReportData();
  }

  getReportData = (): void => {
    const { t, startDate, endDate, projectUuid, showInactiveEmployees, setIsLoading, onError, activityStatus } =
      this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, async () => {
        setIsLoading(true);

        try {
          const response = await getReport(
            {
              status: activityStatus,
              showInactiveEmploees: showInactiveEmployees,
              startDate,
              endDate,
              companyUUID,
              projectUuid,
              type: this.props.isFeatureReport ? "tags_activities" : "tasks_activities",
            },
            true,
          );

          const projectsResponse: { content: Project[] } = await getProjectsList({ companyUuid: companyUUID });

          let activities: ReportRow[];
          if (this.props.isFeatureReport) {
            const data: ActivitiesReportTags[] = response?.content || [];
            activities = mapTasksByTagsSummaryTableData(data);
          } else {
            const data: ActivitiesReportTasks[] = response?.content || [];
            activities = mapTasksSummaryTableData(data);
          }
          const metadata: ActivitiesReportTaskFeatureMetadata = response?.metadata;
          const selectedProject = projectsResponse.content.find((proj: Project) => proj.uuid === projectUuid);

          this.setState({
            metadata,
            activities,
            loading: false,
            projectName: selectedProject?.name || "",
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("Error", e);

          onError(t("Failed to generate report"));
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  getColumns = (): iColumn<ReportRow>[] => {
    const { t, isFeatureReport } = this.props;
    const { metadata } = this.state;
    const columns: iColumn<ReportRow>[] = [
      {
        accessor: "entityName",
        rubyAccessor: "entity_name",
        label: isFeatureReport ? t("Epic") : t("Task"),
        minWidth: 130,
      },
      {
        accessor: "projectName",
        rubyAccessor: "project_name",
        label: t("Project"),
        minWidth: 130,
      },
      {
        accessor: "projectId",
        rubyAccessor: "project_id",
        label: t("Project Id"),
        minWidth: 130,
      },
      {
        accessor: "clientName",
        rubyAccessor: "client_name",
        label: t("Client"),
        minWidth: 130,
      },
      {
        accessor: "employees",
        rubyAccessor: "employees",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employees",
          `${TranslationNamespaces.common}|Employees`,
        ),
        minWidth: 100,
        align: "start",
      },
      {
        accessor: "totalHours",
        rubyAccessor: "total_hours",
        label: t("Total Hours"),
        Footer: metadata?.totalMinutes ? minsToHrsMins(metadata.totalMinutes) : "",
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "totalHoursDecimal",
        rubyAccessor: "total_hours_decimal",
        label: t("Total Hours (Decimal)"),
        Footer: metadata?.totalMinutes ? getMinsToHoursDecimal(metadata.totalMinutes) : "",
        minWidth: 140,
        align: "end",
      },
      {
        accessor: "totalCost",
        rubyAccessor: "total_cost",
        label: t("Total Cost"),
        Footer: getValueWithCurrency(metadata?.totalCost),
        minWidth: 100,
        align: "end",
      },
    ];
    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      columns.push({
        accessor: "totalEmployeeCost",
        rubyAccessor: "total_employee_cost",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.reportsPage,
          "custom-total-employee-cost",
          `Total Employee Cost`,
        ),
        Footer: getValueWithCurrency(metadata?.totalEmployeeCost),
        minWidth: 100,
        align: "end",
      });
    }
    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsActivitiesTaskFeature", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { loading, activities, selectedColumns, projectName } = this.state;
    const { t, startDate, endDate, isFeatureReport, projectUuid, showInactiveEmployees, activityStatus } = this.props;
    const columns = this.getColumns();

    return (
      <div>
        <TablePage<ReportRow>
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <TableDetailsTitle>{projectName}</TableDetailsTitle>
              <TableDetailsTitleDates>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </TableDetailsTitleDates>
            </>
          }
          loading={loading}
          rows={activities}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              // eslint-disable-next-line camelcase
              onChange={(value: string): Promise<Record<string, unknown>> =>
                fireDownloadReport({
                  status: activityStatus,
                  format: value || "pdf",
                  selectedColumns,
                  startDate,
                  endDate,
                  projectUuid,
                  showInactiveEmploees: showInactiveEmployees,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: isFeatureReport ? "project_tags_activities" : "project_tasks_activities",
                })
              }
            />
          }
          noContentComponent={<ReportsNoContent />}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsActivitiesTaskFeature));
