import { FilteredEmployeePayload } from "../types/models/userProfile";

export const baseByUuidPayload = (requestedBy: string, uuids: string[]): FilteredEmployeePayload => ({
  requestedBy,
  skip: 0,
  limit: uuids.length,
  filterModel: {
    uuid: { filterType: "set", values: uuids },
  },
  fields: [
    "id",
    "uuid",
    "fullName",
    "avatarId",
    "employeeStatus",
    "position.title",
    "taxPayerId",
    "matricula",
    "role",
    "createdAt",
    "pis",
    "lastLockDate",
  ],
  externalFields: [],
  excludeTotal: true,
  includeSelf: true,
});
