import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import FormikField from "components/UI/FormikHelpers/Field";
import Button from "components/UI/Button";
import Select from "components/UI/FormSelect";
import { Location } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { addIBeaconValidation } from "utils/apiHelpers";
import BEM from "utils/BEM";
import "./IBeacon.scss";

const b = BEM.b("validation-wifi-form");

interface ValidationIBeaconFormProps {
  currentLocation: Location;
  onCancel: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const ValidationIBeaconForm = ({ currentLocation, onCancel, setNotification, onYes }: ValidationIBeaconFormProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);
  const [manufacture, setManufacture] = useState("minew");

  const createIBeaconValidation = async (
    data: Record<"title" | "serial" | "mac" | "uid" | "uuid" | "minor" | "major" | "manufacture", string>,
  ) => {
    const payload = {
      title: data.title,
      uuid: data.uuid,
      minor: data.minor,
      major: data.major,
      manufacture: data.manufacture as "sensoro" | "minew",
      additional_identifications_attributes: [] as {
        name: "serial" | "mac" | "uid";
        value: string;
      }[],
    };

    if (data.serial) {
      payload.additional_identifications_attributes.push({
        name: "serial",
        value: data.serial,
      });
    }
    if (data.mac) {
      payload.additional_identifications_attributes.push({
        name: "mac",
        value: data.mac,
      });
    }
    if (data.uid) {
      payload.additional_identifications_attributes.push({
        name: "uid",
        value: data.uid,
      });
    }

    try {
      await addIBeaconValidation(currentLocation.id, { body: { beacon: payload } });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  return (
    <Formik
      key="new"
      initialValues={{
        title: "",
        serial: "",
        mac: "",
        uid: "",
        uuid: "",
        minor: "",
        major: "",
        manufacture: "minew",
      }}
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (!values.title) {
          errors.title = t("Can't be blank");
        }
        if (!values.uuid) {
          errors.uuid = t("Can't be blank");
        }
        if (!values.minor) {
          errors.minor = t("Can't be blank");
        }
        if (!values.major) {
          errors.major = t("Can't be blank");
        }
        if (!values.serial && !values.mac && !values.uid) {
          errors.serial = t("At least one field should be provided");
          errors.mac = t("At least one field should be provided");
          errors.uid = t("At least one field should be provided");
        }
        return errors;
      }}
      onSubmit={(values) => {
        void createIBeaconValidation(values);
      }}
      render={({ errors }) => (
        <Form className={b()}>
          <h2 className={b("heading")}>{t("iBeacon Setup")}</h2>
          <FormikField
            name="title"
            autocomplete="off"
            title={t("common|Name")}
            placeholder={t("common|Name")}
            errors={errors}
          />
          <FormikField name="serial" title={t("Serial")} placeholder={t("Serial")} errors={errors} />
          <FormikField name="mac" title={t("mac")} placeholder={t("mac")} errors={errors} />
          <FormikField name="uid" title={t("uid")} placeholder={t("uid")} errors={errors} />
          <FormikField name="uuid" title={t("UUID")} placeholder={t("UUID")} errors={errors} />
          <FormikField name="minor" title={t("Minor")} placeholder={t("Minor")} errors={errors} />
          <FormikField name="major" title={t("Major")} placeholder={t("Major")} errors={errors} />

          <div className="formik-field">
            <label>
              <span className="formik-field__label">{t("manufacture")}</span>
              <Select
                value={manufacture}
                options={[
                  { label: t("minew"), value: "minew" },
                  { label: t("sensoro"), value: "sensoro" },
                ]}
                onChange={setManufacture}
              />
            </label>
          </div>

          <div className={b("action-buttons")}>
            <Button label={t("Cancel")} modifier="bordered" className={b("action-button")} onClick={onCancel} />
            <Button type="submit" className={b("action-button")} label={t("Create")} />
          </div>
        </Form>
      )}
    />
  );
};

export default ValidationIBeaconForm;
