import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Feature } from "types/models/subscription";
import { BillingService } from "components/Billing/BillingService";
import { PunchNowButtonWithModal } from "components/PunchNow/PunchNowButtonWithModal";
import NotificationControl from "components/Notifications/NotificationControl";
import HelpMenuNew from "./HelpMenuNew";
import * as Images from "./menu-svg-icons";
import UserMenu from "./UserMenuNew";
import { HeaderTimeTracker } from "../Activities/TimeTracker";

const TopRowWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 8px 24px 8px 16px;
  border-bottom: 1px solid var(--colors-theme-tr-brdr);
  background: var(--colors-theme-tr-bg);
`;

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 172px;

  svg {
    cursor: pointer;
  }
`;

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .user-menu {
    margin-inline-start: 8px;
  }
`;

interface TopRowNewProps {
  minimal?: boolean;
}

const TopRowNew = ({ minimal }: TopRowNewProps) => {
  const history = useHistory();

  return (
    <TopRowWrapper className="top-row">
      <LeftSide>
        <Images.DayIOLogo onClick={() => history.push("/")} />

        {BillingService.checkFeatureAccess(Feature.TA) && !minimal && <PunchNowButtonWithModal />}
      </LeftSide>

      {BillingService.checkFeatureAccess(Feature.Project) && !minimal && (
        <>
          <HeaderTimeTracker />
        </>
      )}

      <RightSide>
        {!minimal && <HelpMenuNew />}

        {window.global_store.notifications && !minimal && <NotificationControl />}
        <UserMenu minimal={minimal} />
      </RightSide>
    </TopRowWrapper>
  );
};

export default TopRowNew;
