import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import CompanyProfile from "components/CompanyProfile/index";
import SubsidiariesPage from "components/Subsidiaries/SubsidiariesPage";
import ClientsPage from "components/Clients/ClientsPage";
import ClientPage from "components/Clients/ClientPage";
import DepartmentsPage from "components/Departments/DepartmentsPage";
import PositionsPage from "components/Positions/PositionsPage";
import TeamsPage from "components/Teams/TeamsPage";
import HolidayGroupsPage from "components/Holidays/HolidayGroups";
import HolidayGroupPage from "components/Holidays/HolidayGroup";
import PunchingRules from "components/PunchingRules/PunchingRules";
import PunchingRulesGroup from "components/PunchingRules/PunchingRulesGroup";
import SsoPage from "components/SecurityPage/SsoPage";
import PasswordPage from "components/SecurityPage/PasswordPage";
import PermissionsPage from "components/Employees/PermissionsPage";
import { useHubspot } from "hooks/useHubspot";
import RouteCompanyJobSites from "./JobSites";
import RouteCompanyLocations from "./Locations";

const RouteCompany = ({ match }: RouteComponentProps): JSX.Element => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path={`${match.path}/locations`} component={RouteCompanyLocations} />
        <Route path={`${match.path}/job-sites`} component={RouteCompanyJobSites} />

        <Redirect strict from={`${match.path}/schedules`} to="/schedules" />

        <Route exact path={`${match.path}/profile`} component={CompanyProfile} />

        <Route exact path={`${match.path}/permissions`} component={PermissionsPage} />
        <Redirect exact from={`${match.path}/permissions/`} to={`${match.path}/permissions`} />

        <Route exact path={`${match.path}/sso`} component={SsoPage} />
        <Route exact path={`${match.path}/password`} component={PasswordPage} />

        <Route exact path={`${match.path}/holidays/:uuid/:tab(employees)?`} component={HolidayGroupPage} />
        <Route strict path={`${match.path}/holidays/`} component={HolidayGroupsPage} />
        <Redirect strict from={`${match.path}/holidays`} to={`${match.path}/holidays/`} />

        <Redirect strict from={`${match.path}/custom-rules/`} to={`${match.path}/custom-rules`} />

        <Route strict path={`${match.path}/subsidiaries`} component={SubsidiariesPage} />
        <Redirect strict from={`${match.path}/subsidiaries/`} to={`${match.path}/subsidiaries`} />

        <Route strict path={`${match.path}/clients/:uuid`} component={ClientPage} />
        <Redirect strict from={`${match.path}/clients/:uuid/`} to={`${match.path}/clients/:uuid`} />

        <Route strict path={`${match.path}/clients`} component={ClientsPage} />
        <Redirect strict from={`${match.path}/clients/`} to={`${match.path}/clients`} />

        <Route strict path={`${match.path}/departments`} component={DepartmentsPage} />
        <Redirect strict from={`${match.path}/departments/`} to={`${match.path}/departments`} />

        <Route strict path={`${match.path}/positions`} component={PositionsPage} />
        <Redirect strict from={`${match.path}/positions/`} to={`${match.path}/positions`} />

        <Route strict path={`${match.path}/teams`} component={TeamsPage} />
        <Redirect strict from={`${match.path}/teams/`} to={`${match.path}/teams`} />

        <Route
          exact
          strict
          path={`${match.path}/groups-of-rules/:id/:punchType(payedOvertime|extraHours|nightShift)?`}
          component={PunchingRulesGroup}
        />

        <Route strict path={`${match.path}/groups-of-rules/`} component={PunchingRules} />
        <Redirect strict from={`${match.path}/groups-of-rules`} to={`${match.path}/groups-of-rules/`} />

        <Redirect strict from={`${match.path}/rules`} to={`${match.path}/groups-of-rules/`} />
        <Redirect strict from={`${match.path}/rules/`} to={`${match.path}/groups-of-rules/`} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default RouteCompany;
