import { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-create.scss";
import { minsToHrsMins } from "utils/common";
import { withTranslation } from "react-i18next";
import ScheduleAdditionalEntryExit from "components/controls/ScheduleAdditionalEntryExit";
import TimeControl from "components/controls/TimeControl";
import ScheduleDayButton from "components/controls/ScheduleDayButton";
import ClickOutside from "react-click-outside";
import ClearButton from "components/controls/ClearButton";
import DayOfWeek from "components/UI/Table/DayOfWeek";
import ExtraNightTag from "components/UI/ExtraNightTag";
import RadioButton from "components/controls/RadioButton";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRuleNames } from "types/models/companyRules";
import TableCommon from "../TableCommon";
import { BreaksCell } from "./Breaks/BreaksCell";
import * as images from "../svg-images";

const b = BEM.b("schedule-create");

class ScheduleWeeklyTable extends Component {
  static defaultProps = {
    onChange: () => {},
    onRemoveBreak: () => {},
    onAddAdditionalEntryExit: () => {},
    onRemoveAdditionalEntryExit: () => {},
    onRemoveDay: () => {},
    onClearDay: () => {},
    onCopyToNextDay: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      tableColumns: [],
      isFetching: false,
    };
  }

  async componentDidMount() {
    const businessRules = await getCompanyRules();
    const dsrEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;

    this.setState({
      tableColumns: this.getTableColumns(dsrEnabled),
    });
  }

  updateDay = (dayId, dayData) => {
    this.props.onChange(dayId, dayData);
  };

  hasData(schedule) {
    return schedule.entry.value || schedule.exit.value || schedule.breaks.length > 0 || schedule.isDsrDay;
  }

  getTableColumns(dsrEnabled) {
    const {
      t,
      onClearDay,
      onRemoveBreak,
      onAddAdditionalEntryExit,
      onRemoveAdditionalEntryExit,
      readOnly,
      enableDsrDay,
    } = this.props;

    const days = ["", t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
    const columns = [
      {
        Header: "",
        accessor: "id",
        Cell: (r) => {
          if (readOnly) {
            return <ScheduleDayButton value={r.value} selected={r.original.entry.value} />;
          }
          return <DayOfWeek dayName={days[r.value]} />;
        },
        width: 80,
      },
      {
        Header: t("Entry"),
        accessor: "entry",
        Cell: (r) => {
          const entry = r.value;
          if (readOnly || (entry.value && !entry.error && r.original.id !== this.state.selected)) {
            return entry.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("from")}
              onTimeChange={(val) => this.updateDay(r.original.id, { entry: val })}
              error={entry.error}
              value={entry.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 100,
      },
      {
        Header: t("Exit"),
        accessor: "exit",
        Cell: (r) => {
          const exit = r.value;
          if (readOnly || (exit.value && !exit.error && r.original.id !== this.state.selected)) {
            return exit.value;
          }
          return (
            <TimeControl
              modifiers={{ schedule: true }}
              placeholder={t("to")}
              onTimeChange={(val) => this.updateDay(r.original.id, { exit: val, isDsrDay: false })}
              error={exit.error}
              value={exit.value}
            />
          );
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        width: 100,
      },
      {
        Header: t("Extra Night"),
        accessor: "extraNight",
        style: { textAlign: "center" },
        Cell: (r) => (r.value ? <ExtraNightTag value={`${minsToHrsMins(r.value)} hs`} /> : null),
        width: 140,
      },
      dsrEnabled
        ? {
            Header: "DSR",
            accessor: "isDsrDay",
            style: { textAlign: "center", justifyContent: "center", display: "flex" },
            headerStyle: { textAlign: "center" },
            Cell: (r) =>
              !r.original?.exit?.value && (
                <RadioButton selected={r.value} onClick={(checked) => enableDsrDay(r.original.id)} />
              ),
            width: 50,
          }
        : null,
      {
        Header: t("Break"),
        accessor: "breaks",
        Cell: (r) => (
          <BreaksCell
            row={r}
            readOnly={readOnly}
            breaks={r.value}
            selected={this.state.selected}
            updateDay={this.updateDay}
            onRemoveBreak={onRemoveBreak}
          />
        ),
        minWidth: 420,
      },
    ];
    if (window.global_store.beta) {
      columns.push({
        Header: t("Additional Entry/Exit"),
        accessor: "extraEntryExit",
        Cell: (r) => {
          if (!r.original.entry.value || !r.original.exit.value) {
            return null;
          }
          if (r.value && r.value.length > 0) {
            return (
              <div>
                {r.value.map((e, i) => {
                  const entryExit = { ...e };
                  return (
                    <ScheduleAdditionalEntryExit
                      key={i}
                      multiple
                      readOnly={readOnly || r.original.id !== this.state.selected}
                      item={entryExit}
                      onChange={(eNeX) => {
                        const entryExits = Object.assign([], r.value);
                        entryExits[i] = eNeX;
                        this.updateDay(r.original.id, { extraEntryExit: entryExits });
                      }}
                      onAddItem={() => onAddAdditionalEntryExit(r.original.id, i)}
                      onRemoveItem={() => onRemoveAdditionalEntryExit(r.original.id, i)}
                    />
                  );
                })}
              </div>
            );
          }
          return !readOnly ? (
            <div className={b("break-link")} onClick={() => onAddAdditionalEntryExit(r.original.id)}>
              {t("Add extra entry")}
            </div>
          ) : null;
        },
        style: { textAlign: "center", overflow: "visible" },
        headerStyle: { textAlign: "center" },
        minWidth: 460,
      });
    }
    columns.push({
      Header: t("Total"),
      accessor: "total",
      minWidth: 240,
      Cell: (r) =>
        r.value ? (
          <div>
            {r.value ? minsToHrsMins(r.value) : ""} {t("hs")}
          </div>
        ) : null,
      headerStyle: { textAlign: "center" },
      style: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: "14px",
        color: "#525F7F",
      },
    });

    const colOptions = {
      Header: "",
      minWidth: 80,
      Cell: (r) => {
        if (this.hasData(r.original) && (r.original.id !== this.state.selected || r.original.id === 7)) {
          return (
            <span className={b("clear-schedule")}>
              <ClearButton onClick={() => onClearDay(r.original.id)} />
            </span>
          );
        }
        if (this.hasData(r.original) && r.original.id !== 7) {
          return (
            <span className={b("add-schedule")} onClick={this.copyToNextDay.bind(this, r.original.id)}>
              {images.scheduleAdd}
            </span>
          );
        }
        return null;
      },
      style: { textAlign: "center" },
    };
    if (!readOnly) {
      columns.push(colOptions);
    }
    return columns;
  }

  copyToNextDay(id, ev) {
    ev.stopPropagation();
    this.setState({ selected: id + 1 }, () => this.props.onCopyToNextDay(id));
  }

  unselect() {
    this.setState({ selected: null });
  }

  render() {
    const { tableColumns } = this.state;
    const { tableData, readOnly } = this.props;

    return (
      <div className={b()}>
        <ClickOutside
          onClickOutside={(e) => {
            // don't unselect row if clicked inside the popup
            if (!e.target.closest(".PopupWrapper")) {
              this.unselect();
            }
          }}
        >
          <TableCommon
            noScroll
            rows={tableData}
            columns={tableColumns}
            showPagination={false}
            pages={1}
            className={`table-common ${readOnly ? "table-common_readonly" : ""}`}
            defaultPageSize={1000}
            loading={this.state.isFetching}
            manual
            showPageSizeOptions={false}
            getTrProps={(s, r) => {
              const selected = this.state.selected === r.original.id;
              const style = {};
              if (selected) {
                style.backgroundColor = "#F7F9FC";
              }
              return {
                style,
                onClick: (e) => {
                  !readOnly && this.setState({ selected: r.original.id });
                },
              };
            }}
          />
        </ClickOutside>
      </div>
    );
  }
}

export default withTranslation("schedules")(ScheduleWeeklyTable);
