import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FullPage from "components/Layout/FullPage";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import { strIncludesCheck, getTitle } from "utils/common";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import Select from "components/UI/Select";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { Service, ServiceStatus } from "types/models/projects";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { iCellInfo } from "utils/tableHelpers";
import { NotificationType } from "types/common";
import getSymbolFromCurrency from "currency-symbol-map";
import { ButtonState } from "components/controls/StyledButton";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import ServicesPopupMessage from "./ServicesPopupMessage";
import { getServicesList, deactivateService } from "./projectsApiUtils";

const title = "Services Library";
const metaTitle = title;

const StatusFilterWrapper = styled.div`
  margin-inline-start: 15px;
  width: 120px;
`;

type ServicesListPageProps = WithTranslation;

interface ServicesListPageState {
  items: Service[];
  searchStatus: string;
  searchValue: string;
  selectedItem: string | null;
  confirmationPopupVisible: boolean;
  popupVisible: boolean;
  loaded: boolean;
  notification: string | null;
  notificationType: NotificationType;
}

class ServicesListPage extends Component<ServicesListPageProps, ServicesListPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ServicesListPageProps) {
    super(props);
    const { t } = props;

    this.state = {
      items: [],
      searchStatus: ServiceStatus.active,
      selectedItem: null,
      confirmationPopupVisible: false,
      loaded: false,
      notification: "",
      searchValue: "",
      popupVisible: false,
      notificationType: NotificationType.success,
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    this.setState({ loaded: false });
    const company = await this.context.getCompany();
    const response = await getServicesList({ companyUuid: company.uuid });
    this.setState({
      items: response.content || [],
      loaded: true,
    });
  };

  onArchiveClick = (serviceUuid: string) => {
    this.setState({
      confirmationPopupVisible: true,
      selectedItem: serviceUuid,
    });
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns = (): iColumn<Service>[] => {
    const { t } = this.props;
    const columns = [
      {
        label: t(`${TranslationNamespaces.common}|Name`),
        accessor: "name",
        minWidth: 160,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Cost per hour"),
        accessor: "costPerHour",
        Cell: (row: iCellInfo<Service>) =>
          `${parseFloat(row.value) / 100} ${getSymbolFromCurrency(window?.global_store?.company?.currency || "USD")}`,
        align: "center" as ColumnAlign,
        width: 160,
      },
      {
        label: t(`${TranslationNamespaces.common}|Status`),
        accessor: "status",
        minWidth: 50,
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<Service>) => {
          const status = row.value;
          const tagType = status === ServiceStatus.active ? StatusTagType.active : StatusTagType.default;

          return (
            <div>
              <StatusTag value={t(`${TranslationNamespaces.common}|${status}`)} type={tagType} />
              {row.value === ServiceStatus.active && (
                <TableButtons className="buttons">
                  <TableButton
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      this.onArchiveClick(row.original.uuid);
                    }}
                  >
                    {t(`${TranslationNamespaces.common}|Archive`)}
                  </TableButton>
                </TableButtons>
              )}
            </div>
          );
        },
      },
    ];

    return columns;
  };

  saveItem = async () => {
    const { t } = this.props;
    this.setState({ selectedItem: null, popupVisible: false });
    await this.getState();
    this.setState({ loaded: true, notification: t("Service was updated") });
  };

  addItem = async () => {
    const { t } = this.props;
    this.setState({ selectedItem: null, popupVisible: false });
    await this.getState();
    this.setState({ loaded: true, notification: t("Service was added") });
  };

  removeItem = async (selectedItem: string) => {
    const { t } = this.props;
    const data = {
      companyUuid: window.global_store.company.uuid,
      serviceUuid: selectedItem,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };

    this.setState({ loaded: false });
    await deactivateService(data);
    this.setState({
      notification: t("Service was archived"),
      confirmationPopupVisible: false,
      selectedItem: null,
    });

    void this.getState();
  };

  render() {
    const {
      items,
      searchValue,
      loaded,
      notification,
      notificationType = NotificationType.success,
      searchStatus,
      confirmationPopupVisible,
      popupVisible,
      selectedItem,
    } = this.state;
    const { t } = this.props;
    const actionButtonTitle = "Add Service";
    const noItemsTitle = "No Services are available";

    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.name, searchValue);
      if (searchStatus) {
        filter = filter && s.status === searchStatus;
      }
      return filter;
    });

    return (
      <FullPage
        title={t(title)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(actionButtonTitle)}
            onClick={() => this.setState({ popupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <TablePage
            rows={filteredItems}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t(`${TranslationNamespaces.common}|Search`)}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select
                    value={searchStatus}
                    onChange={(val) => this.setState({ searchStatus: val })}
                    options={[
                      { value: "", label: t(`${TranslationNamespaces.common}|Status`) },
                      { value: ServiceStatus.active, label: t(`${TranslationNamespaces.common}|Active`) },
                      { value: ServiceStatus.archived, label: t(`${TranslationNamespaces.common}|Archived`) },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            customColumnsAvailable={false}
            columns={this.getTableColumns()}
            className="projects-table"
            loading={!loaded}
            getTrProps={(state, rowInfo) => ({
              onClick: () => {
                if (rowInfo?.row._original.uuid && rowInfo.row._original.status === ServiceStatus.active) {
                  this.setState({
                    selectedItem: rowInfo.row._original,
                    popupVisible: true,
                  });
                }
              },
            })}
            noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          />
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <ServicesPopupMessage
              services={items}
              service={selectedItem}
              allItems={items}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={() => {
                if (selectedItem) {
                  void this.saveItem();
                } else {
                  void this.addItem();
                }
              }}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Archive service")}
              text={t("archive-service-confirmation")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Yes`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  confirmationPopupVisible: false,
                });
              }}
              onYes={() => {
                if (selectedItem) {
                  void this.removeItem(selectedItem);
                }
              }}
            />
          </ModalDialog>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ServicesListPage);
