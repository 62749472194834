import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { BaseSyntheticEvent, ForwardedRef, forwardRef, useEffect, useState } from "react";
import { stylesheet } from "astroturf";
import CountryJSON from "countries-list/dist/countries.min.json";
import { BillingData } from "types/models/subscription";
import FormField from "./FormField";

const styles = stylesheet`@import "../billing.scss"`;

type BillingFormProps = {
  data: BillingData,
  onChangeCountry: (val: BaseSyntheticEvent) => void
};

const baseRequiredFields = {
  country: Yup.string().required("Required").nullable(),
  businessName: Yup.string().required("Please add your organization name.").nullable(),
  email: Yup.string().email("Invalid email").required("Please add your email.").nullable(),
}
const validationConfigRequired = {
  BR: {
    validation: {
      ...baseRequiredFields,
      taxId: Yup.string()
        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
          message: "Enter correct CNPJ in format xx.xxx.xxx/xxxxx-xx",
          excludeEmptyString: true,
        })
        .required("Enter the valid number.")
        .nullable(),
      line1: Yup.string().required("Add your street address.").nullable(),
      city: Yup.string().required("Add your city.").nullable(),
      state: Yup.string().required("Add your state/province.").nullable(),
      postalCode: Yup.string().required("Add your postal code.").nullable(),
    },
    optionalFields: [],
    fields: ["country", "email", "businessName", "taxId", "line1", "city", "state", "postalCode"],
  },
  US: {
    validation: {
      ...baseRequiredFields,
      line1: Yup.string().required("Add your street address.").nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().required("Add your state/province.").nullable(),
      postalCode: Yup.string().nullable(),
    },
    optionalFields: ["city", "postalCode"],
    fields: ["country", "email", "businessName", "line1", "city", "state", "postalCode"],
  },
  IL: {
    validation: {
      ...baseRequiredFields,
      line1: Yup.string().nullable(),
      city: Yup.string().nullable(),
      postalCode: Yup.string().nullable(),
    },
    optionalFields: ["line1", "city", "postalCode"],
    fields: ["country", "email", "businessName", "line1", "city", "postalCode"],
  },
  OTHER_WORLD: {
    validation: {
      ...baseRequiredFields,
      line1: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(),
      postalCode: Yup.string().nullable(),
    },
    optionalFields: ["line1", "city", "state", "postalCode"],
    fields: ["country", "email", "businessName", "line1", "city", "state", "postalCode"],
  },
};

const BillingDetailsForm = forwardRef((props: BillingFormProps, ref: ForwardedRef<any>) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [billingSchema, setBillingSchema] = useState(Yup.object().shape({
    country: Yup.string().required("Required"),
  }));
  const [billingFields, setBillingFields] = useState<{fields: string[], optionalFields: string[]}>({fields: [], optionalFields: []});
  const changeCountry = (country: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const validationObject = Object.keys(validationConfigRequired).includes(country) ? validationConfigRequired[country] : validationConfigRequired.OTHER_WORLD;
    setBillingSchema(Yup.object().shape(validationObject.validation));
    setBillingFields({fields: validationObject.fields, optionalFields: validationObject.optionalFields});
  };
  const countryOptions: {label: string, value: string}[] = Object.entries(CountryJSON).map((entry) => {
    const [key, obj] = entry;
    return {
      label: obj.name,
      value: key,
    }
  });

  const initialValues = props.data;
  useEffect(() => {
    changeCountry(props.data.country);
  }, [props.data.country]);

  const onSubmit = () => {}

  const addOptionalLabel = (name: string): string => billingFields.optionalFields.includes(name) && " (Optional)" || "";
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={billingSchema}
      innerRef={ref}
      onSubmit={onSubmit}
    >
      {({ errors, touched, handleChange }) => (
        <Form>
          <div className={styles.BillingForm}>
            { billingFields.fields.includes("country") ?
            <FormField name="country" title={t("Country")} type="select" placeholder={t("Select country")} options={countryOptions} onChange={(e: BaseSyntheticEvent) => {
              handleChange(e);
              props.onChangeCountry(e);
              changeCountry(e.target.value);
            }} /> : null}
            <div className={`${billingFields.fields.includes("taxId") ? styles.CompanyRow : ""}`}>
              { billingFields.fields.includes("businessName") ? <FormField name="businessName" title={t(`Company name${addOptionalLabel("businessName")}`)} placeholder={t("Apple inc.")} error={touched.businessName && errors.businessName ? t(errors.businessName) : null} /> : null }
              { billingFields.fields.includes("taxId") ? <FormField name="taxId" title={t("CNPJ")} placeholder={t("13.339.532/0001-09")} error={touched.taxId && errors.taxId && t(errors.taxId)} /> : null}
            </div>
            { billingFields.fields.includes("email") ? <FormField name="email" title={t("Email")} placeholder={t("example@copr.com")} error={touched.email && errors.email ? t(errors.email) : null} /> : null }
            { billingFields.fields.includes("line1") ? <FormField name="line1" title={t(`Street address${addOptionalLabel("line1")}`)} placeholder={t("R. São Paulo, 45")} error={touched.line1 && errors.line1 ? t(errors.line1) : null} /> : null}
            <div className={styles.HalfRow}>
              { billingFields.fields.includes("city") ? <FormField name="city" title={t(`City${addOptionalLabel("city")}`)} placeholder={t("São Paulo")} error={touched.city && errors.city ? t(errors.city) : null } /> : null}
              { billingFields.fields.includes("state") ? <FormField name="state" title={t(`State / Province${addOptionalLabel("state")}`)} placeholder={t("Campos Elísios")} error={touched.state && errors.state ? t(errors.state) : null} /> : null}
            </div>
            { billingFields.fields.includes("postalCode") ? <div className={styles.HalfRow}>
              <FormField name="postalCode" title={t(`Postal code${addOptionalLabel("postalCode")}`)} placeholder={t("14085-10")} error={touched.postalCode && errors.postalCode ? t(errors.postalCode) : null} />
            </div> : null}
          </div>
        </Form>
      )}
    </Formik>
  );
})

export default BillingDetailsForm;
