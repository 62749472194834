import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import TextInputControl from "components/controls/TextInputControlNew";
import { useEffect, useState } from "react";
import { hrsMinsToMins, minsToHrsMins } from "utils/common";

const Wrapper = styled.div``;

type TimeInputWithoutLimitsProps = {
  disabled?: boolean;
  locked?: boolean;
  value: number;
  onChange: (num: number) => void;
};

const TimeInputWithoutLimits = ({ value, disabled, locked, onChange }: TimeInputWithoutLimitsProps) => {
  const { t } = useTranslation(TranslationNamespaces.common);

  const [val, setValue] = useState<string>(minsToHrsMins(value || 0));

  useEffect(() => {
    setValue(minsToHrsMins(value || 0));
  }, [value]);

  return (
    <Wrapper>
      <TextInputControl
        disabled={disabled}
        locked={locked}
        value={val}
        onChange={({ target }) => {
          setValue(target.value.replace(/[^0-9:]/g, ""));
        }}
        onBlur={({ target }) => {
          let inputVal = target.value.replace(/[^0-9:]/g, "");

          if (inputVal.indexOf(":") === -1) {
            inputVal = `${inputVal}:00`;
          }

          onChange(hrsMinsToMins(inputVal));
        }}
        placeholder="00:00"
      />
    </Wrapper>
  );
};

export default TimeInputWithoutLimits;
