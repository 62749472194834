import { Component } from "react";
import BEM from "utils/BEM";
import { withRouter } from "react-router-dom";
import { isInclusivelyAfterDay } from "react-dates";
import SettingsLayout from "components/Layout/SettingsLayout";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import "components/UI/Page/Page.scss";
import "styles/rep-details.scss";
import { getRep, addRep, updateRep, deleteRep, getCurrentCompany } from "utils/apiHelpers";
import { urlParam, getTitle } from "utils/common";
import TextInputControl from "components/controls/TextInputControl";
import FieldTabs from "components/controls/FieldTabs";
import { withTranslation } from "react-i18next";
import CheckboxControl from "components/UI/CheckboxControl";
import Select from "components/UI/Select";
import NotificationRow from "components/NotificationRow";
import * as moment from "moment";
import PegadorRepFormModel from "components/Pegador/Rep/Form/Model";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import PortField from "./PortField";
import LocationsDropdownControl from "./LocationsDropdownControl";

const b = BEM.b("rep-details");
const p = BEM.b("page");

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

class PegadorRepForm extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    let repName = "";
    if (urlParam("name")) {
      repName = urlParam("name");
    }
    this.PUNCHES_TYPES_LIST = [
      {
        label: t("Entry"),
        value: 0,
      },
      {
        label: t("Exit"),
        value: 1,
      },
      {
        label: t("Both Entry and Exit"),
        value: 2,
      },
    ];
    this.state = {
      rep: this.props.rep || {},
      name: {
        value: repName,
      },
      externalId: {
        value: "",
      },
      fabricNumber: {
        value: "",
      },
      host: {
        value: "",
      },
      port: {
        value: "",
      },
      locationId: "",
      repPunchesType: this.PUNCHES_TYPES_LIST[2].value,
      loaded: this.props.repId === "add-automatic" || this.props.repId === "add-manual" || this.props.repId === "add",
      isFetching: false,
      activeType: "online",
      startDate: moment(),
      startSyncFromActive: false,
      notification: "",
      errors: null,
      modelData: {},
      isManual: this.props.repId === "add-manual",
    };

    if (this.props.repId === "add" || this.props.repId === "add-automatic" || this.props.repId === "add-manual") {
      document.title = getTitle(t("Add a New Time Clock"));
    }
  }
  componentDidMount() {
    this.getState({
      ...this.props,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState({
      ...nextProps,
    });
  }
  getState(props) {
    if (
      this.props.repId !== "add" &&
      this.props.repId !== "add-automatic" &&
      this.props.repId !== "add-manual" &&
      !this.state.isFetching
    ) {
      getCurrentCompany().then((company) => {
        getRep({
          companyUuid: company.uuid,
          repId: this.props.repId,
        }).then((r) => {
          const repPunchesType = this.PUNCHES_TYPES_LIST.find((m) => m.value === r.rep.actionType).value;
          this.setState({
            loaded: true,
            rep: r.rep,
            externalId: {
              value: r.rep.externalId,
              error: "",
            },
            locationId: r.rep.locationId,
            name: {
              value: r.rep.name,
              error: "",
            },
            host: {
              value: r.rep.host,
              error: "",
            },
            port: {
              value: r.rep.port,
              error: "",
            },
            fabricNumber: {
              value: r.rep.serialNumber,
              error: "",
            },
            startDate: r.rep.startSyncFrom ? moment(r.rep.startSyncFrom) : moment(),
            isManual: r.rep.isManual,
            startSyncFromActive: r.rep.startSyncFrom,
            repPunchesType: repPunchesType || repPunchesType === 0 ? repPunchesType : this.PUNCHES_TYPES_LIST[2].value,
            isFetching: false,
          });
        });
      });

      this.setState({
        isFetching: true,
        loaded: false,
      });
    }
  }
  getFormErrors() {
    const { t } = this.props;
    const { name, externalId, fabricNumber } = this.state;
    let errors = {};

    if (!name.value) {
      errors.name = t("Name can't be empty");
    }
    if (!externalId.value) {
      errors.externalId = t("External ID can't be empty");
    }
    if (!fabricNumber.value) {
      errors.fabricNumber = t("Fabric number can't be empty");
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }
  onExternalIdChange(val) {
    this.setState({
      externalId: {
        value: val,
      },
    });
  }
  onNameChange(val) {
    this.setState({
      name: {
        value: val,
      },
    });
  }
  onFabricNumberChange(val) {
    this.setState({
      fabricNumber: {
        value: val,
      },
    });
  }

  onPortChange(val) {
    this.setState({
      port: {
        value: val,
      },
    });
  }
  onHostChange(val) {
    this.setState({
      host: {
        value: val,
      },
    });
  }
  typeChange(tab, ev) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  onDeleteRepClick(repId) {
    deleteRep({
      companyUuid: window.global_store.company.uuid,
      repId,
    })
      .then((r) => {
        this.setState({
          notification: {
            message: `${this.props.t("You deleted REP")}: ${this.state.rep.name}`,
          },
        });
        setTimeout(() => {
          this.props.history.push("/time-clocks/");
        }, 1000);
      })
      .catch((e) => alert(e));
  }
  onDisableRepClick(repId) {
    updateRep({
      companyUuid: window.global_store.company.uuid,
      repId,
      body: {
        active: false,
      },
    })
      .then((r) => {
        this.setState({
          notification: {
            message: `${this.props.t("You deactivated REP")}: ${this.state.rep.name}`,
          },
        });
        this.getState();
      })
      .catch((e) => alert(e));
  }
  onEnableRepClick(repId) {
    updateRep({
      companyUuid: window.global_store.company.uuid,
      repId,
      body: {
        active: true,
      },
    })
      .then((r) => {
        this.setState({
          notification: {
            message: `${this.props.t("You activated REP")}: ${this.state.rep.name}`,
          },
        });
        this.getState();
      })
      .catch((e) => alert(e));
  }
  onSaveRepClick() {
    const errors = this.getFormErrors();
    const {
      name,
      externalId,
      locationId,
      fabricNumber,
      rep,
      modelData,
      repPunchesType,
      startDate,
      startSyncFromActive,
      host,
      isManual,
    } = this.state;
    if (!errors) {
      const body = {
        name: name.value,
        externalId: `${externalId.value}`,
        locationId: locationId || null,
        serialNumber: fabricNumber.value,
        host: host.value,
        actionType: repPunchesType,
        port: this.port ? parseInt(this.port.getValue()) : null,
        isManual,
        startSyncFrom: startSyncFromActive && startDate ? startDate.utc().format() : null,
        ...modelData,
      };

      if (this.props.repId === "add-automatic" || this.props.repId === "add-manual" || this.props.repId === "add") {
        addRep({
          companyUuid: window.global_store.company.uuid,
          body,
        })
          .then((r) => this.props.history.push("/time-clocks/"))
          .catch((e) => alert(e));
      } else {
        updateRep({
          companyUuid: window.global_store.company.uuid,
          repId: rep.id,
          body,
        })
          .then((r) => this.props.history.push("/time-clocks/"))
          .catch((e) => alert(e));
      }
    } else {
      const error = Object.keys(errors)[0];
      this.setState({
        notification: { message: errors[error], type: "error" },
      });
    }
  }
  onStartDateChange = (startDate) => {
    this.setState({ startDate });
  };

  onStartSyncFromChange(checked) {
    this.setState({ startSyncFromActive: checked });
  }
  render() {
    const {
      rep,
      loaded,
      externalId,
      locationId,
      name,
      host,
      port,
      fabricNumber,
      isManual,
      repPunchesType,
      startSyncFromActive,
      errors,
      startDate,
      notification,
    } = this.state;
    const { t, history, repId } = this.props;

    if (!loaded) {
      return t("Loading...");
    }
    if (this.props.repId !== "add" && this.props.repId !== "add-automatic" && this.props.repId !== "add-manual") {
      document.title = getTitle(`${rep.externalId} - ${rep.model}`);
    }

    return (
      <SettingsLayout
        title={
          ["add", "add-automatic", "add-manual"].includes(repId) ? (
            t("Add a Time Clock")
          ) : (
            <>
              {t("Edit Time Clock")}

              <div
                className={b("status", {
                  active: rep.active,
                })}
              >
                {rep.active ? t("active") : t("inactive")}
              </div>
            </>
          )
        }
        backButtonOnclick={() => history.push("/time-clocks/")}
        backButtonTitle={t(`${TranslationNamespaces.sidebar}|REPs List`)}
      >
        <div className={`${p()} ${b()}`}>
          {notification && (
            <NotificationRow
              employeesPage
              onClose={() =>
                this.setState({
                  notification: "",
                })
              }
              type={notification.type || "success"}
              message={notification.message}
            />
          )}
          <div className={p("content")}>
            <div>
              <div className={b("id-wrapper")}>
                <div className={b("label")}> {t("ID")} </div>
                <TextInputControl
                  value={externalId.value || ""}
                  error={externalId.error}
                  onChange={this.onExternalIdChange.bind(this)}
                />
              </div>
              <div className={b("name-wrapper")}>
                <div className={b("label")}> {t("REP Name")} </div>
                <TextInputControl value={name.value || ""} error={name.error} onChange={this.onNameChange.bind(this)} />
              </div>
            </div>
            <LocationsDropdownControl
              t={this.props.t}
              value={locationId}
              onChange={(val) => this.setState({ locationId: val })}
            />

            <div className={b("label")}>{t("Fabrication number")} </div>
            <TextInputControl
              value={fabricNumber.value}
              error={fabricNumber.error}
              onChange={this.onFabricNumberChange.bind(this)}
            />

            <div className={b("label")}>{t("Connection type")}</div>
            <FieldTabs
              active={isManual ? "manual" : "automatic"}
              onChange={(type) => this.setState({ isManual: type === "manual" })}
              tab1={{
                value: "automatic",
                label: t("Online"),
              }}
              tab2={{
                value: "manual",
                label: t("Manual"),
              }}
            />
            <div className={b("label")}> {t("Rep punches type")} </div>
            <Select
              modifiers={{ field: true }}
              onChange={(val) =>
                this.setState({
                  repPunchesType: val,
                })
              }
              error={errors && errors.repPunchesType}
              value={repPunchesType}
              options={this.PUNCHES_TYPES_LIST}
            />
            <div className={b("start-sync-from")}>
              <CheckboxControl
                checked={startSyncFromActive || false}
                id="start-sync-from"
                onChange={this.onStartSyncFromChange.bind(this)}
              />
              <label className={b("label")}>{t("Sync from specific date")}</label>
            </div>
            {startSyncFromActive && <div className={b("label")}> {t("Start Sync date")} </div>}
            {startSyncFromActive && (
              <SingleDatePickerControl
                value={startDate}
                onChange={this.onStartDateChange}
                isOutsideRange={(day) => isInclusivelyAfterDay(day, moment().add(1, "days"))}
                disabled={!startSyncFromActive}
              />
            )}
            {!isManual && <div className={b("label")}> {t("Brand and Model")} </div>}
            {!isManual && (
              <PegadorRepFormModel
                onChange={(modelData) => this.setState({ modelData })}
                key={this.state.rep.id || "new"}
                rep={this.state.rep}
                hostPortWrapper={
                  <div>
                    <div className={b("host-wrapper")}>
                      <div className={b("label")}> {t("Host")} </div>
                      <TextInputControl value={host.value} error={host.error} onChange={this.onHostChange.bind(this)} />
                    </div>
                    <div className={b("port-wrapper")}>
                      <div className={b("label")}> {t("Port")} </div>
                      <PortField
                        value={port.value}
                        isValid={!port.error}
                        onChange={this.onPortChange.bind(this)}
                        onRef={(ref) => (this.port = ref)}
                      />
                    </div>
                  </div>
                }
              />
            )}

            {rep.status && <div className={b("label")}> {t("Last synched at")} </div>}
            <div>{rep.status ? moment(rep.status).utc().format("HH:mm - DD/MM/YYYY") : ""}</div>
          </div>

          <div className={b("save")}>
            <div className={b("save-button")} onClick={this.onSaveRepClick.bind(this)}>
              {t("Save")}
            </div>
          </div>

          {this.props.repId !== "add" && this.props.repId !== "add-automatic" && this.props.repId !== "add-manual" && (
            <div className={b("footer")}>
              <div className={b("delete-rep")} onClick={this.onDeleteRepClick.bind(this, rep.id)}>
                {t("Delete REP")}
              </div>
              {rep.active ? (
                <div className={b("delete-rep")} onClick={this.onDisableRepClick.bind(this, rep.id)}>
                  {t("Disable REP")}
                </div>
              ) : (
                <div className={b("delete-rep")} onClick={this.onEnableRepClick.bind(this, rep.id)}>
                  {t("Enable REP")}
                </div>
              )}
              <div className={b("footer-text")}>{t("When deleting the REP, all points will be excluded")}</div>
            </div>
          )}
        </div>
      </SettingsLayout>
    );
  }
}

export default withRouter(withTranslation([TranslationNamespaces.reps, TranslationNamespaces.sidebar])(PegadorRepForm));
