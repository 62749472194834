import {
  Component,
  CSSProperties,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  RefObject,
  FocusEvent,
  ChangeEvent,
} from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;

  &.disabled input {
    font-weight: var(--typography-font-weight-default);
    font-style: normal;
    color: var(--colors-surface-400);
    border-color: var(--colors-surface-150);
    background-color: var(--colors-unknown6);
    cursor: default;
  }

  &.locked input {
    padding-inline-end: 29px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 15'%3E%3Cpath d='M11 5.886V4.687C11 2.103 8.757 0 6 0S1 2.103 1 4.688v1.198C.405 6.21 0 6.809 0 7.5v5.625C0 14.159.897 15 2 15h8c1.103 0 2-.84 2-1.875V7.5c0-.691-.405-1.29-1-1.614zM6 1.875c1.654 0 3 1.262 3 2.813v.937H3v-.938c0-1.55 1.346-2.812 3-2.812zm1 9.01v1.303H5v-1.303a1.361 1.361 0 01-.5-1.041c0-.777.672-1.406 1.5-1.406s1.5.63 1.5 1.406c0 .415-.195.784-.5 1.041z' fill='%23D2DEEE' fill-opacity='.8'/%3E%3C/svg%3E");
    background-size: 12px 15px;
    background-repeat: no-repeat;
    background-position-y: 50%;
    ${(p) => (p.theme.dir === "ltr" ? "background-position-x: calc(100% - 17px);" : "background-position-x: 17px;")}
    background-color: var(--colors-default);
    color: var(--colors-mainText);
    border-color: var(--colors-surface-400);
    cursor: default;

    &:placeholder-shown {
      border-color: var(--colors-surface-400);
    }
  }

  &.password .eye-button {
    position: absolute;
    top: 2px;
    inset-inline-end: 12px;
    content: "";
    width: 19px;
    height: 46px;
    border: none;
    outline: none;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.422 5.771c.976 1.128 4.272 4.578 8.077 4.578 3.813 0 7.102-3.448 8.076-4.577-.976-1.129-4.271-4.578-8.076-4.578-3.814 0-7.102 3.448-8.077 4.577zm-1.304.353a.575.575 0 010-.705C1.288 5.198 5.332 0 10.498 0s9.211 5.198 9.38 5.42a.574.574 0 010 .704c-.169.221-4.214 5.419-9.38 5.419s-9.21-5.198-9.38-5.42z' fill='%238093AC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.499 2.868A2.481 2.481 0 008.02 5.347 2.481 2.481 0 0010.5 7.825a2.481 2.481 0 002.478-2.478A2.481 2.481 0 0010.5 2.868zm0 6.196A3.722 3.722 0 016.78 5.347 3.722 3.722 0 0110.5 1.629a3.722 3.722 0 013.718 3.718 3.722 3.722 0 01-3.718 3.717z' fill='%238093AC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;

    cursor: pointer;
  }

  &.onboarding {
    input {
      height: 48px;
      padding: 0 12px 0 16px;
      font-size: 16px;
      border-color: var(--colors-surface-150);
      border-width: 2px;

      &::placeholder {
        font-size: 16px;
        color: var(--colors-surface-400);
      }
    }
  }

  &.password {
    input {
      padding-inline-end: 31px;
    }
  }

  input {
    padding: 7.5px 10px;
    width: 100%;
    line-height: 14px;
    font-size: 15px;
    font-weight: var(--typography-font-weight-default);
    color: var(--colors-mainText);
    border: 1px solid var(--colors-surface-400);
    border-radius: var(--shapes-border-radius-default);

    &:focus {
      outline: none;
      border-color: var(--colors-primary);
    }
    &::placeholder {
      font-size: var(--typography-font-size-default);
      color: var(--colors-secondary);
    }

    &:placeholder-shown {
      border-color: var(--colors-surface-150);
    }
  }

  &.error input {
    border: 1px solid var(--colors-error);
  }

  &.error.onboarding input {
    border: 2px solid var(--colors-error);
  }
`;

interface TextInputControlNewProps {
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  locked?: boolean;
  error?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  // mask: unknown;
  size?: number;
  style?: CSSProperties;
  name?: string;
  onboarding?: boolean;
  className?: string;
  password?: boolean;
  innerRef?: RefObject<HTMLInputElement>;
  onBlur?: (ev: FocusEvent) => void;
  onFocus?: (ev: FocusEvent) => void;
  ["data-tip"]?: string;
  ["data-for"]?: string;
}

interface TextInputControlNewState {
  type?: HTMLInputTypeAttribute;
}

class TextInputControl extends Component<TextInputControlNewProps, TextInputControlNewState> {
  readonly state: Readonly<TextInputControlNewState> = { type: "" };

  getClasses({
    error,
    disabled,
    locked,
    onboarding,
    password,
    className,
  }: Record<string, boolean | string | undefined>) {
    const classes = [];
    if (className) {
      classes.push(className);
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (locked) {
      classes.push("locked");
    }
    if (error) {
      classes.push("error");
    }
    if (onboarding) {
      classes.push("onboarding");
    }
    if (password) {
      classes.push("password");
    }

    return classes.join(" ");
  }

  render() {
    const {
      disabled,
      locked,
      error,
      placeholder,
      value,
      onChange,
      size,
      style,
      name,
      onboarding,
      className,
      password,
      innerRef,
      onBlur,
      onFocus,
    } = this.props;
    const type = this.state.type || this.props.type;
    const inputProps: Partial<InputHTMLAttributes<HTMLInputElement> & { ["data-tip"]: string; ["data-for"]: string }> =
      {
        disabled,
        placeholder,
        value,
        onChange,
        type,
        size,
        style,
        name,
        onBlur,
        onFocus,
      };

    if (this.props["data-tip"] || this.props["data-for"]) {
      inputProps["data-tip"] = this.props["data-tip"];
      inputProps["data-for"] = this.props["data-for"];
    }

    return (
      <Wrapper className={this.getClasses({ disabled, locked, className, error, onboarding, password })}>
        <input ref={innerRef} {...inputProps} />

        {password ? (
          <div
            className="eye-button"
            onClick={(ev) => {
              ev.preventDefault();
              this.setState({ type: type === "text" ? this.props.type : "text" });
            }}
          />
        ) : null}
      </Wrapper>
    );
  }
}

export default TextInputControl;
