import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import LoginPage from "components/Onboarding/LoginPage";
import { urlParam } from "utils/common";
import { useHubspot } from "hooks/useHubspot";

const RouteLogin = (routeProps: RouteComponentProps) => {
  let trafficSource: string | undefined;
  let trafficMedium: string | undefined;
  let trafficCampaing: string | undefined;
  let email: string | undefined;

  const trafficSourceParam = urlParam("utm_source");
  if (trafficSourceParam) {
    trafficSource = trafficSourceParam;
  }
  const trafficMediumParam = urlParam("utm_medium");
  if (trafficMediumParam) {
    trafficMedium = trafficMediumParam;
  }
  const trafficCampaingParam = urlParam("utm_campaign");
  if (trafficCampaingParam) {
    trafficCampaing = trafficCampaingParam;
  }
  const emailParam = urlParam("email");
  if (emailParam) {
    email = emailParam;
  }

  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path="/login"
          render={() => (
            <LoginPage
              trafficSource={trafficSource}
              trafficMedium={trafficMedium}
              email={email}
              trafficCampaing={trafficCampaing}
              {...routeProps}
            />
          )}
        />

        <Redirect from="/login/" to="/login" />
      </Switch>
    </Suspense>
  );
};

export default RouteLogin;
