import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import ga, { GaApprovePunchButtonLocation } from "utils/ga";
import { Punch, PunchStatuses, PunchValidation } from "types/models/punches";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { OnPunchActionData } from "context/SuperpunchProvider/types";
import { SuperpunchPunchCellData } from "types/models/superpunch";
import DropdownHeader from "./DropdownHeader";
import { disablePunchActions } from "../helpers";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PunchAction = styled.div`
  position: relative;
  padding: 8px 16px;
`;

type DropdownHeaderWithActionButtonProps = {
  title?: string;
  onBackClick?: () => void;
  onHistoryClick?: () => void;
  onPunchClear?: () => void;
  employeeUuid: string;
  onPunchApprove: (data: OnPunchActionData) => void;
  onPunchValidate: (data: OnPunchActionData) => void;
  cellData: SuperpunchPunchCellData;
  date: moment.Moment;
  selectedPunch: Punch | null;
};

const DropdownHeaderWithActionButton = ({
  title,
  onBackClick,
  onHistoryClick,
  employeeUuid,
  onPunchApprove,
  onPunchValidate,
  cellData,
  date,
  selectedPunch,
  onPunchClear,
}: DropdownHeaderWithActionButtonProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  let isPending = false;
  let canBeValidated = false;

  if (cellData.raw) {
    isPending = (cellData.raw.status === PunchStatuses.pending || !cellData.raw.status) && !!selectedPunch;
    canBeValidated = cellData.raw.validation === PunchValidation.invalid;
  }

  const disableButton = disablePunchActions(employeeUuid);

  const showActionButton = !disableButton && (isPending || canBeValidated);

  return (
    <Wrapper>
      {title && (
        <DropdownHeader
          title={title}
          onBackClick={onBackClick}
          onHistoryClick={onHistoryClick}
          onClearClick={onPunchClear}
        />
      )}
      {showActionButton && (
        <PunchAction>
          {isPending && canBeValidated && (
            <Button
              state={ButtonState.secondary}
              value={t("Approve and Validate")}
              onClick={() => {
                if (selectedPunch && cellData.raw) {
                  ga.trackApprovePunch({
                    punch_type: selectedPunch.punch_type,
                    button_location: GaApprovePunchButtonLocation.superPunch,
                  });

                  onPunchApprove({
                    destinationDate: date,
                    punchUuid: cellData.raw.uuid,
                    punchId: cellData.raw.id,
                    punchKey: cellData.raw.key,
                    employeeUuid,
                  });
                }
              }}
            />
          )}
          {isPending && !canBeValidated && (
            <Button
              state={ButtonState.secondary}
              value={t("Approve Punch")}
              onClick={() => {
                if (selectedPunch && cellData.raw) {
                  ga.trackApprovePunch({
                    punch_type: selectedPunch.punch_type,
                    button_location: GaApprovePunchButtonLocation.superPunch,
                  });

                  onPunchApprove({
                    destinationDate: date,
                    punchUuid: cellData.raw.uuid,
                    punchId: cellData.raw.id,
                    punchKey: cellData.raw.key,
                    employeeUuid,
                  });
                }
              }}
            />
          )}
          {!isPending && canBeValidated && (
            <Button
              state={ButtonState.secondary}
              value={t("Validate Punch")}
              onClick={() => {
                !!cellData.raw &&
                  onPunchValidate({
                    destinationDate: date,
                    punchUuid: cellData.raw.uuid,
                    punchId: cellData.raw.id,
                    punchKey: cellData.raw.key,
                    employeeUuid,
                  });
              }}
            />
          )}
        </PunchAction>
      )}
    </Wrapper>
  );
};

export default DropdownHeaderWithActionButton;
