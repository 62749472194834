import { ProjRes } from "services/oitchau-api";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { StoreApi, UseBoundStore } from "zustand/esm";
import { useContext } from "react";
import { GlobalContext } from "context/GlobalContextProvider";
import { TrackTimeStore } from "../TrackTime.store";
import { NothingFound } from "./NothingFound";
import { ProjectRow } from "./ProjectRow";
import { TaskOption } from "./TaskOption";
import { filterLocationsBasedOnProject } from "../time-tracker.utils";

export type TaskOptionType = {
  task: {
    uuid: string;
    name: string;
  };
  searchTerms: string[];
}
export type ProjectOption = {
  project: ProjRes;
  taskOptions: TaskOptionType[];
}

export const TaskOptions = ({
   searchValue,
   options,
   selectedTaskUuid,
   onCreateTask,
   updateActiveActivity,
   canAddTask,
   store,
 }: {
searchValue: string,
options: ProjectOption[],
selectedTaskUuid?: string,
onCreateTask: (taskName: string, project: ProjRes) => void,
updateActiveActivity: (act: any) => void,
canAddTask: boolean,
store: UseBoundStore<StoreApi<TrackTimeStore>>
}): JSX.Element | JSX.Element[] => {
  const {t} = useTranslation(TranslationNamespaces.modalPunch);
  const {activity, setActivity, setTargetProject, setShowTaskPicker, targetProject, projects, locations} = store();
  const context = useContext(GlobalContext);

  const onSelectTask = async (task: {uuid: string, name: string}, project: ProjRes) => {
    const item = { ...activity, taskUuid: task.uuid, projectUuid: project.uuid, task, project };
    if (activity?.project?.uuid !== project.uuid) {
      item.customFields = [];
    }
    if (activity?.uuid) {
      void updateActiveActivity(item);
    }
    const locs = filterLocationsBasedOnProject(projects, locations, project.uuid);
    let selectedLoc = locs.find(l => l.uuid === activity?.locationUuid || activity?.location?.uuid);
    if (!selectedLoc) {
      const company = await context.getCompany();
      selectedLoc = locs.find(l => l.uuid === company.default_location.uuid) ?? locs[0];
    }
    item.locationUuid = selectedLoc!.uuid;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.location = selectedLoc;
    setTargetProject(project);
    setActivity(item);

    setShowTaskPicker(false);
  }

  if (searchValue === "") {
    // !searchValue && !options
    if (options.length === 0) return (
      <NothingFound title={t("No items")} description={t("Start from creating a project")}/>
    );
    // !searchValue && options
    return options.map((prjOpt) => (<>
      <ProjectRow
        project={prjOpt}
        key={prjOpt.project.uuid}
        swapChildren={prjOpt?.taskOptions?.length === 0}
        onCreateTask={onCreateTask}
        withTasksSelection
        selectedProjectUuid={targetProject?.uuid || activity?.project?.uuid}
        canAddTask={canAddTask}
        store={store}
      >
        {prjOpt?.taskOptions?.map((tskOpt) => (
          <TaskOption
            key={tskOpt.task.uuid}
            task={tskOpt.task}
            selected={activity?.task?.uuid !== undefined && tskOpt.task.uuid === activity?.task?.uuid}
            onSelect={async () => await onSelectTask(tskOpt.task, prjOpt.project)}
            nested
          />
        ))}
        {prjOpt?.taskOptions?.length === 0 ? (
          <NothingFound title={t("No tasks")} description={t("Start by creating your first task")}/>
        ) : null}
      </ProjectRow>
    </>))
  }
  // searchValue && !options
  if (options.length === 0) return (
    <NothingFound large title={t("Nothing found")} description={t("Try a different keyword or create a new task")} />
  );

  // searchValue && options
  return options.map((prjOpt) => (
    <ProjectRow
      key={prjOpt.project.uuid}
      project={prjOpt}
      simple
      selectedProjectUuid={targetProject?.uuid || activity?.project?.uuid}
      canAddTask={canAddTask}
      onCreateTask={() => {}}
      store={store}
    >
      <div className="SearchResults">
        {prjOpt.taskOptions.map((tskOpt) => (
          <TaskOption
            key={tskOpt.task.uuid}
            task={tskOpt.task}
            selected={selectedTaskUuid !== undefined && tskOpt.task.uuid === selectedTaskUuid}
            searchValue={searchValue}
            nested
            onSelect={async () => await onSelectTask(tskOpt.task, prjOpt.project)}
          />
        ))}
      </div>
    </ProjectRow>
  ));
}
