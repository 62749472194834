import { DigitalSignatureStatus } from "./digitalSignature";
import { FilteredEmployeeProfile, UserProfile } from "./userProfile";

enum PayrollLockStatus {
  locked = "locked",
  unlocked = "unlocked",
  partial = "partial",
}

type UserProfilePayrollLock = {
  uuid: string;
  userProfileUuid: string;
  payrollLockUuid: string;
  payrollGroupUuid: string;
  companyUuid: string;
  startDate: string;
  endDate: string;
  status: PayrollLockStatus;
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
};

type PayrollLock = {
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  digitalSignatureStatus: DigitalSignatureStatus | null;
  digitalSignatureStatusChangedAt: string | null;
  endDate: string;
  forDeactivatedProfile: boolean;
  name: string;
  payrollGroupUuid: string;
  startDate: string;
  status: PayrollLockStatus;
  /** integer but string */
  userProfilesCount: string;
  /** for_decativated_profile: true */
  userProfilePayrollLocks?: UserProfilePayrollLock[];
  uuid: string;
  /** added by FE */
  deactivatedEmployeeProfile?: UserProfile | FilteredEmployeeProfile;
  title?: string;
};

type DeactivatedPayrollLock = Omit<PayrollLock, "userProfilePayrollLocks"> &
  Pick<UserProfilePayrollLock, "userProfileUuid">;

type PayrollLockReport = {
  active: boolean;
  avatarId: unknown | null; // todo
  businessRulesGroups: {
    name: string;
    uuid: string;
  }[];
  cpf: string;
  cumulativeHoursBankMinutes: number;
  cumulativeHoursBankPhases: Record<string, number> & { negative: number }; // uuid: number
  defaultTimeZone: string;
  department: string;
  digitalSignatureMetadata: {
    createdAt: string;
    createdBy: string;
    signedAt: string | null;
    signedBy: string | null;
    status: "pending" | "missing";
  };
  dsr: number;
  dsrWorkingDays: number;
  endDate: string;
  externalId: unknown | null; // todo
  fullName: string;
  id: number;
  matricula: string;
  missedDays: number;
  pis: string;
  positionTitle: string | null;
  schedules: {
    name: string;
    uuid: string;
  }[];
  sourceReportType: "detailed"; // todo add more
  startDate: string;
  status: "completed"; // todo add more
  supervisorFullName: string;
  totalBreakMinutes: number;
  totalCrossShiftsIntervalDiffMinutes: number;
  totalEarlyLeaveMinutes: number;
  totalExtraHoursMinutes: number;
  totalExtraHoursPhases: Record<string, number>; // uuid: number
  totalHoursBankMinutes: number;
  totalHoursBankNegativeMinutes: number;
  totalHoursBankPhases: Record<string, number> & { negative: number }; // uuid: number
  totalHoursBankPositiveMinutes: number;
  totalLateEntryMinutes: number;
  totalMedicalAbsenceMinutes: number;
  totalMissedMinutes: number;
  totalMissedMinutesBeforeCompensation: number;
  totalNightMinutes: number;
  totalNightReducedMinutes: number;
  totalNightShiftMinutes: Record<number, number>;
  totalNightShiftOvertimeMinutes: number;
  totalOnCallMinutes: {
    activated: number;
    onCall: number;
  };
  totalPlannedMinutes: number;
  totalWorkedInHolidayMinutes: number;
  totalWorkedMinutes: number;
  userProfileUuid: string;
  workedDays: number;
  workedHolidays: number;
  lockStatus: Exclude<PayrollLockStatus, PayrollLockStatus.partial>;
};

type PayPeriodRange = { isGroupActive: boolean; start: string; end: string };

type PayPeriod = {
  year: number;
  months: {
    month: number;
    ranges: PayPeriodRange[];
  }[];
};

type DefaultPayPeriod = PayPeriod;

export type { PayrollLock, PayrollLockReport, DefaultPayPeriod, PayPeriod, PayPeriodRange, DeactivatedPayrollLock };

export { PayrollLockStatus };
