import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import styled from "styled-components";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import { GlobalContext } from "context/GlobalContextProvider";
import { useAsyncCallback } from "utils/useAsyncEffect";
import {
  getBRGroups,
  getLockGroupsList,
  getProfileUuidsWithFilters,
  getSchedules,
  listUserProfilesWIthFilters,
} from "utils/api/company";
import {
  EmployeeHolidayGroup,
  EmployeePayrollGroup,
  EmployeesBusinessRulesGroup,
  EmployeeSchedule,
  FilteredEmployeeProfile,
  FilteredEmployeesExternalFields,
  UserProfileRole,
} from "types/models/userProfile";
import { formatPhone, getEmployeeTaxIdTranslation, getEmployeeTaxPayerType } from "utils/common";
import { LocalStorageKeys } from "utils/localStorageUtils";
import { translateEmployeeTerm } from "utils/translationHelpers";
import * as images from "components/svg-images";
import Avatar from "components/views/Avatar";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "components/UI/GridTable/GridTable.scss";
import FullPage from "components/Layout/FullPage";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import { ButtonState } from "components/controls/StyledButton";
import { SubscriptionContext } from "components/Billing/context/subscription-context.provider";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { Schedule } from "types/models/schedule";
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import { getDepartments } from "utils/api/department";
import { Department } from "types/models/department";
import { fireDownloadEmployees, getLocations, getPositions, getSubsidiaries, getTeams } from "utils/apiHelpers";
import { Subsidiary } from "types/models/subsidiary";
import CheckboxControl from "components/UI/CheckboxControl";
import { GridActionsDropdown } from "components/UI/GridTable/GridActionsDropdown";
import { HeaderAction } from "components/styled/Page";
import { ImportEmployee } from "components/DataManagement/ImportEmployee";
import { APIResponse } from "utils/api/common";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { Team } from "types/models/team";
import { ColumnSelectorToolPanel } from "components/UI/GridTable/ColumnSelectorToolPanel";
import StatusBadge from "components/controls/StatusBadge";
import { MultilineRenderer } from "components/UI/GridTable/renderers/Multiline.renderer";
import { BusinessRuleGroup, BusinessRuleGroupStatus } from "types/models/businessRulesGroup";
import { HolidayGroup, HolidayGroupStatus } from "types/models/holidayGroup";
import { PayrollGroup, PayrollGroupStatus } from "types/models/payrollGroup";
import { Position } from "types/models/position";
import { Location } from "types/models/location";
import {
  emptyValueFormatter,
  getGroupName,
  getGroupNameAndDescription,
  getImageUrlsWithParams,
} from "components/UI/GridTable/grid.helpers";
import { GridTable, restoreState } from "components/UI/GridTable/GridTable";
import { listHolidaysGroups } from "components/Holidays/holidayApiUtils";
import Overlay from "components/UI/Overlay";
import { TagRenderer } from "components/UI/GridTable/renderers/Tag.renderer";
import sentryUtils from "utils/sentryUtils";
import EmployeeDetailsNew from "./EmployeeDetailsNew";
import ActionsBar from "./ActionsBar";
import EmployeeSearchControl from "./EmployeeSearchControl";

const StyledFullImg = styled.img`
  position: relative;
  border-radius: 10px;
`;

const FullImgWrapper = styled.div`
  position: relative;
  z-index: 100000;
`;

const CloseBtn = styled.button`
  position: absolute;
  inset-inline-end: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 4.293a1 1 0 00-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 001.414 1.414L12 13.414l6.293 6.293a1 1 0 001.414-1.414L13.414 12l6.293-6.293a1 1 0 00-1.414-1.414L12 10.586 5.707 4.293z' fill='%23767676'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px 16px;

  .Search {
    width: 180px;
    position: relative;
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);

    input {
      border: none;
      outline: none;
      width: 100%;
      height: 32px;
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-700);

      &::placeholder {
        color: var(--colors-surface-700);
      }
    }
  }

  .Flex {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
const dictionary: Record<string, { filter: string; sort: string }> = {
  schedules: { filter: "schedules.uuid", sort: "schedules.lc_name" },
  positions: { filter: "positions.uuid", sort: "positions.title" },
  payrollGroups: { filter: "payrollGroups.uuid", sort: "payrollGroups.lc_name" },
  holidayGroups: { filter: "holidayGroups.uuid", sort: "holidayGroups.lc_name" },
  departments: { filter: "departments.uuid", sort: "departments.lc_name" },
  subsidiaries: { filter: "subsidiaries.uuid", sort: "subsidiaries.lc_name" },
  businessRulesGroups: { filter: "businessRulesGroups.uuid", sort: "businessRulesGroups.lc_name" },
  locations: { filter: "locations.uuid", sort: "locations.lc_name" },
  teams: { filter: "teams.uuid", sort: "teams.lc_name" },
};

const filterColumnMapper = (filterModel: any) =>
  Object.keys(filterModel).reduce((acc, key) => {
    if (dictionary[key]) {
      acc[dictionary[key].filter] = filterModel[key];
    } else {
      acc[key] = filterModel[key];
    }
    return acc;
  }, {} as any);

const sortColumnMapper = (sortModel: { colId: string; sort: string }[]) =>
  sortModel.map((sortObject) => {
    let { colId } = sortObject;
    if (dictionary[colId]) colId = dictionary[colId].sort;
    return { ...sortObject, colId };
  });

const EmployeesCenterWrapper = styled.div`
  .Avatar {
    margin-inline-end: 8px;

    & > div {
      height: 28px;
      width: 28px;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: nowrap;
`;

const EmployeesCountBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  min-width: 31px;
  height: 26px;
  background: var(--colors-surface-50);
  border-radius: 100px;
  font-weight: var(--typography-font-weight-bold);
  font-size: var(--typography-font-size-default);
  line-height: 14px;
  color: var(--colors-surface-800);
`;

enum HeaderActionType {
  addEmployee = "addEmployee",
  inviteEmployees = "inviteEmployees",
}

export const EmployeesCenter = () => {
  const { t } = useTranslation([
    TranslationNamespaces.employeesPage,
    TranslationNamespaces.employeesPageAllTable,
    TranslationNamespaces.agGrid,
  ]);
  const context = useContext(GlobalContext);
  const subscriptionContext = useContext(SubscriptionContext);
  const history = useHistory();
  const [gridApi, setGridApi] = useState<GridReadyEvent | null>(null);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showAddEmployeePopup, setShowAddEmployeePopup] = useState(false);
  const [total, setTotal] = useState<number>();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [notification, setNotification] = useState<{ message: string; type: NotificationType } | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [imageVisible, setImageVisible] = useState<string | null>(null);
  const [isAllCompanySelected, setIsAllCompanySelected] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);

  const [getAllUuids] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const filterModel = gridApi?.api.getFilterModel();
    const { content } = await getProfileUuidsWithFilters(company.uuid, {
      requestedBy: window.global_store.profile.uuid,
      skip: 0,
      limit: 200000,
      filterModel: filterColumnMapper({ ...filterModel, ...filters }),
    });
    return content;
  }, [filters, gridApi]);

  const isDefaultFilters = useCallback(() => {
    const filterModel = gridApi?.api.getFilterModel();
    return (
      (filterModel &&
        Object.keys(filterModel).length === 1 &&
        filterModel.employee_status?.values.every((s: string) => ["active", "invited"].includes(s)) &&
        !filters?.uuid) ||
      false
    );
  }, [filters, gridApi]);

  const doesExternalFilterPass = useCallback((node: IRowNode): boolean => true, []);

  const [onSelectionChanged] = useAsyncCallback(async () => {
    if (!gridApi) return;
    setSelectionLoading(true);
    const selectionState = gridApi?.api.getServerSideSelectionState() as IServerSideSelectionState;
    if (selectionState.selectAll) {
      const uuids = await getAllUuids();
      const rows = uuids.filter((uuid) => !selectionState.toggledNodes.includes(uuid));
      setIsAllCompanySelected(uuids.length === rows.length && isDefaultFilters());
      setSelectedEmployees(rows);
    } else {
      const rows = selectionState.toggledNodes;
      setIsAllCompanySelected(total === rows.length && isDefaultFilters());
      setSelectedEmployees(rows);
    }
    setSelectionLoading(false);
  }, [getAllUuids, gridApi, total]);

  const [search] = useAsyncCallback(
    async (sv: string) => {
      if (timer) {
        clearTimeout(timer);
      }

      setTimer(
        setTimeout(async () => {
          setFilters(
            sv
              ? {
                  uuid: {
                    filterType: "join",
                    type: "OR",
                    conditions: [
                      {
                        colId: "email",
                        filterType: "text",
                        type: "contains",
                        filter: sv.toLowerCase(),
                      },
                      {
                        colId: "matricula",
                        filterType: "text",
                        type: "contains",
                        filter: sv.toLowerCase(),
                      },
                      {
                        colId: "lc_name",
                        filterType: "text",
                        type: "contains",
                        filter: sv.toLowerCase(),
                      },
                    ],
                  },
                }
              : { ...filters, uuid: undefined },
          );
          setTimer(null);
          void onSelectionChanged();
        }, 500),
      );
    },
    [filters, timer],
  );
  const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
  const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);
  const invalidAssociationDates = (entity: { startDate: string | null; endDate: string | null }): boolean => {
    if (
      entity.startDate &&
      moment(entity.startDate).isSameOrBefore(moment(), "day") &&
      (entity.endDate === null || moment(entity.endDate).isSameOrAfter(entity.startDate, "day"))
    )
      return true;
    return (
      entity.startDate === null && (moment(entity.endDate).isSameOrAfter(moment(), "day") || entity.endDate === null)
    );
  };
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "fullName",
        colId: "lc_name",
        headerName: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employee",
          `${TranslationNamespaces.employeesPageAllTable}|Employee`,
        ),
        headerCheckboxSelection: true,
        checkboxSelection: true,
        pinned: window.global_store.isRTL ? "right" : "left",
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => (
          <Link
            to={`/employees/${params.data.id}`}
            style={{ display: "flex", alignItems: "center", fontSize: "14px", color: "var(--colors-surface-900-p)" }}
          >
            <Avatar
              className="Avatar"
              user={{
                fullName: params.data.fullName,
                avatarId: params.data.avatarId,
              }}
            />
            <div title={params.data.fullName} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              {params.data.fullName}
            </div>
          </Link>
        ),
      },
      {
        colId: "face_id",
        initialHide: true,
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Biometric Template`),
        cellRenderer: (params: ICellRendererParams) => {
          const hasBiometricProfile = params.data.faceId || params.data.faceVersion || params.data.faceTemplate;

          if (!hasBiometricProfile) {
            return "-";
          }
          return images.punchesTabletPhoto({});
        },
        suppressMenu: false,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["empty", "blank", "notBlank"],
          buttons: ["clear", "apply"],
          closeOnApply: true,
          maxNumConditions: 0,
        },
        tooltipField: "faceId",
        tooltipComponent: (params: ICellRendererParams) => {
          if (imageVisible) return null;
          if (params.data.faceId && params.data.faceVersion) {
            const baseUrl = `v${params.data.faceVersion}/${params.data.faceId}`;
            const [imgUrl, fullimgUrl] = getImageUrlsWithParams(baseUrl);

            return (
              <>
                <img src={imgUrl} alt="" />
              </>
            );
          }
          return null;
        },
      },
      {
        field: "payrollGroups",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Payroll Group`),
        valueGetter: (params: ValueGetterParams<FilteredEmployeeProfile>) =>
          params.data?.payrollGroups
            .filter(invalidAssociationDates)
            .map((hg: EmployeePayrollGroup) => getGroupName(hg, t))
            .join(", "),
        sortable: true,
        colId: "payrollGroups",
        initialHide: true,
        tooltipValueGetter: (params) =>
          params.data.payrollGroups
            .filter(invalidAssociationDates)
            .map((hg: EmployeePayrollGroup) => getGroupName(hg, t))
            .join(", "),
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, PayrollGroup>) => {
            const company = await context.getCompany();
            const { content } = await getLockGroupsList({ companyUuid: company.uuid });
            params.success([
              {
                uuid: null,
                name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as PayrollGroup,
              ...content.filter((g) => g.status === PayrollGroupStatus.active),
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "holidayGroups",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Holiday Group`),
        cellRenderer: (params: ICellRendererParams) => (
          <TagRenderer
            value={params.data.holidayGroups
              .filter(invalidAssociationDates)
              .map((hg: EmployeeHolidayGroup) => getGroupName(hg, t))}
          />
        ),
        tooltipValueGetter: (params) =>
          params.data.holidayGroups
            .filter(invalidAssociationDates)
            .map((hg: EmployeeHolidayGroup) => getGroupName(hg, t))
            .join(", "),
        sortable: true,
        initialHide: true,
        colId: "holidayGroups",
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, HolidayGroup>) => {
            const company = await context.getCompany();
            const { content } = (await listHolidaysGroups({ companyUuid: company.uuid })) as {
              content: HolidayGroup[];
            };
            params.success([
              { uuid: null, name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`) } as unknown as HolidayGroup,
              ...content.filter((hg) => hg.status === HolidayGroupStatus.active),
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "schedules",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Schedule`),
        colId: "schedules",
        initialHide: true,
        valueGetter: (params: ValueGetterParams) =>
          getGroupNameAndDescription(params.data.schedules.filter(invalidAssociationDates), t),
        cellRenderer: (params: ICellRendererParams): JSX.Element => <MultilineRenderer {...params.value} />,
        tooltipValueGetter: (params) =>
          params.data.schedules
            .filter(invalidAssociationDates)
            .map((hg: EmployeeSchedule) => getGroupName(hg, t))
            .join(", "),
        filter: "agSetColumnFilter",
        suppressMenu: false,
        sortable: true,
        minWidth: 180,
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Schedule>) => {
            const company = await context.getCompany();
            const schedules = await getSchedules({
              companyUuid: company.uuid,
              params: { perPage: 1000, active: true },
            });
            params.success([
              { uuid: null, name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`) } as unknown as Schedule,
              ...schedules.content.filter((s) => s.active),
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "businessRulesGroups",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Business Rules Group`),
        minWidth: 180,
        initialHide: true,
        valueGetter: (params: ValueGetterParams) =>
          getGroupNameAndDescription(params.data.businessRulesGroups.filter(invalidAssociationDates), t),
        cellRenderer: (params: ICellRendererParams): JSX.Element => <MultilineRenderer {...params.value} />,
        tooltipValueGetter: (params) =>
          params.data.businessRulesGroups
            .filter(invalidAssociationDates)
            .map((hg: EmployeesBusinessRulesGroup) => getGroupName(hg, t))
            .join(", "),
        sortable: true,
        colId: "businessRulesGroups",
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, BusinessRuleGroup>) => {
            const company = await context.getCompany();
            const { content } = await getBRGroups({ companyUuid: company.uuid });
            params.success([
              {
                uuid: null,
                name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as BusinessRuleGroup,
              ...content.filter((brg) => brg.status === BusinessRuleGroupStatus.active),
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "locations",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Locations`),
        colId: "locations",
        cellRenderer: (params: ICellRendererParams) => (
          <TagRenderer
            value={
              params.data.locations?.filter((l: Location) => l.active).length > 0
                ? params.data.locations
                    .filter((l: Location) => l.active)
                    .sort((a: Location, b: Location) => a.name - b.name)
                    .map((l: Location) => l.name)
                : t("All locations")
            }
          />
        ),
        tooltipValueGetter: (params) =>
          params.data.locations?.filter((l: Location) => l.active).length > 0
            ? params.data.locations
                .filter((l: Location) => l.active)
                .map((hg: Location) => hg.name)
                .join(", ")
            : t("All locations"),
        sortable: true,
        initialHide: true,
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Location>) => {
            const { locations } = await getLocations();
            params.success([
              { uuid: null, name: t("All locations") } as unknown as Location,
              ...locations.filter((l) => l.active),
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
          showTooltips: true,
        },
      },
      {
        field: "pis",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|PIS`),
        colId: "pis",
        sortable: true,
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        filter: "agTextColumnFilter",
        suppressMenu: false,
        tooltipField: "pis",
        filterParams: {
          maxNumConditions: 0,
        },
      },
      {
        field: "taxPayerId",
        headerName: employeeTaxIdLabel,
        colId: "taxPayerId",
        sortable: true,
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        filter: "agTextColumnFilter",
        suppressMenu: false,
        tooltipField: "taxPayerId",
        filterParams: {
          maxNumConditions: 0,
        },
      },
      {
        field: "matricula",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Matricula`),
        sortable: true,
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        filter: "agTextColumnFilter",
        suppressMenu: false,
        tooltipField: "matricula",
        filterParams: {
          buttons: ["clear", "apply"],
          closeOnApply: true,
          maxNumConditions: 0,
        },
      },
      {
        field: "position.title",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Position`),
        valueFormatter: (params) => emptyValueFormatter(params.data.position?.title),
        tooltipField: "position.title",
        sortable: true,
        colId: "positions",
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Position>) => {
            const { positions } = await getPositions();
            params.success([
              {
                uuid: null,
                title: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as Position,
              ...positions,
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.title,
        },
      },
      {
        field: "user.userAccount.email",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Email`),
        tooltipField: "user.userAccount.email",
        colId: "email",
        sortable: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
      },
      {
        field: "user.devices.phoneNumber",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Phone`),
        valueFormatter: (params) => emptyValueFormatter(params.value ? formatPhone(params.value, true) : ""),
        cellStyle: {
          direction: "ltr",
          textAlign: window.global_store.isRTL ? "right" : "left",
        },
      },
      {
        field: "team.name",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Team`),
        colId: "teams",
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        sortable: true,
        suppressMenu: false,
        tooltipField: "team.name",
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Team>) => {
            const { teams } = await getTeams();
            params.success([
              {
                uuid: null,
                name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as Team,
              ...teams,
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "department.name",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Department`),
        sortable: true,
        colId: "departments",
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        tooltipField: "department.name",
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Department>) => {
            const { departments } = await getDepartments();
            params.success([
              {
                uuid: null,
                name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as Department,
              ...departments,
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "subsidiary.name",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Subsidiary`),
        colId: "subsidiaries",
        initialHide: true,
        valueFormatter: (params) => emptyValueFormatter(params.value),
        tooltipField: "subsidiary.name",
        sortable: true,
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: async (params: SetFilterValuesFuncParams<FilteredEmployeeProfile, Subsidiary>) => {
            const { subsidiaries } = await getSubsidiaries();
            params.success([
              {
                uuid: null,
                name: t(`${TranslationNamespaces.agGrid}|filter_value_Blank`),
              } as unknown as Subsidiary,
              ...subsidiaries,
            ]);
          },
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => params.value.name,
        },
      },
      {
        field: "role",
        initialHide: true,
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Type`),
        cellRenderer: ({ value }: ICellRendererParams) => <span>{t(value)}</span>,
        tooltipValueGetter: (params) => t(params.value),
        colId: "role",
        sortable: true,
        suppressMenu: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: [
            {
              key: UserProfileRole.employee,
              value: translateEmployeeTerm(t, TranslationNamespaces.common, "custom-employee", "Employee"),
            },
            {
              key: UserProfileRole.supervisor,
              value: t("Supervisor"),
            },
          ],
          valueFormatter: (params: ICellRendererParams) => t(params.value.value),
          keyCreator: (params: ICellRendererParams) => params.value.key,
        },
      },
      {
        field: "user.sex",
        colId: "sex",
        initialHide: true,
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Sex`),
        valueFormatter: (params) => emptyValueFormatter(params.value ? t(params.value) : undefined),
        sortable: true,
        suppressMenu: false,
        filter: "agSetColumnFilter",
        tooltipValueGetter: (params) => t(params.value),
        filterParams: {
          values: [
            { key: null, value: `${TranslationNamespaces.agGrid}|filter_value_Blank` },
            {
              key: "male",
              value: "male",
            },
            { key: "female", value: "female" },
          ],
          valueFormatter: (params: ICellRendererParams) => t(params.value.value),
          keyCreator: (params: ICellRendererParams) => params.value.key,
        },
      },
      {
        field: "permissionRoles",
        colId: "permissionRoles",
        initialHide: true,
        headerName: t("Permission"),
        tooltipValueGetter: (params) =>
          params.data.permissionRoles.map((hg: { name: string }) => t(hg.name)).join(", "),
        cellRenderer: (params: ICellRendererParams) => (
          <TagRenderer value={params.value && params.value.length ? params.value.map((v) => t(v.name)) : ""} />
        ),
      },
      {
        field: "createdAt",
        initialHide: true,
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Added date`),
        valueFormatter: (params: ValueFormatterParams) => (params.value ? moment(params.value).format("DD/MM/YY") : ""),
        sortable: true,
        colId: "created_at",
      },
      {
        field: "employeeStatus",
        headerName: t(`${TranslationNamespaces.employeesPageAllTable}|Status`),
        cellRenderer: (params: ICellRendererParams) => <StatusBadge value={t(params.value)} type={params.value} />,
        sortable: true,
        filter: "agSetColumnFilter",
        colId: "employee_status",
        suppressMenu: false,
        filterParams: {
          values: ["active", "deactivated", "invited"],
          keyCreator: (params: ICellRendererParams) => params.value,
          valueFormatter: (params: ICellRendererParams) => t(params.value).toUpperCase(),
        },
      },
    ],
    [],
  );

  const [getData] = useAsyncCallback(async (params: any) => {
    try {
      const company = await context.getCompany();
      const { content, metadata } = await listUserProfilesWIthFilters(company.uuid, {
        requestedBy: window.global_store.profile.uuid,
        skip: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          "id",
          "uuid",
          "fullName",
          "avatarId",
          "role",
          "pis",
          "matricula",
          "lastSignInAt",
          "employeeStatus",
          "createdAt",
          "faceId",
          "faceVersion",
          "faceTemplate",
          "externalId",
          "user.devices.phoneNumber",
          "user.userAccount.email",
          "taxPayerId",
          "user.sex",
          "user.active",
          "position.title",
          "subsidiary.uuid",
          "subsidiary.name",
          "department.uuid",
          "department.name",
          "team.name",
          "team.uuid",
          "permissionRoles.name",
          "locations.name",
          "locations.uuid",
          "locations.active",
        ],
        externalFields: [
          FilteredEmployeesExternalFields.Schedules,
          FilteredEmployeesExternalFields.HolidayGroups,
          FilteredEmployeesExternalFields.BusinessRulesGroups,
          FilteredEmployeesExternalFields.PayrollGroups,
        ],
        filterModel: filterColumnMapper(params.filterModel),
        sortModel: sortColumnMapper(params.sortModel),
      });
      setTotal(metadata.total);
      return { content, total: metadata.total };
    } catch (error) {
      sentryUtils.sendError(error);
      setNotification({
        message: t(`${TranslationNamespaces.common}|unable-to-fetch-data`),
        type: NotificationType.error,
      });
    }
  }, []);

  const datasource = useMemo(
    () => ({
      getRows(params: IServerSideGetRowsParams) {
        getData({ ...params.request, filterModel: { ...params.request.filterModel, ...filters } })
          .then((data) => {
            params.success({ rowData: data.content, rowCount: data.total });
          })
          .catch((error) => {
            params.fail();
          });
      },
    }),
    [filters],
  );

  const initialFilterForDeactivatedEmployees = (api?: GridApi, values?: string[]) => {
    const filter = values ? { filterType: "set", values } : undefined;
    const filterModel = api?.getFilterModel();
    api?.setFilterModel({ ...filterModel, employee_status: filter });
    api?.onFilterChanged();
  };

  const onGridReady = (params: GridReadyEvent) => {
    const alreadyInitialized = !!gridApi;
    setGridApi(params);
    // register datasource with the grid
    if (!alreadyInitialized) initialFilterForDeactivatedEmployees(params.api, ["active", "invited"]);
    params.api.setServerSideDatasource(datasource);

    restoreState(params.columnApi, LocalStorageKeys.employeeCenterColumns);
  };

  const externalFilterChanged = useCallback(() => {
    gridApi?.api.onFilterChanged();
  }, [gridApi]);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
      externalFilterChanged();
    }
  }, [filters]);

  const isExternalFilterPresent = useCallback(
    (): boolean =>
      // if ageType is not everyone, then we are filtering
      filters && Object.keys(filters).length > 0,
    [filters],
  );

  return (
    <FullPage
      className="EmployeeGrid"
      title={
        <TitleWrapper>
          {translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-employees",
            `${TranslationNamespaces.sidebar}|People`,
          )}
          <EmployeesCountBubble>{total}</EmployeesCountBubble>
        </TitleWrapper>
      }
      headerAction={
        <HeaderAction>
          <ImportEmployee notificationCb={(notificationData) => setNotification(notificationData)} />
          <SimpleMenu
            options={[
              {
                value: HeaderActionType.addEmployee,
                label: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.employeesPage,
                  "custom-create-employee",
                  "Add an Employee",
                ),
              },
              {
                value: HeaderActionType.inviteEmployees,
                label: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.employeesPage,
                  "custom-invite-employees",
                  "Add Multiple Employees",
                ),
              },
            ]}
            onChange={async (val: HeaderActionType) => {
              const validSeats = await subscriptionContext.checkAvailableSeats(false, {});
              if (validSeats) {
                if (val === HeaderActionType.addEmployee) {
                  setShowAddEmployeePopup(true);
                } else if (val === HeaderActionType.inviteEmployees) {
                  history.push("/invite-employees");
                }
              }
            }}
            style={{ width: 160, insetInlineEnd: 0 }}
          >
            <HeaderActionButtonAdd
              state={ButtonState.primary}
              title={translateEmployeeTerm(t, TranslationNamespaces.common, "custom-add-employee", "Add Employee")}
            />
          </SimpleMenu>
        </HeaderAction>
      }
    >
      <EmployeesCenterWrapper>
        {notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notification.type}
            onClose={() => setNotification(null)}
            message={notification.message}
          />
        )}
        <TopRow>
          <EmployeeSearchControl onChange={async (term: string) => await search(term)} />
          <div className="Flex">
            <ColumnSelectorToolPanel gridApi={gridApi!} />
            <DownloadControlWithEvents
              withoutDropdown
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              options={[{ label: "XLSX", value: "xlsx" }]}
              withConfirm={{
                title: t("download-employees-confirm-title"),
                description: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.employeesPage,
                  "custom-download-employees-confirm-description",
                  "download-employees-confirm-description",
                  { interpolation: { escapeValue: false } },
                ),
                downloadLabel: t(`${TranslationNamespaces.common}|Download`),
                cancelLabel: t(`${TranslationNamespaces.common}|Cancel`),
              }}
              onChange={(): APIResponse<unknown> => {
                const args: {
                  selectedColumns: string;
                  employee_statuses?: string;
                  role?: string;
                  withoutSchedule?: boolean;
                } = {
                  selectedColumns:
                    "name,biometric_template,payroll_group,holiday_group,schedule,business_rules_group,pis,cpf,matricula,position,email,phone_number,supervisor,department,subsidiary,locations,role,sex,permission_roles,created_at,status",
                  // withoutSchedule: filterModel && !filterModel["schedules.uuid"],
                  employee_statuses: "active,invited,deactivated",
                };

                return fireDownloadEmployees(args);
              }}
            />
            <GridActionsDropdown
              gridApi={gridApi!}
              optionsComponent={
                <>
                  <div className="Switcher">
                    <div>
                      {translateEmployeeTerm(
                        t,
                        TranslationNamespaces.employeesPage,
                        "custom-show-suspended-profiles",
                        "Show suspended profiles",
                      )}
                    </div>
                    <div className="IntervalSwitch">
                      <CheckboxControl
                        checked={
                          gridApi?.api.getFilterModel()?.employee_status?.values.includes("deactivated") ||
                          !gridApi?.api.getFilterModel()?.employee_status
                        }
                        modifiers={{ small: true }}
                        onChange={(checked: boolean) => {
                          initialFilterForDeactivatedEmployees(
                            gridApi?.api,
                            checked ? undefined : ["active", "invited"],
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </TopRow>

        <div style={{ width: "100%", height: "calc(100vh - 185px)" }}>
          <GridTable
            columnDefs={columnDefs}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            lsKey={LocalStorageKeys.employeeCenterColumns}
            gridApi={gridApi!}
            onFilterChanged={async () => onSelectionChanged()}
          />
        </div>
      </EmployeesCenterWrapper>

      {!!selectedEmployees.length && (
        <ActionsBar
          selectedEmployees={selectedEmployees}
          onUncheckAll={(): void => gridApi?.api.deselectAll()}
          isAllCompany={isAllCompanySelected}
          disabled={selectionLoading}
          onDone={(resp: { notification: string; notificationType: NotificationType }): void => {
            setNotification({
              message: resp.notification,
              type: resp.notificationType,
            });
            gridApi?.api.deselectAll();
            gridApi?.api.onFilterChanged();
          }}
        />
      )}
      <SidePopupOverlay
        header={translateEmployeeTerm(t, TranslationNamespaces.common, "custom-add-employee", "Add Employee")}
        isOpen={showAddEmployeePopup}
        contentOverflow
        onClose={(): void => setShowAddEmployeePopup(false)}
      >
        <EmployeeDetailsNew
          employee={null}
          popupMode
          onSave={async (val: string): Promise<void> => {
            void gridApi?.api.onFilterChanged();
            setShowAddEmployeePopup(false);
          }}
          onError={(err) =>
            setNotification({
              message: (err as unknown as Error)?.message,
              type: NotificationType.error,
            })
          }
        />
      </SidePopupOverlay>
      {imageVisible && (
        <Overlay dark isOpen={imageVisible} onClose={(): void => setImageVisible(null)}>
          <FullImgWrapper>
            <StyledFullImg src={imageVisible} alt="" />
            <CloseBtn className="img-close-btn" onClick={(): void => setImageVisible(null)} />
          </FullImgWrapper>
        </Overlay>
      )}
    </FullPage>
  );
};
