import { UserProfile } from "types/models/userProfile";
import { APIResponse, sendRequest } from "./common";
import {
  GetEmployeeResponseData,
  GetEmployeeTeamResponseData,
  GetEmployeeTeamRequestData,
  GetPunchesByEmployeeRequestData,
  GetPunchesByEmployeeResponseData,
  RemoveEmployeeFromTeamRequestData,
} from "./types";

type TODORequestData = any;
type TODOResponseData = Promise<unknown>;

export function setUserLocale({ id, locale }: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      locale,
    },
    "POST",
    `/employees/${id}/locale`,
  );
}

export function sendInvites(employees: TODORequestData, type: TODORequestData): TODOResponseData {
  const userProfiles = employees.map((e) => {
    const emp = {
      full_name: e.name.value,
      employee_status: "invited",
      associate_products: e.associate_products,
    };
    if (type === "email") {
      emp.email = e.email.value;
    } else {
      emp.devices_attributes = {
        phone_number: `+${e.phoneNumber.value}`,
      };
    }
    return emp;
  });
  return sendRequest(
    {
      company: {
        user_profiles_attributes: userProfiles,
      },
    },
    "patch",
    "/employees/send_invitations",
  );
}

export function changePhone(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      device: {
        phone_number: data.phoneNumber,
      },
    },
    "post",
    `/employees/${data.employeeId}/change_phone_number`,
  );
}

export function getEmployeesByUUIDs(data: TODORequestData = {}): APIResponse<{ user_profiles: UserProfile[] }> {
  const url = `/employees?uuids=${data.uuids.join(",")}`;
  return sendRequest({}, "get", url);
}

export function getPunchesByEmployee(
  data: GetPunchesByEmployeeRequestData,
): APIResponse<GetPunchesByEmployeeResponseData> {
  return sendRequest({}, "get", `/employees/${data.employeeId}/punches?page=${data.page}&per_page=${data.perPage}`);
}

export function getEmployee(data: {
  id: number;
  newHierarchyPermissions?: boolean;
}): APIResponse<GetEmployeeResponseData> {
  return sendRequest(
    {},
    "get",
    `/employees/${data.id}${data.newHierarchyPermissions ? "?new_hierarchy_permissions=true" : ""}`,
  );
}

export function updateUserDetails(data: TODORequestData): TODOResponseData {
  return sendRequest(data.employee, "PUT", `/employees/${data.id}`);
}

export function updateEmployeeRole({ id, rolesUUIDs }: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      user_profile: {
        permission_role_uuids: rolesUUIDs,
      },
    },
    "POST",
    `/employees/${id}/update_roles`,
  );
}

export function getDevices(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/employees/${data.employeeId}/devices`);
}

export function addDevice(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/employees/${data.employeeId}/devices/${data.deviceId}/add_device`);
}

export function toggleDeviceStatus(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      device: {
        status: data.status,
      },
    },
    "POST",
    `/employees/${data.employeeId}/devices/${data.deviceId}/change_status`,
  );
}

export function getEmployeeTeam(data: GetEmployeeTeamRequestData) {
  return sendRequest<Record<string, never>, GetEmployeeTeamResponseData>(
    {},
    "GET",
    `/employees/${data.employeeId}/employees?page=${data.page}&per_page=${data.perPage}`,
  );
}

export function addEmployeeToTeam(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "POST", `/employees/${data.employerId}/add_employee?employee_id=${data.employeeId}`);
}

export function addEmployeesToTeam(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/teams/${data.teamId}/members`);
}

export function removeEmployeeFromTeam(data: RemoveEmployeeFromTeamRequestData): TODOResponseData {
  return sendRequest({}, "POST", `/employees/${data.employerId}/remove_employee?employee_id=${data.employeeId}`);
}

export function addEmployee(data: TODORequestData): TODOResponseData {
  return sendRequest(data, "POST", "/employees/upsert");
}

export function deactivateEmployee(
  id: TODORequestData,
  deactivationDate: TODORequestData,
  removeFromPayrollGroup: TODORequestData,
  removeDeactivateDateFromSchedule: TODORequestData,
): TODOResponseData {
  const payload = {
    deactivate_date: deactivationDate,
    has_terminated_payroll_group: removeFromPayrollGroup,
    remove_deactivate_date_from_schedule: removeDeactivateDateFromSchedule,
  };

  return sendRequest(payload, "POST", `/employees/${id}/deactivate`);
}
