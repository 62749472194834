import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BEM from "utils/BEM";
import { copyTextToClipboard } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import "components/UI/Page/Page.scss";
import "styles/rep-pegador.scss";

const b = BEM.b("rep-pegador");
const p = BEM.b("page");

interface PegadorContentProps {
  token: string | null;
  lastSync: string | null;
}

const PegadorContent = ({ token, lastSync }: PegadorContentProps): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.reps);

  return token ? (
    <div className={p("content")}>
      <div className={b("label")}>{t("Token")}</div>
      <div className={b("copy-control")}>
        <input className={b("input")} type="text" value={token} disabled />
        <span className={b("copy-button")} onClick={() => copyTextToClipboard(token)}>
          {t("Copy")}
        </span>
      </div>

      {lastSync && <div className={b("label")}>{t("Last synched at")}</div>}
      {lastSync && <div>{moment(lastSync).format("HH:mm - DD/MM/YYYY")}</div>}
    </div>
  ) : (
    <div className={p("content")}>
      <div className={b("no-integrations")}>{t("There are no REPs at this time")}</div>
      <Link className={b("no-integrations-link")} to="/time-clocks/add">
        {t("Add REP to start using Pegador")}
      </Link>
    </div>
  );
};

export default PegadorContent;
