import { Amplitude } from "services/amplitude";
import { getErrorText } from "utils/get-error-text";
import {
  CreatePunchOptions,
  CreatePunchResult,
  EventTrackingServiceInterface,
  Location,
  PunchTypeObject,
} from "services/punch-service/types";
import { GoogleAnalytics } from "utils/ga/GoogleAnalytics";
import { PunchType } from "types/models/punches";
import { MassActionLocations } from "utils/ga";

export class PunchEventTracking implements EventTrackingServiceInterface {
  constructor(protected eventTracking: Amplitude, protected gaEventTracking?: GoogleAnalytics) {}

  trackInit() {
    this.eventTracking.punchScreen({});
  }

  trackMassAction(numberOfEmployees: number, location: MassActionLocations) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackMassAction({
        numberOfEmployees,
        location,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackError(err: any) {
    const msg = getErrorText(err);
    this.eventTracking.punchErrorLightbox({ error: msg });
  }

  trackLocationChange(location: Location | null) {
    this.eventTracking.punchChangeLocation({});
  }

  trackPunchTypeUpdate(punch: CreatePunchResult, punchType: PunchTypeObject) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackChangePunchCategory({
        punch_type: punch.punchType.type,
        punch_type_changed_to: punchType?.type,
      });
      // do not duplicate GA events with Apmlitude
      return;
    }

    this.eventTracking.punchChangeCategory({
      changeCategoryFrom: punch.punchType.type,
      changeCategoryTo: punchType?.type,
    });
  }

  trackPunch(punch: CreatePunchOptions) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackClockNow(punch.punchType.type);
      // do not duplicate GA events with Apmlitude
      return;
    }

    if (punch.isVerified) {
      this.eventTracking.punchSuccess({ punchCategory: punch.punchType.type });
    } else {
      const failedValidations = [];
      if (!punch.isLocationVerified) {
        failedValidations.push("location");
      }
      if (!punch.isTimeVerified) {
        failedValidations.push("time");
      }
      this.eventTracking.punchRequestSucess({ validation: failedValidations.join() });
    }
  }

  trackPunchFinish(punchType: PunchType) {
    if (this.gaEventTracking) {
      this.gaEventTracking.trackChangePunchCompleted(punchType);
    }
  }
}
