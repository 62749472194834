import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "context/GlobalContextProvider";
import { useAsyncCallback } from "utils/useAsyncEffect";
import ga from "utils/ga";
import { ReactComponent as CrossIcon } from "../icons/close.svg";
import { BillingService } from "../BillingService";

const msInSecond = 1000;
const msInMinute = msInSecond * 60;
const msInHour = msInMinute * 60;
const msInDay = msInHour * 24;

export const AMOUNT_OF_FREE_DAYS = 3;
export const MINIMAL_COMPANY_SIZE = 10;

const styles = stylesheet`
  .Wrapper {
    margin: 16px 0;
    width: 100%;
    display: flex;
    padding: 16px;
    background: #0DB06D;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: white;
    font-size: var(--typography-font-size-default);

    .TimeWrapper {
      direction: ltr;
      font-size: 24px;
      display: flex;
      gap: 4px;

      .TimeItem {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .Time {
        padding: 6px 7px;
        background: rgba(white, .2);
        border-radius: 8px;
        width: 40px;
        text-align: center;
        font-size: 20px;
      }

      .TimeLabel {
        font-size: 12px;
        opacity: .7;
        text-align: center;
      }
      span {
        padding-top: 2px;
      }
    }
    .Message {
      margin: 0 16px;
    }
    .CTABtn {
      color: var(--colors-surface-900-p);
      background: var(--colors-warning-300);
      border-radius: var(--shapes-border-radius-default);
      padding: 11px 12px;
      border: none;
      cursor: pointer;
      font-weight: var(--typography-font-weight-medium);
      margin-inline-start: auto;
    }
    .CloseBtn {
      margin-inline-start: 24px;
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;
    }
  }
`;

export const InitialDiscountNotification = () => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const history = useHistory();
  const isOwner = BillingService.checkIsCompanyAdmin(true);
  const startedAt = BillingService.getInitialDiscountTime();
  const started = moment(startedAt).add(AMOUNT_OF_FREE_DAYS, "days");
  const [availableTimeInMS, setAvailableTimeInMS] = useState(0);
  const context = useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const [init] = useAsyncCallback(async () => {
    const companySize = (await context.getCompany())?.employee_count?.toString();
    const hideDate = localStorage.getItem("gs_hide_initial_discount");

    if (BillingService.getMaxCompanySizeValue(companySize!) <= MINIMAL_COMPANY_SIZE) return setShow(false);
    if (!hideDate) return setShow(true);

    if (moment(hideDate).add(1, "day").isSameOrBefore(moment(), "day")) {
      localStorage.removeItem("gs_hide_initial_discount");
      return setShow(true);
    }
    return setShow(false);
  }, []);

  const updateTime = () => {
    const diff = startedAt ? moment().diff(started) : 0;
    setAvailableTimeInMS(diff);
  };

  useEffect(() => {
    void init();
    updateTime();
  }, [init]);

  const calculateTime = useMemo(() => {
    const time = Math.abs(availableTimeInMS);
    const days = Math.floor(time / msInDay);
    const hours = Math.floor((time - days * msInDay) / msInHour);
    const minutes = Math.floor((time - days * msInDay - hours * msInHour) / msInMinute);
    const seconds = Math.floor((time - days * msInDay - hours * msInHour - minutes * msInMinute) / msInSecond);
    return { days, hours, minutes, seconds };
  }, [availableTimeInMS]);

  const goToPricing = () => {
    ga.initialDiscountClaimClick();
    history.push("/billing/pricing");
  };

  const close = () => {
    ga.initialDiscountCloseClick();
    localStorage.setItem("gs_hide_initial_discount", new Date().toUTCString());
    setShow(false);
  };

  const formatDate = (time: number) => {
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  };

  if (!isOwner || availableTimeInMS >= 0 || !show) return null;
  setInterval(() => updateTime(), msInSecond);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TimeWrapper}>
        <div className={styles.TimeItem}>
          <div className={styles.Time}>{formatDate(calculateTime.days)}</div>
          <span className={styles.TimeLabel}>{t("time_days_label")}</span>
        </div>
        <span>:</span>
        <div className={styles.TimeItem}>
          <div className={styles.Time}>{formatDate(calculateTime.hours)}</div>
          <span className={styles.TimeLabel}>{t("time_hours_label")}</span>
        </div>
        <span>:</span>
        <div className={styles.TimeItem}>
          <div className={styles.Time}>{formatDate(calculateTime.minutes)}</div>
          <span className={styles.TimeLabel}>{t("time_minutes_label")}</span>
        </div>
        <span>:</span>
        <div className={styles.TimeItem}>
          <div className={styles.Time}>{formatDate(calculateTime.seconds)}</div>
          <span className={styles.TimeLabel}>{t("time_seconds_label")}</span>
        </div>
      </div>
      <div
        className={styles.Message}
        dangerouslySetInnerHTML={{
          __html: t("new_user_discount_message {{amountOfFreeDays}}", {
            amountOfFreeDays: AMOUNT_OF_FREE_DAYS,
            interpolation: { escapeValue: false },
          }),
        }}
      />
      <button className={styles.CTABtn} type="button" onClick={() => goToPricing()}>
        {t("initial_discount_CTA")}
      </button>
      <button className={styles.CloseBtn} type="button" onClick={() => close()}>
        <CrossIcon />
      </button>
    </div>
  );
};
