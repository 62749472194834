import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSelectedColumns } from "utils/tableHelpers";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import { ActivitiesReportAllProjects, ActivitiesReportAllProjectsMetadata } from "types/models/projects";
import { getMinsToHours, getMinsToHoursDecimal } from "utils/reportsHelpers";
import "styles/reports-extra-hour-table.scss";
import { iColumn } from "components/TableCommon";
import { getValueWithCurrency, hasPermisionAccess, minsToHrsMins, PermissionSectionName } from "utils/common";
import { OnError, SetIsLoading } from "types/reports";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TableDetailsTitle, TableDetailsTitleDates } from "./reportsStyledComponents";
import { ReportActvitiesStatus } from "./helpers";
import ReportsNoContent from "./ReportsNoContent";

interface ReportsActivitiesAllProjectsProps extends RouteComponentProps, WithTranslation {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  showInactiveEmployees?: boolean;
  setIsLoading: SetIsLoading;
  onError: OnError;
  activityStatus: ReportActvitiesStatus;
}

type ProjectActivityRow = {
  projectName: string;
  projectId: string;
  clientName: string;
  employees: number;
  totalHours: string;
  totalHoursDecimal: string;
  totalCost: string;
  totalEmployeeCost: string;
};

interface ReportsActivitiesAllProjectsState {
  loading: boolean;
  activities: ProjectActivityRow[];
  selectedColumns: string;
  metadata: ActivitiesReportAllProjectsMetadata | null;
}

const mapActivityTableData = (inputData: ActivitiesReportAllProjects[]): ProjectActivityRow[] =>
  inputData.map((row) => ({
    projectName: row.project.name,
    projectId: row.project.id,
    clientName: row?.client?.name || "",
    employees: row.userProfilesCount || 0,
    totalHours: getMinsToHours(row.totalMinutes),
    totalHoursDecimal: getMinsToHoursDecimal(row.totalMinutes),
    totalCost: getValueWithCurrency(row.totalCost) || "0",
    totalEmployeeCost: getValueWithCurrency(row.totalEmployeeCost) || "0",
  }));

class ReportsActivitiesAllProjects extends Component<
  ReportsActivitiesAllProjectsProps,
  ReportsActivitiesAllProjectsState
> {
  constructor(props: ReportsActivitiesAllProjectsProps) {
    super(props);
    this.state = {
      metadata: null,
      loading: true,
      activities: [],
      selectedColumns: getSelectedColumns(
        "project_name,client_name,employees,total_hours,total_hours_decimal,total_cost",
        "ReportsActivitiesAllProjects",
      ),
    };
  }

  componentDidMount(): void {
    this.getReportData();
  }

  getReportData = (): void => {
    const { t, startDate, endDate, showInactiveEmployees, setIsLoading, onError, activityStatus } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, async () => {
        setIsLoading(true);

        try {
          const response = await getReport(
            {
              status: activityStatus,
              showInactiveEmploees: showInactiveEmployees,
              startDate,
              endDate,
              companyUUID,
              type: "projects_activities",
            },
            true,
          );

          const data: ActivitiesReportAllProjects[] = response?.content || [];
          const activities: ProjectActivityRow[] = mapActivityTableData(data);
          const metadata: ActivitiesReportAllProjectsMetadata = response?.metadata;

          this.setState({
            metadata,
            activities,
            loading: false,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("Error", e);

          onError(t("Failed to generate report"));
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  getColumns = (): iColumn<ProjectActivityRow>[] => {
    const { t } = this.props;
    const { metadata } = this.state;
    const columns: iColumn<ProjectActivityRow>[] = [
      {
        accessor: "projectName",
        rubyAccessor: "project_name",
        label: t("Project"),
        minWidth: 130,
      },
      {
        accessor: "projectId",
        rubyAccessor: "project_id",
        label: t("Project Id"),
        minWidth: 130,
      },
      {
        accessor: "clientName",
        rubyAccessor: "client_name",
        label: t("Client"),
        minWidth: 130,
      },
      {
        accessor: "employees",
        rubyAccessor: "employees",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employees",
          `${TranslationNamespaces.common}|Employees`,
        ),
        minWidth: 100,
        align: "start",
      },
      {
        accessor: "totalHours",
        rubyAccessor: "total_hours",
        label: t("Total Hours"),
        Footer: metadata?.totalMinutes ? minsToHrsMins(metadata.totalMinutes) : "",
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "totalHoursDecimal",
        rubyAccessor: "total_hours_decimal",
        label: t("Total Hours (Decimal)"),
        Footer: metadata?.totalMinutes ? getMinsToHoursDecimal(metadata.totalMinutes) : "",
        minWidth: 140,
        align: "end",
      },
      {
        accessor: "totalCost",
        rubyAccessor: "total_cost",
        label: t("Total Cost"),
        Footer: getValueWithCurrency(metadata?.totalCost),
        minWidth: 100,
        align: "end",
      },
    ];
    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      columns.push({
        accessor: "totalEmployeeCost",
        rubyAccessor: "total_employee_cost",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.reportsPage,
          "custom-total-employee-cost",
          `Total Employee Cost`,
        ),
        Footer: getValueWithCurrency(metadata?.totalEmployeeCost),
        minWidth: 100,
        align: "end",
      });
    }
    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsActivitiesAllProjects", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { loading, activities, selectedColumns } = this.state;
    const { t, startDate, endDate, showInactiveEmployees, activityStatus } = this.props;
    const columns = this.getColumns();
    const title = t("All Projects");

    return (
      <div>
        <TablePage<ProjectActivityRow>
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <TableDetailsTitle>{title}</TableDetailsTitle>
              <TableDetailsTitleDates>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </TableDetailsTitleDates>
            </>
          }
          loading={loading}
          rows={activities}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              // eslint-disable-next-line camelcase
              onChange={(value: string): Promise<Record<string, unknown>> =>
                fireDownloadReport({
                  status: activityStatus,
                  format: value || "pdf",
                  selectedColumns,
                  startDate,
                  endDate,
                  showInactiveEmploees: showInactiveEmployees,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "projects_activities",
                })
              }
            />
          }
          noContentComponent={<ReportsNoContent />}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsActivitiesAllProjects));
