import { Moment } from "moment";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { getVacationBalance } from "utils/apiHelpers";
import { TFunction } from "i18next";
import { RequestComplianceRules, Request, RequestTypeName, RequestType } from "types/models/request";
import { TranslationNamespaces, WithTranslation } from "types/translationNamespaces";

const moment = extendMoment(momentTz);

type GetWarningData = {
  t: TFunction;
  daysBeforeRequestDate: RequestComplianceRules["daysBeforeRequestDate"];
  predefinedPeriods: RequestComplianceRules["predefinedPeriods"];
  availableDays: number;
  startDate: Moment;
  endDate: Moment;
  selectedPeriod: number;
  validateAvailableDays: boolean;
};

export function getWarning({
  t,
  daysBeforeRequestDate,
  predefinedPeriods,
  availableDays,
  startDate,
  endDate,
  selectedPeriod,
  validateAvailableDays,
}: GetWarningData) {
  const diff = endDate.endOf("day").diff(startDate.startOf("day"), "days") + 1;

  if (predefinedPeriods && predefinedPeriods.active && predefinedPeriods.values.length && !selectedPeriod) {
    const isPeriodAllowed = predefinedPeriods.values.filter((period) => period === diff).length > 0;
    if (!isPeriodAllowed) {
      return t("Period doesn’t match predefined periods list");
    }
  }
  if (
    daysBeforeRequestDate.active &&
    daysBeforeRequestDate.days &&
    moment().clone().add(daysBeforeRequestDate.days, "day").isAfter(startDate, "day")
  ) {
    return t("Start date is sooner than {{daysBeforeRequest}} days", {
      daysBeforeRequest: daysBeforeRequestDate.days,
    });
  }

  if (validateAvailableDays && diff > availableDays) {
    return t("Vacation duration is longer than the available days");
  }

  return "";
}

export async function fetchVacationBalance(userProfileUuid: string, from: moment.Moment, to: moment.Moment) {
  const { company } = window.global_store;

  const resp = await getVacationBalance({
    companyUUID: company.uuid,
    requestedBy: window.global_store.profile.uuid,
    userProfileUuid,
    from: from.format("YYYY"),
    to: to.format("YYYY"),
  });

  const filteredPeriod = resp.content.find((period) =>
    moment.range(moment(period.startDate, "YYYY-MM-DD"), moment(period.endDate, "YYYY-MM-DD")).contains(from),
  );

  const selectedPeriod = filteredPeriod || resp.content[0];

  return {
    usedDays: selectedPeriod ? selectedPeriod.used : 0,
    availableDays: selectedPeriod ? selectedPeriod.available : 0,
  };
}

export const getRequestTypeName = (request: Request, t: WithTranslation["t"]) => {
  let requestDetailsHeader = null;
  const isPredefinedRequestType = Object.values(RequestTypeName).indexOf(request.requestType) > -1;

  if (isPredefinedRequestType) {
    requestDetailsHeader = t(`${TranslationNamespaces.requestsPageTmp}|${request.requestType}`);
  } else {
    requestDetailsHeader = request.requestType;
  }

  return requestDetailsHeader;
};

/**
 * Get request type name by request type object
 * Used in create request popup
 * @param requestType Complete request type object. Typically comes from /tree endpoint
 * @param t
 * @returns
 */
export const getRequestTypeNameByRequestType = (requestType: RequestType, t: WithTranslation["t"]) =>
  requestType.userDefined ? requestType.name : t(`${TranslationNamespaces.requestsPageTmp}|${requestType.name}`);
