import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import GroupsPage from "components/Payroll/Groups";
import LockPeriodsPage from "components/Payroll/LockPeriodsPage";
import LockReport from "components/Payroll/LockReportPage";
import DigitalSignaturesList from "components/Payroll/DigitalSignaturesList";
import DigitalSignaturesGroup from "components/Payroll/DigitalSignaturesGroup";
import GroupPage from "components/Payroll/Group";
import Payroll from "components/Reports/Payroll";
import PayrollReport from "components/Reports/PayrollReport";
import CreateLayout from "components/Payroll/CreateLayout";
import ReportsPage from "components/Reports/ReportsPage";
import LockPeriodDeactivatedEmployeesPage from "components/Payroll/LockPeriodDeactivatedEmployeesPage";

const RoutePayroll = ({ match: { path } }: RouteComponentProps): JSX.Element => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict exact from={`${path}/`} to={`${path}/payroll-groups/`} />
      <Redirect strict exact from={path} to={`${path}/payroll-groups/`} />
      <Redirect strict exact from={`${path}/payroll-groups`} to={`${path}/payroll-groups/`} />
      <Redirect strict exact from={`${path}/digital-signatures/`} to={`${path}/digital-signatures`} />
      <Redirect
        strict
        exact
        from={`${path}/digital-signatures/:payrollLockUuid/`}
        to={`${path}/digital-signatures/:payrollLockUuid`}
      />
      <Redirect strict exact from={`${path}/lock-periods`} to={`${path}/lock-periods/`} />
      <Redirect
        strict
        exact
        from={`${path}/lock-periods-dectivated-employees`}
        to={`${path}/lock-periods-dectivated-employees/`}
      />

      <Redirect strict from={`${path}/payroll-groups/:groupId/`} to={`${path}/payroll-groups/:groupId`} />
      <Redirect strict from={`${path}/lock-periods/:lockId/`} to={`${path}/lock-periods/:lockId`} />

      <Route exact strict path={`${path}/payroll-groups/`} component={GroupsPage} />
      <Route
        path={`${path}/payroll-groups/:groupUuid`}
        render={(p) => <GroupPage {...p} groupUuid={p.match.params.groupUuid} />}
      />
      <Route exact strict path={`${path}/lock-periods/`} component={LockPeriodsPage} />
      <Route
        exact
        strict
        path={`${path}/lock-periods-dectivated-employees/`}
        component={LockPeriodDeactivatedEmployeesPage}
      />
      <Route exact strict path={`${path}/digital-signatures`} component={DigitalSignaturesList} />
      <Route exact strict path={`${path}/digital-signatures/:payrollLockUuid`} component={DigitalSignaturesGroup} />

      <Route
        path={`${path}/lock-periods/:lockId`}
        render={({ match: { params } }) => <LockReport lockId={params.lockId} />}
      />

      <Redirect strict from={`${path}/export/view-:id/`} to={`${path}/export/view-:id`} />
      <Route
        path={`${path}/export/view-:id`}
        strict
        render={({ match: { params } }) => <PayrollReport id={params.id} />}
      />

      <Redirect strict from={`${path}/export/:id/`} to={`${path}/export/:id`} />
      <Route path={`${path}/export/:id`} strict render={({ match: { params } }) => <CreateLayout id={params.id} />} />

      <Redirect strict from={`${path}/export/`} to={`${path}/export`} />
      <Route path={`${path}/export`} strict render={(props) => <Payroll {...props} />} />

      <Redirect strict from={`${path}/export/create-layout/`} to={`${path}/export/create-layout`} />
      <Route path={`${path}/create-layout`} strict render={() => <CreateLayout />} />

      <Redirect strict from={`${path}/export/view-:id/`} to={`${path}/export/view-:id`} />
      <Route path={`${path}/export/view-:id`} strict render={(props) => <ReportsPage {...props} />} />
    </Switch>
  </Suspense>
);

export default RoutePayroll;
