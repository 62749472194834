import { Groups } from "types/models/dashboard";

const getGenericGroupName = (groupBy: Groups) =>
  ({
    [Groups.company]: "All Employees",
    [Groups.departments]: "Other employees",
    [Groups.postions]: "Other employees",
    [Groups.subsidiaries]: "Other employees",
    [Groups.teams]: "Other employees",
  }[groupBy]);

export default getGenericGroupName;
