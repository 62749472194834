import { Component, ContextType } from "react";
import TextInputControl from "components/controls/TextInputControl";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { createTask } from "./projectsApiUtils";
import GlobalContext from "../../context/global-context";
import { CreateTaskRequestData } from "./projectsApiTypes";

const Title = styled.div`
  font-size: 25px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  button {
    max-width: 155px;
  }
`;

const IdNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface TasksPopupMessageProps extends WithTranslation {
  projectUuid: string;
  onClose: () => void;
  onYes: (taskName: string) => void;
  title: string;
}

interface TasksPopupMessageState {
  name: string;
  loading: boolean;
  errors: Record<string, string> | null;
}

class TasksPopupMessage extends Component<TasksPopupMessageProps, TasksPopupMessageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  state: TasksPopupMessageState = {
    name: "",
    loading: false,
    errors: null,
  };

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = () => {
    this.closePopup();
  };

  saveProject = async () => {
    const { name } = this.state;
    const { projectUuid, onYes } = this.props;
    this.setState({ loading: true });

    const requestDetails: CreateTaskRequestData = {
      companyUuid: window.global_store.company.uuid,
      projectUuid,
      body: {
        content: {
          name,
          serviceUuids: [],
          createdBy: window.global_store.profile.uuid,
        },
      },
    };

    await createTask(requestDetails);
    onYes(name);
  };

  onYes = () => {
    const errors = this.validate();

    if (!errors) {
      void this.saveProject();
    } else {
      this.setState({ errors });
    }
  };

  validate = () => {
    const { name } = this.state;
    const { t } = this.props;
    let errors: Record<string, string> | null = {};

    if (!name.trim()) {
      errors.name = t(`${TranslationNamespaces.common}|Name can't be empty`);
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  render() {
    const { name, errors, loading } = this.state;
    const { t, title } = this.props;

    return (
      <div>
        <Title>{title}</Title>
        <IdNameWrapper>
          <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Name`)} wrapperWidth="100%" width="100%">
            <TextInputControl value={name} error={errors?.name} onChange={(value) => this.setState({ name: value })} />
          </FieldWrapper>
        </IdNameWrapper>
        {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={loading}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Button
            value={t(`${TranslationNamespaces.common}|Create`)}
            state={ButtonState.primary}
            disabled={!name}
            onClick={this.onYes}
            loading={loading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(TasksPopupMessage);
