import React, { useContext } from "react";
import { UserProfile } from "types/models/userProfile";

export type UserProfileContextData = {
  userProfile: UserProfile;
};
export const UserProfileContext = React.createContext<UserProfileContextData | null>(null);

export function useProfile() {
  const contextData = useContext(UserProfileContext);
  return contextData?.userProfile || window.global_store?.profile;
}
