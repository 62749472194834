import styled from "styled-components";
import { TFunction, TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { PayedOvertimePhase, PhaseType } from "types/models/businessRulesGroup";
import Tooltip from "components/UI/Tooltip";
import TextInputControl from "components/controls/TextInputControlNew";
import { ErrorLabel } from "components/UI/TextLabels";
import { getPhasesDays } from "utils/common";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CompensationRateCell, CustomNameCell, PhasesTableProps } from ".";
import PhaseValueInput from "./PhaseValueInput";
import PhaseLimitInput from "./PhaseLimitInput";
import { ButtonAddLimit, ButtonRemoveSplit, ButtonSplitHours } from "./buttons";
import SplitSlider from "./SplitSlider";
import AdditionalLimitsControl from "./AdditionalLimitsControl";

export const LimitsSubRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  .custom-phase-name-input {
    &.error {
      input {
        border-color: var(--colors-danger-600-p);
      }
    }
    input {
      text-overflow: ellipsis;
      border-color: var(--colors-surface-150);
      &:placeholder-shown {
        border-color: var(--colors-surface-150);
      }
      &::placeholder {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        color: var(--colors-mainText);
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
  }
`;

type PhaseLimitsRowProps = { phase: PayedOvertimePhase } & PhasesTableProps;

const getDefaultPhaseName = (
  t: TFunction,
  {
    isHoursBank,
    isDayTypeBasedOnSchedule,
    dayMask,
    limit,
    type,
  }: {
    isHoursBank: boolean;
    isDayTypeBasedOnSchedule: boolean;
    dayMask: string;
    limit: number | string;
    type: PhaseType;
  },
) => {
  let label = "";

  if (isHoursBank) {
    label = `${t("Hours Bank")} ${getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[dayMask]} ${limit}%`;
  } else {
    label = `${t("Extra Hours")} ${getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[dayMask]} ${limit}%`;
  }

  if (type !== PhaseType.combined) {
    label += ` (${t(type)})`;
  }

  return label;
};

const PhaseLimitsRow = ({
  phase,
  disabled,
  locked,
  phasesType,
  onPhaseChange,
  onRemovePhase,
  isDayTypeBasedOnSchedule,
  onlyEH,
  onAddPhase,
  errors,
}: PhaseLimitsRowProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const { customPhaseName } = useFlags();

  return (
    <LimitsSubRow>
      <CompensationRateCell>
        <PhaseValueInput
          disabled={disabled}
          locked={locked}
          value={phase.name}
          phasesType={phasesType}
          onChange={(value) => {
            onPhaseChange({
              uuid: phase.uuid,
              field: "name",
              value,
            });
          }}
        />
      </CompensationRateCell>

      {customPhaseName && (
        <CustomNameCell>
          <Tooltip id={`tooltip-custom-phase-name-${phase.uuid}`}>
            {phase.customName ||
              getDefaultPhaseName(t, {
                isHoursBank: !onlyEH,
                isDayTypeBasedOnSchedule,
                dayMask: phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"] || "",
                limit: phase.name,
                type: phase.type,
              })}
          </Tooltip>
          <div data-tip data-for={`tooltip-custom-phase-name-${phase.uuid}`}>
            <TextInputControl
              className="custom-phase-name-input"
              disabled={locked || disabled}
              locked={locked}
              placeholder={getDefaultPhaseName(t, {
                isHoursBank: !onlyEH,
                isDayTypeBasedOnSchedule,
                dayMask: phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"] || "",
                limit: phase.name,
                type: phase.type,
              })}
              error={!!errors?.phases && !!errors?.phases[phase.uuid]?.customName}
              value={phase.customName || ""}
              onChange={(evt) => {
                onPhaseChange({
                  uuid: phase.uuid,
                  field: "customName",
                  value: evt.target.value || "",
                });
              }}
            />
          </div>
          {errors?.phases && errors?.phases[phase.uuid]?.customName && (
            <ErrorLabel>{errors.phases[phase.uuid].customName}</ErrorLabel>
          )}
        </CustomNameCell>
      )}

      {phase.limit > -1 && (
        <PhaseLimitInput
          disabled={disabled}
          locked={locked}
          onChange={(val) => {
            onPhaseChange({
              uuid: phase.uuid,
              field: "limit",
              value: val,
            });
          }}
          onDeleteClick={() => {
            if (phase.additionalLimit !== null && phase.additionalLimit !== undefined) {
              onPhaseChange({
                uuid: phase.uuid,
                field: "limit",
                value: -1,
              });
            } else {
              const daysMaskOrDaysType = phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"];
              if (daysMaskOrDaysType) {
                onRemovePhase({
                  daysMaskOrDaysType,
                  uuid: phase.uuid,
                });
              }
            }
          }}
          value={phase.limit}
        />
      )}

      {!disabled && !locked && phase.limit === -1 && (
        <ButtonAddLimit
          onClick={() => {
            if (phase.additionalLimit !== null && phase.additionalLimit !== undefined) {
              onPhaseChange({
                uuid: phase.uuid,
                field: "limit",
                value: 120,
              });
            } else {
              const daysMaskOrDaysType = phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"];
              if (daysMaskOrDaysType) {
                onAddPhase({
                  daysMaskOrDaysType,
                  uuid: phase.uuid,
                  type: phase.type,
                });
              }
            }
          }}
        >
          {t("Add Limit")}
        </ButtonAddLimit>
      )}

      <AdditionalLimitsControl
        id={`tooltip-additional-limits-${phase.uuid}`}
        additionalTime={phase.additionalLimit}
        disabled={disabled || locked}
        onDelete={() => {
          if (phase.limit === -1) {
            const daysMaskOrDaysType = phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"];
            if (daysMaskOrDaysType) {
              onRemovePhase({
                daysMaskOrDaysType,
                uuid: phase.uuid,
              });
            }
          } else {
            onPhaseChange({
              uuid: phase.uuid,
              field: "additionalLimit",
              value: null,
            });
          }
        }}
        onApply={(time) => {
          if (phase.limit === -1) {
            const daysMaskOrDaysType = phase[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"];
            if (daysMaskOrDaysType) {
              onAddPhase({
                daysMaskOrDaysType,
                uuid: phase.uuid,
                type: phase.type,
                additionalLimit: time,
              });
            }
          } else {
            onPhaseChange({
              uuid: phase.uuid,
              field: "additionalLimit",
              value: time,
            });
          }
        }}
      />

      {!onlyEH && (
        <>
          {phase.extraHours === null && !disabled && !locked && (
            <ButtonSplitHours
              onClick={() => {
                onPhaseChange({
                  uuid: phase.uuid,
                  field: "extraHours",
                  value: 50,
                });
              }}
            />
          )}
          {phase.extraHours !== null && (
            <SplitSlider
              onChange={(value) => {
                onPhaseChange({
                  uuid: phase.uuid,
                  field: "extraHours",
                  value: 100 - value,
                });
              }}
              inactive={disabled || locked}
              value={100 - phase.extraHours}
            />
          )}
          {phase.extraHours !== null && !disabled && !locked && (
            <>
              <Tooltip id={`tooltip-split-${phase.uuid}`}>{t("remove-split-tooltip")}</Tooltip>
              <ButtonRemoveSplit
                data-tip
                data-for={`tooltip-split-${phase.uuid}`}
                onClick={() => {
                  onPhaseChange({
                    uuid: phase.uuid,
                    field: "extraHours",
                    value: null,
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </LimitsSubRow>
  );
};

export default PhaseLimitsRow;
