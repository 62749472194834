import SimpleMenu from "components/UI/Select/SimpleMenu";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import { COLOR_PALETTE } from "./chartDataHooks/defaultChartData";

const ChartLegendWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SeriesName = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 16px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-text1);
  user-select: none;
`;

const Circle = styled.span`
  margin-inline-end: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

const More = styled.div`
  display: flex;
  align-items: center;
  color: #0780e0;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  cursor: pointer;

  div {
    display: inline-block;
    padding-inline-end: 6px;
    padding-bottom: 5px;
  }
`;

interface ChartLegendProps {
  seriesNames: string[];
}

const ChartLegend = ({ seriesNames }: ChartLegendProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const hiddenSeriesNames = seriesNames.splice(5);

  return (
    <ChartLegendWrapper>
      {seriesNames.map((sn, i) => (
        <SeriesName key={sn}>
          <Circle style={{ backgroundColor: COLOR_PALETTE[i % COLOR_PALETTE.length] }} />
          <span>{sn}</span>
        </SeriesName>
      ))}

      {!!hiddenSeriesNames.length && (
        <SimpleMenu onChange={() => {}} options={hiddenSeriesNames.map((sn) => ({ value: sn, label: sn }))}>
          <More>
            <div>...</div> {t("more")}
          </More>
        </SimpleMenu>
      )}
    </ChartLegendWrapper>
  );
};

export default ChartLegend;
