import FullPage from "components/Layout/FullPage";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Permissions from "./Permissions";

const PermissionsWrapper = styled.div`
  margin-top: 20px;
`;

const PermissionsPage = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  return (
    <FullPage title={t("Settings")}>
      <PermissionsWrapper>
        <Permissions />
      </PermissionsWrapper>
    </FullPage>
  );
};

export default PermissionsPage;
