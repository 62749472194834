import React, { Component } from "react";
import BEM from "utils/BEM";
import "../UI/Page/Page.scss";
import "./styles/teams-page.scss";
import { withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import GlobalContext from "context/global-context";
import SearchInput from "components/UI/SearchInput";
import ModalDialog from "components/UI/ModalDialog";
import { getTitle, strIncludesCheck } from "utils/common";
import { updateTeam, deleteTeam } from "utils/apiHelpers";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import NotificationRow from "../NotificationRow";
import PopupMessage from "./PopupMessage";
import NoContent from "../NoContent";

const b = BEM.b("teams-page");

class TeamsPage extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      notification: "",
      searchValue: "",
      selectedItem: null,
      popupVisible: false,
      isFetching: false,
    };
    document.title = getTitle(t("Teams"));
  }

  componentDidMount() {
    this.getState();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.getState();
  }

  getState = async () => {
    if (!this.state.isFetching) {
      this.context.getTeams(true).then((teams) => {
        this.setState({
          selectedItem: null,
          popupVisible: false,
          items: teams || [],
          isFetching: false,
        });
      });
      this.setState({ isFetching: true });
    }
  };

  onRemoveItemClick(item, ev) {
    ev.stopPropagation();
    this.setState({ selectedItem: item });
  }

  removeItem(item) {
    const { t } = this.props;
    deleteTeam({ id: item.id })
      .then(() => {
        this.setState({ notification: `${t("You deleted")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }

  onEditItemClick = (item) => {
    this.setState({ selectedItem: item, popupVisible: true });
  };

  saveItem = (item) => {
    const { t } = this.props;
    updateTeam({
      id: item.id,
      body: {
        team: item,
      },
    })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Team Name"),
        accessor: "name",
        minWidth: 100,
        style: { lineHeight: "50px" },
      },
      {
        label: t("Supervisor"),
        accessor: "supervisor.name",
        minWidth: 100,
        style: { lineHeight: "50px" },
      },
      {
        label: t("Number of employees"),
        accessor: "employees_count",
        Cell: (row) => (
          <div>
            {row.value}
            <TableButtons className="buttons">
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onEditItemClick(row.original);
                }}
              >
                {t("common|Edit")}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 50,
      },
    ];
    return columns;
  }

  render() {
    const { items, searchValue, selectedItem, popupVisible, notification } = this.state;
    const { t } = this.props;

    const filteredItems = items.filter((s) => {
      const { name = "" } = s;
      const owner = s.supervisor ? s.supervisor.name : "";
      const filter = strIncludesCheck(name, searchValue) || strIncludesCheck(owner, searchValue);

      return filter;
    });

    return (
      <SettingsLayout title={t(`${TranslationNamespaces.sidebar}|Company Structure`)}>
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={notification} />
          )}

          <div className={b()}>
            <TablePage
              filters={
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search")}
                  value={searchValue}
                />
              }
              columnSelectorOnFiltersRow
              rows={filteredItems}
              columns={this.getTableColumns()}
              className="teams-table"
              loading={this.state.isFetching}
              noContentComponent={<NoContent>{t("You don’t have Teams")}</NoContent>}
            />
          </div>
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              title={t("Edit a Team")}
              item={selectedItem || {}}
              allItems={items}
              buttonYesTitle={t("Save")}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={this.saveItem}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withTranslation([TranslationNamespaces.teams, TranslationNamespaces.sidebar])(TeamsPage);
