import styled from "styled-components";
import { PermissionSection } from "types/models/permissions";
import { useState } from "react";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import PermissionActionsModal from "./PermissionActionsModal";

const Wrapper = styled.div``;
const ToggleButton = styled.div`
  padding: 8px;
  padding-inline-start: 30px;
  position: relative;
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-surface-150);
  border-radius: var(--shapes-border-radius-default);
  font-weight: var(--typography-font-weight-medium);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-900-p);
  cursor: pointer;
  &:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none'%3E%3Cpath fill='%231E97F7' d='M14.613 8.233C13.267 5.107 10.733 3.167 8 3.167c-2.733 0-5.267 1.94-6.613 5.066a.667.667 0 0 0 0 .534c1.346 3.126 3.88 5.066 6.613 5.066 2.733 0 5.267-1.94 6.613-5.066a.667.667 0 0 0 0-.534ZM8 12.5c-2.12 0-4.113-1.527-5.267-4C3.887 6.027 5.88 4.5 8 4.5c2.12 0 4.113 1.527 5.267 4-1.154 2.473-3.147 4-5.267 4Zm0-6.667a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334Zm0 4a1.333 1.333 0 1 1 0-2.666 1.333 1.333 0 0 1 0 2.666Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    width: 16px;
    height: 100%;
    position: absolute;
    inset-inline-start: 8px;
    top: 1px;
  }
`;

type TogglePermissionActionsProps = {
  permission: PermissionSection;
  onChange: (permission: PermissionSection) => void;
};

const TogglePermissionActions = ({ permission, onChange }: TogglePermissionActionsProps) => {
  const { t } = useTranslation([TranslationNamespaces.employeesPermissions]);
  const activeCount = permission.permission_actions.filter((p) => p.allow).length;
  const allCount = permission.permission_actions.length;
  const [dialogVisible, setDialogVisible] = useState(false);
  const label = activeCount === allCount ? t("All") : `${activeCount}/${allCount}`;

  return (
    <Wrapper>
      <ToggleButton onClick={() => setDialogVisible(true)}>{label}</ToggleButton>
      {dialogVisible && (
        <PermissionActionsModal
          onClose={() => setDialogVisible(false)}
          onSave={(permissionActions) => {
            onChange({ ...permission, permission_actions: permissionActions });
            setDialogVisible(false);
          }}
          permission={permission}
        />
      )}
    </Wrapper>
  );
};

export default TogglePermissionActions;
