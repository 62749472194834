import { UserProfile } from "types/models/userProfile";

class UserProfileHelpers {
  formatUserProfile(userProfile: UserProfile) {
    return {
      ...userProfile,
      avatarId: userProfile.avatar_id,
      birthDate: userProfile.birth_date,
      companyUuid: userProfile.company.uuid,
      defaultTimeZone: userProfile.default_time_zone,
      fullName: userProfile.full_name || userProfile.name!,
      lastLockDate: userProfile.last_lock_date,
    };
  }
}
export default new UserProfileHelpers();
