import styled from "styled-components";

interface InputFieldIcon {
  type: "person" | "group";
}

const SimpleIcon = styled.div<InputFieldIcon>`
  position: absolute;
  width: 25px;
  height: 36px;
  inset-inline-start: 16px;
  background-image: ${(p) => p.theme.svg[`search_${p.type}`]};
  background-repeat: no-repeat;
  background-position: center;
`;

export default ({ type = "person" }: Partial<InputFieldIcon>) => <SimpleIcon className="search-icon" type={type} />;
