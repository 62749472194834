import TimeControl from "components/controls/TimeControl";
import styled from "styled-components";
import { minsToHrsMins } from "utils/common";
import cx from "classnames";

const Wrapper = styled.div`
  width: 71px;
  position: relative;
  &:hover {
    .mutliple-activities-button {
      opacity: 1;
    }
  }
  .mutliple-activities-button {
    opacity: 0;
    background-color: #f3f5fa;
    width: 14px;
    height: 28px;
    position: absolute;
    inset-inline-end: 1px;
    top: 1px;
    border: none;
    border-radius: 0 4px 4px 0;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.606 8.759H2.521c-.679 0-1.358.002-2.037-.001-.438-.002-.614-.31-.38-.68C1.681 5.563 3.264 3.048 4.846.535c.127-.203.297-.318.538-.21.233.106.276.298.216.537-.377 1.485-.75 2.97-1.123 4.456-.015.06-.023.121-.042.223h.294c.929 0 1.858.002 2.786-.002.2 0 .412.017.466.224.04.15.013.36-.069.49-1.558 2.497-3.13 4.986-4.696 7.478-.131.209-.288.348-.549.25-.222-.083-.29-.276-.213-.582.368-1.468.737-2.936 1.104-4.405.018-.068.028-.137.048-.235h-.001Z' fill='%231E97F7'/%3E%3C/svg%3E");
    cursor: pointer;
    transition: opacity 200ms;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

type Props = {
  placeholder: string;
  value: number;
  disabled: boolean;
  grayedOut: boolean;
  notEditable: boolean;
  onTimeChange: (value: { value: string; error: boolean }) => void;
  onButtonClick: () => void;
};

export default (props: Props) => {
  const { value, placeholder, disabled, onButtonClick, onTimeChange, grayedOut, notEditable } = props;

  return (
    <Wrapper>
      <TimeControl
        className={cx({ "grayed-out": grayedOut, "not-editable": notEditable })}
        placeholder={placeholder}
        value={value === 0 ? "" : minsToHrsMins(value)}
        disabled={disabled || notEditable}
        onTimeChange={onTimeChange}
        autoComplete={localStorage.timesheetInputsAutoComplete || "off"}
      />
      {!notEditable && <button type="button" className="mutliple-activities-button" onClick={onButtonClick} />}
    </Wrapper>
  );
};
