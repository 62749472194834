import { Component, ContextType } from "react";
import styled from "styled-components";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import { Operation, OperationStatus } from "types/models/operations";
import { LocalStorageKeys } from "utils/localStorageUtils";
import SidePopupOverlay from "../UI/SidePopupOverlay";
import NotificationPanel from "./NotificationPanel";

const IconWrapper = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  padding: 6px;
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;

  &:hover {
    background: var(--colors-theme-tr-icon-bg-hvr);

    path {
      fill: var(--colors-theme-tr-icon-fill-hvr);
    }
  }
`;

const NotificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.8333 11.3215V8.33317C15.8333 5.65234 14.0125 3.394 11.5458 2.71484C11.3017 2.09984 10.705 1.6665 10 1.6665C9.295 1.6665 8.69833 2.09984 8.45417 2.71484C5.9875 3.39484 4.16667 5.65234 4.16667 8.33317V11.3215L2.74417 12.744C2.5875 12.8998 2.5 13.1115 2.5 13.3332V14.9998C2.5 15.4607 2.8725 15.8332 3.33333 15.8332H16.6667C17.1275 15.8332 17.5 15.4607 17.5 14.9998V13.3332C17.5 13.1115 17.4125 12.8998 17.2558 12.744L15.8333 11.3215ZM15.8333 14.1665H4.16667V13.6782L5.58917 12.2557C5.74583 12.0998 5.83333 11.8882 5.83333 11.6665V8.33317C5.83333 6.03567 7.7025 4.1665 10 4.1665C12.2975 4.1665 14.1667 6.03567 14.1667 8.33317V11.6665C14.1667 11.8882 14.2542 12.0998 14.4108 12.2557L15.8333 13.6782V14.1665ZM10 18.3332C11.0925 18.3332 12.0058 17.6382 12.3483 16.6665H7.65167C7.99417 17.6382 8.9075 18.3332 10 18.3332Z"
      fill="#8093AC"
    />
  </svg>
);

const InProgressIndicator = styled.div`
  position: absolute;
  top: 5px;
  inset-inline-end: 7px;
  width: 8px;
  height: 8px;
  background-color: var(--colors-pending);
  border-radius: 50%;
`;

type NotificationControlProps = WithTranslation;

interface NotificationControlState {
  popupVisible: boolean;
  items: Operation[];
  /** Operation uuids */
  unseenItems: string[];
  count: number;
  lastNotificationUuid: string;
}

class NotificationControl extends Component<NotificationControlProps, NotificationControlState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  timeout: NodeJS.Timeout | null = null;

  readonly state: Readonly<NotificationControlState> = {
    popupVisible: false,
    items: [],
    unseenItems: [],
    count: 0,
    lastNotificationUuid: localStorage.getItem(LocalStorageKeys.lastNotificationUuid) || "",
  };

  async componentDidMount() {
    void this.getNotifications();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getNotifications = async () => {
    const operations = await this.context.getOperations();
    this.updateState(operations);

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.getNotifications, 10000);
  };

  updateState = (operations: Operation[]) => {
    let count = 0;
    let items: Operation[] = [];
    const unseenItems: string[] = [];
    const { lastNotificationUuid } = this.state;

    if (operations.length) {
      // from latest to oldest
      items = operations.sort((a, b) => {
        const createdA = moment(a.createdAt);
        const updatedA = moment(a.updatedAt);
        const createdB = moment(b.createdAt);
        const updatedB = moment(b.updatedAt);

        if (
          createdA.isBefore(createdB) ||
          createdA.isBefore(updatedB) ||
          updatedA.isBefore(createdB) ||
          updatedA.isBefore(updatedB)
        ) {
          return 1;
        }

        if (
          createdB.isBefore(createdA) ||
          createdB.isBefore(updatedA) ||
          updatedB.isBefore(createdA) ||
          updatedB.isBefore(updatedA)
        ) {
          return -1;
        }

        return 0;
      });

      if (items[0].uuid !== lastNotificationUuid) {
        items.some((item) => {
          if (item.uuid !== lastNotificationUuid) {
            count += 1;
            unseenItems.push(item.uuid);
            return false;
          }

          return true;
        });
      }
    }

    this.setState({ items, lastNotificationUuid, count, unseenItems });
  };

  onIconClick = () => {
    this.setState({ popupVisible: true });
  };

  onClosePopup = () => {
    const { items } = this.state;
    let { lastNotificationUuid } = this.state;

    if (items.length) {
      lastNotificationUuid = items[0].uuid;
      localStorage.setItem(LocalStorageKeys.lastNotificationUuid, lastNotificationUuid);
    }

    this.setState({ popupVisible: false, unseenItems: [], count: 0, lastNotificationUuid });
  };

  render() {
    const { t } = this.props;
    const { popupVisible, items, count, unseenItems } = this.state;

    return (
      <>
        <IconWrapper onClick={this.onIconClick}>
          <NotificationIcon />
          {(!!count || items.some((item) => item.status === OperationStatus.pending)) && <InProgressIndicator />}
        </IconWrapper>
        <SidePopupOverlay header={t("Notification")} isOpen={popupVisible} contentOverflow onClose={this.onClosePopup}>
          <NotificationPanel items={items} unseenItems={unseenItems} />
        </SidePopupOverlay>
      </>
    );
  }
}

export default withTranslation()(NotificationControl);
