import styled from "styled-components";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "components/styled/MaskedInput";
import { PhasesType } from "./helpers";

const PhaseInput = styled(MaskedInput)<{ $phasesType: PhasesType }>`
  position: relative;

  input {
    padding-inline-start: 38px;
    border-color: var(--colors-surface-150);
  }
  &.disabled input {
    border-color: var(--colors-surface-150);
  }

  &:before {
    content: "${(p) => (p.$phasesType === PhasesType.PERCENTAGE ? "%" : "×")}";
    position: absolute;
    inset-inline-start: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
    font-size: ${(p) => (p.$phasesType === PhasesType.PERCENTAGE ? "15px" : "22px")};
    font-weight: ${(p) => (p.$phasesType === PhasesType.PERCENTAGE ? "400" : "600")};
    line-height: 21px;
    background-color: ${(p) =>
      p.$phasesType === PhasesType.PERCENTAGE ? "rgba(185, 163, 239, 0.2)" : "rgba(239, 181, 163, 0.2)"};
    color: ${(p) => (p.$phasesType === PhasesType.PERCENTAGE ? "#9372E2" : "#FF7A00")};
  }
`;

type PhaseValueInputProps = {
  disabled: boolean;
  locked: boolean;
  value: number;
  onChange: (val: number) => void;
  phasesType: PhasesType;
};

const PhaseValueInput = ({ disabled, locked, value, phasesType, onChange, onBlur }: PhaseValueInputProps) => {
  const percentageMask = createNumberMask({
    prefix: "",
    suffix: "",
    allowDecimal: phasesType === PhasesType.MULTIPLIER,
    includeThousandsSeparator: phasesType !== PhasesType.MULTIPLIER,
    decimalLimit: 2,
    allowNegative: false,
  });

  return (
    <PhaseInput
      $phasesType={phasesType}
      disabled={disabled || locked}
      locked={locked}
      value={value}
      onBlur={(ev) => {
        const inputVal = ev.target.value;
        let result;
        if (inputVal && phasesType === PhasesType.PERCENTAGE) {
          result = inputVal.replace(/[^\d]/g, "");
          result = result ? parseInt(result, 10) : 0;
        } else if (inputVal && phasesType === PhasesType.MULTIPLIER) {
          result = parseFloat(inputVal);
          result = Number.isFinite(result) ? result : 0;
        } else {
          result = 0;
        }

        onChange(result);
      }}
      mask={percentageMask}
    />
  );
};

export default PhaseValueInput;
