import SafeInput, { SafeInputProps, Selection } from "../SafeInput";

function validateTime(HHmm: string) {
  if (!HHmm) return false;
  const re = /^(([01][0-9])|([2][0-3])):[0-5][0-9]$/;
  return HHmm.match(re) !== null;
}

function validateInput(val: string) {
  const re = /^(([01]?[0-9]?)|([2]?[0-3]?)):?[0-5]?[0-9]?$/;
  return val.match(re) !== null;
}

function prepareInput(val: string) {
  let v = val;
  v = v.replace(/[^0-9:]/g, "");
  const firstColonIndex = v.indexOf(":");
  if (firstColonIndex !== -1) {
    // because lookbehind is not supported by safari
    v = `${v.slice(0, firstColonIndex + 1)}${v.slice(firstColonIndex + 1).replace(/:/g, "")}`;
  }
  if (!v.includes(":") && v.length > 2) {
    v = `${v.slice(0, 2)}:${v.slice(2)}`;
  }
  const isValid = validateInput(v);
  if (!isValid) return null;
  return v;
}

function completeInput(value: string) {
  let val = value;
  const [h, m = ""] = val.split(":");
  const hNum = Number.parseInt(h || "0", 10);
  const mNum = Number.parseInt(m || "0", 10);
  const mmStr = mNum < 6 ? m.padEnd(2, "0") : m.padStart(2, "0");
  val = `${String(hNum).padStart(2, "0")}:${mmStr}`;

  const isValid = validateTime(val);
  if (!isValid) {
    val = "00:00";
  }
  return val;
}

function smartInput(val: string, sel: Selection, key: string) {
  const [ss, se] = sel[0] < sel[1] ? sel : [sel[1], sel[0]];
  if (ss !== se) return null;
  const separators = " :.,;/";
  if (val.length === 0 && ss === 0) {
    if (separators.includes(key)) {
      return { cursor: 3, value: "00:" };
    }
    if ("3456789".includes(key)) {
      return { cursor: 3, value: `0${key}:` };
    }
  }
  if ((val === "0" || val === "1") && ss === 1) {
    if (separators.includes(key)) {
      return { cursor: 3, value: `0${val}:` };
    }
    if ("0123456789".includes(key)) {
      return { cursor: 3, value: `${val}${key}:` };
    }
  }
  if (val === "2" && ss === 1) {
    if ("0123".includes(key)) {
      return { cursor: 3, value: `${val}${key}:` };
    }
    if ("45".includes(key)) {
      return { cursor: 4, value: `02:${key}` };
    }
    if ("6789".includes(key)) {
      return { cursor: 5, value: `02:0${key}` };
    }
  }
  if (val.match(/^[0-9]$/) && ss === 1) {
    if (separators.includes(key)) {
      return { cursor: 3, value: `0${val}:` };
    }
  }
  if (val.match(/^[0-9][0-9]$/) && ss === 2) {
    if (separators.includes(key)) {
      return { cursor: 3, value: `${val}:` };
    }
    if ("012345".includes(key)) {
      return { cursor: 4, value: `${val}:${key}` };
    }
    if ("6789".includes(key)) {
      return { cursor: 5, value: `${val}:0${key}` };
    }
  }
  if (val.match(/^[0-9][0-9]:$/) && ss === 3) {
    if ("6789".includes(key)) {
      return { cursor: 5, value: `${val}0${key}` };
    }
  }
  if (separators.includes(key) && val[ss] === ":") {
    return { cursor: ss + 1, value: val };
  }
  return null;
}

export type SafeTimeInputProps = Omit<SafeInputProps, "smartInput" | "prepareInput" | "completeInput">;

export default function SafeTimeInput(props: SafeTimeInputProps) {
  return (
    <SafeInput
      placeholder="00:00"
      smartInput={smartInput}
      prepareInput={prepareInput}
      completeInput={completeInput}
      {...props}
    />
  );
}
