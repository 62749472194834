import React, { Component, createRef } from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  overflow: auto;
  margin-top: 5px;
  padding: 10px;
  max-height: 200px;
  width: 100%;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-default);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
  width: auto;
`;

class DropdownWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        left: "0",
        top: "23px",
      },
    };

    this.root = createRef();
  }

  componentDidMount() {
    let left = "auto";
    let right = "auto";
    const top = "auto";
    let bottom = "auto";

    if (this.props.left) {
      left = this.props.left;
    } else if (this.hasRightOverflow()) {
      right = "0";
    }

    if (this.hasBottomOverflow()) {
      bottom = "53px";
    }

    this.setState({ position: { left, right, top, bottom } });
  }

  hasRightOverflow() {
    return this.root.current && this.root.current.getBoundingClientRect().right + 10 > window.innerWidth;
  }

  hasBottomOverflow() {
    return this.root.current && this.root.current.getBoundingClientRect().bottom + 50 > window.innerHeight;
  }

  render() {
    const { children, className, style } = this.props;
    const { position } = this.state;

    return (
      <Wrapper ref={this.root} style={{ ...position, ...(style || {}) }} className={className || ""}>
        {children}
      </Wrapper>
    );
  }
}

export default DropdownWrapper;
