import { Component, ReactNode } from "react";
import TableCommon, { iColumn, iTableCommonProps } from "components/TableCommon";
import MultiSelect from "components/UI/Select/MultiWithSearch";
import BEM from "utils/BEM";
import styled from "styled-components";
import * as images from "./svg-images";
import Tooltip from "./UI/Tooltip";
import "styles/table-page.scss";

const tb = BEM.b("table-page");

const Wrapper = styled.div`
  .ui-select__input_only-icon {
    padding-inline-end: 16px;
    background: var(--colors-surface-50);
    padding: 0 8px;
  }

  .ui-select__input_only-icon:hover {
    background: var(--colors-surface-100);
  }

  .ui-select__input_only-icon:after {
    display: none;
  }

  .ui-select__option-list {
    left: unset;
    inset-inline-end: 0;
  }
`;

export const ColumnsSelector = ({
  value,
  onChange,
  options,
}: {
  value: string | string[];
  onChange: (val: string[]) => void;
  options: {
    value: string;
    label: string;
    disabled: boolean;
  }[];
}) => {
  let val: string[] = [];

  if (value && !Array.isArray(value) && value.indexOf(",") > -1) {
    val = value.split(",");
  } else {
    val = value as string[];
  }

  return (
    <Wrapper>
      <MultiSelect icon={images.customColumnsNewDesign} value={val} onChange={onChange} options={options} />
    </Wrapper>
  );
};

interface iTablePageProps<R> extends Partial<iTableCommonProps<R>> {
  customColumnsAvailable: boolean;
  filters: JSX.Element | null;
  noContentComponent?: JSX.Element;
  onColumnsChange?: (selectedColumns: string[]) => void;
  tableDetails: JSX.Element | null;
  downloadControl: JSX.Element | null;
  punchesToggle: ReactNode;
  superpunch: JSX.Element | null;
  superpunchPage?: boolean;
  infoControl: JSX.Element | null;
  tableDatailsClassName?: string;
  columnSelectorOnFiltersRow?: boolean;
  selectedColumns?: string[] | string;
  showDetailsRow?: boolean;
}

interface iTablePageState {
  selectedColumns: string[] | string;
  columnsSelectorOptions: { value: string; label: string; disabled: boolean }[];
}

class TablePage<R> extends Component<iTablePageProps<R>, iTablePageState> {
  static defaultProps = {
    customColumnsAvailable: true,
    interactive: true,
    filters: null,
    tableDetails: null,
    downloadControl: null,
    punchesToggle: null,
    superpunch: null,
    infoControl: null,
    rows: [],
    columns: [],
  };

  constructor(props: iTablePageProps<R>) {
    super(props);
    const { selectedColumns, columnsSelectorOptions } = TablePage.updateColumns(props);

    this.state = { selectedColumns, columnsSelectorOptions };
  }

  static getDerivedStateFromProps<R>(nextProps: iTablePageProps<R>): Partial<iTablePageState> {
    const { selectedColumns, columnsSelectorOptions } = TablePage.updateColumns(nextProps);

    return { selectedColumns, columnsSelectorOptions };
  }

  static updateColumns<R>(props: iTablePageProps<R>): iTablePageState {
    const columns = props.columns || [];
    let columnsSelectorOptions = columns.map((col) => ({
      value: col?.rubyAccessor?.toString() || col?.accessor?.toString() || "",
      label: col.groupLabel || col.label || "",
      disabled: col.locked || false,
    }));

    const allOptions = columnsSelectorOptions.map((col) => col.value);
    columnsSelectorOptions = columnsSelectorOptions.filter((v, i) => allOptions.indexOf(v.value) === i);

    return {
      selectedColumns: columnsSelectorOptions.map((col) => col?.value || ""),
      columnsSelectorOptions,
    };
  }

  render() {
    // refactor this
    const {
      noContentComponent,
      onColumnsChange,
      tableDetails = null,
      downloadControl = null,
      punchesToggle = null,
      superpunch = null,
      infoControl = null,
      tableDatailsClassName,
      withHeaderTooltip,
      showDetailsRow = true,
      ...tableProps
    } = this.props;
    const { rows, columns = [], loading, filters, columnSelectorOnFiltersRow, customColumnsAvailable } = this.props;

    const { columnsSelectorOptions } = this.state;
    const selectedColumns = this.props.selectedColumns || this.state.selectedColumns;
    const tableDatailsCss = [tb("details-row")];

    if (tableDatailsClassName) {
      tableDatailsCss.push(tableDatailsClassName);
    }

    let cls = columns;
    cls = columns.reduce((a, c) => {
      const column = { ...c };

      if (!column.Header) {
        if (withHeaderTooltip) {
          const tooltipId = `table-header-tooltip${column.label?.replace(" ", "-")}`;

          column.Header = (
            <>
              <span data-tip data-for={tooltipId} style={{ pointerEvents: "auto" }}>
                {column.label}
              </span>
              <Tooltip id={tooltipId}>{column.description || column.label}</Tooltip>
            </>
          );
        } else {
          column.Header = column.label;
        }
      }
      a.push(column);

      return a;
    }, [] as iColumn<R>[]);

    if (customColumnsAvailable) {
      cls = columns.reduce((a, b) => {
        // todo remove 'as string'
        if (
          b.locked ||
          (b.accessor && selectedColumns.indexOf(b.accessor.toString()) > -1) ||
          selectedColumns.indexOf(b.rubyAccessor as string) > -1
        ) {
          const column = { ...b };

          if (!column.Header) {
            if (withHeaderTooltip) {
              const tooltipId = `table-header-tooltip-${column.label?.replace(" ", "-")}`;

              column.Header = (
                <>
                  <span data-tip data-for={tooltipId} style={{ pointerEvents: "auto" }}>
                    {column.label}
                  </span>
                  <Tooltip id={tooltipId}>{column.description || column.label}</Tooltip>
                </>
              );
            } else {
              column.Header = column.label;
            }
          }
          a.push(column);
        }
        return a;
      }, [] as iColumn<R>[]);
    }

    const customColumnsSelector = (
      <ColumnsSelector
        value={selectedColumns}
        onChange={(val: string[]): void => {
          if (onColumnsChange) {
            onColumnsChange(val);
          } else {
            this.setState({ selectedColumns: val });
          }
        }}
        options={columnsSelectorOptions}
      />
    );

    return (
      <>
        {filters && (
          <div className={tb("filters-row")}>
            <div className={tb("filters")}>{filters}</div>
            {columnSelectorOnFiltersRow && (
              <div className={tb("controls")}>
                {infoControl}
                {customColumnsAvailable ? customColumnsSelector : null}
                {downloadControl}
                {punchesToggle}
              </div>
            )}
          </div>
        )}
        {loading || rows?.length ? (
          <>
            {!loading &&
              (tableDetails ||
                (showDetailsRow && !columnSelectorOnFiltersRow) ||
                (downloadControl && !columnSelectorOnFiltersRow)) && (
                <div className={tableDatailsCss.join(" ")}>
                  <div className={tb("details")}>{tableDetails}</div>
                  <div className={tb("controls")}>
                    {superpunch && superpunch}
                    {!columnSelectorOnFiltersRow && customColumnsAvailable && customColumnsSelector}
                    {!columnSelectorOnFiltersRow && downloadControl}
                  </div>
                </div>
              )}
            <TableCommon<R> {...tableProps} columns={cls} />
          </>
        ) : (
          noContentComponent || null
        )}
      </>
    );
  }
}

export default TablePage;
