import { Component, PropsWithChildren } from "react";
import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Portal from "@atlaskit/portal";

const Overlay = styled.div<{ $isOpen: boolean; $dark?: boolean }>`
  position: fixed;
  inset: 0;
  background: ${(p) => (p.$dark ? "rgba(0, 0, 0, 0.70);" : "rgb(255 255 255 / 93%)")};
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  overscroll-behavior: contain;
  overflow-y: auto;
`;

const Dialog = styled.div<{ $top?: string; $width: number; $padding: number }>`
  position: absolute;
  top: ${(props) => (props.$top ? props.$top : "calc(50% - 290px)")};
  left: ${(props) => `calc(50% - ${props.$width / 2}px);`};
  top: 50%;
  transform: translateY(-50%);
  background: var(--colors-default);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
  border-radius: 8px;
  padding: ${(props) => props.$padding}px;
  width: ${(props) => props.$width}px;
`;

export interface ModalDialogProps extends PropsWithChildren {
  onClose: () => void;
  width: number;
  padding: number;
  top?: string;
  offsetLeft?: number;
  isOpen: boolean;
  preventOutsideClose?: boolean;
  dark?: boolean;
}

/**
 * @deprecated Use ModalDialog from components/UI/ModalDialog/ModalDialog.tsx
 */
class ModalDialog extends Component<ModalDialogProps> {
  static defaultProps = {
    width: 414,
    padding: 35,
  };
  wrapperRef: HTMLDivElement | null = null;

  constructor(props: ModalDialogProps) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyPress);
  }

  closePopup = () => {
    this.props.onClose();
  };

  handleKeyPress = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27 && !this.props.preventOutsideClose) {
      this.closePopup();
    }
  };

  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  };

  render() {
    const { children, isOpen, offsetLeft, width, top, padding, dark } = this.props;

    return (
      <Portal key="HoursBankAdjustPopup" zIndex={99999}>
        <TransitionGroup>
          {isOpen && (
            <CSSTransition classNames="modal-transition" timeout={{ enter: 175, exit: 175 }}>
              <Overlay
                $dark={dark}
                className="overlay"
                $isOpen
                key="overlay"
                style={{
                  left: offsetLeft || undefined,
                  width: offsetLeft ? `calc(100% - ${offsetLeft}px)` : undefined,
                }}
                onMouseDown={() => !this.props.preventOutsideClose && this.closePopup()} // onMouseDown helps to avoid closing if mouse was clicked inside and released outside
              >
                <Dialog
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  $width={width}
                  $top={top}
                  $padding={padding}
                  className="modal-dialog"
                  ref={this.setWrapperRef}
                >
                  {children}
                </Dialog>
              </Overlay>
            </CSSTransition>
          )}
        </TransitionGroup>
      </Portal>
    );
  }
}

export default ModalDialog;
