import { useRef, useState, useLayoutEffect, useCallback } from "react";
import { stylesheet } from "astroturf";
import cx from "classnames";

function measureHeightWithMargins(node: HTMLElement) {
  const bcRect = node.getBoundingClientRect();
  const compStls = window.getComputedStyle(node);
  const marginH = parseFloat(compStls.marginTop) + parseFloat(compStls.marginBottom);
  const height = bcRect.height + marginH;
  return height;
}

const styles = stylesheet`
.Wrapper {
  overflow: hidden;
  transition: height 0.1s ease-in;
}
`;

export type ExpandProps = React.HTMLProps<HTMLDivElement> & { expanded: boolean, onChangeHeight?: (height: number) => void };
/**
 * Animate height 0 <-> auto. Css allows only concrete values for animation (not auto).
 * The component consists of a wrapper div whose size is animated and child div with a ref.
 * We get specific size values of child div via ref and use them instead of auto.
 */
export function Expand(props: ExpandProps) {
  const { className, expanded, children, onChangeHeight, ...restProps } = props;

  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [height, setHeight] = useState(0);
  const applyHeight = useCallback(() => {
    if (!expanded) {
      setHeight(0);
      return;
    }
    const h = measureHeightWithMargins(divRef.current);
    setHeight(h);
  }, [expanded, divRef]);
  useLayoutEffect(() => applyHeight(), [applyHeight]);

  useLayoutEffect(() => {
    const ro = new ResizeObserver(applyHeight);
    const div = divRef.current;
    ro.observe(div);
    return () => ro.disconnect();
  }, [applyHeight, divRef]);

  const onTransitionEnd = useCallback(() => {
    if (!onChangeHeight) return;
    onChangeHeight(height);
  }, [height, onChangeHeight]);

  return (
    <div className={cx(styles.Wrapper, className)} onTransitionEnd={onTransitionEnd} {...restProps} style={{ height }}>
      <div ref={divRef}>{children}</div>
    </div>
  );
}
