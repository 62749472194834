import React, { MouseEvent, ReactNode } from "react";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RightAngleIcon from "@iconscout/react-unicons/icons/uil-angle-right";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LeftAngleIcon from "@iconscout/react-unicons/icons/uil-angle-left";

import SwitchControl from "../SwitchControl";
import * as images from "../../svg-images";
import IconButton from "../IconButton";

interface ListItemCategoryProps {
  disabled: boolean;
  onChange: (checked: boolean) => void;
  checked: boolean;
  className?: string;
  label?: ReactNode;
  selected: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  canDelete?: boolean;
  onDelete?: (event: MouseEvent<HTMLElement>) => void;
}

const Wrapper = styled.div<{ selected: boolean; canDelete?: boolean }>`
  display: flex;
  height: var(--component-toggles-li-category-height, 32px);
  padding: 0px var(--component-toggles-li-category-wicon-hpad, 6px) 0px var(--component-toggles-li-category-hpad, 8px);
  border-radius: var(--component-toggles-li-category-border-radius, 4px);
  width: 100%;

  align-items: center;
  gap: var(--component-toggles-li-category-gap, 12px);
  flex-shrink: 0;
  align-self: stretch;

  &:hover {
    background: var(--component-toggles-li-category-hover-bg-color, rgba(33, 36, 46, 0.08));
    cursor: pointer;

    .deleteIcon {
      display: flex;
    }

    .angle-icon {
      ${({ canDelete }) => canDelete && "display: none;"}
    }
  }

  background: ${({ selected }) =>
    selected ? "var(--component-toggles-li-category-selected-bg-color, #F7F7F7)" : "unset"};

  .switch-control-label {
    flex: 1 0 0;
    color: var(--component-toggles-li-category-label-text-color, #3e4357);
    font-family: var(--typography-font-family-default, "Circular");
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px;
    letter-spacing: -0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
  }

  .angle-icon {
    height: 24px;
    svg {
      fill: var(--component-icon-button-common-default-icon-color, #575f79);
      flex-shrink: 0;
      width: unset;
      height: unset;
    }
  }

  .deleteIcon {
    flex-shrink: 0;
    display: none;

    svg {
      width: unset;
      height: unset;
    }
  }
`;

const ListItemCategory = ({
  onClick,
  disabled,
  onChange,
  checked,
  className,
  label,
  selected,
  canDelete,
  onDelete,
}: ListItemCategoryProps) => {
  return (
    <Wrapper className={className} onClick={onClick} selected={selected} canDelete={canDelete}>
      <SwitchControl disabled={disabled} onChange={onChange} checked={checked} />
      <span className="switch-control-label">{label}</span>
      {selected && (
        <span className="angle-icon">{window.global_store.isRTL ? <LeftAngleIcon /> : <RightAngleIcon />}</span>
      )}
      {canDelete && (
        <div data-tip data-for="tooltip-for-delete-request-type" className="deleteIcon" onClick={onDelete}>
          <IconButton icon={images.trashIcon} size="small" variant="tertiary" />
        </div>
      )}
    </Wrapper>
  );
};

ListItemCategory.defaultProps = {
  checked: false,
  disabled: false,
  canDelete: false,
  selected: false,
};

export default ListItemCategory;
