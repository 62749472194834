import { stylesheet } from "astroturf";
import { useState, useCallback } from "react";
import { Expand } from "components/views/Expand";
import { ActivitiesListItem, ActivitiesListItemProps } from "../ActivitiesListItem";

const styles = stylesheet`
.Group {
  .ItemsWrapper {
    overflow: hidden;
    background-color: #F5F9FC;
    transition: height 0.1s ease-in;

    .Item {
      padding-inline-start: 44px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}
`;

export interface ActivitiesListGroupProps extends ActivitiesListItemProps {
  id: string;
  items: (ActivitiesListItemProps & { id: string; time: string })[];
  onEditItem?: (item: { id: string }) => void;
  onRepeatItem?: (item: { id: string }) => void;
  wireframe?: boolean;
  onExpandActivitiesGroup: (numItems: number) => void;
}

export function ActivitiesListGroup(props: ActivitiesListGroupProps) {
  const { items, onEditItem, onRepeatItem, client, project, duration, task, wireframe, onExpandActivitiesGroup } =
    props;
  const [collapsed, setCollapsed] = useState(true);
  const handleClick = useCallback(
    (itms: any[]) => {
      if (itms.length === 1) {
        onEditItem && onEditItem(itms[0]);
      }
    },
    [onEditItem],
  );

  return (
    <div className={styles.Group}>
      <ActivitiesListItem
        client={client}
        project={project}
        task={task}
        duration={duration}
        onShowItems={() => {
          if (collapsed) onExpandActivitiesGroup(items.length);
          setCollapsed(!collapsed);
        }}
        onRepeat={onRepeatItem == null ? undefined : () => onRepeatItem(items[0])}
        onClick={() => handleClick(items)}
        numItems={items.length > 1 ? items.length : undefined}
        collapsed={collapsed}
        wireframe={wireframe}
      />
      {items.length > 1 && (
        <Expand className={styles.ItemsWrapper} expanded={!collapsed}>
          {items.map((item) => (
            <ActivitiesListItem
              className={styles.Item}
              key={item.id}
              {...item}
              project={item.time}
              client={undefined}
              onClick={() => onEditItem && onEditItem(item)}
              onRepeat={onRepeatItem == null ? undefined : () => onRepeatItem(item)}
            />
          ))}
        </Expand>
      )}
    </div>
  );
}
