import { getImgUrl } from "utils/common";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import "./drop-zone.scss";

const Wrapper = styled.div`
  &.with-hover {
    position: relative;
    &:before {
      z-index: 1;
      content: "";
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6.133a4.888 4.888 0 00-4.883 4.883A4.888 4.888 0 0010 15.898a4.888 4.888 0 004.883-4.882A4.888 4.888 0 0010 6.133zm0 8.203c-1.83 0-3.32-1.49-3.32-3.32 0-1.831 1.49-3.32 3.32-3.32 1.83 0 3.32 1.489 3.32 3.32 0 1.83-1.49 3.32-3.32 3.32z' fill='%23A1B2CF'/%3E%3Cpath d='M17.656 3.79h-2.874a.242.242 0 01-.215-.13l-.795-1.672A1.796 1.796 0 0012.15.976H7.905a1.796 1.796 0 00-1.621 1.012L5.489 3.66a.242.242 0 01-.216.13h-2.93A2.346 2.346 0 000 6.133V16.68a2.346 2.346 0 002.344 2.343h15.312A2.346 2.346 0 0020 16.68V6.133a2.346 2.346 0 00-2.344-2.344zm.782 12.89c0 .43-.351.78-.782.78H2.344a.782.782 0 01-.781-.78V6.133c0-.431.35-.781.78-.781h2.93c.689 0 1.308-.383 1.615-.998l.007-.014.795-1.671a.242.242 0 01.215-.13h4.245c.091 0 .173.05.215.13l.796 1.67.006.014c.308.616.927.999 1.615.999h2.874c.431 0 .782.35.782.78V16.68z' fill='%23A1B2CF'/%3E%3Cpath d='M16.875 6.914h-1.563v1.563h1.563V6.914z' fill='%23A1B2CF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-color: var(--colors-surface-800);
      opacity: 0.7;
      border-radius: 50%;
      cursor: pointer;
      pointer-events: none;
    }
    &.with-hover:hover {
      &:before {
        display: block;
      }
    }
  }
  .profile-logo-drop-zone {
    border: 1px solid transparent;
    border-radius: 50%;
  }
  .profile-logo-drop-zone_reject {
    border: 1px solid var(--colors-error);
  }
  .profile-logo-drop-zone_accept {
    border: 1px solid var(--colors-success-600-p);
  }
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--colors-surface-50);
  border: 1px solid var(--colors-surface-150);
  object-fit: cover;
  display: flex;
  position: relative;
  cursor: pointer;
`;

const DefaultLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--colors-surface-50);
  border: 1px solid var(--colors-surface-150);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 6.133a4.888 4.888 0 00-4.883 4.883A4.888 4.888 0 0010 15.898a4.888 4.888 0 004.883-4.882A4.888 4.888 0 0010 6.133zm0 8.203c-1.83 0-3.32-1.49-3.32-3.32 0-1.831 1.49-3.32 3.32-3.32 1.83 0 3.32 1.489 3.32 3.32 0 1.83-1.49 3.32-3.32 3.32z' fill='%23A1B2CF'/%3E%3Cpath d='M17.656 3.79h-2.874a.242.242 0 01-.215-.13l-.795-1.672A1.796 1.796 0 0012.15.976H7.905a1.796 1.796 0 00-1.621 1.012L5.489 3.66a.242.242 0 01-.216.13h-2.93A2.346 2.346 0 000 6.133V16.68a2.346 2.346 0 002.344 2.343h15.312A2.346 2.346 0 0020 16.68V6.133a2.346 2.346 0 00-2.344-2.344zm.782 12.89c0 .43-.351.78-.782.78H2.344a.782.782 0 01-.781-.78V6.133c0-.431.35-.781.78-.781h2.93c.689 0 1.308-.383 1.615-.998l.007-.014.795-1.671a.242.242 0 01.215-.13h4.245c.091 0 .173.05.215.13l.796 1.67.006.014c.308.616.927.999 1.615.999h2.874c.431 0 .782.35.782.78V16.68z' fill='%23A1B2CF'/%3E%3Cpath d='M16.875 6.914h-1.563v1.563h1.563V6.914z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

// CompanyProfile index

interface CompanyProfileLogoUploaderProps {
  logoId: string;
  onDrop: (file: File) => void;
  file: File | null;
  isLoading?: boolean;
}

const CompanyProfileLogoUploader = ({
  logoId,
  onDrop,
  file,
  isLoading,
}: CompanyProfileLogoUploaderProps): JSX.Element => {
  const cloudinaryTransformation = "f_auto,q_auto/h_170,w_170,c_thumb,fl_progressive.force_strip/";
  let dropzoneClassName = "profile-logo-drop-zone";

  if (isLoading) {
    dropzoneClassName += " loading";
  }

  return (
    <Wrapper className={logoId || file ? "with-hover" : ""}>
      <Dropzone
        multiple={false}
        accept="image/jpeg,image/jpg,image/png,image/gif"
        preventDropOnDocument
        className={dropzoneClassName}
        activeClassName="profile-logo-drop-zone_active"
        acceptClassName="profile-logo-drop-zone_accept"
        rejectClassName="profile-logo-drop-zone_reject"
        onDrop={(droppedFiles: File[]): void => {
          onDrop(droppedFiles[0]);
        }}
      >
        {!logoId && !file ? (
          <DefaultLogo />
        ) : (
          <Logo alt="" src={file ? URL.createObjectURL(file) : getImgUrl(logoId, cloudinaryTransformation)} />
        )}
      </Dropzone>
    </Wrapper>
  );
};

export default CompanyProfileLogoUploader;
