import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import BEM from "utils/BEM";
import { translateEmployeeTerm } from "utils/translationHelpers";
import InviteEmployees from "./InviteEmployees";
import "components/UI/Page/Page.scss";

const p = BEM.b("page");

const InviteEmployeesFullPage = () => {
  const { t } = useTranslation(TranslationNamespaces.employeesPage);

  return (
    <div className={p()}>
      <div className={p("header")}>
        <div className={p("title")}>
          {translateEmployeeTerm(
            t,
            TranslationNamespaces.employeesPage,
            "custom-invite-your-employees",
            `Invite your employees`,
          )}
        </div>
      </div>
      <div className={p("content")}>
        <InviteEmployees />
      </div>
    </div>
  );
};

export default InviteEmployeesFullPage;
