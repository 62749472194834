import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import { fireEvent, hrsMinstoMins, minsToHrsMins } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import {
  createActivities,
  getActivitiesList,
  removeActivity,
  updateAtivity,
} from "components/Projects/projectsApiUtils";
import { ActivityStatuses } from "types/models/activity";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";
import { RequestUserProfile } from "types/models/userProfile";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces, TFunction } from "types/translationNamespaces";
import {
  Activity,
  ActivityInCreatePayload,
  CompanyTimesheetSettings,
  UserProfileTask,
} from "components/Projects/projectsApiTypes";
import { GlobalStoreCompany } from "types/models/company";
import ga from "utils/ga";
import TimesheetTimeInput from "./TimesheetTimeInput";
import { upsertTimeForTaskOnDate } from "../timesheet-api.service";
import MultipleTasksLightbox from "./MultipleTasksLightbox";
import { TaskEntry } from "./MultipleTasksLightbox/MultipleTasksLightbox";
import ActivityEditTimesheetWrapper from "./ActivityEditTimesheetWrapper";

const Wrapper = styled.div`
  .time-control {
    margin-top: 0;
    height: 30px;
    font-weight: var(--typography-font-weight-default);
    font-size: var(--typography-font-size-default);
    line-height: 18px;
    padding-inline-start: 10px;
    color: var(--colors-surface-900-p);
    border-color: var(--colors-surface-150);

    &.grayed-out {
      color: var(--colors-surface-600);
    }
    &:focus {
      border: 1px solid var(--colors-primary-500-p);
    }
    &:focus-visible {
      outline: none;
    }
    &.not-editable {
      border-color: transparent;
      background-color: transparent;
      font-weight: var(--typography-font-weight-medium);
      color: var(--colors-surface-600);
    }
  }
`;

const LightboxContainer = styled.div`
  position: absolute;
  z-index: 2;
  cursor: default;
`;

type Props = {
  editable: boolean;
  placeholder: string;
  value: number;
  date: string;
  taskUuid: string;
  taskName: string;
  projectName: string;
  projectUuid: string;
  userProfile: Pick<RequestUserProfile, "uuid" | "id" | "fullName">;
  tasksList: UserProfileTask[];
  isDayPlanned: boolean;
  onCellTimeChange: (value: number) => void;
  forceRefetchTable: () => void;
  settings?: CompanyTimesheetSettings;
};

export type ActivityExtendedItem = {
  activity: Activity;
  uuid: string;
  status: ActivityStatuses;
  locationUuid: string;
  startTime: string;
  endTime: string;
  date?: string;
};

const isActivityChanged = (activity: TaskEntry, activities: ActivityExtendedItem[]) => {
  const foundActivity = activities.find((a) => a.uuid === activity.uuid);
  return (
    !!foundActivity &&
    (foundActivity.startTime !== activity.startTime ||
      foundActivity.endTime !== activity.endTime ||
      moment(foundActivity.date, "YYYY-MM-DD") !== moment(activity.date, "YYYY-MM-DD"))
  );
};

type OnTimeChangeProps = {
  changedValue: { value: string; error: boolean };
  previousValue: number;
  userProfileUuid: string;
  taskUuid: string;
  date: string;
  setIsPending: (val: boolean) => void;
  onCellTimeChange: (val: number) => void;
  locationUuid?: string;
  t: TFunction;
};

const onTimeChange = async ({
  changedValue,
  previousValue,
  userProfileUuid,
  taskUuid,
  date,
  setIsPending,
  onCellTimeChange,
  locationUuid,
  t,
}: OnTimeChangeProps) => {
  const valueInMinutes = hrsMinstoMins(changedValue.value);

  if (!changedValue.error && valueInMinutes !== previousValue) {
    setIsPending(true);
    try {
      const resp = await upsertTimeForTaskOnDate({
        companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
        userProfileUuid,
        body: {
          taskUuid,
          date,
          activitiesMinutes: valueInMinutes,
          updatedBy: window.global_store.profile.uuid,
          locationUuid,
        },
      });
      if (valueInMinutes !== 0 && !resp?.content?.days[0]?.activitiesMinutesByTasks[taskUuid]) {
        fireEvent("timesheet_message", { uuid: uuidv4(), message: t("Time update failed"), status: "error" });
      } else {
        const updatedMinutes = resp.content.days[0]?.activitiesMinutesByTasks[taskUuid]?.activitiesMinutes || 0;
        if (updatedMinutes !== valueInMinutes) {
          onCellTimeChange(updatedMinutes);
          fireEvent("timesheet_message", {
            uuid: uuidv4(),
            message: t("Time updated to different time"),
            status: "warning",
          });
        } else {
          onCellTimeChange(updatedMinutes);
          fireEvent("timesheet_message", {
            uuid: uuidv4(),
            message: t("Time updated successfully"),
            status: "success",
          });
        }
      }
    } catch (error) {
      fireEvent("timesheet_message", { uuid: uuidv4(), message: t("Time update failed"), status: "error" });
    } finally {
      setIsPending(false);
    }
  }
};

type OnMultipleActivitiesSaveProps = {
  entries: TaskEntry[];
  activities: ActivityExtendedItem[];
  date: string;
  userProfileUuid: string;
  taskUuid: string;
  projectUuid: string;
  setSelectedActivity: (selectedActivity: Activity | null) => void;
  setLightboxOpened: (isOpened: boolean) => void;
  forceRefetchTable: () => void;
  onCellTimeChange: (value: number) => void;
  t: TFunction;
  locationsUuids?: string[];
};

const onMultipleActivitiesSave = async (props: OnMultipleActivitiesSaveProps): Promise<void> => {
  const {
    entries,
    activities,
    date,
    userProfileUuid,
    taskUuid,
    projectUuid,
    setSelectedActivity,
    setLightboxOpened,
    forceRefetchTable,
    t,
    onCellTimeChange,
    locationsUuids,
  } = props;
  const shouldUpdateTable = false;

  const locationUuid =
    (activities?.length && activities[activities.length - 1].locationUuid) ||
    (locationsUuids?.length && locationsUuids[0]) ||
    (window.global_store.company as GlobalStoreCompany).default_location.uuid;

  const activitiesToSave: ActivityInCreatePayload[] = [];
  entries.forEach((entry) => {
    switch (entry.status) {
      case "new" as ActivityStatuses:
        if (hrsMinstoMins(entry.startTime) !== hrsMinstoMins(entry.endTime) && hrsMinstoMins(entry.endTime) !== 0) {
          activitiesToSave.push({
            date,
            startTime: hrsMinstoMins(entry.startTime),
            endTime: hrsMinstoMins(entry.endTime),
            userProfileUuid,
            locationUuid,
            taskUuid,
            projectUuid,
          });
        }
        break;
      case ActivityStatuses.pending:
        if (
          hrsMinstoMins(entry.startTime) !== hrsMinstoMins(entry.endTime) &&
          hrsMinstoMins(entry.endTime) !== 0 &&
          isActivityChanged(entry, activities)
        ) {
          // update pending activity if time changed and not 0
          activitiesToSave.push({
            uuid: entry.uuid,
            date,
            startTime: hrsMinstoMins(entry.startTime),
            endTime: hrsMinstoMins(entry.endTime),
            userProfileUuid,
            locationUuid,
            taskUuid,
            projectUuid,
          });
        } else if (
          hrsMinstoMins(entry.startTime) === hrsMinstoMins(entry.endTime) &&
          hrsMinstoMins(entry.endTime) === 0
        ) {
          // decline pending activity if time changed to 0
          activitiesToSave.push({
            uuid: entry.uuid,
            status: ActivityStatuses.declined,
          } as ActivityInCreatePayload);
        }
        break;
      default:
        break;
    }
  });

  if (activitiesToSave.length) {
    try {
      await createActivities({
        companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
        body: {
          content: {
            activities: activitiesToSave,
            createdBy: window.global_store.profile.uuid,
          },
        },
      });
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message: t("Activities saved successfully"),
        status: "success",
      });

      const updatedMinutes = activitiesToSave.reduce(
        (res: number, activity: { startTime: number; endTime: number }) =>
          res + (activity.endTime - activity.startTime),
        0,
      );
      onCellTimeChange(updatedMinutes);

      ga.trackTimesheetUpdateCellTime();
    } catch (error) {
      fireEvent("timesheet_message", {
        uuid: uuidv4(),
        message: t("Activities save failed"),
        status: "error",
      });
    }
    setSelectedActivity(null);
    setLightboxOpened(false);
    if (shouldUpdateTable) {
      forceRefetchTable();
    }
  }
};

type FetchActivitiesProps = {
  setIsPending: (val: boolean) => void;
  setActivities: (val: ActivityExtendedItem[]) => void;
  userProfileUuid: string;
  date: string;
  taskUuid: string;
};

async function fetchActivities(props: FetchActivitiesProps): Promise<void> {
  const { setIsPending, setActivities, userProfileUuid, date, taskUuid } = props;

  setIsPending(true);
  const { content } = await getActivitiesList({
    employeeUuid: userProfileUuid,
    from: date,
    to: date,
    status: "pending,approved",
    requestedBy: window.global_store.profile.uuid,
    companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
    taskUuid,
  });
  setActivities(
    content.map((activity) => ({
      activity,
      uuid: activity.uuid,
      status: activity.status,
      locationUuid: activity.locationUuid,
      startTime: minsToHrsMins(activity.startTime),
      endTime: minsToHrsMins(activity.endTime),
    })),
  );
  setIsPending(false);
}

export default function ActivityCell(props: Props) {
  const {
    editable,
    value,
    placeholder,
    date,
    taskUuid,
    taskName,
    projectName,
    projectUuid,
    userProfile,
    onCellTimeChange,
    forceRefetchTable,
    tasksList,
    isDayPlanned,
    settings,
  } = props;

  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const [isPending, setIsPending] = useState(false);
  const [isLightboxOpened, setLightboxOpened] = useState(false);
  const [activities, setActivities] = useState<ActivityExtendedItem[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
  return (
    <Wrapper>
      <TimesheetTimeInput
        placeholder={placeholder}
        value={value}
        disabled={isPending}
        grayedOut={!isDayPlanned}
        notEditable={!editable}
        onButtonClick={async () => {
          await fetchActivities({
            setActivities,
            setIsPending,
            userProfileUuid: userProfile.uuid,
            date,
            taskUuid,
          });
          setLightboxOpened(true);
        }}
        onTimeChange={(val: { value: string; error: boolean }) => {
          if (value) {
            ga.trackTimesheetUpdateCellTime();
          }

          void onTimeChange({
            changedValue: val,
            previousValue: value,
            userProfileUuid: userProfile.uuid,
            taskUuid,
            date,
            setIsPending,
            onCellTimeChange,
            locationUuid: props?.tasksList.find((i) => i.uuid === taskUuid)?.project?.locationUuids[0],
            t,
          });
        }}
      />
      {isLightboxOpened && (
        <ClickOutside<ClickOutsideType>
          onClickOutside={() => {
            setSelectedActivity(null);
            setLightboxOpened(false);
          }}
        >
          <LightboxContainer>
            {!selectedActivity ? (
              <MultipleTasksLightbox
                task={{
                  name: taskName,
                  project: projectName,
                  uuid: taskUuid,
                  date: moment(date, "YYYY-MM-DD"),
                  entries: activities,
                }}
                onSave={(entries: TaskEntry[]) =>
                  onMultipleActivitiesSave({
                    entries,
                    activities,
                    date,
                    userProfileUuid: userProfile.uuid,
                    taskUuid,
                    projectUuid,
                    setSelectedActivity,
                    setLightboxOpened,
                    forceRefetchTable,
                    t,
                    onCellTimeChange,
                    locationsUuids: props?.tasksList.find((i) => i.uuid === taskUuid)?.project?.locationUuids,
                  })
                }
                onCancel={(): void => {
                  setSelectedActivity(null);
                  setLightboxOpened(false);
                }}
                onOpenDetails={(activity): void => {
                  setSelectedActivity(activity);
                }}
                settings={settings}
                userProfileUuid={userProfile.uuid}
              />
            ) : (
              <ActivityEditTimesheetWrapper
                tasksList={tasksList}
                userProfile={userProfile}
                activity={selectedActivity}
                onBackClick={() => setSelectedActivity(null)}
                onUpdateActivity={async (act) => {
                  try {
                    await updateAtivity({
                      body: {
                        content: {
                          taskUuid: act.taskUuid,
                          projectUuid: act.projectUuid,
                          startTime: act.startTime,
                          endTime: act.endTime,
                          date: act.date,
                          customFields: act.customFields,
                          // ...update,
                          updatedBy: window.global_store.profile.uuid,
                        },
                      },
                      updatedBy: window.global_store.profile.uuid,
                      activityUuid: act.uuid,
                      companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
                    });
                    fireEvent("timesheet_message", {
                      uuid: uuidv4(),
                      message: t("Activity saved"),
                      status: "success",
                    });
                    setSelectedActivity(null);
                    setLightboxOpened(false);
                    forceRefetchTable();
                  } catch (error) {
                    fireEvent("timesheet_message", {
                      uuid: uuidv4(),
                      message: t("Saving activity failed"),
                      status: "error",
                    });
                  }
                }}
                onDeleteActivity={async ({ uuid }: { uuid: string }) => {
                  try {
                    await removeActivity({
                      body: {
                        content: {
                          updatedBy: window.global_store.profile.uuid,
                        },
                      },
                      companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
                      activityUuid: uuid,
                    });
                    fireEvent("timesheet_message", {
                      uuid: uuidv4(),
                      message: t("Activity canceled"),
                      status: "success",
                    });
                    setSelectedActivity(null);
                    setLightboxOpened(false);
                    forceRefetchTable();
                  } catch (error) {
                    fireEvent("timesheet_message", {
                      uuid: uuidv4(),
                      message: t("Deleting activity failed"),
                      status: "error",
                    });
                  }
                }}
              />
            )}
          </LightboxContainer>
        </ClickOutside>
      )}
    </Wrapper>
  );
}
