import { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import { ActivityStatuses } from "types/models/activity";

export function getActivityStatusBadgeState(activityStatus: ActivityStatuses): StatusBadgeStatuses {
  let status = activityStatus.toString();

  if (activityStatus === ActivityStatuses.submitted) {
    status = StatusBadgeStatuses.pending;
  } else if (activityStatus === ActivityStatuses.pending) {
    status = StatusBadgeStatuses.new;
  }

  return status as StatusBadgeStatuses;
}

/**
 * Map of activity statuses to UI state
 * When timesheet is enabled
 */
export const activityStatuBadgeType: Record<ActivityStatuses, StatusBadgeStatuses> = {
  [ActivityStatuses.pending]: StatusBadgeStatuses.new,
  [ActivityStatuses.submitted]: StatusBadgeStatuses.pending,
  [ActivityStatuses.approved]: StatusBadgeStatuses.approved,
  [ActivityStatuses.declined]: StatusBadgeStatuses.declined,
  [ActivityStatuses.deleted]: StatusBadgeStatuses.declined,
};

/**
 * Map of activity statuses to UI state
 * When timesheet is disabled
 */
export const activityStatuBadgeTypeOld: Record<ActivityStatuses, StatusBadgeStatuses> = {
  [ActivityStatuses.pending]: StatusBadgeStatuses.pending,
  [ActivityStatuses.submitted]: StatusBadgeStatuses.pending,
  [ActivityStatuses.approved]: StatusBadgeStatuses.approved,
  [ActivityStatuses.declined]: StatusBadgeStatuses.declined,
  [ActivityStatuses.deleted]: StatusBadgeStatuses.declined,
};

export function getActivityStatuBadgeType(activityStatus: ActivityStatuses, isTimesheetEnabled: boolean) {
  if (isTimesheetEnabled) {
    return activityStatuBadgeType[activityStatus];
  }
  return activityStatuBadgeTypeOld[activityStatus];
}

/**
 * Map of activity statuses to display label
 * When timehseet is enabled
 */
export const activityStatuBadgeLabel: Record<ActivityStatuses, string> = {
  [ActivityStatuses.pending]: "New",
  [ActivityStatuses.submitted]: "Submitted",
  [ActivityStatuses.approved]: "Approved",
  [ActivityStatuses.declined]: "Denied",
  [ActivityStatuses.deleted]: "Deleted",
};

/**
 * Map of activity statuses to display label
 * When timehseet is disabled
 */
export const activityStatuBadgeLabelOld: Record<ActivityStatuses, string> = {
  [ActivityStatuses.pending]: "Pending",
  [ActivityStatuses.submitted]: "Pending",
  [ActivityStatuses.approved]: "Approved",
  [ActivityStatuses.declined]: "Denied",
  [ActivityStatuses.deleted]: "Deleted",
};

export function getActivityStatusBadgeLabel(activityStatus: ActivityStatuses, isTimesheetEnabled: boolean) {
  if (isTimesheetEnabled) {
    return activityStatuBadgeLabel[activityStatus];
  }
  return activityStatuBadgeLabelOld[activityStatus];
}

export function isDateInTimesheet(timesheetRanges: DateRange[], activitiesDate: Moment) {
  let dateInTimesheet = false;
  if (timesheetRanges?.length && activitiesDate) {
    timesheetRanges.forEach((range) => {
      if (!dateInTimesheet) {
        dateInTimesheet = activitiesDate.within(range);
      }
    });
  }
  return dateInTimesheet;
}
