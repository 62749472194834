import { Component, Fragment, ChangeEvent, ContextType } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import moment from "moment";
import MaskedInput from "react-text-mask";
import BEM from "utils/BEM";
import FullPage from "components/Layout/FullPage";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/StyledTextInput";
import NotificationRow from "components/NotificationRow";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import AddRemoveLink from "components/controls/AddRemoveLink";
import FieldTabs from "components/controls/FieldTabs";
import SelectControl from "components/UI/SelectControl";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import { ErrorLabel } from "components/UI/TextLabels";
import GlobalContext from "context/global-context";
import {
  createPayrollLayout,
  getPayrollEventTypes,
  getPayrollProviders,
  updatePayrollLayout,
  getPayrollLayout,
  deletePayrollLayout,
} from "utils/apiHelpers";
import {
  GetPayrollEventTypesResponseData,
  GetPayrollLayoutResponseData,
  GetPayrollProvidersResponseData,
  PayrollLayoutPayload,
} from "utils/api/types";
import { APIResponse } from "utils/api/common";
import {
  PayrollProvider,
  PayrollEvent,
  PayrollProviderTemplate,
  FilenameConfigurationType,
  FilenameConfiguration,
  PayrollEventType,
  PayrollLayoutFull,
  ERPPayrollEventType,
  InfotypeOperation,
  RunType,
} from "types/models/payroll/payrollLayout";
import { SelectOption } from "types/ui";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import CheckboxControl from "components/UI/NewCheckbox";
import { isEmailValid, showSnackbar } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import GroupedDropdown from "components/Payroll/GroupedDropdown";
import { TwoFieldsWrapper } from "components/Reports/styled";
import {
  PageWrapper,
  MainFields,
  FieldHalfWidth,
  Subsection,
  Subtitle,
  IdFieldWrapper,
  DateFieldWrapper,
  RemoveOption,
  AddRemoveLinkWrapper,
  ButtonWrapper,
  DeleteLink,
  HoursSeparator,
  MultiFieldWrapper,
  EventTypeFieldWrapper,
} from "./styled";
import CreatePayrollFilenameConfig from "./CreatePayrollFilenameConfig";
import "components/UI/Page/Page.scss";
import { GroupPrefixes } from "./GroupedDropdown/helpers";

const p = BEM.b("page");
const input = BEM.b("input");

const getElementFromArray = (
  array: PayrollProviderTemplate[],
  elementName: string,
): PayrollProviderTemplate | undefined => array.find((f) => f.name === elementName || f.type === elementName);

interface EventType {
  id: number;
  eventCode: string;
  payrollEventTypeUuid: string;
  eventType?: ERPPayrollEventType;
}

interface FieldLimits {
  minLength?: number;
  maxLength?: number;
  format?: string;
}
interface FieldsWithLimits {
  [index: string]: FieldLimits;
}

interface PayrollProviderFields {
  [index: string]: FieldsWithLimits;
}

interface EventsLimitsMap {
  [index: string]: number;
}

interface CreateLayoutProps extends WithTranslation, RouteComponentProps {
  id?: string;
}

type Errors = (Record<string, string> & { events?: { id: string | number; error: string }[] }) | null;

interface CreateLayoutState {
  fieldsByPayrollProvider: PayrollProviderFields;
  isLoading: boolean;
  name: string;
  confirmationPopupVisible: boolean;
  errors: Errors;
  payrollProviderUuid: string;
  companyCode: string | null;
  payrollDate: moment.Moment;
  competence: string;
  subsidiaryCode: string;
  clientCode: string;
  companyName: string;
  contactName: string | null;
  contactEmail: string | null;
  adpUserName: string | null;
  nameOfCustomer: string | null;
  sequentialNumberItRecord: string | null;
  infotypeOperation: InfotypeOperation | null;
  runType: RunType | null;
  payrollCode: string;
  userLoggedInADP: string;
  payrollCompanyID: string;
  userRequisitionReturn: string;
  mescom: string;
  hoursFormat: string;
  hoursSeparator: string;
  hoursSeparatorChecked: boolean;
  convertValuesToAbs: boolean;
  notification: string;
  notificationType: NotificationType;
  eventCodesMinLength: EventsLimitsMap;
  eventCodeMaxLength: EventsLimitsMap;
  events: EventType[];
  providersOptions: SelectOption[];
  filenameConfigurationType: FilenameConfigurationType | null | undefined;
  filenameConfiguration: FilenameConfiguration | null | undefined;
  rawEventTypes: PayrollEventType[];
}
type ErrorEvent = { id: string | number; error: string };

class CreateLayout extends Component<CreateLayoutProps, CreateLayoutState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: CreateLayoutProps) {
    super(props);
    const { t } = props;

    this.state = {
      errors: null,
      isLoading: false,
      payrollProviderUuid: "",
      confirmationPopupVisible: false,
      name: "",
      companyCode: "",
      payrollDate: moment(),
      competence: "",
      subsidiaryCode: "",
      clientCode: "",
      companyName: "",
      contactName: null,
      contactEmail: null,
      adpUserName: null,
      nameOfCustomer: null,
      sequentialNumberItRecord: null,
      runType: RunType.testing,
      payrollCode: "",
      userLoggedInADP: "",
      payrollCompanyID: "",
      userRequisitionReturn: "",
      mescom: "",
      fieldsByPayrollProvider: {},
      eventCodesMinLength: {},
      eventCodeMaxLength: {},
      events: [],
      notification: "",
      notificationType: NotificationType.success,
      hoursFormat: "sexagesimal",
      hoursSeparator: "",
      hoursSeparatorChecked: false,
      convertValuesToAbs: false,
      providersOptions: [],
      filenameConfigurationType: undefined,
      filenameConfiguration: undefined,
      rawEventTypes: [],
    };

    document.title = t("Payroll");
  }

  componentDidMount(): void {
    void this.getState();
  }

  updateAvailableFields(
    payrollProviderFields: FieldsWithLimits,
    fieldName: string,
    fieldValue: FieldLimits,
  ): FieldsWithLimits {
    let newFields = payrollProviderFields;

    if (newFields) {
      newFields[fieldName] = fieldValue;
    } else {
      newFields = {};
      newFields[fieldName] = fieldValue;
    }

    return newFields;
  }

  async getState(): Promise<void> {
    const { t } = this.props;
    const company = await this.context.getCompany();
    type Responses = [
      APIResponse<GetPayrollProvidersResponseData>,
      APIResponse<GetPayrollEventTypesResponseData>,
      APIResponse<GetPayrollLayoutResponseData>?,
    ];

    if (company) {
      const promises: Responses = [getPayrollProviders(), getPayrollEventTypes({ companyUUID: company.uuid })];

      if (this.props.id && this.props.id !== "create-layout") {
        promises.push(getPayrollLayout({ payrollUUID: this.props.id }));
      }
      let resp = null;
      try {
        resp = await Promise.all(promises);
      } catch (error) {
        sentryUtils.sendError(error);
        showSnackbar({ text: t("Failed to get payroll layout") });
      }

      const rawEventTypes = resp?.[1]?.content || [];
      const fieldsByPayrollProvider: PayrollProviderFields = {};
      const eventCodesMinLength: EventsLimitsMap = {};
      const eventCodeMaxLength: EventsLimitsMap = {};
      let providersOptions: SelectOption[] = [];
      let defaultPayrollProviderUuid = "";
      let events: EventType[] = [];

      if (resp?.[0]?.content?.length) {
        const providers = resp[0].content as PayrollProvider[];

        providers.forEach((pr: PayrollProvider) => {
          if (pr?.configFields?.headerTemplate?.length) {
            const configFields = ["contactName", "contactEmail", "adpUserName", "nameOfCustomer", "runType"];
            configFields.forEach((fieldName) => {
              const customField = getElementFromArray(pr.configFields.headerTemplate, fieldName);

              if (customField) {
                const fieldLimits: FieldLimits = this.getConfigFieldMinMaxLength(customField);

                if (customField.format) {
                  fieldLimits.format = customField.format;
                }

                fieldsByPayrollProvider[pr.uuid] = this.updateAvailableFields(
                  fieldsByPayrollProvider[pr.uuid],
                  fieldName,
                  fieldLimits,
                );
              }
            });
          }

          if (pr?.configFields?.template?.length) {
            const configFieldsWithoutLimits = ["payrollDate", "competence", "userLoggedInADP", "userRequisitionReturn"];

            configFieldsWithoutLimits.forEach((fieldName) => {
              const field = getElementFromArray(pr.configFields.template, fieldName);

              if (field) {
                fieldsByPayrollProvider[pr.uuid] = this.updateAvailableFields(
                  fieldsByPayrollProvider[pr.uuid],
                  fieldName,
                  {},
                );
              }
            });

            const configFieldsWithLimits = [
              "subsidiaryCode",
              "clientCode",
              "companyName",
              "companyCode",
              "payrollCode",
              "payrollCompanyID",
              "mescom",
              "competence",
              "contactName",
              "contactEmail",
              "adpUserName",
              "nameOfCustomer",
              "sequentialNumberItRecord",
              "infotypeOperation",
            ];

            configFieldsWithLimits.forEach((fieldName) => {
              const customField: PayrollProviderTemplate | undefined = getElementFromArray(
                pr.configFields.template,
                fieldName,
              );

              if (customField) {
                const fieldLimits: FieldLimits = this.getConfigFieldMinMaxLength(customField);

                if (customField.format) {
                  fieldLimits.format = customField.format;
                }

                fieldsByPayrollProvider[pr.uuid] = this.updateAvailableFields(
                  fieldsByPayrollProvider[pr.uuid],
                  fieldName,
                  fieldLimits,
                );
              }
            });

            const eventCodeField: PayrollProviderTemplate | undefined = getElementFromArray(
              pr.configFields.template,
              "eventCode",
            );

            if (eventCodeField) {
              eventCodesMinLength[pr.uuid] = eventCodeField.minlength || 0;
              eventCodeMaxLength[pr.uuid] = eventCodeField.maxlength || 0;
            }
          }
        });

        providersOptions = providers
          .map((pr: PayrollProvider) => ({ value: pr.uuid, label: pr.name }))
          .sort((a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label));
        defaultPayrollProviderUuid = providers[0].uuid;
      }

      if (rawEventTypes.length) {
        if (!this.props.id || this.props.id === "create-layout") {
          events = [
            {
              id: 0,
              eventCode: "",
              payrollEventTypeUuid: rawEventTypes.length ? rawEventTypes[0].uuid : "",
            },
          ];
        }
      }

      if (this.props.id && this.props.id !== "create-layout" && resp?.[2]?.content) {
        const {
          name,
          companyCode,
          hoursFormat,
          hoursSeparator,
          payrollProviderUuid,
          payrollEvents,
          payrollDate,
          competence,
          subsidiaryCode,
          userLoggedInADP,
          payrollCompanyID,
          userRequisitionReturn,
          mescom,
          clientCode,
          companyName,
          contactName,
          contactEmail,
          adpUserName,
          nameOfCustomer,
          sequentialNumberItRecord,
          infotypeOperation,
          payrollCode,
          filenameTemplateConfig,
          convertValuesToAbs,
          extraOptions,
        } = resp[2].content as PayrollLayoutFull;

        const filenameConfigurationType = (Object.keys(filenameTemplateConfig)[0] as FilenameConfigurationType) || null;

        this.setState({
          providersOptions,
          fieldsByPayrollProvider,
          eventCodesMinLength,
          eventCodeMaxLength,
          name,
          companyCode,
          competence: competence === null ? "" : competence,
          subsidiaryCode: subsidiaryCode === null ? "" : subsidiaryCode,
          userLoggedInADP: userLoggedInADP === null ? "" : userLoggedInADP,
          payrollCompanyID: payrollCompanyID || "",
          userRequisitionReturn: userRequisitionReturn === null ? "" : userRequisitionReturn,
          mescom: mescom === null ? "" : mescom,
          clientCode: clientCode === null ? "" : clientCode,
          companyName: companyName === null ? "" : companyName,
          contactName: !contactName ? "" : contactName,
          contactEmail: !contactEmail ? "" : contactEmail,
          adpUserName: !adpUserName ? "" : adpUserName,
          nameOfCustomer: !nameOfCustomer ? "" : nameOfCustomer,
          sequentialNumberItRecord: !sequentialNumberItRecord ? "" : sequentialNumberItRecord,
          infotypeOperation: !infotypeOperation ? InfotypeOperation.INS : infotypeOperation,
          runType: !extraOptions?.runtype ? RunType.testing : extraOptions.runtype,
          payrollCode: payrollCode === null ? "" : payrollCode,
          payrollDate: payrollDate ? moment(payrollDate) : moment(),
          hoursFormat,
          hoursSeparator,
          hoursSeparatorChecked: !!(hoursSeparator && hoursSeparator !== ""),
          convertValuesToAbs,
          payrollProviderUuid,
          rawEventTypes,
          events: payrollEvents
            ? payrollEvents.map((ev: PayrollEvent, i: number) => ({
                id: i,
                payrollEventTypeUuid: this.getEventWithExtraOptions(ev),
                eventCode: ev.eventCode,
                eventType: ev.eventType,
              }))
            : [],
          filenameConfigurationType,
          filenameConfiguration: filenameConfigurationType ? filenameTemplateConfig?.[filenameConfigurationType] : null,
        });
      } else {
        this.setState({
          rawEventTypes,
          events,
          providersOptions,
          fieldsByPayrollProvider,
          payrollProviderUuid: defaultPayrollProviderUuid,
          eventCodesMinLength,
          eventCodeMaxLength,
          filenameConfigurationType: null,
          filenameConfiguration: null,
        });
      }
    }
  }

  getConfigFieldMinMaxLength = (customField: PayrollProviderTemplate): { minLength: number; maxLength: number } => {
    let minLength = customField.minlength || 0;
    let maxLength = customField.maxlength || 0;

    if (!minLength && !maxLength && customField.length) {
      minLength = customField.length;
      maxLength = customField.length;
    }

    return { minLength, maxLength };
  };

  getEventWithExtraOptions = (event: PayrollEvent): string => {
    if (event?.extraOptions?.eventTypeName === GroupPrefixes.cross_shifts_interval_phases) {
      return `${event.extraOptions.eventTypeName}[${event.extraOptions.limit}_${event.extraOptions.name}]`;
    }
    if (event?.extraOptions?.key === GroupPrefixes.extra_hours_phases_from_punches) {
      return `${GroupPrefixes.extra_hours_phases_from_punches}[${event.extraOptions.phaseKey}]`;
    }
    if (event?.extraOptions?.key === GroupPrefixes.extra_hours_phases_from_expired_hours_bank) {
      return `${GroupPrefixes.extra_hours_phases_from_expired_hours_bank}[${event.extraOptions.phaseKey}]`;
    }
    if (event?.extraOptions?.phaseKey) {
      if (event?.extraOptions?.payPolicyType === "hours_bank") {
        return `${GroupPrefixes.hours_bank_phases}[${event.extraOptions.phaseKey}]`;
      }
      return `${GroupPrefixes.extra_hours_phases}[${event.extraOptions.phaseKey}]`;
    }
    if (event?.extraOptions?.nightShiftName) {
      return `${GroupPrefixes.night_shift_names}[${event.extraOptions.nightShiftName}]`;
    }
    if (event?.extraOptions?.nightShiftOvertimeName) {
      return `${GroupPrefixes.nightShiftOvertimeName}[${event.extraOptions.nightShiftOvertimeName}]`;
    }
    if (event?.extraOptions?.nightShiftWithoutOvertimeName) {
      return `${GroupPrefixes.nightShiftWithoutOvertimeName}[${event.extraOptions.nightShiftWithoutOvertimeName}]`;
    }
    if (event?.extraOptions?.hoursDistributionName) {
      return `${GroupPrefixes.hours_distribution}[${event.extraOptions.hoursDistributionName}]`;
    }
    if (event?.extraOptions?.serviceUuid) {
      return `${GroupPrefixes.projects_services}[${event.extraOptions.serviceUuid}]`;
    }
    if (event?.extraOptions?.customId === "total_working_hours_excluding_projects_services") {
      return "total_working_hours_excluding_projects_services";
    }
    if (event?.extraOptions?.requestSubtypeUuid) {
      const key = event?.extraOptions?.key || "request_subtypes";
      return `${key}[${event?.extraOptions?.requestSubtypeUuid}]`;
    }
    if (event?.extraOptions?.requestTypeUuid) {
      const key = event?.extraOptions?.key || "request_types";
      return `${key}[${event?.extraOptions?.requestTypeUuid}]`;
    }
    if (event?.extraOptions?.breakTypeUuid) {
      const key = event?.extraOptions?.key || "break_types";
      return `${key}[${event?.extraOptions?.breakTypeUuid}]`;
    }

    return event.payrollEventTypeUuid as string;
  };

  onRemovePayrollConfirm = async (): Promise<void> => {
    const { id, history } = this.props;

    await deletePayrollLayout({ payrollUUID: id });
    history.push("/payroll/export");
  };

  setFilenameConfig = (type: FilenameConfigurationType | null, config: FilenameConfiguration) => {
    this.setState({ filenameConfigurationType: type, filenameConfiguration: type && config });
  };

  onSave = async (): Promise<void> => {
    const {
      name,
      companyCode,
      events,
      payrollDate,
      fieldsByPayrollProvider,
      eventCodesMinLength,
      eventCodeMaxLength,
      hoursFormat,
      hoursSeparator,
      convertValuesToAbs,
      payrollProviderUuid,
      competence,
      subsidiaryCode,
      userLoggedInADP,
      payrollCompanyID,
      userRequisitionReturn,
      mescom,
      clientCode,
      companyName,
      contactName,
      contactEmail,
      adpUserName,
      nameOfCustomer,
      sequentialNumberItRecord,
      infotypeOperation,
      runType,
      payrollCode,
      filenameConfigurationType,
      filenameConfiguration,
    } = this.state;

    const { history, t } = this.props;
    const fields = fieldsByPayrollProvider[payrollProviderUuid] || {};
    let errors: Errors = {};

    if (!name) {
      errors.name = t("Name can't be empty");
    } else if (fields.companyCode && !companyCode) {
      errors.companyCode = t("Company code can't be empty");
    } else if (
      fields.companyCode &&
      fields.companyCode.minLength &&
      (companyCode?.length || 0) < fields.companyCode.minLength
    ) {
      errors.companyCode = `${t("Company code length should be")} >= ${fields.companyCode.minLength}`;
    } else if (
      fields.companyCode &&
      fields.companyCode.maxLength &&
      (companyCode?.length || 0) > fields.companyCode.maxLength
    ) {
      errors.companyCode = `${t("Company code length should be")} <= ${fields.companyCode.maxLength}`;
    } else if (fields.payrollDate && !payrollDate) {
      errors.payrollDate = t("Company payroll date can't be empty");
    } else if (fields.competence && !competence) {
      errors.competence = t("Competence date can't be empty");
    } else if (fields.subsidiaryCode && !subsidiaryCode) {
      errors.subsidiaryCode = t("Subsidiary code can't be empty");
    } else if (
      fields.subsidiaryCode &&
      fields.subsidiaryCode.minLength &&
      subsidiaryCode.length < fields.subsidiaryCode.minLength
    ) {
      errors.subsidiaryCode = `${t("Subsidiary code length should be")} >= ${fields.subsidiaryCode.minLength}`;
    } else if (
      fields.subsidiaryCode &&
      fields.subsidiaryCode.maxLength &&
      subsidiaryCode.length > fields.subsidiaryCode.maxLength
    ) {
      errors.subsidiaryCode = `${t("Subsidiary code length should be")} <= ${fields.subsidiaryCode.maxLength}`;
    } else if (fields.userLoggedInADP && !userLoggedInADP) {
      errors.userLoggedInADP = t("usuenv can't be empty");
    } else if (fields.payrollCompanyID && !payrollCompanyID) {
      errors.payrollCompanyID = t("Company identificator can't be empty");
    } else if (fields.payrollCompanyID?.minLength && payrollCompanyID.length < fields.payrollCompanyID.minLength) {
      errors.payrollCompanyID = `${t("Company identificator length should be")} >= ${
        fields.payrollCompanyID.minLength
      }`;
    } else if (fields.payrollCompanyID?.maxLength && payrollCompanyID.length > fields.payrollCompanyID.maxLength) {
      errors.payrollCompanyID = `${t("Company identificator length should be")} <= ${
        fields.payrollCompanyID.maxLength
      }`;
    } else if (fields.userRequisitionReturn && !userRequisitionReturn) {
      errors.userRequisitionReturn = t("usubpo can't be empty");
    } else if (fields.mescom && !mescom) {
      errors.mescom = t("mescom can't be empty");
    } else if (fields.mescom && mescom.length !== (fields.mescom.format as string).length) {
      // moment will successfuly parse "2" with "MM" format. Here we want force user to enter "02" in that case
      errors.mescom = t("Wrong mescom format");
    } else if (fields.mescom && !moment(mescom, fields.mescom.format).isValid()) {
      errors.mescom = t("Wrong mescom format");
    } else if (fields.clientCode && !clientCode) {
      errors.clientCode = t("Client code can't be empty");
    } else if (fields.clientCode?.minLength && clientCode.length < fields.clientCode.minLength) {
      errors.clientCode = `${t("Client code length should be")} >= ${fields.clientCode.minLength}`;
    } else if (fields.clientCode?.maxLength && clientCode.length > fields.clientCode.maxLength) {
      errors.clientCode = `${t("Client code length should be")} <= ${fields.clientCode.maxLength}`;
    } else if (fields.companyName && !companyName) {
      errors.companyName = t("Company name can't be empty");
    } else if (fields.contactName && !contactName) {
      errors.contactName = t("Contact name can't be empty");
    } else if (fields.contactEmail && !contactEmail) {
      errors.contactEmail = t("Contact email can't be empty");
    } else if (fields.contactEmail && contactEmail && !isEmailValid(contactEmail)) {
      errors.contactEmail = t("Email is not valid");
    } else if (fields.adpUserName && !adpUserName) {
      errors.adpUserName = t("ADP user name can't be empty");
    } else if (fields.nameOfCustomer && !nameOfCustomer) {
      errors.nameOfCustomer = t("Customer name can't be empty");
    } else if (fields.sequentialNumberItRecord && !sequentialNumberItRecord) {
      errors.sequentialNumberItRecord = t("Sequential Number of IT Record can't be empty");
    } else if (fields.infotypeOperation && !infotypeOperation) {
      errors.infotypeOperation = t("Infotype operation can't be empty");
    } else if (fields.runType && !runType) {
      errors.runType = t("Run type can't be empty");
    } else if (fields.companyName?.minLength && companyName.length < fields.companyName.minLength) {
      errors.companyName = `${t("Company name length should be")} >= ${fields.companyName.minLength}`;
    } else if (fields.companyName?.maxLength && companyName.length > fields.companyName.maxLength) {
      errors.companyName = `${t("Company name length should be")} <= ${fields.companyName.maxLength}`;
    } else if (fields.payrollCode && !payrollCode) {
      errors.payrollCode = t("Payroll code can't be empty");
    } else if (fields.payrollCode?.minLength && payrollCode.length < fields.payrollCode.minLength) {
      errors.payrollCode = `${t("Payroll code length should be")} >= ${fields.payrollCode.minLength}`;
    } else if (fields.payrollCode?.maxLength && payrollCode.length > fields.payrollCode.maxLength) {
      errors.payrollCode = `${t("Payroll code length should be")} <= ${fields.payrollCode.maxLength}`;
    } else if (events.length && events.some((ev) => !ev.eventCode)) {
      const errorEvents = events
        .filter((ev) => !ev.eventCode)
        .map((ev) => ({ id: ev.id, error: t("Event ID can't be empty") }));

      errors.events = errorEvents;
    } else if (
      events.length &&
      eventCodesMinLength[payrollProviderUuid] &&
      events.some((ev) => ev.eventCode.length < eventCodesMinLength[payrollProviderUuid])
    ) {
      const minLength = eventCodesMinLength[payrollProviderUuid];
      errors.events = events
        .filter((ev) => ev.eventCode.length < minLength)
        .map((ev) => ({
          id: ev.id,
          error: `${t("Event ID length should be")} >= ${minLength}`,
        }));
    } else if (
      events.length &&
      eventCodeMaxLength[payrollProviderUuid] &&
      events.some((ev) => ev.eventCode.length > eventCodeMaxLength[payrollProviderUuid])
    ) {
      const maxLength = eventCodeMaxLength[payrollProviderUuid];
      errors.events = events
        .filter((ev) => ev.eventCode.length > maxLength)
        .map((ev) => ({
          id: ev.id,
          error: `${t("Event ID length should be")} =< ${maxLength}`,
        }));
    } else {
      errors = null;
      this.setState({ isLoading: true });
      const isERPSankhuya = this.isERPSankhuya();

      const body: PayrollLayoutPayload = {
        content: {
          name,
          payrollProviderUuid,
          hoursFormat,
          active: true,
          convertValuesToAbs,
          payrollEvents: events.map((ev) => ({
            eventCode: ev.eventCode,
            payrollEventTypeUuid: ev.payrollEventTypeUuid,
            eventType: isERPSankhuya ? ev.eventType : undefined,
          })),
          filenameTemplateConfig: filenameConfigurationType
            ? { [filenameConfigurationType]: filenameConfiguration }
            : {},
        },
      };

      if (window.global_store.beta) {
        body.content.hoursSeparator = hoursSeparator;
      }

      if (fields.companyCode) {
        body.content.companyCode = companyCode;
      }
      if (fields.clientCode) {
        body.content.clientCode = clientCode;
      }
      if (fields.companyName || fields.contactName || fields.contactEmail || fields.sequentialNumberItRecord) {
        body.content.extraOptions = {};
        if (fields.companyName) {
          body.content.extraOptions.companyName = companyName;
        }
        if (fields.contactEmail) {
          body.content.extraOptions.contactEmail = contactEmail || undefined;
        }
        if (fields.adpUserName) {
          body.content.extraOptions.adpUserName = adpUserName ? adpUserName.toUpperCase() : undefined;
        }
        if (fields.nameOfCustomer) {
          body.content.extraOptions.nameOfCustomer = nameOfCustomer || undefined;
        }
        if (fields.contactName) {
          body.content.extraOptions.contactName = contactName || undefined;
        }
        if (fields.sequentialNumberItRecord) {
          body.content.extraOptions.sequentialNumberItRecord = sequentialNumberItRecord || undefined;
        }
        if (fields.infotypeOperation) {
          body.content.extraOptions.infotypeOperation = infotypeOperation || undefined;
        }
        if (fields.runType) {
          body.content.extraOptions.runtype = runType || undefined;
        }
      }
      if (fields.payrollDate) {
        body.content.payrollDate = payrollDate.format("YYYY-MM-DD");
      }
      if (fields.competence) {
        body.content.competence = competence;
      }
      if (fields.subsidiaryCode) {
        body.content.subsidiaryCode = subsidiaryCode;
      }
      if (fields.userLoggedInADP) {
        body.content.userLoggedInADP = userLoggedInADP;
      }
      if (fields.payrollCompanyID) {
        body.content.payrollCompanyID = payrollCompanyID;
      }
      if (fields.userRequisitionReturn) {
        body.content.userRequisitionReturn = userRequisitionReturn;
      }
      if (fields.mescom) {
        body.content.mescom = mescom;
      }
      if (fields.payrollCode) {
        body.content.payrollCode = payrollCode;
      }

      try {
        if (this.props.id && this.props.id !== "create-layout") {
          await updatePayrollLayout({
            body,
            payrollUUID: this.props.id,
          });

          localStorage.setItem("payrollMessage", `${t("You updated payroll")} ${name}`);
        } else {
          body.createdBy = window.global_store.profile.uuid;

          await createPayrollLayout({
            body,
            companyUUID: window.global_store.company.uuid,
          });

          localStorage.setItem("payrollMessage", `${t("You added payroll")} ${name}`);
        }

        history.push("/payroll/export");
      } catch (err) {
        this.setState(
          {
            isLoading: false,
            notification: t((err as any).errors?.[0]?.message || (err as Error).message),
            notificationType: NotificationType.error,
          },
          () => window.scrollTo(0, 0),
        );
      }
    }

    if (errors) {
      this.setState({ errors });
    }
  };

  getEventPlaceholder = () => {
    const { eventCodesMinLength, payrollProviderUuid } = this.state;

    return [...Array(eventCodesMinLength[payrollProviderUuid])].map(() => Math.floor(Math.random() * 10)).join("");
  };

  // prod-11996
  isERPSankhuya = () => {
    const { providersOptions, payrollProviderUuid } = this.state;
    return providersOptions.find((po) => po.value === payrollProviderUuid)?.label === "ERP Sankhya";
  };

  render(): JSX.Element {
    const {
      name,
      payrollProviderUuid,
      errors,
      companyCode,
      hoursFormat,
      hoursSeparator,
      hoursSeparatorChecked,
      convertValuesToAbs,
      payrollDate,
      competence,
      subsidiaryCode,
      userLoggedInADP,
      payrollCompanyID,
      userRequisitionReturn,
      mescom,
      clientCode,
      companyName,
      contactName,
      contactEmail,
      adpUserName,
      nameOfCustomer,
      sequentialNumberItRecord,
      infotypeOperation,
      runType,
      confirmationPopupVisible,
      events,
      providersOptions,
      fieldsByPayrollProvider,
      isLoading,
      payrollCode,
      notification,
      notificationType,
      filenameConfigurationType,
      filenameConfiguration,
      rawEventTypes,
    } = this.state;
    const { t, history } = this.props;

    const errorEvents: ErrorEvent[] =
      !errors?.events || typeof errors?.events === "string"
        ? []
        : errors.events.map((ev) => ({ id: ev.id, error: ev.error }));

    const fields = fieldsByPayrollProvider[payrollProviderUuid] || {};
    const isERPSankhuya = this.isERPSankhuya();

    return (
      <FullPage
        title={name || t("Create a new layout")}
        backButtonOnclick={() => history.push("/payroll/export")}
        backButtonTitle={t("Layouts")}
      >
        <div className={p()}>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <PageWrapper>
            <MainFields>
              <FieldWrapper fieldName={t("Name")} width="100%">
                <TextInputControl
                  value={name}
                  error={!!errors?.name}
                  size={120}
                  placeholder={t("Layout Name")}
                  onChange={(val: string): void => this.setState({ name: val })}
                />
              </FieldWrapper>
              {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
              <TwoFieldsWrapper>
                <FieldHalfWidth>
                  <FieldWrapper fieldName={t("Provider")} width="100%">
                    <SelectControl<string>
                      isSearchable
                      modifiers={{ field: true }}
                      value={payrollProviderUuid}
                      onChange={(val: string, label: string): void => {
                        let evs;
                        // prod-11996
                        if (label === "ERP Sankhya") {
                          evs = events.map((e) => ({ ...e, eventType: ERPPayrollEventType.P }));
                        } else {
                          evs = events.map((e) => {
                            delete e.eventType;
                            return e;
                          });
                        }

                        this.setState({ payrollProviderUuid: val, events: evs });
                      }}
                      options={providersOptions}
                    />
                  </FieldWrapper>
                </FieldHalfWidth>
                {fields.companyCode && (
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Cod. Company")} width="100%">
                      <TextInputControl
                        value={companyCode || ""}
                        error={!!errors?.companyCode}
                        placeholder="#7464"
                        onChange={(val: string): void => this.setState({ companyCode: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                )}
              </TwoFieldsWrapper>
              {fields.companyCode && errors?.companyCode && <ErrorLabel>{errors.companyCode}</ErrorLabel>}
              {fields.payrollDate && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Payroll Date")} width="100%">
                      <SingleDatePickerControl
                        onChange={(val: moment.Moment): void => {
                          this.setState({ payrollDate: val });
                        }}
                        isOutsideRange={(): boolean => false}
                        value={payrollDate}
                        error={!!errors?.payrollDate}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.payrollDate && errors?.payrollDate && <ErrorLabel>{errors.payrollDate}</ErrorLabel>}
              {fields.competence && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Competence")} width="100%">
                      <MaskedInput
                        className={input({ error: errors && !!errors.competence })}
                        placeholder={fields.competence?.format || "MMYYYY"}
                        value={competence}
                        name="competence"
                        onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                          this.setState({ competence: ev.target.value })
                        }
                        mask={
                          fields.competence?.maxLength
                            ? [...new Array(fields.competence?.maxLength)].map((e) => /\d/)
                            : [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                        }
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.competence && errors?.competence && <ErrorLabel>{errors.competence}</ErrorLabel>}
              {fields.subsidiaryCode && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Subsidiary Code")} width="100%">
                      <TextInputControl
                        value={subsidiaryCode}
                        error={!!errors?.subsidiaryCode}
                        placeholder="#7464"
                        onChange={(val: string): void => this.setState({ subsidiaryCode: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.subsidiaryCode && errors && errors.subsidiaryCode && (
                <ErrorLabel>{errors.subsidiaryCode}</ErrorLabel>
              )}
              {(fields.clientCode || fields.companyName) && (
                <TwoFieldsWrapper>
                  {fields.clientCode && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Client Code")} width="100%">
                        <TextInputControl
                          value={clientCode}
                          error={!!errors?.clientCode}
                          placeholder="#7464"
                          onChange={(val: string): void => this.setState({ clientCode: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                  {fields.companyName && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Company name")} width="100%">
                        <TextInputControl
                          value={companyName}
                          error={!!errors?.companyName}
                          placeholder={t("company-name-placeholder")}
                          onChange={(val: string): void => this.setState({ companyName: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                </TwoFieldsWrapper>
              )}
              {fields.clientCode && errors?.clientCode && <ErrorLabel>{errors.clientCode}</ErrorLabel>}
              {fields.companyName && errors?.companyName && <ErrorLabel>{errors.companyName}</ErrorLabel>}
              {(fields.contactName || fields.contactEmail) && (
                <TwoFieldsWrapper>
                  {fields.contactName && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Contact name")} width="100%">
                        <TextInputControl
                          value={contactName || ""}
                          error={!!errors?.contactName}
                          placeholder={t("contact-name-placeholder")}
                          onChange={(val: string): void => this.setState({ contactName: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                  {fields.contactEmail && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Contact email")} width="100%">
                        <TextInputControl
                          value={contactEmail || ""}
                          error={!!errors?.contactEmail}
                          placeholder={t("contact-email-placeholder")}
                          onChange={(val: string): void => this.setState({ contactEmail: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                </TwoFieldsWrapper>
              )}
              {fields.contactName && errors?.contactName && <ErrorLabel>{errors.contactName}</ErrorLabel>}
              {fields.contactEmail && errors?.contactEmail && <ErrorLabel>{errors.contactEmail}</ErrorLabel>}

              {(fields.adpUserName || fields.nameOfCustomer) && (
                <TwoFieldsWrapper>
                  {fields.adpUserName && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("ADP user name")} width="100%">
                        <TextInputControl
                          value={adpUserName || ""}
                          error={!!errors?.adpUserName}
                          placeholder={t("adp-user-name-placeholder")}
                          onChange={(val: string): void => this.setState({ adpUserName: val?.toUpperCase() || "" })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                  {fields.nameOfCustomer && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Customer name")} width="100%">
                        <TextInputControl
                          value={nameOfCustomer || ""}
                          error={!!errors?.nameOfCustomer}
                          placeholder={t("customer-name-placeholder")}
                          onChange={(val: string): void => this.setState({ nameOfCustomer: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                </TwoFieldsWrapper>
              )}
              {fields.adpUserName && errors?.adpUserName && <ErrorLabel>{errors.adpUserName}</ErrorLabel>}
              {fields.nameOfCustomer && errors?.nameOfCustomer && <ErrorLabel>{errors.nameOfCustomer}</ErrorLabel>}

              {(fields.sequentialNumberItRecord || fields.infotypeOperation) && (
                <TwoFieldsWrapper>
                  {fields.sequentialNumberItRecord && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Sequential Number of IT Record")} width="100%">
                        <TextInputControl
                          value={sequentialNumberItRecord || ""}
                          error={!!errors?.sequentialNumberItRecord}
                          placeholder="000"
                          onChange={(val: string): void => this.setState({ sequentialNumberItRecord: val })}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                  {fields.infotypeOperation && (
                    <FieldHalfWidth>
                      <FieldWrapper fieldName={t("Infotype operation")} width="100%">
                        <SelectControl<InfotypeOperation>
                          isSearchable
                          modifiers={{ field: true }}
                          value={infotypeOperation}
                          onChange={(val: InfotypeOperation): void => {
                            this.setState({ infotypeOperation: val });
                          }}
                          options={Object.values(InfotypeOperation).map((option) => ({
                            label: t(option),
                            value: option,
                          }))}
                        />
                      </FieldWrapper>
                    </FieldHalfWidth>
                  )}
                </TwoFieldsWrapper>
              )}
              {fields.sequentialNumberItRecord && errors?.sequentialNumberItRecord && (
                <ErrorLabel>{errors.sequentialNumberItRecord}</ErrorLabel>
              )}
              {fields.infotypeOperation && errors?.infotypeOperation && (
                <ErrorLabel>{errors.infotypeOperation}</ErrorLabel>
              )}

              {fields.runType && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Run type")} width="100%">
                      <SelectControl<RunType>
                        isSearchable
                        modifiers={{ field: true }}
                        value={runType}
                        onChange={(val: RunType): void => {
                          this.setState({ runType: val });
                        }}
                        options={Object.values(RunType).map((option) => ({
                          label: t(option),
                          value: option,
                        }))}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                </TwoFieldsWrapper>
              )}
              {fields.runType && errors?.runType && <ErrorLabel>{errors.runType}</ErrorLabel>}

              {fields.payrollCode && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Payroll Code")} width="100%">
                      <TextInputControl
                        value={payrollCode}
                        error={!!errors?.payrollCode}
                        placeholder="#7464"
                        onChange={(val: string): void => this.setState({ payrollCode: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.payrollCode && errors?.payrollCode && <ErrorLabel>{errors.payrollCode}</ErrorLabel>}
              {fields.payrollCompanyID && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("Company Identificator")} width="100%">
                      <TextInputControl
                        value={payrollCompanyID}
                        error={!!errors?.payrollCompanyID}
                        onChange={(val: string): void => this.setState({ payrollCompanyID: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.payrollCompanyID && errors?.payrollCompanyID && (
                <ErrorLabel>{errors.payrollCompanyID}</ErrorLabel>
              )}
              {fields.userLoggedInADP && fields.userRequisitionReturn && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("usuenv")} width="100%">
                      <TextInputControl
                        value={userLoggedInADP}
                        error={!!errors?.userLoggedInADP}
                        onChange={(val: string): void => this.setState({ userLoggedInADP: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>

                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("usubpo")} width="100%">
                      <TextInputControl
                        value={userRequisitionReturn}
                        error={!!errors?.userRequisitionReturn}
                        onChange={(val: string): void => this.setState({ userRequisitionReturn: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                </TwoFieldsWrapper>
              )}
              {fields.userLoggedInADP && errors?.userLoggedInADP && <ErrorLabel>{errors.userLoggedInADP}</ErrorLabel>}
              {fields.userRequisitionReturn && errors?.userRequisitionReturn && (
                <ErrorLabel>{errors.userRequisitionReturn}</ErrorLabel>
              )}
              {fields.mescom && (
                <TwoFieldsWrapper>
                  <FieldHalfWidth>
                    <FieldWrapper fieldName={t("mescom")} width="100%">
                      <TextInputControl
                        placeholder="MM"
                        value={mescom}
                        error={!!errors?.mescom}
                        onChange={(val: string): void => this.setState({ mescom: val })}
                      />
                    </FieldWrapper>
                  </FieldHalfWidth>
                  <FieldHalfWidth />
                </TwoFieldsWrapper>
              )}
              {fields.mescom && errors?.mescom && <ErrorLabel>{errors.mescom}</ErrorLabel>}
              <FieldWrapper fieldName={t("Hours formats")} width="100%">
                <FieldTabs
                  active={hoursFormat}
                  onChange={(val: string): void => this.setState({ hoursFormat: val })}
                  tab1={{
                    value: "sexagesimal",
                    label: t("Sexagesimal (12:00)"),
                  }}
                  tab2={{
                    value: "centesimal",
                    label: t("Centessimal (12,00)"),
                  }}
                />
              </FieldWrapper>
              <FieldWrapper width="100%">
                <CheckboxControl
                  checked={convertValuesToAbs}
                  label={t("convert-values-to-abs")}
                  onChange={(checked) => {
                    this.setState({
                      convertValuesToAbs: checked,
                    });
                  }}
                />
              </FieldWrapper>
              {window.global_store.beta && (
                <FieldWrapper width="100%">
                  <CheckboxControl
                    checked={hoursSeparatorChecked}
                    label={t("hours-separator")}
                    onChange={(checked) => {
                      this.setState({
                        hoursSeparatorChecked: checked,
                        hoursSeparator: checked ? hoursSeparator || "" : "",
                      });
                    }}
                  />
                  {hoursSeparatorChecked && (
                    <HoursSeparator>
                      <TextInputControl
                        value={hoursSeparator}
                        onChange={(val: string): void =>
                          this.setState({
                            hoursSeparator: val && val[0] && [",", ":", "."].indexOf(val[0]) > -1 ? val[0] : "",
                          })
                        }
                      />
                    </HoursSeparator>
                  )}
                </FieldWrapper>
              )}
            </MainFields>
            <Subsection>
              <Subtitle>{t("Events")}</Subtitle>

              {!!events?.length && (
                <>
                  {events.map((event) => (
                    <Fragment key={event.id}>
                      <MultiFieldWrapper>
                        <IdFieldWrapper>
                          <FieldWrapper fieldName={t("ID")} width="100%">
                            <TextInputControl
                              value={event.eventCode}
                              error={errorEvents.some((ev: ErrorEvent) => ev.id === event.id)}
                              placeholder={this.getEventPlaceholder()}
                              onChange={(eventCode: string): void =>
                                this.setState({
                                  events: events.map((ev) => (ev.id === event.id ? { ...event, eventCode } : ev)),
                                })
                              }
                            />
                          </FieldWrapper>
                        </IdFieldWrapper>

                        <DateFieldWrapper>
                          <FieldWrapper fieldName={t("Dates")} width="100%">
                            <GroupedDropdown
                              onChange={(payrollEventTypeUuid: string) => {
                                this.setState({
                                  events: events.map((ev) =>
                                    ev.id === event.id ? { ...event, payrollEventTypeUuid } : ev,
                                  ),
                                });
                              }}
                              rawEventTypes={rawEventTypes}
                              value={event?.payrollEventTypeUuid || null}
                              key={`event${event?.payrollEventTypeUuid}`}
                            />
                          </FieldWrapper>
                        </DateFieldWrapper>

                        {isERPSankhuya && (
                          <EventTypeFieldWrapper>
                            <FieldWrapper fieldName={t("Event Type")} width="100%">
                              <SelectControl<ERPPayrollEventType>
                                isSearchable={false}
                                value={event?.eventType}
                                onChange={(eventType: ERPPayrollEventType) => {
                                  this.setState({
                                    events: events.map((ev) => (ev.id === event.id ? { ...event, eventType } : ev)),
                                  });
                                }}
                                options={Object.entries(ERPPayrollEventType).map(([k, v]) => ({ value: v, label: k }))}
                              />
                            </FieldWrapper>
                          </EventTypeFieldWrapper>
                        )}

                        <RemoveOption
                          onClick={(): void => {
                            const stateEvents = this.state.events;
                            this.setState({
                              events: stateEvents.filter((ev) => ev.id !== event.id),
                            });
                          }}
                        >
                          {images.removeIcon(10)}
                        </RemoveOption>
                      </MultiFieldWrapper>
                      {errorEvents.some((ev: ErrorEvent) => ev.id === event.id) && (
                        <ErrorLabel>{errorEvents.filter((ev: ErrorEvent) => ev.id === event.id)[0].error}</ErrorLabel>
                      )}
                    </Fragment>
                  ))}
                </>
              )}

              <AddRemoveLinkWrapper>
                <AddRemoveLink
                  label={t("New events")}
                  onClick={(): void => {
                    const stateEvents: EventType[] = this.state.events;
                    events.push({
                      id: events.length ? stateEvents[stateEvents.length - 1].id + 1 : 0,
                      eventCode: "",
                      payrollEventTypeUuid: rawEventTypes.length ? rawEventTypes[0].uuid : "",
                      eventType: isERPSankhuya ? ERPPayrollEventType.P : undefined,
                    });
                    this.setState({ events });
                  }}
                />
              </AddRemoveLinkWrapper>
            </Subsection>

            {/* undefined represents uninitialized state */}
            {filenameConfigurationType !== undefined && filenameConfiguration !== undefined && (
              <CreatePayrollFilenameConfig
                originalType={filenameConfigurationType}
                originalConfig={filenameConfiguration}
                setFilenameConfig={this.setFilenameConfig}
              />
            )}

            <ButtonWrapper>
              <Button loading={isLoading} state={ButtonState.primary} onClick={this.onSave} value={t("Save Layout")} />
            </ButtonWrapper>
            {this.props.id && this.props.id !== "create-layout" && (
              <DeleteLink
                onClick={(): void => {
                  this.setState({ confirmationPopupVisible: true });
                }}
              >
                {t("Delete Layout")}
              </DeleteLink>
            )}
          </PageWrapper>

          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={(): void => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Are you sure you want to delete this layout?")}
              text={t("Deleting this layout you will no longer have access to the data")}
              buttonYesTitle={t("Yes, Delete")}
              buttonCancelTitle={t("Cancel")}
              onClose={(): void => {
                this.setState({
                  confirmationPopupVisible: false,
                });
              }}
              onYes={this.onRemovePayrollConfirm}
            />
          </ModalDialog>
        </div>
      </FullPage>
    );
  }
}

export default withRouter(withTranslation([TranslationNamespaces.payroll, TranslationNamespaces.phases])(CreateLayout));
