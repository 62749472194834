import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import * as images from "../svg-images";
import RequestAttachmentsThumbnail from "./RequestAttachmentsThumbnail";

const FieldValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-surface-900-p);
`;
const AddFileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const AddFileText = styled.div`
  margin-top: 3px;
  font-size: 12px;
  font-weight: var(--typography-font-weight-medium);
  line-height: 12px;
  color: var(--colors-surface-400);
  text-align: center;
  letter-spacing: 0;
`;
const AddFile = styled.li`
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 79px;
  height: 77px;
  margin-top: 0;
  vertical-align: middle;
  cursor: pointer;
  background: var(--colors-surface-50);
  border: 2px dashed var(--colors-surface-400);
  border-radius: var(--shapes-border-radius-default);
`;
const Files = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  padding-inline-start: 0;
  margin: 0;
`;
const RemoveFileIcon = styled.div`
  display: none;
  background: var(--colors-buttonBgColor);
  border-radius: 22px;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  inset-inline-end: 8px;
  top: 8px;
`;
const FileContainer = styled.li`
  position: relative;
  width: 79px;
  height: 77px;
  margin-inline-end: 10px;
  margin-top: 0;
  list-style: none;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .request-details__file-preview::before {
      display: block;
    }

    .request-details__remove-file-icon {
      display: flex;
    }
  }
`;

export interface RequestAttachmentsRowProps {
  t: WithTranslation["t"];
  files: {
    uuid: string;
    title: string;
    url: string;
    thumbnailUrl: string;
    mimeType: string;
    type?: string;
    loading?: boolean;
    name?: string;
    preview?: string;
    percentCompleted?: number;
    requestUuid?: string;
    activityUuid?: string;

    cloudinaryId?: string;
    companyUuid?: string;
    createdAt?: string;
    createdBy?: string;
    deletedAt?: string | null;
    externalId?: unknown | null;
    updatedAt?: string;
    updatedBy?: string;
  }[];
  dropAvailable: boolean;
  onAddFilesClick?: () => void;
  onRemoveAttachment?: (attachment: { uuid: string; name: string }) => void;
  openSlideshow?: (fileIndex: number) => void;
}

function RequestAttachmentsRow(props: RequestAttachmentsRowProps) {
  const { files, t, dropAvailable, onAddFilesClick, onRemoveAttachment, openSlideshow } = props;

  return (
    <FieldValue>
      <Files>
        {files.map((f, i) => (
          <FileContainer
            key={f.uuid || f.url || f.preview}
            onClick={() => {
              if (openSlideshow) {
                openSlideshow(i);
              }
            }}
          >
            <RequestAttachmentsThumbnail
              preview={f.thumbnailUrl || f.preview || f.url}
              loading={f.loading}
              mimeType={f.mimeType || f.type}
              percentCompleted={f.percentCompleted}
            />
            {!f.loading && onRemoveAttachment && (
              <RemoveFileIcon
                className="request-details__remove-file-icon"
                onClick={(ev) => {
                  ev.stopPropagation();
                  onRemoveAttachment({ uuid: f.uuid, name: f.name || f.title });
                }}
              >
                {images.clear}
              </RemoveFileIcon>
            )}
          </FileContainer>
        ))}
        {dropAvailable && (
          <AddFile onClick={onAddFilesClick}>
            <div>
              <AddFileIcon>{images.requestAddPhoto(32)}</AddFileIcon>
              <AddFileText>{t("Add Files")}</AddFileText>
            </div>
          </AddFile>
        )}
      </Files>
    </FieldValue>
  );
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(RequestAttachmentsRow);
