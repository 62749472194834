import { useEffect, useRef, useState } from "react";
import { Expand } from "components/views/Expand";
import styled from "styled-components";
import { ChevronRight } from "components/Activities/TimeTracker/components/TimerButtons";
import { GroupedOption } from ".";

const OptionRowWrapper = styled.div`
  width: 100%;
  .OptionRow {
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    cursor: pointer;
    & > svg path {
      fill: var(--colors-surface-500);
    }
    &:hover {
      svg path {
        fill: var(--colors-surface-900-p);
      }
    }
    &.expanded {
      background: var(--colors-surface-50);
    }
    .Title {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      font-size: var(--typography-font-size-default);
      height: 52px;
      .Name {
        display: flex;
        align-items: center;
        flex: 1 0 0;
        color: var(--colors-surface-900-p);
        font-weight: var(--typography-font-weight-medium);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &.simple {
      border: none;
      .Title {
        height: 46px;
        background: var(--colors-surface-50);
      }
    }
  }
  .SearchResults {
    margin: 4px 0;
  }
`;

export const OptionRow = ({
  option,
  children,
  withSubOptionSelection,
  simple,
  selectedOptionId,
  onChange,
}: {
  option: GroupedOption | null;
  withSubOptionSelection?: boolean;
  simple?: boolean;
  selectedOptionId?: string;
  children: any;
  onChange: (value: string) => void;
}) => {
  const [expanded, setExpanded] = useState(!!(selectedOptionId && selectedOptionId === option?.id));
  const rowRef = useRef(null);

  useEffect(() => {
    if (selectedOptionId && !expanded && selectedOptionId === option?.id) {
      rowRef?.current && rowRef.current?.scrollIntoView({ block: "center" });
      setExpanded(true);
    }
  }, [selectedOptionId, expanded]);

  return (
    <>
      <OptionRowWrapper>
        <div
          ref={rowRef}
          className={`OptionRow ${expanded ? "expanded" : ""} ${simple ? "simple" : ""}`}
          onClick={() => {
            if (withSubOptionSelection) {
              setExpanded(!expanded);
            } else {
              option?.id && onChange(option.id);
            }
          }}
        >
          <div className="Title">
            <div className="Name">
              <div>{option?.label}</div>
            </div>
            {withSubOptionSelection && (
              <div style={{ rotate: expanded ? "90deg" : "0deg" }}>
                <ChevronRight />
              </div>
            )}
          </div>
        </div>
        {withSubOptionSelection && !simple && <Expand expanded={expanded}>{children}</Expand>}
        {simple && children}
      </OptionRowWrapper>
    </>
  );
};
