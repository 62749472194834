import { Component, ReactNode } from "react";
import styled from "styled-components";
import * as images from "components/svg-images";
import ClickOutside from "react-click-outside";

const ItemWrapper = styled.div<{ overlay: boolean }>`
  position: relative;
  ${(props) =>
    props.overlay
      ? `
    &:after{
      position: absolute;
      width: 100%;
      height: 100%;
      background: color-mix(in srgb, var(--colors-surface-400), transparent 50%);
      top: 0;
      inset-inline-end: 0;
      content: "";
    }
  `
      : ""}
`;

const NewRoleItem = styled.div`
  cursor: pointer;
  padding: 0;
  padding-inline-end: 32px;
  padding-inline-start: 12px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary-500-p);
  line-height: 35px;
`;

const Edit = styled.input`
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-800);
  padding: 0;
  padding-inline-end: 32px;
  padding-inline-start: 12px;
  line-height: 29px;
  border: 1px solid var(--colors-surface-400);
  border-radius: var(--shapes-border-radius-default);
  width: 100%;
`;

const EditApplyButton = styled.button`
  width: 31px;
  height: 31px;
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  background: var(--colors-primary-500-p);
  border: 1px solid var(--colors-primary-500-p);
  border-start-start-radius: 0;
  border-end-end-radius: 4px;
  border-end-start-radius: 0;
  border-start-end-radius: 4px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    ${(p) => (p.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
  }
`;

export interface NewItemProps {
  label: ReactNode;
  applyRegex?: RegExp;
  onApply: (data: { value: string; callBackFail: () => void; callBackSuccess: () => void }) => void;
}

interface NewItemState {
  edit: boolean;
  value: string;
  inProgress: boolean;
}

class NewItem extends Component<NewItemProps, NewItemState> {
  state = {
    edit: false,
    value: "",
    inProgress: false,
  };

  onApplyClick = (value: string) => {
    const { onApply } = this.props;
    this.setState({ inProgress: true });

    const callBackSuccess = () => {
      this.setState({ inProgress: false, edit: false, value: "" });
    };
    const callBackFail = () => {
      this.setState({ inProgress: false });
    };

    onApply({ value, callBackFail, callBackSuccess });
  };

  render() {
    const { edit, value, inProgress } = this.state;
    const { label, applyRegex } = this.props;

    return (
      <ItemWrapper overlay={inProgress}>
        {!edit ? (
          <NewRoleItem onClick={() => this.setState({ edit: true })}>{label}</NewRoleItem>
        ) : (
          <ClickOutside
            onClickOutside={() => {
              if (!inProgress) {
                this.setState({ edit: false });
              }
            }}
          >
            <Edit
              value={value}
              onChange={(ev) => {
                let val = ev.target.value;
                if (applyRegex) {
                  val = val.replaceAll(applyRegex, "");
                }
                this.setState({ value: val });
              }}
            />
            <EditApplyButton
              onClick={() => {
                if (value) {
                  this.onApplyClick(value);
                }
              }}
            >
              {images.smallArrowRight}
            </EditApplyButton>
          </ClickOutside>
        )}
      </ItemWrapper>
    );
  }
}

export default NewItem;
