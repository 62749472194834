import { Component } from "react";
import { getSchedule, getScheduleEmployees, deactivateSchedule } from "utils/apiHelpers";
import { minsToHrsMins, urlParam, getTitle } from "utils/common";
import { WithTranslation, withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import ScheduleCreate from "components/Schedules/ScheduleCreate";
import ScheduleEmployees from "components/Schedules/ScheduleEmployees";
import SchedulePopupMessage from "components/Schedules/SchedulePopupMessage";
import ModalDialog from "components/UI/ModalDialog";
import NotificationRow from "components/NotificationRow";
import Tabs from "components/UI/Tabs";
import GlobalContext from "context/global-context";
import moment from "moment";
import BEM from "utils/BEM";
import styled from "styled-components";
import "components/UI/Page/Page.scss";
import "styles/schedule-details.scss";
import { Schedule } from "types/models/schedule";
import { Notification } from "types/common";
import { EmployeeSchedule } from "types/models/userProfile";
import { RouteComponentProps } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import { calculateTotalWorkingHoursWithWorkingBreaks } from "./helpers";

const b = BEM.b("schedule-details");
const p = BEM.b("page");

const HeaderAction = styled.div`
  float: right;
  min-width: 166px;
  align-items: right;
`;

enum ScheduleTab {
  details = "details",
  employees = "employees",
}

interface ScheduleDetailsProps extends WithTranslation, RouteComponentProps {
  scheduleUuid: string;
}

interface ScheduleDetailsState {
  schedule: Schedule | null;
  loaded: boolean;
  isFetching: boolean;
  weeklyTotal: number;
  activeTab: ScheduleTab;
  notification: Notification["notification"];
  addEmployeesActive: boolean;
  employees: EmployeeSchedule[] | null;
  deactivatePopupVisible: boolean;
}

class ScheduleDetails extends Component<ScheduleDetailsProps, ScheduleDetailsState> {
  static contextType = GlobalContext;

  constructor(props: ScheduleDetailsProps) {
    super(props);
    const { t } = props;
    const tab = urlParam("addEmployees");

    this.state = {
      schedule: null,
      loaded: false,
      isFetching: false,
      weeklyTotal: 0,
      activeTab: ScheduleTab.details,
      addEmployeesActive: false,
      employees: null,
      deactivatePopupVisible: false,
      notification: this.getNotificationElement(tab, t),
    };

    document.title = getTitle(t("Schedule"));
  }

  componentDidMount(): void {
    void this.getState({ ...this.props });
  }

  getState = async (props: ScheduleDetailsProps): Promise<void> => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, loaded: false });

      const company = await this.context.getCompany();
      const companyUuid = company.uuid;

      const r = await getSchedule({ companyUuid, scheduleUuid: props.scheduleUuid });

      const employees = await getScheduleEmployees({
        companyUuid,
        scheduleUuid: props.scheduleUuid,
        limitDate: moment().format("YYYY-MM-DD"),
      });

      this.setState({
        loaded: true,
        schedule: r.content,
        weeklyTotal: r?.content ? calculateTotalWorkingHoursWithWorkingBreaks(r.content) : 0,
        employees: employees.content,
        isFetching: false,
      });
    }
  };

  getNotificationElement = (tab: string | 0 | null, t: WithTranslation["t"]): JSX.Element | string => {
    if (tab) {
      return (
        <div>
          <div>{t("Schedule Created")}</div>
          <div>{t("Congratulations on creating your schedule. Now please assign the relevant employees.")}</div>
          <div>
            <button onClick={this.onAddEmployeesClick}>{t("Add Employees")}</button>
          </div>
        </div>
      );
    }

    return "";
  };

  onAddEmployeesClick = (): void => {
    this.setState(
      {
        activeTab: ScheduleTab.employees,
        addEmployeesActive: true,
        notification: "",
      },
      () => this.setState({ addEmployeesActive: false }),
    );
  };

  tabChange = (tab: ScheduleTab): void => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  onDeactivateScheduleClick = (): void => {
    this.setState({ deactivatePopupVisible: true });
  };

  deactivateSchedule = async (): Promise<void> => {
    const { t } = this.props;
    const currentProfileUuid = window.global_store.profile.uuid;

    try {
      await deactivateSchedule({
        body: {
          content: {
            endDate: moment().format("YYYY-MM-DD"),
            deactivatedBy: currentProfileUuid,
          },
        },
        companyUuid: window.global_store.company.uuid,
        scheduleUuid: this.props.scheduleUuid,
      });

      this.setState({
        notification: `${t("You deactivated schedule")}: ${this.state.schedule?.name}`,
        deactivatePopupVisible: false,
      });

      this.getState(this.props);
    } catch (e) {
      console.log((e as Error).message);
    }
  };

  render(): JSX.Element {
    const {
      schedule,
      loaded,
      activeTab,
      weeklyTotal,
      employees,
      notification,
      deactivatePopupVisible,
      addEmployeesActive,
    } = this.state;
    const { t, history } = this.props;
    const notificationType = "success";

    if (!loaded || !schedule) {
      return t("Loading...");
    }

    return (
      <SettingsLayout backButtonOnclick={() => history.push("/schedules")} backButtonTitle={t("Schedules")}>
        <div className={p()}>
          <div className={p("header", { reports: true })} style={{ marginTop: 0, marginBottom: 8 }}>
            <div
              className={p("title")}
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                height: "30px",
              }}
            >
              {schedule.name}
              <StatusTag
                value={schedule.active ? t("active") : t("inactive")}
                type={schedule.active ? StatusTagType.active : StatusTagType.default}
              />
            </div>
            <HeaderAction>
              <div className={b("weekly-total")}>
                {t("Weekly Total")}: <span className={b("weekly-total-value")}>{minsToHrsMins(weeklyTotal)} hs</span>
              </div>
              <div className={b("employees-total")}>
                {t("Employees")}: <span className={b("employees-total-value")}>{employees?.length}</span>
              </div>
            </HeaderAction>
          </div>

          <Tabs
            tabs={[
              {
                name: "details",
                label: t("Details"),
              },
              {
                name: "employees",
                label: t("Employees"),
              },
            ]}
            t={t}
            value={activeTab}
            onChange={(tab: ScheduleTab): void => this.setState({ activeTab: tab })}
          />

          {notification && (
            <NotificationRow
              employeesPage
              onClose={(): void => this.setState({ notification: "" })}
              type={notificationType}
              message={notification}
            />
          )}

          <div className={p("content")}>
            {schedule && (
              <div style={{ display: activeTab === "details" ? "block" : "none" }}>
                <ScheduleCreate schedule={schedule} />
              </div>
            )}

            {schedule && activeTab === "employees" ? (
              <div style={{ display: activeTab === "employees" ? "block" : "none" }}>
                <ScheduleEmployees addEmployeesActive={addEmployeesActive} schedule={schedule} employees={employees} />
              </div>
            ) : null}
          </div>

          {schedule.active && (
            <div className={b("footer")}>
              <div className={b("disable-schedule")} onClick={this.onDeactivateScheduleClick}>
                {t("Disable Schedule")}
              </div>
              <div className={b("footer-text")}>
                {t(
                  "You can not edit active schedules. By deactivating your schedule, your employees will be on the schedule.",
                )}
              </div>
            </div>
          )}

          <ModalDialog
            isOpen={deactivatePopupVisible}
            onClose={(): void => this.setState({ deactivatePopupVisible: false })}
          >
            <SchedulePopupMessage
              type="disable"
              onClose={(): void => this.setState({ deactivatePopupVisible: false })}
              onYes={this.deactivateSchedule}
            />
          </ModalDialog>
        </div>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.schedules)(ScheduleDetails);
