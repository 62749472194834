import FieldWrapper from "components/UI/FieldWrapper";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import TimeControl from "components/controls/TimeControl";
import { hrsMinsToMins, minsToHrsMins } from "utils/common";
import { PayedOvertimePhase } from "types/models/businessRulesGroup";
import { GetHoursBankBalanceResponseData } from "utils/api/types";
import { RequestSubtypeSpecificFieldTypes, RequestTypeSpecificField } from "types/models/request";
import { ErrorLabel } from "components/UI/TextLabels";
import { RequestsAddEditPopupErrors } from "../RequestAddEditPopup";

const TwoFiledsWrapper = styled.div`
  display: flex;
  margin: 8px 0;
  > div {
    flex: 1;
  }
  > div:first-child {
    margin-inline-end: 10px;
  }
`;

type SubtypeSpecificFieldsHBAdjustmentProps = {
  userProfileUuid: string | null;
  selectedPhase: PayedOvertimePhase | null;
  hoursBankMins: GetHoursBankBalanceResponseData["content"] | null;
  errors: RequestsAddEditPopupErrors;
  field: RequestTypeSpecificField;
  onTimeChange: (value: RequestTypeSpecificField) => void;
};

const SubtypeSpecificFieldsHBAdjustment = ({
  userProfileUuid,
  selectedPhase,
  hoursBankMins,
  errors,
  field,
  onTimeChange,
}: SubtypeSpecificFieldsHBAdjustmentProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <>
      <TwoFiledsWrapper key={RequestSubtypeSpecificFieldTypes.adjustment}>
        <FieldWrapper fieldName={t("Cumulated Bank")} width="100%" fieldTitleMarginBottom={0}>
          <TimeControl
            style={{
              borderColor: "var(--colors-surface-150)",
              paddingInlineStart: 12,
              color: "var(--colors-surface-500)",
            }}
            disabled
            value={minsToHrsMins(
              (selectedPhase ? hoursBankMins?.phases[selectedPhase.uuid] : hoursBankMins?.total) || 0,
            )}
            onTimeChange={undefined}
            placeholder="00:00"
          />
        </FieldWrapper>

        <FieldWrapper fieldName={t("To Convert")} width="100%" fieldTitleMarginBottom={0}>
          <TimeControl
            disabled={!userProfileUuid}
            noHoursLimit
            style={{
              paddingInlineStart: 12,
            }}
            value={field.value ? minsToHrsMins(field.value.phaseValue as number) : undefined}
            onTimeChange={({ value }) => {
              const newField = { ...field };

              newField.value = {
                phaseId: selectedPhase?.uuid || "negative",
                phaseValue: hrsMinsToMins(value),
              };
              onTimeChange(newField);
            }}
            placeholder="00:00"
            error={!!errors?.tsfErrors?.[field.name]}
          />
        </FieldWrapper>
      </TwoFiledsWrapper>
      {!!errors?.tsfErrors?.[field.name] && <ErrorLabel>{errors.tsfErrors[field.name]}</ErrorLabel>}
    </>
  );
};

export default SubtypeSpecificFieldsHBAdjustment;
