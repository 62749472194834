import styled from "styled-components";

export const PageWrapper = styled.div`
  padding-bottom: 90px;
`;

export const PageContent = styled.div`
  margin-top: 23px;
`;

export const Header = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 28px;
  align-items: center;
`;

export const Title = styled.div<{ isRTL?: boolean }>`
  font-size: 30px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.66px;
  line-height: 36px;
  flex-grow: 1;
  &.loading {
    max-width: 100px;
    height: 26px;
  }
  font-weight: ${(p): string => (p.isRTL ? "700" : "normal")};
`;

export const HeaderAction = styled.div`
  min-width: 166px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .ui-select__wrapper > div {
    margin: 0;
  }

  & > button {
    margin: 0;
  }
`;

export const SpacerVertical = styled.div<{ height?: number }>`
  height: ${(p): string => (p.height ? `${p.height}px` : "36px")};
`;

export const TableButtons = styled.div`
  display: none;
  position: absolute;
  margin-top: -22px;
  inset-inline-end: 30px;
`;

export const TableButton = styled.div`
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-secondary2);
  border-radius: var(--shapes-border-radius-default);
  color: var(--colors-surface-800);
  height: 30px;
  padding: 0 7px;
  font-size: var(--typography-font-size-default);
  line-height: 30px;
  letter-spacing: -0.08px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--colors-buttonBorderColor);
    box-shadow: 0 1px 2px 0 rgba(82, 95, 127, 0.2);
  }
  svg {
    vertical-align: middle;
  }
  margin-inline-end: 10px;
`;

interface PageSearchProps {
  withControls?: boolean;
  reports?: boolean;
  lateArrival?: boolean;
  activity?: boolean;
}

export const PageSearch = styled.div<PageSearchProps>`
  width: 100%;
  max-width: 237px;
  min-width: 237px;
  ${(p): string =>
    p.withControls
      ? `
      max-width: 300px;
      min-width: 300px;`
      : ""}
  ${(p): string =>
    p.reports
      ? `
      max-width: 319px;
      min-width: 250px;`
      : ""}
  ${(p): string =>
    p.lateArrival
      ? `
      max-width: 250px;
      min-width: 250px;`
      : ""}
  ${(p): string => (p.activity ? "margin-inline-end: 10px;" : "")}
`;
