import { useContext, useState, useEffect } from "react";
import { Groups, AggregationGroupType, PeriodAggregationData } from "types/models/dashboard";
import { getSuperpunchGroupsData } from "utils/apiHelpers";
import GlobalContext from "context/global-context";
import sentryUtils from "utils/sentryUtils";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { FilteredEmployeeProfile, FilteredEmployeesExternalFields } from "types/models/userProfile";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { showSnackbar } from "utils/common";
import { useFlags } from "launchdarkly-react-client-sdk";

export enum UseDataHookStatus {
  idle = "idle",
  success = "success",
  processing = "processing",
  error = "error",
}

export type SuperpunchGroup = {
  uuid: string;
  name: string;
  groupSize: number;
  data: PeriodAggregationData;
};

export type SuperpunchEmployee = {
  data: PeriodAggregationData;
  name: string;
  groupSize: number;
  uuid: string;
  employee?: {
    fullName: string;
    uuid: string;
    id: number;
    avatarId: string | null;
    teamUuid: string | null;
    ownTeamUuid: string | null;
    position: string | null;
    hasSchedule: boolean;
  };
};

type UseDataHookState<T> = {
  data: T[] | null;
  status: UseDataHookStatus;
  errorMessage?: string;
  totalRecords: number;
};

export const useGroupData = ({
  startDate,
  endDate,
  groupUuid,
  groupBy,
  pagination,
}: {
  startDate: string;
  endDate: string;
  groupUuid: string;
  groupBy: Groups;
  pagination: { page: number; perPage: number };
}) => {
  const context = useContext(GlobalContext);
  const { t } = useTranslation(TranslationNamespaces.common);
  const flags = useFlags();
  const [state, setState] = useState<UseDataHookState<SuperpunchEmployee>>({
    data: null,
    status: UseDataHookStatus.idle,
    totalRecords: 0,
  });

  useEffect(() => {
    setState({
      data: null,
      status: UseDataHookStatus.processing,
      errorMessage: undefined,
      totalRecords: 0,
    });

    void (async () => {
      try {
        const company = await context.getCompany();

        let response = [];
        let metadata: Record<string, number> = {};
        let employees: FilteredEmployeeProfile[] = [];
        let errorMessage;

        ({ content: response, metadata } = await getSuperpunchGroupsData({
          companyUUID: company.uuid,
          requestedBy: window.global_store.profile.uuid,
          from: startDate,
          to: endDate,
          groupBy,
          groupUuid,
          aggregationGroupType: AggregationGroupType.userProfile,
          page: pagination.page,
          perPage: pagination.perPage,
        }));

        ({ content: employees } = await listUserProfilesWIthFilters(company.uuid, {
          ...baseByUuidPayload(
            window.global_store.profile.uuid,
            Object.values(response).map((v) => v.groupUuid),
          ),
          fields: ["id", "uuid", "fullName", "avatarId", "position.title", "team.uuid", "ownTeam.uuid"],
          externalFields: [FilteredEmployeesExternalFields.Schedules],
        }));

        setState({
          data: Object.values(response).map(({ groupName: name, groupUuid: uuid, ...item }) => {
            const emp = employees.find((e) => e.uuid === uuid);
            if (emp) {
              const employee: SuperpunchEmployee["employee"] = {
                fullName: name,
                uuid,
                id: emp.id,
                avatarId: emp.avatarId,
                teamUuid: emp.team?.uuid || null,
                ownTeamUuid: emp.ownTeam?.uuid || null,
                position: emp.position?.title || null,
                hasSchedule: !!emp.schedules.length,
              };

              return { name, uuid, ...item, employee };
            }

            return { name, uuid, ...item };
          }),
          status: UseDataHookStatus.success,
          errorMessage,
          totalRecords: metadata?.totalRows || metadata?.total || 0,
        });
      } catch (error) {
        const errorMessage = t("failed-to-load-data");
        if (!flags.suppressSuperpunchErrors) {
          showSnackbar({
            text: errorMessage,
            notificationStyle: "error",
          });
        }
        sentryUtils.sendError(error);
        setState({
          data: null,
          status: UseDataHookStatus.error,
          errorMessage: (error as Error)?.message || errorMessage,
          totalRecords: 0,
        });
      }
    })();
  }, [startDate, endDate, groupUuid, groupBy, pagination.page, pagination.perPage]);

  return state;
};

export const useGroupsData = ({
  startDate,
  endDate,
  groupType,
  pagination,
}: {
  startDate: string;
  endDate: string;
  groupType: AggregationGroupType;
  pagination: { page: number; perPage: number };
}) => {
  const { t } = useTranslation(TranslationNamespaces.common);
  const context = useContext(GlobalContext);
  const flags = useFlags();
  const [state, setState] = useState<UseDataHookState<SuperpunchGroup>>({
    data: null,
    status: UseDataHookStatus.idle,
    totalRecords: 0,
  });

  useEffect(() => {
    setState({
      data: null,
      status: UseDataHookStatus.processing,
      errorMessage: undefined,
      totalRecords: 0,
    });
    void (async () => {
      try {
        const company = await context.getCompany();

        let response = [];
        let metadata: Record<string, number> = {};

        ({ content: response, metadata } = await getSuperpunchGroupsData({
          companyUUID: company.uuid,
          requestedBy: window.global_store.profile.uuid,
          from: startDate,
          to: endDate,
          aggregationGroupType: groupType,
          page: pagination.page,
          perPage: pagination.perPage,
        }));

        const data = Object.values(response)
          .filter((item) => item?.groupUuid)
          .map(({ groupName: name, groupUuid: uuid, ...item }) => ({
            name,
            uuid,
            ...item,
          })) as SuperpunchGroup[];

        setState({
          data,
          status: UseDataHookStatus.success,
          errorMessage: undefined,
          totalRecords: metadata?.totalRows || metadata?.total || 0,
        });
      } catch (error) {
        const errorMessage = t("failed-to-load-data");
        if (!flags.suppressSuperpunchErrors) {
          showSnackbar({
            text: errorMessage,
            notificationStyle: "error",
          });
        }
        sentryUtils.sendError(error);
        setState({
          data: null,
          status: UseDataHookStatus.error,
          errorMessage: (error as Error)?.message || errorMessage,
          totalRecords: 0,
        });
      }
    })();
  }, [startDate, endDate, groupType, pagination.page, pagination.perPage]);

  return state;
};
