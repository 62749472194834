import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox";
import BEM from "utils/BEM";
import "./Select.scss";
import Tooltip from "../Tooltip";

const b = BEM.b("ui-select");

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`;
const OptionTextWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface SelectOptionProps {
  label: string;
  onSelect: () => void;
  active: boolean;
  checkbox?: boolean;
  dash?: boolean;
  preselected?: unknown;
  disabled?: boolean;
}

const Option = ({ label, checkbox, dash, onSelect, active, preselected, disabled }: SelectOptionProps): JSX.Element => (
  <OptionWrapper
    onClick={() => !disabled && onSelect()}
    className={b("option", { disabled, active, checkbox, "no-checkbox": !checkbox, preselected })}
  >
    {checkbox ? (
      <CheckboxControl checked={active} disabled={disabled} label={label} onChange={() => {}} dash={dash} />
    ) : (
      <>
        <Tooltip id={`id_${label}`} offset={{ top: -8 }} wrapperStyle={{ zIndex: 100000 }}>
          {label}
        </Tooltip>
        <OptionTextWrapper data-tip data-for={`id_${label}`}>
          {label}
        </OptionTextWrapper>
      </>
    )}
  </OptionWrapper>
);

export default Option;
