import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useContext, useEffect, useState } from "react";
import SettingsLayout from "components/Layout/SettingsLayout";
import ImportsService, { FlatfileActionType, FlatfileImportJob, JobStatus } from "utils/api/imports";
import styled from "styled-components";
import moment from "moment-timezone";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { getTitle } from "../../utils/common";
import { PageWrapper } from "../styled/Page";
import NotificationRow from "../NotificationRow";
import { NotificationType } from "../../types/common";
import SearchInput from "../UI/SearchInput";
import NoContent from "../NoContent";
import TablePage from "../TablePage";
import { ImportEmployee } from "./ImportEmployee";
import { GlobalContext } from "../../context/GlobalContextProvider";
import { useAsyncCallback } from "../../utils/useAsyncEffect";
import { iCellInfo } from "../../utils/tableHelpers";
import StatusBadge, { StatusBadgeStatuses } from "../controls/StatusBadge";

const TableWrapper = styled.div`
  .table-common .rt-td {
    height: 50px;
    display: flex;
    align-items: center;
  }
`;

export const ImportFilesSettingsPage = () => {
  const { t } = useTranslation([TranslationNamespaces.dataManagement, TranslationNamespaces.sidebar]);
  const context = useContext(GlobalContext);
  const [files, setFiles] = useState<FlatfileImportJob[]>([]);
  const [notification, setNotification] = useState<{ message: string; type: NotificationType }>();
  const [search, setSearch] = useState<string>();
  const [filteredItems, setFilteredItems] = useState<FlatfileImportJob[]>([]);

  document.title = getTitle(t("Import files"));
  const [getList, getListLoading] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const list = await ImportsService.getImportsList(company.uuid);
    setFiles(list);
    setFilteredItems(list);
  }, []);

  useEffect(() => void getList(), []);
  useEffect(() => {
    let filteredData = files;
    if (search) {
      filteredData = files.filter((file) => file.fileName.match(search!));
    }
    setFilteredItems(filteredData);
  }, [search]);

  const tableColumns = [
    {
      label: t("file_name_col"),
      accessor: "fileName",
    },
    {
      label: t("date_col"),
      accessor: "createdAt",
      Cell: (row: iCellInfo<FlatfileImportJob>): JSX.Element => (
        <span>{moment(row.value).format("DD MMMM YYYY, HH:mm")}</span>
      ),
    },
    {
      label: t("user_col"),
      accessor: "executorName",
    },
    {
      label: t("type_col"),
      accessor: "type",
      Cell: (row: iCellInfo<FlatfileImportJob>): JSX.Element => {
        const typeMap: Record<string, string> = {
          [FlatfileActionType.Add]: translateEmployeeTerm(
            t,
            TranslationNamespaces.dataManagement,
            "custom-import-employees",
            "Import employees",
          ),
          [FlatfileActionType.Update]: t("employee_update_type"),
        };

        return <span>{typeMap[row.value]}</span>;
      },
    },
    {
      label: t("status_col"),
      accessor: "status",
      Cell: (row: iCellInfo<FlatfileImportJob>): JSX.Element => {
        const typeMap: Record<JobStatus, StatusBadgeStatuses> = {
          [JobStatus.Pending]: StatusBadgeStatuses.pending,
          [JobStatus.Processing]: StatusBadgeStatuses.new,
          [JobStatus.Completed]: StatusBadgeStatuses.success,
          [JobStatus.Failed]: StatusBadgeStatuses.declined,
          [JobStatus.WithErrors]: StatusBadgeStatuses.warn,
        };
        return <StatusBadge value={t(`status_type_${row.value}`)} type={typeMap[row.value as JobStatus]} />;
      },
      width: 130,
      align: "center",
    },
  ];

  return (
    <SettingsLayout
      title={t(`${TranslationNamespaces.common}|Settings`)}
      headerAction={<ImportEmployee notificationCb={(notificationData) => setNotification(notificationData)} />}
    >
      <PageWrapper>
        {notification?.message && (
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type={notification.type}
            message={notification.message}
          />
        )}

        <TableWrapper>
          <TablePage
            columnSelectorOnFiltersRow
            filters={
              <SearchInput
                modifiers={["filter"]}
                onChange={(event) => setSearch(event.target.value)}
                placeholder={t("Search")}
                value={search}
              />
            }
            rows={filteredItems}
            columns={tableColumns}
            className="positions-table"
            loading={getListLoading}
            noContentComponent={<NoContent>{t("No files added.")}</NoContent>}
          />
        </TableWrapper>
      </PageWrapper>
    </SettingsLayout>
  );
};
