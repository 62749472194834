import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import NoContent from "components/NoContent";
import TablePage from "components/TablePage";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { AggregationGroupType } from "types/models/dashboard";
import { minsToHrsMins } from "utils/common";
import { iCellInfo } from "utils/tableHelpers";
import GlobalContext from "context/global-context";
import { paramsToUrlParams } from "utils/apiHelpers";
import { ColumnAlign, iColumn } from "components/TableCommon";
import PaginationNew from "components/PaginationNew";
import ga, { GaSpPageType } from "utils/ga";
import { SuperpunchGroup, UseDataHookStatus, useGroupsData } from "./customHooks";
import ProgressCell from "./ProgressCell/ProgressCell";
import PendingCell from "./PendingCell/PendingCell";
import ColumnHeaderWithDescr from "./ColumnHeaderWithDescr/ColumnHeaderWithDescr";
import ProgressCellTooltip from "./ProgressCell/ProgressCellTooltip";

const Wrapper = styled.div`
  .groups-superpunch-table {
    .table-common__th {
      div {
        pointer-events: auto;
      }
    }
  }
`;

const NameCell = styled.span`
  font-size: var(--typography-font-size-default);
  font-weight: var(--cp-sp-table-name-cell-font-weight, var(--typography-font-size-default));
  color: var(--cp-sp-table-name-cell-text-color, var(--colors-surface-800));
`;

type GroupsSuperpunchTableProps = {
  groupType: Exclude<AggregationGroupType, "userProfile" | "company">;
  startDate: string;
  endDate: string;
  onPendingPunchesClick: (uuid: string, pendingPunchesCount: number, isProcessing: boolean) => void;
  // PROD-11156 PROD-11157 todo
  processingGroupsUuids: string[];
};

const useColumns = (
  groupType: GroupsSuperpunchTableProps["groupType"],
  onPendingPunchesClick: GroupsSuperpunchTableProps["onPendingPunchesClick"],
  processingGroupsUuids: GroupsSuperpunchTableProps["processingGroupsUuids"],
) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const nameColumnLabel = {
    [AggregationGroupType.departments]: t(`${TranslationNamespaces.common}|Department`),
    [AggregationGroupType.teams]: t(`${TranslationNamespaces.common}|Team`),
    [AggregationGroupType.subsidiaries]: t(`${TranslationNamespaces.common}|Subsidiary`),
  }[groupType];

  return [
    {
      label: nameColumnLabel,
      accessor: "name",
      Cell: (row: iCellInfo<SuperpunchGroup>) => <NameCell>{row.value}</NameCell>,
      minWidth: 160,
    },
    {
      label: t(`${TranslationNamespaces.common}|Employees`),
      accessor: "groupSize",
      minWidth: 70,
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchGroup> }) => (
        <ColumnHeaderWithDescr column={column} description={t("group-size-descr")} />
      ),
    },
    {
      label: t("Pending punches"),
      accessor: "data",
      Cell: (row: iCellInfo<SuperpunchGroup, SuperpunchGroup["data"]>) => (
        <PendingCell
          entityUuid={row.original.uuid}
          pendingItemsCount={row.value.pendingPunchesCount}
          onClick={onPendingPunchesClick}
          // PROD-11156 PROD-11157 todo
          isProcessing={false}
        />
      ),
      align: "center" as ColumnAlign,
      style: { padding: 0, margin: 0, flex: "0 0 auto", width: 148 },
      minWidth: 80,
    },
    {
      label: t("Invalid punches"),
      accessor: "data",
      Cell: (row: iCellInfo<SuperpunchGroup, SuperpunchGroup["data"]>) => (
        <PendingCell
          entityUuid={row.original.uuid}
          pendingItemsCount={row.value.invalidPunchesCount}
          onClick={onPendingPunchesClick}
          // PROD-11156 PROD-11157 todo
          isProcessing={false}
        />
      ),
      align: "center" as ColumnAlign,
      style: { padding: 0, margin: 0, flex: "0 0 auto", width: 148 },
      minWidth: 80,
    },
    {
      label: t("Executed / Planned Hours"),
      accessor: "data",
      Cell: ({ original, value }: iCellInfo<SuperpunchGroup, SuperpunchGroup["data"]>) => (
        <>
          <div data-tip data-for={`request-tooltip-${original.uuid}`.replace("\n", "")}>
            <ProgressCell
              targetValue={value.plannedMinutes}
              currentValue={value.workedMinutes + value.paidAbsenceMinutes}
              usePercents
            />
          </div>
          <ProgressCellTooltip
            t={t}
            id={`request-tooltip-${original.uuid}`.replace("\n", "")}
            plannedMinutes={value.plannedMinutes}
            workedMinutes={value.workedMinutes}
            paidAbsenceMinutes={value.paidAbsenceMinutes}
          />
        </>
      ),
      align: "start" as ColumnAlign,
      minWidth: 200,
      Header: ({ column }: { column: iColumn<SuperpunchGroup> }) => (
        <ColumnHeaderWithDescr column={column} description={t("executed-planned-descr")} />
      ),
    },
    {
      label: t(`Missed Days`),
      accessor: "data",
      minWidth: 70,
      Cell: (row: iCellInfo<SuperpunchGroup>) => row.value.missedDay || "-",
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchGroup> }) => (
        <ColumnHeaderWithDescr column={column} description={t("missed-days-descr")} />
      ),
    },
    {
      label: t(`Extra Hours`),
      accessor: "data",
      minWidth: 70,
      Cell: (row: iCellInfo<SuperpunchGroup>) =>
        row.value.extraHoursMinutes ? minsToHrsMins(row.value.extraHoursMinutes) : "-",
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchGroup> }) => (
        <ColumnHeaderWithDescr column={column} description={t("extra-hours-descr")} />
      ),
    },
    {
      label: t(`Hours Bank`),
      accessor: "data",
      minWidth: 70,
      Cell: (row: iCellInfo<SuperpunchGroup>) =>
        row.value.hoursBankMinutes ? minsToHrsMins(row.value.hoursBankMinutes) : "-",
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchGroup> }) => (
        <ColumnHeaderWithDescr column={column} description={t("hours-bank-descr")} />
      ),
    },
  ];
};

const GroupsSuperpunchTable = ({
  startDate,
  endDate,
  groupType,
  onPendingPunchesClick,
  // PROD-11156 PROD-11157 todo
  processingGroupsUuids,
}: GroupsSuperpunchTableProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const location = useLocation();
  const history = useHistory();
  const context = useContext(GlobalContext);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  // TODO consider to pass whole props obj if attrs are growing
  const columns = useColumns(groupType, onPendingPunchesClick, processingGroupsUuids);

  const { data, status, totalRecords } = useGroupsData({ startDate, endDate, groupType, pagination });

  useEffect(() => {
    // if no data - redirect to employee superpunch
    if (status === UseDataHookStatus.error || (status === UseDataHookStatus.success && !data?.length)) {
      void (async () => {
        const { full_name: label, id, uuid } = window.global_store?.profile || {};
        const { startDate: periodStartDate, endDate: periodEndDate } = (await context.getDefaultPayPeriod()) || {};

        const params = paramsToUrlParams({
          type: "edit",
          label,
          id,
          uuid,
          startDate: (periodStartDate ? moment(periodStartDate) : moment().date(1)).format("YYYY-MM-DD"),
          endDate: (periodEndDate ? moment(periodEndDate) : moment().endOf("month")).format("YYYY-MM-DD"),
        });

        history.push(`/punches${params}`);
      })();
      return;
    }

    if (status === UseDataHookStatus.success) {
      const pageLocation = encodeURI(window.location.origin + location.pathname + location.search);
      const pageType = AggregationGroupType.departments ? GaSpPageType.departments : GaSpPageType.teams;

      ga.trackSuperPunchNavigation(pageLocation, pageType);
    }
  }, [data]);

  return (
    <Wrapper>
      <TablePage<SuperpunchGroup>
        rows={data || []}
        columnSelectorOnFiltersRow
        columns={columns}
        className="groups-superpunch-table"
        loading={status === UseDataHookStatus.processing}
        noContentComponent={<NoContent>{t("No departments")}</NoContent>}
        getTrProps={(_, rowInfo): { className: string; onClick: (e: KeyboardEvent) => void } => ({
          className: "groups-superpunch-row",
          onClick: (e: KeyboardEvent): void => {
            if (!rowInfo) {
              return;
            }
            const { uuid, name } = rowInfo.row._original;
            const url = `/punches?type=group-edit&startDate=${startDate}&endDate=${endDate}&group_type=${groupType}&group_uuid=${uuid}&search_value=${name}`;

            if (e.metaKey) {
              Object.assign(document.createElement("a"), {
                target: "_blank",
                href: url,
              }).click();
            } else {
              history.push(url);
            }
          },
        })}
        showPagination
        PaginationComponent={() => (
          <PaginationNew
            totalRecords={totalRecords}
            pageLimit={pagination.perPage}
            currentPage={pagination.page}
            onPageLimitChange={(perPage, page) => setPagination({ page, perPage })}
            onPageChange={(currentPage) => setPagination({ page: currentPage, perPage: pagination.perPage })}
          />
        )}
      />
    </Wrapper>
  );
};

export default GroupsSuperpunchTable;
