import { useEffect, useState } from "react";
import styled from "styled-components";
import GroupedSelect from "components/UI/Select/GroupedSelect";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Project } from "types/models/projects";
import { ClearRow } from "./styled";

const Wrapper = styled.div`
  border-top: 1px solid var(--colors-surface-150);
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  &:hover {
    .clear-row-button {
      visibility: visible;
    }
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  padding-top: 10px;
  padding-inline-end: 0;
  padding-inline-start: 12px;
  padding-bottom: 10px;
`;

type Task = {
  projectUuid: string;
  taskName: string;
  projectName: string;
  taskUuid: string;
  project: Project;
};

type GroupedTaskOption = {
  label: string;
  options: {
    label: string;
    value: string;
    projectUuid: string;
    project: Project;
  }[];
};

type AddNewTaskActivitiesRowProps = {
  onAddTaskToTable: (taskUuid: string) => void;
  onRemoveRow: () => void;
  tasksOptions: Task[];
};

const mapTaskProjects = (tasks: Task[]) => {
  const grouped: GroupedTaskOption[] = [];
  const projects: Record<string, Task[]> = {};
  tasks.forEach((task) => {
    if (projects[task.projectUuid]) {
      projects[task.projectUuid].push(task);
    } else {
      projects[task.projectUuid] = [task];
    }
  });
  Object.keys(projects).forEach((proj) => {
    const projectTasks = projects[proj];
    grouped.push({
      label: projectTasks[0].projectName,
      options: projectTasks.map((pt) => ({
        label: pt.taskName,
        value: pt.taskUuid,
        projectUuid: pt.projectUuid,
        project: pt.project,
      })),
    });
  });
  return grouped;
};

export default ({ tasksOptions, onAddTaskToTable, onRemoveRow }: AddNewTaskActivitiesRowProps) => {
  const [availableTaskOptions, setAvailableTaskOptions] = useState<GroupedTaskOption[]>([]);
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  useEffect(() => {
    setAvailableTaskOptions(mapTaskProjects(tasksOptions));
  }, [tasksOptions]);

  if (!availableTaskOptions?.length) {
    return null;
  }

  return (
    <Wrapper>
      <ClearRow className="clear-row-button" onClick={() => onRemoveRow()} />
      <SelectWrapper>
        <GroupedSelect
          modifiers={{ field: true }}
          value={null}
          placeholder={t("Add new task")}
          onChange={(val: string) => {
            onAddTaskToTable(val);
          }}
          groupedOptions={availableTaskOptions}
          withSearch
        />
      </SelectWrapper>
    </Wrapper>
  );
};
