import styled from "styled-components";
import Avatar from "components/views/Avatar";

const Wrapper = styled.div<{ withBorder?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => (p.withBorder ? "var(--colors-surface-150)" : "transparent")};
  padding-top: 0;
  padding-inline-end: 0;
  padding-bottom: 24px;
  padding-inline-start: 0;
`;

const Name = styled.div<{ inactive?: boolean }>`
  font-size: 16px;
  color: ${({ inactive }) => (inactive ? "var(--colors-surface-600)" : "var(--colors-surface-900-p)")};
  letter-spacing: 0;
  line-height: 27px;
  font-weight: ${({ inactive }) => (inactive ? "400" : "500")};

  .small {
    font-weight: var(--typography-font-weight-default);
    font-size: var(--typography-font-size-default);
    line-height: 18px;
  }
`;

const Position = styled.div<{ inactive?: boolean }>`
  font-size: var(--typography-font-size-default);
  color: ${({ inactive }) => (inactive ? "var(--colors-surface-500)" : "var(--colors-activitiInfoBlockValue)")};
  letter-spacing: 0;

  .small {
    font-weight: var(--typography-font-weight-default);
    font-size: 12px;
    line-height: 15px;
    color: var(--colors-activitiInfoBlockValue);
  }
`;

const Info = styled.div`
  margin-inline-start: 15px;
`;

export type EmployeeInfo = {
  fullName: string;
  avatarId: string;
  position: string;
};

interface EmployeeInfoProps<T> {
  employeeInfo: T;
  small?: boolean;
  withBorder?: boolean;
  inactive?: boolean;
}

export default <T extends EmployeeInfo>({ employeeInfo, small, withBorder, inactive }: EmployeeInfoProps<T>) => (
  <Wrapper className="employee-info" withBorder={withBorder}>
    <Avatar
      user={{
        fullName: employeeInfo.fullName,
        avatarId: employeeInfo.avatarId,
      }}
      modifiers={{ big: !small }}
    />
    <Info>
      <Name inactive={inactive} className={small ? "small" : ""}>
        {employeeInfo.fullName}
      </Name>
      <Position inactive={inactive} className={small ? "small" : ""}>
        {employeeInfo.position}
      </Position>
    </Info>
  </Wrapper>
);
