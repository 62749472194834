import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";

const TextWrapper = styled.div`
  margin-top: 10px,  
  font-size: 16px,
  color: var(--colors-surface-900-p),
`;

type HoursBankNotificationRowProps = {
  prop: string;
};

const HoursBankNotificationRow = ({ prop }: HoursBankNotificationRowProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  return (
    <NotificationRow
      employeesPage
      withCloseButton={false}
      type={NotificationType.locked}
      style={{ marginTop: 0, marginBottom: "25px" }}
      message={
        <TextWrapper>
          {t(
            "Editing of this Rules Group is locked. For any changes please contact support or create a new set of rules.",
          )}
        </TextWrapper>
      }
    />
  );
};

export default HoursBankNotificationRow;
