import styled from "styled-components";
import * as images from "components/svg-images";

const TogglesWrapper = styled.div`
  --border-color: var(--cp-view-switch-border-color, var(--colors-surface-150));
  --active-border-color: var(--cp-view-switch-active-border-color, var(--colors-surface-150));
  --active-bg-color: var(--cp-view-switch-active-bg-color, var(--colors-surface-50));
  --icon-color: var(--cp-view-switch-icon-color, var(--colors-surface-800));
  --active-icon-color: var(--cp-view-switch-active-icon-color, var(--colors-surface-800));
  --control-height: var(--cp-control-height, 36px);
  display: flex;
  height: var(--control-height);
`;

const Toggle = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
  border: 1px solid var(--border-color);
  svg g {
    fill: var(--icon-color);
  }
  &:first-child {
    border-start-start-radius: 4px;
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: 4px;
    border-inline-end: none;
  }
  &:last-child {
    border-start-start-radius: 0;
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
    border-end-start-radius: 0;
    border-inline-start: none;
  }

  ${(props) =>
    props.active
      ? `
        svg g {
          fill: var(--active-icon-color);
        }
        border-color: var(--active-border-color);
        background: var(--active-bg-color)
      `
      : `
      cursor: pointer;
      &:hover {
        background: var(--colors-surface-50);
      }
  `};
`;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: var(--active-border-color);
`;

export enum PunchesTogglesType {
  editor = "editor",
  list = "list",
}

interface PunchesTogglesProps {
  active: PunchesTogglesType;
  onEditorClick?: () => void;
  onListClick?: () => void;
}

const PunchesToggles = ({ active, onListClick, onEditorClick }: PunchesTogglesProps) => (
  <TogglesWrapper>
    <Toggle active={active === PunchesTogglesType.editor} onClick={onEditorClick}>
      {images.punchesEditorIcon}
    </Toggle>
    <Separator />
    <Toggle active={active === PunchesTogglesType.list} onClick={onListClick}>
      {images.punchesListIcon}
    </Toggle>
  </TogglesWrapper>
);

export default PunchesToggles;
