import { Component, ContextType } from "react";
import moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import FullPage from "components/Layout/FullPage";
import NoContent from "components/NoContent";
import NotificationRow from "components/NotificationRow";
import SearchControl from "components/UI/SearchControlNew";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "components/UI/ModalDialog";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import GlobalContext from "context/global-context";
import { fireEvent, hasEmployeesAccess, urlParam } from "utils/common";
import { fireDownloadReport } from "utils/apiHelpers";
import { NotificationType, SearchObject } from "types/common";
import { ReportType, SetIsLoading, OnError } from "types/reports";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga from "utils/ga";
import { PermissionSectionName } from "types/models/permissions";
import { BillingService } from "components/Billing/BillingService";
import { Feature } from "types/models/subscription";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { getReportsPageTitle } from "./ReportsTitle";
import { Page, SearchControlWrapper } from "./reportsStyledComponents";
import ReportSearchFiltersBase from "./ReportSearchFiltersBase";
import ReportsDetailedTable from "./ReportsDetailedTable";
import ReportsDownloadAllDialog, { ReportForMultipleEmployees } from "./ReportsDownloadAllDialog";
import InProgressRow from "./InProgressRow";

const ContentSpacer = styled.div`
  height: 40px;
`;

const FiltersSpacer = styled.div`
  width: 12px;
`;

interface ReportsPageDetailsState {
  isGenerateButtonLocked: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  isReportVisible: boolean;
  key: number;
  notification: JSX.Element | string | null;
  notificationType: NotificationType;
  searchObj: SearchObject | null;
  generateAllReportOpen: boolean;
  generatingEmailForDownload: boolean;
  servicesSummaryAvailable: boolean;
  showServicesSummary: boolean;
}

type ReportsPageDetailsProps = WithTranslation;

class ReportsPageDetails extends Component<ReportsPageDetailsProps, ReportsPageDetailsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ReportsPageDetailsProps) {
    super(props);

    this.state = {
      isGenerateButtonLocked: hasEmployeesAccess(),
      generatingEmailForDownload: false,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      isReportVisible: false,
      key: new Date().getTime(),
      notification: null,
      notificationType: NotificationType.error,
      searchObj: hasEmployeesAccess()
        ? {}
        : {
            employee: {
              avatar_id: window.global_store.profile.avatar_id,
              full_name: window.global_store.profile.full_name,
              id: window.global_store.profile.id,
              job_description: window.global_store.profile.job_description,
              position_id: window.global_store.profile.position_id,
              uuid: window.global_store.profile.uuid,
            },
            id: window.global_store.profile.id,
            label: window.global_store.profile.name,
          },
      generateAllReportOpen: false,
      servicesSummaryAvailable: false,
      showServicesSummary: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const stateFromUrl = this.getStateFromUrlParms();

    let state = {
      servicesSummaryAvailable: BillingService.checkFeatureAccess(Feature.Project, false),
    };

    if (stateFromUrl) {
      state = { ...state, ...stateFromUrl };
      this.setState({ ...state }, (): void => {
        this.onGenerateButtonClick();
      });
    } else {
      const defaultPayPeriod = await this.context.getDefaultPayPeriod();

      if (defaultPayPeriod) {
        this.setState({
          startDate: moment(defaultPayPeriod.startDate),
          endDate: moment(defaultPayPeriod.endDate),
        });
      }
      this.setState({ ...state });
    }
  }

  getStateFromUrlParms = (): Partial<ReportsPageDetailsState> | null => {
    let state = null;
    const uuid = urlParam("uuid");
    const id = urlParam("id");
    const label = urlParam("label");
    const startDate = urlParam("startDate");
    const endDate = urlParam("endDate");

    if (uuid && id && label && startDate && endDate) {
      state = {
        startDate: moment(startDate, "YYYY-MM-DD"),
        endDate: moment(endDate, "YYYY-MM-DD"),
        searchObj: {
          id: Number(id),
          uuid,
          label,
          employee: {
            uuid,
          },
        },
      };
    }

    return state;
  };

  onGenerateButtonClick = (): void => {
    ga.trackGenerateReport(ReportType.detailed);

    this.setState({
      notification: null,
      isReportVisible: true,
      key: new Date().getTime(),
    });
  };

  onResetButtonClick = (): void => {
    this.setState({
      isGenerateButtonLocked: hasEmployeesAccess(),
      isReportVisible: false,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      notification: null,
    });
  };

  setIsLoading: SetIsLoading = (isLoading) => {
    this.setState({ isGenerateButtonLocked: isLoading });
  };

  onError: OnError = (message) => {
    this.setState({ isReportVisible: false, notification: message, notificationType: NotificationType.error });
  };

  onSearchChange = (searchObj: SearchObject | null): void => {
    this.setState({ searchObj, isGenerateButtonLocked: !searchObj });
  };

  generateDetailedReportForMultipleEmployees = async (params: ReportForMultipleEmployees): Promise<void> => {
    const { t } = this.props;
    const { format, searchObj, startDate, endDate, onSamePage, showInactive, skipSupervisors, selectedColumns } =
      params.body;

    try {
      const response = await fireDownloadReport({
        format: format || "pdf",
        selectedColumns,
        searchObj,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        onSamePage,
        showInactiveEmploees: showInactive,
        skipSupervisors,
        byEmail: true,
        reportType: ReportType.detailed,
      });

      void this.context.addToDownloads(response.report_uuid);
      this.setState({
        generateAllReportOpen: false,
        generatingEmailForDownload: true,
        notification: null,
      });
    } catch (err) {
      this.setState({
        notification: `${t("Failed to run generation on detailed report for multiple emloyees")}`,
        notificationType: NotificationType.error,
      });
    }
  };

  updateRecalculatingMessage = (
    recalculatingInProgress: boolean,
    customMessage?: Record<"title" | "text" | "lastUpdatedTime", string>,
  ): void => {
    fireEvent("reports_inProgress", { recalculatingInProgress, customMessage });
  };

  render(): JSX.Element {
    const { t } = this.props;
    let { notification, notificationType } = this.state;

    const {
      startDate,
      endDate,
      isGenerateButtonLocked,
      isReportVisible,
      key,
      generatingEmailForDownload,
      searchObj,
      servicesSummaryAvailable,
      showServicesSummary,
    } = this.state;

    const reportParams = {
      searchObj,
      startDate,
      endDate,
      showServicesSummary,
    };

    if (generatingEmailForDownload) {
      notificationType = NotificationType.progress;
      notification = (
        <>
          <div>{t("We're still updating. You'll receive an email as soon as you're done.")}</div>
        </>
      );
    }

    return (
      <FullPage
        headerAction={
          hasEmployeesAccess() ? (
            <Button
              state={ButtonState.outline}
              value={t("Generate reports from all")}
              onClick={(): void => {
                this.setState({ generateAllReportOpen: true });
              }}
            />
          ) : null
        }
        title={getReportsPageTitle(t, ReportType.detailed)}
      >
        <Page>
          <InProgressRow t={t} onRefresh={this.onGenerateButtonClick} />

          <ReportSearchFiltersBase
            onGenerate={this.onGenerateButtonClick}
            generateButtonDisabled={isGenerateButtonLocked}
            onReset={this.onResetButtonClick}
          >
            {hasEmployeesAccess() && (
              <SearchControlWrapper>
                <SearchControl
                  permissionSection={PermissionSectionName.basicReports}
                  onChange={this.onSearchChange}
                  onClear={(): void => this.setState({ searchObj: null })}
                  placeholder={translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-search-employees",
                    `${TranslationNamespaces.common}|Search Employee`,
                  )}
                />
              </SearchControlWrapper>
            )}
            {servicesSummaryAvailable && (
              <>
                <AdditionalFiltersControl
                  options={[
                    {
                      label: t("servicesSummary"),
                      checked: showServicesSummary,
                      onChange: (value: boolean): void => {
                        this.setState({ showServicesSummary: value });
                      },
                    },
                  ]}
                />
                {!hasEmployeesAccess() && <FiltersSpacer />}
              </>
            )}
            <DateRangePicker
              newOnChangeApproach
              availableDaysCount={window.global_store.beta ? 1900 : 186}
              isAdmin={hasEmployeesAccess()}
              onChange={(sd, ed): void => {
                if (sd && ed) {
                  this.setState({ startDate: sd, endDate: ed });
                }
              }}
              startDate={startDate}
              endDate={endDate}
            />
          </ReportSearchFiltersBase>
          {notification && <NotificationRow withCloseButton={false} type={notificationType} message={notification} />}
          <ContentSpacer />
          {isReportVisible ? (
            <ReportsDetailedTable
              {...reportParams}
              key={key}
              setIsLoading={this.setIsLoading}
              onError={this.onError}
              updateRecalculatingMessage={this.updateRecalculatingMessage}
            />
          ) : (
            <NoContent>{t("Select your report type")}</NoContent>
          )}
          <ModalDialog
            isOpen={this.state.generateAllReportOpen}
            onClose={(): void => this.setState({ generateAllReportOpen: false })}
          >
            <ReportsDownloadAllDialog
              onClose={(): void => this.setState({ generateAllReportOpen: false })}
              onYes={this.generateDetailedReportForMultipleEmployees}
              reportType={ReportType.detailed}
            />
          </ModalDialog>
        </Page>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsPageDetails);
