import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { urlParam, hasPermisionAccess, PermissionSectionName } from "utils/common";
import PunchesPage from "components/Punches/PunchesPage";
import SuperpunchPage from "components/Superpunch/SuperpunchPage";
import PunchSettingsPage from "components/Punches/PunchSettingsPage";
import EmployeesSuperpunchPage from "components/SuperpunchGroups/EmployeesSuperpunchPage";
import GroupsSuperpunchPage from "components/SuperpunchGroups/GroupsSuperpunchPage";
import { AggregationGroupType } from "types/models/dashboard";

const RoutePunches = ({ match: { path }, location: { search } }: RouteComponentProps): JSX.Element => {
  const hasSuperpunchPermission = hasPermisionAccess(PermissionSectionName.superpunch);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route strict path={`${path}/settings`} render={(): JSX.Element => <PunchSettingsPage />} />

        {hasSuperpunchPermission && urlParam("type") === "edit" && urlParam("uuid") && urlParam("id") && (
          <Route
            path={`${path}/`}
            render={(props): JSX.Element => (
              <SuperpunchPage
                uuid={urlParam("uuid") as string}
                id={urlParam("id") as string}
                label={urlParam("label") || null}
                startDate={urlParam("startDate") || null}
                endDate={urlParam("endDate") || null}
                showOnlyIssues={urlParam("showOnlyIssues") || null}
                directReportsOnly={urlParam("directReportsOnly") || null}
                {...props}
              />
            )}
          />
        )}

        {hasSuperpunchPermission && urlParam("type") === "group-edit" && (
          <Route
            path={`${path}/`}
            render={(props) => {
              const dates = {
                startDate: urlParam("startDate") || moment().startOf("month").format("YYYY-MM-DD"),
                endDate: urlParam("endDate") || moment().endOf("month").format("YYYY-MM-DD"),
              };

              if (urlParam("group_uuid")) {
                return (
                  <EmployeesSuperpunchPage
                    groupUuid={urlParam("group_uuid") as string}
                    groupType={urlParam("group_type") as Exclude<AggregationGroupType, "userProfile" | "company">}
                    searchValue={urlParam("search_value") as string}
                    // this param is for back button
                    previousGroup={urlParam("previous_group") || ("" as string)}
                    {...dates}
                    {...props}
                  />
                );
              }

              const groupType = (urlParam("group_type") || AggregationGroupType.departments) as Exclude<
                AggregationGroupType,
                "userProfile" | "company"
              >;

              return <GroupsSuperpunchPage groupType={groupType} {...dates} {...props} />;
            }}
          />
        )}

        <Redirect strict from={`${path}/:punchId/`} to={`${path}/:punchId`} />

        <Redirect exact strict from={`${path}${search}`} to={`${path}/${search}`} />

        <Route
          path={`${path}/`}
          render={(props): JSX.Element => (
            <PunchesPage
              uuid={urlParam("uuid") || null}
              id={urlParam("id") || null}
              label={urlParam("label") || null}
              startDate={urlParam("startDate") || null}
              endDate={urlParam("endDate") || null}
              {...props}
            />
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default RoutePunches;
