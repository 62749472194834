import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BillingService } from "components/Billing/BillingService";
import { Feature, SubscriptionStatus } from "types/models/subscription";
import { PermissionSectionName, hasPermisionAccess, hasProjectsAccess } from "utils/common";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { paramsToUrlParams } from "utils/apiHelpers";
import { GlobalContext } from "context/GlobalContextProvider";
import { AggregationGroupType } from "types/models/dashboard";
import { LocalStorageKeys, getObjectFromLocalStorage } from "utils/localStorageUtils";
import * as Icons from "./menu-svg-icons";
import ReportsSubMenu from "./ReportsSubmenu";
import DashboardsSubMenu from "./DashboardsSubMenu";
import NavigationMenuItem from "./NavigationMenuItem";

const Wrapper = styled.div<{ $isCollapsedPermanently: boolean }>`
  height: 100%;
  width: ${(p) => (p.$isCollapsedPermanently ? "56" : "240")}px;
  transition: width 300ms ease-out;
`;

const MenuWrapper = styled.div<{ $isCollapsed: boolean }>`
  position: fixed;
  top: 55px;
  bottom: 0;
  width: ${(p) => (p.$isCollapsed ? "56" : "240")}px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--colors-theme-tr-brdr);
  border-inline-end: 1px solid var(--colors-theme-nm-brdr);
  background: var(--colors-theme-nm-bg);
  z-index: 110;
  transition: width 300ms ease-out;

  &:hover {
    border-inline-end-color: transparent;

    .collapse-button {
      opacity: 0.4;
    }
  }
`;

const Menu = styled.div`
  height: calc(100% - 60px); // footer height
  padding: 6px 12px 12px;
  overflow-x: hidden;
  overflow-y: overlay;
`;

const Section = styled.div<{ $isCollapsed: boolean }>`
  padding: 4px ${(p) => (p.$isCollapsed ? "0" : "6")}px 8px;
  transition: padding 300ms ease-out;

  .section-name {
    opacity: ${(p) => (p.$isCollapsed ? "0" : "1")};
    transition: opacity 300ms ease-out;
    height: 32px;
    padding: 0 6px;
    line-height: 32px;
    font-size: 13px;
    color: var(--colors-theme-nm-section-name-color);
    user-select: none;
  }
`;

const Footer = styled.div<{ isCollapsed: boolean }>`
  height: 68px;
  width: ${(p) => (p.isCollapsed ? "55" : "239")}px;
  padding: 12px;
  background: var(--colors-theme-nm-bg);
  transition: width 300ms ease-out;

  div {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 12px ${(p) => (p.isCollapsed ? "6" : "12")}px;
    transition: padding 300ms ease-out;
    border-radius: var(--shapes-border-radius-default);
    background: #9649cb;
    cursor: pointer;

    &:hover {
      background: var(--colors-theme-nm-upgrade-btn-bg-hvr);
    }

    span {
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-medium);
      opacity: ${(p) => (p.isCollapsed ? "0" : "1")};
      transition: opacity 300ms ease-out;
      white-space: nowrap;
      color: var(--colors-surface-0);
    }
  }

  svg {
    flex-shrink: 0;
  }
`;

const CollapseButtonWrapper = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: -10px;
  width: 10px;
  z-index: 14;
  display: flex;
  align-items: center;
  border-inline-start: 2px solid var(--colors-theme-nm-collapse-brdr);
  background: transparent;
  transition: opacity 300ms ease-out;

  &:hover {
    opacity: 1 !important;
  }
`;

const CollapseButton = styled.div<{ isCollapsedPermanently: boolean }>`
  position: relative;
  height: 24px;
  width: 8px;
  border-radius: 0px 4px 4px 0px;
  background: var(--colors-theme-nm-collapse-btn-bg);
  cursor: pointer;

  svg {
    position: absolute;
    top: 2px;
    inset-inline-start: -7px;
    transform: rotate(${(p) => (p.isCollapsedPermanently ? "270" : "90")}deg);
  }
`;

const IS_COLLAPSED_LS_KEY = "oi_is_menu_collapsed";

export type MenuItem = {
  icon: JSX.Element;
  name: string;
  paths: { path: string; hasAccess?: () => boolean }[];
  show: boolean;
  subMenu?: JSX.Element;
  pathRegex: RegExp;
};

type MenuSection = {
  name: string | null;
  items: MenuItem[];
};

interface NavigationMenuProps extends WithTranslation, AllFlagsLDClient, RouteComponentProps {}

interface NavigationMenuState {
  isCollapsed: boolean;
  isCollapsedPermanently: boolean;
  initialDiscountApplicable: boolean;
}
class NavigationMenu extends Component<NavigationMenuProps, NavigationMenuState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<NavigationMenuState> = {
    isCollapsed: localStorage.getItem(IS_COLLAPSED_LS_KEY) === "true",
    isCollapsedPermanently: localStorage.getItem(IS_COLLAPSED_LS_KEY) === "true",
    initialDiscountApplicable: false,
  };

  async componentDidMount() {
    const [company] = await Promise.all([this.context.getCompany()]);

    const initialDiscountApplicable = await BillingService.isInitialDiscountApplicable(
      company.employee_count?.toString() as string,
    );

    this.setState({ initialDiscountApplicable });
  }

  setIsCollapsedPermanently = (isCollapsedPermanently: boolean) => {
    localStorage.setItem(IS_COLLAPSED_LS_KEY, isCollapsedPermanently.toString());

    this.setState({ isCollapsedPermanently, isCollapsed: isCollapsedPermanently });
  };

  onHover = (enter: boolean) => {
    const { isCollapsedPermanently } = this.state;
    if (isCollapsedPermanently) {
      this.setState({ isCollapsed: !enter });
    }
  };

  getSuperpunchPath = () => {
    const { full_name: fullName, id, uuid, teams } = window.global_store?.profile || {};
    let path = "";

    const hasSuperpunchPermission = hasPermisionAccess(PermissionSectionName.superpunch);

    if (hasSuperpunchPermission && fullName && id && uuid) {
      let params: Record<string, string | number> = {};

      params = {
        type: "group-edit",
        group_type: AggregationGroupType.teams,
      };

      // supervisor
      const team = teams[0];
      if (team) {
        params.group_uuid = team.uuid;
        params.search_value = team.name;
      }

      path = `/punches${paramsToUrlParams(params)}`;
    }

    return path;
  };

  /**  find the first path user has access to */
  getPath = (paths: MenuItem["paths"]) => paths.find((p) => !!p.hasAccess?.())?.path || null;

  getMenuSections = () => {
    const { flags } = this.props;
    const sections: MenuSection[] = [];
    let superpunchPath = "";

    if (BillingService.checkFeatureAccess(Feature.TA)) {
      superpunchPath = this.getSuperpunchPath();
    }
    const showDashboardsSubMenu = !!getObjectFromLocalStorage(LocalStorageKeys.companyDashboards)?.length;

    sections.push({
      name: null,
      items: [
        {
          name: "Attendance",
          icon: <Icons.Attendace />,

          paths: [
            { path: superpunchPath, hasAccess: () => !!superpunchPath },
            {
              path: "/punches",
              hasAccess: () =>
                hasPermisionAccess(PermissionSectionName.viewPunches) ||
                hasPermisionAccess(PermissionSectionName.managePunches),
            },
            {
              path: "/on-calls/",
              hasAccess: () =>
                hasPermisionAccess(PermissionSectionName.onCallManagement) ||
                hasPermisionAccess(PermissionSectionName.onCallRequest),
            },
          ],
          show: BillingService.checkFeatureAccess(Feature.TA),
          pathRegex: /^\/(punches|on-calls)/,
        },
        {
          name: "Time",
          icon: <Icons.Time />,
          paths: [
            { path: "/activities/calendar", hasAccess: () => true },
            { path: "/timesheets", hasAccess: () => true },
            { path: "/activities", hasAccess: () => true },
            { path: "/timesheets/list", hasAccess: () => true },
          ],
          show:
            BillingService.checkFeatureAccess(Feature.Project) && hasPermisionAccess(PermissionSectionName.activities),
          pathRegex: /^\/(timesheets|activities)/,
        },
        {
          name: "Absence & Vacation",
          icon: <Icons.Absence />,
          paths: [{ path: "/requests", hasAccess: () => true }],
          show: BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.requests),
          pathRegex: /^\/request/,
        },
        {
          name: "Dashboards",
          icon: <Icons.Dashboards />,
          paths: [{ path: "/dashboard", hasAccess: () => showDashboardsSubMenu }],
          show: BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.dashboard),
          subMenu: showDashboardsSubMenu && <DashboardsSubMenu />,
          pathRegex: /^\/dashboard/,
        },
        {
          name: "Reports",
          icon: <Icons.Reports />,
          paths: [{ path: "#", hasAccess: () => true }],
          show:
            hasPermisionAccess(PermissionSectionName.basicReports) ||
            hasPermisionAccess(PermissionSectionName.advancedReports),
          subMenu: <ReportsSubMenu />,
          pathRegex: /^\/reports/,
        },
        {
          name: "AI Insights",
          icon: <Icons.Aiinsights />,
          paths: [{ path: "#", hasAccess: () => true }],
          show: false,
          pathRegex: /^\//,
        }, // TODO path, pathRegex
      ],
    });

    sections.push({
      name: "Manage",
      items: [
        {
          name: "People",
          icon: <Icons.People />,
          paths: [{ path: "/employees", hasAccess: () => true }],
          show: hasPermisionAccess(PermissionSectionName.employees),
          pathRegex: /^\/employees/,
        },
        {
          name: "Projects",
          icon: <Icons.Projects />,
          paths: [{ path: "/projects", hasAccess: () => true }],
          show: BillingService.checkFeatureAccess(Feature.Project) && hasProjectsAccess(),
          pathRegex: /^\/projects/,
        },
        {
          name: "Clients",
          icon: <Icons.Clients />,
          paths: [{ path: "/company/clients", hasAccess: () => true }],
          show: hasPermisionAccess(PermissionSectionName.clients),
          pathRegex: /^\/company\/clients/,
        },
        {
          name: "Services",
          icon: <Icons.Services />,
          paths: [{ path: "/services", hasAccess: () => true }],
          show: BillingService.checkFeatureAccess(Feature.Project) && hasProjectsAccess(),
          pathRegex: /^\/services/,
        },
        {
          name: "Payroll",
          icon: <Icons.Payroll />,
          paths: [
            { path: "/payroll/payroll-groups", hasAccess: () => true },
            { path: "/payroll/lock-periods", hasAccess: () => true },
            { path: "/payroll/lock-periods-dectivated-employees", hasAccess: () => true },
            { path: "/payroll/digital-signatures", hasAccess: () => true },
            { path: "/payroll/export", hasAccess: () => true },
          ],
          show: BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.payroll),
          pathRegex: /^\/payroll/,
        },
      ],
    });

    sections.push({
      name: "Configure",
      items: [
        {
          name: "Schedules",
          icon: <Icons.Schedules />,
          paths: [{ path: "/schedules", hasAccess: () => true }],
          show: BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.schedules),
          pathRegex: /^\/schedules/,
        },
        {
          name: "Business Rules",
          icon: <Icons.BusinessRules />,
          paths: [{ path: "/company/groups-of-rules", hasAccess: () => true }],
          show:
            BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.rulesAndLimits),
          pathRegex: /^\/company\/groups-of-rules/,
        },
        {
          name: "Locations",
          icon: <Icons.Locations />,
          paths: [
            { path: "/company/locations", hasAccess: () => hasPermisionAccess(PermissionSectionName.locations) },
            { path: "/company/job-sites", hasAccess: () => hasPermisionAccess(PermissionSectionName.jobSides) },
          ],
          show: true,
          pathRegex: /^\/company\/(locations|job-sites)/,
        },
        {
          name: "Company Structure",
          icon: <Icons.CompanyStructure />,
          paths: [
            { path: "/company/subsidiaries/", hasAccess: () => hasPermisionAccess(PermissionSectionName.subsidiaries) },
            { path: "/company/departments/", hasAccess: () => hasPermisionAccess(PermissionSectionName.departments) },
            { path: "/company/teams/", hasAccess: () => hasPermisionAccess(PermissionSectionName.teams) },
            { path: "/company/positions/", hasAccess: () => hasPermisionAccess(PermissionSectionName.positions) },
          ],
          show: true,
          pathRegex: /^\/company\/(subsidiaries|departments|teams|positions)/,
        },
        {
          name: "Holidays",
          icon: <Icons.Holidays />,
          paths: [{ path: "/company/holidays/", hasAccess: () => true }],
          show: BillingService.checkFeatureAccess(Feature.TA) && hasPermisionAccess(PermissionSectionName.holidays),
          pathRegex: /^\/company\/holidays/,
        },
      ],
    });

    return sections;
  };

  /** same as UpgradePlanBtn */
  canSeeUpgradeButton = () => {
    const subscriptions = localStorage.getItem("gs_subscriptions");
    if (!subscriptions) {
      return false;
    }

    const isAdmin = BillingService.checkIsCompanyAdmin();
    const hideDate = localStorage.getItem("gs_hide_initial_discount");
    const trialSubscription = BillingService.findSubscriptions(JSON.parse(subscriptions), SubscriptionStatus.Trialing);

    if (
      !isAdmin ||
      trialSubscription ||
      BillingService.isEnterpriseCustomer() ||
      (this.state.initialDiscountApplicable &&
        (!hideDate || moment(hideDate).add(1, "day").isSameOrBefore(moment(), "day")))
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { history, t } = this.props;
    const { isCollapsed, isCollapsedPermanently } = this.state;

    return (
      <Wrapper $isCollapsedPermanently={isCollapsedPermanently} className="navigation-menu">
        <MenuWrapper
          $isCollapsed={isCollapsed}
          onMouseEnter={() => this.onHover(true)}
          onMouseLeave={() => this.onHover(false)}
        >
          <Menu>
            {this.getMenuSections().map((s) =>
              s.items.filter((i) => i.show && this.getPath(i.paths)).length ? (
                <Section $isCollapsed={isCollapsed} key={s.name}>
                  {s.name && <div className="section-name">{t(s.name)}</div>}

                  {s.items.map((item) => {
                    const path = this.getPath(item.paths);

                    return (
                      item.show &&
                      path && <NavigationMenuItem item={item} key={item.name} path={path} isCollapsed={isCollapsed} />
                    );
                  })}
                </Section>
              ) : null,
            )}
          </Menu>

          <Footer isCollapsed={isCollapsed}>
            {this.canSeeUpgradeButton() && (
              <div onClick={() => history.push("/billing/pricing")}>
                <Icons.UpgradeNow />
                <span>{t("Upgrade Now")}</span>
              </div>
            )}
          </Footer>

          <CollapseButtonWrapper className="collapse-button">
            <CollapseButton
              isCollapsedPermanently={isCollapsedPermanently}
              onClick={() => this.setIsCollapsedPermanently(!isCollapsedPermanently)}
            >
              <Icons.ArrowDown />
            </CollapseButton>
          </CollapseButtonWrapper>
        </MenuWrapper>
      </Wrapper>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation(TranslationNamespaces.sidebar)(NavigationMenu)));
