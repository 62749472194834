import { TimesheetApprovalStatus } from "components/Timesheets/timesheet.types";
import moment from "moment-timezone";
import { minsToHrsMins } from "utils/common";
import { ReactComponent as AttachmentIcon } from "../icons/attachment.svg";
import { ReactComponent as NoteIcon } from "../icons/note.svg";
import { ReactComponent as ApproveIcon } from "../icons/approve.svg";
import { ReactComponent as GoogleCalendarIcon } from "../icons/google-calendar.svg";

export enum EventType {
  Activity = "activity",
  Off = "off",
  Punch = "punch",
  OnCall = "on-call",
  GoogleEvent = "google-event",
}

type EventProps = {
  title: string;
  description?: string;
  time: string; // todo decide maybe number
  attachments: any[];
  customFields: any[];

  allDay?: boolean;
  original: {
    entity: any;
  };
  position: {
    height: string;
  }
  startDate: {m: moment.Moment};
  endDate: {m: moment.Moment};
  type: EventType;
  activeId?: string;
  id: string;
  locked?: boolean;
};

const getEventContainerSize = (height: string, subtractHeight = 28) => {
  const parent = document.querySelector(`.mbsc-schedule-events`);
  const clientHeight = Math.round((parent?.clientHeight ?? 0) * (+height.replace('%', '') / 100))
  const numberOfTruncatedLines = Math.floor((clientHeight - subtractHeight) / 16);
  return {clientHeight, numberOfTruncatedLines};
}

const ActivityEvent = (props: EventProps) => {
  const {clientHeight, numberOfTruncatedLines} = getEventContainerSize(props.position.height);
  const swappedLines = (props.description && clientHeight > 70 ? Math.round((clientHeight - 74) / 16) || 1: 0)
  const titleRows = Math.abs(numberOfTruncatedLines - Math.round((swappedLines) / 2)) || 1;
  const descriptionRows = Math.round(swappedLines / 2) || 1;
  return (
    <div className={`ActivityEvent ${props.activeId === props.id ? "active" : ""} ${props.locked ? "locked" : ""}`}>
      <div className="title" style={{ display: clientHeight && clientHeight > 24 ? "-webkit-box" : "none", WebkitLineClamp: titleRows}}>
        {props.title}
      </div>
      {props.description && clientHeight && clientHeight > 70 ? (
        <span className="description" style={{WebkitLineClamp: descriptionRows}}>
          {props.description}
        </span>
      ) : null}
      <div className="footer" style={{ display: clientHeight && clientHeight > 40 ? "flex" : "none" }}>
        <div className="time">{props.time}</div>
        {props.original.entity.customFields?.length > 0 ? <NoteIcon /> : null}
        {props.original.entity.attachments?.length > 0 ? <AttachmentIcon /> : null}
      </div>
    </div>
  );
};
const OffEvent = (props: EventProps) => (
  <div className={`OffEvent ${props.allDay ? "allDay" : ""} ${props.activeId === props.id ? "active" : ""}`}>
    <div className="title">{props.title}</div>
    {props.description && !props.allDay ? <div className="description">{props.description}</div> : null}
    {props.original.entity.approvalStatus === TimesheetApprovalStatus.approved ? (
      <ApproveIcon className="approved-time-icon" />
    ) : null}
    {!props.allDay ? (
      <div className="footer">
        <div className="time">{props.time}</div>
      </div>
    ) : null}
  </div>
);

const NewEvent = (props: EventProps) => {
  let { time } = props;

  if (/NaN/gi.test(props.time)) {
    const timeInMinutes = moment(props.endDate.m, "HH:mm a").diff(moment(props.startDate.m, "HH:mm a"), "minutes");
    time = minsToHrsMins(timeInMinutes, true);
  }

  return (
    <div className="NewEvent">
      <div className="time">{time}</div>
    </div>
  );
};

const OnCallEvent = (props: EventProps) => (
  <div className={`OnCallEvent ${props.allDay ? "allDay" : ""} ${props.activeId === props.id ? "active" : ""}`}>
    <div className="title">{props.title}</div>
    {props.original.entity.approvalStatus === TimesheetApprovalStatus.approved ? (
      <ApproveIcon className="approved-time-icon" />
    ) : null}
    {!props.allDay ? (
      <div className="footer">
        <div className="time">{props.time}</div>
      </div>
    ) : null}
  </div>
);

const GoogleEvent = (props: EventProps) => {
  const {clientHeight, numberOfTruncatedLines} = getEventContainerSize(props.position.height, 14);
  return (
    <div className={`GoogleEvent ${props.allDay ? "allDay" : ""} ${props.activeId === props.id ? "active" : ""}`}>
      <div className="row" style={{ display: clientHeight && clientHeight > 24 ? "flex" : "none" }}>
        <div className="title" style={{ display: clientHeight && clientHeight > 24 ? "-webkit-box" : "none", WebkitLineClamp: numberOfTruncatedLines || 1}}>{props.title}</div>
        <GoogleCalendarIcon className="icon" />
      </div>
      {!props.allDay ? (
        <div className="footer" style={{ display: clientHeight && clientHeight > 40 ? "flex" : "none" }}>
          <div className="time">{props.time}</div>
        </div>
      ) : null}
    </div>
  );
};

export const EventRenderer = (props: EventProps) => {
  switch (props.type) {
    case EventType.Activity:
      return <ActivityEvent {...props} />;
    case EventType.Off:
      return <OffEvent {...props} />;
    case EventType.OnCall:
      return <OnCallEvent {...props} />;
    case EventType.GoogleEvent:
      return <GoogleEvent {...props} />;
    default:
      return <NewEvent {...props} />;
  }
};
