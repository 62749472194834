import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";
import moment from "moment";
import { Application } from "types/models/application";
import { DailySummaryReport } from "types/models/superpunch";
import { EnableDayEventData } from "context/SuperpunchProvider/types";
import { ClientReportsType, ReportType } from "types/reports";
import { RequestTypeName } from "types/models/request";
import { BusinessRuleGroup } from "types/models/businessRulesGroup";
import { getLocale } from "utils/translationHelpers";
import { APIEmptyResponse, APIResponse, QueryParams, getHeaders, paramsToUrlParams, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  AssignUserToBRGroupRequestData,
  AssociateDeviceSettingsToEmployeesRequestData,
  AssociateLocationsToEmployeesRequestData,
  CheckUserProfileExistsInCompanyRequestData,
  CheckUserProfileExistsInCompanyResponseData,
  CreateCompanyRequestData,
  CreateCompanyResponseData,
  CreateLockGroupRequestData,
  CreateLockGroupResponseData,
  CreateLockRequestData,
  CreateLockResponseData,
  CreateOnCallRequestData,
  CreateOnCallResponseData,
  DeleteOnCallRequestData,
  RequestTypeCreateRequestData,
  RequestTypeDeleteRequestData,
  GenerateIntegrationCredsResponseData,
  GenerateIntegrationTmpCodeRequestData,
  GenerateIntegrationTmpCodeResponseData,
  GetBRGroupRequestData,
  GetBRGroupResponseData,
  GetCompanyResponseData,
  GetDefaultLockPeriodsRequestData,
  GetDefaultLockPeriodsResponseData,
  GetEmbeddedUrlRequestData,
  GetEmbeddedUrlResponseData,
  GetEmployeesRequestData,
  GetEmployeesResponseData,
  GetHoursBankBalanceRequestData,
  GetHoursBankBalanceResponseData,
  GetIndustryTypesResponseData,
  GetLockGroupListRequestData,
  GetLockGroupListResponseData,
  GetLockGroupRequestData,
  GetLockGroupResponseData,
  GetLockPeriodsForReportsRequestData,
  GetLockPeriodsForReportsResponseData,
  GetLocksListRequestData,
  GetLocksListResponseData,
  GetNewReportResponseData,
  GetOldReportResponseData,
  GetOmCallsRequestData,
  GetOmCallsResponseData,
  GetOperationsRequestData,
  GetOperationsResponseData,
  GetPayrollLayoutsRequestData,
  GetPayrollLayoutsResponseData,
  GetPeriodAggregationRequestData,
  GetPeriodAggregationResponseData,
  GetReportRequestData,
  GetRequestByUuidRequestData,
  GetRequestByUuidResponseData,
  GetRequestsRequestData,
  GetRequestsResponseData,
  GetRequestTypesRequestData,
  GetRequestTypesResponseData,
  GetSchedulesRequestData,
  GetSchedulesResponseData,
  GetSuperpunchGroupsRequestData,
  GetSuperpunchGroupsResponseData,
  GetSuperpunchReportRequestData,
  GetSuperpunchReportResponseData,
  GetUserBRGroupsRequestData,
  GetUserBRGroupsResponseData,
  IsPhoneNumberExistOnboardingResponseData,
  IsPhoneNumberExistRequestData,
  MakeDefaultPayrollGroupRequestData,
  PayrollLockForEmployeesRequestData,
  PayrollLockForEmployeesResponseData,
  RequestBulkChangeStatusRequestData,
  RequestBulkChangeStatusResponseData,
  RequestDigitalSignaturesRequestData,
  RequestDigitalSignaturesResponseData,
  RequestSubTypeDeleteRequestData,
  RequestSubTypeUpdateRequestData,
  RequestTypeToggleRequestData,
  RevealIntegrationSecretRequestData,
  RevealIntegrationSecretResponseData,
  SearchEmployeesAndGroupsRequestData,
  SearchEmployeesAndGroupsResponseData,
  ToggleGroupStatusRequestData,
  UpdateBRGroupRequestData,
  UpdateBRGroupSettingsRequestData,
  UpdateCompanyRequestData,
  UpdateCompanyResponseData,
  UpdateLockGroupRequestData,
  UpdateOnCallRequestData,
  UploadRequestAttachmentRequest,
  UploadRequestAttachmentResponse,
  GetShiftCompilationsInRangeV2RequestData,
  GetShiftCompilationsInRangeV2ResponseData,
  GetDeactivatedLocksListResponseData,
  GetDeactivatedLocksListRequestData,
  BulkPayrollLocksRequestData,
  BulkSendToPayrollRequestData,
} from "./types";
import { FilteredEmployeePayload, FilteredEmployeeProfile } from "../../types/models/userProfile";
import { DigitalSignatureResponse } from "../../components/Payroll/types";

// TODO change this to proper types
type TODORequestData = never;
type TODOResponseData = Promise<unknown>;

// TODO split file
export function createCompany(data: CreateCompanyRequestData): APIResponse<CreateCompanyResponseData> {
  return sendRequest(
    {
      company: {
        name: data.companyName,
        company_type: data.companyType,
        company_legal_detail_attributes: {
          industry_type_id: data.categoryId,
          legal_name: data.companyName,
        },
        employee_count: data.employeesCount,
        default_location_attributes: {
          name: data.companyName,
          code: "01",
          address_attributes: data.address,
        },
        referral_code: data.refCode,
        locale: data.locale,
      },
    },
    "post",
    "/company",
  );
}

export function updateCompany(data: UpdateCompanyRequestData): APIResponse<UpdateCompanyResponseData> {
  return sendRequest(data.body, "PUT", "/company");
}

export function isPhoneNumberExist(
  data: IsPhoneNumberExistRequestData,
): APIResponse<IsPhoneNumberExistOnboardingResponseData> {
  if (data.isOnboarding) {
    return sendRequest({}, "get", `/devices/phone_number_busy?phone_number=+${data.phoneNumber}`);
  }
  return sendRequest({}, "get", `/company/phone_number_exist?phone_number=+${data.phoneNumber}`);
}

export function isEmailTaken(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "get", `/company/check_email?email=${data.email}`);
}

export function checkForExistingUserProfilesWithinCompany(
  data: CheckUserProfileExistsInCompanyRequestData,
): APIResponse<CheckUserProfileExistsInCompanyResponseData> {
  return sendRequest(data.body, "POST", "/company/check_profiles");
}

export function getIndustryTypes(): APIResponse<GetIndustryTypesResponseData> {
  return sendRequest({}, "get", "/company/industry_types");
}

export async function getCurrentCompany(force = false): APIResponse<GetCompanyResponseData | false> {
  try {
    if (window.global_store.company && !force) {
      return window.global_store.company;
    }

    return await sendRequest({}, "get", "/company");
  } catch (error) {
    return false;
  }
}

export function getEmployees(data: GetEmployeesRequestData): APIResponse<GetEmployeesResponseData> {
  let params = "";

  if (data.args) {
    params = `?${new URLSearchParams(data.args).toString()}`;
  }

  return sendRequest({}, "get", `/companies/${data.companyUuid}/user_profiles${params}`, CONFIG.client_admin_api);
}

export function searchEmployeesAndGroups(
  data: SearchEmployeesAndGroupsRequestData,
): APIResponse<SearchEmployeesAndGroupsResponseData> {
  const params = new URLSearchParams(data);

  return sendRequest({}, "GET", `/company/search?${params}`);
}

export function updateShiftCompilationsSP(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PATCH",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/shift_compilations?requested_by=${data.requestedBy}&source=SUPER_PUNCH`,
    CONFIG.client_admin_api,
  );
}

export function addPunchSP(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/punches?requested_by=${
      data.requestedBy
    }&date=${data.date.format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

export function validatePunchSP(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "POST",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/punches/${
      data.punchUuid
    }/validate?requested_by=${data.requestedBy}&date=${data.date.format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

export function declinePunchSP(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/punches/${
      data.punchUuid
    }/decline?requested_by=${data.requestedBy}&date=${data.date.format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

export function getRequestsList(data: GetRequestsRequestData): APIResponse<GetRequestsResponseData> {
  const typesToInclude = [RequestTypeName.overtime, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment];

  const params = paramsToUrlParams({
    ...data.params,
    types_to_include: typesToInclude.join(","),
  });

  return sendRequest(
    undefined,
    "GET",
    `/companies/${data.companyUUID}/requests/user_profiles/${data.userProfileUuid}${params}`,
    CONFIG.client_admin_api,
  );
}

export function getRequestByUuid(data: GetRequestByUuidRequestData): APIResponse<GetRequestByUuidResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUUID}/requests/${data.requestUuid}`, CONFIG.client_admin_api);
}

export function createSingleRequest(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUUID}/requests`, CONFIG.client_admin_api);
}

export function createRequest(data: TODORequestData): TODOResponseData {
  const params = paramsToUrlParams(data.params);
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/requests/bulk${params}`,
    CONFIG.client_admin_api,
  );
}

export function getVacationBalance(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "GET",
    `/companies/${data.companyUUID}/requests/user_profiles/${data.userProfileUuid}/vacations_balance?requested_by=${data.requestedBy}&from=${data.from}&to=${data.to}`,
    CONFIG.client_admin_api,
  );
}

export function getHoursBankBalance(
  data: GetHoursBankBalanceRequestData,
): APIResponse<GetHoursBankBalanceResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUUID}/user_profiles/${data.userProfileUuid}/hours_bank/${data.date.format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

export function requestsBulkChangeStatus(
  data: RequestBulkChangeStatusRequestData,
): APIResponse<RequestBulkChangeStatusResponseData> {
  const params = data.params ? paramsToUrlParams(data.params) : "";

  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/requests/bulk_status${params}`,
    CONFIG.client_admin_api,
  );
}

export function getRequestTypesNew(data: GetRequestTypesRequestData): APIResponse<GetRequestTypesResponseData> {
  const { companyUUID, requestedBy } = data;
  return sendRequest(
    {},
    "GET",
    `/companies/${companyUUID}/requests/types/tree?requested_by=${requestedBy}&types_to_include=${[
      RequestTypeName.overtime,
      RequestTypeName.hbConvert,
      RequestTypeName.scheduleAssignment,
      "userDefined", // added as string because it's not a name of a type of RequestTypeName
    ].join(",")}`,
    CONFIG.client_admin_api,
  );
}

export function requestTypeToggle(data: RequestTypeToggleRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/requests/types/${data.requestType}/${data.action}`,
    CONFIG.client_admin_api,
  );
}

export function requestTypeCreate(data: RequestTypeCreateRequestData): APIEmptyResponse {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/requests/types`, CONFIG.client_admin_api);
}

export function requestTypeDelete(data: RequestTypeDeleteRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/requests/types/${data.requestTypeUuid}`,
    CONFIG.client_admin_api,
  );
}

export function requestSubTypeDelete(data: RequestSubTypeDeleteRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUUID}/requests/types/${data.requestType}/subtypes/${data.requestSubtypeUuid}`,
    CONFIG.client_admin_api,
  );
}

export function requestSubTypeCreate(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/requests/types/${data.typeName}/subtypes`,
    CONFIG.client_admin_api,
  );
}

export function requestSubTypeUpdate(data: RequestSubTypeUpdateRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUUID}/requests/types/${data.requestType}/subtypes/${data.requestSubtypeUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getSchedules(data: GetSchedulesRequestData): APIResponse<GetSchedulesResponseData> {
  const params: QueryParams = {};

  if (data?.params) {
    if (data.params.scheduleTypes) {
      params.scheduleType = data.params.scheduleTypes.map((st) => st).join(",");
    }

    params.per_page = data.params.perPage || 1000;

    if (data?.params?.active !== undefined && data?.params?.active !== null) {
      params.active = data.params.active.toString();
    }
  }

  const query = paramsToUrlParams(params);

  return sendRequest<unknown, GetSchedulesResponseData>(
    {},
    "GET",
    `/companies/${data.companyUuid}/schedules${query}`,
    CONFIG.client_admin_api,
  );
}

export function getSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getScheduleEmployees(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUuid}/user_profiles?today=${data.limitDate}&per_page=1000`,
    CONFIG.client_admin_api,
  );
}

export function createNewSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/schedules`, CONFIG.client_admin_api);
}

export function updateSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PATCH",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUUID}?start_date=${moment(data.startDate).format(
      "YYYY-MM-DD",
    )}`,
    CONFIG.client_admin_api,
  );
}

export function activateSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "POST",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUuid}/activate`,
    CONFIG.client_admin_api,
  );
}

export function deactivateSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUuid}/deactivate`,
    CONFIG.client_admin_api,
  );
}

export function addEmployeeToSchedule(data: {
  body: {
    content: {
      userProfileUuids: string[];
      startDate: string;
      createdBy: string;
    };
  };
  scheduleUuid: string;
  companyUUID: string;
}): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/schedules/${data.scheduleUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function removeEmployeeFromAllSchedules(data: {
  body: {
    content: {
      userProfileUuids: string[];
      endDate: string;
      updatedBy: string;
    };
  };
  companyUuid: string;
}): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/schedules/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function removeEmployeeFromSchedule(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/schedules/${data.scheduleUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function adjustHoursBank(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/user_profiles/${data.userProfileUuid}/hours_bank/adjustments`,
    CONFIG.client_admin_api,
  );
}

export function getSuperpunchGroupsData(
  data: GetSuperpunchGroupsRequestData,
): APIResponse<GetSuperpunchGroupsResponseData> {
  const queryParams: Record<string, string> = {
    from: data.from,
    to: data.to,
    requested_by: data.requestedBy,
    aggregation_group_type: data.aggregationGroupType,
    page: String(data.page),
    per_page: String(data.perPage),
  };

  if (data.groupUuid && data.groupBy) {
    queryParams.group_type = data.groupBy;
    queryParams.group_uuid = data.groupUuid;
  }

  const paramStr = paramsToUrlParams(queryParams);

  return sendRequest({}, "GET", `/companies/${data.companyUUID}/superpunch_groups${paramStr}`, CONFIG.client_admin_api);
}

export function getPeriodAggregation(
  data: GetPeriodAggregationRequestData,
): APIResponse<GetPeriodAggregationResponseData> {
  const queryParams: Record<string, string> = {
    from: data.from,
    to: data.to,
    period: data.frequency,
    requested_by: data.requestedBy,
  };

  if (data.aggregationGroupType) {
    queryParams.aggregation_group_type = data.aggregationGroupType;
  } else if (data.groupBy) {
    queryParams.aggregation_group_type = data.groupBy;
  }

  if (data.aggregationType) {
    queryParams.aggregation_type = data.aggregationType;
  }

  if (data.groupUuids) {
    // TODO add right query param key
    queryParams.group_type = data.groupBy;
    queryParams.group_uuids = data.groupUuids;
  }

  if (data.props) {
    queryParams.props = data.props;
  }

  if (data.includeZeroes) {
    queryParams.include_zeroes = "true";
  }

  if (data.noCache !== undefined) {
    queryParams.no_cache = data.noCache.toString();
  }

  if (data.page && data.perPage) {
    queryParams.page = String(data.page);
    queryParams.perPage = String(data.perPage);
  }

  const paramStr = paramsToUrlParams(queryParams);

  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUUID}/period_aggregation${paramStr}`,
    CONFIG.client_admin_api,
  );
}

export function getReportNew({
  companyUUID,
  UserProfileUUID,
  type,
  startDate,
  endDate,
  groupType,
  groupUUID,
  clientReportType,
  clientUUID,
  skipSupervisors,
  onlyNonZeroPhases,
  onlyNonZeroLines,
  forceRecalculation,
  onlyMetadata,
  showInactiveEmploees,
  locationUuid,
  clientUuid,
  punchType,
  projectUuid,
  showServicesSummary,
  status,
  newApi,
}: GetReportRequestData): APIResponse<GetNewReportResponseData> {
  return new Promise((res, rej) => {
    let usersParam = "";
    let typeParam = "";

    if (type === ReportType.clients && clientReportType !== ClientReportsType.clientList) {
      usersParam = "/clients";

      if (clientUUID) {
        usersParam += `/${clientUUID}/reports/${clientReportType}`;
      }
    } else if (UserProfileUUID) {
      usersParam = UserProfileUUID ? `/user_profiles/${UserProfileUUID}/reports/${type}` : "";

      if (punchType) {
        typeParam += `punch_type=${punchType}&`;
      }
    } else {
      usersParam += `/reports/${type}`;
    }

    let groupParam = groupType && groupUUID ? `&group_type=${groupType}&group_uuid=${groupUUID}` : "";

    if (onlyNonZeroPhases) {
      groupParam += "&only_non_zero_phases=1";
    }
    if (onlyNonZeroLines) {
      groupParam += "&only_non_zero_lines=1";
    }
    if (!showInactiveEmploees) {
      groupParam += "&active=true";
    }
    if (skipSupervisors) {
      groupParam += "&skip_supervisors=true";
    }
    if (locationUuid) {
      groupParam += `&location_uuid=${locationUuid}`;
    }
    if (clientUuid) {
      groupParam += `&client_uuid=${clientUuid}`;
    }
    if (projectUuid) {
      groupParam += `&project_uuid=${projectUuid}`;
    }
    if (window.global_store.beta) {
      groupParam += "&custom_period=true";
    }
    if (showServicesSummary) {
      groupParam += "&with_service_summary=true";
    }
    if (forceRecalculation) {
      groupParam += "&force_recalculation=true";
    }
    if (onlyMetadata) {
      groupParam += "&only_metadata=true";
    }
    if (status) {
      groupParam += `&status=${status}`;
    }

    sendRequest<Record<string, never>, GetNewReportResponseData>(
      {},
      "GET",
      `/companies/${companyUUID}${usersParam}?${typeParam}from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(
        endDate,
      ).format("YYYY-MM-DD")}${groupParam}&requested_by=${window.global_store.profile.uuid}`,
      newApi ? CONFIG.client_admin_api_day_io : CONFIG.client_admin_api,
    )
      .then((report) => {
        res(report);
      })
      .catch((e) => {
        rej(e);
      });
  });
}

export function getSuperpunchReport({
  companyUUID,
  userProfileUUID,
  startDate,
  endDate,
  showOnlyIssues,
}: GetSuperpunchReportRequestData): Promise<DailySummaryReport> {
  return new Promise((res, rej) => {
    const requestedBy = window.global_store.profile.uuid;
    const from = moment(startDate).format("YYYY-MM-DD");
    const to = moment(endDate).format("YYYY-MM-DD");

    const url = `/companies/${companyUUID}/user_profiles/${userProfileUUID}/daily_summaries`;
    let params = `?from=${from}&to=${to}&requested_by=${requestedBy}&formatter=legacy`;

    if (showOnlyIssues) {
      params += "&show_only_issues=true";
    }

    sendRequest<Record<string, never>, GetSuperpunchReportResponseData>(
      {},
      "GET",
      `${url}${params}`,
      CONFIG.client_admin_api,
    )
      .then((report) => {
        res(report.content);
      })
      .catch((e) => {
        rej(e);
      });
  });
}

export function getReportOld({
  companyUUID,
  UserProfileUUID,
  type,
  startDate,
  endDate,
  groupType,
  groupUUID,
  clientReportType,
  clientUUID,
  showInactiveEmploees,
  punchType,
}: GetReportRequestData): APIResponse<GetOldReportResponseData> {
  return new Promise((res, rej) => {
    let usersParam = "";
    let typeParam = "";
    if (type === "clients") {
      usersParam = "/clients";
      if (clientReportType !== "clientList" && clientUUID) {
        usersParam += `/${clientUUID}`;
        typeParam = `type=${clientReportType}&`;
      }
    } else {
      usersParam = UserProfileUUID ? `/user_profiles/${UserProfileUUID}` : "";
      typeParam = type ? `type=${type}&` : "";
      if (punchType) {
        typeParam += `punch_type=${punchType}&`;
      }
    }

    let groupParam = groupType && groupUUID ? `&group_type=${groupType}&group_uuid=${groupUUID}` : "";
    if (!showInactiveEmploees) {
      groupParam += "&active=true";
    }

    let response;
    if (usersParam) {
      // TODO: get rid of public CONFIG.api_reporting endpoints (TATECH-182, TATECH-185)
      response = sendRequest<any, GetOldReportResponseData>(
        {},
        "GET",
        `/companies/${companyUUID}${usersParam}?${typeParam}from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(
          endDate,
        ).format("YYYY-MM-DD")}${groupParam}&requested_by=${window.global_store.profile.uuid}`,
        CONFIG.api_reporting,
      );
    } else {
      response = sendRequest<any, GetOldReportResponseData>(
        {},
        "GET",
        `/companies/${companyUUID}/reporting?${typeParam}from=${moment(startDate).format("YYYY-MM-DD")}&to=${moment(
          endDate,
        ).format("YYYY-MM-DD")}${groupParam}&requested_by=${window.global_store.profile.uuid}`,
        CONFIG.client_admin_api,
      );
    }

    response
      .then((report) => {
        res(report);
      })
      .catch((e) => {
        rej(e);
      });
  });
}

export function getReport(
  params: GetReportRequestData,
  withMeta: boolean,
): APIResponse<GetNewReportResponseData | GetNewReportResponseData["content"] | GetOldReportResponseData> {
  if (!params.oldReport) {
    return new Promise((res, rej) =>
      getReportNew(params)
        .then((resp) => res(withMeta ? resp : resp.content))
        .catch((err) => rej(err)),
    );
  }

  return getReportOld(params);
}

export function uploadRequestAttachmentNew(
  data: UploadRequestAttachmentRequest,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"],
  cancelToken: CancelToken,
): Promise<AxiosResponse<UploadRequestAttachmentResponse>> {
  const formData = new FormData();
  formData.append("attachment", data.attachment);
  formData.append("createdBy", data.createdBy);
  formData.append("title", data.attachment.name);
  formData.append("mimeType", data.attachment.type);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  let url = `${CONFIG.protocol}${CONFIG.client_admin_api}/companies/${data.companyUUID}/requests`;
  // if no request uuid use different url
  url += `/${data.requestUuid ? `${data.requestUuid}/` : ""}attachments`;

  return axios.post<UploadRequestAttachmentResponse>(url, formData, {
    headers,
    onUploadProgress,
    cancelToken,
  });
}

export function uploadCompanyLogo(data: TODORequestData, onUploadProgress: TODORequestData): TODOResponseData {
  const formData = new FormData();
  formData.append("logo", data.attachment);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  return axios.post(`${CONFIG.protocol}${CONFIG.api}/company/upload_logo`, formData, {
    headers,
    onUploadProgress,
  });
}

export function uploadCompanyDocumentLogo(data: TODORequestData, onUploadProgress: TODORequestData): TODOResponseData {
  const formData = new FormData();
  formData.append("document_logo", data.attachment);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  return axios.post(`${CONFIG.protocol}${CONFIG.api}/company/upload_document_logo`, formData, {
    headers,
    onUploadProgress,
  });
}

export function getShiftCompilationsInRangeV2(
  data: GetShiftCompilationsInRangeV2RequestData,
): APIResponse<GetShiftCompilationsInRangeV2ResponseData> {
  return sendRequest(
    null,
    "GET",
    `/companies/${data.companyUUID}/user_profiles/${data.employeeUUID}/shift_compilations?from=${data.from.format(
      "YYYY-MM-DD",
    )}&to=${data.to.format("YYYY-MM-DD")}&requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function forceRecalculateRange(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "POST",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfile}/force_recalculate?requested_by=${data.requestedBy}&from=${data.from}&to=${data.to}`,
    CONFIG.client_admin_api,
  );
}

export function removeScheduleOnRange(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfile}/remove_schedules`,
    CONFIG.client_admin_api,
  );
}

export function enableDay(data: EnableDayEventData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUUID}/user_profiles/${data.employeeUUID}/enable_day?requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function makeDefaultPayrollGroup(data: MakeDefaultPayrollGroupRequestData): Promise<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}/set_default`,
    CONFIG.client_admin_api,
  );
}

export function requestDigitalSignatures(
  data: RequestDigitalSignaturesRequestData,
): APIResponse<RequestDigitalSignaturesResponseData> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/digital_signatures`,
    CONFIG.client_admin_api,
  );
}

export function getDigitalSignatures(data: {
  companyUuid: string;
}): APIResponse<{ content: DigitalSignatureResponse[] }> {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/digital_signatures`, CONFIG.client_admin_api);
}

export function cancelDigitalSignatureForProfile(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/digital_signatures/${data.digitalSignatureUuid}/cancel`,
    CONFIG.client_admin_api,
  );
}

export function dismissDigitalSignatureForProfile(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/digital_signatures/${data.digitalSignatureUuid}/dismiss`,
    CONFIG.client_admin_api,
  );
}

export function resendDigitalSignatureForProfile(data: {
  body: any;
  companyUuid: string;
  digitalSignatureUuid: string;
}): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/digital_signatures/${data.digitalSignatureUuid}/resend`,
    CONFIG.client_admin_api,
  );
}

export function cancelDigitalSignatureGroup(data: {
  body: any;
  companyUuid: string;
  payrollLockUuid: string;
}): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/digital_signatures/cancel`,
    CONFIG.client_admin_api,
  );
}

export function dismissDigitalSignatureGroup(data: {
  body: any;
  companyUuid: string;
  payrollLockUuid: string;
}): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/digital_signatures/dismiss`,
    CONFIG.client_admin_api,
  );
}

export function resendlDigitalSignatureGroup(data: {
  body: any;
  companyUuid: string;
  payrollLockUuid: string;
}): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/digital_signatures/resend`,
    CONFIG.client_admin_api,
  );
}

export function downloadDigitalSignaturesReports(
  companyUuid: string,
  payrollLockUuid: string,
  requestedBy: string,
): TODOResponseData {
  return sendRequest(
    { requestedBy, locale: getLocale() },
    "POST",
    `/companies/${companyUuid}/payroll_locks/${payrollLockUuid}/digital_signatures/download`,
    CONFIG.client_admin_api,
  );
}

export function getMyDigitalSignatures(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/digital_signatures?status=pending`,
    CONFIG.client_admin_api,
  );
}

export function getMyDigitalSignatureReport(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/reports/digital_signature?requested_by=${data.requestedBy}&digital_signature_uuid=${data.digitalSignatureUuid}&report_hash=${data.reportHash}`,
    CONFIG.client_admin_api,
  );
}

export function signMyDigitalSignatureReport(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/digital_signatures/${data.digitalSignatureUuid}/sign`,
    CONFIG.client_admin_api,
  );
}

export function getDigitalSignaturesGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/digital_signatures`,
    CONFIG.client_admin_api,
  );
}

export function getPayrollLayouts(data: GetPayrollLayoutsRequestData): APIResponse<GetPayrollLayoutsResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUUID}/payroll_layouts`, CONFIG.api_exporting);
}

export function createPayrollLayout(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUUID}/payroll_layouts`, CONFIG.api_exporting);
}

export function getPayrollEventTypes(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/companies/${data.companyUUID}/payroll_event_types`, CONFIG.api_exporting);
}

export function getExceptions(data: TODORequestData): TODOResponseData {
  const { companyUUID, page, perPage, status } = data;
  return sendRequest(
    {},
    "GET",
    `/companies/${companyUUID}/exceptions?order=createdAt:desc&page=${page}&per_page=${perPage}&status=${status}`,
    CONFIG.client_admin_api,
  );
}

export function getScheduleException(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/exceptions/${data.exceptionUuid}`,
    CONFIG.client_admin_api,
  );
}

export function createScheduleException(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/exceptions`, CONFIG.client_admin_api);
}

export function toggleScheduleException(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/exceptions/${data.scheduleExceptionUuid}/${data.action}?requested_by=${data.userProfileUUID}`,
    CONFIG.client_admin_api,
  );
}
export function toggleScheduleExceptionDay(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/exceptions/${data.scheduleExceptionUuid}/${data.action}?date=${data.date}&user_profile_uuid=${data.userProfileUuid}&requested_by=${data.requestedByUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getLocksList(data: GetLocksListRequestData): APIResponse<GetLocksListResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_locks${data.forDeactivatedProfile ? "?for_deactivated_profile=true" : ""}`,
    CONFIG.client_admin_api,
  );
}

export function getDeactivatedLocksList(
  data: GetDeactivatedLocksListRequestData,
): APIResponse<GetDeactivatedLocksListResponseData> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/deactivated`,
    CONFIG.client_admin_api,
  );
}

export function bulkDigitalSignaturesRequest(data: BulkPayrollLocksRequestData) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/digital_signatures/mass_request`,
    CONFIG.client_admin_api,
  );
}

export function bulkSendToPayrollRequest(data: BulkSendToPayrollRequestData) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/mass-add-to-layout`,
    CONFIG.client_admin_api,
  );
}

export function bulkLockPayrollLocksRequest(data: BulkPayrollLocksRequestData) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/mass-lock`,
    CONFIG.client_admin_api,
  );
}

export function bulkUnlockPayrollLocksRequest(data: BulkPayrollLocksRequestData) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/mass-unlock`,
    CONFIG.client_admin_api,
  );
}

export function getLockById(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}`,
    CONFIG.client_admin_api,
  );
}

export function createLock(data: CreateLockRequestData): Promise<CreateLockResponseData> {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/payroll_locks`, CONFIG.client_admin_api);
}

export function lockPayrollLock(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      content: {
        updatedBy: data.requestedBy,
      },
    },
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/lock`,
    CONFIG.client_admin_api,
  );
}

export function unlockPayrollLock(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {
      content: {
        updatedBy: data.requestedBy,
      },
    },
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/unlock?force=${!!window.global_store.beta}`,
    CONFIG.client_admin_api,
  );
}

export function lockPayrollLockForEmployees(
  data: PayrollLockForEmployeesRequestData,
): APIResponse<PayrollLockForEmployeesResponseData> {
  return sendRequest(
    {
      content: {
        userProfileUuids: data.userProfileUuids,
        updatedBy: data.updatedBy,
      },
    },
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/user_profiles/lock`,
    CONFIG.client_admin_api,
  );
}

export function unlockPayrollLockForEmployees(
  data: PayrollLockForEmployeesRequestData,
): APIResponse<PayrollLockForEmployeesResponseData> {
  return sendRequest(
    {
      content: {
        userProfileUuids: data.userProfileUuids,
        updatedBy: data.updatedBy,
      },
    },
    "POST",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/user_profiles/unlock?force=${!!window
      .global_store.beta}`,
    CONFIG.client_admin_api,
  );
}

export function getLockGroupsList(data: GetLockGroupListRequestData): APIResponse<GetLockGroupListResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/payroll_groups`, CONFIG.client_admin_api);
}

export function getLockGroupByUuid(data: GetLockGroupRequestData): APIResponse<GetLockGroupResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}`,
    CONFIG.client_admin_api,
  );
}

export function toggleGroupStatus(data: ToggleGroupStatusRequestData): APIEmptyResponse {
  return sendRequest(
    {},
    "POST",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}/${data.status}`,
    CONFIG.client_admin_api,
  );
}

export function createLockGroup(data: CreateLockGroupRequestData): APIResponse<CreateLockGroupResponseData> {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/payroll_groups`, CONFIG.client_admin_api);
}

export function updateLockGroup(data: UpdateLockGroupRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}`,
    CONFIG.client_admin_api,
  );
}

export function assignEmployeeToPayrollGroup(data: {
  companyUuid: string;
  payrollGroupUuid: string;
  body: {
    content: {
      userProfileUuids: string[];
      createdBy: string;
    };
  };
}): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function unassignEmployeeFromPayrollGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/payroll_groups/${data.payrollGroupUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function getPayrollLockReport(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "GET",
    `/companies/${data.companyUuid}/payroll_locks/${data.payrollLockUuid}/report?from=${data.from}&to=${data.to}&requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function getBRGroups(data: { companyUuid: string }): APIResponse<{ content: BusinessRuleGroup[] }> {
  return sendRequest(data.body, "GET", `/companies/${data.companyUuid}/business_rules/groups`, CONFIG.client_admin_api);
}

export function getBRGroup(data: GetBRGroupRequestData): APIResponse<GetBRGroupResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}`,
    CONFIG.client_admin_api,
  );
}

export function createBRGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/business_rules/groups`,
    CONFIG.client_admin_api,
  );
}

export function updateBRGroup(data: UpdateBRGroupRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}`,
    CONFIG.client_admin_api,
  );
}

export function deactivateBRGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/deactivate`,
    CONFIG.client_admin_api,
  );
}

export function makeDefaultBRGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/set_default`,
    CONFIG.client_admin_api,
  );
}

export function updateBRGroupSettings(data: UpdateBRGroupSettingsRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/${data.businessRulesType}`,
    CONFIG.client_admin_api,
  );
}

export function getUserBRGroups(data: GetUserBRGroupsRequestData): APIResponse<GetUserBRGroupsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/business_rules?from=${moment(
      data.startDate,
    ).format("YYYY-MM-DD")}&to=${moment(data.endDate || data.startDate).format("YYYY-MM-DD")}&requested_by=${
      data.requestedBy
    }`,
    CONFIG.client_admin_api,
  );
}

export function assignUserToBRGroup(data: AssignUserToBRGroupRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/user_profiles?start_date=${moment(
      data.startDate,
    ).format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

export function unassignUserFromBRGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function getOperations(data: GetOperationsRequestData): APIResponse<GetOperationsResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/operations`, CONFIG.client_admin_api);
}

export function createOnCall(data: CreateOnCallRequestData): APIResponse<CreateOnCallResponseData> {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/on_calls`, CONFIG.client_admin_api);
}

export function updateOnCallStatus(data: UpdateOnCallRequestData): APIEmptyResponse {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/on_calls/status/${data.status}`,
    CONFIG.client_admin_api,
  );
}

export function deleteOnCall(data: DeleteOnCallRequestData) {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/on_calls/${data.onCallUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getLockPeriodsForReports(
  data: GetLockPeriodsForReportsRequestData,
): APIResponse<GetLockPeriodsForReportsResponseData> {
  const { companyUuid, startDate = moment().startOf("month").subtract(18, "month").format("YYYY-MM-DD") } = data;

  return sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/payroll_groups/periods?from=${startDate}`,
    CONFIG.client_admin_api,
  );
}

export function getLockPeriodsForUserProfileForReports(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_groups/user_profiles/${data.userProfileUuid}/periods?from=${moment().format(
      "YYYY-MM",
    )}&to=${moment().format("YYYY-MM")}`,
    CONFIG.client_admin_api,
  );
}

export function getDefaultLockPeriodsForReports(
  data: GetDefaultLockPeriodsRequestData,
): APIResponse<GetDefaultLockPeriodsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/payroll_groups/default_periods?from=${moment().format(
      "YYYY-MM",
    )}&to=${moment().format("YYYY-MM")}`,
    CONFIG.client_admin_api,
  );
}

export function generateIntegrationCredentials(): APIResponse<GenerateIntegrationCredsResponseData> {
  return sendRequest({}, "POST", "/company/create_integration_credentials");
}

export function revealIntegrationSecret(
  data: RevealIntegrationSecretRequestData,
): APIResponse<RevealIntegrationSecretResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/external_api/clients/id/${data.clientId}/reveal_secret`,
    CONFIG.client_admin_api,
  );
}

export function generateIntegrationTmpCode({
  companyUuid,
}: GenerateIntegrationTmpCodeRequestData): APIResponse<GenerateIntegrationTmpCodeResponseData> {
  return sendRequest({}, "POST", `/companies/${companyUuid}/integrations/verification`, CONFIG.client_admin_api);
}

export function getOnCalls(data: GetOmCallsRequestData): APIResponse<GetOmCallsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/on_calls${paramsToUrlParams(data.params)}`,
    CONFIG.client_admin_api,
  );
}

export function getApplications(companyUuid: string): APIResponse<{ content: Application[] }> {
  return sendRequest({}, "GET", `/companies/${companyUuid}/integrations/applications`, CONFIG.client_admin_api);
}

export function removeApplication(
  companyUuid: string,
  applicationUuid: string,
  keepImported: boolean,
): APIResponse<unknown> {
  return sendRequest(
    { keepImported },
    "DELETE",
    `/companies/${companyUuid}/integrations/applications/${applicationUuid}`,
    CONFIG.client_admin_api,
  );
}

export function listUserProfilesWIthFilters(
  companyUuid: string,
  payload: FilteredEmployeePayload,
): APIResponse<{ content: FilteredEmployeeProfile[]; metadata: { total: number } }> {
  return sendRequest(payload, "POST", `/companies/${companyUuid}/user_profiles/list`, CONFIG.client_admin_api);
}

export function listTeamMemberUuidsWithNesting(companyUuid: string, userProfileUuid: string, requestedBy: string) {
  return sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/authorization/actor/${userProfileUuid}/team?requested_by=${requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function getProfileUuidsWithFilters(
  companyUuid: string,
  payload: {
    requestedBy: string;
    skip: number;
    limit: number;
    filterModel?: any;
    sortModel?: any;
  },
): APIResponse<{ content: string[]; total: number }> {
  return sendRequest(payload, "POST", `/companies/${companyUuid}/user_profiles/list_uuids`, CONFIG.client_admin_api);
}

export function getProfilesBasedOnPermissions(
  companyUuid: string,
  payload: {
    requestedBy: string;
    term?: string;
  },
): APIResponse<{ content: { uuid: string; name: string }[] }> {
  return sendRequest(
    payload,
    "POST",
    `/companies/${companyUuid}/user_profiles/list_based_on_permission`,
    CONFIG.client_admin_api,
  );
}

export function getEmbeddedDashboardUrl(data: GetEmbeddedUrlRequestData): APIResponse<GetEmbeddedUrlResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/embeddings/dashboards/${data.type}/url?language=${data.language}`,
    CONFIG.client_admin_api,
  );
}

export function assignLocationsToEmployees(
  companyUuid: string,
  payload: AssociateLocationsToEmployeesRequestData,
): APIResponse<void> {
  return sendRequest(
    payload,
    "POST",
    `/companies/${companyUuid}/user_profiles/associate_locations`,
    CONFIG.client_admin_api,
  );
}

export function assignDeviceSettingsToEmployees(
  companyUuid: string,
  payload: AssociateDeviceSettingsToEmployeesRequestData,
): APIResponse<void> {
  return sendRequest(
    payload,
    "POST",
    `/companies/${companyUuid}/user_profiles/associate_device_settings`,
    CONFIG.client_admin_api,
  );
}
