enum PermissionRoleName {
  admin = "Admin",
  owner = "Owner",
  hr = "Hr",
  HR = "HR", // TODO EmployeePage
  finance = "Finance",
  supervisor = "Supervisor",
  basic = "Basic",
  clientManager = "ClientManager",
  locationManager = "LocationManager",
  deactivated = "Deactivated",
}

enum PermissionSectionName {
  /** NOT flags.newHierarchyPermissions */
  punches = "punches",
  /** flags.newHierarchyPermissions */
  viewPunches = "punches_view",
  /** flags.newHierarchyPermissions */
  managePunches = "punches_manage",
  managePermissions = "permissions_manage",
  requests = "requests",
  payroll = "payroll",
  projects = "projects",
  /**
   * Permission to edit "Allow to work on projects" employee setting.
   */
  allowProjects = "allow_projects",
  /** NOT flags.newHierarchyPermissions */
  reports = "reports",
  /** flags.newHierarchyPermissions */
  basicReports = "reports_basic",
  /** flags.newHierarchyPermissions */
  punchSettings = "punches_settings",
  /** flags.newHierarchyPermissions */
  advancedReports = "reports_advanced",
  employees = "employees",
  companyProfile = "company_profile",
  schedules = "schedules",
  billing = "billing",
  payrollExport = "payroll_export",
  timeClocks = "time_clocks",
  marketplace = "marketplace",
  locations = "locations",
  jobSides = "job_sides",
  clients = "clients",
  subsidiaries = "subsidiaries",
  positions = "positions",
  departments = "departments",
  teams = "teams",
  holidays = "holidays",
  rulesAndLimits = "rules_and_limits",
  groupsOfRules = "groups-of-rules",
  security = "security",
  deactivateEmployees = "deactivate_employees",
  approveActivities = "approve_activities",
  requestSuperApprover = "requests_super_approver",
  onCallManagement = "on_call_management",
  onCallRequest = "on_call_request",
  dashboard = "dashboard",
  tabletSettings = "tablet_settings", // kiosk
  hoursBankAdjustment = "hours_bank_adjustment",
  // routes (doesn't come from the API and never set?)
  activity = "activity",
  activities = "activities",
  projectEmployeeCost = "project_employee_cost",
  superpunch = "superpunch",
  requestSettings = "requests_settings",
  company = "company",
  inviteEmployees = "inviteEmployees",
  recommend = "recommend",
  pegador = "pegador",
  mysignatures = "mysignatures",
  onCalls = "onCalls",
  subscription = "subscription",
  dataManagement = "dataManagement",
  cancelApprovedRequest = "cancel_approved_request",
}

export type PermissionAction = {
  allow: boolean;
  id: number;
  name: string;
  uuid: string;
};

export type PermissionSection = {
  id: number;
  name: PermissionSectionName;
  filter_scopes: "all" | "team";
  uuid: string;
  allow: boolean;
  permission_actions: PermissionAction[];
};

type PermissionRole = {
  id: number;
  name: PermissionRoleName;
  uuid: string;
  predefined: boolean;
  permission_sections: PermissionSection[];
};

type ProfilePermissionRole = {
  id: unknown | null; // todo
  name: PermissionRoleName;
  uuid: string;
};

/** Additional permissions for employee */
type EmployeePermissionRole = {
  id: number;
  /** reference to a company level permission role */
  permission_role_id: number;
  permission_role_uuid: string;
  /** reference to an user profile */
  user_profile_id: number;
  user_profile_uuid: string;

  teams_uuid: string[];
  read_only_teams: string[];
  user_profiles_uuid: string[];
  global: boolean;

  /** remove ? when implemented on BE */
  departments_uuid?: string[];
  /** remove ? when implemented on BE */
  subsidiaries_uuid?: string[];
};

export type { ProfilePermissionRole, PermissionRole, EmployeePermissionRole };

export { PermissionRoleName, PermissionSectionName };
