import styled from "styled-components";
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { stylesheet } from "astroturf";
import { validateInput, Sel, assumeInput, handleKeySmart, sanitizeInput } from "./time-input-utils";

export const timeInputStyles = stylesheet`
  .Underline {
    position: absolute;
    bottom: -20%;
    height: 2px;
    background-color: var(--colors-borderColor4);
    border-inline-start: calc(0.03em + 2px) solid white;
    border-inline-end: 2px solid white;
    inset-inline-start: 0;
    inset-inline-end: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  left: -2px; // letter-spacing shifts text slightly to the right (doesn't depend on rtl/ltr)
  width: 220px;
  height: 80px;
  font-size: 80px;
  font-weight: var(--typography-font-weight-medium);
  line-height: 76px;
  letter-spacing: -0.03em;
`;

const Chars = styled.div`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  opacity: 0;
  direction: ltr;
`;

const Char = styled.div`
  position: relative;
  font: inherit;
  line-height: inherit;
  color: transparent;
  letter-spacing: inherit;
  white-space: pre;
  background: transparent;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: inherit;
  color: var(--colors-mainText);
  text-align: center;
  letter-spacing: inherit;
  background: transparent;
  border: none;
  outline: none;

  &.hasError {
    color: var(--colors-danger-600-p);
  }

  &:hover,
  &:focus {
    color: var(--colors-primary);

    &:disabled {
      color: var(--colors-mainText);
    }
  }
`;

export type TimeInputProps = React.HTMLProps<HTMLInputElement> & {
  value: string;
  hasError?: boolean;
  onChange?: (value: string) => void;
  /** Guarantee that the value is a valid HH:mm time string */
  onChangeFinished?: (value: string) => void;
};

/**
 * Never let value that can't become a valid time after adding more symbols into the input.
 */
export default function TimeInput(props: TimeInputProps) {
  const {
    value: valueProp = "",
    hasError,
    onChange,
    onFocus,
    onBlur,
    onChangeFinished,
    className,
    ...otherProps
  } = props;
  const input = useRef<HTMLInputElement>(null);
  const selection = useRef<Sel | null>(null);
  const [value, setValue] = useState(valueProp);

  // --- save and restore selection
  /**
   * allows to trigger layout effect that restores selection if value was not changed during onChange.
   * Detailed explanation:
   * During `onChange` the `value` of a controlled input (that has a value prop passed to it) is changea.
   * If setValue with the new value is not called during `onChange` then react restores input.value to the old value.
   * The sideeffect of this is that the selection cursor jumps to the end of the input.
   * This can be avoided by storing selection during `onKeyDown` and restoring in useLayoutEffect.
   * To cause a layout effect to be triggered even if the value was not changed an extra state variable is required.
   * This behavior is described here https://stackoverflow.com/a/28922465/2277240
   */
  const [counter, setCounter] = useState(0);
  const scheduleSelectionRestore = () => setCounter((c) => c + 1);
  function saveSelection() {
    selection.current = [input.current!.selectionStart!, input.current!.selectionEnd!];
  }
  /**
   * If selection.current is null then skip restore
   */
  function restoreSelection() {
    if (selection.current) {
      [input.current!.selectionStart, input.current!.selectionEnd] = selection.current;
    }
    selection.current = null;
  }
  useLayoutEffect(() => restoreSelection(), [value, counter]);

  // --- add underscores to characters
  const [chars, setChars] = useState<string[] | null>(null);
  const [showChars, setShowChars] = useState(false);
  useLayoutEffect(() => setChars(value.split("")), [value]);

  // --- update value
  /**
   * `sel` undefined means don't no update to stored selection is needed.
   * `sel` null means skip selection restore - selection is already handled externally and should not be restored.
   * *) If the external change doesn't pass validation resulting in no value update,
   * then the external change of selection also should be considered invalid and selection has to be restored.
   */
  function setValueSafe(val: string, sel?: Sel | null) {
    const v = sanitizeInput(val);
    const isValid = validateInput(v);
    const shouldUpdate = isValid && v !== value;
    if (!shouldUpdate) {
      // don't overwrite stored selection if null is passed.*
      if (sel !== undefined && sel !== null) {
        selection.current = sel;
      }
      scheduleSelectionRestore();
      return value;
    }
    if (sel !== undefined) {
      selection.current = sel;
    }
    setValue(v);
    return v;
  }

  useEffect(() => {
    saveSelection();
    setValueSafe(valueProp);
  }, [valueProp]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // it is too late to `saveSelection` here, the change is applied to the input
    const v = setValueSafe(event.target.value, null);
    onChange && onChange(v);
  };

  const handleKey: KeyboardEventHandler<HTMLInputElement> = (event) => {
    saveSelection(); // store selection while the change is not yet applied to the input
    const { key } = event;
    const sel: Sel = [input.current!.selectionStart!, input.current!.selectionEnd!];

    const res = handleKeySmart(value, sel, key);
    if (res) {
      const { val: newVal, sel: newSel } = res;
      const v = setValueSafe(newVal, [newSel, newSel]);
      onChange && onChange(v);
      event.preventDefault(); // prevent `onChange` from handling the same change
    }
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    setShowChars(true);
    onFocus && onFocus(event);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    let val = event.target.value;
    if (val !== ":") {
      val = assumeInput(event.target.value);
    }
    setValue(val);
    setShowChars(false);
    onBlur && onBlur(event);
    onChangeFinished && onChangeFinished(val);
  };

  return (
    <Wrapper className={className}>
      <Chars style={{ opacity: showChars ? 1 : 0 }}>
        {chars &&
          chars.map((ch, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Char key={i}>
              {ch}
              {ch !== ":" ? <div className={timeInputStyles.Underline} /> : null}
            </Char>
          ))}
      </Chars>
      <Input
        ref={input}
        maxLength={5}
        className={hasError ? "hasError" : ""}
        onChange={handleChange}
        onKeyDown={handleKey}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        {...otherProps}
      />
    </Wrapper>
  );
}
