import Lightbox from "components/Lightbox";
import TableButtonsControl from "components/styled/TableButtonsControl";
import { ColumnAlign } from "components/TableCommon";
import TablePage from "components/TablePage";
import ModalDialog from "components/UI/ModalDialog";
import useState from "hooks/useState";
import styled from "styled-components";
import { Application } from "types/models/application";
import { TFunction, TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { removeApplication } from "utils/apiHelpers";
import { iCellInfo } from "utils/tableHelpers";
import moment from "moment";
import RadioButton from "components/controls/RadioButton";
import { SectionTitle } from "./styled";
import { ApplicationsService } from "./ApplicationsService";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Count = styled.div`
  margin-inline-start: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 26px;
  background: var(--colors-surface-50);
  border-radius: 100%;
  font-weight: var(--typography-font-weight-bold);
  font-size: var(--typography-font-size-default);
  line-height: 14px;
  text-align: center;
  color: var(--colors-surface-800);
`;

const ButtonsWrapper = styled.div`
  span {
    padding-inline-end: 10px;
  }
  .buttons {
    margin-top: -32px;
    inset-inline-end: 0px;
  }
`;

const LogoName = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 3px;
  gap: 16px;
`;

const Logo = styled.div<{ logoImage: string }>`
  width: 36px;
  height: 36px;
  background-image: ${(p) => p.logoImage};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const DeleteIntegrationText = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-700);
`;

const RadioButtons = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: var(--typography-font-weight-default);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);

  div:first-child {
    margin-inline-end: 12px;
  }
`;

interface InstalledIntegrationsTableProps {
  applications: Application[];
  onRemove: () => void;
}

interface InstalledIntegrationsTableState {
  isLoading: boolean;
  keepImported: boolean;
  selectedApplication: Application | null;
}

const getColumns = (t: TFunction, onSelectToRemove: (application: Application) => void) => {
  const columns = [
    {
      label: t("App name"),
      accessor: "type",
      minWidth: 160,
      Cell: (row: iCellInfo<Application>) => (
        <LogoName>
          <Logo logoImage={ApplicationsService.integrations[row.original.type]?.logo} />
          {row.original.type}
        </LogoName>
      ),
      style: { lineHeight: "36px", fontWeight: "500" },
    },
    {
      label: t("Last sync"),
      accessor: "updatedAt",
      minWidth: 160,
      Cell: (row: iCellInfo<Application>) => moment(row.value).format("DD/MM/YYYY HH:mm"),
      style: { lineHeight: "36px" },
    },
    {
      label: "",
      accessor: "uuid",
      width: 127,
      style: { lineHeight: "36px" },
      Cell: (row: iCellInfo<Application>) => (
        <ButtonsWrapper>
          <span>...</span>
          <TableButtonsControl
            visibleButtons={[
              {
                label: t(`${TranslationNamespaces.common}|Remove`),
                onClick: () => {
                  onSelectToRemove(row.original);
                },
              },
            ]}
          />
        </ButtonsWrapper>
      ),
      align: "end" as ColumnAlign,
    },
  ];

  return columns;
};

const InstalledIntegrationsTable = ({ applications, onRemove }: InstalledIntegrationsTableProps) => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const [{ isLoading, selectedApplication, keepImported }, setState] = useState<InstalledIntegrationsTableState>({
    isLoading: true,
    keepImported: true,
    selectedApplication: null,
  });

  return (
    <Wrapper>
      <TitleRow>
        <SectionTitle>{t("Installed Apps")}</SectionTitle>
        <Count>{applications.length}</Count>
      </TitleRow>
      <TablePage<Application>
        rows={applications}
        columns={getColumns(t, (value) => setState({ selectedApplication: value }))}
        columnSelectorOnFiltersRow
        customColumnsAvailable={false}
      />
      <ModalDialog onClose={() => setState({ selectedApplication: null })} isOpen={selectedApplication !== null}>
        <Lightbox
          showLoading={isLoading}
          onClose={() => setState({ selectedApplication: null })}
          title={t("Delete integration?")}
          buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onYes={async () => {
            if (!selectedApplication) {
              return;
            }

            setState({ isLoading: true });
            await removeApplication(window.global_store.company.uuid, selectedApplication.uuid, keepImported);
            await onRemove();
            setState({ selectedApplication: null, isLoading: false });
          }}
        >
          <DeleteIntegrationText>
            {t("Are you sure you want to stop synchronizing your issues with Day.io and delete the integration?")}
          </DeleteIntegrationText>
          <RadioButtons>
            <RadioWrapper>
              <RadioButton selected={keepImported} onClick={() => setState({ keepImported: true })} />
              <div onClick={() => setState({ keepImported: true })}>
                {t("Keep all the imported projects and issues")}
              </div>
            </RadioWrapper>
            <RadioWrapper>
              <RadioButton selected={!keepImported} onClick={() => setState({ keepImported: false })} />
              <div onClick={() => setState({ keepImported: false })}>
                {t("Remove all the imported projects and issues")}
              </div>
            </RadioWrapper>
          </RadioButtons>
        </Lightbox>
      </ModalDialog>
    </Wrapper>
  );
};

export default InstalledIntegrationsTable;
