import { Component } from "react";
import { withTranslation } from "react-i18next";
import CheckboxControl from "components/UI/NewCheckbox";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import arrayMove from "array-move";
import BEM from "utils/BEM";
import { getPhasesDays } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import FieldWrapper from "components/UI/FieldWrapper";
import RadioButton from "components/UI/RadioButton";
import SelectControl from "components/SelectControl";
import { PhaseType, PhaseDayType } from "types/models/businessRulesGroup";
import { ErrorLabel } from "components/UI/TextLabels";
import { DSR_PHASE_KEY } from "components/Payroll/types";
import { PHASE_TYPES, MULTIPLIER_TYPES } from "./PhasesTable/helpers";
import { Subheader } from "./styled";
import PhasesTable from "./PhasesTable";

const b = BEM.b("company-rules");

const DAY_TYPES_BASED_ON_SCHEDULE = {
  WORKING: PhaseDayType.working,
  NON_WORKING: PhaseDayType.nonWorking,
};

const Wrapper = styled.div`
  width: 100%;

  .type-switch {
    margin-top: 16px;
  }
`;

const SortablePhase = styled.li`
  ${(p) => (!p.$disabled ? "cursor: pointer;" : "cursor: default;")}
  display: flex;
  font-size: 15px;
  color: #b4b9c5;
  margin-bottom: 15px;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    top: 0;
    inset-inline-end: 10px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 21'%3E%3Cg fill='%23F1F5F8' fill-rule='evenodd'%3E%3Ccircle cx='10.604' cy='2.507' r='2'/%3E%3Ccircle cx='10.604' cy='10.507' r='2'/%3E%3Ccircle cx='2.604' cy='2.507' r='2'/%3E%3Ccircle cx='2.604' cy='10.507' r='2'/%3E%3Ccircle cx='10.604' cy='18.507' r='2'/%3E%3Ccircle cx='2.604' cy='18.507' r='2'/%3E%3C/g%3E%3C/svg%3E");
  }
`;

const SortablePhaseContainer = styled.ul`
  padding-inline-start: 0;
  width: 100%;
  max-width: 420px;
`;

const RowNumber = styled.div`
  width: 25px;
  line-height: 34px;
`;

const RowValue = styled.div`
  background: var(--colors-default);
  border: 1px solid #dae1ec;
  line-height: 34px;
  border-radius: var(--shapes-border-radius-default);
  padding: 0 14px;
  width: calc(100% - 23px);
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const SortableItem = SortableElement(({ value, rowNumber, inactive }) => (
  <SortablePhase $disabled={inactive}>
    <RowNumber>{rowNumber}.</RowNumber>
    <RowValue>{value}</RowValue>
  </SortablePhase>
));

const SortableList = SortableContainer(({ items, disabled }) => (
  <SortablePhaseContainer>
    {items.map((value, index) => (
      <SortableItem
        key={`item-${value.uuid}`}
        disabled={disabled}
        inactive={disabled}
        index={index}
        rowNumber={index + 1}
        value={value.name}
      />
    ))}
  </SortablePhaseContainer>
));

class HbEhPhases extends Component {
  constructor(props) {
    super(props);
    const { t, phasesType, phases } = props;

    this.state = {
      // if phase has dayTypeBasedOnSchedule property instead of daysMask
      isDayTypeBasedOnSchedule: phases.length && !!phases[0].dayTypeBasedOnSchedule,
    };

    this.defaultPhase = {
      daysMask: "11111000",
      name: phasesType === PHASE_TYPES.PERCENTAGE ? 50 : 1.5, // int > 0
      limit: 120, // -1 анлімітед і в хвилинах, 0 не може бути. максимальне значення 5999 (99годин 59 хвилин)
      extraHours: null, // (якщо undefined то або нема сплітера або виключений аверс бенк),
      uuid: "", // uuid,
      type: PhaseType.combined,
    };

    this.phasesTypeOptions = [
      { label: t("Percentage"), value: PHASE_TYPES.PERCENTAGE },
      { label: t("Multipliers"), value: PHASE_TYPES.MULTIPLIER },
    ];

    this.multiplierTypeOptions = [
      { label: t("Disabled"), value: MULTIPLIER_TYPES.DISABLED },
      { label: t("Real Time"), value: MULTIPLIER_TYPES.REALTIME },
    ];

    this.dayTypeBasedOnScheduleOptions = [
      { label: t("Planned Days"), value: DAY_TYPES_BASED_ON_SCHEDULE.WORKING },
      { label: t("Non-Planned Days"), value: DAY_TYPES_BASED_ON_SCHEDULE.NON_WORKING },
    ];

    // //substractionOrder": [uuid,uuid,uuid,uuid] //довжина масиву така сама як довжина масиву phases. якщо аверс бенк виключений тоді пустий або нема
    // let phasesByDays = phases ? this.serialize(phases) : this.serialize([this.getNewPhase(),this.getNewPhase({daysMask: "11111111"}),this.getNewPhase({daysMask: "11111111"})])
  }

  /**
    Serialize array of days with phases to plain array of phases
   */
  deserialize(phasesByDayInput) {
    const phasesByDay = JSON.parse(JSON.stringify(phasesByDayInput));

    return Object.values(phasesByDay).reduce((acc, val) => acc.concat(val), []);
  }

  /**
    Serialize plain array of phases array to array of days with phases
   */
  serialize = (phasesInput) => {
    const { isDayTypeBasedOnSchedule } = this.state;
    const phasesByDays = {};
    const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";

    const phases = JSON.parse(JSON.stringify(phasesInput));

    phases.forEach((phase) => {
      if (phasesByDays[phase[key]]) {
        phasesByDays[phase[key]].push(phase);
      } else {
        phasesByDays[phase[key]] = [phase];
      }
    });

    return phasesByDays;
  };

  // get new Phase object based on Default Phase
  getNewPhase = (props) => {
    const { isDayTypeBasedOnSchedule } = this.state;
    const phase = JSON.parse(JSON.stringify(this.defaultPhase));

    if (this.props.phasesType === PHASE_TYPES.PERCENTAGE) {
      phase.name = props.daysMask === "00000011" ? 100 : 50;
    } else {
      phase.name = props.daysMask === "00000011" ? 2 : 1.5;
    }

    if (isDayTypeBasedOnSchedule) {
      delete phase.daysMask;
    }

    return { ...phase, uuid: uuidv4(), ...props };
  };

  // available only for phases with daymask
  addDayWithPhase = () => {
    const { phases, phasesSubstractionOrder, hoursBankActive } = this.props;
    const phasesByDays = this.serialize(phases);
    const availableDays = this.getDaysOptions(Object.keys(phasesByDays));
    const newPhase = this.getNewPhase({ daysMask: availableDays[0].value });
    newPhase.limit = -1;
    newPhase.extraHours = !hoursBankActive ? 100 : null;

    phases.push(newPhase);

    if (hoursBankActive) {
      phasesSubstractionOrder.push(newPhase.uuid);
    }

    this.update({ phases, phasesSubstractionOrder });
  };

  addDsrDayBasedOnSchedule = () => {
    const { phases, phasesSubstractionOrder, hoursBankActive } = this.props;
    const newPhase =  this.getNewPhase({
        dayTypeBasedOnSchedule: "dsr",
        limit: -1,
        extraHours: !hoursBankActive ? 100 : null,
        type: "combined",
      });
    phases.push(newPhase);
    if (hoursBankActive) {
      phasesSubstractionOrder.push(newPhase.uuid);
    }
    this.update({ phases });
  }

  isAddDsrAvailable = () => {
    const { isDayTypeBasedOnSchedule } = this.state;
    const { phases } = this.props;
    return isDayTypeBasedOnSchedule && phases.length < 3;
  }

  removeDayWithPhase = ({ daysMaskOrDaysType }) => {
    const { hoursBankActive } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;
    const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
    let { phases, phasesSubstractionOrder } = this.props;

    phases = phases.filter((p) => p[key] !== daysMaskOrDaysType);

    if (hoursBankActive) {
      phasesSubstractionOrder = phasesSubstractionOrder.filter((sop) => phases.filter((ph) => ph.uuid === sop).length);
    }

    this.update({ phases, phasesSubstractionOrder });
  };

  addSinglePhase = ({ daysMaskOrDaysType, uuid, type, additionalLimit }) => {
    const { phasesSubstractionOrder, hoursBankActive } = this.props;
    let { phases } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;

    const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
    const phasesByDays = this.serialize(phases);
    const daysPhases = phasesByDays[daysMaskOrDaysType];
    const newPhase = this.getNewPhase({
      [key]: daysMaskOrDaysType,
      extraHours: !hoursBankActive ? 100 : null,
      type: type || PhaseType.combined,
    });

    if (additionalLimit !== null && additionalLimit !== undefined) {
      newPhase.additionalLimit = additionalLimit;
      newPhase.limit = -1;
    }

    const positionId = uuid ? daysPhases.findIndex((p) => p.uuid === uuid) : daysPhases.length - 1;
    daysPhases.splice(positionId, 0, newPhase);
    phasesByDays[daysMaskOrDaysType] = daysPhases;
    phases = this.deserialize(phasesByDays);

    if (hoursBankActive) {
      phasesSubstractionOrder.push(newPhase.uuid);
    }

    this.update({ phases, phasesSubstractionOrder });
  };

  removeSinglePhase = ({ daysMaskOrDaysType, uuid }) => {
    const { phases, phasesSubstractionOrder } = this.props;

    const phasesByDays = this.serialize(phases);
    const daysPhases = phasesByDays[daysMaskOrDaysType] || [];

    phasesByDays[daysMaskOrDaysType] = daysPhases.filter((e) => e.uuid !== uuid);

    this.update({
      phases: this.deserialize(phasesByDays),
      phasesSubstractionOrder: phasesSubstractionOrder.filter((e) => e !== uuid),
    });
  };

  changePhase = ({ uuid, field, value }) => {
    const { phases } = this.props;
    const phase = phases.filter((p) => p.uuid === uuid)[0];
    phase[field] = value;

    this.update({ phases });
  };

  onDayNightSplitToggle = (daysMaskOrDaysType) => {
    const { phases, phasesSubstractionOrder, hoursBankActive } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;
    const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
    const phasesByDays = this.serialize(phases);
    const daysPhases = phasesByDays[daysMaskOrDaysType] || [];
    const oldPhasesUuids = daysPhases.map((p) => p.uuid);
    let newPhasesSubstractionOrder = phasesSubstractionOrder;

    if (hoursBankActive) {
      newPhasesSubstractionOrder = phasesSubstractionOrder.filter((e) => oldPhasesUuids.indexOf(e) === -1);
    }

    const isDayNightSplitActivated =
      phasesByDays[daysMaskOrDaysType].findIndex((phase) => phase.type === PhaseType.combined) === -1;

    phasesByDays[daysMaskOrDaysType] = [];

    if (isDayNightSplitActivated) {
      // if split active -  add one combined phase
      phasesByDays[daysMaskOrDaysType] = [
        this.getNewPhase({
          [key]: daysMaskOrDaysType,
          extraHours: !hoursBankActive ? 100 : null,
          limit: -1,
          type: PhaseType.combined,
        }),
      ];
    } else {
      // if split active -  add one day and one night phase
      phasesByDays[daysMaskOrDaysType] = [
        this.getNewPhase({
          [key]: daysMaskOrDaysType,
          limit: -1,
          extraHours: !hoursBankActive ? 100 : null,
          type: "day",
        }),
        this.getNewPhase({
          [key]: daysMaskOrDaysType,
          limit: -1,
          extraHours: !hoursBankActive ? 100 : null,
          type: "night",
        }),
      ];
    }

    if (hoursBankActive) {
      newPhasesSubstractionOrder = [
        ...newPhasesSubstractionOrder,
        ...phasesByDays[daysMaskOrDaysType].map((e) => e.uuid),
      ];
    }

    this.update({
      phases: this.deserialize(phasesByDays),
      phasesSubstractionOrder: newPhasesSubstractionOrder,
    });
  };

  splitDaysToggle = (isDayTypeBasedOnSchedule) => {
    const { rawPhases, rawPhasesSubstractionOrder } = this.props;
    const isRawPhasesDayTypeBasedOnSchedule = !!rawPhases[0].dayTypeBasedOnSchedule;
    let phases;
    let phasesSubstractionOrder = [];

    this.setState({ isDayTypeBasedOnSchedule });

    // get new default phases if toggle state is different than initial
    if (isRawPhasesDayTypeBasedOnSchedule !== isDayTypeBasedOnSchedule) {
      if (isDayTypeBasedOnSchedule) {
        const workingDaysPhase = this.getNewPhase({
          limit: -1,
          dayTypeBasedOnSchedule: DAY_TYPES_BASED_ON_SCHEDULE.WORKING,
        });
        delete workingDaysPhase.daysMask;

        const nonWorkingDaysPhase = this.getNewPhase({
          limit: -1,
          dayTypeBasedOnSchedule: DAY_TYPES_BASED_ON_SCHEDULE.NON_WORKING,
        });
        delete nonWorkingDaysPhase.daysMask;

        phases = [workingDaysPhase, nonWorkingDaysPhase];
      } else {
        phases = [
          this.getNewPhase({ limit: -1, daysMask: "11111100" }),
          this.getNewPhase({ limit: -1, daysMask: "00000011" }),
        ];
      }
      phasesSubstractionOrder = phases.map((p) => p.uuid);
    } else {
      // bring back raw phases if toggle returns to its initial state
      phases = rawPhases;
      phasesSubstractionOrder = rawPhasesSubstractionOrder;
    }

    this.update({ phases, phasesSubstractionOrder });
  };

  dateMaskChange = ({ oldDaysMaskOrDaysType, newDaysMaskOrDaysType }) => {
    const { isDayTypeBasedOnSchedule } = this.state;
    const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
    let { phases } = this.props;

    phases = phases.map((p) => {
      const phase = p;
      if (p[key] === oldDaysMaskOrDaysType) {
        phase[key] = newDaysMaskOrDaysType;
      }
      return phase;
    });

    this.update({ phases });
  };

  update = ({ phases, phasesSubstractionOrder, phasesType, multiplierType, customPhasesSubtractionOrder }) => {
    const customOrder =
      typeof customPhasesSubtractionOrder !== "undefined"
        ? customPhasesSubtractionOrder
        : this.props.customPhasesSubtractionOrder;

    this.props.onPhasesChange({
      phases: phases || this.props.phases,
      phasesSubstractionOrder: phasesSubstractionOrder || this.props.phasesSubstractionOrder,
      phasesType: phasesType || this.props.phasesType,
      multiplierType: multiplierType || this.props.multiplierType,
      customPhasesSubtractionOrder: customOrder,
    });
  };

  // get human readable phase days options
  getDaysOptions = (selectedDays) => {
    const { t } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;
    const { daysMasksObj, order } = getPhasesDays({ t, isDayTypeBasedOnSchedule });
    let days = order.map((d) => ({ value: d, label: daysMasksObj[d] }));

    days = days.filter((d) => selectedDays.indexOf(d.value) === -1);

    return days;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(this.props.phasesSubstractionOrder, oldIndex, newIndex);
    this.update({ phasesSubstractionOrder: newOrder });
  };

  // check if Phases cover all days and not overlapping
  checkMask = (phases) => {
    const { t } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;

    if (!isDayTypeBasedOnSchedule) {
      const uniqDaysMaskSum = phases
        .reduce((acc, p) => {
          if (acc.indexOf(p.daysMask) !== -1 || p.daysMask === DSR_PHASE_KEY) {
            return acc;
          }

          acc.push(p.daysMask);
          return acc;
        }, [])
        .reduce((acc, daysMask) => acc + parseInt(daysMask, 2), 0);

      if (uniqDaysMaskSum > 255) {
        return t("Some days are overlaping");
      }

      if (uniqDaysMaskSum < 255) {
        return t("Not whole range is covered");
      }
    }

    return "";
  };

  convertPercentageMultiplier = (val, phases) => {
    const newPhases = phases.map((phase) => {
      let value = phase.name;

      if (val === PHASE_TYPES.PERCENTAGE) {
        value = parseFloat(value);
        value = Number.isFinite(value) ? value : 0;
        value = parseInt(((value - 1) * 100).toFixed(0), 10);
      } else if (val === PHASE_TYPES.MULTIPLIER) {
        value = value ? parseInt(value, 10) : 0;
        value = parseFloat((value / 100 + 1).toFixed(2));
      }

      if (value < 0) {
        value = 0;
      }

      return { ...phase, name: value };
    });

    return newPhases;
  };

  onPercentageMultiplierChange = (val, phases) => {
    this.update({
      phases: this.convertPercentageMultiplier(val, phases),
      phasesType: val,
      multiplierType: MULTIPLIER_TYPES[val === PHASE_TYPES.PERCENTAGE ? "DISABLED" : "REALTIME"],
    });
  };

  normalizePhases = (isDayTypeBasedOnSchedule, phases) => {
    if (isDayTypeBasedOnSchedule && phases.find(phase => phase.daysMask)) {
      const dsrPhase = phases.find(phase => phase?.daysMask === DSR_PHASE_KEY)
      if (dsrPhase) {
        dsrPhase.dayTypeBasedOnSchedule = DSR_PHASE_KEY;
        dsrPhase.daysMask = undefined;
      }
    }
  }

  render() {
    const {
      phasesSubstractionOrder,
      t,
      phases,
      errors,
      hoursBankActive,
      disabled,
      locked,
      phasesType,
      multiplierType,
      customPhasesSubtractionOrder,
    } = this.props;
    const { isDayTypeBasedOnSchedule } = this.state;
    const inactive = disabled || locked;
    this.normalizePhases(isDayTypeBasedOnSchedule, phases);
    const phaseOrder = phases
      .map((p) => p[isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask"])
      .filter((v, i, a) => a.indexOf(v) === i);

    const phasesByDays = this.serialize(phases);

    return (
      <Wrapper>
        {hoursBankActive && (
          <>
            <FieldWrapper
              fieldName={t("split-days-by-label")}
              marginBottom={13}
              fieldTitleMarginTop={23}
              fieldTitleMarginBottom={8}
            >
              <RadioButtonsWrapper>
                <RadioButton
                  disabled={disabled || locked}
                  checked={!isDayTypeBasedOnSchedule}
                  value={PHASE_TYPES.PERCENTAGE}
                  label={t("Specific days")}
                  id="split-days-by-spcecific-days"
                  name="split-days-by-spcecific-days"
                  onChange={() => this.splitDaysToggle(false)}
                />
                <RadioButton
                  disabled={disabled || locked}
                  checked={isDayTypeBasedOnSchedule}
                  value={PHASE_TYPES.MULTIPLIER}
                  label={t("Planned & Non planned days")}
                  id="split-days-by-shcedule"
                  name="split-days-by-shcedule"
                  onChange={() => this.splitDaysToggle(true)}
                />
              </RadioButtonsWrapper>
            </FieldWrapper>

            <FieldWrapper
              fieldName={t("compensation-calculation-label")}
              fieldTitleMarginBottom={8}
              fieldTitleMarginTop={24}
            >
              <RadioButtonsWrapper>
                <RadioButton
                  disabled={disabled || locked}
                  checked={phasesType === PHASE_TYPES.PERCENTAGE}
                  value={PHASE_TYPES.PERCENTAGE}
                  label={t("Percentage")}
                  id="phases-type-percentage"
                  name="phases-type-percentage"
                  onChange={(val) => this.onPercentageMultiplierChange(val.target.value, phases)}
                />
                <RadioButton
                  disabled={disabled || locked}
                  checked={phasesType === PHASE_TYPES.MULTIPLIER}
                  value={PHASE_TYPES.MULTIPLIER}
                  label={t("Multipliers")}
                  id="phases-type-multiplier"
                  name="phases-type-multiplier"
                  onChange={(val) => this.onPercentageMultiplierChange(val.target.value, phases)}
                />
              </RadioButtonsWrapper>
            </FieldWrapper>
          </>
        )}

        {phasesType === PHASE_TYPES.MULTIPLIER && (
          <FieldWrapper fieldName={t("Multiplier Type")} width="250px" fieldTitleMarginTop={24}>
            <SelectControl
              options={this.multiplierTypeOptions}
              value={this.multiplierTypeOptions.find((o) => o.value === multiplierType)}
              onChange={(o) => this.update({ multiplierType: o.value })}
              isDisabled
            />
          </FieldWrapper>
        )}
        {!!phaseOrder?.length && (
          <PhasesTable
            arrayWithOrderedPhasesMasks={phaseOrder}
            onlyEH={!hoursBankActive}
            disabled={disabled}
            locked={locked}
            phasesByDays={phasesByDays}
            getDaysOptions={this.getDaysOptions}
            isDayTypeBasedOnSchedule={isDayTypeBasedOnSchedule}
            phasesType={phasesType}
            errors={errors}
            onDateMaskChange={this.dateMaskChange}
            onRemoveDayWithPhase={Object.keys(phasesByDays).length > 1 ? this.removeDayWithPhase : null}
            onAddPhase={this.addSinglePhase}
            onRemovePhase={this.removeSinglePhase}
            onPhaseChange={this.changePhase}
            onDayNightSplitToggle={this.onDayNightSplitToggle}
          />
        )}
        {this.checkMask(phases) ? <ErrorLabel>{this.checkMask(phases)}</ErrorLabel> : null}
        {!inactive && !isDayTypeBasedOnSchedule && this.getDaysOptions(Object.keys(phasesByDays)).length ? (
          <div className={b("add-phase-wrapper")}>
            <AddRemoveLink label={t("New Phase")} onClick={this.addDayWithPhase} />
          </div>
        ) : (!inactive && this.isAddDsrAvailable() ? (
          <div className={b("add-phase-wrapper")}>
            <AddRemoveLink label={t("New Phase")} onClick={this.addDsrDayBasedOnSchedule} />
          </div>
        ) : (
          <div style={{ height: "15px" }} />
        ))}
        {window.global_store.beta && hoursBankActive && phasesSubstractionOrder && phasesSubstractionOrder.length ? (
          <>
            <Subheader>{t("Substraction Order from Banco de Horas")}</Subheader>
            <FieldWrapper>
              <CheckboxControl
                label={t("Enable Custom Ordering")}
                checked={customPhasesSubtractionOrder}
                disabled={inactive}
                onChange={(checked) => this.update({ customPhasesSubtractionOrder: checked })}
              />
            </FieldWrapper>
            <SortableList
              disabled={inactive || !customPhasesSubtractionOrder}
              items={phasesSubstractionOrder.map((soUuid) => {
                const selectedPhase = phases.filter((ph) => ph.uuid === soUuid)[0];
                const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
                let name = `${this.getDaysOptions([]).filter((day) => day.value === selectedPhase[key])[0].label} ${
                  selectedPhase.name
                }%`;

                if (selectedPhase?.type === PhaseType.day) {
                  name = `${name} (${t("Day")})`;
                } else if (selectedPhase?.type === PhaseType.night) {
                  name = `${name} (${t("Night")})`;
                }

                return { uuid: selectedPhase.uuid, name };
              })}
              onSortEnd={this.onSortEnd}
            />
          </>
        ) : null}
      </Wrapper>
    );
  }
}

export default withTranslation(["company-rules", "phases"])(HbEhPhases);
