import moment from "moment";
import { Component } from "react";
import { WithTranslation } from "react-i18next";
import styled from "styled-components";
import { Notification, NotificationType } from "types/common";
import NotificationRow from "../NotificationRow";

const NotificationWrapper = styled.div`
  margin-inline-start: 2px;
`;

const NotificationTextWrapper = styled.div``;

const NotificationTitle = styled.div`
  font-size: 16px;
  color: var(--colors-surface-900-p);
  line-height: 24px;
  margin-top: 9px;
`;

const NotificationText = styled.div`
  font-size: 16px;
  color: var(--colors-unknown39);
  line-height: 24px;
  margin-top: 3px;
`;

const NotificationDate = styled.div`
  font-size: var(--typography-font-size-default);
  color: var(--colors-unknown39);
  line-height: 24px;
  margin-top: 3px;
`;

const NotificationRefresh = styled.button`
  background: var(--colors-surface-0);
  box-shadow: 0 1px 3px 0 rgba(67, 78, 108, 0.2);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  text-align: start;
  line-height: 24px;
  border: none;
  border-radius: var(--shapes-border-radius-default);
  height: 36px;
  padding: 0 15px 0 20px;
  outline: none;
  margin-top: 15px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    background: var(--colors-surface-50);
  }
`;

interface InProgressRowProps {
  t: WithTranslation["t"];
  onRefresh?: () => void;
}

interface InProgressRowState {
  recalculatingInProgress: boolean;
  title: string;
  text: string;
  lastUpdatedTime: moment.MomentInput | null;
  refreshButtonDisabled?: boolean;
}

class InProgressRow extends Component<InProgressRowProps, InProgressRowState> {
  constructor(props: InProgressRowProps) {
    super(props);
    const { t } = props;

    this.state = {
      recalculatingInProgress: false,
      title: t("Report is being updated"),
      text: t("The highlighted rows are still calculating, please refresh"),
      lastUpdatedTime: null,
      refreshButtonDisabled: false,
    };
  }

  componentDidMount() {
    document.addEventListener("reports_inProgress", this.inProgressChange);
  }

  componentWillUnmountMount() {
    document.removeEventListener("reports_inProgress", this.inProgressChange);
  }

  // todo type
  inProgressChange = (ev: any) => {
    const {
      recalculatingInProgress,
      customMessage,
    }: { recalculatingInProgress: boolean; customMessage?: Record<"title" | "text" | "lastUpdatedTime", string> } =
      ev.detail;

    if (customMessage) {
      const { title, text, lastUpdatedTime } = customMessage;
      this.setState({ recalculatingInProgress, title, text, lastUpdatedTime: lastUpdatedTime || null });
    } else {
      this.setState({ recalculatingInProgress });
    }
  };

  render() {
    const { t, onRefresh } = this.props;
    const { recalculatingInProgress, title, text, lastUpdatedTime, refreshButtonDisabled } = this.state;
    const notification: Partial<Notification> = {};

    notification.notificationType = NotificationType.progress;
    notification.notification = (
      <NotificationWrapper>
        <NotificationTextWrapper>
          <NotificationTitle>{title}</NotificationTitle>
          <NotificationText>{text}</NotificationText>
          {lastUpdatedTime && (
            <NotificationDate>{`${t("Last Updated time")} ${moment(lastUpdatedTime).format(
              "DD/MM/YY - HH:mm",
            )}`}</NotificationDate>
          )}
        </NotificationTextWrapper>
        <NotificationRefresh
          disabled={refreshButtonDisabled}
          onClick={() => {
            if (onRefresh) {
              this.setState({ refreshButtonDisabled: true });
              onRefresh();
              setTimeout(() => {
                this.setState({ refreshButtonDisabled: false });
              }, 10000);
            }
          }}
        >
          {t("Refresh")}
        </NotificationRefresh>
      </NotificationWrapper>
    );

    return (
      <div>
        {recalculatingInProgress ? (
          <NotificationRow
            style={{ marginBottom: "20px", marginTop: "20px" }}
            type={notification.notificationType}
            message={notification.notification}
          />
        ) : null}
      </div>
    );
  }
}

export default InProgressRow;
