import { ReactNode } from "react";
import BEM from "utils/BEM";
import "./Page.scss";

const b = BEM.b("page");

interface PageWrapperProps {
  children: ReactNode;
}

const Layout = ({ children }: PageWrapperProps): JSX.Element => (
  <div className={b()}>
    <main className={b("content")}>{children}</main>
  </div>
);

export default Layout;
