import styled from "styled-components";
import moment from "moment";
import TimeControl from "components/controls/TimeControl";
import FieldWrapper from "components/UI/FieldWrapper";
import SingleDatePickerControl from "components/controls/DatePicker/SingleDatePickerControl";

const Wrapper = styled.div`
  display: flex;
  margin: 8px 0;
  > div {
    flex: 1;
  }
  > div:first-child {
    margin-inline-end: 10px;
  }
`;

interface DateTiemRowProps {
  date: moment.Moment;
  dateLabel: string;
  timeLabel: string;
  time: string;
  onDateChange: (date: moment.Moment) => void;
  onTimeChange: (data: { value: string; error: boolean }) => void;
  dateError: boolean;
  timeError: string | null;
  timeDisabled?: boolean;
  isOutsideRange: (date: moment.Moment) => boolean;
}

export default ({
  date,
  dateLabel,
  timeLabel,
  time,
  onDateChange,
  onTimeChange,
  dateError,
  timeError,
  timeDisabled,
  isOutsideRange,
}: DateTiemRowProps) => (
  <Wrapper>
    <FieldWrapper fieldName={dateLabel} width="100%" marginBottom={5}>
      <SingleDatePickerControl
        numberOfMonths={1}
        error={dateError}
        value={date}
        onChange={onDateChange}
        isOutsideRange={isOutsideRange}
      />
    </FieldWrapper>
    <FieldWrapper fieldName={timeLabel} width="100%" fieldTitleMarginBottom={0}>
      <TimeControl
        value={time}
        error={!!timeError}
        disabled={timeDisabled}
        onTimeChange={onTimeChange}
        placeholder="00:00"
      />
    </FieldWrapper>
  </Wrapper>
);
