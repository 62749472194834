import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import { hasEmployeesAccess } from "utils/common";
import { downloadAfdReport } from "utils/apiHelpers";
import ga from "utils/ga";
import { ReportType } from "types/reports";
import { SelectOption } from "types/ui";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";
import ModalDialog from "components/UI/ModalDialog";
import Select from "components/UI/Select";
import styled from "styled-components";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { getReportsPageTitle } from "./ReportsTitle";
import { Page, SourceTypeWrapper } from "./reportsStyledComponents";
import ReportsPopupAddCnpj from "./ReportsPopupAddCnpj";
import ReportSearchFiltersBase from "./ReportSearchFiltersBase";

const AFDReportPage = styled(Page)`
  min-height: 512px;
`;

interface ReportsPageAfdState {
  cnpjPopupOpen: boolean;
  sourceType: string;
  isGenerateButtonLocked: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

type ReportsPageAfdProps = WithTranslation;

const SOURCE_TYPES: SelectOption[] = [
  { value: "afd", label: "AFD" },
  { value: "afdt", label: "AFDT" },
  { value: "afd_v2", label: "Novo AFD (671-1486)" },
  { value: "aej", label: "AEJ - 671" },
];

class ReportsPageAfd extends Component<ReportsPageAfdProps, ReportsPageAfdState> {
  constructor(props: ReportsPageAfdProps) {
    super(props);
    this.state = {
      cnpjPopupOpen: false,
      sourceType: SOURCE_TYPES[0].value,
      isGenerateButtonLocked: false,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
    };
  }

  render(): JSX.Element {
    const { t } = this.props;
    const { cnpjPopupOpen, sourceType, startDate, endDate, isGenerateButtonLocked } = this.state;

    return (
      <FullPage title={getReportsPageTitle(t, ReportType.afd)}>
        <AFDReportPage>
          <ReportSearchFiltersBase
            onGenerate={() => {
              ga.trackGenerateReport(ReportType.afd);

              this.setState({ cnpjPopupOpen: true });
            }}
            generateButtonDisabled={isGenerateButtonLocked}
          >
            <SourceTypeWrapper>
              <Select
                value={sourceType}
                onChange={(value: string) => this.setState({ sourceType: value, isGenerateButtonLocked: false })}
                options={SOURCE_TYPES.map((o) => ({ ...o, label: t(o.label) }))}
              />
            </SourceTypeWrapper>
            <DateRangePicker
              newOnChangeApproach
              availableDaysCount={window.global_store.beta ? 1900 : 186}
              isAdmin={hasEmployeesAccess()}
              onChange={(sd, ed) => {
                if (sd && ed) {
                  this.setState({ startDate: sd, endDate: ed, isGenerateButtonLocked: false });
                }
              }}
              startDate={startDate}
              endDate={endDate}
            />
          </ReportSearchFiltersBase>

          <ModalDialog isOpen={cnpjPopupOpen} onClose={() => this.setState({ cnpjPopupOpen: false })}>
            <ReportsPopupAddCnpj
              onClose={() => this.setState({ cnpjPopupOpen: false })}
              onYes={({ cnpj, cpf }: { cnpj: string; cpf: string }) => {
                this.setState({ cnpjPopupOpen: false, isGenerateButtonLocked: true });

                void downloadAfdReport({
                  companyUUID: window.global_store.company.uuid,
                  startDate,
                  endDate,
                  sourceType,
                  cnpj,
                  cpf,
                });
                setTimeout(() => {
                  this.setState({ isGenerateButtonLocked: false });
                }, 5000);
              }}
            />
          </ModalDialog>
        </AFDReportPage>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsPageAfd);
