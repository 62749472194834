import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import RequestsPage from "components/Requests/RequestsPage";

const RouteRequests = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect exact strict from={`${path}`} to={`${path}/`} />

      <Route exact strict path={`${path}/`} component={RequestsPage} />
    </Switch>
  </Suspense>
);

export default RouteRequests;
