import { stylesheet } from "astroturf";
import cx from "classnames";

const styles = stylesheet`
.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: rgb(82 95 127 / 60%); /* --colors-text1 with 60% opacity */
}
`;

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  onBackgroundClick?(): void;
}

export function Modal({ children, onBackgroundClick, className, ...props }: ModalProps) {
  return (
    <div className={cx(styles.Background, className)} onClick={onBackgroundClick} {...props}>
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
}
