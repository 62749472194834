import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import Debug from "debug";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { WidgetProjectsService, ActivitiesDay } from "services/widget-projects-service";
import { minsToHrsMins, hrsMinsToMins } from "utils/common";
import { useProfile } from "context/user-profile-context";
import { useAsyncCallbackCached } from "utils/useAsyncCallbackCached";
import { useDialog } from "utils/useDialog";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  useWidgetProjectsService,
  usePunchNowService,
  useProjectsEventTracking,
  useProjectsWsService,
  useProjectsService,
} from "utils/useServices";
import { v4 as uuidv4 } from "uuid";
import { SyncIndicator } from "../SyncIndicator";
import { ActivitiesView } from "../ActivitiesView";
import { ActivityEdit } from "../ActivityEdit";
import { Modal } from "../Modal";
import {
  ActivityStoppedOnPunchOutDialog,
  ActivityIncompleteStoppedOnPunchOutDialog,
  OutsideOfScheduleDialog,
  OutsideOfPunchInDialog,
} from "../ModalDialog";

const debug = Debug("Projects");

export function Projects() {
  const widgetProjectsService = useWidgetProjectsService();
  const projectsWsService = useProjectsWsService();
  const projectsEventTracking = useProjectsEventTracking();
  const punchNowService = usePunchNowService();
  const projectsService = useProjectsService();
  useEffect(() => {
    debug("mount", new Date().toISOString());
    return () => debug("unmount");
  }, []);
  useEffect(() => projectsEventTracking.trackInit(), [projectsEventTracking]);

  const history = useHistory();
  const routeMatch = useRouteMatch();

  // --- load data
  const [projects, setProjects] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [lastLockDate, setLastLockDate] = useState<string>();
  const [runningActivity, setRunningActivity] =
    useState<Awaited<ReturnType<WidgetProjectsService["getRunningActivity"]>>>(null);
  const [days, setDays] = useState<ActivitiesDay[]>([]);
  const [hasMoreDays, setHasMoreDays] = useState(true);
  const [daysGen, setDaysGen] = useState<AsyncGenerator | null>(null);
  const [companyRules, setCompanyRules] = useState<any>(null);

  const [loadRunningActivity, loadingRunningActivity] = useAsyncCallback(async () => {
    const runningAct = await widgetProjectsService.getRunningActivity();
    setRunningActivity(runningAct);
  }, [widgetProjectsService]);

  const [loadDaysInit] = useAsyncCallback(async () => {
    setDaysGen(null);
    const gen = widgetProjectsService.getDays();
    const next = await gen.next();
    const firstDays = next.value;
    return [firstDays, gen];
  }, [widgetProjectsService]);

  const profile = useProfile();
  const initCounterRef = useRef(0);
  const [loadData, loadingData, data, loadingDataBg] = useAsyncCallbackCached(
    async () => {
      initCounterRef.current += 1;
      const counter = initCounterRef.current;
      const promises = [
        widgetProjectsService.listProjects(),
        widgetProjectsService.listLocations(),
        widgetProjectsService.getLockDate(),
        widgetProjectsService.getRunningActivity(),
        loadDaysInit(),
        projectsService.getCompanyRules(),
      ];
      const res = await Promise.all(promises);
      if (counter !== initCounterRef.current) return null; // discard data, because initDays was called since loading started
      return res;
    },
    [widgetProjectsService, loadDaysInit, projectsService],
    {
      key: "projects.loadData",
      vary: profile!.uuid,
      ttl: "P3D",
      delay: 400,
    },
  );
  useEffect(() => {
    if (data == null) return;
    const [prjs, locs, lld, runningAct, [dayz, dayzGen], companyRulez] = data;
    setProjects(prjs);
    setLocations(locs);
    setLastLockDate(lld);
    setRunningActivity(runningAct);
    setDays(dayz);
    setDaysGen(dayzGen?.constructor === Object ? null : dayzGen);
    setCompanyRules(companyRulez);
  }, [data, punchNowService]);
  useEffect(() => void loadData(), [loadData]);

  const [loadDays, loadingDays] = useAsyncCallback(async () => {
    const counter = initCounterRef.current;
    const next = await daysGen!.next();
    if (counter !== initCounterRef.current) return;
    if (next.value != null) {
      setDays((dayz) => [...dayz, ...next.value]);
    }
    setHasMoreDays(!next.done);
  }, [daysGen]);

  const [today] = useState(moment().format("YYYY-MM-DD"));

  const [connectWs] = useAsyncCallback(async () => {
    await projectsWsService?.connect();
    projectsWsService?.on("activity-updated", () => void loadData());
  }, [projectsWsService, loadData]);
  useEffect(() => {
    void connectWs();
    return () => void projectsWsService?.disconnect();
  }, [connectWs, projectsWsService]);

  // --- time
  const [date, setDate] = useState(new Date());
  const [ticking, setTicking] = useState(false);
  useEffect(() => setTicking(runningActivity != null), [runningActivity]);
  useEffect(() => {
    if (!ticking) return undefined;
    setDate(new Date());
    const intervalId = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, [ticking]);
  const [duration, setDuration] = useState<string>("");
  useEffect(() => {
    if (runningActivity == null) return;
    if (runningActivity.endTime !== 0) {
      const d = minsToHrsMins(runningActivity.duration);
      setDuration(d);
      return;
    }
    const m = moment(date);

    const createdAt = new Date(runningActivity.createdAt);
    const seconds = createdAt.getSeconds();
    const start = moment(
      `${runningActivity.date} ${minsToHrsMins(runningActivity.startTime)}:${Number.isNaN(seconds) ? "00" : seconds}`,
      "YYYY-MM-DD HH:mm:ss",
    );
    const diffTotalSecs = m.diff(start, "seconds");
    const durSeconds = diffTotalSecs % 60;
    const dur = `${minsToHrsMins(Math.floor(diffTotalSecs / 60))}:${String(durSeconds).padStart(2, "0")}`;
    setDuration(dur);
  }, [date, runningActivity]);

  // --- manipulate activities
  const [editedActivity, setEditedActivity] = useState<any>(null);

  const [showingOutsideOfScheduleDialog, setShowingOutsideOfScheduleDialog] = useState(false);
  const [showingOutsideOfPunchInDialog, setShowingOutsideOfPunchInDialog] = useState(false);

  const [loadShifts, loadingShifts, shifts] = useAsyncCallback(() => punchNowService.getShifts(), [punchNowService]);
  useEffect(() => void loadShifts(), [loadShifts]);
  const [loadPunches, loadingPunches, punches] = useAsyncCallback(
    () => punchNowService.getPunches(),
    [punchNowService],
  );
  useEffect(() => void loadPunches(), [loadPunches]);

  const openAddActivity = () => {
    const now = moment();
    const startTime = hrsMinsToMins(
      moment.max(now.clone().startOf("hour").subtract(1, "hour"), now.clone().startOf("day")).format("HH:mm"),
    );
    const endTime = hrsMinsToMins(now.clone().startOf("hour").format("HH:mm"));
    const act = {
      uuid: undefined,
      date: today,
      startTime,
      endTime,
      duration: endTime - startTime,
      projectUuid: null,
      taskUuid: null,
      locationUuid: null,
      customFields: [],
      attachments: [],
      status: "pending",
    };
    setEditedActivity(act);
  };

  const [startActivity, startingActivity] = useAsyncCallback(async () => {
    if (companyRules.restrictActivitiesBasedOnSchedule) {
      const isInsideSchedule = punchNowService.checkIfTimeInsideSchedule(shifts, new Date());
      if (!isInsideSchedule) {
        setShowingOutsideOfScheduleDialog(true);
        return;
      }
    }
    if (companyRules.restrictActivitiesBasedOnPunchIn) {
      const isPunchedIn = punchNowService.checkIfPunchedIn(punches!);
      if (!isPunchedIn) {
        setShowingOutsideOfPunchInDialog(true);
        return;
      }
    }

    const now = moment();
    const tempNewActivity = {
      uuid: uuidv4(),
      date: now.format("YYYY-MM-DD"),
      createdAt: now.toISOString(), // needed only for time to start from 0
      startTime: hrsMinsToMins(now.format("HH:mm")),
      endTime: 0,
      projectUuid: null,
      taskUuid: null,
      locationUuid: null,
      customFields: [],
      attachments: [],
      status: "running",
    };
    setEditedActivity(tempNewActivity); // set edited activity immediately, without waiting for the response to make the UI more responsive

    projectsEventTracking.trackStartActivity({}, "start btn");
    const started = await widgetProjectsService.startActivity();
    setEditedActivity(started);
    await loadRunningActivity();
  }, [
    loadRunningActivity,
    widgetProjectsService,
    projectsEventTracking,
    shifts,
    punchNowService,
    companyRules,
    punches,
  ]);

  const [removeActivity, removingActivity] = useAsyncCallback(
    async (act) => {
      projectsEventTracking.trackDeleteActivity(act);
      await widgetProjectsService.deleteActivity(act);
      setRunningActivity(null);
      history.push(routeMatch.path);
      setEditedActivity(null);
      await loadData();
    },
    [widgetProjectsService, loadData, projectsEventTracking, history, routeMatch],
  );

  const [isInvalid, setIsInvalid] = useState(false);
  const [shouldRepeat, setShouldRepeat] = useState(false);
  const shouldStopAfterUpdateRef = useRef(false);
  const shouldRepeatAfterStopRef = useRef<any>();
  useEffect(() => {
    if (editedActivity != null) return;
    setIsInvalid(false);
    setShouldRepeat(false);
    shouldStopAfterUpdateRef.current = false;
    shouldRepeatAfterStopRef.current = null;
  }, [editedActivity]);

  const [stopActivity, stoppingActivity] = useAsyncCallback(
    async (act1, tsk1?, prj?) => {
      const tsk = tsk1 == null ? null : { ...tsk1 };
      const act = { ...act1 };
      if (prj != null) {
        const projectUuid = await widgetProjectsService.createProject(prj);
        tsk.projectUuid = projectUuid;
        act.projectUuid = projectUuid;
      }
      if (tsk != null) {
        const taskUuid = await widgetProjectsService.createTask(tsk);
        act.taskUuid = taskUuid;
      }

      if (act.taskUuid == null) {
        if (editedActivity?.taskUuid !== act.taskUuid) {
          setEditedActivity(act);
          setIsInvalid(true);
        }
        shouldStopAfterUpdateRef.current = true;
      } else {
        projectsEventTracking.trackStopActivity(act);
        const updd = await widgetProjectsService.stopActivity(act);
        if (editedActivity != null) {
          setEditedActivity(updd);
        }
        await loadData();
      }
    },
    [editedActivity, loadData, widgetProjectsService, projectsEventTracking],
  );

  const [saveActivity, savingActivity] = useAsyncCallback(
    async (act1, tsk1?, prj?) => {
      const tsk = tsk1 == null ? null : { ...tsk1 };
      const act = { ...act1 };
      if (prj != null) {
        const projectUuid = await widgetProjectsService.createProject(prj);
        tsk.projectUuid = projectUuid;
        act.projectUuid = projectUuid;
      }
      if (tsk != null) {
        const taskUuid = await widgetProjectsService.createTask(tsk);
        act.taskUuid = taskUuid;
      }

      if (act.uuid == null) {
        const cre = { ...act, status: "pending" };
        projectsEventTracking.trackAddActivity(cre);
        await widgetProjectsService.createActivity(cre);
      } else if (shouldStopAfterUpdateRef.current) {
        await stopActivity(act, undefined, undefined);
        setRunningActivity(null);
        history.push(routeMatch.path);
        setEditedActivity(undefined);
        if (shouldRepeatAfterStopRef.current != null) {
          const repeated = shouldRepeatAfterStopRef.current;
          projectsEventTracking.trackStartActivity({}, "repeat after stop");
          await widgetProjectsService.repeatActivity(repeated);
          await loadData();
        }
        return act; // no need to initDays() - it was done during stopActivity
      } else {
        projectsEventTracking.trackUpdateActivity(act);
        await widgetProjectsService.updateActivity(act);
      }
      setRunningActivity(null);
      history.push(routeMatch.path);
      setEditedActivity(undefined);
      await loadData();
    },
    [loadData, widgetProjectsService, stopActivity, projectsEventTracking, history, routeMatch],
  );

  const [repeatActivity, repeatingActivity] = useAsyncCallback(
    async (act) => {
      if (companyRules.restrictActivitiesBasedOnSchedule) {
        const isInsideSchedule = punchNowService.checkIfTimeInsideSchedule(shifts, new Date());
        if (!isInsideSchedule) {
          setShowingOutsideOfScheduleDialog(true);
          return;
        }
      }
      if (companyRules.restrictActivitiesBasedOnPunchIn) {
        const isPunchedIn = punchNowService.checkIfPunchedIn(punches!);
        if (!isPunchedIn) {
          setShowingOutsideOfPunchInDialog(true);
          return;
        }
      }

      let eventSource = "repeat";
      if (runningActivity != null) {
        if (runningActivity?.taskUuid == null) {
          shouldRepeatAfterStopRef.current = act;
          setShouldRepeat(true);
          await stopActivity(runningActivity, undefined, undefined);
          return;
        }
        await stopActivity(runningActivity, undefined, undefined);
        eventSource = "repeat with autostop";
      }

      projectsEventTracking.trackStartActivity({}, eventSource);
      await widgetProjectsService.repeatActivity(act);
      await loadRunningActivity();
    },
    [
      runningActivity,
      loadRunningActivity,
      widgetProjectsService,
      stopActivity,
      projectsEventTracking,
      shifts,
      companyRules,
      punchNowService,
      punches,
    ],
  );

  // --- stop running activity on punch out or by schedule
  const [showingViewStoppedDialog, askViewStopped, respondViewStopped] = useDialog<boolean>();
  const [showingFillSemistoppedDialog, askFillSemistopped, respondFillSemistopped] = useDialog<boolean>();

  const [stopActivityWithDialog] = useAsyncCallback(
    async (actToStop) => {
      const validationErrors = widgetProjectsService.validateActivity(actToStop);
      const canStop = validationErrors == null;
      if (canStop) {
        const stoppedAct = await widgetProjectsService.stopActivity(actToStop);
        const res = await askViewStopped();
        if (res) {
          history.push(`projects/${stoppedAct.uuid}`);
        }
      }
      if (!canStop) {
        const semistoppedAct = await widgetProjectsService.stopActivity(actToStop);
        const res = await askFillSemistopped();
        if (res) {
          history.push(`projects/running`);
        } else {
          await widgetProjectsService.deleteActivity(semistoppedAct);
          setRunningActivity(null);
        }
      }
    },
    [widgetProjectsService, askViewStopped, askFillSemistopped, history],
  );

  const [checkOutscheduled] = useAsyncCallback(async () => {
    const isSemistopped = runningActivity?.stopReason != null;
    if (isSemistopped) return;
    const predictedEndAt = runningActivity?.predictedEndAt == null ? null : new Date(runningActivity.predictedEndAt);
    if (predictedEndAt == null) return;
    const now = moment();
    const isOutscheduled = now.isAfter(predictedEndAt);
    if (isOutscheduled) {
      const endTime = hrsMinsToMins(moment(predictedEndAt).format("HH:mm"));
      const actToStop = { ...runningActivity, endTime, stopReason: "schedule", status: "pending" };
      await stopActivityWithDialog(actToStop);
    }
  }, [runningActivity, stopActivityWithDialog]);

  // --- check for semistopped activity
  const [checkSemistopped] = useAsyncCallback(async () => {
    const isSemistopped = runningActivity?.stopReason != null;
    if (!isSemistopped) return;
    const res = await askFillSemistopped();
    if (res) {
      history.push(`projects/running`);
    } else {
      await widgetProjectsService.deleteActivity(runningActivity);
      setRunningActivity(null);
      history.push(routeMatch.path);
    }
  }, [runningActivity, widgetProjectsService, askFillSemistopped, history, routeMatch]);

  const location = useLocation();
  const path = routeMatch.path.replace(/\/$/, "");
  const activityUuid = location.pathname.match(`${path}/([^/]*).*`)?.[1];
  const [doSkipDetails, setDoSkipDetails] = useState(false);
  const [editByUrl] = useAsyncCallback(async () => {
    if (companyRules == null || shifts == null || punches == null) return;
    if (activityUuid === "running") {
      // getActivity doesn't return running activity
      const runningAct = await widgetProjectsService.getRunningActivity();
      setEditedActivity(runningAct);
      return;
    }
    if (activityUuid === "start-activity") {
      setDoSkipDetails(true);
      history.push(routeMatch.path);
      await startActivity();
      setDoSkipDetails(false);
      return;
    }
    if (activityUuid != null) {
      const act = await widgetProjectsService.getActivity(activityUuid);
      setEditedActivity(act);
      return;
    }
    void checkOutscheduled();
    void checkSemistopped();
  }, [
    checkSemistopped,
    checkOutscheduled,
    activityUuid,
    widgetProjectsService,
    startActivity,
    companyRules,
    shifts,
    punches,
    history,
    routeMatch,
  ]);
  useEffect(() => void editByUrl(), [editByUrl]);

  const loading =
    startingActivity ||
    removingActivity ||
    stoppingActivity ||
    savingActivity ||
    repeatingActivity ||
    loadingShifts ||
    loadingPunches;

  return (
    <>
      <ActivitiesView
        runningActivity={runningActivity}
        today={today}
        duration={duration}
        days={days}
        projects={projects}
        loadDays={daysGen != null ? loadDays : undefined}
        hasMoreDays={hasMoreDays}
        loadingDays={loadingDays || loadingData}
        loading={loading}
        onStartActivity={startActivity}
        onAddActivity={openAddActivity}
        onStopActivity={(act) => stopActivity(act._raw)}
        onEditActivity={(act) => setEditedActivity(act._raw)}
        onRepeatActivity={(act) => repeatActivity(act._raw)}
        onExpandActivitiesGroup={(numItems: number) => {
          projectsEventTracking.trackExpandActivitiesGroup(numItems);
        }}
      />
      {editedActivity && (
        <Modal style={{ background: "none", height: "100%" }}>
          <ActivityEdit
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "var(--colors-surface-0)",
            }}
            isNew={editedActivity.uuid == null}
            isInvalid={isInvalid}
            isRepeat={shouldRepeat}
            loading={savingActivity || stoppingActivity || startingActivity}
            activity={editedActivity}
            projects={projects}
            locations={locations}
            lastLockDate={lastLockDate}
            onBackClick={() => {
              history.push(routeMatch.path);
              setEditedActivity(undefined);
            }}
            onStopClick={stopActivity}
            onDeleteClick={removeActivity}
            onSaveClick={saveActivity}
            projectsEventTracking={projectsEventTracking}
            doSkipDetails={doSkipDetails}
          />
        </Modal>
      )}
      {loadingDataBg && <SyncIndicator />}
      {showingViewStoppedDialog && <ActivityStoppedOnPunchOutDialog onResult={respondViewStopped} />}
      {showingFillSemistoppedDialog && <ActivityIncompleteStoppedOnPunchOutDialog onResult={respondFillSemistopped} />}
      {showingOutsideOfScheduleDialog && (
        <OutsideOfScheduleDialog onResult={() => setShowingOutsideOfScheduleDialog(false)} />
      )}
      {showingOutsideOfPunchInDialog && (
        <OutsideOfPunchInDialog onResult={() => setShowingOutsideOfPunchInDialog(false)} />
      )}
    </>
  );
}
