import { DBSchema, IDBPDatabase, openDB } from "idb";
import { GlobalStoreCompany } from "types/models/company";

const DB_NAME = "OitchauDb";
const DB_VERSION = 2;

enum DbStore {
  system = "system",
}

type SystemStore = {
  company: GlobalStoreCompany;
  companyCachedTime: number;
  employeesCachedTime: number;
};

interface OitchauDbSchema extends DBSchema {
  [DbStore.system]: {
    key: keyof SystemStore;
    /** TODO value type */
    value: unknown;
    // | number
    // | "";
  };
}

const stores: DbStore[] = [DbStore.system];

const initStores = async (db: IDBPDatabase<OitchauDbSchema>) => {
  const storesList = db.objectStoreNames;

  stores.forEach((store) => {
    if (!storesList.contains(store)) {
      db.createObjectStore(store);
    }
  });

  const storesListArray = Array.from(storesList);
  storesListArray.forEach((store) => {
    if (stores.indexOf(store) === -1) {
      db.deleteObjectStore(store);
    }
  });
};

const dbPromise = openDB<OitchauDbSchema>(DB_NAME, DB_VERSION, {
  async upgrade(db) {
    void initStores(db);
  },
});

export async function clearDb() {
  return Promise.all(stores.map(async (store) => (await dbPromise).clear(store)));
}

/**
 * Store for system data
 */
export const system = {
  getCompanyCachedTime: async () => (await dbPromise).get(DbStore.system, "companyCachedTime") as Promise<number>,

  setCompany: async (value: GlobalStoreCompany) => {
    const tx = (await dbPromise).transaction([DbStore.system], "readwrite");
    const systemStore = tx.objectStore(DbStore.system);

    void systemStore.put(value, "company");
    void systemStore.put(Date.now(), "companyCachedTime");

    await tx.done;
  },

  getCompany: async () => (await dbPromise).get(DbStore.system, "company") as Promise<GlobalStoreCompany>,

  clear: async () => (await dbPromise).clear(DbStore.system),
};
