import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import { useEffect } from "react";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import SearchControl, { SearchControlItemType, SearchControlOnChangeData } from "components/UI/SearchControlNew";
import useState from "hooks/useState";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import AssigneesList from "./AssigneesList";

const SearchWrapper = styled.div`
  .search-control-new__input-field {
    background-color: var(--colors-surface-0);
    padding-inline-start: 44px;
  }
  .search-control-new__input-field_newStyle {
    border: 1px solid var(--colors-surface-400);
  }
  .search-control-new__input-field_newStyle:focus {
    border: 1px solid var(--colors-primary-500-p);
  }
  .search-icon {
    inset-inline-start: 8px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Crect width='24' height='24' fill='%23ECF2FA' rx='12'/%3E%3Cpath fill='%23A0AEC6' d='M13.855 12.355a3 3 0 1 0-3.71 0 5 5 0 0 0-3.11 4.09.503.503 0 1 0 1 .11 4 4 0 0 1 7.95 0 .5.5 0 0 0 .5.445h.055a.5.5 0 0 0 .44-.55 5 5 0 0 0-3.125-4.095ZM12 12a2 2 0 1 1 0-4.001 2 2 0 0 1 0 4Z'/%3E%3C/svg%3E");
  }
  margin-bottom: 12px;
`;

const ListLabel = styled.div`
  width: 100%;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--colors-surface-800);
  position: relative;
  padding-inline-end: 36px;
  padding-top: 7px;
  padding-bottom: 15px;
  margin-top: 24px;

  .count {
    top: 0;
    inset-inline-end: 0;
    content: "";
    padding: 0 15px;
    height: 26px;
    background: var(--colors-surface-50);
    border-radius: 100px;
    text-align: center;
    line-height: 26px;
    position: absolute;
  }
`;

export type Assignee = {
  title: string;
  subtitle: string;
  avatarId?: string;
  isServiceGroup: boolean;
  uuid: string;
};

export type OnSaveProp = {
  addAssignees?: {
    userProfilesUuids: string[];
    servicesUuids: string[];
  };
  removeAssignees?: {
    userProfilesUuids: string[];
    servicesUuids: string[];
  };
};

type AssigneesDialogProps = {
  title: string;
  selectedAssignees?: Assignee[];
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  onSave: (assignees: OnSaveProp) => void;
};

type AssigneesDialogState = {
  assignees: Assignee[];
  assigneesToUnassign: Assignee[];
};

const formatAssignees = (assignees: Assignee[]) =>
  assignees.reduce(
    (
      result: {
        userProfilesUuids: string[];
        servicesUuids: string[];
      },
      assignee,
    ) => {
      if (assignee.isServiceGroup) {
        result.servicesUuids.push(assignee.uuid);
      } else {
        result.userProfilesUuids.push(assignee.uuid);
      }
      return result;
    },
    { userProfilesUuids: [], servicesUuids: [] },
  );

const AssigneesDialog = ({ selectedAssignees, isVisible, setVisible, onSave, title }: AssigneesDialogProps) => {
  const { t } = useTranslation(TranslationNamespaces.projects);
  const [state, setState] = useState<AssigneesDialogState>({
    assignees: selectedAssignees || [],
    assigneesToUnassign: [],
  });

  const { assignees, assigneesToUnassign } = state;

  useEffect(() => {
    setState({
      assignees: selectedAssignees || [],
    });
  }, [isVisible]);

  const onRemove = (uuid: string) => {
    const assignee = selectedAssignees?.find((item) => item.uuid === uuid);
    if (assignee) {
      assigneesToUnassign.push(assignee);
    }

    setState({ assignees: assignees.filter((item) => item.uuid !== uuid), assigneesToUnassign });
  };

  const updatedAssignees = (assignee: Assignee) => {
    let lAssigneesToUnassign = assigneesToUnassign;
    let lAssignees = assignees;

    if (selectedAssignees?.some((item) => item.uuid === assignee.uuid)) {
      lAssigneesToUnassign = lAssigneesToUnassign.filter((unassigned) => unassigned.uuid !== assignee.uuid);
    }

    if (!assignees.some((item) => item.uuid === assignee.uuid)) {
      lAssignees = [...assignees, assignee];
    }

    setState({
      assigneesToUnassign: lAssigneesToUnassign,
      assignees: lAssignees,
    });
  };

  const onEmployeeSelect = (val: SearchControlOnChangeData) => {
    if (val?.type === SearchControlItemType.services && val.uuid) {
      void updatedAssignees({
        title: val.label,
        subtitle: t("Services"),
        isServiceGroup: true,
        uuid: val.uuid,
      });
    } else {
      void updatedAssignees({
        title: val.employee?.full_name || "",
        avatarId: val.employee?.avatarId || "",
        subtitle: val.employee?.position?.title || "",
        isServiceGroup: false,
        uuid: val.uuid || "",
      });
    }
  };

  return (
    <ModalDialog isOpen={isVisible} onClose={() => setVisible(false)}>
      <Lightbox
        title={title}
        buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
        buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
        yesDisabled={typeof selectedAssignees === "undefined" && !assignees?.length}
        onClose={() => {
          setVisible(false);
        }}
        onYes={() => {
          onSave({
            addAssignees: formatAssignees(
              assignees.filter(
                (assignee) =>
                  // Check if the current assignee is not present in the selectedAssignees array
                  !selectedAssignees?.some((selected) => selected.uuid === assignee.uuid),
              ),
            ),
            removeAssignees: formatAssignees(assigneesToUnassign),
          });
        }}
      >
        <SearchWrapper>
          <SearchControl
            permissionSection={PermissionSectionName.projects}
            value=""
            searchServices
            onChange={onEmployeeSelect}
            placeholder={translateEmployeeTerm(
              t,
              TranslationNamespaces.projects,
              "custom-search-assignees",
              `search-assignees`,
            )}
          />
        </SearchWrapper>

        {!!assignees.length && (
          <ListLabel>
            {t("Assignees")}
            <span className="count">{assignees.length}</span>
          </ListLabel>
        )}

        <AssigneesList assignees={assignees} onRemove={onRemove} />
      </Lightbox>
    </ModalDialog>
  );
};

export default AssigneesDialog;
