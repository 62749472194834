import { Component, ContextType } from "react";
import GroupedSelect from "components/UI/Select/GroupedSelect";
import { getTasksListForUserProfile } from "components/Projects/projectsApiUtils";
import GlobalContext from "context/global-context";
import Spinner from "@atlaskit/spinner";
import {
  ActivityCustomField,
  GetTasksListForUserProfileRequestData,
  UserProfileTask,
} from "components/Projects/projectsApiTypes";
import { WithTranslation } from "types/translationNamespaces";

export type TaskDropdownOptionTask = {
  label: string;
  value: string | null;
  projectUuid: string;
  project: {
    customFieldsSettings: ActivityCustomField[] | null;
  };
};

type TaskDropdownOption = {
  label: string;
  options: TaskDropdownOptionTask[];
};

interface TasksDropdownControlProps {
  employeeUuid: string;
  locationUuid?: string;
  placeholder: string;
  value: string;
  t: WithTranslation["t"];
  onChange: (value: TaskDropdownOptionTask) => void;
}

interface TasksDropdownControlState {
  loading: boolean;
  groupedOptions: unknown[];
}

export default class TasksDropdownControl extends Component<TasksDropdownControlProps, TasksDropdownControlState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: TasksDropdownControlProps) {
    super(props);
    this.state = {
      loading: true,
      groupedOptions: [],
    };
  }

  async componentDidMount() {
    const { employeeUuid, locationUuid } = this.props;
    const tasks = await this.getEmployeeTasks(employeeUuid, locationUuid);

    this.setState({ loading: false, groupedOptions: this.mapTaskProjects(tasks) });
  }

  mapTaskProjects = (tasks: UserProfileTask[]) => {
    const grouped: TaskDropdownOption[] = [];
    const projects: Record<string, UserProfileTask[]> = {};
    tasks.forEach((task) => {
      if (projects[task.projectUuid]) {
        projects[task.projectUuid].push(task);
      } else {
        projects[task.projectUuid] = [task];
      }
    });

    Object.keys(projects).forEach((proj) => {
      const projectTasks = projects[proj];
      grouped.push({
        label: projectTasks[0].projectName,
        options: projectTasks.map((pt) => ({
          label: pt.name,
          value: pt.uuid,
          projectUuid: pt.projectUuid,
          project: pt.project,
        })),
      });
    });
    return grouped;
  };

  getEmployeeTasks = async (userProfileUuid: string, locationUuid: string) => {
    const company = await this.context.getCompany();
    const requestData: GetTasksListForUserProfileRequestData = {
      companyUuid: company.uuid,
      requestedBy: window.global_store.profile.uuid,
      locationUuid,
      userProfileUuid,
    };

    if (locationUuid) {
      requestData.locationUuid = locationUuid;
    }

    const tasks = await getTasksListForUserProfile(requestData);

    return tasks?.content ? tasks.content : [];
  };

  onChange = (val: TaskDropdownOptionTask) => {
    const { onChange } = this.props;

    onChange(val);
  };

  render() {
    const { loading, groupedOptions } = this.state;
    const { value, t, placeholder } = this.props;

    if (loading) {
      return <Spinner size="small" />;
    }
    if (!groupedOptions?.length) {
      return t("no-tasks-error");
    }

    return (
      <GroupedSelect
        returnOption
        className={loading ? "loading" : ""}
        modifiers={{ field: true }}
        value={value}
        placeholder={placeholder}
        onChange={this.onChange}
        groupedOptions={groupedOptions}
      />
    );
  }
}
