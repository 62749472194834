import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as images from "components/svg-images";

const Wrapper = styled.div`
  position: relative;
  margin-inline-end: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    .info-dropdown {
      display: block;
    }
  }
`;

const DropdownContainer = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  width: 307px;
  padding: 30px;
  background: var(--colors-default);
  border-radius: var(--shapes-border-radius-default);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
  cursor: default;
`;

const Title = styled.div`
  margin-bottom: 16px;
  font-weight: var(--typography-font-weight-medium);
  font-size: 18px;
  color: ${(props) => props.theme.colors.text1};
`;

const Mark = styled.div<{ pending?: boolean }>`
  margin: 19px 0;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => (props.pending ? props.theme.colors.pending : props.theme.colors.error)};
`;
const MarkDescription = styled.div`
  font-size: var(--typography-font-size-default);
  color: ${(props) => props.theme.colors.activitiInfoBlockValue};
`;

const HelpCenterBlock = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  padding-top: 25px;
  border-top: 1px solid ${(props) => props.theme.colors.inputBorderDefault};
`;

const HelpCenterIcon = styled.div``;

const HelpCenterText = styled.a`
  margin-inline-start: 12px;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  color: ${(props) => props.theme.colors.activitiInfoBlockValue};
`;

const Dropdown = () => {
  const { t } = useTranslation("punches-page");

  return (
    <DropdownContainer className="info-dropdown">
      <Title>{t("Punch Status")}</Title>
      <Mark pending />
      <MarkDescription>
        {t(
          "Pending points are points that need not validated some of your company's requirements. It can be unverified time or unvalidated location.",
        )}
      </MarkDescription>
      <Mark />
      <MarkDescription>
        {t("Invalid points are points that are outside the limits registered for the journey of your company.")}
      </MarkDescription>
      <HelpCenterBlock>
        <HelpCenterIcon>{images.helpCenter}</HelpCenterIcon>
        <HelpCenterText
          href="https://support.day.io/hc/pt-br/articles/14825416170263-Super-Punch-Gest%C3%A3o-e-fechamento-de-ponto-em-uma-tela-o-que-%C3%A9-e-como-usar-"
          alt={t("Help Center")}
          target="_blank"
        >
          {t("Help Center")}
        </HelpCenterText>
      </HelpCenterBlock>
    </DropdownContainer>
  );
};

const InfoControl = () => (
  <Wrapper>
    {images.infoCircularDark}
    <Dropdown />
  </Wrapper>
);

export default InfoControl;
