import React from "react";
import Select from "components/UI/Select";
import FieldWrapper from "components/UI/FieldWrapper";

const TIMES = [
  { label: "0m", value: "0" },
  { label: "5m", value: "5" },
  { label: "10m", value: "10" },
  { label: "15m", value: "15" },
  { label: "20m", value: "20" },
  { label: "30m", value: "30" },
  { label: "45m", value: "45" },
  { label: "50m", value: "50" },
  { label: "60m", value: "60" },
  { label: "75m", value: "75" },
  { label: "80m", value: "80" },
  { label: "90m", value: "90" },
  { label: "100m", value: "100" },
  { label: "110m", value: "110" },
  { label: "120m", value: "120" },
  { label: "180m", value: "180" },
  { label: "240m", value: "240" },
  { label: "300m", value: "300" },
];

export default ({ label, value, onChange, addNone, t, disabled }) => (
  <FieldWrapper width="89px" fieldTitleMarginBottom={8} label={label}>
    <Select
      disabled={disabled}
      modifiers={{ field: true }}
      value={`${value}`}
      onChange={onChange}
      options={addNone ? [{ label: t("None"), value: "none" }, ...TIMES] : TIMES}
    />
  </FieldWrapper>
);
