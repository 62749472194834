import styled from "styled-components";
import IconAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";
import IconAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";

const CalendarInsideNavWrapper = styled.div`
  border-radius: 100%;
  border: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-0);
  color: var(--colors-activitiInfoBlockValue);
  outline: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-color: var(--colors-activitiCellScheduleItemHover);
    background: var(--colors-activitiCellScheduleItemHover);
    color: var(--colors-surface-800);
    path {
      fill: var(--colors-surface-800);
    }
  }
}`;

export const DatePickerCommonOverrides = styled.div`
  --font-family: var(--cp-dp-font-family, var(--typography-font-family-default));
  --font-weight: var(--cp-dp-font-weight, var(--typography-font-weight-default));
  --month-caption-font-size: var(--cp-dp-month-caption-font-size, 15px);
  --month-caption-text-color: var(--cp-dp-month-caption-text-color, var(--colors-surface-800));

  --cell-font-size: var(--cp-dp-cell-font-size, 14px);
  --cell-text-color: var(--cp-dp-cell-text-color, var(--colors-surface-800));
  --cell-selected-bg-color: var(--cp-dp-cell-selected-bg-color, var(--colors-primary));
  --cell-active-bg-color: var(--cp-dp-cell-active-bg-color, var(--colors-primary-500-p));
  --cell-inactive-text-color: var(--cp-dp-cell-inactive-text-color, var(--colors-surface-200));
  --cell-selected-text-color: var(--cp-dp-cell-selected-text-color, var(--colors-primary));

  --input-text-color: var(--cp-dp-input-text-color, var(--colors-surface-800));
  --input-font-size: var(--cp-dp-input-font-size, 15px);
  --weekheader-text-color: var(--cp-dp-weekheader-text-color, var(--colors-surface-600));
  --weekheader-text-transform: var(--cp-dp-weekheader-text-transform, uppercase);

  * {
    font-family: var(--font-family);
  }

  .DayPicker_weekHeader {
    top: 52px;
  }
  .DayPicker_weekHeader_li {
    font-size: 12px;
    text-transform: var(--weekheader-text-transform);
    font-weight: var(--typography-font-weight-medium);
    color: var(--weekheader-text-color);
  }
  .CalendarDay {
    font-weight: var(--font-weight);
    font-size: var(--cell-font-size);
    &__default {
      color: var(--cell-text-color);
      border: none;
      &:hover {
        border: none;
      }
    }
    &__blocked_out_of_range {
      color: var(--cell-inactive-text-color);
    }

    // Will edit when the second date (end date) in a range of dates
    // is not yet selected. Edits the dates between your mouse and said date
    &__hovered_span {
      background: var(--colors-surface-50);
      color: var(--colors-primary);
      &:hover {
        color: var(--colors-default);
        background: var(--colors-primary);
      }
    }

    /* cell inside of selected range */
    &__selected_span {
      background: var(--colors-surface-50);
      color: var(--cell-selected-text-color);
      &_:active,
      &:hover {
        background: var(--cell-active-bg-color);
        border: none;
        color: var(--colors-default);
      }
    }

    /* start and end cells of selected range */
    &__selected,
    &__selected:active,
    &__selected:hover {
      background: var(--cell-active-bg-color);
      border: none;
      color: var(--colors-default);
      outline: none;
    }
  }

  .DateInput {
    width: 87px;
    &_input {
      font-weight: 400;
      text-transform: capitalize;
      font-size: var(--input-font-size);
      color: var(--input-text-color);
      padding: 0;
      cursor: pointer;
      &__focused {
        border-bottom-color: transparent;
      }
    }
    &_fang {
      z-index: 15;
      top: 30px !important;
    }
  }

  .DayPickerNavigation__horizontal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 17px;
    padding: 0 23px;
  }

  .CalendarMonth_caption {
    padding-top: 20px;
    strong {
      font-weight: var(--font-weight);
      font-size: var(--month-caption-font-size);
      color: var(--month-caption-text-color);
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }
`;

export const CelandarInsidePrev = () => (
  <CalendarInsideNavWrapper>
    <IconAngleLeft size={20} />
  </CalendarInsideNavWrapper>
);

export const CalendarInsideNext = () => (
  <CalendarInsideNavWrapper>
    <IconAngleRight size={20} />
  </CalendarInsideNavWrapper>
);
