import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { assignUserToBRGroup, getBRGroup, unassignUserFromBRGroup } from "utils/apiHelpers";
import BEM from "utils/BEM";
import { PermissionSectionName } from "types/models/permissions";
import { BusinessRuleGroup, BusinessRuleGroupStatus } from "types/models/businessRulesGroup";
import { FilteredEmployeeProfile, GlobalContextEmployee, UserProfileStatus } from "types/models/userProfile";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { iColumn } from "components/TableCommon";
import { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import AddEmployeeRow from "components/controls//AddEmployeeRow";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import { TableButton, TableButtons } from "components/styled/Page";
import NotificationRow from "components/NotificationRow";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import TableUserProfile from "components/styled/TableUserProfile";
import SearchInput from "components/UI/SearchInput";
import Select from "components/UI/Select";
import { strIncludesCheck } from "utils/common";
import StatusBadge from "components/controls/StatusBadge";
import GlobalContext from "context/global-context";
import "styles/company-rules.scss";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { MassActionLocations } from "utils/ga";
import * as images from "../svg-images";

const b = BEM.b("schedule-employees");

const TableWrapper = styled.div`
  margin-top: 32px;
`;

const FiltersWrapper = styled.div<{ withSearch: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.withSearch ? "space-between" : "flex-end")};
`;

const SearchFiltersWrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  width: 150px;
  margin-inline-start: 20px;
`;

type PunchingRulesGroupEmployee = FilteredEmployeeProfile & { assigmentDate: string | undefined };

interface PunchingRulesGroupEmployeesProps extends WithTranslation {
  /** uuid */
  id: string;
  group: BusinessRuleGroup | null;
}

interface PunchingRulesGroupEmployeesState {
  isLoading: boolean;
  isFetching: boolean;
  confirmationPopupVisible: boolean;
  warningPopupVisible: boolean;
  group: BusinessRuleGroup | null;
  employees: PunchingRulesGroupEmployee[];
  filterStr: string;
  addEmployeeRowVisible: boolean;
  notification: string | null;
  notificationType: NotificationType | null;
  /** uuid */
  selectedItem: string | null;
  status: UserProfileStatus | null;
}

class PunchingRulesGroupEmployees extends Component<
  PunchingRulesGroupEmployeesProps,
  PunchingRulesGroupEmployeesState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: PunchingRulesGroupEmployeesProps) {
    super(props);

    this.state = {
      isLoading: false,
      isFetching: false,
      confirmationPopupVisible: false,
      warningPopupVisible: false,
      group: props.group,
      employees: [],
      filterStr: "",
      addEmployeeRowVisible: false,
      notification: null,
      notificationType: null,
      selectedItem: null,
      status: UserProfileStatus.active,
    };
  }

  componentDidMount() {
    this.getState();
  }

  getState = () => {
    const { id } = this.props;

    this.setState({ isLoading: true, isFetching: true }, async () => {
      const company = await this.context.getCompany();
      if (!company) {
        return;
      }

      const { content: brGroup } = await getBRGroup({ companyUuid: company.uuid, brGroupUuid: id });
      const { content: userProfiles } = await listUserProfilesWIthFilters(window.global_store.company.uuid, {
        ...baseByUuidPayload(
          window.global_store.profile.uuid,
          brGroup.userProfileBusinessRulesGroups.map((up) => up.userProfileUuid),
        ),
        fields: ["id", "uuid", "fullName", "department.uuid", "department.id", "department.name", "employeeStatus"],
      });
      const employeesWithAssignments = userProfiles.map((p) => ({
        ...p,
        assigmentDate: brGroup.userProfileBusinessRulesGroups.find((em) => em.userProfileUuid === p.uuid)?.startDate,
      }));

      this.setState({
        group: brGroup,
        employees: employeesWithAssignments,
        isFetching: false,
      });
    });
  };

  onAddEmployee = (input: SearchControlOnChangeData | GlobalContextEmployee | string[], startDate: moment.Moment) => {
    const { id, t } = this.props;
    let userProfileUuids: string[] = [];

    if (!Array.isArray(input)) {
      userProfileUuids = [input.uuid as string]; // todo could be undefined ?
    } else {
      userProfileUuids = input;
    }

    this.setState({ isFetching: true }, async () => {
      if (!window.global_store.company) {
        return;
      }

      try {
        await assignUserToBRGroup({
          companyUuid: window.global_store.company.uuid,
          brGroupUuid: id,
          startDate,
          body: {
            content: {
              userProfileUuids,
              createdBy: window.global_store.profile.uuid,
            },
          },
        });

        this.setState({
          notification: t("Employee added successfully"),
          notificationType: NotificationType.success,
        });
        this.getState();
      } catch (err) {
        const error = err as any;
        const message = error?.message || error?.originalRequest?.errors[0]?.message;
        const notification = t(message || "Adding employee failed");

        this.setState({
          notification,
          notificationType: NotificationType.error,
        });
        this.getState();
      }
    });
  };

  removeItem = () => {
    this.setState({ isFetching: true }, async () => {
      const { selectedItem } = this.state;
      const { id } = this.props;
      if (!window.global_store.company || !selectedItem) {
        return;
      }

      await unassignUserFromBRGroup({
        companyUuid: window.global_store.company.uuid,
        brGroupUuid: id,
        body: {
          content: {
            userProfileUuids: [selectedItem],
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });

      const gr = await getBRGroup({ companyUuid: window.global_store.company.uuid, brGroupUuid: id });

      this.setState(
        {
          group: gr.content,
          selectedItem: null,
          confirmationPopupVisible: false,
        },
        this.getState,
      );
    });
  };

  getTableColumns = (): iColumn<PunchingRulesGroupEmployee>[] => {
    const { t } = this.props;
    const { group } = this.state;

    return [
      {
        Header: t("Employees"),
        accessor: "uuid",
        sortable: false,
        resizable: false,
        Cell: (row) => <TableUserProfile {...row.original} />,
        minWidth: 178,
      },
      {
        label: t("Department"),
        Cell: (row) => (row.original.department ? row.original.department.name : ""),
        accessor: "department",
        width: 160,
      },
      {
        label: t("Start Date"),
        accessor: "assigmentDate",
        Cell: (row) => <div>{row.value ? moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}</div>,
        width: 160,
      },
      {
        Header: t("common|Status"),
        accessor: "employeeStatus",
        align: "center",
        Cell: (row) => (
          <div>
            <StatusBadge type={row.value} value={t(`common|${row.value}`)} />
            {!group?.isDefault && !!row.original.uuid && (
              <TableButtons className="buttons">
                <TableButton
                  onClick={() => {
                    this.setState({ selectedItem: row.original.uuid, confirmationPopupVisible: true });
                  }}
                >
                  {t("Remove")}
                </TableButton>
              </TableButtons>
            )}
          </div>
        ),
        minWidth: 150,
      },
    ];
  };

  filterEmployees = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterStr: ev.target.value });
  };

  toggleAddEmployeeRow(visible: boolean) {
    this.setState({ addEmployeeRowVisible: visible });
  }

  render() {
    const {
      isFetching,
      isLoading,
      employees,
      confirmationPopupVisible,
      warningPopupVisible,
      group,
      filterStr,
      addEmployeeRowVisible,
      notification,
      notificationType,
      status,
    } = this.state;
    const { t } = this.props;

    const filteredUsers = employees.filter((u) => {
      let result = strIncludesCheck(u.fullName, filterStr);

      if (result && status !== null) {
        result = u.employeeStatus === status;
      }

      return result;
    });

    return (
      <div>
        {!isLoading && group?.status === BusinessRuleGroupStatus.active && !employees.length && !group.isLocked && (
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type={NotificationType.warning}
            style={{ marginTop: 0, marginBottom: "25px" }}
            message={
              <div style={{ marginTop: "10px", fontSize: "16px", color: "var(--colors-mainText)" }}>
                {t("Adding employees to the Rules Group will lock possibility to edit it")}
              </div>
            }
          />
        )}

        {group?.status === BusinessRuleGroupStatus.active && addEmployeeRowVisible && (
          <AddEmployeeRow
            permissionSection={PermissionSectionName.rulesAndLimits}
            newSearch
            trackingLocation={MassActionLocations.PayPolicies}
            hideRow={() => this.toggleAddEmployeeRow(false)}
            startDate={group.businessRules[0].startDate ? moment(group.businessRules[0].startDate) : moment()}
            onAddMultipleEmployee={this.onAddEmployee}
            onAddEmployee={this.onAddEmployee}
          />
        )}

        {notification && (
          <>
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType}
              onClose={() => this.setState({ notification: null, notificationType: null })}
              message={notification}
            />
            <br />
          </>
        )}

        <FiltersWrapper withSearch={!!employees.length}>
          {!!employees.length && (
            <SearchFiltersWrapper>
              <SearchInput
                modifiers={["filter"]}
                onChange={this.filterEmployees}
                placeholder={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-search-employees",
                  `${TranslationNamespaces.common}|Search Employees`,
                )}
                value={filterStr}
              />

              <SelectWrapper>
                <Select<UserProfileStatus | "">
                  value={status || ""}
                  onChange={(s) => this.setState({ status: s !== "" ? s : null })}
                  options={[
                    { value: "", label: t("common|Status") },
                    { value: UserProfileStatus.active, label: t("common|Active") },
                    { value: UserProfileStatus.invited, label: t("common|Invited") },
                  ]}
                />
              </SelectWrapper>
            </SearchFiltersWrapper>
          )}

          {group && group.status !== BusinessRuleGroupStatus.deactivated && !addEmployeeRowVisible && (
            <div className={b("add-employee-btn")} onClick={() => this.toggleAddEmployeeRow(true)}>
              {images.plusBig}
              {t("Add Employess")}
            </div>
          )}
        </FiltersWrapper>
        <br />
        <TableWrapper>
          <TablePage
            rows={filteredUsers}
            columnSelectorOnFiltersRow
            columns={this.getTableColumns()}
            className="employees-table"
            loading={isFetching}
            noContentComponent={<NoContent>{t("Please add employees")}</NoContent>}
          />
        </TableWrapper>
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Remove Employee.")}
            text={t("Are you sure to remove employee from this group?")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => this.setState({ selectedItem: null, confirmationPopupVisible: false })}
            onYes={this.removeItem}
          />
        </ModalDialog>
        <ModalDialog isOpen={warningPopupVisible} onClose={() => this.setState({ warningPopupVisible: false })}>
          <Lightbox
            title={t("Employee Exists")}
            text={t("Employee is already assigned to this group.")}
            buttonYesTitle={t("Ok")}
            noCancelButton
            onClose={() => this.setState({ selectedItem: null, warningPopupVisible: false })}
            onYes={() => this.setState({ warningPopupVisible: false })}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.companyRules)(PunchingRulesGroupEmployees);
