import React, { Component } from "react";
import BEM from "../utils/BEM";
import * as images from "./svg-images";

const input = BEM.b("input");
class PasswordInput extends Component {
  constructor(props) {
    super(props);
    const { isValid, value } = this.props;

    this.state = {
      isValid,
      value: value || "",
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.value !== state.value || nextProps.isValid !== state.isValid) {
      return {
        value: nextProps.value,
        isValid: nextProps.isValid,
      };
    }
    return null;
  }

  onPasswordChange = (ev) => {
    const { value } = ev.target;

    this.setState({ value });
    this.props.onPasswordChange(value);
  };

  onShowPasswordClick = () => {
    this.setState({ type: !this.state.type });
  };

  render() {
    const { type, isValid, value } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <input
          className={`${input({
            error: !isValid,
            "with-icon-right": true,
            "has-value": !!value,
            onboarding: this.props.onboarding,
          })} ${this.props.className || ""}`}
          type={type ? "text" : "password"}
          placeholder=""
          name="password"
          value={value}
          onChange={this.onPasswordChange}
        />
        <div
          className={input("icon", {
            onboarding: this.props.onboarding,
          })}
          onClick={this.onShowPasswordClick}
        >
          {type ? images.passwordEyeStrikethrough : images.passwordEye}
        </div>
      </div>
    );
  }
}

export default PasswordInput;
