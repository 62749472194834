import { GlobalContextValue } from "context/GlobalContextProvider/types";
import { AllFlagsLDClient } from "launchdarkly-react-client-sdk/lib/types";
import moment from "moment";
import { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { BusinessRuleGroupByUser } from "types/models/businessRulesGroup";
import { PunchKey, PunchType, DailySummaryPunch, Punch } from "types/models/punches";
import { DailySummaryRequest } from "types/models/request";
import { DailySummaryDate, DailySummaryEmployeeInfo } from "types/models/superpunch";

enum SuperpunchCustomEvents {
  /** Placeholder for values in dynamic event names */
  placeholder = "*",
  /** Handler in SuperpunchContext */
  updateData = "sp_UpdateData",
  /** Handler in SuperpunchContext */
  addPunch = "sp_addPunch",
  /** Handler in SuperpunchContext */
  enableDay = "sp_enableDay",
  /** Handler in SuperpunchContext */
  removeException = "sp_removeException",
  /** Handler in CellTotalWithProgress */
  workedHours = "sp_*_worked-hours",
  /** Handler in CellTotalWithProgress */
  frozen = "sp_*_frozen",
  /** Handler in CellSchedule */
  schedule = "sp_*_schedule",
  /** Handler in SuperpunchErrorNotification */
  sendError = "sp_sendErrorNotification",
  /** Handler in SuperpunchOverlay */
  openNewPunchOverlay = "sp_openNewPunch",
  /** Handler in SuperpunchOverlay */
  closeOverlay = "sp_closeOverlay",
  /** Handler in SuperpunchOverlay */
  openPunchDetails = "sp_openPunchDetails",
  /** Handler in SuperpunchOverlay */
  openRequests = "sp_openRequests",
  /** Handler in Superpunch */
  tableRowsLoaded = "sp_tableRowsLoaded",
  /** Handler in Superpunch in CellCombinedBreaks */
  cellCombinedBreaks = "sp_*_cellCombinedBreaks",
}

type PrepareTableRowsData = {
  employeeId: number;
  employeeUuid: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  showOnlyIssues?: boolean;
};

type ChangePunchData = {
  selectedType: PunchType;
  destinationDate: moment.Moment;
  punch: DailySummaryPunch | Record<string, never>;
  eventKey: PunchKey;
};

type GetTableRowsData = {
  inputData: DailySummaryDate[];
  t: WithTranslation["t"];
  daysInProgress: string[];
  lockDate: string | null;
};

type ShiftCompilationOperation = {
  date: string;
  user_profile_uuid: string;
  shift_events: {
    uuid: string | null; // punch uuid
    key: PunchKey;
  }[];
};

type OnPunchActionData = {
  destinationDate: moment.Moment;
  punchKey?: PunchKey;
  punchId: number;
  punchUuid: string;
  employeeUuid: string;
};

type OnPunchDeclineData = OnPunchActionData & { declineReason: unknown };

type EnableDayEventData = {
  employeeUUID: string;
  companyUUID: string;
  requestedBy: string;
  body: {
    content: {
      date: string;
      approvedBy: string;
    };
  };
};

type RemoveExceptionEventData = {
  userProfileUuid: string;
  companyUuid: string;
  requestedByUuid: string;
  date: string;
  scheduleExceptionUuid: string;
  body: {
    content: {
      date: string;
    };
  };
};

type SuperpunchWorkedHoursEventData = {
  isUpdating: boolean;
  total?: string;
};

type SuperpunchOpenRequestsEventData = {
  selectedDate: moment.Moment;
  selectedDayRequests: DailySummaryRequest[];
  employeeInfo: DailySummaryEmployeeInfo;
};

interface SuperpunchProviderProps extends PropsWithChildren, WithTranslation, RouteComponentProps, AllFlagsLDClient {
  uuid: string;
  id: string;
  startDate: string | null;
  endDate: string | null;
  label: string | null;
  showOnlyIssues: string | null; // 'true' is true, everything else if false
  directReportsOnly: string | null; // 'true' is true, everything else if false
}

interface SuperpunchProviderState {
  employeeUuid: string;
  employeeId: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  employeeInfo: DailySummaryEmployeeInfo | null;
  directReportsOnly: boolean;
  showOnlyIssues: boolean;
  searchValue: string;
  employeeBusinessRuleGroups: BusinessRuleGroupByUser[];
  customBreaksNamesMap: Record<string, string>;
}

interface SuperpunchContextInterface {
  /**
   * Clear all async stuff. Clear days related cache. Update context state
   *
   * @param state
   */
  setContextState: (state: Partial<Omit<SuperpunchProviderState, "employeeInfo">>) => void;
  /**
   * Fetch report from cache. Populate cell with data or clear it. Update shift compilations
   *
   * @param param0
   * @returns
   */
  changePunch: (data: ChangePunchData) => void;
  /**
   * Superpunch init function. Get fresh report
   *
   * @returns
   */
  getTableRows: () => Promise<void>;
  /**
   *  Get punches without declined from cache or backend.
   *
   * @param requestData
   * @param forceNetworkUse decide where to get report from
   * @returns
   */
  fetchPunches: (requestData: Record<"from" | "to", string>, forceNetworkUse?: boolean) => Promise<Punch[]>;
  /**
   * Validate punch on backend side. Change day state to processing. Clear punch cache
   *
   * @param param0
   */
  onPunchValidate: (props: OnPunchActionData) => Promise<void>;
  /**
   * Validate punch on backend side. Change day state to processing. Clear punch cache
   *
   * TODO almost the same as onPunchValidate
   *
   * @param param0
   */
  onPunchApprove: (props: OnPunchActionData) => Promise<void>;
  /**
   * Decline punch on backend side. Change day state to processing. Clear punch cache
   *
   * @param param0
   */
  onPunchDecline: (props: OnPunchDeclineData) => Promise<void>;
  /**
   * Fetch report from cache. Clear punch cells for specific rows. Update shift compilations.
   *
   * @param destinationDates row dates
   */
  clearPunches: (destinationDates: string[]) => Promise<void>;
  /**
   * Fetch report from cache. Fetch punches. Sort punches per day, update cells. Update shift compilations.
   *
   * @param destinationDates
   * @returns
   */
  organizePunchesChronologically: (destinationDates: string[]) => Promise<void>;
  /**
   * Send force recalculate request to backend side
   *
   * @param param0
   */
  recalculateRange: ({ from, to }: Record<"from" | "to", string>) => Promise<void>;
  /**
   * Perform remove schedulesAPI call for specific dates. Mark days as processing
   *
   * @param param0
   */
  removeScheduleOnRange: ({ dates }: { dates: string[] }) => Promise<void>;
  /**
   * Perform validate all punches API call for specific dates. Mark days as processing. Update data
   *
   * @param param0
   */
  validateAllPunchesInDays: ({ dates }: { dates: string[] }) => Promise<void>;
  /**
   * Perform approve all punches API call for specific dates. Mark days as processing. Update data
   *
   * @param param0
   */
  approveAllPunchesInDays: ({ dates }: { dates: string[] }) => Promise<void>;
  /**
   * Get business rules for employee
   */
  getBusinessRulesGroups: () => Promise<void>;
  /**
   * Get custom breaks names for company
   */
  getCustomBreaksNamesMap: () => Promise<void>;
}

type SuperpunchContextValue = {
  /**
   * Get employee entity. Inherited from global context
   */
  getEmployees: GlobalContextValue["getEmployees"];
} & SuperpunchContextInterface &
  SuperpunchProviderState;

export type {
  PrepareTableRowsData,
  ChangePunchData,
  SuperpunchContextInterface,
  SuperpunchProviderProps,
  SuperpunchProviderState,
  GetTableRowsData,
  SuperpunchContextValue,
  ShiftCompilationOperation,
  OnPunchActionData,
  OnPunchDeclineData,
  EnableDayEventData,
  RemoveExceptionEventData,
  SuperpunchWorkedHoursEventData,
  SuperpunchOpenRequestsEventData,
};

export { SuperpunchCustomEvents };
