/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import sentryUtils from "utils/sentryUtils";

/**
 * Guarantee that the async error is not lost and can be handled by the component.
 */
export async function reactAsync(
  fn: () => Promise<any>,
  setErr: (err: any) => void,
  setLdng?: (ldng: boolean) => void,
) {
  try {
    setLdng && setLdng(true);
    const res = await fn();
    return res;
  } catch (err) {
    setErr((e) => e || err);
    throw err;
  } finally {
    setLdng && setLdng(false);
  }
}

/**
 * Call setError on error.
 * Call setLoading(true) before calling callback and setLoading(false) after (or on error).
 * @deprecated Prefer using useAsyncCallback with useEffect
 */
export function useAsyncEffect(
  callback: () => Promise<void>,
  dependencies: Parameters<typeof useEffect>[1],
  setError: (error: any) => void,
  setLoading?: (loading: boolean) => void,
) {
  useEffect(() => {
    void reactAsync(callback, setError, setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

/**
 * Make async errors handlable by react error boundary.
 * Return isLoading boolean flag while loading.
 */
export function useAsyncCallback<F extends (...args: any[]) => Promise<any>>(
  callback: F,
  dependencies: React.DependencyList,
  /** Just `console.error` errors instead of throwing and expecting an error boundary to handle them */
  swallowErrors = false,
) {
  const [err, setErr] = useState();
  const [ldng, setLdng] = useState(false);
  const [reslt, setReslt] = useState();
  const load = useCallback(async (...args: Parameters<F>) => {
    const res = await reactAsync(() => callback(...args), setErr, setLdng);
    setReslt(res);
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  if (err) {
    console.error("ERROR!", err);
    if (swallowErrors) console.error(err);
    else throw err;
  }
  return [load, ldng, reslt] as [cb: F, loading: boolean, res: Awaited<ReturnType<F> | undefined>];
}
