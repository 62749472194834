import { Component } from "react";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import BEM from "utils/BEM";
import { EmployeeWithLastLockDate, PermissionSectionName, getLastLockDate } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { FilteredEmployeeProfile, GlobalContextEmployee } from "types/models/userProfile";
import SearchControl, { AllSearchObjects, SearchControlOnChangeData } from "components/UI/SearchControlNew";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import * as images from "components/svg-images";
import Button from "components/controls/StyledButton";
import "styles/add-employee-row.scss";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { MassActionLocations } from "utils/ga";

const b = BEM.b("add-employee-row");

const AddButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100px;
`;

const HideRowButton = styled.div`
  position: absolute;
  top: 10px;
  cursor: pointer;
  inset-inline-end: 15px;
`;

interface AddEmployeeRowProps extends WithTranslation {
  startDate?: moment.Moment;
  withDate: boolean;
  onAddMultipleEmployee?: (uuids: string[], startDate: moment.Moment) => void;
  onAddEmployee?: (searchObj: SearchControlOnChangeData | GlobalContextEmployee, startDate: moment.Moment) => void;
  inProgress?: boolean;
  hideRow?: () => void;
  newSearch?: boolean;
  trackingLocation?: MassActionLocations;
  permissionSection: PermissionSectionName;
}

interface AddEmployeeRowState {
  searchValue: string;
  searchObj: AllSearchObjects | null;
  startDate: moment.Moment;
  employeeUuids: string[] | null;
  lockedDate: moment.Moment | null;
}

class AddEmployeeRow extends Component<AddEmployeeRowProps, AddEmployeeRowState> {
  static defaultProps = {
    withDate: true,
  };

  constructor(props: AddEmployeeRowProps) {
    super(props);

    this.state = {
      searchValue: "",
      searchObj: null,
      startDate: props.startDate ? props.startDate : moment(),
      employeeUuids: null,
      lockedDate: null,
    };
  }

  onAddEmployee = () => {
    const { t, onAddMultipleEmployee, onAddEmployee, inProgress } = this.props;
    const { employeeUuids, startDate, searchObj } = this.state;

    if (inProgress) {
      return;
    }

    if (employeeUuids && onAddMultipleEmployee) {
      onAddMultipleEmployee(employeeUuids, startDate);
    } else if (!Array.isArray(searchObj) && searchObj?.id && onAddEmployee) {
      onAddEmployee(searchObj, startDate);
    } else {
      alert(t("Please select employee"));
    }
  };

  onStartDateChange = (value?: moment.MomentInput) => {
    let date = value && moment(value).isValid() ? moment(value).clone() : moment().clone();

    if (date.isAfter(moment().clone().add(1, "year"))) {
      date = moment().clone().add(1, "year");
    } else if (date.isBefore(moment().clone().subtract(1, "year"))) {
      date = moment().clone().subtract(1, "year");
    }

    this.setState({ startDate: date });
  };

  onEmployeeChange = (searchObj: AllSearchObjects) => {
    let lockedDate = null;
    let employee = null;
    let employeeUuids = null;

    if (!Array.isArray(searchObj)) {
      employee = searchObj as SearchControlOnChangeData | GlobalContextEmployee;
    } else if (searchObj.length > 1) {
      employeeUuids = (searchObj as GlobalContextEmployee[]).map((e) => e.uuid);
    }

    if (!employeeUuids && (employee as SearchControlOnChangeData)?.employee) {
      lockedDate = getLastLockDate((employee as SearchControlOnChangeData).employee)
        ? moment(getLastLockDate((employee as SearchControlOnChangeData).employee)).utc()
        : null;
    } else if (!employeeUuids && (employee as unknown as FilteredEmployeeProfile).lastLockDate) {
      lockedDate = getLastLockDate(employee as EmployeeWithLastLockDate)
        ? moment(getLastLockDate(employee as EmployeeWithLastLockDate)).utc()
        : null;
    }

    let sDate = this.state.startDate;
    if (lockedDate && sDate.isSameOrBefore(lockedDate, "day")) {
      sDate = lockedDate.clone().add(1, "day");
    }

    this.setState({
      searchObj: !Array.isArray(searchObj) ? searchObj : null,
      searchValue:
        (searchObj as SearchControlOnChangeData).label ||
        (searchObj as GlobalContextEmployee).name ||
        (searchObj as unknown as FilteredEmployeeProfile).fullName,
      lockedDate,
      startDate: sDate,
      employeeUuids,
    });
  };

  hideRow = () => {
    this.props.hideRow?.();
  };

  render() {
    const { searchValue, startDate, lockedDate } = this.state;
    const { t, withDate, permissionSection, hideRow, newSearch, inProgress } = this.props;

    return (
      <div className={b()}>
        {hideRow ? <HideRowButton onClick={this.hideRow}>{images.removeIcon()}</HideRowButton> : null}

        <div className={b("add-employee-field")}>
          <div className={b("add-employee-label")}>{t("Employess")}</div>
          <div>
            <SearchControl
              trackingLocation={this.props.trackingLocation}
              permissionSection={permissionSection}
              value={searchValue}
              withMultiple={newSearch}
              scheduleAdd
              onChange={this.onEmployeeChange}
              placeholder={translateEmployeeTerm(
                t,
                TranslationNamespaces.common,
                "custom-search-employees",
                `${TranslationNamespaces.common}|Search Employees`,
              )}
            />
          </div>
        </div>

        {withDate && (
          <div className={b("add-employee-field", { date: true })}>
            <div className={b("add-employee-label")}>{t("Start Date")}</div>
            <SingleDatePickerControl
              value={startDate}
              onChange={this.onStartDateChange}
              isOutsideRange={(day) => {
                if (this.props.startDate && day.isBefore(this.props.startDate, "day")) {
                  return true;
                }
                if (lockedDate) {
                  return day.isSameOrBefore(lockedDate, "day");
                }

                return day.isBefore(startDate ? startDate.clone().subtract(3, "month") : moment().subtract(3, "month"));
              }}
            />
          </div>
        )}

        <AddButtonWrapper>
          <Button loading={inProgress} onClick={this.onAddEmployee} value={t("Add")} />
        </AddButtonWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.schedules)(AddEmployeeRow);
