import React, { Component } from "react";

import BEM from "utils/BEM";

class PortField extends Component {
  constructor(props) {
    super(props);
    const value = this.props.value || "";

    this.state = {
      value,
      isValid: this.props.isValid,
    };
  }
  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(this);
    }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value || nextProps.isValid !== this.state.isValid) {
      this.setState({
        value: nextProps.value,
        isValid: nextProps.isValid,
      });
    }
  }
  getValue = () => this.state.value;
  valueChange = (ev) => {
    let value = ev.target.value.replace(/\D/g, "");
    value = value.substring(0, 5);
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
    this.setState({
      value,
    });
  };
  render() {
    const { value, isValid } = this.state;
    const tic = BEM.b("text-input-control");
    return (
      <input
        className={tic({
          error: !isValid,
        })}
        type="text"
        name="host"
        value={value || ""}
        onChange={this.valueChange}
      />
    );
  }
}

export default PortField;
