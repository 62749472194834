import { ChangeEvent, Component, KeyboardEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import BEM from "utils/BEM";
import { FilteredEmployeeProfile, GroupSearchUserProfile, SearchEmployeeUserProfile } from "types/models/userProfile";
import MultipleEmployeeSelector from "components/MultipleEmployeeSelector";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { MassActionLocations } from "utils/ga";
import { services } from "components/PunchNow/services";
import { PermissionSectionName } from "types/models/permissions";
import Item, { SearchControlItem, SearchControlItemType } from "./Item";
import InputFieldIcon from "./InputFieldIcon";
import "./search-control.scss";

const b = BEM.b("search-control-new");

const ClearIcon = styled.div<{ $loading: boolean }>`
  position: absolute;
  top: 0;
  inset-inline-end: 23px;
  width: 16px;
  height: 36px;
  background-image: ${(p) => (p.$loading ? p.theme.svg.loading_small : p.theme.svg.clear_small)};
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  ${(p) => (p.$loading ? "animation: loaderRoation 1s infinite linear;" : "")}
`;

const LockIcon = styled.div`
  position: absolute;
  top: 0;
  inset-inline-start: 19px;
  width: 12px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 15'%3E%3Cpath d='M11 5.886V4.687C11 2.103 8.757 0 6 0S1 2.103 1 4.688v1.198C.405 6.21 0 6.809 0 7.5v5.625C0 14.159.897 15 2 15h8c1.103 0 2-.84 2-1.875V7.5c0-.691-.405-1.29-1-1.614zM6 1.875c1.654 0 3 1.262 3 2.813v.937H3v-.938c0-1.55 1.346-2.812 3-2.812zm1 9.01v1.303H5v-1.303a1.361 1.361 0 01-.5-1.041c0-.777.672-1.406 1.5-1.406s1.5.63 1.5 1.406c0 .415-.195.784-.5 1.041z' fill='%23D2DEEE' fill-opacity='.8'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;

const Divider = styled.div`
  margin: 0 20px;
  border-top: 1px solid var(--colors-surface-150);
`;

const MultipleEmployeesItem = styled.div`
  padding: 12px 0;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  text-align: center;
  color: var(--colors-primary-500-p);
  cursor: pointer;

  &:hover {
    background: var(--colors-surface-50);
  }
`;

export enum SearchControlItemId {
  all = "all",
  departments = "departments",
  supervisors = "supervisors",
  teams = "teams",
  subsidiaries = "subsidiaries",
}

export type SearchControlOnChangeData = {
  id: number | SearchControlItemId;
  label: string;
  /**   */
  employee?: SearchControlItem["obj"];
  uuid?: string;
  type?: SearchControlItemType;
  permissionSection: PermissionSectionName;
};

interface EmployeesSearchDropdownProps extends WithTranslation {
  selectedItem: string;
  items: SearchControlItem[];
  multipleEmployees: FilteredEmployeeProfile[];
  onItemChange: (item: SearchControlOnChangeData) => void;
  getSearchResults: (ev: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  withMultiple?: boolean;
  trackingLocation?: MassActionLocations;
  onAddMultiple: (data: { list: FilteredEmployeeProfile[] }) => void;
  withNavigateControls?: boolean;
  placeholder?: string;
  loading: boolean;
  disabled?: boolean;
  locked?: boolean;
  scheduleAdd?: boolean;
}

interface EmployeesSearchDropdownState {
  dropdownVisible: boolean;
  dialogOpened: boolean;
  preselectedItem: SearchControlItem | null;
}

class EmployeesSearchDropdown extends Component<EmployeesSearchDropdownProps, EmployeesSearchDropdownState> {
  searchTimeout: NodeJS.Timeout | null = null;
  wrapperRef: HTMLDivElement | null = null;

  readonly state: Readonly<EmployeesSearchDropdownState> = {
    dropdownVisible: false,
    dialogOpened: false,
    preselectedItem: null,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (ev: MouseEvent) => {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target as Node)) {
      this.toggleOpen(false);
    }
  };

  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.dropdownVisible;
    const preselectedItem = !isOpen ? null : this.state.preselectedItem;

    this.setState({ dropdownVisible: isOpen, preselectedItem });
  };

  onItemChange = (item: SearchControlItem) => {
    const { t, onItemChange } = this.props;
    const { obj, type } = item;
    let newItem: SearchControlOnChangeData;

    switch (type) {
      case SearchControlItemType.all:
        newItem = {
          id: SearchControlItemId.all,
          type: SearchControlItemType.all,
          label: translateEmployeeTerm(t, TranslationNamespaces.common, "custom-all-employees", "All Employees"),
        };
        break;
      case SearchControlItemType.departments:
        newItem = {
          id: SearchControlItemId.departments,
          type: SearchControlItemType.departments,
          label: t("All Departments"),
        };
        break;
      case SearchControlItemType.supervisors:
        newItem = {
          id: SearchControlItemId.supervisors,
          type: SearchControlItemType.supervisors,
          label: t("All Supervisors"),
        };
        break;
      case SearchControlItemType.teams:
        newItem = { id: SearchControlItemId.teams, type: SearchControlItemType.teams, label: t("All Teams") };
        break;
      case SearchControlItemType.subsidiaries:
        newItem = {
          id: SearchControlItemId.subsidiaries,
          type: SearchControlItemType.subsidiaries,
          label: t("All Subsidiaries"),
        };
        break;
      case SearchControlItemType.employee: {
        const o = obj as GroupSearchUserProfile | SearchEmployeeUserProfile;

        newItem = { id: o.id, label: o.full_name, employee: o, uuid: o.uuid };
        break;
      }
      default: {
        const o = obj as Exclude<SearchControlItem["obj"], GroupSearchUserProfile | SearchEmployeeUserProfile | null>;

        newItem = { id: o.id, label: o.name, employee: o, uuid: o.uuid, type };
        break;
      }
    }

    if (onItemChange) {
      onItemChange(newItem);
    }

    this.toggleOpen(false);
  };

  handleKeyPress = (ev: KeyboardEvent<HTMLInputElement>) => {
    const { items, multipleEmployees, onClear } = this.props;
    const { preselectedItem } = this.state;

    if (items.length) {
      if (ev.which === 27) {
        this.toggleOpen(false);
      } else if (ev.which === 38 || ev.which === 40) {
        ev.stopPropagation();
        ev.preventDefault();

        this.preselectValue(ev.which === 38);
      } else if (ev.which === 13) {
        if (preselectedItem) {
          this.onItemChange(preselectedItem);
        } else {
          this.toggleOpen(false);
        }
      } else if (multipleEmployees.length) {
        onClear();
      }
    }
  };

  preselectValue = (isUp: boolean) => {
    const { items } = this.props;
    const { preselectedItem } = this.state;

    if (preselectedItem) {
      let nextIndex = items.map((i) => i.value).indexOf(preselectedItem.value);

      if (items.length > 1) {
        nextIndex = isUp ? nextIndex - 1 : nextIndex + 1;

        if (nextIndex < 0) {
          nextIndex = items.length - 1;
        } else if (nextIndex > items.length - 1) {
          nextIndex = 0;
        }
      }
      this.setState({ preselectedItem: items[nextIndex] });
    } else {
      this.setState({ preselectedItem: items[0] });
    }
  };

  render() {
    const { dropdownVisible, preselectedItem, dialogOpened } = this.state;
    const {
      t,
      getSearchResults,
      items = [],
      selectedItem,
      placeholder,
      withNavigateControls,
      withMultiple,
      trackingLocation,
      onClear,
      loading,
      disabled,
      locked,
      onAddMultiple,
      scheduleAdd,
      permissionSection,
    } = this.props;

    if (withMultiple && !trackingLocation) {
      // PROD-14851 task
      console.error("'trackingLocation' prop is required when 'withMultiple' is true");
    }

    return (
      <div className={b()}>
        <InputFieldIcon />
        <input
          disabled={disabled || locked}
          className={b("input-field", {
            newStyle: true,
            scheduleAdd,
            "with-controls": withNavigateControls,
            "has-value": !!selectedItem,
            disabled,
            locked,
          })}
          placeholder={placeholder}
          type="text"
          value={selectedItem}
          onKeyDown={this.handleKeyPress}
          onClick={() => this.toggleOpen(true)}
          onChange={(value) => {
            this.toggleOpen(true);
            getSearchResults(value);
          }}
        />

        {!disabled && !locked && !withNavigateControls && !!selectedItem && (
          <ClearIcon
            $loading={loading}
            onClick={() => {
              this.setState({ preselectedItem: null, dropdownVisible: false }, onClear);
            }}
          />
        )}
        {locked && <LockIcon />}

        {dropdownVisible && (
          <div className={b("dropdown")} ref={this.setWrapperRef}>
            {items.map((item) => (
              <Item
                textToHighlight={selectedItem}
                item={item}
                preselected={!!(preselectedItem && preselectedItem.value === item.value)}
                key={item.value}
                t={t}
                onItemClick={this.onItemChange}
              />
            ))}
            {withMultiple && items.length > 0 ? <Divider /> : null}
            {withMultiple ? (
              <MultipleEmployeesItem
                onClick={() => {
                  this.setState({ dialogOpened: true, dropdownVisible: false });
                }}
              >
                {translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-select-multiple-employees",
                  "Select multiple employees",
                )}
              </MultipleEmployeesItem>
            ) : null}
          </div>
        )}

        {withMultiple ? (
          <MultipleEmployeeSelector
            permissionSection={permissionSection}
            onClose={() => this.setState({ dialogOpened: false })}
            dialogOpened={dialogOpened}
            addEmployees={(list: FilteredEmployeeProfile[]) => {
              if (trackingLocation) {
                services.punchEventTracking.trackMassAction(list?.length ?? 0, trackingLocation);
              }
              onAddMultiple({ list });
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(EmployeesSearchDropdown);
