import { useTranslation } from "react-i18next";
import { Feature } from "types/models/subscription";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useContext, useState } from "react";
import { stylesheet } from "astroturf";
import GlobalContext from "context/global-context";
import { useHistory } from "react-router-dom";
import ga from "utils/ga";
import { SubscriptionApi } from "utils/api/subscription";
import SentryUtils from "utils/sentryUtils";
import { useHubspot } from "hooks/useHubspot";
import MainPage from "./MainPage";
import * as images from "../svg-images";
import { InputTagControl } from "../UI/InputTagControl/intex";

const styles = stylesheet`
  .Wrapper {
    margin-bottom: 64px;
  }
  .BlockTitle {
    font-size: 12px;
    font-weight: var(--typography-font-weight-bold);
    color: var(--colors-surface-800);
    text-transform: uppercase;
    margin-bottom: 12px;
  }
  .ObjectivesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .Badge {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 14px;
    height: 48px;
    padding: 0 16px 0 12px;
    border: 2px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);
    .Check {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--colors-surface-150);
      margin-inline-end: 8px;
      svg {
        width: 10px;
        height: 8px;
        path {
          fill: white;
        }
      }
    }
    .FeatureLabel {
      font-size: 15px;
      color: var(--colors-surface-900-p);
    }
    &.Active {
      border-color: var(--colors-primary-500-p);
      .Check {
        border-color: var(--colors-primary-500-p);
        background: var(--colors-primary-500-p);
      }
    }
  }
  .OtherInput {
    margin-top: 26px;

  }
`;

const features: Record<string, { selected: boolean; feature: Feature; customValues?: string[] }> = {
  closingPayroll: {
    selected: false,
    feature: Feature.TA,
  },
  managingTimeOff: {
    selected: false,
    feature: Feature.TA,
  },
  calculatingTimeOff: {
    selected: false,
    feature: Feature.TA,
  },
  managingSchedules: {
    selected: false,
    feature: Feature.TA,
  },
  billingTS: {
    selected: false,
    feature: Feature.Project,
  },
  timeSpendTS: {
    selected: false,
    feature: Feature.Project,
  },
  monitorBudget: {
    selected: false,
    feature: Feature.Project,
  },
  other: {
    customValues: [],
    selected: false,
    feature: Feature.Bundle,
  },
};

export const SelectObjectivesPage = () => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const context = useContext(GlobalContext);
  const history = useHistory();
  useHubspot();
  const [selectedFeatures, selectFeatures] = useState(features);
  const [loading, setLoading] = useState<boolean>(false);

  const onNextClick = async () => {
    setLoading(true);
    const company = await context.getCompany();
    const featuresSet = new Set<string>();
    const selectedOptions = Object.keys(selectedFeatures).reduce((acc: string[], key) => {
      if (selectedFeatures[key].selected) {
        if (key === "other") {
          featuresSet.add(Feature.TA);
          featuresSet.add(Feature.Project);
        } else {
          featuresSet.add(selectedFeatures[key].feature);
        }
        acc.push(key);
      }
      return acc;
    }, []);
    try {
      await SubscriptionApi.selectObjectives(company.uuid, {
        objectives: selectedOptions,
        others: selectedFeatures.other.selected ? selectedFeatures.other.customValues : undefined,
      });
    } catch (e) {
      SentryUtils.sendError(e);
    }

    const productsArray = [...featuresSet.values()];
    ga.selectObjectivesClick(productsArray.length === 2 ? Feature.Bundle : productsArray[0]);
    const query = productsArray.join("&");

    history.push(`/select-plan?plan=${query}`);
    setLoading(false);
  };
  const toggleBadge = (feature: string) => {
    selectFeatures({
      ...selectedFeatures,
      [feature]: {
        ...selectedFeatures[feature],
        selected: !selectedFeatures[feature].selected,
      },
    });
  };
  const setOtherValues = (values: string[]) => {
    selectFeatures({
      ...selectedFeatures,
      other: {
        ...selectedFeatures.other,
        customValues: values,
      },
    });
  };

  return (
    <MainPage
      topRowText=""
      topRowButtonText={t("LOGIN")}
      topRowButtonClick={() => history.push("/login")}
      buttonText={t("Continue")}
      pageTitle={t("select_objectives_page_title")}
      pageSubTitle={t("select_objectives_sub-title")}
      onSubmit={onNextClick}
      loading={loading}
      disabled={!Object.values(selectedFeatures).find((i) => i.selected)}
      maxWidth={748}
    >
      <div className={styles.Wrapper}>
        <div className={styles.BlockTitle}>{t("select_objectives_block_title")}</div>
        <div className={styles.ObjectivesWrapper}>
          {Object.keys(selectedFeatures).map((f) => (
            <div
              onClick={() => toggleBadge(f)}
              className={`${styles.Badge} ${selectedFeatures[f].selected ? styles.Active : ""}`}
            >
              <div className={styles.Check}>{images.check}</div>
              <div className={styles.FeatureLabel}>{t(`select_objectives_label_${f}`)}</div>
            </div>
          ))}
        </div>
        {selectedFeatures.other.selected ? (
          <div className={styles.OtherInput}>
            <div className={styles.BlockTitle}>{t("select_objectives_other_input_title")}</div>
            <InputTagControl tags={selectedFeatures.other.customValues!} setTags={setOtherValues} />
          </div>
        ) : null}
      </div>
    </MainPage>
  );
};
