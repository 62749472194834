import { stylesheet } from "astroturf";
import { useMemo } from "react";
import { ReactComponent as InfoIcon } from "components/Billing/icons/info_notification_ic.svg";

export enum BlockNotificationType {
  Error = "error",
}

type BlockNotificationProps = {
  type: BlockNotificationType;
  title: string;
  description?: string;
  styles?: Record<string, string>;
};

const styles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;

  &.error {
    background: rgba(191, 77, 75, 0.07);
    .Icon svg path {
      fill: var(--colors-danger-600-p);
    }
    .Title {
      color: var(--colors-danger-700);
    }
  }
  .Icon svg {
    width: 26px;
    height: 26px;
  }
  .Title {
    font-size: 16px;
  }
  .Description {
    margin-top: 6px;
    color: var(--colors-surface-900-p);
    font-size: var(--typography-font-size-default);
  }
}
`;

export const BlockNotification = (props: BlockNotificationProps) => {
  const icon = useMemo(() => {
    switch (props.type) {
      case BlockNotificationType.Error:
        return <InfoIcon />;
      default:
        return null;
    }
  }, [props.type]);

  return (
    <div style={props.styles} className={`${styles.Wrapper} ${styles[props.type]}`}>
      {icon ? <div className={styles.Icon}>{icon}</div> : null}
      <div>
        <div className={styles.Title}>{props.title}</div>
        {props.description ? <div className={styles.Description}>{props.description}</div> : null}
      </div>
    </div>
  );
};
