import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "context/GlobalContextProvider";
import { ActiveUserProfile, UserProfileRole } from "types/models/userProfile";
import { ClickOutsideType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { fireEvent, getImgUrl, strIncludesCheck } from "utils/common";
import { chooseProfile } from "utils/apiHelpers";
import { clearDb } from "utils/oitchauDb";
import ModalDialog from "components/UI/ModalDialog";
import CreateCompany from "components/Signup/CreateCompany";
import { translateEmployeeTerm } from "utils/translationHelpers";
import * as Images from "./menu-svg-icons";

const Wrapper = styled.div``;

const Search = styled.input`
  width: 180px;
  line-height: 130%;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-600);
  outline: none;
  border: none;
`;

const MenuSection = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid var(--colors-surface-150);

  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const MenuOption = styled.div<{ $withhover?: boolean }>`
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
  line-height: 32px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  border-radius: var(--shapes-border-radius-default);

  &:hover {
    background: ${(p) => (p.$withhover ? "rgba(0, 0, 0, 0.06)" : "transparent")};
  }

  span,
  input {
    margin: 0 8px;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CompanyList = styled(MenuSection)`
  max-height: 175px;
  overflow-y: scroll;
`;

const CompanyListOption = styled.div`
  width: 250px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

const CompanyLogo = styled.div`
  width: 32px;
  height: 32px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }

  div {
    border-radius: var(--shapes-border-radius-default);
    background: var(--colors-primary-100);
    line-height: 32px;
    font-size: 16px;
    text-align: center;
    color: var(--colors-primary-400);
    background: var(--colors-primary-100);
  }
`;

const CompanyInfo = styled.div`
  width: 150px;

  .name {
    font-size: var(--typography-font-size-default);
    line-height: 18px;
    color: var(--colors-surface-900-p);

    overflow: hidden;
    text-overflow: ellipsis;
    wite-spacw: nowrap;
  }

  .role {
    font-size: 12px;
    line-height: 14px;
    color: var(--colors-surface-600);
  }
`;

const CompanySelected = styled.div`
  width: 20px;
  height: 20px;
`;

const CreateCompanyOption = styled.div`
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
`;

interface CompanySwitchProps {
  isOpened: boolean;
  closeCompanySwitch: () => void;
  closeUserMenu: () => void;
}

const CompanySwitchNew = ({ isOpened, closeCompanySwitch, closeUserMenu }: CompanySwitchProps) => {
  const context = useContext(GlobalContext);
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const [searchValue, setSearchValue] = useState("");
  const [showCreateCompanyPopup, setShowCreateCompanyPopup] = useState(false);
  const [profiles, setProfiles] = useState<ActiveUserProfile[]>([]);

  const onChooseProfile = async (profileId: number) => {
    fireEvent("LogOut");

    await clearDb();
    await chooseProfile({ profileId });

    window.location = "/" as unknown as Location;
  };

  useEffect(() => {
    void (async () => {
      const activeProfiles = await context.getActiveProfiles();

      activeProfiles.sort((profileA, profileB) => {
        if (profileA.company && profileB.company) {
          if (profileA.company.name.toLowerCase() > profileB.company.name.toLowerCase()) {
            return 1;
          }
          if (profileA.company.name.toLowerCase() < profileB.company.name.toLowerCase()) {
            return -1;
          }
          return 0;
        }
        return -1;
      });

      setProfiles(activeProfiles);
    })();
  }, []);

  return (
    <>
      {isOpened && (
        <ClickOutside<ClickOutsideType> onClickOutside={closeCompanySwitch}>
          <Wrapper className="company-switch">
            <MenuSection>
              <MenuOption>
                <Images.Search />
                <Search
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={t("Search workspaces")}
                />
              </MenuOption>
            </MenuSection>

            <CompanyList>
              {profiles
                .filter((p) => {
                  if (!searchValue) return true;
                  if (!p.company) return false;
                  return strIncludesCheck(p.company.name, searchValue) || strIncludesCheck(p.full_name, searchValue);
                })
                .map((p) => {
                  const avatarUrl = p.company.logo_id
                    ? getImgUrl(
                        p.company.logo_id,
                        "f_auto,q_auto/h_100,w_100,c_thumb,g_face,fl_progressive.force_strip/",
                      )
                    : "";

                  return (
                    <CompanyListOption key={p.uuid} onClick={() => onChooseProfile(p.id)}>
                      <CompanyLogo>
                        {avatarUrl ? <img alt="" src={avatarUrl} /> : <div>{p.company.name[0]}</div>}
                      </CompanyLogo>

                      <CompanyInfo>
                        <div className="name">{p.company.name}</div>
                        <div className="role">
                          {p.role === UserProfileRole.employee
                            ? translateEmployeeTerm(
                                t,
                                TranslationNamespaces.common,
                                "custom-employee",
                                `${TranslationNamespaces.common}|Employee`,
                              )
                            : t(`${TranslationNamespaces.common}|${p.role}`)}
                        </div>
                      </CompanyInfo>

                      <CompanySelected>
                        {p.uuid === window.global_store.profile?.uuid && <Images.SelectedCompany />}
                      </CompanySelected>
                    </CompanyListOption>
                  );
                })}
            </CompanyList>

            <MenuSection>
              <MenuOption $withhover>
                <Images.CreateCompany />

                <CreateCompanyOption
                  onClick={() => {
                    setShowCreateCompanyPopup(true);
                    closeUserMenu();
                  }}
                >
                  <span>{t("Add New Profile")}</span>
                </CreateCompanyOption>
              </MenuOption>
            </MenuSection>
          </Wrapper>
        </ClickOutside>
      )}

      <ModalDialog
        width={530}
        top="8%"
        isOpen={showCreateCompanyPopup}
        onClose={() => setShowCreateCompanyPopup(false)}
      >
        <CreateCompany />
      </ModalDialog>
    </>
  );
};

export default CompanySwitchNew;
