import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { stylesheet } from "astroturf";
import { UserProfile } from "types/models/userProfile";
import { getTitle } from "utils/common";
import { getDetectedLanguage } from "utils/appInitHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";

interface NoPermissionsProps extends WithTranslation, RouteComponentProps {}
interface NoPermissionsState {
  profile: UserProfile;
  locale: string;
}
const styles = stylesheet`
  .Layout {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;

    .Overlay {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      max-width: 1280px;
      margin: 0 auto;
    }
    .CenterImage, .LeftImage, .RightImage {
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
    }
    .CenterImage {
      width: 80%;
      height: 65%;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: 50%;
      margin: 0 auto;
      background-position: top center;
    }
    .LeftImage {
      width: 310px;
      height: 400px;
      inset-inline-start: 0;
      background-position: 0 bottom;
      background-size: contain;
    }
    .RightImage {
      width: 280px;
      height: 400px;
      inset-inline-end: 0;
      background-position: 0 bottom;
      background-size: contain;
    }
    .TopBlock {
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .Title {
      font-weight: var(--typography-font-weight-default);
      font-size: 30px;
      line-height: 38px;
      color: var(--colors-mainText);
      margin-bottom: 16px;
    }
  }

  .LayoutBackground {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    
    &:before {
      content: "";
      width: 1px;
      height: 1px;
      bottom: 0;
      left: 30%;
      position: absolute;
      transform: translate(-1000px, 1250px);
      border-radius: 50%;
      border: 1000px solid rgba(160, 178, 207, 0.1);
      z-index: 0;
    }
    
    &:after {
      content: "";
      width: 1px;
      height: 1px;
      right: 30%;
      bottom: 0;
      position: absolute;
      transform: translate(1000px, 1250px);
      border-radius: 50%;
      border: 1000px solid rgba(160, 178, 207, 0.1);
      z-index: 0;
    }

    &:after, &:before {
      top: 170px;
      transform: none;
    }
  }
`;

class NoPermissions extends Component<NoPermissionsProps, NoPermissionsState> {
  constructor(props: NoPermissionsProps) {
    super(props);
    const { t } = props;
    document.title = getTitle(t("No permissions"));
    this.state = {
      profile: window.global_store.profile,
      locale: getDetectedLanguage(),
    };
  }

  render() {
    const { locale } = this.state;
    const { t } = this.props;

    const headerMessage = t("no-permissions-header");
    const descriptionMessage = t("no-permissions-description");

    return (
      <FullPage minimal>
        <div className={`${styles.Layout}`}>
          <div className={styles.LayoutBackground} />
          <div className={styles.Overlay}>
            <div className={styles.TopBlock}>
              <h1 className={styles.Title}>{headerMessage}</h1>
              <div>{descriptionMessage}</div>
            </div>
            <div className={styles.CenterImage} style={{ backgroundImage: `url(/lock/${locale}/center.svg)` }} />
            <div className={styles.LeftImage} style={{ backgroundImage: `url(/lock/${locale}/left.svg)` }} />
            <div className={styles.RightImage} style={{ backgroundImage: `url(/lock/${locale}/right.svg)` }} />
          </div>
        </div>
      </FullPage>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.fallback)(NoPermissions));
