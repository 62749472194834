import { useContext, useEffect } from "react";
import { ServicesContext } from "context/services-context";

/**
 * Prefer using specific service hooks instead of this one
 */
export function useServices() {
  const svcs = useContext(ServicesContext);
  return svcs;
}

export function useAuthService() {
  const svcs = useServices();
  return svcs.authService;
}

export function useProjectsService() {
  const svcs = useServices();
  return svcs.projectsService;
}

export function useWidgetProjectsService() {
  const svcs = useServices();
  return svcs.widgetProjectsService;
}

export function useProjectsEventTracking() {
  const svcs = useServices();
  return svcs.projectsEventTracking;
}

export function useProjectsWsService() {
  const svcs = useServices();
  return svcs.projectsWsService;
}

export function usePunchNowService() {
  const svcs = useServices();
  return svcs.punchNowService;
}

export function useKvStorage() {
  const svcs = useServices();
  return svcs.kvStorage;
}

export function useAppStorage() {
  const svcs = useServices();
  return svcs.appStorage;
}

export function useExtensionService() {
  const svcs = useServices();
  return svcs.extensionService;
}

export function useInjectionCommands() {
  const svcs = useServices();
  useEffect(() => {
    window.addEventListener("message", svcs.injectionCommands.distribute);
    return () => window.removeEventListener("message", svcs.injectionCommands.distribute);
  }, [svcs.injectionCommands.distribute]);
  return svcs.injectionCommands;
}
