import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import { Punch } from "types/models/punches";
import { OnPunchActionData, OnPunchDeclineData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import { ShiftEventLog } from "types/models/schedule";
import ga, { GaApprovePunchButtonLocation } from "utils/ga";
import moment from "moment";
import * as momentTz from "moment-timezone";
import ViewPunchIconButton from "components/controls/IconButton/ViewPunchIconButton";
import { fireEvent } from "utils/common";
import EyeIcon from "@iconscout/react-unicons/icons/uil-eye";

const EventsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--shapes-border-radius-default);
`;
const LogEventItem = styled.div`
  padding: 12px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  position: relative;

  .button-view-punch {
    position: absolute;
    inset-inline-end: 12px;
    top: 12px;
    display: none;
  }

  &:hover {
    background: var(--colors-surface-50, #f7f9fc);
    .button-view-punch {
      display: flex;
    }
  }
`;
const LogEventItemTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  width: 100%;
`;
const LogEventItemAction = styled.div`
  color: var(--colors-surface-900-p, #434e6c);
  font-size: 15px;
  font-weight: var(--typography-font-weight-default);
`;
const LogEventItemTime = styled.div`
  color: var(--colors-surface-500, #a0aec6);
  font-size: 12px;
  font-weight: var(--typography-font-weight-default);
`;
const LogEventItemDescription = styled.div`
  color: var(--colors-surface-700, #707d9e);
  font-size: 13px;
  font-weight: var(--typography-font-weight-default);
`;

type ShiftEventsListProps = {
  userProfineUuid: string;
  punches: Punch[];
  events: ShiftEventLog[];
  onPunchApprove: (data: OnPunchActionData) => void;
  onPunchValidate: (data: OnPunchActionData) => void;
  onPunchDecline: (data: OnPunchDeclineData) => void;
};

const ShiftEventsList = ({
  userProfineUuid,
  punches,
  events,
  onPunchApprove,
  onPunchValidate,
  onPunchDecline,
}: ShiftEventsListProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const punchesMap = punches.reduce((acc, punch) => {
    acc[punch.uuid] = punch;
    return acc;
  }, {} as Record<string, Punch>);

  return (
    <EventsListWrapper>
      {events.map((event: ShiftEventLog) => (
        <LogEventItem key={event.triggeredAt}>
          <LogEventItemTopRow>
            <LogEventItemAction>{t(event.action)}</LogEventItemAction>
            <LogEventItemTime>{moment(event.triggeredAt).format("DD/MM/YYYY HH:mm")}</LogEventItemTime>
          </LogEventItemTopRow>
          <LogEventItemDescription>{event.triggeredBy.name || t(event.triggeredBy.trigger)}</LogEventItemDescription>
          {punchesMap[event.punch_uuid] && (
            <ViewPunchIconButton
              className="button-view-punch"
              onClick={(ev) => {
                ev.stopPropagation();
                const punch = punchesMap[event.punch_uuid];

                fireEvent(SuperpunchCustomEvents.openPunchDetails, {
                  punch,
                  onPunchDetailsApprove: () => {
                    ga.trackApprovePunch({
                      punch_type: punch.punch_type,
                      button_location: GaApprovePunchButtonLocation.superPunch,
                    });

                    onPunchApprove({
                      destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                      punchUuid: punch.uuid,
                      punchId: punch.id,
                      employeeUuid: userProfineUuid,
                    });
                  },
                  onPunchDetailsValidate: () => {
                    onPunchValidate({
                      destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                      punchUuid: punch.uuid,
                      punchId: punch.id,
                      employeeUuid: userProfineUuid,
                    });
                  },
                  onPunchDetailsDecline: (declineReason: string) => {
                    onPunchDecline({
                      destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                      punchUuid: punch.uuid,
                      punchId: punch.id,
                      employeeUuid: userProfineUuid,
                      declineReason,
                    });
                  },
                });
              }}
            >
              <EyeIcon width={20} />
            </ViewPunchIconButton>
          )}
        </LogEventItem>
      ))}
    </EventsListWrapper>
  );
};

export default ShiftEventsList;
