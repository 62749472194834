import styled from "styled-components";
import HistoryIcon from "@iconscout/react-unicons/icons/uil-history";
import IconAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";
import IconAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";
import Tooltip from "components/UI/Tooltip";
import ButtonComponent from "components/UI/ButtonComponent";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import IconButton from "components/UI/IconButton";

const Header = styled.div`
  height: var(--component-menu-punches-title-height, 44px);
  padding: 0px var(--component-menu-punches-title-wicon-hpad, 8px);
  gap: var(--component-menu-punches-title-gap, 4px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--component-menu-punches-title-border, 1px) solid
    var(--component-menu-punches-title-border-color, #e1e3eb);
  background: var(--component-menu-punches-title-bg-color, #fff);
`;
const TitleWithBack = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 4px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const Separator = styled.div`
  width: var(--component-menu-punches-title-separator-width, 1px);
  height: var(--component-menu-punches-title-separator-height, 16px);
  background: var(--component-menu-punches-title-separator-color, #e1e3eb);
`;
const Title = styled.span`
  overflow: hidden;
  color: var(--component-menu-punches-title-label-text-color, #3e4357);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 450;
  line-height: 20px;
`;

type DropdownHeaderProps = {
  title: string;
  onBackClick?: () => void;
  onHistoryClick?: () => void;
  onClearClick?: () => void;
};

const DropdownHeader = ({ title, onBackClick, onHistoryClick, onClearClick }: DropdownHeaderProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  return (
    <Header>
      <TitleWithBack>
        {onBackClick && (
          <IconButton
            size="medium"
            variant="tertiary"
            onClick={onBackClick}
            icon={window.global_store.isRTL ? <IconAngleRight width={20} /> : <IconAngleLeft width={20} />}
          />
        )}
        <Title data-tip data-for="cell-dropdown-title">
          {title}
        </Title>
        <Tooltip id="cell-dropdown-title" wrapperStyle={{ zIndex: 1 }}>
          {title}
        </Tooltip>
      </TitleWithBack>
      {(onClearClick || onHistoryClick) && (
        <Actions>
          {onClearClick && (
            <ButtonComponent
              label={t("Clear")}
              onClick={onClearClick}
              size="small"
              variant="tertiary"
              color="neutral"
            />
          )}
          {onClearClick && onHistoryClick && <Separator />}
          {onHistoryClick && (
            <IconButton size="medium" variant="tertiary" icon={<HistoryIcon width={20} />} onClick={onHistoryClick} />
          )}
        </Actions>
      )}
    </Header>
  );
};

export default DropdownHeader;
