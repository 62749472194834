// TODO
enum ClientReportType {
  detailed = "detailed",
}

enum ClientReportDownloadStatus {
  inProgress = "in_progress",
  done = "done",
  longTime = "long_time",
  error = "error",
}

type ClientReportDownload = {
  uuid: string;
  status: ClientReportDownloadStatus;
  id: number;
  report_type: ClientReportType;
  filename: string;
  generating_start_time: string;
  generating_end_time: string;
  download_url: string;
  email_sended_at: string | null;
};

export type { ClientReportDownload };

export { ClientReportDownloadStatus };
