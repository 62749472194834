import styled from "styled-components";
import ErrorLabel from "./ErrorLabel";

export const BlockTitle = styled.div`
  color: var(--colors-surface-500, #a0aec6);
  font-family: var(--typography-font-family-default);
  font-size: 12px;
  font-weight: var(--typography-font-weight-bold);
  line-height: 100%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export { ErrorLabel };
