import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { TFunction } from "i18next";
import { IconErrorBig } from "../styled";
import { Button, ButtonType } from "../Button";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  margin: 0 auto;
`;

const Top = styled.div`
  height: 300px;
  padding-top: 102px;
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  text-align: center;
  max-width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 76px;
    max-width: 100%;
    align-items: center;
  }
`;

const Header = styled.div`
  font-size: 20px;
  letter-spacing: -0.03em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Message = styled.div`
  width: 232px;
  font-size: 15px;
  color: var(--colors-secondary5);
`;

const Bottom = styled.div`
  flex-shrink: 0;
  width: 300px;
  padding-bottom: 50px;

  button {
    height: 40px;
  }
`;

export interface ErrorViewProps {
  onClose?: () => void;
  error: any;
}

const formatError = (message: string, t: TFunction) => {
  if (message.includes("/utc_time")) {
    return t("Can't get server time");
  }
  if (message.includes("/projects/")) {
    return t("Can't get activities");
  }
  if (message.includes("/locations")) {
    return t("Can't get locations");
  }
  if (message.includes("/service_provider_settings")) {
    return t("Can't get services");
  }
  if (message.includes("user_profiles/current")) {
    return t("Can't get current profile");
  }
  if (message.includes("api/company")) {
    return t("Can't get company details");
  }
  if (message.includes("/api/punches") && message.indexOf("GET") === 0) {
    return t("Can't get punches");
  }
  if (message.includes("/api/punches") && message.indexOf("POST") === 0) {
    return t("Can't send the punch");
  }
  if (message.includes("/shifts") && message.indexOf("POST") === 0) {
    return t("Can't get shifts");
  }
  if (message.includes("/shift_compilations") && message.indexOf("POST") === 0) {
    return t("Can't get shift compilations");
  }
  if (message === "connection-offline") {
    return t(message);
  }

  return message;
};

export function ErrorView(props: ErrorViewProps) {
  const { onClose, error } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const message = error?.message ? formatError(error.message, t) : t("Ops, we have a problem");

  return (
    <Wrapper>
      <Top>
        <IconErrorBig className="icon" />
      </Top>
      <Middle>
        <div>
          <Header>{message}</Header>
          <Message>{t("Check you internet conection and try again.")}</Message>
        </div>
      </Middle>
      <Bottom>
        <Button buttonType={ButtonType.neutral} onClick={onClose}>
          {t("Okay")}
        </Button>
      </Bottom>
    </Wrapper>
  );
}
