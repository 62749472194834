import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox/NewCheckbox";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import GlobalContext from "context/global-context";

import { Notification, NotificationType } from "types/common";
import { getCompanyRulesForProjects, updateCompanyRulesForProjects } from "components/Projects/projectsApiUtils";

const MainWrapper = styled.div`
  max-width: 420px;
  margin-top: 23px;
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;

type TimesheetSettingsForm = {
  timesheetDailyLock: boolean;
  timesheetWorkingHoursLock: boolean;
  stopActivitiesWithoutTaskBySchedule: boolean;
  /**
   * Don't allow activities before Schedule start - schedule start limit
   * Don't allow activities after Schedule end + schedule end limit
   * https://hrappbrazil.atlassian.net/browse/PB-1025
   */
  restrictActivitiesBasedOnSchedule: boolean;
  /**
   * Don't allow start activity if latest punch is not a punch in
   * Don't allow add activity if latest punch is not a punch in
   * https://hrappbrazil.atlassian.net/browse/PB-938
   */
  restrictActivitiesBasedOnPunchIn: boolean;
  /**
   * https://hrappbrazil.atlassian.net/browse/PB-938
   */
  suggestStartActivityBasedOnPunchIn: boolean;
  allowFutureActivities: boolean;
  enabled: boolean;
};

interface TimesheetSettingsProps {
  setNotification: (notification: Notification) => void;
  setOnSaveFn?: Dispatch<SetStateAction<{ fn: (() => Promise<void>) | null }>>;
}

export const TimesheetSettings = ({ setNotification, setOnSaveFn }: TimesheetSettingsProps) => {
  const { t } = useTranslation(TranslationNamespaces.timesheetSettings);
  const context = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<TimesheetSettingsForm>();
  const init = async () => {
    const company = await context.getCompany();
    const { content } = await getCompanyRulesForProjects({ companyUuid: company.uuid });
    setFormData({
      timesheetDailyLock: content.timesheetDailyLock,
      timesheetWorkingHoursLock: content.timesheetWorkingHoursLock,
      stopActivitiesWithoutTaskBySchedule: content.stopActivitiesWithoutTaskBySchedule,
      restrictActivitiesBasedOnSchedule: content.restrictActivitiesBasedOnSchedule,
      restrictActivitiesBasedOnPunchIn: content.restrictActivitiesBasedOnPunchIn,
      suggestStartActivityBasedOnPunchIn: content.suggestStartActivityBasedOnPunchIn,
      allowFutureActivities: content.allowFutureActivities,
      enabled: content.enabled,
    });
  };

  useEffect(() => void init(), []);

  const submit = async () => {
    setNotification({ notification: null, notificationType: null });

    const company = await context.getCompany();
    setLoading(true);

    try {
      await updateCompanyRulesForProjects({ companyUuid: company.uuid, body: formData! });
      setNotification({ notification: t("Timesheet settings saved"), notificationType: NotificationType.success });
    } catch (e) {
      setNotification({
        notification: t("Failed to save timesheet settings"),
        notificationType: NotificationType.error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (setOnSaveFn && formData) {
      setOnSaveFn({ fn: submit });
    }
  }, [formData]);

  return formData ? (
    <MainWrapper className="timesheet-settings">
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.timesheetDailyLock}
        label={t("timesheet_lock_more_24h")}
        onChange={(checked) => {
          setFormData({ ...formData, timesheetDailyLock: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.timesheetWorkingHoursLock}
        label={t("timesheet_lock_more_than_working_hours")}
        onChange={(checked) => {
          setFormData({ ...formData, timesheetWorkingHoursLock: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.stopActivitiesWithoutTaskBySchedule}
        label={t("stop-unnamed-activity-based-on-schedule")}
        onChange={(checked) => {
          setFormData({ ...formData, stopActivitiesWithoutTaskBySchedule: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.restrictActivitiesBasedOnSchedule}
        label={t("restrict-activites-based-on-schedule")}
        onChange={(checked) => {
          setFormData({ ...formData, restrictActivitiesBasedOnSchedule: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.restrictActivitiesBasedOnPunchIn}
        label={t("restrict-activities-based-on-punch-in")}
        onChange={(checked) => {
          setFormData({ ...formData, restrictActivitiesBasedOnPunchIn: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.suggestStartActivityBasedOnPunchIn}
        label={t("suggest-start-activity-based-on-punch-in")}
        onChange={(checked) => {
          setFormData({ ...formData, suggestStartActivityBasedOnPunchIn: checked });
        }}
      />
      <CheckboxControl
        css={{ marginBottom: "16px" }}
        checked={formData.allowFutureActivities}
        label={t("allow-adding-time-in-future")}
        onChange={(checked) => {
          setFormData({ ...formData, allowFutureActivities: checked });
        }}
      />
      {!setOnSaveFn && (
        <ButtonWrapper>
          <Button
            loading={loading}
            value={t(`${TranslationNamespaces.common}|Save`)}
            state={ButtonState.primary}
            onClick={submit}
          />
        </ButtonWrapper>
      )}
    </MainWrapper>
  ) : null;
};
