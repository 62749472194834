import styled from "styled-components";
import moment from "moment-timezone";

const TimeTextSmall = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 16px;
  line-height: 20px;
  color: var(--colors-text1);
`;

export interface TimeTextProps extends React.HTMLProps<HTMLDivElement> {
  date: Date | number;
  timezone: string;
}

export default function TimeText(props: TimeTextProps) {
  const { date, timezone, ...rest } = props;
  const format = "HH:mm - L";
  const text = moment(date).tz(timezone).format(format);
  return <TimeTextSmall {...rest}>{text}</TimeTextSmall>;
}
