import React, { Component } from "react";
import styled from "styled-components";
import TextInput from "components/controls/TextInputControlNew";
import LocationSearchInput from "components/LocationSearchInput";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { createCompany } from "utils/apiHelpers";
import { showSnackbar } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";

const AddCode = styled.div`
  font-style: normal;
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 18px;
  color: var(--colors-primary);
  cursor: pointer;
  margin-top: -16px;
  margin-bottom: 60px;
`;

class CreateCompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      employeesCount: "11 to 50",
      companyType: "household",
      showCodeField: false,
      refCode: props.refCode || "",
      category: "",
      address: { formatted: "", lat: "", lon: "", country: "" },
    };
  }
  validateCompanyName() {
    const { companyName } = this.state;
    const { t } = this.props;
    let error = "";
    if (!companyName) {
      error = t("Company name can't be empty");
    } else {
      error = "";
    }
    return error;
  }
  validateAddress() {
    const { address } = this.state;
    const { t } = this.props;
    const value = address.country;
    let error = "";
    if (!value) {
      error = t("Address can't be empty.");
      error += ` ${t("Please select address form dropdown")}`;
    } else {
      error = "";
    }
    return error;
  }

  onNextClick = (ev) => {
    ev.preventDefault();
    let errors = {};
    if (this.validateCompanyName()) {
      errors.companyName = this.validateCompanyName();
    }
    if (this.validateAddress()) {
      errors.address = this.validateAddress();
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
      this.submitForm();
    } else {
      this.setState({ errors });
    }
  };
  async submitForm() {
    const { companyName, address, employeesCount, category, refCode } = this.state;
    const { t } = this.props;
    this.setState({ loading: true, errors: null });

    try {
      await createCompany({
        companyType: "household",
        companyName,
        address,
        employeesCount,
        category,
        refCode,
      });
    } catch (error) {
      showSnackbar({ text: t(`Server responded with error:${error}`) });
      this.setState({
        loading: false,
      });
    }
  }
  render() {
    const { companyName, employeesCount, refCode, email, showCodeField, address, errors, loading } = this.state;
    const { t, history } = this.props;
    return (
      <MainPage
        topRowText={t("Want to add a Company?")}
        topRowButtonText={t("CREATE")}
        topRowButtonClick={() => history.push("/account-type")}
        buttonText={t("Continue")}
        pageTitle={t("Create Company")}
        onSubmit={this.onNextClick}
        loading={loading}
        formParams={{
          action: "/register",
          style: { paddingTop: "40px" },
          method: "post",
          id: "hs_create-company",
          noValidate: "novalidate",
          acceptCharset: "UTF-8",
        }}
      >
        <OnboardingFieldWrapper fieldName={t("COMPANY NAME")}>
          <TextInput
            name="company"
            onboarding
            value={companyName}
            placeholder={t("My Company")}
            size="70"
            type="text"
            error={errors?.companyName}
            onChange={(ev) => this.setState({ companyName: ev.target.value })}
          />
          {!!errors?.companyName && <ErrorLabel>{errors.companyName}</ErrorLabel>}
        </OnboardingFieldWrapper>
        <OnboardingFieldWrapper fieldName={t("Address")}>
          <LocationSearchInput
            address={address}
            isOnboarding
            isValid={!errors || !errors.address}
            onChange={(val) => this.setState({ address: val })}
          />
          {!!errors?.address && <ErrorLabel>{errors.address}</ErrorLabel>}
        </OnboardingFieldWrapper>

        <div style={{ display: "none" }}>
          <label htmlFor="numero_de_funcionarios">Employees number</label>
          <input type="text" name="numero_de_funcionarios" value={employeesCount} />
        </div>
        <div style={{ display: "none" }}>
          <label className="label label_onboarding">EMAIL</label>
          <input placeholder="EMAIL" type="email" value={email} />
        </div>

        {!showCodeField && (
          <AddCode onClick={() => this.setState({ showCodeField: true })}>{t("Add an Invite Code")}</AddCode>
        )}

        {!!showCodeField && (
          <OnboardingFieldWrapper fieldName={t("Invite Code (optional)")}>
            <TextInput
              onboarding
              value={refCode}
              placeholder={t("Invite Code")}
              type="text"
              size="70"
              onChange={(ev) => this.setState({ refCode: ev.target.value })}
            />
          </OnboardingFieldWrapper>
        )}
      </MainPage>
    );
  }
}

export default withRouter(
  withTranslation([TranslationNamespaces.signup, TranslationNamespaces.industryTypes])(CreateCompanyPage),
);
