import { Injectable } from "services/di";
import { RequestOptions, RequestService } from "services/request-service";
import {
  OitchauApiOptions,
  CreateActivityReq,
  ListActivitiesReq,
  Location,
  ListProjectsWithTasksReq,
  ProjRes,
  TaskRes,
  ActivityRes,
  ContentWrapped,
  GetServiceProviderSettingsResponse,
  GetCompanyRulesForProjectsResponse,
  GetCurrentProfileResponse,
  CreateProjReq,
  UpdateProjReq,
  CreateTaskReq,
  ListTimesheetsRequest,
  TimesheetsListItem,
  GetInjectedTaskSummaryRequest,
  GetInjectedTaskSummaryResponse,
  GetInjectedExternalAttributesSummeryRequest,
  GetInjectedExternalAttributesSummeryResponse,
  GetInjectedProjectSummeryRequest,
  GetInjectedProjectSummaryResponse,
  TimesheetDataQuery,
  TimesheetResponse,
  TimeForTaskOnDateQuery,
} from "./types";

@Injectable()
export class OitchauApi {
  constructor(protected requestService: RequestService, protected options: OitchauApiOptions) {}

  protected async req<T = any>(request: RequestOptions, authToken?: string): Promise<T> {
    const req: RequestOptions = {
      baseUrl: this.options.rubyApiUrl, // https://admin-api-stg.oitchau.com.br/api
      headers: {
        authorization: authToken ? `Bearer ${authToken}` : undefined,
        ...request.headers,
      },
      ...request,
    };
    const res = await this.requestService.request<T>(req);
    return res.body;
  }

  protected async reqClientAdminApi<T = any>(request: RequestOptions, authToken?: string): Promise<ContentWrapped<T>> {
    const req: RequestOptions = {
      baseUrl: this.options.clientAdminApiUrl, // https://stg-api.oitchau.com.br/v2/public/client_admin_api
      headers: {
        authorization: authToken ? `Bearer ${authToken}` : undefined,
        ...request.headers,
      },
      ...request,
    };
    const res = await this.requestService.request<ContentWrapped<T>>(req);
    return res.body;
  }

  /**
   * There may be a situation where provided authToken is valid
   * but another profile of an employee with a different authToken is chosen as current.
   * In this case request is successful and both authTokens are returned in the response.
   * Subsequent requests for entities belonging to the NON-current profile will fail.
   * Oitchau-admin handles this situation by updating used authToken so that it matches the current profile.
   */
  async getProfile(authToken: string) {
    const req: RequestOptions = {
      url: "/user_profiles/current",
    };
    const res = this.req<GetCurrentProfileResponse>(req, authToken);
    return res;
  }

  async getServiceProviderSettings(authToken: string, options) {
    const { companyUuid, employeeUuid, requestedByUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/user_profiles/${employeeUuid}/service_provider_settings`,
      query: {
        requested_by: employeeUuid || requestedByUuid,
      },
    };
    const res = this.reqClientAdminApi<GetServiceProviderSettingsResponse>(req, authToken);
    return res;
  }

  async getCompanyRulesForProjects(authToken: string, options) {
    const { companyUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/service_provider_settings`,
    };
    const res = this.reqClientAdminApi<GetCompanyRulesForProjectsResponse>(req, authToken);
    return res;
  }

  async getCurrentTime() {
    const req: RequestOptions = {
      url: "/utc_time",
    };
    const res = this.reqClientAdminApi<number>(req);

    return res;
  }

  async changeLocale(authToken: string, options) {
    const { employeeId, locale } = options;
    const req: RequestOptions = {
      method: "post",
      url: `/employees/${employeeId}/locale`,
      body: { locale },
    };
    const res = this.req(req, authToken);
    return res;
  }

  async getLocations(authToken: string) {
    const req: RequestOptions = {
      url: "/locations",
    };
    const res = this.req<{ locations: Location[] }>(req, authToken);
    return res;
  }

  async verifyLocation(authToken: string, options) {
    const req: RequestOptions = {
      url: `/locations/${options.locationId}/ip_addresses/validate_location`,
    };
    const res = this.req(req, authToken);
    return res;
  }

  async getCompany(authToken: string) {
    const req: RequestOptions = {
      url: "/company",
    };
    const res = this.req(req, authToken);
    return res;
  }

  async getShifts(authToken: string, options) {
    const req: RequestOptions = {
      query: {
        from: options.dateFrom,
        requested_by: options.requestedByUuid || options.employeeUuid,
        to: options.dateTo,
      },
      url: `/user_profiles/${options.employeeUuid}/shifts`,
    };
    const res = this.reqClientAdminApi<any>(req, authToken);
    return res;
  }

  async getShiftCompilation(authToken: string, options) {
    const req: RequestOptions = {
      url: `/user_profiles/${options.userProfileId}/shift_compilations/${options.date}`,
    };
    const res = this.req(req, authToken);
    return res;
  }

  async updateShiftCompilation(authToken: string, options) {
    const req: RequestOptions = {
      body: {
        content: options.operations,
      },
      method: "patch",
      query: {
        requested_by: options.requestedByUuid || options.employeeUuid,
      },
      url: `/companies/${options.companyUuid}/user_profiles/${options.employeeUuid}/shift_compilations`,
    };
    const res = this.reqClientAdminApi<any>(req, authToken);
    return res;
  }

  async getPunches(
    authToken: string,
    options: {
      employeeId: number;
      from: string;
      to: string;
    },
  ) {
    const req: RequestOptions = {
      query: {
        employee_id: options.employeeId,
        from: options.from,
        to: options.to,
      },
      url: `/punches`,
    };
    const res = this.req(req, authToken);
    return res;
  }

  async createPunch(authToken: string, options) {
    const req: RequestOptions = {
      body: options,
      method: "post",
      url: `/punches`,
    };
    const res = this.req(req, authToken);
    return res;
  }

  /**
   * Return all profiles active and inactive, some may have `company: null`.
   */
  async getOwnProfiles(authToken: string) {
    const req = {
      url: "/user_profiles/select",
    };
    const res = this.req(req, authToken);
    return res;
  }

  async chooseProfile(authToken: string, options: { profileId: number }) {
    const req: RequestOptions = {
      query: {
        id: options.profileId,
      },
      url: `/user_profiles/choose`,
    };
    const res = this.req(req, authToken);
    return res;
  }

  async getCurrentTranslationVersionNumber() {
    const req: RequestOptions = {
      url: "/management/get_translation_version",
    };
    const res = this.req(req);
    return res;
  }

  async listActivities(authToken: string, options: ListActivitiesReq) {
    const { companyUuid, ...query } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/activities`,
      query: { ...query },
    };
    const res = this.reqClientAdminApi<ActivityRes[]>(req, authToken);
    return res;
  }

  async getActivity(authToken: string, options) {
    const { companyUuid, activityUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/activities/${activityUuid}`,
    };
    const res = this.reqClientAdminApi<ActivityRes>(req, authToken);
    return res;
  }

  async createActivity(authToken: string, options: CreateActivityReq) {
    const { companyUuid, ...content } = options;
    const req: RequestOptions = {
      body: { content },
      method: "post",
      url: `/companies/${companyUuid}/projects/activities`,
    };
    const res = this.reqClientAdminApi<string>(req, authToken);
    return res;
  }

  async updateActivity(authToken: string, options: CreateActivityReq) {
    const { companyUuid, uuid, ...content } = options;
    const req: RequestOptions = {
      body: { content },
      method: "put",
      url: `/companies/${companyUuid}/projects/activities/${uuid}`,
    };
    const res = this.reqClientAdminApi<ActivityRes>(req, authToken); // TODO fix type (not COntentWrapped)
    return res;
  }

  async deleteActivity(authToken: string, options: CreateActivityReq) {
    const { companyUuid, uuid, ...content } = options;
    const req: RequestOptions = {
      body: { content },
      method: "delete",
      url: `/companies/${companyUuid}/projects/activities/${uuid}`,
    };
    const res = this.reqClientAdminApi<{ uuid: string }>(req, authToken);
    return res;
  }

  /**
   * Return all projects (including inactive) with all tasks including not assigned to employee (!isUserAssignedToService)
   */
  async listProjectsWithTasks(authToken: string, options: ListProjectsWithTasksReq) {
    const { companyUuid, employeeUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects-with-tasks`,
      query: {
        user_profile_uuid: employeeUuid,
      },
    };
    const res = this.reqClientAdminApi<ProjRes[]>(req, authToken);
    return res;
  }

  /*
   * Return all projects with tasks that is visible for given user
   */
  async listProjectsWithTasksForUser(authToken: string, options: ListProjectsWithTasksReq) {
    const { companyUuid, employeeUuid, requestedBy } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/user-profile/${employeeUuid}`,
      query: {
        requested_by: requestedBy,
      },
    };
    const res = this.reqClientAdminApi<ProjRes[]>(req, authToken);
    return res;
  }

  async listTasks(authToken: string, options: ListProjectsWithTasksReq) {
    const { companyUuid, employeeUuid, externalId, source, requestedBy } = options;
    const req: RequestOptions = {
      url:
        employeeUuid == null
          ? `/companies/${companyUuid}/tasks`
          : `/companies/${companyUuid}/tasks/user_profiles/${employeeUuid}`,
      query: {
        externalId,
        source,
        requested_by: requestedBy,
      },
    };
    const res = this.reqClientAdminApi<TaskRes[]>(req, authToken);
    return res;
  }

  async createProject(authToken: string, options: CreateProjReq) {
    const { companyUuid, body } = options;
    const req: RequestOptions = {
      method: "post",
      url: `/companies/${companyUuid}/projects`,
      body,
    };
    const res = this.reqClientAdminApi<string>(req, authToken);
    return res;
  }

  async updateProject(authToken: string, options: UpdateProjReq) {
    const { companyUuid, projectUuid, body } = options;
    const req: RequestOptions = {
      method: "put",
      url: `/companies/${companyUuid}/projects/${projectUuid}`,
      body,
    };
    const res = this.reqClientAdminApi(req, authToken);
    return res;
  }

  async getProject(authToken: string, options) {
    const { companyUuid, projectUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/${projectUuid}`,
    };
    const res = this.reqClientAdminApi<ProjRes>(req, authToken);
    return res;
  }

  async listProjects(authToken: string, options) {
    const { companyUuid } = options;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects`,
    };
    const res = this.reqClientAdminApi<ProjRes[]>(req, authToken);
    return res;
  }

  async createTask(authToken: string, options: CreateTaskReq) {
    const { companyUuid, projectUuid, ...content } = options;
    const req: RequestOptions = {
      method: "post",
      url: `/companies/${companyUuid}/projects/${projectUuid}/tasks`,
      body: {
        content,
      },
    };
    const res = this.reqClientAdminApi<string>(req, authToken);
    return res;
  }

  async listTimesheets(authToken: string, props: ListTimesheetsRequest) {
    const { userProfileUuid, companyUuid, from, to, requestedBy, approvalStatus } = props;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/timesheets`,
      query: {
        from,
        to,
        requested_by: requestedBy,
        approval_status: approvalStatus?.length !== 0 ? approvalStatus?.join(",") : undefined,
        user_profile_uuid: userProfileUuid,
      },
    };
    const res = await this.reqClientAdminApi<TimesheetsListItem[]>(req, authToken);
    return res;
  }

  async getInjectedExternalAttributesSummary(authToken: string, props: GetInjectedExternalAttributesSummeryRequest) {
    const { companyUuid, requestedBy, values, source, projectExternalId } = props;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/activities/injected/external_attributes_summary`,
      query: {
        values,
        source,
        requested_by: requestedBy,
        project_external_id: projectExternalId,
      },
    };
    return this.reqClientAdminApi<GetInjectedExternalAttributesSummeryResponse[]>(req, authToken);
  }

  async getInjectedProjectSummary(authToken: string, props: GetInjectedProjectSummeryRequest) {
    const { companyUuid, requestedBy, externalId, source } = props;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/activities/injected/project_summary`,
      query: {
        external_id: externalId,
        source,
        requested_by: requestedBy,
      },
    };
    return this.reqClientAdminApi<GetInjectedProjectSummaryResponse>(req, authToken);
  }

  /**
   * Get activity durations per employee for task created by widget injection (into e.g. Asana)
   */
  async getInjectedTaskSummary(authToken: string, props: GetInjectedTaskSummaryRequest) {
    const { companyUuid, requestedBy, name, source } = props;
    const req: RequestOptions = {
      url: `/companies/${companyUuid}/projects/activities/injected/task_summary`,
      query: {
        name,
        source,
        requested_by: requestedBy,
      },
    };
    const res = await this.reqClientAdminApi<GetInjectedTaskSummaryResponse>(req, authToken);
    return res;
  }

  async getTimesheet(authToken: string, props: TimesheetDataQuery): Promise<TimesheetResponse> {
    const { companyUuid, userProfileUuid, requestedBy, from, to } = props;
    const req = {
      url: `/companies/${companyUuid}/projects/activities/user_profiles/${userProfileUuid}/aggregated`,
      query: {
        from,
        to,
        requested_by: requestedBy,
      },
    };
    const res = await this.reqClientAdminApi(req, authToken);
    return res;
  }

  async upsertTimeForTaskOnDate(
    authToken: string,
    props: TimeForTaskOnDateQuery & { content: TimeForTaskOnDateQuery["body"] },
  ): Promise<TimesheetResponse> {
    const { companyUuid, userProfileUuid, content } = props;
    const req = {
      method: "post" as const,
      body: { content },
      url: `/companies/${companyUuid}/projects/activities/user_profiles/${userProfileUuid}/bulk/upsert_for_task`,
    };
    const res = await this.reqClientAdminApi(req, authToken);
    return res;
  }
}
