import { stylesheet } from "astroturf";
import { useTranslation, TranslationNamespaces } from "types/translationNamespaces";
import { IconStopActivity } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
  cursor: default;
  background: linear-gradient(258.94deg, var(--colors-primary) 0%, #0075D2 100%);
  border: none;

  &:hover {
    background: linear-gradient(258.94deg, var(--colors-primary) 0%, #0075D2 100%);
  }

  .Texts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Task {
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-default);
    }

    .ProjCli {
      font-size: 13px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: #D2EAFD;
    }
  }

  .NoTask {
    padding: 10.5px 16px;
    font-size: 15px;
    font-weight: var(--typography-font-weight-default);
    line-height: 100%;
    color: var(--colors-default);
    background: #1E97F7;
    border-radius: 60px;
    cursor: pointer;
  }

  .End {
    display: flex;
    gap: 10px;
    align-items: center;

    .Duration {
      min-width: 38px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-default);
      line-height: 130%;
      color: var(--colors-default);
      text-align: end;
    }

    .StopButton {
      cursor: pointer;
    }
  }
}
`;

export interface RunningActivityCardProps {
  client: string;
  task: string;
  project: string;
  duration: string;
  onStop: () => void;
  onEdit: () => void;
}

export function RunningActivityCard(props: RunningActivityCardProps) {
  const { client, task, project, duration, onStop, onEdit } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <div className={styles.Wrapper} onClick={onEdit}>
      {task ? (
        <div className={styles.Texts}>
          <div className={styles.Task}>{task}</div>
          <div className={styles.ProjCli}>{client ? `${project} / ${client}` : project}</div>
        </div>
      ) : (
        <div className={styles.NoTask}>{t("Select your task")}</div>
      )}
      <div className={styles.End}>
        <div className={styles.Duration}>{duration}</div>
        <IconStopActivity
          className={styles.StopButton}
          onClick={(evt) => {
            evt.stopPropagation();
            onStop();
          }}
        />
      </div>
    </div>
  );
}
