import { sendRequest } from "utils/apiHelpers";
import CONFIG from "../../config";

export function listHolidaysGroups(data) {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/holidays/groups`, CONFIG.client_admin_api);
}
export function createHolidaysGroup(data) {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/holidays/groups`, CONFIG.client_admin_api);
}
export function updateHolidaysGroup(data) {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}`,
    CONFIG.client_admin_api,
  );
}
export function getHolidaysGroup(data) {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}`,
    CONFIG.client_admin_api,
  );
}

export function toggleStatusHolidaysGroup(data) {
  return sendRequest(
    data.body,
    data.action === "deactivate" ? "DELETE" : "POST",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}/${data.action}`,
    CONFIG.client_admin_api,
  );
}
export function createHoliday(data) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}/holidays`,
    CONFIG.client_admin_api,
  );
}

export function deleteHoliday(data) {
  return sendRequest(
    {
      content: {
        updatedBy: data.updatedBy,
      },
    },
    "DELETE",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}/holidays/${data.holidayUuid}`,
    CONFIG.client_admin_api,
  );
}

export function assignUserProfiles(data) {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}

export function unassignUserProfiles(data) {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/holidays/groups/${data.holidaysGroupUuid}/user_profiles`,
    CONFIG.client_admin_api,
  );
}
