import { EmployeeInfo } from "../userProfile";

enum DigitalSignatureStatus {
  missing = "missing",
  pending = "pending",
} // TODO: add more

type Phases = Record<string, number>;

type ReportDaySchedule = {
  uuid: string;
  name: string;
};

type ReportDayBusinessRulesGroup = {
  uuid: string;
  name: string;
};

export enum ReportStatus {
  completed = "completed", // TODO: add more
}

type Punch = {
  description: string;
  key: string;
  timezone: string;
  time: string;
};

export type ReportSummaryDay = {
  /** date in format '2022-02-12' */
  date: string;
  status: ReportStatus;
  weekend: boolean;
  holiday: string;
  schedule: ReportDaySchedule;
  scheduleException: { name: string } | null;
  observation: string | null; // ?
  requests: null; // ?
  businessRulesGroup: ReportDayBusinessRulesGroup;
  missedDay: boolean;
  plannedMinutes: number;
  plannedBreakMinutes: number;
  workedMinutes: number;
  breakMinutes: number;
  nightMinutes: number;
  lateEntryMinutes: number;
  earlyLeaveMinutes: number;
  onCallMinutes: {
    onCall: number;
    activated: number;
    reducingActivated: number;
  };
  missedMinutesBeforeCompensation: number;
  missedMinutes: number;
  nightShiftMinutes: Record<string, number>;
  nightReducedMinutes: number;
  medicalAbsenceMinutes: number;
  nightShiftOvertimeMinutes: number;
  extraHoursMinutes: number;
  extraHoursPhases: Phases;
  hoursBankPhases: Phases;
  cumulativeHoursBankPhases: Phases;
  hoursBankPositiveMinutes: number;
  hoursBankNegativeMinutes: number;
  hoursBankMinutes: number;
  cumulativeHoursBankMinutes: number;
  hoursBankExpiredPhases: Phases;
  hoursBankExpiredMinutes: number;
  hoursBankOverLimitPhases: Phases;
  hoursBankOverLimitMinutes: number;
  debitMinutes: number;
  adjustments: {
    reason: string; // TODO: verify if there more fields
  }[];
  punches: Punch[];
  crossShiftsInterval: {
    diffMinutes: number;
  };
};

export type ReportSummaryContent = {
  dates: ReportSummaryDay[];
  digitalSignatureMetadata: {
    status: DigitalSignatureStatus;
    createdAt: string | null;
    createdBy: string | null;
    signedAt: string | null;
    signedBy: string | null;
  };
  dsr: number;
  dsrWorkingDays: number;
  employeeInfo: EmployeeInfo | null;
  missedDays: number;
  totalBreakMinutes: number;
  totalCrossShiftsIntervalDiffMinutes: number;
  totalEarlyLeaveMinutes: number;
  totalEarlyLeaves: number;
  earlyLeavesWithoutBreaks: number;
  totalExtraHoursMinutes: number;
  totalExtraHoursPhases: Phases;
  totalCrossShiftsIntervalDiffMinutesPhases: Phases;
  totalHoursBankMinutes: number;
  totalHoursBankNegativeMinutes: number;
  totalHoursBankPhases: Phases;
  totalHoursBankPositiveMinutes: number;
  totalLateEntryMinutes: number;
  totalLateArrivals: number;
  lateArrivalsWithoutBreaks: number;
  totalMedicalAbsenceMinutes: number;
  totalMissedMinutes: number;
  totalMissedMinutesBeforeCompensation: number;
  totalNightMinutes: number;
  totalNightReducedMinutes: number;
  totalNightShiftMinutes: Record<number, number>;
  totalNightShiftOvertimeMinutes: number;
  totalOnCallMinutes: {
    onCall: number;
    activated: number;
    reducingActivated: number;
  };
  totalPlannedMinutes: number;
  totalWorkedInHolidayMinutes: number;
  totalWorkedMinutes: number;
  workedDays: number;
  workedHolidays: number;
};

export type ReportSummaryMetadata = {
  lastUpdatedAt: string;
  status: ReportStatus;
  linesCount: number;
  activePhases: Phases[];
  activeNightShifts: {
    name: number;
  }[];
  shiftEventKeys: string[];
};
