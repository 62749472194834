import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { normalizeString } from "components/views/Select";
import { ChangeEventHandler, useMemo, useState } from "react";
import { searchClose } from "components/svg-images";
import cx from "classnames";
import { stylesheet } from "astroturf";
import { Expand } from "components/views/Expand";
import { SearchInput } from "components/views/SearchInput";
import { filterNested } from "components/views/Select/filter-nested";
import { useProfile } from "context/user-profile-context";
import { IconDropdown } from "../IconDropdown";
import { IconAddTask, IconSelectedTick } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--colors-default);

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    flex-shrink: 0;
    padding: 20px 16px;
    border-bottom: 1px solid var(--colors-separator);

    .Search {
      height: auto;
      padding: 0;
      background: none;
    }

    .IconClose {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .Options {
    overflow-y: auto;
    background: var(--colors-default);
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow: hidden;
  background: var(--colors-default);

  .NothingFound {
    .Top {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 23px 16px;

      .Title {
        font-size: var(--typography-font-size-default);
        line-height: 130%;
        color: var(--colors-surface-800);
      }

      .Subtitle {
        font-size: 12px;
        line-height: 120%;
        color: var(--colors-surface-700);
      }
    }
    .Separator {
      border-bottom: 1px solid var(--colors-surface-150);
      margin: 0 16px;
    }
    .AddTask {
      margin-top: 4px;
      background: var(--colors-default);
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-inline-start: 19px;
      gap: 11px;
      height: 40px;
      font-size: var(--typography-font-size-default);
      cursor: pointer;

      &:hover {
        background: var(--colors-bgHover);
      }

      .TaskName {
        font-weight: var(--typography-font-weight-medium);
        color: var(--colors-primary-500-p);
      }
    }
  }

  .Project {
    padding: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #DDE5EE;
    }

    .Title {
      cursor: default;
      background: var(--colors-default);
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 11.5px 16px;
      color: var(--colors-activitiInfoBlockValue);
      height: 56px;
      align-items: center;

      &:hover {
        background: var(--colors-bgHover);
      }

      &.expanded {
        background: var(--colors-bgHover);
      }

      .ProjTexts {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        display: flex;
        flex-direction: row;
        white-space: pre-wrap;

        .Name {
          color: var(--colors-surface-900-p);
        }

        .Client {
          color: var(--colors-surface-500);
        }
      }
    }

    .Tasks > * {
      margin: 4px 0;
    }

    .AddTask {
      margin-top: 4px;
      background: var(--colors-default);
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-inline-start: 19px;
      gap: 11px;
      height: 40px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-medium);
      cursor: pointer;

      &:hover {
        background: var(--colors-bgHover);
      }
    }

    .Separator {
      border-bottom: 1px solid var(--colors-surface-100);
      margin: 0 20px;
    }

    &.simple {
      border: none;

      .Title {
        background: var(--colors-surface-50);
        height: 40px;
      }
    }
  }

  .Task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px;
    height: 40px;
    cursor: pointer;

    .Texts {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      gap: 8px;

      .Name {
        font-size: var(--typography-font-size-default);
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-mainText);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &:hover {
      background: var(--colors-bgHover);
    }

    &.selected {
      .Texts {
        .Name {
          color: var(--colors-primary-600);
        }
      }
    }

    &.tmp:not(.selected) {
      .Texts {
        .Name {
          color: var(--colors-surface-900-p);
        }
      }
    }
  }
}
`;

interface Task {
  uuid: string;
  name: string;
}
function TaskOption(props: { task: Task; selected: boolean; onSelect: () => void }) {
  const { task, selected, onSelect } = props;

  return (
    <div
      className={cx(styles.Task, { [styles.selected]: selected, [styles.tmp]: task.uuid.startsWith("newtask-") })}
      onClick={onSelect}
    >
      <div className={styles.Texts}>
        <div className={styles.Name}>{task.name}</div>
      </div>
      {selected && <IconSelectedTick />}
    </div>
  );
}

interface Project {
  uuid: string;
  name: string;
  client?: { name: string };
  tasks: Task[];
}
function ProjectGroup(
  props: { project: Project; onAddTask?(tsk: { project: Project }): void } & React.HTMLProps<HTMLDivElement>,
) {
  const { project, onAddTask, children } = props;
  const [expanded, setExpanded] = useState(false);
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <div className={cx(styles.Project)}>
      <div className={cx(styles.Title, { [styles.expanded]: expanded })} onClick={() => setExpanded(!expanded)}>
        <div className={styles.ProjTexts}>
          <div className={styles.Name}>{project.name}</div>
          {project.client?.name != null && <div className={styles.Client}>{` / ${project.client.name}`}</div>}
        </div>
        <IconDropdown expanded={expanded} />
      </div>
      <Expand className={styles.Tasks} expanded={expanded}>
        {onAddTask && (
          <div className={styles.AddTask} onClick={() => onAddTask({ project })}>
            <IconAddTask />
            <div>{t("New task")}</div>
          </div>
        )}
        <div className={styles.Separator} />
        {children}
      </Expand>
    </div>
  );
}

function NothingFound(
  props: React.HTMLProps<HTMLDivElement> & { onAddTask?(tsk: { name: string }): void; taskName: string },
) {
  const { onAddTask, taskName } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  const randomTaskName = `task-${Math.random().toFixed(12).slice(2)}`;
  const createTaskText = t("Create {{taskName}} task", { taskName: randomTaskName });
  const [creTskTranslationPrefix, creTskTranslationSuffix] = createTaskText.split(randomTaskName);

  return (
    <div className={styles.NothingFound}>
      <div className={styles.Top}>
        <div className={styles.Title}>{t("Nothing found")}</div>
        <div className={styles.Subtitle}>
          {onAddTask ? t("Try a different keyword or create a new task") : t("Try a different keyword")}
        </div>
      </div>
      <div className={styles.Separator} />
      {onAddTask && (
        <div className={styles.AddTask} onClick={() => onAddTask({ name: taskName })}>
          <IconAddTask />
          <div>
            {creTskTranslationPrefix} <span className={styles.TaskName}>{taskName}</span> {creTskTranslationSuffix}
          </div>
        </div>
      )}
    </div>
  );
}

export interface ActivityTaskSelectorProps {
  projects: Project[];
  selectedTaskUuid?: string;
  onChange(project: Project, task: Task): void;
  onAddTask(tsk: { name?: string; project?: Project }): void;
  onClose(): void;
}
export function ActivityTaskSelector(props: ActivityTaskSelectorProps) {
  const { projects, selectedTaskUuid, onChange, onClose, onAddTask } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const options = useMemo(() => {
    const opts = projects.map((project) => {
      const projOpt = {
        project,
        taskOptions: project.tasks.map((task) => {
          const taskOpt = {
            task,
            searchTerms: [normalizeString(task.name)],
          };
          return taskOpt;
        }),
      };
      return projOpt;
    });
    return opts;
  }, [projects]);

  const [searchQuery, setSearchQuery] = useState("");

  const search: ChangeEventHandler<HTMLInputElement> = (event) => {
    const searchText = event.target.value;
    setSearchQuery(searchText);
  };

  const foundOptions = useMemo(() => {
    const str = normalizeString(searchQuery);
    if (str === "") return options;
    const predicate = (opt) => opt.searchTerms?.some((term: string) => term.includes(str));
    const foundOpts = filterNested(options, predicate, "taskOptions");
    return foundOpts;
  }, [options, searchQuery]);

  const profile = useProfile()!;
  const canAddTask = profile.permission_roles?.some((prole) => prole.name === "Owner");

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <SearchInput className={styles.Search} placeholder={t("Search tasks")} autoFocus onChange={search} />
        {searchQuery === "" && onClose && (
          <div className={styles.IconClose} onClick={onClose}>
            {searchClose}
          </div>
        )}
      </div>

      <div className={styles.Options}>
        {searchQuery === "" && foundOptions.length === 0 && <div className={styles.Empty}>{t("No items")}</div>}
        {searchQuery !== "" && foundOptions.length === 0 && (
          <NothingFound
            onAddTask={canAddTask ? onAddTask : undefined}
            taskName={`${searchQuery[0].toUpperCase()}${searchQuery.slice(1)}`}
          />
        )}
        {searchQuery === "" &&
          foundOptions.length !== 0 &&
          foundOptions.map((prjOpt) => (
            <ProjectGroup
              key={prjOpt.project.uuid}
              project={prjOpt.project}
              onAddTask={canAddTask ? onAddTask : undefined}
            >
              {prjOpt.taskOptions.map((tskOpt) => (
                <TaskOption
                  key={tskOpt.task.uuid}
                  task={tskOpt.task}
                  selected={selectedTaskUuid !== undefined && tskOpt.task.uuid === selectedTaskUuid}
                  onSelect={() => onChange(prjOpt.project, tskOpt.task)}
                />
              ))}
            </ProjectGroup>
          ))}
        {searchQuery !== "" &&
          foundOptions.length !== 0 &&
          foundOptions.map((prjOpt) => (
            <div key={prjOpt.project.uuid} className={cx(styles.Project, styles.simple)}>
              <div className={cx(styles.Title)}>
                <div className={styles.ProjTexts}>
                  <div className={styles.Name}>{prjOpt.project.name}</div>
                  {prjOpt.project.client?.name != null && (
                    <div className={styles.Client}>{` / ${prjOpt.project.client.name}`}</div>
                  )}
                </div>
              </div>
              {prjOpt.taskOptions.map((tskOpt) => (
                <TaskOption
                  key={tskOpt.task.uuid}
                  task={tskOpt.task}
                  selected={selectedTaskUuid !== undefined && tskOpt.task.uuid === selectedTaskUuid}
                  onSelect={() => onChange(prjOpt.project, tskOpt.task)}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}
