import { Component } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import { TableButton } from "./Page";

export const Wrapper = styled.div`
  .more {
    width: 36px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cg fill='%23525F7F' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='10' cy='2' r='2'/%3E%3Ccircle cx='18' cy='2' r='2'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 4px;
  }
`;

export const Dropdown = styled.div<{ dropToTop?: boolean }>`
  display: block;
  position: absolute;
  inset-inline-end: 10px;
  top: ${(p) => (p.dropToTop ? "auto" : "36px")};
  bottom: ${(p) => (p.dropToTop ? "0" : "auto")};
  padding: 13px 10px;
  background: var(--colors-default);
  box-shadow: 0 5px 30px 0 rgba(129, 147, 171, 0.2);
  border-radius: var(--shapes-border-radius-default);
`;

export const DropdownItem = styled.div`
  min-width: 147px;
  padding: 0 10px;
  line-height: 36px;
  font-size: var(--typography-font-size-default);
  text-align: initial;
  cursor: pointer;
  color: #8193ab;

  &:hover {
    font-weight: var(--typography-font-weight-medium);
    color: #525f7f;
    background: #f3f5fa;
    border-radius: var(--shapes-border-radius-default);
  }
`;

export type TableButtonItems = {
  label: string;
  onClick: () => void;
};

interface TableButtonWithDropdownProps {
  items: TableButtonItems[];
  dropToTop?: boolean;
}

interface TableButtonWithDropdownState {
  dropdownVisible: boolean;
}

class TableButtonWithDropdown extends Component<TableButtonWithDropdownProps, TableButtonWithDropdownState> {
  readonly state: Readonly<TableButtonWithDropdownState> = { dropdownVisible: false };

  render() {
    const { dropdownVisible } = this.state;
    const { items, dropToTop } = this.props;

    return (
      <Wrapper>
        <ClickOutside onClickOutside={() => this.setState({ dropdownVisible: false })}>
          <TableButton
            className="more"
            onClick={(ev) => {
              ev.stopPropagation();
              this.setState({ dropdownVisible: !dropdownVisible });
            }}
          />

          {dropdownVisible && (
            <Dropdown dropToTop={dropToTop}>
              {items.map((item) => (
                <DropdownItem
                  key={item.label}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    item.onClick();
                  }}
                >
                  {item.label}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default TableButtonWithDropdown;
