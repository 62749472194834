import styled, { css } from "styled-components";
import GroupedSelect from "components/UI/Select/GroupedSelect";
import SafeTimeInput from "../SafeTimeInput";
import SafeNumericInput from "../SafeNumericInput";
import SafeInput, { SafeInputProps } from "../SafeInput";

export const baseStyle = css`
  border: none;
  outline: none;
  padding: 0;
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  background-color: #0000;
  caret-color: var(--colors-primary3);

  &::placeholder {
    color: var(--colors-secondary);
  }

  &:disabled {
    cursor: text;
  }
`;

export type { SafeInputProps };

export const InlineInput = styled(SafeInput)`
  ${baseStyle}
`;

export const InlineTimeInput = styled(SafeTimeInput)`
  ${baseStyle}
`;

export const InlineNumericInput = styled(SafeNumericInput)`
  ${baseStyle}
`;

export interface GroupedOption {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
}

export const InlineGroupedSelect = styled(GroupedSelect)`
  .ui-select__input {
    border: none;
    padding: 0;

    &::after {
      right: 1px;
    }
  }

  .ui-select__option-list {
    margin-top: 27px;
  }
  .ui-select__input_disabled {
    color: inherit;
    background: none;
  }

  input {
    outline: none;
  }
`;
