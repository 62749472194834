import { Injectable } from "services/di";
import { OitchauAuthedApi, Location } from "services/oitchau-api";
import { AppStorage } from "services/app-storage";

@Injectable()
export class LocationsService {
  constructor(protected oitchauApi: OitchauAuthedApi, protected appStorage: AppStorage) {}

  async listOwnLocations() {
    const profile = await this.appStorage.getProfile();
    const locationsRes = await this.oitchauApi.getLocations();
    const profileLocations = profile.locations;
    const allLocations = locationsRes.locations || [];
    const activeLocations = allLocations.filter((loc) => loc.active);
    const isAllowedAnyLocation =
      !profileLocations || profileLocations.length === 0 || profileLocations.filter((loc) => loc.active).length === 0;
    const availableLocations = isAllowedAnyLocation
      ? activeLocations
      : profileLocations
          .map((ploc) => activeLocations.find((actLoc) => actLoc.uuid === ploc.uuid))
          .filter((ploc) => ploc != null);
    return availableLocations as Location[];
  }
}
