import ConfirmationModal from "components/UI/ConfirmationModalNew";
import { Notification } from "types/common";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useState } from "react";
import styled from "styled-components";
import PunchSettings from "./PunchSettings";

const Wrapper = styled.div`
  padding: 20px 0;
`;

interface PunchSettingsModalProps {
  onClose: () => void;
  setNotification: (n: Notification) => void;
}

const PunchSettingsModal = ({ onClose, setNotification }: PunchSettingsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const [yesFn, setYesFn] = useState<{ fn: (() => Promise<void>) | null; disabled: boolean }>({
    fn: null,
    disabled: false,
  });

  const onYes = async () => {
    if (yesFn.fn) {
      await yesFn.fn();
    }

    onClose();
  };

  return (
    <ConfirmationModal
      title={t("Device settings")}
      isOpen
      width={518}
      onClose={onClose}
      onYes={yesFn.fn ? onYes : undefined}
      yesDisabled={yesFn.disabled}
      btnYesTitle={t("Save")}
      btnCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
    >
      <Wrapper>
        <PunchSettings setNotification={setNotification} setOnSaveFn={setYesFn} />
      </Wrapper>
    </ConfirmationModal>
  );
};

export default PunchSettingsModal;
