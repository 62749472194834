import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { TFunction } from "i18next";
import styled from "styled-components";
import classNames from "classnames";

const Wrapper = styled.div`
  --text-color: var(--cp-schedule-day-button-inactive-color, var(--colors-surface-400));
  --background-color: var(--colors-surface-0);
  --border-color: var(--cp-schedule-day-button-inactive-color, var(--colors-secondary2));
  --font-family: var(--cp-schedule-day-button-font-family, var(--typography-font-family-default));
  --font-weight: var(--cp-schedule-day-button-font-weight, var(--typography-font-weight-medium));
  &.selected {
    --text-color: var(--colors-surface-0);
    --background-color: var(--cp-schedule-day-button-active-bg-color, #81d6d4);
    --border-color: var(--cp-schedule-day-button-active-bg-color, #81d6d4);
    font-weight: var(--font-weight);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 100px;
  font-weight: var(--font-weight);
  font-size: 12px;
  color: var(--text-color);
  text-transform: uppercase;
  flex-direction: column;

  &.interactive {
    cursor: pointer;
  }
`;

const getDayTitle = (t: TFunction, dayNumber: number) => {
  const days = ["", t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
  return days[dayNumber];
};

type ScheduleDayButtonProps = {
  onChange?: () => void;
  isNumberDay: boolean;
  value: number | string;
  interactive: boolean;
  selected: boolean;
};

const ScheduleDayButton = ({ isNumberDay, onChange, interactive, selected, value }: ScheduleDayButtonProps) => {
  const { t } = useTranslation(TranslationNamespaces.schedules);

  return (
    <Wrapper onClick={() => onChange && onChange()} className={classNames({ interactive, selected })}>
      {isNumberDay ? (
        <>
          <span>{value}</span>
          <span>{t("Day")}</span>
        </>
      ) : (
        getDayTitle(t, value as number)
      )}
    </Wrapper>
  );
};

export default ScheduleDayButton;
