import { stylesheet } from "astroturf";
import { IconBack } from "../styled";
import { Modal } from "../Modal";
import { Header } from "../Header";

const styles = stylesheet`
  .Modal {
    background: none;
  }

  .Wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    > *:first-child {
      flex-shrink: 0;
    }

    .IconBack {
      display: flex;
      align-items: center;
      width: 50px;
      height: 100%;
      padding-inline-start: 17px;
      cursor: pointer;

      &:hover {
        background: var(--colors-bgHover);
      }
    }

    .Content {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background: var(--colors-default);
    }
  }

  [dir="rtl"] {
    .Wrapper .IconBack {
      transform: rotate(180deg);
    }
  }
`;

export interface SubscreenProps extends React.HTMLProps<HTMLDivElement> {
  onBackClick(): void;
}

export function Subscreen(props: SubscreenProps) {
  const { onBackClick, ...restProps } = props;
  return (
    <Modal className={styles.Modal}>
      <div className={styles.Wrapper} {...restProps}>
        <Header>
          <div className={styles.IconBack} onClick={onBackClick}>
            <IconBack />
          </div>
        </Header>
        <div className={styles.Content}>{props.children}</div>
      </div>
    </Modal>
  );
}
