import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Attachments, { AttachmentsProps } from "components/Requests/Attachments";
import { CustomField } from "types/models/projects";
import {
  InlineTimeInput,
  InlineInput,
  InlineGroupedSelect,
  InlineNumericInput,
  SafeInputProps,
  GroupedOption,
} from "components/views/Inline";
import FormField from "components/views/FormField";
import { ErrorLabel } from "components/UI/TextLabels";
import { TranslationNamespaces } from "types/translationNamespaces";
import { InlineDatepickerProps } from "components/controls/DatePicker";
import InlineDatepicker from "components/controls/DatePicker/InlineDatepicker";
import ActivityLocation, { ActivityLocationProps, LocationV10nMethod, LocationV10nStatus } from "../ActivityLocation";

export { LocationV10nMethod, LocationV10nStatus };

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  padding: 17px;
  gap: 11px;
`;

const TimeRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const ProjectName = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--colors-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export type ActivityEditProps = React.HTMLProps<HTMLDivElement> & {
  onDateChange: InlineDatepickerProps["onChange"];
  isDateOutsideRange: InlineDatepickerProps["isOutsideRange"];
  onStartTimeChange: SafeInputProps["onChangeFinished"];
  onEndTimeChange: SafeInputProps["onChangeFinished"];
  uploadAttachment: AttachmentsProps["upload"];
  removeAttachment: AttachmentsProps["remove"];
  onAttachmentsChange: AttachmentsProps["onChange"];
  onCustomFieldsChange: (cfs: CustomField[]) => void;
  onTaskChange: (task: any) => void;

  disabled: boolean;
  date: string;
  duration: string;
  startTime: string;
  endTime: string;
  client: string;
  customFields: CustomField[];
  attachments: AttachmentsProps["attachments"];
  task: { uuid: string; name: string };
  availableTasks: GroupedOption[];
  projectName: string;
  location: ActivityLocationProps["location"];
  locationVerification: ActivityLocationProps["locationVerification"];
  coordinates: ActivityLocationProps["coordinates"];
  dateError: string | null;
};

export default function ActivityEdit(props: ActivityEditProps) {
  const {
    onDateChange,
    isDateOutsideRange,
    onStartTimeChange,
    onEndTimeChange,
    uploadAttachment,
    removeAttachment,
    onAttachmentsChange,
    onCustomFieldsChange,
    onTaskChange,

    date,
    duration,
    startTime,
    endTime,
    client,
    location,
    customFields,
    attachments,
    disabled,
    task,
    availableTasks,
    projectName,
    locationVerification,
    coordinates,
    dateError,
    ...restProps
  } = props;
  const [t] = useTranslation(TranslationNamespaces.punchesPage);

  function handleCustomFieldChange(cfUuid: string, newVal: string) {
    const newCfs = customFields.map((cf) => (cf.uuid !== cfUuid ? cf : { ...cf, value: newVal }));
    onCustomFieldsChange && onCustomFieldsChange(newCfs);
  }

  return (
    <Fields {...restProps}>
      <TimeRow>
        <FormField label={t("Date")} style={{ width: "120px" }} inactive={disabled}>
          <InlineDatepicker
            value={date}
            onChange={onDateChange}
            isOutsideRange={isDateOutsideRange}
            disabled={disabled}
          />
        </FormField>
        <FormField label={t("From")} style={{ width: "70px" }} inactive={disabled}>
          <InlineTimeInput value={startTime} onChangeFinished={onStartTimeChange} disabled={disabled} />
        </FormField>
        <FormField label={t("To")} style={{ width: "70px" }} inactive={disabled}>
          <InlineTimeInput value={endTime} onChangeFinished={onEndTimeChange} disabled={disabled} />
        </FormField>
        <FormField label={t("Hours")} style={{ width: "70px" }} inactive>
          <div>{duration}</div>
        </FormField>
      </TimeRow>
      {dateError && <ErrorLabel>{dateError}</ErrorLabel>}
      <FormField label={t("Task")} inactive={disabled}>
        <InlineGroupedSelect
          disabled={disabled}
          returnOption
          modifiers={{ field: true }}
          value={task?.uuid}
          onChange={onTaskChange}
          placeholder={task?.name}
          groupedOptions={availableTasks}
        />
        <ProjectName>{projectName}</ProjectName>
      </FormField>
      <FormField label={t("Client")} inactive>
        <div>{client}</div>
      </FormField>
      {customFields.map((cf) => {
        const Inp = cf.fieldType === "numeric" ? InlineNumericInput : InlineInput;
        return (
          <FormField key={cf.uuid} label={cf.name} inactive={disabled}>
            <Inp
              disabled={disabled}
              value={cf.value}
              onChangeFinished={(val) => handleCustomFieldChange(cf.uuid!, val)}
            />
          </FormField>
        );
      })}
      <FormField label={t("Location")} inactive>
        <ActivityLocation location={location} locationVerification={locationVerification} coordinates={coordinates} />
      </FormField>
      <FormField label={t("Attachments")} inactive>
        <Attachments
          edit={!disabled}
          upload={uploadAttachment}
          remove={removeAttachment}
          attachments={attachments}
          onChange={onAttachmentsChange}
        />
      </FormField>
    </Fields>
  );
}
