import Sentry from "utils/sentryUtils";
import CONFIG from "config";
import { sendRequest } from "./common";

type TODOResponseData = Promise<unknown>;

export function getCurrentTime(): TODOResponseData {
  return new Promise((res) => {
    sendRequest({}, "get", "/utc_time", CONFIG.client_admin_api)
      .then((r) => {
        res(r);
      })
      .catch((e) => Sentry.sendError(e));
  });
}

export function getCurrentTranslationVersion(): TODOResponseData {
  return sendRequest({}, "get", "/management/get_translation_version");
}

export function incrementTranslationVersion(): TODOResponseData {
  return sendRequest({}, "POST", "/management/increment_translation_version");
}

export function detectCountry(): TODOResponseData {
  return sendRequest({}, "get", "/management/country_by_ip");
}
