import moment from "moment";
import { minsToHrsMins } from "utils/common";
import { WithTranslation } from "react-i18next";
import { iColumn, iRow } from "components/TableCommon";
import { TranslationNamespaces } from "types/translationNamespaces";
import DayOfWeek from "components/UI/Table/DayOfWeek";
import ReporsDsrLabel from "components/Reports/ReporsDsrLabel";
import styled from "styled-components";
import { translateEmployeeTerm } from "./translationHelpers";

const DayOfWeekWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export interface iDateCellData {
  isDayOff: boolean;
  dayOfWeek: string;
  dayMonth: string;
  isLostDsrDay: boolean;
  isPossibleDsrDay: boolean;
  showDsrInReports: boolean;
  isPlannedDsrDay: boolean;
}

export function getDateColumn<T>(t: WithTranslation["t"]): iColumn<T> {
  return {
    Header: t("Date"),
    locked: true,
    accessor: "date",
    rubyAccessor: "date",
    Cell: (row: iRow<T, iDateCellData>) => (
      <DayOfWeekWrapper>
        <DayOfWeek dayName={row.value.dayOfWeek} dayMonth={row.value.dayMonth} isDayOff={row.value.isDayOff} />
        <ReporsDsrLabel
          isLostDsrDay={row.value.isLostDsrDay}
          isPossibleDsrDay={row.value.isPossibleDsrDay}
          showDsrInReports={row.value.showDsrInReports}
          isPlannedDsrDay={row.value.isPlannedDsrDay}
        />
      </DayOfWeekWrapper>
    ),
    width: 120,
    style: { lineHeight: "22px" },
  };
}

export function getEmployeeNameColumn<T>(t: WithTranslation["t"]): iColumn<T> {
  return {
    accessor: "fullName",
    rubyAccessor: "name",
    label: translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-employee-name",
      `${TranslationNamespaces.reportsPage}|Employee name`,
    ),
    minWidth: 200,
    locked: true,
    style: { lineHeight: "22px" },
    Cell: (row: iRow<T, string>) => (
      <span>
        <span title={row.value}>{row.value}</span>
        {row.original.trHeading ? (
          <div className="tr-heading">
            {t(`${TranslationNamespaces.reportsPage}|Period`)}: {row.original.trHeading}
          </div>
        ) : null}
      </span>
    ),
  };
}

// TODO
export function getDateCellData(row): iDateCellData {
  return {
    dayOfWeek: moment(row.date, "YYYY-MM-DD").format("ddd"),
    dayMonth: moment(row.date, "YYYY-MM-DD").format("DD/MM"),
    isDayOff: row.weekend || row.holiday,
    isPlannedDsrDay: row.isPlannedDsrDay,
    isLostDsrDay: row.isLostDsrDay,
    isPossibleDsrDay: row.isPossibleDsrDay,
    showDsrInReports: row.showDsrInReports,
  };
}

const minutesColumns = [
  "duration",
  "totalWorkedMinutes",
  "totalMissedMinutes",
  "cumulativeHoursBankMinutes",
  "totalExtraHoursMinutes",
  "totalCrossShiftsIntervalDiffMinutes",
  "totalLateEntryMinutes",
  "totalEarlyLeaveMinutes",
  "totalBreakMinutes",
  "totalPlannedMinutes",
  "totalMissedMinutes",
  "totalDebitMinutes",
  "totalUnusedBreakMinutes",
];

export function getMinsToHours(val: number): string {
  return val ? minsToHrsMins(val) : "00:00";
}

export function getMinsToHoursDecimal(val: number): string {
  const hoursWithMinutes = getMinsToHours(val).split(":");
  const hoursDecimal = (parseInt(hoursWithMinutes[0], 10) + parseInt(hoursWithMinutes[1], 10) / 60).toFixed(2);
  return `${hoursDecimal}`;
}

// TODO data type
function mapTableDataRow(rows: string[], data: Record<string, any>): Record<string, unknown> {
  const exportObj: Record<string, unknown> = {};

  rows.forEach((row) => {
    if (row === "date") {
      exportObj.date = data.date ? getDateCellData(data) : "";
    } else if (minutesColumns.includes(row)) {
      exportObj[row] = getMinsToHours(data[row]);
    } else if (row === "totalOnCallMinutes") {
      exportObj[row] = getMinsToHours(data[row]?.onCall);
    } else if (row === "totalOnCallActivatedMinutes") {
      exportObj[row] = getMinsToHours(data.totalOnCallMinutes?.activated);
    } else if (row === "totalOnCallReducingActivatedMinutes") {
      exportObj[row] = getMinsToHours(data.totalOnCallMinutes?.reducingActivated);
    } else if (row === "schedules" || row === "businessRulesGroups") {
      exportObj[row] = data[row]?.length && !data[row][0].endDate ? data[row][0].name : "";
    } else if (row === "digitalSignatureMetadata") {
      exportObj.ds_status = data[row].status;
      exportObj.ds_requestedAt = data[row].createdAt ? moment(data[row].createdAt).format("DD/MM/YYYY") : "";
      exportObj.ds_signedAt = data[row].signedAt ? moment(data[row].signedAt).format("DD/MM/YYYY") : "";
    } else if (row === "fullName") {
      exportObj.fullName = data.fullName || data.userProfile.fullName;
    } else if (row === "locationName") {
      exportObj.locationName = data?.location?.name || data.locationName;
    } else if (row === "serviceName") {
      exportObj.serviceName = data?.service?.name || data.serviceName;
    } else if (row === "taskName") {
      exportObj.taskName = data?.task?.name || data.taskName;
    } else if (row === "projectName") {
      exportObj.projectName = data?.project?.name || data.projectName;
    } else if (row === "clientName") {
      exportObj.clientName = data?.client?.name || data.clientName;
    } else if (row === "cpf") {
      exportObj.cpf = data?.userProfile?.cpf || data.cpf;
    } else if (row === "matricula") {
      exportObj.matricula = data?.userProfile?.matricula || data.matricula;
    } else {
      exportObj[row] = data[row] || "";
    }
  });

  return exportObj;
}

// TODO data type
export function mapTableData(rows: string[], data: Record<string, any>[]): Record<string, unknown>[] {
  let period = "";

  return data.map((d) => {
    let trHeading: string | boolean = false;
    const startDate = d.startDate ? moment(d.startDate, "YYYY-MM-DD").format("DD MMM") : "";
    const endDate = d.endDate ? moment(d.endDate, "YYYY-MM-DD").format("DD MMM") : "";
    const periodStr = `${startDate} - ${endDate}`;

    if (d.startDate && !period) {
      period = periodStr;
      trHeading = periodStr;
    } else if (d.startDate && period !== periodStr) {
      trHeading = periodStr;
      period = periodStr;
    } else {
      trHeading = false;
    }

    return {
      ...mapTableDataRow(rows, d),
      trHeading,
    };
  });
}
