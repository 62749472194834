import { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import RichTooltip from "components/UI/RichTooltip";
import ScheduleLimitRow from "./ScheduleLimitRow";

const Title = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.328125px;
  color: var(--colors-mainText);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;
const Subtitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #a1b2cf;
`;
const Row = styled.div`
  padding-bottom: 13px;
`;
const Block = styled.div`
  margin-inline-end: 60px;
`;
const Blocks = styled.div`
  display: flex;
  margin-bottom: 3px;
`;
const Section = styled.div`
  margin-top: 32px;
`;

class ScheduleLimits extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  changeRule(key, value) {
    const { rules, onChange, readonly } = this.props;
    const keys = key.split(".");
    if (keys.length === 2) {
      rules[keys[0]][keys[1]] = value;
    } else {
      rules[keys[0]] = value;
    }
    if (!readonly) {
      onChange(rules);
    }
  }

  render() {
    const { rules, t, disableToleranceEntryLate } = this.props;
    if (!rules) {
      return null;
    }
    return (
      <div>
        <Section>
          <Title>
            {t("Notification & Alerts")}
            &nbsp;&nbsp;
            <RichTooltip text={t("notification-alerts-info")} />
          </Title>
          <Blocks>
            <Block>
              <Subtitle>{t("late arrival")}</Subtitle>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  disabled={disableToleranceEntryLate}
                  value={rules.entry.tolerance}
                  label={t("After Entry")}
                  onChange={(value) => this.changeRule("entry.tolerance", value)}
                />
              </Row>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  value={rules.break_end.tolerance}
                  label={t("After End of the Break")}
                  onChange={(value) => this.changeRule("break_end.tolerance", value)}
                />
              </Row>
            </Block>
            <Block>
              <Subtitle>{t("early leave")}</Subtitle>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  value={rules.exit.tolerance}
                  label={t("Before Exit")}
                  onChange={(value) => this.changeRule("exit.tolerance", value)}
                />
              </Row>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  value={rules.break_start.tolerance}
                  label={t("Before Break Start")}
                  onChange={(value) => this.changeRule("break_start.tolerance", value)}
                />
              </Row>
            </Block>
          </Blocks>
        </Section>
        <Section>
          <Title>
            {t("Schedule Limits")} &nbsp;&nbsp;
            <RichTooltip text={t("schedule-limits-info")} />
          </Title>
          <Blocks>
            <Block>
              <Subtitle>{t("Entry limit")}</Subtitle>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  addNone
                  value={rules.entry.validity}
                  label={t("Before the scheduled time of Entry")}
                  onChange={(value) => this.changeRule("entry.validity", value)}
                />
              </Row>
            </Block>
            <Block>
              <Subtitle>{t("Exit limit")}</Subtitle>
              <Row>
                <ScheduleLimitRow
                  t={t}
                  addNone
                  value={rules.exit.validity}
                  label={t("After Exit scheduled time")}
                  onChange={(value) => this.changeRule("exit.validity", value)}
                />
              </Row>
            </Block>
          </Blocks>
        </Section>
      </div>
    );
  }
}
export default withTranslation("schedules")(ScheduleLimits);
