import styled from "styled-components";
import { RequestApprovalFlow, RequestApprovalFlowStatus } from "types/models/request";
import * as images from "components/svg-images";

const ApprovalFlow = styled.div`
  display: flex;
  justify-content: start;
  gap: 5px;
`;

const ApprovalFlowIcon = styled.div<{ status: RequestApprovalFlowStatus | "skipped" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 2px;
  border: 1.5px solid;
  border-radius: 50%;
  border-color: ${(p) => {
    switch (p.status) {
      case RequestApprovalFlowStatus.approved:
      case RequestApprovalFlowStatus.declined:
      case "skipped":
        return "transparent";
      case RequestApprovalFlowStatus.pending:
      default:
        return "var(--colors-surface-200)";
    }
  }};
  background-color: ${(p) => {
    switch (p.status) {
      case RequestApprovalFlowStatus.approved:
        return "rgba(0, 161, 92, 0.2)";
      case RequestApprovalFlowStatus.declined:
        return "rgba(254, 102, 100, 0.2)";
      case "skipped":
        return "var(--colors-surface-150)";
      case RequestApprovalFlowStatus.pending:
      default:
        return "var(--colors-surface-0)";
    }
  }};

  svg {
    width: 11px;
    height: 5px;
    stroke: ${(p) => {
      switch (p.status) {
        case RequestApprovalFlowStatus.approved:
          return "var(--colors-success-700)";
        case RequestApprovalFlowStatus.declined:
          return "var(--colors-danger-600-p)";
        case "skipped":
          return "var(--colors-surface-150)";
        case RequestApprovalFlowStatus.pending:
        default:
          return "var(--colors-surface-200)";
      }
    }};
    path {
      fill: ${(p) => {
        switch (p.status) {
          case RequestApprovalFlowStatus.approved:
            return "var(--colors-success-700)";
          case RequestApprovalFlowStatus.declined:
            return "var(--colors-danger-600-p)";
          case "skipped":
            return "var(--colors-surface-150)";
          case RequestApprovalFlowStatus.pending:
          default:
            return "var(--colors-surface-200)";
        }
      }};
    }
  }
`;

interface ApprovalFlowCellProps {
  approvalFlow: RequestApprovalFlow[];
  status: RequestApprovalFlowStatus;
}

const ApprovalFlowCell = ({ approvalFlow, status }: ApprovalFlowCellProps) => {
  const stageWithUpdatedStatus =
    approvalFlow.find((af) => af.status !== RequestApprovalFlowStatus.pending)?.stage || -1;

  return (
    <ApprovalFlow>
      {approvalFlow.map((af) => {
        const stageStatus =
          (status !== RequestApprovalFlowStatus.pending && af.status === RequestApprovalFlowStatus.pending) ||
          af.stage < stageWithUpdatedStatus
            ? "skipped"
            : af.status;

        return (
          <ApprovalFlowIcon status={stageStatus} key={af.stage}>
            {af.status === RequestApprovalFlowStatus.declined ? images.searchClose : images.check}
          </ApprovalFlowIcon>
        );
      })}
    </ApprovalFlow>
  );
};

export default ApprovalFlowCell;
