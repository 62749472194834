import { Component } from "react";
import BEM from "utils/BEM";
import "components/UI/Page/Page.scss";
import "./styles/clients-page.scss";
import { withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import { getClients, addClient, updateClient, deleteClient } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import NoContent from "components/NoContent";
import * as images from "components/svg-images";
import { getTitle, strIncludesCheck } from "utils/common";
import { PageWrapper, Header, Title, HeaderAction, TableButtons, TableButton } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import { withRouter } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import { TableHelper } from "utils/tableHelpers";
import PopupMessage from "./PopupMessage";

const b = BEM.b("clients-page");
const tableHelper = new TableHelper("ClientsPage");

class ClientsPage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      notification: "",
      searchValue: "",
      selectedItem: null,
      popupVisible: false,
      confirmationPopupVisible: false,
      isFetching: false,
      selectedColumns: tableHelper.getSelectedColumns(["name", "contact_person", "managers", "locations_count"]),
    };
    document.title = getTitle(t("Clients"));
  }
  componentDidMount() {
    this.getState();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState();
  }
  getState(props) {
    if (!this.state.isFetching) {
      getClients().then((r) => {
        this.setState({
          loaded: true,
          items: r.clients || r || [],
          isFetching: false,
          selectedItem: null,
          popupVisible: false,
          confirmationPopupVisible: false,
        });
      });
      this.setState({ isFetching: true, loaded: false });
    }
  }
  onRemoveItemClick = (item) => {
    this.setState({ confirmationPopupVisible: true, selectedItem: item });
  };
  removeItem(item) {
    const { t } = this.props;
    deleteClient({ id: item.id })
      .then(() => {
        this.setState({ notification: `${t("You deleted")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  onEditItemClick = (item) => {
    const { history } = this.props;
    history.push(`/company/clients/${item.id}`);
  };
  saveItem(item) {
    const { t } = this.props;
    updateClient({
      id: item.id,
      body: {
        client: { ...item, manager_ids: item.managers ? item.managers.map((m) => m.uuid) : [] },
      },
    })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  addItem = (item) => {
    const { t } = this.props;
    this.setState({ popupVisible: false });
    addClient({ body: { client: { ...item, manager_ids: [] } } })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  };
  addItemClick = () => {
    this.setState({ popupVisible: true, selectedItem: null });
  };
  onSearch(ev) {
    this.setState({ searchValue: ev.target.value });
  }

  onColumnsChange = (selectedColumns) => {
    tableHelper.setSelectedColumns(selectedColumns);
    this.setState({ selectedColumns });
  };

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Client name"),
        accessor: "name",
        locked: true,
        width: 230,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Owner"),
        accessor: "contact_person",
        width: 230,
      },
      // {
      //   label: t('Number of employees'),
      //   accessor: 'employees_count',
      //   minWidth: 50,
      // },
      {
        label: t("Client Manager"),
        accessor: "managers",
        Cell: ({ value }) => (value && value.length ? value[0].name : null),
        width: 230,
      },
      {
        label: t("Locations"),
        accessor: "locations_count",
        Cell: (row) => (
          <div>
            <div>{row.value}</div>
            <TableButtons className="buttons">
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onEditItemClick(row.original);
                }}
              >
                {t(`${TranslationNamespaces.common}|Edit`)}
              </TableButton>
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onRemoveItemClick(row.original);
                }}
              >
                {images.trashIcon}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 50,
      },
    ].filter((e) => e);
    return columns;
  }
  render() {
    const { items, searchValue, selectedItem, popupVisible, confirmationPopupVisible, selectedColumns } = this.state;
    const { t } = this.props;

    const filteredItems = items.filter((s) => {
      const { name = "", owner = "" } = s;
      const filter = strIncludesCheck(name, searchValue) || strIncludesCheck(owner, searchValue);
      return filter;
    });

    return (
      <SettingsLayout
        title={t("Clients")}
        headerAction={
          <HeaderActionButtonAdd state={ButtonState.primary} title={t("Add a Client")} onClick={this.addItemClick} />
        }
      >
        <PageWrapper>
          {this.state.notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={this.state.notification} />
          )}

          <div className={b()}>
            <TablePage
              filters={
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch.bind(this)}
                  placeholder={t("Search")}
                  value={searchValue}
                />
              }
              columnSelectorOnFiltersRow
              rows={filteredItems}
              columns={this.getTableColumns()}
              onColumnsChange={this.onColumnsChange}
              selectedColumns={selectedColumns}
              className="clients-table"
              loading={this.state.isFetching}
              noContentComponent={<NoContent>{t("You don’t have Clients")}</NoContent>}
            />
          </div>
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              title={selectedItem ? t("Edit a Client") : t("New Client")}
              item={selectedItem || {}}
              allItems={items}
              buttonYesTitle={selectedItem ? t("Save") : t("Create a new client ")}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={selectedItem ? this.saveItem.bind(this) : this.addItem.bind(this)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Remove Client.")}
              text={t("Are you sure to remove the Client for your company?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ selectedItem: null, confirmationPopupVisible: false });
              }}
              onYes={this.removeItem.bind(this, selectedItem)}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.clients)(ClientsPage));
