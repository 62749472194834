import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import RecommendPage from "components/Recommend";
import FullPage from "components/Layout/FullPage";

const Recommend = ({ match: { path } }) => (
  <Suspense fallback={<div />}>
    <FullPage>
      <Switch>
        <Redirect strict exact from={path} to={`${path}/`} />
        <Route exact strict path={`${path}/`} component={RecommendPage} />
      </Switch>
    </FullPage>
  </Suspense>
);

export default Recommend;
