import { Component, MouseEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import PaginationPerPageSelector from "components/UI/PaginationPerPageSelector";
import * as images from "./svg-images";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 18px;
`;

const PerPageWrapper = styled.nav`
  position: absolute;
  inset-inline-end: 0;
`;

const PaginationNumberList = styled.ul`
  padding-inline-start: 0;
`;

const BaseItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;
`;

const Item = styled(BaseItem)<{ active: boolean }>`
  margin: 0 4px;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.06px;
  color: ${(p) => (p.active ? "var(--colors-primary-500-p)" : "var(--colors-inputMaskText)")};
  border: 1px solid;
  border-color: ${(p) => (p.active ? "var(--colors-primary-500-p)" : "var(--colors-secondary2)")};
  background: var(--colors-surface-0);
`;

const BaseItemWithHover = styled(BaseItem)`
  svg path {
    fill: var(--colors-buttonBorderColor2);
  }

  &:hover {
    background-color: var(--colors-surface-50);

    svg path {
      fill: var(--colors-surface-800);
    }
  }
`;

const PrevItem = styled(BaseItemWithHover)`
  margin-inline-end: 15px;
`;

const NextItem = styled(BaseItemWithHover)`
  margin-inline-start: 15px;
`;

// https://github.com/gladchinda/build-react-pagination-demo/tree/master/src

/**
 * Helper generator for creating a range of numbers
 */
function* rangeGen(from: number, to: number, step = 1) {
  let i = from;

  do {
    yield i;

    i += step;
  } while (i <= to);
}

type PaginationData = {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords: number;
};

interface PaginationProps extends WithTranslation {
  currentPage?: number;
  totalRecords: number | null;
  pageLimit: number;
  pageNeighbours: number;
  onPageChanged?: (data: PaginationData) => void;
  onPageLimitChange?: (pageLimit: number, currentPage: number) => void;
  showPerPage: boolean;
  pageLimits: number[];
}

interface PaginationState {
  currentPage: number;
}

class Pagination extends Component<PaginationProps, PaginationState> {
  pageLimit: number;
  totalRecords: number;
  // pageNeighbours can be: 0, 1 or 2
  pageNeighbours: number;
  totalPages: number;

  static defaultProps = {
    totalRecords: null,
    pageLimit: 25,
    pageNeighbours: 0,
    showPerPage: true,
    pageLimits: [25, 50, 100],
  };

  constructor(props: PaginationProps) {
    super(props);
    const { totalRecords, pageLimit, pageNeighbours, currentPage } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;
    this.pageNeighbours = typeof pageNeighbours === "number" ? pageNeighbours : 0;
    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: currentPage || 1 };
  }

  gotoPage = (page: number) => {
    const { onPageChanged } = this.props;
    const currentPage = Math.max(1, Math.min(page, this.totalPages));
    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords,
    };

    this.setState({ currentPage }, () => {
      if (onPageChanged) {
        onPageChanged(paginationData);
      }
    });
  };

  handleClick = (page: number) => (evt: MouseEvent) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = (evt: MouseEvent<HTMLLIElement>) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = (evt: MouseEvent<HTMLLIElement>) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const { totalPages } = this;
    const { currentPage } = this.state;
    const { pageNeighbours } = this;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      if (currentPage === 1 || currentPage === 2) {
        const pages = rangeGen(1, 5);

        return [...pages];
      }

      if (currentPage === totalPages || currentPage === totalPages - 1 || currentPage === totalPages - 2) {
        const pages = rangeGen(totalPages - 4, totalPages);

        return [...pages];
      }
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      const pages = rangeGen(startPage, endPage);

      return [...pages];
    }

    return [...rangeGen(1, totalPages)];
  };

  render() {
    if (!this.totalRecords) {
      return null;
    }

    const { currentPage } = this.state;
    const { pageLimit, onPageLimitChange, t, showPerPage, pageLimits } = this.props;
    const pages = this.fetchPageNumbers();

    return (
      <Nav>
        {this.totalPages > 1 && (
          <PaginationNumberList className="pagination">
            <PrevItem className="prev-item" onClick={this.handleMoveLeft}>
              {window.global_store.isRTL ? images.calArrowRight : images.calArrowLeft}{" "}
            </PrevItem>

            {pages.map((page) => (
              <Item
                key={String(page)}
                className={`page-item${currentPage === page ? " active" : ""}`}
                onClick={this.handleClick(page)}
                active={currentPage === page}
              >
                {page}
              </Item>
            ))}

            <NextItem className="next-item" onClick={this.handleMoveRight}>
              {window.global_store.isRTL ? images.calArrowLeft : images.calArrowRight}
            </NextItem>
          </PaginationNumberList>
        )}

        {showPerPage && (
          <PerPageWrapper>
            <PaginationPerPageSelector
              options={pageLimits.map((pl) => ({ value: pl, label: String(pl) }))}
              onChange={(val: number) => {
                if (onPageLimitChange) {
                  onPageLimitChange(val, this.totalPages <= val ? 1 : currentPage);
                }
              }}
            >
              {t("Per Page")} <span>&nbsp;{pageLimit}</span>
            </PaginationPerPageSelector>
          </PerPageWrapper>
        )}
      </Nav>
    );
  }
}

export default withTranslation()(Pagination);
