import styled from "styled-components";

export const NotificationMessage = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: var(--colors-mainText);
`;

export const SearchControlWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  min-width: 300px;
`;

export const DateRangePickerWrapper = styled.div`
  align-self: center;
  margin-inline-start: 20px;
`;
