import axios, { CancelToken, AxiosRequestConfig, AxiosResponse } from "axios";
import { sendRequest, getHeaders } from "utils/api/common";
import CONFIG from "../../config";

export interface UploadActivityAttachmentRequest {
  attachment: File;
  createdBy: string;
  companyUuid: string;
  activityUuid: string | null;
}

export interface UploadActivityAttachmentResponse {
  content: {
    uuid: string;
    companyUuid: string;
    createdBy: string;
    cloudinaryId: string;
    url: string;
    thumbnailUrl: string;
    title: string;
    mimeType: string;
    updatedAt: string;
    createdAt: string;
    requestUuid: null;
    updatedBy: null;
    deletedAt: null;
    externalId: null;
  };
}

export function uploadActivityAttachment(
  data: UploadActivityAttachmentRequest,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"],
  cancelToken: CancelToken,
): Promise<AxiosResponse<UploadActivityAttachmentResponse>> {
  const formData = new FormData();
  formData.append("attachment", data.attachment);
  formData.append("createdBy", data.createdBy);
  formData.append("title", data.attachment.name);
  formData.append("mimeType", data.attachment.type);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  const url = data.activityUuid
    ? `${CONFIG.protocol}${CONFIG.client_admin_api}/companies/${data.companyUuid}/projects/activities/${data.activityUuid}/attachments`
    : `${CONFIG.protocol}${CONFIG.client_admin_api}/companies/${data.companyUuid}/projects/activities/attachments`;

  return axios.post<UploadActivityAttachmentResponse>(url, formData, {
    headers,
    onUploadProgress,
    cancelToken,
  });
}

export interface DeleteActivityAttachmentRequest {
  body: {
    /** for activity attachments */
    content?: {
      updatedBy: string;
    };
    /** for requests attachments */
    updatedBy?: string;
  };
  companyUuid: string;
  activityUuid: string;
  attachmentUuid: string;
}

export function deleteActivityAttachment(data: DeleteActivityAttachmentRequest) {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/projects/activities/attachments/${data.attachmentUuid}`,
    CONFIG.client_admin_api,
  );
}
