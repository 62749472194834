import moment from "moment";
import { DashboardFrequency, Periods } from "types/models/dashboard";

const getRange = (period: Periods) => {
  const now = moment();

  switch (period) {
    case Periods.thisYear:
      return {
        start: now.clone().startOf("year"),
        end: now.clone().endOf("month"),
      };
    case Periods.pastYear:
      return {
        start: now.clone().subtract(1, "year").startOf("year"),
        end: now.clone().subtract(1, "year").endOf("year"),
      };
    case Periods.thisMonth:
      return {
        start: now.clone().startOf("month"),
        end: now.clone().endOf("day"),
      };
    case Periods.lastMonth:
      return {
        start: now.clone().subtract(1, "month").startOf("day"),
        end: now.clone().endOf("day"),
      };
    case Periods.last3Month:
      return {
        start: now.clone().subtract(3, "month").startOf("day"),
        end: now.clone().endOf("day"),
      };
    case Periods.last6Month:
      return {
        start: now.clone().subtract(6, "month").startOf("day"),
        end: now.clone().endOf("day"),
      };
    case Periods.last12Month:
      return {
        start: now.clone().subtract(12, "month").startOf("day"),
        end: now.clone().endOf("day"),
      };
    case Periods.last24Month:
      return {
        start: now.clone().subtract(24, "month").startOf("day"),
        end: now.clone().endOf("day"),
      };
    default:
      return {
        start: now.clone().startOf("year"),
        end: now.clone().endOf("month"),
      };
  }
};

const batchPeriodsAmountByFrequency: Record<DashboardFrequency, number> = {
  day: 30,
  week: 4,
  month: 1,
};

const buildPeriodBatches = (period: Periods, frequency: DashboardFrequency) => {
  const { start, end } = getRange(period);
  const momentFrequency = frequency === "week" ? "isoWeek" : frequency; // I love Moment

  const startStr = start.format("YYYY-MM-DD");
  const endStr = end.format("YYYY-MM-DD");

  const periods = [];
  const subPeriods = [];

  for (
    let batchStart = start.clone().startOf(momentFrequency);
    batchStart.isSameOrBefore(end);
    batchStart.add(batchPeriodsAmountByFrequency[frequency], frequency)
  ) {
    let periodStart = batchStart.clone();
    periods.push(batchStart.format("YYYY-MM-DD"));

    for (let i = 0; i < batchPeriodsAmountByFrequency[frequency] - 1; i += 1) {
      const maybePeriodStart = periodStart.clone().add(1, frequency); // I love Moment

      if (maybePeriodStart.isSameOrBefore(end)) {
        periodStart = maybePeriodStart;
        periods.push(periodStart.format("YYYY-MM-DD"));
      } else {
        break;
      }
    }

    const batchEnd = periodStart.clone().endOf(momentFrequency);

    subPeriods.push({
      startStr: batchStart.format("YYYY-MM-DD"),
      endStr: batchEnd.format("YYYY-MM-DD"),
    });
  }

  return { start, end, startStr, endStr, subPeriods, periods };
};

export { buildPeriodBatches };
const periods = { buildPeriodBatches };
export default periods;
