import { Component } from "react";
import CheckboxControl from "components/UI/NewCheckbox";

interface CellCheckboxAllProps<T> {
  entities: T[];
  isEntitySelectable: (entity: T) => boolean;
  checked: boolean;
  onChange: (checked: boolean, selectedEntitiesIds: string[]) => void;
  entityIdKey: string; // key for values that will be pushed to selectedEntitiesIds
  disabled?: boolean;
}

// TODO move to TablePage
class CellCheckboxAll<T extends Record<string, unknown>> extends Component<CellCheckboxAllProps<T>> {
  static defaultProps = {
    isEntitySelectable: () => true,
    entities: [],
    entityIdKey: "uuid",
  };

  UNSAFE_componentWillMount(): void {
    document.addEventListener("cell_checkbox_uncheck_all", this.uncheckAll);
    window.onkeydown = (e) => {
      const { entities } = this.props;
      if (!entities.length) {
        return true;
      }

      e = e || window.event;
      const k = e.keyCode || e.which;

      switch (k) {
        case 65: // handle A key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.onCheckAll(true);
            return false;
          }
          return true;
        case 68: // handle D key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.onCheckAll(false);
            return false;
          }
          return true;
        default:
          return true;
      }
    };
  }

  componentWillUnmount(): void {
    document.removeEventListener("cell_checkbox_uncheck_all", this.uncheckAll);

    window.onkeydown = null;
  }

  uncheckAll = (): void => {
    this.onCheckAll(false);
  };

  onCheckAll = (isChecked: boolean): void => {
    const { entities, onChange, isEntitySelectable, entityIdKey } = this.props;
    const selectedEntitiesIds: string[] = [];

    if (isChecked) {
      entities.forEach((entity) => isEntitySelectable(entity) && selectedEntitiesIds.push(entity[entityIdKey]));
    }

    onChange(isChecked, selectedEntitiesIds);
  };

  render(): JSX.Element {
    const { entities, checked, disabled } = this.props;

    return <CheckboxControl disabled={!entities?.length || disabled} checked={checked} onChange={this.onCheckAll} />;
  }
}

export default CellCheckboxAll;
