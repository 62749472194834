import { ChangeEvent, Component, ContextType } from "react";
import styled from "styled-components";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "components/UI/Select";
import MultiSelect from "components/UI/Select/MultiSelect";
import ModalDialog from "components/UI/ModalDialog";
import SearchInput from "components/UI/SearchInput";
import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getTitle, strIncludesCheck, minsToHrsMins } from "utils/common";
import { TableButtons, TableButton } from "components/styled/Page";
import { iColumn, ColumnAlign } from "components/TableCommon";
import { iCellInfo, iRowInfo } from "utils/tableHelpers";
import sentryUtils from "utils/sentryUtils";
import { getSchedules, activateSchedule, deactivateSchedule } from "utils/apiHelpers";
import { ScheduleListSchedule, ScheduleType, ScheduleListScheduleStatus } from "types/models/schedule";
import { TranslationNamespaces } from "types/translationNamespaces";
import arrowImg from "img/select-arrow.svg";
import noSchedules from "img/no-schedules.png";
import GlobalContext from "context/global-context";
import { GetSchedulesRequestData } from "utils/api/types";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import TablePage from "../TablePage";
import ScheduleDayButton from "../controls/ScheduleDayButton";
import SchedulePopupMessage from "./SchedulePopupMessage";
import NoContent from "../NoContent";
import * as images from "../svg-images";
import "../UI/Page/Page.scss";
import "styles/schedules-page.scss";

const b = BEM.b("schedules-page");
const p = BEM.b("page");

const MultiselectWrapper = styled.div`
  margin-inline-start: 20px;

  .ui-select {
    div {
      .ui-select__input {
        &.ui-select__input_icon {
          min-width: 150px;
          background-color: var(--colors-surface-50);

          &:after {
            background-image: url(${arrowImg});
          }

          .ui-select__label {
            margin-inline-start: 0;
            font-size: 15px;
            color: var(--colors-surface-900-p);
          }
        }
      }
    }
  }
`;

const ScheduleDaysRow = styled.div`
  display: flex;
  gap: 8px;
`;

type WeekDayName = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

interface SelectedSchedule {
  uuid: string;
  name: string;
}

interface WeekDay {
  id: string;
  isNumberDay?: boolean;
  working: boolean;
}

interface ScheduleTableData {
  id: string;
  uuid: string;
  name: string;
  hours: string;
  type: ScheduleType;
  employees: number;
  schedule: WeekDay[];
  active: boolean;
  options: string;
}

interface SchedulesPageProps extends RouteComponentProps, WithTranslation {
  onNotification: (message: string) => void;
}

interface SchedulesPageState {
  schedules: ScheduleListSchedule[];
  status: ScheduleListScheduleStatus | "";
  searchValue: string;
  initialFetchDone: boolean;
  loading: boolean;
  selectedSchedule: SelectedSchedule | null;
  deactivatePopupVisible: boolean;
  selectedScheduleTypes: string[];
}

class SchedulesPage extends Component<SchedulesPageProps, SchedulesPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: SchedulesPageProps) {
    super(props);
    const { t } = props;

    this.state = {
      schedules: [],
      status: ScheduleListScheduleStatus.active,
      searchValue: "",
      initialFetchDone: false,
      loading: true,
      selectedSchedule: null,
      deactivatePopupVisible: false,
      selectedScheduleTypes: [],
    };

    document.title = getTitle(t("Schedules"));
  }

  componentDidMount(): void {
    void this.getState();
  }

  getState = async (): Promise<void> => {
    const { initialFetchDone, loading, status } = this.state;
    if (!initialFetchDone || !loading) {
      const company = await this.context.getCompany();

      this.setState({ loading: true, initialFetchDone: true }, async () => {
        const { selectedScheduleTypes } = this.state;
        const params: GetSchedulesRequestData["params"] = {};

        if (selectedScheduleTypes.length) {
          params.scheduleTypes = selectedScheduleTypes;
        }

        if (status) {
          params.active = status === ScheduleListScheduleStatus.active;
        }

        const r = await getSchedules({ companyUuid: company.uuid, params });

        this.setState({ schedules: r.content, loading: false });
      });
    }
  };

  onDeactivateScheduleClick = (uuid: string, name: string): void => {
    this.setState({ deactivatePopupVisible: true, selectedSchedule: { uuid, name } });
  };

  onActivateScheduleClick = async (scheduleUuid: string, name: string): Promise<void> => {
    const { t, onNotification } = this.props;

    try {
      await activateSchedule({
        companyUuid: window.global_store.company.uuid,
        scheduleUuid,
      });

      onNotification(`${t("You activated schedule")}: ${name}`);

      void this.getState();
    } catch (error) {
      sentryUtils.sendError(error);
      console.log(error);
    }
  };

  deactivateSchedule = async (selectedSchedule: SelectedSchedule | null): Promise<void> => {
    const { t, onNotification } = this.props;
    const currentProfileUuid = window.global_store.profile.uuid;

    if (!selectedSchedule) {
      return;
    }

    try {
      await deactivateSchedule({
        body: {
          content: {
            endDate: moment().format("YYYY-MM-DD"),
            deactivatedBy: currentProfileUuid,
          },
        },
        companyUuid: window.global_store.company.uuid,
        scheduleUuid: selectedSchedule.uuid,
      });

      onNotification(`${t("You deactivated schedule")}: ${selectedSchedule.name}`);

      this.setState({ deactivatePopupVisible: false, selectedSchedule: null });

      void this.getState();
    } catch (err) {
      sentryUtils.sendError(err);
      console.log((err as Error).message);
    }
  };

  onScheduleTypesChange = (selectedScheduleTypes: string[]): void => {
    this.setState({ selectedScheduleTypes }, this.getState);
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableData(schedules: ScheduleListSchedule[]): ScheduleTableData[] {
    const data = schedules.map(
      (e: ScheduleListSchedule): ScheduleTableData => ({
        id: e.legacyId,
        uuid: e.uuid,
        name: e.name,
        hours: minsToHrsMins(e.totalDurationWithWorkingBreaks),
        type: e.scheduleType || e.type,
        employees: e.activeUserProfilesCount,
        schedule: this.getWeekDays(e), // e.scheduleDays || e.rules.schedule,
        active: e.active,
        options: e.uuid,
      }),
    );

    return data;
  }

  getWeekDays = (schedule: ScheduleListSchedule): WeekDay[] => {
    let days: WeekDay[] = [];

    if (schedule.scheduleDays) {
      days = schedule.scheduleDays.map((d, i) => ({
        id: schedule.scheduleType === "shifts" && schedule.scheduleDays.length > 7 && i >= 6 ? "7+" : d.dayId,
        isNumberDay: schedule.scheduleType === "shifts",
        working: d.working,
      }));

      days = days.slice(0, 7);
    } else {
      const scheduleDays = schedule.rules.schedule || {};
      const weekDays: WeekDayName[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

      days = weekDays.map((wd, i) => ({
        id: String(i + 1),
        working: scheduleDays[wd],
      }));
    }

    return days;
  };

  getTableColumns = (): iColumn<ScheduleTableData>[] => {
    const { t } = this.props;

    return [
      {
        label: t("Schedule Name"),
        accessor: "name",
        locked: true,
        Cell: (row: iCellInfo<ScheduleTableData>): JSX.Element => (
          <a
            className={b("schedule-name")}
            href={`/schedules/${row.original.uuid}/`}
            onClick={(ev): void => ev.preventDefault()}
          >
            <div className={b("schedule-name-value")}>{row.value}</div>
            <div className={b("schedule-name-type")}>{t(row.original.type)}</div>
          </a>
        ),
        minWidth: 160,
      },
      {
        label: t("Hours"),
        accessor: "hours",
        Cell: (row: iCellInfo<ScheduleTableData>): JSX.Element | string => {
          if (row.original.type === "shifts") {
            return <span>{images.scheduleRecuringShift}</span>;
          }
          return row.value ? <span>{row.value}</span> : "";
        },
        minWidth: 100,
        align: "center" as ColumnAlign,
      },
      {
        label: t("Schedule"),
        accessor: "schedule",
        Cell: (days: iCellInfo<ScheduleTableData, WeekDay[]>): JSX.Element => (
          <ScheduleDaysRow>
            {days.value.map((day) => (
              <ScheduleDayButton key={day.id} value={day.id} isNumberDay={day.isNumberDay} selected={day.working} />
            ))}
          </ScheduleDaysRow>
        ),
        width: 338,
      },
      {
        label: t("Employees"),
        accessor: "employees",
        align: "right" as ColumnAlign,
        minWidth: 50,
      },
      {
        label: t("Status"),
        accessor: "active",
        locked: true,
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<ScheduleTableData>): JSX.Element => (
          <div>
            <StatusTag
              value={t(row.value ? "active" : "inactive")}
              type={row.value ? StatusTagType.active : StatusTagType.inactive}
            />
            <TableButtons className="buttons">
              <TableButton
                onClick={(ev): void => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  if (row.original.active) {
                    this.onDeactivateScheduleClick(row.original.uuid, row.original.name);
                  } else {
                    void this.onActivateScheduleClick(row.original.uuid, row.original.name);
                  }
                }}
              >
                {row.original.active ? t("common|Deactivate") : t("common|Activate")}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 120,
      },
    ];
  };

  render(): JSX.Element {
    const { schedules, loading, searchValue, status, deactivatePopupVisible, selectedSchedule, selectedScheduleTypes } =
      this.state;
    const { t, history } = this.props;

    const filteredSchedules = schedules.filter((s) => {
      const filter = strIncludesCheck(s.name, searchValue);

      return filter;
    });

    return (
      <div>
        <div className={b()}>
          <TablePage<ScheduleTableData>
            columnSelectorOnFiltersRow
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search Schedules")}
                  value={searchValue}
                />
                <MultiselectWrapper>
                  <MultiSelect<string>
                    placeholder={t("Schedules")}
                    value={selectedScheduleTypes}
                    onChange={this.onScheduleTypesChange}
                    options={[
                      { value: ScheduleType.regular, label: t("Weekly") },
                      { value: ScheduleType.flexible, label: t("Flexible") },
                      { value: ScheduleType.shifts, label: t("Shifts") },
                    ]}
                  />
                </MultiselectWrapper>
                <div className={p("dropdown")}>
                  <Select
                    value={status}
                    onChange={(value: ScheduleListScheduleStatus | ""): void =>
                      this.setState({ status: value }, this.getState)
                    }
                    options={[
                      { value: "", label: t("Status") },
                      { value: ScheduleListScheduleStatus.active, label: t("Active") },
                      { value: ScheduleListScheduleStatus.inactive, label: t("Inactive") },
                    ]}
                  />
                </div>
              </>
            }
            rows={this.getTableData(filteredSchedules)}
            columns={this.getTableColumns()}
            noDataText=""
            loading={loading}
            manual
            showPageSizeOptions={false}
            getTrProps={(_, rowInfo: iRowInfo<ScheduleTableData> | undefined): { onClick: () => void } => ({
              onClick: (): void => {
                if (rowInfo) {
                  history.push(`/schedules/${rowInfo.original.uuid}/`);
                }
              },
            })}
            noContentComponent={<NoContent img={noSchedules}>{t("Please create a new schedule")}</NoContent>}
          />
        </div>

        <ModalDialog
          isOpen={deactivatePopupVisible}
          onClose={(): void => this.setState({ deactivatePopupVisible: false, selectedSchedule: null })}
        >
          <SchedulePopupMessage
            type="disable"
            onClose={(): void => this.setState({ deactivatePopupVisible: false, selectedSchedule: null })}
            onYes={(): Promise<void> => this.deactivateSchedule(selectedSchedule)}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.schedules)(SchedulesPage));
