import { useMemo, useEffect } from "react";
import { UserProfileContext, UserProfileContextData } from "context/user-profile-context";
import { MemoryRouter } from "react-router-dom";
import { Feature } from "types/models/subscription";
import { Wrapper, ErrorBoundary, TabType } from "../views";
import { PunchNowWidget } from "../PunchNowWidget";
import { services } from "../services";

export interface PunchNowProps {
  onClose: () => void;
}
/**
 * A wrapper for PunchNowWidget used to show widget inside oitchau-admin.
 * Not used by browser extension or desktop app.
 */
function PunchNow({ onClose }: PunchNowProps) {
  useEffect(() => services.punchNowService.clearCache(), []);

  const tabs = useMemo(() => {
    const { profile } = window.global_store;
    const isPunchAllowed =
      profile.allow_web_punch &&
      (profile.associate_products.includes(Feature.TA) || profile.associate_products.includes(Feature.Bundle));
    const isProjectsAllowed =
      profile.associate_products.includes(Feature.Project) || profile.associate_products.includes(Feature.Bundle);

    const ts = [];
    if (isPunchAllowed) {
      ts.push(TabType.punch);
    }
    if (isProjectsAllowed) {
      ts.push(TabType.projects);
    }
    return ts;
  }, []);

  const userProfileContextData: UserProfileContextData = {
    userProfile: window.global_store.profile,
  };

  return (
    <UserProfileContext.Provider value={userProfileContextData}>
      <PunchNowWidget
        availableTabs={tabs}
        punchEventTracking={services.punchEventTracking}
        punchNowService={services.punchNowService}
        onClose={onClose}
      />
    </UserProfileContext.Provider>
  );
}

export function PunchNowPopup(props: PunchNowProps) {
  const { onClose } = props;

  const handleError = (err: any) => {
    services.punchEventTracking.trackError(err);
  };

  return (
    <Wrapper>
      <ErrorBoundary onClose={onClose} onError={handleError}>
        <MemoryRouter>
          <PunchNow {...props} />
        </MemoryRouter>
      </ErrorBoundary>
    </Wrapper>
  );
}
