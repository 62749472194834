/**
 * Only apply predicate to the leaves (with no children) of the nested array.
 * Drop empty branches from returned value.
 */
export function filterNested<T = any, P = any>(
  arr: T[],
  predicate: (item: P) => boolean,
  childrenFieldName = "items",
): T[] {
  const filtered = [];

  for (const item of arr) {
    const isLeaf = item[childrenFieldName] === undefined || item[childrenFieldName] === null;
    if (isLeaf) {
      const shouldFilterIn = predicate(item);
      if (shouldFilterIn) {
        filtered.push(item);
      }
    } else {
      const filteredBranch = filterNested(item[childrenFieldName], predicate, childrenFieldName);
      const shouldFilterIn = filteredBranch.length > 0;
      if (shouldFilterIn) {
        filtered.push({ ...item, [childrenFieldName]: filteredBranch });
      }
    }
  }
  return filtered;
}
