import { PunchEventTracking } from "services/punch-event-tracking";
import { PunchService } from "services/punch-service";
import { PunchNowOptionsServiceInterface } from "services/widget-options-service/types";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Projects } from "../views/Projects";
import { Punches } from "../views/Punches";
import { TabSelector, TabSelectorProps, TabType, Wrapper } from "../views";
import { WidgetOptions } from "../views/WidgetOptions";

export { TabType };

export interface PunchNowWidgetProps {
  punchNowService: PunchService;
  punchEventTracking: PunchEventTracking;
  optionsService?: PunchNowOptionsServiceInterface;
  availableTabs: TabSelectorProps["activeTab"][];
  onClose: () => void;
}
export function PunchNowWidget(props: PunchNowWidgetProps) {
  const { availableTabs, punchNowService, punchEventTracking, optionsService, onClose } = props;
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();
  const path = routeMatch.path.replace(/\/$/, "");
  const tab = location.pathname.match(`${path}/([^/]*).*`)?.[1] as TabType;
  if (tab) {
    localStorage.setItem("gs_widget_tab", tab);
  }
  const widgetUsedTab = () => {
    const lsTab: string | null = localStorage.getItem("gs_widget_tab");
    if (!lsTab || !availableTabs.includes(lsTab as TabType)) return undefined;
    return lsTab as TabType;
  };

  return (
    <Wrapper>
      <TabSelector
        tabs={availableTabs}
        activeTab={tab}
        onTabClick={(selectedTab) => {
          history.push(`${path}/${selectedTab}`);
        }}
      />
      <Switch>
        <Route path={`${path}/punch`}>
          <Punches punchNowService={punchNowService} eventTrakingService={punchEventTracking} onClose={onClose} />
        </Route>
        <Route path={`${path}/projects`}>
          <Projects />
        </Route>
        <Route path={`${path}/options`}>
          {optionsService != null && <WidgetOptions optionsService={optionsService} />}
        </Route>
        <Route path={`${path}/`}>
          <Redirect to={`${path}/${widgetUsedTab() || availableTabs[0]}`} />
        </Route>
      </Switch>
    </Wrapper>
  );
}
