import React, { Component } from "react";
import styled from "styled-components";
import SearchInput from "components/UI/SearchInput";
import rtl from "styled-components-rtl";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import MenuItems from "./MenuItems";

const GroupListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 223px;
  border-inline-end: 1px solid #dde5ee;
  height: 100%;
  padding: 16px 15px;
`;
const ContainerInner = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
`;
const SearchWrapper = styled.div`
  .ui-search-input {
    width: 100%;
  }
`;
const AllEmployeesItem = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  color: #a1b2cf;
  padding-inline-start: 27px;
  text-transform: uppercase;
  height: 36px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 4px;
  &:before {
    content: "";
    width: 13px;
    position: absolute;
    ${rtl`
      left: 0;
    `}
    height: 36px;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.077 5.615a2.308 2.308 0 100-4.615 2.308 2.308 0 000 4.615zM1 12.954a4.57 4.57 0 014.57-4.57h1.259a4.57 4.57 0 014.57 4.57v0c0 .026-.02.046-.046.046H1.046A.046.046 0 011 12.954v0z' stroke='%23A1B2CF' stroke-width='1.6'/%3E%3C/svg%3E");
  }
  &:hover,
  &.selected {
    color: var(--colors-primary);
  }
  &:hover:before,
  &.selected:before {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.077 5.615a2.308 2.308 0 100-4.615 2.308 2.308 0 000 4.615zM1 12.954a4.57 4.57 0 014.57-4.57h1.259a4.57 4.57 0 014.57 4.57v0c0 .026-.02.046-.046.046H1.046A.046.046 0 011 12.954v0z' stroke='%231e97f7' stroke-width='1.6'/%3E%3C/svg%3E");
  }
`;

export default class GroupList extends Component {
  state = {
    selectedGroup: "department",
    filterStr: "",
  };

  setSelectedGroup = (group) => this.setState({ selectedGroup: group });

  render() {
    const { t, groups, setEmployees, filterGroups } = this.props;
    const { selectedGroup, filterStr } = this.state;
    const allEmployeesLabel = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );

    return (
      <GroupListWrapper>
        <ContainerInner>
          <SearchWrapper>
            <SearchInput
              modifiers={["filter"]}
              onChange={(ev) => {
                this.setState({ filterStr: ev.target.value });
                filterGroups(ev.target.value.toLowerCase());
              }}
              placeholder={t("Search groups")}
              value={filterStr}
            />
          </SearchWrapper>
          {!filterStr && (
            <AllEmployeesItem
              className={selectedGroup === "all" ? "selected" : ""}
              onClick={() => {
                this.setSelectedGroup("all");
                setEmployees({
                  employees: groups.allEmployees,
                  groupName: allEmployeesLabel,
                });
              }}
            >
              {allEmployeesLabel}
            </AllEmployeesItem>
          )}
          <MenuItems
            key="subsidiary"
            searchMode={!!filterStr}
            items={groups.subsidiaries}
            setEmployees={setEmployees}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "subsidiary"}
            name={t("Subsidiaries")}
            type="subsidiary"
          />
          <MenuItems
            key="team"
            searchMode={!!filterStr}
            items={groups.teams}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "team"}
            setEmployees={setEmployees}
            name={t("Teams")}
            type="team"
          />
          <MenuItems
            key="department"
            searchMode={!!filterStr}
            items={groups.departments}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "department"}
            setEmployees={setEmployees}
            name={t("Departments")}
            type="department"
          />
          <MenuItems
            key="position"
            searchMode={!!filterStr}
            items={groups.positions}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "position"}
            setEmployees={setEmployees}
            name={t("Position")}
            type="position"
          />
          <MenuItems
            key="payrollGroups"
            searchMode={!!filterStr}
            items={groups.payrollGroups}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "payrollGroups"}
            setEmployees={setEmployees}
            name={t("Payroll groups")}
            type="payrollGroups"
          />
          <MenuItems
            key="businessRulesGroups"
            searchMode={!!filterStr}
            items={groups.businessRulesGroups}
            setSelectedGroup={this.setSelectedGroup}
            isSelected={selectedGroup === "businessRulesGroups"}
            setEmployees={setEmployees}
            name={t("Business rules")}
            type="businessRulesGroups"
          />
        </ContainerInner>
      </GroupListWrapper>
    );
  }
}
