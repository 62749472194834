import { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { fireDownloadReport } from "utils/apiHelpers";
import { showSnackbar } from "utils/common";
import GlobalContext from "context/global-context";

const Button = styled.button`
  margin-top: 60px;
  margin-bottom: 30px;
  outline: none;
  font-weight: var(--typography-font-weight-medium);
  cursor: pointer;
  border: 1.2px solid var(--colors-secondary);
  border-radius: var(--shapes-border-radius-default);
  font-size: var(--typography-font-size-default);
  line-height: 36px;
  color: var(--colors-mainText);
  padding: 0 22px;
  background-color: var(--colors-default);
`;

type DownloadDigitalSignaturesReportButtonProps = {
  reportHash: string;
  digitalSignatureUuid: string;
  selectedColumns?: string;
};

export default function DownloadDigitalSignaturesReportButton({
  reportHash,
  digitalSignatureUuid,
  selectedColumns,
}: DownloadDigitalSignaturesReportButtonProps) {
  const { t } = useTranslation("payment");
  const context = useContext(GlobalContext);

  const downloadReport = async () => {
    const response = await fireDownloadReport({
      searchObj: {
        employee: {
          uuid: window.global_store.profile.uuid,
        },
      },
      selectedColumns: selectedColumns || "",
      reportHash: reportHash || "",
      digitalSignatureUuid,
      reportType: "digital_signature",
    });
    showSnackbar({ text: t("common|Preparing file for download…"), notificationStyle: "notice" });
    context.addToDownloads(response.report_uuid);
  };

  return <Button onClick={downloadReport}>{t("Download Report")}</Button>;
}
