import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPasswordPage from "components/Onboarding/ForgotPasswordPage";

export default ({ match: { path } }) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Route path="/forgot-password" component={ForgotPasswordPage} />
    </Switch>
  </Suspense>
);
