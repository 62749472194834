import React from "react";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import Button from "components/controls/StyledButton";
import CheckboxControl from "components/UI/NewCheckbox";
import { withTranslation } from "react-i18next";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import { fireEvent } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";

const ActionsBarWrapper = styled.div`
  left: 32.19%;
  right: 13.67%;
  bottom: 5.12%;
  height: 60px;
  max-width: 693px;
  background: var(--colors-default);
  box-shadow: 0px 5px 30px rgba(129, 147, 171, 0.2);
  border-radius: 58px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  /* width: 100%;
  max-width: 693px; */
`;
const SlecetedCount = styled.div`
  display: flex;
  align-items: center;
`;
const CheckboxLabel = styled.span`
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: #525f7f;
`;
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 251px; */
`;
const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 0a.794.794 0 00-.794.794v3.412H.794a.794.794 0 000 1.588h3.412v3.412a.794.794 0 001.588 0V5.794h3.412a.794.794 0 100-1.588H5.794V.794A.794.794 0 005 0z' fill='%231E97F7'/%3E%3C/svg%3E");
  ${rtl`
  background-position: left;
  `}
  background-repeat: no-repeat;
  padding-inline-start: 20px;
`;

class ActionsBar extends React.Component {
  state = {
    checkedRows: [],
  };
  checkedRows = [];
  UNSAFE_componentWillMount() {
    document.addEventListener("sp_row_checked", this.handleChecked);
    document.addEventListener("sp_row_clear_all", this.clearPunches);
    document.addEventListener("sp_row_organize_all", this.organizePunches);
    document.addEventListener("sp_row_fill-last", this.fillLast);
    document.addEventListener("sp_row_approve-all", this.approveAll);
    document.addEventListener("sp_row_validate-all", this.validateAll);
    document.addEventListener("sp_row_force-validate-all", this.forceValidateAll);
  }
  UNSAFE_componentWillReceiveProps() {
    this.checkedRows = [];
    this.setState({ checkedRows: [] });
  }
  componentWillUnmount() {
    document.removeEventListener("sp_row_checked", this.handleChecked);
    document.removeEventListener("sp_row_clear_all", this.clearPunches);
    document.removeEventListener("sp_row_organize_all", this.organizePunches);
    document.removeEventListener("sp_row_fill-last", this.fillLast);
    document.removeEventListener("sp_row_approve-all", this.approveAll);
    document.removeEventListener("sp_row_validate-all", this.validateAll);
    document.removeEventListener("sp_row_force-validate-all", this.forceValidateAll);
  }
  handleChecked = (ev) => {
    if (!ev.detail.checked) {
      this.checkedRows = this.checkedRows.filter((row) => row !== ev.detail.date);
    } else if (this.checkedRows.indexOf(ev.detail.date) === -1) {
      this.checkedRows = [...this.checkedRows, ev.detail.date];
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState({ checkedRows: this.checkedRows });
    }, 200);
    if (this.shouldFillLastGap) {
      this.findAndFill();
    }
  };
  fillLast = () => {
    this.shouldFillLastGap = true;
  };
  findAndFill = () => {
    this.shouldFillLastGap = false;
    if (this.checkedRows && this.checkedRows.length > 1) {
      const [firstDate, secondDate] = [
        this.checkedRows[this.checkedRows.length - 1],
        this.checkedRows[this.checkedRows.length - 2],
      ].sort();
      const { allRows } = this.props;
      allRows.forEach((row) => {
        const formatted = row.date.raw.format("YYYYMMDD");
        if (formatted > firstDate && formatted < secondDate) {
          fireEvent(`sp_row_checked_${formatted}`, { checked: true });
        }
      });
    }
  };
  approveAll = () => {
    const { checkedRows } = this.state;
    if (checkedRows && checkedRows.length) {
      this.props.onApproveAll(checkedRows);
    }
    fireEvent("sp_row_uncheck_all");
  };
  validateAll = () => {
    const { checkedRows } = this.state;
    if (checkedRows && checkedRows.length) {
      this.props.onValidateAll(checkedRows);
    }
    fireEvent("sp_row_uncheck_all");
  };
  clearPunches = () => {
    const { checkedRows } = this.state;
    if (checkedRows && checkedRows.length) {
      this.props.onClearPunches(checkedRows);
    }
    fireEvent("sp_row_uncheck_all");
  };
  forceValidateAll = () => {
    const { checkedRows } = this.state;
    if (checkedRows && checkedRows.length) {
      this.props.onRecalculate(checkedRows.sort());
    }
    fireEvent("sp_row_uncheck_all");
  };
  organizePunches = () => {
    const { checkedRows } = this.state;
    if (checkedRows && checkedRows.length) {
      this.props.onOrganizePunches(checkedRows);
    }
    fireEvent("sp_row_uncheck_all");
  };
  render = () => {
    const { t, onClearPunches, onOrganizePunches, onRecalculate, onRemoveSchedule, onValidateAll, onApproveAll } =
      this.props;
    const { checkedRows } = this.state;
    if (!checkedRows.length) {
      return null;
    }
    return (
      <ActionsBarWrapper>
        <SlecetedCount>
          <CheckboxControl
            label={
              <CheckboxLabel>
                {t("Selected")} {checkedRows.length}
              </CheckboxLabel>
            }
            checked
            onChange={() => {
              fireEvent("sp_row_uncheck_all");
            }}
          />
        </SlecetedCount>
        <ActionButtons>
          <SimpleMenu
            boldItemOnHover
            options={[
              {
                value: "clearPunches",
                label: t("Clear punches"),
              },
              { value: "organizeChronologically", label: t("Organize Chronologically") },
              window.global_store.beta && localStorage.getItem("validateButton") === "true"
                ? { value: "recalculate", label: t("Force Recalculate") }
                : null,
              { value: "removeSchedule", label: t("Remove Schedule") },
              { value: "validateAll", label: t("Validate All") },
            ].filter((e) => e)}
            onChange={(val) => {
              if (val === "clearPunches") {
                onClearPunches(checkedRows);
              } else if (val === "organizeChronologically") {
                onOrganizePunches(checkedRows);
              } else if (val === "recalculate") {
                onRecalculate(checkedRows.sort());
              } else if (val === "removeSchedule") {
                onRemoveSchedule(checkedRows);
              } else if (val === "validateAll") {
                onValidateAll(checkedRows);
              }
              fireEvent("sp_row_uncheck_all");
            }}
          >
            <Button
              state="secondary"
              value={<ButtonInner>{t("Actions")}</ButtonInner>}
              onClick={() => {}}
              style={{ width: "110px" }}
            />
          </SimpleMenu>

          <Button
            value={t("Approve All")}
            onClick={() => {
              onApproveAll(checkedRows);
              fireEvent("sp_row_uncheck_all");
            }}
            style={{ width: "120px" }}
          />
        </ActionButtons>
      </ActionsBarWrapper>
    );
  };
}
export default withTranslation(TranslationNamespaces.punchesPage)(ActionsBar);
