import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";

const Wrapper = styled.div`
  widht: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  padding: 24px 8px;
  white-space: normal;
`;
const Title = styled.h6`
  margin: 0;
  color: var(--colors-surface-900-p, #434e6c);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--typography-font-weight-medium);
  text-align: center;
`;
const Description = styled.p`
  margin: 0;
  color: var(--colors-surface-700, #707d9e);
  text-align: center;
  font-size: 13px;
  font-weight: var(--typography-font-weight-normal);
  line-height: 17px;
`;
const ViewLogs = styled.a`
  widht: 100%;
  cursor: pointer;
  color: var(--colors-primary-500-p);
  text-align: center;
  font-size: var(--typography-font-size-default);
  font-style: normal;
  font-weight: var(--typography-font-weight-medium);
  line-height: 14px;
  padding: 11px 0;
`;

type LockedDayScreenProps = {
  onViewLogs: () => void;
};

const LockedDayScreen = ({ onViewLogs }: LockedDayScreenProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  return (
    <Wrapper>
      <Title>{t("The punch is locked")}</Title>
      <Description>{t("punch-locked-description")}</Description>
      <ViewLogs
        onClick={(ev) => {
          ev.preventDefault();
          onViewLogs();
        }}
      >
        {t("View Log History")}
      </ViewLogs>
    </Wrapper>
  );
};

export default LockedDayScreen;
