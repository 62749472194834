import { APIResponse, sendRequest } from "./common";
import { GetTeamsResponseData } from "./types";

// TODO change this to proper types
type TODORequestData = any;
type TODOResponseData = Promise<any>;

export function getTeams(): APIResponse<GetTeamsResponseData> {
  return sendRequest({}, "GET", "/teams");
}

export function updateTeam(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PUT", `/teams/${data.id}`);
}

export function deleteTeam(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "DELETE", `/teams/${data.id}`);
}
