import moment from "moment";
import { BillingService } from "components/Billing/BillingService";
import { Customer, Feature, PaymentType, SubscriptionStatus } from "types/models/subscription";
import { PermissionRoleName } from "types/models/permissions";
import { PunchType } from "types/models/punches";
import { ReportType } from "types/reports";
import sentryUtils from "utils/sentryUtils";
import CONFIG from "config";
import {
  GaCreateAccountPayload,
  GaAmpliObject,
  GaCreateCompanyPayload,
  GaSelectIntegrationPayload,
  GaInstallExtensionPopupStatus,
  GaBeginCheckoutPayload,
  GaPurchasePayload,
  GaBoolean,
  GaProduct,
  GaApprovePunchPayload,
  GaCreatePunchPayload,
  GaChangePunchCategory,
  GaAddTimeButtonLocation,
  GaCreateNewProjectPayload,
  GaRequestSubmitPayload,
  GaSpPageType,
  GaLoginMethod,
  GaPurchasePaymentType,
  GaStartTimeButtonLocation,
  GaIntegrationState,
  DownloadExtensionPage,
  MassActionLocations,
} from "./types";
import { ApplicationType } from "../../types/models/application";

export class GoogleAnalytics {
  private getAmpliObject = () => {
    const { profile, signupUser } = window.global_store;

    let ampliObject: Partial<GaAmpliObject> = {
      platform: "Web App",
    };

    if (profile) {
      const lssubscriptions = localStorage.getItem("gs_subscriptions");
      const subscriptions = lssubscriptions ? (JSON.parse(lssubscriptions) as Customer["subscriptions"]) : [];

      let product: GaAmpliObject["product"];
      let activePayingClient: GaAmpliObject["active_paying_client"];
      let plan: GaAmpliObject["plan"];

      if (profile.associate_products.length > 1) {
        product = GaProduct.Bundle;
      } else {
        product = {
          [Feature.TA]: GaProduct.TA,
          [Feature.Project]: GaProduct.Project,
        }[profile.associate_products[0]];
      }

      if (!subscriptions.length) {
        activePayingClient = "";
        plan = "Trial";
      } else {
        const subscription = subscriptions[0];

        activePayingClient = subscriptions.some((s) => s.status === SubscriptionStatus.Active)
          ? GaBoolean.true
          : GaBoolean.false;

        if (!subscription.externalId) {
          plan = "Trial";
        } else {
          plan =
            moment(subscription.currentPeriodEndedAt).diff(moment(subscription.currentPeriodStartedAt), "months") > 1
              ? "Yearly"
              : "Monthly";
        }
      }

      ampliObject = {
        ...ampliObject,
        plan,
        product,
        integration_name: "", // TODO
        user_role: profile.permission_roles[0]?.name || "",
        signup_date: moment(profile.created_at).format("YYYY/MM/DD"),
        active_paying_client: activePayingClient,
        company_name: profile.company?.name,
        company_id: String(profile.company?.id),
        company_uuid: profile.company?.uuid,
        user_profile_id: String(profile.id),
        user_profile_uuid: profile.uuid,
        user_id: String(profile?.user_id || signupUser?.id || ""),
      };
    }

    return ampliObject;
  };

  private getSignupUserAmpliObject = () => {
    const { signupUser } = window.global_store;

    const ampliObject: GaAmpliObject = {
      platform: "Web App",
      plan: "",
      product: "",
      integration_name: "",
      user_role: PermissionRoleName.owner,
      signup_date: signupUser ? moment(signupUser.created_at).format("YYYY/MM/DD") : "",
      active_paying_client: "",
      company_id: "",
      company_uuid: "",
      company_name: "",
      user_profile_id: "",
      user_profile_uuid: "",
      user_id: signupUser ? String(signupUser.id) : "",
    };

    return ampliObject;
  };

  private trackEvent = (eventName: string, payload: Record<string, any>) => {
    try {
      const data = {
        ...payload,
        event: eventName,
      };

      // this.logEvent(eventName, data);

      window.dataLayer.push(data);
    } catch (err) {
      // ignore error becuase if GA fails, it should not affect the app
      // and as it fails due to adsence enabled, we are losing visability in console and Sentry
      console.log("trackEvent error");
    }
  };

  // private logEvent = (eventName: string, data: any) => {
  //   if (CONFIG.appEnv !== "production") {
  //     console.log("GA Event:", eventName, data);
  //   }
  // };

  // ==== public methods =====

  trackSessionStart = () => {
    this.trackEvent("logged_in_session_start", this.getAmpliObject());
  };

  trackNavigation = (pageLocation: string, pageTitle: string) => {
    // call trackPricingPageTab instead - see trackPricingPageTab
    if (pageLocation.includes("/billing/pricing")) {
      return;
    }
    // Superpunch - see trackSuperPunchNavigation
    if (pageLocation.includes("/punches?type=group-edit") || pageLocation.includes("/punches?type=edit")) {
      return;
    }

    this.trackEvent("page_view", { ...this.getAmpliObject(), page_location: pageLocation, page_title: pageTitle });
  };

  trackSuperPunchNavigation = (pageLocation: string, pageType: GaSpPageType) => {
    this.trackEvent("page_view", {
      ...this.getAmpliObject(),
      page_location: pageLocation,
      page_title: document.title,
      page_type: pageType,
    });
  };

  trackPricingPageTab = (tab: Feature) => {
    let pageLocation = `${window.location.origin}/billing/pricing/`;
    let pageTitle = "Plans & Pricing - ";

    switch (tab) {
      case Feature.TA:
        pageLocation += "time-and-attendance";
        pageTitle += "time and attendance";
        break;
      case Feature.Project:
        pageLocation += "project-time-tracking";
        pageTitle += "project time tracking";
        break;
      case Feature.Bundle:
        pageLocation += ""; // TODO
        pageTitle += "bundle";
        break;
      default:
        break;
    }

    pageLocation = encodeURI(pageLocation);

    this.trackEvent("page_view", { ...this.getAmpliObject(), page_location: pageLocation, page_title: pageTitle });
  };

  trackCreateAccount = (payload: GaCreateAccountPayload) => {
    this.trackEvent("sign_up", { ...this.getSignupUserAmpliObject(), ...payload });
  };

  trackLogin = (method: GaLoginMethod) => {
    this.trackEvent("login", { ...this.getAmpliObject(), method });
  };

  trackLogout = () => {
    this.trackEvent("logout", {});
  };

  trackCreateCompany = (payload: GaCreateCompanyPayload) => {
    this.trackEvent("sign_up_create_company", { ...this.getSignupUserAmpliObject(), ...payload });
  };

  trackSelectProduct = (isSelectedTa: boolean, isSelectedProject: boolean) => {
    let product: GaAmpliObject["product"] = "";

    if (isSelectedTa) {
      product = GaProduct.TA;
    }
    if (isSelectedProject) {
      product = isSelectedTa ? GaProduct.Bundle : GaProduct.Project;
    }

    this.trackEvent("select_product", {
      ...this.getAmpliObject(),
      product,
      user_role: PermissionRoleName.owner,
      plan: "Trial",
    });
  };

  trackSelectIntegration = (payload: GaSelectIntegrationPayload) => {
    this.trackEvent("select_integration", {
      ...this.getAmpliObject(),
      ...payload,
      user_role: PermissionRoleName.owner,
    });
  };

  trackInstallExtensionPopup = (status: GaInstallExtensionPopupStatus) => {
    this.trackEvent("install_extension_popup", { status });
  };

  trackClickAuthorizeIntegration = (integrationName: ApplicationType) => {
    this.trackEvent("integration_click_to_authorize", {
      integration_name: integrationName,
    });
  };

  trackIntegrationSuccess = (integrationName: ApplicationType) => {
    this.trackEvent("integration_authorize_success", {
      integration_name: integrationName,
    });
  };

  trackDownloadBrowserExtensionClick = (location: DownloadExtensionPage) => {
    this.trackEvent("download_browser_extension", {
      download_button_location: location,
    });
  };

  trackMassAction = (params: { numberOfEmployees: number; location: MassActionLocations }) => {
    const { numberOfEmployees, location } = params;
    this.trackEvent("mass_action", {
      number_of_employees_added: numberOfEmployees,
      mass_action_location: location,
    });
  };

  trackExploreApp = (location: DownloadExtensionPage) => {
    this.trackEvent("integration_click_to_explore", {
      download_button_location: location,
    });
  };

  trackClickExploreExternalApp = (location: DownloadExtensionPage) => {
    this.trackEvent("integration_click_to_explore_app", {
      download_button_location: location,
    });
  };

  trackSkipIntegration = (integrationName: ApplicationType, state: GaIntegrationState) => {
    this.trackEvent("integration_click_to_skip", {
      integration_name: integrationName,
      status: state,
    });
  };

  trackBeginCheckout = ({ currency, promotionCode, totalPrice, items }: GaBeginCheckoutPayload) => {
    const payload = {
      currency,
      coupon: promotionCode?.coupon?.name || "",
      value: totalPrice,
      items: items.map((item) => {
        const price = BillingService.calculateTotalPrice(item.selectedTier, items[0].price.interval, null);

        return {
          item_id: item.product.uuid,
          item_name: `${item.product.name} Professional`,
          item_brand: "day.io",
          coupon: promotionCode?.coupon?.name || "",
          discount: price * (promotionCode?.coupon?.percentOff || 0),
          price,
          quantity: 1,
        };
      }),
    };

    window.dataLayer.push({ ecommerce: null });
    this.trackEvent("begin_checkout", { ecommerce: payload });
  };

  trackPurchase = ({
    totalPrice,
    currency,
    promotionCode,
    items,
    activeSubscription,
    paymentType,
    paymentIntentId,
  }: GaPurchasePayload) => {
    const payload = {
      transaction_id: paymentIntentId,
      value: totalPrice.toFixed(2),
      currency,
      tax: 0, // TODO
      coupon: promotionCode?.coupon?.name || "",
      items: items.map((item) => {
        const price = BillingService.calculateTotalPrice(item.selectedTier, items[0].price.interval, null);

        return {
          item_id: item.product.uuid,
          item_name: `${item.product.name} Professional`,
          item_brand: "day.io",
          coupon: promotionCode?.coupon?.name || "",
          discount: price * (promotionCode?.coupon?.percentOff || 0),
          price,
          quantity: 1,
          currency: item.price.currency,
        };
      }),
      first_time_client: !activeSubscription ? GaBoolean.true : GaBoolean.false,
      payment_type: {
        [PaymentType.Card]: GaPurchasePaymentType.card,
        [PaymentType.Boleto]: GaPurchasePaymentType.boleto,
      }[paymentType],
    };

    window.dataLayer.push({ ecommerce: null });
    this.trackEvent("purchase", { ecommerce: payload });
  };

  trackEnterpriseLead = (feature: Feature) => {
    const leadProduct = {
      [Feature.TA]: GaProduct.TA,
      [Feature.Project]: GaProduct.Project,
      [Feature.Bundle]: GaProduct.Bundle,
    }[feature];

    this.trackEvent("enterprise_lead", { lead_product: leadProduct });
  };

  trackLead = (feature: Feature) => {
    const leadProduct = {
      [Feature.TA]: GaProduct.TA,
      [Feature.Project]: GaProduct.Project,
      [Feature.Bundle]: GaProduct.Bundle,
    }[feature];

    this.trackEvent("lead", { lead_product: leadProduct });
  };

  trackApprovePunch = (payload: GaApprovePunchPayload) => {
    this.trackEvent("punch_approved", payload);
  };

  trackCreatePunch = (payload: GaCreatePunchPayload) => {
    this.trackEvent("punch_create", payload);
  };

  trackClockNow = (punchType: PunchType) => {
    this.trackEvent("punch_clock_now", { button_location: "Web Tracker Box", punch_type: punchType });
  };

  trackChangePunchCategory = (payload: GaChangePunchCategory) => {
    this.trackEvent("punch_change_category", { button_location: "Web Tracker Box", ...payload });
  };

  trackChangePunchCompleted = (punchType: PunchType) => {
    this.trackEvent("punch_completed", { button_location: "Web Tracker Box", punch_type: punchType });
  };

  trackAddTime = (buttonLocation: GaAddTimeButtonLocation) => {
    this.trackEvent("time_tracking_add_time", { button_location: buttonLocation });
  };

  trackStartTimeTracking = (buttonLocation: GaStartTimeButtonLocation) => {
    this.trackEvent("time_tracking_start_time", { button_location: buttonLocation });
  };

  trackStopTimeTracking = (buttonLocation: GaStartTimeButtonLocation = GaStartTimeButtonLocation.punchNow) => {
    this.trackEvent("time_tracking_stop_tracking", { button_location: buttonLocation });
  };

  trackCreateNewProject = (payload: GaCreateNewProjectPayload) => {
    this.trackEvent("projects_create_new", payload);
  };

  trackRequestSubmit = (payload: GaRequestSubmitPayload) => {
    this.trackEvent("employees_request_submit", payload);
  };

  trackAddEmployees = (employeesCount: number) => {
    this.trackEvent("employees_add_employee", { number_of_employees_added: employeesCount });
  };

  trackSubmitTimesheet = () => {
    this.trackEvent("timesheet_submit", {});
  };

  trackTimesheetUpdateCellTime = () => {
    this.trackEvent("timesheet_update_cell_time", {});
  };

  trackGenerateReport = (reportType: ReportType) => {
    this.trackEvent("analytics_generate_report", { report_name: reportType });
  };

  initialDiscountClaimClick = () => {
    this.trackEvent("discount_click_to_claim", {});
  };

  initialDiscountCloseClick = () => {
    this.trackEvent("discount_click_to_close", {});
  };

  selectObjectivesClick = (objective: string) => {
    this.trackEvent("select_objectives", { ...this.getAmpliObject(), objective });
  };
}

const ga = new GoogleAnalytics();

export default ga;
