import { BillingService } from "components/Billing/BillingService";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { Feature } from "types/models/subscription";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionSectionName } from "utils/common";
import { LocalStorageKeys, getObjectFromLocalStorage } from "utils/localStorageUtils";
import * as Icons from "./menu-svg-icons";

const Wrapper = styled.div`
  width: 260px;
`;

const MenuSection = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid var(--colors-surface-150);
`;

const MenuItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

const SectionName = styled.div`
  font-size: 13px;
  color: var(--colors-surface-600);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  line-height: 18px;
  cursor: default;
`;

const DashboardsSubMenu = () => {
  const { t } = useTranslation([TranslationNamespaces.sidebar]);
  const location = useLocation();
  const dashboardNames: string[] = getObjectFromLocalStorage(LocalStorageKeys.companyDashboards) || [];

  const getSubMenuOptions = () => {
    const getTASection = () => {
      const options = dashboardNames.reduce((acc, name) => {
        acc.push({
          name: t(`${name}`),
          path: `/dashboard/${name}`,
          permissionSection: PermissionSectionName.dashboard,
        });

        return acc;
      }, []);

      return {
        name: t("Time & Attendance"),
        options,
      };
    };

    const options = [];
    const taSection = getTASection();
    if (BillingService.checkFeatureAccess(Feature.TA) && taSection.options.length) options.push(taSection);

    return options;
  };

  return (
    <Wrapper>
      {getSubMenuOptions().map((g) => (
        <MenuSection key={g.name}>
          <SectionName>{g.name}</SectionName>

          {g.options.map((o) => (
            <MenuItem key={o.name} to={o.path} onClick={(e) => e.stopPropagation()}>
              {o.name}
              {o.path === location.pathname && <Icons.CheckMark />}
            </MenuItem>
          ))}
        </MenuSection>
      ))}
    </Wrapper>
  );
};

export default DashboardsSubMenu;
