import { stylesheet } from "astroturf";
import { ActivitiesList } from "../ActivitiesList";
import { RunningActivityCard } from "../RunningActivityCard";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
`;

export function ActivitiesView(props) {
  const {
    runningActivity,
    days,
    projects,
    loadDays,
    hasMoreDays,
    loadingDays,
    loading,
    onStartActivity,
    onEditActivity,
    onAddActivity,
    onRepeatActivity,
    onStopActivity,
    today,
    duration,
    onExpandActivitiesGroup,
  } = props;
  return (
    <div className={styles.Wrapper}>
      {runningActivity != null && !(days.length === 0 && loadingDays) && (
        <RunningActivityCard
          client={runningActivity.project?.client?.name}
          task={runningActivity.task?.name}
          project={runningActivity.project?.name}
          duration={duration}
          onStop={() => onStopActivity({ _raw: runningActivity })}
          onEdit={() => onEditActivity({ _raw: runningActivity })}
        />
      )}
      <ActivitiesList
        today={today}
        days={days}
        projects={projects}
        loadDays={loadDays}
        hasMoreDays={hasMoreDays}
        loading={loadingDays}
        onEditItem={onEditActivity}
        onRepeatItem={loading ? null : onRepeatActivity}
        onStart={loading ? null : onStartActivity}
        onAdd={loading ? null : onAddActivity}
        showAddActivityButtons={runningActivity == null && !(days.length === 0 && loadingDays)}
        onExpandActivitiesGroup={onExpandActivitiesGroup}
      />
    </div>
  );
}
