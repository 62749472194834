import { useEffect, useRef, useState } from "react";
import { Expand } from "components/views/Expand";
import { ProjRes } from "services/oitchau-api";
import styled from "styled-components";
import { StoreApi, UseBoundStore } from "zustand/esm";
import { AddTaskRow } from "./AddTaskRow";
import { ChevronRight } from "./TimerButtons";
import { ProjectOption } from "./TaskOptions";
import { TrackTimeStore } from "../TrackTime.store";

const ProjectRowWrapper = styled.div`
  width: 100%;
  .ProjectRow {
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    cursor: pointer;
    & > svg path {
      fill: var(--colors-surface-500);
    }
    &:hover {
      svg path {
        fill: var(--colors-surface-900-p);
      }
    }
    &.expanded {
      background: var(--colors-surface-50);
    }
    .Title {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      font-size: var(--typography-font-size-default);
      height: 52px;
      .Name {
        display: flex;
        align-items: center;
        flex: 1 0 0;
        color: var(--colors-surface-900-p);
        font-weight: var(--typography-font-weight-medium);
        .Client {
          color: var(--colors-surface-500);
          font-weight: var(--typography-font-weight-default);
        }
      }
    }
    &.simple {
      border: none;
      .Title {
        height: 46px;
        background: var(--colors-surface-50);
      }
    }
  }
  .SearchResults {
    margin: 4px 0;
  }
`;

export const ProjectRow = ({
  project,
  children,
  withTasksSelection,
  simple,
  selectedProjectUuid,
  onCreateTask,
  swapChildren,
  canAddTask,
  store,
}: {
  project: ProjectOption | null;
  withTasksSelection?: boolean;
  simple?: boolean;
  selectedProjectUuid?: string;
  onCreateTask: (taskName: string, project: ProjRes) => void;
  swapChildren?: boolean;
  canAddTask: boolean;
  children: any;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
}) => {
  const [expanded, setExpanded] = useState(!!(selectedProjectUuid && selectedProjectUuid === project?.project.uuid));
  const rowRef = useRef(null);
  useEffect(() => {
    if (selectedProjectUuid && !expanded && selectedProjectUuid === project?.project.uuid) {
      rowRef?.current && rowRef.current?.scrollIntoView({ block: "center" });
      setExpanded(true);
    }
  }, [selectedProjectUuid]);

  return (
    <>
      <ProjectRowWrapper>
        <div
          ref={rowRef}
          className={`ProjectRow ${expanded ? "expanded" : ""} ${simple ? "simple" : ""}`}
          onClick={() => setExpanded(!expanded)}
        >
          <div className="Title">
            <div className="Name">
              <div>{project?.project?.name}</div>
              {project?.project?.client?.name != null && (
                <div className="Client">&nbsp;/&nbsp;{project?.project.client.name}</div>
              )}
            </div>
            {withTasksSelection && (
              <div style={{ rotate: expanded ? "90deg" : "0deg" }}>
                <ChevronRight />
              </div>
            )}
          </div>
        </div>
        {withTasksSelection && !simple && (
          <Expand className="Tasks" expanded={expanded}>
            {!swapChildren && canAddTask && (
              <AddTaskRow project={project?.project} nested onCreateTask={onCreateTask} store={store} />
            )}
            {children}
            {swapChildren && canAddTask && (
              <AddTaskRow project={project?.project} nested onCreateTask={onCreateTask} store={store} />
            )}
          </Expand>
        )}
        {simple && children}
      </ProjectRowWrapper>
    </>
  );
};
