import ConfirmationModal from "components/UI/ConfirmationModalNew";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useState } from "react";
import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox/NewCheckbox";
import { PermissionAction, PermissionSection, PermissionSectionName } from "types/models/permissions";

const Wrapper = styled.div`
  padding: 24px 0;
  .timesheet-settings {
    max-width: inherit;
    margin-top: 0;

    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

interface PermissionActionsModalProps {
  onClose: () => void;
  onSave: (permissionActions: PermissionAction[]) => void;
  permission: PermissionSection;
}

const PermissionActionsModal = ({ onClose, permission, onSave }: PermissionActionsModalProps) => {
  const { t } = useTranslation([TranslationNamespaces.employeesPermissions, TranslationNamespaces.sidebar]);
  const [permActions, setPermActions] = useState(
    permission.permission_actions.sort((a, b) => {
      if (b.name > a.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }),
  );
  const [isDirty, setIsDirty] = useState(false);

  return (
    <ConfirmationModal
      isOpen
      title={t("permissions-actions-modal-title", { section: t(permission.name) })}
      onClose={onClose}
      yesDisabled={!isDirty}
      onYes={() => onSave(permActions)}
      btnYesTitle={t(`${TranslationNamespaces.common}|Save`)}
      btnCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
      width={484}
    >
      <Wrapper>
        {permActions.map((pa) => (
          <CheckboxControl
            css={{ marginBottom: "16px" }}
            checked={pa.allow}
            label={
              permission.name === PermissionSectionName.dashboard
                ? t(`${TranslationNamespaces.sidebar}|${pa.name}`)
                : t(pa.name)
            }
            onChange={(val) => {
              setIsDirty(true);
              setPermActions(
                permActions.map((p) => {
                  if (p.uuid === pa.uuid) {
                    return { ...p, allow: val };
                  }
                  return p;
                }),
              );
            }}
          />
        ))}
      </Wrapper>
    </ConfirmationModal>
  );
};

export default PermissionActionsModal;
