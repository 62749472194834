import { useCallback, useEffect, useMemo, useState } from "react";
import { Popup } from "@mobiscroll/react";
import { useTranslation, withTranslation } from "react-i18next";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import CheckboxControl from "components/UI/NewCheckbox";
import { TranslationNamespaces } from "types/translationNamespaces";
import RadioButton from "components/UI/RadioButton";
import TimeControl from "components/controls/TimeControl";
import { hrsMinstoMins, minsToHrsMins } from "utils/common";
import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";
import { BreakStatus } from "utils/api/types";
import Tooltip from "components/UI/Tooltip";
import { breaksAutomaticTooltipIcon, breaksDurationTooltipIcon } from "components/svg-images";
import TextInputControl from "components/controls/StyledTextInput";
import {
  BreakModalWrapper,
  BreaksTooltipIconWrapper,
  ButtonsWrapper,
  CheckboxWrapper,
  ErrorsWrapper,
  RadioButtonsWrapper,
  RangeWrapper,
  Title,
} from "./styled";
import { BREAK_TIMES, TIME_PLACEHOLDER } from "./constants";
import { useBreaks } from "./hooks/useBreaks";

const FLEXIBLE_BREAKS_MAX = 15;
const DAY = 24 * 60;

export enum BasedOn {
  range = "range",
  duration = "duration",
  flexible = "flexible",
}

interface TimeObject {
  value: string;
  error: boolean;
}

export interface DayInfoBreak {
  breakDuration: number;
  automatic: boolean;
  breakMode: BasedOn;
  breakTypeName: string;
  breakTypeUuid: string;
  startAfter?: number;
  start: TimeObject;
  end: TimeObject;
  eventsCount?: number;
}

export interface DayInfo {
  id: number;
  entry?: TimeObject;
  exit?: TimeObject;
  breaks: DayInfoBreak[];
  isDsrDay: boolean;
  extraNight: number;
  hoursPerDay?: {
    value: string;
    error: boolean;
    raw: number;
  };
}

interface AddBreakModalProps {
  isOpen: boolean;
  anchor?: HTMLElement;
  selectedBreakId: number | null;
  dayInfo?: DayInfo;
  onClose: () => void;
  onSave: (data: any) => void;
}

const statementToBinary = (statement: boolean) => String(Number(statement));
const getTimeValue = (value: number | null) => (value !== null ? minsToHrsMins(value) : "");

const AddBreakModal = ({ isOpen, dayInfo, selectedBreakId, anchor, onSave, onClose }: AddBreakModalProps) => {
  const { t } = useTranslation("schedules");

  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customBreakId, setBreakId] = useState<string>("");
  const [breakMode, setBreakMode] = useState<BasedOn>(BasedOn.duration);
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [breakDurationTime, setBreakDurationMins] = useState<string | null>("60");
  const [startAfter, setStartAfter] = useState<number | null>(null);
  const [automatic, setAutomatic] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [breaksCount, setBreaksCount] = useState<string>("");
  const { data: breaks, isLoading } = useBreaks();

  const isFormLoading = useMemo(() => loading || isLoading, [loading, isLoading]);

  const { extraNight, entry, exit } = dayInfo ?? { extraNight: 0, entry: { value: null }, exit: { value: null } };

  const entryValue = entry?.value;
  const exitValue = exit?.value;

  const isCrossdayNightshift = useMemo(() => {
    const entryTime = entryValue ? hrsMinstoMins(entryValue) : null;
    const exitTime = exitValue ? hrsMinstoMins(exitValue) : null;

    return entryTime !== null && exitTime !== null && extraNight !== 0 && exitTime <= entryTime;
  }, [entryValue, exitValue, extraNight]);

  const isFlexibleSchedule = useMemo(() => !!dayInfo?.hoursPerDay, [dayInfo]);

  const breakOptionList = useMemo(() => {
    const result = breaks
      .filter((b) => b.status === BreakStatus.active || b.uuid === customBreakId)
      .map((item) => ({ label: item.default ? t(item.name) : item.name, value: item.uuid }));

    return result;
  }, [t, breaks, customBreakId]);

  const breakTimesOptionList = useMemo(
    () => BREAK_TIMES.map((item) => ({ label: item.label, value: String(item.value) })),
    [],
  );
  const isDurationDisabled = useMemo(() => to === null || from === null, [from, to]);

  const fromToDifference = useMemo(() => {
    let result = null;
    if (!isDurationDisabled && from !== null && to !== null) {
      const toLessFrom = to < from;
      const toTime = toLessFrom ? to + DAY : to;
      result = toTime - from;
    }
    return result;
  }, [from, isDurationDisabled, to]);

  const isDurationLessInfo = fromToDifference !== null && duration !== null && duration < fromToDifference;

  const reset = (isFullReset = false) => {
    if (isFullReset) {
      setBreakId("");
      setAutomatic(false);
      setBreakMode(BasedOn.range);
    }
    setFrom(null);
    setTo(null);
    setDuration(null);
    setBreakDurationMins("60");
    setStartAfter(null);
    setBreaksCount("");
    setErrors({});
  };

  useEffect(() => {
    if (isOpen && dayInfo) {
      setAutomatic(false);
      setBreakMode(BasedOn.range);
      setFrom(null);
      setTo(null);
      setDuration(null);
      setBreakDurationMins("60");
      setStartAfter(null);
    }
  }, [isOpen, dayInfo]);

  useEffect(() => {
    if (isOpen && !customBreakId) {
      const defaultBreak = breaks.find((item) => item.default);
      if (defaultBreak) {
        setBreakId(defaultBreak.uuid);
      }
    }
  }, [isOpen, breaks, customBreakId]);

  const dayInfoBreaks = dayInfo?.breaks;

  useEffect(() => {
    if (isOpen && dayInfoBreaks && selectedBreakId !== null) {
      const editBreak = dayInfoBreaks[selectedBreakId];
      if (editBreak) {
        setBreakId(editBreak.breakTypeUuid);
        setAutomatic(editBreak.automatic);
        if (editBreak.eventsCount) {
          setBreaksCount(editBreak.eventsCount ? editBreak.eventsCount.toString() : "");
        }
        setBreakMode(editBreak.breakMode ? editBreak.breakMode : BasedOn.range);

        if (editBreak.start.value) {
          setFrom(hrsMinstoMins(editBreak.start.value));
        }
        if (editBreak.end.value) {
          setTo(hrsMinstoMins(editBreak.end.value));
        }
        if (editBreak.startAfter !== undefined) {
          setStartAfter(editBreak.startAfter);
        }
        setDuration(editBreak.breakDuration);
        setBreakDurationMins(String(editBreak.breakDuration));
      }
    }
  }, [isOpen, breaks, selectedBreakId, dayInfoBreaks]);

  useEffect(() => {
    if (!isOpen) {
      reset(true);
    }
  }, [isOpen]);

  const onBasedChange = (v: BasedOn) => {
    setBreakMode(v);
    reset();
  };

  const validateBasedOnRange = () => {
    const nightshift = isCrossdayNightshift ? DAY : 0;

    const errorsObj: Record<string, string> = {};
    const entryTime = dayInfo?.entry?.value ? hrsMinstoMins(dayInfo.entry.value) : null;
    const exitTime = dayInfo?.exit?.value ? hrsMinstoMins(dayInfo.exit.value) + nightshift : null;

    if (from === null) {
      errorsObj.from = t("Required");
    }
    if (to === null) {
      errorsObj.to = t("Required");
    }
    if (duration === null || duration === 0) {
      errorsObj.duration = t("Required");
    }
    if (
      fromToDifference !== null &&
      duration !== null &&
      duration > 0 &&
      fromToDifference > 0 &&
      duration > fromToDifference
    ) {
      errorsObj.durationGreaterRange = t("Break Duration cannot be greater than the Range");
    }

    if (from !== null && to !== null && !isCrossdayNightshift) {
      if (to <= from) {
        errorsObj.toEntry = t("Break End Time cannot be before Break Start Time");
      }
    }

    if (entryTime !== null && exitTime !== null && from !== null && to !== null) {
      if (!isCrossdayNightshift) {
        if (from < entryTime) {
          errorsObj.fromEntry = t("Break Start Time cannot be before Entry Time");
        }
        if (from > exitTime) {
          errorsObj.fromEntry = t("Break Start Time cannot be after Exit Time ");
        }
        if (to < entryTime) {
          errorsObj.toEntry = t("Break End Time cannot be before Entry Time");
        }
        if (to > exitTime) {
          errorsObj.toEntry = t("Break End Time cannot be after Exit Time");
        }
      } else {
        const fromLessEntry = statementToBinary(from < entryTime);
        const fromLessExit = statementToBinary(from < exitTime);
        const toLessEntry = statementToBinary(to < entryTime);
        const toLessExit = statementToBinary(to < exitTime);
        const fromLessTo = statementToBinary(from < to);
        const stringNumber = `${fromLessEntry}${fromLessExit}${toLessEntry}${toLessExit}${fromLessTo}`;

        switch (stringNumber) {
          // case "01000": impossible 01***
          // case "01001": impossible 01***
          // case "01011": impossible 01***
          // case "01100": impossible 01***
          // case "01101": impossible 01***
          // case "01110": impossible 01***
          // case "01111": impossible 01***
          // case "01010": impossible 01***
          // case "00010": impossible **01*
          // case "00011": impossible **01*
          // case "10010": impossible **01*
          // case "10011": impossible **01*
          // case "11010": impossible **01*
          // case "11011": impossible **01*
          // case "11100": impossible
          // case "10000": impossible
          // case "10111": impossible
          // case "00111": impossible
          // case "00101": impossible

          case "10110":
            errorsObj.fromEntry = t("Break Start Time cannot be before Entry Time");
            errorsObj.toEntry = t("Break End Time cannot be before Break Start Time");
            break;
          case "10001":
            errorsObj.fromEntry = t("Break Start Time cannot be before Entry Time");
            break;
          case "10101": {
            if (from - exitTime < entryTime - to) {
              errorsObj.fromEntry = t("Break Start Time cannot be after Exit Time");
              errorsObj.toEntry = t("Break End Time cannot be after Exit Time");
            } else {
              errorsObj.fromEntry = t("Break Start Time cannot be before Entry Time");
              errorsObj.toEntry = t("Break End Time cannot be before Entry Time");
            }

            break;
          }
          case "00100":
          case "10100":
          case "11000":
          case "11001":
          case "11101":
            errorsObj.toEntry = t("Break End Time cannot be after Exit Time");
            break;
          case "00000":
          case "11110":
            errorsObj.toEntry = t("Break End Time cannot be before Break Start Time");
            break;
          case "00001":
          case "11111":
          case "00110":
          default:
            break;
        }
      }
    }

    return errorsObj;
  };

  const validateBasedOnDuration = () => {
    const nightshift = isCrossdayNightshift ? DAY : 0;

    const errorsObj: Record<string, string> = {};
    const entryTime = dayInfo?.entry?.value ? hrsMinstoMins(dayInfo.entry.value) : null;
    const exitTime = dayInfo?.exit?.value ? hrsMinstoMins(dayInfo.exit.value) + nightshift : null;
    const durationMinutes = breakDurationTime ? Number(breakDurationTime) : 0;
    const startAfterValue = startAfter === null || startAfter === undefined ? 0 : startAfter;

    if (isFlexibleSchedule && startAfter !== null) {
      const { raw } = dayInfo?.hoursPerDay ?? { raw: null };

      if (raw) {
        if (startAfter > raw) {
          errorsObj.startAfter = t(`Start After cannot be more than Hours Per Day`);
        }
        if (durationMinutes > raw) {
          errorsObj.breakDuration = t(`Duration cannot be more than Hours Per Day`);
        }
      }
    }

    if (automatic && !startAfter) {
      errorsObj.startAfter = t("Required");
    }

    if (entryTime !== null && exitTime !== null) {
      if (entryTime + startAfterValue + durationMinutes > exitTime) {
        const timeSum = entryTime + startAfterValue + durationMinutes;
        const errorTime = getTimeValue(timeSum > DAY ? timeSum - DAY : timeSum);
        if (startAfter !== 0) {
          errorsObj.startAfter = t(`break-end-time-error`, { time: errorTime });
        } else {
          errorsObj.duration = t(`break-end-time-error`, { time: errorTime });
        }
      }
    }

    return errorsObj;
  };

  const validateBasedOnFlexible = () => {
    const errorsObj: Record<string, string> = {};

    let isBreakAlreadyUsed = false;

    if (breakMode === BasedOn.flexible) {
      const usedBreakIndex = (dayInfoBreaks || []).findIndex(
        (item) => item.breakMode === BasedOn.flexible && item.breakTypeUuid === customBreakId,
      );

      isBreakAlreadyUsed =
        (selectedBreakId === null && usedBreakIndex > -1) ||
        (selectedBreakId !== null && usedBreakIndex > -1 && selectedBreakId !== usedBreakIndex);
    }

    if (isBreakAlreadyUsed) {
      errorsObj.breaksCount = t("break-type-in-use-error");
    } else if (breaksCount === "0") {
      errorsObj.breaksCount = t("flexible-break-error-0");
    } else if (!breaksCount) {
      errorsObj.breaksCount = t("Required");
    } else if (parseInt(breaksCount, 10) > FLEXIBLE_BREAKS_MAX) {
      errorsObj.breaksCount = t("flexible-break-error-max", { max: FLEXIBLE_BREAKS_MAX });
    }

    return errorsObj;
  };

  const validate = () => {
    let errorsDict: Record<string, string> = {};

    // check if break type is already used with another break mode
    let isBreakAlreadyUsed = false;

    const usedBreakIndex = (dayInfoBreaks || []).findIndex(
      (item) => item.breakTypeUuid === customBreakId && item.breakMode !== breakMode,
    );
    const usedBreaksCount = (dayInfoBreaks || []).filter(
      (item) => item.breakTypeUuid === customBreakId && item.breakMode !== breakMode,
    ).length;

    isBreakAlreadyUsed =
      usedBreaksCount > 1 ||
      (selectedBreakId === null && usedBreakIndex > -1) ||
      (selectedBreakId !== null && usedBreakIndex > -1 && selectedBreakId !== usedBreakIndex);

    if (isBreakAlreadyUsed) {
      errorsDict.customBreakId = t("one-mode-per-break-type-error");
    }

    if (Object.keys(errorsDict).length === 0) {
      switch (breakMode) {
        case BasedOn.range:
          errorsDict = validateBasedOnRange();
          break;
        case BasedOn.duration:
          errorsDict = validateBasedOnDuration();
          break;
        case BasedOn.flexible:
          errorsDict = validateBasedOnFlexible();
          break;
        default:
          break;
      }
    }

    const isValid = Object.keys(errorsDict).length === 0;

    setErrors(errorsDict);

    return isValid;
  };

  const handleClose = () => {
    reset(true);
    onClose();
  };

  const handleSave = () => {
    setLoading(true);
    const isValid = validate();
    if (isValid) {
      const isRange = breakMode === BasedOn.range;
      const breakOption = breaks.find((item) => item.uuid === customBreakId);
      let _duration = null;
      switch (breakMode) {
        case BasedOn.duration:
          _duration = Number(breakDurationTime);
          break;
        case BasedOn.range:
          _duration = duration;
          break;
        case BasedOn.flexible:
          _duration = null;
          break;

        default:
          break;
      }

      const data = {
        start: {
          value: isRange ? getTimeValue(from) : null,
          error: false,
        },
        end: {
          value: isRange ? getTimeValue(to) : null,
          error: false,
        },
        automatic,
        breakMode,
        startAfter,
        breakDuration: _duration,
        breakTypeUuid: breakOption?.uuid,
        breakTypeName: breakOption?.name,
        isWorkingHours: breakOption?.isWorkingHours,
        eventsCount: BasedOn.flexible ? parseInt(breaksCount, 10) : undefined,
      };

      onSave(data);
      handleClose();
    }

    setLoading(false);
  };

  const recalculateHeight = useCallback(() => {
    if (instance && instance.position && instance._popup) {
      instance.position();
    }
  }, [instance]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        recalculateHeight();
      }, 200);
    }
  }, [isOpen, breakMode, isDurationLessInfo, errors]);

  return (
    <Popup
      ref={setInstance}
      display="anchored"
      anchor={anchor}
      showArrow={false}
      scrollLock={false}
      isOpen={isOpen}
      maxHeight={600}
      width={452}
      onClose={onClose}
      cssClass="PopupWrapper BreakPopup"
      rtl={window.global_store.isRTL}
    >
      <BreakModalWrapper id="BreakModal">
        <Title>{t("Add break")}</Title>

        <FieldWrapper fieldName={t("Type")} wrapperWidth="100%" width="100%">
          <Select
            value={isFormLoading ? t("Loading...") : customBreakId}
            onChange={(val) => {
              setBreakId(val);
            }}
            modifiers={{ field: true }}
            options={breakOptionList}
            disabled={isFormLoading}
          />
        </FieldWrapper>
        {!!errors?.customBreakId && <ErrorLabel>{errors.customBreakId}</ErrorLabel>}

        <FieldWrapper fieldName={t("Choose the Break")}>
          <RadioButtonsWrapper>
            <RadioButton
              value={breakMode}
              checked={breakMode === BasedOn.range}
              label={t("Range")}
              id="break-based-on-range"
              name="break-based-on"
              onChange={() => {
                onBasedChange(BasedOn.range);
              }}
              disabled={false}
            />
            <RadioButton
              value={breakMode}
              checked={breakMode === BasedOn.duration}
              label={t("Duration")}
              id="break-based-on-duration"
              name="break-based-on"
              onChange={() => {
                onBasedChange(BasedOn.duration);
              }}
              disabled={false}
            />
            <RadioButton
              value={breakMode}
              checked={breakMode === BasedOn.flexible}
              label={t("Flexible")}
              id="break-based-on-flexible"
              name="break-based-on"
              onChange={() => {
                onBasedChange(BasedOn.flexible);
                setAutomatic(false);
                setBreakMode(BasedOn.flexible);
                setFrom(null);
                setTo(null);
                setDuration(null);
                setBreakDurationMins(null);
                setStartAfter(null);
                setErrors({});
              }}
              disabled={false}
            />
          </RadioButtonsWrapper>
        </FieldWrapper>

        {breakMode === BasedOn.range && (
          <>
            <RangeWrapper>
              <FieldWrapper fieldName={t("From")} className="range-input-field">
                <TimeControl
                  modifiers={{ schedule: true }}
                  placeholder={TIME_PLACEHOLDER}
                  onTimeChange={(val) => {
                    if (val.error) {
                      return;
                    }

                    const v = hrsMinstoMins(val.value);
                    if (v === from) {
                      return;
                    }

                    setFrom(v);
                    setErrors({});
                    if (v !== null && to !== null) {
                      let dif = to - v;

                      if (isCrossdayNightshift && dif < 0) {
                        dif += DAY;
                      }
                      setDuration(dif);
                    }
                  }}
                  error={!!errors.from || !!errors.fromEntry}
                  value={getTimeValue(from)}
                />
              </FieldWrapper>
              <FieldWrapper fieldName={t("To")} className="range-input-field">
                <TimeControl
                  modifiers={{ schedule: true }}
                  placeholder={TIME_PLACEHOLDER}
                  onTimeChange={(val) => {
                    if (val.error) {
                      return;
                    }

                    const v = hrsMinstoMins(val.value);
                    if (v === to) {
                      return;
                    }

                    setTo(v);
                    setErrors({});
                    if (v !== null && from !== null) {
                      let dif = v - from;
                      if (isCrossdayNightshift && dif < 0) {
                        dif += DAY;
                      }
                      setDuration(dif);
                    }
                  }}
                  error={!!errors.to || !!errors.toEntry}
                  value={getTimeValue(to)}
                />
              </FieldWrapper>
              <FieldWrapper fieldName={t("Duration")} className="range-input-field">
                <TimeControl
                  disabled={isDurationDisabled}
                  modifiers={{ schedule: true }}
                  error={!!errors.duration || !!errors.durationGreaterRange}
                  onTimeChange={(val) => {
                    if (val.error) {
                      return;
                    }
                    const v = hrsMinstoMins(val.value);
                    if (v === duration) {
                      return;
                    }

                    setErrors({});
                    setDuration(v);
                  }}
                  value={getTimeValue(duration)}
                />
              </FieldWrapper>
            </RangeWrapper>
          </>
        )}

        {breakMode === BasedOn.duration && (
          <RangeWrapper>
            <FieldWrapper fieldName={t("Duration")} className="duration-input-field">
              <Select
                modifiers={{ field: true }}
                value={breakDurationTime}
                onChange={(val) => {
                  setBreakDurationMins(val);
                }}
                options={breakTimesOptionList}
              />
              {!!errors.breakDuration && <ErrorLabel>{errors.breakDuration}</ErrorLabel>}
            </FieldWrapper>
            <FieldWrapper
              fieldName={`${t("Start After")} ${!automatic ? t("(Optional)") : ""}`}
              className="duration-input-field start-after-input-field"
              tooltip={
                <>
                  <BreaksTooltipIconWrapper data-tip data-for="duration-breaks-tooltip">
                    {breaksDurationTooltipIcon}
                  </BreaksTooltipIconWrapper>
                  <Tooltip
                    id="duration-breaks-tooltip"
                    wrapperStyle={{
                      maxWidth: "200px",
                      zIndex: 100000,
                    }}
                  >
                    {t("This is the time from your punch in after which your break will be suggested for starting")}
                  </Tooltip>
                </>
              }
            >
              <TimeControl
                modifiers={{ schedule: true }}
                placeholder={TIME_PLACEHOLDER}
                error={!!errors.startAfter}
                onTimeChange={(val) => {
                  const v = hrsMinstoMins(val.value);
                  setErrors({});
                  setStartAfter(v);
                }}
                value={getTimeValue(startAfter)}
              />

              <ErrorsWrapper>{!!errors.startAfter && <ErrorLabel>{errors.startAfter}</ErrorLabel>}</ErrorsWrapper>
            </FieldWrapper>
          </RangeWrapper>
        )}

        {breakMode === BasedOn.flexible && (
          <RangeWrapper>
            <FieldWrapper fieldName={t("Breaks amount")} className="duration-input-field">
              <TextInputControl
                value={breaksCount}
                placeholder={t("Breaks amount")}
                error={!!errors?.breaksCount}
                onChange={(val) => {
                  setBreaksCount(val ? val.replace(/\D/g, "") : "");
                }}
              />
              {!!errors.breaksCount && <ErrorLabel>{errors.breaksCount}</ErrorLabel>}
            </FieldWrapper>
          </RangeWrapper>
        )}

        <ErrorsWrapper>
          {!!errors.fromEntry && <ErrorLabel>{errors.fromEntry}</ErrorLabel>}
          {!!errors.toEntry && <ErrorLabel>{errors.toEntry}</ErrorLabel>}
          {!!errors.duration && <ErrorLabel>{errors.duration}</ErrorLabel>}
          {!!errors.durationGreaterRange && <ErrorLabel>{errors.durationGreaterRange}</ErrorLabel>}
        </ErrorsWrapper>

        {isDurationLessInfo && (
          <NotificationRow
            style={{ gap: "8px" }}
            aligned
            employeesPage
            withCloseButton
            type={NotificationType.infoBlue}
            message={t("This will affect early start and late end of breaks")}
          />
        )}

        {breakMode !== BasedOn.flexible && (
          <FieldWrapper>
            <CheckboxWrapper>
              <CheckboxControl small ios checked={automatic} label={t("Setup as Automatic")} onChange={setAutomatic} />
              <BreaksTooltipIconWrapper data-tip data-for="automatic-breaks-tooltip">
                {breaksAutomaticTooltipIcon}
              </BreaksTooltipIconWrapper>
              <Tooltip
                id="automatic-breaks-tooltip"
                wrapperStyle={{
                  maxWidth: "200px",
                  zIndex: 100000,
                }}
              >
                {t(
                  "With this feature activated, punches for this break will automatically be registered for all members assigned to this Schedule.",
                )}
              </Tooltip>
            </CheckboxWrapper>
          </FieldWrapper>
        )}

        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={isFormLoading}
            state={ButtonState.secondary}
            onClick={handleClose}
          />
          <Button
            value={t(`${TranslationNamespaces.schedules}|Add Break`)}
            state={ButtonState.primary}
            disabled={isFormLoading}
            onClick={handleSave}
            loading={isFormLoading}
          />
        </ButtonsWrapper>
      </BreakModalWrapper>
    </Popup>
  );
};

export default withTranslation(TranslationNamespaces.schedules)(AddBreakModal);
