import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as momentTz from "moment-timezone";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { getDateColumn, getDateCellData } from "utils/reportsHelpers";
import Pagination from "components/Pagination";
import ReportUserInfo from "./ReportUserInfo";
import TablePage from "../TablePage";
import "styles/reports-regular-table.scss";
import { sliceReportIntoChunks } from "./helpers";

const b = BEM.b("reports-regular-table");

class ReportsPunchesTable extends Component {
  state = {
    employeeInfo: {},
    maxPunchesCount: 0,
    isFetching: false,
    dates: [[]],
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData(this.props);
  }

  getReportData = async (props) => {
    const { perPage } = this.state;
    const { searchObj, startDate, endDate, oldReport, setIsLoading } = props;
    const companyUUID = window.global_store.company.uuid;

    if (searchObj && startDate && endDate && companyUUID) {
      this.setState({ isFetching: true }, () => setIsLoading(true));

      try {
        const { dates, ...resp } = await getReport({
          UserProfileUUID: searchObj.employee.uuid,
          startDate,
          endDate,
          companyUUID,
          type: "regular",
          oldReport,
        });

        const chunks = sliceReportIntoChunks(dates, perPage);

        this.setState({
          ...resp,
          dates: chunks.length ? chunks : [[]],
          totalRecords: dates.length,
          isFetching: false,
        });
      } catch (err) {
        console.log("Error", err);
        this.setState({ error: this.props.t("Failed to generate report") });
      } finally {
        setIsLoading(false);
      }
    }
  };

  onPageLimitChange = (pageLimit) => {
    const { dates } = this.state;

    this.setState({ page: 1, perPage: pageLimit, dates: sliceReportIntoChunks(dates.flat(), pageLimit) });
  };

  render() {
    const { isFetching, error, dates, employeeInfo, maxPunchesCount, page, perPage, totalRecords } = this.state;
    const { searchObj, startDate, endDate, t } = this.props;
    let lastScheduleName;

    if (error) {
      return <div>{error}</div>;
    }

    const data = dates[page - 1].map((d) => {
      const dd = {
        date: getDateCellData(d),
        status: d.status,
      };

      if (d.schedule) {
        lastScheduleName = d.schedule.name;
      }

      if (d.punches && d.punches.length > 0) {
        d.punches.map((p, i) => {
          dd[`punch${i + 1}`] = p;
          return p;
        });
      }

      return dd;
    });

    let columns = [getDateColumn(t)];
    columns = columns.concat(
      Array.from(Array(maxPunchesCount)).map((x, i) => ({
        Header: `${t("Punch ")}${i + 1}`,
        align: "right",
        accessor: `punch${i + 1}`,
        Cell: (row) =>
          row.value ? (
            <div className={b("punch")}>
              <div className={b("punch-time")}>{momentTz.tz(row.value.time, row.value.timezone).format("HH:mm")}</div>
              <div className={b("punch-desc")}>{t(row.value.description)}</div>
            </div>
          ) : (
            ""
          ),
      })),
    );

    return (
      <div className={b()}>
        <TablePage
          withHeaderTooltip
          tableDatailsClassName="table-details-ai-start"
          tableDetails={<ReportUserInfo employeeInfo={{ ...employeeInfo, schedule: lastScheduleName }} />}
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  searchObj,
                  startDate,
                  endDate,
                  oldReport: this.props.oldReport,
                  reportType: "regular",
                })
              }
            />
          }
          loading={isFetching}
          rows={data}
          interactive={false}
          customColumnsAvailable={false}
          columns={columns}
          className="employee-table"
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("reports-page")(ReportsPunchesTable));
