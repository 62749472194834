import styled from "styled-components";
import ButtonComponent from "components/UI/ButtonComponent";
import IconPlus from "@iconscout/react-unicons/icons/uil-plus";

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
`;

type DropdownFooterProps = {
  onClick: () => void;
  label: string;
};

const DropdownFooter = ({ onClick, label }: DropdownFooterProps) => (
  <Footer>
    <ButtonComponent size="small" variant="tertiary" startIcon={<IconPlus />} onClick={onClick} label={label} />
  </Footer>
);

export default DropdownFooter;
