import { Component } from "react";
import { ErrorView } from "./ErrorView";

interface ErrorBoundaryProps {
  /**
   * Errors caught by error boundary are still logged by react as "Uncaugh Error".
   * There is no way to disable it: https://github.com/facebook/react/issues/15069 (as of 2022-aug-11).
   * Don't log the error in `onError` to avoid duplicated logging.
   */
  onError?: ((error: any, errorInfo: React.ErrorInfo) => void) | ((error: any, errorInfo: React.ErrorInfo) => boolean);
  onClose?: () => void;
  children: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean; error: any | null }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any, ...rest) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    const msg = 'The above "Uncaught Error" was caught by react error boundary';
    // eslint-disable-next-line no-console
    console.error(msg);
    this.setState({ hasError: true, error });
    const isHandled = this.props.onError && this.props.onError(error, errorInfo);

    if (isHandled) {
      this.setState({ hasError: false, error: null });
    }
  }

  render() {
    const { onClose } = this.props;
    const { error } = this.state;

    if (this.state.hasError) {
      return (
        <ErrorView
          error={error}
          onClose={() => {
            this.setState({ hasError: false, error: null });
            onClose();
          }}
        />
      );
    }

    return this.props.children;
  }
}
