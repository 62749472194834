import GlobalContext from "context/global-context";
import { useContext } from "react";
import { useQuery } from "react-query";
import { getCustomBreaksList } from "utils/api/schedule";
import { BreakStatusOptions } from "utils/api/types";

export const useBreaks = () => {
  const context = useContext(GlobalContext);
  const result = useQuery(
    `rq_custom_breaks`,
    async () => {
      const company = await context.getCompany();
      const { content } = await getCustomBreaksList({
        perPage: 300,
        page: 1,
        statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
        companyUuid: company.uuid,
        requestedBy: window.global_store.profile.uuid,
      });
      return content;
    },
    { staleTime: 60 * 1000 },
  );
  const { data, isLoading } = result;
  return { data: data || [], isLoading };
};
