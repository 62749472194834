import React from "react";
import styled from "styled-components";

export type Props = {
  className?: string;
  isMainButtonVisible: boolean;
  mainButton: React.ReactElement;
  actions: React.ReactElement;
};

const Wrapper = styled.div`
  .main-button,
  .action-input {
    height: 36px;
  }

  .actions {
    display: flex;
    padding-inline-start: var(--component-button-group-confirm-inline-hpad, 8px);
    justify-content: flex-end;
    align-items: center;
    gap: var(--component-button-group-confirm-inline-gap, 4px);
  }
`;

const InputWithButtons = ({ className, isMainButtonVisible, mainButton, actions }: Props) => {
  return <Wrapper className={className}>{isMainButtonVisible ? mainButton : actions}</Wrapper>;
};

export default InputWithButtons;
