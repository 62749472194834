/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull client-admin'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 64
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/oitchau/Day.io/implementation/client-admin)
 */

import amplitude, { AmplitudeClient, Callback, Config } from 'amplitude-js';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'aefcdccd39c22a93d0a2db7c8e5b98ab',
  development: '3fb04ac539ec50b95800b8c1651c8bf7'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions: ConfigExt = {
  plan: {
    version: '64',
    branch: 'main',
    source: 'client-admin',
    versionId: 'ca029f43-1336-4186-9102-34b2d4a1cbea'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '1.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { options?: Partial<ConfigExt>; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; options?: Partial<ConfigExt>; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: AmplitudeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * App Rating (Apps only)
   */
  AppRating?: string;
  /**
   * Describe whats the associates produtcys to that user:
   *
   * Can be multiple:
   *
   *
   * * ta
   *
   * * projects
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ta, product |
   */
  AssociateProducts?: "ta" | "product";
  /**
   * Day.io Company ID
   */
  CompanyID?: string;
  /**
   * Client admin defined Language
   */
  Language?: string;
  /**
   * User Permisson Ie: Supervisor, Adm, Basic...
   */
  Permission?: string;
  /**
   * Day.io Profile ID
   */
  UserProfileID?: string;
  /**
   * Day.io user UUID
   */
  UserUuid?: string;
}

export interface AppilcationReloadProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ApproveOnCallProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ApprovePunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ChangePunchCategoryProperties {
  /**
   * ie: Entry, Exit...
   */
  punchCategory: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ClearPunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ClearScheduleExceptionProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateAccountProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateAccountFailProperties {
  /**
   * Page error message
   */
  error: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateAccountSucessProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateCompanyProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateCompanyFailProperties {
  /**
   * Page error message
   */
  error: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateCompanySuccessProperties {
  companyCategory: string;
  employeesRange: string;
  inviteCode: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreateOnCallProperties {
  oncallType: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface CreatePunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DashboardProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DeclineOnCallProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DeclinePunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DeleteOnCallProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DownloadCustomReportProperties {
  /**
   * the selected audience. can be a group, a user, etc...
   */
  audience?: string;
  /**
   * selected format (txt, cvs, pdf...)
   */
  format?: string;
  from?: string;
  /**
   * Define the name of report:
   *  ie: Detailed, Hous Bank, Summary etcc..
   */
  reportName?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  to?: string;
}

export interface DownloadCustomReportScreenProperties {
  /**
   * Define the name of report:
   *  ie: Detailed, Hous Bank, Summary etcc..
   */
  reportName?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface DownloadReadyProperties {
  /**
   * type of download. Web or sent to email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Email, Web |
   */
  downloadType?: "Email" | "Web";
  /**
   * Duration in Minutes
   */
  duration: string;
  /**
   * Define the name of report:
   *  ie: Detailed, Hous Bank, Summary etcc..
   */
  reportName?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface EnableDayProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ExpiredScreenProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ExpiredScreenCtaProperties {
  CTA: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface FilterProperties {
  /**
   * Name of the selected filter
   *  Ex: Status, Category...
   */
  filterName: string;
  /**
   * the value of the filter.
   *  Differrent type of values can appear here. Ex: Punch category, requests types, Oncal.. etc
   */
  filterValue: string;
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface FilterOnlyIssuesProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface GenerateClientSecretProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface LoadingProperties {
  /**
   * Duration in Minutes
   */
  duration: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface LoginProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface LoginForgotProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface LoginSsoProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface LoginSuccessProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface MultipleActionsApproveProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface MultipleActionsClearPunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface MultipleActionsClearScheduleProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface MultipleActionsOrganizeChronologicallyProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface MultipleActionsValidateAllProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface OnCallProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface PunchChangeCategoryProperties {
  /**
   * When the user changes the Punch category from:
   *  ex: Entry
   */
  changeCategoryFrom?: string;
  /**
   * When the user changes the category to: ie: Exit
   */
  changeCategoryTo?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface PunchChangeLocationProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface PunchErrorLightboxProperties {
  /**
   * Page error message
   */
  error: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface PunchRequestSucessProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  /**
   * Defined the reson why the punch is a request.
   *  - IP Validation
   *  - location Validation
   *  - Time validation...
   */
  validation: string;
}

export interface PunchScreenProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface PunchSuccessProperties {
  /**
   * ie: Entry, Exit...
   */
  punchCategory: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface RecalculateProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SearchNextEmployeeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SearchSelectEmployeeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  /**
   * the exact string value ( ie: a search or a menu)
   */
  value: string;
}

export interface SearchSelectGroupProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  /**
   * the exact string value ( ie: a search or a menu)
   */
  value: string;
}

export interface SelectAllProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SelectIntegrationProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SelectIntegrationSkipProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SelectIntegrationSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Asana, Todoist, Trello, Notion, Jira, clickUp, Basecamp |
   */
  integration: "Asana" | "Todoist" | "Trello" | "Notion" | "Jira" | "clickUp" | "Basecamp";
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SelectPlanProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SelectPlanSuccessProperties {
  /**
   * Selected PRODUCTS on the company
   *
   * Values:
   *
   * * TA
   *
   * * TS
   *
   * * TA, TS
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | TA, TS |
   */
  products: "TA" | "TS";
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SpLoadingProperties {
  /**
   * Duration in Minutes
   */
  duration: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface SuperPunchProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  /**
   * The selected level of superPunch
   *  Values:
   *
   *
   * * **Groups** (all departament, all team, all subsidiaries)
   *
   * * **Team** ( departament, supervisor team)
   *
   * * **Employee**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Groups, Team, Employee |
   */
  superPunchLevel: "Groups" | "Team" | "Employee";
}

export interface TopBarCtaProperties {
  CTA: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  source?: string;
}

export interface TsheetAddNewTaskProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetAddTaskActivityProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetAddTimeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetChangeDateProperties {
  ChangeTaskFrom?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetChangeDateRangeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetChangeLocationProperties {
  ChangeTaskFrom?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetChangeTaskProperties {
  ChangeTaskFrom?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetDeleteProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetDeleteTaskRowProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetErrorLightboxProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetExpandViewProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetInputTaskDurationProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetMoveDateRangeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetScreenProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetSelectEmployeeProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetStartTrackingProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetStopProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
  /**
   * Duration of task. ie: HH:MM:SS
   */
  taskDuration: string;
}

export interface TsheetTimesheetTabProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetUpdateProperties {
  ChangeTaskFrom?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface TsheetViewDetailsProperties {
  ChangeTaskFrom?: string;
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ValidatePunchProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ViewOnCallDetailsProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ViewPunchDetailsProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface AllEventsProperties {
  /**
   * client-admin, app-ios, app-and, kiosk-and, kiosk-ios, widget-chrome...
   */
  source: string;
}

export interface ScreenTrackingProperties {
  /**
   * Screen Name. ie: Punch Screen
   */
  screen: string;
  /**
   * Previous Screen Name. ie: Punch Screen
   */
  screenPrevious?: string;
}

export class Identify implements BaseEvent {
  event_type = 'Identify';

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AppilcationReload implements BaseEvent {
  event_type = 'AppilcationReload';

  constructor(
    public event_properties: AppilcationReloadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApproveOnCall implements BaseEvent {
  event_type = 'ApproveOnCall';

  constructor(
    public event_properties: ApproveOnCallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApprovePunch implements BaseEvent {
  event_type = 'ApprovePunch';

  constructor(
    public event_properties: ApprovePunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangePunchCategory implements BaseEvent {
  event_type = 'ChangePunchCategory';

  constructor(
    public event_properties: ChangePunchCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClearPunch implements BaseEvent {
  event_type = 'ClearPunch';

  constructor(
    public event_properties: ClearPunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClearScheduleException implements BaseEvent {
  event_type = 'ClearScheduleException';

  constructor(
    public event_properties: ClearScheduleExceptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccount implements BaseEvent {
  event_type = 'CreateAccount';

  constructor(
    public event_properties: CreateAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccountFail implements BaseEvent {
  event_type = 'CreateAccountFail';

  constructor(
    public event_properties: CreateAccountFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateAccountSucess implements BaseEvent {
  event_type = 'CreateAccountSucess';

  constructor(
    public event_properties: CreateAccountSucessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCompany implements BaseEvent {
  event_type = 'CreateCompany';

  constructor(
    public event_properties: CreateCompanyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCompanyFail implements BaseEvent {
  event_type = 'CreateCompanyFail';

  constructor(
    public event_properties: CreateCompanyFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCompanySuccess implements BaseEvent {
  event_type = 'CreateCompanySuccess';

  constructor(
    public event_properties: CreateCompanySuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateOnCall implements BaseEvent {
  event_type = 'CreateOnCall';

  constructor(
    public event_properties: CreateOnCallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreatePunch implements BaseEvent {
  event_type = 'CreatePunch';

  constructor(
    public event_properties: CreatePunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Dashboard implements BaseEvent {
  event_type = 'Dashboard';

  constructor(
    public event_properties: DashboardProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeclineOnCall implements BaseEvent {
  event_type = 'DeclineOnCall';

  constructor(
    public event_properties: DeclineOnCallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeclinePunch implements BaseEvent {
  event_type = 'DeclinePunch';

  constructor(
    public event_properties: DeclinePunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteOnCall implements BaseEvent {
  event_type = 'DeleteOnCall';

  constructor(
    public event_properties: DeleteOnCallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DownloadCustomReport implements BaseEvent {
  event_type = 'DownloadCustomReport';

  constructor(
    public event_properties: DownloadCustomReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DownloadCustomReportScreen implements BaseEvent {
  event_type = 'DownloadCustomReportScreen';

  constructor(
    public event_properties: DownloadCustomReportScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DownloadReady implements BaseEvent {
  event_type = 'DownloadReady';

  constructor(
    public event_properties: DownloadReadyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EnableDay implements BaseEvent {
  event_type = 'EnableDay';

  constructor(
    public event_properties: EnableDayProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExpiredScreen implements BaseEvent {
  event_type = 'ExpiredScreen';

  constructor(
    public event_properties: ExpiredScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExpiredScreenCta implements BaseEvent {
  event_type = 'ExpiredScreenCTA';

  constructor(
    public event_properties: ExpiredScreenCtaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Filter implements BaseEvent {
  event_type = 'Filter';

  constructor(
    public event_properties: FilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterOnlyIssues implements BaseEvent {
  event_type = 'FilterOnlyIssues';

  constructor(
    public event_properties: FilterOnlyIssuesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateClientSecret implements BaseEvent {
  event_type = 'GenerateClientSecret';

  constructor(
    public event_properties: GenerateClientSecretProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Loading implements BaseEvent {
  event_type = 'Loading';

  constructor(
    public event_properties: LoadingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Login implements BaseEvent {
  event_type = 'Login';

  constructor(
    public event_properties: LoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginForgot implements BaseEvent {
  event_type = 'LoginForgot';

  constructor(
    public event_properties: LoginForgotProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginSso implements BaseEvent {
  event_type = 'LoginSSO';

  constructor(
    public event_properties: LoginSsoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginSuccess implements BaseEvent {
  event_type = 'LoginSuccess';

  constructor(
    public event_properties: LoginSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultipleActionsApprove implements BaseEvent {
  event_type = 'MultipleActionsApprove';

  constructor(
    public event_properties: MultipleActionsApproveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultipleActionsClearPunch implements BaseEvent {
  event_type = 'MultipleActionsClearPunch';

  constructor(
    public event_properties: MultipleActionsClearPunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultipleActionsClearSchedule implements BaseEvent {
  event_type = 'MultipleActionsClearSchedule';

  constructor(
    public event_properties: MultipleActionsClearScheduleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultipleActionsOrganizeChronologically implements BaseEvent {
  event_type = 'MultipleActionsOrganizeChronologically';

  constructor(
    public event_properties: MultipleActionsOrganizeChronologicallyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MultipleActionsValidateAll implements BaseEvent {
  event_type = 'MultipleActionsValidateAll';

  constructor(
    public event_properties: MultipleActionsValidateAllProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnCall implements BaseEvent {
  event_type = 'OnCall';

  constructor(
    public event_properties: OnCallProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchChangeCategory implements BaseEvent {
  event_type = 'PunchChangeCategory';

  constructor(
    public event_properties: PunchChangeCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchChangeLocation implements BaseEvent {
  event_type = 'PunchChangeLocation';

  constructor(
    public event_properties: PunchChangeLocationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchErrorLightbox implements BaseEvent {
  event_type = 'PunchErrorLightbox';

  constructor(
    public event_properties: PunchErrorLightboxProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchRequestSucess implements BaseEvent {
  event_type = 'PunchRequestSucess';

  constructor(
    public event_properties: PunchRequestSucessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchScreen implements BaseEvent {
  event_type = 'PunchScreen';

  constructor(
    public event_properties: PunchScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PunchSuccess implements BaseEvent {
  event_type = 'PunchSuccess';

  constructor(
    public event_properties: PunchSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Recalculate implements BaseEvent {
  event_type = 'Recalculate';

  constructor(
    public event_properties: RecalculateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchNextEmployee implements BaseEvent {
  event_type = 'SearchNextEmployee';

  constructor(
    public event_properties: SearchNextEmployeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSelectEmployee implements BaseEvent {
  event_type = 'SearchSelectEmployee';

  constructor(
    public event_properties: SearchSelectEmployeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSelectGroup implements BaseEvent {
  event_type = 'SearchSelectGroup';

  constructor(
    public event_properties: SearchSelectGroupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectAll implements BaseEvent {
  event_type = 'SelectAll';

  constructor(
    public event_properties: SelectAllProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectIntegration implements BaseEvent {
  event_type = 'SelectIntegration';

  constructor(
    public event_properties: SelectIntegrationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectIntegrationSkip implements BaseEvent {
  event_type = 'SelectIntegrationSkip';

  constructor(
    public event_properties: SelectIntegrationSkipProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectIntegrationSuccess implements BaseEvent {
  event_type = 'SelectIntegrationSuccess';

  constructor(
    public event_properties: SelectIntegrationSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectPlan implements BaseEvent {
  event_type = 'SelectPlan';

  constructor(
    public event_properties: SelectPlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectPlanSuccess implements BaseEvent {
  event_type = 'SelectPlanSuccess';

  constructor(
    public event_properties: SelectPlanSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SpLoading implements BaseEvent {
  event_type = 'SPLoading';

  constructor(
    public event_properties: SpLoadingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuperPunch implements BaseEvent {
  event_type = 'SuperPunch';

  constructor(
    public event_properties: SuperPunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TopBar implements BaseEvent {
  event_type = 'TopBar';
}

export class TopBarCta implements BaseEvent {
  event_type = 'TopBarCTA';

  constructor(
    public event_properties: TopBarCtaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetAddNewTask implements BaseEvent {
  event_type = 'TsheetAddNewTask';

  constructor(
    public event_properties: TsheetAddNewTaskProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetAddTaskActivity implements BaseEvent {
  event_type = 'TsheetAddTaskActivity';

  constructor(
    public event_properties: TsheetAddTaskActivityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetAddTime implements BaseEvent {
  event_type = 'TsheetAddTime';

  constructor(
    public event_properties: TsheetAddTimeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetChangeDate implements BaseEvent {
  event_type = 'TsheetChangeDate';

  constructor(
    public event_properties: TsheetChangeDateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetChangeDateRange implements BaseEvent {
  event_type = 'TsheetChangeDateRange';

  constructor(
    public event_properties: TsheetChangeDateRangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetChangeLocation implements BaseEvent {
  event_type = 'TsheetChangeLocation';

  constructor(
    public event_properties: TsheetChangeLocationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetChangeTask implements BaseEvent {
  event_type = 'TsheetChangeTask';

  constructor(
    public event_properties: TsheetChangeTaskProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetDelete implements BaseEvent {
  event_type = 'TsheetDelete';

  constructor(
    public event_properties: TsheetDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetDeleteTaskRow implements BaseEvent {
  event_type = 'TsheetDeleteTaskRow';

  constructor(
    public event_properties: TsheetDeleteTaskRowProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetErrorLightbox implements BaseEvent {
  event_type = 'TsheetErrorLightbox';

  constructor(
    public event_properties: TsheetErrorLightboxProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetExpandView implements BaseEvent {
  event_type = 'TsheetExpandView';

  constructor(
    public event_properties: TsheetExpandViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetInputTaskDuration implements BaseEvent {
  event_type = 'TsheetInputTaskDuration';

  constructor(
    public event_properties: TsheetInputTaskDurationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetMoveDateRange implements BaseEvent {
  event_type = 'TsheetMoveDateRange';

  constructor(
    public event_properties: TsheetMoveDateRangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetScreen implements BaseEvent {
  event_type = 'TsheetScreen';

  constructor(
    public event_properties: TsheetScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetSelectEmployee implements BaseEvent {
  event_type = 'TsheetSelectEmployee';

  constructor(
    public event_properties: TsheetSelectEmployeeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetStartTracking implements BaseEvent {
  event_type = 'TsheetStartTracking';

  constructor(
    public event_properties: TsheetStartTrackingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetStop implements BaseEvent {
  event_type = 'TsheetStop';

  constructor(
    public event_properties: TsheetStopProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetTimesheetTab implements BaseEvent {
  event_type = 'TsheetTimesheetTab';

  constructor(
    public event_properties: TsheetTimesheetTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetUpdate implements BaseEvent {
  event_type = 'TsheetUpdate';

  constructor(
    public event_properties: TsheetUpdateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TsheetViewDetails implements BaseEvent {
  event_type = 'TsheetViewDetails';

  constructor(
    public event_properties: TsheetViewDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ValidatePunch implements BaseEvent {
  event_type = 'ValidatePunch';

  constructor(
    public event_properties: ValidatePunchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewOnCallDetails implements BaseEvent {
  event_type = 'ViewOnCallDetails';

  constructor(
    public event_properties: ViewOnCallDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewPunchDetails implements BaseEvent {
  event_type = 'ViewPunchDetails';

  constructor(
    public event_properties: ViewPunchDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: AmplitudeClient;
  private middlewares: Middleware[] = [];

  get client(): AmplitudeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): void {
    this.disabled = options.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...options.client?.options });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: IdentifyOptions,
    extra?: MiddlewareExtra
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: IdentifyEvent = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  private _identify(
    event: Event,
    options?: IdentifyOptions,
  ) {
    const amplitudeIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    this.amplitude?.identify(
      amplitudeIdentify,
      options?.callback,
      // options?.errorCallback
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  track(event: Event, options?: EventOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent: BaseEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = (payload.event as BaseEvent).user_properties;
      if (userProperties) {
        const identifyEvent: IdentifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude?.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        // options?.errorCallback,
      );
    });
  }

  /**
   * AppilcationReload
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/AppilcationReload)
   *
   * Triggered when the user refresh the screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  appilcationReload(
    properties: AppilcationReloadProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AppilcationReload(properties), options, extra);
  }

  /**
   * ApproveOnCall
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ApproveOnCall)
   *
   * users Approves the OnCall
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  approveOnCall(
    properties: ApproveOnCallProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ApproveOnCall(properties), options, extra);
  }

  /**
   * ApprovePunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ApprovePunch)
   *
   * user approve a punch from SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  approvePunch(
    properties: ApprovePunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ApprovePunch(properties), options, extra);
  }

  /**
   * ChangePunchCategory
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ChangePunchCategory)
   *
   * When the user changes a punch on the SuperPunch category  
   *  - The propriety, displays the WRONG category.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. punchCategory)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  changePunchCategory(
    properties: ChangePunchCategoryProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChangePunchCategory(properties), options, extra);
  }

  /**
   * ClearPunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ClearPunch)
   *
   * When the user clicks on te clear a Punch classification on the Superpunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  clearPunch(
    properties: ClearPunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ClearPunch(properties), options, extra);
  }

  /**
   * ClearScheduleException
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ClearScheduleException)
   *
   * User click to remove the Schedule exception on SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  clearScheduleException(
    properties: ClearScheduleExceptionProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ClearScheduleException(properties), options, extra);
  }

  /**
   * CreateAccount
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateAccount)
   *
   * View the CreateAccount Screen on the Signup Process
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createAccount(
    properties: CreateAccountProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateAccount(properties), options, extra);
  }

  /**
   * CreateAccountFail
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateAccountFail)
   *
   * when user have an error to create account on the signup process 
   *  The error, should be described on the Proprieties
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createAccountFail(
    properties: CreateAccountFailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateAccountFail(properties), options, extra);
  }

  /**
   * CreateAccountSucess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateAccountSucess)
   *
   * User create account with Success on the SignupProcess
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createAccountSucess(
    properties: CreateAccountSucessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateAccountSucess(properties), options, extra);
  }

  /**
   * CreateCompany
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateCompany)
   *
   * user view the Create Company step on the signup flow
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createCompany(
    properties: CreateCompanyProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateCompany(properties), options, extra);
  }

  /**
   * CreateCompanyFail
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateCompanyFail)
   *
   * user fails on create a company on the signup process
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createCompanyFail(
    properties: CreateCompanyFailProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateCompanyFail(properties), options, extra);
  }

  /**
   * CreateCompanySuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateCompanySuccess)
   *
   * User get success to create a company on the signup process
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. companyCategory)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createCompanySuccess(
    properties: CreateCompanySuccessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateCompanySuccess(properties), options, extra);
  }

  /**
   * CreateOnCall
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreateOnCall)
   *
   * When the user Creates a OnCall
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. oncallType)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createOnCall(
    properties: CreateOnCallProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreateOnCall(properties), options, extra);
  }

  /**
   * CreatePunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/CreatePunch)
   *
   * User click on the button to create a new punch from SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  createPunch(
    properties: CreatePunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new CreatePunch(properties), options, extra);
  }

  /**
   * Dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/Dashboard)
   *
   * When the user VIEW the dashboard screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  dashboard(
    properties: DashboardProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new Dashboard(properties), options, extra);
  }

  /**
   * DeclineOnCall
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DeclineOnCall)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  declineOnCall(
    properties: DeclineOnCallProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DeclineOnCall(properties), options, extra);
  }

  /**
   * DeclinePunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DeclinePunch)
   *
   * User click on Decline a Punch on SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  declinePunch(
    properties: DeclinePunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DeclinePunch(properties), options, extra);
  }

  /**
   * DeleteOnCall
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DeleteOnCall)
   *
   * User delete a oncall
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  deleteOnCall(
    properties: DeleteOnCallProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DeleteOnCall(properties), options, extra);
  }

  /**
   * DownloadCustomReport
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DownloadCustomReport)
   *
   * When the user REQUEST to download the Custom Report
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. audience)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadCustomReport(
    properties: DownloadCustomReportProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadCustomReport(properties), options, extra);
  }

  /**
   * DownloadCustomReportScreen
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DownloadCustomReportScreen)
   *
   * When ser View the Download Custom Report Lightbox
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. reportName)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadCustomReportScreen(
    properties: DownloadCustomReportScreenProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadCustomReportScreen(properties), options, extra);
  }

  /**
   * DownloadReady
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/DownloadReady)
   *
   * Triggered when the download is ready 
   *  1- download on web
   * 2- sent to email 
   *
   *  Proprieties:
   *
   *
   *  \- Duration: How long the file delay to be ready? 
   *  \- method: web or email 
   *  \- Name: Name of the report (ie Detailed Report, Employee, Absences...)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. downloadType)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  downloadReady(
    properties: DownloadReadyProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DownloadReady(properties), options, extra);
  }

  /**
   * EnableDay
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/EnableDay)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  enableDay(
    properties: EnableDayProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new EnableDay(properties), options, extra);
  }

  /**
   * ExpiredScreen
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ExpiredScreen)
   *
   * Every time the user view the Expired  Plan and Block Screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  expiredScreen(
    properties: ExpiredScreenProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ExpiredScreen(properties), options, extra);
  }

  /**
   * ExpiredScreenCTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ExpiredScreenCTA)
   *
   * When the user clicks on the main CTA on the page. 
   *  Ie: Request a Call, or Update. 
   *
   *  The CTA should be fill by event Propiertie CTA.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. CTA)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  expiredScreenCta(
    properties: ExpiredScreenCtaProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ExpiredScreenCta(properties), options, extra);
  }

  /**
   * Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/Filter)
   *
   * User Set a filter. 
   *
   * * add the Value as a Property
   *
   * * add tthe Page name as a Property
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. filterName)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  filter(
    properties: FilterProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new Filter(properties), options, extra);
  }

  /**
   * FilterOnlyIssues
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/FilterOnlyIssues)
   *
   * User selects the filter option to display only iissues
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  filterOnlyIssues(
    properties: FilterOnlyIssuesProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new FilterOnlyIssues(properties), options, extra);
  }

  /**
   * GenerateClientSecret
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/GenerateClientSecret)
   *
   * Very time that user click to generate a Client Secrete button: https://d.pr/i/W1xG9m
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  generateClientSecret(
    properties: GenerateClientSecretProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new GenerateClientSecret(properties), options, extra);
  }

  /**
   * Loading
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/Loading)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. duration)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loading(
    properties: LoadingProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new Loading(properties), options, extra);
  }

  /**
   * Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/Login)
   *
   * the user enters on the login page
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  login(
    properties: LoginProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new Login(properties), options, extra);
  }

  /**
   * LoginForgot
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/LoginForgot)
   *
   * User clicks on the Forget Password
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loginForgot(
    properties: LoginForgotProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LoginForgot(properties), options, extra);
  }

  /**
   * LoginSSO
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/LoginSSO)
   *
   * User enters on SSO login screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loginSso(
    properties: LoginSsoProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LoginSso(properties), options, extra);
  }

  /**
   * LoginSuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/LoginSuccess)
   *
   * the user login with sucess
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  loginSuccess(
    properties: LoginSuccessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new LoginSuccess(properties), options, extra);
  }

  /**
   * MultipleActionsApprove
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/MultipleActionsApprove)
   *
   * User click on the button to Approve Multiple punches
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  multipleActionsApprove(
    properties: MultipleActionsApproveProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MultipleActionsApprove(properties), options, extra);
  }

  /**
   * MultipleActionsClearPunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/MultipleActionsClearPunch)
   *
   * User cliicks on the Clear Multiple punches
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  multipleActionsClearPunch(
    properties: MultipleActionsClearPunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MultipleActionsClearPunch(properties), options, extra);
  }

  /**
   * MultipleActionsClearSchedule
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/MultipleActionsClearSchedule)
   *
   * when the user clicks on Clear Schedules from multiple Selection
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  multipleActionsClearSchedule(
    properties: MultipleActionsClearScheduleProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MultipleActionsClearSchedule(properties), options, extra);
  }

  /**
   * MultipleActionsOrganizeChronologically
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/MultipleActionsOrganizeChronologically)
   *
   * User clicks on the Organize chronologically butoon
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  multipleActionsOrganizeChronologically(
    properties: MultipleActionsOrganizeChronologicallyProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MultipleActionsOrganizeChronologically(properties), options, extra);
  }

  /**
   * MultipleActionsValidateAll
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/MultipleActionsValidateAll)
   *
   * User clicks on the Validate All punches
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  multipleActionsValidateAll(
    properties: MultipleActionsValidateAllProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MultipleActionsValidateAll(properties), options, extra);
  }

  /**
   * OnCall
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/OnCall)
   *
   * User clicks on OnCall item on the menu,
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  onCall(
    properties: OnCallProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new OnCall(properties), options, extra);
  }

  /**
   * PunchChangeCategory
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchChangeCategory)
   *
   * User enter on the Changee category scree
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. changeCategoryFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchChangeCategory(
    properties: PunchChangeCategoryProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchChangeCategory(properties), options, extra);
  }

  /**
   * PunchChangeLocation
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchChangeLocation)
   *
   * Users enters on the Change Location screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchChangeLocation(
    properties: PunchChangeLocationProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchChangeLocation(properties), options, extra);
  }

  /**
   * PunchErrorLightbox
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchErrorLightbox)
   *
   * Users enters on the Error Lightbox
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchErrorLightbox(
    properties: PunchErrorLightboxProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchErrorLightbox(properties), options, extra);
  }

  /**
   * PunchRequestSucess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchRequestSucess)
   *
   * User makes a request with Success
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchRequestSucess(
    properties: PunchRequestSucessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchRequestSucess(properties), options, extra);
  }

  /**
   * PunchScreen
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchScreen)
   *
   * User entries on the Punch Screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchScreen(
    properties: PunchScreenProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchScreen(properties), options, extra);
  }

  /**
   * PunchSuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/PunchSuccess)
   *
   * When the punch is created with sucess
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. punchCategory)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  punchSuccess(
    properties: PunchSuccessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new PunchSuccess(properties), options, extra);
  }

  /**
   * Recalculate
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/Recalculate)
   *
   * Users click on the Recalculate Action https://d.pr/i/UYVV08
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  recalculate(
    properties: RecalculateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new Recalculate(properties), options, extra);
  }

  /**
   * SearchNextEmployee
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SearchNextEmployee)
   *
   * The user clicked on the NEXT/Back arrows  on the search box
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchNextEmployee(
    properties: SearchNextEmployeeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchNextEmployee(properties), options, extra);
  }

  /**
   * SearchSelectEmployee
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SearchSelectEmployee)
   *
   * User click on the Employee, on the select employee result.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchSelectEmployee(
    properties: SearchSelectEmployeeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchSelectEmployee(properties), options, extra);
  }

  /**
   * SearchSelectGroup
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SearchSelectGroup)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchSelectGroup(
    properties: SearchSelectGroupProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchSelectGroup(properties), options, extra);
  }

  /**
   * SelectAll
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectAll)
   *
   * User click on the select all option
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectAll(
    properties: SelectAllProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectAll(properties), options, extra);
  }

  /**
   * SelectIntegration
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectIntegration)
   *
   * user view the Select Integration page on the Signup Process
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectIntegration(
    properties: SelectIntegrationProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectIntegration(properties), options, extra);
  }

  /**
   * SelectIntegrationSkip
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectIntegrationSkip)
   *
   * When the user click on the button toSKIP the integrations pages
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectIntegrationSkip(
    properties: SelectIntegrationSkipProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectIntegrationSkip(properties), options, extra);
  }

  /**
   * SelectIntegrationSuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectIntegrationSuccess)
   *
   * When user select an integration and be redirect to the specific integration page
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. integration)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectIntegrationSuccess(
    properties: SelectIntegrationSuccessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectIntegrationSuccess(properties), options, extra);
  }

  /**
   * SelectPlan
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectPlan)
   *
   * User view the Select Plan page on the signup process
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectPlan(
    properties: SelectPlanProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectPlan(properties), options, extra);
  }

  /**
   * SelectPlanSuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SelectPlanSuccess)
   *
   * When user complete the stepof select Plan/Products with sucess 
   *
   *  We shoould bring the selected projects as a propierties.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. products)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  selectPlanSuccess(
    properties: SelectPlanSuccessProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SelectPlanSuccess(properties), options, extra);
  }

  /**
   * SPLoading
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SPLoading)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. duration)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  spLoading(
    properties: SpLoadingProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SpLoading(properties), options, extra);
  }

  /**
   * SuperPunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/SuperPunch)
   *
   * When the user VIEW the SuperPunch screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. screen)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  superPunch(
    properties: SuperPunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SuperPunch(properties), options, extra);
  }

  /**
   * TopBar
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TopBar)
   *
   * Every time that user view the Trial Top Bar
   *
   * Owner: Leo Ramalho
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  topBar(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TopBar(), options, extra);
  }

  /**
   * TopBarCTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TopBarCTA)
   *
   * Every time that user clicks on the topbar CTA. 
   *  capture the CTA as a event Propiertie.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. CTA)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  topBarCta(
    properties: TopBarCtaProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TopBarCta(properties), options, extra);
  }

  /**
   * TsheetAddNewTask
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetAddNewTask)
   *
   * When a user clicks on 'add new row' CTA to add a new task activity
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetAddNewTask(
    properties: TsheetAddNewTaskProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetAddNewTask(properties), options, extra);
  }

  /**
   * TsheetAddTaskActivity
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetAddTaskActivity)
   *
   * When a user clicks save after adding a task activity in the timesheet
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetAddTaskActivity(
    properties: TsheetAddTaskActivityProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetAddTaskActivity(properties), options, extra);
  }

  /**
   * TsheetAddTime
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetAddTime)
   *
   * when the user clikc on the (+) on the mains screen to Add a new task.
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetAddTime(
    properties: TsheetAddTimeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetAddTime(properties), options, extra);
  }

  /**
   * TsheetChangeDate
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetChangeDate)
   *
   * when the users clicks on the Datepicker, to change the Task Date
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. ChangeTaskFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetChangeDate(
    properties: TsheetChangeDateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetChangeDate(properties), options, extra);
  }

  /**
   * TsheetChangeDateRange
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetChangeDateRange)
   *
   * When user changes the date range through the calendar drop down
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetChangeDateRange(
    properties: TsheetChangeDateRangeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetChangeDateRange(properties), options, extra);
  }

  /**
   * TsheetChangeLocation
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetChangeLocation)
   *
   * User click on the Location selection, to change the location
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. ChangeTaskFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetChangeLocation(
    properties: TsheetChangeLocationProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetChangeLocation(properties), options, extra);
  }

  /**
   * TsheetChangeTask
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetChangeTask)
   *
   * when the user clicks on the Task list selection
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. ChangeTaskFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetChangeTask(
    properties: TsheetChangeTaskProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetChangeTask(properties), options, extra);
  }

  /**
   * TsheetDelete
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetDelete)
   *
   * when the user click on the DELETE option on the task
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetDelete(
    properties: TsheetDeleteProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetDelete(properties), options, extra);
  }

  /**
   * TsheetDeleteTaskRow
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetDeleteTaskRow)
   *
   * When a user deletes a task row from the timesheet using the red
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetDeleteTaskRow(
    properties: TsheetDeleteTaskRowProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetDeleteTaskRow(properties), options, extra);
  }

  /**
   * TsheetErrorLightbox
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetErrorLightbox)
   *
   * Display when the user receive any error on the Widget. (similar to PunchErrorLightbox)
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetErrorLightbox(
    properties: TsheetErrorLightboxProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetErrorLightbox(properties), options, extra);
  }

  /**
   * TsheetExpandView
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetExpandView)
   *
   * User clicks on the multiple tasks icon, in order the expand the view on the main list:
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetExpandView(
    properties: TsheetExpandViewProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetExpandView(properties), options, extra);
  }

  /**
   * TsheetInputTaskDuration
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetInputTaskDuration)
   *
   * When user directly inputs taks duration for a task
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetInputTaskDuration(
    properties: TsheetInputTaskDurationProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetInputTaskDuration(properties), options, extra);
  }

  /**
   * TsheetMoveDateRange
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetMoveDateRange)
   *
   * When a user clicks on arrows next to DateRange shown
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetMoveDateRange(
    properties: TsheetMoveDateRangeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetMoveDateRange(properties), options, extra);
  }

  /**
   * TsheetScreen
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetScreen)
   *
   * View The main TimeSheet screen on the widget
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetScreen(
    properties: TsheetScreenProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetScreen(properties), options, extra);
  }

  /**
   * TsheetSelectEmployee
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetSelectEmployee)
   *
   * When a user select user for viewing timesheet for a specific user
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetSelectEmployee(
    properties: TsheetSelectEmployeeProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetSelectEmployee(properties), options, extra);
  }

  /**
   * TsheetStartTracking
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetStartTracking)
   *
   * user clicks and start Tracking a Task
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetStartTracking(
    properties: TsheetStartTrackingProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetStartTracking(properties), options, extra);
  }

  /**
   * TsheetStop
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetStop)
   *
   * User press the STOP button. We should save the scree (TaskDetails, HilightedBar or MainScreen )
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetStop(
    properties: TsheetStopProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetStop(properties), options, extra);
  }

  /**
   * TsheetTimesheetTab
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetTimesheetTab)
   *
   * When a user goes to timesheet tab by clicking on it
   *
   * Owner: Divya Negi
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetTimesheetTab(
    properties: TsheetTimesheetTabProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetTimesheetTab(properties), options, extra);
  }

  /**
   * TsheetUpdate
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetUpdate)
   *
   * Every Save details button
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. ChangeTaskFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetUpdate(
    properties: TsheetUpdateProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetUpdate(properties), options, extra);
  }

  /**
   * TsheetViewDetails
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/TsheetViewDetails)
   *
   * everytime the user click to see the Task Details screen
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. ChangeTaskFrom)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  tsheetViewDetails(
    properties: TsheetViewDetailsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TsheetViewDetails(properties), options, extra);
  }

  /**
   * ValidatePunch
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ValidatePunch)
   *
   * User click on VALIDATE punch action on the SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  validatePunch(
    properties: ValidatePunchProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ValidatePunch(properties), options, extra);
  }

  /**
   * ViewOnCallDetails
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ViewOnCallDetails)
   *
   * User clicks on the OnCall Details
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewOnCallDetails(
    properties: ViewOnCallDetailsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewOnCallDetails(properties), options, extra);
  }

  /**
   * ViewPunchDetails
   *
   * [View in Tracking Plan](https://data.amplitude.com/oitchau/Day.io/events/main/latest/ViewPunchDetails)
   *
   * User click on the Eye icon, and view a Punch detail from SuperPunch
   *
   * Owner: Leo Ramalho
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  viewPunchDetails(
    properties: ViewPunchDetailsProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ViewPunchDetails(properties), options, extra);
  }

  addEventMiddleware(middleware: Middleware): void {
    this.middlewares.push(middleware);
  }

  private runMiddleware(payload: MiddlewarePayload, next: MiddlewareNext): void {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext: MiddlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next: MiddlewareNext = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type ConfigExt = Partial<Config> & { plan?: Plan };

export type Plan = {
  branch?: string;
  source?: string;
  version?: string;
  versionId?: string;
}

export enum SpecialEventType {
  Identify = "Identify",
  Group = "Group"
}

export type BaseEvent = {
  event_type: string;
  event_properties?: { [key: string]: any },
  plan?: Plan;
  user_id?: string;
  device_id?: string;
  user_properties?: { [key: string]: any };
}
export type IdentifyEvent = Omit<BaseEvent, 'user_properties'> & {event_type: SpecialEventType.Identify };
export type GroupEvent = Omit<BaseEvent, 'user_properties'> & { event_type: SpecialEventType.Group };
export type Event = BaseEvent | IdentifyEvent | GroupEvent;

type BaseEventOptions = Omit<BaseEvent, 'event_type' | 'event_properties'> & {
  callback?: Callback;
  errorCallback?: Callback;
};
export type EventOptions = BaseEventOptions;
export type IdentifyOptions = Omit<BaseEventOptions, 'user_properties'>;
export type GroupOptions = Omit<BaseEventOptions, 'user_properties'>;

/**
 * Unstructured object to let users pass extra data to middleware
 */
export interface MiddlewareExtra {
  [name: string]: any;
}

/**
 * Data to be processed by middleware
 */
export interface MiddlewarePayload {
  event: Event;
  extra?: MiddlewareExtra;
}

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 */
export type MiddlewareNext = (payload: MiddlewarePayload) => void;

/**
 * A function to run on the Event stream (each logEvent call)
 *
 * @param payload The event and extra data being sent
 * @param next Function to run the next middleware in the chain, not calling next will end the middleware chain
 */
export type Middleware = (payload: MiddlewarePayload, next: MiddlewareNext) => void;
