import styled from "styled-components";

export const BreakModalWrapper = styled.div`
  position: relative;
  background: var(--colors-surface-0);
  height: fit-content;
  width: 100%;
  padding: 32px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 24px;
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  gap: 12px;
  button {
    flex: 1;
    height: 40px;
  }
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  input {
    &:checked {
      border-width: 5px;
    }
  }
`;

export const RangeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .range-input-field {
    flex: 1;
    div:nth-child(2) div {
      width: 100%;
    }
  }
  .duration-input-field {
    flex: 1;
    .ui-select__wrapper {
      margin-top: 5px;
    }
    > div:nth-child(1) {
      justify-content: flex-start;
      gap: 4px;
      margin-bottom: 0;
    }
    > div:nth-child(2) > div {
      width: 100%;
      margin-top: 5px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    margin-inline-end: 8px;
  }
`;

export const ScheduleBreakWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  position: relative;
  gap: 10px;
`;

export const BreakInfoWrapper = styled.div<{ $readOnly: boolean; $isSelected: boolean }>`
  width: calc(100% - 25px);
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-inline-end: 26px;
  padding-bottom: 10px;
  padding-inline-start: 16px;
  border-radius: var(--shapes-border-radius-default);
  background-color: ${(props) => (props.$readOnly ? "inherit" : "var(--colors-surface-0)")};
  border: 1px solid ${(props) => (props.$isSelected ? "var(--colors-primary-500-p)" : "transparent")};
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }
  .arrow-down {
    position: absolute;
    inset-inline-end: 10px;
    margin-inline-start: 4px;
    visibility: hidden;
    transition: none;
    svg {
      transition: none;
    }
  }

  &:hover {
    border: 1px solid ${(props) => (props.$readOnly ? "transparent" : "var(--colors-surface-400)")};
    .arrow-down {
      visibility: ${(props) => (props.$readOnly ? "hidden" : "visible")};
      transition: none;
      svg {
        transition: none;
      }
    }
  }
`;

export const BreakInfoHoursWrapper = styled.div<{ automatic: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 440px;
  text-wrap: nowrap;
  margin-inline-end: 4px;
  display: flex;
`;

export const BreakTitleInfo = styled.span`
  margin-inline-end: 4px;
  font-weight: 450;
  font-size: 15px;
  color: var(--colors-surface-900-p);
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BreakHoursInfo = styled.span`
  margin-inline-end: 4px;
  font-weight: 450;
  font-size: 15px;
  color: var(--colors-surface-600);
`;

export const AddBreakWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
`;

export const AddBreakBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary);
  cursor: pointer;
`;

export const RemoveBreakWrapper = styled.div`
  display: flex;
  margin-inline-start: 6px;
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);
  position: relative;
  inset-inline-end: 10px;
  &:hover {
    background-color: var(--colors-surface-100);
    svg path {
      fill: var(--colors-surface-800);
    }
  }
`;

export const BreaksTooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
