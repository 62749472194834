import styled from "styled-components";

export const Wrapper = styled.div`
  width: 292px;
  border-radius: var(--shapes-border-radius-default);
  box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
  background: var(--colors-surface-0);
`;

export const Header = styled.div`
  padding: 12px 16px;
  background: var(--colors-surface-50);
  border-bottom: 1px solid var(--colors-surface-150);
  line-height: 14px;
  font-size: 12px;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-surface-400);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TaskName = styled.div`
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-surface-900-p);
`;

export const Project = styled.div``;

export const Date = styled.div`
  text-transform: uppercase;
`;

export const Body = styled.div`
  padding: 16px;
`;

export const EntriesHeader = styled.div`
  display: flex;
  margin-bottom: 7px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: var(--colors-surface-400);

  span {
    width: 72px;
    margin-inline-end: 12px;
  }
`;

export const EntryRow = styled.div`
  display: flex;
  margin-bottom: 15px;

  .time-control {
    margin-top: 0;
    width: 72px;
    margin-inline-end: 12px;
  }
`;

export const OpenDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const HR = styled.hr`
  margin: 16px 0;
  border: none;
  border-bottom: 1px solid var(--colors-surface-150);
`;

export const Total = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-900-p);
  text-align: end;

  span {
    font-weight: var(--typography-font-weight-medium);
  }
`;

export const Buttons = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
`;
