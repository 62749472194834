import { stylesheet } from "astroturf";
import cx from "classnames";
import Avatar from "components/views/Avatar";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;

  .Avatar {
    flex-shrink: 0;
  }

  .EmployeeDetails {
    height: 20px;
    font-size: 16px;
    color: var(--colors-text1);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .CompanyDetails {
    height: 18px;
    font-size: var(--typography-font-size-default);
    color: var(--colors-surface-600);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Texts {
    overflow: hidden;
  }

  &.loading {
    @use 'styles/loading';

    .Avatar {
      @extend .wireframe;

      width: 36px;
      height: 36px;
      border-radius: 36px;
    }

    .EmployeeDetails {
      @extend .wireframe;

      bottom: 1px;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .CompanyDetails {
      @extend .wireframe;

      top: 1px;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .FlexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
`;

export interface ProfileCardProps extends React.HTMLProps<HTMLDivElement> {
  employeeName: string;
  employeeAvatarId?: string;
  companyName: string;
  departmentName?: string;
  loading?: boolean;
}

export function ProfileCard(props: ProfileCardProps) {
  const { employeeName, employeeAvatarId, companyName, departmentName, loading } = props;
  const companyDetails = departmentName ? `${companyName} | ${departmentName}` : companyName;
  return (
    <div className={cx(styles.Wrapper, { [styles.loading]: loading })}>
      <div className={styles.Avatar}>
        <Avatar user={{ fullName: employeeName, avatarId: employeeAvatarId }} />
      </div>
      <div className={`${styles.Texts} ${styles.FlexColumn}`}>
        <div className={styles.EmployeeDetails}>{employeeName}</div>
        {companyDetails ? <div className={styles.CompanyDetails}>{companyDetails}</div> : null}
      </div>
    </div>
  );
}
