import { PropsWithChildren } from "react";
import "../../styles/App.scss";
import "../../styles/main.scss";
import "../../styles/avatar.scss";

const Signup = ({ children }: PropsWithChildren) => (
  <div className="main-wrapper">
    <div className="linear-bg">
      <div className="bg-layer1" />
      <div className="bg-layer2" />
    </div>

    {children}
  </div>
);

export default Signup;
