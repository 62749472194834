import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import FormikField from "components/UI/FormikHelpers/Field";
import Button from "components/UI/Button";

import BEM from "utils/BEM";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { Location } from "types/models/location";
import { addWiFiValidation } from "utils/apiHelpers";
import "./WiFi.scss";

const b = BEM.b("validation-wifi-form");

interface ValidationWiFiFormProps {
  currentLocation: Location;
  onCancel: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const ValidationWiFiForm = ({ currentLocation, onCancel, setNotification, onYes }: ValidationWiFiFormProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);

  const createWiFiValidation = async (values) => {
    try {
      await addWiFiValidation(currentLocation.id, { body: { wifi_point: values } });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  return (
    <Formik
      key="new"
      initialValues={{
        title: "",
        ssid: "",
        bssid_ids: "",
        mac_address: "",
        model_info: "",
      }}
      validate={(values) => {
        const errors: Record<string, string> = {};

        if (!values.title) {
          errors.title = t("Can't be blank");
        }
        if (!values.ssid) {
          errors.ssid = t("Can't be blank");
        }
        if (!values.bssid_ids) {
          errors.bssid_ids = t("Can't be blank");
        }

        return errors;
      }}
      onSubmit={(values) => {
        void createWiFiValidation(values);
      }}
      render={({ errors }) => (
        <Form className={b()}>
          <h2 className={b("heading")}>{t("Wi-Fi Setup")}</h2>
          <FormikField
            name="title"
            autocomplete="off"
            title={t(`${TranslationNamespaces.common}|Name`)}
            placeholder={t(`${TranslationNamespaces.common}|Name`)}
            errors={errors}
          />
          <FormikField name="ssid" title={t("SSID")} placeholder={t("SSID")} errors={errors} />
          <FormikField name="bssid_ids" title={t("BSSID")} placeholder={t("BSSID")} errors={errors} />
          <FormikField name="mac_address" title={t("MAC Address")} placeholder={t("MAC Address")} errors={errors} />
          <FormikField name="model_info" title={t("Model Info")} placeholder={t("Model Info")} errors={errors} />

          <div className={b("action-buttons")}>
            <Button label={t("Cancel")} modifier="bordered" className={b("action-button")} onClick={onCancel} />
            <Button type="submit" className={b("action-button")} label={t("Create")} />
          </div>
        </Form>
      )}
    />
  );
};

export default ValidationWiFiForm;
