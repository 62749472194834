import { GetCompanyRulesResponseData } from "./api/types";

export enum LocalStorageKeys {
  authToken = "oi_auth",
  profile = "gs_profile",
  profiles = "gs_profiles",
  loginAsMode = "gs_loginAsMode",
  subscriptions = "gs_subscriptions",
  customer = "gs_customer",
  hideDiscount = "gs_hide_initial_discount",
  widgetTab = "gs_widget_tab",
  employeeTerm = "gs_employee_term",
  permissionRoles = "gs_roles",
  requestTypes = "gs_requestTypes",
  downloads = "gs_downloads",
  operations = "gs_operations",
  defaultPayPeriod = "gs_defaultPayPeriod",
  companyDashboards = "gs_company_dashboards",
  employeeCenterColumns = "employeeCenter-colDef",
  deactivatedEmployeesColumns = "deactivatedEmployee-colDef",
  lastNotificationUuid = "gs_lastNotificationUuid",
  isTimesheetEnabled = "gs_isTimesheetEnabled",
  betaFlag = "gs_feature_beta",
  locale = "gs_locale",
  embedded = "gs_embedded",
}

export const getObjectFromLocalStorage = (key: LocalStorageKeys) => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      localStorage.removeItem(key);
    }
  }
  return null;
};

export const setObjectToLocalStorage = (key: LocalStorageKeys, value: any | null) => {
  if (value === null) {
    localStorage.removeItem(key);
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

/** Clears all LocalStorageKeys items from localStorage */
export const clearLocalStorage = () => {
  Object.values(LocalStorageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const getLsProfile = () => getObjectFromLocalStorage(LocalStorageKeys.profile);
export const setLsProfile = (profile: any | null) => setObjectToLocalStorage(LocalStorageKeys.profile, profile);

export const setLsEmployeeTerm = (companyRulesRes: GetCompanyRulesResponseData) => {
  if (
    companyRulesRes?.business_rules?.find((rule) => rule.name === "employee_term_singular")?.value &&
    companyRulesRes?.business_rules?.find((rule) => rule.name === "employee_term_plural")?.value
  ) {
    setObjectToLocalStorage(LocalStorageKeys.employeeTerm, {
      singular: companyRulesRes?.business_rules?.find((rule) => rule.name === "employee_term_singular")?.value,
      plural: companyRulesRes?.business_rules?.find((rule) => rule.name === "employee_term_plural")?.value,
    });
  } else {
    window.localStorage.removeItem(LocalStorageKeys.employeeTerm);
  }
};

export const getLsEmployeeTerm = () => getObjectFromLocalStorage(LocalStorageKeys.employeeTerm);
