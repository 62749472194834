import { useState, useEffect } from "react";
import styled from "styled-components";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import { DatePickerCommonOverrides, CalendarInsideNext, CelandarInsidePrev } from "../styled";

const Wrapper = styled(DatePickerCommonOverrides)`
  display: flex;
  align-items: center;

  .SingleDatePickerInput {
    background: none;

    .DateInput {
      background: none;
    }
  }
  .DateInput_input {
    border: none;
    background: none;
  }
  .DateInput_input__disabled {
    font-style: normal;
    cursor: text;
  }
`;

export interface InlineDatepickerProps {
  value?: string;
  onChange?: (date: string) => void;
  isOutsideRange?: (date: string) => boolean;
  disabled?: boolean;
  isRTL?: boolean;
}

/**
 * Is used when control looks like a text label.
 * For example - Edit Activity popup
 * @param props
 * @returns
 */
export default function InlineDatepicker(props: InlineDatepickerProps) {
  const {
    value: valueProp = moment().format("YYYY-MM-DD"),
    onChange,
    disabled,
    isOutsideRange = () => false,
    isRTL = false,
  } = props;
  const handleChange = (value: moment.Moment | null) => {
    const date = value && moment(value).isValid() ? moment(value).clone() : moment().clone();
    onChange && onChange(date.format("YYYY-MM-DD"));
  };

  const [t] = useTranslation();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(() => moment(valueProp, "YYYY-MM-DD"));
  useEffect(() => {
    setValue(moment(valueProp, "YYYY-MM-DD"));
  }, [valueProp]);

  return (
    <Wrapper>
      <SingleDatePicker
        id="single-date-picker"
        isRTL={isRTL}
        anchorDirection={isRTL ? "right" : "left"}
        date={value}
        disabled={disabled}
        required
        daySize={32}
        horizontalMargin={10}
        verticalSpacing={10}
        hideKeyboardShortcutsPanel
        phrases={{}}
        navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
        navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
        displayFormat="MMM D, YYYY"
        weekDayFormat="ddd"
        monthFormat="MMMM YYYY"
        noBorder
        small
        isOutsideRange={(m: moment.Moment) => isOutsideRange(m.format("YYYY-MM-DD"))}
        onDateChange={handleChange}
        focused={focused}
        placeholder={t("Date")}
        numberOfMonths={1}
        onFocusChange={(evt) => setFocused(evt.focused)}
      />
    </Wrapper>
  );
}
