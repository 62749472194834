import { useCallback, useEffect, useMemo, useState } from "react";
import "./HeaderTimeTracker.scss";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga, { GaAddTimeButtonLocation, GaStartTimeButtonLocation } from "utils/ga";
import { useProjectsWsService, useWidgetProjectsService } from "utils/useServices";
import { useAsyncCallback } from "utils/useAsyncEffect";
import config from "config";
import { hrsMinsToMins } from "utils/common";
import moment from "moment";
import TimerButton from "./components/TimerButton";
import { DeleteIcon, ResetActivityButton } from "./components/TimerButtons";
import { TaskControlWrapper } from "./components/TaskControl";
import { StoredActivity, useTrackTimeStore } from "./TrackTime.store";
import { DateControl } from "./components/DateControl";
import { DetailsControl } from "./components/DetailsControl";

enum TimeTrackerState {
  Default = 'default',
  Delete = 'delete',
}

export enum ActivityStatus {
  running = "running",
}

interface TimerComponentProps {
  // onRefetchActivities: () => void;
  // onAddActivitiesClick: () => void;
}

export const HeaderTimeTracker = (props: TimerComponentProps) => {
  const {t} = useTranslation(TranslationNamespaces.activities);
  const {showDatePicker, showTaskPicker, duration, setShowTaskPicker, setShowDatePicker, showTaskDetails, activity, setActivity, touched, runningActivityLoaded, setRunningActivityLoaded} = useTrackTimeStore();
  const [state, setState] = useState(TimeTrackerState.Default);

  const widgetProjectsService = useWidgetProjectsService();
  const projectsWsService = useProjectsWsService();

  const [loadData] = useAsyncCallback(async (force = false) => {
    let runningAct = null;
    if (runningActivityLoaded && !force) return;

    try {
      runningAct = await widgetProjectsService.getRunningActivity();
    } catch (error) {
      runningAct = null;
    } finally {
      if (runningAct || (activity?.uuid && !runningAct)) {
        setActivity(runningAct);
      }
      setRunningActivityLoaded(true);
    }
  }, [projectsWsService]);

  const [connectWs] = useAsyncCallback(async () => {
    await projectsWsService?.connect();
    projectsWsService?.on("activity-updated", () => void loadData(true));
  }, [projectsWsService, loadData]);

  useEffect(() => void loadData(), [loadData]);
  useEffect(() => {
    void connectWs();
    return () => void projectsWsService?.disconnect();
  }, [connectWs, projectsWsService]);

  const addActivity = async () => {
    if (!activity?.task) {
      setShowTaskPicker(true);
      return;
    }

    if (!activity.startTime || !activity.duration || !activity.date) {
      setShowDatePicker(true);
      return;
    }
    const payload = {
      status: activity.status,
      date: activity.date,
      startTime: activity.startTime,
      endTime: activity.endTime,
      duration: activity.duration,
      locationUuid: activity?.locationUuid || activity?.location?.uuid || null,
      taskUuid: activity.task.uuid,
      projectUuid: activity.project?.uuid,
      customFields: activity.customFields || [],
      attachments: activity.attachments?.map(a=>({uuid: a.uuid})) || [],
    }
    if (activity.startTime < 0) {
      const tmpDate = moment(activity.date).subtract(Math.abs(activity.startTime), 'minutes');
      payload.startTime = tmpDate.diff(tmpDate.clone().startOf('day'), 'minutes');
      payload.date = tmpDate.format(config.apiDateFormat);
    }
    await widgetProjectsService.createActivity(payload);
    ga.trackAddTime(GaAddTimeButtonLocation.topBar);
    setActivity(null);
  };

  const updateActiveActivity = useCallback(async (upAct: StoredActivity) => {
    const { uuid, date, taskUuid, locationUuid, projectUuid, endTime, stopReason, startTime, startedAt, customFields, attachments, location } = upAct;
    const payload = {
      attachments: (attachments || []).map((a) =>({uuid: a.uuid})),
      customFields: customFields || [],
      uuid,
      date,
      taskUuid,
      locationUuid: locationUuid || location?.uuid || null,
      projectUuid,
      startTime,
      startedAt,
      endTime,
      stopReason,
    };
    await widgetProjectsService.updateActivity(payload);
  }, []);

  const Actions = useMemo(() => (
    <div className="Actions">
      <TimerButton
        addActivityMode={!!duration && duration > 0 && (activity?.status === 'pending' || touched)}
        onAddActivity={async () => {
          await addActivity();
        }}
        isActivityRunning={activity?.status === ActivityStatus.running}
        onStartActivity={async () => {
          if (activity?.status !== ActivityStatus.running) {
            // optimistic response by Liran request
            const now = moment();
            setActivity({
              ...activity,
              status: 'running',
              date: now.format("YYYY-MM-DD"),
              createdAt: now.toISOString(),
              startTime: hrsMinsToMins(now.format("HH:mm")),
            });
            const startedActivity = await widgetProjectsService.startActivity({
              projectUuid: activity?.project?.uuid,
              taskUuid: activity?.task?.uuid,
              status: ActivityStatus.running,
              attachments: (activity?.attachments || []).map(a => ({uuid: a.uuid})),
              customFields: activity?.customFields || [],
              locationUuid: activity?.locationUuid || activity?.location?.uuid || null,
            });
            setActivity(startedActivity);

            ga.trackStartTimeTracking(GaStartTimeButtonLocation.topBar);
          }
        }}
        onStopActivity={async () => {
          if (!activity) return;
          if (activity?.status === ActivityStatus.running) {
            if (!activity?.taskUuid || !activity?.projectUuid) {
              setShowTaskPicker(true);
            } else {
              await widgetProjectsService.stopActivity(activity);
              ga.trackStopTimeTracking(GaStartTimeButtonLocation.topBar);
              setActivity(null);
              // void props.onRefetchActivities();
            }
          }
        }}
      />
    </div>
    ), [activity, duration, touched]);

  return (
    <div className={`HeaderTimeTracker ${showTaskPicker || showTaskDetails || showDatePicker ? "ActiveDropdown" : ""}`}>
      { state === TimeTrackerState.Default ? (
        <div className="wrapper">
          <div className="container">
            <DetailsControl updateActiveActivity={updateActiveActivity} />
            <TaskControlWrapper updateActiveActivity={updateActiveActivity} store={useTrackTimeStore}/>
            <DateControl updateActiveActivity={updateActiveActivity} />
            {Actions}
          </div>
          {activity?.status === ActivityStatus.running && !showTaskPicker && !showTaskDetails && !showDatePicker && (
            <ResetActivityButton
              onClick={() => {
                setState(TimeTrackerState.Delete);
              }}
            />
          )}
        </div>) : (
        <div className="wrapper">
          <div className="container DeleteState">
            <div className="DeleteIcon"><DeleteIcon /></div>
            <div className="DeleteText">{t("Delete this activity?")}</div>
            <div className="DeleteActions">
              <button className="CancelBtn" type="button" onClick={() => setState(TimeTrackerState.Default)}>{t(`${TranslationNamespaces.common}|Cancel`)}</button>
              <button className="DeleteBtn" type="button" onClick={async () => {
                if (activity?.status !== null) {
                  await widgetProjectsService.deleteActivity(activity);
                }
                setActivity(null);
                setState(TimeTrackerState.Default);
              }}>{t(`${TranslationNamespaces.common}|Delete`)}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
