import { useState, useEffect, useMemo } from "react";
import { DashboardChartData } from "types/models/dashboard";
import usePeriodAggregation, { AggregationParameters } from "./usePeriodAggregation";
import DEFAULT_CHART_DATA from "./defaultChartData";

const useMissingHours = (params: Omit<AggregationParameters<"missedMinutes">, "requestedProps">) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<FusionCharts.ChartObject>(DEFAULT_CHART_DATA);

  const periodAggregationParams = useMemo(
    () => ({
      ...params,
      requestedProps: ["missedMinutes" as const],
    }),
    [params],
  );

  const { isLoading: isDataLoading, data: processedData } = usePeriodAggregation(periodAggregationParams);

  useEffect(() => {
    setIsLoading(true);
    if (isDataLoading || !processedData) {
      return;
    }

    const chartData: DashboardChartData = {
      categories: [{ category: processedData.categories }],
      dataset: [],
      noData: false,
    };

    Object.entries(processedData.dataset).forEach(([seriesname, periodAggregation]) => {
      chartData.dataset.push({
        seriesname,
        data: periodAggregation.map((pa) => ({
          value: -pa.missedMinutes / 60 || undefined /* so that zeroes are not displayed */,
        })),
      });
    });

    // fix for internal FusionCharts error (data is undefined)
    if (!chartData.dataset.length) {
      chartData.dataset.push({
        seriesname: "",
        data: [],
      });
      chartData.noData = true;
    }

    setData({
      ...DEFAULT_CHART_DATA,
      dataSource: {
        ...DEFAULT_CHART_DATA.dataSource,
        ...chartData,
      },
    });

    setIsLoading(false);
  }, [isDataLoading]);

  return { isLoading, data };
};

export default useMissingHours;
