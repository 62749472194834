import { Component } from "react";
import BEM from "utils/BEM";
import { getTitle } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import SettingsLayout from "components/Layout/SettingsLayout";
import { getIntegration, getCurrentCompany } from "utils/apiHelpers";
import pegadorImg from "img/pegador.png";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import PegadorContent from "../Content";
import "components/UI/Page/Page.scss";
import "styles/rep-pegador.scss";

const b = BEM.b("rep-pegador");
const p = BEM.b("page");

type PegadorPageProps = WithTranslation;

interface PegadorPageState {
  loaded: boolean;
  lastSync: string | null;
  token: string | null;
}

class PegadorPage extends Component<PegadorPageProps, PegadorPageState> {
  constructor(props: PegadorPageProps) {
    super(props);

    this.state = {
      loaded: false,
      lastSync: null,
      token: null,
    };

    document.title = getTitle("Pegador");
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    try {
      const company = await getCurrentCompany();
      if (!company) return;

      const r = await getIntegration({ companyUuid: company.uuid });

      if (r && r.integration) {
        this.setState({
          token: r.integration.token || null,
          lastSync: r.integration.status || null,
          loaded: true,
        });
      } else {
        this.setState({ loaded: true });
      }
    } catch (e) {
      sentryUtils.sendError(e);
      window.alert(e);
    }
  };

  render() {
    const { t } = this.props;
    const { loaded, token, lastSync } = this.state;

    if (!loaded) {
      return t("Loading...");
    }

    return (
      <SettingsLayout title={t(`${TranslationNamespaces.common}|Settings`)}>
        <div
          className={`${p()} ${b()}`}
          style={{
            background: `url(${pegadorImg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "90% 90%",
            marginTop: 20,
          }}
        >
          <PegadorContent token={token} lastSync={lastSync} />
        </div>
      </SettingsLayout>
    );
  }
}

export default withTranslation([TranslationNamespaces.reps, TranslationNamespaces.sidebar])(PegadorPage);
