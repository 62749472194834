import styled from "styled-components";
import { useState, useEffect } from "react";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import moment from "moment-timezone";
import { PunchType } from "types/models/punches";
import { stylesheet } from "astroturf";
import cx from "classnames";
import { Location } from "services/punch-service/types";
import TimeInput from "../TimeInput";
import { SpinnerSmall } from "../styled";
import { Button, ButtonType } from "../Button";
import DropdownButton from "../DropdownButton";
import { LocationSelector, LocationSelectorProps } from "../LocationSelector";
import { Modal } from "../Modal";

export { PunchType };

const styles = stylesheet`
@use 'styles/loading';

.Wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 24px;

  .Top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    max-height: 102px;
    margin-bottom: 4px;

    .Warning {
      box-sizing: border-box;
      padding: 13px 15px;
      font-size: 12px;
      font-style: normal;
      font-weight: var(--typography-font-weight-default);
      line-height: 15px;
      color: #424242;
      text-align: center;
      background: rgb(251 197 51 / 10%);
      border: 1px solid #f3e7c6;
      border-radius: var(--shapes-border-radius-default);

      p {
        margin: 0;
      }

      a {
        font-size: inherit;
        font-weight: var(--typography-font-weight-medium);
        color: #1e97f7;
        text-decoration: inherit;
        cursor: pointer;
      }
    }

    .Header {
      margin-top: 30px;
      overflow: hidden;
      font-size: 20px;
      font-style: normal;
      font-weight: var(--typography-font-weight-default);
      line-height: 25px;
      text-align: center;
      text-overflow: ellipsis;
      letter-spacing: -0.03em;
      transition: none;

      strong {
        font-weight: var(--typography-font-weight-medium);
      }
    }
  }

  .Middle {
    display: flex;
    flex-direction: column;
    flex-grow: 5;
    align-items: center;
    justify-content: flex-start;
    max-height: 290px;
    margin-bottom: 12px;

    .DateWrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
      max-height: 68px;
      padding-top: 0;
      padding-bottom: 4px;

      .DateText {
        font-size: 16px;
        font-style: normal;
        font-weight: var(--typography-font-weight-default);
        line-height: 24px;
        text-align: center;
      }
    }
  }

  .Bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    width: 300px;
    max-height: 90px;
    padding-bottom: 20px;

    button {
      height: 40px;

      span {
        font-weight: var(--typography-font-weight-medium);
      }
    }
  }

  .LocationSelectorModal {
    > * {
      display: flex;
      max-height: 80%;
    }
  }
}

.Wrapper:global(.wireframe) {
  .Top {
    > * {
      @extend .wireframe;

      width: 200px;
      height: 28px;
    }
  }

  .Middle {
    > *:first-child {
      @extend .wireframe;
    }

    .DateWrapper .DateText {
      @extend .wireframe;

      width: 160px;
      height: 24px;
    }

    > *:last-child {
      @extend .wireframe;

      width: 130px;
      height: 36px;
      border-radius: 29px;
    }
  }

  .Bottom {
    button {
      @extend .wireframe;

      border-radius: 20px;
    }
  }
}
`;

const LocationDropdownButton = styled(DropdownButton)`
  min-width: 130px;
  hyphens: auto;
`;

function getGreeting(HHmm: string): string {
  const hours = Number.parseInt(HHmm.slice(0, 2), 10);
  if (hours >= 4 && hours < 12) return "Good morning,";
  if (hours >= 12 && hours < 19) return "Good afternoon,";
  return "Good night,";
}

export interface PunchCreateProps {
  loading: boolean;
  initialLoading: boolean;
  employeeName: string;
  timeVerified: boolean;
  locationVerified: boolean;
  /** HH:mm */
  time: string;
  /** YYYY-MM-DD */
  date: string;
  selectedLocation: LocationSelectorProps["selectedOption"];
  locations: LocationSelectorProps["options"];
  punchTypeName: PunchType;
  /** emit null if location selector was closed without selecting an option for tracking purposes */
  onLocationSelected(option: Location | null): void;
  onTimeEdit: () => void;
  onTimeEditFinished: (value: string) => void;
  onCreate: () => void;
}

export function PunchCreate(props: PunchCreateProps) {
  const {
    loading,
    initialLoading,
    time,
    date: dateProp,
    employeeName,
    timeVerified,
    locationVerified,
    selectedLocation,
    locations,
    onLocationSelected,
    punchTypeName,
    onTimeEdit,
    onTimeEditFinished,
    onCreate,
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const [greeting, setGreeting] = useState<string>("");
  const [date, setDate] = useState<string>("");

  useEffect(() => {
    const greetingText = getGreeting(time);
    setGreeting(greetingText);
  }, [time]);

  useEffect(() => {
    const d = moment(dateProp, "YYYY-MM-DD").format("LL");
    setDate(d);
  }, [dateProp]);

  const [showSelectLocation, setShowSelectLocation] = useState<boolean>(false);

  const needsApproval = !(timeVerified && locationVerified);

  return (
    <div className={cx(styles.Wrapper, { wireframe: initialLoading })}>
      <div className={styles.Top}>
        {needsApproval ? (
          <div className={styles.Warning}>
            {!locationVerified && <p>{t("We were not able to validate your IP")}</p>}
            {!timeVerified && locationVerified && (
              <p>
                {t("Your time is not validated, click for")}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://support.day.io/hc/pt-br/articles/14826034835479" target="_blank">
                  {t("More Info")}
                </a>
              </p>
            )}
            <p>{t("Your Punch will be sent for supervisor approval")}</p>
          </div>
        ) : (
          <div className={styles.Header}>
            {greeting ? t(greeting) : ""} <strong>{employeeName || ""}</strong>!
          </div>
        )}
      </div>
      <div className={styles.Middle}>
        <TimeInput
          value={time}
          disabled={timeVerified}
          hasError={!timeVerified}
          onChange={onTimeEdit}
          onChangeFinished={onTimeEditFinished}
        />
        <div className={styles.DateWrapper}>
          <div className={styles.DateText}>{date}</div>
        </div>
        <LocationDropdownButton
          disabled={loading}
          noOptions={locations.length <= 1}
          onClick={() => setShowSelectLocation(true)}
        >
          {selectedLocation?.code} - {selectedLocation?.name}
        </LocationDropdownButton>
      </div>
      <div className={styles.Bottom}>
        {loading ? (
          <Button disabled buttonType={ButtonType.neutral}>
            <SpinnerSmall />
          </Button>
        ) : (
          <Button buttonType={needsApproval ? "warning" : "regular"} onClick={onCreate}>
            <span>
              {needsApproval ? t("Request") : t("Punch")} <strong>{t(`punch-button:${punchTypeName}`)}</strong>
            </span>
          </Button>
        )}
      </div>
      {showSelectLocation && (
        <Modal
          className={styles.LocationSelectorModal}
          onBackgroundClick={() => {
            setShowSelectLocation(false);
            onLocationSelected(null);
          }}
        >
          <LocationSelector
            options={locations}
            selectedOptionId={selectedLocation.id}
            onOptionSelected={(loc) => {
              setShowSelectLocation(false);
              onLocationSelected(loc);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
