import { stylesheet } from "astroturf";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Attachments, { AttachmentsProps } from "components/Requests/Attachments";
import { InlineInput, InlineNumericInput } from "components/views/Inline";
import cx from "classnames";
import { ActivityFieldDropdown } from "../ActivityFieldDropdown";
import { ActivityField } from "../ActivityField";

export const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--colors-default);

  > :not(:last-child) {
    border-bottom: 1px solid #E9EDF5;
  }

  .FieldValue {
    margin: -0.22em 0 -0.10em; /* make input take the same height as a div would */
    font: inherit;
    text-align: start;
  }

  .Attachments {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .LockedText {
    margin-top: 10px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-default);
    color: var(--colors-secondary);
    text-align: center;
  }
}
`;

export type ActivityDetailsProps = React.HTMLProps<HTMLDivElement> & {
  client?: string;
  task?: string;
  project?: string;
  onTaskSelect: () => void;
  location?: string;
  onLocationSelect(): void;
  customFields: {
    uuid: string;
    name: string;
    value: string | null;
    required: boolean;
    fieldType: "numeric" | "input";
  }[];
  onCustomFieldChange(uuid: string, value: string): void;
  attachments: AttachmentsProps["attachments"];
  uploadAttachment?: AttachmentsProps["upload"];
  removeAttachment?: AttachmentsProps["remove"];
  onAttachmentsChange?: AttachmentsProps["onChange"];
  edit?: boolean;
  errors?: {
    task?: boolean;
    customFields?: {
      [uuid: string]: boolean;
    };
  };
  isNew?: boolean;
};

export function ActivityDetails(props: ActivityDetailsProps) {
  const {
    client,
    project,
    task,
    onTaskSelect,
    location,
    onLocationSelect,
    customFields,
    onCustomFieldChange,
    attachments,
    uploadAttachment,
    removeAttachment,
    onAttachmentsChange,
    edit = false,
    errors,
    isNew,
    ...restProps
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const editAttachments = false;

  return (
    <div className={cx(styles.Wrapper, { [styles.noEdit]: !edit })} {...restProps}>
      <ActivityFieldDropdown
        tabIndex={0}
        disabled={!edit}
        title={t("Task")}
        value={task}
        placeholder={t(isNew ? "empty_task_placeholder" : "What are you working on?")}
        onClick={onTaskSelect}
        error={errors?.task}
      />
      {task != null && (
        <ActivityFieldDropdown
          tabIndex={0}
          disabled={!edit}
          title={t("Location")}
          value={location}
          placeholder={t("Select location")}
          onClick={onLocationSelect}
          error={errors?.task}
        />
      )}
      {customFields.map((cf) => {
        const Inp = cf.fieldType === "numeric" ? InlineNumericInput : InlineInput;
        return (
          <ActivityField
            key={cf.uuid}
            title={cf.required ? `${cf.name} *` : cf.name}
            error={errors?.customFields?.[cf.uuid]}
          >
            <Inp
              disabled={!edit}
              className={styles.FieldValue}
              value={cf.value || ""}
              placeholder={cf.fieldType === "numeric" ? "0" : "-"}
              onChangeFinished={(val) => onCustomFieldChange(cf.uuid!, val)}
            />
          </ActivityField>
        );
      })}
      {((editAttachments && edit) || (attachments && attachments.length > 0)) && (
        <div className={styles.Attachments}>
          <div className={styles.FieldName}>{t("Attachments")}:</div>
          <Attachments
            edit={editAttachments && edit}
            upload={uploadAttachment}
            remove={removeAttachment}
            attachments={attachments}
            onChange={onAttachmentsChange}
          />
        </div>
      )}
      {!edit && <div className={styles.LockedText}>{t("The activity is locked for editing")}</div>}
    </div>
  );
}
