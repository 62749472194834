import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  cancelDigitalSignatureForProfile,
  dismissDigitalSignatureForProfile,
  fireDownloadReport,
  getCompanyRules,
  getDigitalSignaturesGroup,
  getLockById,
  requestDigitalSignatures,
  resendDigitalSignatureForProfile,
} from "utils/apiHelpers";
import { withRouter } from "react-router-dom";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import StatusBadge from "components/controls/StatusBadge";
import { PageWrapper } from "components/styled/Page";
import * as moment from "moment";
import { getTitle, showSnackbar, strIncludesCheck } from "utils/common";
import styled from "styled-components";
import Select from "components/UI/Select";
import TableButtonsControl from "components/styled/TableButtonsControl";
import TableUserProfile from "components/styled/TableUserProfile";
import HeaderActionButtonAdd from "components/controls/HeaderActionButton";
import FullPage from "components/Layout/FullPage";
import { TranslationNamespaces } from "types/translationNamespaces";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { PayrollGroupName } from "components/Payroll/types";
import GlobalContext from "../../context/global-context";
import noReports from "../../img/no-signratures.png";
import RequestDigitalSignaturesPopup from "./RequestDigitalSignaturesPopup";

const title = "Digital Signatures";
const metaTitle = title;
const StatusFilterWrapper = styled.div`
  margin: 0 16px;
  width: 120px;
`;
const ReportHeader = styled.div``;
const ReportTitle = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 18px;
  line-height: 26px;
  color: var(--colors-mainText);
`;
const ReportSubtitle = styled.div`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: #a1b2cf;
  margin-top: 2px;
`;

class DigitalSignaturesGroup extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      selectedItem: null,
      searchStatus: "",
      notification: "",
      searchValue: "",
      cancelConfirmationPopupVisible: false,
      isFetching: true,
      isMadanRashiEnabled: false,
      payrollLock: null,
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    this.getState();
  }

  getState = async () => {
    const { payrollLockUuid } = this.props.match.params;
    this.setState({ isFetching: true });
    const [company, businessRules] = await Promise.all([this.context.getCompany(), await getCompanyRules()]);

    const isMadanRashiEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === "madan_rashi_report_as_digital_signature")?.value ||
      false;

    const response = await getDigitalSignaturesGroup({
      companyUuid: company.uuid,
      payrollLockUuid,
    });
    let payrollLock = await getLockById({
      companyUuid: company.uuid,
      payrollLockUuid,
    });
    payrollLock = payrollLock.content;

    const employees = response.content;
    const userProfilesUuids = employees.map((e) => e.userProfileUuid);

    const { content } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, userProfilesUuids),
    );
    let employeesWithDetails = content;
    employeesWithDetails = employeesWithDetails.map((e) => {
      const employee = employees.filter((emp) => emp.userProfileUuid === e.uuid)[0];
      const { createdAt, status, statusChangedAt, reportHash, uuid } = employee;
      return {
        signatureCreatedAt: createdAt,
        signatureStatus: status,
        signatureSignedAt: status === "signed" ? statusChangedAt : "",
        reportHash,
        digitalSignatureUuid: uuid,
        ...e,
      };
    });

    this.setState({
      items: employeesWithDetails,
      isMadanRashiEnabled,
      isFetching: false,
      payrollLock,
    });
  };

  cancelDigitalSignature = async (item) => {
    const { t } = this.props;
    try {
      await cancelDigitalSignatureForProfile({
        companyUuid: window.global_store.company.uuid,
        digitalSignatureUuid: item.digitalSignatureUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: "success",
        notification: t("You canceled digital signature for {{employeeName}}", {
          employeeName: item.name,
        }),
      });
      this.getState();
    } catch (e) {
      let errorMessage = t("common|Something went wrong");
      if (e.message) {
        errorMessage = t(e.message);
      }
      if (e.originalRequest?.errors?.length && e.originalRequest.errors[0].message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        cancelConfirmationPopupVisible: false,
        selectedItem: null,
        notification: errorMessage,
        notificationType: "error",
      });
    }
  };

  dismissDigitalSignature = async (item) => {
    const { t } = this.props;
    try {
      await dismissDigitalSignatureForProfile({
        companyUuid: window.global_store.company.uuid,
        digitalSignatureUuid: item.digitalSignatureUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: "success",
        notification: t("You dismissed digital signature for {{employeeName}}", { employeeName: item.name }),
      });
      this.getState();
    } catch (e) {
      let errorMessage = t("common|Something went wrong");
      if (e.message) {
        errorMessage = t(e.message);
      }
      if (e.originalRequest?.errors?.length && e.originalRequest.errors[0].message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        dismissConfirmationPopupVisible: false,
        selectedItem: null,
        notification: errorMessage,
        notificationType: "error",
      });
    }
  };

  resendDigitalSignature = async (item) => {
    const { t } = this.props;
    try {
      await resendDigitalSignatureForProfile({
        companyUuid: window.global_store.company.uuid,
        digitalSignatureUuid: item.digitalSignatureUuid,
        body: {
          content: {
            statusChangedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        selectedItem: null,
        notificationType: "success",
        notification: t("You resent digital signature for {{employeeName}}", {
          employeeName: item.name,
        }),
      });
      this.getState();
    } catch (e) {
      let errorMessage = t("common|Something went wrong");
      if (e.message) {
        errorMessage = t(e.message);
      }
      if (e.originalRequest?.errors?.length && e.originalRequest.errors[0].message) {
        errorMessage = t(e.originalRequest.errors[0].message);
      }
      this.setState({
        cancelConfirmationPopupVisible: false,
        resendConfirmationPopupVisible: false,
        selectedItem: null,
        notification: errorMessage,
        notificationType: "error",
      });
    }
  };

  requestDigitalSignatures = ({ payrollGroupUuid, payrollLockUuid, selectedColumns }) => {
    const { t } = this.props;

    requestDigitalSignatures({
      payrollGroupUuid,
      payrollLockUuid,
      body: {
        content: {
          selectedColumns,
          createdBy: window.global_store.profile.uuid,
        },
      },
      companyUuid: window.global_store.company.uuid,
    })
      .then(() => {
        this.setState(
          {
            newPopupVisible: false,
            notification: `${t("The Digital Signature requests were sent to all the employees.")}`,
            notificationType: "success",
          },
          () => this.getState(),
        );
      })
      .catch((e) => {
        let errorMessage = t("common|Something went wrong");
        if (e.message) {
          errorMessage = t(e.message);
        }
        if (
          e.originalRequest &&
          e.originalRequest.errors &&
          e.originalRequest.errors.length &&
          e.originalRequest.errors[0].message
        ) {
          errorMessage = t(e.originalRequest.errors[0].message);
        }
        this.setState({
          notification: errorMessage,
          notificationType: "error",
          newPopupVisible: false,
        });
      });
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns(rows = []) {
    const { t } = this.props;
    const columns = [
      {
        label: t("common|employee"),
        accessor: "uuid",
        minWidth: 160,
        Cell: (row) => <TableUserProfile {...row.original} />,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Requested"),
        accessor: "signatureCreatedAt",
        Cell: (row) => moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY"),
      },
      {
        label: t("Signed"),
        accessor: "signatureSignedAt",
        Cell: (row) => (row.value ? moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY") : ""),
      },
      {
        label: t("common|Status"),
        accessor: "signatureStatus",
        minWidth: 50,
        align: "center",
        Cell: (row) => (
          <div>
            <StatusBadge value={row.value === "pending" ? t("common|Pending") : t(row.value)} type={row.value} />
            <TableButtonsControl
              dropToTop={row.viewIndex === rows.length - 1}
              dropdownButtons={this.getTableDropdownButtons({ row, t })}
              visibleButtons={this.getTableButtons({ row, t })}
            />
          </div>
        ),
      },
    ];
    return columns;
  }

  getTableDropdownButtons = ({ row, t }) => {
    const buttons = [];
    if (row.original.signatureStatus === "pending") {
      buttons.push({
        label: t("common|Cancel"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            cancelConfirmationPopupVisible: true,
          }),
      });
    }
    if (row.original.signatureStatus !== "signed") {
      buttons.push({
        label: t("Resend"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            resendConfirmationPopupVisible: true,
          }),
      });
    } else {
      buttons.push({
        label: t("Dismiss"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            dismissConfirmationPopupVisible: true,
          }),
      });
    }
    return buttons;
  };

  getTableButtons = ({ row, t }) => {
    const { isMadanRashiEnabled } = this.state;
    const buttons = [];
    if (row.original.signatureStatus === "signed" || (window.global_store.beta && row.original.reportHash)) {
      if (isMadanRashiEnabled) {
        buttons.push({
          label: t("common|madaan-rashi"),
          onClick: async () => {
            const response = await fireDownloadReport({
              searchObj: {
                employee: {
                  uuid: row.original.uuid,
                },
              },
              reportHash: row.original.reportHash,
              digitalSignatureUuid: row.original.digitalSignatureUuid,
              reportType: "madan_rashi",
            });
            showSnackbar({
              text: t("common|Preparing file for download…"),
              notificationStyle: "notice",
            });
            this.context.addToDownloads(response.report_uuid);
          },
        });
      }
      buttons.push({
        label: t("common|Download"),
        onClick: async () => {
          const response = await fireDownloadReport({
            searchObj: {
              employee: {
                uuid: row.original.uuid,
              },
            },
            reportHash: row.original.reportHash,
            digitalSignatureUuid: row.original.digitalSignatureUuid,
            forceDs: true,
            reportType: "digital_signature",
          });
          showSnackbar({
            text: t("common|Preparing file for download…"),
            notificationStyle: "notice",
          });
          this.context.addToDownloads(response.report_uuid);
        },
      });
    }
    return buttons;
  };

  render() {
    const {
      items,
      searchValue,
      isFetching,
      selectedItem,
      notification,
      notificationType = "success",
      cancelConfirmationPopupVisible,
      resendConfirmationPopupVisible,
      dismissConfirmationPopupVisible,
      searchStatus,
      newPopupVisible,
      payrollLock,
    } = this.state;
    const { t, history } = this.props;
    const actionButtonTitle = "Signatures";
    const noItemsTitle = "You don't have any Signature Request yet.";
    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.fullName, searchValue);
      if (searchStatus) {
        filter = filter && s.signatureStatus === searchStatus;
      }
      return filter;
    });

    let payrollGroupName = "";

    if (payrollLock?.name === PayrollGroupName.default) {
      payrollGroupName = t(`${TranslationNamespaces.common}|Default`);
    } else if (payrollLock?.name === PayrollGroupName.deactivatedProfile) {
      payrollGroupName = t(payrollLock?.name);
    } else if (payrollLock?.name) {
      payrollGroupName = payrollLock.name;
    }

    return (
      <FullPage
        title={t(title)}
        backButtonOnclick={() => history.push("/payroll/digital-signatures")}
        backButtonTitle={t("Digital Signatures")}
        headerAction={
          <HeaderActionButtonAdd
            title={t(actionButtonTitle)}
            onClick={() => this.setState({ newPopupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}
          <TablePage
            rows={filteredItems}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("common|Search")}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select
                    value={searchStatus}
                    onChange={(val) => this.setState({ searchStatus: val })}
                    options={[
                      { value: "", label: t("common|Status") },
                      { value: "pending", label: t("common|Pending") },
                      { value: "signed", label: t("common|Signed") },
                      { value: "canceled", label: t("Canceled") },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            tableDetails={
              payrollLock ? (
                <ReportHeader>
                  <ReportTitle>{payrollGroupName}</ReportTitle>
                  <ReportSubtitle>
                    {moment(payrollLock.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} -
                    {moment(payrollLock.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                  </ReportSubtitle>
                </ReportHeader>
              ) : null
            }
            columnSelectorOnFiltersRow
            columns={this.getTableColumns(items)}
            className="groups-table"
            loading={isFetching}
            noContentComponent={<NoContent img={noReports}>{t(noItemsTitle)}</NoContent>}
          />
          <ModalDialog
            isOpen={cancelConfirmationPopupVisible}
            onClose={() =>
              this.setState({
                cancelConfirmationPopupVisible: false,
                selectedItem: null,
              })
            }
          >
            <Lightbox
              title={t("Cancel Request")}
              text={t("Are you sure to cancel a request to digital signature?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|No")}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  cancelConfirmationPopupVisible: false,
                });
              }}
              onYes={this.cancelDigitalSignature.bind(this, selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={resendConfirmationPopupVisible}
            onClose={() =>
              this.setState({
                resendConfirmationPopupVisible: false,
                selectedItem: null,
              })
            }
          >
            <Lightbox
              title={t("Resend Request")}
              text={t("Are you sure to re-send a request to digital signature?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|No")}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  resendConfirmationPopupVisible: false,
                });
              }}
              onYes={this.resendDigitalSignature.bind(this, selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={dismissConfirmationPopupVisible}
            onClose={() =>
              this.setState({
                dismissConfirmationPopupVisible: false,
                selectedItem: null,
              })
            }
          >
            <Lightbox
              title={t("Dismiss digital signature")}
              text={t("Are you sure to dismiss a digital signature?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|No")}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  dismissConfirmationPopupVisible: false,
                });
              }}
              onYes={this.dismissDigitalSignature.bind(this, selectedItem)}
            />
          </ModalDialog>
          {newPopupVisible ? (
            <ModalDialog isOpen={newPopupVisible} onClose={() => this.setState({ newPopupVisible: false })}>
              <RequestDigitalSignaturesPopup
                onClose={() => {
                  this.setState({ newPopupVisible: false });
                }}
                onYes={this.requestDigitalSignatures}
              />
            </ModalDialog>
          ) : null}
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.payment)(DigitalSignaturesGroup));
