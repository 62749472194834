import { sendRequest } from "./common";

export type Client = {
  name: string;
  code: string;
  id: string;
  uuid: string;
};

export type GetClientsResponseData = {
  clients: Client[];
};

type TODORequestData = any;
type TODOResponseData = Promise<unknown>;

export function getClients(): Promise<GetClientsResponseData> {
  return sendRequest({}, "GET", "/clients");
}

export function getClient(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/clients/${data.uuid}`);
}
export function addClient(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", "/clients");
}

export function updateClient(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PUT", `/clients/${data.id}`);
}

export function deleteClient(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "DELETE", `/clients/${data.id}`);
}
