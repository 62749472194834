import styled from "styled-components";
import * as images from "components/svg-images";

const StyledDiv = styled.div`
  --bg-color: var(--cp-extra-night-tag-bg-color, var(--colors-surface-0));
  --border-color: var(--cp-extra-night-tag-bg-color, var(--colors-secondary2));
  --text-color: var(--cp-extra-night-tag-text-color, var(--colors-surface-800));
  --font-weight: var(--cp-extra-night-tag-font-weight, var(--typography-font-weight-medium));
  --icon-color: var(--cp-extra-night-tag-icon-color, #5190da);
  --font-family: var(--cp-extra-night-tag-font-family, var(--typography-font-family-default));

  height: 22px;
  border: 1px solid var(--border-color);
  border-radius: 100px;
  font-weight: var(--typography-font-weight-medium);
  font-size: 12px;
  color: var(--text-color);
  letter-spacing: -0.06px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background: var(--bg-color);
  width: fit-content;

  svg {
    margin-inline-end: 8px;
    fill: var(--icon-color);
  }
`;

interface ExtraNightTagProps {
  value: string;
}

const ExtraNightTag = ({ value }: ExtraNightTagProps): JSX.Element => (
  <StyledDiv>
    {images.scheduleNight} {value}
  </StyledDiv>
);

export default ExtraNightTag;
