import { sleep } from "./common";

async function sendExternalMessageToBrowserExtension(authToken: string) {
  const url = new URL(window.location.href);
  const extensionId = url.searchParams.get("dayio_extension_id");
  const browser = (window as any).chrome || (window as any).browser;
  const port = await browser.runtime.connect(extensionId, { name: "dayio-widget-login" });
  port.postMessage({ type: "widget-login", authToken });
  port.onMessage.addListener(async (msg) => {
    port.disconnect();
    if (msg.type === "login-ok") {
      document.write("ok");
      window.close();
    }
  });
  await sleep(10000);
  port.disconnect();
  document.write("something went wrong");
  throw Error("no confirmation from widget");
}

/**
 * Send authToken to URL of desktop app if provided.
 * Otherwise send token in a message to browser extension content script.
 * Wait for confirmation from the widget (desktop app or browser extension).
 * Close window if successful.
 */
export async function widgetLogin(authToken: string) {
  const url = new URL(window.location.href);
  const redirectUrl = url.searchParams.get("redirect_url") || null;
  if (redirectUrl) {
    const parsedRedirectUrl = new URL(redirectUrl);
    const isStandalone = parsedRedirectUrl.pathname === "/" && parsedRedirectUrl.hash === "#/login";
    if (isStandalone) {
      parsedRedirectUrl.searchParams.set("auth_token", authToken);
      window.location.href = parsedRedirectUrl.toString();
      return;
    }

    const validUrlRe = /^http:\/\/localhost:[0-9]+\/authorize$/;
    const isValidUrl = validUrlRe.test(redirectUrl);
    if (!isValidUrl) {
      document.write("something went wrong");
      throw Error(`invalid redirect url ${redirectUrl}`);
    }
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    const url = `${redirectUrl}?auth_token=${authToken}`;
    iframe.setAttribute("src", url); // unable to use fetch due to CORS restrictions
  } else {
    window.postMessage({ type: "widget-login", authToken });
    await sendExternalMessageToBrowserExtension(authToken);
  }

  // get response from either content-script injected by browser extension or iframe with the response page from desktop app
  window.addEventListener("message", async (event) => {
    if (event.data === "widget-login ok") {
      document.write("ok");
      await sleep(100);
      window.close();
    }
  });
  const confirmationTimeoutMs = 5000;
  await sleep(confirmationTimeoutMs);
  // window should be closed by this point if successful
  document.write("something went wrong");
  throw Error("no confirmation from widget");
}
