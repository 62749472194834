import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Coupon, Interval, Subscription, Tier } from "types/models/subscription";
import { SubscriptionApi } from "utils/api/subscription";
import { BillingService } from "../BillingService";
import PricingContainer, { ExistingItem, ProductItem } from "../PricingContainer";
import { ReactComponent as WarningIcon } from "../icons/info_notification_ic.svg";

const styles = stylesheet`@import "../billing.scss"`;
type UpgradeSeatsModalProps = {
  currentSeatsNumber: number;
  close: () => void;
  companyUuid: string;
  interval: Interval;
  coupon: Coupon | null;
  productItem: ProductItem;
  subscription: Subscription;
};
type UpgradeData = {
  seats: number;
  priceUuid: string;
  amount: number;
}

const UpgradeSeatsModal = (props: UpgradeSeatsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [existingItem, setExistingItem] = useState<ExistingItem>();
  const [usedSeatsNumber, setUsedSeatsNumber] = useState<number>(0);

  const getTier = (tiers: Tier[], value: number) => tiers.find((tierData) => tierData.up_to! >= value);
  useEffect(() => {
    if (props.subscription) {
      const item = props.subscription.items.find(
        (item) => item.price && item.feature !== props.productItem.product.features[0],
      );
      const currentSubscriptionItem = props.subscription.items.find(
        (item) => item.price && item.feature === props.productItem.product.features[0],
      );
      setUsedSeatsNumber(PricingContainer.state.usedSeats[currentSubscriptionItem.feature] || 0);
      if (item) {
        setExistingItem({
          seats: item.maxSeats,
          type: item.feature,
          price: item.price!,
          tier: getTier(item.price!.tiers, item.maxSeats)!,
        });
      }
    }
  }, []);

  const formatUpgradeData = (val: number) => {
    const tier = props.productItem.price.tiers.find((tierData) => tierData.up_to === val);
    return {
      seats: val,
      priceUuid: props.productItem.price.uuid,
      amount: BillingService.calculateTotalPrice(tier!, props.interval, props.coupon, props.productItem.product.externalId),
    };
  };
  const [upgradeData, setUpgradeData] = useState<UpgradeData>(formatUpgradeData(props.currentSeatsNumber));

  const setTier = (val: number) => {
    setUpgradeData(formatUpgradeData(val));
  };

  const getUpgradeDescription = () => {
    const price =
      (upgradeData?.amount || 0) +
      (existingItem ? BillingService.calculateTotalPrice(existingItem.tier!, props.interval, props.coupon, existingItem.price.product?.externalId) : 0);
    if (props.interval === Interval.Year) {
      return (
        <p className={styles.UpgradePopupTerms}>
          {t("upgrade_seats_description_annual {{price}} {{date}}", {
            price: price.toLocaleString("en-US", { style: "currency", currency: props.productItem.price.currency }),
            date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
          })}
        </p>
      );
    }
    return (
      <p className={styles.UpgradePopupTerms}>
        {t("upgrade_seats_description_monthly {{price}} {{date}}", {
          price: price.toLocaleString("en-US", { style: "currency", currency: props.productItem.price.currency }),
          date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
        })}
      </p>
    );
  };
  const submit = async () => {
    setError(null);
    setLoading(true);
    const payload = {
      items: [
        {
          seats: upgradeData.seats,
          priceUuid: upgradeData.priceUuid,
        },
      ],
    };
    if (existingItem) {
      payload.items.push({
        seats: existingItem.seats,
        priceUuid: existingItem.price.uuid,
      });
    }
    await SubscriptionApi.upgradePlan(props.companyUuid, props.subscription.uuid, payload)
      .then(() => {
        window.location.href = `/billing/payment-success/upgrade/${props.interval}`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };
  const availableTiers = props.productItem.tierSelector!.filter(
    (tiers) => tiers.value >= props.currentSeatsNumber && tiers.value >= usedSeatsNumber,
  );

  return (
    <div>
      <div className={styles.UpgradePopupTitle}>{t("Add seats")}</div>
      <div className={styles.UpgradeSeatsDescription}>
        <WarningIcon />
        <span>
          {t("You’re currently using {{currentSeatsNumber}} seats in your {{planSeatsNumber}} seat plan.", {
            currentSeatsNumber: usedSeatsNumber,
            planSeatsNumber: props.currentSeatsNumber,
          })}
        </span>
      </div>
      <div className={styles.MarginBottom24}>
        <FieldWrapper
          className={styles.WhiteSelect}
          width="100%"
          fieldName={t("Current plan")}
          fieldTitleMarginBottom={0}
          fieldTitleMarginTop={0}
        >
          <Select
            disabled
            value={props.currentSeatsNumber}
            options={[
              { label: `${props.currentSeatsNumber} ${t("seats (current plan)")}`, value: props.currentSeatsNumber },
            ]}
          />
        </FieldWrapper>
      </div>
      <div className={styles.MarginBottom24}>
        <FieldWrapper
          className={`${styles.WhiteSelect} ${styles.MarginBottom24}`}
          width="100%"
          fieldName={t("New plan")}
          fieldTitleMarginBottom={0}
          fieldTitleMarginTop={0}
        >
          <Select value={upgradeData.seats} onChange={(value) => setTier(value)} options={availableTiers!} />
        </FieldWrapper>
      </div>
      {getUpgradeDescription()}
      <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
        <Button
          disabled={loading}
          value={t("Cancel")}
          style={{ width: "48%" }}
          state={ButtonState.enableDay}
          onClick={props.close}
        />
        {upgradeData.seats === 250 ? (
          <Button
            disabled={loading}
            value={t("Contact sales")}
            style={{ width: "48%" }}
            state={ButtonState.primary}
            onClick={PricingContainer.bookCall}
          />
        ) : (
          <Button
            disabled={loading || !upgradeData.seats || upgradeData.seats === props.currentSeatsNumber}
            value={t("Confirm Upgrade")}
            style={{ width: "48%" }}
            state={ButtonState.primary}
            onClick={submit}
          />
        )}
      </div>
      {error ? <div className={styles.PaymentError}>{t(error)}</div> : null}
    </div>
  );
};

export default UpgradeSeatsModal;
