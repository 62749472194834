import { stylesheet } from "astroturf";
import cx from "classnames";

export const styles = stylesheet`
.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 11px;
  margin: 0; /* disable button+button style in header.css */
  font-size: var(--typography-font-size-default);
  font-weight: var(--typography-font-weight-bold);
  line-height: 18px;
  color: var(--colors-surface-0);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  border-radius: 33.3px;
  outline: none;

  &.regular {
    background-color: var(--colors-success-600-p);
  }
  &.warning {
    background-color: var(--colors-warning);
  }
  &.neutral {
    background-color: var(--colors-primary-500-p);
  }

  &:active {
    border: none;
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(80%);
  }

  &:hover:not(:disabled) {
    filter: saturate(110%);
  }
}
`;

export enum ButtonType {
  regular = "regular",
  neutral = "neutral",
  warning = "warning",
}

export type ButtonProps = React.ComponentProps<"button"> & {
  buttonType?: ButtonType;
};
export function Button(props: ButtonProps) {
  const { children, className, buttonType, ...restProps } = props;
  return (
    <button
      type="button"
      className={cx(
        styles.Button,
        {
          [styles.regular]: buttonType === ButtonType.regular,
          [styles.neutral]: buttonType === ButtonType.neutral,
          [styles.warning]: buttonType === ButtonType.warning,
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  );
}
