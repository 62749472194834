import { stylesheet } from "astroturf";
import cx from "classnames";
import NewCheckbox from "components/UI/NewCheckbox";
import Button from "components/UI/Button";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { forwardRef } from "react";
import { IconCloseDialog } from "../styled";

export const styles = stylesheet`
.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px 16px;
  cursor: default;
  background: var(--colors-surface-0);

  .IconClose {
    position: absolute;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    inset-inline-end: 12px;

    svg {
      fill: var(--colors-surface-500);
    }
  }

  .Header {
    font-size: 15px;
    font-weight: var(--typography-font-weight-medium);
    line-height: 100%;
    color: var(--colors-surface-900-p);
  }

  .Message {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-default);
    line-height: 130%;
    color: var(--colors-surface-700);
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .ButtonGetExtension {
      text-decoration: none;
    }
  }
}
`;

export interface ActivitiesListItemProps extends React.HTMLProps<HTMLDivElement> {
  onClose(): void;
  dontShowAgain: boolean;
  onDontShowAgainChange(dontShowAgain: boolean): void;
  extensionUrl: string;
}

export const GetExtensionPopupView = forwardRef<HTMLDivElement, ActivitiesListItemProps>(
  (props: ActivitiesListItemProps, ref) => {
    const { onClose, onDontShowAgainChange, dontShowAgain, className, extensionUrl, ...restProps } = props;
    const [t] = useTranslation(TranslationNamespaces.modalPunch);

    return (
      <div ref={ref} className={cx(styles.Wrapper, className)} {...restProps}>
        <div className={styles.IconClose} onClick={onClose}>
          <IconCloseDialog />
        </div>
        <div className={styles.Header}>{t("Supercharge your workflow")}</div>
        <div className={styles.Message}>
          {t("Now you can punch in and manage tasks using our browser extension from any website.")}
        </div>
        <div className={styles.Footer}>
          <NewCheckbox label={t("Don't show this again")} checked={dontShowAgain} onChange={onDontShowAgainChange} />
          <Button
            className={styles.ButtonGetExtension}
            label={t("Get extension")}
            type="link"
            href={extensionUrl}
            target="_blank"
          />
        </div>
      </div>
    );
  },
);
