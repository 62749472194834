import { Component, createRef, KeyboardEvent } from "react";
import ClickOutside from "react-click-outside";
import styled from "styled-components";
import { ClickOutsideType } from "types/common";
import BEM from "utils/BEM";
import Option from "./Option";
import "./Select.scss";

const b = BEM.b("ui-select-form");

const Wrapper = styled.div`
  .ui-select-form__input:after {
    inset-inline-end: 16px;
  }
  .locked {
    background-position-y: 50%;
    ${(p) => (p.theme.dir === "ltr" ? "background-position-x: calc(100% - 17px);" : "background-position-x: 17px;")}
  }
`;

export interface iOption<V, L> {
  label: L;
  value: V;
}

interface iSelectProps<V, L> {
  options: Array<iOption<V, L>>;
  value: V;
  onChange: (value: V) => void;
  placeholder?: string;
  disabled?: boolean;
  locked?: boolean;
  modifiers?: { [key: string]: boolean }; // BEM
  newStyle: boolean;
}

interface iSelectState<V, L> {
  isOpen: boolean;
  preselected: iOption<V, L> | null;
}

class Select<V = string | number, L = string | number> extends Component<iSelectProps<V, L>, iSelectState<V, L>> {
  inputRef = createRef<HTMLInputElement>();

  constructor(props: iSelectProps<V, L>) {
    super(props);

    this.state = {
      isOpen: false,
      preselected: null,
    };
  }

  toggleOpen = (open?: boolean): void => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;
    const preselected = !isOpen ? null : this.state.preselected;

    if (isOpen && this.inputRef.current) {
      this.inputRef.current.focus();
    }

    this.setState({ isOpen, preselected });
  };

  handleKeyPress = (ev: KeyboardEvent<HTMLInputElement>): boolean => {
    ev.stopPropagation();
    ev.preventDefault();

    const { options } = this.props;

    if (options && options.length) {
      if (ev.which === 27) {
        this.toggleOpen(false);
      } else if (ev.which === 38 || ev.which === 40) {
        this.preselectValue(ev.which === 38);
      } else if (ev.which === 13) {
        if (this.state.preselected) {
          this.selectValue(this.state.preselected.value);
        } else {
          this.toggleOpen(false);
        }
      }
    }

    return false;
  };

  preselectValue = (isUp: boolean): void => {
    const { options } = this.props;
    const { preselected } = this.state;

    if (preselected) {
      let nextIndex = options.map((e) => e.value).indexOf(preselected.value);
      if (options.length > 1) {
        nextIndex = isUp ? nextIndex - 1 : nextIndex + 1;
        if (nextIndex < 0) {
          nextIndex = options.length - 1;
        } else if (nextIndex > options.length - 1) {
          nextIndex = 0;
        }
      }
      this.setState({ preselected: options[nextIndex] });
    } else {
      this.setState({ preselected: options[0] });
    }
  };

  selectValue = (value: V): void => {
    const { onChange } = this.props;

    if (onChange && typeof onChange === "function") {
      onChange(value);
    }

    this.toggleOpen(false);
  };

  isActive = (optionValue: V): boolean => {
    const { value } = this.props;

    return optionValue === value;
  };

  renderSelectedLabel = (): string => {
    const { value, options, placeholder } = this.props;

    let selectedOption: iOption<V, L> | undefined;
    if (value || (typeof value === "number" && value === 0) || (typeof value === "string" && value === "")) {
      selectedOption = options.find((option) => option.value === value);
    }

    return selectedOption ? String(selectedOption.label) : placeholder || String(value || "");
  };

  render(): JSX.Element {
    const { options, disabled, locked, modifiers, newStyle } = this.props;
    const { preselected } = this.state;

    return (
      <Wrapper className={b("wrapper", modifiers)}>
        <ClickOutside<ClickOutsideType> onClickOutside={() => this.toggleOpen(false)}>
          <input
            type="text"
            onKeyDown={this.handleKeyPress}
            style={{ zIndex: 0, position: "absolute", border: "none", width: "100%", left: 0 }}
            ref={this.inputRef}
          />
          <div
            className={`${b("input", { open: this.state.isOpen, disabled, new: newStyle })}${locked ? " locked" : ""}`}
            onClick={() => !disabled && !locked && this.toggleOpen()}
          >
            {this.renderSelectedLabel()}
          </div>
          {this.state.isOpen && (
            <div className={b("option-list")}>
              {options.map((option) => (
                <Option
                  key={option.value as unknown as string}
                  preselected={!!preselected && option.value === preselected.value}
                  onSelect={() => this.selectValue(option.value)}
                  label={String(option.label)}
                  active={this.isActive(option.value)}
                />
              ))}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default Select;
