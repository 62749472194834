import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import { ErrorLabel } from "components/UI/TextLabels";
import { TableButton } from "components/styled/Page";
import AddRemoveLink from "components/controls/AddRemoveLink";
import Select from "components/UI/FormSelect";
import Multi from "components/UI/FormSelect/Multi";
import { getPhasesDays, minsToHrsMins } from "utils/common";
import { useMemo } from "react";
import { HoursDistributionRule, HoursDistributionType } from "types/models/businessRulesGroup";
import TimeControl from "components/controls/TimeControl";
import { convertTimeToMinutes } from "utils/scheduleHelpers";
import TextInputControl from "components/controls/TextInputControlNew";
import { v4 as uuidv4 } from "uuid";

// TODO: remove when src/components/UI/FormSelect/Multi.js removed
const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  .ui-select-form__input,
  .ui-select__input {
    display: block;
    line-height: 34px;
  }
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-600);
  padding-bottom: 17px;
  border-bottom: 1px solid var(--colors-surface-150, #dde5ee);
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  border-top: 1px solid var(--colors-surface-150);
  padding: 16px 0;
  position: relative;

  &:first-child {
    border-top: none;
  }

  .delete-button {
    display: none;
    position: absolute;
    inset-inline-end: 14px;
    top: 19px;
  }
  &:hover {
    background-color: var(--colors-surface-50);
    .delete-button {
      display: block;
    }
  }
`;

const TableRows = styled.div`
  display: flex;
  flex-direction: column;
`;

const PeriodCell = styled.div`
  width: 200px;
  flex: none;
`;

const ScheduleCell = styled.div`
  width: 160px;
  flex: none;
`;

const RangeCell = styled.div`
  width: 190px;
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  flex-wrap: nowrap;

  .time-control {
    max-width: 80px;
    margin-top: 0;
    flex-shrink: 0;
  }
`;

const NameCell = styled.div`
  width: 240px;
  flex: none;
`;

const IncludeOnCalculationsCell = styled.div`
  width: 200px;
  flex: none;
`;

enum IncludeOnCalculationsOptions {
  includeBreaks = "includeBreaks",
  includeOvertime = "includeOvertime",
  extendToFullRange = "extendToFullRange",
}

type ErrorType = Record<string, Record<string, string>> | null;

type HoursDistributionTableProps = {
  onRemoveRule: (uuid: string) => void;
  onAddNewRule: (rule: HoursDistributionRule) => void;
  onChange: (rule: HoursDistributionRule) => void;
  rules: HoursDistributionRule[];
  errors: ErrorType;
  isGroupDisabled: boolean;
  isGroupLocked: boolean;
};

const HoursDistributionTable = ({
  onRemoveRule,
  onAddNewRule,
  onChange,
  rules,
  errors,
  isGroupDisabled,
  isGroupLocked,
}: HoursDistributionTableProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  const periodOptions = useMemo(() => {
    const { daysMasksObj, order } = getPhasesDays({ t, isDayTypeBasedOnSchedule: false, withDsrDay: false });
    const days = order.map((d) => ({ value: d, label: daysMasksObj[d] }));

    return days;
  }, []);

  const scheduleOptions = useMemo(
    () =>
      Object.values(HoursDistributionType).map((type) => ({
        value: type,
        label: t(`hours-distribution-type-${type}`),
      })),
    [],
  );

  const includeOnCalculationsOptions = useMemo(
    () =>
      Object.values(IncludeOnCalculationsOptions).map((checkbox) => ({
        value: checkbox,
        label: t(`${checkbox}-option`),
      })),
    [],
  );

  return (
    <Wrapper>
      <HeaderRow>
        <PeriodCell>{t("Period")}</PeriodCell>
        <ScheduleCell>{t("Schedule")}</ScheduleCell>
        <RangeCell>{t("Range")}</RangeCell>
        <NameCell>{t("Name")}</NameCell>
        <IncludeOnCalculationsCell>{t("Include on calculations")}</IncludeOnCalculationsCell>
      </HeaderRow>
      <TableRows>
        {rules.map((rule) => (
          <TableRow key={rule.uuid}>
            <PeriodCell>
              <Select
                disabled={isGroupDisabled}
                locked={!isGroupDisabled && isGroupLocked}
                value={rule.daysMask}
                newStyle
                onChange={(value) => {
                  onChange({ ...rule, daysMask: value });
                }}
                options={periodOptions}
              />
            </PeriodCell>
            <ScheduleCell>
              <Select
                disabled={isGroupDisabled}
                locked={!isGroupDisabled && isGroupLocked}
                value={rule.type}
                newStyle
                onChange={(value) => {
                  onChange({ ...rule, type: value });
                }}
                options={scheduleOptions}
              />
            </ScheduleCell>
            <RangeCell>
              <TimeControl
                error={!!errors?.[rule.uuid]?.startTime}
                placeholder="00:00"
                disabled={isGroupDisabled}
                locked={!isGroupDisabled && isGroupLocked}
                onTimeChange={(timeVal) =>
                  onChange({ ...rule, startTime: timeVal.value ? convertTimeToMinutes(timeVal.value) : 0 })
                }
                modifiers={{ new: true }}
                value={minsToHrsMins(rule.startTime)}
              />
              <TimeControl
                error={!!errors?.[rule.uuid]?.endTime}
                placeholder="00:00"
                disabled={isGroupLocked || isGroupDisabled}
                locked={isGroupLocked}
                onTimeChange={(timeVal) => {
                  const val = timeVal.value ? convertTimeToMinutes(timeVal.value) : 0;
                  const endTime = val < rule.startTime ? val + 60 * 24 : val;
                  onChange({ ...rule, endTime });
                }}
                modifiers={{ new: true }}
                value={rule.endTime > 60 * 24 ? minsToHrsMins(rule.endTime - 60 * 24) : minsToHrsMins(rule.endTime)}
              />
            </RangeCell>
            <NameCell>
              <TextInputControl
                disabled={isGroupLocked || isGroupDisabled}
                locked={isGroupLocked}
                placeholder={t("Name")}
                error={!!errors?.[rule.uuid]?.name}
                value={rule.name}
                onChange={(evt) => {
                  onChange({ ...rule, name: evt.target.value || "" });
                }}
              />
              {!!errors?.[rule.uuid]?.name && <ErrorLabel>{errors?.[rule.uuid]?.name}</ErrorLabel>}
            </NameCell>
            <IncludeOnCalculationsCell>
              <Multi
                disabled={isGroupLocked || isGroupDisabled}
                locked={isGroupLocked}
                value={[
                  rule.includeBreaks && IncludeOnCalculationsOptions.includeBreaks,
                  rule.includeOvertime && IncludeOnCalculationsOptions.includeOvertime,
                  rule.extendToFullRange && IncludeOnCalculationsOptions.extendToFullRange,
                ].filter((item) => item)}
                newStyle
                onChange={(value: IncludeOnCalculationsOptions[]) => {
                  onChange({
                    ...rule,
                    includeBreaks: value.includes(IncludeOnCalculationsOptions.includeBreaks),
                    includeOvertime: value.includes(IncludeOnCalculationsOptions.includeOvertime),
                    extendToFullRange: value.includes(IncludeOnCalculationsOptions.extendToFullRange),
                  });
                }}
                options={includeOnCalculationsOptions}
              />
            </IncludeOnCalculationsCell>

            {!isGroupDisabled && !isGroupLocked && (
              <TableButton
                onClick={() => {
                  onRemoveRule(rule.uuid);
                }}
                className="delete-button"
              >
                {t(`${TranslationNamespaces.common}|Delete`)}
              </TableButton>
            )}
          </TableRow>
        ))}
      </TableRows>
      <br />
      {!isGroupLocked && !isGroupDisabled && (
        <AddRemoveLink
          label={t("add-hours-distribution-rule")}
          onClick={() =>
            onAddNewRule({
              uuid: uuidv4(),
              name: "",
              type: HoursDistributionType.everyHour,
              daysMask: periodOptions[0].value,
              startTime: 22 * 60,
              endTime: 24 * 60 + 20 * 60,
              includeBreaks: true,
              includeOvertime: false,
              extendToFullRange: true,
            })
          }
        />
      )}
    </Wrapper>
  );
};

export default HoursDistributionTable;
