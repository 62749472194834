import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NothingFound } from "components/Activities/TimeTracker/components/NothingFound";
import { SubOptionRow } from "./SubOptionRow";
import { OptionRow } from "./OptionRow";
import { GroupedOption, GroupedSubOption } from ".";

export const OptionsList = ({
  searchValue,
  options,
  selectedOption,
  selectedSubOption,
  onChange,
}: {
  searchValue: string;
  options: GroupedOption[];
  selectedOption: GroupedOption | null;
  selectedSubOption: GroupedSubOption | null;
  onChange: (value: string) => void;
}) => {
  const { t } = useTranslation([
    TranslationNamespaces.payroll,
    TranslationNamespaces.phases,
    TranslationNamespaces.requestsPageTmp,
  ]);

  if (searchValue === "") {
    // !searchValue && !options
    if (options.length === 0)
      return (
        <NothingFound
          large
          title={t(`${TranslationNamespaces.common}|No options`)}
          description={t(`${TranslationNamespaces.common}|no-options-description`)}
        />
      );
    // !searchValue && options
    return options.map((option) => (
      <>
        <OptionRow
          key={option.id}
          selectedOptionId={selectedOption?.id}
          option={option}
          withSubOptionSelection={!!option.subOptions}
          onChange={onChange}
        >
          {option?.subOptions?.map((subOption) => (
            <SubOptionRow
              subOption={subOption}
              key={subOption.id}
              selected={subOption.id === selectedSubOption?.id}
              onSelect={() => option.id && onChange(subOption.id)}
              nested
            />
          ))}
        </OptionRow>
      </>
    ));
  }
  // searchValue && !options
  if (options.length === 0)
    return (
      <NothingFound
        large
        title={t(`${TranslationNamespaces.common}|No options`)}
        description={t(`${TranslationNamespaces.common}|no-options-description`)}
      />
    );

  // searchValue && options
  return options.map((option) => (
    <OptionRow
      key={option.id}
      option={option}
      simple
      onChange={onChange}
      withSubOptionSelection={!!option.subOptions}
      selectedOptionId={selectedOption?.id}
    >
      <div className="SearchResults">
        {option.subOptions?.map((subOption) => (
          <SubOptionRow
            key={subOption.id}
            subOption={subOption}
            selected={subOption.id === selectedSubOption?.id}
            searchValue={searchValue}
            nested
            onSelect={() => option.id && onChange(subOption.id)}
          />
        ))}
      </div>
    </OptionRow>
  ));
};
