/* eslint no-unused-vars: 0 */
import { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { RemoveExceptionEventData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import Tooltip from "components/UI/Tooltip";
import * as images from "components/svg-images";
import { fireEvent } from "utils/common";
import { DailySummarySchedule, DailySummaryScheduleException } from "types/models/schedule";

const CellWrapper = styled.div`
  width: 100%;
  height: 50px;
  border: none;
  position: relative;
  padding: 5px;
`;

const Value = styled.div<{ inactive: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--typography-font-size-default);
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.inactive ? props.theme.colors.secondary3 : props.theme.colors.text1)};
  background: none;
`;

const ExceptionButton = styled.button`
  background: #f1f4f8;
  outline: none;
  border: none;
  border-radius: 100px;
  font-size: var(--typography-font-size-default);
  color: #525f7f;
  display: flex;
  justify-content: space-between;
  padding: 8px 15px 8px 17px;

  .hint {
    display: none;
    background: #525f7f;
    border-radius: 3px;
    font-weight: var(--typography-font-weight-medium);
    line-height: 25px;
    font-size: 12px;
    color: var(--colors-default);
    padding: 0 20px;
    position: absolute;
    top: 17px;
    z-index: 1;
  }
  &:hover {
    .hint {
      display: block;
    }
  }
`;

const ExceptionRemove = styled.div`
  margin-inline-start: 10px;
  cursor: pointer;
`;

interface CellScheduleProps {
  t: WithTranslation["t"];
  inactive: boolean;
  employeeUuid: string;
  lockedDay: boolean;
  date: moment.Moment;
  value: DailySummarySchedule;
  scheduleException: DailySummaryScheduleException | null;
}

interface CellScheduleState {
  schedule: DailySummarySchedule | null;
  scheduleException: DailySummaryScheduleException | null;
}

class CellSchedule extends Component<CellScheduleProps, CellScheduleState> {
  constructor(props: CellScheduleProps) {
    super(props);

    this.state = {
      schedule: props.value,
      scheduleException: props.scheduleException,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }

  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }

  getCellKey = () => {
    const { date } = this.props;
    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}_schedule`;
  };

  handleDataLoad = (
    ev: CustomEvent<{ schedule?: DailySummarySchedule; scheduleException?: DailySummaryScheduleException }>,
  ) => {
    this.setState({
      schedule: ev.detail.schedule || null,
      scheduleException: ev.detail.scheduleException || null,
    });
  };

  render() {
    const { t, inactive, employeeUuid, date, lockedDay } = this.props;
    const { schedule, scheduleException } = this.state;

    if (scheduleException?.uuid) {
      return (
        <ExceptionButton>
          {t("Exception")}
          {!lockedDay && (
            <ExceptionRemove
              onClick={() => {
                fireEvent(SuperpunchCustomEvents.removeException, {
                  userProfileUuid: employeeUuid,
                  companyUuid: window.global_store.company.uuid,
                  requestedByUuid: window.global_store.profile.uuid,
                  date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
                  scheduleExceptionUuid: scheduleException.uuid,
                  body: {
                    content: {
                      date: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
                    },
                  },
                } as RemoveExceptionEventData);
              }}
            >
              {images.removeExceptionIcon}
            </ExceptionRemove>
          )}
        </ExceptionButton>
      );
    }

    if (schedule) {
      const tipId = `cell-schedule-${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}`;

      return (
        <CellWrapper>
          <Value inactive={inactive}>
            <p data-tip data-for={tipId}>
              {schedule.name}
            </p>
          </Value>
          <Tooltip id={tipId}>
            <span>
              {t("Schedule")} {schedule.name}
            </span>
          </Tooltip>
        </CellWrapper>
      );
    }

    return "";
  }
}

export default CellSchedule;
