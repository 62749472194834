/**
 * Return URL to map image produced by Google Maps Static API.
 * Add a marker at lat, lng.
 * Optionally add another marker at markerLat, markerLng and a circle around it.
 */
export function GMapCircle(
  lat: number,
  lng: number,
  radiusMeters: number,
  markerLat: number,
  markerLng: number,
  [h, w] = [301, 117],
) {
  const uri = "https://maps.googleapis.com/maps/api/staticmap?";
  let staticMapSrc = "";
  staticMapSrc += `&size=${h}x${w}`;
  staticMapSrc += `&markers=color:blue|size:tiny|${lat},${lng}`;
  if (markerLat && markerLng) {
    staticMapSrc += `&markers=color:red|size:tiny|${markerLat},${markerLng}`;
  }
  staticMapSrc += "&path=fillcolor:0xEC546733|weight:0|color:none";

  if (markerLat && markerLng) {
    const r = 6371;
    const pi = Math.PI;
    const latt = (markerLat * pi) / 180;
    const long = (markerLng * pi) / 180;
    const d = radiusMeters / 1000 / r;
    let i = 0;

    for (i = 0; i <= 360; i += 8) {
      const brng = (i * pi) / 180;

      let pLat = Math.asin(Math.sin(latt) * Math.cos(d) + Math.cos(latt) * Math.sin(d) * Math.cos(brng));
      const pLng =
        ((long +
          Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(latt), Math.cos(d) - Math.sin(latt) * Math.sin(pLat))) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;

      staticMapSrc += `|${pLat},${pLng}`;
    }
  }

  return uri + encodeURI(staticMapSrc);
}

export function latLngToGPS({ lat, lng }: Record<string, number>) {
  function convert(deg: number, isLat: boolean) {
    let direction;
    const absolute = Math.abs(deg);

    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);

    if (isLat) {
      direction = deg >= 0 ? "N" : "S";
    } else {
      direction = deg >= 0 ? "E" : "W";
    }

    return `${degrees}°${minutes}'${seconds}"${direction}`;
  }

  return [convert(lat, true), convert(lng, false)].join(", ");
}
