import styled from "styled-components";
import InputRange, { InputRangeProps } from "react-input-range";
import "react-input-range/lib/css/index.css";

const RangeWrapper = styled.div`
  .input-range__track--background {
    height: 8px;
    background-color: var(--colors-unknown35);
  }

  .input-range__track--active {
    height: 8px;
    background-color: var(--colors-unknown36);
  }

  .input-range__slider {
    top: -2px;
    margin-inline-start: -10px;
    width: 16px;
    height: 16px;
    background-color: var(--colors-unknown37);
    border: 0;

    &:active {
      transform: rotate(45deg) scale(1.3);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      transform: rotate(-45deg);
      width: 100%;
      height: 100%;
      background-size: 11px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .input-range__label {
    display: none;
  }
`;

type RangeProps = InputRangeProps;

const Range = (props: RangeProps) => (
  <RangeWrapper className="ui-range-wrapper">
    <InputRange {...props} />
  </RangeWrapper>
);

export default Range;
