import styled from "styled-components";
import { OverflowList } from "../../OverflowList";

const Tag = styled.div`
  line-height: 28px;
  height: 28px;
  padding: 0 8px;
  color: var(--colors-surface-900-p);
  background: var(--colors-surface-50);
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-150);
  margin-inline-end: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 34px;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  height: 47px;
`;

export const TagRenderer = ({ value }: { value: null | string | string[] }) => {
  if (!value || !value?.length) return null;
  if (typeof value === "string") {
    return (
      <Wrapper>
        <Tag>{value}</Tag>
      </Wrapper>
    );
  }

  const ItemRenderer = (item: string, index: number) => <Tag key={index}>{item}</Tag>;

  const OverflowRenderer = (items: string[]) => <Tag>+{items.length}</Tag>;

  return (
    <OverflowList
      collapseFrom="end"
      minVisibleItems={1}
      items={value}
      itemRenderer={ItemRenderer}
      overflowRenderer={OverflowRenderer}
    />
  );
};
