import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin: 0 2px;
    height: 8px;
    width: 4px;
    background-color: var(--colors-warning-600);
    border-radius: 1px;
    animation: three-block-loader-loading 750ms infinite;
    opacity: 0.25;

    &:nth-child(1) {
      animation-delay: 250ms;
    }
    &:nth-child(2) {
      animation-delay: 500ms;
    }
  }

  @keyframes three-block-loader-loading {
    0% {
      opacity: 1;
      transform: scale(1.2);
    }
    80% {
      opacity: 0.25;
      transform: scale(1);
    }
  }
`;

const ThreeBlockLoader = () => (
  <LoaderWrapper>
    <div />
    <div />
    <div />
  </LoaderWrapper>
);

export default ThreeBlockLoader;
