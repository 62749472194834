import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";
import * as Images from "./menu-svg-icons";

export enum ColorTheme {
  light = "dayio-web-light-theme",
  dark = "dayio-web-dark-theme",
  darkLight = "dayio-web-dark-light-theme",
}

export const DEFAULT_COLOR_THEME = ColorTheme.dark;
export const COLOR_THEME_LS_KEY = "oi_color_theme";

const Wrapper = styled.div``;

const Dropdown = styled.div`
  padding: 8px 4px;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-surface-0);
  box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
`;

const DropdownOption = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 6px;
  line-height: 32px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);
  white-space: nowrap;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

interface ThemeSwitcherProps {
  isOpen: boolean;
  onClose: () => void;
}

const ThemeSwitcher = ({ isOpen, onClose }: ThemeSwitcherProps) => {
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const [currentTheme, setCurrentTheme] = useState(DEFAULT_COLOR_THEME);

  useEffect(() => {
    setCurrentTheme((localStorage.getItem(COLOR_THEME_LS_KEY) as ColorTheme) || DEFAULT_COLOR_THEME);
  }, []);

  const onClick = (theme: ColorTheme) => {
    localStorage.setItem(COLOR_THEME_LS_KEY, theme);

    document.body.classList.remove(ColorTheme.light, ColorTheme.dark, ColorTheme.darkLight);
    document.body.classList.add(theme);
    setCurrentTheme(theme);
  };

  return (
    <Wrapper className="theme-switch">
      {isOpen && (
        <ClickOutside<ClickOutsideType> onClickOutside={onClose}>
          <Dropdown>
            {Object.values(ColorTheme).map((value) => (
              <DropdownOption onClick={() => onClick(value as ColorTheme)}>
                {t(value)}
                <IconWrapper>{currentTheme === value && <Images.CheckMark />}</IconWrapper>
              </DropdownOption>
            ))}
          </Dropdown>
        </ClickOutside>
      )}
    </Wrapper>
  );
};

export default ThemeSwitcher;
