import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Groups } from "types/models/dashboard";
import { Subsidiary } from "types/models/subsidiary";
import { Department } from "types/models/department";
import { Position } from "types/models/position";
import MultiSelectWithSearch from "components/UI/Select/MultiWithSearch";
import * as images from "components/svg-images";
import { Team } from "types/models/team";
import { ControlWrapper, ControlSelectedValue } from "../styled";

const ALL_GROUPS_VALUE = "__all__";

interface EntitySelectorProps {
  /** entity uuids, empty means "all" */
  value: string[];
  groupBy: Groups;
  onSelect: (values: string[]) => void;
  groups: {
    subsidiaries: Subsidiary[];
    departments: Department[];
    positions: Position[];
    teams: Team[];
  };
}

const GroupSelector = ({ onSelect, groupBy, value, groups }: EntitySelectorProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const allEntitiesLabel = {
    [Groups.company]: t("All Employees"),
    [Groups.subsidiaries]: t("All Subsidiaries"),
    [Groups.departments]: t("All Departments"),
    [Groups.postions]: t("All Postions"),
    [Groups.teams]: t("All Teams"),
  }[groupBy];

  const options = [{ value: ALL_GROUPS_VALUE, label: allEntitiesLabel }];
  switch (groupBy) {
    case Groups.subsidiaries:
      options.push(...groups.subsidiaries.map((s) => ({ value: s.uuid, label: s.name })));
      break;
    case Groups.departments:
      options.push(...groups.departments.map((d) => ({ value: d.uuid, label: d.name })));
      break;
    case Groups.postions:
      options.push(...groups.positions.map((p) => ({ value: p.uuid, label: p.title })));
      break;
    case Groups.teams:
      options.push(...groups.teams.map((t) => ({ value: t.uuid, label: t.name })));
      break;
    default:
      break;
  }

  const onChange = (nextValue: string[]) => {
    const prevValue = value;

    // empty means "all"
    if (!prevValue.length) {
      onSelect(nextValue.filter((v) => v !== ALL_GROUPS_VALUE));
    } else if (nextValue.includes(ALL_GROUPS_VALUE)) {
      onSelect([]);
    } else {
      onSelect(nextValue);
    }
  };

  return (
    <ControlWrapper>
      <MultiSelectWithSearch
        onChange={onChange}
        options={options}
        value={!value.length ? [ALL_GROUPS_VALUE] : value}
        customLabelElement={
          <ControlSelectedValue>
            {t("Show")} <span>{!value.length ? allEntitiesLabel : value.length}</span>{" "}
            {images.smallArrowDown({ width: "7px", height: "4px" })}
          </ControlSelectedValue>
        }
      />
    </ControlWrapper>
  );
};

export default GroupSelector;
