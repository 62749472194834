import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { stylesheet } from "astroturf";
import { Feature, FeatureLabel, ProductFeature } from "types/models/subscription";
import GlobalContext from "context/global-context";
import { SubscriptionApi } from "utils/api/subscription";
import { addEmployee } from "utils/api/employee";
import { updateServiceProviderSettings } from "components/Projects/projectsApiUtils";
import ga from "utils/ga";
import * as images from "components/svg-images";
import { useHubspot } from "hooks/useHubspot";
import MainPage from "./MainPage";

import { ReactComponent as TAIcon } from "./img/TA.svg";
import { ReactComponent as ProjectIcon } from "./img/Projects.svg";
import { ErrorLabel } from "./styled";

const styles = stylesheet`
  .Wrapper {
    margin-bottom: 64px;
  }
  .ProductList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
  .ProductWrapper {
    padding: 26px;
    border: 2px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);
    position: relative;
    cursor: pointer;
    &.ProductChecked {
      border-color: var(--colors-primary-500-p);
      .Check {
        background: var(--colors-primary-500-p);
        border-color: var(--colors-primary-500-p);
        svg {
          display: block;
        }
      }
    }
    &:hover {
      background-color: var(--colors-surface-50);
    }

    .Check {
      position: absolute;
      top: 16px;
      inset-inline-start: 16px;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--colors-surface-400);
      svg {
        display: none;
        width: 10px;
        path {
          fill: white;
        }
      }
    }
  }
  .BGIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ProductName {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
    color: var(--colors-surface-900-p);
    margin-bottom: 4px;
  }
  .ProductDescription {
    font-size: 12px;
    color: var(--colors-surface-600);
    text-align: center;
  }
  .Recommendation {
    white-space: nowrap;
    position: absolute;
    color: var(--colors-success-700);
    font-size: 13px;
    padding: 9px 12px;
    background: var(--colors-success-50);
    border-radius: 6px;
    border: 1px solid rgba(#00A15C, .4);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SelectTrialPlan = () => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const history = useHistory();
  useHubspot();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlans, setSelectedPlans] = useState<ProductFeature[]>([]);
  const [recommendedProducts, setRecommendedProducts] = useState<ProductFeature[]>([]);
  const [error, setError] = useState<string>();
  const products: ProductFeature[] = [Feature.TA, Feature.Project];
  const context = useContext(GlobalContext);

  const isSelected = (product: ProductFeature) => selectedPlans.includes(product);

  useEffect(() => {
    const prods = history.location.search.replace("?plan=", "").split("&") as ProductFeature[];
    if (prods.length > 0) {
      setRecommendedProducts(prods);
      setSelectedPlans(prods);
    }
  }, []);

  const onNextClick = async () => {
    setLoading(true);
    const company = await context.getCompany();
    const payload = {
      ta: isSelected(Feature.TA) ? 10 : null,
      projects: isSelected(Feature.Project) ? 5 : null,
    };

    await SubscriptionApi.setTrialSubscription(company.uuid, payload).catch((e) => {
      setError(e.message);
      setLoading(false);
    });
    // update associated_products on user profile
    await addEmployee({
      id: window.global_store.profile.id,
      uuid: window.global_store.profile.uuid,
      associateProducts: Object.keys(payload).filter((k) => !!payload[k]),
    });
    // enable global settings for projects if was selected
    if (isSelected(Feature.Project)) {
      await updateServiceProviderSettings({
        companyUuid: company.uuid,
        userProfileUuid: window.global_store.profile.uuid,
        requestedBy: window.global_store.profile.uuid,
        body: {
          content: {
            enabled: true,
            serviceUuids: [],
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });
    }

    ga.trackSelectProduct(isSelected(Feature.TA), isSelected(Feature.Project));

    // update profile data for GA getAmpliObject method
    if (!window.global_store.profile.associate_products.length) {
      if (isSelected(Feature.TA)) window.global_store.profile.associate_products.push(Feature.TA);
      if (isSelected(Feature.Project)) window.global_store.profile.associate_products.push(Feature.Project);
    }

    if (payload.projects) history.push("/integration");
    else window.location.href = "/";

    setLoading(false);
  };

  const toggleProduct = (product: ProductFeature) =>
    isSelected(product)
      ? setSelectedPlans(selectedPlans.filter((p) => p !== product))
      : setSelectedPlans([...selectedPlans, product]);

  const getProductIcon = (product: ProductFeature) => {
    if (product === Feature.TA) {
      return <TAIcon />;
    }
    if (product === Feature.Project) {
      return <ProjectIcon />;
    }
    return null;
  };

  return (
    <MainPage
      topRowText=""
      topRowButtonText={t("LOGIN")}
      topRowButtonClick={() => history.push("/login")}
      buttonText={t("Continue")}
      pageTitle={t("What will you be using?")}
      pageSubTitle={t("You choice here won’t limit you to change it later.")}
      onSubmit={onNextClick}
      loading={loading}
      disabled={selectedPlans.length === 0}
      formParams={{
        style: { paddingTop: "40px", display: "flex", flexDirection: "column", alignItems: "center" },
        id: "hs_create-company",
        noValidate: true,
        acceptCharset: "UTF-8",
      }}
    >
      <div className={styles.Wrapper}>
        <div className={styles.ProductList}>
          {products.map((product) => (
            <div
              className={`${styles.ProductWrapper} ${isSelected(product) ? styles.ProductChecked : ""}`}
              key={product}
              onClick={() => toggleProduct(product)}
            >
              {recommendedProducts.length === 1 && recommendedProducts.includes(product) ? (
                <div className={styles.Recommendation}>{t("select_product_recommendation")}</div>
              ) : null}
              <div className={styles.Check}>{images.check}</div>
              <div className={styles.BGIcon}>{getProductIcon(product)}</div>
              <div className={styles.ProductName}>{t(FeatureLabel[product])}</div>
              <div className={styles.ProductDescription}>{t(`select_product_description_${product}`)}</div>
            </div>
          ))}
        </div>
        {error && <ErrorLabel css={{ marginTop: "8px" }}>{error}</ErrorLabel>}
      </div>
    </MainPage>
  );
};

export default SelectTrialPlan;
