export enum HolidayGroupStatus {
  active = "active",
  deactivated = "deactivated",
}

type HolidayGroup = {
  uuid: string;
  name: string;
  isDefault: boolean;
  status: HolidayGroupStatus;
};

export type { HolidayGroup };
