import { Component, ReactNode } from "react";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Sentry from "utils/sentryUtils";
import FullPage from "components/Layout/FullPage";
import Tabs from "components/UI/Tabs";
import { getEmployee } from "utils/apiHelpers";
import NotificationRow from "components/NotificationRow";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import ModalDialog from "components/UI/ModalDialog";
import { BillingService } from "components/Billing/BillingService";
import { NotificationType } from "types/common";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { UserProfile } from "types/models/userProfile";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Feature } from "types/models/subscription";
import { ReactComponent as DotsIcon } from "components/Activities/icons/dots.svg";
import { translateEmployeeTerm } from "utils/translationHelpers";
import EmployeeDetailsNew from "./EmployeeDetailsNew";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeDevices from "./EmployeeDevices";
import EmployeeRequests from "./EmployeeRequests";
import EmployeePunches from "./EmployeePunches";
import EmployeePunchesSettings from "./EmployeePunchesSettings";
import EmployeeTeam from "./EmployeeTeam";
import DeactivateEmployee from "./DeactivateEmployee";
import EmployeePageHeaderNew from "./EmployeePageHeaderNew";
import "components/UI/Page/Page.scss";
import "styles/employee-page.scss";
import { hasPermisionAccess } from "utils/common";

const p = BEM.b("page");

const Wrapper = styled.div`
  width: 100%;

  .page__content {
    padding: 0 24px;
  }
`;

const TabsWrapper = styled.div`
  ul {
    padding: 0 24px 1px;
    border-bottom: 1px solid var(--colors-surface-150);

    & > div {
      gap: 16px;

      li {
        padding: 0;
      }
    }
  }
`;

const CTA = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-200);
  background: var(--colors-surface-0);

  svg {
    path {
      fill: var(--colors-surface-600);
    }
  }
`;

enum EmployeePageTabs {
  details = "details",
  deviceManagement = "device-management",
  requests = "requests",
  team = "team",
  punches = "punches",
  punchSettings = "punch-settings",
}

interface EmployeePageProps extends WithTranslation, RouteComponentProps<{ employeeId: string }> {}

interface EmployeePageState {
  activeTab: EmployeePageTabs;
  notificationType: NotificationType | null;
  loaded: boolean;
  employee: UserProfile | null;
  employeeId: number;
  notification: string;
  showDeactivateEmployeePopup: boolean;
}

class EmployeePage extends Component<EmployeePageProps, EmployeePageState> {
  constructor(props: EmployeePageProps) {
    super(props);

    if (!this.props.match?.params?.employeeId) {
      this.props.history.push("/");
    }

    const employeeId = Number.parseInt(this.props.match.params.employeeId, 10);

    this.state = {
      activeTab: EmployeePageTabs.details,
      notificationType: null,
      loaded: false,
      employee: null,
      employeeId,
      notification: "",
      showDeactivateEmployeePopup: false,
    };
  }

  componentDidMount(): void {
    void this.getState();
  }

  getState = async (): Promise<void> => {
    if (this.state.employeeId) {
      try {
        const resp = await getEmployee({
          id: this.state.employeeId,
          newHierarchyPermissions: true,
        });
        const employee = resp?.user_profile || null;

        this.setState({
          employee,
          loaded: true,
        });
      } catch (e) {
        Sentry.sendError(e);

        this.setState({ loaded: true, employee: null });
      }
    }
  };

  onTabClick = (tab: EmployeePageTabs): void => {
    if (tab !== this.state.activeTab) {
      this.setState({ activeTab: tab });
    }
  };

  getTabContent = (employee: UserProfile, activeTab: EmployeePageTabs): ReactNode => {
    let tabContent = null;

    switch (activeTab) {
      case EmployeePageTabs.details:
        tabContent = window.global_store.beta ? (
          <EmployeeDetails
            onSave={async (notification: string): Promise<void> => {
              const resp = await getEmployee({
                id: this.state.employeeId,
                newHierarchyPermissions: true,
              });

              this.setState({
                notification,
                employee: resp.user_profile || null,
                loaded: true,
              });
            }}
            employee={employee}
          />
        ) : (
          <EmployeeDetailsNew
            onSave={async (notification: string): Promise<void> => {
              const resp = await getEmployee({
                id: this.state.employeeId,
                newHierarchyPermissions: true,
              });

              this.setState({
                notification,
                notificationType: NotificationType.success,
                employee: resp.user_profile || null,
                loaded: true,
              });
            }}
            onError={(notification: string): void => {
              this.setState({
                notification,
                notificationType: NotificationType.error,
              });
            }}
            employee={employee}
          />
        );
        break;
      case EmployeePageTabs.deviceManagement:
        tabContent = <EmployeeDevices employee={employee} />; // todo
        break;
      case EmployeePageTabs.requests:
        tabContent = <EmployeeRequests employeeUuid={employee.uuid} />;
        break;
      case EmployeePageTabs.team:
        tabContent = (
          <EmployeeTeam
            key={employee.id}
            employeeId={employee.id}
            employeeUuid={employee.uuid}
            employeeTeamId={employee.teams[0].id}
            employeeTeamUuid={employee.teams[0].uuid}
          />
        );
        break;
      case EmployeePageTabs.punches:
        tabContent = <EmployeePunches employeeId={employee.id} />;
        break;
      case EmployeePageTabs.punchSettings:
        tabContent = <EmployeePunchesSettings employeeId={employee.id} />;
        break;
      default:
        tabContent = null;
    }

    return tabContent;
  };

  render(): JSX.Element {
    const { activeTab, employee, loaded, notification, notificationType, showDeactivateEmployeePopup } = this.state;
    const { t, history } = this.props;

    if (loaded && !employee) {
      return (
        <FullPage
          className="employee-details-page"
          backButtonOnclick={() => history.push("/employees")}
          backButtonTitle={translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-employees",
            `${TranslationNamespaces.sidebar}|People`,
          )}
        >
          <div>{t(`${TranslationNamespaces.common}|not-found`)}</div>
        </FullPage>
      );
    }

    const isAdmin = window.global_store.profile?.permission_roles?.some(
      (r) =>
        r.name === PermissionRoleName.owner || r.name === PermissionRoleName.admin || r.name === PermissionRoleName.HR,
    );

    const tabs = [
      {
        name: EmployeePageTabs.details,
        label: t("Details"),
      },
      {
        name: EmployeePageTabs.deviceManagement,
        label: t("Device Management"),
      },
      {
        name: EmployeePageTabs.punches,
        label: t("Punch History"),
        hide: !BillingService.checkFeatureAccess(Feature.TA),
      },
      {
        name: EmployeePageTabs.requests,
        label: t("Requests"),
        hide: !BillingService.checkFeatureAccess(Feature.TA),
      },
    ];

    if (employee?.role === "supervisor") {
      tabs.push({
        name: EmployeePageTabs.team,
        label: t("Team"),
      });
    }

    if (isAdmin) {
      tabs.push({
        name: EmployeePageTabs.punchSettings,
        label: t("Punch Settings"),
        hide: !BillingService.checkFeatureAccess(Feature.TA),
      });
    }

    return (
      <FullPage
        className="employee-details-page"
        backButtonOnclick={() => history.push("/employees")}
        backButtonTitle={translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employees",
          `${TranslationNamespaces.sidebar}|People`,
        )}
      >
        <Wrapper className={p()}>
          {loaded && employee ? (
            <div>
              <EmployeePageHeaderNew employee={employee} />

              <TabsWrapper>
                <Tabs
                  tabs={tabs}
                  value={activeTab}
                  onChange={(tab: EmployeePageTabs): void => this.setState({ activeTab: tab })}
                  cta={
                    hasPermisionAccess(PermissionSectionName.deactivateEmployees) ? (
                      <SimpleMenu
                        useClick
                        options={[
                          {
                            value: "deactivateEmployee",
                            label: translateEmployeeTerm(
                              t,
                              TranslationNamespaces.employeesPage,
                              "custom-deactivate-employee",
                              "Deactivate employee",
                            ),
                          },
                        ]}
                        onChange={(val: string): void => {
                          if (val === "deactivateEmployee") {
                            this.setState({ showDeactivateEmployeePopup: true });
                          }
                        }}
                      >
                        <CTA>
                          <DotsIcon />
                        </CTA>
                      </SimpleMenu>
                    ) : null
                  }
                />
              </TabsWrapper>

              {notification && (
                <>
                  <br />
                  <NotificationRow type={notificationType} message={t(notification)} />
                </>
              )}

              <div className={p("content")} style={{ marginTop: 20 }}>
                {this.getTabContent(employee, activeTab)}
              </div>
            </div>
          ) : (
            <div>{t(`${TranslationNamespaces.common}|Loading...`)}</div>
          )}

          <ModalDialog
            isOpen={showDeactivateEmployeePopup}
            onClose={(): void => this.setState({ showDeactivateEmployeePopup: false })}
          >
            <DeactivateEmployee
              employee={employee}
              setNotification={(n: string, nt: NotificationType): void => {
                this.setState({ notification: n, notificationType: nt });

                if (nt === NotificationType.success) {
                  void this.getState();
                }
              }}
              closePopup={(): void => this.setState({ showDeactivateEmployeePopup: false })}
            />
          </ModalDialog>
        </Wrapper>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.sidebar])(EmployeePage);
