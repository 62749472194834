import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import * as images from "components/svg-images";
import { Frequency } from "types/models/dashboard";
import { ControlWrapper, ControlSelectedValue } from "../styled";

interface FrequencySelectorProps {
  value: Frequency;
  onSelect: (value: Frequency) => void;
}

const FrequencySelector = ({ onSelect, value }: FrequencySelectorProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const options = [
    { value: Frequency.day, label: t("Day") },
    { value: Frequency.week, label: t("Week") },
    { value: Frequency.month, label: t("Month") },
    // { value: Frequency.payrollPeriods, label: t("Payroll Periods") },
  ];
  const label = options.find((o) => o.value === value)?.label || "";

  return (
    <ControlWrapper>
      <SimpleMenu onChange={onSelect} options={options} useClick>
        <ControlSelectedValue>
          {t("Frequency")} <span>{label}</span> {images.smallArrowDown({ width: "7px", height: "4px" })}
        </ControlSelectedValue>
      </SimpleMenu>
    </ControlWrapper>
  );
};

export default FrequencySelector;
