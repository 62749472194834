import { PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import styled from "styled-components";

const domNode = document.createElement("div");
document.body.appendChild(domNode);

const BodyPortal = ({ children }: { children: ReactNode }) => createPortal(children, domNode);

const Wrapper = styled.div<{ maxWidth?: string; zIndex?: number }>`
  .__react_component_tooltip {
    padding: 6px 10px 7px 10px !important;
    font-size: 12px;
    border-radius: var(--shapes-border-radius-default);

    z-index: ${(props) => props.zIndex ?? "initial"};
    max-width: ${(props) => props.maxWidth ?? "initial"};

    text-overflow: ellipsis;
    overflow: hidden;

    &:before,
    &:after {
      display: none;
    }
  }
`;

interface OurTooltipProps extends PropsWithChildren, TooltipProps {
  wrapperStyle?: {
    maxWidth?: string;
    zIndex?: number;
  };
}

const Tooltip = ({ children, wrapperStyle, ...otherProps }: OurTooltipProps) => (
  <BodyPortal>
    <Wrapper {...wrapperStyle}>
      <ReactTooltip
        place="top"
        effect="solid"
        className="react-tooltip"
        delayShow={200}
        backgroundColor="#525f7f"
        {...otherProps}
      >
        {children}
      </ReactTooltip>
    </Wrapper>
  </BodyPortal>
);

export default Tooltip;
