import { CSSProperties, useEffect, useId, useRef, useState, RefObject } from "react";
import classNames from "classnames";
import styles from "./ActionInput.module.scss";
import { crossIcon, inputError, lockIcon } from "../../svg-images";
import IconButton from "../IconButton";

const getClickableIconStyles = (fn?: () => void, disabled?: boolean): CSSProperties | undefined => {
  if (disabled || !fn) {
    return undefined;
  }

  return { pointerEvents: "all", cursor: "pointer" };
};

type EndIconProps = {
  hasValue: boolean;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  onEndIconClick?: () => void;
  onClear?: () => void;
  withClear?: boolean;
  measureRef: React.RefObject<HTMLDivElement>;
};

type UnitProps = {
  units?: React.ReactNode;
  offset: number;
  measureRef: React.RefObject<HTMLDivElement>;
};

const EndIcon = ({ hasValue, disabled, withClear, endIcon, onClear, onEndIconClick, measureRef }: EndIconProps) => {
  if (hasValue && disabled) {
    return (
      <div ref={measureRef} className={styles.endIcon}>
        <IconButton disabled={disabled} size="small" icon={lockIcon} />
      </div>
    );
  }

  if (hasValue && withClear) {
    return (
      <div
        ref={measureRef}
        style={getClickableIconStyles(onClear, disabled)}
        className={styles.endIcon}
        onClick={onClear}
      >
        <IconButton disabled={disabled} size="small" icon={crossIcon} />
      </div>
    );
  }

  if (!endIcon) {
    return null;
  }

  return (
    <div
      ref={measureRef}
      style={getClickableIconStyles(onEndIconClick, disabled)}
      className={styles.endIcon}
      onClick={() => {
        if (!disabled && onEndIconClick) {
          onEndIconClick();
        }
      }}
    >
      <IconButton disabled={disabled} size="small" icon={endIcon} />
    </div>
  );
};

const Units = ({ units, offset, measureRef }: UnitProps) => {
  if (units) {
    return (
      <div style={{ paddingInlineEnd: offset }} className={styles.units} ref={measureRef}>
        {units}
      </div>
    );
  }

  return null;
};

const useTextWidth = (
  inputRef: RefObject<HTMLInputElement>,
  text: string,
  shouldMeasure: boolean,
  disabled: boolean,
): number | "100%" => {
  const minWidth = disabled ? 0 : 12;
  const [inputWidth, setInputWidth] = useState<number | "100%">(minWidth);

  useEffect(() => {
    if (disabled && shouldMeasure) {
      // don't show input with badges in case of disabled state
      setInputWidth(0);
    } else if (!shouldMeasure) {
      setInputWidth("100%");
    } else {
      const getTextWidth = (text: string, font: string): number => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
          context.font = font;
          return context.measureText(text).width;
        }
        return minWidth;
      };

      const getInputFont = (): string => {
        if (inputRef.current) {
          const style = window.getComputedStyle(inputRef.current);
          return `${style.fontSize} ${style.fontFamily}`;
        }
        return "";
      };

      const calculateWidth = (): void => {
        if (inputRef.current) {
          const font = getInputFont();
          const textWidth = getTextWidth(text || " ", font);
          const newWidth = Math.max(textWidth + 2, minWidth);

          setInputWidth(newWidth);
        }
      };

      calculateWidth();
    }
  }, [text, inputRef, shouldMeasure, minWidth]);

  return inputWidth;
};

export type ActionInputProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  type?: string;
  endIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  onEndIconClick?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
  readOnly?: boolean;
  inputDataStyle?: "menu";
  withClear?: boolean;
  labelIcon?: React.ReactNode;
  actions?: React.ReactNode;
  subInfo?: React.ReactNode;
  units?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  inputClassName?: string;
  onWrapperClick?: () => void;
  shouldShowLockIconWithActions?: boolean;
  wrapperRef?: RefObject<HTMLDivElement> | null;
  actionsClassName?: string;
};
const ActionInput = ({
  label,
  onChange,
  value,
  disabled,
  placeholder,
  hasError,
  errorMessage,
  endIcon,
  leftIcon,
  type,
  onEndIconClick,
  onClear,
  onFocus,
  readOnly = false,
  inputDataStyle,
  withClear = false,
  labelIcon,
  actions,
  subInfo,
  units,
  className,
  style,
  children,
  inputClassName,
  onWrapperClick,
  shouldShowLockIconWithActions = false,
  wrapperRef = null,
  actionsClassName,
}: ActionInputProps) => {
  const uniqeId = useId();
  // children are values (badges) in this case, and value - is a search value
  const disabledWithValue = Boolean((disabled && !!value) || (disabled && children));
  const [inputPadding, setInputPadding] = useState(0);
  const actionsRef = useRef<HTMLDivElement>(null);
  const unitsRef = useRef<HTMLDivElement>(null);
  const endIconRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const inputWidth = useTextWidth(inputRef, value, Boolean(children), Boolean(disabled));

  useEffect(() => {
    // Calculating paddingInlineEnd to ensure there is enough space for the input text if there are end icons

    if (children && disabled) {
      // no need to show input, having badges and disabled state
      setInputPadding(0);
    } else if (!unitsRef.current && !actionsRef.current && !endIconRef.current) {
      setInputPadding(0);
    } else {
      let additionalOffset = 10;

      if (unitsRef.current) {
        additionalOffset += unitsRef.current.offsetWidth;
      }

      if (actionsRef.current) {
        additionalOffset += actionsRef.current.offsetWidth;
      }

      if (endIconRef.current) {
        additionalOffset += endIconRef.current.offsetWidth;
      }

      setInputPadding(additionalOffset);
    }
  }, [actions, units]);

  const inputClasses = classNames(inputClassName, styles.input, {
    [styles.disabled_with_value]: disabledWithValue,
    [styles.error]: !!hasError,
    [styles.disabled]: disabled,
    [styles["focus-within"]]: isFocused,
  });

  const shouldShowLabel = label || labelIcon;
  const actionsClassNames = classNames(
    actionsClassName,
    {
      [styles.actionsDisabled]: Boolean(disabled),
      [styles.shiftedAction]: Boolean(endIcon),
    },
    styles.actions,
  );

  const focus = () => {
    if (!disabled) {
      setIsFocused(true);

      if (onWrapperClick) {
        onWrapperClick();
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={classNames(styles.wrapper, className)} data-style={inputDataStyle} style={style} ref={wrapperRef}>
      {shouldShowLabel && (
        <label htmlFor={uniqeId} className={styles.label}>
          {label && <div className={styles.labelString}>{label}</div>}
          {labelIcon && <div className={styles.labelIcon}>{labelIcon}</div>}
        </label>
      )}
      <div className={styles.input_wrapper} data-style={inputDataStyle}>
        {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
        <EndIcon
          measureRef={endIconRef}
          hasValue={Boolean(value || children)}
          withClear={withClear}
          disabled={disabled && (!actions || shouldShowLockIconWithActions)}
          onClear={onClear}
          onEndIconClick={onEndIconClick}
          endIcon={endIcon}
        />
        <Units measureRef={unitsRef} units={units} offset={endIconRef.current?.offsetWidth ?? 0} />
        {actions && !units && (
          <div ref={actionsRef} className={actionsClassNames}>
            {actions}
          </div>
        )}
        <div className={inputClasses} onClick={focus}>
          {/* children are used to display complex input values like badges in multiselect */}
          {children}
          <input
            ref={inputRef}
            onBlur={handleBlur}
            style={{ paddingInlineEnd: inputPadding, width: inputWidth }}
            type={type}
            id={uniqeId}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            readOnly={readOnly}
            data-style={inputDataStyle}
          />
        </div>
      </div>
      {hasError && errorMessage && (
        <div className={styles.error_message}>
          {inputError}
          <div className={styles.error_text}>{errorMessage}</div>
        </div>
      )}
      {subInfo && !hasError && <div className={styles.subInfo}>{subInfo}</div>}
    </div>
  );
};

export default ActionInput;
