import { Component } from "react";
import { withTranslation } from "react-i18next";
import NotificationRow from "components/NotificationRow";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import SettingsLayout from "components/Layout/SettingsLayout";
import { getBRGroups, createBRGroup, deactivateBRGroup, makeDefaultBRGroup } from "utils/apiHelpers";
import SearchInput from "components/UI/SearchInput";
import { PageWrapper } from "components/styled/Page";
import { GroupNameWrapper } from "components/styled/TableComponents";
import TableButtonsControl from "components/styled/TableButtonsControl";
import ModalDialog from "components/UI/ModalDialog";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import HeaderActionButton from "components/controls/HeaderActionButton";
import StatusBadge from "components/controls/StatusBadge";
import Lightbox from "components/Lightbox";
import Select from "components/UI/Select";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import styled from "styled-components";
import { ButtonState } from "components/controls/StyledButton";
import { getTitle, strIncludesCheck } from "utils/common";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import Sentry from "utils/sentryUtils";
import PopupMessage from "./PopupMessage";

const StatusFilterWrapper = styled.div`
  margin-inline-start: 15px;
  width: 120px;
`;

const title = "Punching Rules";
const metaTitle = title;

class PunchingRules extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      searchValue: "",
      groups: [],
      searchStatus: "active",
      notification: "",
    };
    document.title = getTitle(t(metaTitle));
  }
  UNSAFE_componentWillMount() {
    this.getContent();
  }
  getContent() {
    const { t } = this.props;
    if (!this.state.isFetching) {
      this.context.getCompany().then((company) => {
        getBRGroups({ companyUuid: company.uuid })
          .then((res) => {
            this.setState({
              groups: res.content,
              isFetching: false,
            });
          })
          .catch((er) => {
            Sentry.sendError(er);
            this.setState({
              isFetching: false,
              notification: er.message || `${er.status}: ${t("Something went wrong")}`,
              notificationType: "error",
            });
          });
      });

      this.setState({ isFetching: true });
    }
  }

  onDeactivateClick(obj) {
    const { t } = this.props;

    deactivateBRGroup({
      companyUuid: window.global_store.company.uuid,
      brGroupUuid: obj.uuid,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    }).then(() => {
      this.setState(
        {
          notification: t("Group has been deactivated"),
          selectedItem: null,
          warningPopupVisible: false,
        },
        () => this.getContent(),
      );
    });
  }
  onMakeDefault(obj) {
    const { t } = this.props;

    const body = {
      content: {
        updatedBy: window.global_store.profile.uuid,
      },
    };

    makeDefaultBRGroup({
      companyUuid: window.global_store.company.uuid,
      brGroupUuid: obj.uuid,
      body,
    }).then((r) => {
      this.setState(
        {
          warningDefaultGroupPopupVisible: false,
          selectedItem: null,
          notification: t("You changed default group"),
        },
        () => this.getContent(),
      );
    });
  }
  addItem(name) {
    const { history } = this.props;
    const body = {
      content: {
        name,
      },
    };

    body.content.createdBy = window.global_store.profile.uuid;
    createBRGroup({
      companyUuid: window.global_store.company.uuid,
      body,
    }).then((r) => {
      history.push(`/company/groups-of-rules/${r.content.uuid}`);
    });
  }
  getTableColumns(rows = []) {
    const { t, history } = this.props;
    const columns = [
      {
        label: t("Group"),
        accessor: "name",
        Cell: (row) => {
          const name = `${row.value === "default" ? t("default") : row.value}${
            row.original.isDefault ? ` (${t("Default")})` : ""
          }`;
          return <GroupNameWrapper isLocked={row.original.isLocked}>{name}</GroupNameWrapper>;
        },
        minWidth: 160,
      },
      {
        label: t("Start Date"),
        accessor: "businessRules",
        Cell: (row) => (row.value ? moment(row.value[0].startDate).format("DD/MM/YYYY") : ""),
        width: 160,
      },
      {
        label: t("Employees"),
        accessor: "userProfilesCount",
        width: 160,
      },
      {
        label: t("Status"),
        accessor: "status",
        align: "center",
        width: 100,
        Cell: (row) => (
          <div style={{ textAlign: "center" }}>
            <StatusBadge value={t(row.value)} type={row.value} />
            <TableButtonsControl
              dropToTop={row.viewIndex === rows.length - 1}
              dropdownButtons={this.getTableDropdownButtons({ row, history, t })}
              visibleButtons={this.getTableButtons({ row, t })}
            />
          </div>
        ),
      },
    ];
    return columns;
  }
  getTableButtons = ({ row, t }) => {
    const buttons = [];
    if (!row.original.isDefault && moment(row.original.businessRules[0].startDate).isSameOrBefore(moment(), "day")) {
      buttons.push({
        label: t("Make default"),
        onClick: () => {
          this.setState({
            warningDefaultGroupPopupVisible: true,
            selectedItem: row.original,
          });
        },
      });
    }
    return buttons;
  };
  getTableDropdownButtons = ({ row, history, t }) => {
    const buttons = [];
    if (row.original.status === "active") {
      buttons.push({
        label: t("Add Employees"),
        onClick: () => history.push(`/company/groups-of-rules/${row.original.uuid}?tab=employees`),
      });
    }
    if (row.original.status === "active" && !row.original.isDefault) {
      buttons.push({
        label: t("Deactivate"),
        onClick: () =>
          this.setState({
            selectedItem: row.original,
            warningPopupVisible: true,
          }),
      });
    }
    return buttons;
  };
  render() {
    const {
      notification,
      notificationType = "success",
      groups,
      popupVisible,
      searchValue,
      searchStatus,
      isFetching,
      warningPopupVisible,
      selectedItem,
      warningDefaultGroupPopupVisible,
    } = this.state;
    const { t } = this.props;
    const actionButtonTitle = "Create New";
    const noItemsTitle = "No Groups are available";
    const filteredItems = groups.filter((s) => {
      let filter = strIncludesCheck(s.name, searchValue);
      if (searchStatus) {
        filter = filter && s.status === searchStatus;
      }
      return filter;
    });

    return (
      <SettingsLayout
        title={t(title)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(actionButtonTitle)}
            onClick={() =>
              this.setState({
                popupVisible: true,
              })
            }
          />
        }
      >
        <PageWrapper>
          <NotificationRow
            employeesPage
            aligned
            type="message"
            message={t("Now you can set up Business Rules, like Hours Bank and Extra Hours, by groups of employees")}
          />
          {notification && (
            <NotificationRow
              employeesPage
              aligned
              withCloseButton
              onClose={() => this.setState({ notification: "" })}
              type={notificationType}
              message={notification}
            />
          )}
          <TablePage
            customColumnsAvailable={false}
            rows={filteredItems}
            tableDetails={false}
            columnSelectorOnFiltersRow
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={(ev) => this.setState({ searchValue: ev.target.value })}
                  placeholder={t("Search")}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select
                    value={searchStatus}
                    onChange={(searchStatus) => this.setState({ searchStatus })}
                    options={[
                      { value: "", label: t("Status") },
                      { value: "active", label: t("Active") },
                      { value: "deactivated", label: t("Deactivated") },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            columns={this.getTableColumns(filteredItems)}
            className="groups-table"
            loading={isFetching}
            getTrProps={(state, rowInfo, column) => ({
              onClick: (e) => {
                if (rowInfo.original.uuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/company/groups-of-rules/${rowInfo.original.uuid}`,
                    }).click();
                  } else {
                    this.props.history.push(`/company/groups-of-rules/${rowInfo.original.uuid}`);
                  }
                }
              },
            })}
            noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          />

          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              onClose={() => {
                this.setState({ popupVisible: false });
              }}
              onYes={this.addItem.bind(this)}
            />
          </ModalDialog>
          <ModalDialog isOpen={warningPopupVisible} onClose={() => this.setState({ warningPopupVisible: false })}>
            <Lightbox
              title={t("Deactivate Group?")}
              text={t("Are you sure you want to deactivate this group?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({ selectedItem: null, warningPopupVisible: false });
              }}
              onYes={() => {
                this.onDeactivateClick(selectedItem);
              }}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={warningDefaultGroupPopupVisible}
            onClose={() => this.setState({ warningDefaultGroupPopupVisible: false })}
          >
            <Lightbox
              title={t("Make group default?")}
              text={t("Are you sure you want to make this group default?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  warningDefaultGroupPopupVisible: false,
                });
              }}
              onYes={() => {
                this.onMakeDefault(selectedItem);
              }}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}
export default withRouter(withTranslation(TranslationNamespaces.companyRules)(PunchingRules));
