import { Component, PropsWithChildren } from "react";
import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Portal from "@atlaskit/portal";
import { stylesheet } from "astroturf";
import * as images from "components/svg-images";

const Overlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 1);
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  height: 100vh;
  overflow: hidden;
`;

const styles = stylesheet`
.Dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 0;
  margin: 0;
  
  background: var(--colors-default);
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.ModalWrapper {  
  .ModalHeader {
    width: 100%;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    background-color: var(--colors-primary);
    height: 60px;
    justify-content: space-between;
    .Logo {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.386 14.583a7.244 7.244 0 011.555.614l3.525-13.113A1.659 1.659 0 0016.292.056a1.667 1.667 0 00-2.034 1.17L10.73 14.343a7.124 7.124 0 011.655.24zM12.254 4.411a1.66 1.66 0 00-3.21-.857L5.631 16.247A7.242 7.242 0 019.569 14.4l2.685-9.989zM7.847 20.8a2.77 2.77 0 013.391-1.952 2.759 2.759 0 011.96 3.38 2.77 2.77 0 01-3.393 1.95 2.757 2.757 0 01-1.958-3.379zm1.098 6.577c3.25.867 6.591-1.054 7.461-4.293.872-3.237-1.057-6.567-4.307-7.434-3.25-.868-6.59 1.053-7.462 4.292-.87 3.238 1.059 6.567 4.308 7.435z' fill='%23ffffff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.769 10.086a1.666 1.666 0 00-2.035 1.17L17.477 23.37c-.86 3.194-3.728 5.319-7.028 5.319-3.942-.04-7.126-3.236-7.126-7.174V16.52c0-1.82-1.496-3.31-3.323-3.31v8.305c0 5.73 4.612 10.385 10.337 10.484 4.84.063 9.093-3.1 10.35-7.771l3.256-12.114a1.66 1.66 0 00-1.174-2.028z' fill='%23ffffff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4 19.389l3.607-13.412a1.66 1.66 0 00-3.21-.857l-2.88 10.709a7.194 7.194 0 012.482 3.56z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      width: 60px;
      min-width: 60px;
      height: 60px;
    }
    .ModalClose {
      border: none;
      outline: none;
      background: none;
      position: absolute;
      inset-inline-end: 40px;
      cursor: pointer;
      svg path {
        fill: var(--colors-default);
      }
    }
  }
  .ContainerFluid {
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
  }
}
`;

interface ModalDialogProps extends PropsWithChildren {
  onClose: () => void;
  isOpen: boolean;
}

class ModalDialogFullPage extends Component<ModalDialogProps> {
  wrapperRef: HTMLDivElement | null = null;

  constructor(props: ModalDialogProps) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  closePopup = () => {
    this.props.onClose();
  };

  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  };

  render() {
    const { children, isOpen } = this.props;

    return (
      <Portal key="FullPageModal" zIndex={20}>
        <TransitionGroup>
          {isOpen && (
            <CSSTransition classNames="modal-transition" timeout={{ enter: 175, exit: 175 }}>
              <Overlay $isOpen className="overlay">
                <div className={styles.Dialog} ref={this.setWrapperRef}>
                  <div className={styles.ModalWrapper}>
                    <div className={styles.ModalHeader}>
                      <div className={styles.Logo} />
                      <button className={styles.ModalClose} type="button" onClick={this.closePopup}>
                        {images.removeIcon()}
                      </button>
                    </div>
                    <div className={`${styles.ContainerFluid} ${styles.MarginTop60}`}>{children}</div>
                  </div>
                </div>
              </Overlay>
            </CSSTransition>
          )}
        </TransitionGroup>
      </Portal>
    );
  }
}

export default ModalDialogFullPage;
