import { useQuery } from "react-query";
import { getCompanyRules } from "utils/apiHelpers";

export const useCompanyRules = () => {
  const STALE_TIME = 1000 * 1 * 60 * 60;

  const result = useQuery(
    `rq_company_rules`,
    async () => {
      const response = await getCompanyRules();

      return response;
    },
    { staleTime: STALE_TIME, retry: false },
  );

  return result;
};
