import { ActivityCustomField } from "components/Projects/projectsApiTypes";

export const makeEmptyActivityCustomFields = (customFieldsSettings: ActivityCustomField[] = []) => {
  const customFields = customFieldsSettings
    .filter((cfs) => cfs.active && cfs.deletedAt == null)
    .map((cfs) => {
      const cf = {
        uuid: cfs.uuid,
        name: cfs.name,
        fieldType: cfs.fieldType,
        required: cfs.required,
        value: "",
        error: null,
      };
      return cf;
    });

  return customFields;
};
