import React, { Component } from "react";
import { updateBRGroupSettings, getBRGroup, getCurrentCompany } from "utils/apiHelpers";
import { withTranslation } from "react-i18next";
import CheckboxControl from "components/UI/NewCheckbox";
import TimeControl from "components/controls/TimeControl";
import BEM from "utils/BEM";
import NotificationRow from "components/NotificationRow";
import "styles/company-rules.scss";
import FieldWrapper from "components/UI/FieldWrapper";
import MaskedInput from "components/styled/MaskedInput";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { ErrorLabel } from "components/UI/TextLabels";
import Button from "components/controls/StyledButton";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { TranslationNamespaces } from "types/translationNamespaces";

const b = BEM.b("company-rules");

const convertTimeToMinutes = (time) => parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
class PunchingRulesGroupNightShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nightShiftActive: false,
      allTime: true,
      isLoading: false,
      isLocked: false,
      startTime: "",
      endTime: "",
    };
  }

  componentDidMount() {
    this.getState(this.props);
  }

  getState(props) {
    getCurrentCompany().then((company) => {
      const { id } = props;
      getBRGroup({ companyUuid: company.uuid, brGroupUuid: id }).then((r) => {
        this.setState({ isLoading: true });
        let businessRules = {};
        if (r.content && r.content.businessRules.length) {
          businessRules = r.content.businessRules[0].rules.nightShift;
        } else {
          return;
        }
        this.setState({
          isLocked: r.content.isLocked,
          status: r.content.status,
          nightShiftActive: businessRules.active,
          name: businessRules.name,
          allTime: businessRules.nightShiftApplyEntirePeriod,
          startTime: businessRules.nightShiftStartTime,
          endTime: businessRules.nightShiftEndTime,
          isLoading: false,
        });
      });
    });
  }

  onNightShiftChange = (checked) => {
    this.setState({ nightShiftActive: checked });
  };

  getFormErrors() {
    const { t } = this.props;
    const { startTime, endTime, name } = this.state;
    let errors = {};

    if (!startTime) {
      errors.startTime = t("Start Time can't be empty");
    } else if (this.startTime && this.startTime.getError()) {
      errors.startTime = `${t("Start Time")} ${t(this.startTime.getError())}`;
    }

    if (!name) {
      errors.name = t("Percentage can't be empty");
    }
    if (this.endTime && this.endTime.getError()) {
      errors.endTime = `${t("End Time")} ${t(this.endTime.getError())}`;
    }

    if (!errors.startTime && !errors.endTime) {
      const convertedStart = convertTimeToMinutes(startTime);
      const convertedEnd = convertTimeToMinutes(endTime);
      if (convertedStart < 720 && convertedEnd > 720) {
        // night shift time validation
        const overNoonError = t("Night shift can't be created over noon");
        errors.endTime = overNoonError;
      }
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }

  onNextClick = () => {
    const { nightShiftActive, allTime, startTime, endTime, name } = this.state;
    const { t } = this.props;
    const errors = this.getFormErrors();

    if (!errors) {
      this.setState({ errors, isLoading: true });
      const { id } = this.props;
      const data = {
        companyUuid: window.global_store.company.uuid,
        businessRulesType: "nightShift",
        brGroupUuid: id,
        body: {
          content: {
            name: name ? `${name}` : "",
            active: nightShiftActive,
            nightShiftApplyEntirePeriod: allTime,
            nightShiftStartTime: startTime,
            nightShiftEndTime: endTime,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      };

      data.body.startTime = startTime;
      data.body.endTime = endTime;

      updateBRGroupSettings(data)
        .then(() => {
          this.setState({ isLoading: false });
          if (this.props.onSave) {
            this.props.onSave({
              notificationType: "success",
              notification: t("Group has been updated!"),
            });
          }
        })
        .catch((e) => {
          window.scrollTo(0, 0);
          if (this.props.onSave) {
            this.props.onSave({
              notificationType: "error",
              notification: `${t(e.message)}${
                e.originalRequest && e.originalRequest.errors ? `: ${e.originalRequest.errors[0].message}` : ""
              }`,
            });
          }
        });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { isLoading, nightShiftActive, startTime, endTime, allTime, isLocked, status, errors, name } = this.state;
    const { t, flags } = this.props;

    const groupDisabled = status !== "active";
    const groupLocked = isLocked;
    const inactive = groupDisabled || groupLocked;
    const percentageMask = createNumberMask({
      prefix: "",
      suffix: "%",
    });

    return (
      <div className={b()}>
        {groupLocked ? (
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type="locked"
            style={{ marginTop: 0, marginBottom: "25px" }}
            message={
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  color: "var(--colors-mainText)",
                }}
              >
                {t(
                  "Editing of this Rules Group is locked. For any changes please contact support or create a new set of rules.",
                )}
              </div>
            }
          />
        ) : null}
        <div>
          <div className={b("settings")}>
            <div className={b("activate")}>
              <CheckboxControl
                ios
                checked={nightShiftActive}
                disabled={inactive}
                label={nightShiftActive ? t("Deactivate nightshift") : t("Activate Night Shift")}
                onChange={this.onNightShiftChange}
              />
            </div>
            <FieldWrapper
              fieldName={t("Percentage %")}
              width="191px"
              tooltipText={t("nighshift-name-tooltip-text")}
              wrapperWidth="191px"
            >
              <MaskedInput
                placeholder="%"
                error={errors && !!errors.name}
                disabled={!nightShiftActive || inactive}
                locked={inactive}
                value={name}
                onChange={(ev) => {
                  let value = ev.target.value.replace(/[^\d]/g, "");
                  value = value ? parseInt(value, 10) : 0;
                  this.setState({ name: value });
                }}
                mask={percentageMask}
              />
            </FieldWrapper>
            {errors && errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
            <div className={b("field", ["limit-fields"])}>
              <div className={b("limit-field")}>
                <div className={b("field-title")}>{t("Start Time")}</div>
                <TimeControl
                  disabled={!nightShiftActive || inactive}
                  value={startTime}
                  onTimeChange={(val) => this.setState({ startTime: val.value })}
                  onRef={(ref) => (this.startTime = ref)}
                  error={errors && errors.startTime}
                  placeholder="00:00"
                />
              </div>
              <div className={b("limit-field")}>
                <div className={b("field-title")}>{t("End Time")}</div>
                <TimeControl
                  disabled={!nightShiftActive || inactive}
                  value={endTime}
                  onTimeChange={(val) => this.setState({ endTime: val.value })}
                  onRef={(ref) => (this.endTime = ref)}
                  error={errors && errors.endTime}
                  placeholder="00:00"
                />
              </div>
            </div>
            {errors && errors.startTime && <ErrorLabel>{errors.startTime}</ErrorLabel>}
            {errors && errors.endTime && <ErrorLabel>{errors.endTime}</ErrorLabel>}

            {!flags.moveExtendedNightShiftToSchedule && (
              <div className={b("field", ["apply-with-eh"])} style={{ display: "flex" }}>
                <CheckboxControl
                  checked={!nightShiftActive ? false : allTime}
                  disabled={!nightShiftActive || inactive}
                  label={t("Apply entire period started with Nightshift to nightshifts rules.")}
                  onChange={(checked) => {
                    this.setState({ allTime: checked });
                  }}
                />
              </div>
            )}
            <br />
          </div>
          {!inactive ? (
            <div style={{ width: "132px", marginTop: "35px" }}>
              <Button state="primary" loading={isLoading} onClick={this.onNextClick} value={t("Save")} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withLDConsumer()(withTranslation(TranslationNamespaces.companyRules)(PunchingRulesGroupNightShift));
