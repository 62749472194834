import { Component } from "react";
import BEM from "utils/BEM";
import { validatePhoneNumber, formatPhone } from "utils/common";
import "../styles/phone-number-change-control.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { changePhone } from "utils/apiHelpers";
import ClickOutside from "react-click-outside";
import Spinner from "@atlaskit/spinner";
import GlobalContext from "context/global-context";
import { ClickOutsideType } from "types/common";
import PhoneNumberField from "./PhoneNumberField";

const b = BEM.b("phone-number-change-control");

interface PhoneNumberChangeControlProps extends WithTranslation {
  phoneNumber: string;
  loading: boolean;
  employeeId: number;
  onChange: () => void;
}

interface PhoneNumberChangeControlState {
  phoneNumber: string;
  phoneNumberToChange: string;
  editMode: boolean;
  error: string;
  countryCode: string | null;
}

class PhoneNumberChangeControl extends Component<PhoneNumberChangeControlProps, PhoneNumberChangeControlState> {
  static contextType = GlobalContext;
  constructor(props: PhoneNumberChangeControlProps) {
    super(props);
    this.state = {
      phoneNumber: "",
      phoneNumberToChange: "",
      editMode: false,
      error: "",
      countryCode: null,
    };
  }

  async componentDidMount(): Promise<void> {
    const company = await this.context.getCompany();
    let { countryCode } = this.state;
    if (company?.default_location?.country) {
      countryCode = company?.default_location.country.toLowerCase();
    }
    this.setState({ countryCode, phoneNumber: this.props.phoneNumber });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PhoneNumberChangeControlProps): void {
    this.setState({ phoneNumber: nextProps.phoneNumber });
  }

  validatePhoneNumber(): string {
    const { phoneNumberToChange } = this.state;
    const { t } = this.props;
    let error = validatePhoneNumber(phoneNumberToChange, false);
    if (!error && `+${this.state.phoneNumberToChange}` === this.props.phoneNumber) {
      error = "You have already activated this phone number. Please use Change Device";
    }
    if (error) {
      error = t(error);
    }

    return error;
  }

  onPhoneNumberChange = (val: string): void => {
    this.setState({
      phoneNumberToChange: val,
    });
  };

  changePhoneNumber = async (): Promise<void> => {
    if (this.state.editMode) {
      const error = this.validatePhoneNumber();
      if (error) {
        this.setState({ error });
      } else {
        await changePhone({ employeeId: this.props.employeeId, phoneNumber: `+${this.state.phoneNumberToChange}` });

        this.setState(
          {
            editMode: false,
            phoneNumber: `+${this.state.phoneNumberToChange}`,
            phoneNumberToChange: "",
            error: "",
          },
          () => {
            if (this.props.onChange) {
              this.props.onChange();
            }
          },
        );
      }
    } else {
      this.setState({
        editMode: true,
      });
    }
  };

  changeDevice = async (): Promise<void> => {
    await changePhone({ employeeId: this.props.employeeId, phoneNumber: this.props.phoneNumber });
    this.setState(
      {
        phoneNumberToChange: "",
        editMode: false,
        error: "",
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      },
    );
  };

  getPhoneLabel = (): null | JSX.Element => {
    const { phoneNumber, editMode } = this.state;
    const { t } = this.props;
    if (!editMode && phoneNumber) {
      return (
        <div className={b("phone-number")} onClick={(): void => this.setState({ editMode: true })}>
          {formatPhone(phoneNumber, true)}
        </div>
      );
    }
    if (!editMode) {
      return (
        <div className={b("phone-number-empty")} onClick={(): void => this.setState({ editMode: true })}>
          {t("You don't have active phone number")}
        </div>
      );
    }
    return null;
  };

  render(): JSX.Element {
    const { editMode, error = "", countryCode } = this.state;
    const { t, loading } = this.props;
    if (loading) {
      return <Spinner size="small" />;
    }
    return (
      <div className={b("info")}>
        <div className={b("info-title")}>{t("Phone Number")}</div>
        <ClickOutside<ClickOutsideType>
          className={b("phone-number-wrapper")}
          onClickOutside={(): void => this.setState({ editMode: false })}
        >
          {this.getPhoneLabel()}
          {editMode && (
            <PhoneNumberField
              countryCode={countryCode}
              isValid={!error}
              value=""
              newField
              onPhoneChange={this.onPhoneNumberChange}
            />
          )}

          <div className={b("change-phone", { edit: editMode })} onClick={this.changePhoneNumber}>
            {t("Change Number")}
          </div>
          {this.props.phoneNumber ? (
            <div className={b("change-phone", { edit: true, "change-device": true })} onClick={this.changeDevice}>
              {t("Change Device")}
            </div>
          ) : null}
        </ClickOutside>
        {error && <div className={b("error")}>{error}</div>}
      </div>
    );
  }
}
export default withTranslation(TranslationNamespaces.employeesPage)(PhoneNumberChangeControl);
