import { Component } from "react";
import BEM from "utils/BEM";
import "styles/signup.scss";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { fireEvent, urlParam } from "utils/common";
import cookie from "react-cookies";
import { chooseProfile, createCompany, getIndustryTypes, refetchProfiles, setUserLocale } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import ic_company from "img/ic_company.png";
import ic_household from "img/ic_household.png";
import { getLocale } from "utils/translationHelpers";
import SelectControl from "../SelectControl";
import LocationSearchInput from "../LocationSearchInput";
import EmployeesCountControl from "../Onboarding/EmployeesCountControl";

const b = BEM.b("signup");
const cc = BEM.b("create-company");
const input = BEM.b("input");

class CreateCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: this.props.fullName,
      companyType: {
        value: "llc",
        error: "",
      },
      companyName: {
        value: "",
        error: "",
      },
      address: {
        value: {
          formatted: "",
          lat: "",
          lon: "",
          country: "",
        },
        error: "",
      },
      category: {
        value: "",
        options: [],
        error: "",
      },
      employeesCount: {
        value: "",
        error: "",
      },
      refCode: props.refCode || "",
      responseError: "",
      loading: false,
    };
    const { t } = this.props;
    document.title = t("Create Company");
  }
  componentDidMount() {
    const { t, ignoreFetch } = this.props;
    if (!ignoreFetch) {
      getIndustryTypes()
        .then((r) => {
          const { category } = this.state;
          category.options = r
            .map((c) => ({
              value: c.name,
              label: t(`industry-types|${c.key}`),
              id: c.id,
            }))
            .sort((a, b) => {
              if (b.value === "Others") {
                return -1;
              }
              if (b.label > a.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            });
          this.setState({ category });
        })
        .catch((e) => {
          console.log("can't get industry types");
        });
    }
  }

  onCompanyNameChange = (ev) => {
    this.setState({ companyName: { value: ev.target.value } });
  };

  onAddressChange = (value) => {
    this.setState({ address: { value } });
  };

  onCategoryChange = (select) => {
    const { category } = this.state;
    category.error = "";
    category.value = select;
    this.setState({ category });
  };

  employeesCountChange(employeesCount, ev) {
    this.setState({ employeesCount: { value: employeesCount } });
  }

  onCompanyTypeClick = (type) => {
    this.setState({ companyType: { value: type } });
  };

  validateCompanyName() {
    const { companyName } = this.state;
    const { t } = this.props;
    const { value } = companyName;
    let error = "";
    if (!value) {
      error = t("Company name can't be empty");
    } else {
      error = "";
    }
    return error;
  }

  validateEmployeesCount() {
    const { employeesCount } = this.state;
    const { t } = this.props;
    const { value } = employeesCount;
    if (!value) return t("Employees count can't be empty");
    return "";
  }

  validateAddress() {
    const { address } = this.state;
    const { t } = this.props;
    const value = address.value.country;
    let error = "";
    if (!value) {
      error = t("Address can't be empty.");
      error += ` ${t("Please select address form dropdown")}`;
    } else {
      error = "";
    }
    return error;
  }

  validateCategory() {
    const { category } = this.state;
    const { t } = this.props;
    const { value } = category.value;
    let error = "";
    if (!value) {
      error = t("Category can't be empty");
    } else {
      error = "";
    }
    return error;
  }

  onNextClick(ev) {
    const { companyType, companyName, address, category, employeesCount, refCode } = this.state;
    ev.preventDefault();
    companyName.error = this.validateCompanyName();
    address.error = this.validateAddress();
    if (companyType.value === "llc") {
      category.error = this.validateCategory();
      employeesCount.error = this.validateEmployeesCount();
    } else {
      category.error = "";
      employeesCount.error = "";
    }
    if (companyName.error || address.error || (companyType.value === "llc" && category.error) || employeesCount.error) {
      this.setState({ companyName, address, category, employeesCount });
    } else {
      this.submitForm(
        companyType.value,
        companyName.value,
        address.value,
        employeesCount.value,
        category.value,
        refCode,
      );
    }
  }

  submitForm(companyType, companyName, address, employeesCount, category, refCode) {
    this.setState({ loading: true });

    createCompany({
      companyType,
      companyName,
      address,
      employeesCount,
      category,
      refCode,
    })
      .then((createCompany) => {
        const { t, trafficSource, trafficMedium, trafficCampaing, email } = this.props;

        refetchProfiles().then(async (profiles) => {
          if (profiles && profiles.length) {
            const createdProfile = profiles.filter((p) => p.company && p.company.id === createCompany.id)[0];
            if (createdProfile) {
              fireEvent("LogOut");
              await chooseProfile({ profileId: createdProfile.id }, true);
              try {
                const language = getLocale();
                await setUserLocale({ id: createdProfile.id, locale: language });
              } catch (error) {
                Sentry.sendError(error);
              }
            }
          }
          window.location = "/";
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          responseError: `Server responded with error:${e}`,
          loading: false,
        });
      });
  }

  render() {
    const { companyType, companyName, address, category, employeesCount, responseError, loading, refCode } = this.state;
    const { t } = this.props;
    let buttonProps = {};
    if (loading) {
      buttonProps = { disabled: "disabled" };
    }
    const formProps = {};

    const email = urlParam("email");

    return (
      <div className={cc("wrapper")}>
        <div className={b("container")}>
          <div className={b("header")}>{t("Select your account type")}</div>
          {responseError && <div className={b("response-error", { visible: true })}>{responseError}</div>}
          <form
            noValidate="novalidate"
            action="/register"
            acceptCharset="UTF-8"
            method="post"
            {...formProps}
            id="hs_create-company"
          >
            <div className={cc()}>
              <div className={cc("company-types")}>
                <div
                  className={cc("company-type", {
                    llc: true,
                    active: companyType.value === "llc",
                  })}
                  onClick={() => this.onCompanyTypeClick("llc")}
                >
                  <img src={ic_company} alt="" className={b("company-type-img")} />
                  <div
                    className={cc("company-type-name", {
                      active: companyType.value === "llc",
                    })}
                  >
                    {t("Company")}
                  </div>
                  <div
                    className={cc("company-type-description", {
                      active: companyType.value === "llc",
                    })}
                  >
                    {t("Restaurantes, lojas, escritórios")}
                  </div>
                </div>
                <div
                  className={cc("company-type", {
                    household: true,
                    active: companyType.value === "household",
                  })}
                  onClick={() => this.onCompanyTypeClick("household")}
                >
                  <img src={ic_household} alt="" className={cc("company-type-img")} />
                  <div
                    className={cc("company-type-name", {
                      active: companyType.value === "household",
                    })}
                  >
                    {t("Home")}
                  </div>
                  <div
                    className={cc("company-type-description", {
                      active: companyType.value === "household",
                    })}
                  >
                    {t("Diaristas, jardineiros, motoristas")}
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label label_onboarding">
                  {companyType.value === "llc" ? t("Company name") : t("Home type. E.g. Beach house")}
                </label>
                <input
                  name="company"
                  className={input({
                    error: companyName.error,
                    onboarding: true,
                  })}
                  type="text"
                  placeholder={t("My Company")}
                  size="70"
                  value={companyName.value}
                  onChange={this.onCompanyNameChange}
                />
                {companyName.error && <span className="field-error">{companyName.error}</span>}
              </div>
              <div className="field">
                <label className="label label_onboarding">{t("Address")}</label>
                <LocationSearchInput
                  address={address.value}
                  isOnboarding
                  isValid={!address.error}
                  onChange={this.onAddressChange}
                />
                {/* <input className={input({error: address.error})}  type="text" placeholder={t("street name, number")} value={address.value} onChange={this.onAddressChange.bind(this)} /> */}
                {address.error && <span className="field-error">{address.error}</span>}
              </div>
              {companyType.value === "llc" && (
                <div className="field">
                  <label className="label label_onboarding">{t("Category")}</label>
                  <SelectControl
                    name="category"
                    value={category.value}
                    onboarding
                    placeholder={t("Please select..")}
                    onChange={this.onCategoryChange}
                    options={category.options}
                    error={category.error}
                  />
                  {category.error && <span className="field-error">{category.error}</span>}
                </div>
              )}
              {companyType.value === "llc" && (
                <div className="field">
                  <label className="label label_onboarding">{t("Number of employees")}</label>
                  <EmployeesCountControl
                    onChange={(val) => this.employeesCountChange(val)}
                    value={employeesCount.value}
                  />
                  {employeesCount.error && <span className="field-error">{employeesCount.error}</span>}
                </div>
              )}
              <div style={{ display: "none" }}>
                <label htmlFor="numero_de_funcionarios">Employees number</label>
                <input type="text" name="numero_de_funcionarios" value={employeesCount.value} />
              </div>
              <div style={{ display: "none" }}>
                <label htmlFor="industry">Employees number</label>
                <input type="text" name="industry" value={category && category.value ? category.value.label : ""} />
              </div>
              <div className="field">
                <label className="label label_onboarding">{t("Invite Code (optional)")}</label>
                <input
                  className={input({ onboarding: true })}
                  placeholder={t("Invite Code")}
                  type="text"
                  value={refCode}
                  size="70"
                  onChange={(ev) => this.setState({ refCode: ev.target.value })}
                />
              </div>
              <div className="field" style={{ display: "none" }}>
                <label className="label label_onboarding">EMAIL</label>
                <input
                  className={input({ onboarding: true })}
                  placeholder="EMAIL"
                  type="email"
                  value={email}
                  size="70"
                />
              </div>
            </div>
            <div className={`button-next ${buttonProps.disabled && "button-next_disabled"}`}>
              <input
                name="login"
                type="button"
                className={b("button")}
                {...buttonProps}
                value={t("Continue")}
                onClick={this.onNextClick.bind(this)}
              />
            </div>
          </form>
          <div style={{ marginTop: "35px", textAlign: "center" }}>
            <a
              href="/login"
              onClick={() => {
                cookie.remove("userEmail", { path: "/" });
                cookie.remove("userToken", { path: "/" });
                localStorage.removeItem("oi_auth");
              }}
            >
              {t("login with another account")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation(["signup", "industry-types"])(CreateCompany));
