import BetaBadge from "components/Layout/BetaBadge";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";

const Row = styled.div`
  position: fixed;
  inset-inline-start: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;

  background: #e9f5ff;

  .beta-badge {
    margin-inline-end: 16px;
  }
`;

const RowText = styled.span`
  color: var(--colors-primary);
  font-size: var(--typography-font-size-default);
  line-height: 130%;
`;

const BetaRow = () => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);

  return (
    <Row>
      <RowText>{t("Dashboard feature is still in beta")}</RowText>
    </Row>
  );
};

export default BetaRow;
