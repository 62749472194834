import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import SettingsLayout from "components/Layout/SettingsLayout";
import NotificationRow from "components/NotificationRow";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import Tabs from "components/UI/Tabs";
import PunchingRulesGroupHoursbank from "components/PunchingRules/PunchingRulesGroupHoursbank";
import PunchingRulesGroupTolerance from "components/PunchingRules/PunchingRulesGroupTolerance";
import PunchingRulesGroupNightShift from "components/PunchingRules/PunchingRulesGroupNightShift";
import PunchingRulesGroupEmployees from "components/PunchingRules/PunchingRulesGroupEmployees";
import { PageWrapper } from "components/styled/Page";
import { ErrorLabel } from "components/UI/TextLabels";
import { getTitle, urlParam } from "utils/common";
import { getBRGroup, updateBRGroup, getCompanyRules } from "utils/apiHelpers";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BusinessRuleGroup, BusinessRuleGroupStatus } from "types/models/businessRulesGroup";
import { CompanyRuleNames } from "types/models/companyRules";
import GlobalContext from "../../context/global-context";
import PunchingRulesGroupOnCall from "./PunchingRulesGroupOnCall";
import PunchingRulesGroupCrossShift from "./PunchingRulesGroupCrossShift";
import { PunchingRulesGroupHoursDistribution } from "./PunchingRulesGroupHoursDistribution";

enum PunchingRulesGroupTab {
  payedOvertime = "payedOvertime",
  nightShift = "nightShift",
  hoursDistribution = "hoursDistribution",
  tolerance = "tolerance",
  employees = "employees",
  onCall = "onCall",
  crossShift = "crossShift",
}

interface PunchingRulesGroupProps extends WithTranslation, RouteComponentProps<{ id: string }> {}

interface PunchingRulesGroupState {
  isFetching: boolean;
  activeTab: PunchingRulesGroupTab;
  title: string;
  notification: string | null;
  notificationType: NotificationType | null;
  name: string;
  errors: Record<string, string> | null;
  group: BusinessRuleGroup | null;
}

class PunchingRulesGroup extends Component<PunchingRulesGroupProps, PunchingRulesGroupState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  TABS: { name: PunchingRulesGroupTab; label: string }[];

  constructor(props: PunchingRulesGroupProps) {
    super(props);

    const { t } = props;
    const metaTitle = "Punching Rules";

    this.state = {
      activeTab: (urlParam("tab") as PunchingRulesGroupTab | null) || PunchingRulesGroupTab.payedOvertime,
      title: metaTitle,
      notification: null,
      notificationType: null,
      name: "",
      isFetching: false,
      errors: null,
      group: null,
    };

    this.TABS = [
      { name: PunchingRulesGroupTab.payedOvertime, label: t("Payed Overtime") },
      // { name: PunchingRulesGroupTab.nightShift, label: t("Night Shift") },
      { name: PunchingRulesGroupTab.hoursDistribution, label: t("Hours Distribution") },
      { name: PunchingRulesGroupTab.tolerance, label: t("Tolerance") },
      { name: PunchingRulesGroupTab.onCall, label: t("On Call") },
      { name: PunchingRulesGroupTab.employees, label: t("Employees") },
    ];

    document.title = getTitle(t(metaTitle));
  }

  async componentDidMount() {
    const { t } = this.props;
    const businessRules = await getCompanyRules();
    const isCrossShiftIntervalEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_CROSS_SHIFT_INTERVAL_ENABLED)
        ?.value || false;

    if (isCrossShiftIntervalEnabled) {
      // insert cross shift tab before last tab
      this.TABS.splice(this.TABS.length - 1, 0, { name: PunchingRulesGroupTab.crossShift, label: t("Cross Shift") });
    }

    this.getState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PunchingRulesGroupProps) {
    this.getState(nextProps);
  }

  getState = (props: PunchingRulesGroupProps) => {
    const { t, match } = props;
    const { id } = match.params;

    if (id && !this.state.isFetching) {
      this.setState({ isFetching: true }, async () => {
        const company = await this.context.getCompany();
        const r = await getBRGroup({ companyUuid: company.uuid, brGroupUuid: id });

        this.setState({
          group: r.content,
          title: `${r.content.name === "default" ? t("default") : r.content.name} - ${this.getTitle(
            this.state.activeTab,
          )}`,
          name: r.content.name,
          isFetching: false,
        });
      });
    }
  };

  onSave = async ({
    notification,
    notificationType,
    additionalSettings,
  }: {
    notification: string;
    notificationType: NotificationType;
    additionalSettings?: {
      shouldUpdateExternalId?: boolean;
      externalId?: string;
    };
  }) => {
    const { name } = this.state;
    const { t, match } = this.props;
    let errors: PunchingRulesGroupState["errors"] = {};

    if (!name) {
      errors.name = t("Please enter group name");
    } else {
      errors = null;

      if (notificationType !== NotificationType.error) {
        const body = {
          content: {
            name,
            updatedBy: window.global_store.profile.uuid,
          },
        };

        if (additionalSettings?.shouldUpdateExternalId) {
          body.content.externalId = additionalSettings.externalId || null;
        }

        try {
          await updateBRGroup({
            companyUuid: window.global_store.company.uuid,
            brGroupUuid: match.params.id,
            body,
          });
          this.setState({
            notificationType: NotificationType.success,
            notification: t("Group has been updated!"),
          });
        } catch (e) {
          this.setState({
            notificationType: NotificationType.error,
            notification: (e as any).error.originalRequest.message,
          });
        }
      } else {
        this.setState({
          notificationType,
          notification,
        });
      }
    }

    this.setState({ errors });
  };

  getContent = (activeTab: PunchingRulesGroupTab) => {
    const { match } = this.props;
    const { group } = this.state;
    const { id } = match.params;

    let content = null;
    switch (activeTab) {
      case PunchingRulesGroupTab.payedOvertime:
        content = <PunchingRulesGroupHoursbank id={id} onSave={this.onSave} />;
        break;
      case PunchingRulesGroupTab.nightShift:
        content = <PunchingRulesGroupNightShift id={id} onSave={this.onSave} />;
        break;
      case PunchingRulesGroupTab.hoursDistribution:
        content = <PunchingRulesGroupHoursDistribution onSave={this.onSave} group={group} />;
        break;
      case PunchingRulesGroupTab.tolerance:
        content = <PunchingRulesGroupTolerance id={id} onSave={this.onSave} />;
        break;
      case PunchingRulesGroupTab.onCall:
        content = <PunchingRulesGroupOnCall id={id} onSave={this.onSave} group={group} />;
        break;
      case PunchingRulesGroupTab.crossShift:
        content = group ? <PunchingRulesGroupCrossShift onSave={this.onSave} group={group} /> : null;
        break;
      case PunchingRulesGroupTab.employees:
        content = <PunchingRulesGroupEmployees id={id} onSave={this.onSave} group={group} />;
        break;
      default:
        content = null;
        break;
    }

    return content;
  };

  getTitle = (tab: PunchingRulesGroupTab) => {
    const { t } = this.props;

    switch (tab) {
      case PunchingRulesGroupTab.nightShift:
        return t("NightShift");
      case PunchingRulesGroupTab.tolerance:
        return t("Tolerance");
      case PunchingRulesGroupTab.employees:
        return t("Employees");
      case PunchingRulesGroupTab.payedOvertime:
      case PunchingRulesGroupTab.crossShift:
      default:
        return t("Payed Overtime");
    }
  };

  getStatus = (): StatusBadgeStatuses => {
    const { group } = this.state;

    return {
      [BusinessRuleGroupStatus.active]: StatusBadgeStatuses.active,
      [BusinessRuleGroupStatus.deactivated]: StatusBadgeStatuses.default,
    }[(group as BusinessRuleGroup).status];
  };

  render() {
    const { errors, title, activeTab, notification, group, notificationType } = this.state;
    const { t, history } = this.props;

    return (
      <SettingsLayout
        backButtonTitle={t("Business Rules")}
        backButtonOnclick={() => history.push("/company/groups-of-rules")}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            {title}
            {!!group && (
              <StatusBadge
                value={t(group.status)}
                type={this.getStatus()}
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginInlineStart: "25px",
                }}
              />
            )}
          </div>
        }
      >
        <PageWrapper>
          {notification ? (
            <NotificationRow
              style={{
                marginTop: "25px",
              }}
              aligned
              employeesPage
              withCloseButton
              onClose={() => this.setState({ notification: "" })}
              type={notificationType}
              message={notification}
            />
          ) : null}
          {errors && errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}

          <Tabs<PunchingRulesGroupTab>
            tabs={this.TABS}
            value={activeTab}
            onChange={(tab) =>
              this.setState({
                activeTab: tab,
                title: `${group?.name === "default" ? t("default") : group?.name || ""} - ${this.getTitle(tab)}`,
              })
            }
          />
          <br />
          {this.getContent(activeTab)}
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.companyRules)(PunchingRulesGroup);
