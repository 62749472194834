import moment from "moment";
import { LocationDropdownOption } from "components/LocationsDropdown";
import { PunchKey, PunchStatuses, PunchType, DailySummaryPunch, Punch } from "./punches";
import { DailySummaryRequest } from "./request";
import { DailySummarySchedule, DailySummaryScheduleException } from "./schedule";
import { SuperpunchShiftCompilation, SuperpunchShiftEvent } from "./shift";
import { UserProfile } from "./userProfile";
import { ReportBreakType } from "./reports/common";

type SuperpunchSelectedRangeDays = {
  [date: string]: {
    /** TODO */
    status: boolean;
    /** Is in progress */
    inProgress: boolean;
    /** Timestamp ms */
    lastUpdated: number;
  };
};

type SuperpunchPendingDay = {
  /** Timestamp (ms) when day started processing */
  start: number;
  /** Timestamp (ms) when day finished processing */
  end: number | null;
  /** Processing time in ms */
  diff: number;
};

type SuperpunchPendingDays = {
  [date: string]: SuperpunchPendingDay | null;
};

type DailySummaryEmployeeInfo = {
  active: boolean;
  avatarId: string | null;
  cpf: string;
  defaultTimeZone: string;
  department: string | null;
  fullName: string;
  id: number;
  matricula: string;
  pis: string | null;
  supervisorFullName: string | null;
  uuid: string;
};

// TODO is it the same as DailySummaryDateStatus ?
enum SuperpunchStatus {
  pending = "pending",
  approved = "approved",
  declined = "declined",
  deleted = "deleted",
}

enum DailySummaryDateStatus {
  completed = "completed",
  pending = "pending",
}

type SuperpunchDate = {
  dayMonth: string;
  dayOfWeek: string;
  isDayOff: boolean; // d.weekend || d.holiday,
  raw: moment.Moment;
};

type SuperpunchComment = {
  args: unknown; // todo
  name: string; // todo "day_without_schedule" enum | union?
};

type DailySummaryDate = {
  adjustments: unknown[]; // todo
  breakMinutes: number;
  comments: SuperpunchComment[];
  date: string;
  extraHoursMinutes: number;
  holiday: string;
  holidays: unknown[]; // todo
  hoursBankMinutes: number;
  hoursBankNegativeMinutes: number;
  hoursBankPositiveMinutes: number;
  isoWeekday: number;
  missedDay: boolean;
  missedMinutes: number;
  nightMinutes: number;
  plannedMinutes: number;
  punches: DailySummaryPunch[];
  requests: DailySummaryRequest[];
  schedule: DailySummarySchedule | null;
  scheduleException: DailySummaryScheduleException | null;
  shiftCompilations: SuperpunchShiftCompilation[];
  shiftEvents: SuperpunchShiftEvent[];
  status: DailySummaryDateStatus;
  actualDayBreaksByTypes: ReportBreakType[];
  actualNightBreaksByTypes: ReportBreakType[];
  plannedDayBreakByTypes: ReportBreakType[];
  plannedNightBreakByTypes: ReportBreakType[];
  /** legacy ? */
  weekend: boolean;
  shiftWorkingDay: boolean;
  workedMinutes: number;
  observation?: unknown; // todo
  totalExtraMinutes?: number;
  totalHoursBankMinutes?: number;
  debitMinutes: number;
};

type DailySummaryReport = {
  dates: DailySummaryDate[];
  employeeInfo: DailySummaryEmployeeInfo;
};

type SuperPunchStore = {
  reports: Record<string, DailySummaryReport>;
  employees: Record<string, UserProfile>;
  punches: Record<string, Punch[]>;
};

export type SuperpunchPunchCellRawData = DailySummaryPunch & {
  /**  added on FE */
  description: PunchType;
  /** overrrides on FE */
  status: PunchStatuses | "";
};

type SuperpunchPunchCellData = {
  display: string;
  raw?: SuperpunchPunchCellRawData | null;
};

type SuperpunchTableRowPunchKey = Partial<Record<PunchKey, SuperpunchPunchCellData>>;

type SuperpunchTableRowData = {
  date: SuperpunchDate;
  requests: DailySummaryRequest[];
  hasApprovedRequest: boolean;
  shiftCompilation: SuperpunchShiftCompilation;
  shiftEvents: SuperpunchShiftEvent[];
  schedule: DailySummarySchedule | null;
  lockedDay: boolean;
  workedMinutes: string; // e.g "00:42"
  breakMinutes: string; // e.g "00:42"
  nightMinutes: string; // e.g "00:42"
  totalExtraMinutes: string; // e.g "00:42"
  totalHoursBankMinutes: string; // e.g "00:42"
  hoursBankMinutes: string; // e.g "00:42"
  extraHoursMinutes: string; // e.g "00:42"
  missedMinutes: string; // e.g. "00:42"
  holiday: string;
  scheduleException: DailySummaryScheduleException | null;
  missedDay: boolean;
  observation: unknown; // todo
  comments: SuperpunchComment[];
  inProgress: boolean;
  isLostDsrDay: boolean;
  isPossibleDsrDay: boolean;
  showDsrInReports: boolean;
  plannedDayBreakByTypes: ReportBreakType[];
  plannedNightBreakByTypes: ReportBreakType[];
} & SuperpunchTableRowPunchKey;

type SuperpunchTableRows = {
  rows: SuperpunchTableRowData[];
  hasLockedDays: boolean;
  punchesCount: {
    entriesCount: number;
    breakStartsCount: number;
    breakEndsCount: number;
    exitsCount: number;
  };
};

export type {
  SuperpunchPunchCellData,
  SuperpunchTableRowData,
  SuperpunchTableRows,
  SuperpunchSelectedRangeDays,
  SuperpunchPendingDays,
  SuperPunchStore,
  DailySummaryEmployeeInfo,
  DailySummaryDate,
  DailySummaryReport,
  SuperpunchTableRowPunchKey,
  SuperpunchDate,
  SuperpunchComment,
};

export { DailySummaryDateStatus, SuperpunchStatus };
