import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomField } from "types/models/projects";
import CONFIG from "config";
import ClickOutside from "react-click-outside";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Location } from "types/models/location";
import { ClickOutsideType } from "types/common";
import { TimesheetContext } from "components/Timesheets/timesheets.context";
import { CancelBtn, CheckIcon, DetailsButton, DoneBtn } from "./TimerButtons";
import { useTrackTimeStore } from "../TrackTime.store";
import { findParentInTree, isRunningActivity } from "../time-tracker.utils";
import { ActivityLocationAndFiles } from "./TimeTrackerAttachments";
import { OverlayDropdown } from "./OverlayDropdown";
import { CustomFields } from "./CustomFields";
import { DatePickerPopup } from "./DatePickerPopup";
import { LocationSelect } from "./LocationSelect";

export const DetailsControl = ({ updateActiveActivity }: { updateActiveActivity: (act: any) => void }) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const [files, setFiles] = useState([]);
  const {
    activity,
    setActivity,
    setShowTaskDetails,
    showTaskDetails,
    locations,
    startTime,
    endTime,
    startDate,
    duration,
    initiateDateValues,
    closeAllModals,
    showTaskPicker,
  } = useTrackTimeStore();

  const timesheetContext = useContext(TimesheetContext);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [location, setLocation] = useState<Location | undefined>();

  const initLocation = () => {
    if (activity?.locationUuid && !activity?.location?.uuid) {
      setLocation(locations.find((l) => l.uuid === activity.locationUuid));
    } else {
      setLocation(activity?.location);
    }
  };
  const initFiles = () => {
    setFiles(activity?.attachments || []);
  };
  useEffect(() => {
    initLocation();
  }, [activity, locations]);

  useEffect(() => {
    initFiles();
  }, [activity]);

  const initiateValues = useCallback(() => {
    initiateDateValues();
    initLocation();
    initFiles();

    const options = (activity?.project?.customFieldsSettings || [])
      .filter((cfs) => !cfs.deletedAt)
      .map((cfs) => {
        const cf = activity?.customFields.find((f) => f.uuid === cfs.uuid);
        if (cf?.fieldType === "numeric" && cf?.value) cf.value = +cf.value;
        return { ...cfs, ...cf };
      });
    setCustomFields(options);
  }, [activity]);

  useEffect(() => {
    initiateValues();
  }, [activity]);

  const isRunning = useMemo(() => isRunningActivity(activity), [activity]);
  const onCancel = () => {
    initiateValues();
    closeAllModals();
  };

  const onSave = async () => {
    const getCustomFields = () =>
      customFields.reduce((res, cf) => {
        if (cf.value) res.push(cf);
        return res;
      }, []);

    if (isRunning) {
      await updateActiveActivity({
        ...activity,
        startTime: startTime!,
        customFields: getCustomFields(),
        locationUuid: location?.uuid,
        attachments: files,
      });
    } else {
      await setActivity({
        ...activity,
        date: startDate!.format(CONFIG.apiDateFormat),
        startTime: startTime!,
        endTime: endTime!,
        duration,
        status: "pending",
        customFields: getCustomFields(),
        location,
        locationUuid: location?.uuid,
        attachments: files,
      });
    }
  };

  return (
    <>
      <DetailsButton onClick={() => setShowTaskDetails(true)} />
      {showTaskDetails && !showTaskPicker && (
        <ClickOutside<ClickOutsideType>
          onClickOutside={(e) => {
            if (typeof e?.target?.className === "string" && e.target.className.includes("DurationSelect")) return;

            if (findParentInTree(e.target, ["Task"])) {
              void onSave();
            }
            if (findParentInTree(e.target, ["mbsc-popup"])) return;
            if (!findParentInTree(e.target, ["Task", "mbsc-popup"])) {
              void onSave();
              closeAllModals();
            }
          }}
        >
          <OverlayDropdown
            className="DatePicker ActivityDetails"
            content={
              <>
                <DatePickerPopup
                  store={useTrackTimeStore}
                  isRunning={isRunning}
                  inModal
                  allowFutureActivities={!timesheetContext?.settings?.allowFutureActivities}
                />
                <CustomFields customFields={customFields} setCustomFields={setCustomFields} />
                <ActivityLocationAndFiles
                  files={files}
                  setFiles={setFiles}
                  location={location}
                  store={useTrackTimeStore}
                  setShowLocationSelect={() => {
                    setShowLocationSelect(true);
                    setShowTaskDetails(false);
                  }}
                />
              </>
            }
            footer={
              <div className="Footer align-end">
                {startDate || startTime !== undefined || endTime !== undefined ? (
                  <CancelBtn onClick={onCancel} />
                ) : null}
                <DoneBtn
                  type="button"
                  disabled={!isRunning && (!startDate || startTime === undefined || endTime === undefined)}
                  className="DoneBtn"
                  onClick={async () => {
                    await onSave();
                    closeAllModals();
                  }}
                >
                  <div className="Icon">
                    <CheckIcon />
                  </div>
                  <div>{t(`${TranslationNamespaces.common}|Apply`)}</div>
                </DoneBtn>
              </div>
            }
          />
        </ClickOutside>
      )}
      {showLocationSelect && (
        <ClickOutside<ClickOutsideType>
          onClickOutside={() => {
            setShowLocationSelect(false);
            setShowTaskDetails(true);
          }}
        >
          <LocationSelect
            store={useTrackTimeStore}
            location={location}
            setLocation={setLocation}
            setShowLocationSelect={setShowLocationSelect}
          />
        </ClickOutside>
      )}
    </>
  );
};
