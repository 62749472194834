/* eslint camelcase: ["warn", { allow: ["business_rules", "company_rules"] }] */
import { Component, ContextType, SyntheticEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import { addPunch, fireDownloadPunches, getAllPunches, getCompanyRules } from "utils/apiHelpers";
import { RouteComponentProps } from "react-router-dom";
import {
  getDateWithTZ,
  getEmployeeTaxIdTranslation,
  getEmployeeTaxPayerType,
  getTitle,
  hasEmployeesAccess,
  isSupervisor,
} from "utils/common";
import BEM from "utils/BEM";
import FullPage from "components/Layout/FullPage";
import PaginationNew from "components/PaginationNew";
import NoContent from "components/NoContent";
import SearchControl from "components/UI/SearchControlNew";
import NotificationRow from "components/NotificationRow";
import User from "components/User";
import TablePage from "components/TablePage";
import { iColumn } from "components/TableCommon";
import MultiSelect from "components/UI/Select/MultiSelect";
import "components/UI/Page/Page.scss";
import "styles/punches-page.scss";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { getSelectedColumns } from "utils/tableHelpers";
import Sentry from "utils/sentryUtils";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import styled from "styled-components";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import GlobalContext from "context/global-context";
import CellCheckbox from "components/controls/CellCheckbox";
import { SelectOption } from "types/ui";
import { Notification, NotificationType, SearchObject, SearchObjectTypes } from "types/common";
import { AddPunchPunch, PucnhesTableData, Punch, PunchStatuses, PunchType } from "types/models/punches";
import { CompanyRule } from "types/models/companyRules";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import { AddPunchMappedEvent } from "types/models/shift";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionSectionName } from "types/models/permissions";
import { ButtonState } from "components/controls/StyledButton";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { translateEmployeeTerm } from "utils/translationHelpers";
import noPunches from "img/no-punches.png";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import { BreakStatusOptions } from "utils/api/types";
import { DEFAULT_BREAK_KEY, getBreakName, getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import { getCustomBreaksList } from "utils/api/schedule";
import Tooltip from "components/UI/Tooltip";
import PunchDetails from "./PunchDetails";
import PunchesAddPopup from "./PunchesAddPopup";
import * as images from "../svg-images";
import HintWithPhoto from "../UI/HintWithPhoto";
import ActionsBar from "./ActionsBar";
import CellCheckboxAll from "../controls/CellCheckboxAll";
import PunchSettingsModal from "./PunchSettingsModal";

const b = BEM.b("punches-page");
const p = BEM.b("page");
const tc = BEM.b("table-common");

const PunchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid #b4c4d1;
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

const Wrapper = styled.div`
  .punches-row {
    position: relative;
  }
`;

const isTabletAllowed = (): boolean => {
  const companyRules: CompanyRule[] = window.global_store.company_rules;

  return !!(
    companyRules?.length && companyRules.filter((rule: CompanyRule) => rule.name === "allow_tablet_punch").length
  );
};

interface PunchesPageProps extends RouteComponentProps, WithTranslation {
  startDate?: string | null;
  endDate?: string | null;
  id?: string | null;
  uuid?: string | null;
  label?: string | null;
}

interface PunchesPageState {
  startDate: moment.Moment;
  endDate: moment.Moment;
  notification: string;
  notificationType: NotificationType | null;
  popupAddPunchVisible: boolean;
  loading: boolean;
  initialFetchDone: boolean;
  selectAllChecked: boolean;
  searchObj?: SearchObject | Record<string, never>; // can be null or empty object
  fltrMode: SelectOption;
  fltrChar: string[];
  fltrStatus: PunchStatuses[];
  fltrVerificationMethod: string[];
  totalRecors: number;
  page: number;
  perPage: number;
  searchValue?: string;
  selectedColumns: string;
  selectedPunches: string[];
  punches: Punch[];
  selectedPunch?: Punch;
  directReportsOnly: boolean;
  popupPunchSettingsVisible: boolean;
  breakNamesMap: Record<string, string>;
}

const statusTagMapping = {
  [PunchStatuses.pending]: StatusTagType.pending,
  [PunchStatuses.approved]: StatusTagType.active,
  [PunchStatuses.declined]: StatusTagType.declined,
  [PunchStatuses.ignored]: StatusTagType.inactive,
  [PunchStatuses.invalid]: StatusTagType.default,
};

class PunchesPage extends Component<PunchesPageProps, PunchesPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  PUNCH_CHARS: SelectOption[];
  PUNCH_MODES: SelectOption[];
  STATUS_TYPES: SelectOption<PunchStatuses>[];
  VERIFICATION_METHODS: SelectOption[];

  constructor(props: PunchesPageProps) {
    super(props);
    const { t } = props;

    this.PUNCH_CHARS = [
      { value: "entry", label: t("entry") },
      { value: "break_start", label: t("break_start") },
      { value: "break_end", label: t("break_end") },
      { value: "exit", label: t("exit") },
    ];
    this.PUNCH_MODES = [
      { value: "", label: t("Type") },
      { value: "automatic", label: t("Automatic") },
      { value: "request", label: t("Request") },
    ];
    this.STATUS_TYPES = [
      { value: PunchStatuses.pending, label: t("Pending") },
      { value: PunchStatuses.approved, label: t("Approved") },
      { value: PunchStatuses.declined, label: t("Denied") },
      { value: PunchStatuses.ignored, label: t("Deleted") },
      { value: PunchStatuses.invalid, label: t("Invalid") },
    ];
    this.VERIFICATION_METHODS = [
      { value: "manual", label: t("punches-table|Manual") },
      { value: "wifi_point", label: t("punches-table|WiFi") },
      { value: "location", label: t("punches-table|Gps") },
      { value: "beacon", label: t("punches-table|iBeacon") },
      { value: "biometric", label: t("punches-table|Biometric") },
      { value: "ip_address", label: t("punches-table|IP") },
    ];

    this.state = {
      notification: "",
      notificationType: null,
      popupAddPunchVisible: false,
      startDate: this.props.startDate
        ? moment(this.props.startDate, "YYYY-MM-DD")
        : moment().clone().subtract(2, "days"),
      endDate: this.props.endDate ? moment(this.props.endDate, "YYYY-MM-DD") : moment().clone(),
      fltrMode: this.PUNCH_MODES[0],
      fltrChar: [],
      fltrStatus: [],
      fltrVerificationMethod: [],
      totalRecors: 0,
      page: 1,
      perPage: 25,
      loading: true,
      initialFetchDone: false,
      selectAllChecked: false,
      selectedColumns: getSelectedColumns("employee,punch_type,date,verified_by_type,reason,status", "PunchesPage"),
      selectedPunches: [],
      punches: [],
      selectedPunch: undefined,
      directReportsOnly: false,
      popupPunchSettingsVisible: false,
      breakNamesMap: {},
      ...this.getInitialSearch(),
    };

    document.title = getTitle(t("Punches"));
  }

  async componentDidMount(): Promise<void> {
    document.addEventListener("WebPunch", this.webPunchEventHandler.bind(this));
    const company = await this.context.getCompany();
    const [companyRulesResp, customBreaksResp] = await Promise.all([
      getCompanyRules(),
      getCustomBreaksList({
        perPage: 300,
        page: 1,
        statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
        companyUuid: company.uuid,
        requestedBy: window.global_store.profile.uuid,
      }),
    ]);

    const breakNamesMap = getBreaksNamesMap(customBreaksResp?.content || []);

    window.global_store.company_rules = companyRulesResp.business_rules;

    this.setState({
      breakNamesMap,
    });

    void this.getPunches();
  }

  getPunches = async (): Promise<void> => {
    const {
      page = 1,
      perPage,
      searchObj,
      fltrStatus,
      fltrChar,
      fltrVerificationMethod,
      startDate,
      endDate,
      directReportsOnly,
    } = this.state;
    const { t } = this.props;
    let employeeId;
    let teamUuid;
    let departmentUuid;
    let subsidiaryUuid;
    let groupUuid;

    if (searchObj?.type === SearchObjectTypes.department) {
      departmentUuid = searchObj.uuid;
    } else if (searchObj?.type === SearchObjectTypes.subsidiary) {
      subsidiaryUuid = searchObj.uuid;
    } else if (searchObj?.type === SearchObjectTypes.team) {
      teamUuid = searchObj.uuid;
    } else if (directReportsOnly) {
      teamUuid = window.global_store.profile.teams[0]?.uuid;
    } else {
      employeeId = searchObj?.id;
    }

    if (!this.state.initialFetchDone || !this.state.loading) {
      this.setState({ loading: true, initialFetchDone: true, selectedPunches: [], selectAllChecked: false });

      try {
        const response = await getAllPunches({
          page,
          per_page: perPage,
          employeeId: employeeId ? String(employeeId) : undefined,
          departmentUuid,
          subsidiaryUuid,
          teamUuid,
          from: startDate.clone().format("YYYY-MM-DD"),
          to: endDate.clone().format("YYYY-MM-DD"),
          punch_type: fltrChar.join(","),
          verificationMethod: fltrVerificationMethod.join(","),
          status: fltrStatus.join(","),
          groupUuid,
          newHierarchyPermissions: true,
        });

        const company = await this.context.getCompany();
        const { content } = await listUserProfilesWIthFilters(company.uuid, {
          ...baseByUuidPayload(
            window.global_store.profile.uuid,
            response.punches.map((p) => p.employee.uuid),
          ),
          fields: ["id", "uuid", "taxPayerId", "matricula", "pis"],
        });

        response.punches.forEach((p) => {
          const e = content.find((e) => e.uuid === p.employee.uuid);

          p.employee.pis = e?.pis || "";
          p.employee.cpf = e?.taxPayerId || "";
          p.employee.matricula = e?.matricula || "";
        });

        const nextPage = response?.meta?.next_page || page;
        const responseTotalRecords = nextPage ? nextPage * perPage : response.punches?.length || 0;

        this.setState({
          punches: response.punches,
          loading: false,
          page,
          totalRecors: responseTotalRecords,
        });
      } catch (error) {
        this.setState({
          punches: [],
          loading: false,
          notificationType: NotificationType.error,
          notification: t("common|Something went wrong"),
          page,
          totalRecors: 0,
        });
      }
    }
  };

  getInitialSearch = (): { searchValue: string; searchObj: SearchObject } => {
    let searchObj: SearchObject = {};

    if (this.props.uuid && this.props.id) {
      searchObj = {
        employee: {
          uuid: this.props.uuid,
          id: this.props.id,
          label: this.props.label || "",
        },
        uuid: this.props.uuid,
        id: this.props.id ? Number(this.props.id) : undefined,
      };
    }

    return {
      searchValue: this.props.label || "",
      searchObj,
    };
  };

  onRowCheck = (isChecked: boolean, punchUuid: string): void => {
    let { selectedPunches } = this.state;
    const { selectAllChecked } = this.state;

    if (isChecked) {
      selectedPunches.push(punchUuid);
    } else {
      selectedPunches = selectedPunches.filter((uuid) => uuid !== punchUuid);
    }

    this.setState({ selectedPunches, selectAllChecked: !selectedPunches.length ? false : selectAllChecked });
  };

  onSelectAllCheck = (isChecked: boolean, selectedPunches: string[]): void => {
    this.setState({ selectAllChecked: isChecked, selectedPunches });
  };

  isRowSelectable = (punch: Punch): boolean => {
    const isLocked = punch.is_locked;
    const isPending = punch.status === PunchStatuses.pending;
    const isApproved = punch.status === PunchStatuses.approved;
    const isInvalid = punch.is_valid === false;
    const isOwnPunch = punch.employee.uuid === window.global_store.profile.uuid;

    const hasPermission = !!punch.has_permission_to_manage;

    return (isPending || (isApproved && isInvalid)) && !isLocked && hasPermission && !isOwnPunch;
  };

  getColumns = (punches: Punch[]): iColumn<PucnhesTableData>[] => {
    const { t } = this.props;
    const { selectedPunches, selectAllChecked } = this.state;
    const showSelectAll = punches && punches.length;
    const showCheckbox =
      window.global_store.profile.permission_roles &&
      window.global_store.profile.permission_roles.some((r: { name: string }) =>
        ["admin", "hr", "owner", "supervisor"].includes(r.name.toLowerCase()),
      );

    let columns: iColumn<PucnhesTableData>[] = [];
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    if (showCheckbox) {
      columns.push({
        accessor: "punch",
        headerClassName: "checkbox-header",
        Cell: (row) => (
          <CellCheckbox
            row={row}
            checked={selectedPunches.indexOf(row.value.uuid) > -1}
            onChange={this.onRowCheck}
            isCheckboxDisabled={!this.isRowSelectable(row.original.punch)}
            isRowLocked={row.original.punch.is_locked}
          />
        ),
        Header: showSelectAll ? (
          <CellCheckboxAll
            entities={punches}
            checked={selectAllChecked}
            onChange={this.onSelectAllCheck}
            isEntitySelectable={this.isRowSelectable}
          />
        ) : undefined,
        minWidth: 50,
        style: { fontWeight: "500" },
        align: "center",
        locked: true,
      });
    }

    columns = columns.concat([
      {
        accessor: "employee",
        rubyAccessor: "employee",
        locked: true,
        label: t(`${TranslationNamespaces.punchesTable}|Employee`),
        Cell: (row): JSX.Element => (
          <User
            user={{
              fullName: row.value.name,
              avatarId: row.value.avatar_id,
              position: row.value.job_description,
            }}
          />
        ),
        minWidth: 270,
      },
      { accessor: "cpf", rubyAccessor: "cpf", label: employeeTaxIdLabel },
      { accessor: "pis", rubyAccessor: "pis", label: "PIS" },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(`${TranslationNamespaces.punchesTable}|Matricula`),
      },
      {
        accessor: "position",
        rubyAccessor: "position",
        label: t(`${TranslationNamespaces.punchesTable}|Position`),
      },
      {
        accessor: "when",
        rubyAccessor: "date",
        label: t(`${TranslationNamespaces.punchesTable}|When`),
        locked: true,
        Cell: (row): string =>
          row.value && getDateWithTZ(row.value.time, row.value.timezone).format("HH:mm ddd DD/MM/YYYY"),
        width: 180,
      },
      {
        accessor: "locationName",
        rubyAccessor: "location_name",
        label: t(`${TranslationNamespaces.punchesTable}|Location`),
      },
      {
        accessor: "type",
        rubyAccessor: "punch_type",
        label: t(`${TranslationNamespaces.punchesTable}|Type`),
        locked: true,
        Cell: (row) => (
          <div
            data-tip
            data-for={`${row.original.id}_${row.original.type}`}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {row.value}
            <Tooltip id={`${row.original.id}_${row.original.type}`}>{row.value}</Tooltip>
          </div>
        ),
      },
      {
        accessor: "validation",
        rubyAccessor: "verified_by_type",
        label: t(`${TranslationNamespaces.punchesTable}|Validation`),
        Cell: (row): string => t(row.value),
        width: 160,
      },
      {
        accessor: "source",
        Cell: (row): string => (row.value ? t(row.value) : ""),
        rubyAccessor: "source",
        label: t(`${TranslationNamespaces.punchesTable}|Device`),
      },
      {
        accessor: "reviewedBy",
        rubyAccessor: "approved_by",
        label: t(`${TranslationNamespaces.punchesTable}|Reviewed By`),
      },
      {
        accessor: "reason",
        rubyAccessor: "reason",
        label: t(`${TranslationNamespaces.punchesTable}|Reason`),
        minWidth: 170,
        Cell: (row): string => {
          if (row.original.isValid === false) {
            return t(`${TranslationNamespaces.punchesPage}|Outside Schedule Rules`);
          }
          if (row.value) {
            return t(`${TranslationNamespaces.punchesPage}|${row.value}`);
          }
          return "";
        },
      },
    ]);

    if (isTabletAllowed()) {
      columns.push({
        accessor: "photo",
        rubyAccessor: "photo",
        label: t("Photo"),
        Header: (
          <div className={`${tc("icon")}`}>
            {images.punchesTabletPhoto({})}
            <span className={tc("tooltip")}>{t("Holiday")}</span>
          </div>
        ),
        minWidth: 90,
        Cell: (row) => (row.value ? <HintWithPhoto imgId={row.value} /> : ""),
        style: { overflow: "visible", fontWeight: "500" },
        align: "center",
      });
    }

    columns.push({
      accessor: "status",
      rubyAccessor: "status",
      label: t("punches-table|Status"),
      locked: true,
      Cell: (row) => (
        <StatusTag
          value={t(`punches-table|status-${row.value}`)}
          type={row.value ? statusTagMapping[row.value as PunchStatuses] : StatusTagType.default}
        />
      ),
      minWidth: 120,
      align: "center",
    });

    return columns;
  };

  webPunchEventHandler = (): void => {
    const { t } = this.props;

    this.setState(
      {
        notification: `${t("punches-page|Your punch was created")}`,
        page: 1,
        notificationType: NotificationType.success,
      },
      this.getPunches,
    );
  };

  onSearchValue = (value: SearchObject): void => {
    this.setState({ searchValue: value.label, searchObj: value, page: 1, directReportsOnly: false }, this.getPunches);
  };

  onFilterCharChange = (value: string[]): void => {
    this.setState({ fltrChar: value, page: 1 }, this.getPunches);
  };

  onFilterStatusChange = (value: PunchStatuses[]): void => {
    this.setState({ fltrStatus: value, page: 1 }, this.getPunches);
  };

  onFilterVerificationMethodChange = (value: string[]): void => {
    this.setState({ fltrVerificationMethod: value, page: 1 }, this.getPunches);
  };

  onSearchDatesChange = (startDate: moment.Moment | null, endDate: moment.Moment | null): void => {
    if (startDate && endDate) {
      this.setState({ startDate, endDate, page: 1 }, this.getPunches);
    }
  };

  openPunchDetails(punch?: Punch): void {
    this.setState({ selectedPunch: punch });
  }

  getTableData = (punches: Punch[]): PucnhesTableData[] => {
    const { t } = this.props;
    const { breakNamesMap } = this.state;

    const data: PucnhesTableData[] = punches.map((p) => ({
      id: p.id,
      employee: p.employee,
      photo: p.attachments?.length && (p.attachments[0].attachment_id || ""),
      type:
        p.punch_type === PunchType.breakStart || p.punch_type === PunchType.breakEnd
          ? getBreakName(p.break_type_uuid, p.punch_type, breakNamesMap)
          : t(p.punch_type),
      when: { time: p.device_datetime, timezone: p.time_zone },
      locationName: p.location?.name || "",
      validation: p.verified_by_type,
      status: p.is_valid === false ? "invalid" : p.status,
      reason: p.reason,
      comment: p.comment,
      position: p.employee?.job_description || "",
      reviewedBy: p.manager?.name || "",
      cpf: p.employee?.cpf,
      pis: p.employee?.pis,
      matricula: p.employee?.matricula,
      source: p.source,
      options: p.id,
      isValid: p.is_valid,
      isManual: p.is_manual,
      punch: p,
    }));

    return data;
  };

  /* eslint camelcase: ["warn", { "allow":
    ["employee_uuids", shift_compilation, shift_id]
  }] */
  onAddPunch = async (
    punch: AddPunchPunch,
    fullName = "",
    event: AddPunchMappedEvent["event"] | undefined,
  ): Promise<void> => {
    const { t } = this.props;

    try {
      await addPunch({
        body: {
          ...punch,
          shift_compilation: event
            ? {
                shift_id: event.shiftId,
                date: event.date,
                key: event.key,
              }
            : null,
        },
      });

      this.setState(
        {
          notificationType: NotificationType.success,
          notification: `${t("You added punch for")} ${
            punch?.punch?.employee_uuids?.length ? `${punch.punch.employee_uuids.length} ${t("employees")}` : fullName
          }`,
          popupAddPunchVisible: false,
        },
        this.getPunches,
      );
    } catch (error) {
      this.setState({
        notificationType: NotificationType.error,
        notification: t("Adding punch failed"),
      });
      Sentry.sendError(error);
    }
  };

  hidePopup(notification: string): void {
    this.setState(
      { notification, selectedPunch: undefined, notificationType: NotificationType.success },
      this.getPunches,
    );
  }

  onApprovePunchPopup = (): void => {
    const { t } = this.props;
    this.hidePopup(t("You approved this punch"));
  };

  onDeclinePunchPopup = (): void => {
    const { t } = this.props;
    this.hidePopup(t("You declined this punch"));
  };

  onValidatePunchPopup = (): void => {
    const { t } = this.props;
    this.hidePopup(t("You validated this punch"));
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_PunchesPage", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  getHeaderAction = () => {
    const { t } = this.props;

    const punchSettingsEnabled = window.global_store.profile?.permissions?.some(
      (r: string) => r === PermissionSectionName.punchSettings,
    );

    return (
      <div className={p("header-action")}>
        {punchSettingsEnabled && (
          <PunchSettingsButton onClick={() => this.setState({ popupPunchSettingsVisible: true })}>
            {images.gearIcon}
          </PunchSettingsButton>
        )}

        <HeaderActionButtonAdd
          state={ButtonState.primary}
          title={t("Add Punches")}
          onClick={(): void => this.setState({ popupAddPunchVisible: true })}
        />
      </div>
    );
  };

  render(): JSX.Element {
    const {
      searchValue,
      punches,
      startDate,
      endDate,
      loading,
      popupAddPunchVisible,
      selectedPunch,
      selectedColumns,
      searchObj,
      notificationType,
      perPage,
      totalRecors,
      fltrStatus,
      page,
      selectedPunches,
      selectAllChecked,
      directReportsOnly,
      popupPunchSettingsVisible,
    } = this.state;
    const { t } = this.props;

    const columns = this.getColumns(punches || []);

    return (
      <FullPage headerAction={this.getHeaderAction()}>
        <Wrapper className={p()}>
          {this.state.notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType}
              onClose={(): void => this.setState({ notification: "" })}
              message={this.state.notification}
            />
          )}

          <TablePage<PucnhesTableData>
            selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
            onColumnsChange={this.onColumnsChange}
            filters={
              <>
                {hasEmployeesAccess() && (
                  <div className={p("search", { "punches-supervisor": isSupervisor() })}>
                    <SearchControl
                      permissionSection={PermissionSectionName.viewPunches}
                      value={searchValue}
                      searchGroups
                      onClear={(): void => {
                        this.setState(
                          {
                            searchValue: "",
                            searchObj: {},
                          },
                          this.getPunches,
                        );
                      }}
                      onChange={this.onSearchValue}
                      placeholder={translateEmployeeTerm(
                        t,
                        TranslationNamespaces.common,
                        "custom-search-employees",
                        `${TranslationNamespaces.common}|Search Employees`,
                      )}
                    />
                  </div>
                )}
                {isSupervisor() && (
                  <AdditionalFiltersControl
                    options={[
                      {
                        label: t("Direct reports only"),
                        checked: directReportsOnly,
                        onChange: (val) =>
                          this.setState({ directReportsOnly: val, ...this.getInitialSearch() }, this.getPunches),
                        // hide: !isSupervisor(),
                      },
                    ]}
                  />
                )}
                <DateRangePicker
                  newOnChangeApproach
                  isAdmin={hasEmployeesAccess()}
                  onChange={this.onSearchDatesChange}
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className={b("characteristic")}>
                  <MultiSelect<string>
                    value={this.state.fltrChar}
                    onChange={this.onFilterCharChange}
                    options={this.PUNCH_CHARS}
                    placeholder={t("Punch")}
                  />
                </div>

                {hasEmployeesAccess() && (
                  <div className={b("verification-method")}>
                    <MultiSelect<string>
                      value={this.state.fltrVerificationMethod}
                      onChange={this.onFilterVerificationMethodChange}
                      options={this.VERIFICATION_METHODS}
                      placeholder={t("punches-table|Verified")}
                    />
                  </div>
                )}

                <div className={b("characteristic")}>
                  <MultiSelect<PunchStatuses>
                    value={fltrStatus}
                    onChange={this.onFilterStatusChange}
                    options={this.STATUS_TYPES}
                    placeholder={t("Status")}
                  />
                </div>
              </>
            }
            columnSelectorOnFiltersRow
            rows={this.getTableData(punches)}
            columns={columns}
            page={page}
            showPagination
            pages={totalRecors}
            loading={loading}
            manual
            showPageSizeOptions={false}
            PaginationComponent={(): JSX.Element => (
              <PaginationNew
                unlimited
                totalRecords={totalRecors}
                pageLimit={perPage}
                currentPage={page}
                onPageLimitChange={(pageLimit, currentPage) =>
                  this.setState({ perPage: pageLimit, page: currentPage }, this.getPunches)
                }
                onPageChange={(currentPage) => {
                  this.setState({ page: currentPage }, this.getPunches);
                }}
              />
            )}
            getTrProps={(_, rowInfo): Record<string, unknown> => {
              const props = loading
                ? {}
                : {
                    className: "punches-row",
                    onClick: (e: SyntheticEvent): void => {
                      const target = e.target as HTMLElement;
                      if (!target || target.tagName !== "INPUT") {
                        this.openPunchDetails(rowInfo?.original?.punch);
                      }
                    },
                  };

              return props;
            }}
            downloadControl={
              <DownloadControlWithEvents
                placeholder={t(`${TranslationNamespaces.common}|Download`)}
                options={[{ label: "XLSX", value: "xlsx" }]}
                onChange={(): Promise<any> => {
                  const queryObj: {
                    fltrMode: string;
                    fltrStatus: string[];
                    fltrChar: string[];
                    fltrVerificationMethod: string[];
                    departmentUuid?: string;
                    subsidiaryUuid?: string;
                    teamUuid?: string;
                    employeeId?: string;
                  } = {
                    fltrMode: this.state.fltrMode.value,
                    fltrStatus: this.state.fltrStatus,
                    fltrChar: this.state.fltrChar,
                    fltrVerificationMethod: this.state.fltrVerificationMethod,
                  };

                  if (searchObj?.type === SearchObjectTypes.department) {
                    queryObj.departmentUuid = searchObj.uuid;
                  } else if (searchObj?.type === SearchObjectTypes.subsidiary) {
                    queryObj.subsidiaryUuid = searchObj.uuid;
                  } else if (searchObj?.type === SearchObjectTypes.team) {
                    queryObj.teamUuid = searchObj.uuid;
                  } else {
                    queryObj.employeeId = searchObj?.id;
                  }

                  return fireDownloadPunches({
                    from: startDate.format("YYYY-MM-DD"),
                    to: endDate.format("YYYY-MM-DD"),
                    employeeId: queryObj.employeeId,
                    departmentUuid: queryObj.departmentUuid,
                    subsidiaryUuid: queryObj.subsidiaryUuid,
                    teamUuid: queryObj.teamUuid,
                    selectedColumns: selectedColumns.replace(/photo,|photo/, ""),
                    punch_type: queryObj.fltrChar.join(","),
                    verificationMethod: queryObj.fltrVerificationMethod.join(","),
                    status: queryObj.fltrStatus.join(","),
                  });
                }}
              />
            }
            noContentComponent={<NoContent img={noPunches}>{t("No Punches at this time")}</NoContent>}
          />

          {!!selectedPunches.length && (
            <ActionsBar
              selectedPunches={selectedPunches}
              onUncheckAll={() => this.onSelectAllCheck(false, [])}
              onDone={(resp) => {
                this.setState(
                  {
                    notification: resp.notification,
                    notificationType: resp.notificationType,
                    selectedPunches: resp.notificationType !== NotificationType.success ? selectedPunches : [],
                    selectAllChecked: resp.notificationType !== NotificationType.success ? selectAllChecked : false,
                  },
                  this.getPunches,
                );
              }}
            />
          )}
          <SidePopupOverlay
            width={400}
            header={t("Add a Punch")}
            isOpen={popupAddPunchVisible}
            onClose={(): void => this.setState({ popupAddPunchVisible: false })}
          >
            <PunchesAddPopup
              prefillProfile={searchObj?.employee && !searchObj.type ? searchObj.employee : null}
              onYes={this.onAddPunch}
              onClose={(): void => this.setState({ popupAddPunchVisible: false })}
            />
          </SidePopupOverlay>
          <SidePopupOverlay
            contentOverflow
            header={t("Punch Details")}
            isOpen={!!selectedPunch}
            headerStyle={selectedPunch ? selectedPunch.status : "default"}
            onClose={(): void => this.setState({ selectedPunch: undefined })}
          >
            <PunchDetails
              punchId={selectedPunch ? selectedPunch.id : null}
              employeeUuid={selectedPunch ? selectedPunch.employee.uuid : null}
              onApprovePunch={this.onApprovePunchPopup}
              onValidatePunch={this.onValidatePunchPopup}
              onDeclinePunch={this.onDeclinePunchPopup}
            />
          </SidePopupOverlay>
        </Wrapper>

        {popupPunchSettingsVisible && (
          <PunchSettingsModal
            onClose={() => this.setState({ popupPunchSettingsVisible: false })}
            setNotification={(n: Notification) =>
              this.setState({ notification: n.notification as string, notificationType: n.notificationType })
            }
          />
        )}
      </FullPage>
    );
  }
}

export default withTranslation([
  TranslationNamespaces.employeesPage,
  TranslationNamespaces.punchesTable,
  TranslationNamespaces.punchesPage,
])(PunchesPage);
