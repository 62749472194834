import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import BEM from "utils/BEM";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Department } from "types/models/department";
import TextInputControl, { TextInputControlRef } from "../controls/TextInputControl";
import "./styles/popup-message.scss";

const b = BEM.b("departments-popup-message");

interface DepartmentsPopupMessageProps extends WithTranslation {
  title: string;
  /** Department to edit */
  item: Department | Record<string, never>;
  allItems: Department[];
  buttonYesTitle: string;
  onClose: () => void;
  onYes: (data: { id: number; name: string; code: string }) => void;
}

interface DepartmentsPopupMessageState {
  title: string;
  name: { value: string; error: string };
  code: { value: string; error: string };
  buttonCancelTitle: string;
  buttonYesTitle: string;
}

class DepartmentsPopupMessage extends Component<DepartmentsPopupMessageProps, DepartmentsPopupMessageState> {
  code: TextInputControlRef | null = null;
  name: TextInputControlRef | null = null;

  constructor(props: DepartmentsPopupMessageProps) {
    super(props);
    const { t, title, item, buttonYesTitle } = props;

    this.state = {
      title,
      name: { value: item.name || "", error: "" },
      code: { value: item.code || "", error: "" },
      buttonCancelTitle: t("Cancel"),
      buttonYesTitle: buttonYesTitle || t(`${TranslationNamespaces.common}|Confirm`),
    };
  }

  closePopup = () => {
    this.props.onClose();
  };

  onYes = () => {
    if (this.code && this.name) {
      const { allItems = [], item, onYes } = this.props;

      const name = { value: this.name.getValue(), error: "" };
      const code = { value: this.code.getValue(), error: "" };

      if (!name.value) {
        name.error = this.props.t("Department name can't be empty");
        this.setState({ name, code });
        // if department with this name already exist and (it is create mode or edit mode and name has been changed)
      } else if (allItems.some((d) => d.name === name.value) && (!item.name || item.name !== name.value)) {
        name.error = this.props.t("Department with this name already exists");
        this.setState({ name, code });
      }

      if (!code.value) {
        code.error = this.props.t("Department code can't be empty");
        this.setState({ name, code });
        // if department with this code already exist and (it is create mode or edit mode and code has been changed)
      } else if (allItems.some((d) => d.code === code.value) && (!item.code || item.code !== code.value)) {
        code.error = this.props.t("Department with this code already exists");
        this.setState({ name, code });
      } else {
        onYes({
          id: item.id,
          name: name.value,
          code: code.value,
        });
      }
    }
  };

  render() {
    const { title, name, code, buttonYesTitle, buttonCancelTitle } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>

        <div className={b("label")}>{t("Code")}</div>
        <TextInputControl
          type="text"
          placeholder="01"
          value={code.value}
          error={!!code.error}
          onRef={(ref) => {
            this.code = ref;
          }}
          size={60}
        />
        {code.error && <div className={b("error")}>{code.error}</div>}

        <div className={b("label")}>{t("Name")}</div>
        <TextInputControl
          size={100}
          value={name.value}
          error={!!name.error}
          onRef={(ref) => {
            this.name = ref;
          }}
        />
        {name.error && <div className={b("error")}>{name.error}</div>}

        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.closePopup}>
            {buttonCancelTitle}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes}>
            {buttonYesTitle}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.departments)(DepartmentsPopupMessage);
