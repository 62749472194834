import React, { Component } from "react";
import styled from "styled-components";
import TextInput from "components/controls/TextInputControlNew";
import { withTranslation } from "react-i18next";
import { resetPassword } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";

const EmailSentDescription = styled.div`
  margin-bottom: 64px;
  font-size: 16px;
  line-height: 20px;
  color: #a1b2cf;
`;
class ForgotPasswordPage extends Component {
  state = { email: "" };

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Recover Password");
  }

  onNextClick = (ev) => {
    const { email } = this.state;
    ev.preventDefault();
    let errors = {};
    if (this.validateEmail()) {
      errors.email = this.validateEmail();
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
      this.submitForm(email);
    } else {
      this.setState({ errors });
    }
  };

  validateEmail = () => {
    const { email } = this.state;
    const { t } = this.props;
    let error = "";

    const validate = (str) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };
    if (!email) {
      error = t("Email Can't be Empty");
    } else if (!validate(email)) {
      error = t("Email is incorrect");
    } else {
      error = "";
    }
    return error;
  };

  submitForm = async (email) => {
    const { t } = this.props;

    this.setState({ loading: true, errors: null });
    try {
      await resetPassword(email);

      this.setState({ loading: false, emailSent: true, responseError: "" });
    } catch (error) {
      const message = error.response?.data?.message || error.message || error;

      this.setState({
        errors: { email: t(message.includes("404") ? "Wrong email" : message) },
        loading: false,
        emailSent: false,
      });
    }
  };

  onResetDone = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    const { email, errors, loading, emailSent } = this.state;
    const { t, history } = this.props;

    return (
      <MainPage
        topRowText={t("Don't have an account?")}
        topRowButtonText={t("GET STARTED")}
        topRowButtonClick={() => history.push("/signup")}
        buttonText={emailSent ? t("Login") : t("Send reset instructions")}
        pageTitle={emailSent ? t("We have sent an email to your account") : t("Recover password")}
        loading={loading}
        onSubmit={emailSent ? this.onResetDone : () => {}}
        formParams={
          !emailSent
            ? {
                onSubmit: this.onNextClick,
                noValidate: "novalidate",
                method: "post",
              }
            : null
        }
      >
        {emailSent ? (
          <EmailSentDescription
            dangerouslySetInnerHTML={{
              __html: t("Instructions on how to recover your password have been sent to {{email}}", {
                email,
              }),
            }}
          />
        ) : (
          <OnboardingFieldWrapper fieldName={t("E-MAIL ADDRESS")}>
            <TextInput
              error={!!errors?.email}
              name="email"
              onboarding
              value={email}
              type="email"
              onChange={(ev) => this.setState({ email: ev.target.value })}
            />
            {!!errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}
          </OnboardingFieldWrapper>
        )}
      </MainPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(ForgotPasswordPage);
