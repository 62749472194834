import { RouteComponentProps } from "react-router-dom";
import SuperpunchProvider from "context/SuperpunchProvider";
import { useEffect } from "react";
import ga, { GaSpPageType } from "utils/ga";
import Superpunch from "./Superpunch";

interface SuperpunchPageProps extends RouteComponentProps {
  uuid: string;
  id: string;
  label: string | null;
  startDate: string | null;
  endDate: string | null;
  showOnlyIssues: string | null; // 'true' is true, everything else if false
  directReportsOnly: string | null; // 'true' is true, everything else if false
}

const SuperpunchPage = (props: SuperpunchPageProps) => {
  useEffect(() => {
    const pageLocation = encodeURI(window.location.origin + props.location.pathname + props.location.search);

    ga.trackSuperPunchNavigation(pageLocation, GaSpPageType.individual);
  }, []);

  return (
    <SuperpunchProvider {...props}>
      <Superpunch
        history={props.history}
        location={props.location}
        match={props.match}
        staticContext={props.staticContext}
      />
    </SuperpunchProvider>
  );
};

export default SuperpunchPage;
