import { Component, ReactNode, Key } from "react";
import ClickOutside from "react-click-outside";
import BEM from "utils/BEM";
import styled from "styled-components";
import Option from "./SelectOption";
import "./Select.scss";

const b = BEM.b("ui-select");

const Wrapper = styled.div`
  .ui-select__input::after {
    inset-inline-end: 16px;
  }
  .ui-select__input {
    padding: 0;
    padding-inline-start: 16px;
    padding-inline-end: 28px;
  }
`;

const SelectedLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export type MultiSelectOprion<V> = {
  value: V;
  label: string;
  disabled?: boolean;
};

interface MultiSelectProps<V> {
  onChange: (value: V[]) => void;
  value: V[];
  options: MultiSelectOprion<V>[];
  placeholder: string;
  icon?: ReactNode;
  lightUI?: boolean;
}

interface MultiSelectState {
  isOpen: boolean;
  alignDropdownRight: boolean;
}

/**
 * @deprecated Use NewMultiSelectComponent from UI/NewMultiSelectComponent
 */
class MultiSelect<V = string> extends Component<MultiSelectProps<V>, MultiSelectState> {
  ref: HTMLDivElement | null = null;

  static defaultProps = {
    value: [],
  };

  readonly state: MultiSelectState = {
    isOpen: false,
    alignDropdownRight: false,
  };

  toggleOpen = (open?: boolean): void => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;
    let alignDropdownRight = false;

    if (isOpen && this.ref) {
      alignDropdownRight = this.ref.getBoundingClientRect().right + 50 > window.innerWidth;
    }

    this.setState({ isOpen, alignDropdownRight });
  };

  selectValue = (optionValue: V): void => {
    let newValue: V[];
    const { onChange, value } = this.props;

    if (value.indexOf(optionValue) > -1) {
      newValue = value.filter((i) => i !== optionValue);
    } else {
      newValue = [...value, optionValue];
    }

    // keep order
    newValue = this.props.options
      .map((op) => (newValue.indexOf(op.value) > -1 ? op.value : null))
      .filter((op) => op) as V[];

    if (onChange) {
      onChange(newValue);
    }
  };

  isActive = (optionValue: V): boolean => {
    const { value } = this.props;

    return value && value.indexOf(optionValue) > -1;
  };

  renderSelectedLabel = () => {
    const { options } = this.props;
    const selectedOptions = options.filter((option) => this.isActive(option.value));

    const label = selectedOptions.map((option) => option.label || option.value).join(", ");
    return label ? <SelectedLabel>{label}</SelectedLabel> : null;
  };

  render(): JSX.Element {
    const { value, placeholder, options, icon, lightUI } = this.props;
    const { alignDropdownRight, isOpen } = this.state;

    return (
      <Wrapper
        className={b({ icon: !!icon })}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        <ClickOutside onClickOutside={() => this.toggleOpen(false)}>
          <div
            className={b("input", {
              icon: !!icon,
              open: isOpen,
              filled: !icon && !lightUI && !!value?.length,
            })}
            onClick={() => this.toggleOpen()}
          >
            {icon || this.renderSelectedLabel() || placeholder}
          </div>
          {isOpen && (
            <div className={b("option-list", { icon: !!icon, right: alignDropdownRight })}>
              {options.map((option) => (
                <Option
                  key={option.value as unknown as Key}
                  onSelect={() => this.selectValue(option.value)}
                  label={option.label}
                  checkbox
                  disabled={!!option.disabled}
                  active={this.isActive(option.value)}
                />
              ))}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default MultiSelect;
