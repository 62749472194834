import { Component } from "react";
import TextInputControl from "components/controls/TextInputControl";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Button, { ButtonState } from "components/controls/StyledButton";
import ClientsDropdown from "components/ClientsDropdown";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import getSymbolFromCurrency from "currency-symbol-map";
import CheckboxControl from "components/UI/NewCheckbox";
import ga, { GaBoolean } from "utils/ga";
import { createProject } from "./projectsApiUtils";

const Title = styled.div`
  font-size: 25px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  button {
    max-width: 155px;
  }
`;

const IdNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 16px;
`;

const RatesWrapper = styled.div<{ currencySign?: string }>`
  display: flex;
  align-items: center;
  & > div:first-child {
    position: relative;
    input {
      padding-inline-end: 20px;
    }

    &:after {
      content: ${(p) => `'${p.currencySign}'`};
      position: absolute;
      inset-inline-end: 8px;
      bottom: 1px;
      font-size: 15px;
      line-height: 34px;
      color: var(--colors-surface-700);
    }
  }
`;

interface ProjectsPopupMessageProps extends WithTranslation {
  onClose: () => void;
  onYes: (project?: any) => void;
}

interface ProjectsPopupMessageState {
  id: string;
  name: string;
  estimatedHours: number;
  estimatedCost: number;
  clientUuid: string | null;
  loading: boolean;
  errors: Record<string, string> | null;
  isPublic: boolean;
}

class ProjectsPopupMessage extends Component<ProjectsPopupMessageProps, ProjectsPopupMessageState> {
  static contextType = GlobalContext;
  state = {
    id: "",
    name: "",
    estimatedHours: 0,
    estimatedCost: 0,
    clientUuid: null,
    loading: false,
    errors: null,
    isPublic: true,
  };

  closePopup() {
    this.props.onClose();
  }

  onCancel = () => {
    this.closePopup();
  };

  saveProject = async () => {
    const { id, name, clientUuid, estimatedHours, estimatedCost, isPublic } = this.state;
    this.setState({ loading: true });
    const requestDetails = {
      companyUuid: window.global_store.company.uuid,
      body: {
        content: {
          name,
          id: id || "0000",
          clientUuid,
          estimatedHours: estimatedHours || 0,
          estimatedCost: estimatedCost || 0,
          restrictBasedOnSchedule: false,
          ownerUuid: window.global_store.profile.uuid,
          createdBy: window.global_store.profile.uuid,
          locationUuids: [],
          isPublic,
        },
      },
    };

    const res = await createProject(requestDetails);

    const currency = window.global_store.company ? getSymbolFromCurrency(window.global_store.company.currency) : "$";
    ga.trackCreateNewProject({
      estimated_cost: estimatedCost + (currency || "$"),
      estimated_hours: estimatedHours,
      project_id: id || "0000",
      is_public: isPublic ? GaBoolean.true : GaBoolean.false,
      project_name: name,
    });

    this.props.onYes(res?.content);
  };

  onYes = () => {
    const errors = this.validate();

    if (!errors) {
      void this.saveProject();
    } else {
      this.setState({ errors });
    }
  };

  validate = (): Record<string, string> | null => {
    const { name } = this.state;
    const { t } = this.props;
    let errors: Record<string, string> | null = {};

    if (!name.trim()) {
      errors.name = t(`${TranslationNamespaces.common}|Name can't be empty`);
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  render() {
    const { id, name, errors, loading, clientUuid, estimatedHours, estimatedCost, isPublic } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Title>{t("Create Project")}</Title>
        <IdNameWrapper>
          <FieldWrapper fieldName="ID" wrapperWidth="60px" width="100%">
            <TextInputControl value={id} error={!!errors?.id} onChange={(value) => this.setState({ id: value })} />
          </FieldWrapper>
          <Spacer />
          <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Name`)} wrapperWidth="100%" width="100%">
            <TextInputControl
              value={name}
              error={!!errors?.name}
              onChange={(value) => this.setState({ name: value })}
            />
          </FieldWrapper>
        </IdNameWrapper>
        {errors?.id && <ErrorLabel>{errors.id}</ErrorLabel>}
        {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        <br />
        <CheckboxControl
          checked={isPublic}
          label={t("Public project")}
          onChange={(checked) => {
            this.setState({ isPublic: checked });
          }}
        />
        <FieldWrapper fieldName={t("Client")} width="100%">
          <ClientsDropdown
            placeholder={t("Client")}
            value={clientUuid || ""}
            defaultSelectView
            loading={loading}
            isClearable
            onChange={(value) => this.setState({ clientUuid: value })}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Estimated hours")} wrapperWidth="50%" width="100%">
          <TextInputControl
            value={`${estimatedHours}`}
            error={!!errors?.estimatedHours}
            onChange={(value) =>
              this.setState({
                estimatedHours: value.replace(/[^0-9]*/g, "") ? parseInt(value.replace(/[^0-9]*/g, ""), 10) : 0,
              })
            }
          />
        </FieldWrapper>
        <RatesWrapper
          currencySign={
            window?.global_store?.company?.currency ? getSymbolFromCurrency(window.global_store.company.currency) : "$"
          }
        >
          <FieldWrapper fieldName={t("Estimated cost")} wrapperWidth="50%" width="100%">
            <TextInputControl
              value={`${estimatedCost}`}
              error={!!errors?.estimatedCost}
              onChange={(value) =>
                this.setState({
                  estimatedCost: value.replace(/[^0-9]*/g, "") ? parseInt(value.replace(/[^0-9]*/g, ""), 10) : 0,
                })
              }
            />
          </FieldWrapper>
        </RatesWrapper>

        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={loading}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Button
            value={t(`${TranslationNamespaces.common}|Create`)}
            state={ButtonState.primary}
            disabled={!name || loading}
            onClick={this.onYes}
            loading={loading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ProjectsPopupMessage);
