import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalContext } from "context/GlobalContextProvider";
import { PermissionRole, PermissionRoleName } from "types/models/permissions";
import { Team } from "types/models/team";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import SelectControl from "components/UI/SelectControl";
import FieldWrapper from "components/UI/FieldWrapper";
import NewCheckbox from "components/UI/NewCheckbox";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import { Department } from "types/models/department";
import { Subsidiary } from "types/models/subsidiary";
import { getProfilesBasedOnPermissions } from "utils/api/company";
import { translateEmployeeTerm } from "utils/translationHelpers";

export type EmployeePermission = {
  id?: number;
  role: { name: PermissionRoleName; uuid: string };
  teams: { uuid: string; name: string; readOnly: boolean }[];
  departments: { uuid: string; name: string }[];
  subsidiaries: { uuid: string; name: string }[];
  userProfiles: { uuid: string; name: string }[];
  includingAllcompany: boolean;
};

const SectionTitle = styled.div`
  margin-top: 21px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--colors-surface-800);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
`;

const DeletePermission = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    font-weight: var(--typography-font-weight-medium);
    font-size: 13px;
    color: var(--colors-primary-500-p);
    cursor: pointer;

    svg {
      margin-inline-end: 10px;
    }
  }
`;

interface AddEditPermissionPopupProps {
  /** do not allow duplicates  */
  excludePermissionUuid: string | undefined;
  permissionToUpdate: EmployeePermission | null;
  onAddPermission: (permission: EmployeePermission) => void;
  onDeletePermission?: ((permissionRoleUuid: string) => void) | null;
  onClose: () => void;
  hasReadOnlyTeam: boolean;
}

const AddEditPermissionPopup = ({
  onAddPermission,
  onDeletePermission,
  onClose,
  permissionToUpdate,
  excludePermissionUuid,
  hasReadOnlyTeam,
}: AddEditPermissionPopupProps) => {
  const { t } = useTranslation(TranslationNamespaces.employeesPage);
  const context = useContext(GlobalContext);
  const [teams, setTeams] = useState<(Team & { readOnly?: boolean })[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
  const [userProfiles, setUserProfiles] = useState<{ uuid: string; name: string }[]>([]);
  const [permisionRoles, setPermissionRoles] = useState<PermissionRole[]>([]);

  const [permission, setPermission] = useState<{ uuid: string; name: PermissionRoleName | "" } | undefined>(
    permissionToUpdate?.role,
  );
  const [selectedTeams, setSelectedTeams] = useState<EmployeePermission["teams"]>(permissionToUpdate?.teams || []);
  const [selectedDepartments, setSelectedDepartments] = useState<EmployeePermission["departments"]>(
    permissionToUpdate?.departments || [],
  );
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState<EmployeePermission["subsidiaries"]>(
    permissionToUpdate?.subsidiaries || [],
  );
  const [selectedUserProfiles, setSelectedUserProfiles] = useState<EmployeePermission["userProfiles"]>(
    permissionToUpdate?.userProfiles || [],
  );
  const [includingAllcompany, setIncludingAllCompany] = useState(permissionToUpdate?.includingAllcompany || false);

  useEffect(() => {
    void (async () => {
      const [gcTeams, gcUserProfiles, gcRoles, gcDepartments, gcSubsidiaries] = await Promise.all([
        context.getTeams(),
        getProfilesBasedOnPermissions(window.global_store.company.uuid, {
          requestedBy: window.global_store.profile.uuid,
        }),
        context.getRoles(),
        context.getDepartments(),
        context.getSubsidiaries(),
      ]);

      setPermissionRoles(gcRoles);
      setUserProfiles(gcUserProfiles.content);
      setDepartments(gcDepartments);
      setSubsidiaries(gcSubsidiaries);

      setTeams(
        gcTeams.map((tm) => {
          // do not allow to remove readonly team in SelectControl
          const readOnly = hasReadOnlyTeam && !!selectedTeams.find((st) => st.uuid === tm.uuid)?.readOnly;

          return { ...tm, readOnly };
        }),
      );
    })();
  }, []);

  return (
    <div>
      <FieldWrapper fieldName={t("Select a permission role for this employee")} width="100%" fieldTitleMarginTop={0}>
        <SelectControl<string>
          options={permisionRoles
            .filter(
              (pr) =>
                ![
                  PermissionRoleName.owner,
                  PermissionRoleName.clientManager,
                  PermissionRoleName.locationManager,
                  PermissionRoleName.deactivated,
                ].includes(pr.name) &&
                (!!permissionToUpdate || pr.uuid !== excludePermissionUuid), // exclude duplications
            )
            .map((pr) => ({
              label: pr.predefined ? t(`${TranslationNamespaces.employeesPermissions}|${pr.name}`) : pr.name,
              value: pr.uuid,
            }))}
          onChange={(value: string, label: PermissionRoleName) => setPermission({ uuid: value, name: label })}
          value={permission?.uuid}
          disabled={hasReadOnlyTeam}
        />
      </FieldWrapper>

      <SectionTitle>{t("Units")}</SectionTitle>

      <FieldWrapper
        fieldName={translateEmployeeTerm(t, TranslationNamespaces.common, "custom-employees", "People")}
        width="100%"
        fieldTitleMarginTop={8}
      >
        <SelectControl<string[]>
          isSearchable
          isMulti
          isClearable
          options={userProfiles.map((up) => ({ label: up.name, value: up.uuid }))}
          onChange={(userProfileUuids: string[] | null) =>
            setSelectedUserProfiles(
              userProfiles
                .filter((up) => userProfileUuids?.includes(up.uuid))
                .map((up) => ({ uuid: up.uuid, name: up.name })),
            )
          }
          value={selectedUserProfiles.map((up) => up.uuid)}
          disabled={includingAllcompany}
        />
      </FieldWrapper>

      <FieldWrapper fieldName={t("Teams")} width="100%" fieldTitleMarginTop={8}>
        <SelectControl<string[]>
          isSearchable
          isMulti
          isClearable
          options={teams.map((tm) => ({ label: tm.name, value: tm.uuid, readOnly: tm.readOnly }))}
          onChange={(teamUuids: string[] | null) => {
            setSelectedTeams(
              teams
                .filter((tm) => tm.readOnly || teamUuids?.includes(tm.uuid))
                .map((tm) => ({ uuid: tm.uuid, name: tm.name, readOnly: !!tm.readOnly })),
            );
          }}
          value={selectedTeams.map((tm) => tm.uuid)}
          disabled={includingAllcompany}
        />
      </FieldWrapper>

      <FieldWrapper fieldName={t("Departments")} width="100%" fieldTitleMarginTop={8}>
        <SelectControl<string[]>
          isSearchable
          isMulti
          isClearable
          options={departments.map((d) => ({ label: d.name, value: d.uuid }))}
          onChange={(departmentsUuids: string[] | null) => {
            setSelectedDepartments(
              departments
                .filter((d) => departmentsUuids?.includes(d.uuid))
                .map((d) => ({ uuid: d.uuid, name: d.name })),
            );
          }}
          value={selectedDepartments.map((d) => d.uuid)}
          disabled={includingAllcompany}
        />
      </FieldWrapper>

      <FieldWrapper fieldName={t("Subsidiaries")} width="100%" fieldTitleMarginTop={8}>
        <SelectControl<string[]>
          isSearchable
          isMulti
          isClearable
          options={subsidiaries.map((s) => ({ label: s.name, value: s.uuid }))}
          onChange={(subsidiariesUuids: string[] | null) => {
            setSelectedSubsidiaries(
              subsidiaries
                .filter((s) => subsidiariesUuids?.includes(s.uuid))
                .map((s) => ({ uuid: s.uuid, name: s.name })),
            );
          }}
          value={selectedSubsidiaries.map((s) => s.uuid)}
          disabled={includingAllcompany}
        />
      </FieldWrapper>

      <FieldWrapper width="100%" fieldTitleMarginTop={16}>
        <NewCheckbox
          checked={includingAllcompany}
          onChange={(checked) => setIncludingAllCompany(checked)}
          label={t("Including all company")}
        />
      </FieldWrapper>

      <Buttons>
        <Button state={ButtonState.secondary} value={t("Cancel")} onClick={onClose} />
        <Button
          disabled={!permission?.uuid}
          value={t("Apply")}
          onClick={() =>
            onAddPermission({
              id: permissionToUpdate?.id || undefined,
              role: permission as EmployeePermission["role"],
              teams: selectedTeams,
              departments: selectedDepartments,
              subsidiaries: selectedSubsidiaries,
              userProfiles: selectedUserProfiles,
              includingAllcompany,
            })
          }
        />
      </Buttons>

      {onDeletePermission && permissionToUpdate && !hasReadOnlyTeam && (
        <DeletePermission>
          <div onClick={() => onDeletePermission(permissionToUpdate.role.uuid)}>
            {images.trashIconBlue}
            <span>{t(`${TranslationNamespaces.common}|Delete`)}</span>
          </div>
        </DeletePermission>
      )}
    </div>
  );
};

export default AddEditPermissionPopup;
