import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { Activity } from "components/PunchNow/views/ActivityEditView";
import { CustomField } from "types/models/projects";
import { Popup } from "@mobiscroll/react";
import attachments from "components/Requests/Attachments";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Location } from "services/oitchau-api";
import CONFIG from "config";
import { TimesheetContext } from "components/Timesheets/timesheets.context";
import { TaskControlWrapper } from "./components/TaskControl";
import { useActivityStore } from "./TrackTime.store";
import { CustomFields } from "./components/CustomFields";
import { ActivityLocationAndFiles } from "./components/TimeTrackerAttachments";
import { DatePickerPopup } from "./components/DatePickerPopup";
import { LocationSelect } from "./components/LocationSelect";
import { BriefcaseIcon, ChevronRight, CloseIcon, FolderIcon, TrashIcon } from "./components/TimerButtons";

const ActivityModalWrapper = styled.div`
  position: relative;
  background: var(--colors-surface-0);
  height: fit-content;
  width: 100%;
  .with-hover {
    cursor: pointer;
    &:hover {
      background: var(--colors-surface-50);
    }
  }
  .HeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 12px 16px 0 20px;
    & > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg path {
        fill: var(--colors-surface-600);
      }
      &:hover svg path {
        fill: var(--colors-surface-900-p);
      }
    }
  }
  .Date {
    padding: 0 10px;
  }
  .Date.with-hover:hover {
    cursor: pointer;
    background: var(--colors-surface-50);
    height: 36px;
    border-radius: var(--shapes-border-radius-default);
    display: flex;
    align-items: center;
  }
  .Dropdown .Content {
    max-height: 395px;
    min-height: 102px;
  }
  .DurationSelect {
    padding: 0 12px;
    border-color: transparent;
    margin: 0;
    margin-inline-start: auto;
    max-width: 80px;
    text-align: center;
    &:hover {
      border-color: var(--colors-surface-150);
    }
  }
  .TaskWrapper {
    padding: 12px 12px 20px;
    .Task {
      height: 40px;
      padding: 8px;
      border-radius: var(--shapes-border-radius-default);
    }
  }
  .ModalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 20px;
    border-top: 1px solid var(--colors-surface-150);
    .DoneBtn,
    .CancelBtn {
      outline: none;
      box-shadow: none;
      border: none;
      border-radius: var(--shapes-border-radius-default);
      cursor: pointer;
      padding: 0 16px;
      height: 36px;
      text-align: center;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-medium);
    }
    .DoneBtn {
      color: var(--colors-surface-0);
      background: var(--colors-primary-500-p);
      &:hover {
        background: var(--colors-primary-600);
      }
      &:disabled {
        background: var(--colors-primary-500-p);
        opacity: 0.8;
        cursor: default;
      }
    }
    .CancelBtn {
      color: var(--colors-surface-600);
      background: transparent;
      &:hover {
        color: var(--colors-surface-800);
      }
    }
  }
  .CustomTaskView {
    section {
      padding: 4px 8px 0 8px;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 4px;
      .Project {
        display: flex;
        align-items: center;
        gap: 6px;

        span {
          color: var(--colors-surface-900-p);
          font-size: var(--typography-font-size-default);
          font-weight: var(--typography-font-weight-default);
          line-height: 130%;
        }
      }
    }

    .Task {
      .TaskName {
        color: var(--colors-surface-900-p);
        font-weight: var(--typography-font-weight-default);
      }

      .SelectIcon path {
        fill: var(--colors-surface-700);
      }

      &:hover {
        background: var(--colors-surface-50);
        border-radius: var(--shapes-border-radius-default);
      }
    }
  }
`;
const ConfirmDeleteWrapper = styled.div`
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 0 24px;
    height: 64px;
    border-bottom: 1px solid var(--colors-surface-150);
    font-size: 20px;
    color: var(--colors-surface-900-p);
  }
  .Content {
    padding: 24px;
    color: var(--colors-surface-800);
    border-bottom: 1px solid var(--colors-surface-150);
    font-size: var(--typography-font-size-default);
  }
  .Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 24px;
    .CancelBtn,
    .DoneBtn {
      border-radius: var(--shapes-border-radius-default);
      padding: 0 16px;
      height: 36px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-medium);
      outline: none;
      border: none;
      cursor: pointer;
    }
    .CancelBtn {
      background: transparent;
      color: var(--colors-surface-600);
      border: 1px solid var(--colors-surface-200);
      &:hover {
        border-color: var(--colors-surface-600);
        color: var(--colors-surface-800);
      }
    }
    .DoneBtn {
      background: var(--colors-primary-500-p);
      color: var(--colors-surface-0);
      &:hover {
        background: var(--colors-primary-600);
      }
    }
  }
`;

export const ActivityModal = ({
  activityData,
  onClose,
  deleteActivity,
  saveActivity,
  anchor,
  isOpen,
}: {
  activityData: Activity | null;
  onClose: () => void;
  deleteActivity: (activity: { uuid: string }) => Promise<void>;
  saveActivity: (activity: Activity) => Promise<void>;
  anchor?: HTMLElement;
  isOpen: boolean;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const timesheetContext = useContext(TimesheetContext);
  const {
    activity,
    setActivity,
    setShowTaskDetails,
    locations,
    initiateDateValues,
    showTaskPicker,
    clearStore,
    duration,
    startTime,
    startDate,
    endTime,
    setShowTaskPicker,
    showEmployeeSelect,
    projects,
  } = useActivityStore();
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [showLocationSelect, setShowLocationSelect] = useState(false);
  const [location, setLocation] = useState<Location | undefined>();
  const [files, setFiles] = useState(activity?.attachments || []);
  const [instance, setInstance] = useState(null);
  const [height, setHeight] = useState(298);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  useEffect(() => {
    void setActivity(activityData);
  }, [activityData]);

  useEffect(() => {
    if (activity?.locationUuid && !activity?.location?.uuid) {
      setLocation(locations.find((l) => l.uuid === activity.locationUuid));
    } else {
      setLocation(activity?.location);
    }
  }, [activity, locations]);

  const recalculateHeight = useCallback(() => {
    const modal = document.querySelector(".ActivityPopup .mbsc-popup");
    const wrp = document.querySelector("#ActivityModal");
    const overlay = document.querySelector(".Overlay");
    const getHeight = (height: number) => (height > 450 ? 450 : height);
    if (modal) {
      if (overlay) {
        setHeight(getHeight(overlay.scrollHeight));
      } else {
        setHeight(getHeight(wrp!.scrollHeight));
      }
    }
    instance?.position();
  }, [instance]);

  useEffect(() => {
    const cb = (ev: MouseEvent) => {
      if (ev.target?.offsetParent?.className?.includes("ProjectRow")) {
        setTimeout(() => recalculateHeight(), 90);
      }
    };
    document.addEventListener("click", cb);

    return () => {
      document.removeEventListener("click", cb);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      recalculateHeight();
    }, 200);
  }, [
    activity,
    showLocationSelect,
    showTaskPicker,
    attachments,
    customFields,
    files,
    showEmployeeSelect,
    projects,
    locations,
  ]);

  const initiateValues = useCallback(() => {
    initiateDateValues();

    const options = (activity?.project?.customFieldsSettings || [])
      .filter((cfs) => !cfs.deletedAt)
      .map((cfs) => {
        const cf = activity?.customFields.find((f) => f.uuid === cfs.uuid);
        if (cf?.fieldType === "numeric" && cf?.value) cf.value = +cf.value;
        return { ...cfs, ...cf };
      });
    setCustomFields(options);
  }, [activity]);

  useEffect(() => {
    initiateValues();
  }, [activity, initiateValues]);

  const cancel = () => {
    clearStore();
    onClose();
  };

  const save = async () => {
    const getCustomFields = () =>
      customFields.reduce((res, cf) => {
        if (cf.value) res.push(cf);
        return res;
      }, []);

    const payload = {
      ...activity,
      date: startDate!.format(CONFIG.apiDateFormat),
      startTime: startTime!,
      endTime: endTime!,
      duration,
      customFields: getCustomFields(),
    };
    await saveActivity(payload);

    clearStore();
  };

  const CustomTaskView = () => (
    <div className="CustomTaskView">
      {activity?.project && (
        <section>
          <div className="Project">
            <FolderIcon />
            <span className="ProjectName">{activity?.project?.name}</span>
          </div>
          {activity?.project?.client?.name && (
            <div className="Project">
              <BriefcaseIcon />
              <span className="ProjectName">{activity.project.client.name}</span>
            </div>
          )}
        </section>
      )}
      <div onClick={() => setShowTaskPicker(true)} className="Task">
        {activity?.task?.name ? (
          <span style={{ maxWidth: "90%", fontSize: "18px" }} className="TaskName">
            {activity?.task?.name}
          </span>
        ) : (
          t("Select project and task")
        )}
        <div className="SelectIcon" style={{ rotate: "90deg" }}>
          <ChevronRight />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Popup
        ref={setInstance}
        display="anchored"
        anchor={anchor}
        showArrow={false}
        scrollLock={false}
        isOpen={isOpen}
        maxHeight={600}
        width={420}
        height={height}
        onClose={onClose}
        cssClass="mbsc-no-padding PopupWrapper ActivityPopup"
      >
        <ActivityModalWrapper id="ActivityModal">
          {!showTaskPicker && !showLocationSelect && (
            <div className="HeaderWrapper">
              {activity?.status === "pending" && activity.uuid && (
                <div onClick={() => setDeleteConfirmationVisible(true)}>
                  <TrashIcon />
                </div>
              )}
              <div onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
          )}
          {!showLocationSelect && (
            <div className="TaskWrapper">
              <TaskControlWrapper
                updateActiveActivity={() => {}}
                store={useActivityStore}
                customView={<CustomTaskView />}
              />
            </div>
          )}
          {!showTaskPicker && !showLocationSelect && (
            <DatePickerPopup
              isRunning={false}
              inModal
              withDurationInput
              store={useActivityStore}
              allowFutureActivities={!timesheetContext?.settings?.allowFutureActivities}
            />
          )}
          {!showTaskPicker && !showLocationSelect && (
            <CustomFields customFields={customFields} setCustomFields={setCustomFields} />
          )}
          {!showTaskPicker && !showLocationSelect && (
            <ActivityLocationAndFiles
              files={files}
              setFiles={setFiles}
              location={location}
              store={useActivityStore}
              setShowLocationSelect={() => {
                setShowLocationSelect(true);
                setShowTaskDetails(false);
              }}
            />
          )}
          {!showTaskPicker && !showLocationSelect && (
            <div className="ModalFooter">
              <button type="button" onClick={cancel} className="CancelBtn">
                {t(`${TranslationNamespaces.common}|Cancel`)}
              </button>
              <button type="button" disabled={!activity?.task || !duration} onClick={save} className="DoneBtn">
                {t(`${TranslationNamespaces.common}|${activity?.uuid ? "Update" : "Create"}`)}
              </button>
            </div>
          )}
          {showLocationSelect && (
            <LocationSelect
              store={useActivityStore}
              location={location}
              setLocation={setLocation}
              setShowLocationSelect={setShowLocationSelect}
            />
          )}
        </ActivityModalWrapper>
      </Popup>
      <Popup
        isOpen={deleteConfirmationVisible}
        onClose={() => setDeleteConfirmationVisible(false)}
        cssClass="mbsc-no-padding ConfirmDelete"
        width={340}
      >
        <ConfirmDeleteWrapper>
          <div className="Header">
            <div className="Title">{t(`remove-activity-title`)}</div>
            <div onClick={() => setDeleteConfirmationVisible(false)} className="Icon">
              <CloseIcon />
            </div>
          </div>
          <div className="Content">{t(`remove-activity-description`)}</div>
          <div className="Footer">
            <button type="button" className="CancelBtn" onClick={() => setDeleteConfirmationVisible(false)}>
              {t(`${TranslationNamespaces.common}|Cancel`)}
            </button>
            <button
              type="button"
              className="DoneBtn"
              onClick={async () => {
                await deleteActivity(activity);
                setDeleteConfirmationVisible(false);
                clearStore();
              }}
            >
              {t(`${TranslationNamespaces.common}|Confirm`)}
            </button>
          </div>
        </ConfirmDeleteWrapper>
      </Popup>
    </>
  );
};
