import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";

import RepPegador from "components/Pegador/Page";
import RepImport from "components/Pegador/Rep/Import";

const RoutePegador = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect exact strict from={`${path}`} to={`${path}/`} />
      <Redirect exact strict from={`${path}/import/`} to={`${path}/import`} />

      <Route exact strict path={`${path}/`} component={RepPegador} />
      <Route exact strict path={`${path}/import`} component={RepImport} />
    </Switch>
  </Suspense>
);

export default RoutePegador;
