import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { Select, normalizeString, OptionProps } from "components/views/Select";
import { useMemo } from "react";
import cx from "classnames";
import { stylesheet } from "astroturf";
import { IconSelectedTick } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 370px;
  overflow: hidden;
  background: var(--colors-default);

  .Location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;

    &:first-child {
      margin-top: 8px;
    }

    .Texts {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .Name {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-mainText);
      }

      .Address {
        font-size: 13px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-600);
      }
    }

    &:hover {
      background: var(--colors-bgHover);
    }

    &.selected {
      .Texts {
        .Name {
          color: var(--colors-primary-600);
        }
      }
    }
  }
}
`;

interface Location {
  uuid: string;
  name: string;
  address: string;
}
function LocationOption(props: OptionProps<{ location: Location; selected: boolean }>) {
  const { levels, onSelect } = props;
  const option = levels[0];
  const { location, selected } = option;

  return (
    <div className={cx(styles.Location, { [styles.selected]: selected })} onClick={() => onSelect()}>
      <div className={styles.Texts}>
        <div className={styles.Name}>{location.name}</div>
        <div className={styles.Address}>{location.address}</div>
      </div>
      {selected && <IconSelectedTick />}
    </div>
  );
}

export interface LocationSelectorProps {
  locations: Location[];
  selectedLocationUuid?: string;
  onChange(location: Location): void;
  onClose(): void;
}
export function LocationSelector(props: LocationSelectorProps) {
  const { locations, selectedLocationUuid, onChange, onClose } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const options = useMemo(() => {
    const opts = locations.map((loc) => {
      const searchTerms = [normalizeString(loc.name)];
      if (loc.address) {
        searchTerms.push(normalizeString(loc.address));
      }
      const opt = {
        id: loc.uuid,
        label: loc.name,
        searchTerms,
        location: loc,
      };
      return opt;
    });
    return opts;
  }, [locations]);

  const optionsWithSelection = useMemo(() => {
    const opts = options.map((item) => {
      const opt = {
        ...item,
        selected: selectedLocationUuid !== undefined && item.location?.uuid === selectedLocationUuid,
      };
      return opt;
    });
    return opts;
  }, [options, selectedLocationUuid]);

  return (
    <div className={styles.Wrapper}>
      <Select
        options={optionsWithSelection}
        searchPlaceholder={t("Search locations")}
        onChange={(opt) => onChange(opt[0].location)}
        onClose={onClose}
        Option={LocationOption}
        autoFocus
      />
    </div>
  );
}
