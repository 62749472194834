import { Component, MouseEvent } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import cookie from "react-cookies";
import moment from "moment";
import { showSnackbar, validatePhoneNumber } from "utils/common";
import ga, { GaCreateAccountMethod } from "utils/ga";
import { createAccount, getPasswordValidation, isPhoneNumberExist, submitHSFormCreateContact } from "utils/apiHelpers";
import config from "config";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionRoleName } from "types/models/permissions";
import TextInput from "components/controls/TextInputControlNew";
import PhoneNumberField from "components/PhoneNumberField";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import sentryUtils from "utils/sentryUtils";
import { GetPasswordValidationsReturnData } from "utils/api/types";
import { RouteComponentProps } from "react-router-dom";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";
import Button from "../controls/StyledButton";

const PhoneWrapper = styled.div`
  .react-tel-input {
    margin: 0;

    .flag-dropdown {
      top: 2px !important;
      inset-inline-start: 2px !important;
      bottom: 2px !important;
      border: none;
      border-right: 2px solid var(--colors-surface-150) !important;
    }
    .form-control {
      height: 48px !important;
      border: 2px solid var(--colors-surface-150) !important;
      padding-inline-start: 48px;
      padding-inline-end: 0;
    }
    .form-control.field-error {
      border: 2px solid var(--colors-error) !important;
    }
    .selected-flag {
      padding-inline-start: 11px;
      .arrow {
        left: auto;
        inset-inline-start: 20px;
      }
    }
  }
`;

interface CreateProfilePageProps extends WithTranslation, RouteComponentProps {
  email: string;
  name: string;
  token: string;
  sourceType: string;
  trafficSource: string;
  trafficMedium: string;
  trafficCampaing: string;
  refCode: string;
}

interface CreateProfilePageState {
  phoneNumber: string;
  password: string;
  email: string;
  fullName: string;
  confirmationPopupVisible: boolean;
  country: string;
  passwordValidations: Record<string, never> | GetPasswordValidationsReturnData;
  errors: Record<string, string> | null;
  loading: boolean;
  dontAskForRedirect: boolean;
}

class CreateProfilePage extends Component<CreateProfilePageProps, CreateProfilePageState> {
  readonly state: Readonly<CreateProfilePageState> = {
    phoneNumber: "",
    password: "",
    email: "",
    fullName: "",
    confirmationPopupVisible: false,
    country: "",
    passwordValidations: {},
    errors: null,
    loading: false,
    dontAskForRedirect: false,
  };

  constructor(props: CreateProfilePageProps) {
    super(props);
    const { t } = props;

    document.title = t("Create an Account");
  }

  componentDidMount() {
    const { email, name } = this.props;

    if (email || name) {
      this.setState(
        {
          email: email || "",
          fullName: decodeURIComponent(name || ""),
        },
        this.getPasswordValidations,
      );
    }
  }

  onEmailChange = (email: string) => {
    this.setState({ email }, this.getPasswordValidations);
  };

  getPasswordValidations = async () => {
    const error = this.validateEmail();

    if (!error) {
      try {
        const { entirePassword, validations } = await getPasswordValidation(this.state.email);

        this.setState({ passwordValidations: { entirePassword, validations } });
      } catch (err) {
        sentryUtils.sendError(err);
      }
    }
  };

  validateEmail = () => {
    const { email } = this.state;
    const { t } = this.props;
    let error: string | null = null;

    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (!email) {
      error = t("Email Can't be Empty");
    } else if (!validate(email)) {
      error = t("Incorrect email");
    } else {
      error = null;
    }

    return error;
  };

  validateFullName = () => {
    const { fullName } = this.state;
    const { t } = this.props;
    const regExp = /(^(www|http))|([\d@;:!#$%^<>{}[\]&?/])/gim;

    let error: string | null = null;
    if (!fullName) {
      error = t("Full name can't be empty");
    } else if (regExp.test(fullName)) {
      error = t("Full name can't contain numbers and symbols");
    } else {
      error = null;
    }

    return error;
  };

  validatePassword = () => {
    const { password, passwordValidations } = this.state;
    const { t } = this.props;
    let error: string | null = null;

    if (passwordValidations.validations?.length) {
      passwordValidations.validations.forEach((v) => {
        if (!password.match(new RegExp(v.regex)) && !error) {
          error = t(v.constrain_key);
        }
      });
    } else if (passwordValidations.entirePassword) {
      if (!password.match(new RegExp(passwordValidations.entirePassword))) {
        error = t("Password is not strong enough");
      }
    } else if (!password) {
      error = t("Password can't be empty");
    } else if (password.length < 12) {
      error = t("Passwords should be at least 12 characters long");
    } else if (!password.match(/(?=.*?[A-Z])/)) {
      error = t("Password should contain at least one uppercase letter");
    } else if (!password.match(/(?=.*?[a-z])/)) {
      error = t("Password should contain at least one lowercase letter");
    } else if (!password.match(/(?=.*?[0-9])/)) {
      error = t("Password should contain at least one digit");
    } else if (!password.match(/(?=.*?[#?!@$%^&*-])/)) {
      error = t("Password should contain at least one special character");
    } else {
      error = null;
    }

    return error;
  };

  onNextClick = (ev: MouseEvent) => {
    ev.preventDefault();
    const { phoneNumber } = this.state;
    const { t } = this.props;

    let errors: CreateProfilePageState["errors"] = {};
    const fullnameError = this.validateFullName();
    if (fullnameError) {
      errors.fullName = fullnameError;
    }
    const passwordError = this.validatePassword();
    if (passwordError) {
      errors.password = passwordError;
    }
    const emailError = this.validateEmail();
    if (emailError) {
      errors.email = emailError;
    }
    const phoneNumberError = validatePhoneNumber(phoneNumber);
    if (phoneNumberError) {
      errors.phoneNumber = t(phoneNumberError);
    }

    if (Object.keys(errors).length === 0) {
      errors = null;

      this.setState({ errors });

      void this.submitForm();
    } else {
      this.setState({ errors });
    }
  };

  submitForm = async () => {
    const { password, email, fullName, phoneNumber, country } = this.state;
    const { t, token, sourceType, history } = this.props;

    this.setState({ loading: true }, async () => {
      const r = await isPhoneNumberExist({ phoneNumber, isOnboarding: true });

      // if busy or no response
      if (!r || r.phone_number_busy) {
        this.setState({
          errors: { phoneNumber: t("This phone number already registered.") },
          loading: false,
        });
      } else {
        const { trafficSource, trafficMedium, trafficCampaing } = this.props;

        try {
          const user = await createAccount({
            password,
            email,
            fullName,
            phoneNumber,
            token: token || "",
          });

          ga.trackCreateAccount({
            method: email ? GaCreateAccountMethod.email : GaCreateAccountMethod.phone,
            email,
            phone: phoneNumber,
            full_name: fullName,
            user_role: sourceType !== "inviteEmail" ? PermissionRoleName.owner : "",
            signup_date: moment(user.created_at).format("DD/MM/YY"),
            user_id: String(user.id),
          });

          window.global_store.fullName = fullName;
          window.global_store.signupUser = user;

          if (sourceType === "inviteEmail") {
            history.push("/success");
          } else {
            let params = "";
            const { refCode } = this.props;

            const payload = {
              hutk: cookie.load("hubspotutk"),
              email,
              firstName: fullName,
              mobilephone: phoneNumber,
              refCode,
              country,
              trafficSource,
              trafficMedium,
              trafficCampaing,
            };

            try {
              await submitHSFormCreateContact(payload);
            } catch (err) {
              sentryUtils.sendError(`Rd, Hs Error: ${String(err)}`);
            }

            if (refCode || trafficSource || trafficMedium || trafficCampaing || email) {
              params = "?";
              if (refCode) {
                params += `referral_code=${refCode}&`;
              }
              if (trafficSource) {
                params += `utm_source=${trafficSource}&`;
              }
              if (trafficMedium) {
                params += `utm_medium=${trafficMedium}&`;
              }
              if (email) {
                params += `email=${email}&`;
              }
              if (trafficCampaing) {
                params += `utm_campaign=${trafficCampaing}&`;
              }
            }

            history.push(`/account-type${params}`);
          }
        } catch (err) {
          const error = String(err);
          showSnackbar({ text: t(error) });

          sentryUtils.sendError(error);

          this.setState({ loading: false });
        }
      }
    });
  };

  render() {
    const {
      password,
      phoneNumber,
      email,
      fullName,
      errors,
      loading,
      confirmationPopupVisible,
      dontAskForRedirect,
      country,
    } = this.state;
    const { t, token, sourceType, history } = this.props;

    return (
      <MainPage
        topRowText={t("Already Have an account?")}
        topRowButtonText={t("LOGIN")}
        topRowButtonClick={() => history.push("/login")}
        pageTitle={sourceType === "inviteEmail" ? t("Complete your registration") : t("Create an account")}
        loading={loading}
        customButtons={
          <Button
            type="button"
            style={{ marginTop: "16px" }}
            onboarding
            value={t("Continue")}
            onClick={this.onNextClick}
          />
        }
        formParams={{
          style: { paddingTop: "40px" },
          action: "/account-type",
          method: "get",
          id: "hs_register",
        }}
      >
        <OnboardingFieldWrapper fieldName={t("Full name")}>
          <TextInput
            disabled={!!(token && fullName)}
            name="firstname"
            onboarding
            value={fullName}
            size={70}
            type="text"
            error={!!errors?.fullName}
            placeholder={t("common|name-placeholder")}
            onChange={(ev) => this.setState({ fullName: ev.target.value })}
          />
          {!!errors?.fullName && <ErrorLabel>{errors.fullName}</ErrorLabel>}
        </OnboardingFieldWrapper>

        {!this.props.email && (
          <OnboardingFieldWrapper fieldName={t("Email")}>
            <TextInput
              disabled={!!(token && email)}
              name="email"
              onboarding
              value={email}
              type="email"
              error={!!errors?.email}
              placeholder={t("common|email-placeholder")}
              onChange={(ev) => this.onEmailChange(ev.target.value)}
            />
            {!!errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}
          </OnboardingFieldWrapper>
        )}

        <OnboardingFieldWrapper fieldName={t("Password")}>
          <TextInput
            name="password"
            onboarding
            value={password}
            type="password"
            error={!!errors?.password}
            password
            onChange={(ev) => this.setState({ password: ev.target.value })}
          />
          {!!errors?.password && <ErrorLabel>{errors.password}</ErrorLabel>}
        </OnboardingFieldWrapper>

        <OnboardingFieldWrapper fieldName={t("Phone Number")}>
          <PhoneWrapper>
            <PhoneNumberField
              detectCountryCode
              newField
              autoComplete="off"
              name="mobilephone"
              isValid={!errors || !errors.phoneNumber}
              value={phoneNumber}
              onboarding
              onPhoneChange={(
                val: string,
                countryOb: Record<"name" | "dialCode" | "countryCode" | "format", string>,
              ) => {
                const showConfirmation = !dontAskForRedirect && config.globalMode && val.substring(0, 2) === "55";

                this.setState({
                  phoneNumber: val,
                  confirmationPopupVisible: showConfirmation,
                  country: countryOb?.name ? countryOb.name : "",
                });
              }}
            />
          </PhoneWrapper>
          {!!errors?.phoneNumber && <ErrorLabel>{errors.phoneNumber}</ErrorLabel>}
        </OnboardingFieldWrapper>

        {!!errors?.companyName && <ErrorLabel>{errors.companyName}</ErrorLabel>}
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false, dontAskForRedirect: true })}
        >
          <Lightbox
            title={
              config.globalMode
                ? t("Brazilian customers may use Oitchau.com.br")
                : t("Customers outside of Brazil may use Day.io")
            }
            text={t("Would you like to go there now?")}
            buttonYesTitle={t("Yes, Take me there")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ confirmationPopupVisible: false, dontAskForRedirect: true });
            }}
            onYes={() => {
              window.location = (!config.globalMode
                ? "https://admin.day.io/signup"
                : "https://admin.oitchau.com.br/signup") as unknown as Location;
            }}
          />
        </ModalDialog>
      </MainPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(CreateProfilePage);
