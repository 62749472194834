import { Component, MouseEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import cookie from "react-cookies";
import { createCompany, getIndustryTypes } from "utils/apiHelpers";
import { showSnackbar } from "utils/common";
import ga from "utils/ga";
import { TranslationNamespaces } from "types/translationNamespaces";
import TextInput from "components/controls/TextInputControlNew";
import LocationSearchInput from "components/LocationSearchInput";
import { CompanyType } from "types/models/company";
import sentryUtils from "utils/sentryUtils";
import { Address } from "types/common";
import { stylesheet } from "astroturf";
import { getLocale } from "utils/translationHelpers";
import SelectControl from "../SelectControl";
import EmployeesCountControl from "./EmployeesCountControl";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";
import ProgressBar from "../ProgressBar";

const styles = stylesheet`
  .InputText input {
    font-size: 16px !important;
    color: var(--colors-surface-800) !important;
  }
`;
const AddCode = styled.div`
  margin-top: -16px;
  margin-bottom: 60px;
  line-height: 18px;
  font-size: 15px;
  font-style: normal;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-primary);
  cursor: pointer;
`;
const CustomLoadingWrapper = styled.div`
  h1 {
    color: var(--colors-surface-900-p);
    font-size: 25px;
    margin: 0 0 8px;
    font-weight: var(--typography-font-weight-default);
  }
  .SubTitle {
    color: var(--colors-surface-600);
    font-size: 15px;
    margin-bottom: 24px;
  }
  .ProgressBarWrapper {
    position: relative;
    width: 100%;
    max-width: 484px;
    height: 16px;

    .progress-bar__bar-wrapper {
      width: 100%;
      bottom: -1px;
      margin: 0;
    }
    .progress-bar__bar {
      height: 16px;
    }
    .progress-bar__progress {
      height: 16px;
      background: var(--colors-primary-500-p);
      transition: width 25s ease-out;
    }
  }
`;
type CategoryOption = {
  label: string;
  name: string;
  id: number;
};

interface CreateCompanyPageProps extends WithTranslation, RouteComponentProps {
  refCode: string | null;
}

interface CreateCompanyPageState {
  companyName: string;
  employeesCount: string;
  showCodeField: boolean;
  category: CategoryOption | null;
  categoryOptions: CategoryOption[];
  refCode: string;
  address: Address;
  loading: boolean;
  errors: Record<string, string> | null;
}
const CustomLoadingContent = () => {
  const { t } = useTranslation(TranslationNamespaces.signup);
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(95);
    }, 900);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <CustomLoadingWrapper>
      <h1>{t("create_company_loading_title")}</h1>
      <div className="SubTitle">{t("create_company_loading_subtitle")}</div>
      <div className="ProgressBarWrapper">
        <ProgressBar width={progress} />
      </div>
    </CustomLoadingWrapper>
  );
};

class CreateCompanyPage extends Component<CreateCompanyPageProps, CreateCompanyPageState> {
  constructor(props: CreateCompanyPageProps) {
    super(props);

    this.state = {
      companyName: "",
      employeesCount: "",
      showCodeField: false,
      refCode: props.refCode || "",
      category: null,
      categoryOptions: [],
      address: {
        formatted: "",
        lat: 0,
        lng: 0,
        country: "",
        state: "",
        street: "",
        city: "",
        streetNumber: "",
        zip: "",
      },
      loading: false,
      errors: null,
    };
  }

  async componentDidMount() {
    const { t } = this.props;

    try {
      const industryTypes = await getIndustryTypes();
      const categoryOptions = industryTypes
        .map(
          (it): CategoryOption => ({
            name: it.name,
            label: t(`industry-types|${it.key}`),
            id: it.id,
          }),
        )
        .sort((a, b) => {
          if (b.name === "Others") {
            return -1;
          }
          if (b.label > a.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

      this.setState({ categoryOptions });
    } catch (err) {
      sentryUtils.sendError("can't get industry types");
    }
  }

  onCategoryChange = (category: CategoryOption) => {
    this.setState({ category });
  };

  validateCompanyName = () => {
    const { t } = this.props;
    const { companyName } = this.state;
    let error: string | null;

    if (!companyName) {
      error = t("Company name can't be empty");
    } else {
      error = null;
    }

    return error;
  };

  validateAddress = () => {
    const { t } = this.props;
    const { address } = this.state;
    const value = address.country;

    let error: string | null;
    if (!value) {
      error = `${t("Address can't be empty.")} ${t("Please select address form dropdown")}`;
    } else {
      error = null;
    }

    return error;
  };

  validateCategory = () => {
    const { t } = this.props;
    const { category } = this.state;

    let error: string | null;
    if (category === null) {
      error = t("Category can't be empty");
    } else {
      error = null;
    }

    return error;
  };

  validateEmployeesCount() {
    const { employeesCount } = this.state;
    const { t } = this.props;
    if (!employeesCount) return t("Employees count can't be empty");
    return "";
  }

  onNextClick = (ev: MouseEvent) => {
    ev.preventDefault();

    let errors: CreateCompanyPageState["errors"] = {};

    const companyNameError = this.validateCompanyName();
    if (companyNameError) {
      errors.companyName = companyNameError;
    }
    const addressError = this.validateAddress();
    if (addressError) {
      errors.address = addressError;
    }
    const categoryError = this.validateCategory();
    if (categoryError) {
      errors.category = categoryError;
    }
    const employeeCountError = this.validateEmployeesCount();
    if (employeeCountError) errors.employeesCount = employeeCountError;

    if (Object.keys(errors).length === 0) {
      errors = null;

      void this.submitForm();
    } else {
      this.setState({ errors });
    }
  };

  submitForm = () => {
    this.setState({ loading: true, errors: null }, async () => {
      const { companyName, address, employeesCount, category, refCode } = this.state;
      const { t } = this.props;

      const locale = getLocale();

      try {
        ga.trackCreateCompany({
          company_name: companyName,
          company_address: address.formatted,
          company_category: (category as CategoryOption).name,
          number_of_employees: employeesCount,
        });
        const company = await createCompany({
          companyType: CompanyType.llc,
          companyName,
          address,
          employeesCount,
          categoryId: (category as CategoryOption).id,
          refCode,
          locale,
        });

        // get created session from company response
        // remove x-user-email auth and set current token as bearer
        if (company?.session?.token) {
          cookie.remove("userEmail", { path: "/" });
          cookie.remove("userToken", { path: "/" });
          localStorage.setItem("oi_auth", company.session.token);
        }

        window.location = "/select-objectives" as unknown as Location;
      } catch (error) {
        showSnackbar({ text: t(`Server responded with error:${error}`) });

        this.setState({
          loading: false,
        });
      }
    });
  };
  render() {
    const { companyName, employeesCount, refCode, showCodeField, category, address, errors, loading, categoryOptions } =
      this.state;
    const { t, history } = this.props;

    return (
      <MainPage
        topRowText={t("Want to add a Houshold?")}
        topRowButtonText={t("CREATE")}
        topRowButtonClick={!loading ? () => history.push("/account-type?type=househould") : undefined}
        buttonText={t("Continue")}
        pageTitle={t("Create Company")}
        onSubmit={this.onNextClick}
        loading={loading}
        customContent={loading}
        formParams={{
          action: "/register",
          style: { paddingTop: "40px" },
          method: "post",
          id: "hs_create-company",
          noValidate: true,
          acceptCharset: "UTF-8",
        }}
      >
        {loading ? (
          <CustomLoadingContent />
        ) : (
          <>
            <OnboardingFieldWrapper fieldName={t("COMPANY NAME")}>
              <TextInput
                className={styles.InputText}
                name="company"
                onboarding
                value={companyName}
                placeholder={t("My Company")}
                size={70}
                type="text"
                error={!!errors?.companyName}
                onChange={(ev) => this.setState({ companyName: ev.target.value })}
              />
              {!!errors?.companyName && <ErrorLabel>{errors.companyName}</ErrorLabel>}
            </OnboardingFieldWrapper>

            <OnboardingFieldWrapper fieldName={t("Address")}>
              <LocationSearchInput
                className={styles.InputText}
                address={address}
                isOnboarding
                isValid={!errors || !errors.address}
                onChange={(val) => this.setState({ address: val })}
              />
              {!!errors?.address && <ErrorLabel>{errors.address}</ErrorLabel>}
            </OnboardingFieldWrapper>

            <OnboardingFieldWrapper fieldName={t("Category")}>
              <SelectControl
                className={styles.InputText}
                name="category"
                value={category}
                onboarding
                wideBorder
                placeholder={t("Please select..")}
                onChange={this.onCategoryChange}
                options={categoryOptions}
                error={errors?.category}
              />
              {!!errors?.category && <ErrorLabel>{errors?.category}</ErrorLabel>}
            </OnboardingFieldWrapper>

            <OnboardingFieldWrapper fieldName={t("Number of employees")}>
              <EmployeesCountControl
                onChange={(val) => this.setState({ employeesCount: val })}
                value={employeesCount}
              />
              {!!errors?.employeesCount && <ErrorLabel>{errors?.employeesCount}</ErrorLabel>}
            </OnboardingFieldWrapper>

            {!showCodeField && (
              <AddCode onClick={() => this.setState({ showCodeField: true })}>{t("Add an Invite Code")}</AddCode>
            )}

            {!!showCodeField && (
              <OnboardingFieldWrapper fieldName={t("Invite Code (optional)")}>
                <TextInput
                  onboarding
                  value={refCode}
                  placeholder={t("Invite Code")}
                  type="text"
                  size={70}
                  onChange={(ev) => this.setState({ refCode: ev.target.value })}
                />
              </OnboardingFieldWrapper>
            )}
          </>
        )}
      </MainPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.signup, TranslationNamespaces.industryTypes])(CreateCompanyPage);
