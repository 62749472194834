import styled from "styled-components";

export const Wrapper = styled.div`
  width: 835px;
  height: 596px;
  background: var(--colors-default);
`;
export const Header = styled.div`
  border-bottom: 1px solid var(--colors-surface-150);
  height: 66px;
  padding-inline-start: 24px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--colors-mainText);
  letter-spacing: -0.44px;
  line-height: 26px;
`;
export const Container = styled.div`
  display: flex;
  height: 530px;
`;
export const AvailableEmployeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 331px;
  border-inline-end: 1px solid var(--colors-surface-150);
  height: 100%;
  padding: 8px 0 16px 0;
`;
export const SelectedEmployeesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 281px;
  height: 100%;
  background-color: var(--colors-unknown48);
  padding: 8px 0 16px 0;
  padding-bottom: 69px;
  position: relative;
`;
export const UserItem = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 7px;
  padding-inline-start: 25px;
  padding-inline-end: 10px;
  position: relative;
  display: flex;
  .user-checkbox {
    margin-inline-end: 16px;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--colors-activitiCellScheduleHover);
    .arrow-add {
      display: flex;
    }
  }
`;
export const MoveEmployee = styled.button`
  border: none;
  outline: none;
  width: 22px;
  height: 22px;
  background-color: var(--colors-primary);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  position: absolute;
  inset-inline-end: 24px;
  top: 18px;
  cursor: pointer;
`;
export const Arrow = styled.div<{ isRTL: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  border: 2px solid var(--colors-default);
  border-top: none;
  border-inline-start: none;
  transform: rotate(${(p) => (p.isRTL ? "135deg" : "-45deg")});
  margin-inline-start: -2px;
`;
export const SelectedEmployeeItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 7px 25px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: var(--colors-activitiCellScheduleHover);
    .button-remove {
      display: flex;
    }
  }
`;
export const SelectedEmployeesCount = styled.div`
  background-color: var(--colors-activitiCellScheduleItemHover);
  border-radius: 18px;
  font-weight: var(--typography-font-weight-bold);
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-800);
  line-height: 26px;
  padding: 0 15px;
`;
export const SelectedEmployeesTitle = styled.div`
  height: 18px;
  font-weight: var(--typography-font-weight-medium);
  font-family: CircularStd-Medium;
  font-size: 15px;
  color: var(--colors-mainText);
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
  align-items: center;
  margin-bottom: 22px;
`;
export const SelectedEmployeeName = styled.div`
  height: 18px;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-800);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SelectedEmployeePosition = styled.div`
  height: 18px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-unknown10);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const RemoveEmployee = styled.div`
  height: 22px;
  width: 22px;
  background-color: var(--colors-unknown46);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  position: absolute;
  inset-inline-end: 24px;
  top: 14px;
  cursor: pointer;
`;
export const AvailableEmployeesHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 39px; // actually it's 36px in browser
  margin-bottom: 16px;
  font-size: 15px;
  color: var(--colors-mainText);
`;
export const MoveAll = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary);
  padding-inline-end: 30px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    inset-inline-end: 0;
    width: 22px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 22'%3E%3Cpath d='M21.25 11c0 5.66-4.59 10.25-10.25 10.25S.75 16.66.75 11 5.34.75 11 .75 21.25 5.34 21.25 11z' stroke='%231E97F7' stroke-width='1.5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.293 6.05a1 1 0 011.414 0l4.243 4.243a1 1 0 010 1.414l-4.243 4.243a1 1 0 11-1.414-1.414L12.828 11 9.293 7.464a1 1 0 010-1.414z' fill='%231E97F7'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100%;
    ${(p) => (p.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
  }
`;
export const AddEmployees = styled.div`
  margin: 0 auto;
  width: 100%;
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: center;
`;
export const NavButton = styled.button`
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border: none;
  padding: 0;
  outline: none;
`;
export const CloseButton = styled(NavButton)`
  top: 21px;
  inset-inline-end: 20px;
  background: none;
`;
export const SearchImg = styled.img`
  width: 100%;
  height: 100%;
  max-width: 170px;
  max-height: 160px;
`;
export const SearchImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  input + div {
    font-size: 15px;
    color: var(--colors-surface-800);
  }
`;
