import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Avatar from "../Avatar";
import * as images from "../../svg-images";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
`;
const DefaultLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: var(--colors-unknown2);
  border-radius: 100px;
`;
const CompanyInfo = styled.div`
  display: inline-block;
  width: 100%;
  margin-inline-start: 15px;
  font-size: var(--typography-font-size-default);
  line-height: 16px;
  color: var(--colors-unknown3);
  vertical-align: middle;
`;
const CompanyName = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  line-height: 19px;
  color: var(--colors-text4);
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Position = styled.div`
  margin-top: 2px;
  overflow: hidden;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-600);
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Role = styled.div`
  display: inline-block;
  padding: 0 10px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 17px;
  color: var(--colors-surface-800);
  text-transform: uppercase;
  letter-spacing: -0.05px;
  vertical-align: middle;
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-secondary2);
  border-radius: 100px;
`;

export interface ProfileSelectorItemProps extends React.HTMLAttributes<HTMLDivElement> {
  companyName: string;
  companyLogoId?: string;
  employeePosition: string;
  employeeRole: string;
}

export function ProfileSelectorItem(props: ProfileSelectorItemProps) {
  const { companyName, companyLogoId, employeePosition, employeeRole, ...restProps } = props;
  const { t } = useTranslation("common");
  return (
    <Wrapper {...restProps}>
      <CompanyLogo>
        {companyLogoId ? (
          <Avatar
            user={{
              fullName: companyName,
              avatarId: companyLogoId,
            }}
            modifiers={{ big: true }}
          />
        ) : (
          <DefaultLogo>{images.companySwitchDefaultLogo}</DefaultLogo>
        )}
      </CompanyLogo>
      <CompanyInfo>
        <CompanyName title={companyName}>{companyName}</CompanyName>
        {employeePosition && <Position>{employeePosition}</Position>}
        {employeeRole && <Role>{t(employeeRole)}</Role>}
      </CompanyInfo>
    </Wrapper>
  );
}
