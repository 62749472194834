import moment from "moment";
import { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { ClientReportDownload, ClientReportDownloadStatus } from "types/models/clientReportDownload";
import { GlobalStoreCompany } from "types/models/company";
import { Department } from "types/models/department";
import { Operation } from "types/models/operations";
import { PermissionRole } from "types/models/permissions";
import { RequestType } from "types/models/request";
import { Subsidiary } from "types/models/subsidiary";
import { Team } from "types/models/team";
import { ActiveUserProfile } from "types/models/userProfile";

enum StoreItem {
  roles = "roles",
  employees = "employees",
  profiles = "profiles",
  downloads = "downloads",
  defaultPayPeriod = "defaultPayPeriod",
  loginAsMode = "loginAsMode",
  company = "company",
  requestTypes = "requestTypes",
}

type GlobalContextDownload =
  | {
      uuid: string;
      status: ClientReportDownloadStatus;
    }
  | ClientReportDownload;

type GlobalContextDefaultPayPeriod = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};

interface GlobalContextProviderProps extends PropsWithChildren, WithTranslation {}

interface GlobalContextProviderState {
  roles?: PermissionRole[] | null;
  profiles?: ActiveUserProfile[] | null;
  downloads?: GlobalContextDownload[] | null;
  defaultPayPeriod?: GlobalContextDefaultPayPeriod | null;
  requestTypes?: RequestType[] | null;
  operations?: Operation[] | null;
  teams?: Team[] | null;
  departments?: Department[] | null;
  subsidiaries?: Subsidiary[] | null;

  loginAsMode?: unknown | null;
  company?: unknown | null;
  employees?: unknown | null;
}

interface GlobalContextInterface {
  /**
   * Get existing socket or create new one. (aka singleton)
   */
  getSocket: () => Promise<SocketIOClient.Socket>;
  /**
   * Fetch company and set it to window.globalStore
   */
  refetchCompany: () => Promise<void>;
  /**
   * Get company data
   *
   * @param force if true fetch from server
   *
   * @returns company data or false if there was an error
   */
  getCompany: (force?: boolean) => Promise<GlobalStoreCompany>;
  /**
   * Get permission roles from the context state
   */
  getRoles: () => Promise<PermissionRole[]>;
  /**
   * Get default pay period from the context state
   */
  getDefaultPayPeriod: () => Promise<GlobalContextDefaultPayPeriod | null>;
  /**
   * Get current user's cached active profiles
   */
  getActiveProfiles: () => Promise<ActiveUserProfile[]>;
  /**
   * Get cached request types
   */
  getRequestTypes: (data?: { force?: boolean }) => Promise<RequestType[]>;
  /**
   * Get "done" or "long time" client report downloads
   */
  getDownloads: () => Promise<GlobalContextDownload[]>;
  /**
   * Add download to GlobalContextState
   */
  addToDownloads: (uuid: string) => Promise<GlobalContextDownload[]>;
  /**
   * Remove download from GlobalContextState
   */
  removeFromDownloads: (uuid: string) => Promise<GlobalContextDownload[]>;
  /**
   * Get fresh operations
   */
  getOperations: () => Promise<Operation[]>;
  /**
   * Get company teams (not cached to localstorage)
   */
  getTeams: (force?: boolean) => Promise<Team[]>;
  /**
   * Get company departments (not cached to localstorage)
   */
  getDepartments: (force?: boolean) => Promise<Department[]>;
  /**
   * Get company subsidiaries (not cached to localstorage)
   */
  getSubsidiaries: (force?: boolean) => Promise<Subsidiary[]>;
}

type GlobalContextValue = GlobalContextInterface;

export type {
  GlobalContextValue,
  GlobalContextProviderProps,
  GlobalContextProviderState,
  GlobalContextInterface,
  GlobalContextDefaultPayPeriod,
  GlobalContextDownload,
};

export { StoreItem };
