import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { WithTranslation } from "react-i18next";
import { fireEvent } from "utils/common";
import BEM from "utils/BEM";
import { iCellInfo } from "utils/tableHelpers";
import { PunchKey, PunchType } from "types/models/punches";
import {
  DailySummaryEmployeeInfo,
  SuperpunchPunchCellData,
  SuperpunchTableRowData,
  SuperpunchTableRows,
} from "types/models/superpunch";
import { EnableDayEventData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import Tooltip from "components/UI/Tooltip";
import { iColumn } from "components/TableCommon";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BusinessRuleGroupByUser } from "types/models/businessRulesGroup";
import { UserProfileRole } from "types/models/userProfile";
import { getBreakName } from "components/Schedules/Breaks/utils";
import CellTotalWithProgress from "./CellTotalWithProgress";
import CellTotal, { CellTotalType } from "./CellTotal";
import CellDate from "./CellDate";
import CellSchedule from "./CellSchedule";
import CellPunch from "./CellPunch";
import CellHoliday from "./CellHoliday";
import CellRequests from "./CellRequests";
import RowActionButton from "./RowActionButton";
import CellCheckbox from "./CellCheckbox";
import CellCheckboxAll from "./CellCheckboxAll";
import CellCombinedBreaks from "./CellCombinedBreaks";

const moment = extendMoment(momentTz);
const tc = BEM.b("table-common");

interface CellComponentProps {
  row: iCellInfo<SuperpunchTableRowData, SuperpunchPunchCellData>;
  punchType: PunchType;
  punchTypeCustomKey: PunchKey;
  employeeUuid: string;
  breakTypeName: string | null;
  breakTypeUniqueId: string | null;
}

/**
 * Disable actions only for own punches except owner
 * In any other cases actions should be enabled.
 * Everyone who has access tho superpunch page can make punch actions
 */
export const disablePunchActions = (selectedUserProfileUuid?: string) => {
  const isOwner = window.global_store.profile.role === UserProfileRole.employer;
  const isOwnPunches = selectedUserProfileUuid === window.global_store.profile.uuid;

  return isOwnPunches && !isOwner;
};

const CellComponent = ({
  row,
  punchType,
  punchTypeCustomKey,
  employeeUuid,
  breakTypeName,
  breakTypeUniqueId,
}: CellComponentProps) => (
  <CellPunch
    inactive={!!row.original.date.isDayOff}
    disabled={!row.original.shiftEvents || !row.original.shiftEvents.length}
    isLockedDay={row.original.lockedDay}
    date={row.original.date.raw}
    eventKey={punchTypeCustomKey}
    punchType={punchType}
    value={row.value}
    employeeUuid={employeeUuid}
    breakTypeName={breakTypeName}
    breakTypeUniqueId={breakTypeUniqueId}
  />
);

const getColumnsByPunchType = (
  t: WithTranslation["t"],
  punchType: PunchType,
  employeeUuid: string,
  count: number,
  customBreaksNamesMap: Record<string, string>,
) => {
  let arr: iColumn<SuperpunchTableRowData>[] = [];

  if (count > 0) {
    arr = [...Array(count)].map((_, i) => ({
      accessor: `${punchType}${i}`,
      rubyAccessor: `${punchType}${i}`,
      style: { paddingInlineStart: "1px" },
      label: i > 0 ? `${t(punchType)} ${i}` : t(punchType),
      Cell: (row: iCellInfo<SuperpunchTableRowData, SuperpunchPunchCellData>) => {
        if (row.value) {
          let breakTypeName = null;
          let breakTypeUniqueId = null;

          if (punchType === PunchType.breakStart || punchType === PunchType.breakEnd) {
            breakTypeUniqueId = `${row.original.date.dayMonth}${row.original.date.dayOfWeek}${punchType}${i}`;
            breakTypeName = getBreakName(
              // hotfix for blocker PROD-17980. will be good to refactor it in future
              // as we have breaks in order we use order id to get break name
              [...row.original.plannedDayBreakByTypes, ...row.original.plannedNightBreakByTypes]?.[i]?.breakTypeUuid,
              punchType,
              customBreaksNamesMap,
            );
          }

          return (
            <CellComponent
              row={row}
              employeeUuid={employeeUuid}
              punchType={punchType}
              punchTypeCustomKey={`${punchType}${i}` as PunchKey}
              breakTypeName={breakTypeName}
              breakTypeUniqueId={breakTypeUniqueId}
            />
          );
        }
        return null;
      },
      minWidth: 100,
      align: "center",
    }));
  }

  return arr;
};

type GetColumsData = {
  t: WithTranslation["t"];
  employeeId: number;
  employeeUuid: string;
  tableRows: SuperpunchTableRows | null;
  employeeInfo: DailySummaryEmployeeInfo | null;
  debitFlag: boolean;
  combinedBreaksFlag: boolean;
  businessRules: BusinessRuleGroupByUser[];
  customBreaksNamesMap: Record<string, string>;
};

export function getColumns({
  t,
  tableRows,
  employeeInfo,
  employeeUuid,
  debitFlag,
  combinedBreaksFlag,
  businessRules,
  customBreaksNamesMap,
}: GetColumsData) {
  const punchesCount: Record<string, number | never> = tableRows ? tableRows.punchesCount : {};

  const entries = getColumnsByPunchType(
    t,
    PunchType.entry,
    employeeUuid,
    punchesCount.entriesCount,
    customBreaksNamesMap,
  );
  const breakStarts = getColumnsByPunchType(
    t,
    PunchType.breakStart,
    employeeUuid,
    punchesCount.breakStartsCount,
    customBreaksNamesMap,
  );
  const breakEnds = getColumnsByPunchType(
    t,
    PunchType.breakEnd,
    employeeUuid,
    punchesCount.breakEndsCount,
    customBreaksNamesMap,
  );
  const exits = getColumnsByPunchType(t, PunchType.exit, employeeUuid, punchesCount.exitsCount, customBreaksNamesMap);
  const punchesColumns: iColumn<SuperpunchTableRowData>[] = [];

  if (entries[0]) {
    punchesColumns.push(entries[0]);
  }

  if (combinedBreaksFlag && punchesCount.breakStartsCount > 1) {
    punchesColumns.push({
      accessor: `breakEventsWithPunches`,
      rubyAccessor: `combined_breaks`,
      style: { paddingInlineStart: "1px" },
      label: t("combined_breaks"),
      Cell: (row: iCellInfo<SuperpunchTableRowData, Record<string, SuperpunchPunchCellData>>) => {
        if (!row.value || !Object.keys(row.value).length) {
          return null;
        }

        return (
          <CellCombinedBreaks
            rowDate={row.original.date}
            isLockedDay={row.original.lockedDay}
            employeeUuid={employeeUuid}
            shiftEvents={row.original.shiftEvents}
            breakEventsWithPunches={row.value}
          />
        );
      },
      minWidth: 100,
      align: "center",
    });
  } else {
    breakStarts.forEach((br, i) => {
      punchesColumns.push(br);
      if (breakEnds[i]) {
        punchesColumns.push(breakEnds[i]);
      }
    });
  }

  if (exits[0]) {
    punchesColumns.push(exits[0]);
  }

  if (exits.length > 1) {
    for (let i = 1; i < exits.length; i += 1) {
      punchesColumns.push(entries[i]);
      punchesColumns.push(exits[i]);
    }
  }

  const disableRowSelection = disablePunchActions(employeeInfo?.uuid);
  const showSelectAll = !disableRowSelection && tableRows?.rows?.some((row) => !row.lockedDay);

  // PROD-12889
  let hasHoursbankActive = false;
  let hasHoursbankInactive = false;
  let hasSplitPositiveHoursActive = false;
  let hasSplitPositiveHoursInactive = false;

  // we can have multiple busines rules over some date range
  businessRules.forEach((br) => {
    hasHoursbankActive = hasHoursbankActive || !!br.rules.payedOvertime?.hoursBankActive;
    hasHoursbankInactive = hasHoursbankInactive || !br.rules.payedOvertime?.hoursBankActive;
    hasSplitPositiveHoursActive =
      hasSplitPositiveHoursActive || !!br.rules.payedOvertime?.splitPositiveAndNegativeHours?.active;
    hasSplitPositiveHoursInactive =
      hasSplitPositiveHoursInactive || !br.rules.payedOvertime?.splitPositiveAndNegativeHours?.active;
  });

  const columns: (iColumn<SuperpunchTableRowData> | null)[] = [
    {
      accessor: "date",
      headerClassName: "checkbox-header",
      Cell: (row) => <CellCheckbox row={row} tableRows={tableRows} disableRowSelection={disableRowSelection} />,
      Header: showSelectAll ? <CellCheckboxAll tableRows={tableRows} /> : null,
      minWidth: 50,
      style: { fontWeight: "500", paddingInlineStart: "15px" },
      align: "center",
    },
    {
      accessor: "date",
      rubyAccessor: "date",
      locked: true,
      label: t("Date"),
      Cell: (row) => (
        <CellDate
          t={t}
          date={row.value}
          comments={row.original.comments}
          isLostDsrDay={row.original.isLostDsrDay}
          isPossibleDsrDay={row.original.isPossibleDsrDay}
          showDsrInReports={row.original.showDsrInReports}
        />
      ),
      minWidth: 110,
    },
    {
      accessor: "holiday",
      rubyAccessor: "holiday",
      label: t("Holiday"),
      Header: (
        <div className={tc("icon")}>
          <div data-tip data-for="holiday-col" style={{ pointerEvents: "auto" }}>
            {images.holidayIcon}
          </div>
          <Tooltip id="holiday-col">{t("Holiday")}</Tooltip>
        </div>
      ),
      width: 60,
      Cell: (row) => (row.original.holiday ? <CellHoliday date={row.original.date.raw} holiday={row.value} /> : null),
      style: { overflow: "visible", fontWeight: "500" },
      align: "center",
    },
    {
      accessor: "schedule",
      rubyAccessor: "schedule",
      label: t("Schedule"),
      Cell: (row) => (
        <CellSchedule
          t={t}
          date={row.original.date.raw}
          lockedDay={row.original.lockedDay}
          employeeUuid={employeeInfo?.uuid || ""}
          scheduleException={row.original.scheduleException}
          inactive={row.original.date.isDayOff}
          value={row.value}
        />
      ),
      minWidth: 150,
      align: "start",
    },
    ...punchesColumns,
    {
      accessor: "workedMinutes",
      locked: true,
      rubyAccessor: "worked_hours",
      Cell: (row) => (
        <div>
          <CellTotalWithProgress
            t={t}
            employeeUUID={employeeInfo?.uuid}
            date={row.original.date.raw}
            isUpdating={
              row.original.inProgress ||
              !!(
                row.original.shiftCompilation &&
                (row.original.shiftCompilation.revalidation_in_progress ||
                  row.original.shiftCompilation.revalidationInProgress)
              )
            }
            total={row.value}
            active={row.original.date.raw.isSameOrBefore(moment())}
          />

          <div className="enable-day">
            {!disableRowSelection &&
            !row.original.lockedDay &&
            !row.original.inProgress &&
            !!row.original.schedule &&
            (!row.original.shiftEvents || !row.original.shiftEvents.length) &&
            row.original.date.raw.isSameOrBefore(moment()) ? (
              <Button
                state={ButtonState.enableDay}
                value={t("Enable Day")}
                onClick={() => {
                  const date = moment(row.original.date.raw).format("YYYY-MM-DD");
                  // EnableDayEventData
                  fireEvent(SuperpunchCustomEvents.enableDay, {
                    employeeUUID: employeeInfo?.uuid,
                    companyUUID: window.global_store.company.uuid,
                    requestedBy: window.global_store.profile.uuid,
                    body: {
                      content: {
                        date,
                        approvedBy: window.global_store.profile.uuid,
                      },
                    },
                  } as EnableDayEventData);
                }}
              />
            ) : (
              window.global_store.beta && <RowActionButton />
            )}
          </div>
        </div>
      ),
      label: t("Worked Hours"),
      minWidth: 100,
      style: { fontWeight: "500" },
      align: "center",
    },
    {
      accessor: "extraHoursMinutes",
      rubyAccessor: "extra_hours",
      Cell: (row) => (
        <CellTotal
          date={row.original.date.raw}
          total={row.value}
          type={CellTotalType.extraHoursMinutes}
          active={row.original.date.raw.isSameOrBefore(moment())}
        />
      ),
      label: t("Extra Hours"),
      minWidth: 100,
      style: { fontWeight: "500" },
      align: "center",
    },
    hasHoursbankActive
      ? {
          accessor: "hoursBankMinutes",
          rubyAccessor: "hours_bank",
          Cell: (row) => (
            <CellTotal
              date={row.original.date.raw}
              total={row.value}
              type={CellTotalType.hoursBankMinutes}
              active={row.original.date.raw.isSameOrBefore(moment())}
            />
          ),
          label: t("Hours Bank"),
          minWidth: 100,
          style: { fontWeight: "500" },
          align: "center",
        }
      : null,
    hasHoursbankInactive && hasSplitPositiveHoursInactive
      ? {
          accessor: "missedMinutes",
          rubyAccessor: "missed_hours",
          Cell: (row) => (
            <CellTotal
              date={row.original.date.raw}
              total={row.value}
              type={CellTotalType.missedMinutes}
              active={row.original.date.raw.isSameOrBefore(moment())}
            />
          ),
          label: t("Missed Minutes"),
          minWidth: 100,
          style: { fontWeight: "500" },
          align: "center",
        }
      : null,
    debitFlag && hasHoursbankInactive && hasSplitPositiveHoursActive
      ? {
          accessor: "debitMinutes",
          rubyAccessor: "debit_minutes",
          Cell: (row) => (
            <CellTotal
              date={row.original.date.raw}
              total={row.value}
              type={CellTotalType.debitMinutes}
              active={row.original.date.raw.isSameOrBefore(moment())}
            />
          ),
          label: t("Debit Minutes"),
          minWidth: 100,
          style: { fontWeight: "500" },
          align: "center",
        }
      : null,
    {
      accessor: "requests",
      Cell: (row: iCellInfo<SuperpunchTableRowData, SuperpunchTableRowData["requests"]>) =>
        row.value.length > 0 &&
        row.value.filter((r) => r.approvalStatus !== "ignored" && r.approvalStatus !== "declined").length > 0 ? (
          <CellRequests
            t={t}
            employeeInfo={employeeInfo}
            requests={row.value}
            date={row.original.date.raw}
            allRequests={[]}
          />
        ) : null,
      label: t("Absence"),
      minWidth: 100,
      align: "center",
    },
  ];

  return columns.filter((c) => c !== null);
}
