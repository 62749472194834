import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import DeclineReasonPopup from "components/DeclineReasonPopup";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import ApproveDeclineActionBar from "components/ApproveDeclineActionBar";
import { NotificationType } from "types/common";
import { RequestApprovalFlowStatus } from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";

interface RequestActionsBarProps extends WithTranslation {
  selectedRequestsIds: string[];
  onDone: (notification: { notification: string; notificationType: NotificationType }) => void;
  onChangeRequestsStatus: (
    ids: string[],
    status: Omit<RequestApprovalFlowStatus, RequestApprovalFlowStatus.pending>,
    reason?: string,
  ) => void;
  onUncheckAll: () => void;
}

interface RequestActionsBarState {
  showApproveConfirmationPopup: boolean;
  showDeclineConfirmationPopup: boolean;
}

class RequestActionsBar extends Component<RequestActionsBarProps, RequestActionsBarState> {
  readonly state: Readonly<RequestActionsBarState> = {
    showApproveConfirmationPopup: false,
    showDeclineConfirmationPopup: false,
  };

  declineMultipleRequests = async (reason: string) => {
    const { t, selectedRequestsIds, onDone, onChangeRequestsStatus } = this.props;
    try {
      await onChangeRequestsStatus(selectedRequestsIds, RequestApprovalFlowStatus.declined, reason);

      onDone({ notification: t("decline-all-success"), notificationType: NotificationType.success });
    } catch (error) {
      onDone({ notification: t("decline-all-failed"), notificationType: NotificationType.error });
    }
  };

  approveMultipleRequests = async () => {
    const { t, selectedRequestsIds, onDone, onChangeRequestsStatus } = this.props;
    try {
      await onChangeRequestsStatus(selectedRequestsIds, RequestApprovalFlowStatus.approved);

      onDone({ notification: t("approve-all-success"), notificationType: NotificationType.success });
    } catch (error) {
      onDone({ notification: t("approve-all-failed"), notificationType: NotificationType.error });
    }
  };

  toggleApproveConfirmationPopup = () =>
    this.setState({ showApproveConfirmationPopup: !this.state.showApproveConfirmationPopup });

  toggleDeclineConfirmationPopup = () =>
    this.setState({ showDeclineConfirmationPopup: !this.state.showDeclineConfirmationPopup });

  render = () => {
    const { t, onUncheckAll, selectedRequestsIds } = this.props;
    const { showApproveConfirmationPopup, showDeclineConfirmationPopup } = this.state;
    if (!selectedRequestsIds.length) {
      return null;
    }

    return (
      <>
        <ApproveDeclineActionBar
          onUncheckAll={onUncheckAll}
          selectedItems={selectedRequestsIds}
          onApprove={this.toggleApproveConfirmationPopup}
          onDelcine={this.toggleDeclineConfirmationPopup}
        />

        <ModalDialog isOpen={showDeclineConfirmationPopup} onClose={this.toggleDeclineConfirmationPopup}>
          <DeclineReasonPopup
            t={t}
            title={t("decline-multiple-activity-title")}
            text={t("decline-multiple-activity-description")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={this.toggleDeclineConfirmationPopup}
            onYes={(declineReason) => this.declineMultipleRequests(declineReason)}
          />
        </ModalDialog>
        <ModalDialog isOpen={showApproveConfirmationPopup} onClose={this.toggleApproveConfirmationPopup}>
          <Lightbox
            title={t("approve-multiple-activity-title")}
            text={t("approve-multiple-activity-description")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={this.toggleApproveConfirmationPopup}
            onYes={this.approveMultipleRequests}
          />
        </ModalDialog>
      </>
    );
  };
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(RequestActionsBar);
