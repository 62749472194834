import { Request, RequestSubtypeSpecificFieldTypes } from "types/models/request";
import { PayedOvertimePhase, PhaseType } from "types/models/businessRulesGroup";
import { getPhasesDays, minsToHrsMins } from "utils/common";
import { Fragment } from "react";
import FieldWrapper from "components/UI/FieldWrapper";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";

const FieldValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
`;

type SubtypeSpecificFieldsHBAdjustmentProps = {
  request: Request;
  phases: PayedOvertimePhase[];
};

const SubtypeSpecificFieldsHBAdjustment = ({ request, phases }: SubtypeSpecificFieldsHBAdjustmentProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  const mappedFields = request.subtypeSpecificFields?.length
    ? request.subtypeSpecificFields
        .filter((f) => f.value)
        .map((f) => {
          const isAdjustment = f.name === RequestSubtypeSpecificFieldTypes.adjustment;
          let phaseName = "";

          if (isAdjustment) {
            if (f.value.phaseId !== "negative") {
              const phase = phases.find((p) => p.uuid === f.value.phaseId);
              if (phase?.customName) {
                phaseName = phase.customName;
              } else if (phase) {
                const { daysMasksObj } = getPhasesDays({
                  t,
                  isDayTypeBasedOnSchedule: !!phase.dayTypeBasedOnSchedule,
                });

                phaseName = `${daysMasksObj[(phase.dayTypeBasedOnSchedule || phase.daysMask) as string]} ${
                  phase.name
                }%`;
                if (phase.type !== PhaseType.combined) {
                  phaseName += ` (${t(phase.type)})`;
                }
              }
            }
          }
          return {
            name: f.name,
            value: f.value,
            phaseName,
            isAdjustment,
          };
        })
    : [];

  return (
    <>
      {mappedFields.map((f) => (
        <Fragment key={f.name}>
          {f.isAdjustment && f.phaseName && (
            <FieldWrapper fieldName={t("Phase")} width="100%" fieldTitleMarginTop={20}>
              {f.phaseName}
            </FieldWrapper>
          )}

          <FieldWrapper fieldName={t(`${f.name}_field_name`)} width="100%" fieldTitleMarginTop={20}>
            <FieldValue>{f.isAdjustment ? minsToHrsMins((f.value.phaseValue as number) || 0) : f.value}</FieldValue>
          </FieldWrapper>
        </Fragment>
      ))}
    </>
  );
};

export default SubtypeSpecificFieldsHBAdjustment;
