import { Component, PropsWithChildren, ReactElement, MouseEvent } from "react";
import styled from "styled-components";
import BEM from "utils/BEM";
import Button, { ButtonState } from "components/controls/StyledButton";

const b = BEM.b("lightbox");

const Title = styled.div`
  font-size: 25px;
  color: var(--colors-mainText);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 15px;
  color: #a1b2cf;
  text-align: center;
  line-height: 19px;
  white-space: pre-wrap;
`;

const Buttons = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  &.lightbox__buttons_one {
    justify-content: center;
  }
`;

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;

interface LightboxProps extends PropsWithChildren {
  onClose: () => void;
  // does not trigger onClose
  onYes: () => void;
  titleMargin: string;
  title: string | ReactElement;
  buttonYesTitle: string;
  buttonCancelTitle?: string;
  yesDisabled?: boolean;
  noCancelButton?: boolean;
  text?: string;
  showLoading: boolean;
}

interface LightboxState {
  isLoading: boolean;
}

class Lightbox extends Component<LightboxProps, LightboxState> {
  static defaultProps = {
    titleMargin: "35px",
    showLoading: true,
  };

  readonly state: Readonly<LightboxState> = {
    isLoading: false,
  };

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    this.closePopup();
  };

  onYes = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    const { showLoading } = this.props;
    const { isLoading } = this.state;

    if (!isLoading) {
      if (showLoading) {
        this.setState({ isLoading: true });
      }
      this.props.onYes();
    }
  };

  render() {
    const { titleMargin, text, title, buttonYesTitle, buttonCancelTitle, children, yesDisabled, noCancelButton } =
      this.props;
    const { isLoading } = this.state;

    return (
      <div className={b()}>
        <Title style={{ marginBottom: titleMargin }}>{title}</Title>

        {text ? <Text>{text}</Text> : children}

        <Buttons className={b("buttons", { one: noCancelButton })}>
          {!noCancelButton && (
            <>
              <Button state={ButtonState.secondary} onClick={this.onCancel} value={buttonCancelTitle} />
              <Spacer />
            </>
          )}
          <Button
            state={ButtonState.primary}
            disabled={yesDisabled}
            loading={isLoading}
            onClick={this.onYes}
            value={buttonYesTitle}
          />
        </Buttons>
      </div>
    );
  }
}

export default Lightbox;
