import socketIOClient from "socket.io-client"; // Using v2. Cannot update client version without updating the server https://socket.io/docs/v4/troubleshooting-connection-issues/#the-client-is-not-compatible-with-the-version-of-the-server
import { getStoredAuthToken } from "utils/common";
import cookie from "react-cookies";
import CONFIG from "../config";

let socket: SocketIOClient.Socket | null;

async function socketsConnect(authToken?: string) {
  const email = cookie.load("userEmail");
  const token = authToken || cookie.load("userToken");
  const auth = getStoredAuthToken();
  socket = socketIOClient(`wss://${CONFIG.api_admin!}`, {
    path: "/message_bus",
    query: { token: auth || window.btoa([token, email].join(":")) },
    transports: ["websocket"],
    secure: true,
  });
}

export async function getSocketIo(token?: string): Promise<SocketIOClient.Socket> {
  if (socket != null) {
    return socket;
  }

  await socketsConnect(token);

  return socket!;
}
