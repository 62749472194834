import { Component } from "react";
import BEM from "utils/BEM";
import "../UI/Page/Page.scss";
import "./styles/subsidiaries-page.scss";
import { withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import SettingsLayout from "components/Layout/SettingsLayout";
import SearchInput from "components/UI/SearchInput";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { getTitle, strIncludesCheck, getCompanyTaxPayerType, getCompanyTaxIdTranslation } from "utils/common";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { addSubsidiary, updateSubsidiary, deleteSubsidiary } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ButtonState } from "components/controls/StyledButton";
import TablePage from "../TablePage";
import NotificationRow from "../NotificationRow";
import PopupMessage from "./SubsidiariesPopupMessage";
import NoContent from "../NoContent";
import * as images from "../svg-images";

const b = BEM.b("subsidiaries-page");

class SubsidiariesPage extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      notification: "",
      searchValue: "",
      selectedItem: null,
      popupVisible: false,
      confirmationPopupVisible: false,
      isFetching: false,
    };
    document.title = getTitle(t("Subsidiaries"));
  }

  componentDidMount() {
    this.getState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState();
  }

  getState = async () => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true });
      const subsidiaries = await this.context.getSubsidiaries(true);
      this.setState({
        items: subsidiaries || [],
        isFetching: false,
        confirmationPopupVisible: false,
        selectedItem: null,
        popupVisible: false,
      });
    }
  };

  onRemoveItemClick = (item) => {
    this.setState({ confirmationPopupVisible: true, selectedItem: item });
  };

  removeItem(item) {
    const { t } = this.props;
    deleteSubsidiary({ id: item.id })
      .then(() => {
        this.setState({ notification: `${t("You deleted")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }

  onEditItemClick = (item) => {
    this.setState({ popupVisible: true, selectedItem: item });
  };

  saveItem = (item) => {
    const { t } = this.props;
    updateSubsidiary({
      id: item.id,
      body: {
        subsidiary: { ...item, tax_payer_id: item.taxPayerId },
      },
    })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  };

  addItem = (item) => {
    const { t } = this.props;
    addSubsidiary({ body: { subsidiary: { ...item, tax_payer_id: item.taxPayerId } } })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  };

  addItemClick = () => {
    this.setState({ popupVisible: true, selectedItem: null });
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns() {
    const { t } = this.props;
    const taxPayerIdType = getCompanyTaxPayerType(window.global_store.profile?.company?.coutry);

    const columns = [
      {
        label: t("Code"),
        accessor: "code",
        width: 100,
        style: { lineHeight: "50px" },
      },
      {
        label: t("Name"),
        accessor: "name",
        width: 330,
      },
      {
        label: getCompanyTaxIdTranslation(taxPayerIdType, t),
        accessor: "tax_payer_id",
        minWidth: 50,
      },
      {
        label: t("Employees"),
        accessor: "employees_count",
        Cell: (row) => (
          <div>
            {row.value}
            <TableButtons className="buttons">
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onEditItemClick(row.original);
                }}
              >
                {t("common|Edit")}
              </TableButton>
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onRemoveItemClick(row.original);
                }}
              >
                {images.trashIcon}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 50,
      },
    ];
    return columns;
  }

  render() {
    const { items, searchValue, selectedItem, popupVisible, confirmationPopupVisible } = this.state;
    const { t } = this.props;

    const filteredItems = items.filter((s) => {
      const { name = "", code = "", tax_payer_id = "" } = s;
      const filter =
        strIncludesCheck(name, searchValue) ||
        strIncludesCheck(code, searchValue) ||
        strIncludesCheck(tax_payer_id, searchValue);
      return filter;
    });

    return (
      <SettingsLayout
        title={t(`${TranslationNamespaces.sidebar}|Company Structure`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("Add a subsidiary")}
            onClick={this.addItemClick}
          />
        }
      >
        <PageWrapper>
          {this.state.notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={this.state.notification} />
          )}

          <div className={b()}>
            <TablePage
              rows={filteredItems}
              filters={
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search")}
                  value={searchValue}
                />
              }
              columnSelectorOnFiltersRow
              columns={this.getTableColumns()}
              className="subsidiaries-table"
              loading={this.state.isFetching}
              noContentComponent={<NoContent>{t("No Subsidiaries are available yet.")}</NoContent>}
            />
          </div>
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              title={selectedItem ? t("Edit a Subsidiary") : t("New Subsidiary Company")}
              item={selectedItem || {}}
              allItems={items}
              buttonYesTitle={selectedItem ? t("Save") : t("Submit")}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={selectedItem ? this.saveItem : this.addItem}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Remove Subsidiary.")}
              text={t("Are you sure to remove the Subsidiary for your company?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({ selectedItem: null, confirmationPopupVisible: false });
              }}
              onYes={() => this.removeItem(selectedItem)}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}
export default withTranslation([TranslationNamespaces.subsidiaries, TranslationNamespaces.sidebar])(SubsidiariesPage);
