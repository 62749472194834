import styled from "styled-components";
import Tooltip from "../../Tooltip";

type CellWithSubTextProps = {
  mainText: string;
  subText: string;
  tooltipText: string;
  uniqueId: string;
};
const CellWrapper = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  justify-content: center;
  height: 47px;
`;

const CellMainText = styled.div`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-900-p);
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CellSubText = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: var(--colors-surface-700);
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const MultilineRenderer = ({ mainText, subText, tooltipText, uniqueId }: CellWithSubTextProps): JSX.Element => (
  <CellWrapper>
    <CellMainText data-tip data-for={uniqueId} title={tooltipText && uniqueId ? "" : mainText}>
      {mainText}
    </CellMainText>
    {tooltipText && uniqueId && <Tooltip id={uniqueId}>{tooltipText}</Tooltip>}
    {subText && <CellSubText title={subText}>{subText}</CellSubText>}
  </CellWrapper>
);
