import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteChildrenProps } from "react-router-dom";
import styled from "styled-components";
import SettingsLayout from "components/Layout/SettingsLayout";
import PageLayout from "components/UI/Page/Layout";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { HeaderAction } from "components/styled/Page";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Location, LocationClient, LocationType } from "types/models/location";
import ModalDialog from "components/UI/ModalDialog";
import { getClients, getLocations, getLocationSuggestedCode } from "utils/apiHelpers";
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import { ButtonState } from "components/controls/StyledButton";
import LocationsList, { LocationFilters, SearchStatus } from "./LocationsList";
import LocationsEditForm from "./LocationsEditForm";

const Wrapper = styled.div`
  .locations-edit-popup {
    .locations-edit-form {
      text-align: start;
    }

    .checkbox-ios-conrtrol__wrapper {
      height: 26px;
      padding-inline-end: 2px;
    }

    .location-manager {
      margin-bottom: 50px;
    }
  }
`;

const Title = styled.h1`
  font-size: 25px;
  color: var(--colors-surface-900-p);
  font-weight: var(--typography-font-weight-default);
  text-align: center;
  margin: 20px 0 40px;
`;

interface LocationsPageProps extends WithTranslation, RouteChildrenProps {
  isJobSites?: boolean;
}

interface LocationsPageState extends LocationFilters {
  locations: Location[];
  clients: LocationClient[];
  isLoading: boolean;
  totalRecords: number;
  showCreatePopup: boolean;
  notification: string | null;
  notificationType: NotificationType | null;
  suggestedCode: number | null;
}

class LocationsPage extends Component<LocationsPageProps, LocationsPageState> {
  constructor(props: LocationsPageProps) {
    super(props);

    this.state = {
      showCreatePopup: false,
      locations: [],
      page: 1,
      perPage: 25,
      clients: [],
      isLoading: true,
      totalRecords: 0,
      searchValue: "",
      validations: [],
      status: SearchStatus.active,
      notification: null,
      notificationType: null,
      suggestedCode: null,
    };
  }

  componentDidMount() {
    const promises = [this.getLocations(), this.getSuggestedCode()];

    if (this.props.isJobSites) {
      promises.push(this.getClients());
    }

    void Promise.allSettled(promises);
  }

  getLocations = async () => {
    const { isJobSites, t } = this.props;
    const { page, perPage, validations, status, searchValue } = this.state;

    try {
      const res = await getLocations({
        page,
        perPage,
        active: status ? status === SearchStatus.active : undefined,
        verificationMethods: validations,
        searchTerm: searchValue,
        locationTypes: isJobSites ? [LocationType.jobSite] : [LocationType.workplace, LocationType.homeOffice],
      });

      void this.setState({
        totalRecords: +res.pagingTotalCount,
        locations: res.locations,
        isLoading: false,
      });
    } catch (error) {
      const err = error as any;
      this.setNotification(err?.data ? t(err.data) : t("Failed to load locations"), NotificationType.error);
    }
  };

  getClients = async (): Promise<void> => {
    const { t } = this.props;
    try {
      const res = await getClients();
      void this.setState({ clients: res.clients });
    } catch (error) {
      const err = error as any;
      this.setNotification(err?.data ? t(err.data) : t("Failed to load clients"), NotificationType.error);
    }
  };

  getSuggestedCode = async () => {
    const { isJobSites } = this.props;
    const { code } = await getLocationSuggestedCode(isJobSites ? LocationType.jobSite : LocationType.workplace);

    this.setState({ suggestedCode: parseInt(code, 10) });
  };

  setFilters = (data: Pick<LocationsPageState, keyof LocationFilters>) => {
    // TODO if searchValue = make a delay

    this.setState(data, this.getLocations);
  };

  setNotification = (notification: string, notificationType: NotificationType) => {
    this.setState({ notification, notificationType });
  };

  toggleCreatePopup = (showCreatePopup: boolean) => {
    this.setState({ showCreatePopup });
  };

  render() {
    const { t, isJobSites } = this.props;
    const {
      showCreatePopup,
      locations,
      isLoading,
      perPage,
      page,
      clients,
      totalRecords,
      validations,
      status,
      searchValue,
      notification,
      notificationType,
      suggestedCode,
    } = this.state;

    let title = "Locations";
    let createButtonTitle = "Create Location";

    if (isJobSites) {
      title = "Job Sites";
      createButtonTitle = "Create Job Site";
    }

    return (
      <SettingsLayout
        title={t("Locations")}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(createButtonTitle)}
            onClick={() => this.toggleCreatePopup(true)}
          />
        }
      >
        <PageLayout
          title={t(title)}
          actions={
            <HeaderAction>
              <HeaderActionButtonAdd title={t(createButtonTitle)} onClick={() => this.toggleCreatePopup(true)} />
            </HeaderAction>
          }
        >
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType}
              onClose={() => this.setState({ notification: null, notificationType: null })}
              message={t(notification as string)}
            />
          )}
          <LocationsList
            isJobSites={isJobSites}
            locations={locations}
            setFilters={this.setFilters}
            perPage={perPage}
            page={page}
            clients={clients}
            isLoading={isLoading}
            totalRecords={totalRecords}
            validations={validations}
            status={status}
            searchValue={searchValue}
          />

          <ModalDialog isOpen={showCreatePopup} onClose={() => this.toggleCreatePopup(false)}>
            <Wrapper>
              <Title>{t("Create a Location")}</Title>
              <LocationsEditForm
                isJobSites={!!isJobSites}
                onCancel={() => this.toggleCreatePopup(false)}
                onYes={() => {
                  this.toggleCreatePopup(false);
                  void this.getLocations();
                }}
                setNotification={this.setNotification}
                locationsTotalCount={totalRecords}
                clients={clients}
                suggestedCode={suggestedCode}
              />
            </Wrapper>
          </ModalDialog>
        </PageLayout>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(LocationsPage);
