import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import ChangePasswordPage from "components/Onboarding/ChangePasswordPage";
import { useHubspot } from "hooks/useHubspot";

const ChangePasswordNew = ({ match: { path } }) => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path="/new-password" component={ChangePasswordPage} />
      </Switch>
    </Suspense>
  );
};

export default ChangePasswordNew;
