import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import { searchEmployeesAndGroups, getEmployees } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import { GetEmployeesRequestArgs, SearchEmployeesAndGroupsRequestData } from "utils/api/types";
import { listUserProfilesWIthFilters } from "utils/api/company";
import {
  FilteredEmployeePayload,
  FilteredEmployeeProfile,
  GlobalContextEmployee,
  GroupSearchUserProfile,
  SearchEmployeeUserProfile,
  UserProfileStatus,
} from "types/models/userProfile";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { GroupSearchDepartment } from "types/models/department";
import { Service } from "types/models/projects";
import { GroupSearchTeam } from "types/models/team";
import { GroupSearchSubsidiary } from "types/models/subsidiary";
import { searchEmployeesAndServices } from "components/Projects/projectsApiUtils";
import { MassActionLocations } from "utils/ga";
import Dropdown, { SearchControlOnChangeData } from "./Dropdown";
import { SearchControlItemType, SearchControlItem } from "./Item";
import { SearchNavClicker } from "./SearchNav";
import "./search-control.scss";

export type { SearchControlOnChangeData, SearchControlItem };
export { SearchControlItemType };

const InputWrapper = styled.div`
  position: relative;
  .search-control-new__input-field_newStyle {
    padding-inline-start: 57px;
    padding-inline-end: 30px;
  }
  .search-control-new__input-field_newStyle.search-control-new_with-controls {
    padding-inline-end: 80px;
  }
`;

export type AllSearchObjects = SearchControlOnChangeData | GlobalContextEmployee | GlobalContextEmployee[];

interface SearchControlProps extends WithTranslation {
  /** should be undefined if withMulitple === true */
  value: string | undefined | null;
  placeholder?: string;
  /** if users have been selected via MultipleEmployeeSelector then data is GlobalContextEmployee | GlobalContextEmployee[]. */
  onChange?: (data: AllSearchObjects) => void;
  /** callback called after clear */
  onClear?: () => void;
  /** show navigation controls for prev and next employees */
  withNavigateControls?: boolean;
  /** current employee id if navigation enabled */
  id?: number;
  /** show supervisor direct employees only */
  directReportsOnly?: boolean;
  /** search employees and groups TODO can interchange with showAllGroups? */
  searchGroups?: boolean;
  /** no employee entities in the output if searchGroups: true */
  groupsOnly?: boolean;
  /** show groups TODO can interchange with searchGroups?  */
  showAllGroups?: boolean;
  /** show "All" section */
  showAllEmployeesItem?: boolean;
  /** filter out deactivated employees */
  onlyActive?: boolean;
  /** additional specific case styling */
  scheduleAdd?: boolean;
  /** allow to add multiple employees */
  withMultiple?: boolean;
  /** track location where component is called */
  trackingLocation?: MassActionLocations;
  /** disabled input */
  locked?: boolean;
  /** don't show employer in results dropdown */
  skipEmployer?: boolean;
  /** search employees and services */
  searchServices?: boolean;
  /** permission section for new hierarchy search */
  permissionSection: PermissionSectionName;
}
const paginationLimit = 100;

interface SearchControlState {
  value: string;
  employees: (SearchEmployeeUserProfile | GroupSearchUserProfile)[];
  navEmployees: FilteredEmployeeProfile[];
  teams: GroupSearchTeam[];
  departments: GroupSearchDepartment[];
  multipleEmployees: FilteredEmployeeProfile[];
  subsidiaries: GroupSearchSubsidiary[];
  services: Service[];
  isLoading: boolean;
  offset: number;
  total: number;
}

class SearchControl extends Component<SearchControlProps, SearchControlState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  searchTimeout: NodeJS.Timeout | null = null;
  bgLoading = false;

  constructor(props: SearchControlProps) {
    super(props);
    this.state = {
      value: props.value || "",
      employees: [],
      navEmployees: [],
      teams: [],
      departments: [],
      multipleEmployees: [],
      subsidiaries: [],
      services: [],
      isLoading: false,
      offset: 0,
      total: 0,
    };
  }

  async getEmployees(customOffset = 0) {
    const company = await this.context.getCompany();
    const payload: FilteredEmployeePayload = {
      requestedBy: window.global_store.profile.uuid,
      skip: customOffset,
      limit: paginationLimit,
      fields: ["id", "uuid", "fullName", "avatarId", "employeeStatus", "position.title", "team.uuid", "team.name"],
      externalFields: [],
      filterModel: {
        employee_status: { filterType: "set", values: ["invited", "active"] },
      },
    };

    const profileTeam = window.global_store.profile.teams[0]?.uuid;
    if (this.props.directReportsOnly) {
      payload.filterModel["teams.uuid"] = { filterType: "text", type: "equals", filter: profileTeam };
      payload.includeSelf = true;
    }
    const { content: data, metadata } = await listUserProfilesWIthFilters(company.uuid, payload);
    let navEmployees = data || [];

    navEmployees = navEmployees.map((e) => ({ ...e, status: e.employeeStatus, name: e.fullName }));

    this.setState({
      isLoading: false,
      navEmployees,
      total: metadata.total,
    });
  }

  componentDidMount() {
    const { withNavigateControls } = this.props;

    if (withNavigateControls) {
      this.setState({ isLoading: true }, this.getEmployees);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: SearchControlProps) {
    if (typeof nextProps.value !== "undefined") {
      if (!nextProps.value) {
        this.setState({
          value: "",
          employees: [],
          teams: [],
          departments: [],
          subsidiaries: [],
          multipleEmployees: [],
          services: [],
        });
      } else if (nextProps.value !== this.props.value) {
        this.setState({
          value: nextProps.value,
        });
      }
    }
  }

  getSearchResults = (term: string) => {
    if (term && term.length > 2) {
      this.setState({ isLoading: true }, () => {
        const { searchGroups, groupsOnly, searchServices } = this.props;
        clearTimeout(this.searchTimeout || undefined);
        if (searchGroups) {
          void this.fetchEmployeesAndGroups(term, groupsOnly);
        } else if (searchServices) {
          void this.fetchEmployeesAndServices(term);
        } else {
          void this.fetchEmployees(term);
        }
      });
    } else {
      this.setState({
        employees: [],
        departments: [],
        subsidiaries: [],
        teams: [],
        services: [],
      });
    }

    this.setState({ value: term });
  };

  onValueChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.getSearchResults(ev.target.value);
  };

  onClear = () => {
    this.setState(
      {
        value: "",
        employees: [],
        teams: [],
        departments: [],
        subsidiaries: [],
        multipleEmployees: [],
        services: [],
      },
      () => {
        const { onClear } = this.props;

        if (onClear) {
          onClear();
        }
      },
    );
  };

  fetchEmployeesAndGroups = (term: string, groupsOnly?: boolean) => {
    this.searchTimeout = setTimeout(async () => {
      try {
        const { permissionSection } = this.props;
        const payload: SearchEmployeesAndGroupsRequestData = {
          term,
        };

        payload.permission_section_name = permissionSection;

        const resp = await searchEmployeesAndGroups(payload);
        const { teams, departments, subsidiaries } = resp;
        let { employees } = resp;

        if (groupsOnly) {
          employees = [];
        }

        if (employees.length > 0 || teams.length > 0 || departments.length > 0 || subsidiaries.length > 0) {
          this.setState({ teams, departments, subsidiaries, employees, isLoading: false });
        } else {
          this.setState({
            employees: [],
            teams: [],
            departments: [],
            subsidiaries: [],
            isLoading: false,
          });
        }
      } catch (err) {
        Sentry.sendError(err);
      }
    }, 600);
  };

  fetchEmployees = (term: string) => {
    this.searchTimeout = setTimeout(async () => {
      const { permissionSection } = this.props;
      const company = await this.context.getCompany();

      if (!company) {
        return;
      }

      const args: GetEmployeesRequestArgs = {
        term,
        requestedBy: window.global_store.profile.uuid,
      };

      args.permission_section_name = permissionSection;

      const resp = await getEmployees({
        companyUuid: company.uuid,
        args,
      });

      let employees: SearchEmployeeUserProfile[] = [];

      if (resp?.user_profiles?.length) {
        employees = resp.user_profiles;
      } else if (resp?.content?.length || resp?.content?.employees?.length) {
        const list = (resp?.content?.employees || resp.content) as SearchEmployeeUserProfile[];

        employees = list.map((emp) => {
          const newEmp = { ...emp };

          newEmp.full_name = newEmp.name;
          newEmp.avatar_id = newEmp.avatarId;
          newEmp.employee_status = newEmp.status;
          newEmp.job_description = newEmp.legacyRoleName;

          return newEmp;
        });
      }

      this.setState({ employees, isLoading: false });
    }, 600);
  };

  fetchEmployeesAndServices = (term: string) => {
    this.searchTimeout = setTimeout(async () => {
      const company = await this.context.getCompany();
      if (!company) {
        return;
      }

      const resp = await searchEmployeesAndServices({
        companyUuid: company.uuid,
        args: {
          requestedBy: window.global_store.profile.uuid,
          term,
        },
      });

      const { employees, services } = resp;

      this.setState({
        employees: employees.map((emp) => {
          const newEmp = { ...emp };

          newEmp.full_name = newEmp.name;
          newEmp.avatar_id = newEmp.avatarId;
          newEmp.employee_status = newEmp.status;
          newEmp.job_description = newEmp.legacyRoleName;

          return newEmp;
        }),
        services,
        isLoading: false,
      });
    }, 600);
  };

  onDropdownItemClick = (item: SearchControlOnChangeData) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(item);
    }

    this.setState({ value: item.label });
  };

  onNavClick = (profile: GlobalContextEmployee) => {
    this.onDropdownItemClick({
      employee: profile,
      id: profile.id,
      label: profile.name,
      uuid: profile.uuid,
    });
  };

  addMultiple = ({ list }: { list: FilteredEmployeeProfile[] }) => {
    const { t, onChange } = this.props;

    if (!list || list.length === 0) {
      this.onClear();
    } else if (list.length === 1) {
      this.setState(
        {
          value: list[0].fullName,
          multipleEmployees: [],
        },
        () => {
          if (onChange) {
            // @ts-expect-error TODO change interface to the new employee API
            onChange(list[0]);
          }
        },
      );
    } else {
      this.setState(
        {
          multipleEmployees: list,
          value: `${list.length} ${t("Employees")}`,
        },
        () => {
          if (onChange) {
            // @ts-expect-error TODO change interface to the new employee API
            onChange(list);
          }
        },
      );
    }
  };

  changePosition(cursorIndex: number) {
    // todo fix typings that comes from different requests
    this.onNavClick(this.state.navEmployees[cursorIndex] as unknown as GlobalContextEmployee);
    const currentLimit = this.state.offset + paginationLimit;
    if (!this.bgLoading && this.state.total > currentLimit && cursorIndex + 10 > currentLimit) {
      this.bgLoading = true;
      void this.getEmployees(currentLimit).then(() => {
        this.setState({ offset: currentLimit });
        this.bgLoading = false;
      });
    }
  }

  render() {
    const { employees, teams, departments, subsidiaries, services, value, navEmployees, multipleEmployees, isLoading } =
      this.state;

    const {
      placeholder,
      showAllEmployeesItem,
      onlyActive,
      scheduleAdd,
      withNavigateControls,
      showAllGroups,
      withMultiple,
      trackingLocation,
      skipEmployer,
      locked,
      permissionSection,
    } = this.props;

    let filteredEmployees = employees;
    if (skipEmployer) {
      filteredEmployees = filteredEmployees.filter(
        (e) =>
          // Goup search  employee entity does not have permissionRoles property
          !(e as SearchEmployeeUserProfile).permissionRoles?.some(
            (pr) => pr.name.toLowerCase() === PermissionRoleName.owner.toLowerCase(),
          ),
      );
    }
    filteredEmployees = filteredEmployees.filter((p) => p.employee_status !== UserProfileStatus.deactivated);
    filteredEmployees = filteredEmployees.slice(0, 6);

    let items: SearchControlItem[] = [];
    if (showAllGroups) {
      items.push({ value: "all", obj: null, type: SearchControlItemType.all });
      items.push({ value: "departments", obj: null, type: SearchControlItemType.departments });
      items.push({ value: "supervisors", obj: null, type: SearchControlItemType.supervisors });
      items.push({ value: "teams", obj: null, type: SearchControlItemType.teams });
      items.push({ value: "subsidiaries", obj: null, type: SearchControlItemType.subsidiaries });
    } else if (showAllEmployeesItem) {
      items.push({ value: "all", obj: null, type: SearchControlItemType.all });
    }

    items = items.concat(
      filteredEmployees.map((emp) => ({
        value: emp.uuid,
        obj: emp,
        type: SearchControlItemType.employee,
      })),
    );
    items = items.concat(
      departments.map((dep) => ({
        value: dep.uuid,
        obj: dep,
        type: SearchControlItemType.department,
      })),
    );
    items = items.concat(
      subsidiaries.map((sub) => ({
        value: sub.uuid,
        obj: sub,
        type: SearchControlItemType.subsidiary,
      })),
    );
    items = items.concat(teams.map((team) => ({ value: team.uuid, obj: team, type: SearchControlItemType.team })));
    items = items.concat(
      services.map((service) => ({ value: service.uuid, obj: service, type: SearchControlItemType.services })),
    );

    if (!onlyActive) {
      const deactivated = employees
        .filter((emp) => emp.employee_status === UserProfileStatus.deactivated)
        .map((emp, i) => ({
          value: emp.uuid,
          obj: emp,
          type: SearchControlItemType.employee,
          inactive: true,
          withSeparator: i === 0,
        }));
      items = items.concat(deactivated);
    }

    return (
      <InputWrapper>
        <Dropdown
          locked={locked}
          loading={isLoading}
          scheduleAdd={scheduleAdd}
          items={items}
          withMultiple={withMultiple}
          trackingLocation={trackingLocation}
          placeholder={placeholder}
          selectedItem={value}
          multipleEmployees={multipleEmployees}
          onAddMultiple={this.addMultiple}
          onClear={this.onClear}
          onItemChange={this.onDropdownItemClick}
          withNavigateControls={withNavigateControls}
          getSearchResults={this.onValueChange}
          permissionSection={permissionSection}
        />

        {withNavigateControls && navEmployees && (
          <SearchNavClicker total={this.state.total} onClick={this.changePosition} isLoading={isLoading} />
        )}
      </InputWrapper>
    );
  }
}

export default withTranslation()(SearchControl);
