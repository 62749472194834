import { ColumnAlign } from "components/TableCommon";
import { WithTranslation } from "react-i18next";
import { PhaseDayType, PhaseType, ReportDetailedActivePhase } from "types/models/businessRulesGroup";
import { ReportsDetailedTableData } from "types/models/reports/detailed(payroll)";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getPhasesDays, minsToHrsMins } from "utils/common";
import { iCellInfo } from "utils/tableHelpers";

export const EVENT_REQUESTS_REGEX =
  /(request_types_hours|request_types_days|request_subtypes_hours|request_subtypes_days)\[([^\]]+)\]/;
export const EXTRA_HOUTS_REGEX = /(extra_hours_phases)\[([^\]]+)\]/;

export const sliceReportIntoChunks = <T>(data: T[], perPage: number) => {
  const chunks: T[][] = [];

  for (let i = 0; i < data.length; i += perPage) {
    chunks.push(data.slice(i, i + perPage));
  }

  return chunks;
};

export enum ReportActvitiesStatus {
  approved = "approved",
  all = "approved,pending,submitted",
}

export enum PhasesType {
  hoursBank = "hours_bank",
  extraHours = "extra_hours",
}

export const getPhasesLabel = (t: WithTranslation["t"], brGroupType: PhasesType, phase: ReportDetailedActivePhase) => {
  if (phase.customName) {
    return phase.customName;
  }

  const isDayTypeBasedOnSchedule = !!phase.dayTypeBasedOnSchedule;
  const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
  const columnLabel =
    brGroupType === PhasesType.hoursBank
      ? t(`${TranslationNamespaces.reportsPage}|HB`)
      : t(`${TranslationNamespaces.reportsPage}|EH`);

  const dayNight = phase.type !== PhaseType.combined ? ` - (${t(phase.type)})` : "";

  return `${phase.name}% ${columnLabel} ${
    phase.dayTypeBasedOnSchedule === PhaseDayType.dsr
      ? t(`${TranslationNamespaces.phases}|${phase.dayTypeBasedOnSchedule}`)
      : getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[phase[key]]
  }${dayNight}`;
};

export function getPhasesColumnsForSingleReport(
  availablePhases: ReportDetailedActivePhase[],
  t: WithTranslation["t"],
  phasesTotals: {
    [uuid: string]: number;
  },
  phasesType: PhasesType,
) {
  const columnGroupLabel =
    phasesType === PhasesType.hoursBank
      ? t(`${TranslationNamespaces.reportsPage}|HB Phases`)
      : t(`${TranslationNamespaces.reportsPage}|HE Phases`);
  const columnAccessor = phasesType === PhasesType.hoursBank ? "hours_bank_phases" : "extra_hours_phases";

  const ehPhasesColumns = availablePhases?.length
    ? availablePhases.map((phase) => ({
        accessor: columnAccessor,
        groupLabel: columnGroupLabel,
        label: getPhasesLabel(t, phasesType, phase),
        Cell: (row: iCellInfo<ReportsDetailedTableData>) => {
          let rowPhases: Record<string, number> = {};
          if (phasesType === PhasesType.extraHours) {
            rowPhases = row.original.extraHoursPhases;
          } else if (phasesType === PhasesType.hoursBank) {
            rowPhases = row.original.hoursBankPhases;
          }
          const hours = rowPhases[phase.uuid] || 0;
          return minsToHrsMins(hours);
        },
        Footer: minsToHrsMins(phasesTotals[phase.uuid] || 0),
        align: "end" as ColumnAlign,
        minWidth: 100,
      }))
    : [];
  return ehPhasesColumns;
}
