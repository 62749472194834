import { Key, ReactNode } from "react";
import { WithTranslation } from "react-i18next";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TabsComponent from "components/UI/TabsComponent";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import HeaderAction from "components/Layout/HeaderAction";

const Wrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  border-bottom: solid 2px var(--colors-surface-150);
  list-style: none;
`;

const TabsBar = styled.div`
  display: flex;
`;

const Tab = styled.li<{ $active: boolean }>`
  margin: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-bottom: solid 2px transparent;
  font-size: 15px;
  color: ${(p) => (p.$active ? "var(--colors-mainText)" : "var(--colors-surface-600)")};
  font-weight: ${(p) => (p.$active ? "500" : "normal")};
  border-bottom-color: ${(p) => (p.$active ? "var(--colors-surface-900-p)" : "transparent")};
  cursor: pointer;
  position: relative;
  bottom: -2px;
  &:hover {
    color: var(--colors-mainText);
  }
`;

const CTA = styled.div``;

interface UITab<T> {
  name: T;
  label: string;
  hide?: boolean;
}

interface TabsProps<T = string> {
  tabs: UITab<T>[];
  value: T;
  onChange?: (activeTab: T) => void;
  t?: WithTranslation["t"];
  cta?: ReactNode;
}

/**
 * Depreacted. Refactor page to {@link HeaderAction} or use {@link TabsComponent} instead.
 * @param param0
 * @returns
 * @deprecated
 */
function Tabs<T>({ tabs, value, onChange, t, cta }: TabsProps<T>): JSX.Element {
  return (
    <Wrapper>
      <TabsBar>
        {tabs
          .filter(({ hide }) => !hide)
          .map(({ name, label }) => (
            <Tab key={name as unknown as Key} $active={name === value} onClick={(): void => onChange?.(name)}>
              {typeof t === "function" ? t(label) : label}
            </Tab>
          ))}
      </TabsBar>

      {cta && <CTA>{cta}</CTA>}
    </Wrapper>
  );
}

export default Tabs;
