import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getSelectedColumns } from "utils/tableHelpers";
import { getDateColumn, getDateCellData } from "utils/reportsHelpers";
import { minsToHrsMins } from "utils/common";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import ReportUserInfo from "./ReportUserInfo";
import TablePage from "../TablePage";
import ReportsNoContent from "./ReportsNoContent";
import "styles/reports-late-arrivals-table.scss";

const b = BEM.b("reports-late-arrivals-table");

class ReportsLateArrivalsTable extends Component {
  state = {
    loading: false,
    selectedColumns: getSelectedColumns("date,time,punch_type,reason", "ReportsLateArrivalsTable"),
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const { t, searchObj, startDate, endDate, selectedPunchType, oldReport, setIsLoading } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (searchObj && searchObj.employee && startDate && endDate && companyUUID) {
      this.setState({ loading: true }, () => setIsLoading(true));

      try {
        const resp = await getReport({
          UserProfileUUID: searchObj.employee.uuid,
          punchType: selectedPunchType,
          startDate,
          endDate,
          companyUUID,
          type: "late_arrival",
          oldReport,
        });

        this.setState({
          ...resp,
          loading: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({ error: t("Failed to generate report") });
      } finally {
        setIsLoading(false);
      }
    }
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsLateArrivalsTable", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render() {
    const { loading, error, employeeInfo, dates = [], selectedColumns } = this.state;
    const { t, searchObj, startDate, endDate, selectedPunchType, oldReport } = this.props;
    let lastScheduleName;

    if (error) {
      return <div>{error}</div>;
    }

    const data = dates.map((d) => {
      if (d.schedule) {
        lastScheduleName = d.schedule.name;
      }

      return {
        date: getDateCellData(d),
        lateMinutes: d.lateMinutes,
        punchType: d.punchType,
        reason: d.reason,
        status: d.status,
      };
    });

    const columns = [
      getDateColumn(t),
      {
        label: t("Delay"),
        accessor: "lateMinutes",
        rubyAccessor: "time",
        Cell: (row) => minsToHrsMins(row.value),
        align: "right",
        minWidth: 130,
      },
      {
        label: t("Punch"),
        accessor: "punchType",
        rubyAccessor: "punch",
        Cell: (row) => t(row.value),
        minWidth: 130,
      },
      {
        label: t("Reason"),
        accessor: "reason",
        rubyAccessor: "reason",
        minWidth: 130,
      },
    ];

    return (
      <div className={b()}>
        <TablePage
          rows={data}
          columns={columns}
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDatailsClassName="table-details-ai-start"
          tableDetails={<ReportUserInfo employeeInfo={{ ...employeeInfo, schedule: lastScheduleName }} />}
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              options={[
                { label: "PDF", value: "pdf" },
                { label: "XLSX", value: "xlsx.xlsx" },
              ]}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  oldReport,
                  punchType: selectedPunchType,
                  searchObj,
                  startDate,
                  endDate,
                  reportType: selectedColumns ? "late_arrival" : "latearrival",
                })
              }
            />
          }
          className="reports-hoursbank-table"
          loading={loading}
          interactive={false}
          noContentComponent={<ReportsNoContent />}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("reports-page")(ReportsLateArrivalsTable));
