/**
 * This is the element for one entry for one series name in tooltip
 * Bassicaly this is the text after color dots;
 */
export default `
  <div class="ttEntry entryValue-$dataValue">
    <div class="ttLabel">$label</div>

    <span class="ttSeries">$seriesName</span>
    <span class="ttValue">$dataValue</span>
  </div>
`;
