import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSelectedColumns } from "utils/tableHelpers";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import { ActivitiesReportAllProjectsMetadata, ActivitiesReportSingleProject, Project } from "types/models/projects";
import { getMinsToHours, getMinsToHoursDecimal } from "utils/reportsHelpers";
import { iColumn } from "components/TableCommon";
import { OnError, SetIsLoading } from "types/reports";
import {
  minsToHrsMins,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
  hasPermisionAccess,
  PermissionSectionName,
  getValueWithCurrency,
} from "utils/common";
import { getProjectsList } from "components/Projects/projectsApiUtils";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import ReportsNoContent from "./ReportsNoContent";
import { TableDetailsTitle, TableDetailsTitleDates } from "./reportsStyledComponents";
import { ReportActvitiesStatus } from "./helpers";

interface ReportsActivitiesSingleProjectProps extends RouteComponentProps, WithTranslation {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  setIsLoading: SetIsLoading;
  onError: OnError;
  projectUuid: string;
  activityStatus: ReportActvitiesStatus;
}

type ProjectActivityRow = {
  employee: string;
  totalTasks: number;
  totalHours: string;
  totalHoursDecimal: string;
  totalCost: string;
  totalEmployeeCost: string;
};

interface ReportsActivitiesSingleProjectState {
  loading: boolean;
  activities: ProjectActivityRow[];
  selectedColumns: string;
  metadata: ActivitiesReportAllProjectsMetadata | null;
  projectName: string | null;
}

const mapActivityTableData = (inputData: ActivitiesReportSingleProject[]): ProjectActivityRow[] =>
  inputData.map((row) => ({
    employee: row.userProfile.fullName,
    cpf: row.userProfile.cpf || "",
    matricula: row.userProfile.matricula || "",
    totalTasks: row.totalTasks || 0,
    totalHours: getMinsToHours(row.totalMinutes),
    totalHoursDecimal: getMinsToHoursDecimal(row.totalMinutes),
    totalCost: getValueWithCurrency(row.totalCost) || "0",
    totalEmployeeCost: getValueWithCurrency(row.totalEmployeeCost) || "0",
  }));

class ReportsActivitiesSingleProject extends Component<
  ReportsActivitiesSingleProjectProps,
  ReportsActivitiesSingleProjectState
> {
  static contextType = GlobalContext;
  constructor(props: ReportsActivitiesSingleProjectProps) {
    super(props);
    this.state = {
      loading: true,
      metadata: null,
      projectName: null,
      activities: [],
      selectedColumns: getSelectedColumns(
        "employee_name,cpf,matricula,total_tasks,total_hours,total_hours_decimal,total_cost",
        "ReportsActivitiesSingleProject",
      ),
    };
  }

  componentDidMount(): void {
    void this.getReportData();
  }

  getReportData = async (): Promise<void> => {
    const { t, startDate, endDate, projectUuid, setIsLoading, onError, activityStatus } = this.props;
    const company = await this.context.getCompany();

    if (startDate && endDate && company.uuid) {
      this.setState({ loading: true });

      setIsLoading(true);

      try {
        const response = await getReport(
          {
            startDate,
            endDate,
            companyUUID: company.uuid,
            projectUuid,
            status: activityStatus,
            type: "project_activities",
          },
          true,
        );

        const projectsResponse: { content: Project[] } = await getProjectsList({ companyUuid: company.uuid });

        const activities: ProjectActivityRow[] = mapActivityTableData(response?.content || []);
        const selectedProject = projectsResponse.content.find((proj: Project) => proj.uuid === projectUuid);

        this.setState({
          activities,
          metadata: response?.metadata || null,
          projectName: selectedProject?.name || "",
          loading: false,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error", e);

        onError(t("Failed to generate report"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = (): iColumn<ProjectActivityRow>[] => {
    const { t } = this.props;
    const { metadata } = this.state;

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns: iColumn<ProjectActivityRow>[] = [
      {
        accessor: "employee",
        rubyAccessor: "employee_name",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employee",
          `${TranslationNamespaces.common}|Employee`,
        ),
        minWidth: 130,
      },
      {
        accessor: "cpf",
        rubyAccessor: "cpf",
        label: employeeTaxIdLabel,
        minWidth: 150,
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(`${TranslationNamespaces.common}|Matricula`),
        minWidth: 150,
      },
      {
        accessor: "totalTasks",
        rubyAccessor: "total_tasks",
        label: t("Total Tasks"),
        minWidth: 130,
      },
      {
        accessor: "totalHours",
        rubyAccessor: "total_hours",
        label: t("Total Hours"),
        Footer: metadata?.totalMinutes ? minsToHrsMins(metadata.totalMinutes) : "",
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "totalHoursDecimal",
        rubyAccessor: "total_hours_decimal",
        label: t("Total Hours (Decimal)"),
        Footer: metadata?.totalMinutes ? getMinsToHoursDecimal(metadata.totalMinutes) : "",
        minWidth: 140,
        align: "end",
      },
      {
        accessor: "totalCost",
        rubyAccessor: "total_cost",
        label: t("Total Cost"),
        Footer: getValueWithCurrency(metadata?.totalCost),
        minWidth: 100,
        align: "end",
      },
    ];

    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      columns.push({
        accessor: "totalEmployeeCost",
        rubyAccessor: "total_employee_cost",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.reportsPage,
          "custom-total-employee-cost",
          `Total Employee Cost`,
        ),
        Footer: getValueWithCurrency(metadata?.totalEmployeeCost),
        minWidth: 100,
        align: "end",
      });
    }

    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsActivitiesSingleProject", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { loading, activities, selectedColumns, projectName } = this.state;
    const { t, startDate, endDate, projectUuid, activityStatus } = this.props;
    const columns = this.getColumns();

    return (
      <div>
        <TablePage<ProjectActivityRow>
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <TableDetailsTitle>{projectName}</TableDetailsTitle>
              <TableDetailsTitleDates>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </TableDetailsTitleDates>
            </>
          }
          loading={loading}
          rows={activities}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              onChange={(value: string): Promise<Record<string, unknown>> =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  startDate,
                  endDate,
                  projectUuid,
                  status: activityStatus,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "project_activities",
                })
              }
            />
          }
          noContentComponent={<ReportsNoContent />}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsActivitiesSingleProject));
