import { createPermissionRole } from "utils/apiHelpers";
import NewItem from "components/controls/NewItem";
import { PermissionRole } from "types/models/permissions";

type NewPermissionRoleProps = {
  onRoleAdded: () => void;
  allRoles: PermissionRole[];
  children: React.ReactNode;
};

const NewPermissionRole = ({ onRoleAdded, allRoles = [], children }: NewPermissionRoleProps) => {
  const applyRole = async ({
    value,
    callBackFail,
    callBackSuccess,
  }: {
    value: string;
    callBackFail: () => void;
    callBackSuccess: () => void;
  }) => {
    if (!allRoles.some((r) => r.name === value)) {
      await createPermissionRole({ role: { name: value } });
      onRoleAdded();
      callBackSuccess();
    } else {
      callBackFail();
    }
  };

  return <NewItem onApply={applyRole} label={children} />;
};

export default NewPermissionRole;
