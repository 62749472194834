import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "components/UI/Button";
import BEM from "utils/BEM";
import { LocationValidation } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import "./AddNew.scss";

const b = BEM.b("locations-validations-new");

const Option = styled.div`
  &:before {
    inset-inline-start: 0;
  }
`;

interface ValidationTypeSwitchProps extends WithTranslation {
  onSubmit: (type: LocationValidation) => void;
  onCancel: () => void;
  gpsAwailable: boolean;
  otherAvailable: boolean;
}

interface ValidationTypeSwitchState {
  selectedType: LocationValidation;
}

class ValidationTypeSwitch extends Component<ValidationTypeSwitchProps, ValidationTypeSwitchState> {
  constructor(props: ValidationTypeSwitchProps) {
    super(props);

    this.state = {
      selectedType: props.gpsAwailable ? LocationValidation.gps : LocationValidation.wifi,
    };
  }

  setType = (type: LocationValidation) => {
    this.setState({ selectedType: type });
  };

  render() {
    const { onCancel, onSubmit, gpsAwailable, otherAvailable, t } = this.props;
    const { selectedType } = this.state;

    return (
      <div>
        <h2 className={b("heading")}>{t("Select a Validation")}</h2>
        {gpsAwailable && (
          <Option
            onClick={() => this.setType(LocationValidation.gps)}
            className={b("option", {
              gps: true,
              active: selectedType === LocationValidation.gps,
            })}
          >
            <h4>
              {t("GPS")}
              <div className={b("title-chip")}>{t("MOBILE")}</div>
            </h4>

            <p>{t("Validation by registered address")}</p>
          </Option>
        )}

        {otherAvailable && (
          <>
            <Option
              onClick={() => this.setType(LocationValidation.wifi)}
              className={b("option", {
                wifi: true,
                active: selectedType === LocationValidation.wifi,
              })}
            >
              <h4>
                {t("Wi-Fi")}
                <div className={b("title-chip")}>{t("MOBILE")}</div>
              </h4>

              <p>{t("Validate the point through your WiFi")}</p>
            </Option>
            <Option
              onClick={() => this.setType(LocationValidation.ibeacon)}
              className={b("option", {
                ibeacon: true,
                active: selectedType === LocationValidation.ibeacon,
              })}
            >
              <h4>
                {t("iBeacon")}
                <div className={b("title-chip")}>{t("MOBILE")}</div>
              </h4>

              <p>{t("Use iBeacon or Bluetooth to validate.")}</p>
            </Option>

            <Option
              onClick={() => this.setType(LocationValidation.ip)}
              className={b("option", {
                ip: true,
                active: selectedType === LocationValidation.ip,
              })}
            >
              <h4>
                {t("IP")}
                <div className={b("title-chip")}>{t("WEB")}</div>
              </h4>

              <p>{t("Validate your address with internet")}</p>
            </Option>
          </>
        )}

        <div className={b("action-buttons")}>
          <Button onClick={() => onCancel()} className={b("button")} modifier="bordered">
            {t("Cancel")}
          </Button>
          <Button className={b("button")} onClick={() => onSubmit(this.state.selectedType)}>
            {t("Select")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(ValidationTypeSwitch);
