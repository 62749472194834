import { useTranslation } from "react-i18next";
import Button from "components/UI/Button";
import { Location, LocationValidation } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import ValidationsGPS from "./GPS/ValidationsGPS";
import ValidationsWiFi from "./WiFi/ValidationsWiFi";
import ValidationsIBeacon from "./IBeacon/ValidationsIBeacon";
import ValidationsIP from "./IP/ValidationsIP";
import { translateEmployeeTerm } from "utils/translationHelpers";

interface ValidationProps {
  currentLocation: Location;
  showCreateValidationPopup: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

const Validations = ({ currentLocation, showCreateValidationPopup, setNotification, onYes }: ValidationProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);

  if (!currentLocation?.id) {
    return null;
  }

  const { verification_methods } = currentLocation;

  const hasGPS = verification_methods.includes(LocationValidation.gps);
  const hasWiFi = verification_methods.includes(LocationValidation.wifiPoints);
  const hasIBeacon = verification_methods.includes(LocationValidation.beacons);
  const hasIpAdresses = verification_methods.includes(LocationValidation.ipAddress);

  return (
    <div>
      <div>
        {hasGPS && <ValidationsGPS currentLocation={currentLocation} setNotification={setNotification} onYes={onYes} />}
        {hasWiFi && (
          <ValidationsWiFi currentLocation={currentLocation} setNotification={setNotification} onYes={onYes} />
        )}
        {hasIBeacon && (
          <ValidationsIBeacon currentLocation={currentLocation} setNotification={setNotification} onYes={onYes} />
        )}
        {hasIpAdresses && (
          <ValidationsIP currentLocation={currentLocation} setNotification={setNotification} onYes={onYes} />
        )}
      </div>
      {!hasGPS && !hasWiFi && !hasIBeacon && (
        <div className="validations__placeholder">
          {translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-add-verifications-text",
            `Ensure that your employees are in the right place`,
          )}
          <Button className="validations__placeholder-button" modifier="text" onClick={showCreateValidationPopup}>
            {t("Add Validation")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Validations;
