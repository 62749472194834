import { Component, ContextType } from "react";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import config from "config";
import GlobalContext from "context/global-context";
import { ReportType } from "types/reports";
import { TranslationNamespaces } from "types/translationNamespaces";
import { showSnackbar } from "utils/common";
import { fireDownloadReport } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import FullPage from "components/Layout/FullPage";
import DatePeriodsSelector from "components/UI/DatePeriodsSelector";
import ga from "utils/ga";
import { getReportsPageTitle } from "./ReportsTitle";
import { Page } from "./reportsStyledComponents";
import ReportSearchFiltersBase from "./ReportSearchFiltersBase";

interface ReportsPageFteProps extends WithTranslation, RouteComponentProps {}

interface ReportsPageFteState {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

class ReportsPageFte extends Component<ReportsPageFteProps, ReportsPageFteState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<ReportsPageFteState> = {
    startDate: moment().date(1),
    endDate: moment().endOf("month"),
  };

  constructor(props: ReportsPageFteProps) {
    super(props);

    const { flags } = props;

    if (!flags.fteReport) {
      props.history.push("/");
    }
  }

  downloadReport = async () => {
    const { t } = this.props;
    const { startDate, endDate } = this.state;

    ga.trackGenerateReport(ReportType.fte);

    try {
      const { report_uuid: reportUuid } = await fireDownloadReport({
        format: "xlsx",
        reportType: ReportType.fte,
        byEmail: false,
        companyUUID: window.global_store.company.uuid,
        startDate: startDate.format(config.apiDateFormat),
        endDate: endDate.format(config.apiDateFormat),
        showInactiveEmploees: true,
      });

      void this.context.addToDownloads(reportUuid);

      showSnackbar({ text: t("common|Preparing file for download…"), notificationStyle: "notice" });
    } catch (error) {
      sentryUtils.sendError(error);

      showSnackbar({ text: t("common|Failed to run report generation") });
    }
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <FullPage title={getReportsPageTitle(t, ReportType.fte)}>
        <Page>
          <ReportSearchFiltersBase
            onGenerate={this.downloadReport}
            generateButtonDisabled={false}
            generateBtnText={t("common|Download")}
          >
            <DatePeriodsSelector
              onChange={(sd: moment.Moment, ed: moment.Moment) => {
                this.setState({ startDate: sd, endDate: ed });
              }}
              selectedRange={{
                start: startDate?.format("YYYY-MM-DD"),
                end: endDate?.format("YYYY-MM-DD"),
              }}
            />
          </ReportSearchFiltersBase>
        </Page>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsPageFte);
