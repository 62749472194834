import ReactDOM from "react-dom";
// import * as ReactDOMClient from "react-dom/client";
import cookie from "react-cookies";
import * as moment from "moment";
import "moment/locale/pt";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/he";
import FusionCharts from "fusioncharts";
import {
  adminLoginAs,
  chooseProfile,
  getCompanyRules,
  getCurrentCompany,
  getCurrentUserProfile,
} from "utils/apiHelpers";
import * as oitchauDb from "utils/oitchauDb";
import getAppWithProviders from "components/Providers";
import FontLoader from "utils/FontLoader";
import {
  addPolyfills,
  checkForGhostUser,
  clearStorageAndDb,
  getCountryBasedLanguage,
  getDetectedLanguage,
  getPermissions,
  googleMapsInit,
  initBeamer,
  initColorTheme,
  initHotjar,
  initZendesk,
  setLang,
  setLocalStorageVersion,
  setTextDirection,
  setCompanyDashboards,
  setBetaFlag,
  setEmbeddedFlag,
  injectCssOverrides,
} from "utils/appInitHelpers";
import config from "config";
import { UserProfile } from "types/models/userProfile";
import { PermissionSectionName, fireEvent, getStoredAuthToken, urlParam } from "utils/common";
import { widgetLogin } from "utils/widgetLogin";
import Sentry from "utils/sentryUtils";
import { InitializeI18n } from "i18n";
import { getRouterWithRoutes, getRouterWithRoutesByPermissions } from "routes/routingUtils";
import { GetCompanyRulesResponseData, GetCompanyResponseData } from "utils/api/types";
import ga from "utils/ga";
import { LocalStorageKeys, setLsEmployeeTerm } from "utils/localStorageUtils";
import { saveLocale } from "utils/translationHelpers";
import { BillingService } from "./components/Billing/BillingService";

if (urlParam("z_debug")) {
  console.log(atob ? atob(String(urlParam("z_debug"))) : urlParam("z_debug"));
}
window.global_store = {};

FusionCharts.options.license({
  key: config.fusionChartKey,
  creditLabel: false,
});

// locales, languages, text, fonts
const locale = getDetectedLanguage();
void InitializeI18n();
setTextDirection(locale);
setLang(locale);
void FontLoader.load(locale);
moment.locale(locale);
initColorTheme();

injectCssOverrides();
addPolyfills();
void googleMapsInit();
Sentry.init();
setLocalStorageVersion();
setBetaFlag();
setEmbeddedFlag();
initZendesk();

const createMainApp = async (profile?: UserProfile): Promise<void> => {
  let router;

  if (profile) {
    router = getRouterWithRoutesByPermissions(profile);
  } else {
    router = getRouterWithRoutes();
  }

  const app = await getAppWithProviders(profile, router);

  /*
    Warning: ReactDOM.render is no longer supported in React 18.
    Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17.
  */
  // TODO try to move render to the new style. Currently it will break ReactPortal (e.g. in Overlay componemt)
  // const container = document.getElementById("root");
  // const root = ReactDOMClient.createRoot(container as HTMLElement);
  // root.render(app);

  ReactDOM.render(app, document.getElementById("root"));
};

const loginFallback = async (err?: string): Promise<void> => {
  await clearStorageAndDb();

  if (err) {
    console.log(err);
  }

  void createMainApp();
};

const renderApp = async (): Promise<void> => {
  const email = cookie.load("userEmail");
  const token = cookie.load("userToken");
  const ssoToken = urlParam("token");
  const ssoError = urlParam("sso_error");
  const sourceType = urlParam("sourceType");
  let auth = getStoredAuthToken();
  let authEmail;
  let authToken;
  let systemAdminUuid;

  if (ssoError && window.location.pathname.indexOf("/login") < 0) {
    window.location = `${window.location.origin}/login${window.location.search}` as unknown as Location;
  }

  if (urlParam("authEmail") && urlParam("authToken")) {
    await clearStorageAndDb();

    authToken = urlParam("authToken");
    authEmail = urlParam("authEmail");
    systemAdminUuid = urlParam("systemAdminUuid");
    localStorage.setItem(LocalStorageKeys.loginAsMode, "true");
  } else if (ssoToken && sourceType !== "inviteEmail") {
    await clearStorageAndDb();

    auth = ssoToken;
    localStorage.setItem("oi_auth", auth);

    if (window.location.pathname === "/widget-login") {
      await widgetLogin(ssoToken);
      return;
    }

    window.location = `${window.location.origin}${window.location.pathname}` as unknown as Location;
  }

  if (window.location.pathname.includes("/login")) {
    await clearStorageAndDb();
  }

  if (window.location.pathname === "/account-type") {
    void createMainApp();
    return;
  }
  const hasCredentials = (authEmail && authToken) || auth || (email && token);
  if (!hasCredentials) {
    if (window.location.pathname === "/my-digital-signatures" || window.location.pathname === "/widget-login") {
      const redirectUrl = `${window.location.pathname}${window.location.search || ""}`;
      localStorage.setItem("oitchau_redirect", redirectUrl);
    }

    localStorage.removeItem(LocalStorageKeys.loginAsMode);

    void loginFallback();
    return;
  }

  try {
    if (authEmail && authToken) {
      await adminLoginAs(authEmail, authToken, systemAdminUuid);
    }

    // eslint-disable-next-line prefer-const
    let [profile, company, companyRulesRes]: [
      UserProfile,
      GetCompanyResponseData | false,
      GetCompanyRulesResponseData,
    ] = await Promise.all([getCurrentUserProfile(), oitchauDb.system.getCompany(), getCompanyRules()]);

    if (!profile || !profile.company) {
      profile = (await checkForGhostUser()) as UserProfile;
    }

    setLsEmployeeTerm(companyRulesRes);

    // workaround PROD-10228
    if (!company || company.uuid !== profile.company.uuid) {
      company = await getCurrentCompany(true);

      if (company) {
        window.global_store.company = company;
        await oitchauDb.system.setCompany(company);
      }
    }

    if (profile) {
      localStorage.removeItem("oitchau_redirect");
      if (window.location.pathname === "/widget-login") {
        // TODO: error during widget login should not lead to loginFallback
        const oiAuth = localStorage.getItem("oi_auth");
        if (!oiAuth) {
          document.write("something went wrong");
          throw Error("no auth token");
        }
        await widgetLogin(oiAuth);
        return;
      }

      const profileLocale = profile.locale ? getCountryBasedLanguage(profile.locale) : "";

      // change locale if needed
      if (
        localStorage.getItem(LocalStorageKeys.loginAsMode) !== "true" &&
        profileLocale &&
        profileLocale.toLowerCase() !== locale.toLowerCase()
      ) {
        saveLocale(profileLocale);
        moment.locale(profileLocale);
        FontLoader.load(profileLocale);
        await InitializeI18n();
        setTextDirection(profileLocale);
        setLang(profileLocale);
      }

      // digital signatures
      if (
        window.location.pathname === "/my-digital-signatures" &&
        urlParam("user_profile_uuid") &&
        profile.uuid !== urlParam("user_profile_uuid") &&
        localStorage.getItem("gs_profiles")
      ) {
        const profiles = JSON.parse(localStorage.getItem("gs_profiles") as string) as UserProfile[];
        const profileForReport = profiles.filter((p) => p.uuid === urlParam("user_profile_uuid"))[0];

        if (profileForReport) {
          fireEvent("LogOut");
          await chooseProfile({ profileId: profileForReport.id });

          window.location = "/my-digital-signatures" as unknown as Location;
        }
      }

      const dashboardActions = profile.permissions[PermissionSectionName.dashboard] || [];
      // set permissions
      profile.permissions = await getPermissions(profile);
      // set profile
      window.global_store.profile = profile;

      if (sessionStorage.getItem("first_ga_event_flag") === null) {
        ga.trackSessionStart();
        sessionStorage.setItem("first_ga_event_flag", "true");
      }

      initBeamer(profile);
      initZendesk(profile);
      initHotjar(profile);
      Sentry.setUser(profile);

      setCompanyDashboards(dashboardActions);

      // todo remove try/catch after customer migration
      try {
        await BillingService.getInitialCustomerData(profile.company.uuid);
      } catch (e) {
        Sentry.sendError(e);
        localStorage.removeItem(LocalStorageKeys.subscriptions);
        localStorage.removeItem(LocalStorageKeys.customer);
        localStorage.removeItem(LocalStorageKeys.hideDiscount);
      }

      void createMainApp(profile);
    } else {
      void loginFallback("User doesn't have a profile");
    }
  } catch (error) {
    console.log(error);
    void loginFallback("Error in getting profile");
  }
};

void renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
