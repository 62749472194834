import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { WithTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { DeactivatedPayrollLock } from "types/models/payrollLocks";
import GlobalContext from "context/global-context";
import moment, { Moment } from "moment";
import { AllFlagsLDClient } from "launchdarkly-react-client-sdk";
import { getDeactivatedLocksList, getProfileUuidsWithFilters, listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import TableButtonsControl from "components/styled/TableButtonsControl";
import { DigitalSignatureStatus } from "types/models/digitalSignature";
import { GridTable, restoreState } from "components/UI/GridTable/GridTable";
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideSelectionState,
  IFilterParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from "ag-grid-community";
import Avatar from "components/views/Avatar";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { LocalStorageKeys } from "utils/localStorageUtils";
import styled from "styled-components";
import EmployeeSearchControl from "components/Employees/EmployeeSearchControl";
import { ColumnSelectorToolPanel } from "components/UI/GridTable/ColumnSelectorToolPanel";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
// import DateRangeFilter from "components/UI/GridTable/filters/DateRangeFilter";  // disabled for now. PROD-18805
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import DeactivatedEmployeesTableActionsBar from "./DeactivatedEmployeesTableActionsBar";

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  .Search {
    width: 180px;
    position: relative;
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);

    input {
      border: none;
      outline: none;
      width: 100%;
      height: 32px;
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-700);

      &::placeholder {
        color: var(--colors-surface-700);
      }
    }
  }

  .Flex {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const Wrapper = styled.div`
  .Avatar {
    margin-inline-end: 8px;
  }

  .ag-row {
    z-index: 0;
  }

  .buttons {
    position: fixed;
    inset-inline-end: 12px;
    top: 10px;
    margin-top: 0;
  }

  .ag-row-hover .buttons {
    display: flex;
  }

  .ag-header-cell-text {
    text-transform: capitalize;
  }

  .ag-popup {
    z-index: 9999;
  }
`;

interface DeactivatedEmployeesProps extends RouteComponentProps, WithTranslation, AllFlagsLDClient {
  getTableButtons: (payrollLock: DeactivatedPayrollLock) => {
    dropdownButtons: { label: string; onClick: () => void }[];
    visibleButtons: { label: string; onClick: () => void }[];
  };
}

const DS_STATUS_TO_STATUS_DICT: Record<DigitalSignatureStatus, StatusTagType> = {
  [DigitalSignatureStatus.signRequestSent]: StatusTagType.pending,
  [DigitalSignatureStatus.signRequestInProggress]: StatusTagType.pending,
  [DigitalSignatureStatus.pending]: StatusTagType.pending,
  [DigitalSignatureStatus.signed]: StatusTagType.active,
  [DigitalSignatureStatus.canceled]: StatusTagType.default,
  [DigitalSignatureStatus.missing]: StatusTagType.default,
};

const getProfileUuidsFilterModel = (term: string) => ({
  uuid: {
    filterType: "join",
    type: "OR",
    conditions: [
      {
        colId: "email",
        filterType: "text",
        type: "contains",
        filter: term.toLowerCase(),
      },
      {
        colId: "matricula",
        filterType: "text",
        type: "contains",
        filter: term.toLowerCase(),
      },
      {
        colId: "lc_name",
        filterType: "text",
        type: "contains",
        filter: term.toLowerCase(),
      },
    ],
  },
});

const DeactivatedEmployeesTable = forwardRef(({ t, getTableButtons }: DeactivatedEmployeesProps, ref) => {
  const context = useContext(GlobalContext);
  const [gridApi, setGridApi] = useState<GridReadyEvent | null>(null);
  const [toggledLocks, setToggledLocks] = useState<string[]>([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [isSelectAllMode, setIsSelectAllMode] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterMode, setFilterMode] = useState(null);
  const [notification, setNotification] = useState<{ message: string; type: NotificationType } | null>(null);

  const [onSelectionChanged] = useAsyncCallback(async () => {
    setSelectionLoading(true);
    const selectionState = gridApi?.api.getServerSideSelectionState() as IServerSideSelectionState;
    setIsSelectAllMode(selectionState.selectAll);
    setToggledLocks(selectionState.toggledNodes);

    setSelectionLoading(false);
  }, [gridApi]);

  useImperativeHandle(ref, () => ({
    getState: () => gridApi?.api!.refreshServerSide({ purge: true }),
  }));

  const [getData] = useAsyncCallback(
    async (params: IServerSideGetRowsRequest & { searchTerm: string }) => {
      let locks = [];
      let totalRows = 0;

      const company = await context.getCompany();

      let userProfileUuids: string[] = [];

      if (params.searchTerm.trim()) {
        try {
          const response = await getProfileUuidsWithFilters(company.uuid, {
            requestedBy: window.global_store.profile.uuid,
            skip: 0,
            limit: 200000,
            filterModel: getProfileUuidsFilterModel(params.searchTerm.trim()),
          });
          userProfileUuids = response.content;
        } catch (e) {
          setNotification({
            message: t("Failed to fetch search results. Please try again later."),
            type: NotificationType.error,
          });
        }

        if (userProfileUuids.length === 0) {
          return { content: [], total: 0 };
        }
      }

      const bodyParams = {
        limit: params.endRow! - params.startRow!,
        skip: params.startRow,
        filterModel:
          userProfileUuids.length > 0
            ? { userProfileUuids: { filterType: "set", values: userProfileUuids }, ...params.filterModel }
            : params.filterModel,
        sortModel: params.sortModel.length > 0 ? params.sortModel : undefined,
      };

      setFilterMode(bodyParams.filterModel);

      let locksListResp: { content: DeactivatedPayrollLock[] } = { content: [] };

      try {
        locksListResp = await getDeactivatedLocksList({ companyUuid: company.uuid, body: bodyParams });
        totalRows = locksListResp?.metadata?.total || 0;
      } catch (e) {
        setNotification({
          message: t("Failed to fetch payroll locks details. Please try again later."),
          type: NotificationType.error,
        });
        return { content: [], total: 0 };
      }
      const locksList: DeactivatedPayrollLock[] = locksListResp.content || [];

      // get created by employee details from cache
      // get all needed uuids
      const createdByEmployeesList: Record<string, any> = {};

      locksList.forEach((lock) => {
        createdByEmployeesList[lock.createdBy] = {};
      });

      let content: FilteredEmployeeProfile[] = [];

      try {
        // fetch details by uuids
        const response = await listUserProfilesWIthFilters(
          company.uuid,
          baseByUuidPayload(
            window.global_store.profile.uuid,
            locksList
              .map((lock) => lock.userProfileUuid ?? null)
              .filter(Boolean)
              .concat(Object.keys(createdByEmployeesList)) as string[],
          ),
        );

        content = response.content;
      } catch (e) {
        setNotification({
          message: t("Failed to fetch users details. Please try again later."),
          type: NotificationType.error,
        });
        return { content: [], total: 0 };
      }

      // assign details to object with uuids
      content.forEach((employee) => {
        createdByEmployeesList[employee.uuid] = employee;
      });

      locks = locksList
        .map((lock) => {
          const newLock = lock;

          if (!lock.userProfileUuid) {
            return null;
          }

          const userProfile = content.find((employee) => employee.uuid === lock.userProfileUuid);
          newLock.deactivatedEmployeeProfile = userProfile;
          newLock.createdBy = createdByEmployeesList[lock.createdBy]?.fullName || "";
          return newLock;
        })
        .filter(Boolean) as DeactivatedPayrollLock[];

      const filteredLocks = locks.filter((l) => !!l.deactivatedEmployeeProfile);

      return { content: filteredLocks, total: totalRows };
    },
    [context, t],
  );

  const datasource = useMemo(
    () => ({
      getRows(params: IServerSideGetRowsParams) {
        getData({ ...params.request, searchTerm })
          .then((data) => {
            params.success({ rowData: data.content, rowCount: data.total });
            setRowsCount(data.total);
          })
          .catch(() => {
            params.fail();
          });
      },
    }),
    [searchTerm, getData],
  );

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);

    restoreState(params.columnApi, LocalStorageKeys.deactivatedEmployeesColumns);
  };

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
  }, [searchTerm]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "fullName",
        colId: "lc_name",
        headerName: t("Member"),
        headerCheckboxSelection: true,
        checkboxSelection: true,
        pinned: window.global_store.isRTL ? "right" : "left",
        cellRenderer: (params: ICellRendererParams) => {
          const profile = params.data.deactivatedEmployeeProfile;

          return (
            <Link
              to={`/employees/${profile.id}`}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                color: "var(--colors-surface-900-p)",
              }}
            >
              <Avatar
                className="Avatar"
                user={{
                  fullName: profile.fullName,
                  avatarId: profile.avatarId,
                }}
              />
              <div title={profile.fullName} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {profile.fullName}
              </div>
            </Link>
          );
        },
      },
      {
        field: "deactivatedEmployeeProfile.matricula",
        headerName: t("ID Number"),
        cellRenderer: (params: ICellRendererParams) => params.data.deactivatedEmployeeProfile.matricula || "-",
      },
      {
        field: "cpf",
        headerName: t("CPF"),
        cellRenderer: (params: ICellRendererParams) => params.data.deactivatedEmployeeProfile.taxPayerId || "-",
      },
      {
        field: "payrollLockStartDate",
        headerName: t("Start"),
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => moment(params.data.startDate).format("DD/MM/YY"),
        // filter: DateRangeFilter, // disabled for now. PROD-18805
        // suppressMenu: false,
      },
      {
        field: "payrollLockEndDate",
        headerName: t("End"),
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => moment(params.data.endDate).format("DD/MM/YY"),
        // filter: DateRangeFilter, // disabled for now. PROD-18805
        // filterParams: {
        //   isDayBlocked: (day: Moment, props: IFilterParams) => {
        //     const allFilters = props.api.getFilterModel();

        //     if (!allFilters.payrollLockStartDate) {
        //       return false;
        //     }

        //     return day.isBefore(moment(allFilters.payrollLockStartDate.dateTo));
        //   },
        // },
        // suppressMenu: false,
      },
      { field: "createdBy", headerName: t("Created By") },
      {
        field: "payrollLockStatus",
        headerName: t("Payroll Status"),
        cellStyle: { textTransform: "capitalize" },
        cellRenderer: (params: ICellRendererParams) => t(params.data.status),
        filter: "agSetColumnFilter",
        sortable: true,
        suppressMenu: false,
        filterParams: {
          values: ["locked", "unlocked"],
          keyCreator: (params: ICellRendererParams) => params.value,
          valueFormatter: (params: ICellRendererParams) => t(params.value).toUpperCase(),
        },
      },
      {
        field: "deactivationDate",
        headerName: t("Deactivation Date"),
        cellRenderer: (params: ICellRendererParams) => moment(params.data.deactivationDate).format("DD/MM/YY"),
        // filter: DateRangeFilter,  // disabled for now. PROD-18805
        // suppressMenu: false,
        sortable: true,
      },
      {
        field: "digitalSignatureStatus",
        sortable: true,
        headerName: t("signature status"),
        cellRenderer: (params: ICellRendererParams) => (
          <>
            <StatusTag
              value={params.data.digitalSignatureStatus ? t(params.data.digitalSignatureStatus) : t("not required")}
              type={
                params.data.digitalSignatureStatus
                  ? DS_STATUS_TO_STATUS_DICT[params.data.digitalSignatureStatus as DigitalSignatureStatus]
                  : StatusTagType.default
              }
            />
            <TableButtonsControl
              dropToTop={
                params.api.getDisplayedRowCount() > 8 &&
                (params.node.rowIndex ?? 0) >= params.api.getDisplayedRowCount() - 2
              }
              {...getTableButtons(params.data)}
            />
          </>
        ),
        filter: "agSetColumnFilter",
        suppressMenu: false,
        filterParams: {
          values: [
            { uuid: "pending", title: "PENDING" },
            { uuid: "notRequired", title: "NOT REQUIRED" },
            { uuid: "canceled", title: "CANCELLED" },
            { uuid: "signed", title: "SIGNED" },
          ],
          keyCreator: (params: ICellRendererParams) => params.value.uuid,
          valueFormatter: (params: ICellRendererParams) => t(params.value.title),
        },
      },
    ],
    [t, getTableButtons],
  );

  const clearRowSelection = () => {
    if (gridApi) {
      gridApi?.api.deselectAll();
    }
  };

  const howManySelected = isSelectAllMode ? rowsCount - toggledLocks.length : toggledLocks.length;
  const isAnyRowSelected = howManySelected > 0;

  return (
    <Wrapper>
      {notification && (
        <NotificationRow
          employeesPage
          withCloseButton
          type={notification.type}
          onClose={() => setNotification(null)}
          message={notification.message}
        />
      )}
      <TopRow>
        <EmployeeSearchControl
          onChange={(term: string) => {
            setSearchTerm(term);
          }}
        />
        <div className="Flex">
          <ColumnSelectorToolPanel gridApi={gridApi!} />
        </div>
      </TopRow>
      <div style={{ width: "100%", height: "calc(100vh - 250px)" }}>
        <GridTable
          columnDefs={columnDefs}
          gridOptions={{
            getRowStyle: (params) => {
              if (!params) {
                return undefined;
              }

              // Set z-index starting 1000 and descanding from the first row to the last
              // to make sure the dropdown are always on top
              return { zIndex: 1000 - (params.node.rowIndex ?? 0) };
            },
          }}
          onGridReady={onGridReady}
          lsKey={LocalStorageKeys.deactivatedEmployeesColumns}
          gridApi={gridApi!}
          onSelectionChanged={onSelectionChanged}
          onFilterChanged={clearRowSelection}
        />
      </div>
      {isAnyRowSelected && (
        <DeactivatedEmployeesTableActionsBar
          toggledLocks={toggledLocks}
          isSelectAllMode={isSelectAllMode}
          rowsCount={rowsCount}
          onUncheckAll={(): void => gridApi?.api.deselectAll()}
          disabled={selectionLoading}
          filterMode={filterMode}
          onFail={(resp: { notification: string; notificationType: NotificationType }): void => {
            setNotification({
              message: resp.notification,
              type: resp.notificationType,
            });
          }}
          onDone={(resp: { notification: string; notificationType: NotificationType }): void => {
            setNotification({
              message: resp.notification,
              type: resp.notificationType,
            });
            gridApi?.api.deselectAll();
            gridApi?.api.onFilterChanged();
          }}
        />
      )}
    </Wrapper>
  );
});

export default DeactivatedEmployeesTable;
