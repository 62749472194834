import { Component, ContextType } from "react";
import styled from "styled-components";
import NotificationRow from "components/NotificationRow";
import { PageWrapper, HeaderAction } from "components/styled/Page";
import Tabs from "components/UI/Tabs";
import FullPage from "components/Layout/FullPage";
import { RouteComponentProps } from "react-router-dom";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import ModalDialog from "components/UI/ModalDialog";
import GlobalContext from "context/global-context";
import { getTitle } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { WithTranslation, withTranslation } from "react-i18next";
import { NotificationType } from "types/common";
import { Project, ProjectStatus } from "types/models/projects";
import Sentry from "utils/sentryUtils";
import { ButtonState } from "components/controls/StyledButton";
import { translateEmployeeTerm } from "utils/translationHelpers";
import ProjectTasksTab from "./ProjectPageTasksTab";
import ProjectPageSummary from "./ProjectPageSummaryTab";
import TasksPopupMessage from "./TasksPopupMessage";
import { bulkAssignEmployeesToProject, getProject } from "./projectsApiUtils";
import ProjectPageEmployees from "./ProjectPageEmployees";
import AssigneesDialog, { OnSaveProp } from "./AssigneesDialog";

const Wrapper = styled(PageWrapper)`
  .project-details-content {
    padding: 0 24px;
  }
`;

const HeaderControls = styled.div`
  position: relative;

  .project-header-action {
    position: absolute;
    inset-inline-end: 12px;
    bottom: 8px;
  }
`;

const TabsWrapper = styled.div`
  ul {
    padding: 0 24px 1px;
    border-bottom: 1px solid var(--colors-surface-150);

    & > div {
      gap: 16px;

      li {
        padding: 0;
      }
    }
  }
`;

enum ProjectPageTabs {
  summary = "summary",
  tasks = "tasks",
  employees = "employees",
}

interface ProjectPageProps extends WithTranslation, RouteComponentProps {}

interface ProjectPageState {
  activeTab: ProjectPageTabs;
  title: string;
  loading: boolean;
  addTaskPopupVisible: boolean;
  notification: string | null;
  notificationType: NotificationType;
  project: Project | null;
  contentKey: number;
  isAssignDialogOpen: boolean;
}

class ProjectPage extends Component<ProjectPageProps, ProjectPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ProjectPageProps) {
    super(props);
    const { t } = props;
    this.state = {
      activeTab: ProjectPageTabs.tasks,
      title: t("Project"),
      loading: true,
      addTaskPopupVisible: false,
      notification: "",
      project: null,
      contentKey: new Date().getTime(),
      notificationType: NotificationType.success,
      isAssignDialogOpen: false,
    };
    document.title = getTitle(this.state.title);
  }

  componentDidMount() {
    void this.getContent();
  }

  getContent = async (
    notification?: string | null,
    notificationType: NotificationType = NotificationType.success,
  ): Promise<void> => {
    const {
      t,
      match: {
        params: { projectUuid },
      },
    } = this.props;

    try {
      const company = await this.context.getCompany();
      const response = await getProject({ companyUuid: company.uuid, projectUuid });
      const project = response.content;

      this.setState({
        notification: notification || null,
        notificationType,
        loading: false,
        title: project.name,
        project,
      });
    } catch (error) {
      this.setState({
        loading: false,
        notification: t("project-load-failed"),
        notificationType: NotificationType.error,
      });
    }
  };

  onProjectUpdated = (notification: string | null, notificationType: NotificationType) => {
    void this.getContent(notification, notificationType);
  };

  onAddEmployees = async (
    projectUuid: string,
    {
      userProfilesUuids,
      servicesUuids,
    }: {
      userProfilesUuids: string[];
      servicesUuids: string[];
    },
  ) => {
    const { t } = this.props;
    const { project } = this.state;
    let stateToUpdate = this.state;

    try {
      await bulkAssignEmployeesToProject({
        companyUuid: window.global_store.company.uuid,
        projectUuid,
        body: {
          content: {
            requestedBy: window.global_store.profile.uuid,
            userProfilesUuids,
            servicesUuids,
          },
        },
      });
      stateToUpdate = {
        ...stateToUpdate,
        project: { ...project, isPublic: false } as Project,
        notification: t("project-assign-employees-success"),
        notificationType: NotificationType.success,
      };
    } catch (error) {
      Sentry.sendError(error);
      stateToUpdate = {
        ...stateToUpdate,
        notification: t("project-assign-employees-failed"),
        notificationType: NotificationType.error,
      };
    }
    this.setState(stateToUpdate);
  };

  getTabContent = (activeTab: ProjectPageTabs) => {
    const { project, contentKey } = this.state;
    const { t } = this.props;
    let content = null;

    if (!project) {
      return null;
    }

    switch (activeTab) {
      case ProjectPageTabs.summary:
        content = (
          <ProjectPageSummary
            key={new Date().getTime()}
            onProjectUpdated={this.onProjectUpdated}
            project={project}
            showNotification={(val: string | null) => this.setState({ notification: val })}
          />
        );

        document.title = getTitle(t("Project Summary"));
        break;

      case ProjectPageTabs.tasks:
        content = (
          <ProjectTasksTab
            key={contentKey}
            projectUuid={project.uuid}
            onNotification={(val: string | null, type: NotificationType) =>
              this.setState({
                notification: val,
                notificationType: type,
                contentKey: type === NotificationType.success ? new Date().getTime() : contentKey,
              })
            }
          />
        );

        document.title = getTitle(t("Project Tasks"));
        break;

      case ProjectPageTabs.employees:
        content = (
          <ProjectPageEmployees
            key={contentKey}
            project={project}
            onMakeProjectPrivate={async () => {
              this.setState({ isAssignDialogOpen: true });
            }}
            onProjectUpdated={(val: string | null, type: NotificationType) =>
              this.setState({
                notification: val,
                notificationType: type,
                contentKey: type === NotificationType.success ? new Date().getTime() : contentKey,
              })
            }
          />
        );

        document.title = getTitle(t("Project Employees"));
        break;

      default:
        content = null;
        break;
    }

    return content;
  };

  render() {
    const {
      notification,
      notificationType = NotificationType.success,
      activeTab,
      title,
      addTaskPopupVisible,
      loading,
      project,
      isAssignDialogOpen,
    } = this.state;
    const { t, history } = this.props;
    const tabs = [
      { name: ProjectPageTabs.tasks, label: t("Tasks") },
      { name: ProjectPageTabs.employees, label: t("Team") },
      { name: ProjectPageTabs.summary, label: t("Summary") },
    ];

    const actionButtonTitle = {
      tasks: t("Add task"),
      employees: translateEmployeeTerm(t, TranslationNamespaces.projects, "custom-add-members", "Add members"),
    };

    if (loading) {
      return null;
    }

    return (
      <FullPage
        className="project-details"
        title={title}
        backButtonTitle={t(`${TranslationNamespaces.sidebar}|Projects`)}
        backButtonOnclick={() => history.push("/projects")}
      >
        <Wrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <HeaderControls>
            {project?.status === ProjectStatus.active &&
              (activeTab === ProjectPageTabs.tasks ||
                (!project.isPublic && activeTab === ProjectPageTabs.employees)) && (
                <HeaderAction className="project-header-action">
                  <HeaderActionButtonAdd
                    state={ButtonState.primary}
                    title={actionButtonTitle[activeTab] || ""}
                    onClick={() => {
                      this.setState({
                        isAssignDialogOpen: activeTab === ProjectPageTabs.employees,
                        addTaskPopupVisible: activeTab === ProjectPageTabs.tasks,
                      });
                    }}
                  />
                </HeaderAction>
              )}

            <TabsWrapper>
              <Tabs tabs={tabs} value={activeTab} onChange={(tab) => this.setState({ activeTab: tab })} />
            </TabsWrapper>
          </HeaderControls>

          <div className="project-details-content">{this.getTabContent(activeTab)}</div>

          <ModalDialog isOpen={addTaskPopupVisible} onClose={() => this.setState({ addTaskPopupVisible: false })}>
            <TasksPopupMessage
              title={t("Create Task")}
              projectUuid={project?.uuid || ""}
              onClose={() => {
                this.setState({ addTaskPopupVisible: false });
              }}
              onYes={(taskName: string) => {
                this.setState({
                  addTaskPopupVisible: false,
                  contentKey: new Date().getTime(),
                  notification: t("task-add-success", { taskName }),
                });
              }}
            />
          </ModalDialog>
          <AssigneesDialog
            title={translateEmployeeTerm(t, TranslationNamespaces.projects, "custom-add-members", "Add members")}
            isVisible={isAssignDialogOpen}
            setVisible={(isVisible: boolean) =>
              this.setState({
                isAssignDialogOpen: isVisible,
              })
            }
            onSave={async (assignees: OnSaveProp) => {
              if (assignees.addAssignees && project) {
                await this.onAddEmployees(project.uuid, assignees.addAssignees);
              }

              this.setState({
                isAssignDialogOpen: false,
                contentKey: new Date().getTime(),
              });
            }}
          />
        </Wrapper>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.projects, TranslationNamespaces.sidebar])(ProjectPage);
