import { Component } from "react";
import { AxiosError } from "axios";
import TextInputControl from "components/controls/TextInputControl";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Button, { ButtonState } from "components/controls/StyledButton";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import CheckboxControl from "components/UI/NewCheckbox";
import { createCustomBreak } from "utils/api/schedule";

const Title = styled.div`
  font-size: 24px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 6px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  gap: 12px;
  button {
    flex: 1;
    height: 40px;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`;

interface BreaksPopupMessageProps extends WithTranslation {
  onClose: () => void;
  onYes: () => void;
}

interface BreaksPopupMessageState {
  name: string;
  loading: boolean;
  errors: Record<string, string> | null;
  isWorkingHours: boolean;
}

class AddNewBreakModal extends Component<BreaksPopupMessageProps, BreaksPopupMessageState> {
  static contextType = GlobalContext;

  constructor(props: BreaksPopupMessageProps) {
    super(props);

    this.state = {
      name: "",
      isWorkingHours: false,
      loading: false,
      errors: null,
    };
  }

  closePopup() {
    const { onClose } = this.props;
    onClose();
  }

  onCancel = () => {
    this.closePopup();
  };

  saveProject = async () => {
    const { name, isWorkingHours } = this.state;
    const { t, onYes } = this.props;

    this.setState({ loading: true });

    const requestDetails = {
      companyUuid: window.global_store.company.uuid,
      body: {
        content: {
          requestedBy: window.global_store.profile.uuid,
          name,
          isWorkingHours,
        },
      },
    };

    try {
      const res = await createCustomBreak(requestDetails);
      onYes(res?.content);
    } catch (error) {
      if ((error as AxiosError).message === "Record already exists") {
        this.setState({
          errors: {
            name: t(
              `${TranslationNamespaces.common}|A break with this name already exists. Please use unique names for each break.`,
            ),
          },
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  onYes = () => {
    const errors = this.validate();

    if (!errors) {
      void this.saveProject();
    } else {
      this.setState({ errors });
    }
  };

  validate = (): Record<string, string> | null => {
    const { name } = this.state;
    const { t } = this.props;
    let errors: Record<string, string> | null = {};

    if (!name.trim()) {
      errors.name = t(`${TranslationNamespaces.common}|Name can't be empty`);
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  render() {
    const { name, errors, loading, isWorkingHours } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Title>{t("New Custom Break")}</Title>
        <NameWrapper>
          <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Name`)} wrapperWidth="100%" width="100%">
            <TextInputControl
              placeholder={t(`${TranslationNamespaces.schedules}|This is name of custom pause`)}
              value={name}
              error={!!errors?.name}
              onChange={(value) => this.setState({ name: value })}
            />
          </FieldWrapper>

          {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </NameWrapper>

        <CheckboxControl
          checked={isWorkingHours}
          label={t("Count as worked hours")}
          onChange={(checked) => {
            this.setState({ isWorkingHours: checked });
          }}
        />

        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={loading}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Button
            value={t(`${TranslationNamespaces.common}|Add`)}
            state={ButtonState.primary}
            disabled={!name || loading}
            onClick={this.onYes}
            loading={loading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.schedules)(AddNewBreakModal);
