import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import SettingsLayout from "components/Layout/SettingsLayout";
import ModalDialog from "components/UI/ModalDialog";
import PageLayout from "components/UI/Page/Layout";
import Tabs from "components/UI/Tabs";
import Chip from "components/UI/Chip";
import HeaderActionButtonAdd from "components/controls/HeaderActionButton";
import NotificationRow from "components/NotificationRow";
import { LocationValidation, Location, LocationClient } from "types/models/location";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getClients, getOneLocation } from "utils/apiHelpers";
import Validations from "./Validations";
import AddNewValidation from "./Validations/AddNew";
import LocationsEditForm from "./LocationsEditForm";

const FormWrapper = styled.div`
  margin-top: 35px;
  width: 420px;
`;

enum LocationPageTab {
  details = "details",
  validations = "validations",
}

type LocationPageQueryParams = {
  locationId: string;
};

interface LocationPageProps extends WithTranslation, RouteComponentProps<LocationPageQueryParams> {
  isJobSites?: boolean;
}

interface LocationPageState {
  selectedTab: LocationPageTab;
  location: Location | null;
  notification: string | null;
  notificationType: NotificationType | null;
  showCreateValidationPopup: boolean;
  /** job sites */
  clients: LocationClient[];
}

class LocationPage extends Component<LocationPageProps, LocationPageState> {
  readonly state: Readonly<LocationPageState> = {
    selectedTab: LocationPageTab.details,
    location: null,
    notification: null,
    notificationType: null,
    showCreateValidationPopup: false,
    clients: [],
  };

  async componentDidMount() {
    const { match } = this.props;

    const promises = [this.getLocation(parseInt(match.params.locationId, 10))];
    if (this.props.isJobSites) {
      promises.push(this.getClients());
    }

    void Promise.allSettled(promises);
  }

  getLocation = async (locationId: number) => {
    const { t } = this.props;
    try {
      const { location } = await getOneLocation(locationId);

      this.setState({ location });
    } catch (error) {
      const err = error as any;
      this.setNotification(err?.data ? t(err.data) : t("Failed to load location"), NotificationType.error);
    }
  };

  getClients = async (): Promise<void> => {
    const { t } = this.props;
    try {
      const res = await getClients();

      void this.setState({ clients: res.clients });
    } catch (error) {
      const err = error as any;
      this.setNotification(err?.data ? t(err.data) : t("Failed to load clients"), NotificationType.error);
    }
  };

  setNotification = (notification: string, notificationType: NotificationType) => {
    this.setState({ notification, notificationType });
  };

  toggleCreateValidationPopup = (showCreateValidationPopup: boolean) => {
    this.setState({ showCreateValidationPopup });
  };

  render() {
    const { t, isJobSites, history } = this.props;
    const { selectedTab, location, notification, notificationType, showCreateValidationPopup, clients } = this.state;

    return (
      <SettingsLayout
        title={location?.name || ""}
        headerAction={
          location?.verification_methods && !location.verification_methods.includes(LocationValidation.gps) ? (
            <HeaderActionButtonAdd
              title={t("Add a Validation")}
              onClick={() => this.toggleCreateValidationPopup(true)}
            />
          ) : null
        }
        backButtonTitle={t(isJobSites ? "Job Sites" : "Locations")}
        backButtonOnclick={() => history.push(`/company/${isJobSites ? "job-sites" : "locations"}`)}
      >
        <PageLayout
          title={
            <span>
              {location?.name}{" "}
              {location?.active && (
                <Chip intent="success" style={{ marginInlineStart: 25 }}>
                  {t("Active")}
                </Chip>
              )}
            </span>
          }
          browserTitle={`${location?.name}`}
          actions={
            location?.verification_methods &&
            !location.verification_methods.includes(LocationValidation.gps) && (
              <HeaderActionButtonAdd
                title={t("Add a Validation")}
                onClick={() => this.toggleCreateValidationPopup(true)}
              />
            )
          }
        >
          <>
            <Tabs<LocationPageTab>
              tabs={[
                {
                  name: LocationPageTab.details,
                  label: "Details",
                },
                {
                  name: LocationPageTab.validations,
                  label: "Validation",
                },
              ]}
              t={t}
              value={selectedTab}
              onChange={(tab) => this.setState({ selectedTab: tab })}
            />

            {notification && (
              <NotificationRow
                employeesPage
                withCloseButton
                type={notificationType}
                onClose={() => this.setState({ notification: null, notificationType: null })}
                message={t(notification as string)}
              />
            )}
          </>

          {location && (
            <>
              {selectedTab === LocationPageTab.details && (
                <div>
                  <FormWrapper>
                    <LocationsEditForm
                      location={location}
                      setNotification={this.setNotification}
                      onYes={() => this.getLocation(location.id)}
                      clients={clients}
                      isJobSites={!!isJobSites}
                    />
                  </FormWrapper>
                </div>
              )}

              {selectedTab === LocationPageTab.validations && (
                <Validations
                  currentLocation={location}
                  setNotification={this.setNotification}
                  showCreateValidationPopup={() => this.toggleCreateValidationPopup(true)}
                  onYes={() => this.getLocation(location.id)}
                />
              )}

              <ModalDialog isOpen={showCreateValidationPopup} onClose={() => this.toggleCreateValidationPopup(false)}>
                <AddNewValidation
                  currentLocation={location}
                  onClose={() => this.toggleCreateValidationPopup(false)}
                  setNotification={this.setNotification}
                  onYes={() => {
                    this.toggleCreateValidationPopup(false);
                    void this.getLocation(location.id);
                  }}
                />
              </ModalDialog>
            </>
          )}
        </PageLayout>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(LocationPage);
