import { Component, ContextType, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { approvalFlowToState } from "utils/requestsHelpers";
import FieldsGroup from "components/UI/FieldsGroup";
import RequestSettingsFields from "components/Requests/RequestSettingsFields";
import FieldWrapper from "components/UI/FieldWrapper";
import FormSelect from "components/UI/FormSelect";
import SelectControl from "components/UI/SelectControl";
import AddRemoveLink from "components/controls/AddRemoveLink";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { requestSubTypeUpdate, requestSubTypeDelete } from "utils/apiHelpers";
import GlobalContext from "context/global-context";
import Spinner from "@atlaskit/spinner";
import TextInputControl from "components/controls/StyledTextInput";
import NotificationRow from "components/NotificationRow";
import {
  RequestHoursCalculationType,
  RequestSubType,
  RequestSubTypeApprovalFlow,
  RequestTypeName,
} from "types/models/request";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionRole, PermissionRoleName } from "types/models/permissions";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import CheckboxControl from "components/UI/NewCheckbox";
import RequestSettingsApprovalFlow from "./RequestSettingsApprovalFlow";

const PageWrapper = styled.div`
  width: 100%;

  .multi-select {
    .react-select__control {
      border-color: var(--colors-surface-400);
    }

    .react-select__placeholder {
      color: var(--colors-mainText);
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin: 20px 0px;
`;

interface RequestSettingsSubPageProps extends WithTranslation {
  requestSubType: RequestSubType;
  onChange: () => void;
  refetchData: () => void;
  setOnSaveFn?: Dispatch<SetStateAction<{ fn: (() => Promise<boolean>) | null; disabled: boolean }>>;
}

interface RequestSettingsSubPageState {
  loading: boolean;
  loaded: boolean;
  requestSubType: RequestSubType | null;
  hoursCalculationType: RequestHoursCalculationType;
  approvalFlow: RequestSubTypeApprovalFlow[] | null;
  notification: string;
  errors: Record<string, string> | null;
  deleteConfirmationVisible: boolean;
  deleteForbidden: boolean;
  discountDsr: boolean;
  notificationType: NotificationType;
  permissionRoles: PermissionRole[];
}

class RequestSettingsSubPage extends Component<RequestSettingsSubPageProps, RequestSettingsSubPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  hoursCalculationOptions: { value: RequestHoursCalculationType; label: string }[];

  constructor(props: RequestSettingsSubPageProps) {
    super(props);

    const { t } = props;
    this.hoursCalculationOptions = [
      { value: RequestHoursCalculationType.noCompensation, label: t("noCompensation") },
      { value: RequestHoursCalculationType.compensateMissedHours, label: t("compensateMissedHours") },
    ];

    this.state = {
      loading: false,
      loaded: false,
      requestSubType: null,
      hoursCalculationType: RequestHoursCalculationType.noCompensation,
      discountDsr: false,
      approvalFlow: null,
      notification: "",
      errors: null,
      deleteConfirmationVisible: false,
      deleteForbidden: false,
      notificationType: NotificationType.error,
      permissionRoles: [],
    };
  }

  componentDidUpdate(_: Readonly<RequestSettingsSubPageProps>, prevState: Readonly<RequestSettingsSubPageState>) {
    // reset "disabled" field
    if (this.state !== prevState) {
      this.props.setOnSaveFn?.({ fn: this.onSave, disabled: this.shouldBeDisabled() });
    }
  }

  async componentDidMount() {
    const requestSubType: RequestSubType = JSON.parse(JSON.stringify(this.props.requestSubType));
    const employeeUuids = new Set<string>();
    requestSubType.approvalFlow.forEach((af) => {
      if (af.approver?.uuid) employeeUuids.add(af.approver?.uuid);
    });
    const { content: employees } = await listUserProfilesWIthFilters(
      window.global_store.company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, [...employeeUuids]),
    );
    const permissionRoles = await this.context.getRoles();

    this.setState({
      requestSubType,
      // TODO: Change it after DB migration
      hoursCalculationType: ![
        RequestHoursCalculationType.noCompensation,
        RequestHoursCalculationType.compensateMissedHours,
      ].includes(requestSubType.hoursCalculationType)
        ? RequestHoursCalculationType.noCompensation
        : requestSubType.hoursCalculationType,
      approvalFlow: approvalFlowToState({
        employees,
        approvalFlow: requestSubType.approvalFlow,
      }),
      discountDsr: requestSubType.discountDsr,
      loaded: true,
      permissionRoles,
    });

    // workaround for requst settings modal
    this.props.setOnSaveFn?.({ fn: this.onSave, disabled: this.shouldBeDisabled() });
  }

  onRemove = async () => {
    const { requestSubType } = this.state;
    const { refetchData } = this.props;
    this.setState({ loading: true });

    await requestSubTypeDelete({
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
      companyUUID: window.global_store.company.uuid,
      requestType: (requestSubType as RequestSubType).requestType, // can't be null
      requestSubtypeUuid: (requestSubType as RequestSubType).uuid,
    });

    this.setState({ loading: false });

    refetchData();
  };

  getFormErrors = (): Record<string, string> | null => {
    const { t } = this.props;
    const { approvalFlow } = this.state;
    const errors: Record<string, string> = {};

    if (approvalFlow?.length) {
      if (approvalFlow.filter((af) => !af.approver || !af.approver.uuid).length === 3) {
        errors.approver0 = t("Please select at least one approval level");
      } else if (
        (!approvalFlow[0].approver || !approvalFlow[0].approver.uuid) &&
        approvalFlow[1].approver &&
        approvalFlow[1].approver.uuid
      ) {
        errors.approver0 = t("Please choose approval level for the first stage");
      } else if (
        (!approvalFlow[1].approver || !approvalFlow[1].approver.uuid) &&
        approvalFlow[2].approver &&
        approvalFlow[2].approver.uuid
      ) {
        errors.approver1 = t("Please choose approval level for second stage");
      } else {
        approvalFlow.forEach((level) => {
          if (level.approver.uuid && level.limit !== null && !level.limit) {
            errors[`limits${level.stage}`] = t("Limit can't be empty");
          }
        });
      }
    } else {
      errors.approver0 = t("Please select at least one approval level");
    }

    if (Object.keys(errors).length === 0) {
      return null;
    }

    return errors;
  };

  onSave = async (): Promise<boolean> => {
    const { requestSubType, approvalFlow, hoursCalculationType, discountDsr } = this.state;
    const { t, onChange } = this.props;
    const errors = this.getFormErrors();

    if (!errors && requestSubType && approvalFlow) {
      const { active, requestSubtypeTemplateUuid, createdAt, deletedAt, updatedAt, paid, ...outputRequestSubType } =
        requestSubType;

      this.setState({ loading: true });

      const approvalFlowOutput = approvalFlow
        .filter((level) => level.approver.type && level.approver.uuid)
        .map((level) => {
          const newLevel = level;
          newLevel.limit =
            newLevel.limit || newLevel.limit === 0 || newLevel.limit === "0"
              ? parseInt(newLevel.limit as string, 10)
              : null;

          const { label, ...approver } = newLevel.approver;
          newLevel.approver = approver;

          return newLevel;
        });

      try {
        await requestSubTypeUpdate({
          companyUUID: window.global_store.company.uuid,
          requestType: requestSubType.requestType,
          requestSubtypeUuid: requestSubType.uuid,
          body: {
            content: {
              ...outputRequestSubType,
              discountDsr,
              name: this.props.requestSubType.name,
              approvalFlow: approvalFlowOutput,
              hoursCalculationType,
              updatedBy: window.global_store.profile.uuid,
            },
          },
        });

        this.setState({ loading: false });
        onChange();

        return true;
      } catch (e) {
        const error = e as Error;

        if (error.message) {
          this.setState({ notification: t(error.message), loading: false });
        }

        return false;
      }
    } else {
      this.setState({ errors });

      return false;
    }
  };

  shouldBeDisabled = () => {
    const { requestSubType, loading } = this.state;

    if (loading) {
      return true;
    }

    if (requestSubType?.complianceRules) {
      const { daysBeforeRequestDate, totalDaysLimit } = requestSubType.complianceRules;

      if (daysBeforeRequestDate.active && !daysBeforeRequestDate.days) {
        return true;
      }

      if (totalDaysLimit.active && !totalDaysLimit.days) {
        return true;
      }
    }

    return false;
  };

  render() {
    const {
      hoursCalculationType,
      approvalFlow,
      deleteConfirmationVisible,
      requestSubType,
      loaded,
      loading,
      errors,
      deleteForbidden,
      notification,
      notificationType,
      permissionRoles,
      discountDsr,
    } = this.state;
    const { t } = this.props;

    if (!loaded || !requestSubType) {
      return <Spinner size="medium" />;
    }

    return (
      <PageWrapper>
        {notification && (
          <NotificationRow
            style={{ marginTop: 0, marginBottom: "10px" }}
            type={notificationType}
            message={notification}
          />
        )}
        <RequestSettingsFields
          requestSubType={requestSubType}
          onChange={(val: { requestSubType: RequestSubType }) => {
            this.setState({ requestSubType: val.requestSubType });
          }}
        />

        {(requestSubType.requestType === RequestTypeName.hbConvert ||
          requestSubType.requestType === RequestTypeName.scheduleAssignment) && (
          <FieldsGroup label={t("Visibility")} width="350px">
            <FieldWrapper fieldName={t("Permission roles")} width="218px" fieldTitleMarginBottom={8}>
              <SelectControl<string>
                isMulti
                value={requestSubType.visibilityPermissionRoles}
                options={permissionRoles
                  .filter((pr) => pr.name !== PermissionRoleName.owner)
                  .map((pr) => ({ value: pr.uuid, label: pr.predefined ? t(pr.name) : pr.name }))}
                onChange={(uuids: string[]) =>
                  this.setState({
                    requestSubType: { ...requestSubType, visibilityPermissionRoles: uuids },
                  })
                }
              />
            </FieldWrapper>
          </FieldsGroup>
        )}

        {(![RequestTypeName.overtime, RequestTypeName, RequestTypeName.scheduleAssignment].includes(
          requestSubType.requestType,
        ) ||
          RequestTypeName.other === requestSubType.requestType ||
          !requestSubType.requestSubtypeTemplateUuid) && (
          <FieldsGroup label={t("HOURS CALCULATION")} width="350px">
            {![RequestTypeName.overtime, RequestTypeName.hbConvert].includes(requestSubType.requestType) && (
              <FieldWrapper width="218px" fieldTitleMarginBottom={8}>
                <FormSelect<RequestHoursCalculationType>
                  modifiers={{ field: true }}
                  value={hoursCalculationType}
                  onChange={(value) => this.setState({ hoursCalculationType: value })}
                  options={this.hoursCalculationOptions}
                />
              </FieldWrapper>
            )}
            {(requestSubType.requestType === RequestTypeName.other || !requestSubType.requestSubtypeTemplateUuid) && (
              <FieldWrapper width="218px" fieldTitleMarginBottom={8}>
                <CheckboxControl
                  checked={discountDsr}
                  label={t("Discount DSR")}
                  onChange={(checked) => {
                    this.setState({ discountDsr: checked });
                  }}
                />
              </FieldWrapper>
            )}
          </FieldsGroup>
        )}

        <RequestSettingsApprovalFlow
          errors={errors}
          approvalFlow={approvalFlow as RequestSubTypeApprovalFlow[]}
          onChange={(val: RequestSubTypeApprovalFlow[]) => {
            this.setState({ approvalFlow: val });
          }}
        />

        {(window.global_store.beta || requestSubType.externalId) && (
          <FieldWrapper fieldName={t("External Id")} width="200px">
            <TextInputControl
              disabled={!window.global_store.beta}
              value={requestSubType.externalId}
              onChange={(val: string) =>
                this.setState({
                  requestSubType: { ...requestSubType, externalId: val },
                })
              }
              size={60}
            />
          </FieldWrapper>
        )}

        <ButtonWrapper>
          {![RequestTypeName.scheduleAssignment, RequestTypeName.overtime].includes(requestSubType.requestType) && (
            <AddRemoveLink
              label={t("Remove category")}
              remove
              onClick={() => {
                if (requestSubType.externalId) {
                  this.setState({ deleteForbidden: true });
                } else if (!loading) {
                  this.setState({ deleteConfirmationVisible: true });
                }
              }}
            />
          )}
        </ButtonWrapper>

        <ModalDialog
          isOpen={deleteConfirmationVisible}
          onClose={() => this.setState({ deleteConfirmationVisible: false })}
        >
          <Lightbox
            title={t("Delete this category?")}
            text={t("Are you sure you want to delete this category?")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ deleteConfirmationVisible: false });
            }}
            onYes={() => {
              void this.onRemove();
            }}
          />
        </ModalDialog>

        <ModalDialog isOpen={deleteForbidden} onClose={() => this.setState({ deleteForbidden: false })}>
          <Lightbox
            title={t("You can't delete category")}
            text={t("You can't delete category which has external id set")}
            buttonYesTitle={t("common|Ok")}
            noCancelButton
            onClose={() => {
              this.setState({ deleteForbidden: false });
            }}
            onYes={() => {
              this.setState({ deleteForbidden: false });
            }}
          />
        </ModalDialog>
      </PageWrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp])(RequestSettingsSubPage);
