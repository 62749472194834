import { Component } from "react";
import TextInput from "components/controls/TextInputControlNew";
import { withTranslation } from "react-i18next";
import { changePassword, getPasswordValidation } from "utils/apiHelpers";
import { showSnackbar } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";

class ForgotPasswordPage extends Component {
  state = {
    confirmPassword: "",
    password: "",
    resetPasswordToken: "",
    loading: false,
    email: "",
    passwordValidations: {},
  };

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Change Password");

    const resetPasswordToken = ForgotPasswordPage.getUrlParameter("resetPasswordToken");
    const email = ForgotPasswordPage.getUrlParameter("email");
    if (!resetPasswordToken) {
      showSnackbar({ text: t("Reset Password Token is not Provided") });
    }

    this.setState({ resetPasswordToken, email }, this.getPasswordValidationRegExp);
  }

  static getDerivedStateFromProps(nextProps) {
    const { t } = nextProps;

    const resetPasswordToken = ForgotPasswordPage.getUrlParameter("resetPasswordToken");
    const email = ForgotPasswordPage.getUrlParameter("email");
    if (!resetPasswordToken) {
      showSnackbar({ text: t("Reset Password Token is not Provided") });
    }

    return { resetPasswordToken, email };
  }

  static getUrlParameter(val) {
    const name = val.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  getPasswordValidationRegExp = async () => {
    const { email } = this.state;

    if (email) {
      try {
        const passwordValidations = await getPasswordValidation(email);

        this.setState({ passwordValidations });
      } catch (err) {
        console.log(err);
      }
    }
  };

  validatePassword = () => {
    const { password, passwordValidations } = this.state;
    const { t } = this.props;
    let error = "";

    if (passwordValidations.validations?.length) {
      passwordValidations.validations.forEach((v) => {
        if (!password.match(new RegExp(v.regex)) && !error) {
          error = t(v.constrain_key);
        }
      });
    } else if (passwordValidations.entirePassword) {
      if (!password.match(new RegExp(passwordValidations.entirePassword))) {
        error = t("Password is not strong enough");
      }
    } else if (!password) {
      error = t("Password can't be empty");
    } else if (password.length < 6) {
      error = t("Password must contain at least 6 charactersy");
    } else if (password.length > 20) {
      error = t("Password can contain no more than 20 characters");
    } else {
      error = "";
    }

    return error;
  };

  validateConfirmPassword = () => {
    const { confirmPassword, password } = this.state;
    const { t } = this.props;
    let error = "";

    if (confirmPassword !== password) {
      error = t("Password does not match the confirm password");
    } else {
      error = "";
    }

    return error;
  };

  onNextClick = (ev) => {
    const { resetPasswordToken } = this.state;
    const { t } = this.props;
    let errors = {};

    ev.preventDefault();

    if (this.validatePassword()) {
      errors.password = t(this.validatePassword());
    }
    if (this.validateConfirmPassword()) {
      errors.confirmPassword = t(this.validateConfirmPassword());
    }
    if (!resetPasswordToken) {
      showSnackbar({ text: t("Reset Password Token is not Provided") });
    } else if (Object.keys(errors).length === 0) {
      errors = false;
      this.setState({ errors });
      this.submitForm();
    } else {
      this.setState({ errors });
    }
  };

  submitForm = async () => {
    const { password, resetPasswordToken } = this.state;

    this.setState({ loading: true });
    try {
      await changePassword(password, resetPasswordToken);
      this.setState({ loading: false }, () => this.props.history.push("/login"));
    } catch (err) {
      showSnackbar({ text: err });
      this.setState({ loading: false });
    }
  };

  render() {
    const { confirmPassword, password, errors, loading } = this.state;
    const { t } = this.props;

    return (
      <MainPage
        buttonText={t("Change")}
        pageTitle={t("Recover password")}
        loading={loading}
        onSubmit={this.onNextClick}
        formParams={{
          onSubmit: this.onNextClick,
          noValidate: "novalidate",
          method: "post",
        }}
      >
        <OnboardingFieldWrapper fieldName={t("Password")}>
          <TextInput
            name="password"
            onboarding
            value={password}
            type="password"
            error={!!errors?.password}
            password
            onChange={(ev) => this.setState({ password: ev.target.value })}
          />
          {!!errors?.password && <ErrorLabel>{errors.password}</ErrorLabel>}
        </OnboardingFieldWrapper>
        <OnboardingFieldWrapper fieldName={t("Confirm password")}>
          <TextInput
            name="confirmPassword"
            onboarding
            value={confirmPassword}
            type="password"
            error={!!errors?.confirmPassword}
            password
            onChange={(ev) => this.setState({ confirmPassword: ev.target.value })}
          />
          {!!errors?.confirmPassword && <ErrorLabel>{errors.confirmPassword}</ErrorLabel>}
        </OnboardingFieldWrapper>
      </MainPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(ForgotPasswordPage);
