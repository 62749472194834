import { generateUrlParams, iUrlParamsData } from "utils/common";
import { APIResponse, sendRequest } from "./common";
import {
  GetAllPunchesResponseData,
  AddPunchRequestData,
  GetPunchDetailsRequestData,
  GetPunchDetailsResponseData,
} from "./types";

// TODO change this to proper types
type TODORequestData = any;
type TODOResponseData = Promise<any>;

export function getAllPunches(data: Partial<iUrlParamsData> = {}): APIResponse<GetAllPunchesResponseData> {
  let url = "/punches?";
  url += generateUrlParams(data);

  return sendRequest({}, "get", url);
}

export function getPunchDetails(data: GetPunchDetailsRequestData): APIResponse<GetPunchDetailsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/punches/${data.punchId}${data.newHierarchyPermissions ? "/?new_hierarchy_permissions=true" : ""}`,
  );
}

export function addPunch(data: AddPunchRequestData): APIResponse<void> {
  return sendRequest(data.body, "POST", "/punches");
}

export function approvePunch(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "PATCH", `/punches/${data.punchUuid}/approve/`);
}

export function declinePunch(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PATCH", `/punches/${data.punchUuid}/decline/`);
}

export function validatePunch(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/punches/${data.punchId}/validate/`);
}

export function validateAllRange(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", "/punches/validate_all");
}

export function validateMultiplePunches(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PATCH", "/punches/validate");
}

export function declineMultiplePunches(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PATCH", "/punches/decline");
}

export function approveAllRange(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", "/punches/approve_all");
}
