import { InputHTMLAttributes } from "react";
import c from "classnames";
import styled from "styled-components";
import BEM from "utils/BEM";
import * as images from "components/svg-images";
import "./SearchInput.scss";

const b = BEM.b("ui-search-input");

const Wrapper = styled.div`
  & > svg {
    position: absolute;
    top: 10px;
    inset-inline-start: 9px;
    color: var(--colors-activitiInfoBlockValue);
  }
`;

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  modifiers?: string[];
}

const SearchInput = ({ className, modifiers, ...props }: SearchInputProps): JSX.Element => (
  <Wrapper className={b(modifiers)}>
    {images.searchLens}
    <input type="text" className={c(b("input-field"), className)} {...props} />
  </Wrapper>
);

export default SearchInput;
