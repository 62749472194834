import FieldWrapper from "components/UI/FieldWrapper";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import TimeControl from "components/controls/TimeControl";
import { hrsMinsToMins, minsToHrsMins } from "utils/common";
import { RequestSubtypeSpecificFieldTypes, RequestTypeSpecificField } from "types/models/request";
import { ErrorLabel } from "components/UI/TextLabels";
import { RequestsAddEditPopupErrors } from "../RequestAddEditPopup";

const MultipleFiledsWrapper = styled.div`
  display: flex;
  margin: 8px 0;
  gap: 8px;
  > div {
    flex: 1;
  }
`;

type SubtypeSpecificFieldsOvetimeEnableDayProps = {
  userProfileUuid: string | null;
  errors: RequestsAddEditPopupErrors;
  field: RequestTypeSpecificField;
  onTimeChange: (value: RequestTypeSpecificField) => void;
};

const getTotal = (from: number, to: number) => {
  if (from <= to) {
    return to - from;
  }

  return 24 * 60 - (from - to);
};

const SubtypeSpecificFieldsOvetimeEnableDay = ({
  userProfileUuid,
  errors,
  field,
  onTimeChange,
}: SubtypeSpecificFieldsOvetimeEnableDayProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  const fromValue = field.value?.from || 0;
  const toValue = field.value?.to || 0;
  const totalValue = getTotal(fromValue, toValue);

  return (
    <>
      <MultipleFiledsWrapper key={RequestSubtypeSpecificFieldTypes.adjustment}>
        <FieldWrapper fieldName={t("Start")} width="100%" fieldTitleMarginBottom={0}>
          <TimeControl
            disabled={!userProfileUuid}
            style={{
              paddingInlineStart: 12,
            }}
            value={minsToHrsMins(fromValue)}
            onTimeChange={({ value, error }) => {
              const newField = { ...field };
              newField.value = {
                from: !error ? hrsMinsToMins(value) : 0,
                to: toValue,
              };
              onTimeChange(newField);
            }}
            placeholder="00:00"
            error={!!errors?.tsfErrors?.[field.name]}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("End")} width="100%" fieldTitleMarginBottom={0}>
          <TimeControl
            disabled={!userProfileUuid}
            style={{
              paddingInlineStart: 12,
            }}
            value={minsToHrsMins(toValue)}
            onTimeChange={({ value, error }) => {
              const newField = { ...field };
              newField.value = {
                from: fromValue,
                to: !error ? hrsMinsToMins(value) : 0,
              };
              onTimeChange(newField);
            }}
            placeholder="00:00"
            error={!!errors?.tsfErrors?.[field.name]}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Total")} width="100%" fieldTitleMarginBottom={0}>
          <TimeControl
            style={{
              borderColor: "var(--colors-surface-150)",
              paddingInlineStart: 12,
              color: "var(--colors-surface-500)",
            }}
            disabled
            value={minsToHrsMins(totalValue)}
            onTimeChange={undefined}
            placeholder="00:00"
          />
        </FieldWrapper>
      </MultipleFiledsWrapper>
      {!!errors?.tsfErrors?.[field.name] && <ErrorLabel>{errors.tsfErrors[field.name]}</ErrorLabel>}
    </>
  );
};

export default SubtypeSpecificFieldsOvetimeEnableDay;
