import React, { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import SelectControl from "components/UI/SelectControl";
import { useTranslation } from "react-i18next";
import TextInputControl from "./TextInputControlNew";
import BEM from "../../utils/BEM";

const b = BEM.b("cell-with-edit");
const Wrapper = styled.div`
  & .multi-select {
    line-height: 16px;
  }

  div[id*="tooltip-for-"] {
    line-height: 15px;
    padding: 5px 7px;
    font-size: 12px;
    border-radius: 3px;
  }
`;
const Cell = styled.div`
  line-height: 34px;
  border: 1px solid transparent;
  border-radius: var(--shapes-border-radius-default);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
  &:hover {
    border-color: ${({ withHover }) => (withHover ? "#a1b2cf" : "transparent")};
  }
`;
const ActiveCell = styled.div`
  & .cell-input input {
    padding: 8px 15px;
    font-size: var(--typography-font-size-default);
  }
  & .cell-input.error input {
    color: var(--colors-error);
  }
`;

const CellWithEdit = ({ type = "input", editable = true, initialValue, onBlur, inputProps = {}, id }) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState(initialValue); // string | { label: string, value: any }
  const [errorMsg, setErrorMsg] = useState(null);
  const inputEl = useRef(null);
  const { t } = useTranslation();
  const tooltipText = t("Click to Edit");

  useEffect(() => {
    if (active && inputEl.current && editable) {
      inputEl.current.focus();
    }
  }, [active]);

  const onTextinputChange = (ev) => {
    setValue(ev.target.value);
    setErrorMsg(null);
  };
  const onMultiDropDownChange = (values) => {
    setValue(inputProps.options.filter((o) => values?.indexOf(o.value) > -1));
    setErrorMsg(null);
  };

  const handleBlur = () => {
    // eslint-disable-next-line
    const error = onBlur?.(value);
    setErrorMsg((error?.message || error) ?? null);
  };

  return (
    <Wrapper className={b("")}>
      {type === "multi-dropdown" && (
        <MultiDropdownCell
          id={id}
          inputProps={inputProps}
          placeholder={errorMsg || inputProps.placeholder}
          active={active}
          setActive={setActive}
          editable={editable}
          innerRef={inputEl}
          values={value}
          onChange={onMultiDropDownChange}
          onBlur={handleBlur}
          error={!!errorMsg}
          tooltipText={tooltipText}
        />
      )}
      {type === "input" && (
        <TextInputCell
          id={id}
          inputProps={inputProps}
          placeholder={inputProps.placeholder}
          active={active}
          setActive={setActive}
          editable={editable}
          innerRef={inputEl}
          value={value}
          onChange={onTextinputChange}
          onBlur={handleBlur}
          error={!!errorMsg}
          tooltipText={tooltipText}
        />
      )}
    </Wrapper>
  );
};

export default CellWithEdit;

const MultiDropdownCell = ({
  editable,
  active,
  setActive,
  onBlur,
  onChange,
  values,
  innerRef,
  error,
  inputProps,
  id,
  tooltipText,
}) => {
  if (active || error || !values.length) {
    return (
      <ClickOutside onClickOutside={() => setActive(false)}>
        <ActiveCell className={b("active")}>
          <SelectControl
            {...inputProps}
            innerRef={innerRef}
            isMulti
            value={values.map((v) => v.value)}
            onChange={onChange}
            onFocus={() => !active && setActive(true)} // when blurred with error state
            onBlur={onBlur}
            error={error}
          />
        </ActiveCell>
      </ClickOutside>
    );
  }

  // not active & without error
  return (
    <>
      <Cell
        onClick={() => setActive(true)}
        withHover={editable}
        className={b("cell")}
        data-tip
        data-for={`tooltip-for-dropdown-${id}`}
      >
        {values.map((v) => v.label).join(", ")}
      </Cell>
      {editable && (
        <ReactTooltip
          id={`tooltip-for-dropdown-${id}`}
          place="top"
          effect="float"
          className="react-tooltip"
          delayShow={1}
          backgroundColor="#525f7f"
        >
          <span>{tooltipText}</span>
        </ReactTooltip>
      )}
    </>
  );
};

const TextInputCell = ({
  editable,
  active,
  setActive,
  onBlur,
  onChange,
  value,
  innerRef,
  error,
  placeholder,
  inputProps,
  id,
  tooltipText,
}) => {
  if (active || error) {
    return (
      <ClickOutside onClickOutside={() => setActive(false)}>
        <ActiveCell className={b("active")}>
          <TextInputControl
            {...inputProps}
            placeholder={placeholder}
            className="cell-input"
            innerRef={innerRef}
            value={value}
            onChange={onChange}
            onFocus={() => !active && setActive(true)} // when blurred with error state
            onBlur={onBlur}
            error={error}
          />
        </ActiveCell>
      </ClickOutside>
    );
  }

  // not active & without error
  return (
    <>
      <Cell
        onClick={() => setActive(true)}
        withHover={editable}
        className={b("cell")}
        data-tip
        data-for={`tooltip-for-input-${id}`}
      >
        {value}
      </Cell>
      {editable && (
        <ReactTooltip
          id={`tooltip-for-input-${id}`}
          place="top"
          effect="float"
          className="react-tooltip"
          delayShow={1}
          backgroundColor="#525f7f"
        >
          <span>{tooltipText}</span>
        </ReactTooltip>
      )}
    </>
  );
};
