import React, { Component } from "react";
import styled from "styled-components";
import Tooltip from "components/UI/Tooltip";
import Item from "./Item";
import GroupItem from "./GroupItem";
import LazyListLoading from "./LazyListLoading";

const GroupWrapper = styled.div`
  height: ${(p) => (p.expanded ? "auto" : "36px")};
  overflow: hidden;
`;
export default class MenuItems extends Component {
  state = {
    loading: true,
  };

  getGroupName = (items, uuid, type) => {
    const group = items[uuid][0][type];

    if (group instanceof Array) {
      return group[0].name || group[0].title;
    }

    return group.name || group.title;
  };

  getTooltipText = (items, uuid, type) => {
    const group = items[uuid][0][type];
    const code = `${group.id}`;

    if (group instanceof Array) {
      return group[0].name || group[0].title;
    }

    return `${code} - ${group.name || group.title}`;
  };

  render() {
    const { items, name, type, setEmployees, setSelectedGroup, isSelected, searchMode } = this.props;

    return Object.keys(items).length ? (
      <GroupWrapper expanded={isSelected || searchMode}>
        {!searchMode ? (
          <GroupItem
            onClick={() => setSelectedGroup(isSelected ? "" : type)}
            className={isSelected ? "expanded" : "collapsed"}
          >
            {name}
          </GroupItem>
        ) : null}
        <LazyListLoading style={{ height: 210 }}>
          {Object.keys(items).map((uuid) => (
            <div key={uuid} style={{ position: "relative" }}>
              <Item
                key={uuid}
                data-tip={type === "department" || type === "subsidiary" || type === "position"}
                data-for={uuid}
                onClick={() =>
                  setEmployees({
                    employees: items[uuid],
                    groupName: this.getGroupName(items, uuid, type),
                  })
                }
              >
                {this.getGroupName(items, uuid, type)}
              </Item>
              {(type === "department" || type === "subsidiary" || type === "position") && (
                <Tooltip id={uuid}>{this.getTooltipText(items, uuid, type)}</Tooltip>
              )}
            </div>
          ))}
        </LazyListLoading>
      </GroupWrapper>
    ) : null;
  }
}
