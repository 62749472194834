import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { TFunction } from "react-i18next";
import BEM from "utils/BEM";
import { RequestAttachmentsRowProps } from "components/Requests/RequestAttachmentsRow";
import { downloadAttachment } from "../../../utils/apiHelpers";
import * as images from "../../svg-images";
import pdfPreview from "../../../img/pdfPreview.svg";
import "./Slideshow.scss";

const b = BEM.b("slideshow");

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never;

type File = ArrElement<RequestAttachmentsRowProps["files"]>;

interface SlideshowProps {
  currentImgIndex: number;
  isOpen: boolean;
  onClose: () => void;
  attachments: RequestAttachmentsRowProps["files"];
  t: TFunction;
  hideHeader?: boolean;
}

interface SlideshowState {
  slideIndex: number;
}

class Slideshow extends Component<SlideshowProps, SlideshowState> {
  portalNode: HTMLDivElement;

  constructor(props: SlideshowProps) {
    super(props);
    this.state = {
      slideIndex: this.props.currentImgIndex,
    };

    this.portalNode = document.body.appendChild(document.createElement("div"));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: SlideshowProps) {
    this.setState({ slideIndex: nextProps.currentImgIndex });
  }

  componentWillUnmount() {
    this.portalNode.remove();
  }

  plusSlides = (n: number) => {
    let index = this.state.slideIndex;

    this.setState({ slideIndex: (index += n) });
  };

  currentSlide = (n: number) => {
    this.setState({ slideIndex: n });
  };

  onDownloadClick(file: File) {
    let { url } = file;

    const mimeType = file.mimeType || file.mime_type || file.type;
    if (mimeType?.indexOf("image/") !== -1 || mimeType?.indexOf("application/image") !== -1) {
      url += ".jpg";
    }

    void downloadAttachment({
      url,
      title: file.title ? file.title.replace(/\.[^.]*$/, "") : "attachment",
      extension: mimeType === "application/pdf" ? ".pdf" : ".jpg",
    });
  }

  render() {
    const { slideIndex } = this.state;
    const { isOpen, onClose, attachments, t, hideHeader } = this.props;
    const attachmentsCount = attachments.length;

    if (!isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <div className={b()}>
        <div className={b("header")}>
          <div className={b("info")}>
            {!hideHeader && (
              <div className={b("file-info")}>
                <div className={b("file-name")}>{attachments[slideIndex].title}</div>

                {attachments[slideIndex] && attachments[slideIndex].createdAt && (
                  <div className={b("file-date")}>
                    {moment(attachments[slideIndex].createdAt).format("HH:mm ddd DD/MM/YYYY")}
                  </div>
                )}
              </div>
            )}

            {!hideHeader && (
              <div className={b("download-button")} onClick={() => this.onDownloadClick(attachments[slideIndex])}>
                {images.downloadIcon}
                {t("Download")}
              </div>
            )}
          </div>
          <div className={b("close")} onClick={onClose}>
            {images.slideshowClose}
          </div>
        </div>

        <div className={b("container")}>
          <div className={b("slides")}>
            {attachments.map((img, i) => (
              <div key={img.uuid || i} className={`${b("slide", { active: slideIndex === i })} fade`}>
                {img.mimeType === "application/pdf" ? (
                  <img src={pdfPreview} alt="" />
                ) : (
                  <img className={b("image")} src={img.url} alt="" />
                )}
              </div>
            ))}
          </div>
        </div>

        {slideIndex > 0 && (
          // eslint-disable-next-line
          <a className={b("prev")} onClick={() => this.plusSlides(-1)}>
            {images.slideshowLeft}
          </a>
        )}
        {slideIndex < attachmentsCount - 1 && (
          // eslint-disable-next-line
          <a className={b("next")} onClick={() => this.plusSlides(1)}>
            {images.slideshowRight}
          </a>
        )}
      </div>,
      this.portalNode,
    );
  }
}

export default Slideshow;
