export enum ApplicationStatus {
  Active = 'Active',
  Failed = 'Failed',
}

export enum ApplicationType {
  Jira = 'Jira',
  Asana = 'Asana',
  Clickup = 'Clickup',
  Todoist = 'Todoist',
  Monday = 'Monday',
  Trello = 'Trello',
  Basecamp = 'Basecamp',
  Notion = 'Notion',
  Quickbooks = 'Quickbooks'
}

export type Application = {
  uuid: string;
  companyUuid: string;
  type: ApplicationType,
  status: ApplicationStatus,
}
