import { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;

class LazyListLoading extends Component {
  static defaultProps = {
    children: [],
    step: 10,
    offsetPx: 20,
  };

  state = {
    childrenToShow: [],
  };

  componentDidMount() {
    this.loadChildren(this.props.step);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.updateChildren(this.props.children?.[0]?.key !== prevProps.children?.[0]?.key);
    }
  }

  /** resetChildren is used when different set of children is passed in.
   * For example AvailableEmployees component will receive new set of entities
   * every time different group is selected.
   * If resetChildren is false, then only update is performed (e.g. to show UI changes) */
  updateChildren = (resetChildren) => {
    const { step } = this.props;

    if (resetChildren) {
      this.setState({ childrenToShow: [] }, () => this.loadChildren(step));
    } else {
      const { children } = this.props;
      const { childrenToShow } = this.state;

      // step 0 performs only children update without adding new chunk
      this.loadChildren(children.length > childrenToShow.length ? step : 0);
    }
  };

  loadChildren = (step) => {
    const { children } = this.props;
    const { childrenToShow } = this.state;

    this.setState({ childrenToShow: children.slice(0, childrenToShow.length + step) });
  };

  onScroll = (e) => {
    const { offsetPx, step } = this.props;
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight - scrollTop - clientHeight < offsetPx) {
      this.loadChildren(step);
    }
  };

  render() {
    const { style } = this.props;
    const { childrenToShow } = this.state;

    return (
      <Wrapper style={style} onScroll={this.onScroll}>
        {childrenToShow}
      </Wrapper>
    );
  }
}

export default LazyListLoading;
