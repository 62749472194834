import { useTranslation, TranslationNamespaces } from "types/translationNamespaces";
import {
  IconDialogDelete,
  IconDialogRunning,
  IconDialogFields,
  IconDialogDiscard,
  IconDialogStopped,
  IconDialogStoppedWarn,
  IconStopwatch,
} from "../styled";
import { ModalMessage } from "../ModalMessage";

export type DialogProps = React.HTMLProps<HTMLDivElement> & {
  onResult(res: boolean): void;
};

export function ExitWithoutSaveDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Discard changes?")}
      choice
      icon={IconDialogDiscard}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      okText={t("Discard")}
      cancelText={t("Cancel")}
    />
  );
}

export function ActivityStoppedOnPunchOutDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Tracking was stopped")}
      message={t("We stopped your last activity as it was\nout of your schedule")}
      choice
      vertical
      icon={IconDialogStopped}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      okText={t("Review")}
      cancelText={t("Skip")}
    />
  );
}

export function ActivityIncompleteStoppedOnPunchOutDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Tracking was stopped")}
      message={t("To save your last activity, you need to\nadd missing details")}
      choice
      vertical
      obligatory
      icon={IconDialogStoppedWarn}
      iconBackground="var(--colors-danger-50)"
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      okText={t("Add details")}
      cancelText={t("Delete activity")}
    />
  );
}

export function MandatoryFieldsDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Missing details")}
      message={t("Please add all the required details\nto stop the activity")}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      icon={IconDialogFields}
      okText={t("Add details")}
    />
  );
}

export function OutsideOfScheduleDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Outside of your schedule")}
      message={t("You can track time only during your scheduled working hours")}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      icon={IconStopwatch}
      okText={t("Okay")}
    />
  );
}

export function OutsideOfPunchInDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("You have not punched in")}
      message={t("You can track time only after punching in")}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      icon={IconStopwatch}
      okText={t("Okay")}
    />
  );
}

export function ActivityRunningDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Activity is tracking")}
      message={t("Please stop tracking current activity\nto start a new one")}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      icon={IconDialogRunning}
      okText={t("Add details")}
    />
  );
}

export function SuggestStartActivityDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      choice
      vertical
      header={t("What are you working on?")}
      message={t("Start tracking your progress now")}
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      icon={IconDialogRunning}
      okText={t("Select task")}
      cancelText={t("Skip")}
    />
  );
}

export function DeleteActivityDialog(props: DialogProps) {
  const { onResult } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <ModalMessage
      header={t("Delete this activity?")}
      choice
      warning
      icon={IconDialogDelete}
      iconBackground="#FFE0E0"
      onOk={() => onResult(true)}
      onCancel={() => onResult(false)}
      okText={t("Delete")}
      cancelText={t("Cancel")}
    />
  );
}
