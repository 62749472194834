import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";

const PunchNowButtonWrapper = styled.div`
  position: static;
  bottom: 20px;
  inset-inline-start: 0;
  z-index: 20;
`;

const PunchNowButtonNew = styled.div<{ $active: boolean }>`
  width: 120px;
  height: 40px;
  font-size: 15px;
  line-height: 38px;
  text-align: center;
  color: var(${(p) => (p.$active ? "--colors-theme-tr-clock-now-color-active" : "--colors-theme-tr-clock-now-color")});
  border-radius: 100px;
  border: 1px solid var(--colors-theme-tr-brdr);
  background: var(--colors-theme-tr-btn-bg);
  cursor: pointer;

  ${(p) =>
    !p.$active
      ? `
  &:hover {
    color: var(--colors-theme-tr-clock-now-color-hvr);
    background: var(--colors-theme-tr-clock-now-bg-hvr);
  }
  `
      : ""}
`;

type PunchNowButtonProps = {
  onPunchNowClick: () => void;
};

const PunchNow = (props: PunchNowButtonProps) => {
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const { onPunchNowClick } = props;
  const [active, setActive] = useState(false);

  return (
    <PunchNowButtonWrapper>
      <PunchNowButtonNew
        $active={active}
        onClick={() => {
          setActive(!active);
          onPunchNowClick();
        }}
      >
        {t("Clock Now")}
      </PunchNowButtonNew>
    </PunchNowButtonWrapper>
  );
};

export default PunchNow;
