import { FormHTMLAttributes, MouseEvent, PropsWithChildren, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Button, { ButtonState } from "components/controls/StyledButton";
import Snackbar from "components/styled/Snackbar";
import { TranslationNamespaces } from "types/translationNamespaces";
import SidePanel from "./SidePanel";
import config from "../../config";
import {
  Buttons,
  ButtonWrapper,
  Content,
  ContentFormWrapper,
  GetStarted,
  GetStartedButton,
  Logo,
  MainPanel,
  SubTitle,
  TextLabel,
  Title,
  TopRow,
  Wrapper,
} from "./styled";

interface OnboardingMainPageProps extends WithTranslation, PropsWithChildren {
  topRowText: string;
  pageTitle?: string;
  buttonText?: string;
  onSubmit?: (ev: MouseEvent) => void;
  topRowButtonText?: string;
  topRowButtonClick?: (ev: MouseEvent) => void;
  formParams?: FormHTMLAttributes<HTMLFormElement>;
  loading?: boolean;
  disabled?: boolean;
  customButtons?: ReactNode;
  customContent?: ReactNode;
  pageSubTitle?: string;
  maxWidth?: number;
}

const MainPage = (props: OnboardingMainPageProps) => {
  const {
    children,
    pageTitle,
    buttonText,
    topRowText,
    topRowButtonText,
    topRowButtonClick,
    onSubmit,
    loading,
    formParams,
    disabled,
    customButtons = null,
    customContent,
    pageSubTitle,
    maxWidth,
  } = props;

  return (
    <Wrapper>
      <SidePanel />
      <MainPanel>
        {!!topRowButtonClick && (
          <TopRow>
            <GetStarted>
              <TextLabel>{topRowText}</TextLabel>
              <GetStartedButton onClick={topRowButtonClick}>{topRowButtonText}</GetStartedButton>
            </GetStarted>
          </TopRow>
        )}

        <Logo dayIo={config.globalMode} />
        <ContentFormWrapper maxWidth={maxWidth}>
          {customContent ? (
            <Content>{children}</Content>
          ) : (
            <form {...formParams}>
              <Title>{pageTitle}</Title>
              { pageSubTitle ? <SubTitle>{pageSubTitle}</SubTitle> : null }
              <Content>{children}</Content>
              <Buttons>
                {!customButtons && (
                  <ButtonWrapper>
                    <Button
                      loading={loading}
                      state={ButtonState.primary}
                      disabled={!!disabled}
                      onboarding
                      value={buttonText}
                      onClick={onSubmit}
                    />
                  </ButtonWrapper>
                )}
                {customButtons}
              </Buttons>
            </form>
          )}
        </ContentFormWrapper>
      </MainPanel>
      <Snackbar />
    </Wrapper>
  );
};

export default withTranslation(TranslationNamespaces.signup)(MainPage);
