import React, { Component } from "react";
import BEM from "utils/BEM";
import "components/UI/Page/Page.scss";
import "./rep-popup.scss";
import { withTranslation } from "react-i18next";
import * as images from "../../svg-images";

const b = BEM.b("rep-popup");

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: "automatic",
      repName: "",
      nameError: false,
    };
  }

  closePopup() {
    this.props.onClose();
  }
  onSubmit() {
    if (!this.state.repName) {
      this.setState({ nameError: true });
    } else {
      this.props.onSubmit && this.props.onSubmit(this.state.selected, this.state.repName);
    }
  }

  render() {
    const { t } = this.props;
    const { selected, repName, nameError } = this.state;

    return (
      <div className={b("container")}>
        <div className={b("title")}>{t("Add a New REP")}</div>
        <div className={b("field-title")}>{t("Rep name")}</div>
        <div className={b("field")}>
          <input
            className={b("input", { error: nameError })}
            type="text"
            value={repName}
            onChange={(ev) => this.setState({ repName: ev.target.value })}
            placeholder={t("Rep name")}
          />
        </div>
        <div className={b("subtitle")}>{t("Select the connection type")}</div>
        <div className={b("types")}>
          <div
            className={b("type", { active: selected === "automatic", automatic: true })}
            onClick={() => this.setState({ selected: "automatic" })}
          >
            <div className={b("type-image", { active: selected === "automatic", automatic: true })}>
              {images.pegadorAutomaticRep(selected === "automatic")}
            </div>
            <div className={b("type-title", { active: selected === "automatic" })}>{t("Automatic")}</div>
          </div>
          <div
            className={b("type", { active: selected === "manual", automatic: true })}
            onClick={() => this.setState({ selected: "manual" })}
          >
            <div className={b("type-image", { active: selected === "manual", manual: true })}>
              {images.pegadorManualRep(selected === "manual")}
            </div>
            <div className={b("type-title", { active: selected === "manual" })}>{t("Manual")}</div>
          </div>
        </div>
        <div className={b("buttons")}>
          <button className={b("button", ["secondary"])} onClick={this.closePopup.bind(this)}>
            {t("Cancel")}
          </button>
          <button className={b("button", ["primary"])} onClick={this.onSubmit.bind(this)}>
            {t("Setup a REP")}
          </button>
        </div>
      </div>
    );
  }
}
export default withTranslation("reps")(Index);
