import { useState } from "react";
import { useAsyncEffect, reactAsync, useAsyncCallback } from "utils/useAsyncEffect";
import { WidgetOptionsService, Profile } from "services/widget-options-service";
import { languages } from "utils/languages";
import { OptionsView, OptionsViewProps } from "../OptionsView";

export interface WidgetOptionsProps {
  optionsService: WidgetOptionsService;
}

export function WidgetOptions(props: WidgetOptionsProps) {
  const { optionsService } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const [profile, setProfile] = useState<Profile>();
  const [locale, setLocale] = useState<string>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [companies, setCompanies] = useState<Profile[]>([]);
  useAsyncEffect(
    async () => {
      const locale = await optionsService.getLocale();
      setLocale(locale);
      const { currentProfile, profiles } = await optionsService.getProfiles();
      setProfile(currentProfile);
      setCompanies(profiles);
      setSelectedCompanyId(currentProfile.id);
    },
    [],
    setError,
    setLoading,
  );

  const handleProfileChange = (newProfile: OptionsViewProps["companies"][0]) => {
    void reactAsync(
      async () => {
        setSelectedCompanyId(null);
        const prof = companies.find((p) => p.id === newProfile.id);
        await optionsService.setProfile(prof!);
        setProfile(prof!);
        setSelectedCompanyId(prof!.id);
      },
      setError,
      setLoading,
    );
  };

  const [changeLang, changingLang] = useAsyncCallback(
    async (lang) => {
      await optionsService.setLocale(lang.key);
      setLocale(lang.key);
    },
    [optionsService],
  );

  if (error) throw Error(error);

  return (
    <OptionsView
      loading={loading || changingLang}
      onLogout={() => optionsService.logout()}
      profile={profile}
      companies={companies}
      selectedCompanyId={selectedCompanyId}
      onCompanySelected={handleProfileChange}
      languages={languages}
      languageKey={locale}
      onLanguageChange={changeLang}
    />
  );
}
