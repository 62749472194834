import { TFunction } from "i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import { IField } from "@flatfile/adapter/build/main/interfaces/obj.settings";
import moment from "moment";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Feature } from "types/models/subscription";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { AllowedAddFreeEmployees } from "../Billing/BillingService";

enum FeatureListString {
  TA = "ta",
  Projects = "projects",
  Bundle = "ta, projects",
}

export type EmployeeFields = {
  externalId: string;
  name: string;
  subsidiary: string;
  subsidiary_cnpj: string;
  cpf: string;
  pis: string;
  department: string;
  matricula: string;
  is_supervisor: boolean;
  supervisorExternalId: string;
  position: string;
  email: string;
  phone_number: string;
  schedule_uuid: string;
  schedule_start_date: string;
  is_deactivated: boolean;
  deactivation_date: string;
  business_rule_group: string;
  business_rule_group_start_date: string;
  holiday_group: string;
  payroll_group: string;
  associate_products: FeatureListString;
};
export const getEmployeeFields = (isUpdate: boolean, t: TFunction, allowedProducts: Feature[]): IField[] => {
  const getProductOptions = () => {
    const options = [];
    allowedProducts.forEach((product) => {
      options.push({ value: product, label: t(`import_product_option_${product}`) });
    });
    if (allowedProducts.length === 2) {
      options.push({
        value: FeatureListString.Bundle,
        label: t("import_product_option_bundle"),
        alternates: ["bundle"],
      });
    }
    return options;
  };

  const fields: IField[] = [
    {
      label: t("import_col_user_externalId"),
      key: "externalId",
      alternates: ["external_id"],
      validators: [{ validate: "required" }, { validate: "unique" }],
      description: t("import_col_user_externalId_description"),
    },
    {
      label: t("import_col_name"),
      key: "name",
      validators: [{ validate: "required" }],
      description: t("import_col_user_name_description"),
    },
    {
      label: t("import_col_email"),
      key: "email",
      validators: [
        { validate: "required_without", fields: ["phone_number"] },
        { validate: "unique" },
        {
          validate: "regex_matches",
          regex: `^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`,
          error: t("import_modal_error invalid email format"),
        },
      ],
      description: t("import_col_email_description"),
    },
    {
      label: t("import_col_phone_number"),
      key: "phone_number",
      validators: [{ validate: "unique" }, { validate: "required_without", fields: ["email"] }],
      description: t("import_col_phone_number_description"),
    },
    {
      label: t("import_col_associate_products"),
      key: "associate_products",
      validators: [{ validate: "required" }],
      description: t("import_col_associate_products_description"),
      type: "select",
      options: getProductOptions(),
    },
    {
      label: t("import_col_position"),
      key: "position",
      description: t("import_col_position_description"),
    },
    {
      label: t("import_col_department"),
      key: "department",
      description: t("import_col_department_description"),
    },
    {
      label: t("import_col_schedule"),
      key: "schedule_uuid",
      description: t("import_col_schedule_description"),
    },
    {
      label: t("import_col_schedule_start_date"),
      key: "schedule_start_date",
      description: t("import_col_schedule_start_date_description"),
      validators: [{ validate: "required_with", fields: ["schedule_uuid"] }],
    },
    {
      label: t("import_col_business_rule_group"),
      key: "business_rule_group",
      description: t("import_col_business_rule_group_description"),
    },
    {
      label: t("import_col_business_rule_group_start_date_group"),
      key: "business_rule_group_start_date",
      description: t("import_col_business_rule_group_start_date_description"),
      validators: [{ validate: "required_with", fields: ["business_rule_group"] }],
    },
    {
      label: t("import_col_holiday_group"),
      key: "holiday_group",
      description: t("import_col_holiday_group_description"),
    },
    {
      label: t("import_col_payroll_group"),
      key: "payroll_group",
      description: t("import_col_payroll_group_description"),
    },
    {
      label: t("import_col_is_supervisor"),
      key: "is_supervisor",
      type: "checkbox",
      description: t("import_col_is_supervisor_description"),
    },
    {
      label: t("import_col_matricula"),
      key: "matricula",
      description: t("import_col_matricula_description"),
    },
    {
      label: t("import_col_supervisor_externalId"),
      key: "supervisorExternalId",
      alternates: ["supervisor_external_id", "supervisor_externalId"],
      description: t("import_col_supervisor_externalId_description"),
    },
    {
      label: t("import_col_pis"),
      key: "pis",
      validators: [{ validate: "unique" }],
      description: t("import_col_pis_description"),
    },

    {
      label: t("import_col_subsidiary"),
      key: "subsidiary",
      description: t("import_col_subsidiary_description"),
    },
    {
      label: t("import_col_subsidiary_cnpj"),
      key: "subsidiary_cnpj",
      description: t("import_col_subsidiary_cnpj_description"),
    },
    {
      label: t("import_col_cpf"),
      key: "cpf",
      validators: [{ validate: "unique" }],
      description: t("import_col_cpf_description"),
    },
    {
      label: t("import_col_is_deactivated"),
      key: "is_deactivated",
      type: "checkbox",
      description: t("import_col_is_deactivated_description"),
    },
    {
      label: t("import_col_deactivation_date"),
      key: "deactivation_date",
      validators: [{ validate: "required_with_values", fieldValues: { is_deactivated: true } }],
      description: t("import_col_deactivation_date_description"),
    },
  ];

  return fields;
};

export const getFlatfileImportI18nOverrides = (t: TFunction) => {
  const overrides = {
    manual: translateEmployeeTerm(
      t,
      TranslationNamespaces.dataManagement,
      "custom-import_modal_manual_description",
      "import_modal_manual_description",
    ),
    buttons: {
      back: t("import_modal Go back"),
      cancel: t("import_modal Cancel"),
      clear: t("import_modal Clear data"),
      complete: t("import_modal Complete"),
      confirm: t("import_modal Confirm"),
      confirmMapping: t("import_modal Confirm mapping"),
      continue: t("import_modal Continue"),
      customField: t("import_modal Include as custom field"),
      edit: t("import_modal Edit"),
      ignore: t("import_modal Ignore this column"),
      no: t("import_modal No"),
      ok: t("import_modal Ok"),
      review: t("import_modal Review"),
      yes: t("import_modal Yes"),
      selectAnotherRow: t("import_modal Select another row"),
      noHeaderRow: t("import_modal No header row"),
    },
    errors: {
      boolean: t("import_modal_error error_boolean"),
      unresolvedFormat: t("import_modal_error You have {{errorCount}} rows with unresolved format issues.", {
        errorCount: "{{errorCount}}",
      }),
      configuration: t("import_modal_error There was a configuration error preventing the data importer from loading."),
      discardRowsWithErrors: t("import_modal_error Discard {{errorCount}} rows with issues. Submit the rest.", {
        errorCount: "{{errorCount}}",
      }),
      discardRowWithErrors: t("import_modal_error Discard 1 row with issues. Submit the rest."),
      dupHeaders: t("import_modal_error There are duplicate headers: {{headers}}. Please fix and upload again.", {
        headers: "{{headers}}",
      }),
      duplicateColumns: t("import_modal_error Sorry, several columns are matched to duplicate fields: {{fields}}", {
        fields: "{{fields}}",
      }),
      error: t("import_modal_error Errors: {{errors}}", { errors: "{{errors}}" }),
      featureDisabled: t("import_modal_error This feature is currently disabled, please contact support."),
      fieldCannotBeModified: t("import_modal_error This field cannot be modified."),
      fileReader: t("import_modal_error FileReader is not supported in this browser."),
      identifyUser: t("import_modal_error Please verify that you have identified the user uploading data."),
      maxRecords: t(
        "import_modal_error The maximum number of rows we accept is {{maxRecords}}. Consider breaking your file into multiple parts.",
        { maxRecords: "{{maxRecords}}" },
      ),
      missingRequiredFields: t(
        "import_modal_error Sorry, one or more of the required fields are unmatched: {{fields}}",
        { fields: "{{fields}}" },
      ),
      noRecords: t("import_modal_error Your file must have at least 1 row."),
      notSupported: t("import_modal_error This file type is not supported."),
      parsing: t("import_modal_error Parsing errors"),
      smallDevice: t("import_modal_error Please use a device with a larger screen to upload your bulk data."),
      unreadable: t("import_modal_error Cannot read file!"),
      unresolvedFormatSingular: t("import_modal_error You have one row with unresolved format issues."),
    },
    clearAllChanges: t("import_modal Are you sure you want to clear all changes to data in progress in this stage?"),
    dropzone: {
      accepted: t("import_modal {{types}} spreadsheets accepted.", { types: "{{types}}" }),
      button: t("import_modal_upload_CTA"),
    },
    fileTypes: t(
      "import_modal You can upload any {{file}} file with any set of columns as long as it has 1 record per row. The next step will allow you to match your spreadsheet columns to the right data points. You'll be able to clean up or remove any corrupted data before finalizing your report.",
      { file: "{{file}}" },
    ),
    header: translateEmployeeTerm(
      t,
      TranslationNamespaces.dataManagement,
      "custom-import-employees-with-number",
      "import_modal Bulk add {{number}} employees",
      { number: "{{number}}" },
    ),
    header2: translateEmployeeTerm(
      t,
      TranslationNamespaces.dataManagement,
      "custom-import-employees",
      "Import employees",
    ),
    lookupMatchingFields: t("import_modal Lookup matching fields"),
    processingPleaseWait: t("import_modal Processing, please wait"),
    readySubmit: t("import_modal Are you ready to submit?"),
    success: t("import_modal Success!"),
    unableToMatch: t("import_modal Unable to automatically match"),
    onlyShow: t("import_modal show-errored-rows"),
    modifications: t("import_modal Show modifications"),
    breadcrumbs: {
      columns: t("import_modal_breadcrumbs columns"),
      complete: t("import_modal_breadcrumbs Complete"),
      match: t("import_modal_breadcrumbs Match"),
      problems: t("import_modal_breadcrumbs  problems"),
      repair: t("import_modal_breadcrumbs Review"),
      spreadsheet: t("import_modal_breadcrumbs  your spreadsheet"),
      upload: t("import_modal_breadcrumbs Upload"),
    },
    chooseOne: t("import_modal Select one"),
    clearAndClose: t("import_modal Are you sure you want to close the importer and clear all data?"),
    columnHeader: t("import_modal Does this row contain column names?"),
    confirmClose: t(
      "import_modal Are you sure you want to close the importer? Any data entered or uploaded will be discarded.",
    ),
    confirmedMapping: t("import_modal Confirmed mapping"),
    reviewFix: t("import_modal Review and fix the format issues."),
    submitAnyway: t("import_modal Submit your data anyways (errors may occur)."),
    alreadyMatched: t("import_modal {{priorMatch}} already been matched to {{label}}.", {
      priorMatch: "{{priorMatch}}",
      label: "{{label}}",
    }),
    autoFormatted: t("import_modal This value has been automatically formatted."),
    autoMatchedField1: t("import_modal Matched to the"),
    autoMatchedField2: t("import_modal field."),
    rowsFail: t("import_modal {{failed}}% of rows fail validation (repair on next step)", { failed: "{{failed}}" }),
    rowsHaveValue: t("import_modal {{rows}} of your rows have a value for this column", { rows: "{{rows}}" }),
    validationPass: t("import_modal All values pass validation for this field"),
    ignored: t("import_modal Ignored"),
    headerSelection: {
      encoding: t("import_modal_header Encoding"),
      encodingTooltipContent: t(
        "import_modal_header Computer systems often use encoding schemes to store and retrieve data. If your data in this preview doesn’t look right, you may need to try other encoding schemes.",
      ),
      encodingTooltipTitle: t("import_modal_header Data Encoding"),
      question1: t("import_modal_header Does the selected row contain column headers?"),
      question2: t("import_modal_header Select the row containing column headers"),
      tooltip1Content: t(
        "import_modal_header Making this selection ensures that you'll have the data you need to move ahead. Once you have selected the useful data and the row containing column headers, you can skip this step in the future. Only one row of header data is currently supported.",
      ),
      tooltip1Title: t("import_modal_header Selecting your first row of data"),
      tooltip2Content: t(
        "import_modal_header Correctly identifying your column headers will allow you to validate your data accurately. If the highlighted row doesn’t contain your column headers, select another row. If your file does not have column headers at all, just select no header rows. Once you have made a selection, you can skip this step in the future.",
      ),
      tooltip2Title: t("import_modal_header Selecting your column headers"),
    },
    dialogs: {
      error: t("import_modal_dialogs Error"),
      loading: t("import_modal_dialogs Submitting..."),
      success: t("import_modal_dialogs Success"),
    },
    noData: t("import_modal No data to display"),
    noData2: t("import_modal No data"),
    noMatchSelected: t("import_modal No match selected. We suggest '{{suggestedMatch}}'", {
      suggestedMatch: "{{suggestedMatch}}",
    }),
    nothingPasted: t("import_modal There is no data pasted to be submitted!"),
    required: t("import_modal required"),
    uploading: t("import_modal Uploading..."),
    validationWarning: t("import_modal * We've automatically checked the validation of your first 1,000 rows."),
    validators: {
      required: t("import_modal_validators Required."),
      required_with: t(
        "import_modal_validators This field is required when ANY of the following fields are filled: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_with_all: t(
        "import_modal_validators This field is required when ALL of the following fields are filled: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_with_all_values: t(
        "import_modal_validators This field is required when ALL of the following fields have these values: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_with_values: t(
        "import_modal_validators This field is required when ANY of the following fields have these values: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_without: t(
        "import_modal_validators This field is required when ANY of the following fields are empty: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_without_all: t(
        "import_modal_validators This field is required when ALL of the following fields are empty: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_without_all_values: t(
        "import_modal_validators This field is required when NONE of the following fields have these values: {{fields}}",
        { fields: "{{fields}}" },
      ),
      required_without_values: t(
        "import_modal_validators This field is required when ANY of the following fields don't have these values: {{fields}}",
        { fields: "{{fields}}" },
      ),
      unique: t("import_modal_validators Must be unique."),
    },
    excel: {
      disabledSheet: t("import_modal_excel One sheet was not able to be parsed and is disabled."),
      modifications: t("import_modal_excel Show modifications"),
      multiDefault: t("import_modal_excel data sources"),
      multipleDisabledSheets: t(
        "import_modal_excel {{errorCount}} {{dataSource}} were not able to be parse, and are disabled. ",
        { errorCount: "{{errorCount}}", dataSource: "{{dataSource}}" },
      ),
      multiXls: t("import_modal_excel sheets"),
      multiXml: t("import_modal_excel elements"),
      selectSheet: t("import_modal_excel Select a sheet"),
      singleDefault: t("import_modal_excel data source"),
      singleXls: t("import_modal_excel sheet"),
      singleXml: t("import_modal_excel element"),
      statusLoadedSheets: t("import_modal_excel Loaded sheets"),
      statusUploaded: t("import_modal_excel File Uploaded"),
      stepFourDefault: t("import_modal_excel Loading data from {{dataSource}}", { dataSource: "{{dataSource}}" }),
      stepFourError: t("import_modal_excel Failed to load data from {{dataSource}}", { dataSource: "{{dataSource}}" }),
      stepOneDefault: t("import_modal_excel Uploading file"),
      stepOneError: t("import_modal_excel Error uploading file, please try again or select another file"),
      stepOneSuccess: t("import_modal_excel File uploaded"),
      stepThreeDefault: t("import_modal_excel Select {{dataSource}}", { dataSource: "{{dataSource}}" }),
      stepThreeError: t("import_modal_excel None of the {{dataSource}} could be broken down"),
      stepThreeSuccess: t(`import_modal_excel Selected {{dataSource}} "{{dataSourceLabel}}"`, {
        dataSource: "{{dataSource}}",
        dataSourceLabel: "{{dataSourceLabel}}",
      }),
      stepTwoDefault: t("import_modal_excel Loading {{dataSource}}", { dataSource: "{{dataSource}}" }),
      stepTwoError: t("import_modal_excel Error loading {{dataSource}}, please try again or choose another file", {
        dataSource: "{{dataSource}}",
      }),
      stepTwoSuccess: t("import_modal_excel Loaded {{dataSource}}", { dataSource: "{{dataSource}}" }),
    },
  };
  return {
    en: {
      otherLocales: ["en-US", "en-CA", "en-GB"],
      overrides,
    },
    pt: {
      otherLocales: ["pt-PT", "pt-BR"],
      overrides,
    },
  };
};
type GroupsData = Record<string, any> & {
  uuid: string;
  name: string;
};
const isDateFieldValid = (data: unknown): boolean =>
  !!data && !(moment(data, "DD-MM-YYYY", true).isValid() || moment(data, "DD/MM/YYYY", true).isValid());
const items: Record<number, string> = {};
const productUsage: Record<Feature.TA | Feature.Project, Set<number>> = {
  [Feature.TA]: new Set(),
  [Feature.Project]: new Set(),
};
export const employeeRecordValidationHook = (
  record: EmployeeFields,
  isUpdate: boolean,
  businessRuleGroups: GroupsData[],
  holidayGroups: GroupsData[],
  payrollGroups: GroupsData[],
  schedules: GroupsData[],
  externalIds: string[],
  t: TFunction,
  index: number,
  usedSeats: AllowedAddFreeEmployees,
  allowedProducts: Feature[],
): Record<string, unknown> => {
  const out: Record<string, unknown> = {};
  items[index] = record.externalId;

  if (isDateFieldValid(record.business_rule_group_start_date)) {
    out.business_rule_group_start_date = {
      info: [
        {
          message: t("Invalid format for date"),
          level: "error",
        },
      ],
    };
  }

  if (record.business_rule_group) {
    const bg = businessRuleGroups.find((item) => item.uuid === record.business_rule_group);
    if (!bg) {
      out.business_rule_group = {
        info: [
          {
            message: t("import_col_business_rule_group_not_found"),
            level: "error",
          },
        ],
      };
    } else if (
      bg.businessRules[0].startDate &&
      moment(bg.businessRules[0].startDate).isAfter(record.business_rule_group_start_date, "day")
    ) {
      out.business_rule_group_start_date = {
        info: [
          {
            message: t("Cannot be prior to group start date"),
            level: "error",
          },
        ],
      };
    }
  }

  if (record.holiday_group && !holidayGroups.find((item) => item.uuid === record.holiday_group)) {
    out.holiday_group = {
      info: [
        {
          message: t("import_col_holiday_group_not_found"),
          level: "error",
        },
      ],
    };
  }
  if (record.payroll_group && !payrollGroups.find((item) => item.uuid === record.payroll_group)) {
    out.payroll_group = {
      info: [
        {
          message: t("import_col_payroll_group_not_found"),
          level: "error",
        },
      ],
    };
  }
  if (record.schedule_uuid && !schedules.find((item) => item.uuid === record.schedule_uuid)) {
    out.schedule_uuid = {
      info: [
        {
          message: t("import_col_schedule_uuid_not_found"),
          level: "error",
        },
      ],
    };
  }
  if (isDateFieldValid(record.schedule_start_date)) {
    out.schedule_start_date = {
      info: [
        {
          message: t("Invalid format for date"),
          level: "error",
        },
      ],
    };
  }
  if (isDateFieldValid(record.deactivation_date)) {
    out.deactivation_date = {
      info: [
        {
          message: t("Invalid format for date"),
          level: "error",
        },
      ],
    };
  }

  if (
    record.phone_number &&
    !isValidPhoneNumber(record.phone_number.charAt(0) === "+" ? record.phone_number : `+${record.phone_number}`)
  ) {
    out.phone_number = {
      info: [
        {
          message: t("Invalid phone number"),
          level: "error",
        },
      ],
    };
  }

  if (record.externalId) {
    const existingExternalId = externalIds.find((id) => id === record.externalId);
    if (isUpdate && !existingExternalId) {
      out.externalId = {
        info: [
          {
            message: t("Provided externalId is not found"),
            level: "error",
          },
        ],
      };
    } else if (!isUpdate && existingExternalId) {
      out.externalId = {
        info: [
          {
            message: t("Provided externalId already exists"),
            level: "error",
          },
        ],
      };
    }
  }

  if (
    !!record.supervisorExternalId &&
    !Object.values(items).includes(record.supervisorExternalId) &&
    !externalIds.includes(record.supervisorExternalId)
  ) {
    out.supervisorExternalId = {
      info: [
        {
          message: t("Supervisor external id is not found"),
          level: "error",
        },
      ],
    };
  }

  if (!!record.supervisorExternalId && record.externalId === record.supervisorExternalId) {
    out.supervisorExternalId = {
      info: [
        {
          message: t("Supervisor external id cannot be same as external id"),
          level: "error",
        },
      ],
    };
  }

  if (allowedProducts.length === 1) {
    out.associate_products = { value: allowedProducts[0] };
  }

  if ([FeatureListString.TA, FeatureListString.Bundle].includes(record.associate_products)) {
    productUsage[Feature.TA].add(index);
  } else {
    productUsage[Feature.TA].delete(index);
  }

  if ([FeatureListString.Projects, FeatureListString.Bundle].includes(record.associate_products)) {
    productUsage[Feature.Project].add(index);
  } else {
    productUsage[Feature.Project].delete(index);
  }

  if (usedSeats) {
    if (
      typeof usedSeats[Feature.TA] === "number" &&
      [FeatureListString.TA, FeatureListString.Bundle].includes(record.associate_products)
    ) {
      if ((usedSeats[Feature.TA] as number) - productUsage[Feature.TA].size < 0) {
        out.associate_products = {
          info: [
            {
              message: t("free_plan_limit {{seatNumber}}", { seatNumber: usedSeats[Feature.TA] }),
              level: "error",
            },
          ],
        };
      }
    }
    if (
      typeof usedSeats[Feature.Project] === "number" &&
      [FeatureListString.Projects, FeatureListString.Bundle].includes(record.associate_products)
    ) {
      if ((usedSeats[Feature.Project] as number) - productUsage[Feature.Project].size < 0) {
        out.associate_products = {
          info: [
            {
              message: t("free_plan_limit {{seatNumber}}", { seatNumber: usedSeats[Feature.Project] }),
              level: "error",
            },
          ],
        };
      }
    }
  }

  return out;
};
