import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ClickOutside from "react-click-outside";
import { clearDb } from "utils/oitchauDb";
import { logOut } from "utils/apiHelpers";
import ga from "utils/ga";
import { PermissionSectionName, hasPermisionAccess, fireEvent, getImgUrl } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import { ClickOutsideType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BillingService } from "components/Billing/BillingService";
import { useHistory } from "react-router-dom";
import { PermissionRoleName } from "types/models/permissions";
import { AvailableLocales, getLocale } from "utils/translationHelpers";
import * as Images from "./menu-svg-icons";
import CompanySwitchNew from "./CompanySwitchNew";
import LanguageSwitcherNew from "./LanguageSwitcherNew";
import ThemeSwitcher from "./ThemeSwitcher";

const UserMenuWrapper = styled.div`
  position: relative;
`;

const AvatarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  border-radius: 100%;
  background: var(--colors-primary-300);
  color: var(--colors-surface-0);

  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }

  div {
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const Icon = styled.div<{ isOpened: boolean }>`
  height: 20px;
  width: 20px;
  transform: rotate(${(p) => (p.isOpened ? 180 : 0)}deg);
`;

const Dropdown = styled.div<{ isOpened: boolean }>`
  display: ${(p) => (p.isOpened ? "block" : "none")};
  position: absolute;
  top: 38px;
  inset-inline-end: 0;
  z-index: 110;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-150);
  background: var(--colors-surface-0);
  box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
`;

const DropdownSection = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid var(--colors-surface-150);

  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

const DropdownOption = styled.div<{ withSubMenu?: boolean }>`
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  line-height: 32px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  & > span,
  .lang-option {
    margin: 0 8px;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lang-option {
    display: flex;
    justify-content: space-between;

    span:last-child {
      color: var(--colors-surface-700);
    }
  }

  .arrow {
    transform: rotate(${() => (window.global_store.isRTL ? "90" : "270")}deg);
  }

  .company-switch,
  .lang-switch,
  .theme-switch {
    position: absolute;
    top: -7px;
    z-index: 11;
    border-radius: var(--shapes-border-radius-default);
    border: 1px solid var(--colors-surface-150);
    background: var(--colors-surface-0);
    box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
  }

  .company-switch {
    top: -7px;
    inset-inline-start: -262px;
  }

  .lang-switch {
    top: -9px;
    inset-inline-start: -193px;
  }

  .theme-switch {
    top: -9px;
    inset-inline-end: 253px;
  }
`;

interface UserMenuProps {
  minimal?: boolean;
}

const UserMenu = ({ minimal }: UserMenuProps) => {
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);
  const [isCompanySwitchOpened, setIsCompanySwitchOpened] = useState(false);
  const [isLangSwitchOpened, setIsLangSwitchOpened] = useState(false);
  const [isThemeSwitchOpened, setIsThemeSwitchOpened] = useState(false);

  const onClose = () => {
    setIsCompanySwitchOpened(false);
    setIsLangSwitchOpened(false);
    setIsOpened(false);
    setIsThemeSwitchOpened(false);
  };

  const hasSubscriptions = () => !!BillingService.getSubscriptionsFromLocalStorage;
  const avatarUrl = window.global_store.profile.avatar_id
    ? getImgUrl(
        window.global_store.profile.avatar_id,
        "f_auto,q_auto/h_100,w_100,c_thumb,g_face,fl_progressive.force_strip/",
      )
    : "";

  const initials = !avatarUrl
    ? window.global_store.profile.full_name
        .split(" ")
        .map((w: string) => w[0])
        .join("")
        .substring(0, 2)
        .toUpperCase()
    : "";

  const getLangAbbr = () =>
    ({
      [AvailableLocales.en.toLowerCase()]: "ENG",
      [AvailableLocales.he.toLowerCase()]: "HEB",
      [AvailableLocales.pt.toLowerCase()]: "POR",
      [AvailableLocales.es.toLowerCase()]: "SPA",
    }[getLocale().toLowerCase()]);

  const companySettingsPath = (() => {
    const paths = [
      { path: "/company/profile", hasAccess: () => hasPermisionAccess(PermissionSectionName.companyProfile) },
      { path: "/company/sso", hasAccess: () => hasPermisionAccess(PermissionSectionName.security) },
      { path: "/company/password", hasAccess: () => hasPermisionAccess(PermissionSectionName.security) },
      {
        path: "/company/permissions",
        hasAccess: () =>
          window.global_store.profile.permission_roles?.some(
            (r) => r.name === PermissionRoleName.owner || r.name === PermissionRoleName.admin,
          ),
      },
      { path: "/time-clocks/", hasAccess: () => hasPermisionAccess(PermissionSectionName.timeClocks) },
      { path: "/pegador/import", hasAccess: () => hasPermisionAccess(PermissionSectionName.timeClocks) },
      { path: "/pegador/", hasAccess: () => hasPermisionAccess(PermissionSectionName.timeClocks) },
      { path: "/data-management/files", hasAccess: () => hasPermisionAccess(PermissionSectionName.dataManagement) },
    ];

    return paths.find((p) => p.hasAccess())?.path || null;
  })();

  return (
    <ClickOutside<ClickOutsideType> onClickOutside={(_: PointerEvent) => onClose()}>
      <UserMenuWrapper className="user-menu">
        <AvatarButton onClick={(e) => (isOpened ? onClose() : setIsOpened(true))}>
          <Avatar>{avatarUrl ? <img alt="" src={avatarUrl} /> : <div>{initials}</div>}</Avatar>
          <Icon isOpened={isOpened}>
            <Images.ArrowDown />
          </Icon>
        </AvatarButton>

        <Dropdown isOpened={isOpened}>
          <DropdownSection>
            <DropdownOption onClick={() => setIsCompanySwitchOpened(true)}>
              <Images.Company />
              <span>{window.global_store.company?.name || ""}</span>
              <Images.ArrowDown className="arrow" />

              <CompanySwitchNew
                isOpened={isCompanySwitchOpened}
                closeCompanySwitch={() => setIsCompanySwitchOpened(false)}
                closeUserMenu={onClose}
              />
            </DropdownOption>

            {companySettingsPath && !minimal && (
              <DropdownOption onClick={() => history.push(companySettingsPath)}>
                <Images.CompanySettings />
                <span>{t("Company Settings")}</span>
              </DropdownOption>
            )}

            {hasPermisionAccess(PermissionSectionName.marketplace) && !minimal && (
              <DropdownOption onClick={() => history.push("/apps")}>
                <Images.AppsAndIntegration />
                <span>{t("Apps & Integrations")}</span>
              </DropdownOption>
            )}

            {hasPermisionAccess(PermissionSectionName.billing) && hasSubscriptions() && !minimal && (
              <DropdownOption onClick={() => history.push("/billing")}>
                <Images.Billing />
                <span>{t("Billing")}</span>
              </DropdownOption>
            )}
          </DropdownSection>

          <DropdownSection>
            <DropdownOption onClick={() => setIsLangSwitchOpened(true)}>
              <Images.Language />
              <div className="lang-option">
                <span>{t("Language")}</span>
                <span>{getLangAbbr()}</span>
              </div>
              <Images.ArrowDown className="arrow" />

              <LanguageSwitcherNew isOpened={isLangSwitchOpened} onClose={() => setIsLangSwitchOpened(false)} />
            </DropdownOption>
            <DropdownOption onClick={() => setIsThemeSwitchOpened(true)}>
              <Images.Theme />
              <span>{t("Theme")}</span>
              <Images.ArrowDown className="arrow" />

              <ThemeSwitcher isOpen={isThemeSwitchOpened} onClose={() => setIsThemeSwitchOpened(false)} />
            </DropdownOption>
          </DropdownSection>

          <DropdownSection>
            <DropdownOption
              onClick={() => {
                ga.trackLogout();

                fireEvent("LogOut");

                setTimeout(async () => {
                  try {
                    await logOut();
                    await clearDb();
                    window.location = "/" as unknown as Location;
                  } catch (error) {
                    sentryUtils.sendError(error);
                  }
                }, 100);
              }}
            >
              <Images.SignOut />
              <span>{t("Sign out")}</span>
            </DropdownOption>
          </DropdownSection>
        </Dropdown>
      </UserMenuWrapper>
    </ClickOutside>
  );
};

export default UserMenu;
