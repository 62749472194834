import { MouseEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import NoContent from "components/NoContent";
import TablePage from "components/TablePage";
import User from "components/User";
import * as images from "components/svg-images";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { AggregationGroupType, Groups } from "types/models/dashboard";
import { iCellInfo } from "utils/tableHelpers";
import { minsToHrsMins } from "utils/common";
import Tooltip from "components/UI/Tooltip";
import { ColumnAlign, iColumn } from "components/TableCommon";
import PaginationNew from "components/PaginationNew";
import ga, { GaSpPageType } from "utils/ga";
import { SuperpunchEmployee, UseDataHookStatus, useGroupData } from "./customHooks";
import ProgressCell from "./ProgressCell/ProgressCell";
import ColumnHeaderWithDescr from "./ColumnHeaderWithDescr/ColumnHeaderWithDescr";
import PendingCell from "./PendingCell/PendingCell";
import ProgressCellTooltip from "./ProgressCell/ProgressCellTooltip";

const Wrapper = styled.div`
  .employees-superpunch-table {
    .table-common__th {
      div {
        pointer-events: auto;
      }
    }
  }
`;

const UserWrapper = styled.div`
  display: flex;
  align-intems: center;
`;

const GroupIconWrapper = styled.div`
  position: relative;
  top: 2px;
  inset-inline-start: 8px;
  padding: 0 10px;
`;

type UseColumsProps = {
  startDate: string;
  endDate: string;
  groupUuid: string;
  groupType: Exclude<AggregationGroupType, "userProfile" | "company">;
  groupName: string;
};

const useColumns = ({ startDate, endDate, groupUuid, groupType, groupName }: UseColumsProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const history = useHistory();

  return [
    {
      label: t(`${TranslationNamespaces.common}|Employee`),
      accessor: "employee",
      minWidth: 160,
      Cell: (row: iCellInfo<SuperpunchEmployee, SuperpunchEmployee["employee"]>): JSX.Element => (
        <UserWrapper>
          <User
            user={{
              fullName: row.value?.fullName || row.original.name,
              avatarId: row.value?.avatarId || "",
              position: row.value?.position || "",
            }}
          />
          {row.value?.ownTeamUuid && groupUuid !== row.value.ownTeamUuid && (
            <>
              <GroupIconWrapper
                data-tip
                data-for={row.value.ownTeamUuid}
                onClick={(ev: MouseEvent) => {
                  ev.stopPropagation();
                  // add previous_group param for the back button
                  const url = `/punches?type=group-edit&startDate=${startDate}&endDate=${endDate}&group_type=${
                    AggregationGroupType.teams
                  }&group_uuid=${row.value?.ownTeamUuid}&previous_group=${[groupType, groupUuid, groupName].join("_")}`;

                  history.push(url);
                }}
              >
                {images.group}
              </GroupIconWrapper>
              <Tooltip id={row.value.ownTeamUuid}>
                <span>{t("View team")}</span>
              </Tooltip>
            </>
          )}
        </UserWrapper>
      ),
      style: { lineHeight: "36px" },
    },
    {
      label: t("Pending punches"),
      accessor: "data",
      Cell: (row: iCellInfo<SuperpunchEmployee, SuperpunchEmployee["data"]>) => (
        <PendingCell
          entityUuid={row.original.uuid}
          pendingItemsCount={row.value.pendingPunchesCount}
          onClick={() => {}}
          // PROD-11156 PROD-11157 todo
          isProcessing={false}
        />
      ),
      align: "center" as ColumnAlign,
      style: { padding: 0, margin: 0, flex: "0 0 auto", width: 148 },
      minWidth: 80,
    },
    {
      label: t("Invalid punches"),
      accessor: "data",
      Cell: (row: iCellInfo<SuperpunchEmployee, SuperpunchEmployee["data"]>) => (
        <PendingCell
          entityUuid={row.original.uuid}
          pendingItemsCount={row.value.invalidPunchesCount}
          onClick={() => {}}
          // PROD-11156 PROD-11157 todo
          isProcessing={false}
        />
      ),
      align: "center" as ColumnAlign,
      style: { padding: 0, margin: 0, flex: "0 0 auto", width: 148 },
      minWidth: 80,
    },
    {
      label: t("Executed / Planned Hours"),
      accessor: "data",
      Cell: ({ original, value }: iCellInfo<SuperpunchEmployee, SuperpunchEmployee["data"]>) =>
        original.employee?.hasSchedule ? (
          <>
            <div data-tip data-for={`request-tooltip-${original.uuid}`.replace("\n", "")}>
              <ProgressCell
                targetValue={value.plannedMinutes}
                currentValue={value.workedMinutes + value.paidAbsenceMinutes}
                usePercents
              />
            </div>
            <ProgressCellTooltip
              t={t}
              id={`request-tooltip-${original.uuid}`.replace("\n", "")}
              plannedMinutes={value.plannedMinutes}
              workedMinutes={value.workedMinutes}
              paidAbsenceMinutes={value.paidAbsenceMinutes}
            />
          </>
        ) : (
          t("No Schedules")
        ),
      align: "center" as ColumnAlign,
      minWidth: 120,
      Header: ({ column }: { column: iColumn<SuperpunchEmployee> }) => (
        <ColumnHeaderWithDescr column={column} description={t("executed-planned-descr")} />
      ),
    },
    {
      label: t(`Missed Days`),
      accessor: "data",
      minWidth: 60,
      Cell: (row: iCellInfo<SuperpunchEmployee>) => row.value.missedDay || "-",
      style: { lineHeight: "36px" },
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchEmployee> }) => (
        <ColumnHeaderWithDescr column={column} description={t("missed-days-descr")} />
      ),
    },
    {
      label: t(`Extra Hours`),
      accessor: "data",
      minWidth: 60,
      Cell: (row: iCellInfo<SuperpunchEmployee>) =>
        row.value.extraHoursMinutes ? minsToHrsMins(row.value.extraHoursMinutes) : "-",
      style: { lineHeight: "36px" },
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchEmployee> }) => (
        <ColumnHeaderWithDescr column={column} description={t("extra-hours-descr")} />
      ),
    },
    {
      label: t(`Hours Bank`),
      accessor: "data",
      minWidth: 60,
      Cell: (row: iCellInfo<SuperpunchEmployee>) =>
        row.value.hoursBankMinutes ? minsToHrsMins(row.value.hoursBankMinutes) : "-",
      style: { lineHeight: "36px" },
      align: "center" as ColumnAlign,
      Header: ({ column }: { column: iColumn<SuperpunchEmployee> }) => (
        <ColumnHeaderWithDescr column={column} description={t("hours-bank-descr")} />
      ),
    },
  ];
};

interface EmployeesSuperpunchTableProps {
  groupUuid: string;
  groupType: Exclude<AggregationGroupType, "userProfile" | "company">;
  startDate: string;
  endDate: string;
  groupName: string;
}

const EmployeesSuperpunchTable = ({
  groupUuid,
  groupType,
  startDate,
  endDate,
  groupName,
}: EmployeesSuperpunchTableProps) => {
  const columns = useColumns({ startDate, endDate, groupUuid, groupType, groupName });
  const location = useLocation();
  const history = useHistory();
  const groupBy = {
    [AggregationGroupType.departments]: Groups.departments,
    [AggregationGroupType.teams]: Groups.teams,
    [AggregationGroupType.subsidiaries]: Groups.subsidiaries,
  }[groupType];
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const { data, status, totalRecords } = useGroupData({ startDate, endDate, groupUuid, groupBy, pagination });

  useEffect(() => {
    if (status === UseDataHookStatus.success) {
      const pageLocation = encodeURI(window.location.origin + location.pathname + location.search);
      ga.trackSuperPunchNavigation(pageLocation, GaSpPageType.employees);
    }
  }, []);

  return (
    <Wrapper>
      <TablePage<SuperpunchEmployee>
        rows={data || []}
        columnSelectorOnFiltersRow
        columns={columns}
        className="employees-superpunch-table"
        loading={status === UseDataHookStatus.processing}
        noContentComponent={
          <NoContent>
            <>No employees</>
          </NoContent>
        }
        getTrProps={(_, rowInfo): { className: string; onClick: (e: KeyboardEvent) => void } => ({
          className: "employees-superpunch-row",
          onClick: (e: KeyboardEvent): void => {
            if (!rowInfo) {
              return;
            }

            const { name, employee } = rowInfo.original;
            if (!name || !employee?.id || !employee.uuid) {
              return;
            }

            const url = `/punches?type=edit&label=${name}&id=${employee.id}&uuid=${employee.uuid}&startDate=${startDate}&endDate=${endDate}`;

            if (e.metaKey) {
              Object.assign(document.createElement("a"), {
                target: "_blank",
                href: url,
              }).click();
            } else {
              history.push(url);
            }
          },
        })}
        showPagination
        PaginationComponent={() => (
          <PaginationNew
            totalRecords={totalRecords}
            pageLimit={pagination.perPage}
            currentPage={pagination.page}
            onPageLimitChange={(perPage, page) => setPagination({ page, perPage })}
            onPageChange={(currentPage) => setPagination({ page: currentPage, perPage: pagination.perPage })}
          />
        )}
      />
    </Wrapper>
  );
};

export default EmployeesSuperpunchTable;
