import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { getPositions } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Subsidiary } from "types/models/subsidiary";
import { Department } from "types/models/department";
import { Position } from "types/models/position";
import { Team } from "types/models/team";
import { GlobalContext } from "context/GlobalContextProvider";
import useExtraHours from "./AggregationCharts/chartDataHooks/useExtraHours";
import useHoursBank from "./AggregationCharts/chartDataHooks/useHoursBank";
import useMissingHours from "./AggregationCharts/chartDataHooks/useMissingHours";
import useWorkedHours from "./AggregationCharts/chartDataHooks/useWorkedHours";
import Chart from "./AggregationCharts/Chart";

const Wrapper = styled.div``;

type ChartsProps = WithTranslation;

interface ChartsState {
  subsidiaries: Subsidiary[];
  departments: Department[];
  positions: Position[];
  teams: Team[];
}

class Charts extends Component<ChartsProps, ChartsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<ChartsState> = {
    subsidiaries: [],
    departments: [],
    positions: [],
    teams: [],
  };

  componentDidMount() {
    void this.fetchGroups();
  }

  fetchGroups = async () => {
    const [subsidiaries, departments, { positions }, teams] = await Promise.all([
      this.context.getSubsidiaries(),
      this.context.getDepartments(),
      getPositions(),
      this.context.getTeams(),
    ]);

    this.setState({
      subsidiaries,
      departments,
      positions,
      teams,
    });
  };

  render() {
    const { t } = this.props;
    const { subsidiaries, departments, positions, teams } = this.state;
    const groups = { subsidiaries, departments, positions, teams };

    return (
      <Wrapper>
        <Chart title={t("Worked Hours")} groups={groups} useChartData={useWorkedHours} />
        <Chart title={t("Missing hours")} groups={groups} useChartData={useMissingHours} />
        <Chart title={t("Extra hours")} groups={groups} useChartData={useExtraHours} />
        <Chart title={t("Hours bank")} groups={groups} useChartData={useHoursBank} />
      </Wrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.dashboard)(Charts);
