import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  .rc-slider-dot {
    display: none;
  }
`;
const SliderWrapper = styled.div`
  flex: 1;
  width: 128px;
  height: 100%;
  .rc-slider-disabled {
    background: none;
  }
`;
const Label = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 16px;
  color: var(--colors-surface-600);
`;

const Marks = styled.div`
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  font-weight: var(--typography-font-weight-medium);
  font-size: 12px;
  line-height: 13px;
  color: var(--colors-surface-900-p);
`;

type SplitSliderProps = {
  onChange: (value: number) => void;
  inactive: boolean;
  value: number;
};

const SplitSlider = ({ onChange, inactive, value }: SplitSliderProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  const handleStyle = {
    width: "10px",
    height: "10px",
    background: "var(--colors-surface-0)",
    border: "0.5px solid var(--colors-surface-100)",
    boxShadow: "0px 3px 5px rgba(129, 147, 171, 0.2)",
    borderRadius: "32px",
    top: "5px",
  };

  if (inactive) {
    handleStyle.backgroundImage =
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Crect fill='%23434E6C' width='15' height='15' rx='7.5'/%3E%3Cpath d='M10.125 5.667h-.438v-.762C9.688 3.838 8.726 3 7.5 3c-1.225 0-2.188.838-2.188 1.905v.762h-.437c-.481 0-.875.343-.875.762v3.81c0 .418.394.761.875.761h5.25c.481 0 .875-.343.875-.762v-3.81c0-.418-.394-.761-.875-.761zM7.5 9.095c-.481 0-.875-.343-.875-.762s.394-.762.875-.762.875.343.875.762c0 .42-.394.762-.875.762zm1.356-3.428H6.144v-.762c0-.648.612-1.181 1.356-1.181.744 0 1.356.533 1.356 1.18v.763z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E\")";
    handleStyle.width = "15px";
    handleStyle.height = "15px";
    handleStyle.borderRadius = "15px";
  }

  return (
    <Wrapper>
      <Label>{t("Hours Bank")}</Label>
      <SliderWrapper>
        <Marks>
          <div>{value}%</div>
          <div>{100 - value}%</div>
        </Marks>
        <Slider
          onChange={(val: number) => {
            onChange(val);
          }}
          onAfterChange={(val: number) => {
            onChange(val);
          }}
          value={value}
          step={1}
          disabled={inactive}
          trackStyle={[{ backgroundColor: "var(--colors-primary-500-p)", height: "4px" }]}
          handleStyle={[handleStyle]}
          railStyle={{ backgroundColor: "var(--colors-primary-200)", height: "4px" }}
        />
      </SliderWrapper>
      <Label>{t("Extra Hours")}</Label>
    </Wrapper>
  );
};

export default SplitSlider;
