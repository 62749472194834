import { Component } from "react";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import PhoneNumberChangeControl from "components/PhoneNumberChangeControl";
import { formatPhone } from "utils/common";
import { getDevices, toggleDeviceStatus, addDevice } from "utils/apiHelpers";
import { UserProfile } from "types/models/userProfile";
import { Device } from "types/models/device";
import "components/UI/Page/Page.scss";
import "styles/employee-page.scss";
import "styles/employee-devices-table.scss";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";

const b = BEM.b("employee-page");
const dt = BEM.b("employee-devices-table");

interface EmployeeDevicesProps extends WithTranslation {
  employee: UserProfile;
}

interface EmployeeDevicesState {
  devices: Device[];
  loaded: boolean;
}

class EmployeeDevices extends Component<EmployeeDevicesProps, EmployeeDevicesState> {
  constructor(props: EmployeeDevicesProps) {
    super(props);
    this.state = {
      devices: [],
      loaded: false,
    };
  }

  componentDidMount(): void {
    void this.getDevices(this.props.employee.id);
  }

  getDevices = async (employeeId: number): Promise<void> => {
    if (employeeId) {
      const { devices } = await getDevices({ employeeId });

      this.setState({
        devices,
        loaded: true,
      });
    }
  };

  onEditClick = async (data: Device): Promise<void> => {
    const employeeId = this.props.employee.id;
    this.setState({ loaded: false });
    if (!data.user_profiles_devices_id) {
      await addDevice({ employeeId, deviceId: data.device_id });

      void this.getDevices(employeeId);
    } else {
      await toggleDeviceStatus({
        employeeId,
        deviceId: data.user_profiles_devices_id,
        status: data.status === "active" ? "deactivated" : "active",
      });

      void this.getDevices(employeeId);
    }
  };

  getButtonTitle = (status: string): string => {
    const { t } = this.props;
    let buttonTitle = "";
    switch (status) {
      case "active":
        buttonTitle = t(`${TranslationNamespaces.common}|Deactivate`);
        break;
      case "available":
        buttonTitle = t("Add device");
        break;
      default:
        buttonTitle = t(`${TranslationNamespaces.common}|Activate`);
        break;
    }
    return buttonTitle;
  };

  getAppVersion(appVersion: string): string {
    let returnStr = this.props.t("None");
    if (appVersion && appVersion.indexOf(" iOS ") > 0) {
      const match: RegExpMatchArray | null = appVersion.match(/oitchau\/(.*) \(.*iOS (.*)\)/i);
      if (!!match && appVersion.indexOf("Oitchau ") > -1) {
        const [, iosVersion, iosAppVersion] = match;
        if (iosVersion && iosAppVersion) {
          returnStr = `IOS ${iosAppVersion}, App version ${iosVersion}`;
        }
      } else if (!!match && appVersion.indexOf("Olachau ") > -1) {
        const [, iosVersion, iosAppVersion] = match;
        if (iosVersion && iosAppVersion) {
          returnStr = `IOS ${iosAppVersion}, App version ${iosVersion}`;
        }
      }
    } else if (appVersion) {
      returnStr = `Android ${appVersion}`;
    }
    return returnStr;
  }

  getDevicesTable = (devices: Device[]): JSX.Element => {
    const { t } = this.props;
    const { loaded } = this.state;
    if (!loaded) {
      return <div>Loading...</div>;
    }
    return (
      <div className={dt()}>
        {devices.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>{t("Marke")}</th>
                <th>{t("Model")}</th>
                <th>{t("App Version")}</th>
                <th>{t("Token")}</th>
                <th>{t("Number")}</th>
                <th>{t("Added On")}</th>
                <th>{t("Status")}</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((d: Device) => (
                <tr key={`${d.phone_number}`}>
                  <td className={dt("td")}>{d.vendor}</td>
                  <td className={dt("td")}>{d.model}</td>
                  <td className={dt("td")}>{this.getAppVersion(d.app_version)}</td>
                  <td className={dt("td")}>{d.token}</td>
                  <td className={dt("td")} style={{ direction: "ltr" }}>
                    {d.phone_number ? formatPhone(d.phone_number, true) : ""}
                  </td>
                  <td className={dt("td")}>
                    {d.created_at ? moment(d.created_at).format("HH:mm ddd DD/MM/YYYY") : ""}
                  </td>
                  <td className={dt("td", { options: true })} style={{ position: "relative" }}>
                    <span className={dt("status-button", [d.status])}>{t(d.status)}</span>
                    <div
                      className={dt("change-status")}
                      onClick={(): void => {
                        this.onEditClick(d);
                      }}
                    >
                      {this.getButtonTitle(d.status)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>
            {translateEmployeeTerm(t, TranslationNamespaces.employeesPage, "custom-no-devices", "No employee devices")}
          </div>
        )}
      </div>
    );
  };

  render(): JSX.Element {
    const { devices, loaded } = this.state;
    const { employee } = this.props;
    const { t } = this.props;
    const activeDevice = devices && devices.length ? devices.filter((device) => device.status === "active")[0] : null;

    return (
      <div className="page">
        <PhoneNumberChangeControl
          employeeId={employee.id}
          loading={!loaded}
          phoneNumber={activeDevice ? activeDevice.phone_number : ""}
          onChange={(): void => {
            void this.getDevices(employee.id);
          }}
        />
        <div className={b("title")}>{t("Mobile Devices")}</div>
        {this.getDevicesTable(devices)}
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.employeesPage)(EmployeeDevices);
