import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import classNames from "classnames";
import Tooltip from "components/UI/Tooltip";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import { useCompanyRules } from "hooks/useCompanyRules";
import { CompanyRuleNames } from "types/models/companyRules";

const Wrapper = styled.div`
  color: var(--colors-surface-900-p, #434e6c);
  font-size: 12px;
  font-weight: var(--typography-font-weight-bold);
  line-height: 100%;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &.strikethrough {
    color: var(--colors-surface-500, #a0aec6);
    text-decoration: line-through;
  }
`;

type ReporsDsrLabelProps = {
  isLostDsrDay: boolean;
  isPossibleDsrDay: boolean;
  showDsrInReports: boolean;
  isPlannedDsrDay: boolean;
};

const ReporsDsrLabel = ({ isLostDsrDay, isPossibleDsrDay, showDsrInReports }: ReporsDsrLabelProps) => {
  const { t } = useTranslation(TranslationNamespaces.reportsPage);
  const { data: companyRules } = useCompanyRules();
  const dsrEnabled =
    companyRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;

  const isStrikethrough = isPossibleDsrDay && isLostDsrDay;
  const tooltipId = useMemo(() => uuidv4(), []);

  if (!dsrEnabled || !showDsrInReports || !isPossibleDsrDay) {
    return null;
  }

  return (
    <>
      <Wrapper className={classNames({ strikethrough: isStrikethrough })} data-tip data-for={tooltipId}>
        DSR
      </Wrapper>
      <Tooltip id={tooltipId}>{t("report-dsr-tooltip")}</Tooltip>
    </>
  );
};

export default ReporsDsrLabel;
