import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Select from "components/UI/FormSelect";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PayedOvertimePhase, PhaseType } from "types/models/businessRulesGroup";
import { useState } from "react";
import { TableButton } from "components/styled/Page";
import Tooltip from "components/UI/Tooltip";
import { ButtonSplitDayNight } from "./buttons";
import { PHASE_TYPES, PhasesType } from "./helpers";
import PhasesLimits from "./PhasesLimits";
import Confirmation from "./Confirmation";
import { DSR_PHASE_KEY } from "../../Payroll/types";

const Wrapper = styled.div`
  margin-top: 24px;
`;
const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-600);
  padding-bottom: 17px;
`;
const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid var(--colors-surface-150);
  padding: 24px 0;
  position: relative;
  .delete-button {
    display: none;
    position: absolute;
    inset-inline-end: 14px;
    top: 24px;
  }
  &:hover {
    background-color: var(--colors-surface-50);
    .delete-button {
      display: block;
    }
  }
`;
const DaysCell = styled.div`
  width: 240px;
  flex: none;
  margin-inline-end: 16px;
`;
const DayNightSplitCell = styled.div`
  margin-inline-end: 16px;
  width: 36px;
  flex: none;
`;
export const CompensationRateCell = styled.div`
  width: 128px;
  flex: none;
`;
export const CustomNameCell = styled.div`
  width: 300px;
  flex: none;
`;
const LimitsCell = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export type PhasesTableProps = {
  disabled: boolean;
  locked: boolean;
  onDateMaskChange: (value: { oldDaysMaskOrDaysType: string; newDaysMaskOrDaysType: string }) => void;
  arrayWithOrderedPhasesMasks: string[];
  getDaysOptions: (days: string[]) => { value: string; label: string }[];
  phasesByDays: Record<string, PayedOvertimePhase[]>;
  phasesType: PhasesType;
  errors: Record<string, any> | null;
  isDayTypeBasedOnSchedule: boolean;
  onlyEH: boolean;
  onPhaseChange: (value: {
    uuid: string;
    field: "limit" | "extraHours" | "name" | "customName" | "additionalLimit";
    value: number | string | null;
  }) => void;
  onRemovePhase: (value: { daysMaskOrDaysType: string; uuid: string }) => void;
  onRemoveDayWithPhase: (value: { daysMaskOrDaysType: string }) => void;
  onAddPhase: (value: { daysMaskOrDaysType: string; uuid: string; type: string }) => void;
  onDayNightSplitToggle: (daysMaskOrDaysType: string) => void;
};

const PhasesTable = (props: PhasesTableProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const [splitConfirmationVisible, setSplitConfirmationVisible] = useState<number | null>(null);
  const { dayNightPhasesSplit, customPhaseName } = useFlags();
  const {
    disabled,
    locked,
    onDateMaskChange,
    arrayWithOrderedPhasesMasks,
    getDaysOptions,
    phasesByDays,
    onDayNightSplitToggle,
    phasesType,
    onRemoveDayWithPhase,
    isDayTypeBasedOnSchedule,
  } = props;

  const isDeletable = (phase) => {
    return phase[0]?.dayTypeBasedOnSchedule === DSR_PHASE_KEY || !disabled && !locked && !isDayTypeBasedOnSchedule;
  }

  return (
    <Wrapper>
      <HeaderRow>
        <DaysCell>{t("Days")}</DaysCell>
        {dayNightPhasesSplit && <DayNightSplitCell />}
        <CompensationRateCell style={{ marginInlineStart: "8px" }}>
          {phasesType === PHASE_TYPES.MULTIPLIER ? t("Multipliers rates") : t("Compensation rates")}
        </CompensationRateCell>
        {customPhaseName && <CustomNameCell>{t("Custom name")}</CustomNameCell>}
        <LimitsCell style={{ marginInlineStart: "8px" }}>{t("Limit")}</LimitsCell>
      </HeaderRow>
      {arrayWithOrderedPhasesMasks.map((daysMaskOrDaysType, rowIndex) => (
        <TableRow key={daysMaskOrDaysType}>
          {splitConfirmationVisible === rowIndex && (
            <Confirmation
              onClose={() => setSplitConfirmationVisible(null)}
              onConfirm={() => {
                onDayNightSplitToggle(daysMaskOrDaysType);
                setSplitConfirmationVisible(null);
              }}
            />
          )}
          <DaysCell>
            <Select
              disabled={disabled || isDayTypeBasedOnSchedule}
              locked={!disabled && locked}
              value={daysMaskOrDaysType}
              newStyle
              onChange={(value) => {
                onDateMaskChange({ oldDaysMaskOrDaysType: daysMaskOrDaysType, newDaysMaskOrDaysType: value });
              }}
              options={getDaysOptions(Object.keys(phasesByDays).filter((p) => p !== daysMaskOrDaysType))}
            />
          </DaysCell>
          {dayNightPhasesSplit && (
            <DayNightSplitCell>
              <Tooltip id={`tooltip-day-night-split-${daysMaskOrDaysType}`}>{t("day-night-split-tooltip")}</Tooltip>
              <ButtonSplitDayNight
                data-tip
                data-for={`tooltip-day-night-split-${daysMaskOrDaysType}`}
                disabled={disabled || locked}
                onClick={() => {
                  if (!disabled && !locked) {
                    if (
                      phasesByDays[daysMaskOrDaysType].findIndex((phase) => phase.type === PhaseType.combined) === -1
                    ) {
                      onDayNightSplitToggle(daysMaskOrDaysType);
                    } else {
                      setSplitConfirmationVisible(rowIndex);
                    }
                  }
                }}
                className={
                  phasesByDays[daysMaskOrDaysType].findIndex((phase) => phase.type === PhaseType.combined) === -1
                    ? "active"
                    : ""
                }
              />
            </DayNightSplitCell>
          )}
          <LimitsCell>
            <PhasesLimits allDayMaskPhases={phasesByDays[daysMaskOrDaysType]} {...props} />
            {isDeletable(phasesByDays[daysMaskOrDaysType]) && !!onRemoveDayWithPhase && (
              <TableButton
                onClick={() => {
                  onRemoveDayWithPhase({
                    daysMaskOrDaysType,
                  });
                }}
                className="delete-button"
              >
                {t(`${TranslationNamespaces.common}|Delete`)}
              </TableButton>
            )}
          </LimitsCell>
        </TableRow>
      ))}
    </Wrapper>
  );
};

export default PhasesTable;
