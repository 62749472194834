import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import InputWithButtons from "../UI/InputWithButtons";
import Button from "../UI/ButtonComponent";
import ActionInput from "../UI/ActionInput";
import { TranslationNamespaces, useTranslation } from "../../types/translationNamespaces";

const ItemWrapper = styled.div<{ overlay: boolean }>`
  width: 248px;
  margin-top: 8px;

  .action-input {
    width: unset;
  }

  position: relative;
  ${(props) =>
    props.overlay
      ? `
        &:after{
          position: absolute;
          width: 100%;
          height: 100%;
          background: color-mix(in srgb, var(--colors-surface-400), transparent 50%);
          top: 0;
          inset-inline-end: 0;
          content: "";
        }
      `
      : ""}
`;

export interface NewRequestCategoryProps {
  label: string;
  applyRegex?: RegExp;
  onApply: (data: { value: string; callBackFail: () => void; callBackSuccess: () => void }) => void;
}

const NewRequestCategory = (props: NewRequestCategoryProps) => {
  const [isMainButtonVisible, setIsMainButtonVisible] = useState(true);
  const [value, setValue] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.applyRegex) {
      setValue(event.target.value.replaceAll(props.applyRegex, ""));
    } else {
      setValue(event.target.value);
    }
  };

  const onApplyClick = () => {
    setInProgress(true);

    const callBackSuccess = () => {
      setInProgress(false);
      setIsMainButtonVisible(true);
      setValue("");
    };
    const callBackFail = () => {
      setInProgress(inProgress);
    };

    props.onApply({ value, callBackFail, callBackSuccess });
  };

  return (
    <ItemWrapper overlay={inProgress}>
      <InputWithButtons
        isMainButtonVisible={isMainButtonVisible}
        mainButton={
          <Button
            onClick={() => setIsMainButtonVisible(!isMainButtonVisible)}
            label={props.label}
            className="main-button"
            color="main"
            size="small"
            variant="tertiary"
          />
        }
        actions={
          <ActionInput
            value={value}
            className="action-input"
            onChange={handleChange}
            placeholder={t("Category name")}
            actions={
              <>
                <Button
                  onClick={() => {
                    setIsMainButtonVisible(true);
                  }}
                  label={t(`${TranslationNamespaces.common}|Cancel`)}
                  color="neutral"
                  size="small"
                  variant="tertiary"
                />
                <Button
                  onClick={onApplyClick}
                  label={t(`${TranslationNamespaces.common}|Add`)}
                  color="main"
                  size="small"
                  variant="tertiary"
                  disabled={!value}
                />
              </>
            }
          />
        }
      />
    </ItemWrapper>
  );
};

export default NewRequestCategory;
