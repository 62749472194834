import React, { Component } from "react";
import styled from "styled-components";
import InlineMessage from "./InlineMessage";

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 3.998A1.003 1.003 0 008 6a1 1 0 00.998-1A1 1 0 008 3.998zm0 3c-.552 0-1.001.448-1.001 1.002v3a1 1 0 002 0V8A1 1 0 008 6.999zM8 0C3.588 0 0 3.588 0 8c0 4.41 3.588 8 8 8 4.41 0 8-3.59 8-8 0-4.412-3.59-8-8-8zm0 15.248C4.003 15.248.75 11.997.75 8 .75 4.003 4.002.75 8 .75S15.248 4.002 15.248 8 11.997 15.248 8 15.248z' fill='%238093AC'/%3E%3C/svg%3E");
  flex-shrink: 0;

  &:hover {
    .inline-message {
      display: block;
    }
  }
`;

class RichTooltip extends Component {
  state = {
    isShownTooltip: false,
  };
  showTooltip = () => {
    this.toggleTooltip(true);
  };
  hideTooltip = () => {
    this.toggleTooltip(false);
  };
  toggleTooltip = (visible) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState({ isShownTooltip: visible });
    }, 300);
  };
  render() {
    const { isShownTooltip } = this.state;
    const { children, text, style = {} } = this.props;
    return (
      <Wrapper className="rich-tooltip" style={style} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
        {isShownTooltip && (
          <InlineMessage text={text} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
            {children}
          </InlineMessage>
        )}
      </Wrapper>
    );
  }
}

export default RichTooltip;
