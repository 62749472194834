import ConfirmationModal from "components/UI/ConfirmationModalNew";
import { useEffect, useState } from "react";
import { getCurrentCompany, getIntegration } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import styled from "styled-components";
import PegadorContent from "../Content";

const Wrapper = styled.div`
  margin: 32px 0 48px;

  .page__content {
    margin: 0;

    .rep-pegador__copy-control {
      display: flex;
      gap: 0;
      align-items: center;
    }

    .rep-pegador__label {
      margin-bottom: 8px;
      font-size: 13px;
      line-height: 13px;
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-surface-800);
    }

    .rep-pegador__input {
      padding: 0 8px;
      line-height: 46px;
      font-size: 15px;
      border-color: var(--colors-surface-50);
      background: var(--colors-surface-50);
      color: var(--colors-surface-900-p);
      flex-shrink: 0;
    }

    .rep-pegador__copy-button {
      line-height: 46px;
      flex-shrink: 0;
      width: 81px;
    }
  }
`;

interface PegadorModalProps {
  onClose: () => void;
}

interface PegadorModalState {
  loaded: boolean;
  lastSync: string | null;
  token: string | null;
}

const PegadorModal = ({ onClose }: PegadorModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.reps);
  const [{ token, lastSync, loaded }, setState] = useState<PegadorModalState>({
    token: null,
    lastSync: null,
    loaded: false,
  });

  useEffect(() => {
    void (async () => {
      try {
        const company = await getCurrentCompany();
        if (!company) return;

        const r = await getIntegration({ companyUuid: company.uuid });

        setState({
          token: r?.integration?.token || null,
          lastSync: r?.integration?.status || null,
          loaded: true,
        });
      } catch (e) {
        sentryUtils.sendError(e);
        window.alert(e);
      }
    })();
  }, []);

  return (
    <ConfirmationModal isOpen title={t("It’s me Pegador")} onClose={onClose} width={484}>
      <Wrapper>
        {loaded ? <PegadorContent token={token} lastSync={lastSync} /> : <span>{t("Loading...")}</span>}
      </Wrapper>
    </ConfirmationModal>
  );
};

export default PegadorModal;
