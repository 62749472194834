import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  width: 320px;
  top: 100%;
  inset-inline-start: 0;
  z-index: 1;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-surface-0);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
  display: flex;
  flex-direction: column;
  min-height: 180px;
  max-height: 360px;
  overflow: visible; /* TODO: make it hidden in order to fix scrolling issue. In that case dropdown for selecting breaks will be broken */
`;

const DropdownHeader = styled.div`
  flex-shrink: 0;
  position: sticky;
  top: 0;
`;

const DropdownContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  flex: 1;
  overflow-y: auto;
  width: 100%;
`;

const DropdownFooter = styled.div`
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: var(--component-menu-actions-border, 1px) solid var(--component-menu-actions-border-color, #e1e3eb);
  background: var(--component-menu-actions-bg-color, var(--colors-gray-100-a88, #f9f9fbe0));
  padding: 4px 6px;
`;

type CellDropdownProps = {
  header?: JSX.Element;
  content?: JSX.Element;
  footer?: JSX.Element;
};

const CellDropdown = ({ header, content, footer }: CellDropdownProps) => (
  <Wrapper>
    {header && <DropdownHeader>{header}</DropdownHeader>}
    {content && <DropdownContent>{content}</DropdownContent>}
    {footer && <DropdownFooter>{footer}</DropdownFooter>}
  </Wrapper>
);

export default CellDropdown;
