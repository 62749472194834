import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import Pagination from "components/Pagination";
import { getSelectedColumns } from "utils/tableHelpers";
import { getDateColumn, getDateCellData, getEmployeeNameColumn } from "utils/reportsHelpers";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { minsToHrsMins } from "utils/common";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import ReportsNoContent from "./ReportsNoContent";
import { sliceReportIntoChunks } from "./helpers";
import "styles/reports-extra-hour-table.scss";

const b = BEM.b("reports-extra-hour-table");

class ReportsLateArrivalsTableAllEmployees extends Component {
  state = {
    employees: [[]],
    loading: false,
    selectedColumns: getSelectedColumns("date,name,time,punch_type,reason", "ReportsLateArrivalsTableAllEmployees"),
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const { perPage } = this.state;
    const {
      t,
      startDate,
      endDate,
      searchObj,
      selectedPunchType,
      showInactiveEmploees,
      skipSupervisors,
      onlyNonZeroLines,
      oldReport,
      setIsLoading,
    } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, () => setIsLoading(true));

      try {
        const resp = await getReport({
          showInactiveEmploees,
          skipSupervisors,
          onlyNonZeroLines,
          punchType: selectedPunchType,
          groupUUID: searchObj.uuid,
          groupType: searchObj.type,
          startDate,
          endDate,
          companyUUID,
          type: "late_arrival",
          oldReport,
        });

        const employees = resp.dates || resp;
        const chunks = sliceReportIntoChunks(employees, perPage);

        this.setState({
          employees: chunks.length ? chunks : [[]],
          totalRecords: employees.length,
          loading: false,
          error: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({ error: t("Failed to generate report") });
      } finally {
        setIsLoading(false);
      }
    }
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsLateArrivalsTableAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPageLimitChange = (pageLimit) => {
    const { employees } = this.state;

    this.setState({ page: 1, perPage: pageLimit, employees: sliceReportIntoChunks(employees.flat(), pageLimit) });
  };

  render() {
    const { loading, error, employees, selectedColumns, page, perPage, totalRecords } = this.state;
    const {
      t,
      startDate,
      endDate,
      searchObj,
      selectedPunchType,
      skipSupervisors,
      onlyNonZeroLines,
      showInactiveEmploees,
      oldReport,
    } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    const data = employees[page - 1].map((d) => ({
      fullName: d.fullName,
      date: d.date ? getDateCellData(d) : "",
      matricula: d.matricula,
      cpf: d.cpf,
      lateMinutes: d.lateMinutes,
      punchType: d.punchType,
      reason: d.reason,
      status: d.status,
    }));
    const columns = [];

    columns.push(getDateColumn(t));

    columns.push(
      getEmployeeNameColumn(t),
      {
        label: t("Delay"),
        accessor: "lateMinutes",
        rubyAccessor: "time",
        Cell: (row) => minsToHrsMins(row.value),
        align: "right",
        style: { paddingRight: "20px" },
        labelStyle: { paddingRight: "20px" },
        minWidth: 100,
      },
      {
        label: t("Punch"),
        accessor: "punchType",
        rubyAccessor: "punch",
        Cell: (row) => t(row.value),
        minWidth: 130,
      },
      {
        label: t("Reason"),
        accessor: "reason",
        rubyAccessor: "reason",
        minWidth: 130,
      },
    );

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj && searchObj.type && searchObj.type !== "all" && searchObj.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    return (
      <div className={b()}>
        <TablePage
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              options={[
                { label: "PDF", value: "pdf" },
                { label: "XLSX", value: "xlsx.xlsx" },
              ]}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  skipSupervisors,
                  showInactiveEmploees,
                  onlyNonZeroLines,
                  punchType: selectedPunchType,
                  searchObj,
                  startDate,
                  endDate,
                  oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: selectedColumns ? "late_arrival_all" : "latearrival",
                })
              }
            />
          }
          rows={data}
          columns={columns}
          interactive={false}
          loading={loading}
          className="reports-hoursbank-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("reports-page")(ReportsLateArrivalsTableAllEmployees));
