import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import { DailySummaryPunch, Punch, PunchStatuses } from "types/models/punches";
import moment from "moment";
import * as momentTz from "moment-timezone";
import ga, { GaApprovePunchButtonLocation } from "utils/ga";
import { fireEvent } from "utils/common";
import { OnPunchActionData, OnPunchDeclineData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import Tooltip from "components/UI/Tooltip";
import { SuperpunchPunchCellData } from "types/models/superpunch";
import NoPunches from "./NoPunches";

const DropdownItemWrapper = styled.div`
  padding: 0 13px;

  &:hover {
    border-radius: var(--shapes-border-radius-default);
    background: var(--colors-activitiCellScheduleItemHover);
    cursor: pointer;

    .inner {
      border-top: 1px solid transparent;
    }

    .view-punch {
      display: block;
    }

    .punch-indicator {
      display: none;
    }

    & + div .inner {
      border-top: 1px solid transparent;
    }
  }

  &:first-child {
    .inner {
      border-top: 1px solid transparent;
    }
  }
`;
const DropdownItem = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
  text-overflow: ellipsis;
  border-top: 1px solid var(--colors-surface-150);
  color: var(--colors-surface-900-p);
`;

const PunchTime = styled.div`
  display: flex;
  overflow: hidden;
  letter-spacing: 0;
  text-overflow: ellipsis;
  color: var(--colors-surface-800);
`;

const ManualPunchIcon = styled.div`
  margin-inline-start: 8px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: var(--typography-font-weight-medium);
  border: 1px solid var(--colors-activitiInfoRowBorder);
  border-radius: 3px;
  color: var(--colors-surface-400);
`;

const getStatusBgColor = (status: PunchStatuses) => {
  if (status === PunchStatuses.pending) {
    return `var(--colors-pending)`;
  }
  if (status === PunchStatuses.invalid) {
    return `var(--colors-error)`;
  }
  return "none";
};

const Status = styled.div<{ isPending: boolean; status: PunchStatuses }>`
  position: absolute;
  top: calc(50% - 5px);
  inset-inline-end: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: ${(props) => getStatusBgColor(props.status)};
`;
const ViewPunch = styled.div`
  display: none;
  position: absolute;
  inset-inline-end: -12px;
  width: 44px;
  height: 20px;
  border-inline-start: 1px solid var(--colors-surface-0);
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='19' height='12'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0h19v11.543H0V0z' fill='%23fff'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.422 5.771c.976 1.128 4.271 4.578 8.077 4.578 3.813 0 7.102-3.448 8.076-4.577C16.6 4.643 13.304 1.194 9.5 1.194c-3.814 0-7.103 3.448-8.077 4.577zm-1.304.353a.575.575 0 010-.705C.288 5.198 4.332 0 9.498 0s9.21 5.198 9.38 5.42a.574.574 0 010 .704c-.17.221-4.214 5.419-9.38 5.419S.288 6.345.118 6.123z' fill='%238093AC'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.499 2.868A2.481 2.481 0 007.02 5.347 2.481 2.481 0 009.5 7.825a2.481 2.481 0 002.479-2.478 2.481 2.481 0 00-2.479-2.48zm0 6.196a3.722 3.722 0 01-3.718-3.718A3.722 3.722 0 019.5 1.63a3.722 3.722 0 013.718 3.717 3.722 3.722 0 01-3.718 3.718z' fill='%238093AC'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;
const Wrapper = styled.div``;

type PunchesListProps = {
  punches: Punch[];
  date: moment.Moment;
  employeeUuid: string;
  onChange: (punch?: DailySummaryPunch | Record<string, never>) => void;
  onPunchApprove: (data: OnPunchActionData) => void;
  onPunchValidate: (data: OnPunchActionData) => void;
  onPunchDecline: (data: OnPunchDeclineData) => void;
  hideDropdown: () => void;
  cellData: SuperpunchPunchCellData;
};

const PunchesList = ({
  punches,
  date,
  employeeUuid,
  onChange,
  onPunchApprove,
  onPunchValidate,
  onPunchDecline,
  hideDropdown,
  cellData,
}: PunchesListProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  if (!punches?.length) {
    return <NoPunches>{t("No created punches yet")}</NoPunches>;
  }

  let sortedPunches = cellData.raw ? punches.filter((p) => p.uuid !== cellData.raw?.uuid) : punches;
  sortedPunches = sortedPunches.filter((p) => {
    const formatedDate = date.format("YYYY-MM-DD");
    const formatedNextDate = date.clone().add(1, "day").format("YYYY-MM-DD");
    const formatedPrevDate = date.clone().subtract(1, "day").format("YYYY-MM-DD");
    const formatedPunchDate = moment(momentTz.tz(p.device_datetime, p.time_zone), "YYYY-MM-DD").format("YYYY-MM-DD");

    return (
      formatedPunchDate === formatedDate ||
      formatedPunchDate === formatedPrevDate ||
      formatedPunchDate === formatedNextDate
    );
  });

  sortedPunches = sortedPunches.sort((pA, pB) => {
    if (moment(pA.device_datetime).isBefore(moment(pB.device_datetime))) {
      return -1;
    }
    if (moment(pA.device_datetime).isAfter(moment(pB.device_datetime))) {
      return 1;
    }
    return 0;
  });

  return (
    <Wrapper>
      {sortedPunches.map((punch) => {
        const otherDay =
          moment(momentTz.tz(punch.device_datetime, punch.time_zone), "YYYY-MM-DD").format("YYYY-MM-DD") !==
          moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")
            ? moment(momentTz.tz(punch.device_datetime, punch.time_zone), "YYYY-MM-DD").format("DD/MM")
            : "";

        return (
          <DropdownItemWrapper
            key={punch.uuid}
            onClick={() => {
              onChange(punch);
            }}
          >
            <DropdownItem className="inner">
              <PunchTime>
                {momentTz.tz(punch.device_datetime, punch.time_zone).format("HH:mm")}
                {otherDay ? (
                  <b>
                    &nbsp;
                    {otherDay}
                  </b>
                ) : (
                  ""
                )}

                {punch.is_manual && (
                  <ManualPunchIcon data-tip={punch.is_manual} data-for={punch.is_manual ? "cell-punch-manual" : ""}>
                    M
                  </ManualPunchIcon>
                )}
              </PunchTime>

              <Status
                status={punch.status}
                className="punch-indicator"
                data-tip
                data-for={punch.status === PunchStatuses.pending ? "cell-punch-pending" : ""}
                isPending={punch.status === PunchStatuses.pending}
              />

              <ViewPunch
                className="view-punch"
                data-tip
                data-for="cell-punch-details"
                onClick={(ev) => {
                  ev.stopPropagation();

                  hideDropdown();

                  fireEvent(SuperpunchCustomEvents.openPunchDetails, {
                    punch,
                    onPunchDetailsApprove: () => {
                      ga.trackApprovePunch({
                        punch_type: punch.punch_type,
                        button_location: GaApprovePunchButtonLocation.superPunch,
                      });

                      onPunchApprove({
                        destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                        punchUuid: punch.uuid,
                        punchId: punch.id,
                        employeeUuid,
                      });
                    },
                    onPunchDetailsValidate: () => {
                      onPunchValidate({
                        destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                        punchUuid: punch.uuid,
                        punchId: punch.id,
                        employeeUuid,
                      });
                    },
                    onPunchDetailsDecline: (declineReason: string) => {
                      onPunchDecline({
                        destinationDate: momentTz.tz(punch.device_datetime, punch.time_zone),
                        punchUuid: punch.uuid,
                        punchId: punch.id,
                        employeeUuid,
                        declineReason,
                      });
                    },
                  });
                }}
              />
            </DropdownItem>
          </DropdownItemWrapper>
        );
      })}

      <Tooltip id="cell-punch-manual" wrapperStyle={{ zIndex: 1 }}>
        <span>{t("Manual Punch")}</span>
      </Tooltip>
      <Tooltip id="cell-punch-details" wrapperStyle={{ zIndex: 1 }}>
        <span>{t("View details")}</span>
      </Tooltip>
    </Wrapper>
  );
};

export default PunchesList;
