import { create } from "zustand";
import { Request } from "types/models/request";
import { Punch } from "types/models/punches";
import { OnCall, OnCallTableData } from "types/models/onCalls";
import { Schedule } from "types/models/schedule";
import { TimesheetsListItem } from "services/oitchau-api";
import { Activity } from "../Projects/projectsApiTypes";

type GoogleEvent = {
  allDay: boolean;
  end: string; // "2023-07-12",
  googleCalendarId: string;
  id: string;
  start: string; // "2023-07-11",
  title: string;
  color: string; // "#9fe1e7",
  editable: boolean;
  googleEvent: {
    kind: string; // "calendar#event",
    etag: string;
    id: string;
    status: string; // "confirmed",
    htmlLink: string;
    created: string; // "2023-07-11T09:03:38.000Z",
    updated: string; // "2023-07-11T09:03:38.527Z",
    summary: string; // "test",
    creator: {
      email: string;
      self: boolean;
    };
    organizer: {
      email: string;
      self: boolean;
    };
    start: {
      date: string; // "2023-07-11"
    };
    end: {
      date: string; // "2023-07-12"
    };
    // "transparency": "transparent",
    // "iCalUID": "5r0enddkr7hd7nnapvt7hg1qpc@google.com",
    // "sequence": 0,
    // "reminders": {
    //   "useDefault": false,
    //   "overrides": [
    //     {
    //       "method": "popup",
    //       "minutes": 30
    //     }
    //   ]
    // },
    eventType: string; // "default"
  };
};
type ScheduleWithStartDate = Schedule & {
  startDate: string; // "YYYY-MM-DD" format
};
interface ActivitiesStore {
  activities: Activity[];
  requests: Request[];
  punches: Punch[];
  onCallEvents: OnCall[];
  schedules: ScheduleWithStartDate[];
  googleEvents: GoogleEvent[];
  lastLockDate: string | null;
  timesheets: TimesheetsListItem[];

  runningActivity: Activity | null;
  pendingActivity?: any;
  selectedActivity: Activity | null;
  selectedRequest: Request | null;
  selectedOnCall: OnCallTableData | null;

  loadActivities: (activities: Activity[]) => void;
  loadRequests: (requests: Request[]) => void;
  loadPunches: (punches: Punch[]) => void;
  loadOnCallEvents: (onCallEvents: OnCall[]) => void;
  loadSchedules: (schedules: ScheduleWithStartDate[]) => void;
  loadGoogleEvents: (events: GoogleEvent[]) => void;
  loadLastLockDate: (lastLockDate: string | null) => void;
  loadTimesheets: (timesheets: TimesheetsListItem[]) => void;

  selectActivity: (activity: Activity | null) => void;
  setRunningActivity: (activity: Activity | null) => void;
  setPendingActivity: (activity: Activity) => void;
  selectRequest: (request?: Request) => void;
  selectOnCall: (onCall: OnCallTableData | null) => void;
}
/**
 * It is the test implementation that is used for activities page and will be used both for table/calendar view
 * for the first iteration it will just manage data based on Activities page actions,
 * later if we agree to use it all actions must be moved here and used as a central place to interact with activities related pages
 */
export const useActivitiesStore = create<ActivitiesStore>((set) => ({
  activities: [],
  requests: [],
  punches: [],
  onCallEvents: [],
  schedules: [],
  googleEvents: [],
  lastLockDate: null,
  timesheets: [],

  selectedOnCall: null,
  selectedActivity: null,
  selectedRequest: null,
  runningActivity: null,
  pendingActivity: null,

  loadActivities: (activities) => set(() => ({ activities })),
  loadRequests: (requests) => set(() => ({ requests })),
  loadPunches: (punches) => set(() => ({ punches })),
  loadOnCallEvents: (onCallEvents) => set(() => ({ onCallEvents })),
  loadSchedules: (schedules) => set(() => ({ schedules })),
  loadGoogleEvents: (events) => set(() => ({ googleEvents: events })),
  loadLastLockDate: (lastLockDate) => set(() => ({ lastLockDate })),
  loadTimesheets: (timesheets) => set(() => ({ timesheets })),

  selectActivity: (activity) => set(() => ({ selectedActivity: activity })),
  setRunningActivity: (activity) => set(() => ({ runningActivity: activity })),
  setPendingActivity: (activity) => set(() => ({ pendingActivity: activity })),
  selectRequest: (request) => set(() => ({ selectedRequest: request })),
  selectOnCall: (onCall) => set(() => ({ selectedOnCall: onCall })),
}));
