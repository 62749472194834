import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  CreatePayrollLayoutRequestData,
  DeletePayrollLayoutRequestData,
  DeletePayrollLayoutResponseData,
  GetPayrollEventTypesRequestData,
  GetPayrollEventTypesResponseData,
  GetPayrollLayoutRequestData,
  GetPayrollLayoutResponseData,
  GetPayrollProvidersResponseData,
  UpdatePayrollLayoutRequestData,
} from "./types";

type TODORequestData = any;

type TODOResponseData = Promise<unknown>;

export function getPayrollProviders(): APIResponse<GetPayrollProvidersResponseData> {
  return sendRequest({}, "GET", "/payroll_providers", CONFIG.api_exporting);
}

export function getPayrollEventTypes(
  data: GetPayrollEventTypesRequestData,
): APIResponse<GetPayrollEventTypesResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUUID}/payroll_event_types`, CONFIG.api_exporting);
}

export function getPayrollLayout(data: GetPayrollLayoutRequestData): APIResponse<GetPayrollLayoutResponseData> {
  return sendRequest({}, "GET", `/payroll_layouts/${data.payrollUUID}`, CONFIG.api_exporting);
}

export function createPayrollLayout(data: CreatePayrollLayoutRequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUUID}/payroll_layouts`, CONFIG.api_exporting);
}

export function updatePayrollLayout(data: UpdatePayrollLayoutRequestData): TODOResponseData {
  return sendRequest(data.body, "PATCH", `/payroll_layouts/${data.payrollUUID}`, CONFIG.api_exporting);
}

export function deletePayrollLayout(data: DeletePayrollLayoutRequestData): Promise<DeletePayrollLayoutResponseData> {
  return sendRequest({}, "DELETE", `/payroll_layouts/${data.payrollUUID}`, CONFIG.api_exporting);
}

export function getPayrollReport(data: TODORequestData): TODOResponseData {
  let extraParams = "";

  if (data.groupType) {
    extraParams += `&groupType=${data.groupType}`;
  }
  if (data.groupUuid) {
    extraParams += `&groupUuid=${data.groupUuid}`;
  }
  if (data.forceRecalculate) {
    extraParams += "&forceRecalculate=true";
  }

  return sendRequest(
    {},
    "GET",
    `/payroll_layout/${data.payrollUUID}/report?from=${data.from}&to=${data.to}&requestedBy=${data.requestedBy}${extraParams}`,
    CONFIG.api_exporting,
  );
}
