import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import SearchControl, { SearchControlItemType, SearchControlOnChangeData } from "components/UI/SearchControlNew";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import Select from "components/UI/Select";
import CheckboxControl from "components/UI/CheckboxControl";
import { TranslationNamespaces } from "types/translationNamespaces";
import BEM from "utils/BEM";
import "styles/hours-bank-adjust-popup.scss";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { getEmployeeTaxIdTranslation, getEmployeeTaxPayerType } from "utils/common";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRuleNames } from "types/models/companyRules";
import { EmployeeTaxPayerTypes } from "types/common";
import MultiWithSearch from "components/UI/Select/MultiWithSearch";
import { getSelectedColumns } from "utils/tableHelpers";

const b = BEM.b("hours-bank-adjust-popup");

const DatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 20px;
  height: 60px;
`;

const DateWrapper = styled.div`
  margin: 0 15px;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const LabelFormat = styled.div`
  margin-top: 19px;
  margin-bottom: 5px;
  font-size: 13px;
  letter-spacing: 0;
`;

const Label = styled.div`
  margin-top: 19px;
  margin-bottom: 5px;
  font-size: 13px;
  letter-spacing: 0;
`;

interface ColumnSelectorOptions {
  value: string;
  label: string;
  disabled?: boolean;
}

export type ReportWithCustomRange = {
  showInactive: boolean;
  skipSupervisors: boolean;
  createdBy: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  format: string;
  searchObj: SearchControlOnChangeData;
  selectedColumns: string;
};

interface ReportsDownloadCustomPeriodDialogProps extends WithTranslation {
  onClose: () => void;
  onYes: (data: ReportWithCustomRange) => void;
}

interface ReportsDownloadCustomPeriodDialogState {
  searchValue: string;
  errors: Record<string, string> | null;
  format: string;
  searchObj: SearchControlOnChangeData;
  startDate: moment.Moment;
  endDate: moment.Moment;
  showInactive: boolean;
  skipSupervisors: boolean;
  isNightReducedHoursColumnAllowed: boolean;
  /** comma separated */
  selectedColumns: string;
}

class ReportsDownloadCustomPeriodDialog extends Component<
  ReportsDownloadCustomPeriodDialogProps,
  ReportsDownloadCustomPeriodDialogState
> {
  readonly state: Readonly<ReportsDownloadCustomPeriodDialogState> = {
    searchValue: "",
    errors: null,
    format: "csv",
    searchObj: { type: SearchControlItemType.all, id: "all", label: "All" },
    startDate: moment(),
    endDate: moment().clone().add(1, "month"),
    showInactive: false,
    skipSupervisors: false,
    isNightReducedHoursColumnAllowed: false,
    selectedColumns: getSelectedColumns(
      "name,worked_days,absense_days,planned_hours,worked_hours,schedules,business_rules_groups",
      `reports_summmary_download_dialog`,
    ),
  };

  async componentDidMount(): Promise<void> {
    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );
    this.setState({ isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value });
  }

  getColumnsSelectorOptions(): ColumnSelectorOptions[] {
    const { t } = this.props;
    const { isNightReducedHoursColumnAllowed } = this.state;

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);
    const nightReducedHoursColumn = isNightReducedHoursColumnAllowed
      ? [{ value: "total_night_reduced_hours", label: t("ns-col-reduced-hours") }]
      : [];

    return [
      {
        value: "name",
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employee-name",
          `${TranslationNamespaces.reportsPage}|Employee name`,
        ),
        disabled: true,
      },
      { value: "cpf", label: employeeTaxIdLabel },
      { value: "matricula", label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula") },
      { value: "pis", label: t("PIS") },
      { value: "schedules", label: t("Schedule") },
      { value: "business_rules_groups", label: t("Business Rules Group") },
      { value: "supervisor", label: t("Supervisor") },
      { value: "position_name", label: t("Position") },
      { value: "department", label: t("Department") },
      { value: "worked_days", label: t("Worked Days") },
      { value: "absense_days", label: t("Absence Days") },
      { value: "night_hours", label: t("nightShiftMinutes") },
      ...nightReducedHoursColumn,
      { value: "planned_hours", label: t("Planned hours") },
      { value: "worked_hours", label: t("Worked hours"), disabled: true },
      { value: "total_late_entry_hours", label: t("Late Arrival") },
      { value: "total_late_arrivals", label: t("Total Late Arrivals") },
      { value: "late_arrivals_without_breaks", label: t("Late Arrivals w/o Breaks") },
      { value: "total_cross_shifts_interval_diff_hours", label: t("Cross Shift Interval") },
      { value: "total_early_leave_hours", label: t("Early Leave") },
      { value: "total_early_leaves", label: t("Total Early Leaves") },
      { value: "early_leaves_without_breaks", label: t("Early Leaves w/o Breaks") },
      { value: "total_extra_hours", label: t("Extra Hours") },
      { value: "cumulative_hours_bank_hours", label: t("Accumulated Hours Bank") },
      { value: "total_missed_hours", label: t("Missed Minutes") },
      { value: "total_break_hours", label: t("Total Break hours") },
      { value: "total_on_call_hours", label: t("On Call Minutes") },
      { value: "total_on_call_activated_hours", label: t("On Call Activated Minutes") },
      { value: "total_on_call_reducing_activated_hours", label: t("Department") },
      { value: "department", label: t("On Call Reducing Activated Minutes") },
      { value: "total_debit_hours", label: t("Debit Minutes") },
      { value: "total_unused_break_hours", label: t("Unused Break") },
    ];
  }

  closePopup() {
    this.props.onClose();
  }

  onCancel = () => {
    this.closePopup();
  };

  getFormErrors() {
    const { t } = this.props;
    const { startDate, endDate, searchObj } = this.state;
    let errors: Record<string, string> | null = {};

    if (!startDate) {
      errors.startDate = t("Please select start date");
    }
    if (!endDate) {
      errors.endDate = t("Please select end date");
    }
    // if (!searchObj.id) {
    //   errors.employee = t("Please select a group");
    // }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  }

  onYes = () => {
    const { startDate, endDate, format, searchObj, showInactive, skipSupervisors, selectedColumns } = this.state;
    const errors = this.getFormErrors();

    if (!errors) {
      this.props.onYes({
        showInactive,
        skipSupervisors,
        createdBy: window.global_store.profile.uuid,
        startDate,
        endDate,
        format,
        searchObj,
        selectedColumns,
      });
    }
    this.setState({ errors });
  };

  onColumnsChange = (selectedColumns: string[]) => {
    const joinedColums = selectedColumns.join();

    if (localStorage) {
      localStorage.setItem(`customColumns_reports_summmary_download_dialog`, joinedColums);
    }

    this.setState({ selectedColumns: joinedColums });
  };

  render() {
    const { startDate, endDate, format, showInactive, skipSupervisors, searchValue, errors, selectedColumns } =
      this.state;
    const monthLimit = window.global_store.beta ? 24 : 12;
    const columnsSelectorOptions = this.getColumnsSelectorOptions();

    const { t } = this.props;
    return (
      <div className={b()}>
        <div className={b("title")}>{t("Download Summary")}</div>
        <SearchControl
          permissionSection={PermissionSectionName.advancedReports}
          value={searchValue}
          onChange={(searchObj: SearchControlOnChangeData) => {
            // this.setState({ searchObj, searchValue: searchObj.label });
          }}
          placeholder={translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-all-employees",
            `${TranslationNamespaces.common}|All Employees`,
          )}
          searchGroups
          groupsOnly
          showAllEmployeesItem
          locked
        />
        {errors?.employee && <div className={b("error")}>{errors.employee}</div>}
        <DatesWrapper>
          <DateWrapper>
            <div className={b("label")}>{t("Start Period")}</div>
            <SingleDatePickerControl
              value={startDate}
              error={!!errors?.startDate}
              onChange={(date) => this.setState({ startDate: date })}
              isOutsideRange={(day) =>
                day.isBefore(moment().clone().subtract(monthLimit, "month")) ||
                day.isAfter(moment().clone().add(1, "month").endOf("month"))
              }
            />
          </DateWrapper>
          <DateWrapper>
            <div className={b("label")}>{t("End Period")}</div>
            <SingleDatePickerControl
              value={endDate}
              error={!!(errors && errors.endDate)}
              onChange={(date) => this.setState({ endDate: date })}
              isOutsideRange={(day) =>
                day.isBefore(moment().clone().subtract(monthLimit, "month")) ||
                day.isAfter(moment().clone().add(1, "month").endOf("month"))
              }
            />
          </DateWrapper>
        </DatesWrapper>
        {errors && (errors.startDate || errors.endDate) && (
          <div className={b("error")}>{errors.startDate || errors.endDate}</div>
        )}
        <LabelFormat>{t("Format")}</LabelFormat>
        <Select
          value={format}
          modifiers={{ field: true }}
          onChange={(val) => this.setState({ format: val })}
          options={[
            // { value: "pdf", label: "PDF" },
            { value: "csv", label: "CSV" },
            // { value: "xlsx", label: "XLSX" },
          ]}
        />

        <>
          <Label>{t("Include columns")}</Label>

          <MultiWithSearch
            lightUI
            placeholder={t("Select report columns")}
            value={selectedColumns.split(",")}
            onChange={this.onColumnsChange}
            options={columnsSelectorOptions}
          />
        </>
        <Spacer height={15} />
        <div>
          <CheckboxControl checked={showInactive} osx onChange={(val) => this.setState({ showInactive: val })} />
          <label className="label" style={{ marginInlineStart: "10px" }}>
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.reportsPage,
              "custom-show-inactive-employees",
              `${TranslationNamespaces.reportsPage}|Show inactive employees`,
            )}
          </label>
        </div>
        <div>
          <CheckboxControl checked={skipSupervisors} osx onChange={(val) => this.setState({ skipSupervisors: val })} />
          <label className="label" style={{ marginInlineStart: "10px" }}>
            {t("Hide all supervisors")}
          </label>
        </div>
        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.onCancel}>
            {t("Cancel")}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes}>
            {t("Download")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsDownloadCustomPeriodDialog);
