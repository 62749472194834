enum LockAction {
  lock = "lock",
  unlock = "unlock",
}

type SelectedLockReportEmployee = {
  userProfileUuid: string;
  action: LockAction;
};

export { LockAction };

export enum DigitalSignatureStatus {
  pending = "pending",
  canceled = "canceled",
  completed = "completed",
  signed = "signed",
  partial = "partial",
}

export enum PayrollGroupName {
  default = "default",
  deactivatedProfile = "DEACTIVATED_PROFILE_PAYROLL_GROUP",
}

export type { SelectedLockReportEmployee };
export type DigitalSignatureResponse = {
  canceledCount: number;
  dismissedCount: number;
  endDate: string;
  forceSignedCount: number;
  payrollGroupName: string;
  payrollGroupUuid: string;
  payrollLockUuid: string;
  pendingCount: number;
  signedCount: number;
  startDate: string;
  status?: DigitalSignatureStatus;
  totalCount: number;
};

export const DSR_PHASE_KEY = "dsr";
