import moment from "moment";
import {
  Coupon,
  Customer,
  Feature,
  FeatureLabel,
  Interval,
  Product,
  Subscription,
  SubscriptionItem,
  SubscriptionStatus,
  Tier,
} from "types/models/subscription";
import { UserProfileRole } from "types/models/userProfile";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import sentryUtils from "utils/sentryUtils";
import { TFunction } from "i18next";
import { SubscriptionApi } from "utils/api/subscription";
import config from "config";
import { AMOUNT_OF_FREE_DAYS, MINIMAL_COMPANY_SIZE } from "./components/InitialDiscountNotification";

export type AllowedAddFreeEmployees = {
  [Feature.TA]: number | boolean;
  [Feature.Project]: number | boolean;
};

export const BUNDLE_PROJECTS_DISCOUNT_PERCENT = 15;

export class BillingService {
  static findSubscriptions(subscriptions: Subscription[], status: SubscriptionStatus): Subscription | undefined {
    return subscriptions.find((s) => s.status === status);
  }

  static getTrialDays(subscription: Subscription) {
    return moment(subscription?.trialEndedAt).add(1, "day").diff(moment(), "days");
  }

  static isNeedToLock(subscriptions: Subscription[]): boolean {
    if (subscriptions.length === 0) return false;
    const activeSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Active);
    const pastDueSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.PastDue);
    const freeSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Free);
    if (activeSubscription || pastDueSubscription || freeSubscription || BillingService.isEnterpriseCustomer())
      return false;

    const trialSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Trialing);
    if (trialSubscription) return BillingService.getTrialDays(trialSubscription) <= 0;

    return true;
  }

  static checkIsCompanyAdmin(onlyEmployer = false): boolean {
    const { profile } = window.global_store;
    const isOwner = profile.role === UserProfileRole.employer;
    if (onlyEmployer && !isOwner) return false;
    return !!profile.permissions.find((r) => r === PermissionSectionName.billing);
  }

  static isCompanyManagement(): boolean {
    const { profile } = window.global_store;
    return !!profile?.permission_roles?.find((role) =>
      [PermissionRoleName.owner, PermissionRoleName.admin, PermissionRoleName.HR].includes(role.name),
    );
  }

  static checkLocking(): boolean {
    try {
      const subscriptionsString = localStorage.getItem("gs_subscriptions");
      if (subscriptionsString) {
        const subscriptions = JSON.parse(subscriptionsString);
        return BillingService.isNeedToLock(subscriptions);
      }
    } catch (err) {
      sentryUtils.sendError(err);
      return false;
    }
    return false;
  }

  static calculateTotalPrice(
    tier: Tier,
    interval: Interval,
    coupon: Coupon | null,
    productExternalId?: string,
  ): number {
    let discountMultiplier = 1;
    if (
      coupon &&
      (!coupon.appliesTo?.products.length ||
        (productExternalId && coupon.appliesTo?.products.includes(productExternalId)))
    ) {
      discountMultiplier = (100 - (coupon?.percentOff || 0)) / 100; // eg 1 0.85
    }
    return (((tier.unit_amount || 0) * tier.up_to! + (tier.flat_amount || 0)) / 100) * discountMultiplier;
  }

  static calculateUnitPrice(tier: Tier, interval: Interval, coupon: Coupon | null, productExternalId?: string): number {
    const unitPrice = BillingService.calculateTotalPrice(tier, interval, coupon, productExternalId) / tier.up_to!;
    if (interval === Interval.Month) return unitPrice;
    return unitPrice / 12;
  }

  static calculateTotalPerMonth(
    tier: Tier,
    interval: Interval,
    coupon: Coupon | null,
    productExternalId?: string,
  ): number {
    const total = BillingService.calculateTotalPrice(tier, interval, coupon, productExternalId);
    if (interval === Interval.Month) return total;
    return total / 12;
  }

  static getProductName(product: Product, t: TFunction): string {
    // eslint-disable-next-line no-prototype-builtins
    return FeatureLabel.hasOwnProperty(product.features[0]) ? t(FeatureLabel[product.features[0]]) : product.name;
  }

  static formatPaymentPeriodDate(date: Date): string {
    return moment(date).format("MMM DD, YYYY");
  }

  static getAllowedItems(): Feature[] {
    const subscriptionsString = localStorage.getItem("gs_subscriptions");
    if (subscriptionsString && subscriptionsString !== "[]") {
      const subscriptions = JSON.parse(subscriptionsString);
      return subscriptions[0]?.items.map((i: SubscriptionItem) => i.feature);
    }
    return [Feature.TA, Feature.Project];
  }

  static get getSubscriptionsFromLocalStorage(): Subscription[] | null {
    const subscriptionsString = localStorage.getItem("gs_subscriptions");
    if (subscriptionsString) return JSON.parse(subscriptionsString);
    return null;
  }

  static async getInitialCustomerData(companyUuid: string): Promise<void> {
    const customerResponse = await SubscriptionApi.getCustomer(companyUuid);
    if (customerResponse?.uuid) {
      localStorage.setItem("gs_subscriptions", JSON.stringify(customerResponse.subscriptions));
      localStorage.setItem(
        "gs_customer",
        JSON.stringify({
          uuid: customerResponse.uuid,
          trialStarted: customerResponse.trialStarted,
          rawLocation: customerResponse.rawLocation,
          enterprise: customerResponse.enterprise,
          startedAt: customerResponse.startedAt,
        }),
      );
    } else {
      localStorage.removeItem("gs_subscriptions");
      localStorage.removeItem("gs_customer");
      localStorage.removeItem("gs_hide_initial_discount");
    }
  }

  static isNeedTrialSubscription(): boolean {
    const customer = BillingService.getCustomer();
    const subscriptions = localStorage.getItem("gs_subscriptions");
    if (!customer || subscriptions !== "[]") return false;
    return !customer?.trialStarted;
  }

  static isBrazilCompany(): boolean {
    const customer = BillingService.getCustomer();
    if (!customer) return true;
    return customer?.rawLocation === "BR";
  }

  static checkProductPermission(product: Feature.TA | Feature.Project): boolean {
    const { profile } = window.global_store;
    return profile.associate_products.includes(product);
  }

  static isEnterpriseCustomer(): boolean {
    return !!this.getCustomer()?.enterprise;
  }

  static getCustomer(): Customer | null {
    const customerString = localStorage.getItem("gs_customer") as string;
    if (!customerString) return null;
    try {
      return JSON.parse(customerString) as Customer;
    } catch (e) {
      return null;
    }
  }

  static get subscriptionPlan(): Feature {
    const subscriptionItems = this.getAllowedItems();
    if ([Feature.TA, Feature.Project].every((item) => subscriptionItems.includes(item))) {
      return Feature.Bundle;
    }
    if ([Feature.TA].every((item) => subscriptionItems.includes(item))) {
      return Feature.TA;
    }
    if ([Feature.Project].every((item) => subscriptionItems.includes(item))) {
      return Feature.Project;
    }
    return Feature.Bundle;
  }

  static checkFeatureAccess(feature: Feature.TA | Feature.Project, checkUserLevelAssociation = true): boolean {
    if (checkUserLevelAssociation && !BillingService.isCompanyManagement()) {
      return BillingService.checkProductPermission(feature);
    }
    return [Feature.Bundle, feature].includes(BillingService.subscriptionPlan);
  }

  static getAllowedRolePermissionsByPlan(): PermissionSectionName[] {
    switch (BillingService.subscriptionPlan) {
      case Feature.Bundle:
        return Object.values(PermissionSectionName);
      case Feature.TA:
        return [
          PermissionSectionName.billing,
          PermissionSectionName.clients,
          PermissionSectionName.companyProfile,
          PermissionSectionName.dashboard,
          PermissionSectionName.deactivateEmployees,
          PermissionSectionName.departments,
          PermissionSectionName.employees,
          PermissionSectionName.holidays,
          PermissionSectionName.jobSides,
          PermissionSectionName.locations,
          PermissionSectionName.marketplace,
          PermissionSectionName.onCallManagement,
          PermissionSectionName.onCallRequest,
          PermissionSectionName.payrollExport,
          PermissionSectionName.positions,
          PermissionSectionName.punches,
          PermissionSectionName.viewPunches,
          PermissionSectionName.managePunches,
          PermissionSectionName.superpunch,
          PermissionSectionName.managePermissions,
          PermissionSectionName.reports,
          PermissionSectionName.basicReports,
          PermissionSectionName.advancedReports,
          PermissionSectionName.requests,
          PermissionSectionName.requestSuperApprover,
          PermissionSectionName.rulesAndLimits,
          PermissionSectionName.schedules,
          PermissionSectionName.subsidiaries,
          PermissionSectionName.tabletSettings,
          PermissionSectionName.teams,
          PermissionSectionName.timeClocks,
          PermissionSectionName.hoursBankAdjustment,
          PermissionSectionName.punchSettings,
          PermissionSectionName.mysignatures,
          PermissionSectionName.cancelApprovedRequest,
        ];
      case Feature.Project:
        return [
          PermissionSectionName.activities,
          PermissionSectionName.approveActivities,
          PermissionSectionName.projectEmployeeCost,
          PermissionSectionName.projects,
          PermissionSectionName.billing,
          PermissionSectionName.clients,
          PermissionSectionName.companyProfile,
          PermissionSectionName.deactivateEmployees,
          PermissionSectionName.departments,
          PermissionSectionName.employees,
          PermissionSectionName.jobSides,
          PermissionSectionName.locations,
          PermissionSectionName.marketplace,
          PermissionSectionName.positions,
          PermissionSectionName.reports,
          PermissionSectionName.basicReports,
          PermissionSectionName.advancedReports,
          PermissionSectionName.subsidiaries,
          PermissionSectionName.tabletSettings,
          PermissionSectionName.teams,
        ];
      default:
        return Object.values(PermissionSectionName);
    }
  }

  /**
   * This function check is there pending invoice item and user role able to act on them.
   * for staging: delay values is owner - immediately, admin - 1day
   * for prod: owner - 3days, admin 10days
   */
  static requireActOnPendingInvoice(subscriptions: Subscription[]): boolean {
    const isAdmin = BillingService.checkIsCompanyAdmin();
    const isOwner = BillingService.checkIsCompanyAdmin(true);
    const requiredActionSubscription = subscriptions.find((subscription) => subscription.requiredActionDate);

    if (!requiredActionSubscription || (!isAdmin && !isOwner) || BillingService.isEnterpriseCustomer()) return false;
    if (
      config.appEnv === "production" &&
      ((moment().isSameOrAfter(moment(requiredActionSubscription.requiredActionDate).add(3, "days"), "day") &&
        isOwner) ||
        (moment().isSameOrAfter(moment(requiredActionSubscription.requiredActionDate).add(10, "days"), "day") &&
          isAdmin))
    ) {
      return true;
    }
    return (
      config.appEnv !== "production" &&
      (isOwner ||
        (moment().isSameOrAfter(moment(requiredActionSubscription.requiredActionDate).add(1, "days"), "day") &&
          isAdmin))
    );
  }

  static getCompanySubscriptions() {
    const subscriptions = localStorage.getItem("gs_subscriptions");
    if (!subscriptions) return [];
    return JSON.parse(subscriptions);
  }

  static getInitialDiscountTime(): null | string {
    const subscriptions = BillingService.getCompanySubscriptions() || [];
    const trialSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Trialing);
    const freeSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Free);
    const customer = BillingService.getCustomer();
    if ((!trialSubscription && !freeSubscription) || !customer || !customer.startedAt) return null;
    return customer.startedAt;
  }

  static isInitialDiscountApplicable(seats: string): boolean {
    const startedAt = BillingService.getInitialDiscountTime();
    const started = moment(startedAt).add(AMOUNT_OF_FREE_DAYS, "days");
    return this.getMaxCompanySizeValue(seats) > MINIMAL_COMPANY_SIZE && moment().diff(started) < 0;
  }

  static getMaxCompanySizeValue(employee_count: string | undefined): number {
    if (!employee_count) return 0;
    const parts = employee_count.toString().match(/\d+/g)!;
    if (parts.length < 1) return 0;
    return +parts[parts.length - 1];
  }
}
