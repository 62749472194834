import { Component, ReactNode } from "react";
import FieldWrapper from "components/UI/FieldWrapper";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import { getPayrollLayouts, getPayrollReport } from "utils/apiHelpers";
import SelectControl from "components/UI/SelectControl";
import TextInputControl from "components/controls/TextInputControlNew";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import styled from "styled-components";
import FieldTabs from "components/controls/FieldTabs";
import CheckboxControl from "components/UI/NewCheckbox";
import GlobalContext from "context/global-context";
import { PopupTitle, ButtonsRow } from "components/styled/Popup";
import { PayrollLock } from "types/models/payrollLocks";

const Spacer = styled.div`
  width: 16px;
`;

const Dates = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export type PayrollPopupWithLayoutsOnYesObj = {
  layoutUuid: string;
  from?: string;
  to?: string;
  payrollGroupUuid?: string;
  skipEmptyValues: boolean;
};

type PayrollLayout = {
  name: string;
  uuid: string;
  hoursFormat: string;
};

type Layout = {
  label: string;
  value: string;
  layout: PayrollLayout;
};

interface PayrollPopupWithLayoutsProps extends WithTranslation {
  payrollGroup?: PayrollLock | null;
  onYes: (obj: PayrollPopupWithLayoutsOnYesObj) => void;
  onClose: () => void;
  title: string;
  withForceRecalculate?: boolean;
  isBulkExport?: boolean;
  isLoading?: boolean;
}

interface PayrollPopupWithLayoutsState {
  layouts: Layout[] | null;
  skipEmptyValues: boolean;
  loading: boolean;
  selectedLayout: string | null;
}

class PayrollPopupWithLayouts extends Component<PayrollPopupWithLayoutsProps, PayrollPopupWithLayoutsState> {
  static contextType = GlobalContext;

  readonly state: PayrollPopupWithLayoutsState = {
    layouts: null,
    skipEmptyValues: true,
    loading: true,
    selectedLayout: null,
  };

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true });

    const company = await this.context.getCompany();
    const resp = await getPayrollLayouts({ companyUUID: company.uuid });
    let layouts = resp.content || [];

    layouts = layouts.map((layout: PayrollLayout) => ({
      label: layout.name,
      value: layout.uuid,
      layout,
    }));

    this.setState({ layouts, selectedLayout: layouts.length ? layouts[0].value : null, loading: false });
  }

  closePopup = (): void => {
    this.props.onClose();
  };

  onCancel = (): void => {
    this.closePopup();
  };

  forceRecalculatePayrollReport = async ({ payrollUUID, from, to, groupType, groupUuid }) => {
    await getPayrollReport({
      payrollUUID,
      from,
      to,
      groupType,
      groupUuid,
      forceRecalculate: true,
      requestedBy: window.global_store.profile.uuid,
    });
  };

  render(): ReactNode {
    const { loading, layouts, selectedLayout, skipEmptyValues } = this.state;
    const { t, title, payrollGroup, onYes, withForceRecalculate, isBulkExport, isLoading } = this.props;

    if (!payrollGroup && !isBulkExport) {
      return null;
    }

    const isLoadingEnabled = isLoading || loading;

    return (
      <div>
        <PopupTitle>{title}</PopupTitle>
        {payrollGroup && !isBulkExport && (
          <>
            <FieldWrapper fieldName={t("Payment group")} width="100%">
              <TextInputControl
                value={payrollGroup.name === "default" ? t("common|Default") : payrollGroup.name}
                disabled
                size={120}
                placeholder={t("Payment group")}
              />
            </FieldWrapper>
            <Dates>
              <FieldWrapper fieldTitleMarginBottom={0} fieldName={t("Start Period")} width="100%">
                <SingleDatePickerControl value={moment(payrollGroup.startDate, "YYYY-MM-DD")} disabled />
              </FieldWrapper>
              <Spacer />
              <FieldWrapper fieldTitleMarginBottom={0} fieldName={t("End Period")} width="100%">
                <SingleDatePickerControl value={moment(payrollGroup.endDate, "YYYY-MM-DD")} disabled />
              </FieldWrapper>
            </Dates>
          </>
        )}
        <FieldWrapper fieldName={t("Payroll Layout")} width="100%">
          <SelectControl
            options={layouts || []}
            onChange={(val: string): void => {
              this.setState({ selectedLayout: val });
            }}
            isLoading={isLoadingEnabled}
            value={selectedLayout || ""}
            isSearchable
            placeholder={t("Payroll Layout")}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Hours formats")} width="100%">
          <FieldTabs
            active={
              layouts?.length && selectedLayout
                ? layouts.filter((l) => l.value === selectedLayout)[0].layout.hoursFormat
                : "sexagesimal"
            }
            disabled
            tab1={{
              value: "sexagesimal",
              label: t("Sexagesimal (12:00)"),
            }}
            tab2={{
              value: "centesimal",
              label: t("Centessimal (12,00)"),
            }}
          />
        </FieldWrapper>
        <br />
        <br />
        <CheckboxControl
          checked={skipEmptyValues}
          label={t("Do not export empty value events")}
          onChange={(checked): void => {
            this.setState({ skipEmptyValues: checked });
          }}
        />
        {isBulkExport && (
          <>
            <br />
            {t("payroll-export-email-send-note")}
          </>
        )}
        <ButtonsRow
          loading={isLoadingEnabled}
          yesButtonDisabled={!layouts || !layouts.length || !selectedLayout}
          onCancel={this.onCancel}
          buttonCancelTitle={t("common|Cancel")}
          onConfirm={async (): Promise<void> => {
            if (!isLoadingEnabled && layouts && layouts.length && selectedLayout) {
              if (isBulkExport) {
                onYes({
                  layoutUuid: selectedLayout,
                  skipEmptyValues,
                });
                return;
              }

              if (!payrollGroup) {
                return;
              }

              if (withForceRecalculate) {
                this.setState({ loading: true });
                await this.forceRecalculatePayrollReport({
                  payrollUUID: selectedLayout,
                  from: payrollGroup.startDate,
                  to: payrollGroup.endDate,
                  groupType: "payroll_lock",
                  groupUuid: payrollGroup.uuid,
                });
              }
              setTimeout(
                () => {
                  onYes({
                    layoutUuid: selectedLayout,
                    payrollGroupUuid: payrollGroup.uuid,
                    from: payrollGroup.startDate,
                    to: payrollGroup.endDate,
                    skipEmptyValues,
                  });
                },
                withForceRecalculate ? 2000 : 0,
              ); // if doing force recalculate we need to wait a bit. If we don't wait, the report will be returned with previous state
            }
          }}
          buttonYesTitle={t("common|Confirm")}
        />
      </div>
    );
  }
}

export default withTranslation("payroll")(PayrollPopupWithLayouts);
