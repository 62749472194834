import { ChangeEvent, Component, CSSProperties } from "react";
import styled from "styled-components";
import TextAreaControl from "components/controls/TextArea/TextareaControl";

/**
 * This component is depracated. Please use {@link TextAreaControl} instead.
 * @param {*} param0
 * @returns
 * @deprecated
 */

export const Wrapper = styled.div`
  &.error textarea {
    border: 1px solid #ed413d;
  }

  &.disabled textarea {
    font-weight: var(--typography-font-weight-default);
    color: #a1b2cf;
    border: 1px solid #dde5ee;
    background-color: #f5f8fd;
    font-style: normal;
    cursor: default;
  }

  &.locked textarea {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 15'%3E%3Cpath d='M11 5.886V4.687C11 2.103 8.757 0 6 0S1 2.103 1 4.688v1.198C.405 6.21 0 6.809 0 7.5v5.625C0 14.159.897 15 2 15h8c1.103 0 2-.84 2-1.875V7.5c0-.691-.405-1.29-1-1.614zM6 1.875c1.654 0 3 1.262 3 2.813v.937H3v-.938c0-1.55 1.346-2.812 3-2.812zm1 9.01v1.303H5v-1.303a1.361 1.361 0 01-.5-1.041c0-.777.672-1.406 1.5-1.406s1.5.63 1.5 1.406c0 .415-.195.784-.5 1.041z' fill='%23D2DEEE' fill-opacity='.8'/%3E%3C/svg%3E");
    background-size: 12px 15px;
    background-repeat: no-repeat;
    background-position-y: calc(100% - 10px);
    ${(p) => (p.theme.dir === "ltr" ? "background-position-x: calc(100% - 17px);" : "background-position-x: 17px;")};
    background-color: var(--colors-default);
    padding-inline-end: 29px;
    color: var(--colors-mainText);
    border: 1px solid #a1b2cf;
    cursor: default;

    &:placeholder-shown {
      border: 1px solid #a1b2cf;
    }
  }

  textarea {
    border: 1px solid #a1b2cf;
    padding: 16px;
    width: 100%;
    font-size: 15px;
    line-height: 19px;
    border-radius: var(--shapes-border-radius-default);
    color: var(--colors-mainText);
    font-weight: var(--typography-font-weight-default);

    &:focus {
      outline: none;
      border: 1px solid var(--colors-primary);
    }

    &::placeholder {
      font-size: var(--typography-font-size-default);
      color: #bac7dc;
    }

    &:placeholder-shown {
      border: 1px solid #dde5ee;
    }
  }
`;

interface TextareaControlProps {
  disabled?: boolean;
  className?: string;
  locked?: boolean;
  error?: string;
  placeholder?: string;
  value?: string;
  onChange?: (ev: ChangeEvent<HTMLTextAreaElement>) => void;
  mask?: unknown;
  rows?: number;
  size?: string;
  style?: CSSProperties;
  name?: string;
}

class TextareaControl extends Component<TextareaControlProps> {
  getClasses({ error, disabled, locked, className }: Record<string, string | boolean | undefined>) {
    const classes = [];

    if (className) {
      classes.push(className);
    }
    if (disabled) {
      classes.push("disabled");
    }
    if (locked) {
      classes.push("locked");
    }
    if (error) {
      classes.push("error");
    }

    return classes.join(" ");
  }

  render() {
    const { disabled, className, locked, error, placeholder, value, onChange, mask, rows, size, style, name } =
      this.props;
    const inputProps = {
      disabled,
      placeholder,
      value,
      onChange,
      mask,
      rows,
      size,
      style,
      name,
    };

    return (
      <Wrapper className={this.getClasses({ disabled, locked, className, error })}>
        <textarea {...inputProps} disabled={disabled || locked} />
      </Wrapper>
    );
  }
}

export default TextareaControl;
