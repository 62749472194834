import { MouseEventHandler, PropsWithChildren, ReactElement, ReactNode } from "react";
import classnames from "classnames";
import { Oval } from "react-loader-spinner";

import "./IconButton.scss";

interface CommonUIButtonProps extends PropsWithChildren {
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon: ReactNode;
}

interface UIButtonProps extends CommonUIButtonProps {
  variant?: "primary";
}

interface UIButtonSecondaryProps extends CommonUIButtonProps {
  variant?: "secondary";
}

interface UIButtonTertiaryProps extends CommonUIButtonProps {
  variant?: "tertiary";
}

type Props = UIButtonProps | UIButtonSecondaryProps | UIButtonTertiaryProps;

const IconButton = ({
  size = "medium",
  variant = "tertiary",
  disabled = false,
  loading = false,
  className,
  onClick,
  icon,
}: Props): ReactElement => {
  const classNameCombined = classnames(
    className,
    "ui-icon-button-component",
    `ui-icon-button-component_size-${size}`,
    `ui-icon-button-component_variant-${variant}`,
    {
      "ui-icon-button-component_disabled": disabled,
      "ui-icon-button-component_loading": loading,
    },
  );

  const onClickHandler = disabled || loading ? () => {} : onClick;

  return (
    <button disabled={disabled} type="button" className={classNameCombined} onClick={onClickHandler}>
      {!loading && icon}
      <Oval visible={loading} strokeWidth="4" wrapperClass="ui-button-component_loader-wrapper" />
    </button>
  );
};

export default IconButton;
