import i18n from "i18next";
import Sentry from "utils/sentryUtils";
import Backend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend"; // primary use cache
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import axios from "axios";
import { LocalStorageKeys } from "utils/localStorageUtils";
import { AvailableLocales } from "utils/translationHelpers";
import { getCurrentTranslationVersion } from "utils/apiHelpers";
import translationConfig from "./translationConfig";

let missingKeys = [];
let postitimeout = null;
const skipCache = false;
const DEFAULT_CACHE_TTL = 10 * 60 * 1000;

let { translationVersion } = translationConfig;
const cachedTranslationVersion = localStorage.getItem("i18nTranslationVersion") || 0;
const intTranslationVersion = parseInt(translationVersion, 10);
const intCachedTranslationVersion = parseInt(cachedTranslationVersion, 10);
if (!cachedTranslationVersion || intCachedTranslationVersion < intTranslationVersion) {
  localStorage.setItem("i18nTranslationVersion", intTranslationVersion);
  translationVersion = intTranslationVersion;
} else {
  translationVersion = intCachedTranslationVersion;
}
if (!localStorage.getItem("i18nTranslationVersionCachedTime")) {
  localStorage.setItem("i18nTranslationVersionCachedTime", Date.now());
}

function getBackendOptions(version) {
  const backends = [];
  const backendOptions = [];
  if (!skipCache) {
    backends.push(LocalStorageBackend);
    backendOptions.push({
      prefix: "i18next_res_",
      expirationTime: 7 * 24 * 60 * 60 * 1000,
      versions: {
        en: `v${version}`,
        [AvailableLocales.pt]: `v${version}`,
        [AvailableLocales.es]: `v${version}`,
        [AvailableLocales.he]: `v${version}`,
        [AvailableLocales.en]: `v${version}`,
      },
      store: window.localStorage,
    });
  }
  backends.push(HttpApi);
  backendOptions.push({
    loadPath: "https://admin.oitchau.com/static/locales/{{lng}}/{{ns}}",
    crossDomain: true,
    // allowMultiLoading: true,
    // requestOptions: {
    //   mode: 'no-cors',
    // },
  });

  return {
    backends,
    backendOptions,
  };
}

export const translationVersionCheck = async () => {
  let r = translationVersion;
  try {
    r = await getCurrentTranslationVersion();
  } catch (err) {
    Sentry.sendError(err);
  }

  let currentTranslationVersion = parseInt(r, 10);

  if (currentTranslationVersion > parseInt(translationVersion, 10)) {
    localStorage.setItem("i18nTranslationVersion", currentTranslationVersion);
    localStorage.setItem("i18nTranslationVersionCachedTime", Date.now());
    translationVersion = currentTranslationVersion;
    i18n.init({
      backend: getBackendOptions(translationVersion),
    });
  } else {
    currentTranslationVersion = parseInt(translationVersion, 10);
  }
  return currentTranslationVersion;
};

const addMissingKeyToLocalStorage = (lng, ns, key) => {
  let cachedNamespace = localStorage.getItem(`i18next_res_${lng}-${ns}`);
  if (!cachedNamespace) {
    const objToInsert = {};
    objToInsert[key] = key;
    localStorage.setItem(`i18next_res_${lng}-${ns}`, JSON.stringify(objToInsert));
  } else {
    cachedNamespace = JSON.parse(cachedNamespace);
    cachedNamespace[key] = key;
    localStorage.setItem(`i18next_res_${lng}-${ns}`, JSON.stringify(cachedNamespace));
  }
};
const postMissingTranslations = (lngs, ns, key) => {
  if (!key) {
    return;
  }

  const fallBackLang = "en";
  if (missingKeys[fallBackLang]) {
    if (!missingKeys[fallBackLang][ns]) {
      missingKeys[fallBackLang][ns] = {};
    }
  } else {
    missingKeys[fallBackLang] = {};
    missingKeys[fallBackLang][ns] = {};
  }
  missingKeys[fallBackLang][ns][key] = key;

  lngs.map((language) => addMissingKeyToLocalStorage(language, ns, key));

  clearTimeout(postitimeout);
  postitimeout = setTimeout(async () => {
    const data = missingKeys?.en ? missingKeys.en : null;
    console.log("posting", data);
    missingKeys = [];
    if (data) {
      const result = await axios({
        method: "PUT",
        mode: "no-cors",
        url: "https://us-central1-oitchau-155812.cloudfunctions.net/lokalise-webhook",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
      console.log(result);
    }
  }, 10 * 1000);
};

export const InitializeI18n = async () => {
  // moved it here to lower the load on rails server
  // move it to translationVersionCheck if continuous version check is needed
  const translationVersionCachedTime = localStorage.getItem("i18nTranslationVersionCachedTime");
  if (!translationVersionCachedTime || Date.now() - parseInt(translationVersionCachedTime, 10) > DEFAULT_CACHE_TTL) {
    translationVersionCheck();
  }

  await i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        lookupQuerystring: "locale",
        lookupCookie: "locale",
        lookupLocalStorage: LocalStorageKeys.locale,
        order: ["querystring", "localStorage", "cookie", "navigator", "htmlTag", "path", "subdomain"],
      },
      fallbackLng: "en",
      appendNamespaceToCIMode: true,
      saveMissing: process.env.NODE_ENV !== "production",
      nsSeparator: "|",
      // have a common namespace used around the full app
      ns: ["common"],
      defaultNS: "common",

      debug: false, // process.env.NODE_ENV !== 'production',
      keySeparator: "### not used ###", // we use content as keys
      pluralSeparator: "@@@",
      contextSeparator: "^^^",
      saveMissingTo: "all",
      missingKeyHandler: process.env.NODE_ENV === "development" ? postMissingTranslations : null,
      backend: getBackendOptions(translationVersion),
      interpolation: {
        formatSeparator: ",",
        format(value, format, lng) {
          switch (format) {
            case "uppercase":
              return value.toUpperCase();
            case "lowercase":
              return value.toLowerCase();
            case "capitalize":
              return value.charAt(0).toUpperCase() + value.slice(1);
            default:
              return value;
          }
        },
      },
    });
};
