import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -2px;
  display: flex;
  align-items: center;
  box-shadow: 0 22px 30px 0 rgba(129, 147, 171, 0.2);
  border-radius: var(--shapes-border-radius-default);
  z-index: 10;
  &.drop {
    top: 56px;
    border-radius: 0 0 4px 4px;
    border-top: none;
    width: 622px;
    left: -1px;
  }
  .Dropdown {
    width: 100%;
    .Content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      overflow-y: auto;
      max-height: calc(640px - 56px);
      border-top: 1px solid var(--colors-surface-150);
    }
    .Footer {
      display: flex;
      padding: 8px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 6px;
      background: var(--colors-surface-50);
      position: sticky;
      bottom: 0;
      border-top: 1px solid var(--colors-surface-150);
      &.align-end {
        justify-content: flex-end;
      }
    }
  }
  .OverlayWrapper {
    display: flex;
    width: 630px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--shapes-border-radius-default);
    background: var(--colors-surface-0);
    border: 1px solid var(--colors-surface-150);
    overflow: auto;

    .Header {
      display: flex;
      height: 56px;
      padding: 0 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      flex-shrink: 0;

      .Icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .Title {
        flex: 1 0 0;
        color: var(--colors-surface-800);
        font-size: 16px;
      }

      .Search {
        .SearchIcon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input {
          flex: 1 0 0;
          color: var(--colors-surface-900-p);
          border: none;

          &::placeholder {
            color: var(--colors-surface-700);
          }
        }
      }
    }
  }
`;

export const OverlayDropdown = ({
  content,
  footer,
  header,
  className = "",
}: {
  content?: JSX.Element;
  footer?: JSX.Element | null;
  header?: JSX.Element;
  className?: string;
}) => (
  <Overlay className={`Overlay ${!header ? "drop" : ""}`}>
    <div className={`OverlayWrapper ${className}`}>
      {header}
      <div className="Dropdown">
        <div className="Content">{content}</div>
        {footer}
      </div>
    </div>
  </Overlay>
);
