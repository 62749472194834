import { ChangeEvent, Component, CSSProperties, HTMLInputTypeAttribute } from "react";
import styled from "styled-components";

const StyledInput = styled.input<{ hasError: boolean }>`
  width: 100%;
  padding: 0 15px;
  vertical-align: middle;
  line-height: 34px;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--shapes-border-radius-default);
  font-size: 15px;
  color: ${(props) => props.theme.colors.mainText};
  ${(props) =>
    props.hasError
      ? `border-color: ${props.theme.colors.error};`
      : `border-color: ${props.theme.colors.inputBorderDefault};`}
  ${(props) => (props.value ? `border-color: ${props.theme.colors.secondary};` : null)}


  &:focus {
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.primary};
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.inputMaskText};
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
`;

interface TextInputControlProps {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  disabled?: boolean;
  size?: number;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  style?: CSSProperties;
  onBlur?: () => void;
}

class TextInputControl extends Component<TextInputControlProps> {
  static defaultProps = {
    style: {},
    onChange: () => {},
  };

  onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(ev.target.value);
  };

  render() {
    const { value, error, disabled, size, type, placeholder, style, onBlur } = this.props;

    return (
      <StyledDiv>
        <StyledInput
          hasError={!!error}
          disabled={disabled}
          type={type || "text"}
          maxLength={size}
          size={size}
          onChange={this.onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          style={{ ...style }}
          value={value}
        />
      </StyledDiv>
    );
  }
}

export default TextInputControl;
