import moment from "moment-timezone";
import { AppStorage } from "services/app-storage";
import { Injectable } from "services/di";
import { LocationValidation } from "types/models/location";
import { Memoize, clear } from "typescript-memoize";
import { OitchauAuthedApi } from "services/oitchau-api";
import { PunchType, Punch } from "types/models/punches";
import { Shift, ScheduleType } from "types/models/shift";
import { ExpectedEvent, getEventClosestByTime, getExpectedEvents, getBestEvent } from "./punch-service-utils";

@Injectable()
export class PunchService {
  constructor(protected oitchauApi: OitchauAuthedApi, protected appStorage: AppStorage) {}

  async getEmployeeProfile() {
    const rawProfile = await this.appStorage.getProfile();
    const profile = { name: rawProfile.full_name.split(" ")[0] };
    return profile;
  }

  // --- verification
  async getCurrentTimeUtc(): Promise<Date> {
    const res = await this.oitchauApi.getCurrentTime();
    return new Date(res);
  }

  @Memoize({ expiring: 1e3 * 20, tags: ["punch-now-service"] })
  async verifyTime(time: Date): Promise<boolean> {
    const serverTime = await this.getCurrentTimeUtc();
    const diff = moment(serverTime).diff(moment(time), "minutes");
    if (Math.abs(diff) > 1) {
      return false;
    }
    return true;
  }

  async verifyLocation(location: Location): Promise<boolean> {
    const compLocation = location._raw;
    if (compLocation.verification_methods.indexOf(LocationValidation.ipAddress) > -1) {
      const isIpValid = await this.oitchauApi.verifyLocation({ locationId: compLocation.id });
      return isIpValid;
    }
    return true;
  }

  // --- get locations
  protected async getLocationsCopypasted() {
    const [profileRes, locationsRes, companyRes, lastUsedLocationId] = await Promise.all([
      this.oitchauApi.getProfile(),
      this.oitchauApi.getLocations(),
      this.oitchauApi.getCompany(),
      this.appStorage.getLastUsedLocation(),
    ]);

    const profileLocations = profileRes.user_profile.locations;
    const defaultCompanyLocation = companyRes.default_location;
    const allLocations = locationsRes.locations || [];
    const activeLocations = allLocations.filter((loc) => loc.active);
    const isAllowedAnyLocation =
      !profileLocations || profileLocations.length === 0 || profileLocations.filter((loc) => loc.active).length === 0;
    const availableLocations = isAllowedAnyLocation
      ? activeLocations
      : profileLocations
          .map((ploc) => activeLocations.find((aloc) => aloc.uuid === ploc.uuid))
          .filter((ploc) => ploc != null);
    const defaultLocation =
      availableLocations.find((loc) => loc.id === lastUsedLocationId) ||
      availableLocations.find((loc) => loc.uuid === defaultCompanyLocation.uuid) ||
      availableLocations[0];
    return { defaultLocation, locations: availableLocations };
  }

  protected mapLocation(loc) {
    const location = {
      _raw: loc,
      address: loc.formatted_address,
      id: loc.id,
      name: loc.name,
      timezone: loc.time_zone,
      uuid: loc.uuid,
      code: loc.code,
    };
    return location;
  }

  async getLocations() {
    const { locations: locs, defaultLocation: defaultLoc } = await this.getLocationsCopypasted();
    const locations = locs.map((loc) => this.mapLocation(loc));
    const defaultLocation = this.mapLocation(defaultLoc);
    return { defaultLocation, locations };
  }

  // --- get punch types
  /**
   * Get shifts for today and yesterday (because may span over today)
   */
  async getShifts(YYYYMMDD?: string) {
    const profile = (await this.appStorage.getProfile())!;
    const m = YYYYMMDD != null ? moment(YYYYMMDD, "YYYY-MM-DD") : moment().startOf("day");
    const resShifts = await this.oitchauApi.getShifts({
      dateFrom: m.clone().subtract(1, "day").format("YYYY-MM-DD"),
      dateTo: m.clone().format("YYYY-MM-DD"),
      employeeUuid: profile.uuid,
    });
    return (resShifts?.content || []) as Shift[];
  }

  checkIfTimeInsideSchedule(shifts: Shift[], time: Date) {
    for (const shift of shifts) {
      const shouldCheck = shift.scheduleType !== ScheduleType.flexible;
      if (!shouldCheck) continue;

      const min = moment(shift.date, "YYYY-MM-DD").add(shift.minStartTime, "minutes");
      const max = moment(shift.date, "YYYY-MM-DD").add(shift.maxEndTime, "minutes");
      const isInsideSchedule = moment(time).isBetween(min, max, undefined, "[)");
      if (isInsideSchedule) return true;
    }
    return false;
  }

  protected async getShiftCompilations(YYYYMMDD: string) {
    const profile = (await this.appStorage.getProfile())!;
    const m = moment(YYYYMMDD, "YYYY-MM-DD");
    const dates = [m.clone().subtract(1, "day").format("YYYY-MM-DD"), m.clone().format("YYYY-MM-DD")];
    const promises = dates.map((date) => this.oitchauApi.getShiftCompilation({ date, userProfileId: profile.id }));
    const ress = await Promise.all(promises);
    const shiftComps = ress.filter((res) => res?.shift_compilation != null).map((res) => res.shift_compilation);
    return shiftComps;
  }

  async getPunches(YYYYMMDD?: string) {
    const profile = (await this.appStorage.getProfile())!;
    const m = YYYYMMDD != null ? moment(YYYYMMDD, "YYYY-MM-DD") : moment().startOf("day");
    const punchesParams = {
      employeeId: profile.id,
      from: m.clone().subtract(1, "day").format("YYYY-MM-DD"),
      to: m.clone().add(1, "day").format("YYYY-MM-DD"),
    };
    const res = await this.oitchauApi.getPunches(punchesParams);
    return res?.punches || [];
  }

  checkIfPunchedIn(punches: Punch[]) {
    let latestPunch = null;
    for (const punch of punches) {
      const isLater = latestPunch == null || latestPunch.device_datetime <= punch.device_datetime;
      if (isLater) {
        latestPunch = punch;
      }
    }
    if (latestPunch == null) return false;
    const isPunchedIn = latestPunch.punch_type === PunchType.entry || latestPunch.punch_type === PunchType.breakEnd;
    return isPunchedIn;
  }

  protected getDefaultEvents(now: Date, timezone: string) {
    const shiftId = undefined;
    const shiftDate = moment(now).tz(timezone).format("YYYY-MM-DD");
    const getDate = (HHmm: string) => moment.tz(`${shiftDate} ${HHmm}`, "YYYY-MM-DD HH:mm", timezone).toDate();
    const events: ExpectedEvent[] = [
      { expectedDate: getDate("09:00"), key: "1", shiftDate, shiftId, type: PunchType.entry },
      { expectedDate: getDate("13:00"), key: "2", shiftDate, shiftId, type: PunchType.breakStart },
      { expectedDate: getDate("14:00"), key: "3", shiftDate, shiftId, type: PunchType.breakEnd },
      { expectedDate: getDate("17:00"), key: "4", shiftDate, shiftId, type: PunchType.exit },
    ];
    return events;
  }

  async getPunchTypes(
    date: Date,
    timezone: string,
  ): Promise<{ punchTypes: PunchTypeObject[]; assumedPunchType: PunchTypeObject }> {
    const shiftDate = moment(date).tz(timezone).format("YYYY-MM-DD");
    const promises = [this.getPunches(shiftDate), this.getShifts(shiftDate), this.getShiftCompilations(shiftDate)];
    const [punches, shifts, shiftCompilations] = await Promise.all(promises);
    const expectedEvents = getExpectedEvents(timezone, punches, shifts, shiftCompilations);
    const todayEvents = expectedEvents.filter(
      (evt) => evt.shiftDate === shiftDate || moment(evt.expectedDate).tz(timezone).isSame(date, "day"),
    );
    const eventsToChoose = todayEvents.length !== 0 ? todayEvents : this.getDefaultEvents(date, timezone);
    const punchTypes = eventsToChoose.map((evt) => ({
      _raw: evt,
      id: `${evt.shiftId}_${evt.key}`,
      type: evt.type,
    }));
    const bestEvent =
      expectedEvents.length !== 0
        ? getBestEvent(date, timezone, expectedEvents, shifts)
        : getEventClosestByTime(date, eventsToChoose);

    let assumedPunchType = punchTypes[0];
    let dropdownPunches = todayEvents.length !== 0 ? punchTypes : [];

    if (bestEvent) {
      // check if event is from yersterdays shift
      if (bestEvent.shiftDate !== shiftDate) {
        // get all events from yestedays shift
        const shiftsDayEvents = expectedEvents.filter(
          (evt) => evt.shiftDate === bestEvent.shiftDate || moment(evt.expectedDate).tz(timezone).isSame(date, "day"),
        );

        const dropdownEvents = shiftsDayEvents.length !== 0 ? shiftsDayEvents : this.getDefaultEvents(date, timezone);

        dropdownPunches =
          shiftsDayEvents.length !== 0
            ? dropdownEvents.map((evt) => ({
                _raw: evt,
                id: `${evt.shiftId}_${evt.key}`,
                type: evt.type,
              }))
            : [];
      }

      // find best event in dropdown events by shiftId. If not found, crete new event based on bestEvent
      assumedPunchType = dropdownPunches.find((pt) => pt.id === `${bestEvent.shiftId}_${bestEvent.key}`) || {
        _raw: bestEvent,
        id: `${bestEvent.shiftId}_${bestEvent.key}`,
        type: bestEvent.type,
      };
    }

    return {
      assumedPunchType,
      punchTypes: dropdownPunches,
    };
  }

  // --- punch
  async createPunch(options: CreatePunchOptions): Promise<CreatePunchResult> {
    const { punchType, isVerified, date, location } = options;
    const selectedEvent: ExpectedEvent = punchType._raw;
    const companyLocation = location._raw;

    const dateStr = date.toISOString();
    if (!dateStr) throw Error("Unable to get location time");

    const data: AddPunchRequestData = {
      punch: {
        device_datetime: dateStr,
        is_manual: isVerified ? "" : "not verified",
        location_id: companyLocation.id,
        punch_type: selectedEvent.type,
        time_zone: companyLocation.time_zone,
      },
      shift_compilation: !selectedEvent.shiftId
        ? undefined
        : {
            date: moment.tz(dateStr, companyLocation.time_zone).clone().format("YYYY-MM-DD"),
            key: selectedEvent.key,
            shift_id: selectedEvent.shiftId,
          },
    };
    const addPunchResponse = await this.oitchauApi.createPunch(data);
    await this.appStorage.setLastUsedLocation(companyLocation.id);
    return { ...options, uuid: addPunchResponse.punch_uuids[0] };
  }

  async updatePunchType(punch: CreatePunchResult, punchType: PunchTypeObject): Promise<void> {
    const profile = await this.appStorage.getProfile();
    const operations = [
      {
        date: moment(punch.date).format("YYYY-MM-DD"),
        shift_events: [
          {
            key: punchType._raw.key,
            uuid: punch.uuid,
          },
        ],
        user_profile_uuid: profile.uuid,
      },
    ];
    await this.oitchauApi.updateShiftCompilation({
      companyUuid: profile.company.uuid,
      employeeUuid: profile.uuid,
      operations,
    });
  }

  clearCache() {
    clear(["punch-now-service"]);
  }
}
