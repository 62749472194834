import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: ${(p) => (p.width ? p.width : "100%")};
`;
const Fields = styled.div`
  width: 100%;
`;
const Label = styled.label<{ $spacerBottom: boolean }>`
  color: #a1b2cf;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  ${(p) => (p.$spacerBottom ? "margin-bottom: 16px;" : "")}
`;

interface FieldsGroupProps {
  label?: string;
  children: ReactNode;
  width?: string;
  spacerBottom?: boolean;
  style?: CSSProperties;
}

export default ({ label, children, width, spacerBottom = false, style }: FieldsGroupProps) => (
  <Wrapper className="fields-group" width={width} style={style}>
    {label && <Label $spacerBottom={spacerBottom}>{label}</Label>}
    <Fields className="fields">{children}</Fields>
  </Wrapper>
);
