import { ChangeEvent, Component, ContextType } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import CheckboxControl from "components/UI/NewCheckbox";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import Select from "components/UI/FormSelect";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import ButtonAddRemoveSmall from "components/controls/ButtonAddRemoveSmall";
import GlobalContext from "context/global-context";
import { ErrorLabel } from "components/UI/TextLabels";
import "styles/table-common.scss";
import { RequestApprovalFlowApproverType, RequestSubTypeApprovalFlow } from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";

const ApprovalLevelWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;

  .error {
    padding-inline-start: 54px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    .show-on-parent-hover {
      display: flex;
    }
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: flex-end;

  .ui-select-form__input {
    display: block;
    padding-inline-end: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CounterStyles = `
  position: absolute;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  line-height: 14px;
  font-size: 12px;
  background: var(--colors-surface-900-p);
  border-radius: 50%;
  font-weight: var(--typography-font-weight-bold);
  color: var(--colors-default);
`;

const AvatarIcon = styled.div<{ inactive: boolean; order: number }>`
  width: 38px;
  height: 38px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 38px 38px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 38 38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z' fill='%23DDE5EE'/%3E%3Cg fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M15 15c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4-4-1.794-4-4z' fill='%238093AC'/%3E%3Cpath d='M19 13c-1.103 0-2 .896-2 2 0 1.103.897 2 2 2s2-.897 2-2c0-1.104-.897-2-2-2z' fill='%23DDE5EE'/%3E%3Cpath d='M27 27H11v-1c0-3.533 3.29-6 8-6 4.711 0 8 2.467 8 6v1z' fill='%238093AC'/%3E%3Cpath d='M13.159 25h11.683c-.6-1.808-2.834-3-5.842-3-3.008 0-5.243 1.192-5.841 3z' fill='%23DDE5EE'/%3E%3C/g%3E%3C/svg%3E");
  margin-inline-end: 16px;

  &:before {
    inset-inline-end: -8px;
  }

  ${(p) =>
    !p.inactive
      ? `
      &:before{
        content: "${p.order}";
        ${CounterStyles}
      }
    `
      : "opacity: 0.2"}
`;

const RemoveButtonWrapper = styled.div`
  align-self: flex-end;
  margin-inline-start: 10px;
  margin-bottom: 8px;
  min-width: 20px;
  min-height: 20px;
`;

const PersonSelector = styled.div`
  margin-inline-start: 8px;
`;

const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 54px;
  padding-top: 8px;
`;

const LimitWrapper = styled.div`
  margin-inline-start: 8px;
  display: flex;
`;

interface RequestSettingsApprovalLevelProps extends WithTranslation {
  approvalLevel: RequestSubTypeApprovalFlow;
  onChange: (approvalFlow: RequestSubTypeApprovalFlow) => void;
  errors: Record<string, string> | null;
}

interface RequestSettingsApprovalLevelState {
  loaded: boolean;
  levelTypes: { value: string; label: string }[];
}

class RequestSettingsApprovalLevel extends Component<
  RequestSettingsApprovalLevelProps,
  RequestSettingsApprovalLevelState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<RequestSettingsApprovalLevelState> = { levelTypes: [], loaded: false };

  async componentDidMount() {
    const { t, approvalLevel } = this.props;
    const roles = await this.context.getRoles();
    let levelTypes = [];

    if (approvalLevel && approvalLevel.stage > 0) {
      levelTypes.push({ value: "", label: t("Select") });
    }

    levelTypes.push({ value: RequestApprovalFlowApproverType.userProfile, label: t("userProfile") });

    const supervisorRole = roles.find((r) => r.name === PermissionRoleName.supervisor);
    if (supervisorRole) {
      // add All supervisors and three different levels
      levelTypes = levelTypes.concat(
        [...Array(4)].map((_, i) => ({
          value: i === 0 ? supervisorRole.uuid : `supervisor${i}`,
          label:
            i === 0
              ? t("All supervisors within hierarchy")
              : t(`${supervisorRole.name} level {{supervisorLevel}}`, { supervisorLevel: i }),
        })),
      );
    }

    levelTypes = levelTypes.concat(
      roles
        .filter((r) => r.name !== PermissionRoleName.supervisor)
        .map((role) => ({
          value: role.uuid,
          label: role.predefined ? t(role.name) : role.name,
        })),
    );

    this.setState({ levelTypes, loaded: true });
  }

  onApprovalChange = ({
    item,
    key,
    value,
  }: {
    item: RequestSubTypeApprovalFlow;
    key: keyof RequestSubTypeApprovalFlow;
    value: any;
  }) => {
    const { onChange } = this.props;
    const newItem = JSON.parse(JSON.stringify(item));

    newItem[key] = value;

    onChange(newItem);
  };

  getApprovalLeveStr = (stage: number) => {
    let level = "";
    switch (stage) {
      case 0:
        level = "First";
        break;
      case 1:
        level = "Second";
        break;
      case 2:
        level = "Third";
        break;

      default:
        break;
    }

    return `${level} level`;
  };

  // value could be permission role uuid | "" | "userProfile" | "supervisor1" | "supervisor2" etc.
  getLevelTypeValue = () => {
    const { approvalLevel } = this.props;

    if (approvalLevel.approver.type === RequestApprovalFlowApproverType.role) {
      if (approvalLevel.approver.options?.level) {
        return `supervisor${approvalLevel.approver.options.level}`;
      }

      return approvalLevel.approver.uuid || "";
    }

    return approvalLevel.approver.type;
  };

  render() {
    const { t, approvalLevel, errors } = this.props;
    const { levelTypes, loaded } = this.state;

    if (!loaded) {
      return null;
    }

    return (
      <ApprovalLevelWrapper>
        <TopRow>
          <AvatarIcon inactive={!approvalLevel.approver.uuid} order={approvalLevel.stage + 1} />
          <FieldWrapper
            fieldName={t(this.getApprovalLeveStr(approvalLevel.stage))}
            width="240px"
            fieldTitleMarginTop={0}
            fieldTitleMarginBottom={8}
          >
            <Select<string>
              modifiers={{ field: true }}
              value={this.getLevelTypeValue()}
              // val could be permission role uuid | "" | "userProfile" | "supervisor1" | "supervisor2" etc.
              onChange={(val) => {
                const value: Record<string, any> = { type: val, uuid: "" };

                if (val !== "" && val !== RequestApprovalFlowApproverType.userProfile) {
                  value.type = RequestApprovalFlowApproverType.role;

                  // user choose specific supervisor level
                  if (val.includes("supervisor")) {
                    // find supervisor role uuid
                    value.uuid =
                      levelTypes.find((lt) => lt.label === t("All supervisors within hierarchy"))?.value || "";
                    // add supervisor level to options
                    value.options = {
                      level: +val[val.length - 1],
                    };
                  } else {
                    value.uuid = val;
                  }
                }

                this.onApprovalChange({
                  item: approvalLevel,
                  key: "approver",
                  value,
                });
              }}
              options={levelTypes}
            />
          </FieldWrapper>

          {approvalLevel.approver.type === RequestApprovalFlowApproverType.userProfile && (
            <PersonSelector>
              <SearchControl
                permissionSection={PermissionSectionName.requests}
                value={approvalLevel.approver.label}
                onClear={() =>
                  this.onApprovalChange({
                    item: approvalLevel,
                    key: "approver",
                    value: { type: RequestApprovalFlowApproverType.userProfile, uuid: "" },
                  })
                }
                onChange={(value: SearchControlOnChangeData) =>
                  this.onApprovalChange({
                    item: approvalLevel,
                    key: "approver",
                    value: {
                      type: RequestApprovalFlowApproverType.userProfile,
                      uuid: value.uuid,
                      label: value.label,
                    },
                  })
                }
                placeholder={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-search-employees",
                  `${TranslationNamespaces.common}|Search Employees`,
                )}
              />
            </PersonSelector>
          )}

          {!approvalLevel.autoApproval && (
            <div>
              {approvalLevel.limit !== null && (
                <LimitWrapper>
                  <FieldWrapper
                    fieldName={t("Monthly limit")}
                    width="110px"
                    fieldTitleMarginTop={0}
                    fieldTitleMarginBottom={8}
                  >
                    <TextInputControl
                      placeholder={t("limit")}
                      value={approvalLevel.limit || ""}
                      error={errors?.[`limits${approvalLevel.stage}`]}
                      onChange={(val: ChangeEvent<HTMLInputElement>) => {
                        this.onApprovalChange({
                          item: approvalLevel,
                          key: "limit",
                          value: val.target.value,
                        });
                      }}
                    />
                  </FieldWrapper>
                  <RemoveButtonWrapper>
                    <ButtonAddRemoveSmall
                      remove
                      showOnParentHover
                      onClick={() => {
                        this.onApprovalChange({
                          item: approvalLevel,
                          key: "limit",
                          value: null,
                        });
                      }}
                    />
                  </RemoveButtonWrapper>
                </LimitWrapper>
              )}
            </div>
          )}
        </TopRow>
        <CheckboxRow>
          <CheckboxControl
            checked={approvalLevel.autoApproval}
            disabled={!approvalLevel.approver.uuid}
            label={t("Auto Approval")}
            onChange={(checked) => {
              this.onApprovalChange({
                item: approvalLevel,
                key: "autoApproval",
                value: checked,
              });
            }}
          />
        </CheckboxRow>
        {errors && errors[`limits${approvalLevel.stage}`] && (
          <ErrorLabel className="error">{errors && errors[`limits${approvalLevel.stage}`]}</ErrorLabel>
        )}
        {errors && errors[`approver${approvalLevel.stage}`] && (
          <ErrorLabel className="error">{errors && errors[`approver${approvalLevel.stage}`]}</ErrorLabel>
        )}
      </ApprovalLevelWrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp])(RequestSettingsApprovalLevel);
