import { ChangeEvent, FocusEvent } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CheckboxControl from "components/UI/NewCheckbox";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import RichTooltip from "components/UI/RichTooltip";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RequestComplianceResetRule } from "types/models/request";

const Spacer = styled.div`
  width: 16px;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
`;

interface HowManyVacationDaysProps {
  days?: number;
  resetRule?: RequestComplianceResetRule;
  adjustFirstPeriodProportionally?: boolean;
  onChange: (data: {
    resetRule?: RequestComplianceResetRule;
    days?: number;
    adjustFirstPeriodProportionally?: boolean;
  }) => void;
}

const HowManyVacationDays = ({
  onChange,
  days,
  resetRule,
  adjustFirstPeriodProportionally,
}: HowManyVacationDaysProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <>
      <div style={{ margin: "8px 0" }}>
        <FieldWrapper width="100%" fieldTitleMarginTop={0} fieldTitleMarginBottom={8}>
          <FieldRow>
            <TextInputControl
              placeholder=""
              value={days || 0}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                let val: number | undefined;

                if (ev.target.value) {
                  val = parseInt(ev.target.value.replace(/[^0-9]*/g, ""), 10);

                  if (val > 365) {
                    val = 365;
                  } else if (val < 1) {
                    val = 1;
                  }
                }

                onChange({ days: val, resetRule, adjustFirstPeriodProportionally });
              }}
            />
            <Spacer />
            <RichTooltip text={t("how-many-vacation-days-tooltip")} />
          </FieldRow>
        </FieldWrapper>
        <FieldWrapper width="100%" fieldTitleMarginTop={16} fieldTitleMarginBottom={8}>
          <CheckboxControl
            checked={resetRule === RequestComplianceResetRule.calendarYear}
            label={t("Should reset on a calendar year")}
            onChange={(checked) => {
              onChange({
                resetRule: checked ? RequestComplianceResetRule.calendarYear : RequestComplianceResetRule.startDate,
                days,
                adjustFirstPeriodProportionally: checked ? adjustFirstPeriodProportionally : false,
              });
            }}
          />
        </FieldWrapper>
        <FieldWrapper width="100%" fieldTitleMarginTop={16} fieldTitleMarginBottom={8}>
          <CheckboxControl
            checked={adjustFirstPeriodProportionally}
            disabled={resetRule === RequestComplianceResetRule.startDate}
            label={t("Should adjust proportionally")}
            onChange={(checked) => {
              onChange({ days, resetRule, adjustFirstPeriodProportionally: checked });
            }}
          />
        </FieldWrapper>
      </div>
    </>
  );
};

export default HowManyVacationDays;
