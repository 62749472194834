import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import c from "classnames";
import styled from "styled-components";
import { useMemo } from "react";
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 345px;
  background: var(--colors-default);
`;
const Header = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  align-self: center;
  height: 90px;
  font-size: 23.6994px;
  font-weight: var(--typography-font-weight-default);
  line-height: 30px;
  letter-spacing: -0.03em;
`;
const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  overflow: overlay;
  font-size: 19px;
  font-weight: var(--typography-font-weight-default);
  line-height: 24px;
`;
const Option = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 59.25px;
  padding: 0 28px;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f4f7fb;
  }

  &.selected {
    color: #1e97f7;
  }
`;

const OptionDate = styled.div`
  position: absolute;
  inset-inline-end: 10px;
  z-index: 1;
  color: var(--colors-surface-700, #707d9e);
  font-size: 16px;
  font-weight: 450;
`;

export interface PunchTypeOption {
  id: string;
  type: string;
}

export interface PunchTypeSelectorProps {
  options: PunchTypeOption[];
  selectedOption: PunchTypeOption;
  onOptionSelected: (option: PunchTypeOption) => void;
}

/**
 * Map item to empty string if item occurs only once.
 * Otherwise map item to its number among same items.
 * Example: ['a', 'b', 'a', 'c', 'b'] => [' 1', ' 1', ' 2', '', ' 2']
 */
function mapToNumericSuffixes(items: (string | number)[]) {
  const numOptsMap: Record<string, number> = {};
  for (const str of items) {
    numOptsMap[str] = (numOptsMap[str] || 0) + 1;
  }

  const numOptsMapRunning: Record<string, number> = {};
  const suffs = [];
  for (const opt of items) {
    numOptsMapRunning[opt] = (numOptsMapRunning[opt] || 0) + 1;
    const num = numOptsMapRunning[opt];
    const suff = numOptsMap[opt] > 1 ? ` ${num}` : "";
    suffs.push(suff);
  }
  return suffs;
}

export default function PunchTypeSelector({ options, selectedOption, onOptionSelected }: PunchTypeSelectorProps) {
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const suffs = useMemo(() => mapToNumericSuffixes(options.map((opt) => opt.type)), [options]);

  return (
    <Wrapper>
      <Header>{t("Are you coming or going?")}</Header>
      <Options>
        {options?.map((opt, i) => {
          const name = t(`punch-type:${opt.type}`);
          return (
            <Option
              role="option"
              className={c({ selected: opt.id === selectedOption.id })}
              key={opt.id}
              onClick={() => onOptionSelected(opt)}
            >
              {`${name}${suffs[i]}`}
              <OptionDate>{moment(opt._raw.shiftDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</OptionDate>
            </Option>
          );
        })}
      </Options>
    </Wrapper>
  );
}
