import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { normalizeString } from "components/views/Select";
import { useMemo, useState, ChangeEventHandler } from "react";
import { searchClose } from "components/svg-images";
import cx from "classnames";
import { stylesheet } from "astroturf";
import { SearchInput } from "components/views/SearchInput";
import { filterNested } from "components/views/Select/filter-nested";
import { IconSelectedTick } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--colors-default);

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    flex-shrink: 0;
    padding: 20px 16px;
    border-bottom: 1px solid var(--colors-separator);

    .Search {
      height: auto;
      padding: 0;
      background: none;
    }

    .IconClose {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .Options {
    overflow-y: auto;
    background: var(--colors-default);
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: var(--colors-default);

  .NothingFound {
    .Top {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 23px 16px;

      .Title {
        font-size: var(--typography-font-size-default);
        line-height: 130%;
        color: var(--colors-surface-800);
      }

      .Subtitle {
        font-size: 12px;
        line-height: 120%;
        color: var(--colors-surface-700);
      }
    }
  }

  .Project {
    padding: 0;

    .Title {
      cursor: default;
      background: var(--colors-default);
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 11.5px 16px;
      color: var(--colors-activitiInfoBlockValue);
      height: 56px;
      align-items: center;

      &:hover {
        background: var(--colors-bgHover);
      }

      .ProjTexts {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        display: flex;
        flex-direction: row;
        white-space: pre-wrap;

        .Name {
          color: var(--colors-surface-900-p);
        }

        .Client {
          color: var(--colors-surface-500);
        }
      }
    }


    &.selected {
      .Title {
        .ProjTexts {
          .Name {
            color: var(--colors-primary-600);
          }
        }
      }
    }

    &.tmp:not(.selected) {
      .Title {
        .ProjTexts {
          .Name {
            color: var(--colors-surface-900-p);
          }
        }
      }
    }
  }
}
`;

interface Project {
  uuid: string;
  name: string;
  client?: { name: string };
}
function ProjectGroup(props: { project: Project; selected: boolean } & React.HTMLProps<HTMLDivElement>) {
  const { project, selected, ...restProps } = props;

  return (
    <div className={cx(styles.Project, { [styles.selected]: selected })} {...restProps}>
      <div className={cx(styles.Title)}>
        <div className={styles.ProjTexts}>
          <div className={styles.Name}>{project.name}</div>
          {project.client?.name != null && <div className={styles.Client}>{` / ${project.client.name}`}</div>}
        </div>
        {selected && <IconSelectedTick />}
      </div>
    </div>
  );
}

function NothingFound() {
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <div className={styles.NothingFound}>
      <div className={styles.Top}>
        <div className={styles.Title}>{t("Nothing found")}</div>
        <div className={styles.Subtitle}>{t("Try a different keyword")}</div>
      </div>
    </div>
  );
}

export interface ActivityProjectSelectorProps {
  projects: Project[];
  selectedProjectUuid?: string;
  onChange(project: Project): void;
  onClose(): void;
}
export function ActivityProjectSelector(props: ActivityProjectSelectorProps) {
  const { projects, selectedProjectUuid, onChange, onClose } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const options = useMemo(() => {
    const opts = projects.map((proj) => {
      const fullName = proj?.client?.name ? `${proj.name} / ${proj.client.name}` : proj.name;
      const searchTerms = [normalizeString(fullName)];
      const opt = {
        searchTerms,
        project: proj,
      };
      return opt;
    });
    return opts;
  }, [projects]);

  const [searchQuery, setSearchQuery] = useState("");

  const search: ChangeEventHandler<HTMLInputElement> = (event) => {
    const searchText = event.target.value;
    setSearchQuery(searchText);
  };

  const foundOptions = useMemo(() => {
    const str = normalizeString(searchQuery);
    if (str === "") return options;
    const predicate = (opt) => opt.searchTerms?.some((term: string) => term.includes(str));
    const foundOpts = filterNested(options, predicate, "taskOptions");
    return foundOpts;
  }, [options, searchQuery]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <SearchInput className={styles.Search} placeholder={t("Search projects")} autoFocus onChange={search} />
        {searchQuery === "" && onClose && (
          <div className={styles.IconClose} onClick={onClose}>
            {searchClose}
          </div>
        )}
      </div>

      <div className={styles.Options}>
        {searchQuery === "" && foundOptions.length === 0 && <div className={styles.Empty}>{t("No items")}</div>}
        {searchQuery !== "" && foundOptions.length === 0 && <NothingFound />}
        {foundOptions.map((prjOpt) => (
          <ProjectGroup
            key={prjOpt.project.uuid}
            project={prjOpt.project}
            selected={prjOpt.project.uuid === selectedProjectUuid}
            onClick={() => onChange(prjOpt.project)}
          />
        ))}
      </div>
    </div>
  );
}
