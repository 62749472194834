import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import Select from "components/UI/FormSelect";
import FieldWrapper from "components/UI/FieldWrapper";
import {
  HoursBankExpiredPhasesPositiveAction,
  HoursBankExpiredPhasesNegativeAction,
} from "types/models/businessRulesGroup";
import { Subtitle } from "./styled";

const Wrapper = styled.div``;
const Fields = styled.div`
  display: flex;
  gap: 16px;
`;

type HBDischargeProps = {
  locked: boolean;
  disabled: boolean;
  hoursBankExpiredPhasesPositiveAction: HoursBankExpiredPhasesPositiveAction;
  hoursBankExpiredPhasesNegativeAction: HoursBankExpiredPhasesNegativeAction;
  onPositiveActionChange: (value: HoursBankExpiredPhasesPositiveAction) => void;
  onNegativeActionChange: (value: HoursBankExpiredPhasesNegativeAction) => void;
};

const HBDischarge = ({
  locked,
  disabled,
  hoursBankExpiredPhasesPositiveAction,
  hoursBankExpiredPhasesNegativeAction,
  onPositiveActionChange,
  onNegativeActionChange,
}: HBDischargeProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  const positiveOptions = [
    {
      label: t(HoursBankExpiredPhasesPositiveAction.keepInExpiredPhases),
      value: HoursBankExpiredPhasesPositiveAction.keepInExpiredPhases,
    },
    {
      label: t(HoursBankExpiredPhasesPositiveAction.convertToExtraHours),
      value: HoursBankExpiredPhasesPositiveAction.convertToExtraHours,
    },
    {
      label: t(HoursBankExpiredPhasesPositiveAction.convertOriginalHoursToExtraHours),
      value: HoursBankExpiredPhasesPositiveAction.convertOriginalHoursToExtraHours,
    },
  ];
  const negativeOptions = [
    {
      label: t(HoursBankExpiredPhasesNegativeAction.keepInExpiredPhases),
      value: HoursBankExpiredPhasesNegativeAction.keepInExpiredPhases,
    },
    {
      label: t(HoursBankExpiredPhasesNegativeAction.convertToMissingHours),
      value: HoursBankExpiredPhasesNegativeAction.convertToMissingHours,
    },
  ];

  return (
    <Wrapper>
      <Subtitle>{t("Hours Bank Discharge")}</Subtitle>
      <Fields>
        <FieldWrapper fieldName={t("Positive Hours")} width="240px" tooltipText={t("discharge-positive-tooltip")}>
          <Select
            locked={locked}
            disabled={disabled}
            options={positiveOptions}
            value={hoursBankExpiredPhasesPositiveAction}
            onChange={onPositiveActionChange}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Negative Hours")} width="240px" tooltipText={t("discharge-negative-tooltip")}>
          <Select
            locked={locked}
            disabled={disabled}
            options={negativeOptions}
            value={hoursBankExpiredPhasesNegativeAction}
            onChange={onNegativeActionChange}
          />
        </FieldWrapper>
      </Fields>
    </Wrapper>
  );
};

export default HBDischarge;
