import React, { Component } from "react";
import { Formik, Field } from "formik";
import { withTranslation } from "react-i18next";

import { getRepModels } from "utils/apiHelpers";
import BEM from "utils/BEM";
import "./Model.scss";

const b = BEM.b("rep-details-model");

const mapInitialValues = (fields, values = {}) => {
  const initialValues = {};
  fields.forEach((f) => {
    initialValues[f.name] = values[f.name] || "";
  });
  return initialValues;
};

class PegadorRepFormModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
      selectedModelId: props.rep ? props.rep.modelId : "",
      preservedValues: props.rep || {},
    };
  }
  componentDidMount() {
    getRepModels()
      .then((res) => {
        res.items &&
          res.items.length &&
          this.setState(
            {
              models: res.items,
              selectedModelId: this.state.selectedModelId || res.items[0].id,
            },
            () => setTimeout(() => this.onChange()),
          );
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
  onChange(values = {}) {
    this.props.onChange({
      modelId: this.state.selectedModelId,
      ...values,
    });
  }
  render() {
    const { t } = this.props;
    const { models, selectedModelId, preservedValues } = this.state;
    const selectedModel = models.find((model) => +model.id === +selectedModelId) || {};

    const configFields = selectedModel.configFields || [];
    const initialValues = mapInitialValues(configFields, preservedValues);

    if (models.length === 0) {
      return null;
    }
    return (
      <div>
        <select
          className={b("select")}
          value={this.state.selectedModelId}
          onChange={(e) => {
            this.setState({ selectedModelId: e.target.value }, () => this.onChange());
          }}
        >
          {this.state.models.map((m) => (
            <option key={m.id} value={m.id}>
              {m.model}
            </option>
          ))}
        </select>
        {this.props.hostPortWrapper}
        <Formik
          key={this.state.selectedModelId}
          initialValues={initialValues}
          validate={(values) => {
            this.setState({
              preservedValues: { ...preservedValues, ...values },
            });
            this.onChange(values);
          }}
          render={(props) =>
            configFields.map((field) => (
              <div key={field.name}>
                <label className={b("field-wrapper")}>
                  <span className={b("field-label")}>{t(field.name)}</span>
                  <Field
                    name={field.name}
                    autoComplete="new-password"
                    type={field.type === "password" ? "password" : "text"}
                    component={field.type === "text" ? "textarea" : "input"}
                    className={b("field-input")}
                  />
                </label>
              </div>
            ))
          }
        />
      </div>
    );
  }
}

export default withTranslation("reps")(PegadorRepFormModel);
