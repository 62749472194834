import styled from "styled-components";
import { minsToHrsMins } from "utils/common";

export const ActivityRow = styled.div`
  height: 45px;
  display: flex;
  width: 100%;
  border-top: 1px solid var(--colors-surface-150);
  &.highlighted {
    background-color: var(--colors-warning-50);
  }
`;

const Cell = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding-inline-start: 10px;
  &.common-cell.grayed-out {
    color: var(--colors-surface-400);
  }
`;

export default ({
  dates,
  data,
  isDayPlannedArray,
  loading,
  highlighted,
  label,
}: {
  dates: string[];
  data: number[];
  isDayPlannedArray: boolean[];
  loading: boolean;
  highlighted?: boolean;
  label: string;
}) => {
  const classNames = [];
  if (loading) {
    classNames.push("loading");
  }
  if (highlighted) {
    classNames.push("highlighted");
  }

  return (
    <ActivityRow className={classNames.join(" ")}>
      <Cell className="task-cell common-text">{label}</Cell>
      {data.map((value, i) => (
        <Cell
          key={dates[i]}
          className={`common-cell common-text unasigned-cell ${isDayPlannedArray[i] === false ? "grayed-out" : ""}`}
        >
          {minsToHrsMins(value)}
        </Cell>
      ))}
      <Cell className="total-cell common-text" style={{ paddingInlineEnd: "13px" }}>
        {minsToHrsMins(data.reduce((partialSum, a) => partialSum + a, 0))}
      </Cell>
    </ActivityRow>
  );
};
