import { Component, ContextType, PropsWithChildren, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import { getSystemName } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "./FullPage";

interface SettingsBillingLayoutProps extends WithTranslation, PropsWithChildren {
  headerAction?: JSX.Element | null;
  title?: ReactNode;
}

class SettingsBillingLayout extends Component<SettingsBillingLayoutProps> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: SettingsBillingLayoutProps) {
    super(props);

    const { t } = this.props;
    document.title = `${getSystemName()} - ${t("Admin control panel")}`;
  }

  async componentDidMount() {
    const res = await this.context.getCompany();

    if (res) {
      window.global_store.company = res;
    }
  }

  render() {
    const { children, title, headerAction } = this.props;

    return (
      <FullPage title={title} headerAction={headerAction}>
        {children}
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.fullPage)(SettingsBillingLayout);
