import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { getSystemName } from "utils/common";
import styled from "styled-components";
import ownerPermissionPageImg from "./img/ownerPermissionPage.png";

const OwnerPermissionPageWrapper = styled.div`
  width: 100%;
  max-width: 479px;
  font-size: var(--typography-font-size-default);
  color: #525f7f;
  padding-inline-start: 37px;
`;

const OwnerPermissionPageImg = styled.img``;

const OwnerPermissionPageTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 20px;
  color: var(--colors-mainText);
  margin-top: 17px;
  margin-bottom: 15px;
`;
const OwnerPermissionPageP = styled.p``;

const OwnerPermissionPageUl = styled.ul`
  padding-inline-start: 0;
`;

const OwnerPermissionPageLi = styled.li`
  list-style-type: none;
  &:before {
    content: "-";
    margin-inline-end: 3px;
  }
`;

const OwnerPermissionsPage = () => {
  const { t } = useTranslation(TranslationNamespaces.employeesPermissions);

  return (
    <OwnerPermissionPageWrapper>
      <OwnerPermissionPageImg alt={t("With great power comes great resposibilities")} src={ownerPermissionPageImg} />
      <OwnerPermissionPageTitle>{t("With great power comes great resposibilities")}</OwnerPermissionPageTitle>
      <OwnerPermissionPageP>
        {t("Owners are almighty rulers who can see and do everything in {{systemName}}", {
          systemName: getSystemName(),
        })}
      </OwnerPermissionPageP>
      <OwnerPermissionPageP>{t("The most important things Owners can do are:")}</OwnerPermissionPageP>
      <OwnerPermissionPageUl>
        <OwnerPermissionPageLi>{t("Export data")}</OwnerPermissionPageLi>
        <OwnerPermissionPageLi>{t("Revoke other admins rights")}</OwnerPermissionPageLi>
        <OwnerPermissionPageLi>{t("Add and Delete users")}</OwnerPermissionPageLi>
        <OwnerPermissionPageLi>{t("Manage Billing")}</OwnerPermissionPageLi>
      </OwnerPermissionPageUl>
      <OwnerPermissionPageP>
        {t(
          "We advise to use admin permission set with caution and assign people to it only when explicitly necessary.",
        )}
      </OwnerPermissionPageP>
    </OwnerPermissionPageWrapper>
  );
};

export default OwnerPermissionsPage;
