import { useTranslation } from "react-i18next";
import { stylesheet } from "astroturf";
import { ReactComponent as IconArrowRight } from "components/Employees/img/arrowRight.svg";
import { useParams } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import { ReactComponent as IconSuccessBig } from "../icons/success_img_ic.svg";

const styles = stylesheet`
  .TopBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .SuccessIcon {
      width: 80px;
      height: 80px;
      margin: 64px 0 32px;
    }
    .Title {
      font-size: 30px;
      color: var(--colors-surface-900-p);
      margin-bottom: 16px;
    }
    .SubTitle {
      color: var(--colors-surface-700);
      font-size: 20px;
      margin-bottom: 32px;
    }
    .BtnIcon {
      margin-inline-start: 13px;
      path {
        fill: white;
      }
    }
  }
`;

const PaymentSuccessPage = () => {
  const {t} = useTranslation(TranslationNamespaces.subscription);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const {type, interval} = useParams();
  const pageTextConfig: Record<string, {title: string, description: string}> = {
    "payment:month": {
      title: "Payment successful",
      description: "Your account has been upgraded to Professional Monthly plan."
    },
    "payment:year": {
      title: "Payment successful",
      description: "Your account has been upgraded to Professional Annual plan."
    },
    "upgrade:month": {
      title: "Upgrade successful",
      description: "Your account has been upgraded."
    },
    "upgrade:year": {
      title: "Payment successful",
      description: "Your account has been upgraded to Professional Annual plan."
    },
    base: {
      title: "Action successful",
      description: ""
    }
  };
  // eslint-disable-next-line no-prototype-builtins
  const config = pageTextConfig.hasOwnProperty(`${type}:${interval}`) ? pageTextConfig[`${type}:${interval}`] : pageTextConfig.base;

  return (
    <div className={styles.TopBlock}>
      <IconSuccessBig className={styles.SuccessIcon} />
      <div className={styles.Title}>{t(config.title)}</div>
      <div className={styles.SubTitle}>{t(config.description)}</div>
      <Button value={<>{t("Go to Main page")}<IconArrowRight className={styles.BtnIcon}/></>} style={{ width: "200px" }} state={ButtonState.primary} url="/" />
    </div>
  );
}

export default PaymentSuccessPage;
