import SafeInput, { Selection, SafeInputProps } from "../SafeInput";

function validateCompleteValue(val: string) {
  if (!val) return false;
  const num = Number.parseFloat(val);
  if (Number.isNaN(num)) return false;
  const re = /^[-]?[0-9]+[.]?[0-9]*$/;
  return val.match(re) !== null;
}

function validateInput(val: string) {
  const re = /^[+-]?[0-9]*[.]?[0-9]*$/;
  return val.match(re) !== null;
}

export function prepareIntGtzInput(val: string) {
  let v = val;
  v = v.replace(/[, ]/g, ".");
  v = v.replace(/[^0-9]/g, "");
  const firstDotIndex = v.indexOf(".");
  if (firstDotIndex !== -1) {
    // because lookbehind is not supported by safari
    const [bef, aft] = [v.slice(0, firstDotIndex + 1), v.slice(firstDotIndex + 1)];
    v = `${bef}${aft.replace(/\./g, "")}`;
  }
  const re = /^[+]?[0-9]*$/;
  const isValid = v.match(re) !== null;
  if (!isValid) return null;
  return v;
}

function prepareInput(val: string) {
  let v = val;
  v = v.replace(/[, ]/g, ".");
  v = v.replace(/[^0-9.+-]/g, "");
  const firstDotIndex = v.indexOf(".");
  if (firstDotIndex !== -1) {
    // because lookbehind is not supported by safari
    const [bef, aft] = [v.slice(0, firstDotIndex + 1), v.slice(firstDotIndex + 1)];
    v = `${bef}${aft.replace(/\./g, "")}`;
  }
  const isValid = validateInput(v);
  if (!isValid) return null;
  return v;
}

function completeInput(value: string) {
  let val = value;
  const sign = val[0] === "-" ? "-" : "";
  val = val.replace(/^[+-]/, "");
  const [whole, decimal] = val.split(".");
  const wStr = whole || "0";
  const dStr = decimal ? `.${decimal}` : "";
  val = `${sign}${wStr}${dStr}`;

  // TODO: do something about too big/too precise numbers to show user that precision will be lost.
  const isValid = validateCompleteValue(val);
  if (!isValid) {
    val = "0";
  }
  return val;
}

function smartInput(val: string, sel: Selection, key: string) {
  const [ss, se] = sel[0] < sel[1] ? sel : [sel[1], sel[0]];
  if (ss !== se) return null;
  const separators = " .,";
  if (["", "+", "-"].includes(val) && ss === val.length) {
    if (separators.includes(key)) {
      return { value: `${val}0.`, cursor: val.length + 2 };
    }
  }
  return null;
}

export type SafeTimeInputProps = Omit<SafeInputProps, "smartInput" | "prepareInput" | "completeInput">;

export default function SafeNumericInput(props: SafeTimeInputProps) {
  return (
    <SafeInput
      placeholder="0"
      smartInput={smartInput}
      prepareInput={prepareInput}
      completeInput={completeInput}
      {...props}
    />
  );
}
