import FieldsGroup from "components/UI/FieldsGroup";
import { useTranslation } from "react-i18next";
import { RequestSubTypeApprovalFlow } from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";
import RequestSettingsApprovalLevel from "./RequestSettingsApprovalLevel";

interface RequestSettingsApprovalFlowProps {
  errors: Record<string, string> | null;
  approvalFlow: RequestSubTypeApprovalFlow[];
  onChange: (val: RequestSubTypeApprovalFlow[]) => void;
}

const RequestSettingsApprovalFlow = ({ onChange, approvalFlow, errors }: RequestSettingsApprovalFlowProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <FieldsGroup label={t("Approval Flow")} width="100%" spacerBottom>
      {approvalFlow.map((level, i) => (
        <RequestSettingsApprovalLevel
          key={level.stage}
          approvalLevel={level}
          errors={errors}
          onChange={(item) => {
            const newItem = item;
            const items = JSON.parse(JSON.stringify(approvalFlow));

            if (item.autoApproval) {
              newItem.limit = null;
            }

            items[i] = newItem;
            onChange(items);
          }}
        />
      ))}
    </FieldsGroup>
  );
};

export default RequestSettingsApprovalFlow;
