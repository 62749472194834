import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";

import FullPage from "components/Layout/FullPage";
import InviteEmployeesFullPage from "components/InviteEmployeesFullPage";

const RouteInviteEmployees = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <FullPage>
      <Switch>
        <Redirect strict from={`${path}/`} to={`${path}`} />
        <Route strict path={path} component={InviteEmployeesFullPage} />
      </Switch>
    </FullPage>
  </Suspense>
);

export default RouteInviteEmployees;
