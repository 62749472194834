import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ADPLogo, googleIcon } from "components/svg-images";
import CONFIG from "config";
import { handleGoogleLogin } from "utils/ssoApiUtils";
import { useGoogleLogin } from "@react-oauth/google";
import cookie from "react-cookies";
import { stylesheet } from "astroturf";
import ga, { GaLoginMethod } from "utils/ga";
import { BlockNotification, BlockNotificationType } from "../UI/BlockNotification";

const styles = stylesheet`
.GoogleBtn {
  min-width: 160px;
}
.ErrorModal {
  .Title {
    font-size: 20px;
    margin-bottom: 12px;
    color: var(--colors-surface-900-p);
  }
  .Description {
    font-size: 15px;
    color: var(--colors-surface-700);
    margin-bottom: 32px;
  }
  .BtnBlock {
    display: flex;
    justify-content: end;
    .CtaBtn {
      width: 120px;
    }
  }
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;

const Divider = styled.div`
  margin: 24px 0 34px;
  height: 11px;
  text-align: center;
  border-bottom: 1px solid var(--colors-surface-200);

  span {
    padding: 0 16px;
    background: #fff;
    color: var(--colors-surface-900-p);
  }
`;

const SecondaryButtons = styled.div`
  display: flex;
  gap: 16px;

  .styled-button {
    color: var(--colors-surface-900-p);
  }
`;

interface LoginButtonsProps {
  primaryButtonText: string;
  onPrimaryButtonClick: (ev: MouseEvent) => void;
  isLogin?: boolean;
}

const GoogleLoginBtn = (props: { setErrors: (err: string | null) => void }) => {
  const history = useHistory();
  const [user, setUser] = useState<{ access_token: string }>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && loading) {
      handleGoogleLogin(user.access_token)
        .then((res) => {
          if (res.token) {
            // set auth token
            localStorage.setItem("oi_auth", res.token);
            ga.trackLogin(GaLoginMethod.email);
            window.location.href = "/";
          } else if (res.email) {
            cookie.save("userEmail", res.email, { path: "/" });
            res.userToken && cookie.save("userToken", res.userToken, { path: "/" });
            history.push(`/account-type?email=${res.email}`);
          }
        })
        .catch((e) => {
          props.setErrors(e.response?.data?.message || e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, loading]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      props.setErrors(null);
      setLoading(true);
      setUser(codeResponse);
    },
    onError: (error) => {
      props.setErrors(error.error_description!);
      setLoading(false);
    },
  });

  return (
    <>
      <div className={styles.GoogleBtn}>
        <Button
          disabled={loading}
          onboarding
          value={googleIcon}
          state={ButtonState.outline}
          onClick={(e) => {
            e.preventDefault();
            login();
          }}
        />
      </div>
    </>
  );
};

const LoginButtons = ({ primaryButtonText, onPrimaryButtonClick, isLogin }: LoginButtonsProps) => {
  const { t } = useTranslation(TranslationNamespaces.signup);
  const history = useHistory();
  const [errors, setErrors] = useState<string | null>();

  return (
    <Wrapper>
      <Button onboarding value={primaryButtonText} onClick={onPrimaryButtonClick} />

      <Divider>
        <span>{isLogin ? t("Continue with") : t("Or")}</span>
      </Divider>

      {!isLogin && (
        <Button
          onboarding
          style={{ textTransform: "none", fontSize: 16, color: "var(--colors-surface-900-p)" }}
          value={t("Continue with regular login")}
          state={ButtonState.outline}
          onClick={(e) => {
            setErrors(null);
            e.preventDefault();
            history.push("/login");
          }}
        />
      )}

      {isLogin && (
        <SecondaryButtons>
          {CONFIG.globalMode ? <GoogleLoginBtn setErrors={setErrors} /> : null}
          <Button
            onboarding
            value={ADPLogo}
            state={ButtonState.outline}
            onClick={(e) => {
              setErrors(null);
              e.preventDefault();
              let location =
                "https://accounts.adp.com/auth/oauth/v2/authorize?client_id=0a435689-795d-49df-b260-023eb03f598e&scope=openid%20profile&response_type=code&redirect_uri=https%3A%2F%2Fstg-api.oitchau.com.br%2Fv2%2Fpublic%2Fsso%2Flogin%2Faccounts.adp.com%2Fcallback&resource=authorizationEndpoint";

              if (CONFIG.appEnv === "production") {
                location =
                  "https://accounts.adp.com/auth/oauth/v2/authorize?client_id=d76058da-58bf-4e17-af73-f9111c31169e&scope=openid%20profile&response_type=code&redirect_uri=https%3A%2F%2Fapi.day.io%2Fv2%2Fpublic%2Fsso%2Flogin%2Faccounts.adp.com%2Fcallback&resource=authorizationEndpoint";
              }

              window.location = location as unknown as Location;
            }}
          />
          <Button
            onboarding
            value={t("SSO")}
            state={ButtonState.outline}
            onClick={(e) => {
              setErrors(null);
              e.preventDefault();

              history.push("/sso-login");
            }}
          />
        </SecondaryButtons>
      )}
      {errors ? (
        <BlockNotification
          styles={{ marginTop: "20px", width: "100%" }}
          type={BlockNotificationType.Error}
          title={t(`login_page_error_title-${errors}`)}
          description={t(`login_page_error_description-${errors}`)}
        />
      ) : null}
    </Wrapper>
  );
};

export default LoginButtons;
