import { PayedOvertimePhase, PhaseType } from "types/models/businessRulesGroup";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getPhasesDays } from "utils/common";
import i18next from "i18next";

export const CROSS_SHIFTS_PHASE_UNLIMITED_NAME = "unlimited";

export const getCrossShiftsPhaseName = (phaseName: string): string =>
  phaseName === CROSS_SHIFTS_PHASE_UNLIMITED_NAME
    ? i18next.t(`${TranslationNamespaces.companyRules}|cross-shifts-phase-unlimited-name`)
    : phaseName;

export const getPhaseName = (phase: PayedOvertimePhase) => {
  let phaseName = "";

  if (phase.customName) {
    phaseName = phase.customName;
  } else {
    const { daysMasksObj } = getPhasesDays({
      t: i18next.t,
      isDayTypeBasedOnSchedule: !!phase.dayTypeBasedOnSchedule,
    });

    phaseName = `${daysMasksObj[(phase.dayTypeBasedOnSchedule || phase.daysMask) as string]} ${phase.name}%`;
    if (phase.type !== PhaseType.combined) {
      phaseName += ` (${i18next.t(`${TranslationNamespaces.phases}|${phase.type}`)})`;
    }
  }

  return phaseName;
};
