import styled from "styled-components";
import Lottie, { LottieComponentProps } from "lottie-react";
import { stylesheet } from "astroturf";
import cx from "classnames";
import { searchClose } from "components/svg-images";
import { ReactComponent as IconSpinnerBig } from "../icons/spinner-gradient-big.svg";
import { ReactComponent as IconSpinnerSmall } from "../icons/spinner-rounded-small.svg";
import animationDataConfetti from "../icons/animation-confetti.lottie.json";
import { ReactComponent as IconArrowDown } from "../icons/arrow-v-down.svg";
import { ReactComponent as IconArrowDownFilled } from "../icons/arrow-down-filled.svg";

export { ReactComponent as IconArrowDown } from "../icons/arrow-v-down.svg";
export { ReactComponent as IconErrorBig } from "../icons/cloud-error.svg";
export { ReactComponent as IconSuccessBig } from "../icons/circle-check-green.svg";
export { ReactComponent as IconExclamationBig } from "../icons/circle-exclamation-orange.svg";
export { ReactComponent as IconLocation } from "../icons/pin-on-map.svg";
export { ReactComponent as IconDayioLogo } from "../icons/dayio-logo-hand.svg";
export { ReactComponent as IconDayioLogoWhite } from "../icons/dayio-logo-hand-white.svg";
export { ReactComponent as IconOptions } from "../icons/gear-settings.svg";
export { ReactComponent as IconStartActivity } from "../icons/play.svg";
export { ReactComponent as IconRepeatActivity } from "../icons/play-repeat.svg";
export { ReactComponent as IconStopActivity } from "../icons/stop.svg";
export { ReactComponent as IconAddActivity } from "../icons/plus.svg";
export { ReactComponent as IconWarning } from "../icons/triangle-exclamation.svg";
export { ReactComponent as IconRecycleBin } from "../icons/recycle-bin.svg";
export { ReactComponent as IconCalendar } from "../icons/calendar.svg";
export { ReactComponent as IconTaskFlag } from "../icons/flag.svg";
export { ReactComponent as IconNoActivities } from "../icons/box-empty-fly.svg";
export { ReactComponent as IconFieldError } from "../icons/circle-exclamation-small.svg";
export { ReactComponent as IconListEnd } from "../icons/arrow-up-underlined.svg";
export { ReactComponent as IconClose } from "../icons/cross-pointy.svg";
export { ReactComponent as IconCloseDialog } from "../icons/cross-rounded.svg";
export { ReactComponent as IconClearInput } from "../icons/cross-circled.svg";
export { ReactComponent as IconSelectedTick } from "../icons/tick-selected.svg";
export { ReactComponent as IconExternalLink } from "../icons/square-arrow-outside.svg";
export { ReactComponent as IconDialogFields } from "../icons/lines-horizontal-text.svg";
export { ReactComponent as IconStopwatch } from "../icons/stopwatch.svg";
export { ReactComponent as IconDialogRunning } from "../icons/play-triangle-small.svg";
export { ReactComponent as IconDialogStopped } from "../icons/play-triangle-small-pale.svg";
export { ReactComponent as IconDialogStoppedWarn } from "../icons/play-triangle-small-orange.svg";
export { ReactComponent as IconDialogDiscard } from "../icons/circle-exclamation-gray-small.svg";
export { ReactComponent as IconDialogDelete } from "../icons/triangle-exclamation-red.svg";
export { ReactComponent as IconAddTask } from "../icons/plus-rounded.svg";
export { ReactComponent as IconPencil } from "../icons/pencil.svg";
export { ReactComponent as IconClock } from "../icons/clock.svg";
export { ReactComponent as IconArrowUpRight } from "../icons/arrow-up-right.svg";
export { ReactComponent as IconCoin } from "../icons/coin-dollar.svg";
export function IconSearchClose() {
  return <>{searchClose}</>;
}

const styles = stylesheet`
.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: default;
  background-color: var(--colors-surface-0);
}

@use 'styles/scrollbar';

.MaclikeScrollbars {
  @extend .scrollbar;
}
`;

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

export function Wrapper({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  return (
    <div className={cx(styles.Wrapper, { [styles.MaclikeScrollbars]: !isMac }, className)} {...props}>
      {children}
    </div>
  );
}

const iconDroprightStyles = stylesheet`
.IconDropright {
  fill: var(--colors-unknown39);
  transform: rotate(-90deg) scale(1.2);
}

[dir="rtl"] {
  .IconDropright {
    transform: rotate(90deg) scale(1.2);
  }
}
`;
export const IconDropright = ({ className, ...props }) => (
  <IconArrowDown className={cx(iconDroprightStyles.IconDropright, className)} {...props} />
);

const iconDroprightFilledStyles = stylesheet`
.IconDropright {
  transform: rotate(-90deg);
}

[dir="rtl"] {
  .IconDropright {
    transform: rotate(90deg);
  }
}
`;
export const IconDroprightFilled = ({ className, ...props }) => (
  <IconArrowDownFilled className={cx(iconDroprightFilledStyles.IconDropright, className)} {...props} />
);

export const iconBackStyles = stylesheet`
.IconBack {
  fill: #455170;
  transform: rotate(90deg) scale(1.2);
}

[dir="rtl"] {
  .IconBack {
    transform: rotate(-90deg) scale(1.2);
  }
}
`;
export const IconBack = ({ className, ...props }) => (
  <IconArrowDown className={cx(iconBackStyles.IconBack, className)} {...props} />
);

const Rotate = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  display: flex;
  align-items: center;
  align-self: center;
  animation: rotating 2.5s infinite linear;
`;

export const SpinnerSmall = () => (
  <Rotate>
    <IconSpinnerSmall />
  </Rotate>
);

export const SpinnerBig = () => (
  <Rotate>
    <IconSpinnerBig />
  </Rotate>
);

export const AnimationConfetti = (props: Partial<LottieComponentProps>) => {
  const options: LottieComponentProps = {
    loop: false,
    autoplay: true,
    animationData: animationDataConfetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie {...options} {...props} />;
};
