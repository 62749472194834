import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  BusinessRuleCrossShiftsPhase,
  BusinessRuleGroup,
  BusinessRuleGroupStatus,
  BusinessRuleType,
} from "types/models/businessRulesGroup";
import { NotificationType } from "types/common";
import Button, { ButtonState } from "components/controls/StyledButton";
import { updateBRGroupSettings } from "utils/apiHelpers";
import RichTooltip from "components/UI/RichTooltip";
import { v4 as uuidv4 } from "uuid";
import TextInputControl from "components/controls/TextInputControlNew";
import { ErrorLabel } from "components/UI/TextLabels";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import PhaseLimitInput from "./PhasesTable/PhaseLimitInput";
import { ButtonAddLimit } from "./PhasesTable/buttons";
import { getCrossShiftsPhaseName } from "./helpers";

const Wrapper = styled.div``;

const NameCell = styled.div`
  width: 240px;
  flex: none;
  margin-inline-end: 16px;
`;

const LimitCell = styled.div`
  width: 128px;
  flex: none;
  margin-inline-end: 16px;

  .time-control {
    margin-top: 0;
  }
`;

const TableWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 56px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-600);
  padding-bottom: 17px;
  border-bottom: 1px solid var(--Surface---colors-surface-150, #dde5ee);
`;

const TableRows = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 24px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

const IntervalLabelValue = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IntervalLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-600);
`;

const IntervalValue = styled.div`
  background: var(--colors-surface-100, #ecf2fa);
  height: 36px;
  padding-inline: 12px;
  padding-inline-end: 26px;
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-700);
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

interface PunchingRulesGroupCrossShiftProps {
  onSave: (data: { notification: string; notificationType: NotificationType }) => void;
  group: BusinessRuleGroup;
}

type ErrorType = Record<string, Record<string, string>> | null;

const PunchingRulesGroupCrossShift = ({ onSave, group }: PunchingRulesGroupCrossShiftProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const groupDisabled = group.status !== BusinessRuleGroupStatus.active;
  const groupLocked = group.isLocked;

  const { phases: crossShiftsPhases } = group?.businessRules?.[0]?.rules?.crossShiftsInterval;
  const [errors, setErrors] = useState<ErrorType>({});
  const [phases, setPhases] = useState<BusinessRuleCrossShiftsPhase[]>(crossShiftsPhases);

  const [isLoading, setIsloading] = useState(false);
  const [confirmationPopupVisible, setConfirmationVisible] = useState(false);

  const onValidate = () => {
    let err: ErrorType = {};

    phases.forEach((phase) => {
      const phaseErrors: Record<string, string> = {};

      if (!phase.name) {
        phaseErrors.name = t("Name is required");
      } else {
        // name should be unique
        const isNameUnique = phases.filter((p) => p.uuid !== phase.uuid).every((p) => p.name !== phase.name);
        if (!isNameUnique) {
          phaseErrors.name = t("Name should be unique");
        }
      }

      if (phase.limit === 0) {
        phaseErrors.limit = t("Limit is required");
      }

      if (Object.keys(phaseErrors).length !== 0) {
        err = { ...err, [phase.uuid]: phaseErrors };
      }
    });

    if (Object.keys(err).length === 0) {
      setErrors(null);
      setConfirmationVisible(true);
    } else {
      setErrors(err);
    }
  };

  const onSubmit = async () => {
    setIsloading(true);

    try {
      await updateBRGroupSettings({
        companyUuid: window.global_store.company.uuid,
        businessRulesType: BusinessRuleType.crossShiftsInterval,
        brGroupUuid: (group as BusinessRuleGroup).uuid,
        body: {
          content: {
            interval: 660,
            phases,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });

      onSave({ notification: t("Group has been updated!"), notificationType: NotificationType.success });
    } catch (e) {
      let notification = (e as Error)?.message || String(e);

      if (e?.originalRequest?.errors[0]?.message) {
        notification += `: ${e.originalRequest.errors[0].message}`;
      }

      onSave({ notification, notificationType: NotificationType.error });
    } finally {
      setIsloading(false);
      setConfirmationVisible(false);
    }
  };

  return (
    <Wrapper>
      <IntervalLabelValue>
        <IntervalLabel>
          {t("Cross Shift Interval")} <RichTooltip text={t("cross-shift-interval-tooltip")} />{" "}
        </IntervalLabel>
        <IntervalValue>{t("cross-shift-interval-value", { value: "11:00" })}</IntervalValue>
      </IntervalLabelValue>
      <TableWrapper>
        <HeaderRow>
          <NameCell>{t("Name")}</NameCell>
          <LimitCell>{t("Limit")}</LimitCell>
        </HeaderRow>
        <TableRows>
          {phases.map((phase) => (
            <TableRow key={phase.uuid}>
              <NameCell>
                <TextInputControl
                  disabled={groupLocked || groupDisabled}
                  locked={groupLocked}
                  placeholder={t("Name")}
                  error={!!errors?.[phase.uuid]?.name}
                  value={getCrossShiftsPhaseName(phase.name)}
                  onChange={(evt) => {
                    setPhases(phases.map((p) => (p.uuid === phase.uuid ? { ...p, name: evt.target.value || "" } : p)));
                  }}
                />
                {!!errors?.[phase.uuid]?.name && <ErrorLabel>{errors?.[phase.uuid]?.name}</ErrorLabel>}
              </NameCell>
              <LimitCell>
                {phase.limit > -1 && (
                  <>
                    <PhaseLimitInput
                      disabled={groupDisabled}
                      locked={groupLocked}
                      error={!!errors?.[phase.uuid]?.limit}
                      onChange={(val) => {
                        setPhases(phases.map((p) => (p.uuid === phase.uuid ? { ...p, limit: val || 0 } : p)));
                      }}
                      onDeleteClick={() => {
                        setPhases(phases.filter((p) => p.uuid !== phase.uuid));
                      }}
                      value={phase.limit}
                    />
                    {!!errors?.[phase.uuid]?.limit && <ErrorLabel>{errors?.[phase.uuid]?.limit}</ErrorLabel>}
                  </>
                )}
                {!groupDisabled && !groupLocked && phase.limit === -1 && phases.length < 3 && (
                  <ButtonAddLimit
                    onClick={() => {
                      setPhases([
                        ...phases.map((p) => (p.uuid === phase.uuid ? { ...p, limit: 0 } : p)),
                        {
                          name: "",
                          uuid: uuidv4(),
                          limit: -1,
                        },
                      ]);
                    }}
                  >
                    {t("Add Limit")}
                  </ButtonAddLimit>
                )}
              </LimitCell>
            </TableRow>
          ))}
        </TableRows>
      </TableWrapper>
      <ModalDialog isOpen={confirmationPopupVisible} onClose={() => setConfirmationVisible(false)}>
        <Lightbox
          showLoading={isLoading}
          title={t("cross-shifts-confirmation-title")}
          text={t("cross-shifts-confirmation-description")}
          buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onClose={() => {
            setConfirmationVisible(false);
          }}
          onYes={onSubmit}
        />
      </ModalDialog>
      {!groupDisabled && !group.isLocked && (
        <Button
          style={{ width: 155 }}
          state={ButtonState.primary}
          loading={isLoading}
          onClick={onValidate}
          value={t("Save")}
        />
      )}
    </Wrapper>
  );
};

export default PunchingRulesGroupCrossShift;
