import { ActivityEdit } from "components/PunchNow/views/ActivityEdit";
import { services } from "components/PunchNow/services";
import { useEffect, useState } from "react";
import { ProjRes, TaskRes } from "services/oitchau-api/types";
import styled from "styled-components";

const ActivityEditContainer = styled.div`
  width: 350px;
  min-height: 410px;
  border-radius: var(--shapes-border-radius-default);
  box-shadow: 0 10px 30px rgb(129 147 171 / 20%);
`;

const groupTasksByProject = (tasks: TaskRes[]): ProjRes[] => {
  const dict: Record<string, ProjRes> = {};
  for (const task of tasks) {
    if (!dict[task.project.uuid]) {
      dict[task.project.uuid] = { ...task.project, tasks: [] };
    }
    dict[task.project.uuid].tasks.push(task);
  }
  const projs = Object.values(dict);
  return projs;
};

export default ({ activity, onDeleteActivity, onUpdateActivity, onBackClick, userProfile, tasksList }) => {
  const [{ projects, locations, loading }, setState] = useState<{
    projects: ProjRes[];
    locations: [];
    loading: boolean;
  }>({
    loading: true,
    locations: [],
    projects: [],
  });

  useEffect(() => {
    const projectsWithTasks = groupTasksByProject(tasksList);
    const userLocations = userProfile.locations;

    setState({
      loading: false,
      projects: projectsWithTasks,
      locations: userLocations,
    });
  }, [userProfile.uuid]);

  if (loading) {
    return null;
  }

  return (
    <ActivityEditContainer>
      {!loading ? (
        <ActivityEdit
          activity={activity}
          projects={projects}
          locations={locations}
          onBackClick={onBackClick}
          onDeleteClick={onDeleteActivity}
          onSaveClick={onUpdateActivity}
          projectsEventTracking={services.projectsEventTracking}
          // onDateChange={onDateChange}
        />
      ) : (
        <div>Loading...</div>
      )}
    </ActivityEditContainer>
  );
};
