import styled from "styled-components";
import * as images from "../svg-images";

export const COMPANY_SIZE = [
  '1 - 5',
  '6 - 10',
  '11 - 50',
  '51 - 100',
  '101 - 250',
  '251 - 500',
  '501 - 1000',
  '1000+'
];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const SizeSelector = styled.div`
  border: 2px solid var(--colors-surface-150);
  display: flex;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  color: var(--colors-surface-900-p);
  font-size: 16px;
  height: 48px;
  padding: 0 16px 0 12px;
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--colors-surface-150);
    margin-inline-end: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10px;
      height: 10px;
      path {
        fill: white;
      }
    }
  }
  &:hover {
    border-color: var(--colors-surface-200);
  }
  &.active {
    border-color: var(--colors-primary-500-p);
    .icon {
      background: var(--colors-primary-500-p);
      border-color: var(--colors-primary-500-p);
    }
  }
`;

interface EmployeesCountControlProps {
  onChange: (value: string) => void;
  value: string;
}

const EmployeesCountControl = (props: EmployeesCountControlProps) => {
  const { onChange, value } = props;

  return (
    <Wrapper>
      { COMPANY_SIZE.map((v) => (
        <SizeSelector key={v} className={value === v ? "active" : ""} onClick={() => onChange(v)}>
          <div className="icon">{images.check}</div>
          <span>{v}</span>
        </SizeSelector>
      ))}
    </Wrapper>
  );
};

export default EmployeesCountControl;
