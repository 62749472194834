import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "components/UI/Select";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import CheckboxControl from "components/UI/NewCheckbox";
import DateTimeRow from "components/UI/DateTimeRow";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import styled from "styled-components";
import { ErrorLabel } from "components/UI/TextLabels";
import Button from "components/controls/StyledButton";
import FieldTabs from "components/controls/FieldTabs";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import { createHoliday } from "./holidayApiUtils";

const Wrapper = styled.div`
  padding-bottom: 50px;
`;
const CheckboxWrapper = styled.div`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 15px;
`;
const ButtonsWrapper = styled.div`
  margin-top: 35px;
  width: 160px;
`;
const RepeatAnnuallyText = styled.div`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: #a1b2cf;
  margin-inline-start: 33px;
  margin-top: 2px;
`;
class HolidayCreatePopup extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const compensationOptions = [
      {
        label: t("Compensated"),
        value: "compensated",
      },
    ];
    const levelOptions = [
      {
        label: t("common|Country"),
        value: "country",
      },
      {
        label: t("common|State"),
        value: "state",
      },
      {
        label: t("common|City"),
        value: "city",
      },
    ];
    this.state = {
      allDay: false,
      errors: null,
      type: "holiday",
      name: "",
      compensation: compensationOptions[0].value,
      compensationOptions,
      startDate: moment(),
      endDate: moment(),
      repeatAnnually: false,
      levelOptions,
      level: levelOptions[0].value,
    };
  }

  closePopup() {
    this.props.onClose();
  }

  onCancel() {
    this.closePopup();
  }

  getFormErrors() {
    const { t } = this.props;

    const { startTime, endTime, allDay, startDate, endDate, name, type } = this.state;
    let errors = {};

    if (!name.trim()) {
      errors.name = t("common|Name can't be blank");
    }
    if (!startDate) {
      errors.startDate = t("common|Start date can't be blank");
    }
    if (type === "event" && !endDate) {
      errors.endDate = t("common|End date can't be blank");
    }
    if (type === "event" && !allDay) {
      if (!startTime) {
        errors.startTime = t("common|Start time can't be empty");
      }
      if (!endTime) {
        errors.endTime = t("common|End time can't be empty");
      }
      if (startDate && endDate && startTime && endTime) {
        const sDate = moment(`${startDate.format("YYYY-MM-DD ") + startTime} +0000`);
        const eDate = moment(`${endDate.format("YYYY-MM-DD ") + endTime} +0000`);

        // if (sDate.isSameOrAfter(eDate)) {
        //   errors.startDate = t('End Date should be later then Start Date');
        //   errors.endDate = true;
        //   errors.startTime = true;
        //   errors.endTime = true;
        // }
      }
    } else if (startDate && endDate && startDate.isAfter(endDate)) {
      // errors.startDate = t('End Date should be later then Start Date');
      // errors.endDate = true;
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }
  onCreate = async () => {
    const { name, type, allDay, level, repeatAnnually, startTime, endTime } = this.state;
    const { holidaysGroupUuid } = this.props;
    // const timeZone = this.state.timeZone || window.global_store.company.time_zone;
    const errors = this.getFormErrors();

    if (!errors) {
      this.setState({ loading: true });
      const holidayStart = this.state.startDate.format("YYYY-MM-DD ") + (startTime || "00:00");
      const holidayEnd = this.state.endDate
        ? this.state.endDate.format("YYYY-MM-DD ") + (endTime || "00:00")
        : this.state.startDate.format("YYYY-MM-DD ") + (startTime || "00:00");

      const request = {
        content: {
          name,
          type,
          level,
          startTime: holidayStart,
          endTime: type === "holiday" ? holidayStart : holidayEnd,
          paid: true,
          repeatAnnually,
          createdBy: window.global_store.profile.uuid,
        },
      };
      try {
        await createHoliday({
          body: request,
          companyUuid: window.global_store.company.uuid,
          holidaysGroupUuid,
        });
        this.props.onYes({ message: "You created holiday", type: "success" });
      } catch (error) {
        if (error && error.status === 500) {
          this.props.onYes({ type: "error", message: "Something went wrong" });
        } else {
          this.props.onYes({ type: "error", message: "Something went wrong" });
        }
      }
    }

    this.setState({ errors });
  };
  getNextEndDate(nextDate) {
    const { t } = this.props;
    let nextDateStr = nextDate.clone().add(1, "year").format("DD/MM/YYYY");
    if (nextDate.month() === 1 && nextDate.date() === 29) {
      nextDateStr = nextDate.add(1, "day").format("DD/MM/YYYY");
    }
    return `${t("Next end date")} ${nextDateStr}`;
  }
  render() {
    const {
      allDay,
      errors,
      startDate,
      startTime,
      endDate,
      endTime,
      loading,
      type,
      name,
      compensation,
      compensationOptions,
      level,
      levelOptions,
      repeatAnnually,
    } = this.state;

    const { t } = this.props;

    return (
      <Wrapper>
        <FieldWrapper fieldName={t("common|Name")} width="100%">
          <TextInputControl
            placeholder={t("common|Name")}
            value={name}
            error={errors && !!errors.name}
            onChange={(ev) => {
              this.setState({ name: ev.target.value });
            }}
          />
          {errors && errors.name ? <ErrorLabel>{errors.name}</ErrorLabel> : null}
        </FieldWrapper>
        {errors && errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}

        {/* <FieldWrapper width="100%">
          <FieldTabs
            active={type}
            onChange={(value) => this.setState({ type: value })}
            field1={{
              value: 'holiday',
              label: t('Holiday'),
            }}
            field2={{
              value: 'event',
              label: t('Event'),
            }}
          />
        </FieldWrapper> */}

        {type === "holiday" ? (
          <>
            <FieldWrapper fieldName={t("common|Start Date")} width="100%" fieldTitleMarginBottom={0}>
              <SingleDatePickerControl
                value={startDate}
                numberOfMonths={1}
                error={errors && !!errors.startDate}
                onChange={(val) => this.setState({ startDate: val })}
                isOutsideRange={() => false}
              />
              {errors && errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
            </FieldWrapper>
            <FieldWrapper fieldName={t("common|Level")} width="100%">
              <Select
                modifiers={{ field: true }}
                value={level}
                onChange={(val) => {
                  this.setState({ level: val });
                }}
                options={levelOptions}
              />
            </FieldWrapper>
          </>
        ) : (
          <>
            <CheckboxWrapper>
              <CheckboxControl
                checked={allDay}
                label={t("common|All day")}
                onChange={(checked) => this.setState({ allDay: checked })}
              />
            </CheckboxWrapper>

            <DateTimeRow
              dateLabel={`${t("common|Start")}:`}
              timeLabel={`${t("common|Time")}:`}
              date={startDate}
              time={startTime}
              onDateChange={(startDate) => {
                let ed = endDate;
                if (endDate.isBefore(startDate, "day")) {
                  ed = startDate.clone();
                }
                this.setState({ startDate, endDate: ed });
              }}
              onTimeChange={({ value }) => this.setState({ startTime: value })}
              dateError={!!(errors && errors.startDate)}
              timeError={!!(errors && errors.startTime)}
              timeDisabled={allDay}
              isOutsideRange={(day) => false}
            />

            {errors && errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
            {errors && errors.startTime && <ErrorLabel>{errors.startTime}</ErrorLabel>}
            <DateTimeRow
              dateLabel={`${t("common|End")}:`}
              timeLabel={`${t("common|Time")}:`}
              date={endDate}
              time={endTime}
              onDateChange={(endDate) => {
                let sd = startDate;
                if (startDate.isAfter(endDate, "day")) {
                  sd = endDate.clone();
                }
                this.setState({ endDate, startDate: sd });
              }}
              onTimeChange={({ value }) => {
                this.setState({ endTime: value });
              }}
              dateError={!!(errors && errors.endDate)}
              timeError={!!(errors && errors.endTime)}
              timeDisabled={allDay}
              isOutsideRange={() => false}
            />
            {errors && errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
            {errors && errors.endTime && <ErrorLabel>{errors.endTime}</ErrorLabel>}

            <FieldWrapper fieldName={t("Compensation")} width="100%">
              <Select
                modifiers={{ field: true }}
                value={compensation}
                onChange={(val) => {
                  this.setState({ compensation: val });
                }}
                options={compensationOptions}
              />
            </FieldWrapper>
          </>
        )}

        <CheckboxWrapper>
          <CheckboxControl
            checked={repeatAnnually}
            label={t("Repeat Anually")}
            onChange={(checked) => this.setState({ repeatAnnually: checked })}
          />
          {repeatAnnually && startDate ? (
            <RepeatAnnuallyText>{this.getNextEndDate(startDate)}</RepeatAnnuallyText>
          ) : null}
        </CheckboxWrapper>

        <ButtonsWrapper>
          <Button value={t("common|Create")} disabled={loading} loading={loading} onClick={this.onCreate} />
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}
export default withTranslation(["holidays"])(HolidayCreatePopup);
