import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import ReportsPage from "components/Reports/ReportsPage";
import Payroll from "components/Reports/Payroll";
import CreateLayout from "components/Payroll/CreateLayout";
import PayrollReport from "components/Reports/PayrollReport";
import { ActivityReportType, ReportType } from "types/reports";
import ReportsPageAfd from "components/Reports/ReportsPageAfd";
import ReportsPageClients from "components/Reports/ReportsPageClients";
import ReportsPageActivities from "components/Reports/ReportsPageActivities";
import ReportsPageDetailed from "components/Reports/ReportsPageDetailed(payroll)";
import ReportsPageFte from "components/Reports/ReportsPageFte";

export const RouteAdminReports = ({ match: { path } }: RouteComponentProps): React.ReactElement => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict from={`${path}/punches/`} to={`${path}/punches`} />
      <Route
        path={`${path}/punches`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.punches} key={ReportType.punches} />}
      />

      <Redirect strict from={`${path}/detailed/`} to={`${path}/detailed`} />
      <Route
        path={`${path}/detailed`}
        strict
        render={(): JSX.Element => <ReportsPageDetailed key={ReportType.detailed} />}
      />

      <Redirect strict from={`${path}/afd/`} to={`${path}/afd`} />
      <Route path={`${path}/afd`} strict render={(): JSX.Element => <ReportsPageAfd key={ReportType.afd} />} />

      <Redirect strict from={`${path}/fte/`} to={`${path}/fte`} />
      <Route
        path={`${path}/fte`}
        strict
        render={(routerProps): JSX.Element => <ReportsPageFte {...routerProps} key={ReportType.fte} />}
      />

      <Redirect strict from={`${path}/extra-hours/`} to={`${path}/extra-hours`} />
      <Route
        path={`${path}/extra-hours`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.extraHours} key={ReportType.extraHours} />}
      />

      <Redirect strict from={`${path}/summary/`} to={`${path}/summary`} />
      <Route
        path={`${path}/summary`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.summary} key={ReportType.summary} />}
      />

      <Redirect strict from={`${path}/checkin/`} to={`${path}/checkin`} />
      <Route
        path={`${path}/checkin`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.checkin} key={ReportType.checkin} />}
      />

      <Redirect strict from={`${path}/hour-bank/`} to={`${path}/hour-bank`} />
      <Route
        path={`${path}/hour-bank`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.hoursbank} key={ReportType.hoursbank} />}
      />

      <Redirect strict from={`${path}/client-reports/`} to={`${path}/client-reports`} />
      <Route
        path={`${path}/client-reports`}
        strict
        render={(): JSX.Element => <ReportsPageClients key={ReportType.clients} />}
      />

      <Redirect strict from={`${path}/late-arrivals/`} to={`${path}/late-arrivals`} />
      <Route
        path={`${path}/late-arrivals`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.lateArrival} key={ReportType.lateArrival} />}
      />
      <Redirect strict from={`${path}/digital_signatures_status/`} to={`${path}/digital_signatures_status`} />
      <Route
        path={`${path}/digital_signatures_status`}
        strict
        render={(): JSX.Element => (
          <ReportsPage reportType={ReportType.digitalSignaturesStatus} key={ReportType.digitalSignaturesStatus} />
        )}
      />

      <Redirect strict from={`${path}/projects/location/`} to={`${path}/projects/location`} />
      <Route
        path={`${path}/projects/location`}
        strict
        render={(): JSX.Element => (
          <ReportsPageActivities type={ActivityReportType.location} key={ActivityReportType.location} />
        )}
      />
      <Redirect strict from={`${path}/projects/project/`} to={`${path}/projects/project`} />
      <Route
        path={`${path}/projects/project`}
        strict
        render={(): JSX.Element => (
          <ReportsPageActivities type={ActivityReportType.project} key={ActivityReportType.project} />
        )}
      />
      <Redirect strict from={`${path}/projects/employee/`} to={`${path}/projects/employee`} />
      <Route
        path={`${path}/projects/employee`}
        strict
        render={(): JSX.Element => (
          <ReportsPageActivities type={ActivityReportType.employee} key={ActivityReportType.employee} />
        )}
      />
      <Redirect strict from={`${path}/projects/client/`} to={`${path}/projects/client`} />
      <Route
        path={`${path}/projects/client`}
        strict
        render={(): JSX.Element => (
          <ReportsPageActivities type={ActivityReportType.client} key={ActivityReportType.client} />
        )}
      />
      <Redirect strict from={`${path}/projects/task/`} to={`${path}/projects/task`} />
      <Route
        path={`${path}/projects/task`}
        strict
        render={(): JSX.Element => (
          <ReportsPageActivities type={ActivityReportType.taskFeature} key={ActivityReportType.taskFeature} />
        )}
      />

      <Redirect from={path} to={`${path}/punches`} />
    </Switch>
  </Suspense>
);

export const RouteEmployeeReports = ({ match: { path } }: RouteComponentProps): React.ReactElement => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict from={`${path}/punches/`} to={`${path}/punches`} />
      <Route
        path={`${path}/punches`}
        strict
        render={(): JSX.Element => <ReportsPage reportType={ReportType.punches} key={ReportType.punches} />}
      />

      <Redirect strict from={`${path}/detailed/`} to={`${path}/detailed`} />
      <Route
        path={`${path}/detailed`}
        strict
        render={(): JSX.Element => <ReportsPageDetailed key={ReportType.detailed} />}
      />

      <Redirect from={path} to={`${path}/punches`} />
    </Switch>
  </Suspense>
);
