import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Dropzone, { DropFilesEventHandler } from "react-dropzone";
import "styles/request-details.scss";
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from "axios";
import Slideshow from "components/UI/Slideshow";
import { ErrorLabel } from "components/UI/TextLabels";
import BEM from "utils/BEM";
import { TranslationNamespaces } from "types/translationNamespaces";
import RequestAttachmentsRow, { RequestAttachmentsRowProps } from "./RequestAttachmentsRow";

const b = BEM.b("request-details");

export type PendingAttachment = {
  file: File;
  cancelTokenSource: CancelTokenSource;
};

export type UploadAttachment = (
  file: File,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"],
  cancelToken: CancelToken,
) => Promise<RequestAttachmentsRowProps["files"][0]>;

export type RemoveAttachment = (uuid: string) => Promise<void>;

export type OnattachmentsChange = (state: { attachments: RequestAttachmentsRowProps["files"] }) => void;

export interface AttachmentsProps {
  t: WithTranslation["t"];
  attachments: RequestAttachmentsRowProps["files"];
  error?: string;
  stateRef?: React.Ref<{ loading: boolean }>;
  onChange: OnattachmentsChange;
  edit: boolean;
  upload?: UploadAttachment;
  remove?: RemoveAttachment;
}

function Attachments(props: AttachmentsProps) {
  const { t, error, attachments, stateRef, onChange = () => {}, edit, upload, remove } = props;
  const dropzone = useRef<Dropzone>();
  const [files, setFiles] = useState(attachments);
  const pendings = useRef<PendingAttachment[]>([]);

  useImperativeHandle(stateRef, () => ({
    get loading() {
      return pendings.current.length > 0;
    },
  }));
  useEffect(() => () => pendings.current.forEach((p) => p.cancelTokenSource.cancel()), [attachments]);
  useEffect(() => onChange({ attachments: files }), [files, onChange]);

  async function addAttachment(file: File) {
    if (!upload) {
      return;
    }

    const pend: PendingAttachment = {
      file,
      cancelTokenSource: axios.CancelToken.source(),
    };

    pendings.current.push(pend);

    setFiles((fs) => {
      const newF = {
        name: file.name,
        title: file.name,
        preview: URL.createObjectURL(file),
        loading: true,
        percentCompleted: 0,
      } as unknown as RequestAttachmentsRowProps["files"][0];

      const newFs = [...fs, newF];
      return newFs;
    });

    const onUploadProgress = (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      setFiles((fs) => {
        const newFs = fs.map((f) => (f.name === file.name ? { ...f, percentCompleted } : f));
        return newFs;
      });
    };

    const uploadedAttachment = await upload(pend.file, onUploadProgress, pend.cancelTokenSource.token);
    pendings.current = pendings.current.filter((pend) => pend.file !== file);
    setFiles((fs) => {
      const newF: RequestAttachmentsRowProps["files"][0] = {
        ...uploadedAttachment,
        name: file.name,
        type: "",
        loading: false,
        percentCompleted: 100,
      } as unknown as RequestAttachmentsRowProps["files"][0];
      const newFs = fs.map((f) => (f.name === file.name ? newF : f));
      return newFs;
    });
  }

  /**
   *
   * @param uuid attachment uuid
   * @returns
   */
  async function removeAttachment(uuid: string) {
    setFiles((fs) => {
      const newFs = fs.map((f) => (f.uuid === uuid ? { ...f, loading: true, percentCompleted: 0 } : f));
      return newFs;
    });

    if (remove) {
      try {
        await remove(uuid);
      } catch (err) {
        setFiles((fs) => {
          const newFs = fs.map((f) => (f.uuid === uuid ? { ...f, loading: false, percentCompleted: 0 } : f));
          return newFs;
        });
        return;
      }
    }
    setFiles((fs) => {
      const newFs = fs.filter((f) => f.uuid !== uuid);
      return newFs;
    });
  }

  const onAddAttachmentsClick = () => {
    dropzone.current?.open();
  };

  const onDrop: DropFilesEventHandler = (droppedFiles) => {
    droppedFiles.forEach((file) => {
      const isAlreadyAdded = files.some((f) => f.name === file.name);
      if (isAlreadyAdded) return;
      void addAttachment(file);
    });
  };

  const onRemoveAttachment: RequestAttachmentsRowProps["onRemoveAttachment"] = ({ uuid }) => {
    void removeAttachment(uuid);
  };

  const [slideshowIndex, setSlideshowIndex] = useState<number | null>(null);

  return (
    <>
      <Dropzone
        ref={dropzone as any}
        multiple
        accept={["image/*", "application/pdf"]}
        preventDropOnDocument
        disableClick
        className={b("drop-zone", ["new"])}
        activeClassName={b("drop-zone", ["active", "new"])}
        acceptClassName={b("drop-zone", ["accept", "new"])}
        rejectClassName={b("drop-zone", ["reject", "new"])}
        onDrop={onDrop}
      >
        <RequestAttachmentsRow
          tabIndex={0}
          files={files}
          dropAvailable={edit}
          onAddFilesClick={onAddAttachmentsClick}
          onRemoveAttachment={edit ? onRemoveAttachment : undefined}
          openSlideshow={(index) => setSlideshowIndex(index)}
        />
      </Dropzone>
      {error && <ErrorLabel>{error}</ErrorLabel>}
      {files.length > 0 && slideshowIndex !== null && (
        <Slideshow
          isOpen={slideshowIndex !== null}
          currentImgIndex={slideshowIndex}
          attachments={files}
          onClose={() => setSlideshowIndex(null)}
          t={t}
        />
      )}
    </>
  );
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(Attachments);
