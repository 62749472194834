import { useQuery } from "react-query";
import { getShiftCompilationEventsLog } from "utils/api/userProfile";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { Punch } from "types/models/punches";
import { OnPunchActionData, OnPunchDeclineData } from "context/SuperpunchProvider/types";
import DropdownHeader from "./DropdownHeader";
import ShiftEventsList from "./ShiftEventsList";
import CellDropdown from "./CellDropdown";
import NoPunches from "./NoPunches";

const useShiftEventsLogs = (userProfineUuid: string, shiftEventId: string, eventKey: string) => {
  const STALE_TIME = 1000 * 5;

  const result = useQuery(
    `rq_shift_events_logs_${shiftEventId}_${eventKey}`,
    async () => {
      const response = await getShiftCompilationEventsLog({ userProfineUuid, shiftId: shiftEventId, eventKey });

      return response;
    },
    { staleTime: STALE_TIME, retry: false },
  );

  return result;
};

type ShiftEventsLogsScreenProps = {
  userProfineUuid: string;
  shiftEventId: string;
  eventKey: string;
  punches: Punch[];
  onPunchApprove: (data: OnPunchActionData) => void;
  onPunchValidate: (data: OnPunchActionData) => void;
  onPunchDecline: (data: OnPunchDeclineData) => void;
  onBack: () => void;
};

const ShiftEventsLogsScreen = ({
  userProfineUuid,
  shiftEventId,
  eventKey,
  punches,
  onPunchApprove,
  onPunchValidate,
  onPunchDecline,
  onBack,
}: ShiftEventsLogsScreenProps) => {
  const eventsResult = useShiftEventsLogs(userProfineUuid, shiftEventId, eventKey);
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const events = eventsResult?.data?.content || [];

  let dropdownContent = null;
  if (eventsResult.isLoading) {
    dropdownContent = <NoPunches loading />;
  } else if (eventsResult.error) {
    dropdownContent = <NoPunches>{t("events-getting-error")}</NoPunches>;
  } else if (events.length) {
    dropdownContent = (
      <ShiftEventsList
        userProfineUuid={userProfineUuid}
        punches={punches}
        events={events}
        onPunchApprove={onPunchApprove}
        onPunchValidate={onPunchValidate}
        onPunchDecline={onPunchDecline}
      />
    );
  } else {
    dropdownContent = <NoPunches>{t("No events")}</NoPunches>;
  }

  return (
    <CellDropdown header={<DropdownHeader title={t("Log History")} onBackClick={onBack} />} content={dropdownContent} />
  );
};

export default ShiftEventsLogsScreen;
