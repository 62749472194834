import { PropsWithChildren, useEffect } from "react";
import { Provider } from "unstated";
import { BrowserRouter, Redirect, Route, RouteComponentProps, Switch, useHistory, useLocation } from "react-router-dom";
import RouteRecommend from "routes/Recommend";
import RoutePayroll from "routes/Payroll";
import RoutePegador from "routes/Pegador";
import RouteEmployees from "routes/Employees";
import RouteBilling from "routes/Billing";
import RoutePunches from "routes/Punches";
import RouteRequests from "routes/Requests";
import RouteProjects from "routes/Projects";
import RouteServices from "routes/Services";
import RouteCompany from "routes/Company";
import RouteInviteEmployees from "routes/InviteEmployees";
import { RouteAdminReports } from "routes/Reports";
import RouteTimeClocks from "routes/TimeClocks";
import RouteApplications from "routes/Applications";
import Schedules from "routes/Schedules";
import RouteLoginNew from "routes/LoginNew";
import RouteMyDigitalSignatures from "routes/MyDigitalSignatures";
import RouteForgotPasswordNew from "routes/ForgotPasswordNew";
import RouteSsoLogin from "routes/RouteSsoLogin";
import RouteAccountTypeNew from "routes/AccountTypeNew";
import RouteSuccess from "routes/RouteSuccess";
import RouteSignupNew from "routes/SignupNew";
import RouteChangePasswordNew from "routes/ChangePasswordNew";
import RouteDashboard from "routes/RouteDashboard";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { BillingService } from "components/Billing/BillingService";
import ga from "utils/ga";
import { UserProfile, UserProfileEmployeeStatus } from "types/models/userProfile";
import NoPermissions from "components/FallbackPages/NoPermissions";
import RouteOnCalls from "./OnCalls";
import RouteSubscriptions from "./Subscriptions";
import { RouteTimesheets } from "../components/Timesheets";
import SelectTrialPlan from "../components/Onboarding/SelectTrialPlan";
import { Feature } from "../types/models/subscription";
import RouteDataManagement from "./DataManagement";
import RouteIntegrations from "./Integrations";
import { SelectObjectivesPage } from "../components/Onboarding/SelectObjectivesPage";
import RouteActivities from "../components/Activities/Activities.router";

type RouteListenerProps = PropsWithChildren;

const RouteListener = ({ children }: RouteListenerProps) => {
  const location = useLocation();
  // use history here, do not pass it to useEffect
  const { action, push } = useHistory();
  const currentPageTitle = document.title;

  useEffect(() => {
    let retries = 5;

    const sendAnalytics = () =>
      setTimeout(() => {
        // Sometimes document title is changed dynamically (e.g. in componentDidMount). Retry during 5 * 300 ms and then send the event
        if (retries && currentPageTitle === document.title) {
          retries -= 1;

          sendAnalytics();
          return;
        }

        const pageLocation = encodeURI(window.location.origin + location.pathname + location.search);
        try {
          ga.trackNavigation(pageLocation, document.title);
        } catch (error) {
          // ignore error. Error can rise if user has adblock or something else
        }
      }, 300);

    // avoid tracking redirects
    if (action !== "REPLACE") {
      sendAnalytics();
    } else {
      // console.log("Redirect does not count", location);
    }
  }, [location]);

  useEffect(() => {
    // show scroll bar
    const scrollHandler = (e) => {
      if (e.target?.classList && !e.target.classList.contains("scrolling")) {
        e.target.classList.add("scrolling");
      }
    };

    // hide scroll bar
    const scrollEndHandler = (e) => {
      setTimeout(() => {
        if (e.target?.classList && e.target.classList.contains("scrolling")) {
          e.target.classList.remove("scrolling");
        }
      }, 1000);
    };

    // custom scroll only for non mac operating systems
    if (navigator.userAgent.indexOf("Mac") === -1) {
      document.body.classList.add("custom-scroll");

      window.addEventListener("scroll", scrollHandler, true);
      window.addEventListener("scrollend", scrollEndHandler, true);
    }

    const handleNavigateWhenEmbedded = (event: MessageEvent) => {
      if (event.data?.action === "navigate" && event.data?.url) {
        push(event.data?.url);
      }
    };

    if (window.global_store.embedded) {
      window.parent.postMessage(
        {
          action: "pageLoaded",
        },
        "*",
      );

      window.addEventListener("message", handleNavigateWhenEmbedded);
    }

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("scrollend", scrollEndHandler);
      window.removeEventListener("message", handleNavigateWhenEmbedded);
    };
  }, []);

  return <>{children}</>;
};

type AdminRoute = {
  key: PermissionSectionName | "routeRedirect" | "login" | "selectTrialPlan" | "selectObjectives";
  path: string;
  component?: (props: RouteComponentProps) => JSX.Element;
  redirect?: boolean;
  show?: boolean;
};

export const getRouterWithRoutesByPermissions = (profile: UserProfile): JSX.Element => {
  const { permissions } = profile;

  const adminRoutes: AdminRoute[] = [
    {
      key: PermissionSectionName.dashboard,
      path: "/dashboard",
      component: RouteDashboard,
      show: BillingService.checkFeatureAccess(Feature.TA),
    },
    { key: PermissionSectionName.employees, path: "/employees", component: RouteEmployees },
    { key: PermissionSectionName.billing, path: "/billing", component: RouteBilling },
    {
      key: PermissionSectionName.punches,
      path: "/punches",
      component: RoutePunches,
      show: BillingService.checkFeatureAccess(Feature.TA),
    },
    { key: PermissionSectionName.activities, path: "/activities", component: RouteActivities },
    {
      key: PermissionSectionName.requests,
      path: "/requests",
      component: RouteRequests,
      show: BillingService.checkFeatureAccess(Feature.TA),
    },
    {
      key: PermissionSectionName.projects,
      path: "/projects",
      component: RouteProjects,
      show: BillingService.checkFeatureAccess(Feature.Project),
    },
    {
      key: PermissionSectionName.projects,
      path: "/services",
      component: RouteServices,
      show: BillingService.checkFeatureAccess(Feature.Project),
    },
    { key: PermissionSectionName.company, path: "/company", component: RouteCompany },
    { key: PermissionSectionName.dataManagement, path: "/data-management", component: RouteDataManagement },
    { key: PermissionSectionName.schedules, path: "/schedules", component: Schedules },
    {
      key: PermissionSectionName.inviteEmployees,
      path: "/invite-employees",
      component: RouteInviteEmployees,
    },
    { key: PermissionSectionName.reports, path: "/reports", component: RouteAdminReports },
    { key: PermissionSectionName.recommend, path: "/recommend", component: RouteRecommend },
    { key: PermissionSectionName.timeClocks, path: "/time-clocks", component: RouteTimeClocks },
    { key: PermissionSectionName.marketplace, path: "/apps", component: RouteApplications },
    { key: PermissionSectionName.pegador, path: "/pegador", component: RoutePegador },
    { key: PermissionSectionName.onCalls, path: "/on-calls", component: RouteOnCalls },
    {
      key: PermissionSectionName.subscription,
      path: "/integration",
      component: RouteIntegrations,
      show: BillingService.checkFeatureAccess(Feature.Project),
    },
  ];

  const companyPermissions = [
    PermissionSectionName.companyProfile,
    PermissionSectionName.subsidiaries,
    PermissionSectionName.departments,
    PermissionSectionName.positions,
    PermissionSectionName.teams,
    PermissionSectionName.clients,
    PermissionSectionName.locations,
    PermissionSectionName.jobSides,
    PermissionSectionName.groupsOfRules,
    PermissionSectionName.rulesAndLimits,
    PermissionSectionName.holidays,
    PermissionSectionName.payroll,
  ];

  const availablePermissions = permissions;
  if (
    permissions.length &&
    !window.global_store?.profile?.permission_roles?.some((r) => r.name === PermissionRoleName.deactivated)
  )
    availablePermissions.push(PermissionSectionName.subscription);

  if (
    window.global_store?.profile?.permission_roles?.some((r) =>
      [PermissionRoleName.admin, PermissionRoleName.owner, PermissionRoleName.hr].includes(r.name),
    )
  ) {
    availablePermissions.push(PermissionSectionName.dataManagement);
  }

  if (permissions.some((elem) => companyPermissions.indexOf(elem) > -1)) {
    availablePermissions.push(PermissionSectionName.company);
  }

  if (
    permissions.some((elem) =>
      [PermissionSectionName.basicReports, PermissionSectionName.advancedReports].includes(elem),
    )
  ) {
    availablePermissions.push(PermissionSectionName.reports);
  }

  let availableRoutes: AdminRoute[] = [];

  availablePermissions.map((perm) => {
    const routes = adminRoutes.filter((rt) => rt.key === perm);

    if (routes.length !== 0) {
      availableRoutes.push(...routes);
    }

    return perm;
  });

  if (permissions.length) {
    availableRoutes.push({
      key: PermissionSectionName.activities,
      path: "/timesheets",
      component: RouteTimesheets,
      show: BillingService.checkFeatureAccess(Feature.Project),
    });
    availableRoutes.push({
      key: PermissionSectionName.recommend,
      path: "/recommend",
      component: RouteRecommend,
    });
  }

  if (availablePermissions.includes(PermissionSectionName.mysignatures)) {
    availableRoutes.push({
      key: PermissionSectionName.mysignatures,
      path: "/my-digital-signatures",
      component: RouteMyDigitalSignatures,
    });
  }

  if (availablePermissions.includes(PermissionSectionName.activities)) {
    availableRoutes.push({ key: PermissionSectionName.activities, path: "/activities", component: RouteActivities });
  }

  if (permissions.includes(PermissionSectionName.timeClocks)) {
    availableRoutes.push({
      key: PermissionSectionName.pegador,
      path: "/pegador",
      component: RoutePegador,
    });
  }

  if (availablePermissions.includes(PermissionSectionName.payroll)) {
    availableRoutes.push({
      key: PermissionSectionName.payroll,
      path: "/payroll",
      component: RoutePayroll,
    });
  }

  if (
    availablePermissions.includes(PermissionSectionName.onCallManagement) ||
    availablePermissions.includes(PermissionSectionName.onCallRequest)
  ) {
    const route = adminRoutes.find((rt) => rt.key === PermissionSectionName.onCalls);
    route && availableRoutes.push(route);
  }

  if (permissions.includes(PermissionSectionName.employees)) {
    const route = adminRoutes.find((rt) => rt.key === PermissionSectionName.inviteEmployees);
    route && availableRoutes.push(route);

    availableRoutes.push({
      key: "routeRedirect",
      path:
        BillingService.subscriptionPlan === Feature.Project && permissions.includes(PermissionSectionName.activities)
          ? "/activities/calendar"
          : "/employees",
      redirect: true,
    });
  } else if (permissions.includes(PermissionSectionName.punches) && BillingService.checkFeatureAccess(Feature.TA)) {
    availableRoutes.push({
      key: "routeRedirect",
      path: BillingService.subscriptionPlan === Feature.Project ? "/activities/calendar" : "/punches",
      redirect: true,
    });
  } else if (
    permissions.includes(PermissionSectionName.activities) &&
    BillingService.subscriptionPlan === Feature.Project
  ) {
    availableRoutes.push({
      key: "routeRedirect",
      path: "/activities/calendar",
      redirect: true,
    });
  } else {
    // eslint-disable-next-line no-prototype-builtins
    const availableRoute = availableRoutes.find((route) => route.path && (!route.hasOwnProperty("show") || route.show));
    availableRoutes.push({
      key: "routeRedirect",
      path: availableRoute?.path as string,
      redirect: true,
    });
  }

  const isNeedToLock = BillingService.checkLocking();
  if (isNeedToLock) {
    availableRoutes = availableRoutes.filter((router) => router!.key === PermissionSectionName.billing);
    availableRoutes.push(
      {
        key: PermissionSectionName.subscription,
        path: "/subscription-lock",
        component: RouteSubscriptions,
      },
      {
        key: "routeRedirect",
        path: "/subscription-lock",
        redirect: true,
      },
    );
  }

  if (BillingService.isNeedTrialSubscription()) {
    availableRoutes = [
      { key: "login", path: "/login", component: RouteLoginNew },
      { key: "selectObjectives", path: "/select-objectives", component: SelectObjectivesPage },
      { key: "selectTrialPlan", path: "/select-plan", component: SelectTrialPlan },
      { key: PermissionSectionName.subscription, path: "/integration", component: RouteIntegrations },
      {
        key: "routeRedirect",
        path: "/select-objectives",
        redirect: true,
      },
    ];
  }

  // if user has acces only to my digital signatures - don't show admin and redirect to my digital signatures page
  // overrides all avaliable routes and leaves only my digital signatures
  if (availablePermissions.length === 1 && availablePermissions.includes(PermissionSectionName.mysignatures)) {
    availableRoutes = [
      {
        key: PermissionSectionName.mysignatures,
        path: "/my-digital-signatures",
        component: RouteMyDigitalSignatures,
      },
      {
        key: "routeRedirect",
        path: "/my-digital-signatures",
        redirect: true,
      },
    ];
  } else if (!availablePermissions.length) {
    availableRoutes = [
      {
        key: "no-permissions",
        path: "/no-permissions",
        component: NoPermissions,
      },
      {
        key: "routeRedirect",
        path: "/no-permissions",
        redirect: true,
      },
    ];
  }

  // eslint-disable-next-line no-prototype-builtins
  const filteredAvailableRoutes = availableRoutes.filter((route) => !route.hasOwnProperty("show") || route.show);

  return (
    <Provider>
      <BrowserRouter>
        <RouteListener>
          <Switch>
            {filteredAvailableRoutes.map((route) => {
              if (route?.redirect) {
                return <Redirect key={route.key} to={route.path} />;
              }
              return <Route key={route?.key} path={route?.path} component={route?.component} />;
            })}
          </Switch>
        </RouteListener>
      </BrowserRouter>
    </Provider>
  );
};

export const getRouterWithRoutes = (): JSX.Element => {
  const unauthorisedRoutes = [
    { key: "login", path: "/login", component: RouteLoginNew },
    { key: "newPassword", path: "/new-password", component: RouteChangePasswordNew },
    { key: "signup", path: "/signup", component: RouteSignupNew },
    { key: "signup", path: "/signup-default", component: RouteSignupNew },
    { key: "success", path: "/success", component: RouteSuccess },
    {
      key: "forgotPassword",
      path: "/forgot-password",
      component: RouteForgotPasswordNew,
    },
    {
      key: "ssoLogin",
      path: "/sso-login",
      component: RouteSsoLogin,
    },
    { key: "accountType", path: "/account-type", component: RouteAccountTypeNew },
    { key: "selectObjectives", path: "/select-objectives", component: SelectObjectivesPage },
    { key: "selectTrialPlan", path: "/select-plan", component: SelectTrialPlan },
    { key: "integration", path: "/integration", component: RouteIntegrations },
    { key: "loginRedirect", path: "/login", redirect: true },
  ];

  return (
    <Provider>
      <BrowserRouter>
        <RouteListener>
          <Switch>
            {unauthorisedRoutes.map((route) => {
              if (route.redirect) {
                return <Redirect key={route.key} to={route.path} />;
              }
              return <Route key={route.key} path={route.path} component={route.component} />;
            })}
          </Switch>
        </RouteListener>
      </BrowserRouter>
    </Provider>
  );
};
