import { Component } from "react";
import BEM from "utils/BEM";
import "../../styles/reports-popup-add-cnpj.scss";
import FieldTabs from "components/controls/FieldTabs";
import { withTranslation } from "react-i18next";
import { CNPJ, CPF } from "cpf_cnpj";
import {
  getCompanyTaxPayerType,
  getCompanyTaxIdTranslation,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
} from "utils/common";
import { CompanyTaxPayerTypes, EmployeeTaxPayerTypes } from "types/common";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import TextInputControl from "components/controls/StyledTextInput";
import GlobalContext from "../../context/global-context";

const input = BEM.b("input");
const b = BEM.b("reports-popup-add-cnpj");

const Spacer = styled.div`
  height: 25px;
`;

const CNPJ_MASK = {
  placeholder: "xx.xxx.xxx/xxxx-xx",
  mask: [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/],
};

const CPF_MASK = {
  placeholder: "XXX.XXX.XXX-XX",
  mask: [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/],
};

class ReportsPopupAddCnpj extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      cpfValue: "",
      cnpjValue: "",
      type: "cnpj",
    };
  }

  componentDidMount() {
    this.context.getCompany().then((company) => {
      this.setState({ cnpjValue: company.tax_payer_id ? CNPJ.format(company.tax_payer_id) : "" });
    });
  }

  closePopup() {
    this.props.onClose();
  }

  handleKeyPress(ev) {
    if (ev.keyCode === 27) {
      this.closePopup();
    }
  }

  onCancel() {
    this.closePopup();
  }

  onSubmit() {
    let errors = {};
    const { t } = this.props;
    const { type, cpfValue, cnpjValue } = this.state;
    const companyTaxId = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
    const companyTaxIdLabel = getCompanyTaxIdTranslation(companyTaxId, t);
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    if (type === "cnpj") {
      if (!cnpjValue) {
        errors.cnpj = t("Please enter {{taxId}}", { taxId: companyTaxIdLabel });
      } else if (companyTaxId === CompanyTaxPayerTypes.cnpj && !CNPJ.isValid(cnpjValue)) {
        errors.cnpj = t("Entered CNPJ is invalid");
      } else {
        errors = null;
        if (this.props.onYes) {
          this.props.onYes({ cnpj: cnpjValue });
        }
      }
    } else if (!cpfValue) {
      errors.cpf = t("Please enter {{taxId}}", { taxId: employeeTaxIdLabel });
    } else if (employeeTaxId === EmployeeTaxPayerTypes.cpf && !CPF.isValid(cpfValue)) {
      errors.cpf = t("Entered {{taxId}} is invalid", { taxId: employeeTaxIdLabel });
    } else {
      errors = null;
      if (this.props.onYes) {
        this.props.onYes({ cpf: cpfValue });
      }
    }
    this.setState({ errors });
  }

  render() {
    const { errors, type, cpfValue, cnpjValue } = this.state;
    const { t } = this.props;
    const companyTaxId = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
    const companyTaxIdLabel = getCompanyTaxIdTranslation(companyTaxId, t);
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    return (
      <div className={b()}>
        <div className={b("title")}>{t("Configure Report")}</div>
        <div className={b("description")}>{t("Choose the preferred document to generate report:")}</div>
        <FieldTabs
          active={type}
          onChange={(val) => this.setState({ type: val })}
          tab1={{
            value: "cnpj",
            label: companyTaxIdLabel,
          }}
          tab2={{
            value: "cpf",
            label: employeeTaxIdLabel,
          }}
        />
        <Spacer />
        {type === "cnpj" ? (
          <>
            <div className={b("label")}>CNPJ</div>
            {companyTaxId === CompanyTaxPayerTypes.cnpj ? (
              <MaskedInput
                className={input({ error: !!errors?.cnpj })}
                placeholder={CNPJ_MASK.placeholder}
                value={cnpjValue}
                name="cnpj"
                onChange={(ev) => this.setState({ cnpjValue: ev.target.value })}
                mask={CNPJ_MASK.mask}
              />
            ) : (
              <TextInputControl
                value={cnpjValue}
                error={!!errors?.cnpj}
                onChange={(value) => this.setState({ cnpjValue: value })}
              />
            )}
            {errors?.cnpj && <div className={b("error")}>{errors.cnpj}</div>}
            <div />
          </>
        ) : (
          <>
            <div className={b("label")}>{employeeTaxIdLabel}</div>
            {employeeTaxId === EmployeeTaxPayerTypes.cpf ? (
              <MaskedInput
                className={input({ error: !!errors?.cpf })}
                placeholder={CPF_MASK.placeholder}
                value={cpfValue}
                name="cpf"
                onChange={(ev) => this.setState({ cpfValue: ev.target.value })}
                mask={CPF_MASK.mask}
              />
            ) : (
              <TextInputControl
                value={cpfValue}
                error={!!errors?.cpf}
                onChange={(value) => this.setState({ cpfValue: value })}
              />
            )}
            {errors?.cpf && <div className={b("error")}>{errors.cpf}</div>}
          </>
        )}
        <div className={b("buttons")}>
          <button className={b("button")} onClick={this.onCancel.bind(this)}>
            {t("Cancel")}
          </button>
          <button className={b("button", { active: true })} onClick={this.onSubmit.bind(this)}>
            {t("Submit")}
          </button>
        </div>
      </div>
    );
  }
}
export default withTranslation("reports")(ReportsPopupAddCnpj);
