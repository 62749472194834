import { Component } from "react";
import styled from "styled-components";
import { TFunction } from "react-i18next";
import BEM from "utils/BEM";
import { GroupSearchDepartment } from "types/models/department";
import { GroupSearchSubsidiary } from "types/models/subsidiary";
import { GroupSearchTeam } from "types/models/team";
import { GlobalContextEmployee, GroupSearchUserProfile, SearchEmployeeUserProfile } from "types/models/userProfile";
import User from "components/User";
import * as images from "components/svg-images";
import "./search-control.scss";
import { Service } from "types/models/projects";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";

const b = BEM.b("search-control-new");

const UserWrapper = styled.div<{ $inactive: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.$inactive ? "0.3" : "1")};
`;

export enum SearchControlItemType {
  /** All entities */
  all = "all",
  /** All departments */
  departments = "departments",
  /** All supervisors */
  supervisors = "supervisors",
  /** All teams */
  teams = "teams",
  /** All subsidiaries */
  subsidiaries = "subsidiaries",
  /** All services */
  services = "services",

  /** Single employee */
  employee = "employee",
  /** Single department */
  department = "department",
  /** Single subsidiary */
  subsidiary = "subsidiary",
  /** Single team */
  team = "team",
}

export type SearchControlItem = {
  type: SearchControlItemType;
  value: string;
  label?: string;
  inactive?: boolean;
  withSeparator?: boolean;
  obj:
    | SearchEmployeeUserProfile /** Regular search */
    | GroupSearchUserProfile /** Group search */
    | GlobalContextEmployee /** Navigation arrows */
    | GroupSearchDepartment /** Group search */
    | GroupSearchSubsidiary /** Group search */
    | GroupSearchTeam /** Group search */
    | Service
    | null /** "All <entity>" options */;
};

interface SearchItemProps {
  t: TFunction;
  item: SearchControlItem;
  onItemClick: (item: SearchControlItem) => void;
  preselected: boolean;
  textToHighlight: string;
}

// used also in another places
class Item extends Component<SearchItemProps> {
  renderLogo = (item: SearchControlItem) => {
    switch (item.type) {
      case SearchControlItemType.all:
        return images.searchControlAllEmployees;
      case SearchControlItemType.department:
      case SearchControlItemType.departments:
        return images.navDepartments(16, 16);
      case SearchControlItemType.subsidiary:
      case SearchControlItemType.subsidiaries:
        return images.navSubsidiaries(16, 19);
      case SearchControlItemType.team:
      case SearchControlItemType.teams:
      case SearchControlItemType.supervisors:
        return images.navTeams(20, 14);

      default:
        return null;
    }
  };

  renderTitle = (item: SearchControlItem) => {
    const { t } = this.props;

    switch (item.type) {
      case SearchControlItemType.all:
        return translateEmployeeTerm(t, TranslationNamespaces.common, "custom-all-employees", "All Employees");
      case SearchControlItemType.departments:
        return t("All Departments");
      case SearchControlItemType.supervisors:
        return t("All Supervisors");
      case SearchControlItemType.teams:
        return t("All Teams");
      case SearchControlItemType.subsidiaries:
        return t("All Subsidiaries");

      // single entity
      default:
        return item.label || (item.obj as GroupSearchDepartment | GroupSearchSubsidiary | GroupSearchTeam).name;
    }
  };

  renderSubTitle = (item: SearchControlItem) => {
    const { t } = this.props;

    switch (item.type) {
      case SearchControlItemType.department:
        return t("Departments");
      case SearchControlItemType.subsidiary:
        return t("Subsidiary");
      case SearchControlItemType.team:
        return t("Team");
      case SearchControlItemType.services:
        return t("Service");

      // single entity
      default:
        return null;
    }
  };

  renderItem = (item: SearchControlItem) => {
    const { textToHighlight } = this.props;

    if (item.type === SearchControlItemType.employee) {
      return (
        <UserWrapper $inactive={!!item.inactive}>
          <User
            textToHighlight={textToHighlight}
            user={{
              matricula: (item.obj as GroupSearchUserProfile).matricula,
              fullName: (item.obj as GroupSearchUserProfile).full_name,
              avatarId: (item.obj as GroupSearchUserProfile).avatar_id,
              position:
                (item.obj as GroupSearchUserProfile).position?.title ||
                (item.obj as GroupSearchUserProfile).job_description ||
                "",
            }}
          />
        </UserWrapper>
      );
    }

    return (
      <>
        <div className={b("logo", [item.type])}>{this.renderLogo(item)}</div>
        <div className={b("user-info")}>
          <div className={b("user-name")}>{this.renderTitle(item)}</div>
          <div className={b("user-position")}>{this.renderSubTitle(item)}</div>
        </div>
      </>
    );
  };

  render() {
    const { item, preselected, onItemClick } = this.props;

    return (
      <div className={b("item", { preselected, "top-border": item.withSeparator })} onClick={() => onItemClick(item)}>
        {this.renderItem(item)}
      </div>
    );
  }
}

export default Item;
