import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import ProjectsList from "components/Projects/ProjectsList";
import ProjectPage from "components/Projects/ProjectPage";

const RouteProjects = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Route exact strict path={`${path}/`} render={() => <Redirect to={`${path}`} />} />
      <Route exact strict path={`${path}`} component={ProjectsList} />

      <Route strict path={`${path}/:projectUuid/`} render={() => <Redirect to={`${path}/:projectUuid`} />} />
      <Route path={`${path}/:projectUuid`} component={ProjectPage} />
    </Switch>
  </Suspense>
);

export default RouteProjects;
