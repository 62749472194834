import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { useState } from "react";

const styles = stylesheet`
.Wrapper {
  color: var(--colors-surface-800);

  .Title {
    font-size: 25px;
    color: var(--colors-mainText);
    letter-spacing: -0.55px;
    line-height: 26px;
    margin-bottom: 32px;
    text-align: center;
  }

  .Message {
    font-size: var(--typography-font-size-default);
    margin-bottom: 25px;
    color: var(--colors-surface-700);
  }

  .ReasonTitle {
    font-size: 13px;
    margin-bottom: 7px;
  }

  textarea {
    width: 100%;
    height: 80px;
    border: 1px solid var(--colors-surface-400);
    border-radius: var(--shapes-border-radius-default);
    outline: none;

    &:focus {
      border: 2px solid var(--colors-primary);
    }
  }
  .ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    button {
      max-width: 155px;
    }
  }
}
`;

interface SubmitTimesheetPopupProps {
  numTimesheets: number;
  onClose: () => void;
  onYes: (evt: { reason: string }) => void;
}

export default function SubmitTimesheetPopup(props: SubmitTimesheetPopupProps) {
  const { onYes, onClose, numTimesheets } = props;
  const [t] = useTranslation(TranslationNamespaces.timesheets);
  const [reason, setReason] = useState("");

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Title}>{numTimesheets === 1 ? t("Decline timesheet") : t("Decline timesheets")}</div>
      <div className={styles.Message}>
        {numTimesheets === 1 ? (
          t("Are you sure you want to decline the timesheet?")
        ) : (
          <>
            {t("Are you sure you want to decline these")} <strong>{numTimesheets}</strong> {t("timesheets?")}
          </>
        )}
      </div>
      <div>
        <div className={styles.ReasonTitle}>{t("Reason")}</div>
        <textarea maxLength={65536} value={reason} onChange={(evt) => setReason(evt.target.value)} />
      </div>

      <div className={styles.ButtonsWrapper}>
        <Button value={t("common|Cancel")} state={ButtonState.secondary} onClick={onClose} />
        <Button
          value={t("common|Decline")}
          disabled={reason === ""}
          state={ButtonState.primary}
          onClick={() => onYes({ reason })}
        />
      </div>
    </div>
  );
}
