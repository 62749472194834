import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { getEmbeddedDashboardUrl } from "utils/apiHelpers";
import { getLocale } from "utils/translationHelpers";

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 96px); // header height (56px) + beta row (40px)
  border: none;
`;

const EmbeddedDashboardTable = (props: { type: string }) => {
  const { type } = props;
  const [embeddedUrl, setEmbeddedUrl] = useState<string>("");
  const context = useContext(GlobalContext);

  const [getData] = useAsyncCallback(async () => {
    const companyUuid = (await context.getCompany())?.uuid;
    const response = await getEmbeddedDashboardUrl({
      companyUuid,
      type,
      language: getLocale(),
    });

    setEmbeddedUrl(response.content.url);
  }, [type]);

  useEffect(() => {
    void getData();
  }, [type]);

  return <Iframe width="100%" height="100%" title="analytics" src={embeddedUrl} />;
};

export default EmbeddedDashboardTable;
