import { create } from "zustand";
import {
  ActivityRes,
  Location,
  ProjRes,
  TimesheetApprovalStatus,
  TimesheetsListItem,
} from "services/oitchau-api";
import moment from "moment";
import CONFIG from "../../../config";
import { listTimesheets, ListTimesheetsRequest } from "../../Timesheets";
import { ProjectOption } from "./components/TaskOptions";

export type StoredActivity = Partial<ActivityRes & {taskUuid?: string, projectUuid?: string, locationUuid?: string}>;
export interface TrackTimeStore {
  showTaskPicker: boolean;
  setShowTaskPicker: (val: boolean) => void;
  showDatePicker: boolean;
  setShowDatePicker: (val: boolean) => void;
  showTaskDetails: boolean;
  setShowTaskDetails: (val: boolean) => void;
  projects: ProjectOption[];
  targetProject: ProjRes | null;
  setProjects: (projArray: ProjectOption[]) => void;
  setLocations: (locs: Location[]) => void;
  activity: StoredActivity | null;
  setActivity: (activity: StoredActivity | null) => void;
  selectedEmployee?: {uuid: string};
  selectEmployee: (data: any) => void;
  showEmployeeSelect: boolean;
  setShowEmployeeSelect: (val: boolean) => void;

  setTargetProject: (project: ProjRes | null) => void;
  showSelectProject: boolean;
  setShowSelectProject: (val: boolean) => void;
  showCreateProject: boolean,
  setShowCreateProject: (val: boolean) => void;

  touched: boolean;
  setTouched: (val: boolean) => void;
  reload: boolean;
  reloadProjects: (val: boolean) => void;

  searchValue: string;
  setSearchValue: (val: string) => void;

  timesheetsLoaded: boolean;
  runningActivityLoaded: boolean;
  projectsLoaded: boolean;
  locationsLoaded: boolean;
  setRunningActivityLoaded: (val: boolean) => void;

  timesheets: TimesheetsListItem[];
  loadTimesheets: (companyUuid: string, userProfileUuid: string, requestedUserProfileUuid: string, force?: boolean) => Promise<TimesheetsListItem[]>;
  setTimesheets: (timesheets: TimesheetsListItem[]) => void;

  locations: Location[];
  // date-time related state values
  startTime: number | undefined;
  setStartTime: (val: number | undefined) => void;
  endTime: number | undefined;
  setEndTime: (val: number | undefined) => void;
  startDate: moment.Moment | null;
  setStartDate: (val: moment.Moment | null) => void;
  endDate: moment.Moment | null;
  setEndDate: (val: moment.Moment | null) => void;
  duration: number | undefined;
  setDuration: (val: number | undefined) => void;
  initiateDateValues: () => void;
  closeAllModals: () => void;
  changeDuration: (dur: string | null) => string;
  clearStore: () => void;
}
const initialStoreValues = {
  showTaskPicker: false,
  showDatePicker: false,
  showTaskDetails: false,
  showSelectProject: false,
  showCreateProject: false,
  showEmployeeSelect: false,
  reload: false,
  touched: false,
  startTime: undefined,
  endTime: undefined,
  duration: undefined,

  activity: null,
  targetProject: null,
  searchValue: "",
  prevModalState: {
    showTaskPicker: false,
    showDatePicker: false,
    showTaskDetails: false,
  },

  startDate: null,
  endDate: null,
  runningActivityLoaded: false,
}

const store = (set, get) => ({
  ...initialStoreValues,
  locations: [],
  projects: [],
  timesheets: [],
  selectedEmployee: undefined,

  timesheetsLoaded: false,
  projectsLoaded: false,
  locationsLoaded: false,

  setShowTaskPicker: (val: boolean) => set(() => ({showTaskPicker: val})),
  setShowDatePicker: (val: boolean) => set(() => ({showDatePicker: val})),
  setShowTaskDetails: (val: boolean) => set(() => ({showTaskDetails: val})),
  setShowSelectProject: (val: boolean) => set(() => ({showSelectProject: val})),
  setShowCreateProject: (val: boolean) => set(() => ({showCreateProject: val})),
  setShowEmployeeSelect: (val: boolean) => set(() => ({showEmployeeSelect: val})),

  setRunningActivityLoaded: (val: boolean) => set(() => ({runningActivityLoaded: val})),

  setProjects: (projArray: ProjectOption[]) => set(() => ({
    projects: projArray.sort((a,b) => moment(a.project.createdAt).isBefore(moment(b.project.createdAt)) ? 1 : -1),
    projectsLoaded: true,
  })),
  setActivity: (activity: StoredActivity | null) => set(() => ({activity})),
  setTargetProject: (project: ProjRes | null) => set(() => ({ targetProject: project })),
  setSearchValue: (val: string) => set(() => ({ searchValue: val })),
  reloadProjects: (val: boolean) => set(() => ({ reload: val })),
  setTouched: (val: boolean) => set(() => ({ touched: val })),
  setTimesheets: (timesheets: TimesheetsListItem[]) => set(() => ({ timesheets, timesheetsLoaded: true })),
  setLocations: (locs: Location[]) => set(() => ({ locations: locs, locationsLoaded: true })),
  selectEmployee: (data: any) => set(() => ({selectedEmployee: data})),

  setEndDate: (val: moment.Moment | null) => set(() => ({endDate: val})),
  setStartTime: (val: number | undefined) => set(() => ({ startTime: val })),
  setEndTime: (val: number | undefined) => set(() => ({ endTime: val })),
  setStartDate: (val: moment.Moment | null) => set(() => ({ startDate: val })),
  setDuration: (val: number | undefined) => set(() => ({ duration: val })),
  initiateDateValues: () => {
    const {activity, touched} = get();
    const nowInMinutes = moment().diff(moment().startOf('day'), 'minutes');
    const start = activity?.date ? moment(activity.date) : moment();
    const startT = activity?.startTime ? activity.startTime : nowInMinutes;
    let endT = activity?.endTime ? activity.endTime : nowInMinutes;
    let end = start.clone();
    const dur = activity?.duration;
    if (activity?.duration) {
      end = start.clone().startOf('day').add(startT + activity.duration, 'minutes');
      endT = end.clone().diff(end.clone().startOf('day'), 'minutes');
    }
    const payload = {
      startDate: start,
      startTime: startT,
      endTime: endT,
      duration: dur,
      touched,
      endDate: end,
    }

    if (activity === null) {
      payload.touched = false;
    }
    set(() => (payload));
  },
  closeAllModals: () => set(() => ({
    showSelectProject: false,
    showTaskDetails: false,
    showDatePicker: false,
    showTaskPicker: false,
    showEmployeeSelect: false,
  })),
  changeDuration: (dur: string | null) => {
    if (!dur || dur === '00:00') {
      set(() => ({
        activity: {
          ...get().activity,
          startTime: undefined,
          endTime: undefined,
          duration: undefined,
          status: undefined,
        },
        touched: false,
      }));

      return "";
    }
    const parseTimeString = (time: string) => {
      if (time.match(/^(\d{1,5}):[0-5][0-9]$/)) {
        return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
      }
      return 0;
    };

    const {startDate, endDate, startTime, endTime, activity} = get();
    const d = parseTimeString(dur);
    let start: moment.Moment;
    let endT: number;
    let startT: number;

    const startWithDuration = startDate.clone().startOf('day').add(startTime, 'minutes').add(d, 'minutes');
    // this means that start + duration is after now and we need to use end - duration
    if (startWithDuration.isAfter(moment())) {
      start = endDate.clone().startOf('day').add(endTime, 'minutes').subtract(d, 'minutes');
      startT = start.clone().diff(start.clone().startOf('day'), 'minutes');
      endT = endTime
    } else {
      start = startDate;
      startT = startTime;
      endT = startWithDuration.clone().diff(startWithDuration.clone().startOf('day'), 'minutes');
    }

    set(() => ({
      activity: {
        ...activity,
        date: start!.format(CONFIG.apiDateFormat),
        startTime: startT!,
        endTime: endT!,
        duration: d,
        status: "pending",
      },
      startDate: start,
      startTime: startT,
      endTime: endT,
      duration: d,
    }));
    return dur;
  },
  loadTimesheets: async (companyUuid: string, userProfileUuid: string, requestedBy: string, force = false) => {
    if (get().timesheetsLoaded && !force) return get().timesheets;

    const req: ListTimesheetsRequest = {
      companyUuid,
      requestedBy,
      userProfileUuid,
      approvalStatus: [TimesheetApprovalStatus.approved, TimesheetApprovalStatus.pending],
      from: moment(get().activity?.date).subtract(6, "month").format(CONFIG.apiDateFormat),
      to: moment().format(CONFIG.apiDateFormat),
    };
    const { content } = await listTimesheets(req);
    set(() => ({timesheets: content, timesheetsLoaded: true}));
    return content;
  },
  clearStore: () => set(() => ({...initialStoreValues})),
});
export const useTrackTimeStore = create<TrackTimeStore>(store);
export const useActivityStore = create<TrackTimeStore>(store);
