import os from 'os'

function getPlatform() {
  const currentPlatform = os.platform()

  const isMac = currentPlatform === 'darwin'
  const isWindows = currentPlatform === 'win32'
  const isLinux = currentPlatform === 'linux'

  return { isMac, isLinux, isWindows }
}

export interface HostEnvironment {
  isBrowserExtension: boolean;
  isElectronApplication: boolean;
  isWebBrowser: boolean;
  isDesktop: boolean;
  isChrome: boolean;
  isMsEdge: boolean;
  isChromiumBrowser: boolean;
  isMac: boolean;
  isWindows: boolean;
  isLinux: boolean;
}

function checkBrowserExtension() {
  if (globalThis.chrome?.runtime?.id != null) return true;
  if ((globalThis as any).browser?.runtime?.id != null) return true;
  return false;
}

function checkElectronApplication() {
  const isElectronRendererProcess = globalThis.navigator?.userAgent.toLowerCase().indexOf("electron") > -1;
  return isElectronRendererProcess;
}

function checkChrome() {
  const isChromium = globalThis.chrome;
  const winNav = globalThis.navigator;
  const vendorName = winNav?.vendor;
  const isOpera = typeof globalThis.opr !== "undefined";
  const isIEedge = winNav?.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav?.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  }
  if (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  }
  return false;
}

function checkMsEdge() {
  const winNav = globalThis.navigator;
  const isIEedge = winNav?.userAgent.indexOf("Edg") > -1;
  return isIEedge;
}

function getHostEnvironment() {
  const isBrowserExtension = checkBrowserExtension();
  const isElectronApplication = checkElectronApplication();
  const isChrome = checkChrome();
  const hostEnv: HostEnvironment = {
    isBrowserExtension,
    isElectronApplication,
    isWebBrowser: !isBrowserExtension && !isElectronApplication,
    isDesktop: true,
    isChrome,
    isMsEdge: checkMsEdge(),
    isChromiumBrowser: !!globalThis.chrome,
    ...getPlatform(),
  };
  return hostEnv;
}

export const host = getHostEnvironment();
