import React, { Component } from "react";
import { getBRGroup, getCurrentCompany, updateBRGroupTolerance } from "utils/apiHelpers";
import { withTranslation } from "react-i18next";
import MaskedInput from "components/styled/MaskedInput";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import BEM from "utils/BEM";
import Select from "components/UI/FormSelect";
import NotificationRow from "components/NotificationRow";
import FieldWrapper from "components/UI/FieldWrapper";
import CheckboxControl from "components/UI/NewCheckbox";
import "styles/company-rules.scss";
import RichTooltip from "components/UI/RichTooltip";
import styled from "styled-components";
import Button from "components/controls/StyledButton";

const b = BEM.b("company-rules");
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    margin-inline-end: 10px;
  }
`;
class PunchingRulesGroupTolerance extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      active: false,
      toleranceLimit: "",
      tolerancePeriod: "punch",
      allowNegativeTolerance: false,
      isLoading: false,
      isLocked: false,
    };
    this.periods = [
      { value: "punch", label: t("Per Punch") },
      { value: "day", label: t("Daily Total") },
    ];
  }
  componentDidMount() {
    this.getState(this.props);
  }

  getState(props) {
    getCurrentCompany().then((company) => {
      const { id } = props;
      getBRGroup({ companyUuid: company.uuid, brGroupUuid: id }).then((r) => {
        let businessRules = {};
        if (r.content && r.content.businessRules.length) {
          businessRules = r.content.businessRules[0].rules.tolerance;
        } else {
          return;
        }
        this.setState({
          isLocked: r.content.isLocked,
          status: r.content.status,
          includeBreaks: businessRules.includeBreaks || false,
          allowNegativeTolerance: businessRules.allowNegativeTolerance || false,
          tolerancePeriod: businessRules.scope || "punch",
          toleranceLimit: businessRules.limit || "",
          active: businessRules.active,
        });
      });
    });
  }

  onActiveChange = (checked) => {
    this.setState({ active: checked });
  };
  getFormErrors() {
    const { t } = this.props;
    const { toleranceLimit, active } = this.state;
    let errors = {};
    if (active && !toleranceLimit) {
      errors.toleranceLimit = t("Tolerance limit can't be empty");
    } else if (active && parseInt(toleranceLimit) > 300) {
      errors.toleranceLimit = t("Tolerance limit can't be more than 300 minutes");
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }
  onNextClick = () => {
    const { active, includeBreaks, allowNegativeTolerance, toleranceLimit, tolerancePeriod } = this.state;
    const { t } = this.props;
    const errors = this.getFormErrors();
    if (!errors) {
      this.setState({ errors, isLoading: true });
      const { id } = this.props;

      updateBRGroupTolerance({
        companyUuid: window.global_store.company.uuid,
        brGroupUuid: id,
        body: {
          content: {
            active,
            limit: parseInt(toleranceLimit) || 0,
            scope: tolerancePeriod || "punch",
            includeBreaks: includeBreaks || false,
            allowNegativeTolerance: allowNegativeTolerance || false,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      })
        .then((r) => {
          this.setState({ isLoading: false });
          // this.getState(this.props);
          this.props.onSave &&
            this.props.onSave({
              notificationType: "success",
              notification: t("Group has been updated!"),
            });
        })
        .catch((e) => {
          window.scrollTo(0, 0);
          this.props.onSave &&
            this.props.onSave({
              notificationType: "error",
              notification: `${t(e.message)}${
                e.originalRequest && e.originalRequest.errors ? `: ${e.originalRequest.errors[0].message}` : ""
              }`,
            });
        });
    } else {
      this.setState({ errors });
    }
  };
  render() {
    const {
      isLoading,
      active,
      isLocked,
      status,
      toleranceLimit,
      tolerancePeriod,
      includeBreaks,
      allowNegativeTolerance,
      errors,
    } = this.state;

    const groupDisabled = status !== "active";
    const groupLocked = isLocked;
    const inactive = groupDisabled || groupLocked;
    const mask = createNumberMask({
      prefix: "",
      suffix: "m",
    });
    const { t } = this.props;
    return (
      <div className={b()}>
        {groupLocked ? (
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type="locked"
            style={{ marginTop: 0, marginBottom: "25px" }}
            message={
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  color: "var(--colors-mainText)",
                }}
              >
                {t(
                  "Editing of this Rules Group is locked. For any changes please contact support or create a new set of rules.",
                )}
              </div>
            }
          />
        ) : null}
        <div>
          <div className={b("settings")}>
            {/* <div className={b('activate')}>
              <CheckboxControl
                checked={active}
                disabled={inactive}
                id="company-settings-ns"
                onChange={this.onActiveChange.bind(this)}
              />
              <label htmlFor="company-settings-ns" className={b('label')}>
                {active ? t('Deactivate tolerance') : t('Activate tolerance')}
              </label>
            </div> */}
            <FieldWrapper fieldTitleMarginTop={0} tooltipText={t("tolerance-activate-tooltip-text")}>
              <CheckboxWrapper>
                <CheckboxControl
                  ios
                  checked={active}
                  disabled={inactive}
                  label={active ? t("Deactivate tolerance") : t("Activate tolerance")}
                  onChange={this.onActiveChange}
                />
                <RichTooltip text={t("tolerance-activate-tooltip-text")} />
              </CheckboxWrapper>
            </FieldWrapper>
            <div>
              <div className={b("field", ["limit-fields"])}>
                <div className={b("limit-field")}>
                  <div className={b("field-title")}>{t("Tolerance limit")}</div>
                  <MaskedInput
                    disabled={groupDisabled || groupLocked || !active}
                    locked={active && !groupDisabled && groupLocked}
                    error={errors && !!errors.toleranceLimit}
                    placeholder={t("Minutes")}
                    type="number"
                    value={toleranceLimit}
                    mask={mask}
                    onChange={(val) => {
                      this.setState({
                        toleranceLimit: parseInt(val.target.value) || "",
                      });
                    }}
                  />
                </div>
                <div className={b("limit-field")}>
                  <div className={b("field-title")}>{t("Period")}</div>
                  <Select
                    disabled={groupDisabled || !active}
                    locked={active && !groupDisabled && groupLocked}
                    modifiers={{ field: true }}
                    value={tolerancePeriod}
                    onChange={(tolerancePeriod) => this.setState({ tolerancePeriod })}
                    options={this.periods}
                  />
                </div>
              </div>
              {errors && errors.toleranceLimit && <div className={b("error")}>{errors.toleranceLimit}</div>}
              <FieldWrapper>
                <CheckboxControl
                  checked={includeBreaks}
                  disabled={inactive || !active}
                  label={t("Include Breaks in which case we check them specifically for the calculation.")}
                  onChange={(checked) => {
                    this.setState({ includeBreaks: checked });
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <CheckboxControl
                  checked={allowNegativeTolerance}
                  disabled={inactive || !active}
                  label={t("Allow negative tolerance")}
                  onChange={(checked) => {
                    this.setState({ allowNegativeTolerance: checked });
                  }}
                />
              </FieldWrapper>
            </div>
            <br />
          </div>
          {!inactive ? (
            <div style={{ width: "132px", marginTop: "35px" }}>
              <Button state="primary" loading={isLoading} onClick={this.onNextClick} value={t("Save")} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withTranslation("company-rules")(PunchingRulesGroupTolerance);
