import { PropsWithChildren, HTMLAttributes } from "react";
import styled from "styled-components";

const ErrorLabel = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-danger-600-p);
  margin-top: 7px;
  position: relative;
  padding-inline-start: 20px;

  &:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23DA3837' d='M7 .333C3.333.333.333 3.333.333 7s3 6.667 6.667 6.667 6.667-3 6.667-6.667S10.667.333 7 .333Zm0 10c-.4 0-.667-.266-.667-.666S6.6 9 7 9s.667.267.667.667-.267.666-.667.666ZM7.667 7c0 .4-.267.667-.667.667S6.333 7.4 6.333 7V4.333c0-.4.267-.666.667-.666s.667.266.667.666V7Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    position: absolute;
    inset-inline-start: 0px;
  }
`;

interface ErrorLabelProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {}

export default ({ children, ...props }: ErrorLabelProps): JSX.Element => <ErrorLabel {...props}>{children}</ErrorLabel>;
