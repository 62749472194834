/* eslint-disable no-underscore-dangle */
import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import FullPage from "components/Layout/FullPage";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import StatusBadge from "components/controls/StatusBadge";
import Select from "components/UI/Select";
import { PageWrapper } from "components/styled/Page";
import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import TableButtonsControl from "components/styled/TableButtonsControl";
import { GroupNameWrapper } from "components/styled/TableComponents";
import { getTitle, strIncludesCheck } from "utils/common";
import { getLockGroupsList, toggleGroupStatus, makeDefaultPayrollGroup } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { iCellInfo } from "utils/tableHelpers";
import {
  PayrollGroup,
  PayrollGroupRecurrence,
  PayrollGroupRecurrenceFreq,
  PayrollGroupStatus,
  TogglePayrolGroupAction,
} from "types/models/payrollGroup";
import { iColumn } from "components/TableCommon";
import { ButtonState } from "components/controls/StyledButton";
import GlobalContext from "context/global-context";

const TITLE = "Payroll Groups";
const META_TITLE = TITLE;

const StatusFilterWrapper = styled.div`
  margin-inline-start: 15px;
  width: 120px;
`;

interface PayrollGroupsProps extends WithTranslation, RouteComponentProps {}

interface PayrollGroupsState {
  items: PayrollGroup[];
  selectedItem: PayrollGroup | null;
  notification: string;
  searchValue: string;
  searchStatus: PayrollGroupStatus | "";
  isFetching: boolean;
  warningDefaultGroupPopupVisible: boolean;
}

class PayrollGroups extends Component<PayrollGroupsProps, PayrollGroupsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: PayrollGroupsProps) {
    super(props);
    const { t } = props;

    this.state = {
      items: [],
      selectedItem: null,
      notification: "",
      searchValue: "",
      searchStatus: PayrollGroupStatus.active,
      isFetching: false,
      warningDefaultGroupPopupVisible: false,
    };

    document.title = getTitle(t(META_TITLE));
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true });

      const company = await this.context.getCompany();
      const r = await getLockGroupsList({
        companyUuid: company.uuid,
      });

      this.setState({
        items: r.content || r || [],
        isFetching: false,
      });
    }
  };

  onToggleActiveClick = async (item: PayrollGroup, newStatus: TogglePayrolGroupAction) => {
    await toggleGroupStatus({
      companyUuid: window.global_store.company.uuid,
      payrollGroupUuid: item.uuid,
      status: newStatus,
    });

    const company = await this.context.getCompany();
    const r = await getLockGroupsList({
      companyUuid: company.uuid,
    });

    this.setState({
      items: r.content || r || [],
      isFetching: false,
    });
  };

  onMakeDefault = async (selectedItem: PayrollGroup) => {
    const { t } = this.props;

    const body = {
      content: {
        updatedBy: window.global_store.profile.uuid,
      },
    };

    await makeDefaultPayrollGroup({
      companyUuid: window.global_store.company.uuid,
      payrollGroupUuid: selectedItem.uuid,
      body,
    });

    this.setState(
      {
        warningDefaultGroupPopupVisible: false,
        selectedItem: null,
        notification: t("You changed default group"),
      },
      () => this.getState(),
    );
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableButtons = (row: iCellInfo<PayrollGroup, PayrollGroupStatus>) => {
    const { t } = this.props;
    const buttons = [];

    if (!row.original.isDefault) {
      if (row.value === PayrollGroupStatus.active) {
        buttons.push({
          label: t("common|Deactivate"),
          onClick: () => this.onToggleActiveClick(row.original, TogglePayrolGroupAction.deactivate),
        });

        buttons.push({
          label: t("common|Make default"),
          onClick: () => {
            this.setState({
              warningDefaultGroupPopupVisible: true,
              selectedItem: row.original,
            });
          },
        });
      }

      if (row.value === PayrollGroupStatus.deactivated) {
        buttons.push({
          label: t("common|Activate"),
          onClick: () => this.onToggleActiveClick(row.original, TogglePayrolGroupAction.activate),
        });
      }
    }

    return buttons;
  };

  getTableColumns = (): iColumn<PayrollGroup>[] => {
    const { t } = this.props;

    const getRecurrence = (val: number) => {
      if (val === 31) {
        return t("The last day of month");
      }
      return val;
    };

    return [
      {
        label: t("Pay day group"),
        accessor: "name",
        minWidth: 160,
        Cell: (row: iCellInfo<PayrollGroup>) => (
          <GroupNameWrapper isLocked={row.original.isLocked}>
            {row.value === "default" ? t("common|default") : row.value}{" "}
            {row.original.isDefault ? `(${t("common|default")})` : ""}
          </GroupNameWrapper>
        ),
        style: { lineHeight: "36px" },
      },
      {
        label: t("common|Employees"),
        accessor: "userProfilePayrollGroups",
        Cell: (row: iCellInfo<PayrollGroup>) => (row.value ? row.value.length : 0),
        width: 160,
      },
      {
        label: t("Period"),
        accessor: "recurrence",
        Cell: (row: iCellInfo<PayrollGroup, PayrollGroupRecurrence>) => {
          if (row.value) {
            if (row.value.freq === PayrollGroupRecurrenceFreq.weekly) {
              return t("Weekly");
            }
            if (row.value.bymonthday?.length) {
              return row.value.bymonthday.length === 2 ? t("Twice a Month") : t("Monthly");
            }
          }

          return "";
        },
        width: 160,
      },
      {
        label: t("Day"),
        accessor: "recurrence",
        Cell: (row: iCellInfo<PayrollGroup, PayrollGroupRecurrence>) => {
          if (row.value) {
            if (row.value.freq === PayrollGroupRecurrenceFreq.weekly) {
              return t("Every Sunday");
            }

            if (row.value.bymonthday?.length) {
              return row.value.bymonthday.length === 2
                ? `${getRecurrence(row.value.bymonthday[0] as number)} - ${getRecurrence(
                    row.value.bymonthday[1] as number,
                  )}`
                : getRecurrence(row.value.bymonthday[0] as number);
            }
          }

          return "";
        },
        width: 160,
      },
      {
        label: t("common|Status"),
        accessor: "status",
        minWidth: 50,
        align: "center",
        Cell: (row: iCellInfo<PayrollGroup>) => (
          <div style={{ maxWidth: "100px" }}>
            <StatusBadge value={t(row.value)} type={row.value} />
            <TableButtonsControl visibleButtons={this.getTableButtons(row)} />
          </div>
        ),
      },
    ];
  };

  render() {
    const {
      items,
      searchValue,
      searchStatus,
      isFetching,
      notification,
      warningDefaultGroupPopupVisible,
      selectedItem,
    } = this.state;
    const { t } = this.props;
    const actionButtonTitle = "New Group";
    const noItemsTitle = "No Groups are available";

    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.name, searchValue);
      if (searchStatus) {
        filter = filter && s.status === searchStatus;
      }
      return filter;
    });

    return (
      <FullPage
        title={t(`${TranslationNamespaces.sidebar}|Payroll`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(actionButtonTitle)}
            onClick={() => this.props.history.push("/payroll/payroll-groups/new-group")}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton={false}
              type={NotificationType.success}
              message={notification}
            />
          )}

          <TablePage<PayrollGroup>
            rows={filteredItems}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search")}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select<PayrollGroupStatus | "">
                    value={searchStatus}
                    onChange={(val) => this.setState({ searchStatus: val })}
                    options={[
                      { value: "", label: t("common|Status") },
                      { value: PayrollGroupStatus.active, label: t("common|Active") },
                      { value: PayrollGroupStatus.deactivated, label: t("common|Deactivated") },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            columns={this.getTableColumns()}
            className="groups-table"
            loading={isFetching}
            getTrProps={(_, rowInfo) => ({
              onClick: (e: KeyboardEvent) => {
                if (rowInfo?.row._original.uuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/payroll/payroll-groups/${rowInfo.row._original.uuid}`,
                    }).click();
                  } else {
                    this.props.history.push(`/payroll/payroll-groups/${rowInfo.row._original.uuid}`);
                  }
                }
              },
            })}
            noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          />

          <ModalDialog
            isOpen={warningDefaultGroupPopupVisible}
            onClose={() => this.setState({ warningDefaultGroupPopupVisible: false })}
          >
            <Lightbox
              title={t("Make group default?")}
              text={t("Are you sure you want to make this group default?")}
              buttonYesTitle={t("common|Confirm")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({
                  selectedItem: null,
                  warningDefaultGroupPopupVisible: false,
                });
              }}
              onYes={() => {
                void this.onMakeDefault(selectedItem as PayrollGroup);
              }}
            />
          </ModalDialog>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withRouter(
  withTranslation([TranslationNamespaces.payment, TranslationNamespaces.sidebar])(PayrollGroups),
);
