import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import React from "react";

const LoadingMessage = styled.div`
  font-size: 15px;
  margin-top: 12px;
  color: var(--colors-surface-900-p);
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  * {
    transition: none;
  }
  svg circle {
    stroke: var(--colors-primary-500-p);
  }
`;

export default function Loader () {
  const { t } = useTranslation(TranslationNamespaces.common);
  return (
    <Wrapper>
      <Spinner size="xlarge" />
      <LoadingMessage>{t("Loading details...")}</LoadingMessage>
    </Wrapper>
  );
}
