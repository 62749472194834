import CheckboxControl from "components/UI/NewCheckbox";
import styled from "styled-components";
import { PermissionSection } from "types/models/permissions";
import { ListItem } from "./ListItem";
import TogglePermissionActions from "./TogglePermissionActions";

const SectionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  .checkbox-oxs-conrtrol__wrapper {
    top: auto;
    margin-inline-end: 11px;
  }
`;

type SectionItemProps = {
  checked: boolean;
  onChange: (permission: PermissionSection) => void;
  label: string;
  disabled: boolean;
  permission: PermissionSection;
};

const SectionItem = ({ checked, onChange, label, disabled, permission }: SectionItemProps) => (
  <SectionItemWrapper>
    <CheckboxControl
      ios
      small
      checked={checked}
      disabled={disabled}
      label={<ListItem $notExpandable>{label}</ListItem>}
      onChange={(val) =>
        onChange({
          ...permission,
          allow: val,
        })
      }
    />
    {checked && !!permission.permission_actions.length && (
      <TogglePermissionActions permission={permission} onChange={onChange} />
    )}
  </SectionItemWrapper>
);

export default SectionItem;
