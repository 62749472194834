import moment from "moment";
import { ApplicationType } from "types/models/application";
import { UserProfile } from "types/models/userProfile";

import {
  ampli,
  AppilcationReloadProperties,
  OnCallProperties,
  CreateOnCallProperties,
  ApproveOnCallProperties,
  DeclineOnCallProperties,
  FilterProperties,
  TopBarCtaProperties,
  ExpiredScreenProperties,
  ExpiredScreenCtaProperties,
  Ampli,
  DashboardProperties,
  SuperPunchProperties,
  EventOptions,
  CreateCompanySuccessProperties,
} from "./tracking-library";

export enum Sources {
  "clientAdmin" = "clientAdmin",
  "desktop" = "desktop",
  "browserExtension" = "browserExtension",
}

export enum Screens {
  SuperPunch = "SuperPunch",
  LoginPage = "LoginPage",
  Punches = "Punches",
  Dashboard = "Dashboard",
}

export enum EventSources {
  topMenu = "top-menu",
  detailedReport = "Detailed Report",
}

export interface AmplitudeOptions {
  isIncognitoMode: boolean;
  environment: "production" | "development";
  source: Sources;
}

/**
 * Instead of adding a method into `Amplitude` class expicitly add a single `Ampli` method name here.
 * The error will appear at `MappedAmpli` type if the line is not a correct `Ampli` method name.
 */
const methodsToMap = [
  "punchScreen",
  "punchErrorLightbox",
  "punchChangeLocation",
  "punchChangeCategory",
  "punchRequestSucess",
  "punchSuccess",

  "tsheetScreen",
  "tsheetErrorLightbox",
  "tsheetStartTracking",
  "tsheetStop",
  "tsheetExpandView",
  "tsheetViewDetails",
  "tsheetDelete",
  "tsheetChangeTask",
  "tsheetChangeLocation",
  "tsheetChangeDate",
  "tsheetUpdate",
  "tsheetAddTime",
] as const;
type MethodsToMap = typeof methodsToMap[number];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TakeFirst<T extends unknown[]> = T extends [infer U, ...any[]] ? U : never;
type MappedAmpli = {
  [M in keyof Pick<Ampli, MethodsToMap>]: (
    properties: Omit<TakeFirst<Parameters<Ampli[M]>>, "source">,
  ) => ReturnType<Ampli[M]>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defineDynamicClass = (): { new (): MappedAmpli } => Object as any;

export class Amplitude extends defineDynamicClass() {
  source: Sources;
  isIncognitoMode = true;
  environment: AmplitudeOptions["environment"];

  constructor(options: AmplitudeOptions) {
    super();
    this.source = options.source;
    this.isIncognitoMode = options.isIncognitoMode;
    this.environment = options.environment;
    this.createMappedMethods();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async callOriginalMethod(methodName: MethodsToMap, eventProperties: any) {
    if (this.isIncognitoMode) return undefined;
    return ampli[methodName]({ ...eventProperties, source: this.source });
  }

  /**
   * Names of the methods could be prefixed and `MappedAmpli` type could be fixed accordingly.
   * But then ctrl+click navigation is lost which is inconvenient.
   */
  protected createMappedMethods() {
    const ownKeys = Object.getOwnPropertyNames(this.constructor.prototype);
    for (const key of methodsToMap) {
      const doKeysCollide = ownKeys.includes(key);
      if (doKeysCollide) continue;
      const newKey = key as keyof MappedAmpli;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[newKey] = (...args: any[]) => {
        console.log("aplitude call");
        // this.callOriginalMethod(key, ...args);
      };
    }
  }

  init() {
    if (this.isIncognitoMode) return;
    ampli.load({ environment: this.environment });
  }

  identify(profile: UserProfile) {
    if (this.isIncognitoMode) return;
    ampli.identify(`${profile.id}`, {
      CompanyID: `${profile.company.id}`,
      Permission: profile?.permission_roles[0]?.name || "missing",
      UserProfileID: `${profile.id}`,
      UserUuid: profile.uuid,
      AssociateProducts: profile.associate_products.join(", ") || undefined,
    });
  }

  trackAppilcationReload(eventProperties: Omit<AppilcationReloadProperties, "source">) {
    if (this.isIncognitoMode) return;
    ampli.appilcationReload({ ...eventProperties, source: this.source });
  }

  trackLogin() {
    if (this.isIncognitoMode) return;
    ampli.login({
      source: this.source,
    });
  }

  trackForgotPassword() {
    if (this.isIncognitoMode) return;
    ampli.loginForgot({
      source: this.source,
    });
  }

  trackLoginSuccess() {
    if (this.isIncognitoMode) return;
    ampli.loginSuccess({
      source: this.source,
      screen: Screens.LoginPage,
      screenPrevious: Screens.LoginPage,
    });
  }

  trackLoginWithSso() {
    if (this.isIncognitoMode) return;
    ampli.loginSso({
      source: this.source,
    });
  }

  trackSuperPunchView(screenPrevious: string, superPunchLevel: SuperPunchProperties["superPunchLevel"]) {
    if (this.isIncognitoMode) return;
    ampli.superPunch({
      screen: Screens.SuperPunch,
      source: this.source,
      screenPrevious,
      superPunchLevel,
    });
  }

  trackFilterOnlyIssues() {
    if (this.isIncognitoMode) return;
    ampli.filterOnlyIssues({
      source: this.source,
    });
  }

  trackSuperpunchClearPunch() {
    if (this.isIncognitoMode) return;
    ampli.clearPunch({
      source: this.source,
    });
  }

  trackSuperpunchCreatePunch() {
    if (this.isIncognitoMode) return;
    ampli.createPunch({
      source: this.source,
    });
  }

  trackSuperpunchApprovePunch() {
    if (this.isIncognitoMode) return;
    ampli.approvePunch({
      source: this.source,
    });
  }

  trackSuperpunchValidatePunch() {
    if (this.isIncognitoMode) return;
    ampli.validatePunch({
      source: this.source,
    });
  }

  trackSuperpunchDeclinePunch() {
    if (this.isIncognitoMode) return;
    ampli.declinePunch({
      source: this.source,
    });
  }

  trackSuperpunchViewPunchDetails() {
    if (this.isIncognitoMode) return;
    ampli.viewPunchDetails({
      source: this.source,
    });
  }

  trackSuperpunchChangePunchCategory(punchCategory: string) {
    if (this.isIncognitoMode) return;
    ampli.changePunchCategory({
      source: this.source,
      punchCategory,
    });
  }

  trackSuperpunchSearchNextEmployee() {
    if (this.isIncognitoMode) return;
    ampli.searchNextEmployee({
      source: this.source,
    });
  }

  trackSuperpunchSearchSelectEmployee(value: string) {
    if (this.isIncognitoMode) return;

    ampli.searchSelectEmployee({
      source: this.source,
      value,
    });
  }

  trackSuperpunchSearchSelectGroup(value: string) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.searchSelectGroup({
      source: this.source,
      value,
    });
  }

  trackSuperpunchSelectAll() {
    if (this.isIncognitoMode) return;
    ampli.selectAll({
      screen: Screens.SuperPunch,
      source: this.source,
    });
  }

  trackMultipleActionsClearPunch() {
    if (this.isIncognitoMode) return;
    ampli.multipleActionsClearPunch({
      source: this.source,
    });
  }

  trackMultipleActionsApprove() {
    if (this.isIncognitoMode) return;
    ampli.multipleActionsApprove({
      source: this.source,
    });
  }

  trackMultipleActionsOrganizeChronologically() {
    if (this.isIncognitoMode) return;
    ampli.multipleActionsOrganizeChronologically({
      source: this.source,
    });
  }

  trackMultipleActionsValidateAll() {
    if (this.isIncognitoMode) return;
    ampli.multipleActionsValidateAll({
      source: this.source,
    });
  }

  trackMultipleActionsClearSchedule() {
    if (this.isIncognitoMode) return;
    ampli.multipleActionsClearSchedule({
      source: this.source,
    });
  }

  trackClearScheduleException() {
    if (this.isIncognitoMode) return;
    ampli.clearScheduleException({
      source: this.source,
    });
  }

  trackEnableDay() {
    if (this.isIncognitoMode) return;
    ampli.enableDay({
      source: this.source,
    });
  }

  trackRecalculate() {
    if (this.isIncognitoMode) return;
    ampli.recalculate({
      source: this.source,
    });
  }

  // todo
  trackVisitOnCall(props: Omit<OnCallProperties, "source" | "screen">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.onCall({
      source: this.source,
      screen: "OnCall",
      screenPrevious: props.screenPrevious,
    });
  }

  trackViewOnCallDetails() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.viewOnCallDetails({
      source: this.source,
    });
  }

  trackCreateOnCall(props: Omit<CreateOnCallProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createOnCall({
      source: this.source,
      ...props,
    });
  }

  trackDeleteOnCall() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.deleteOnCall({
      source: this.source,
    });
  }

  trackApproveOnCall(props: Omit<ApproveOnCallProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.approveOnCall({
      source: this.source,
      ...props,
    });
  }

  trackDeclineOnCall(props: Omit<DeclineOnCallProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.declineOnCall({
      source: this.source,
      ...props,
    });
  }

  trackFilter(props: Omit<FilterProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.filter({
      source: this.source,
      ...props,
    });
  }

  trackTsheetAddNewTask() {
    if (this.isIncognitoMode) return;
    ampli.tsheetAddNewTask({
      source: this.source,
    });
  }

  tsheetAddTaskActivity() {
    if (this.isIncognitoMode) return;
    ampli.tsheetAddTaskActivity({
      source: this.source,
    });
  }

  tsheetChangeDateRange() {
    if (this.isIncognitoMode) return;
    ampli.tsheetChangeDateRange({
      source: this.source,
    });
  }

  tsheetDeleteTaskRow() {
    if (this.isIncognitoMode) return;
    ampli.tsheetDeleteTaskRow({
      source: this.source,
    });
  }

  tsheetInputTaskDuration() {
    if (this.isIncognitoMode) return;
    ampli.tsheetInputTaskDuration({
      source: this.source,
    });
  }

  tsheetMoveDateRange() {
    if (this.isIncognitoMode) return;
    ampli.tsheetMoveDateRange({
      source: this.source,
    });
  }

  tsheetSelectEmployee() {
    if (this.isIncognitoMode) return;
    ampli.tsheetSelectEmployee({
      source: this.source,
    });
  }

  tsheetTimesheetTab() {
    if (this.isIncognitoMode) return;
    ampli.tsheetTimesheetTab({
      source: this.source,
    });
  }

  trackTopBar(props: EventOptions) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.topBar({
      ...props,
    });
  }

  trackTopBarCTA(props: Omit<TopBarCtaProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.topBarCta({
      source: this.source,
      ...props,
    });
  }

  trackExpiredScreen(props: Omit<ExpiredScreenProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.expiredScreen({
      source: this.source,
      ...props,
    });
  }

  trackExpiredScreenCTA(props: Omit<ExpiredScreenCtaProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.expiredScreenCta({
      source: this.source,
      ...props,
    });
  }

  trackDashboardPageVisit(props: Omit<DashboardProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.dashboard({
      source: this.source,
      ...props,
    });
  }

  trackDashboardLoadingTime = function* () {
    if (this.isIncognitoMode) {
      return;
    }

    const startTime = moment.now();
    yield;
    const duration = `${(moment.now() - startTime) / 1000}`;

    if (window.global_store.beta) {
      console.log(`trackDashboardLoadingTime: ${duration} sec.`);
    }

    ampli.loading({
      source: this.source,
      duration,
    });
  };

  trackSPLoadingTime = function* () {
    if (this.isIncognitoMode) {
      return;
    }

    const startTime = moment.now();
    yield;
    const duration = `${(moment.now() - startTime) / 1000}`;

    if (window.global_store.beta) {
      console.log(`trackSPLoadingTime: ${duration} sec.`);
    }

    ampli.spLoading({
      source: this.source,
      duration,
    });

    yield;
  };

  trackApplicationsSecretGeneration() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.generateClientSecret({
      source: this.source,
    });
  }

  trackCreateAccount() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createAccount({
      source: this.source,
    });
  }

  trackCreateAccountSucess() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createAccountSucess({
      source: this.source,
    });
  }

  trackCreateAccountFail(error: string) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createAccountFail({
      source: this.source,
      error,
    });
  }

  trackCreateCompany() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createCompany({
      source: this.source,
    });
  }

  trackCreateCompanySuccess(eventData: Omit<CreateCompanySuccessProperties, "source">) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createCompanySuccess({
      ...eventData,
      source: this.source,
    });
  }

  trackCreateCompanyFail(error: string) {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.createCompanyFail({
      source: this.source,
      error,
    });
  }

  trackSelectPlan() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.selectPlan({
      source: this.source,
    });
  }

  trackSelectPlanSuccess(products: "TA" | "TS" | "TA, TS") {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.selectPlanSuccess({
      source: this.source,
      products,
    });
  }

  trackSelectIntegration() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.selectIntegration({
      source: this.source,
    });
  }

  trackSelectIntegrationSkip() {
    if (this.isIncognitoMode) {
      return;
    }

    ampli.selectIntegrationSkip({
      source: this.source,
    });
  }

  trackSelectIntegrationSuccess(integrationName: ApplicationType) {
    if (this.isIncognitoMode) {
      return;
    }

    const integration = {
      [ApplicationType.Asana]: "Asana",
      [ApplicationType.Todoist]: "Todoist",
      [ApplicationType.Trello]: "Trello",
      [ApplicationType.Notion]: "Notion",
      [ApplicationType.Jira]: "Jira",
      [ApplicationType.Clickup]: "clickUp",
      [ApplicationType.Basecamp]: "Basecamp",
      [ApplicationType.Monday]: "Monday",
    }[integrationName];

    ampli.selectIntegrationSuccess({
      source: this.source,
      integration,
    });
  }
}
