import { iDateCellData } from "utils/reportsHelpers";
import { BusinessRuleCrossShiftsPhase, ReportDetailedActivePhase } from "types/models/businessRulesGroup";
import { DetailedReportDigitalSignature } from "../digitalSignature";
import { PunchStatuses, PunchType, PunchValidation } from "../punches";
import { RequestApprovalFlowStatus, RequestHoursCalculationType, RequestTypeName } from "../request";
import { ReportBreakType } from "./common";

// TODO not full
enum ReportDetailedDayStatus {
  completed = "completed",
}

// TODO not full
enum ReportDetailedReportStatus {
  pending = "pending",
  completed = "completed",
}

// TODO not full
enum ReportDetailedObservation {
  custom = "custom",
}

type ReportDetailedDay = {
  date: string;
  status: ReportDetailedDayStatus;
  weekend: boolean;
  holiday: string | null;
  schedule: { name: string; uuid: string } | null;
  /** TODO not full  */
  scheduleException: { name: string } | null;
  observation: ReportDetailedObservation | null;
  requests:
    | {
        paid: boolean;
        type: RequestTypeName;
        uuid: string;
        payed: boolean;
        allDay: boolean;
        endDate: string;
        timezone: string;
        upToDate: boolean;
        startDate: string;
        externalId: string | null;
        requestType: RequestTypeName;
        originalPaid: boolean;
        approvalStatus: RequestApprovalFlowStatus;
        requestSubtypeName: string;
        requestSubtypeUuid: string;
        hoursCalculationType: RequestHoursCalculationType;
        requestSubtypeTranslationKey: string;
      }[]
    | null;
  businessRulesGroup: {
    uuid: string;
    name: string;
  };
  missedDay: boolean;
  plannedMinutes: number;
  plannedBreakMinutes: number;
  workedMinutes: number;
  breakMinutes: number;
  nightMinutes: number;
  lateEntryMinutes: number;
  earlyLeaveMinutes: number;
  onCallMinutes: {
    onCall: number;
    activated: number;
    reducingActivated: number;
  };
  missedMinutesBeforeCompensation: number;
  missedMinutes: number;
  nightShiftMinutes: Record<string, number>;
  nightReducedMinutes: number;
  medicalAbsenceMinutes: number;
  nightShiftOvertimeMinutes: number;
  activitiesPerProject: unknown[]; // TODO
  activitiesPerService: unknown[]; // TODO
  workingMinutesExcludingActivities: number;
  retroactiveActivitiesMinutes: number;
  unverifiedLocationActivitiesMinutes: number;
  lateArrivalsWithoutBreaks?: unknown | null; // TODO
  earlyLeavesWithoutBreaks?: unknown | null; // TODO
  totalLateArrivals: number | null;
  totalEarlyLeaves: number | null;
  extraHoursMinutes: number;
  crossShiftsIntervalPhases: { [uuid: string]: number };
  extraHoursPhases: { [uuid: string]: number };
  actualDayBreaksByTypes: ReportBreakType[];
  actualNightBreaksByTypes: ReportBreakType[];
  plannedDayBreakByTypes: ReportBreakType[];
  plannedNightBreakByTypes: ReportBreakType[];
  hoursBankPhases: { negative: number } & { [uuid: string]: number };
  cumulativeHoursBankPhases: { negative: number } & { [uuid: string]: number };
  hoursBankPositiveMinutes: number;
  hoursBankNegativeMinutes: number;
  hoursBankMinutes: number;
  cumulativeHoursBankMinutes: number;
  hoursBankExpiredPhases: Record<string, never | unknown>; // TODO
  hoursBankExpiredMinutes: number;
  hoursBankOverLimitPhases: { negative: number } & { [uuid: string]: number };
  hoursDistribution: {
    minutes: number;
    uuid: string;
  }[];
  hoursBankOverLimitMinutes: number;
  debitMinutes: number;
  unusedBreakMinutes: number;
  adjustments: unknown[]; // TODO
  punches: {
    /** e.g. entry0 */
    key: string;
    description: PunchType;
    time?: string;
    timezone: string;
    uuid: string;
    type: PunchType;
    manual: boolean;
    status: PunchStatuses;
    validation: PunchValidation;
    minutes: number;
    lateMinutes: number;
    earlyMinutes: number;
    locationId: number;
    breakTypeUuid?: string;
  }[];
  crossShiftsInterval: {
    diffMinutes: number;
  };
};

type ReportDetailedContent = {
  employeeInfo: {
    id: number;
    uuid: string;
    fullName: string;
    avatarId: string | null;
    defaultTimeZone: string;
    cpf: string;
    pis: string;
    matricula: string;
    department: unknown | null; // TODO
    subsidiaryCnpj: string | null;
    supervisorFullName: string | null;
    positionTitle: string | null;
    active: boolean;
  };
  dates: ReportDetailedDay[];
  totalPlannedMinutes: number;
  totalWorkedMinutes: number;
  totalMissedMinutes: number;
  totalMissedMinutesBeforeCompensation: number;
  totalBreakMinutes: number;
  totalNightMinutes: number;
  totalNightReducedMinutes: number;
  totalNightShiftMinutes: Record<string, number>;
  totalCrossShiftsIntervalDiffMinutes: number;
  totalCrossShiftsIntervalDiffMinutesPhases: { [uuid: string]: number };
  totalDebitMinutes: number;
  totalUnusedBreakMinutes: number;
  totalLateEntryMinutes: number;
  totalEarlyLeaveMinutes: number;
  totalExtraHoursMinutes: number;
  totalExtraHoursPhases: { [uuid: string]: number };
  totalHoursBankPhases: { negative: number } & { [uuid: string]: number };
  totalHoursBankNegativeMinutes: number;
  totalHoursBankPositiveMinutes: number;
  totalHoursBankMinutes: number;
  totalMedicalAbsenceMinutes: number;
  totalNightShiftOvertimeMinutes: number;
  missedDays: number;
  workedDays: number;
  workedHolidays: number;
  totalWorkedInHolidayMinutes: number;
  totalOnCallMinutes: {
    onCall: number;
    activated: number;
    reducingActivated: number;
  };
  lateArrivalsWithoutBreaks: number;
  earlyLeavesWithoutBreaks: number;
  totalLateArrivals: number;
  totalEarlyLeaves: number;
  dsr: number;
  dsrWorkingDays: number;
  totalActivitiesPerService: Record<string, never | unknown>; // TODO
  totalWorkingMinutesExcludingActivities: number;
  totalRetroactiveActivitiesMinutes: number;
  totalUnverifiedLocationActivitiesMinutes: number;
  digitalSignatureMetadata: DetailedReportDigitalSignature;
  actualDayBreaksByTypes: ReportBreakType[];
  actualNightBreaksByTypes: ReportBreakType[];
  plannedDayBreakByTypes: ReportBreakType[];
  plannedNightBreakByTypes: ReportBreakType[];
  totalHoursDistribution: Record<
    string,
    {
      minutes: number;
      uuid: string;
    }
  >;
};

type ReportDetailedMetadata = {
  startDate: string;
  endDate: string;
  lastUpdatedAt: string;
  status: ReportDetailedReportStatus;
  activePhases: ReportDetailedActivePhase[];
  crossShiftsIntervalPhases: BusinessRuleCrossShiftsPhase[];
  hoursDistribution: {
    uuid: string;
    name: string;
  }[]; // TODO replace with HoursDistributionRule type when 1207 PR is merged
  /** ["entry0", "break_start0", "break_end0", "exit0", ...] */
  shiftEventKeys: string[];
  activeNightShifts: { name: number }[];
  hash: string;
  servicesSummary?: unknown; // TODO
};

type ReportDetailed = {
  content: ReportDetailedContent;
  metadata: ReportDetailedMetadata;
};

type ReportsDetailedTableData = {
  missedDay: boolean;
  nightShiftMinutes: Record<string, number | never>;
  requests: ReportDetailedDay["requests"] | null;
  date: iDateCellData;
  scheduleException: "ApprovedOvertimeRequest" | "enableDay" | "ApprovedOvertimeNonWorkingDayRequest" | string;
  holiday: string | null;
  schedule: string;
  observation: ReportDetailedObservation | null;
  onCallMinutes: string;
  onCallActivatedMinutes: string;
  onCallReducingActivatedMinutes: string;
  status: string;
  crossShiftsInterval: string;
  crossShiftsIntervalPhases: Record<string, number>;
  cumulativeHoursBankMinutes: string;
  missedMinutes: string;
  hoursBankPhases: Record<string, number>;
  hoursBankMinutes: string;
  earlyLeaveMinutes: string;
  lateEntryMinutes: string;
  businessRulesGroup: string;
  breakMinutes: string;
  nightMinutes: string;
  nightReducedMinutes: string;
  workedMinutes: string;
  extraHoursPhases: Record<string, number>;
  actualDayBreaksByTypes: ReportBreakType[];
  actualNightBreaksByTypes: ReportBreakType[];
  plannedDayBreakByTypes: ReportBreakType[];
  plannedNightBreakByTypes: ReportBreakType[];
  hoursDistribution: {
    minutes: number;
    uuid: string;
  }[];
  extraHoursMinutes: string;
  plannedMinutes: string;
  debitMinutes: string;
  unusedBreakMinutes: string;
  isPlannedDsrDay: boolean;
  isLostDsrDay: boolean;
  isPossibleDsrDay: boolean;
  showDsrInReports: boolean;
  punches: ReportDetailedDay["punches"];
};

export type { ReportDetailed, ReportDetailedContent, ReportDetailedMetadata, ReportsDetailedTableData };

export { ReportDetailedDayStatus, ReportDetailedReportStatus };
