import { Component } from "react";
import moment from "moment";
import { DateRangePicker, DayOfWeekShape, FocusedInputShape } from "react-dates";
import styled from "styled-components";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import IconAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";
import IconAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";
import { DatePickerCommonOverrides, CalendarInsideNext, CelandarInsidePrev } from "../styled";

const NavNextPrevWrapper = styled.div`
  border-radius: 100%;
  border: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-0);
  color: var(--colors-activitiInfoBlockValue);
  outline: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-color: var(--colors-activitiCellScheduleItemHover);
    background: var(--colors-activitiCellScheduleItemHover);
    color: var(--colors-surface-800);
    path {
      fill: var(--colors-surface-800);
    }
  }
}`;

const PrevDay = ({ onClick }: { onClick: () => void }) => (
  <NavNextPrevWrapper onClick={onClick}>
    <IconAngleLeft size={20} />
  </NavNextPrevWrapper>
);

const NextDay = ({ onClick }: { onClick: () => void }) => (
  <NavNextPrevWrapper onClick={onClick}>
    <IconAngleRight size={20} />
  </NavNextPrevWrapper>
);

const Wrapper = styled(DatePickerCommonOverrides)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 20px;
  gap: 8px;
  .DateRangePicker {
    vertical-align: middle;
    line-height: 26px;
  }
  .DateRangePickerInput_arrow {
    padding: 0 7px;
  }
  .DateRangePickerInput {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .DateRangePicker_picker {
    background: var(--colors-surface-0);
    box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
    z-index: 10;
  }

  .DateRangePickerInput .DateInput:first-child .DateInput_input {
    text-align: end;
  }
`;

export const DEFAULT_AVAILABLE_DAYS_COUNT = 90;

interface DatePickerProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  availableDaysCount?: number;
  onChange: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  /**
   * Use this field to skip internal onChange logic
   */
  returnDefaultOnChangeValues?: boolean;
  /**
   * https://hrappbrazil.atlassian.net/browse/PROD-11628
   */
  newOnChangeApproach?: boolean;
  isAdmin?: boolean;
  isOutsideRange?: (day: moment.Moment, startDate: moment.Moment | null, endDate: moment.Moment | null) => boolean;
  /** Next/prev arrows */
  step: { amount: moment.DurationInputArg1; unit: moment.unitOfTime.DurationConstructor };
  /** From 0 (Sunday) to 6 (Saturday) */
  firstDayOfWeek: DayOfWeekShape;
  focusedInput?: FocusedInputShape | null;
  onFocusChange?: (val: FocusedInputShape | null) => void;
  labelFormat?: string;
}

interface DatePickerState {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  focusedInput: FocusedInputShape | null;
}

/**
 * Used on all pages where we have StartDate - EndDate
 * For example - Reports, Punches, Superpunch etc.
 */

class DatePicker extends Component<DatePickerProps, DatePickerState> {
  static defaultProps = {
    availableDaysCount: DEFAULT_AVAILABLE_DAYS_COUNT,
    step: { amount: 1, unit: "day" },
    firstDayOfWeek: 0, // Sunday
  };

  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      startDate: props.startDate || moment().date(1),
      endDate: props.endDate || moment().endOf("month"),
      focusedInput: null,
    };
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: DatePickerProps) {
    this.setState({
      startDate: nextProps.startDate || moment().date(1),
      endDate: nextProps.endDate || moment().endOf("month"),
      focusedInput: null,
    });
  }

  onPrevClick = () => {
    const { startDate, endDate } = this.state;
    const { onChange, step } = this.props;

    if (startDate && endDate) {
      const newStartDate = startDate.clone().subtract(step?.amount, step?.unit);
      const newEndDate = endDate.clone().subtract(step?.amount, step?.unit);

      this.setState({ startDate: newStartDate, endDate: newEndDate }, () => {
        onChange(newStartDate, newEndDate);
      });
    }
  };

  onNextClick = () => {
    const { startDate, endDate } = this.state;
    const { onChange, step } = this.props;

    if (startDate && endDate) {
      const newStartDate = startDate.clone().add(step?.amount, step?.unit);
      const newEndDate = endDate ? endDate.clone().add(step?.amount, step?.unit) : startDate.clone().add(1, "day");

      this.setState({ startDate: newStartDate, endDate: newEndDate }, () => {
        onChange(newStartDate, newEndDate);
      });
    }
  };

  isOutsideRange = (day: moment.Moment, sd: moment.Moment | null, ed: moment.Moment | null) => {
    const { availableDaysCount, newOnChangeApproach } = this.props;
    const { startDate } = this.state;

    const dayToCheck = startDate || moment();

    if (newOnChangeApproach) {
      // if only start day is selected - perform comparison. if start and end dates selected - return true (unlimited range)
      return !!sd && !ed && day.isAfter(sd.clone().add(availableDaysCount, "day"));
    }

    return (
      day.isAfter(moment().endOf("month")) ||
      day.isBefore(dayToCheck.clone().subtract(availableDaysCount, "day")) ||
      day.isAfter(dayToCheck.clone().add(availableDaysCount, "day"))
    );
  };

  onDatesChange = (dates: Record<"startDate" | "endDate", moment.Moment | null>) => {
    const { focusedInput } = this.state;
    const { availableDaysCount, onChange, returnDefaultOnChangeValues, newOnChangeApproach } = this.props;
    const { startDate, endDate } = dates;

    // https://hrappbrazil.atlassian.net/browse/PROD-11628
    if (newOnChangeApproach) {
      let sd = startDate;
      let ed = endDate;

      // if useris  trying to change end date once again - force user to change start date
      if (focusedInput === "endDate" && this.state.endDate !== null && ed !== null) {
        sd = ed && ed.clone();
        ed = null;
      }

      // if start date has been changed - clear end date
      if (sd !== this.state.startDate) {
        ed = null;
      }

      this.setState({ startDate: sd, endDate: ed });

      onChange(sd, ed);
      return;
    }

    if (returnDefaultOnChangeValues) {
      onChange(startDate, endDate);
      return;
    }

    const startDateL = startDate;
    let endDateL = endDate;

    if (startDateL && endDateL) {
      const duration = moment.duration(startDateL.diff(endDate));

      if (Math.abs(duration.asDays()) > availableDaysCount) {
        endDateL = startDateL.clone().add(availableDaysCount, "day");
      }

      this.setState({ startDate, endDate: endDateL });
      onChange(startDateL, endDateL);
    }
  };

  render() {
    const { startDate, endDate } = this.state;
    const { firstDayOfWeek, onFocusChange, focusedInput, newOnChangeApproach } = this.props;
    const isOutsideRange = this.props.isOutsideRange || this.isOutsideRange;

    return (
      <Wrapper>
        {window.global_store.isRTL ? <NextDay onClick={this.onPrevClick} /> : <PrevDay onClick={this.onPrevClick} />}
        <DateRangePicker
          keepOpenOnDateSelect={newOnChangeApproach}
          firstDayOfWeek={firstDayOfWeek}
          required
          noBorder
          daySize={32}
          horizontalMargin={10}
          isRTL={window.global_store.isRTL}
          readOnly
          small
          verticalSpacing={10}
          hideKeyboardShortcutsPanel
          phrases={{}}
          navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
          navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
          displayFormat={this.props.labelFormat ?? "MMM D, YYYY"}
          weekDayFormat="ddd"
          monthFormat="MMMM YYYY"
          isOutsideRange={(day) => isOutsideRange(day, startDate, endDate)}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          minimumNights={0}
          onDatesChange={this.onDatesChange}
          focusedInput={focusedInput || this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(val) => {
            if (onFocusChange) {
              onFocusChange(val);
            } else {
              this.setState({ focusedInput: val });
            }
          }} // PropTypes.func.isRequired,
        />
        {window.global_store.isRTL ? <PrevDay onClick={this.onNextClick} /> : <NextDay onClick={this.onNextClick} />}
      </Wrapper>
    );
  }
}

export default DatePicker;
