import { useTranslation } from "react-i18next";
import Button, { ButtonState } from "components/controls/StyledButton";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import { TranslationNamespaces } from "types/translationNamespaces";

const RowActionButton = () => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  return (
    <SimpleMenu
      options={[
        { value: "clearPunches", label: t("Clear punches") },
        { value: "organizeByTime", label: t("Organize by Time") },
        { value: "addRequest", label: t("Add a Request") },
        { value: "disableDay", label: t("Disable Day") },
      ]}
      onChange={() => {}}
    >
      <Button state={ButtonState.enableDay} value="Actions" style={{ width: "110px" }} />
    </SimpleMenu>
  );
};

export default RowActionButton;
