import { PageWrapper } from "components/styled/Page";
import { Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import FullPage from "components/Layout/FullPage";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { useContext, useEffect, useState } from "react";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "components/UI/ModalDialog";
import { Notification } from "types/common";
import NotificationRow from "components/NotificationRow";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ActivityInCreatePayload } from "components/Projects/projectsApiTypes";
import { GlobalContext } from "context/GlobalContextProvider";
import { createActivities } from "components/Projects/projectsApiUtils";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import AddActivities from "components/Activities/AddActivities";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRule } from "types/models/companyRules";
import { hasProjectsAccess } from "utils/common";
import TimesheetsList from "./TimesheetsList";
import Timesheet from "./Timesheet";
import SubmitTimesheetPopup from "../components/SubmitTimesheetPopup";
import * as images from "../../svg-images";
import TimesheetSettingsModal from "../components/TimesheetSettingsModal";
import { TimesheetContext } from "../timesheets.context";

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HeaderActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  & > * {
    flex-shrink: 0;
  }
`;

const SettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

enum TimesheetPageTab {
  timesheet = "/time",
  list = "/list",
}

export function TimesheetIndex(): JSX.Element {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const context = useContext(GlobalContext);

  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [popupSettingsVisible, setPopupSettingsVisible] = useState(false);
  const [notification, setNotification] = useState<Notification>({ notification: null, notificationType: null });
  const [popupAddTimeVisible, setPopupAddTimeVisible] = useState(false);
  const timesheetContext = useContext(TimesheetContext);

  const { path } = useRouteMatch();

  const onAddActivities = async (activities: ActivityInCreatePayload[]) => {
    try {
      const company = await context.getCompany();
      await createActivities({
        companyUuid: company.uuid,
        body: {
          content: {
            activities,
            createdBy: window.global_store.profile.uuid,
          },
        },
      });

      // await getActivities();
      setPopupAddTimeVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FullPage
      headerAction={
        <HeaderActionWrapper>
          {hasProjectsAccess() && (
            <SettingsButton onClick={() => setPopupSettingsVisible(true)}>{images.gearIcon}</SettingsButton>
          )}
          {timesheetContext.isTimesheetEnabled && (
            <Button
              value={t("Submit timesheet")}
              state={ButtonState.outline}
              style={{
                width: "auto",
                padding: "0 16px",
                whiteSpace: "nowrap",
                fontSize: 14,
                fontWeight: 500,
                color: "var(--colors-surface-600)",
              }}
              onClick={() => setShowSubmitPopup(true)}
            />
          )}
          <HeaderActionButtonAdd
            title={t(`Add time`)}
            state={ButtonState.primary}
            style={{ fontSize: 14, fontWeight: 500, whiteSpace: "nowrap" }}
            onClick={() => setPopupAddTimeVisible(true)}
          />
        </HeaderActionWrapper>
      }
    >
      <PageWrapper>
        <Wrapper>
          {notification.notification && (
            <NotificationRow
              message={notification.notification}
              type={notification.notificationType}
              withCloseButton
              onClose={() => setNotification({ notification: null, notificationType: null })}
            />
          )}

          <Route strict exact path={`${path}${TimesheetPageTab.list}`}>
            <TimesheetsList />
          </Route>
          <Route strict exact path={`${path}${TimesheetPageTab.timesheet}`}>
            <Timesheet />
          </Route>
        </Wrapper>

        <ModalDialog isOpen={showSubmitPopup} onClose={() => setShowSubmitPopup(false)}>
          <SubmitTimesheetPopup onClose={() => setShowSubmitPopup(false)} />
        </ModalDialog>

        {popupSettingsVisible && (
          <TimesheetSettingsModal
            onClose={() => setPopupSettingsVisible(false)}
            setNotification={(n) =>
              setNotification({ notification: n.notification, notificationType: n.notificationType })
            }
          />
        )}

        {popupAddTimeVisible && (
          <SidePopupOverlay
            width={614}
            header={t("Create Multiple Activities")}
            isOpen={popupAddTimeVisible}
            onClose={() => setPopupAddTimeVisible(false)}
            contentOverflow
            paddingBottom
          >
            <AddActivities
              isTimesheetEnabled={timesheetContext.isTimesheetEnabled}
              prefillProfile={null}
              onYes={onAddActivities}
              onClose={() => setPopupAddTimeVisible(false)}
            />
          </SidePopupOverlay>
        )}
      </PageWrapper>
    </FullPage>
  );
}
