import styled from "styled-components";
import NotificationRow from "../NotificationRow";

const NotificationWrapper = styled.div`
  margin-inline-start: 2px;
`;
const NotificationTextWrapper = styled.div``;
const NotificationTitle = styled.div`
  font-size: 16px;
  color: var(--colors-surface-900-p);
  line-height: 24px;
  margin-top: 9px;
`;
const NotificationText = styled.div`
  font-size: 16px;
  color: var(--colors-unknown39);
  line-height: 24px;
  margin-top: 3px;
`;
const NotificationRefresh = styled.button`
  background: var(--colors-surface-0);
  box-shadow: 0 1px 3px 0 rgba(67, 78, 108, 0.2);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  text-align: start;
  line-height: 24px;
  border: none;
  border-radius: var(--shapes-border-radius-default);
  height: 36px;
  padding: 0 15px 0 20px;
  outline: none;
  margin-top: 15px;
  cursor: pointer;
`;

export default function ({ t, title, description, onRefresh }) {
  const notification = {};
  notification.type = "progress";
  notification.message = (
    <NotificationWrapper>
      <NotificationTextWrapper>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationText>{description}</NotificationText>
      </NotificationTextWrapper>
      <NotificationRefresh onClick={() => onRefresh && onRefresh()}>{t("Refresh")}</NotificationRefresh>
    </NotificationWrapper>
  );

  return (
    <div>
      <NotificationRow
        style={{ marginBottom: "20px", marginTop: "20px" }}
        type={notification.type}
        message={notification.message}
      />
    </div>
  );
}
