import CnpjField from "components/CnpjField";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/StyledTextInput";
import { CompanyTaxPayerTypes } from "types/common";
import { getCompanyTaxPayerType, getCompanyTaxIdTranslation } from "utils/common";
import { useTranslation } from "react-i18next";

interface CompanyTaxPayerFieldProps {
  isLoading: boolean;
  isValid: boolean;
  value: string;
  onChange: (val: string) => void;
  country: string;
}

export default ({ country, isLoading, isValid, value, onChange }: CompanyTaxPayerFieldProps): JSX.Element | null => {
  const { t } = useTranslation("common");

  const companyTaxId = getCompanyTaxPayerType(country);

  return (
    <FieldWrapper fieldName={getCompanyTaxIdTranslation(companyTaxId, t)} width="100%" loading={isLoading}>
      {companyTaxId === CompanyTaxPayerTypes.cnpj ? (
        <CnpjField isValid={isValid} value={value.replace(/[^0-9]*/g, "")} onChange={onChange} />
      ) : (
        <TextInputControl value={value} error={!isValid} onChange={onChange} />
      )}
    </FieldWrapper>
  );
};
