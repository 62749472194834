import styled from "styled-components";

export const NotificationWrapper = styled.div`
  visibility: hidden;
  position: fixed;
  top: 30px;
  inset-inline-start: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  width: 100%;

  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 3s;
    animation: fadein 0.5s, fadeout 0.5s 3s;
  }

  &.style-notice .snackbar-notification {
    background-color: var(--colors-unknown40);
    color: var(--colors-unknown39);
  }

  &.style-error .snackbar-notification {
    background-color: var(--colors-danger-500);
    color: var(--colors-surface-0);
  }

  @-webkit-keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      tpo: 30px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }
`;

export const Notification = styled.div`
  display: inline-block;
  padding: 9px 24px;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  text-align: center;
  border-radius: var(--shapes-border-radius-default);
  background-color: var(--colors-danger-500);
  color: var(--colors-surface-0);
`;

const Snackbar = () => (
  <NotificationWrapper id="snackbar">
    <Notification className="snackbar-notification" />
  </NotificationWrapper>
);

export default Snackbar;
