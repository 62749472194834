import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import GlobalContext from "context/global-context";
import { getSelectedColumns } from "utils/tableHelpers";
import { hasEmployeesAccess, fireEvent, hasPermisionAccess } from "utils/common";
import { adjustHoursBank, fireDownloadReport } from "utils/apiHelpers";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { ReportType, ReportParams, SetIsLoading } from "types/reports";
import FullPage from "components/Layout/FullPage";
import ModalDialog from "components/UI/ModalDialog";
import Button, { ButtonState } from "components/controls/StyledButton";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import Lightbox from "components/Lightbox";
import NotificationRow from "components/NotificationRow";
import { translateEmployeeTerm } from "utils/translationHelpers";
import ReportsNoContent from "./ReportsNoContent";
import ReportsPunchesTable from "./ReportsPunchesTable";
import ReportsExtraHourTable from "./ReportsExtraHourTable";
import ReportsExtraHourTableAllEmployees from "./ReportsExtraHourTableAllEmployees";
import ReportsSummary from "./ReportsSummary";
import ReportsCheckinAllEmployees from "./ReportsCheckinAllEmployees";
import ReportsHoursbankTable from "./ReportsHoursbankTable";
import ReportsHoursbankTableAllEmployees from "./ReportsHoursbankTableAllEmployees";
import ReportsLateArrivalsTable from "./ReportsLateArrivalsTable";
import ReportsLateArrivalsTableAllEmployees from "./ReportsLateArrivalsTableAllEmployees";
import ReportsDigitalSignaturesAllEmployees from "./ReportsDigitalSignaturesAllEmployees";
import HoursBankAdjustPopup from "./HoursBankAdjustPopup";
import ReportSearchFilters, { REPORT_LOADING_EVENT } from "./ReportSearchFilters";
import InProgressRow from "./InProgressRow";
import ReportsDownloadAllDialog, { ReportForMultipleEmployees } from "./ReportsDownloadAllDialog";
import { getReportsPageTitle } from "./ReportsTitle";
import { ControlsWrapper, Page } from "./reportsStyledComponents";
import ReportsDownloadCustomPeriodDialog, { ReportWithCustomRange } from "./ReportsDownloadCustomPeriodDialog";

type AdjustmentData = {
  companyUUID: string;
  userProfileUuid: string;
  body: {
    content: {
      createdBy: string;
      convertToExtraHours: boolean;
      date: string;
      adjustment: number;
      reason: string;
      adjustmentType: string;
    };
  };
};

interface ReportsPageProps extends WithTranslation, RouteComponentProps {
  reportType?: ReportType;
  id?: string;
}

interface ReportsPagePropsState {
  showReport?: boolean;
  isLoading: boolean;
  adjustHBDialogOpen: boolean;
  generateAllReportOpen: boolean;
  generateCustomPeriodOpen: boolean;
  confirmationPopupVisible: boolean;
  generatingEmailForDownload: boolean;
  reportParams: ReportParams;
  adjustmentData?: AdjustmentData;
  notification?: {
    message: string | React.ReactNode;
    type: string;
  };
}

class ReportsPage extends Component<ReportsPageProps, ReportsPagePropsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ReportsPageProps) {
    super(props);
    this.state = {
      generatingEmailForDownload: false,
      confirmationPopupVisible: false,
      generateAllReportOpen: false,
      generateCustomPeriodOpen: false,
      showReport: false,
      reportParams: {},
      adjustHBDialogOpen: false,
      isLoading: false,
    };
  }

  getContentTable = (reportType: ReportType) => {
    let content = null;
    const { showReport, reportParams } = this.state;
    const { startDate, endDate, searchObj } = reportParams;
    const key = new Date().getTime();

    const isOtherTableReport =
      startDate &&
      endDate &&
      ((reportType !== ReportType.summary && reportType !== ReportType.checkin && searchObj && searchObj.id) ||
        reportType === ReportType.summary ||
        reportType === ReportType.digitalSignaturesStatus ||
        reportType === ReportType.checkin);

    if (isOtherTableReport) {
      switch (reportType) {
        // Clock ins report
        case ReportType.punches:
          content = <ReportsPunchesTable {...reportParams} key={key} setIsLoading={this.setIsLoading} />;
          break;
        // Summary
        case ReportType.summary:
          content = (
            <ReportsSummary
              {...reportParams}
              key={key}
              updateRecalculatingMessage={this.updateRecalculatingMessage}
              setIsLoading={this.setIsLoading}
            />
          );
          break;
        // Check ins report
        case ReportType.checkin:
          // TODO PROD-11494 this report does not work
          content = (
            <ReportsCheckinAllEmployees
              {...reportParams}
              key={key}
              updateRecalculatingMessage={this.updateRecalculatingMessage}
              setIsLoading={this.setIsLoading}
            />
          );
          break;
        case ReportType.extraHours:
          if (searchObj && (!searchObj.id || searchObj.id === "all" || searchObj.type)) {
            content = (
              <ReportsExtraHourTableAllEmployees
                {...reportParams}
                key={key}
                updateRecalculatingMessage={this.updateRecalculatingMessage}
                setIsLoading={this.setIsLoading}
              />
            );
          } else {
            content = (
              <ReportsExtraHourTable
                {...reportParams}
                updateRecalculatingMessage={this.updateRecalculatingMessage}
                key={key}
                setIsLoading={this.setIsLoading}
              />
            );
          }
          break;
        case ReportType.hoursbank:
          if (searchObj && (!searchObj.id || searchObj.id === "all" || searchObj.type)) {
            content = (
              <ReportsHoursbankTableAllEmployees
                {...reportParams}
                updateRecalculatingMessage={this.updateRecalculatingMessage}
                key={key}
                setIsLoading={this.setIsLoading}
              />
            );
          } else {
            content = (
              <ReportsHoursbankTable
                {...reportParams}
                updateRecalculatingMessage={this.updateRecalculatingMessage}
                key={key}
                setIsLoading={this.setIsLoading}
              />
            );
          }
          break;
        case ReportType.lateArrival:
          if (searchObj && (!searchObj.id || searchObj.id === "all" || searchObj.type)) {
            content = (
              <ReportsLateArrivalsTableAllEmployees {...reportParams} key={key} setIsLoading={this.setIsLoading} />
            );
          } else {
            content = <ReportsLateArrivalsTable {...reportParams} key={key} setIsLoading={this.setIsLoading} />;
          }
          break;
        case ReportType.digitalSignaturesStatus:
          content = (
            <ReportsDigitalSignaturesAllEmployees
              showReport={showReport}
              {...reportParams}
              updateRecalculatingMessage={this.updateRecalculatingMessage}
              key={key}
              setIsLoading={this.setIsLoading}
            />
          );
          break;
        default:
          content = null;
      }
    }

    return content;
  };

  setIsLoading: SetIsLoading = (isLoading) => {
    fireEvent(REPORT_LOADING_EVENT, { isLoading });
  };

  handleResetFilters = (filters: ReportParams) => {
    this.generateReport(filters);
  };

  handleGenerateButton = (filters: ReportParams) => {
    this.generateReport(filters);
  };

  generateReport = (filters: ReportParams) => {
    const {
      searchObj,
      startDate,
      endDate,
      onlyNonZeroPhases,
      onlyNonZeroLines,
      selectedPunchType,
      showInactiveEmploees,
      skipSupervisors,
      oldReport,
      selectedSignatureType,
    } = filters;
    let { reportParams } = this.state;

    const showReport = searchObj && Object.keys(searchObj).length > 0;

    if (showReport) {
      reportParams = {
        oldReport,
        startDate: startDate?.clone(),
        endDate: endDate?.clone(),
        onlyNonZeroPhases,
        onlyNonZeroLines,
        showInactiveEmploees,
        skipSupervisors,
        selectedPunchType,
        searchObj: JSON.parse(JSON.stringify(searchObj)),
      };

      if (selectedSignatureType) {
        reportParams.selectedSignatureType = selectedSignatureType;
      }
    }

    this.setState({ showReport: !!showReport, reportParams, notification: undefined });
  };

  generateDetailedReportForMultipleEmployees = async (params: ReportForMultipleEmployees) => {
    const { t, reportType } = this.props;
    const { format, searchObj, startDate, endDate, onSamePage, showInactive, skipSupervisors, selectedColumns } =
      params.body;

    try {
      const response = await fireDownloadReport({
        format: format || "pdf",
        selectedColumns,
        searchObj,
        startDate: startDate?.format("YYYY-MM-DD"),
        endDate: endDate?.format("YYYY-MM-DD"),
        onSamePage,
        showInactiveEmploees: showInactive,
        skipSupervisors,
        byEmail: true,
        reportType: reportType === ReportType.punches ? "regular" : "detailed",
        oldReport: reportType === ReportType.punches,
      });

      void this.context.addToDownloads(response.report_uuid);
      this.setState({
        generateAllReportOpen: false,
        generatingEmailForDownload: true,
        notification: undefined,
      });
    } catch (err) {
      this.setState({
        notification: {
          message: t("Failed to run generation on detailed report for multiple emloyees"),
          type: NotificationType.error,
        },
      });
    }
  };

  generateCustomRangeSummaryReport = async (reportProps: ReportWithCustomRange) => {
    const { t } = this.props;
    const { format, searchObj, startDate, endDate, showInactive, skipSupervisors, selectedColumns } = reportProps;

    const response = await fireDownloadReport({
      format: format || "csv",
      selectedColumns,
      searchObj,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      showInactiveEmploees: showInactive,
      skipSupervisors,
      byEmail: true,
      reportType: ReportType.summaryAll,
      background: true,
      customPeriod: true,
    });

    void this.context.addToDownloads(response.report_uuid);

    try {
      this.setState({
        generateCustomPeriodOpen: false,
      });
    } catch (err) {
      this.setState({
        notification: {
          message: t("Failed to run generation on summary report with custom date range"),
          type: NotificationType.error,
        },
      });
    }
  };

  onAdjustHoursBankClick = (data: AdjustmentData) => {
    this.setState({ confirmationPopupVisible: true, adjustmentData: data });
  };

  onAdjustHoursBankSave = async () => {
    const { t } = this.props;
    const { adjustmentData } = this.state;

    try {
      await adjustHoursBank(adjustmentData);

      this.setState({
        notification: {
          message: `${t("You adjusted hours bank settings")}`,
          type: "success",
        },
        confirmationPopupVisible: false,
        adjustmentData: undefined,
        adjustHBDialogOpen: false,
      });

      // refetch report
      setTimeout(() => {
        this.setState({});
      }, 5000);
    } catch (err) {
      this.setState({
        confirmationPopupVisible: false,
        adjustmentData: undefined,
        adjustHBDialogOpen: false,
        notification: {
          message: `${t("Internal server error. Please try again later or contact support.")}`,
          type: "error",
        },
      });
    }
  };

  updateRecalculatingMessage = (
    recalculatingInProgress: boolean,
    customMessage?: Record<"title" | "text" | "lastUpdatedTime", string>,
  ): void => {
    fireEvent("reports_inProgress", { recalculatingInProgress, customMessage });
  };

  getHeaderAction = () => {
    const { reportType, t } = this.props;

    if (reportType === ReportType.hoursbank && hasPermisionAccess(PermissionSectionName.hoursBankAdjustment)) {
      return (
        <HeaderActionButtonAdd
          state={ButtonState.primary}
          title={t("Adjust")}
          onClick={() => {
            this.setState({ adjustHBDialogOpen: true });
          }}
        />
      );
    }

    if (reportType === ReportType.punches && hasEmployeesAccess()) {
      return (
        <Button
          state={ButtonState.outline}
          value={t("Generate reports from all")}
          onClick={() => {
            this.setState({ generateAllReportOpen: true });
          }}
        />
      );
    }

    if (reportType === ReportType.summary && hasEmployeesAccess()) {
      return (
        <Button
          state={ButtonState.outline}
          value={t("Download custom period")}
          onClick={() => {
            this.setState({ generateCustomPeriodOpen: true });
          }}
        />
      );
    }

    return null;
  };

  render() {
    const {
      showReport,
      reportParams,
      generatingEmailForDownload,
      confirmationPopupVisible,
      adjustHBDialogOpen,
      isLoading,
    } = this.state;
    let { notification } = this.state;
    const { searchObj } = reportParams;
    const { reportType, t } = this.props;

    if (generatingEmailForDownload) {
      notification = {
        type: "progress",
        message: (
          <>
            <div>{t("We're still updating. You'll receive an email as soon as you're done.")}</div>
          </>
        ),
      };
    }

    if (!reportType) {
      return null;
    }

    return (
      <FullPage key={reportType} headerAction={this.getHeaderAction()} title={getReportsPageTitle(t, reportType)}>
        <Page>
          {notification ? (
            <NotificationRow
              style={{ marginBottom: "20px", marginTop: "20px" }}
              onClose={() => this.setState({ notification: undefined })}
              type={notification.type}
              message={notification.message}
            />
          ) : (
            <InProgressRow t={t} onRefresh={() => this.setState({})} />
          )}
          <ControlsWrapper style={{ paddingBottom: "40px" }}>
            <ReportSearchFilters
              isLoading={isLoading}
              reportType={reportType}
              onGenerateButtonClick={this.handleGenerateButton}
              onResetFilters={this.handleResetFilters}
            />
          </ControlsWrapper>
          {showReport ? this.getContentTable(reportType) : <ReportsNoContent />}

          <ModalDialog
            isOpen={this.state.generateAllReportOpen}
            onClose={() => this.setState({ generateAllReportOpen: false })}
          >
            <ReportsDownloadAllDialog
              reportType={reportType}
              onClose={() => this.setState({ generateAllReportOpen: false })}
              onYes={this.generateDetailedReportForMultipleEmployees}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={this.state.generateCustomPeriodOpen}
            onClose={() => this.setState({ generateCustomPeriodOpen: false })}
          >
            <ReportsDownloadCustomPeriodDialog
              onClose={() => this.setState({ generateCustomPeriodOpen: false })}
              onYes={this.generateCustomRangeSummaryReport}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={adjustHBDialogOpen}
            onClose={() => this.setState({ adjustHBDialogOpen: confirmationPopupVisible })}
          >
            <HoursBankAdjustPopup
              prefillProfile={searchObj?.employee && !searchObj.type ? searchObj.employee : null}
              onClose={() => this.setState({ adjustHBDialogOpen: false })}
              onYes={this.onAdjustHoursBankClick}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false, adjustmentData: undefined })}
          >
            <Lightbox
              title={t("Add Adjustment")}
              text={translateEmployeeTerm(
                t,
                TranslationNamespaces.reportsPage,
                "custom-hours-bank-adjust-comfirmation",
                "Are you sure you want to add a Hours Bank adjustment for this employee?",
              )}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ confirmationPopupVisible: false, adjustmentData: undefined });
              }}
              onYes={this.onAdjustHoursBankSave}
            />
          </ModalDialog>
        </Page>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.reportsPage, TranslationNamespaces.sidebar])(ReportsPage);
