import { CSSProperties } from "react";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "../svg-images";

const Wrapper = styled.div`
  button {
    padding: 0 16px;
  }
`;

const BigPlus = styled.div<{ state: ButtonState }>`
  margin-inline-end: 13px;
  display: flex;

  ${(p) =>
    p.state === ButtonState.primary
      ? `
  path {
    fill: #fff;
  }
  `
      : ""}
`;

interface HeaderActionButtonAddProps {
  title: string;
  onClick?: () => void;
  state?: ButtonState;
  style?: CSSProperties;
}

const HeaderActionButtonAdd = ({ title, onClick, state, style }: HeaderActionButtonAddProps): JSX.Element => (
  <Wrapper>
    <Button
      style={style}
      state={state || ButtonState.secondary}
      value={
        <>
          <BigPlus state={state || ButtonState.secondary}>{images.plusBigBlue}</BigPlus> {title}
        </>
      }
      onClick={onClick}
    />
  </Wrapper>
);

export default HeaderActionButtonAdd;
