import styled from "styled-components";

const Date = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`;

const DayName = styled.span`
  --font-family: var(--cp-table-day-name-cell-font-family, var(--typography-font-family-default));
  --font-size: var(--cp-table-name-cell-font-size, 12px);
  --font-weight: var(--cp-table-day-name-cell-font-weight, var(--typography-font-weight-bold));
  --text-color: var(--cp-table-day-month-cell-text-color, var(--colors-surface-800));
  .day-off & {
    --text-color: var(--cp-table-day-month-cell-dayoff-text-color, var(--colors-surface-500));
  }

  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  color: var(--text-color);
  text-transform: uppercase;
`;

const DayMonth = styled.span`
  --font-family: var(--cp-table-day-month-cell-font-family, var(--typography-font-family-default));
  --font-size: var(--cp-table-name-cell-font-size, var(--typography-font-size-default));
  --text-color: var(--cp-table-day-month-cell-text-color, var(--colors-surface-700));
  .day-off & {
    --text-color: var(--cp-table-day-month-cell-dayoff-text-color, var(--colors-surface-500));
  }

  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--text-color);
`;

const DayOfWeek = ({ dayName, dayMonth, isDayOff }: { dayName: string; dayMonth?: string; isDayOff: boolean }) => (
  <Date className={isDayOff ? "day-off" : ""}>
    <DayName>{dayName}</DayName>
    {!!dayMonth && <DayMonth>{dayMonth}</DayMonth>}
  </Date>
);

export default DayOfWeek;
