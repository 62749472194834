import { Component } from "react";
import CheckboxControl from "components/UI/NewCheckbox";
import moment from "moment";
import { fireEvent } from "utils/common";

class CellCheckboxAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("sp_row_uncheck_all", this.uncheckAll);
    window.onkeydown = (e) => {
      const { tableRows } = this.props;
      if (!tableRows || !tableRows.rows.length) {
        return true;
      }
      e = e || window.event;
      const k = e.keyCode || e.which;
      // console.log('k', k, e);
      switch (k) {
        case 65: // handle A key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.checkAll();
            return false;
          }
          return true;
        case 68: // handle D key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.uncheckAll();
            return false;
          }
          return true;
        case 67: // handle C key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.clearAll();
            return false;
          }
          return true;
        case 79: // handle O key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.organizeAll();
            return false;
          }
          return true;
        case 80: // handle v key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.approveAll();
            return false;
          }
          return true;
        case 86: // handle p key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.validateAll();
            return false;
          }
          return true;
        case 70: // handle f key
          if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            this.forceValidateAll();
            return false;
          }
          return true;
        default:
          return true;
      }
    };
  }

  componentWillUnmount() {
    document.removeEventListener("sp_row_uncheck_all", this.uncheckAll);
    window.onkeydown = null;
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.state.checked) {
      this.setState({ checked: false });
    }
  }

  uncheckAll = () => {
    this.setState({ checked: false }, () => this.fireToggleAll(this.props.tableRows.rows, false));
  };

  checkAll = () => {
    this.setState({ checked: true }, () => this.fireToggleAll(this.props.tableRows.rows, true));
  };

  clearAll = () => {
    fireEvent("sp_row_clear_all");
  };

  organizeAll = () => {
    fireEvent("sp_row_organize_all");
  };

  approveAll = () => {
    fireEvent("sp_row_approve-all");
  };

  validateAll = () => {
    fireEvent("sp_row_validate-all");
  };

  forceValidateAll = () => {
    fireEvent("sp_row_force-validate-all");
  };

  getEventKey = (date) => `sp_row_checked_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}`;

  fireToggleAll(rows, checked) {
    rows.forEach((row) => {
      if (!row.lockedDay) {
        fireEvent(this.getEventKey(row.date.raw), { checked });
      }
    });
  }

  onCheckAll = (isChecked) => {
    const { tableRows } = this.props;
    this.setState({ checked: isChecked }, () => this.fireToggleAll(tableRows.rows, isChecked));
  };

  render() {
    const { checked } = this.state;
    const { tableRows } = this.props;

    return (
      <CheckboxControl disabled={!tableRows || !tableRows.rows.length} checked={checked} onChange={this.onCheckAll} />
    );
  }
}

export default CellCheckboxAll;
