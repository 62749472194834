import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";
import Charts from "./Charts";
import { DashboardPageWrapper } from "./styled";
import oitchauChartTheme from "./oitchauChartTheme";
import BetaRow from "./BetaRow";

FusionCharts.register("theme", oitchauChartTheme);

// global fusioncharts init
ReactFusioncharts.fcRoot(FusionCharts, charts);

type DashboardPageProps = RouteComponentProps;

const DashboardPage = (props: DashboardPageProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);

  return (
    <DashboardPageWrapper>
      <FullPage title={t("Time & Attendance Dashboard")}>
        <Charts />
        <BetaRow />
      </FullPage>
    </DashboardPageWrapper>
  );
};

export default DashboardPage;
