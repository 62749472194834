import moment from "moment";
import { SearchObject } from "./common";
import { SelectOption } from "./ui";

enum ReportType {
  punches = "punches",
  detailed = "detailed",
  /** AFD/AFDT report */
  afd = "afd",
  extraHours = "extra-hour",
  summary = "summary",
  checkin = "checkin",
  hoursbank = "hoursbank",
  lateArrival = "late-arrival",
  clients = "clients",
  fte = "fte",
  /** Activities by location report */
  activities = "activities",
  digitalSignaturesStatus = "digital_signatures_status",
  /** on rails side */
  summaryAll = "summary_all",
}

enum ActivityReportType {
  location = "location",
  client = "client",
  project = "project",
  employee = "employee",
  taskFeature = "taskFeature",
}

enum ClientReportsType {
  clientList = "clientList",
  locations = "locations",
  employees = "employees",
}

/**
 * value: client.uuid,
 * label: client.name  */
type SelectedClient = SelectOption | null;

type SetIsLoading = (isLoading: boolean) => void;

type OnError = (notification: string) => void;

type ReportParams = {
  /**
   * Legacy.
   * old report is used only for punches report
   * @TODO remove oldReport
   */
  oldReport?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  onlyNonZeroPhases?: boolean;
  onlyNonZeroLines?: boolean;
  showInactiveEmploees?: boolean;
  skipSupervisors?: boolean;
  searchObj?: SearchObject | null;
  selectedPunchType?: string;
  selectedSignatureType?: string;
};

export type { ReportParams, SelectedClient, SetIsLoading, OnError };
export { ReportType, ActivityReportType, ClientReportsType };
