import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CreateAccountPage from "components/Onboarding/CreateAccountPage";
import { urlParam } from "utils/common";
import { useHubspot } from "hooks/useHubspot";
import { CreateAccountPageNew } from "../components/Onboarding/CreateAccountPageNew";

const SignupNew = () => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          path="/signup/:email?"
          render={(props) => {
            const { match } = props;
            let { email } = match.params;
            let sourceType = "";
            let name = "";
            let refCode = "";
            let trafficSource = "";
            let trafficMedium = "";
            let trafficCampaing = "";
            let token = "";

            if (urlParam("email")) {
              email = String(urlParam("email"));
            }
            if (urlParam("name")) {
              name = String(urlParam("name"));
            }
            if (urlParam("token") && urlParam("sourceType")) {
              token = String(urlParam("token"));
              sourceType = String(urlParam("sourceType"));
            }
            if (urlParam("referral_code")) {
              refCode = String(urlParam("referral_code"));
            }

            if (urlParam("utm_source")) {
              trafficSource = String(urlParam("utm_source"));
            }
            if (urlParam("utm_medium")) {
              trafficMedium = String(urlParam("utm_medium"));
            }
            if (urlParam("utm_campaign")) {
              trafficCampaing = String(urlParam("utm_campaign"));
            }

            return (
              <CreateAccountPageNew
                email={email || ""}
                token={token}
                name={name}
                sourceType={sourceType}
                refCode={refCode}
                trafficSource={trafficSource}
                trafficMedium={trafficMedium}
                trafficCampaing={trafficCampaing}
              />
            );
          }}
        />
        <Route
          path="/signup-default/:email?"
          render={(props) => {
            const { match } = props;
            let { email } = match.params;
            let sourceType = "";
            let name = "";
            let refCode = "";
            let trafficSource = "";
            let trafficMedium = "";
            let trafficCampaing = "";
            let token = "";

            if (urlParam("email")) {
              email = String(urlParam("email"));
            }
            if (urlParam("name")) {
              name = String(urlParam("name"));
            }
            if (urlParam("token") && urlParam("sourceType")) {
              token = String(urlParam("token"));
              sourceType = String(urlParam("sourceType"));
            }
            if (urlParam("referral_code")) {
              refCode = String(urlParam("referral_code"));
            }

            if (urlParam("utm_source")) {
              trafficSource = String(urlParam("utm_source"));
            }
            if (urlParam("utm_medium")) {
              trafficMedium = String(urlParam("utm_medium"));
            }
            if (urlParam("utm_campaign")) {
              trafficCampaing = String(urlParam("utm_campaign"));
            }

            return (
              <CreateAccountPage
                {...props}
                email={email || ""}
                token={token}
                name={name}
                sourceType={sourceType}
                refCode={refCode}
                trafficSource={trafficSource}
                trafficMedium={trafficMedium}
                trafficCampaing={trafficCampaing}
              />
            );
          }}
        />
      </Switch>
    </Suspense>
  );
};

export default SignupNew;
