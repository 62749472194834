import { Component, ContextType, CSSProperties } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import { showSnackbar } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import * as images from "./svg-images";
import "styles/download-control.scss";
import ModalDialog from "./UI/ModalDialog";
import Button, { ButtonState } from "./controls/StyledButton";

const ConfirmWrapper = styled.div`
  background: var(--colors-surface-0);

  .title {
    font-size: 24px;
    text-align: center;
    color: var(--colors-surface-900-p);
    margin-bottom: 24px;
  }

  .description {
    font-size: var(--typography-font-size-default);
    color: var(--colors-surface-700);
  }

  .buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

const Value = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  min-height: 36px;
  line-height: 14px;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  background: var(--colors-surface-50);
  vertical-align: middle;
  text-align: center;
  font-size: var(--typography-font-size-default);
  letter-spacing: 0;
  border-radius: var(--shapes-border-radius-default);
  color: var(--colors-surface-800);
  outline: none;
  cursor: pointer;

  svg {
    margin-inline-end: 0;
  }
`;

const Menu = styled.div`
  inset-inline-end: 0;
  inset-inline-start: inherit;
  min-width: 130px;
`;

const b = BEM.b("download-control");

enum FileFormat {
  csv = "csv",
  pdf = "pdf",
  xlsx = "xlsx",
}

type Option = { label: string; value: FileFormat | string };

const DEFAULT_OPTIONS: Option[] = [
  { label: "CSV", value: FileFormat.csv },
  { label: "PDF", value: FileFormat.pdf },
  { label: "XLSX", value: FileFormat.xlsx },
];

interface DownloadControlWithEventsProps extends WithTranslation {
  onChange: (reportUuid: string) => void;
  modifiers: Record<string, any>;
  options?: Option[];
  value?: Option;
  disabled?: boolean;
  style: CSSProperties;
  withConfirm?: {
    title: string;
    description: string;
    downloadLabel: string;
    cancelLabel: string;
  };
  withoutDropdown?: boolean;
}

interface DownloadControlWithEventsState {
  open: boolean;
  options: Option[];
  value: Option;
  disabled: boolean;
  showConfirm: boolean;
  selectedValue: Option;
}

class DownloadControlWithEvents extends Component<DownloadControlWithEventsProps, DownloadControlWithEventsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  static defaultProps = {
    modifiers: {},
    placeholder: "Download",
    style: {},
  };

  wrapperRef: HTMLDivElement | null = null;

  constructor(props: DownloadControlWithEventsProps) {
    super(props);

    this.state = {
      open: false,
      options: props.options || DEFAULT_OPTIONS,
      value: props.value || { value: "", label: "" },
      disabled: !!props.disabled,
      showConfirm: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps: DownloadControlWithEventsProps) {
    this.setState({
      options: nextProps.options || DEFAULT_OPTIONS,
      value: nextProps.value || { value: "", label: "" },
      disabled: !!nextProps.disabled,
    });
  }

  handleClickOutside = (ev: Event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target as Node)) {
      this.setState({ open: false });
    }
  };
  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  };

  toggleDropdown = () => {
    if (!this.state.disabled) {
      this.setState({ open: !this.state.open });
    }
  };

  selectValue = async (value: Option) => {
    const { withConfirm } = this.props;
    this.setState({ disabled: true, open: false, value });
    if (!withConfirm) await this.process(value);
    else this.setState({ showConfirm: true });
  };

  process = async (value: Option) => {
    const { t, onChange } = this.props;
    setTimeout(() => {
      this.setState({ disabled: false, value: { value: "", label: "" } });
    }, 5000);

    try {
      const response = await onChange(value.value); // report_uuid

      void this.context.addToDownloads(response.report_uuid);

      showSnackbar({ text: t("Preparing file for download…"), notificationStyle: "notice" });
    } catch (error) {
      sentryUtils.sendError(error);

      showSnackbar({ text: t("Failed to run report generation") });
    }
  };

  render() {
    const { options, open, value, disabled, showConfirm } = this.state;
    const { style, modifiers, withConfirm, withoutDropdown } = this.props;

    return (
      <>
        <div className={b({ open, ...modifiers })} ref={this.setWrapperRef} style={{ ...style }}>
          <Value
            className={b("value", { disabled, download: true, ...modifiers })}
            onClick={() => {
              if (withoutDropdown) this.selectValue(options[0]);
              else this.toggleDropdown();
            }}
          >
            {images.downloadColumnNewDesign}
          </Value>

          {!withoutDropdown ? (
            <Menu className={b("menu", { open, ...modifiers })}>
              {options.map((option) => (
                <div
                  className={b("option", { active: value.value === option.value })}
                  key={option.value}
                  onClick={() => this.selectValue(option)}
                >
                  {option.label}
                </div>
              ))}
            </Menu>
          ) : null}
        </div>
        {withConfirm ? (
          <ModalDialog
            onClose={() => this.setState({ showConfirm: false, disabled: false, value: { value: "", label: "" } })}
            isOpen={showConfirm}
            padding={32}
          >
            <ConfirmWrapper>
              <div className="title">{withConfirm.title}</div>
              <div className="description" dangerouslySetInnerHTML={{ __html: withConfirm.description }} />
              <div className="buttons">
                <Button
                  state={ButtonState.secondary}
                  onClick={() =>
                    this.setState({ showConfirm: false, disabled: false, value: { value: "", label: "" } })
                  }
                  value={withConfirm.cancelLabel}
                />
                <Button
                  state={ButtonState.primary}
                  onClick={async () => {
                    await this.process(value);
                    this.setState({ showConfirm: false });
                  }}
                  value={withConfirm.downloadLabel}
                />
              </div>
            </ConfirmWrapper>
          </ModalDialog>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(DownloadControlWithEvents);
