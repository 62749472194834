import { Component, CSSProperties } from "react";
import styled from "styled-components";
import { Notification, NotificationType } from "types/common";
import * as images from "./svg-images";

const NotificationSection = styled.div<{
  $employeesPage: boolean;
  $aligned: boolean;
  $type: NotificationType | null;
  $default: boolean;
  $onboardingMargin: boolean;
}>`
  position: relative;
  margin-top: ${(p) => (p.$employeesPage ? "25px" : "0")};
  margin-top: 20px;
  display: flex;
  align-items: ${(p) => (p.$aligned ? "center" : "flex-start")};
  padding: 15px 20px;
  font-size: ${(p) => (p.$type === NotificationType.warning ? "13px" : "14px")};
  line-height: 24px;
  letter-spacing: 0;
  border-radius: var(--shapes-border-radius-default);
  color: #515e80;
  gap: 15px;

  ${(p) => {
    switch (true) {
      case p.$default:
      case p.$employeesPage:
        return "margin-bottom: 0;";
      case p.$onboardingMargin:
        return "margin-bottom: 30px;";
      default:
        return "margin-bottom: 55px;";
    }
  }}

  ${(p) => {
    switch (p.$type) {
      case NotificationType.success:
        return "background: rgba(0, 202, 115, 0.15);";
      case NotificationType.progress:
        return "background: #fff7e1;";
      case NotificationType.message:
        return "background: #f1f5f9;";
      case NotificationType.attention:
        return "background: var(--colors-unknown43);";
      case NotificationType.error:
        return "background: #ffe8e8;";
      case NotificationType.locked:
        return "background: #f1f4f8;";
      case NotificationType.infoBlue:
        return `
          background: var(--colors-primary-50);
        `;
      case NotificationType.errorNew:
        return `
          display: flex;
          align-items: center;
          background: var(--colors-errorNotificationNew);
          line-height: 14px;
          font-size: 12px;
          color: var(--colors-surface-900-p);
        `;
      case NotificationType.info:
        return `
          padding: 0;
          display: flex;
          align-items: center;
          background: transparent;
          color: var(--colors-surface-500);
        `;
      case NotificationType.warning:
      default:
        return "background: rgba(251, 197, 51, 0.15);";
    }
  }}

  p {
    display: inline;
    vertical-align: middle;
  }
`;

const Icon = styled.div<{ $type: NotificationType | null }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.$type === NotificationType.locked
      ? `
      width: 33px;
      min-width: 33px;
      height: 33px;
      background-color: var(--colors-surface-600);
      border-radius: 50px;
    `
      : ""}
  ${(p) =>
    p.$type === NotificationType.info
      ? `
    margin-top: 0;
  `
      : ""}
`;

const CloseBtn = styled.div`
  position: absolute;
  inset-inline-end: 7px;
  top: 19px;
  margin-top: -11px;
  cursor: pointer;
`;

interface NotificationRowProps {
  style: CSSProperties;
  type: NotificationType | null;
  onClose?: () => void;
  message: Notification["notification"];
  employeesPage?: boolean;
  aligned?: boolean;
  withCloseButton?: boolean;
  onboardingMargin?: boolean;
  icon?: JSX.Element;
}

class NotificationRow extends Component<NotificationRowProps> {
  static defaultProps = {
    style: {},
    type: NotificationType.warning,
  };

  getIcon(type: NotificationType | null) {
    switch (type) {
      case "success":
        return images.notificationSuccess;
      case "warning":
        return images.notificationWarning;
      case "progress":
        return images.notificationProgress;
      case "error":
        return images.notificationError;
      case "message":
        return images.notificationMessage;
      case "attention":
        return images.notificationAttention;
      case "locked":
        return images.lockedPadlock();
      case "errorNew":
        return images.warningIcon;
      case "info":
        return images.info;
      case "infoBlue":
        return images.infoBlue;
      default:
        return null;
    }
  }

  render() {
    const { style, type, message, employeesPage, onClose, aligned, withCloseButton, onboardingMargin } = this.props;
    const icon = this.props.icon || this.getIcon(type);

    return (
      <NotificationSection
        $type={type}
        $onboardingMargin={!!onboardingMargin}
        $employeesPage={!!employeesPage}
        $default={!employeesPage && !onboardingMargin}
        $aligned={!!aligned}
        style={{ ...style }}
      >
        <Icon $type={type}>{icon && icon}</Icon>

        <div className="notification-message">{message}</div>

        {withCloseButton && onClose && <CloseBtn onClick={onClose}>{images.removeIcon()}</CloseBtn>}
      </NotificationSection>
    );
  }
}

export default NotificationRow;
