import { DSR_PHASE_KEY } from "components/Payroll/types";
import { BusinessGroupUserProfile } from "./userProfile";

type EmployeeBusinessRulesGroup = {
  uuid: string;
  name: string;
  isDefault: boolean;
};

enum BusinessRuleGroupStatus {
  active = "active",
  deactivated = "deactivated",
}

enum PayedOvertimeAlertLimit {
  day = "day",
  week = "week",
}

enum OnCallsCompensationType {
  noCompensation = "no",
  extraHours = "extra_hours",
}

enum BusinessRuleType {
  nightShift = "nightShift",
  payedOvertime = "payedOvertime",
  tolerance = "tolerance",
  onCalls = "onCalls",
  crossShiftsInterval = "crossShiftsInterval",
  hoursDistribution = "hoursDistribution",
}

export enum PhaseType {
  day = "day",
  night = "night",
  combined = "combined",
}

export enum PhaseDayType {
  working = "working",
  nonWorking = "nonWorking",
  dsr = DSR_PHASE_KEY,
}

export enum HoursBankMissingDayStrategy {
  DiscountHoursBankAndConsiderMissingDay = 'discountHoursBankAndConsiderMissingDay',
  DontDiscountHoursBankAndConsiderMissingDay = 'dontDiscountHoursBankAndConsiderMissingDay',
  DiscountHoursBankAndDontConsiderMissingDay = 'discountHoursBankAndDontConsiderMissingDay',
}

type PayedOvertimeAlert = {
  limit: number;
  active: boolean;
  limitType: PayedOvertimeAlertLimit;
};

type PayedOvertimePhase = {
  extraHours: number;
  limit: number;
  name: number;
  uuid: string;
  type: PhaseType;
  daysMask?: string;
  customName?: string;
  dayTypeBasedOnSchedule?: PhaseDayType;
  additionalLimit?: number;
};

/** Used on reports */
type ReportDetailedActivePhase = {
  uuid: string;
  customName?: string;
  name: number;
  type: PhaseType;
  dayTypeBasedOnSchedule?: PhaseDayType;
  daysMask?: string;
};

type BusinessRuleNightShift = {
  active: boolean;
  name: number;
  nightShiftApplyEntirePeriod: boolean;
  nightShiftEndTime: string;
  nightShiftStartTime: string;
};

type BusinessRuleTolerance = {
  active: boolean;
  includeBreaks: boolean;
  limit: number;
  scope: "punch"; // TODO enum
  type: "tolerance"; // TODO enum
};

type BusinessRulePayedOvertime = {
  extraHoursBalanceAlert: PayedOvertimeAlert;
  hoursBankActive: boolean;
  hoursBankBalanceAlert: PayedOvertimeAlert;
  hoursBankHideOnMobiles: boolean;
  hoursBankMissingDayStrategy: HoursBankMissingDayStrategy | null;
  hoursBankRecurrenceInterval: number;
  hoursBankRecurrencePeriod: "month"; // TODO enum
  hoursBankStartDateNew: string | null;
  hoursBankStartDateOverride: string | null;
  phases: PayedOvertimePhase[];
  phasesSubtractionOrder: unknown[];
  ignoreOvertimeOnNonPlannedDays: boolean;
  ignoreOvertimeOnNonPlannedDaysThreshold: number;
};

type BusinessRuleOnCalls = {
  compensation: OnCallsCompensationType;
};

type BusinessRuleCrossShiftsPhase = {
  limit: number;
  name: string;
  uuid: string;
};

type BusinessRuleCrossShifts = {
  interval: 660;
  phases: BusinessRuleCrossShiftsPhase[];
};

export enum HoursDistributionType {
  everyHour = "every_hour",
  plannedHours = "planned_hours",
  notPlanned = "not_planned",
}

export type HoursDistributionRule = {
  uuid: string;
  name: string;
  type: HoursDistributionType;
  daysMask: string;
  startTime: number;
  endTime: number;
  includeBreaks: boolean;
  includeOvertime: boolean;
  extendToFullRange: boolean;
};

export type BusinessRuleHoursDistribution = {
  rules: HoursDistributionRule[];
};

type BusinessRule = {
  businessRulesGroupUuid: string;
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  endDate: string | null;
  rules: {
    [BusinessRuleType.nightShift]: BusinessRuleNightShift;
    [BusinessRuleType.payedOvertime]: BusinessRulePayedOvertime;
    [BusinessRuleType.tolerance]: BusinessRuleTolerance;
    [BusinessRuleType.onCalls]: BusinessRuleOnCalls;
    [BusinessRuleType.crossShiftsInterval]: BusinessRuleCrossShifts;
    [BusinessRuleType.hoursDistribution]: BusinessRuleHoursDistribution;
  };
  startDate: string;
  updatedAt: string;
  updatedBy: string | null;
  uuid: string;
};

type BusinessRuleGroup = {
  businessRules: BusinessRule[];
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  isDefault: boolean;
  isLocked: boolean;
  name: string;
  externalId: string | null;
  status: BusinessRuleGroupStatus;
  updatedAt: string;
  updatedBy: string | null;
  userProfileBusinessRulesGroups: BusinessGroupUserProfile[];
  uuid: string;
};

type HoursBankPayedRules = {
  _ruleType: "hours_bank"; // todo
  hoursBank: unknown | null; // todo
  extraHours: unknown | null; // todo
  hoursBankLimit: unknown | null; // todo
  extraHoursLimit: unknown | null; // todo
  hoursBankHoliday: unknown | null; // todo
  extraHoursHoliday: unknown | null; // todo
};

type SplitPositiveAndNegativeHours = {
  active: boolean;
  includeBreaks: boolean;
};

export enum HoursBankExpiredPhasesPositiveAction {
  keepInExpiredPhases = "keepInExpiredPhases",
  convertToExtraHours = "convertToExtraHours",
  convertOriginalHoursToExtraHours = "convertOriginalHoursToExtraHours",
}

export enum HoursBankExpiredPhasesNegativeAction {
  keepInExpiredPhases = "keepInExpiredPhases",
  convertToMissingHours = "convertToMissingHours",
}

type BusinessRulePayedOvertimeByUser = {
  phases: PayedOvertimePhase[];
  holidayStage: number;
  weekdayStage: number;
  hoursBankActive: boolean;
  hoursBankPayedRules: HoursBankPayedRules;
  hoursBankBalanceAlert: PayedOvertimeAlert | null; // todo not sure PayedOvertimeAlert
  hoursBankStartDateNew: string | null; // todo not sure string
  extraHoursBalanceAlert: PayedOvertimeAlert | null; // todo not sure PayedOvertimeAlert
  hoursBankHideOnMobiles: boolean;
  /** uuids */
  phasesSubtractionOrder: string[];
  hoursBankRecurrencePeriod: unknown | null; // todo
  hoursBankMissingDayStrategy: HoursBankMissingDayStrategy | null;
  hoursBankStartDateOverride: string | null; // todo not sure string
  hoursBankRecurrenceInterval: number | null; // todo not sure number
  splitPositiveAndNegativeHours: SplitPositiveAndNegativeHours;
  hoursBankExpiredPhasesPositiveAction: HoursBankExpiredPhasesPositiveAction;
  hoursBankExpiredPhasesNegativeAction: HoursBankExpiredPhasesNegativeAction;
};

type BusinessRuleGroupByUser = {
  uuid: string;
  companyUuid: string;
  businessRulesGroupUuid: string;
  rules: {
    [BusinessRuleType.tolerance]: BusinessRuleTolerance;
    [BusinessRuleType.nightShift]: BusinessRuleNightShift;
    [BusinessRuleType.payedOvertime]: BusinessRulePayedOvertimeByUser;
  };
  createdBy: string;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  hashPayedOvertime: number;
  suuid: string;
  groupStartDate: string;
  groupEndDate: string | null;
  userStartDate: string;
  userEndDate: string | null;
  startDate: string;
  endDate: string | null;
  businessRulesGroupName: "default" | string;
};

export type {
  EmployeeBusinessRulesGroup,
  BusinessRuleGroup,
  BusinessRule,
  BusinessRuleNightShift,
  BusinessRulePayedOvertime,
  BusinessRuleTolerance,
  BusinessRuleOnCalls,
  BusinessRuleCrossShifts,
  BusinessRuleCrossShiftsPhase,
  BusinessRuleGroupByUser,
  PayedOvertimePhase,
  ReportDetailedActivePhase,
};

export { BusinessRuleGroupStatus, BusinessRuleType, OnCallsCompensationType };
