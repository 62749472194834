import cx from "classnames";
import { stylesheet } from "astroturf";
import { IconRepeatActivity } from "../styled";

export const activitiesListItemStyles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
  cursor: default;
  border-bottom: 1px solid var(--colors-surface-150);

  .Start {
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;

    .Badge {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      height: 20px;
      font-size: 13px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-600);
      border: 1px solid var(--colors-surface-200);
      border-radius: var(--shapes-border-radius-default);
      background: var(--colors-surface-0);
      cursor: pointer;

      &.expanded {
        background: var(--colors-primary-50);
      }

      &:hover {
        background: var(--colors-primary-50);
        color: var(--colors-primary-500-p);
        border: 1px solid var(--colors-primary-500-p);
      }
    }

    .Texts {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .Client {
        padding-bottom: 4px;
        font-size: 12px;
        font-weight: var(--typography-font-weight-default);
        color: var(--colors-surface-800);
      }

      .Task {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-900-p);
      }

      .ProjCli {
        font-size: 13px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-700);
      }
    }
  }

  .End {
    display: flex;
    gap: 10px;
    align-items: center;


    .Duration {
      min-width: 38px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-surface-900-p);
      text-align: end;
    }

    .StartIcon {
      margin-inline-start: -42px;
      cursor: pointer;
      opacity: 0;
      inset-inline-end: 0;
    }
  }

  &:hover {
    background: var(--colors-surface-100);

    &.repeatable {
      .End {
        .Duration {
          opacity: 0;
        }

        .StartIcon {
          z-index: 1;
          opacity: 1;
        }
      }
    }
  }


  &:global(.wireframe) {
    @use 'styles/loading';

    .Start {
      .Texts > {
        .Client {
          @extend .wireframe;

          width: fit-content;
        }

        .Task {
          @extend .wireframe;

          width: fit-content;
        }

        .ProjCli {
          @extend .wireframe;

          width: fit-content;
        }
      }
    }

    .End {
      .Duration {
        @extend .wireframe;

        width: 40px;
      }

      .StartIcon {
        @extend .wireframe;

        border-radius: 50%;
      }
    }
  }
}
`;

export interface ActivitiesListItemProps extends React.HTMLProps<HTMLDivElement> {
  task: string;
  project: string;
  client?: string;
  duration: string;
  numItems?: number;
  onShowItems?: () => void;
  onRepeat?: () => void;
  wireframe?: boolean;
  collapsed?: boolean;
}

export function ActivitiesListItem(props: ActivitiesListItemProps) {
  const { client, project, collapsed, task, duration, numItems, onRepeat, onShowItems, className, wireframe, onClick } =
    props;
  return (
    <div
      className={cx(activitiesListItemStyles.Wrapper, className, {
        wireframe,
        [activitiesListItemStyles.repeatable]: onRepeat != null,
      })}
      onClick={onClick}
    >
      <div className={activitiesListItemStyles.Start}>
        {numItems != null && (
          <div
            className={cx(activitiesListItemStyles.Badge, {
              [activitiesListItemStyles.expanded]: !collapsed,
            })}
            onClick={onShowItems}
          >
            {numItems}
          </div>
        )}
        <div className={activitiesListItemStyles.Texts}>
          <div className={activitiesListItemStyles.Task}>{task}</div>
          <div className={activitiesListItemStyles.ProjCli}>{client ? `${project} / ${client}` : project}</div>
        </div>
      </div>
      <div className={activitiesListItemStyles.End}>
        <div className={activitiesListItemStyles.Duration}>{duration}</div>
        {onRepeat != null && (
          <IconRepeatActivity
            className={activitiesListItemStyles.StartIcon}
            onClick={(evt) => {
              evt.stopPropagation();
              onRepeat();
            }}
          />
        )}
      </div>
    </div>
  );
}
