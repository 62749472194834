import SocketIOClient from "socket.io-client";
import { Injectable } from "services/di";
import { getSocketIo } from "utils/get-socket-io";
import { AppStorage } from "services/app-storage";
import { EventEmitter } from "events";

export type EventAttrActivityUpdatedData = {
  uuid: string;
  date: string;
  status: string;
  taskUuid?: string;
  taskSource?: string;
  taskName?: string;
}

@Injectable()
export class ProjectsWsService extends EventEmitter {
  constructor(protected appStorage: AppStorage) {
    super();
  }

  public socketIo?: SocketIOClient.Socket | null;

  protected handleEvent(evt: { eventDefinitionName: string; attrs: any }) {
    if (evt.eventDefinitionName !== "projects:profileActivitiesUpdated") return;
    this.emit("activity-updated", evt.attrs.activityData as EventAttrActivityUpdatedData);
  }

  async connect(token?: string) {
    const socketIo = await getSocketIo(token);
    this.socketIo = socketIo;
    const profile = await this.appStorage.getProfile();
    socketIo.emit("commands", [
      {
        name: "join",
        args: {
          scope: "activities",
          companyUuid: profile.company.uuid,
          userProfileUuid: profile.uuid,
        },
      },
    ]);
    socketIo.on("systemEvent", (evt: { eventDefinitionName: string; attrs: any }) => this.handleEvent(evt));
  }

  async disconnect() {
    this.socketIo?.off("systemEvent");
    this.socketIo?.emit("commands", [{ name: "leave", args: { scope: "activities" } }]);
    this.removeAllListeners();
  }
}
