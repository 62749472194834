import { Component, MouseEvent } from "react";
import styled from "styled-components";
import BEM from "utils/BEM";
import { getImgUrl } from "utils/common";
import Overlay from "components/UI/Overlay";
import rtl from "styled-components-rtl";
import * as images from "../svg-images";

const tc = BEM.b("table-common");

const StyledDiv = styled.div`
  position: absolute;
  top: 17px;
  &.hint {
    padding: 6px !important;
  }
  img {
    width: 100%;
    height: 100%;
  }
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.333 4.667c.368 0 .667.298.667.666v1.334h1.333a.667.667 0 110 1.333H8v1.333a.667.667 0 01-1.333 0V8H5.333a.667.667 0 010-1.333h1.334V5.333c0-.368.298-.666.666-.666z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.333 14c1.601 0 3.07-.564 4.22-1.505l2.642 2.643a.667.667 0 10.943-.943l-2.643-2.643A6.667 6.667 0 107.333 14zm0-1.333A5.333 5.333 0 107.333 2a5.333 5.333 0 000 10.667z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 10px;
    z-index: 1;
    content: "";

    ${rtl`
      left: 10px; 
    `}
  }
`;

const StyledFullImg = styled.img`
  position: relative;
  border-radius: 10px;
`;

const FullImgWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.span``;

const CloseBtn = styled.button`
  position: absolute;
  ${rtl`
    right: 20px;
  `}
  top: 20px;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 4.293a1 1 0 00-1.414 1.414L10.586 12l-6.293 6.293a1 1 0 001.414 1.414L12 13.414l6.293 6.293a1 1 0 001.414-1.414L13.414 12l6.293-6.293a1 1 0 00-1.414-1.414L12 10.586 5.707 4.293z' fill='%23767676'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
`;

interface HintWithPhotoProps {
  imgId?: string;
  imgUrl?: string;
  fullImgUrl?: string;
}

interface HintWithPhotoState {
  hintVisible: boolean;
  imageVisible: boolean;
}

class HintWithPhoto extends Component<HintWithPhotoProps, HintWithPhotoState> {
  constructor(props: HintWithPhotoProps) {
    super(props);

    this.state = { hintVisible: false, imageVisible: false };
  }

  onMouseEnter = (): void => {
    this.setState({ hintVisible: true });
  };

  render(): JSX.Element | null {
    const { hintVisible, imageVisible } = this.state;
    const { imgId, imgUrl, fullImgUrl } = this.props;

    if (!imgId && (!imgUrl || !fullImgUrl)) {
      return null;
    }

    const img = imgId
      ? getImgUrl(imgId, "f_auto,q_auto/h_108,w_108,c_thumb,c_pad,fl_progressive.force_strip/")
      : imgUrl;
    const fullImg = imgId ? getImgUrl(imgId, "f_auto,q_auto/fl_progressive.force_strip/") : fullImgUrl;

    return (
      <Wrapper
        className={tc("photo")}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={(): void => this.setState({ hintVisible: false })}
        onClick={(ev: MouseEvent<HTMLSpanElement>): void => {
          ev.preventDefault();
          ev.stopPropagation();

          if ((ev.target as HTMLSpanElement)?.className?.indexOf?.("img-close-btn") > -1) {
            this.setState({ hintVisible: false });
            return;
          }

          this.setState({ imageVisible: true, hintVisible: false });
        }}
      >
        {images.punchesTabletPhoto({})}
        {hintVisible && (
          <StyledDiv className="hint" style={{ right: "5px" }}>
            <img src={img} alt="" />
          </StyledDiv>
        )}

        {imageVisible && (
          <Overlay
            test="mytest"
            dark
            isOpen={imageVisible}
            onClose={(): void => this.setState({ imageVisible: false })}
          >
            <FullImgWrapper>
              <StyledFullImg src={fullImg} alt="" />
              <CloseBtn className="img-close-btn" onClick={(): void => this.setState({ imageVisible: false })} />
            </FullImgWrapper>
          </Overlay>
        )}
      </Wrapper>
    );
  }
}

export default HintWithPhoto;
