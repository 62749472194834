import React from "react";
import { formatCnpj } from "../utils/common";
import BEM from "../utils/BEM";

const input = BEM.b("input");

class CnpjField extends React.Component {
  constructor(props) {
    super(props);
    const value = this.props.value || "";
    let formattedValue = "";
    if (value) {
      formattedValue = formatCnpj(value);
    }
    this.state = {
      value,
      formattedValue,
      isValid: this.props.isValid,
    };
  }
  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) {
      onRef(this);
    }
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isValid !== this.state.isValid) {
      this.setState({ isValid: nextProps.isValid });
    }
    if (nextProps.controlled) {
      if (nextProps.value !== this.state.value) {
        this.setState({ value: nextProps.value, formattedValue: formatCnpj(nextProps.value) });
      }
    }
  }
  getValue = () => this.state.value;
  getFormattedValue = () => this.state.formattedValue;
  valueChange = (ev) => {
    let value = ev.target.value.replace(/\D/g, "");
    value = value.substring(0, 14);
    const formattedValue = formatCnpj(value);
    this.setState({
      value,
      formattedValue,
    });
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };
  render() {
    const { formattedValue, isValid } = this.state;
    return (
      <input
        className={input({ error: !isValid, onboarding: this.props.onboarding })}
        onBlur={(ev) => {
          if (this.props.onBlur) {
            this.props.onBlur(ev);
          }
        }}
        type="text"
        name="cnpj"
        value={formattedValue}
        onChange={this.valueChange}
        placeholder="XX.XXX.XXX/XXXX-XX"
      />
    );
  }
}
export default CnpjField;
