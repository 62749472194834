import { Component, ContextType } from "react";
import BEM from "utils/BEM";
import "components/UI/Page/Page.scss";
import "styles/schedules-page.scss";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "components/UI/Select";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getTitle } from "utils/common";
import { TableButton, TableButtons } from "components/styled/Page";
import GlobalContext from "context/global-context";
import Pagination from "components/Pagination";
import ModalDialog from "components/UI/ModalDialog";
import { deleteBreak, getCustomBreaksListWithSchedules } from "utils/api/schedule";
import { CheckIcon } from "components/Activities/TimeTracker/components/TimerButtons";
import { iCellInfo } from "utils/tableHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ColumnAlign } from "components/TableCommon";
import { BreakWithSchedule, BreakStatus } from "utils/api/types";
import TablePage from "components/TablePage";
import AddNewBreakModal from "./AddNewBreakModal";
import DeleteBreakModal from "./DeleteBreakModal";

const b = BEM.b("schedules-page");
const p = BEM.b("page");

const NOT_PAID_ICON = "–";
const PAID_ICON = <CheckIcon />;
const MODAL_WIDTH = 452;

interface BreaksProps extends RouteComponentProps, WithTranslation {
  addNewBreakPopupVisible: boolean;
  closeNewBreakPopup: () => void;
}

export enum BreakStatusOptions {
  all = "",
  active = "active",
  archived = "archived",
}

interface BreaksState {
  breaks: BreakWithSchedule[];
  status: BreakStatusOptions;
  totalRecords: number;
  page: number;
  perPage: number;
  initialFetchDone: boolean;
  loading: boolean;
  deleteBreakInfo: BreakWithSchedule | null;
}

class Breaks extends Component<BreaksProps, BreaksState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: BreaksProps) {
    super(props);
    const { t } = props;

    this.state = {
      breaks: [],
      status: BreakStatusOptions.active,
      totalRecords: 0,
      page: 1,
      perPage: 25,
      initialFetchDone: false,
      loading: true,
      deleteBreakInfo: null,
    };

    document.title = getTitle(t("Schedule Breaks"));
  }

  componentDidMount() {
    void this.getState();
  }

  getState = async () => {
    const { initialFetchDone, loading, page, perPage, status } = this.state;

    if (!initialFetchDone || !loading) {
      this.setState({ loading: true, initialFetchDone: true });
      const company = await this.context.getCompany();

      let statusList = [status];
      if (status === BreakStatusOptions.all) {
        statusList = [BreakStatusOptions.active, BreakStatusOptions.archived];
      }
      const { content, metadata } = await getCustomBreaksListWithSchedules({
        perPage,
        page,
        statusList,
        companyUuid: company.uuid,
        requestedBy: window.global_store.profile.uuid,
      });

      const totalRecords = Number(metadata?.total) || 0;

      this.setState({
        breaks: content,
        loading: false,
        totalRecords,
        page: page || 1,
      });
    }
  };

  onDelete = async () => {
    await this.deleteBreak();
    this.closeActionModal();
    void this.getState();
  };

  closeActionModal = () =>
    this.setState({
      deleteBreakInfo: null,
    });

  deleteBreak = async () => {
    const { deleteBreakInfo } = this.state;

    this.setState({ loading: true });

    const company = await this.context.getCompany();

    const data = {
      companyUuid: company.uuid,
      uuid: deleteBreakInfo?.uuid ?? "",
      body: { content: { requestedBy: window.global_store.profile.uuid } },
    };

    await deleteBreak(data);

    this.setState({ loading: false });
  };

  getStatusActionLabel = (isUsed: boolean): string => {
    const { t } = this.props;
    return isUsed ? t(`${TranslationNamespaces.common}|Archive`) : t(`${TranslationNamespaces.common}|Delete`);
  };

  getStatusLabel = (value: string) => {
    const { t } = this.props;
    return t(value);
  };

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Name"),
        accessor: "name",
        minWidth: 160,
        style: { lineHeight: "36px" },
        Cell: (row: iCellInfo<BreakWithSchedule>) => (row.original.default ? t(row.value) : row.value),
      },
      {
        label: t("Worked Hours"),
        accessor: "isWorkingHours",
        minWidth: 100,
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<BreakWithSchedule>) => (row.value ? PAID_ICON : NOT_PAID_ICON),
      },
      {
        label: t("Status"),
        accessor: "status",
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<BreakWithSchedule>) => {
          const { original } = row;
          const { default: isDefault, schedules, status } = original;
          const isUsed = !!schedules?.length;
          const isArchived = status === BreakStatus.archived;

          return (
            <div>
              <span className={b("status-button", { inactive: row.value !== BreakStatus.active })}>{t(row.value)}</span>

              {!isDefault && !isArchived && (
                <TableButtons className="buttons">
                  <TableButton
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      this.setState({
                        deleteBreakInfo: original,
                      });
                    }}
                  >
                    {this.getStatusActionLabel(isUsed)}
                  </TableButton>
                </TableButtons>
              )}
            </div>
          );
        },
        minWidth: 120,
      },
    ];
    return columns;
  }
  render() {
    const { deleteBreakInfo, breaks, loading, status, totalRecords, page, perPage } = this.state;
    const { t, addNewBreakPopupVisible, closeNewBreakPopup } = this.props;

    return (
      <div>
        <div className={b()}>
          <TablePage
            filters={
              <div className={p("dropdown-first")}>
                <Select
                  value={status}
                  onChange={(value) => this.setState({ status: value, page: 1 }, () => this.getState())}
                  options={[
                    { value: BreakStatusOptions.all, label: t("All Breaks") },
                    { value: BreakStatusOptions.active, label: t("Active") },
                    { value: BreakStatusOptions.archived, label: t("Archived") },
                  ]}
                />
              </div>
            }
            rows={breaks}
            columns={this.getTableColumns()}
            showPagination
            noDataText=""
            pages={100}
            loading={loading}
            manual
            showDetailsRow={false}
            PaginationComponent={() => (
              <Pagination
                totalRecords={totalRecords}
                pageLimit={perPage}
                pageNeighbours={2}
                currentPage={page}
                onPageLimitChange={(pageLimit, currentPage) =>
                  this.setState({ perPage: pageLimit, page: currentPage }, () => this.getState())
                }
                onPageChanged={(val) => {
                  this.setState({ page: val.currentPage }, () => this.getState());
                }}
              />
            )}
            onPageChange={(val) => {
              this.setState({ page: val }, () => this.getState());
            }}
            customColumnsAvailable={false}
          />
        </div>

        <ModalDialog width={MODAL_WIDTH} isOpen={!!deleteBreakInfo} onClose={() => closeNewBreakPopup()} dark>
          <DeleteBreakModal breakInfo={deleteBreakInfo} onClose={this.closeActionModal} onYes={this.onDelete} />
        </ModalDialog>

        <ModalDialog width={MODAL_WIDTH} isOpen={addNewBreakPopupVisible} onClose={() => closeNewBreakPopup()} dark>
          <AddNewBreakModal
            onClose={() => {
              closeNewBreakPopup();
            }}
            onYes={() => {
              void this.getState();
              closeNewBreakPopup();
            }}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.schedules)(Breaks));
