import { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import Button from "components/controls/StyledButton";
import { ErrorLabel } from "components/UI/TextLabels";
import TextInputControl from "components/controls/StyledTextInput";
import CheckboxControl from "components/UI/NewCheckbox";
import TextareaControl from "components/controls/TextareaControl";
import InputFieldWithCopy from "components/controls/InputFieldWithCopy";
import DownloadControl from "components/DownloadControl";
import { getTitle, isDomainValid, getSystemName } from "utils/common";
import {
  createDomain,
  deleteDomain,
  getIdentityProviderByDomainName,
  upsertIdentityProviderByDomainName,
  listDomains,
  getDownloadCertificateUrl,
} from "utils/ssoApiUtils";
import sentryUtils from "utils/sentryUtils";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import CONFIG from "../../config";

const MainWrapper = styled.div`
  max-width: 420px;
  margin-top: 23px;
`;

const RowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-top: 32px;
  margin-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;

const UnverifiedLabel = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #a1b2cf;
`;

const MappingFieldsRow = styled.div`
  div:first-child {
    width: 49%;
  }
  display: flex;
  justify-content: space-between;
`;

const MAPPING_FIELDS = ["email"];
class SsoTab extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      domainCreated: false,
      ssoEnabled: false,
      isFetching: true,
    };

    document.title = getTitle(t("SSO Settings"));
  }

  componentDidMount() {
    const { t, onSave } = this.props;

    try {
      this.fetchData();
    } catch (error) {
      onSave({ notification: t("Failed to fetch sso settings"), notificationType: "error" });
    }
  }

  async fetchData() {
    const company = await this.context.getCompany();
    const { t, onSave } = this.props;
    let domainName = "";
    let issueUrl = "";
    let ssoLoginUrl = "";
    let certificate = "";
    let configurationAssertEndpoint = "";
    let configurationCertificate = "";
    let configurationEntityId = "";
    let homeUrl = "";
    let allowUnencryptedAssertion = false;
    let attributes = {};
    let isDomainVerified = false;
    let domainsResp;

    try {
      domainsResp = await listDomains({
        companyUuid: company.uuid,
      });
    } catch (error) {
      onSave({ notification: t("Failed to fetch sso settings"), notificationType: "error" });
    }

    const ssoEnabled = domainsResp && domainsResp.content && domainsResp.content.length > 0;

    if (ssoEnabled) {
      domainName = domainsResp.content[0].domainName;
      isDomainVerified = domainsResp.content[0].validatedAt;
      let idpResp;

      try {
        idpResp = await getIdentityProviderByDomainName({ companyUuid: company.uuid, domainName });
      } catch (error) {
        onSave({ notification: t("Failed to fetch sso settings"), notificationType: "error" });
        return;
      }

      const settings = idpResp.content;
      const { configurationSettings, identityProvider } = settings;
      configurationAssertEndpoint = configurationSettings.assertEndpoint;
      configurationCertificate = configurationSettings.certificate;
      configurationEntityId = configurationSettings.entityId;
      issueUrl = identityProvider ? identityProvider.settings.idp.metadataUrl : "";
      ssoLoginUrl = identityProvider ? identityProvider.settings.idp.ssoLoginUrl : "";
      homeUrl =
        identityProvider && identityProvider.settings.homeUrl
          ? identityProvider.settings.homeUrl
          : window.location.origin;
      allowUnencryptedAssertion = identityProvider?.settings?.sp?.allowUnencryptedAssertion;
      attributes = identityProvider ? identityProvider.settings.attributes : {};
      if (!Object.keys(attributes).length) {
        MAPPING_FIELDS.forEach((fieldName) => {
          attributes[fieldName] = "";
        });
      }
      certificate = identityProvider?.settings?.idp?.certificates?.length
        ? identityProvider.settings.idp.certificates[0]
        : ""; // get and set only first element from array
    }

    this.setState({
      ssoEnabled,
      domainName,
      domainCreated: ssoEnabled,
      homeUrl,
      attributes,
      isDomainVerified,
      issueUrl,
      ssoLoginUrl,
      certificate,
      configurationCertificate,
      configurationEntityId,
      configurationAssertEndpoint,
      allowUnencryptedAssertion,
      isFetching: false,
    });
  }

  onSave = async () => {
    const { onSave, t } = this.props;
    const { ssoEnabled, domainCreated } = this.state;
    const errors = this.validate();

    if (!errors) {
      try {
        if (ssoEnabled) {
          if (domainCreated) {
            await this.saveSettings();
          } else {
            await this.onCreateDomain();
          }
        } else {
          await this.onDeleteDomain();
        }

        this.setState({ ssoEnabled: false, isFetching: true }, () => {
          try {
            this.fetchData();
          } catch (error) {
            sentryUtils.sendError(error);

            onSave({ notification: t("Failed to fetch sso settings"), notificationType: "error" });
          }
        });
      } catch (error) {
        const notification = (error.errors?.[0] || error)?.message || t("Failed to save sso settings");
        sentryUtils.sendError(error);

        onSave({ notification, notificationType: "error" });
      }
    } else {
      this.setState({ errors });
    }
  };

  onCreateDomain = async () => {
    const company = await this.context.getCompany();
    const { domainName } = this.state;

    await createDomain({
      domainName,
      companyUuid: company.uuid,
      createdBy: window.global_store.profile.uuid,
    });
  };

  onDeleteDomain = async () => {
    const company = await this.context.getCompany();
    const { domainName } = this.state;

    await deleteDomain({
      domainName,
      companyUuid: company.uuid,
      deletedBy: window.global_store.profile.uuid,
    });
  };

  saveSettings = async () => {
    const { domainName, issueUrl, ssoLoginUrl, certificate, homeUrl, attributes, allowUnencryptedAssertion } =
      this.state;
    const company = await this.context.getCompany();
    const idp = {
      metadataUrl: issueUrl,
    };

    if (ssoLoginUrl) {
      idp.ssoLoginUrl = ssoLoginUrl;
      idp.certificates = [certificate];
    }

    await upsertIdentityProviderByDomainName({
      domainName,
      companyUuid: company.uuid,
      updatedBy: window.global_store.profile.uuid,
      settings: {
        homeUrl: homeUrl || window.location.origin,
        sp: {
          allowUnencryptedAssertion,
        },
        attributes,
        idp,
      },
    });
  };

  validate = () => {
    const { domainName, ssoEnabled, domainCreated, attributes } = this.state;
    const { t } = this.props;
    let errors = {};

    if (ssoEnabled) {
      const domainError = this.validateDomain(domainName);
      if (domainError) {
        errors.domainName = domainError;
      }

      if (domainCreated) {
        if (!attributes || !Object.keys(attributes).length) {
          errors.attributesError = t("Please fill out fields mapping");
        } else {
          MAPPING_FIELDS.forEach((fieldName) => {
            if (!attributes[fieldName]) {
              errors.attributes = {};
              errors.attributes[fieldName] = t("Field mapping is required");
            }
          });
        }
      }
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  };

  validateDomain = () => {
    let error = "";
    const { t } = this.props;
    const { domainName } = this.state;

    if (!domainName) {
      error = t("Domain name can't be empty");
    } else if (!isDomainValid(domainName)) {
      error = t("Domain name is invalid");
    }

    return error;
  };

  downloadCertificate = async (format) => {
    const { domainName } = this.state;
    getDownloadCertificateUrl({ domainName, format });
  };

  render() {
    const { t, isLoading } = this.props;
    const {
      ssoEnabled,
      ssoLoginUrl,
      issueUrl,
      domainCreated,
      certificate,
      configurationCertificate,
      configurationEntityId,
      configurationAssertEndpoint,
      domainName,
      isDomainVerified,
      homeUrl,
      allowUnencryptedAssertion,
      errors,
      attributes,
      isFetching,
    } = this.state;

    return (
      <MainWrapper>
        {!isFetching && (
          <CheckboxControl
            ios
            checked={ssoEnabled}
            label={t("Single Sign-on (SSO)")}
            onChange={(checked) => {
              this.setState({ ssoEnabled: checked });
            }}
          />
        )}

        {isFetching && <div>{t(`${TranslationNamespaces.common}|Loading...`)}</div>}
        {ssoEnabled && (
          <>
            {/* <RowTitle>{t('Requirements')}</RowTitle> */}
            {/*
            <Label>
              <input
                name="requirements"
                type="radio"
                checked={requirement === 'optional'}
                onChange={() => this.setState({ requirement: 'optional' })}
              />
              {t('Optional')}
            </Label>
            <Label>
              <input
                name="requirements"
                type="radio"
                checked={requirement === 'for-all-employees'}
                onChange={() => this.setState({ requirement: 'for-all-employees' })}
              />
              {t('Required for All Employees')}
            </Label> */}

            {/* <RowTitle>{t('Select Provider')}</RowTitle>
            <Label>
              <input
                name="provider"
                type="radio"
                checked={provider === 'google'}
                onChange={() => this.setState({ provider: 'google' })}
              />
              Google
            </Label>
            <Label>
              <input
                name="provider"
                type="radio"
                checked={provider === 'active-dirrectory'}
                onChange={() => this.setState({ provider: 'active-dirrectory' })}
              />
              {t('MS Active Directory')}
            </Label>
            <Label>
              <input
                name="provider"
                type="radio"
                checked={provider === 'saml'}
                onChange={() => this.setState({ provider: 'saml' })}
              />
              {t('Custom SAML')}
            </Label> */}
            <RowTitle>{t("1. Verify your domain")}</RowTitle>
            <div>
              <FieldWrapper fieldName={t("Domain Name")} width="100%" loading={isLoading}>
                <TextInputControl
                  disabled={domainCreated}
                  value={domainName}
                  error={errors && errors.domainName}
                  placeholder="domain.com"
                  onChange={(value) => this.setState({ domainName: value })}
                />
              </FieldWrapper>
              {errors?.domainName && <ErrorLabel>{errors.domainName}</ErrorLabel>}
            </div>

            {domainCreated && (
              <div>
                <RowTitle>{t("2. Add SSO configuration")}</RowTitle>
                {!isDomainVerified ? (
                  <UnverifiedLabel>{t("domain-under-verification")}</UnverifiedLabel>
                ) : (
                  <div>
                    <FieldWrapper fieldName={t("Home url")} width="100%" loading={isLoading}>
                      <TextInputControl
                        disabled
                        value={homeUrl}
                        error={errors?.homeUrl}
                        placeholder={CONFIG.globalMode ? "https://day.io" : "https://oitchau.com"}
                        onChange={(value) => this.setState({ homeUrl: value })}
                      />
                    </FieldWrapper>
                    {errors?.homeUrl && <ErrorLabel>{errors.homeUrl}</ErrorLabel>}
                    <FieldWrapper width="100%" loading={isLoading}>
                      <CheckboxControl
                        checked={allowUnencryptedAssertion}
                        label={t("Allow unencrypted assertion")}
                        onChange={(val) => {
                          this.setState({ allowUnencryptedAssertion: val });
                        }}
                      />
                    </FieldWrapper>
                    <FieldWrapper fieldName={t("Issue URL (IdP metadata)")} width="100%" loading={isLoading}>
                      <TextInputControl
                        value={issueUrl}
                        error={errors?.issueUrl}
                        placeholder={t("The issuer URL of the IdP")}
                        onChange={(value) => this.setState({ issueUrl: value })}
                      />
                    </FieldWrapper>
                    {errors?.issueUrl && <ErrorLabel>{errors.issueUrl}</ErrorLabel>}

                    <FieldWrapper fieldName={t("Single Sign On URL")} width="100%" loading={isLoading}>
                      <TextInputControl
                        value={ssoLoginUrl}
                        error={errors?.ssoLoginUrl}
                        placeholder={t("The url of your indentify provider login endpoint")}
                        onChange={(value) => this.setState({ ssoLoginUrl: value })}
                      />
                    </FieldWrapper>
                    {errors?.ssoLoginUrl && <ErrorLabel>{errors.ssoLoginUrl}</ErrorLabel>}

                    <FieldWrapper fieldName={t("Certifcate (x.509)")} width="100%" loading={isLoading}>
                      <TextareaControl
                        placeholder={t("IDD Public certificate")}
                        rows={5}
                        value={certificate}
                        error={errors?.certificate}
                        onChange={(ev) => {
                          this.setState({ certificate: ev.target.value });
                        }}
                      />
                    </FieldWrapper>
                    {errors?.certificate && <ErrorLabel>{errors.certificate}</ErrorLabel>}
                    <FieldWrapper fieldName={t("Fields mapping")} width="100%" loading={isLoading}>
                      {MAPPING_FIELDS.map((fieldName) => (
                        <>
                          <MappingFieldsRow>
                            <TextInputControl disabled value={fieldName} placeholder={fieldName} />
                            <TextInputControl
                              value={attributes[fieldName]}
                              error={errors?.attributes && errors.attributes[fieldName]}
                              placeholder={fieldName}
                              onChange={(value) => {
                                const newAttributes = {
                                  ...attributes,
                                };
                                newAttributes[fieldName] = value;
                                this.setState({ attributes: newAttributes });
                              }}
                            />
                          </MappingFieldsRow>
                          {errors?.attributes && errors.attributes[fieldName] && (
                            <ErrorLabel>{errors.attributes[fieldName]}</ErrorLabel>
                          )}
                        </>
                      ))}
                    </FieldWrapper>
                    {errors?.attributesError && <ErrorLabel>{errors.attributesError}</ErrorLabel>}
                  </div>
                )}
              </div>
            )}
            {configurationEntityId ? (
              <div>
                <RowTitle>{t("3. Add {{systemName}} configuration to IdP", { systemName: getSystemName() })}</RowTitle>
                <div>
                  <FieldWrapper
                    fieldName={t("{{systemName}} Audience (EntityId, metadata.xml)", { systemName: getSystemName() })}
                    width="100%"
                    loading={isLoading}
                  >
                    <InputFieldWithCopy value={configurationEntityId} copyButtonEnabled />
                  </FieldWrapper>

                  <FieldWrapper
                    fieldName={t("{{systemName}} Assert endpoint (ASC Url)", { systemName: getSystemName() })}
                    width="100%"
                    loading={isLoading}
                  >
                    <InputFieldWithCopy value={configurationAssertEndpoint} copyButtonEnabled />
                  </FieldWrapper>

                  <FieldWrapper
                    fieldName={t("{{systemName}} Certifcate (x.509)", { systemName: getSystemName() })}
                    width="100%"
                    loading={isLoading}
                  >
                    <InputFieldWithCopy value={configurationCertificate} copyButtonEnabled />
                    {/* <TextareaControl
                    // placeholder={t('IDD Public certificate')}
                    rows={5}
                    value={certificate}
                    onChange={(ev) => {
                      this.setState({ certificate: ev.target.value });
                    }}
                  /> */}
                  </FieldWrapper>
                  {configurationCertificate && (
                    <>
                      <br />
                      <DownloadControl
                        modifiers={{ filled: true }}
                        placeholder={t("Download certificate")}
                        options={[
                          { label: "DER", value: "der" },
                          { label: "PEM", value: "pem" },
                        ]}
                        onChange={(value) => {
                          this.downloadCertificate(value);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )}
        {!isLoading ? (
          <ButtonWrapper>
            <Button
              loading={isLoading}
              value={t(`${TranslationNamespaces.common}|Save`)}
              state="primary"
              onClick={this.onSave}
            />
          </ButtonWrapper>
        ) : null}
      </MainWrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.companySecurity])(SsoTab);
