import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import { getLocations } from "utils/apiHelpers";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import { LocationInGlobalContextEmployee, Location } from "types/models/location";
import { hasPermisionAccess } from "utils/common";

export interface LocationDropdownOption<TValue> {
  value: TValue;
  label: string;
  timeZone: string;
}

interface LocationsDropdownProps<TValue> extends WithTranslation {
  disabled?: boolean;
  value: TValue;
  isMulti?: boolean;
  placeholder: string;
  onChange: (v: TValue, option?: LocationDropdownOption<TValue>) => void;
  onlyActive?: boolean;
  employeeUuid: string | null;
  uuid?: boolean;
  withCode?: boolean;
  withEmpty?: boolean;
  locationByPermission?: boolean;
  employeeLocations?: (Location | LocationInGlobalContextEmployee)[];
}

function LocationsDropdown<TValue extends string>({
  t,
  withEmpty,
  employeeUuid,
  employeeLocations,
  withCode,
  uuid,
  onlyActive,
  disabled,
  value,
  isMulti,
  onChange,
  placeholder,
  locationByPermission,
}: LocationsDropdownProps<TValue>) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<LocationDropdownOption<TValue>[]>([]);
  const [allLocations, setAllLocations] = useState<Location[]>([]);

  useEffect(() => {
    async function uploadLocations() {
      const response = await getLocations();
      let items = response.locations || response || [];
      if (onlyActive) {
        items = items.filter((item) => item.active);
      }
      setAllLocations(items);
    }

    void uploadLocations();
  }, [onlyActive]);

  useEffect(() => {
    function getLocationsOptions(locations: Location[]) {
      const items = [];
      if (withEmpty) {
        items.push({ label: placeholder, value: "" });
      }

      items.push(...locations);

      return locations.map(
        (item) =>
          ({
            label: withCode ? `${item.code} - ${item.name}` : item.name,
            value: (uuid ? item.uuid : item.id) as TValue,
            timeZone: item.time_zone,
          } as LocationDropdownOption<TValue>),
      );
    }

    const { profile } = window.global_store;
    const { locations: currentProfileLocations } = profile;
    const isAdmin =
      profile?.role === "employer" ||
      profile?.permission_roles?.some(
        (pr) => pr.name === PermissionRoleName.admin || pr.name === PermissionRoleName.owner,
      );

    let items: Location[] = [];

    if (!employeeLocations?.length || (locationByPermission && hasPermisionAccess(PermissionSectionName.locations))) {
      items = [...allLocations];
    } else if (employeeLocations?.length) {
      items = allLocations.filter((item) => employeeLocations.find((i) => i.uuid === item.uuid));
    } else if (!isAdmin && currentProfileLocations?.length) {
      items = allLocations.filter((item) => currentProfileLocations.find((i) => i.uuid === item.uuid));
    }

    setOptions(getLocationsOptions(items));
    setIsLoading(false);
  }, [employeeUuid, allLocations]);

  return (
    <SelectControl<TValue>
      disabled={disabled}
      options={options}
      onChange={(v: TValue) =>
        onChange(
          v,
          options.find((o) => o.value === v),
        )
      }
      isLoading={isLoading}
      value={value}
      isSearchable
      isMulti={isMulti}
      placeholder={placeholder || t("Please select..")}
    />
  );
}

export default withTranslation(TranslationNamespaces.employeesPage)(LocationsDropdown);
