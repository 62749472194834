import { RequestUserProfile } from "./userProfile";

enum RequestApprovalFlowStatus {
  pending = "pending",
  approved = "approved",
  declined = "declined",
  ignored = "ignored",
}

enum RequestApprovalFlowTitle {
  supervisor = "Supervisor",
  owner = "Owner",
}

enum RequestLogAction {
  created = "created",
  statusUpdated = "statusUpdated",
}

enum RequestLogActorType {
  userProfile = "userProfile",
}

enum RequestSubTypeLimitType {
  month = "month",
}

enum RequestComplianceResetRule {
  calendarYear = "calendarYear",
  startDate = "startDate",
}

enum RequestLegacyTypeName {
  vacation = "vacation",
}

enum RequestLegacyReason {
  vacation = "vacation",
}

enum RequestTypeName {
  vacation = "vacation",
  medical = "medical",
  other = "custom",
  overtime = "overtime",
  hbConvert = "hoursBankToExtraHoursConversion",
  scheduleAssignment = "scheduleAssignment",
}

enum RequestHoursCalculationType {
  noCompensation = "noCompensation",
  compensateMissedHours = "compensateMissedHours",
}

enum RequestApprovalFlowApproverType {
  role = "role",
  userProfile = "userProfile",
}

enum RequestCustomFieldType {
  input = "input",
  multilineInput = "multilineInput",
}

enum OvertimeRequestValidityType {
  entryEarly = "entryEarly",
  exitLate = "exitLate",
}

type RequestApprovalFlowApprover = {
  type: RequestApprovalFlowApproverType | "";
  /** null added by FE in requestHelpers.ts */
  uuid: string | null;
  /** Could be added on FE side - requestHelpers.ts */
  label?: string;
  options?: {
    level: number;
  };
};

type RequestApprovalFlow = {
  approver: RequestApprovalFlowApprover;
  /** uuids */
  canChangeStatus: string[];
  reason: string | null;
  stage: number;
  status: RequestApprovalFlowStatus;
  statusUpdatedAt: string | null;
  statusUpdatedBy: string | null;
  subtitle: string | null;
  title: RequestApprovalFlowTitle;
};

type RequestAttachment = {
  /** Piece of url */
  cloudinaryId: string;
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  deletedAt: string | null;
  externalId: string | null;
  mimeType: string;
  requestUuid: string;
  /** Full url */
  thumbnailUrl: string;
  /** Filename */
  title: string;
  updatedAt: string;
  updatedBy: string;
  /** Full url */
  url: string;
  uuid: string;

  /** added on FE */
  loading?: boolean;
};

type RequestComment = {
  active: boolean;
  required: boolean;
  value: string;
};

type RequestLogActionDetails = {
  approvalStatus: RequestApprovalFlowStatus;
  approvalStatusReason: string;
  /** 100 - bypass approve */
  approvalStage: number;
  autoApproval: boolean;
};

type RequestLogActor = {
  actorType: RequestLogActorType;
  avatarId: string | null;
  avatarVersion: unknown | null; // todo
  fullName: string;
  jobTitle: string | null;
  uuid: string;
};

type RequestLog = {
  action: RequestLogAction;
  actionDetails: RequestLogActionDetails | null;
  actor: RequestLogActor;
  actorType: RequestLogActorType;
  actorUuid: string;
  companyUuid: string;
  createdAt: string;
  requestUuid: string;
  uuid: string;
};

type RequestSubTypeApprovalFlow = {
  approver: RequestApprovalFlowApprover;
  autoApproval: boolean;
  limit: string | number | null;
  limitType: RequestSubTypeLimitType;
  stage: number;
};

type RequestSubTypeAttachment = {
  active: boolean;
  lockAfterDays: number | null;
  required: boolean;
};

type RequestSubTypeComment = {
  active: boolean;
  required: boolean;
  configurable: boolean;
};

type RequestComplianceRules = {
  daysBeforeRequestDate: { days: number; active: boolean };
  predefinedPeriods: { active: boolean; values: number[] };
  totalDaysLimit: {
    days: number;
    active: boolean;
    resetRule: RequestComplianceResetRule;
    adjustFirstPeriodProportionally: boolean;
  };
};

type RequestCustomField = {
  externalId?: string;
  active: boolean;
  fieldType: RequestCustomFieldType;
  iconKey: string | null;
  name: string;
  required: boolean;

  /** Absent in request settings */
  value?: string;
};

enum RequestOvertimeSubTypes {
  overtime = "overtime",
  overtimeEnableDay = "overtimeEnableDay",
}

enum RequestSubtypeSpecificFieldTypes {
  adjustment = "adjustment",
  dayRange = "dayRange",
  schedule = "schedule",
}

type RequestTypeSpecificField = {
  name: string;
  active: boolean;
  required: boolean;
  configurable: boolean;
  fieldType?: RequestSubtypeSpecificFieldTypes;

  /** Absent in request settings */
  value?: any; // string | number | { phaseId: string; phaseValue: number } | null;
};

type RequestManager = {
  name: string;
};

type RequestSubType = {
  active: boolean;
  approvalFlow: RequestSubTypeApprovalFlow[];
  attachment: RequestSubTypeAttachment;
  comment: RequestSubTypeComment;
  companyUuid: string;
  complianceRules: RequestComplianceRules | null;
  createdAt: string;
  createdBy: string;
  customFields: RequestCustomField[];
  subtypeSpecificFields: RequestTypeSpecificField[];
  deletedAt: string | null;
  externalId: string;
  hoursCalculationType: RequestHoursCalculationType;
  discountDsr: boolean;
  name: string;
  nameId: string;
  paid: boolean;
  requestSubtypeTemplateUuid: string;
  requestType: RequestTypeName;
  translationKey: string;
  /** permission role uuids */
  visibilityPermissionRoles: string[];
  updatedAt: string;
  updatedBy: string;
  uuid: string;
};

/** RequestsPage, EmployeeRequests, EmployeeRequestDetails */
type Request = {
  allDay: boolean;
  isNightShift: boolean;
  approvalFlow: RequestApprovalFlow[];
  approvalStage: number;
  approvalStatus: RequestApprovalFlowStatus;
  attachments: RequestAttachment[];
  comment: RequestComment;
  companyUuid: string;
  createdAt: string;
  createdBy: RequestUserProfile;
  customFields: RequestCustomField[];
  subtypeSpecificFields: RequestTypeSpecificField[];
  deletedAt: string | null;
  employee: RequestUserProfile;
  endTime: string;
  externalId: unknown | null; // todo
  externalSource: unknown | null; // todo
  hoursCalculationType: RequestHoursCalculationType;
  legacyReason: RequestLegacyReason;
  legacyType: RequestLegacyTypeName;
  locked: boolean;
  logs: RequestLog[];
  manager?: RequestManager;
  metadata: unknown | null; // todo
  paid: boolean;
  requestSubtypeState: RequestSubType;
  requestSubtypeUuid: string;
  requestType: RequestTypeName;
  startTime: string;
  syncedAt: string | null;
  timezone: string;
  upToDate: boolean;
  updatedAt: string;
  userProfileUuid: string;
  uuid: string;
  version: number;
  /** Overtime request */
  validityType?: OvertimeRequestValidityType;
  /** Overtime request */
  validity?: number;
};

type CreateRequestPayload = {
  requestType: RequestTypeName;
  requestSubtypeUuid: string;
  startTime: string;
  endTime: string;
  allDay: boolean;
  isNightShift: boolean;
  uuid?: string;
  userProfileUuids: string[];
  userProfileUuid?: string;
  attachments?: { uuid: string; cloudinaryId: string }[];
  comment?: { value: string };
  customFields: Omit<RequestCustomField, "required" | "active" | "iconKey" | "fieldType">[];
  subtypeSpecificFields: Omit<RequestTypeSpecificField, "required" | "active" | "configurable">[];
  validity?: number;
  validityType?: OvertimeRequestValidityType;
  createdBy: string;
};

type RequestListTableData = {
  id: string;
  request: Request;
  employee: RequestUserProfile;
  cpf: string;
  pis: string;
  matricula: string;
  category: string;
  subCategory: string;
  startDate: string;
  when: string;
  timezone: string;
  requestedOn: string;
  reviewedBy: string;
  status: RequestApprovalFlowStatus;
  supervisor: unknown | null; // todo
  showButtons: boolean;
  options: string;
  approvalFlow: RequestApprovalFlow[];
};

type RequestType = {
  active: boolean;
  companyUuid: string;
  createdAt: string;
  externalId: string | null;
  name: RequestTypeName | string;
  requestTypeTemplateUuid: string;
  subtypes: RequestSubType[];
  updatedAt: string;
  updatedBy: string | null;
  uuid: string;
  userDefined: boolean;
};

type DailySummaryRequest = {
  allDay: boolean;
  approvalStatus: RequestApprovalFlowStatus;
  endDate: string;
  endTime: string;
  paid: boolean;
  payed: boolean;
  requestType: RequestTypeName;
  startDate: string;
  startTime: string;
  timezone: string;
  type: RequestTypeName;
  upToDate: boolean;
  uuid: string;
};

/** EmployeeRequests.tsx */
type EmployeeRequestListTableData = {
  uuid: string;
  employee: RequestUserProfile;
  category: RequestTypeName;
  subCategory: unknown;
  startDate: string;
  timezone: string;
  when: string;
  requestedOn: string;
  status: RequestApprovalFlowStatus;
};

enum CalendarRequestType {
  vacation = "vacation",
  medical = "medical",
  custom = "custom",
}

enum CalendarGroupType {
  self = "self",
  team = "team",
  department = "department",
  subsidiary = "subsidiary",
  company = "company",
}

type IcalCalendar = {
  uuid: string;
  icalUrl: string;
  name: string;
  visibleRequestTypes: CalendarRequestType[];
  visibleGroupTypes: CalendarGroupType;
  groupUuid?: string;
};

type RequestSettings = Request & { subtypes: RequestSubType[] };

export type {
  Request,
  RequestListTableData,
  RequestType,
  EmployeeRequestListTableData,
  RequestSubType,
  RequestSubTypeApprovalFlow,
  RequestAttachment,
  DailySummaryRequest,
  IcalCalendar,
  RequestCustomField,
  RequestSettings,
  RequestComplianceRules,
  CreateRequestPayload,
  RequestLog,
  RequestApprovalFlow,
  RequestApprovalFlowApprover,
  RequestTypeSpecificField,
  RequestLogActionDetails,
};

export {
  RequestApprovalFlowStatus,
  RequestLogAction,
  RequestTypeName,
  RequestHoursCalculationType,
  RequestApprovalFlowApproverType,
  RequestSubTypeLimitType,
  RequestComplianceResetRule,
  RequestCustomFieldType,
  CalendarRequestType,
  CalendarGroupType,
  OvertimeRequestValidityType,
  RequestOvertimeSubTypes,
  RequestSubtypeSpecificFieldTypes,
};
