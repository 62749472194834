import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import SchedulesManagementPage from "components/Schedules/SchedulesManagementPage";
import ScheduleCreate from "components/Schedules/ScheduleCreate";
import ScheduleCreateException from "components/Schedules/ScheduleCreateException";
import ScheduleDetails from "components/Schedules/ScheduleDetails";
import ScheduleExceptionPage from "components/Schedules/ScheduleExceptionPage";
import ScheduleImport from "components/Schedules/ScheduleImport";
import ScheduleExceptionsPageNew from "components/Schedules/ScheduleExceptionsPageNew";
import BreaksPage from "components/Schedules/Breaks/BreaksPage";

const Schedules = ({ match: { path } }: RouteComponentProps): JSX.Element => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict exact from={path} to={`${path}/`} />

      <Redirect exact from={`${path}/exception`} to={`${path}/`} />

      <Redirect strict from={`${path}/exception/:exceptionUuid/`} to={`${path}/exception/:exceptionUuid`} />
      <Route
        path={`${path}/exception/:exceptionUuid`}
        render={(p: RouteComponentProps<{ exceptionUuid: string }>): JSX.Element => (
          <ScheduleExceptionPage {...p} exceptionUuid={p.match.params.exceptionUuid} />
        )}
      />

      <Redirect strict from={`${path}/:scheduleUuid/`} to={`${path}/:scheduleUuid`} />
      <Redirect strict from={`${path}/create/`} to={`${path}/create`} />

      <Route path={`${path}/create`} component={ScheduleCreate} />
      <Redirect strict from={`${path}/create-exception/`} to={`${path}/create-exception`} />
      <Route path={`${path}/create-exception`} component={ScheduleCreateException} />

      <Redirect strict from={`${path}/import/`} to={`${path}/import`} />
      <Route path={`${path}/import`} component={ScheduleImport} />

      <Redirect strict from={`${path}/exceptions/`} to={`${path}/exceptions`} />
      <Route path={`${path}/exceptions`} component={ScheduleExceptionsPageNew} />

      <Redirect strict from={`${path}/breaks/`} to={`${path}/breaks`} />
      <Route path={`${path}/breaks`} component={BreaksPage} />

      <Route
        path={`${path}/:scheduleUuid`}
        render={(p: RouteComponentProps<{ scheduleUuid: string }>): JSX.Element => (
          <ScheduleDetails {...p} scheduleUuid={p.match.params.scheduleUuid} />
        )}
      />

      <Route exact strict path={`${path}/`} component={SchedulesManagementPage} />
    </Switch>
  </Suspense>
);

export default Schedules;
