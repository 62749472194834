import { TFunction } from "react-i18next";
import { FailedOperationDetails, OperationName, OperationResultAttrs, OperationStatus } from "types/models/operations";
import config from "../../config";

type Notification = {
  name: OperationName;
  status: OperationStatus;
  failed: FailedOperationDetails[];
} & OperationResultAttrs;

const getUrl = ({ name, targetObjectUuid }: Notification) => {
  let url = `https://${config.hostname}/`;

  switch (name) {
    case OperationName.bulkAssignUserProfilesToSchedule:
    case OperationName.bulkUnassignUserProfilesFromSchedule:
      url += `schedules/${targetObjectUuid}`;
      break;
    case OperationName.bulkAssignUserProfilesToPayrollGroup:
      url += `payroll/payroll-groups/${targetObjectUuid}`;
      break;
    default:
      url = "";
      break;
  }

  return url;
};

const getEventStr = (name: OperationName, t: TFunction) => {
  let event = "";

  switch (name) {
    case OperationName.bulkAssignUserProfilesToSchedule:
      event = t("Assign users to schedule");
      break;
    case OperationName.bulkUnassignUserProfilesFromSchedule:
      event = t("Unassign users from schedule");
      break;
    case OperationName.bulkAssignUserProfilesToPayrollGroup:
      event = t("Assign users to payroll group");
      break;
    case OperationName.bulkCreateScheduleException:
      event = t("Create schedule exception");
      break;
    case OperationName.bulkCreateRequest:
      event = t("Create request");
      break;
    default:
      event = t(name);
      break;
  }

  return event;
};

export const generateStr = (notificationObj: Notification, t: TFunction) => {
  const arr = [];
  const { name, targetObjectName, targetObjectUuid, status, failed } = notificationObj;

  arr.push(getEventStr(name, t));

  if (targetObjectName) {
    if (targetObjectUuid) {
      const url = getUrl(notificationObj);

      if (url) {
        arr.push(`<a href="${url}">${targetObjectName}</a>`);
      }
    } else {
      arr.push(targetObjectName);
    }
  }

  if (status) {
    arr.push(status === OperationStatus.pending ? t("started") : t(status));
  }

  if (failed.length) {
    arr.push(t("with some errors"));
  }

  return arr.join(" ");
};
