import { MouseEvent } from "react";
import styled from "styled-components";

const Wrapper = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  outline: none;
  background: var(--colors-surface-50, #f7f9fc);
  svg {
    width: 20px;
    height: 20px;
    fill: var(--colors-surface-500, #a0aec6);
  }
  &:hover {
    background: var(--colors-surface-100, #ecf2fa);
    svg {
      fill: var(--colors-surface-900-p, #434e6c);
    }
  }
`;

type ViewPunchIconButtonProps = {
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

const ViewPunchIconButton = ({ onClick, disabled, className, children }: ViewPunchIconButtonProps) => (
  <Wrapper onClick={onClick} disabled={disabled} className={`icon-button${className ? ` ${className}` : ""}`}>
    {children}
  </Wrapper>
);

export default ViewPunchIconButton;
