import {
  AssociatedSeatsResponse,
  BillingData,
  BillingDataResponse,
  Coupon,
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
  Customer,
  InvoiceResponse,
  PendingInvoiceResponse,
  Product,
  PromotionCode, SelectObjectivesRequest,
  SetTrialSubscriptionPayload,
  UpgradePlanRequest,
} from "types/models/subscription";
import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";

export class SubscriptionApi {
  static async getCustomer(companyUuid: string): Promise<Customer> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/plans/customer`, CONFIG.client_admin_api);
  }

  static async getProductPlansForCompany(companyUuid: string): Promise<Product[]> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/plans/customer/products`, CONFIG.client_admin_api);
  }

  static async validateCouponByCode(companyUuid: string, code: string): Promise<PromotionCode> {
    return sendRequest(
      {},
      "GET",
      `/companies/${companyUuid}/plans/customer/find-coupon?code=${code}`,
      CONFIG.client_admin_api,
    );
  }

  static async getSubscriptionCoupon(companyUuid: string, subscriptionUuid: string): Promise<Coupon> {
    return sendRequest(
      {},
      "GET",
      `/companies/${companyUuid}/plans/customer/subscription/${subscriptionUuid}/coupon`,
      CONFIG.client_admin_api,
    );
  }

  static async createSubscription(
    companyUuid: string,
    payload: CreateSubscriptionPayload,
  ): Promise<CreateSubscriptionResponse> {
    return sendRequest(
      payload,
      "POST",
      `/companies/${companyUuid}/plans/customer/create-subscription`,
      CONFIG.client_admin_api,
    );
  }

  static async getPublicKey(companyUuid: string): Promise<string> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/plans/public-key`, CONFIG.client_admin_api);
  }

  static getBillingInfo(companyUuid: string): Promise<BillingDataResponse> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/plans/customer/billing-info`, CONFIG.client_admin_api);
  }
  static setBillingInfo(companyUuid: string, payload: BillingData): Promise<void> {
    return sendRequest(
      payload,
      "PUT",
      `/companies/${companyUuid}/plans/customer/billing-info`,
      CONFIG.client_admin_api,
    );
  }

  static upgradePlan(companyUuid: string, subscriptionUuid: string, payload: UpgradePlanRequest): Promise<void> {
    return sendRequest(
      payload,
      "PUT",
      `/companies/${companyUuid}/plans/customer/subscription/${subscriptionUuid}/plan`,
      CONFIG.client_admin_api,
    );
  }

  static getCompanyAssociatedProducts(): APIResponse<AssociatedSeatsResponse> {
    return sendRequest({}, "GET", `/company/associate_products`);
  }

  static clearAutoUpgradeNotification(companyUuid: string, subscriptionUuid: string): APIResponse<void> {
    return sendRequest(
      {},
      "PUT",
      `/companies/${companyUuid}/plans/customer/subscription/${subscriptionUuid}/auto-upgrade-notification-clear`,
      CONFIG.client_admin_api,
    );
  }

  static getInvoices(companyUuid: string): APIResponse<InvoiceResponse[]> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/plans/customer/invoices`, CONFIG.client_admin_api);
  }

  static setTrialSubscription(companyUuid: string, payload: SetTrialSubscriptionPayload): APIResponse<void> {
    return sendRequest(payload, "POST", `/companies/${companyUuid}/plans/subscription/trial`, CONFIG.client_admin_api);
  }

  static linkIntegration(companyUuid: string, payload: {integration: string}): APIResponse<void> {
    return sendRequest(payload, "POST", `/companies/${companyUuid}/plans/customer/integration`, CONFIG.client_admin_api);
  }

  static getPendingInvoice(companyUuid: string, subscriptionUuid: string): APIResponse<PendingInvoiceResponse> {
    return sendRequest(
      {},
      "GET",
      `/companies/${companyUuid}/plans/subscription/${subscriptionUuid}/pending-invoice`,
      CONFIG.client_admin_api,
    );
  }

  static selectObjectives(companyUuid: string, payload: SelectObjectivesRequest): APIResponse<void> {
    return sendRequest(payload, "POST", `/companies/${companyUuid}/plans/customer/objectives`, CONFIG.client_admin_api);
  }
}
