import styled from "styled-components";
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";

export type Selection = [start: number, end: number];

const Input = styled.input``;

export type SafeInputProps = Omit<React.HTMLProps<HTMLInputElement>, "onChange"> & {
  value?: string;
  onChange?(value: string): void;
  /**
   * Optionally return a value modified by parent component.
   * This is useful when the parent component wants to reject a value and reset it to some default,
   * but reseting through `value` prop may not work if the `value` prop passed by parent is unchanged since passed initially.
   */
  onChangeFinished?(value: string): void | string;

  /**
   * Remove unwanted characters from the value. Return sanitizied value.
   * Return null if the value is invalid and shouldn't be allowed into the input.
   */
  prepareInput?(value: string): string | null;
  /**
   * When a key is entered then modify value and move selection cursor.
   * Return null if no smart handling is required.
   * E.g. user entered '10' and we modify the value to be '10:' but keep the cursor before ':'.
   * Next user enters spacebar and we move the cursor after ':'.
   */
  smartInput?(val: string, sel: Selection, key: string): { value: string; cursor: number } | null;
  /**
   * Assume missing parts of incomplete input on blur.
   * E.g. user entered '10:' and it should be completed to '10:00'.
   */
  completeInput?(value: string): string;
};

export default function SafeInput(props: SafeInputProps) {
  const {
    value: valueProp = "",
    onKeyDown,
    onChange,
    onBlur,
    onChangeFinished,
    prepareInput = (v) => v,
    smartInput = () => null,
    completeInput = (v) => v,
    ...otherProps
  } = props;
  const input = useRef<HTMLInputElement>(null);
  const selection = useRef<Selection | null>(null);
  const [value, setValue] = useState(valueProp);

  // --- save and restore selection
  /**
   * allows to trigger layout effect that restores selection if value was not changed during onChange.
   * Detailed explanation:
   * During `onChange` the `value` of a controlled input (that has a value prop passed to it) is changea.
   * If setValueotherProps with the new value is not called during `onChange` then react restores input.value to the old value.
   * The sideeffect of this is that the selection conFocusurhandleKeySmartsor jumps to the end of the input.
   * This can be avoided by storing selection during `onKeyDown` and restoring in useLayoutEffect.
   * To cause a layout effect to be triggered even if the value was not changed an extra state variable is required.
   * This behavior is described here https://stackoverflow.com/a/28922465/2277240
   */
  const [counter, setCounter] = useState(0);
  const scheduleSelectionRestore = () => setCounter((c) => c + 1);
  function saveSelection() {
    selection.current = [input.current!.selectionStart!, input.current!.selectionEnd!];
  }
  /**
   * If selection.current is null then skip restore
   */
  function restoreSelection() {
    if (selection.current) {
      [input.current!.selectionStart, input.current!.selectionEnd] = selection.current;
    }
    selection.current = null;
  }
  useLayoutEffect(() => restoreSelection(), [value, counter]);

  // --- update value
  /**
   * `sel` undefined means no update to stored selection is needed.
   * `sel` null means skip selection restore - selection is already handled externally and should not be restored.
   * *) If the external change doesn't pass validation resulting in no value update,
   * then the external change of selection also should be considered invalid and selection has to be restored.
   */
  function setValueSafe(val: string, sel?: Selection | null) {
    const v = prepareInput(val);
    const isValid = v !== null;
    const shouldUpdate = isValid && v !== value;
    if (!shouldUpdate) {
      // don't overwrite stored selection if null is passed.*
      if (sel !== undefined && sel !== null) {
        selection.current = sel;
      }
      scheduleSelectionRestore();
      return value;
    }
    if (sel !== undefined) {
      selection.current = sel;
    }
    setValue(v);
    return v;
  }

  useEffect(() => {
    saveSelection();
    setValue(valueProp || "");
  }, [valueProp]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    saveSelection(); // store selection while the change is not yet applied to the input
    const { key } = event;
    const sel: Selection = [input.current!.selectionStart!, input.current!.selectionEnd!];
    const res = smartInput(value, sel, key);
    if (res) {
      const { value: newVal, cursor: newSel } = res;
      const v = setValueSafe(newVal, [newSel, newSel]);
      onChange && onChange(v);
      event.preventDefault(); // prevent `onChange` from handling the same change
    }
    onKeyDown && onKeyDown(event);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // it is too late to `saveSelection` here, the change is applied to the input
    const v = setValueSafe(event.target.value, null);
    onChange && onChange(v);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const completedVal = completeInput(event.target.value);
    const modifiedVal = onChangeFinished && onChangeFinished(completedVal);
    const val = modifiedVal !== undefined ? modifiedVal : completedVal;
    setValue(val);
    onBlur && onBlur(event);
  };

  return (
    <Input
      ref={input}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      {...otherProps}
    />
  );
}
