import { useState } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { UserProfileRole } from "types/models/userProfile";
import { PermissionRoleName } from "types/models/permissions";
import { AvailableLocales, getLocale } from "utils/translationHelpers";
import * as Images from "./menu-svg-icons";

const HelpWrapper = styled.div`
  position: relative;
`;

const HelpButton = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  padding: 6px;
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;

  &:hover {
    background: var(--colors-theme-tr-icon-bg-hvr);

    path {
      fill: var(--colors-theme-tr-icon-fill-hvr);
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 38px;
  inset-inline-end: 0;
  z-index: 10;
  padding: 8px 4px;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-150);
  background: var(--colors-surface-0);
  box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
`;

const DropdownOption = styled.div`
  position: relative;
  height: 32px;
  width: 180px;
  padding: 0 12px;
  line-height: 32px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

interface RedDotProps {
  borderColor: string;
  top: number;
  right: number;
  size: number;
}

const RedDot = styled.div<RedDotProps>`
  position: absolute;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  top: ${(p) => p.top}px;
  inset-inline-end: ${(p) => p.right}px;
  font-size: 12px; // for size 18
  line-height: 14px; // for size 18
  text-align: center;
  color: #fff;
  border: 2px solid;
  border-color: ${(p) => p.borderColor};
  border-radius: 50%;
  background-color: #ff616a;
`;

interface HelpMenuNewProps {}

const HelpMenuNew = (props: HelpMenuNewProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation(TranslationNamespaces.fullPage);
  const { profile } = window.global_store;

  const notificationActive = !!window.Beamer?.notificationActive;
  const menuOptions = [
    {
      label: t("What's new"),
      onClick: () => window.Beamer?.show?.(),
      notificationNumber: window.Beamer?.notificationNumber || 0,
      show: true,
    },
    {
      label: t("Help Center"),
      onClick: () => {
        const helpDeskLocale = {
          [AvailableLocales.he.toLowerCase()]: "he",
          [AvailableLocales.en.toLowerCase()]: "en",
          [AvailableLocales.pt.toLowerCase()]: "pt-br",
        }[getLocale().toLowerCase()];

        window.open(`https://support.day.io/hc/${helpDeskLocale || "pt-br"}/`, "_blank")?.focus();
      },
      notificationNumber: 0,
      show: true,
    },
    {
      label: t("Chat with Us"),
      onClick: () => window.zE("messenger", "open"),
      notificationNumber: 0,
      show:
        profile.role === UserProfileRole.employer ||
        profile.permission_roles.some((pr) =>
          [PermissionRoleName.admin, PermissionRoleName.hr, PermissionRoleName.supervisor].includes(pr.name),
        ), // same as in initZendesk();
    },
  ];

  return (
    <HelpWrapper>
      <HelpButton onClick={() => setIsOpened(!isOpened)}>
        <Images.Help />
        {notificationActive && <RedDot size={8} borderColor="transparent" top={5} right={6} />}
      </HelpButton>

      {isOpened && (
        <ClickOutside<ClickOutsideType> onClickOutside={() => setIsOpened(false)}>
          <Dropdown>
            {menuOptions.map((o) =>
              o.show ? (
                <DropdownOption
                  key={o.label}
                  onClick={() => {
                    o.onClick();
                    setIsOpened(false);
                  }}
                >
                  {o.label}
                  {!!o.notificationNumber && (
                    <RedDot size={18} borderColor="transparent" top={6} right={65}>
                      {o.notificationNumber}
                    </RedDot>
                  )}
                </DropdownOption>
              ) : null,
            )}
          </Dropdown>
        </ClickOutside>
      )}
    </HelpWrapper>
  );
};

export default HelpMenuNew;
