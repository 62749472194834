import { Component } from "react";
import { withTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import NotificationRow from "components/NotificationRow";
import { HeaderAction, PageWrapper } from "components/styled/Page";
import Tabs from "components/UI/Tabs";
import HeaderActionButtonAdd from "components/controls/HeaderActionButton";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { getTitle } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import GlobalContext from "context/global-context";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import HolidayGroupTabHolidays from "./HolidayGroupTabHolidays";
import HolidayGroupTabEmployees from "./HolidayGroupTabEmployees";
import HolidayCreatePopup from "./HolidayCreatePopup";
import { getHolidaysGroup } from "./holidayApiUtils";

class Group extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;
    const { tab } = this.props.match.params;
    this.state = {
      activeTab: tab || "holidays",
      title: "",
      loading: true,
      holidays: [],
      userProfiles: [],
      notification: "",
      status: "",
    };
    document.title = getTitle(t("Holidays"));
  }

  componentDidMount = async () => {
    this.fetchHolidayGroup();
  };
  fetchHolidayGroup = async () => {
    const { uuid } = this.props.match.params;
    this.setState({ loading: true });
    const company = await this.context.getCompany();
    const res = await getHolidaysGroup({
      companyUuid: company.uuid,
      holidaysGroupUuid: uuid,
    });
    const { content } = await listUserProfilesWIthFilters(company.uuid, {
      ...baseByUuidPayload(
        window.global_store.profile.uuid,
        res.content.userProfileHolidaysGroups.map((up) => up.userProfileUuid),
      ),
      filterModel: {
        uuid: { filterType: "set", values: res.content.userProfileHolidaysGroups.map((up) => up.userProfileUuid) },
        employee_status: { filterType: "text", type: "equals", filter: "active" },
      },
      fields: [
        "id",
        "uuid",
        "fullName",
        "avatarId",
        "position.title",
        "department.id",
        "department.name",
        "department.uuid",
      ],
    });
    let employeesWithDetails = content;
    employeesWithDetails = employeesWithDetails.map((p) => ({
      ...p,
      assigmentDate: res.content.userProfileHolidaysGroups.filter((em) => em.userProfileUuid === p.uuid)[0].startDate,
      unassignedAt: res.content.userProfileHolidaysGroups.filter((em) => em.userProfileUuid === p.uuid)[0].unassignedAt,
    }));

    this.setState({
      title: res.content.name,
      holidays: res.content.holidays ? res.content.holidays.filter((h) => h) : [], // filter not null items
      userProfiles: employeesWithDetails,
      isDefault: res.content.isDefault,
      status: res.content.status,
      loading: false,
    });
  };

  getContent(activeTab) {
    const { t } = this.props;
    const { uuid } = this.props.match.params;
    const { holidays, loading, userProfiles, isDefault, status } = this.state;

    let content = null;
    switch (activeTab) {
      case "holidays":
        content = (
          <HolidayGroupTabHolidays
            loading={loading}
            items={holidays}
            holidaysGroupUuid={uuid}
            onUpdate={this.onUpdate}
          />
        );
        document.title = getTitle(t("Holidays"));
        break;
      case "employees":
        content = (
          <HolidayGroupTabEmployees
            userProfiles={userProfiles}
            loading={loading}
            isDefault={isDefault}
            holidaysGroupUuid={uuid}
            holidaysGroupStatus={status}
            onUpdate={this.onUpdate}
          />
        );
        document.title = getTitle(t("common|Employees"));
        break;
      default:
        content = null;
        break;
    }
    return content;
  }

  onUpdate = async ({ message, type }) => {
    const { t } = this.props;
    const notificationType = type;
    const notification = t(message);
    this.setState({
      notificationType,
      notification,
      popupCreateVisible: false,
    });

    if (type === "success") {
      this.fetchHolidayGroup();
    }
  };

  getTitle = () => {
    const { title, isDefault } = this.state;
    const { t } = this.props;

    return isDefault
      ? `${title === "defaultHolidaysGroup" ? t(title) : title} (${t("common|Default")})`
      : `${title === "defaultHolidaysGroup" ? t(title) : title}`;
  };

  render() {
    const { notification, notificationType = "success", activeTab, popupCreateVisible } = this.state;
    const { t, match, history } = this.props;
    const { uuid } = match.params;
    const tabs = [
      { name: "holidays", label: t("Holidays") },
      { name: "employees", label: t("common|Employees") },
    ];

    return (
      <SettingsLayout
        title={this.getTitle()}
        headerAction={
          activeTab === "holidays" ? (
            <HeaderAction>
              <HeaderActionButtonAdd
                title={t("New Holiday")}
                onClick={() => this.setState({ popupCreateVisible: true })}
              />
            </HeaderAction>
          ) : null
        }
        backButtonOnclick={() => history.push("/company/holidays")}
        backButtonTitle={t("Holidays")}
      >
        <PageWrapper>
          {notification && (
            <>
              <NotificationRow withCloseButton={false} type={notificationType} message={notification} />
              <br />
            </>
          )}

          <Tabs tabs={tabs} value={activeTab} onChange={(tab) => this.setState({ activeTab: tab })} />

          <div>{this.getContent(activeTab)}</div>

          <SidePopupOverlay
            dark
            header={t("Holiday")}
            isOpen={popupCreateVisible}
            contentOverflow
            onClose={() => this.setState({ popupCreateVisible: false })}
          >
            <HolidayCreatePopup holidaysGroupUuid={uuid} onYes={this.onUpdate} />
          </SidePopupOverlay>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withTranslation(TranslationNamespaces.holidays)(Group);
