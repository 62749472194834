import Lightbox from "components/Lightbox";
import FieldWrapper from "components/UI/FieldWrapper";
import ModalDialog from "components/UI/ModalDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LocationsDropdown from "components/LocationsDropdown";
import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";

type AssociateLocationsPopupProps = {
  onClose: (uncheck?: boolean) => void;
  onYes: (locationUuids: string[]) => void;
};
export const AssociateLocationsPopup = ({ onClose, onYes }: AssociateLocationsPopupProps) => {
  const { t } = useTranslation("employees-page-all-table");
  const [isSaving, setIsSaving] = useState(false);
  const [locationsUuids, setLocationsUuids] = useState([]);

  return (
    <ModalDialog isOpen onClose={onClose} isLoading={isSaving}>
      <Lightbox
        onClose={onClose}
        yesDisabled={false}
        onYes={async () => {
          setIsSaving(true);
          await onYes(locationsUuids);
          onClose(true);
        }}
        buttonYesTitle={t("common|Confirm")}
        buttonCancelTitle={t("common|Cancel")}
        title={t("Change locations")}
      >
        <div>
          <FieldWrapper fieldName={t("Locations")} width="100%">
            <LocationsDropdown<string[] | null>
              uuid
              isMulti
              onlyActive
              value={locationsUuids}
              onChange={(val: string[]) => setLocationsUuids(val || [])}
            />
          </FieldWrapper>
          <NotificationRow
            style={{ marginTop: "16px" }}
            type={NotificationType.info}
            message={t("change_locations_action_description")}
          />
        </div>
      </Lightbox>
    </ModalDialog>
  );
};
