import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  padding: 16px var(--component-menu-item-toggle-indent-hpad, 32px);

  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  align-self: stretch;
  border-top: 1px solid var(--component-modal-border-color, #dde5ee);
`;

const Header = ({ children }: PropsWithChildren) => <Wrapper>{children}</Wrapper>;

export default Header;
