import { Component, ContextType, createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { fireDownloadReport, getClients } from "utils/apiHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FullPage from "components/Layout/FullPage";
import {
  getEmployeeTaxIdTranslation,
  getEmployeeTaxPayerType,
  getTitle,
  hasPermisionAccess,
  hasProjectsAccess,
  isSupervisor,
  minsToHrsMins,
} from "utils/common";
import {
  approveActivity,
  bulkChangeActivitiesStatus,
  createActivities,
  declineActivity,
  getActivitiesList,
  getCompanyRulesForProjects,
} from "components/Projects/projectsApiUtils";
import Pagination from "components/Pagination";
import SearchControl from "components/UI/SearchControlNew";
import NotificationRow from "components/NotificationRow";
import User from "components/User";
import TablePage, { ColumnsSelector } from "components/TablePage";
import MultiSelect from "components/UI/Select/MultiSelect";
import { iCellInfo, iRowInfo, TableHelper } from "utils/tableHelpers";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import styled from "styled-components";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import GlobalContext from "context/global-context";
import { PageWrapper, TableButton, TableButtons } from "components/styled/Page";
import StatusBadge from "components/controls/StatusBadge";
import CellCheckbox from "components/controls/CellCheckbox";
import CellCheckboxAll from "components/controls/CellCheckboxAll";
import ProjectsDropdownControlFilter from "components/Projects/ProjectsDropdownControlFilter";
import ClientsDropdown from "components/ClientsDropdown";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { NotificationType, SearchObject, SearchObjectTypes } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import { ActivityStatuses } from "types/models/activity";
import CONFIG from "config";
import { Client } from "utils/api/client";
import { FilteredEmployeeProfile, GlobalContextEmployee } from "types/models/userProfile";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { Activity, ActivityInCreatePayload } from "components/Projects/projectsApiTypes";
import LocationsDropdownControl from "components/Activities/LocationsDropdownControl";
import ActivityDetails from "components/Activities/ActivityDetails";
import AddActivities from "components/Activities/AddActivities";
import { getActivityStatuBadgeType, getActivityStatusBadgeLabel } from "components/Activities/activitiesHelpers";
import ActivitiesActionsBar from "components/Activities/ActivitiesActionsBar";
import ga, { GaAddTimeButtonLocation } from "utils/ga";
import { getMinsToHoursDecimal } from "utils/reportsHelpers";
import Button, { ButtonState } from "components/controls/StyledButton";
import SubmitTimesheetPopup from "components/Timesheets/components/SubmitTimesheetPopup";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import TimesheetSettingsModal from "components/Timesheets/components/TimesheetSettingsModal";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import NoActivities from "./NoActivities";
import * as images from "../svg-images";

const tableHelper = new TableHelper("ActivitiesPage");

const PopupHeaderText = styled.span``;

const SearchWrapper = styled.div`
  display: inline-block;
  width: 100%;
  min-width: 237px;
  max-width: 237px;
  vertical-align: middle;
`;

const FilterDropdownWrapper = styled.div`
  margin-inline-end: 8px;

  .react-select__control {
    background-color: var(--colors-surface-50);
    color: var(--colors-mainText);
    border: none;
    min-width: 100px;
  }

  .react-select__control:hover {
    background-color: var(--colors-surface-100);
    color: var(--colors-mainText);
    border: none;
  }

  .react-select__control.react-select__control--is-focused {
    border: none;
  }

  .react-select__placeholder {
    color: var(--colors-mainText);
  }

  .ui-select__input {
    min-width: 100px;
  }
`;

const Wrapper = styled(PageWrapper)`
  .activities-row {
    position: relative;
  }

  .DateInput {
    width: 50px;
  }
`;

const TableControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  div.download-control {
    margin-inline: 8px;
  }
`;

const HeaderActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  & > * {
    flex-shrink: 0;
  }
`;

const SettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

export type ActivitiesWithUserProfile = Activity & {
  employee: FilteredEmployeeProfile;
};

export type ActivitiesPageActivity = {
  activity: ActivitiesWithUserProfile;
  uuid: string;
  employee: GlobalContextEmployee;
  taskName: string;
  locationName: string;
  projectName: string;
  projectId: string;
  clientName: string;
  hours: string;
  hoursDecimal: string;
  date: string;
  status: ActivityStatuses;
  cpf: string;
  matricula: string;
};

interface ActivitiesPageProps extends WithTranslation, RouteComponentProps {
  startDate: string;
  endDate: string;
  uuid: string;
  id: number;
  label: string;
  timesheetContext: any; // ContextType<typeof TimesheetContext>;
}

interface ActivitiesPageState {
  notification: string;
  refetchOnClose: boolean;
  initialFetchDone: boolean;
  popupAddPunchVisible: boolean;
  activityUuidToDecline: string | null;
  notificationType: NotificationType | null;
  selectedActivity: ActivitiesPageActivity | null;
  searchValue: string | undefined;
  startDate: Moment;
  endDate: Moment;
  activities: ActivitiesWithUserProfile[];
  directReportsOnly: boolean;
  filtrClient: {
    value: string;
    label: string;
  } | null;
  searchObj: SearchObject | Record<string, never>; // can be null or empty object
  filtrLocationUuid: string;
  filtrProjectUuid: string;
  loading: boolean;
  page: number;
  totalRecors: number;
  selectedRowsUuids: string[];
  fltrStatus: string[];
  selectedColumns: string[];
  clients: Client[];
  selectAllChecked: boolean;
  popupSettingsVisible: boolean;
  showSubmitPopup: boolean;
  allowFutureActivities: boolean;
}

const GRANULARITY_DAY = "day";

class ActivitiesPage extends Component<ActivitiesPageProps, ActivitiesPageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;
  STATUS_TYPES: { value: ActivityStatuses; label: string }[];
  activityStateRef: typeof ActivityDetails;

  constructor(props: ActivitiesPageProps) {
    super(props);
    const { t, label, startDate, endDate, uuid, id } = props;

    this.STATUS_TYPES = [
      { value: ActivityStatuses.pending, label: t("New") },
      { value: ActivityStatuses.approved, label: t("Approved") },
      { value: ActivityStatuses.declined, label: t("Denied") },
      { value: ActivityStatuses.deleted, label: t("Deleted") },
    ];

    this.activityStateRef = createRef<typeof ActivityDetails>();

    const state = {
      notification: "",
      refetchOnClose: false,
      activityUuidToDecline: null,
      notificationType: null,
      popupAddPunchVisible: false,
      selectedActivity: null,
      searchValue: label || "",
      startDate: startDate ? moment(startDate, CONFIG.apiDateFormat) : moment().clone().subtract(2, "weeks"),
      endDate: endDate ? moment(endDate, CONFIG.apiDateFormat) : moment().clone(),
      searchObj:
        hasPermisionAccess(PermissionSectionName.approveActivities) ||
        hasPermisionAccess(PermissionSectionName.employees)
          ? {}
          : {
              employee: {
                avatar_id: window.global_store.profile.avatar_id,
                full_name: window.global_store.profile.full_name,
                id: window.global_store.profile.id,
                uuid: window.global_store.profile.uuid,
              },
              id: window.global_store.profile.id,
              label: window.global_store.profile.name,
            },
      activities: [],
      filtrLocationUuid: "",
      filtrProjectUuid: "",
      filtrClient: null,
      fltrStatus: [],
      totalRecors: 0,
      page: 1,
      clients: [],
      loading: true,
      initialFetchDone: false,
      selectedRowsUuids: [],
      selectAllChecked: false,
      selectedColumns: tableHelper.getSelectedColumns(
        "name,cpf,matricula,task_name,project_name,duration,date,location_name,client_name,status".split(","),
      ),
      directReportsOnly: false,
      popupSettingsVisible: false,
      showSubmitPopup: false,
      allowFutureActivities: false,
    };

    if (uuid && id) {
      state.searchObj = {
        employee: {
          uuid,
          id,
          avatar_id: null,
          full_name: label,
        },
        label,
        id,
      };
    }
    this.state = state;
    document.title = getTitle(t("Timetracking list"));
  }

  async componentDidMount() {
    const { t } = this.props;
    const company = await this.context.getCompany();
    const [clientsResp, projectsSettings] = await Promise.all([
      getClients(),
      getCompanyRulesForProjects({ companyUuid: company.uuid }),
    ]);

    if (this.props.timesheetContext.isTimesheetEnabled) {
      this.STATUS_TYPES.push({ value: ActivityStatuses.pending, label: t("Pending") });
    } else {
      this.STATUS_TYPES.push({ value: ActivityStatuses.submitted, label: t("Submitted") });
    }

    this.setState(
      {
        clients: clientsResp?.clients ? clientsResp.clients : [],
        allowFutureActivities: !!projectsSettings?.content?.allowFutureActivities,
      },
      this.getActivites,
    );
  }

  getActivites = async () => {
    const {
      page = 1,
      searchObj,
      fltrStatus,
      filtrProjectUuid,
      filtrLocationUuid,
      startDate,
      endDate,
      filtrClient,
      directReportsOnly,
    } = this.state;
    const { t } = this.props;
    let employeeUuid;
    let teamUuid;
    let departmentUuid;
    let subsidiaryUuid;
    let groupUuid;

    if (searchObj?.type === SearchObjectTypes.department) {
      departmentUuid = searchObj.uuid;
    } else if (searchObj?.type === SearchObjectTypes.subsidiary) {
      subsidiaryUuid = searchObj.uuid;
    } else if (searchObj?.type === SearchObjectTypes.team) {
      teamUuid = searchObj.uuid;
    } else {
      employeeUuid = searchObj?.employee?.uuid || searchObj?.uuid;
    }

    if (directReportsOnly) {
      groupUuid = window.global_store.profile.teams[0]?.uuid;
    }

    if (!this.state.initialFetchDone || !this.state.loading) {
      this.setState({ loading: true, initialFetchDone: true, selectedRowsUuids: [] });
      try {
        const company = await this.context.getCompany();
        const response = await getActivitiesList({
          page,
          employeeUuid,
          departmentUuid,
          subsidiaryUuid,
          teamUuid,
          from: startDate.clone().format(CONFIG.apiDateFormat),
          to: endDate.clone().format(CONFIG.apiDateFormat),
          clientUuid: filtrClient?.value || "",
          projectUuid: filtrProjectUuid,
          locationUuid: filtrLocationUuid,
          status: fltrStatus.join(","),
          requestedBy: window.global_store.profile.uuid,
          companyUuid: company.uuid,
          groupUuid,
        });
        const activities = response.content;
        const userProfileUuids = activities.map((activity) => activity.userProfileUuid);
        const { content: userProfiles } = await listUserProfilesWIthFilters(company.uuid, {
          ...baseByUuidPayload(window.global_store.profile.uuid, userProfileUuids),
          global: true,
          fields: [
            "id",
            "uuid",
            "fullName",
            "avatarId",
            "employeeStatus",
            "position.title",
            "taxPayerId",
            "matricula",
            "role",
            "createdAt",
            "pis",
            "lastLockDate",
          ],
        });

        const stateActivities: ActivitiesWithUserProfile[] = activities
          .map((activity) => ({
            ...activity,
            employee: userProfiles.find((up) => activity.userProfileUuid === up.uuid),
          }))
          .filter((activity: ActivitiesWithUserProfile) => !!activity.employee);

        this.setState({
          activities: stateActivities,
          loading: false,
          page,
          totalRecors: parseInt(response.pagingTotalCount, 10),
        });
      } catch (error) {
        this.setState({
          activities: [],
          loading: false,
          notificationType: NotificationType.error,
          notification: t(`${TranslationNamespaces.common}|Something went wrong`),
          page,
          totalRecors: 0,
        });
      }
    }
  };

  isRowLocked(activity: ActivitiesWithUserProfile) {
    return (
      !!activity?.employee?.lastLockDate &&
      moment(activity.employee.lastLockDate).isSameOrAfter(moment(activity.date), GRANULARITY_DAY)
    );
  }

  isRowSelectable = (activity: ActivitiesWithUserProfile) => {
    const isOwn = activity.employee.uuid === window.global_store.profile.uuid;
    return (
      activity.status === ActivityStatuses.pending &&
      hasPermisionAccess(PermissionSectionName.approveActivities) &&
      !isOwn &&
      !this.isRowLocked(activity)
    );
  };

  getColumns() {
    const { t } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);
    const { selectedRowsUuids, selectAllChecked, activities } = this.state;

    const showCheckboxes =
      !this.props.timesheetContext.isTimesheetEnabled && hasPermisionAccess(PermissionSectionName.approveActivities);

    const columns: iColumn<ActivitiesPageActivity>[] = [];

    if (showCheckboxes) {
      columns.push({
        accessor: "uuid",
        headerClassName: "checkbox-header",
        Cell: (row: iCellInfo<ActivitiesPageActivity>) => (
          <CellCheckbox
            row={row}
            checked={selectedRowsUuids.indexOf(row.value) > -1}
            onChange={this.onRowCheck}
            entityIdKey=""
            isCheckboxDisabled={!this.isRowSelectable(row.original.activity)}
            isRowLocked={this.isRowLocked(row.original.activity)}
          />
        ),
        Header: (
          <CellCheckboxAll
            entities={activities}
            checked={selectAllChecked}
            onChange={this.onSelectAllCheck}
            isEntitySelectable={this.isRowSelectable}
          />
        ),
        locked: true,
        minWidth: 50,
        style: { fontWeight: "500" },
        align: "center",
      });
    }

    columns.push({
      accessor: "employee",
      rubyAccessor: "name",
      locked: true,
      label: translateEmployeeTerm(
        t,
        TranslationNamespaces.common,
        "custom-employee",
        `${TranslationNamespaces.common}|Employee`,
      ),
      Cell: (row: iCellInfo<ActivitiesPageActivity>) => (
        <User
          user={{
            fullName: row.value.fullName,
            avatarId: row.value.avatarId,
          }}
        />
      ),
      minWidth: 270,
    });

    columns.push(
      ...[
        {
          accessor: "cpf",
          label: employeeTaxIdLabel,
        },
        {
          accessor: "matricula",
          label: t(`${TranslationNamespaces.common}|Matricula`),
        },
        {
          accessor: "taskName",
          rubyAccessor: "task_name",
          label: t("Task"),
        },
        {
          accessor: "projectName",
          rubyAccessor: "project_name",
          label: t("Project"),
        },
        {
          accessor: "projectId",
          rubyAccessor: "project_id",
          label: t("Project Id"),
        },
        {
          accessor: "hours",
          rubyAccessor: "duration",
          label: t("Hours"),
        },
        {
          accessor: "hoursDecimal",
          rubyAccessor: "duration_decimal",
          label: t("Hours (Decimal)"),
          width: 140,
        },
        {
          accessor: "date",
          rubyAccessor: "date",
          label: t("When"),
          width: 180,
        },
        {
          accessor: "locationName",
          rubyAccessor: "location_name",
          label: t("Location"),
        },
        {
          accessor: "clientName",
          rubyAccessor: "client_name",
          label: t("Client"),
        },
      ],
    );

    columns.push({
      accessor: "status",
      rubyAccessor: "status",
      label: t(`${TranslationNamespaces.common}|Status`),
      locked: true,
      Cell: (row: iCellInfo<ActivitiesPageActivity>) => {
        const status = row.value;
        const showApproveDeclineButtons =
          !this.props.timesheetContext.isTimesheetEnabled && this.isRowSelectable(row.original.activity);
        return (
          <div>
            <StatusBadge
              value={t(
                `${TranslationNamespaces.common}|${getActivityStatusBadgeLabel(
                  status,
                  this.props.timesheetContext.isTimesheetEnabled,
                )}`,
              )}
              type={getActivityStatuBadgeType(status, this.props.timesheetContext.isTimesheetEnabled)}
            />
            {showApproveDeclineButtons && (
              <TableButtons className="buttons">
                <TableButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.onApproveClick(row.original.uuid);
                  }}
                >
                  {t(`${TranslationNamespaces.common}|Approve`)}
                </TableButton>
                <TableButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setTimeout(() => {
                      this.setState({ activityUuidToDecline: row.original.uuid });
                    }, 300);
                  }}
                >
                  {t(`${TranslationNamespaces.common}|Decline`)}
                </TableButton>
              </TableButtons>
            )}
          </div>
        );
      },
      minWidth: 120,
      align: "center" as ColumnAlign,
    });
    return columns.filter((c) => !!c);
  }

  onRowCheck = (isChecked: boolean, activityUuid: string) => {
    let { selectedRowsUuids } = this.state;
    const { selectAllChecked } = this.state;

    if (isChecked) {
      selectedRowsUuids.push(activityUuid);
    } else {
      selectedRowsUuids = selectedRowsUuids.filter((uuid) => uuid !== activityUuid);
    }

    this.setState({ selectedRowsUuids, selectAllChecked: !selectedRowsUuids.length ? false : selectAllChecked });
  };

  onConfirmBulkChange = async (status: ActivityStatuses) => {
    const { selectedRowsUuids } = this.state;
    const company = await this.context.getCompany();

    await bulkChangeActivitiesStatus({
      status,
      companyUuid: company.uuid,
      body: {
        updatedBy: window.global_store.profile.uuid,
        activityUuids: selectedRowsUuids,
      },
    });
  };

  onSelectAllCheck = (isChecked: boolean, selectedRowsUuids: string[]) =>
    this.setState({ selectAllChecked: isChecked, selectedRowsUuids });

  onSearchValue = (value: SearchObject) => {
    this.setState({ searchValue: value.label, searchObj: value, page: 1, directReportsOnly: false }, this.getActivites);
  };

  onFilterStatusChange = (value: string[]) => {
    this.setState({ fltrStatus: value, page: 1 }, this.getActivites);
  };

  onSearchDatesChange = (startDate: Moment | null, endDate: Moment | null) => {
    if (startDate && endDate) {
      this.setState({ startDate, endDate, page: 1 }, this.getActivites);
    }
  };

  openActivityDetails(activity: ActivitiesPageActivity) {
    this.setState({ selectedActivity: activity, refetchOnClose: false });
  }

  getClientName(clientUuid: string, clients: Client[]) {
    if (!clientUuid || !clients || !clients.length) {
      return "";
    }
    const client = clients.filter((c) => c.uuid === clientUuid)[0];
    if (client) {
      return client.name;
    }
    return "";
  }

  getDateFormatted = (date: string, startTime: number, endTime: number, duration: number) => {
    const dateStr = moment(date, CONFIG.apiDateFormat).format("DD/MM/YYYY");
    const startTimeStr = moment(date, CONFIG.apiDateFormat).add(startTime, "minutes").format("HH:mm");
    const endTimeStr = duration >= 0 ? moment(date, CONFIG.apiDateFormat).add(endTime, "minutes").format("HH:mm") : "";
    return `${dateStr} ${startTimeStr}${endTimeStr && " - "}${endTimeStr}`;
  };

  getTableData = (activities: ActivitiesWithUserProfile[]) => {
    const { clients } = this.state;
    const data = activities.map((e) => ({
      activity: e,
      uuid: e.uuid,
      employee: e.employee,
      taskName: e?.task?.name || "",
      locationName: e?.location?.name || "",
      projectName: e?.project?.name || "",
      projectId: e?.project?.id || "",
      clientName: e?.project?.clientUuid ? this.getClientName(e.project.clientUuid, clients) : "",
      hours: e?.duration ? (e.duration >= 0 ? minsToHrsMins(e.duration) : "-") : "00:00",
      hoursDecimal: e?.duration ? (e.duration >= 0 ? getMinsToHoursDecimal(e.duration) : "-") : "00:00",
      date: this.getDateFormatted(e.date, e.startTime, e.endTime, e.duration),
      status: e.status,
      cpf: e.employee.taxPayerId,
      matricula: e.employee.matricula || "",
    }));
    return data;
  };

  onAddActivities = async (activities: ActivityInCreatePayload[]) => {
    const { t } = this.props;

    try {
      const company = await this.context.getCompany();

      if (company) {
        await createActivities({
          companyUuid: company.uuid,
          body: {
            content: {
              activities,
              createdBy: window.global_store.profile.uuid,
            },
          },
        });

        ga.trackAddTime(GaAddTimeButtonLocation.sidebar);

        this.setState({
          notificationType: NotificationType.success,
          notification: t(`${TranslationNamespaces.punchesPage}|adding-activities-success`),
          popupAddPunchVisible: false,
        });
        void this.getActivites();
      }
    } catch (error) {
      this.setState({
        notificationType: NotificationType.error,
        notification: t(`${TranslationNamespaces.punchesPage}|adding-activities-failed`),
        popupAddPunchVisible: false,
      });
    }
  };

  onColumnsChange = (selectedColumns: string[]) => {
    tableHelper.setSelectedColumns(selectedColumns);
    this.setState({ selectedColumns });
  };

  onDeclineActivityConfirmationClicked = async () => {
    const { t } = this.props;
    const { activityUuidToDecline } = this.state;

    try {
      const company = await this.context.getCompany();
      await declineActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
        activityUuid: activityUuidToDecline,
      });
      this.setState({
        notificationType: NotificationType.success,
        notification: t("task-decline-success"),
        activityUuidToDecline: null,
      });
      void this.getActivites();
    } catch (error) {
      this.setState({
        notificationType: NotificationType.error,
        notification: t("task-decline-failed"),
        activityUuidToDecline: null,
      });
    }
  };

  onApproveClick = async (activityUuid: string) => {
    const { t } = this.props;
    try {
      const company = await this.context.getCompany();
      await approveActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
        activityUuid,
      });
      this.setState({
        notificationType: NotificationType.success,
        notification: t("task-approve-success"),
      });
      void this.getActivites();
    } catch (error) {
      this.setState({
        notificationType: NotificationType.error,
        notification: t("task-approve-failed"),
      });
    }
  };

  render() {
    const {
      searchValue,
      activities,
      startDate,
      endDate,
      loading,
      popupAddPunchVisible,
      selectedRowsUuids,
      selectAllChecked,
      selectedActivity,
      selectedColumns,
      searchObj,
      notificationType,
      fltrStatus,
      filtrLocationUuid,
      filtrProjectUuid,
      filtrClient,
      activityUuidToDecline,
      directReportsOnly,
      refetchOnClose,
      notification,
      popupSettingsVisible,
      showSubmitPopup,
      allowFutureActivities,
    } = this.state;
    const { t } = this.props;

    const columns = this.getColumns();

    return (
      <FullPage
        headerAction={
          <HeaderActionWrapper>
            {hasProjectsAccess() && (
              <SettingsButton onClick={() => this.setState({ popupSettingsVisible: true })}>
                {images.gearIcon}
              </SettingsButton>
            )}
            {this.props.timesheetContext.isTimesheetEnabled && (
              <Button
                value={t(`${TranslationNamespaces.timesheets}|Submit timesheet`)}
                state={ButtonState.outline}
                style={{
                  width: "auto",
                  padding: "0 16px",
                  whiteSpace: "nowrap",
                  fontSize: 14,
                  fontWeight: 500,
                  color: "var(--colors-surface-600)",
                }}
                onClick={() => this.setState({ showSubmitPopup: true })}
              />
            )}
            <HeaderActionButtonAdd
              title={t(`${TranslationNamespaces.timesheets}|Add time`)}
              state={ButtonState.primary}
              style={{ fontSize: 14, fontWeight: 500, whiteSpace: "nowrap" }}
              onClick={() => this.setState({ popupAddPunchVisible: true })}
            />
          </HeaderActionWrapper>
        }
      >
        <Wrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType}
              onClose={() => this.setState({ notification: "" })}
              message={notification}
            />
          )}

          <TablePage<ActivitiesPageActivity>
            selectedColumns={selectedColumns}
            onColumnsChange={this.onColumnsChange}
            filters={
              <>
                <SearchWrapper>
                  <SearchControl
                    onClear={() => this.setState({ searchObj: {} }, this.getActivites)}
                    value={searchValue}
                    searchGroups
                    onChange={this.onSearchValue}
                    placeholder={translateEmployeeTerm(
                      t,
                      TranslationNamespaces.common,
                      "custom-search-employees",
                      `${TranslationNamespaces.common}|Search Employees`,
                    )}
                    permissionSection={PermissionSectionName.activities}
                  />
                </SearchWrapper>

                {isSupervisor() && (
                  <AdditionalFiltersControl
                    options={[
                      {
                        label: t("Direct reports only"),
                        checked: directReportsOnly,
                        onChange: (val) =>
                          this.setState({ directReportsOnly: val, searchObj: {}, searchValue: "" }, this.getActivites),
                      },
                    ]}
                  />
                )}
                <DateRangePicker
                  newOnChangeApproach
                  onChange={this.onSearchDatesChange}
                  startDate={startDate}
                  endDate={endDate}
                  labelFormat="MMM DD"
                />
                <FilterDropdownWrapper>
                  <ClientsDropdown
                    withEmptyOption
                    value={filtrClient?.value || ""}
                    onChange={(val, option) => {
                      this.setState({ filtrClient: option }, () => {
                        if (filtrClient?.value !== val) {
                          void this.getActivites();
                        }
                      });
                    }}
                    placeholder={t("Select a client")}
                  />
                </FilterDropdownWrapper>
                <FilterDropdownWrapper>
                  <ProjectsDropdownControlFilter
                    value={filtrProjectUuid}
                    onChange={(val: { value: string; label: string }) => {
                      this.setState({ filtrProjectUuid: val.value }, () => {
                        if (filtrProjectUuid !== val.value) {
                          void this.getActivites();
                        }
                      });
                    }}
                    placeholder={t("Select a project")}
                  />
                </FilterDropdownWrapper>
                <FilterDropdownWrapper>
                  <LocationsDropdownControl
                    withEmpty
                    fieldView={false}
                    value={filtrLocationUuid}
                    onChange={(val: { value: string; label: string }) => {
                      this.setState({ filtrLocationUuid: val.value }, () => {
                        if (filtrLocationUuid !== val.value) {
                          this.getActivites();
                        }
                      });
                    }}
                    placeholder={t("Select a location")}
                  />
                </FilterDropdownWrapper>

                <FilterDropdownWrapper>
                  <MultiSelect
                    value={fltrStatus}
                    onChange={this.onFilterStatusChange}
                    options={this.STATUS_TYPES}
                    placeholder={t("Status")}
                  />
                </FilterDropdownWrapper>
                <TableControlsWrapper>
                  <ColumnsSelector
                    value={selectedColumns}
                    onChange={(val: string[]): void => {
                      this.onColumnsChange(val);
                    }}
                    options={columns.map((col) => ({
                      value: col?.rubyAccessor?.toString() || col?.accessor?.toString() || "",
                      label: col.groupLabel || col.label || "",
                      disabled: col.locked || false,
                    }))}
                  />
                  <DownloadControlWithEvents
                    placeholder=""
                    modifiers={{ icon_only: true }}
                    options={[{ label: t("Download log XLSX"), value: "xlsx" }]}
                    onChange={async (value) =>
                      await fireDownloadReport({
                        searchObj,
                        format: value || "pdf",
                        selectedColumns: selectedColumns.join(),
                        startDate,
                        endDate,
                        locationUuid: filtrLocationUuid || "",
                        projectUuid: filtrProjectUuid || "",
                        clientUuid: filtrClient?.value || "",
                        status: fltrStatus.join(","),
                        companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                        reportType: "activities_list",
                      })
                    }
                  />
                </TableControlsWrapper>
              </>
            }
            showDetailsRow={false}
            rows={this.getTableData(activities)}
            columns={columns}
            page={this.state.page}
            showPagination
            pages={this.state.totalRecors}
            loading={loading}
            manual
            showPageSizeOptions={false}
            PaginationComponent={() => (
              <Pagination
                totalRecords={this.state.totalRecors}
                pageLimit={20}
                pageNeighbours={2}
                currentPage={this.state.page}
                onPageChanged={(changedPage) => {
                  this.setState({ page: changedPage.currentPage }, this.getActivites);
                }}
              />
            )}
            getTrProps={(state, rowInfo: iRowInfo<ActivitiesPageActivity> | undefined) => {
              const props: {
                className: string;
                onClick?: () => void;
              } = {
                className: "activities-row",
              };
              if (!loading) {
                props.onClick = () => {
                  if (rowInfo?.original) {
                    this.openActivityDetails(rowInfo.original);
                  }
                };
              }
              return props;
            }}
            noContentComponent={<NoActivities onAddActivity={() => this.setState({ popupAddPunchVisible: true })} />}
          />
          {popupAddPunchVisible && (
            <SidePopupOverlay
              width={614}
              header={t("Create Multiple Activities")}
              isOpen={popupAddPunchVisible}
              onClose={() => this.setState({ popupAddPunchVisible: false })}
              contentOverflow
              paddingBottom
            >
              <AddActivities
                isTimesheetEnabled={this.props.timesheetContext.isTimesheetEnabled}
                prefillProfile={searchObj?.employee && !searchObj.type ? searchObj.employee : null}
                onYes={this.onAddActivities}
                onClose={() => this.setState({ popupAddPunchVisible: false })}
              />
            </SidePopupOverlay>
          )}
          {selectedActivity && (
            <SidePopupOverlay
              contentOverflow
              header={<PopupHeaderText>{t("Activity Details")}</PopupHeaderText>}
              isOpen={!!selectedActivity}
              headerStyle={selectedActivity?.status || ""}
              onClose={() => {
                if (this.activityStateRef.current.loading) {
                  const isConfirmed = window.confirm(t("Cancel attachment upload?"));
                  if (!isConfirmed) {
                    return;
                  }
                }
                this.setState({ selectedActivity: null });
                if (refetchOnClose || this.activityStateRef.current.changed) {
                  this.getActivites();
                }
              }}
            >
              <ActivityDetails
                stateRef={this.activityStateRef}
                activity={selectedActivity ? selectedActivity.activity : null}
                isTimesheetEnabled={this.props.timesheetContext.isTimesheetEnabled}
                allowFutureActivities={allowFutureActivities}
                onActivityUpdate={() => {
                  this.setState({ refetchOnClose: true });
                }}
                onPopupAction={(notification) => {
                  this.setState({ ...notification, selectedActivity: null });
                  if (notification.notificationType === NotificationType.success) {
                    void this.getActivites();
                  }
                }}
              />
            </SidePopupOverlay>
          )}
          <ModalDialog isOpen={!!activityUuidToDecline} onClose={() => this.setState({ activityUuidToDecline: null })}>
            <Lightbox
              title={t("decline-activity-title")}
              text={t("decline-activity-description")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ activityUuidToDecline: null });
              }}
              onYes={this.onDeclineActivityConfirmationClicked}
            />
          </ModalDialog>

          {selectedRowsUuids.length > 0 && (
            <ActivitiesActionsBar
              selectedActivityUuids={selectedRowsUuids}
              onUncheckAll={() => this.onSelectAllCheck(false, [])}
              onConfirm={this.onConfirmBulkChange}
              onDone={(res) => {
                this.setState(
                  {
                    notification: res.notification,
                    notificationType: res.notificationType,
                    selectedRowsUuids: res.notificationType !== NotificationType.success ? selectedRowsUuids : [],
                    selectAllChecked: res.notificationType !== NotificationType.success ? selectAllChecked : false,
                  },
                  this.getActivites,
                );
              }}
            />
          )}

          <ModalDialog isOpen={showSubmitPopup} onClose={() => this.setState({ showSubmitPopup: false })}>
            <SubmitTimesheetPopup onClose={() => this.setState({ showSubmitPopup: false })} />
          </ModalDialog>

          {popupSettingsVisible && (
            <TimesheetSettingsModal
              onClose={() => this.setState({ popupSettingsVisible: false })}
              setNotification={(n) =>
                this.setState({ notification: n.notification, notificationType: n.notificationType })
              }
            />
          )}
        </Wrapper>
      </FullPage>
    );
  }
}

export default withRouter(
  withTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.activities])(ActivitiesPage),
);
