import { Injectable } from "services/di";
import { ActivityRes, OitchauAuthedApi, GetInjectedExternalAttributesSummeryResponse } from "services/oitchau-api";
import { AppStorage } from "services/app-storage";
import { ProjectsService } from "services/projects-service";
import { LocationsService } from "services/locations-service";
import { minsToHrsMins, hrsMinsToMins } from "utils/common";
import moment from "moment-timezone";
import { ScheduleType, Shift } from "types/models/shift";
import Sentry from "utils/sentryUtils";
import { InjectedTaskData } from "types/injected";
import { Location } from "types/models/location";

export interface ActivitiesListItem {
  id: string;
  client: string;
  task: string;
  project: string;
  duration: string;
  numItems?: number;
  _raw: ActivityRes;
}

export interface ActivitiesListGroup extends ActivitiesListItem {
  id: string;
  projectUuid: string;
  taskUuid: string;
  items: ActivitiesListItem[];
}

export interface ActivitiesDay {
  date: string;
  duration: string;
  groups: ActivitiesListGroup[];
}

@Injectable()
export class WidgetProjectsService {
  constructor(
    protected projectsService: ProjectsService,
    protected locationsService: LocationsService,
    protected appStorage: AppStorage,
    protected oitchauApi: OitchauAuthedApi,
  ) {}

  async getLockDate() {
    const profile = (await this.appStorage.getProfile())!;
    return profile.last_lock_date;
  }

  async listLocations() {
    let locsRes: Location[] = [];

    try {
      locsRes = await this.locationsService.listOwnLocations();
    } catch (error) {
      Sentry.sendError(error);
    }

    return locsRes;
  }

  async listProjects(userProfileUuid?: string) {
    const projsRes = await this.projectsService.listProjectsWithTasks(userProfileUuid);
    return projsRes;
  }

  async listProjectsWithTasks(userProfileUuid?: string) {
    return this.projectsService.listProjectsWithTasksAndClients(userProfileUuid);
  }

  async getProject(opts: { externalId: string; source: string }) {
    const projs = await this.projectsService.listProjects();
    const proj = projs.find((p) => p.id === opts.externalId && p.source === opts.source);
    const projSummary = await this.projectsService.getInjectedProjectSummary(opts.source, opts.externalId);
    return {
      ...proj,
      totalDuration: projSummary.content.totalDuration,
      totalServiceCost: projSummary.content.totalServiceCost,
      totalEmployeeCost: projSummary.content.totalEmployeeCost,
    };
  }

  async listTasks(opts) {
    const tasksRes = await this.projectsService.listTasks(opts);
    return tasksRes;
  }

  async getRunningActivity() {
    const profile = (await this.appStorage.getProfile())!;
    const activity = await this.projectsService.getRunningActivity({ user_profile_uuid: profile.uuid });
    if (!activity) return null;
    return activity;
  }

  protected rawToAct(raw: ActivityRes) {
    const dets = {
      date: raw.date,
      duration: minsToHrsMins(raw.duration),
      startTime: minsToHrsMins(raw.startTime),
      endTime: minsToHrsMins(raw.endTime),
      task: raw.task?.name,
      project: raw.project?.name,
      client: raw.project?.client?.name,
      location: raw.location?.name,
      customFields: raw.customFields,
      attachments: raw.attachments,
      isRunning: raw.status === "running",
      _raw: raw,
    };
    return dets;
  }

  getActivityDetails(act: ActivitiesListItem) {
    const dets = this.rawToAct(act._raw);
    return dets;
  }

  async getActivity(uuid: string) {
    const act = await this.projectsService.getActivity(uuid);
    return act;
  }

  async updateActivity(activity) {
    const profile = await this.appStorage.getProfile();
    const upd = {
      ...activity,
      companyUuid: profile.company.uuid,
      updatedBy: profile.uuid,
    };
    const updated = await this.projectsService.updateActivity(upd);
    return updated;
  }

  async deleteActivity(activity) {
    const profile = await this.appStorage.getProfile();
    const upd = {
      ...activity,
      companyUuid: profile.company.uuid,
      updatedBy: profile.uuid,
    };

    await this.projectsService.deleteActivity(upd);
  }

  protected groupDay(rawActivities: ActivityRes[]): ActivitiesDay {
    let duration = 0;
    const groupsDict: { [key: string]: any } = {};
    for (const rawAct of rawActivities) {
      if (rawAct.status === "running" || rawAct.status === "declined" || rawAct.status === "deleted") continue;
      duration += rawAct.duration;
      const item = {
        id: rawAct.uuid,
        client: rawAct.project?.client?.name,
        task: rawAct.task?.name,
        project: rawAct.project?.name,
        duration: minsToHrsMins(rawAct.duration),
        startTime: rawAct.startTime,
        customFields: rawAct.customFields,
        attachments: rawAct.attachments || [],
        time: `${minsToHrsMins(rawAct.startTime)} - ${minsToHrsMins(rawAct.endTime)}`,
        _raw: rawAct,
      };
      const key = `${rawAct.project?.uuid}-${rawAct.task?.uuid}`;
      if (!groupsDict[key]) {
        groupsDict[key] = {
          id: key,
          client: item.client,
          project: item.project,
          task: item.task,
          projectUuid: rawAct.project?.uuid,
          taskUuid: rawAct.task?.uuid,
          duration: 0,
          items: [],
          numItems: 0,
          startTime: Infinity,
        };
      }
      const group = groupsDict[key];
      group.duration += rawAct.duration;
      group.items.push(item);
      group.numItems += 1;
      group.startTime = Math.max(group.startTime, rawAct.startTime);
    }

    const groups = Object.values(groupsDict)
      .sort((a, b) => a.startTime - b.startTime)
      .map((g) => {
        const gg = {
          ...g,
          items: g.items.sort((a, b) => a.startTime - b.startTime).reverse(),
          duration: minsToHrsMins(g.duration),
        };
        return gg;
      });

    const day = {
      date: rawActivities[0].date,
      duration: minsToHrsMins(duration),
      groups,
    };
    return day;
  }

  /**
   * Get current employee activities starting from the most recent ones.
   * Yield arrays of days.
   * Guarantee that each day item has all activities for the day.
   */
  async *getDays() {
    const profile = await this.appStorage.getProfile();
    const actsPerDayGen = this.projectsService.getActivitiesPerDay({ user_profile_uuid: profile.uuid });
    for await (const rawDays of actsPerDayGen) {
      const days = rawDays.map((rawDay) => this.groupDay(rawDay)).filter((d) => d.groups.length > 0);
      yield days;
    }
    return undefined; // avoids typechecking issues in `if (next.value) values.push(...next.value)` scenario
  }

  async createActivity(act) {
    return this.projectsService.createActivity(act);
  }

  /**
   * Get a template of a new activity that can be added (saved) by updateActivity.
   */
  getAddActivityTemplate() {
    const now = moment();
    const startTime = hrsMinsToMins(
      moment.max(now.clone().startOf("hour").subtract(1, "hour"), now.clone().startOf("day")).format("HH:mm"),
    );
    const endTime = hrsMinsToMins(now.clone().startOf("hour").format("HH:mm"));
    const act = {
      uuid: undefined,
      date: now.format("YYYY-MM-DD"),
      startTime,
      endTime,
      duration: endTime - startTime,
      projectUuid: null,
      taskUuid: null,
      locationUuid: null,
      customFields: [],
      attachments: [],
      status: "pending",
    };
    return act;
  }

  async startActivity(itd = {}) {
    const now = moment();
    const predictedEndAt = await this.getPredictedEndAt(null);
    const cre = {
      uuid: undefined,
      date: now.format("YYYY-MM-DD"),
      createdAt: now.toISOString(), // needed only for time to start from 0
      startTime: hrsMinsToMins(now.format("HH:mm")),
      endTime: 0,
      projectUuid: null,
      taskUuid: null,
      locationUuid: null,
      customFields: [],
      attachments: [],
      status: "running",
      predictedEndAt,
      ...itd,
    };
    const uuid = await this.createActivity(cre);

    // backend fills in the defaults for some fields (namely locationUuid) on creation but doesn't return them
    // backend also doesn't allow to update activity without locationUuid, so we need to fetch the activity after start
    const profile = (await this.appStorage.getProfile())!;
    const running = await this.projectsService.getRunningActivity({ user_profile_uuid: profile.uuid });
    if (running.uuid !== uuid) {
      throw new Error("Running activity is not the one just started");
    }
    return running;
  }

  async stopActivity(act) {
    const m = moment();
    const start = moment(`${act.date} ${minsToHrsMins(act.startTime)}`, "YYYY-MM-DD HH:mm");
    const diffMinutes = m.diff(start, "minutes");
    const upd = {
      uuid: act.uuid,
      date: act.date,
      startTime: act.startTime,
      endTime: act.endTime || act.startTime + diffMinutes,
      projectUuid: act.projectUuid,
      taskUuid: act.taskUuid,
      locationUuid: act.locationUuid,
      customFields: act.customFields,
      attachments: act.attachments,
      stopReason: act.stopReason,
    };
    const updated = await this.updateActivity(upd);
    const updd = {
      ...act,
      ...upd,
      ...updated,
    };
    return updd;
  }

  async resumeActivity(act) {
    const upd = {
      uuid: act.uuid,
      date: act.date,
      startTime: act.startTime,
      endTime: 0,
      projectUuid: act.projectUuid,
      taskUuid: act.taskUuid,
      locationUuid: act.locationUuid,
      customFields: act.customFields,
      attachments: act.attachments,
      stopReason: null,
      resumeActivity: true,
    };
    const updated = await this.updateActivity(upd);
    const updd = {
      ...act,
      ...upd,
      ...updated,
    };
    return updd;
  }

  async repeatActivity(act) {
    const now = moment();
    const predictedEndAt = await this.getPredictedEndAt(act.projectUuid);
    const cre = {
      uuid: undefined,
      date: now.format("YYYY-MM-DD"),
      startTime: hrsMinsToMins(now.format("HH:mm")),
      createdAt: now.toISOString(), // needed only for time to start from 0
      endTime: 0,
      projectUuid: act.projectUuid,
      taskUuid: act.taskUuid,
      locationUuid: act.locationUuid,
      customFields: act.customFields,
      attachments: [], // cannot reuse attachments because they will get unlinked from original activity (?)
      status: "running",
      predictedEndAt,
    };
    const uuid = await this.createActivity(cre);
    const created = { ...cre, uuid };
    return created;
  }

  async createTask(task) {
    const uuid = await this.projectsService.createTask(task);
    return uuid;
  }

  async createProject(project) {
    const uuid = await this.projectsService.createProject(project);
    return uuid;
  }

  validateActivity(act) {
    const errors = this.projectsService.validateActivity(act);
    return errors;
  }

  protected async getShifts(YYYYMMDD: string) {
    const profile = (await this.appStorage.getProfile())!;
    const m = moment(YYYYMMDD, "YYYY-MM-DD");
    const resShifts = await this.oitchauApi.getShifts({
      dateFrom: m.clone().subtract(1, "day").format("YYYY-MM-DD"),
      dateTo: m.clone().add(1, "day").format("YYYY-MM-DD"),
      employeeUuid: profile.uuid,
    });
    return (resShifts?.content || []) as Shift[];
  }

  /** return null if no suitable shift, or if outside of shift */
  getShiftEndsAt(shifts: Shift[]) {
    const now = moment();
    const sh = shifts.find((s) => {
      const shouldPredict = s.scheduleType === ScheduleType.regular;
      if (!shouldPredict) return false;
      const minStart = moment(s.date, "YYYY-MM-DD").startOf("day").add(s.minStartTime, "minutes").toDate();
      const maxEnd = moment(s.date, "YYYY-MM-DD").startOf("day").add(s.maxEndTime, "minutes").toDate();
      return now.isBetween(minStart, maxEnd);
    });
    if (sh == null) return null;
    const endsAt = moment(sh.date, "YYYY-MM-DD").startOf("day").add(sh.maxEndTime, "minutes").toDate();
    return endsAt;
  }

  async getPredictedEndAt(projectUuid: string | null) {
    const today = moment().format("YYYY-MM-DD");
    const [shifts, project, companyRules] = await Promise.all([
      this.getShifts(today),
      projectUuid == null ? null : this.projectsService.getProject({ projectUuid }),
      projectUuid != null ? null : this.projectsService.getCompanyRules(),
    ]);
    const shiftEndsAt = this.getShiftEndsAt(shifts);
    const shouldPredict =
      projectUuid == null ? companyRules!.stopActivitiesWithoutTaskBySchedule : project!.restrictBasedOnSchedule;
    return shouldPredict ? shiftEndsAt : null;
  }

  async getInjectedExternalAttributesSummary(projectExternalId: string, source: string, values: string[]) {
    let res = { content: [] as GetInjectedExternalAttributesSummeryResponse[] };
    try {
      res = await this.projectsService.getInjectedExternalAttributesSummary(projectExternalId, source, values);
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  async getInjectedProjectSummary(source: string, externalId: string) {
    let res = { content: {} as { totalDuration: number } };
    try {
      res = await this.projectsService.getInjectedProjectSummary(source, externalId);
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  async getInjectedTaskSummary({ taskName, taskSource }: { taskName: string; taskSource: string }) {
    let res = { content: [], metadata: { userProfilesByUuid: {} } };
    try {
      res = await this.projectsService.getInjectedTaskSummary({ name: taskName, source: taskSource });
    } catch (err) {
      console.error(err);
    }
    const durationsPerUserProfile = res.content.map((durPerUserProf) => {
      const obj = {
        ...res.metadata.userProfilesByUuid[durPerUserProf.userProfileUuid],
        ...durPerUserProf,
      };
      return obj;
    });
    const profile = (await this.appStorage.getProfile())!;
    const durationPerOwnProfile = {
      userProfileUuid: profile.uuid,
      uuid: profile.uuid,
      fullName: profile.full_name,
      avatarId: profile.avatar_id,
      duration: 0,
      ...durationsPerUserProfile.find((durPerUserProf) => durPerUserProf.uuid === profile.uuid),
    };
    let totalDuration = 0;
    for (const durPerUserProf of durationsPerUserProfile) {
      totalDuration += durPerUserProf.duration;
    }
    return {
      durationsPerUserProfile,
      durationPerOwnProfile,
      totalDuration: res.metadata.totalDuration != null ? res.metadata.totalDuration : totalDuration,
    };
  }

  async autodetectTask(itd: InjectedTaskData) {
    let autodetectedTask = null;
    if (itd?.taskSource === "Asana") {
      const integratedTasks = await this.projectsService.listTasks({
        source: itd.taskSource,
        externalId: itd.taskId,
      });
      autodetectedTask = integratedTasks[0];
      if (!autodetectedTask?.externalId || !autodetectedTask.externalId.includes(itd?.taskId)) {
        autodetectedTask = null;
      }
    }
    return autodetectedTask;
  }
}
