import cx from "classnames";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import { Header } from "../Header";
import { IconDayioLogo, IconOptions } from "../styled";

const styles = stylesheet`
.Wrapper {
  flex-shrink: 0;

  .Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    margin-inline-end: -17px;
    cursor: pointer;

    &:hover {
      background: var(--colors-bgHover);
    }

    > * {
      width: 20px;
    }
  }

  .Tab {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    font-weight: var(--typography-font-weight-bold);
    line-height: 15px;
    color: var(--colors-surface-600);
    text-transform: uppercase;
    cursor: pointer;

    &.active {
      color: var(--colors-primary);
      border-top: 2px solid transparent;
      border-bottom: 2px solid var(--colors-primary);
      transition: all 100ms, border-top 0ms; /* fix flickering of border-top due to transparency animation artifacts */
    }

    &:hover {
      background: var(--colors-bgHover);
    }
  }

  .TextTab {
    @extend .Tab;
  }

  .Options {
    @extend .Tab;

    display: flex;
    align-items: center;
    width: 50px;
    margin-inline-start: auto;
    fill: var(--colors-surface-500);

    &.active {
      border: none;
      fill: var(--colors-primary);
    }
  }

  &.hideHeader {
    display: none;
    visibility: hidden;
    background: transparent;
  }

  &.hideTextTabs {
    .TextTab {
      display: none;
    }
  }

  &.hideOptions {
    padding-inline-start: 17px;

    .Logo,.Options {
      display: none;
    }
  }
}
`;

export enum TabType {
  punch = "punch",
  projects = "projects",
  options = "options",
}

export interface TabSelectorProps extends React.HTMLProps<HTMLDivElement> {
  tabs: TabType[];
  activeTab: TabType;
  onTabClick: (tab: TabType) => void;
}

export function TabSelector(props: TabSelectorProps) {
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const { activeTab, tabs, onTabClick, ...restProps } = props;

  const hideTextTabs = !(tabs.includes(TabType.punch) && tabs.includes(TabType.projects));
  const hideOptions = !tabs.includes(TabType.options);
  const hideHeader = tabs.length === 0 || (tabs.length === 1 && [TabType.punch, TabType.projects].includes(tabs[0]));

  const handleLogoClick = () => {
    if (tabs.includes(TabType.punch)) {
      onTabClick(TabType.punch);
    } else if (tabs.includes(TabType.projects)) {
      onTabClick(TabType.projects);
    }
  };

  return (
    <Header
      className={cx(styles.Wrapper, {
        [styles.hideTextTabs]: hideTextTabs,
        [styles.hideOptions]: hideOptions,
        [styles.hideHeader]: hideHeader,
      })}
      {...restProps}
    >
      <div className={styles.Logo} onClick={handleLogoClick}>
        <IconDayioLogo />
      </div>
      <div
        className={cx(styles.TextTab, { [styles.active]: activeTab === TabType.punch })}
        onClick={() => onTabClick(TabType.punch)}
      >
        {t("Schedule")}
      </div>
      <div
        className={cx(styles.TextTab, { [styles.active]: activeTab === TabType.projects })}
        onClick={() => onTabClick(TabType.projects)}
      >
        {t("Projects")}
      </div>
      <div
        className={cx(styles.Options, { [styles.active]: activeTab === TabType.options })}
        onClick={() => onTabClick(TabType.options)}
      >
        <IconOptions />
      </div>
    </Header>
  );
}
