import moment from "moment";
import { useReducer } from "react";
import { TFunction } from "react-i18next";
import {
  DashboardProcessedData,
  DashboardFrequency,
  Groups,
  PeriodAggregation,
  PeriodAggregationData,
} from "types/models/dashboard";
import getGenericGroupName from "./getAllEntitiesGroupName";

const formats = { month: "MMM YYYY", week: "ddd, MMM DD YYYY", day: "ddd, MMM DD YYYY" } as const;

const defaultDataCreator =
  <K extends keyof PeriodAggregationData>(requestedProps: K[]) =>
  (): Pick<PeriodAggregationData, K> =>
    requestedProps.reduce((acc, prop) => ({ ...acc, [prop]: 0 }), {}) as Pick<PeriodAggregationData, K>;

type Dataset<K extends keyof PeriodAggregationData> = { [groupName: string]: Pick<PeriodAggregationData, K>[] };

const initCategories = (periods: string[], frequency: DashboardFrequency) =>
  periods.map((periodLabel) => ({ label: moment(periodLabel).format(formats[frequency]) }));

const groupProcessor = <K extends keyof PeriodAggregationData>(requestedProps: K[], periods: string[]) => {
  const getDefaultData = defaultDataCreator(requestedProps);

  return (oldDataset: Dataset<K>, data: PeriodAggregationData, idx: number, groupName: string): Dataset<K> => {
    const dataset: Dataset<K> = { ...oldDataset };
    if (!dataset[groupName]) {
      dataset[groupName] = Array(periods.length).fill(getDefaultData());
    }

    dataset[groupName][idx] = data;

    return dataset;
  };
};

type PeriodAggregationState<K extends keyof PeriodAggregationData> = {
  frequency: DashboardFrequency;
  periods: string[];
  groupBy: Groups;
} & DashboardProcessedData<K>;

const getInitialState = ({
  frequency,
  periods,
  groupBy,
}: {
  frequency: DashboardFrequency;
  periods: string[];
  groupBy: Groups;
}) => ({
  frequency,
  periods,
  groupBy,
  categories: initCategories(periods, frequency),
  dataset: {},
});

type InitAction = { action: "init"; data: { frequency: DashboardFrequency; periods: string[]; groupBy: Groups } };
type UpdateAction = { action: "update"; data: PeriodAggregation[] };

const buildPeriodAggregationReducer =
  <K extends keyof PeriodAggregationData>({ requestedProps, t }: { requestedProps: K[]; t: TFunction }) =>
  (state: PeriodAggregationState<K>, data: InitAction | UpdateAction): PeriodAggregationState<K> => {
    if (data.action === "init") {
      return getInitialState({
        frequency: data.data.frequency,
        periods: data.data.periods,
        groupBy: data.data.groupBy,
      });
    }

    const { frequency, groupBy, periods, categories } = state;
    let { dataset } = state;

    const processGroup = groupProcessor(requestedProps, periods);

    data.data.forEach((row) => {
      if (row.periodLabel === null) {
        // No data for this group in this period
        // Backend filters out zero sums/averages,
        // but doesn't filter periods with no data
        return;
      }

      const periodIdx = periods.indexOf(row.periodLabel);

      if (periodIdx === -1) {
        throw new Error(`Period ${row.periodLabel} not found in periods list: ${periods.join(", ")}`);
      }

      const groupName =
        groupBy === "company" || row.groupUuid === null ? t(getGenericGroupName(groupBy)) : row.groupName;

      dataset = processGroup(dataset, row.data, periodIdx, groupName);
    });

    return { frequency, groupBy, periods, categories, dataset };
  };

const usePeriodAggregationProcessor = <K extends keyof PeriodAggregationData>(
  frequency: DashboardFrequency,
  periods: string[],
  groupBy: Groups,
  requestedProps: K[],
  t: TFunction,
) => {
  const [{ categories, dataset }, onDataLoaded] = useReducer(
    buildPeriodAggregationReducer({ requestedProps, t }),
    getInitialState({ frequency, periods, groupBy }),
  );

  return [{ categories, dataset }, onDataLoaded] as const;
};

export default usePeriodAggregationProcessor;
