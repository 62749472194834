import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { TranslationNamespaces } from "types/translationNamespaces";
import TimeControl from "components/controls/TimeControl";
import Button, { ButtonState } from "components/controls/StyledButton";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { pen as penBtn } from "components/svg-images";
import { minsToHrsMins } from "utils/common";
import { ActivityStatuses } from "types/models/activity";
import { Activity, CompanyTimesheetSettings } from "components/Projects/projectsApiTypes";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { getActivitiesList } from "components/Projects/projectsApiUtils";
import { GlobalStoreCompany } from "types/models/company";
import {
  Body,
  Buttons,
  Date,
  EntriesHeader,
  EntryRow,
  Header,
  HR,
  OpenDetails,
  Project,
  TaskName,
  Total,
  Wrapper,
} from "./styled";
import { ActivityExtendedItem } from "../ActivityCell";
import { getDiffInMins } from "./utils";

const moment = extendMoment(momentTz);

type Task = {
  name: string;
  project: string;
  uuid: string;
  date: moment.Moment;
  entries?: ActivityExtendedItem[];
};

/** Enhanced type for UI */
type Entry = ActivityExtendedItem & {
  uuid: string;
  error: boolean;
  total: string;
  status: string;
  duplicationError?: boolean;
};
export type TaskEntry = Omit<Entry, "error">;

const DEFAULT_ENTRY: Readonly<{ total: string; startTime: string; endTime: string; error: boolean; status: string }> =
  Object.freeze({
    startTime: "00:00",
    endTime: "00:00",
    total: "00:00",
    error: false,
    status: "new",
  });

interface MultipleTasksLightboxProps {
  task: Task;
  onSave: (entries: Omit<Entry, "error">[]) => void;
  onCancel: () => void;
  onOpenDetails: (activity: Activity) => void;
  settings?: CompanyTimesheetSettings;
  userProfileUuid?: string;
}

const MultipleTasksLightbox = ({
  task,
  onCancel,
  onSave,
  onOpenDetails,
  settings,
  userProfileUuid,
}: MultipleTasksLightboxProps) => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const [entries, setEntries] = useState<Entry[]>(
    (task.entries || []).map((e) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uuid: uuidv4(),
      error: false,
      total: minsToHrsMins(getDiffInMins(e.startTime, e.endTime) || 0),
      ...e,
    })),
  );
  const checkCrossActivityTimeDuplication = (entriesArray: Entry[], activities = data?.activities): void => {
    const setDateAndTime = (date: moment.Moment, time: string) => {
      const [hours, minutes] = time.split(":");
      return date.set({ hours: +hours, minutes: +minutes });
    };
    const convertWithoutTz = (date: moment.Moment) => moment(date.format("YYYY-MM-DD HH:mm"));

    const otherActivities = activities?.filter(
      (activity) => !entriesArray.map((ec) => ec.uuid).includes(activity.uuid),
    );
    const newArray = entriesArray.map((entry, index) => {
      const duplicatedCrossActivity = !!otherActivities?.find((activity) =>
        moment
          .range(
            convertWithoutTz(setDateAndTime(task.date, entry.startTime)),
            convertWithoutTz(setDateAndTime(task.date, entry.endTime)),
          )
          .overlaps(
            moment.range(convertWithoutTz(moment(activity.startedAt)), convertWithoutTz(moment(activity.endedAt))),
          ),
      );
      const duplicatedSameActivity = !!entriesArray.find(
        (activity, i) =>
          index !== i &&
          moment
            .range(
              convertWithoutTz(setDateAndTime(task.date, entry.startTime)),
              convertWithoutTz(setDateAndTime(task.date, entry.endTime)),
            )
            .overlaps(
              moment.range(
                convertWithoutTz(setDateAndTime(task.date, activity.startTime)),
                convertWithoutTz(setDateAndTime(task.date, activity.endTime)),
              ),
            ),
      );

      return {
        ...entry,
        duplicationError: duplicatedCrossActivity || duplicatedSameActivity || false,
      };
    });

    setEntries(newArray);
  };

  const onTimeChange = (key: "startTime" | "endTime", uuid: string, value: string, error: boolean) => {
    const index = entries.findIndex((e) => e.uuid === uuid);
    const entry = entries[index];
    if (!entry) {
      return;
    }

    const totalMins = key === "startTime" ? getDiffInMins(value, entry.endTime) : getDiffInMins(entry.startTime, value);
    // TODO possible bug with one error
    entries[index] = { ...entry, [key]: value, error: error || totalMins < 0, total: minsToHrsMins(totalMins || 0) };

    checkCrossActivityTimeDuplication([...entries]);
  };

  const onAddEntry = () => {
    setEntries([...entries, { ...DEFAULT_ENTRY, uuid: uuidv4() }] as Entry[]);
  };

  const [initExtendedValidation, extendedDataLoaded, data] = useAsyncCallback(async () => {
    const [activities] = await Promise.all([
      getActivitiesList({
        employeeUuid: userProfileUuid,
        from: task.date.format("YYYY-MM-DD"),
        to: task.date.format("YYYY-MM-DD"),
        status: "pending,approved",
        requestedBy: window.global_store.profile.uuid,
        companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
      }),
    ]);
    checkCrossActivityTimeDuplication([...entries], activities.content);
    return {
      activities: activities.content,
    };
  }, []);

  useEffect(() => {
    void initExtendedValidation();
  }, [settings]);

  return (
    <Wrapper>
      <Header>
        <div>
          <TaskName>{task.name}</TaskName>
          <Project>{task.project}</Project>
        </div>
        <Date>{task.date.format("ddd DD/MM")}</Date>
      </Header>

      <Body>
        {!!entries?.length && (
          <EntriesHeader>
            <span>{t("Start")}</span>
            <span>{t("End")}</span>
            <span>{t(`${TranslationNamespaces.common}|Total`)}</span>
          </EntriesHeader>
        )}

        {entries.map((s) => (
          <EntryRow key={s.uuid}>
            <TimeControl
              error={s.error || s.duplicationError}
              disabled={s.status === ActivityStatuses.approved}
              value={s.startTime}
              onTimeChange={({ value, error }: { value: string; error: boolean }) =>
                onTimeChange("startTime", s.uuid, value, error)
              }
            />
            <TimeControl
              error={s.error || s.duplicationError}
              disabled={s.status === ActivityStatuses.approved}
              value={s.endTime}
              onTimeChange={({ value, error }: { value: string; error: boolean }) =>
                onTimeChange("endTime", s.uuid, value, error)
              }
            />
            <TimeControl value={s.total} disabled />

            {s.activity && <OpenDetails onClick={() => onOpenDetails(s.activity)}>{penBtn}</OpenDetails>}
          </EntryRow>
        ))}

        <AddRemoveLink label={t("Add new entry")} onClick={onAddEntry} />

        <HR />

        <Total>
          {`${t(`${TranslationNamespaces.common}|Total`)}: `}
          <span>{minsToHrsMins(entries.reduce((acc, e) => acc + getDiffInMins(e.startTime, e.endTime), 0) || 0)}</span>
        </Total>
      </Body>

      <Buttons>
        <Button
          state={ButtonState.secondary}
          value={t(`${TranslationNamespaces.common}|Cancel`)}
          onClick={onCancel}
          style={{ width: "126px" }}
        />
        <Button
          style={{ width: "126px" }}
          value={t(`${TranslationNamespaces.common}|Save`)}
          onClick={() => {
            onSave(entries.map(({ error, duplicationError, ...entry }) => entry));
          }}
          disabled={entries.some((e) => e.error || e.duplicationError)}
        />
      </Buttons>
    </Wrapper>
  );
};

export default MultipleTasksLightbox;
