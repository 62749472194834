import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Tooltip from "components/UI/Tooltip";
import useState from "hooks/useState";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import AssigneesDialog, { OnSaveProp } from "./AssigneesDialog";
import { ProjectTaskAssignee } from "./projectsApiTypes";

const Cell = styled.div`
  line-height: 34px;
  border: 1px solid transparent;
  border-radius: var(--shapes-border-radius-default);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
  height: 36px;
  min-height: 36px;
  &:hover {
    border-color: var(--colors-surface-400);
  }
`;

const Placeholder = styled.span`
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 130%;
  color: var(--colors-surface-600);
`;

type CellWithPopupProps = {
  assignees: ProjectTaskAssignee[];
  onUpdateTaskAssignees: (assignees: OnSaveProp) => void;
  id: string;
};

type CellWithPopupState = {
  isAssignDialogOpen: boolean;
};

const CellWithPopup = ({ assignees, id, onUpdateTaskAssignees }: CellWithPopupProps) => {
  const [{ isAssignDialogOpen }, setState] = useState<CellWithPopupState>({
    isAssignDialogOpen: false,
  });
  const { t } = useTranslation();
  const tooltipText = t("Click to Edit");

  const openPopup = () => {
    setState({
      isAssignDialogOpen: true,
    });
  };

  return (
    <>
      <Cell onClick={() => openPopup()} className="cell-with-popup" data-tip data-for={`tooltip-for-dropdown-${id}`}>
        {assignees?.length ? (
          assignees.map((s) => s.title).join(", ")
        ) : (
          <Placeholder>
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.projects,
              "custom-all-project-members",
              `${TranslationNamespaces.common}|All project’s members`,
            )}
          </Placeholder>
        )}
      </Cell>
      <Tooltip id={`tooltip-for-dropdown-${id}`}>
        <span>{tooltipText}</span>
      </Tooltip>
      {isAssignDialogOpen && (
        <AssigneesDialog
          title={translateEmployeeTerm(
            t,
            TranslationNamespaces.projects,
            "custom-assign-member",
            `${TranslationNamespaces.common}|Assign member`,
          )}
          selectedAssignees={assignees}
          isVisible={isAssignDialogOpen}
          setVisible={() =>
            setState({
              isAssignDialogOpen: false,
            })
          }
          onSave={(props) => {
            onUpdateTaskAssignees(props);
            setState({
              isAssignDialogOpen: false,
            });
          }}
        />
      )}
    </>
  );
};

export default CellWithPopup;
