import styled from "styled-components";

const Wrapper = styled.div`
  // display: flex;

  overflow-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  font-size: var(--typography-font-size-default);
  color: var(--colors-text1);
  text-align: center;

  .name {
    font-weight: var(--typography-font-weight-medium);
  }
`;

export interface LocationTextProps {
  name: string;
  address: string;
}

export default function LocationText(props: LocationTextProps) {
  const { name, address } = props;
  return (
    <Wrapper>
      <span className="name">{name}</span> - {address}
    </Wrapper>
  );
}
