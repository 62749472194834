import { IconSelectedTick } from "components/PunchNow/views";
import styled from "styled-components";
import { GroupedSubOption } from ".";

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  &.nested {
    padding: 0 20px;
  }
`;
const SubOptionRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid var(--colors-surface-150);
  &.nested {
    padding: 0 20px;
    padding-inline-start: 28px;
  }

  .Texts {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;

    .Name {
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-default);
      line-height: 110%;
      color: var(--colors-mainText);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.selected {
    .Texts {
      .Name {
        color: var(--colors-primary-600);
      }
    }
  }

  &.tmp:not(.selected) {
    .Texts {
      .Name {
        color: var(--colors-surface-900-p);
      }
    }
  }
  .highlighted {
    color: var(--colors-primary-600);
  }
`;

export const SubOptionRow = (props: {
  subOption: GroupedSubOption;
  selected: boolean;
  onSelect: () => void;
  searchValue?: string;
  nested?: boolean;
}) => {
  const { subOption, selected, onSelect } = props;
  const formatSearched = (name: string) => {
    if (!props.searchValue) return name;
    const match = name.match(new RegExp(props.searchValue, "iu"));
    if (!match) {
      // TODO: add highlight for matches of portuguese characters like é, ú etc.
      return name;
    }
    return `${name.slice(0, match!.index)}<span class="highlighted">${name.slice(
      match!.index,
      match!.index! + props.searchValue.length,
    )}</span>${name.slice(match!.index! + props.searchValue.length)}`;
  };
  return (
    <Row className={`Row with-hover ${props.nested ? "nested" : ""}`}>
      <SubOptionRowWrapper className={selected ? "selected" : ""} onClick={() => onSelect()}>
        <div className="Texts">
          <div className="Name" dangerouslySetInnerHTML={{ __html: formatSearched(subOption.label) }} />
        </div>
        {selected && <IconSelectedTick />}
      </SubOptionRowWrapper>
    </Row>
  );
};
