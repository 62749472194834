import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FullPage from "components/Layout/FullPage";
import { urlParam, getTitle } from "utils/common";
import { getLockGroupByUuid } from "utils/apiHelpers";
import NotificationRow from "components/NotificationRow";
import { PageWrapper } from "components/styled/Page";
import Tabs from "components/UI/Tabs";
import { PayrollGroup, PAYROLL_GROUP_DEFAULT_NAME } from "types/models/payrollGroup";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RouteComponentProps } from "react-router-dom";
import EmployeesTab from "./EmployeesTab";
import GroupRulesTab from "./GroupRulesTab";
import GlobalContext from "../../context/global-context";

enum PayrollGroupTabs {
  rules = "rules",
  employees = "employees",
}

const NEW_GROUP_ID = "new-group";
const TITLE = "Payroll Group";
const META_TITLE = TITLE;

interface PayrollGroupProps extends WithTranslation, RouteComponentProps {
  groupUuid: string;
}

interface PayrollGroupState {
  activeTab: PayrollGroupTabs;
  group: PayrollGroup | null;
  notification: string;
  isFetching: boolean;
}

class Group extends Component<PayrollGroupProps, PayrollGroupState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: PayrollGroupProps) {
    super(props);
    const { t } = props;

    this.state = {
      activeTab: props.groupUuid && urlParam("employeesTab") ? PayrollGroupTabs.employees : PayrollGroupTabs.rules,
      group: null,
      notification: "",
      isFetching: false,
    };

    document.title = getTitle(t(META_TITLE));
  }

  UNSAFE_componentWillMount() {
    if (this.props.groupUuid !== NEW_GROUP_ID && !this.state.isFetching) {
      void this.fetchGroup();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps: PayrollGroupProps) {
    if (this.props.groupUuid !== nextProps.groupUuid && urlParam("employeesTab")) {
      await this.fetchGroup();

      this.setState({ activeTab: PayrollGroupTabs.employees });
    }
  }

  fetchGroup = async () => {
    this.setState({ isFetching: true });

    const company = await this.context.getCompany();
    const groupResp = await getLockGroupByUuid({
      companyUuid: company.uuid,
      payrollGroupUuid: this.props.groupUuid,
    });

    this.setState({
      group: groupResp.content,
      isFetching: false,
    });
  };

  getGroupTitle = (group: PayrollGroup) => {
    const { t } = this.props;
    const defaultName = t(`${TranslationNamespaces.common}|default`);
    const groupName = group.name === PAYROLL_GROUP_DEFAULT_NAME ? defaultName : group.name;

    return group.isDefault ? `${groupName} (${defaultName})` : groupName;
  };

  render() {
    const { notification, activeTab, group } = this.state;
    const { t, groupUuid, history } = this.props;

    const tabs = [{ name: PayrollGroupTabs.rules, label: t("Rules") }];

    if (groupUuid !== NEW_GROUP_ID) {
      tabs.push({ name: PayrollGroupTabs.employees, label: t(`${TranslationNamespaces.common}|Employees`) });
    }

    return (
      <FullPage
        title={group ? this.getGroupTitle(group) : t(TITLE)}
        backButtonTitle={t("Payroll Groups")}
        backButtonOnclick={() => history.push("/payroll/payroll-groups/")}
      >
        <PageWrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton={false}
              type={NotificationType.success}
              message={notification}
            />
          )}

          <Tabs tabs={tabs} value={activeTab} onChange={(tab) => this.setState({ activeTab: tab })} />
          <div>
            {activeTab === PayrollGroupTabs.rules && (groupUuid === NEW_GROUP_ID || group) && (
              <GroupRulesTab groupUuid={groupUuid === NEW_GROUP_ID ? "" : groupUuid} group={group} />
            )}
            {activeTab === PayrollGroupTabs.employees && groupUuid !== NEW_GROUP_ID && group && (
              <EmployeesTab groupId={groupUuid} group={group} onUpade={this.fetchGroup} />
            )}
          </div>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.payment)(Group);
