import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Coupon, Interval, Price, Subscription, Tier } from "types/models/subscription";
import { SubscriptionApi } from "utils/api/subscription";
import { CheckedProduct } from "../PricingContainer";
import { BillingService } from "../BillingService";

const styles = stylesheet`@import "../billing.scss"`;
type SwitchToAnnualModalProps = {
  close: () => void;
  companyUuid: string;
  coupon: Coupon | null;
  products: CheckedProduct[];
  subscription: Subscription;
};

type ProductListItem = {
  seats: number;
  currentPrice: Price;
  givenPrice: Price;
  product: CheckedProduct;
};

const SwitchToAnnualModal = (props: SwitchToAnnualModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [productItems, setProductItems] = useState<ProductListItem[]>([]);
  const getTier = (tiers: Tier[], value: number) => tiers.find((tierData) => tierData.up_to! >= value);
  useEffect(() => {
    if (props.subscription) {
      const items = props.subscription.items.map((item) => {
        const product = props.products.find((product) => product.features[0] === item.feature)!;
        const givenPrice = product.prices.find((price) => price.interval === Interval.Year)!;
        return {
          product,
          seats: item.maxSeats,
          currentPrice: item.price!,
          givenPrice,
        };
      });
      setProductItems(items);
    }
  }, []);

  const submit = async () => {
    setError(null);
    setLoading(true);
    const payload = {
      items: productItems.map((item) => ({ seats: item.seats, priceUuid: item.givenPrice.uuid })),
    };
    await SubscriptionApi.upgradePlan(props.companyUuid, props.subscription.uuid, payload)
      .then(() => {
        window.location.href = `/billing/payment-success/upgrade/${Interval.Year}`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const { discount, total } = productItems.reduce(
    (acc, item) => {
      const total = BillingService.calculateTotalPrice(
        getTier(item.givenPrice.tiers, item.seats)!,
        Interval.Year,
        props.coupon,
        item.product.externalId,
      );
      const difference =
        total -
        BillingService.calculateTotalPrice(getTier(item.currentPrice.tiers, item.seats)!, Interval.Year, props.coupon) *
          12;
      acc.total += total;
      acc.discount += difference;
      return acc;
    },
    { total: 0, discount: 0 },
  );

  return (
    (productItems.length > 0 && (
      <div>
        <div className={`${styles.UpgradePopupTitle} ${styles.MarginBottom40}`}>{t("Switch to Annual")}</div>
        <div>
          {productItems.map((item) => (
            <div key={item.product.uuid} className={styles.SwitchToAnnualItems}>
              <div>
                <div className={styles.SwitchToAnnualItemsTitle}>{BillingService.getProductName(item.product, t)}</div>
                <div className={`${styles.TextGrey600} ${styles.Text14}`}>
                  {t("{{price}} x {{seatsNumber}} seats x {{monthNumber}} month", {
                    price: BillingService.calculateUnitPrice(
                      getTier(item.givenPrice.tiers, item.seats)!,
                      Interval.Year,
                      props.coupon,
                      item.product.externalId
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: item.currentPrice.currency,
                    }),
                    seatsNumber: item.seats,
                    monthNumber: 12,
                  })}
                </div>
              </div>
              <div className={`${styles.TextGrey800} ${styles.Text14}`}>
                {BillingService.calculateTotalPrice(
                  getTier(item.givenPrice.tiers, item.seats)!,
                  Interval.Year,
                  props.coupon,
                  item.product.externalId
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: item.currentPrice.currency,
                })}
              </div>
            </div>
          ))}
          <div className={styles.SwitchToAnnualItems}>
            <div>
              <div className={styles.SwitchToAnnualItemsTitle}>{t("Discount")}</div>
              <div className={`${styles.TextGrey600} ${styles.Text14}`}>{t("discount_annual_savings")}</div>
            </div>
            <div className={`${styles.TextGrey800} ${styles.Text14}`}>
              {discount.toLocaleString("en-US", {
                style: "currency",
                currency: productItems[0].currentPrice.currency,
              })}
            </div>
          </div>
          <div className={styles.SwitchToAnnualTotal}>
            <div>{t("Total")}</div>
            <div>
              {total.toLocaleString("en-US", {
                style: "currency",
                currency: productItems[0].currentPrice.currency,
              })}
            </div>
          </div>
        </div>
        <p className={styles.UpgradePopupTerms}>
          {t("switch_to_annual_description {{price}}", {
            price: total.toLocaleString("en-US", {
              style: "currency",
              currency: productItems[0].currentPrice.currency,
            }),
          })}
        </p>
        <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
          <Button
            disabled={loading}
            value={t("Cancel")}
            style={{ width: "48%" }}
            state={ButtonState.enableDay}
            onClick={props.close}
          />

          <Button
            disabled={loading}
            value={t("Confirm Upgrade")}
            style={{ width: "48%" }}
            state={ButtonState.primary}
            onClick={submit}
          />
        </div>
        {error ? <div className={styles.PaymentError}>{t(error)}</div> : null}
      </div>
    )) ||
    null
  );
};

export default SwitchToAnnualModal;
