import styled from "styled-components";

const TabsBar = styled.ul`
  display: inline-flex;
  align-items: center;
  padding: var(--common-pad-none, 0px) var(--component-container-md-hpad, 24px) var(--common-pad-none, 0px)
    var(--common-pad-none, 0px);
  margin: 0;
  gap: var(--component-tabs-gap, 16px);
  list-style: none;
  border-bottom: var(--component-tabs-border-bottom, 1px) solid var(--component-tabs-border-color, #e1e3eb);
`;

const Tab = styled.li<{ $active: boolean }>`
  margin: 0;
  height: var(--component-tabs-tab-height, 48px);
  padding: var(--component-tabs-tab-vpad, 0px) var(--component-tabs-tab-hpad, 0px);
  display: flex;
  align-items: center;
  border-bottom: solid 2px transparent;
  font-size: var(--cp-tabs-font-size, 15px);
  color: ${(p) =>
    p.$active
      ? "var(--component-tabs-tab-active-text-color, #3E4357)"
      : "var(--component-tabs-tab-default-text-color, #9BA1B7)"};
  font-weight: var(--cp-tabs-font-weight, var(--typography-font-weight-normal));
  border-bottom-color: ${(p) => (p.$active ? "var(--component-tabs-tab-active-border-color, #3E4357)" : "transparent")};
  cursor: pointer;
  position: relative;
  bottom: -1px;
  white-space: nowrap;
  &:hover {
    color: var(--component-tabs-tab-hover-text-color, #3e4357);
  }
`;

type UITab = {
  name: string;
  label: string;
  isActive: boolean;
};

interface TabsComponentProps {
  tabs: UITab[];
  onChange: (activeTab: string) => void;
}

function TabsComponent({ tabs, onChange }: TabsComponentProps): JSX.Element {
  return (
    <TabsBar>
      {tabs.map(({ name, label, isActive }) => (
        <Tab key={name} $active={isActive} onClick={(): void => onChange(name)}>
          {label}
        </Tab>
      ))}
    </TabsBar>
  );
}

export default TabsComponent;
