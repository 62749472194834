import { ChangeEvent, Component, ContextType, useContext } from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import { CPF } from "cpf_cnpj";
import brValidator from "br-validations";
import getSymbolFromCurrency from "currency-symbol-map";
import GlobalContext from "context/global-context";
import {
  getEmployeeTaxIdTranslation,
  getEmployeeTaxPayerType,
  hasEmployeesAccess,
  hasPermisionAccess,
  validatePhoneNumber,
} from "utils/common";
import {
  addEmployee,
  checkForExistingUserProfilesWithinCompany,
  deletePermissionRoleByUserProfileId,
  getEmployee,
  getPermissionRoleByUserProfileId,
  isPhoneNumberExist,
  updatePermissionRoleByUserProfileId,
} from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import ga from "utils/ga";
import { EmployeeTaxPayerTypes } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { EmployeeExistsCheckResult } from "types/models/employee";
import { UserProfile, UserProfileEmployeeStatus, UserProfileRole } from "types/models/userProfile";
import { Feature } from "types/models/subscription";
import { TranslationNamespaces } from "types/translationNamespaces";
import TextInputControl from "components/controls/StyledTextInput";
import TextInputControlNew from "components/controls/TextInputControlNew";
import { getServiceProviderSettings, updateServiceProviderSettings } from "components/Projects/projectsApiUtils";
import DepartmentsDropdownControl from "components/DepartmentsDropdownControl";
import PositionsDropdownControl from "components/PositionsDropdownControl";
import SubsidiariesDropdownControl from "components/SubsidiariesDropdownControl";
import LocationsDropdown from "components/LocationsDropdown";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Button from "components/controls/StyledButton";
import PhoneNumberField from "components/PhoneNumberField";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import ServicesDropdownControl from "components/Projects/ServicesDropdownControl";
import CheckboxControl from "components/UI/NewCheckbox";
import TeamsDropdownControl from "components/TeamsDropdownControl";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import AssociateEmployeeToProduct from "./AssociateEmployeeToProduct";
import { BillingService } from "../Billing/BillingService";
import EmployeePermssions from "./EmployeePermissions";
import { EmployeePermission } from "./AddEditPermissionPopup";
import { SubscriptionContext } from "../Billing/context/subscription-context.provider";
import "components/UI/Page/Page.scss";
import { translateEmployeeTerm } from "utils/translationHelpers";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 60px;

  .react-tel-input {
    .form-control {
      width: 100%;
    }
  }

  .react-select__placeholder {
    color: var(--colors-surface-900-p);
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 35px;
  width: 132px;
`;

const CheckboxWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;

  .checkbox-label {
    margin-inline-start: 12px;
  }
`;

const Block = styled.div``;

const BlockContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
  &.personal-details {
    max-height: 620px;
    padding-bottom: 8px;
    overflow: visible;
  }
  &.employee-details {
    max-height: 100500px;
    padding-bottom: 8px;
    overflow: visible;
  }
  &.advanced-settings {
    max-height: initial;
    padding-bottom: 8px;
    overflow: visible;
  }
  &.cost-details {
    max-height: initial;
    padding-bottom: 8px;
    overflow: visible;
  }
`;

const BlockTitle = styled.div<{ $popupMode?: boolean; $withTopMargin?: boolean }>`
  position: relative;
  ${(p) => (p.$popupMode ? "padding-inline-start: 20px;" : "")}
  margin-top: ${(p) => (p.$withTopMargin ? "21px" : "0")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--colors-surface-800);
  background-repeat: no-repeat;
  background-position-y: center;
  cursor: ${(p) => (p.$popupMode ? "pointer" : "default")};

  &::after {
    content: "";
    display: ${(p) => (p.$popupMode ? "block" : "none")};
    width: 9px;
    height: 9px;
    position: absolute;
    top: 2px;
    inset-inline-start: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 1a1 1 0 012 0v7a1 1 0 01-2 0V1z' fill='%23A1B2CF'/%3E%3Cpath d='M3.5 1a1 1 0 012 0v7a1 1 0 01-2 0V1z' fill='%23A1B2CF'/%3E%3Cpath d='M1 5.5a1 1 0 010-2h7a1 1 0 110 2H1z' fill='%23A1B2CF'/%3E%3Cpath d='M1 5.5a1 1 0 010-2h7a1 1 0 110 2H1z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  }

  &.opened::after {
    top: 5px;
    height: 3px;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='2.5' width='2' height='9' rx='1' transform='rotate(-90 0 2.5)' fill='%23525F7F'/%3E%3C/svg%3E");
    color: var(--colors-surface-800);
  }
`;

const EmailFieldWrapper = styled.div`
  position: relative;

  & > span {
    position: absolute;
    top: 11.5px;
    inset-inline-end:: 14px;
  }

  input {
    padding: 0;
    padding-inline-start: 15px;
    padding-inline-end: 30px;
  }
`;

const RatesWrapper = styled.div<{ $currencySign: string }>`
  display: flex;
  align-items: center;
  .SingleDatePicker {
    margin-top: 0;
  }
  & > div:first-child {
    position: relative;
    input {
      padding-inline-end: 20px;
    }

    &:after {
      content: ${(p) => `'${p.$currencySign}'`};
      position: absolute;
      inset-inline-end: 8px;
      top: 31px;
      font-size: 15px;
      line-height: 19px;
      color: var(--colors-unknown39);
    }
  }
`;

interface EmployeeDetailsProps extends WithTranslation {
  employee: UserProfile | null;
  popupMode?: boolean;
  onSave: (notification: string) => void;
  onError: (error: string) => void;
  subscriptionContext?: any;
}

interface EmployeeDetailsState {
  email: string | null;
  fullName: string;
  socialName: string | null;
  useSocialName: boolean;
  cpf: string;
  pis: string;
  phone: string;
  matricula: string;
  department: string | number | null;
  subsidiary: string | number | null;
  position: string | null;
  teamUuid: string | null;
  locationUuids: string[] | null;
  personalDetailsOpen: boolean;
  loading: boolean;
  serviceUuids: string[];
  sendInvitation: boolean;
  emailTouched: boolean;
  pisTouched: boolean;
  taxPayerIdTouched: boolean;
  countryCode: string | null;
  startDate: moment.Moment | null;
  advancedSettingsOpen: boolean;
  employeelDetailsOpen: boolean;
  costDetailsOpen: boolean;
  errors: Record<string, string | null> | null;
  projectEmployeeCost: string;
  /** new permissions */
  role: UserProfileRole;
  permissions: EmployeePermission[];
  associateProducts?: Feature[];
  seatsChange: {
    [Feature.TA]: number;
    [Feature.Project]: number;
  };
}

class EmployeeDetailsNew extends Component<EmployeeDetailsProps, EmployeeDetailsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  static defaultProps = {
    employee: {},
  };

  readonly state: Readonly<EmployeeDetailsState> = {
    email: null,
    fullName: "",
    socialName: null,
    useSocialName: false,
    cpf: "",
    pis: "",
    phone: "",
    matricula: "",
    department: null,
    subsidiary: null,
    position: null,
    teamUuid: null,
    locationUuids: null,
    personalDetailsOpen: true,
    loading: true,
    serviceUuids: [],
    sendInvitation: false,
    emailTouched: false,
    pisTouched: false,
    taxPayerIdTouched: false,
    countryCode: null,
    startDate: null,
    advancedSettingsOpen: false,
    employeelDetailsOpen: false,
    costDetailsOpen: false,
    errors: null,
    projectEmployeeCost: "",
    role: UserProfileRole.employee,
    permissions: [],
    associateProducts: undefined,
    seatsChange: {
      [Feature.TA]: 0,
      [Feature.Project]: 0,
    },
  };

  async componentDidMount() {
    const company = await this.context.getCompany();

    let { countryCode } = this.state;

    if (company?.default_location?.country) {
      countryCode = company.default_location.country.toLowerCase();
    }

    if (company && this.props.employee?.id) {
      try {
        const [respEmployee, permissions] = await Promise.all([
          getEmployee({ id: this.props.employee.id, newHierarchyPermissions: true }),
          this.getPermissions(),
        ]);
        const employee = respEmployee.user_profile || null;

        let serviceUuids: string[] = [];
        // get services for employee only if he has access to projects plan
        if (employee?.associate_products.includes(Feature.Project)) {
          const settingsResp = await getServiceProviderSettings({
            companyUuid: company?.uuid,
            userProfileUuid: employee.uuid,
            requestedBy: window.global_store.profile.uuid,
          });

          serviceUuids = settingsResp?.content?.services
            ? settingsResp?.content.services.map((service) => service.uuid)
            : [];
        }

        this.setState({
          associateProducts: employee?.associate_products,
          countryCode,
          email: employee.email || null,
          fullName: employee.real_name || "",
          socialName: employee.social_name || null,
          useSocialName: employee.use_social_name,
          cpf: employee.cpf || "",
          pis: employee.pis || "",
          matricula: employee.matricula || "",
          phone: employee.phone_number || "",
          startDate: employee.start_date ? moment(employee.start_date) : null,
          department: employee.department ? employee.department.uuid : "",
          subsidiary: employee.subsidiary ? employee.subsidiary.uuid : "",
          position: employee.position ? employee.position.uuid : "",
          teamUuid: employee.team?.uuid || null,
          locationUuids: employee.locations ? employee.locations.map((l) => l.uuid) : [],
          projectEmployeeCost: `${employee.project_employee_cost || 0}`,
          loading: false,
          serviceUuids,
          role: employee?.role || UserProfileRole.employee,
          permissions,
        });
      } catch (error) {
        Sentry.sendError(error);
      }
    } else {
      this.setState({ loading: false, startDate: moment(), countryCode, associateProducts: [] });
    }
    await this.props.subscriptionContext.init();
  }

  getPermissions = async (forceTeams?: boolean): Promise<EmployeePermission[]> => {
    const { employee } = this.props;
    if (!employee?.id) {
      return [];
    }

    const [userPermissionRoles, companyPermissionRoles, teams, subsidiaries, departments] = await Promise.all([
      getPermissionRoleByUserProfileId(employee.id),
      this.context.getRoles(),
      this.context.getTeams(forceTeams),
      this.context.getSubsidiaries(),
      this.context.getDepartments(),
    ]);

    const profilesUuids = new Set<string>();
    const company = await this.context.getCompany();
    userPermissionRoles.forEach((upr) => upr.user_profiles_uuid.forEach((uuid) => profilesUuids.add(uuid)));
    const { content: employees } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, [...profilesUuids]),
    );
    return userPermissionRoles.map((upr) => {
      const pr = companyPermissionRoles.find((cpr) => cpr.uuid === upr.permission_role_uuid) || ({} as any);

      return {
        id: upr.id,
        role: {
          name: pr.name,
          uuid: upr.permission_role_uuid,
        },
        teams: upr.teams_uuid.map((uuid) => ({
          uuid,
          name: teams.find((tm) => tm.uuid === uuid)?.name || "",
          readOnly: upr.read_only_teams?.includes(uuid),
        })),
        userProfiles: upr.user_profiles_uuid.map((uuid) => ({
          uuid,
          name: employees.find((e) => e.uuid === uuid)?.fullName || "",
        })),
        subsidiaries: (upr.subsidiaries_uuid || []).map((uuid) => ({
          uuid,
          name: subsidiaries.find((s) => s.uuid === uuid)?.name || "",
        })),
        departments: (upr.departments_uuid || []).map((uuid) => ({
          uuid,
          name: departments.find((s) => s.uuid === uuid)?.name || "",
        })),
        includingAllcompany: upr.global,
      };
    });
  };

  onFullNameChange = (val: string) => {
    this.setState({ fullName: val });
  };

  onCpfChange = (val: string) => {
    let value = val || "";
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);

    if (employeeTaxId === EmployeeTaxPayerTypes.cpf && value.length > 11) {
      value = value.slice(0, 11);
    }

    this.setState({ cpf: value });
  };

  onEmailChange = (val: string) => {
    this.setState({ email: val, errors: { ...this.state.errors, email: null } });
  };

  onEmailBlur = () => {
    const emailTouched = this.props.employee?.email !== this.state.email;

    this.setState({ emailTouched });
  };

  onPisBlur = () => {
    const pisTouched = this.props.employee?.pis !== this.state.pis;

    this.setState({ pisTouched });
  };

  onTaxPayerIdBlur = () => {
    const taxPayerIdTouched = this.props.employee?.cpf !== this.state.cpf;

    this.setState({ taxPayerIdTouched });
  };

  onPisChange = (val: string) => {
    this.setState({ pis: val ? val.trim() : "" });
  };

  onMatriculaChange = (val: string) => {
    this.setState({ matricula: val });
  };

  validateFullName = () => {
    const { fullName } = this.state;
    const { t } = this.props;
    let error = "";
    if (!fullName) {
      error = t("Full name can't be empty");
    } else {
      error = "";
    }
    return error;
  };

  validateCpf = () => {
    const { cpf } = this.state;
    const { t } = this.props;
    let error = "";

    if (cpf && !CPF.isValid(cpf)) {
      error = t("This CPF format is invalid");
    } else {
      error = "";
    }

    return error;
  };

  validatePIS = () => {
    const { pis } = this.state;
    const { t } = this.props;
    let error = "";

    if (pis && !brValidator.pis.validate(pis)) {
      error = t("This PIS format is invalid");
    } else {
      error = "";
    }

    return error;
  };

  validatePhone = () => {
    const { phone } = this.state;
    const { t } = this.props;
    let error = "";

    if (phone && validatePhoneNumber(phone, true)) {
      error = t(validatePhoneNumber(phone, true));
    } else {
      error = "";
    }

    return error;
  };

  validateEmail = () => {
    const { email } = this.state;
    const { t } = this.props;
    let error = "";
    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (email && !validate(email)) {
      error = t("Incorrect email");
    } else {
      error = "";
    }
    return error;
  };

  validate = () => {
    const { email, phone, cpf, pis, associateProducts } = this.state;
    const { t, employee } = this.props;
    let errors: Record<string, string> | null = {};

    if (!email && !cpf && !phone && !pis) {
      errors.email = t("Email can't be empty");
    }

    if (this.validateFullName()) {
      errors.fullName = this.validateFullName();
    }

    if (email) {
      const validationError = this.validateEmail();

      if (validationError) {
        errors.email = validationError;
      } else if (this.state.errors?.email) {
        // on the fly validation error
        errors.email = this.state.errors.email;
      }
    } else if (employee?.email) {
      errors.email = t("Email can't be removed. Only edited.");
    }

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    if (cpf && employeeTaxId === EmployeeTaxPayerTypes.cpf && this.validateCpf()) {
      errors.cpf = this.validateCpf();
    }

    if ((!employee || !employee.id) && phone && this.validatePhone()) {
      errors.phone = this.validatePhone();
    }

    if (pis && this.validatePIS()) {
      errors.pis = this.validatePIS();
    }

    if (!email && !cpf && !phone && !pis) {
      const errorMsg = t("At least one field should be provided");
      errors.email = errorMsg;
      errors.cpf = errorMsg;
      errors.phone = errorMsg;
      errors.pis = errorMsg;
    }

    if (associateProducts?.length === 0 && BillingService.getAllowedItems().length > 0) {
      errors.associateProducts = t("select_subscription_products");
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }
    return errors;
  };

  onNextClick = () => {
    const { t, employee } = this.props;
    const { phone, email, pis, cpf, emailTouched, pisTouched, taxPayerIdTouched } = this.state;

    this.setState({ loading: true }, async () => {
      let errors = this.validate();

      if (!errors && phone && (!employee || !employee.id)) {
        const resp = await isPhoneNumberExist({
          phoneNumber: phone,
        });

        if (resp) {
          errors = {
            phone: t("This phone number already registered."),
          };
        }
      }

      if (!errors) {
        const validationPayload: Record<string, string | string[]> = {};

        if (email && emailTouched) {
          validationPayload.emails = [email];
        }

        if (pis && pisTouched) {
          validationPayload.pis = pis;
        }

        if (cpf && taxPayerIdTouched) {
          validationPayload.tax_payer_id = cpf;
        }

        if (Object.keys(validationPayload).length) {
          const errs: Record<string, string> = {};
          const results = await checkForExistingUserProfilesWithinCompany({ body: validationPayload });
          if (
            email &&
            results?.emails?.length &&
            results.emails.some((e) => e[email] === EmployeeExistsCheckResult.inUse)
          ) {
            errs.email = t("Employee with this email is already registered");
          }

          if (pis && results?.pis && results.pis[pis] === EmployeeExistsCheckResult.inUse) {
            errs.pis = t("Employee with this pis is already registered");
          }

          if (cpf && results?.tax_payer_id && results?.tax_payer_id[cpf] === EmployeeExistsCheckResult.inUse) {
            const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
            const taxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);
            errs.cpf = t("Employee with this {{taxIdLabel}} is already registered", { taxIdLabel });
          }

          if (Object.keys(errs).length) {
            errors = errs;
          }
        }
      }

      if (!errors) {
        void this.submitForm();
      } else {
        this.setState({ errors, loading: false });
      }
    });
  };

  submitForm = async () => {
    const { onSave, onError, t } = this.props;
    const {
      email,
      fullName,
      socialName,
      useSocialName,
      cpf,
      pis,
      matricula,
      role,
      phone,
      startDate,
      serviceUuids,
      sendInvitation,
      projectEmployeeCost,
      teamUuid,
      associateProducts,
    } = this.state;
    this.setState({ loading: true, errors: null });

    const positionUuid = this.state.position || null;
    const departmentUuid = this.state.department || null;
    const subsidiaryUuid = this.state.subsidiary || null;
    const locationUuids = this.state.locationUuids && this.state.locationUuids.length ? this.state.locationUuids : null;

    const employee: Record<string, any> = {
      realName: fullName.trim(),
      socialName,
      useSocialName,
      matricula,
      pis,
      cpf,
      email: email || null,
      role,
      positionUuid,
      departmentUuid,
      subsidiaryUuid,
      locationUuids,
      teamUuid,
      associateProducts,
    };

    employee.role = role;

    if (this.props?.employee?.uuid) {
      employee.uuid = this.props.employee.uuid;
    }
    if (startDate) {
      employee.startDate = startDate.format("YYYY-MM-DD");
    }
    if (!this.props.employee || !this.props.employee.id) {
      employee.doNotSendInvitation = !sendInvitation;

      if (phone) {
        employee.phoneNumber = `+${phone}`;
      }
    }

    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      employee.projectEmployeeCost = projectEmployeeCost ? parseFloat(projectEmployeeCost) : 0;
    }

    try {
      const notification =
        this.props.employee && this.props.employee.id ? "The profile was updated" : "The profile was added";

      const res = await addEmployee(employee);

      if (
        BillingService.checkFeatureAccess(Feature.Project) &&
        (this.props?.employee?.uuid || res?.user_profile?.uuid)
      ) {
        await updateServiceProviderSettings({
          companyUuid: window.global_store.company.uuid,
          userProfileUuid: this.props?.employee?.uuid || res?.user_profile?.uuid,
          requestedBy: window.global_store.profile.uuid,
          body: {
            content: {
              enabled: associateProducts?.includes(Feature.Project),
              serviceUuids: associateProducts?.includes(Feature.Project) ? serviceUuids : [],
              updatedBy: window.global_store.profile.uuid,
            },
          },
        });
      }

      const permissions = await this.getPermissions(true);

      if (!this.props.employee) {
        ga.trackAddEmployees(1);
      }

      this.setState({ loading: false, permissions });

      onSave(notification);
    } catch (error) {
      Sentry.sendError(error);

      this.setState({ loading: false });

      if (onError) {
        let err = t(`${TranslationNamespaces.common}|Something went wrong`);
        if (error?.message) {
          err = t(error.message);
        } else if (error) {
          err = t(error as string);
        }

        onError(err);
      }
    } finally {
      const elem = document.querySelector(".full-page .page-content");

      if (this.props.employee && this.props.employee.id && elem) {
        elem.scroll?.({ top: 0, behavior: "smooth" });
      }
    }
  };

  onAddPermission = async (permission: EmployeePermission) => {
    const { employee, onError } = this.props;
    let ps = this.state.permissions;

    this.setState({ loading: true }, async () => {
      try {
        const {
          permission_roles_user_profiles: { id },
        } = await updatePermissionRoleByUserProfileId({
          userProfileId: (employee as UserProfile).id,
          permission_role: {
            id: permission.id,
            permission_role_uuid: permission.role.uuid,
            user_profiles_uuid: permission.includingAllcompany ? [] : permission.userProfiles.map((up) => up.uuid),
            teams_uuid: permission.includingAllcompany ? [] : permission.teams.map((tm) => tm.uuid),
            departments_uuid: permission.includingAllcompany ? [] : permission.departments.map((d) => d.uuid),
            subsidiaries_uuid: permission.includingAllcompany ? [] : permission.subsidiaries.map((s) => s.uuid),
            global: permission.includingAllcompany,
          },
        });

        // clear UI if needed
        if (permission.includingAllcompany) {
          permission.userProfiles = [];
          permission.teams = [];
        }

        // update permission
        if (permission.id) {
          ps = ps.map((p) => (p.id === permission.id ? permission : p));
        } else {
          // add new permission
          permission.id = id;

          ps = [...ps, { ...permission }];
        }

        this.setState({ permissions: ps, loading: false });
      } catch (error) {
        Sentry.sendError(error);

        this.setState({ loading: false });

        if (onError) {
          onError((error as Error)?.message);
        }
      }
    });
  };

  onDeletePermission = async (permissionRoleUuid: string) => {
    const { employee, onError } = this.props;

    this.setState({ loading: true }, async () => {
      try {
        await deletePermissionRoleByUserProfileId({
          userProfileId: (employee as UserProfile).id,
          permissionRoleUuid,
        });

        this.setState({
          permissions: this.state.permissions.filter((p) => p.role.uuid !== permissionRoleUuid),
          loading: false,
        });
      } catch (error) {
        Sentry.sendError(error);

        this.setState({ loading: false });

        if (onError) {
          onError((error as Error)?.message);
        }
      }
    });
  };

  onRoleChange = (role: UserProfileRole) => {
    this.setState({ role });

    // if edit mode
    if (this.props.employee?.uuid) {
      this.onNextClick();
    }
  };

  isOwner = (employee: UserProfile | Record<string, never>) => employee?.permission_roles?.[0]?.name === "Owner";

  updateSeats = (products: Feature[]) => {
    const { associateProducts, seatsChange } = this.state;
    const check = (feature: Feature) => {
      if (!associateProducts?.includes(feature) && products.includes(feature)) return 1;
      if (associateProducts?.includes(feature) && !products.includes(feature)) return -1;
      return 0;
    };

    return {
      [Feature.TA]: ((seatsChange && seatsChange[Feature.TA]) || 0) + check(Feature.TA),
      [Feature.Project]: ((seatsChange && seatsChange[Feature.Project]) || 0) + check(Feature.Project),
    };
  };

  render() {
    const {
      loading,
      fullName,
      cpf,
      email,
      pis,
      subsidiary,
      position,
      teamUuid,
      department,
      matricula,
      locationUuids,
      phone,
      errors,
      personalDetailsOpen,
      advancedSettingsOpen,
      employeelDetailsOpen,
      costDetailsOpen,
      startDate,
      countryCode,
      serviceUuids,
      sendInvitation,
      projectEmployeeCost,
      associateProducts,
      socialName,
      useSocialName,
      role,
      permissions,
      seatsChange,
    } = this.state;
    const { t, popupMode } = this.props;
    const { profile } = window.global_store;
    const employee: UserProfile | Record<string, never> = this.props.employee || {};
    const employeeTaxId = getEmployeeTaxPayerType(profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const canUpdatePermissionRole =
      hasPermisionAccess(PermissionSectionName.managePermissions) &&
      employee?.uuid !== profile.uuid &&
      !this.isOwner(employee);

    return (
      <Wrapper style={{ maxWidth: !popupMode ? "327px" : "none" }}>
        {associateProducts !== undefined && (
          <AssociateEmployeeToProduct
            error={errors?.associateProducts}
            associateProducts={associateProducts}
            setAssociateProducts={async (products) => {
              const seatsChange = this.updateSeats(products);
              const valid = await this.props.subscriptionContext.checkAvailableSeats(true, seatsChange);
              if (valid) this.setState({ associateProducts: products, seatsChange });
            }}
            isUpgrade={!!this.props.employee?.id}
          />
        )}
        <Block>
          <BlockTitle
            className={personalDetailsOpen || !popupMode ? "opened" : ""}
            $popupMode={!!popupMode}
            onClick={() => {
              if (popupMode) this.setState({ personalDetailsOpen: !personalDetailsOpen });
            }}
          >
            {t("Personal  details")}
          </BlockTitle>
          <BlockContent className={personalDetailsOpen || !popupMode ? "personal-details" : ""}>
            <FieldWrapper fieldName={t("Full name")} width="100%">
              <TextInputControl
                value={fullName}
                size={50}
                type="text"
                placeholder={t("name-placeholder")}
                error={!!errors?.fullName}
                onChange={this.onFullNameChange}
              />
            </FieldWrapper>
            {errors?.fullName && <ErrorLabel>{errors.fullName}</ErrorLabel>}

            <FieldWrapper fieldName={t("Social name")} width="100%">
              <TextInputControl
                value={socialName || ""}
                size={50}
                type="text"
                placeholder={t("social-name-placeholder")}
                error={!!errors?.socialName}
                onChange={(sn) => this.setState({ socialName: sn })}
                disabled={!useSocialName}
              />
            </FieldWrapper>
            <FieldWrapper>
              <CheckboxControl
                checked={useSocialName}
                onChange={(checked) => this.setState({ useSocialName: checked })}
                label={t("Use the Social Name")}
              />
            </FieldWrapper>

            <FieldWrapper fieldName={employeeTaxIdLabel} width="100%" tooltipText={t("cpf-field-info")}>
              <TextInputControl
                value={cpf}
                placeholder={employeeTaxId === EmployeeTaxPayerTypes.cpf ? "XXX.XXX.XXX-XX" : ""}
                error={!!errors?.cpf}
                onBlur={this.onTaxPayerIdBlur}
                onChange={this.onCpfChange}
              />
            </FieldWrapper>
            {errors?.cpf && <ErrorLabel>{errors.cpf}</ErrorLabel>}

            <FieldWrapper fieldName={t("PIS")} width="100%" tooltipText={t("pis-tooltip")}>
              <TextInputControl
                value={pis}
                placeholder="XXX.XXXXX.XX-X"
                error={!!errors?.pis}
                onBlur={this.onPisBlur}
                onChange={this.onPisChange}
              />
            </FieldWrapper>
            {errors?.pis && <ErrorLabel>{errors.pis}</ErrorLabel>}

            <FieldWrapper
              fieldName={t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula")}
              width="100%"
              tooltipText={t("matricula-tooltip")}
            >
              <TextInputControl
                value={matricula}
                placeholder=""
                error={!!errors?.matricula}
                onChange={this.onMatriculaChange}
              />
            </FieldWrapper>
            {errors?.matricula && <ErrorLabel>{errors.matricula}</ErrorLabel>}

            <FieldWrapper fieldName={t("Email")} width="100%">
              <EmailFieldWrapper>
                <TextInputControl
                  value={email || ""}
                  placeholder={t("common|email-placeholder")}
                  type="email"
                  error={!!errors?.email}
                  onChange={this.onEmailChange}
                  onBlur={this.onEmailBlur}
                />
              </EmailFieldWrapper>
            </FieldWrapper>
            {errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}

            {(!employee || !employee.id) && (
              <FieldWrapper fieldName={t("Phone Number")} width="100%">
                <PhoneNumberField
                  countryCode={countryCode || undefined}
                  disabled={!!employee?.id}
                  newField
                  value={phone}
                  isValid={!errors?.phone}
                  onPhoneChange={(val) => this.setState({ phone: !val || val.length < 3 ? "" : val })}
                />
              </FieldWrapper>
            )}
            {(!employee || !employee.id) && errors?.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}

            <FieldWrapper fieldName={t("Start date")} width="100%" fieldTitleMarginBottom={0}>
              <SingleDatePickerControl
                numberOfMonths={1}
                error={!!errors?.startDate}
                value={startDate}
                onChange={(val) => this.setState({ startDate: val })}
                isOutsideRange={(_) => false}
              />
            </FieldWrapper>
          </BlockContent>
        </Block>
        <Block>
          <BlockTitle
            className={employeelDetailsOpen || !popupMode ? "opened" : ""}
            $popupMode={!!popupMode}
            $withTopMargin
            onClick={() => {
              if (popupMode) this.setState({ employeelDetailsOpen: !employeelDetailsOpen });
            }}
          >
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.employeesPage,
              "custom-employee-details",
              "Employee details",
            )}
          </BlockTitle>
          <BlockContent className={employeelDetailsOpen || !popupMode ? "employee-details" : ""}>
            <FieldWrapper fieldName={t("Position")} width="100%">
              <PositionsDropdownControl
                uuid
                value={position}
                onChange={(val) => this.setState({ position: val as string })}
              />
            </FieldWrapper>

            <FieldWrapper fieldName={t("Team")} width="100%">
              <TeamsDropdownControl<string>
                uuid
                value={teamUuid}
                onChange={(val: string) => this.setState({ teamUuid: val })}
              />
            </FieldWrapper>

            <FieldWrapper fieldName={t("Department")} width="100%">
              <DepartmentsDropdownControl
                uuid
                value={department}
                onChange={(val) => this.setState({ department: val })}
              />
            </FieldWrapper>
            <FieldWrapper fieldName={t("Subsidiary")} width="100%">
              <SubsidiariesDropdownControl
                uuid
                value={subsidiary}
                onChange={(val) => this.setState({ subsidiary: val })}
              />
            </FieldWrapper>
            <EmployeePermssions
              employeeEditMode={!!employee?.uuid}
              role={role}
              canUpdatePermissionRole={
                employee.employee_status !== UserProfileEmployeeStatus.deactivated && canUpdatePermissionRole
              }
              permissions={permissions}
              onAddPermission={this.onAddPermission}
              onDeletePermission={this.onDeletePermission}
              onRoleChange={this.onRoleChange}
            />
          </BlockContent>
        </Block>
        {hasPermisionAccess(PermissionSectionName.projectEmployeeCost) && (
          <Block>
            <BlockTitle
              className={costDetailsOpen || !popupMode ? "opened" : ""}
              $popupMode={!!popupMode}
              $withTopMargin
              onClick={() => {
                if (popupMode) this.setState({ costDetailsOpen: !costDetailsOpen });
              }}
            >
              {t("Cost")}
            </BlockTitle>
            <BlockContent className={costDetailsOpen || !popupMode ? "cost-details" : ""}>
              <RatesWrapper
                $currencySign={
                  window?.global_store?.company?.currency
                    ? getSymbolFromCurrency(window.global_store.company.currency) || ""
                    : "$"
                }
              >
                <FieldWrapper fieldName={t("Cost per Hour")} width="100%">
                  <TextInputControlNew
                    placeholder="100"
                    value={projectEmployeeCost}
                    error={!!errors?.projectEmployeeCost}
                    onBlur={() =>
                      this.setState({
                        projectEmployeeCost: `${
                          projectEmployeeCost ? parseFloat(projectEmployeeCost).toFixed(2) : "0"
                        }`,
                      })
                    }
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                      // allow only numbers, ",", "."
                      const val = ev.target.value.replace(/[^0-9^,^.]*/g, "");
                      this.setState({ projectEmployeeCost: val || "" });
                    }}
                  />
                </FieldWrapper>
              </RatesWrapper>
            </BlockContent>
          </Block>
        )}

        <Block>
          <BlockTitle
            $withTopMargin
            className={advancedSettingsOpen || !popupMode ? "opened" : ""}
            $popupMode={!!popupMode}
            onClick={() => {
              if (popupMode) this.setState({ advancedSettingsOpen: !advancedSettingsOpen });
            }}
          >
            {t(`${TranslationNamespaces.punchesPage}|Advanced Settings`)}
          </BlockTitle>
          <BlockContent className={advancedSettingsOpen || !popupMode ? "advanced-settings" : ""}>
            <FieldWrapper fieldName={t("Select Locations")} width="100%">
              <LocationsDropdown<string[] | null>
                uuid
                isMulti
                onlyActive
                value={locationUuids}
                locationByPermission
                onChange={(val: string[]) => {
                  this.setState({ locationUuids: val });
                }}
              />
            </FieldWrapper>
            {errors?.locationUuids && <ErrorLabel>{errors.locationUuids}</ErrorLabel>}
          </BlockContent>
        </Block>
        {associateProducts?.includes(Feature.Project) ? (
          <Block>
            <BlockTitle $withTopMargin className="opened">
              {t("Projects")}
            </BlockTitle>
            <div>
              <FieldWrapper fieldName={t("Services")} width="100%">
                <ServicesDropdownControl
                  value={serviceUuids}
                  onChange={(val) => {
                    this.setState({ serviceUuids: val || [] });
                  }}
                />
              </FieldWrapper>
              {errors?.serviceUuids && <ErrorLabel>{errors.serviceUuids}</ErrorLabel>}
            </div>
          </Block>
        ) : null}

        {!employee.id && (
          <CheckboxWrapper>
            <CheckboxControl
              checked={sendInvitation}
              label={translateEmployeeTerm(
                t,
                TranslationNamespaces.employeesPage,
                "custom-notify-about-invite",
                "Notify employee about invite",
              )}
              onChange={(checked) => this.setState({ sendInvitation: checked })}
            />
          </CheckboxWrapper>
        )}

        {hasEmployeesAccess() &&
          (employee.role !== UserProfileRole.employer || employee.id === window.global_store.profile.id) && (
            <ButtonsWrapper>
              <Button
                value={
                  employee.id
                    ? t(`${TranslationNamespaces.common}|Save`)
                    : translateEmployeeTerm(
                        t,
                        TranslationNamespaces.employeesPage,
                        "custom-create-employee-button",
                        "Create Employee",
                      )
                }
                disabled={loading}
                loading={loading}
                onClick={async () => {
                  const valid = await this.props.subscriptionContext.checkAvailableSeats(true, seatsChange);
                  if (valid) this.onNextClick();
                  return null;
                }}
              />
            </ButtonsWrapper>
          )}
      </Wrapper>
    );
  }
}

const EmployeeDetailsNewWrapper = (props: EmployeeDetailsProps) => {
  const subscriptionContext = useContext(SubscriptionContext);
  const { t } = useTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.punchesPage]);
  return <EmployeeDetailsNew {...props} subscriptionContext={subscriptionContext} t={t} />;
};

export default EmployeeDetailsNewWrapper;
