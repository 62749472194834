import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  --checkmark-hover: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M12.473 4.8067C12.411 4.74421 12.3373 4.69461 12.256 4.66077C12.1748 4.62692 12.0876 4.6095 11.9996 4.6095C11.9116 4.6095 11.8245 4.62692 11.7433 4.66077C11.662 4.69461 11.5883 4.74421 11.5263 4.8067L6.55964 9.78003L4.47297 7.6867C4.40863 7.62454 4.33267 7.57566 4.24943 7.54286C4.16619 7.51006 4.07731 7.49397 3.98786 7.49552C3.8984 7.49706 3.81013 7.51622 3.72808 7.55188C3.64602 7.58754 3.5718 7.63902 3.50964 7.70336C3.44748 7.76771 3.39861 7.84367 3.3658 7.92691C3.333 8.01014 3.31691 8.09903 3.31846 8.18848C3.32001 8.27793 3.33916 8.36621 3.37482 8.44826C3.41049 8.53031 3.46196 8.60454 3.52631 8.6667L6.08631 11.2267C6.14828 11.2892 6.22202 11.3388 6.30326 11.3726C6.3845 11.4065 6.47163 11.4239 6.55964 11.4239C6.64765 11.4239 6.73479 11.4065 6.81603 11.3726C6.89726 11.3388 6.971 11.2892 7.03297 11.2267L12.473 5.7867C12.5406 5.72427 12.5946 5.6485 12.6316 5.56417C12.6685 5.47983 12.6876 5.38876 12.6876 5.2967C12.6876 5.20463 12.6685 5.11356 12.6316 5.02923C12.5946 4.94489 12.5406 4.86912 12.473 4.8067Z" fill="%233E4357"/></svg>');
  --checkmark-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white"><path d="M12.473 4.8067C12.411 4.74421 12.3373 4.69461 12.256 4.66077C12.1748 4.62692 12.0876 4.6095 11.9996 4.6095C11.9116 4.6095 11.8245 4.62692 11.7433 4.66077C11.662 4.69461 11.5883 4.74421 11.5263 4.8067L6.55964 9.78003L4.47297 7.6867C4.40863 7.62454 4.33267 7.57566 4.24943 7.54286C4.16619 7.51006 4.07731 7.49397 3.98786 7.49552C3.8984 7.49706 3.81013 7.51622 3.72808 7.55188C3.64602 7.58754 3.5718 7.63902 3.50964 7.70336C3.44748 7.76771 3.39861 7.84367 3.3658 7.92691C3.333 8.01014 3.31691 8.09903 3.31846 8.18848C3.32001 8.27793 3.33916 8.36621 3.37482 8.44826C3.41049 8.53031 3.46196 8.60454 3.52631 8.6667L6.08631 11.2267C6.14828 11.2892 6.22202 11.3388 6.30326 11.3726C6.3845 11.4065 6.47163 11.4239 6.55964 11.4239C6.64765 11.4239 6.73479 11.4065 6.81603 11.3726C6.89726 11.3388 6.971 11.2892 7.03297 11.2267L12.473 5.7867C12.5406 5.72427 12.5946 5.6485 12.6316 5.56417C12.6685 5.47983 12.6876 5.38876 12.6876 5.2967C12.6876 5.20463 12.6685 5.11356 12.6316 5.02923C12.5946 4.94489 12.5406 4.86912 12.473 4.8067Z" fill="white"/></svg>');
  --checkbox-partially-indicator: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"%3E%3Cpath d="M12.667 7.33337H3.33366C2.93366 7.33337 2.66699 7.60004 2.66699 8.00004C2.66699 8.40004 2.93366 8.66671 3.33366 8.66671H12.667C13.067 8.66671 13.3337 8.40004 13.3337 8.00004C13.3337 7.60004 13.067 7.33337 12.667 7.33337Z" fill="white"/%3E%3C/svg%3E');

  display: flex;
  align-items: center;
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      appearance: none;
      -moz-appearance: none;
      border-radius: var(--component-toggles-checkbox-border-radius, 4px);
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      margin-inline-end: 9px;

      cursor: pointer;
      border: none;
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
      }

      &.checked {
        background: var(--component-toggles-checkbox-checked-default-bg-color, #1e97f7);

        &:after {
          content: "";
          width: var(--component-toggles-checkbox-width, 16px);
          height: var(--component-toggles-checkbox-height, 16px);
          background: var(--checkmark-checked);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        &:hover:not(:disabled) {
          background: var(--component-toggles-checkbox-checked-hover-bg-color, #0780e0);
        }
      }

      &.indeterminate {
        background: var(--component-toggles-checkbox-checked-default-bg-color, #1e97f7);

        &:after {
          content: "";
          width: var(--component-toggles-checkbox-width, 16px);
          height: var(--component-toggles-checkbox-height, 16px);
          background: var(--checkbox-partially-indicator);
          color: white;
          font-size: 16px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        &:hover:not(:disabled) {
          background: var(--component-toggles-checkbox-checked-hover-bg-color, #0780e0);
        }
      }

      &:disabled {
        cursor: not-allowed;
        border: none;
        &.checked,
        &.indeterminate {
          background: var(--component-toggles-checkbox-checked-disable-bg-color, #a7adc0);
        }
        &:not(.checked):not(.indeterminate) {
          &:disabled {
            border: var(--component-toggles-border-checkbox, 1px) solid
              var(--component-toggles-checkbox-unchecked-disable-border-color, #e1e3eb);
            background: var(--component-toggles-checkbox-unchecked-disable-bg-color, #fff);
          }
        }
      }

      &:hover:not(:disabled) {
        &:not(.checked):not(.indeterminate) {
          &:not(:disabled) {
            border: var(--component-toggles-border-checkbox, 1px) solid
              var(--component-toggles-checkbox-unchecked-hover-border-color, #757e9c);
            background: var(--component-toggles-checkbox-unchecked-hover-bg-color, rgba(33, 36, 46, 0.08));

            &:after {
              content: "";
              width: var(--component-toggles-checkbox-width, 16px);
              height: var(--component-toggles-checkbox-height, 16px);
              background: var(--checkmark-hover);
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              opacity: 1;
            }
          }
        }
      }

      &:not(.switch) {
        width: var(--component-toggles-checkbox-width, 16px);
        height: var(--component-toggles-checkbox-height, 16px);
        flex-shrink: 0;
        &:after {
          opacity: var(--o, 0);
        }
        &.checked,
        &.indeterminate {
          --o: 1;
        }
      }
    }

    input[type="checkbox"] {
      &:not(.checked):not(.indeterminate) {
        border: var(--component-toggles-border-checkbox, 1px) solid
          var(--component-toggles-checkbox-unchecked-default-border-color, #a7adc0);
        background: var(--component-toggles-checkbox-unchecked-default-bg-color, #fff);
      }
    }
  }
`;

const Checkbox = styled.input``;

const Label = styled.div<{ disabled: boolean }>`
  color: var(--colors-mainText);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  ${(p): string => (p.disabled ? "color: var(--colors-secondary);" : "")}
  cursor: pointer;
`;

interface NewCheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onChange: (checked: boolean, indeterminate?: boolean) => void;
  label?: ReactNode;
  className?: string;
  isWrapperClickable?: boolean;
  labelStyles?: CSSProperties;
}

const NewCheckbox = ({
  disabled,
  checked = false,
  indeterminate = false,
  onChange,
  label,
  className,
  labelStyles,
  isWrapperClickable = false,
}: NewCheckboxProps): JSX.Element => (
  <Wrapper
    className={className}
    onClick={
      isWrapperClickable
        ? () => {
            onChange(!checked, false);
          }
        : undefined
    }
  >
    <Checkbox
      type="checkbox"
      checked={checked}
      disabled={disabled}
      className={`${checked ? "checked " : ""}${indeterminate ? "indeterminate" : ""}`}
      onChange={
        isWrapperClickable
          ? undefined
          : (ev): void => {
              onChange(ev.target.checked, false);
            }
      }
      readOnly={isWrapperClickable}
    />
    {!!label && (
      <Label
        className="checkbox-label"
        style={labelStyles}
        disabled={!!disabled}
        onClick={
          isWrapperClickable
            ? undefined
            : (): void => {
                if (!disabled) {
                  onChange(!checked, false);
                }
              }
        }
      >
        {label}
      </Label>
    )}
  </Wrapper>
);

export default NewCheckbox;
