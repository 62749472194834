import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";
import { HeaderAction } from "components/styled/Page";
import HeaderActionButton from "components/controls/HeaderActionButton";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import Exceptions from "./ScheduleExceptions";
import { importFiles } from "../svg-images";

type ScheduleExceptionPageProps = RouteComponentProps;

const ScheduleExceptionsPage = ({ history }: ScheduleExceptionPageProps) => {
  const { t } = useTranslation(TranslationNamespaces.schedules);
  const flags = useFlags();

  return (
    <FullPage
      title={t("Schedule Exceptions")}
      headerAction={
        <HeaderAction>
          {flags.importShifts && (
            <HeaderActionButton
              title={t("Import Files")}
              onClick={() => history.push("/schedules/import")}
              icon={importFiles}
              state={ButtonState.enableDay}
            />
          )}

          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("Create an Exception")}
            onClick={() => history.push("/schedules/create-exception")}
          />
        </HeaderAction>
      }
    >
      <Exceptions />
    </FullPage>
  );
};

export default ScheduleExceptionsPage;
