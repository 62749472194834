import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { minsToHrsMins } from "utils/common";
import { CalendarButton } from "./TimerButtons";
import TimerField from "./TimerField";
import { getRunningActivityStartDateTime } from "../time-tracker.utils";
import { StoredActivity } from "../TrackTime.store";
import { DurationInput } from "./DurationInput";

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding-inline-end: 8px;
  .Date {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .Duration {
    width: 80px;
    height: 36px;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    color: var(--colors-surface-600);
    border-radius: 100px;

    &.with-hover:hover {
      background: var(--colors-theme-tr-timetracker-duration-bg-hvr);
      color: var(--colors-theme-tr-timetracker-duration-color-hvr);
    }

    .time-control {
      margin: 0;
      background: transparent;
      border-radius: 100px;
      border: 1px solid transparent;
      width: 80px;
      height: 36px;
      text-align: center;
      padding: 0;
      color: var(--colors-theme-tr-timetracker-duration-color);

      &:hover {
        background: var(--colors-theme-tr-timetracker-duration-bg-hvr);
        &::placeholder,
        &:placeholder-shown {
          color: var(--colors-theme-tr-timetracker-duration-ph-hvr);
        }
      }
      &:focus {
        background: var(--colors-theme-tr-timetracker-duration-bg-fcs);
        border-color: var(--colors-theme-tr-timetracker-duration-brdr-fcs);
      }
    }
  }
`;

export const TrackerDateTime = ({
  activity,
  isRunning,
  duration,
  setShowDatePicker,
  changeDuration,
  startDate,
  hideDate,
}: {
  activity: StoredActivity | null;
  isRunning: boolean;
  duration: number;
  setShowDatePicker: (val: boolean) => void;
  changeDuration: (val: any) => void;
  startDate: moment.Moment | null;
  hideDate?: boolean;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const dateValue = startDate || (activity?.date && moment(activity.date)) || undefined;
  const dateLabel = dateValue && !dateValue.isSame(moment(), "day") ? dateValue.format("MM/DD") : t("Today");
  return (
    <TimeWrapper>
      {!hideDate && (
        <div className="Date">
          <CalendarButton onClick={() => setShowDatePicker(true)} value={dateLabel} withValue={!!dateValue} />
        </div>
      )}
      <div className={`Duration ${!isRunning ? "with-hover" : ""}`}>
        {isRunning ? (
          <TimerField dateTime={getRunningActivityStartDateTime(activity)} />
        ) : (
          <DurationInput
            className="DurationSelect time-control"
            placeholder="0:00:00"
            value={duration ? minsToHrsMins(duration, true) : ""}
            onChange={changeDuration}
          />
        )}
      </div>
    </TimeWrapper>
  );
};
