import { useEffect, useState } from "react";
import { useTranslation, TFunction } from "react-i18next";
import styled from "styled-components";
import BEM from "utils/BEM";
import { GMapCircle } from "utils/gmapsHelpers";
import * as images from "components/svg-images";
import { TranslationNamespaces } from "types/translationNamespaces";
import CONFIG from "config";

const b = BEM.b("activity-location");

const LocationStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VerificationIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  path {
    fill: var(--colors-success2);
  }
  g {
    stroke: var(--colors-success2);
    path {
      fill: none;
    }
  }

  &.not_verified {
    path {
      fill: var(--colors-error2);
    }
    g {
      stroke: var(--colors-error2);
      path {
        fill: none;
      }
    }
  }

  .hint {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    position: absolute;
    bottom: 30px;
    right: 0;
    width: max-content;
    background-color: var(--colors-surface-800);
    color: var(--colors-surface-0);
    padding: 6px 10px;
    border-radius: var(--shapes-border-radius-default);
  }

  &:hover .hint {
    opacity: 1;
  }
`;

const AddressText = styled.div`
  color: var(--colors-surface-400);
`;

interface Coordinates {
  lat: number;
  lng: number;
}

interface Location {
  code: string;
  id: string;
  name: string;
  lat: number;
  lng: number;
  radius: number;
}

export enum LocationV10nStatus {
  verified = "verified",
  // eslint-disable-next-line camelcase
  not_verified = "not_verified",
  skipped = "skipped",
  none = "none",
}

export enum LocationV10nMethod {
  location = "location",
  // eslint-disable-next-line camelcase
  wifi_point = "wifi_point",
  beacon = "beacon",
  none = "none",
}

interface LocationVerification {
  status: LocationV10nStatus;
  method: LocationV10nMethod;
}

export interface Activity {
  coordinates: Coordinates;
  location: Location;
  locationVerification: LocationVerification;
}

interface ActivityCoordinatesMapProps {
  reportedLocation: Location;
  realCoordinates: Coordinates;
}

function ActivityCoordinatesMap({ reportedLocation, realCoordinates }: ActivityCoordinatesMapProps) {
  const [address, setAddress] = useState<string | null>(null);
  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();
    const location = { lat: realCoordinates.lat, lng: realCoordinates.lng };
    void geocoder.geocode({ location }, (res) => {
      if (!res?.length) return;
      setAddress(res[0].formatted_address);
    });
  }, [realCoordinates]);

  return (
    <div className={b("field")} style={{ marginTop: "12px" }}>
      <img
        src={`${GMapCircle(
          realCoordinates.lat,
          realCoordinates.lng,
          reportedLocation.radius,
          reportedLocation.lat,
          reportedLocation.lng,
          [350, 116],
        )}&key=${CONFIG.googleMapApiActivity}`}
        alt=""
      />
      {address && (
        <div className={b("address")}>
          <AddressText className={b("address-text")}>{address}</AddressText>
        </div>
      )}
    </div>
  );
}

interface LocationVerificationStatusProps {
  t: TFunction;
  locationVerification: LocationVerification;
}
function LocationVerificationStatus({ t, locationVerification }: LocationVerificationStatusProps) {
  if (locationVerification.status === "skipped") {
    return <VerificationIcon className="verification-icon">{images.verification.computer}</VerificationIcon>;
  }

  const shouldShowIcon =
    locationVerification.status &&
    locationVerification.status !== "none" &&
    locationVerification.method &&
    locationVerification.method !== "none";
  if (!shouldShowIcon) {
    return null;
  }

  return (
    <VerificationIcon className={["verification-icon", locationVerification.status].join(" ")}>
      {images.verification[locationVerification.method as keyof typeof images.verification]}
      {locationVerification.status === "not_verified" && <div className="hint">{t("Location not verified")}</div>}
    </VerificationIcon>
  );
}

export interface ActivityLocationProps {
  location: Location;
  locationVerification: LocationVerification;
  coordinates: Coordinates;
}
export default function ActivityLocation(props: ActivityLocationProps) {
  const [t] = useTranslation(TranslationNamespaces.employeesPage);
  const { location, locationVerification, coordinates } = props;
  const doShowMap =
    locationVerification.status === "not_verified" && coordinates?.lat != null && coordinates?.lng != null;
  const locationText = `${location.code || location.id} - ${location.name}`;

  return (
    <>
      <LocationStatus className={b("location")}>
        <div className={b("field-value")}>{locationText}</div>
        <LocationVerificationStatus t={t} locationVerification={locationVerification} />
      </LocationStatus>
      {doShowMap && <ActivityCoordinatesMap reportedLocation={location} realCoordinates={coordinates} />}
    </>
  );
}
