import deepEqual from "fast-deep-equal";
import moment from "moment";
import { KvStorage } from "services/kv-storage";

export class Cache {
  constructor(protected prefix: string, protected kvStorage: KvStorage) {}

  /**
   * @param vary - JSON serializable value stored along with value to check cache validity
   */
  async store<T = any, Vary = any>(key: string, value: T, vary: Vary) {
    const item = { value, vary, timestamp: Date.now() };
    const itemStr = JSON.stringify(item);
    await this.kvStorage.setItem(`${this.prefix}${key}`, itemStr);
  }

  /**
   * @param vary - consider cached value to be invalid if new `vary` doesn't `deepEqual` the cached one.
   * @param ttl - ms or [ISO 8601 duration](https://momentjs.com/docs/#:~:text=parsing%20ISO%208601%20durations) (e.g. 'P1Y2M3DT4H5M6S').
   * @param softTtl - ms or [ISO 8601 duration](https://momentjs.com/docs/#:~:text=parsing%20ISO%208601%20durations) (e.g. 'P1Y2M3DT4H5M6S').
   * After softTtl expires value is still returned but is considered to be not fresh.
   * @returns [value, isFresh] or [] if not found or invalid.
   */
  async load<T = any, Vary = any>(
    key: string,
    vary: Vary,
    ttl: number | string,
    softTtl: number | string = 0,
  ): Promise<[value: T, isFresh: boolean] | []> {
    const itemStr = await this.kvStorage.getItem(`${this.prefix}${key}`);
    if (itemStr == null) return [];
    const { value, vary: oldVary, timestamp } = JSON.parse(itemStr);
    const now = Date.now();
    const expiresAt = moment(timestamp).add(ttl).valueOf();
    const isValid = expiresAt > now && deepEqual(vary, oldVary);
    if (!isValid) return [];
    const stalesAt = moment(timestamp).add(softTtl).valueOf();
    const isFresh = stalesAt > now;
    return [value, isFresh];
  }
}
