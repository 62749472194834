export interface Abstract<T> extends Function {
  prototype: T;
}
export interface Type<T = any> extends Function {
  new (...args: any[]): T;
}
// eslint-disable-next-line @typescript-eslint/ban-types
type InjectionToken = string | symbol | Type<any> | Abstract<any> | Function;

interface ClassProvider<T = any> {
  provide: InjectionToken;
  useClass: Type<T>;
}
interface ValueProvider<T = any> {
  provide: InjectionToken;
  useValue: T;
}
interface FactoryProvider<T = any> {
  provide: InjectionToken;
  useFactory: (...args: any[]) => T;
  inject?: Array<InjectionToken>;
}

export type Provider<T = any> = Type<any> | ClassProvider<T> | ValueProvider<T> | FactoryProvider<T>;

export function Injectable() {
  const decorate = (target: any) => target;
  return decorate;
}
