import { ChangeEventHandler } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Input = styled.input`
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--colors-surface-400);
  &:checked {
    background-color: var(--colors-surface-0);
    border: 4px solid var(--colors-primary-500-p);
  }
  &:disabled {
    cursor: default;
  }
`;
const Label = styled.label<{ $disabled: boolean }>`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-900-p);
  ${(p) => (p.$disabled ? "cursor: default;" : "cursor: pointer;")}
`;

type RadioButtonProps = {
  id: string;
  name: string;
  checked: boolean;
  disabled: boolean;
  value: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const RadioButton = ({ id, name, checked, value, label, onChange, disabled }: RadioButtonProps) => (
  <Wrapper>
    <Input type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} />
    <Label htmlFor={id} $disabled={disabled}>
      {label}
    </Label>
  </Wrapper>
);

export default RadioButton;
