import { PropsWithChildren } from "react";
import styled from "styled-components";

const MoreInfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 11px 11px 10px 26px;
  border: 1px solid #dde5ee;
  border-radius: var(--shapes-border-radius-default);
`;

const MoreInfoText = styled.div`
  position: relative;
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-mainText);

  &:before {
    position: absolute;
    inset-inline-start: -20px;
    width: 14px;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 14A7 7 0 107 0a7 7 0 000 14z' fill='%23DDE5EE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 7.643C8 7.84 7.776 8 7.5 8h-1c-.276 0-.5-.16-.5-.357V3.357C6 3.16 6.224 3 6.5 3h1c.276 0 .5.16.5.357v4.286zM8 10.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
`;

type RequestVacationDaysInfoRowProps = PropsWithChildren;

const RequestVacationDaysInfoRow = ({ children }: RequestVacationDaysInfoRowProps) => (
  <MoreInfoBlock>
    <MoreInfoText>{children}</MoreInfoText>
  </MoreInfoBlock>
);

export default RequestVacationDaysInfoRow;
