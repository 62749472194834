import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Assignee } from ".";
import AvatarWithDetails from "../AvatarWithDetails";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-start: -35px;
  margin-inline-end: -35px;
  max-height: 312px;
  overflow: auto;
`;

const AssigneeRow = styled.div`
  padding: 7px 35px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: var(--colors-surface-50);
    .row-action {
      display: flex;
    }
  }
`;

const RowAction = styled.div`
  display: none;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  inset-inline-end: 0;
  margin-inline-end: 35px;
`;

const RemoveButton = styled.button`
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  padding: 6px 8px;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-900-p);
  cursor: pointer;
`;

const AssigneesList = ({ assignees, onRemove }: { assignees: Assignee[]; onRemove: (uuid: string) => void }) => {
  const { t } = useTranslation(TranslationNamespaces.projects);
  return (
    <Wrapper>
      {assignees.map((item) => (
        <AssigneeRow key={item.uuid}>
          <AvatarWithDetails {...item} />
          <RowAction className="row-action">
            <RemoveButton onClick={() => onRemove(item.uuid)}>
              {t(`${TranslationNamespaces.common}|Remove`)}
            </RemoveButton>
          </RowAction>
        </AssigneeRow>
      ))}
    </Wrapper>
  );
};

export default AssigneesList;
