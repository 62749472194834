import { Component } from "react";
import { TFunction } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import moment from "moment";
import styled from "styled-components";
import * as images from "components/svg-images";
import { forceRecalculateRange } from "utils/apiHelpers";

const CellWrapper = styled.div<{ isUpdating?: boolean; active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: none;
  color: ${(p) => (p.active ? "var(--colors-surface-800)" : "var(--colors-surface-400)")};

  ${(p) =>
    p.isUpdating
      ? `
    &::before{
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: var(--colors-activitiCellScheduleHover:);
      content: "";
    }`
      : ""}
`;

const Loader = styled.div`
  width: 24px;
  height: 24px;
  animation: loaderRoation 2s infinite linear;
`;

const TooltipWrapper = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -12px;
  left: calc(50% - 40px);
  z-index: 2;
  padding: 4px 15px;
  font-weight: var(--typography-font-weight-medium);
  font-size: 12px;
  color: var(--colors-default);
  background: ${(props) => props.theme.colors.text1};
  border-radius: 3px;
`;

const ForceRecalculate = styled.div<{ disabled?: boolean }>`
  position: absolute;
  left: calc(100% + 4px);
  z-index: 1;
  padding: 4px;
  border-radius: var(--shapes-border-radius-default);
  box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
  background: #efe;
  ${(p) => (p.disabled ? "cursor: default;" : "cursor: pointer;")}
`;

interface CellTotalWithProgressProps {
  t: TFunction;
  employeeUUID: string | undefined;
  date: moment.MomentInput;
  total: string;
  isUpdating?: boolean;
  active: boolean;
}

interface CellTotalWithProgressState {
  total: string;
  isUpdating?: boolean;
  recalculate: boolean;
  recalculatingInProgress: boolean;
  showToolip: boolean;
}

class CellTotalWithProgress extends Component<CellTotalWithProgressProps, CellTotalWithProgressState> {
  constructor(props: CellTotalWithProgressProps) {
    super(props);

    this.state = {
      total: props.total,
      isUpdating: props.isUpdating,
      recalculate: false,
      recalculatingInProgress: false,
      showToolip: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
    document.addEventListener(this.getFrozenCellKey(), this.handleFrozenCell);
  }

  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
    document.removeEventListener(this.getFrozenCellKey(), this.handleFrozenCell);
  }

  handleDataLoad = (ev) => {
    // ev type SuperpunchWorkedHoursEventData
    this.setState({
      total: ev.detail.total ? ev.detail.total : this.state.total,
      isUpdating: ev.detail.isUpdating,
    });
  };

  handleFrozenCell = () => {
    if (!this.state.recalculate) {
      this.setState({ recalculate: true });
    }
  };

  getFrozenCellKey = () => {
    const { date } = this.props;

    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}_frozen`;
  };

  getCellKey = () => {
    const { date } = this.props;

    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}_worked-hours`;
  };

  recalculateButtonClick = async () => {
    const { employeeUUID, date } = this.props;
    const { recalculatingInProgress } = this.state;

    if (!recalculatingInProgress && employeeUUID) {
      this.setState({ recalculatingInProgress: true });

      await forceRecalculateRange({
        userProfile: employeeUUID,
        from: moment(date, "YYYY-MM-DD").format("YYYYMMDD"),
        to: moment(date, "YYYY-MM-DD").format("YYYYMMDD"),
        requestedBy: window.global_store.profile.uuid,
        companyUuid: window.global_store.company.uuid,
      });
    }
  };

  render() {
    const { t, active } = this.props;
    const { showToolip, total, isUpdating, recalculate, recalculatingInProgress } = this.state;

    if (isUpdating) {
      return (
        <CellWrapper isUpdating active>
          <TooltipWrapper>
            {recalculate && (
              <ForceRecalculate disabled={recalculatingInProgress} onClick={this.recalculateButtonClick}>
                {recalculatingInProgress ? t("Recalculating...") : t("Recalculate")}
              </ForceRecalculate>
            )}

            <Loader
              onMouseEnter={() => this.setState({ showToolip: true })}
              onMouseLeave={() => this.setState({ showToolip: false })}
            >
              {images.reloadIcon}
            </Loader>

            <TransitionGroup>
              {showToolip && (
                <CSSTransition classNames="tooltip-transition" timeout={{ enter: 175, exit: 175 }}>
                  <Tooltip className="loader-tooltip">{t("Updating")}</Tooltip>
                </CSSTransition>
              )}
            </TransitionGroup>
          </TooltipWrapper>
        </CellWrapper>
      );
    }

    return <CellWrapper active={active}>{total}</CellWrapper>;
  }
}

export default CellTotalWithProgress;
