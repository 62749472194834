import React, { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-confirm-popup-message.scss";
import * as moment from "moment";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import { withTranslation } from "react-i18next";
import Button from "components/controls/StyledButton";
import styled from "styled-components";

const b = BEM.b("schedule-confirm-popup-message");
const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;
const Buttons = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;
class ScheduleConfirmPopupMessage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      startDate: moment(),
      title: t("Schedule Change"),
      description: t("Changes to the journey will apply from day"),
      buttonCancelTitle: t("Cancel"),
      buttonYesTitle: t("Confirm"),
    };
  }

  closePopup() {
    this.props.onClose();
  }

  onCancel = () => {
    this.closePopup();
  };
  onYes = () => {
    this.setState({ isLoading: true });
    this.props.onYes && this.props.onYes(this.state.startDate);
  };

  render() {
    const { title, description, buttonYesTitle, buttonCancelTitle, startDate, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        <div className={b("description")}>{description}</div>
        <div className={b("dates")}>
          <div className={b("label")}>{t("Current Date")}</div>
          <SingleDatePickerControl
            value={startDate}
            onChange={(startDate) => this.setState({ startDate })}
            isOutsideRange={(day) => {
              if (window.global_store.beta) {
                return false;
              }
              if (day.isAfter(moment()) || day.isBefore(moment().clone().subtract(90, "day"))) {
                return true;
              }
              return false;
            }}
          />
        </div>
        <Buttons>
          <Button state="secondary" onClick={this.onCancel} value={buttonCancelTitle} />
          <Spacer />
          <Button
            state="primary"
            loading={isLoading}
            onClick={this.onYes}
            onDoubleClick={this.onYes}
            value={buttonYesTitle}
          />
        </Buttons>
      </div>
    );
  }
}
export default withTranslation("schedules")(ScheduleConfirmPopupMessage);
