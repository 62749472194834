import styled from "styled-components";
import arrowRight from "./img/arrowRight.svg";

export const ListItem = styled.div<{
  $active?: boolean;
  $notExpandable?: boolean;
  $isRTL?: boolean;
}>`
  font-size: var(--typography-font-size-default);
  font-weight: ${(props) => (props.$active ? "500" : "400")};
  color: var(--colors-surface-800);
  line-height: 35px;
  padding-inline-end: 32px;
  padding-inline-start: 12px;
  border-radius: var(--shapes-border-radius-default);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background: ${(props) =>
    !props.$notExpandable && props.$active ? "var(--colors-activitiCellScheduleItemHover)" : "none"};

  &:after {
    content: "";
    background-repeat: no-repeat;
    background-image: url("${arrowRight}");
    width: 11px;
    height: 8px;
    position: absolute;
    inset-inline-end: 16px;
    top: calc(50% - 4px);
    display: ${(props) => (!props.$notExpandable && props.$active ? "block" : "none")};
    ${(props) => (props.$isRTL ? "transform: rotate(180deg);" : "")}
  }
  ${(props) =>
    !props.$notExpandable
      ? `
  cursor: pointer;

  &:hover {
    background: var(--colors-activitiCellScheduleItemHover);
    .remove-button{
      display: block;
    }
    &:after{
      display: block;
    }`
      : ""}
`;
