import { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  inset-inline-end: 40px;
  bottom: 20px;
`;

const Snack = styled.div`
  white-space: nowrap;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-0);
  padding-top: 9px;
  padding-bottom: 9px;
  padding-inline-end: 38px;
  padding-inline-start: 29px;
  border-radius: var(--shapes-border-radius-default);
  position: relative;
  margin-top: 10px;
  &.success-event {
    background-color: var(--colors-success-600-p);
  }
  &.error-event {
    background-color: var(--colors-error);
  }
  &.warning-event {
    background-color: var(--colors-attention);
  }
`;

const Close = styled.button`
  position: absolute;
  top: 0;
  inset-inline-end: 8px;
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;
  width: 20px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.912 1.088a.583.583 0 1 0-.825.824L4.175 5 1.088 8.088a.583.583 0 1 0 .824.824L5 5.825l3.087 3.087a.583.583 0 1 0 .825-.824L5.825 5l3.087-3.088a.583.583 0 0 0-.825-.824L5 4.175 1.912 1.088Z' fill='%23fff'/%3E%3C/svg%3E");
`;

export enum EventStatus {
  success = "success",
  error = "error",
  warning = "warning",
}

export type TimesheetEvent = { uuid: string; message: string; status: EventStatus };

type TimesheetSnackbarProps = Record<string, never>;
interface TimesheetSnackbarState {
  events: TimesheetEvent[];
}

class TimesheetSnackbar extends Component<TimesheetSnackbarProps, TimesheetSnackbarState> {
  constructor(props: TimesheetSnackbarProps) {
    super(props);
    document.addEventListener("timesheet_message", this.handleCustomEvent);
    this.state = {
      events: [],
    };
  }

  handleCustomEvent = (event: CustomEvent<TimesheetEvent>): void => {
    const newEvents = this.state.events;
    newEvents.push(event.detail);

    this.setState({ events: newEvents });
    setTimeout(() => {
      this.setState({ events: this.state.events.filter((e) => e.uuid !== event.detail.uuid) });
    }, 5000);
  };

  render() {
    const { events } = this.state;

    return (
      <Wrapper>
        {events.map((event) => (
          <Snack
            key={event.uuid}
            className={`${event.status}-event`}
            onClick={() => {
              this.setState({ events: this.state.events.filter((e) => e.uuid !== event.uuid) });
            }}
          >
            {event.message}
            <Close />
          </Snack>
        ))}
      </Wrapper>
    );
  }
}

export default TimesheetSnackbar;
