import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as images from "components/svg-images";

const DownloadButton = styled.button`
  padding: 11px 16px;
  font-size: var(--typography-font-size-default);
  color: ${(props) => props.theme.colors.text1};
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${(props) => props.theme.colors.primary2};
  border-radius: var(--shapes-border-radius-default);
  outline: none;
  cursor: pointer;
  svg {
    margin-inline-end: 10px;
  }
`;

export default ({ onClick, label }) => {
  const { t } = useTranslation("common");
  return (
    <DownloadButton onClick={onClick}>
      {images.downloadIcon} {label || t("Download")}
    </DownloadButton>
  );
};
