import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import SettingsLayout from "components/Layout/SettingsLayout";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import styled from "styled-components";
import Select from "components/UI/Select";
import StatusBadge from "components/controls/StatusBadge";
import { getSelectedColumns } from "utils/tableHelpers";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import FieldWrapper from "components/UI/FieldWrapper";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import * as moment from "moment";
import { getTitle, strIncludesCheck } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { listHolidaysGroups, toggleStatusHolidaysGroup } from "./holidayApiUtils";
import CreateGroupPopup from "./CreateGroupPopup";
import GlobalContext from "../../context/global-context";

const title = "Holidays Management";
const metaTitle = title;
const SelectWrapper = styled.div`
  width: 150px;
  margin-inline-start: 20px;
`;

const LightboxText = styled.div`
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #a1b2cf;
`;
class Groups extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      selectedItem: null,
      loading: true,
      notification: "",
      searchValue: "",
      status: "active",
      popupVisible: false,
      deactivationDate: moment(),
      selectedColumns: getSelectedColumns("name,holidaysCount,userProfilesCount,status", "HolidayGroupsList"),
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    this.getState();
  }

  getState = async () => {
    const company = await this.context.getCompany();

    if (company) {
      this.setState({ loading: true });

      const response = await listHolidaysGroups({ companyUuid: company.uuid });

      this.setState({
        loading: false,
        selectedItem: null,
        items: response.content || [],
      });
    }
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_HolidayGroupsList", selectedColumns.join());
    }
    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  onToggleActiveClick = async (item, action, ev) => {
    ev.stopPropagation();

    if (action === "deactivate") {
      this.setState({ deactivatePopupVisible: true, selectedItem: item.uuid });
    } else {
      this.onToggleActive(item.uuid, action);
    }
  };

  onToggleActive = async (uuid, action, date) => {
    let notificationType = "";
    let notification = "";

    const request = {
      companyUuid: window.global_store.company.uuid,
      holidaysGroupUuid: uuid,
      action,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };

    if (action === "deactivate") {
      request.body.content.endDate = date.format("YYYY-MM-DD");
    }

    try {
      await toggleStatusHolidaysGroup(request);

      notificationType = "success";
      notification = `You ${action}d holidays group`;
    } catch (error) {
      if (error && error.status === 500) {
        notificationType = "error";
        notification = "Something went wrong";
      } else {
        notificationType = "error";
        notification = "Something went wrong";
      }
    }
    this.getState();
    this.setState({ deactivatePopupVisible: false, notificationType, notification });
  };

  getTableColumns() {
    const { t, history } = this.props;
    const columns = [
      {
        label: t("Holidays group"),
        accessor: "name",
        Cell: (row) =>
          row.original.isDefault
            ? `${row.value === "defaultHolidaysGroup" ? t(row.value) : row.value} (${t("common|Default")})`
            : `${row.value === "defaultHolidaysGroup" ? t(row.value) : row.value}`,
        minWidth: 160,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Holidays"),
        accessor: "holidaysCount",
        Cell: (row) => row.value || 0,
        width: 160,
      },
      {
        label: t("common|Employees"),
        accessor: "userProfilesCount",
        Cell: (row) => row.value || 0,
        width: 160,
      },
      {
        label: t("common|Status"),
        accessor: "status",
        headerStyle: { textAlign: "center" },
        width: 100,
        align: "center",
        Cell: (row) => (
          <div>
            <StatusBadge value={t(`common|${row.value}`)} type={row.value} />
            {!row.original.isDefault ? (
              <TableButtons className="buttons">
                {row.value === "active" ? (
                  <TableButton
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      history.push(`/company/holidays/${row.original.uuid}/employees`);
                    }}
                  >
                    {t("common|Add employees")}
                  </TableButton>
                ) : null}
                <TableButton
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.setState({
                      selectedItem: row.original.uuid,
                      popupVisible: true,
                    });
                  }}
                >
                  {t("common|Edit")}
                </TableButton>
                {row.value === "active" ? (
                  <TableButton onClick={(ev) => this.onToggleActiveClick(row.original, "deactivate", ev)}>
                    {t("common|Deactivate")}
                  </TableButton>
                ) : null}
                {row.value === "deactivated" ? (
                  <TableButton onClick={(ev) => this.onToggleActiveClick(row.original, "activate", ev)}>
                    {t("common|Activate")}
                  </TableButton>
                ) : null}
              </TableButtons>
            ) : (
              <TableButtons className="buttons">
                <TableButton
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.setState({
                      selectedItem: row.original.uuid,
                      popupVisible: true,
                    });
                  }}
                >
                  {t("common|Edit")}
                </TableButton>
              </TableButtons>
            )}
          </div>
        ),
      },
    ];
    return columns;
  }

  render() {
    const {
      items,
      searchValue,
      loading,
      notification,
      notificationType = "success",
      status,
      popupVisible,
      selectedItem,
      deactivationDate,
      deactivatePopupVisible,
      selectedColumns,
    } = this.state;

    const { t } = this.props;
    const actionButtonTitle = "New Group";
    const noItemsTitle = "No holiday groups are available";

    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.name, searchValue);
      if (status) {
        filter = filter && s.status === status;
      }
      return filter;
    });

    return (
      <SettingsLayout
        title={t(title)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(actionButtonTitle)}
            onClick={() => this.setState({ popupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={t(notification)} />
          )}

          <TablePage
            rows={filteredItems}
            onColumnsChange={this.onColumnsChange}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("common|Search")}
                  value={searchValue}
                />
                <SelectWrapper>
                  <Select
                    value={status}
                    onChange={(st) => this.setState({ status: st })}
                    options={[
                      { value: "", label: t("common|Status") },
                      { value: "active", label: t("common|Active") },
                      { value: "deactivated", label: t("common|Deactivated") },
                    ]}
                  />
                </SelectWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            selectedColumns={selectedColumns ? selectedColumns.split(",").map((i) => i.trim()) : ""}
            columns={this.getTableColumns()}
            className="groups-table"
            getTrProps={(_, rowInfo) => ({
              onClick: (e) => {
                if (rowInfo.row._original.uuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/company/holidays/${rowInfo.row._original.uuid}`,
                    }).click();
                  } else {
                    this.props.history.push(`/company/holidays/${rowInfo.row._original.uuid}`);
                  }
                }
              },
            })}
            loading={loading}
            noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          />
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false, selectedItem: null })}>
            <CreateGroupPopup
              holidaysGroupUuid={selectedItem}
              name={selectedItem ? items.find((i) => i.uuid === selectedItem).name : ""}
              externalId={selectedItem ? items.find((i) => i.uuid === selectedItem).externalId : null}
              onClose={() => {
                this.setState({ popupVisible: false, selectedItem: null });
              }}
              onSave={(uuid) => {
                if (this.state.selectedItem) {
                  this.setState({ popupVisible: false, selectedItem: null });
                  this.getState();
                } else {
                  this.props.history.push(`/company/holidays/${uuid}`);
                }
              }}
            />
          </ModalDialog>
          <ModalDialog isOpen={deactivatePopupVisible} onClose={() => this.setState({ deactivatePopupVisible: false })}>
            <Lightbox
              titleMargin="25px"
              title={t("common|Deactivate group.")}
              buttonYesTitle={t("common|Deactivate")}
              buttonCancelTitle={t("common|Cancel")}
              onClose={() => {
                this.setState({ selectedItem: null, deactivatePopupVisible: false });
              }}
              onYes={() => this.onToggleActive(selectedItem, "deactivate", deactivationDate)}
            >
              <div>
                <LightboxText>
                  {t(
                    "Deactivating a holiday group will remove all employees from it and the events won’t appear on their reports anymore. This can’t be undone.",
                  )}
                </LightboxText>

                <FieldWrapper fieldName={t("Deactivation date")} width="100%" fieldTitleMarginBottom={-4}>
                  <SingleDatePickerControl
                    value={deactivationDate}
                    numberOfMonths={1}
                    onChange={(val) => this.setState({ deactivationDate: val })}
                    isOutsideRange={() => false}
                  />
                </FieldWrapper>
              </div>
            </Lightbox>
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.holidays)(Groups));
