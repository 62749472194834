export enum CompanyRuleNames {
  ALLOW_NIGHT_REDUCED_HOURS = "allow_night_reduced_hours",
  PASSWORD_POLICY = "password_policy",
  MADAN_RASHI_REPORT_AS_DIGITAL_SINGATURE = "madan_rashi_report_as_digital_signature",
  IS_CROSS_SHIFT_INTERVAL_ENABLED = "is_cross_shift_interval_enabled",
  IS_DSR_ENABLED = "is_dsr_enabled",
  DISCOUNT_DSR_FOR_MISSING_MINUTES_LIMIT = "discount_dsr_for_missing_minutes_limit",
  DISCOUNT_DSR_FOR_MISSING_MINUTES_ACTIVE = "discount_dsr_for_missing_minutes_active",
}

type CompanyRule = {
  id: number;
  name: CompanyRuleNames;
  input_type: string;
  data_type: string;
  value: boolean | string;
};

export type { CompanyRule };
