import React from "react";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CloseIcon from "@iconscout/react-unicons/icons/uil-multiply";
import IconButton from "../../IconButton";

const Wrapper = styled.div`
  display: flex;
  height: var(--component-modal-header-height, 64px);
  padding: 0px var(--component-modal-header-wicon-hpad, 16px) 0px var(--component-modal-header-hpad, 24px);
  justify-content: space-between;

  align-items: center;
  gap: var(--component-modal-header-gap, 16px);
  border-bottom: var(--component-modal-border, 1px) solid var(--component-modal-border-color, #e1e3eb);
`;

const Title = styled.div`
  color: var(--component-modal-text-color, #3e4357);
  font-family: var(--typography-font-family-default);
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: 32px;
  letter-spacing: -0.144px;
`;

const Header = ({ title, onClose }: { title: string; onClose: () => void }) => (
  <Wrapper>
    <Title>{title}</Title>
    <IconButton variant="tertiary" size="large" onClick={onClose} icon={<CloseIcon />} />
  </Wrapper>
);

export default Header;
