/* eslint-disable react/prefer-stateless-function */
import { Component, PropsWithChildren } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ControlsWrapper, GenerateButton, ResetButton } from "./reportsStyledComponents";

interface ReportSearchFiltersBaseProps extends WithTranslation, PropsWithChildren {
  generateButtonDisabled?: boolean;
  onGenerate: () => void;
  onReset?: () => void;
  generateBtnText?: string;
}

export const REPORT_LOADING_EVENT = "report_loading";

class ReportSearchFiltersBase extends Component<ReportSearchFiltersBaseProps> {
  render(): JSX.Element {
    const { t, children, onReset, onGenerate, generateButtonDisabled, generateBtnText } = this.props;

    return (
      <ControlsWrapper>
        {children && children}
        <GenerateButton disabled={generateButtonDisabled} onClick={onGenerate}>
          {generateBtnText || t("Generate")}
        </GenerateButton>
        {onReset && <ResetButton onClick={onReset}>{t("Reset")}</ResetButton>}
      </ControlsWrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportSearchFiltersBase);
