import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import pure from "recompose/pure";

/**
 * _____ 0
 *
 *  ---- scrollTop       \
 *                        } clientHeight (1 screen)
 *  ---- scrollBottom    /
 *                      \
 *                       } preloaded
 * _____ scrollHeight   /
 *
 */
function measurePreloadedScreens(el: HTMLElement) {
  const { scrollTop, scrollHeight, clientHeight } = el;
  const scrollBottom = scrollTop + clientHeight;
  const preloadedNumScreens = (scrollHeight - scrollBottom) / clientHeight;
  return preloadedNumScreens;
}

export interface InfiniteScrollProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Expect newly loaded data to be rendered externally (passed as children into this component).
   */
  loadMore?: () => Promise<void>;
  hasMore: boolean;
  /** Call loadMore if less then preloadScreens left for scroll */
  preloadScreens?: number;
  initialPreloadScreens?: number;
  listEnd?: React.ReactNode;
  listEmpty?: React.ReactNode;
  isEmpty?: boolean;
  showListEndIfNoScroll?: boolean;
}

/**
 * Expect overflow-y to be configured externally on this component.
 * Check if more data should be loaded on scroll.
 * Check if more data should be loaded on resize (available space resize).
 * Check if more data should be loaded on children prop update (data update).
 *
 * It is strongly adviced that a parent component (that uses this component) is careful about its renders,
 * otherwise performance degradation is possible.
 */
export const InfiniteScroll = pure((props: InfiniteScrollProps) => {
  const {
    children,
    loadMore,
    hasMore,
    preloadScreens = 1,
    initialPreloadScreens = 1,
    listEnd,
    listEmpty,
    isEmpty,
    showListEndIfNoScroll,
    ...restProps
  } = props;

  const dataEnded = !hasMore;

  const preloadScrns = useRef(initialPreloadScreens);
  const dataLoading = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);
  useEffect(() => {
    dataLoading.current = false;
  }, [loadMore]);
  const checkAndPreload = useCallback(async () => {
    if (dataLoading.current) return;
    if (dataEnded) return;
    if (containerRef.current == null) return;
    const numScreens = measurePreloadedScreens(containerRef.current!);
    if (numScreens >= initialPreloadScreens) {
      preloadScrns.current = preloadScreens;
    }
    const hazScroll = containerRef.current!.scrollHeight > containerRef.current!.clientHeight;
    setHasScroll(hazScroll);
    const shouldPreload = numScreens < preloadScrns.current;
    if (shouldPreload) {
      dataLoading.current = true;
      if (loadMore != null) await loadMore();
      dataLoading.current = false;
    }
  }, [initialPreloadScreens, preloadScreens, loadMore, dataEnded]);

  // check and preload on scroll
  useEffect(() => {
    const contDiv = containerRef.current!;
    contDiv.addEventListener("scroll", checkAndPreload);
    return () => contDiv.removeEventListener("scroll", checkAndPreload);
  }, [checkAndPreload]);

  // check and preload on resize
  useEffect(() => {
    const contDiv = containerRef.current!;
    const ro = new ResizeObserver(checkAndPreload);
    ro.observe(contDiv);
    return () => ro.disconnect();
  }, [checkAndPreload]);

  // check and preload on new children
  useEffect(() => void checkAndPreload(), [children, checkAndPreload, loadMore]);

  const listEndElement = useMemo(() => {
    if (isEmpty) return listEmpty;
    if (hasScroll || showListEndIfNoScroll) return listEnd;
    return null;
  }, [hasScroll, dataEnded, checkAndPreload, showListEndIfNoScroll, isEmpty, listEmpty, listEnd]);

  return (
    <div {...restProps} ref={containerRef}>
      {children}
      {dataEnded && (
        <div onClick={() => containerRef.current?.scrollTo({ top: 0, behavior: "smooth" })}>{listEndElement}</div>
      )}
    </div>
  );
});
