import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Tooltip from "components/UI/Tooltip";
import { useState } from "react";
import classNames from "classnames";
import { minsToHrsMins } from "utils/common";
import { ButtonAdditionalLimits } from "./buttons";
import AdditionalLimitsOverlay from "./AdditionalLimitsOverlay";

const Wrapper = styled.div`
  position: relative;
  overflow: visible;
`;

type AdditionalLimitsControlProps = {
  id: string;
  disabled: boolean;
  onApply: (time: number) => void;
  onDelete: () => void;
  additionalTime?: number;
};

const AdditionalLimitsControl = ({ onDelete, onApply, id, additionalTime, disabled }: AdditionalLimitsControlProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <Wrapper>
      {additionalTime ? (
        <Tooltip id={id}>{t("tooltip-additional-limits-value", { value: minsToHrsMins(additionalTime) })}</Tooltip>
      ) : (
        <Tooltip id={id}>{t("tooltip-additional-limits")}</Tooltip>
      )}
      <ButtonAdditionalLimits
        data-tip
        data-for={id}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setIsDialogOpened(true);
          }
        }}
        className={classNames({ "with-value": additionalTime, active: isDialogOpened })}
      />
      {isDialogOpened && (
        <AdditionalLimitsOverlay
          additionalTime={additionalTime}
          onDelete={() => {
            onDelete();
            setIsDialogOpened(false);
          }}
          onApply={(time) => {
            onApply(time);
            setIsDialogOpened(false);
          }}
          onClose={() => setIsDialogOpened(false)}
        />
      )}
    </Wrapper>
  );
};

export default AdditionalLimitsControl;
