import { Component } from "react";
import ReactTable, { ReactTableDefaults } from "react-table";
// https://github.com/tannerlinsley/react-table
// https://react-table.js.org/
import "react-table/react-table.css";
import BEM from "utils/BEM";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import { LoaderWrapper, Loader, LoaderInner, iCellInfo, iRowInfo } from "utils/tableHelpers";
import "styles/table-common.scss";

// ComponentPropsGetterR, CellInfo used here fro TSDoc
import { Column, GlobalColumn, TableProps, ComponentPropsGetterR, CellInfo } from "react-table-v6";

const tc = BEM.b("table-common");
const Wrapper = styled.div`
  .rt-tbody .rt-td {
    font-family: var(--cp-table-cell-font-family);
  }
  ${rtl`
    .table-common__th,
    .table-common__th_start,
    .table-common__th_left,
    .table-common__td_left,
    .table-common__td_start {
      text-align: start;
    }
    .table-common__th_right, 
    .table-common__th_end, 
    .table-common__td_right,
    .table-common__td_end {
      text-align: end;
    }

    .table-common__name-position{
      margin-inline-start: 20px;
    }
  `}
  .rt-noData {
    z-index: 0;
  }
  .table-common__th_center {
    text-align: center;
    justify-content: center;
  }
  .table-common_interactive {
    .rt-tbody {
      .rt-tr:not(.-padRow):hover {
        background: var(--colors-surface-50);
        cursor: pointer;
        .hide-on-hover {
          visibility: hidden;
        }
        .table-common__options,
        .table-common__buttons,
        .buttons {
          display: flex;
        }
      }
    }
  }
  .with-tr-heading {
    position: relative;
    padding-top: 24px;
    &:before {
      content: "";
      height: 24px;
      width: 100%;
      position: absolute;
      top: 0;
      background-color: var(--colors-surface-50);
    }
  }
  .tr-heading {
    line-height: 24px;
    position: absolute;
    top: 0;
    padding: 0 4px;
    color: var(--colors-surface-400);
    font-size: 12px;
    font-weight: var(--typography-font-weight-medium);
  }
  .ReactTable .rt-tbody,
  .table-common .rt-table {
    overflow: initial;
  }
`;

Object.assign(ReactTableDefaults, {
  defaultPageSize: 10000,
  minRows: 3,
  sortable: false,
  resizable: false,
});

export type ColumnAlign = "right" | "left" | "center" | "start" | "end";

export interface iColumn<R> extends Column, Partial<GlobalColumn> {
  /** value that is passed to backend for downloading reports */
  rubyAccessor?: string;
  /** label that will be displayed in column selector when using groups */
  groupLabel?: string;
  /** column can't be unchecked from column selector menu */
  locked?: boolean;
  /** alignment of the data */
  align?: ColumnAlign;
  /** column label that will be displayed in column selector menu */
  label?: string;
  /**
   * custom cell renderer
   * @remarks
   * overrides type from {@link CellInfo}
   * @TODO add required types for column when needed
   */
  Cell?: ((cellInfo: iCellInfo<R>, column: unknown) => React.ReactNode) | React.ReactNode;
  /** column description for tooltip */
  description?: string;
}

type CustomComponentPropsGetterR<T> = (
  finalState: unknown,
  rowInfo?: iRowInfo<T>,
  column?: undefined,
  instance?: unknown,
  // eslint-disable-next-line @typescript-eslint/ban-types
) => object | undefined;

export interface iTableCommonProps<R, ResolvedData = R> extends Partial<TableProps> {
  /** table rows. will be passed as data to react-table */
  rows: R[];
  /** if table has drabbable columns */
  draggable?: boolean;
  /** highlight rows on hover
   * @dafault true
   */
  interactive?: boolean;
  /** remove scroll */
  noScroll?: boolean;
  /** remove padding */
  noPadding?: boolean;
  /** columns of the table */
  columns: Array<iColumn<ResolvedData>>;
  /**
   * @remarks
   * overrides type from {@link ComponentPropsGetterR}
   * @TODO add required types for state when needed
   */
  getTrProps?: CustomComponentPropsGetterR<R>;
  /** Add tooltip to header. Use column.description || colum.label as a text for tooltip  */
  withHeaderTooltip?: boolean;
  /** superpunch table */
  superpunchPage?: boolean;
}

class TableCommon<R> extends Component<iTableCommonProps<R>> {
  dragged: number | null = null;
  reorder: { a: number; b: number | null }[] = [];
  static defaultProps = {
    rows: [],
    columns: [],
    draggable: true,
  };

  componentDidMount(): void {
    this.mountEvents();
  }

  componentDidUpdate(): void {
    this.mountEvents();
  }

  mountEvents(): void {
    const headers: HTMLElement[] = Array.prototype.slice.call(document.querySelectorAll(".table-common__th_draggable"));

    headers.forEach((header, i) => {
      // TODO why boolean?
      header.setAttribute("draggable", true as unknown as string);
      // the dragged header
      header.ondragstart = (e) => {
        e.stopPropagation();
        this.dragged = i;
      };

      header.ondrag = (e) => e.stopPropagation;

      header.ondragend = (e) => {
        e.stopPropagation();
        setTimeout(() => {
          this.dragged = null;
        }, 1000);
      };

      // the dropped header
      header.ondragover = (e) => {
        e.preventDefault();
      };
      header.ondragenter = (e) => {
        // this / e.target is the current hover target.
        e.stopPropagation();
        (e.target as HTMLElement).classList.add("over");
      };

      header.ondragleave = (e) => {
        (e.target as HTMLElement).classList.remove("over");
      };
      header.ondrop = (e) => {
        console.log((e.target as HTMLElement).classList, "ondrop");
        (e.target as HTMLElement).classList.remove("over");
        e.preventDefault();
        this.reorder.push({ a: i, b: this.dragged });
        this.setState({ trigger: Math.random() });
      };
    });
  }

  getColumns = (): iColumn<R>[] => {
    const { columns = [], draggable, loading } = this.props;

    return columns
      .filter((col) => col)
      .map((col) => ({
        ...col,
        Cell: loading
          ? () => (
              <LoaderWrapper>
                <LoaderInner>
                  {col.accessor === "employee" && <Loader className="loading" avatar />}
                  <Loader className="loading" />
                </LoaderInner>
              </LoaderWrapper>
            )
          : col.Cell,
        headerClassName: `${tc("th", [col.align, draggable && "draggable"])}${
          col?.headerClassName ? ` ${col.headerClassName}` : ""
        }`,
        className: tc("td", [col.align]),
      }));
  };

  render(): JSX.Element {
    const { rows, loading, interactive, noPadding, noScroll, className, superpunchPage } = this.props;
    const data = loading ? [...Array(5)].map(() => ({})) : rows;
    const cols = this.getColumns();
    // run all reorder events
    this.reorder.forEach((o) => cols.splice(o.a, 0, cols.splice(o.b || 0, 1)[0]));

    return (
      <Wrapper>
        <ReactTable
          showPagination={false}
          minRows={0}
          getTheadProps={() => ({
            className: tc("header"),
          })}
          {...this.props}
          className={`${tc({
            interactive: !loading && interactive,
            "no-padding": noPadding,
            "no-scroll": noScroll,
            "sp-new-navigation": superpunchPage,
          })} ${className || ""}`}
          loading={false}
          data={data}
          columns={cols}
        />
      </Wrapper>
    );
  }
}

export default TableCommon;
