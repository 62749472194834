import { ActivityReportType, OnError, ReportType, SetIsLoading } from "types/reports";
import { Component } from "react";
import Select from "components/UI/Select";
import { SelectOption } from "types/ui";
import moment from "moment";
import { hasEmployeesAccess } from "utils/common";
import { withTranslation, WithTranslation } from "react-i18next";
import FullPage from "components/Layout/FullPage";
import NoContent from "components/NoContent";
import NotificationRow from "components/NotificationRow";
import styled from "styled-components";
import LocationsDropdown from "components/LocationsDropdown";
import SearchControl from "components/UI/SearchControlNew";
import { NotificationType, SearchObject } from "types/common";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import ProjectsDropdownControl from "components/Projects/ProjectsDropdownControl";
import ClientsDropdown from "components/ClientsDropdown";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga from "utils/ga";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { getReportsPageTitle } from "./ReportsTitle";
import { Page, SearchControlWrapper } from "./reportsStyledComponents";
import ReportSearchFiltersBase from "./ReportSearchFiltersBase";
import ReportsActivitiesByLocationAllEmployees from "./ReportsActivitiesByLocationAllEmployees";
import ReportsActivitiesByClientAllEmployees from "./ReportsActivitiesByClientAllEmployees";
import ReportsActivitiesAllProjects from "./ReportsActivitiesAllProjects";
import ReportsActivitiesAllClients from "./ReportsActivitiesAllClients";
import ReportsActivitiesSingleProject from "./ReportsActivitiesSingleProject";
import ReportsActivitiesSingleEmployee from "./ReportsActivitiesSingleEmployee";
import ReportsActivitiesTaskFeature from "./ReportsActivitiesTaskFeature";
import { ReportActvitiesStatus } from "./helpers";

const ContentSpacer = styled.div`
  height: 40px;
`;

const LocationsSelectWrapper = styled.div`
  display: flex;
  .multi-select {
    min-width: 200px;
  }
  .react-select__option {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .react-select__placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

enum TaskFeatureReportSubtypes {
  task = "task",
  feature = "feature",
}

interface ReportsPageActivitiesState {
  isGenerateButtonLocked: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  isReportVisible: boolean;
  key: number;
  notification: string | null;
  notificationType: NotificationType;
  selectedLocation: SelectOption | null;
  selectedClientOption: SelectOption | null;
  projectUuid: string | null;
  searchObj: SearchObject | null;
  showInactiveEmployees: boolean;
  showServicesSummary: boolean;
  selectedActivityStatus: ReportActvitiesStatus;
  selectedTaskFeatureSubtype: TaskFeatureReportSubtypes;
}

interface ReportsPageActivitiesProps extends WithTranslation {
  type: ActivityReportType;
}

class ReportsPageActivities extends Component<ReportsPageActivitiesProps, ReportsPageActivitiesState> {
  ACTIVITY_STATUS_TYPES: SelectOption<ReportActvitiesStatus>[];
  TASK_FEATURE_REPORT_SUBTYPES: SelectOption<TaskFeatureReportSubtypes>[];

  constructor(props: ReportsPageActivitiesProps) {
    super(props);
    const { t } = props;

    this.ACTIVITY_STATUS_TYPES = [
      { value: ReportActvitiesStatus.all, label: t(`${TranslationNamespaces.common}|All`) },
      { value: ReportActvitiesStatus.approved, label: t("Approved") },
    ];
    this.TASK_FEATURE_REPORT_SUBTYPES = [
      { value: TaskFeatureReportSubtypes.task, label: t("Task") },
      { value: TaskFeatureReportSubtypes.feature, label: t("Feature") },
    ];

    this.state = {
      projectUuid: null,
      selectedLocation: null,
      selectedClientOption: null,
      selectedActivityStatus: this.ACTIVITY_STATUS_TYPES[0].value,
      selectedTaskFeatureSubtype: TaskFeatureReportSubtypes.task,
      isGenerateButtonLocked: this.isDownloadBtnLocked(),
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      isReportVisible: false,
      key: new Date().getTime(),
      notification: null,
      notificationType: NotificationType.error,
      searchObj: hasEmployeesAccess()
        ? null
        : {
            employee: {
              avatar_id: window.global_store.profile.avatar_id,
              full_name: window.global_store.profile.full_name,
              id: window.global_store.profile.id,
              job_description: window.global_store.profile.job_description,
              position_id: window.global_store.profile.position_id,
              uuid: window.global_store.profile.uuid,
            },
            uuid: window.global_store.profile.uuid,
            id: window.global_store.profile.id,
            label: window.global_store.profile.name,
          },
      showInactiveEmployees: false,
      showServicesSummary: false,
    };
  }

  isDownloadBtnLocked = () =>
    this.props.type === ActivityReportType.location ||
    (hasEmployeesAccess() && this.props.type === ActivityReportType.employee);

  onGenerateButtonClick = (): void => {
    ga.trackGenerateReport(ReportType.activities);

    this.setState({
      notification: null,
      isReportVisible: true,
      key: new Date().getTime(),
    });
  };

  onResetButtonClick = (): void => {
    this.setState({
      isGenerateButtonLocked: this.isDownloadBtnLocked(),
      isReportVisible: false,
      showInactiveEmployees: false,
      showServicesSummary: false,
      selectedLocation: null,
      selectedClientOption: null,
      projectUuid: null,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      notification: null,
    });
  };

  setIsLoading: SetIsLoading = (isLoading) => {
    this.setState({ isGenerateButtonLocked: isLoading });
  };

  onError: OnError = (message) => {
    this.setState({ isReportVisible: false, notification: message, notificationType: NotificationType.error });
  };

  onSearchChange = (searchObj: SearchObject | null): void => {
    this.setState({ searchObj, isGenerateButtonLocked: false });
  };

  render(): JSX.Element {
    const { t, type } = this.props;
    const {
      startDate,
      endDate,
      isGenerateButtonLocked,
      isReportVisible,
      key,
      notification,
      notificationType,
      projectUuid,
      selectedLocation,
      selectedClientOption,
      searchObj,
      showInactiveEmployees,
      showServicesSummary,
      selectedActivityStatus,
      selectedTaskFeatureSubtype,
    } = this.state;

    const showSingleEmployeeReport = type === ActivityReportType.employee;
    const showSingleProjectTaskFeatureReport = [ActivityReportType.taskFeature].includes(type) && projectUuid !== null;
    const showAllProjectTaskFeatureReport = [ActivityReportType.taskFeature].includes(type) && projectUuid === null;
    const showSingleProjectReport = type === ActivityReportType.project && projectUuid !== null;
    const showAllProjectsReport = type === ActivityReportType.project && projectUuid === null;
    const showAllClientsReport = type === ActivityReportType.client && !selectedClientOption?.value;
    const showByLocationReport = type === ActivityReportType.location && selectedLocation !== null;
    const showByClientReport =
      type === ActivityReportType.client && selectedClientOption?.value !== null && selectedClientOption?.value !== "";

    const showReport =
      isReportVisible &&
      (showSingleEmployeeReport ||
        showSingleProjectReport ||
        showAllProjectsReport ||
        showSingleProjectTaskFeatureReport ||
        showAllProjectTaskFeatureReport ||
        showAllClientsReport ||
        showByLocationReport ||
        showByClientReport);

    const reportParams = {
      startDate,
      endDate,
      showServicesSummary,
      activityStatus: selectedActivityStatus,
    };
    const isAdmin = window?.global_store?.profile?.permission_roles?.some(
      (r) =>
        r.name === PermissionRoleName.owner || r.name === PermissionRoleName.admin || r.name === PermissionRoleName.hr,
    );
    const employeeUUID = window.global_store?.profile?.uuid;

    let additionalFiltersControlOptions;
    if (type === ActivityReportType.employee) {
      additionalFiltersControlOptions = [
        {
          label: t("servicesSummary"),
          checked: showServicesSummary,
          onChange: (val: boolean): void => {
            this.setState({ showServicesSummary: val });
          },
        },
      ];
    } else if (type === ActivityReportType.location) {
      additionalFiltersControlOptions = [
        {
          label: translateEmployeeTerm(
            t,
            TranslationNamespaces.reportsPage,
            "custom-show-inactive-employees",
            `${TranslationNamespaces.reportsPage}|Show inactive employees`,
          ),
          checked: showInactiveEmployees,
          onChange: (val: boolean): void => {
            this.setState({ showInactiveEmployees: val });
          },
        },
        {
          label: t("servicesSummary"),
          checked: showServicesSummary,
          onChange: (val: boolean): void => {
            this.setState({ showServicesSummary: val });
          },
        },
      ];
    } else {
      additionalFiltersControlOptions = [
        {
          label: translateEmployeeTerm(
            t,
            TranslationNamespaces.reportsPage,
            "custom-show-inactive-employees",
            `${TranslationNamespaces.reportsPage}|Show inactive employees`,
          ),
          checked: showInactiveEmployees,
          onChange: (val: boolean): void => {
            this.setState({ showInactiveEmployees: val });
          },
        },
      ];
    }

    return (
      <FullPage title={getReportsPageTitle(t, type)}>
        <Page>
          <ReportSearchFiltersBase
            onGenerate={this.onGenerateButtonClick}
            generateButtonDisabled={isGenerateButtonLocked}
            onReset={this.onResetButtonClick}
          >
            <LocationsSelectWrapper>
              {type === ActivityReportType.location && (
                <LocationsDropdown
                  withCode
                  uuid
                  placeholder={t("Select Location")}
                  value={selectedLocation ? selectedLocation.value : ""}
                  employeeLocations={
                    !isAdmin && window?.global_store?.profile?.locations?.length
                      ? window.global_store.profile.locations
                      : []
                  }
                  employeeUuid={employeeUUID}
                  onChange={(_, option): void => {
                    this.setState({
                      selectedLocation: option,
                      isGenerateButtonLocked: false,
                    });
                  }}
                />
              )}
              {type === ActivityReportType.client && (
                <ClientsDropdown
                  defaultSelectView
                  withEmptyOption
                  placeholder={t("All Clients")}
                  value={selectedClientOption?.value || ""}
                  onChange={(v, o) => {
                    this.setState({
                      selectedClientOption: o,
                      isGenerateButtonLocked: false,
                    });
                  }}
                />
              )}
              {type === ActivityReportType.taskFeature && (
                <Select
                  wrapperStyles={{ marginInlineEnd: "16px" }}
                  value={selectedTaskFeatureSubtype}
                  onChange={(value: TaskFeatureReportSubtypes): void => {
                    this.setState({
                      selectedTaskFeatureSubtype: value,
                    });
                  }}
                  options={this.TASK_FEATURE_REPORT_SUBTYPES}
                />
              )}
              {[ActivityReportType.project, ActivityReportType.taskFeature].includes(type) && (
                <ProjectsDropdownControl
                  withEmptyItem
                  emptyItemLabel={t("All Projects")}
                  placeholder={t("All Projects")}
                  value={projectUuid || null}
                  onChange={(val: string): void => {
                    this.setState({ projectUuid: val });
                  }}
                />
              )}
            </LocationsSelectWrapper>

            {type === ActivityReportType.employee && (
              <SearchControlWrapper>
                <SearchControl
                  permissionSection={PermissionSectionName.basicReports}
                  onChange={this.onSearchChange}
                  onClear={(): void => this.setState({ searchObj: null, isGenerateButtonLocked: true })}
                  placeholder={translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-search-groups-employees",
                    `${TranslationNamespaces.reportsPage}|Department, Team, All Employees`,
                  )}
                />
              </SearchControlWrapper>
            )}

            <AdditionalFiltersControl marginStart={8} closeOnChange={false} options={additionalFiltersControlOptions} />

            <DateRangePicker
              newOnChangeApproach
              availableDaysCount={window.global_store.beta ? 1900 : 186}
              isAdmin={hasEmployeesAccess()}
              onChange={(sd, ed): void => {
                if (sd && ed) {
                  this.setState({ startDate: sd, endDate: ed });
                }
              }}
              startDate={startDate}
              endDate={endDate}
            />
            <Select
              value={selectedActivityStatus}
              onChange={(value: ReportActvitiesStatus): void => {
                this.setState({
                  selectedActivityStatus: value,
                });
              }}
              options={this.ACTIVITY_STATUS_TYPES}
            />
          </ReportSearchFiltersBase>
          {notification && <NotificationRow withCloseButton={false} type={notificationType} message={notification} />}
          <ContentSpacer />
          {showReport ? (
            <>
              {showAllProjectsReport && (
                <ReportsActivitiesAllProjects
                  {...reportParams}
                  showInactiveEmployees={showInactiveEmployees}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {(showSingleProjectTaskFeatureReport || showAllProjectTaskFeatureReport) && (
                <ReportsActivitiesTaskFeature
                  {...reportParams}
                  projectUuid={projectUuid}
                  isFeatureReport={selectedTaskFeatureSubtype === TaskFeatureReportSubtypes.feature}
                  showInactiveEmployees={showInactiveEmployees}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {showAllClientsReport && (
                <ReportsActivitiesAllClients
                  {...reportParams}
                  showInactiveEmployees={showInactiveEmployees}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {showSingleEmployeeReport && (
                <ReportsActivitiesSingleEmployee
                  {...reportParams}
                  searchObj={searchObj}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {showSingleProjectReport && (
                <ReportsActivitiesSingleProject
                  {...reportParams}
                  projectUuid={projectUuid}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {showByLocationReport && (
                <ReportsActivitiesByLocationAllEmployees
                  {...reportParams}
                  showInactiveEmployees={showInactiveEmployees}
                  selectedLocation={selectedLocation}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
              {showByClientReport && selectedClientOption && (
                <ReportsActivitiesByClientAllEmployees
                  {...reportParams}
                  showInactiveEmployees={showInactiveEmployees}
                  clientUuid={selectedClientOption.value}
                  clientName={selectedClientOption.label}
                  key={key}
                  setIsLoading={this.setIsLoading}
                  onError={this.onError}
                />
              )}
            </>
          ) : (
            <NoContent>{t("Select your report type")}</NoContent>
          )}
        </Page>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsPageActivities);
