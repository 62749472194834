import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Dropzone, { DropFilesEventHandler } from "react-dropzone";
import "styles/request-details.scss";
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from "axios";
import { TranslationNamespaces } from "types/translationNamespaces";
import Spinner from "@atlaskit/spinner";
import { Location } from "types/models/location";
import styled from "styled-components";
import { StoreApi, UseBoundStore } from "zustand/esm/index";
import { GlobalContext } from "context/GlobalContextProvider";
import { deleteActivityAttachment, uploadActivityAttachment } from "components/Projects/activityAttachmentsApiUtils";
import MapIcon from "@iconscout/react-unicons/icons/uil-location-point";
import { RequestAttachmentsRowProps } from "../../../Requests/RequestAttachmentsRow";
import { AttachmentIcon, CloseIcon, DocIcon } from "./TimerButtons";
import { TrackTimeStore } from "../TrackTime.store";

export type PendingAttachment = {
  file: File;
  cancelTokenSource: CancelTokenSource;
};

export type UploadAttachment = (
  file: File,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"],
  cancelToken: CancelToken,
) => Promise<RequestAttachmentsRowProps["files"][0]>;

export type RemoveAttachment = (uuid: string) => Promise<void>;

const Attachments = styled.div`
  .BtnRow {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 8px;
  }
  .drop-zone {
  }
  .UploadBtn,
  .LocationBtn {
    align-self: flex-start;
    display: flex;
    align-items: center;
    border: 1px solid var(--colors-surface-150);
    cursor: pointer;
    outline: none;
    color: var(--colors-surface-700);
    background: transparent;
    border-radius: var(--shapes-border-radius-default);
    height: 32px;
    padding: 0 8px;
    gap: 6px;
    svg path {
      fill: var(--colors-surface-400);
    }
    &:hover {
      color: var(--colors-surface-800);
      border-color: var(--colors-surface-400);
      background: var(--colors-surface-50);
      svg path {
        fill: var(--colors-surface-800);
      }
    }
    &:disabled {
      border-color: var(--colors-surface-100);
      color: var(--colors-surface-200);
      background: transparent;
      svg path {
        fill: var(--colors-surface-200);
      }
    }
    &.active svg path {
      fill: var(--colors-primary-500-p);
    }
  }
  .Files {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    border-radius: var(--shapes-border-radius-default);
    padding: 20px 20px 8px;
    width: 100%;
    .File {
      font-size: var(--typography-font-size-default);
      line-height: 130%;
      width: 280px;
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid var(--colors-surface-50);
      background: var(--colors-surface-50);
      color: var(--colors-surface-900-p);
      border-radius: var(--shapes-border-radius-default);
      .Title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .Icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.Close {
          opacity: 0;
          cursor: pointer;
        }
        &.Loader svg circle {
          stroke: var(--colors-primary-500-p);
        }
      }
      &:hover {
        border-color: var(--colors-surface-400);
        .Icon.Close {
          opacity: 1;
          svg path {
            fill: var(--colors-surface-900-p);
          }
        }
      }
    }
  }
`;

export const ActivityLocationAndFiles = ({
  files,
  setFiles,
  store,
  setShowLocationSelect,
  location,
}: {
  setShowLocationSelect: () => void;
  location?: Location;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
  files: any[];
  setFiles: (files: any[]) => void;
}) => {
  const { activity } = store();
  const { t } = useTranslation(TranslationNamespaces.activities);
  const context = useContext(GlobalContext);
  const dropzone = useRef<Dropzone>();
  const pendings = useRef<PendingAttachment[]>([]);

  useEffect(() => {
    setFiles(activity?.attachments || []);
  }, [activity]);

  const uploadAttachment: UploadAttachment = async (file, onUploadProgress, cancelToken) => {
    const company = await context.getCompany();
    const response = await uploadActivityAttachment(
      {
        createdBy: window.global_store.profile.uuid,
        companyUuid: company.uuid,
        attachment: file,
        activityUuid: activity?.uuid || null,
      },
      onUploadProgress,
      cancelToken,
    );
    const attachment = response.data.content;
    setFiles(files.concat(attachment));
    return attachment;
  };

  const removeAttachment: RemoveAttachment = async (uuid) => {
    const company = await context.getCompany();
    await deleteActivityAttachment({
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
      activityUuid: activity?.uuid || null,
      companyUuid: company.uuid,
      attachmentUuid: uuid,
    });

    setFiles(files.filter((a) => a.uuid !== uuid));
  };

  const onAddFilesClick = () => {
    dropzone.current?.open();
  };

  const onDrop: DropFilesEventHandler = (droppedFiles) => {
    droppedFiles.forEach((file) => {
      const isAlreadyAdded = files.some((f) => f.name === file.name);
      if (isAlreadyAdded) return;
      void addAttachment(file);
    });
  };
  async function addAttachment(file: File) {
    const pend: PendingAttachment = {
      file,
      cancelTokenSource: axios.CancelToken.source(),
    };

    pendings.current.push(pend);

    setFiles((fs) => {
      const newF = {
        name: file.name,
        title: file.name,
        preview: URL.createObjectURL(file),
        loading: true,
        percentCompleted: 0,
      } as unknown as RequestAttachmentsRowProps["files"][0];

      const newFs = [...fs, newF];
      return newFs;
    });

    const onUploadProgress = (progressEvent: { loaded: number; total: number }) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      setFiles((fs) => {
        const newFs = fs.map((f) => (f.name === file.name ? { ...f, percentCompleted } : f));
        return newFs;
      });
    };

    const uploadedAttachment = await uploadAttachment(pend.file, onUploadProgress, pend.cancelTokenSource.token);
    pendings.current = pendings.current.filter((pend) => pend.file !== file);
    setFiles((fs) => {
      const newF: RequestAttachmentsRowProps["files"][0] = {
        ...uploadedAttachment,
        name: file.name,
        loading: false,
        percentCompleted: 100,
      } as unknown as RequestAttachmentsRowProps["files"][0];
      const newFs = fs.map((f) => (f.name === file.name ? newF : f));
      return newFs;
    });
  }

  return (
    <Attachments className="Attachments">
      {files.length > 0 && (
        <div className="Files">
          {files.map((f) => (
            <div key={f.title} className="File">
              <div className="Icon">
                <DocIcon />
              </div>
              <div className="Title" title={f.title}>
                {f.title}
              </div>
              {!f.percentCompleted || f.percentCompleted === 100 ? (
                <div className="Icon Close" onClick={() => removeAttachment(f.uuid)}>
                  <CloseIcon />
                </div>
              ) : (
                <div className="Icon Loader">
                  <Spinner size="small" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="BtnRow">
        <button
          type="button"
          disabled={!activity?.task}
          onClick={() => activity?.task && setShowLocationSelect()}
          className={`LocationBtn ${location ? "active" : ""}`}
          title={!activity?.task ? t("Select project and task first") : ""}
        >
          <MapIcon size="16" />
          {location ? location?.name : t("Location")}
        </button>
        <Dropzone
          ref={dropzone as any}
          multiple
          accept={["image/*", "application/pdf"]}
          preventDropOnDocument
          disableClick
          className="Attachments drop-zone"
          onDrop={onDrop}
        >
          <button type="button" className="UploadBtn" onClick={onAddFilesClick}>
            <AttachmentIcon />
            {t("Add File")}
          </button>
        </Dropzone>
      </div>
    </Attachments>
  );
};
