import { ActivityReportType, ReportType } from "types/reports";
import { getTitle } from "utils/common";
import { TFunction } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";

type ReportTypeTitles = Record<ReportType | ActivityReportType, string>;

export const getReportsPageTitle = (t: TFunction, reportType: ReportType | ActivityReportType) => {
  const reportsTitles = {} as ReportTypeTitles;

  reportsTitles[ReportType.punches] = t("Punches Report");
  reportsTitles[ReportType.afd] = t("Export AFD/AFDT");
  reportsTitles[ReportType.fte] = t("FTE Report");
  reportsTitles[ReportType.detailed] = t("Detailed Report");
  reportsTitles[ReportType.extraHours] = t("Extra Hour");
  reportsTitles[ReportType.summary] = t("Frequency Map");
  reportsTitles[ReportType.checkin] = t("Checkin Report");
  reportsTitles[ReportType.hoursbank] = t("Hour Bank");
  reportsTitles[ReportType.lateArrival] = t("Late Arrivals Report");
  reportsTitles[ReportType.clients] = t("Client Reports");
  reportsTitles[ActivityReportType.project] = t(`Project_title`);
  reportsTitles[ActivityReportType.client] = t(`Client_title`);
  reportsTitles[ActivityReportType.employee] = translateEmployeeTerm(
    t,
    TranslationNamespaces.common,
    "custom-employees",
    `${TranslationNamespaces.common}|Employees`,
  );
  reportsTitles[ActivityReportType.location] = t(`Location_title`);
  reportsTitles[ActivityReportType.taskFeature] = t(`Task_Feature_title`);
  reportsTitles[ReportType.digitalSignaturesStatus] = t("Digital Signatures");

  const title = reportsTitles[reportType] || "";

  document.title = getTitle(title);

  return title;
};
