import moment from "moment-timezone";

class DateHelpers {
  createRangeArray = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const arr = [];
    while (start.isSameOrBefore(end, "day")) {
      arr.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }
    return arr;
  };
}

export default new DateHelpers();
