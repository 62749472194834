import { useRef, useEffect, useState } from "react";

/**
 * There may be a delay after a component is rendered and before loading flag is set in useEffect
 * Also sometimes different behavior is required for the first load of the component (e.g. show skeleton) and secondary partial loads (e.g. show smaller spinner in the corner)
 * Use this hook in both cases.
 * It returns true initially and only when all of the loadingFlags went through true -> false transition it returns false.
 * Example:
 * 1st render - useInitialLoading(false, false) - true
 * 2nd render - useInitialLoading(true, false)  - true
 * 3rd render - useInitialLoading(false, false) - true, because second flag haven't gone through true -> false transition yet
 * 4th render - useInitialLoading(false, true)  - true
 * 5th render - useInitialLoading(false, false) - false, both flags have gone from true -> false transition
 * 6th render - useInitialLoading(true, false) -  false, once it's false it will never go back to true
 */
export function useInitialLoading(...loadingFlags: React.DependencyList): boolean {
  const wasLoading = useRef(loadingFlags.some((f) => f));
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (!initialLoading) return;
    const isLoading = loadingFlags.some((f) => f);
    if (wasLoading.current && !isLoading) {
      setInitialLoading(false);
    }
    wasLoading.current = isLoading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, loadingFlags);
  return initialLoading;
}
