import { stylesheet } from "astroturf";
import cx from "classnames";
import { IconFieldError } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  &:hover {
    background: var(--colors-unknown26);
  }

  .Texts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .Title {
      font-size: 13px;
      font-weight: var(--typography-font-weight-medium);
      line-height: 100%;
      color: var(--colors-surface-800);
    }

    .Value {
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-900-p);
    }

    .Placeholder {
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-600);
    }
  }


  .IconDropdown {
    margin-inline-end: 6px;
  }

  .IconError {
    margin: 1px;
  }

  &.error {
    .Texts {
      .Title {
        color: var(--colors-danger-500);
      }
    }
  }

  &.disabled {
    cursor: default;
  }
}
`;

export interface ActivityTaskProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  error?: boolean;
}

export function ActivityField(props: ActivityTaskProps) {
  const { title, value, disabled, className, error, placeholder, onClick, children, ...restProps } = props;

  return (
    <div
      className={cx(styles.Wrapper, { [styles.error]: error, [styles.disabled]: disabled }, className)}
      onClick={disabled ? undefined : onClick}
      {...restProps}
    >
      <div className={styles.Texts}>
        <div className={styles.Title}>{title}</div>
        {children}
      </div>
      {error && <IconFieldError className={styles.IconError} />}
    </div>
  );
}
