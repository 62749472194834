import { BillingService } from "components/Billing/BillingService";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { Feature } from "types/models/subscription";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionSectionName, hasPermisionAccess } from "utils/common";
import { translateEmployeeTerm } from "utils/translationHelpers";
import * as Icons from "./menu-svg-icons";

const Wrapper = styled.div`
  width: 260px;
`;

const MenuSection = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid var(--colors-surface-150);
`;

const MenuItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

const SectionName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  line-height: 18px;
  font-size: 13px;
  color: var(--colors-surface-600);
  cursor: default;
`;

const ReportsSubMenu = () => {
  const { t } = useTranslation([TranslationNamespaces.sidebar, TranslationNamespaces.reportsPage]);
  const flags = useFlags();
  const location = useLocation();

  const getReportsSubMenuOptions = () => {
    const hasProjectsPermission = hasPermisionAccess(PermissionSectionName.basicReports);

    const getTASection = () => {
      const options = [
        {
          name: t("Regular Report"),
          path: "/reports/punches",
          permissionSection: PermissionSectionName.basicReports,
        },
        {
          name: t("Detailed Report"),
          path: "/reports/detailed",
          permissionSection: PermissionSectionName.basicReports,
        },
        {
          name: t("Summary"),
          path: "/reports/summary",
          permissionSection: PermissionSectionName.advancedReports,
        },
        {
          name: t("Extra Hour"),
          path: "/reports/extra-hours",
          permissionSection: PermissionSectionName.basicReports,
        },
        {
          name: t("Hour Bank"),
          path: "/reports/hour-bank",
          permissionSection: PermissionSectionName.basicReports,
        },
        {
          name: t("Client Reports"),
          path: "/reports/client-reports",
          permissionSection: PermissionSectionName.advancedReports,
        },
        {
          name: t("Checkin Report"),
          path: "/reports/checkin",
          permissionSection: PermissionSectionName.advancedReports,
        },
        {
          name: t("Export AFD/AFDT"),
          path: "/reports/afd",
          permissionSection: PermissionSectionName.advancedReports,
        },
        {
          name: t("Digital Signatures"),
          path: "/reports/digital_signatures_status",
          permissionSection: PermissionSectionName.advancedReports,
        },
      ];

      if (flags["fte-report"]) {
        options.push({
          name: t("FTE Report"),
          path: "/reports/fte",
          permissionSection: PermissionSectionName.advancedReports,
        });
      }

      return {
        name: t("Time & Attendance"),
        options: options.filter((o) => hasPermisionAccess(o.permissionSection)),
      };
    };

    const getPerformanceSection = () => ({
      name: t("Performance"),
      options: [
        {
          name: t("Under Performance"),
          path: "/reports/late-arrivals",
          permissionSection: PermissionSectionName.basicReports,
        },
      ].filter((o) => hasPermisionAccess(o.permissionSection)),
    });

    const getProjectsSection = () => ({
      name: t("Projects"),
      options: hasProjectsPermission
        ? [
            {
              name: t(`${TranslationNamespaces.reportsPage}|Project_title`),
              path: "/reports/projects/project",
            },
            {
              name: t(`${TranslationNamespaces.reportsPage}|Client_title`),
              path: "/reports/projects/client",
            },
            {
              name: translateEmployeeTerm(
                t,
                TranslationNamespaces.common,
                "custom-employees",
                `${TranslationNamespaces.common}|Employees`,
              ),
              path: "/reports/projects/employee",
            },
            {
              name: t(`${TranslationNamespaces.reportsPage}|Location_title`),
              path: "/reports/projects/location",
            },
            {
              name: t(`${TranslationNamespaces.reportsPage}|Task_Feature_title`),
              path: "/reports/projects/task",
            },
          ]
        : [],
    });

    const options = [];
    const taSection = getTASection();
    if (BillingService.checkFeatureAccess(Feature.TA) && taSection.options.length) options.push(taSection);

    const projectsSection = getProjectsSection();
    if (BillingService.checkFeatureAccess(Feature.Project) && projectsSection.options.length)
      options.push(projectsSection);

    const performanceSection = getPerformanceSection();
    if (BillingService.checkFeatureAccess(Feature.TA) && performanceSection.options.length)
      options.push(performanceSection);

    return options;
  };

  return (
    <Wrapper>
      {getReportsSubMenuOptions().map((g) => (
        <MenuSection key={g.name}>
          <SectionName>{g.name}</SectionName>

          {g.options.map((o) => (
            <MenuItem key={o.name} to={o.path} onClick={(e) => e.stopPropagation()}>
              {o.name}
              {o.path === location.pathname && <Icons.CheckMark />}
            </MenuItem>
          ))}
        </MenuSection>
      ))}
    </Wrapper>
  );
};

export default ReportsSubMenu;
