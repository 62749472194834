import { useTranslation } from "react-i18next";
import { getImgUrl } from "utils/common";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import "./drop-zone.scss";

const Wrapper = styled.div`
  width: 300px;
  height: 140px;
  border-radius: var(--shapes-border-radius-default);
  overflow: hidden;
  position: relative;
  .remove-logo {
    display: none;
    z-index: 1;
    background: var(--colors-surface-50);
    position: absolute;
    cursor: pointer;
  }
  &:hover {
    .remove-logo {
      display: flex;
    }
  }
  .document-logo-drop-zone {
    background: var(--colors-surface-50);
    border: 1px solid var(--colors-surface-150);
    width: 100%;
    height: 100%;
    border-radius: var(--shapes-border-radius-default);
    cursor: pointer;
    &.loading {
      cursor: default;
    }
  }
  .document-logo-drop-zone_reject {
    border: 1px solid var(--colors-error);
  }
  .document-logo-drop-zone_accept {
    border: 1px solid var(--colors-success-600-p);
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DefaultBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 18px;
`;

const LogoTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-900-p);
  margin-bottom: 4px;
  text-align: center;
`;

const LogoDetails = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: var(--colors-surface-400);
  text-align: center;
`;

// GeneralTab

interface CompanyDocumentLogoUploaderProps {
  logoId: string;
  onDrop: (file: File[]) => void;
  onClear: () => void;
  file: File | null;
  isLoading: boolean;
}

const CompanyDocumentLogoUploader = ({
  logoId,
  onDrop,
  onClear,
  file,
  isLoading,
}: CompanyDocumentLogoUploaderProps): JSX.Element => {
  const { t } = useTranslation(["company-profile", "industry-types"]);
  const cloudinaryTransformation = "f_auto,q_auto/h_140,w_300,c_thumb,fl_progressive.force_strip/";
  let dropzoneClassName = "document-logo-drop-zone";

  if (isLoading) {
    dropzoneClassName += " loading";
  }

  return (
    <Wrapper>
      <>
        {logoId || file ? (
          <DefaultBlock className="remove-logo" onClick={(): void => onClear()}>
            <LogoTitle>{t("Click here to remove logo")}</LogoTitle>
          </DefaultBlock>
        ) : null}
        <Dropzone
          multiple={false}
          accept="image/jpeg,image/jpg,image/png,image/gif"
          preventDropOnDocument
          disabled={isLoading}
          className={dropzoneClassName}
          activeClassName="document-logo-drop-zone_active"
          acceptClassName="document-logo-drop-zone_accept"
          rejectClassName="document-logo-drop-zone_reject"
          onDrop={(dropFile: File[]): void => {
            onDrop(dropFile);
          }}
        >
          {!logoId && !file ? (
            <DefaultBlock>
              <LogoTitle>{t("Upload logo to be used in printed reports")}</LogoTitle>
              <LogoDetails>{t("300x140 maximum / JPG or PNG")}</LogoDetails>
            </DefaultBlock>
          ) : (
            <Logo alt="" src={file ? URL.createObjectURL(file) : getImgUrl(logoId, cloudinaryTransformation)} />
          )}
        </Dropzone>
      </>
    </Wrapper>
  );
};

export default CompanyDocumentLogoUploader;
