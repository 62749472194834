import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { ErrorLabel } from "components/UI/TextLabels";
import CheckboxControl from "components/UI/NewCheckbox";
import FieldWrapper from "components/UI/FieldWrapper";
import { Label, LabelRow, SettingsBlock, Subheader } from "../styled";
import { AlertsPeriod } from "./helpers";
import AlertsRow from "./AlertsRow";

type NotificationsAndAlertsProps = {
  hoursBankActive: boolean;
  groupDisabled: boolean;
  groupLocked: boolean;
  inactive: boolean;
  errors: Record<string, string>;
  ehLimit: number;
  hbPeriod: AlertsPeriod;
  ehPeriod: AlertsPeriod;
  hbLimit: number | null;
  overtimeStartAlert: boolean;
  onEHPeriodChange: (value: AlertsPeriod) => void;
  onHBPeriodChange: (value: AlertsPeriod) => void;
  onHBLimitChange: (value: number) => void;
  onEHLimitChange: (value: number) => void;
  onOvertimeStartAlertChange: (value: boolean) => void;
};

const NotificationsAndAlerts = ({
  hoursBankActive,
  groupDisabled,
  groupLocked,
  inactive,
  errors,
  hbPeriod,
  hbLimit,
  ehLimit,
  ehPeriod,
  overtimeStartAlert,
  onEHPeriodChange,
  onHBPeriodChange,
  onHBLimitChange,
  onEHLimitChange,
  onOvertimeStartAlertChange,
}: NotificationsAndAlertsProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  return (
    <SettingsBlock>
      <Subheader style={{ marginBottom: "-7px" }}>{t("Notifications and Alerts")}</Subheader>

      <AlertsRow
        disabled={!hoursBankActive || groupDisabled || groupLocked}
        locked={hoursBankActive && !groupDisabled && groupLocked}
        error={errors?.hbLimit ? errors.hbLimit : null}
        limit={hbLimit}
        period={hbPeriod}
        onLimitChange={onHBLimitChange}
        onPeriodChange={onHBPeriodChange}
        isHoursBank
      />
      {errors?.hbLimit && <ErrorLabel>{errors.hbLimit}</ErrorLabel>}
      <AlertsRow
        disabled={hoursBankActive || groupDisabled || groupLocked}
        locked={!hoursBankActive && !groupDisabled && groupLocked}
        error={errors?.ehLimit ? errors.ehLimit : null}
        limit={ehLimit}
        period={ehPeriod}
        onLimitChange={onEHLimitChange}
        onPeriodChange={onEHPeriodChange}
        isHoursBank={false}
      />
      <LabelRow>
        <Label style={{ fontSize: "12px", lineHeight: "15px" }}>
          {t("You will be notified as soon as your employees reach the configured total")}
        </Label>
      </LabelRow>
      {errors?.ehLimit && <ErrorLabel>{errors.ehLimit}</ErrorLabel>}

      {window.global_store.beta ? (
        <>
          <br />
          <FieldWrapper>
            <CheckboxControl
              label={t("Overtime start alert")}
              checked={overtimeStartAlert}
              disabled={inactive}
              onChange={onOvertimeStartAlertChange}
            />
          </FieldWrapper>
        </>
      ) : null}
    </SettingsBlock>
  );
};

export default NotificationsAndAlerts;
