import { Component, createRef } from "react";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import RequestsAddEditPopup from "components/Requests/RequestAddEditPopup";
import NewRequest from "components/Requests/NewRequest";
import { WithTranslation, withTranslation } from "react-i18next";
import { createRequest, createSingleRequest } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  CreateRequestPayload,
  RequestAttachment,
  RequestSubType,
  RequestType,
  RequestTypeName,
} from "types/models/request";
import { SearchObject } from "types/common";
import ga from "utils/ga";
import { getRequestTypeNameByRequestType } from "./requestUtils";

interface CreateNewRequestScreenProps extends WithTranslation {
  onCreateRequest: (err?: unknown) => void;
  isOpen: boolean;
  onClose: () => void;
  prefillProfile: SearchObject | null;
}

interface CreateNewRequestScreenState {
  newRequestDetails: boolean;
  requestSettings: RequestType | null;
}

class CreateNewRequestScreen extends Component<CreateNewRequestScreenProps, CreateNewRequestScreenState> {
  requestStateRef = createRef<{ loading: boolean }>();

  readonly state: Readonly<CreateNewRequestScreenState> = {
    newRequestDetails: false,
    requestSettings: null,
  };

  onCreateRequest = async (
    request: CreateRequestPayload,
    attachments: RequestAttachment[],
    requestSubtype: RequestSubType,
  ) => {
    const params = request.userProfileUuids.length
      ? {
          from: request.startTime,
          to: request.endTime,
          user_profile_uuid: request.userProfileUuids[0],
        }
      : {};

    try {
      if ((!attachments || !attachments.length) && request.userProfileUuids.length > 1) {
        await createRequest({
          body: { content: request },
          params,
          companyUUID: window.global_store.company.uuid,
        });
      } else {
        // eslint-disable-next-line prefer-destructuring
        request.userProfileUuid = request.userProfileUuids[0];
        delete request.userProfileUuids;

        await createSingleRequest({
          body: { content: request },
          params,
          companyUUID: window.global_store.company.uuid,
        });
      }

      ga.trackRequestSubmit({
        request_category: request.requestType,
        request_sub_category: requestSubtype.name,
      });

      this.props.onCreateRequest();
    } catch (err) {
      this.props.onCreateRequest(err);
    }

    this.onClose();
  };

  openNewRequestDetails = ({ type, request }: { type: RequestTypeName; request: RequestType }) => {
    this.setState({
      newRequestDetails: true,
      requestSettings: request,
    });
  };

  closeNewRequestDetails = () => {
    this.setState({
      newRequestDetails: false,
      requestSettings: null,
    });
  };

  onClose = () => {
    const { t, onClose } = this.props;

    if (this.requestStateRef?.current?.loading) {
      const isConfirmed = window.confirm(t("Cancel attachment upload?"));

      if (!isConfirmed) {
        return;
      }
    }

    onClose();
    this.closeNewRequestDetails();
  };

  render() {
    const { isOpen, prefillProfile, t } = this.props;
    const { newRequestDetails, requestSettings } = this.state;

    return (
      <SidePopupOverlay
        isOpen={isOpen}
        header={requestSettings ? getRequestTypeNameByRequestType(requestSettings, t) : null}
        contentOverflow={!!newRequestDetails}
        onBack={newRequestDetails ? this.closeNewRequestDetails : undefined}
        onClose={this.onClose}
        requestDetails={!newRequestDetails && !requestSettings}
      >
        {!newRequestDetails && !requestSettings ? (
          <NewRequest onSelect={this.openNewRequestDetails} />
        ) : (
          <RequestsAddEditPopup
            stateRef={this.requestStateRef}
            prefillProfile={prefillProfile}
            requestSettings={requestSettings as RequestType}
            onClose={this.onClose}
            onYes={this.onCreateRequest}
          />
        )}
      </SidePopupOverlay>
    );
  }
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(CreateNewRequestScreen);
