import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import BEM from "utils/BEM";
import { getEmployee, updateCompanyRules, getCompanyRules } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import { getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import { NotificationType } from "types/common";
import { UserProfile } from "types/models/userProfile";
import NotificationRow from "components/NotificationRow";
import PunchTypeBox from "components/controls/PunchTypeBox";
import { SpacerVertical } from "components/styled/Page";
import SelectControl from "components/UI/SelectControl";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/StyledTextInput";
import { ErrorLabel } from "components/UI/TextLabels";
import "components/UI/Page/Page.scss";
import { TranslationNamespaces } from "types/translationNamespaces";
import CheckboxControl from "components/UI/NewCheckbox";
import { translateEmployeeTerm } from "utils/translationHelpers";
import RichTooltip from "components/UI/RichTooltip";

const b = BEM.b("employee-details");
const p = BEM.b("page");

const KioskIdSelectorWrapper = styled.div`
  max-width: 320px;
`;
const PunchesTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 448px;
`;

const PunchesTypesTitle = styled.div`
  margin-bottom: 24px;
  line-height: 26px;
  font-size: 15px;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-surface-900-p);
`;

const AdvancedSettings = styled.div`
  margin-top: 24px;
`;

const AdvancedSettingBlock = styled.div`
  margin-top: 24px;
`;

const AdvancedSettingBlockTitle = styled.div`
  margin-bottom: 18px;
  margin-top: 32px;
  font-size: 12px;
  font-weight: var(--typography-font-weight-bold);
  color: var(--colors-surface-600);
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface EmployeePunchesSettingsProps extends WithTranslation {
  employeeId: number;
}

interface EmployeePunchesSettingsState {
  loaded: boolean;
  notification: string | null;
  employee: UserProfile | null;
  notificationType: NotificationType | null;
  selectedKioskId: "cpf" | "matricula" | "other"; // todo
  selectedKioskIdOther: string | null;
  webPunchActive: boolean;
  appPunchActive: boolean;
  kioskPunchActive: boolean;
  repPunchActive: boolean;
  askLateReason: boolean;
  allowFaceRecognition: boolean;
  allowOnlyManualPunchOnMobile: boolean;
  allowClearCompilation: boolean;
  allowCustomLocation: boolean;
  errors: Record<string, string> | null;
}

class EmployeePunchesSettings extends Component<EmployeePunchesSettingsProps, EmployeePunchesSettingsState> {
  readonly state: Readonly<EmployeePunchesSettingsState> = {
    loaded: false,
    notification: "",
    employee: null,
    notificationType: null,
    selectedKioskId: "cpf",
    selectedKioskIdOther: null,
    webPunchActive: false,
    appPunchActive: false,
    kioskPunchActive: false,
    repPunchActive: false,
    askLateReason: false,
    allowFaceRecognition: false,
    allowOnlyManualPunchOnMobile: false,
    allowClearCompilation: false,
    allowCustomLocation: false,
    errors: null,
  };

  async componentDidMount() {
    try {
      const { user_profile: employee } = await getEmployee({
        id: this.props.employeeId,
        newHierarchyPermissions: true,
      });

      const resp = await getCompanyRules({ employeeUUID: employee.uuid });
      const businessRulesArray = resp.business_rules || [];
      const businessRules: Record<string, boolean | string> = {};

      businessRulesArray.forEach((br) => {
        businessRules[br.name] = br.value;
      });

      this.setState({
        selectedKioskIdOther: (businessRules.user_profile_tablet_token as unknown as string) || "",
        selectedKioskId:
          (businessRules.tablet_id_mode as unknown as EmployeePunchesSettingsState["selectedKioskId"]) || "cpf",
        webPunchActive: businessRules.allow_web_punch as boolean,
        appPunchActive: businessRules.allow_mobile_punch as boolean,
        kioskPunchActive: businessRules.allow_tablet_punch as boolean,
        repPunchActive: businessRules.allow_rep_punch as boolean,
        askLateReason: businessRules.ask_late_reason as boolean,
        allowFaceRecognition: businessRules.allow_face_recognition as boolean,
        allowOnlyManualPunchOnMobile: businessRules.allow_only_manual_punch_on_mobile as boolean,
        allowClearCompilation: businessRules.allow_clear_compilation as boolean,
        allowCustomLocation: businessRules.allow_custom_location as boolean,
        employee,
        loaded: true,
      });
    } catch (e) {
      Sentry.sendError(e);
    }
  }

  onSave = async () => {
    const {
      webPunchActive,
      kioskPunchActive,
      appPunchActive,
      repPunchActive,
      allowFaceRecognition,
      allowOnlyManualPunchOnMobile,
      allowClearCompilation,
      askLateReason,
      selectedKioskId,
      selectedKioskIdOther,
      employee,
      allowCustomLocation,
    } = this.state;
    let errors = null;

    if (selectedKioskId === "other") {
      if (!selectedKioskIdOther) {
        errors = {
          selectedKioskIdOther: "Kiosk ID cant't be empty",
        };
      }
    }

    if (errors) {
      this.setState({ errors });
      return;
    }

    const data = {
      employeeUUID: (employee as UserProfile).uuid,
      body: {
        business_rules: {
          user_profile_tablet_token: selectedKioskIdOther || "",
          tablet_id_mode: selectedKioskId || "cpf",
          allow_web_punch: webPunchActive,
          allow_mobile_punch: appPunchActive,
          allow_tablet_punch: kioskPunchActive,
          allow_rep_punch: repPunchActive,
          ask_late_reason: askLateReason,
          allow_face_recognition: kioskPunchActive && allowFaceRecognition,
          allow_only_manual_punch_on_mobile: allowOnlyManualPunchOnMobile,
          allow_clear_compilation: allowClearCompilation,
          allow_custom_location: allowCustomLocation,
        },
      },
    };

    try {
      await updateCompanyRules(data);

      this.setState({
        errors: null,
        notificationType: NotificationType.success,
        notification: this.props.t("Punch settings were updated"),
      });
    } catch (e) {
      Sentry.sendError(e);

      this.setState({
        errors: null,
        notification: this.props.t("Error accurred while saving"),
        notificationType: NotificationType.error,
      });
    }
  };

  onKioskIdOtherChange = (value: string) => {
    this.setState({
      selectedKioskIdOther: value.replace(/[^\d]*/g, "").substring(0, 5),
    });
  };

  render() {
    const {
      webPunchActive,
      kioskPunchActive,
      appPunchActive,
      notification,
      notificationType,
      allowFaceRecognition,
      allowOnlyManualPunchOnMobile,
      allowClearCompilation,
      allowCustomLocation,
      selectedKioskId,
      selectedKioskIdOther,
      loaded,
      errors,
    } = this.state;
    const { t } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const kioskIdOptions = [
      { label: employeeTaxIdLabel, value: "cpf" },
      { label: "PIS", value: "pis" },
      { label: t(`${TranslationNamespaces.common}|Matricula`), value: "matricula" },
      { label: t(`${TranslationNamespaces.common}|Other`), value: "other" },
    ];

    return (
      <div>
        {notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notificationType}
            onClose={() => {
              this.setState({ notification: null, notificationType: null });
            }}
            message={notification}
          />
        )}

        {!loaded && <div>{t(`${TranslationNamespaces.common}|Loading...`)}</div>}

        {loaded && (
          <div className={p("content")}>
            <PunchesTypesTitle>
              {translateEmployeeTerm(
                t,
                TranslationNamespaces.punchesPage,
                "custom-allow-users-to-punch",
                `${TranslationNamespaces.punchesPage}|Allow users to punch using:`,
              )}
            </PunchesTypesTitle>
            <PunchesTypes>
              <PunchTypeBox
                active={appPunchActive}
                onClick={() => {
                  this.setState({ appPunchActive: !this.state.appPunchActive });
                }}
                type="app"
                label={t("App")}
              />
              <PunchTypeBox
                active={kioskPunchActive}
                onClick={() => {
                  this.setState({
                    kioskPunchActive: !this.state.kioskPunchActive,
                  });
                }}
                type="tablet"
                label={t("Tablet")}
              />
              <PunchTypeBox
                active={webPunchActive}
                onClick={() => {
                  this.setState({ webPunchActive: !this.state.webPunchActive });
                }}
                type="web"
                label={t("Web")}
              />
            </PunchesTypes>

            <AdvancedSettings>
              <PunchesTypesTitle>{t(`${TranslationNamespaces.punchesPage}|Advanced Settings`)}</PunchesTypesTitle>

              <AdvancedSettingBlock>
                <AdvancedSettingBlockTitle>{t("App")}</AdvancedSettingBlockTitle>

                <CheckboxControl
                  ios
                  small
                  disabled={!appPunchActive}
                  checked={allowOnlyManualPunchOnMobile}
                  label={t("Allow punch adjustments only")}
                  onChange={(value) => this.setState({ allowOnlyManualPunchOnMobile: value })}
                />
                <br />
                <CheckboxControl
                  ios
                  small
                  disabled={!appPunchActive}
                  checked={allowClearCompilation}
                  label={t("allow_clear_compilation")}
                  onChange={(value) => this.setState({ allowClearCompilation: value })}
                />
                <br />
                <RowWrapper>
                  <CheckboxControl
                    ios
                    small
                    disabled={!appPunchActive}
                    checked={allowCustomLocation}
                    label={t("allow_custom_location")}
                    onChange={(value) => this.setState({ allowCustomLocation: value })}
                  />
                  <RichTooltip text={t("allow_custom_location-tooltip")} />
                </RowWrapper>

                <AdvancedSettingBlockTitle>{t("Tablet")}</AdvancedSettingBlockTitle>
                <div>
                  <CheckboxControl
                    ios
                    small
                    disabled={!kioskPunchActive}
                    checked={allowFaceRecognition}
                    label={t("Enable Facial Biometry")}
                    onChange={(value) => this.setState({ allowFaceRecognition: value })}
                  />
                </div>

                {kioskPunchActive && <SpacerVertical height={18} />}
                {kioskPunchActive && (
                  <KioskIdSelectorWrapper>
                    <FieldWrapper
                      fieldName={t(`${TranslationNamespaces.punchesPage}|Kiosk Access ID`)}
                      width="320px"
                      tooltipText={translateEmployeeTerm(
                        t,
                        TranslationNamespaces.punchesPage,
                        "custom-kiosk-access-id-info",
                        `${TranslationNamespaces.punchesPage}|kiosk-access-id-info`,
                      )}
                    >
                      <SelectControl<EmployeePunchesSettingsState["selectedKioskId"]>
                        options={kioskIdOptions}
                        onChange={(val) => {
                          this.setState({
                            selectedKioskId: val,
                            selectedKioskIdOther: "",
                          });
                        }}
                        value={selectedKioskId}
                        isSearchable
                      />
                    </FieldWrapper>

                    {selectedKioskId === "other" && (
                      <>
                        <FieldWrapper fieldName="" width="100%">
                          <TextInputControl
                            value={selectedKioskIdOther || ""}
                            placeholder={t(`${TranslationNamespaces.punchesPage}|Kiosk Access ID`)}
                            error={!!errors?.selectedKioskIdOther}
                            onChange={this.onKioskIdOtherChange}
                          />
                        </FieldWrapper>
                        {errors?.selectedKioskIdOther && <ErrorLabel>{t(errors.selectedKioskIdOther)}</ErrorLabel>}
                      </>
                    )}
                  </KioskIdSelectorWrapper>
                )}
              </AdvancedSettingBlock>
            </AdvancedSettings>

            <div style={{ width: "132px" }} className="button-next">
              <input
                value={t(`${TranslationNamespaces.common}|Save`)}
                type="button"
                className={b("button", { full: true })}
                onClick={this.onSave}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.punchesPage])(
  EmployeePunchesSettings,
);
