import moment from "moment";
import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import { GetCompanyRulesRequestData, GetCompanyRulesResponseData } from "./types";

// TODO change this to proper types
type TODORequestData = any;
type TODOResponseData = Promise<any>;

export function getCompanyRules(data?: GetCompanyRulesRequestData): APIResponse<GetCompanyRulesResponseData> {
  const employeeUUID = data?.employeeUUID ? `?user_profile_uuid=${data.employeeUUID}` : "";

  return sendRequest({}, "GET", `/business_rules${employeeUUID}`);
}

export function updateCompanyRules(data?: TODORequestData): TODOResponseData {
  const employeeUUID = data && data.employeeUUID ? `?user_profile_uuid=${data.employeeUUID}` : "";

  return sendRequest(data.body, "PUT", `/business_rules${employeeUUID}`);
}

export function getNextDueDateBRGroup(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/business_rules/hours_bank/next_due_date?today=${moment().format("YYYY-MM-DD")}`,
    CONFIG.client_admin_api,
  );
}

// TODO move somewhere
export function updateBRGroupTolerance(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/extra_hours/companies/${data.companyUuid}/business_rules/groups/${data.brGroupUuid}/tolerance`,
    CONFIG.api_management,
  );
}
