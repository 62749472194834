import { LocationType } from "types/models/location";
import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  GetLocationsRequestData,
  GetWifiValidationsResponseData,
  AddLocationBeaconRequest,
  AddLocationBeaconResponse,
  GetLocationsResponseData,
  GetLocationBeaconsResponse,
  GetOneLocationResponseData,
  GetSuggestedCodeResponseData,
} from "./types";

// TODO
type TODOApiRequestPayload = any;
type TODOApiResponse = Promise<any>;

export function getLocations(data: GetLocationsRequestData = {}): APIResponse<GetLocationsResponseData> {
  const queryParams = [];

  if (data.page && data.perPage && !data.searchTerm) {
    queryParams.push(`page=${data.page}&per_page=${data.perPage}`);
  }
  if (typeof data.active === "boolean") {
    queryParams.push(`active=${data.active}`);
  }
  if (data.verificationMethods?.length) {
    queryParams.push(`verification_methods=${data.verificationMethods.join(",")}`);
  }
  if (data.locationTypes?.length) {
    queryParams.push(`location_types=${data.locationTypes.join(",")}`);
  }
  if (data.searchTerm) {
    queryParams.push(`term=${data.searchTerm}`);
  }

  return sendRequest({}, "GET", `/locations${queryParams.length ? `?${queryParams.join("&")}` : ""}`);
}

export function getOneLocation(locationId: number): APIResponse<GetOneLocationResponseData> {
  return sendRequest({}, "GET", `/locations/${locationId}`);
}

export function addLocation(data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "POST", "/locations/");
}

export function editLocation(id: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "PUT", `/locations/${id}`);
}

export function getWiFiValidations(locationId: number): APIResponse<GetWifiValidationsResponseData> {
  return sendRequest({}, "GET", `/locations/${locationId}/wifi_points`);
}

export function addWiFiValidation(locationId: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "POST", `/locations/${locationId}/wifi_points/`);
}

export function editWiFiValidation(validationId: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "PUT", `/wifi_points/${validationId}`);
}

export function getIBeaconValidations(locationId: number): APIResponse<GetLocationBeaconsResponse[]> {
  return sendRequest({}, "GET", `/locations/${locationId}/beacons`);
}

export function addIBeaconValidation(
  locationId: number,
  data: AddLocationBeaconRequest,
): APIResponse<AddLocationBeaconResponse> {
  return sendRequest(data.body, "POST", `/locations/${locationId}/beacons/`);
}

export function editIBeaconValidation(validationId: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "PUT", `/beacons/${validationId}`);
}

export function getIPValidations(locationId: number): TODOApiResponse {
  return sendRequest({}, "GET", `/locations/${locationId}/ip_addresses`);
}

export function addIPValidation(locationId: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "POST", `/locations/${locationId}/ip_addresses/`);
}

export function editIPValidation(validationId: number, data: TODOApiRequestPayload): TODOApiResponse {
  return sendRequest(data.body, "PUT", `/ip_addresses/${validationId}`);
}

export function isLocationValidByIp(locationId: number): TODOApiResponse {
  return sendRequest({}, "get", `/locations/${locationId}/ip_addresses/validate_location`, CONFIG.api);
}

export function getLocationSuggestedCode(locationType: LocationType): APIResponse<GetSuggestedCodeResponseData> {
  return sendRequest({}, "get", `/locations/suggest_code?location_type=${locationType}`, CONFIG.api);
}
