import React, { Component } from "react";
import BEM from "utils/BEM";
import "../UI/Page/Page.scss";
import "styles/schedules-page.scss";
import { withTranslation } from "react-i18next";
import Select from "components/UI/Select";
import SearchInput from "components/UI/SearchInput";
import * as moment from "moment";
import { withRouter } from "react-router-dom";
import { getTitle, strIncludesCheck } from "utils/common";
import { TableButton, TableButtons } from "components/styled/Page";
import { getExceptions, toggleScheduleException } from "utils/apiHelpers";
import GlobalContext from "context/global-context";
import Pagination from "components/Pagination";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import TablePage from "../TablePage";
import NoContent from "../NoContent";
import noSchedules from "../../img/no-schedules.png";

const b = BEM.b("schedules-page");
const p = BEM.b("page");

class ScheduleExceptionsPage extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      exceptions: [],
      status: "active",
      searchValue: "",
      totalRecords: 0,
      page: 1,
      perPage: 25,
      initialFetchDone: false,
      loading: true,
    };
    document.title = getTitle(t("Schedules"));
  }
  componentDidMount() {
    this.getState({ ...this.props });
  }

  getState = async () => {
    const { initialFetchDone, loading, page, perPage, status } = this.state;
    if (!initialFetchDone || !loading) {
      this.setState({ loading: true, initialFetchDone: true });
      const company = await this.context.getCompany();

      const { content, metadata } = await getExceptions({ companyUUID: company.uuid, perPage, page, status });
      const userProfilesMap = content.reduce((acc, curr) => {
        acc[curr.UserProfileUuid] = "";
        acc[curr.createdBy] = "";
        return acc;
      }, {});
      const userProfilesUuidsToFetch = Object.keys(userProfilesMap);
      const { content: userProfiles } = await listUserProfilesWIthFilters(window.global_store.company.uuid, {
        ...baseByUuidPayload(window.global_store.profile.uuid, userProfilesUuidsToFetch),
        fields: ["id", "uuid", "fullName"],
      });
      // eslint-disable-next-line no-return-assign
      userProfiles.forEach((userProfile) => (userProfilesMap[userProfile.uuid] = userProfile));

      const exceptions = content.map((exception) => ({
        ...exception,
        createdByName: userProfilesMap[exception.createdBy].fullName || "",
        userProfileName: userProfilesMap[exception.UserProfileUuid].fullName || "",
      }));
      const totalRecords = metadata?.total || 0;
      this.setState({
        exceptions,
        loading: false,
        totalRecords,
        page: page || 1,
      });
    }
  };
  toggleException = ({ scheduleExceptionUuid, action }) => {
    toggleScheduleException({
      companyUuid: window.global_store.company.uuid,
      userProfileUUID: window.global_store.profile.uuid,
      scheduleExceptionUuid,
      action,
    })
      .then(() => {
        this.getState({ ...this.props });
      })
      .catch((e) => console.log(e.message));
  };

  onSearch = (ev) => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Exception Name"),
        accessor: "name",
        locked: true,
        Cell: (row) => (
          <a
            className={b("schedule-name")}
            href={`/schedules/exception/${row.original.uuid}/`}
            onClick={(ev) => ev.preventDefault()}
          >
            <div className={b("schedule-name-value")}>
              {["ApprovedOvertimeRequest", "enableDay", "ApprovedOvertimeNonWorkingDayRequest"].includes(row.value)
                ? t(row.value)
                : row.value}
            </div>
            <div className={b("schedule-name-type")}>{t(row.original.type)}</div>
          </a>
        ),
        minWidth: 160,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Employees"),
        accessor: "userProfileName",
        minWidth: 100,
      },
      {
        label: t("Period"),
        accessor: "startDate",
        Cell: (row) =>
          row.original.endDate !== row.value
            ? `${moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
                row.original.endDate,
                "YYYY-MM-DD",
              ).format("DD/MM/YYYY")}`
            : moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY"),
        minWidth: 150,
      },
      {
        label: t("Created By"),
        accessor: "createdByName",
        minWidth: 100,
      },
      {
        label: t("Status"),
        accessor: "status",
        locked: true,
        align: "center",
        Cell: (row) => (
          <div>
            <span className={b("status-button", { inactive: row.value !== "active" })}>
              {t(row.value === "active" ? "active" : "deactivated")}
            </span>
            <TableButtons className="buttons">
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  if (row.value === "active") {
                    this.toggleException({
                      scheduleExceptionUuid: row.original.uuid,
                      action: "deactivate",
                    });
                  } else {
                    this.toggleException({
                      scheduleExceptionUuid: row.original.uuid,
                      action: "activate",
                    });
                  }
                }}
              >
                {row.value === "active" ? t("common|Deactivate") : t("common|Activate")}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 120,
      },
    ];
    return columns;
  }
  render() {
    const { exceptions, loading, searchValue, status, totalRecords, page, perPage } = this.state;
    const { t } = this.props;

    const filtered = exceptions.filter((s) => strIncludesCheck(s.name, searchValue));

    return (
      <div>
        <div className={b()}>
          <TablePage
            columnSelectorOnFiltersRow
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search Schedule Exceptions")}
                  value={searchValue}
                />
                <div className={p("dropdown")}>
                  <Select
                    value={status}
                    onChange={(value) => this.setState({ status: value, page: 1 }, () => this.getState())}
                    options={[
                      { value: "", label: t("Status") },
                      { value: "active", label: t("Active") },
                      { value: "deactivated", label: t("Inactive") },
                    ]}
                  />
                </div>
              </>
            }
            rows={filtered}
            columns={this.getTableColumns()}
            showPagination
            noDataText=""
            pages={100}
            loading={loading}
            manual
            showPageSizeOptions={false}
            PaginationComponent={() => (
              <Pagination
                totalRecords={totalRecords}
                pageLimit={perPage}
                pageNeighbours={2}
                currentPage={page}
                onPageLimitChange={(pageLimit, currentPage) =>
                  this.setState({ perPage: pageLimit, page: currentPage }, () => this.getState())
                }
                onPageChanged={(val) => {
                  this.setState({ page: val.currentPage }, () => this.getState());
                }}
              />
            )}
            onPageChange={(val) => {
              this.getState({ page: val });
            }}
            getTrProps={(state, rowInfo) => ({
              onClick: () => {
                this.props.history.push(`/schedules/exception/${rowInfo.original.uuid}/`);
              },
            })}
            noContentComponent={<NoContent img={noSchedules}>{t("Please create a new schedule")}</NoContent>}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation("schedules")(ScheduleExceptionsPage));
