import moment from "moment";
import { ReactNode } from "react";

interface StartEndDates {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

enum EmployeeTaxPayerTypes {
  pis = "pis", // BR
  cpf = "cpf", // BR
  tz = "tz", // IL
  ssn = "ssn", // US
  rfc = "rfc", // MX
  nit = "nit", // CO
  ss = "SS", // ES
  tpid = "tpid", // Tax payer ID, Other countries
}

enum CompanyTaxPayerTypes {
  cnpj = "cnpj", // BR
  hp = "hp", // IL
  ein = "ein", // US
  cif = "cif", // ES
  mxid = "mxid", // MX
  coid = "coid", // CO
  ctid = "ctid", // Company tax ID, Other countries
}

enum SearchObjectTypes {
  department = "department",
  subsidiary = "subsidiary",
  team = "team",
}

/* eslint camelcase: ["warn", { allow: ["avatar_id", "full_name", "job_description", "position_id"] }] */
type SearchObject = {
  employee?: {
    uuid?: string;
    id?: string | number;
    label?: string;
    avatar_id?: string | null;
    full_name?: string;
    job_description?: string;
    position_id?: number | null;
  };
  uuid?: string;
  id?: number | "all";
  label?: string;
  type?: SearchObjectTypes;
};

// TODO. Use some other lib instead of react-click-outside because it's deprecated
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClickOutsideType = any;

enum NotificationType {
  success = "success",
  error = "error",
  progress = "progress",
  warning = "warning",
  attention = "attention",
  message = "message",
  locked = "locked",
  errorNew = "errorNew",
  info = "info",
  infoBlue = "infoBlue",
}

type Notification = {
  notification: ReactNode; // usually string
  notificationType: NotificationType | null;
};

enum RDStationActionEventType {
  emailInput = "email_input",
  newCompnay = "new_company",
  newUser = "new_user",
}

type Address = {
  formatted: string;
  country: string;
  lat: number | null;
  lng: number | null;
  state: string;
  street: string;
  city: string;
  streetNumber: string;
  zip: string;
};

enum Direction {
  up = "up",
  down = "down",
  left = "left",
  right = "right",
}

enum Sizes {
  small = "sm",
  large = "lg",
}

export type { StartEndDates, SearchObject, Notification, ClickOutsideType, Address };

export {
  SearchObjectTypes,
  NotificationType,
  EmployeeTaxPayerTypes,
  CompanyTaxPayerTypes,
  RDStationActionEventType,
  Direction,
  Sizes,
};
