import styled from "styled-components";

const Wrapper = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  background-image: ${(p) =>
    p.selected
      ? `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none'%3E%3Crect width='16' height='16' x='.5' y='.5' fill='%231E97F7' rx='8'/%3E%3Ccircle cx='8.5' cy='8.5' r='3' fill='%23fff'/%3E%3C/svg%3E")`
      : `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none'%3E%3Crect width='15' height='15' x='1' y='1' fill='%23fff' rx='7.5'/%3E%3Crect width='15' height='15' x='1' y='1' stroke='%23A1B2CF' rx='7.5'/%3E%3C/svg%3E")`};
  background-repeat: no-repeat;
  background-position: center;
`;

interface RadioButtonProps {
  selected: boolean;
  onClick: () => void;
}

const RadioButton = ({ selected, onClick }: RadioButtonProps) => <Wrapper selected={selected} onClick={onClick} />;

export default RadioButton;
