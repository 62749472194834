import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FieldsGroup from "components/UI/FieldsGroup";
import AddRemoveLink from "components/controls/AddRemoveLink";
import NewItem from "components/controls/NewItem";
import {
  RequestComplianceResetRule,
  RequestCustomFieldType,
  RequestSubType,
  RequestSubtypeSpecificFieldTypes,
  RequestTypeName,
} from "types/models/request";
import RequestField from "./RequestField";
import "styles/table-common.scss";

const NewItemWrapper = styled.div`
  margin-top: 16px;
  max-width: 200px;
  width: 100%;
`;

const Spacer = styled.div`
  height: 10px;
`;

interface RequestSettingsFieldsProps {
  onChange: (data: { requestSubType: RequestSubType }) => void;
  requestSubType: RequestSubType;
}

const RequestSettingsFields = ({ onChange, requestSubType }: RequestSettingsFieldsProps) => {
  const { t } = useTranslation("requests-page-tmp");

  const getPeriods = ({ active, values }: { active: boolean; values?: number[] }): number[] => {
    if (!active) {
      return [];
    }

    if (!values?.length) {
      return [30];
    }

    return values as number[];
  };

  const getDaysBeforeRequest = ({ active, days }: { active: boolean; days?: number }) => {
    if (!active) {
      return 0;
    }

    return days as number;
  };
  const getRequestDays = ({ active, days }: { active: boolean; days?: number }) => {
    if (!active) {
      return 0;
    }

    return days as number;
  };

  return (
    <FieldsGroup label={t("Fields")} width="525px" style={{ marginTop: "7px" }}>
      <Spacer />
      {![RequestTypeName.overtime, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(
        requestSubType.requestType,
      ) && (
        <RequestField
          t={t}
          key="attachment"
          field={{
            name: t("attachment"),
            // don't implement lockAfterDays for stg1
            attachmentAditionalFields: window.global_store.beta
              ? {
                  lockAfterDays: requestSubType.attachment.lockAfterDays
                    ? requestSubType.attachment.lockAfterDays
                    : null,
                }
              : null,
            ...requestSubType.attachment,
          }}
          onChange={(field) => {
            const newRequestSubType = requestSubType;
            newRequestSubType.attachment = {
              active: field.active,
              required: field.required,
              lockAfterDays: field.lockAfterDays,
            };
            onChange({ requestSubType: newRequestSubType });
          }}
        />
      )}

      <RequestField
        t={t}
        key="comment"
        field={{ name: t("comment"), ...requestSubType.comment }}
        onChange={(field) => {
          const newRequestSubType = requestSubType;
          newRequestSubType.comment = {
            active: field.active,
            required: field.required,
          };
          onChange({ requestSubType: newRequestSubType });
        }}
      />

      {requestSubType.requestType === RequestTypeName.vacation && (
        <>
          <RequestField
            t={t}
            withoutObligatory
            key="predefinedPeriods"
            tooltip={t("predefined-periods-tooltip")}
            field={{
              name: t("Predefined Period Selection"),
              predefinedPeriods: true,
              ...requestSubType.complianceRules.predefinedPeriods,
            }}
            onChange={(field) => {
              const newRequestSubType = requestSubType;
              newRequestSubType.complianceRules.predefinedPeriods = {
                active: field.active,
                values: getPeriods(field),
              };
              onChange({ requestSubType: newRequestSubType });
            }}
          />

          <RequestField
            t={t}
            withoutObligatory
            key="daysBeforeRequestDate"
            field={{
              name: t("Days before requests date"),
              daysBeforeRequestDate: true,
              ...requestSubType.complianceRules.daysBeforeRequestDate,
            }}
            onChange={(field) => {
              const newRequestSubType = requestSubType;

              newRequestSubType.complianceRules.daysBeforeRequestDate = {
                active: field.active,
                days: getDaysBeforeRequest(field),
              };

              onChange({ requestSubType: newRequestSubType });
            }}
          />

          <RequestField
            t={t}
            withoutObligatory
            key="totalDaysLimit"
            field={{
              name: t("How many vacation days"),
              totalDaysLimit: true,
              ...requestSubType.complianceRules.totalDaysLimit,
            }}
            onChange={(field) => {
              const newRequestSubType = requestSubType;

              newRequestSubType.complianceRules.totalDaysLimit = {
                active: field.active,
                days: getRequestDays(field),
                resetRule: field.active ? field.resetRule : RequestComplianceResetRule.startDate,
                adjustFirstPeriodProportionally: field.active ? field.adjustFirstPeriodProportionally : false,
              };

              onChange({ requestSubType: newRequestSubType });
            }}
          />
        </>
      )}

      {![RequestTypeName.scheduleAssignment].includes(requestSubType.requestType) &&
        !!requestSubType.subtypeSpecificFields?.length &&
        requestSubType.subtypeSpecificFields.map((f, i) => (
          <RequestField
            key={f.name}
            withoutObligatory={f.name === RequestSubtypeSpecificFieldTypes.dayRange}
            t={t}
            field={{ ...f, name: t(`${f.name}_field_name`) }}
            onChange={(field) => {
              const newRequestSubType = requestSubType;
              const updatedField = {
                ...f,
                active: field.active,
                required: field.active && field.required,
              };

              newRequestSubType.subtypeSpecificFields[i] = updatedField;

              onChange({ requestSubType: newRequestSubType });
            }}
          />
        ))}

      {!!requestSubType.customFields?.length &&
        requestSubType.customFields.map((cf, i) => (
          <RequestField
            key={`custom${cf.name}`}
            t={t}
            field={{ ...cf, customField: true }}
            onRemove={() => {
              const newRequestSubType = requestSubType;
              newRequestSubType.customFields.splice(i, 1);
              onChange({ requestSubType: newRequestSubType });
            }}
            onChange={(field) => {
              const newRequestSubType = requestSubType;
              const customField = {
                name: field.name,
                fieldType: field.fieldType,
                active: field.active,
                required: field.active ? field.required : false,
                iconKey: field.iconKey,
                externalId: field.externalId,
              };
              newRequestSubType.customFields[i] = customField;
              onChange({ requestSubType: newRequestSubType });
            }}
          />
        ))}

      {![RequestTypeName.overtime, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(
        requestSubType.requestType,
      ) &&
        requestSubType.customFields?.length < 5 && (
          <NewItemWrapper>
            <NewItem
              onApply={({ value, callBackSuccess }) => {
                const newRequestSubType = requestSubType;
                newRequestSubType.customFields.push({
                  name: value,
                  iconKey: null,
                  fieldType: RequestCustomFieldType.input,
                  active: true,
                  required: false,
                });
                onChange({ requestSubType: newRequestSubType });
                callBackSuccess();
              }}
              label={
                <AddRemoveLink
                  label={t("Add field")}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                />
              }
            />
          </NewItemWrapper>
        )}
    </FieldsGroup>
  );
};

export default RequestSettingsFields;
