import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import Button, { ButtonState } from "components/controls/StyledButton";
import CheckboxControl from "components/UI/NewCheckbox";

const ActionsBarRow = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  left: 0;
  bottom: 5.12%;
`;

const ActionsBarWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;
  height: 60px;
  width: 100%;
  max-width: 693px;
  border-radius: 58px;
  background: var(--colors-default);
  box-shadow: 0px 5px 30px rgba(129, 147, 171, 0.2);
`;

const SlecetedCount = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.span`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-text1);
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 16px;
`;

interface ApproveDeclineActionBarProps<T> {
  t: WithTranslation["t"];
  selectedItems: T[];
  onApprove: () => void;
  onDelcine: () => void;
  onUncheckAll: (checked: boolean) => void;
}

const ApproveDeclineActionBar = <T extends unknown>({
  t,
  onUncheckAll,
  selectedItems,
  onApprove,
  onDelcine,
}: ApproveDeclineActionBarProps<T>) => {
  if (!selectedItems.length) {
    return null;
  }

  return (
    <ActionsBarRow>
      <ActionsBarWrapper>
        <SlecetedCount>
          <CheckboxControl
            label={
              <CheckboxLabel>
                {t("Selected")} {selectedItems.length}
              </CheckboxLabel>
            }
            checked
            onChange={onUncheckAll}
          />
        </SlecetedCount>
        <ActionButtons>
          <Button value={t("Decline")} style={{ width: "150px" }} state={ButtonState.cancel} onClick={onDelcine} />
          <Spacer />
          <Button style={{ width: "150px" }} value={t("Approve")} state={ButtonState.success} onClick={onApprove} />
        </ActionButtons>
      </ActionsBarWrapper>
    </ActionsBarRow>
  );
};

export default withTranslation()(ApproveDeclineActionBar);
