const PAYROLL_GROUP_DEFAULT_NAME = "default";

enum TogglePayrolGroupAction {
  activate = "activate",
  deactivate = "deactivate",
}

enum PayrollGroupStatus {
  active = "active",
  deactivated = "deactivated",
  deactivating = "deactivating",
}

enum PayrollGroupRecurrenceFreq {
  monthly = "monthly",
  weekly = "weekly",
}

// EmployeeList.tsx
type PayrollGroupEmployee = {
  uuid: string;
  name: string;
  isDefault: boolean;
};

type PayrollGroupRecurrence = {
  freq: PayrollGroupRecurrenceFreq;
  bymonthday: [number?, number?];
};

type UserProfilePayrollGroups = {
  uuid: string;
  userProfileUuid: string;
  payrollGroupUuid: string;
  companyUuid: string;
  startDate: string | null;
  endDate: string | null;
  unassignedAt: string | null;
  createdAt: string;
};

type PayrollGroup = {
  name: "default" | string;
  isDefault: boolean;
  recurrence?: PayrollGroupRecurrence;
  uuid: string;
  companyUuid: string;
  status: PayrollGroupStatus;
  isLocked: boolean;
  forDeactivatedProfile: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  userProfilePayrollGroups: UserProfilePayrollGroups[];
  nextStartDate: string;
  nextEndDate: string;
  lastLockDate: string | null;
};

type PayrollGroupCreateEdit = {
  name: string;
  userProfilesUuids: string[];
  companyUuid: string;
  createdBy: string;
  recurrence: PayrollGroupRecurrence;
};

export type { PayrollGroupEmployee, PayrollGroup, PayrollGroupCreateEdit, PayrollGroupRecurrence };

export { PayrollGroupStatus, PayrollGroupRecurrenceFreq, PAYROLL_GROUP_DEFAULT_NAME, TogglePayrolGroupAction };
