import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { IntegrationObject } from "./ApplicationsService";
import { useHistory, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  .lightbox__buttons {
    margin-top: 20px;
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const Logo = styled.div<{ logoImage: string }>`
  width: 24px;
  height: 24px;
  background-image: ${(p) => p.logoImage};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Name = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.55px;
  color: var(--colors-surface-900-p);
`;

const FeaturesBlock = styled.div`
  margin-top: 032px;
`;

const FeaturesTitle = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 16px;
  line-height: 16px;
  color: var(--colors-surface-900-p);
  margin-top: 20px;
`;

const FeatruesList = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin-top: 20px;
  margin-bottom: 0;
`;

const FeatureItem = styled.li`
  position: relative;
  margin-top: 16px;
  padding-inline-start: 30px;
  border-bottom: 1px solid var(--colors-surface-100);
  padding-bottom: 12px;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-800);

  &:last-child {
    border-bottom: none;
    padding-bottom: 12px;
  }

  &:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none'%3E%3Cpath fill='%231E97F7' d='M10.767 5.825 7.192 9.408 5.817 8.033a.833.833 0 1 0-1.175 1.175L6.6 11.175a.831.831 0 0 0 .592.241.833.833 0 0 0 .583-.241l4.167-4.167a.833.833 0 1 0-1.175-1.183ZM8.5.167a8.333 8.333 0 1 0 0 16.666A8.333 8.333 0 0 0 8.5.167Zm0 15a6.667 6.667 0 1 1 0-13.334 6.667 6.667 0 0 1 0 13.333Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const AditionalInfoBlock = styled.div`
  div.ol {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
`;

const Description = styled.p`
  color: var(--colors-surface-800);
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
`;

const AditionalInfoBlockLi = styled.div<{ step: string }>`
    font-weight: var(--typography-font-weight-default);
    font-size: var(--typography-font-size-default);
    line-height: 18px
    color: var(--colors-surface-800);
    padding-bottom: 12px;
    display: flex;
    align-items: flex-start;
    a {
      font-size: var(--typography-font-size-default);
      line-height: 18px;
      font-weight: var(--typography-font-weight-medium);
    }
    &:before {
      content: '${(p) => p.step}';
      padding-inline-end: 4px;
      min-width: 12px;
      display: flex;
    }
    &:first-child {
      border-bottom: 1px solid var(--colors-surface-100);
    }
    &:last-child {
      padding-bottom: 12px;
    }
`;

interface IntegrationConnectConfirmationProps {
  isVisible: boolean;
  onClose: () => void;
  integration: IntegrationObject;
  customNextStepUrl?: string;
}

const IntegrationConnectConfirmation = ({
  isVisible,
  onClose,
  integration,
  customNextStepUrl,
}: IntegrationConnectConfirmationProps) => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const url = `${integration.url}?companyUuid=${window.global_store?.company?.uuid}&from=${window.location.href}`;
  const history = useHistory();

  return (
    <ModalDialog onClose={onClose} isOpen={isVisible}>
      <Wrapper>
        <Lightbox
          onClose={onClose}
          title={
            <Title>
              <Logo logoImage={integration.logo} />
              <Name>{integration.name}</Name>
            </Title>
          }
          buttonYesTitle={t(`Connect`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onYes={() => {
            if (customNextStepUrl) {
              history.push(customNextStepUrl);
            } else if (integration.openInNewTab) {
              window.open(url, "_blank");
            } else {
              window.location.href = url;
            }

            onClose();
          }}
        >
          <Description>{t("connect-confirmation-description-general")}</Description>
          <FeaturesBlock>
            <FeaturesTitle>{t("Features")}</FeaturesTitle>
            <FeatruesList>
              <FeatureItem>{t("connect-confirmation-description-general-feature-1")}</FeatureItem>
              <FeatureItem>{t("connect-confirmation-description-general-feature-2")}</FeatureItem>
              <FeatureItem>{t("connect-confirmation-description-general-feature-3")}</FeatureItem>
            </FeatruesList>
          </FeaturesBlock>
          {integration.name === "Monday" && (
            <AditionalInfoBlock>
              <FeaturesTitle>{t("Getting started")}</FeaturesTitle>
              <div className="ol">
                <AditionalInfoBlockLi
                  step="1."
                  dangerouslySetInnerHTML={{
                    __html: `<div>${t(`integration-monday-step-1`, { url: integration.installAppUrl })}</div>`,
                  }}
                />
                <AditionalInfoBlockLi step="2.">{t("integration-monday-step-2")}</AditionalInfoBlockLi>
              </div>
            </AditionalInfoBlock>
          )}
        </Lightbox>
      </Wrapper>
    </ModalDialog>
  );
};

export default IntegrationConnectConfirmation;
