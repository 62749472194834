enum LocationType {
  jobSite = "job_site",
  workplace = "workplace",
  homeOffice = "home_office",
}

enum LocationValidation {
  gps = "gps",
  wifi = "wifi",
  ibeacon = "ibeacon",
  ip = "ip",
  ipAddress = "ip_address",
  wifiPoints = "wifi_points",
  beacons = "beacons",
}

type LocationClient = {
  uuid: string;
  name: string;
};

/* eslint-disable camelcase */
type LocationManager = {
  avatar_id: string | null;
  avatar_url: string | null;
  company_name: string;
  id: number;
  job_description: string | null;
  last_lock_date: string;
  name: string;
  position_id: string | null;
  uuid: string;
  project_employee_cost: string;
};

type LocationInReports = {
  id: number;
  uuid: string;
  name: string;
  active: boolean;
  timezone: string;
  clientUuid: string;
  locationType: LocationType;
};

type Location = {
  active: boolean;
  city: string;
  client_id: string | null;
  code: string;
  country: string;
  formatted_address: string;
  id: number;
  lat: number;
  lng: number;
  location_type: LocationType;
  manager: LocationManager | null;
  name: string;
  radius: number;
  state: string | null;
  time_zone: string;
  uuid: string;
  verification_methods: LocationValidation[];
  home_office_manager: LocationManager | null;
};

declare global {
  type WindowGlobalStoreLocations = Location[] | undefined;
}

type LocationContainerItem = Location;
type LocationTableData = Location;

type WifiValidation = {
  active: boolean;
  bssid_ids: string;
  created_at: string;
  id: number;
  mac_address: string;
  model_info: string;
  ssid: string;
  title: string;
  deleted_at?: string;
};

type LocationInGlobalContextEmployee = {
  active: boolean;
  code: string;
  isDefault: boolean;
  name: string;
  uuid: string;
  verification_methods: LocationValidation[];
};

type LocationInSearchEmployee = LocationInGlobalContextEmployee;

type PunchLocation = {
  id: number;
  uuid: string;
  name: string;
  formatted: string;
  lat: number;
  lng: number;
  time_zone: string;
  city: string;
  state: unknown | null; // todo
};

export type {
  LocationInReports,
  LocationTableData,
  LocationContainerItem,
  LocationClient,
  Location,
  WifiValidation,
  LocationInGlobalContextEmployee,
  LocationInSearchEmployee,
  PunchLocation,
};

export { LocationType, LocationValidation };
