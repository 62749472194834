import { MouseEvent } from "react";
import styled from "styled-components";
import ThreeBlockLoader from "components/styled/ThreeBlockLoader";
import CheckIcon from "@iconscout/react-unicons/icons/uil-check";

export enum PendingCellStatus {
  clear = "clear",
  warn = "warn",
}

const PendingCellWraper = styled.div<{ status: PendingCellStatus }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${(p) =>
    p.status === PendingCellStatus.warn
      ? "var(--cp-sp-cell-pending-bg-color, var(--colors-activitiCellPending))"
      : "inherit"};
  cursor: ${(p) => (p.status === PendingCellStatus.warn ? "pointer" : "default")};

  &:hover {
    background-color: ${(p) =>
      p.status === PendingCellStatus.warn
        ? "var(--cp-sp-cell-pending-hover-bg-color, var(--colors-unknown38))"
        : "inherit"};
  }
  svg {
    fill: var(--cp-sp-cell-pending-text-color, var(--colors-surface-700));
  }
`;

const Counter = styled.span`
  font-size: var(--typography-font-size-default);
  color: var(--cp-sp-cell-pending-text-color, var(--colors-surface-800));
`;

interface PendingCellProps {
  entityUuid: string;
  pendingItemsCount: number;
  onClick: (entityUuid: string, pendingItemsCount: number, isProcessing: boolean) => void;
  isProcessing: boolean;
}

const PendingCell = ({ entityUuid, pendingItemsCount, onClick, isProcessing }: PendingCellProps) => {
  const status = pendingItemsCount || isProcessing ? PendingCellStatus.warn : PendingCellStatus.clear;

  const handleClick = (ev: MouseEvent) => {
    ev.stopPropagation();

    onClick(entityUuid, pendingItemsCount, isProcessing);
  };

  return (
    <PendingCellWraper status={status} onClick={handleClick}>
      {isProcessing && <ThreeBlockLoader />}

      {!isProcessing &&
        (status === PendingCellStatus.clear ? <CheckIcon size={24} /> : <Counter>{pendingItemsCount}</Counter>)}
    </PendingCellWraper>
  );
};

export default PendingCell;
