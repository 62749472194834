import styled from "styled-components";

const FieldTabsWrapper = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colors-surface-150);
  border-radius: var(--shapes-border-radius-default);
`;
const Separator = styled.div`
  height: 100%;
  width: 1px;
  background: var(--colors-surface-150);
`;
const Tab = styled.div<{ disabled: boolean; active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  ${(props) => (props.active ? `background: var(--colors-activitiCellScheduleItemHover)` : "")}
  font-size: var(--typography-font-size-default);
  color: ${(props) => (props.active ? `var(--colors-primary-500-p)` : `var(--colors-surface-400)`)};
  line-height: 14px;
  height: 100%;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
`;

type FieldTabsProps = {
  active: string;
  onChange: (value: string) => void;
  tab1: { value: string; label: string };
  tab2: { value: string; label: string };
  disabled: boolean;
};

const FieldTabs = ({ active, onChange, tab1, tab2, disabled }: FieldTabsProps) => (
  <FieldTabsWrapper>
    <Tab
      disabled
      active={active === tab1.value}
      onClick={() => {
        if (!disabled) {
          onChange(tab1.value);
        }
      }}
    >
      {tab1.label}
    </Tab>
    <Separator />
    <Tab
      disabled
      active={active === tab2.value}
      onClick={() => {
        if (!disabled) {
          onChange(tab2.value);
        }
      }}
    >
      {tab2.label}
    </Tab>
  </FieldTabsWrapper>
);

export default FieldTabs;
