import { Component } from "react";
import BEM from "utils/BEM";
import { incrementTranslationVersion } from "utils/apiHelpers";
import "../styles/super-admin-panel.scss";
import CheckboxControl from "components/UI/NewCheckbox";
import translationConfig from "../translationConfig";
import { translationVersionCheck } from "../i18n";

const b = BEM.b("super-admin-panel");

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SuperAdminPanelProps {}

interface SuperAdminPanelState {
  translationVersion: number;
  collapsed: boolean;
  validateButton: boolean;
}

class SuperAdminPanel extends Component<SuperAdminPanelProps, SuperAdminPanelState> {
  constructor(props: SuperAdminPanelProps) {
    super(props);
    this.state = {
      translationVersion: translationConfig.translationVersion,
      collapsed: true,
      validateButton: localStorage?.getItem("validateButton") === "true",
    };
  }

  render() {
    const { collapsed, validateButton, translationVersion } = this.state;

    if (!localStorage) {
      return null;
    }

    return (
      <div className={b({ collapsed })}>
        <div
          className={b("header")}
          onClick={() => {
            this.setState({ collapsed: !collapsed }, async () => {
              if (!this.state.collapsed) {
                const version = await translationVersionCheck();
                this.setState({ translationVersion: version });
              }
            });
          }}
        >
          Super settings
        </div>
        <div className={b("content")}>
          <button
            type="button"
            onClick={async () => {
              await incrementTranslationVersion();
              const resp = await translationVersionCheck();
              this.setState({ translationVersion: resp });
            }}
          >
            Update translations (V-{translationVersion})
          </button>
          <br />
          <br />
          <CheckboxControl
            checked={validateButton || false}
            label="Enable validate button"
            onChange={(checked) => {
              if (checked) {
                localStorage.setItem("validateButton", checked.toString());
              } else {
                localStorage.removeItem("validateButton");
              }

              this.setState({ validateButton: checked });
            }}
          />
        </div>
      </div>
    );
  }
}

export default SuperAdminPanel;
