import React, { Component } from "react";
import { getLocations } from "utils/apiHelpers";
import Select from "components/UI/Select";
import BEM from "utils/BEM";

const b = BEM.b("rep-details");

class LocationsDropdownControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
    };
  }
  componentDidMount() {
    getLocations().then((r) => {
      const locations = r.locations.map((loc) => ({
        label: loc.name,
        value: loc.id,
      }));
      this.setState({ locations });
    });
  }
  onChange = (val) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(val);
    }
  };
  render() {
    const { locations } = this.state;
    const { t, value, error, placeholder } = this.props;
    if (locations.length > 0) {
      const selectedLocation = locations.filter((l) => l.value === value)[0] || "";
      return (
        <div>
          <div className={b("label")}>{t("Location")}</div>
          <Select
            onChange={this.onChange}
            error={error}
            modifiers={{ field: true }}
            value={selectedLocation.value}
            placeholder={placeholder}
            options={locations}
          />
        </div>
      );
    }
    return null;
  }
}

export default LocationsDropdownControl;
