export default {
  colors: {
    default: "#fff",
    primary: "#1E97F7",
    primary2: "#F7F9FC",
    secondary: "#A1B2CF",
    secondary2: "#D2DEED",
    secondary3: "#C0CAD8",
    secondary4: "#F5F7FA",
    success2: "#48D465",
    success3: "#00CA73",
    pending: "#FBC533",
    recommend: "#8861E7",
    attention: "#FF9730",
    error: "#FE6764",
    error2: "#ED413D",
    borderPermissions: "#F4F7F9",
    borderColor: "#CFD7E7",
    borderColor2: "#E2E7F0",
    borderColor3: "#E7EAF3",
    badgeBackground: "#F1F4F8",
    background1: "#FCFCFE",
    inputBorderDefault: "#DDE5EE",
    inputMaskText: "#BAC7DC",
    mainText: "#434E6C",
    text1: "#525F7F",
    text3: "#707D9E",
    text4: "#515E80",
    outline: "#B4C4D1",
    outlinetext: "#97ADBE",
    buttonBgColor: "#F6F8FC",
    buttonBgColor2: "#EEF2FF",
    buttonBorderColor: "#BAC7DB",
    buttonBorderColor2: "#E3EAF2",
    activitiInfoRowBorder: "#D2DEEE",
    activityRequestRowHover: "#F6F8FE",
    activitiCellScheduleHover: "#F5F8FB",
    activitiCellScheduleItemHover: "#F3F5FA",
    activitiInfoBlockValue: "#8193AB",
    superPunchBackground: "#D9F7EA",
    superPunchColor: "#0CA563",
    rowBackground: "#F1F5F9",
    cancel: "#FE6664",
    danger1: "#F3D067",
  },
  svg: {
    search_person:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 23 23'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 23C17.851 23 23 17.851 23 11.5S17.851 0 11.5 0 0 5.149 0 11.5 5.149 23 11.5 23z' fill='%23ECEFF4'/%3E%3Cpath d='M11.5 10.97a2.354 2.354 0 100-4.707 2.354 2.354 0 000 4.708zM7.263 16.947v0a3.766 3.766 0 013.766-3.766h.942a3.766 3.766 0 013.766 3.766v0' stroke='%23A1B2CF' stroke-width='1.2'/%3E%3C/svg%3E\")",
    search_group:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 25'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25z' fill='%23E7E3F2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.605 16.71h2.105v-1.579h-2.105v1.58zm-6.315 0h2.105v-1.579H8.29v1.58zm3.157-6.842V8.29h2.106v1.58H11.447zm5.789 4.21h-1.053v-.525a1.58 1.58 0 00-1.578-1.58h-1.579v-1.052h1.053c.29 0 .526-.236.526-.526V7.763a.527.527 0 00-.526-.526H10.92a.527.527 0 00-.526.526v2.632c0 .29.236.526.526.526h1.053v1.053h-1.58a1.58 1.58 0 00-1.578 1.579v.526H7.763a.527.527 0 00-.526.526v2.632c0 .29.236.526.526.526h3.158c.29 0 .526-.236.526-.526v-2.632a.526.526 0 00-.526-.526H9.868v-.526c0-.29.236-.527.527-.527h4.211c.29 0 .524.236.524.527v.526H14.08a.527.527 0 00-.526.526v2.632c0 .29.235.526.526.526h3.157a.526.526 0 00.526-.526v-2.632a.526.526 0 00-.526-.526z' fill='%239F93C2'/%3E%3C/svg%3E\")",
    clear_small:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16A8 8 0 108 0a8 8 0 000 16z' fill='%23EEF2FF'/%3E%3Crect x='10.242' y='4.606' width='1.828' height='7.921' rx='.914' transform='rotate(45 10.242 4.606)' fill='%23A1B2CF'/%3E%3Crect x='11.534' y='10.207' width='1.828' height='7.921' rx='.914' transform='rotate(135 11.534 10.207)' fill='%23A1B2CF'/%3E%3C/svg%3E\")",
    loading_small:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 12'%3E%3Cpath d='M.577.933l1.019 1.02c.05-.06.106-.125.17-.188A6.008 6.008 0 016 0c3.308 0 6 2.692 6 6s-2.692 6-6 6A5.96 5.96 0 01.249 7.714a.75.75 0 111.437-.428A4.472 4.472 0 006 10.5c2.482 0 4.5-2.018 4.5-4.5S8.482 1.5 6 1.5a4.51 4.51 0 00-3.177 1.324l-.153.203 1.646 1.646c.319.319.213.577-.238.577H.408A.407.407 0 010 4.842v-3.67C0 .722.258.615.577.933z' fill='%23A1B2CF'/%3E%3C/svg%3E\")",
    chevron_right:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 22'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z' fill='%231E97F7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.293 6.05a1 1 0 011.414 0l4.243 4.243a1 1 0 010 1.414l-4.243 4.243a1 1 0 11-1.414-1.414L12.828 11 9.293 7.464a1 1 0 010-1.414z' fill='%23fff'/%3E%3C/svg%3E\")",
  },
};
