import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import ClickOutside from "react-click-outside";
import CONFIG from "config";
import { GlobalContext } from "context/GlobalContextProvider";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { ClickOutsideType } from "types/common";
import { TimesheetContext } from "components/Timesheets/timesheets.context";
import { useTrackTimeStore } from "../TrackTime.store";
import { CancelBtn, CheckIcon, DoneBtn } from "./TimerButtons";
import { findParentInTree, isRunningActivity } from "../time-tracker.utils";
import { OverlayDropdown } from "./OverlayDropdown";
import { TrackerDateTime } from "./TrackerDateTime";
import { DatePickerPopup } from "./DatePickerPopup";

export const DateControl = ({ updateActiveActivity }: { updateActiveActivity: (act: any) => void }) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const context = useContext(GlobalContext);
  const timesheetContext = useContext(TimesheetContext);
  const {
    activity,
    setActivity,
    setShowDatePicker,
    showDatePicker,
    showTaskDetails,
    showTaskPicker,
    showSelectProject,
    touched,
    startTime,
    endTime,
    startDate,
    duration,
    initiateDateValues,
    closeAllModals,
    changeDuration,
    loadTimesheets,
  } = useTrackTimeStore();

  const [getTimesheets] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    const ts = await loadTimesheets(company.uuid, window.global_store.profile.uuid, window.global_store.profile.uuid);
    return ts;
  }, [startDate]);

  useEffect(() => void getTimesheets(), [getTimesheets]);

  const initiateValues = useCallback(() => {
    initiateDateValues();
  }, [activity]);

  useEffect(() => {
    initiateValues();
  }, [activity]);

  const isRunning = useMemo(() => isRunningActivity(activity), [activity]);

  const onCancel = () => {
    setShowDatePicker(false);
    initiateValues();
  };

  const onSave = async () => {
    if (touched) {
      if (isRunning) {
        await updateActiveActivity({
          ...activity,
          startTime: startTime!,
        });
      } else {
        await setActivity({
          ...activity,
          date: startDate!.format(CONFIG.apiDateFormat),
          startTime: startTime!,
          endTime: endTime!,
          duration,
          status: "pending",
        });
      }
    }
  };

  const hideDate = useMemo(
    () => showDatePicker || showTaskDetails || showTaskPicker || showSelectProject,
    [showDatePicker, showTaskDetails, showTaskPicker, showSelectProject],
  );

  return (
    <>
      <TrackerDateTime
        activity={activity}
        duration={duration!}
        setShowDatePicker={setShowDatePicker}
        isRunning={isRunning}
        changeDuration={changeDuration}
        startDate={touched ? startDate : null}
        hideDate={hideDate}
      />
      {showDatePicker && !showTaskPicker && (
        <ClickOutside<ClickOutsideType>
          onClickOutside={(e) => {
            if (typeof e?.target?.className === "string" && e.target.className.includes("DurationSelect")) return;
            if (findParentInTree(e.target, ["Task"])) {
              void onSave();
            }
            if (!findParentInTree(e.target, ["Task"])) {
              void onSave();
              closeAllModals();
            }
          }}
        >
          <OverlayDropdown
            className="DatePicker"
            content={
              <DatePickerPopup
                isRunning={isRunning}
                store={useTrackTimeStore}
                allowFutureActivities={!timesheetContext?.settings?.allowFutureActivities}
              />
            }
            footer={
              <div className="Footer align-end">
                {startDate || startTime !== undefined || endTime !== undefined ? (
                  <CancelBtn onClick={onCancel} />
                ) : null}
                <DoneBtn
                  type="button"
                  disabled={!isRunning && (!startDate || startTime === undefined || endTime === undefined)}
                  className="DoneBtn"
                  onClick={async () => {
                    await onSave();
                    closeAllModals();
                  }}
                >
                  <div className="Icon">
                    <CheckIcon />
                  </div>
                  <div>{t(`${TranslationNamespaces.common}|Apply`)}</div>
                </DoneBtn>
              </div>
            }
          />
        </ClickOutside>
      )}
    </>
  );
};
