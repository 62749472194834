import TextInputControl from "components/controls/TextInputControlNew";
import FieldWrapper from "components/UI/FieldWrapper";

export interface CustomFieldProps {
  name: string;
  value: string | number;
  fieldType: "numeric" | "text";
  error: boolean;
  onChange: (value: string | number) => void;
}

export default function CustomField({ name, value, error, fieldType, onChange }: CustomFieldProps): JSX.Element {
  return (
    <FieldWrapper fieldName={name} width="167px">
      <TextInputControl
        placeholder={fieldType === "numeric" ? "0" : ""}
        value={value}
        error={error}
        onChange={(evt) => {
          const val = fieldType === "numeric" ? evt.target.value.replace(/[^0-9,.+-]*/g, "") : evt.target.value;
          onChange(val);
        }}
      />
    </FieldWrapper>
  );
}
