import Tooltip from "components/UI/Tooltip";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import { minsToHrsMins } from "utils/common";
import cx from "classnames";
import moment from "moment";
import { RequestUserProfile } from "types/models/userProfile";
import { TimesheetApprovalStatus, TimesheetRow, TimesheetsListItem } from "../timesheet.types";
import {ReactComponent as WarningIcon } from "../icons/warning.svg";
import {ReactComponent as ErrorIcon } from "../icons/error.svg";
import {ReactComponent as LockIcon } from "../icons/lock.svg";
import { CompanyTimesheetSettings } from "../../Projects/projectsApiTypes";

export const TotalsRow = styled.div`
  height: 45px;
  display: grid;
  border-top: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-50);
  .react-tooltip.place-top {
    margin-top: 10px;
    margin-inline-start: 40px;
  }
  grid-template-columns: 1fr repeat(8, 93px);
`;

const Cell = styled.div<{joinCols?: number, isRTL?: boolean}>`
  align-items: center;
  justify-content: center;
  display: flex;
  transition: none;
  padding-inline-start: 10px;
  ${(props) => (props.joinCols ? `grid-column: ${props.joinCols};` : "")}
  &.common-text.danger,
  &.common-text.warning,
  &.common-text.lock {
    cursor: pointer;
  }
  &.common-text.danger {
    color: var(--colors-danger-600-p);
  }
  &.common-text.warning {
    color: #ebb61a;
  }
  &.common-text.empty {
    color: var(--colors-surface-200);
  }
`;

const getTotalIcon = ({
  danger,
  warning,
  lock,
}: {
  danger: boolean,
  warning: boolean,
  lock: boolean
}) => {
  if (danger) return <ErrorIcon />;
  if (warning) return <WarningIcon />;
  if (lock) return <LockIcon />;
  return null;
};

export default ({
  data,
  dates,
  loading,
  workingHours,
  startDayOffset = 0,
  timesheets,
  lockedDateEnd,
  settings,
}: {
  data: TimesheetRow[];
  loading: boolean;
  dates: string[];
  workingHours: number[];
  startDayOffset?: number;
  timesheets?: Array<TimesheetsListItem & {
    submittedByUser: RequestUserProfile,
    declinedByUser: RequestUserProfile,
    approvedByUser: RequestUserProfile,
  }>;
  lockedDateEnd?: string | null;
  settings?: CompanyTimesheetSettings;
}) => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const dateHoursByAllTasks: Record<string, number> = {};
  dates.forEach((date, i) => {
    dateHoursByAllTasks[date] = dateHoursByAllTasks[date] || 0;
    data.forEach((taskRow) => {
      dateHoursByAllTasks[date] += taskRow.dates[i];
    });
  });
  const totals = Object.values(dateHoursByAllTasks);

  return (
    <TotalsRow className={loading ? "loading" : ""}>
      <Cell className="task-cell common-text">{t("Total")}</Cell>
      {startDayOffset > 1 ? <Cell joinCols={startDayOffset} className={cx("common-cell")} /> : null}
      {totals.map((value, i) => {
        const isMoreThan24Hours: boolean = value > 60 * 24;
        const isMoreThanWorkingHours: boolean = !isMoreThan24Hours && (!!workingHours[i] || !!settings?.restrictActivitiesBasedOnPunchIn) && value > workingHours[i];
        let lockedData: string | null;
        const timesheet = timesheets?.find((ts) => moment(dates[i]).isBetween(moment(ts.startDate), moment(ts.endDate), "day", "[]"));
        if (timesheet && timesheet.approvalStatus === TimesheetApprovalStatus.approved) {
          lockedData = t("Approved on {{date}}", {date: moment(timesheet.submittedAt).format("DD/MM/YYYY"), interpolation: { escapeValue: false }});
        } else if (timesheet && timesheet.approvalStatus === TimesheetApprovalStatus.pending) {
          lockedData = t("Submitted by {{name}} on {{date}}", {name: timesheet.submittedByUser.fullName, date: moment(timesheet.updatedAt).format("DD/MM/YYYY"), interpolation: { escapeValue: false }});
        } else if (lockedDateEnd && moment(dates[i]).isSameOrBefore(lockedDateEnd, "day")) {
          lockedData = t("Payroll period is locked");
        } else {
          lockedData = null;
        }

        const getTooltip = (): string => {
          const tooltip = [];
          if (isMoreThan24Hours) tooltip.push(t("Total Hours exceeds 24h"));
          if (isMoreThanWorkingHours) tooltip.push(t("Total hours exceeds scheduled hours"));
          if (lockedData) tooltip.push(lockedData);
          return tooltip.join("<br />");
        }

        const tooltipString = getTooltip();

        return (
          <Cell
            data-tip
            data-for={`total-cell-tooltip-${i}`}
            key={dates[i]}
            className={cx("common-cell", "common-text", {
              danger: isMoreThan24Hours,
              warning: isMoreThanWorkingHours,
              empty: value === 0,
              lock: lockedData,
            })}
            isRTL={window.global_store.isRTL}
            style={{
              fontWeight: "700",
              lineHeight: "18px",
              fontSize: "14px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {value === 0 ? "–" : minsToHrsMins(value)}
            {getTotalIcon({
              danger: isMoreThan24Hours,
              warning: isMoreThanWorkingHours,
              lock: !!lockedData,
            })}
            {tooltipString ? <Tooltip id={`total-cell-tooltip-${i}`} html>{tooltipString}</Tooltip> : null}
          </Cell>
        );
      })}
      <Cell className="total-cell common-text" style={{ paddingInlineEnd: "13px", gridColumn: "9" }}>
        {minsToHrsMins(totals.reduce((partialSum, a) => partialSum + a, 0))}
      </Cell>
    </TotalsRow>
  );
};
