import { sendRequest } from "utils/apiHelpers";
import qs from "qs";
import moment from "moment-timezone";
import { RequestUserProfile } from "types/models/userProfile";
import CONFIG from "config";
import {
  TimesheetApprovalStatus,
  TimesheetsListItem,
  TimesheetResponse,
  TimeForTaskOnDateQuery,
  TimeForTaskOnDatePayload,
  TimesheetDate,
  TimesheetRow,
  TimesheetDataQuery,
  TimesheetResponseContent,
  TimesheetResponseMetadata,
} from "./timesheet.types";

export type {
  TimesheetApprovalStatus,
  TimesheetsListItem,
  TimesheetResponse,
  TimeForTaskOnDateQuery,
  TimeForTaskOnDatePayload,
  TimesheetDate,
  TimesheetRow,
  TimesheetDataQuery,
  TimesheetResponseContent,
  TimesheetResponseMetadata,
};

export type GetTimeSheetByUuidRequest = {
  companyUuid: string;
  requestedBy: string;
  timesheetUuid: string;
};

export type GetTimeSheetByUuidResponse = {
  content: TimesheetsListItem;
  metadata: {
    userProfilesByUuid: Record<string, RequestUserProfile>;
  };
};

export const getTimesheet = async (props: TimesheetDataQuery): Promise<TimesheetResponse> => {
  const { companyUuid, userProfileUuid, requestedBy, from, to } = props;
  const res: TimesheetResponse = await sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/projects/activities/user_profiles/${userProfileUuid}/aggregated?from=${from}&to=${to}&requested_by=${requestedBy}`,
    CONFIG.client_admin_api,
  );
  return res;
};

export const getTimesheetByUuid = async (props: GetTimeSheetByUuidRequest): Promise<GetTimeSheetByUuidResponse> => {
  const { companyUuid, requestedBy, timesheetUuid } = props;
  return sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/projects/timesheets/${timesheetUuid}?requested_by=${requestedBy}`,
    CONFIG.client_admin_api,
  );
};

export const upsertTimeForTaskOnDate = async (props: TimeForTaskOnDateQuery): Promise<TimesheetResponse> => {
  const { companyUuid, userProfileUuid, body } = props;
  const res: TimesheetResponse = await sendRequest(
    { content: body },
    "POST",
    `/companies/${companyUuid}/projects/activities/user_profiles/${userProfileUuid}/bulk/upsert_for_task`,
    CONFIG.client_admin_api,
  );
  return res;
};

type CancelActivitiesForTaskInRangePayload = {
  companyUuid: string;
  userProfileUuid: string;
  body: {
    from: string;
    to: string;
    taskUuid: string;
    updatedBy: string;
  };
};

export const cancelActivitiesForTaskInRange = async (
  props: CancelActivitiesForTaskInRangePayload,
): Promise<{ content: { data: TimesheetResponseContent; metadata: TimesheetResponseMetadata } }> => {
  const { companyUuid, userProfileUuid, body } = props;
  return await sendRequest(
    { content: body },
    "POST",
    `/companies/${companyUuid}/projects/activities/user_profiles/${userProfileUuid}/decline_in_range`,
    CONFIG.client_admin_api,
  );
};

export type SubmitTimesheetRequest = {
  companyUuid: string;
  userProfileUuid: string;
  body: {
    submittedBy: string;
    /** YYYY-MM-DD */
    startDate: string;
    endDate: string;
    workedMinutes: number;
    activitiesMinutes: number;
    overtimedDays: number;
    exceededDays: number;
  };
};
export const submitTimesheet = async (props: SubmitTimesheetRequest) => {
  const { userProfileUuid, companyUuid, body } = props;
  return await sendRequest(
    {
      content: body,
    },
    "POST",
    `/companies/${companyUuid}/projects/user_profiles/${userProfileUuid}/timesheets/upsert`,
    CONFIG.client_admin_api,
  );
};

type ApproveTimesheetsRequest = {
  companyUuid: string;
  requestedBy: string;
  timesheetsUuids: string[];
};
export const approveTimesheets = async (props: ApproveTimesheetsRequest) => {
  const { requestedBy, companyUuid, timesheetsUuids } = props;
  return await sendRequest(
    {
      content: {
        timesheetsUuids,
        requestedBy,
      },
    },
    "POST",
    `/companies/${companyUuid}/projects/timesheets/bulk/approve`,
    CONFIG.client_admin_api,
  );
};

type DeclineTimesheetsRequest = {
  companyUuid: string;
  requestedBy: string;
  timesheetsUuids: string[];
  declineReason: string;
};
export const declineTimesheets = async (props: DeclineTimesheetsRequest) => {
  const { requestedBy, companyUuid, timesheetsUuids, declineReason } = props;
  return await sendRequest(
    {
      content: {
        timesheetsUuids,
        requestedBy,
        declineReason,
      },
    },
    "POST",
    `/companies/${companyUuid}/projects/timesheets/bulk/decline`,
    CONFIG.client_admin_api,
  );
};

export type ListTimesheetsRequest = {
  companyUuid: string;
  requestedBy: string;
  userProfileUuid?: string;
  /** YYYY-MM-DD */
  from?: string;
  to?: string;
  approvalStatus?: TimesheetApprovalStatus[];
};
export type ListTimesheetsResponse = {
  content: TimesheetsListItem[];
  metadata: any; // todo
};
export const listTimesheets = async (props: ListTimesheetsRequest) => {
  const { userProfileUuid, companyUuid, from, to, requestedBy, approvalStatus } = props;
  const query = {
    from,
    to,
    requested_by: requestedBy,
    approval_status: approvalStatus?.length !== 0 ? approvalStatus?.join(",") : undefined,
    user_profile_uuid: userProfileUuid,
  };
  const res: ListTimesheetsResponse = await sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/projects/timesheets?${qs.stringify(query)}`,
    CONFIG.client_admin_api,
  );
  return res;
};

export const listTimesheetsV2 = async (props: ListTimesheetsRequest) => {
  const { userProfileUuid, companyUuid, from, to, requestedBy, approvalStatus } = props;
  const query = {
    from,
    to,
    requested_by: requestedBy,
    approval_status: approvalStatus?.length !== 0 ? approvalStatus?.join(",") : undefined,
    user_profile_uuid: userProfileUuid,
  };
  const res: TimesheetsListItem[] = await sendRequest(
    {},
    "GET",
    `/companies/${companyUuid}/projects/timesheets/list?${qs.stringify(query)}`,
    CONFIG.client_admin_api,
  );
  return res;
};

export type GetUnavailableDatesRequest = {
  from: string;
  to?: string;
  userProfileUuid?: string;
};
/**
 * Assume a timesheet cannot be longer than 1 month
 */
export const getUnavailableDates = async (params: GetUnavailableDatesRequest) => {
  const { from, to, userProfileUuid = window.global_store.profile.uuid } = params;
  const mFrom = moment(from, "YYYY-MM-DD");
  const mTo = to == null ? mFrom.clone().add(1, "month") : moment(to, "YYYY-MM-DD");
  const req: ListTimesheetsRequest = {
    userProfileUuid,
    companyUuid: window.global_store.profile.company.uuid,
    from: mFrom.clone().subtract(1, "month").format("YYYY-MM-DD"),
    to: mTo.clone().format("YYYY-MM-DD"),
    requestedBy: window.global_store.profile.uuid,
    approvalStatus: [TimesheetApprovalStatus.pending, TimesheetApprovalStatus.approved],
  };
  const res = await listTimesheets(req);
  const tsRanges = res.content.map((ts) => [moment(ts.startDate, "YYYY-MM-DD"), moment(ts.endDate, "YYYY-MM-DD")]);

  const unavailableDates = [];
  const d = mFrom.clone();
  while (d.isSameOrBefore(mTo, "day")) {
    const isUnavail = tsRanges.some((range) => d.isBetween(range[0], range[1], "day", "[]"));
    if (isUnavail) {
      unavailableDates.push(d.format("YYYY-MM-DD"));
    }
    d.add(1, "day");
  }
  return unavailableDates;
};

export type RevokeTimesheetRequest = {
  companyUuid: string;
  timesheetUuid: string;
  requestedBy: string;
};

/** When epmployye withdraws (revokes) his submitted timesheet */
export const revokeTimesheet = async (props: RevokeTimesheetRequest) => {
  const { companyUuid, timesheetUuid, requestedBy } = props;

  return await sendRequest(
    {
      content: {
        requestedBy,
      },
    },
    "PUT",
    `/companies/${companyUuid}/projects/timesheets/${timesheetUuid}/revoke`,
    CONFIG.client_admin_api,
  );
};

export type CancelTimesheetRequest = {
  companyUuid: string;
  timesheetUuid: string;
  requestedBy: string;
};

/** When manager cancels appoved timesheets */
export const cancelTimesheet = async (props: CancelTimesheetRequest): Promise<void> => {
  const { companyUuid, timesheetUuid, requestedBy } = props;

  return await sendRequest(
    {
      content: {
        requestedBy,
      },
    },
    "PUT",
    `/companies/${companyUuid}/projects/timesheets/${timesheetUuid}/cancel`,
    CONFIG.client_admin_api,
  );
};
