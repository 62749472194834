import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import Chip from "components/UI/Chip";
import Button from "components/UI/Button";
import BEM from "utils/BEM";
import { Location } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { editIBeaconValidation, getIBeaconValidations } from "utils/apiHelpers";
import { ColumnAlign } from "components/TableCommon";
import "../Validations.scss";

const b = BEM.b("locations-validations");

interface ValidationsIBeaconProps extends WithTranslation {
  currentLocation: Location;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

interface ValidationsIBeaconState {
  ibeaconValidations: unknown[];
}

class ValidationsIBeacon extends Component<ValidationsIBeaconProps, ValidationsIBeaconState> {
  constructor(props: ValidationsIBeaconProps) {
    super(props);

    this.state = {
      ibeaconValidations: [],
    };
  }

  componentDidMount() {
    void this.getIBeaconValidations();
  }

  getIBeaconValidations = async () => {
    const { beacons } = await getIBeaconValidations(this.props.currentLocation.id);

    this.setState({ ibeaconValidations: beacons });
  };

  editIBeaconValidation = async (validationId: number, data) => {
    const { t, setNotification, onYes } = this.props;

    try {
      await editIBeaconValidation(validationId, { body: { beacon: data } });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  render() {
    const { ibeaconValidations } = this.state;
    const { currentLocation, t } = this.props;

    const columns = [
      {
        label: t("ID"),
        accessor: "id",
        maxWidth: 120,
      },
      {
        label: t(`${TranslationNamespaces.common}|Name`),
        accessor: "title",
      },
      {
        label: t("Serial"),
        accessor: "additional_identifications",
        Cell: ({ value }) => value.find((attr) => attr.name === "serial")?.value || null,
      },
      {
        label: t("Added On"),
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
        maxWidth: 200,
      },
      {
        label: t("Status"),
        accessor: "active",
        Cell: ({ value, original }) => (
          <div>
            <div className={b("item-status")}>{value && <Chip intent="success">{t("Active")}</Chip>}</div>
            <div className={b("item-actions")}>
              <Button
                className={b("item-action-button")}
                onClick={() =>
                  this.editIBeaconValidation(original.id, {
                    active: !original.active,
                  })
                }
              >
                {original.active ? t("Disable") : t("Enable")}
              </Button>
              <Button
                className={b("item-action-button", ["remove"])}
                onClick={() =>
                  this.editIBeaconValidation(original.id, {
                    deleted_at: new Date().toISOString(),
                  })
                }
              />
            </div>
          </div>
        ),
        align: "center" as ColumnAlign,
        maxWidth: 200,
      },
    ];

    return (
      <div className={b()}>
        <h3 className={b("list-header")}>{t("iBeacon")}</h3>
        <TablePage
          rows={ibeaconValidations}
          columns={columns}
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          className="departments-table"
          loading={!currentLocation}
          noContentComponent={<NoContent>{t("This location has no WiFi validations")}</NoContent>}
        />
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(ValidationsIBeacon);
