import { Component } from "react";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import { formatPhone, getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import { getEmployeeTeam, removeEmployeeFromTeam, addEmployeesToTeam } from "utils/apiHelpers";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import Sentry from "utils/sentryUtils";
import NotificationRow from "components/NotificationRow";
import TablePage from "components/TablePage";
import Avatar from "components/views/Avatar";
import AddEmployeeRow from "components/controls//AddEmployeeRow";
import { NotificationType } from "types/common";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import { EmployeeTeamTableData } from "types/models/employee";
import { iColumn } from "components/TableCommon";
import "components/UI/Page/Page.scss";
import "styles/table-common.scss";
import { PermissionSectionName } from "types/models/permissions";
import { MassActionLocations } from "utils/ga";
import PaginationNew from "components/PaginationNew";
import { listTeamMemberUuidsWithNesting, listUserProfilesWIthFilters } from "../../utils/api/company";

const tb = BEM.b("table-common");
const p = BEM.b("page");

interface EmployeeTeamProps extends WithTranslation {
  employeeId: number;
  employeeUuid: string;
  employeeTeamId: number;
  employeeTeamUuid: string;
}

interface EmployeeTeamState {
  loading: boolean;
  membersUuids: string[];
  members: FilteredEmployeeProfile[];
  notification: string | null;
  notificationType: NotificationType | null;
  teamId: unknown | null;
  selectedColumns: string;
  totalRecords: number;
  page: number;
  perPage: number;
}

class EmployeeTeam extends Component<EmployeeTeamProps, EmployeeTeamState> {
  state = {
    loading: true,
    membersUuids: [],
    members: [],
    notification: null,
    teamId: null,
    notificationType: null,
    selectedColumns: getSelectedColumns("employee,phone,email,cpf,supervisorName", "EmployeeTeamList"),
    totalRecords: 0,
    page: 1,
    perPage: 25,
  };

  componentDidMount() {
    void this.getAllMembersUuids();
  }

  getAllMembersUuids = async () => {
    const { employeeUuid } = this.props;
    const response = await listTeamMemberUuidsWithNesting(
      window.global_store.profile?.company?.uuid as string,
      employeeUuid,
      window.global_store?.profile?.uuid,
    );
    this.setState({ membersUuids: response.content });
    void this.getEmployeeMembers();
  };

  getEmployeeMembers = async () => {
    const { page, perPage, membersUuids } = this.state;
    const { employeeTeamId } = this.props;
    this.setState({ loading: true });
    const response = await listUserProfilesWIthFilters(window.global_store.profile?.company?.uuid as string, {
      fields: [
        "id",
        "uuid",
        "fullName",
        "avatarId",
        "employeeStatus",
        "taxPayerId",
        "matricula",
        "position.title",
        "role",
        "pis",
        "team.uuid",
        "team.name",
        "team.supervisor.fullName",
        "user.devices.phoneNumber",
        "user.userAccount.email",
      ],
      skip: (page - 1) * perPage,
      limit: perPage,
      externalFields: [],
      filterModel: {
        uuid: { filterType: "set", values: membersUuids },
        employee_status: { filterType: "set", values: ["active"] },
      },
      global: true,
      requestedBy: window.global_store?.profile?.uuid,
    });

    const members: FilteredEmployeeProfile[] = response.content || [];
    this.setState({
      members,
      teamId: employeeTeamId,
      loading: false,
      totalRecords: response.metadata.total,
    });
  };

  onNotificationReceived = (notification: string) => {
    this.setState({ notification });
  };

  onNotificationClose = () => {
    this.setState({ notification: null });
  };

  // TODO add the type
  onAddEmployeeToTeam = async (searchObj: unknown | string[]) => {
    const { t } = this.props;
    const { teamId } = this.state;

    let employee = null;
    let userProfileIds = [];

    if (!Array.isArray(searchObj)) {
      employee = searchObj.employee ? searchObj.employee : searchObj;
      userProfileIds = [searchObj.uuid];
    } else {
      userProfileIds = searchObj;
    }

    this.setState({ loading: true });

    try {
      await addEmployeesToTeam({
        teamId,
        body: {
          member_uuids: userProfileIds,
        },
      });

      let notification = null;

      if (employee) {
        notification = `${employee.full_name || employee.name || employee.fullName}${
          employee.position ? ` ${employee.position.title}` : ""
        } ${t("has been added to your team.")}`;
      } else {
        notification = `${userProfileIds.length} ${t("employees were added to team")}`;
      }

      this.setState({
        loading: false,
        notification,
        notificationType: NotificationType.success,
      });

      void this.getEmployeeMembers();
    } catch (e) {
      Sentry.sendError(e);

      this.setState({
        loading: false,
        notification: (e as Error).message || "Error",
        notificationType: NotificationType.error,
      });
    }
  };

  onRemoveEmployeeFromTeam = async (employee: EmployeeTeamTableData["employee"]) => {
    const { t, employeeId: employerId } = this.props;

    this.setState({ loading: true });

    try {
      await removeEmployeeFromTeam({ employerId, employeeId: employee.id });

      this.setState({
        loading: false,
        notification: `${employee.fullName}${employee.position ? ` ${employee.position.title}` : ""} ${t(
          "was removed from your team.",
        )}`,
        notificationType: NotificationType.success,
      });

      void this.getEmployeeMembers();
    } catch (e) {
      Sentry.sendError(e);

      this.setState({
        loading: false,
        notification: (e as Error).message || "Error",
        notificationType: NotificationType.error,
      });
    }
  };

  getTableData = (members: FilteredEmployeeProfile[]) =>
    members.map((e) => ({
      employee: {
        fullName: e.fullName || "",
        avatarId: e.avatarId || "",
        role: e.role,
        position: e.position?.title,
        id: e.id,
        team: e.team,
      },
      supervisorName: e.team?.supervisor?.fullName || "",
      phone: e.user?.devices?.phoneNumber ? formatPhone(e.user.devices.phoneNumber, true) : "",
      email: e.user?.userAccount?.email,
      cpf: e.taxPayerId,
      options: e.id,
      id: e.id,
    }));

  getTableColumns = (): iColumn<EmployeeTeamTableData>[] => {
    const { t, employeeTeamId } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    return [
      {
        label: t("Employee"),
        accessor: "employee",
        locked: true,
        Cell: (row: iCellInfo<EmployeeTeamTableData>) => (
          <div className={tb("full-name")}>
            <Avatar user={{ fullName: row.value.fullName, avatarId: row.value.avatarId }} />
            <div className={tb("name-position")}>
              <div className={tb("name")}>{row.value.fullName}</div>
              {row.value.position && <div className={tb("position")}>{row.value.position.title}</div>}
            </div>
          </div>
        ),
        minWidth: 178,
      },
      {
        label: t("Phone"),
        accessor: "phone",
        minWidth: 150,
      },
      {
        label: t("Email"),
        accessor: "email",
        minWidth: 150,
      },
      {
        label: employeeTaxIdLabel,
        accessor: "cpf",
        minWidth: 150,
      },
      {
        label: t("Supervisor"),
        accessor: "supervisorName",
        locked: true,
        Cell: (row: iCellInfo<EmployeeTeamTableData>) => {
          const isDirectEmployee = employeeTeamId === row.original.employee.team?.id;

          return (
            <div>
              <span
                style={{
                  display: "block",
                  minHeight: "22px",
                }}
              >
                {row.value}
              </span>

              {isDirectEmployee && (
                <div className={tb("buttons")}>
                  <div
                    className={tb("button", ["remove"])}
                    onClick={() => this.onRemoveEmployeeFromTeam(row.original.employee)}
                  >
                    {t("Remove")}
                  </div>
                </div>
              )}
            </div>
          );
        },
        minWidth: 150,
        style: { overflow: "visible" },
      },
    ];
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_EmployeeTeamList", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPaginationChange = (pageLimit: number, page: number) => {
    this.setState({ page, perPage: pageLimit }, () => this.getEmployeeMembers());
  };

  render() {
    const { members, loading, notification, notificationType, selectedColumns, page, perPage, totalRecords } =
      this.state;
    const data = this.getTableData(members);
    const columns = this.getTableColumns();

    return (
      <div>
        <div className={p("controls")}>
          <AddEmployeeRow
            permissionSection={PermissionSectionName.employees}
            newSearch
            trackingLocation={MassActionLocations.EmployeeProfile}
            onAddEmployee={this.onAddEmployeeToTeam}
            onAddMultipleEmployee={this.onAddEmployeeToTeam}
          />
        </div>
        {notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notificationType}
            onClose={this.onNotificationClose}
            message={notification}
          />
        )}
        <div className={p("content")}>
          <TablePage<EmployeeTeamTableData>
            loading={loading}
            rows={data}
            columns={columns}
            onColumnsChange={this.onColumnsChange}
            selectedColumns={selectedColumns.split(",")}
            showPagination
            getTrProps={() => ({
              style: {
                position: "relative",
                backgroundColor: "var(--colors-default)",
                cursor: "default",
              },
            })}
            PaginationComponent={(): JSX.Element => (
              <PaginationNew
                unlimited
                totalRecords={totalRecords}
                pageLimit={perPage}
                currentPage={page}
                onPageLimitChange={(pageLimit, currentPage) => this.onPaginationChange(pageLimit, currentPage)}
                onPageChange={(currentPage) => {
                  this.onPaginationChange(perPage, currentPage);
                }}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("employees-page")(EmployeeTeam);
