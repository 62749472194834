import styled from "styled-components";
import rtl from "styled-components-rtl";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import CONFIG from "../../config";
import GiGroup from "./img/GiGroup.png";
import GiGroup2x from "./img/GiGroup@2x.png";
import AEB from "./img/AEB.png";
import AEB2x from "./img/AEB@2x.png";
import QuintoAndar from "./img/QuintoAndar.png";
import QuintoAndar2x from "./img/QuintoAndar@2x.png";
import { logoDayIo, logoOitchau } from "./styled";

const Wrapper = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  width: 424px;
  height: 100vh;
  background: rgba(74, 144, 226, 0.1);

  &:before {
    position: absolute;
    width: 2096px;
    height: calc(100vh * 2);
    right: 0;
    top: 0;
    content: "";
    background: rgba(74, 144, 226, 0.1);
    border-radius: 50%;
    transform: rotate(180deg);
  }

  &:after {
    position: absolute;
    width: 2096px;
    height: calc(100vh * 2);
    right: 0;
    content: "";
    background: rgba(74, 144, 226, 0.1);
    border-radius: 50%;
    transform: rotate(180deg);
    bottom: 0;
  }

  @media (min-width: 1000px) {
    display: block;
  }
`;

const SidePanelBottom = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const TrustedBy = styled.div`
  margin-bottom: 12px;
  width: 100%;
  line-height: 14px;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: var(--typography-font-weight-bold);
  font-size: 10.9073px;
  text-align: center;
  text-transform: uppercase;
  color: var(--colors-unknown44);
`;

const Groups = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 60px;
`;

const GroupImg = styled.img`
  width: 100px;
  object-fit: cover;
`;

const TopRow = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  padding: 0 34px;
`;

const Logo = styled(Link)<{ dayio?: string }>`
  ${(p) => (p.dayio ? logoDayIo : logoOitchau)}
  width: 100%;
  height: 42px;
  background-repeat: no-repeat;

  ${rtl`
  background-position: left;
  `}

  @media (max-width: 799px) {
    justify-content: center;
    display: flex;
  }
`;

const SidePanel = () => {
  const { t } = useTranslation(TranslationNamespaces.signup);

  return (
    <Wrapper>
      <TopRow>
        <Logo to="/" dayio={CONFIG.globalMode ? "true" : ""} />
      </TopRow>
      <SidePanelBottom>
        <TrustedBy>{t("trusted by leading brands")}</TrustedBy>
        <Groups>
          <GroupImg
            style={{ width: "90px" }}
            src={GiGroup}
            srcSet={`
              ${GiGroup} 95w, 
              ${GiGroup2x} 190w
            `}
          />
          <GroupImg
            style={{ width: "80px" }}
            src={AEB}
            srcSet={`
              ${AEB} 95w, 
              ${AEB2x} 190w
            `}
          />
          <GroupImg
            style={{ width: "55px" }}
            src={QuintoAndar}
            srcSet={`
              ${QuintoAndar} 95w, 
              ${QuintoAndar2x} 190w
            `}
          />
        </Groups>
      </SidePanelBottom>
    </Wrapper>
  );
};

export default SidePanel;
