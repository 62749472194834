import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import RepDetails from "components/Pegador/Rep/Form";
import RepsList from "components/Pegador/Rep/List";

const RouteTimeClocks = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict exact from={path} to={`${path}/`} />
      <Redirect strict from={`${path}/:repId/`} to={`${path}/:repId`} />

      <Route exact strict path={`${path}/`} component={RepsList} />
      <Route path={`${path}/:repId`} render={({ match }) => <RepDetails repId={match.params.repId} fullPage />} />
    </Switch>
  </Suspense>
);

export default RouteTimeClocks;
