import { DayOfWeekShape } from "react-dates";

// A mapping of weekday names used by day.io API to numeric indices suitable for moment.js
export const dowDict: Record<string, DayOfWeekShape> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export function getWeekStart(now: moment.Moment, dow = 1): moment.Moment {
  const d = now.clone().weekday(dow);
  if (d.isAfter(now, "day")) {
    d.subtract(1, "week");
  }
  return d;
}
