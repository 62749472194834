import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalContext } from "context/GlobalContextProvider";
import { PermissionRole, PermissionRoleName } from "types/models/permissions";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import SelectControl from "components/UI/SelectControl";
import FieldWrapper from "components/UI/FieldWrapper";
import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import { UserProfileRole } from "types/models/userProfile";
import Tooltip from "components/UI/Tooltip";
import { translateEmployeeTerm } from "utils/translationHelpers";
import AddEditPermissionPopup, { EmployeePermission } from "./AddEditPermissionPopup";

const SectionTitle = styled.div`
  margin-top: 21px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--colors-surface-800);
`;

const Wrapper = styled.div``;

const PermissionBox = styled.div<{ $canUpdatePermissionRole: boolean }>`
  margin-top: 16px;
  border-radius: 5px;
  border: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-0);
  cursor: ${(p) => (p.$canUpdatePermissionRole ? "pointer" : "default")} !important;

  &:hover {
    .permission-box-border {
      border-color: ${(p) => (p.$canUpdatePermissionRole ? "var(--colors-surface-150)" : "transparent")};
    }
  }

  .special-permission {
    .multi-select.react-select--is-disabled {
      cursor: ${(p) => (p.$canUpdatePermissionRole ? "pointer" : "default")};
    }
    .react-select__control.react-select__control--is-disabled {
      border: 1px solid var(--colors-surface-150);
      background-color: var(--colors-surface-50);
    }
    .react-select__indicators {
      display: none;
    }
  }

  .permission-field [class*="FieldName"] {
    color: var(--colors-surface-600);
  }
`;

const PermissionBoxBorder = styled.div`
  padding: 15px;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid transparent;
`;

const Units = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 6px;
`;

const Unit = styled.div`
  background-color: var(--colors-surface-150);
  padding: 3px 8px;
  color: var(--colors-surface-700);
  border-radius: var(--shapes-border-radius-default);
  white-space: nowrap;
`;

const AddPermissionBtn = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: var(--colors-primary);
  cursor: pointer;

  span:not(.plus) {
    margin-inline-start: 6px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-medium);
  }

  .plus {
    padding-bottom: 4px;
    font-size: 25px;
  }
`;

enum EmployeePermssionsWarning {
  changingRole = "changing-role-warning",
  deletingPermission = "deleting-permission-warning",
}
interface EmployeePermssionsProps {
  role: UserProfileRole;
  permissions: EmployeePermission[];
  onAddPermission: (permission: EmployeePermission) => void;
  onDeletePermission: (permissionRoleUuid: string) => void;
  onRoleChange: (role: UserProfileRole) => void;
  canUpdatePermissionRole: boolean;
  employeeEditMode: boolean;
}

const EmployeePermssions = ({
  permissions,
  onAddPermission,
  role,
  onRoleChange,
  onDeletePermission: onDeletePerm,
  canUpdatePermissionRole,
  employeeEditMode,
}: EmployeePermssionsProps) => {
  const { t } = useTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.employeesPermissions]);
  const context = useContext(GlobalContext);
  const [permissionRoles, setPermissionRoles] = useState<PermissionRole[]>([]);
  const [isAddEditPopupOpened, setIsAddEditPopupOpened] = useState(false);
  const [permissionToUpdate, setPermissionToUpdate] = useState<EmployeePermission | null>(null);
  const [warning, setWarning] = useState<{ msg: EmployeePermssionsWarning; data: any } | null>(null);

  const supervisorPermission = permissionRoles.find((pr) => pr.name === PermissionRoleName.supervisor);

  useEffect(() => {
    void (async () => {
      const roles = await context.getRoles();
      setPermissionRoles(roles);
    })();
  }, []);

  const onCloseAddEditPopup = () => {
    setIsAddEditPopupOpened(false);
    setPermissionToUpdate(null);
  };

  const onChangeRole = (r: UserProfileRole, confirmed?: boolean) => {
    if (role !== r) {
      // explain consequences for an user :)
      if (employeeEditMode && !confirmed) {
        setWarning({ msg: EmployeePermssionsWarning.changingRole, data: r });
        return;
      }

      onRoleChange(r);
      setWarning(null);
    }
  };

  const onDeletePermission = (permissionRoleUuid: string, confirmed?: boolean) => {
    if (!confirmed) {
      setWarning({ msg: EmployeePermssionsWarning.deletingPermission, data: permissionRoleUuid });
      return;
    }

    onDeletePerm(permissionRoleUuid);
    setWarning(null);
    onCloseAddEditPopup();
  };

  return (
    <Wrapper>
      <FieldWrapper fieldName={t("Role")} width="100%">
        <SelectControl<UserProfileRole>
          options={[
            employeeEditMode ? { value: UserProfileRole.employer, label: t("Owner") } : null,
            { value: UserProfileRole.supervisor, label: t("Manager") },
            { value: UserProfileRole.employee, label: t(UserProfileRole.employee) },
          ].filter((o) => o !== null)}
          onChange={(val: UserProfileRole) => onChangeRole(val)}
          value={role}
          isSearchable={false}
          disabled={!canUpdatePermissionRole}
        />
      </FieldWrapper>

      {employeeEditMode && role !== UserProfileRole.employer && (
        <>
          <SectionTitle>{t("Permission roles")}</SectionTitle>

          {permissions.map((p, i) => {
            const toltalUnits = p.teams.length + p.departments.length + p.subsidiaries.length + p.userProfiles.length;

            return (
              <div>
                <PermissionBox
                  $canUpdatePermissionRole={canUpdatePermissionRole}
                  key={p.role.uuid}
                  onClick={(e) => {
                    e.stopPropagation();
                    // do not allow to update default permission
                    if (!canUpdatePermissionRole) {
                      return;
                    }

                    setPermissionToUpdate(p);
                  }}
                  data-tip
                  data-for={`permission-${p.role.uuid}`}
                >
                  <PermissionBoxBorder className="permission-box-border">
                    <SelectControl<string>
                      className="special-permission"
                      options={[{ value: p.role.uuid, label: p.role.name }]}
                      onChange={() => {}}
                      value={p.role.uuid}
                      disabled
                    />

                    {!p.includingAllcompany && toltalUnits === 0 ? (
                      <FieldWrapper
                        className="permission-field"
                        fieldName={t("No special access")}
                        width="100%"
                        fieldTitleMarginTop={16}
                      >
                        <></>
                      </FieldWrapper>
                    ) : (
                      <FieldWrapper
                        className="permission-field"
                        fieldName={t("Units")}
                        width="100%"
                        fieldTitleMarginTop={16}
                      >
                        <Units>
                          {p.includingAllcompany ? (
                            <Unit>{t("All company")}</Unit>
                          ) : (
                            <>
                              <Unit>{`${t("Teams")} (${p.teams.length})`}</Unit>
                              <Unit>{`${t("Departments")} (${p.departments.length})`}</Unit>
                              <Unit>{`${t("Subsidiaries")} (${p.subsidiaries.length})`}</Unit>
                              <Unit>{`${translateEmployeeTerm(
                                t,
                                TranslationNamespaces.common,
                                "custom-employees",
                                "People",
                              )} (${p.userProfiles.length})`}</Unit>
                            </>
                          )}
                        </Units>
                      </FieldWrapper>
                    )}
                  </PermissionBoxBorder>
                </PermissionBox>

                <Tooltip id={`permission-${p.role.uuid}`} effect="float" disable={!canUpdatePermissionRole}>
                  {t("permission-tooltip-text")}
                </Tooltip>
              </div>
            );
          })}

          {canUpdatePermissionRole && permissions.length < 2 && (
            <AddPermissionBtn
              onClick={(ev) => {
                ev.stopPropagation();
                setIsAddEditPopupOpened(true);
              }}
            >
              <span className="plus">+</span>
              <span>{t("Add Permission Role")}</span>
            </AddPermissionBtn>
          )}

          <SidePopupOverlay
            header={t("Add Permission Role")}
            isOpen={isAddEditPopupOpened || !!permissionToUpdate}
            onClose={onCloseAddEditPopup}
          >
            <AddEditPermissionPopup
              excludePermissionUuid={permissions[0]?.role.uuid}
              onAddPermission={(p) => {
                onAddPermission(p);
                onCloseAddEditPopup();
              }}
              onDeletePermission={permissions.length > 1 ? (id) => onDeletePermission(id) : null}
              onClose={onCloseAddEditPopup}
              permissionToUpdate={permissionToUpdate}
              hasReadOnlyTeam={
                !!permissionToUpdate &&
                permissionToUpdate.role.uuid === supervisorPermission?.uuid &&
                permissionToUpdate.teams.some((tm) => tm.readOnly)
              }
            />
          </SidePopupOverlay>

          <ModalDialog isOpen={!!warning} onClose={() => setWarning(null)}>
            {warning && (
              <Lightbox
                onClose={() => setWarning(null)}
                onYes={() => {
                  if (warning.msg === EmployeePermssionsWarning.changingRole) {
                    onChangeRole(warning.data, true);
                  } else if (warning.msg === EmployeePermssionsWarning.deletingPermission) {
                    onDeletePermission(warning.data, true);
                  }
                }}
                title=""
                buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
                buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
                text={t(
                  warning.msg === EmployeePermssionsWarning.changingRole
                    ? `${warning.msg}-${warning.data}`
                    : warning.msg,
                )}
              />
            )}
          </ModalDialog>
        </>
      )}
    </Wrapper>
  );
};

export default EmployeePermssions;
