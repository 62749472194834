import { Component, createRef } from "react";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import moment from "moment";
import { TFunction } from "react-i18next";
import { PayPeriod, PayPeriodRange } from "types/models/payrollLocks";
import YearSelector from "./YearSelector";

const Wrapper = styled.div`
  overflow: auto;
  position: absolute;
  display: flex;
  margin-top: 5px;
  z-index: 10;
  text-align: start;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-default);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
`;

const MainPanel = styled.div`
  max-width: 222px;
  min-width: 218px;
  padding-top: 20px;
  padding-bottom: 18px;
`;

const PeriodsPanel = styled.div`
  max-width: 222px;
  min-width: 218px;
  padding-top: 20px;
  padding-bottom: 18px;
  border-inline-start: 1px solid #dde5ee;
`;

const MonthsSelector = styled.div`
  overflow: auto;
  margin-top: 12px;
  padding: 0 12px 0 16px;
  max-height: 240px;
`;

const PeriodsListTitle = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  padding-inline-start: 26px;
  line-height: 15px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  text-transform: uppercase;
  color: #a1b2cf;
`;

const Item = styled.div<{ extenddable?: boolean; isRTL?: boolean }>`
  overflow: hidden;
  position: relative;
  margin-bottom: 4px;
  padding-inline-end: 28px;
  padding-inline-start: 14px;
  line-height: 36px;
  font-size: 15px;
  letter-spacing: -0.1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);
  text-transform: capitalize;
  color: ${(p) => (p.extenddable ? "#525f7f" : "#8193AB")};

  &.selected,
  &:hover {
    background: var(--colors-primary);
    color: var(--colors-default);

    &:after {
      ${(p) => (p.extenddable ? "display: block;" : "display: none;")}
    }
  }

  &:after {
    display: none;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    height: 100%;
    width: 40px;
    content: "";
    ${(p) => (p.isRTL ? "transform: rotate(180deg);" : "")}
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.768 3.954a.79.79 0 01-.015 1.133l-3.29 3.176a.863.863 0 01-1.19 0 .791.791 0 010-1.148L2.97 4.513.246 1.884a.791.791 0 010-1.146.863.863 0 011.19 0l3.292 3.175a.83.83 0 01.04.041z' fill='%23fff'/%3E%3C/svg%3E");
  }
`;

interface DatePeriodsDropdownProps {
  t: TFunction;
  periods: PayPeriod[];
  onChange: (range: PayPeriodRange) => void;
}

interface DatePeriodsDropdownState {
  selectedYear: number | null;
  selectedMonth: number | null;
  position: Record<"left" | "right" | "top" | "bottom", string>;
}

class DatePeriodsDropdown extends Component<DatePeriodsDropdownProps, DatePeriodsDropdownState> {
  root = createRef<HTMLDivElement>();
  rangeStrFormat: string;

  constructor(props: DatePeriodsDropdownProps) {
    super(props);

    this.state = {
      selectedYear: null,
      selectedMonth: null,
      position: {
        left: "0",
        top: "auto",
        right: "auto",
        bottom: "auto",
      },
    };

    let rangeStrFormat = "MMM DD";
    // for Brasil different format
    if (window.global_store.company && window.global_store.company.billing_info.country.toLowerCase() === "br") {
      rangeStrFormat = "DD MMM";
    }

    this.rangeStrFormat = rangeStrFormat;
  }

  componentDidMount() {
    const top = "auto";
    const left = "auto";
    let right = "auto";
    let bottom = "auto";

    if (this.hasRightOverflow()) {
      right = "0";
    }

    if (this.hasBottomOverflow()) {
      bottom = "53px";
    }

    this.setState({ position: { left, right, top, bottom } });
  }

  hasRightOverflow = () =>
    this.root.current && this.root.current.getBoundingClientRect().right + 10 > window.innerWidth;

  hasBottomOverflow = () =>
    this.root.current && this.root.current.getBoundingClientRect().bottom + 50 > window.innerHeight;

  getRangeStr = (range: PayPeriodRange) => {
    if (!range) {
      return "";
    }

    return `${moment(range.start, "YYYY-MM-DD").format(this.rangeStrFormat)} - ${moment(range.end, "YYYY-MM-DD").format(
      this.rangeStrFormat,
    )}`;
  };

  render() {
    const { t, periods } = this.props;
    const { position, selectedMonth } = this.state;
    const selectedYear = this.state.selectedYear || periods[periods.length - 1]?.year;
    const selectedPer = periods.find((p) => p.year === selectedYear);
    const months = selectedPer?.months || [];
    const ranges = selectedMonth ? months.find((month) => month.month === selectedMonth)?.ranges || [] : [];

    return (
      <Wrapper ref={this.root} style={position}>
        <MainPanel>
          <YearSelector
            year={selectedYear}
            years={periods.map((p) => p.year)}
            onYearChange={(year) => {
              this.setState({ selectedYear: year, selectedMonth: null });
            }}
          />
          <MonthsSelector>
            {months.map((month) => (
              <Item
                isRTL={window.global_store.isRTL}
                extenddable
                key={month.month}
                className={month.month === selectedMonth ? "selected" : ""}
                onMouseEnter={() => this.setState({ selectedMonth: month.month })}
              >
                {moment()
                  .month(month.month - 1)
                  .format("MMMM")}
              </Item>
            ))}
          </MonthsSelector>
        </MainPanel>

        {!!ranges.length && (
          <PeriodsPanel key={`${selectedMonth} - ${ranges.length}`}>
            <PeriodsListTitle>{t("Payroll lock period")}</PeriodsListTitle>
            <MonthsSelector>
              {[...ranges]
                .sort((a, b) => +b.isGroupActive - +a.isGroupActive)
                .map((range) => {
                  const rangeStr = this.getRangeStr(range);

                  return (
                    <Item key={rangeStr} onClick={() => this.props.onChange(range)}>
                      {rangeStr}
                    </Item>
                  );
                })}
            </MonthsSelector>
          </PeriodsPanel>
        )}
      </Wrapper>
    );
  }
}

export default DatePeriodsDropdown;
