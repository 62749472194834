import { useQuery } from "react-query";
import { useContext } from "react";
import { getSchedules } from "utils/apiHelpers";
import GlobalContext from "context/global-context";
import SelectControl from "components/UI/SelectControl";
import { useHistory } from "react-router-dom";

const useSchedules = () => {
  const STALE_TIME = 1000 * 5;
  const context = useContext(GlobalContext);
  const result = useQuery(
    `rq_schedules`,
    async () => {
      const company = await context.getCompany();
      const response = await getSchedules({ companyUuid: company.uuid, params: { perPage: 10000, active: true } });

      return response.content;
    },
    { staleTime: STALE_TIME, retry: false },
  );

  return result;
};

type SchedulesDropdownProps = {
  selectedItem: string | null;
  employeeCurrentScheduleUuid: string | null;
  onChange: (uuid: string) => void;
};

const SchedulesDropdown = ({ selectedItem, onChange, employeeCurrentScheduleUuid }: SchedulesDropdownProps) => {
  const history = useHistory();
  const { data: schedules } = useSchedules();

  const options =
    schedules?.map((schedule) => ({
      label: schedule.name,
      value: schedule.uuid,
      isHighlighted: employeeCurrentScheduleUuid === schedule.uuid,
      onViewClick: (scheduleUuid: string) => window.open(`/schedules/${scheduleUuid}`, "_blank"),
    })) || [];

  return (
    <SelectControl
      options={options}
      onChange={(val) => {
        onChange(val as string);
      }}
      value={selectedItem}
    />
  );
};

export default SchedulesDropdown;
