import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SettingsLayout from "components/Layout/SettingsLayout";
import { getApplications } from "utils/apiHelpers";
import BEM from "utils/BEM";
import { getTitle } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import styled from "styled-components";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { useProfile } from "context/user-profile-context";
import { useInitialLoading } from "utils/useInitialLoading";
import { Content, SectionTitle } from "./styled";
import IntegrationBox from "./IntegrationBox";
import { ApplicationsService } from "./ApplicationsService";
import InstalledIntegrationsTable from "./InstalledIntegrationsTable";
import BrowserExtensionRow from "./BrowserExtensionRow";
import InternalToolsRow from "./InternalToolsRow";

const p = BEM.b("page");

const NativeIntegrationsTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const NativeIntegrations = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const ApplicationsPage = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const profile = useProfile();

  const [loadApplications, loadingApplications, applications = []] = useAsyncCallback(async () => {
    const res = await getApplications(profile.company.uuid);
    return res.content;
  }, [profile]);
  useEffect(() => void loadApplications(), [loadApplications]);

  useEffect(() => {
    document.title = getTitle("Apps");
  }, []);

  const initialLoading = useInitialLoading(loadingApplications);
  const loading = initialLoading || loadingApplications;

  return (
    <SettingsLayout title={t("Application central")}>
      <div className={p()}>
        <Content>
          <BrowserExtensionRow />
          {!loading && !!applications?.length && (
            <InstalledIntegrationsTable applications={applications} onRemove={loadApplications} />
          )}
          <InternalToolsRow />
          <NativeIntegrationsTitleRow>
            <SectionTitle>{t("Native integrations")}</SectionTitle>
            {/* <LinkWithIcon className="explore-apps">{t("explore-all-apps")}</LinkWithIcon> */}
          </NativeIntegrationsTitleRow>
          {!loading && (
            <NativeIntegrations>
              {ApplicationsService.settingsPageIntegrations
                .filter((integration) => !applications.some((a) => a.type === integration.name))
                .map((integration) => (
                  <IntegrationBox
                    type={integration.name}
                    key={integration.name}
                    application={applications.find((a) => a.type === integration.name)}
                  />
                ))}
            </NativeIntegrations>
          )}
        </Content>
      </div>
    </SettingsLayout>
  );
};

export default ApplicationsPage;
