import * as moment from "moment";
import { BasedOn } from "components/Schedules/Breaks/BreakModal";
import { calculateTotalWorkingHoursWithWorkingBreaksForDay } from "components/Schedules/helpers";
import { minsToHrsMins } from "./common";

export function getStartEndRange(entry, exit) {
  const entryHrs = parseInt(entry.split(":")[0], 10);
  const entryMins = parseInt(entry.split(":")[1], 10);
  const exitHrs = parseInt(exit.split(":")[0], 10);
  const exitMins = parseInt(exit.split(":")[1], 10);
  const start = moment(`${entryHrs}:${entryMins}`, "HH:mm");
  const end = moment(`${exitHrs}:${exitMins}`, "HH:mm");

  if (exitHrs < entryHrs || (exitHrs === entryHrs && exitMins < entryMins) || entry === exit) {
    end.add(1, "day");
  }

  return { start, end };
}

export function getExtraHours(start, end) {
  let extraHours = 0;
  const nsStart = moment("22:00", "HH:mm");
  if (start.hours() < 22 && start.hours() > 5 && end.hours() < 22 && end.hours() > 5 && start.isSame(end, "day")) {
    extraHours = 0;
  } else if (
    start.hours() < 22 &&
    start.hours() > 5 &&
    end.hours() > 22 &&
    end.hours() > 5 &&
    start.isSame(end, "day")
  ) {
    extraHours = Math.abs(nsStart.diff(end, "minutes"));
  } else if (start.hours() < 22 && start.hours() > 5 && !start.isSame(end, "day")) {
    extraHours = Math.abs(nsStart.diff(end, "minutes"));
  } else if (start.hours() >= 22 && end.hours() <= 23 && start.isSame(end, "day")) {
    extraHours = Math.abs(start.diff(end, "minutes"));
  } else if (start.hours() >= 22 && !start.isSame(end, "day")) {
    extraHours = Math.abs(start.diff(end, "minutes"));
  } else if (start.hours() < 5 && start.isSame(end, "day")) {
    extraHours = Math.abs(start.diff(end, "minutes"));
  }

  return extraHours;
}

const validateTime = (val) => {
  let valid = true;
  if (!val) {
    valid = true;
  } else if (val.indexOf(":") === -1) {
    valid = false;
  } else {
    const hh = val.split(":")[0];
    const mm = val.split(":")[1];
    if (!mm || !hh) {
      valid = false;
    } else if (hh > -1 && hh < 24 && mm > -1 && mm < 60) {
      valid = true;
    } else {
      valid = false;
    }
  }
  return valid;
};

export function validateDayScheduleWeekly(day) {
  day.entry.error = !validateTime(day.entry.value);
  day.exit.error = !validateTime(day.exit.value);
  if (day.breaks.length > 0) {
    let brks = Object.assign([], day.breaks);
    brks = brks.map((brk) => {
      const newEl = { ...brk };
      newEl.start.error = !validateTime(newEl.start.value);
      newEl.end.error = !validateTime(newEl.end.value);
      if (!newEl.start.error && !newEl.end.error) {
        if (newEl.start.value && !newEl.end.value) {
          newEl.end.error = true;
        } else if (!newEl.start.value && newEl.end.value) {
          newEl.start.error = true;
        } else if (newEl.start.value && newEl.end.value && newEl.start.value === newEl.end.value) {
          newEl.end.error = true;
          newEl.start.error = true;
        }
      }
      return newEl;
    });
    day.breaks = brks;
  }

  if (day.entry.value && !day.exit.value) {
    day.exit.error = true;
  }
  if (day.exit.value && !day.entry.value) {
    day.entry.error = true;
  }

  if (day.working) {
    if (!day.entry.value && !day.exit.value) {
      day.entry.error = true;
      day.exit.error = true;
    }
  }
  const hasError =
    day.entry.error ||
    day.exit.error ||
    (day.breaks.length > 0 && day.breaks.filter((brk) => brk.start.error || brk.end.error).length > 0);
  return !hasError;
}

function startEndToTableData(entries, exits) {
  let events = [];
  if (exits && exits.length > 0) {
    events = exits.map((b, i) => {
      let eventStartTime = parseInt(entries[i].time, 10);
      let eventEndTime = parseInt(b.time, 10);
      eventStartTime = eventStartTime >= 1440 ? eventStartTime - 1440 : eventStartTime;
      eventEndTime = eventEndTime >= 1440 ? eventEndTime - 1440 : eventEndTime;
      const event = {
        breakDuration: b.duration,
      };
      if (b.time) {
        event.start = {
          value: minsToHrsMins(eventStartTime),
          error: false,
        };
        event.end = {
          value: minsToHrsMins(eventEndTime),
          error: false,
        };
      } else {
        event.start = {};
        event.end = {};
      }
      return event;
    });
  }
  return events;
}

export function getDataTableRegular(scheduleDays, exception) {
  return scheduleDays.map((day) => {
    const newEl = {
      entry: {
        value: "",
        error: false,
      },
      exit: {
        value: "",
        error: false,
      },
      extraNight: 0,
      isDsrDay: Boolean(day.isDsrDay),
      breaks: [],
      extraEntryExit: [],
      hasData: false,
      total: day.shiftDuration + calculateTotalWorkingHoursWithWorkingBreaksForDay(day.events),
      id: day.dayId,
      working: day.working,
    };

    if (exception) {
      newEl.hoursPerDay = day.hoursPerDay || "";
      newEl.type = day.type || "regular";
    }
    const entries = getEventsByType(day.events, "entry");
    const exits = getEventsByType(day.events, "exit");
    const entry = entries[0];
    const exit = exits[0];
    const breakStart = getEventsByType(day.events, "break_start");
    const breakEnd = getEventsByType(day.events, "break_end");

    newEl.breaks = Object.assign([], getBreaks(breakStart, breakEnd));

    if (exits && exits.length > 1) {
      newEl.extraEntryExit = Object.assign([], startEndToTableData(entries.splice(1), exits.splice(1)));
    }

    if (entry && exit) {
      newEl.entry = {
        value: minsToHrsMins(entry.time),
        error: false,
      };
      newEl.exit = {
        value: exit.time >= 1440 ? minsToHrsMins(exit.time - 1440) : minsToHrsMins(exit.time),
        error: false,
      };
      const range = getStartEndRange(newEl.entry.value, newEl.exit.value);
      const extraHours = getExtraHours(range.start, range.end);

      newEl.extraNight = extraHours;
    }

    return newEl;
  });
}

export function populateScheduleWithBreakData(tableData, customBreaksList, automaticBreak) {
  const defaultBreak = customBreaksList.find((c) => c.default === true);
  const newTableData = tableData.map((day) => {
    const breaks = day.breaks.map((b) => {
      const newBreak = { ...b };
      const customBreak = customBreaksList.find((c) => c.uuid === b.breakTypeUuid);

      if (b.breakMode === undefined) {
        newBreak.breakMode = b.start?.value === null || b.start?.value === undefined ? BasedOn.duration : BasedOn.range;
      }
      if (b.automatic === undefined) {
        newBreak.automatic = automaticBreak;
      }
      if (b.breakTypeUuid === undefined) {
        newBreak.breakTypeUuid = defaultBreak.uuid;
      }
      newBreak.isWorkingHours = !!customBreak?.isWorkingHours;
      newBreak.breakTypeName = customBreak?.name || defaultBreak.name;

      return newBreak;
    });

    return { ...day, breaks };
  });

  return newTableData;
}

function getBreaks(breakStartList, breakEndList) {
  let events = [];
  const flexibleBreaksByBreakTypeUuid = [];

  if (breakEndList?.length) {
    events = breakEndList.map((endEvent, i) => {
      const startEvent = breakStartList[i];

      let eventStartTime = parseInt(startEvent.time, 10);
      let eventEndTime = parseInt(endEvent.time, 10);
      eventStartTime = eventStartTime >= 1440 ? eventStartTime - 1440 : eventStartTime;
      eventEndTime = eventEndTime >= 1440 ? eventEndTime - 1440 : eventEndTime;

      const event = {
        breakDuration: endEvent.duration,
        automatic: startEvent.automatic,
        breakMode: startEvent.breakMode,
        startAfter: startEvent.startAfter,
        breakTypeUuid: startEvent.breakTypeUuid,
        breakTypeName: startEvent.breakTypeName,
        isWorkingHours: startEvent.isWorkingHours,
      };

      if (endEvent.time !== null) {
        event.start = {
          value: eventStartTime !== null ? minsToHrsMins(eventStartTime) : null,
          error: false,
        };
        event.end = {
          value: eventEndTime !== null ? minsToHrsMins(eventEndTime) : null,
          error: false,
        };
      } else {
        event.start = {};
        event.end = {};
      }

      if (event.breakMode === BasedOn.flexible) {
        const breakTypeUuidIndex = flexibleBreaksByBreakTypeUuid.findIndex(
          (e) => e.breakTypeUuid === event.breakTypeUuid,
        );

        if (breakTypeUuidIndex > -1) {
          flexibleBreaksByBreakTypeUuid[breakTypeUuidIndex].eventsCount += 1;
        } else {
          flexibleBreaksByBreakTypeUuid.push({
            breakTypeUuid: event.breakTypeUuid,
            breakTypeName: event.breakTypeName,
            eventsCount: 1,
          });
        }

        return null;
      }

      return event;
    });
  }

  events = events.filter((e) => e !== null);

  const flexibleBreaks = flexibleBreaksByBreakTypeUuid.map((flexBreak) => ({
    breakDuration: null,
    automatic: false,
    breakMode: BasedOn.flexible,
    breakTypeUuid: flexBreak.breakTypeUuid,
    breakTypeName: flexBreak.breakTypeName,
    eventsCount: flexBreak.eventsCount,
    isWorkingHours: false,
    start: {},
    end: {},
  }));

  return events.concat(flexibleBreaks);
}

function getDataTableFlexible(scheduleDays) {
  const rows = {};

  scheduleDays.map((day, i) => {
    const flexibleDay = { id: day.dayId, extraEntryExit: [], hoursPerDay: 0, breaks: [], isDsrDay: day.isDsrDay };

    if (day.working) {
      flexibleDay.working = true;

      const breakStart = getEventsByType(day.events, "break_start");
      const breakEnd = getEventsByType(day.events, "break_end");
      const entries = getEventsByType(day.events, "entry");
      const exits = getEventsByType(day.events, "exit");
      const exit = exits[0];

      flexibleDay.hoursPerDay = exit.duration;
      flexibleDay.breaks = Object.assign([], getBreaks(breakStart, breakEnd));
      if (exits && exits.length > 1) {
        flexibleDay.extraEntryExit = Object.assign([], startEndToTableData(entries.splice(1), exits.splice(1)));
      }
    }

    rows[day.dayId] = [flexibleDay];

    return flexibleDay;
  });

  const tableData = [];

  Object.keys(rows).map((key, i) => {
    const row = rows[key];
    const tableRow = {
      id: i,
      hoursPerDay: {
        value: minsToHrsMins(row[0].hoursPerDay),
        raw: row[0].hoursPerDay,
        error: false,
      },
      isDsrDay: row[0].isDsrDay,
    };
    if (row[0].breaks) {
      tableRow.breaks = row[0].breaks;
    }
    if (row[0].extraEntryExit) {
      tableRow.extraEntryExit = row[0].extraEntryExit;
    }

    const week = [...Array(7)].map((e, it) => ({ id: it + 1 }));
    row.map((d) => {
      week[d.id - 1].working = !!row[0].hoursPerDay;
      return "";
    });
    tableRow.daysOfWeek = week;
    let breaksDuration = 0;
    if (row[0].breaks && row[0].breaks.length) {
      breaksDuration = row[0].breaks.filter((e) => !e.isWorkingHours).reduce((a, b) => a + b.breakDuration, 0);
    }

    const workingHours = row[0].hoursPerDay - breaksDuration;
    tableRow.total = workingHours * week.filter((d) => d.working).length;
    tableData.push(tableRow);
    return "";
  });
  return tableData;
}

export function getDataTable(schedule, exception) {
  if (exception) {
    return getDataTableRegular(schedule.scheduleDays, true);
  }
  if (schedule.scheduleType === "regular" && schedule.scheduleDays) {
    return getDataTableRegular(schedule.scheduleDays);
  }
  if (schedule.scheduleType === "flexible" && schedule.scheduleDays) {
    return getDataTableFlexible(schedule.scheduleDays);
  }
  if (schedule.scheduleType === "shifts" && schedule.scheduleDays) {
    return getDataTableRegular(schedule.scheduleDays);
  }

  return [];
}

function updateTotals(row) {
  const newEl = { ...row };
  if (newEl.daysOfWeek) {
    let breaksDuration = 0;

    if (newEl.breaks && newEl.breaks.length) {
      breaksDuration = newEl.breaks.filter((e) => !e.isWorkingHours).reduce((a, b) => a + b.breakDuration, 0);
    }

    const minutesPerDay = newEl.hoursPerDay ? parseInt(newEl.hoursPerDay.raw, 10) : 0;
    newEl.total = (minutesPerDay - breaksDuration) * newEl.daysOfWeek.filter((d) => d.working).length;
  }

  return newEl;
}

export function getEventsByType(events = [], eventType) {
  return events && events.length > 0 && events.filter((e) => e.type === eventType).length > 0
    ? events.filter((e) => e.type === eventType)
    : [];
}

export function clearDayRegular(dayId, tableData) {
  tableData = tableData.map((el) =>
    el.id === dayId
      ? {
          entry: {
            value: "",
            error: false,
          },
          exit: {
            value: "",
            error: false,
          },
          extraNight: 0,
          isDsrDay: false,
          breaks: [],
          extraEntryExit: [],
          hasData: false,
          total: 0,
          id: dayId,
        }
      : el,
  );

  return tableData;
}

export function copyToNextDayRegular(currentDayId, tableData) {
  if (tableData[currentDayId]) {
    const newTableData = Object.assign([], tableData);
    const currentRow = { ...tableData[currentDayId - 1] };

    newTableData[currentDayId] = {
      entry: { ...currentRow.entry },
      exit: { ...currentRow.exit },
      extraNight: currentRow.extraNight,
      isDsrDay: false,
      breaks: JSON.parse(JSON.stringify(currentRow.breaks)),
      extraEntryExit: JSON.parse(JSON.stringify(currentRow.extraEntryExit)),
      hasData: currentRow.hasData,
      total: currentRow.total,
      id: currentDayId + 1,
    };
    return newTableData;
  }
  return [];
}

export function addBreak(id, tableData, breakId) {
  const brk = {
    breakDuration: 60,
    start: {
      value: "",
      error: false,
    },
    end: {
      value: "",
      error: false,
    },
  };
  const row = tableData.find((r) => r.id === id);

  if (!row) {
    return tableData;
  }
  row.breaks.splice(breakId + 1, 0, brk);

  const newEl = { ...row };

  if (newEl.breaks && newEl.breaks.length > 0) {
    newEl.breaks.map((br) => {
      if (br.breakDuration && !br.isWorkingHours) {
        newEl.total -= parseInt(br.breakDuration, 10);
      }

      return br;
    });
  }

  return tableData.map((el) => (el.id === id ? updateTotals(newEl) : el));
}

export function removeBreak(id, tableData, breakId) {
  const rows = tableData.map((el) => {
    const newEl = el;
    let removedBreak = null;

    if (el.id === id) {
      [removedBreak] = newEl.breaks.splice(breakId, 1);
    }

    if (newEl.daysOfWeek) {
      return updateTotals(newEl);
    }

    if (removedBreak?.breakDuration && !removedBreak.isWorkingHours) {
      newEl.total += parseInt(removedBreak.breakDuration, 10);
    }

    return newEl;
  });

  return rows;
}

export function addAdditionalEntryExit(id, tableData, breakId) {
  const brk = {
    breakDuration: null,
    start: {
      value: "",
      error: false,
    },
    end: {
      value: "",
      error: false,
    },
  };
  const row = tableData.filter((r) => r.id === id)[0];

  if (!row) {
    return tableData;
  }
  row.extraEntryExit.splice(breakId + 1, 0, brk);

  const newEl = { ...row };

  if (newEl.extraEntryExit && newEl.extraEntryExit.length > 0) {
    newEl.extraEntryExit.map((br) => br);
  }

  return tableData.map((el) => (el.id === id ? updateTotals(newEl) : el));
}

export function removeAdditionalEntryExit(id, tableData, breakId) {
  const rows = tableData.map((el) => {
    const newEl = el;
    if (el.id === id) {
      newEl.extraEntryExit.splice(breakId, 1);
    }

    return updateTotals(newEl);
  });

  return rows;
}

export function removeDay(id, tableData) {
  tableData = tableData.filter((td) => td.id !== id);

  return tableData.map((el, i) => {
    const newEl = el;
    newEl.id = i + 1;
    return { ...newEl };
  });
}

export function addDay(isWorkDay, tableData) {
  tableData.push({
    entry: {
      value: "",
      error: false,
    },
    exit: {
      value: "",
      error: false,
    },
    extraNight: 0,
    isDsrDay: false,
    breaks: [],
    extraEntryExit: [],
    hasData: false,
    total: 0,
    id: tableData.length + 1,
    working: isWorkDay,
  });

  return tableData;
}

export function toggleDay(rowId, dayId, tableData) {
  tableData = tableData.map((el) => {
    const resultEl = el;
    if (el.id === rowId) {
      resultEl.daysOfWeek = resultEl.daysOfWeek.map((d) => {
        if (d.id === dayId) {
          d.working = !d.working;
        }
        return d;
      });
    }
    return updateTotals(resultEl);
  });
  return tableData;
}

export function getScheduleObjFlexible(tableData, schedule) {
  const days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const scheduleObj = {
    scheduleType: schedule.scheduleType,
    name: schedule.name,
    active: true,
    recurrencePeriod: "weekly",
    options: {},
    scheduleDays: [],
  };

  const week = [...Array(7)].map((e, i) => ({
    dayId: i + 1,
    dayName: days[i + 1],
    working: false,
    events: [],
    isDsrDay: false,
  }));

  tableData.map((r) => {
    week[r.id].isDsrDay = r.isDsrDay;
    r.daysOfWeek.map((d) => {
      if (d.working) {
        week[d.id - 1].working = true;
        const eventEntry = {
          type: "entry",
          time: null,
          duration: null,
          tolerance: null,
          validity: null,
          obligatory: true,
        };
        const eventBreakStart = {
          type: "break_start",
          time: null,
          duration: null,
          tolerance: null,
          validity: null,
          obligatory: false,
        };
        const eventBreakEnd = {
          type: "break_end",
          time: null,
          duration: null,
          tolerance: null,
          validity: null,
          obligatory: false,
        };
        const eventExit = {
          type: "exit",
          time: null,
          duration: r.hoursPerDay.value ? convertTimeToMinutes(r.hoursPerDay.value) : null,
          tolerance: null,
          validity: null,
          obligatory: true,
        };

        const breaks = getBreaksEvents({ ...d, breaks: r.breaks });

        const extraEntryExit = [];
        if (r.extraEntryExit && r.extraEntryExit.length > 0) {
          r.extraEntryExit.map((event) => {
            const start = JSON.parse(JSON.stringify(eventBreakStart));
            start.type = "entry";
            const end = JSON.parse(JSON.stringify(eventBreakEnd));
            end.type = "exit";

            end.duration = event.breakDuration;

            if (event.start.value && event.end.value) {
              start.time = convertTimeToMinutes(event.start.value);
              end.time = convertTimeToMinutes(event.end.value);
              if (end.time < start.time) {
                end.time = 1440 + end.time;
              }
            }
            extraEntryExit.push(start, end);
            return event;
          });
        }
        week[d.id - 1].events.push(eventEntry);
        week[d.id - 1].events = week[d.id - 1].events.concat(breaks);
        week[d.id - 1].events.push(eventExit);
        week[d.id - 1].events = week[d.id - 1].events.concat(extraEntryExit);
      }
      return "";
    });
    return "";
  });
  scheduleObj.scheduleDays = week;

  return scheduleObj;
}

function getExtraEntryExitEvents(day) {
  const events = [];
  if (day.extraEntryExit && day.extraEntryExit.length > 0) {
    day.extraEntryExit.map((eNeX) => {
      const start = {
        type: "entry",
        time: null,
        duration: null,
        tolerance: null,
        validity: null,
        obligatory: false,
      };
      const end = {
        type: "exit",
        time: null,
        duration: eNeX.breakDuration,
        tolerance: null,
        validity: null,
        obligatory: false,
      };
      if (eNeX.start.value && eNeX.end.value) {
        const startTime = convertTimeToMinutes(eNeX.start.value);
        const endTime = convertTimeToMinutes(eNeX.end.value);
        start.time = startTime;
        end.time = endTime;
      }
      events.push(start, end);
      return "";
    });
  }
  return events;
}

function getBreaksEvents(day, entry) {
  const events = [];
  if (day.breaks && day.breaks.length > 0) {
    day.breaks.map((brk) => {
      const breakStart = {
        type: "break_start",
        time: null,
        duration: null,
        tolerance: null,
        validity: null,
        obligatory: false,
        breakMode: brk.breakMode,
        automatic: brk.automatic,
        isWorkingHours: brk.isWorkingHours,
        breakTypeUuid: brk.breakTypeUuid,
      };
      const breakEnd = {
        type: "break_end",
        time: null,
        duration: brk.breakDuration,
        tolerance: null,
        validity: null,
        obligatory: false,
        breakTypeUuid: brk.breakTypeUuid,
      };

      if (brk.breakMode === BasedOn.flexible) {
        // for brk.eventsCount times push to evetns array
        for (let i = 0; i < brk.eventsCount; i += 1) {
          events.push({ ...breakStart }, { ...breakEnd });
        }
      } else {
        let breakStartTime = null;
        let breakEndTime = null;

        if (brk.start.value && brk.end.value) {
          breakStartTime = convertTimeToMinutes(brk.start.value);
          breakEndTime = convertTimeToMinutes(brk.end.value);

          if (entry) {
            if (breakStartTime < entry.time) {
              breakStartTime = 1440 + breakStartTime;
            }
            if (breakEndTime < entry.time) {
              breakEndTime = 1440 + breakEndTime;
            }
          } else if (breakEndTime < breakStartTime) {
            breakEndTime = 1440 + breakEndTime;
          }
        }

        if (brk.breakMode === BasedOn.range) {
          breakStart.time = breakStartTime;
        } else {
          breakStart.time = null;

          if (brk.startAfter) {
            breakStart.startAfter = brk.startAfter;
          }
        }

        breakEnd.time = breakEndTime;

        events.push(breakStart, breakEnd);
      }

      return "";
    });
  }
  return events;
}

function getEventsForDay(day) {
  let events = [];
  const entry = {
    type: "entry",
    time: day.entry && day.entry.value ? convertTimeToMinutes(day.entry.value) : null,
    duration: null,
    tolerance: null,
    validity: null,
    obligatory: true,
  };
  events.push(entry);
  events = events.concat(getBreaksEvents(day, entry));

  let duration = null;
  if (day.hoursPerDay) {
    if (day.hoursPerDay.indexOf(":") > 0) {
      duration = convertTimeToMinutes(day.hoursPerDay);
    } else {
      duration = parseInt(day.hoursPerDay, 10) * 60;
    }

    // BE validation accepts null or integer > 0
    duration = duration || null;
  }

  const exit = {
    type: "exit",
    time: day.exit && day.exit.value ? convertTimeToMinutes(day.exit.value) : null,
    duration,
    tolerance: null,
    validity: null,
    obligatory: true,
  };
  if ((exit.time || exit.time === 0) && exit.time <= entry.time) {
    exit.time = 1440 + exit.time;
  }
  events.push(exit);
  events = events.concat(getExtraEntryExitEvents(day));
  return events;
}

export function getScheduleObjRegular(tableData, schedule) {
  const days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const scheduleObj = {
    CompanyUuid: window.global_store.company.uuid,
    createdBy: window.global_store.profile.uuid,
    scheduleType: schedule.scheduleType,
    name: schedule.name,
    active: true,
    recurrencePeriod: schedule.scheduleType === "shifts" ? "daily" : "weekly",
    options: {},
    scheduleDays: [],
  };

  tableData.map((d, i) => {
    const newScheduleDay = {
      dayId: d.id,
      dayName: days[d.id],
      working: false,
      isDsrDay: Boolean(d.isDsrDay),
      events: [],
    };

    if (d.entry.value) {
      newScheduleDay.working = true;
    }
    newScheduleDay.events = newScheduleDay.working ? getEventsForDay(d) : [];
    scheduleObj.scheduleDays.push(newScheduleDay);
    return "";
  });

  return scheduleObj;
}

export function getExceptionDaysFromTableData(tableData) {
  const days = ["", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const scheduleDays = [];
  for (const td of tableData) {
    let day = {};
    day.dayId = parseInt(td.id, 10);
    day.dayName = days[day.dayId];
    day.working = td.working;
    day.events = [];

    if (td.type === "flexible") {
      delete td.entry;
      delete td.exit;
      day.events = getEventsForDay(td);
      day.type = td.type;
    } else if (td.type === "noexception") {
      day = null;
    } else if (td.type === "regular") {
      day.type = td.type;
      day.events = td.working ? getEventsForDay(td) : [];
    }
    if (day) {
      scheduleDays.push(day);
    }
  }
  return scheduleDays;
}

export function getScheduleExceptionForCreate({ tableData, name, startDate, endDate, UserProfileUuids }) {
  return {
    CompanyUuid: window.global_store.company.uuid,
    name,
    status: "active",
    UserProfileUuids,
    dateType: endDate && endDate.format("YYYY-MM-DD") !== startDate.format("YYYY-MM-DD") ? "multiple" : "single",
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate ? endDate.format("YYYY-MM-DD") : startDate.format("YYYY-MM-DD"),
    createdBy: window.global_store.profile.uuid,
    options: {
      businessRules: {},
      confines: {
        toleranceType: "detailed",
        validityExitLate: 60,
        toleranceEntryLate: 15,
        toleranceExitEarly: 15,
        validityEntryEarly: 60,
        toleranceBreakEndLate: 60,
        toleranceBreakStartEarly: 60,
      },
    },
    scheduleDays: getExceptionDaysFromTableData(tableData),
  };
}

export function convertTimeToMinutes(time) {
  return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
}

export function validateScheduleRegular(tableData, isException) {
  tableData = tableData.slice();
  let valid = true;
  let atLeastOneHasData = false;

  tableData = tableData.map((sch) => {
    const schedule = { ...sch };
    if ((!schedule.type || schedule.type === "regular") && !validateDayScheduleWeekly(schedule)) {
      valid = false;
    }
    if (schedule.entry && schedule.entry.value) {
      atLeastOneHasData = true;
    }

    return schedule;
  });

  if (!isException && !atLeastOneHasData) {
    tableData[0].entry.error = true;
    tableData[0].exit.error = true;
    valid = false;
  } else if (!isException && atLeastOneHasData) {
    tableData = tableData.map((sch) => {
      const schedule = { ...sch };
      if (!schedule.entry && !schedule.entry.value) {
        schedule.entry.error = false;
        schedule.exit.error = false;
      }

      return schedule;
    });
  }
  return { tableData, valid };
}

function getTotalForFlexibleException(newEl) {
  let minutes = 0;

  if (newEl.hoursPerDay) {
    if (newEl.hoursPerDay.indexOf(":") > 0) {
      minutes = convertTimeToMinutes(newEl.hoursPerDay);
    }
    minutes = parseInt(newEl.hoursPerDay, 10) * 60;

    if (newEl?.breaks?.length) {
      newEl.breaks.map((br) => {
        if (br.breakDuration && !br.isWorkingHours) {
          minutes -= parseInt(br.breakDuration, 10);
        }
        return br;
      });
    }
  }

  return minutes;
}

export function updateTableData(dayId, dayData, tableData) {
  return tableData.map((el) => {
    let resultEl = el;

    if (el.id === dayId) {
      const newEl = { ...el, ...dayData };

      if (newEl.type && newEl.type !== "regular") {
        switch (newEl.type) {
          case "flexible":
            newEl.total = getTotalForFlexibleException(newEl);
            newEl.extraNight = 0;
            break;
          case "nonworking":
            newEl.working = false;
          // eslint-disable-next-line no-fallthrough
          case "noexception":
            newEl.total = 0;
            newEl.breaks = [];
            newEl.entry = null;
            newEl.exit = null;
            newEl.extraNight = 0;
            break;
          default:
            break;
        }
      } else if (
        newEl.entry &&
        newEl.entry.value &&
        !newEl.entry.error &&
        newEl.exit &&
        newEl.exit.value &&
        !newEl.exit.error
      ) {
        const range = getStartEndRange(newEl.entry.value, newEl.exit.value);
        const extraHours = getExtraHours(range.start, range.end);

        newEl.total = range.end.diff(range.start, "minutes", true);
        if (newEl.breaks && newEl.breaks.length > 0) {
          newEl.breaks.map((br) => {
            if (br.breakDuration && !br.isWorkingHours) {
              newEl.total -= parseInt(br.breakDuration, 10);
            }
            return br;
          });
        }
        newEl.extraNight = extraHours;
      } else {
        newEl.total = 0;
        newEl.extraNight = 0;
      }
      resultEl = newEl;
    }

    return updateTotals(resultEl);
  });
}

export function enableDsrDay(dayId, tableData) {
  return tableData.map((el) => ({ ...el, isDsrDay: el.id === dayId }));
}

export function getRulesFromConfines(confines = {}) {
  const getValue = (val, defaultValue) => {
    if (val === null) {
      return "none";
    }
    return val || val === 0 ? parseInt(val, 10) : defaultValue;
  };
  return {
    entry: {
      tolerance: getValue(confines.toleranceEntryLate, 30),
      validity: getValue(confines.validityEntryEarly, 60),
    },
    exit: {
      tolerance: getValue(confines.toleranceExitEarly, 15),
      validity: getValue(confines.validityExitLate, 60),
    },
    break_start: {
      tolerance: getValue(confines.toleranceBreakStartEarly, 15),
    },
    break_end: {
      tolerance: getValue(confines.toleranceBreakEndLate, 15),
    },
  };
}

export function getConfinesFromRules(
  rules = {
    entry: {},
    exit: {},
    break_start: {},
    break_end: {},
  },
) {
  return {
    validityExitLate: rules.exit.validity === "none" ? null : parseInt(rules.exit.validity, 10),
    toleranceEntryLate: parseInt(rules.entry.tolerance, 10),
    toleranceExitEarly: parseInt(rules.exit.tolerance, 10),
    validityEntryEarly: rules.entry.validity === "none" ? null : parseInt(rules.entry.validity, 10),
    toleranceBreakEndLate: parseInt(rules.break_end.tolerance, 10),
    toleranceBreakStartEarly: parseInt(rules.break_start.tolerance, 10),
  };
}

export function validateEvents(data, t) {
  let error = "";
  if (data.scheduleType === "shifts" && data.scheduleDays.length > 0) {
    data.scheduleDays.map((day) => {
      if (day.events && day.events.length > 0) {
        let prevEvent;
        day.events.map((event) => {
          if (prevEvent && prevEvent.time && event.time && prevEvent.time > event.time) {
            error = t("Event time should be grater then time of previous event");
          }
          if (event.time) {
            prevEvent = event;
          }
          return "";
        });
      }
      return "";
    });
  }
  return error;
}
