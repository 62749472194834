import "styles/time-control.scss";
import DropdownControl from "components/UI/DropdownControl";
import { useTranslation } from "react-i18next";
import BEM from "utils/BEM";
import * as images from "../svg-images";

const b = BEM.b("schedule-create");

export default ({ item, readOnly, onRemoveItem, onChange, onAddItem }) => {
  const { t } = useTranslation("schedules");
  const DURATIONS = [
    { label: t("Flexible"), value: null },
    { label: "30m", value: 30 },
    { label: "45m", value: 45 },
    { label: "60m", value: 60 },
    { label: "90m", value: 90 },
    { label: "120m", value: 120 },
    { label: "180m", value: 180 },
    { label: "240m", value: 240 },
    { label: "300m", value: 300 },
  ];

  if (
    readOnly &&
    (item.breakDuration || item.breakDuration === null) &&
    (!item.start || !item.start.value) &&
    (!item.end || !item.end.value)
  ) {
    return (
      <div className={b("break-text")}>
        <span className={b("break-text", ["duration"])}>
          {item.breakDuration === null ? t("Flexible") : `${item.breakDuration}m`}
        </span>
      </div>
    );
  }
  if (
    readOnly &&
    item.start &&
    item.start.value &&
    !item.start.error &&
    item.end &&
    item.end.value &&
    !item.end.error
  ) {
    return (
      <div className={b("break-text")}>
        <span className={b("break-text", ["duration"])}>{`${item.breakDuration}m`}</span>
        {t("Between")} {item.start.value}-{item.end.value}
      </div>
    );
  }
  return (
    <div className={b("break-control")}>
      <DropdownControl
        onChange={(val) => {
          const newItem = item;
          newItem.breakDuration = parseInt(val.value, 10);
          onChange(newItem);
        }}
        value={{
          label: `${item.breakDuration === null ? t("Flexible") : `${item.breakDuration}m`}`,
          value: item.breakDuration,
        }}
        modifiers={{ break: true }}
        options={DURATIONS}
      />
      {/* {item.breakDuration !== null && <div className={b('break-time-label')}>{t('Break start')}</div>}
      {item.breakDuration !== null && (
        <>
          <TimeControl
            modifiers={{ schedule: true }}
            placeholder={t('from')}
            style={{ marginRight: '10px', maxWidth: '100px' }}
            onTimeChange={val => {
              const newItem = item;
              if (val && val.value) {
                newItem.start = { ...val };
              }
              onChange(newItem);
            }}
            error={item.start && item.start.error}
            value={item.start ? item.start.value : ''}
          />
          <TimeControl
            modifiers={{ schedule: true }}
            placeholder={t('to')}
            style={{ maxWidth: '100px' }}
            onTimeChange={val => {
              const newItem = item;
              if (val && val.value) {
                newItem.end = { ...val };
              }
              onChange(newItem);
            }}
            error={item.end && item.end.error}
            value={item.end ? item.end.value : ''}
          />
        </>
      )} */}
      <span className={b("remove-break")} onClick={() => onAddItem(item)}>
        {images.scheduleAddSmall}
      </span>
      <span className={b("remove-break")} onClick={() => onRemoveItem(item)}>
        {images.scheduleRemoveBreak}
      </span>
    </div>
  );
};
