import styled from "styled-components";
import { useTranslation } from "react-i18next";
import FieldsGroup from "components/UI/FieldsGroup";
import AddRemoveLink from "components/controls/AddRemoveLink";

import { CustomFieldType, CustomField, CustomFieldError } from "types/models/projects";
import ProjectField from "./ProjectCustomField";

import "styles/table-common.scss";

const NewItemWrapper = styled.div`
  margin-top: 16px;
  max-width: 200px;
  width: 100%;
`;
const Spacer = styled.div`
  height: 10px;
`;
const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

interface ICustomFieldsProps {
  customFields: CustomField[];
  onChange: (cfs: CustomField[]) => void;
  errors: CustomFieldError[];
}

export default ({ onChange, customFields, errors }: ICustomFieldsProps): JSX.Element => {
  const { t } = useTranslation("requests-page-tmp");
  return (
    <FieldsGroup label={t("Fields")} width="350px" style={{ marginTop: "13px" }}>
      <Spacer />
      {customFields.map((customField, i) =>
        customField.deletedAt === null || customField.deletedAt === undefined ? (
          // eslint-disable-next-line react/no-array-index-key
          <Wrapper key={`${customField.uuid}_${i}`}>
            <ProjectField
              // eslint-disable-next-line react/no-array-index-key
              key={`custom${i}`}
              customField={{ ...customField }}
              width="367px"
              error={errors && errors[i]}
              onRemove={() => {
                const newCustomFields = [...customFields];
                newCustomFields.splice(i, 1);
                onChange(newCustomFields);
              }}
              onChange={(cf: CustomField) => {
                const newCustomField: CustomField = {
                  ...cf,
                  required: cf.active ? cf.required : false,
                };
                const newCustomFields = [...customFields];
                newCustomFields[i] = newCustomField;
                onChange(newCustomFields);
              }}
              onChangeFieldType={(value: CustomFieldType) => {
                const newCustomField: CustomField = {
                  ...customField,
                  fieldType: value,
                };
                const newCustomFields = [...customFields];
                newCustomFields[i] = newCustomField;
                onChange(newCustomFields);
              }}
            />
          </Wrapper>
        ) : null,
      )}

      {customFields.filter((cf) => cf.deletedAt === null || cf.deletedAt === undefined).length < 5 ? (
        <NewItemWrapper>
          <AddRemoveLink
            label={t("Add field")}
            onClick={() => {
              const newCustomField: CustomField = {
                uuid: undefined,
                name: "",
                fieldType: CustomFieldType.numeric,
                active: true,
                required: false,
              };
              const newCustomFields = [...customFields];
              newCustomFields.push(newCustomField);
              onChange(newCustomFields);
            }}
          />
        </NewItemWrapper>
      ) : null}
    </FieldsGroup>
  );
};
