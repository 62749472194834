import { Component } from "react";
import styled from "styled-components";
import BEM from "utils/BEM";
import SettingsLayout from "components/Layout/SettingsLayout";
import { getSystemName, getTitle, strIncludesCheck } from "utils/common";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import { withTranslation } from "react-i18next";
import { getReps, getCurrentCompany } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import ModalDialog from "components/UI/ModalDialog";
import * as moment from "moment";
import noReps from "img/no-reps.png";
import { withRouter } from "react-router-dom";
import { PageWrapper, HeaderAction } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ButtonState } from "components/controls/StyledButton";
import PegadorModal from "components/Pegador/Modal";
import { ReactComponent as DotsIcon } from "components/Activities/icons/dots.svg";
import Popup from "../../Popup";
import "components/UI/Page/Page.scss";
import "styles/reps-list.scss";

const b = BEM.b("reps-list");

const CTA = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-200);
  background: var(--colors-surface-0);

  svg {
    path {
      fill: var(--colors-surface-600);
    }
  }
`;

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      reps: [],
      notification: "",
      searchValue: "",
      isFetching: false,
    };
    document.title = getTitle(t("Time Clocks"));
  }
  componentDidMount() {
    this.getState({ page: 0, ...this.props });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState({ page: 0, ...nextProps });
  }
  getState(props) {
    if (!this.state.isFetching) {
      getCurrentCompany().then((r) => {
        getReps({ companyUuid: r.uuid }).then((resp) => {
          this.setState({
            reps: resp.items.map((rep) => ({ model: rep.info ? rep.info.model : null, ...rep })),
            isFetching: false,
          });
        });
      });
      this.setState({ isFetching: true });
    }
  }

  onSearch(ev) {
    this.setState({ searchValue: ev.target.value });
  }

  getTableData(holidays) {
    const data = holidays.map((e) => ({
      id: e.id,
      holiday: e.name,
      when: e.type,
      period: e.users.length,
    }));
    return data;
  }
  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("ID"),
        accessor: "id",
        minWidth: 50,
      },
      {
        label: t("External ID"),
        accessor: "externalId",
        width: 100,
      },
      {
        label: t("Name"),
        accessor: "name",
        Cell: (row) => (
          <a
            style={{ textTransform: "none", color: "#525F7F", fontSize: "14px" }}
            href={`/time-clocks/${row.original.id}`}
            onClick={(ev) => ev.preventDefault()}
          >
            {row.value}
          </a>
        ),
        width: 170,
      },
      {
        label: t("Brand"),
        accessor: "model",
        minWidth: 170,
      },
      {
        label: t("Last sync"),
        accessor: "status",
        Cell: (row) => (row.value ? moment(row.value).format("HH:mm - DD/MM/YYYY") : ""),
        minWidth: 90,
        align: "center",
      },
      {
        label: t("Status"),
        accessor: "active",
        Cell: (row) => (
          <span className={b("status-button", { inactive: !row.value })}>
            {t(row.value ? "active" : "deactivated")}
          </span>
        ),
        minWidth: 90,
        align: "center",
      },
    ];
    return columns;
  }
  render() {
    const { reps, searchValue, popupVisible, showPegadorModal } = this.state;
    const { t } = this.props;

    const filteredReps = reps.filter((s) => {
      const filter = strIncludesCheck(s.name, searchValue);
      return filter;
    });

    return (
      <SettingsLayout
        title={t(`${TranslationNamespaces.common}|Settings`)}
        headerAction={
          <HeaderAction>
            <SimpleMenu
              useClick
              options={[{ value: "showPegadorModal", label: t("Pegador") }]}
              onChange={(val) => {
                if (val === "showPegadorModal") {
                  this.setState({ showPegadorModal: true });
                }
              }}
            >
              <CTA>
                <DotsIcon />
              </CTA>
            </SimpleMenu>
            <HeaderActionButtonAdd
              state={ButtonState.primary}
              title={t("Add REP")}
              onClick={() => this.setState({ popupVisible: true })}
            />
          </HeaderAction>
        }
      >
        <PageWrapper>
          {this.state.notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={this.state.notification} />
          )}
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <SearchInput
              modifiers={["filter"]}
              onChange={this.onSearch.bind(this)}
              placeholder={t("Search REPS")}
              value={searchValue}
            />
          </div>
          <div className={b()}>
            <TablePage
              rows={filteredReps}
              columnSelectorOnFiltersRow
              columns={this.getTableColumns()}
              className="reps-list-table"
              loading={this.state.isFetching}
              getTrProps={(state, rowInfo, column) => ({
                onClick: (e) => {
                  this.props.history.push(`/time-clocks/${rowInfo.original.id}/`);
                },
              })}
              noContentComponent={
                <NoContent img={noReps}>
                  {t("Setup now your REP and stay connected with {{systemName}}", { systemName: getSystemName() })}
                </NoContent>
              }
            />
          </div>

          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <Popup
              onClose={() => this.setState({ popupVisible: false })}
              onSubmit={(type, name) => {
                this.props.history.push(`/time-clocks/add-${type}?name=${name}`);
              }}
            />
          </ModalDialog>

          {showPegadorModal && <PegadorModal onClose={() => this.setState({ showPegadorModal: false })} />}
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withRouter(withTranslation([TranslationNamespaces.reps, TranslationNamespaces.sidebar])(Index));
