import { PayrollEventType } from "types/models/payroll/payrollLayout";
import { TranslationNamespaces, WithTranslation } from "types/translationNamespaces";
import { EVENT_REQUESTS_REGEX } from "components/Reports/helpers";
import i18n from "i18next";
import { DEFAULT_BREAK_NAME } from "components/Schedules/Breaks/constants";
import { getCrossShiftsPhaseLabel, getPhasesDays } from "utils/common";
import { SelectOption } from "types/ui";
import { GroupedOption } from ".";
import { DSR_PHASE_KEY } from "../types";

export enum GroupPrefixes {
  request_types_hours = "request_types_hours",
  request_types_days = "request_types_days",
  request_subtypes_hours = "request_subtypes_hours",
  request_subtypes_days = "request_subtypes_days",
  extra_hours_phases = "extra_hours_phases",
  extra_hours_phases_from_punches = "extra_hours_phases_from_punches",
  extra_hours_phases_from_expired_hours_bank = "extra_hours_phases_from_expired_hours_bank",
  hours_bank_phases = "hours_bank_phases",
  night_shift_names = "night_shift_names",
  nightShiftOvertimeName = "night_shift_reduced_overtime_names",
  nightShiftWithoutOvertimeName = "night_shift_reduced_without_overtime_names",
  projects_services = "projects_services",
  cross_shifts_interval_phases = "cross_shifts_interval_phases",
  break_types = "break_types",
  break_types_day = "break_types_day",
  break_types_night = "break_types_night",
  hours_distribution = "hours_distribution",
  break_types_on_holidays = "break_types_on_holidays",
  break_types_on_not_holidays = "break_types_on_not_holidays",
}

export const EXTRA_HOURS_PHASE_KEY = "extra_hours_phases[";
export const HOURS_BANK_PHASE_KEY = "hours_bank_phases[";
export const PROJECT_SERVICES_KEY = "projects_services[";
export const CROSS_SHIFTS_PHASES_KEY = "cross_shifts_interval_phases[";
export const PHASE_CUSTOM_NAME_KEY = "custom_name:";

export const getPhaseNameLabel = (eventTypeUuid: string, t: WithTranslation["t"]) => {
  let label = "";
  // get all symbols between brackets
  let phaseKey = "";

  if (eventTypeUuid.indexOf(EXTRA_HOURS_PHASE_KEY) === 0) {
    phaseKey = eventTypeUuid.substring(EXTRA_HOURS_PHASE_KEY.length, eventTypeUuid.length - 1);
  } else if (eventTypeUuid.indexOf(HOURS_BANK_PHASE_KEY) === 0) {
    phaseKey = eventTypeUuid.substring(HOURS_BANK_PHASE_KEY.length, eventTypeUuid.length - 1);
  } else if (eventTypeUuid.indexOf(`${GroupPrefixes.extra_hours_phases_from_punches}[`) === 0) {
    phaseKey = eventTypeUuid.substring(
      `${GroupPrefixes.extra_hours_phases_from_punches}[`.length,
      eventTypeUuid.length - 1,
    );
  } else if (eventTypeUuid.indexOf(`${GroupPrefixes.extra_hours_phases_from_expired_hours_bank}[`) === 0) {
    phaseKey = eventTypeUuid.substring(
      `${GroupPrefixes.extra_hours_phases_from_expired_hours_bank}[`.length,
      eventTypeUuid.length - 1,
    );
  }

  const isCustomName = phaseKey.indexOf(PHASE_CUSTOM_NAME_KEY) === 0;

  if (isCustomName) {
    return phaseKey.substring(PHASE_CUSTOM_NAME_KEY.length);
  }

  const isDayTypeBasedOnSchedule = !isCustomName && phaseKey.toLowerCase().indexOf("working") !== -1;
  const isDsrPhase = !isCustomName && phaseKey.toLowerCase().indexOf(DSR_PHASE_KEY) !== -1;

  const regexp =
    isDayTypeBasedOnSchedule || isDsrPhase ? /([a-zA-Z]+)_([^_]*)_?(day|night)?/ : /(\d{8})_([^_]*)_?(day|night)?/;

  const match = phaseKey.match(regexp) || [];

  if (eventTypeUuid.indexOf(`${GroupPrefixes.extra_hours_phases_from_punches}[`) === 0) {
    label = `${t("Extra Hours from Punches")} ${
      getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[match[1]]
    } ${match[2]}%`;
  } else if (eventTypeUuid.indexOf(`${GroupPrefixes.extra_hours_phases_from_expired_hours_bank}[`) === 0) {
    label = `${t("Extra Hours from Expired Hours Bank")} ${
      getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[match[1]]
    } ${match[2]}%`;
  } else if (eventTypeUuid.indexOf(HOURS_BANK_PHASE_KEY) === 0) {
    label = `${t("Hours Bank")} ${getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[match[1]]} ${match[2]}%`;
  } else {
    label = `${t("Extra Hours")} ${getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[match[1]]} ${match[2]}%`;
  }

  if (match[3]) {
    label += ` (${t(match[3])})`;
  }

  return label;
};

const buildRegexpForEventTypes = (prefixes: string[]) => new RegExp(`^(${prefixes.join("|")})\\[([^\\]]+)\\]$`);

export const validateEventTypeByPrefixes = (eventType: string, prefixes: string[]) =>
  buildRegexpForEventTypes(prefixes).test(eventType);

export const getEventTypeByPrefixes = (eventType: string, prefixes: string[]) => {
  const match = eventType.match(new RegExp(buildRegexpForEventTypes(prefixes))) || [];

  if (match?.length !== 3) {
    return null;
  }

  return {
    name: match[1],
    value: match[2],
  };
};

export const isNightShiftEventType = (eventTypeUuid: string) =>
  validateEventTypeByPrefixes(eventTypeUuid, [
    GroupPrefixes.night_shift_names,
    GroupPrefixes.nightShiftOvertimeName,
    GroupPrefixes.nightShiftWithoutOvertimeName,
  ]);

export const getNightShiftType = (eventTypeUuid: string) =>
  getEventTypeByPrefixes(eventTypeUuid, [
    GroupPrefixes.night_shift_names,
    GroupPrefixes.nightShiftOvertimeName,
    GroupPrefixes.nightShiftWithoutOvertimeName,
  ]);

export const getBreakTypesLabel = (name: string) => {
  const { t } = i18n;
  let label = "";
  let suffix = "";

  const { value: breakName } =
    getEventTypeByPrefixes(name, [
      GroupPrefixes.break_types,
      GroupPrefixes.break_types_day,
      GroupPrefixes.break_types_night,
      GroupPrefixes.break_types_on_holidays,
      GroupPrefixes.break_types_on_not_holidays,
    ]) || {};

  // shouldn't happen
  if (!breakName) {
    label = "unknown_break";
  } else {
    if (name.indexOf(GroupPrefixes.break_types_day) === 0) {
      suffix = ` (${t(`${TranslationNamespaces.payroll}|day`)})`;
    } else if (name.indexOf(GroupPrefixes.break_types_night) === 0) {
      suffix = ` (${t(`${TranslationNamespaces.payroll}|night`)})`;
    } else if (name.indexOf(GroupPrefixes.break_types_on_holidays) === 0) {
      suffix = ` (${t(`${TranslationNamespaces.payroll}|holidays`)})`;
    } else if (name.indexOf(GroupPrefixes.break_types_on_not_holidays) === 0) {
      suffix = ` (${t(`${TranslationNamespaces.payroll}|not_holidays`)})`;
    }
    label =
      t(`${TranslationNamespaces.payroll}|break_type_event`, {
        name: breakName === DEFAULT_BREAK_NAME ? t(DEFAULT_BREAK_NAME) : breakName,
      }) + suffix;
  }

  return label;
};

const getPayrollEventTypeOption = (payrollEventType: PayrollEventType, t: WithTranslation["t"]): SelectOption => {
  const option = {
    value: payrollEventType.uuid,
    label: "",
  };

  if (
    option.value.indexOf(EXTRA_HOURS_PHASE_KEY) === 0 ||
    option.value.indexOf(`${GroupPrefixes.extra_hours_phases_from_expired_hours_bank}[`) === 0 ||
    option.value.indexOf(`${GroupPrefixes.extra_hours_phases_from_punches}[`) === 0 ||
    option.value.indexOf(HOURS_BANK_PHASE_KEY) === 0
  ) {
    option.label = getPhaseNameLabel(option.value, t);
  } else if (option.value.indexOf(CROSS_SHIFTS_PHASES_KEY) === 0) {
    const result = getEventTypeByPrefixes(option.value, [GroupPrefixes.cross_shifts_interval_phases]);
    if (result?.value) {
      const [, limit, name] = result.value.match(/(\d*)_(.*)/) || [];
      option.label = getCrossShiftsPhaseLabel(name, limit);
    } else {
      option.label = "test";
    }
  } else if (isNightShiftEventType(payrollEventType.uuid)) {
    const eventType = getNightShiftType(payrollEventType.uuid);
    if (eventType?.name) {
      const { name, value } = eventType;
      option.label = t(`${TranslationNamespaces.payroll}|${name}`, { value });
    } else {
      option.label = t("Night Shift");
    }
  } else if (option.value.indexOf(GroupPrefixes.break_types) === 0) {
    option.label = getBreakTypesLabel(payrollEventType.name);
  } else if (payrollEventType.uuid.indexOf(`${GroupPrefixes.hours_distribution}[`) === 0) {
    option.label = payrollEventType.uuid.substring(
      GroupPrefixes.hours_distribution.length + 1,
      payrollEventType.uuid.length - 1,
    );
  } else if (payrollEventType.uuid.indexOf(PROJECT_SERVICES_KEY) === 0) {
    option.label = `${t(`${TranslationNamespaces.common}|Service`)} - "${payrollEventType.name}"`;
  } else {
    option.label = t(payrollEventType.name);
  }

  return option;
};

const populateRequestsOptions = (acc: Map<string, GroupedOption>, item: PayrollEventType, t: WithTranslation["t"]) => {
  const [, type, name] = item.name.match(EVENT_REQUESTS_REGEX) || [];
  const daysOrHours = t(type.split("_")[2]);

  if (type.indexOf("request_subtypes") === 0 && item.requestTypeUuid) {
    const requestSubtypeName = item.userDefined ? name : t(`${TranslationNamespaces.requestsPageTmp}|${name}`);

    if (!acc.get(item.requestTypeUuid)) {
      acc.set(item.requestTypeUuid, {
        subOptions: [],
        id: "",
        label: "",
      });
    }

    acc.get(item.requestTypeUuid)?.subOptions?.push({
      id: item.uuid,
      label: `${requestSubtypeName} - ${daysOrHours}`,
    });
  }

  if (type.indexOf("request_types") === 0) {
    const [, , requestTypeUuid] = item.uuid.match(EVENT_REQUESTS_REGEX) || [];
    const requestName = item.userDefined ? name : t(`${TranslationNamespaces.requestsPageTmp}|${name}`);

    if (requestTypeUuid) {
      if (!acc.get(requestTypeUuid)) {
        acc.set(requestTypeUuid, {
          id: requestTypeUuid,
          label: `${t(`${TranslationNamespaces.requestsPageTmp}|requests`)} ${requestName}`,
          subOptions: [],
        });
      }

      acc.get(requestTypeUuid)?.subOptions?.push({
        id: item.uuid,
        label: `${requestName} ${t("All")} - ${daysOrHours}`,
      });
    }
  }
};

const populateParentWithOptions = (
  acc: Map<string, GroupedOption>,
  item: PayrollEventType,
  parentId: string,
  t: WithTranslation["t"],
) => {
  const option = getPayrollEventTypeOption(item, t);
  if (!acc.get(parentId)) {
    acc.set(parentId, {
      id: parentId,
      label: t(parentId),
      subOptions: [
        {
          id: item.uuid,
          label: option.label,
        },
      ],
    });
  } else {
    acc.get(parentId)?.subOptions?.push({
      id: item.uuid,
      label: option.label,
    });
  }

  return acc;
};

type GroupKey = string; // todo think if it can be done with enum

enum Groups {
  crossShift = "Cross shift",
  debit = "Debit",
  dsr = "DSR",
  earlyLeaves = "Early Leaves",
  extraHours = "Extra Hours",
  extraHoursPhases = "Extra Hours Phases",
  extraHoursPhasesFromPunches = "Extra Hours Phases from Punches",
  extraHoursPhasesFromExpiredHB = "Extra Hours Phases from Expired Hours Bank",
  hoursBankPhases = "Hours Bank Phases",
  holidays = "Holidays",
  hoursBank = "Hours Bank",
  lateArrivals = "Late arrivals",
  missingDaysAndHours = "Missing days & hours",
  nightShift = "Night Shift",
  onCall = "On call",
  paidAbsences = "Paid absences",
  projectsServices = "projects_services",
  breaks = "Breaks",
  unfinishedBreaks = "Unfinished breaks",
  workingHours = "Working hours",
  hoursDistribution = "Hours Distribution",
  other = "Other Payroll Types",
}

const GroupsDefininitions: Record<GroupKey, string> = {
  total_debit_hours: Groups.debit,

  total_of_dsr: Groups.dsr,
  total_of_dsr_working_days: Groups.dsr,
  total_of_dsr_discount: Groups.dsr,
  business_days: Groups.dsr,
  dsr_hours: Groups.dsr,
  possible_dsr_days: Groups.dsr,

  early_leaves_without_breaks: Groups.earlyLeaves,
  total_early_leaves: Groups.earlyLeaves,
  total_early_leave_hours: Groups.earlyLeaves,

  extra_hours_total: Groups.extraHours,
  total_extra_hours_minutes_from_punches: Groups.extraHours,
  total_extra_hours_minutes_from_expired_hours_bank: Groups.extraHours,

  total_working_days_in_holiday: Groups.holidays,
  total_working_hours_in_holiday: Groups.holidays,

  total_of_hours_bank: Groups.hoursBank,
  total_hours_bank_positive_hours: Groups.hoursBank,
  total_hours_bank_negative_hours: Groups.hoursBank,
  cumulative_hours_bank_positive_hours: Groups.hoursBank,
  cumulative_hours_bank_negative_hours: Groups.hoursBank,

  total_late_entry_hours: Groups.lateArrivals,
  late_arrivals_without_breaks: Groups.lateArrivals,
  total_late_arrivals: Groups.lateArrivals,

  total_of_missing_hours: Groups.missingDaysAndHours,
  total_of_missing_days: Groups.missingDaysAndHours,
  total_missed_minutes_before_compensation: Groups.missingDaysAndHours,
  missed_days_in_hours: Groups.missingDaysAndHours,
  total_missed_hours_from_incomplete_schedule: Groups.missingDaysAndHours,

  total_night_shift_hours: Groups.nightShift,
  total_holiday_night_shift_hours: Groups.nightShift,
  total_night_shift_reduced_hours: Groups.nightShift,
  total_night_shift_overtime_hours: Groups.nightShift,
  total_net_reduced_night_shift_hours: Groups.nightShift,
  total_night_shift_without_overtime_hours: Groups.nightShift,

  total_on_call_hours: Groups.onCall,
  total_on_call_activated_hours: Groups.onCall,
  total_on_call_reducing_activated_hours: Groups.onCall,

  total_paid_absence_hours: Groups.paidAbsences,
  total_unused_break_hours: Groups.unfinishedBreaks,
  total_unused_day_break_minutes: Groups.unfinishedBreaks,
  total_unused_night_break_minutes: Groups.unfinishedBreaks,

  total_projected_hours: Groups.workingHours,
  total_work_hours_with_projected_hours: Groups.workingHours,
  worked_days_in_hours: Groups.workingHours,
  total_working_hours_excluding_projects_services: Groups.workingHours,

  total_hours_interjornada: Groups.crossShift,
  cross_shifts_interval: Groups.crossShift,

  // PREFIXES
  extra_hours_phases: Groups.extraHoursPhases,
  extra_hours_phases_from_punches: Groups.extraHoursPhasesFromPunches,
  extra_hours_phases_from_expired_hours_bank: Groups.extraHoursPhasesFromExpiredHB,
  hours_bank_phases: Groups.hoursBankPhases,

  [GroupPrefixes.night_shift_names]: Groups.nightShift,
  [GroupPrefixes.nightShiftOvertimeName]: Groups.nightShift,
  [GroupPrefixes.nightShiftWithoutOvertimeName]: Groups.nightShift,

  [GroupPrefixes.break_types]: Groups.breaks,
  [GroupPrefixes.break_types_day]: Groups.breaks,
  [GroupPrefixes.break_types_night]: Groups.breaks,
  [GroupPrefixes.break_types_on_holidays]: Groups.breaks,
  [GroupPrefixes.break_types_on_not_holidays]: Groups.breaks,

  [GroupPrefixes.hours_distribution]: Groups.hoursDistribution,

  projects_services: Groups.projectsServices,
  cross_shifts_interval_phases: Groups.crossShift,

  // fallback group
  other: Groups.other,
};

export const getGroupedDropdownOptions = (rawItems: PayrollEventType[], t: WithTranslation["t"]): GroupedOption[] => {
  const typesMap = rawItems.reduce((acc, item) => {
    if (item.name?.match(EVENT_REQUESTS_REGEX)) {
      populateRequestsOptions(acc, item, t);
    } else if (GroupsDefininitions[item.name as GroupKey]) {
      populateParentWithOptions(acc, item, GroupsDefininitions[item.name as GroupKey], t);
    } else if (
      validateEventTypeByPrefixes(item.name, [
        GroupPrefixes.extra_hours_phases,
        GroupPrefixes.extra_hours_phases_from_punches,
        GroupPrefixes.extra_hours_phases_from_expired_hours_bank,
        GroupPrefixes.hours_bank_phases,
        GroupPrefixes.night_shift_names,
        GroupPrefixes.nightShiftOvertimeName,
        GroupPrefixes.nightShiftWithoutOvertimeName,
        GroupPrefixes.cross_shifts_interval_phases,
        GroupPrefixes.break_types,
        GroupPrefixes.break_types_day,
        GroupPrefixes.break_types_night,
        GroupPrefixes.hours_distribution,
        GroupPrefixes.break_types_on_holidays,
        GroupPrefixes.break_types_on_not_holidays,
      ])
    ) {
      const { name } = getEventTypeByPrefixes(item.name, [
        GroupPrefixes.extra_hours_phases,
        GroupPrefixes.extra_hours_phases_from_punches,
        GroupPrefixes.extra_hours_phases_from_expired_hours_bank,
        GroupPrefixes.hours_bank_phases,
        GroupPrefixes.night_shift_names,
        GroupPrefixes.nightShiftOvertimeName,
        GroupPrefixes.nightShiftWithoutOvertimeName,
        GroupPrefixes.cross_shifts_interval_phases,
        GroupPrefixes.break_types,
        GroupPrefixes.break_types_day,
        GroupPrefixes.break_types_night,
        GroupPrefixes.hours_distribution,
        GroupPrefixes.break_types_on_holidays,
        GroupPrefixes.break_types_on_not_holidays,
      ])!;

      populateParentWithOptions(acc, item, GroupsDefininitions[name as GroupKey], t);
    } else if (validateEventTypeByPrefixes(item.uuid, [GroupPrefixes.projects_services])) {
      const { name } = getEventTypeByPrefixes(item.uuid, [GroupPrefixes.projects_services])!;

      populateParentWithOptions(acc, item, GroupsDefininitions[name as GroupKey], t);
    } else {
      populateParentWithOptions(acc, item, GroupsDefininitions.other, t);
    }

    return acc;
  }, new Map<string, GroupedOption>());

  return [...typesMap.values()];
};
