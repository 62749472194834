import { Component, ContextType, createContext } from "react";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { withTranslation } from "react-i18next";
import { fireEvent } from "utils/common";
import {
  getSuperpunchReport,
  addPunchSP,
  getAllPunches,
  validatePunchSP,
  declinePunchSP,
  enableDay,
  toggleScheduleExceptionDay,
  getEmployee,
  forceRecalculateRange,
  removeScheduleOnRange,
  validateAllRange,
  approveAllRange,
  updateShiftCompilationsSP,
  getUserBRGroups,
} from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  SuperpunchPendingDays,
  SuperpunchPunchCellData,
  SuperpunchTableRowData,
  SuperpunchTableRows,
  SuperPunchStore,
  DailySummaryReport,
  DailySummaryDateStatus,
  DailySummaryEmployeeInfo,
} from "types/models/superpunch";
import { BreakStatusOptions, GetSuperpunchReportRequestData } from "utils/api/types";
import { UserProfile } from "types/models/userProfile";
import { PunchKey, PunchStatuses, Punch, AddPunchPunch, DailySummaryPunch } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { getCustomBreaksList } from "utils/api/schedule";
import { getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import {
  ChangePunchData,
  PrepareTableRowsData,
  SuperpunchCustomEvents,
  SuperpunchProviderProps,
  SuperpunchProviderState,
  SuperpunchContextInterface,
  SuperpunchContextValue,
  ShiftCompilationOperation,
  OnPunchActionData,
  OnPunchDeclineData,
  EnableDayEventData,
  RemoveExceptionEventData,
  SuperpunchWorkedHoursEventData,
} from "./types";
import { aggregateTableData, generateEventKey } from "./helpers";
import GlobalContext from "../global-context";

const moment = extendMoment(momentTz);
// TODO make common constant
export const SP_DATE_FORMAT = "YYYY-MM-DD";

export const SuperpunchContext = createContext<SuperpunchContextValue>({} as SuperpunchContextValue);

class SuperpunchProvider
  extends Component<SuperpunchProviderProps, SuperpunchProviderState>
  implements SuperpunchContextInterface
{
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  // Update delay
  updateDataTimeout: NodeJS.Timeout | null = null;
  // Checking wheter some pendingDays are still processing
  processingCheckInterval: NodeJS.Timer | null = null;
  // Days (rows) that are still processing.
  pendingDays: SuperpunchPendingDays = {};
  // Superpunch cache
  superPunchStore: SuperPunchStore = {
    reports: {},
    employees: {},
    punches: {},
  };

  constructor(props: SuperpunchProviderProps) {
    super(props);
    const { uuid, id, startDate, endDate, directReportsOnly, showOnlyIssues, label } = this.props;

    this.state = {
      employeeUuid: uuid,
      employeeId: Number(id),
      searchValue: label || "",
      startDate: startDate ? moment(props.startDate, SP_DATE_FORMAT) : moment().clone().subtract(1, "month"),
      endDate: endDate ? moment(props.endDate, SP_DATE_FORMAT) : moment().clone(),
      employeeInfo: null,
      directReportsOnly: directReportsOnly === "true",
      showOnlyIssues: showOnlyIssues === "true",
      employeeBusinessRuleGroups: [],
      customBreaksNamesMap: {},
    };

    this.processingCheck();
  }
  getBusinessRules: () => Promise<void>;

  componentDidMount() {
    void this.socketsConnect();

    document.addEventListener("LogOut", this.clearSuperPunchStore);
    document.addEventListener(SuperpunchCustomEvents.updateData, this.updateData);
    document.addEventListener(SuperpunchCustomEvents.addPunch, this.addPunch);
    document.addEventListener(SuperpunchCustomEvents.enableDay, this.enableDayEventHandler);
    document.addEventListener(SuperpunchCustomEvents.removeException, this.removeExceptionEventHandler);
  }

  componentWillUnmount = async () => {
    void this.socketDisconnect();

    this.clearAsyncProcedures();

    document.removeEventListener("LogOut", this.clearSuperPunchStore);
    document.removeEventListener(SuperpunchCustomEvents.updateData, this.updateData);
    document.removeEventListener(SuperpunchCustomEvents.addPunch, this.addPunch);
    document.removeEventListener(SuperpunchCustomEvents.enableDay, this.enableDayEventHandler);
    document.removeEventListener(SuperpunchCustomEvents.removeException, this.removeExceptionEventHandler);
  };

  /**
   * Clear all async stuff that runs under the hood
   */
  private clearAsyncProcedures = () => {
    if (this.updateDataTimeout) {
      clearTimeout(this.updateDataTimeout);
    }
    if (this.processingCheckInterval) {
      clearInterval(this.processingCheckInterval);
    }
  };

  /**
   * Update data 2 sec after any socket event
   *
   * @param msg
   */
  private updateDataOnSocketEvent = async (msg: unknown) => {
    const socket = await this.context.getSocket();

    if (window.global_store.beta) {
      console.log("systemEvent", msg);
    }

    this.updateData(2000);

    socket.send(msg);
  };

  /**
   * Connect to the socket
   */
  private socketsConnect = async () => {
    const { employeeUuid } = this.state;

    if (window.global_store.beta) {
      console.log("Socket connect", this.state);
    }

    const socket = await this.context.getSocket();
    const company = await this.context.getCompany();

    socket.emit("commands", [
      { name: "leave", args: { scope: "superpunch" } },
      {
        name: "join",
        args: {
          scope: "superpunch",
          companyUuid: company.uuid,
          userProfileUuid: employeeUuid,
        },
      },
    ]);

    socket.on("systemEvent", this.updateDataOnSocketEvent);
  };

  /**
   * Disconnect from the socket
   */
  private socketDisconnect = async () => {
    const socket = await this.context.getSocket();

    socket.emit("commands", [{ name: "leave", args: { scope: "superpunch" } }]);
    socket.off("systemEvent", this.updateDataOnSocketEvent);
  };

  /**
   * Reconnect to the socket
   */
  private socketReconnect = async () => {
    await this.socketDisconnect();
    await this.socketsConnect();
  };

  /** Async function that check this.pendingDays and fires sp_frozen event. Runs every 5 sec */
  private processingCheck = () => {
    if (this.processingCheckInterval) {
      clearInterval(this.processingCheckInterval);
    }

    this.processingCheckInterval = setInterval(() => {
      const pendingDaysEntries = Object.entries(this.pendingDays);

      pendingDaysEntries.forEach(([key, pendingDay]) => {
        if (pendingDay?.diff && pendingDay.diff > 5 * 1000) {
          if (window.global_store.beta) {
            console.log(`Day ${key} was processing ${pendingDay.diff / 1000} seconds`);
          }

          this.pendingDays[key] = null;
        } else if (pendingDay?.start && !pendingDay?.end) {
          // in seconds
          const processingTime = (new Date().getTime() - pendingDay.start) / 1000;

          if (processingTime > 20) {
            // mark CellTotalWithProgress as frozen in order to show recalculate button
            fireEvent(generateEventKey(SuperpunchCustomEvents.frozen, key));
          }

          if (window.global_store.beta) {
            console.log(`Still processing day ${key}... ${processingTime} seconds`);
          }
        }
      });
    }, 5 * 1000);
  };

  private removeExceptionEventHandler = (ev: CustomEvent<RemoveExceptionEventData>) => {
    void this.toggleExceptionDay(ev, "removeException");
  };

  private enableDayEventHandler = (ev: CustomEvent<EnableDayEventData>) => {
    void this.toggleExceptionDay(ev, "enableDay");
  };

  /**
   * Set "updating" (loading) state to CellTotalWithProgress. Add day to this.pendingDays.
   *
   * @param date key in this.pendingDays object
   */
  private fireDayStartedProcessing = async (date: string) => {
    this.pendingDays[date] = { start: new Date().getTime(), end: null, diff: 0 };

    fireEvent(generateEventKey(SuperpunchCustomEvents.workedHours, date), {
      isUpdating: true,
    });
  };

  /**
   * Change CellTotalWithProgress state to default. Fire staticstics event.
   *
   * @param date key in this.pendingDays object
   */
  private fireDayStoppedProcessing = (date: string, total: string) => {
    const pendingDay = this.pendingDays[date];

    if (pendingDay) {
      pendingDay.end = new Date().getTime();
      pendingDay.diff = new Date().getTime() - pendingDay.start;

      // statistic event
      fireEvent("slo_superpunch", {
        action: "superpunch_day_procesing",
        duration: pendingDay.diff / 1000, // in seconds
      });
    }

    const eventObj: SuperpunchWorkedHoursEventData = { isUpdating: false };
    if (total) {
      // CellTotalWithProgress cell value
      eventObj.total = total;
    }

    fireEvent<SuperpunchWorkedHoursEventData>(generateEventKey(SuperpunchCustomEvents.workedHours, date), eventObj);
  };

  /**
   * Perform API call. Fire events for CellSchedule.
   *
   * @param ev removeException or enableDay events payload
   * @param action choose which API call to perform.
   */
  private toggleExceptionDay = async (
    ev: CustomEvent<RemoveExceptionEventData | EnableDayEventData>,
    action: "removeException" | "enableDay",
  ) => {
    const date = ev.detail.body.content.date.replace(/-/g, "");
    let promise;

    void this.fireDayStartedProcessing(date);

    if (action === "removeException") {
      fireEvent(generateEventKey(SuperpunchCustomEvents.schedule, date), {
        scheduleException: null,
      });

      promise = toggleScheduleExceptionDay({
        action: "deactivate",
        ...ev.detail,
      });
    } else {
      fireEvent(generateEventKey(SuperpunchCustomEvents.schedule, date), {
        scheduleException: {},
      });

      promise = enableDay({
        ...(ev.detail as EnableDayEventData),
      });
    }

    Sentry.addBreadcrumb("Toggle exception clicked");

    try {
      await promise;

      this.updateData();
    } catch (e) {
      Sentry.addBreadcrumb("Toggle exception failed");
      Sentry.sendError(e);

      fireEvent(generateEventKey(SuperpunchCustomEvents.workedHours, date), {
        isUpdating: false,
      });

      const errorMsg = (e as any)?.originalRequest?.errors?.[0]?.message || "Request failed. Please try again.";
      fireEvent(SuperpunchCustomEvents.sendError, this.props.t(errorMsg));
    }
  };

  /**
   * Perform addPunch API call. Update data. Fetch punches
   *
   * @param ev
   */
  private addPunch = async (
    ev: CustomEvent<{
      punch: AddPunchPunch;
      fullName: string;
      event: AddPunchMappedEvent["event"];
      employeeUuid: string;
    }>,
  ) => {
    const { punch, event, employeeUuid } = ev.detail;

    Sentry.addBreadcrumb("Add punch clicked");

    try {
      await addPunchSP({
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: employeeUuid,
        requestedBy: window.global_store.profile.uuid,
        date: moment(punch.punch.device_datetime),
        body: {
          ...punch,
          shift_compilation: event
            ? {
                shift_id: event.shiftId,
                date: event.date,
                key: event.key,
              }
            : null,
        },
      });

      this.updateData();

      const date = moment(event.date, "YYYY-MM-DD");
      const from = date.clone().subtract(1, "day").format("YYYY-MM-DD");
      const to = date.clone().add(1, "day").format("YYYY-MM-DD");

      void this.fetchPunches({ from, to }, true);

      fireEvent(SuperpunchCustomEvents.closeOverlay);
      // fireEvent(SuperpunchCustomEvents.sendNotification, `${`${t("employees-page|You added punch for")} ${fullName}`}`);

      void this.fireDayStartedProcessing(date.format("YYYYMMDD"));
    } catch (e) {
      Sentry.addBreadcrumb("Add punch failed", {
        event,
        punch,
      });
      Sentry.sendError(e);
    }
  };

  /**
   * Prepare table rows asynchronously with provided delay. Broadcast new data
   *
   * Also triggered by SuperpunchCustomEvents.updateData event
   *
   * @param timeout delay in ms. Default is 100
   */
  private updateData = (timeout = 100) => {
    this.clearAsyncProcedures();

    this.updateDataTimeout = setTimeout(async () => {
      const { startDate, endDate, employeeId, employeeUuid } = this.state;

      if (startDate || endDate || employeeId || employeeUuid) {
        const preparedRows = await this.prepareTableRows({ startDate, endDate, employeeId, employeeUuid }, true);

        if (!preparedRows) {
          return null;
        }

        this.broadcastTableData(preparedRows.tableRows);
      }
      return null;
    }, timeout);
  };

  /**
   * LogOut event handler. Clears this.superPunchStore to default state
   */
  private clearSuperPunchStore = () => {
    this.superPunchStore = {
      reports: {},
      employees: {},
      // requests: {},
      punches: {},
      // shiftCompilations: {},
    };
  };

  /**
   * Update report data in superPunchStore
   *
   * @param key report range key. Get one using this.getCurrentRangeKey
   * @param data report data
   * @returns report data
   */
  private updateReportData = (rangeKey: string, data: DailySummaryReport) => {
    this.superPunchStore.reports[rangeKey] = data;

    return data;
  };

  /**
   * Update employee data in superPunchStore
   *
   * @param key employee key
   * @param data employee data
   * @returns employee data
   */
  private updateEmployees = (key: string, data: UserProfile) => {
    this.superPunchStore.employees[key] = data;

    return data;
  };

  /**
   * Update punch data in superPunchStore
   *
   * @param key punch key
   * @param data punches data
   * @returns punch data
   */
  private updatePunches = (key: string, data: Punch[]) => {
    this.superPunchStore.punches[key] = data;

    return data;
  };

  /**
   * Get formatted date string
   *
   * @param date
   * @returns formatted date string
   */
  private getMomentKey = (date: moment.Moment | string): string => {
    if (date instanceof moment) {
      return (date as moment.Moment).format("YYYYMMDD");
    }

    return moment(date, "YYYY-MM-DD").format("YYYYMMDD");
  };

  /**
   * Fetch daily summary report (fresh or from cache). Aggregate data
   *
   * @param param0
   * @param forceNetworkUse decide where to get report from (cache or API call)
   * @returns employee info and table rows
   */
  private prepareTableRows = async (
    { employeeId, employeeUuid, startDate, endDate, showOnlyIssues }: PrepareTableRowsData,
    forceNetworkUse?: boolean,
  ): Promise<{ employeeInfo: DailySummaryEmployeeInfo; tableRows: SuperpunchTableRows } | null> => {
    let returnValue = null;

    try {
      let company = await this.context.getCompany();
      if (!company) {
        // doublecheck
        company = await this.context.getCompany();
      }

      let lockDate: string | null = null;
      const key = this.getCurrentRangeKey();
      const cachedReport = this.superPunchStore.reports[key] || null;

      const report = await this.fetchReport(
        {
          userProfileUUID: employeeUuid,
          startDate,
          endDate,
          companyUUID: company.uuid,
          showOnlyIssues,
        },
        forceNetworkUse,
      );

      const employeeDetails = await this.getEmployeeDetails(employeeId);

      if (!employeeDetails) {
        Sentry.sendError("Superpunch trying to access not existing employee.");
        return null;
      }

      /* eslint eqeqeq: 0 */
      if (report.employeeInfo.id == this.state.employeeId) {
        if (employeeDetails.last_lock_date) {
          lockDate = moment(employeeDetails.last_lock_date).format("YYYY-MM-DD");
        }

        const daysInProgress: string[] = report.dates
          .filter((day) => day.status !== DailySummaryDateStatus.completed)
          .map((day) => moment(day.date, "YYYY-MM-DD").format("YYYY-MM-DD"));

        const tableRows = aggregateTableData({
          inputData: report.dates,
          t: this.props.t,
          daysInProgress,
          lockDate,
        });

        // TODO should prepareTableRows function trigger events?
        if (cachedReport && this.shouldUpdateStateWithTableRows(cachedReport, report)) {
          fireEvent(SuperpunchCustomEvents.tableRowsLoaded, tableRows);
        }

        returnValue = {
          employeeInfo: report.employeeInfo,
          tableRows,
        };
      }
    } catch (error) {
      this.updateData(3000);

      Sentry.addBreadcrumb("Daily summary failed");
      Sentry.sendError(error);
    }

    return returnValue;
  };

  /**
   * Decide whether to update state considering scheduleException dates
   *
   * @param oldReport cached daily summary report
   * @param report fresh daily summary report
   * @returns
   */
  shouldUpdateStateWithTableRows = (oldReport: DailySummaryReport, report: DailySummaryReport) => {
    const cachedDaysWithExceptions = oldReport.dates
      .filter((date) => date.scheduleException)
      .map((se) => se.date)
      .join(",");

    const fetchedDaysWithExceptions = report.dates
      .filter((date) => date.scheduleException)
      .map((se) => se.date)
      .join(",");

    return cachedDaysWithExceptions !== fetchedDaysWithExceptions;
  };

  /**
   * Fetch report from cache or backend
   *
   * @param requestData backend request payload
   * @param forceNetworkUse decide where to get report from
   * @returns
   */
  private fetchReport = async (requestData: GetSuperpunchReportRequestData, forceNetworkUse?: boolean) => {
    const key = this.getCurrentRangeKey();
    let report = null;

    if (!forceNetworkUse && this.superPunchStore.reports[key]) {
      report = this.superPunchStore.reports[key];
    } else {
      const superpunchReport = await getSuperpunchReport({ ...requestData });

      // update cache
      report = this.updateReportData(key, superpunchReport);
    }

    return report;
  };

  /**
   * Get employee data from cache or backend.
   *
   * @param employeeId
   * @returns
   */
  private getEmployeeDetails = (employeeId: number): Promise<UserProfile | null> => {
    const key = `employee${employeeId}`;

    return new Promise((res) => {
      if (this.superPunchStore.employees[key]) {
        res(this.superPunchStore.employees[key]);
      } else {
        void getEmployee({ id: employeeId, newHierarchyPermissions: true }).then((employee) => {
          // update cache
          res(employee?.user_profile ? this.updateEmployees(key, employee.user_profile) : null);
        });
      }
    });
  };

  /**
   * Get range key based on employee id, start date and end date
   *
   * @returns key
   */
  private getCurrentRangeKey = (): string => {
    const { employeeId, startDate, endDate } = this.state;

    // todo only issues ?

    return `${employeeId}${this.getMomentKey(startDate)}${this.getMomentKey(endDate)}`;
  };

  /**
   * Remove punch data binded to particular cell
   *
   * @param row row data
   * @param destinationDate date that should be processed
   * @param punchUuid
   */
  private clearPunchFromSourceCell(rowData: SuperpunchTableRowData, destinationDate: moment.Moment, punchUuid: string) {
    Object.entries(rowData).forEach(([key, cellData]) => {
      // TODO better is to add cell type for punch
      const cellDataTyped = cellData as SuperpunchPunchCellData;

      if (cellDataTyped?.raw?.uuid === punchUuid) {
        const newCellData: SuperpunchPunchCellData = {
          display: this.props.t("Missing"),
          raw: null,
        };

        const dateForKey = moment(rowData.date.raw, "YYYY-MM-DD").format("YYYYMMDD");

        const cellStr = `sp_${dateForKey}${cellDataTyped?.raw?.key}`;
        fireEvent(cellStr, newCellData);

        const combinedCellBreaksKey = generateEventKey(SuperpunchCustomEvents.cellCombinedBreaks, dateForKey);
        fireEvent(combinedCellBreaksKey, { ...newCellData, eventKey: cellDataTyped.raw.key });

        void this.fireDayStartedProcessing(moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD"));
      }

      return key;
    });
  }

  /**
   * Save shift compilations on the BE side
   *
   * @param param0
   */
  private updateShiftCompilations = async (operations: ShiftCompilationOperation[], employeeUuid: string) => {
    const body = {
      content: operations,
    };

    try {
      await updateShiftCompilationsSP({
        body,
        userProfileUuid: employeeUuid,
        companyUuid: window.global_store.company.uuid,
        requestedBy: window.global_store.profile.uuid,
      });
    } catch (err) {
      fireEvent(SuperpunchCustomEvents.sendError, this.props.t("Punch change failed. Please try again."));

      // TODO: implement failed cell highlighting
      // if (cellStr) {
      //   fireEvent(`${cellStr}_failed`);
      // }

      Sentry.addBreadcrumb("updateShiftCompilation failed");
      Sentry.sendError(err);
    }
  };

  /**
   * Broadcast whole bunch of different events
   *
   * @param tableRows table data
   * @returns
   */
  private broadcastTableData(tableRows: SuperpunchTableRows) {
    // if some row is in progress dont broadcast events
    const shouldBroadcast = !tableRows.rows.some((tr) => tr.inProgress);

    // if (!Object.keys(this.selectedRangeDays).length) {
    //   tableRows.rows.forEach((tr) => {
    //     const dateKey = moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD");

    //     this.selectedRangeDays[dateKey] = {
    //       // TODO status ?
    //       status: tr.inProgress,
    //       inProgress: tr.inProgress,
    //       lastUpdated: new Date().getTime(),
    //     };
    //   });
    // }

    if (!shouldBroadcast) {
      this.updateData(30000);

      tableRows.rows.forEach((tr) => {
        const dateKey = moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD");
        const pendingDay = this.pendingDays[dateKey];
        const now = new Date().getTime();

        if (!tr.inProgress && pendingDay && !pendingDay.end && now - pendingDay.start >= 10 * 1000) {
          if (window.global_store.beta) {
            console.log((now - pendingDay.start) / 1000);
          }

          fireEvent(`${dateKey}missedMinutes`, tr.missedMinutes);

          void this.fireDayStoppedProcessing(dateKey, tr.workedMinutes);
        }

        // TODO this piece was unreachable before. Check if we need it
        // if (
        //   pendingDay &&
        //   tr.inProgress !== pendingDay.inProgress &&
        //   now - this.selectedRangeDays[dateKey].lastUpdated >= 10 * 1000
        // ) {
        //   if (tr.inProgress) {
        //     void this.fireDayStartedProcessing(dateKey);
        //   } else {
        //     void this.fireDayStoppedProcessing(dateKey, tr.workedMinutes);
        //   }
        // }
      });

      return;
    }

    if (shouldBroadcast) {
      tableRows.rows.forEach((tr) => {
        const keys = Object.keys(tr);

        keys.forEach((key) => {
          // punch data
          if (key.includes("break_") || key.includes("entry") || key.includes("exit")) {
            // update each cell
            const dateForKey = moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD");
            fireEvent(`sp_${dateForKey}${key}`, tr[key as PunchKey]);

            const combinedCellBreaksKey = generateEventKey(SuperpunchCustomEvents.cellCombinedBreaks, dateForKey);
            fireEvent(combinedCellBreaksKey, { ...tr[key as PunchKey], eventKey: key });
          }

          if (key.includes("workedMinutes")) {
            const dateKey = moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD");
            // this.selectedRangeDays[dateKey].inProgress = tr.inProgress;

            if (tr.inProgress) {
              void this.fireDayStartedProcessing(dateKey);
            } else {
              void this.fireDayStoppedProcessing(dateKey, tr[key as "workedMinutes"]);
            }
          }
          if (key.includes("extraHoursMinutes") || key.includes("hoursBankMinutes")) {
            fireEvent(`sp_${moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD")}${key}`, {
              total: tr[key as "extraHoursMinutes" | "hoursBankMinutes"],
            });
          }
          if (key.includes("missedMinutes") && tr[key as "missedMinutes"]) {
            fireEvent(`sp_${moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD")}missedMinutes`, {
              total: tr[key as "missedMinutes"],
            });
          }
          if (key === "requests" && tr[key]) {
            fireEvent(`sp_${moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD")}_${key}`, {
              requests: tr[key],
            });
          }
          if (key === "comments" && tr[key]) {
            fireEvent(`sp_${moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD")}_date`, {
              comments: tr[key],
            });
          }
        });
      });
    }
  }

  // ====== CONTEXT VALUE METHODS =========

  setContextState = (state: Partial<Omit<SuperpunchProviderState, "employeeInfo">>) => {
    const shouldSocketReconnect = state.employeeUuid && this.state.employeeUuid !== state.employeeUuid;
    this.clearAsyncProcedures();

    this.pendingDays = {};
    this.processingCheck();

    this.setState({ ...state } as SuperpunchProviderState, async () => {
      if (shouldSocketReconnect) {
        void this.socketReconnect();
      }
    });
  };

  // TODO why do we update whole table?
  changePunch = async ({ selectedType, destinationDate, punch, eventKey }: ChangePunchData) => {
    const { startDate, endDate, employeeUuid, employeeId } = this.state;
    const res = await this.prepareTableRows({
      startDate,
      endDate,
      employeeId,
      employeeUuid,
    });

    if (!res) {
      return;
    }

    res.tableRows.rows.forEach((row) => {
      // should be triggered for each row ?
      if (punch.uuid) {
        this.clearPunchFromSourceCell(row, destinationDate, punch.uuid);
      }

      // if this is right row
      if (this.getMomentKey(row.date.raw) === this.getMomentKey(destinationDate) && row[eventKey]) {
        const cellData: SuperpunchPunchCellData = {
          display: this.props.t("Missing"),
          raw: null,
        };

        // populate cell with data
        if (punch.uuid) {
          cellData.display = momentTz.tz(punch.device_datetime, punch.time_zone).format("HH:mm");
          cellData.raw = {
            ...(punch as DailySummaryPunch),
            description: selectedType,
            type: selectedType,
            key: eventKey,
            timezone: punch.time_zone,
            time: punch.device_datetime,
            status: punch.status || "",
          };
        }

        const dateForKey = moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD");
        const cellStr = `sp_${dateForKey}${eventKey}`;
        fireEvent(cellStr, cellData);

        const combinedCellBreaksKey = generateEventKey(SuperpunchCustomEvents.cellCombinedBreaks, dateForKey);
        fireEvent(combinedCellBreaksKey, { ...cellData, eventKey });

        void this.fireDayStartedProcessing(dateForKey);
      }
    });

    const operations = [
      {
        date: destinationDate.format("YYYY-MM-DD"),
        user_profile_uuid: employeeUuid,
        shift_events: [
          {
            uuid: punch.uuid,
            key: eventKey,
          },
        ],
      },
    ];

    void this.updateShiftCompilations(operations, employeeUuid);
  };

  getTableRows = async () => {
    const { employeeId, employeeUuid, startDate, endDate, showOnlyIssues } = this.state;
    // get fresh report
    const res = await this.prepareTableRows({ startDate, endDate, employeeId, employeeUuid, showOnlyIssues }, true);
    if (!res) {
      return;
    }

    // employeeInfo used in Superpunch
    this.setState({ employeeInfo: res.employeeInfo });

    fireEvent(SuperpunchCustomEvents.tableRowsLoaded, res.tableRows);

    res.tableRows.rows.forEach((tr) => {
      const dateKey = moment(tr.date.raw, "YYYY-MM-DD").format("YYYYMMDD");

      if (tr.inProgress) {
        void this.fireDayStartedProcessing(dateKey);
      }
    });
  };

  getBusinessRulesGroups = async () => {
    const { employeeUuid, startDate, endDate } = this.state;

    const company = await this.context.getCompany();
    if (!company) {
      return;
    }

    const { content } = await getUserBRGroups({
      startDate,
      endDate,
      userProfileUuid: employeeUuid,
      requestedBy: window.global_store.profile.uuid,
      companyUuid: company.uuid,
    });

    this.setState({ employeeBusinessRuleGroups: content });
  };

  fetchPunches = (requestData: Record<"from" | "to", string>, forceNetworkUse?: boolean): Promise<Punch[]> => {
    const { from, to } = requestData;
    const { employeeId } = this.state;
    const key = `${employeeId}${this.getMomentKey(from)}${this.getMomentKey(to)}`;

    return new Promise((res) => {
      if (!forceNetworkUse && this.superPunchStore.punches[key]) {
        res(this.superPunchStore.punches[key]);
      } else {
        void getAllPunches({ from, to, employeeId }).then((r) => {
          const resp = r.punches || [];

          res(
            this.updatePunches(
              key,
              resp.filter((p) => p.status !== PunchStatuses.declined),
            ),
          );
        });
      }
    });
  };

  onPunchValidate = async ({ destinationDate, punchId, punchUuid, employeeUuid, punchKey }: OnPunchActionData) => {
    Sentry.addBreadcrumb("Punch validate clicked");
    void this.fireDayStartedProcessing(moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD"));

    try {
      await validatePunchSP({
        punchId,
        punchUuid,
        requestedBy: window.global_store.profile.uuid,
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: employeeUuid,
        date: destinationDate,
      });

      this.superPunchStore.punches = {};
      this.updateData();
    } catch (err) {
      Sentry.addBreadcrumb("Punch validation failed");
      Sentry.sendError(err);

      if (punchKey) {
        const cellStr = `sp_${moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD")}${punchKey}`;
        fireEvent(`${cellStr}_failed`);
      }

      fireEvent(SuperpunchCustomEvents.sendError, this.props.t("Punch validation failed. Please try again."));
    }
  };

  onPunchApprove = async ({ destinationDate, punchKey, punchId, punchUuid, employeeUuid }: OnPunchActionData) => {
    Sentry.addBreadcrumb("Punch approve clicked");
    void this.fireDayStartedProcessing(moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD"));

    try {
      await validatePunchSP({
        punchId,
        punchUuid,
        requestedBy: window.global_store.profile.uuid,
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: employeeUuid,
        date: destinationDate,
      });

      this.superPunchStore.punches = {};
      this.updateData();
    } catch (err) {
      Sentry.addBreadcrumb("Punch approved failed");
      Sentry.sendError(err);

      if (punchKey) {
        const cellStr = `sp_${moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD")}${punchKey}`;
        fireEvent(`${cellStr}_failed`);
      }

      fireEvent(SuperpunchCustomEvents.sendError, this.props.t("Punch approving failed. Please try again."));
    }
  };

  onPunchDecline = async ({
    destinationDate,
    punchKey,
    punchId,
    punchUuid,
    employeeUuid,
    declineReason,
  }: OnPunchDeclineData) => {
    Sentry.addBreadcrumb("Punch decline clicked");
    void this.fireDayStartedProcessing(moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD"));

    try {
      await declinePunchSP({
        body: {
          content: {
            declineReason: declineReason || "",
          },
        },
        punchId,
        punchUuid,
        requestedBy: window.global_store.profile.uuid,
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: employeeUuid,
        date: destinationDate,
      });

      this.superPunchStore.punches = {};
      this.updateData();
    } catch (err) {
      Sentry.addBreadcrumb("Punch decline failed");
      Sentry.sendError(err);

      if (punchKey) {
        const cellStr = `sp_${moment(destinationDate, "YYYY-MM-DD").format("YYYYMMDD")}${punchKey}`;
        fireEvent(`${cellStr}_failed`);
      }

      fireEvent(SuperpunchCustomEvents.sendError, this.props.t("Punch decline failed. Please try again."));
    }
  };

  clearPunches = async (destinationDates: string[]) => {
    const { startDate, endDate, employeeUuid, employeeId } = this.state;
    const operations: ShiftCompilationOperation[] = [];
    const res = await this.prepareTableRows({
      startDate,
      endDate,
      employeeId,
      employeeUuid,
    });

    if (!res) {
      return;
    }

    res.tableRows.rows.forEach((row) => {
      const dateKey = this.getMomentKey(row.date.raw);

      if (destinationDates.includes(dateKey)) {
        const keys = Object.keys(row);

        keys.forEach((key) => {
          // if SuperpunchPunchCellData
          if (row[key as PunchKey]?.raw?.uuid) {
            operations.push({
              date: row.date.raw.format("YYYY-MM-DD"),
              user_profile_uuid: employeeUuid,
              shift_events: [
                {
                  uuid: null,
                  key: key as PunchKey,
                },
              ],
            });

            const cellData: SuperpunchPunchCellData = {
              display: this.props.t("Missing"),
              raw: null,
            };

            const cellStr = `sp_${dateKey}${key}`;
            fireEvent(cellStr, cellData);

            const combinedCellBreaksKey = generateEventKey(SuperpunchCustomEvents.cellCombinedBreaks, dateKey);
            fireEvent(combinedCellBreaksKey, { ...cellData, eventKey: key });

            void this.fireDayStartedProcessing(dateKey);
          }
        });
      }
    });

    await this.updateShiftCompilations(operations, employeeUuid);
  };

  organizePunchesChronologically = async (destinationDates: string[]) => {
    const { startDate, endDate, employeeUuid, employeeId } = this.state;
    const operations: ShiftCompilationOperation[] = [];
    const res = await this.prepareTableRows({
      startDate,
      endDate,
      employeeId,
      employeeUuid,
    });

    if (!res) {
      return;
    }

    const punchesResponse = await this.fetchPunches(
      {
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
      },
      true,
    );

    res.tableRows.rows.forEach((row) => {
      const dateKey = this.getMomentKey(row.date.raw);

      if (destinationDates.includes(dateKey) && row.shiftEvents?.length) {
        let punchesPerDay = punchesResponse.filter(
          (punch) => this.getMomentKey(momentTz.tz(punch.device_datetime, punch.time_zone)) === dateKey,
        );

        punchesPerDay = punchesPerDay.sort((pA, pB) => {
          const punchADate = momentTz.tz(pA.device_datetime, pA.time_zone);
          const punchBDate = momentTz.tz(pB.device_datetime, pB.time_zone);

          if (punchADate.isBefore(punchBDate)) {
            return -1;
          }
          if (punchADate.isAfter(punchBDate)) {
            return 1;
          }
          return 0;
        });

        if (punchesPerDay.length) {
          row.shiftEvents.forEach((shiftEvent, i) => {
            const cellStr = `sp_${dateKey}${shiftEvent.key}`;
            const cellData: SuperpunchPunchCellData = {
              display: this.props.t("Missing"),
              raw: null,
            };

            if (punchesPerDay[i]) {
              // if there is punch for event assign it
              const punch = punchesPerDay[i];
              cellData.display = momentTz.tz(punch.device_datetime, punch.time_zone).format("HH:mm");
              // TODO expects DailySummaryPunch but got Punch
              cellData.raw = {
                ...(punch as DailySummaryPunch),
                description: shiftEvent.type,
                type: shiftEvent.type,
                key: shiftEvent.key,
                timezone: punch.time_zone,
                time: punch.device_datetime,
                status: punch.status || "",
              };

              operations.push({
                date: row.date.raw.format("YYYY-MM-DD"),
                user_profile_uuid: employeeUuid,
                shift_events: [
                  {
                    uuid: punchesPerDay[i].uuid,
                    key: shiftEvent.key,
                  },
                ],
              });
            } // if there is no punch for event - clear cell

            fireEvent(cellStr, cellData);

            const combinedCellBreaksKey = generateEventKey(SuperpunchCustomEvents.cellCombinedBreaks, dateKey);
            fireEvent(combinedCellBreaksKey, { ...cellData, eventKey: shiftEvent.key });
          });

          void this.fireDayStartedProcessing(dateKey);
        }
      }
    });

    if (operations.length) {
      await this.updateShiftCompilations(operations, employeeUuid);
    }
  };

  recalculateRange = async ({ from, to }: Record<"from" | "to", string>) => {
    const { employeeUuid } = this.state;

    await forceRecalculateRange({
      userProfile: employeeUuid,
      from: moment(from, "YYYYMMDD").format("YYYY-MM-DD"),
      to: moment(to, "YYYYMMDD").format("YYYY-MM-DD"),
      requestedBy: window.global_store.profile.uuid,
      companyUuid: window.global_store.company.uuid,
    });
  };

  removeScheduleOnRange = async ({ dates }: { dates: string[] }) => {
    const { employeeUuid } = this.state;

    dates.forEach((date) => {
      const dateKey = this.getMomentKey(date);
      void this.fireDayStartedProcessing(dateKey);
    });

    await removeScheduleOnRange({
      userProfile: employeeUuid,
      body: {
        content: {
          dates: dates.map((date) => moment(date, "YYYYMMDD").format("YYYY-MM-DD")),
          updatedBy: window.global_store.profile.uuid,
        },
      },
      companyUuid: window.global_store.company.uuid,
    });
  };

  validateAllPunchesInDays = async ({ dates }: { dates: string[] }) => {
    const { employeeUuid } = this.state;

    dates.forEach((date) => {
      const dateKey = this.getMomentKey(date);
      void this.fireDayStartedProcessing(dateKey);
    });

    await validateAllRange({
      body: {
        user_profile_uuids: [employeeUuid],
        dates: dates.map((date) => moment(date, "YYYYMMDD").format("YYYY-MM-DD")),
      },
    });

    setTimeout(() => {
      this.updateData();
    }, 5000);
  };

  approveAllPunchesInDays = async ({ dates }: { dates: string[] }) => {
    const { employeeUuid } = this.state;

    dates.forEach((date) => {
      const dateKey = this.getMomentKey(date);
      void this.fireDayStartedProcessing(dateKey);
    });

    await approveAllRange({
      body: {
        user_profile_uuids: [employeeUuid],
        dates: dates.map((date) => moment(date, "YYYYMMDD").format("YYYY-MM-DD")),
      },
    });

    setTimeout(() => {
      this.updateData();
    }, 5000);
  };

  getCustomBreaksNamesMap = async () => {
    const company = await this.context.getCompany();
    if (!company) {
      return;
    }

    const { content } = await getCustomBreaksList({
      perPage: 300,
      page: 1,
      statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
      companyUuid: company.uuid,
      requestedBy: window.global_store.profile.uuid,
    });

    this.setState({ customBreaksNamesMap: getBreaksNamesMap(content) });
  };
  // ======  END OF CONTEXT VALUE METHODS =========

  render() {
    return (
      <SuperpunchContext.Provider
        value={{
          setContextState: this.setContextState,
          changePunch: this.changePunch,
          getTableRows: this.getTableRows,
          getBusinessRulesGroups: this.getBusinessRulesGroups,
          getCustomBreaksNamesMap: this.getCustomBreaksNamesMap,
          fetchPunches: this.fetchPunches,
          onPunchValidate: this.onPunchValidate,
          onPunchApprove: this.onPunchApprove,
          onPunchDecline: this.onPunchDecline,
          clearPunches: this.clearPunches,
          organizePunchesChronologically: this.organizePunchesChronologically,
          recalculateRange: this.recalculateRange,
          removeScheduleOnRange: this.removeScheduleOnRange,
          validateAllPunchesInDays: this.validateAllPunchesInDays,
          approveAllPunchesInDays: this.approveAllPunchesInDays,
          getEmployees: this.context.getEmployees,

          ...this.state,
        }}
      >
        {this.props.children}
      </SuperpunchContext.Provider>
    );
  }
}

export default withLDConsumer()(
  withTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.employeesPage])(SuperpunchProvider),
);
