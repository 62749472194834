import React, { Component } from "react";
import styled from "styled-components";
import rtl from "styled-components-rtl";

const Item = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  color: #a1b2cf;
  padding-inline-start: 27px;
  text-transform: uppercase;
  height: 36px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:before {
    content: "";
    width: 9px;
    position: absolute;
    ${rtl`
      left: 0;
    `}
    height: 36px;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.expanded {
    color: var(--colors-primary);
  }
  &.expanded:before {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='9' height='2' rx='1' fill='%231e97f7'/%3E%3C/svg%3E");
  }
  &.collapsed:before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 1a1 1 0 012 0v7a1 1 0 01-2 0V1z' fill='%23A1B2CF'/%3E%3Cpath d='M1.5 5.5a1 1 0 010-2h7a1 1 0 110 2h-7z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  }
  &.collapsed:hover {
    color: var(--colors-primary);
  }
  &.collapsed:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 1a1 1 0 012 0v7a1 1 0 01-2 0V1z' fill='%231e97f7'/%3E%3Cpath d='M1.5 5.5a1 1 0 010-2h7a1 1 0 110 2h-7z' fill='%231e97f7'/%3E%3C/svg%3E");
  }
`;

export default class GroupItem extends Component {
  state = {};
  render() {
    return <Item {...this.props} />;
  }
}
