import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  padding: 16px;
  box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-0);
  position: absolute;
  top: 8px;
  inset-inline-start: 0px;
  z-index: 1;
  max-width: 340px;
  align-items: center;
`;
const Label = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 15px;
  line-height: 22px;
  color: var(--colors-surface-900-p);
`;

type ConfirmationProps = {
  onConfirm: () => void;
  onClose: () => void;
};

const Confirmation = ({ onConfirm, onClose }: ConfirmationProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  return (
    <ClickOutside<ClickOutsideType> onClickOutside={() => onClose()}>
      <Wrapper>
        <Label>{t("day-night-split-confirmation")}</Label>
        <Button
          value={t(`${TranslationNamespaces.common}|Confirm`)}
          style={{ width: "auto", minWidth: "105px" }}
          state={ButtonState.primary}
          onClick={onConfirm}
        />
      </Wrapper>
    </ClickOutside>
  );
};

export default Confirmation;
