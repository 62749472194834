import { Component, ContextType } from "react";
import { Moment } from "moment";
import * as momentTz from "moment-timezone";
import BEM from "utils/BEM";
import "styles/punches-add-popup.scss";
import { getEmployee } from "utils/apiHelpers";
import { WithTranslation, withTranslation } from "react-i18next";
import { withLockDateCheck } from "components/HocWithLockDateCheck";
import { getLastLockDate } from "utils/common";
import SearchControl from "components/UI/SearchControlNew";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import styled from "styled-components";
import Button from "components/controls/StyledButton";
import FieldWrapper from "components/UI/FieldWrapper";
import { v4 as uuidv4 } from "uuid";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { RequestUserProfile, UserProfile } from "types/models/userProfile";
import { SearchObject } from "types/common";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import {
  ActivityCustomField,
  ActivityInCreatePayload,
  LocationVerificationStatus,
} from "components/Projects/projectsApiTypes";
import { Location } from "types/models/location";
import { CustomFieldType } from "types/models/projects";
import { TranslationNamespaces } from "types/translationNamespaces";
import GlobalContext from "context/global-context";
import { DateRange, extendMoment } from "moment-range";
import { listTimesheets } from "components/Timesheets";
import { translateEmployeeTerm } from "utils/translationHelpers";
import ActivityRow from "./ActivityRow";
import RetroactiveChangeWarning from "../Punches/RetroactiveChangeWarning";
import { isDateInTimesheet } from "./activitiesHelpers";

const moment = extendMoment(momentTz);

const b = BEM.b("punches-add-popup");

const Wrapper = styled.div`
  padding: 0 7px;
  overflow: visible;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  width: 160px;
`;
const NotificationBar = styled.div`
  width: 100%;
  margin-bottom: 24px;
  color: red;
`;
const SearchDateWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  .SingleDatePicker {
    margin-top: 0;
  }
`;
const ActivitiesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: 23px;
`;
const BlockTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  text-transform: uppercase;
  color: var(--colors-surface-800);
  margin: 32px 0 3px 0;
`;

const canSeachForEmployees = () => {
  const { profile } = window.global_store;

  return (
    profile?.permission_roles?.length &&
    profile?.permission_roles.some(
      (pr) =>
        pr.name.toLowerCase() === PermissionRoleName.admin.toLowerCase() ||
        pr.name.toLowerCase() === PermissionRoleName.owner.toLowerCase() ||
        pr.name.toLowerCase() === PermissionRoleName.supervisor.toLowerCase(),
    )
  );
};

export type ActivityToAdd = {
  uuid: string;
  startTime: null | number;
  endTime: null | number;
  locationUuid: string;
  projectUuid: string;
  taskUuid: string;
  customFields: ActivityCustomField[];
};

interface AddActivitiesProps extends WithTranslation {
  prefillProfile: UserProfile | null;
  employeeInfo: RequestUserProfile;
  isTimesheetEnabled: boolean;
  onClose: () => void;
  onYes: (activities: ActivityInCreatePayload[]) => void;
}

interface AddActivitiesState {
  errors: Record<string, string | Record<string, string>> | null;
  activitiesDate: Moment;
  lockedDate: Moment | null;
  userProfileUuid: string | null;
  employeeID: number | null;
  employeeFullName: string;
  employeeLocations: Location[];
  activitiesList: ActivityToAdd[];
  loading: boolean;
  saving: boolean;
  employee: SearchObject["employee"] | null;
  timesheetRanges: DateRange[];
  fetchedRange: {
    from: Moment;
    to: Moment;
  } | null;
}

class AddActivities extends Component<AddActivitiesProps, AddActivitiesState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: AddActivitiesProps) {
    super(props);
    const { prefillProfile, employeeInfo } = props;

    const state: AddActivitiesState = {
      loading: false,
      saving: false,
      errors: null,
      activitiesDate: moment(),
      lockedDate: null,
      userProfileUuid: null,
      employeeID: null,
      employeeFullName: "",
      employeeLocations: [],
      employee: null,
      activitiesList: [
        {
          uuid: uuidv4(),
          startTime: null,
          endTime: null,
          locationUuid: "",
          projectUuid: "",
          taskUuid: "",
          customFields: [],
        },
      ],
      timesheetRanges: [],
      fetchedRange: null,
    };

    if (prefillProfile) {
      state.loading = true;
      state.userProfileUuid = prefillProfile.uuid;
      state.employeeID = prefillProfile.id;
      state.employeeFullName = prefillProfile.fullName || prefillProfile.full_name;
    } else if (employeeInfo) {
      state.loading = true;
      state.userProfileUuid = employeeInfo.uuid;
      state.employeeID = employeeInfo.id;
      state.employeeFullName = employeeInfo.fullName;
      state.lockedDate =
        employeeInfo.lastLockDate || employeeInfo.last_lock_date ? moment(employeeInfo.last_lock_date).utc() : null;
    }
    this.state = state;
  }

  async componentDidMount() {
    if (!this.state.employeeID && !canSeachForEmployees()) {
      const { profile } = window.global_store;
      await this.setState({
        userProfileUuid: profile.uuid,
        employeeID: profile.id,
        employeeFullName: profile.full_name,
      });
    }

    const { activitiesDate, employeeID } = this.state;
    const { isTimesheetEnabled } = this.props;

    if (employeeID) {
      const employee = await this.getEmployeeDetails(employeeID);
      if (isTimesheetEnabled && employee?.uuid) {
        void this.getTimesheets({ userProfileUuid: employee.uuid, activitiesDate });
      }
      if (employee) {
        this.setState({
          loading: false,
          lockedDate: employee.last_lock_date ? moment(employee.last_lock_date).utc() : null,
          employeeLocations: employee.locations || [],
        });
      }
    }
  }

  getTimesheets = async ({ userProfileUuid, activitiesDate }: { userProfileUuid: string; activitiesDate: Moment }) => {
    const company = await this.context.getCompany();
    const from = activitiesDate.clone().subtract(1, "year").format("YYYY-MM-DD");
    const to = activitiesDate.clone().add(2, "month").format("YYYY-MM-DD");

    const response = await listTimesheets({
      companyUuid: company.uuid,
      userProfileUuid,
      requestedBy: window.global_store.profile.uuid,
      from,
      to,
    });

    const timesheetRanges = response.content
      .filter((r) => r.approvalStatus === "pending" || r.approvalStatus === "approved")
      .map((r) => moment.range(moment(r.startDate).startOf("day"), moment(r.endDate).endOf("day")));
    this.setState({
      fetchedRange: {
        from: moment(from),
        to: moment(to),
      },
      timesheetRanges,
    });
  };

  getEmployeeDetails = async (employeeID: number) => {
    let employee = null;
    const response = await getEmployee({
      id: employeeID,
      newHierarchyPermissions: true,
    });

    if (response) {
      employee = response.user_profile;
    }

    return employee;
  };

  onCancel() {
    this.props.onClose();
  }

  getFormErrors(activities: ActivityToAdd[]) {
    const { t } = this.props;
    const { activitiesDate, lockedDate, userProfileUuid } = this.state;
    let errors: Record<string, string | Record<string, string>> | null = {};

    if (lockedDate && moment(lockedDate).isSameOrAfter(activitiesDate, "day")) {
      errors.when = t("lock-date-error");
    }
    if (!userProfileUuid) {
      errors.employee = translateEmployeeTerm(
        t,
        TranslationNamespaces.common,
        "custom-please-select-employee",
        `${TranslationNamespaces.common}|please-select-employee`,
      );
    }
    if (!activitiesDate) {
      errors.when = t("When can't be blank");
    }
    let activitiesErrors = {};

    if (Object.keys(errors).length === 0) {
      activities.forEach((activity) => {
        const activityError = this.validateActivity(activity);
        if (activityError) activitiesErrors[activityError.uuid] = activityError.error;
      });
    }

    if (Object.keys(activitiesErrors).length === 0) {
      activitiesErrors = false;
    }
    if (activitiesErrors) {
      errors.activities = activitiesErrors;
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }
    return errors;
  }

  validateActivity = (activity: ActivityToAdd) => {
    const { uuid, startTime, endTime, locationUuid, taskUuid, customFields } = activity;

    if (!startTime && startTime !== 0) {
      return { uuid, error: { startTime: "Start time can't be empty" } };
    }

    if (!endTime && endTime !== 0) {
      return { uuid, error: { endTime: "End time can't be empty" } };
    }

    if (startTime === endTime || startTime > endTime) {
      return {
        uuid,
        error: { startTime: "Start time should be after the end time" },
      };
    }

    if (!locationUuid) {
      return { uuid, error: { locationUuid: "Please select location" } };
    }

    if (!taskUuid) {
      return { uuid, error: { taskUuid: "Please select task" } };
    }

    for (let i = 0; i < customFields.length; i += 1) {
      const cf = customFields[i];
      if (cf.required && !cf.value) {
        return {
          uuid,
          error: {
            customFields: `Custom field "${cf.name}" can't be empty`,
            customFieldUuid: cf.uuid,
          },
        };
      }

      if (cf.value && cf.fieldType === CustomFieldType.numeric) {
        const numStr = cf.value ? cf.value.replace(",", ".") : "0";
        const numberRe = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        const isNumeric = numberRe.test(numStr) && !Number.isNaN(parseFloat(numStr));
        if (!isNumeric) {
          return {
            uuid,
            error: {
              customFields: `Custom field "${cf.name}" should be numeric`,
              customFieldUuid: cf.uuid,
            },
          };
        }
      }
    }
  };

  onYes = () => {
    const { onYes } = this.props;
    const { activitiesList, activitiesDate, employee, userProfileUuid, employeeLocations } = this.state;
    const errors = this.getFormErrors(activitiesList);

    if (!errors) {
      this.setState({ saving: true });
      const enchancedActivities: ActivityInCreatePayload[] = activitiesList
        .filter(({ startTime, endTime }) => startTime !== null && endTime !== null)
        .map(({ startTime, endTime, locationUuid, projectUuid, taskUuid, customFields }) => {
          const location = employeeLocations.find((loc) => loc.uuid === locationUuid);
          return {
            date: activitiesDate.format("YYYY-MM-DD"),
            startTime: startTime as number,
            endTime: endTime as number,
            locationUuid,
            locationVerification: {
              status:
                location?.verification_methods?.length !== 0
                  ? LocationVerificationStatus.skipped
                  : LocationVerificationStatus.none,
            },
            taskUuid,
            projectUuid,
            userProfileUuid: employee?.uuid ? employee.uuid : userProfileUuid,
            customFields: customFields.map((cf) => {
              let { value } = cf;
              if (cf.fieldType === "numeric") {
                value = value ? value.replace(",", ".") : "0";
              }
              const cfForSave = { uuid: cf.uuid, value, name: cf.name };
              return cfForSave;
            }),
          };
        });

      if (onYes) {
        onYes(enchancedActivities);
      }

      return;
    }

    this.setState({ errors, saving: false });
  };

  selectDate = async (date: Moment) => {
    const { userProfileUuid, activitiesDate } = this.state;
    const { isTimesheetEnabled } = this.props;
    if (date === activitiesDate) return;
    if (isTimesheetEnabled && userProfileUuid && date) {
      void this.getTimesheets({ userProfileUuid, activitiesDate: date });
    }
    this.setState({ activitiesDate: date, errors: null });
  };

  addActivity = () => {
    const { activitiesList } = this.state;
    activitiesList.push({
      uuid: uuidv4(),
      startTime: null,
      endTime: null,
      projectUuid: "",
      locationUuid: "",
      taskUuid: "",
      customFields: [],
    });

    this.setState({ activitiesList });
  };

  removeActivity = (activity: ActivityToAdd) => {
    let { activitiesList } = this.state;
    activitiesList = activitiesList.filter((act) => act.uuid !== activity.uuid);
    this.setState({ activitiesList });
  };

  updateActivity = (activity: ActivityToAdd) => {
    let { activitiesList } = this.state;
    activitiesList = activitiesList.map((act) => {
      if (act.uuid === activity.uuid) {
        return activity;
      }
      return act;
    });
    this.setState({ activitiesList });
  };

  onEmployeeSearch = async (searchObj: SearchObject | Record<string, never>) => {
    let { activitiesList } = this.state;
    const { activitiesDate, userProfileUuid } = this.state;
    const { isTimesheetEnabled } = this.props;
    let lockDate = null;
    let employee = null;
    this.setState({ loading: true });
    employee = searchObj.employee ? searchObj.employee : searchObj;

    lockDate = employee && getLastLockDate(employee) ? moment(getLastLockDate(employee)).utc() : null;
    if (employee?.id) {
      if (isTimesheetEnabled && employee.uuid) {
        await this.getTimesheets({ userProfileUuid: employee.uuid, activitiesDate });
      }
      const emp = await this.getEmployeeDetails(employee.id as number);
      if (emp) {
        activitiesList = activitiesList.map((act) => {
          const activity = act;
          if (userProfileUuid !== emp.uuid) {
            activity.taskUuid = "";
            activity.locationUuid = "";
            activity.projectUuid = "";
          }
          return activity;
        });

        this.setState({
          userProfileUuid: emp.uuid,
          employeeID: emp.id,
          employeeFullName: emp.fullName,
          employee: emp,
          lockedDate: lockDate,
          employeeLocations: emp.locations || [],
          activitiesList,
          errors: null,
          loading: false,
        });
      }
    } else {
      this.setState({
        userProfileUuid: null,
        employeeID: null,
        employeeFullName: "",
        employeeLocations: [],
        lockedDate: null,
        employee: null,
        errors: null,
        loading: false,
      });
    }
  };

  render() {
    const {
      errors,
      activitiesDate,
      lockedDate,
      employeeLocations,
      loading,
      activitiesList,
      userProfileUuid,
      saving,
      timesheetRanges,
    } = this.state;

    const { t, isTimesheetEnabled } = this.props;
    const hasTimesheetOnselectedDate = isTimesheetEnabled && isDateInTimesheet(timesheetRanges, activitiesDate);

    const generalError = hasTimesheetOnselectedDate ? t("timesheet-already-submitted") : null;

    const buttonProps =
      saving || hasTimesheetOnselectedDate
        ? { disabled: true }
        : {
            onDoubleClick: this.onYes,
            onClick: this.onYes,
          };

    return (
      <Wrapper>
        {generalError && <NotificationBar>{generalError}</NotificationBar>}
        <SearchDateWrapper>
          {canSeachForEmployees() ? (
            <>
              <FieldWrapper
                fieldName={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-employee",
                  `${TranslationNamespaces.common}|Employee`,
                )}
                width="358px"
              >
                <SearchControl
                  value={this.state.employeeFullName ? this.state.employeeFullName : undefined}
                  onClear={() =>
                    this.setState({
                      errors: null,
                      userProfileUuid: null,
                      employee: null,
                      activitiesList: activitiesList.map((act) => {
                        const activity = act;

                        activity.taskUuid = "";
                        activity.locationUuid = "";
                        activity.projectUuid = "";

                        return activity;
                      }),
                    })
                  }
                  onChange={this.onEmployeeSearch}
                  placeholder={translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-search-employees",
                    `${TranslationNamespaces.common}|Search Employee`,
                  )}
                  permissionSection={PermissionSectionName.activities}
                />
              </FieldWrapper>
            </>
          ) : null}

          <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Date`)} width="167px">
            <SingleDatePickerControl
              numberOfMonths={1}
              value={activitiesDate}
              error={errors?.when}
              onChange={(val) => this.selectDate(val)}
              isOutsideRange={(day) =>
                lockedDate
                  ? day.isSameOrBefore(lockedDate, "day")
                  : day.isBefore(moment().subtract(window.global_store.beta ? 24 : 2, "month"))
              }
            />
          </FieldWrapper>
        </SearchDateWrapper>
        {errors?.employee && <div className={b("error")}>{errors.employee}</div>}
        <BlockTitle>{t("CLOCK INS & ACTIVITIES")}</BlockTitle>
        <ActivitiesList key={userProfileUuid || ""}>
          {activitiesList.map((activity, i) => (
            <ActivityRow
              loading={loading}
              errors={errors?.activities ? errors.activities[activity.uuid] : null}
              employeeLocations={employeeLocations}
              employeeUuid={userProfileUuid}
              key={activity.uuid}
              activity={activity}
              onRemove={activitiesList.length > 1 ? this.removeActivity : null}
              onUpdate={this.updateActivity}
            />
          ))}
        </ActivitiesList>

        <AddRemoveLink label={t("Add new task")} onClick={this.addActivity} />

        {moment(activitiesDate).isBefore(moment().subtract(1, "day").startOf("day")) && (
          <RetroactiveChangeWarning style={{ marginTop: "25px", width: "358px" }} />
        )}

        <div className={b("buttons")}>
          <ButtonWrapper>
            <Button value={t(`${TranslationNamespaces.common}|Save`)} {...buttonProps} />
          </ButtonWrapper>
        </div>
      </Wrapper>
    );
  }
}

export default withLockDateCheck(withTranslation(TranslationNamespaces.punchesPage)(AddActivities));
