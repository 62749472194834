import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SearchControl from "components/UI/SearchControlNew";
import Select from "components/UI/Select";
import DatePeriodsSelector from "components/UI/DatePeriodsSelector";
import { hasEmployeesAccess, urlParam } from "utils/common";
import { getLockPeriodsForUserProfileForReports } from "utils/apiHelpers";
import moment from "moment";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import { ReportParams, ReportType } from "types/reports";
import { SelectOption } from "types/ui";
import { SearchObject } from "types/common";
import { LockPeriodsForUserProfileForReports } from "types/models/lockPeriods";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga from "utils/ga";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { GenerateButton, LateArrivalPunchTime, ResetButton, SearchControlWrapper } from "./reportsStyledComponents";

interface ReportSearchFiltersState extends Partial<ReportParams> {
  isGenerateButtonLocked: boolean;
  searchObj?: SearchObject | null;
  showReport: boolean;
  notification?: {
    message: string;
    type: string;
  } | null;
}

interface ReportSearchFiltersProps extends WithTranslation {
  reportType: ReportType;
  isLoading?: boolean;
  onGenerateButtonClick: (state: ReportSearchFiltersState) => void;
  onResetFilters: (state: ReportSearchFiltersState) => void;
}

const DatePeriodsSelectorWrapper = styled.div`
  margin-inline-start: 16px;
`;

const DigitalSignatureStatusWrapper = styled.div`
  margin-inline-start: 16px;
  margin-inline-end: 16px;
`;

export const REPORT_LOADING_EVENT = "report_loading";

export interface ReportLoadingCustomEvent extends Event {
  detail?: {
    isLoading: boolean;
  };
}

const GENERATE_BTN_LOCK_TIMEOUT = 5000;

class ReportSearchFilters extends Component<ReportSearchFiltersProps, ReportSearchFiltersState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  PUNCH_TYPES: SelectOption[];
  SIGNATURE_TYPES: SelectOption[];
  generateButtonLockTimeout?: NodeJS.Timeout;

  constructor(props: ReportSearchFiltersProps) {
    super(props);
    const { t, reportType } = props;

    this.PUNCH_TYPES = [
      { value: "", label: t("All") },
      { value: "entry", label: t("Entry") },
      { value: "break_end", label: t("Break End") },
    ];
    this.SIGNATURE_TYPES = [
      { value: "", label: t("all-signatures") },
      { value: "pending", label: t(`${TranslationNamespaces.common}|Pending`) },
      { value: "missing", label: t("Missing") },
      { value: "signed", label: t("Signed") },
    ];

    this.state = {
      oldReport: reportType === ReportType.punches || reportType === ReportType.lateArrival,
      onlyNonZeroPhases: false,
      showReport: false,
      onlyNonZeroLines: false,
      selectedPunchType: this.PUNCH_TYPES[0].value,
      selectedSignatureType: this.SIGNATURE_TYPES[0].value,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      searchObj: hasEmployeesAccess()
        ? {}
        : {
            employee: {
              avatar_id: window.global_store.profile.avatar_id,
              full_name: window.global_store.profile.full_name,
              id: window.global_store.profile.id,
              job_description: window.global_store.profile.job_description,
              position_id: window.global_store.profile.position_id,
              uuid: window.global_store.profile.uuid,
            },
            id: window.global_store.profile.id,
            label: window.global_store.profile.name,
          },
      isGenerateButtonLocked: false,
    };
  }

  async componentDidMount() {
    const state = this.getStateFromUrlParms();
    document.addEventListener(REPORT_LOADING_EVENT, this.unlockGenerateButton);

    if (state) {
      this.setState({ ...state }, () => this.props.onGenerateButtonClick(this.state));
    } else {
      const defaultPayPeriod = await this.context.getDefaultPayPeriod();

      if (defaultPayPeriod) {
        this.setState({
          startDate: moment(defaultPayPeriod.startDate),
          endDate: moment(defaultPayPeriod.endDate),
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener(REPORT_LOADING_EVENT, this.unlockGenerateButton);
  }

  getStateFromUrlParms = () => {
    let state = null;
    const uuid = urlParam("uuid");
    const id = urlParam("id");
    const label = urlParam("label");
    const startDate = urlParam("startDate");
    const endDate = urlParam("endDate");

    if (uuid && id && label && startDate && endDate) {
      state = {
        startDate: moment(startDate, "YYYY-MM-DD"),
        endDate: moment(endDate, "YYYY-MM-DD"),
        searchObj: {
          id,
          uuid,
          label,
          employee: {
            uuid,
          },
        },
      };
    }

    return state;
  };

  onSearchChange = async (value: SearchObject) => {
    let skipSupervisorsLocal = this.state.skipSupervisors;
    let onlyNonZeroLinesLocal = this.state.onlyNonZeroLines;

    if (value?.employee?.uuid) {
      skipSupervisorsLocal = false;
      onlyNonZeroLinesLocal = false;

      const resp = await getLockPeriodsForUserProfileForReports({
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: value.employee.uuid,
      });

      if (resp?.content[0]?.months[0]?.ranges?.length) {
        const { ranges }: { ranges: LockPeriodsForUserProfileForReports[] } = resp.content[0].months[0];
        let range = ranges[ranges.length - 1];

        if (ranges.length > 1) {
          ranges.forEach((r) => {
            if (
              moment(r.start, "YYYY-MM-DD").isSameOrBefore(moment()) &&
              moment(r.end, "YYYY-MM-DD").isSameOrAfter(moment())
            ) {
              range = r;
            }
          });
        }

        this.setState({
          skipSupervisors: skipSupervisorsLocal,
          onlyNonZeroLines: onlyNonZeroLinesLocal,
          searchObj: value,
          startDate: moment(range.start, "YYYY-MM-DD"),
          endDate: moment(range.end, "YYYY-MM-DD"),
          isGenerateButtonLocked: false,
        });
      } else {
        this.setState({
          searchObj: value,
          skipSupervisors: skipSupervisorsLocal,
          onlyNonZeroLines: onlyNonZeroLinesLocal,
          isGenerateButtonLocked: false,
        });
      }
    } else {
      this.setState({
        searchObj: value,
        skipSupervisors: skipSupervisorsLocal,
        onlyNonZeroLines: onlyNonZeroLinesLocal,
        isGenerateButtonLocked: false,
      });
    }
  };

  unlockGenerateButton = ({ detail }: ReportLoadingCustomEvent) => {
    const isReportLoading = detail?.isLoading || false;

    // report is loaded earlier than GENERATE_BTN_LOCK_TIMEOUT ms
    if (!isReportLoading) {
      if (this.generateButtonLockTimeout) {
        clearTimeout(this.generateButtonLockTimeout);
      }

      this.setState({
        isGenerateButtonLocked: false,
      });
    }
  };

  onGenerateButtonClick = () => {
    const { onGenerateButtonClick, reportType } = this.props;
    const { isGenerateButtonLocked, searchObj } = this.state;

    if (!isGenerateButtonLocked) {
      if (searchObj && Object.keys(searchObj).length) {
        this.setState({ isGenerateButtonLocked: true }, () => {
          onGenerateButtonClick(this.state);

          ga.trackGenerateReport(reportType);

          // unlock ganerate button if loading duration is more than 5 seconds
          this.generateButtonLockTimeout = setTimeout(() => {
            this.setState({ isGenerateButtonLocked: false });
          }, GENERATE_BTN_LOCK_TIMEOUT);
        });
      }
    }
  };

  onResetButtonClick = () => {
    const { onResetFilters } = this.props;
    const state: ReportSearchFiltersState = {
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      showReport: false,
      selectedSignatureType: this.SIGNATURE_TYPES[0].value,
      notification: null,
      showInactiveEmploees: false,
      onlyNonZeroPhases: false,
      skipSupervisors: false,
      onlyNonZeroLines: false,
      isGenerateButtonLocked: false,
    };

    if (hasEmployeesAccess()) {
      state.searchObj = {};
    }

    this.setState(state, () => onResetFilters(state));
  };

  isGroupReport = () => {
    const { reportType } = this.props;
    const { searchObj } = this.state;

    return (
      reportType === ReportType.summary ||
      reportType === ReportType.checkin ||
      reportType === ReportType.digitalSignaturesStatus ||
      ((reportType === ReportType.lateArrival ||
        reportType === ReportType.extraHours ||
        reportType === ReportType.hoursbank) &&
        searchObj &&
        (!searchObj.id || searchObj.id === "all" || searchObj.type))
    );
  };

  render() {
    const { t, reportType } = this.props;
    const {
      startDate,
      endDate,
      selectedPunchType,
      selectedSignatureType,
      showInactiveEmploees,
      skipSupervisors,
      onlyNonZeroLines,
      onlyNonZeroPhases,
      isGenerateButtonLocked,
    } = this.state;
    const availableDaysCount = window.global_store.beta ? 1900 : 186;
    const isAditionalFiltersAvailable = (type: ReportType) =>
      type === ReportType.lateArrival ||
      type === ReportType.summary ||
      type === ReportType.extraHours ||
      type === ReportType.hoursbank;

    return (
      <>
        {hasEmployeesAccess() && (
          <SearchControlWrapper>
            <SearchControl
              permissionSection={
                [ReportType.punches, ReportType.extraHours, ReportType.hoursbank, ReportType.lateArrival].includes(
                  reportType,
                )
                  ? PermissionSectionName.basicReports
                  : PermissionSectionName.advancedReports
              }
              searchGroups={
                reportType === ReportType.digitalSignaturesStatus ||
                reportType === ReportType.lateArrival ||
                reportType === ReportType.extraHours ||
                reportType === ReportType.summary ||
                reportType === ReportType.checkin ||
                reportType === ReportType.hoursbank
              }
              groupsOnly={
                reportType === ReportType.summary ||
                reportType === ReportType.checkin ||
                reportType === ReportType.digitalSignaturesStatus
              }
              showAllEmployeesItem={
                hasEmployeesAccess() &&
                (reportType === ReportType.lateArrival ||
                  reportType === ReportType.digitalSignaturesStatus ||
                  reportType === ReportType.hoursbank ||
                  reportType === ReportType.extraHours ||
                  reportType === ReportType.summary ||
                  reportType === ReportType.checkin)
              }
              onChange={this.onSearchChange}
              onClear={() => this.setState({ searchObj: null })}
              placeholder={translateEmployeeTerm(
                t,
                TranslationNamespaces.common,
                "custom-search-employees",
                `${TranslationNamespaces.common}|Search Employee`,
              )}
            />
          </SearchControlWrapper>
        )}
        {reportType === ReportType.lateArrival && (
          <LateArrivalPunchTime>
            <Select
              value={selectedPunchType}
              onChange={(value: string) => this.setState({ selectedPunchType: value, isGenerateButtonLocked: false })}
              options={this.PUNCH_TYPES}
            />
          </LateArrivalPunchTime>
        )}

        {isAditionalFiltersAvailable(reportType) && (
          <AdditionalFiltersControl
            closeOnChange={false}
            options={[
              {
                label: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.reportsPage,
                  "custom-show-inactive-employees",
                  `${TranslationNamespaces.reportsPage}|Show inactive employees`,
                ),
                checked: showInactiveEmploees,
                onChange: (val: boolean) => this.setState({ showInactiveEmploees: val, isGenerateButtonLocked: false }),
              },
              {
                label: t("Filter out phases with only zeros"),
                checked: onlyNonZeroPhases,
                onChange: (val: boolean) => this.setState({ onlyNonZeroPhases: val, isGenerateButtonLocked: false }),
                hide: reportType !== ReportType.extraHours && reportType !== ReportType.hoursbank,
              },
              {
                label: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.reportsPage,
                  "custom-filter-out-lines-with-0",
                  "Filter out lines with only zeros",
                ),
                checked: onlyNonZeroLines,
                onChange: (val: boolean) => this.setState({ onlyNonZeroLines: val, isGenerateButtonLocked: false }),
                hide: !this.isGroupReport(),
              },
              {
                label: t("Hide Supervisor"),
                checked: skipSupervisors,
                onChange: (val: boolean) => this.setState({ skipSupervisors: val, isGenerateButtonLocked: false }),
              },
            ]}
          />
        )}
        {reportType === ReportType.digitalSignaturesStatus && (
          <AdditionalFiltersControl
            closeOnChange={false}
            options={[
              {
                label: translateEmployeeTerm(
                  t,
                  TranslationNamespaces.reportsPage,
                  "custom-show-inactive-employees",
                  `${TranslationNamespaces.reportsPage}|Show inactive employees`,
                ),
                checked: showInactiveEmploees,
                onChange: (val: boolean) => this.setState({ showInactiveEmploees: val, isGenerateButtonLocked: false }),
              },
            ]}
          />
        )}

        {!window.global_store.beta && this.isGroupReport() ? (
          <DatePeriodsSelectorWrapper>
            <DatePeriodsSelector
              onChange={(sd: moment.Moment, ed: moment.Moment) => {
                this.setState({ startDate: sd, endDate: ed, isGenerateButtonLocked: false });
              }}
              selectedRange={{
                start: startDate?.format("YYYY-MM-DD"),
                end: endDate?.format("YYYY-MM-DD"),
              }}
            />
          </DatePeriodsSelectorWrapper>
        ) : (
          <DateRangePicker
            newOnChangeApproach
            availableDaysCount={availableDaysCount}
            isAdmin={hasEmployeesAccess()}
            onChange={(sd, ed) => {
              if (sd && ed) {
                this.setState({ startDate: sd, endDate: ed, isGenerateButtonLocked: false });
              }
            }}
            startDate={startDate || null}
            endDate={endDate || null}
          />
        )}
        {window.global_store.beta && reportType === ReportType.digitalSignaturesStatus ? (
          <DigitalSignatureStatusWrapper>
            <Select
              value={selectedSignatureType}
              onChange={(value: string) =>
                this.setState({ selectedSignatureType: value, isGenerateButtonLocked: false })
              }
              options={this.SIGNATURE_TYPES}
            />
          </DigitalSignatureStatusWrapper>
        ) : null}
        <GenerateButton disabled={isGenerateButtonLocked} onClick={this.onGenerateButtonClick}>
          {t("Generate")}
        </GenerateButton>
        <ResetButton onClick={this.onResetButtonClick}>{t("Reset")}</ResetButton>
      </>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportSearchFilters);
