import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import * as images from "components/svg-images";
import { Groups } from "types/models/dashboard";
import { ControlWrapper, ControlSelectedValue } from "../styled";

interface GroupBySelectorProps {
  value: Groups;
  onSelect: (value: Groups) => void;
}

const GroupBySelector = ({ onSelect, value }: GroupBySelectorProps) => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const options = [
    { value: Groups.company, label: t("None") },
    { value: Groups.subsidiaries, label: t("Subisidiaries") },
    { value: Groups.departments, label: t("Departaments") },
    // { value: Groups.postions, label: t("Postions") },
    { value: Groups.teams, label: t("Teams") },
  ];
  const label = options.find((o) => o.value === value)?.label || "";

  return (
    <ControlWrapper>
      <SimpleMenu onChange={onSelect} options={options} useClick>
        <ControlSelectedValue>
          {t("Group by")} <span>{label}</span> {images.smallArrowDown({ width: "7px", height: "4px" })}
        </ControlSelectedValue>
      </SimpleMenu>
    </ControlWrapper>
  );
};

export default GroupBySelector;
