import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import EmbeddedDashboardPage from "components/Dashboard/EmbeddedDashboardPage";
import DashboardPage from "components/Dashboard/DashboardPage";
import { LocalStorageKeys, getObjectFromLocalStorage } from "utils/localStorageUtils";

const RouteDashboard = ({ match: { path } }: RouteComponentProps) => {
  const dashboardNames: string[] = getObjectFromLocalStorage(LocalStorageKeys.companyDashboards) || [];

  return (
    <Suspense fallback={<div />}>
      <Switch>
      {dashboardNames.map((name: string) => [
        <Redirect key={`${name}-redirect`} strict from={`${path}/${name}/`} to={`${path}/${name}`} />,
        <Route
          key={`${name}-route`}
          path={`${path}/${name}`}
          strict
          render={(): JSX.Element => (
            <EmbeddedDashboardPage dashboardType={name} />
          )}
        />,
      ])}
        <Redirect strict from={`${path}/`} to={`${path}`} />
        <Route strict path={`${path}`} component={DashboardPage} />
      </Switch>
    </Suspense>
  )
};

export default RouteDashboard;
