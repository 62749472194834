import ProgressBar from "components/ProgressBar";
import LoadingBar from "components/styled/LoadingBar";
import styled from "styled-components";

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 29px;
  justify-content: start;
  gap: 16px;
`;

const ProgressBarWrapper = styled.div`
  --progress-bar-bg-color: var(--cp-progress-bar-bg-color, var(--colors-surface-100));
  --progress-bar-progress-color: var(--cp-progress-bar-progress-color, var(--colors-primary-500-p));

  position: relative;
  width: 100px;
  min-width: 100px;
  height: 7px;

  .progress-bar {
    width: 100px;
    background-color: var(--progress-bar-bg-colo);

    .progress-bar__bar-wrapper {
      width: 100px;
      top: 0;
      bottom: auto;
      margin: 0;

      .progress-bar__progress {
        background-color: var(--progress-bar-progress-color);
      }
    }
  }
`;

const ProgressValue = styled.div`
  color: var(--colors-surface-800);
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
`;

interface ProgressCellProps {
  currentValue: number;
  targetValue: number;
  isLoading?: string;
  usePercents?: boolean;
}

const ProgressCell = ({ currentValue, targetValue, isLoading, usePercents }: ProgressCellProps) => {
  const progressWidth = currentValue && (currentValue / targetValue) * 100;

  return (
    <CellWrapper>
      {!isLoading && (
        <>
          <ProgressBarWrapper>
            <ProgressBar width={progressWidth} />
          </ProgressBarWrapper>
          <ProgressValue>
            {usePercents ? `${progressWidth.toFixed(0)}%` : `${currentValue} / ${targetValue}`}
          </ProgressValue>
        </>
      )}

      {isLoading && <LoadingBar width={160} />}
    </CellWrapper>
  );
};

export default ProgressCell;
