import { Marker, MarkerProps } from "react-google-maps";

interface OitchauMarkerProps {
  position: MarkerProps["position"];
  onDragStart: MarkerProps["onDragStart"];
  onDragEnd: MarkerProps["onDragEnd"];
  draggable: MarkerProps["draggable"];
}

const OitchauMarker = ({ position, onDragStart, onDragEnd, draggable }: OitchauMarkerProps) => (
  <Marker
    position={position}
    draggable={draggable}
    cursor={draggable ? "grab" : "pointer"}
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    icon={{
      path: "M11.367 27a2.3 2.3 0 0 1-.944-.204c-1.921-.864-4.19-2.381-6.08-4.378C1.6 19.52 0 15.996 0 11.855 0 5.317 5.143.008 11.499 0 17.857.008 23 5.317 23 11.855c0 4.141-1.6 7.666-4.342 10.563-1.89 1.997-4.161 3.515-6.08 4.378-.3.135-.621.204-.945.204-.048 0-.09-.003-.134-.008a1.13 1.13 0 0 1-.132.008zM12 8a4.001 4.001 0 0 0 0 8 4.001 4.001 0 0 0 0-8z",
      // --colors-unknown37
      fillColor: "#69A7EF",
      fillOpacity: 1,
      anchor: new window.google.maps.Point(12, 27),
      strokeWeight: 0,
      scale: 1,
    }}
  />
);

export default OitchauMarker;
