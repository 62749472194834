import { Component } from "react";
import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";
import { SuperpunchCustomEvents } from "context/SuperpunchProvider/types";

type SuperpunchErrorNotificationState = {
  notification: string | null;
};

class SuperpunchErrorNotification extends Component<Record<string, never>, SuperpunchErrorNotificationState> {
  readonly state: Readonly<SuperpunchErrorNotificationState> = {
    notification: null,
  };

  UNSAFE_componentWillMount() {
    document.addEventListener(SuperpunchCustomEvents.sendError, this.receiveNotification);
  }

  componentWillUnmount() {
    document.removeEventListener(SuperpunchCustomEvents.sendError, this.receiveNotification);
  }

  receiveNotification = (ev: CustomEvent<string>): void => {
    this.setState({ notification: ev.detail });
  };

  render() {
    const { notification } = this.state;

    if (notification) {
      return (
        <NotificationRow
          employeesPage
          withCloseButton
          type={NotificationType.error}
          onClose={() => this.setState({ notification: "" })}
          message={<div style={{ paddingTop: "9px" }}>{this.state.notification}</div>}
        />
      );
    }
    return null;
  }
}

export default SuperpunchErrorNotification;
