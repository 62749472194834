import { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "components/UI/Select";
import BEM from "../../utils/BEM";
import { getScheduleTemplates } from "../../utils/apiHelpers";
import "../../styles/schedule-templates-dropdown.scss";

const b = BEM.b("schedule-templates-dropdown");

class ScheduleTemplatesDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [this.props.defaultItem],
    };
  }
  componentDidMount() {
    getScheduleTemplates().then((r) => {
      const { t } = this.props;
      const { items } = this.state;

      if (r && r.content && r.content.length > 0) {
        r.content.forEach((template) => {
          if (template.scheduleType === this.props.scheduleType) {
            const name = template.name
              .split("|")
              .map((partialName) => t(partialName.trim()))
              .join(" | ");
            items.push({ value: template.uuid, label: name, obj: template });
          }
        });
      }

      this.setState({ items }, () => {
        if (
          this.props.newSchedule &&
          this.props.scheduleType === "shifts" &&
          items.length > 1 &&
          items.filter((t) => t.label === "12x36 Hours").length > 0
        ) {
          this.props.onChange(items.filter((t) => t.label === "12x36 Hours")[0]);
        }
      });
    });
  }

  onChange = (val) => {
    const { items } = this.state;
    const selectedValue = items.find((item) => item.value === val) || this.props.defaultItem;
    this.props.onChange && this.props.onChange(selectedValue);
  };

  render() {
    const { label, value } = this.props;
    const { items } = this.state;
    const selectedValue = value.value;

    if (items.length > 1) {
      return (
        <div className={b()}>
          <div className={b("label")}>{label}</div>
          <Select
            onChange={this.onChange}
            error={this.props.error}
            value={selectedValue}
            style={{ maxWidth: "335px" }}
            options={items}
          />
        </div>
      );
    }
    return null;
  }
}

export default withTranslation("schedules")(ScheduleTemplatesDropdown);
