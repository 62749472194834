import styled from "styled-components";

export const EmbeddedDashboardPageWrapper = styled.div`
  .page-content-children {
    padding: 0 !important;
  }
`;

export const DashboardPageWrapper = styled.div`
  .full-page .main-panel {
    position: relative;
    background: var(--colors-surface-0);
  }
`;

export const ChartWrapper = styled.div`
  position: relative;
  margin: 24px auto;
  width: 1240px;
  padding: 24px;
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-surface-150);
  border-radius: var(--shapes-border-radius-default);
`;

export const ChartTitle = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 100%;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-900-p);
`;

export const ChartControls = styled.div`
  display: flex;

  & > div {
    margin-inline-end: 20px;
  }
`;

export const ChartDataPlot = styled(ChartControls)`
  display: flex;
  justify-content: center;
`;

export const FusionChartsWrapper = styled.div`
  position: relative;

  .ttEntry {
    display: flex;
    justify-content: space-between;
    width: 150px;
    padding-inline-start: 3px;
    font-size: var(--typography-font-size-default);
    line-height: 32px;
    color: var(--colors-surface-0);

    .ttLabel {
      display: none;
    }

    .ttSeries {
      padding-inline-end: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ttValue {
      font-weight: var(--typography-font-weight-bold);
    }
  }

  // PROD-10619 if some row has no value - hide row
  .fc__tooltip > div:has(.entryValue-) {
    display: none !important;
  }

  // PROD-10619 show tooltip if it has at least one row with non-empty value
  .fc__tooltip:has(> div > div > div:not([class="ttEntry entryValue-"])) {
    display: block !important;
  }

  // PROD-10619 otherwise do not show tooltip. (Second !important should not overwrite first one)
  .fc__tooltip {
    display: none !important;
  }
`;

export const ChartNoData = styled.div`
  position: absolute;
  display: inline-block;
  top: 114px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  padding: 10px;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 100%;
  color: var(--colors-surface-400);
  background: var(--colors-surface-0);
`;

export const ControlWrapper = styled.div`
  .ui-select__wrapper div {
    margin: 0;
  }

  .ui-select__dropdown-wrapper {
    width: 100%;
  }
`;

export const ControlSelectedValue = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 130px;
  padding: 8px 10px;
  padding-inline-end: 27px;
  background: ${(p) => (p.isOpen ? "var(--colors-surface-50)" : "inherit")};
  border-radius: var(--shapes-border-radius-default);
  color: var(--colors-surface-600);
  font-size: 15px;
  line-height: 100%;
  cursor: pointer;

  span {
    margin: 0 4px;
    color: var(--colors-surface-800);
  }
`;
