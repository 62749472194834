import { Component } from "react";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import TimeControl from "components/controls/TimeControl";
import { WithTranslation, withTranslation } from "react-i18next";
import { ErrorLabel } from "components/UI/TextLabels";
import LocationsDropdown from "components/LocationsDropdown";
import { minsToHrsMins } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { LocationInGlobalContextEmployee, Location } from "types/models/location";
import TasksDropdownControl, { TaskDropdownOptionTask } from "./TasksDropdownControl";
import CustomField from "./CustomField";
import { ActivityToAdd } from "./AddActivities";
import { makeEmptyActivityCustomFields } from "./utils";

const Wrapper = styled.div`
  position: relative;
`;

const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 24px;
  align-items: center;
  position: relative;
  .time-control {
    margin-top: 0;
  }
  .ui-select__wrapper_notfilled {
    .ui-select__input {
      border: 1px solid var(--colors-surface-150);
      color: var(--colors-surface-400);
    }
  }
`;

const RemoveButton = styled.div`
  background-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='10.067' y='2.87' width='2.171' height='9.407' rx='1.085' transform='rotate(44 10.067 2.87)' fill='%238193AB'/%3E%3Crect x='11.718' y='9.494' width='2.171' height='9.407' rx='1.085' transform='rotate(134 11.718 9.494)' fill='%238193AB'/%3E%3C/svg%3E");
  position: absolute;
  top: 43px;
  inset-inline-end: -23px;
`;

interface ActivityRowProps extends WithTranslation {
  activity: ActivityToAdd;
  employeeUuid: string;
  loading: boolean;
  errors: Record<string, string> | null;
  employeeLocations: (Location | LocationInGlobalContextEmployee)[];
  onRemove: ((activity: ActivityToAdd) => void) | null;
  onUpdate: (activity: ActivityToAdd) => void;
}

interface ActivityRowState {
  hintVisible: boolean;
}

class ActivityRow extends Component<ActivityRowProps, ActivityRowState> {
  constructor(props: ActivityRowProps) {
    super(props);
    this.state = { hintVisible: false };
  }

  parseTime = (time: string) => {
    if (time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
      return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
    }
    return 0;
  };

  render() {
    const { t, activity, onRemove, onUpdate, employeeLocations, errors, loading, employeeUuid } = this.props;
    const { startTime, endTime, locationUuid, taskUuid, customFields } = activity;
    return (
      <Wrapper>
        <FieldsWrapper>
          <FieldWrapper fieldName={t("common|From")} loading={loading} width="167px">
            <TimeControl
              value={startTime !== null ? minsToHrsMins(startTime) : ""}
              onTimeChange={(val: { value: string }) => {
                if (val !== null) {
                  onUpdate({
                    ...activity,
                    startTime: val.value ? this.parseTime(val.value) : null,
                  });
                }
              }}
              error={!!errors?.startTime}
              placeholder="00:00"
            />
          </FieldWrapper>
          <FieldWrapper fieldName={t("common|To")} loading={loading} width="167px">
            <TimeControl
              value={endTime !== null ? minsToHrsMins(endTime) : ""}
              onTimeChange={(val: { value: string }) => {
                if (val !== null) {
                  onUpdate({ ...activity, endTime: val.value ? this.parseTime(val.value) : null });
                }
              }}
              error={!!errors?.endTime}
              placeholder="00:00"
            />
          </FieldWrapper>
          <FieldWrapper fieldName={t("common|Location")} loading={loading} width="167px">
            <LocationsDropdown
              onlyActive
              employeeLocations={employeeLocations}
              value={locationUuid}
              withCode={false}
              uuid
              employeeUuid={employeeUuid}
              placeholder={t("All locations")}
              onChange={(val) => {
                onUpdate({ ...activity, locationUuid: val, projectUuid: "", taskUuid: "" });
              }}
            />
          </FieldWrapper>
          {locationUuid && employeeUuid ? (
            <FieldWrapper fieldName={t("Task")} loading={loading} width="358px">
              <TasksDropdownControl
                t={t}
                key={locationUuid}
                value={taskUuid}
                locationUuid={locationUuid}
                employeeUuid={employeeUuid}
                onChange={(val: TaskDropdownOptionTask) => {
                  const cFields = makeEmptyActivityCustomFields(val.project.customFieldsSettings || undefined);
                  onUpdate({ ...activity, projectUuid: val.projectUuid, taskUuid: val.value, customFields: cFields });
                }}
                placeholder={t("Select a task")}
              />
            </FieldWrapper>
          ) : null}
          {customFields &&
            customFields.map((cf) => (
              <CustomField
                key={cf.uuid}
                name={cf.name}
                value={cf.value}
                fieldType={cf.fieldType}
                error={errors?.customFieldUuid === cf.uuid}
                onChange={(value) => {
                  const cFields = customFields.map((c) => (c.uuid === cf.uuid ? { ...c, value } : c));
                  onUpdate({ ...activity, customFields: cFields });
                }}
              />
            ))}
        </FieldsWrapper>
        {errors?.startTime && <ErrorLabel>{errors.startTime}</ErrorLabel>}
        {errors?.endTime && <ErrorLabel>{errors.endTime}</ErrorLabel>}
        {errors?.locationUuid && <ErrorLabel>{errors.locationUuid}</ErrorLabel>}
        {errors?.taskUuid && <ErrorLabel>{errors.taskUuid}</ErrorLabel>}
        {errors?.customFields && <ErrorLabel>{errors.customFields}</ErrorLabel>}
        {onRemove ? <RemoveButton onClick={() => onRemove(activity)} /> : null}
      </Wrapper>
    );
  }
}
export default withTranslation(TranslationNamespaces.punchesPage)(ActivityRow);
