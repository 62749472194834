enum OperationStatus {
  completed = "completed",
  pending = "pending",
}

enum OperationName {
  deactivatePayrollGroup = "deactivatePayrollGroup",
  deactivateUserProfilesPayrollStrategy = "deactivateUserProfilesPayrollStrategy",
  bulkAssignUserProfilesToPayrollGroup = "bulkAssignUserProfilesToPayrollGroup",
  bulkUnassignUserProfilesFromAllHolidaysGroups = "bulkUnassignUserProfilesFromAllHolidaysGroups",
  bulkUpdateLockedOnCalls = "bulkUpdateLockedOnCalls",
  bulkAssignUserProfilesToSchedule = "bulkAssignUserProfilesToSchedule",
  bulkUnassignUserProfilesFromSchedule = "bulkUnassignUserProfilesFromSchedule",
  bulkCreateDigitalSignatures = "bulkCreateDigitalSignatures",
  bulkResendDigitalSignatures = "bulkResendDigitalSignatures",
  bulkCancelDigitalSignatures = "bulkCancelDigitalSignatures",
  bulkCreateScheduleException = "bulkCreateScheduleException",
  bulkCreateRequest = "bulkCreateRequest",
}

type SuccessfulOperationDetails = {
  userProfileUuid: string;
};

type FailedOperationDetails = {
  message: string;
} & Record<string, any>;

type OperationResultAttrs = {
  targetObjectName?: string;
  targetObjectUuid?: string;
} & Record<string, any>;

type Operation = {
  resultAttrs: OperationResultAttrs;
  successful: SuccessfulOperationDetails[];
  failed: FailedOperationDetails[];
  uuid: string;
  companyUuid: string;
  name: OperationName;
  attrs: Record<string, any>;
  status: OperationStatus;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
};

export type { Operation, OperationResultAttrs, FailedOperationDetails };

export { OperationStatus, OperationName };
