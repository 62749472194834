import { Component, ContextType } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { createLockGroup, updateLockGroup } from "utils/apiHelpers";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/StyledTextInput";
import Button, { ButtonState } from "components/controls/StyledButton";
import Select from "components/UI/Select";
import { ErrorLabel } from "components/UI/TextLabels";
import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PayrollGroup, PayrollGroupCreateEdit, PayrollGroupRecurrenceFreq } from "types/models/payrollGroup";
import GlobalContext from "context/global-context";
import SwitchableTextField from "components/UI/SwitchableTextField";

const SettingsWrapper = styled.div`
  width: 100%;
  padding-top: 19px;
`;

const DatesWrapper = styled.div`
  display: flex;
  width: 420px;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;

enum PayrollGroupPeriods {
  monthly = 1,
  twiceAMonth,
  weekly,
}

interface RulesTabProps extends WithTranslation, RouteComponentProps {
  groupUuid: string;
  group: PayrollGroup | null;
}

interface RulesTabState {
  period: PayrollGroupPeriods;
  date: number;
  secondDate: number;
  items: unknown[];
  name: string;
  loaded: boolean;
  isFetching: boolean;
  isDefault: boolean;
  status: unknown | null;
  isLoading: boolean;
  notification: string | null;
  errors: Record<string, string> | null;
  externalId: string | null;
}

class GroupRulesTab extends Component<RulesTabProps, RulesTabState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  periods: { value: PayrollGroupPeriods; label: string }[];
  dates: { value: number; label: string }[];

  constructor(props: RulesTabProps) {
    super(props);
    const { t, group } = props;

    this.state = {
      period: 1,
      date: 1,
      secondDate: 2,
      items: [],
      name: "",
      loaded: false,
      isFetching: false,
      isDefault: false,
      status: null,
      isLoading: false,
      notification: null,
      errors: null,
      externalId: null,
    };

    if (group) {
      const { name, isDefault, status, recurrence, externalId } = group;

      this.state = {
        ...this.state,
        externalId,
        name,
        isDefault,
        status,
      };

      if (recurrence) {
        const period =
          recurrence.freq === PayrollGroupRecurrenceFreq.weekly
            ? PayrollGroupPeriods.weekly
            : recurrence.bymonthday.length || PayrollGroupPeriods.monthly;

        this.state = {
          ...this.state,
          period,
          date: recurrence.bymonthday?.[0] || 1,
          secondDate: recurrence.bymonthday?.[1] || 2,
        };
      }
    }

    this.periods = [
      { value: PayrollGroupPeriods.monthly, label: t("Monthly") },
      { value: PayrollGroupPeriods.twiceAMonth, label: t("Twice a Month") },
      { value: PayrollGroupPeriods.weekly, label: t("Weekly") },
    ];

    this.dates = [...Array(28)].map((_, i) => ({ value: i + 1, label: String(i + 1) }));
    this.dates.push({ value: 31, label: t("The last day of month") });
  }

  onSave = async () => {
    const { name, period, date, secondDate, externalId } = this.state;
    const { t, groupUuid, history } = this.props;

    let errors: Record<string, string> | null = {};

    if (!name) {
      errors.name = t("Please enter Company Short Name");
    } else if (period === PayrollGroupPeriods.twiceAMonth && date === secondDate) {
      errors.date = t("Please select different periods");
    } else {
      errors = null;
      this.setState({ isLoading: true });

      const freq =
        period === PayrollGroupPeriods.weekly ? PayrollGroupRecurrenceFreq.weekly : PayrollGroupRecurrenceFreq.monthly;

      const bymonthday: [number?, number?] = [];
      if (period !== PayrollGroupPeriods.weekly) {
        bymonthday.push(date);

        if (period === PayrollGroupPeriods.twiceAMonth) {
          bymonthday.push(secondDate);
        }
      }

      const body = {
        content: {
          name,
          userProfilesUuids: [],
          companyUuid: window.global_store.company.uuid,
          createdBy: window.global_store.profile.uuid,
          recurrence: { freq, bymonthday },
          externalId,
        } as PayrollGroupCreateEdit,
      };

      try {
        if (groupUuid) {
          await updateLockGroup({
            body,
            companyUuid: window.global_store.company.uuid,
            payrollGroupUuid: groupUuid,
          });

          this.setState({ isLoading: false });
        } else {
          const res = await createLockGroup({
            body,
            companyUuid: window.global_store.company.uuid,
          });

          if (res?.content?.uuid) {
            history.push(`/payroll/payroll-groups/${res.content.uuid}?employeesTab=true`);
          }
        }
      } catch (err) {
        const error = err as Error;
        this.setState({ isLoading: false, notification: error.message });

        return;
      }
    }

    this.setState({ errors, notification: "" });
  };

  render() {
    const { period, name, date, secondDate, errors, isDefault, status, isLoading, notification, externalId } =
      this.state;
    const { t, group, groupUuid } = this.props;
    const isTwiceAMonth = period === PayrollGroupPeriods.twiceAMonth;
    const isWeekly = period === PayrollGroupPeriods.weekly;
    const isGroupLocked = group?.isLocked;

    return (
      <SettingsWrapper>
        {!!isGroupLocked && (
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type={NotificationType.locked}
            style={{ marginTop: 0, marginBottom: "25px" }}
            message={
              <div
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  color: "var(--colors-mainText)",
                }}
              >
                {t("You can't edit a group with already added employees. Please create a new group.")}
              </div>
            }
          />
        )}

        {notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={NotificationType.error}
            style={{ marginTop: 0, marginBottom: "25px" }}
            message={notification}
          />
        )}

        <FieldWrapper fieldName={t("Name")} width="296px">
          <TextInputControl
            value={isDefault ? t(name) : name}
            disabled={isDefault || !!groupUuid}
            error={!!errors?.name}
            onChange={(name) => this.setState({ name })}
            placeholder={t("Example Name")}
          />
        </FieldWrapper>
        {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}

        <FieldWrapper fieldName={t("Period")} width="200px">
          <Select
            disabled={isGroupLocked}
            modifiers={{ field: true }}
            value={period}
            onChange={(val) => {
              this.setState({ period: val });
            }}
            options={this.periods}
          />
        </FieldWrapper>

        {!isWeekly && (
          <>
            <DatesWrapper>
              <FieldWrapper fieldName={isTwiceAMonth ? t("First Date") : t("Date")} width="200px">
                <Select
                  disabled={isGroupLocked}
                  modifiers={{ field: true }}
                  value={date}
                  onChange={(val) => {
                    this.setState({ date: val });
                  }}
                  options={this.dates}
                />
              </FieldWrapper>

              {isTwiceAMonth && (
                <FieldWrapper fieldName={t("Second Date")} width="200px">
                  <Select
                    disabled={isGroupLocked}
                    modifiers={{ field: true }}
                    value={secondDate}
                    onChange={(val) => {
                      this.setState({ secondDate: val });
                    }}
                    options={this.dates}
                  />
                </FieldWrapper>
              )}
            </DatesWrapper>
            {errors?.date && <ErrorLabel>{errors.date}</ErrorLabel>}
          </>
        )}
        <br />
        <SwitchableTextField
          value={externalId}
          placeholder="ID A1B2C3"
          label={t("external-id-label")}
          disabled={!!groupUuid && status !== "active"}
          locked={isGroupLocked}
          onChange={(val) => {
            this.setState({ externalId: val });
          }}
        />

        {status !== "deactivated" && (
          <ButtonWrapper>
            <Button
              disabled={isGroupLocked}
              loading={isLoading}
              value={t("Save")}
              state={ButtonState.primary}
              onClick={this.onSave}
            />
          </ButtonWrapper>
        )}
      </SettingsWrapper>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.payment)(GroupRulesTab));
