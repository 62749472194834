import React, { Component } from "react";
import BEM from "utils/BEM";
import "./DropdownControl.scss";
import * as images from "components/svg-images";
import styled from "styled-components";
import rtl from "styled-components-rtl";

const b = BEM.b("dropdown-control");

const Wrapper = styled.div`
  .dropdown-control__arrow {
    ${rtl`
    right: 17px;
  `}
  }
`;
class DropdownControl extends Component {
  static defaultProps = {
    modifiers: {},
  };
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      open: false,
      options: this.props.options || [],
      value: this.props.value || { value: "", label: "" },
      disabled: this.props.disabled,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.props.onRef && this.props.onRef(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const state = {
      options: nextProps.options || [],
      disabled: nextProps.disabled,
    };
    if (typeof nextProps.value !== "undefined") {
      state.value = nextProps.value;
    }

    this.setState(state);
  }
  getSelectedOption = () => this.state.value;
  handleClickOutside(ev) {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target)) {
      this.setState({ open: false });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  toggleDropdown(ev) {
    if (!this.state.disabled) {
      this.setState({ open: !this.state.open });
    }
  }
  selectValue(value, ev) {
    this.setState({ value, open: false });
    this.props.onChange && this.props.onChange(value);
  }
  render() {
    const { options, open, value, disabled } = this.state;
    const { style, placeholder, modifiers } = this.props;
    return (
      <Wrapper className={b({ open, ...this.props.modifiers })} ref={this.setWrapperRef} style={{ ...style }}>
        <div className={b("value", { disabled, "has-value": !!value, open })} onClick={this.toggleDropdown.bind(this)}>
          {!value.value && !value.label ? placeholder : value.label}
          <span className={b("arrow", { open })}>
            {images.smallArrowUp(modifiers.big ? { width: "12px", height: "8px" } : undefined)}
          </span>
          <span className={b("arrow", { open: !open })}>
            {images.smallArrowDown(modifiers.big ? { width: "12px", height: "8px" } : undefined)}
          </span>
        </div>
        <div className={b("menu", { open })}>
          {options.map((option) => (
            <div
              className={b("option", { active: value.value === option.value })}
              key={option.value}
              onClick={this.selectValue.bind(this, option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }
}
export default DropdownControl;
