import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ModalDialog from "components/UI/ModalDialog";
import Button from "components/controls/StyledButton";
import * as images from "components/svg-images";
import Spinner from "@atlaskit/spinner";
import GlobalContext from "context/global-context";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import { TranslationNamespaces } from "types/translationNamespaces";

import { PermissionSectionName } from "types/models/permissions";
import GroupList from "./GroupList";
import { AddEmployees, CloseButton, Container, Header, Wrapper } from "./styled";
import { AvailableEmployees } from "./AvailableEmployees";
import { SelectedEmployees } from "./SelectedEmployees";
import { getEmployees } from "./getEmployees";
import { filterEmployees } from "./helpers";

interface Groups {
  allEmployees: FilteredEmployeeProfile[];
  subsidiaries: Record<string, FilteredEmployeeProfile[]>;
  departments: Record<string, FilteredEmployeeProfile[]>;
  teams: Record<string, FilteredEmployeeProfile[]>;
  positions: Record<string, FilteredEmployeeProfile[]>;
  payrollGroups: Record<string, FilteredEmployeeProfile[]>;
  businessRulesGroups: Record<string, FilteredEmployeeProfile[]>;
}

interface MultipleEmployeeSelectorProps extends WithTranslation, RouteComponentProps {
  skipEmployees?: FilteredEmployeeProfile[];
  dialogOpened: boolean;
  addEmployees: (selectedEmployees: FilteredEmployeeProfile[]) => void;
  onClose: () => void;
  permissionSection: PermissionSectionName;
}

interface MultipleEmployeeSelectorState {
  companyEmployees: FilteredEmployeeProfile[];
  availableEmployees: FilteredEmployeeProfile[];
  selectedEmployees: FilteredEmployeeProfile[];
  checkedEmployees: string[];
  groups: Groups | null;
  groupName: string;
  loaderVisible: boolean;
  checkedAll: boolean;
}

class MultipleEmployeeSelector extends Component<MultipleEmployeeSelectorProps, MultipleEmployeeSelectorState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: MultipleEmployeeSelectorProps) {
    super(props);

    this.state = {
      companyEmployees: [],
      availableEmployees: [],
      selectedEmployees: [],
      checkedEmployees: [],
      groups: null,
      groupName: "",
      loaderVisible: true,
      checkedAll: false,
    };
  }

  componentDidMount() {
    void this.getData();
  }

  async getData() {
    const { skipEmployees = [], permissionSection } = this.props;

    const company = await this.context.getCompany();
    const companyEmployees = await getEmployees(company.uuid, permissionSection);
    const groups = filterEmployees({ companyEmployees, skipEmployees });

    this.setState({
      companyEmployees,
      groups,
      loaderVisible: false,
    });
  }

  selectEmployee = (employee: FilteredEmployeeProfile) => {
    this.setState((state) => {
      const { selectedEmployees, availableEmployees, checkedEmployees } = state;

      return {
        selectedEmployees: [...selectedEmployees, employee],
        availableEmployees: availableEmployees.filter((e) => e.uuid !== employee.uuid),
        checkedEmployees: checkedEmployees.filter((uuid) => uuid !== employee.uuid),
      };
    });
  };

  toggleEmployee = (employee: FilteredEmployeeProfile) => {
    const { checkedEmployees } = this.state;
    const index = checkedEmployees.indexOf(employee.uuid);

    if (index !== -1) {
      checkedEmployees.splice(index, 1);
    } else {
      checkedEmployees.push(employee.uuid);
    }

    this.setState({
      checkedEmployees,
    });
  };

  setAvailableEmployees = ({ employees, groupName }: { employees: FilteredEmployeeProfile[]; groupName: string }) => {
    if (groupName === this.state.groupName) {
      return;
    }

    const { selectedEmployees } = this.state;
    const availableEmployees = employees.filter((e) => !selectedEmployees.find((se) => se.uuid === e.uuid));

    this.setState({
      availableEmployees,
      groupName,
      checkedEmployees: [],
      checkedAll: false,
    });
  };

  removeEmployee = (employee: FilteredEmployeeProfile) => {
    const { availableEmployees } = this.state;
    availableEmployees.push(employee);

    this.setState({
      selectedEmployees: this.state.selectedEmployees.filter((e) => e.uuid !== employee.uuid),
      availableEmployees,
    });
  };

  moveAll = () => {
    const {
      selectedEmployees: prevSelectedEmployees,
      availableEmployees: prevAvailableEmployees,
      checkedEmployees,
    } = this.state;

    const availableEmployees = [];
    const newSelectedEmployees = [];

    for (const ae of prevAvailableEmployees) {
      const isChecked = checkedEmployees.indexOf(ae.uuid) !== -1;
      if (isChecked) {
        newSelectedEmployees.push(ae);
      } else {
        availableEmployees.push(ae);
      }
    }

    const selectedEmployees = prevSelectedEmployees.concat(newSelectedEmployees);

    this.setState({
      selectedEmployees,
      availableEmployees,
      checkedAll: false,
      checkedEmployees: [],
    });
  };

  onClose = () => {
    this.setState(
      {
        availableEmployees: [],
        groupName: "",
        checkedAll: false,
        selectedEmployees: [],
        checkedEmployees: [],
      },
      () => this.props.onClose(),
    );
  };

  render() {
    const {
      groups,
      availableEmployees = [],
      selectedEmployees = [],
      checkedEmployees = [],
      loaderVisible,
      checkedAll,
    } = this.state;

    const { t, dialogOpened } = this.props;

    return (
      <div>
        <ModalDialog isOpen={dialogOpened} onClose={this.onClose} width={835} padding={0}>
          <Wrapper>
            <Header>
              {t("Add multiple employees")}
              <CloseButton onClick={this.onClose}>{images.modalPunchClose2}</CloseButton>
            </Header>
            {loaderVisible ? (
              <Spinner size="medium" />
            ) : (
              <Container>
                <GroupList
                  t={t}
                  groups={groups}
                  filterGroups={(value: string) => {
                    this.setState({
                      groups: filterEmployees({
                        companyEmployees: this.state.companyEmployees,
                        term: value,
                      }),
                    });
                  }}
                  setEmployees={this.setAvailableEmployees}
                  setGroupName={() => {}}
                />
                <AvailableEmployees
                  availableEmployees={availableEmployees}
                  checkedEmployees={checkedEmployees}
                  onSelect={this.selectEmployee}
                  onToggle={this.toggleEmployee}
                  checkedAll={checkedAll}
                  moveAll={this.moveAll}
                  toggleAll={(checked) =>
                    this.setState({
                      checkedAll: checked,
                      checkedEmployees: checked ? availableEmployees.map((e) => e.uuid) : [],
                    })
                  }
                />
                <SelectedEmployees selectedEmployees={selectedEmployees} onRemove={this.removeEmployee}>
                  <AddEmployees>
                    <div style={{ maxWidth: "186px" }}>
                      {selectedEmployees.length > 0 ? (
                        <Button
                          value={t("Add Employees")}
                          onClick={() => {
                            if (selectedEmployees.length) {
                              this.props.addEmployees(selectedEmployees);
                              this.onClose();
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </AddEmployees>
                </SelectedEmployees>
              </Container>
            )}
          </Wrapper>
        </ModalDialog>
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.common)(MultipleEmployeeSelector));
