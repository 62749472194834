import { APIResponse, sendRequest } from "./common";
import {
  DeletePermissionRoleByUserProfileIdRequestData,
  GetPermissionRolesByUserProfileIdResponseData,
  GetPermissionRolesResponseData,
  UpdatePermissionRoleByUserProfileIdResponseData,
  UpdatePremissionRoleByUserProfileIdRequestData,
} from "./types";

export function getPermissionRoles(): Promise<GetPermissionRolesResponseData> {
  return new Promise((res, rej) => {
    sendRequest({}, "get", "/permission_roles")
      .then((r) => {
        res(r);
      })
      .catch((e) => rej(e));
  });
}

export function updatePermissionRole({ role }) {
  return new Promise((res, rej) => {
    sendRequest(
      {
        permission_role: role,
      },
      "PUT",
      `/permission_roles/${role.uuid}`,
    )
      .then((r) => {
        res(r);
      })
      .catch((e) => rej(e));
  });
}

export function createPermissionRole({ role }) {
  return new Promise((res, rej) => {
    sendRequest(
      {
        permission_role: role,
      },
      "POST",
      "/permission_roles",
    )
      .then((r) => {
        res(r);
      })
      .catch((e) => rej(e));
  });
}

export function removePermissionRole({ uuid }) {
  return new Promise((res, rej) => {
    sendRequest({}, "DELETE", `/permission_roles/${uuid}`)
      .then((r) => {
        res(r);
      })
      .catch((e) => rej(e));
  });
}

export function getPermissionRoleByUserProfileId(
  userProfileId: number,
): APIResponse<GetPermissionRolesByUserProfileIdResponseData> {
  return sendRequest({}, "GET", `/employees/${userProfileId}/permission_roles`);
}

export function updatePermissionRoleByUserProfileId(
  data: UpdatePremissionRoleByUserProfileIdRequestData,
): APIResponse<UpdatePermissionRoleByUserProfileIdResponseData> {
  const { userProfileId, ...payload } = data;

  return sendRequest(payload, "POST", `/employees/${userProfileId}/upsert_role`);
}

export function deletePermissionRoleByUserProfileId(data: DeletePermissionRoleByUserProfileIdRequestData) {
  return sendRequest(
    { permission_role: { permission_role_uuid: data.permissionRoleUuid } },
    "DELETE",
    `/employees/${data.userProfileId}/delete_role`,
  );
}
