import styled from "styled-components";
import ProgressBar from "../ProgressBar";
import pdfPreview from "../../img/pdfPreview.svg";

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  &:before {
    position: absolute;
    display: none;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #8093ac40;
  }
`;

interface RequestAttachmentsThumbnailProps {
  preview: string;
  loading: boolean;
  mimeType: string;
  percentCompleted: number;
}

const RequestAttachmentsThumbnail = ({
  preview,
  loading,
  percentCompleted = 0,
  mimeType,
}: RequestAttachmentsThumbnailProps) => {
  let style = {};

  if (mimeType !== "application/pdf") {
    style = { backgroundImage: `url('${preview}')` };
  }

  return (
    <FilePreview className="request-details__file-preview" style={style}>
      {loading ? <ProgressBar popup width={percentCompleted} /> : null}
      {mimeType === "application/pdf" ? <img src={pdfPreview} alt="" /> : null}
    </FilePreview>
  );
};

export default RequestAttachmentsThumbnail;
