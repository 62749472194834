import { Component, ContextType, Fragment, createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import sentryUtils from "utils/sentryUtils";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import EmployeeInfo from "components/UI/EmployeeInfo";
import GlobalContext from "context/global-context";
import FieldWrapper from "components/UI/FieldWrapper";
import DeclineReasonPopup from "components/DeclineReasonPopup";
import { PermissionSectionName, getDateWithTZ, hasPermisionAccess, minsToHrsMins } from "utils/common";
import { getRequestByUuid, getUserBRGroups } from "utils/apiHelpers";
import Button, { ButtonState } from "components/controls/StyledButton";
import { PayedOvertimePhase } from "types/models/businessRulesGroup";
import { UserProfileRole } from "types/models/userProfile";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  Request,
  RequestApprovalFlowApproverType,
  RequestApprovalFlowStatus,
  RequestOvertimeSubTypes,
  RequestTypeName,
} from "types/models/request";
import RequestLockedPeriodRow from "./RequestLockedPeriodRow";
import RequestDetailsApprovalFlow from "./RequestDetailsApprovalFlow";
import RequestDetailsLogEvents from "./RequestDetailsLogEvents";
import RequestDetailsVacationDays from "./RequestDetailsVacationDays";
import * as images from "../svg-images";
import Attachments from "./Attachments";
import "styles/request-details.scss";
import SubtypeSpecificFieldsHBAdjustment from "./RequestDetailsScreenFields/SubtypeSpecificFieldsHBAdjustment";
import SubtypeSpecificFieldsOvetimeEnableDay from "./RequestDetailsScreenFields/SubtypeSpecificFieldsOvetimeEnableDay";
import SubtypeSpecificFieldsScheduleAssignment from "./RequestDetailsScreenFields/SubtypeSpecificFieldsScheduleAssignment";

const moment = extendMoment(momentTz);

const Content = styled.div`
  max-width: 430px;
  min-width: 300px;
  padding: 12px;
  padding-bottom: 50px;

  & > *:not([class^="ApprovalFlow"]) {
    margin-inline-start: 12px;
    margin-inline-end: 12px;
  }
`;

const IgnoreRequestRow = styled.div`
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IgnoreRequestButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 18px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--colors-primary);

  &:disabled {
    cursor: default;
  }
`;

const FieldValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
`;

const UserInfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  ${(p) => `border-bottom: 1px solid ${p.theme.colors.inputBorderDefault};`}
`;

const CommentBody = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;

const DatesWrapper = styled.div``;

const PaidAbsence = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  padding-inline-start: 11px;
  padding-inline-end: 15px;
  border-radius: 18px;
  background: #e3eaf2;

  p {
    margin: 0;
    margin-inline-start: 10px;
    font-size: var(--typography-font-size-default);
    line-height: 18px;
    color: var(--colors-mainText);
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const ActionButtons = styled.div<{ isRTL: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;

  button {
    max-width: 155px;
  }

  ${(p) => (p.isRTL ? "flex-direction: row-reverse;" : "")}
`;

const DatesRow = styled.div`
  display: flex;

  div:nth-child(1) {
    flex: 2;
  }

  div:nth-child(2) {
    flex: 1;
  }

  div:nth-child(3) {
    flex: 1;
  }
`;

const Loader = styled.div`
  position: absolute;
  inset-inline-end: 10px;
  bottom: 13px;
  width: 24px;
  height: 24px;
  animation: loaderRoation 2s infinite linear;
`;

interface RequestDetailsProps extends WithTranslation {
  request: Request;
  onStatusChanged: (data: {
    request: Request;
    status: RequestApprovalFlowStatus;
    declineReason: string | undefined;
  }) => void;
}

interface RequestDetailsState {
  loading: boolean;
  fetching: boolean;
  files: unknown[];
  request: Request;
  declineConfirmationPopupVisible: boolean;
  confirmationPopupVisible: boolean;
  isLockedDate: boolean;
  phases: PayedOvertimePhase[];
}

class RequestDetails extends Component<RequestDetailsProps, RequestDetailsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  dropzone = createRef();

  constructor(props: RequestDetailsProps) {
    super(props);

    this.state = {
      loading: true,
      fetching: true,
      files: [],
      request: props.request,
      declineConfirmationPopupVisible: false,
      confirmationPopupVisible: false,
      isLockedDate: false,
      phases: [],
    };
  }

  async componentDidMount() {
    const { request } = this.props;
    this.updateStateWithRequest(request, !request.requestSubtypeState);

    const company = await this.context.getCompany();

    if (!request.requestSubtypeState && company) {
      const response = await getRequestByUuid({
        companyUUID: company.uuid,
        requestUuid: request.uuid,
      });

      if (response.content) {
        this.updateStateWithRequest(response.content, false);
      }
    }

    if (request.requestType === RequestTypeName.hbConvert) {
      await this.getPhases();
    }
  }

  refetchRequest = async () => {
    const { request } = this.props;
    const company = await this.context.getCompany();

    if (!company) {
      return;
    }

    const response = await getRequestByUuid({
      companyUUID: company?.uuid,
      requestUuid: request.uuid,
    });

    if (response.content) {
      this.updateStateWithRequest(response.content, false);
    }
  };

  updateStateWithRequest = (request: Request, loading: boolean) => {
    this.setState({
      request,
      files: request?.attachments ? request.attachments.filter((f) => f && !f.deleted_at && !f.deletedAt) : [],
      isLockedDate:
        !!request?.employee?.lastLockDate &&
        moment
          .tz(request.startTime, request.timezone)
          .isSameOrBefore(moment(request.employee.lastLockDate).utc(), "day"),
      loading,
      fetching: false,
    });
  };

  onApproveClick = () => {
    void this.changeStatus(RequestApprovalFlowStatus.approved);
  };

  onDeclineClick = () => {
    this.setState({ declineConfirmationPopupVisible: true });
  };

  onIgnoreClick = () => {
    void this.changeStatus(RequestApprovalFlowStatus.ignored);
  };

  changeStatus = async (status: RequestApprovalFlowStatus, declineReason?: string) => {
    const { request } = this.state;

    this.setState({ loading: true, confirmationPopupVisible: false, declineConfirmationPopupVisible: false });
    this.props.onStatusChanged({ request, status, declineReason });
  };

  getDatesRows = (request: Request) => {
    const rows = [];
    const [startDate, startTime] = getDateWithTZ(request.startTime, request.timezone)
      .format("DD/MM/YYYY HH:mm")
      .split(" ");
    const [endDate, endTime] = getDateWithTZ(request.endTime, request.timezone).format("DD/MM/YYYY HH:mm").split(" ");

    if (startDate === endDate) {
      const row = [{ label: "Date", value: startDate }];

      if (
        !request.allDay &&
        request.requestType !== RequestTypeName.overtime &&
        request?.requestType !== RequestTypeName.scheduleAssignment
      ) {
        row.push({ label: "From", value: startTime });
        row.push({ label: "To", value: endTime });
      }

      rows.push(row);
    } else {
      let row = [{ label: "Start Date", value: startDate }];

      if (!request.allDay) {
        row.push({ label: "Time", value: startTime });
      }

      rows.push(row);
      row = [{ label: "End Date", value: endDate }];

      if (!request.allDay) {
        row.push({ label: "Time", value: endTime });
      }

      rows.push(row);
    }

    return rows;
  };

  getFromTo = (request: Request) => {
    const { t } = this.props;
    const datesRows = this.getDatesRows(request);

    return datesRows.map((row, i) => (
      <DatesRow
        key={i === 0 ? "start" : "end"}
        style={request.requestType === "vacation" && i === 0 ? { marginInlineEnd: "30px" } : {}}
      >
        {row.map((field) => (
          <FieldWrapper key={field.label} fieldName={t(field.label)} fieldTitleMarginTop={20}>
            <FieldValue>{field.value}</FieldValue>
          </FieldWrapper>
        ))}
      </DatesRow>
    ));
  };

  getPhases = async () => {
    const { request } = this.state;

    const company = await this.context.getCompany();
    if (!company || !request) {
      return;
    }

    this.setState({ loading: true }, async () => {
      try {
        const { content } = await getUserBRGroups({
          companyUuid: company.uuid,
          userProfileUuid: request.userProfileUuid,
          startDate: request.startTime,
          requestedBy: window.global_store.profile.uuid,
        });

        const [brg] = content;
        const { phases } = brg.rules.payedOvertime;

        this.setState({ phases });
      } catch (error) {
        sentryUtils.sendError(error);

        this.setState({ phases: [] });
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  getContent = (request: Request, buttonProps: { disabled?: boolean }) => {
    const { isLockedDate, loading, files, phases } = this.state;
    const { t } = this.props;

    const isLocked = request.locked;
    const isPending = request.approvalStatus === RequestApprovalFlowStatus.pending;
    const isOwnerRole = window.global_store.profile.permission_roles.some((r) =>
      ["owner", "admin"].includes(r.name.toLowerCase()),
    );

    const hasAccess =
      window.global_store.profile.permission_roles.some((r) => r.name.toLowerCase() === "supervisor") ||
      window.global_store.profile?.permissions?.some((r) => r && r.toLowerCase() === "requests_super_approver") ||
      (request.approvalFlow?.length &&
        request.approvalFlow.some(
          (level) =>
            level.canChangeStatus &&
            level.stage >= request.approvalStage &&
            level.canChangeStatus.some((uuid) => window.global_store.profile.uuid === uuid),
        ));

    const showButtons = !isLocked && !isLockedDate && isPending && (isOwnerRole || hasAccess);

    const statusType = {
      [RequestApprovalFlowStatus.approved]: StatusBadgeStatuses.approved,
      [RequestApprovalFlowStatus.declined]: StatusBadgeStatuses.declined,
      [RequestApprovalFlowStatus.ignored]: StatusBadgeStatuses.default,
      [RequestApprovalFlowStatus.pending]: StatusBadgeStatuses.pending,
    }[request.approvalStatus];

    return (
      <>
        {isLockedDate ? <RequestLockedPeriodRow /> : null}
        <UserInfoWrapper>
          <EmployeeInfo
            employeeInfo={{
              fullName: request.employee ? request.employee.fullName : "",
              avatarId: request.employee ? request.employee.avatarId || "" : "",
              position: request.employee ? request.employee.jobTitle || "" : "",
            }}
          />
          <StatusBadge type={statusType} value={t(request.approvalStatus)} />
          {loading ? <Loader>{images.reloadIcon}</Loader> : null}
        </UserInfoWrapper>

        {request.requestType === RequestTypeName.vacation &&
          request.approvalStatus === RequestApprovalFlowStatus.pending &&
          !!request?.requestSubtypeState?.complianceRules && <RequestDetailsVacationDays request={request} />}

        <DatesWrapper style={request.requestType === RequestTypeName.vacation ? { display: "flex" } : {}}>
          {this.getFromTo(request)}
        </DatesWrapper>

        {request?.requestSubtypeState?.nameId === RequestOvertimeSubTypes.overtime && (
          <>
            <FieldWrapper fieldName={t("Request to")} width="100%" fieldTitleMarginTop={20}>
              <FieldValue>{t(`request-to-${request.validityType}`)}</FieldValue>
            </FieldWrapper>
            <FieldWrapper fieldName={t("Additional requested hours")} width="100%" fieldTitleMarginTop={20}>
              <FieldValue>{minsToHrsMins(request.validity || 0)}</FieldValue>
            </FieldWrapper>
          </>
        )}
        {request.isNightShift && (
          <FieldWrapper fieldName={t("Apply to yesterday schedule")} width="100%" fieldTitleMarginTop={20}>
            <FieldValue>{t(`${TranslationNamespaces.common}|Yes`)}</FieldValue>
          </FieldWrapper>
        )}
        {request?.requestSubtypeState?.nameId === RequestOvertimeSubTypes.overtimeEnableDay && (
          <SubtypeSpecificFieldsOvetimeEnableDay request={request} />
        )}
        {request?.requestType === RequestTypeName.scheduleAssignment && (
          <SubtypeSpecificFieldsScheduleAssignment request={request} />
        )}

        {!!request.requestSubtypeState &&
          ![RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(request.requestType) && (
            <FieldWrapper fieldName={t("Category")} width="100%" fieldTitleMarginTop={20}>
              <FieldValue>
                <FieldValue>
                  {request.requestSubtypeState.translationKey
                    ? t(request.requestSubtypeState.translationKey)
                    : request.requestSubtypeState.name}
                </FieldValue>
              </FieldValue>
            </FieldWrapper>
          )}

        {request.requestType === RequestTypeName.hbConvert && (
          <SubtypeSpecificFieldsHBAdjustment phases={phases} request={request} />
        )}

        {!!request.customFields?.length &&
          request.customFields
            .filter((cf) => cf.value)
            .map((cf, i) => (
              <FieldWrapper key={cf.name + i} fieldName={cf.name} width="100%" fieldTitleMarginTop={20}>
                <FieldValue>{cf.value}</FieldValue>
              </FieldWrapper>
            ))}

        {!!(request.comment?.active && request.comment?.value) && (
          <FieldWrapper fieldName={t("Comments")} width="100%" fieldTitleMarginTop={20}>
            <FieldValue>
              <CommentBody>{request.comment.value}</CommentBody>
            </FieldValue>
          </FieldWrapper>
        )}

        {!!request.requestSubtypeState &&
          ![RequestTypeName.overtime, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(
            request.requestType,
          ) && (
            <FieldWrapper fieldName="" width="100%" fieldTitleMarginTop={20}>
              <FieldValue>
                <PaidAbsence>
                  {images.absenceType(
                    request.requestSubtypeState.hoursCalculationType === "compensateMissedHours"
                      ? "#00CA73"
                      : "#FE6664",
                  )}
                  <p>{request.paid ? t("Paid absence") : t("No paid absence")}</p>
                </PaidAbsence>
              </FieldValue>
            </FieldWrapper>
          )}

        {!!files?.length && (
          <FieldWrapper fieldName={t("Attachments")} width="100%" fieldTitleMarginTop={20}>
            <Attachments edit={false} attachments={files} />
          </FieldWrapper>
        )}

        {request.approvalFlow && (
          <RequestDetailsApprovalFlow
            status={request.approvalStatus}
            approvalFlow={request.approvalFlow}
            logs={request.logs}
            timezone={request.timezone}
          />
        )}
        {request.logs?.length && <RequestDetailsLogEvents logs={request.logs} timezone={request.timezone} />}
        {showButtons && (
          <ActionButtons isRTL={window.global_store.isRTL}>
            <Button value={t("Decline")} state={ButtonState.cancel} {...buttonProps} onClick={this.onDeclineClick} />
            <Button value={t("Appove")} state={ButtonState.success} {...buttonProps} onClick={this.onApproveClick} />
          </ActionButtons>
        )}
      </>
    );
  };

  canCancelRequest = () => {
    const { isLockedDate, request } = this.state;
    const { profile } = window.global_store;

    if (isLockedDate || request.approvalStatus === RequestApprovalFlowStatus.ignored) {
      return false;
    }

    const currentUserEmployer = profile.role === UserProfileRole.employer;
    const currentUserInApprovalFlow = request.approvalFlow?.some(
      (level) =>
        level.canChangeStatus?.some((uuid) => window.global_store.profile.uuid === uuid) ||
        (level.approver?.type === RequestApprovalFlowApproverType.role &&
          window.global_store.profile.permission_roles.some((permRole) => level.approver.uuid === permRole.uuid)),
    );
    const currentUserSuperApprover = hasPermisionAccess(PermissionSectionName.requestSuperApprover);
    const hasCancelRquestPermission = hasPermisionAccess(PermissionSectionName.cancelApprovedRequest);
    const isOwnRequest = profile.uuid === request?.userProfileUuid;
    const currentUserCreator = profile.uuid === request?.createdBy?.uuid;

    const canCancelPendingRequest =
      request.approvalStatus === RequestApprovalFlowStatus.pending &&
      (currentUserEmployer || isOwnRequest || currentUserCreator || currentUserSuperApprover);

    const canCancelApprovedRequest =
      request.approvalStatus === RequestApprovalFlowStatus.approved &&
      hasCancelRquestPermission &&
      (currentUserSuperApprover || currentUserInApprovalFlow);

    return canCancelPendingRequest || canCancelApprovedRequest;
  };

  render() {
    const { request, loading, fetching, confirmationPopupVisible, declineConfirmationPopupVisible } = this.state;
    let buttonProps = {};
    const { t } = this.props;

    if (loading) {
      buttonProps = { disabled: true };
    }

    if (fetching) {
      return <div>{t("Loading...")}</div>;
    }

    return (
      <Content>
        {this.getContent(request, buttonProps)}

        {this.canCancelRequest() && (
          <IgnoreRequestRow>
            <IgnoreRequestButton
              disabled={loading}
              onClick={() => {
                this.setState({ confirmationPopupVisible: true });
              }}
            >
              {images.ignoreRequest} {t("Cancel Request")}
            </IgnoreRequestButton>
          </IgnoreRequestRow>
        )}

        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Cancel Request.")}
            text={t("Are you sure to cancel that request?")}
            buttonYesTitle={t("Confirm")}
            buttonCancelTitle={t("Cancel")}
            onClose={() => {
              this.setState({ confirmationPopupVisible: false });
            }}
            onYes={this.onIgnoreClick}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={declineConfirmationPopupVisible}
          onClose={() => this.setState({ declineConfirmationPopupVisible: false })}
        >
          <DeclineReasonPopup
            t={t}
            title={t("Decline Request.")}
            text={t("Are you sure to Decline the request? You should not be able to undo that action.")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            onClose={() => {
              this.setState({ declineConfirmationPopupVisible: false });
            }}
            onYes={(declineReason) => this.changeStatus(RequestApprovalFlowStatus.declined, declineReason)}
          />
        </ModalDialog>
      </Content>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp, TranslationNamespaces.phases])(RequestDetails);
