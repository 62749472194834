import React, { Component } from "react";
import styled from "styled-components";
import * as images from "components/svg-images";
import { fireEvent } from "utils/common";
import moment from "moment";
import Tooltip from "components/UI/Tooltip";

const CellWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: var(--typography-font-size-default);
  position: relative;
`;
const Count = styled.div`
  position: absolute;
  background: #fe6764;
  line-height: 16px;
  width: 16px;
  border-radius: 16px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 10px;
  color: var(--colors-default);
  top: -7px;
  right: -11px;
`;
const CalendarIcon = styled.div`
  position: relative;
  cursor: pointer;
`;

class CellRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: props.requests,
    };
  }
  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }
  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }
  getCellKey = () => {
    const { date } = this.props;
    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}_requests`;
  };
  handleDataLoad = (ev) => {
    if (ev.detail && ev.detail.requests) {
      this.setState({ requests: ev.detail.requests });
    }
  };
  onClick = () => {
    fireEvent("sp_openRequests", {
      selectedDate: this.props.date,
      selectedDayRequests: this.state.requests,
      employeeInfo: this.props.employeeInfo,
    });
  };
  getRequestIcon = (requests) => {
    const requestsCount = requests ? requests.length : 0;

    if (!requestsCount) {
      return null;
    }
    let pendingVacationRequests = 0;
    let approvedVacationRequests = 0;
    let pendingCustomRequests = 0;
    let approvedCustomRequests = 0;

    requests.map((request) => {
      if (request.type === "vacation") {
        if (request.approvalStatus === "approved") {
          approvedVacationRequests += 1;
        } else if (request.approvalStatus === "pending") {
          pendingVacationRequests += 1;
        }
      } else if (request.approvalStatus === "approved") {
        approvedCustomRequests += 1;
      } else if (request.approvalStatus === "pending") {
        pendingCustomRequests += 1;
      }
      return request;
    });
    if (approvedVacationRequests > 0) {
      return images.vacationIcon;
    }
    if (approvedCustomRequests > 0) {
      return images.calendarIcon;
    }
    if (pendingVacationRequests > 0) {
      return images.vacationIcon;
    }
    if (pendingCustomRequests > 0) {
      return images.calendarIcon;
    }
    return null;
  };
  render() {
    const { requests } = this.state;
    const { t } = this.props;
    const pendingRequests = requests.filter((r) => r.approvalStatus === "pending");
    const vacationRequests = requests.filter(
      (r) => r.requestType === "vacation" && r.approvalStatus !== "declined" && r.approvalStatus !== "ignored",
    );
    const count = pendingRequests.length;

    return (
      <CellWrapper>
        <CalendarIcon
          onClick={this.onClick}
          data-tip
          data-for={vacationRequests.length ? "cell-requests-vacation" : "cell-requests-absence"}
        >
          {this.getRequestIcon(requests)}
          {count ? <Count>{count}</Count> : null}
        </CalendarIcon>
        <Tooltip id="cell-requests-vacation">{t("Vacation")}</Tooltip>
        <Tooltip id="cell-requests-absence">{t("Absence")}</Tooltip>
      </CellWrapper>
    );
  }
}
export default CellRequests;
