import { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";
import { getSelectedColumns } from "utils/tableHelpers";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import Select from "components/UI/Select";
import CheckboxControl from "components/UI/CheckboxControl";
import MultiWithSearch from "components/UI/Select/MultiWithSearch";
import { ReportParams, ReportType } from "types/reports";
import { TranslationNamespaces } from "types/translationNamespaces";
import BEM from "utils/BEM";
import "styles/hours-bank-adjust-popup.scss";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRuleNames } from "types/models/companyRules";

const b = BEM.b("hours-bank-adjust-popup");

const DatesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 20px;
  height: 60px;
`;

const DateWrapper = styled.div`
  margin: 0 15px;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const Label = styled.div`
  margin-top: 19px;
  margin-bottom: 5px;
  font-size: 13px;
  letter-spacing: 0;
`;

interface ColumnSelectorOptions {
  value: string;
  label: string;
  disabled?: boolean;
}

export type ReportForMultipleEmployees = {
  body: {
    format: string;
    onSamePage: boolean;
    showInactive: boolean;
    createdBy: string;
    selectedColumns: string;
  } & Partial<ReportParams>;
};

interface ReportsDownloadAllDialogProps extends WithTranslation {
  onClose: () => void;
  onYes: (data: ReportForMultipleEmployees) => void;
  reportType: ReportType;
}

interface ReportsDownloadAllDialogState {
  searchValue: string;
  errors: Record<string, string> | null;
  format: string;
  searchObj: Record<string, never> | SearchControlOnChangeData;
  startDate: moment.Moment;
  endDate: moment.Moment;
  onSamePage: boolean;
  showInactive: boolean;
  skipSupervisors: boolean;
  /** comma separated */
  selectedColumns: string;
  isNightReducedHoursColumnAllowed: boolean;
}

class ReportsDownloadAllDialog extends Component<ReportsDownloadAllDialogProps, ReportsDownloadAllDialogState> {
  constructor(props: ReportsDownloadAllDialogProps) {
    super(props);

    this.state = {
      searchValue: "",
      errors: null,
      format: "pdf",
      searchObj: {},
      startDate: moment(),
      endDate: moment().clone().add(1, "month"),
      onSamePage: false,
      showInactive: false,
      skipSupervisors: false,
      selectedColumns: getSelectedColumns(
        this.getDefaultSelectedColums(props.reportType),
        `generateFromAll_${props.reportType}`,
      ),
      isNightReducedHoursColumnAllowed: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );
    this.setState({ isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value });
  }

  closePopup() {
    this.props.onClose();
  }

  onCancel = () => {
    this.closePopup();
  };

  getFormErrors() {
    const { t } = this.props;
    let errors: Record<string, string> | null = {};

    if (!this.state.startDate) {
      errors.startDate = t("Please select start date");
    }
    if (!this.state.endDate) {
      errors.endDate = t("Please select end date");
    }
    if (!this.state.searchObj.id) {
      errors.employee = t("Please select a group");
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  }

  getDefaultSelectedColums = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.detailed:
        return "date,punches,worked_hours,break_hours,night_hours,holiday,observation";
      default:
        return "";
    }
  };

  getColumnsSelectorOptions = (): ColumnSelectorOptions[] => {
    const { reportType, t } = this.props;
    const { isNightReducedHoursColumnAllowed } = this.state;
    let result: ColumnSelectorOptions[] = [];

    const nightReducedHoursColumn = isNightReducedHoursColumnAllowed
      ? [{ value: "night_reduced_hours", label: t("ns-col-reduced-hours") }]
      : [];

    switch (reportType) {
      case ReportType.detailed:
        result = [
          { value: "date", label: t("Date"), disabled: true },
          { value: "schedule", label: t("Schedule") },
          { value: "business_rules_group", label: t("Business Rules Group") },
          { value: "punches", label: t("Punches"), disabled: true },
          { value: "planned_hours", label: t("Planned Hours") },
          { value: "worked_hours", label: t("Worked Hours") },
          { value: "break_hours", label: t("Break hours") },
          { value: "night_hours", label: t("nightShiftMinutes") },
          ...nightReducedHoursColumn,
          { value: "late_entry_hours", label: t("Late Arrival") },
          { value: "early_leave_hours", label: t("Late Leave") },
          { value: "extra_hours_phases", label: t("HE Phases") },
          { value: "extra_hours", label: t("Extra Hours") },
          { value: "debit_hours", label: t("Debit Minutes") },
          { value: "hours_bank_phases", label: t("HB Phases") },
          { value: "hours_bank", label: t("Hours Bank") },
          { value: "missed_hours", label: t("Missed Minutes") },
          { value: "cumulative_hours_bank", label: t("Accumulated Hours Bank") },
          { value: "cross_shifts_interval_diff_hours", label: t("Cross Shift Interval") },
          { value: "holiday", label: t("Holiday") },
          { value: "observation", label: t("OBS") },
          { value: "on_call_hours", label: t("On Call Minutes") },
          { value: "on_call_activated_hours", label: t("On Call Activated Minutes") },
          { value: "on_call_reducing_activated_hours", label: t("On Call Reducing Activated Minutes") },
        ];
        break;
      default:
        result = [];
    }
    return result;
  };

  onColumnsChange = (selectedColumns: string[]) => {
    const { reportType } = this.props;
    const joinedColums = selectedColumns.join();

    if (localStorage) {
      localStorage.setItem(`customColumns_generateFromAll_${reportType}`, joinedColums);
    }

    this.setState({ selectedColumns: joinedColums });
  };

  onYes = () => {
    const { startDate, endDate, format, searchObj, onSamePage, showInactive, skipSupervisors, selectedColumns } =
      this.state;
    const errors = this.getFormErrors();

    if (!errors) {
      this.props.onYes({
        body: {
          onSamePage: format === "pdf" && onSamePage,
          showInactive,
          skipSupervisors,
          createdBy: window.global_store.profile.uuid,
          startDate,
          endDate,
          format,
          searchObj,
          selectedColumns,
        },
      });
    }
    this.setState({ errors });
  };

  render() {
    const {
      startDate,
      endDate,
      format,
      showInactive,
      skipSupervisors,
      onSamePage,
      searchValue,
      errors,
      selectedColumns,
    } = this.state;
    const { t, reportType } = this.props;
    const monthLimit = window.global_store.beta ? 60 : 4;
    const columnsSelectorOptions = this.getColumnsSelectorOptions();

    if (reportType === ReportType.detailed) {
      columnsSelectorOptions.push({ value: "unused_breaks", label: t("Unused Breaks") });
    }

    return (
      <div className={b()}>
        <div className={b("title")}>{t("Group Reports")}</div>
        <SearchControl
          permissionSection={PermissionSectionName.advancedReports}
          value={searchValue}
          onChange={(searchObj: SearchControlOnChangeData) => {
            this.setState({ searchObj, searchValue: searchObj.label });
          }}
          placeholder={translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-search-groups-employees",
            `${TranslationNamespaces.reportsPage}|Department, Team, All Employees`,
          )}
          searchGroups
          groupsOnly
          showAllEmployeesItem
        />
        {errors?.employee && <div className={b("error")}>{errors.employee}</div>}
        <DatesWrapper>
          <DateWrapper>
            <div className={b("label")}>{t("From")}</div>
            <SingleDatePickerControl
              value={startDate}
              error={!!errors?.startDate}
              onChange={(date) => this.setState({ startDate: date })}
              isOutsideRange={(day) => day.isBefore(moment().clone().subtract(monthLimit, "month"))}
            />
          </DateWrapper>
          <DateWrapper>
            <div className={b("label")}>{t("Until")}</div>
            <SingleDatePickerControl
              value={endDate}
              error={!!(errors && errors.endDate)}
              onChange={(date) => this.setState({ endDate: date })}
              isOutsideRange={(day) => day.isBefore(moment().clone().subtract(monthLimit, "month"))}
            />
          </DateWrapper>
        </DatesWrapper>
        {errors && (errors.startDate || errors.endDate) && (
          <div className={b("error")}>{errors.startDate || errors.endDate}</div>
        )}
        <Label>{t("Format")}</Label>
        <Select
          value={format}
          modifiers={{ field: true }}
          onChange={(val) => this.setState({ format: val })}
          options={[
            { value: "pdf", label: "PDF" },
            { value: "csv", label: "CSV" },
            { value: "xlsx", label: "XLSX" },
          ]}
        />

        {!!columnsSelectorOptions.length && (
          <>
            <Label>{t("Include columns")}</Label>

            <MultiWithSearch
              lightUI
              placeholder={t("Select report columns")}
              value={selectedColumns.split(",")}
              onChange={this.onColumnsChange}
              options={columnsSelectorOptions}
            />
          </>
        )}

        <Spacer height={15} />
        <div>
          <CheckboxControl checked={showInactive} osx onChange={(val) => this.setState({ showInactive: val })} />
          <label className="label" style={{ marginInlineStart: "10px" }}>
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.reportsPage,
              "custom-show-inactive-employees",
              `${TranslationNamespaces.reportsPage}|Show inactive employees`,
            )}
          </label>
        </div>
        <div>
          <CheckboxControl checked={skipSupervisors} osx onChange={(val) => this.setState({ skipSupervisors: val })} />
          <label className="label" style={{ marginInlineStart: "10px" }}>
            {t("Hide Supervisor")}
          </label>
        </div>
        {format === "pdf" && (
          <div>
            <CheckboxControl checked={onSamePage} osx onChange={(onSamePage) => this.setState({ onSamePage })} />
            <label className="label" style={{ marginInlineStart: "10px" }}>
              {t("Print all on the same page")}
            </label>
          </div>
        )}
        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.onCancel}>
            {t("Cancel")}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes}>
            {t("Confirm")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsDownloadAllDialog);
