import { useTranslation } from "react-i18next";
import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import { TranslationNamespaces } from "types/translationNamespaces";
import sentryUtils from "utils/sentryUtils";

export enum BulkActionType {
  groupPunchApprove = "groupPunchApprove",
}

export type BulkAction = {
  actionType: BulkActionType;
  entityUuid: string;
  bulkSize: number;
};

interface BulkActionConfirmModalProps {
  bulkAction: BulkAction;
  onClose: () => void;
  // PROD-11156 PROD-11157 todo
  setProcessingGroupsUuids: (uuid: string) => void;
}

const BulkActionConfirmModal = ({ bulkAction, onClose, setProcessingGroupsUuids }: BulkActionConfirmModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const onConfirm = () => {
    try {
      switch (bulkAction.actionType) {
        case BulkActionType.groupPunchApprove:
          // PROD-11156 PROD-11157 todo
          setProcessingGroupsUuids(bulkAction.entityUuid);
          break;
        default:
          break;
      }
    } catch (e) {
      sentryUtils.sendError(e);
    }

    onClose();
  };

  const text = {
    [BulkActionType.groupPunchApprove]:
      "This action will affect {{bulkSize}} pending punches. Are you sure want to continue?",
  }[bulkAction.actionType];

  return (
    <ModalDialog isOpen onClose={onClose}>
      <Lightbox
        onClose={onClose}
        onYes={onConfirm}
        title={t("Confirm Bulk Action")}
        buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
        buttonYesTitle={t(`${TranslationNamespaces.common}|Continue`)}
        // PROD-10770 todo
        text={`****** THIS IS MOCKED FUNCTIONALITY ****** ******** DOES NOT AFFECT ANYTHING ******** ${t(text, {
          bulkSize: bulkAction.bulkSize,
        })}`}
        showLoading
      />
    </ModalDialog>
  );
};

export default BulkActionConfirmModal;
