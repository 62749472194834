import { useTranslation } from "react-i18next";
import {
  BreakHoursInfo,
  BreakInfoHoursWrapper,
  BreakInfoWrapper,
  BreakTitleInfo,
  RemoveBreakWrapper,
  ScheduleBreakWrapper,
} from "components/Schedules/Breaks/styled";
import { MouseEvent, useMemo } from "react";
import { minsToHrsMins } from "utils/common";
import Tooltip from "components/UI/Tooltip";
import { TranslationNamespaces } from "types/translationNamespaces";
import { breaksAutomaticTooltipIcon, breaksRemoveIcon, smallArrowDown } from "../../svg-images";
import { BasedOn, DayInfoBreak } from "./BreakModal";
import { DEFAULT_BREAK_NAME } from "./constants";

interface ScheduleBreakProps {
  index: string;
  brk: DayInfoBreak;
  readOnly: boolean;
  isSelected: boolean;
  disableRemove: boolean;
  onRemoveBreak: (item: DayInfoBreak) => void;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const ArrowIcon = () => (
  <span className="arrow-down" style={{ marginInlineStart: "4px" }}>
    {smallArrowDown({ width: "7px", height: "8px" })}
  </span>
);

export const ScheduleBreak = ({
  index,
  brk,
  readOnly,
  disableRemove,
  onRemoveBreak,
  onClick,
  isSelected,
}: ScheduleBreakProps) => {
  const { t } = useTranslation(TranslationNamespaces.schedules);

  const breakHoursInfo = useMemo(() => {
    let infoStr = "";
    if (brk.breakMode === BasedOn.flexible) {
      infoStr = t("flexible-breaks-row-text", { count: brk.eventsCount || 1 });
    } else {
      infoStr = `${minsToHrsMins(brk.breakDuration)} ${t("hrs")} ${
        brk.breakMode === BasedOn.range ? `- ${t("From")} ${brk.start.value} ${t("To")} ${brk.end.value}` : ""
      }`;
    }

    return <>{infoStr}</>;
  }, [brk, t]);

  const tooltipText = useMemo(() => {
    const mainText = `${
      brk.breakTypeName === DEFAULT_BREAK_NAME ? t(DEFAULT_BREAK_NAME) : brk.breakTypeName
    }: ${minsToHrsMins(brk.breakDuration)} ${t("hrs")}`;
    const hoursText =
      brk.breakMode === BasedOn.range ? ` - ${t("From")} ${brk.start.value} ${t("To")} ${brk.end.value}` : "";
    const automaticText = brk.automatic ? ` - ${t("Automatic")}` : "";
    return `${mainText}${hoursText}${automaticText}`;
  }, [brk.automatic, brk.breakDuration, brk.breakMode, brk.breakTypeName, brk.end.value, brk.start.value, t]);

  const tooltipId = `breaks-tooltip-${index}`;
  const deleteTooltipId = `breaks-delete-tooltip-${index}`;

  return (
    <ScheduleBreakWrapper>
      <BreakInfoWrapper
        data-tip
        data-for={tooltipId}
        $readOnly={readOnly}
        $isSelected={isSelected}
        onClick={(e) => {
          if (!readOnly) {
            onClick(e);
          }
        }}
      >
        <BreakInfoHoursWrapper automatic={brk.automatic}>
          <BreakTitleInfo>
            {brk.breakTypeName === DEFAULT_BREAK_NAME ? t(DEFAULT_BREAK_NAME) : brk.breakTypeName}
          </BreakTitleInfo>

          <BreakHoursInfo>{breakHoursInfo}</BreakHoursInfo>
        </BreakInfoHoursWrapper>

        {brk.automatic && breaksAutomaticTooltipIcon}
        <ArrowIcon />
      </BreakInfoWrapper>

      {brk.breakMode !== BasedOn.flexible && (
        <Tooltip
          id={tooltipId}
          wrapperStyle={{
            maxWidth: "200px",
          }}
        >
          {tooltipText}
        </Tooltip>
      )}

      {!disableRemove && (
        <>
          <RemoveBreakWrapper
            data-tip
            data-for={deleteTooltipId}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveBreak(brk);
            }}
          >
            {breaksRemoveIcon}
          </RemoveBreakWrapper>
          <Tooltip
            id={deleteTooltipId}
            wrapperStyle={{
              maxWidth: "200px",
            }}
          >
            {t("Delete")}
          </Tooltip>
        </>
      )}
    </ScheduleBreakWrapper>
  );
};
