import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import moment from "moment-timezone";
import { fireEvent, formatDateShort, hasPermisionAccess, minsToHrsMins } from "utils/common";
import { stylesheet } from "astroturf";
import SearchControl from "components/UI/SearchControlNew";
import ModalDialog from "components/UI/ModalDialog";
import TablePage from "components/TablePage";
import StatusBadge from "components/controls/StatusBadge";
import { useHistory } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import CellCheckbox from "components/controls/CellCheckbox";
import ApproveDeclineActionBar from "components/ApproveDeclineActionBar";
import CellCheckboxAll from "components/controls/CellCheckboxAll";
import { ProfileCard } from "components/PunchNow/views/ProfileCard";
import MultiSelect from "components/UI/Select/MultiSelect";
import { useAsyncCallback } from "utils/useAsyncEffect";
import NoContent from "components/NoContent";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { iCellInfo } from "utils/tableHelpers";
import { TableButton, TableButtons } from "components/styled/Page";
import Sentry from "utils/sentryUtils";
import { cancelTimesheet, revokeTimesheet } from "components/Timesheets";
import { v4 as uuidv4 } from "uuid";
import { iColumn } from "components/TableCommon";
import { TFunction } from "i18next";
import Lightbox from "components/Lightbox";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import TimesheetSnackbar from "../../components/TimesheetSnackbar";
import DeclineTimesheetPopup from "../../components/DeclineTimesheetPopup";
import { getStatusBadgeStatus } from "../../timesheet.helpers";
import { TimesheetApprovalStatus, TimesheetsListItem } from "../../timesheet.types";
import { TimesheetContext } from "../../timesheets.context";

const styles = stylesheet`
.Wrapper {
  :global(.buttons) {
    top: 33px;
  }
}

.SearchWrapper {
  display: inline-block;
  width: 100%;
  min-width: 237px;
  max-width: 237px;
  vertical-align: middle;
}

.FilterDropdownWrapper {
  margin-inline-end: 8px;

  .ui-select__input {
    min-width: 100px;
  }
}

.Row {
  position: relative;
}
`;

export enum EventStatus {
  success = "success",
  error = "error",
  warning = "warning",
}

async function onCancelTimesheet(timesheet: TimesheetsListItem, t: TFunction, loadRows: () => void) {
  try {
    await cancelTimesheet({
      companyUuid: window.global_store.company.uuid,
      timesheetUuid: timesheet.uuid,
      requestedBy: window.global_store.profile.uuid,
    });
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-cancel-success"),
      status: EventStatus.success,
    });
    loadRows();
  } catch (error) {
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-cancel-error"),
      status: EventStatus.error,
    });
    Sentry.sendError(error);
  }
}

async function onRevokeTimesheet(timesheet: TimesheetsListItem, t: TFunction, loadRows: () => void) {
  try {
    await revokeTimesheet({
      companyUuid: window.global_store.company.uuid,
      timesheetUuid: timesheet.uuid,
      requestedBy: window.global_store.profile.uuid,
    });
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-revoke-success"),
      status: EventStatus.success,
    });
    loadRows();
  } catch (error) {
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-revoke-success"),
      status: EventStatus.error,
    });
    Sentry.sendError(error);
  }
}

export default function TimesheetsList() {
  const [t] = useTranslation(TranslationNamespaces.timesheets);
  const history = useHistory();
  const [timesheets, setTimesheets] = useState<TimesheetsListItem[]>();
  const timesheetContext = useContext(TimesheetContext);
  const hasApprovalPermission = hasPermisionAccess(PermissionSectionName.approveActivities);

  const canViewAllEmployees = () => {
    const { profile } = window.global_store;
    const allowedPermissionRoles = [
      PermissionRoleName.admin,
      PermissionRoleName.owner,
      PermissionRoleName.supervisor,
      PermissionRoleName.clientManager,
      PermissionRoleName.locationManager,
    ].map((role) => role.toLowerCase());

    return (
      !!profile?.permission_roles?.length &&
      profile?.permission_roles.some((pr) => allowedPermissionRoles.includes(pr.name.toLowerCase()))
    );
  };

  // filter by userProfile
  const [searchFilter, setSearchFilter] = useState(null);

  // filter by date
  const [fromFilter, setFromFilter] = useState(moment().startOf("month").subtract(1, "month"));
  const [toFilter, setToFilter] = useState(moment());
  const changeDatesFilter = useCallback((start: moment.Moment, end: moment.Moment) => {
    setFromFilter(start);
    setToFilter(end);
  }, []);

  // filter by status
  const statusFilterOptions = [
    { value: TimesheetApprovalStatus.pending, label: t("Pending") },
    { value: TimesheetApprovalStatus.approved, label: t("Approved") },
    { value: TimesheetApprovalStatus.declined, label: t("Declined") },
  ];
  const [statusFilter, setStatusFilter] = useState<TimesheetApprovalStatus[]>([]);
  const changeStatusFilter = useCallback((statusFltr: TimesheetApprovalStatus[]) => {
    setStatusFilter(statusFltr);
  }, []);

  const [loadRows, loadingRows] = useAsyncCallback(
    async () => {
      await timesheetContext.setUserProfile(searchFilter?.employee);
      await timesheetContext.setDates(fromFilter, toFilter);
      const { content } = await timesheetContext.fetchTimeSheets(false, statusFilter);
      setTimesheets(content);
    },
    [searchFilter, fromFilter, toFilter, statusFilter],
    true,
  );
  useEffect(() => void loadRows(), [loadRows]);
  useEffect(
    () => void timesheetContext.setReloadDataCallback(loadRows),
    [searchFilter, fromFilter, toFilter, statusFilter],
  );

  // select rows
  const [selectedRowsUuids, setSelectedRowsUuids] = useState<string[]>([]);
  const onRowCheck = useCallback(
    (isChecked: boolean, uuid: string) => {
      const uuids = isChecked ? [...selectedRowsUuids, uuid] : selectedRowsUuids.filter((u) => u !== uuid);
      setSelectedRowsUuids(uuids);
    },
    [selectedRowsUuids],
  );

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    const selAll =
      (timesheets &&
        timesheets.length !== 0 &&
        timesheets.every(
          (row) => row.approvalStatus !== TimesheetApprovalStatus.pending || selectedRowsUuids.includes(row.uuid),
        )) ||
      false;
    setSelectAllChecked(selAll);
  }, [selectedRowsUuids, timesheets]);

  const onSelectAllCheck = useCallback(
    (isChecked: boolean) => {
      const uuids =
        isChecked && timesheets
          ? timesheets?.filter((row) => row.approvalStatus === TimesheetApprovalStatus.pending).map((row) => row.uuid)
          : [];
      setSelectedRowsUuids(uuids);
    },
    [timesheets],
  );

  // withdraw
  const [timesheetToWithdraw, setTimesheetToWithdraw] = useState<TimesheetsListItem | null>(null);

  // cancel
  const [timesheetToCancel, setTimesheetToCancel] = useState<TimesheetsListItem | null>(null);

  // approve
  const [approve, approving] = useAsyncCallback(
    async () => {
      onSelectAllCheck(false);
      await timesheetContext.approveTimesheet(selectedRowsUuids);
      await loadRows();
    },
    [selectedRowsUuids, onSelectAllCheck, loadRows],
    true,
  );

  // decline
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [decline, declining] = useAsyncCallback(
    async (evt) => {
      setShowDeclinePopup(false);
      onSelectAllCheck(false);
      await timesheetContext.declineTimesheet(selectedRowsUuids, evt.reason);
      await loadRows();
    },
    [selectedRowsUuids, onSelectAllCheck, loadRows],
    true,
  );

  const isRevokeAvailable = (timesheet: TimesheetsListItem) => {
    let revokeAvaiable = timesheet.userProfileUuid === window.global_store.profile.uuid;
    revokeAvaiable = revokeAvaiable && timesheet.approvalStatus === TimesheetApprovalStatus.pending;
    return revokeAvaiable;
  };

  const isCancelAvailable = (timesheet: TimesheetsListItem) => {
    const cancelAvaiable = hasApprovalPermission && timesheet.approvalStatus === TimesheetApprovalStatus.approved;
    return cancelAvaiable;
  };
  // columns
  const columns: iColumn<TimesheetsListItem>[] = [
    {
      accessor: "uuid",
      headerClassName: "checkbox-header",
      Cell: (row: iCellInfo<TimesheetsListItem>): JSX.Element => (
        <CellCheckbox
          row={row}
          checked={row && selectedRowsUuids.indexOf(row.value) !== -1}
          onChange={(isChecked) => onRowCheck(isChecked, row.original.uuid)}
          isCheckboxDisabled={row.original.approvalStatus !== TimesheetApprovalStatus.pending || !hasApprovalPermission}
        />
      ),
      Header: (
        <CellCheckboxAll
          disabled={!hasApprovalPermission}
          entities={timesheets}
          checked={selectAllChecked}
          onChange={onSelectAllCheck}
        />
      ),
      minWidth: 30,
      style: { fontWeight: "500" },
      align: "center",
      locked: true,
    },
    {
      accessor: "userProfile",
      locked: true,
      label: translateEmployeeTerm(
        t,
        TranslationNamespaces.common,
        "custom-employee",
        `${TranslationNamespaces.common}|Employee`,
      ),
      Cell: (row: iCellInfo<TimesheetsListItem>) => (
        <ProfileCard
          employeeName={row.value.fullName}
          companyName={row.value.position?.title}
          employeeAvatarId={row.value.avatarId}
        />
      ),
      minWidth: 180,
    },
    {
      accessor: "period",
      label: t("Period"),
      Cell: (row: iCellInfo<TimesheetsListItem>) =>
        `${formatDateShort(row.original.startDate)} - ${formatDateShort(row.original.endDate)}`,
      minWidth: 180,
    },
    {
      accessor: "workedMinutes",
      label: t("At work"),
      Cell: (row: iCellInfo<TimesheetsListItem>) => minsToHrsMins(row.value),
    },
    {
      accessor: "activitiesMinutes",
      label: t("On activity"),
      Cell: (row: iCellInfo<TimesheetsListItem>) => minsToHrsMins(row.value),
    },
    {
      accessor: "submittedAt",
      label: t("Submitted"),
      Cell: (row: iCellInfo<TimesheetsListItem>) =>
        row.value != null ? moment(row.value).format("DD/MM/YYYY HH:mm") : "-",
      minWidth: 130,
    },
    {
      accessor: "approvalStatus",
      label: t("Status"),
      Cell: (row: iCellInfo<TimesheetsListItem>) => {
        const status = getStatusBadgeStatus(row.value);
        const showCancelTimesheetButton = isCancelAvailable(row.original);
        const showRevokeTimesheetButton = isRevokeAvailable(row.original);
        const showActionButtons = showCancelTimesheetButton || showRevokeTimesheetButton;

        return (
          <div>
            {showActionButtons && (
              <TableButtons className="buttons">
                {showRevokeTimesheetButton && (
                  <TableButton
                    onClick={async (ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      setTimesheetToWithdraw(row.original);
                    }}
                  >
                    {t(`Withdraw`)}
                  </TableButton>
                )}
                {showCancelTimesheetButton && (
                  <TableButton
                    onClick={async (ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      setTimesheetToCancel(row.original);
                    }}
                  >
                    {t(`Cancel approval`)}
                  </TableButton>
                )}
              </TableButtons>
            )}
            {status && <StatusBadge type={status} value={t(row.value)} />}
          </div>
        );
      },
      minWidth: 100,
    },
  ];
  const selectedColumns: string[] = columns.map((col) => (col.accessor as string) || "");

  const loading = loadingRows || approving || declining;

  return (
    <div className={styles.Wrapper}>
      <TablePage<TimesheetsListItem>
        filters={
          <>
            <div className={styles.SearchWrapper}>
              <SearchControl
                onClear={() => setSearchFilter(null)}
                value={searchFilter?.label}
                onChange={(sObj) => setSearchFilter(sObj)}
                placeholder={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-search-employees",
                  `${TranslationNamespaces.common}|Search Employee`,
                )}
                permissionSection={PermissionSectionName.activities}
              />
            </div>
            <DateRangePicker
              isAdmin={canViewAllEmployees()}
              onChange={changeDatesFilter}
              startDate={fromFilter}
              endDate={toFilter}
            />

            <div className={styles.FilterDropdownWrapper}>
              <MultiSelect
                value={statusFilter}
                onChange={changeStatusFilter}
                options={statusFilterOptions}
                placeholder={t("Status")}
              />
            </div>
          </>
        }
        selectedColumns={selectedColumns}
        columnSelectorOnFiltersRow
        customColumnsAvailable={false}
        rows={timesheets}
        columns={columns}
        loading={loading}
        getTrProps={(_, rowInfo) => {
          const trProps = {
            className: styles.Row,
            onClick: (e: KeyboardEvent): void => {
              const url = `/timesheets/details/${rowInfo?.original.uuid}`;
              history.push(url);
            },
          };
          return trProps;
        }}
        noContentComponent={<NoContent>{t("No timesheets to approve")}</NoContent>}
      />

      {selectedRowsUuids.length > 0 && (
        <ApproveDeclineActionBar
          onUncheckAll={() => onSelectAllCheck(false)}
          selectedItems={selectedRowsUuids}
          onApprove={approve}
          onDelcine={() => setShowDeclinePopup(true)}
        />
      )}
      <ModalDialog isOpen={showDeclinePopup} onClose={() => setShowDeclinePopup(false)}>
        <DeclineTimesheetPopup
          numTimesheets={selectedRowsUuids.length}
          onClose={() => setShowDeclinePopup(false)}
          onYes={(evt) => decline(evt)}
        />
      </ModalDialog>

      <ModalDialog
        isOpen={!!timesheetToWithdraw || !!timesheetToCancel}
        onClose={() => {
          if (timesheetToWithdraw) {
            setTimesheetToWithdraw(null);
          }
          if (timesheetToCancel) {
            setTimesheetToCancel(null);
          }
        }}
      >
        <Lightbox
          title={
            timesheetToWithdraw ? t("withdraw-timesheet-confirmation-title") : t("cancel-timesheet-confirmation-title")
          }
          text={
            timesheetToWithdraw
              ? t("withdraw-timesheet-confirmation-description")
              : t("cancel-timesheet-confirmation-description")
          }
          buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onClose={() => {
            if (timesheetToWithdraw) {
              setTimesheetToWithdraw(null);
            }
            if (timesheetToCancel) {
              setTimesheetToCancel(null);
            }
          }}
          onYes={async () => {
            if (timesheetToWithdraw) {
              await onRevokeTimesheet(timesheetToWithdraw, t, loadRows);
              setTimesheetToWithdraw(null);
            }
            if (timesheetToCancel) {
              await onCancelTimesheet(timesheetToCancel, t, loadRows);
              setTimesheetToCancel(null);
            }
          }}
        />
      </ModalDialog>
      <TimesheetSnackbar />
    </div>
  );
}
