import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import styled from "styled-components";
import { ApplicationType } from "types/models/application";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { getApplications } from "utils/api/company";
import { useContext, useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { GlobalContext } from "context/GlobalContextProvider";
import ModalDialog from "components/UI/ModalDialog";
import { useExtensionService } from "utils/useServices";
import { SubscriptionApi } from "utils/api/subscription";
import ReactTooltip from "react-tooltip";
import ga, {
  DownloadExtensionPage,
  GaInstallExtensionPopupStatus,
  GaIntegrationState,
  GaIntegrationStatus,
} from "utils/ga";
import { ApplicationsService, IntegrationObject } from "../Applications/ApplicationsService";
import { ReactComponent as TooltipImg } from "./img/tooltip-img.svg";

const styles = stylesheet`
  .MainWrapper {
    height: 100vh;
    overflow: auto;
  }

  .Top {
    background-color: var(--colors-surface-100);
    height: 400px;
    overflow: hidden;
    .BGImage {
      width: 900px;
      height: 95%;
      inset-inline-start: 0;
      inset-inline-end: 0;
      top: 300px;
      margin: 0 auto;
      background-position: center 10px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .Wrapper {
    display: flex;
    max-width: 640px;
    margin: 0 auto;
    flex-direction: column;
  }
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .BackBtn, .SkipBtn {
      border: 2px solid var(--colors-surface-150);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      color: var(--colors-surface-900-p);
      margin: 32px;
      background: transparent;
      height: 40px;
      font-weight: var(--typography-font-weight-bold);
      font-size: 12px;
      cursor: pointer;
    }
    .BackBtn {
      width: 40px;
    }
    .SkipBtn {
      padding: 0 48px;
      text-transform: uppercase;
    }
  }
  .Center {
    padding-bottom: 40px;
  }
  .Logo {
    margin-top: 40px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    .Day {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.386 14.583a7.244 7.244 0 011.555.614l3.525-13.113A1.659 1.659 0 0016.292.056a1.667 1.667 0 00-2.034 1.17L10.73 14.343a7.124 7.124 0 011.655.24zM12.254 4.411a1.66 1.66 0 00-3.21-.857L5.631 16.247A7.242 7.242 0 019.569 14.4l2.685-9.989zM7.847 20.8a2.77 2.77 0 013.391-1.952 2.759 2.759 0 011.96 3.38 2.77 2.77 0 01-3.393 1.95 2.757 2.757 0 01-1.958-3.379zm1.098 6.577c3.25.867 6.591-1.054 7.461-4.293.872-3.237-1.057-6.567-4.307-7.434-3.25-.868-6.59 1.053-7.462 4.292-.87 3.238 1.059 6.567 4.308 7.435z' fill='%231E97F7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.769 10.086a1.666 1.666 0 00-2.035 1.17L17.477 23.37c-.86 3.194-3.728 5.319-7.028 5.319-3.942-.04-7.126-3.236-7.126-7.174V16.52c0-1.82-1.496-3.31-3.323-3.31v8.305c0 5.73 4.612 10.385 10.337 10.484 4.84.063 9.093-3.1 10.35-7.771l3.256-12.114a1.66 1.66 0 00-1.174-2.028z' fill='%231E97F7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4 19.389l3.607-13.412a1.66 1.66 0 00-3.21-.857l-2.88 10.709a7.194 7.194 0 012.482 3.56z' fill='%231E97F7'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
    }
  }
  .Title {
    font-size: 30px;
    color: var(--colors-surface-900-p);
    margin-bottom: 6px;
  }
  .Description {
    color: var(colors-surface-700);
    font-size: var(--typography-font-size-default);
    margin-bottom: 32px;
  }
  .SetupTitle {
    font-size: 12px;
    line-height: 12px;
    color: var(--colors-surface-600);
    text-transform: uppercase;
  }
  .SetupWrapper {
    border-bottom: 1px solid var(--colors-surface-100);
    padding: 14px 0;
    &:last-of-type {
        border-bottom: none;
      }
  }
  .Setup {
    display: flex;
    align-items: center;
    cursor: pointer;

    .Check {
      width: 24px;
      height: 24px;
      border: 2px solid var(--colors-primary-500-p);
      border-radius: 50%;
      margin-inline-end: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      i path {
        fill: white;
      }
    }
    .StepTitle {
      font-size: 15px;
      color: var(--colors-surface-900-p);
    }
    .StepDescription {
      font-size: 12px;
      color: var(--colors-surface-600);
    }
    .StepStatus {
      margin-inline-start: auto;
    }
    &.Completed {
      cursor: default;
      .Check {
        background: var(--colors-primary-500-p);
      }
      .StepTitle {
        color: var(--colors-surface-600);
        text-decoration: line-through;
      }
    }
    &.Disabled {
      cursor: default;
      .Check {
        border: 2px dashed var(--colors-primary-100);
      }
      .StepTitle {
        color: var(--colors-surface-400);
      }
      .StepDescription {
        color: var(--colors-surface-400);
      }
      .StepStatus {
        path {
          fill: var(--colors-surface-100);
        }
      }
    }
  }
  .Actions {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    margin-bottom: 100px;
    margin-top: 24px;
    button {
      border-radius: 100px;
      padding: 18px 40px;
      width: auto;
      text-transform: uppercase;
      i {
        margin-inline-start: 8px;
        path {
          stroke: var(--colors-primary-500-p);
          fill: var(--colors-primary-500-p);
        }
      }
    }
  }

  .SkipModalTitle {
    text-align: center;
    margin-bottom: 32px;
    font-size: 25px;
    color: var(--colors-surface-900-p);
  }
  .SkipModalDescription {
    text-align: center;
    font-size: var(--typography-font-size-default);
    color: var(--colors-surface-600);
  }
  .SkipModalPath {
    text-align: center;
    font-size: var(--typography-font-size-default);
    color: var(--colors-surface-800);
  }
  .SkipModalActions {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    margin-top: 32px;
  }
  .Link {
    font-weight: var(--typography-font-weight-medium);
    color: var(--colors-primary-500-p);
    font-size: var(--typography-font-size-default);
  }
  .StepNoteWrapper {
    .StepNote {
      display: inline-flex;
      align-items: center;
      background: var(--colors-warning-100);
      color: var(--colors-warning-900);
      font-size: 12px;
      padding: 6px 6px 6px 8px;
      padding-inline-end: 12px;
      border-radius: 6px;
      margin-inline-start: 36px;
      margin-top: 10px;
      .Icon {
        font-size: 16px;
        margin-inline-end: 6px;
      }
      .Link {
        font-size: 12px;
      }
    }
    .extensionTooltip {
      background: var(--colors-primary-900) !important;
      color: var(--colors-surface-0);
      padding: 0 !important;
      width: 360px;
      visibility: visible;
      .tooltipTitle {
        padding: 20px;
        font-size: var(--typography-font-size-default);
      }
      .tooltipDescription {
        position: absolute;
        color: var(--colors-primary-300);
        font-weight: var(--typography-font-weight-medium);
        bottom: 23px;
        font-size: 12px;
        inset-inline-end: 65px;
        text-decoration: underline;
      }
      .tooltipImage {
        padding: 8px 0 0 0;
        padding-inline-end: 20px;
      }
    }
    &:hover {
    .extensionTooltip {
        opacity: 1;
      }
    }
  }
`;

const Logo = styled.div<{ logoImage: string }>`
  width: 40px;
  height: 40px;
  background-image: ${(p) => p.logoImage};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export enum StepState {
  RequiredPreviousStep = "required-previous-step",
  NotStarted = "not-started",
  InProgress = "in-progress",
  Done = "done",
}

type Step = {
  title: string;
  description?: string;
  state: StepState;
  click: () => void;
  note?: JSX.Element | string | null;
  tooltip?: JSX.Element | string | null;
  customSuccessIcon?: JSX.Element;
};

type AppData = {
  app: IntegrationObject;
  installedApp?: {
    uuid: string;
    status: string;
    type: ApplicationType;
  };
};

const removePendingIntegrationLS = () => {
  localStorage.removeItem("onboarding_integration");
};

const getConfig = (
  companyUuid: string,
  integrationName: ApplicationType,
  app: AppData,
  extensionInstalled: boolean,
  extensionDownloadUrl: string | undefined,
  t: TFunction,
): Step[] => {
  const integrationLoginStep = {
    title: t("integration_step login_integration_title {{integrationName}}", { integrationName }),
    description: t("integration_step login_integration_description"),
    state: app.installedApp ? StepState.Done : StepState.NotStarted,
    customSuccessIcon: images.cloudSuccess,
    click: async () => {
      // link integration event for hubspot
      const integrationVal = integrationName === "Jira" ? "JIRA" : integrationName;
      await SubscriptionApi.linkIntegration(companyUuid, { integration: integrationVal });
      ga.trackSelectIntegration({
        integration_name: integrationName,
        status: "",
      });
      ga.trackClickAuthorizeIntegration(integrationName);

      const fromUrl = encodeURIComponent(`${window.location.href}?status=success`);
      window.location.href = `${app.app.url}?companyUuid=${companyUuid}&from=${fromUrl}`;
    },
  };
  const extensionInstallStep = {
    title: t("integration_step download_extension_title"),
    description: t("integration_step download_extension_description {{integrationName}}", { integrationName }),
    state: extensionInstalled ? StepState.Done : StepState.NotStarted,
    click: () => {
      if (!extensionInstalled && extensionDownloadUrl) {
        ga.trackInstallExtensionPopup(GaInstallExtensionPopupStatus.install);
        ga.trackDownloadBrowserExtensionClick(DownloadExtensionPage.IntegrationSetupOnboarding);
        window.location.href = extensionDownloadUrl;
      }
    },
    note: extensionInstalled ? t("integration_step extension_login_reminder") : null,
    tooltip: extensionInstalled ? (
      <div>
        <div className={styles.tooltipTitle}>{t("extension_install_tooltip_title")}</div>
        <TooltipImg className={styles.tooltipImage} />
        <div className={styles.tooltipDescription}>{t("extension_install_tooltip_pin-hint")}</div>
      </div>
    ) : null,
  };

  const InstallNote = (): JSX.Element => (
    <>
      <span>{t("integration_step install_integration_title {{integrationName}}", { integrationName })} </span>
      <a className={styles.Link} rel="noreferrer" href={app.app.installAppUrl} target="_blank">
        {t("integration_step install_integration_CTA")}
      </a>
    </>
  );

  const configs = {
    [ApplicationType.Asana]: [integrationLoginStep, extensionInstallStep],
    [ApplicationType.Monday]: [
      {
        ...integrationLoginStep,
        note: <InstallNote />,
      },
      extensionInstallStep,
    ],
    [ApplicationType.Jira]: [integrationLoginStep, extensionInstallStep],
    [ApplicationType.Clickup]: [integrationLoginStep, extensionInstallStep],
    [ApplicationType.Todoist]: [integrationLoginStep, extensionInstallStep],
    [ApplicationType.Trello]: [integrationLoginStep, extensionInstallStep],
    [ApplicationType.Quickbooks]: [integrationLoginStep, extensionInstallStep],
    // coming soon
    [ApplicationType.Basecamp]: [],
    [ApplicationType.Notion]: [],
  };

  return configs[integrationName];
};

const StepRow = ({ step, t }: { step: Step; t: TFunction }) => {
  let icon = images.calArrowRight;
  if (step.state === StepState.Done) {
    icon = step.customSuccessIcon || <></>;
  } else if (step.state === StepState.InProgress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    icon = (
      <div>
        <i>{images.reloadIcon}</i>
        <span>{t("Syncing...")}</span>
      </div>
    );
  }

  const StepNote = () => (
    <>
      <div className={styles.Icon}>☝️</div>
      <div>{step.note}</div>
    </>
  );

  return (
    <div className={styles.SetupWrapper}>
      <div
        className={`${styles.Setup} ${step.state === StepState.Done ? styles.Completed : ""} ${
          step.state === StepState.RequiredPreviousStep ? styles.Disabled : ""
        }`}
        onClick={step.click}
      >
        <div className={styles.Check}>
          <i>{images.check}</i>
        </div>
        <div>
          <div className={styles.StepTitle}>{step.title}</div>
          <div className={styles.StepDescription}>{step.description || ""}</div>
        </div>
        <div className={styles.StepStatus}>{icon}</div>
      </div>
      {step.note ? (
        <div className={styles.StepNoteWrapper}>
          {step.tooltip ? (
            <>
              <div className={styles.StepNote} data-tip data-for="tooltip">
                <StepNote />
              </div>
              <ReactTooltip className={styles.extensionTooltip} id="tooltip">
                {step.tooltip}
              </ReactTooltip>
            </>
          ) : (
            <div className={styles.StepNote}>
              <StepNote />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export const IntegrationStepsPage = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const location = useLocation();
  const history = useHistory();
  const context = useContext(GlobalContext);
  const extensionService = useExtensionService();
  const [companyUuid, setCompanyUuid] = useState<string>();
  const [showSkipModal, setShowSkipModal] = useState(false);

  const getIntegrationName = () => {
    const integrationRoute: string = location.pathname.split("/").pop() as string;
    return `${integrationRoute.charAt(0).toUpperCase()}${integrationRoute.substring(1)}` as ApplicationType;
  };
  const integrationName = getIntegrationName();
  const applications = ApplicationsService.integrations;
  const targetApp = applications[integrationName as ApplicationType];
  const getBgImage = () =>
    [ApplicationType.Asana, ApplicationType.Monday].includes(integrationName as ApplicationType)
      ? integrationName.toLowerCase()
      : "default";

  const [init, dataLoading, data] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    if (!targetApp) {
      history.push("/integration");
      return {};
    }
    setCompanyUuid(company.uuid);
    const installedApps = await getApplications(company.uuid);
    const installedApp = installedApps.content.find((app) => app.type === integrationName);

    return {
      app: targetApp,
      installedApp,
    };
  }, []);

  const [checkExtension, extensionLoading, extensionData] = useAsyncCallback(async () => {
    const version = await extensionService.getExtensionVersion();
    const extensionDownloadUrl = extensionService.getExtensionDownloadUrl();
    return {
      extensionInstalled: version !== null,
      extensionDownloadUrl,
    };
  }, [extensionService]);

  useEffect(() => {
    void init();
    void checkExtension();
    if (/status=success/.test(history.location.search)) {
      ga.trackIntegrationSuccess(integrationName as ApplicationType);
    }
  }, []);

  const steps: Step[] = useMemo(
    () =>
      companyUuid && data
        ? getConfig(
            companyUuid!,
            integrationName as ApplicationType,
            data as AppData,
            !!extensionData?.extensionInstalled,
            extensionData?.extensionDownloadUrl,
            t,
          )
        : [],
    [data, extensionData],
  );
  const isCompleted = steps.every((step) => step.state === StepState.Done);

  return (
    <div className={styles.MainWrapper}>
      <section className={styles.Top}>
        <header className={styles.Header}>
          <button
            className={styles.BackBtn}
            type="button"
            onClick={() => {
              removePendingIntegrationLS();
              history.push("/integration");
            }}
          >
            {images.navArrowLeft}
          </button>
          <button className={styles.SkipBtn} type="button" onClick={() => setShowSkipModal(true)}>
            {t("integrations_steps_skip_CTA")}
          </button>
        </header>
        <div className={styles.BGImage} style={{ backgroundImage: `url(/integrations/${getBgImage()}-bg.png)` }} />
      </section>
      <section className={styles.Center}>
        <div className={styles.Wrapper}>
          <div className={styles.Logo}>
            <div className={styles.Day} />
            <div>{images.plusBig}</div>
            {data?.app ? <Logo logoImage={data.app.logo} /> : null}
          </div>
          <div className={styles.Title}>{t("integrations_steps_title {{integrationName}}", { integrationName })}</div>
          <div className={styles.Description}>
            <span>{t("integrations_steps_description {{integrationName}}", { integrationName })} </span>
            <a className={styles.Link} href={t("integration_steps learn_more_link")} rel="noreferrer" target="_blank">
              {t("integration_steps learn more")}
            </a>
          </div>
          <div className={styles.SetupTitle}>{t("integrations_steps Setup the integration")}</div>
          {!dataLoading && !!data ? (
            <div>
              {steps?.map((step) => (
                <StepRow key={step.title} step={step} t={t} />
              ))}
              {isCompleted ? (
                <div className={styles.Actions}>
                  <Button
                    state={ButtonState.primary}
                    value={t("integration_page explore_CTA")}
                    onClick={() => {
                      removePendingIntegrationLS();
                      window.location.href = "/";
                      ga.trackExploreApp(DownloadExtensionPage.IntegrationSetupOnboarding);
                    }}
                  />
                  <Button
                    state={ButtonState.secondary}
                    value={
                      <>
                        {t("integration_page track_CTA {{integrationName}}", { integrationName })}
                        <i>{images.helpCenter}</i>
                      </>
                    }
                    onClick={() => {
                      removePendingIntegrationLS();
                      ga.trackClickExploreExternalApp(DownloadExtensionPage.IntegrationSetupOnboarding);
                      window.open(data.app!.appUrl as string, "_blank");
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>

      <ModalDialog
        onClose={() => setShowSkipModal(false)}
        isOpen={showSkipModal}
        preventOutsideClose
        width={415}
        top="40%"
        padding={32}
      >
        <div className={styles.SkipModalTitle}>{t("skip_modal title")}</div>
        <div className={styles.SkipModalDescription}>{t("skip_modal description")}</div>
        <div className={styles.SkipModalPath}>{t("skip_modal path")}</div>
        <div className={styles.SkipModalActions}>
          <Button
            state={ButtonState.secondary}
            value={t("skip_modal leave_CTA")}
            onClick={() => {
              removePendingIntegrationLS();
              ga.trackSelectIntegration({
                integration_name: "",
                status: GaIntegrationStatus.skip,
              });
              ga.trackSkipIntegration(integrationName, GaIntegrationState.Skip);
              window.location.href = "/";
            }}
          />
          <Button
            state={ButtonState.primary}
            value={t("skip_modal continue_CTA")}
            onClick={() => {
              ga.trackSkipIntegration(integrationName, GaIntegrationState.Stay);
              setShowSkipModal(false);
            }}
          />
        </div>
      </ModalDialog>
    </div>
  );
};
