import styled from "styled-components";
import { AddTimeButton, StartTimerButton, StopTimerButton } from "./TimerButtons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface TimerButtonProps {
  isActivityRunning: boolean;
  addActivityMode: boolean;
  onStartActivity: () => void;
  onStopActivity: () => void;
  onAddActivity: () => void;
}

const TimerButton = ({
  isActivityRunning,
  onStartActivity,
  onStopActivity,
  onAddActivity,
  addActivityMode,
}: TimerButtonProps) => (
  <Wrapper>
    {!isActivityRunning && addActivityMode ? <AddTimeButton onClick={() => onAddActivity()} /> : null}
    {!isActivityRunning && !addActivityMode ? <StartTimerButton onClick={() => onStartActivity()} /> : null}
    {isActivityRunning ? <StopTimerButton onClick={() => onStopActivity()} /> : null}
  </Wrapper>
);

export default TimerButton;
