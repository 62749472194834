import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { getSelectedColumns } from "utils/tableHelpers";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { getDateColumn, getDateCellData, iDateCellData } from "utils/reportsHelpers";
import BEM from "utils/BEM";
import { minsToHrsMins } from "utils/common";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { iColumn } from "components/TableCommon";
import { SetIsLoading } from "types/reports";
import ReportUserInfo from "./ReportUserInfo";
import TablePage from "../TablePage";
import "styles/reports-hoursbank-table.scss";
import { getPhasesLabel, PhasesType } from "./helpers";

const b = BEM.b("reports-hoursbank-table");

interface iPhase {
  daysMask?: string;
  dayTypeBasedOnSchedule?: string;
  uuid: string;
  name: number;
}

interface iAdjustment {
  adjustment: string;
  reason: string;
}

// TODO =type=
type tDateStatus = "completed";
// TODO =interface=
interface iDate {
  status: tDateStatus;
  schedule: { name: string };
  hoursBankPhases: { [key: string]: number };
  workedMinutes: number;
  missedMinutes: number;
  hoursBankMinutes: number;
  cumulativeHoursBankMinutes: number;
  hoursBankOverLimitMinutes: number;
  adjustments: iAdjustment | iAdjustment[];
}

interface iReportsHoursbankTableData {
  date: iDateCellData;
  hoursBankPhases: { [key: string]: number };
  workedHours: string;
  missedMinutes: string;
  hoursBankMinutes: string;
  cumulativeHoursBankMinutes: string;
  hoursBankOverLimitMinutes: string;
  adjustment: string | string[];
  reason: string | string[];
}

interface iReportsHoursbankTableProps extends WithTranslation {
  searchObj: any;
  startDate: any;
  endDate: any;
  oldReport: boolean;
  updateRecalculatingMessage: (recalculatingInProgress: boolean) => void;
  onlyNonZeroPhases: boolean;
  setIsLoading: SetIsLoading;
}

interface iReportsHoursbankTableState {
  dates: iDate[];
  totalHoursBankPhases: { [key: string]: any }; // todo
  activePhases: iPhase[];
  isFetching: boolean;
  selectedColumns: string;
  totalHoursBankMinutes: any;
  totalMissedMinutes: any;
  totalWorkedMinutes: any;
  error: string | null;
  employeeInfo: any | null; // todo
}

class ReportsHoursbankTable extends Component<iReportsHoursbankTableProps, iReportsHoursbankTableState> {
  readonly state: iReportsHoursbankTableState = {
    dates: [],
    totalHoursBankPhases: {},
    activePhases: [],
    isFetching: false,
    selectedColumns: getSelectedColumns(
      "date,worked_hours,phases,adjustments,reason,hours_bank,cumulative_hours_bank,hours_bank_limit_minutes",
      "ReportsHoursbankTable",
    ),
    totalHoursBankMinutes: null,
    totalMissedMinutes: null,
    totalWorkedMinutes: null,
    error: null,
    employeeInfo: null,
  };

  componentDidMount() {
    void this.getReportData();
  }

  getReportData = async () => {
    const { t, searchObj, startDate, endDate, oldReport, updateRecalculatingMessage, onlyNonZeroPhases, setIsLoading } =
      this.props;
    const companyUUID = window.global_store.company.uuid;

    if (searchObj && startDate && endDate && companyUUID) {
      this.setState({ isFetching: true }, () => setIsLoading(true));

      try {
        const response = await getReport(
          {
            UserProfileUUID: searchObj.employee.uuid,
            startDate,
            endDate,
            companyUUID,
            onlyNonZeroPhases,
            oldReport,
            type: "hours_bank",
          },
          true,
        );

        const resp = response.content;
        if (!oldReport && resp.dates) {
          const allDaysCount = resp.dates.length || 0;
          const completedDaysCount = (resp.dates as iDate[]).filter((d) => d.status === "completed").length;

          if (!resp.dates.length) {
            updateRecalculatingMessage(false);
          } else {
            updateRecalculatingMessage(allDaysCount !== completedDaysCount || response?.metadata?.status === "pending");
          }
        }

        this.setState({
          ...resp,
          activePhases: response.metadata.activePhases,
          isFetching: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({
          error: t("Failed to generate report"),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = (): iColumn<iReportsHoursbankTableData>[] => {
    const { totalHoursBankMinutes, totalHoursBankPhases, activePhases, totalMissedMinutes, totalWorkedMinutes } =
      this.state;
    const { t } = this.props;

    const phasesColumns = activePhases.length
      ? activePhases.map(
          (phase): iColumn<iReportsHoursbankTableData> => ({
            accessor: phase.uuid,
            rubyAccessor: "phases",
            groupLabel: t("Phases"),
            label: getPhasesLabel(t, PhasesType.hoursBank, phase),
            Cell: (row) => {
              const hours = row.original.hoursBankPhases?.[phase.uuid] || 0;
              return minsToHrsMins(hours);
            },
            Footer: minsToHrsMins(totalHoursBankPhases[phase.uuid] || 0),
            align: "right",
            minWidth: 100,
          }),
        )
      : [];

    const columns: iColumn<iReportsHoursbankTableData>[] = [
      getDateColumn<iReportsHoursbankTableData>(t),
      {
        accessor: "workedHours",
        rubyAccessor: "worked_hours",
        label: t("Total Worked Hours"),
        Footer: totalWorkedMinutes ? minsToHrsMins(totalWorkedMinutes) : "00:00",
        align: "right",
        minWidth: 100,
      },
      {
        accessor: "missedMinutes",
        rubyAccessor: "missed_hours",
        label: t("Missed Minutes"),
        Footer: totalMissedMinutes ? minsToHrsMins(totalMissedMinutes) : "00:00",
        minWidth: 100,
        align: "right",
      },
      ...phasesColumns,
      {
        accessor: "adjustment",
        rubyAccessor: "adjustments",
        label: t("Adjustments"),
        Cell: (r) => {
          if (Array.isArray(r.value)) {
            return r.value.map((adj, i) => (
              <div
                key={`${adj}${i}`}
                style={{
                  color: adj > 0 ? "#00CA73" : adj < 0 ? "#FE6764" : "inherit",
                }}
              >
                {adj > 0 ? "+" : ""}
                {minsToHrsMins(adj)} hs
              </div>
            ));
          }
          if (r.value) {
            return (
              <span
                style={{
                  color: r.value > 0 ? "#00CA73" : r.value < 0 ? "#FE6764" : "inherit",
                }}
              >
                {r.value > 0 ? "+" : ""}
                {minsToHrsMins(r.value)} hs
              </span>
            );
          }
          return "";
        },
        minWidth: 100,
      },
      {
        accessor: "reason",
        rubyAccessor: "reason",
        label: t("Reason"),
        Cell: (r) => {
          if (Array.isArray(r.value)) {
            return r.value.map((adj, i) => <div key={`${adj}${i}`}>{adj}</div>);
          }
          if (r.value) {
            return r.value;
          }
          return "";
        },
        minWidth: 100,
      },
      {
        accessor: "hoursBankOverLimitMinutes",
        rubyAccessor: "hours_bank_limit_minutes",
        label: t("Removed Hours"),
        minWidth: 100,
        align: "right",
      },
      {
        accessor: "hoursBankMinutes",
        rubyAccessor: "hours_bank",
        label: t("Daily Banco"),
        Footer: totalHoursBankMinutes ? minsToHrsMins(totalHoursBankMinutes) : "00:00",
        minWidth: 100,
        align: "right",
      },
      {
        accessor: "cumulativeHoursBankMinutes",
        rubyAccessor: "cumulative_hours_bank",
        label: t("Cumulative Hours Bank"),
        minWidth: 100,
        align: "right",
      },
    ];

    return columns.filter((col) => col);
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsHoursbankTable", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render() {
    const { isFetching, error, employeeInfo, dates, selectedColumns } = this.state;
    const { t, onlyNonZeroPhases, searchObj, startDate, endDate, oldReport } = this.props;
    let lastScheduleName;

    if (error) {
      return <div>{error}</div>;
    }

    const data = dates.map((d): iReportsHoursbankTableData => {
      if (d.schedule) {
        lastScheduleName = d.schedule.name;
      }

      const dataObj = {
        date: getDateCellData(d),
        hoursBankPhases: d.hoursBankPhases,
        workedHours: d.workedMinutes ? minsToHrsMins(d.workedMinutes) : "00:00",
        missedMinutes: d.missedMinutes ? minsToHrsMins(d.missedMinutes) : "00:00",
        hoursBankMinutes: d.hoursBankMinutes ? minsToHrsMins(d.hoursBankMinutes) : "00:00",
        hoursBankOverLimitMinutes: d.hoursBankOverLimitMinutes ? minsToHrsMins(d.hoursBankOverLimitMinutes) : "00:00",
        cumulativeHoursBankMinutes: d.cumulativeHoursBankMinutes
          ? minsToHrsMins(d.cumulativeHoursBankMinutes)
          : "00:00",
        adjustment: d.adjustments
          ? !Array.isArray(d.adjustments)
            ? d.adjustments.adjustment
            : d.adjustments.map((adj) => adj.adjustment)
          : "",
        reason: d.adjustments
          ? !Array.isArray(d.adjustments)
            ? d.adjustments.reason
            : d.adjustments.map((adj) => adj.reason)
          : "",
        status: d.status,
      };

      return dataObj;
    });

    const columns = this.getColumns();

    return (
      <div className={b()}>
        <TablePage<iReportsHoursbankTableData>
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDatailsClassName="table-details-ai-start"
          tableDetails={<ReportUserInfo employeeInfo={{ ...employeeInfo, schedule: lastScheduleName }} />}
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(
                value: "csv" | "xlsx" | "pdf" | undefined, // todo ts
              ) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  oldReport,
                  onlyNonZeroPhases,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  searchObj,
                  startDate,
                  endDate,
                  reportType: selectedColumns ? "hours_bank" : "hoursbank",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className:
              // eslint-disable-next-line no-underscore-dangle
              !oldReport && rowInfo?.row._original.status && rowInfo.row._original.status !== "completed"
                ? // eslint-disable-next-line no-underscore-dangle
                  `row-incomplete row-incomplete_${rowInfo.row._original.status}`
                : "",
          })}
          rows={data}
          columns={columns}
          loading={isFetching}
          interactive={false}
          className="reports-hoursbank-table"
        />
      </div>
    );
  }
}

export default withTranslation(["reports-page", "phases"])(ReportsHoursbankTable);
