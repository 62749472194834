export const TIME_PLACEHOLDER = "00:00";

export const BREAK_TIMES = [
  { label: "5m", value: 5 },
  { label: "10m", value: 10 },
  { label: "15m", value: 15 },
  { label: "20m", value: 20 },
  { label: "30m", value: 30 },
  { label: "40m", value: 40 },
  { label: "45m", value: 45 },
  { label: "50m", value: 50 },
  { label: "60m", value: 60 },
  { label: "65m", value: 65 },
  { label: "68m", value: 68 },
  { label: "70m", value: 70 },
  { label: "72m", value: 72 },
  { label: "75m", value: 75 },
  { label: "78m", value: 78 },
  { label: "80m", value: 80 },
  { label: "85m", value: 85 },
  { label: "90m", value: 90 },
  { label: "100m", value: 100 },
  { label: "110m", value: 110 },
  { label: "112m", value: 112 },
  { label: "120m", value: 120 },
  { label: "132m", value: 132 },
  { label: "150m", value: 150 },
  { label: "180m", value: 180 },
  { label: "240m", value: 240 },
  { label: "300m", value: 300 },
  { label: "360m", value: 360 },
  { label: "720m", value: 720 },
];

export const DEFAULT_BREAK_NAME = "break";
