import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { hasEmployeesAccess } from "utils/common";
import FullPage from "components/Layout/FullPage";
import { AggregationGroupType } from "types/models/dashboard";
import { PermissionSectionName } from "types/models/permissions";
import { HeaderAction } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import PunchesAddPopup from "components/Punches/PunchesAddPopup";
import { AddPunchPunch } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { addPunch } from "utils/apiHelpers";
import PunchSettingsModal from "components/Punches/PunchSettingsModal";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import GroupsSuperpunchTable from "./GroupsSuperpunchTable";
import { updateUrlWithParams } from "./helpers";
import GroupSuperpunchSearch from "./GroupSuperpunchSearch";
import BulkActionConfirmModal, { BulkAction } from "./BulkActionConfirmModal";
import * as images from "../svg-images";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FiltersWrapper = styled.div`
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 25px;
  display: flex;
`;

const PunchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid #b4c4d1;
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

type GroupsSuperpunchPageProps = {
  startDate: string;
  endDate: string;
  groupType: Exclude<AggregationGroupType, "userProfile" | "company">;
};

const GroupsSuperPunchPage = ({ groupType, startDate, endDate }: GroupsSuperpunchPageProps) => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.employeesPage]);
  const history = useHistory();
  const startDateM = moment(startDate);
  const endDateM = moment(endDate);
  const [bulkActionData, setBulkActionData] = useState<BulkAction | null>(null);
  // PROD-11156 PROD-11157 todo
  const [processingGroupsUuids, setProcessingGroupsUuids] = useState<string[]>([]);
  const availableDaysCount = 60;
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showAddPunchPopup, setShowAddPunchPopup] = useState(false);

  const onPendingPunchesClick = (uuid: string, pendingPunchesCount: number, isProcessing: boolean) => {
    if (!isProcessing && pendingPunchesCount) {
      // TODO
      // setBulkActionData({
      //   actionType: BulkActionType.groupPunchApprove,
      //   entityUuid: uuid,
      //   bulkSize: pendingPunchesCount,
      // });
    }
  };

  /* eslint camelcase: ["warn", { "allow":
    [shift_compilation, shift_id]
  }] */
  const onAddPunch = async (
    punch: AddPunchPunch,
    _: string | undefined,
    event: AddPunchMappedEvent["event"] | undefined,
  ): Promise<void> => {
    try {
      await addPunch({
        body: {
          ...punch,
          shift_compilation: event
            ? {
                shift_id: event.shiftId,
                date: event.date,
                key: event.key,
              }
            : null,
        },
      });
    } finally {
      setShowAddPunchPopup(false);
    }
  };

  const punchSettingsEnabled = window.global_store.profile?.permissions?.some(
    (r: string) => r === PermissionSectionName.punchSettings,
  );
  return (
    <FullPage
      headerAction={
        <HeaderAction>
          {punchSettingsEnabled && (
            <PunchSettingsButton onClick={() => setShowSettingsPopup(true)}>{images.gearIcon}</PunchSettingsButton>
          )}

          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(`${TranslationNamespaces.employeesPage}|Add Punches`)}
            onClick={() => setShowAddPunchPopup(true)}
          />
        </HeaderAction>
      }
    >
      <Wrapper>
        <FiltersWrapper>
          <GroupSuperpunchSearch startDate={startDateM} endDate={endDateM} />
          <DateRangePicker
            newOnChangeApproach
            isAdmin={hasEmployeesAccess()}
            onChange={(startDateL, endDateL) => {
              if (startDateL && endDateL) {
                updateUrlWithParams(
                  {
                    type: "group-edit",
                    startDate: startDateL || moment(),
                    endDate: endDateL || moment().add(10, "day"),
                    group_type: groupType,
                  },
                  history,
                );
              }
            }}
            startDate={startDateM}
            endDate={endDateM}
            availableDaysCount={availableDaysCount}
          />
        </FiltersWrapper>
        <div>
          <GroupsSuperpunchTable
            startDate={startDate}
            endDate={endDate}
            groupType={groupType}
            onPendingPunchesClick={onPendingPunchesClick}
            processingGroupsUuids={processingGroupsUuids}
          />
        </div>

        {bulkActionData && (
          <BulkActionConfirmModal
            bulkAction={bulkActionData}
            onClose={() => setBulkActionData(null)}
            // mock purpose
            setProcessingGroupsUuids={(uuid) => setProcessingGroupsUuids([...processingGroupsUuids, uuid])}
          />
        )}

        <>
          {showSettingsPopup && (
            <PunchSettingsModal onClose={() => setShowSettingsPopup(false)} setNotification={() => {}} />
          )}

          <SidePopupOverlay
            width={400}
            header={t("Add a Punch")}
            isOpen={showAddPunchPopup}
            onClose={() => setShowAddPunchPopup(false)}
          >
            <PunchesAddPopup
              prefillProfile={undefined}
              onYes={onAddPunch}
              onClose={() => setShowAddPunchPopup(false)}
            />
          </SidePopupOverlay>
        </>
      </Wrapper>
    </FullPage>
  );
};

export default GroupsSuperPunchPage;
