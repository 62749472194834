import { TableButton } from "components/styled/Page";
import { MouseEvent } from "react";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { showSnackbar } from "utils/common";

const Wrapper = styled.div`
  width: 100%;
  background: var(--colors-surface-50);
  border-radius: var(--shapes-border-radius-default);
  font-weight: var(--typography-font-weight-default);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);
  padding: 16px 8px;
  padding-inline-end: 66px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  &:hover .copy-button {
    display: flex;
  }
`;

const CopyPlaceholder = styled.div`
  position: absolute;
  height: 100%;
  inset-inline-end: 0;
  display: none;
  align-items: center;
  top: 0;
`;

const onCopy = async (value: string, notification: string) => {
  try {
    await navigator.clipboard.writeText(value);

    showSnackbar({ text: notification, notificationStyle: "notice" });
  } catch (e) {
    console.error(e);
  }
};

interface FieldWithCopyProps {
  value: string;
  copyButtonEnabled: boolean;
}

const FieldWithCopy = ({ value, copyButtonEnabled }: FieldWithCopyProps) => {
  const { t } = useTranslation(TranslationNamespaces.common);
  return (
    <Wrapper>
      {value}
      {copyButtonEnabled && (
        <CopyPlaceholder className="copy-button">
          <TableButton
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              void onCopy(value, t(`${TranslationNamespaces.requestsPageTmp}|Url copied`));
            }}
          >
            {t("Copy")}
          </TableButton>
        </CopyPlaceholder>
      )}
    </Wrapper>
  );
};

export default FieldWithCopy;
