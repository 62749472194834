import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import FieldWrapper from "components/UI/FieldWrapper";
import CompanyTaxPayerField from "components/controls/CompanyTaxPayerField";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import { getIndustryTypes } from "utils/apiHelpers";
import styled from "styled-components";
import { ErrorLabel } from "components/UI/TextLabels";
import TextInputControl from "components/controls/StyledTextInput";
import { getTitle, hasProjectsAccess } from "utils/common";
import { CompanyProfileCompany, CompanyProfileIndustryType, CompanyCurrency } from "types/models/company";
import { TranslationNamespaces } from "types/translationNamespaces";
import CompanyDocumentLogoUploader from "./CompanyDocumentLogoUploader";

const MainWrapper = styled.div`
  max-width: 420px;
  margin-top: 23px;
`;

const RowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-top: 32px;
`;

const RowSubTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #a1b2cf;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;

type CompanyProfileGeneralTabFields = "name" | "fullName" | "taxPayerId" | "category" | "currency";
export type OnChangePayload = Partial<
  Record<CompanyProfileGeneralTabFields & ("uploadedDocumentLogo" | "documentLogo"), string>
>;
export type CompanyProfileGeneralTabErrors = Partial<Record<CompanyProfileGeneralTabFields, string>> | null;

interface CompanyProfileGeneralTabProps extends WithTranslation {
  company: CompanyProfileCompany;
  isLoading: boolean;
  errors: Partial<Record<CompanyProfileGeneralTabFields, string>> | null;
  uploadedDocumentLogo: File | null;
  onChange: (val: OnChangePayload) => void;
  onSave: () => void;
}

interface CompanyProfileGeneralTabState {
  categories: { value: number; label: string }[];
  currencies: { value: CompanyCurrency; label: string }[];
}

class CompanyProfileGeneralTab extends Component<CompanyProfileGeneralTabProps, CompanyProfileGeneralTabState> {
  constructor(props: CompanyProfileGeneralTabProps) {
    super(props);
    const { t } = props;

    const currencies = [
      {
        value: CompanyCurrency.USD,
        label: `USD - ${t(`${TranslationNamespaces.common}|Dollar`)}`,
      },
      {
        value: CompanyCurrency.AED,
        label: `AED - ${t(`${TranslationNamespaces.common}|Emirati Dirham`)}`,
      },
      {
        value: CompanyCurrency.AUD,
        label: `AUD - ${t(`${TranslationNamespaces.common}|Australian Dollar`)}`,
      },
      {
        value: CompanyCurrency.BRL,
        label: `BRL - ${t(`${TranslationNamespaces.common}|Brasilian Real`)}`,
      },
      {
        value: CompanyCurrency.CHF,
        label: `CHF - ${t(`${TranslationNamespaces.common}|Swiss Franc`)}`,
      },
      {
        value: CompanyCurrency.EUR,
        label: `EUR - ${t(`${TranslationNamespaces.common}|Euro`)}`,
      },
      {
        value: CompanyCurrency.GBP,
        label: `GBP - ${t(`${TranslationNamespaces.common}|British Pound`)}`,
      },
      {
        value: CompanyCurrency.ILS,
        label: `ILS - ${t(`${TranslationNamespaces.common}|Israeli New Shekel`)}`,
      },
      {
        value: CompanyCurrency.JPY,
        label: `JPY - ${t(`${TranslationNamespaces.common}|Japanese Yen`)}`,
      },
    ];
    this.state = {
      categories: [],
      currencies: currencies.sort((a, b) => (a.value < b.value ? -1 : 1)),
    };
    document.title = getTitle(t("Edit Profile"));
  }

  componentDidMount(): void {
    void this.fetchData();
  }

  fetchData = async (): Promise<void> => {
    const { t } = this.props;
    const industryTypes = await getIndustryTypes();

    if (industryTypes?.length) {
      this.setState({
        categories: industryTypes.map((c) => ({
          value: c.id,
          label: t(`${TranslationNamespaces.industryTypes}|${c.key}`),
          name: c.name,
        })),
      });
    } else {
      this.setState({ categories: [] });
    }
  };

  render(): JSX.Element {
    const { categories, currencies } = this.state;

    const { t, company, isLoading, errors, uploadedDocumentLogo, onChange, onSave } = this.props;
    const { name, fullName, taxPayerId = "", category, documentLogo, currency } = company;

    return (
      <MainWrapper>
        <>
          <FieldWrapper fieldName={t("Company Name")} width="100%" loading={isLoading}>
            <TextInputControl
              value={name}
              error={!!errors?.name}
              onChange={(value: string): void => onChange({ name: value })}
            />
          </FieldWrapper>
          {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}

          <FieldWrapper fieldName={t("Company Full Name")} width="100%" loading={isLoading}>
            <TextInputControl
              value={fullName}
              error={!!errors?.fullName}
              onChange={(value: string): void => onChange({ fullName: value })}
            />
          </FieldWrapper>
          {errors?.fullName && <ErrorLabel>{errors.fullName}</ErrorLabel>}

          <CompanyTaxPayerField
            country={window.global_store.profile?.company?.country}
            isLoading={isLoading}
            isValid={!errors?.taxPayerId}
            value={taxPayerId}
            onChange={(value): void => onChange({ taxPayerId: value })}
          />
          {errors?.taxPayerId && <ErrorLabel>{errors.taxPayerId}</ErrorLabel>}

          <FieldWrapper fieldName={t(`${TranslationNamespaces.common}|Category`)} width="100%" loading={isLoading}>
            <Select<number>
              modifiers={{ field: true }}
              value={category}
              onChange={(val) => {
                onChange({ category: val });
              }}
              options={categories}
            />
          </FieldWrapper>

          {hasProjectsAccess() ? (
            <FieldWrapper fieldName={t("Currency")} width="190px" loading={isLoading}>
              <Select
                modifiers={{ field: true }}
                value={currency}
                onChange={(val: CompanyCurrency): void => {
                  onChange({ currency: val });
                }}
                options={currencies}
              />
            </FieldWrapper>
          ) : null}

          <RowTitle>{t("Customizations")}</RowTitle>
          <RowSubTitle>{t(`${TranslationNamespaces.common}|Reports`)}</RowSubTitle>
          <CompanyDocumentLogoUploader
            isLoading={isLoading}
            logoId={documentLogo}
            file={uploadedDocumentLogo}
            onDrop={(file): void => {
              onChange({ uploadedDocumentLogo: file[0] });
            }}
            onClear={(): void => {
              if (uploadedDocumentLogo) {
                onChange({ uploadedDocumentLogo: null });
              } else {
                onChange({ documentLogo: null });
              }
            }}
          />

          {!isLoading ? (
            <ButtonWrapper>
              <Button
                loading={isLoading}
                value={t(`${TranslationNamespaces.common}|Save`)}
                state={ButtonState.primary}
                onClick={onSave}
              />
            </ButtonWrapper>
          ) : null}
        </>
      </MainWrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.companyProfile, TranslationNamespaces.industryTypes])(
  CompanyProfileGeneralTab,
);
