/* eslint-disable camelcase */
import moment from "moment";
import { Schedule, ScheduleFileImport, ScheduleTemplate } from "types/models/schedule";
import { Location, LocationType, LocationValidation, WifiValidation } from "types/models/location";
import {
  CompanyCurrency,
  CompanyProfileIndustryType,
  CompanyType,
  GlobalStoreCompany,
  SignUpCompany,
} from "types/models/company";
import {
  ActiveUserProfile,
  EmployeeListUserProfile,
  GlobalContextEmployee,
  GroupSearchUserProfile,
  SearchEmployeeUserProfile,
  SignUpUser,
  UserProfile,
} from "types/models/userProfile";
import { CompanyRule } from "types/models/companyRules";
import { AddPunchPunch, Punch, PunchDetailsPunch, PunchType } from "types/models/punches";
import {
  RequestApprovalFlowStatus,
  Request,
  RequestSubType,
  RequestType,
  RequestTypeName,
  IcalCalendar,
} from "types/models/request";
import { EmployeeExistsCheckResult } from "types/models/employee";
import { OnCall, OnCallStatus, OnCallType } from "types/models/onCalls";
import {
  BusinessRuleCrossShifts,
  BusinessRuleGroup,
  BusinessRuleGroupByUser,
  BusinessRuleHoursDistribution,
  BusinessRuleNightShift,
  BusinessRuleOnCalls,
  BusinessRulePayedOvertime,
  BusinessRuleTolerance,
  BusinessRuleType,
} from "types/models/businessRulesGroup";
import {
  FilenameConfiguration,
  PayrollEvent,
  PayrollEventType,
  PayrollLayoutFull,
  PayrollProvider,
  InfotypeOperation,
  RunType,
} from "types/models/payroll/payrollLayout";
import { Position } from "types/models/position";
import { AddSubsidiaryResponse, GroupSearchSubsidiary, Subsidiary } from "types/models/subsidiary";
import { AddDepartmentResponse, Department, GroupSearchDepartment } from "types/models/department";
import { DailySummaryReport } from "types/models/superpunch";
import {
  Frequency,
  Groups,
  PeriodAggregation,
  AggrerationType,
  AggregationGroupType,
  DashboardFrequency,
} from "types/models/dashboard";
import { GroupSearchTeam, Team } from "types/models/team";
import { Operation } from "types/models/operations";
import { EmployeePermissionRole, PermissionRole, PermissionSectionName } from "types/models/permissions";
import { DeactivatedPayrollLock, DefaultPayPeriod, PayPeriod, PayrollLock } from "types/models/payrollLocks";
import { ClientReportDownload } from "types/models/clientReportDownload";
import { PayrollGroup, PayrollGroupCreateEdit, TogglePayrolGroupAction } from "types/models/payrollGroup";
import { ClientReportsType, ReportType } from "types/reports";
import { ReportDetailed } from "types/models/reports/detailed(payroll)";
import { RDStationActionEventType, Address } from "types/common";
import { Shift, ShiftCompilationInRange } from "types/models/shift";

// ===== company.ts =====
export interface GetSchedulesRequestData {
  companyUuid: string;
  params?: {
    scheduleTypes?: string[];
    active?: boolean;
    perPage?: number;
  };
}

export interface GetSchedulesResponseData {
  content: Schedule[];
}

export interface GetLocationsRequestData {
  page?: number;
  perPage?: number;
  active?: boolean;
  verificationMethods?: LocationValidation[];
  searchTerm?: string;
  locationTypes?: LocationType[];
}

export interface GetLocationsResponseData {
  locations: Location[];
  pagingTotalCount: string;
}

export interface GetOneLocationResponseData {
  location: Location;
}

export type GetPayrollLayoutsRequestData = {
  companyUUID: string;
};

export type GetPayrollLayoutsResponseData = {
  content: PayrollLayoutFull[];
};

export type GetLocksListRequestData = {
  companyUuid: string;
  forDeactivatedProfile?: boolean;
};

export type GetDeactivatedLocksListRequestData = {
  companyUuid: string;
  body: {
    skip?: number;
    limit?: number;
    filterModel?: any;
    sortModel?: any;
  };
};

export type BulkPayrollLocksRequestData = {
  companyUuid: string;
  body: {
    content: {
      filterModel?: any;
      requestedBy: string;
      includeLocksUuids: string[];
      excludeLocksUuids: string[];
    };
  };
};

export type BulkSendToPayrollRequestData = {
  companyUuid: string;
  body: {
    content: {
      filterModel?: any;
      payrollLayoutUuid: string;
      requestedBy: string;
      includeLocksUuids: string[];
      excludeLocksUuids: string[];
      forceRecalculate: boolean;
    };
  };
};

export type GetLocksListResponseData = {
  content: PayrollLock[];
};

export type GetDeactivatedLocksListResponseData = {
  content: DeactivatedPayrollLock[];
  metadata: {
    total: number;
  };
};

export type CreateLockRequestData = {
  companyUuid: string;
  body: {
    content: {
      createDigitalSignatures: boolean;
      payrollGroupUuid: string;
      companyUuid: string;
      createdBy: string;
      startDate: string;
      endDate: string;
      selectedColumns: string;
    };
  };
};

export type CreateLockResponseData = "Created";

export interface PayrollLockForEmployeesRequestData {
  companyUuid: string;
  payrollLockUuid: string;
  userProfileUuids: string[];
  updatedBy: string;
}

export interface PayrollLockForEmployeesResponseData {}

export interface UpdateCompanyRequestData {
  body: {
    company: {
      name: string;
      currency: CompanyCurrency;
      legal_name: string;
      company_legal_detail_attributes: {
        tax_payer_id: string;
        industry_type_id: string;
      };
      document_logo_id?: string | null; // string?
    };
  };
}

export type IsPhoneNumberExistRequestData = {
  isOnboarding?: boolean;
  phoneNumber: string;
};

export type IsPhoneNumberExistOnboardingResponseData = {
  phone_number_busy: boolean;
};

export type CreateCompanyRequestData = {
  companyName: string;
  companyType: CompanyType;
  categoryId: number;
  employeesCount: string;
  address: Address;
  refCode: string;
  locale: string;
};

export type CreateCompanyResponseData = SignUpCompany;

export interface UpdateCompanyResponseData {
  company: GlobalStoreCompany;
}

export type GetEmployeesRequestArgs = {
  page?: string;
  perPage?: string;
  requestedBy: string;
  filter_str?: string;
  status?: string;
  type?: string;
  has_schedule?: string;
  hasBiometricFace?: string;
  team_uuid?: string;
  /** search term */
  term?: string;
  /** comma separated */
  exclude_fields?: string;
  /** new hierarchy search */
  permission_section_name?: PermissionSectionName;
};

export type GetEmployeesRequestData = {
  companyUuid: string;
  args?: GetEmployeesRequestArgs;
};

// TODO investigate all types of response with BE dev and fix this type if it's wrong
export type GetEmployeesResponseData = {
  content?: {
    employees?: EmployeeListUserProfile[] | SearchEmployeeUserProfile[];
    /** SearchControlNew */
    departments?: unknown[];
    subsidiaries?: unknown[];
    teams?: unknown[];
  } & (EmployeeListUserProfile[] | SearchEmployeeUserProfile[] | GlobalContextEmployee[]); // or
  user_profiles?: SearchEmployeeUserProfile[]; // not sure at all;
};

export type GetRequestsRequestParams = {
  from?: string;
  to?: string;
  page: string;
  perPage: string;
  side?: "subject" | "approver" | "";
  requested_by?: string;
  status?: string;
  request_subtypes?: string;
  group_type?: "team";
  group_uuid?: string;
};

export type GetRequestsRequestData = {
  companyUUID: string;
  userProfileUuid: string;
  params: GetRequestsRequestParams;
};

export type GetRequestsResponseData = {
  content: Request[];
};

export type GetRequestByUuidRequestData = {
  companyUUID: string;
  requestUuid: string;
};

export type GetRequestByUuidResponseData = {
  content: Request;
};

export type GetRequestTypesRequestData = {
  companyUUID: string;
  requestedBy: string;
};

export type GetRequestTypesResponseData = {
  content: RequestType[];
};

export type RequestBulkChangeStatusRequestData = {
  body: {
    content: {
      [RequestApprovalFlowStatus.approved]: string[]; // uuids
      [RequestApprovalFlowStatus.ignored]: string[]; // uuids
      [RequestApprovalFlowStatus.declined]: { uuid: string; reason: string }[];
      ranges?: { userProfileUuid: string; from: string; to: string }[];
      updatedBy: string;
    };
  };
  companyUUID: string;
  params?: {
    from: string;
    to: string;
    user_profile_uuid: string;
  };
};

export type RequestBulkChangeStatusResponseData = {
  content?: {
    failed?: {
      issues: string[];
    }[];
  };
};

export type GetOmCallsRequestData = {
  companyUuid: string;
  params: {
    page: string;
    perPage: string;
    from: string;
    to: string;
    requestedBy: string;
    userProfileUuid?: string;
    type?: OnCallType;
    status?: OnCallStatus;
    groupType?: string; // "team"
    groupUuid?: string;
  };
};

export type GetOmCallsResponseData = {
  content: OnCall[];
};

export type CreateOnCallRequestData = {
  companyUuid: string;
  body: {
    content: {
      timezone: string;
      startTime: string;
      endTime: string;
      type: OnCallType;
      createdBy: string;
      userProfileUuids: string[];
    };
  };
};

export type CreateOnCallResponseData = {
  content: {
    uuid: string;
  };
};

export type UpdateOnCallRequestData = {
  companyUuid: string;
  status: OnCallStatus;
  body: {
    content: {
      updatedBy: string;
      reviewedBy: string;
      onCallUuids: string[];
    };
  };
};

export type RequestTypeToggleRequestData = {
  companyUUID: string;
  requestType: string;
  action: "activate" | "deactivate";
  body: {
    content: {
      updatedBy: string;
    };
  };
};

export type RequestTypeCreateRequestData = {
  companyUuid: string;
  body: {
    content: {
      createdBy: string;
      name: string;
    };
  };
};

export type RequestTypeDeleteRequestData = {
  companyUuid: string;
  requestTypeUuid: string;
  body: {
    content: {
      deletedBy: string;
    };
  };
};

export type DeleteOnCallRequestData = {
  companyUuid: string;
  onCallUuid: string;
  body: {
    content: {
      updatedBy: string;
      deletedBy: string;
    };
  };
};

export type GetBRGroupRequestData = {
  companyUuid: string;
  brGroupUuid: string;
};

export type GetBRGroupResponseData = {
  content: BusinessRuleGroup;
};

export type UpdateBRGroupRequestData = {
  companyUuid: string;
  brGroupUuid: string;
  body: {
    content: {
      name: string;
      updatedBy: string;
    };
  };
};

export type UpdateBRGroupSettingsRequestData = {
  companyUuid: string;
  brGroupUuid: string;
  businessRulesType: BusinessRuleType;
  body: {
    content: (
      | BusinessRuleNightShift
      | BusinessRulePayedOvertime
      | BusinessRuleTolerance
      | BusinessRuleOnCalls
      | BusinessRuleCrossShifts
      | BusinessRuleHoursDistribution
    ) & {
      updatedBy: string;
    };
  };
};

export type GetUserBRGroupsRequestData = {
  companyUuid: string;
  userProfileUuid: string;
  startDate: moment.MomentInput;
  endDate?: moment.MomentInput;
  requestedBy: string;
};

export type GetUserBRGroupsResponseData = {
  content: BusinessRuleGroupByUser[];
};

export type AssignUserToBRGroupRequestData = {
  companyUuid: string;
  brGroupUuid: string;
  startDate: moment.MomentInput;
  body: {
    content: {
      userProfileUuids: string[];
      createdBy: string;
    };
  };
};

export type RequestSubTypeUpdateRequestData = {
  companyUUID: string;
  requestType: RequestTypeName;
  requestSubtypeUuid: string;
  body: {
    content: Omit<
      RequestSubType,
      "active" | "createdAt" | "deletedAt" | "paid" | "requestSubtypeTemplateUuid" | "updatedAt"
    >;
  };
};

export type RequestSubTypeDeleteRequestData = {
  companyUUID: string;
  requestType: RequestTypeName;
  requestSubtypeUuid: string;
  body: {
    content: {
      updatedBy: string;
    };
  };
};

export type GetSuperpunchReportRequestData = {
  companyUUID: string;
  userProfileUUID: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  showOnlyIssues?: boolean;
};

export type GetSuperpunchReportResponseData = {
  content: DailySummaryReport;
};

export type UploadRequestAttachmentRequest = {
  attachment: File;
  createdBy: string;
  companyUUID: string;
  requestUuid: string | null;
};

export type UploadRequestAttachmentResponse = {
  content: {
    uuid: string;
    companyUuid: string;
    createdBy: string;
    cloudinaryId: string;
    url: string;
    thumbnailUrl: string;
    title: string;
    mimeType: string;
    updatedAt: string;
    createdAt: string;
    requestUuid: string;
    updatedBy: string;
    deletedAt: string | null;
    externalId: string | null;
  };
};

export type GetSuperpunchGroupsRequestData = {
  companyUUID: string;
  from: string;
  to: string;
  requestedBy: string;
  aggregationGroupType: AggregationGroupType;
  page: number;
  perPage: number;

  groupBy?: Groups;
  groupUuid?: string;
};

export type GetSuperpunchGroupsResponseData = {
  content: (PeriodAggregation & { periodLabel: string })[];
  metadata?: { total?: number; skip?: number; limit?: number };
};

export type GetPeriodAggregationRequestData = {
  companyUUID: string;
  from: string;
  to: string;
  frequency: Frequency | DashboardFrequency;
  aggregationType?: AggrerationType;
  aggregationGroupType?: AggregationGroupType;
  requestedBy: string;
  groupBy?: Groups;
  noCache?: boolean;
  /** comma separated */
  props?: string;
  /** comma separated */
  groupUuids?: string;
  page?: number;
  perPage?: number;
  includeZeroes?: boolean;
};

export type GenerateIntegrationCredsResponseData = {
  clientId: string;
  clientSecret: string;
};

export type RevealIntegrationSecretRequestData = {
  companyUuid: string;
  clientId: string;
};

export type RevealIntegrationSecretResponseData = {
  content: {
    revealedClientSecret: string;
  };
};

export type GenerateIntegrationTmpCodeRequestData = {
  companyUuid: string;
};

export type GenerateIntegrationTmpCodeResponseData = {
  content: string;
};

export type GetPeriodAggregationResponseData = { content: PeriodAggregation[]; metadata: { totalRows?: number } };

export type SearchEmployeesAndGroupsRequestData = {
  term: string;
  permission_section_name?: PermissionSectionName;
};

export type SearchEmployeesAndGroupsResponseData = {
  departments: GroupSearchDepartment[];
  employees: GroupSearchUserProfile[];
  subsidiaries: GroupSearchSubsidiary[];
  teams: GroupSearchTeam[];
};

export type GetOperationsRequestData = {
  companyUuid: string;
};

export type GetOperationsResponseData = {
  content: Operation[];
};

export type GetIndustryTypesResponseData = CompanyProfileIndustryType[];

export type GetCompanyResponseData = GlobalStoreCompany;

export type GetDefaultLockPeriodsRequestData = {
  companyUuid: string;
};

export type GetDefaultLockPeriodsResponseData = {
  content: DefaultPayPeriod[];
};

export type MakeDefaultPayrollGroupRequestData = {
  body: { content: { updatedBy: string } };
  companyUuid: string;
  payrollGroupUuid: string;
};

export type RequestDigitalSignaturesRequestData = {
  body: {
    content: {
      createdBy: string;
      selectedColumns?: string;
    };
  };
  companyUuid: string;
  payrollLockUuid: string;
};

export type RequestDigitalSignaturesResponseData = {
  content: {
    operationUuid: string;
  };
};

export type ToggleGroupStatusRequestData = {
  companyUuid: string;
  payrollGroupUuid: string;
  status: TogglePayrolGroupAction;
};

export type GetLockGroupListRequestData = {
  companyUuid: string;
};

export type GetLockGroupListResponseData = {
  content: PayrollGroup[];
};

export type GetLockGroupRequestData = {
  companyUuid: string;
  payrollGroupUuid: string;
};

export type GetLockGroupResponseData = {
  content: PayrollGroup;
};

export type CreateLockGroupRequestData = {
  body: {
    content: PayrollGroupCreateEdit;
  };
  companyUuid: string;
};

export type CreateLockGroupResponseData = {
  content: {
    uuid: string;
  };
};

export type UpdateLockGroupRequestData = {
  body: {
    content: PayrollGroupCreateEdit;
  };
  companyUuid: string;
  payrollGroupUuid: string;
};

export type GetReportRequestData = {
  oldReport?: boolean;
  companyUUID: string;
  UserProfileUUID?: string;
  type: ReportType;
  startDate: moment.MomentInput;
  endDate: moment.MomentInput;
  groupType?: unknown; // TODO
  groupUUID?: string;
  clientReportType?: ClientReportsType;
  clientUUID?: string;
  skipSupervisors?: boolean;
  onlyNonZeroPhases?: boolean;
  onlyNonZeroLines?: boolean;
  forceRecalculation?: boolean;
  onlyMetadata?: boolean;
  showInactiveEmploees?: boolean;
  locationUuid?: string;
  clientUuid?: string;
  punchType?: PunchType;
  projectUuid?: string;
  showServicesSummary?: boolean;
  status?: unknown; // TODO
  newApi?: boolean;
};

export type GetNewReportResponseData =
  | {
      content: Record<string, any>; // TODO
      metadata: Record<string, any>; // TODO
    }
  | ReportDetailed;

export type GetOldReportResponseData = {
  content: Record<string, any>; // TODO
};

export type GetHoursBankBalanceRequestData = {
  companyUUID: string;
  userProfileUuid: string;
  date: moment.Moment;
};

export type GetHoursBankBalanceResponseData = {
  content: {
    phases: { [uuid: string | "negative"]: number };
    total: number;
  };
};

export type GetLockPeriodsForReportsRequestData = {
  companyUuid: string;
  startDate?: string; // YYYY-MM-DD
};

export type GetLockPeriodsForReportsResponseData = {
  content?: PayPeriod[];
};

export type GetEmbeddedUrlRequestData = {
  companyUuid: string;
  type: string;
  language: string;
};

export type GetEmbeddedUrlResponseData = {
  content: {
    url: string;
  };
};

// ===== businessRule.ts =====
export type GetCompanyRulesRequestData = {
  employeeUUID: string;
};

export type GetCompanyRulesResponseData = {
  business_rules: CompanyRule[];
};

// ===== punch.ts =====
export type GetAllPunchesResponseData = {
  punches: Punch[];
  meta: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
  };
};

export type GetPunchDetailsRequestData = {
  punchId: number;
  newHierarchyPermissions?: boolean;
};

export type GetPunchDetailsResponseData = PunchDetailsPunch;

export interface AddPunchRequestData {
  body: AddPunchPunch & {
    shift_compilation: {
      shift_id: string | undefined;
      date: string;
      key: string | undefined;
    } | null;
  };
}

export type GetShiftCompilationsInRangeV2RequestData = {
  employeeUUID: string;
  companyUUID: string;
  from: moment.Moment;
  to: moment.Moment;
  requestedBy: string;
};

export type GetShiftCompilationsInRangeV2ResponseData = {
  content: ShiftCompilationInRange[];
};

export type CheckUserProfileExistsInCompanyRequestData = {
  body: {
    emails?: string[];
    pis?: string;
    tax_payer_id?: string;
  };
};

export type CheckUserProfileExistsInCompanyResponseData = {
  emails?: Record<string, EmployeeExistsCheckResult>[];
  pis?: Record<string, EmployeeExistsCheckResult>;
  tax_payer_id?: Record<string, EmployeeExistsCheckResult>;
};

// ===== location.ts =====
export type GetWifiValidationsResponseData = {
  wifi_points: WifiValidation[];
};

export type GetLocationBeaconsResponse = {
  active: boolean;
  additional_identifications: {
    name: "serial" | "mac" | "uid";
    value: string;
    uuid: string;
    deleted_at: string | null;
  }[];
  created_at: string;
  id: number;
  major: string;
  manufacture: string;
  minor: string;
  model_info: unknown | null; // todo
  title: string;
  uuid: string;
};

export type AddLocationBeaconRequest = {
  body: {
    beacon: {
      title: string;
      uuid: string;
      minor: string;
      major: string;
      manufacture: "sensoro" | "minew";
      additional_identifications_attributes: {
        name: "serial" | "mac" | "uid";
        value: string;
        uuid?: string;
        deleted_at?: string;
      }[];
    };
  };
};

export type AddLocationBeaconResponse = GetLocationBeaconsResponse;

export type GetSuggestedCodeResponseData = {
  /** integer */
  code: string;
};

// ===== schedule.ts =====
export interface GetScheduleTemplatesResponseData {
  content: ScheduleTemplate[];
}

export type GetScheduleImportsResponseData = {
  file_imports: ScheduleFileImport[];
};

export type ImportScheduleRequestData = {
  file: File;
};

export type ImportScheduleResponseData = {
  content: {
    import_uuid: string;
  };
};

export type GetScheduleImportErrorLogRequestData = {
  fileImportUuid: string;
};

// ===== employee.ts =====
export type GetEmployeeTeamRequestData = {
  employeeId: number;
  page: number;
  perPage: number;
};

export type GetEmployeeTeamResponseData = {
  user_profiles?: UserProfile[];
  meta?: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
  };
};

export type RemoveEmployeeFromTeamRequestData = {
  employerId: number;
  employeeId: number;
};

export type GetPunchesByEmployeeRequestData = {
  employeeId: number;
  page: number;
  perPage: number;
};

export type GetPunchesByEmployeeResponseData = {
  punches: Punch[];
  meta: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
  };
};

export type GetEmployeeResponseData = {
  user_profile: UserProfile;
};

//  ===== payroll.ts =====
export type PayrollLayoutPayload = {
  content: {
    name: string;
    payrollProviderUuid: string;
    hoursFormat: string;
    hoursSeparator?: string;
    convertValuesToAbs: boolean;
    companyCode?: string | null;
    companyName?: string;
    clientCode?: string;
    payrollDate?: string;
    competence?: string;
    subsidiaryCode?: string;
    userLoggedInADP?: string;
    payrollCompanyID?: string;
    userRequisitionReturn?: string;
    mescom?: string;
    payrollCode?: string;
    active: boolean;
    payrollEvents: Partial<PayrollEvent>[];
    filenameTemplateConfig: {
      payrollGroup?: FilenameConfiguration;
      deactivatedEmployee?: FilenameConfiguration;
    };
    extraOptions?: {
      companyName?: string;
      contactName?: string;
      contactEmail?: string;
      adpUserName?: string;
      nameOfCustomer?: string;
      sequentialNumberItRecord?: string;
      infotypeOperation?: InfotypeOperation;
      runtype?: RunType;
    };
  };
  createdBy?: string;
};

export type CreatePayrollLayoutRequestData = {
  companyUUID: string;
  body: PayrollLayoutPayload;
};

export type UpdatePayrollLayoutRequestData = {
  payrollUUID: string;
  body: PayrollLayoutPayload;
};

export type DeletePayrollLayoutRequestData = {
  payrollUUID: string;
};

export type DeletePayrollLayoutResponseData = Record<string, never>;

export type GetPayrollEventTypesRequestData = {
  companyUUID: string;
};

export type GetPayrollEventTypesResponseData = {
  content?: PayrollEventType[];
};

export type GetPayrollProvidersResponseData = {
  content?: PayrollProvider[];
};

export type GetPayrollLayoutRequestData = {
  payrollUUID: string;
};

export type GetPayrollLayoutResponseData = {
  content?: PayrollLayoutFull;
};

// ===== position.ts =====
export type GetPositionsResponseData = {
  positions: Position[];
};

export type AddPositionRequestData = {
  body: {
    position: {
      title: string;
    };
  };
};

export type AddPositionResponseData = {
  position: Position;
};

// ===== subsidiary.ts =====
export type GetSubsidiariesResponseData = {
  subsidiaries: Subsidiary[];
};

export type GetSubsidiaryByUuidResponseData = {
  subsidiary: Subsidiary;
};

export type AddSubsidiaryRequestData = {
  body: {
    subsidiary: {
      name: string;
      code: string;
      tax_payer_id: string;
    };
  };
};

export type AddSubsidiaryResponseData = {
  subsidiary: AddSubsidiaryResponse;
};

// ===== department.ts =====
export type GetDepartmentResponseData = {
  departments: Department[];
};

export type AddDepartmentRequestData = {
  body: {
    department: {
      name: string;
      code: string;
    };
  };
};

export type AddDepartmentResponseData = {
  department: AddDepartmentResponse;
};

//  ===== integration.ts =====
export type RepModel = {
  id: number;
  uuid: string;
  model: string;
  active: boolean;
  configFields: {
    name: string;
    type: string;
  }[];
};

export type GetRepModelsData = {
  items: RepModel[];
};

export type RepData = {
  id: number;
  uuid: string;
  companyId: number;
  locationId: number;
  modelId: number;
  externalId: string;
  name: string;
  serialNumber: string;
  active: boolean;
  isManual: boolean;
  searchBy: string;
  host: string;
  port: number;
  startSyncFrom: Date;
  actionType: number;
  extraOptions: Record<string, string>;
};

export type GetRepData = {
  rep: RepData;
};

export type GetCompanyRepsData = {
  items: RepData[];
};

// here we can have multiple field combination depending on validator type
// todo create additional types for each validator
export type AddRepPayload = Record<string, string | number | boolean> & {
  name: string;
  externalId: string;
  locationId: number;
  serialNumber: string;
  host: string;
  actionType: number;
  port: string;
  startSyncFrom: string;
  modelId: number;
  manufacturingNumber: string;
  isManual: boolean;
};

export type DefaultSuccessResult = {
  success: boolean;
};

export type IntegratorData = {
  id: number;
  uuid: string;
  name: string;
  companyId: number;
  companyUuid: string;
  active: boolean;
  hidden: boolean;
  lastSync: Date;
};

export type GetCompanyIntegration = DefaultSuccessResult & {
  integration: IntegratorData & {
    status: string | null;
    token: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
  };
};

// ===== team.ts =====
export type GetTeamsResponseData = {
  teams: Team[];
};

// ===== request.ts =====
export type GetIcalCalendarsRequestData = {
  companyUUID: string;
  userProfileUUID: string;
};

export type GetIcalCalendarsResponseData = {
  content: IcalCalendar[];
};

export type CreateIcalCalendarRequestData = {
  companyUUID: string;
  userProfileUUID: string;
} & Omit<IcalCalendar, "uuid" | "icalUrl">;

export type CreateIcalCalendarResponseData = {
  content: IcalCalendar;
};

export type RegenerateIcalCalendarRequestData = {
  companyUUID: string;
  userProfileUUID: string;
  calendarUUID: string;
};

export type RegenerateIcalCalendarResponseData = {
  content: IcalCalendar;
};

export type UpdateIcalCalendarRequestData = {
  companyUUID: string;
  userProfileUUID: string;
  calendarUUID: string;
} & Omit<IcalCalendar, "uuid" | "icalUrl">;

export type UpdateIcalCalendarResponseData = {
  content: IcalCalendar;
};

export type DeleteIcalCalendarRequestData = {
  companyUUID: string;
  userProfileUUID: string;
  calendarUUID: string;
};

export type DeleteIcalCalendarResponseData = {
  content: {
    uuid: string;
    deleted: boolean;
  };
};

export type IntegrationCredentials = {
  clientId: string;
  clientSecret: string;
};

// ===== permissionRoles.ts =====
export type GetPermissionRolesResponseData = {
  permission_roles: PermissionRole[];
};

export type GetPermissionRolesByUserProfileIdResponseData = EmployeePermissionRole[];

export type UpdatePremissionRoleByUserProfileIdRequestData = {
  userProfileId: number;
  permission_role: {
    id?: number;
    permission_role_uuid: string;
    user_profiles_uuid: string[];
    teams_uuid: string[];
    global: boolean;

    /** remove ? when implemented on BE */
    departments_uuid?: string[];
    /** remove ? when implemented on BE */
    subsidiaries_uuid?: string[];
  };
};

export type UpdatePermissionRoleByUserProfileIdResponseData = {
  permission_roles_user_profiles: EmployeePermissionRole;
};

export type DeletePermissionRoleByUserProfileIdRequestData = {
  userProfileId: number;
  permissionRoleUuid: string;
};

// ===== userProfile.ts =====
export type GetActiveUserProfilesResponseData = ActiveUserProfile[];

export type ChooseProfileRequestData = {
  profileId: number;
};

export type ChooseProfileResponseData = {
  authorization_tokens: {
    token: string;
    userProfileUuid: string;
  }[];
  batch_uuid: string;
  user_profile: UserProfile;
};

export type ChooseProfileReturnData = UserProfile | null;

export type GetScheduleEventsRequestData = {
  userProfileUUID: string;
  dateFrom: string;
  dateTo: string;
  supervisorUUID: string;
};

export type GetScheduleEventsResponseData = {
  content: Shift[];
};

// ===== downloadFiles.ts =====
export type CheckDownloadsUuidsRequestData = {
  uuids: string[];
};

export type CheckDownloadsUuidsResponseData = {
  generated_reports?: ClientReportDownload[];
};

export type FireDowloadReportResponseData = {
  report_uuid: string;
};

// ===== ssoApiUtils.ts =====
export type CreateLoginRequestRequestData = {
  domainName: string;
  callbackPath: string;
  params: string;
};

export type CreateLoginRequestResponseData = {
  content?: {
    loginUrl: string;
  };
};

// ===== management.ts =====
export type SendRDStationActionRequestData = {
  body: {
    email: string;
    event_type: RDStationActionEventType;
    traffic_source?: string;
    traffic_medium?: string;
    traffic_campaing?: string;
  };
};

// ===== auth.ts =====
export type GetPasswordValidationsResponseData = {
  /** regex */
  entire_password: string;
  validations: {
    constrain_key: string;
    regex: string;
  }[];
};

export type GetPasswordValidationsReturnData = {
  entirePassword: GetPasswordValidationsResponseData["entire_password"];
  validations: GetPasswordValidationsResponseData["validations"];
};

export type CreateAccountRequestData = {
  fullName: string;
  password: string;
  email: string;
  phoneNumber: string;
  token: string;
};

export type CreateAccountPayload = {
  user: {
    full_name: string;
    user_account_attributes: {
      password: string;
      email: string;
    };
    devices_attributes: { phone_number: string }[];
  };
  token: string;
};

export type CreateAccountResponseData = SignUpUser;

export type LoginRequestData = {
  email: string;
  password: string;
};

export type LoginResponseData = {
  email: string;
  authentication_token: string;
  selected_user_profile: UserProfile;
  selected_user_profile_id: number;
  selected_user_profile_uuid: string;
  batch_uuid: string;
  authorization_tokens: {
    userProfileUuid: string;
    token: string;
  }[];
};

export type AssociateLocationsToEmployeesRequestData = {
  requestedBy: string;
  userProfilesUuids: string[];
  locationsUuids: string[];
};

export type UserPunchSettingRules = {
  allow_clear_compilation: boolean;
  allow_face_recognition: boolean;
  allow_only_manual_punch_on_mobile: boolean;
  allow_mobile_punch: boolean;
  allow_web_punch: boolean;
  allow_tablet_punch: boolean;
};

export type AssociateDeviceSettingsToEmployeesRequestData = {
  requestedBy: string;
  userProfilesUuids: string[];
  settings: UserPunchSettingRules;
};

export enum BreakStatusOptions {
  all = "",
  active = "active",
  archived = "archived",
}

export enum BreakStatus {
  active = "active",
  archived = "archived",
}

export interface Break {
  uuid: string;
  name: string;
  isWorkingHours: boolean;
  default: boolean;
  status: BreakStatus.active | BreakStatus.archived;
  companyUuid: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  schedules: string[];
}

export interface BreakWithSchedule extends Break {
  schedules: string[];
}

export interface GetCustomBreakListResponseData {
  content: Break[];
}
export interface GetCustomBreakListWithSchedulesResponseData {
  content: BreakWithSchedule[];
}

export interface CreateCustomBreakListResponseData {
  content: Break;
}

export interface CreateCustomBreakBody {
  requestedBy: string;
  name: string;
  isWorkingHours: boolean;
}

export interface CreateCustomBreak {
  companyUuid: string;
  body: {
    content: CreateCustomBreakBody;
  };
}

export interface DeleteCustomBreak {
  companyUuid: string;
  uuid: string;
  body: {
    content: {
      requestedBy: string;
    };
  };
}

export interface GetCustomBreakList {
  companyUuid: string;
  requestedBy: string;
  page: number;
  perPage: number;
  statusList: string[];
}
