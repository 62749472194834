import { Injectable } from "services/di";
import EventEmitter from "events";
import {
  ActivityChangedData,
  InjectedIframeMessageType,
  InjectedTaskData,
  MessageEventData,
  MessageFromTaskSummaryPayload,
  MessageFromTaskSummaryTypes,
} from "types/injected";

export declare interface InjectionCommands {
  on(event: "activity-updated", listener: () => void): this;
  on(event: string, listener: Function): this;
}

@Injectable()
export class InjectionCommands extends EventEmitter {
  distribute = (messageEvent: MessageEvent<MessageEventData>) => {
    const { type } = messageEvent.data;
    if (type === InjectedIframeMessageType.RefetchTaskSummary) {
      this.emit("activity-updated");
    }
  };

  private sendMessageWithPayload(type: InjectedIframeMessageType, payload?: MessageFromTaskSummaryPayload) {
    const data: MessageEventData = {
      type,
      payload,
    };
    this.sendMessage(data);
  }

  private sendMessage(data: MessageEventData) {
    window.parent.postMessage(data, "*");
  }

  /**
   * This event is handled in ProjectsInjected.tsx.
   * The behavior there is "stop if running".
   */
  startActivity(itd: InjectedTaskData) {
    const payload: MessageFromTaskSummaryPayload = {
      type: MessageFromTaskSummaryTypes.StartActivity,
      injectedTaskData: itd,
    };
    this.sendMessageWithPayload(InjectedIframeMessageType.MessageFromTaskSummary, payload);
  }

  stopActivity(itd: InjectedTaskData) {
    const payload: MessageFromTaskSummaryPayload = {
      type: MessageFromTaskSummaryTypes.StopActivity,
      injectedTaskData: itd,
    };
    this.sendMessageWithPayload(InjectedIframeMessageType.MessageFromTaskSummary, payload);
  }

  addActivity(itd?: InjectedTaskData) {
    const payload: MessageFromTaskSummaryPayload = {
      type: MessageFromTaskSummaryTypes.AddActivity,
      injectedTaskData: itd,
    };
    this.sendMessageWithPayload(InjectedIframeMessageType.MessageFromTaskSummary, payload);
  }

  onExpand(itd: InjectedTaskData, height: number) {
    const payload: MessageFromTaskSummaryPayload = {
      type: MessageFromTaskSummaryTypes.ChangeHeight,
      height,
    };
    this.sendMessageWithPayload(InjectedIframeMessageType.MessageFromTaskSummary, payload);
  }

  extensionInitialized() {
    this.sendMessage({ type: InjectedIframeMessageType.ExtensionInitialized });
  }

  updateSummariesOnActivitiesChange(data: ActivityChangedData) {
    this.sendMessage({
      type: InjectedIframeMessageType.UpdateSummariesOnActivitiesChange,
      activityChangedData: data,
    });
  }
}
