import React, { Component } from "react";
import BEM from "utils/BEM";
import "styles/lightbox.scss";
import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/Select";
import Button from "components/controls/StyledButton";
import styled from "styled-components";

const b = BEM.b("lightbox");
const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;
class ChangeGroupPopup extends Component {
  state = {};
  closePopup() {
    this.props.onClose();
  }
  onCancel = () => {
    this.closePopup();
  };
  onYes = () => {
    const { selectedGroup } = this.state;
    if (selectedGroup) {
      this.setState({ isLoading: true });
      this.props.onYes(selectedGroup);
    }
  };

  render() {
    const { fieldTitle, title, text, buttonYesTitle, buttonCancelTitle, groups = [] } = this.props;
    const { selectedGroup, isLoading } = this.state;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        <div className={b("text")}>{text}</div>
        <FieldWrapper fieldName={fieldTitle} width="100%">
          <Select
            modifiers={{ field: true }}
            value={selectedGroup}
            onChange={(val) => {
              this.setState({ selectedGroup: val });
            }}
            options={groups}
          />
        </FieldWrapper>
        <div className={b("buttons")}>
          <Button state="secondary" onClick={this.onCancel} value={buttonCancelTitle} />
          <Spacer />
          <Button
            disabled={!selectedGroup}
            state="primary"
            loading={isLoading}
            onClick={this.onYes}
            onDoubleClick={this.onYes}
            value={buttonYesTitle}
          />
        </div>
      </div>
    );
  }
}
export default ChangeGroupPopup;
