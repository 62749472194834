import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import { useState, useMemo, ChangeEventHandler } from "react";
import c from "classnames";
import { IconLocation } from "../styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 370px;
  width: 100%;
  background: var(--colors-surface-0);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  font-size: 23.6994px;
  line-height: 30px;
`;

const HeaderText = styled.div`
  height: 15px;
  margin: 7px 25px;
  font-size: 12px;
  font-weight: var(--typography-font-weight-bold);
  line-height: 15px;
  color: var(--colors-surface-400);
  text-transform: uppercase;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 8px;
  margin: 24px;
  font-size: 15px;
  font-weight: var(--typography-font-weight-default);
  line-height: 24px;
  letter-spacing: -0.01em;
  background: var(--colors-surface-50);
  border: none;
  border-radius: 8px;
  outline: none;

  &::placeholder {
    color: var(--colors-activitiInfoBlockValue);
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  overflow: overlay;
  font-size: 19px;
  font-weight: var(--typography-font-weight-default);
  line-height: 24px;
`;

const Option = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 20px;
  align-items: center;
  padding: 10px 25px;
  hyphens: auto;
  cursor: pointer;

  & > .icon {
    flex-shrink: 0;
    fill: var(--colors-surface-900-p);
  }

  & > .location {
    & > .name {
      font-size: 16px;
      line-height: 20px;
    }

    & > .address {
      font-size: var(--typography-font-size-default);
      line-height: 18px;
      color: var(--colors-unknown28);
    }
  }

  &:hover {
    background: var(--colors-bgHover);
  }

  &.selected {
    & > .icon {
      fill: var(--colors-primary-500-p);
    }

    & > .location > .name {
      color: var(--colors-primary-500-p);
    }
  }
`;

/**
 * Replace complex characters with their plain latin analogs.
 */
function normalizeString(str: string): string {
  const normalized = str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/ł/g, "l")
    .replace(/ß/g, "ss")
    .replace(/æ/g, "ae");
  return normalized;
}

interface Location {
  id: string;
  name: string;
  address: string;
  code: string;
}

export interface LocationSelectorProps {
  options: Location[];
  selectedOptionId?: string;
  onOptionSelected: (option: Location) => void;
}

export function LocationSelector({ options, selectedOptionId, onOptionSelected }: LocationSelectorProps) {
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const optionsForSearch = useMemo(() => {
    const optsForSearch = options.map((original) => ({
      original,
      name: normalizeString(original.name || ""),
      address: normalizeString(original.address || ""),
      code: normalizeString(original.code || ""),
    }));
    return optsForSearch;
  }, [options]);
  const [foundOptions, setFoundOptions] = useState(optionsForSearch);

  const search: ChangeEventHandler<HTMLInputElement> = (event) => {
    const str = normalizeString(event.target.value);
    const opts = optionsForSearch.filter(
      (opt) => opt.code.includes(str) || opt.name.includes(str) || opt.address.includes(str),
    );
    setFoundOptions(opts);
  };

  return (
    <Wrapper>
      <Header>
        <SearchInput placeholder={t("Search your location")} onChange={search} />
        <HeaderText>{t("Workplaces")}</HeaderText>
      </Header>
      <Options>
        {foundOptions?.map(({ original: opt }) => (
          <Option
            className={c("option", { selected: opt.id === selectedOptionId && selectedOptionId != null })}
            key={opt.id}
            onClick={() => onOptionSelected(opt)}
          >
            <IconLocation className="icon" />
            <div className="location">
              <div className="name">
                {opt.code} - {opt.name}
              </div>
              <div className="address">{opt.address}</div>
            </div>
          </Option>
        ))}
      </Options>
    </Wrapper>
  );
}
