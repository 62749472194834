import { useRef } from "react";
import { useTranslation } from "react-i18next";
import rtl from "styled-components-rtl";

import CheckboxControl from "components/UI/NewCheckbox";
import ButtonAddRemoveSmall from "components/controls/ButtonAddRemoveSmall";
import TextInputControl from "components/controls/TextInputControl";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/FormSelect";
import { CustomFieldType, CustomField, CustomFieldError } from "types/models/projects";

const RequestFieldRow = styled.div`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    .show-on-parent-hover {
      display: flex;
    }
  }
`;
const RequestFieldWrapper = styled.div`
  &:last-of-type {
    margin-bottom: 8px;
  }
`;
const Field = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  position: relative;
`;

const RemoveButtonWrapper = styled.div`
  position: absolute;
  ${rtl`
    right: 10px;
  `}
`;
const FieldTypeWrapper = styled.div`
  margin-inline-start: 8px;
`;

interface IProjectFieldProps {
  customField: CustomField;
  onChange: (v: CustomField) => void;
  onChangeFieldType: (v: CustomFieldType) => void;
  onRemove: () => void;
  width: string;
  error?: CustomFieldError;
}

export default ({ customField, onChange, onChangeFieldType, onRemove, width, error }: IProjectFieldProps) => {
  const { t } = useTranslation("requests-page-tmp");
  const fieldTypes = useRef([
    { value: CustomFieldType.numeric, label: t("Numeric") },
    { value: CustomFieldType.input, label: t("Text") },
  ]).current;
  return (
    <RequestFieldWrapper>
      <RequestFieldRow>
        <Field style={{ width }}>
          <CheckboxControl
            small
            ios
            checked={customField.active}
            onChange={(checked) => {
              onChange({ ...customField, active: checked });
            }}
          />
          <FieldTypeWrapper>
            <FieldWrapper width="140px" fieldTitleMarginBottom={8} fieldTitleMarginTop={0}>
              <TextInputControl
                value={customField.name}
                error={!!error?.name}
                onChange={(value) => onChange({ ...customField, name: value })}
              />
            </FieldWrapper>
          </FieldTypeWrapper>
          <FieldTypeWrapper>
            <FieldWrapper width="140px" fieldTitleMarginBottom={8} fieldTitleMarginTop={0}>
              <Select
                disabled={customField.uuid != null}
                modifiers={{ field: true }}
                value={customField.fieldType}
                onChange={onChangeFieldType}
                options={fieldTypes}
              />
            </FieldWrapper>
          </FieldTypeWrapper>
          {onRemove ? (
            <RemoveButtonWrapper>
              <ButtonAddRemoveSmall remove showOnParentHover onClick={onRemove} />
            </RemoveButtonWrapper>
          ) : null}
        </Field>
      </RequestFieldRow>
    </RequestFieldWrapper>
  );
};
