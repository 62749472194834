import { PaymentIntentResult } from "@stripe/stripe-js";

export enum SubscriptionStatus {
  Free = "free",
  Active = "active",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Trialing = "trialing",
  Unpaid = "unpaid",
  Deleted = "deleted",
}

export enum Feature {
  TA = "ta",
  Project = "projects",
  Bundle = "bundle",
}
export type ProductFeature = Feature.TA | Feature.Project;

export const FeatureLabel = {
  [Feature.TA]: "Time & Attendance",
  [Feature.Project]: "Project Tracking",
  [Feature.Bundle]: "Bundle",
};

export enum Interval {
  Month = "month",
  Year = "year",
}

export enum PriceType {
  Premium = "premium",
  Enterprise = "enterprise",
}

export interface Tier {
  up_to: number | null;
  unit_amount: number | null;
  flat_amount: number | null;
}
export type Currency = string;
export type CurrencyTier = {
  [key: Currency]: {
    tiers: Tier[];
  };
};
export interface Price {
  uuid: string;
  interval: Interval;
  type: PriceType;
  tiers: Tier[];
  currencyTiers: CurrencyTier;
  currency: string;
  product?: Product;
}

export interface Product {
  name: string;
  uuid: string;
  prices: Price[];
  features: ProductFeature[];
  uiFeatures: string[];
  enterpriseFeatures: string[];
  externalId: string;
}

export type CreateSubscriptionPayload = {
  items: {
    priceUuid: string;
    seats: number;
  }[];
  currency: string;
  promotionCode?: string;
  checkInitialSize?: boolean;
};

export enum PaymentType {
  Card = "card",
  Boleto = "boleto",
}
export type CreateSubscriptionResponse = {
  clientSecret: string;
  publicKey: string;
  subscriptionId: string;
  paymentTypes: PaymentType[]; // TODO right type ?
  paymentIntentId: string;
};
export type Coupon = {
  percentOff: number;
  amountOff: number | null;
  valid: boolean;
  name: string;
  appliesTo?: { products: string[] };
  metadata: any;
};
export type PromotionCode = {
  code: string;
  maxRedemptions: number;
  restrictions: {
    first_time_transaction: boolean;
    minimum_amount: number | null;
    minimum_amount_currency: string | null;
  };
  timesRedeemed: number;
  expiresAt: Date;
  coupon: Coupon;
};
export type BillingData = {
  country: string;
  businessName: string;
  taxId: string;
  email: string;
  line1: string;
  city: string;
  state: string;
  postalCode: string;
};

export type BillingDataResponse = BillingData & {
  defaultCurrency: string;
  wasPayments: boolean;
};

export type SubscriptionItem = {
  feature: Feature;
  maxSeats: number;
  price?: Price;
};

export type Subscription = {
  uuid: string;
  externalId?: string;
  status: SubscriptionStatus;
  startedAt: string;
  endedAt: string | null;
  maxSeats: number;
  trialStartedAt: string | null;
  trialEndedAt: string | null;
  items: SubscriptionItem[];
  currentPeriodStartedAt: Date;
  currentPeriodEndedAt: Date;
  autoUpgradeDate: Date | null;
  requiredActionDate: Date | null;
};

export type Customer = {
  uuid: string;
  email: string;
  companyUuid: string;
  rawLocation: string;
  trialStarted: boolean;
  subscriptions: Subscription[];
  enterprise: boolean;
  startedAt?: string;
};

export type UpgradePlanRequest = {
  items: {
    seats: number;
    priceUuid: string;
  }[];
};
export type SetTrialSubscriptionPayload = {
  [Feature.TA]: number | null;
  [Feature.Project]: number | null;
};

export type InvoiceResponse = {
  id: string;
  created: number;
  status: string;
  quantity: number;
  total: number;
  currency: string;
  invoice_pdf: string;
};

export type PendingInvoiceResponse = {
  publicKey: string;
  paymentIntent: Pick<PaymentIntentResult, "paymentIntent">;
  status: string;
  hostedInvoiceUrl?: string;
};

export type SelectObjectivesRequest = {
  objectives: string[];
  others?: string[];
}

export type AssociatedSeatsResponse = {
  [Feature.TA]?: number,
  [Feature.Project]?: number,
};
