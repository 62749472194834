import { MouseEvent } from "react";
import styled from "styled-components";
import * as images from "components/svg-images";

const Add = styled.button`
  outline: none;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary);
  letter-spacing: 0;
  background: var(--colors-default);
  svg {
    margin-inline-end: 15px;
  }
  padding: 0;
`;

interface AddRemoveLinkProps {
  label: string;
  remove?: boolean;
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const AddRemoveLink = ({ label, remove, ...props }: AddRemoveLinkProps) => {
  const image = remove ? images.trashIconBlue : images.addIcon;

  return (
    <Add {...props}>
      {image}
      <span>{label}</span>
    </Add>
  );
};

export default AddRemoveLink;
