import FullPage from "components/Layout/FullPage";
import { EmbeddedDashboardPageWrapper } from "./styled";
import EmbeddedDashboardTable from "./EmbeddedDashboardTable";
import BetaRow from "./BetaRow";

const EmbeddedDashboardPage = (props: {dashboardType: string}) => {
  const { dashboardType } = props;

  return (
    <EmbeddedDashboardPageWrapper>
      <FullPage>
        <BetaRow />
        <EmbeddedDashboardTable type={dashboardType} />
      </FullPage>
    </EmbeddedDashboardPageWrapper>
  );
};

export default EmbeddedDashboardPage;
