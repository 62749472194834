import { stylesheet } from "astroturf";
import cx from "classnames";
import { IconDropright, IconFieldError } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background: var(--colors-unknown26);
  }

  .Texts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .Title {
      font-size: 13px;
      font-weight: var(--typography-font-weight-medium);
      line-height: 100%;
      color: var(--colors-surface-800);
    }

    .Value {
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-900-p);
    }

    .Placeholder {
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-600);
    }
  }


  .IconDropdown {
    margin-inline-end: 6px;
  }

  .IconError {
    margin: 1px;
    fill: var(--colors-danger-500);
  }

  &.error {
    .Texts {
      .Title {
        color: var(--colors-danger-500);
      }
    }
  }

  &.disabled {
    cursor: default;
  }

  &.compact {
    gap: 10px;

    .Texts {
      flex-direction: row;
      justify-content: space-between;

      .Title {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-900-p);
      }

      .Value {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-700);
      }
    }
  }

  &.noTitle {
    height: 77px;

    .Texts {
      .Title {
        display: none;
      }

      .Placeholder {
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-surface-800);
      }
    }
  }

  &.loading {
    @use 'styles/loading';

    .Texts {
      .Value {
        @extend .wireframe;

        width: 80px;
      }
    }
  }
}
`;

export interface ActivityTaskDropdownProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  placeholder?: string;
  value?: string;
  error?: boolean;
  /** Show value on the same line as title */
  compact?: boolean;
  loading?: boolean;
  /** Hide title and show placeholder in bright color */
  noTitle?: boolean;
}

export function ActivityFieldDropdown(props: ActivityTaskDropdownProps) {
  const { title, value, disabled, className, error, placeholder, onClick, compact, noTitle, loading, ...restProps } =
    props;

  return (
    <div
      className={cx(
        styles.Wrapper,
        {
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.compact]: compact,
          [styles.noTitle]: noTitle,
          [styles.loading]: loading,
        },
        className,
      )}
      onClick={disabled ? undefined : onClick}
      {...restProps}
    >
      <div className={styles.Texts}>
        <div className={styles.Title}>{title}</div>
        {value == null && <div className={styles.Placeholder}>{placeholder}</div>}
        {value != null && !loading && <div className={styles.Value}>{value}</div>}
        {loading && <div className={styles.Value}> </div>}
      </div>
      {!disabled && !error && <IconDropright className={styles.IconDropdown} />}
      {error && <IconFieldError className={styles.IconError} />}
    </div>
  );
}
