import { useCallback, useState, useRef } from "react";
import { useAsyncCallback } from "./useAsyncEffect";

class Deferred<T = any> {
  promise: Promise<T>;
  reject(err: Error): void;
  resolve(res: T): void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

/**
 * Usage:
 * ```
 * const [askingExit, askExit, respondExit] = useDialog<boolean>()
 *
 * return (
 *   <div>
 *     <div>Main content</div>
 *     <button onClick={() => {
 *       const confirmed = await askExit()
 *       if (confirmed) onExit()
 *     }}>Exit</button>
 *   {askingExit && (
 *     <div>
 *       <div>Are you sure you want to exit?</div>
 *       <button onClick={() => respondExit(true)}>Yes</button>
 *       <button onClick={() => respondExit(false)}>No</button>
 *     </div>
 *   )}
 *   </div>
 * )
 * ```
 */
export function useDialog<T = any>() {
  const [show, setShow] = useState(false);
  const defRef = useRef<Deferred | null>(null);
  const setDialogResult = useCallback((res: T) => defRef.current?.resolve(res), []);
  const [setShowDialog] = useAsyncCallback(async (shw?: boolean) => {
    if (shw === false) {
      setShow(false);
      defRef.current = null;
      return null;
    }
    setShow(true);
    if (defRef.current != null) {
      console.warn("useDialog dialog already open, overwriting previous deferred");
    }
    // TODO: think if overwriting the previous deferred if it exists is a good idea, reusing it doesn't work in some cases
    defRef.current = new Deferred();
    const res = await defRef.current.promise;
    defRef.current = null;
    setShow(false);
    return res;
  }, []);
  return [show, setShowDialog, setDialogResult] as [
    showing: boolean,
    setShow: (show?: boolean) => Promise<T | null>,
    setResult: (res: T) => void,
  ];
}
