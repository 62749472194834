import moment from "moment";
import "./ActivityCalendar.scss";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { removeActivity, updateAtivity } from "components/Projects/projectsApiUtils";
import { GlobalStoreCompany } from "types/models/company";
import { hasPermisionAccess, hrsMinsToMins, minsToHrsMins, PermissionSectionName } from "utils/common";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  Eventcalendar,
  formatDate,
  MbscEventcalendarView,
  MbscEventUpdateEvent,
  momentTimezone,
  setOptions,
  Toast,
  locale,
} from "@mobiscroll/react";
import { useProjectsEventTracking, useWidgetProjectsService } from "utils/useServices";
import { useAsyncCallback } from "utils/useAsyncEffect";
import RequestDetailsNew from "components/Requests/RequestDetailsNew";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { Request, RequestApprovalFlowStatus } from "types/models/request";
import { RequestBulkChangeStatusRequestData } from "utils/api/types";
import { listUserProfilesWIthFilters, requestsBulkChangeStatus, updateOnCallStatus } from "utils/api/company";
import { MbscCalendarColor } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types.public";
import {
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventCreateEvent,
  MbscEventDragEvent,
} from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar.types.public";
import { MbscCalendarDayData } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day";
import { PunchType } from "types/models/punches";
import * as momentTz from "moment-timezone";
import OnCallDetailsPopup from "components/OnCalls/OnCallDetailsPopup";
import { OnCall, OnCallStatus } from "types/models/onCalls";
import { Activity } from "components/Projects/projectsApiTypes";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import { GlobalContext } from "context/GlobalContextProvider";
import { TimesheetContext } from "components/Timesheets/timesheets.context";
import { AvailableLocales, getLocale } from "utils/translationHelpers";
import { useActivitiesStore } from "../../activities.store";
import { EventRenderer, EventType } from "./components/EventRenderer";
import { ReactComponent as LockIcon } from "./icons/lock.svg";
import { ActivityModal } from "../../TimeTracker";

momentTimezone.moment = moment;

type ActivityCalendarProps = {
  forceFetchActivities: () => void;
  forceFetchRequests: () => void;
  forceReloadOnCalls: () => void;
  showWeekends: boolean;
  selectedDate: Date;
  firstDayOfWeek: number;
  selectedEmployee: { uuid: string };
};

export const ActivityCalendar = (props: ActivityCalendarProps) => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const context = useContext(GlobalContext);
  const timesheetContext = useContext(TimesheetContext);
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [showActivityPopup, setShowActivityPopup] = useState(false);
  const [showRequestPopup, setShowRequestPopup] = useState(false);
  const [activeEventId, setActiveEventId] = useState<string>();
  const [isToastOpen, setToastOpen] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>();
  const [events, setEvents] = useState<any[]>([]);
  const activityStore = useActivitiesStore();

  const getLocaleForCalendar = () => {
    let lang = getLocale();

    if (lang && lang !== AvailableLocales.pt) {
      // eslint-disable-next-line prefer-destructuring
      lang = lang.split("-")[0];
    }
    return lang;
  };

  const widgetProjectsService = useWidgetProjectsService();
  const projectsEventTracking = useProjectsEventTracking();

  const getStartTimeFromEvent = (event: MbscEventDragEvent | MbscEventCreatedEvent) =>
    hrsMinsToMins(moment(event.event.start).format("HH:mm"));
  const getEndTimeFromEvent = (event: MbscEventDragEvent | MbscEventCreatedEvent) =>
    hrsMinsToMins(moment(event.event.end).format("HH:mm"));
  const isDateInLockedTimesheetRanges = (date: Date | string | undefined) => {
    const inRange = activityStore.timesheets.find(
      (timesheet) =>
        moment(date).isSameOrBefore(timesheet.endDate, "day") && moment(date).isSameOrAfter(timesheet.startDate, "day"),
    );
    return !!inRange;
  };

  const isLocked = (date: Date | string | undefined) =>
    (activityStore.lastLockDate && moment(date).isSameOrBefore(moment(activityStore.lastLockDate), "day")) ||
    isDateInLockedTimesheetRanges(date);

  /**
   *  This event handler executed when user finished dragging event block, if it is newly created event we not allow drag
   */
  const onEventDragEnd = async (event: MbscEventDragEvent & { entity: any }) => {
    if (!event.event?.entity?.uuid) return false;
    const res = await updateAtivity({
      body: {
        content: {
          taskUuid: event.event.entity.taskUuid,
          projectUuid: event.event.entity.projectUuid,
          startTime: getStartTimeFromEvent(event),
          endTime: getEndTimeFromEvent(event),
          date: moment(event.event.start).format("YYYY-MM-DD"),
          customFields: event.event.entity.customFields,
          updatedBy: window.global_store.profile.uuid,
        },
      },
      updatedBy: window.global_store.profile.uuid,
      activityUuid: event.event.entity.uuid,
      companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
    });
    event.event = {
      ...event.event,
      entity: {
        ...event.event.entity,
        ...res,
      },
    };

    const otherEvents = events.filter((e) => e.entity.uuid !== event.event.entity.uuid);
    setEvents([...otherEvents, event.event]);
    return true;
  };

  const onEventUpdate = (event: MbscEventUpdateEvent) => {
    if (moment(event.event.start).format("YYYY-MM-DD") !== moment(event.event.end).format("YYYY-MM-DD")) {
      setToastText(t("Cannot cross the date"));
      setToastOpen(true);
      return false;
    }
    if (event.event.allDay) {
      setToastText(t("Cannot set activity for whole day"));
      setToastOpen(true);
      return false;
    }
    const forbidInFuture = !timesheetContext?.settings?.allowFutureActivities;
    if (
      forbidInFuture &&
      (moment(event.event.start).isAfter(moment(), "day") || moment(event.event.end).isAfter(moment(), "day"))
    ) {
      setToastText(t("Cannot set activity in future"));
      setToastOpen(true);
      return false;
    }
    if (isLocked(event.event.start as string)) {
      setToastText(t("This time slot locked by payroll lock"));
      setToastOpen(true);
      return false;
    }

    return true;
  };

  // This preventing creating new event if there date is under payroll lock or event in future
  const onEventCreate = (event: MbscEventCreateEvent) => {
    if (onEventUpdate(event)) {
      const endTime = getEndTimeFromEvent(event);
      if (endTime === 0) {
        event.event.end = moment(event.event.end).subtract(1, "minutes").toDate();
      }
      return true;
    }
    return false;
  };

  const onEventCreated = (event: MbscEventCreatedEvent) => {
    const startTime = getStartTimeFromEvent(event);
    const endTime = getEndTimeFromEvent(event);

    const newAct = {
      uuid: undefined,
      date: moment(event.event.start).format("YYYY-MM-DD"),
      startTime,
      endTime,
      duration: endTime - startTime,
      projectUuid: null,
      taskUuid: null,
      locationUuid: null,
      customFields: [],
      attachments: [],
      status: "pending",
    };
    activityStore.selectActivity(newAct as unknown as Activity);
    activityStore.setPendingActivity(newAct as unknown as Activity);
    const anchorElement = document.querySelector(`[data-id="${event.event.id}"]`) || event.target!;
    setAnchor(anchorElement);
    setShowActivityPopup(true);
  };

  const formatOnCallForModal = async (oc: OnCall) => {
    const company = await context.getCompany();
    const { content } = await listUserProfilesWIthFilters(company.uuid, {
      requestedBy: window.global_store.profile.uuid,
      skip: 0,
      limit: 10,
      filterModel: {
        uuid: { filterType: "set", values: [oc.userProfileUuid, oc.updatedBy] },
      },
      fields: ["id", "uuid", "fullName", "avatarId", "position.title", "matricula", "taxPayerId"],
      externalFields: [],
    });
    const startTime = moment(momentTz.tz(oc.startTime, oc.timezone));
    const endTime = moment(momentTz.tz(oc.endTime, oc.timezone));
    const employee: FilteredEmployeeProfile | undefined = content.find((e) => e.uuid === oc.userProfileUuid);
    const approver: FilteredEmployeeProfile | undefined = content.find((e) => e.uuid === oc.updatedBy);

    const getOnCallDuration = (startTime: moment.Moment, endTime: moment.Moment): string => {
      const duration = moment.duration(endTime.diff(startTime));

      const hrs = Math.floor(duration.asHours());
      const mins = duration.minutes();

      return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : mins}`;
    };

    return {
      ...oc,
      employee: {
        avatarId: employee?.avatarId || "",
        fullName: employee?.fullName || "",
        position: employee?.position?.title || "",
      },
      userProfileUuid: oc.userProfileUuid,
      matricula: employee?.matricula || "",
      cpf: employee?.taxPayerId || "",
      type: oc.type,
      startDate: startTime.format("DD/MM/YYYY"),
      startTime: startTime.format("HH:mm"),
      endDate: endTime.format("DD/MM/YYYY"),
      endTime: endTime.format("HH:mm"),
      duration: getOnCallDuration(startTime, endTime),
      approver: approver?.fullName || "",
      status: oc.status,
      createdBy: oc.createdBy,
      uuid: oc.uuid,
      locked: oc.locked,
    };
  };

  const onEventClick = async (event: MbscEventClickEvent) => {
    setActiveEventId(event.event.id as string);
    const anchorElement = document.querySelector(`[data-id="${event.event.id}"]`) || event.domEvent.target;
    setAnchor(anchorElement);
    switch (event.event.type) {
      case EventType.Activity:
        if (isLocked(event.date)) return;
        if (event.event.entity?.uuid) {
          activityStore.selectActivity(event.event.entity);
        }

        setShowActivityPopup(true);
        break;
      case EventType.Off:
        if (event.event.entity?.uuid) {
          activityStore.selectRequest(event.event.entity);
        }
        setShowRequestPopup(true);
        break;

      case EventType.OnCall:
        activityStore.selectOnCall(await formatOnCallForModal(event.event.entity));
        break;
      case EventType.GoogleEvent:
        if (
          event.event.allDay ||
          isLocked(event.date) ||
          moment(event.event.start).isAfter(moment()) ||
          moment(event.event.end).isAfter(moment())
        ) {
          setActiveEventId(undefined);
        } else {
          const eventToCreate = { ...event } as unknown as MbscEventCreatedEvent;
          const endTime = getEndTimeFromEvent(eventToCreate);
          if (endTime === 0) {
            eventToCreate.event.end = moment(event.event.end).subtract(1, "minutes").toDate();
          }
          onEventCreated(eventToCreate);
        }
        break;
      default:
    }
  };

  const processPunches = useCallback((): Array<MbscCalendarColor & { punchState: PunchType }> => {
    const punchEvents = [];
    const initialArray = [
      ...activityStore.punches.sort((a, b) => {
        if (moment(a.device_datetime).isAfter(b.device_datetime)) return 1;
        return -1;
      }),
    ];
    while (initialArray.length > 0) {
      const event: MbscCalendarColor & { punchState: PunchType } = {
        start: moment(initialArray[0].device_datetime),
        end: undefined,
        punchState: initialArray[0].punch_type,
        cssClass: `PunchEvent ${initialArray[0].punch_type}`,
      };
      initialArray.splice(0, 1);
      const eventEnd = initialArray.findIndex((e) => {
        switch (event.punchState) {
          case PunchType.entry:
            return e.punch_type === PunchType.exit;
          case PunchType.breakStart:
            return e.punch_type === PunchType.breakEnd;
          default: {
            return false;
          }
        }
      });

      if (eventEnd > -1) {
        event.end = moment(initialArray[eventEnd].device_datetime);
        initialArray.splice(eventEnd, 1);
        event.cssClass = `${event.cssClass} containEnd`;
      } else {
        event.end = event.start;
      }
      punchEvents.push(event);
    }
    return punchEvents;
  }, [activityStore.punches]);

  const processSchedules = useCallback(
    () =>
      activityStore.schedules.reduce((acc, schedule: any) => {
        const { startDate } = schedule;
        const scheduleEvents = schedule.scheduleDays.reduce((accDay: MbscCalendarColor[], s) => {
          const initialArray = [...s.events];
          while (initialArray.length > 0) {
            const event: MbscCalendarColor & { punchState: PunchType } = {
              date: startDate,
              start: moment().startOf("day").add({ minutes: initialArray[0].time }).format("HH:mm"),
              end: undefined,
              punchState: initialArray[0].type,
              cssClass: `PunchEvent scheduled ${initialArray[0].type}`,
              recurring: {
                repeat: "weekly",
                weekDays: s.dayName.slice(0, 2).toUpperCase(),
              },
            };
            initialArray.splice(0, 1);
            const eventEnd = initialArray.findIndex((e) => {
              switch (event.punchState) {
                case PunchType.entry:
                  return e.type === PunchType.exit;
                case PunchType.breakStart:
                  return e.type === PunchType.breakEnd;
                default: {
                  return false;
                }
              }
            });

            if (eventEnd > -1) {
              event.end = moment().startOf("day").add({ minutes: initialArray[eventEnd].time }).format("HH:mm");
              initialArray.splice(eventEnd, 1);
              event.cssClass = `${event.cssClass} containEnd`;
            } else {
              event.end = event.start;
            }
            accDay.push(event);
          }
          return accDay;
        }, []);
        return acc.concat(...scheduleEvents);
      }, []),
    [activityStore.schedules],
  );

  useEffect(() => {
    setEvents([
      ...activityStore.activities.map((ev) => {
        const start = moment(ev.date).startOf("day").add(ev.startTime, "minutes");
        return {
          title: ev.task.name,
          start: start.toDate(),
          end: start.clone().add(ev.duration, "minutes").toDate(),
          description: ev.project.name,
          entity: ev,
          type: EventType.Activity,
          editable: !isLocked(ev.startedAt!),
        };
      }),
      ...activityStore.requests.map((req) => ({
        title: req.requestSubtypeState.name,
        description: req.requestType,
        entity: req,
        start: moment(req.startTime).toDate(),
        end: moment(req.endTime).toDate(),
        allDay: req.allDay,
        type: EventType.Off,
        editable: false,
      })),
      ...activityStore.onCallEvents.map((onCall) => ({
        title: t(`on_call_type-${onCall.type}`),
        entity: onCall,
        start: moment(onCall.startTime),
        end: moment(onCall.endTime),
        allDay: onCall.allDay,
        type: EventType.OnCall,
        editable: false,
      })),
      ...activityStore.googleEvents.map((event) => {
        const ev = {
          allDay: event.allDay,
          title: event.title,
          entity: event,
          start: moment(
            event.googleEvent.start?.dateTime?.replace(/([-|+]\d{1,2}:\d{1,2}|Z)$/, "") || event.start,
          ).format(),
          end: event.allDay
            ? moment(event.end).subtract(1, "day")
            : moment(event.googleEvent.end?.dateTime?.replace(/([-|+]\d{1,2}:\d{1,2}|Z)$/, "") || event.end).format(),
          editable: false,
          type: EventType.GoogleEvent,
        };
        return ev;
      }),
    ]);
  }, [activityStore.activities, activityStore.requests, activityStore.onCallEvents, activityStore.googleEvents]);

  const [saveActivity] = useAsyncCallback(
    async (act) => {
      if (act.uuid == null) {
        const payload = {
          status: "pending",
          date: act.date,
          startTime: act.startTime,
          endTime: act.endTime,
          duration: act.duration,
          locationUuid: act?.locationUuid || act?.location?.uuid || null,
          taskUuid: act.task.uuid,
          projectUuid: act.project?.uuid,
          customFields: act.customFields || [],
          attachments: act.attachments?.map((a) => ({ ...a, activityUuid: undefined })) || [],
          userProfileUuid: props.selectedEmployee.uuid,
        };
        projectsEventTracking.trackAddActivity(payload);
        await widgetProjectsService.createActivity(payload);
      } else {
        const payload = {
          attachments: act.attachments || [],
          customFields: act.customFields || [],
          uuid: act.uuid,
          date: act.date,
          taskUuid: act.taskUuid,
          locationUuid: act.locationUuid || act.location?.uuid || null,
          projectUuid: act.project?.uuid,
          startTime: act.startTime,
          endTime: act.endTime,
          duration: act.duration,
          // stopReason,
        };
        projectsEventTracking.trackUpdateActivity(payload);
        await widgetProjectsService.updateActivity(payload);
      }
      setShowActivityPopup(false);

      props.forceFetchActivities();
    },
    [widgetProjectsService, projectsEventTracking],
  );

  const [deleteActivity] = useAsyncCallback(async ({ uuid }: { uuid: string }) => {
    try {
      await removeActivity({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: (window.global_store.company as GlobalStoreCompany).uuid,
        activityUuid: uuid,
      });
      setShowActivityPopup(false);
      props.forceFetchActivities();
    } catch (error) {
      setToastText(t("Deleting activity failed"));
      setToastOpen(true);
    }
  }, []);

  const view = useMemo<MbscEventcalendarView>(() => {
    const { firstDayOfWeek } = props;
    const daysCount = props.showWeekends ? 6 : 4;
    const lastDayOfWeek = (firstDayOfWeek + daysCount) % 7;

    return {
      schedule: {
        allDay: false, // todo currently we hide it entirely by Liran request. !!events.filter((e) => e.allDay).length,
        currentTimeIndicator: true,
        type: "week",
        startDay: firstDayOfWeek,
        endDay: lastDayOfWeek,
      },
    };
  }, [props.showWeekends, props.firstDayOfWeek, events]);

  setOptions({
    theme: "ios",
    themeVariant: "light",
  });

  const closeToast = useCallback(() => {
    setToastOpen(false);
  }, []);

  const renderDay = (args: MbscCalendarDayData) => {
    const { date } = args;
    const today = moment(date).isSame(moment(), "day");

    const timeInMinutesUsed = args.events!.reduce((acc, event) => {
      if (event.type === EventType.Activity) {
        const timeInMinutes = moment(event.end).diff(moment(event.start), "minutes");
        acc += timeInMinutes;
      }
      return acc;
    }, 0);

    const time = timeInMinutesUsed
      ? `${Math.round((Math.abs(timeInMinutesUsed) / 60 + Number.EPSILON) * 100) / 100}h`
      : "";
    const locked = isLocked(date);

    return (
      <div className={`HeaderDayCol ${today ? "today" : ""}`}>
        {formatDate("DDD", date, locale[getLocaleForCalendar()])}
        <span>{formatDate("D", date)}</span>
        <div className="TimeLabel">{time}</div>
        {locked ? <LockIcon className="icon" /> : null}
      </div>
    );
  };

  const onRequestStatusChange = async ({
    request,
    status,
    declineReason,
  }: {
    request: Request;
    status: RequestApprovalFlowStatus;
    declineReason?: string;
  }) => {
    const body: RequestBulkChangeStatusRequestData["body"] = {
      content: {
        [RequestApprovalFlowStatus.approved]: [],
        [RequestApprovalFlowStatus.declined]: [],
        [RequestApprovalFlowStatus.ignored]: [],
        ranges: [],
        updatedBy: window.global_store.profile.uuid,
      },
    };
    if (status === RequestApprovalFlowStatus.declined) {
      body.content[RequestApprovalFlowStatus.declined].push({ uuid: request.uuid, reason: declineReason ?? "" });
    } else {
      body.content[status as RequestApprovalFlowStatus.approved | RequestApprovalFlowStatus.ignored].push(request.uuid);
    }

    try {
      await requestsBulkChangeStatus({
        companyUUID: (window.global_store.company as GlobalStoreCompany).uuid,
        body,
      });
    } catch (e) {
      setToastText(t("Failed to change request state"));
      setToastOpen(true);
    }

    setShowRequestPopup(false);
    activityStore.selectRequest(undefined);
    props.forceFetchRequests();
  };

  const updateOnCallStatusReq = async (onCallUuids: string[], status: OnCallStatus) => {
    const { profile, company } = window.global_store;
    try {
      await updateOnCallStatus({
        companyUuid: (company as GlobalStoreCompany).uuid,
        status,
        body: {
          content: {
            updatedBy: profile.uuid,
            reviewedBy: profile.uuid,
            onCallUuids,
          },
        },
      });

      props.forceReloadOnCalls();
    } catch (e) {
      setToastText((e as Error)?.message || String(e));
      setToastOpen(true);
    }
  };

  const canUpdateStatus = (onCall: { locked: boolean; status: OnCallStatus; userProfileUuid: string } | null) => {
    if (!onCall) {
      return false;
    }

    const userProfile = window.global_store.profile;

    const isLockedStatus = onCall.locked;
    const isPending = onCall.status === OnCallStatus.pending;
    const isOwnRequest = onCall.userProfileUuid === userProfile.uuid;

    return !isOwnRequest && !isLockedStatus && isPending && hasPermisionAccess(PermissionSectionName.onCallManagement);
  };

  return (
    <>
      <div className="ActivityCalendar" style={{ height: "calc(100vh - 230px)", paddingBottom: 0 }}>
        <Eventcalendar
          theme="ios"
          timeFormat="H:mm"
          themeVariant="light"
          clickToCreate="single"
          dragToCreate
          dragToMove
          dragToResize
          data={events}
          view={view}
          key={props.selectedDate}
          locale={locale[getLocaleForCalendar()]}
          // validators
          onEventUpdate={onEventUpdate}
          onEventCreate={onEventCreate}
          // handlers
          onEventClick={onEventClick}
          onEventDragEnd={onEventDragEnd}
          onEventCreated={onEventCreated}
          timezonePlugin={momentTimezone}
          defaultSelectedDate={props.selectedDate}
          refDate={props.selectedDate}
          dataTimezone="local"
          renderHeader={() => <></>}
          renderDay={renderDay}
          renderScheduleEvent={(data) => {
            const timeInMinutes = moment(data.original?.end).diff(moment(data.original?.start), "minutes");
            const time = minsToHrsMins(timeInMinutes, true);
            const locked = isLocked(data.original?.start!);

            return (
              <EventRenderer
                {...data}
                time={time}
                type={data.original?.type}
                activeId={
                  locked && [EventType.Activity, EventType.GoogleEvent].includes(data.original?.type)
                    ? null
                    : activeEventId
                }
                description={data.original?.description}
                locked={locked}
              />
            );
          }}
          colors={[...processPunches(), ...processSchedules()]}
        />
        <Toast
          theme="ios"
          themeVariant="light"
          message={toastText}
          isOpen={isToastOpen}
          onClose={closeToast}
          display="center"
        />
      </div>
      <ActivityModal
        anchor={anchor}
        isOpen={showActivityPopup}
        activityData={activityStore.selectedActivity}
        onClose={() => {
          setEvents([...events]);
          setActiveEventId(undefined);
          setShowActivityPopup(false);
        }}
        deleteActivity={deleteActivity}
        saveActivity={saveActivity}
      />

      <SidePopupOverlay
        header={activityStore.selectedRequest ? t(activityStore.selectedRequest.requestType) : null}
        isOpen={showRequestPopup && !!activityStore.selectedRequest}
        contentOverflow
        onClose={() => {
          activityStore.selectRequest(undefined);
          setActiveEventId(undefined);
          setShowRequestPopup(false);
        }}
      >
        {showRequestPopup && activityStore.selectedRequest ? (
          <RequestDetailsNew onStatusChanged={onRequestStatusChange} request={activityStore.selectedRequest} />
        ) : null}
      </SidePopupOverlay>

      <OnCallDetailsPopup
        isOpen={!!activityStore.selectedOnCall}
        onCall={activityStore.selectedOnCall as any}
        onClose={() => activityStore.selectOnCall(null)}
        updateStatus={updateOnCallStatusReq}
        onDelete={() => props.forceReloadOnCalls}
        canUpdateStatus={canUpdateStatus(activityStore.selectedOnCall)}
      />
    </>
  );
};
