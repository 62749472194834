import { TranslationNamespaces } from "types/translationNamespaces";
import { Break } from "utils/api/types";
import i18next from "i18next";

export const DEFAULT_BREAK_KEY = "DEFAULT";

export const getBreaksNamesMap = (customBreaks: Break[]) => {
  const breakNamesMap = customBreaks.reduce((acc: Record<string, string>, brk) => {
    acc[brk.uuid] = brk.name;

    if (brk.default) {
      acc[DEFAULT_BREAK_KEY] = i18next.t(`${TranslationNamespaces.common}|${brk.name}`);
      acc[brk.uuid] = acc[DEFAULT_BREAK_KEY];
    }

    return acc;
  }, {});

  return breakNamesMap;
};

export const getBreakName = (
  breakTypeUuid: string | null,
  punchType: string,
  breaksNamesMap: Record<string, string>,
) => {
  const { t } = i18next;
  const breakType = t(`${TranslationNamespaces.punchesPage}|${punchType}`);
  const breakTypeSimple = t(`${TranslationNamespaces.punchesPage}|${punchType}-simple`);
  const breakName = breakTypeUuid
    ? breaksNamesMap[breakTypeUuid] || breaksNamesMap[DEFAULT_BREAK_KEY]
    : breaksNamesMap[DEFAULT_BREAK_KEY];

  return t(`${TranslationNamespaces.common}|custom-break-2`, {
    breakType,
    breakName,
    breakTypeSimple,
  });
};
