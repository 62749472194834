import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import Select from "components/UI/Select";
import moment from "moment";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RequestComplianceRules } from "types/models/request";
import { getWarning } from "./requestUtils";
import RequestVacationDaysInfoRow from "./RequestVacationDaysInfoRow";

const Block = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Divider = styled.div`
  margin: 0 6px;
  padding: 20px 0;
  border-inline-end: 1px solid #dde5ee;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Spacer = styled.div`
  width: 12px;
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const DaysCount = styled.div`
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: var(--colors-unknown22);
`;

const Description = styled.div`
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  text-align: center;
  color: var(--colors-unknown23);
`;

interface RequestAddVacationDaysWithInfoProps extends WithTranslation, RequestComplianceRules {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onChange: (data: { startDate: moment.Moment; endDate: moment.Moment }) => void;
  usedDays: number;
  availableDays: number;
  errors: (Record<string, string | boolean> & { cfErrors?: Record<string, string> | undefined }) | null;
  lockedDate: moment.Moment | null;
  validateAvailableDays: boolean;
  withInfo: boolean;
  isEditMode: boolean;
}

interface RequestAddVacationDaysWithInfoState {
  selectedPeriod: number;
}

class RequestAddVacationDaysWithInfo extends Component<
  RequestAddVacationDaysWithInfoProps,
  RequestAddVacationDaysWithInfoState
> {
  constructor(props: RequestAddVacationDaysWithInfoProps) {
    super(props);

    let selectedPeriod = 0;

    if (props.predefinedPeriods.active && props.predefinedPeriods.values) {
      if (props.isEditMode) {
        const daysDiff = props.endDate.diff(props.startDate, "days") + 1;
        if (props.predefinedPeriods.values.includes(daysDiff)) {
          selectedPeriod = daysDiff;
        }
      } else {
        const firstItem = 0;
        selectedPeriod = props.predefinedPeriods.values[firstItem];
      }
    }

    this.state = { selectedPeriod };
  }

  getCountStr = (count: number) => {
    const { t } = this.props;

    return count === 1 ? t("common|day") : t("common|days");
  };

  getCount = (count: number) => (count < 10 && count > 0 ? `0${count}` : count);

  onStartDateChange = (startDate: moment.Moment) => {
    const { selectedPeriod } = this.state;
    const { onChange, endDate } = this.props;
    let ed = endDate.clone();

    if (endDate && endDate.isBefore(startDate, "day")) {
      ed = startDate.clone();
    }

    if (selectedPeriod) {
      ed = startDate.clone().add(selectedPeriod - 1, "day");
    }

    onChange({ startDate, endDate: ed });
  };

  onEndDateChange = (endDate: moment.Moment) => {
    const { onChange, startDate } = this.props;
    let sd = startDate;

    if (startDate && startDate.isAfter(endDate, "day")) {
      sd = endDate.clone();
    }

    onChange({ endDate, startDate: sd });
  };

  render() {
    const {
      usedDays,
      t,
      startDate,
      endDate,
      availableDays,
      errors,
      lockedDate,
      predefinedPeriods,
      daysBeforeRequestDate,
      validateAvailableDays,
      withInfo,
    } = this.props;
    const { selectedPeriod } = this.state;
    const periods = [0].concat(predefinedPeriods.values).map((val) => ({ value: val, label: val || t("Custom") }));
    const warning = getWarning({
      t,
      daysBeforeRequestDate,
      predefinedPeriods,
      availableDays,
      startDate,
      endDate,
      selectedPeriod,
      validateAvailableDays,
    });

    return (
      <div>
        {withInfo && (
          <Wrapper>
            <Block>
              <DaysCount>
                {this.getCount(availableDays)} {this.getCountStr(availableDays)}
              </DaysCount>
              <Description>{t("Available")}</Description>
            </Block>
            <Divider />
            <Block>
              <DaysCount>
                {this.getCount(usedDays)} {this.getCountStr(usedDays)}
              </DaysCount>
              <Description>{t("Used")}</Description>
            </Block>
          </Wrapper>
        )}
        {!!warning && (
          <>
            {!withInfo ? <br /> : null}
            <RequestVacationDaysInfoRow>{warning}</RequestVacationDaysInfoRow>
          </>
        )}

        {predefinedPeriods.active && (
          <FieldWrapper fieldName={t("Period")} width="100%">
            <Select<number, number>
              modifiers={{ field: true }}
              value={selectedPeriod}
              onChange={(val) => {
                let ed = endDate;

                if (val) {
                  ed = startDate.clone().add(val - 1, "day");
                  this.onEndDateChange(ed);
                }

                this.setState({ selectedPeriod: val });
              }}
              options={periods}
            />
          </FieldWrapper>
        )}

        <DatesWrapper>
          <FieldWrapper fieldName={t("common|From")} width="100%" fieldTitleMarginBottom={0}>
            <SingleDatePickerControl
              numberOfMonths={1}
              error={!!errors?.startDate}
              value={startDate}
              onChange={this.onStartDateChange}
              isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
            />
          </FieldWrapper>
          <Spacer />
          <FieldWrapper fieldName={t("common|To")} width="100%" fieldTitleMarginBottom={0}>
            <SingleDatePickerControl
              disabled={predefinedPeriods.active && selectedPeriod !== 0}
              numberOfMonths={1}
              error={!!errors?.endDate}
              value={endDate}
              onChange={this.onEndDateChange}
              isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
            />
          </FieldWrapper>
        </DatesWrapper>
        {errors?.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
        {errors?.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(RequestAddVacationDaysWithInfo);
