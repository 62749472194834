import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import TimeControl from "components/controls/TimeControl";
import moment, { Moment } from "moment";
import styled from "styled-components";
import { OvertimeRequestValidityType, RequestOvertimeSubTypes, RequestSubType } from "types/models/request";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { useEffect } from "react";
import { getScheduleEvents } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import { RequestsAddEditPopupErrors } from "../RequestAddEditPopup";

const RadioButton = styled.div`
  display: inline-flex;
  justify-content: start;
  align-items: center;
  margin-inline-end: 16px;
  gap: 8px;
  font-size: 15px;
  color: var(--colors-surface-900-p);

  input {
    margin: 0;
  }
`;

type PlannedDayOvertimeFiledsProps = {
  startDate: Moment;
  errors: RequestsAddEditPopupErrors;
  lockedDate: moment.Moment | null;
  validityType: OvertimeRequestValidityType | undefined | null;
  validity: string | undefined | null;
  onDateChange?: (date: Moment) => void;
  onValidityTypeChange: (validityType: OvertimeRequestValidityType) => void;
  onValidityChange: (validity: string | undefined | null) => void;
  userProfileUuid: string | null;
  userProfileUuids: string[] | null;
  requestModel: RequestSubType;
  updateSelectedSubtype: (subtypeName: RequestOvertimeSubTypes) => void;
};

const PlannedDayOvertimeFileds = ({
  startDate,
  errors,
  lockedDate,
  validityType,
  validity,
  onDateChange,
  onValidityTypeChange,
  onValidityChange,
  userProfileUuid,
  userProfileUuids,
  updateSelectedSubtype,
  requestModel,
}: PlannedDayOvertimeFiledsProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  const [getShiftCompilation] = useAsyncCallback(async () => {
    if (userProfileUuids?.length || !userProfileUuid) {
      updateSelectedSubtype(RequestOvertimeSubTypes.overtime);
    } else if (userProfileUuid && startDate) {
      let res = null;
      try {
        res = await getScheduleEvents({
          userProfileUUID: userProfileUuid,
          supervisorUUID: window.global_store.profile.uuid, // requestedBy
          dateFrom: startDate.format("YYYY-MM-DD"),
          dateTo: startDate.format("YYYY-MM-DD"),
        });
      } catch (error) {
        console.log("Shift has not been generated"); // TODO: verify if we throw 500 error when shift is not generated. If yes implement retry
        Sentry.sendError(error);
      } finally {
        if (!res?.content?.length) {
          // no shift compilation or non working day
          updateSelectedSubtype(RequestOvertimeSubTypes.overtimeEnableDay);
        } else {
          const hasWorkingDay = res.content.find((r) => moment(r.date).isSame(startDate, "day"));

          updateSelectedSubtype(
            !hasWorkingDay || hasWorkingDay.nonWorkingDayShift
              ? RequestOvertimeSubTypes.overtimeEnableDay
              : RequestOvertimeSubTypes.overtime,
          );
        }
      }
    }
  }, [startDate, userProfileUuid, userProfileUuids]);

  useEffect(() => void getShiftCompilation(), [getShiftCompilation, startDate, userProfileUuid]);

  return (
    <>
      <FieldWrapper fieldName={t("Date")} width="100%" fieldTitleMarginBottom={0}>
        <SingleDatePickerControl
          numberOfMonths={1}
          error={!!errors?.startDate}
          value={startDate}
          disabled={!onDateChange}
          onChange={onDateChange}
          isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
        />
      </FieldWrapper>
      {requestModel.nameId === RequestOvertimeSubTypes.overtime && (
        <>
          <FieldWrapper fieldName={t("What would you like to request?")} fieldTitleMarginBottom={10}>
            <RadioButton>
              <input
                name="validity-type"
                type="radio"
                checked={validityType === OvertimeRequestValidityType.entryEarly}
                onChange={(): void => onValidityTypeChange(OvertimeRequestValidityType.entryEarly)}
              />
              <span>{t("Enter earlier")}</span>
            </RadioButton>
            <RadioButton>
              <input
                name="validity-type"
                type="radio"
                checked={validityType === OvertimeRequestValidityType.exitLate}
                onChange={(): void => onValidityTypeChange(OvertimeRequestValidityType.exitLate)}
              />
              <span>{t("Exit later")}</span>
            </RadioButton>
          </FieldWrapper>
          {errors?.validityType && <ErrorLabel>{errors.validityType}</ErrorLabel>}

          <FieldWrapper fieldName={t("How many additional hours do you need?")} width="100%">
            <TimeControl
              style={{
                borderColor: "var(--colors-surface-400)",
                paddingInlineStart: 12,
                color: "var(--colors-surface-900-p)",
              }}
              value={validity || undefined}
              error={!!errors?.validity}
              onTimeChange={({ value }) => onValidityChange(value)}
              placeholder="00:00"
            />
          </FieldWrapper>
          {errors?.validity && <ErrorLabel>{errors.validity}</ErrorLabel>}
        </>
      )}
    </>
  );
};

export default PlannedDayOvertimeFileds;
