import { APIResponse, sendRequest } from "./common";
import {
  AddSubsidiaryRequestData,
  AddSubsidiaryResponseData,
  GetSubsidiariesResponseData,
  GetSubsidiaryByUuidResponseData,
} from "./types";

type TODORequestData = any;
type TODOResponseData = Promise<unknown>;

export function getSubsidiaries(): APIResponse<GetSubsidiariesResponseData> {
  return sendRequest({}, "GET", "/subsidiaries");
}

/** have no refferences in the code anymore */
export function getSubsidiaryByUuid(subsidiaryUuid: string): APIResponse<GetSubsidiaryByUuidResponseData> {
  return sendRequest({}, "GET", `/subsidiaries/${subsidiaryUuid}`);
}

export function addSubsidiary(data: AddSubsidiaryRequestData): APIResponse<AddSubsidiaryResponseData> {
  return sendRequest(data.body, "POST", "/subsidiaries");
}

export function updateSubsidiary(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PUT", `/subsidiaries/${data.id}`);
}

export function deleteSubsidiary(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "DELETE", `/subsidiaries/${data.id}`);
}

export function addEmployeesToSubsidiary(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/subsidiaries/${data.uuid}/add_employees`);
}
