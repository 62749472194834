import { Component, PropsWithChildren } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  MainWrapper,
  ChildrenWrapper,
  DropdownWrapper,
  OptionWrapper,
  Label,
  Dropdown,
  DropdownOption,
  IconArrowDown,
} from "./styled";
import * as images from "../../svg-images";

type PaginationOption = {
  value: number;
  label: string;
};

interface PaginationPerPageSelectorProps extends PropsWithChildren, WithTranslation {
  onChange?: (value: number) => void;
  options: PaginationOption[];
  /** new pagination */
  newPagination?: boolean;
  /** new pagination */
  pageLimit?: number;
}

interface PaginationPerPageSelectorState {
  isOpen: boolean;
}

class PaginationPerPageSelector extends Component<PaginationPerPageSelectorProps, PaginationPerPageSelectorState> {
  timeout: NodeJS.Timeout | null = null;

  readonly state: Readonly<PaginationPerPageSelectorState> = {
    isOpen: false,
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.setState({ isOpen });
    }, 300);
  };

  selectValue = (value: number) => {
    const { onChange } = this.props;

    if (onChange && typeof onChange === "function") {
      onChange(value);
    }

    this.setState({ isOpen: false });
  };

  getNewSelector = () => {
    const { t, pageLimit, options } = this.props;

    return (
      <MainWrapper>
        <Label
          active={this.state.isOpen}
          onMouseEnter={() => this.toggleOpen(true)}
          onMouseLeave={() => this.toggleOpen(false)}
        >
          {t("{{pageLimit}} per page", { pageLimit })}

          <IconArrowDown>{images.smallArrowDown()}</IconArrowDown>
        </Label>

        {this.state.isOpen && (
          <Dropdown>
            <div onMouseEnter={() => this.toggleOpen(true)} onMouseLeave={() => this.toggleOpen(false)}>
              {options.map((option) => (
                <DropdownOption key={option.value} onClick={() => this.selectValue(option.value)}>
                  {t("{{pageLimit}} per page", { pageLimit: option.value })}
                  {option.value === pageLimit && images.checkIcon}
                </DropdownOption>
              ))}
            </div>
          </Dropdown>
        )}
      </MainWrapper>
    );
  };

  render() {
    const { options, children, newPagination } = this.props;

    if (newPagination) {
      return this.getNewSelector();
    }

    return (
      <MainWrapper className="ui-select__wrapper">
        <ChildrenWrapper onMouseEnter={() => this.toggleOpen(true)} onMouseLeave={() => this.toggleOpen(false)}>
          {children}
        </ChildrenWrapper>

        {this.state.isOpen && (
          <DropdownWrapper>
            <div onMouseEnter={() => this.toggleOpen(true)} onMouseLeave={() => this.toggleOpen(false)}>
              {options.map((option) => (
                <OptionWrapper
                  className="ui-select__option"
                  key={option.value}
                  onClick={() => this.selectValue(option.value)}
                >
                  {option.label}
                </OptionWrapper>
              ))}
            </div>
          </DropdownWrapper>
        )}
      </MainWrapper>
    );
  }
}

export default withTranslation()(PaginationPerPageSelector);
