import styled, { css } from "styled-components";

const style = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid #0000;
  border-radius: var(--shapes-border-radius-default);
  padding: 5px 7px 8px 7px;

  font-size: 15px;
  line-height: 19px;
`;

const Inactive = styled.div`
  ${style}
`;

const Label = styled.label`
  ${style}

  cursor: pointer;

  &:hover:not(:focus-within) {
    border: 1px solid var(--colors-surface-150);
  }

  &:focus-within {
    border: 1px solid var(--colors-primary3);
  }
`;

const LabelText = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
  color: var(--colors-surface-400);
`;

export type FormFieldProps = React.HTMLProps<HTMLLabelElement> & {
  label: string;
  inactive?: boolean;
};

export default function FormField({ label, inactive, children, ...props }: FormFieldProps) {
  const Cmp = inactive ? Inactive : Label;
  return (
    <Cmp {...props}>
      <LabelText>{label}</LabelText>
      {children}
    </Cmp>
  );
}
