import { ChangeEvent, Component, ContextType, createRef, Fragment, RefObject } from "react";
import { AxiosRequestConfig, CancelToken } from "axios";
import moment from "moment";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { GlobalContext } from "context/GlobalContextProvider";
import { hasEmployeesAccess, getLastLockDate, getDateWithTZ, minsToHrsMins, hrsMinsToMins } from "utils/common";
import {
  uploadRequestAttachmentNew,
  deleteAttachmentNew,
  getHoursBankBalance,
  getUserBRGroups,
} from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { GetHoursBankBalanceResponseData } from "utils/api/types";
import Select from "components/UI/Select";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import Textarea from "components/controls/TextareaControl";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import CheckboxControl from "components/UI/NewCheckbox";
import DateTimeRow from "components/UI/DateTimeRow";
import { ErrorLabel } from "components/UI/TextLabels";
import Button from "components/controls/StyledButton";
import { PayedOvertimePhase } from "types/models/businessRulesGroup";
import { SearchObject } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  CreateRequestPayload,
  OvertimeRequestValidityType,
  RequestAttachment,
  RequestCustomField,
  RequestOvertimeSubTypes,
  RequestSettings,
  RequestSubType,
  RequestSubtypeSpecificFieldTypes,
  RequestType,
  RequestTypeName,
  RequestTypeSpecificField,
} from "types/models/request";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TwoFieldsWrapper } from "components/Reports/styled";
import { MassActionLocations } from "utils/ga";
import DateRangeSelector from "components/controls/DatePicker/DateRangeSelector";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { FilteredEmployeesExternalFields } from "types/models/userProfile";
import { getSchedulesForUserProfile } from "utils/api/schedule";
import CONFIG from "config";
import RequestAddVacationDaysWithInfo from "./RequestAddVacationDaysWithInfo";
import { fetchVacationBalance } from "./requestUtils";
import Attachments from "./Attachments";
import "styles/request-details.scss";
import PlannedDayOvertimeFileds from "./RequestAddEditScreenFields/PlannedDayOvertimeFileds";
import HoursBankCovertFields from "./RequestAddEditScreenFields/HoursBankCovertFields";
import SubtypeSpecificFieldsHBAdjustment from "./RequestAddEditScreenFields/SubtypeSpecificFieldsHBAdjustment";
import SubtypeSpecificFieldsOvetimeEnableDay from "./RequestAddEditScreenFields/SubtypeSpecificFieldsOvetimeEnableDay";
import ScheduleAssignmentFields from "./RequestAddEditScreenFields/ScheduleAssignmentFields";

const DAY_LENGTH = 1440; // 24 hours in minutes

const Wrapper = styled.div`
  padding-bottom: 50px;
  .checkbox-nightshift {
    margin-top: 16px;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 35px;
  width: 160px;
`;

interface RequestsAddEditPopupProps extends WithTranslation {
  isEditMode: boolean;
  /** RequestSettings if Edit mode, RequestType if create mode */
  requestSettings: RequestSettings | RequestType;
  prefillProfile: SearchObject | null;
  stateRef: RefObject<{ loading: boolean }>;
  onClose: () => void;
  onYes: (request: CreateRequestPayload, attachments: RequestAttachment[], requestSubtype: RequestSubType) => void;
}

export type RequestsAddEditPopupErrors =
  | (Record<string, string | boolean> & { cfErrors?: Record<string, string>; tsfErrors?: Record<string, string> })
  | null;

interface RequestsAddEditPopupState {
  loading: boolean;
  allDay: boolean;
  isNightShift: boolean;
  errors: RequestsAddEditPopupErrors;
  lockedDate: moment.Moment | null;
  category: string;
  requestModel: RequestSubType;
  employeeFullName: string | null;
  subTypesOptions: Record<"label" | "name" | "value", string>[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  startTime: string | null;
  endTime: string | null;
  comment: string | null;
  userProfileUuid: string | null;
  userProfileUuids: string[] | null;
  attachments: RequestAttachment[];
  usedDays: number;
  availableDays: number;
  validityType: OvertimeRequestValidityType | undefined | null;
  validity: string | undefined | null;
  hoursBankMins: GetHoursBankBalanceResponseData["content"] | null;
  phases: PayedOvertimePhase[];
  selectedPhase: PayedOvertimePhase | null;
  employeeScheduleUuid: string | null;
  employeeCurrentScheduleUuid: string | null;
}

class RequestsAddEditPopup extends Component<RequestsAddEditPopupProps, RequestsAddEditPopupState> {
  static defaultProps = {
    isEditMode: false,
  };

  dropzone = createRef();
  attachments: RequestAttachment[];

  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: RequestsAddEditPopupProps) {
    super(props);
    const { requestSettings, t, prefillProfile, isEditMode, stateRef } = props;

    if (stateRef) {
      stateRef.current = { loading: false };
    }

    const subTypesOptions = requestSettings.subtypes
      .map((subType) => ({
        label: subType.translationKey ? t(subType.translationKey) : subType.name,
        name: subType.name,
        value: subType.uuid,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    const requestModel = requestSettings.subtypes.find((st) => subTypesOptions[0].value === st.uuid) as RequestSubType;
    const isVacation = requestModel.requestType === RequestTypeName.vacation;
    const isHbConvert = requestModel.requestType === RequestTypeName.hbConvert;
    const isOvertime = requestModel.requestType === RequestTypeName.overtime;
    const isScheduleAssignment = requestModel.requestType === RequestTypeName.scheduleAssignment;
    const allDay = isEditMode
      ? (requestSettings as RequestSettings).allDay
      : isVacation || isHbConvert || isOvertime || isScheduleAssignment;

    const startDate = isEditMode
      ? getDateWithTZ((requestSettings as RequestSettings).startTime, (requestSettings as RequestSettings).timezone)
      : moment().add(isHbConvert ? -1 : 0, "day");
    let endDate = isEditMode
      ? getDateWithTZ((requestSettings as RequestSettings).endTime, (requestSettings as RequestSettings).timezone)
      : moment().add(isHbConvert ? -1 : 0, "day");

    if (isVacation && !isEditMode) {
      if (
        requestModel.complianceRules &&
        requestModel.complianceRules.predefinedPeriods.active &&
        requestModel.complianceRules.predefinedPeriods.values &&
        requestModel.complianceRules.predefinedPeriods.values[0]
      ) {
        endDate = moment().add(requestModel.complianceRules.predefinedPeriods.values[0] - 1, "day");
      }
    }

    const state: RequestsAddEditPopupState = {
      loading: false,
      allDay,
      isNightShift: requestSettings.isNightShift,
      errors: null,
      lockedDate: null,
      category: subTypesOptions[0].value,
      requestModel,
      employeeFullName: isEditMode ? (requestSettings as RequestSettings).employee.fullName : null,
      subTypesOptions,
      startDate,
      endDate,
      startTime: isEditMode && !allDay ? startDate.format("HH:mm") : null,
      endTime: isEditMode && !allDay ? endDate.format("HH:mm") : null,
      comment: isEditMode ? (requestSettings as RequestSettings).comment?.value ?? null : null,
      userProfileUuid: isEditMode ? (requestSettings as RequestSettings).userProfileUuid : null,
      userProfileUuids: null,
      attachments: isEditMode ? (requestSettings as RequestSettings).attachments.filter((a) => !a.deletedAt) : [],
      usedDays: 0,
      availableDays: 0,
      validityType: isEditMode ? (requestSettings as RequestSettings).validityType : null,
      validity: isEditMode ? minsToHrsMins((requestSettings as RequestSettings).validity || 0) : null,
      hoursBankMins: null,
      phases: [],
      selectedPhase: null,
      employeeScheduleUuid: null,
      employeeCurrentScheduleUuid: null,
    };

    this.attachments = state.attachments;

    if (!hasEmployeesAccess()) {
      state.userProfileUuid = window.global_store.profile.uuid;
      state.employeeFullName = window.global_store.profile.full_name;
      state.lockedDate = moment(window.global_store.profile.last_lock_date, "YYYY-MM-DD");

      if (isVacation && requestModel.complianceRules && requestModel.complianceRules.totalDaysLimit.active) {
        if (window.global_store.profile.uuid) {
          void this.requestVacationBalance(window.global_store.profile.uuid, startDate, endDate);
        }
      }
    } else if (prefillProfile) {
      state.userProfileUuid = prefillProfile.uuid || null;
      state.employeeFullName = prefillProfile.full_name || prefillProfile.label;
    }

    this.state = state;
  }

  async componentDidMount() {
    const { startDate, endDate, userProfileUuid, requestModel } = this.state;
    const promises = [];

    if (userProfileUuid) {
      const { content: userProfiles } = await listUserProfilesWIthFilters(window.global_store.company.uuid, {
        ...baseByUuidPayload(window.global_store.profile.uuid, [userProfileUuid]),
        fields: ["uuid", "lastLockDate"],
        externalFields: [FilteredEmployeesExternalFields.Schedules],
      });
      const userProfile = userProfiles.find((e) => e.uuid === userProfileUuid) || null;

      if (userProfile?.lastLockDate) {
        this.setState({
          lockedDate: moment(userProfile.lastLockDate).utc(),
        });

        const lockDate = userProfile ? moment(userProfile.lastLockDate, "YYYY-MM-DD") : null;
        let sDate = startDate;
        let eDate = endDate;

        if (lockDate && startDate.isSameOrBefore(lockDate, "day")) {
          sDate = lockDate.clone().add(1, "day");
        }
        if (lockDate && endDate.isSameOrBefore(lockDate, "day")) {
          eDate = lockDate.clone().add(1, "day");
        }

        promises.push(this.requestVacationBalance(userProfileUuid as string, sDate, eDate));
      }

      if (userProfile?.schedules?.length) {
        this.setState({ employeeScheduleUuid: userProfile.schedules[0].uuid });
      }

      if (requestModel.requestType === RequestTypeName.hbConvert) {
        promises.push(this.getHBrequestData());
      }

      if (requestModel.requestType === RequestTypeName.scheduleAssignment) {
        promises.push(this.getSelectedEmployeesScheduleForDate(userProfileUuid, startDate || moment()));
      }

      await Promise.all(promises);

      if (requestModel.requestType === RequestTypeName.hbConvert) {
        this.resetHBAdjustmentField(
          this.state.phases.length ? this.state.phases[0]?.uuid : "negative",
          this.state.phases.length ? this.state.phases[0] || null : null,
        );
      }
    }
  }

  getSelectedEmployeesScheduleForDate = async (userProfileUuid: string, date: moment.Moment) => {
    const { content } = await getSchedulesForUserProfile(window.global_store.company.uuid, userProfileUuid, {
      from: date.format(CONFIG.apiDateFormat),
      to: date.format(CONFIG.apiDateFormat),
    });

    this.setState({ employeeCurrentScheduleUuid: content?.[0]?.ScheduleUuid || null });
  };

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = () => {
    this.closePopup();
  };

  validateCustomFields = () => {
    const { requestModel } = this.state;
    const { t, isEditMode } = this.props;
    const cfErrors: Record<string, string> = {};

    // all custom fields are disabled during editing. no need to check them
    if (!isEditMode) {
      requestModel.customFields.forEach((f) => {
        const { required, active, value, name } = f;

        if (required && active && !value) {
          cfErrors[name] = t("Field can't be empty");
        }
      });
    }
    return cfErrors;
  };

  getFormErrors = () => {
    const { t, isEditMode } = this.props;
    const customFieldsErrors = this.validateCustomFields();
    const {
      comment,
      startTime,
      endTime,
      allDay,
      startDate,
      endDate,
      userProfileUuid,
      requestModel,
      userProfileUuids,
      validity,
      validityType,
      hoursBankMins,
      employeeCurrentScheduleUuid,
    } = this.state;
    const { attachments } = this;
    let errors: RequestsAddEditPopupState["errors"] = {};
    const tsfErrors: Record<string, string> = {};

    if (!startDate) {
      errors.startDate = t("Start date can't be blank");
    }
    if (!endDate) {
      errors.endDate = t("End date can't be blank");
    }

    if (
      !allDay &&
      ![RequestTypeName.overtime, RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(
        requestModel.requestType,
      )
    ) {
      if (!startTime) {
        errors.startTime = t("Start time can't be empty");
      }
      if (!endTime) {
        errors.endTime = t("End time can't be empty");
      }
      if (startDate && endDate && startTime && endTime) {
        const sDate = moment(`${startDate.format("YYYY-MM-DD ") + startTime} +0000`);
        const eDate = moment(`${endDate.format("YYYY-MM-DD ") + endTime} +0000`);

        if (sDate.isSameOrAfter(eDate)) {
          errors.startDate = t("End Date should be later then Start Date");
          errors.endDate = true;
          errors.startTime = true;
          errors.endTime = true;
        } else if (eDate.diff(sDate, "minutes") > DAY_LENGTH) {
          errors.startDate = true;
          errors.endDate = true;
          errors.startTime = true;
          errors.endTime = t("The time is outside the 24-hour limit.");
        }
      }
    } else if (startDate && endDate && startDate.isAfter(endDate)) {
      errors.startDate = t("End Date should be later then Start Date");
      errors.endDate = true;
    }
    if (Object.keys(customFieldsErrors).length > 0) {
      errors.cfErrors = customFieldsErrors;
    }

    // comment field is disabled during editing. no need to check it
    if (
      !isEditMode &&
      requestModel.comment &&
      requestModel.comment.active &&
      requestModel.comment.required &&
      !comment
    ) {
      errors.comment = t("Comment can't be empty");
    }

    if (requestModel?.attachment?.active && requestModel.attachment.required && !attachments?.length) {
      errors.attachment = t("Attachment is required");
    }

    if (attachments?.length && userProfileUuids?.length) {
      errors.attachment = t("Attachments are not supported for multiple employees request");
    }

    if (attachments.some((a) => a.loading)) {
      errors.attachment = t("Attachments are loading");
    }

    if (!userProfileUuid && !userProfileUuids) {
      errors.employee = t("Please select an employee");
    }

    if (requestModel.nameId === RequestOvertimeSubTypes.overtime) {
      if (!validity) {
        errors.validity = t("Additional hours can't be empty");
      }
      if (!validityType) {
        errors.validityType = t("Please select overtime requet type");
      }
    }

    requestModel.subtypeSpecificFields.forEach((f) => {
      const isEmpty =
        f.required &&
        ((f.name === RequestSubtypeSpecificFieldTypes.adjustment && !f?.value?.phaseValue) ||
          (f.name === RequestSubtypeSpecificFieldTypes.schedule && !f?.value) ||
          (f.name === RequestSubtypeSpecificFieldTypes.dayRange && (!f?.value?.from || !f?.value?.to)));

      if (isEmpty) {
        tsfErrors[f.name] = t("Can't be empty");
        return;
      }

      if (f.name === RequestSubtypeSpecificFieldTypes.schedule && f?.value && f.value === employeeCurrentScheduleUuid) {
        tsfErrors[f.name] = t("Can't be the same as the current schedule");
      }

      if (f.name === RequestSubtypeSpecificFieldTypes.adjustment) {
        // ex: when phase is not selected - f.value === {phaseId: "negative", phaseValue: 754}
        // ex: when phase is selected - f.value === {phaseId: "4e6d84a9-189d-47c5-a3cb-6a20b8a73b3f", phaseValue: 754}
        // ex: hoursBankMins === {phases: {negative: 0, "90361f2b-754d-4c0a-a58a-7891381767ab": 1078, "4e6d84a9-189d-47c5-a3cb-6a20b8a73b3f": 0}, total: 1078}

        let hbValue = hoursBankMins?.total || 0;

        if (f.value.phaseId !== "negative") {
          hbValue = hoursBankMins?.phases[f.value.phaseId] || 0;
        }

        if (f.value.phaseValue > hbValue) {
          tsfErrors.adjustment = t("Can't be greater than hoursbank");
        }
      }
    });

    if (Object.keys(tsfErrors).length > 0) {
      errors.tsfErrors = tsfErrors;
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  onYes = () => {
    const {
      comment,
      allDay,
      category,
      userProfileUuid,
      userProfileUuids,
      requestModel,
      startTime,
      endTime,
      validity,
      validityType,
      isNightShift,
    } = this.state;
    const { attachments } = this;
    const { requestSettings, isEditMode, onYes } = this.props;
    const errors = this.getFormErrors();
    if (!errors) {
      this.setState({ loading: true });
      const startDate = `${this.state.startDate.format("YYYY-MM-DD")}T${startTime || "00:00"}`;
      const endDate = `${this.state.endDate.format("YYYY-MM-DD")}T${endTime || "00:00"}`;

      const request: CreateRequestPayload = {
        requestType: requestSettings.name,
        requestSubtypeUuid: category,
        startTime: startDate,
        endTime: endDate,
        allDay,
        isNightShift,
        customFields: requestModel.customFields
          .filter((f) => f.active)
          .map((f) => {
            const { required, active, iconKey, fieldType, ...customField } = f;

            return { ...customField, value: customField.value || "" };
          }),
        createdBy: window.global_store.profile.uuid,
        userProfileUuids: userProfileUuids || [userProfileUuid as string],
        subtypeSpecificFields: requestModel.subtypeSpecificFields
          .filter((f) => f.active && [null, undefined].indexOf(f?.value) === -1)
          .map((f) => {
            const { required, active, configurable, fieldType, ...subtypeSpecificFields } = f;
            const value = subtypeSpecificFields.value || null;

            return { ...subtypeSpecificFields, value };
          }),
      };

      if (isEditMode) {
        request.uuid = requestSettings.uuid;
      }

      if (attachments?.length) {
        request.attachments = attachments.map(({ uuid, cloudinaryId }) => ({ uuid, cloudinaryId }));
      }

      if (requestModel.comment.active) {
        request.comment = { value: comment || "" };
      }

      if (requestModel.nameId === RequestOvertimeSubTypes.overtime) {
        request.validity = hrsMinsToMins(validity as string);
        request.validityType = validityType as OvertimeRequestValidityType;
      }

      if (requestModel.nameId === RequestOvertimeSubTypes.overtimeEnableDay) {
        // TODO - add implementation
      }

      if (onYes) {
        onYes(request, attachments, requestModel);
      }
    } else {
      this.setState({ errors });
    }
  };

  requestVacationBalance = async (userProfileUuid: string, from: moment.Moment, to: moment.Moment) => {
    const resp = await fetchVacationBalance(userProfileUuid, from, to);

    this.setState(resp);
  };

  requestHoursBankBalance = async () => {
    const { userProfileUuid, startDate } = this.state;

    if (userProfileUuid) {
      const company = await this.context.getCompany();

      if (company) {
        try {
          const { content } = await getHoursBankBalance({
            companyUUID: company.uuid,
            userProfileUuid,
            date: startDate,
          });

          this.setState({ hoursBankMins: content });
        } catch (error) {
          sentryUtils.sendError(error);

          this.setState({ hoursBankMins: null, errors: { hoursBankMins: `HB: ${error?.message || error}` } });
        }
      }
    }
  };

  getPhases = async () => {
    const { userProfileUuid, startDate } = this.state;

    const company = await this.context.getCompany();
    if (!userProfileUuid || !company) {
      return;
    }

    try {
      const { content } = await getUserBRGroups({
        companyUuid: company.uuid,
        userProfileUuid,
        startDate,
        requestedBy: window.global_store.profile.uuid,
      });

      const [brg] = content;
      const { phases } = brg.rules.payedOvertime;

      this.setState({ phases });
    } catch (error) {
      sentryUtils.sendError(error);

      this.setState({ phases: [], errors: { phases: `Phases: ${error?.message || error}` } });
    }
  };

  getHBrequestData = async () => {
    this.setState({ loading: true });

    try {
      await Promise.all([this.getPhases(), this.requestHoursBankBalance()]);
    } finally {
      this.setState({ loading: false });
    }
  };

  getCustomFields = (): RequestCustomField[] => {
    const { isEditMode, requestSettings } = this.props;
    const { requestModel } = this.state;

    if (isEditMode) {
      return ((requestSettings as RequestSettings).customFields || []).map((cf) => ({
        ...cf,
        // check model to determine whether custom field is active
        active:
          (requestSettings as RequestSettings).requestSubtypeState.customFields.find((cfm) => cf.name === cfm.name)
            ?.active || false,
      }));
    }

    return requestModel.customFields?.length ? requestModel.customFields : [];
  };

  getTypeSpecificFields = (): RequestTypeSpecificField[] => {
    const { isEditMode, requestSettings } = this.props;
    const { requestModel } = this.state;

    if (isEditMode) {
      return ((requestSettings as RequestSettings).subtypeSpecificFields || []).map((f) => {
        const active = !!(requestSettings as RequestSettings).requestSubtypeState.subtypeSpecificFields.find(
          (fm) => f.name === fm.name,
        )?.active;
        /// ugly workaround. we need to rewrite add/edit component.
        const updatedValue = requestModel.subtypeSpecificFields.find((fm) => f.name === fm.name)?.value ?? null;
        const value = updatedValue === null ? f.value : updatedValue;

        return {
          ...f,
          value,
          // check model to determine whether type specific field is active
          active,
        };
      });
    }

    return requestModel.subtypeSpecificFields || [];
  };

  uploadAttachment = async (
    file: File,
    onUploadProgress: AxiosRequestConfig["onUploadProgress"],
    cancelToken: CancelToken,
  ) => {
    const response = await uploadRequestAttachmentNew(
      {
        createdBy: window.global_store.profile.uuid,
        companyUUID: window.global_store.company.uuid,
        attachment: file,
        requestUuid: null,
      },
      onUploadProgress,
      cancelToken,
    );
    const attachment = response.data.content;

    return attachment;
  };

  removeAttachment = async (uuid: string) => {
    const { requestModel, errors } = this.state;
    const { attachments } = this;
    const { t } = this.props;

    if (requestModel.attachment.required && attachments.length === 1) {
      this.setState({ errors: { ...errors, attachment: t("At least one attachment is required") } });
      throw Error("attachments required");
    }

    const foundAttachments = attachments.filter((a) => a.uuid === uuid && !!a.requestUuid);

    if (foundAttachments.length) {
      await deleteAttachmentNew({
        body: {
          content: {
            updatedBy: window.global_store.profile.uuid,
          },
        },
        requestUUID: this.props.requestSettings.uuid,
        companyUUID: window.global_store.company.uuid,
        attachmentUuid: uuid,
      });
    }
  };

  onAttachmentsChange = (event: { attachments: RequestAttachment[] }) => {
    const isSomeLoading = event.attachments.some((a) => a.loading);

    if (this.props.stateRef?.current) {
      this.props.stateRef.current.loading = isSomeLoading;
    }

    this.setState({ loading: isSomeLoading });
    this.attachments = event.attachments;
  };

  resetHBAdjustmentField = (nextPhaseId: string, nextSelectedPhase: RequestsAddEditPopupState["selectedPhase"]) => {
    const { requestModel } = this.state;
    let index = -1;
    const field = this.getTypeSpecificFields().find((f, i) => {
      if (f.name === RequestSubtypeSpecificFieldTypes.adjustment) {
        index = i;
        return true;
      }
      return false;
    });

    let rm = requestModel;
    // reset hb ajustment field
    if (field) {
      const newField = { ...(field || {}) } as RequestTypeSpecificField;
      newField.value = {
        phaseId: nextPhaseId,
        phaseValue: 0,
      };

      const fieldsNew: RequestTypeSpecificField[] = [...requestModel.subtypeSpecificFields];
      fieldsNew[index] = newField;

      rm = { ...requestModel, subtypeSpecificFields: fieldsNew };
    }

    this.setState({
      selectedPhase: nextSelectedPhase,
      requestModel: rm,
    });
  };

  getFields = () => {
    const {
      allDay,
      requestModel,
      userProfileUuid,
      userProfileUuids,
      usedDays,
      availableDays,
      errors,
      lockedDate,
      startDate,
      endDate,
      startTime,
      endTime,
      validityType,
      validity,
      phases,
      selectedPhase,
      isNightShift,
      employeeCurrentScheduleUuid,
    } = this.state;
    const { t, isEditMode, requestSettings } = this.props;

    switch (requestModel.requestType) {
      case RequestTypeName.vacation:
        return (
          <RequestAddVacationDaysWithInfo
            onChange={(props: { startDate: moment.Moment; endDate: moment.Moment }) => {
              this.setState(props);

              if (
                userProfileUuid &&
                !userProfileUuids?.length &&
                requestModel.complianceRules?.totalDaysLimit.active &&
                startDate.format("DDMMYYYY") !== props.startDate.format("DDMMYYYY")
              ) {
                void this.requestVacationBalance(userProfileUuid, props.startDate, props.endDate);
              }
            }}
            validateAvailableDays={
              !!(
                userProfileUuid &&
                (!userProfileUuids || !userProfileUuids.length) &&
                requestModel.complianceRules?.totalDaysLimit.active
              )
            }
            errors={errors}
            lockedDate={lockedDate}
            startDate={startDate}
            endDate={endDate}
            usedDays={usedDays}
            availableDays={availableDays}
            withInfo={
              !!(
                (userProfileUuid || userProfileUuids?.length === 1) &&
                requestModel.complianceRules?.totalDaysLimit.active
              )
            }
            isEditMode={isEditMode}
            {...requestModel.complianceRules}
          />
        );
      case RequestTypeName.overtime:
        return requestModel.requestType === RequestTypeName.overtime ? (
          <PlannedDayOvertimeFileds
            requestModel={requestModel}
            userProfileUuid={userProfileUuid}
            userProfileUuids={userProfileUuids}
            startDate={startDate}
            errors={errors}
            lockedDate={lockedDate}
            validityType={validityType}
            validity={validity}
            updateSelectedSubtype={(selectedSubtype) => {
              const requestSubtype = this.props.requestSettings.subtypes.find((st) => st.nameId === selectedSubtype);

              if (requestSubtype) {
                this.setState({
                  category: requestSubtype.uuid,
                  requestModel: requestSubtype,
                });
              }
            }}
            onDateChange={
              !isEditMode
                ? (sd) => {
                    // for overtime dates should be the same
                    this.setState({ startDate: sd, endDate: sd });
                  }
                : undefined
            }
            onValidityTypeChange={(_validityType: OvertimeRequestValidityType): void =>
              this.setState({ validityType: _validityType })
            }
            onValidityChange={(value) => this.setState({ validity: value })}
          />
        ) : null;
      case RequestTypeName.hbConvert:
        return (
          <HoursBankCovertFields
            selectedPhase={selectedPhase}
            startDate={startDate}
            errors={errors}
            lockedDate={lockedDate}
            phases={phases}
            onSelectPhaseChange={(checked) => {
              // reset field after toogle was changed
              this.resetHBAdjustmentField("negative", checked ? phases[0] || null : null);
            }}
            onPhaseChange={(val) => {
              this.resetHBAdjustmentField(selectedPhase?.uuid, phases.find((p) => p.uuid === val) || null);
            }}
            onDateChange={(sd) => {
              // for convert request dates should be the same
              this.setState({ startDate: sd, endDate: sd }, async () => {
                await this.getHBrequestData();

                // choose the first phase from fresh phases list if phase was selected before
                this.resetHBAdjustmentField(
                  selectedPhase ? phases[0]?.uuid : "negative",
                  selectedPhase ? phases[0] || null : null,
                );
              });
            }}
          />
        );
      case RequestTypeName.scheduleAssignment:
        return (
          <ScheduleAssignmentFields
            selectedSchedule={
              requestModel.subtypeSpecificFields.find((e) => e.name === RequestSubtypeSpecificFieldTypes.schedule)
                ?.value ||
              requestSettings?.subtypeSpecificFields?.find((e) => e.name === RequestSubtypeSpecificFieldTypes.schedule)
                ?.value ||
              null
            }
            startDate={startDate}
            errors={errors}
            lockedDate={lockedDate}
            employeeCurrentScheduleUuid={employeeCurrentScheduleUuid}
            onScheduleChange={(scheduleUuid: string) => {
              const fieldsNew = requestModel.subtypeSpecificFields.find(
                (e) => e.fieldType === RequestSubtypeSpecificFieldTypes.schedule,
              );
              if (fieldsNew) {
                this.setState({
                  requestModel: { ...requestModel, subtypeSpecificFields: [{ ...fieldsNew, value: scheduleUuid }] },
                });
              }
            }}
            onDateChange={async (sd) => {
              if (userProfileUuid && sd) {
                await this.getSelectedEmployeesScheduleForDate(userProfileUuid, sd || moment());
              } else {
                this.setState({ employeeCurrentScheduleUuid: null });
              }
              // dates should be the same
              this.setState({ startDate: sd, endDate: sd, employeeCurrentScheduleUuid: null });
            }}
          />
        );
      default:
        return (
          <>
            <CheckboxWrapper>
              <CheckboxControl
                checked={allDay}
                label={t("All day")}
                onChange={(checked) => {
                  this.setState({
                    allDay: checked,
                    isNightShift: checked ? false : isNightShift,
                  });
                }}
              />
            </CheckboxWrapper>
            {allDay ? (
              <>
                <TwoFieldsWrapper style={{ gap: "16px" }}>
                  <FieldWrapper width="100%" wrapperWidth="100%" fieldName={t("Date")}>
                    <DateRangeSelector
                      startDate={startDate}
                      endDate={endDate}
                      numberOfMonths={1}
                      dropOver
                      smallHeight
                      handleDateChange={(sd, ed) => {
                        this.setState({ endDate: ed!, startDate: sd! });
                      }}
                      selectPlaceholder={t("Date")}
                    />
                  </FieldWrapper>
                  <FieldWrapper width="100%" wrapperWidth="100%" fieldName={t("Duration")}>
                    <TextInputControl
                      disabled
                      value={startDate && endDate ? `${endDate.diff(startDate, "days") + 1}` : ""}
                      onChange={() => {}}
                    />
                  </FieldWrapper>
                </TwoFieldsWrapper>
                {errors?.startDate && errors.startDate !== true && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                {errors?.endDate && errors.endDate !== true && <ErrorLabel>{errors.endDate}</ErrorLabel>}
              </>
            ) : (
              <>
                <DateTimeRow
                  dateLabel={`${t("Start date")}:`}
                  timeLabel={`${t("Time")}:`}
                  date={startDate}
                  time={startTime || ""}
                  onDateChange={(sd) => {
                    let ed = endDate;

                    if (endDate && endDate.isBefore(sd, "day")) {
                      ed = sd.clone();
                    }
                    this.setState({ startDate: sd, endDate: ed, isNightShift: false });
                  }}
                  onTimeChange={({ value }) => this.setState({ startTime: value })}
                  dateError={!!errors?.startDate}
                  timeError={(errors?.startTime as string) || ""}
                  timeDisabled={allDay}
                  isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
                />
                {errors?.startDate && errors.startDate !== true && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                {errors?.startTime && errors.startTime !== true && <ErrorLabel>{errors.startTime}</ErrorLabel>}

                <DateTimeRow
                  dateLabel={`${t("End date")}:`}
                  timeLabel={`${t("Time")}:`}
                  date={endDate}
                  time={endTime || ""}
                  onDateChange={(ed) => {
                    let sd = startDate;

                    if (startDate?.isAfter(ed, "day")) {
                      sd = ed.clone();
                    }

                    this.setState({ endDate: ed, startDate: sd, isNightShift: false });
                  }}
                  onTimeChange={({ value }) => {
                    this.setState({ endTime: value });
                  }}
                  dateError={!!errors?.endDate}
                  timeError={(errors?.endTime as string) || ""}
                  timeDisabled={allDay}
                  isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
                />
                {errors?.endDate && errors.endDate !== true && <ErrorLabel>{errors.endDate}</ErrorLabel>}
                {errors?.endTime && errors.endTime !== true && <ErrorLabel>{errors.endTime}</ErrorLabel>}

                {startDate && endDate && startDate.isSame(endDate, "day") && (
                  <CheckboxControl
                    className="checkbox-nightshift"
                    checked={isNightShift}
                    label={t("Apply to yesterday schedule")}
                    onChange={(checked) => this.setState({ isNightShift: checked })}
                  />
                )}
              </>
            )}
          </>
        );
    }
  };

  render() {
    const {
      allDay,
      category,
      errors,
      startDate,
      endDate,
      subTypesOptions,
      requestModel,
      comment,
      loading,
      attachments,
      selectedPhase,
      hoursBankMins,
      userProfileUuid,
      employeeFullName,
    } = this.state;
    const { t, requestSettings, isEditMode } = this.props;

    return (
      <Wrapper>
        {hasEmployeesAccess() && (
          <SearchControl
            trackingLocation={MassActionLocations.Requests}
            permissionSection={PermissionSectionName.requests}
            withMultiple
            value={employeeFullName || undefined}
            onChange={async (searchObj) => {
              let lockDate = null;
              let employee = null;
              let uuids = null;

              if (!Array.isArray(searchObj)) {
                employee = (searchObj as SearchControlOnChangeData).employee
                  ? (searchObj as SearchControlOnChangeData).employee
                  : searchObj;
              } else if (searchObj.length === 1) {
                [employee] = searchObj;
              } else if (searchObj.length > 1) {
                uuids = searchObj.map((e) => e.uuid);
              }
              lockDate = employee && getLastLockDate(employee) ? moment(getLastLockDate(employee), "YYYY-MM-DD") : null;
              let sDate = startDate;
              let eDate = endDate;

              if (lockDate && startDate.isSameOrBefore(lockDate, "day")) {
                sDate = lockDate.clone().add(1, "day");
              }
              if (lockDate && endDate.isSameOrBefore(lockDate, "day")) {
                eDate = lockDate.clone().add(1, "day");
              }

              if (
                employee &&
                requestModel.requestType === RequestTypeName.vacation &&
                requestModel.complianceRules &&
                requestModel.complianceRules.totalDaysLimit.active
              ) {
                void this.requestVacationBalance(employee.uuid as string, sDate, eDate);
              }

              if (requestModel.requestType === RequestTypeName.scheduleAssignment) {
                if (employee?.uuid) {
                  await this.getSelectedEmployeesScheduleForDate(employee.uuid, startDate || moment());
                } else {
                  this.setState({ employeeCurrentScheduleUuid: null });
                }
              }

              this.setState(
                {
                  userProfileUuids: uuids,
                  userProfileUuid: employee ? (employee.uuid as string) : null,
                  employeeFullName: employee ? employee.full_name : null,
                  startDate: sDate,
                  lockedDate: lockDate,
                  endDate: eDate,
                },
                async () => {
                  if (requestModel.requestType === RequestTypeName.hbConvert) {
                    await this.getHBrequestData();

                    // choose the first phase from fresh phases list if phase was selected before
                    this.resetHBAdjustmentField(
                      this.state.phases.length ? this.state.phases[0]?.uuid : "negative",
                      this.state.phases.length ? this.state.phases[0] || null : null,
                    );
                  }
                },
              );
            }}
            placeholder={translateEmployeeTerm(
              t,
              TranslationNamespaces.common,
              "custom-search-employees",
              `${TranslationNamespaces.common}|Search Employees`,
            )}
            locked={isEditMode}
          />
        )}
        {errors?.employee && <ErrorLabel>{errors.employee}</ErrorLabel>}
        {this.getFields()}
        {!!subTypesOptions.length &&
          ![RequestTypeName.hbConvert, RequestTypeName.scheduleAssignment].includes(requestModel.requestType) && (
            <FieldWrapper fieldName={t("Category")} width="100%">
              <Select
                disabled={isEditMode || requestModel.requestType === RequestTypeName.overtime}
                modifiers={{ field: true }}
                value={category}
                onChange={(val) => {
                  this.setState({
                    category: val,
                    allDay:
                      requestSettings.subtypes.filter((st) => st.uuid === val)[0].requestType === "vacation"
                        ? true
                        : allDay,
                    requestModel: requestSettings.subtypes.filter((st) => st.uuid === val)[0],
                  });
                }}
                options={subTypesOptions}
              />
            </FieldWrapper>
          )}
        {this.getTypeSpecificFields()
          .filter((f) => f.active && f.name !== RequestSubtypeSpecificFieldTypes.schedule)
          .map((f, i) => {
            switch (f.name) {
              case RequestSubtypeSpecificFieldTypes.adjustment:
                return (
                  <SubtypeSpecificFieldsHBAdjustment
                    key={f.name}
                    userProfileUuid={userProfileUuid}
                    errors={errors}
                    selectedPhase={selectedPhase}
                    hoursBankMins={hoursBankMins}
                    field={f}
                    onTimeChange={(newField) => {
                      const fieldsNew: RequestTypeSpecificField[] = [...requestModel.subtypeSpecificFields];
                      fieldsNew[i] = newField;

                      this.setState({ requestModel: { ...requestModel, subtypeSpecificFields: fieldsNew } });
                    }}
                  />
                );

              case RequestSubtypeSpecificFieldTypes.dayRange:
                return (
                  <SubtypeSpecificFieldsOvetimeEnableDay
                    key={f.name}
                    userProfileUuid={userProfileUuid}
                    errors={errors}
                    field={f}
                    onTimeChange={(newField) => {
                      const fieldsNew: RequestTypeSpecificField[] = [...requestModel.subtypeSpecificFields];
                      fieldsNew[i] = newField;

                      this.setState({ requestModel: { ...requestModel, subtypeSpecificFields: fieldsNew } });
                    }}
                  />
                );

              default:
                return <div key={f.name}>TODO: {f.name} field</div>;
            }
          })}
        {this.getCustomFields().map((cf, i) => {
          if (!cf.active) {
            return null;
          }

          return (
            <FieldWrapper key={cf.name + i} fieldName={cf.name} width="100%">
              <TextInputControl
                disabled={isEditMode}
                locked={isEditMode}
                placeholder={cf.name}
                value={cf.value || ""}
                error={!!errors?.cfErrors?.[cf.name]}
                onChange={(val: ChangeEvent<HTMLInputElement>) => {
                  const newCf = { ...cf };
                  newCf.value = val.target.value;

                  const customFieldsNew: RequestCustomField[] = Object.assign([], requestModel.customFields);
                  customFieldsNew[i] = newCf;

                  this.setState({ requestModel: { ...requestModel, customFields: customFieldsNew } });
                }}
              />

              {!!errors?.cfErrors?.[cf.name] && <ErrorLabel>{errors.cfErrors[cf.name]}</ErrorLabel>}
            </FieldWrapper>
          );
        })}
        {requestModel.comment.active && (
          <FieldWrapper fieldName={t("Comments")} width="100%">
            <Textarea
              locked={isEditMode}
              error={(errors?.comment as string) || ""}
              value={comment || ""}
              rows={15}
              onChange={(ev) => this.setState({ comment: ev.target.value })}
            />

            {errors?.comment && <ErrorLabel>{errors.comment}</ErrorLabel>}
          </FieldWrapper>
        )}
        {requestModel.attachment.active && (
          <FieldWrapper fieldName={t("Attachments")} width="100%" fieldTitleMarginTop={20}>
            <Attachments
              edit
              upload={this.uploadAttachment}
              remove={this.removeAttachment}
              attachments={attachments}
              onChange={this.onAttachmentsChange}
            />
            {errors?.attachment && <ErrorLabel>{errors.attachment}</ErrorLabel>}
          </FieldWrapper>
        )}
        <ButtonsWrapper>
          <Button
            value={isEditMode ? t("Save") : t("Add an Absence")}
            disabled={loading}
            onClick={!loading ? this.onYes : undefined}
          />
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp, TranslationNamespaces.phases])(
  RequestsAddEditPopup,
);
