import FieldWrapper from "components/UI/FieldWrapper";
import GlobalContext from "context/global-context";
import { useContext } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Request, RequestSubtypeSpecificFieldTypes } from "types/models/request";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { getSchedules } from "utils/apiHelpers";

const FieldValue = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
`;

const useSchedules = () => {
  const STALE_TIME = 1000 * 5;
  const context = useContext(GlobalContext);
  const result = useQuery(
    `rq_schedules`,
    async () => {
      const company = await context.getCompany();
      const response = await getSchedules({ companyUuid: company.uuid, params: { perPage: 10000 } });

      return response.content;
    },
    { staleTime: STALE_TIME, retry: false },
  );

  return result;
};

type SubtypeSpecificFieldsScheduleAssignmentProps = {
  request: Request;
};

const SubtypeSpecificFieldsScheduleAssignment = ({ request }: SubtypeSpecificFieldsScheduleAssignmentProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const { isLoading, data: schedules } = useSchedules();

  const scheduleField = request.subtypeSpecificFields?.find(
    (field) => field.name === RequestSubtypeSpecificFieldTypes.schedule,
  );

  if (!scheduleField) {
    return null;
  }

  const scheduleUuid = scheduleField.value;
  const selectedSchedule = schedules?.find((sch) => sch.uuid === scheduleUuid);

  return (
    <FieldWrapper fieldName={t("Schedule")} width="100%" fieldTitleMarginTop={20} loading={isLoading}>
      {!!selectedSchedule && <FieldValue>{selectedSchedule.name}</FieldValue>}
    </FieldWrapper>
  );
};

export default SubtypeSpecificFieldsScheduleAssignment;
