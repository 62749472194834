enum OnCallType {
  onCall = "onCall",
  activation = "activation",
}

enum OnCallStatus {
  pending = "pending",
  approved = "approved",
  declined = "declined",
}

type OnCall = {
  uuid: string;
  companyUuid: string;
  userProfileUuid: string;
  startTime: string;
  endTime: string;
  timezone: string;
  allDay: boolean;
  type: OnCallType;
  status: OnCallStatus;
  createdAt: string;
  createdBy: string;
  deletedAt: string | null;
  updatedBy: string | null;
  reviewedBy: string | null;
  locked: boolean;
};

type OnCallTableData = {
  employee: {
    avatarId: string;
    fullName: string;
    position: string;
  };
  userProfileUuid: string;
  matricula: string;
  cpf: string;
  type: OnCallType;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  duration: string;
  approver: string;
  status: OnCallStatus;
  createdBy: string;
  uuid: string;
  locked: boolean;
};

export type { OnCall, OnCallTableData };

export { OnCallType, OnCallStatus };
