import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "components/UI/Page/Layout";
import NotificationRow from "components/NotificationRow";
import { TranslationNamespaces } from "types/translationNamespaces";
import { SubscriptionApi } from "utils/api/subscription";
import GlobalContext from "context/global-context";
import { BillingData, BillingDataResponse } from "types/models/subscription";
import { FormikProps } from "formik";
import { NotificationType } from "types/common";
import Button, { ButtonState } from "components/controls/StyledButton";
import SettingsBillingLayout from "components/Layout/SettingsBillingLayout";
import BillingDetailsForm from "./BillingDetailsForm";

const BillingAddressPage = () => {
  const billingFormRef: MutableRefObject<FormikProps<BillingData> | undefined> = useRef();
  const { t } = useTranslation([TranslationNamespaces.subscription, TranslationNamespaces.fullPage]);
  const [billingInfo, setBillingInfo] = useState<BillingDataResponse>();
  const [success, setSuccess] = useState<boolean>(false);
  const [companyUuid, setCompanyUuid] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const companyPromise = useContext(GlobalContext).getCompany();

  useEffect(() => {
    companyPromise.then((company: { uuid: string }) => {
      setCompanyUuid(company.uuid);
      void SubscriptionApi.getBillingInfo(company.uuid).then((res) => {
        setBillingInfo(res);
      });
    });
  }, []);

  const changeCountry = () => {};
  const submit = async () => {
    await billingFormRef.current!.submitForm();
    if (!billingFormRef.current?.isValid) return;
    setLoading(true);
    const { values } = billingFormRef.current;
    void SubscriptionApi.setBillingInfo(companyUuid!, values).then(() => {
      setSuccess(true);
      setLoading(false);
    });
  };

  return (
    <SettingsBillingLayout title={t(`${TranslationNamespaces.fullPage}|Billing`)}>
      <PageLayout title={t("Billing address")}>
        {success && <NotificationRow type={NotificationType.success} message={t("Information saved successfully")} />}
        <div style={{ maxWidth: "600px", paddingTop: 20 }}>
          {(billingInfo && (
            <BillingDetailsForm ref={billingFormRef} data={billingInfo} onChangeCountry={changeCountry} />
          )) ||
            null}
        </div>
        <Button
          type="button"
          disabled={loading}
          value={t("Save")}
          style={{ width: "160px", marginTop: "24px" }}
          state={ButtonState.primary}
          onClick={submit}
        />
      </PageLayout>
    </SettingsBillingLayout>
  );
};

export default BillingAddressPage;
