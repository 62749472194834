import { Component, ContextType, MouseEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import { getPayrollLayouts, deletePayrollLayout } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PayrollLayoutFull } from "types/models/payroll/payrollLayout";
import FullPage from "components/Layout/FullPage";
import { iColumn } from "components/TableCommon";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import NoContent from "components/NoContent";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import Tooltip from "components/UI/Tooltip";
import * as images from "components/svg-images";
import { PageWrapper } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import noPayrolls from "../../img/no-schedules.png";
import "components/UI/Page/Page.scss";

const tb = BEM.b("table-common");

interface PayrollProps extends WithTranslation, RouteComponentProps {}

interface PayrollState {
  payrolls: PayrollLayoutFull[];
  selectedPayroll: PayrollLayoutFull | null;
  notification: string | null;
  isFetching: boolean;
  notificationType: NotificationType | null;
  confirmationPopupVisible: boolean;
}

class Payroll extends Component<PayrollProps, PayrollState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: PayrollProps) {
    super(props);
    const { t } = props;

    this.state = {
      payrolls: [],
      selectedPayroll: null,
      notification: null,
      isFetching: false,
      notificationType: null,
      confirmationPopupVisible: false,
    };

    document.title = t("Payroll");
  }

  componentDidMount() {
    if (localStorage.getItem("payrollMessage")) {
      this.setState(
        {
          notification: localStorage.getItem("payrollMessage"),
          notificationType: NotificationType.success,
        },
        () => localStorage.removeItem("payrollMessage"),
      );
    }

    void this.getState();
  }

  getState = async () => {
    const company = await this.context.getCompany();

    if (!this.state.isFetching && company) {
      this.setState({ isFetching: true }, async () => {
        try {
          const r = await getPayrollLayouts({ companyUUID: company.uuid });

          this.setState({
            payrolls: r?.content?.sort((a, b) => a.name.localeCompare(b.name)) || [],
            isFetching: false,
          });
        } catch (err) {
          const error = err as Error;
          sentryUtils.sendError(error);

          this.setState({
            notification: error.message,
            notificationType: NotificationType.error,
          });
        } finally {
          this.setState({ isFetching: false });
        }
      });
    }
  };

  onRemovePayrollClick = (ev: MouseEvent, selectedPayroll: PayrollLayoutFull) => {
    ev.stopPropagation();
    this.setState({ confirmationPopupVisible: true, selectedPayroll });
  };

  onRemovePayrollConfirm = async () => {
    const { t } = this.props;
    const { selectedPayroll } = this.state;

    if (!selectedPayroll) {
      return;
    }

    try {
      await deletePayrollLayout({
        payrollUUID: selectedPayroll.uuid,
      });

      this.setState(
        {
          notification: `${t("You deleted")} ${selectedPayroll.name}`,
          notificationType: NotificationType.success,
          confirmationPopupVisible: false,
        },
        this.getState,
      );
    } catch (err) {
      const error = err as Error;
      sentryUtils.sendError(error);

      this.setState({
        notification: error.message,
        notificationType: NotificationType.error,
      });
    }
  };

  onEditPayrollClick = (uuid: string) => {
    const { history } = this.props;
    history.push(`/payroll/export/${uuid}`);
  };

  addPayrollClick = () => {
    const { history } = this.props;
    history.push("/payroll/export/create-layout");
  };

  getTableColumns = (): iColumn<PayrollLayoutFull>[] => {
    const { t } = this.props;

    return [
      {
        label: t("Name"),
        accessor: "name",
        locked: true,
        minWidth: 320,
        style: { lineHeight: "36px" },
        Cell: ({ value }) => (
          <>
            <span data-tip data-for={`tooltip-${value}`}>
              {value}
            </span>
            <Tooltip id={`tooltip-${value}`}>{value}</Tooltip>
          </>
        ),
      },
      {
        label: t("System"),
        locked: true,
        accessor: "payrollProvider",
        minWidth: 150,
        Cell: (row) => (
          <div>
            {row.value.name}
            <div className={tb("buttons")}>
              <div className={tb("button")} onClick={() => this.onEditPayrollClick(row.original.uuid)}>
                {t("common|Edit")}
              </div>
              <div className={tb("button", ["remove"])} onClick={(ev) => this.onRemovePayrollClick(ev, row.original)}>
                {images.trashIcon}
              </div>
            </div>
          </div>
        ),
      },
    ];
  };

  render() {
    const { payrolls, confirmationPopupVisible, notification, notificationType } = this.state;
    const { t } = this.props;

    return (
      <FullPage
        title={t(`${TranslationNamespaces.sidebar}|Payroll`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("Create a Layout")}
            onClick={this.addPayrollClick}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              onClose={() => this.setState({ notification: null, notificationType: null })}
              type={notificationType}
              message={notification}
            />
          )}
          <br />

          <TablePage
            rows={payrolls}
            columnSelectorOnFiltersRow
            customColumnsAvailable={false}
            columns={this.getTableColumns()}
            className="payroll-table"
            loading={this.state.isFetching}
            getTrProps={(_, rowInfo) => ({
              onClick: (e: MouseEvent) => {
                if (rowInfo?.row._original.uuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/payroll/export/${rowInfo.row._original.uuid}`,
                    }).click();
                  } else {
                    this.onEditPayrollClick(rowInfo.row._original.uuid);
                  }
                }
              },
            })}
            noContentComponent={
              <NoContent img={noPayrolls}>{t("You do not have any registered payroll layouts yet")}</NoContent>
            }
          />

          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Are you sure you want to delete this layout?")}
              text={t("Deleting this layout you will no longer have access to the data")}
              buttonYesTitle={t("Yes, Delete")}
              buttonCancelTitle={t("Cancel")}
              onClose={() => {
                this.setState({ selectedPayroll: null, confirmationPopupVisible: false });
              }}
              onYes={this.onRemovePayrollConfirm}
            />
          </ModalDialog>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.payroll, TranslationNamespaces.sidebar])(Payroll);
