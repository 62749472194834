import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getSystemName } from "utils/common";
import DownloadDigitalSignaturesReportButton from "./DownloadDigitalSignaturesReportButton";
import reportSignedImg from "./images/report-signed.png";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 30px;
  line-height: 26px;
  color: var(--colors-mainText);
  font-weight: var(--typography-font-weight-medium);
  margin-bottom: 22px;
`;
const Description = styled.div`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--colors-surface-600);
`;
const Back = styled.a`
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-primary);
  font-weight: var(--typography-font-weight-medium);
  cursor: pointer;
`;
const Image = styled.img`
  object-fit: content;
  width: 249px;
  height: 179px;
  margin-bottom: 40px;
`;

type ReportSignedSuccessfullyProps = {
  reportHash: string;
  digitalSignatureUuid: string;
  startDate: string;
  endDate: string;
  selectedColumns: string;
};

export default function ReportSignedSuccessfully({
  reportHash,
  digitalSignatureUuid,
  startDate,
  endDate,
  selectedColumns,
}: ReportSignedSuccessfullyProps) {
  const { t } = useTranslation("payment");

  return (
    <Wrapper>
      <Image src={reportSignedImg} alt={t("Thank you for Signing!")} />
      <Title>{t("Thank you for Signing!")}</Title>
      <Description>
        {t("The report {{range}} was signed successfully", {
          range: `${moment(startDate, "YYYY-MM-DD").format("DD.MM.YYYY")} - ${moment(endDate, "YYYY-MM-DD").format(
            "DD.MM.YYYY",
          )}`,
        })}
      </Description>
      <DownloadDigitalSignaturesReportButton
        reportHash={reportHash}
        digitalSignatureUuid={digitalSignatureUuid}
        selectedColumns={selectedColumns}
      />
      <Back href="/">{t("Back To {{systemName}}", { systemName: getSystemName() })}</Back>
    </Wrapper>
  );
}
