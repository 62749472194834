import { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { extendMoment } from "moment-range";
import * as momentLib from "moment";
import SettingsLayout from "components/Layout/SettingsLayout";
import BEM from "utils/BEM";
import GlobalContext from "context/global-context";
import {
  getDataTable,
  updateTableData,
  clearDayRegular,
  getScheduleExceptionForCreate,
  copyToNextDayRegular,
  removeBreak,
  addAdditionalEntryExit,
  removeAdditionalEntryExit,
  getRulesFromConfines,
  validateScheduleRegular,
  getConfinesFromRules,
} from "utils/scheduleHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getTitle, getLastLockDate } from "utils/common";
import { PermissionSectionName } from "types/models/permissions";
import Sentry from "utils/sentryUtils";
import schedulesMoc from "utils/schedule";
import { createScheduleException } from "utils/apiHelpers";
import FieldsGroup from "components/UI/FieldsGroup";
import FieldWrapper from "components/UI/FieldWrapper";
import NotificationRow from "components/NotificationRow";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import SearchControl from "components/UI/SearchControlNew";
import Select from "components/UI/Select";
import RichTooltip from "components/UI/RichTooltip";
import Button from "components/controls/StyledButton";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { MassActionLocations } from "utils/ga";

import ScheduleLimits from "./ScheduleLimits";
import ScheduleTableException from "./ScheduleTableException";
import * as images from "../svg-images";
import "../UI/Page/Page.scss";
import "styles/schedule-create.scss";

const b = BEM.b("schedule-create");
const p = BEM.b("page");

const moment = extendMoment(momentLib);

const PeriodRow = styled.div`
  display: flex;
  align-items: center;
`;
const AddPeriod = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.primary}
  font-size: var(--typography-font-size-default);
`;
const RemovePeriod = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  margin-bottom: 9px;
  margin-inline-start: 5px;
  cursor: pointer;
  width: 19px;
  height: 19px;
  border-radius: 100px;
  &:hover {
    background-color: ${(p) => p.theme.colors.buttonBgColor2};
  }
`;

const SpacerVerical = styled.div`
  width: 15px;
`;
const Subtitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.328125px;
  color: var(--colors-mainText);
  margin: 32px 0 16px 0;
  display: flex;
  align-items: center;
`;

const SearchControlWrapper = styled.div`
  width: 300px;
  margin-inline-end: 25px;
`;

class ScheduleCreateException extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;

    this.defaultTemplate = { value: "custom", label: t("Custom") };
    const schedule = this.props.schedule || { ...schedulesMoc.regularEmpty };
    this.unusedBreak = [
      { value: "useCompanyBusinessRules", label: t("Use Company Business Rules") },
      { value: "ignore", label: t("Ignore") },
      { value: "moveToExtraHours", label: t("Move to Extra Hours") },
    ];
    this.state = {
      name: "",
      startDate: moment(),
      endDate: null,
      isPeriod: false,
      errors: null,
      userProfileUuids: null,
      schedule,
      tableData: [],
      unusedBreak:
        schedule.options && schedule.options.businessRules && schedule.options.businessRules.unusedBreak
          ? schedule.options.businessRules.unusedBreak
          : "useCompanyBusinessRules",
      rules: getRulesFromConfines({
        toleranceBreakEndLate: "10",
        toleranceBreakStartEarly: "10",
        toleranceEntryLate: "10",
        toleranceExitEarly: "10",
        validityEntryEarly: "10",
        validityExitLate: "10",
      }),
    };

    document.title = getTitle(t("Schedules"));
  }

  componentDidMount() {
    this.setState({
      tableData: getDataTable(
        {
          scheduleDays: this.getScheduleDays(),
        },
        true,
      ),
    });
  }

  getScheduleDays() {
    const { startDate, endDate, isPeriod } = this.state;
    const scheduleDays = [];
    if (!isPeriod) {
      scheduleDays.push({
        dayId: moment(startDate).format("E"),
        working: true,
        events: [],
        type: "regular",
      });
    } else if (endDate) {
      const range = moment().range(moment(startDate).startOf("day").clone(), moment(endDate).endOf("day").clone());
      const rangeLength = range.diff("days");
      if (rangeLength > 0 && rangeLength < 7) {
        for (const day of range.by("day")) {
          scheduleDays.push({
            dayId: day.format("E"),
            working: true,
            events: [],
            type: "regular",
          });
        }
      } else if (rangeLength > 6) {
        const week = [...Array(7)].map((e, i) => ({ id: i + 1 }));
        for (const day of week) {
          scheduleDays.push({
            dayId: day.id,
            working: true,
            events: [],
            type: "regular",
          });
        }
      }
    }
    return scheduleDays;
  }

  validate() {
    const { t } = this.props;
    const { name, searchObj, startDate, endDate, isPeriod } = this.state;
    let errors = {};
    if (!name) {
      errors.name = t("Exception name can't be empty");
    } else if (!searchObj) {
      errors.searchObj = t("Please select employee");
    } else if (!startDate) {
      errors.startDate = t("Please select start date");
    } else if (isPeriod && !endDate) {
      errors.endDate = t("Please select end date");
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }

  saveSchedule = () => {
    const { t } = this.props;
    const { schedule, tableData, name, startDate, endDate, unusedBreak, userProfileUuids, rules } = this.state;

    const errors = this.validate();
    if (!errors) {
      const validationObj = validateScheduleRegular(tableData, true);
      let body = {};

      if (validationObj.valid) {
        schedule.name = name;
        body = getScheduleExceptionForCreate({
          tableData,
          name,
          startDate,
          endDate,
          UserProfileUuids: userProfileUuids,
        });
        body.options.confines = getConfinesFromRules(rules);
        body.options.businessRules = {
          unusedBreak,
        };
        if (body.options.confines.validityEntryEarly === "none") {
          body.options.confines.validityEntryEarly = null;
        }
        if (body.options.confines.validityExitLate === "none") {
          body.options.confines.validityExitLate = null;
        }
        this.setState({ isLoading: true });
        createScheduleException({
          body: { content: body },
          companyUuid: window.global_store.company.uuid,
        })
          .then((r) => {
            this.props.history.push("/schedules/?tab=exceptions");
          })
          .catch((e) => {
            Sentry.sendError(e);

            const generalError = (e.errors?.[0] || e)?.message || t("Something went wrong");
            this.setState({
              errors: { generalError, isLoading: false },
            });
          });
      }
      this.setState({ tableData: validationObj.tableData, errors });
    } else {
      this.setState({ errors });
    }
  };

  onScheduleTableChange = (dayId, dayData) => {
    const { tableData } = this.state;
    this.setState({
      tableData: updateTableData(dayId, dayData, tableData),
    });
  };

  onCopyToNextDay = (currentDayId) => {
    const { tableData } = this.state;
    this.setState({
      tableData: copyToNextDayRegular(currentDayId, tableData),
    });
  };

  onClearDay = (dayId) => {
    const { tableData } = this.state;
    this.setState({
      tableData: clearDayRegular(dayId, tableData),
    });
  };

  getTotal(scheduleType, tableData) {
    let total = 0;
    tableData.map((d) => {
      const rowTotal = d.total;
      if (rowTotal) {
        total += rowTotal;
      }
      return d;
    });
    return total;
  }

  getScheduleTable = () => (
    <ScheduleTableException
      tableData={this.state.tableData}
      onChange={this.onScheduleTableChange}
      onClearDay={this.onClearDay}
      onRemoveBreak={this.onRemoveBreak}
      onCopyToNextDay={this.onCopyToNextDay}
      onAddAdditionalEntryExit={this.onAddAdditionalEntryExit}
      onRemoveAdditionalEntryExit={this.onRemoveAdditionalEntryExit}
    />
  );

  onRemoveBreak = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({
      tableData: removeBreak(dayId, tableData, breakId),
    });
  };

  onAddAdditionalEntryExit = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({
      tableData: addAdditionalEntryExit(dayId, tableData, breakId),
    });
  };

  onRemoveAdditionalEntryExit = (dayId, breakId) => {
    const { tableData } = this.state;
    this.setState({
      tableData: removeAdditionalEntryExit(dayId, tableData, breakId),
    });
  };

  render() {
    const { errors, searchObj, isPeriod, name, startDate, endDate, unusedBreak, isLoading } = this.state;
    const { t, history } = this.props;
    const notification = t(
      "When time added in Exception will replace the employee's current schedule on the specified day, within the exception validation period.",
    );

    return (
      <SettingsLayout
        title={!this.props.schedule ? t("Create a new Schedule Exception") : null}
        backButtonTitle={t("Schedule Exceptions")}
        backButtonOnclick={() => history.push("/schedules/exceptions/")}
      >
        <div className={p(["schedules"])}>
          <NotificationRow employeesPage withCloseButton={false} type="warning" message={notification} />

          {errors?.generalError && (
            <NotificationRow style={{ marginTop: "16px" }} withCloseButton type="error" message={errors.generalError} />
          )}

          <div style={{ marginTop: "20px" }}>
            <FieldWrapper fieldName={t("Schedule exception name")}>
              <input
                type="text"
                size="60"
                maxLength="60"
                placeholder={t("Schedule exception name")}
                className={b("name-field", {
                  exception: true,
                  error: errors ? errors.name : "",
                })}
                onChange={(ev) => {
                  if (errors) errors.name = "";
                  this.setState({ name: ev.target.value, errors });
                }}
                value={name}
              />
            </FieldWrapper>
            {errors && errors.name && <div className={b("error")}>{t("Please enter Schedule exception name")}</div>}

            <PeriodRow>
              <FieldWrapper fieldName={t("Search")}>
                <SearchControlWrapper>
                  <SearchControl
                    trackingLocation={MassActionLocations.ScheduleExceptions}
                    permissionSection={PermissionSectionName.schedules}
                    withMultiple
                    value={searchObj ? searchObj.label : ""}
                    onChange={(value) => {
                      let lockedDate = null;
                      let employee = null;
                      let userProfileUuids = null;
                      if (!Array.isArray(value)) {
                        employee = value;
                        userProfileUuids = [employee.uuid];
                      } else if (value.length === 1) {
                        [employee] = value;
                        userProfileUuids = [employee.uuid];
                      } else if (value.length > 1) {
                        userProfileUuids = value.map((e) => e.uuid);
                      }
                      lockedDate =
                        value?.employee && getLastLockDate(value.employee)
                          ? moment(getLastLockDate(value.employee)).utc()
                          : null;
                      let sDate = this.state.startDate;
                      if (lockedDate && sDate.isSameOrBefore(lockedDate, "day")) {
                        sDate = lockedDate.clone().add(1, "day");
                      }
                      this.setState({
                        userProfileUuids,
                        searchObj: value,
                        lockedDate,
                        startDate: sDate,
                      });
                    }}
                    placeholder={translateEmployeeTerm(
                      t,
                      TranslationNamespaces.common,
                      "custom-search-employees",
                      `${TranslationNamespaces.common}|Search Employees`,
                    )}
                  />
                </SearchControlWrapper>
              </FieldWrapper>

              <FieldWrapper fieldName={isPeriod ? t("From") : t("Day")} fieldTitleMarginBottom={0}>
                <SingleDatePickerControl
                  className={b("scheduleExceptionDatepicker")}
                  onChange={(val) => {
                    const day = val || moment();
                    this.setState(
                      {
                        startDate: day,
                      },
                      () => {
                        this.setState({
                          tableData: getDataTable(
                            {
                              scheduleDays: this.getScheduleDays(),
                            },
                            true,
                          ),
                        });
                      },
                    );
                  }}
                  value={startDate}
                  error={errors && errors.startDate}
                  isOutsideRange={(day) => {
                    if (this.state.lockedDate) {
                      return day.isSameOrAfter(endDate, "day") || day.isSameOrBefore(this.state.lockedDate, "day");
                    }
                    return day.isSameOrAfter(endDate, "day");
                  }}
                />
              </FieldWrapper>
              <SpacerVerical />
              {isPeriod ? (
                <FieldWrapper fieldName={t("To")}>
                  <SingleDatePickerControl
                    className={b("scheduleExceptionDatepicker")}
                    onChange={(val) => {
                      const day = val || moment();
                      this.setState(
                        {
                          endDate: day,
                        },
                        () => {
                          this.setState({
                            tableData: getDataTable(
                              {
                                scheduleDays: this.getScheduleDays(),
                              },
                              true,
                            ),
                          });
                        },
                      );
                    }}
                    value={endDate}
                    error={errors && errors.endDate}
                    isOutsideRange={(day) => {
                      if (this.state.lockedDate) {
                        return day.isBefore(startDate, "day") || day.isSameOrBefore(this.state.lockedDate, "day");
                      }
                      return day.isBefore(startDate, "day");
                    }}
                  />
                </FieldWrapper>
              ) : null}
              {!isPeriod ? (
                <AddPeriod
                  onClick={() => {
                    const startDate = this.state.startDate || moment();
                    const endDate = this.state.startDate
                      ? this.state.startDate.clone().add(1, "day")
                      : moment().clone().add(1, "day");

                    this.setState({ isPeriod: true, startDate, endDate }, () => {
                      this.setState({
                        tableData: getDataTable(
                          {
                            scheduleDays: this.getScheduleDays(),
                          },
                          true,
                        ),
                      });
                    });
                  }}
                >
                  {t("Add a Period")}
                </AddPeriod>
              ) : null}
              {isPeriod && (
                <RemovePeriod
                  onClick={() => {
                    this.setState({ isPeriod: false });
                  }}
                >
                  {images.removeExceptionIcon}
                </RemovePeriod>
              )}
            </PeriodRow>
            {errors && (errors.startDate || errors.endDate || errors.searchObj) && (
              <div className={b("error")}>{errors.startDate || errors.endDate || errors.searchObj}</div>
            )}
          </div>

          <div className={b("set-times", { exception: true })}>{t("Set times")}</div>
          {this.getScheduleTable()}
          <div className={b("rules-title")}>{t("Rules")}</div>

          <ScheduleLimits rules={this.state.rules} onChange={(rules) => this.setState({ rules })} />

          <Subtitle>
            {t("Advanced Breaks Management")} &nbsp;&nbsp;
            <RichTooltip text={t("breaks-management-info")} />
          </Subtitle>
          <FieldsGroup label={t("UNFINISHED BREAK")}>
            <div style={{ width: "230px", marginTop: "12px" }}>
              <Select
                modifiers={{ field: true }}
                value={unusedBreak}
                onChange={(val) => this.setState({ unusedBreak: val })}
                options={this.unusedBreak}
              />
            </div>
          </FieldsGroup>
          <div className={b("save")}>
            <Button
              state="primary"
              style={{ width: "136px" }}
              loading={isLoading}
              onClick={this.saveSchedule}
              value={t("Save Exception")}
            />
          </div>
        </div>
      </SettingsLayout>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.schedules)(ScheduleCreateException));
