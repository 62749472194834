import { Component } from "react";
import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox";
import moment from "moment";
import { fireEvent } from "utils/common";
import * as images from "components/svg-images";
import rtl from "styled-components-rtl";

const DateButton = styled.div`
  ${rtl`
    left: 10px;
  `}
  position: absolute;
  top: 13px;
  background-color: #d7dee8;
  border-radius: 100%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckboxWrapper = styled.div`
  &.checked:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    border-inline-start: 4px solid var(--colors-primary);
    ${rtl`
      left: 0;
    `}
  }
`;
class CellCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }
  UNSAFE_componentWillMount() {
    document.addEventListener(this.getEventKey(this.props.row), this.handleCheck);
  }
  componentWillUnmount() {
    document.removeEventListener(this.getEventKey(this.props.row), this.handleCheck);
  }
  getEventKey = (row) => `sp_row_checked_${moment(row.value.raw, "YYYY-MM-DD").format("YYYYMMDD")}`;
  handleCheck = (ev) => {
    const { row } = this.props;
    this.setState({ checked: ev.detail.checked });
    this.onCheck(ev.detail.checked, row.value.raw.format("YYYYMMDD"));
  };
  onCheck = (checked, date) => {
    this.setState({ checked }, () => fireEvent("sp_row_checked", { date, checked }));
  };
  onWrapperClick = (ev) => {
    const { row } = this.props;
    if (!row.original.lockedDay && ev.shiftKey) {
      console.log("sp_row_fill-last");
      fireEvent("sp_row_fill-last");
    }
  };
  render() {
    const { checked } = this.state;
    const { row, disableRowSelection } = this.props;

    return (
      <CheckboxWrapper className={checked ? "checked" : ""} onClick={this.onWrapperClick}>
        {row.original.lockedDay ? (
          <DateButton locked>{images.lockedPadlock()}</DateButton>
        ) : (
          <CheckboxControl
            disabled={disableRowSelection}
            checked={checked}
            onChange={(isChecked) => {
              this.onCheck(isChecked, row.value.raw.format("YYYYMMDD"));
            }}
          />
        )}
      </CheckboxWrapper>
    );
  }
}
export default CellCheckbox;
