import { Component, ReactNode } from "react";
import styled from "styled-components";
import RichTooltip from "components/UI/RichTooltip";

interface FieldNameProps {
  $fieldTitleMarginBottom: number;
}

const FieldName = styled.div<FieldNameProps>`
  font-size: 13px;
  line-height: 16px;
  color: var(--colors-secondary);
  letter-spacing: 0;
  margin-bottom: ${(props) => props.$fieldTitleMarginBottom}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface FieldProps {
  $width?: string;
}

const Field = styled.div<FieldProps>`
  ${(props) => (props.$width ? `width: ${props.$width};` : "")}
`;

const Label = styled.div`
  font-size: 13px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.mainText};
  letter-spacing: -0.38px;
  margin-inline-start: 16px;
  font-weight: var(--typography-font-weight-medium);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface iMainWrapperProps {
  wrapperWidth?: string;
  marginTop: number;
  marginBottom: number;
}

const MainWrapper = styled.div<iMainWrapperProps>`
  width: ${(props) => (props.wrapperWidth ? `${props.wrapperWidth}` : "auto")};
  margin-top: ${(p) => p.marginTop}px;
  margin-bottom: ${(p) => p.marginBottom}px;
`;

interface iLoaderProps {
  height: number;
}

const Loader = styled.div<iLoaderProps>`
  height: ${(props) => `${props.height}px;`};
  width: 100%;
`;

interface iFieldWrapperProps {
  fieldName?: ReactNode;
  label?: string;
  tooltipText?: string;
  tooltip?: ReactNode;
  loading?: boolean;
  width?: string;
  wrapperWidth?: string;
  className: string;
  fieldTitleMarginTop: number;
  fieldTitleMarginBottom: number;
  marginBottom: number;
}

class FieldWrapper extends Component<iFieldWrapperProps & { children: ReactNode }> {
  state = {};
  static defaultProps = {
    fieldTitleMarginTop: 13,
    fieldTitleMarginBottom: 5,
    marginBottom: 0,
    className: "",
  };

  render(): JSX.Element {
    const {
      width,
      fieldName,
      label,
      children,
      loading,
      fieldTitleMarginBottom,
      className,
      fieldTitleMarginTop,
      tooltip,
      tooltipText,
      wrapperWidth,
      marginBottom,
    } = this.props;

    return (
      <MainWrapper
        className={className}
        marginTop={fieldTitleMarginTop}
        marginBottom={marginBottom}
        wrapperWidth={wrapperWidth}
      >
        {fieldName ? (
          <FieldName $fieldTitleMarginBottom={fieldTitleMarginBottom}>
            {loading ? <Loader className="loading" height={15} /> : fieldName}
            {tooltipText && <RichTooltip text={tooltipText} />}
            {tooltip}
          </FieldName>
        ) : null}
        <Wrapper>
          <Field $width={width}>{loading ? <Loader className="loading" height={36} /> : children}</Field>
          {label && <Label>{loading ? <Loader className="loading" height={16} /> : label}</Label>}
        </Wrapper>
      </MainWrapper>
    );
  }
}

export default FieldWrapper;
