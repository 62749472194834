import Button, { ButtonState } from "components/controls/StyledButton";
import React from "react";
import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";

const styles = stylesheet`@import "../billing.scss"`;
type NeedOwnerPermissionsModalProps = {
  close: () => void;
};

const NeedOwnerPermissionsModal = ({ close }: NeedOwnerPermissionsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);

  return (
    <div className={styles.UpgradePlanModal}>
      <div className={styles.UpgradeTitle}>{t("Purchase plan")}</div>
      <div className={styles.UpgradePopupTerms}>
        {t("Only the billing owner can upgrade your plan. Contact to owner to let him know you’d like to upgrade.")}
      </div>
      <Button value={t("OK")} style={{ width: "100%" }} state={ButtonState.primary} onClick={close} />
    </div>
  );
};

export default NeedOwnerPermissionsModal;
