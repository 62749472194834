import BEM from "utils/BEM";
import "./Select.scss";

const b = BEM.b("ui-select");

interface iOptionProps {
  label: string;
  active: boolean;
  preselected: boolean;
  checkbox?: boolean;
  disabled?: boolean;
  onSelect?: () => void;
}

const Option = ({ label, checkbox, onSelect, active, preselected, disabled }: iOptionProps): JSX.Element => (
  <div
    onClick={() => !disabled && onSelect && typeof onSelect === "function" && onSelect()}
    className={b("option", { disabled, active, checkbox, "no-checkbox": !checkbox, preselected })}
  >
    {checkbox && <input type="checkbox" className={b("checkbox")} checked={active} disabled={disabled} readOnly />}
    {label}
  </div>
);

export default Option;
