/* eslint camelcase: ["warn", { allow: ["business_rules", "data_type", "input_type", "password_policy"] }] */
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Button from "components/controls/StyledButton";
import styled from "styled-components";
import { getTitle } from "utils/common";
import { getCompanyRules, updateCompanyRules } from "utils/apiHelpers";
import { Notification, NotificationType } from "types/common";
import { CompanyRuleNames } from "types/models/companyRules";

const MainWrapper = styled.div`
  max-width: 420px;
  margin-top: 16px;
`;

const RowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-top: 26px;
  margin-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  width: 167px;
  margin-top: 57px;
  margin-bottom: 57px;
`;

const Label = styled.label`
  margin-top: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    align-self: flex-start;
    margin-top: 3px;
    margin-inline-end: 12px;
    cursor: pointer;
  }
`;

const LabelContent = styled.div``;

const LabelText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: #525f7f;
`;

const LabelDescription = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #a1b2cf;
  margin-top: 8px;
`;

const ChangesWarning = styled.div`
  width: 800px;
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-text4);
  background-color: rgb(255, 246, 224);
`;

const BellIcon = styled.div`
  width: 32px;
  height: 32px;
  margin-inline-end: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.997 9.305c0-3.311-3.11-6.1-6-6.998V2a2 2 0 00-4 0v.307c-2.89.898-6 3.687-6 6.998V15h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h5.5a3 3 0 006 0h5.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-.5V9.305zM9.497 18a.5.5 0 01-.5-.5V17h2v.5a.5.5 0 01-.5.5h-1zm-5.5-8.695V15h12V9.305c0-2.926-3.243-5.305-6-5.305s-6 2.379-6 5.305z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colors-pending);
  border-radius: 50%;
`;

enum PasswordStrength {
  weak = "weak",
  medium = "medium",
  strong = "strong",
}

interface PasswordTabProps extends WithTranslation {
  onSave: (notification: Notification) => void;
}

interface PasswordTabState {
  isLoading: boolean;
  passwordStrength: PasswordStrength | null;
}

class PasswordTab extends Component<PasswordTabProps, PasswordTabState> {
  constructor(props: PasswordTabProps) {
    super(props);
    const { t } = props;

    this.state = {
      isLoading: false,
      passwordStrength: null,
    };

    document.title = getTitle(t("Passowrd Settings"));
  }

  async componentDidMount(): Promise<void> {
    await this.fetchData();
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isLoading: true });
    const { business_rules } = await getCompanyRules();

    const passwordPolicyRule = business_rules.find((br) => br.name === CompanyRuleNames.PASSWORD_POLICY);
    this.setState({ passwordStrength: (passwordPolicyRule?.value as PasswordStrength) || null });
  };

  onChange = (passwordStrength: PasswordStrength): void => {
    this.setState({ passwordStrength });
  };

  changePasswordStrength = async (): Promise<void> => {
    const { onSave, t } = this.props;
    const { passwordStrength } = this.state;

    const data = {
      body: {
        business_rules: {
          password_policy: passwordStrength,
        },
      },
    };

    try {
      await updateCompanyRules(data);

      onSave({ notification: t("Password setting was updated"), notificationType: NotificationType.success });
    } catch (err) {
      onSave({
        notification: t("Error occured while updating password settting"),
        notificationType: NotificationType.error,
      });
    }
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { passwordStrength, isLoading } = this.state;

    return (
      <MainWrapper>
        <ChangesWarning>
          <BellIcon />
          <span>{t("Changes to the Password strength will only affect newly created password")}</span>
        </ChangesWarning>
        <RowTitle>{t("Password Strength")}</RowTitle>
        <Label>
          <input
            name="password-strength"
            type="radio"
            checked={passwordStrength === PasswordStrength.weak}
            onChange={(): void => this.onChange(PasswordStrength.weak)}
          />
          <LabelContent>
            <LabelText>{t("Simple")}</LabelText>
            <LabelDescription>{t("simple-password-description")}</LabelDescription>
          </LabelContent>
        </Label>
        <Label>
          <input
            name="password-strength"
            type="radio"
            checked={passwordStrength === PasswordStrength.medium}
            onChange={(): void => this.onChange(PasswordStrength.medium)}
          />
          <LabelContent>
            <LabelText>{t("Medium")}</LabelText>
            <LabelDescription>{t("medium-password-description")}</LabelDescription>
          </LabelContent>
        </Label>
        <Label>
          <input
            name="password-strength"
            type="radio"
            checked={passwordStrength === PasswordStrength.strong}
            onChange={(): void => this.onChange(PasswordStrength.strong)}
          />
          <LabelContent>
            <LabelText>{t("Strong")}</LabelText>
            <LabelDescription>{t("strong-password-description")}</LabelDescription>
          </LabelContent>
        </Label>

        {!isLoading ? (
          <ButtonWrapper>
            <Button
              loading={isLoading}
              value={t("common|Save")}
              state="primary"
              onClick={this.changePasswordStrength}
            />
          </ButtonWrapper>
        ) : null}
      </MainWrapper>
    );
  }
}

export default withTranslation(["company-security"])(PasswordTab);
