import { PropsWithChildren, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import cx from "classnames";
import * as Icons from "./menu-svg-icons";
import { MenuItem } from "./NavigationMenu";

const Wrapper = styled.div`
  .MenuItem {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 0 6px;
    font-size: var(--typography-font-size-default);
    white-space: nowrap;
    border-radius: var(--shapes-border-radius-default);
    cursor: pointer;
    user-select: none;

    color: var(--colors-theme-nm-section-item-color);
    background: transparent;

    path {
      fill: var(--colors-theme-nm-section-item-svg);
    }

    &:hover {
      background: var(--colors-theme-nm-section-item-bg-hvr);

      .sub-menu {
        display: block;
      }
    }

    &.active {
      color: var(--colors-primary-500-p);
      background: rgba(30, 151, 247, 0.12);
      path {
        fill: var(--colors-primary-500-p);
      }
      &:hover {
        background: rgba(30, 151, 247, 0.12);
      }
    }

    svg {
      flex-shrink: 0;
    }

    span {
      width: 140px;
      opacity: 1;
      transition: opacity 300ms ease-out;
    }

    &.collapsed {
      span {
        width: inherit;
        opacity: 0;
      }
    }

    .arrow {
      width: 20px;
      height: 20px;
      transform: rotate(270deg);

      path {
        fill: var(--colors-theme-nm-section-item-arrow-svg);
      }
    }

    &.is-rtl {
      .arrow {
        transform: rotate(90deg);
      }
    }
  }
`;

// TODO think how to avoid overflow-x auto
const SubMenu = styled.div<{ $top: number }>`
  display: none;
  position: fixed;
  inset-inline-start: 205px;
  top: ${(p) => p.$top - 8}px;
  z-index: 15;
  padding-inline-start: 25px;

  & > div {
    min-width: 200px;
    max-height: 360px;
    border-radius: var(--shapes-border-radius-default);
    border: 1px solid var(--colors-surface-150);
    background: transparent;
    box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
    background: var(--colors-surface-0);
    overflow-y: overlay;
  }
`;

const ItemText = ({ itemName }: { itemName: string }) => {
  const [t] = useTranslation(TranslationNamespaces.sidebar);

  const translatedName =
    itemName === "People"
      ? translateEmployeeTerm(t, TranslationNamespaces.common, "custom-employees", "People")
      : t(itemName);

  return <span>{translatedName}</span>;
};

const ItemSubMenu = ({
  parentId,
  className,
  children,
}: { parentId: string; className: string } & PropsWithChildren) => {
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const parent = document.getElementById(parentId);

      if (parent) {
        const rect = parent.getBoundingClientRect();

        setTop(rect.top);
      }
    }, 150);
  }, []);

  return (
    <SubMenu className={className} $top={top}>
      <div className="custom-scroll-on-hover">{children}</div>
    </SubMenu>
  );
};

type NavigationMenuProps = {
  isCollapsed: boolean;
  path: string;
  item: MenuItem;
};

const NavigationMenuItem = ({ item, isCollapsed, path }: NavigationMenuProps) => {
  const { pathname } = useLocation();
  const id = `item-${item.name.toLowerCase().replace(" ", "-")}`;

  return (
    <Wrapper>
      {item.subMenu ? (
        <div
          className={cx({
            MenuItem: true,
            active: item.pathRegex.test(pathname),
            collapsed: isCollapsed,
            "is-rtl": window.global_store.isRTL,
          })}
          id={id}
          key={id}
        >
          {item.icon}
          <ItemText itemName={item.name} />

          {item.subMenu && (
            <>
              <Icons.ArrowDown className="arrow" />
              <ItemSubMenu parentId={id} className="sub-menu">
                {item.subMenu}
              </ItemSubMenu>
            </>
          )}
        </div>
      ) : (
        <Link
          className={cx({
            MenuItem: true,
            active: item.pathRegex.test(pathname),
            collapsed: isCollapsed,
            "is-rtl": window.global_store.isRTL,
          })}
          id={id}
          key={id}
          to={item.subMenu ? "#" : path}
        >
          {item.icon}
          <ItemText itemName={item.name} />
        </Link>
      )}
    </Wrapper>
  );
};

export default NavigationMenuItem;
