import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import ApproveDeclineActionBar from "components/ApproveDeclineActionBar";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ActivityStatuses } from "types/models/activity";

interface ActivitiesActionsBarProps {
  selectedActivityUuids: string[];
  onUncheckAll: () => void;
  onDone: (v: { notification: string; notificationType: NotificationType.error | NotificationType.success }) => void;
  onConfirm: (s: ActivityStatuses.approved | ActivityStatuses.declined) => void;
}

function ActivitiesActionsBar({ selectedActivityUuids, onDone, onUncheckAll, onConfirm }: ActivitiesActionsBarProps) {
  const { t } = useTranslation(TranslationNamespaces.activities);

  const [isShownApproveConfirmation, setIsShownApproveConfirmation] = useState<boolean>(false);
  const [isShownDeclineConfirmation, setIsShownDeclineConfirmation] = useState<boolean>(false);
  const toggleIsShownApproveConfirmation = useCallback(
    () => setIsShownApproveConfirmation(!isShownApproveConfirmation),
    [isShownApproveConfirmation],
  );
  const toggleIsShownDeclineConfirmation = useCallback(
    () => setIsShownDeclineConfirmation(!isShownDeclineConfirmation),
    [isShownDeclineConfirmation],
  );

  if (!selectedActivityUuids.length) {
    return null;
  }

  return (
    <>
      <ApproveDeclineActionBar
        onUncheckAll={onUncheckAll}
        selectedItems={selectedActivityUuids}
        onApprove={toggleIsShownApproveConfirmation}
        onDelcine={toggleIsShownDeclineConfirmation}
      />

      <ModalDialog isOpen={isShownDeclineConfirmation} onClose={toggleIsShownDeclineConfirmation}>
        <Lightbox
          title={t("decline-multiple-activities-title")}
          text={t("decline-multiple-activities-description")}
          buttonYesTitle={t("common|Confirm")}
          buttonCancelTitle={t("common|Cancel")}
          onClose={toggleIsShownDeclineConfirmation}
          onYes={async () => {
            try {
              await onConfirm(ActivityStatuses.declined);
              onDone({ notification: t("decline-all-success"), notificationType: NotificationType.success });
            } catch (error) {
              onDone({ notification: t("decline-all-failed"), notificationType: NotificationType.error });
            }
          }}
        />
      </ModalDialog>
      <ModalDialog isOpen={isShownApproveConfirmation} onClose={toggleIsShownApproveConfirmation}>
        <Lightbox
          title={t("approve-multiple-activities-title")}
          text={t("approve-multiple-activities-description")}
          buttonYesTitle={t("common|Confirm")}
          buttonCancelTitle={t("common|Cancel")}
          onClose={toggleIsShownApproveConfirmation}
          onYes={async () => {
            try {
              await onConfirm(ActivityStatuses.approved);
              onDone({ notification: t("approve-all-success"), notificationType: NotificationType.success });
            } catch (error) {
              onDone({ notification: t("approve-all-failed"), notificationType: NotificationType.error });
            }
          }}
        />
      </ModalDialog>
    </>
  );
}

export default ActivitiesActionsBar;
