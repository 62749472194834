import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { TimesheetDetails, TimesheetIndex } from "./pages";
import TimesheetProvider from "./timesheets.context";

export const RouteTimesheets = ({ match: { path }, location: { search } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <TimesheetProvider>
      <Switch>
        <Route
          strict
          path={`${path}/details/:timesheetUuid/`}
          render={() => <Redirect to={`${path}/details/:timesheetUuid`} />}
        />
        <Route exact path={`${path}/details/:timesheetUuid`} render={(props) => <TimesheetDetails />} />
        <Redirect exact strict from={`${path}`} to={`${path}/time`} />
        <Route path={`${path}`} render={() => <TimesheetIndex />} />
      </Switch>
    </TimesheetProvider>
  </Suspense>
);
