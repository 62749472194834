import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { validateMultiplePunches, declineMultiplePunches } from "utils/apiHelpers";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import ApproveDeclineActionBar from "components/ApproveDeclineActionBar";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import ga, { GaApprovePunchButtonLocation } from "utils/ga";

interface PunchesActionBarProps extends WithTranslation {
  selectedPunches: unknown[];
  onDone: (data: { notification: string; notificationType: NotificationType }) => void;
  onUncheckAll: () => void;
}

interface PunchesActionBarState {
  showApproveConfirmationPopup: boolean;
  showDeclineConfirmationPopup: boolean;
}

class ActionsBar extends Component<PunchesActionBarProps, PunchesActionBarState> {
  readonly state: Readonly<PunchesActionBarState> = {
    showApproveConfirmationPopup: false,
    showDeclineConfirmationPopup: false,
  };

  declineMultiplePunches = async () => {
    const { t, selectedPunches, onDone } = this.props;

    try {
      await declineMultiplePunches({
        body: {
          uuids: selectedPunches,
        },
      });
      onDone({ notification: t("decline-all-success"), notificationType: NotificationType.success });
    } catch (error) {
      onDone({ notification: t("decline-all-failed"), notificationType: NotificationType.error });
    }
  };

  approveMultiplePunches = async () => {
    const { t, selectedPunches, onDone } = this.props;

    ga.trackApprovePunch({
      punch_type: "multiple types",
      button_location: GaApprovePunchButtonLocation.massActions,
    });

    try {
      await validateMultiplePunches({
        body: {
          uuids: selectedPunches,
        },
      });
      onDone({ notification: t("approve-all-success"), notificationType: NotificationType.success });
    } catch (error) {
      onDone({ notification: t("approve-all-failed"), notificationType: NotificationType.error });
    }
  };

  toggleApproveConfirmationPopup = () =>
    this.setState({ showApproveConfirmationPopup: !this.state.showApproveConfirmationPopup });

  toggleDeclineConfirmationPopup = () =>
    this.setState({ showDeclineConfirmationPopup: !this.state.showDeclineConfirmationPopup });

  render = () => {
    const { t, onUncheckAll, selectedPunches } = this.props;
    const { showApproveConfirmationPopup, showDeclineConfirmationPopup } = this.state;
    if (!selectedPunches.length) {
      return null;
    }

    return (
      <>
        <ApproveDeclineActionBar
          onUncheckAll={onUncheckAll}
          selectedItems={selectedPunches}
          onApprove={this.toggleApproveConfirmationPopup}
          onDelcine={this.toggleDeclineConfirmationPopup}
        />

        <ModalDialog isOpen={showDeclineConfirmationPopup} onClose={this.toggleDeclineConfirmationPopup}>
          <Lightbox
            title={t("decline-multiple-activity-title")}
            text={t("decline-multiple-activity-description")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={this.toggleDeclineConfirmationPopup}
            onYes={this.declineMultiplePunches}
          />
        </ModalDialog>
        <ModalDialog isOpen={showApproveConfirmationPopup} onClose={this.toggleApproveConfirmationPopup}>
          <Lightbox
            title={t("approve-multiple-activity-title")}
            text={t("approve-multiple-activity-description")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={this.toggleApproveConfirmationPopup}
            onYes={this.approveMultiplePunches}
          />
        </ModalDialog>
      </>
    );
  };
}

export default withTranslation(TranslationNamespaces.punchesPage)(ActionsBar);
