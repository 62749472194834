import { stylesheet } from "astroturf";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { AttachmentsProps } from "components/Requests/Attachments";
import { useCallback, useMemo, useState, useEffect } from "react";
import moment from "moment-timezone";
import { useDialog } from "utils/useDialog";
import { useProfile } from "context/user-profile-context";
import cx from "classnames";
import { Button, ButtonType } from "../Button";
import { Modal } from "../Modal";
import { ActivityDuration } from "../ActivityDuration";
import { ActivityDetails, ActivityDetailsProps } from "../ActivityDetails";
import { LocationSelector, LocationSelectorProps } from "../ActivityLocationSelector";
import { DateSelector } from "../DateSelector";
import { ActivityTaskCreate } from "../ActivityTaskCreate";
import { ActivityTaskSelectorWithCreate, ActivityTaskSelectorWithCreateProps } from "../ActivityTaskSelectorWithCreate";
import { DeleteActivityDialog, ExitWithoutSaveDialog } from "../ModalDialog";

export const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--colors-surface-0);

  > * {
    flex-shrink: 0;
  }

  .Body {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    width: 100%;
    overflow-y: overlay;
    height: 100%;

    .ButtonWrapper {
      width: 100%;

      &.UpdateButton,&.CreateButton {
        position: sticky;
        bottom: 0;
        padding: 8px 40px 24px;
        margin-top: auto;

        button {
          color: var(--colors-surface-0);
          background: var(--colors-primary-500-p);
        }
      }

      &.DeleteButton,&.DiscardButton {
        display: flex;
        justify-content: center;
        padding: 8px 40px;

        button {
          width: auto;
          min-width: 150px;
          color: var(--colors-surface-800);
          background: none;
        }
      }

      &.DeleteButton {
        button {
          color: var(--colors-danger-500);
        }
      }
    }
  }

  .Modal {
    & > * {
      height: 100%;

      & > * {
        height: 100%;
      }
    }
  }
  .Warning {
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    height: 32px;
    background: var(--colors-primary-50);
    align-self: stretch;
    text-align: center;
    line-height: 32px;
    color: var(--colors-primary-500-p);
  }
}
`;

export type Activity = {
  /** YYYY-MM-DD */
  date: string;
  startTime: string;
  endTime?: string;
  duration: string;
  client?: string;
  task?: string;
  project?: string;
  location?: string;
  customFields: ActivityDetailsProps["customFields"];
  attachments: AttachmentsProps["attachments"];
  stopReason: string | null;

  durationSeconds?: string;
  isRunning: boolean;
};

export type ActivityEditViewProps = Omit<React.HTMLProps<HTMLDivElement>, "duration" | "onDurationChange"> & {
  activity: Activity;
  today: string;
  duration: string;
  durationSeconds: string;
  onDateChange(date: string | null): void;
  isDateOutsideRange(date: string): boolean;
  getUnavailableDates?(month: string): Promise<string[]>;
  onCustomFieldChange(uuid: string, value: string): void;
  uploadAttachment?: ActivityDetailsProps["uploadAttachment"];
  removeAttachment?: ActivityDetailsProps["removeAttachment"];
  onAttachmentsChange?: ActivityDetailsProps["onAttachmentsChange"];
  onStartTimeChange(start: string): void;
  onEndTimeChange(end: string): void;
  onDurationChange(duration: string): void;

  taskUuid?: string;
  projectUuid?: string;
  projects: ActivityTaskSelectorWithCreateProps["projects"];
  onTaskChange(
    prj: ActivityTaskSelectorWithCreateProps["projects"][0] | null,
    tsk: ActivityTaskSelectorWithCreateProps["projects"][0]["tasks"][0] | null,
  ): void;

  locationUuid?: string;
  locations: {
    uuid: string;
    name: string;
    address: string;
  }[];
  onLocationChange(loc: LocationSelectorProps["locations"][0] | null): void;
  onAddTask(newTask: { name: string; projectUuid: string }, shouldAddProj?: boolean): void;
  warning?: React.ReactElement | string | null;

  isEditable: boolean;
  isNew: boolean;
  isTouched: boolean;
  onBackClick?(): void;
  onStopClick(): void;
  onSaveClick(): void;
  onDeleteClick(): void;
  validate(forbidNoTask?: boolean): boolean;
  errors?: ActivityDetailsProps["errors"];
  loading?: boolean;
  doSkipDetails: boolean;
};

export function ActivityEditView(props: ActivityEditViewProps) {
  const {
    activity,
    today,
    duration,
    durationSeconds,
    onDateChange,
    isDateOutsideRange,
    getUnavailableDates,
    onCustomFieldChange,
    uploadAttachment,
    removeAttachment,
    onAttachmentsChange,
    onStartTimeChange,
    onEndTimeChange,
    onDurationChange,

    taskUuid,
    projectUuid,
    projects,
    onTaskChange,
    warning,
    locationUuid,
    locations,
    onLocationChange,
    onAddTask,

    isNew,
    isTouched,
    isEditable,
    onBackClick,
    onStopClick,
    onSaveClick,
    onDeleteClick,
    validate,
    errors,
    loading,
    doSkipDetails,
    ...restProps
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [showTaskSelector, setShowTaskSelector] = useState(false);
  const [showTaskCreate, setShowTaskCreate] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);

  useEffect(() => {
    if (doSkipDetails) {
      setShowTaskSelector(true);
    }
  }, [doSkipDetails]);

  const profile = useProfile()!;
  const canAddTask = profile?.permission_roles?.some((prole) => prole.name === "Owner");
  const shouldSkipTaskSelector = canAddTask && projects.length === 0;

  const dateName = useMemo(() => {
    const yesterday = moment(today, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD");
    if (activity.date === today) return t("Today");
    if (activity.date === yesterday) return t("Yesterday");
    const long = moment(activity.date, "YYYY-MM-DD").format("l");
    const year = activity.date.slice(0, 4);
    const isSameYear = today.slice(0, 4) === year;
    if (isSameYear) {
      const re = new RegExp(`.${year}`);
      const short = long.replace(re, "");
      return short;
    }
    return long;
  }, [activity.date, today, t]);

  const [showingExitWithoutSave, askExitWithoutSave, respondExitWithoutSave] = useDialog<boolean>();
  const handleBack = useCallback(async () => {
    if (isTouched) {
      const confirmed = await askExitWithoutSave();
      if (!confirmed) return;
    }
    onBackClick!();
  }, [isTouched, onBackClick, askExitWithoutSave]);

  const [showingConfirmDelete, askConfirmDelete, respondConfirmDelete] = useDialog<boolean>();
  const handleDelete = useCallback(async () => {
    const confirmed = await askConfirmDelete();
    if (!confirmed) return;
    onDeleteClick();
  }, [onDeleteClick, askConfirmDelete]);

  const handleSave = useCallback(() => {
    const valid = validate();
    if (valid) onSaveClick();
  }, [onSaveClick, validate]);
  const handleStop = useCallback(() => {
    const valid = validate(true);
    if (valid) onStopClick();
  }, [onStopClick, validate]);

  return (
    <div className={styles.Wrapper} {...restProps}>
      <div className={styles.Body}>
        <ActivityDuration
          onBackClick={onBackClick != null ? handleBack : undefined}
          onStopClick={handleStop}
          date={dateName}
          onDateClick={() => setShowDateSelector(true)}
          startTime={activity.startTime}
          endTime={activity.endTime}
          duration={activity.isRunning ? duration : activity.duration}
          durationSeconds={activity.isRunning ? durationSeconds : activity.durationSeconds || ""}
          isRunning={activity.isRunning}
          onStartTimeChange={onStartTimeChange}
          onEndTimeChange={onEndTimeChange}
          onDurationChange={onDurationChange}
          edit={isEditable}
          isNew={isNew}
          loading={loading}
          stopReason={activity.stopReason}
        />
        {warning && <div className={styles.Warning}>{warning}</div>}
        <ActivityDetails
          task={activity.task}
          project={activity.project}
          client={activity.client}
          onTaskSelect={() => (shouldSkipTaskSelector ? setShowTaskCreate(true) : setShowTaskSelector(true))}
          location={activity.location}
          onLocationSelect={() => activity.task != null && setShowLocationSelector(true)}
          customFields={activity.customFields}
          onCustomFieldChange={onCustomFieldChange}
          attachments={activity.attachments}
          uploadAttachment={uploadAttachment}
          removeAttachment={removeAttachment}
          onAttachmentsChange={onAttachmentsChange}
          edit={isEditable}
          errors={errors}
          isNew={isNew}
        />
        {isEditable && (
          <>
            {isNew ? (
              <>
                <div className={cx(styles.ButtonWrapper, styles.CreateButton)}>
                  <Button buttonType={ButtonType.regular} onClick={handleSave} disabled={loading}>
                    {t("Create")}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {activity.isRunning ? (
                  <div className={cx(styles.ButtonWrapper, styles.DiscardButton)}>
                    <Button buttonType={ButtonType.regular} onClick={handleDelete} disabled={loading}>
                      {t("Discard")}
                    </Button>
                  </div>
                ) : (
                  <div className={cx(styles.ButtonWrapper, styles.DeleteButton)}>
                    <Button buttonType={ButtonType.regular} onClick={handleDelete} disabled={loading}>
                      {t("Delete")}
                    </Button>
                  </div>
                )}
                <div className={cx(styles.ButtonWrapper, styles.UpdateButton)}>
                  <Button buttonType={ButtonType.regular} onClick={handleSave} disabled={loading}>
                    {t("Update")}
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showingExitWithoutSave && <ExitWithoutSaveDialog onResult={respondExitWithoutSave} />}
      {showingConfirmDelete && <DeleteActivityDialog onResult={respondConfirmDelete} />}

      {showLocationSelector && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowLocationSelector(false);
            onLocationChange(null);
          }}
        >
          <LocationSelector
            locations={locations}
            selectedLocationUuid={locationUuid}
            onChange={(loc) => {
              setShowLocationSelector(false);
              onLocationChange(loc);
            }}
            onClose={() => {
              setShowLocationSelector(false);
              onLocationChange(null);
            }}
          />
        </Modal>
      )}
      {showTaskSelector && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowTaskSelector(false);
            onTaskChange(null, null);
          }}
        >
          <ActivityTaskSelectorWithCreate
            projects={projects}
            selectedTaskUuid={taskUuid}
            onChange={(prj, tsk) => {
              setShowTaskSelector(false);
              onTaskChange(prj, tsk);
            }}
            onClose={() => {
              setShowTaskSelector(false);
              onTaskChange(null, null);
            }}
            onAddTask={(tsk) => {
              setShowTaskSelector(false);
              onAddTask(tsk);
            }}
          />
        </Modal>
      )}
      {showTaskCreate && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowTaskCreate(false);
          }}
        >
          <ActivityTaskCreate
            name=""
            project={undefined}
            projects={null}
            onClose={() => {
              setShowTaskCreate(false);
            }}
            onCreate={(newTask) => {
              setShowTaskCreate(false);
              onAddTask(newTask, true);
            }}
          />
        </Modal>
      )}
      {showDateSelector && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowDateSelector(false);
            onDateChange(null);
          }}
        >
          <DateSelector
            date={activity.date}
            isOutsideRange={isDateOutsideRange}
            getUnavailableDates={getUnavailableDates}
            onChange={(d) => {
              setShowDateSelector(false);
              onDateChange(d);
            }}
            onClose={() => {
              setShowDateSelector(false);
              onDateChange(null);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
