import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import { SelectOption } from "types/ui";
import { ActivitiesReportByLocation } from "types/models/projects";
import {
  getEmployeeNameColumn,
  getDateColumn,
  getDateCellData,
  iDateCellData,
  getMinsToHoursDecimal,
} from "utils/reportsHelpers";
import { iColumn } from "components/TableCommon";
import { OnError, SetIsLoading } from "types/reports";
import {
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
  minsToHrsMins,
  hasPermisionAccess,
  getValueWithCurrency,
} from "utils/common";
import { EmployeeTaxPayerTypes } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TableDetailsTitle, TableDetailsTitleDates } from "./reportsStyledComponents";
import ReportsDetailedServicesSummary, { ServicesSummary } from "./ReportsDetailedServicesSummary";
import { ReportActvitiesStatus } from "./helpers";
import ReportsNoContent from "./ReportsNoContent";

interface ReportsActivitiesByLocationAllEmployeesProps extends RouteComponentProps, WithTranslation {
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedLocation: SelectOption | null;
  setIsLoading: SetIsLoading;
  onError: OnError;
  showInactiveEmployees: boolean;
  showServicesSummary: boolean;
  activityStatus: ReportActvitiesStatus;
}

type ProjectActivityRow = {
  fullName: string;
  cpf: string;
  startTime: string;
  endTime: string;
  matricula: string;
  date: iDateCellData;
  clientName: string;
  serviceName: string;
  taskName: string;
  projectName: string;
  projectId: string;
  locationName: string;
  duration: number;
  durationDecaimal: string;
  customFieldsMap: {
    [key: string]: {
      name: string;
      value: string | number;
      fieldType: "numeric" | "input";
    };
  };
  employeeCost: string;
};

type ReportMetadata = {
  totalMinutes: number;
  totalCost: string;
  totalEmployeeCost: string;
  customFieldsMap: {
    [key: string]: {
      name: string;
      value: string | number;
    };
  };
  servicesSummary: ServicesSummary | null;
};

interface ReportsActivitiesByLocationAllEmployeesState {
  loading: boolean;
  activities: ProjectActivityRow[];
  metadata: ReportMetadata | null;
  selectedColumns: string;
}

const mapActivityTableData = (inputData: ActivitiesReportByLocation[]): ProjectActivityRow[] =>
  inputData.map((row) => ({
    fullName: row.userProfile.fullName,
    cpf: row.userProfile.cpf,
    matricula: row.userProfile.matricula,
    startTime: moment(row.date).add(row.startTime, "minutes").format("HH:mm"),
    endTime: moment(row.date).add(row.endTime, "minutes").format("HH:mm"),
    date: getDateCellData(row),
    clientName: row.client.name,
    serviceName: row?.service?.name || "",
    taskName: row.task.name,
    projectName: row.project.name,
    projectId: row.project.id,
    locationName: row.location.name,
    duration: row.duration,
    durationDecimal: getMinsToHoursDecimal(row.duration || 0),
    customFieldsMap: row.customFieldsMap,
    employeeCost: getValueWithCurrency(row.employeeCost) || "0",
  }));

const defaultColumns =
  "date,name,cpf,matricula,service_name,task_name,project_name,location_name,client_name,duration,duration_decimal";
const tableName = "ReportsActivitiesByLocationAllEmployees";

class ReportsActivitiesByLocationAllEmployees extends Component<
  ReportsActivitiesByLocationAllEmployeesProps,
  ReportsActivitiesByLocationAllEmployeesState
> {
  constructor(props: ReportsActivitiesByLocationAllEmployeesProps) {
    super(props);
    this.state = {
      loading: true,
      activities: [],
      selectedColumns: getSelectedColumns(defaultColumns, tableName),
      metadata: null,
    };
  }

  componentDidMount(): void {
    this.getReportData();
  }

  getReportData = (): void => {
    const { t, startDate, endDate, selectedLocation, setIsLoading, onError, showInactiveEmployees, activityStatus } =
      this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, async () => {
        setIsLoading(true);

        try {
          const response = await getReport(
            {
              startDate,
              endDate,
              companyUUID,
              showInactiveEmploees: showInactiveEmployees,
              locationUuid: selectedLocation?.value || "",
              status: activityStatus,
              type: "location_activities",
            },
            true,
          );

          const resp = response.content;
          const data: ActivitiesReportByLocation[] = resp.dates || resp;
          const activities: ProjectActivityRow[] = mapActivityTableData(data);

          if (activities.length > 0) {
            const custFields = Object.keys(activities[0].customFieldsMap)
              .map((id) => `customFieldsMap[${id}]`)
              .join(",");
            const selectedColumns = getSelectedColumns(`${defaultColumns},${custFields}`, tableName);
            this.setState({ selectedColumns });
          }
          this.setState({
            activities,
            metadata: response?.metadata || null,
            loading: false,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log("Error", e);

          onError(t("Failed to generate report"));
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  getColumns = (): iColumn<ProjectActivityRow>[] => {
    const { t } = this.props;
    const { activities, metadata } = this.state;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns: iColumn<ProjectActivityRow>[] = [
      getDateColumn(t),
      getEmployeeNameColumn(t),
      {
        accessor: "startTime",
        rubyAccessor: "start",
        label: t("Start"),
        minWidth: 100,
      },
      {
        accessor: "endTime",
        rubyAccessor: "end",
        label: t("End"),
        minWidth: 100,
      },
      {
        accessor: "cpf",
        rubyAccessor: "cpf",
        label: employeeTaxIdLabel,
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
        minWidth: 100,
      },
      {
        accessor: "serviceName",
        rubyAccessor: "service_name",
        label: t("Service"),
        minWidth: 130,
      },
      {
        accessor: "taskName",
        rubyAccessor: "task_name",
        label: t("Task"),
        minWidth: 130,
      },
      {
        accessor: "projectName",
        rubyAccessor: "project_name",
        label: t("Project"),
        minWidth: 130,
      },
      {
        accessor: "projectId",
        rubyAccessor: "project_id",
        label: t("Project Id"),
        minWidth: 130,
      },
      {
        accessor: "locationName",
        rubyAccessor: "location_name",
        label: t("Location"),
        minWidth: 130,
      },
      {
        accessor: "clientName",
        rubyAccessor: "client_name",
        label: t("Client"),
        minWidth: 130,
      },
      {
        accessor: "duration",
        rubyAccessor: "duration",
        label: t("Hours"),
        Cell: (row: iCellInfo<ProjectActivityRow>): string => minsToHrsMins(row.value),
        Footer: metadata ? minsToHrsMins(metadata?.totalMinutes || 0) : "",
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "durationDecimal",
        rubyAccessor: "duration_decimal",
        label: t("Hours (Decimal)"),
        Footer: metadata ? getMinsToHoursDecimal(metadata?.totalMinutes || 0) : "",
        minWidth: 120,
        align: "end",
      },
    ];

    if (activities.length > 0) {
      const customFieldsColumns: iColumn<ProjectActivityRow>[] = Object.entries(activities[0].customFieldsMap).map(
        ([id, cf]) => ({
          accessor: `customFieldsMap[${id}].value`,
          rubyAccessor: `customFieldsMap[${id}]`,
          label: cf.name,
          Footer: String(metadata?.customFieldsMap[id]?.value || ""),
          align: "right",
          Cell: (row) => (
            <div
              style={{
                textAlign: row.original.customFieldsMap[id].fieldType === "numeric" ? "right" : "left",
              }}
            >
              {row.value}
            </div>
          ),
        }),
      );
      columns.push(...customFieldsColumns);
    }

    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      columns.push({
        accessor: "employeeCost",
        rubyAccessor: "employee_cost",
        label: translateEmployeeTerm(t, TranslationNamespaces.reportsPage, "custom-employee-cost", `Employee Cost`),
        Footer: getValueWithCurrency(metadata?.totalEmployeeCost),
        minWidth: 100,
        align: "end",
      });
    }

    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsActivitiesByLocationAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { loading, activities, selectedColumns, metadata } = this.state;
    const { t, selectedLocation, startDate, endDate, showInactiveEmployees, showServicesSummary, activityStatus } =
      this.props;
    const columns = this.getColumns();
    const title = `${selectedLocation?.label || ""} - ${translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    )}`;

    return (
      <div>
        <TablePage<ProjectActivityRow>
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <TableDetailsTitle>{title}</TableDetailsTitle>
              <TableDetailsTitleDates>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </TableDetailsTitleDates>
            </>
          }
          loading={loading}
          rows={activities}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              onChange={(value: string): Promise<Record<string, unknown>> => {
                const cols = selectedColumns.split(",");
                const cfRe = /^customFieldsMap\[(.*)\]$/;
                const regularColumns = cols.filter((c) => c.match(cfRe) == null).join(",");
                const customFields = cols
                  .map((c) => c.match(cfRe))
                  .filter((m) => m != null)
                  .map((m) => m![1])
                  .join(",");
                return fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns: regularColumns,
                  customFields,
                  showInactiveEmploees: showInactiveEmployees,
                  showServicesSummary,
                  startDate,
                  endDate,
                  status: activityStatus,
                  locationUuid: selectedLocation?.value || "",
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "project_location_activities",
                });
              }}
            />
          }
          noContentComponent={<ReportsNoContent />}
        />
        {showServicesSummary && metadata?.servicesSummary && (
          <ReportsDetailedServicesSummary
            servicesSummary={metadata?.servicesSummary}
            currency={window.global_store.company.currency}
            summaryWithCosts
          />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsActivitiesByLocationAllEmployees));
