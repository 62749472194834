import CONFIG from "config";
import { APIResponse, sendRequest } from "./common";

export enum FlatfileActionType {
  Add = "add",
  Update = "update",
}
type FlatfileTokenPayload = {
  user: { uuid: string; email: string; name: string };
  company: { name: string };
  action: FlatfileActionType;
};

export enum JobStatus {
  Pending = "pending",
  Processing = "processing",
  Completed = "completed",
  Failed = "failed",
  WithErrors = "with-errors",
}

export type FlatfileImportJob = {
  uuid: string;
  status: JobStatus;
  companyUuid: string;
  errors: string[];
  executorName: string;
  createdAt: Date;
  fileName: string;
  type: FlatfileActionType;
};

class ImportsService {
  async getFlatfileToken(companyUuid: string, data: FlatfileTokenPayload): APIResponse<{ token: string }> {
    return sendRequest(data, "POST", `/companies/${companyUuid}/actions/flatfile-token`, CONFIG.client_admin_api);
  }

  async getImportsList(companyUuid: string): APIResponse<FlatfileImportJob[]> {
    return sendRequest({}, "GET", `/companies/${companyUuid}/actions/list`, CONFIG.client_admin_api);
  }
}

export default new ImportsService();
