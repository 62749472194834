import { Component, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import "./Overlay.scss";

const OverlayWrapper = styled.div<{ $dark?: boolean }>`
  position: fixed;
  z-index: 20;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  background-color: ${(p) => (p.$dark ? "rgba(0, 0, 0, 0.70)" : "rgb(255 255 255 / 93%)")};

  @media print {
    & {
      background-color: rgb(255 255 255 / 100%);
    }
  }
`;

interface OverlayProps extends PropsWithChildren {
  onClose?: () => void;
  offsetLeft: number;
  isOpen: boolean;
  dark?: boolean;
  style: CSSStyleSheet;
}

interface OverlayState {
  closable: boolean;
}

class Overlay extends Component<OverlayProps, OverlayState> {
  portalNode: HTMLDivElement;
  overlayNode: HTMLDivElement | null;

  static defaultProps = {
    style: {},
  };

  readonly state: Readonly<OverlayState> = { closable: false };

  constructor(props: OverlayProps) {
    super(props);

    this.overlayNode = null;

    this.portalNode = document.body.appendChild(document.createElement("div"));
    this.portalNode.classList.add("overlay-portal");
    document.addEventListener("keyup", this.handleKeyPress);

    this.state = { closable: false };
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyPress);

    this.portalNode?.remove();
  }

  handleKeyPress = (ev: KeyboardEvent) => {
    const { onClose } = this.props;

    if (ev.keyCode === 27) {
      onClose && onClose();
    }
  };

  render() {
    const { offsetLeft, children, onClose, isOpen, dark, style } = this.props;
    const { closable } = this.state;

    if (!isOpen) {
      return null;
    }

    return createPortal(
      <OverlayWrapper
        $dark={dark}
        className="overlay"
        // eslint-disable-next-line no-return-assign
        ref={(el) => (this.overlayNode = el)}
        onMouseUp={(e) => {
          if (closable && this.overlayNode === e.target && typeof onClose === "function") {
            this.setState({ closable: false }, onClose);
          }
        }}
        onMouseDown={(e) => {
          if (this.overlayNode === e.target && typeof onClose === "function") {
            this.setState({ closable: true });
          }
        }}
        style={{
          left: offsetLeft || 0,
          width: offsetLeft ? `calc(100% - ${offsetLeft}px)` : undefined,
          ...style,
        }}
      >
        {children}
      </OverlayWrapper>,
      this.portalNode,
    );
  }
}

export default Overlay;
