import styled from "styled-components";
// eslint-disable-next-line import/no-unresolved
import { CellInfo, RowInfo, ComponentPropsGetterR } from "react-table-v6";

export const LoaderWrapper = styled.div`
  display: inline-block;
  width: 70%;
  white-space: nowrap;
  margin: 6px 0;
`;

export const LoaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Loader = styled.div<{ avatar?: boolean }>`
  ${(props) =>
    props.avatar
      ? `
      height: 36px;
      width: 36px;
      border-radius: 100px;
      margin-inline-end: 15px;
    `
      : `
      height: 12px;
      flex: 1;
      margin: 5px 0;
    `}
`;

// get columns from the localstorage or use default
export function getSelectedColumns(defaultColumns: string, tableName: string): string {
  if (!localStorage.getItem("customColumns_ver") || localStorage.getItem("customColumns_ver") !== "3") {
    localStorage.removeItem(`customColumns_${tableName}`);
    localStorage.setItem("customColumns_ver", "3");
  }

  let columns = defaultColumns;

  const storedColumns = localStorage.getItem(`customColumns_${tableName}`);
  if (storedColumns) {
    columns = storedColumns;
  }

  return columns;
}

export class TableHelper {
  constructor(protected tableName: string) {}

  getSelectedColumns(defaultColumns: string[]): string[] {
    return getSelectedColumns(defaultColumns.join(","), this.tableName).split(",");
  }
  setSelectedColumns(selectedColumns: string[]) {
    localStorage.setItem(`customColumns_${this.tableName}`, selectedColumns.join());
  }
}

// TODO =interface=
interface iProps {
  oldReport: boolean;
}

// TODO =type=
type tRowStatus = "completed";
export interface iRow<O = Record<string, unknown>, V = any> {
  original: O;
  value: V;
  _original: {
    status: tRowStatus;
    trHeading: string | boolean; // TODO ?
  };
}

export interface iRowInfo<T> extends RowInfo {
  original: T;
}

export interface iCellInfo<T, V = any> extends CellInfo {
  original: T;
  value: V;
}

export function getTrPropsClassName<T>(
  props: iProps,
  rowInfo: iRowInfo<T> | undefined,
  skipStatusCheck: boolean,
): string {
  const classes = [];

  if (!rowInfo) {
    return "";
  }

  // eslint-disable-next-line no-underscore-dangle
  const rowOriginal = rowInfo.row._original || {};

  if (!skipStatusCheck && !props.oldReport && rowOriginal.status !== "completed") {
    classes.push("row-incomplete");
    classes.push(`row-incomplete_${rowOriginal.status}`);
  }

  if (rowOriginal.trHeading) {
    classes.push("with-tr-heading");
  }

  return classes.join(" ");
}
