import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { useEffect, useMemo, useState } from "react";
import { normalizeString } from "components/views/Select";
import { filterNested } from "components/views/Select/filter-nested";
import ClickOutside from "react-click-outside";
import { SearchBar } from "components/Activities/TimeTracker/components/SearchBar";
import TextInputControl from "components/controls/TextInputControl";
import styled from "styled-components";
import { PayrollEventType } from "types/models/payroll/payrollLayout";
import { OverlayDropdown } from "./OverlayDropdown";
import { OptionsList } from "./OptionsList";
import { getGroupedDropdownOptions } from "./helpers";

const Wrapper = styled.div`
  position: relative;
`;

type GroupedDropdownProps = {
  rawEventTypes: PayrollEventType[];
  value: string | null;
  onChange: (value: string) => void;
};

export type GroupedSubOption = {
  id: string;
  label: string;
};
export type GroupedOption = {
  id: string;
  label: string;
  subOptions: GroupedSubOption[] | null;
};

const GroupedDropdown = ({ value, rawEventTypes, onChange }: GroupedDropdownProps) => {
  const { t } = useTranslation([
    TranslationNamespaces.payroll,
    TranslationNamespaces.phases,
    TranslationNamespaces.requestsPageTmp,
  ]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<GroupedOption | null>(null);
  const [selectedSubOption, setSelectedSubOption] = useState<GroupedSubOption | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const items = useMemo(() => getGroupedDropdownOptions(rawEventTypes, t), [rawEventTypes, searchValue]);

  const filteredOptions = useMemo(() => {
    const str = normalizeString(searchValue);
    if (str === "") return items;
    const predicate = (option: GroupedOption) => normalizeString(option.label).includes(str);
    return filterNested(items, predicate, "subOptions");
  }, [items, searchValue]);

  useEffect(() => {
    const foundOption = items.find(
      (item) => item.id === value || item.subOptions?.find((subItem) => subItem.id === value),
    );
    if (foundOption) {
      setSelectedOption(foundOption);
      if (foundOption.id !== value) {
        setSelectedSubOption(foundOption.subOptions?.find((subItem) => subItem.id === value) || null);
      }
    }
  }, [value]);

  return (
    <Wrapper>
      <div onClick={() => setDropdownVisible(true)}>
        <TextInputControl value={selectedSubOption?.label || selectedOption?.label || ""} />
      </div>
      {dropdownVisible && (
        <ClickOutside
          onClickOutside={() => {
            setDropdownVisible(false);
          }}
        >
          <OverlayDropdown
            header={
              <SearchBar
                placeholder={t(`${TranslationNamespaces.common}|Search`)}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            }
            content={
              <OptionsList
                onChange={(id) => {
                  setDropdownVisible(false);
                  onChange(id);
                }}
                searchValue={searchValue}
                options={filteredOptions}
                selectedOption={selectedOption}
                selectedSubOption={selectedSubOption}
              />
            }
          />
        </ClickOutside>
      )}
    </Wrapper>
  );
};

export default GroupedDropdown;
