import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AddEmployeeRow from "components/controls/AddEmployeeRow";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import SearchInput from "components/UI/SearchInput";
import TableButtonsControl from "components/styled/TableButtonsControl";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import "styles/company-rules.scss";
import styled from "styled-components";
import * as moment from "moment";
import TableUserProfile from "components/styled/TableUserProfile";
import * as images from "components/svg-images";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import FieldWrapper from "components/UI/FieldWrapper";
import { strIncludesCheck } from "utils/common";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { MassActionLocations } from "utils/ga";
import GlobalContext from "../../context/global-context";
import { assignUserProfiles, unassignUserProfiles } from "./holidayApiUtils";

const TableWrapper = styled.div`
  margin-top: 32px;
`;
const LightboxText = styled.div`
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #a1b2cf;
`;
const AddEmployeesBtn = styled.div`
  border: 1px solid var(--colors-surface-600);
  border-radius: var(--shapes-border-radius-default);
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-600);
  line-height: 34px;
  vertical-align: middle;
  padding: 0 20px;
  cursor: pointer;
  max-width: 187px;
  white-space: nowrap;
  svg {
    margin-inline-end: 10px;
    vertical-align: middle;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class HolidayGroupTabEmployees extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      addEmployeeRowVisible: false,
      confirmationPopupVisible: false,
      filterStr: "",
      removeDate: moment(),
      employees: [],
    };
  }

  onAddEmployees = async (input, startDate) => {
    const { holidaysGroupUuid } = this.props;

    let userProfileUuids = [];
    if (!Array.isArray(input)) {
      userProfileUuids = [input.uuid];
    } else {
      userProfileUuids = input;
    }
    this.setState({ inProgress: true });
    try {
      await assignUserProfiles({
        companyUuid: window.global_store.company.uuid,
        holidaysGroupUuid,
        body: {
          content: {
            startDate: startDate.format("YYYY-MM-DD"),
            userProfileUuids,
            createdBy: window.global_store.profile.uuid,
          },
        },
      });
      this.setState({ inProgress: false });
      this.props.onUpdate({ message: "You assigned employees", type: "success" });
    } catch (error) {
      this.setState({ inProgress: false });
      if (error && error.status === 500) {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      } else {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      }
    }
  };
  removeItem = async (userProfileUuid) => {
    const { holidaysGroupUuid } = this.props;
    const { removeDate } = this.state;

    try {
      await unassignUserProfiles({
        companyUuid: window.global_store.company.uuid,
        holidaysGroupUuid,
        body: {
          content: {
            endDate: removeDate.format("YYYY-MM-DD"),
            userProfileUuids: [userProfileUuid],
            updatedBy: window.global_store.profile.uuid,
          },
        },
      });
      this.props.onUpdate({ message: "You unassigned employees", type: "success" });
    } catch (error) {
      if (error && error.status === 500) {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      } else {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      }
    }
    this.setState({ confirmationPopupVisible: false });
  };
  toggleAddEmployeeRow(visible) {
    this.setState({ addEmployeeRowVisible: visible });
  }
  getTableColumns() {
    const { t, isDefault, holidaysGroupStatus } = this.props;
    const columns = [
      {
        Header: t("common|Employees"),
        accessor: "uuid",
        sortable: false,
        resizable: false,
        Cell: (row) => <TableUserProfile {...row.original} />,
        minWidth: 178,
      },
      {
        label: t("common|Department"),
        Cell: (row) => (row.original.department ? row.original.department.name : ""),
        accessor: "department",
        width: 160,
      },
      {
        label: t("common|Start Date"),
        accessor: "assigmentDate",
        Cell: (row) => (
          <div>
            {row.value ? moment(row.value).format("DD/MM/YYYY") : ""}
            {!isDefault && holidaysGroupStatus === "active" ? (
              <TableButtonsControl
                visibleButtons={[
                  {
                    label: t("common|Remove"),
                    onClick: () => this.setState({ selectedItem: row.original.uuid, confirmationPopupVisible: true }),
                  },
                ]}
              />
            ) : null}
          </div>
        ),
        width: 160,
      },
    ];
    return columns;
  }
  filterEmployees = (ev) => {
    this.setState({ filterStr: ev.target.value });
  };
  render() {
    const {
      employees = [],
      confirmationPopupVisible,
      selectedItem,
      filterStr,
      removeDate,
      addEmployeeRowVisible,
      inProgress,
    } = this.state;

    const { t, userProfiles, loading, holidaysGroupStatus } = this.props;
    const filteredItems = userProfiles.filter((s) => strIncludesCheck(s.fullName, filterStr));
    return (
      <div>
        <br />
        {addEmployeeRowVisible ? (
          <>
            <AddEmployeeRow
              permissionSection={PermissionSectionName.holidays}
              inProgress={inProgress}
              newSearch
              trackingLocation={MassActionLocations.Holidays}
              hideRow={() => this.setState({ addEmployeeRowVisible: false })}
              onAddMultipleEmployee={(list, startDate) => this.onAddEmployees(list, startDate)}
              employees={employees.map((e) => ({ uuid: e.uuid }))}
              onAddEmployee={(employee, startDate) => this.onAddEmployees(employee, startDate)}
            />
          </>
        ) : (
          <>
            <FilterWrapper>
              {userProfiles.length > 0 && (
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.filterEmployees}
                  placeholder={translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-search-employees",
                    `${TranslationNamespaces.common}|Search Employees`,
                  )}
                  value={filterStr}
                />
              )}
              {holidaysGroupStatus === "active" && !addEmployeeRowVisible && (
                <AddEmployeesBtn onClick={() => this.toggleAddEmployeeRow(true)}>
                  {images.plusBig}
                  {t("common|Add Employess")}
                </AddEmployeesBtn>
              )}
            </FilterWrapper>
          </>
        )}
        <TableWrapper>
          <TablePage
            rows={filteredItems}
            columnSelectorOnFiltersRow
            columns={this.getTableColumns()}
            className="employees-table"
            loading={loading}
            noContentComponent={<NoContent>{t("common|Please add employees")}</NoContent>}
          />
        </TableWrapper>
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            titleMargin="25px"
            title={t("common|Remove Employee.")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ selectedItem: null, confirmationPopupVisible: false });
            }}
            onYes={this.removeItem.bind(this, selectedItem)}
          >
            <div>
              <LightboxText>
                {t(
                  "Removing the employee from the group will also remove its events from the employee’s reports.\nThis action can’t be undone.",
                )}
              </LightboxText>

              <FieldWrapper fieldName={t("Removal Date")} width="100%" fieldTitleMarginBottom={-4}>
                <SingleDatePickerControl
                  value={removeDate}
                  numberOfMonths={1}
                  onChange={(val) => this.setState({ removeDate: val })}
                  isOutsideRange={() => false}
                />
              </FieldWrapper>
            </div>
          </Lightbox>
        </ModalDialog>
      </div>
    );
  }
}
export default withTranslation("holidays")(HolidayGroupTabEmployees);
