import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import FullPage from "components/Layout/FullPage";
import { Notification, NotificationType } from "types/common";
import { createLock } from "utils/apiHelpers";
import { getTitle } from "utils/common";
import NotificationRow from "components/NotificationRow";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { PageWrapper } from "components/styled/Page";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { RouteComponentProps } from "react-router-dom";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ButtonState } from "components/controls/StyledButton";
import DeactivatedEmployeesList from "./DeactivatedEmployees";
import Locks from "./Locks";
import NewPreparationPopupMessage, { AddLock } from "./NewPreparationPopupMessage";

const title = "Lock Periods";
const metaTitle = title;

enum LockPeriodsPageTabs {
  groups,
  deactivatedEmployees,
}

interface LockPeriodsPageProps extends WithTranslation, RouteComponentProps {}

interface LockPeriodsPageState {
  notification: Notification["notification"];
  notificationType: NotificationType | null;
  activeTab: LockPeriodsPageTabs;
  newPopupVisible: boolean;
  popupUnlockedWarningVisible: boolean;
}

class LockPeriodsPage extends Component<LockPeriodsPageProps, LockPeriodsPageState> {
  constructor(props: LockPeriodsPageProps) {
    super(props);
    const { t } = this.props;

    this.state = {
      notification: null,
      notificationType: null,
      activeTab: LockPeriodsPageTabs.groups,
      newPopupVisible: false,
      popupUnlockedWarningVisible: false,
    };

    document.title = getTitle(t(metaTitle));
  }

  toggleUlockedWarningPopUp = (show: boolean): void => {
    this.setState({ popupUnlockedWarningVisible: show });
  };

  closeNewPreparationPopup = (): void => {
    this.setState({ newPopupVisible: false });
  };

  setNotification = (notification: Notification): void => {
    this.setState({ ...notification });
  };

  addLock = async ({
    payGroupUuid,
    startDate,
    endDate,
    createDigitalSignatures,
    selectedColumns,
  }: AddLock): Promise<void> => {
    const { t } = this.props;

    try {
      if (!window.global_store.company) {
        throw new Error("Create Lock: No company data");
      }

      await createLock({
        body: {
          content: {
            createDigitalSignatures,
            payrollGroupUuid: payGroupUuid,
            companyUuid: window.global_store.company.uuid,
            createdBy: window.global_store.profile.uuid,
            startDate,
            endDate,
            selectedColumns,
          },
        },
        companyUuid: window.global_store.company.uuid,
      });

      this.setNotification({ notification: `${t("You added lock")}`, notificationType: NotificationType.success });
    } catch (e) {
      const err: any = e;
      let errorMessage = t("common|Something went wrong");

      if (err.message) {
        errorMessage = t(err.message);
      }

      if (
        err.originalRequest &&
        err.originalRequest.errors &&
        err.originalRequest.errors.length &&
        err.originalRequest.errors[0].message
      ) {
        errorMessage = t(err.originalRequest.errors[0].message);
      }

      this.setNotification({
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }

    this.closeNewPreparationPopup();
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { notification, notificationType, activeTab, newPopupVisible, popupUnlockedWarningVisible } = this.state;

    return (
      <FullPage
        title={t(`${TranslationNamespaces.sidebar}|Payroll`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("New Lock")}
            onClick={(): void => this.setState({ newPopupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow
              style={{ marginBottom: "32px" }}
              employeesPage
              withCloseButton={false}
              type={notificationType}
              message={notification}
            />
          )}

          {activeTab === LockPeriodsPageTabs.groups && (
            <Locks
              setNotification={this.setNotification}
              showUlockedWarningPopUp={(): void => this.toggleUlockedWarningPopUp(true)}
            />
          )}

          {activeTab === LockPeriodsPageTabs.deactivatedEmployees && (
            <DeactivatedEmployeesList
              setNotification={this.setNotification}
              showUlockedWarningPopUp={(): void => this.toggleUlockedWarningPopUp(true)}
            />
          )}

          <ModalDialog isOpen={newPopupVisible} onClose={this.closeNewPreparationPopup}>
            <NewPreparationPopupMessage onClose={this.closeNewPreparationPopup} onYes={this.addLock} />
          </ModalDialog>

          <ModalDialog isOpen={popupUnlockedWarningVisible} onClose={(): void => this.toggleUlockedWarningPopUp(false)}>
            <Lightbox
              title={t("Period is not locked")}
              text={t("You have to lock a period for exporting")}
              buttonYesTitle="Ok"
              noCancelButton
              onClose={(): void => this.toggleUlockedWarningPopUp(false)}
              onYes={(): void => this.toggleUlockedWarningPopUp(false)}
            />
          </ModalDialog>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.payment, TranslationNamespaces.sidebar])(LockPeriodsPage);
