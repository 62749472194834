import { Component, ReactElement, ReactNode, cloneElement } from "react";
import ClickOutside from "react-click-outside";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import BEM from "utils/BEM";
import SearchInput from "components/UI/SearchInput";
import Option from "./SelectOption";
import OptionWithSubOptions, { OptionSubOption } from "./OptionWithSubOptions";
import "./Select.scss";

const b = BEM.b("ui-select");

const Wrapper = styled.div`
  .ui-select__input {
    padding: 0;
    padding-inline-start: 16px;
    padding-inline-end: 28px;
  }

  .ui-select__input::after {
    inset-inline-end: 16px;
  }

  .ui-select__option-list {
    width: 100%;
    min-width: 250px;
    max-height: 500px;
  }

  .ui-select__input_icon:after {
    inset-inline-end: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.546 4.768a.79.79 0 01-1.133-.015L.237 1.463a.863.863 0 010-1.19.791.791 0 011.148 0L3.987 2.97 6.616.246a.791.791 0 011.146 0 .863.863 0 010 1.19L4.587 4.728a.83.83 0 01-.041.04z' fill='%238193AB'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  .ui-select__input_open.ui-select__input_icon:after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.463 1.23a.79.79 0 011.133.02l3.163 3.302a.863.863 0 01-.005 1.189.791.791 0 01-1.148-.004L5.015 3.03l-2.64 2.714a.791.791 0 01-1.146-.005.863.863 0 01.004-1.19l3.188-3.28a.837.837 0 01.042-.039z' fill='%238193AB'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
`;

const SearchBlock = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  inset-inline-start: 0;
  background-color: var(--colors-default);
  margin-bottom: 10px;
`;

const SearchBackground = styled.div`
  position: absolute;
  inset-inline-start: -10px;
  inset-inline-end: -10px;
  top: -10px;
  height: 57px;
  background: var(--colors-default);
  border-bottom: 1px solid #dde5ee;
`;

const Label = styled.span`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 14px;
  color: #525f7f;
  margin-inline-start: 12px;
`;

const SelectedLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type MultiSelectWithSearchOption = {
  /** can have suboptions */
  value: string | OptionSubOption[];
  label: string;
  disabled?: boolean;
};

interface MultiSelectWithSearchProps extends WithTranslation {
  options: MultiSelectWithSearchOption[];
  /** long string that contains selected values */
  value: string[];
  onChange: (values: string[]) => void;
  label?: string;
  placeholder?: string;
  withSearch?: boolean;
  icon?: ReactNode;
  /** Fully custom element */
  customLabelElement?: ReactElement;
  lightUI?: boolean;
}

interface MultiSelectWithSearchState {
  searchValue: string;
  isOpen: boolean;
  alignDropdownRight: boolean;
}

class MultiSelectWithSearch extends Component<MultiSelectWithSearchProps, MultiSelectWithSearchState> {
  containerRef: HTMLDivElement | null = null;

  static defaultProps = {
    withSearch: true,
    value: [],
  };

  readonly state: Readonly<MultiSelectWithSearchState> = {
    searchValue: "",
    isOpen: false,
    alignDropdownRight: false,
  };

  toggleOpen = (open?: boolean) => {
    let alignDropdownRight = false;
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    if (isOpen && this.containerRef) {
      alignDropdownRight = this.containerRef.getBoundingClientRect().right + 65 > window.innerWidth;
    }

    this.setState({ isOpen, alignDropdownRight });
  };

  selectMultipleSubOptions = (subOptionsValues: string[]) => {
    let newValues;
    const { onChange, value } = this.props;

    // if all suboptions checked - uncheck all
    if (subOptionsValues.every((so) => value.indexOf(so) > -1)) {
      newValues = value.filter((v) => subOptionsValues.indexOf(v) === -1);
    } else {
      // check all
      newValues = [...value, ...subOptionsValues];
    }

    if (onChange) {
      onChange(newValues);
    }
  };

  selectValue = async (optionValue: string[] | string) => {
    let newValue: string[] = [];
    const { onChange, value, options } = this.props;

    // suboptions
    if (Array.isArray(optionValue)) {
      this.selectMultipleSubOptions(optionValue);
      return;
    }

    if (value.indexOf(optionValue) > -1) {
      newValue = value.filter((v) => v !== optionValue);
    } else {
      newValue = [...value, optionValue];
    }

    // if doesnt have suboptions
    if (!Array.isArray(options[0].value)) {
      // keep order
      newValue = options
        .map((op) => (newValue.indexOf(op.value as string) > -1 ? (op.value as string) : ""))
        .filter((op) => op);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  isActive = (optionValue: string): boolean => {
    const { value } = this.props;

    return !!(value && value.indexOf(optionValue) > -1);
  };

  getSelectedLabelString = () => {
    const { options } = this.props;
    const selectedOptions = options.filter((option) => typeof option.value === "string" && this.isActive(option.value));

    const label = selectedOptions.map((option) => option.label || option.value).join(", ");
    return label ? <SelectedLabel>{label}</SelectedLabel> : null;
  };

  renderLabel = () => {
    const { icon, placeholder, label, t } = this.props;

    return (
      <>
        {icon || this.getSelectedLabelString() || placeholder}
        {!!icon && label && <Label className={b("label")}>{label}</Label>}
      </>
    );
  };

  filterBySearch = (options: MultiSelectWithSearchOption[], searchValue: string) => {
    const filtered: MultiSelectWithSearchOption[] = [];
    const isMatching = (o: MultiSelectWithSearchOption) =>
      o.label && o.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;

    options.forEach((o) => {
      // if has sub options
      if (Array.isArray(o.value)) {
        o.value.forEach((so) => {
          if (isMatching(so)) {
            filtered.push(so);
          }
        });
      } else if (isMatching(o)) {
        filtered.push(o);
      }
    });

    return filtered;
  };

  render() {
    const { value, options, icon, t, withSearch, customLabelElement, lightUI, label } = this.props;
    const { alignDropdownRight, isOpen, searchValue } = this.state;
    const filteredItems = searchValue ? this.filterBySearch(options, searchValue) : options.filter((o) => !!o.label);

    return (
      <Wrapper
        className={b({ icon: !!icon })}
        ref={(ref) => {
          this.containerRef = ref;
        }}
      >
        <ClickOutside onClickOutside={() => this.toggleOpen(false)}>
          <div onClick={() => this.toggleOpen(true)}>
            {customLabelElement ? (
              cloneElement(customLabelElement, { isOpen })
            ) : (
              <div
                className={b("input", {
                  "only-icon": !!icon && !label,
                  icon: !!icon,
                  open: isOpen,
                  filled: !icon && !lightUI && !!value?.length,
                })}
              >
                {this.renderLabel()}
              </div>
            )}
          </div>

          {isOpen && (
            <div className={b("option-list", { icon: !!icon, right: alignDropdownRight })}>
              {withSearch && (
                <SearchBlock>
                  <SearchBackground />
                  <SearchInput
                    modifiers={["filter"]}
                    onChange={(ev) => this.setState({ searchValue: ev.target.value })}
                    placeholder={t("Search")}
                    value={searchValue}
                  />
                </SearchBlock>
              )}

              {filteredItems.map((option) => {
                if (Array.isArray(option.value)) {
                  return (
                    <OptionWithSubOptions
                      key={option.label}
                      label={option.label}
                      disabled={option.disabled}
                      checkbox
                      subOptions={option.value}
                      onSelect={this.selectValue}
                      checkIfActive={this.isActive}
                    />
                  );
                }

                return (
                  <Option
                    key={option.value}
                    onSelect={() => this.selectValue(option.value as string)}
                    label={option.label}
                    checkbox
                    disabled={option.disabled}
                    active={this.isActive(option.value)}
                  />
                );
              })}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default withTranslation()(MultiSelectWithSearch);
