/** Activity is from Tasks in Projects (not Superpunch)  */

export enum ActivityStatuses {
  /** when users starts tracking.
   * currently is not supported on client admin */
  running = "pending",
  /** default activity status */
  pending = "pending",
  /** when timesheet range was approved */
  approved = "approved",
  /** deprecated */
  declined = "declined",
  /** when timesheet range was submitted */
  submitted = "submitted",
  /** when user cancels activity */
  deleted = "deleted",
}
