import styled from "styled-components";
import noItems from "../img/no-groups.png";

const Container = styled.div`
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImgWrapper = styled.div`
  margin-bottom: 25px;
`;

const Img = styled.img``;

const Text = styled.div`
  font-size: 20px;
  color: var(--colors-surface-600);
  letter-spacing: -0.13px;
  text-align: center;

  .clickable {
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-inline-end: 14px;
      path {
        fill: var(--colors-primary);
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 20px;
      color: var(--colors-primary);
    }
  }
`;

const NoContent = ({ children, img }: { children: JSX.Element | string; img?: string }): JSX.Element => (
  <Container>
    <ImgWrapper>
      <Img src={img || noItems} alt="" />
    </ImgWrapper>
    <Text>{children}</Text>
  </Container>
);

export default NoContent;
