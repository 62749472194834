import { WithTranslation } from "react-i18next";
import {
  EmployeeHolidayGroup,
  EmployeePayrollGroup,
  EmployeesBusinessRulesGroup,
  EmployeeSchedule,
} from "types/models/userProfile";
import moment from "moment";
import { getImgUrl } from "utils/common";

export const getImageUrlsWithParams = (imgId: string): [string, string] => [
  getImgUrl(imgId, "c_fill,h_108,w_108/"),
  getImgUrl(imgId, "c_fill/"),
];

export const getGroupName = (group: CommonGroup, t: WithTranslation["t"]): string =>
  `${group.name === "default" ? t("common|default") : group.name} ${group.isDefault ? `[${t("common|default")}]` : ""}`;

type CommonGroup = EmployeePayrollGroup | EmployeesBusinessRulesGroup | EmployeeSchedule | EmployeeHolidayGroup;

export const getGroupNameAndDescription = (
  groups: CommonGroup[],
  t: WithTranslation["t"],
): { mainText: string; subText: string } => {
  let mainText = "";
  let subText = "";

  if (groups && groups.length) {
    const group = groups[0];
    if (moment(group.startDate, "YYYY-MM-DD").isAfter(moment())) {
      subText = `${t("Starting on")}: ${moment(group.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`;
      mainText = getGroupName(group, t);
    } else if (!group.endDate && moment(group.startDate, "YYYY-MM-DD").isSameOrBefore(moment(), "day")) {
      subText = `${t("Start Date")}: ${moment(group.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`;
      mainText = getGroupName(group, t);
    } else if (group.endDate && moment(group.startDate, "YYYY-MM-DD").isSameOrBefore(moment(), "day")) {
      mainText = getGroupName(group, t);
      subText = `${t("Next Change")}: ${moment(group.endDate, "YYYY-MM-DD").add(1, "day").format("DD/MM/YYYY")}`;
    } else if (
      !group.endDate &&
      groups.length > 1 &&
      groups[1].startDate &&
      moment(groups[1].startDate, "YYYY-MM-DD").isAfter(moment(), "day")
    ) {
      mainText = getGroupName(group, t);
      subText = `${t("Next Change")}: ${moment(groups[1].startDate, "YYYY-MM-DD").add(1, "day").format("DD/MM/YYYY")}`;
    } else {
      mainText = getGroupName(group, t);
    }
  }
  return {
    mainText,
    subText,
  };
};

export const emptyValueFormatter = (value?: string) => (value || "-");
