import { Component, ChangeEvent, CSSProperties, HTMLInputTypeAttribute } from "react";
import BEM from "utils/BEM";
import "styles/text-input-control.scss";

const b = BEM.b("text-input-control");

export interface TextInputControlRef extends Component {
  getValue: () => string;
}

interface iTextInputControlProps {
  value: string;
  onChange?: (value: string) => void;
  className: string;
  error?: boolean;
  onRef?: (ref: TextInputControlRef) => void;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  size?: number;
  placeholder?: string;
  style: CSSProperties;
}

interface iTextInputControlState {
  value: string;
  error: boolean;
}

class TextInputControl extends Component<iTextInputControlProps, iTextInputControlState> {
  static defaultProps = {
    className: "",
    style: {},
  };

  constructor(props: iTextInputControlProps) {
    super(props);

    this.state = {
      value: props.value || "",
      error: props.error || false,
    };
  }

  componentDidMount(): void {
    const { onRef } = this.props;

    if (onRef) {
      onRef(this);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: iTextInputControlProps): void {
    if (nextProps.value !== this.state.value || nextProps.error !== this.state.error) {
      const state: Partial<iTextInputControlState> = { error: nextProps.error };

      if (typeof nextProps.value !== "undefined") {
        state.value = nextProps.value;
      }

      this.setState(state as iTextInputControlState);
    }
  }

  onChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    this.setState({ value: ev.target.value, error: false });

    if (onChange) {
      onChange(ev.target.value);
    }
  };

  getValue = (): string => this.state.value;

  render(): JSX.Element {
    const { className, disabled, type, size, placeholder, style } = this.props;
    const { value, error } = this.state;

    return (
      <input
        className={`${b({ error, "has-value": !!value })} ${className}`}
        disabled={disabled}
        type={type || "text"}
        maxLength={size}
        size={size}
        onChange={this.onChange}
        placeholder={placeholder}
        style={style}
        value={value}
      />
    );
  }
}

export default TextInputControl;
