import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import { addDepartment, getDepartments } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import sentryUtils from "utils/sentryUtils";
import { Department } from "types/models/department";
import { GlobalContext } from "context/GlobalContextProvider";
import ModalDialog from "./UI/ModalDialog";
import DepartmentsPopupMessage from "./Departments/DepartmentsPopupMessage";

type Option = {
  label: string;
  value: string | number;
};

interface DepartmentsDropdownControlProps {
  t: WithTranslation["t"];
  /** Department uuid or id as an option value */
  uuid?: boolean;
  value: string | number | null;
  /** Department uuid or id */
  onChange: (value: string | number | null) => void;
}

interface DepartmentsDropdownControlState {
  isLoading: boolean;
  options: Option[];
  createPopupVisible: boolean;
  departments: Department[];
}

class DepartmentsDropdownControl extends Component<DepartmentsDropdownControlProps, DepartmentsDropdownControlState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<DepartmentsDropdownControlState> = {
    isLoading: true,
    options: [],
    createPopupVisible: false,
    departments: [],
  };

  async componentDidMount() {
    const { t, uuid } = this.props;

    const departments = (await this.context.getDepartments()) || [];

    let options: Option[] = [{ label: t("none-option"), value: "" }];

    if (departments.length > 0) {
      options = options.concat(
        departments.map((item) => ({ label: `${item.code} - ${item.name}`, value: uuid ? item.uuid : item.id })),
      );
    }

    this.setState({ options, isLoading: false, departments });
  }

  createDepartment = async ({ name, code }: { name: string; code: string }) => {
    const { onChange, uuid } = this.props;
    const requestData = {
      body: {
        department: {
          name,
          code,
        },
      },
    };

    try {
      const { department } = await addDepartment(requestData);

      this.setState(
        {
          options: [...this.state.options, { label: department.name, value: uuid ? department.uuid : department.id }],
        },
        () => onChange(uuid ? department.uuid : department.id),
      );
    } catch (err) {
      sentryUtils.sendError(err);
    } finally {
      this.setState({ createPopupVisible: false });
    }
  };

  render() {
    const { t, value, onChange } = this.props;
    const { options, isLoading, createPopupVisible, departments } = this.state;

    return (
      <>
        <SelectControl<string | number>
          placeholder={t("Select department")}
          options={options}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          isSearchable
          withCreateOption
          onCreateOption={() => this.setState({ createPopupVisible: true })}
          createOptionText={t("Create new department")}
        />

        <ModalDialog isOpen={createPopupVisible} onClose={() => this.setState({ createPopupVisible: false })}>
          <DepartmentsPopupMessage
            title={t(`${TranslationNamespaces.departments}|Create a Department`)}
            item={{}}
            allItems={departments}
            buttonYesTitle={t(`${TranslationNamespaces.departments}|Create`)}
            onClose={() => this.setState({ createPopupVisible: false })}
            onYes={this.createDepartment}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.departments])(
  DepartmentsDropdownControl,
);
