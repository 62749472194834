import { Component } from "react";
import styled from "styled-components";
import * as images from "components/svg-images";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { withTranslation } from "react-i18next";
import EmployeeInfo from "../UI/EmployeeInfo";

const moment = extendMoment(momentTz);

const getStatusColor = (props) => {
  switch (props.status) {
    case "pending":
      return props.theme.colors.pending;
    case "approved":
      return props.theme.colors.success2;
    case "declined":
    case "ignored":
      return props.theme.colors.error;
    default:
      return props.theme.colors.secondary;
  }
};

const RequestsPanel = styled.div`
  width: 100%;
  height: 100%;
`;

const EmployeeInfoWrapper = styled.div``;

const RequestRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &:first-child {
    .inner {
      border-top: 1px solid transparent;
    }
  }
  &:hover {
    border-radius: 6px;
    background: ${(props) => props.theme.colors.activityRequestRowHover} .inner {
      border-top: 1px solid transparent;
    }
    & + div .inner {
      border-top: 1px solid transparent;
    }
  }
`;
const RequestRowInner = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  position: relative;
  border-top: 1px solid ${(props) => props.theme.colors.inputBorderDefault};
`;
const RequestIcon = styled.div`
  display: flex;
  margin-inline-end: 20px;
  width: 42px;
  align-items: center;
  justify-content: center;
  svg g {
    stroke: ${(props) => props.theme.colors.mainText};
  }
`;
const RequestStatus = styled.div`
  color: ${(props) => getStatusColor(props)};
  border: 1px solid ${(props) => getStatusColor(props)};
  padding: 2px 13px;
  border-radius: 100px;
  font-size: 10px;
  letter-spacing: -0.05px;
  text-transform: uppercase;
  align-self: flex-start;
`;
const RequestNameTime = styled.div`
  display: flex;
  flex-direction: column;
  width: 165px;
  overflow: hidden;
`;
const RequestName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: var(--typography-font-size-default);
  color: ${(props) => props.theme.colors.mainText};
  letter-spacing: 0;
  margin-bottom: 8px;
`;
const RequestTime = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.secondary};
`;
const RequestIconNameTime = styled.div`
  display: flex;
  align-items: center;
`;
class RequestsList extends Component {
  getRequestIcon(type) {
    switch (type) {
      case "health":
        return images.requestHealth;
      case "personal":
        return images.requestPersonal;
      case "legal":
        return images.requestLegal;
      case "for_work":
        return images.requestForWork;
      case "vacation":
        return images.requestVacation;
      case "others":
        return images.requestOther;
      case "manual_punch":
      default:
        return images.locationIcon;
    }
  }
  getRequestTime = (request) => {
    const { t } = this.props;
    let requestTime = `${moment.tz(request.startTime, request.timezone).format("DD/MM HH:mm")} - ${momentTz
      .tz(request.endTime, request.timezone)
      .format("DD/MM HH:mm")}`;
    if (request.allDay) {
      if (
        !moment.tz(request.startTime, request.timezone).isSame(momentTz.tz(request.endTime, request.timezone), "day")
      ) {
        requestTime = `${t("All day")} - (${requestTime})`;
      } else {
        requestTime = t("All day");
      }
    }

    return requestTime;
  };

  render() {
    const { t, requests = [], employeeInfo, onSelectRequest } = this.props;

    return (
      <RequestsPanel>
        <EmployeeInfoWrapper>
          <EmployeeInfo employeeInfo={employeeInfo} withBorder />
        </EmployeeInfoWrapper>
        <div>
          {requests.map((request) => (
            // <RequestRow key={request.uuid} onClick={() => fullRequests && onSelectRequest(fullRequests[request.uuid])}>
            <RequestRow
              key={request.uuid}
              onClick={() =>
                onSelectRequest({
                  ...request,
                  startTime: request.startDate,
                  endTime: request.endDate,
                  employee: employeeInfo,
                })
              }
            >
              <RequestRowInner className="inner">
                <RequestIconNameTime>
                  {/* <RequestIcon>{this.getRequestIcon(request.request_type)}</RequestIcon> */}
                  <RequestNameTime>
                    <RequestName>{t(request.requestType)}</RequestName>
                    <RequestTime>{this.getRequestTime(request)}</RequestTime>
                  </RequestNameTime>
                </RequestIconNameTime>
                <RequestStatus status={request.approvalStatus}>{t(request.approvalStatus)}</RequestStatus>
              </RequestRowInner>
            </RequestRow>
          ))}
        </div>
      </RequestsPanel>
    );
  }
}
export default withTranslation(["requests-page-tmp"])(RequestsList);
