import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import {
  hasEmployeesAccess,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
  hasPermisionAccess,
} from "utils/common";
import { updateUserDetails, getLocations, getPasswordValidation } from "utils/apiHelpers";
import { EmployeeTaxPayerTypes } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { TranslationNamespaces } from "types/translationNamespaces";
import CheckboxControl from "components/UI/NewCheckbox";
import { UserProfile } from "types/models/userProfile";
import PasswordInput from "components/PasswordInput";
import SelectControl from "components/SelectControl";
import { getServiceProviderSettings, updateServiceProviderSettings } from "components/Projects/projectsApiUtils";
import DepartmentsDropdownControl from "components/DepartmentsDropdownControl";
import PositionsDropdownControl from "components/PositionsDropdownControl";
import SubsidiariesDropdownControl from "components/SubsidiariesDropdownControl";
import FieldWrapper from "components/UI/FieldWrapper";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import ServicesDropdownControl from "components/Projects/ServicesDropdownControl";
import { ErrorLabel } from "components/UI/TextLabels";
import TeamsDropdownControl from "components/TeamsDropdownControl";
import "components/UI/Page/Page.scss";
import "styles/employee-details.scss";

const b = BEM.b("employee-details");
const input = BEM.b("input");

const Wrapper = styled.div`
  .employee-details__bottom {
    display: block;
  }
`;

const BlockTitle = styled.div`
  margin-top: 15px;
  line-height: 15px;
  font-size: 12px;
  font-weight: var(--typography-font-weight-bold);
  text-transform: uppercase;
  color: var(--colors-surface-800);
  background-repeat: no-repeat;
  background-position-y: center;
`;

const CheckboxWrapper = styled.div`
  text-align: start;
  margin-top: 20px;
  margin-bottom: 15px;
`;

type EmployeeDetailsField = {
  value: string;
  error: string | null;
};

interface EmployeeDetailsOldProps extends WithTranslation {
  onSave: (notification: string) => void;
  employee: UserProfile;
}

interface EmployeeDetailsOldState {
  employee: UserProfile;
  email: EmployeeDetailsField;
  password: EmployeeDetailsField;
  fullName: EmployeeDetailsField;
  socialName: EmployeeDetailsField;
  useSocialName: boolean;
  cpf: EmployeeDetailsField;
  pis: EmployeeDetailsField;
  matricula: EmployeeDetailsField;
  position: number | null;
  department: number | null;
  subsidiary: number | null;
  teamId: number | null;
  startDate: moment.Moment | null;
  locations: { value: unknown[]; error: string | null; options: unknown[] };
  responseError: Error | null;
  availableLocations: { value: number; label: string }[];
  selectedLocations: { value: number; label: string }[];
  serviceUuids: string[];
  allowProjects: boolean;
  loading: boolean;
  passwordValidations: Record<string, unknown>; // TODO
  errors: { serviceUuids?: string } | null;
}

class EmployeeDetails extends Component<EmployeeDetailsOldProps, EmployeeDetailsOldState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: EmployeeDetailsOldProps) {
    super(props);

    this.state = {
      employee: props.employee || null,
      email: { value: props.employee.email || "", error: null },
      password: { value: "", error: null },
      // fullName: { value: props.employee.full_name, error: null },
      fullName: { value: props.employee.real_name, error: null },
      socialName: { value: props.employee.social_name, error: null },
      useSocialName: props.employee.use_social_name || false,
      cpf: { value: props.employee.cpf, error: null },
      pis: { value: props.employee.pis || "", error: null },
      matricula: { value: props.employee.matricula, error: null },
      position: props.employee.position_id || null,
      department: props.employee.department_id || null,
      subsidiary: props.employee.subsidiary_id || null,
      teamId: props.employee.team?.id || null,
      startDate: props.employee.start_date ? moment(props.employee.start_date) : null,
      locations: { value: [], error: null, options: [] },
      responseError: null,
      availableLocations: [],
      selectedLocations: [],
      serviceUuids: [],
      allowProjects: false,
      loading: false,
      passwordValidations: {},
      errors: null,
    };
  }

  componentDidMount() {
    void this.getState(this.props.employee);
    void this.getPasswordValidationRegExp();
  }

  componentDidUpdate(prevProps: EmployeeDetailsOldProps) {
    if (this.props.employee.id !== prevProps.employee.id) {
      void this.getState(this.props.employee);
    }
  }

  getPasswordValidationRegExp = async () => {
    try {
      const email = this.state.email.value || window.global_store.profile.email;
      const passwordValidations = await getPasswordValidation(email);

      this.setState({ passwordValidations });
    } catch (err) {
      console.log(err);
    }
  };

  getState = async (employee: UserProfile) => {
    const state: Partial<EmployeeDetailsOldState> = {};
    const eployeeLocations = employee?.locations || [];
    const resp = await getLocations();

    if (resp?.locations?.length) {
      const selectedLocations: EmployeeDetailsOldState["selectedLocations"] = [];

      const locations = resp.locations
        .filter((l) => l.active)
        .map((l) => {
          const location = eployeeLocations.find((el) => el.id === l.id);

          if (location) {
            selectedLocations.push({
              value: location.id,
              label: location.name,
            });
          }

          return {
            value: l.id,
            label: l.name,
          };
        });

      state.availableLocations = locations;
      state.selectedLocations = selectedLocations;
    }

    const company = await this.context.getCompany();

    if (company) {
      const { uuid } = company;

      const settingsResp = await getServiceProviderSettings({
        companyUuid: uuid,
        userProfileUuid: employee.uuid,
        requestedBy: window.global_store.profile.uuid,
      });

      const respSettings = settingsResp?.content || null;

      state.allowProjects = !!respSettings?.enabled;

      state.serviceUuids = respSettings?.enabled ? respSettings.services.map((service) => service.uuid) : [];
    }

    this.setState(state);
  };

  onFullNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ fullName: { value: ev.target.value, error: null } });
  };

  onSocialNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ socialName: { value: ev.target.value, error: null } });
  };

  onCpfChange = (ev: ChangeEvent<HTMLInputElement>) => {
    let { value } = ev.target;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);

    if (employeeTaxId === EmployeeTaxPayerTypes.cpf && value.length > 11) {
      value = value.slice(0, 11);
    }

    this.setState({ cpf: { value, error: null } });
  };

  onEmailChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: { value: ev.target.value.trim(), error: null } });
  };

  onPasswordChange = (val: string) => {
    this.setState({ password: { value: val, error: null } });
  };

  onPisChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ pis: { value: ev.target.value.trim(), error: null } });
  };

  onPositionChange = (select: number) => {
    this.setState({ position: select });
  };

  onLocationsChange = (select: EmployeeDetailsOldState["selectedLocations"]) => {
    this.setState({ selectedLocations: select });
  };

  onMatriculaChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ matricula: { value: ev.target.value, error: null } });
  };

  validateEmail = () => {
    const { value } = this.state.email;
    const { t } = this.props;
    let error: string | null = null;

    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (!value) {
      error = t("Email can't be empty");
    } else if (!validate(value)) {
      error = t("Incorrect email");
    } else {
      error = null;
    }

    return error;
  };

  validatePassword = () => {
    const {
      password: { value },
      passwordValidations,
    } = this.state;
    const { t } = this.props;
    let error: string | null = null;

    if (value) {
      if (passwordValidations.validations?.length) {
        passwordValidations.validations.forEach((v) => {
          if (!value.match(new RegExp(v.regex)) && !error) {
            error = t(`${TranslationNamespaces.signup}|${v.constrain_key}`);
          }
        });
      } else if (passwordValidations.entirePassword) {
        if (!value.match(new RegExp(passwordValidations.entirePassword))) {
          error = t(`${TranslationNamespaces.signup}|Password is not strong enough`);
        }
      }
    }

    return error;
  };

  onNextClick = () => {
    const {
      email,
      fullName,
      cpf,
      pis,
      matricula,
      locations,
      selectedLocations,
      password,
      startDate,
      allowProjects,
      serviceUuids,
      socialName,
    } = this.state;
    const { t } = this.props;
    let errors: EmployeeDetailsOldState["errors"] = {};

    if (hasPermisionAccess(PermissionSectionName.projects) && allowProjects) {
      if (!serviceUuids || !serviceUuids.length) {
        errors.serviceUuids = t("error-no-service");
      }
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    } else {
      this.setState({ errors });
      return;
    }

    if (this.validatePassword()) {
      password.error = this.validatePassword();
    }
    if (email.error || fullName.error || cpf.error || locations.error || password.error || socialName.error) {
      this.setState({ email, fullName, cpf, locations, responseError: null });
    } else {
      void this.submitForm(
        email.value,
        fullName.value,
        cpf.value,
        matricula.value,
        pis.value,
        selectedLocations,
        password.value,
        startDate,
        socialName.value,
      );
    }
  };

  submitForm = async (
    email: string,
    fullName: string,
    cpf: string,
    matricula: string,
    pis: string,
    selectedLocations: EmployeeDetailsOldState["selectedLocations"],
    password: string,
    startDate: moment.Moment | null,
    socialName: string,
  ) => {
    this.setState({ loading: true }, async () => {
      const { onSave } = this.props;
      const { allowProjects, serviceUuids, position, department, subsidiary, teamId, useSocialName } = this.state;
      const position_id = position || null;
      const department_id = department || null;
      const subsidiary_id = subsidiary || null;
      const team_id = teamId || null;

      const employee: { user_profile: Record<string, any> } = {
        user_profile: {
          // full_name: useSocialName ? socialName : fullName,
          real_name: fullName,
          social_name: socialName,
          use_social_name: useSocialName,
          matricula,
          pis,
          user_attributes: {
            cpf,
          },
        },
      };

      if (email) {
        employee.user_profile.user_attributes.user_account_attributes = { email };
      }
      if (startDate) {
        employee.user_profile.start_date = startDate.format("YYYY-MM-DD");
      }
      if (selectedLocations) {
        employee.user_profile.location_ids = selectedLocations.map((l) => l.value);
      } else {
        employee.user_profile.location_ids = [];
      }
      if (this.state.employee.position_id !== position_id) {
        employee.user_profile.position_id = position_id;
      }
      if (this.state.employee.department_id !== department_id) {
        employee.user_profile.department_id = department_id;
      }
      if (this.state.employee.subsidiary_id !== subsidiary_id) {
        employee.user_profile.subsidiary_id = subsidiary_id;
      }
      if (this.state.employee?.team?.id !== team_id) {
        employee.user_profile.team_id = team_id;
      }

      if (password) {
        if (employee.user_profile.user_attributes.user_account_attributes) {
          employee.user_profile.user_attributes.user_account_attributes.password = password;
        } else {
          employee.user_profile.user_attributes.user_account_attributes = { password };
        }
      }

      try {
        await updateUserDetails({ id: this.state.employee.id, employee });

        if (hasPermisionAccess(PermissionSectionName.projects) && this.props?.employee?.id) {
          void updateServiceProviderSettings({
            companyUuid: window.global_store.company.uuid,
            userProfileUuid: this.props.employee.uuid,
            requestedBy: window.global_store.profile.uuid,
            body: {
              content: {
                enabled: allowProjects,
                serviceUuids,
                updatedBy: window.global_store.profile.uuid,
              },
            },
          });
        }

        this.setState({ responseError: null, loading: false }, () => {
          const elem = document.querySelector(".full-page .main-panel");

          if (elem && elem.scrollIntoView) {
            elem.scrollIntoView();
          }

          onSave("The profile was updated");
        });
      } catch (err) {
        const error = err as Error;
        this.setState({ responseError: error, loading: false });
      }
    });
  };

  render() {
    const {
      loading,
      fullName,
      cpf,
      email,
      pis,
      subsidiary,
      position,
      teamId,
      department,
      matricula,
      locations,
      responseError,
      selectedLocations,
      password,
      availableLocations,
      startDate,
      allowProjects,
      serviceUuids,
      errors,
      socialName,
      useSocialName,
    } = this.state;
    const { t } = this.props;

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    return (
      <Wrapper className={b()}>
        {responseError && (
          <div className={b("response-error", ["visible"])}>{t(responseError?.message || String(responseError))}</div>
        )}

        <FieldWrapper fieldName={t("Full name")} width="100%">
          <input
            style={{ marginTop: 0 }}
            className={input({ error: fullName.error })}
            placeholder={t(`${TranslationNamespaces.common}|name-placeholder`)}
            type="text"
            value={fullName.value}
            size={50}
            name="name"
            onChange={this.onFullNameChange}
          />
        </FieldWrapper>
        {fullName.error && <span className="field-error">{fullName.error}</span>}

        <FieldWrapper fieldName={t("Social name")} width="100%">
          <input
            style={{ marginTop: 0 }}
            className={input({ error: socialName.error })}
            placeholder={t(`social-name-placeholder`)}
            type="text"
            value={socialName.value}
            size={50}
            name="name"
            onChange={this.onSocialNameChange}
            disabled={!useSocialName}
          />
        </FieldWrapper>

        <FieldWrapper>
          <CheckboxControl
            checked={useSocialName}
            onChange={(checked) => this.setState({ useSocialName: checked })}
            label={t("Use the Social Name")}
          />
        </FieldWrapper>

        <FieldWrapper
          fieldName={employeeTaxIdLabel}
          width="100%"
          tooltipText={employeeTaxId === EmployeeTaxPayerTypes.cpf ? t("cpf-field-info") : t("tax-payer-field-info")}
        >
          <input
            style={{ marginTop: 0 }}
            className={input({ error: cpf.error })}
            placeholder={employeeTaxId === EmployeeTaxPayerTypes.cpf ? "XXX.XXX.XXX-XX" : ""}
            value={cpf.value}
            name="cpf"
            onChange={this.onCpfChange}
          />
        </FieldWrapper>
        {cpf.error && <span className="field-error">{cpf.error}</span>}

        <FieldWrapper fieldName={t("PIS")} width="100%" tooltipText={t("pis-tooltip")}>
          <input
            style={{ marginTop: 0 }}
            className={input({ error: pis.error })}
            placeholder="XXX.XXXXX.XX-X"
            type=""
            name="pis"
            value={pis.value}
            onChange={this.onPisChange}
          />
        </FieldWrapper>
        {pis.error && <span className="field-error">{pis.error}</span>}

        <FieldWrapper fieldName={t("Department")} width="100%">
          <DepartmentsDropdownControl
            value={department}
            onChange={(val: number | null) => this.setState({ department: val })}
          />
        </FieldWrapper>
        <FieldWrapper
          fieldName={t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula")}
          width="100%"
          tooltipText={t("matricula-tooltip")}
        >
          <input
            style={{ marginTop: 0 }}
            className={input({ error: matricula.error })}
            placeholder=""
            type=""
            name="matricula"
            value={matricula.value}
            onChange={this.onMatriculaChange}
          />
        </FieldWrapper>
        {matricula.error && <span className="field-error">{matricula.error}</span>}

        <FieldWrapper fieldName={t("Subsidiary")} width="100%">
          <SubsidiariesDropdownControl
            value={subsidiary}
            onChange={(val: number | null) => this.setState({ subsidiary: val })}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Start date")} width="100%" fieldTitleMarginBottom={0}>
          <SingleDatePickerControl
            numberOfMonths={1}
            value={startDate}
            onChange={(val) => this.setState({ startDate: val })}
            isOutsideRange={() => false}
          />
        </FieldWrapper>

        <FieldWrapper fieldName={t("Team")} width="100%">
          <TeamsDropdownControl value={teamId} onChange={(val: number | null) => this.setState({ teamId: val })} />
        </FieldWrapper>

        <FieldWrapper fieldName={t("Position")} width="100%">
          <PositionsDropdownControl
            value={position}
            onChange={(val: number | null) => this.setState({ position: val })}
          />
        </FieldWrapper>
        <br />
        <div className={b("title")}>{t("Locations")}</div>

        {availableLocations.length > 0 && (
          <div className="field">
            <label className="label">{t("Select Locations")}</label>

            <SelectControl
              name="locations"
              isMulti
              value={selectedLocations}
              placeholder={t("Please select..")}
              onChange={this.onLocationsChange}
              options={availableLocations}
              error={locations.error}
            />
            {locations.error && <span className="field-error">{locations.error}</span>}
          </div>
        )}

        {hasPermisionAccess(PermissionSectionName.projects) && (
          <div>
            <BlockTitle>{t("Projects")}</BlockTitle>
            <CheckboxWrapper>
              <CheckboxControl
                checked={allowProjects}
                label={t("Allow to work on projects")}
                onChange={(checked) => this.setState({ allowProjects: checked, serviceUuids: [] })}
              />
            </CheckboxWrapper>

            {allowProjects && (
              <div>
                <FieldWrapper fieldName={t("Services")} width="100%">
                  <ServicesDropdownControl
                    disabled={!allowProjects}
                    value={serviceUuids}
                    onChange={(val) => {
                      this.setState({ serviceUuids: val || [] });
                    }}
                  />
                </FieldWrapper>
                {errors && errors.serviceUuids && <ErrorLabel>{errors.serviceUuids}</ErrorLabel>}
              </div>
            )}
          </div>
        )}
        <br />
        <div className={b("title")}>{t("Admin Access")}</div>
        <FieldWrapper fieldName={t("Email")} width="100%">
          <input
            style={{ marginTop: 0 }}
            className={input({ error: email.error })}
            placeholder={t(`${TranslationNamespaces.common}|email-placeholder`)}
            type="email"
            name="email"
            value={email.value}
            onChange={this.onEmailChange}
          />
        </FieldWrapper>
        {email.error && <span className="field-error">{email.error}</span>}
        <FieldWrapper fieldName={t("Password")} width="100%" fieldTitleMarginBottom={0}>
          <PasswordInput
            style={{ marginTop: 0 }}
            className={b("password")}
            isValid={!password.error}
            value={password.value}
            onPasswordChange={this.onPasswordChange}
          />
        </FieldWrapper>
        {password.error && <span className="field-error">{password.error}</span>}

        {hasEmployeesAccess() &&
          (this.props.employee.role !== "employer" || this.props.employee.id === window.global_store.profile.id) && (
            <div style={{ width: "132px" }} className={`button-next ${loading ? "button-next_disabled" : ""}`}>
              <input
                value={t("Save")}
                type="button"
                className={b("button", { full: true })}
                onClick={this.onNextClick}
                disabled={loading}
              />
            </div>
          )}
      </Wrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.signup])(EmployeeDetails);
