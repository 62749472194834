import styled from "styled-components";

const TaskProjectCell = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
`;

const TaskName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const ProjectName = styled.div`
  padding-inline-start: 12px;
  font-weight: var(--typography-font-weight-default);
  font-size: 12px;
  line-height: 15px;
  color: var(--colors-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default ({ taskName, projectName }: { taskName: string; projectName: string }) => (
  <TaskProjectCell>
    <TaskName className="common-text" title={taskName}>
      {taskName}
    </TaskName>
    <ProjectName title={taskName}>{projectName}</ProjectName>
  </TaskProjectCell>
);
