import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import noPunches from "img/no-punches.png";
import NoContent from "components/NoContent";
import HeaderActionButton from "components/controls/HeaderActionButton";

const Wrapper = styled.div``;
const Text = styled.div``;
const ButtonWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  justify-content: center;
  display: flex;
`;

interface NoActivitiesProps {
  onAddActivity: () => void;
}

const NoActivities = ({ onAddActivity }: NoActivitiesProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  return (
    <NoContent img={noPunches}>
      <Wrapper>
        <Text>{t("No activities at this time")}</Text>
        <ButtonWrapper>
          <HeaderActionButton title={t("Add Timetack")} onClick={onAddActivity} style={{ width: "fit-content" }} />
        </ButtonWrapper>
      </Wrapper>
    </NoContent>
  );
};

export default NoActivities;
