import { host } from "utils/host-environment";

const extensionIds = [
  "opiijijhpdedjbjccegpbghppkhlfohf", // LOCAL/STG
  "phpgakpokidfdngbcfipndjcdgaoipna", // PROD from Chrome store
  "onfoenmifjdmfbdnocnhelcbdafgnfpc", // PROD from MS Edge store
];

async function sendMessagePromise(extensionId: string, message: any) {
  const promise = new Promise((res, rej) => {
    window.chrome.runtime.sendMessage(extensionId, message, res);
  });
  return promise;
}

async function trySendMessage(extensionId: string, message: any) {
  try {
    return await sendMessagePromise(extensionId, message);
  } catch (err) {
    return null;
  }
}

export class ExtensionService {
  getExtensionDownloadUrl() {
    if (!host.isWebBrowser || !host.isDesktop) return undefined;
    if (host.isMsEdge)
      return "https://microsoftedge.microsoft.com/addons/detail/dayio-time-tracker/onfoenmifjdmfbdnocnhelcbdafgnfpc";
    if (host.isChromiumBrowser)
      return "https://chrome.google.com/webstore/detail/dayio-time-tracker/phpgakpokidfdngbcfipndjcdgaoipna";
    return undefined;
  }

  async getExtensionVersion() {
    const msg = { type: "get-dayio-widget-version" };
    const promises = extensionIds.map((extId) => trySendMessage(extId, msg));
    const results = await Promise.all(promises);
    const version = results.find((res) => res != null);
    return version || null;
  }

  async getAuthorizedUserProfileUuid() {
    const msg = { type: "get-dayio-authorized-profile-uuid" };
    const promises = extensionIds.map((extId) => trySendMessage(extId, msg));
    const results = await Promise.all(promises);
    const data = results.find((res) => res != null) as {uuid: string | null} | undefined;
    return data?.uuid || null;
  }
}
