import { Schedule, ScheduleDayEvent } from "types/models/schedule";
import { PunchType } from "types/models/punches";

export const calculateTotalWorkingHoursWithWorkingBreaksForDay = (dayEvents: ScheduleDayEvent[]): number =>
  dayEvents.reduce((dayAcc, event) => {
    if (event.type === PunchType.breakStart && event.isWorkingHours) {
      return (
        dayAcc +
        (dayEvents.find(
          (e) => e.type === PunchType.breakEnd && e.key === `${e.type}${event.key.substring(event.key.length - 1)}`,
        )?.duration || 0)
      );
    }

    return dayAcc;
  }, 0);

export const calculateTotalWorkingHoursWithWorkingBreaks = (schedule: Schedule): number =>
  schedule.scheduleDays.reduce(
    (scheduleCcc, day) =>
      scheduleCcc + day.shiftDuration + calculateTotalWorkingHoursWithWorkingBreaksForDay(day.events),
    0,
  );

type BreakType = { type: PunchType; key: string };
export type BreakPair<T extends BreakType> = [T | null, T | null];

export function getBreakPairs<T extends BreakType>(breaks: T[]): BreakPair<T>[] {
  const startMap: { [key: string]: T } = {};
  const endMap: { [key: string]: T } = {};
  const pairs: BreakPair<T>[] = [];

  // Separate the break_start and break_end elements into respective maps
  breaks.forEach((b) => {
    const match = b.key.match(/(\d+)$/);
    if (match) {
      const num = match[1];
      if (b.type === PunchType.breakStart) {
        startMap[num] = b;
      } else if (b.type === PunchType.breakEnd) {
        endMap[num] = b;
      }
    }
  });

  // Create pairs based on the matching numbers
  const allKeys = new Set([...Object.keys(startMap), ...Object.keys(endMap)]);
  allKeys.forEach((key) => {
    pairs.push([startMap[key] || null, endMap[key] || null]);
  });

  return pairs;
}
