import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import cx from "classnames";

export const HeaderRow = styled.div`
  height: 45px;
  display: grid;
  grid-template-columns: 1fr repeat(8, 93px);
`;

const HeaderCell = styled.div<{ joinCols?: number }>`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--colors-secondary);
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding-inline-start: 10px;
  ${(props) => (props.joinCols ? `grid-column: ${props.joinCols};` : "")}
  &.day-not-planned {
    color: var(--colors-surface-200);
  }
`;

const INPUT_DATE_FORMAT = "YYYY-MM-DD";
const TABLE_DATE_FORMAT = "ddd DD/MM";

export default ({
  dates,
  isDayPlannedArray,
  loading,
  startDayOffset = 0,
}: {
  dates: string[];
  isDayPlannedArray: boolean[];
  loading: boolean;
  startDayOffset?: number;
}) => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);

  return (
    <HeaderRow className={loading ? "loading" : ""}>
      <HeaderCell className="task-cell">{t("Task")}</HeaderCell>
      {startDayOffset > 1 ? <HeaderCell joinCols={startDayOffset} className={cx("common-cell")} /> : null}
      {dates.map((date, i) => (
        <HeaderCell
          key={date}
          className={cx("common-cell header-date-cell", { "day-not-planned": !isDayPlannedArray[i] })}
        >
          {moment(date, INPUT_DATE_FORMAT).format(TABLE_DATE_FORMAT)}
        </HeaderCell>
      ))}
      <HeaderCell className="total-cell" style={{ fontSize: "12px", gridColumn: "9" }}>
        {t("common|Total")}
      </HeaderCell>
    </HeaderRow>
  );
};
