import styled from "styled-components";
import { UfoIcon } from "./TimerButtons";

const Wrapper = styled.div`
  .Top {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 14px 16px;

    .Title {
      font-size: var(--typography-font-size-default);
      line-height: 130%;
      color: var(--colors-surface-700);
    }

    .Subtitle {
      font-size: 12px;
      line-height: 120%;
      color: var(--colors-surface-600);
    }
  }
  &.large {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 240px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    text-align: center;
    .Top {
      padding: 0;
      .Title {
        color: var(--colors-surface-800);
        font-size: 15px;
        font-weight: var(--typography-font-weight-medium);
      }
      .Subtitle {
        font-size: 13px;
      }
    }
  }
`;
export const NothingFound = ({
  title,
  description,
  large,
}: {
  title: string;
  description?: string;
  large?: boolean;
}) => {
  if (large)
    return (
      <Wrapper className="large">
        <UfoIcon />
        <div className="Top">
          <div className="Title">{title}</div>
          {description ? <div className="Subtitle">{description}</div> : null}
        </div>
      </Wrapper>
    );

  return (
    <Wrapper>
      <div className="Top">
        <div className="Title">{title}</div>
        {description ? <div className="Subtitle">{description}</div> : null}
      </div>
    </Wrapper>
  );
};
