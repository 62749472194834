import Tooltip from "components/UI/Tooltip";
import { TFunction } from "react-i18next";
import styled from "styled-components";

interface ProgressCellTooltipProps {
  t: TFunction;
  id: string;
  plannedMinutes: number;
  workedMinutes: number;
  paidAbsenceMinutes: number;
}

const TooltipContent = styled.div`
  text-align: start;
`;

const ProgressCellTooltip = ({
  t,
  id,
  plannedMinutes,
  workedMinutes,
  paidAbsenceMinutes,
}: ProgressCellTooltipProps) => (
  <Tooltip id={id} place="bottom">
    <TooltipContent>
      <div>{`${t("Planned")}: ${Math.round(plannedMinutes / 60)} ${t("hrs")}`}</div>
      <div>{`${t("Worked")}: ${Math.round(workedMinutes / 60)} ${t("hrs")}`}</div>
      <div>{`${t("Absence")}: ${Math.round(paidAbsenceMinutes / 60)} ${t("hrs")}`}</div>
    </TooltipContent>
  </Tooltip>
);

export default ProgressCellTooltip;
