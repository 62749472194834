import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { stylesheet } from "astroturf";
import Select from "components/UI/Select";
import { Coupon, Feature, Interval, Subscription, Tier } from "types/models/subscription";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { SubscriptionApi } from "utils/api/subscription";
import FieldWrapper from "components/UI/FieldWrapper";
import { BillingService } from "../BillingService";
import PricingContainer, { ExistingItem, ProductItem } from "../PricingContainer";

const styles = stylesheet`@import "../billing.scss"`;
type UpgradePlanModalProps = {
  productItem: ProductItem;
  interval: Interval;
  coupon: Coupon | null;
  subscription: Subscription;
  close: () => void;
  companyUuid: string;
};

const UpgradePlanModal = (props: UpgradePlanModalProps) => {
  const {t} = useTranslation(TranslationNamespaces.subscription);
  const [loading, setLoading] = useState<boolean>(false);
  const [existingItem, setExistingItem] = useState<ExistingItem>();
  const [tier, setTier] = useState<Tier>();
  const [error, setError] = useState<string | null>(null);

  const getTier = (tiers: Tier[], value: number) => tiers.find((tierData) => tierData.up_to! >= value);

  const productLabelMap = {
    [Feature.TA]: "Time & Attendance",
    [Feature.Project]: "Project Tracking",
    [Feature.Bundle]: "Bundle",
  };

  useEffect(() => {
    setExistingItem({
      seats: props.subscription.items[0].maxSeats,
      type: props.subscription.items[0].feature,
      price: props.subscription.items[0].price!,
      tier: getTier( props.subscription.items[0].price!.tiers, props.subscription.items[0].maxSeats)!,
    });
    setTier(props.productItem.selectedTier);
  }, []);

  const getUpgradeDescription = () => {
    const totalPrice =
      BillingService.calculateTotalPrice(existingItem!.tier, props.interval, props.coupon, props.productItem.product.externalId) +
      BillingService.calculateTotalPrice(tier!, props.interval, props.coupon, props.productItem.product.externalId);
    if (props.interval === Interval.Year) {
      return (
        <p className={styles.UpgradePopupTerms}>
          {t("upgrade_plan_description_annual {{price}} {{date}}", {
            price: totalPrice.toLocaleString("en-US", {
              style: "currency",
              currency: props.productItem.price.currency,
            }),
            date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
          })}
        </p>
      );
    }
    return (
      <p className={styles.UpgradePopupTerms}>
        {t("upgrade_plan_description_monthly {{price}} {{date}}", {
          price: totalPrice.toLocaleString("en-US", {
            style: "currency",
            currency: props.productItem.price.currency,
          }),
          date: BillingService.formatPaymentPeriodDate(props.subscription.currentPeriodEndedAt),
        })}
      </p>
    );
  }
  const submit = async () => {
    setError(null);
    setLoading(true);
    const payload = {
      items: [{
        seats: existingItem!.seats!,
        priceUuid: existingItem!.price.uuid
      }, {
        seats: tier!.up_to!,
        priceUuid: props.productItem.price.uuid
      }],
    };
    await SubscriptionApi.upgradePlan(props.companyUuid, props.subscription.uuid, payload).then(() => {
      window.location.href = `/billing/payment-success/upgrade/${props.interval}`;
    }).catch(err => {
      setLoading(false);
      setError(err.message);
    })
  };

  return (
    <div className={styles.UpgradePlanModal}>
      <div className={styles.UpgradeTitle}>{t("Purchase plan")}</div>
      <div className={styles.AddProductTable}>
        <div className={styles.AddProductTitle}>
          {t("Professional plan")}
          <span>{props.interval === Interval.Year ? t("Billed Annually") : t("Billed Monthly")}</span>
        </div>
        <div className={styles.AddProductContent}>
          {(tier && (
            <div className={styles.ProductItem}>
              <div className={styles.ProductItemLabel}>{t(productLabelMap[props.productItem.product.features[0]])}</div>
              <div className={styles.ProductPrice}>
                {BillingService.calculateUnitPrice(tier!, props.interval, props.coupon, props.productItem.product.externalId).toLocaleString("en-US", {
                  style: "currency",
                  currency: props.productItem.price.currency,
                })}
                <span>
                  {t("seat")} / {t("month")}
                </span>
              </div>
            </div>
          )) ||
            null}
        </div>
      </div>
      <div className={styles.MarginBottom24}>
        <FieldWrapper
          className={`${styles.WhiteSelect} ${styles.MarginBottom24}`}
          width="100%"
          fieldName={t("Choose seats")}
          fieldTitleMarginBottom={0}
          fieldTitleMarginTop={0}
        >
          <Select
            value={tier?.up_to}
            onChange={(value) => setTier(getTier(props.productItem.price.tiers, value!))}
            options={props.productItem.tierSelector!}
          />
        </FieldWrapper>
      </div>
      {(tier && existingItem && getUpgradeDescription()) || null}
      <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
        <Button
          disabled={loading}
          value={t("Cancel")}
          style={{ width: "48%" }}
          state={ButtonState.enableDay}
          onClick={() => props.close()}
        />
        {tier?.up_to === 250 ? (
          <Button
            disabled={loading}
            value={t("Contact sales")}
            style={{ width: "48%" }}
            state={ButtonState.primary}
            onClick={PricingContainer.bookCall}
          />
        ) : (
          <Button
            disabled={loading}
            value={t("Purchase")}
            style={{ width: "48%" }}
            state={ButtonState.primary}
            onClick={submit}
          />
        )}
      </div>
      {error ? <div className={styles.PaymentError}>{t(error)}</div> : null}
    </div>
  );
}
export default UpgradePlanModal;
