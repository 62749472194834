import ChartTooltip from "../ChartTooltip";

export const COLOR_PALETTE = ["#1390F6", "#0DB06D", "#642CA9", "#75D4D8", "#FF715B", "#F7AEF8"];

export default Object.freeze({
  width: "100%",
  dataFormat: "json",
  type: "stackedcolumn2d",
  dataSource: {
    chart: {
      theme: "oitchauChartTheme",
      plottooltext: ChartTooltip,
    },
  },
});
