import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -26px -25px 0 -25px;
  margin-bottom: 25px;
  height: 60px;
  line-height: 24px;
  font-size: var(--typography-font-size-default);
  background: linear-gradient(0deg, #e9f0fc, #e9f0fc), var(--colors-default);
  font-weight: var(--typography-font-weight-default);
  color: #515e80;

  &:after {
    content: " ";
    position: absolute;
    inset-inline-start: calc(50% - 20px);
    bottom: -20px;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z' fill='%23fff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.5 15.25h-.75v-1.5c0-2.1-1.65-3.75-3.75-3.75s-3.75 1.65-3.75 3.75v1.5h-.75c-.825 0-1.5.675-1.5 1.5v7.5c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5v-7.5c0-.825-.675-1.5-1.5-1.5zM20 22c-.825 0-1.5-.675-1.5-1.5S19.175 19 20 19s1.5.675 1.5 1.5S20.825 22 20 22zm2.324-6.75h-4.65v-1.5A2.337 2.337 0 0120 11.423a2.337 2.337 0 012.325 2.325v1.5z' fill='%23B6C4DC'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default () => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return <Row>{t("Closed Period. You can´t edit that requets.")}</Row>;
};
