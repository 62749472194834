import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import './GridTable.scss';
import { AgGridReactProps, AgReactUiProps } from "ag-grid-react/lib/shared/interfaces";
import { MutableRefObject, useCallback, useMemo, useRef } from "react";
import {
  ColDef,
  ColumnApi, GetLocaleTextParams,
  GetRowIdParams,
  GridReadyEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { LoadingCellRenderer } from "./renderers/LoadingCell.renderer";
import { TooltipRenderer } from "./renderers/Tooltip.renderer";

export const restoreState = (api: ColumnApi, lsKey?: string) => {
  if (!lsKey) return;
  const storedState = localStorage.getItem(lsKey);
  if (!storedState) {
    return;
  }

  try {
    const state = JSON.parse(storedState);
    api.applyColumnState({ state, applyOrder: true });
  } catch(e) {
    localStorage.removeItem(lsKey);
  }
};

const storeState = (api: ColumnApi, lsKey?: string) => {
  if (!lsKey) return;
  const state = api?.getColumnState();
  if (state && state.length > 0) localStorage.setItem(lsKey, JSON.stringify(state.map( s =>({ ...s, sort: null }))))
}

export const GridTable = (params: (AgGridReactProps | AgReactUiProps) & {gridApi: GridReadyEvent, lsKey?: string}) => {
  const {t} = useTranslation(TranslationNamespaces.agGrid);
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 150,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      sortable: false,
      suppressFillHandle: true,
      menuTabs: ["filterMenuTab"],
      tooltipComponent: TooltipRenderer,
      filterParams: {
        defaultToNothingSelected: true,
        buttons: ['clear', 'apply'],
        closeOnApply: true,
        showTooltips: true,
      }
    }),
    [],
  );
  const getRowId = (param: GetRowIdParams) => param.data.uuid;
  const debounce: MutableRefObject<NodeJS.Timeout | undefined> = useRef();
  const saveState = useCallback(() => {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
     storeState(params.gridApi.columnApi, params.lsKey);
    }, 200);
  }, [params.gridApi, params.lsKey]);

  const lastColClass = (): void => {
    const displayedColumns = params.gridApi.columnApi.getAllDisplayedColumns();
    if (displayedColumns.length === 0) return;
    const columns = params.gridApi.api.getColumnDefs();
    const lastCol = displayedColumns[displayedColumns.length - 1];
    columns?.forEach((col: ColDef) => {
      const className = (col as ColDef).colId === lastCol.getColId() ? 'lastCell' : "";
      col.headerClass = className;
      col.cellClass = className;
    })
    params.gridApi.api.setColumnDefs(columns!);
  };
  const getLocaleText = useCallback((params: GetLocaleTextParams) => {
    switch (params.key) {
      case 'thousandSeparator':
        return '.';
      case 'decimalSeparator':
        return ',';
      default:
        if (params.defaultValue) return (t(params.defaultValue))
        return '';
    }
  }, [t]);

  return (
    <div className="GridTable ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
      <AgGridReact
        rowSelection='multiple'
        suppressRowClickSelection
        suppressContextMenu
        embedFullWidthRows
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowModelType="serverSide"
        cacheBlockSize={100}
        maxBlocksInCache={10}
        rowBuffer={10}
        blockLoadDebounceMillis={600}
        serverSideSortOnServer
        onColumnVisible={() => {
          saveState();
        }}
        loadingCellRenderer={LoadingCellRenderer}
        onColumnResized={() => {
          saveState();
        }}
        maintainColumnOrder
        enableRtl={window.global_store.isRTL}
        getLocaleText={getLocaleText}
        tooltipShowDelay={0}
        tooltipInteraction
        enableCellTextSelection
        alwaysShowHorizontalScroll
        alwaysShowVerticalScroll
        suppressAnimationFrame
        maxConcurrentDatasourceRequests={1}
        {...params}
      />
    </div>
  )
}
