import { Sources, Amplitude } from "services/amplitude";
import CONFIG from "../config";
import { LocalStorageKeys } from "./localStorageUtils";

export * from "services/amplitude";

const options = {
  source: Sources.clientAdmin,
  environment: CONFIG.appEnv === "production" ? ("production" as const) : ("development" as const),
  isIncognitoMode: localStorage.getItem(LocalStorageKeys.loginAsMode) === "true",
};
export default new Amplitude(options);
