import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CNPJ } from "cpf_cnpj";
import TextInputControl, { TextInputControlRef } from "components/controls/TextInputControl";
import { getCompanyTaxPayerType, getCompanyTaxIdTranslation } from "utils/common";
import BEM from "utils/BEM";
import { CompanyTaxPayerTypes } from "types/common";
import "./styles/popup-message.scss";
import { Subsidiary } from "types/models/subsidiary";
import { TranslationNamespaces } from "types/translationNamespaces";

const b = BEM.b("subsidiaries-popup-message");

interface SubsidiariesPopupMessageProps extends WithTranslation {
  title: string;
  /** Subsidiary to edit */
  item: Subsidiary | Record<string, never>;
  allItems: Subsidiary[];
  buttonYesTitle: string;
  onClose: () => void;
  onYes: (data: { id: number; name: string; code: string; taxPayerId: string }) => void;
}

interface SubsidiariesPopupMessageState {
  title: string;
  name: { value: string; error: string };
  code: { value: string; error: string };
  cnpj: { value: string; error: string };
  buttonCancelTitle: string;
  buttonYesTitle: string;
}

class SubsidiariesPopupMessage extends Component<SubsidiariesPopupMessageProps, SubsidiariesPopupMessageState> {
  code: TextInputControlRef | null = null;
  name: TextInputControlRef | null = null;
  cnpj: TextInputControlRef | null = null;

  constructor(props: SubsidiariesPopupMessageProps) {
    super(props);
    const { t, title, item, buttonYesTitle } = props;

    this.state = {
      title,
      code: { value: item.code || "", error: "" },
      name: { value: item.name || "", error: "" },
      cnpj: { value: item.tax_payer_id || "", error: "" },
      buttonCancelTitle: t("Cancel"),
      buttonYesTitle: buttonYesTitle || t("common|Confirm"),
    };
  }

  closePopup = () => {
    this.props.onClose();
  };

  onYes = () => {
    if (this.code && this.cnpj && this.name) {
      const { allItems = [], item, t, onYes } = this.props;

      const code = { value: this.code.getValue(), error: "" };
      const name = { value: this.name.getValue(), error: "" };
      const cnpj = { value: this.cnpj.getValue(), error: "" };
      const companyTaxId = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
      const companyTaxIdLabel = getCompanyTaxIdTranslation(companyTaxId, t);

      if (!code.value) {
        code.error = this.props.t("Subsidiary code can't be empty");
        this.setState({ name, code, cnpj });
        // if subsidiary with this code already exist and (it is create mode or edit mode and code has been changed)
      } else if (allItems.some((s) => s.code === code.value) && (!item.code || item.code !== code.value)) {
        code.error = this.props.t("Subsidiary with this code already exists");
        this.setState({ name, code, cnpj });
      }

      if (!name.value) {
        name.error = this.props.t("Subsidiary name can't be empty");
        this.setState({ name, code, cnpj });
        // if subsidiary with this name already exist and (it is create mode or edit mode and name has been changed)
      } else if (allItems.some((s) => s.name === name.value) && (!item.name || item.name !== name.value)) {
        name.error = this.props.t("Subsidiary with this name already exists");
        this.setState({ name, code, cnpj });
      }

      if (!cnpj.value) {
        cnpj.error = this.props.t("Subsidiary {{taxId}} can't be empty", { taxId: companyTaxIdLabel });
        this.setState({ name, code, cnpj });
      } else if (
        allItems.some((s) => s.tax_payer_id === cnpj.value) &&
        (!item.tax_payer_id || item.tax_payer_id !== cnpj.value)
      ) {
        // if subsidiary with this tax_payer_id already exist and (it is create mode or edit mode and tax_payer_id has been changed)
        cnpj.error = this.props.t("Subsidiary with this {{taxId}} already exists", { taxId: companyTaxIdLabel });
        this.setState({ name, code, cnpj });
      } else if (cnpj.value && companyTaxId === CompanyTaxPayerTypes.cnpj && !CNPJ.isValid(cnpj.value)) {
        cnpj.error = this.props.t("Entered CNPJ is invalid");
        this.setState({ name, code, cnpj });
      } else {
        onYes({
          id: item.id,
          code: code.value,
          name: name.value,
          taxPayerId: cnpj.value,
        });
      }
    }
  };

  render() {
    const { title, name, code, cnpj, buttonYesTitle, buttonCancelTitle } = this.state;
    const { t } = this.props;
    const companyTaxId = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
    const companyTaxIdLabel = getCompanyTaxIdTranslation(companyTaxId, t);

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>

        <div className={b("code-name")}>
          <div className={b("code-field")}>
            <div className={b("label")}>{t("Code")}</div>
            <TextInputControl
              type="number"
              value={code.value}
              error={!!code.error}
              onRef={(ref) => {
                this.code = ref;
              }}
            />
          </div>
          <div className={b("name-field")}>
            <div className={b("label")}>{t("Name")}</div>
            <TextInputControl
              size={100}
              value={name.value}
              error={!!name.error}
              onRef={(ref) => {
                this.name = ref;
              }}
            />
          </div>
        </div>
        {(name.error || code.error) && <div className={b("error")}>{name.error || code.error}</div>}

        <div className={b("label")}>{companyTaxIdLabel}</div>
        <div>
          <TextInputControl
            value={cnpj.value}
            error={!!cnpj.error}
            onRef={(ref) => {
              this.cnpj = ref;
            }}
          />
          {cnpj.error && <div className={b("error")}>{cnpj.error}</div>}
        </div>

        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.closePopup}>
            {buttonCancelTitle}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes}>
            {buttonYesTitle}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.subsidiaries)(SubsidiariesPopupMessage);
