import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSystemName } from "utils/common";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "components/UI/ModalDialog";
import { services } from "components/PunchNow/services";
import ga, { GaInstallExtensionPopupStatus } from "utils/ga";
import { ApplicationsService } from "./ApplicationsService";

const styles = stylesheet`
  .ExtensionModal {
    .ModalTitle {
      color: var(--colors-surface-900-p);
      font-weight: var(--typography-font-weight-default);
      font-size: 25px;
      margin-bottom: 42px;
      text-align: center;
    }
    .ModalDescription {
      text-align: center;
      font-weight: var(--typography-font-weight-default);
      font-size: 16px;
      line-height: 140%;
      color: var(--colors-surface-700);
    }
    .ModalIconWrapper {
      margin: 0 auto 28px;
      display: flex;
      grid-gap: 30px;
      align-items: center;
      justify-content: center;
    }
    .ModalBtnGroup {
      margin-top: 48px;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const InstallExtensionModal = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [isOpen, setOpen] = useState(false);
  const [integrationName, setIntegrationName] = useState<string | undefined>();

  const extensionUrl = services.extensionService.getExtensionDownloadUrl();
  const init = async () => {
    const extensionQuery = searchParams.get("extension");
    if (!extensionQuery) return;

    const installedExtensionVersion = await services.extensionService.getExtensionVersion();

    if (!installedExtensionVersion && extensionQuery) {
      setOpen(true);
      setIntegrationName(extensionQuery);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const skip = () => {
    window.location.href = "/projects";
    setOpen(false);
  };

  const install = () => {
    ga.trackInstallExtensionPopup(GaInstallExtensionPopupStatus.install);

    window.open(extensionUrl, "_blank");
    skip();
  };

  return (
    <ModalDialog onClose={() => {}} isOpen={isOpen}>
      <div className={styles.ExtensionModal}>
        <div className={styles.ModalTitle}>{t("Install Extension")}</div>
        <div className={styles.ModalIconWrapper}>{Object.values(ApplicationsService.browserLogos)}</div>
        <div className={styles.ModalDescription}>
          {t("install_extension_modal_description {{appName}} {{integrationName}}", {
            appName: getSystemName(),
            integrationName,
          })}
        </div>
        <div className={styles.ModalBtnGroup}>
          <Button
            value={t("Cancel")}
            state={ButtonState.secondary}
            onClick={() => {
              ga.trackInstallExtensionPopup(GaInstallExtensionPopupStatus.cancel);
              skip();
            }}
          />
          <Button value={t("install_extension_CTA")} state={ButtonState.primary} onClick={install} />
        </div>
      </div>
    </ModalDialog>
  );
};

export default InstallExtensionModal;
