import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 280px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-inline-end: 1px solid var(--component-modal-border-color, #e1e3eb);
`;

const Sidebar = ({ children }: PropsWithChildren) => <Wrapper>{children}</Wrapper>;

export default Sidebar;
