import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { baseStyle } from "components/views/Inline";
import { useState } from "react";
import { stylesheet } from "astroturf";
import styled from "styled-components";
import { IconSearchClose } from "../styled";
import { Button, ButtonType } from "../Button";
import { Modal } from "../Modal";
import { ActivityField } from "../ActivityField";
import { ActivityFieldDropdown } from "../ActivityFieldDropdown";
import { ActivityProjectSelector } from "../ActivityProjectSelector";

const InlineInput = styled("input")`
  ${baseStyle}
`;

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--colors-default);

  .Header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: center;
    justify-content: space-between;
    padding-inline-start: 16px;
    height: 56px;
    flex-shrink: 0;
    color: var(--colors-surface-800);
    background: var(--colors-surface-50);
    font-weight: var(--typography-font-weight-default);
    font-size: 16px;
    line-height: 100%;
    padding-inline-end: 8px;

    .Close {
      display: flex;
      justify-content: end;
      width: 40px;
      padding-inline-end: 11px;
      cursor: pointer;
    }
  }

  .Body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .Fields {
      > * {
        border-bottom: 1px solid var(--colors-surface-100);
      }
    }

    .Buttons {
      margin: 24px 40px;
    }
  }

  .Modal {
    > * {
      height: 100%;
    }
  }
}
`;

interface Task {
  uuid: string;
  name: string;
}
interface Project {
  uuid: string;
  name: string;
  client?: { name: string };
  tasks: Task[];
}
export type ActivityTaskCreateProps = React.HTMLProps<HTMLDivElement> & {
  project?: Project;
  name?: string;
  /** Hide projects dropdown if null */
  projects: Project[] | null;
  onCreate(task: { projectUuid?: string; name: string }): void;
  onClose(): void;
};
export function ActivityTaskCreate(props: ActivityTaskCreateProps) {
  const { onClose, onCreate, project: projectProp, name: nameProp, projects } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const [name, setName] = useState(nameProp);
  const [project, setProject] = useState<Project | undefined>(projectProp);
  const [showProjectSelector, setShowProjectSelector] = useState(false);
  const showProjectsDropdown = projects != null;
  const enableCreateBtn = name !== "" && (project != null || projects == null);
  const fullProjName = project?.client?.name ? `${project?.name} / ${project?.client.name}` : project?.name;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <div className={styles.Title}>{t("New task")}</div>
        <div className={styles.Close} onClick={onClose}>
          <IconSearchClose />
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Fields}>
          <ActivityField title={t("Name")}>
            <InlineInput
              className={styles.FieldValue}
              autoFocus
              value={name}
              placeholder={t("Give your task a name")}
              onChange={(evt) => setName(evt.target.value)}
            />
          </ActivityField>
          {showProjectsDropdown && (
            <ActivityFieldDropdown
              title={t("Project")}
              value={fullProjName}
              placeholder={t("Select project")}
              noTitle={project == null}
              onClick={() => setShowProjectSelector(true)}
            />
          )}
        </div>
        <div className={styles.Buttons}>
          <Button
            buttonType={ButtonType.neutral}
            disabled={!enableCreateBtn}
            onClick={() => onCreate({ name, projectUuid: project?.uuid })}
          >
            {t("Create")}
          </Button>
        </div>
      </div>

      {showProjectSelector && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowProjectSelector(false);
          }}
        >
          <ActivityProjectSelector
            projects={projects!}
            selectedProjectUuid={project?.uuid}
            onChange={(proj) => {
              setShowProjectSelector(false);
              setProject(proj as Project);
            }}
            onClose={() => {
              setShowProjectSelector(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
