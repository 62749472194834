import React, { Component, createRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  z-index: 12;
  padding: 25px 30px 27px 30px;
  background: var(--colors-default);
  box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
  border-radius: var(--shapes-border-radius-default);
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: #8193ab;
  overflow: hidden;
  white-space: pre-wrap;
`;
const Content = styled.div`
  width: 265px;
`;
class InlineMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        left: "0",
        top: "23px",
      },
    };
    this.root = createRef();
  }
  componentDidMount() {
    const left = "auto";
    let right = "auto";
    let top = "auto";
    let bottom = "auto";

    if (this.root.current.getBoundingClientRect().height + 75 > this.root.current.getBoundingClientRect().top) {
      top = "10px";
    }
    if (this.root.current.getBoundingClientRect().bottom + 50 > window.innerHeight) {
      bottom = "20px";
    }
    if (this.root.current.getBoundingClientRect().right > window.innerWidth) {
      right = "0";
    }

    this.setState({ position: { left, right, top, bottom } });
  }
  render() {
    const { children, text } = this.props;
    const { position } = this.state;
    return (
      <Wrapper ref={this.root} style={position}>
        {text ? <Content>{text}</Content> : children}
      </Wrapper>
    );
  }
}
export default InlineMessage;
