import React from "react";
import styled from "styled-components";
import Button from "components/controls/StyledButton";

export const PopupTitle = styled.div`
  font-size: 25px;
  color: var(--colors-mainText);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;

const Buttons = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;

export const ButtonsRow = ({ loading, yesButtonDisabled, onCancel, buttonCancelTitle, onConfirm, buttonYesTitle }) => (
  <Buttons>
    <Button state="secondary" onClick={onCancel} value={buttonCancelTitle} />
    <Spacer />
    <Button state="primary" onClick={onConfirm} value={buttonYesTitle} loading={loading} disabled={yesButtonDisabled} />
  </Buttons>
);
