import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DashboardFrequency,
  Groups,
  Periods,
  AggrerationType,
  DashboardProcessedData,
  PeriodAggregationData,
} from "types/models/dashboard";
import { TranslationNamespaces } from "types/translationNamespaces";
import { getPeriodAggregation } from "utils/api/company";
import { GetPeriodAggregationResponseData } from "utils/api/types";
import usePeriodAggregationProcessor from "../utils/periodAggregationReducer";
import periods from "../utils/periods";
import { retryUntilResolved } from "../utils/retriers";

export type AggregationParameters<K extends keyof PeriodAggregationData> = {
  groupBy: Groups;
  groupUuids: string[];
  period: Periods;
  frequency: DashboardFrequency;
  aggregationType: AggrerationType;
  requestedProps: K[];
};

type Aggregation<K extends keyof PeriodAggregationData> = {
  isLoading: boolean;
  data: DashboardProcessedData<K> | null;
};

const usePeriodAggregation = <K extends keyof PeriodAggregationData>(
  params: AggregationParameters<K>,
): Aggregation<K> => {
  const { t } = useTranslation(TranslationNamespaces.dashboard);
  const [isLoading, setIsLoading] = useState(true);

  // If this batching doesn't match with the backend, it will probably lead to duplicated results
  const period = periods.buildPeriodBatches(params.period, params.frequency);

  const [data, updateData] = usePeriodAggregationProcessor(
    params.frequency,
    period.periods,
    params.groupBy,
    params.requestedProps,
    t,
  );

  useEffect(() => {
    const cancellers: (() => void)[] = [];
    setIsLoading(true);
    updateData({
      action: "init",
      data: { frequency: params.frequency, periods: period.periods, groupBy: params.groupBy },
    });

    const companyUUID = window.global_store.profile.company.uuid;

    const fetchData = async () => {
      const aggregationTasks = period.subPeriods.map(
        (subPeriod) => (): Promise<GetPeriodAggregationResponseData> =>
          getPeriodAggregation({
            companyUUID,
            groupBy: params.groupBy,
            groupUuids: params.groupUuids.join(","),
            from: subPeriod.startStr,
            to: subPeriod.endStr,
            frequency: params.frequency,
            aggregationType: params.aggregationType,
            props: params.requestedProps.join(","),
            requestedBy: window.global_store.profile.uuid,
          }),
      );

      const aggregationRetriers = aggregationTasks.map((task) => retryUntilResolved(task, 5_000, 10));
      aggregationRetriers.forEach((retrier) => cancellers.push(retrier.cancel));

      const response = await Promise.all(aggregationRetriers.map((retrier) => retrier.run()));
      response
        .filter((aggregationData): aggregationData is GetPeriodAggregationResponseData => !!aggregationData)
        .map((ad) => updateData({ action: "update", data: ad.content }));

      setIsLoading(false);
    };

    void fetchData();

    return () => {
      cancellers.forEach((cancel) => cancel());
    };
  }, [period.startStr, period.endStr, params]);

  return { isLoading, data };
};

export default usePeriodAggregation;
