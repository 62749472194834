import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useEffect, useMemo } from "react";
import { useProfile } from "context/user-profile-context";
import { useWidgetProjectsService } from "utils/useServices";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { normalizeString } from "components/views/Select";
import { filterNested } from "components/views/Select/filter-nested";
import { ProjRes } from "services/oitchau-api";
import ClickOutside from "react-click-outside";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { StoreApi, UseBoundStore } from "zustand";
import { ChevronRight } from "./TimerButtons";
import { StoredActivity, TrackTimeStore } from "../TrackTime.store";
import { SelectProject } from "./SelectProject";
import { CreateProject } from "./CreateProject";
import { AddTaskRow } from "./AddTaskRow";
import { OverlayDropdown } from "./OverlayDropdown";
import "./TaskControl.scss";
import { TaskOptions, TaskOptionType } from "./TaskOptions";
import { SearchBar } from "./SearchBar";

type TaskControlProps = {
  task: any;
  onClose: () => void;
  createTask: (taskName: string, project: ProjRes) => void;
  updateActiveActivity: (act: any) => void;
  canAddTask: boolean;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
};

export const TaskControlWrapper = ({
  updateActiveActivity,
  store,
  customView,
}: {
  updateActiveActivity: (act: StoredActivity) => void;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
  customView?: JSX.Element;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const {
    activity,
    setActivity,
    setProjects,
    setLocations,
    showTaskPicker,
    setShowTaskPicker,
    showSelectProject,
    reloadProjects,
    reload,
    selectedEmployee,
    locationsLoaded,
  } = store();
  const profile = useProfile();
  const widgetProjectsService = useWidgetProjectsService();
  const canAddTask = useMemo(
    () =>
      profile?.permission_roles?.some((prole) =>
        [PermissionRoleName.owner, PermissionRoleName.admin].includes(prole.name),
      ) || profile.permissions.includes(PermissionSectionName.projects),
    [profile],
  );

  useEffect(() => {
    if (reload) {
      void loadProjects();
      reloadProjects(false);
    }
  }, [reload]);

  const [loadLocations] = useAsyncCallback(async () => {
    if (locationsLoaded) return;
    const loc = await widgetProjectsService.listLocations();
    setLocations(loc);
  }, [locationsLoaded]);

  const [loadProjects] = useAsyncCallback(async () => {
    const [res] = await Promise.all([
      widgetProjectsService.listProjectsWithTasks(selectedEmployee?.uuid),
      loadLocations(),
    ]);
    const options = (res as unknown as ProjRes[]).map((project) => ({
      project,
      taskOptions: project.tasks.map((t) => ({
        task: t,
        searchTerms: [normalizeString(t.name)],
      })),
    }));
    setProjects(options);
    return options;
  }, [profile]);

  useEffect(() => reloadProjects(true), []);
  const createTask = async (name: string, project: ProjRes) => {
    const taskUuid = await widgetProjectsService.createTask({ projectUuid: project.uuid, name });
    const item = { ...activity, projectUuid: project.uuid, taskUuid };
    if (activity?.project?.uuid !== project.uuid) {
      item.customFields = [];
    }
    if (activity?.uuid) {
      await updateActiveActivity(item);
    }
    setActivity({ ...item, task: { uuid: taskUuid, name }, project });

    setShowTaskPicker(false);
    void loadProjects();
  };

  return (
    <>
      {customView || (
        <div onClick={() => setShowTaskPicker(true)} className="Task">
          {activity?.task?.name ? (
            <>
              <span className="TaskName">{activity?.task?.name}</span>
              {activity?.project?.name ? <span className="ProjectName">/ {activity?.project?.name}</span> : null}
            </>
          ) : activity && activity?.status !== "running" ? (
            t("What have you done?")
          ) : (
            t("what are you working on?")
          )}
          <div className="SelectIcon" style={{ rotate: "90deg" }}>
            <ChevronRight />
          </div>
        </div>
      )}
      {showTaskPicker && !showSelectProject ? (
        <TaskControl
          task={activity}
          canAddTask={canAddTask}
          createTask={createTask}
          updateActiveActivity={updateActiveActivity}
          onClose={() => setShowTaskPicker(false)}
          store={store}
        />
      ) : null}
      {showSelectProject ? <SelectProject canAddTask={canAddTask} createTask={createTask} store={store} /> : null}
    </>
  );
};

export const TaskControl = ({ task, createTask, updateActiveActivity, canAddTask, store }: TaskControlProps) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const { searchValue, setSearchValue, projects, setShowTaskPicker, showCreateProject } = store();

  const foundOptions = useMemo(() => {
    const str = normalizeString(searchValue);
    if (str === "") return projects;
    const predicate = (option: TaskOptionType) => option.searchTerms?.some((term: string) => term.includes(str));
    return filterNested(projects, predicate, "taskOptions");
  }, [projects, searchValue]);

  return (
    <ClickOutside
      onClickOutside={() => {
        if (!showCreateProject) setShowTaskPicker(false);
      }}
    >
      <OverlayDropdown
        header={<SearchBar placeholder={t("Search tasks")} searchValue={searchValue} setSearchValue={setSearchValue} />}
        content={
          <>
            <TaskOptions
              searchValue={searchValue}
              options={foundOptions}
              selectedTaskUuid={task?.task?.uuid}
              onCreateTask={createTask}
              updateActiveActivity={updateActiveActivity}
              canAddTask={canAddTask}
              store={store}
            />
            {canAddTask && (
              <div className="Footer">
                {searchValue === "" ? (
                  <CreateProject store={store} />
                ) : (
                  <AddTaskRow onCreateTask={createTask} store={store} />
                )}
              </div>
            )}
          </>
        }
      />
    </ClickOutside>
  );
};
