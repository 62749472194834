import { ChangeEvent, Component, CSSProperties, FocusEvent } from "react";
import BEM from "utils/BEM";
import "styles/time-control.scss";

const b = BEM.b("time-control");

interface TimeControlProps {
  style: CSSProperties;
  modifiers: Record<string, boolean>;
  className: string;
  /** HH:MM */
  value?: string;
  error?: boolean;
  onRef?: (ref: TimeControl) => void;
  noHoursLimit?: boolean;
  /** HH:MM */
  onTimeChange?: (data: { value: string; error: boolean }) => void;
  disabled?: boolean;
  locked?: boolean;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  clearZero?: boolean;
}

interface TimeControlState {
  value: string;
  error: boolean | undefined;
}

class TimeControl extends Component<TimeControlProps, TimeControlState> {
  static defaultProps = {
    style: {},
    modifiers: {},
    className: "",
  };

  constructor(props: TimeControlProps) {
    super(props);

    this.state = {
      value: props.value || "",
      error: props.error,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: TimeControlProps) {
    const { onRef } = this.props;

    if (nextProps.value !== this.state.value || nextProps.error !== this.state.error) {
      const state: Pick<TimeControlState, keyof TimeControlState> = {};

      if (typeof nextProps.value !== "undefined") {
        state.value = nextProps.value;
      }

      if (typeof nextProps.error !== "undefined") {
        state.error = nextProps.error;
      }

      this.setState(state);
    }

    if (onRef) {
      onRef(this);
    }
  }

  onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    let { value } = ev.target;
    value = value.replace(/\D/g, "");
    value = value.substring(0, 4);
    value = value.replace(/(\d{1,2})(\d{1,2})?/, (_, p1, p2) => {
      let output = "";
      if (p1) output = `${p1}`;
      if (p2) output += `:${p2}`;
      return output;
    });

    this.setState({
      value,
      error: ev.target.value === "" ? false : this.state.error,
    });
  };

  handleKeyUp = (ev: KeyboardEvent) => {
    if (ev.keyCode === 9) {
      this.onBlur(ev);
    }
  };

  onKeyUp = (ev: KeyboardEvent) => {
    if (this.props.clearZero) {
      const v = this.state.value.replace(/\D/gi, "");
      if (v === '0' && Number.isInteger(parseInt(+ev.key))) {
        this.setState({value: ev.key});
      }
    }
  }

  getZeroBased = (val: number) => (val < 10 ? `0${parseInt(String(val), 10)}` : parseInt(String(val), 10));

  getValue = () => this.state.value;

  getError = () => {
    const { noHoursLimit } = this.props;
    const { value } = this.state;
    let error = "";

    if (value.indexOf(":") === -1) {
      error = "Incorrect time format";
    } else {
      const [hh, mm] = value.split(":");
      if (value.split(":").length !== 2) {
        error = "Incorrect format";
      } else if (noHoursLimit || (Number(hh) > -1 && Number(hh) < 24 && Number(mm) > -1 && Number(mm) < 60)) {
        error = "";
      } else {
        error = "Incorrect time format";
      }
    }

    return error;
  };

  onBlur = (ev: FocusEvent<HTMLInputElement>) => {
    const { onTimeChange } = this.props;
    let { error, value } = this.state;
    let val: string | number = ev.target.value;

    if (val === "" || val === null) {
      error = false;
    } else if (val.indexOf(":") === -1) {
      val = parseInt(ev.target.value, 10);

      if (!Number.isNaN(val) && (this.props.noHoursLimit || (val > -1 && val < 24))) {
        error = false;
        value = `${this.getZeroBased(val)}:00`;
      } else {
        error = true;
      }
    } else {
      const hh = +val.split(":")[0];
      const mm = +val.split(":")[1];

      if (this.props.noHoursLimit || (hh > -1 && hh < 24 && mm > -1 && mm < 60)) {
        value = `${this.getZeroBased(hh)}:${this.getZeroBased(mm)}`;
        error = false;
      } else if (!mm || !hh) {
        error = true;
      } else {
        error = true;
      }
    }

    if (onTimeChange) {
      onTimeChange({ value, error });
    }

    this.setState({ error, value });
  };

  render() {
    const { value, error } = this.state;
    const { disabled, locked, modifiers, className, placeholder, style, autoComplete, autoFocus } = this.props;

    return (
      <input
        className={`${b({
          error: !disabled && error,
          disabled: !locked && disabled,
          locked,
          "has-value": !!value,
          ...modifiers,
        })} ${className}`}
        name="time"
        disabled={disabled || locked}
        type="text"
        maxLength={5}
        size={5}
        onKeyUp={this.onKeyUp}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholder={placeholder}
        style={{ ...style }}
        value={value}
        autoComplete={autoComplete}
        autoFocus={!!autoFocus}
      />
    );
  }
}

export default TimeControl;
