/* eslint no-underscore-dangle: ["warn", { allow: ["_original"] }] */
import { ChangeEvent, Component, MouseEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getLocksList, lockPayrollLock, unlockPayrollLock } from "utils/apiHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TablePage from "components/TablePage";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { TableButton, TableButtons } from "components/styled/Page";
import moment from "moment";
import { strIncludesCheck } from "utils/common";
import GlobalContext from "context/global-context";
import PayrollPopupWithLayouts, { PayrollPopupWithLayoutsOnYesObj } from "components/Reports/PayrollPopupWithLayouts";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { Notification, NotificationType } from "types/common";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { PayrollLock, PayrollLockStatus } from "types/models/payrollLocks";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { LockAction } from "./types";

interface LocksProps extends WithTranslation, RouteComponentProps, AllFlagsLDClient {
  setNotification: (notification: Notification) => void;
  showUlockedWarningPopUp: () => void;
}

interface LockState {
  locks: PayrollLock[];
  searchValue: string;
  isFetching: boolean;
  action: LockAction | null;
  confirmationPopupVisible: boolean;
  exportDialogVisible: boolean;
  selectedGroup: PayrollLock | null;
  selectedItemUuid: string | null;
  withForceRecalculate: boolean;
}

class Locks extends Component<LocksProps, LockState> {
  static contextType = GlobalContext;
  readonly state: LockState = {
    locks: [],
    searchValue: "",
    isFetching: true,
    action: null,
    confirmationPopupVisible: false,
    exportDialogVisible: false,
    selectedGroup: null,
    selectedItemUuid: null,
    withForceRecalculate: false,
  };

  async componentDidMount(): Promise<void> {
    await this.getState();
  }

  getState = async (): Promise<void> => {
    let locks = [];
    this.setState({ isFetching: true });

    const company = await this.context.getCompany();
    const locksListResp = await getLocksList({ companyUuid: company.uuid });
    const locksList: PayrollLock[] = locksListResp.content || [];

    // get created by employee details from cache
    // get all needed uuids
    const createdByEmployeesList: Record<string, any> = {};

    locksList.forEach((lock) => {
      createdByEmployeesList[lock.createdBy] = {};
    });
    // fetch details by uuids
    const { content } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, Object.keys(createdByEmployeesList)),
    );
    // assign details to object with uuids
    content.forEach((employee) => {
      createdByEmployeesList[employee.uuid] = employee;
    });

    locks = locksList.map((lock) => {
      const newLock = lock;
      newLock.createdBy = createdByEmployeesList[lock.createdBy]?.fullName || "";

      return newLock;
    });

    this.setState({
      locks,
      isFetching: false,
    });
  };

  toggleLock = async (item: string, action: LockAction): Promise<void> => {
    const { t, setNotification } = this.props;
    const params = {
      companyUuid: window.global_store.company.uuid,
      payrollLockUuid: item,
      requestedBy: window.global_store.profile.uuid,
    };
    let notification = "";

    try {
      if (action === LockAction.unlock) {
        await unlockPayrollLock(params);
        notification = t("You unlocked period");
      } else {
        await lockPayrollLock(params);
        notification = t("You locked period");
      }

      this.setState(
        {
          confirmationPopupVisible: false,
          selectedItemUuid: null,
          action: null,
        },
        () => setNotification({ notification, notificationType: NotificationType.success }),
      );

      void this.getState();
    } catch (e) {
      const err: any = e;
      let errorMessage = t("common|Something went wrong");

      if (err.message) {
        errorMessage = t(err.message);
      } else if (err.originalRequest?.errors?.length && err.originalRequest.errors[0].message) {
        errorMessage = t(err.originalRequest.errors[0].message);
      }

      this.setState(
        {
          confirmationPopupVisible: false,
          selectedItemUuid: null,
          action: null,
        },
        () => setNotification({ notification: errorMessage, notificationType: NotificationType.error }),
      );
    }
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchValue: ev.target.value });
  };

  onRowClick = (ev: MouseEvent<HTMLDivElement>, lock?: PayrollLock) => {
    const { history } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    if (lock?.uuid) {
      if (ev.metaKey) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: `/payroll/lock-periods/${lock.uuid}`,
        }).click();
      } else {
        history.push(`/payroll/lock-periods/${lock.uuid}`);
      }
    }
  };

  getTableColumns = (): iColumn<PayrollLock>[] => {
    const { t, showUlockedWarningPopUp, flags } = this.props;

    return [
      {
        label: t("Payroll group"),
        accessor: "name",
        minWidth: 160,
        Cell: (row): string => (row.value === "default" ? t("common|Default") : row.value),
        style: { lineHeight: "36px" },
      },
      {
        label: t("common|Employees"),
        accessor: "userProfilesCount",
        Cell: (row): number => row.value,
        width: 160,
      },
      {
        label: t("Period"),
        accessor: "endDate",
        Cell: (row): string =>
          `${moment(row.original.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
            row.value,
            "YYYY-MM-DD",
          ).format("DD/MM/YYYY")}`,
      },
      {
        label: t("Created by"),
        accessor: "createdBy",
        Cell: (row): string => row.value,
      },
      {
        label: t("common|Status"),
        accessor: "status",
        minWidth: 50,
        align: "center" as ColumnAlign,
        Cell: (row): JSX.Element => {
          const statusBadgeType: StatusBadgeStatuses = {
            [PayrollLockStatus.locked]: StatusBadgeStatuses.success,
            [PayrollLockStatus.unlocked]: StatusBadgeStatuses.default,
            [PayrollLockStatus.partial]: StatusBadgeStatuses.warn,
          }[row.value as PayrollLockStatus];

          return (
            <div style={{ maxWidth: "100px" }}>
              <StatusBadge value={t(row.value)} type={statusBadgeType} />
              <TableButtons className="buttons">
                <TableButton onClick={(ev): void => this.onRowClick(ev, row.original)}>{t("View")}</TableButton>
                <TableButton
                  onClick={(ev): void => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    if ([PayrollLockStatus.unlocked, PayrollLockStatus.partial].includes(row.original.status)) {
                      showUlockedWarningPopUp();
                    } else {
                      this.setState({ exportDialogVisible: true, selectedGroup: row.original });
                    }
                  }}
                >
                  {t("Export")}
                </TableButton>
                {row.original.status !== PayrollLockStatus.partial && (
                  <TableButton
                    onClick={(ev): void => {
                      ev.stopPropagation();
                      this.setState({
                        confirmationPopupVisible: true,
                        action: row.original.status === PayrollLockStatus.locked ? LockAction.unlock : LockAction.lock,
                        selectedItemUuid: row.original.uuid,
                      });
                    }}
                  >
                    {row.original.status === PayrollLockStatus.locked ? t("Unlock") : t("Lock")}
                  </TableButton>
                )}
                {flags.payrollExportRecalculate && row.original.status === PayrollLockStatus.locked && (
                  <TableButton
                    onClick={(ev): void => {
                      ev.preventDefault();
                      ev.stopPropagation();

                      if ([PayrollLockStatus.unlocked, PayrollLockStatus.partial].includes(row.original.status)) {
                        showUlockedWarningPopUp();
                      } else {
                        this.setState({
                          exportDialogVisible: true,
                          selectedGroup: row.original,
                          withForceRecalculate: true,
                        });
                      }
                    }}
                  >
                    {t("Send to payroll")}
                  </TableButton>
                )}
              </TableButtons>
            </div>
          );
        },
      },
    ];
  };

  render(): JSX.Element {
    const {
      locks,
      searchValue,
      isFetching,
      confirmationPopupVisible,
      exportDialogVisible,
      selectedGroup,
      action,
      selectedItemUuid,
      withForceRecalculate,
    } = this.state;
    const { t, history } = this.props;
    const filteredLocks = locks.filter((lock) => strIncludesCheck(lock.name, searchValue));

    return (
      <>
        <TablePage
          rows={filteredLocks}
          filters={
            <SearchInput
              modifiers={["filter"]}
              onChange={this.onSearch}
              placeholder={t("Search")}
              value={searchValue}
            />
          }
          columnSelectorOnFiltersRow
          columns={this.getTableColumns()}
          getTrProps={(_, rowInfo): { onClick: (e: any) => void } => ({
            onClick: (e) => this.onRowClick(e, rowInfo?.row._original),
          })}
          className="groups-table"
          loading={isFetching}
          noContentComponent={<NoContent>{t("You haven't locked any pay periods yet")}</NoContent>}
        />

        <ModalDialog
          isOpen={exportDialogVisible}
          onClose={(): void => this.setState({ exportDialogVisible: false, selectedGroup: null })}
        >
          <PayrollPopupWithLayouts
            title={t("Export Payroll")}
            payrollGroup={selectedGroup}
            withForceRecalculate={withForceRecalculate}
            onClose={(): void => {
              this.setState({ exportDialogVisible: false, selectedGroup: null, withForceRecalculate: false });
            }}
            onYes={(obj: PayrollPopupWithLayoutsOnYesObj): void => {
              history.replace(
                `/payroll/export/view-${obj.layoutUuid}?from=${obj.from}&to=${obj.to}&groupType=payroll_lock&groupUuid=${obj.payrollGroupUuid}&skipEmptyValues=${obj.skipEmptyValues}`,
              );
            }}
          />
        </ModalDialog>

        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={(): void => this.setState({ confirmationPopupVisible: false, action: null, selectedItemUuid: null })}
        >
          <Lightbox
            title={t("Are you sure?")}
            text={t("Are you sure you want to {{actionItem}} this period?", {
              actionItem: action === LockAction.lock ? t("Lock").toLowerCase() : t("Unlock").toLowerCase(),
            })}
            buttonYesTitle={action === LockAction.lock ? t("Lock") : t("Unlock")}
            buttonCancelTitle={t("Cancel")}
            onClose={(): void => {
              this.setState({ selectedItemUuid: null, action: null, confirmationPopupVisible: false });
            }}
            onYes={(): Promise<void> => this.toggleLock(selectedItemUuid as string, action as LockAction)}
          />
        </ModalDialog>
      </>
    );
  }
}

export default withLDConsumer()(withRouter(withTranslation("payment")(Locks)));
