const ELEMENT_SEPARATOR = "__";
const MODIFIER_SEPARATOR = "_";

function getBEMPAth({ b, e, m }) {
  const base = typeof e === "string" ? b + ELEMENT_SEPARATOR + e : b;

  return `${base} ${m
    .map((modifier) => modifier && base + MODIFIER_SEPARATOR + modifier)
    .filter((i) => i)
    .join(" ")}`.trim();
}

const BEM = {
  /**
   * Get BEM classnames factory by blockname.
   * Examples:
   * ```
   * b = BEM('menu');
   * classnames = b('item', ['disabled']) // 'menu__item menu__item_disabled'
   * classnames = b('user-icon', { hasNotifications: true ) // 'menu__user-icon menu__user-icon_hasNotifications'
   * ```
   */
  b(b) {
    return (elementName, modifiers = {}) => {
      let e;
      let m;

      if (elementName && typeof elementName === "string") {
        e = elementName;
      } else {
        modifiers = elementName || {};
      }

      if (modifiers instanceof Array) {
        m = modifiers;
      } else {
        m = Object.keys(modifiers).filter((modifier) => modifiers[modifier]) || [];
      }

      return getBEMPAth({ b, e, m });
    };
  },

  bR(b) {
    b = this.b(b);
    return (elementName, modifiers = {}) => ({ className: b(elementName, modifiers) });
  },
};

export default BEM;
