import { ChangeEvent, Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import { getTitle, strIncludesCheck } from "utils/common";
import { PageWrapper, TableButton, TableButtons } from "components/styled/Page";
import Select from "components/UI/Select";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { getClients } from "utils/apiHelpers";
import { Project, ProjectStatus } from "types/models/projects";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { RouteComponentProps } from "react-router-dom";
import { iCellInfo, TableHelper } from "utils/tableHelpers";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { Client } from "types/models/client";
import InstallExtensionModal from "components/Applications/InstallExtensionModal";
import getSymbolFromCurrency from "currency-symbol-map";
import FullPage from "components/Layout/FullPage";
import { ButtonState } from "components/controls/StyledButton";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import ProjectsProgressIndicator from "./ProjectsProgressIndicator";
import { archiveProject, deleteProject, getProjectsList } from "./projectsApiUtils";
import ProjectsPopupMessage from "./ProjectsPopupMessage";

const tableHelper = new TableHelper("ProjectsList");
const title = "Projects";
const metaTitle = title;
const StatusFilterWrapper = styled.div`
  margin-inline-start: 15px;
  width: 120px;
`;
const ButtonsWrapper = styled.div`
  .buttons {
    margin-top: -25px;
  }
`;

const getEstimatedColumnWidth = (actual: number, estimated: number, isCostEstimate: boolean): number => {
  const gap = 12;
  const charWidth = 8;
  const progressBarWidth = 100;
  const percentagesWidth = 56;
  const additionalChars = isCostEstimate ? 4 : 0;

  // default placeholder for values *h from *h
  return (
    `${actual}h from ${estimated}h`.length * charWidth +
    gap +
    progressBarWidth +
    gap +
    percentagesWidth +
    additionalChars
  );
};

interface ProjectsListProps extends WithTranslation, RouteComponentProps {}

interface ProjectsListState {
  items: Project[];
  searchStatus: string;
  searchValue: string;
  selectedItem: Project | null;
  archiveConfirmationPopupVisible: boolean;
  deleteConfirmationPopupVisible: boolean;
  popupVisible: boolean;
  loaded: boolean;
  notification: string;
  notificationType: NotificationType;
  estimatedCostCellMaxWidth: number;
  estimatedHoursCellMaxWidth: number;
  selectedColumns: string[];
}

class ProjectsList extends Component<ProjectsListProps, ProjectsListState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: ProjectsListProps) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      searchStatus: ProjectStatus.active,
      selectedItem: null,
      archiveConfirmationPopupVisible: false,
      deleteConfirmationPopupVisible: false,
      loaded: false,
      notification: "",
      searchValue: "",
      popupVisible: false,
      notificationType: NotificationType.success,
      estimatedCostCellMaxWidth: 277,
      estimatedHoursCellMaxWidth: 277,
      selectedColumns: tableHelper.getSelectedColumns([
        "name",
        "client",
        "workedMinutes",
        "estimatedCost",
        "spentServiceCost",
        "status",
      ]),
    };
    document.title = getTitle(t(metaTitle));
  }

  componentDidMount() {
    void this.getState();
  }

  async getState() {
    this.setState({ loaded: false });
    let { estimatedHoursCellMaxWidth, estimatedCostCellMaxWidth } = this.state;
    const company = await this.context.getCompany();

    const response = (await getProjectsList({ companyUuid: company.uuid })) as { content: Project[] };
    let projects: Project[] = response.content || [];
    let ownersUuids: string[] = [];
    ownersUuids = projects.map((project: Project) => project.ownerUuid);
    const { content } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(window.global_store.profile.uuid, ownersUuids),
    );

    let { clients } = (await getClients()) as { clients: Client[] };
    clients = clients || [];
    projects = projects.map((project) => {
      const employee = content.filter((emp) => emp.uuid === project.ownerUuid)[0];
      const client = clients.filter((cl) => cl.uuid === project.clientUuid)[0];
      // 87+12+100+12+56
      const estimatedCostCellWidth = getEstimatedColumnWidth(
        project.estimatedCost,
        project.spentEmployeeCost || 0,
        true,
      );
      if (estimatedCostCellWidth > estimatedCostCellMaxWidth) {
        estimatedCostCellMaxWidth = estimatedCostCellWidth;
      }
      const estimatedHoursCellWidth = getEstimatedColumnWidth(
        project.estimatedHours,
        project.workedMinutes || 0,
        false,
      );
      if (estimatedHoursCellWidth > estimatedHoursCellMaxWidth) {
        estimatedHoursCellMaxWidth = estimatedHoursCellWidth;
      }
      return { ...project, owner: employee?.fullName || "", client: client?.name || "" };
    });

    this.setState({
      estimatedCostCellMaxWidth,
      estimatedHoursCellMaxWidth,
      items: projects,
      loaded: true,
    });
  }

  onDeleteClick(item: Project) {
    this.setState({ deleteConfirmationPopupVisible: true, selectedItem: item });
  }

  onArchiveClick(item: Project) {
    this.setState({ archiveConfirmationPopupVisible: true, selectedItem: item });
  }

  onSearch = (ev: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchValue: ev.target.value });
  };

  onColumnsChange = (selectedColumns) => {
    tableHelper.setSelectedColumns(selectedColumns);
    this.setState({ selectedColumns });
  };

  getTableColumns = (): iColumn<Project>[] => {
    const { t } = this.props;
    const { estimatedCostCellMaxWidth, estimatedHoursCellMaxWidth } = this.state;
    const columns = [
      {
        label: "ID",
        accessor: "id",
        minWidth: 60,
      },
      {
        label: t(`${TranslationNamespaces.common}|Name`),
        accessor: "name",
        locked: true,
        minWidth: 160,
        style: { lineHeight: "36px" },
      },
      {
        label: t("Client"),
        accessor: "client",
        width: 160,
      },
      {
        label: t("Owner"),
        accessor: "owner",
        width: 160,
      },
      {
        label: t("Hours"),
        accessor: "workedMinutes",
        Cell: (row: iCellInfo<Project>) => {
          const { estimatedHours } = row.original;
          const workedHours = Math.round(row.original.workedMinutes / 60);
          return <ProjectsProgressIndicator estimated={estimatedHours} actual={workedHours} type="hours" />;
        },
        align: "start" as ColumnAlign,
        width: estimatedHoursCellMaxWidth,
      },
      {
        label: t("Cost"),
        accessor: "estimatedCost",
        Cell: (row: iCellInfo<Project>) => {
          const estimatedCost = row?.value || 0;
          const actual = row.original.spentEmployeeCost || 0;
          return <ProjectsProgressIndicator estimated={estimatedCost} actual={actual} type="cost" />;
        },
        align: "start" as ColumnAlign,
        width: estimatedCostCellMaxWidth,
      },
      {
        label: t("Billing"),
        accessor: "spentServiceCost",
        Cell: (row: iCellInfo<Project>) => {
          const spentServiceCost = row?.value || 0;
          const currency = getSymbolFromCurrency(window.global_store.company.currency) || "$";
          return (
            <span>
              {t(`${TranslationNamespaces.common}|value-with-currency`, { value: spentServiceCost, currency })}
            </span>
          );
        },
        align: "start" as ColumnAlign,
        width: 130,
      },
      {
        label: t(`${TranslationNamespaces.common}|Status`),
        accessor: "status",
        style: {
          position: "relative",
          overflow: "visible",
        },
        minWidth: 100,
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<Project>) => {
          const status = row.value;
          const tagType = status === ProjectStatus.active ? StatusTagType.active : StatusTagType.default;

          return (
            <ButtonsWrapper>
              <StatusTag value={t(`${TranslationNamespaces.common}|${status}`)} type={tagType} />
              <TableButtons className="buttons">
                {row.value === ProjectStatus.active && (
                  <TableButton
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.onArchiveClick(row.original);
                    }}
                  >
                    {t(`${TranslationNamespaces.common}|Archive`)}
                  </TableButton>
                )}
                <TableButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.onDeleteClick(row.original);
                  }}
                >
                  {t(`${TranslationNamespaces.common}|Delete`)}
                </TableButton>
              </TableButtons>
            </ButtonsWrapper>
          );
        },
      },
    ];
    return columns;
  };

  addItem = () => {
    const { t } = this.props;
    this.setState({ selectedItem: null, popupVisible: false });
    void this.getState();

    this.setState({ loaded: true, notification: t("Project was added") });
  };

  removeItem = async (selectedItem: Project) => {
    const { t } = this.props;
    const data = {
      companyUuid: window.global_store.company.uuid,
      projectUuid: selectedItem.uuid,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };

    this.setState({ loaded: false });
    await deleteProject(data);

    this.setState({
      loaded: true,
      notification: t("Project was deleted"),
      selectedItem: null,
      deleteConfirmationPopupVisible: false,
    });

    void this.getState();
  };

  archiveItem = async (selectedItem: Project) => {
    const { t } = this.props;
    if (!selectedItem) {
      return;
    }
    const data = {
      companyUuid: window.global_store.company.uuid,
      projectUuid: selectedItem.uuid,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };
    this.setState({ loaded: false });
    await archiveProject(data);

    this.setState({
      loaded: true,
      notification: t("Project was archived", { projectName: selectedItem.name }),
      selectedItem: null,
      archiveConfirmationPopupVisible: false,
    });
    void this.getState();
  };

  render() {
    const {
      items,
      searchValue,
      loaded,
      notification,
      notificationType,
      searchStatus,
      archiveConfirmationPopupVisible,
      deleteConfirmationPopupVisible,
      popupVisible,
      selectedItem,
      selectedColumns,
    } = this.state;
    const { t } = this.props;
    const actionButtonTitle = "Project";
    const noItemsTitle = "No Projects are available";

    const filteredItems = items.filter((s) => {
      let filter = strIncludesCheck(s.name, searchValue);
      if (searchStatus) {
        filter = filter && s.status === searchStatus;
      }
      return filter;
    });

    return (
      <FullPage
        title={t(title)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(actionButtonTitle)}
            onClick={() => this.setState({ popupVisible: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <TablePage<Project>
            rows={filteredItems}
            filters={
              <>
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t(`${TranslationNamespaces.common}|Search`)}
                  value={searchValue}
                />
                <StatusFilterWrapper>
                  <Select
                    value={searchStatus}
                    onChange={(val) => this.setState({ searchStatus: val })}
                    options={[
                      { value: "", label: t(`${TranslationNamespaces.common}|Status`) },
                      { value: "active", label: t(`${TranslationNamespaces.common}|Active`) },
                      { value: "archived", label: t(`${TranslationNamespaces.common}|Archived`) },
                    ]}
                  />
                </StatusFilterWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            columns={this.getTableColumns()}
            onColumnsChange={this.onColumnsChange}
            selectedColumns={selectedColumns}
            className="projects-table"
            getTrProps={(state, rowInfo) => ({
              onClick: (e: KeyboardEvent) => {
                if (rowInfo?.row._original.uuid) {
                  if (e.metaKey) {
                    Object.assign(document.createElement("a"), {
                      target: "_blank",
                      href: `/projects/${rowInfo.row._original.uuid}`,
                    }).click();
                  } else {
                    this.props.history.push(`/projects/${rowInfo.row._original.uuid}`);
                  }
                }
              },
            })}
            loading={!loaded}
            noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
          />
          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <ProjectsPopupMessage
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={this.addItem}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={archiveConfirmationPopupVisible}
            onClose={() => this.setState({ archiveConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Archive Project")}
              text={t("archive-project-confirmation")}
              buttonYesTitle={t("Yes, archive")}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ selectedItem: null, archiveConfirmationPopupVisible: false });
              }}
              onYes={() => selectedItem && this.archiveItem(selectedItem)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={deleteConfirmationPopupVisible}
            onClose={() => this.setState({ deleteConfirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Delete Project")}
              text={t("delete-project-confirmation")}
              buttonYesTitle={t("Yes, delete")}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ selectedItem: null, deleteConfirmationPopupVisible: false });
              }}
              onYes={() => selectedItem && this.removeItem(selectedItem)}
            />
          </ModalDialog>
          <InstallExtensionModal />
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.projects)(ProjectsList);
