import { Component, ContextType } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { fireEvent } from "utils/common";
import { SuperpunchContext } from "context/SuperpunchProvider";
import { DailySummaryPunch, PunchKey, PunchType } from "types/models/punches";
import { SuperpunchPunchCellData } from "types/models/superpunch";
import { OnPunchActionData, OnPunchDeclineData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import Tooltip from "components/UI/Tooltip";
import Cell from "./Cell";
import CellPunchDropdown from "./CellPunchDropdown";

const CellWrapper = styled.div`
  width: 100%;
  height: 50px;
  border: none;
  position: relative;
`;

interface CellPunchProps extends AllFlagsLDClient {
  t: WithTranslation["t"]; // for Dropdown
  value: SuperpunchPunchCellData;
  date: moment.Moment;
  disabled: boolean;
  inactive: boolean;
  eventKey: PunchKey;
  punchType: PunchType;
  employeeUuid: string;
  breakTypeName: string | null;
  breakTypeUniqueId: string | null;
  isLockedDay: boolean;
}

interface CellPunchState {
  requestFailed: boolean;
  isOpen: boolean;
  value: SuperpunchPunchCellData;
}

class CellPunch extends Component<CellPunchProps, CellPunchState> {
  static contextType = SuperpunchContext;
  context!: ContextType<typeof SuperpunchContext>;

  constructor(props: CellPunchProps) {
    super(props);

    this.state = {
      requestFailed: false,
      isOpen: false,
      value: props.value,
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(`${this.getCellKey()}_failed`, this.handleRequestFailed);
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }

  componentWillUnmount() {
    document.removeEventListener(`${this.getCellKey()}_failed`, this.handleRequestFailed);
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }

  getCellKey = () => {
    const { eventKey, date } = this.props;

    return `sp_${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}${eventKey}`;
  };

  handleRequestFailed = () => {
    this.setState({ requestFailed: true });
  };

  handleDataLoad = (ev: CustomEvent<SuperpunchPunchCellData>) => {
    this.setState({ value: ev.detail, requestFailed: false });
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    this.setState({ isOpen });
  };

  onChange = (punch?: DailySummaryPunch | Record<string, never>) => {
    const { changePunch, employeeInfo } = this.context;
    const { punchType, eventKey, date } = this.props;

    this.toggleOpen(false);

    if (punch) {
      changePunch({
        punch,
        destinationDate: date.clone(),
        selectedType: punchType,
        eventKey,
      });
    } else {
      fireEvent(SuperpunchCustomEvents.openNewPunchOverlay, {
        employeeInfo,
        selectedDate: date.clone(),
        selectedType: punchType,
        selectedEventKey: eventKey,
      });
    }
  };

  onPunchApprove = (data: OnPunchActionData) => {
    void this.context.onPunchApprove(data);
    this.toggleOpen(false);
  };

  onPunchValidate = (data: OnPunchActionData) => {
    void this.context.onPunchValidate(data);
    this.toggleOpen(false);
  };

  onPunchDecline = (data: OnPunchDeclineData) => {
    void this.context.onPunchDecline(data);
    this.toggleOpen(false);
  };

  render() {
    const { value, isOpen, requestFailed } = this.state;
    const { date, disabled, inactive, employeeUuid, breakTypeName, breakTypeUniqueId, isLockedDay, flags } = this.props;
    const isDropdownOpen = isOpen;

    return (
      <CellWrapper>
        <div data-tip={!!breakTypeUniqueId} data-for={breakTypeUniqueId} style={{ height: "50px" }}>
          <Cell
            requestFailed={requestFailed}
            inactive={inactive}
            date={date}
            punch={value}
            onClick={() => !disabled && (!isLockedDay || (isLockedDay && flags.punchEventsLog)) && this.toggleOpen()}
            disabled={disabled || (isLockedDay && !flags.punchEventsLog)}
          />
        </div>
        {breakTypeName && breakTypeUniqueId && <Tooltip id={breakTypeUniqueId}>{breakTypeName}</Tooltip>}

        {isDropdownOpen && (
          <ClickOutside
            onClickOutside={(ev: MouseEvent) => {
              this.toggleOpen(false);
              ev.stopPropagation();
            }}
          >
            <CellPunchDropdown
              employeeUuid={employeeUuid}
              value={value}
              eventKey={this.props.eventKey}
              date={date}
              isLockedDay={isLockedDay}
              onChange={this.onChange}
              onPunchApprove={this.onPunchApprove}
              onPunchValidate={this.onPunchValidate}
              onPunchDecline={this.onPunchDecline}
              hideDropdown={() => this.toggleOpen(false)}
            />
          </ClickOutside>
        )}
      </CellWrapper>
    );
  }
}

export default withLDConsumer()(CellPunch);
