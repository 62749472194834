import { LegacyRef } from "react";
import { Circle, GoogleMap as GoogleMapFromLib, GoogleMapProps, MarkerProps } from "react-google-maps";
import GoogleMap from ".";
import Marker from "./Marker";

interface GoogleMapPreviewProps {
  lat?: number;
  lng?: number;
  zoom?: number;
  radius?: number;
  height?: string;
  googleMapProps?: Record<string, never> | GoogleMapProps;
  showCircle?: boolean;
  className?: string;
  mapRef: LegacyRef<GoogleMapFromLib>;
  onMarkerDragStart: MarkerProps["onDragStart"];
  onMarkerDragEnd: MarkerProps["onDragEnd"];
}

const GoogleMapPreview = ({
  lat = 0,
  lng = 0,
  zoom = 15,
  radius = 50,
  height = "200px",
  googleMapProps = {},
  showCircle = true,
  className,
  mapRef,
  onMarkerDragStart,
  onMarkerDragEnd,
}: GoogleMapPreviewProps) => {
  const { options, ...restGoogleMapsProps } = googleMapProps;
  const position = { lat, lng };

  return (
    <div>
      <GoogleMap
        mapRef={mapRef}
        defaultZoom={zoom}
        defaultCenter={position}
        height={height}
        options={{
          draggable: true,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          ...options,
        }}
        className={className || ""}
        {...restGoogleMapsProps}
      >
        <Marker
          position={position}
          draggable={!!onMarkerDragEnd}
          onDragStart={onMarkerDragStart}
          onDragEnd={onMarkerDragEnd}
        />
        {showCircle && (
          <Circle
            center={position}
            radius={radius}
            // var(--colors-unknown36);
            options={{ fillColor: "#4A90E2", fillOpacity: 0.3, strokeWeight: 0 }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapPreview;
