import { ReportType, ClientReportsType, SelectedClient, SetIsLoading, OnError } from "types/reports";
import { Component } from "react";
import Select from "components/UI/Select";
import { SelectOption } from "types/ui";
import moment from "moment";
import { hasEmployeesAccess } from "utils/common";
import FullPage from "components/Layout/FullPage";
import { withTranslation, WithTranslation } from "react-i18next";
import NoContent from "components/NoContent";
import NotificationRow from "components/NotificationRow";
import ClientsDropdown from "components/ClientsDropdown";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import styled from "styled-components";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga from "utils/ga";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { getReportsPageTitle } from "./ReportsTitle";
import { Page, ClientReportTypeWrapper, ClientsDropdownWrapper } from "./reportsStyledComponents";
import ReportSearchFiltersBase from "./ReportSearchFiltersBase";
import ReportsClientsTable from "./ReportsClientsTable";

const Spacer = styled.div`
  height: 40px;
`;

interface ReportsPageClientsState {
  clientReportType: ClientReportsType;
  isGenerateButtonLocked: boolean;
  showInactiveEmploees: boolean;
  skipSupervisors: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  isReportVisible: boolean;
  selectedClient: SelectedClient;
  key: number;
  notification: string | null;
  notificationType: NotificationType;
}

type ReportsPageClientsProps = WithTranslation;

class ReportsPageClients extends Component<ReportsPageClientsProps, ReportsPageClientsState> {
  CLIENT_REPORT_TYPES: SelectOption<ClientReportsType>[];

  constructor(props: ReportsPageClientsProps) {
    super(props);
    const { t } = props;

    this.CLIENT_REPORT_TYPES = [
      { value: ClientReportsType.clientList, label: t("Client List") },
      { value: ClientReportsType.locations, label: t("Locations") },
      { value: ClientReportsType.employees, label: t("Employees") },
    ];

    this.state = {
      selectedClient: null,
      clientReportType: this.CLIENT_REPORT_TYPES[0].value,
      isGenerateButtonLocked: false,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      showInactiveEmploees: false,
      skipSupervisors: false,
      isReportVisible: false,
      key: new Date().getTime(),
      notification: null,
      notificationType: NotificationType.error,
    };
  }

  onGenerateButtonClick = (): void => {
    ga.trackGenerateReport(ReportType.clients);

    this.setState({
      notification: null,
      isReportVisible: true,
      key: new Date().getTime(),
    });
  };

  onResetButtonClick = (): void => {
    this.setState({
      notification: null,
      isReportVisible: false,
      clientReportType: ClientReportsType.clientList,
      selectedClient: null,
      skipSupervisors: false,
      showInactiveEmploees: false,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
    });
  };

  setIsLoading: SetIsLoading = (isLoading) => {
    this.setState({ isGenerateButtonLocked: isLoading });
  };

  onError: OnError = (message) => {
    this.setState({ isReportVisible: false, notification: message, notificationType: NotificationType.error });
  };

  render() {
    const { t } = this.props;
    const {
      selectedClient,
      clientReportType,
      startDate,
      endDate,
      isGenerateButtonLocked,
      showInactiveEmploees,
      skipSupervisors,
      isReportVisible,
      key,
      notification,
      notificationType,
    } = this.state;

    // don't show report if client is not selected
    const showReport =
      isReportVisible &&
      (clientReportType === ClientReportsType.clientList ||
        ((clientReportType === ClientReportsType.locations || clientReportType === ClientReportsType.employees) &&
          selectedClient?.value !== null));

    const reportParams = {
      selectedClient,
      clientReportType,
      startDate,
      endDate,
      showInactiveEmploees,
      skipSupervisors,
    };

    return (
      <FullPage title={getReportsPageTitle(t, ReportType.clients)}>
        <Page>
          <ReportSearchFiltersBase
            onGenerate={this.onGenerateButtonClick}
            generateButtonDisabled={isGenerateButtonLocked}
            onReset={this.onResetButtonClick}
          >
            <ClientReportTypeWrapper>
              <Select
                value={clientReportType}
                onChange={(value: ClientReportsType) =>
                  this.setState({ clientReportType: value, isGenerateButtonLocked: false })
                }
                options={this.CLIENT_REPORT_TYPES}
              />
            </ClientReportTypeWrapper>
            {clientReportType !== "clientList" && (
              <ClientsDropdownWrapper>
                <ClientsDropdown
                  value={selectedClient?.value || ""}
                  onChange={(value, option) => this.setState({ selectedClient: option, isGenerateButtonLocked: false })}
                />
              </ClientsDropdownWrapper>
            )}
            <AdditionalFiltersControl
              closeOnChange={false}
              options={[
                {
                  label: translateEmployeeTerm(
                    t,
                    TranslationNamespaces.reportsPage,
                    "custom-show-inactive-employees",
                    `${TranslationNamespaces.reportsPage}|Show inactive employees`,
                  ),
                  checked: showInactiveEmploees,
                  onChange: (val: boolean) =>
                    this.setState({ showInactiveEmploees: val, isGenerateButtonLocked: false }),
                },
                {
                  label: t("Hide Supervisor"),
                  checked: skipSupervisors,
                  onChange: (val: boolean) => this.setState({ skipSupervisors: val, isGenerateButtonLocked: false }),
                },
              ]}
            />
            <DateRangePicker
              newOnChangeApproach
              availableDaysCount={window.global_store.beta ? 1900 : 186}
              isAdmin={hasEmployeesAccess()}
              onChange={(sd, ed) => {
                if (sd && ed) {
                  this.setState({ startDate: sd, endDate: ed, isGenerateButtonLocked: false });
                }
              }}
              startDate={startDate}
              endDate={endDate}
            />
          </ReportSearchFiltersBase>
          {notification && <NotificationRow withCloseButton={false} type={notificationType} message={notification} />}
          <Spacer />
          {showReport ? (
            <ReportsClientsTable {...reportParams} key={key} setIsLoading={this.setIsLoading} onError={this.onError} />
          ) : (
            <NoContent>{t("Select your report type")}</NoContent>
          )}
        </Page>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.reportsPage)(ReportsPageClients);
