import { TranslationNamespaces, WithTranslation } from "types/translationNamespaces";
import CONFIG from "config";
import { LocalStorageKeys, getLsEmployeeTerm } from "./localStorageUtils";

export enum AvailableLocales {
  pt = "pt-BR",
  en = "en-US",
  he = "he-IL",
  es = "es-ES",
}
// it = "it-IT",
// de = "de-DE",

export const translateEmployeeTerm = (
  t: WithTranslation["t"],
  namespace: TranslationNamespaces,
  customKey: string,
  fallback: string,
  translationOptions: any = {},
): string => {
  if (getLsEmployeeTerm()) {
    return t(`${namespace}|${customKey}`, { ...translationOptions, ...getLsEmployeeTerm() }) as string;
  }

  return t(fallback, translationOptions) as string;
};

const getDefaultLocale = () => (CONFIG.globalMode ? AvailableLocales.en : AvailableLocales.pt);

export const saveLocale = (locale: string | null) =>
  localStorage.setItem(LocalStorageKeys.locale, locale || getDefaultLocale());

export const getLocale = () => localStorage.getItem(LocalStorageKeys.locale) || getDefaultLocale();
