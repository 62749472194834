import { useState, ChangeEventHandler, useEffect } from "react";
import { stylesheet } from "astroturf";
import { searchClose } from "components/svg-images";
import { SearchInput } from "../SearchInput";
import { SelectList, OptionProps, GroupProps, OptionsGroup, SelectListProps } from "./SelectList";
import type { Option } from "./SelectList";
import { normalizeString } from "./normalize-string";
import { filterNested } from "./filter-nested";

export type { OptionProps, GroupProps };

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--colors-default);

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    padding: 20px 16px;
    border-bottom: 1px solid var(--colors-separator);

    .Search {
      height: auto;
      padding: 0;
      background: none;
    }

    .IconClose {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .Options {
    overflow-y: auto;
    background: var(--colors-default);
  }
}
`;

function searchOptions(options, searchText: string) {
  const str = normalizeString(searchText);
  if (str === "") return options;
  const predicate = (opt) => opt.searchTerms?.some((term) => term.includes(str));
  const foundOpts = filterNested(options, predicate, "options");
  return foundOpts;
}

export interface SelectProps {
  options: (Option | OptionsGroup)[];
  onSearch?(text: string): void;
  searchPlaceholder?: string;
  onChange(levels: [...parents: OptionsGroup[], opt: Option]): void;
  onClose?(): void;
  autoFocus?: boolean;
  Group?: SelectListProps["Group"];
  Option?: SelectListProps["Option"];
  Search?: React.JSXElementConstructor<React.HTMLProps<HTMLInputElement>>;
}
export function Select({
  options,
  onSearch,
  searchPlaceholder = "Search",
  onChange,
  autoFocus,
  Group,
  Option,
  Search = SearchInput,
  onClose,
}: SelectProps) {
  const [foundOptions, setFoundOptions] = useState(options);
  const [searchQuery, setSearchQuery] = useState("");

  const search: ChangeEventHandler<HTMLInputElement> = (event) => {
    const searchText = event.target.value;
    setSearchQuery(searchText);
    onSearch && onSearch(searchText);
  };

  useEffect(() => {
    const opts = searchOptions(options, searchQuery);
    setFoundOptions(opts);
  }, [options, searchQuery]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <Search className={styles.Search} placeholder={searchPlaceholder} autoFocus={autoFocus} onChange={search} />
        {searchQuery === "" && onClose && (
          <div className={styles.IconClose} onClick={onClose}>
            {searchClose}
          </div>
        )}
      </div>
      <div className={styles.Options}>
        <SelectList options={foundOptions} onChange={onChange} Group={Group} Option={Option} />
      </div>
    </div>
  );
}
