import React, { PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";
import ReactTooltip, { Place, TooltipProps } from "react-tooltip";
import styled from "styled-components";

const domNode = document.createElement("div");
document.body.appendChild(domNode);

const BodyPortal = ({ children }: { children: ReactNode }) => createPortal(children, domNode);

const Wrapper = styled.div<{ maxWidth?: string; zIndex?: number }>`
  .__react_component_tooltip {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--component-tooltip-gap, 2px);
    padding: var(--component-tooltip-vpad, 8px) var(--component-tooltip-hpad, 12px) !important;
    font-size: 12px;
    font-weight: 450;
    line-height: 14px;
    letter-spacing: -0.08px;
    border-radius: var(--shapes-border-radius-default);
    background: var(--component-tooltip-bg-color, #2a2e3be0);

    .title {
      font-weight: 700;
    }

    z-index: ${(props) => props.zIndex ?? "initial"};
    max-width: ${(props) => props.maxWidth ?? "initial"};

    text-overflow: ellipsis;
    overflow: hidden;

    &:before,
    &:after {
      display: none;
    }
  }
`;

interface Props extends PropsWithChildren<Omit<TooltipProps, "children">> {
  place?: Place;
  className?: string;
  delayShow?: number;
  backgroundColor?: string;
  title?: React.ReactNode;
  wrapperStyle?: {
    maxWidth?: string;
    zIndex?: number;
  };
}

const BasicTooltip: React.FC<Props> = ({ children, wrapperStyle, ...props }) => {
  return (
    <BodyPortal>
      <Wrapper {...wrapperStyle}>
        <ReactTooltip delayShow={200} {...props}>
          {props.title && <div className="title">{props.title}</div>}
          {children}
        </ReactTooltip>
      </Wrapper>
    </BodyPortal>
  );
};

export default BasicTooltip;
