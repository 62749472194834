import SafeInput, { Selection } from "../../../views/SafeInput";

function validateTime(HHmm: string) {
  if (!HHmm) return false;
  const re = /^(\d{1,3}):[0-5][0-9]$/;

  return HHmm.match(re) !== null;
}

function validateInput(val: string) {
  const re = /^(\d{0,3}?):?[0-5]?[0-9]?$/;
  return val.match(re) !== null;
}

function prepareInput(val: string) {
  let v = val;
  v = v.replace(/[^0-9:]/g, "");
  const firstColonIndex = v.indexOf(":");
  if (firstColonIndex !== -1) {
    // because lookbehind is not supported by safari
    v = `${v.slice(0, firstColonIndex + 1)}${v.slice(firstColonIndex + 1).replace(/:/g, "")}`;
  }
  if (!v.includes(":") && v.length > 3) {
    v = `${v.slice(0, 3)}:${v.slice(3)}`;
  }
  const isValid = validateInput(v);
  if (!isValid) return null;
  return v;
}

function completeInput(value: string) {
  let val = value;
  const [h, m = ""] = val.split(":");
  const hNum = Number.parseInt(h || "0", 10);
  const mNum = Number.parseInt(m || "0", 10);
  const mmStr = mNum < 6 ? m.padEnd(2, "0") : m.padStart(2, "0");
  val = `${String(hNum).padStart(2, "0")}:${mmStr}`;

  const isValid = validateTime(val);
  if (!isValid) {
    val = "00:00";
  }
  return val;
}

function smartInput(val: string, sel: Selection, key: string) {
  const [ss, se] = sel[0] < sel[1] ? sel : [sel[1], sel[0]];
  if (ss !== se) return null;

  const separators = " :.,;/";
  if (val.length === 0 && ss === 0 && separators.includes(key)) {
    return { cursor: 3, value: "00:" };
  }
  if (val.match(/^[0-9]{3}$/) && ss === 3) {
    if (separators.includes(key)) {
      return { cursor: 4, value: `${val}:` };
    }
    if ("012345".includes(key)) {
      return { cursor: 5, value: `${val}:${key}` };
    }
    if ("6789".includes(key)) {
      return { cursor: 6, value: `${val}:0${key}` };
    }
  }
  if (val.match(/^[0-9][0-9]:$/) && ss === 3) {
    if ("6789".includes(key)) {
      return { cursor: 5, value: `${val}0${key}` };
    }
  }
  if (val.match(/^[0-9]{3}:$/) && ss === 4) {
    if ("6789".includes(key)) {
      return { cursor: 6, value: `${val}0${key}` };
    }
  }

  if (separators.includes(key) && val[ss] === ":") {
    return { cursor: ss + 1, value: val };
  }
  if (separators.includes(key) && !val.match(/:/)) {
    return { cursor: ss + 1, value: `${val.substring(0, ss)}:${val.substring(ss)}` };
  }
  if ("0123456789".includes(key)) {
    return { cursor: ss + 1, value: `${val.substring(0, ss)}${key}${val.substring(ss)}` };
  }
  return null;
}

export const DurationInput = ({
  value,
  onChange,
  placeholder,
  className,
}: {
  value: string;
  onChange: (val: string) => string | void;
  placeholder: string;
  className: string;
}) => (
  <SafeInput
    placeholder={placeholder}
    className={className}
    smartInput={smartInput}
    prepareInput={prepareInput}
    completeInput={completeInput}
    value={value}
    onChangeFinished={onChange}
  />
);
