import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { generateIntegrationCredentials, revealIntegrationSecret, generateIntegrationTmpCode } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { TranslationNamespaces } from "types/translationNamespaces";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import useState from "hooks/useState";
import GlobalContext from "context/global-context";
import { showSnackbar } from "utils/common";
import { FieldTitle, GenerateLink } from "./styled";
import FieldWithCopy from "./FieldWithCopy";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  input {
    width: 260px;
  }
`;

const Description = styled.p`
  margin: 0;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-800);
  margin-bottom: 33px;
`;

interface SystemIntegrationViewState {
  clientId: string;
  clientSecret: string;
  tokenGenerated: boolean;
  tmpCode: string;
}

const SystemIntegrationView = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const [state, setState] = useState<SystemIntegrationViewState>({
    clientId: "",
    clientSecret: "",
    tmpCode: "",
    tokenGenerated: false,
  });
  const { clientId, clientSecret, tmpCode, tokenGenerated } = state;
  const context = useContext(GlobalContext);

  useEffect(() => {
    async function init() {
      const company = await context.getCompany();

      setState({
        clientId: company.client_id || "",
        clientSecret: company.client_secret || "",
      });
    }
    void init();
  }, [context]);

  const generateSecret = async () => {
    const credentials = await generateIntegrationCredentials();

    await context.refetchCompany();

    setState({
      tokenGenerated: true,
      clientId: credentials.clientId,
      clientSecret: credentials.clientSecret,
    });
  };

  const revealSecret = async () => {
    const company = await context.getCompany();

    if (company) {
      try {
        const { content } = await revealIntegrationSecret({ companyUuid: company.uuid, clientId });

        setState({
          tokenGenerated: true,
          clientSecret: content.revealedClientSecret,
        });
      } catch (err: Error | any) {
        sentryUtils.sendError(err);
        showSnackbar({
          text: err?.message ? t(err?.message) : t(`${TranslationNamespaces.common}|Something went wrong`),
        });
      }
    }
  };

  const generateTmpCode = async () => {
    const company = await context.getCompany();

    if (company) {
      try {
        const { content } = await generateIntegrationTmpCode({ companyUuid: company.uuid });

        setState({ tmpCode: content });
      } catch (err) {
        sentryUtils.sendError(err);
      }
    }
  };

  return (
    <Wrapper>
      <Description>{t("app-access-description")}</Description>
      <FieldTitle>{t("CLIENT ID")}</FieldTitle>
      <FieldWithCopy value={clientId} copyButtonEnabled={!!clientId} />
      <br />
      <FieldTitle>{t("CLIENT SECRET")}</FieldTitle>
      <FieldWithCopy value={clientSecret} copyButtonEnabled={!!(clientSecret && tokenGenerated)} />
      <br />
      {clientId && (
        <GenerateLink
          href=""
          onClick={(ev) => {
            ev.preventDefault();

            void revealSecret();
          }}
        >
          {t("Reveal Client Secret")}
        </GenerateLink>
      )}
      <GenerateLink
        href=""
        onClick={(ev) => {
          ev.preventDefault();

          void generateSecret();
        }}
      >
        {t("Generate a new Client Secret")}
      </GenerateLink>
      <>
        <br />
        <br />
        <br />
        <FieldTitle>{t("TEMPORARY CODE")}</FieldTitle>
        <FieldWithCopy value={tmpCode} copyButtonEnabled={!!tmpCode} />
        <GenerateLink
          href=""
          onClick={(ev) => {
            ev.preventDefault();

            void generateTmpCode();
          }}
        >
          {t("Generate a temporary code")}
        </GenerateLink>
      </>
    </Wrapper>
  );
};

interface SystemIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SystemIntegrationModal = ({ isOpen, onClose }: SystemIntegrationModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <SidePopupOverlay isOpen={isOpen} header={t("Application access")} onClose={onClose}>
      <SystemIntegrationView />
    </SidePopupOverlay>
  );
};

export default SystemIntegrationModal;
