import cookie from "react-cookies";
import { getAuthTokenFromApiResponse } from "utils/common";
import { ActiveUserProfile, UserProfileRole, UserProfileStatus } from "types/models/userProfile";
import { ShiftEventLog } from "types/models/schedule";
import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  ChooseProfileRequestData,
  ChooseProfileResponseData,
  ChooseProfileReturnData,
  GetActiveUserProfilesResponseData,
  GetScheduleEventsRequestData,
  GetScheduleEventsResponseData,
} from "./types";

type TODORequestData = never;
type TODOResponseData = Promise<never>;

export function refetchProfiles(): TODOResponseData {
  return new Promise((res, rej) => {
    sendRequest({}, "get", "/user_profiles/select")
      .then((profiles) => {
        let availableProfiles = [];
        if (profiles.length > 0) {
          availableProfiles = profiles.filter(
            (p) =>
              (p.role === "supervisor" || p.role === "employer" || p.role === "employee") &&
              p.employee_status === "active" &&
              p.company,
          );
        }
        localStorage.setItem("gs_profiles", JSON.stringify(availableProfiles));
        res(availableProfiles);
      })
      .catch(() => rej());
  });
}

export async function getActiveUserProfiles(): APIResponse<ActiveUserProfile[] | false> {
  try {
    const response = await sendRequest<Record<string, never>, GetActiveUserProfilesResponseData>(
      {},
      "get",
      "/user_profiles/select",
    );
    const profiles = response || [];

    return profiles.filter(
      (p) =>
        (p.role === UserProfileRole.supervisor ||
          p.role === UserProfileRole.employer ||
          p.role === UserProfileRole.employee) &&
        p.can_login &&
        p.company,
    );
  } catch (error) {
    return false;
  }
}

export async function getCurrentUserProfile(): TODOResponseData {
  try {
    localStorage.removeItem("gs_profile");
    const response = await sendRequest({}, "get", "/user_profiles/current");
    localStorage.setItem("gs_profile", JSON.stringify(response.user_profile));
    window.global_store.profile = response.user_profile;
    const authToken = getAuthTokenFromApiResponse({
      ...response,
      selected_user_profile_uuid: response.user_profile.uuid,
    });
    if (authToken) {
      cookie.remove("userEmail", { path: "/" });
      cookie.remove("userToken", { path: "/" });
      localStorage.setItem("oi_auth", authToken);
    }
    return response.user_profile;
  } catch (error) {
    return false;
  }
}

export async function chooseProfile(
  data: ChooseProfileRequestData,
  overrideToken = false,
): Promise<ChooseProfileReturnData> {
  try {
    const response = await sendRequest<Record<string, never>, ChooseProfileResponseData>(
      {},
      "get",
      `/user_profiles/choose?id=${data.profileId}`,
    );

    const authToken = getAuthTokenFromApiResponse({
      ...response,
      selected_user_profile_uuid: overrideToken ? response.user_profile.uuid : undefined,
    });

    if (authToken) {
      cookie.remove("userEmail", { path: "/" });
      cookie.remove("userToken", { path: "/" });
      localStorage.setItem("oi_auth", authToken);
    }

    localStorage.setItem("gs_profile", JSON.stringify(response.user_profile));

    window.global_store.profile = response.user_profile;
    return response.user_profile;
  } catch (error) {
    return null;
  }
}

export function getScheduleEvents(data: GetScheduleEventsRequestData): APIResponse<GetScheduleEventsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/user_profiles/${data.userProfileUUID}/shifts?from=${data.dateFrom}&to=${data.dateTo}&requested_by=${data.supervisorUUID}`,
    CONFIG.client_admin_api,
  );
}

export function getShiftCompilations(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "GET", `/user_profiles/${data.employeeId}/shift_compilations/${data.date}`);
}

export function getShiftCompilationEventsLog(data: {
  userProfineUuid: string;
  shiftId: string;
  eventKey: string;
}): Promise<{
  content: ShiftEventLog[];
}> {
  return sendRequest(
    {},
    "GET",
    `/user_profiles/${data.userProfineUuid}/shift_compilations/events_log?key=${data.eventKey}&shift_id=${data.shiftId}`,
  );
}

export function getCompanyUsersExternalIdList(): Promise<string[]> {
  return sendRequest({}, "GET", "/user_profiles/external_ids");
}
