import styled from "styled-components";
import BEM from "utils/BEM";
import Avatar from "components/views/Avatar";
import "styles/user.scss";
import { AvatarUserStatus } from "./views/Avatar/Avatar";

const b = BEM.b("user");
const NamePosition = styled.div`
  margin-inline-start: 20px;
`;

type UserProps = {
  user: {
    fullName: string;
    avatarId: string;
    demo?: boolean;
    matricula?: string;
    position?: string;
  };
  status?: AvatarUserStatus;
  textToHighlight?: string;
};

const highlightText = (fullName: string, textToHighlight: string) => {
  const safeRegExpStr = textToHighlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regExp = new RegExp(`(${safeRegExpStr})`, "gi");
  const chunks = fullName.split(regExp);

  return chunks.map((chunk, i) => {
    if (chunk.toLowerCase() === textToHighlight.toLowerCase()) {
      const key = chunk + i;
      return (
        <span key={key} style={{ backgroundColor: "rgb(215,233,251)", color: "#1E97F7" }}>
          {chunk}
        </span>
      );
    }
    return chunk;
  });
};

const User = ({ user, status, textToHighlight }: UserProps) => (
  <div className={b()}>
    <Avatar user={{ fullName: user.fullName, avatarId: user.avatarId }} status={status} />
    <NamePosition className={b("name-position")}>
      <div className={b("name", { demo: user.demo })}>
        {textToHighlight ? highlightText(user.fullName, textToHighlight) : user.fullName}
      </div>
      <div>
        {user.matricula && (
          <span className={b("matricula")}>
            {textToHighlight ? highlightText(user.matricula, textToHighlight) : user.matricula} -{" "}
          </span>
        )}
        {user.position && <span className={b("position")}>{user.position}</span>}
      </div>
    </NamePosition>
  </div>
);

export default User;
