import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import {
  BusinessRuleGroup,
  BusinessRuleGroupStatus,
  BusinessRuleNightShift,
  BusinessRuleType,
  HoursDistributionRule,
} from "types/models/businessRulesGroup";
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { useCallback, useState } from "react";
import { updateBRGroupSettings } from "utils/apiHelpers";
import { convertTimeToMinutes } from "utils/scheduleHelpers";
import { Subheader } from "../styled";
import HoursDistributionTable from "./HoursDistributionTable";
import NightShiftConfig from "./NightShiftConfig";

type ErrorType = Record<string, Record<string, string>> | null;

const NS_CONFIG_ERRORS_KEY = "nightShiftConfig";

const Wrapper = styled.div``;

type PunchingRulesGroupHoursDistributionProps = {
  onSave: (data: { notification: string; notificationType: NotificationType }) => void;
  group: BusinessRuleGroup;
};

const PunchingRulesGroupHoursDistribution = ({ onSave, group }: PunchingRulesGroupHoursDistributionProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const [errors, setErrors] = useState<ErrorType>({});
  const [isLoading, setIsloading] = useState(false);
  const [confirmationPopupVisible, setConfirmationVisible] = useState(false);
  const { hoursDistribution, nightShift } = group?.businessRules?.[0]?.rules;
  const [rules, setRules] = useState<HoursDistributionRule[]>(hoursDistribution?.rules || []);
  const [nightShiftConfig, setNightShiftConfig] = useState<BusinessRuleNightShift>(nightShift);
  const { isLocked, status } = group;
  const isDisabled = status === BusinessRuleGroupStatus.deactivated;

  const validateHoursDistributionRules = useCallback(() => {
    let err: ErrorType = {};

    rules.forEach((rule) => {
      const ruleErrors: Record<string, string> = {};

      if (!rule.name) {
        ruleErrors.name = t("Name is required");
      } else {
        // name should be unique
        const isNameUnique = rules.filter((p) => p.uuid !== rule.uuid).every((p) => p.name !== rule.name);
        if (!isNameUnique) {
          ruleErrors.name = t("Name should be unique");
        }
      }

      // if (rule.limit === 0) {
      //   phaseErrors.limit = t("Limit is required");
      // }

      if (Object.keys(ruleErrors).length !== 0) {
        err = { ...err, [rule.uuid]: ruleErrors };
      }
    });

    return err;
  }, [rules, t]);

  const validateNightShift = useCallback(() => {
    const { nightShiftStartTime, nightShiftEndTime, name, active } = nightShiftConfig;

    if (!active) {
      return null;
    }

    let err: Record<string, string> | null = {};

    if (!nightShiftStartTime) {
      err.nightShiftStartTime = t("Start Time can't be empty");
    }

    if (!name) {
      err.name = t("Percentage can't be empty");
    }

    if (!nightShiftEndTime) {
      err.nightShiftEndTime = t("End Time can't be empty");
    }

    if (!err.nightShiftStartTime && !err.nightShiftEndTime) {
      if (convertTimeToMinutes(nightShiftStartTime) < 720 && convertTimeToMinutes(nightShiftEndTime) > 720) {
        // night shift time validation
        err.nightShiftEndTime = t("Night shift can't be created over noon");
      }
    }

    if (Object.keys(err).length === 0) {
      err = null;
    }

    return err;
  }, [nightShiftConfig, t]);

  const onValidate = useCallback(() => {
    let err: ErrorType = {};

    const nightShiftErrors = validateNightShift();
    if (nightShiftErrors) {
      err = { ...err, [NS_CONFIG_ERRORS_KEY]: nightShiftErrors };
    }

    const hoursDistributionErrors = validateHoursDistributionRules();
    if (hoursDistributionErrors) {
      err = { ...err, ...hoursDistributionErrors };
    }

    if (Object.keys(err).length === 0) {
      setErrors(null);
      setConfirmationVisible(true);
    } else {
      setErrors(err);
    }
  }, [validateNightShift, validateHoursDistributionRules]);

  const onSubmit = async () => {
    setIsloading(true);

    try {
      const hoursDistributionPayloyad = {
        companyUuid: window.global_store.company.uuid,
        businessRulesType: BusinessRuleType.hoursDistribution,
        brGroupUuid: group.uuid,
        body: {
          content: {
            rules,
            updatedBy: window.global_store.profile.uuid,
          },
        },
      };

      const nightShiftPayload = {
        companyUuid: window.global_store.company.uuid,
        businessRulesType: BusinessRuleType.nightShift,
        brGroupUuid: group.uuid,
        body: {
          content: {
            name: nightShiftConfig.name.toString(),
            active: nightShiftConfig.active,
            nightShiftApplyEntirePeriod: nightShiftConfig.nightShiftApplyEntirePeriod,
            nightShiftStartTime: nightShiftConfig.nightShiftStartTime,
            nightShiftEndTime: nightShiftConfig.nightShiftEndTime,
            updatedBy: window.global_store.profile.uuid,
          },
          startTime: nightShiftConfig.nightShiftStartTime,
          endTime: nightShiftConfig.nightShiftEndTime,
        },
      };

      // getting error when trying to run both in Promise.all
      await updateBRGroupSettings(nightShiftPayload);
      await updateBRGroupSettings(hoursDistributionPayloyad);

      onSave({ notification: t("Group has been updated!"), notificationType: NotificationType.success });
    } catch (e) {
      let notification = (e as Error)?.message || String(e);

      if (e?.originalRequest?.errors[0]?.message) {
        notification += `: ${e.originalRequest.errors[0].message}`;
      }

      onSave({ notification, notificationType: NotificationType.error });
    } finally {
      setIsloading(false);
      setConfirmationVisible(false);
    }
  };

  return (
    <Wrapper>
      {isLocked ? (
        <NotificationRow
          employeesPage
          withCloseButton={false}
          type={NotificationType.locked}
          style={{ marginTop: 0, marginBottom: "25px" }}
          message={
            <div
              style={{
                marginTop: "10px",
                fontSize: "16px",
                color: "var(--colors-mainText)",
              }}
            >
              {t(
                "Editing of this Rules Group is locked. For any changes please contact support or create a new set of rules.",
              )}
            </div>
          }
        />
      ) : null}

      <Subheader style={{ marginTop: 0 }}>{t("Night Shift")}</Subheader>
      <NightShiftConfig
        config={nightShiftConfig}
        onChange={setNightShiftConfig}
        isGroupDisabled={isDisabled}
        isGroupLocked={isLocked}
        errors={errors?.[NS_CONFIG_ERRORS_KEY] || null}
      />
      <br />
      <br />
      <Subheader style={{ marginTop: 0 }}>{t("Settings")}</Subheader>
      <HoursDistributionTable
        isGroupDisabled={isDisabled}
        isGroupLocked={isLocked}
        rules={rules}
        errors={errors}
        onAddNewRule={(rule: HoursDistributionRule) => {
          setRules([...rules, rule]);
        }}
        onRemoveRule={(uuid: string) => {
          setRules(rules.filter((rule) => rule.uuid !== uuid));
        }}
        onChange={(rule: HoursDistributionRule) => {
          setRules(rules.map((r) => (r.uuid === rule.uuid ? rule : r)));
        }}
      />

      <ModalDialog isOpen={confirmationPopupVisible} onClose={() => setConfirmationVisible(false)}>
        <Lightbox
          showLoading={isLoading}
          title={t("hours-distribution-confirmation-title")}
          text={t("hours-distribution-confirmation-description")}
          buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onClose={() => {
            setConfirmationVisible(false);
          }}
          onYes={onSubmit}
        />
      </ModalDialog>
      {!isDisabled && !isLocked && (
        <Button
          style={{ width: 155 }}
          state={ButtonState.primary}
          loading={isLoading}
          onClick={onValidate}
          value={t("Save")}
        />
      )}
    </Wrapper>
  );
};

export default PunchingRulesGroupHoursDistribution;
