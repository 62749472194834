export { withTranslation, useTranslation } from "react-i18next";
export type { WithTranslation } from "react-i18next";
export type { TFunction } from "react-i18next";

enum TranslationNamespaces {
  activities = "activities",
  applications = "applications",
  billing = "billing",
  clients = "clients",
  common = "common",
  companyProfile = "company-profile",
  companyRules = "company-rules",
  companySecurity = "company-security",
  dashboard = "dashboard",
  departments = "departments",
  employeesPageAllTable = "employees-page-all-table",
  employeesPage = "employees-page",
  employeesPermissions = "employees-permissions",
  fullPage = "full-page",
  holidays = "holidays",
  hoursBank = "hours-bank",
  industryTypes = "industry-types",
  locations = "locations",
  modalPunch = "modal-punch",
  notificationCenter = "notification-center",
  payment = "payment",
  payroll = "payroll",
  pegador = "pegador",
  phases = "phases",
  positions = "positions",
  projects = "projects",
  punchesPage = "punches-page",
  recommend = "recommend",
  reportsPage = "reports-page",
  reports = "reports",
  reps = "reps",
  requestReasons = "request-reasons",
  requestsPageTmp = "requests-page-tmp",
  schedules = "schedules",
  sidebar = "sidebar",
  signup = "signup",
  subsidiaries = "subsidiaries",
  teams = "teams",
  onCalls = "on-calls",
  timesheets = "timesheets",
  subscription = "subscription",
  timesheetSettings = "timesheet-settings",
  dataManagement = "data-management",
  punchesTable = "punches-table",
  agGrid = "ag-grid",
  fallback = "fallback",
}

export { TranslationNamespaces };
