import { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px soild var(--colors-surface-200);
  padding: 10px;
  font-weight: var(--typography-font-weight-default);
  font-size: 16px;
  line-height: 100%;
  color: var(--colors-theme-tr-timetracker-duration-timer-color);
  border-radius: var(--shapes-border-radius-default);
  width: 90px;
`;

interface TimerFieldProps {
  dateTime: Date | null;
}

const TimerField = ({ dateTime }: TimerFieldProps) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    if (!dateTime) {
      return () => null;
    }
    const intervalId = setInterval(() => {
      setElapsedTime(Date.now() - dateTime.getTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [dateTime]);

  function formatTime(time: number) {
    const totalSeconds = Math.round(time / 1000);
    const hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  const elapsedTimeFormatted = dateTime ? formatTime(elapsedTime) : "00:00:00";

  return <Wrapper className="timer-field">{elapsedTimeFormatted}</Wrapper>;
};

export default TimerField;
