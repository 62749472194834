import { APIResponse, sendRequest } from "./common";
import CONFIG from "../../config";
import {
  AddRepPayload,
  DefaultSuccessResult,
  GetCompanyIntegration,
  GetCompanyRepsData,
  GetRepData,
  GetRepModelsData,
} from "./types";

// ////////
// PEGADOR
// ///////

export function getReps(data: {companyUuid: string}): APIResponse<GetCompanyRepsData> {
  return sendRequest({}, "GET", `/integrations/companies/${data.companyUuid}/reps`, CONFIG.client_admin_api);
}

export function getRep(data: {companyUuid: string, repId: number}): APIResponse<GetRepData> {
  return sendRequest({}, "GET", `/integrations/companies/${data.companyUuid}/reps/${data.repId}`, CONFIG.client_admin_api);
}

export function addRep(data: {companyUuid: string, body: AddRepPayload}): APIResponse<GetRepData> {
  return sendRequest(data.body, "POST", `/integrations/companies/${data.companyUuid}/reps`, CONFIG.client_admin_api);
}

export function updateRep(data: {companyUuid: string, repId: number, body: AddRepPayload}): APIResponse<DefaultSuccessResult> {
  return sendRequest(
    data.body,
    "PUT",
    `/integrations/companies/${data.companyUuid}/reps/${data.repId}`,
    CONFIG.client_admin_api
  );
}

export function deleteRep(data: {companyUuid: string, repId: number}): APIResponse<DefaultSuccessResult> {
  return sendRequest(
    {},
    "DELETE",
    `/integrations/companies/${data.companyUuid}/reps/${data.repId}`,
    CONFIG.client_admin_api
  );
}

export function getRepModels(): APIResponse<GetRepModelsData> {
  return sendRequest({}, "GET", "/integrations/rep-models", CONFIG.client_admin_api);
}

export function getIntegration(data: { companyUuid: string }): APIResponse<GetCompanyIntegration> {
  return sendRequest({}, "GET", `/integrations/companies/${data.companyUuid}/integration`, CONFIG.client_admin_api);
}

// HCM
export function checkHCMIntegration(companyUuid: string): Promise<boolean> {
  return sendRequest({}, "GET", `/companies/${companyUuid}/hcm/integration-exists`, CONFIG.client_admin_api);
}
