import styled from "styled-components";
import rtl from "styled-components-rtl";

export const MainWrapper = styled.div`
  position: relative;
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 20px;
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-activitiInfoBlockValue);
  cursor: pointer;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  margin-top: 5px;
  z-index: 10;
  top: auto;
  ${rtl`
  left: auto;
  right: 20px;
  `}
  bottom: 30px;
  width: auto;
  max-height: 200px;
  padding: 10px;
  overflow: auto;
  border-radius: var(--shapes-border-radius-default);
  background: var(--colors-default);
  box-shadow: 0 10px 30px 0 rgba(129, 147, 171, 0.2);
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 13px;
  line-height: 36px;
  font-size: var(--typography-font-size-default);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: var(--shapes-border-radius-default);
  color: var(--colors-activitiInfoBlockValue);
  cursor: pointer;

  &:hover {
    background: var(--colors-activitiCellScheduleItemHover);
    color: var(--colors-text1);
  }
`;

/** new pagination */
export const Label = styled.div<{ active: boolean }>`
  display: flex;
  height: var(--component-input-common-height, 36px);
  padding: var(--common-gap-none, 0px) var(--component-input-common-wicon-hpad, 8px) var(--common-gap-none, 0px)
    var(--component-input-common-hpad, 12px);
  align-items: center;
  gap: var(--component-input-common-gap, 4px);
  align-self: stretch;

  padding: ${(p) => (p.active ? "6.5px 27px 6.5px 11px" : "7.5px 28px 7.5px  12px")};
  width: 136px;
  line-height: 18px;
  font-size: 14px;
  color: var(--colors-surface-900-p);
  border: ${(p) => (p.active ? "2px solid var(--colors-primary-500-p)" : "1px solid var(--colors-surface-200)")};
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;

  box-sizing: border-box;
`;

/** new pagination */
export const IconArrowDown = styled.div`
  position: absolute;
  top: 7px;
  inset-inline-end: 12px;
`;

/** new pagination */
export const Dropdown = styled.div`
  position: absolute;
  top: auto;
  bottom: 40px;
  z-index: 10;
  margin-top: 5px;
  min-width: 162px;
  max-height: 200px;
  padding: 8px;
  overflow: auto;
  background: var(--colors-surface-0);
  border-radius: var(--shapes-border-radius-default);
  box-shadow: 0 10px 30px 0 rgb(129 147 171 / 20%);
`;

/** new pagination */
export const DropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  line-height: 130%;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-900-p);
  border-radius: var(--shapes-border-radius-default);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: var(--colors-surface-100);
  }

  svg {
    fill: var(--component-select-list-item-check-icon-color);
  }
`;
