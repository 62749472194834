import { TFunction, useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { stylesheet } from "astroturf";
import FullPage from "components/Layout/FullPage";
import styled from "styled-components";
import { useAsyncCallback } from "utils/useAsyncEffect";
import CONFIG from "config";
import Loader from "components/styled/Loader";
import { HeaderAction, PageWrapper } from "components/styled/Page";
import Button, { ButtonState } from "components/controls/StyledButton";
import { fireEvent, hasPermisionAccess, minsToHrsMins, PermissionSectionName } from "utils/common";
import Avatar from "components/views/Avatar";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import { RequestUserProfile } from "types/models/userProfile";
import Accordion from "components/UI/Accordion";
import { v4 as uuidv4 } from "uuid";
import Sentry from "utils/sentryUtils";
import { cancelTimesheet } from "components/Timesheets";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { TimesheetContext } from "../../timesheets.context";
import HeaderRow from "../../components/HeaderRow";
import ActivityRow from "../../components/ActivityRow";
import TotalsRow from "../../components/TotalsRow";
import {
  ParsedTimesheetData,
  ParsedTimesheetWeekData,
  TimesheetApprovalStatus,
  TimesheetsListItem,
} from "../../timesheet.types";
import { getStatusBadgeStatus, parseTimesheet, splitTimesheetToWeeks } from "../../timesheet.helpers";
import { getTimesheet, getTimesheetByUuid, TimesheetDataQuery } from "../../timesheet-api.service";
import TimesheetActions from "../../components/TimesheetActions";
import TimesheetSnackbar from "../../components/TimesheetSnackbar";

const styles = stylesheet`@import "./TimesheetDetails.scss"`;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  .common-text {
    font-weight: var(--typography-font-weight-medium);
    font-size: 12px;
    line-height: 15px;
    color: var(--colors-surface-900-p);
    justify-content: flex-start;
  }
  .task-cell {
    width: 100%;
    min-width: 235px;
    padding-inline-end: 13px;
  }
  .common-cell {
    width: 93px;
    min-width: 93px;
  }
  .total-cell {
    width: 93px;
    min-width: 93px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-bold);
    padding-inline-end: 20px !important;
    justify-content: flex-end;
  }
  &.AccordionTimesheetTable .task-cell {
    padding-inline-start: 24px;
    & * {
      padding: 0;
    }
  }
`;

export enum EventStatus {
  success = "success",
  error = "error",
  warning = "warning",
}

async function onCancelTimesheet(timesheet: TimesheetsListItem, t: TFunction, loadRows: () => void) {
  try {
    await cancelTimesheet({
      companyUuid: window.global_store.company.uuid,
      timesheetUuid: timesheet.uuid,
      requestedBy: window.global_store.profile.uuid,
    });
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-cancel-success"),
      status: EventStatus.success,
    });
    loadRows();
  } catch (error) {
    fireEvent("timesheet_message", {
      uuid: uuidv4(),
      message: t("timesheet-cancel-error"),
      status: EventStatus.error,
    });
    Sentry.sendError(error);
  }
}

const TimesheetWeekTable = ({
  data,
  userProfile,
  workingHours,
  loading,
  startDayOffset,
  className,
}: {
  data: ParsedTimesheetWeekData;
  userProfile: RequestUserProfile;
  workingHours: number[];
  loading: boolean;
  startDayOffset: number;
  className?: string;
}) => {
  const filteredTasks = data.tasksData.filter((row) => !row.dates.every((d) => d === 0));

  return (
    <TableWrapper className={className}>
      <HeaderRow
        startDayOffset={startDayOffset}
        dates={data.dates}
        isDayPlannedArray={data.isDayPlannedArray}
        loading={loading}
      />
      {filteredTasks.map((row, i) => (
        <ActivityRow
          key={`${row.taskUuid}_${row.dates[0]}`}
          data={row}
          userProfile={userProfile}
          isDayPlannedArray={data.isDayPlannedArray}
          dates={data.dates}
          loading={loading}
          unavailableDates={data.dates}
          startDayOffset={startDayOffset}
          viewOnly
        />
      ))}
      <TotalsRow
        startDayOffset={startDayOffset}
        workingHours={workingHours}
        loading={loading}
        data={data!.tasksData}
        dates={data!.dates}
      />
    </TableWrapper>
  );
};

export function TimesheetDetails() {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const history = useHistory();
  const { timesheetUuid } = useParams<{ timesheetUuid: string }>();
  const timesheetContext = useContext(TimesheetContext);
  const [timesheet, setTimesheet] = useState<TimesheetsListItem>();
  const [timesheetToCancel, setTimesheetToCancel] = useState<TimesheetsListItem | null>(null);
  const [activities, setActivities] = useState<ParsedTimesheetData>();
  const [users, setUsers] = useState<Record<string, RequestUserProfile>>();
  const { isTimesheetEnabled, companyUuid } = timesheetContext;

  const [loadData, loadingData] = useAsyncCallback(
    async () => {
      if (!companyUuid || !timesheetUuid) return;

      const { content, metadata } = await getTimesheetByUuid({
        requestedBy: window.global_store.profile.uuid,
        companyUuid,
        timesheetUuid,
      });
      setTimesheet(content);
      setUsers(metadata.userProfilesByUuid);

      const requestArgs: TimesheetDataQuery = {
        from: moment(content.startDate)!.format(CONFIG.apiDateFormat),
        to: moment(content.endDate)!.format(CONFIG.apiDateFormat),
        userProfileUuid: content.userProfileUuid,
        requestedBy: window.global_store.profile.uuid,
        companyUuid,
      };

      const res = await getTimesheet(requestArgs);
      setActivities(parseTimesheet(res));
    },
    [timesheetUuid, companyUuid],
    true,
  );

  useEffect(() => void loadData(), [loadData]);

  const weeks: ParsedTimesheetWeekData[] = useMemo(
    () => (activities ? splitTimesheetToWeeks(activities) : []),
    [activities],
  );

  const getDate = (startDate: string, endDate: string) =>
    `${moment(startDate).format("MMM DD, YYYY")} - ${moment(endDate).format("MMM DD, YYYY")}`;
  const goBack = () => history.push("/timesheets/list");

  const canAct = (status: TimesheetApprovalStatus) =>
    isTimesheetEnabled &&
    hasPermisionAccess(PermissionSectionName.approveActivities) &&
    timesheet?.approvalStatus === status;
  const canApprove = canAct(TimesheetApprovalStatus.pending);
  const canCancel = canAct(TimesheetApprovalStatus.approved);

  const timesheetFor = timesheet && users ? users[timesheet.userProfileUuid] : null;
  const timesheetSubmittedBy = timesheet && users ? users[timesheet.submittedBy] : null;

  const renderTable = () => {
    if (weeks.length === 0) return null;

    if (weeks.length === 1)
      return (
        <TimesheetWeekTable
          data={weeks[0]}
          userProfile={timesheetFor!}
          loading={loadingData}
          workingHours={activities!.workingHours}
          startDayOffset={7 - (weeks[0].dates.length - 1)}
        />
      );

    return weeks.map((week) => {
      const activityTime = week.tasksData.reduce((acc, task) => {
        acc += task.dates.reduce((v, i) => v + i);
        return acc;
      }, 0);

      return (
        <Accordion
          key={`accordion_${week.dates[0]}`}
          label={getDate(week.dates[0], week.dates[week.dates.length - 1])}
          customElement={<div className={styles.AccordionBadge}>{minsToHrsMins(activityTime)}</div>}
          hideElementOnOpen
        >
          <TimesheetWeekTable
            key={`week_table_${week.dates[0]}`}
            data={week}
            userProfile={timesheetFor!}
            loading={loadingData}
            workingHours={activities!.workingHours}
            startDayOffset={week.startDayOffset}
            className="AccordionTimesheetTable"
          />
        </Accordion>
      );
    });
  };

  const tooltip = useMemo(() => {
    if (timesheet?.approvalStatus === TimesheetApprovalStatus.approved) {
      return (
        <div className={styles.StatusHint}>
          <div className={styles.StatusHintText}>
            {t("Approved on {{date}}", {
              date: moment(timesheet.submittedAt).format("DD/MM/YYYY"),
              interpolation: { escapeValue: false },
            })}
          </div>
        </div>
      );
    }

    if (timesheet?.approvalStatus === TimesheetApprovalStatus.declined) {
      return (
        <div className={styles.StatusHint}>
          <div className={styles.StatusHintText}>
            {t("Declined by {{name}} on {{date}}", {
              date: moment(timesheet.submittedAt).format("DD/MM/YYYY"),
              interpolation: { escapeValue: false },
              name: users ? users[timesheet.declinedBy]?.fullName : "",
            })}
            <div className={styles.DeclineReason}>- {timesheet?.declineReason}</div>
          </div>
        </div>
      );
    }

    return null;
  }, [timesheet, users]);

  return (
    <FullPage
      backButtonOnclick={goBack}
      backButtonTitle={t("Approvals")}
      title={timesheet ? getDate(timesheet?.startDate, timesheet.endDate) : t("Approvals")}
      headerAction={
        timesheet ? (
          <HeaderAction>
            {canCancel && (
              <Button
                onClick={() => setTimesheetToCancel(timesheet)}
                style={{ padding: "11px 16px", width: "auto", whiteSpace: "nowrap" }}
                value={t("Cancel approval")}
                state={ButtonState.outline}
              />
            )}
            {canApprove && <TimesheetActions timesheetsUuid={[timesheetUuid]} reloadData={loadData} />}
          </HeaderAction>
        ) : null
      }
    >
      <PageWrapper>
        <div className={styles.Wrapper}>
          {!timesheet || loadingData ? (
            <Loader />
          ) : (
            <>
              {timesheetFor ? (
                <section className={styles.Details}>
                  <Avatar
                    user={{ fullName: timesheetFor.fullName || "", avatarId: timesheetFor!.avatarId as string | null }}
                    modifiers={{ big: true }}
                  />
                  <div className={styles.FieldGroup}>
                    <div className={styles.UserName}>{timesheetFor.fullName}</div>
                    {timesheetFor.role ? <div className={styles.UserRole}>{timesheetFor.role}</div> : null}
                  </div>
                  <div className={styles.FieldGroup}>
                    <div className={styles.Field}>
                      {t("Submitted on:")}
                      <span>{moment(timesheet.submittedAt).format("DD/MM/YYYY HH:MM")}</span>
                    </div>
                    <div className={styles.Field}>
                      {t("Submitted by:")}
                      <span>{timesheetSubmittedBy!.fullName}</span>
                    </div>
                  </div>
                  <div className={styles.FieldGroup}>
                    <div className={styles.Field}>
                      {t("Working hours:")}
                      <span>{minsToHrsMins(activities!.summary.actualWorkMinutes)}</span>
                    </div>
                    <div className={styles.Field}>
                      {t("Activity hours:")}
                      <span>{minsToHrsMins(activities!.summary.dailyActivitiesMinutes)}</span>
                    </div>
                  </div>

                  <div className={styles.StatusBadge}>
                    <StatusTag
                      type={getStatusBadgeStatus(timesheet.approvalStatus)!}
                      value={t(timesheet.approvalStatus)}
                    />
                    {tooltip}
                  </div>
                </section>
              ) : null}

              <div className="timesheet-details-content">{renderTable()}</div>
            </>
          )}
          <ModalDialog
            isOpen={!!timesheetToCancel}
            onClose={() => {
              setTimesheetToCancel(null);
            }}
          >
            <Lightbox
              title={t("cancel-timesheet-confirmation-title")}
              text={t("cancel-timesheet-confirmation-description")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                setTimesheetToCancel(null);
              }}
              onYes={async () => {
                await onCancelTimesheet(timesheetToCancel as TimesheetsListItem, t, loadData);
                setTimesheetToCancel(null);
              }}
            />
          </ModalDialog>
          <TimesheetSnackbar />
        </div>
      </PageWrapper>
    </FullPage>
  );
}
