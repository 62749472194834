import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { DayOfWeekShape, FocusedInputShape } from "react-dates";
import { dowDict, getWeekStart } from "utils/get-week-start";
import { GlobalStoreCompany } from "types/models/company";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";

const TimesheetDateRangePicker = ({
  startDate,
  endDate,
  onChange,
  labelFormat,
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onChange: (from: moment.Moment, to: moment.Moment) => void;
  labelFormat?: string;
}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const firstDayOfWeek: DayOfWeekShape = useMemo(
    () => dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day],
    [(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day],
  );

  const datesChanged = (from: moment.Moment | null, to: moment.Moment | null) => {
    const daysCount = 6;
    let fromL = getWeekStart(from ? from.clone() : moment(), firstDayOfWeek);
    let toL = fromL.clone().add(daysCount, "day");

    if (focusedInput === "startDate") {
      toL = fromL.clone().add(daysCount, "day");
    } else if (focusedInput === "endDate") {
      fromL = toL.clone().subtract(daysCount, "day");
    }

    if (!!startDate?.diff(fromL, "day") || !!endDate?.diff(toL, "day")) onChange(fromL, toL);
  };

  useEffect(() => {
    if (firstDayOfWeek !== undefined) {
      datesChanged(startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDayOfWeek]);

  return (
    <DateRangePicker
      step={{ amount: 7, unit: "day" }}
      firstDayOfWeek={firstDayOfWeek}
      isOutsideRange={() => false}
      focusedInput={focusedInput}
      onFocusChange={(val) => {
        setFocusedInput(val);
      }}
      returnDefaultOnChangeValues
      onChange={(...props) => {
        datesChanged(...props);
      }}
      startDate={startDate}
      endDate={endDate}
      labelFormat={labelFormat}
    />
  );
};

export default TimesheetDateRangePicker;
