import { Component } from "react";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import CreateNewRequestScreen from "components/Requests/CreateNewRequestScreen";
import { WithTranslation, withTranslation } from "react-i18next";
import FullPage from "components/Layout/FullPage";
import SearchControl from "components/UI/SearchControlNew";
import MultiSelect from "components/UI/Select/MultiWithSearch";
import User from "components/User";
import Select from "components/UI/Select";
import NoContent from "components/NoContent";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import moment from "moment";
import {
  getDateWithTZ,
  hasEmployeesAccess,
  getTitle,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
  isSupervisor,
  hasPermisionAccess,
} from "utils/common";
import GlobalContext from "context/global-context";
import styled from "styled-components";
import ModalDialog from "components/UI/ModalDialog";
import { PageWrapper, PageContent, PageSearch, HeaderAction, TableButtons, TableButton } from "components/styled/Page";
import DeclineReasonPopup from "components/DeclineReasonPopup";
import StatusBadge from "components/controls/StatusBadge";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import CellCheckbox from "components/controls/CellCheckbox";
import { getRequestsList, requestsBulkChangeStatus, fireDownloadRequests } from "utils/apiHelpers";
import arrowImg from "img/select-arrow.svg";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import { EmployeeTaxPayerTypes, Notification, NotificationType, SearchObject } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { GetRequestsRequestParams, RequestBulkChangeStatusRequestData } from "utils/api/types";
import { getRequestDate } from "utils/requestsHelpers";
import {
  RequestApprovalFlowStatus,
  Request,
  RequestListTableData,
  RequestType,
  RequestOvertimeSubTypes,
  RequestTypeName,
} from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";
import { iColumn } from "components/TableCommon";
import { ButtonState } from "components/controls/StyledButton";
import PaginationNew from "components/PaginationNew";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import * as images from "../svg-images";
import RequestDetailsNew from "./RequestDetailsNew";
import TablePage from "../TablePage";
import noRequests from "../../img/no-requests.png";
import NotificationRow from "../NotificationRow";
import CellCheckboxAll from "../controls/CellCheckboxAll";
import ActionsBar from "./ActionsBar";
import EditRequestScreen from "./EditRequestScreen";
import ApprovalFlowCell from "./ApprovalFlowCell";
import RequestSettingsModal from "./RequestSettingsModal";
import { getRequestTypeName } from "./requestUtils";

const FilterStatus = styled.div`
  width: 150px;
  margin-inline-start: 13px;
`;

const Wrapper = styled(PageWrapper)`
  .requests-row {
    position: relative;
  }
`;

const MultiselectWrapper = styled.div`
  .ui-select {
    div {
      .ui-select__input {
        &.ui-select__input_icon {
          min-width: 150px;
          background-color: var(--colors-surface-50);

          &:after {
            background-image: url(${arrowImg});
          }

          .ui-select__label {
            margin-inline-start: 0;
            font-size: 15px;
            color: var(--colors-surface-900-p);
          }
        }
      }
    }
  }
`;

const SettingsButton = styled.button`
  outline: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  background: none;
  margin-inline-end: 15px;
`;

type RequestPageProps = WithTranslation;

interface RequestPageState {
  popupNewVisible: boolean;
  popupDetailsVisible: boolean;
  popupEditVisible: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  status: RequestApprovalFlowStatus | "";
  requests: Request[];
  page: number;
  loading: boolean;
  totalRecors: number;
  notification: Notification["notification"];
  notificationType: NotificationType | null;
  selectedRequest: Request | null;
  perPage: number;
  selectedColumns: string;
  selectedRowsUuids: string[];
  selectAllChecked: boolean;
  selectedRequestSubTypes: string[]; // uuids
  requestTypes: RequestType[];
  searchValue: string;
  searchObj: SearchObject;
  directReportsOnly: boolean;
  inProgress: boolean;
  confirmationPopupVisible: boolean;
  popupSettingsVisible: boolean;
}

class RequestsPage extends Component<RequestPageProps, RequestPageState> {
  static contextType = GlobalContext;
  context!: React.ContextType<typeof GlobalContext>;

  STATUS_TYPES: { value: string; label: string }[];

  constructor(props: RequestPageProps) {
    super(props);
    const { t } = props;
    document.title = getTitle(t("Requests"));

    this.STATUS_TYPES = [
      { value: "", label: t("Status") },
      { value: RequestApprovalFlowStatus.pending, label: t("Pending") },
      { value: RequestApprovalFlowStatus.approved, label: t("Approved") },
      { value: RequestApprovalFlowStatus.declined, label: t("Declined") },
      { value: RequestApprovalFlowStatus.ignored, label: t("Ignored") },
    ];

    this.state = {
      popupNewVisible: false,
      popupDetailsVisible: false,
      popupEditVisible: false,
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().add(6, "month"),
      status: "",
      requests: [],
      page: 1,
      loading: false,
      totalRecors: 0,
      notification: "",
      notificationType: null,
      selectedRequest: null,
      perPage: 25,
      selectedColumns: getSelectedColumns(
        "employee,when,category,sub_category,requested_on,manager,status",
        "RequestsPage",
      ),
      ...this.getInitialSearch(),
      selectedRowsUuids: [],
      selectAllChecked: false,
      selectedRequestSubTypes: [],
      requestTypes: [],
      directReportsOnly: false,
      inProgress: false,
      confirmationPopupVisible: false,
      popupSettingsVisible: false,
    };
  }

  componentDidMount() {
    void this.getRequestTypes();
    void this.getState();
  }

  getInitialSearch = (): { searchValue: string; searchObj: SearchObject } => ({
    searchValue: "",
    searchObj: hasEmployeesAccess()
      ? {}
      : {
          id: window.global_store.profile.id,
          uuid: window.global_store.profile.uuid,
        },
  });

  getRequestTypes = async () => {
    const requestTypes = (await this.context.getRequestTypes()) as RequestType[];

    this.setState({ requestTypes });
  };

  getState = async (inBackground?: boolean): Promise<Request[] | undefined> => {
    const {
      startDate,
      endDate,
      searchObj,
      page,
      status,
      loading,
      perPage,
      selectedRequestSubTypes,
      directReportsOnly,
    } = this.state;

    if (!loading) {
      if (!inBackground) {
        this.setState({ loading: true, selectAllChecked: false, selectedRowsUuids: [] });
      }
      const company = await this.context.getCompany();
      let userProfileUuid = window.global_store.profile.uuid;
      const params: GetRequestsRequestParams = {
        from: startDate.format("YYYY-MM-DD"),
        to: endDate.format("YYYY-MM-DD"),
        perPage: String(perPage),
        page: String(page || 1),
      };

      if (searchObj && searchObj.uuid) {
        userProfileUuid = searchObj.uuid;
        params.side = hasEmployeesAccess() ? "subject" : "";
        params.requested_by = window.global_store.profile.uuid;
      }
      if (status) {
        params.status = status;
      }
      if (selectedRequestSubTypes) {
        params.request_subtypes = selectedRequestSubTypes.join(",");
      }
      if (directReportsOnly) {
        params.group_type = "team";
        params.group_uuid = window.global_store.profile.teams[0]?.uuid;
      }

      const requestsList = await getRequestsList({
        companyUUID: company.uuid,
        userProfileUuid,
        params,
      });

      this.setState({
        requests: requestsList.content,
        loading: false,
        inProgress: false,
        totalRecors: parseInt(requestsList?.metadata?.total || "0", 10),
        page: page > (requestsList?.metadata?.totalPages || 0) ? 1 : page,
      });

      return requestsList.content;
    }
  };

  onSearchValue = (value: SearchObject) => {
    this.setState(
      { searchValue: value.label as string, searchObj: value, page: 1, directReportsOnly: false },
      this.getState,
    );
  };

  onRequestTypesChange = (values: string[]) => {
    this.setState({ selectedRequestSubTypes: values, page: 1 }, this.getState);
  };

  onStatusChange = (value: RequestApprovalFlowStatus | "") => {
    this.setState({ status: value, page: 1 }, this.getState);
  };

  onSearchDatesChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    if (startDate && endDate) {
      this.setState({ startDate, endDate, page: 1 }, this.getState);
    }
  };

  // TODO move it to RequestDetails
  onChangeRequestsStatus = async (
    requestUuids: string[],
    status: Omit<RequestApprovalFlowStatus, RequestApprovalFlowStatus.pending>,
    declineReason?: string,
  ) => {
    const { t } = this.props;
    const { requests } = this.state;
    this.setState({ inProgress: true });

    let notification = "";
    let notificationType = NotificationType.success;

    const body: RequestBulkChangeStatusRequestData["body"] = {
      content: {
        approved: [],
        declined: [],
        ignored: [],
        ranges: [],
        updatedBy: window.global_store.profile.uuid,
      },
    };

    requestUuids.forEach((requestUuid) => {
      body.content[status].push(
        status === RequestApprovalFlowStatus.declined
          ? { uuid: requestUuid, reason: declineReason ?? "" }
          : requestUuid,
      );

      const request = requests.find((r) => r.uuid === requestUuid);
      if (request) {
        body.content.ranges.push({
          userProfileUuid: request.userProfileUuid,
          from: getDateWithTZ(request.startTime, request.timezone).format("YYYY-MM-DD"),
          to: getDateWithTZ(request.endTime, request.timezone).format("YYYY-MM-DD"),
        });
      }
    });

    try {
      const res = await requestsBulkChangeStatus({
        companyUUID: window.global_store.company.uuid,
        body,
      });

      if (res?.content?.failed?.length) {
        notification = t(res.content.failed[0].issues[0]);
        notificationType = NotificationType.error;
      } else {
        notification = t(`You ${status} this request`);
        notificationType = NotificationType.success;
      }
    } catch (e) {
      const error = e as any;
      notificationType = NotificationType.error;

      if (error?.content?.failed?.[0]?.errorMessage) {
        notification = t(error.content.failed[0].errorMessage);
      } else if (error?.message) {
        notification = t(error.message);
      } else {
        notification = t("Something went wrong");
      }
    }

    this.setState(
      {
        confirmationPopupVisible: false,
        selectedRequest: null,
        popupDetailsVisible: false,
        notification,
        notificationType,
      },
      () => this.getState(true),
    );
  };

  refetchRequests = async () => {
    const { selectedRequest } = this.state;
    const list = (await this.getState(true)) as Request[];

    this.setState({
      selectedRequest: list.find((r) => r.uuid === selectedRequest?.uuid) || null,
    });
  };

  onPopupRequestStatusChanged = async ({
    request,
    status,
    declineReason,
  }: {
    request: Request;
    status: RequestApprovalFlowStatus;
    declineReason: string;
  }) => {
    await this.onChangeRequestsStatus([request.uuid], status, declineReason);
    this.onRowCheck(false, request.uuid);
  };

  onNotificationClose = () => {
    this.setState({ notification: "", notificationType: NotificationType.success });
  };

  onCreateRequest = (err: Error | any) => {
    const { t } = this.props;
    let notification: string;

    if (err) {
      if (err.originalRequest?.errors?.length) {
        notification = err.originalRequest.errors[0].message;
      } else if (err.message) {
        notification = err.message;
      } else {
        notification = t("Saving failed. Please try again.");
      }
    } else {
      notification = t("You successfully created request");
    }

    this.setState({
      notification,
      notificationType: err ? NotificationType.error : NotificationType.success,
    });

    if (!err) {
      void this.getState();
    }
  };

  onEditRequest = (err: boolean) => {
    const { t } = this.props;

    this.setState({
      notification: err ? t("Saving failed. Please try again.") : t("The request was updated successfully"),
      notificationType: err ? NotificationType.error : NotificationType.success,
    });

    if (!err) {
      void this.getState();
    }
  };

  onCreateRequestPopupClose = () => {
    this.setState({ popupNewVisible: false });
  };

  onEditRequestPopupClose = () => {
    this.setState({ popupEditVisible: false });
  };

  openEditPopup = () => {
    this.setState({
      popupEditVisible: true,
      popupDetailsVisible: false,
    });
  };

  onRowCheck = (isChecked: boolean, requestUuid: string) => {
    let { selectedRowsUuids } = this.state;
    const { selectAllChecked } = this.state;

    if (isChecked) {
      selectedRowsUuids.push(requestUuid);
    } else {
      selectedRowsUuids = selectedRowsUuids.filter((uuid) => uuid !== requestUuid);
    }

    this.setState({ selectedRowsUuids, selectAllChecked: !selectedRowsUuids.length ? false : selectAllChecked });
  };

  onSelectAllCheck = (isChecked: boolean, selectedRowsUuids: string[]) =>
    this.setState({ selectAllChecked: isChecked, selectedRowsUuids });

  getTableData = ({ requests }: { requests: Request[] }): RequestListTableData[] => {
    const { t } = this.props;
    const { inProgress } = this.state;

    return requests.map((e) => ({
      id: e.uuid,
      request: e,
      employee: e.employee,
      cpf: e.employee ? e.employee.cpf : "",
      pis: e.employee ? e.employee.pis : "",
      matricula: e.employee ? e.employee.matricula : "",
      category: getRequestTypeName(e, t),
      subCategory: e.requestSubtypeState.translationKey
        ? t(e.requestSubtypeState.translationKey)
        : e.requestSubtypeState.name,
      startDate: e.startTime,
      when: getRequestDate(e),
      timezone: e.timezone,
      requestedOn: e.createdAt,
      reviewedBy: e.manager ? e.manager.name : "",
      status: e.approvalStatus,
      supervisor: e.employee.supervisor,
      showButtons: !inProgress && this.isRowSelectable(e),
      options: e.uuid,
      approvalFlow: e.approvalFlow,
    }));
  };

  // determine whether row could be selected using checkbox
  isRowSelectable = (request: Request) => {
    const isLocked = request.locked;
    const isLockedDate =
      request?.employee?.lastLockDate &&
      moment.tz(request.startTime, request.timezone).isSameOrBefore(moment(request.employee.lastLockDate).utc(), "day");
    const isPending = request.approvalStatus === RequestApprovalFlowStatus.pending;
    const isOwnerRole = window.global_store.profile.permission_roles.some((r) =>
      ["owner", "admin"].includes(r.name.toLowerCase()),
    );

    const hasAccess =
      window.global_store.profile.permission_roles.some((r) => r.name.toLowerCase() === "supervisor") ||
      window.global_store.profile.permissions.some(
        (r) => r && r.toLowerCase() === PermissionSectionName.requestSuperApprover,
      ) ||
      (!!request.approvalFlow.length &&
        request.approvalFlow.some(
          (level) =>
            level.canChangeStatus &&
            level.stage >= request.approvalStage &&
            level.canChangeStatus.some((uuid) => window.global_store.profile.uuid === uuid),
        ));

    return !isLocked && !isLockedDate && isPending && (isOwnerRole || hasAccess);
  };

  isRequestEditable = (request: Request | null) => !!request && this.isRowSelectable(request);

  getTableColumns = (): iColumn<RequestListTableData>[] => {
    const { t } = this.props;
    const { selectedRowsUuids, selectAllChecked, requests } = this.state;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const showCheckboxes =
      window.global_store.profile.permission_roles &&
      window.global_store.profile.permission_roles.some((r) =>
        ["admin", "hr", "owner", "supervisor"].includes(r.name.toLowerCase()),
      );

    const columns = [
      showCheckboxes
        ? {
            accessor: "request",
            headerClassName: "checkbox-header",
            Cell: (row: iCellInfo<RequestListTableData>) => (
              <CellCheckbox
                row={row}
                checked={selectedRowsUuids.indexOf(row.value.uuid) > -1}
                onChange={this.onRowCheck}
                isCheckboxDisabled={!this.isRowSelectable(row.original.request)}
                isRowLocked={row.original.request.locked}
              />
            ),
            Header: (
              <CellCheckboxAll
                entities={requests}
                checked={selectAllChecked}
                onChange={this.onSelectAllCheck}
                isEntitySelectable={this.isRowSelectable}
              />
            ),
            locked: true,
            minWidth: 50,
            style: { fontWeight: "500" },
            align: "center",
          }
        : null,
      {
        accessor: "employee",
        rubyAccessor: "employee",
        locked: true,
        label: t("Employee"),
        minWidth: 178,
        Cell: (row: iCellInfo<RequestListTableData>) => {
          let position = "";
          if (row.value.jobTitle) {
            position = row.value.jobTitle;
          } else if (row.value.role) {
            position = row.value.role;
          }
          return (
            <User
              user={{
                fullName: row.value.full_name || row.value.fullName,
                avatarId: row.value.avatar_id || row.value.avatarId,
                position,
              }}
            />
          );
        },
      },
      {
        accessor: "cpf",
        rubyAccessor: "cpf",
        label: employeeTaxIdLabel,
      },
      {
        accessor: "pis",
        rubyAccessor: "pis",
        label: t("PIS"),
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
      },
      {
        accessor: "supervisor",
        rubyAccessor: "supervisor",
        Cell: (row: iCellInfo<RequestListTableData>) => <span>{row.value ? row.value.fullName : null}</span>,
        label: t("Supervisor"),
      },
      {
        accessor: "when",
        rubyAccessor: "when",
        label: t("When"),
        minWidth: 200,
      },
      {
        accessor: "requestedOn",
        rubyAccessor: "requested_on",
        label: t("Requested On"),
        Cell: (row: iCellInfo<RequestListTableData>) => (
          <span>{row.value && getDateWithTZ(row.value, row.original.timezone).format("HH:mm ddd DD/MM/YYYY")}</span>
        ),
        minWidth: 230,
      },
      {
        accessor: "category",
        rubyAccessor: "category",
        label: t("Category-column"),
        minWidth: 150,
      },
      {
        accessor: "subCategory",
        rubyAccessor: "sub_category",
        label: t("Sub Category"),
        minWidth: 150,
      },
      {
        accessor: "approvalFlow",
        label: t("Approval Flow"),
        Cell: ({ value, original }: iCellInfo<RequestListTableData, RequestListTableData["approvalFlow"]>) => (
          <ApprovalFlowCell approvalFlow={value} status={original.request.approvalStatus} />
        ),
        minWidth: 150,
      },
      {
        accessor: "status",
        rubyAccessor: "status",
        locked: true,
        label: t("Status"),
        Cell: (row: iCellInfo<RequestListTableData>) => {
          const lockedDate = row.original.employee?.last_lock_date || row.original.employee?.lastLockDate;
          const isDayLocked =
            lockedDate &&
            getDateWithTZ(row.original.startDate, row.original.timezone).isSameOrBefore(moment(lockedDate), "day");

          return (
            <div>
              <StatusBadge value={t(row.value)} type={row.value} />
              {!isDayLocked && row.original.showButtons ? (
                <TableButtons className="buttons">
                  <TableButton
                    onClick={async (ev) => {
                      ev.stopPropagation();
                      const { uuid } = row.original.request;
                      await this.onChangeRequestsStatus([uuid], RequestApprovalFlowStatus.approved);
                      this.onRowCheck(false, uuid);
                    }}
                  >
                    {t("Approve")}
                  </TableButton>
                  <TableButton
                    onClick={(ev) => {
                      ev.stopPropagation();
                      this.setState({
                        selectedRequest: row.original.request,
                        confirmationPopupVisible: true,
                      });
                    }}
                  >
                    {t("Decline")}
                  </TableButton>
                </TableButtons>
              ) : null}
            </div>
          );
        },
        minWidth: 130,
        align: "center",
      },
    ].filter((e) => e) as iColumn<RequestListTableData>[];

    return columns;
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem("customColumns_RequestsPage", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  getHeaderAction = () => {
    const { t } = this.props;

    const hasRequestsSettingsAccess = window.global_store.profile?.permissions?.some?.(
      (r) => r === PermissionSectionName.requestSettings,
    );

    return (
      <HeaderAction>
        {hasRequestsSettingsAccess && (
          <SettingsButton
            onClick={() => {
              this.setState({ popupSettingsVisible: true });
            }}
          >
            {images.gearIcon}
          </SettingsButton>
        )}
        <HeaderActionButtonAdd
          state={ButtonState.primary}
          title={t("Add Absence")}
          onClick={() => this.setState({ popupNewVisible: true })}
        />
      </HeaderAction>
    );
  };

  render() {
    const {
      popupNewVisible,
      popupDetailsVisible,
      selectedRequest,
      requests,
      searchValue,
      loading,
      selectedColumns,
      startDate,
      endDate,
      notification,
      notificationType,
      totalRecors,
      confirmationPopupVisible,
      searchObj,
      perPage,
      page,
      inProgress,
      selectedRowsUuids,
      selectAllChecked,
      popupEditVisible,
      selectedRequestSubTypes,
      requestTypes,
      directReportsOnly,
      popupSettingsVisible,
    } = this.state;
    const { t } = this.props;
    const availableDaysCount = 60;

    return (
      <FullPage title={t("Requests")} headerAction={this.getHeaderAction()}>
        <Wrapper>
          {notification && (
            <NotificationRow
              employeesPage
              withCloseButton
              type={notificationType as NotificationType}
              onClose={() => this.setState({ notification: "", notificationType: NotificationType.success })}
              message={notification}
            />
          )}
          <PageContent style={{ paddingBottom: "90px" }}>
            <TablePage
              filters={
                <>
                  {hasEmployeesAccess() && (
                    <PageSearch>
                      <SearchControl
                        permissionSection={PermissionSectionName.requests}
                        groupInactive
                        value={searchValue}
                        onClear={() => this.setState({ ...this.getInitialSearch() }, this.getState)}
                        onChange={this.onSearchValue}
                        placeholder={translateEmployeeTerm(
                          t,
                          TranslationNamespaces.common,
                          "custom-search-employees",
                          `${TranslationNamespaces.common}|Search Employees`,
                        )}
                      />
                    </PageSearch>
                  )}
                  {isSupervisor() && (
                    <AdditionalFiltersControl
                      options={[
                        {
                          label: t("Direct reports only"),
                          checked: directReportsOnly,
                          onChange: (val) =>
                            this.setState({ directReportsOnly: val, ...this.getInitialSearch() }, this.getState),
                          // hide: !isSupervisor(),
                        },
                      ]}
                    />
                  )}
                  <DateRangePicker
                    newOnChangeApproach
                    isAdmin={hasEmployeesAccess()}
                    onChange={this.onSearchDatesChange}
                    startDate={startDate}
                    endDate={endDate}
                    availableDaysCount={availableDaysCount}
                  />
                  <MultiselectWrapper>
                    <MultiSelect
                      icon={<span />}
                      label={t("Category-filter")}
                      value={selectedRequestSubTypes}
                      onChange={this.onRequestTypesChange}
                      options={requestTypes
                        .filter((rt) => rt.active && rt?.subtypes.length)
                        .map((rt) => ({
                          label: rt.requestTypeTemplateUuid ? t(rt.name) : rt.name,
                          value: rt.subtypes
                            .filter((subtype) => subtype.nameId !== RequestOvertimeSubTypes.overtimeEnableDay)
                            .map((st) => ({
                              label: st.translationKey ? t(st.translationKey) : st.name,
                              value: st.uuid,
                            })),
                        }))}
                    />
                  </MultiselectWrapper>
                  <FilterStatus>
                    <Select
                      onChange={this.onStatusChange}
                      value={this.state.status}
                      options={this.STATUS_TYPES}
                      placeholder={t("Status")}
                    />
                  </FilterStatus>
                </>
              }
              columnSelectorOnFiltersRow
              selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
              onColumnsChange={this.onColumnsChange}
              rows={this.getTableData({ requests })}
              columns={this.getTableColumns()}
              className="table-common"
              showPagination
              loading={loading}
              manual
              showPageSizeOptions={false}
              PaginationComponent={() => (
                <PaginationNew
                  totalRecords={totalRecors}
                  pageLimit={perPage}
                  currentPage={page}
                  onPageLimitChange={(pageLimit, currentPage) =>
                    this.setState({ perPage: pageLimit, page: currentPage }, this.getState)
                  }
                  onPageChange={(currentPage) => {
                    this.setState({ page: currentPage }, this.getState);
                  }}
                />
              )}
              getTrProps={(_, rowInfo) => ({
                className: "requests-row",
                onClick: !inProgress
                  ? () => {
                      this.setState({
                        popupDetailsVisible: true,
                        selectedRequest: rowInfo?.original?.request || null,
                      });
                    }
                  : null,
              })}
              downloadControl={
                <DownloadControlWithEvents
                  placeholder={t(`${TranslationNamespaces.common}|Download`)}
                  options={[
                    { label: "XLSX", value: "xlsx" },
                    { label: "PDF", value: "pdf" },
                  ].filter((o) => o !== null)}
                  onChange={(value: string) => {
                    const queryObj: { status: RequestApprovalFlowStatus | ""; employeeUuid?: string } = {
                      status: this.state.status,
                    };

                    if (searchObj?.uuid) {
                      queryObj.employeeUuid = searchObj.uuid;
                    }

                    return fireDownloadRequests({
                      format: value || "xlsx",
                      from: startDate.format("YYYY-MM-DD"),
                      to: endDate.format("YYYY-MM-DD"),
                      employeeUuid: queryObj.employeeUuid,
                      selectedColumns,
                      status: queryObj.status || "",
                      requestSubTypes: selectedRequestSubTypes.join(","),
                    });
                  }}
                />
              }
              noContentComponent={<NoContent img={noRequests}>{t("No requests at this time")}</NoContent>}
            />
          </PageContent>

          <>
            {selectedRowsUuids.length > 0 && (
              <ActionsBar
                selectedRequestsIds={selectedRowsUuids}
                onUncheckAll={() => this.onSelectAllCheck(false, [])}
                onChangeRequestsStatus={this.onChangeRequestsStatus}
                onDone={(res: Notification) => {
                  this.setState(
                    {
                      notification: res.notification,
                      notificationType: res.notificationType,
                      selectedRowsUuids: res.notificationType !== NotificationType.success ? selectedRowsUuids : [],
                      selectAllChecked: res.notificationType !== NotificationType.success ? selectAllChecked : false,
                    },
                    this.getState,
                  );
                }}
              />
            )}

            <CreateNewRequestScreen
              prefillProfile={searchObj?.employee && !searchObj.type ? searchObj.employee : null}
              isOpen={popupNewVisible}
              onCreateRequest={this.onCreateRequest}
              onClose={this.onCreateRequestPopupClose}
            />

            <EditRequestScreen
              isOpen={popupEditVisible}
              request={selectedRequest}
              onEditRequest={this.onEditRequest}
              onClose={this.onEditRequestPopupClose}
            />

            <SidePopupOverlay
              requestDetails
              header={selectedRequest ? getRequestTypeName(selectedRequest, t) : null}
              onEdit={this.isRequestEditable(selectedRequest) ? this.openEditPopup : undefined}
              isOpen={popupDetailsVisible && !!selectedRequest}
              contentOverflow
              onClose={() =>
                this.setState({
                  popupDetailsVisible: false,
                })
              }
            >
              {popupDetailsVisible && selectedRequest ? (
                <RequestDetailsNew onStatusChanged={this.onPopupRequestStatusChanged} request={selectedRequest} />
              ) : null}
            </SidePopupOverlay>
          </>

          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() =>
              this.setState({
                selectedRequest: null,
                confirmationPopupVisible: false,
              })
            }
          >
            <DeclineReasonPopup
              t={t}
              title={t("Decline Request.")}
              text={t("Are you sure to Decline the request? You should not be able to undo that action.")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({
                  selectedRequest: null,
                  confirmationPopupVisible: false,
                });
              }}
              onYes={async (declineReason: string) => {
                const { uuid } = this.state.selectedRequest as Request;

                await this.onChangeRequestsStatus([uuid], RequestApprovalFlowStatus.declined, declineReason);
                this.onRowCheck(false, uuid);
              }}
            />
          </ModalDialog>

          {popupSettingsVisible && (
            <RequestSettingsModal onClose={() => this.setState({ popupSettingsVisible: false })} />
          )}
        </Wrapper>
      </FullPage>
    );
  }
}

export default withTranslation([TranslationNamespaces.requestsPageTmp])(RequestsPage);
