import { ChangeEvent, Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BEM from "utils/BEM";
import TablePage from "components/TablePage";
import SearchInput from "components/UI/SearchInput";
import FilterWrapper from "components/UI/FilterWrapper";
import Select from "components/UI/Select";
import MultiSelect from "components/UI/Select/MultiSelect";
import { Location, LocationClient, LocationTableData, LocationValidation } from "types/models/location";
import { iColumn } from "components/TableCommon";
import Pagination from "components/Pagination";
import { TranslationNamespaces } from "types/translationNamespaces";
import StatusTag, { StatusTagType } from "components/UI/StatusTag";
import NoContent from "components/NoContent";
import "components/UI/Page/Page.scss";
import "./LocationsList.scss"; // TODO styled

const b = BEM.b("locations-list");

export enum SearchStatus {
  active = "active",
  inactive = "inactive",
}

export type LocationFilters = {
  page: number;
  perPage: number;
  validations: LocationValidation[];
  status: SearchStatus | "";
  searchValue: string;
};

interface LocationsListProps extends LocationFilters, WithTranslation, RouteComponentProps {
  isJobSites?: boolean;
  locations: Location[];
  setFilters: (data: Pick<LocationFilters, keyof LocationFilters>) => void;
  clients: LocationClient[];
  isLoading: boolean;
  totalRecords: number;
}

class LocationsList extends Component<LocationsListProps> {
  getTableColumns = (): iColumn<LocationTableData>[] => {
    const { t, clients, isJobSites } = this.props;

    const columns: iColumn<LocationTableData>[] = [
      {
        label: t("ID"),
        accessor: "code",
        maxWidth: 80,
      },
      {
        label: t("Name"),
        accessor: "name",
        Cell: (row) => (
          <a
            style={{
              textTransform: "none",
              color: "var(--colors-surface-800)",
              fontSize: "14px",
            }}
            href={`${this.getItemLinkPrefix()}${row.original.id}`}
            onClick={(ev) => ev.preventDefault()}
          >
            {row.value}
          </a>
        ),
        maxWidth: 200,
      },
      {
        label: t("Client"),
        accessor: "client_id",
        Cell: ({ value }) => {
          const client = clients.find((c) => c.uuid === value);

          return client?.name || null;
        },
        show: isJobSites,
        maxWidth: 200,
      },
      {
        label: t("Address"),
        accessor: "formatted_address",
      },
      {
        label: t("State"),
        accessor: "state",
        maxWidth: 160,
      },
      {
        label: t("Location Manager"),
        accessor: "manager",
        Cell: ({ value }) => (value && value.name ? value.name : null),
        maxWidth: 160,
      },
      {
        label: t("Validation"),
        accessor: "verification_methods",
        Cell: ({ value }) =>
          value?.length
            ? value
                .map((v: LocationValidation) => t(v))
                .join(", ")
                .toUpperCase()
            : null,
        maxWidth: 160,
      },
      {
        label: t("Status"),
        accessor: "active",
        Cell: ({ value }) => (
          <StatusTag
            value={t(value ? "Active" : "Deactivated")}
            type={value ? StatusTagType.active : StatusTagType.default}
          />
        ),
        align: "center",
        maxWidth: 120,
      },
    ];

    return columns;
  };

  renderFilters = (): JSX.Element => {
    const { t, setFilters, searchValue, validations, status } = this.props;

    return (
      <FilterWrapper>
        <SearchInput
          className={b("input-field")}
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFilters({ searchValue: e.target.value })}
          placeholder={t("Search Locations")}
        />
        <div className={b("dropdown")}>
          <MultiSelect<LocationValidation>
            value={validations}
            onChange={(value: LocationValidation[]) => setFilters({ validations: value })}
            placeholder={t("Validation")}
            options={[
              { value: LocationValidation.gps, label: t("GPS") },
              { value: LocationValidation.wifiPoints, label: t("Wi-Fi") },
              { value: LocationValidation.beacons, label: t("iBeacon") },
            ]}
          />
        </div>
        <div className={b("dropdown")}>
          <Select<SearchStatus | "">
            value={status}
            onChange={(value) => setFilters({ status: value })}
            placeholder={t("Status")}
            options={[
              { value: "", label: t("All") },
              { value: SearchStatus.active, label: t("Active") },
              { value: SearchStatus.inactive, label: t("Inactive") },
            ]}
          />
        </div>
      </FilterWrapper>
    );
  };

  getItemLinkPrefix = (): string => {
    const { isJobSites } = this.props;

    if (isJobSites) {
      return "/company/job-sites/";
    }

    return "/company/locations/";
  };

  render(): JSX.Element {
    const { t, locations, history, isLoading, page, perPage, totalRecords, setFilters } = this.props;

    return (
      <div className={b()}>
        <TablePage<LocationTableData>
          filters={this.renderFilters()}
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          rows={locations}
          columns={this.getTableColumns()}
          loading={isLoading}
          page={page}
          showPagination
          manual
          PaginationComponent={() => (
            <Pagination
              totalRecords={totalRecords}
              pageLimit={perPage}
              pageNeighbours={2}
              currentPage={page}
              onPageLimitChange={(pageLimit, currentPage) => setFilters({ page: currentPage, perPage: pageLimit })}
              onPageChanged={(p: { currentPage: number }) => setFilters({ page: p.currentPage, perPage })}
            />
          )}
          getTrProps={(_, rowInfo) => ({
            onClick: () => {
              const { _original } = rowInfo?.row;
              if (_original) {
                history.push(`${this.getItemLinkPrefix()}${_original.id}`);
              }
            },
          })}
          noContentComponent={<NoContent>{t("No Invoice History at this time")}</NoContent>}
        />
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(withRouter(LocationsList));
