import FieldWrapper from "components/UI/FieldWrapper";
import { ErrorLabel } from "components/UI/TextLabels";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import moment, { Moment } from "moment";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import SchedulesDropdown from "components/Schedules/controls/SchedulesDropdown";
import { RequestSubtypeSpecificFieldTypes } from "types/models/request";
import { RequestsAddEditPopupErrors } from "../RequestAddEditPopup";

type ScheduleAssignmentFieldsProps = {
  startDate: Moment;
  errors: RequestsAddEditPopupErrors;
  onDateChange: (date: Moment) => void;
  lockedDate: moment.Moment | null;
  selectedSchedule: "string" | null;
  onScheduleChange: (val: string) => void;
  employeeCurrentScheduleUuid: string | null;
};

const ScheduleAssignmentFields: React.FC<ScheduleAssignmentFieldsProps> = ({
  startDate,
  lockedDate,
  errors,
  onDateChange,
  selectedSchedule,
  onScheduleChange,
  employeeCurrentScheduleUuid,
}) => {
  const { t } = useTranslation(TranslationNamespaces.common);

  return (
    <>
      <FieldWrapper fieldName={t("Schedule")} width="100%" fieldTitleMarginTop={16}>
        <SchedulesDropdown
          employeeCurrentScheduleUuid={employeeCurrentScheduleUuid}
          selectedItem={selectedSchedule}
          onChange={onScheduleChange}
        />
      </FieldWrapper>
      {!!errors?.tsfErrors?.[RequestSubtypeSpecificFieldTypes.schedule] && (
        <ErrorLabel>{errors?.tsfErrors?.[RequestSubtypeSpecificFieldTypes.schedule]}</ErrorLabel>
      )}
      <FieldWrapper fieldName={t("Date")} width="100%" fieldTitleMarginBottom={0}>
        <SingleDatePickerControl
          numberOfMonths={1}
          error={!!errors?.startDate}
          value={startDate}
          onChange={onDateChange}
          isOutsideRange={(day) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
        />
      </FieldWrapper>
    </>
  );
};

export default ScheduleAssignmentFields;
