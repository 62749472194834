import { useTranslation } from "react-i18next";
import { ProjRes } from "services/oitchau-api";
import { TranslationNamespaces } from "types/translationNamespaces";
import { IconAddTask } from "components/PunchNow/views";
import { useState } from "react";
import styled from "styled-components";
import { StoreApi, UseBoundStore } from "zustand/esm";
import { TrackTimeStore } from "../TrackTime.store";
import { CancelBtn, CreateProjectBtn, DirectoryIcon } from "./TimerButtons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  &.border-bottom {
    border-bottom: 1px solid var(--colors-surface-150);
  }
  &.border-top {
    border-top: 1px solid var(--colors-surface-150);
  }
  &.border-dark {
    border-color: var(--colors-surface-400);
  }
  .Label {
    color: var(--colors-surface-800);
    font-size: 13px;
    font-style: normal;
    font-weight: var(--typography-font-weight-medium);
    line-height: 100%;
    align-self: stretch;
  }
  .Input {
    width: 100%;
    input {
      width: 100%;
      flex: 1 0 0;
      color: var(--colors-surface-900-p);
      border: none;
      &::placeholder {
        color: var(--colors-surface-700);
      }
    }
  }
  &.nested {
    padding: 0 20px;
    .AddTask {
      padding-inline-start: 0;
      border-bottom: 1px solid var(--colors-surface-100);
      .CreateBtn {
        display: flex;
        height: 28px;
        padding: 0 12px;
        justify-content: center;
        align-items: center;
        background: transparent;
        outline: none;
        border: 1px solid var(--colors-surface-0);
        color: var(--colors-primary-500-p);
        background: var(--colors-primary-50);
        font-size: 13px;
        border-radius: var(--shapes-border-radius-default);

        &:disabled,
        &[disabled] {
          border-color: var(--colors-surface-100);
          color: var(--colors-surface-200);
          background: var(--colors-surface-0);
        }
        &:hover:not(:disabled),
        &:hover:not([disabled]) {
          cursor: pointer;
          color: var(--colors-primary-600);
          background: rgba(30, 151, 247, 0.08);
        }
      }
    }
  }
  .AddTask {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-inline-start: 19px;
    gap: 11px;
    height: 40px;
    font-size: var(--typography-font-size-default);
    font-weight: var(--typography-font-weight-medium);
    cursor: pointer;
    &.nestedBtn {
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-surface-700);
      svg path {
        fill: var(--colors-surface-700);
      }
      &:hover {
        color: var(--colors-surface-900-p);
      }
    }

    .TaskName {
      font-weight: var(--typography-font-weight-medium);
      color: var(--colors-primary-500-p);
    }
  }
`;

export const AddTaskRow = ({
  project,
  nested,
  onCreateTask,
  store,
}: {
  project?: ProjRes;
  nested?: boolean;
  onCreateTask: (taskName: string, project: ProjRes) => void;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const { searchValue, setShowSelectProject, setShowTaskPicker, setSearchValue } = store();
  const createTaskText = t("Create {{taskName}} task", { taskName: "newTask" });
  const [creTskTranslationPrefix, creTskTranslationSuffix] = createTaskText.split("newTask");
  const [showAddTask, setShowAddTask] = useState(false);
  const [taskName, setTaskName] = useState("");
  const onKeyDownHandler = async (event) => {
    if (event.key === "Enter") {
      await onCreateTask(taskName, project!);
      setShowAddTask(false);
    }
  };

  if (nested) {
    return (
      <Wrapper className={`nested ${showAddTask ? "border-top border-bottom border-dark" : "with-hover"}`}>
        {showAddTask ? (
          <div className="AddTask">
            <div className="Input">
              <input
                value={taskName}
                onChange={(event) => setTaskName(event.target.value)}
                placeholder={t("Type task a name")}
                type="text"
                onKeyDown={onKeyDownHandler}
                autoFocus
              />
            </div>
            <CancelBtn
              noIcon
              onClick={() => {
                setTaskName("");
                setShowAddTask(false);
              }}
            />
            <button
              className="CreateBtn"
              disabled={!taskName}
              type="button"
              onClick={async () => {
                await onCreateTask(taskName, project!);
                setShowAddTask(false);
              }}
            >
              {t(`${TranslationNamespaces.common}|Create`)}
            </button>
          </div>
        ) : (
          <div className="AddTask nestedBtn border-top" onClick={() => setShowAddTask(true)}>
            <IconAddTask />
            <div>{t("New task")}</div>
          </div>
        )}
      </Wrapper>
    );
  }

  return (
    <>
      <CreateProjectBtn
        type="button"
        onClick={() => {
          setShowSelectProject(true);
        }}
      >
        <DirectoryIcon />
        <span>
          {creTskTranslationPrefix} <span className="TaskName">{searchValue}</span> {creTskTranslationSuffix}
        </span>
      </CreateProjectBtn>
      <CancelBtn
        onClick={() => {
          setSearchValue("");
          setShowAddTask(false);
          setShowTaskPicker(false);
        }}
      />
    </>
  );
};
