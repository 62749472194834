import { MouseEvent, ReactNode } from "react";
import FullPage from "./FullPage";

interface SettingsLayoutProps {
  headerAction?: JSX.Element | null;
  title?: ReactNode;
  backButtonTitle?: string;
  backButtonOnclick?: (e: MouseEvent) => void;
  children: ReactNode;
}

const SettingsLayout = (props: SettingsLayoutProps) => {
  const { children, title, headerAction, backButtonOnclick, backButtonTitle } = props;

  return (
    <FullPage
      title={title}
      headerAction={headerAction}
      backButtonOnclick={backButtonOnclick}
      backButtonTitle={backButtonTitle}
    >
      {children}
    </FullPage>
  );
};

export default SettingsLayout;
