import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControl";
import LocationsDropdown from "components/LocationsDropdown";
import ClientsDropdown from "components/ClientsDropdown";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import Button from "components/controls/StyledButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CheckboxControl from "components/UI/NewCheckbox";
import { getTitle } from "utils/common";
import ProjectCustomFields from "components/Projects/ProjectCustomFields";
import { CustomField, CustomFieldError, Project, ProjectStatus } from "types/models/projects";
import { TranslationNamespaces } from "types/translationNamespaces";
import getSymbolFromCurrency from "currency-symbol-map";
import { NotificationType } from "types/common";
import { ErrorLabel } from "components/UI/TextLabels";
import { archiveProject, updateProject } from "./projectsApiUtils";

const Wrapper = styled.div`
  width: 100%;
  max-width: 367px;
`;

const IdNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 16px;
`;

const BlockTitle = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  font-weight: var(--typography-font-weight-medium);
  margin-top: 32px;
`;

const DeactivateLink = styled.div`
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary);
  line-height: 14px;
  cursor: pointer;
  display: flex;
  margin-inline-end: 10px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 32px;
  width: 132px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
  div:first-child {
    margin-inline-end: 10px;
  }
`;

const CheckboxForPublicProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
  justify-content: space-between;
`;

const CheckboxForPublicProjectLabelWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding-inline-end: 14px;
  cursor: pointer;
`;

const CheckboxForPublicProjectTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);
`;

const CheckboxForPublicProjectSubtitle = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 12px;
  line-height: 120%;
  color: var(--colors-surface-700);
`;

const RatesWrapper = styled.div<{ currencySign?: string }>`
  display: flex;
  align-items: center;
  & > div:first-child {
    position: relative;
    input {
      padding-inline-end: 20px;
    }

    &:after {
      content: ${(p) => `'${p.currencySign}'`};
      position: absolute;
      inset-inline-end: 8px;
      bottom: 1px;
      font-size: 15px;
      line-height: 34px;
      color: var(--colors-surface-700);
    }
  }
`;

type ErrorType = Record<string, string | CustomFieldError[]> | null;

interface ProjectPageSummaryProps extends WithTranslation, RouteComponentProps {
  project: Project;
  onProjectUpdated: (notification: string, notificationType: NotificationType) => void;
}

interface ProjectPageSummaryState {
  id: string;
  name: string;
  status: ProjectStatus | null;
  estimatedHours: number;
  estimatedCost: number;
  clientUuid: string | null;
  loading: boolean;
  errors: ErrorType;
  uuid: string;
  locationUuids: string[];
  ownerUuid: string | null;
  isPublic: boolean;
  restrictBasedOnSchedule: boolean;
  customFieldsSettings: CustomField[];
  confirmationPopupVisible: boolean;
}

class ProjectPageSummary extends Component<ProjectPageSummaryProps, ProjectPageSummaryState> {
  static contextType = GlobalContext;
  constructor(props: ProjectPageSummaryProps) {
    super(props);
    const { t } = props;
    this.state = {
      estimatedHours: 0,
      estimatedCost: 0,
      loading: true,
      clientUuid: null,
      locationUuids: [],
      id: "",
      uuid: "",
      name: "",
      errors: {},
      status: null,
      restrictBasedOnSchedule: false,
      customFieldsSettings: [],
      ownerUuid: null,
      isPublic: false,
      confirmationPopupVisible: false,
    };
    document.title = getTitle(t("Project"));
  }

  componentDidMount() {
    const { project } = this.props;

    this.setState({ ...project, loading: false });
  }

  onSaveClick = async () => {
    const { t, onProjectUpdated } = this.props;
    const {
      id,
      name,
      clientUuid,
      uuid,
      locationUuids,
      ownerUuid,
      restrictBasedOnSchedule,
      customFieldsSettings,
      estimatedHours,
      estimatedCost,
      isPublic,
    } = this.state;
    const newErrors: ErrorType = {};
    if (estimatedCost && estimatedCost > 2147483647) {
      newErrors.estimatedCost = t("Estimated cost can't be more than 2147483647");
    }
    const customFieldsErrors = customFieldsSettings.map((cfs) =>
      cfs.name ? null : { name: t("Name can't be empty") },
    );
    if (customFieldsErrors.some((v) => !!v)) {
      newErrors.customFieldsSettings = customFieldsErrors;
    }
    const hasErrors = Object.values(newErrors).some((v) => !!v);
    this.setState({ errors: hasErrors ? newErrors : null });
    if (hasErrors) return;

    this.setState({ loading: true });
    const requestDetails = {
      companyUuid: window.global_store.company.uuid,
      projectUuid: uuid,
      body: {
        content: {
          locationUuids: locationUuids || [],
          name,
          id,
          clientUuid,
          ownerUuid,
          isPublic,
          restrictBasedOnSchedule,
          customFieldsSettings,
          estimatedHours: estimatedHours || 0,
          estimatedCost: estimatedCost || 0,
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };

    try {
      await updateProject(requestDetails);
      onProjectUpdated(
        t("Project was updated", { projectName: name, interpolation: { escapeValue: false } }),
        NotificationType.success,
      );
    } catch (error) {
      onProjectUpdated(
        t("Project update failed", { projectName: name, interpolation: { escapeValue: false } }),
        NotificationType.error,
      );
    }
    this.setState({ loading: false });
  };

  removeProject = async () => {
    const { onProjectUpdated, t } = this.props;
    const { uuid, name } = this.state;
    const data = {
      companyUuid: window.global_store.company.uuid,
      projectUuid: uuid,
      body: {
        content: {
          updatedBy: window.global_store.profile.uuid,
        },
      },
    };
    this.setState({ loading: true });
    await archiveProject(data);
    this.setState({ loading: false, confirmationPopupVisible: false });
    onProjectUpdated(
      t("Project was archived", { projectName: name, interpolation: { escapeValue: false } }),
      NotificationType.success,
    );
  };

  render() {
    const {
      id,
      name,
      clientUuid,
      locationUuids,
      errors,
      loading,
      status,
      confirmationPopupVisible,
      restrictBasedOnSchedule,
      customFieldsSettings,
      estimatedHours,
      estimatedCost,
      isPublic,
    } = this.state;
    const { t } = this.props;
    if (loading) {
      return <div>{t("common|Loading...")}</div>;
    }
    return (
      <Wrapper>
        <BlockTitle>{t("Details")}</BlockTitle>
        <IdNameWrapper>
          <FieldWrapper fieldName="ID" wrapperWidth="60px" width="100%">
            <TextInputControl
              value={id}
              disabled={status !== "active"}
              error={!!errors?.id}
              onChange={(value) => this.setState({ id: value })}
            />
          </FieldWrapper>
          <Spacer />
          <FieldWrapper fieldName={t("common|Name")} wrapperWidth="100%" width="100%">
            <TextInputControl
              value={name}
              disabled={status !== "active"}
              error={!!errors?.name}
              onChange={(value) => this.setState({ name: value })}
            />
          </FieldWrapper>
        </IdNameWrapper>
        <FieldWrapper fieldName={t("Client")} width="100%">
          <ClientsDropdown
            placeholder={t("Client")}
            disabled={status !== "active"}
            value={clientUuid || ""}
            defaultSelectView
            withEmptyOption
            loading={loading}
            withCreateOption
            onChange={(value) => this.setState({ clientUuid: value || null })}
          />
        </FieldWrapper>
        <FieldWrapper fieldName={t("Locations")} width="100%">
          <LocationsDropdown
            uuid
            isMulti
            disabled={status !== "active"}
            onlyActive
            value={locationUuids}
            placeholder={t("All locations")}
            onChange={(val) => {
              this.setState({ locationUuids: val });
            }}
          />
        </FieldWrapper>
        <BlockTitle>{t("Estimation")}</BlockTitle>
        <FieldWrapper fieldName={t("Estimated hours")} wrapperWidth="183px" width="100%">
          <TextInputControl
            value={`${estimatedHours}`}
            error={!!errors?.estimatedHours}
            onChange={(value) =>
              this.setState({
                // use only digits
                estimatedHours: value.replace(/[^0-9]*/g, "") ? parseInt(value.replace(/[^0-9]*/g, ""), 10) : 0,
              })
            }
          />
        </FieldWrapper>
        <RatesWrapper
          currencySign={
            window?.global_store?.company?.currency ? getSymbolFromCurrency(window.global_store.company.currency) : "$"
          }
        >
          <FieldWrapper fieldName={t("Estimated cost")} width="183px">
            <TextInputControl
              placeholder="100"
              value={`${estimatedCost}`}
              error={!!errors?.estimatedCost}
              onChange={(value) =>
                this.setState({
                  // use only digits
                  estimatedCost: value.replace(/[^0-9]*/g, "") ? parseInt(value.replace(/[^0-9]*/g, ""), 10) : 0,
                })
              }
            />
          </FieldWrapper>
        </RatesWrapper>
        {errors?.estimatedCost && <ErrorLabel>{errors.estimatedCost}</ErrorLabel>}

        <CheckboxWrapper>
          <CheckboxControl
            checked={restrictBasedOnSchedule}
            label={t("restrict-based-on-schedule")}
            onChange={(checked) => this.setState({ restrictBasedOnSchedule: checked })}
          />
        </CheckboxWrapper>

        <CheckboxForPublicProjectWrapper>
          <CheckboxForPublicProjectLabelWrapper onClick={() => this.setState({ isPublic: !isPublic })}>
            <CheckboxForPublicProjectTitle>{t("Public project")}</CheckboxForPublicProjectTitle>
            <CheckboxForPublicProjectSubtitle>{t("Everyone can work on tasks")}</CheckboxForPublicProjectSubtitle>
          </CheckboxForPublicProjectLabelWrapper>
          <CheckboxControl ios small checked={isPublic} onChange={(checked) => this.setState({ isPublic: checked })} />
        </CheckboxForPublicProjectWrapper>

        <BlockTitle>{t("Customize")}</BlockTitle>
        <ProjectCustomFields
          customFields={customFieldsSettings}
          errors={errors?.customFieldsSettings as CustomFieldError[]}
          onChange={(val) => {
            this.setState({ customFieldsSettings: val });
          }}
        />
        {status === "active" && (
          <ButtonsWrapper>
            <Button
              value={t("common|Save")}
              disabled={loading}
              loading={loading}
              onClick={this.onSaveClick}
              onDoubleClick={this.onSaveClick}
            />
          </ButtonsWrapper>
        )}
        {status === "active" && (
          <DeactivateLink onClick={() => this.setState({ confirmationPopupVisible: true })}>
            {t("Archive project")}
          </DeactivateLink>
        )}
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Archive Project")}
            text={t("archive-project-confirmation")}
            buttonYesTitle={t("Yes, archive")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ confirmationPopupVisible: false });
            }}
            onYes={this.removeProject}
          />
        </ModalDialog>
      </Wrapper>
    );
  }
}
export default withRouter(withTranslation(TranslationNamespaces.projects)(ProjectPageSummary));
