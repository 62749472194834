import { Suspense } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import SubscriptionLockedPage from "components/Subscriptions/SubscriptionLockedPage";

const RouteSubscriptions = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect exact strict from={`${path}`} to={`${path}/`} />

      <Route exact strict path={`${path}/`} component={SubscriptionLockedPage} />
    </Switch>
  </Suspense>
);

export default RouteSubscriptions;
