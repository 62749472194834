import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Position } from "types/models/position";
import { TranslationNamespaces } from "types/translationNamespaces";
import BEM from "../../utils/BEM";
import TextInputControl, { TextInputControlRef } from "../controls/TextInputControl";
import "./styles/popup-message.scss";

const b = BEM.b("positions-popup-message");

interface PositionsPopupMessageProps extends WithTranslation {
  title: string;
  /** Position to edit */
  item: Position | Record<string, never>;
  allItems: Position[];
  buttonYesTitle: string;
  onClose: () => void;
  onYes: (data: { id: number; title: string }) => void;
}

interface PositionsPopupMessageState {
  title: string;
  name: { value: string; error: string };
  buttonCancelTitle: string;
  buttonYesTitle: string;
}

class PositionsPopupMessage extends Component<PositionsPopupMessageProps, PositionsPopupMessageState> {
  name: TextInputControlRef | null = null;

  constructor(props: PositionsPopupMessageProps) {
    super(props);
    const { t, title, item, buttonYesTitle } = props;

    this.state = {
      title,
      name: { value: item.title || "", error: "" },
      buttonCancelTitle: t("common|Cancel"),
      buttonYesTitle: buttonYesTitle || t("common|Yes"),
    };
  }

  closePopup = () => {
    this.props.onClose();
  };

  onYes = () => {
    if (this.name) {
      const { allItems = [], item, onYes } = this.props;
      const name = { value: this.name.getValue(), error: "" };

      if (!name.value) {
        name.error = this.props.t("Position name can't be empty");
        this.setState({ name });
        // if position with this name already exist and (it is create mode or edit mode and name has been changed)
      } else if (
        allItems.some((position) => position.title === name.value) &&
        (!item.title || item.title !== name.value)
      ) {
        name.error = this.props.t("Position with this name already exists");
        this.setState({ name });
      } else {
        onYes({
          id: item.id,
          title: name.value,
        });
      }
    }
  };

  render() {
    const { title, name, buttonYesTitle, buttonCancelTitle } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>

        <div className={b("label")}>{t("Name")}</div>
        <TextInputControl
          size={60}
          value={name.value}
          error={!!name.error}
          onRef={(ref) => {
            this.name = ref;
          }}
        />
        {name.error && <div className={b("error")}>{name.error}</div>}

        <div className={b("buttons")}>
          <div className={b("button")} onClick={this.closePopup}>
            {buttonCancelTitle}
          </div>
          <div className={b("button", { active: true })} onClick={this.onYes}>
            {buttonYesTitle}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.positions)(PositionsPopupMessage);
