import { Component } from "react";
import { updateBRGroupSettings, getBRGroup, getCompanyRules } from "utils/apiHelpers";
import { withTranslation } from "react-i18next";
import ToggleControl from "components/UI/CheckboxControl";
import CheckboxControl from "components/UI/NewCheckbox";
import { ErrorLabel } from "components/UI/TextLabels";
import Select from "components/UI/FormSelect";
import FieldWrapper from "components/UI/FieldWrapper";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import * as moment from "moment";
import BEM from "utils/BEM";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import "styles/company-rules.scss";
import hash from "object-hash";
import { v4 as uuidv4 } from "uuid";
import GlobalContext from "context/global-context";
import Button from "components/controls/StyledButton";
import TextInputControl from "components/controls/TextInputControl";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { TranslationNamespaces } from "types/translationNamespaces";
import { HoursBankExpiredPhasesPositiveAction, PhaseType } from "types/models/businessRulesGroup";
import styled from "styled-components";
import RichTooltip from "components/UI/RichTooltip";
import TimeControl from "components/controls/TimeControl";
import { minsToHrsMins, hrsMinsToMins } from "utils/common";
import { CompanyRuleNames } from "types/models/companyRules";
import { DSR_PHASE_KEY } from "components/Payroll/types";
import SwitchableTextField from "components/UI/SwitchableTextField";
import NextDueDate from "./NextDueDate";
import HbEhPhases from "./HbEhPhases";
import { Subheader, SettingsBlock } from "./styled";
import HoursBankNotificationRow from "./HoursBankNotificationRow";
import NotificationsAndAlerts from "./NotificationsAndAlerts";
import { AlertsPeriod } from "./NotificationsAndAlerts/helpers";
import { PHASE_TYPES, MULTIPLIER_TYPES, PhasesType } from "./PhasesTable/helpers";
import HbLimits, { defaultLimitRule } from "./HbLimits";
import HBDischarge from "./HBDischarge";

const b = BEM.b("company-rules");

const MAX_CYCLES_COUNT_UNTIL_RESET = 99;
const MIN_CYCLES_COUNT_UNTIL_RESET = 0;

const CheckboxWithInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 16px;

  .limit {
    width: 80px;
    margin-inline-start: 10px;
    margin-top: 0;
  }

  div:first-child {
    margin-inline-end: 10px;
  }
`;

class PunchingRulesGroupHoursbank extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.availableDurations = [
      { value: 1, label: `1 ${t("Month")}` },
      { value: 2, label: `2 ${t("Months")}` },
      { value: 3, label: `3 ${t("Months")}` },
      { value: 4, label: `4 ${t("Months")}` },
      { value: 5, label: `5 ${t("Months")}` },
      { value: 6, label: `6 ${t("Months")}` },
      { value: 7, label: `7 ${t("Months")}` },
      { value: 8, label: `8 ${t("Months")}` },
      { value: 9, label: `9 ${t("Months")}` },
      { value: 10, label: `10 ${t("Months")}` },
      { value: 11, label: `11 ${t("Months")}` },
      { value: 12, label: `12 ${t("Months")}` },
      { value: 13, label: `13 ${t("Months")}` },
      { value: 14, label: `14 ${t("Months")}` },
      { value: 15, label: `15 ${t("Months")}` },
      { value: 16, label: `16 ${t("Months")}` },
      { value: 17, label: `17 ${t("Months")}` },
      { value: 18, label: `18 ${t("Months")}` },
    ];

    this.availableHoursBankMissingDayStrategies = [
      { value: "dontDiscountHoursBankAndConsiderMissingDay", label: t("Remove days from payroll") },
      {
        value: "discountHoursBankAndConsiderMissingDay",
        label: t("Remove days from payroll and hours from Hours Bank"),
      },
      { value: "discountHoursBankAndDontConsiderMissingDay", label: t("Remove hours from Hours Bank") },
    ];
    this.defaultMissingDayStrategy = this.availableHoursBankMissingDayStrategies[1].value;

    this.defaultPhase = {
      days: "1",
      limits: [
        {
          phasePercentage: {
            value: "",
            error: false,
          },
          periodLimits: {
            hours: {
              value: "",
              error: false,
            },
            period: AlertsPeriod.day,
          },
        },
      ],
    };

    this.state = {
      hoursBankActive: false,
      isDefault: false,
      hoursBankRecurrenceInterval: this.availableDurations[5].value,
      isFetched: false,
      isLoading: false,
      startDate: moment(),
      hoursBankStartDateNew: moment(),
      hbPeriod: AlertsPeriod.day,
      ehLimit: AlertsPeriod.day,
      phaseSimpleHours: 50,
      phaseWeekends: 200,
      splitOvertimeValue: 48,
      splitOvertime: false,
      splitOvertimeHolidayValue: 48,
      addToHoursBank: false,
      addToHoursBankValue: "",
      isLocked: false,
      phases: [],
      phasesType: PHASE_TYPES.PERCENTAGE,
      multiplierType: MULTIPLIER_TYPES.DISABLED,
      hoursBankHideOnMobiles: false,
      missingDayStrategy: this.defaultMissingDayStrategy,
      overtimeStartAlert: false,
      phasesSubstractionOrder: [],
      rawPhasesSubstractionOrder: [],
      hoursBankResetType: "full",
      hoursBankCyclesCountUntilReset: 0,
      hoursBankLimits: [],
      customPhasesSubtractionOrder: false,
      calculateOvertimeOnlyForPositiveHours: false,
      includeBreakOnCalculation: false,
      dsrEnabled: false,
    };
  }

  componentDidMount() {
    this.getState();
  }

  getState = async () => {
    const { id } = this.props;
    let dsrEnabled = false;
    let companyRulesDiscountDsrActive = false;
    let companyRulesDiscountDsrLimit = 0;

    try {
      const [company, companyRules] = await Promise.all([this.context.getCompany(), getCompanyRules()]);
      dsrEnabled =
        companyRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;
      companyRulesDiscountDsrActive =
        companyRules?.business_rules?.find(
          (rule) => rule.name === CompanyRuleNames.DISCOUNT_DSR_FOR_MISSING_MINUTES_ACTIVE,
        )?.value || false;
      companyRulesDiscountDsrLimit =
        companyRules?.business_rules?.find(
          (rule) => rule.name === CompanyRuleNames.DISCOUNT_DSR_FOR_MISSING_MINUTES_LIMIT,
        )?.value || 0;
      if (companyRulesDiscountDsrLimit) {
        companyRulesDiscountDsrLimit = hrsMinsToMins(companyRulesDiscountDsrLimit);
      }
      const r = await getBRGroup({ companyUuid: company.uuid, brGroupUuid: id });
      let content;

      if (r.content && r.content.businessRules.length) {
        content = r.content.businessRules[0].rules.payedOvertime;
      }

      if (content) {
        if (!content.startDate) {
          content.startDate = r.content.businessRules[0].startDate;
        }

        content.isLocked = r.content.isLocked;
        content.isDefault = r.content.isDefault;
        content.status = r.content.status;

        if (content.phases?.length > 0) {
          content.phases = content.phases.map((p) => {
            if (p?.dayTypeBasedOnSchedule === DSR_PHASE_KEY) {
              const { dayTypeBasedOnSchedule: _, ...phase } = p;
              phase.daysMask = DSR_PHASE_KEY;
              return phase;
            }
            return p;
          });
        }
        this.updateState(content, r.content);
      } else {
        this.setState({ isFetched: true });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        dsrEnabled,
        companyRulesDiscountDsrActive,
        companyRulesDiscountDsrLimit,
      });
    }
  };

  updateState = (settings, group) => {
    const data = {};

    if (settings) {
      if (settings.phases) {
        data.rawPhases = JSON.parse(JSON.stringify(settings.phases));
        data.phases = settings.phases;
        data.phasesType = settings.phasesType || PHASE_TYPES.PERCENTAGE;
        data.multiplierType = settings.multiplierType || MULTIPLIER_TYPES.DISABLED;
      }

      if (settings.phasesSubtractionOrder) {
        data.phasesSubstractionOrder = settings.phasesSubtractionOrder;
        data.rawPhasesSubstractionOrder = JSON.parse(JSON.stringify(settings.phasesSubtractionOrder));
      }

      if (typeof settings.customPhasesSubtractionOrder !== "undefined") {
        data.customPhasesSubtractionOrder = settings.customPhasesSubtractionOrder;
      }

      if (settings.hoursBankLimits) {
        const { hoursBankLimits } = settings;
        data.hoursBankLimits = hoursBankLimits.map((hoursBankLimit) => ({
          ...hoursBankLimit,
          limit: hoursBankLimit.limit || 0,
        }));
      }

      data.hoursBankRecurrenceInterval = settings.hoursBankRecurrenceInterval
        ? this.availableDurations.find((d) => d.value === settings.hoursBankRecurrenceInterval).value ||
          this.availableDurations[5].value
        : this.availableDurations[5].value;
      data.hoursBankActive = settings.hoursBankActive;
      data.overtimeStartAlert = settings.overtimeStartAlert || false;
      data.isDefault = settings.isDefault;
      data.startDate = settings.startDate ? moment(settings.startDate, "YYYY-MM-DD") : moment();
      data.hoursBankStartDateOverride = settings.hoursBankStartDateOverride;
      data.hoursBankStartDateNew = settings.hoursBankStartDateNew
        ? moment(settings.hoursBankStartDateNew, "YYYY-MM-DD")
        : moment();
      data.phaseSimpleHours = settings.weekdayStage;
      data.phaseWeekends = settings.holidayStage;
      data.hbLimit = settings.hoursBankBalanceAlert?.limit || 0;
      data.hbPeriod =
        settings.hoursBankBalanceAlert && settings.hoursBankBalanceAlert.limitType
          ? settings.hoursBankBalanceAlert.limitType
          : AlertsPeriod.day;
      data.ehLimit = settings.extraHoursBalanceAlert?.limit || 0;
      data.ehPeriod =
        settings.extraHoursBalanceAlert && settings.extraHoursBalanceAlert.limitType
          ? settings.extraHoursBalanceAlert.limitType
          : AlertsPeriod.day;

      if (settings.hoursBankPayedRules) {
        const { hoursBankPayedRules } = settings;
        data.addToHoursBank = hoursBankPayedRules.extraHoursLimit || hoursBankPayedRules.extraHoursLimit === 0;
        data.addToHoursBankValue =
          hoursBankPayedRules.extraHoursLimit > 0
            ? hoursBankPayedRules.extraHoursLimit < 60
              ? hoursBankPayedRules.extraHoursLimit
              : hoursBankPayedRules.extraHoursLimit / 60
            : "";
        data.addToExtraHours = hoursBankPayedRules.hoursBankLimit || hoursBankPayedRules.hoursBankLimit === 0;
        data.addToExtraHoursValue =
          hoursBankPayedRules.hoursBankLimit > 0
            ? hoursBankPayedRules.hoursBankLimit < 60
              ? hoursBankPayedRules.hoursBankLimit
              : hoursBankPayedRules.hoursBankLimit / 60
            : "";
        data.splitOvertime = hoursBankPayedRules.extraHours || hoursBankPayedRules.hoursBank;
        data.splitOvertimeValue =
          hoursBankPayedRules.hoursBank === null || typeof hoursBankPayedRules.hoursBank === "undefined"
            ? 100
            : hoursBankPayedRules.hoursBank;
        data.splitOvertimeHolidayValue =
          hoursBankPayedRules.hoursBankHoliday === null || typeof hoursBankPayedRules.hoursBankHoliday === "undefined"
            ? 100
            : hoursBankPayedRules.hoursBankHoliday;
      }

      data.hoursBankHideOnMobiles = settings.hoursBankHideOnMobiles;
      data.missingDayStrategy =
        settings.missingDayStrategy ||
        this.getHBMissingDayStrategyFromIgnoreMissingDays(settings.hoursBankIgnoreMissingDays) ||
        this.defaultMissingDayStrategy;
      data.isLocked = settings.isLocked;
      data.status = settings.status;
      data.hoursBankResetType = settings.hoursBankResetType || "full";
      data.hoursBankCyclesCountUntilReset = settings.hoursBankCyclesCountUntilReset || 0;
      data.calculateOvertimeOnlyForPositiveHours = settings.splitPositiveAndNegativeHours?.active || false;
      data.includeBreakOnCalculation = settings.splitPositiveAndNegativeHours?.includeBreaks || false;
      data.hoursBankExpiredPhasesPositiveAction = settings.hoursBankExpiredPhasesPositiveAction;
      data.hoursBankExpiredPhasesNegativeAction = settings.hoursBankExpiredPhasesNegativeAction;
      data.discountDsrForMissingMinutesActive = !!settings.discountDsrForMissingMinutes?.active;
      data.discountDsrForMissingMinutesLimit = settings.discountDsrForMissingMinutes?.limit || 0;
      data.ignoreOvertimeOnNonPlannedDays = !!settings.ignoreOvertimeOnNonPlannedDays;
      data.ignoreOvertimeOnNonPlannedDaysThreshold = settings.ignoreOvertimeOnNonPlannedDaysThreshold || 0;
      data.externalId = group?.externalId;
    }

    this.setState({
      ...data,
      isFetched: true,
    });
  };

  getFormErrors() {
    const { t } = this.props;
    const {
      addToHoursBank,
      addToHoursBankValue,
      addToExtraHours,
      hoursBankStartDateOverride,
      hoursBankStartDateNew,
      addToExtraHoursValue,
      startDate,
      hoursBankResetType,
      hoursBankCyclesCountUntilReset,
      ignoreOvertimeOnNonPlannedDays,
      ignoreOvertimeOnNonPlannedDaysThreshold,
      hoursBankExpiredPhasesPositiveAction,
      multiplierType,
      phases,
    } = this.state;
    let errors = {};

    if (!startDate) {
      errors.startDate = t("Start date can't be empty");
    }
    if (addToHoursBank && !addToHoursBankValue) {
      errors.addToHoursBankValue = t("Please set hours");
    }
    if (hoursBankStartDateOverride && !hoursBankStartDateNew) {
      errors.hoursBankStartDateNew = t("Next due date can't be empty");
    }
    if (addToExtraHours && !addToExtraHoursValue) {
      errors.addToExtraHoursValue = t("Please set hours");
    }
    if (hoursBankResetType === "cyclic") {
      if (hoursBankCyclesCountUntilReset === undefined) {
        errors.hoursBankCyclesCountUntilReset = t("Cycles count can't be empty");
      }

      if (hoursBankCyclesCountUntilReset < 1) {
        errors.hoursBankCyclesCountUntilReset = t("Cycles count should be greater than 0");
      }

      if (hoursBankCyclesCountUntilReset % 1) {
        errors.hoursBankCyclesCountUntilReset = t("Cycles count should be integer");
      }
    }

    if (ignoreOvertimeOnNonPlannedDays) {
      if (!ignoreOvertimeOnNonPlannedDaysThreshold) {
        errors.ignoreOvertimeOnNonPlannedDaysThreshold = t("ignore-overtime-error-0");
      }
    }

    if (
      hoursBankExpiredPhasesPositiveAction === HoursBankExpiredPhasesPositiveAction.convertOriginalHoursToExtraHours &&
      multiplierType !== MULTIPLIER_TYPES.REALTIME // multipliers
    ) {
      errors.hoursBankExpiredPhasesPositiveAction = t(
        "Converting original hours to extra hours is only allowed when calculating compensation rates by multipliers",
      );
    }

    // validate if some phases have duplicate customName
    if (phases) {
      const customNames = phases.filter((p) => p.customName).map((p) => p.customName);
      const uniqueCustomNames = [...new Set(customNames)];
      if (customNames.length !== uniqueCustomNames.length) {
        errors.phases = {};
        phases
          .filter((p) => p.customName)
          .forEach((phase, i) => {
            if (customNames.indexOf(phase.customName) !== i) {
              errors.phases[phase.uuid] = {
                customName: t("This name is already used"),
              };
            }
          });
      }
    }

    if (Object.keys(errors).length === 0) {
      errors = false;
    }

    return errors;
  }

  onBancosDeHorasChange = (checked) => {
    let { phasesSubstractionOrder, phases, hoursBankStartDateNew, startDate, hoursBankLimits } = this.state;
    const { companyRulesDiscountDsrActive, companyRulesDiscountDsrLimit } = this.state;
    let discountDsrForMissingMinutesActive = false;
    let discountDsrForMissingMinutesLimit = false;

    if (!checked) {
      phasesSubstractionOrder = [];
      phases = phases.map((p) => {
        const phase = p;
        phase.extraHours = 100;
        phase.type = PhaseType.combined;
        return phase;
      });
    } else {
      phases = phases.map((p) => {
        const phase = p;
        phase.extraHours = null;
        return phase;
      });
      hoursBankStartDateNew = moment();
      startDate = moment();
      phasesSubstractionOrder = phases.map((p) => p.uuid);

      discountDsrForMissingMinutesActive = companyRulesDiscountDsrActive;
      if (discountDsrForMissingMinutesActive) {
        discountDsrForMissingMinutesLimit = companyRulesDiscountDsrLimit;
      }
    }

    this.setState({
      hoursBankLimits: checked ? hoursBankLimits : [],
      discountDsrForMissingMinutesActive,
      discountDsrForMissingMinutesLimit,
      hoursBankActive: checked,
      phasesSubstractionOrder,
      rawPhasesSubstractionOrder: JSON.parse(JSON.stringify(phasesSubstractionOrder)),
      phases,
      hoursBankStartDateNew,
      startDate,
    });
  };

  onStartDateChange = (startDate) => {
    let { hoursBankStartDateNew } = this.state;

    if (hoursBankStartDateNew && hoursBankStartDateNew.isBefore(startDate, "day")) {
      hoursBankStartDateNew = startDate.clone();
    }

    this.setState({ startDate, hoursBankStartDateNew });
  };

  onHoursBankStartDateNewChange = (hoursBankStartDateNew) => {
    this.setState({ hoursBankStartDateNew });
  };

  onPhasesChange = ({ phases, phasesSubstractionOrder, phasesType, multiplierType, customPhasesSubtractionOrder }) => {
    let hbLimits = [];
    const { hoursBankLimits, hoursBankActive } = this.state;
    // if hoursBankLimits' limit has sourcePhaseUuid or targetPhaseUuid that doesn't exist in phases, remove limit with it from hoursBankLimits

    if (hoursBankActive && hoursBankLimits) {
      const phasesUuids = phases.map(({ uuid }) => uuid);
      hbLimits = hoursBankLimits.filter(
        (limit) =>
          !(
            (limit.sourcePhaseUuid && !phasesUuids.includes(limit.sourcePhaseUuid)) ||
            (limit.targetPhaseUuid && !phasesUuids.includes(limit.targetPhaseUuid))
          ),
      );
    }

    this.setState({
      phases,
      phasesSubstractionOrder,
      phasesType,
      multiplierType,
      customPhasesSubtractionOrder,
      hoursBankLimits: hbLimits,
    });
  };

  onLimitsChange = (updatedLimit) => {
    let found = false;

    const updatedHoursBankLimits = this.state.hoursBankLimits.map((limit) => {
      if (limit.uuid === updatedLimit.uuid) {
        found = true;

        return updatedLimit;
      }

      return limit;
    });

    if (!found) {
      updatedHoursBankLimits.push(updatedLimit);
    }

    this.setState({ hoursBankLimits: updatedHoursBankLimits });
  };

  onLimitsRemove = (uuid) => {
    const updatedHoursBankLimits = this.state.hoursBankLimits.filter((limit) => limit.uuid !== uuid);
    this.setState({ hoursBankLimits: updatedHoursBankLimits });
  };

  onNextClick = async () => {
    const {
      hoursBankRecurrenceInterval,
      hoursBankActive,
      startDate,
      hoursBankStartDateNew,
      hoursBankStartDateOverride,
      phaseSimpleHours,
      phaseWeekends,
      phasesType,
      multiplierType,
      addToHoursBank,
      addToHoursBankValue,
      addToExtraHours,
      addToExtraHoursValue,
      splitOvertime,
      splitOvertimeValue,
      hbLimit,
      hbPeriod,
      ehLimit,
      ehPeriod,
      phasesSubstractionOrder,
      hoursBankHideOnMobiles,
      missingDayStrategy,
      splitOvertimeHolidayValue,
      overtimeStartAlert,
      hoursBankResetType,
      hoursBankCyclesCountUntilReset,
      hoursBankLimits,
      customPhasesSubtractionOrder,
      calculateOvertimeOnlyForPositiveHours,
      includeBreakOnCalculation,
      hoursBankExpiredPhasesPositiveAction,
      hoursBankExpiredPhasesNegativeAction,
      discountDsrForMissingMinutesActive,
      discountDsrForMissingMinutesLimit,
      ignoreOvertimeOnNonPlannedDays,
      ignoreOvertimeOnNonPlannedDaysThreshold,
      externalId,
    } = this.state;
    let { phases } = this.state;
    const errors = this.getFormErrors();

    if (!errors) {
      this.setState({ isLoading: true });

      const { id, t, onSave } = this.props;
      const data = {
        companyUuid: window.global_store.company.uuid,
        businessRulesType: "payedOvertime",
        brGroupUuid: id,
        body: {
          content: {
            hoursBankActive,
            startDate: startDate.format("YYYY-MM-DD"),
            hoursBankStartDateOverride: hoursBankStartDateOverride || false,
            hoursBankStartDateNew: hoursBankStartDateOverride ? hoursBankStartDateNew.format("YYYY-MM-DD") : null,
            hoursBankRecurrenceInterval,
            hoursBankRecurrencePeriod: "month",
            weekdayStage: parseInt(phaseSimpleHours || 0, 10),
            holidayStage: parseInt(phaseWeekends || 0, 10),
            hoursBankHideOnMobiles,
            missingDayStrategy,
            hoursBankCyclesCountUntilReset,
            hoursBankBalanceAlert: {
              active: true,
              limit: hbLimit || null,
              limitType: hbPeriod,
            },
            extraHoursBalanceAlert: {
              active: true,
              limit: ehLimit || null,
              limitType: ehPeriod,
            },
            hoursBankPayedRules: {
              extraHoursLimit: addToHoursBank ? parseInt(addToHoursBankValue, 10) * 60 : null,
              hoursBankLimit: addToExtraHours ? parseInt(addToExtraHoursValue, 10) * 60 : null,
              extraHours: splitOvertime ? 100 - splitOvertimeValue : null,
              hoursBank: splitOvertime ? splitOvertimeValue : null,
              extraHoursHoliday: splitOvertime ? 100 - splitOvertimeHolidayValue : null,
              hoursBankHoliday: splitOvertime ? splitOvertimeHolidayValue : null,
            },
            hoursBankResetType,
            updatedBy: window.global_store.profile.uuid,
            hoursBankLimits: hoursBankLimits.map((hoursBankLimit) => ({
              ...hoursBankLimit,
              limit: hoursBankLimit.limit || 0,
            })),
            hoursBankExpiredPhasesPositiveAction,
            hoursBankExpiredPhasesNegativeAction,
          },
        },
      };

      data.body.content.discountDsrForMissingMinutes = {
        active: !hoursBankActive ? discountDsrForMissingMinutesActive : false,
        limit: !hoursBankActive && discountDsrForMissingMinutesActive ? discountDsrForMissingMinutesLimit : 0,
      };

      data.body.content.ignoreOvertimeOnNonPlannedDays = ignoreOvertimeOnNonPlannedDays || false;
      data.body.content.ignoreOvertimeOnNonPlannedDaysThreshold = ignoreOvertimeOnNonPlannedDaysThreshold || 0;

      if (window.global_store.beta || overtimeStartAlert) {
        data.body.content.overtimeStartAlert = overtimeStartAlert;
      }

      const newPhasesSubstractionOrder = JSON.parse(JSON.stringify(phasesSubstractionOrder));

      if (phases) {
        phases = phases.map((phase, i) => {
          const newPhase = JSON.parse(JSON.stringify(phase));

          // if exxisting phase has changed
          if (
            this.state.rawPhases[i] &&
            this.state.rawPhases[i].uuid === phase.uuid &&
            hash(this.state.rawPhases[i]) !== hash(phase)
          ) {
            newPhase.uuid = uuidv4();

            if (phasesSubstractionOrder && hoursBankActive) {
              newPhasesSubstractionOrder.splice(phasesSubstractionOrder.indexOf(phase.uuid), 1, newPhase.uuid);
            }
          }
          if (newPhase?.daysMask === DSR_PHASE_KEY) {
            newPhase.dayTypeBasedOnSchedule = DSR_PHASE_KEY;
            newPhase.daysMask = undefined;
          }

          return newPhase;
        });

        data.body.content.phases = phases;
        data.body.content.phasesType = phasesType;
        data.body.content.multiplierType = multiplierType;
      }

      if (phasesSubstractionOrder) {
        data.body.content.phasesSubtractionOrder = hoursBankActive ? newPhasesSubstractionOrder : [];
        data.body.content.customPhasesSubtractionOrder = hoursBankActive ? customPhasesSubtractionOrder : false;
      }

      data.body.content.splitPositiveAndNegativeHours = {
        active: calculateOvertimeOnlyForPositiveHours,
        includeBreaks: includeBreakOnCalculation,
      };

      try {
        await updateBRGroupSettings(data);

        this.setState({ isLoading: false });
        this.getState();

        if (onSave) {
          onSave({
            notificationType: "success",
            notification: "Group has been updated!",
            additionalSettings: {
              shouldUpdateExternalId: true,
              externalId,
            },
          });
        }
      } catch (e) {
        window.scrollTo(0, 0);

        if (onSave) {
          this.setState({ isLoading: false });
          onSave({
            notificationType: "error",
            notification: `${t(e.message)}${
              e.originalRequest && e.originalRequest.errors ? `: ${e.originalRequest.errors[0].message}` : ""
            }`,
          });
        }
      }
    }

    this.setState({ errors });
  };

  render() {
    const {
      isLoading,
      isFetched,
      hoursBankActive,
      hoursBankRecurrenceInterval,
      startDate,
      hoursBankStartDateOverride,
      hoursBankStartDateNew,
      hbLimit,
      hbPeriod,
      ehLimit,
      ehPeriod,
      errors,
      status,
      isLocked,
      isDefault,
      hoursBankHideOnMobiles,
      missingDayStrategy,
      phasesSubstractionOrder,
      rawPhasesSubstractionOrder,
      confirmationPopupVisible,
      rawPhases,
      phases,
      overtimeStartAlert,
      phasesType,
      multiplierType,
      hoursBankResetType,
      hoursBankCyclesCountUntilReset,
      hoursBankLimits,
      customPhasesSubtractionOrder,
      calculateOvertimeOnlyForPositiveHours,
      includeBreakOnCalculation,
      hoursBankExpiredPhasesPositiveAction,
      hoursBankExpiredPhasesNegativeAction,
      discountDsrForMissingMinutesActive,
      discountDsrForMissingMinutesLimit,
      dsrEnabled,
      companyRulesDiscountDsrLimit,
      externalId,
      ignoreOvertimeOnNonPlannedDays,
      ignoreOvertimeOnNonPlannedDaysThreshold,
    } = this.state;
    const { t, flags } = this.props;

    // default value for newDailySummaryCalculation feature flag in launchdarkly is "null"
    // if flags is present get the value from flags else set it to "null"
    const newDailySummaryCalculationFeatureFlagValue = flags ? flags.newDailySummaryCalculation : "null";
    const groupDisabled = status !== "active";
    const groupLocked = isLocked;
    const inactive = groupDisabled || groupLocked;

    return (
      <div className={b()}>
        {isFetched ? (
          <div>
            {isLocked ? <HoursBankNotificationRow /> : null}
            <SettingsBlock>
              <FieldWrapper className={b("bancos-de-horas")}>
                <ToggleControl
                  checked={hoursBankActive}
                  disabled={inactive}
                  id="company-settings-bdo"
                  onChange={(checked) => {
                    if (!checked) {
                      this.setState({
                        confirmationPopupVisible: true,
                        phasesType: PHASE_TYPES.PERCENTAGE,
                      });
                    } else {
                      this.onBancosDeHorasChange(true);
                    }
                  }}
                />
                <label
                  className={b("label", {
                    "active-toggle": true,
                    disabled: inactive,
                  })}
                >
                  {hoursBankActive ? t("Deactivate Bank Hours") : t("Activate Bank Hours")}
                </label>
              </FieldWrapper>

              {hoursBankActive && (
                <div>
                  <FieldWrapper fieldName={t("Start Date")} width="100%">
                    <SingleDatePickerControl
                      value={startDate}
                      disabled={!hoursBankActive || groupDisabled || groupLocked}
                      locked={hoursBankActive && !groupDisabled && groupLocked}
                      error={errors && !!errors.startDate}
                      onChange={this.onStartDateChange}
                      isOutsideRange={(day) => {
                        if (isDefault) {
                          return day.isAfter(moment(), "day");
                        }
                        return day.isBefore(
                          startDate ? startDate.clone().subtract(3, "month") : moment().subtract(3, "month"),
                          "day",
                        );
                      }}
                    />
                  </FieldWrapper>
                  {errors && errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                  <FieldWrapper className={b("field")}>
                    <CheckboxControl
                      label={t("Change the date of the first due date")}
                      checked={hoursBankStartDateOverride}
                      disabled={!hoursBankActive || inactive}
                      id="company-settings-first-due-date"
                      onChange={(checked) => {
                        this.setState({
                          hoursBankStartDateOverride: checked,
                          hoursBankStartDateNew: checked ? startDate.clone() : hoursBankStartDateNew,
                        });
                      }}
                    />
                  </FieldWrapper>
                  {hoursBankStartDateOverride && (
                    <div className={b("field", { "first-due-date": true })}>
                      <div className={b("field-title")}>{t("First Due Date")}</div>
                      <SingleDatePickerControl
                        value={hoursBankStartDateNew}
                        disabled={!hoursBankActive || groupDisabled || groupLocked}
                        locked={hoursBankActive && !groupDisabled && groupLocked}
                        error={errors && !!errors.hoursBankStartDateNew}
                        onChange={this.onHoursBankStartDateNewChange}
                        isOutsideRange={(day) =>
                          startDate ? day.isBefore(startDate, "day") : day.isBefore(moment(), "day")
                        }
                      />
                    </div>
                  )}
                  {errors && errors.hoursBankStartDateNew && <ErrorLabel>{errors.hoursBankStartDateNew}</ErrorLabel>}
                  <div
                    className={b("field", {
                      duration: !hoursBankStartDateOverride,
                    })}
                  >
                    <div className={b("field-title")}>{t("Duration")}</div>
                    <Select
                      modifiers={{ field: true }}
                      value={hoursBankRecurrenceInterval}
                      disabled={groupDisabled || !hoursBankActive || groupLocked || hoursBankResetType === "cyclic"}
                      locked={hoursBankActive && !groupDisabled && groupLocked}
                      onChange={(value) => this.setState({ hoursBankRecurrenceInterval: value })}
                      options={this.availableDurations}
                    />
                  </div>
                  {hoursBankActive ? (
                    <NextDueDate
                      startDate={startDate}
                      hoursBankStartDateOverride={hoursBankStartDateOverride}
                      hoursBankStartDateNew={hoursBankStartDateNew}
                      hoursBankRecurrenceInterval={hoursBankRecurrenceInterval}
                      disabled={!hoursBankActive || inactive}
                      t={t}
                    />
                  ) : null}
                  <FieldWrapper className={b("field")}>
                    <CheckboxControl
                      label={t("Reset hours bank cyclically")}
                      checked={hoursBankResetType === "cyclic"}
                      disabled={!hoursBankActive || inactive}
                      id="company-settings-hoursBankResetType"
                      onChange={(checked) => {
                        this.setState({
                          hoursBankResetType: checked ? "cyclic" : "full",
                          hoursBankCyclesCountUntilReset: checked ? hoursBankCyclesCountUntilReset : null,
                          hoursBankRecurrenceInterval: this.availableDurations[0].value,
                        });
                      }}
                    />
                  </FieldWrapper>
                  <FieldWrapper className={b("field")} fieldName={t("Cycles count before reset")} width="100%">
                    <TextInputControl
                      type="text"
                      className={!hoursBankActive || hoursBankResetType !== "cyclic" ? "disabled" : ""}
                      value={typeof hoursBankCyclesCountUntilReset === "number" ? hoursBankCyclesCountUntilReset : ""}
                      disabled={!hoursBankActive || hoursBankResetType !== "cyclic" || inactive}
                      onChange={(value) => {
                        let count = parseInt(value, 10);
                        count = Number.isFinite(count) ? count : 0;
                        count = count > MAX_CYCLES_COUNT_UNTIL_RESET ? MAX_CYCLES_COUNT_UNTIL_RESET : count;
                        count = count < MIN_CYCLES_COUNT_UNTIL_RESET ? MIN_CYCLES_COUNT_UNTIL_RESET : count;

                        this.setState({ hoursBankCyclesCountUntilReset: count });
                      }}
                    />
                  </FieldWrapper>
                  {errors && errors.hoursBankCyclesCountUntilReset && (
                    <ErrorLabel>{errors.hoursBankCyclesCountUntilReset}</ErrorLabel>
                  )}
                  <FieldWrapper className={b("field")}>
                    <CheckboxControl
                      label={t("Hide Summary to Employees")}
                      checked={hoursBankHideOnMobiles}
                      disabled={!hoursBankActive || inactive}
                      id="company-settings-hoursBankHideOnMobiles"
                      onChange={(checked) => {
                        this.setState({ hoursBankHideOnMobiles: checked });
                      }}
                    />
                  </FieldWrapper>
                </div>
              )}
              {newDailySummaryCalculationFeatureFlagValue !== "null" && (
                <div>
                  {!hoursBankActive && (
                    <Subheader style={{ marginBottom: "-7px" }}>{t("Extra hours settings")}</Subheader>
                  )}
                  <FieldWrapper className={b("field")}>
                    <CheckboxControl
                      label={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <div>{t("Calculate overtime only for positive hours")}</div>
                          <RichTooltip text={t("calculate-overtime-positive-hours-info")} />
                        </div>
                      }
                      checked={calculateOvertimeOnlyForPositiveHours}
                      disabled={inactive}
                      onChange={(checked) => {
                        this.setState({
                          calculateOvertimeOnlyForPositiveHours: checked,
                          includeBreakOnCalculation: checked ? includeBreakOnCalculation : false,
                        });
                      }}
                    />
                  </FieldWrapper>
                  <FieldWrapper className={b("field")}>
                    <CheckboxControl
                      label={t("Include a break on the calculation")}
                      checked={includeBreakOnCalculation}
                      disabled={inactive || !calculateOvertimeOnlyForPositiveHours}
                      onChange={(checked) => {
                        this.setState({
                          includeBreakOnCalculation: checked,
                        });
                      }}
                    />
                  </FieldWrapper>
                  <br />
                </div>
              )}
              {hoursBankActive && (
                <div className={b("field")}>
                  <div className={b("field-title")}>{t("Missing Days")}</div>
                  <Select
                    modifiers={{ field: true }}
                    value={missingDayStrategy}
                    disabled={groupDisabled || !hoursBankActive || groupLocked}
                    locked={hoursBankActive && !groupDisabled && groupLocked}
                    onChange={(value) => this.setState({ missingDayStrategy: value })}
                    options={this.availableHoursBankMissingDayStrategies}
                  />
                </div>
              )}
            </SettingsBlock>
            {dsrEnabled && !hoursBankActive && (
              <CheckboxWithInputWrapper>
                <CheckboxControl
                  checked={discountDsrForMissingMinutesActive}
                  label={t("Discount DSR for late entries.")}
                  disabled={inactive}
                  onChange={(checked) =>
                    this.setState({
                      discountDsrForMissingMinutesActive: checked,
                      discountDsrForMissingMinutesLimit: checked ? companyRulesDiscountDsrLimit : 0,
                    })
                  }
                />
                <RichTooltip text={t("dsr-late-entries-info")} />
                {discountDsrForMissingMinutesActive && (
                  <TimeControl
                    className="limit"
                    disabled={!discountDsrForMissingMinutesActive || inactive}
                    locked={groupLocked}
                    value={minsToHrsMins(discountDsrForMissingMinutesLimit || 0)}
                    onTimeChange={(val) =>
                      this.setState({ discountDsrForMissingMinutesLimit: hrsMinsToMins(val.value) })
                    }
                    error={errors?.discountDsrForMissingMinutesLimit}
                    placeholder="00:00"
                  />
                )}
              </CheckboxWithInputWrapper>
            )}

            <CheckboxWithInputWrapper>
              <CheckboxControl
                checked={ignoreOvertimeOnNonPlannedDays}
                label={t("ignore-overtime-on-non-planned-days")}
                disabled={inactive}
                onChange={(checked) =>
                  this.setState({
                    ignoreOvertimeOnNonPlannedDays: checked,
                    ignoreOvertimeOnNonPlannedDaysThreshold: 0,
                  })
                }
              />
              <RichTooltip text={t("ignore-overtime-on-non-planned-days-info")} />
              {ignoreOvertimeOnNonPlannedDays && (
                <TimeControl
                  className="limit"
                  noHoursLimit
                  disabled={!ignoreOvertimeOnNonPlannedDays || inactive}
                  locked={groupLocked}
                  value={minsToHrsMins(ignoreOvertimeOnNonPlannedDaysThreshold || 0)}
                  onTimeChange={(val) =>
                    this.setState({ ignoreOvertimeOnNonPlannedDaysThreshold: hrsMinsToMins(val.value) })
                  }
                  error={errors?.ignoreOvertimeOnNonPlannedDaysThreshold}
                  placeholder="00:00"
                />
              )}
            </CheckboxWithInputWrapper>
            {errors?.ignoreOvertimeOnNonPlannedDaysThreshold && (
              <ErrorLabel>{errors.ignoreOvertimeOnNonPlannedDaysThreshold}</ErrorLabel>
            )}

            {flags.expiredPhasesActions && hoursBankActive && (
              <HBDischarge
                locked={groupLocked}
                disabled={groupDisabled}
                hoursBankExpiredPhasesPositiveAction={hoursBankExpiredPhasesPositiveAction}
                hoursBankExpiredPhasesNegativeAction={hoursBankExpiredPhasesNegativeAction}
                onPositiveActionChange={(value) => this.setState({ hoursBankExpiredPhasesPositiveAction: value })}
                onNegativeActionChange={(value) => this.setState({ hoursBankExpiredPhasesNegativeAction: value })}
              />
            )}
            {errors && errors.hoursBankExpiredPhasesPositiveAction && (
              <ErrorLabel>{errors.hoursBankExpiredPhasesPositiveAction}</ErrorLabel>
            )}
            <HbEhPhases
              disabled={groupDisabled}
              locked={groupLocked}
              inactive={inactive}
              hoursBankActive={hoursBankActive}
              onPhasesChange={this.onPhasesChange}
              rawPhasesSubstractionOrder={rawPhasesSubstractionOrder}
              phasesSubstractionOrder={phasesSubstractionOrder}
              phases={phases}
              errors={errors}
              rawPhases={rawPhases}
              phasesType={phasesType}
              multiplierType={multiplierType}
              customPhasesSubtractionOrder={customPhasesSubtractionOrder}
            />

            {hoursBankActive && (
              <HbLimits
                disabled={!hoursBankActive || groupDisabled || groupLocked}
                locked={hoursBankActive && !groupDisabled && groupLocked}
                hoursBankLimits={hoursBankLimits}
                onLimitsChange={this.onLimitsChange}
                onLimitsRemove={this.onLimitsRemove}
                phases={phases}
              />
            )}

            <NotificationsAndAlerts
              hoursBankActive={hoursBankActive}
              groupDisabled={groupDisabled}
              groupLocked={groupLocked}
              inactive={inactive}
              errors={errors}
              hbPeriod={hbPeriod}
              hbLimit={hbLimit}
              ehLimit={ehLimit}
              ehPeriod={ehPeriod}
              overtimeStartAlert={overtimeStartAlert}
              onEHPeriodChange={(val) => this.setState({ ehPeriod: val })}
              onHBPeriodChange={(val) => this.setState({ hbPeriod: val })}
              onHBLimitChange={(val) => {
                this.setState({
                  hbLimit: val,
                });
              }}
              onEHLimitChange={(val) => {
                this.setState({
                  ehLimit: val,
                });
              }}
              onOvertimeStartAlertChange={(checked) => this.setState({ overtimeStartAlert: checked })}
            />

            <br />
            <SwitchableTextField
              value={externalId}
              disabled={inactive}
              locked={groupLocked}
              placeholder="ID A1B2C3"
              label={t("external-id-label")}
              onChange={(val) => {
                this.setState({ externalId: val });
              }}
            />

            {!inactive ? (
              <div style={{ width: "132px", marginTop: "35px" }}>
                <Button state="primary" loading={isLoading} onClick={this.onNextClick} value={t("Save")} />
              </div>
            ) : null}
            <ModalDialog
              isOpen={confirmationPopupVisible}
              onClose={() => this.setState({ confirmationPopupVisible: false })}
            >
              <Lightbox
                title={t("Are you sure?")}
                text={t("This will switch the calculation to extra hours starting today.")}
                buttonYesTitle={t("common|Confirm")}
                buttonCancelTitle={t("common|Cancel")}
                onClose={() => {
                  this.setState({ confirmationPopupVisible: false });
                }}
                onYes={() => {
                  this.setState({ confirmationPopupVisible: false }, () => this.onBancosDeHorasChange(false));
                }}
              />
            </ModalDialog>
          </div>
        ) : (
          <div>{t("Loading...")}</div>
        )}
      </div>
    );
  }

  // translate true / false => dontDiscountHoursBankAndConsiderMissingDay / discountHoursBankAndConsiderMissingDay
  getHBMissingDayStrategyFromIgnoreMissingDays(hoursBankIgnoreMissingDays) {
    if (hoursBankIgnoreMissingDays == null) {
      return null;
    }
    if (hoursBankIgnoreMissingDays) {
      return "dontDiscountHoursBankAndConsiderMissingDay";
    }
    return "discountHoursBankAndConsiderMissingDay";
  }
}

export default withLDConsumer()(withTranslation(TranslationNamespaces.companyRules)(PunchingRulesGroupHoursbank));
