import { host } from "utils/host-environment";

const FONT_WEIGHTS = [300, 400, 450, 500, 600, 700];

class FontLoader {
  private getFontFamily(locale: string) {
    let fontFamily = "Circular";

    if (locale.toLowerCase() === "he-il") {
      fontFamily = "Almoni";
    }

    return fontFamily;
  }

  private getFontUrl(locale: string, fontWeight: number): string | null {
    let fontUrl: string | null;

    switch (locale.toLowerCase()) {
      case "he-il":
        switch (fontWeight) {
          case 300:
            fontUrl = "/fonts/ploni-light-aaa.woff";
            break;
          case 400:
          case 450:
            fontUrl = "/fonts/ploni-regular-aaa.woff";
            break;
          case 500:
            fontUrl = "/fonts/ploni-medium-aaa.woff";
            break;
          case 600:
            fontUrl = "/fonts/ploni-demibold-aaa.woff";
            break;
          case 700:
            fontUrl = "/fonts/ploni-bold-aaa.woff";
            break;
          default:
            fontUrl = null;
        }
        break;
      default:
        switch (fontWeight) {
          case 300: // load 400 for 300 to avoid error in console
          case 400:
          case 450:
            fontUrl = "/fonts/lineto-circular-pro-book.woff";
            break;
          case 500:
          case 600:
            fontUrl = "/fonts/lineto-circular-pro-medium.woff";
            break;
          case 700:
          case 900:
            fontUrl = "/fonts/lineto-circular-pro-bold.woff";
            break;
          default:
            fontUrl = null;
        }
        break;
    }

    if (host.isBrowserExtension) {
      return `chrome-extension://${chrome.runtime.id}/generated/web-app${fontUrl}`;
    }
    return `${process.env.PUBLIC_URL}${fontUrl}`;
  }

  /** Should be awaited to avoid fonts flickering after page refresh */
  async load(locale = ""): Promise<void> {
    const fontFamily = this.getFontFamily(locale);

    const promises = FONT_WEIGHTS.map(async (fontWeight: number): Promise<void> => {
      const fontUrl = this.getFontUrl(locale, fontWeight);

      if (fontUrl) {
        const descriptors = {
          style: "normal",
          weight: String(fontWeight),
        };

        const fontWoff = new FontFace(fontFamily, `url('${fontUrl}') format('woff')`, descriptors);
        const fontWoff2 = new FontFace(fontFamily, `url('${fontUrl}2') format('woff2')`, descriptors);

        await Promise.all([fontWoff.load(), fontWoff2.load()]);

        document.fonts.add(fontWoff);
        document.fonts.add(fontWoff2);
      }
    });

    document.body.classList.remove(
      ...Array.prototype.filter.call(document.body.classList, (c) => c.includes("font-family-")),
    );
    document.body.classList.add(`font-family-${fontFamily.toLowerCase()}`);

    await Promise.all(promises);
  }
}

export default new FontLoader();
