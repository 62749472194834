import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import { HeaderAction as HeaderActionPage, Title } from "components/styled/Page";
import { TranslationNamespaces } from "types/translationNamespaces";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import * as images from "../svg-images";

interface HeaderProps {
  marginTop?: number;
  detailedReportUrl: string | undefined;
  newNaviagation?: boolean;
  setShowSettingsPopup?: (show: boolean) => void;
  setShowAddPunchPopup?: (show: boolean) => void;
}

const Wrapper = styled.div<{ marginTop?: number }>`
  margin-top: ${(p) => (typeof p.marginTop === "undefined" ? 35 : p.marginTop)}px;
  display: flex;
  height: 37px;
`;

const HeaderAction = styled(HeaderActionPage)`
  & > * {
    flex-shrink: 0;
  }
`;

const PunchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid #b4c4d1;
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

const ActivityHeader = ({
  detailedReportUrl,
  marginTop,
  newNaviagation,
  setShowSettingsPopup,
  setShowAddPunchPopup,
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.employeesPage]);

  const punchSettingsEnabled = window.global_store.profile?.permissions?.some((r: string) => r === "punches_settings");
  return (
    <Wrapper marginTop={marginTop}>
      {!newNaviagation && <Title isRTL={window.global_store.isRTL}>{t("Activity")}</Title>}

      <HeaderAction>
        {detailedReportUrl && (
          <Button
            style={{ maxWidth: 120 }}
            state={ButtonState.outline}
            value={t("View Reports")}
            url={detailedReportUrl}
          />
        )}

        {newNaviagation && setShowSettingsPopup && punchSettingsEnabled && (
          <PunchSettingsButton onClick={() => setShowSettingsPopup(true)}>{images.gearIcon}</PunchSettingsButton>
        )}

        {newNaviagation && setShowAddPunchPopup && (
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(`${TranslationNamespaces.employeesPage}|Add Punches`)}
            onClick={() => setShowAddPunchPopup(true)}
          />
        )}
      </HeaderAction>
    </Wrapper>
  );
};

export default ActivityHeader;
