import { CustomField } from "types/models/projects";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

const CustomFieldsWrapper = styled.ul`
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  margin: 0;
  .CustomField {
    display: flex;
    align-items: flex-start;
    width: 100%;
    .Label {
      width: 100px;
      color: var(--colors-surface-800);
      font-size: var(--typography-font-size-default);
      line-height: 130%;
      padding: 9px 0;
    }
    input,
    textarea {
      padding: 8px;
      flex: 1 0 160px;
      border: 2px solid transparent;
      border-radius: var(--shapes-border-radius-default);
      resize: none;
      outline: none;
      &:hover {
        border-color: var(--colors-surface-400);
      }
      &:focus,
      &:focus-visible {
        border-color: var(--colors-primary-500-p);
      }
    }
  }
`;

const CustomFieldControl = ({
  cf,
  onBlur,
}: {
  cf: CustomField;
  onBlur: (uuid: string, value: string | number) => void;
}) => {
  const [value, setValue] = useState(cf.value);
  useEffect(() => {
    setValue(cf.value);
  }, [cf.value]);

  return cf.fieldType === "numeric" ? (
    <input
      value={value as unknown as number}
      onChange={(ev) => setValue(ev.target.value)}
      type="number"
      onBlur={(ev) => onBlur(cf.uuid!, ev.target.value)}
      placeholder="-"
    />
  ) : (
    <TextareaAutosize
      placeholder="-"
      value={value as unknown as string}
      onChange={(ev) => {
        setValue(ev.target.value);
      }}
      onBlur={(ev) => onBlur(cf.uuid!, ev.target.value)}
    />
  );
};
export const CustomFields = ({
  customFields,
  setCustomFields,
}: {
  customFields: CustomField[];
  setCustomFields: (values: CustomField[]) => void;
}) => {
  if (!customFields.length) return null;

  const onBlur = (uuid: string, value: string | number) => {
    setCustomFields(
      customFields.map((cf) => {
        const field = cf;
        if (cf.uuid === uuid) field.value = value as unknown as string;
        return field;
      }),
    );
  };

  return (
    <CustomFieldsWrapper className="CustomFields">
      {customFields.map((cf: CustomField) => (
        <li key={cf.uuid} className="CustomField">
          <div className="Label">{cf.name}</div>
          <CustomFieldControl cf={cf} onBlur={onBlur} />
        </li>
      ))}
    </CustomFieldsWrapper>
  );
};
