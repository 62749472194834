import React, { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-popup-message.scss";
import { withTranslation } from "react-i18next";
import DropdownControl from "components/UI/DropdownControl";
import styled from "styled-components";
import Button from "components/controls/StyledButton";
import Avatar from "../views/Avatar";

const b = BEM.b("schedule-popup-message");
const Buttons = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`;
const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;
class SchedulePopupMessage extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      title: "",
      description: null,
      buttonCancelTitle: t("common|Cancel"),
      buttonYesTitle: t("common|Confirm"),
      selectedSchedule: { value: "", label: "" },
    };
  }
  componentDidMount() {
    this.getContent();
  }

  closePopup() {
    this.props.onClose();
  }

  getContent() {
    let title = "";
    let description = null;
    let buttonYesTitle = "";
    const { type } = this.props;
    const { t } = this.props;
    const schedules = this.props.schedules || [];
    let { selectedSchedule } = this.state;

    if (schedules.length > 0 && !selectedSchedule.value) {
      [selectedSchedule] = schedules;
    }

    if (type === "disable") {
      title = t("Disable Schedule");
      description = (
        <div>
          <div>{t("Employees will be removed from this Schedule.")}</div>
          <div>{t("You can also reactivate this journey in the future.")}</div>
        </div>
      );
      buttonYesTitle = t("Yes, Disable");
    } else if (type === "change") {
      const { employee } = this.props;

      title = t("Change Schedule");
      description = (
        <div>
          <div>{t("The following employee will be moved to a new schedule")}</div>
          {employee && (
            <div className={b("user-info")}>
              <Avatar user={{ fullName: employee.fullName || employee.name, avatarId: employee.avatarId }} />
              <div className={b("name-position")}>
                <div className={b("name")}>{employee.fullName || employee.name}</div>
                {employee.position && <div className={b("position")}>{employee.position.title}</div>}
              </div>
            </div>
          )}
          {schedules.length > 0 && (
            <div className={b("schedules")}>
              <DropdownControl
                onChange={(val) => {
                  this.setState({ selectedSchedule: val });
                }}
                value={selectedSchedule}
                options={schedules}
              />
            </div>
          )}
        </div>
      );
      buttonYesTitle = t("Yes, Change");
    } else if (type === "remove") {
      const { employee } = this.props;
      title = t("Remove Employee");
      description = (
        <div>
          <div>{t("Are you sure you want to remove this Employee from your Schedule?")}</div>
          {employee && (
            <div className={b("user-info")}>
              <Avatar user={{ fullName: employee.fullName || employee.name, avatarId: employee.avatarId }} />
              <div className={b("name-position")}>
                <div className={b("name")}>{employee.fullName || employee.name}</div>
                {employee.position && <div className={b("position")}>{employee.position.title}</div>}
              </div>
            </div>
          )}
        </div>
      );
      buttonYesTitle = t("Yes, Remove");
    } else {
      return null;
    }
    this.setState({
      selectedSchedule,
      description,
      title,
      buttonYesTitle,
    });
    return null;
  }

  onCancel = () => {
    this.closePopup();
  };

  onYes = () => {
    this.setState({ isLoading: true });
    if (this.props.onYes) {
      if (this.props.type === "change") {
        this.props.onYes(this.state.selectedSchedule, this.props.employee);
      } else {
        this.props.onYes();
      }
    }
  };

  render() {
    const { title, description, buttonYesTitle, buttonCancelTitle, isLoading } = this.state;
    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        <div className={b("description")}>{description}</div>

        <Buttons>
          <Button state="secondary" onClick={this.onCancel} value={buttonCancelTitle} />
          <Spacer />
          <Button
            state="primary"
            loading={isLoading}
            onClick={this.onYes}
            onDoubleClick={this.onYes}
            value={buttonYesTitle}
          />
        </Buttons>
      </div>
    );
  }
}
export default withTranslation("schedules")(SchedulePopupMessage);
