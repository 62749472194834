import { stylesheet } from "astroturf";
import { useTranslation, TranslationNamespaces } from "types/translationNamespaces";
import { IconStartActivity, IconAddActivity } from "../styled";
import { Button, ButtonType } from "../Button";

const styles = stylesheet`
.Wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .StartActivity {
    height: 36px;
    font-size: 12px;
    font-weight: var(--typography-font-weight-bold);
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    svg {
      margin-inline-end: 11px;
      fill: #4CABF7;

      path {
        fill: white;
      }
    }
  }

  .AddActivity {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background: #E9F5FF;
    border-radius: 50%;
  }
}
`;

export interface RunningActivityCardProps {
  onStart: () => void;
  onAdd: () => void;
}

export function AddActivityButtons(props: RunningActivityCardProps) {
  const { onStart, onAdd } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <div className={styles.Wrapper}>
      <Button className={styles.StartActivity} buttonType={ButtonType.neutral} onClick={onStart}>
        <IconStartActivity /> {t("Start tracking")}
      </Button>
      <Button className={styles.AddActivity} onClick={onAdd}>
        <IconAddActivity />
      </Button>
    </div>
  );
}
