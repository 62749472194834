import { useTranslation } from "react-i18next";
import { useState } from "react";
import BreakModal, { DayInfoBreak } from "./BreakModal";
import { ScheduleBreak } from "./ScheduleBreak";
import { AddBreakBtnWrapper, AddBreakWrapper } from "./styled";
import * as images from "../../svg-images";

interface AddBreakModalProps {
  row: any;
  selected?: number;
  readOnly?: boolean;
  disableRemove?: boolean;
  breaks: DayInfoBreak[];
  updateDay?: (dayId: number, dayData: any) => void;
  onRemoveBreak: (id: number, index: number) => void;
}

export const BreaksCell = ({
  row,
  breaks,
  readOnly = false,
  selected,
  disableRemove = false,
  updateDay,
  onRemoveBreak,
}: AddBreakModalProps) => {
  const { t } = useTranslation("schedules");

  const [isOpen, setModalOpened] = useState(false);
  const [selectedElement, setSelectedElement] = useState<HTMLElement | undefined>(undefined);
  const [selectedBreakId, setSelectedBreakId] = useState<number | null>(null);

  return (
    <div>
      {breaks.map((b, i) => {
        const brk = { ...b };
        return (
          <ScheduleBreak
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            isSelected={i === selectedBreakId}
            index={`${row.index}-${i}`}
            readOnly={readOnly || row.original.id !== selected}
            disableRemove={disableRemove}
            brk={brk}
            onClick={(e) => {
              setSelectedBreakId(i);
              setModalOpened(true);
              setSelectedElement(e.target as HTMLElement);
            }}
            onRemoveBreak={() => onRemoveBreak(row.original.id, i)}
          />
        );
      })}

      {!readOnly ? (
        <AddBreakWrapper>
          <AddBreakBtnWrapper
            onClick={(e) => {
              setModalOpened(true);
              setSelectedElement(e.target as HTMLElement);
            }}
          >
            {images.plusBlue}
            {t("Add break")}
          </AddBreakBtnWrapper>
        </AddBreakWrapper>
      ) : null}

      {isOpen && (
        <BreakModal
          selectedBreakId={selectedBreakId}
          dayInfo={row.original}
          anchor={selectedElement}
          isOpen={isOpen}
          onSave={(data) => {
            if (!updateDay) return;

            const rowDay = row.original;

            let newBreaks = rowDay.breaks;

            if (selectedBreakId || selectedBreakId === 0) {
              newBreaks[selectedBreakId] = data;
            } else {
              newBreaks = [...rowDay.breaks, data];
            }

            updateDay(row.original.id, { breaks: newBreaks });
            setModalOpened(false);
            setSelectedElement(undefined);
            setSelectedBreakId(null);
          }}
          onClose={() => {
            setModalOpened(false);
            setSelectedElement(undefined);
            setSelectedBreakId(null);
          }}
        />
      )}
    </div>
  );
};
