import { Component, ContextType } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import moment from "moment";
import { fireEvent } from "utils/common";
import { SuperpunchContext } from "context/SuperpunchProvider";
import { DailySummaryPunch, PunchStatuses, PunchType, PunchValidation } from "types/models/punches";
import { SuperpunchDate, SuperpunchPunchCellData, SuperpunchPunchCellRawData } from "types/models/superpunch";
import { OnPunchActionData, OnPunchDeclineData, SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import { SuperpunchShiftEvent } from "types/models/shift";
import { TranslationNamespaces, WithTranslation, withTranslation } from "types/translationNamespaces";
import { getBreakPairs } from "components/Schedules/helpers";
import { generateEventKey } from "context/SuperpunchProvider/helpers";
import Cell, { SuperpunchCellStatus } from "./Cell";
import AdditionalBreaksDropdown from "./AdditionalBreaksDropdown";
import CellPunchDropdown from "./CellPunchDropdown";

const CellWrapper = styled.div`
  width: 100%;
  height: 50px;
  border: none;
  position: relative;
`;

/**
 * Shift key format is <prefix><id>. This function lists all ids that should be hidden. (only flexible breaks)
 * For each shift event key, if a non-null value is found, the array for that type is reset.
 * This ensures that only IDs that should be hidden remain in the array.
 * @param breakEventsWithPunches
 * @param shiftEvents
 * @returns
 */
const getShiftKeyIdsToHide = (
  breakEventsWithPunches: Record<string, SuperpunchPunchCellData>,
  shiftEvents: SuperpunchShiftEvent[],
) => {
  // will contain unique keys of hidden breaks
  const hiddenKeys = new Map<string, Set<string>>();

  Object.entries(breakEventsWithPunches).forEach(([key, cellData]) => {
    const keyPatternType = new RegExp(`(${PunchType.breakStart}|${PunchType.breakEnd})`);
    const keyPatternTypeWithId = new RegExp(`(${PunchType.breakStart}|${PunchType.breakEnd})(\\d+)`);

    const oppositeKey = key.replace(keyPatternType, (match) =>
      match === PunchType.breakStart ? PunchType.breakEnd : PunchType.breakStart,
    );
    const [, , id] = key.match(keyPatternTypeWithId) || [];
    // hide only breaks with breakMode rannge or duration
    const shiftEventWithBreakMode = shiftEvents.find((ev) => ev.key === `${PunchType.breakStart}${id}`);
    const breakTypeUuid = shiftEventWithBreakMode?.breakTypeUuid;

    if (breakTypeUuid && shiftEventWithBreakMode?.breakMode === "flexible") {
      if (!hiddenKeys.has(breakTypeUuid)) {
        hiddenKeys.set(breakTypeUuid, new Set());
      }

      if (!cellData.raw && !breakEventsWithPunches[oppositeKey]?.raw) {
        hiddenKeys.set(breakTypeUuid, hiddenKeys.get(breakTypeUuid)!.add(id));
      } else {
        hiddenKeys.set(breakTypeUuid, new Set());
      }
    }
  });

  return [...hiddenKeys.values()].flatMap((set) => [...set]);
};

interface CellCombinedBreaksProps extends WithTranslation {
  employeeUuid: string;
  rowDate: SuperpunchDate;
  isLockedDay: boolean;
  shiftEvents: SuperpunchShiftEvent[];
  breakEventsWithPunches: Record<string, SuperpunchPunchCellData>;
}

interface CellCombinedBreaksState {
  isDropdownOpen: boolean;
  requestFailed: boolean;
  breakEventsWithPunches: Record<string, SuperpunchPunchCellData>;
  hiddenBreakKeys: string[];
  selectedEvent: {
    breakName: string;
    shiftEventKey: string;
    cellData: SuperpunchPunchCellData;
  } | null;
}

class CellCombinedBreaks extends Component<CellCombinedBreaksProps, CellCombinedBreaksState> {
  static contextType = SuperpunchContext;
  context!: ContextType<typeof SuperpunchContext>;

  constructor(props: CellCombinedBreaksProps) {
    super(props);

    this.state = {
      requestFailed: false,
      isDropdownOpen: false,
      selectedEvent: null,
      breakEventsWithPunches: props.breakEventsWithPunches,
      hiddenBreakKeys: getShiftKeyIdsToHide(props.breakEventsWithPunches, props.shiftEvents),
    };
  }

  UNSAFE_componentWillMount() {
    document.addEventListener(this.getCellKey(), this.handleDataLoad);
  }

  componentWillUnmount() {
    document.removeEventListener(this.getCellKey(), this.handleDataLoad);
  }

  getCellKey = () => {
    const { rowDate } = this.props;

    return generateEventKey(
      SuperpunchCustomEvents.cellCombinedBreaks,
      moment(rowDate.raw, "YYYY-MM-DD").format("YYYYMMDD"),
    );
  };

  handleDataLoad = (ev: CustomEvent<SuperpunchPunchCellData & { eventKey: string }>) => {
    const { breakEventsWithPunches } = this.state;
    const { eventKey, ...cellData } = ev.detail;

    if (eventKey.indexOf("break_") !== 0) return;

    breakEventsWithPunches[eventKey] = cellData;

    this.setState({ breakEventsWithPunches });
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isDropdownOpen;

    this.setState({ isDropdownOpen: isOpen, selectedEvent: !open ? null : this.state.selectedEvent });
  };

  onChange = (punch?: DailySummaryPunch | Record<string, never>) => {
    const { changePunch, employeeInfo } = this.context;
    const { selectedEvent } = this.state;
    const { rowDate } = this.props;

    if (!selectedEvent) return;

    const punchType: PunchType = selectedEvent.shiftEventKey.replace(/\d+$/, "") as PunchType;
    const eventKey = selectedEvent.shiftEventKey;

    this.toggleOpen(false);

    if (punch) {
      changePunch({
        punch,
        destinationDate: rowDate.raw.clone(),
        selectedType: punchType,
        eventKey,
      });
    } else {
      fireEvent(SuperpunchCustomEvents.openNewPunchOverlay, {
        employeeInfo,
        selectedDate: rowDate.raw.clone(),
        selectedType: punchType,
        selectedEventKey: eventKey,
      });
    }
  };

  onPunchApprove = (data: OnPunchActionData) => {
    void this.context.onPunchApprove(data);
    this.toggleOpen(false);
  };

  onPunchValidate = (data: OnPunchActionData) => {
    void this.context.onPunchValidate(data);
    this.toggleOpen(false);
  };

  onPunchDecline = (data: OnPunchDeclineData) => {
    void this.context.onPunchDecline(data);
    this.toggleOpen(false);
  };

  getCellStatus = (breakEventsWithPunches: Record<string, SuperpunchPunchCellData>): SuperpunchCellStatus | null => {
    const events = Object.values(breakEventsWithPunches);

    let approvedCount = 0;
    let invalidCount = 0;
    let pendingCount = 0;
    let status = null;

    events.forEach((ev) => {
      if (ev.raw) {
        if (ev.raw.validation === PunchValidation.invalid) {
          invalidCount += 1;
        } else if (ev.raw.status === PunchStatuses.approved) {
          approvedCount += 1;
        } else if (ev.raw.status === PunchStatuses.pending) {
          pendingCount += 1;
        }
      }
    });

    if (events.length === approvedCount) {
      status = SuperpunchCellStatus.valid;
    } else if (invalidCount) {
      status = SuperpunchCellStatus.error;
    } else if (pendingCount) {
      status = SuperpunchCellStatus.pending;
    }

    return status;
  };

  render() {
    const { isDropdownOpen, requestFailed, selectedEvent, breakEventsWithPunches, hiddenBreakKeys } = this.state;
    const { t, rowDate, shiftEvents, isLockedDay, employeeUuid } = this.props;
    const disabled = !shiftEvents || !shiftEvents.length;

    const breaksArray = Object.values(breakEventsWithPunches)
      .filter((ev) => !!ev.raw)
      .map((ev) => ev.raw as SuperpunchPunchCellRawData);

    const breakPairsCount = getBreakPairs(breaksArray).length;

    const cellLabel = t(`breaks-cell`, { count: breakPairsCount });
    const cellStatus = this.getCellStatus(breakEventsWithPunches);

    return (
      <CellWrapper>
        <Cell
          requestFailed={requestFailed}
          inactive={rowDate.isDayOff}
          date={rowDate.raw}
          punch={{
            display: cellLabel,
            raw: null,
          }}
          onClick={() => !disabled && this.toggleOpen()}
          disabled={disabled}
          customStatus={cellStatus || undefined}
        />

        {isDropdownOpen && (
          <ClickOutside
            onClickOutside={(ev: MouseEvent) => {
              this.toggleOpen(false);
              ev.stopPropagation();
            }}
          >
            {!selectedEvent && (
              <AdditionalBreaksDropdown
                rowDate={rowDate}
                breakEventsWithPunches={breakEventsWithPunches}
                shiftEvents={shiftEvents}
                hiddenBreakKeys={hiddenBreakKeys}
                onSelectShiftEvent={(eventWithPunch) => {
                  this.setState({ selectedEvent: eventWithPunch });
                }}
                onAddBreakRow={(keyId) => {
                  // remove keyId from hiddenBreakKeys
                  this.setState({ hiddenBreakKeys: hiddenBreakKeys.filter((key) => key !== keyId) });
                }}
              />
            )}
            {selectedEvent && (
              <CellPunchDropdown
                title={`${t("Select a punch")}: ${selectedEvent.breakName}`}
                onTitleBackClick={() => {
                  this.setState({ selectedEvent: null });
                }}
                employeeUuid={employeeUuid}
                value={selectedEvent.cellData}
                eventKey={selectedEvent.shiftEventKey}
                date={rowDate.raw}
                isLockedDay={isLockedDay}
                onChange={this.onChange}
                onPunchApprove={this.onPunchApprove}
                onPunchValidate={this.onPunchValidate}
                onPunchDecline={this.onPunchDecline}
                hideDropdown={() => this.toggleOpen(false)}
              />
            )}
          </ClickOutside>
        )}
      </CellWrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.punchesPage)(CellCombinedBreaks);
