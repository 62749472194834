// todo
enum DigitalSignatureStatus {
  missing = "missing",
  pending = "pending",
  canceled = "canceled",
  signed = "signed",
  signRequestSent = "signRequestSent",
  signRequestInProggress = "signRequestInProggress",
}

type DetailedReportDigitalSignature = {
  status: DigitalSignatureStatus;
  createdAt: string | null;
  createdBy: string | null;
  signedAt: string | null;
  signedBy: string | null;
};

export type { DetailedReportDigitalSignature };

export { DigitalSignatureStatus };
