import { APIResponse, sendRequest } from "./common";
import { AddDepartmentRequestData, AddDepartmentResponseData, GetDepartmentResponseData } from "./types";

type TODORequestData = any;
type TODOResponseData = Promise<unknown>;

export function getDepartments(): APIResponse<GetDepartmentResponseData> {
  return sendRequest({}, "GET", "/departments");
}

export function addDepartment(data: AddDepartmentRequestData): APIResponse<AddDepartmentResponseData> {
  return sendRequest(data.body, "POST", "/departments");
}

export function updateDepartment(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "PUT", `/departments/${data.id}`);
}

export function deleteDepartment(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "DELETE", `/departments/${data.id}`);
}

export function addEmployeesToDepartment(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/departments/${data.uuid}/add_employees`);
}
