import { Location } from "./location";

enum CompanyType {
  llc = "llc",
  household = "household",
}

/* eslint-disable camelcase */
type UserProfileCompany = {
  country: string;
  currency: string;
  id: number;
  uuid: string;
  name: string;
  logo_version: unknown | null; // todo
  logo_id: string;
  created_at: string;
};

type ReportUserInfoCompany = {
  id: number;
  uuid: string;
  country: string;
  default_location: {
    country: string;
  };
};

type CompanyProfileIndustryType = {
  id: number;
  name: string;
  key: string; // todo enum ?
  created_at: string;
  updated_at: string;
};

enum CompanyCurrency {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
  ILS = "ILS",
  GBP = "GBP",
  AUD = "AUD",
  JPY = "JPY",
  CHF = "CHF",
  AED = "AED",
}

type CompanyProfileCompany = {
  name: string;
  fullName: string;
  taxPayerId: string;
  category: CompanyProfileIndustryType["id"];
  logo: string;
  documentLogo: string;
  currency: CompanyCurrency;
};

type GlobalStoreCompany = {
  active: boolean;
  billing_info: {
    city: string;
    company_full_name: string | null;
    company_id: number;
    company_name: string;
    country: string;
    created_at: string;
    email: string;
    extension: string;
    id: number;
    number: string;
    state: string;
    street: string;
    tax_payer_id: string;
    updated_at: string;
    zip: string;
  };
  boleto_url: string | null; // todo
  client_id: string;
  client_secret: string;
  company_type: string;
  currency: CompanyCurrency;
  default_location: Omit<Location, "manager">;
  default_location_id: number;
  document_logo_id: string | null;
  employee_count: number | null;
  employer_id: number;
  id: number;
  industry_type_id: number;
  legal_name: string;
  logo_id: string | null;
  logo_version: unknown | null;
  name: string;
  payment_status: string | "pending";
  tablet_code: string;
  tax_payer_id: string;
  time_zone: string;
  uuid: string;
  timesheet_start_day: "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";
};

type SignUpCompany = {
  organization_id: number;
  id: number;
  logo_id: null;
  document_logo_id: null;
  slug: string;
  company_type: CompanyType;
  /** always true */
  active: boolean; // always true
  name: string;
  created_at: string;
  updated_at: string;
  /** user id (not user profile id) */
  creator_id: number;
  default_location_id: number;
  tax_payer_id: null;
  employee_count: null;
  /** user profile id */
  employer_id: number;
  uuid: string;
  source_platform: "user_admin"; // TODO always?
  source_type: "create_company"; // TODO always?
  logo_version: null;
  company_subscription_id: null;
  tablet_code: string;
  referred_by_id: null;
  client_id: null;
  client_secret: null;
  settings_updated_at: null;
  locale: "en"; // TODO always?
  currency: CompanyCurrency.USD; // TODO always?
  legacy_hubspot_id: null;
  gainsight_id: null;
  beacon_manufacture: "sensoro"; // TODO always?
  timesheet_start_day: "monday"; // TODO always?
  external_id: null;
  session:
    | Record<string, never>
    | {
        userProfileUuid: string;
        token: string;
      };
};

declare global {
  type WindowGlobalStoreCompany = GlobalStoreCompany;
}

export type {
  ReportUserInfoCompany,
  UserProfileCompany,
  CompanyProfileCompany,
  CompanyProfileIndustryType,
  GlobalStoreCompany,
  SignUpCompany,
};

export { CompanyCurrency, CompanyType };
