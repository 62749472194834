import { Component, CSSProperties, KeyboardEvent } from "react";
import ClickOutside from "react-click-outside";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import BEM from "utils/BEM";
import Option from "./SelectOption";
import "./Select.scss";

const b = BEM.b("ui-select");

const Wrapper = styled.div`
  .ui-select__input {
    padding: 0;
    padding-inline-start: 16px;
    padding-inline-end: 28px;
  }

  .ui-select__input::after {
    ${rtl`
      right: 16px;
    `}
  }

  .ui-select__input_disabled,
  .ui-select__input_locked {
    background-position-y: 50%;
    ${(p) => (p.theme.dir === "ltr" ? "background-position-x: calc(100% - 17px);" : "background-position-x: 17px;")}
  }
`;

const Label = styled.div``;

interface SelectOption<V, L> {
  value: V;
  label: L;
}

interface SelectProps<V, O> {
  /** default type is string */
  value: V;
  /** any object that implements SelectOption interface */
  options: O[];
  onChange?: (value: V) => void;
  placeholder?: string;
  disabled?: boolean;
  locked?: boolean;
  /** wrapper modifiers */
  modifiers?: Record<"field", boolean>;
  wrapperStyles?: CSSProperties;
}

interface SelectState<O> {
  isOpen: boolean;
  preselected: O | null;
}

class Select<V = string, L = string, O extends SelectOption<V, L> = SelectOption<V, L>> extends Component<
  SelectProps<V, O>,
  SelectState<O>
> {
  inputRef: HTMLInputElement | null = null;

  readonly state: Readonly<SelectState<O>> = {
    isOpen: false,
    preselected: null,
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;
    const preselected = !isOpen ? null : this.state.preselected;

    if (isOpen && this.inputRef) {
      this.inputRef.focus();
    }

    this.setState({ isOpen, preselected });
  };

  handleKeyPress = (ev: KeyboardEvent<HTMLInputElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    const { options } = this.props;

    if (options?.length) {
      if (ev.which === 27) {
        this.toggleOpen(false);
      } else if (ev.which === 38 || ev.which === 40) {
        this.preselectValue(ev.which === 38);
      } else if (ev.which === 13) {
        if (this.state.preselected) {
          this.selectValue(this.state.preselected.value);
        } else {
          this.toggleOpen(false);
        }
      }
    }
    return false;
  };

  preselectValue = (isUp: boolean) => {
    const { options } = this.props;
    const { preselected } = this.state;

    if (preselected) {
      let nextIndex = options.map((o) => o.value).indexOf(preselected.value);

      if (options.length > 1) {
        nextIndex = isUp ? nextIndex - 1 : nextIndex + 1;

        if (nextIndex < 0) {
          nextIndex = options.length - 1;
        } else if (nextIndex > options.length - 1) {
          nextIndex = 0;
        }
      }

      this.setState({ preselected: options[nextIndex] });
    } else {
      this.setState({ preselected: options[0] });
    }
  };

  selectValue = (value: V) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }

    this.toggleOpen(false);
  };

  isActive = (optionValue: V) => {
    const { value } = this.props;

    return optionValue === value;
  };

  renderSelectedLabel = () => {
    const { value, options, placeholder } = this.props;
    const shouldFindString = typeof value === "string" && (value === "0" || value === "");
    const shouldFindNumber = typeof value === "number" && value === 0;

    const selectedOption =
      value || shouldFindString || shouldFindNumber ? options.find((option) => option.value === value) : "";

    return selectedOption ? selectedOption.label : placeholder || String(value);
  };

  render() {
    const { options, disabled, locked, modifiers, wrapperStyles } = this.props;
    const { isOpen, preselected } = this.state;

    return (
      <Wrapper className={b("wrapper", modifiers)} style={wrapperStyles}>
        <ClickOutside onClickOutside={() => this.toggleOpen(false)}>
          <input
            type="text"
            onKeyDown={this.handleKeyPress}
            style={{ zIndex: 0, position: "absolute", border: "none", width: "100%", left: 0 }}
            ref={(ref) => {
              this.inputRef = ref;
            }}
          />

          <Label
            className={b("input", { open: isOpen, disabled, locked })}
            onClick={() => !disabled && !locked && this.toggleOpen()}
          >
            {this.renderSelectedLabel()}
          </Label>

          {isOpen && (
            <div className={b("option-list")}>
              {options.map((option) => (
                <Option
                  key={String(option.value)}
                  preselected={preselected && option.value === preselected.value}
                  onSelect={() => this.selectValue(option.value)}
                  label={option.label}
                  active={this.isActive(option.value)}
                />
              ))}
            </div>
          )}
        </ClickOutside>
      </Wrapper>
    );
  }
}

export default Select;
