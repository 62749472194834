import * as oitchauDb from "utils/oitchauDb";
import { chooseProfile, getActiveUserProfiles } from "utils/apiHelpers";
import CONFIG from "config";
import { PermissionSectionName, PermissionRoleName } from "types/models/permissions";
import { UserProfile, UserProfileRole, UserProfileStatus } from "types/models/userProfile";
import { DEFAULT_COLOR_THEME, COLOR_THEME_LS_KEY } from "components/Layout/ThemeSwitcher";
import { LicenseManager } from "ag-grid-enterprise";
import { urlParam } from "./common";
import { clearLocalStorage, setObjectToLocalStorage, LocalStorageKeys, setLsProfile } from "./localStorageUtils";
import { AvailableLocales, getLocale, saveLocale } from "./translationHelpers";

LicenseManager.setLicenseKey(CONFIG.agGridLicense || "");

export const checkForGhostUser = async (): Promise<UserProfile | undefined> => {
  const userProfiles = await getActiveUserProfiles();

  if (userProfiles) {
    if (userProfiles.length === 0) {
      setLsProfile(null);
      window.location = "/account-type" as unknown as Location;
    } else {
      const profileToSelect = userProfiles.find((up) => up.employee_status === UserProfileStatus.active);

      const profile = (await chooseProfile({
        profileId: profileToSelect ? profileToSelect.id : userProfiles[0].id,
      })) as UserProfile;

      window.global_store.profile = profile;
      setLsProfile(profile);

      return profile;
    }
  }

  return undefined;
};

export const initBeamer = (profile: UserProfile): void => {
  if (CONFIG.appEnv === "production" && window.Beamer) {
    window.beamer_config = {
      // default beamer props
      ...window.beamer_config,
      filter_by_url: true,
      language: profile.locale,
      display: window.global_store.isRTL ? "left" : "right",
      user_firstname: profile.full_name,
      user_id: profile.id,
      // custom beamer props
      company_id: profile.company.id,
      company_name: profile.company.name,
      profile_id: profile.id,
      user_permission: profile.permission_roles?.[0]?.name,
    };
    window.Beamer.init();
  }
};

export const initZendesk = (profile?: UserProfile) => {
  const isAllowedRoute = !["/my-digital-signatures"].some((p) => window.location.pathname.includes(p));
  const hasAccess =
    !!profile &&
    (profile.role === UserProfileRole.employer ||
      profile.permission_roles.some((pr) =>
        [PermissionRoleName.admin, PermissionRoleName.hr, PermissionRoleName.supervisor].includes(pr.name),
      ));

  if (window.zE) {
    setTimeout(() => {
      if (isAllowedRoute && hasAccess) {
        window.zE("messenger:set", "locale", profile.locale);
        window.zE("messenger", "show");
      } else {
        window.zE("messenger", "hide");
      }
    });
  }
};

export const initHotjar = (profile: UserProfile): void => {
  setTimeout(() => {
    if (CONFIG.appEnv === "production" && window.hj) {
      window.hj("identify", profile.id, {
        companyId: profile.company.id,
        companyName: profile.company.name,
        profileId: profile.id,
        userPermission: profile.permission_roles?.[0]?.name,
        language: profile.locale,
        associateProducts: profile.associate_products.join(", "),
      });
    }
  }, 0);
};

export const initColorTheme = () => {
  let theme = localStorage.getItem(COLOR_THEME_LS_KEY);

  if (!theme) {
    theme = DEFAULT_COLOR_THEME;
    localStorage.setItem(COLOR_THEME_LS_KEY, theme);
  }

  document.body.classList.add(theme);
};

export const setTextDirection = (locale: string): void => {
  if (locale.toLowerCase() === AvailableLocales.he.toLowerCase()) {
    window.global_store.isRTL = true;
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  } else {
    window.global_store.isRTL = false;
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  }
};

export const setLang = (locale: string): void => {
  document.documentElement.setAttribute("lang", locale.toLowerCase());
};

export const setCompanyDashboards = (dashboardsActions: string[]) => {
  if (dashboardsActions?.length) {
    setObjectToLocalStorage(LocalStorageKeys.companyDashboards, dashboardsActions);
  } else {
    setObjectToLocalStorage(LocalStorageKeys.companyDashboards, null);
  }
};

/* eslint-disable no-extend-native */
export const addPolyfills = (): void => {
  // // CustomEvent polyfill
  (function CustomEventPolyfill(): boolean | void {
    if (typeof window.CustomEvent === "function") {
      return false;
    }

    function CustomEvent(event: string, params?: { bubbles: boolean; cancelable: boolean; detail: any }): CustomEvent {
      const evtParams = params || {
        bubbles: false,
        cancelable: false,
        detail: null,
      };

      const evt = document.createEvent("CustomEvent");
      evt.initCustomEvent(event, evtParams.bubbles, evtParams.cancelable, evtParams.detail);

      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent as any; // todo
  })();

  // // Array.indexOf polyfill
  (function IndexOfPolyfill(): void {
    if (!Array.prototype.indexOf) {
      Array.prototype.indexOf = function indexOf(obj, start?: number): number {
        // eslint-disable-next-line no-plusplus
        for (let i = start || 0, j = this.length; i < j; i++) {
          if (this[i] === obj) {
            return i;
          }
        }
        return -1;
      };
    }
  })();
  // /

  // // Array.find polyfill
  (function FindPolyfill(): void {
    if (!Array.prototype.find) {
      Array.prototype.find = function find<S>(
        callback: (value: any, index: number, obj: any[]) => value is S,
      ): S | undefined {
        let elem: S | undefined;

        for (let i = 0; i < this.length; i += 1) {
          const result = callback(this[i], i, this);
          if (result) {
            elem = this[i];
            break;
          }
        }

        return elem;
      };
    }
  })();
  // /

  // // Array.flat polyfill
  (function FlatPolyfill() {
    if (!Array.prototype.flat) {
      Array.prototype.flat = function flat<T>() {
        let flatArray: T[] = [];

        for (let i = 0; i < this.length; i += 1) {
          if (Array.isArray(this[i])) {
            flatArray = flatArray.concat(this[i].flat());
          } else {
            flatArray.push(this[i]);
          }
        }

        return flatArray;
      };
    }
  })();
  // /

  // // requestIdleCallback polyfill
  if (typeof window !== "undefined") {
    window.requestIdleCallback =
      window.requestIdleCallback ||
      function requestIdleCallback(cb): NodeJS.Timeout {
        const start = Date.now();

        return setTimeout(() => {
          cb({
            didTimeout: false,
            timeRemaining() {
              return Math.max(0, 50 - (Date.now() - start));
            },
          });
        }, 1);
      };
  }
};

export const googleMapsInit = async () => {
  if (typeof google !== "undefined" && google.maps) {
    await Promise.all([google.maps.importLibrary("geocoding"), google.maps.importLibrary("places")]);
    window.googleMapsApiCallback && window.googleMapsApiCallback();
  }
};

export const setLocalStorageVersion = (): void => {
  const localStorageVersion = "4";

  if (localStorage) {
    if (
      !localStorage.getItem("version") ||
      parseInt(localStorage.getItem("version") || "0", 10) < parseInt(localStorageVersion, 10)
    ) {
      localStorage.clear();
      localStorage.setItem("version", String(parseInt(localStorageVersion, 10)));
    }
  }
};

export const clearStorageAndDb = async (): Promise<void> => {
  await oitchauDb.clearDb();

  clearLocalStorage();
};

export const PROJECTS_PERMISSIONS = [
  PermissionSectionName.projects,
  PermissionSectionName.approveActivities,
  PermissionSectionName.activities,
  PermissionSectionName.projectEmployeeCost,
];

/**
 * Some permissions exist only in the UI and are not returned by the API.
 * Some permissions are infered based on user role or other permissions.
 * Get all user permissions including those that don't exist on the backend and ifered ones.
 */
export const getPermissions = async (profile: UserProfile): Promise<PermissionSectionName[]> => {
  const permissions = profile.permissions ? (Object.keys(profile.permissions) as PermissionSectionName[]) : [];
  const hasAdminPermissionRole =
    !!profile.permission_roles && profile.permission_roles.some((pr) => pr.name.toLowerCase() === "admin");
  const hasOwnerPermissionRole =
    !!profile.permission_roles && profile.permission_roles.some((pr) => pr.name.toLowerCase() === "owner");

  if (!permissions.length) {
    return permissions;
  }

  if (profile.role === UserProfileRole.employer || hasAdminPermissionRole || hasOwnerPermissionRole) {
    permissions.push(PermissionSectionName.security);
  }

  if (
    profile.role === UserProfileRole.employer ||
    permissions.includes(PermissionSectionName.payrollExport) ||
    hasAdminPermissionRole
  ) {
    permissions.push(PermissionSectionName.payroll);
  }

  // allow punches page if user has view or manage punches permission
  if (
    !permissions.includes(PermissionSectionName.punches) &&
    (permissions.includes(PermissionSectionName.viewPunches) ||
      permissions.includes(PermissionSectionName.managePunches))
  ) {
    permissions.push(PermissionSectionName.punches);
  }

  return permissions;
};

/**
 * initialize injected css
 */
export function injectCssOverrides(): void {
  const inectedCssUrl = urlParam("css_override");
  // TODO: remove when going live
  // const inectedCssUrl = `http://localhost:8080/injection.css`;
  if (!inectedCssUrl) return;

  const link = document.createElement("link");
  link.href = inectedCssUrl;
  link.type = "text/css";
  link.rel = "stylesheet";
  link.media = "screen,print";
  document.getElementsByTagName("head")[0].appendChild(link);
}

/**
 * set beta flag to global_store if it's provided in url or local storage
 */
export function setBetaFlag(): void {
  const param = urlParam("beta") || localStorage.getItem(LocalStorageKeys.betaFlag);

  if (param === "true") {
    localStorage.setItem(LocalStorageKeys.betaFlag, param);
    window.global_store.beta = true;
  } else {
    localStorage.removeItem(LocalStorageKeys.betaFlag);
    window.global_store.beta = false;
  }
}

/**
 * set embedded flag to global_store if it's provided in url or local storage
 */
export function setEmbeddedFlag(): void {
  const param = urlParam("embedded") || localStorage.getItem(LocalStorageKeys.embedded);

  if (param === "true") {
    localStorage.setItem(LocalStorageKeys.embedded, param);
    window.global_store.embedded = true;
  } else {
    localStorage.removeItem(LocalStorageKeys.embedded);
    window.global_store.embedded = false;
  }
}

export function getDetectedLanguage(): string {
  const availableLocales = ["en-us", "pt-br", "es-ar", "es-es", "pt-pt", "it-it", "de-de", "he-il"];

  if (urlParam("locale")) {
    saveLocale(urlParam("locale")!.toString());
  } else if (!localStorage.getItem(LocalStorageKeys.locale)) {
    if (window.navigator.language || window.navigator.userLanguage) {
      const detected = window.navigator.language || window.navigator.userLanguage;
      if (detected) {
        if (availableLocales.indexOf(detected.toLowerCase()) === -1) {
          if (detected.toLowerCase().indexOf("en") === 0) {
            saveLocale(AvailableLocales.en);
          } else if (detected.toLowerCase().indexOf("es") === 0) {
            saveLocale(AvailableLocales.es);
          } else if (detected.toLowerCase().indexOf("pt") === 0) {
            saveLocale(AvailableLocales.pt);
          }
        } else {
          saveLocale(detected);
        }
      }
    }

    if (!localStorage.getItem(LocalStorageKeys.locale)) {
      saveLocale(null); // set default locale
    }
  }

  return getLocale();
}

/**
 * @param {string} locale - a two sybmol language code or a full locale code
 */
export function getCountryBasedLanguage(locale: string): string {
  let localeToReturn = "en_us";
  if (locale.length === 2) {
    switch (locale.toLowerCase()) {
      case "en":
        localeToReturn = AvailableLocales.en;
        break;
      case "pt":
        localeToReturn = AvailableLocales.pt;
        break;
      case "es":
        localeToReturn = AvailableLocales.es;
        break;

      default:
        break;
    }
  } else {
    localeToReturn = locale;
  }

  return localeToReturn;
}
