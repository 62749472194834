import React, { ChangeEventHandler, useMemo } from "react";
import styled from "styled-components";
import Spinner from "@atlaskit/spinner";
import { searchLens } from "../../../svg-images";
import { ClearIcon } from "./TimerButtons";

const SearchWrapper = styled.div`
  display: flex;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-shrink: 0;

  .Icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .Title {
    flex: 1 0 0;
    color: var(--colors-surface-800);
    font-size: 16px;
  }

  .SearchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    flex: 1 0 0;
    color: var(--colors-surface-900-p);
    border: none;

    &::placeholder {
      color: var(--colors-surface-700);
    }
  }
`;

export const SearchBar = ({
  searchValue,
  setSearchValue,
  placeholder,
  additionalAction,
  loading,
}: {
  searchValue: string;
  setSearchValue: (val: string) => void;
  placeholder: string;
  additionalAction?: () => void;
  loading?: boolean;
}) => {
  const search: ChangeEventHandler<HTMLInputElement> = (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
  };

  const getIcon = useMemo(() => {
    if (loading)
      return (
        <div className="Icon">
          <Spinner size="small" />
        </div>
      );
    if (searchValue)
      return (
        <div className="Icon" onClick={() => setSearchValue("")}>
          <ClearIcon />
        </div>
      );
    if (additionalAction)
      return (
        <div className="Icon" onClick={() => additionalAction()}>
          <ClearIcon />
        </div>
      );
    return null;
  }, [loading, searchValue, additionalAction]);

  return (
    <SearchWrapper>
      <div className="SearchIcon">{searchLens}</div>
      <input autoFocus placeholder={placeholder} value={searchValue} onChange={search} type="text" />
      {getIcon}
    </SearchWrapper>
  );
};
