import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Row = styled.div`
  position: relative;
  width: 100%;
  padding-top: 13px;
  padding-inline-end: 8px;
  padding-bottom: 11px;
  padding-inline-start: 57px;
  line-height: 24px;
  font-size: var(--typography-font-size-default);
  border-radius: var(--shapes-border-radius-default);
  color: var(--colors-mainText);
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-color: var(--colors-unknown43);
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z' fill='%23FBC533'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.0324 9.10205L19.9321 10.239C22.2798 11.7111 23.6444 14.4101 23.1808 17.2299C22.8761 19.0894 21.8304 20.7235 20.2408 21.8305C18.8528 22.7985 17.1924 23.263 15.4998 23.1709L15.7901 22.8782C15.7848 22.8782 15.7784 22.8795 15.7731 22.8795L16.8748 21.7734L15.8578 20.8456L13.6741 23.0389L13.6731 23.0376L12.7314 23.9839L13.7471 24.9121L13.7484 24.9108L16.0401 26.9992L16.9828 26.0542L15.4984 24.7013C17.5298 24.7925 19.5168 24.2282 21.1821 23.0673C23.1194 21.7198 24.3904 19.7309 24.7628 17.4678C25.3111 14.1251 23.7524 10.9229 21.0324 9.10205Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.69157 15.0452C8.99624 13.187 10.0419 11.5529 11.6319 10.4446C13.0199 9.47788 14.6799 9.01205 16.3726 9.10547L16.0809 9.39689C16.0876 9.39689 16.0926 9.39689 16.0992 9.39689L14.9976 10.5017L16.0146 11.4295L18.1982 9.23747L19.1409 8.29243L18.1239 7.36428L15.8322 5.27588L14.8896 6.2222L16.3739 7.57504C14.3426 7.48257 12.3556 8.04692 10.6889 9.20782C8.75291 10.5565 7.48191 12.5454 7.10957 14.8086C6.56024 18.1503 8.11857 21.3521 10.8389 23.1743L11.9389 22.0373C9.59124 20.564 8.22691 17.8663 8.69157 15.0452Z' fill='white'/%3E%3C/svg%3E%0A");
`;

interface InProgressRowProps {
  text: string;
}

const InProgressRow = ({ text }: InProgressRowProps) => {
  const { t } = useTranslation();

  return <Row className="in-progress">{t(text)}</Row>;
};

export default InProgressRow;
