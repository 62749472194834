import { Injectable } from "services/di";
import {
  OitchauAuthedApi,
  CreateActivityReq,
  ListActivitiesReq,
  TaskRes,
  ActivityRes,
  ProjRes,
  ListTimesheetsRequest,
  TimesheetApprovalStatus,
} from "services/oitchau-api";
import { AppStorage } from "services/app-storage";
import moment from "moment-timezone";
import { InjectedTaskData } from "types/injected";

export type GetUnavailableDatesRequest = {
  from: string;
  to?: string;
  userProfileUuid: string;
};

export type ActivityValidationErrors = {
  task?: boolean;
  customFields?: {
    [uuid: string]: boolean;
  };
};

@Injectable()
export class ProjectsService {
  constructor(protected oitchauApi: OitchauAuthedApi, protected appStorage: AppStorage) {}

  async getRunningActivity(filters) {
    const profile = await this.appStorage.getProfile();
    const req: ListActivitiesReq = {
      ...filters,
      companyUuid: profile.company.uuid,
      status: "running",
      skip: 0,
      limit: 10,
      sort_direction: "DESC",
    };
    const res = await this.oitchauApi.listActivities(req);
    return res.content[0];
  }

  async *getActivitiesPaged(filters) {
    const profile = await this.appStorage.getProfile();
    const limit = 50;
    let skip = 0;
    while (true) {
      const req = {
        ...filters,
        companyUuid: profile.company.uuid,
        skip,
        limit,
        sort_direction: "DESC",
      };
      const page = await this.oitchauApi.listActivities(req);
      yield page.content;
      if (page.content.length < limit) return;
      skip += limit;
    }
  }

  async *getActivitiesPerDay(filters) {
    const pagesGen = this.getActivitiesPaged(filters);
    let completeDays = [];
    let date: string | null = null;
    let activities = [];
    for await (const page of pagesGen) {
      for (const activity of page) {
        const isNewDay = date == null || date !== activity.date;
        if (isNewDay) {
          if (date != null) {
            completeDays.push(activities);
          }
          date = activity.date;
          activities = [];
        }
        activities.push(activity);
      }
      if (completeDays.length > 0) {
        yield completeDays;
        completeDays = [];
      }
    }
    if (activities.length > 0) {
      completeDays.push(activities);
    }
    yield completeDays;
  }

  async getActivity(uuid: string) {
    const profile = (await this.appStorage.getProfile())!;
    const opts = {
      companyUuid: profile.company.uuid,
      activityUuid: uuid,
    };
    const res = await this.oitchauApi.getActivity(opts);
    return res.content;
  }

  async createActivity(act) {
    const profile = (await this.appStorage.getProfile())!;
    const activity: CreateActivityReq = {
      companyUuid: profile.company.uuid,
      userProfileUuid: profile.uuid,
      createdBy: profile.uuid,
      ...act,
    };
    const res = await this.oitchauApi.createActivity(activity);
    return res.content;
  }

  async updateActivity(activity: CreateActivityReq) {
    const res = await this.oitchauApi.updateActivity(activity);
    return res;
  }

  async deleteActivity(activity: CreateActivityReq) {
    const res = await this.oitchauApi.deleteActivity(activity);

    return res;
  }

  protected groupTasksByProject(tasks: TaskRes[]): ProjRes[] {
    const dict: Record<string, ProjRes> = {};
    for (const task of tasks) {
      if (!dict[task.project.uuid]) {
        dict[task.project.uuid] = { ...task.project, tasks: [] };
      }
      dict[task.project.uuid].tasks.push(task);
    }
    const projs = Object.values(dict);
    return projs;
  }

  async listProjectsWithTasks(userProfileUuid?: string) {
    const profile = await this.appStorage.getProfile();
    const opts = {
      companyUuid: profile!.company.uuid,
      employeeUuid: userProfileUuid ?? profile!.uuid,
      requestedBy: profile!.uuid
    };
    const res = await this.oitchauApi.listTasks(opts);
    const projs = this.groupTasksByProject(res.content);
    const activeProjects = projs.filter((proj) => proj.status === "active");
    return activeProjects;
  }

  async listProjectsWithTasksAndClients(userProfileUuid?: string) {
    const profile = await this.appStorage.getProfile();
    const opts = {
      companyUuid: profile!.company.uuid,
      employeeUuid: userProfileUuid ?? profile!.uuid,
      requestedBy: profile!.uuid
    };
    return this.oitchauApi.listProjectsWithTasksForUser(opts);
  }

  async listTasks({ source, externalId }) {
    const profile = await this.appStorage.getProfile();
    const opts = {
      companyUuid: profile!.company.uuid,
      source,
      externalId,
    };
    const res = await this.oitchauApi.listTasks(opts);
    return res.content;
  }

  async createTask(task) {
    const profile = await this.appStorage.getProfile();
    const opts = {
      companyUuid: profile!.company.uuid,
      createdBy: profile!.uuid,
      projectUuid: task.projectUuid,
      name: task.name,
    };
    const res = await this.oitchauApi.createTask(opts);
    return res.content;
  }

  async createProject(project) {
    const profile = (await this.appStorage.getProfile())!;
    const opts = {
      companyUuid: profile!.company.uuid,
      body: {
        content: {
          id: "",
          clientUuid: null,
          ownerUuid: profile.uuid,
          createdBy: profile.uuid,
          locationUuids: [],
          ...project,
        },
      },
    };
    const res = await this.oitchauApi.createProject(opts);
    return res.content;
  }

  async updateProject(project) {
    const profile = (await this.appStorage.getProfile())!;
    const opts = {
      companyUuid: profile.company.uuid,
      projectUuid: project.uuid,
      body: {
        content: {
          clientUuid: project.clientUuid,
          customFieldsSettings: project.customFieldsSettings,
          estimatedCost: project.estimatedCost,
          estimatedHours: project.estimatedHours,
          id: project.id,
          isPublic: project.isPublic,
          locationUuids: project.locationUuids,
          name: project.name,
          ownerUuid: project.ownerUuid,
          restrictBasedOnSchedule: project.restrictBasedOnSchedule,
          updatedBy: profile.uuid,
        },
      },
    };
    const res = await this.oitchauApi.updateProject(opts);
    return res.content;
  }

  async getProject(opts) {
    const profile = (await this.appStorage.getProfile())!;
    const res = await this.oitchauApi.getProject({ ...opts, companyUuid: profile.company.uuid });
    return res.content;
  }

  async listProjects(opts = {}) {
    const profile = (await this.appStorage.getProfile())!;
    const res = await this.oitchauApi.listProjects({ ...opts, companyUuid: profile.company.uuid });
    return res.content;
  }

  async getCompanyRules() {
    const profile = (await this.appStorage.getProfile())!;
    const res = await this.oitchauApi.getCompanyRulesForProjects({ companyUuid: profile.company.uuid });
    return res.content;
  }

  async getTimesheet(opts: { from: string; to: string; userProfileUuid: string }) {
    const profile = (await this.appStorage.getProfile())!;
    const req = {
      ...opts,
      requestedBy: profile.uuid,
      companyUuid: profile.company.uuid,
    };
    const res = await this.oitchauApi.getTimesheet(req);
    return res.content;
  }

  async upsertTimeForTaskOnDate(opts) {
    const profile = (await this.appStorage.getProfile())!;
    const req = {
      userProfileUuid: opts.userProfileUuid,
      content: {
        ...opts.content,
        updatedBy: profile.uuid,
      },
      companyUuid: profile.company.uuid,
    };
    const res = await this.oitchauApi.upsertTimeForTaskOnDate(req);
    return res.content;
  }

  /**
   * Assume a timesheet cannot be longer than 1 month
   */
  async getUnavailableDates(params: GetUnavailableDatesRequest) {
    const { from, to, userProfileUuid } = params;
    const profile = (await this.appStorage.getProfile())!;
    const mFrom = moment(from, "YYYY-MM-DD");
    const mTo = to == null ? mFrom.clone().add(1, "month") : moment(to, "YYYY-MM-DD");
    const req: ListTimesheetsRequest = {
      userProfileUuid,
      companyUuid: profile.company.uuid,
      from: mFrom.clone().subtract(1, "month").format("YYYY-MM-DD"),
      to: mTo.clone().format("YYYY-MM-DD"),
      requestedBy: profile.uuid,
      approvalStatus: [TimesheetApprovalStatus.pending, TimesheetApprovalStatus.approved],
    };
    const res = await this.oitchauApi.listTimesheets(req);
    const tsRanges = res.content.map((ts) => [moment(ts.startDate, "YYYY-MM-DD"), moment(ts.endDate, "YYYY-MM-DD")]);

    const unavailableDates = [];
    const d = mFrom.clone();
    while (d.isSameOrBefore(mTo, "day")) {
      const isUnavail = tsRanges.some((range) => d.isBetween(range[0], range[1], "day", "[]"));
      if (isUnavail) {
        unavailableDates.push(d.format("YYYY-MM-DD"));
      }
      d.add(1, "day");
    }
    return unavailableDates;
  }

  validateActivity(act): ActivityValidationErrors | null {
    const errs: ActivityValidationErrors = {};
    const isTaskOk = act.taskUuid != null || act.status === "running";
    if (!isTaskOk) {
      errs.task = true;
    }
    for (const cf of act.customFields) {
      if (cf.required && cf.value == null) {
        errs.customFields = errs.customFields || {};
        errs.customFields[cf.uuid] = true;
      }
    }
    if (Object.keys(errs).length === 0) {
      return null;
    }
    return errs;
  }

  async getInjectedExternalAttributesSummary(projectExternalId: string, source: string, values: string[]) {
    const profile = (await this.appStorage.getProfile())!;
    return this.oitchauApi.getInjectedExternalAttributesSummary({
      companyUuid: profile.company.uuid,
      requestedBy: profile.uuid,
      source,
      values,
      projectExternalId,
    });
  }

  async getInjectedProjectSummary(source: string, externalId: string) {
    const profile = (await this.appStorage.getProfile())!;
    return this.oitchauApi.getInjectedProjectSummary({
      companyUuid: profile.company.uuid,
      requestedBy: profile.uuid,
      source,
      externalId,
    });
  }

  async getInjectedTaskSummary({ name, source }: { name: string; source: string }) {
    const profile = (await this.appStorage.getProfile())!;
    const req = {
      companyUuid: profile.company.uuid,
      requestedBy: profile.uuid,
      name,
      source,
    };
    const res = await this.oitchauApi.getInjectedTaskSummary(req);
    return res;
  }

  checkIfSameInjectedTask(activity: ActivityRes, injectedTaskData: InjectedTaskData | null): boolean {
    if (!activity || !injectedTaskData) return false;
    let isSameSource = !!injectedTaskData.taskSource && injectedTaskData.taskSource === activity.task?.source;
    if (injectedTaskData.taskId && activity.task?.externalId) {
      isSameSource = isSameSource && injectedTaskData.taskId === activity.task.externalId;
    } else {
      isSameSource = isSameSource && injectedTaskData.taskName === activity.task?.name;
    }
    const isSameInjected =
      !!injectedTaskData.injectedTaskName && injectedTaskData.taskName === activity.injectedTaskName;
    return isSameSource || isSameInjected;
  }
}
