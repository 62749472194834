import { stylesheet } from "astroturf";
import { ProfileSelectorItem, ProfileSelectorItemProps } from "../../../views/ProfileSelectorItem";
import * as images from "../../../svg-images";

const styles = stylesheet`
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .Companies {
      display: flex;
      flex-direction: column;
      width: 100%;

      .Company {
        height: 80px;
        flex-shrink: 0;
        padding-inline-start: 14px;
        padding-inline-end: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background-color: var(--colors-surface-50);
        }
      }
    }
  }
`;

export interface ProfileSelectorProps extends React.HTMLProps<HTMLDivElement> {
  companies: (ProfileSelectorItemProps & { id: string })[];
  selectedCompanyId: string;
  onCompanySelected: (company: ProfileSelectorItemProps & { id: string }) => void;
}

export function ProfileSelector(props: ProfileSelectorProps) {
  const { companies, selectedCompanyId, onCompanySelected, ...restProps } = props;
  return (
    <div className={styles.Wrapper} {...restProps}>
      <div className={styles.Companies}>
        {companies.map((comp) => (
          <div className={styles.Company} key={comp.id} onClick={() => onCompanySelected(comp)}>
            <ProfileSelectorItem {...comp} />
            {comp.id === selectedCompanyId && images.companySwitchSelected}
          </div>
        ))}
      </div>
    </div>
  );
}
