import ConfirmationModal from "components/UI/ConfirmationModalNew";
import { Notification } from "types/common";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useState } from "react";
import styled from "styled-components";
import { TimesheetSettings } from "../pages";

const Wrapper = styled.div`
  .timesheet-settings {
    max-width: inherit;
    margin-top: 0;
    padding: 24px 0;

    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

interface TimesheetSettingsModalProps {
  onClose: () => void;
  setNotification: (n: Notification) => void;
}

const TimesheetSettingsModal = ({ setNotification, onClose }: TimesheetSettingsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const [yesFn, setYesFn] = useState<{ fn: (() => Promise<void>) | null }>({ fn: null });

  const onYes = async () => {
    if (yesFn.fn) {
      await yesFn.fn();
    }

    onClose();
  };

  return (
    <ConfirmationModal
      isOpen
      title={t("Tracking Settings")}
      onClose={onClose}
      onYes={yesFn.fn ? onYes : undefined}
      btnYesTitle={t(`${TranslationNamespaces.common}|Save`)}
      btnCancelTitle={yesFn.fn ? t(`${TranslationNamespaces.common}|Cancel`) : ""}
      width={484}
    >
      <Wrapper>
        <TimesheetSettings
          setOnSaveFn={setYesFn}
          setNotification={(n) =>
            setNotification({ notification: n.notification, notificationType: n.notificationType })
          }
        />
      </Wrapper>
    </ConfirmationModal>
  );
};

export default TimesheetSettingsModal;
