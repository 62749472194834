import { stylesheet } from "astroturf";
import cx from "classnames";
import SafeTimeInput from "components/views/SafeTimeInput";
import { useTranslation, TranslationNamespaces, TFunction } from "types/translationNamespaces";
import { IconFieldError, IconBack, IconClose, IconStopActivity } from "../styled";

export const styles = stylesheet`
.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
  color: var(--colors-surface-0);
  background: var(--colors-text1);
  fill: var(--colors-surface-0);

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IconBack {
      display: flex;
      align-items: center;
      width: 64px;
      height: 64px;
      padding-inline-start: 24px;
      cursor: pointer;

      > svg {
        fill: var(--colors-surface-0);
      }

      &.noBack {
        cursor: default;
        visibility: hidden;
      }
    }

    .IconStop {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
  }

  .DurationContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 52px;

    .DurationTexts {
      display: flex;
      height: 52px;
      font-size: 42px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      direction: ltr;

      input {
        width: 180px;
        height: 52px;
        padding: 0;
        font: inherit;
        color: inherit;
        text-align: center;
        background: none;
        border: 1px solid transparent;
        border-radius: 3px;
        outline: none;

        &:hover {
          background: var(--colors-surface-700);
        }

        &:focus {
          background: var(--colors-surface-700);
          border: 1px solid var(--colors-surface-400);
        }
      }

      .Hours {
        font: inherit;
      }

      .Seconds {
        width: 1.5em; /* avoid seconds width jumping */
        color: var(--colors-unknown27);
      }
    }
  }

  .TimesRow {
    display: flex;
    justify-content: center;
    width: 100%;

    .Times {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-around;
      width: 300px;
      height: 28px;
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: 15px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;

      .TimeContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        --icon-width: 15px;

        /* stylelint-disable-next-line no-descending-specificity */
        input {
          height: 28px;
          padding: 0;
          font: inherit;
          color: inherit;
          text-align: center;
          background: none;
          border: 1px solid transparent;
          border-radius: 3px;
          outline: none;

          &:focus {
            background: var(--colors-surface-700);
            border: 1px solid var(--colors-surface-400);
          }

          &:hover:not(:disabled) {
            background: var(--colors-surface-700);
          }
        }

        &.withHint:not(:focus-within) {
          margin-inline-end: -20px; /** -calc(var(--icon-width) + 5px) produces different result for some reason */

          input {
            padding-inline-end: var(--icon-width);
          }

          .Hint {
            display: flex;
          }

          &:hover {
            .HintText {
              display: block;
            }
          }
        }

        .Hint {
          display: none;

          .HintIcon {
            position: relative;
            inset-inline-start: calc(-100% - 5px);
            width: var(--icon-width);
            height: var(--icon-width);
            fill: var(--colors-surface-0);
            pointer-events: none;
          }

          .HintText {
            display: none;
            position: absolute;
            top: calc(100% + 6px);
            inset-inline-end: calc(5px);

            background: #2F3648;
            border: 1px solid #272D3D;
            border-radius: var(--shapes-border-radius-default);
            color: var(--colors-surface-0);
            font-weight: var(--typography-font-weight-default);
            font-size: 12px;
            line-height: 120%;
            padding: 8px 12px;
          }
        }
      }

      .Date {
        padding: 6.5px 8px;
        cursor: pointer;
        border-radius: 3px;

        &:hover {
          box-sizing: content-box;
          background: var(--colors-surface-700);
        }

        &:active {
          border: 1px solid var(--colors-surface-400);
        }
      }
    }
  }

  &.isRunning {
    color: var(--colors-surface-0);
    background: linear-gradient(259.37deg, #1E97F7 18.86%, #0075D2 93.03%);

    .TimesRow {
      .Times {
        /* stylelint-disable-next-line no-descending-specificity */
        input {
          &:focus {
            background: var(--colors-primary-500-p);
            border: 1px solid #78C1FA;
          }

          &:hover:not(:disabled) {
            background: var(--colors-primary-500-p);
          }
        }
      }
    }
  }

  &.noEdit,&.isRunning {
    .TimesRow {
      .Times {
        .Date {
          cursor: default;
          background: none;

          &:hover,&:active {
            background: none;
            border: none;
          }
        }
      }
    }
  }

  &.loading {
    .IconStop {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
}
`;

function getStopReasonHint(stopReason: string, t: TFunction) {
  if (stopReason === "break_start") {
    return t("End adjusted based on time of your break start");
  }
  if (stopReason === "exit") {
    return t("End adjusted based on time of your exit");
  }
  return t("End adjusted based on schedule");
}

export type ActivityDurationProps = Omit<React.HTMLProps<HTMLDivElement>, "duration" | "onDurationChange"> & {
  date: string;
  onDateClick(): void;
  startTime: string;
  endTime?: string;
  duration: string;
  onStartTimeChange(start: string): void;
  onEndTimeChange(end: string): void;
  onDurationChange(duration: string): void;
  durationSeconds: string;
  isRunning: boolean;
  collapsed?: boolean;
  onStopClick(): void;
  onBackClick?(): void;
  stopReason: string | null;
  edit?: boolean;
  isNew?: boolean;
  loading?: boolean;
};

export function ActivityDuration(props: ActivityDurationProps) {
  const {
    date,
    onDateClick,
    startTime,
    endTime,
    duration,
    durationSeconds,
    onStartTimeChange,
    onEndTimeChange,
    onDurationChange,
    isRunning,
    collapsed,
    onStopClick,
    onBackClick,
    stopReason,
    edit,
    isNew,
    loading,
    ...restProps
  } = props;

  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const stopReasonHint = getStopReasonHint(stopReason, t);

  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.isRunning]: isRunning,
        [styles.collapsed]: collapsed,
        [styles.noEdit]: !edit,
        [styles.loading]: loading,
      })}
      {...restProps}
    >
      <div className={styles.Header}>
        <div className={cx(styles.IconBack, { [styles.noBack]: onBackClick == null })} onClick={onBackClick}>
          {isNew ? <IconClose /> : <IconBack width={12} height={12} />}
        </div>
        {isRunning && (
          <div className={styles.IconStop} onClick={loading ? () => {} : onStopClick}>
            <IconStopActivity />
          </div>
        )}
      </div>
      <div className={styles.DurationContainer}>
        <div className={styles.DurationTexts}>
          {isRunning ? (
            <>
              <div className={styles.Hours}>{duration}</div>
              <div className={styles.Seconds}>{durationSeconds}</div>
            </>
          ) : (
            <SafeTimeInput
              disabled={!edit || isRunning}
              className={styles.Hours}
              value={duration}
              size={5}
              onChangeFinished={onDurationChange}
            />
          )}
        </div>
      </div>

      <div className={styles.TimesRow}>
        <div className={styles.Times}>
          <div className={styles.Date} onClick={!edit || isRunning ? undefined : onDateClick}>
            {date}
          </div>
          <div className={styles.TimeContainer}>
            <SafeTimeInput disabled={!edit} value={startTime} onChangeFinished={onStartTimeChange} size={5} />
          </div>
          {" — "}
          <div className={cx(styles.TimeContainer, { [styles.withHint]: stopReason != null })}>
            <SafeTimeInput
              disabled={!edit || isRunning}
              value={isRunning ? "--:--" : endTime}
              onChangeFinished={onEndTimeChange}
              size={5}
            />
            <div className={styles.Hint}>
              <IconFieldError className={styles.HintIcon} />
              <div className={styles.HintText}>{stopReasonHint}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
