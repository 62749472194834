import { stylesheet } from "astroturf";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment-timezone";
import { useProfile } from "context/user-profile-context";
import { useCallback, useEffect, useState } from "react";
import { DayPickerSingleDateController } from "react-dates";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import cx from "classnames";
import { dowDict } from "utils/get-week-start";
import {
  DatePickerCommonOverrides,
  CalendarInsideNext,
  CelandarInsidePrev,
} from "components/controls/DatePicker/styled";
import { IconCloseDialog } from "../styled";

const styles = stylesheet`
.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: var(--colors-surface-0);

  :global(.DayPickerNavigation_button__horizontalDefault) {
    top: 0;
  }

  :global(.CalendarDay__default) {
    color: var(--colors-surface-900-p);
    border-radius: 999px;
  }

  :global(.CalendarDay__blocked_out_of_range) {
    color: var(--colors-surface-200);
  }

  :global(.CalendarDay__selected) {
    font-weight: var(--typography-font-weight-bold);
    color: var(--colors-surface-0);
    border-radius: 999px;
  }

  :global(.CalendarMonth_caption) {
    strong {
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-bold);
      line-height: 100%;
      color: var(--colors-surface-800);
    }
  }

  :global(.DayPicker_weekHeader) {
    top: 70px;
  }

  :global(.DayPicker_weekHeader_li) {
    font-size: 12px;
    font-weight: var(--typography-font-weight-bold);
    line-height: 100%;
    color: var(--colors-surface-700);
  }

  :global(.CalendarMonth_table) {
    margin-top: 20px;
  }

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--colors-surface-150);

    .Title {
      padding-inline-start: 16px;
      font-size: 15px;
      font-weight: var(--typography-font-weight-medium);
      line-height: 100%;
      color: var(--colors-surface-900-p);
    }

    .IconClose {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      cursor: pointer;

      svg {
        fill: var(--colors-surface-500);
      }
    }
  }

  .DayPicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;

    &.loading {
      cursor: wait;

      > * {
        pointer-events: none;
      }
    }
  }
}
`;

export interface DateSelectorProps {
  date: string;
  isOutsideRange?(date: string): boolean;
  getUnavailableDates?(month: string): Promise<string[]>;
  onChange: (date: string) => void;
  onClose: () => void;
}

export function DateSelector(props: DateSelectorProps) {
  const {
    date: dateProp,
    isOutsideRange: isOutsideRangeProp,
    getUnavailableDates = () => [],
    onChange,
    onClose,
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const profile = useProfile();

  const [date, setDate] = useState<moment.Moment>(moment(dateProp, "YYYY-MM-DD"));
  useEffect(() => {
    setDate(moment(dateProp, "YYYY-MM-DD"));
  }, [dateProp]);
  const handleChange = (value: moment.Moment | null) => {
    const d = value && moment(value).isValid() ? moment(value).clone() : moment().clone();
    onChange && onChange(d.format("YYYY-MM-DD"));
  };

  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const changeMonth = useCallback(
    async (m: moment.Moment) => {
      setLoading(true);
      const month = m.format("YYYY-MM");
      const unavailDates = await getUnavailableDates(month);
      setUnavailableDates(unavailDates);
      setLoading(false);
    },
    [getUnavailableDates],
  );
  useEffect(() => void changeMonth(date), [changeMonth, date]);
  const isOutsideRange = useCallback(
    (m: moment.Moment) => {
      if (loading) return true;
      const d = m.format("YYYY-MM-DD");
      const isOutside = isOutsideRangeProp ? isOutsideRangeProp(d) : false;
      if (isOutside) return true;
      const isUnavail = unavailableDates.includes(d);
      return isUnavail;
    },
    [unavailableDates, isOutsideRangeProp, loading],
  );

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <div className={styles.Title}>{t("Select start date")}</div>
        <div className={styles.IconClose} onClick={onClose}>
          <IconCloseDialog />
        </div>
      </div>
      <DatePickerCommonOverrides className={cx(styles.DayPicker, { [styles.loading]: loading })}>
        <DayPickerSingleDateController
          firstDayOfWeek={dowDict[profile.company.timesheet_start_day]}
          isOutsideRange={isOutsideRange}
          date={date}
          navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
          navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
          onNextMonthClick={changeMonth}
          onPrevMonthClick={changeMonth}
          noBorder
          hideKeyboardShortcutsPanel
          daySize={42}
          focused
          initialVisibleMonth={() => date!}
          weekDayFormat="ddd"
          monthFormat="MMMM YYYY"
          onDateChange={handleChange}
          onFocusChange={() => {}}
          isRTL={document.dir === "rtl"}
        />
      </DatePickerCommonOverrides>
    </div>
  );
}
