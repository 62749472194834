/* eslint-disable no-plusplus */
import { Component, ContextType } from "react";
import BEM from "utils/BEM";
import { WithTranslation, withTranslation } from "react-i18next";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { getReport, fireDownloadReport, getCompanyRules } from "utils/apiHelpers";
import { minsToHrsMins, hasPermisionAccess, PermissionSectionName } from "utils/common";
import * as momentTz from "moment-timezone";
import styled from "styled-components";
import Tooltip from "components/UI/Tooltip";
import moment from "moment";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getDateColumn, getDateCellData } from "utils/reportsHelpers";
import { SetIsLoading, ReportType } from "types/reports";
import { APIResponse } from "utils/api/common";
import { SearchObject } from "types/common";
import { ColumnAlign, iColumn } from "components/TableCommon";
import { ReportStatus } from "types/models/reports/summary";
import { PunchType } from "types/models/punches";
import GlobalContext from "context/global-context";
import { RequestTypeName } from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  ReportDetailed,
  ReportDetailedContent,
  ReportDetailedDayStatus,
  ReportDetailedMetadata,
  ReportDetailedReportStatus,
  ReportsDetailedTableData,
} from "types/models/reports/detailed(payroll)";
import { CompanyRuleNames } from "types/models/companyRules";
import { ReportBreakType } from "types/models/reports/common";
import { getCustomBreaksList } from "utils/api/schedule";
import ReportUserInfo from "components/Reports/ReportUserInfo";
import TablePage from "components/TablePage";
import * as images from "components/svg-images";
import ReportsDetailedServicesSummary from "components/Reports/ReportsDetailedServicesSummary";
import superPunchIcon from "components/Reports/images/super-punch-logo.svg";
import "styles/reports-detailed-table.scss";
import { Break, BreakStatusOptions } from "utils/api/types";
import { DEFAULT_BREAK_KEY, getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import { getBreakPairs } from "components/Schedules/helpers";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import { PhasesType, getPhasesColumnsForSingleReport } from "./helpers";

const tc = BEM.b("table-common");

type Date = {
  status: string;
};

interface ReportsDetailedTableProps extends RouteComponentProps, WithTranslation, AllFlagsLDClient {
  startDate: moment.Moment;
  endDate: moment.Moment;
  showServicesSummary: boolean;
  setIsLoading: SetIsLoading;
  searchObj: SearchObject | null;
  updateRecalculatingMessage: (attr: boolean) => void;
}

interface ReportsDetailedTablePropsState extends ReportDetailedContent, Partial<ReportDetailedMetadata> {
  isFetching: boolean;
  error: string | null;
  selectedColumns: string;
  servicesSummary: unknown | null; // TODO
  employeeInfo: ReportDetailedContent["employeeInfo"] | null;
  isNightReducedHoursColumnAllowed: boolean;
  customBreaks: Break[];
  availableBreakUuids: string[];
}

const RequestsCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SuperPunchButton = styled(Link)`
  background: var(--colors-superPunchBackground);
  color: var(--colors-superPunchColor);
  line-height: 14px;
  margin: 0;
  padding: 0 15px 0 50px;
  height: 36px;
  position: relative;
  font-size: var(--typography-font-size-default);
  border-radius: var(--shapes-border-radius-default);
  border: none;
  cursor: pointer;
  margin-inline-end: 10px;
  display: flex;
  align-items: center;
  &:before {
    background-image: url(${superPunchIcon});
    background-repeat: no-repeat;
    content: "";
    width: 26px;
    height: 17px;
    background-position: center;
    position: absolute;
    left: 15px;
  }
`;

class ReportsDetailedTable extends Component<ReportsDetailedTableProps, ReportsDetailedTablePropsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<ReportsDetailedTablePropsState> = {
    error: null,
    activeNightShifts: [],
    isFetching: false,
    shiftEventKeys: [],
    plannedDayBreakByTypes: [],
    totalWorkedMinutes: 0,
    totalPlannedMinutes: 0,
    totalExtraHoursMinutes: 0,
    totalMissedMinutes: 0,
    totalEarlyLeaveMinutes: 0,
    totalLateEntryMinutes: 0,
    totalCrossShiftsIntervalDiffMinutes: 0,
    totalNightShiftMinutes: {},
    totalNightReducedMinutes: 0,
    totalDebitMinutes: 0,
    totalUnusedBreakMinutes: 0,
    totalOnCallMinutes: {
      activated: 0,
      onCall: 0,
      reducingActivated: 0,
    },
    selectedColumns: getSelectedColumns(
      "date,punches,worked_hours,break_hours,night_hours,holiday,observation",
      "ReportsDetailedTable",
    ),
    employeeInfo: null,
    dates: [],
    servicesSummary: null,
    isNightReducedHoursColumnAllowed: false,
    customBreaks: [],
    availableBreakUuids: [],
  };

  async componentDidMount(): Promise<void> {
    const company = await this.context.getCompany();
    window.global_store.company = company;

    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );
    this.setState({ isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value });
    void this.getReportData();
  }

  getReportData = async (): Promise<void> => {
    const { searchObj, startDate, endDate, updateRecalculatingMessage, setIsLoading, showServicesSummary } = this.props;
    const companyUUID = window.global_store.company?.uuid;

    if (searchObj && startDate && endDate && companyUUID) {
      this.setState({ isFetching: true }, () => setIsLoading(true));

      try {
        const [response, customBreaksResponse] = await Promise.all([
          (await getReport(
            {
              UserProfileUUID: searchObj?.employee?.uuid,
              startDate,
              endDate,
              companyUUID,
              type: ReportType.detailed,
              showServicesSummary,
            },
            true,
          )) as ReportDetailed,
          await getCustomBreaksList({
            perPage: 300,
            page: 1,
            statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
            companyUuid: companyUUID,
            requestedBy: window.global_store.profile.uuid,
          }),
        ]);
        const resp = response.content;
        const availableBreakUuidsSet = new Set();

        if (resp.dates) {
          const defaultBreakUuid = customBreaksResponse.content.find((brk) => brk.default)!.uuid;
          const allDaysCount = resp.dates.length || 0;
          const completedDaysCount = resp.dates.filter(
            (d: Date) => d.status === ReportDetailedDayStatus.completed,
          ).length;
          resp.plannedDayBreakByTypes.forEach((brk) => {
            availableBreakUuidsSet.add(brk.breakTypeUuid || defaultBreakUuid);
          });
          resp.plannedNightBreakByTypes.forEach((brk) => {
            availableBreakUuidsSet.add(brk.breakTypeUuid || defaultBreakUuid);
          });
          if (!resp.dates.length) {
            updateRecalculatingMessage(false);
          } else {
            updateRecalculatingMessage(
              allDaysCount !== completedDaysCount || response?.metadata?.status === ReportDetailedReportStatus.pending,
            );
          }
        }

        this.setState({
          ...resp,
          shiftEventKeys: response?.metadata ? response.metadata.shiftEventKeys : [],
          activeNightShifts: response.metadata.activeNightShifts || [],
          servicesSummary: response?.metadata?.servicesSummary || null,
          activePhases: response.metadata.activePhases,
          crossShiftsIntervalPhases: response.metadata.crossShiftsIntervalPhases,
          hoursDistribution: response.metadata.hoursDistribution,
          customBreaks: customBreaksResponse.content,
          availableBreakUuids: Array.from(availableBreakUuidsSet),
          isFetching: false,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Error", err);

        this.setState({
          error: this.props.t("Failed to generate report"),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  getPunchesColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const { flags } = this.props;
    const { shiftEventKeys } = this.state;

    if (
      shiftEventKeys &&
      shiftEventKeys?.filter((ev) => ev.indexOf("break_start") === 0)?.length > 1 &&
      flags?.combinedBreaksOnDetailedReport
    ) {
      return [
        this.getFirstEntryColumn(),
        ...this.getBreaksColumns(),
        ...this.getPunchesWithoutFirsEntryEndBreaksColumns(),
      ];
    }

    if (shiftEventKeys?.length === 0) {
      return [];
    }

    return this.getDefaultPunchesColumns();
  };

  getDefaultPunchesColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const { t } = this.props;
    const { shiftEventKeys = [] } = this.state;
    const defaultKeys = Object.values(PunchType) as string[];
    const punchesColumns: iColumn<ReportsDetailedTableData>[] = [];

    shiftEventKeys.forEach((key) => {
      const newKey: string = defaultKeys.indexOf(key) > -1 ? `${key}0` : key;

      punchesColumns.push({
        accessor: newKey,
        rubyAccessor: "punches",
        groupLabel: t("Punches"),
        label: `${t(newKey.substring(0, newKey.length - 1))} ${newKey.slice(-1) === "0" ? "" : newKey.slice(-1)}`,
        locked: true,
        minWidth: 100,
        align: "end" as ColumnAlign,
      });
    });

    return punchesColumns;
  };

  getBreaksColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const { availableBreakUuids, customBreaks } = this.state;
    const { t } = this.props;
    const punchesColumns: iColumn<ReportsDetailedTableData>[] = [];
    const breakNamesMap = getBreaksNamesMap(customBreaks);
    const availableBreakUuidsSortedByName = availableBreakUuids.sort((a, b) => {
      const nameA = breakNamesMap[a].toLowerCase();
      const nameB = breakNamesMap[b].toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    availableBreakUuidsSortedByName.forEach((breakTypeUuid) => {
      punchesColumns.push({
        accessor: "punches",
        rubyAccessor: "punches",
        groupLabel: t("Punches"),
        label: breakNamesMap[breakTypeUuid],
        locked: true,
        minWidth: 130,
        align: "center" as ColumnAlign,
        Cell: (cellInfo: iCellInfo<ReportsDetailedTableData, ReportsDetailedTableData["punches"]>) => {
          const currentBreaksPunches = cellInfo.value.filter((punch) => punch.breakTypeUuid === breakTypeUuid);
          const breakPairs = getBreakPairs(currentBreaksPunches);
          if (!breakPairs.length) {
            return null;
          }

          return (
            <div>
              {breakPairs.map(([breakStart, breakEnd]) => (
                <div
                  key={breakStart?.key || breakEnd?.key}
                  style={{ display: "flex", gap: "8px", justifyContent: "center" }}
                >
                  <div style={{ minWidth: "40px" }}>
                    {breakStart?.time ? momentTz.tz(breakStart.time, breakStart.timezone).format("HH:mm") : ""}
                  </div>
                  <div>-</div>
                  <div style={{ minWidth: "40px" }}>
                    {breakEnd?.time ? momentTz.tz(breakEnd.time, breakEnd.timezone).format("HH:mm") : ""}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      });
    });

    return punchesColumns;
  };

  getPunchesWithoutFirsEntryEndBreaksColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const { shiftEventKeys = [] } = this.state;
    const { t } = this.props;
    const punchesColumns: iColumn<ReportsDetailedTableData>[] = [];

    shiftEventKeys
      .filter((key) => key !== `${PunchType.entry}0` && !key.includes("break"))
      .forEach((key) => {
        punchesColumns.push({
          accessor: key,
          rubyAccessor: "punches",
          groupLabel: t("Punches"),
          label: `${t(key.substring(0, key.length - 1))} ${key.slice(-1) === "0" ? "" : key.slice(-1)}`,
          locked: true,
          minWidth: 100,
          align: "end" as ColumnAlign,
        });
      });

    return punchesColumns;
  };

  getFirstEntryColumn = (): iColumn<ReportsDetailedTableData> => {
    const { t } = this.props;

    return {
      accessor: `${PunchType.entry}0`,
      rubyAccessor: "punches",
      groupLabel: t("Punches"),
      label: t(PunchType.entry),
      locked: true,
      minWidth: 100,
      align: "end" as ColumnAlign,
    };
  };

  getBreaksTotalColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const {
      plannedDayBreakByTypes = [],
      plannedNightBreakByTypes = [],
      actualDayBreaksByTypes = [],
      actualNightBreaksByTypes = [],
      customBreaks,
    } = this.state;
    const defaultBreakUuid = customBreaks.find((brk) => brk.default)?.uuid;

    const cumulativeTotalActualBreaksByTypes = [...actualDayBreaksByTypes, ...actualNightBreaksByTypes];

    const { t } = this.props;
    const breaksColumns: iColumn<ReportsDetailedTableData>[] = [];

    const breakNamesMap = getBreaksNamesMap(customBreaks);
    // get only unique break uuids
    const totalPlannedDayBreakByTypes = plannedDayBreakByTypes.map((brk) => brk.breakTypeUuid || defaultBreakUuid);
    const totalPlannedNightBreakByTypes = plannedNightBreakByTypes.map((brk) => brk.breakTypeUuid || defaultBreakUuid);

    const cumulativePlannedBreaksByTypes = [
      ...new Set([...totalPlannedDayBreakByTypes, ...totalPlannedNightBreakByTypes]),
    ];

    cumulativePlannedBreaksByTypes.forEach((breakTypeUuid) => {
      const breaksTotalByBreakType: number = cumulativeTotalActualBreaksByTypes.reduce(
        (acc: number, brk: ReportBreakType) => {
          const brkUuuid = brk.breakTypeUuid || defaultBreakUuid;
          if (brkUuuid === breakTypeUuid) {
            return acc + brk.minutes;
          }
          return acc;
        },
        0,
      );

      breaksColumns.push({
        accessor: "actualDayBreaksByTypes",
        rubyAccessor: "break_hours",
        groupLabel: t("Break Types"),
        label: breakNamesMap[breakTypeUuid] || breakNamesMap[DEFAULT_BREAK_KEY],
        Cell: (cellInfo) => {
          const dayBreaks: ReportBreakType[] = cellInfo.value || [];
          const nightBreaks: ReportBreakType[] = cellInfo.original.actualNightBreaksByTypes || [];
          const allBreaks = [...dayBreaks, ...nightBreaks];
          const currentBreakMinutes: number = allBreaks.reduce((acc: number, brk: ReportBreakType) => {
            const brkUuuid = brk.breakTypeUuid || defaultBreakUuid;
            if (brkUuuid === breakTypeUuid) {
              return acc + brk.minutes;
            }
            return acc;
          }, 0);

          return currentBreakMinutes ? minsToHrsMins(currentBreakMinutes) : "00:00";
        },
        Footer: minsToHrsMins(breaksTotalByBreakType),
        minWidth: 100,
        align: "end" as ColumnAlign,
      });
    });

    return breaksColumns;
  };

  getRequestTypeTranslated = (requestType: string, t: WithTranslation["t"]): string => {
    const isPredefinedRequestType = (Object.values(RequestTypeName) as string[]).indexOf(requestType) > -1;

    if (isPredefinedRequestType) {
      return t(`${TranslationNamespaces.requestsPageTmp}|${requestType}`);
    }
    return requestType;
  };

  getObservations = (row: iCellInfo<ReportsDetailedTableData>): JSX.Element | string | null => {
    const { t } = this.props;

    if (row.original.requests?.length) {
      const requestsStr = row.original.requests
        .filter((r) => r.requestType !== RequestTypeName.overtime) // PROD-11225
        .map(
          (r) =>
            `${this.getRequestTypeTranslated(r.type, t)}/${
              r.requestSubtypeTranslationKey
                ? t(`${TranslationNamespaces.requestsPageTmp}|${r.requestSubtypeName}`)
                : r.requestSubtypeName
            }`,
        )
        .join(", ");

      return (
        <RequestsCell>
          <Tooltip id={`requests-tooltip-${row.original.date.dayMonth}`}>{requestsStr}</Tooltip>
          <span data-tip data-for={`requests-tooltip-${row.original.date.dayMonth}`}>
            {requestsStr}
          </span>
        </RequestsCell>
      );
    }

    if (row.value) {
      return row.value;
    }

    return row.original.missedDay ? t("Missing") : null;
  };

  getColumns = (): iColumn<ReportsDetailedTableData>[] => {
    const { t } = this.props;
    const {
      activeNightShifts = [],
      totalWorkedMinutes = 0,
      totalPlannedMinutes = 0,
      totalExtraHoursMinutes = 0,
      totalMissedMinutes = 0,
      totalEarlyLeaveMinutes = 0,
      totalLateEntryMinutes = 0,
      totalCrossShiftsIntervalDiffMinutes = 0,
      totalCrossShiftsIntervalDiffMinutesPhases = {},
      totalNightShiftMinutes = {},
      totalNightReducedMinutes = 0,
      totalOnCallMinutes = {
        activated: 0,
        onCall: 0,
        reducingActivated: 0,
      },
      totalDebitMinutes = 0,
      totalUnusedBreakMinutes = 0,
      activePhases,
      crossShiftsIntervalPhases,
      totalHoursDistribution,
      totalExtraHoursPhases = {},
      totalHoursBankPhases = {},
      hoursDistribution,
    } = this.state;

    const punchesColumns = this.getPunchesColumns();
    const breaksColumns = this.getBreaksTotalColumns();

    const nightShiftColumns = activeNightShifts.length
      ? activeNightShifts.map((ns) => ({
          accessor: `nightShiftMinutes${ns.name}`,
          rubyAccessor: "night_hours",
          groupLabel: t("nightShiftMinutes"),
          label: `${t("ns-col")} ${ns.name}%`,
          Footer: minsToHrsMins(totalNightShiftMinutes[ns.name] || 0),
          style: { fontWeight: "500" },
          Cell: (row: iCellInfo<ReportsDetailedTableData>): string => {
            const hours = row.original.nightShiftMinutes[ns.name] || 0;
            return minsToHrsMins(hours);
          },
          align: "end" as ColumnAlign,
          minWidth: 100,
        }))
      : [];

    const crossShiftsPhasesColumns = crossShiftsIntervalPhases?.length
      ? crossShiftsIntervalPhases.map((ns) => ({
          accessor: `crossShiftsIntervalPhases${ns.uuid}`,
          rubyAccessor: "cross_shifts_interval_phases",
          groupLabel: t("crossShiftsIntervalPhases"),
          label: t("cross-shifts-col", { name: ns.name }),
          Footer: minsToHrsMins(totalCrossShiftsIntervalDiffMinutesPhases[ns.uuid] || 0),
          style: { fontWeight: "500" },
          Cell: (row: iCellInfo<ReportsDetailedTableData>): string => {
            const hours = row.original.crossShiftsIntervalPhases[ns.uuid] || 0;
            return minsToHrsMins(hours);
          },
          align: "end" as ColumnAlign,
          minWidth: 100,
        }))
      : [];

    const hoursDistributionColumns = hoursDistribution?.length
      ? hoursDistribution.map((hd) => ({
          accessor: `hoursDistribution${hd.uuid}`,
          rubyAccessor: "hours_distribution",
          groupLabel: t("hours_distribution"),
          label: t("hours_distribution-col", { name: hd.name, interpolation: { escapeValue: false } }),
          Footer: minsToHrsMins(totalHoursDistribution[hd.uuid].minutes || 0),
          style: { fontWeight: "500" },
          Cell: (row: iCellInfo<ReportsDetailedTableData>): string => {
            const hours = row.original.hoursDistribution.find((el) => el.uuid === hd.uuid)?.minutes || 0;
            return minsToHrsMins(hours);
          },
          align: "end" as ColumnAlign,
          minWidth: 100,
        }))
      : [];

    const nightReducedHoursColumn = this.state.isNightReducedHoursColumnAllowed
      ? [
          {
            accessor: "nightReducedMinutes",
            rubyAccessor: "night_reduced_hours",
            label: t("ns-col-reduced-hours"),
            minWidth: 130,
            style: { fontWeight: "500" },
            Footer: minsToHrsMins(totalNightReducedMinutes),
            align: "end",
          },
        ]
      : [];

    const ehPhasesColumns = activePhases
      ? getPhasesColumnsForSingleReport(activePhases, t, totalExtraHoursPhases, PhasesType.extraHours)
      : [];

    const hbPhasesColumns = activePhases
      ? getPhasesColumnsForSingleReport(activePhases, t, totalHoursBankPhases, PhasesType.hoursBank)
      : [];

    // if you are adding new columns - add rubyAccessor to ReportsDownloadAllDialog.getColumnsSelectorOptions
    const columns = [
      getDateColumn(t),
      {
        accessor: "schedule",
        rubyAccessor: "schedule",
        label: t("Schedule"),
        Cell: (r: iCellInfo<ReportsDetailedTableData>): JSX.Element =>
          r.original.scheduleException ? (
            <span className={tc("holiday")}>
              {t("Exception")}
              <div className="hint" style={{ top: "-4px" }}>
                {["ApprovedOvertimeRequest", "enableDay", "ApprovedOvertimeNonWorkingDayRequest"].includes(
                  r.original.scheduleException,
                )
                  ? t(r.original.scheduleException)
                  : r.original.scheduleException}
              </div>
            </span>
          ) : (
            <span title={r.value}>{r.value}</span>
          ),
        minWidth: 100,
      },
      {
        accessor: "businessRulesGroup",
        rubyAccessor: "business_rules_group",
        label: t("Business Rules Group"),
        Cell: (r: iCellInfo<ReportsDetailedTableData>): JSX.Element => <span title={r.value}>{r.value}</span>,
        minWidth: 100,
      },
      ...punchesColumns,
      {
        accessor: "plannedMinutes",
        rubyAccessor: "planned_hours",
        label: t("Planned Hours"),
        Footer: minsToHrsMins(totalPlannedMinutes),
        style: { fontWeight: "500" },
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        label: t("Worked Hours"),
        Footer: minsToHrsMins(totalWorkedMinutes),
        minWidth: 100,
        style: { fontWeight: "500" },
        align: "end" as ColumnAlign,
      },
      ...breaksColumns,
      ...nightShiftColumns,
      ...nightReducedHoursColumn,
      ...hoursDistributionColumns,
      {
        accessor: "lateEntryMinutes",
        rubyAccessor: "late_entry_hours",
        Footer: minsToHrsMins(totalLateEntryMinutes),
        style: { fontWeight: "500" },
        label: t("Late Arrival"),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "earlyLeaveMinutes",
        rubyAccessor: "early_leave_hours",
        Footer: minsToHrsMins(totalEarlyLeaveMinutes),
        style: { fontWeight: "500" },
        label: t("Late Leave"),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      ...ehPhasesColumns,
      {
        accessor: "extraHoursMinutes",
        rubyAccessor: "extra_hours",
        label: t("Extra Hours"),
        Footer: minsToHrsMins(totalExtraHoursMinutes),
        style: { fontWeight: "500" },
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      ...hbPhasesColumns,
      {
        accessor: "hoursBankMinutes",
        rubyAccessor: "hours_bank",
        label: t("Hours Bank"),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "missedMinutes",
        rubyAccessor: "missed_hours",
        label: t("Missed Minutes"),
        Footer: minsToHrsMins(totalMissedMinutes),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "cumulativeHoursBankMinutes",
        rubyAccessor: "cumulative_hours_bank",
        label: t("Accumulated Hours Bank"),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "crossShiftsInterval",
        rubyAccessor: "cross_shifts_interval_diff_hours",
        label: t("Cross Shift Interval"),
        style: { fontWeight: "500" },
        Footer: minsToHrsMins(totalCrossShiftsIntervalDiffMinutes),
        minWidth: 100,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "holiday",
        rubyAccessor: "holiday",
        label: t("Holiday"),
        Header: (
          <div className={tc("icon")}>
            {images.holidayIcon}
            <span className={tc("tooltip")}>{t("Holiday")}</span>
          </div>
        ),
        minWidth: 90,
        Cell: (row: iCellInfo<ReportsDetailedTableData>): JSX.Element | null =>
          row.original.holiday ? (
            <span className={tc("holiday")}>
              {images.holidayIcon}
              <div className="hint">{row.value}</div>
            </span>
          ) : null,
        style: { overflow: "visible", fontWeight: "500" },
        align: "center" as ColumnAlign,
      },
      {
        accessor: "observation",
        rubyAccessor: "observation",
        label: t("OBS"),
        minWidth: 100,
        Cell: (row: iCellInfo<ReportsDetailedTableData>): JSX.Element | string | null => this.getObservations(row),
      },
      {
        accessor: "onCallMinutes",
        rubyAccessor: "on_call_hours",
        label: t("On Call Minutes"),
        Footer: minsToHrsMins(totalOnCallMinutes.onCall),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "onCallActivatedMinutes",
        rubyAccessor: "on_call_activated_hours",
        Footer: minsToHrsMins(totalOnCallMinutes.activated),
        label: t("On Call Activated Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "onCallReducingActivatedMinutes",
        rubyAccessor: "on_call_reducing_activated_hours",
        Footer: minsToHrsMins(totalOnCallMinutes.reducingActivated),
        label: t("On Call Reducing Activated Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "debitMinutes",
        rubyAccessor: "debit_hours",
        Footer: minsToHrsMins(totalDebitMinutes),
        label: t("Debit Minutes"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
      {
        accessor: "unusedBreakMinutes",
        rubyAccessor: "unused_break_hours",
        Footer: minsToHrsMins(totalUnusedBreakMinutes),
        label: t("Unused Break"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "end" as ColumnAlign,
      },
      ...crossShiftsPhasesColumns,
    ];

    return columns.filter((col) => col);
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsDetailedTable", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { error, employeeInfo, dates = [], isFetching, selectedColumns, servicesSummary } = this.state;
    const { t, searchObj, startDate, endDate, showServicesSummary } = this.props;
    let lastScheduleName = "";

    if (error) {
      return <div>{error}</div>;
    }

    const data = dates.map((d): ReportsDetailedTableData => {
      const punches2: Record<string, string> = {};
      let entryI = 0;
      let breakStartI = 0;
      let breakEndI = 0;
      let exitI = 0;

      if (d.schedule) {
        lastScheduleName = d.schedule.name;
      }

      d.punches.forEach((p) => {
        let { key } = p;

        if (!key) {
          switch (p.description) {
            case "entry":
              key = `${p.description}${entryI++}`;
              break;
            case "break_start":
              key = `${p.description}${breakStartI++}`;
              break;
            case "break_end":
              key = `${p.description}${breakEndI++}`;
              break;
            case "exit":
              key = `${p.description}${exitI++}`;
              break;
            default:
              break;
          }
        } else {
          switch (p.description) {
            case "entry":
              entryI++;
              break;
            case "break_start":
              breakStartI++;
              break;
            case "break_end":
              breakEndI++;
              break;
            case "exit":
              exitI++;
              break;
            default:
              break;
          }
        }

        punches2[key as string] = p.time ? momentTz.tz(p.time, p.timezone).format("HH:mm") : "00:00";
      });

      return {
        date: getDateCellData(d),
        ...punches2,
        workedMinutes: d.workedMinutes ? minsToHrsMins(d.workedMinutes) : "00:00",
        extraHoursPhases: d.extraHoursPhases,
        extraHoursMinutes: d.extraHoursMinutes ? minsToHrsMins(d.extraHoursMinutes) : "00:00",
        actualDayBreaksByTypes: d.actualDayBreaksByTypes,
        actualNightBreaksByTypes: d.actualNightBreaksByTypes,
        plannedDayBreakByTypes: d.plannedDayBreakByTypes,
        plannedNightBreakByTypes: d.plannedNightBreakByTypes,
        hoursDistribution: d.hoursDistribution,
        plannedMinutes: d.plannedMinutes ? minsToHrsMins(d.plannedMinutes) : "00:00",
        nightMinutes: d.nightMinutes ? minsToHrsMins(d.nightMinutes) : "00:00",
        nightReducedMinutes: d.nightReducedMinutes ? minsToHrsMins(d.nightReducedMinutes) : "00:00",
        businessRulesGroup: d.businessRulesGroup ? d.businessRulesGroup.name : "",
        lateEntryMinutes: d.lateEntryMinutes ? minsToHrsMins(d.lateEntryMinutes) : "00:00",
        earlyLeaveMinutes: d.earlyLeaveMinutes ? minsToHrsMins(d.earlyLeaveMinutes) : "00:00",
        hoursBankPhases: d.hoursBankPhases,
        hoursBankMinutes: d.hoursBankMinutes ? minsToHrsMins(d.hoursBankMinutes) : "00:00",
        missedMinutes: d.missedMinutes ? minsToHrsMins(d.missedMinutes) : "00:00",
        cumulativeHoursBankMinutes: d.cumulativeHoursBankMinutes
          ? minsToHrsMins(d.cumulativeHoursBankMinutes)
          : "00:00",
        crossShiftsInterval: d.crossShiftsInterval ? minsToHrsMins(d.crossShiftsInterval.diffMinutes) : "00:00",
        nightShiftMinutes: d.nightShiftMinutes || {},
        crossShiftsIntervalPhases: d.crossShiftsIntervalPhases || {},
        holiday: d.holiday,
        schedule: d.schedule ? d.schedule.name || "" : "",
        scheduleException: d.scheduleException ? d.scheduleException.name || "enableDay" : "",
        missedDay: d.missedDay,
        observation: d.observation,
        requests: d.requests,
        breakMinutes: minsToHrsMins(d.debitMinutes || 0),
        debitMinutes: d.debitMinutes ? minsToHrsMins(d.debitMinutes) : "00:00",
        unusedBreakMinutes: d.unusedBreakMinutes ? minsToHrsMins(d.unusedBreakMinutes) : "00:00",
        onCallMinutes: d?.onCallMinutes?.onCall ? minsToHrsMins(d.onCallMinutes.onCall) : "00:00",
        onCallActivatedMinutes: d?.onCallMinutes?.activated ? minsToHrsMins(d.onCallMinutes.activated) : "00:00",
        onCallReducingActivatedMinutes: d?.onCallMinutes?.reducingActivated
          ? minsToHrsMins(d.onCallMinutes.reducingActivated)
          : "00:00",
        status: d.status,
        punches: d.punches,
      };
    });

    const columns = this.getColumns();

    const hasSuperpunchButton =
      hasPermisionAccess(PermissionSectionName.superpunch) && searchObj && searchObj.employee && !searchObj.type;

    return (
      <>
        <TablePage<ReportsDetailedTableData>
          withHeaderTooltip
          rows={data}
          columns={columns}
          loading={isFetching}
          customColumnsAvailable
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDatailsClassName="table-details-ai-start"
          tableDetails={<ReportUserInfo employeeInfo={{ ...employeeInfo, schedule: lastScheduleName }} />}
          superpunch={
            hasSuperpunchButton ? (
              <SuperPunchButton
                to={`/punches?type=edit&label=${searchObj.label || searchObj.employee.full_name}&id=${
                  searchObj.id || searchObj.employee.id
                }&uuid=${searchObj.uuid || searchObj.employee.uuid}&startDate=${startDate.format(
                  "YYYY-MM-DD",
                )}&endDate=${endDate.format("YYYY-MM-DD")}`}
              >
                {t("Super Punch")}
              </SuperPunchButton>
            ) : null
          }
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              onChange={(value: string): APIResponse<Record<string, unknown>> =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  showServicesSummary,
                  searchObj,
                  startDate,
                  endDate,
                  reportType: "detailed",
                })
              }
            />
          }
          interactive={false}
          getTrProps={(_, rowInfo): { className: string } => ({
            className:
              // eslint-disable-next-line no-underscore-dangle
              rowInfo?.row?._original?.status !== ReportStatus.completed
                ? // eslint-disable-next-line no-underscore-dangle
                  `row-incomplete row-incomplete_${rowInfo?.row._original.status}`
                : "",
          })}
        />
        {showServicesSummary && !!servicesSummary?.metadata && (
          <ReportsDetailedServicesSummary
            servicesSummary={servicesSummary}
            currency={window.global_store.company?.currency}
            summaryWithCosts
          />
        )}
      </>
    );
  }
}

export default withLDConsumer()(
  withRouter(
    withTranslation([
      TranslationNamespaces.reportsPage,
      TranslationNamespaces.requestsPageTmp,
      TranslationNamespaces.phases,
      TranslationNamespaces.schedules,
    ])(ReportsDetailedTable),
  ),
);
