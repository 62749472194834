import styled from "styled-components";

export default styled.div`
  display: flex;

  > div {
    min-width: 150px;
    margin-inline-end: 10px;
  }
`;
