import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import * as images from "components/svg-images";

import { FilteredEmployeeProfile } from "types/models/userProfile";

import LazyListLoading from "./LazyListLoading";
import {
  RemoveEmployee,
  SelectedEmployeeItem,
  SelectedEmployeeName,
  SelectedEmployeePosition,
  SelectedEmployeesCount,
  SelectedEmployeesTitle,
  SelectedEmployeesWrapper,
} from "./styled";

interface SelectedEmployeesProps {
  selectedEmployees: FilteredEmployeeProfile[];
  onRemove: (employee: FilteredEmployeeProfile) => void;
  children: ReactNode;
}

export const SelectedEmployees = ({ selectedEmployees = [], onRemove, children }: SelectedEmployeesProps) => {
  const { t } = useTranslation("common");

  return (
    <SelectedEmployeesWrapper>
      <SelectedEmployeesTitle>
        <div>{t("Selected Employees")}</div>
        <SelectedEmployeesCount>{selectedEmployees.length}</SelectedEmployeesCount>
      </SelectedEmployeesTitle>
      <LazyListLoading>
        {selectedEmployees.map((e) => (
          <SelectedEmployeeItem key={e.uuid}>
            <SelectedEmployeeName>{e.fullName}</SelectedEmployeeName>
            <SelectedEmployeePosition>{e.position ? e.position.title : ""}</SelectedEmployeePosition>
            <RemoveEmployee className="button-remove" onClick={() => onRemove(e)}>
              {images.removeIcon(10)}
            </RemoveEmployee>
          </SelectedEmployeeItem>
        ))}
      </LazyListLoading>
      {children}
    </SelectedEmployeesWrapper>
  );
};
