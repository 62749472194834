import { ChangeEvent, Component } from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import TablePage from "components/TablePage";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import { iColumn } from "components/TableCommon";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { strIncludesCheck } from "utils/common";
import { PayrollLock } from "types/models/payrollLocks";
import GlobalContext from "context/global-context";
import { getLocksList } from "utils/apiHelpers";
import moment from "moment";
import { AllFlagsLDClient } from "launchdarkly-react-client-sdk";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import TableButtonsControl from "components/styled/TableButtonsControl";
import { TranslationNamespaces } from "types/translationNamespaces";
import { DigitalSignatureStatus } from "types/models/digitalSignature";
import TableUserProfile from "components/styled/TableUserProfile";

interface DeactivatedEmployeesProps extends RouteComponentProps, WithTranslation, AllFlagsLDClient {
  getTableButtons: (payrollLock: PayrollLock) => {
    dropdownButtons: { label: string; onClick: () => void }[];
    visibleButtons: { label: string; onClick: () => void }[];
  };
}

const DS_STATUS_TO_STATUS_MAP: Record<DigitalSignatureStatus, StatusBadgeStatuses> = {
  [DigitalSignatureStatus.signRequestSent]: StatusBadgeStatuses.pending,
  [DigitalSignatureStatus.signRequestInProggress]: StatusBadgeStatuses.pending,
  [DigitalSignatureStatus.pending]: StatusBadgeStatuses.pending,
  [DigitalSignatureStatus.signed]: StatusBadgeStatuses.success,
  [DigitalSignatureStatus.canceled]: StatusBadgeStatuses.default,
  [DigitalSignatureStatus.missing]: StatusBadgeStatuses.default,
};

interface DeactivatedEmployeesState {
  locks: PayrollLock[];
  isFetching: boolean;
  searchValue: string;
}

class DeactivatedEmployeesTableOld extends Component<DeactivatedEmployeesProps, DeactivatedEmployeesState> {
  static contextType = GlobalContext;

  readonly state: DeactivatedEmployeesState = {
    locks: [],
    isFetching: false,
    searchValue: "",
  };

  componentDidMount(): void {
    void this.getState();
  }

  onSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchValue: e.target.value });
  };

  getState = async (): Promise<void> => {
    let locks = [];

    this.setState({ isFetching: true });

    const company = await this.context.getCompany();
    const locksListResp = await getLocksList({ companyUuid: company.uuid, forDeactivatedProfile: true });
    const locksList: PayrollLock[] = locksListResp.content || [];

    // get created by employee details from cache
    // get all needed uuids
    const createdByEmployeesList: Record<string, any> = {};

    locksList.forEach((lock) => {
      createdByEmployeesList[lock.createdBy] = {};
    });

    // fetch details by uuids
    const { content } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(
        window.global_store.profile.uuid,
        locksList
          .map((lock) => (lock.userProfilePayrollLocks ? lock.userProfilePayrollLocks[0].userProfileUuid : null))
          .filter(Boolean)
          .concat(Object.keys(createdByEmployeesList)) as string[],
      ),
    );

    // assign details to object with uuids
    content.forEach((employee) => {
      createdByEmployeesList[employee.uuid] = employee;
    });

    locks = locksList
      .map((lock) => {
        const newLock = lock;

        if (!lock.userProfilePayrollLocks) {
          return null;
        }

        const { userProfileUuid } = lock.userProfilePayrollLocks[0];
        const userProfile = content.find((employee) => employee.uuid === userProfileUuid);
        newLock.deactivatedEmployeeProfile = userProfile;
        newLock.createdBy = createdByEmployeesList[lock.createdBy]?.fullName || "";
        return newLock;
      })
      .filter(Boolean) as PayrollLock[];

    this.setState({
      locks: locks.filter((l) => !!l.deactivatedEmployeeProfile),
      isFetching: false,
    });
  };

  getTableColumns = (rowsCount: number): iColumn<PayrollLock>[] => {
    const { t } = this.props;

    return [
      {
        label: t(`${TranslationNamespaces.common}|Employee`),
        accessor: "deactivatedEmployeeProfile",
        minWidth: 160,
        Cell: (row): JSX.Element => <TableUserProfile {...row.value} />,
      },
      {
        label: t(`${TranslationNamespaces.common}|Matricula`),
        accessor: "deactivatedEmployeeProfile",
        rubyAccessor: "matricula",
        Cell: (row): string => row.value.matricula,
        width: 160,
      },
      {
        label: t(`${TranslationNamespaces.common}|CPF`),
        accessor: "deactivatedEmployeeProfile",
        rubyAccessor: "cpf",
        Cell: (row): string => row.value.taxPayerId || "",
      },
      {
        label: t("Period"),
        accessor: "endDate",
        Cell: (row): string =>
          `${moment(row.original.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
            row.value,
            "YYYY-MM-DD",
          ).format("DD/MM/YYYY")}`,
      },
      {
        label: t("Created by"),
        accessor: "createdBy",
        Cell: (row): string => row.value,
      },
      {
        label: t("Payroll Status"),
        accessor: "status",
        Cell: (row): JSX.Element => <StatusBadge value={t(row.value)} type={row.value} />,
      },
      {
        label: t("signature status"),
        accessor: "digitalSignatureStatus",
        Cell: (row): JSX.Element => (
          <div style={{ maxWidth: "100px" }}>
            <StatusBadge
              value={row.value ? t(row.value) : t("not requested")}
              type={
                row.value ? DS_STATUS_TO_STATUS_MAP[row.value as DigitalSignatureStatus] : StatusBadgeStatuses.default
              }
            />
            <TableButtonsControl
              dropToTop={rowsCount > 8 && row.viewIndex >= rowsCount - 2}
              {...this.props.getTableButtons(row.original)}
            />
          </div>
        ),
      },
    ];
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { locks, searchValue, isFetching } = this.state;
    const filteredLocks = locks.filter((l) =>
      strIncludesCheck(
        (l.deactivatedEmployeeProfile?.fullName as string) || (l.deactivatedEmployeeProfile?.name as string),
        searchValue,
      ),
    );

    return (
      <>
        <TablePage
          rows={filteredLocks}
          filters={
            <SearchInput
              modifiers={["filter"]}
              onChange={this.onSearch}
              placeholder={t("Search")}
              value={searchValue}
            />
          }
          columnSelectorOnFiltersRow
          columns={this.getTableColumns(filteredLocks.length)}
          getTrProps={(): any => ({})}
          className="deactivated-employees-table"
          loading={isFetching}
          noContentComponent={<NoContent>{t("You haven't deactivated employees")}</NoContent>}
        />
      </>
    );
  }
}

export default DeactivatedEmployeesTableOld;
