import { CSSProperties, LegacyRef, PropsWithChildren } from "react";
import { withGoogleMap, GoogleMap, GoogleMapProps } from "react-google-maps";

interface MapProps extends PropsWithChildren, GoogleMapProps {
  mapRef: LegacyRef<GoogleMap>;
}

const Map = withGoogleMap(({ children, mapRef, ...restProps }: MapProps) => (
  <GoogleMap {...restProps} ref={mapRef}>
    {children}
  </GoogleMap>
));

interface MapWrapperProps extends MapProps {
  className: string;
  style?: CSSProperties;
  height: string;
}

const MapWrapper = ({ className, style = {}, height = "200px", ...props }: MapWrapperProps) => (
  <Map
    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: "100%" }} />}
    containerElement={<div style={{ height, ...style }} className={className} />}
    mapElement={<div style={{ height: "100%" }} />}
    {...props}
  />
);

export default MapWrapper;
