import { Component, ContextType } from "react";
import GlobalContext from "context/global-context";
import Select from "components/UI/Select";
import { SelectOption } from "types/ui";
import { getProjectsList } from "./projectsApiUtils";

interface ProjectsDropdownControlFilterProps {
  onChange: (val: SelectOption) => void;
  placeholder: string;
  value: string;
}

interface ProjectsDropdownControlFilterState {
  /** SelectOption uses uuid as value */
  projects: SelectOption[];
  isLoading: boolean;
}

export default class ProjectsDropdownControlFilter extends Component<
  ProjectsDropdownControlFilterProps,
  ProjectsDropdownControlFilterState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  state: ProjectsDropdownControlFilterState = {
    isLoading: true,
    projects: [],
  };

  async componentDidMount() {
    const company = await this.context.getCompany();
    const response = await getProjectsList({ companyUuid: company.uuid, status: "active" });

    const projects = response.content || [];

    const items = projects.map((item) => ({ label: item.name, value: item.uuid }));

    this.setState({ projects: [{ label: this.props.placeholder, value: "" }].concat(items), isLoading: false });
  }

  onChange = (val: string) => {
    const { projects } = this.state;
    const { onChange } = this.props;
    const selectedProject = projects.find((proj) => proj.value === val);

    if (selectedProject) {
      onChange(selectedProject);
    }
  };

  render() {
    const { projects, isLoading } = this.state;
    const { value } = this.props;

    return (
      <Select
        className={isLoading ? "loading" : ""}
        modifiers={{ notfilled: !value }}
        value={value || ""}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        options={projects}
      />
    );
  }
}
