import { useTranslation } from "react-i18next";
import { Formik, Form, FormikErrors } from "formik";
import NotificationRow from "components/NotificationRow";
import FormikField from "components/UI/FormikHelpers/Field";
import Button from "components/UI/Button";
import { Location } from "types/models/location";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import BEM from "utils/BEM";
import { addIPValidation } from "utils/apiHelpers";
import "./IP.scss";

const b = BEM.b("validation-ip-form");

interface ValidationIPFormProps {
  currentLocation: Location;
  onCancel: () => void;
  onYes: () => void;
}

const ValidationIPForm = ({ currentLocation, onCancel, onYes }: ValidationIPFormProps) => {
  const { t } = useTranslation(TranslationNamespaces.locations);

  const createIPValidation = async (
    ip: string,
    setErrors: (
      errors: FormikErrors<{
        IP: string;
      }>,
    ) => void,
  ) => {
    try {
      await addIPValidation(currentLocation.id, { body: { ip_address: { title: "", ip } } });

      onYes();
    } catch (e) {
      setErrors({
        IP: t(e as string),
      });
    }
  };

  return (
    <Formik
      key="new"
      initialValues={{
        IP: "",
      }}
      validate={(values) => {
        const errors: Record<string, string> = {};

        if (!values.IP) {
          errors.IP = t("Can't be blank");
        }

        return errors;
      }}
      onSubmit={(values, { setErrors }) => {
        void createIPValidation(values.IP, setErrors);
      }}
      render={({ errors }) => (
        <Form className={b()}>
          <NotificationRow
            onboardingMargin
            withCloseButton={false}
            type={NotificationType.attention}
            message={t("A validação por IP é disponível apenas\n por pontos batidos via plataforma Web.")}
          />
          <h2 className={b("heading")}>{t("IP Setup")}</h2>
          <FormikField
            style={{ height: "auto" }}
            as="textarea"
            name="IP"
            rows="18"
            title={t("IP")}
            placeholder="xxx.xxx.xxx.xxx xxx.xxx.xxx.xxx"
            errors={errors}
          />

          <div className={b("action-buttons")}>
            <Button label={t("Cancel")} modifier="bordered" className={b("action-button")} onClick={onCancel} />
            <Button type="submit" className={b("action-button")} label={t("Create")} />
          </div>
        </Form>
      )}
    />
  );
};

export default ValidationIPForm;
