import styled from "styled-components";
import Option from "./SelectOption";

const Wrapper = styled.div`
  .checkbox-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: var(--typography-font-weight-medium);
  }

  .suboptions {
    padding-inline-start: 28px;

    .checkbox-label {
      font-size: 15px;
      line-height: 19px;
      font-weight: var(--typography-font-weight-default);
    }
  }
`;

export type OptionSubOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

interface OptionWithSubOptionsProps {
  label: string;
  disabled?: boolean;
  checkbox?: boolean;
  subOptions: OptionSubOption[];
  onSelect: (values: string[] | string) => void;
  checkIfActive: (value: string) => boolean;
}

const OptionWithSubOptions = ({
  label,
  disabled,
  checkbox,
  subOptions,
  onSelect,
  checkIfActive,
}: OptionWithSubOptionsProps) => (
  <Wrapper>
    <Option
      onSelect={() => onSelect(subOptions.map((so) => so.value))}
      label={label}
      checkbox={checkbox}
      disabled={disabled}
      dash
      active={subOptions.every((so) => checkIfActive(so.value))}
    />
    <div className="suboptions">
      {subOptions.map((o: OptionSubOption) => (
        <Option
          key={o.value}
          onSelect={() => onSelect(o.value)}
          label={o.label}
          checkbox={checkbox}
          disabled={disabled}
          active={checkIfActive(o.value)}
        />
      ))}
    </div>
  </Wrapper>
);

export default OptionWithSubOptions;
