import styled from "styled-components";
import Avatar from "components/views/Avatar";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
`;

const NamePosition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Name = styled.div`
  color: var(--colors-surface-800);
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 16px;
`;

const Position = styled.div`
  color: var(--colors-surface-500);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GroupAvatar = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  background-color: var(--colors-primary-100);
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='13' fill='none'%3E%3Cpath fill='%231E97F7' d='M8.2 6.646a3.28 3.28 0 0 0 1.133-2.48 3.333 3.333 0 0 0-6.667 0A3.28 3.28 0 0 0 3.8 6.646 5.333 5.333 0 0 0 .667 11.5.667.667 0 1 0 2 11.5a4 4 0 1 1 8 0 .667.667 0 0 0 1.333 0A5.333 5.333 0 0 0 8.2 6.646ZM6 6.166a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm6.493.214A3.334 3.334 0 0 0 10 .833a.667.667 0 0 0 0 1.333 2 2 0 0 1 2 2 2 2 0 0 1-1 1.727.666.666 0 0 0-.034 1.133l.26.174.087.046A4.667 4.667 0 0 1 13.98 11.5a.667.667 0 0 0 1.333 0 6 6 0 0 0-2.82-5.12Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;

type AvatarWithDetailsProps = {
  title: string;
  subtitle?: string;
  avatarId?: string;
  isServiceGroup?: boolean;
};

const AvatarWithDetails = ({ title, subtitle, avatarId, isServiceGroup }: AvatarWithDetailsProps) => (
  <Wrapper>
    {isServiceGroup ? <GroupAvatar /> : <Avatar user={{ fullName: title, avatarId }} />}
    <NamePosition>
      <Name>{title}</Name>
      {subtitle && <Position>{subtitle}</Position>}
    </NamePosition>
  </Wrapper>
);

export default AvatarWithDetails;
