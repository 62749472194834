import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import moment from "moment-timezone";
import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import FieldWrapper from "components/UI/FieldWrapper";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { NotificationType } from "types/common";
import NotificationRow from "components/NotificationRow";
import { fireEvent, minsToHrsMins } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import CONFIG from "config";
import Loader from "components/styled/Loader";
import { RequestUserProfile } from "types/models/userProfile";
import Sentry from "utils/sentryUtils";
import ga from "utils/ga";
import DateRangeSelector from "components/controls/DatePicker/DateRangeSelector";
import { ParsedTimesheetData, TimesheetApprovalStatus, TimesheetsListItem } from "../../timesheet.types";
import { TimesheetContext } from "../../timesheets.context";
import { getTimesheet, listTimesheets, ListTimesheetsRequest, TimesheetDataQuery } from "../../timesheet-api.service";
import { parseTimesheet } from "../../timesheet.helpers";

const styles = stylesheet`
.Wrapper {
  color: var(--colors-surface-800);

  .Title {
    font-size: 25px;
    color: var(--colors-surface-900-p);
    letter-spacing: -0.55px;
    line-height: 26px;
    margin-bottom: 32px;
    text-align: center;
  }

  .Message {
    font-size: var(--typography-font-size-default);
    margin-bottom: 16px;
  }

  .Grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    border-bottom: 1px solid var(--colors-surface-150);
    padding: 12px 0;
    &.NoBorder {
      border: none;
    }
    .GridTitle {
      text-transform: uppercase;
      color: var(--colors-surface-500);
      font-size: 12px;
      font-weight: var(--typography-font-weight-bold);
    }
    .ListTitle {
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-800);
    }
    .ListData {
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-900-p);
      font-weight: var(--typography-font-weight-medium);
      &.Error {
        color: var(--colors-danger-600-p);
      }
    }
  }
  .ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    button {
      max-width: 155px;
    }
  }
}
`;

interface SubmitTimesheetPopupProps {
  startDate?: string;
  endDate?: string;
  onClose: () => void;
}

export default function SubmitTimesheetPopup(props: SubmitTimesheetPopupProps) {
  const { onClose } = props;
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const timesheetContext = useContext(TimesheetContext);
  const [startDate, setStartDate] = useState<moment.Moment>();
  const [endDate, setEndDate] = useState<moment.Moment>();
  const [data, setData] = useState<ParsedTimesheetData>();
  const [timesheets, setTimesheets] = useState<TimesheetsListItem[]>();
  const [notification, setNotification] = useState<{ message: string; type: NotificationType }>();
  const [timesheetUserProfile, setTimesheetUserProfile] = useState<RequestUserProfile>();
  const lockedDates: boolean = useMemo(() => !!startDate && !endDate, [startDate, endDate]);
  const { settings, userProfile, companyUuid, currentUserUuid } = timesheetContext;
  const moreThan24hError = useCallback(
    () => (data && data.summary.gt24hPerDayNumDays > 0 && !!settings?.timesheetDailyLock) || false,
    [data, settings],
  );
  const hasOvertimesError = useCallback(
    () => (data && data.summary.activityMoreThanWorkingTimeDays > 0 && !!settings?.timesheetWorkingHoursLock) || false,
    [data, settings],
  );
  const hasActivityMoreThanPunchedError = useCallback(
    () =>
      (data &&
        data.summary.overallActivityMoreThanWorkingTimeDays > 0 &&
        !!settings?.restrictActivitiesBasedOnPunchIn) ||
      false,
    [data, settings],
  );

  const processNotification = () => {
    if (moreThan24hError()) {
      setNotification({ message: t("contain_days_with_activity_more_than_24h"), type: NotificationType.errorNew });
    } else if (hasOvertimesError() || hasActivityMoreThanPunchedError()) {
      setNotification({
        message: t("contain_days_with_activity_more_than_working_hours"),
        type: NotificationType.errorNew,
      });
    } else {
      setNotification({ message: t("timesheet_will_be_locked_after_submission"), type: NotificationType.info });
    }
  };

  const fetchDays = async () => {
    const requestArgs: TimesheetDataQuery = {
      from: startDate!.format(CONFIG.apiDateFormat),
      to: endDate!.format(CONFIG.apiDateFormat),
      userProfileUuid: userProfile?.uuid ?? currentUserUuid,
      requestedBy: currentUserUuid,
      companyUuid,
    };
    const res = await getTimesheet(requestArgs);
    return parseTimesheet(res);
  };

  const fetchTimesheets = async () => {
    const req: ListTimesheetsRequest = {
      companyUuid,
      requestedBy: window.global_store.profile.uuid,
      userProfileUuid: userProfile?.uuid ?? currentUserUuid,
      approvalStatus: [TimesheetApprovalStatus.pending, TimesheetApprovalStatus.approved],
    };
    return listTimesheets(req);
  };

  const [loadData, loadingData] = useAsyncCallback(async () => {
    if (startDate && endDate) {
      const days = await fetchDays();
      await setData(days);
    } else {
      await setData(undefined);
    }
  }, [startDate, endDate]);

  const [loadTimesheets, loadingTimesheets] = useAsyncCallback(async () => {
    if (!userProfile) {
      await timesheetContext.setUserProfile(window.global_store.profile);
    }
    const { content, metadata } = await fetchTimesheets();
    setTimesheets(content);

    const user = metadata.userProfilesByUuid[userProfile?.uuid || currentUserUuid];
    setTimesheetUserProfile(user);
    return content;
  }, [userProfile]);

  // process notification after receiving the data
  useEffect(() => processNotification(), [data]);
  // load data on changing dates
  useEffect(() => void loadTimesheets(), [loadTimesheets]);
  useEffect(() => void loadData(), [loadData]);

  const [submit, submitting] = useAsyncCallback(
    async () => {
      try {
        await timesheetContext.submitTimesheet(data!.summary, startDate!, endDate!);
        const { userProfile } = timesheetContext;

        ga.trackSubmitTimesheet();

        fireEvent("timesheet_message", {
          uuid: uuidv4(),
          message:
            userProfile?.uuid === window.global_store.profile.uuid
              ? t("You've submitted the timesheet successfully")
              : t("You've successfully submitted the timesheet for {{name}}", { name: userProfile?.name }),
          status: "success",
        });

        typeof timesheetContext.reloadCallback === "function" && timesheetContext.reloadCallback();
      } catch (error) {
        fireEvent("timesheet_message", {
          uuid: uuidv4(),
          message: t("Failed to submit the timesheet"),
          status: "error",
        });
        Sentry.sendError(error);
      }
    },
    [t, data, startDate, endDate],
    true,
  );
  const handleSubmit = async () => {
    if (!hasOvertimesError() && !moreThan24hError() && !hasActivityMoreThanPunchedError()) {
      await submit();
    }
    onClose();
  };

  const isOutsideRange = (day: moment.Moment): boolean => {
    const availableDaysCount = 45;
    const lastLockDate = timesheetUserProfile?.lastLockDate;

    return (
      day.isAfter(moment(), "day") ||
      !!timesheets?.find((ts) => day.isSameOrAfter(ts.startDate, "day") && day.isSameOrBefore(ts.endDate, "day")) ||
      (startDate
        ? day.isAfter(startDate.clone().add(availableDaysCount, "day")) ||
          (lockedDates &&
            !!timesheets?.find(
              (ts) => day.isSameOrAfter(ts.startDate, "day") && startDate.clone().isBefore(ts.startDate, "day"),
            ))
        : false) ||
      (!!lastLockDate && day.isSameOrBefore(lastLockDate, "day"))
    );
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Title}>{t("Submit timesheet")}</div>
      {!loadingTimesheets && timesheets ? (
        <FieldWrapper width="100%" fieldTitleMarginTop={24} fieldName={t("Period")}>
          <DateRangeSelector
            disabled={loadingData}
            startDate={startDate}
            endDate={endDate}
            handleDateChange={(sd, ed) => {
              setStartDate(sd!);
              setEndDate(ed!);
            }}
            selectPlaceholder={t("Select a time range")}
            isOutsideRange={isOutsideRange}
          />
        </FieldWrapper>
      ) : (
        <Loader />
      )}
      {data ? (
        <div style={{ marginTop: "16px" }}>
          <div className={styles.Grid}>
            <div className={styles.GridTitle}>{t("Summary")}</div>
            <div className={styles.GridTitle}>{t("For {{dateNum}} days", { dateNum: data.dates.length })}</div>
          </div>
          <div className={styles.Grid}>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListTitle}>{t("Working hours")}</div>
            </FieldWrapper>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListData}>{data && minsToHrsMins(data.summary.actualWorkMinutes)}</div>
            </FieldWrapper>
          </div>
          <div className={styles.Grid}>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListTitle}>{t("Activity hours")}</div>
            </FieldWrapper>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListData}>{data && minsToHrsMins(data.summary.dailyActivitiesMinutes)}</div>
            </FieldWrapper>
          </div>
          <div className={styles.Grid}>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListTitle}>{t(">24h per day")}</div>
            </FieldWrapper>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={`${styles.ListData} ${moreThan24hError() ? styles.Error : ""}`}>
                {data.summary?.gt24hPerDayNumDays > 0
                  ? t(">24h_per_day_value {{days}}", { days: data?.summary.gt24hPerDayNumDays })
                  : 0}
              </div>
            </FieldWrapper>
          </div>
          <div className={`${styles.Grid} ${styles.NoBorder}`}>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={styles.ListTitle}>{t("Overtime")}</div>
            </FieldWrapper>
            <FieldWrapper width="100%" loading={loadingData} fieldTitleMarginTop={0}>
              <div className={`${styles.ListData} ${hasOvertimesError() ? styles.Error : ""}`}>
                {data.summary?.activityMoreThanWorkingTimeDays > 0
                  ? t("overtime_value {{days}}", { days: data?.summary.activityMoreThanWorkingTimeDays })
                  : 0}
              </div>
            </FieldWrapper>
          </div>
        </div>
      ) : null}
      {notification && data ? (
        <NotificationRow style={{ marginTop: "16px" }} type={notification.type} message={notification.message} />
      ) : null}
      <div className={styles.ButtonsWrapper}>
        <Button value={t(`${TranslationNamespaces.common}|Cancel`)} state={ButtonState.secondary} onClick={onClose} />
        <Button
          loading={submitting}
          disabled={loadingData || !data}
          state={ButtonState.primary}
          value={
            hasOvertimesError() || moreThan24hError()
              ? t("Edit Timesheet")
              : t(`${TranslationNamespaces.common}|Submit`)
          }
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
