import { Component } from "react";
import "styles/schedule-employees.scss";
import StatusBadge from "components/controls/StatusBadge";
import AddEmployeeRow from "components/controls/AddEmployeeRow";
import ModalDialog from "components/UI/ModalDialog";
import SearchInput from "components/UI/SearchInput";
import styled from "styled-components";
import { withLockDateCheck } from "components/HocWithLockDateCheck";
import { withTranslation } from "react-i18next";
import Lightbox from "components/Lightbox";
import { TableButton, TableButtons } from "components/styled/Page";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import Select from "components/UI/Select";
import { getEmployeeTaxIdTranslation, getEmployeeTaxPayerType, strIncludesCheck } from "utils/common";
import BEM from "utils/BEM";
import GlobalContext from "context/global-context";
import { PermissionSectionName } from "types/models/permissions";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { MassActionLocations } from "utils/ga";
import ChangeGroupPopup from "./ChangeGroupPopup";
import {
  assignEmployeeToPayrollGroup,
  getLockGroupByUuid,
  getLockGroupsList,
  unassignEmployeeFromPayrollGroup,
} from "../../utils/apiHelpers";
import Avatar from "../views/Avatar";
import NotificationRow from "../NotificationRow";
import * as images from "../svg-images";

const b = BEM.b("schedule-employees");
const tb = BEM.b("table-common");

const FiltersWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: ${(p) => (p.withSearch ? "space-between" : "flex-end")};
  gap: 24px;
`;

const SearchFiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

class ScheduleEmployees extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      addingEmployees: false,
      notification: "",
      notificationType: "success",
      filterStr: "",
      groups: [],
      status: "active",
      isFetching: false,
      addEmployeeRowVisible: this.props.addEmployeesActive,
    };
  }
  async componentDidMount() {
    const { group } = this.props;
    const company = await this.context.getCompany();
    const employeesInGroup = group.userProfilePayrollGroups || [];
    this.setState({ isFetching: true });

    const { content } = await listUserProfilesWIthFilters(
      company.uuid,
      baseByUuidPayload(
        window.global_store.profile.uuid,
        employeesInGroup.map((s) => s.userProfileUuid),
      ),
    );

    let lockGroupsList = await getLockGroupsList({ companyUuid: company.uuid });
    lockGroupsList = lockGroupsList.content || [];

    // getting startDate (assigment date) from group details
    const res = content.map((p) => ({
      ...p,
      assigmentDate: employeesInGroup.filter((e) => e.userProfileUuid === p.uuid)[0]?.startDate,
    }));

    this.setState({ employees: res, groups: lockGroupsList, isFetching: false });
  }

  async updateEmployeesList(stateToUpdate) {
    this.setState({ isFetching: true });
    const state = { ...stateToUpdate };
    const companyUuid = window.global_store.company.uuid;

    const lockGroup = await getLockGroupByUuid({ companyUuid, payrollGroupUuid: this.props.group.uuid });
    if (lockGroup?.content?.userProfilePayrollGroups?.length) {
      const { content } = await listUserProfilesWIthFilters(
        companyUuid,
        baseByUuidPayload(
          window.global_store.profile.uuid,
          lockGroup.content.userProfilePayrollGroups.map((s) => s.userProfileUuid),
        ),
      );

      const res = content.map((p) => ({
        ...p,
        assigmentDate: lockGroup.content.userProfilePayrollGroups.filter((e) => e.userProfileUuid === p.uuid)[0]
          .startDate,
      }));
      state.employees = res || [];
    } else {
      state.employees = [];
    }
    this.setState({ ...state, isFetching: false });
  }
  removeEmployee(employee) {
    const { t, group } = this.props;
    const companyUuid = window.global_store.company.uuid;
    unassignEmployeeFromPayrollGroup({
      body: {
        content: {
          userProfileUuid: employee.uuid,
        },
      },
      companyUuid,
      payrollGroupUuid: group.uuid,
    })
      .then((r) => {
        this.setState({
          notification: `${t("You unassigned")} ${employee.name}`,
          confirmationPopupVisible: false,
          selectedEmployee: null,
          notificationType: "success",
        });
        this.props.onUpade();
      })
      .catch((e) => {
        this.setState({ notificationType: "error", confirmationPopupVisible: false, notification: e.message });
      });
  }

  onAddEmployee = async (input) => {
    const { t, group } = this.props;
    const { employees } = this.state;

    let employee = null;
    let userProfileUuids = [];

    if (!Array.isArray(input)) {
      employee = input.employee ? input.employee : input;
      userProfileUuids = [input.uuid];
    } else {
      if (input.length === 1) {
        [employee] = input;
      }
      userProfileUuids = input;
    }

    if (employees.filter((u) => userProfileUuids.indexOf(u.uuid) > -1).length > 0) {
      this.setState({
        notificationType: "error",
        notification: t("Employee is already added"),
        selectedEmployee: null,
      });
    } else {
      try {
        this.setState({ addingEmployees: true });
        const companyUuid = window.global_store.company.uuid;
        await assignEmployeeToPayrollGroup({
          companyUuid,
          payrollGroupUuid: group.uuid,
          body: {
            content: {
              userProfileUuids,
              createdBy: window.global_store.profile.uuid,
            },
          },
        });
        this.updateEmployeesList({
          notification: `${t("You assigned")} ${
            employee
              ? employee.full_name || employee.name || employee.fullName
              : ` ${userProfileUuids.length} ${t("common|employees")}`
          }`,
          addingEmployees: false,
          notificationType: "success",
          deactivatePopupVisible: false,
          selectedEmployee: null,
        });
      } catch (e) {
        this.setState({ addingEmployees: false, notificationType: "error", notification: e.message });
      }
    }
  };

  filterEmployees(ev) {
    this.setState({ filterStr: ev.target.value });
  }
  onNotificationClose(notification) {
    this.setState({ notification: "" });
  }

  getTableColumns() {
    const { t, group } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns = [
      {
        Header: t("common|Employees"),
        accessor: "id",
        sortable: false,
        resizable: false,
        Cell: (row) => (
          <div className={tb("full-name")}>
            <Avatar user={{ fullName: row.original.fullName, avatarId: row.original.avatarId }} />
            <div className={tb("name-position")}>
              <div className={tb("name")}>{row.original.fullName}</div>
              {row.original.position && <div className={tb("position")}>{row.original.position.title}</div>}
            </div>
          </div>
        ),
        minWidth: 178,
      },
      {
        label: employeeTaxIdLabel,
        accessor: "taxPayerId",
        width: 160,
      },
      {
        label: t("Start Date"),
        accessor: "assigmentDate",
        width: 160,
      },
      {
        Header: t("common|Status"),
        accessor: "employeeStatus",
        align: "center",
        Cell: (row) => (
          <div>
            <StatusBadge type={row.value} value={t(`common|${row.value}`)} />
            {this.props.groupId && !group.isDefault && (
              <TableButtons className="buttons">
                <TableButton onClick={this.onChangeClick.bind(this, row.original)}>{t("Change Group")}</TableButton>
                <TableButton onClick={this.onRemoveClick.bind(this, row.original)}>{images.trashIcon}</TableButton>
              </TableButtons>
            )}
          </div>
        ),
        minWidth: 150,
      },
    ];
    return columns;
  }
  onChangeClick(item, ev) {
    ev.stopPropagation();
    this.setState({ changeGroupPopupVisible: true, selectedEmployee: item });
  }
  onRemoveClick(item, ev) {
    ev.stopPropagation();

    this.setState({ confirmationPopupVisible: true, selectedEmployee: item });
  }
  changeGroup(group) {
    const { t } = this.props;
    const { selectedEmployee } = this.state;

    const companyUuid = window.global_store.company.uuid;

    assignEmployeeToPayrollGroup({
      companyUuid,
      payrollGroupUuid: group,
      body: {
        content: {
          userProfileUuid: selectedEmployee.uuid,
        },
      },
    })
      .then((r) => {
        this.updateEmployeesList({
          notification: `${t("You assigned")} ${selectedEmployee.fullName}`,
          notificationType: "success",
          changeGroupPopupVisible: false,
          selectedEmployee: null,
        });
      })
      .catch((e) => {
        this.setState({ notificationType: "error", notification: e.message });
      });
  }
  toggleAddEmployeeRow(visible) {
    this.setState({ addEmployeeRowVisible: visible });
  }
  render() {
    const {
      filterStr,
      isFetching,
      addEmployeeRowVisible,
      employees,
      notificationType,
      changeGroupPopupVisible,
      groups,
      selectedEmployee,
      confirmationPopupVisible,
      status,
      addingEmployees,
    } = this.state;
    const { t, group } = this.props;

    const filteredUsers = employees.filter((u) => {
      let filter = true;
      if (u && u.fullName) {
        filter = strIncludesCheck(u.fullName, filterStr);
      }
      if (status !== "") {
        filter = filter && u.employeeStatus === status;
      }
      return filter;
    });
    return (
      <div className={b()}>
        {addEmployeeRowVisible && (
          <>
            <br />
            <AddEmployeeRow
              permissionSection={PermissionSectionName.payrollExport}
              inProgress={addingEmployees}
              hideRow={() => this.toggleAddEmployeeRow(false)}
              withDate={false}
              newSearch
              trackingLocation={MassActionLocations.PayrollGroups}
              onAddEmployee={this.onAddEmployee}
              onAddMultipleEmployee={this.onAddEmployee}
            />
          </>
        )}
        {this.state.notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notificationType}
            onClose={this.onNotificationClose.bind(this)}
            message={this.state.notification}
          />
        )}

        <FiltersWrapper withSearch={employees.length > 0}>
          {employees.length > 0 && (
            <SearchFiltersWrapper>
              <SearchInput
                modifiers={["filter"]}
                onChange={this.filterEmployees.bind(this)}
                placeholder={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-search-employees",
                  `${TranslationNamespaces.common}|Search Employees`,
                )}
                value={filterStr}
              />
              <SelectWrapper>
                <Select
                  value={status}
                  onChange={(status) => this.setState({ status })}
                  options={[
                    { value: "", label: t("common|Status") },
                    { value: "active", label: t("common|Active") },
                    { value: "invited", label: t("common|Invited") },
                  ]}
                />
              </SelectWrapper>
            </SearchFiltersWrapper>
          )}

          {group && group.status !== "deactivated" && !group.isDefault && !addEmployeeRowVisible && (
            <div className={b("add-employee-btn")} onClick={() => this.toggleAddEmployeeRow(true)}>
              {images.plusBig}
              {t("Add Employess")}
            </div>
          )}
        </FiltersWrapper>

        <br />
        <TablePage
          rows={filteredUsers}
          columnSelectorOnFiltersRow
          columns={this.getTableColumns()}
          className="groups-table"
          loading={isFetching}
          interactive
          noContentComponent={<NoContent>{t("Please add employees")}</NoContent>}
        />

        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Are you sure?")}
            text={t("Removing the employee from that group the will assigned to Default pay-group")}
            buttonYesTitle={t("Remove")}
            buttonCancelTitle={t("Cancel")}
            onClose={() => {
              this.setState({ selectedEmployee: null, confirmationPopupVisible: false });
            }}
            onYes={this.removeEmployee.bind(this, selectedEmployee)}
          />
        </ModalDialog>
        <ModalDialog isOpen={changeGroupPopupVisible} onClose={() => this.setState({ changeGroupPopupVisible: false })}>
          <ChangeGroupPopup
            title={t("Change group?")}
            text={t("You are changing this employee's payroll group.")}
            buttonYesTitle={t("Change")}
            buttonCancelTitle={t("Cancel")}
            fieldTitle={t("Select Pay Day Group")}
            groups={groups
              .filter((a) => !a.isDefault && a.status !== "deactivated" && a.uuid !== group.uuid)
              .map((group) => ({ value: group.uuid, label: group.name }))}
            onClose={() => {
              this.setState({ selectedEmployee: null, changeGroupPopupVisible: false });
            }}
            onYes={this.changeGroup.bind(this)}
          />
        </ModalDialog>
      </div>
    );
  }
}
export default withLockDateCheck(withTranslation("payment")(ScheduleEmployees));
