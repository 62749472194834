import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import Select from "components/UI/FormSelect";
import FieldWrapper from "components/UI/FieldWrapper";
import { hrsMinsToMins, minsToHrsMins } from "utils/common";
import TimeControl from "components/controls/TimeControl";
import { Subtitle } from "../styled";
import { AlertsPeriod } from "./helpers";

const Wrapper = styled.div``;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Field = styled.div`
  width: 48%;
`;

type AlertsRowProps = {
  disabled: boolean;
  isHoursBank: boolean;
  locked: boolean;
  error: string | null;
  limit: number | null;
  period: AlertsPeriod;
  onLimitChange: (value: number) => void;
  onPeriodChange: (value: AlertsPeriod) => void;
};

const AlertsRow = ({
  disabled,
  locked,
  error,
  limit,
  period,
  isHoursBank,
  onLimitChange,
  onPeriodChange,
}: AlertsRowProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  return (
    <Wrapper>
      <Subtitle>{isHoursBank ? t("Hours Bank") : t("Extra Hours")}</Subtitle>
      <Row>
        <Field>
          <FieldWrapper fieldName={isHoursBank ? t("Hours bank alert limit") : t("Defined limit")} width="100%">
            <TimeControl
              disabled={disabled}
              locked={locked}
              value={minsToHrsMins(limit || 0)}
              placeholder="00:00"
              onTimeChange={(val) => {
                onLimitChange(hrsMinsToMins(val.value));
              }}
            />
          </FieldWrapper>
        </Field>
        <Field>
          <FieldWrapper fieldName={t("Period")} width="100%">
            <Select
              modifiers={{ field: true }}
              disabled={disabled}
              locked={locked}
              value={period}
              onChange={onPeriodChange}
              options={[
                { value: AlertsPeriod.day, label: t("Daily") },
                { value: AlertsPeriod.week, label: t("Weekly") },
                { value: AlertsPeriod.month, label: t("Monthly") },
              ]}
            />
          </FieldWrapper>
        </Field>
      </Row>
    </Wrapper>
  );
};

export default AlertsRow;
