import { Feature, FeatureLabel } from "types/models/subscription";
import { useEffect, useState } from "react";
import { BillingService } from "components/Billing/BillingService";
import { useTranslation } from "react-i18next";
import Tooltip from "components/UI/Tooltip";
import { stylesheet } from "astroturf";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "../../types/translationNamespaces";
import { ReactComponent as CheckActiveIcon } from "./img/check-green.svg";
import { ReactComponent as CheckIcon } from "./img/check-grey.svg";
import { ReactComponent as InfoIcon } from "./img/info.svg";
import { ReactComponent as TAActiveIcon } from "./img/ta-active.svg";
import { ReactComponent as TAIcon } from "./img/ta.svg";
import { ReactComponent as ProjectIcon } from "./img/projects.svg";
import { ReactComponent as ProjectActiveIcon } from "./img/projects-active.svg";
import { ErrorLabel } from "../UI/TextLabels";

type AssociateEmployeeToProductProps = {
  associateProducts: Feature[];
  setAssociateProducts: (features: Feature[]) => void;
  error?: string | null;
  isUpgrade: boolean;
};

const styles = stylesheet`
  .AssociateWrapper {
    margin-bottom: 32px;
    max-width: 352px;
  }
  .Title {
    color: var(--colors-surface-800);
    font-weight: var(--typography-font-weight-bold);
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    svg {
      margin-inline-start: 8px;
    }
  }
  .ProductList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
  .ProductWrapper {
    border: 1px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);
    position: relative;
    cursor: pointer;
    &.ProductChecked {
      border-color: var(--colors-surface-400);
      .ProductName {
        border-color: var(--colors-surface-400);
        color: var(--colors-surface-900-p);
      }
    }
    &:hover {
      .TAIcon path, .TAIcon rect {
        stroke: var(--colors-surface-800);
      }
      .ProjectIcon {
        & rect {
          fill: var(--colors-surface-800);
        }
        & path {
          stroke: var(--colors-surface-800);
        }
      }
    }
  }
  .CheckIconWrapper {
    position: absolute;
    inset-inline-start: 12px;
    top: 12px;
  }
  .BGIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px;
  }
  .ProductName {
    border-top: 1px solid var(--colors-surface-150);
    text-align: center;
    padding: 15px 0;
    color: var(--colors-surface-600);
  }
`;

const AssociateEmployeeToProduct = (props: AssociateEmployeeToProductProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [allowedProducts, setAllowedProducts] = useState<Feature[]>([]);

  const isAssociated = (feature: Feature): boolean => props.associateProducts.includes(feature);
  const toggleProduct = (feature: Feature): void => {
    if (isAssociated(feature)) props.setAssociateProducts([...props.associateProducts].filter((i) => i !== feature));
    else props.setAssociateProducts([...props.associateProducts, feature]);
  };
  const getProductIcon = (feature: Feature) => {
    if (feature === Feature.TA) {
      return isAssociated(feature) ? <TAActiveIcon /> : <TAIcon className={styles.TAIcon} />;
    }
    if (feature === Feature.Project) {
      return isAssociated(feature) ? <ProjectActiveIcon /> : <ProjectIcon className={styles.ProjectIcon} />;
    }
    return null;
  };

  useEffect(() => {
    const productItems = BillingService.getAllowedItems();
    const isManagerRole = BillingService.isCompanyManagement();
    let products;
    if (isManagerRole) products = productItems;
    else if (props.isUpgrade) products = props.associateProducts;
    else {
      const { profile } = window.global_store;
      products = profile?.associate_products || productItems;
    }
    setAllowedProducts(products);
    if (products.length === 1) !isAssociated(products[0]) && toggleProduct(products[0]);
  }, []);

  if (allowedProducts.length <= 1) return null;

  return (
    <div className={styles.AssociateWrapper}>
      <div className={styles.Title}>
        <div>{t("SUBSCRIPTION PLAN")}</div>
        <InfoIcon data-tip data-for="subscription-info-icon" />
        <Tooltip id="subscription-info-icon" offset={{ top: -8 }}>
          {translateEmployeeTerm(
            t,
            TranslationNamespaces.subscription,
            "custom-subscription-plan-tooltip",
            "Select a subscription plan to which the employee will be assigned",
          )}
        </Tooltip>
      </div>
      <div className={styles.ProductList}>
        {allowedProducts.map((feature) => (
          <div
            className={`${styles.ProductWrapper} ${isAssociated(feature) ? styles.ProductChecked : ""}`}
            key={feature}
            onClick={() => toggleProduct(feature)}
          >
            <div className={styles.CheckIconWrapper}>{isAssociated(feature) ? <CheckActiveIcon /> : <CheckIcon />}</div>
            <div className={styles.BGIcon}>{getProductIcon(feature)}</div>
            <div className={styles.ProductName}>{t(FeatureLabel[feature])}</div>
          </div>
        ))}
      </div>
      {props.error && <ErrorLabel>{props.error}</ErrorLabel>}
    </div>
  );
};

export default AssociateEmployeeToProduct;
