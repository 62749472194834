import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getSystemName } from "utils/common";
import defaultPageImg from "img/requests-settings-default.png";
import { TranslationNamespaces } from "types/translationNamespaces";

const DefaultPageWrapper = styled.div`
  display: flex;
  padding: 24px 24px var(--component-menu-item-toggle-indent-hpad, 32px) 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  font-family: var(--typography-font-family-default, "Circular");
  color: var(--common-body-text-color, #3e4357);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;

  flex: 1 0 0;
`;

const DefaultPageImg = styled.img`
  width: 217px;
  height: 145px;
`;

const DefaultPageTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  color: var(--common-title-text-color, #3e4357);
  font-size: var(--size-200, 16px);
  font-style: normal;
  line-height: 20px;
`;

const Main = styled.div``;

const DefaultPageP = styled.p`
  padding: 0;
  margin: 0;
`;

const DefaultPageUl = styled.ul`
  padding: 0;
  margin: 0;
`;

const DefaultPageLi = styled.li`
  list-style-type: disc;
  margin-inline-start: 23px;
`;

const DefaultPageNew = () => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <DefaultPageWrapper>
      <DefaultPageImg alt={t("Customize Absence Requests & Vacation management")} src={defaultPageImg} />
      <DefaultPageTitle>{t("Customize Absence Requests & Vacation management")}</DefaultPageTitle>
      <Main>
        <DefaultPageP>
          {t("Managers have much more power with {{systemName}}!", {
            systemName: getSystemName(),
          })}
        </DefaultPageP>
        <DefaultPageP>
          {t(
            "In this section, you can set up the Absence Requests & Vacation tool preferences according to the company needs, totally personalized:",
          )}
        </DefaultPageP>
        <DefaultPageP>{t("You can define:")}</DefaultPageP>
        <DefaultPageUl>
          <DefaultPageLi>{t("Which informations must be mandatory")}</DefaultPageLi>
          <DefaultPageLi>{t("Allow comments and documents attachment")}</DefaultPageLi>
          <DefaultPageLi>{t("Which types of requests are paid")}</DefaultPageLi>
          <DefaultPageLi>{t("The hierarchy of those responsible for approving requests")}</DefaultPageLi>
          <DefaultPageLi>{t("Export to other platforms")}</DefaultPageLi>
          <DefaultPageLi>{t("Create totally new customized fields")}</DefaultPageLi>
        </DefaultPageUl>
        <DefaultPageP>
          {t("The employee’s Requests screen on the app is updated according to the settings.")}
        </DefaultPageP>
      </Main>
    </DefaultPageWrapper>
  );
};

export default DefaultPageNew;
