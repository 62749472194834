export interface InjectedTaskData {
  projectName?: string;
  taskSource?: string;
  injectedTaskName: string;
  taskId?: string;
  projectId?: string;
  sectionId?: string;
  taskName?: string;
  url: string;
  startTime?: number;
  date?: string;
}

export interface RunningActivityData {
  injectedTaskName?: string;
  injectedProjectName?: string;
  url?: string;
  taskSource?: string;
  taskName?: string;
  taskId?: string;
  createdAt: string;
}

export interface TaskExternalAttributeSummaryRecord {
  taskName: string;
  externalAttributeValue: string;
  taskExternalId: string;
  projectExternalId: string;
  externalAttributeExternalId: string;
  duration: number;
}

export interface ActivityChangedData {
  taskUuid?: string;
  taskName?: string;
  taskSource?: string;
}

export enum InjectedIframeMessageType {
  ChangeVisibility = "ChangeVisibility",
  StartActivityBg = "StartActivityBg",
  AddActivity = "AddActivity",
  OnUpdateRunningActivity = "OnUpdateRunningActivity",
  MessageFromTaskSummary = "MessageFromTaskSummary",
  ExtensionInitialized = "ExtensionInitialized",
  UpdateSummariesOnActivitiesChange = "UpdateSummariesOnActivitiesChange",
  RefetchTaskSummary = "RefetchTaskSummary",
  FetchExternalAttributesSummary = "FetchExternalAttributesSummary",
  FetchProjectSummary = "FetchProjectSummary",
  SaveExternalAttributesSummary = "SaveExternalAttributesSummary",
  SaveProjectSummary = "SaveProjectSummary",
  OnActivityPlatformNameDetected = "OnActivityPlatformNameDetected",
  HideWindow = "HideWindow", // For Electron App
  LoginRedirect = "LoginRedirect", // For Electron App
}

export enum MessageFromTaskSummaryTypes {
  ChangeHeight = "ChangeHeight",
  StartActivity = "StartActivity",
  StopActivity = "StopActivity",
  AddActivity = "AddActivity",
}

export interface MessageFromTaskSummaryPayload {
  type: MessageFromTaskSummaryTypes;
  height?: number;
  injectedTaskData?: InjectedTaskData;
}

export interface MessageEventData {
  type: InjectedIframeMessageType;
  payload?: MessageFromTaskSummaryPayload;
  activityChangedData?: ActivityChangedData;
  visible?: boolean;
  injectedTaskData?: InjectedTaskData;
  runningActivityData?: RunningActivityData;
  activityPlatformName?: string | null;
  externalAttributesValues?: string[];
  projectId?: string;
  source?: string;
  taskExternalAttributesSummary?: TaskExternalAttributeSummaryRecord[];
  projectDuration?: number;
  styles?: any;
  skipAutodetect?: boolean;
  skipLoadRunningActivity?: boolean;
}
