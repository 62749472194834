import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import Debug from "debug";
import { PunchType } from "types/models/punches";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { useAsyncCallbackCached } from "utils/useAsyncCallbackCached";
import { useInitialLoading } from "utils/useInitialLoading";
import { useProfile } from "context/user-profile-context";
import { usePunchNowService, useWidgetProjectsService, useProjectsService } from "utils/useServices";
import { useDialog } from "utils/useDialog";
import { hrsMinsToMins } from "utils/common";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SyncIndicator } from "../SyncIndicator";
import { PunchCreate, PunchFinished, PunchFinishedProps, Modal } from "..";
import { EventTrackingServiceInterface, PunchTypeObject, Location } from "./types";
import {
  ActivityStoppedOnPunchOutDialog,
  ActivityIncompleteStoppedOnPunchOutDialog,
  SuggestStartActivityDialog,
} from "../ModalDialog";
import { PunchTypeOption } from "../PunchTypeSelector";

const debug = Debug("Punches");

enum ScreenType {
  create,
  finish,
}

export interface PunchProps {
  eventTrakingService: EventTrackingServiceInterface;
  onClose: () => void;
}
export function Punches({ eventTrakingService, onClose }: PunchProps) {
  if (!navigator?.onLine) {
    throw new Error("connection-offline");
  }
  const punchNowService = usePunchNowService();
  const widgetProjectsService = useWidgetProjectsService();
  const projectsService = useProjectsService();
  const [screen, setScreen] = useState<ScreenType>(ScreenType.create);
  useEffect(() => eventTrakingService.trackInit(), [eventTrakingService]);
  useEffect(() => {
    debug("mount", new Date().toISOString());
    return () => debug("unmount");
  }, []);

  const profile = useProfile();

  // --- init
  const [loadData, loadingData, data, loadingDataBg] = useAsyncCallbackCached(
    async () => {
      debug("loadData");
      const res = await Promise.all([
        punchNowService.getEmployeeProfile(),
        punchNowService.getLocations(),
        projectsService.getCompanyRules(),
      ]);
      debug("loadedData");
      return res;
    },
    [punchNowService, projectsService],
    {
      key: "punches.loadData",
      vary: profile!.uuid,
      ttl: "P3D",
      delay: 400,
    },
  );
  const [location, setLocation] = useState<any>(null);
  const [availableLocations, setAvailableLocations] = useState<any[]>([]);
  const [employeeProfile, setEmployeeProfile] = useState<any>(null);
  const [companyRules, setCompanyRules] = useState<any>(null);
  useEffect(() => {
    if (data == null) {
      return;
    }
    const [emplProfile, { locations, defaultLocation }, companyRulez] = data;
    setLocation(defaultLocation);
    setAvailableLocations(locations);
    setEmployeeProfile(emplProfile);
    setCompanyRules(companyRulez);
  }, [data]);
  useEffect(() => void loadData(), [loadData]);

  // --- time verification
  const [timeVerified, setTimeVerified] = useState<boolean>(true);
  const delta = useRef(0);
  const [verifyTime, verifyingTime] = useAsyncCallback(async () => {
    const d = Date.now() - performance.now();
    const deltaDiff = Math.abs(delta.current - d);
    delta.current = d;
    const maxOutOfSyncMs = 30e3;
    if (deltaDiff > maxOutOfSyncMs) {
      debug("verifyTime");
      const isTimeVerified = await punchNowService.verifyTime(new Date());
      setTimeVerified(isTimeVerified);
      debug("verifiedTime");
    }
  }, [punchNowService]);
  useEffect(() => {
    void verifyTime();
    const intervalId = setInterval(() => void verifyTime, 2000);
    return () => clearInterval(intervalId);
  }, [verifyTime]);

  // --- time
  const [date, setDate] = useState(new Date());
  const [ticking, setTicking] = useState(true);
  useEffect(() => {
    if (!ticking) return undefined;
    const intervalId = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, [ticking]);

  const [timeStr, setTimeStr] = useState<string>("");
  const [dateStr, setDateStr] = useState<string>("");
  useEffect(() => {
    if (!location) return;
    const m = moment.tz(date, location.timezone);
    const t = m.format("HH:mm");
    const d = m.format("YYYY-MM-DD");
    setTimeStr(t);
    setDateStr(d);
  }, [date, location]);

  const handleTimeEditFinished = (HHmm: string) => {
    if (HHmm === ":") {
      setTimeStr(HHmm);
      setTicking(true);
      setDate(new Date());
      return;
    }
    const str = `${moment(date).format("YYYY-MM-DD")} ${HHmm}`;
    const t = moment.tz(str, "YYYY-MM-DD HH:mm", location.timezone).toDate();
    setDate(t);
  };

  // --- location verification
  const [locationVerified, setLocationVerified] = useState<boolean>(true);
  const [verifyLocation, verifyingLocation] = useAsyncCallback(async () => {
    if (!location) return;
    debug("verifyLocation");
    const isVerified = await punchNowService.verifyLocation(location);
    setLocationVerified(isVerified);
    debug("verifiedLocation");
  }, [location, punchNowService]);
  useEffect(() => void verifyLocation(), [verifyLocation]);

  // --- punchType
  const [availablePunchTypes, setAvailablePunchTypes] = useState<any>([]);
  const [punchType, setPunchType] = useState<PunchFinishedProps["selectedPunchType"] | null>(null);

  const createdPunch = useRef<any>(null);

  // --- stop running activity on punch out or by schedule
  const history = useHistory();
  const [showingViewStoppedDialog, askViewStopped, respondViewStopped] = useDialog<boolean>();
  const [showingFillSemistoppedDialog, askFillSemistopped, respondFillSemistopped] = useDialog<boolean>();
  const [showingSuggestStartActivityDialog, askSuggestStartActivity, respondSuggestStartActivity] =
    useDialog<boolean>();

  const [runningActivity, setRunningActivity] = useState<any>(null);

  const [stopActivityWithDialog] = useAsyncCallback(
    async (actToStop) => {
      const validationErrors = widgetProjectsService.validateActivity(actToStop);
      const canStop = validationErrors == null;

      if ((actToStop.status === "running" && actToStop.taskUuid === null) || !canStop) {
        const shouldStop = await askFillSemistopped();
        if (shouldStop) {
          history.push(`projects/running`);
        } else {
          // shouldRemove
          await widgetProjectsService.deleteActivity(actToStop);
          setRunningActivity(null);
          onClose();
        }
      } else {
        const res = await askViewStopped();
        if (res) {
          history.push(`projects/${actToStop.uuid}`);
        } else {
          onClose();
        }
      }
    },
    [widgetProjectsService, askViewStopped, askFillSemistopped, history, onClose],
  );

  const [handleFinish] = useAsyncCallback(async () => {
    const isPunchOut = punchType?.type === PunchType.breakStart || punchType?.type === PunchType.exit;
    const shouldStopRunningActivity = runningActivity && isPunchOut;
    const shouldSuggestStartActivity =
      runningActivity == null && !isPunchOut && companyRules.suggestStartActivityBasedOnPunchIn;

    eventTrakingService.trackPunchFinish &&
      eventTrakingService.trackPunchFinish((punchType as PunchTypeOption)?.type as PunchType);

    if (shouldStopRunningActivity) {
      await stopActivityWithDialog(runningActivity);
      return;
    }
    if (shouldSuggestStartActivity) {
      const shouldStartActivity = await askSuggestStartActivity();
      if (shouldStartActivity) {
        history.push(`projects/start-activity`);
        return;
      }
    }

    onClose();
  }, [
    stopActivityWithDialog,
    onClose,
    punchType,
    runningActivity,
    companyRules,
    askSuggestStartActivity,
    history,
    eventTrakingService,
  ]);

  const [loadRunningActivity, loadingRunningActivity] = useAsyncCallback(async () => {
    const runningAct = await widgetProjectsService.getRunningActivity();
    setRunningActivity(runningAct);
  }, [widgetProjectsService]);

  useEffect(() => void loadRunningActivity(), [loadRunningActivity]);

  const [checkOutscheduled] = useAsyncCallback(async () => {
    const isSemistopped = runningActivity?.stopReason != null;
    if (isSemistopped) return;
    const predictedEndAt = runningActivity?.predictedEndAt == null ? null : new Date(runningActivity.predictedEndAt);
    if (predictedEndAt == null) return;
    const now = moment();
    const isOutscheduled = now.isAfter(predictedEndAt);
    if (isOutscheduled) {
      const endTime = hrsMinsToMins(moment(predictedEndAt).format("HH:mm"));
      const actToStop = { ...runningActivity, endTime, stopReason: "schedule", status: "pending" };
      await widgetProjectsService.stopActivity(actToStop);
    }
  }, [runningActivity, widgetProjectsService]);

  // --- check for semistopped activity
  const routeMatch = useRouteMatch();
  const [checkSemistopped] = useAsyncCallback(async () => {
    const isSemistopped = runningActivity?.stopReason != null;
    if (!isSemistopped) return;
    const res = await askFillSemistopped();
    if (res) {
      history.push(`projects/running`);
    } else {
      await widgetProjectsService.deleteActivity(runningActivity);
      setRunningActivity(null);
      history.push(routeMatch.path);
    }
  }, [runningActivity, widgetProjectsService, askFillSemistopped, history, routeMatch]);

  useEffect(() => {
    void checkSemistopped();
    void checkOutscheduled();
  }, [checkSemistopped, checkOutscheduled]);

  const [stoppedActivity, setStoppedActivity] = useState<any>(null);
  const [stopRunningActivity, stoppingRunningActivity] = useAsyncCallback(
    async (stopReason) => {
      const runningAct = await widgetProjectsService.getRunningActivity();
      const isSemistopped = runningAct?.stopReason != null;
      if (runningAct == null || isSemistopped) return;
      const punch = createdPunch.current;
      const endTimeMom = moment(punch.date);
      const endTime = hrsMinsToMins(endTimeMom.format("HH:mm"));
      const actToStop = { ...runningAct, endTime, stopReason, status: "pending" };
      await widgetProjectsService.stopActivity(actToStop);
      setStoppedActivity(actToStop);
    },
    [widgetProjectsService, setStoppedActivity],
  );

  // --- punch create
  const [createPunch, creatingPunch] = useAsyncCallback(async () => {
    setTicking(false);
    setScreen(ScreenType.finish);

    debug("createPunch");
    const { punchTypes, assumedPunchType } = await punchNowService.getPunchTypes(date, location.timezone);
    setAvailablePunchTypes(punchTypes);
    setPunchType(assumedPunchType);

    const punch = {
      punchType: assumedPunchType,
      date,
      location,
      isVerified: locationVerified && timeVerified,
      isLocationVerified: locationVerified,
      isTimeVerified: timeVerified,
    };
    createdPunch.current = await punchNowService.createPunch(punch);
    debug("createdPunch");
    eventTrakingService.trackPunch(punch);
    const isPunchOut = assumedPunchType.type === PunchType.breakStart || assumedPunchType.type === PunchType.exit;
    if (isPunchOut) {
      void stopRunningActivity(assumedPunchType.type);
    }
  }, [date, eventTrakingService, location, locationVerified, punchNowService, timeVerified, stopRunningActivity]);

  // --- punch type update
  const [updatePunchType, updatingPunchType] = useAsyncCallback(
    async (pnchTyp: PunchTypeObject | null) => {
      eventTrakingService.trackPunchTypeUpdate(createdPunch.current, pnchTyp);
      if (pnchTyp === null) return;
      setPunchType(pnchTyp);
      await punchNowService.updatePunchType(createdPunch.current, pnchTyp);
      const isPunchOut = pnchTyp.type === PunchType.breakStart || pnchTyp.type === PunchType.exit;
      if (isPunchOut) {
        void stopRunningActivity(pnchTyp.type);
      } else if (stoppedActivity) {
        const resumedActivity = await widgetProjectsService.resumeActivity(stoppedActivity);
        setRunningActivity(resumedActivity);
      }
    },
    [eventTrakingService, punchNowService, stopRunningActivity, stoppedActivity, widgetProjectsService],
  );

  // --- wireframe loading
  const wireframeLoading = useInitialLoading(loadingData, verifyingLocation, verifyingTime, loadingRunningActivity);
  const loading = updatingPunchType || verifyingLocation || verifyingTime;

  return (
    <>
      {screen === ScreenType.create && (
        <>
          <PunchCreate
            loading={loading}
            initialLoading={wireframeLoading}
            timeVerified={timeVerified}
            locationVerified={locationVerified}
            employeeName={employeeProfile?.name}
            time={timeStr}
            date={dateStr}
            onTimeEdit={() => setTicking(false)}
            onTimeEditFinished={handleTimeEditFinished}
            punchTypeName={PunchType.entry} // we don't show it anywhere
            onCreate={createPunch}
            locations={availableLocations}
            selectedLocation={location}
            onLocationSelected={(loc: Location | null) => {
              eventTrakingService.trackLocationChange(loc);
              if (loc === null) return;
              setLocation(loc);
              setTicking(true);
              setDate(new Date());
            }}
          />
          {loadingDataBg && <SyncIndicator />}
        </>
      )}
      {screen === ScreenType.finish && (
        <Modal style={{ background: "none", height: "100%" }}>
          <PunchFinished
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "var(--colors-surface-0)",
            }}
            loading={loading || stoppingRunningActivity}
            wireframe={creatingPunch}
            employeeName={employeeProfile?.name}
            needsApproval={!(locationVerified && timeVerified)}
            location={location}
            createdAt={{ date, timezone: location?.timezone }}
            selectedPunchType={punchType!}
            punchTypes={availablePunchTypes}
            onPunchTypeSelected={updatePunchType}
            onConfirm={() => void handleFinish()}
          />
        </Modal>
      )}
      {showingViewStoppedDialog && <ActivityStoppedOnPunchOutDialog onResult={respondViewStopped} />}
      {showingFillSemistoppedDialog && <ActivityIncompleteStoppedOnPunchOutDialog onResult={respondFillSemistopped} />}
      {showingSuggestStartActivityDialog && <SuggestStartActivityDialog onResult={respondSuggestStartActivity} />}
    </>
  );
}
