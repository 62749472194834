import React, { useContext, useEffect, useState } from "react";
import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import moment from "moment";
import GlobalContext from "context/global-context";
import { SubscriptionApi } from "utils/api/subscription";
import { InvoiceResponse } from "types/models/subscription";
import TablePage from "components/TablePage";
import { iCellInfo } from "utils/tableHelpers";
import Pagination from "components/Pagination";
import { TableButtons } from "components/styled/Page";
import { iColumn } from "components/TableCommon";
import NoContent from "components/NoContent";

const styles = stylesheet`@import "./invoice-list.scss"`;

const InvoiceList = () => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const context = useContext(GlobalContext);
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginatedInvoices, setPaginatedInvoices] = useState<InvoiceResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const paginateInvoices = () => {
    const paginated = invoices.slice(pageSize * (currentPage - 1), (pageSize * currentPage));
    setPaginatedInvoices(paginated);
  }

  const getInvoices = async () => {
    const company = await context.getCompany();
    const res = await SubscriptionApi.getInvoices(company.uuid);
    setInvoices(res);
    setLoading(false);
  }
  useEffect(() => {
    getInvoices();
  }, []);

  useEffect(() => {
    paginateInvoices();
  }, [currentPage, invoices, pageSize]);

  const columns: iColumn<InvoiceResponse>[] = [
    {
      accessor: "created",
      label: t("Date"),
      align: "start",
      minWidth: 150,
      Cell: (row: iCellInfo<InvoiceResponse>): JSX.Element | string => moment(row.value * 1000).format("MMM DD, YYYY"),
      style: { lineHeight: "36px" },
    },
    {
      accessor: "interval",
      label: t("Description"),
      align: "start",
      minWidth: 150,
      Cell: (row: iCellInfo<InvoiceResponse>): JSX.Element | string => t(`invoice_description_${row.value}`),
    },
    {
      accessor: "total",
      label: t("Invoice Total"),
      minWidth: 150,
      align: "end",
      Cell: (row: iCellInfo<InvoiceResponse>): JSX.Element | string => (row.value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: row.original.currency,
      }),
    },
    {
      accessor: "status",
      label: t("Status"),
      minWidth: 150,
      align: "center",
      Cell: (row: iCellInfo<InvoiceResponse>) => {
        const status = row.value;
        return (
          <div>
            <div className={styles.StatusBadge}>{t(status)}</div>
            <TableButtons className="buttons">
              <a className={styles.DownloadBtn} href={row.original.invoice_pdf}>{t("Download")}</a>
            </TableButtons>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.InvoiceList}>
      <div className={styles.BlockTitle}>{t("Invoices")}</div>
      <TablePage<InvoiceResponse>
        rows={paginatedInvoices}
        columns={columns}
        loading={loading}
        className={styles.InvoiceTable}
        customColumnsAvailable={false}
        showPagination
        manual
        showPageSizeOptions={false}
        PaginationComponent={() => (
          <Pagination
            totalRecords={invoices.length}
            pageLimit={pageSize}
            pageNeighbours={0}
            currentPage={currentPage}
            onPageChanged={(changedPage) => setCurrentPage(changedPage.currentPage)}
            onPageLimitChange={(pageLimit, currentPage) => {
             setPageSize(pageLimit);
             setCurrentPage(currentPage);
            }}
          />
        )}
        noContentComponent={
          <NoContent>
              <div>{t("No invoices yet.")}</div>
          </NoContent>
        }
      />
    </div>
  );
}

export default InvoiceList;
