import NoContent from "components/NoContent";
import TablePage from "components/TablePage";
import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { iCellInfo } from "utils/tableHelpers";
import { iColumn } from "components/TableCommon";
import { TableButton, TableButtons } from "components/styled/Page";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { Project } from "types/models/projects";
import SearchInput from "components/UI/SearchInput";
import { ChangeEvent } from "react";
import { strIncludesCheck } from "utils/common";
import Sentry from "utils/sentryUtils";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { UserProfileRole } from "types/models/userProfile";
import { useProjectPageEmployeesInit } from "./projectPageEmployeesHooks";
import AssignProjectTeamRow from "./AssignProjectTeamRow";
import AvatarWithDetails from "./AvatarWithDetails";
import { ProjectTaskAssignee, RemoveAssigneeFromProjectRequestData } from "./projectsApiTypes";
import { removeAssigneeFromProject } from "./projectsApiUtils";

const Wrapper = styled.div`
  margin-top: 32px;
  .rt-tr {
    position: relative;
    .buttons {
      bottom: 10px;
    }
  }
`;

const TableWrapper = styled.div`
  margin-top: 32px;
`;

interface ProjectPageEmployeesProps {
  project: Project;
  onMakeProjectPrivate: () => void;
  onProjectUpdated: (message: string | null, notificationType: NotificationType) => void;
}

const unassignEmployee = async (projectUuid: string, assignee: ProjectTaskAssignee) => {
  const payload: RemoveAssigneeFromProjectRequestData = {
    companyUuid: window.global_store.company.uuid,
    projectUuid,
    body: {
      content: {
        requestedBy: window.global_store.profile.uuid,
      },
    },
  };

  if (assignee.isServiceGroup) {
    payload.body.content.serviceUuid = assignee.uuid;
  } else {
    payload.body.content.userProfileUuid = assignee.uuid;
  }

  await removeAssigneeFromProject(payload);
};

const useTableColumns = ({
  onUnassignButtonClick,
}: {
  onUnassignButtonClick: (val: ProjectTaskAssignee) => void;
}): iColumn<ProjectTaskAssignee>[] => {
  const { t } = useTranslation(TranslationNamespaces.projects);

  return [
    {
      Header: translateEmployeeTerm(
        t,
        TranslationNamespaces.common,
        "custom-employees",
        `${TranslationNamespaces.common}|Employees`,
      ),
      accessor: "fullName",
      Cell: (row: iCellInfo<ProjectTaskAssignee>) => (
        <div>
          <AvatarWithDetails
            title={row.original.title}
            subtitle={
              row.original.subtitle === UserProfileRole.employee
                ? translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-employee",
                    `${TranslationNamespaces.common}|Employee`,
                  )
                : row.original.subtitle
            }
            avatarId={row.original.avatarId || ""}
            isServiceGroup={row.original.isServiceGroup}
          />
          <TableButtons className="buttons">
            <TableButton
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                onUnassignButtonClick(row.original);
              }}
            >
              {t(`${TranslationNamespaces.common}|Remove`)}
            </TableButton>
          </TableButtons>
        </div>
      ),
      minWidth: 300,
    },
  ];
};

const ProjectPageEmployees = ({ project, onMakeProjectPrivate, onProjectUpdated }: ProjectPageEmployeesProps) => {
  const { t } = useTranslation(TranslationNamespaces.projects);
  const projectUuid = project.uuid;

  const [{ employeeUuidToUnassign, assignees, loading, searchValue }, setState] = useProjectPageEmployeesInit({
    projectUuid,
  });

  const columns = useTableColumns({
    onUnassignButtonClick: (val: ProjectTaskAssignee) => {
      setState({ employeeUuidToUnassign: val });
    },
  });

  const filteredItems = assignees.filter((s) => strIncludesCheck(s.title, searchValue || ""));

  return (
    <Wrapper>
      {project.isPublic && <AssignProjectTeamRow onMakeProjectPrivate={onMakeProjectPrivate} />}
      <TableWrapper>
        <TablePage<ProjectTaskAssignee>
          filters={
            <SearchInput
              modifiers={["filter"]}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => setState({ searchValue: ev.target.value })}
              placeholder={t(`${TranslationNamespaces.common}|Search`)}
              value={searchValue || ""}
            />
          }
          rows={filteredItems}
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          columns={columns}
          loading={loading}
          noContentComponent={
            <NoContent>
              {translateEmployeeTerm(
                t,
                TranslationNamespaces.common,
                "custom-please-add-employees",
                `${TranslationNamespaces.common}|Please add employees`,
              )}
            </NoContent>
          }
        />
      </TableWrapper>
      <ModalDialog isOpen={!!employeeUuidToUnassign} onClose={() => setState({ employeeUuidToUnassign: null })}>
        <Lightbox
          title={translateEmployeeTerm(
            t,
            TranslationNamespaces.projects,
            "custom-remove-project-assignee-confirmation-title",
            `${TranslationNamespaces.projects}|remove-project-assignee-confirmation-title`,
          )}
          text={translateEmployeeTerm(
            t,
            TranslationNamespaces.projects,
            "custom-remove-project-assignee-confirmation-text",
            `${TranslationNamespaces.projects}|remove-project-assignee-confirmation-text`,
          )}
          buttonYesTitle={t(`${TranslationNamespaces.common}|Yes`)}
          buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
          onClose={() => {
            setState({
              employeeUuidToUnassign: null,
            });
          }}
          onYes={async () => {
            if (employeeUuidToUnassign) {
              try {
                await unassignEmployee(projectUuid, employeeUuidToUnassign);
                onProjectUpdated(t("remove-project-assignee-success"), NotificationType.success);
              } catch (error) {
                onProjectUpdated(t("remove-project-assignee-failed"), NotificationType.error);
                Sentry.sendError(error);
              }
            }
            setState({
              employeeUuidToUnassign: null,
            });
          }}
        />
      </ModalDialog>
    </Wrapper>
  );
};

export default ProjectPageEmployees;
