import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Application, ApplicationStatus, ApplicationType } from "types/models/application";
import useState from "hooks/useState";
import { ApplicationsService } from "./ApplicationsService";
import IntegrationConnectConfirmation from "./IntegrationConnectConfirmation";

const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 272px;
  height: 88px;
  position: relative;
  gap: 16px;
  padding: 16px 20px;
  align-items: center;
  cursor: ${(p) => (p.isActive ? "default" : "pointer")};
  text-decoration: none;
  background: var(--colors-surface-0);
  border: 1px solid var(--colors-surface-150);
  border-radius: var(--shapes-border-radius-default);
  &:hover {
    box-shadow: 0px 3px 5px rgba(129, 147, 171, 0.2);
    border: 1px solid var(--colors-surface-200);
  }
`;

const LogoBox = styled.div`
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled.div<{ logoImage: string }>`
  width: 48px;
  height: 48px;
  background-image: ${(p) => p.logoImage};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Title = styled.div`
  width: 100%;
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  }
`;

const TitleDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
`;

const Description = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-600);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Badge = styled.div`
  position: absolute;
  inset-inline-end: 12px;
  top: 12px;
  padding: 3px 6px;
  border-radius: 40px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--colors-primary-500-p);
  &.new {
    color: var(--colors-success-600-p);
  }
  &.featured {
    color: var(--colors-primary-500-p);
  }
`;

interface IntegrationBoxProps {
  type: ApplicationType;
  application?: Application;
}

interface IntegrationBoxState {
  isConnectConfirmationVisible: boolean;
}

const IntegrationBox = ({ type, application }: IntegrationBoxProps) => {
  const { t } = useTranslation(TranslationNamespaces.applications);
  const isActive = application?.status === ApplicationStatus.Active;
  const integration = ApplicationsService.integrations[type];
  const [{ isConnectConfirmationVisible }, setState] = useState<IntegrationBoxState>({
    isConnectConfirmationVisible: false,
  });

  return (
    <>
      <Wrapper
        isActive={isActive}
        onClick={() => {
          setState({ isConnectConfirmationVisible: true });
        }}
      >
        {/* <Badge className="featured">{t("featured")}</Badge> */}
        <LogoBox>
          <Logo logoImage={integration.logo} />
        </LogoBox>
        <TitleDescriptionBox>
          <Title>{integration.name}</Title>
          <Description>{t("Project Management")}</Description>
        </TitleDescriptionBox>
      </Wrapper>
      <IntegrationConnectConfirmation
        integration={integration}
        isVisible={isConnectConfirmationVisible}
        onClose={() => setState({ isConnectConfirmationVisible: false })}
      />
    </>
  );
};

export default IntegrationBox;
