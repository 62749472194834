const schedules = {
  flexible: {
    uuid: "{{schedule_uuid}}",
    CompanyUuid: "{{company_uuid}}",
    scheduleType: "flexible",
    createdBy: "892bbafa-1752-4033-9979-286bea7174c8",
    active: true,
    recurrencePeriod: "weekly",
    options: {},
    scheduleDays: [
      {
        dayId: 1,
        dayName: "monday",
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            obligatory: true,
          },
          {
            type: "exit",
            time: null,
            duration: 540,
            obligatory: true,
          },
        ],
      },
      {
        dayId: 2,
        dayName: "tuesday",
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            obligatory: true,
          },
          {
            type: "exit",
            time: null,
            duration: 540,
            obligatory: true,
          },
        ],
      },
      {
        dayId: 3,
        dayName: "wednesday",
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            obligatory: true,
          },
          {
            type: "exit",
            time: null,
            duration: 540,
            obligatory: true,
          },
        ],
      },
      {
        dayId: 4,
        dayName: "thursday",
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            obligatory: true,
          },
          {
            type: "exit",
            time: null,
            duration: 540,
            obligatory: true,
          },
        ],
      },
      {
        dayId: 5,
        dayName: "friday",
        working: true,
        events: [
          {
            type: "entry",
            time: null,
            duration: null,
            obligatory: true,
          },
          {
            type: "exit",
            time: null,
            duration: 540,
            obligatory: true,
          },
        ],
      },
      {
        dayId: 6,
        dayName: "saturday",
        working: false,
        events: [],
      },
      {
        dayId: 7,
        dayName: "sunday",
        working: false,
        events: [],
      },
    ],
  },
  shifts: {
    uuid: "{{schedule_uuid}}",
    CompanyUuid: "{{company_uuid}}",
    scheduleType: "shifts",
    createdBy: "892bbafa-1752-4033-9979-286bea7174c8",
    active: true,
    recurrencePeriod: "daily",
    options: {},
    scheduleDays: [],
  },
  regular: {
    uuid: "{{schedule_uuid}}",
    CompanyUuid: "{{company_uuid}}",
    scheduleType: "regular",
    createdBy: "892bbafa-1752-4033-9979-286bea7174c8",
    active: true,
    recurrencePeriod: "weekly",
    options: {},
    scheduleDays: [
      {
        dayId: 1,
        dayName: "monday",
        working: true,
        events: [],
      },
      {
        dayId: 2,
        dayName: "tuesday",
        working: true,
        events: [],
      },
      {
        dayId: 3,
        dayName: "wednesday",
        working: true,
        events: [],
      },
      {
        dayId: 4,
        dayName: "thursday",
        working: true,
        events: [],
      },
      {
        dayId: 5,
        dayName: "friday",
        working: true,
        events: [],
      },
      {
        dayId: 6,
        dayName: "saturday",
        working: false,
        events: [],
      },
      {
        dayId: 7,
        dayName: "sunday",
        working: false,
        events: [],
      },
    ],
  },
  regularEmpty: {
    scheduleType: "regular",
    name: "",
    active: true,
    recurrencePeriod: "weekly",
    options: {},
    scheduleDays: [
      {
        dayId: 1,
        working: false,
        events: [],
      },
      {
        dayId: 2,
        working: false,
        events: [],
      },
      {
        dayId: 3,
        working: false,
        events: [],
      },
      {
        dayId: 4,
        working: false,
        events: [],
      },
      {
        dayId: 5,
        working: false,
        events: [],
      },
      {
        dayId: 6,
        working: false,
        events: [],
      },
      {
        dayId: 7,
        working: false,
        events: [],
      },
    ],
  },
};

export default schedules;
