import { sendRequest, APIResponse } from "./common";
import CONFIG from "../../config";
import {
  GetIcalCalendarsRequestData,
  GetIcalCalendarsResponseData,
  CreateIcalCalendarRequestData,
  CreateIcalCalendarResponseData,
  RegenerateIcalCalendarRequestData,
  RegenerateIcalCalendarResponseData,
  UpdateIcalCalendarRequestData,
  UpdateIcalCalendarResponseData,
  DeleteIcalCalendarRequestData,
  DeleteIcalCalendarResponseData,
} from "./types";

type TODORequestData = unknown;
type TODOResponseData = Promise<unknown>;

export function updateRequest(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/requests/${data.requestUuid}`,
    CONFIG.client_admin_api,
  );
}

export function deleteAttachmentNew(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUUID}/requests/${data.requestUUID}/attachments/${data.attachmentUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getIcalCalendars(data: GetIcalCalendarsRequestData): APIResponse<GetIcalCalendarsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUUID}/calendars/user_profiles/${data.userProfileUUID}`,
    CONFIG.client_admin_api,
  );
}

export function createIcalCalendar(data: CreateIcalCalendarRequestData): APIResponse<CreateIcalCalendarResponseData> {
  const { companyUUID, userProfileUUID, ...payload } = data;

  return sendRequest(
    payload,
    "POST",
    `/companies/${companyUUID}/calendars/user_profiles/${userProfileUUID}`,
    CONFIG.client_admin_api,
  );
}

export function regenerateIcalCalendar(
  data: RegenerateIcalCalendarRequestData,
): APIResponse<RegenerateIcalCalendarResponseData> {
  return sendRequest(
    {},
    "PUT",
    `/companies/${data.companyUUID}/calendars/regenerate/user_profiles/${data.userProfileUUID}/calendar/${data.calendarUUID}`,
    CONFIG.client_admin_api,
  );
}

export function updateIcalCalendar(data: UpdateIcalCalendarRequestData): APIResponse<UpdateIcalCalendarResponseData> {
  const { companyUUID, userProfileUUID, calendarUUID, ...payload } = data;

  return sendRequest(
    payload,
    "PUT",
    `/companies/${companyUUID}/calendars/user_profiles/${userProfileUUID}/calendar/${calendarUUID}`,
    CONFIG.client_admin_api,
  );
}

export function deleteIcalCalendar(data: DeleteIcalCalendarRequestData): APIResponse<DeleteIcalCalendarResponseData> {
  const { companyUUID, userProfileUUID, calendarUUID } = data;

  return sendRequest(
    {},
    "DELETE",
    `/companies/${companyUUID}/calendars/user_profiles/${userProfileUUID}/calendar/${calendarUUID}`,
    CONFIG.client_admin_api,
  );
}
