import { Component } from "react";
import BEM from "utils/BEM";
// TODO styled
import "./CheckboxControl.scss";

interface CheckboxControlProps {
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  modifiers: Record<string, boolean>;
  osx?: boolean;
}

class CheckboxControl extends Component<CheckboxControlProps> {
  static defaultProps = {
    modifiers: {
      small: false,
    },
  };

  onChange = () => {
    const { disabled, onChange, checked } = this.props;
    if (!disabled && onChange) {
      onChange(!checked);
    }
  };

  render() {
    const { disabled, checked, modifiers, osx } = this.props;
    let b = BEM.b("checkbox-ios-conrtrol");

    if (osx) {
      b = BEM.b("checkbox-oxs-conrtrol");
    }

    return (
      <div className={b("wrapper", { checked, ...modifiers })}>
        <label>
          <input
            onChange={this.onChange}
            checked={checked || false}
            type="checkbox"
            readOnly
            disabled={disabled}
            className={b({ disabled, ...modifiers })}
          />
          <span />
        </label>
      </div>
    );
  }
}

export default CheckboxControl;
