import { Component, ContextType, PropsWithChildren } from "react";
import { requestSubTypeCreate } from "utils/apiHelpers";
import NewItem, { NewItemProps } from "components/controls/NewItem";
import GlobalContext from "context/global-context";
import "components/UI/Page/Page.scss";
import "styles/table-common.scss";
import { RequestHoursCalculationType, RequestSubTypeLimitType, RequestType } from "types/models/request";

interface NewRequestCategoryProps extends PropsWithChildren {
  onCategoryAdded: () => void;
  allCategories: RequestType[];
  typeName: unknown;
}

class NewCategory extends Component<NewRequestCategoryProps> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  static defaultProps = {
    allCategories: [],
  };

  createSubtype: NewItemProps["onApply"] = async ({ value, callBackFail, callBackSuccess }) => {
    const { onCategoryAdded, allCategories, typeName } = this.props;

    if (!allCategories.some((r) => r.name === value)) {
      const roles = await this.context.getRoles();

      const supervisor = roles.find((r) => r.name.toLowerCase() === "supervisor");
      const owner = roles.find((r) => r.name.toLowerCase() === "owner");
      const approverUuid = supervisor?.uuid || owner?.uuid;

      await requestSubTypeCreate({
        companyUUID: window.global_store.company.uuid,
        typeName,
        body: {
          content: {
            requestType: typeName,
            name: value,
            comment: {
              active: false,
              required: false,
            },
            customFields: [],
            attachment: {
              active: false,
              required: false,
              lockAfterDays: null,
            },
            hoursCalculationType: RequestHoursCalculationType.noCompensation,
            approvalFlow: [
              {
                limit: null,
                stage: 0,
                approver: {
                  type: "role",
                  uuid: approverUuid,
                },
                limitType: RequestSubTypeLimitType.month,
                autoApproval: false,
              },
            ],
            createdBy: window.global_store.profile.uuid,
          },
        },
      });

      onCategoryAdded();
      callBackSuccess();
    } else {
      callBackFail();
    }
  };

  render() {
    return <NewItem applyRegex={/[[|\]]*/g} onApply={this.createSubtype} label={this.props.children} />;
  }
}

export default NewCategory;
