import { SearchControlItemType } from "components/UI/SearchControlNew";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";

const DropdownWrapper = styled.div`
  position: absolute;
  width: 320px;
  padding: 8px;
  z-index: 10;
  background-color: var(--colors-surface-0);
  box-shadow: 0 10px 30px 0 rgb(129 147 171 / 20%);
`;

const DropdownOption = styled.div`
  padding: 12px;
  font-size: 15px;
  color: var(--colors-surface-800);
  cursor: pointer;

  &:hover {
    background-color: var(--colors-surface-50);
  }
`;

interface GroupsDropdownProps {
  onClick: (value: SearchControlItemType) => void;
}

const GroupsDropdown = (props: GroupsDropdownProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  const onClick = (value: SearchControlItemType) => {
    props.onClick(value);
  };

  return (
    <DropdownWrapper className="groups-dropdown">
      <DropdownOption onClick={() => onClick(SearchControlItemType.department)}>{t("All Departments")}</DropdownOption>
      <DropdownOption onClick={() => onClick(SearchControlItemType.team)}>{t("All Teams")}</DropdownOption>
      <DropdownOption onClick={() => onClick(SearchControlItemType.subsidiary)}>{t("All Subsidiaries")}</DropdownOption>
    </DropdownWrapper>
  );
};

export default GroupsDropdown;
