import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useState } from "react";
import ModalDialog from "components/UI/ModalDialog/ModalDialog";
import { Footer, Header } from "components/UI/ModalDialog/components";
import styled from "styled-components";
import Button from "../UI/ButtonComponent";
import RequestSettings from "./RequestSettings";

interface RequestSettingsModalProps {
  onClose: () => void;
}

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--component-button-group-confirm-normal-gap, 12px);
`;

const RequestSettingsModal = ({ onClose }: RequestSettingsModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [yesFn, setYesFn] = useState<{ fn: (() => Promise<boolean>) | null; disabled: boolean }>({
    fn: null,
    disabled: false,
  });

  const onYes = async () => {
    let result = true;
    if (yesFn.fn) {
      result = await yesFn.fn();
    }

    if (result) {
      onClose();
    }
  };

  return (
    <ModalDialog isOpen onClose={onClose} width={798} top="80px" dark>
      <Header title={t("Requests Settings")} onClose={onClose} />
      <RequestSettings setOnSaveFn={setYesFn} />
      <Footer>
        <ButtonGroup data-tip data-for="buttons">
          <Button
            size="large"
            variant="secondary"
            label={t(`${TranslationNamespaces.common}|Cancel`)}
            color="neutral"
            onClick={onClose}
          />
          <Button
            size="small"
            variant="primary"
            label={t(`${TranslationNamespaces.common}|Save`)}
            color="main"
            onClick={onYes}
          />
        </ButtonGroup>
      </Footer>
    </ModalDialog>
  );
};

export default RequestSettingsModal;
