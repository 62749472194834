import styled, { CSSProperties } from "styled-components";
import classNames from "classnames";

export enum StatusTagType {
  declined = "declined",
  pending = "pending",
  new = "new",
  default = "default", // called "canceled" in figma destign
  inactive = "inactive",
  active = "active",
  suspended = "suspended", // in figma destign. TODO: implement
  nopaidabsence = "nopaidabsence", // in figma destign. TODO: implement
  paidabsence = "paidabsence", // in figma destign. TODO: implement
  //
  // warn = "warn",
  // success = "success",
  // signed = "signed",
  // completed = "completed",
  // approved = "approved",
}

const StyledDiv = styled.div<{ type?: StatusTagType }>`
  --font-weight: 500;
  --font-size: 12px;

  &.tag-type-declined {
    --text-color: var(--component-badge-status-negative-text-color, #da3837);
    --bg-color: var(--component-badge-status-negative-bg-color, rgba(245, 63, 62, 0.06));
  }

  &.tag-type-pending {
    --text-color: var(--component-badge-status-warning-text-color, #c49401);
    --bg-color: var(--component-badge-status-warning-bg-color, rgba(235, 177, 1, 0.12));
  }

  &.tag-type-new {
    --text-color: var(--component-badge-status-new-text-color, #0780e0);
    --bg-color: var(--component-badge-status-new-bg-color, rgba(30, 151, 247, 0.12));
  }

  &.tag-type-default {
    --text-color: var(--component-badge-status-neutral-text-color, #3e4357);
    --bg-color: var(--component-badge-status-neutral-bg-color, #f7f7f7);
  }

  &.tag-type-inactive {
    --text-color: var(--component-badge-status-inactive-text-color, #757e9c);
    --bg-color: var(--component-badge-status-inactive-bg-color, #fff);
    border: var(--component-badge-common-border, 1px) solid var(--component-badge-status-inactive-border-color, #e1e3eb);
  }

  &.tag-type-active {
    --text-color: var(--component-badge-status-positive-text-color, #00a15c);
    --bg-color: var(--component-badge-status-positive-bg-color, rgba(0, 202, 115, 0.12));
  }

  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: 16px;
  letter-spacing: 0.4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: var(--component-badge-status-height, 20px);
  padding: 0px var(--component-badge-status-hpad, 8px);
  text-transform: uppercase;
  color: var(--text-color);
  border-radius: var(--component-badge-common-border-radius, 9999px);
  background-color: var(--bg-color);
`;

interface StatusTagProps {
  type?: StatusTagType;
  value: string;
  className?: string;
  style?: CSSProperties;
}

const StatusTag = ({ value, type, style, className }: StatusTagProps): JSX.Element => (
  <StyledDiv className={`${classNames({ [`tag-type-${type}`]: true })} ${className || ""}`} type={type} style={style}>
    {value}
  </StyledDiv>
);

export default StatusTag;
