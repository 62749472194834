import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FieldWrapper from "components/UI/FieldWrapper";
import SelectControl from "components/UI/SelectControl";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { getDepartments, getPositions, getSubsidiaries } from "utils/apiHelpers";
import { useQuery } from "react-query";
import { hasPermisionAccess } from "utils/common";

const FETCH_FUNC = {
  department: getDepartments,
  position: getPositions,
  subsidiary: getSubsidiaries,
};

const RESPONSE_DATA_KEYS = {
  department: "departments",
  position: "positions",
  subsidiary: "subsidiaries",
};

const OrganizationUnitChangePopup = ({ onClose, onYes }) => {
  const { t } = useTranslation("employees-page-all-table");
  const [unit, setUnit] = useState(null);
  const [nameUuid, setName] = useState(null);

  const { data: names, isFetching: isLoading } = useQuery(
    `rq_${unit}`,
    () =>
      FETCH_FUNC[unit]().then((data) => {
        const items = data[RESPONSE_DATA_KEYS[unit]] ?? [];
        return items.map((item) => ({ label: item.name || item.title, value: item.uuid }));
      }),
    { staleTime: 60 * 1000, enabled: !!unit },
  );

  return (
    <ModalDialog isOpen onClose={onClose}>
      <Lightbox
        onClose={onClose}
        yesDisabled={isLoading || !nameUuid}
        onYes={() => onYes(unit, nameUuid)}
        buttonYesTitle={t("common|Confirm")}
        buttonCancelTitle={t("common|Cancel")}
        title={t("Change Organizational Unit")}
      >
        <>
          <FieldWrapper fieldName={t("Organizational Unit")} isLoading={isLoading} width="100%">
            <SelectControl
              options={[
                hasPermisionAccess("departments") ? { value: "department", label: t("Department") } : null,
                hasPermisionAccess("positions") ? { value: "position", label: t("Position") } : null,
                hasPermisionAccess("subsidiaries") ? { value: "subsidiary", label: t("Subsidiary") } : null,
              ].filter((o) => o)}
              onChange={(val) => {
                setUnit(val);
                setName(null);
              }}
              value={unit}
            />
          </FieldWrapper>
          {unit && (
            <FieldWrapper fieldName={t("Name")} isLoading={isLoading} width="100%">
              <SelectControl options={names} isLoading={isLoading} onChange={setName} value={nameUuid} isSearchable />
            </FieldWrapper>
          )}
        </>
      </Lightbox>
    </ModalDialog>
  );
};

export default OrganizationUnitChangePopup;
