import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import TimeControl from "components/controls/TimeControl";
import { minsToHrsMins } from "utils/common";

const Wrapper = styled.div`
  width: 128px;
  position: relative;
  flex-shrink: 0;
`;

const Delete = styled.button`
  position: absolute;
  top: 6px;
  inset-inline-end: 6px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%23A1B2CF' d='M4.667 11a.667.667 0 0 0 .666-.667v-4a.667.667 0 1 0-1.333 0v4a.667.667 0 0 0 .667.667Zm6.666-8H8.667v-.667a2 2 0 0 0-2-2H5.333a2 2 0 0 0-2 2V3H.667a.667.667 0 0 0 0 1.333h.666v7.334a2 2 0 0 0 2 2h5.334a2 2 0 0 0 2-2V4.333h.666a.667.667 0 1 0 0-1.333Zm-6.666-.667a.667.667 0 0 1 .666-.666h1.334a.667.667 0 0 1 .666.666V3H4.667v-.667Zm4.666 9.334a.667.667 0 0 1-.666.666H3.333a.667.667 0 0 1-.666-.666V4.333h6.666v7.334Zm-2-.667A.667.667 0 0 0 8 10.333v-4a.667.667 0 0 0-1.333 0v4a.667.667 0 0 0 .666.667Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-color: var(--colors-surface-50);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%23434E6C' d='M4.667 11a.667.667 0 0 0 .666-.667v-4a.667.667 0 1 0-1.333 0v4a.667.667 0 0 0 .667.667Zm6.666-8H8.667v-.667a2 2 0 0 0-2-2H5.333a2 2 0 0 0-2 2V3H.667a.667.667 0 0 0 0 1.333h.666v7.334a2 2 0 0 0 2 2h5.334a2 2 0 0 0 2-2V4.333h.666a.667.667 0 1 0 0-1.333Zm-6.666-.667a.667.667 0 0 1 .666-.666h1.334a.667.667 0 0 1 .666.666V3H4.667v-.667Zm4.666 9.334a.667.667 0 0 1-.666.666H3.333a.667.667 0 0 1-.666-.666V4.333h6.666v7.334Zm-2-.667A.667.667 0 0 0 8 10.333v-4a.667.667 0 0 0-1.333 0v4a.667.667 0 0 0 .666.667Z'/%3E%3C/svg%3E");
`;

type PhaseLimitInputProps = {
  disabled: boolean;
  locked: boolean;
  error?: boolean;
  onChange: (val: number) => void;
  value: number;
  onDeleteClick?: () => void;
};

const convertTimeToMinutes = (time: string) => parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);

const PhaseLimitInput = ({ disabled, locked, onChange, value, onDeleteClick, error }: PhaseLimitInputProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  let val;
  if (value === -1) {
    val = undefined;
  } else if (value > 0) {
    val = minsToHrsMins(value);
  } else {
    val = "00:00";
  }

  const showDeleteButton = !disabled && !locked && !!onDeleteClick;

  return (
    <Wrapper>
      <TimeControl
        error={error}
        placeholder={value === -1 ? t("Unlimited") : t("00:00")}
        disabled={disabled}
        locked={!disabled && locked}
        onTimeChange={(timeVal) => onChange(timeVal.value ? convertTimeToMinutes(timeVal.value) : 0)}
        modifiers={{ new: true }}
        value={val}
      />
      {showDeleteButton && <Delete onClick={onDeleteClick} />}
    </Wrapper>
  );
};

export default PhaseLimitInput;
