import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import { getSelectedColumns } from "utils/tableHelpers";
import { getCompanyRules, getLockGroupsList, getLocksList } from "utils/apiHelpers";
import { ReportType } from "types/reports";
import { DigitalSignatureStatus } from "types/models/digitalSignature";
import { PayrollLockStatus } from "types/models/payrollLocks";
import { TranslationNamespaces } from "types/translationNamespaces";
import MultiWithSearch from "components/UI/Select/MultiWithSearch";
import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/Select";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import "styles/holidays-popup-message.scss";
import { CompanyRuleNames } from "types/models/companyRules";

const b = BEM.b("holidays-popup-message");

const ButtonsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    max-width: 155px;
  }
`;

const Row = styled.div`
  margin-top: 19px;
  margin-bottom: 26px;
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: var(--shapes-border-radius-default);
  background-color: ${(props) => props.theme.colors.rowBackground};
`;

const Image = styled.div`
  margin-top: 5px;
  margin-inline-end: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: var(--colors-surface-600);
`;

const Text = styled.div`
  font-size: var(--typography-font-size-default);
  color: var(--colors-text4);
  line-height: 24px;
`;

const ColumnsSelector = styled.div`
  .ui-select__input {
    background-color: var(--colors-surface-0);
    border: solid 1px #a1b2cf;
  }
`;

type RequestDigitalSignaturesPopupGroup = {
  /** uuid */
  value: string;
  label: string;
  locks: {
    /** uuid */
    value: string;
    /** dates */
    label: string;
  }[];
};

interface RequestDigitalSignaturesPopupProps extends WithTranslation {
  onClose: () => void;
  onYes: (data: { payrollGroupUuid: string; payrollLockUuid: string; selectedColumns: string }) => void;
}

interface RequestDigitalSignaturesPopupState {
  isNightReducedHoursColumnAllowed: boolean;
  isLoading: boolean;
  groups: RequestDigitalSignaturesPopupGroup[];
  payrollGroupUuid: string | null;
  payrollLockUuid: string | null;
  selectedColumns: string[];
}

class RequestDigitalSignaturesPopup extends Component<
  RequestDigitalSignaturesPopupProps,
  RequestDigitalSignaturesPopupState
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: RequestDigitalSignaturesPopupProps) {
    super(props);

    this.state = {
      isNightReducedHoursColumnAllowed: false,
      isLoading: true,
      groups: [],
      payrollGroupUuid: null,
      payrollLockUuid: null,
      selectedColumns: getSelectedColumns(
        "date,punches,worked_hours,break_hours,night_hours,holiday,observation",
        `requestDsPopup_${ReportType.detailed}`,
      ).split(","),
    };
  }

  UNSAFE_componentWillMount() {
    void this.getState();
  }

  getState = async () => {
    const { t } = this.props;
    const company = await this.context.getCompany();
    if (!company) {
      return;
    }

    const groupsListResp = await getLockGroupsList({ companyUuid: company.uuid });
    const groupsList = groupsListResp.content || [];

    const locksResp = await getLocksList({ companyUuid: company.uuid });
    const locks = locksResp.content || [];

    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );

    const groups: RequestDigitalSignaturesPopupGroup[] = groupsList
      .map((group) => ({
        value: group.uuid,
        label: group.name === "default" ? t(`${TranslationNamespaces.common}|default`) : group.name,
        locks: locks
          .filter(
            (lock) =>
              lock.digitalSignatureStatus !== DigitalSignatureStatus.signRequestSent &&
              lock.payrollGroupUuid === group.uuid &&
              lock.status === PayrollLockStatus.locked,
          )
          .map((lock) => ({
            value: lock.uuid,
            label: `${moment(lock.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
              lock.endDate,
              "YYYY-MM-DD",
            ).format("DD/MM/YYYY")}`,
          })),
      }))
      .filter((group) => !!group.locks.length);

    this.setState({
      isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value,
      isLoading: false,
      payrollGroupUuid: groups.length ? groups[0].value : null,
      payrollLockUuid: groups.length ? groups[0].locks[0].value : null,
      groups,
    });
  };

  getColumnsSelectorOptions = () => {
    const { t } = this.props;
    const { isNightReducedHoursColumnAllowed } = this.state;

    const nightReducedHoursColumn = isNightReducedHoursColumnAllowed
      ? [
          {
            value: "night_reduced_hours",
            label: t(`${TranslationNamespaces.reportsPage}|ns-col-reduced-hours`),
          },
        ]
      : [];

    const result = [
      { value: "date", label: t(`${TranslationNamespaces.reportsPage}|Date`), disabled: true },
      { value: "schedule", label: t(`${TranslationNamespaces.reportsPage}|Schedule`) },
      { value: "business_rules_group", label: t(`${TranslationNamespaces.reportsPage}|Business Rules Group`) },
      { value: "punches", label: t(`${TranslationNamespaces.reportsPage}|Punches`), disabled: true },
      { value: "planned_hours", label: t(`${TranslationNamespaces.reportsPage}|Planned Hours`) },
      { value: "worked_hours", label: t(`${TranslationNamespaces.reportsPage}|Worked Hours`) },
      { value: "break_hours", label: t(`${TranslationNamespaces.reportsPage}|Break hours`) },
      { value: "night_hours", label: t(`${TranslationNamespaces.reportsPage}|nightShiftMinutes`) },
      ...nightReducedHoursColumn,
      { value: "late_entry_hours", label: t(`${TranslationNamespaces.reportsPage}|Late Arrival`) },
      { value: "early_leave_hours", label: t(`${TranslationNamespaces.reportsPage}|Late Leave`) },
      { value: "extra_hours_phases", label: t(`${TranslationNamespaces.reportsPage}|HE Phases`) },
      { value: "extra_hours", label: t(`${TranslationNamespaces.reportsPage}|Extra Hours`) },
      { value: "hours_bank_phases", label: t(`${TranslationNamespaces.reportsPage}|HB Phases`) },
      { value: "hours_bank", label: t(`${TranslationNamespaces.reportsPage}|Hours Bank`) },
      { value: "missed_hours", label: t(`${TranslationNamespaces.reportsPage}|Missed Minutes`) },
      { value: "cumulative_hours_bank", label: t(`${TranslationNamespaces.reportsPage}|Accumulated Hours Bank`) },
      {
        value: "cross_shifts_interval_diff_hours",
        label: t(`${TranslationNamespaces.reportsPage}|Cross Shift Interval`),
      },
      { value: "holiday", label: t(`${TranslationNamespaces.reportsPage}|Holiday`) },
      { value: "observation", label: t(`${TranslationNamespaces.reportsPage}|OBS`) },
      { value: "on_call_hours", label: t(`${TranslationNamespaces.reportsPage}|On Call Minutes`) },
      { value: "on_call_activated_hours", label: t(`${TranslationNamespaces.reportsPage}|On Call Activated Minutes`) },
      { value: "on_call_reducing_activated_hours", label: t("On Call Reducing Activated Minutes") },
      { value: "debit_hours", label: t(`${TranslationNamespaces.reportsPage}|Debit Minutes`) },
    ];

    return result;
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem(`customColumns_requestDsPopup_${ReportType.detailed}`, selectedColumns.join());
    }

    this.setState({ selectedColumns });
  };

  onCancel = () => {
    this.props.onClose();
  };

  onYes = () => {
    const { payrollGroupUuid, payrollLockUuid, selectedColumns } = this.state;

    this.setState({ isLoading: true }, () => {
      if (payrollGroupUuid && payrollLockUuid) {
        this.props.onYes({
          payrollGroupUuid,
          payrollLockUuid,
          selectedColumns: selectedColumns?.length ? selectedColumns.join(",") : "",
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { payrollGroupUuid, payrollLockUuid, isLoading, groups, selectedColumns } = this.state;

    const title = t("Request Digital Signatures");
    const groupLocks = groups?.length ? groups.find((v) => v.value === payrollGroupUuid)?.locks || [] : [];

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        {isLoading ? (
          <div>{t("common|Loading...")}</div>
        ) : (
          <div>
            {groups.length ? (
              <div>
                <FieldWrapper fieldName={t("Select Pay Day Group")} width="100%">
                  <Select
                    modifiers={{ field: true }}
                    value={payrollGroupUuid || ""}
                    onChange={(val) => {
                      const group = groups.filter((v) => v.value === val)[0];
                      this.setState({
                        payrollGroupUuid: val,
                        payrollLockUuid: group.locks[0].value,
                      });
                    }}
                    options={groups}
                  />
                </FieldWrapper>

                {!!groupLocks.length && (
                  <FieldWrapper fieldName={t("Locking Period")} width="100%">
                    <Select
                      modifiers={{ field: true }}
                      value={payrollLockUuid || ""}
                      onChange={(val) => {
                        this.setState({
                          payrollLockUuid: val,
                        });
                      }}
                      options={groupLocks}
                    />
                  </FieldWrapper>
                )}

                <FieldWrapper fieldName={t("Include columns")} width="100%">
                  <ColumnsSelector>
                    <MultiWithSearch
                      lightUI
                      placeholder={t(`${TranslationNamespaces.reportsPage}|Select report columns`)}
                      value={selectedColumns}
                      onChange={this.onColumnsChange}
                      options={this.getColumnsSelectorOptions()}
                    />
                  </ColumnsSelector>
                </FieldWrapper>
              </div>
            ) : (
              <Row>
                <Image>{images.lockedPadlock()}</Image>

                <Text>{t("You don't have available locked periods to create digital signatures")}</Text>
              </Row>
            )}
          </div>
        )}

        <ButtonsWrapper>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            disabled={isLoading}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Button
            value={t("Request")}
            state={ButtonState.primary}
            disabled={!groupLocks.length || !groups.length}
            onClick={this.onYes}
            loading={isLoading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation([TranslationNamespaces.payment, TranslationNamespaces.reportsPage])(
  RequestDigitalSignaturesPopup,
);
