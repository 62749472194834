import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";
import { stylesheet } from "astroturf";
import { useGoogleLogin } from "@react-oauth/google";
import { checkEmailExistence, handleGoogleSignUp } from "utils/ssoApiUtils";
import cookie from "react-cookies";
import { TranslationNamespaces } from "types/translationNamespaces";
import ga, { GaCreateAccountMethod } from "utils/ga";
import { PermissionRoleName } from "types/models/permissions";
import moment from "moment";
import { getSystemName } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import MainPage from "./MainPage";
import TextInput from "../controls/TextInputControlNew";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import Button from "../controls/StyledButton";
import { ReactComponent as GoogleIcon } from "./img/Google.svg";
import { BlockNotification, BlockNotificationType } from "../UI/BlockNotification";
import { submitHSFormCreateContact } from "../../utils/api/common";
import config from "../../config";

const styles = stylesheet`
  .Divider {
    gap: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    height: 30px;
    &:before, &:after {
      content: '';
      width: 100%;
      height: 1px;
      border-top: 1px solid var(--colors-surface-150);
    }
  }
  .GoogleBtn {
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: center;
    border: 2px solid var(--colors-surface-150);
    border-radius: 1000px;
    width: 100%;
    background: none;
    outline: none;
    gap: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    span {
      font-size: 15px;
      color: var(--colors-surface-900-p);
    }
  }
  .ErrorModal {
    .Title {
      font-size: 20px;
      margin-bottom: 12px;
      color: var(--colors-surface-900-p);
    }
    .Description {
      font-size: 15px;
      color: var(--colors-surface-700);
      margin-bottom: 32px;
    }
    .BtnBlock {
      display: flex;
      justify-content: end;
      .CtaBtn {
        width: 120px;
      }
    }
  }
  .Terms {
    text-align: center;
    margin-top: 24px;
    color: var(--colors-surface-900-p);
    a {
      color: var(--colors-primary-500-p);
    }
  }
`;

const Terms = () => {
  const { t } = useTranslation(TranslationNamespaces.signup);
  return (
    <div className={styles.Terms}>
      {t("I agree to {{systemName}}", { systemName: getSystemName() })}
      &nbsp;
      <a
        href={
          config.globalMode
            ? "https://day.io/legal/privacy-policy/"
            : "https://www.oitchau.com.br/legal/privacy-policy/"
        }
        rel="noreferrer"
        target="_blank"
        onClick={(ev) => ev.stopPropagation()}
      >
        {t("Terms & Conditions")}
      </a>
      ,&nbsp;
      <a
        href={
          config.globalMode
            ? "https://day.io/legal/privacy-policy/"
            : "https://www.oitchau.com.br/legal/privacy-policy/"
        }
        rel="noreferrer"
        target="_blank"
        onClick={(ev) => ev.stopPropagation()}
      >
        {t("Privacy Policy")}
      </a>
      &nbsp;&&nbsp;
      <a
        href={
          config.globalMode
            ? "https://day.io/legal/privacy-policy/"
            : "https://www.oitchau.com.br/legal/privacy-policy/"
        }
        rel="noreferrer"
        target="_blank"
        onClick={(ev) => ev.stopPropagation()}
      >
        {t("Data Processing Agreement")}
      </a>
      , {t("and to receive emails, news and notifications")}
    </div>
  );
};

type CreateAccountPageNewProps = {
  email?: string;
  name?: string;
  token?: string;
  sourceType?: string;
  trafficSource?: string;
  trafficMedium?: string;
  trafficCampaing?: string;
  refCode?: string;
};

export const CreateAccountPageNew = (props: CreateAccountPageNewProps) => {
  const { t } = useTranslation(TranslationNamespaces.signup);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [error, setErrors] = useState<string | null>();
  const [user, setUser] = useState<{ access_token: string }>();

  useEffect(() => {
    if (user && loading) {
      handleGoogleSignUp(user.access_token)
        .then((res) => {
          cookie.save("userEmail", res.email, { path: "/" });
          res.userToken && cookie.save("userToken", res.userToken, { path: "/" });
          ga.trackCreateAccount({
            method: GaCreateAccountMethod.google,
            email: res.email,
            full_name: res.fullName,
            phone: "",
            user_role: PermissionRoleName.owner,
            signup_date: moment(res.createdAt).format("DD/MM/YY"),
            user_id: String(res.id),
          });
          const payload = {
            hutk: cookie.load("hubspotutk"),
            email: res.email,
            firstName: res.fullName,
            mobilephone: "",
            refCode: props.refCode ?? "",
            trafficSource: props.trafficSource,
            trafficMedium: props.trafficMedium,
            trafficCampaing: props.trafficCampaing,
          };

          try {
            submitHSFormCreateContact(payload);
          } catch (e) {
            sentryUtils.sendError(`Rd, Hs Error: ${String(e)}`);
          }
          history.push(`/account-type?email=${res.email}`);
        })
        .catch((e) => {
          setErrors(e.response?.data?.message || e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user, loading]);

  const onEmailChange = async (email: string) => {
    setEmail(email);
  };

  const validateEmail = async () => {
    let error: string | null = null;

    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (!email) {
      error = "Please, provide an e-mail";
    } else if (!validate(email)) {
      error = "Incorrect e-mail";
    } else {
      error = null;
      const existedEmail = await checkEmailExistence(email!).catch((err) => (error = err.message));
      if (existedEmail) {
        error = "User with given email already exists";
      }
    }

    return error;
  };

  const constructQueryParams = (): string => {
    const query: string[] = [];
    if (email) query.push(`email=${email}`);
    if (props.name) query.push(`name=${props.name}`);
    if (props.token && props.sourceType) query.push(`token=${props.token}&sourceType=${props.sourceType}`);
    if (props.refCode) query.push(`referral_code=${props.refCode}`);
    if (props.trafficSource) query.push(`utm_source=${props.trafficSource}`);
    if (props.trafficMedium) query.push(`utm_medium=${props.trafficMedium}`);
    if (props.trafficCampaing) query.push(`utm_campaign=${props.trafficCampaing}`);
    if (query.length > 0) return `?${query.join("&")}`;
    return "";
  };

  const onNextClick = async (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setErrors(null);
    const emError = await validateEmail();
    setErrors(emError);

    if (!emError) {
      history.push(`/signup-default/${email}${constructQueryParams()}`);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setErrors(null);
      setLoading(true);
      setUser(codeResponse);
    },
    onError: (error) => {
      setErrors(error.error_description);
      setLoading(false);
    },
  });

  return (
    <MainPage
      topRowText={t("Already Have an account?")}
      topRowButtonText={t("LOGIN")}
      topRowButtonClick={() => history.push("/login")}
      pageTitle={t("create_account_provider_title")}
      pageSubTitle={t("create_account_provider_sub-title")}
      loading={loading}
      formParams={{
        onSubmit: (e) => { e.preventDefault(); },
        noValidate: true,
      }}
      customButtons={
        <div>
          <Button type="button" onboarding value={t("Continue")} onClick={onNextClick} />
          <Terms />
        </div>
      }
    >
      {error ? (
        <BlockNotification
          styles={{ marginTop: "-40px", marginBottom: "50px" }}
          type={BlockNotificationType.Error}
          title={t(`create_account_page_error_title-${error}`)}
          description={t(`create_account_page_error_description-${error}`)}
        />
      ) : null}
      <div className={styles.Wrapper}>
        {config.globalMode ? (
          <>
            <button disabled={loading} className={styles.GoogleBtn} type="button" onClick={() => login()}>
              <GoogleIcon />
              <span>{t("create_account_provider_google-signup")}</span>
            </button>

            <div className={styles.Divider}>
              <span>{t("create_account_provider_divider")}</span>
            </div>
          </>
        ) : null}

        <OnboardingFieldWrapper fieldName={t("create_account_provider_email-label")} css={{marginBottom: config.globalMode ? "32px" : "48px" }}>
          <TextInput
            name="email"
            onboarding
            value={email}
            type="email"
            placeholder={t("common|email-placeholder")}
            onChange={(ev) => onEmailChange(ev.target.value)}
          />
        </OnboardingFieldWrapper>
      </div>
    </MainPage>
  );
};
