import styled from "styled-components";
import { IconDropdown } from "./IconDropdown";

const Wrapper = styled.button`
  font-size: 16px;
  line-height: 20px;
  color: var(--colors-surface-900-p);
  padding: 7px 12px;
  border: 1.39901px solid var(--colors-surface-150);
  border-radius: 29px;
  background-color: var(--colors-surface-0);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export type DropdownButtonProps = React.HTMLProps<HTMLButtonElement> & { noOptions?: boolean };

export default function DropdownButton({ children, noOptions, onClick, ...props }: DropdownButtonProps) {
  return (
    <Wrapper {...props} onClick={noOptions ? undefined : onClick} style={noOptions ? { cursor: "default" } : {}}>
      <div>{children}</div>
      {!noOptions && <IconDropdown />}
    </Wrapper>
  );
}
