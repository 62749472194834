import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getSelectedColumns, iRow } from "utils/tableHelpers";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { minsToHrsMins, getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import { ClientReportsType, SelectedClient, SetIsLoading, OnError } from "types/reports";
import { ClientsReportTableData } from "types/models/reports/clients";
import { SelectOption } from "types/ui";
import styled from "styled-components";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import NoContent from "../NoContent";
import { TableDetailsTitle } from "./reportsStyledComponents";

const TotalWrapper = styled.div`
  width: 100%;
  text-align: end;
  font-weight: var(--typography-font-weight-medium);
  font-size: 16px;
  color: var(--colors-surface-800);
  border-top: 2px solid var(--colors-surface-150);
  padding-top: 15px;
`;

const TotalValue = styled.span`
  font-weight: var(--typography-font-weight-bold);
`;

interface ReportsClientsTableProps extends WithTranslation {
  clientReportType: ClientReportsType;
  showInactiveEmploees: boolean;
  skipSupervisors: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedClient: SelectedClient;
  setIsLoading: SetIsLoading;
  onError: OnError;
}

interface ReportsClientsTablePropsState {
  clientReportType: ClientReportsType;
  showInactiveEmploees: boolean;
  skipSupervisors: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedClient: SelectedClient;
  selectedColumns: string;
  loadingStatus: string | null;
  data: ClientsReportTableData | null;
}

class ReportsClientsTable extends Component<ReportsClientsTableProps, ReportsClientsTablePropsState> {
  defaultColumns = {
    clientList: "client,locations,employees,worked_hours",
    locations: "id,location_name,worked_hours",
    employees: "employee,cpf,worked_hours",
  };

  constructor(props: ReportsClientsTableProps) {
    super(props);
    const { clientReportType, selectedClient, startDate, endDate, showInactiveEmploees, skipSupervisors } = props;
    this.state = {
      loadingStatus: null,
      selectedColumns: getSelectedColumns(this.defaultColumns.clientList, "ReportsClientsTable_clientList"),
      clientReportType,
      selectedClient,
      startDate,
      endDate,
      showInactiveEmploees,
      skipSupervisors,
      data: null,
    };
  }

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const {
      clientReportType,
      selectedClient,
      startDate,
      endDate,
      showInactiveEmploees,
      skipSupervisors,
      setIsLoading,
      onError,
    } = this.props;
    const companyUUID = window.global_store.company ? window.global_store.company.uuid : "";

    if (
      (clientReportType === ClientReportsType.clientList ||
        ((clientReportType === ClientReportsType.locations || clientReportType === ClientReportsType.employees) &&
          selectedClient &&
          selectedClient.value !== null)) &&
      startDate &&
      endDate &&
      companyUUID
    ) {
      this.setState(
        {
          loadingStatus: "loading",
          selectedColumns: getSelectedColumns(
            this.defaultColumns[clientReportType],
            `ReportsClientsTable_${clientReportType}`,
          ),
        },
        () => setIsLoading(true),
      );

      try {
        const resp: ClientsReportTableData = await getReport({
          oldReport: true,
          startDate,
          endDate,
          companyUUID,
          showInactiveEmploees,
          skipSupervisors,
          type: "clients",
          clientReportType,
          clientUUID: selectedClient ? selectedClient.value : null,
        });

        this.setState({
          data: resp,
          loadingStatus: "loaded",
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error", e);

        onError(e as string);
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = () => {
    const { t } = this.props;
    const { clientReportType } = this.state;
    const byClients = [
      {
        label: t("Name"),
        accessor: "name",
        rubyAccessor: "client",
        style: { lineHeight: "20px", fontWeight: "700" },
        minWidth: 70,
      },
      {
        label: t("Locations"),
        accessor: "locations",
        rubyAccessor: "locations",
        align: "right",
        minWidth: 70,
      },
      {
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employees",
          `${TranslationNamespaces.common}|Employees`,
        ),
        accessor: "employees",
        rubyAccessor: "employees",
        align: "right",
        minWidth: 70,
      },
      {
        label: t("Worked Hours"),
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        Cell: (row: iRow) => `${minsToHrsMins(row.value)} hs`,
        align: "right",
        style: { fontWeight: "500" },
        minWidth: 70,
      },
    ];

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const byEmployees = [
      {
        label: translateEmployeeTerm(
          t,
          TranslationNamespaces.common,
          "custom-employee",
          `${TranslationNamespaces.common}|Employee`,
        ),
        accessor: "fullName",
        rubyAccessor: "employee",
        style: { lineHeight: "20px", fontWeight: "700" },
        minWidth: 70,
      },
      {
        label: employeeTaxIdLabel,
        accessor: "matricula",
        rubyAccessor: "cpf",
        minWidth: 70,
      },
      {
        label: t("Worked Hours"),
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        Cell: (row: iRow) => `${minsToHrsMins(row.value)} hs`,
        align: "right",
        style: { fontWeight: "500" },
        minWidth: 70,
      },
    ];

    const byLocations = [
      {
        label: t("Id"),
        accessor: "id",
        rubyAccessor: "id",
        style: { lineHeight: "20px", fontWeight: "700" },
        minWidth: 70,
      },
      {
        label: t("Location Name"),
        accessor: "name",
        rubyAccessor: "location_name",
        minWidth: 70,
      },
      {
        label: t("Worked Hours"),
        accessor: "workedMinutes",
        rubyAccessor: "worked_hours",
        Cell: (row: iRow) => `${minsToHrsMins(row.value)} hs`,
        align: "right",
        style: { fontWeight: "500" },
        minWidth: 70,
      },
    ];

    switch (clientReportType) {
      case ClientReportsType.clientList:
        return byClients;
      case ClientReportsType.locations:
        return byLocations;
      case ClientReportsType.employees:
        return byEmployees;

      default:
        return [];
    }
  };

  getTitle = () => {
    const { t } = this.props;
    const { clientReportType, selectedClient } = this.state;
    let title = "";

    switch (clientReportType) {
      case ClientReportsType.clientList:
        title = t("All Clients list");
        break;
      case ClientReportsType.locations:
        title = `${t("All Locations from")} ${selectedClient?.label}`;
        break;
      case ClientReportsType.employees:
        title = `${translateEmployeeTerm(
          t,
          TranslationNamespaces.reportsPage,
          "custom-all-employees-from",
          `All employees from`,
        )} ${selectedClient?.label}`;
        break;

      default:
        break;
    }

    return title;
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      const { clientReportType } = this.state;
      localStorage.setItem(`customColumns_ReportsClientsTable_${clientReportType}`, selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render() {
    const { t } = this.props;
    const {
      startDate,
      endDate,
      clientReportType,
      selectedClient,
      skipSupervisors,
      showInactiveEmploees,
      loadingStatus,
      data,
      selectedColumns,
    } = this.state;
    let tableData = [];
    let total = 0;

    if (clientReportType === ClientReportsType.clientList) {
      tableData = data;
    } else if (clientReportType === ClientReportsType.locations && data) {
      tableData = data.locations || [];
      total = data.total;
    } else if (clientReportType === ClientReportsType.employees && data) {
      tableData = data.employees || [];
      total = data.total;
    }

    if (loadingStatus !== "loading" && tableData && tableData.length === 0) {
      return <NoContent>{t("Select your report type")}</NoContent>;
    }

    const columns = this.getColumns();
    const clientReportTypesMap: Record<string, string> = {};
    clientReportTypesMap[ClientReportsType.clientList] = "clients";
    clientReportTypesMap[ClientReportsType.locations] = "client_locations";
    clientReportTypesMap[ClientReportsType.employees] = "client_employees";

    if (loadingStatus === "loading" || (loadingStatus === "loaded" && tableData.length > 0)) {
      return (
        <div>
          <TablePage<ClientsReportTableData>
            selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
            onColumnsChange={this.onColumnsChange}
            rows={tableData}
            columns={columns}
            loading={loadingStatus === "loading"}
            tableDetails={<TableDetailsTitle>{this.getTitle()}</TableDetailsTitle>}
            downloadControl={
              <DownloadControlWithEvents
                options={[
                  { label: "PDF", value: "pdf" },
                  { label: "XLSX", value: "xlsx.xlsx" },
                ]}
                placeholder={t("common|Download")}
                onChange={(value: SelectOption) =>
                  fireDownloadReport({
                    format: value || "pdf",
                    selectedColumns,
                    skipSupervisors,
                    clientUUID: selectedClient ? selectedClient.value : null,
                    startDate,
                    showInactiveEmploees,
                    endDate,
                    companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                    reportType: clientReportTypesMap[clientReportType],
                    oldReport: true,
                  })
                }
              />
            }
            interactive={false}
          />
          {loadingStatus === "loaded" && clientReportType !== ClientReportsType.clientList && (
            <TotalWrapper>
              {t("Total")} <TotalValue>{total ? minsToHrsMins(total) : "00:00"}</TotalValue>
            </TotalWrapper>
          )}
        </div>
      );
    }

    return null;
  }
}

export default withTranslation("reports-page")(ReportsClientsTable);
