import React from "react";
import styled from "styled-components";
import * as images from "components/svg-images";

const Button = styled.div`
  &.show-on-parent-hover {
    display: none;
  }
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #e9f5ff;
  }
`;

export default (props) => {
  const { showOnParentHover, add, remove } = props;
  const className = showOnParentHover ? `${props.className} show-on-parent-hover` : "";
  let image = null;
  if (add) {
    image = images.scheduleAddSmall;
  } else if (remove) {
    image = images.clear;
  }
  return (
    <Button {...props} className={className}>
      {image}
    </Button>
  );
};
