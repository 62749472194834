import styled from "styled-components";
import rtl from "styled-components-rtl";

export const Page = styled.div`
  padding-bottom: 90px;
`;

export const Header = styled.div`
  margin-top: 35px;
  display: flex;
  height: 37px;
`;

export const ControlsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const GenerateButton = styled.button`
  margin: 0 15px;
  width: 88px;
  height: 36px;
  line-height: 14px;
  text-align: center;
  font-size: var(--typography-font-size-default);
  letter-spacing: 0;
  font-weight: var(--typography-font-weight-bold);
  border: none;
  border-radius: var(--shapes-border-radius-default);
  background-color: var(--colors-primary);
  color: var(--colors-default);
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

const resetBgImg =
  // eslint-disable-next-line max-len
  "url(\"data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.577 1.933 1.019 1.02c.05-.06.107-.124.17-.188A6.008 6.008 0 0 1 7 1c3.308 0 6 2.692 6 6s-2.692 6-6 6a5.96 5.96 0 0 1-5.751-4.286.75.75 0 1 1 1.437-.428A4.471 4.471 0 0 0 7 11.5c2.482 0 4.5-2.018 4.5-4.5S9.482 2.5 7 2.5a4.51 4.51 0 0 0-3.177 1.324l-.153.203 1.646 1.646c.319.319.213.577-.238.577h-3.67A.407.407 0 0 1 1 5.842v-3.67c0-.45.258-.557.577-.239Z' fill='%238193AB' stroke='%238193AB' stroke-width='.5'/%3E%3C/svg%3E\")";

export const ResetButton = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  margin-inline-start: 3px;
  padding-inline-start: 20px;
  line-height: 14px;
  font-size: var(--typography-font-size-default);
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-activitiInfoBlockValue);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  :after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 14px;
    height: 14px;
    ${rtl`
      left: 0;
    `}
    background-image: ${resetBgImg};
    background-repeat: no-repeat;
    background-position: 0px;
  }
`;

export const ClientReportTypeWrapper = styled.div`
  width: 155px;
  display: inline-block;
`;

export const ServicesTypeWrapper = styled.div`
  width: 155px;
  display: inline-block;
  margin-inline-start: 16px;
`;

export const SourceTypeWrapper = styled.div`
  width: 200px;
  display: inline-block;
`;

export const LateArrivalPunchTime = styled.div`
  margin-inline-start: 15px;
  width: 155px;
  display: inline-block;
`;

export const SearchControlWrapper = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  max-width: 250px;
  min-width: 250px;
`;

export const ClientsDropdownWrapper = styled.div`
  margin-inline-start: 8px;
`;

export const TableDetailsTitle = styled.div`
  font-size: 20px;
  color: var(--colors-mainText);
  letter-spacing: -0.44px;
  text-align: start;
  line-height: 27px;
  margin-bottom: 7px;
`;

export const TableDetailsTitleDates = styled.div`
  font-size: 15px;
  color: var(--colors-secondary);
  letter-spacing: 0;
  text-align: start;
  margin-top: 7px;
`;
