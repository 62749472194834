import styled, { CSSProperties } from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import StatusTag from "components/UI/StatusTag";

export enum StatusBadgeStatuses {
  default = "default",
  warn = "warn",
  success = "success",
  active = "active",
  signed = "signed",
  completed = "completed",
  approved = "approved",
  pending = "pending",
  declined = "declined",
  new = "new",
}

const StyledDiv = styled.div<{ type?: StatusBadgeStatuses }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 19px;
  padding: 0 10px;
  font-size: 10px;
  text-transform: uppercase;
  color: ${(props) => {
    switch (props.type) {
      case StatusBadgeStatuses.success:
      case StatusBadgeStatuses.active:
      case StatusBadgeStatuses.signed:
      case StatusBadgeStatuses.completed:
      case StatusBadgeStatuses.approved:
        return "var(--colors-success-600-p)";
      case StatusBadgeStatuses.warn:
      case StatusBadgeStatuses.pending:
        return "var(--colors-pending)";
      case StatusBadgeStatuses.declined:
        return "var(--colors-danger-500)";
      case StatusBadgeStatuses.new:
        return "var(--colors-primary-500-p)";

      case StatusBadgeStatuses.default:
      default:
        return "var(--colors-surface-800)";
    }
  }};
  border-width: 1px;
  border-style: solid;
  border-radius: 9.5px;
  border-color: ${(props) => {
    switch (props.type) {
      case StatusBadgeStatuses.success:
      case StatusBadgeStatuses.active:
      case StatusBadgeStatuses.signed:
      case StatusBadgeStatuses.completed:
      case StatusBadgeStatuses.approved:
        return "var(--colors-success-600-p)";
      case StatusBadgeStatuses.warn:
      case StatusBadgeStatuses.pending:
        return "var(--colors-pending)";
      case StatusBadgeStatuses.declined:
        return "var(--colors-danger-500)";
      case StatusBadgeStatuses.new:
        return "var(--colors-primary-500-p)";

      case StatusBadgeStatuses.default:
      default:
        return "var(--colors-secondary2)";
    }
  }};
`;

interface StatusBadgeProps {
  type?: StatusBadgeStatuses;
  value: string;
  className?: string;
  style?: CSSProperties;
}

/**
 * This component is depracated. Please use {@link StatusTag} instead.
 *
 * @param param0
 * @returns
 * @deprecated
 */
const StatusBadge = ({ value, type, style, className }: StatusBadgeProps): JSX.Element => (
  <StyledDiv className={className} type={type} style={style}>
    {value}
  </StyledDiv>
);

export default StatusBadge;
