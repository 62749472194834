import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Success from "components/Signup/Success";
import Signup from "components/Signup/Signup";
import { useHubspot } from "hooks/useHubspot";

const RouteSuccess = () => {
  useHubspot();

  return (
    <Suspense fallback={<div />}>
      <Signup>
        <Switch>
          <Route path="/success" component={Success} />
        </Switch>
      </Signup>
    </Suspense>
  );
};

export default RouteSuccess;
