import React, { Component } from "react";
import BEM from "utils/BEM";
import "./styles/popup-message.scss";
import TextInputControl from "components/controls/StyledTextInput";
import PhoneNumberField from "components/PhoneNumberField";
import FieldWrapper from "components/UI/FieldWrapper";
import { withTranslation } from "react-i18next";
import { validatePhoneNumber } from "utils/common";
import Button, { ButtonState } from "components/controls/StyledButton";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import styled from "styled-components";

const b = BEM.b("clients-popup-message");

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  button {
    max-width: 155px;
  }
`;

class ClientsPopupMessage extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      title: this.props.title,
      name: { value: this.props.item.name || "", error: "" },
      owner: { value: this.props.item.contact_person || "", error: "" },
      phone: { value: this.props.item.phone_number || "", error: "" },
      email: { value: this.props.item.email || "", error: "" },
      buttonCancelTitle: t(`${TranslationNamespaces.common}|Cancel`),
      buttonYesTitle: t(`${TranslationNamespaces.common}|Confirm`),
      loading: false,
    };
  }

  componentDidMount() {
    this.getContent();
  }

  closePopup() {
    this.props.onClose();
  }

  async getContent() {
    const company = await this.context.getCompany();
    let { countryCode } = this.state;
    if (company?.default_location?.country) {
      countryCode = company?.default_location.country.toLowerCase();
    }
    this.setState({ countryCode });

    this.setState({
      title: this.props.title,
      name: { value: this.props.item.name || "", error: "" },
      owner: { value: this.props.item.contact_person || "", error: "" },
      phone: { value: this.props.item.phone_number || "", error: "" },
      email: { value: this.props.item.email || "", error: "" },
      buttonCancelTitle: this.props.t("Cancel"),
      buttonYesTitle: this.props.buttonYesTitle,
      countryCode,
    });
  }

  onCancel = () => {
    this.closePopup();
  };

  onYes = async () => {
    const { item, t } = this.props;
    const { phone, name, owner, email } = this.state;

    this.setState({ loading: true });
    const phoneError = validatePhoneNumber(phone.value);
    if (!name.value) {
      name.error = t("Client name can't be empty");
      this.setState({ name, owner, phone, email });
    } else if (phoneError && phoneError !== "Phone can't be empty") {
      phone.error = t(phoneError);
      this.setState({ name, owner, phone, email });
    } else if (this.getEmailError(email.value)) {
      email.error = t("Email is invalid");
      this.setState({ name, owner, phone, email });
    } else {
      const obj = {};

      obj.id = item.id;
      obj.name = name.value;
      obj.contact_person = owner.value;
      obj.phone_number = phone.value;
      obj.email = email.value;
      if (this.props.onYes) {
        await this.props.onYes(obj);
      }
    }
    this.setState({ loading: false });
  };

  getPhoneError(phoneNumber) {
    const { t } = this.props;
    let error = "";
    if (!phoneNumber) {
      error = "";
    } else if (phoneNumber.length < 11) {
      error = t("Phone number is incorrect");
    } else if (phoneNumber.substr(2, 1) !== "9") {
      error = t("Please put a valid Mobile Number");
    } else {
      error = "";
    }
    return error;
  }

  getEmailError(email) {
    const { t } = this.props;
    let error = "";
    const value = email;
    const validate = (str) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };
    if (!value) {
      error = "";
    } else if (!validate(value)) {
      error = t("Email is incorrect");
    } else {
      error = "";
    }
    return error;
  }

  render() {
    const { title, name, owner, phone, email, buttonYesTitle, buttonCancelTitle, countryCode, loading } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{title}</div>
        <FieldWrapper fieldName={t("Client Name")} width="100%">
          <TextInputControl
            value={name.value}
            error={name.error}
            onChange={(val) => this.setState({ name: { value: val, error: "" } })}
            size={60}
          />
        </FieldWrapper>
        {name.error && <div className={b("error")}>{name.error}</div>}

        <FieldWrapper fieldName={t("Contact Name")} width="100%">
          <TextInputControl
            size={60}
            value={owner.value}
            error={owner.error}
            onChange={(val) => this.setState({ owner: { value: val, error: "" } })}
          />
        </FieldWrapper>
        {owner.error && <div className={b("error")}>{owner.error}</div>}

        <FieldWrapper fieldName={t("Phone Number")} width="100%">
          <PhoneNumberField
            countryCode={countryCode}
            newField
            value={phone.value}
            isValid={!phone.error}
            onPhoneChange={(val, country) => {
              this.setState({
                phone: { value: val === country.dialCode ? "" : val },
              });
            }}
          />
        </FieldWrapper>
        {phone.error && <div className={b("error")}>{phone.error}</div>}

        <FieldWrapper fieldName={t("Email")} width="100%">
          <TextInputControl
            type="email"
            name="email"
            value={email.value}
            error={email.error}
            onChange={(val) => {
              this.setState({
                email: { value: val },
              });
            }}
          />
        </FieldWrapper>
        {email.error && <div className={b("error")}>{email.error}</div>}

        <ButtonsWrapper>
          <Button value={buttonCancelTitle} disabled={loading} state={ButtonState.secondary} onClick={this.onCancel} />
          <Button
            value={buttonYesTitle}
            state={ButtonState.primary}
            disabled={!name.value || loading}
            onClick={loading ? undefined : this.onYes}
            loading={loading}
          />
        </ButtonsWrapper>
      </div>
    );
  }
}
export default withTranslation(TranslationNamespaces.clients)(ClientsPopupMessage);
