import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import * as momentTz from "moment-timezone";
import styled from "styled-components";
import { hasEmployeesAccess, getLastLockDate, hasPermisionAccess } from "utils/common";
import { createOnCall } from "utils/apiHelpers";
import Sentry from "utils/sentryUtils";
import Select from "components/UI/Select";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import FieldWrapper from "components/UI/FieldWrapper";
import DateTimeRow from "components/UI/DateTimeRow";
import { ErrorLabel } from "components/UI/TextLabels";
import Button from "components/controls/StyledButton";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import { OnCallType } from "types/models/onCalls";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionSectionName } from "types/models/permissions";
import { MassActionLocations } from "utils/ga";

const Wrapper = styled.div`
  padding-bottom: 50px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 35px;
`;

interface OnCallCreatePopupProps extends WithTranslation {
  isOpen: boolean;
  onClose: () => void;
  onYes: (e?: Error) => void;
}

interface OnCallCreatePopupState {
  isLoading: boolean;
  errors: Record<string, string> | null;
  lockedDate: moment.Moment | null;
  type: OnCallType;
  employeeId: number | null;
  employeeFullName: string | null;
  timeZone: string | null;
  startDate: moment.Moment;
  startTime: string;
  endDate: moment.Moment;
  endTime: string;
  userProfileUuids: string[] | null;
}

class OnCallCreatePopup extends Component<OnCallCreatePopupProps, OnCallCreatePopupState> {
  ON_CALL_TYPES: { value: OnCallType; label: string }[];

  constructor(props: OnCallCreatePopupProps) {
    super(props);
    const { t } = props;

    this.ON_CALL_TYPES = [
      { value: OnCallType.onCall, label: t("On-Call Shift") },
      { value: OnCallType.activation, label: t("On-Call Working Time") },
    ];

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const today = moment();

    const state: OnCallCreatePopupState = {
      isLoading: false,
      errors: null,
      lockedDate: null,
      type: OnCallType.onCall,
      employeeId: null,
      employeeFullName: null,
      timeZone: null,
      startDate: today,
      startTime: today.format("HH:ss"),
      endDate: today.clone(),
      endTime: today.format("HH:ss"),
      userProfileUuids: null,
    };

    if (!hasEmployeesAccess() || !hasPermisionAccess(PermissionSectionName.onCallManagement)) {
      const { id, default_time_zone, uuid, full_name, last_lock_date } = window.global_store.profile;

      state.employeeId = id;
      state.timeZone = default_time_zone;
      state.userProfileUuids = [uuid];
      state.employeeFullName = full_name;
      state.lockedDate = moment(last_lock_date, "YYYY-MM-DD");
    }

    return state;
  };

  getFormErrors() {
    const { t } = this.props;

    const { startTime, endTime, startDate, endDate, employeeId, userProfileUuids } = this.state;
    let errors: OnCallCreatePopupState["errors"] = {};

    if (!startDate) {
      errors.startDate = t("Start date can't be blank");
    }
    if (!endDate) {
      errors.endDate = t("End date can't be blank");
    }

    if (!startTime) {
      errors.startTime = t("Start time can't be empty");
    }
    if (!endTime) {
      errors.endTime = t("End time can't be empty");
    }

    if (startDate && endDate && startTime && endTime) {
      const sDate = moment(`${startDate.format("YYYY-MM-DD ") + startTime} +0000`);
      const eDate = moment(`${endDate.format("YYYY-MM-DD ") + endTime} +0000`);

      if (sDate.isSameOrAfter(eDate)) {
        errors.startDate = t("End Date should be later then Start Date");
        errors.endDate = " "; // means true
        errors.startTime = " "; // means true
        errors.endTime = " "; // means true
      }
    }

    if (!employeeId && !userProfileUuids) {
      errors.employee = t("Please select an employee");
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  }

  getTime = (date: moment.Moment, time: string, timeZone: string) =>
    momentTz.tz(date.format("YYYY-MM-DD ") + (time || "00:00"), "YYYY-MM-DD HH:mm", timeZone);

  onYes = () => {
    const { onYes } = this.props;
    const { type, userProfileUuids, startTime, endTime } = this.state;
    const timeZone = this.state.timeZone || window.global_store.company.time_zone;

    this.setState({ isLoading: true }, async () => {
      const startDate = this.getTime(this.state.startDate, startTime, timeZone);
      const endDate = this.getTime(this.state.endDate, endTime, timeZone);

      const payload = {
        content: {
          timezone: timeZone,
          startTime: startDate.utc().format(),
          endTime: endDate.utc().format(),
          type,
          createdBy: window.global_store.profile.uuid,
          userProfileUuids: userProfileUuids as string[],
          allDay: false,
        },
      };

      try {
        await createOnCall({
          body: payload,
          companyUuid: window.global_store.company.uuid,
        });

        onYes();
        this.setState({ ...this.getInitialState() });
      } catch (e) {
        const err = e as Error;

        Sentry.sendError(err);
        onYes(err);
      } finally {
        this.setState({ isLoading: false });
      }
    });
  };

  onCreateClick = () => {
    this.setState({ errors: this.getFormErrors() }, () => {
      if (!this.state.errors) {
        this.onYes();
      }
    });
  };

  render() {
    const { t, isOpen, onClose } = this.props;
    const { type, errors, startDate, endDate, lockedDate, startTime, endTime, isLoading, employeeFullName } =
      this.state;

    return (
      <SidePopupOverlay isOpen={isOpen} header={t("Add On Call")} onClose={onClose}>
        <Wrapper>
          <SearchControl
            trackingLocation={MassActionLocations.OnCall}
            permissionSection={PermissionSectionName.onCallManagement}
            onlyActive
            withMultiple
            locked={!hasPermisionAccess(PermissionSectionName.onCallManagement) || !hasEmployeesAccess()}
            value={employeeFullName || undefined}
            onChange={(data) => {
              let userProfileUuids: string[] = [];
              // won't be null if just one employee selected
              let employee = null;
              let lockDate = null;
              let sDate = startDate;
              let eDate = endDate;

              if (Array.isArray(data)) {
                userProfileUuids = data.map((e) => e.uuid);
              } else {
                employee = (data as SearchControlOnChangeData).employee || data;
                const lockDateStr = getLastLockDate(employee);
                lockDate = lockDateStr ? moment(lockDateStr, "YYYY-MM-DD") : null;

                if (lockDate && startDate.isSameOrBefore(lockDate, "day")) {
                  sDate = lockDate.clone().add(1, "day");
                }
                if (lockDate && endDate.isSameOrBefore(lockDate, "day")) {
                  eDate = lockDate.clone().add(1, "day");
                }

                userProfileUuids.push(employee.uuid);
              }

              this.setState({
                userProfileUuids,
                employeeId: employee ? employee.id : null,
                employeeFullName: employee ? employee.full_name : null,
                timeZone: employee ? employee.default_time_zone : window.global_store.company.time_zone,
                startDate: sDate,
                lockedDate: lockDate,
                endDate: eDate,
              });
            }}
            onClear={() =>
              this.setState({
                userProfileUuids: null,
                employeeId: null,
                employeeFullName: null,
                timeZone: null,
              })
            }
            placeholder={t("Search Employee")}
          />
          {errors && errors.employee && <ErrorLabel>{errors.employee}</ErrorLabel>}
          <FieldWrapper fieldName={t("Category")} width="100%">
            <Select
              modifiers={{ field: true }}
              value={type}
              onChange={(val: OnCallType) => this.setState({ type: val })}
              options={this.ON_CALL_TYPES}
            />
          </FieldWrapper>
          <DateTimeRow
            dateLabel={`${t("Start date")}:`}
            timeLabel={`${t("Time")}:`}
            date={startDate}
            time={startTime}
            onDateChange={(sd: moment.Moment) => {
              let ed = endDate;

              if (endDate.isBefore(sd, "day")) {
                ed = sd.clone();
              }

              this.setState({ startDate: sd, endDate: ed });
            }}
            onTimeChange={({ value }: { value: string }) => this.setState({ startTime: value })}
            dateError={!!(errors && errors.startDate)}
            timeError={errors && errors.startTime}
            isOutsideRange={(day: moment.Moment) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
          />
          {errors && errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
          {errors && errors.startTime && <ErrorLabel>{errors.startTime}</ErrorLabel>}
          <DateTimeRow
            dateLabel={`${t("End date")}:`}
            timeLabel={`${t("Time")}:`}
            date={endDate}
            time={endTime}
            onDateChange={(ed: moment.Moment) => {
              let sd = startDate;

              if (startDate.isAfter(ed, "day")) {
                sd = ed.clone();
              }

              this.setState({ endDate: ed, startDate: sd });
            }}
            onTimeChange={({ value }: { value: string }) => {
              this.setState({ endTime: value });
            }}
            dateError={!!(errors && errors.endDate)}
            timeError={errors && errors.endTime}
            isOutsideRange={(day: moment.Moment) => (lockedDate ? day.isSameOrBefore(lockedDate, "day") : false)}
          />
          {errors && errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
          {errors && errors.endTime && <ErrorLabel>{errors.endTime}</ErrorLabel>}
          <ButtonsWrapper>
            <Button value={t("Create")} disabled={isLoading} onClick={this.onCreateClick} />
          </ButtonsWrapper>
        </Wrapper>
      </SidePopupOverlay>
    );
  }
}

export default withTranslation(TranslationNamespaces.onCalls)(OnCallCreatePopup);
