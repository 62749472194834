import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useCompanyRules } from "hooks/useCompanyRules";
import { CompanyRuleNames } from "types/models/companyRules";
import { ReactComponent as IconClock } from "./icon-clock.svg";

const Wrapper = styled.div`
  min-height: 72px;
  background: rgba(227, 234, 242, 0.5);
  border-radius: var(--shapes-border-radius-default);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  padding-right: 7px;
  gap: 12px;
  font-size: var(--typography-font-size-default);

  svg {
    flex-shrink: 0;
  }

  p {
    margin: 0;
  }
`;

function RetroactiveChangeWarning(props: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const { data: companyRules } = useCompanyRules();
  const isMadanRashiEnabled = !!companyRules?.business_rules?.find(
    (rule) => rule.name === CompanyRuleNames.MADAN_RASHI_REPORT_AS_DIGITAL_SINGATURE,
  )?.value;

  if (!isMadanRashiEnabled) return null;

  return (
    <Wrapper {...props}>
      <IconClock />
      <div>{t("You are about to do a retroactive change, please try to avoid it in the future")}</div>
    </Wrapper>
  );
}

export default RetroactiveChangeWarning;
