import React from "react";
import styled from "styled-components";
import * as images from "components/svg-images";

const Clear = styled.button`
  width: 23px;
  height: 23px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  background: ${(props) => props.theme.colors.buttonBgColor};
`;

export default (props) => <Clear {...props}>{images.clear}</Clear>;
