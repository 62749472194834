import { Component, MouseEvent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import TextInput from "components/controls/TextInputControlNew";
import { showSnackbar, urlParam } from "utils/common";
import { login } from "utils/apiHelpers";
import Snackbar from "components/styled/Snackbar";
import { TranslationNamespaces } from "types/translationNamespaces";
import { SendRDStationActionRequestData } from "utils/api/types";
import { RDStationActionEventType } from "types/common";
import ga, { GaLoginMethod } from "utils/ga";
import OnboardingFieldWrapper from "./OnboardingFieldWrapper";
import MainPage from "./MainPage";
import { ErrorLabel } from "./styled";
import LoginButtons from "./LoginButtons";

const ForgotPassword = styled(Link)`
  line-height: 18px;
  font-size: 15px;
  font-weight: var(--typography-font-weight-medium);
  font-style: normal;
  color: var(--colors-primary);
  text-transform: none;
  cursor: pointer;
`;

interface LoginPageProps extends WithTranslation, RouteComponentProps {
  trafficSource?: string;
  trafficMedium?: string;
  trafficCampaing?: string;
  email?: string;
}

interface LoginPageState {
  email: string;
  password: string;
  errors: Record<string, string> | null;
  loading: boolean;
}

class LoginPage extends Component<LoginPageProps, LoginPageState> {
  readonly state: Readonly<LoginPageState> = {
    email: "",
    password: "",
    errors: null,
    loading: false,
  };

  async componentDidMount() {
    const { t, trafficSource, trafficMedium, trafficCampaing, email } = this.props;

    const ssoError = urlParam("sso_error");
    if (ssoError) {
      const ssoErrorArgs = urlParam("sso_error_args");
      if (ssoError === "missing_mapped_attributes") {
        showSnackbar({ text: t(ssoError, { attributes: ssoErrorArgs }) });
      } else {
        showSnackbar({ text: t(ssoError) });
      }
    }

    document.title = t("Sign-in");
    document.addEventListener("keyup", this.handleKeyPress.bind(this));

    const actionBody: SendRDStationActionRequestData = {
      body: {
        email: email || "",
        event_type: RDStationActionEventType.emailInput,
      },
    };

    if (trafficSource) {
      actionBody.body.traffic_source = trafficSource;
    }
    if (trafficMedium) {
      actionBody.body.traffic_medium = trafficMedium;
    }
    if (trafficCampaing) {
      actionBody.body.traffic_campaing = trafficCampaing;
    }

    if (email) {
      this.setState({ email });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyPress);
  }

  handleKeyPress = (ev: KeyboardEvent) => {
    if (ev.keyCode === 13) {
      this.onNextClick(ev);
    }
  };

  onNextClick = (ev: KeyboardEvent | MouseEvent) => {
    ev.preventDefault();
    const { password, email } = this.state;
    let errors: LoginPageState["errors"] = {};

    const passwordError = this.validatePassword();
    if (passwordError) {
      errors.password = passwordError;
    }
    const emailError = this.validateEmail();
    if (emailError) {
      errors.email = emailError;
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
      this.submitForm(email, password);
    } else {
      this.setState({ errors });
    }
  };

  submitForm = (email: string, password: string) => {
    const { t } = this.props;
    this.setState({ loading: true, errors: null }, async () => {
      try {
        const res = await login(email, password);

        window.global_store.profile = res.selected_user_profile;

        ga.trackLogin(GaLoginMethod.email);

        // session storage may get cleared during reloads/navigations that happen during login, so localStorage is more reliable
        const redirect = localStorage.getItem("oitchau_redirect");
        if (redirect) {
          window.location = `${redirect}` as unknown as Location;
        } else {
          window.location = "/" as unknown as Location;
        }
      } catch (error) {
        showSnackbar({ text: t(error as string) });
        this.setState({ loading: false });
      }
    });
  };

  validateEmail = () => {
    const { t } = this.props;
    const { email } = this.state;
    let error: string | null = null;

    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };

    if (!email) {
      error = t("Email Can't be Empty");
    } else if (!validate(email)) {
      error = t("Email is incorrect");
    }

    return error;
  };

  validatePassword = () => {
    const { t } = this.props;
    const { password } = this.state;
    let error: string | null = null;

    if (!password) {
      error = t("Password can't be empty");
    }

    return error;
  };

  render() {
    const { email, password, errors, loading } = this.state;
    const { t, history } = this.props;

    return (
      <MainPage
        topRowText={t("Don't have an account?")}
        topRowButtonText={t("Get started")}
        topRowButtonClick={() => history.push("/signup")}
        pageTitle={t("Login")}
        customButtons={<LoginButtons primaryButtonText={t("Login")} onPrimaryButtonClick={this.onNextClick} isLogin />}
        loading={loading}
        formParams={{
          noValidate: true,
          style: { paddingTop: "40px" },
          action: "/register",
          method: "post",
          id: "hs_login",
        }}
      >
        <OnboardingFieldWrapper fieldName={t("E-MAIL ADDRESS")}>
          <TextInput
            name="email"
            onboarding
            value={email}
            type="email"
            error={!!errors?.email}
            onChange={(ev) => this.setState({ email: ev.target.value })}
          />
          {!!errors?.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </OnboardingFieldWrapper>
        <OnboardingFieldWrapper
          fieldName={t("Password")}
          sideElement={
            <ForgotPassword tabIndex={-1} to="/forgot-password">
              {t("Recover password")}
            </ForgotPassword>
          }
        >
          <TextInput
            name="password"
            onboarding
            value={password}
            type="password"
            password
            error={!!errors?.password}
            onChange={(ev) => this.setState({ password: ev.target.value })}
          />
          {!!errors?.password && <ErrorLabel>{errors.password}</ErrorLabel>}
        </OnboardingFieldWrapper>
        <Snackbar />
      </MainPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(LoginPage);
