import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import GlobalContext from "context/global-context";
import { SelectOption } from "types/ui";
import { Project } from "types/models/projects";
import { getProjectsList } from "./projectsApiUtils";

type ProjectSelectOption = SelectOption<string | null>;

interface ProjectsDropdownControlProps extends WithTranslation {
  withEmptyItem?: boolean;
  emptyItemLabel?: string;
  onChange: (value: string) => void;
  value: string | null;
  disabled?: boolean;
  isMulti?: boolean;
  placeholder: string;
}

interface ReportSearchFiltersState {
  isLoading: boolean;
  options: ProjectSelectOption[];
}

class ProjectsDropdownControl extends Component<ProjectsDropdownControlProps, ReportSearchFiltersState> {
  static contextType = GlobalContext;

  constructor(props: ProjectsDropdownControlProps) {
    super(props);
    this.state = {
      isLoading: true,
      options: [],
    };
  }

  async componentDidMount() {
    const { withEmptyItem, emptyItemLabel } = this.props;
    const company = await this.context.getCompany();
    const response = await getProjectsList({ companyUuid: company.uuid, status: "active" });
    let options: ProjectSelectOption[] = [];

    if (withEmptyItem && emptyItemLabel) {
      options.push({ label: emptyItemLabel, value: null });
    }

    const items: Project[] = response.content || response || [];

    options = options.concat(items.map((item) => ({ label: item.name, value: item.uuid })));

    this.setState({ options, isLoading: false });
  }

  render() {
    const { t, onChange, value, disabled, isMulti, placeholder } = this.props;
    const { options, isLoading } = this.state;

    return (
      <SelectControl
        options={options}
        onChange={onChange}
        isLoading={isLoading}
        disabled={disabled}
        value={value}
        isSearchable
        isMulti={isMulti}
        placeholder={placeholder || t("Please select projects")}
      />
    );
  }
}

export default withTranslation("projects")(ProjectsDropdownControl);
