import { Component, ContextType, lazy, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation, WithTranslation, withTranslation } from "react-i18next";
import { Feature, Subscription, SubscriptionStatus } from "types/models/subscription";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BillingService } from "components/Billing/BillingService";
import { Link } from "react-router-dom";
import PricingContainer from "components/Billing/PricingContainer";
import UpgradeSeatsModal from "components/Billing/Modals/UpgradeSeatsModal";
import ModalDialog from "components/UI/ModalDialog";
import GlobalContext from "context/global-context";
import { SubscriptionApi } from "utils/api/subscription";
import { ReactComponent as CrossIcon } from "components/Billing/icons/cross.svg";
import { GlobalStoreCompany } from "types/models/company";

const PendingInvoiceNotification = lazy(() => import("../Billing/components/PendingInvoiceNotification"));

type TrialBarProps = WithTranslation;

interface TrialBarState {
  subscriptions: Subscription[];
  loaded: boolean;
  company?: GlobalStoreCompany;
}

const TrialBarWrapper = styled.div`
  display: flex;
  background: var(--colors-warning-50);
  color: white;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 24px;
  font-size: var(--typography-font-size-default);
`;

const TrialMessage = styled.div`
  color: var(--colors-surface-900-p);
`;

const CloseIconContainer = styled.div`
  margin-inline-start: auto;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UpgradeButton = styled(Link)`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #f3992d;
  color: var(--colors-surface-0);
  padding: 9px 12px;
  font-weight: var(--typography-font-weight-medium);
  border-radius: var(--shapes-border-radius-default);
  margin: 0 16px;
  font-size: var(--typography-font-size-default);
  line-height: 14px;
`;

const UpgradeSeatsButton = styled("button")`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--colors-surface-0);
  color: var(--colors-surface-900-p);
  padding: 9px 12px;
  font-weight: var(--typography-font-weight-medium);
  border-radius: var(--shapes-border-radius-default);
  margin: 0 16px;
  font-size: var(--typography-font-size-default);
  line-height: 14px;
`;

const UpgradeSeatsBtn = (props: { product: Feature }) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [updateSeatsPopupVisible, setUpdateSeatsPopupVisible] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    void init();
  }, []);

  const init = async () => {
    await PricingContainer.getProducts();
    await PricingContainer.getSubscriptionCoupon();
    await PricingContainer.getCurrentSeatsUsed();
    setLoaded(true);
  };

  const openModal = async () => {
    await PricingContainer.selectProduct(props.product);
    await PricingContainer.selectPrice(
      PricingContainer.state.interval,
      t,
      PricingContainer.state.currentSeatsNumber,
      true,
    );
    setUpdateSeatsPopupVisible(true);
  };

  return (
    <>
      <UpgradeSeatsButton disabled={!loaded} onClick={() => openModal()}>
        {t("Add seats")}
      </UpgradeSeatsButton>

      <ModalDialog
        preventOutsideClose
        onClose={() => setUpdateSeatsPopupVisible(false)}
        isOpen={updateSeatsPopupVisible}
        width={464}
        top="120px"
      >
        {updateSeatsPopupVisible && (
          <>
            <UpgradeSeatsModal
              companyUuid={PricingContainer.state.companyUuid}
              coupon={PricingContainer.state.coupon}
              close={() => setUpdateSeatsPopupVisible(false)}
              productItem={PricingContainer.productItems[0]}
              currentSeatsNumber={PricingContainer.state.currentSeatsNumber!}
              interval={PricingContainer.state.interval}
              subscription={PricingContainer.state.activeSubscription!}
            />
          </>
        )}
      </ModalDialog>
    </>
  );
};

class TrialBar extends Component<TrialBarProps, TrialBarState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;
  constructor(props: TrialBarProps) {
    super(props);
    this.state = {
      loaded: false,
      subscriptions: [],
    };
  }

  componentDidMount() {
    void this.getSubscriptionsData();
  }

  async getSubscriptionsData() {
    const company = await this.context.getCompany();
    await PricingContainer.setCompanyUuid(company.uuid);
    await PricingContainer.getSubscriptions();

    const subscriptions = localStorage.getItem("gs_subscriptions");
    this.setState({
      subscriptions: subscriptions ? JSON.parse(subscriptions) : [],
      loaded: true,
      company,
    });
  }

  clearNotification = async () => {
    await SubscriptionApi.clearAutoUpgradeNotification(
      PricingContainer.state.companyUuid,
      this.state.subscriptions[0].uuid!,
    );
    await BillingService.getInitialCustomerData(PricingContainer.state.companyUuid);
    void this.getSubscriptionsData();
  };

  render() {
    const isAdmin = BillingService.checkIsCompanyAdmin();
    const isOwner = BillingService.checkIsCompanyAdmin(true);
    const { t } = this.props;
    const { subscriptions, loaded, company } = this.state;
    const activeSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Active);
    const trialSubscription = BillingService.findSubscriptions(subscriptions, SubscriptionStatus.Trialing);
    if (!isAdmin) return null;

    const trialDaysCount = trialSubscription ? BillingService.getTrialDays(trialSubscription) : null;
    const displayTrialNotification = isAdmin && loaded && trialSubscription && trialDaysCount !== null;
    const displayAutoUpgradeNotification =
      isOwner && loaded && activeSubscription && !!activeSubscription.autoUpgradeDate;
    const displayPendingPaymentNotification = loaded && BillingService.requireActOnPendingInvoice(subscriptions);

    if (displayTrialNotification || displayAutoUpgradeNotification || displayPendingPaymentNotification) {
      const elements = [
        ...(document.getElementsByClassName("layout__main-panel-settings") as HTMLCollectionOf<HTMLElement>),
        ...(document.getElementsByClassName("layout__main-panel") as HTMLCollectionOf<HTMLElement>),
      ];
      const offset = displayPendingPaymentNotification ? "132px" : "108px";
      elements.forEach((el) => {
        el.style.top = offset;
      });
    }
    if (displayPendingPaymentNotification) return <PendingInvoiceNotification isAdmin={isAdmin} isOwner={isOwner} />;
    if (!displayTrialNotification && !displayAutoUpgradeNotification) return null;

    return (
      <TrialBarWrapper>
        {displayTrialNotification && (
          <>
            <TrialMessage
              dangerouslySetInnerHTML={{ __html: t("your-trial-ends-in {{trialDaysCount}}", { trialDaysCount }) }}
            />
            <UpgradeButton to="/billing/pricing">{t("Upgrade Now")}</UpgradeButton>
          </>
        )}
        {displayAutoUpgradeNotification && (
          <>
            <TrialMessage
              dangerouslySetInnerHTML={{ __html: t("seats_limit_crossed {{appName}}", { appName: company?.name }) }}
            />
            {activeSubscription!.items.length === 1 ? (
              <UpgradeSeatsBtn product={activeSubscription!.items[0].feature} />
            ) : (
              <UpgradeButton
                to="/billing/summary"
                style={{
                  background: "var(--colors-surface-0)",
                  color: "var(--colors-surface-900-p)",
                }}
              >
                {t("Add seats")}
              </UpgradeButton>
            )}
            <CloseIconContainer onClick={() => this.clearNotification()}>
              <CrossIcon />
            </CloseIconContainer>
          </>
        )}
      </TrialBarWrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.subscription)(TrialBar);
