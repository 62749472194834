import { MouseEvent, useEffect, useRef, useState } from "react";
import { stylesheet } from "astroturf";
import PunchNowButton from "components/Layout/PunchNowButton";
import cx from "classnames";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { Feature } from "types/models/subscription";
import ClickOutside from "react-click-outside";
import { GetExtensionPopupView } from "../views/GetExtensionPopupView";
import { services } from "../services";
import { PunchNowPopup } from "../PunchNowPopup";

const styles = stylesheet`
.Wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  width: 350px;
  height: 500px;
  visibility: hidden;
  background: none;
  opacity: 0;
  transition: all 200ms ease-in;
  top: 52px;
  bottom: inherit;
  inset-inline-start: 73px;
  z-index: 150;

  > * {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 16%);
    transition: all 200ms ease-in;
  }

  .PunchNowModal {
    position: absolute;
    top: 0;
    width: 100%;
    height: 542px;
    background: var(--colors-default);
  }

  .GetExtensionPopup {
    position: absolute;
    bottom: 0;
    flex-shrink: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
  }

  &.opened {
    height: 542px;
    visibility: visible;
    opacity: 1;
  }

  &.showGetExtension {
    .PunchNowModal {
      height: 379px;
    }

    .GetExtensionPopup {
      height: 151px;
      pointer-events: auto;
      opacity: 1;
    }
  }
}
`;

export function PunchNowButtonWithModal() {
  const extensionElRef = useRef<HTMLDivElement | null>(null);

  const { profile } = window.global_store;
  const showPunchNowButton =
    profile.allow_web_punch &&
    (profile.associate_products.includes(Feature.TA) || profile.associate_products.includes(Feature.Bundle));

  const [opened, setOpened] = useState(false);
  const onAddPunchClick = () => {
    if (opened) {
      setOpened(false);
      return;
    }

    setOpened(true);
  };

  const extensionUrl = services.extensionService.getExtensionDownloadUrl();

  const [showDownload, setShowDownload] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const [init] = useAsyncCallback(async () => {
    if (!opened) return;
    const dontShow = await services.appStorage.getDownloadExtensionPopupSilenced();
    const installedExtensionVersion = await services.extensionService.getExtensionVersion();
    setDontShowAgain(!!dontShow);
    setShowDownload(!dontShow && installedExtensionVersion == null);
  }, [opened]);

  useEffect(() => void init(), [init]);

  const [changeDontShowAgain] = useAsyncCallback(async (dontShow) => {
    await services.appStorage.setDownloadExtensionPopupSilenced(dontShow);
    setDontShowAgain(dontShow);
  }, []);

  return (
    showPunchNowButton && (
      <>
        <div
          className={cx(styles.Wrapper, {
            [styles.showGetExtension]: showDownload && extensionUrl != null,
            [styles.opened]: opened,
          })}
        >
          <ClickOutside
            className={styles.PunchNowModal}
            onClickOutside={(e: MouseEvent<HTMLElement>): void => {
              const extensionEl = extensionElRef.current;

              if (extensionEl && extensionEl.contains(e.target as Node)) {
                return;
              }

              setOpened(false);
            }}
          >
            {opened && <PunchNowPopup onClose={() => setOpened(false)} />}
          </ClickOutside>
          <GetExtensionPopupView
            ref={extensionElRef}
            className={styles.GetExtensionPopup}
            onClose={() => setShowDownload(false)}
            extensionUrl={extensionUrl!}
            dontShowAgain={dontShowAgain}
            onDontShowAgainChange={changeDontShowAgain}
          />
        </div>

        <PunchNowButton onPunchNowClick={onAddPunchClick} />
      </>
    )
  );
}
