import { useEffect } from "react";
import { appendScript } from "utils/common";

export const useHubspot = () => {
  useEffect(() => {
    appendScript({
      id: `hs-script-loader`,
      async: true,
      defer: true,
      src: `https://js.hs-scripts.com/20984162.js`,
    });
  }, []);
};
