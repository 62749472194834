import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import Select from "components/UI/FormSelect";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { TranslationNamespaces } from "types/translationNamespaces";

const PeriodRow = styled.div`
  position: relative;
  padding-inline-end: 16px;
  width: 196px;
`;

const RemoveButton = styled.div`
  position: absolute;
  inset-inline-end: 0;
  bottom: 0;
  height: 36px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: 100% center;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.11.8a.914.914 0 111.292 1.293L2.093 6.402A.914.914 0 01.801 5.109L5.109.801z' fill='%23A1B2CF'/%3E%3Cpath d='M6.402 5.11a.914.914 0 11-1.293 1.292L.801 2.093A.914.914 0 112.093.801l4.309 4.308z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 16px;
`;

const Wrapper = styled.div`
  margin: 8px 0;
`;

const DEFAULT_OPTIONS = [...Array(30)].map((_, i) => ({ value: i + 1, label: i + 1 }));

interface PredefinedPeriodsSelectionProps {
  onChange: (values: number[]) => void;
  values: number[];
}

const PredefinedPeriodsSelection = ({ onChange, values }: PredefinedPeriodsSelectionProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  const availableOptions = DEFAULT_OPTIONS.filter((val) => values.indexOf(val.value) === -1);

  return (
    <Wrapper>
      {values.map((value, i) => (
        <PeriodRow key={value}>
          <FieldWrapper
            fieldName={i === 0 ? t("Periods") : ""}
            width="100%"
            fieldTitleMarginTop={i === 0 ? 0 : 16}
            fieldTitleMarginBottom={8}
          >
            <Select<number, number>
              modifiers={{ field: true }}
              value={value}
              onChange={(val) => {
                const returnValues = values;
                returnValues[i] = val;

                onChange(returnValues);
              }}
              options={availableOptions}
            />
            {values.length > 1 ? (
              <RemoveButton
                onClick={() => {
                  const returnValues = values;
                  returnValues.splice(i, 1);
                  onChange(returnValues);
                }}
              />
            ) : null}
          </FieldWrapper>
        </PeriodRow>
      ))}

      <Spacer />

      <AddRemoveLink
        label={t("Add period")}
        onClick={(ev) => {
          const returnValues = values;
          returnValues.push(availableOptions[0].value);
          onChange(returnValues);
        }}
      />
    </Wrapper>
  );
};

export default PredefinedPeriodsSelection;
