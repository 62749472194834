import styled from "styled-components";
import rtl from "styled-components-rtl";
import arrowRight from "../Employees/img/arrowRight.svg";

export default styled.div`
  font-size: var(--typography-font-size-default);
  font-weight: ${(props) => (props.active ? "500" : "400")};
  color: ${(props) => props.theme.colors.text1};
  line-height: 35px;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-end: 32px;
  padding-inline-start: 19px;
  border-radius: var(--shapes-border-radius-default);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background: ${(props) => (props.active ? props.theme.colors.activitiCellScheduleItemHover : "none")};
  &:after {
    content: "";
    background-repeat: no-repeat;
    background-image: url("${arrowRight}");
    ${(p) => (p.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
    width: 11px;
    height: 8px;
    position: absolute;
    ${rtl`
      right: 16px;
    `}
    top: calc(50% - 4px);
    display: ${(props) => (props.active ? "block" : "none")};
  }
  cursor: pointer;
  &:hover {
    background: #f3f5fa;
    .remove-button {
      display: block;
    }
    &:after {
      display: block;
    }
  }
  svg {
    margin-inline-end: 16px;
    margin-bottom: -1px;
  }
`;
