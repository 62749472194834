import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { Moment } from "moment";
import { getDateWithTZ } from "utils/common";
import { Request } from "types/models/request";
import { TranslationNamespaces } from "types/translationNamespaces";
import RequestVacationDaysInfoRow from "./RequestVacationDaysInfoRow";
import { getWarning, fetchVacationBalance } from "./requestUtils";

const BlockUsedAvailableDays = styled.div`
  width: 100%;
  margin-inline-start: 24px;
`;

const RowUsedAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BlockRequestDays = styled.div`
  padding-inline-start: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--colors-surface-150);
`;

const DaysCount = styled.div`
  line-height: 20px;
  font-size: 18px;
  white-space: nowrap;
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-unknown22);
`;

const Description = styled.div`
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-unknown23);
`;

interface RequestDetailsVacationDaysProps extends WithTranslation {
  request: Request;
}

interface RequestDetailsVacationDaysState {
  usedDays: number;
  availableDays: number;
}

class RequestDetailsVacationDays extends Component<RequestDetailsVacationDaysProps> {
  readonly state: Readonly<RequestDetailsVacationDaysState> = {
    usedDays: 0,
    availableDays: 0,
  };

  UNSAFE_componentWillMount() {
    const { request } = this.props;
    const startDate = getDateWithTZ(request.startTime, request.timezone);
    const endDate = getDateWithTZ(request.endTime, request.timezone);
    const { totalDaysLimit } = request.requestSubtypeState.complianceRules;

    if (totalDaysLimit.active) {
      void this.requestVacationBalance(request.employee.uuid, startDate, endDate);
    }
  }

  getCountStr = (count: number) => {
    const { t } = this.props;

    return count === 1 ? t("common|day") : t("common|days");
  };

  getCount = (count: number) => (count < 10 && count > 0 ? `0${count}` : count);

  requestVacationBalance = async (userProfileUuid: string, from: Moment, to: Moment) => {
    const resp = await fetchVacationBalance(userProfileUuid, from, to);

    this.setState(resp);
  };

  getRequestDaysColor = (warning: string, requestDaysCount: number) => {
    const { t } = this.props;
    const { availableDays } = this.state;

    if (!warning) {
      return "var(--colors-success2)";
    }
    if (warning === t("Vacation duration is longer than the available days") || requestDaysCount > availableDays) {
      return "var(--colors-unknown21)";
    }

    return "var(--colors-surface-900-p)";
  };

  render() {
    const { t, request } = this.props;
    const { usedDays, availableDays } = this.state;
    const { daysBeforeRequestDate, predefinedPeriods, totalDaysLimit } = request.requestSubtypeState.complianceRules;
    const startDate = getDateWithTZ(request.startTime, request.timezone);
    const endDate = getDateWithTZ(request.endTime, request.timezone);
    const requestDaysCount = endDate.endOf("day").diff(startDate.startOf("day"), "days") + 1;
    const warning = getWarning({
      t,
      daysBeforeRequestDate,
      predefinedPeriods,
      availableDays,
      startDate,
      endDate,
      validateAvailableDays: totalDaysLimit.active,
      selectedPeriod: 0,
    });

    return (
      <div>
        {totalDaysLimit.active || warning ? <br /> : null}
        {totalDaysLimit.active ? (
          <Wrapper style={warning ? { borderBottom: "none" } : {}}>
            <BlockRequestDays style={{ borderInlineStart: "1px solid var(--colors-surface-150)" }}>
              <DaysCount
                style={{
                  lineHeight: "23px",
                  color: this.getRequestDaysColor(warning, requestDaysCount),
                }}
              >
                {this.getCount(requestDaysCount)} {this.getCountStr(requestDaysCount)}
              </DaysCount>
              <Description>{t("requested-days")}</Description>
            </BlockRequestDays>
            <BlockUsedAvailableDays>
              <RowUsedAvailable>
                <Description>{t("Available")}</Description>
                <DaysCount>
                  {this.getCount(availableDays)} {this.getCountStr(availableDays)}
                </DaysCount>
              </RowUsedAvailable>
              <RowUsedAvailable>
                <Description>{t("Used")}</Description>
                <DaysCount>
                  {this.getCount(usedDays)} {this.getCountStr(usedDays)}
                </DaysCount>
              </RowUsedAvailable>
            </BlockUsedAvailableDays>
          </Wrapper>
        ) : null}
        {warning ? <RequestVacationDaysInfoRow>{warning}</RequestVacationDaysInfoRow> : null}
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.requestsPageTmp)(RequestDetailsVacationDays);
