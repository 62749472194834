import { useState, useEffect, useCallback, useMemo, CSSProperties } from "react";
import { getClients, addClient } from "utils/apiHelpers";
import ModalDialog from "components/UI/ModalDialog";
import Select from "components/UI/Select";
import SelectControl from "components/UI/SelectControl";
import BEM from "utils/BEM";
import { Client } from "types/models/client";
import { SelectOption } from "types/ui";
import ClientPopupMessage from "components/Clients/PopupMessage";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";

const b = BEM.b("reports-page");

interface ClientsDropdownProps {
  loading?: boolean;
  value: string;
  onChange: (v: string | null, o: SelectOption | null) => void;
  placeholder?: string;
  disabled?: boolean;
  defaultSelectView?: boolean;
  withEmptyOption?: boolean;
  isClearable?: boolean;
  withCreateOption?: boolean;
}

function getClientsOptions(clients: Client[], withEmptyOption: boolean, emptyOptionLabel?: string): SelectOption[] {
  const options: SelectOption[] = [];
  if (withEmptyOption && emptyOptionLabel) {
    options.push({ label: emptyOptionLabel, value: "" });
  }

  clients.forEach((client) => {
    options.push({ label: client.name, value: client.uuid });
  });
  return options;
}

function ClientsDropdown({
  value,
  onChange,
  defaultSelectView,
  disabled,
  placeholder,
  loading,
  withEmptyOption = false,
  isClearable = false,
  withCreateOption,
}: ClientsDropdownProps) {
  const [t] = useTranslation(TranslationNamespaces.clients);

  const [createPopupVisible, setCreatePopupVisible] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    void getClients().then((result) => {
      const clients = (result.clients || []) as Client[];
      const optionsToSet = getClientsOptions(clients, withEmptyOption, placeholder);
      setOptions(optionsToSet);
      if (!clients.length) return;
      let selectedOption = optionsToSet.find((o) => o.value === value);
      if (!selectedOption && !isClearable) {
        [selectedOption] = optionsToSet;
        onChange(selectedOption.value, selectedOption);
      }
    });
  }, []);

  const onChangeCallback = useCallback(
    (v: string) => {
      const selectedOption = options.find((o) => o.value === v);
      if (!selectedOption && !isClearable) return;
      if (!selectedOption && isClearable) {
        onChange(null, null);
        return;
      }
      onChange(selectedOption!.value, selectedOption!);
    },
    [options, onChange],
  );

  const handleAddClient = useCallback(
    async (item) => {
      try {
        const res = await addClient({ body: { client: { ...item, manager_ids: [] } } });
        const newOption = getClientsOptions([res.client], false)[0];
        setOptions([...options, newOption]);
        onChange(newOption.value, newOption);
        setCreatePopupVisible(false);
      } catch (e) {
        console.log(e);
      }
    },
    [onChange, options],
  );

  return (
    <>
      <SelectControl
        disabled={disabled}
        options={options}
        onChange={onChangeCallback}
        isLoading={loading}
        value={value}
        isSearchable
        placeholder={placeholder}
        withCreateOption={withCreateOption}
        onCreateOption={() => setCreatePopupVisible(true)}
        createOptionText={t("Create new client")}
        isClearable={isClearable}
        styles={{ menu: (base: CSSProperties) => ({ ...base, width: "auto" }) }}
      />

      <ModalDialog isOpen={createPopupVisible} onClose={() => setCreatePopupVisible(false)}>
        <ClientPopupMessage
          title={t("Add Client")}
          item={{}}
          allItems={[]}
          buttonYesTitle={t("Create a new client ")}
          onClose={() => setCreatePopupVisible(false)}
          onYes={handleAddClient}
        />
      </ModalDialog>
    </>
  );
}

export default ClientsDropdown;
