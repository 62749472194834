/** check if value is a valid HH:mm time. */
export function validateTime(HHmm: string) {
  if (!HHmm) return false;
  const re = /^(([01][0-9])|([2][0-3])):[0-5][0-9]$/;
  return HHmm.match(re) !== null;
}

/** Check if value may become a valid HH:mm time after adding more symbols. */
export function validateInput(val: string) {
  const re = /^(([01]?[0-9]?)|([2]?[0-3]?)):[0-5]?[0-9]?$/;
  return val.match(re) !== null;
}

/** Remove not allowed symbols from value. */
export function sanitizeInput(val: string) {
  let v = val;
  v = v.replace(/[^0-9:]/g, "");
  const firstColonIndex = v.indexOf(":");
  if (firstColonIndex !== -1) {
    // because lookbehind is not supported by safari
    v = `${v.slice(0, firstColonIndex + 1)}${v.slice(firstColonIndex + 1).replace(/:/g, "")}`;
  }
  if (!v.includes(":")) {
    v = `${v.slice(0, 2)}:${v.slice(2)}`;
  }
  return v;
}

/** If value is incomplete than assume how a finished input would look like. */
export function assumeInput(value: string) {
  let val = value;
  const [hhSpl = "00", mmSpl = "00"] = val.split(":");
  const [hh, mm] = [hhSpl, mmSpl].map((v) => Number.parseInt(v.slice(0, 2), 10)).map((v) => (Number.isNaN(v) ? 0 : v));
  const hhStr = String(hh).padStart(2, "0");
  const mmStr = mmSpl.length <= 1 && mm < 6 ? String(mm).padEnd(2, "0") : String(mm).padStart(2, "0");
  val = `${hhStr}:${mmStr}`;

  const isValid = validateTime(val);
  if (!isValid) {
    val = "00:00";
  }
  return val;
}

export type Sel = [start: number, end: number];

/**
 * Take current value before change, selection and key pressed.
 * Return a new value and selection or null if no smart handling should be applied.
 */
export function handleKeySmart(val: string, sel: Sel, key: string) {
  const [ss, se] = sel[0] < sel[1] ? sel : [sel[1], sel[0]];
  if (ss !== se && val.slice(ss, se).includes(":")) {
    const v = `${val.slice(0, ss)}:${val.slice(se)}`;
    if (key === "Backspace") {
      return { val: v, sel: ss };
    }
    if (key === "Delete") {
      return { val: v, sel: ss + 1 };
    }
  }
  if (ss !== se) return null;
  if (key === "Backspace" && val[ss - 1] === ":") {
    return { val, sel: ss - 1 };
  }
  if (["Delete", ":", " ", ".", ",", ";", "/"].includes(key) && val[ss] === ":") {
    return { val, sel: ss + 1 };
  }
  if (val === ":" && ss === 0) {
    if (" :".includes(key)) {
      return { val: "00:", sel: 3 };
    }
    if ("012".includes(key)) {
      return { val: `${key}:`, sel: 1 };
    }
    if ("3456789".includes(key)) {
      return { val: `0${key}:`, sel: 3 };
    }
  }
  if (val.match(/^[0-9]:$/) && ss === 1) {
    if ("0123456789".includes(key)) {
      return { val: `${val[0]}${key}:`, sel: 3 };
    }
    if (" :".includes(key)) {
      return { val: `0${val[0]}:`, sel: 3 };
    }
  }
  if (val.match(/^2:$/) && ss === 1) {
    if ("45".includes(key)) {
      return { val: `02:${key}`, sel: 4 };
    }
    if ("6789".includes(key)) {
      return { val: `02:0${key}`, sel: 5 };
    }
  }
  if (val.match(/^[0-9][0-9]:$/) && (ss === 2 || ss === 3)) {
    if ("6789".includes(key)) {
      return { val: `${val}0${key}`, sel: 5 };
    }
  }
  return null;
}
