import axios from "axios";
import moment from "moment";
import { generateUrlParams } from "utils/common";
import CONFIG from "config";
import { getLocale } from "utils/translationHelpers";
import { sendRequest, getHeaders, APIResponse } from "./common";
import {
  CheckDownloadsUuidsRequestData,
  CheckDownloadsUuidsResponseData,
  FireDowloadReportResponseData,
} from "./types";

type TODORequestData = unknown;
type TODOResponseData = Promise<never>;

export function downloadFile(
  linkToFile,
  fileName,
  byEmail,
  withHeaders = true,
  extension = "",
  throwError,
): TODOResponseData {
  return axios({
    method: "GET",
    url: linkToFile,
    headers: withHeaders ? getHeaders() : null,
    responseType: "blob",
  })
    .then((response) => {
      let fullFileName = `${fileName}${extension}`;
      if (response.headers && response.headers["report-filename"]) {
        fullFileName = response.headers["report-filename"];
      }
      if (!byEmail) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fullFileName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log(err);

      if (throwError) {
        throw new Error(err);
      }
    });
}

export function downloadAttachment(data: TODORequestData): TODOResponseData {
  return downloadFile(data.url, `${data.title}`, false, false, data.extension);
}

export function downloadAfdReport(data: TODORequestData): TODOResponseData {
  // http://oitchau-api-s.dev.ua:8080/services/api/mobile/companies/128/afd?from=2017-06-13&to=2017-12-18
  let url = `${CONFIG.protocol}${CONFIG.client_admin_api}/companies/${data.companyUUID}/reporting?type=${
    data.sourceType
  }&from=${moment(data.startDate).format("YYYY-MM-DD")}&to=${moment(data.endDate).format("YYYY-MM-DD")}`;
  if (data.cnpj) {
    url += `&cnpj=${data.cnpj}`;
  }
  if (data.cpf) {
    url += `&cpf=${data.cpf}`;
  }
  return downloadFile(url, `${data.sourceType}.txt`);
}

export function downloadScheduleImportErrorLog(data: TODORequestData): TODOResponseData {
  return downloadFile(
    `${CONFIG.protocol}${CONFIG.api}/file_imports/${data.fileImportUuid}/log`,
    data.filename,
    false,
    true,
    ".txt",
    true,
  );
}

export function fireDownloadEmployees(data: TODORequestData = {}): TODOResponseData {
  let url = "/employees/report?report_format=xlsx&";
  const params = [`locale=${getLocale()}`];

  if (data.selectedColumns) {
    // todo make it more fancy
    params.push(`show_columns=external_id,supervisor_external_id,associate_products,${data.selectedColumns}`);
  }
  if (data.status) {
    params.push(`status=${data.status}`);
  }
  if (data.employee_statuses) {
    params.push(`employee_statuses=${data.employee_statuses}`);
  }
  if (data.role) {
    params.push(`role=${data.role}`);
  }
  if (data.withoutSchedule) {
    params.push("has_schedule=false");
  }
  url += params.join("&");
  return sendRequest({}, "GET", url, CONFIG.api_download);
}

export function fireDownloadPunches(data: TODORequestData = {}): TODOResponseData {
  let url = "/punches/report?report_format=xlsx&";
  url += generateUrlParams(data);
  if (data.selectedColumns) {
    url += `&show_columns=${data.selectedColumns}`;
  }
  url += `&locale=${getLocale()}`;

  return sendRequest({}, "GET", url, CONFIG.api_download);
}

export function fireDownloadRequests(data: TODORequestData = {}): TODOResponseData {
  let url = "/requests/report?";
  url += generateUrlParams(data);
  if (data.selectedColumns) {
    url += `&show_columns=${data.selectedColumns}`;
  }
  if (data.requestSubTypes) {
    url += `&request_subtypes=${data.requestSubTypes}`;
  }
  url += `&locale=${getLocale()}`;

  return sendRequest({}, "GET", url, CONFIG.api_download);
}

export function fireDownloadReport(data: TODORequestData = {}): APIResponse<FireDowloadReportResponseData> {
  const format = data.format || "pdf";

  const {
    searchObj,
    startDate,
    endDate,
    reportType,
    clientUUID,
    companyUUID,
    punchType,
    selectedColumns,
    customFields,
    skipSupervisors,
    onlyNonZeroLines,
    showInactiveEmploees,
    locationUuid,
    byEmail,
    onSamePage,
    oldReport,
    reportHash,
    digitalSignatureUuid,
    status,
    clientUuid,
    projectUuid,
    showServicesSummary,
    forceDs,
    background,
    customPeriod,
  } = data;
  let paramValueArr = [
    `report_format=${format}`,
    `report_type=${reportType}`,
    `locale=${getLocale()}`,
    `send_to_email=${byEmail || false}`,
    `rand=${new Date().getTime()}`,
  ];
  if (startDate) {
    paramValueArr.push(`start_date=${moment(startDate).format("YYYY-MM-DD")}`);
  }
  if (endDate) {
    paramValueArr.push(`end_date=${moment(endDate).format("YYYY-MM-DD")}`);
  }
  if (reportHash) {
    paramValueArr.push(`report_hash=${reportHash}`);
  }
  if (digitalSignatureUuid) {
    paramValueArr.push(`digital_signature_uuid=${digitalSignatureUuid}`);
    if (forceDs) {
      paramValueArr.push(`force_ds=true`);
    }
  }
  if (window.global_store.beta || customPeriod) {
    paramValueArr.push("custom_period=true");
  }

  const paramValueGroupParamArr = [];
  const groupType = searchObj && searchObj.type;
  if (!groupType && searchObj && searchObj.employee) {
    paramValueArr.push(`user_profile_uuid=${searchObj.employee.uuid}`);
  } else if (clientUUID) {
    paramValueArr.push(`client_uuid=${clientUUID}`);
  }

  if (groupType && searchObj.uuid) {
    paramValueGroupParamArr.push(`group_type=${groupType}`);
    paramValueGroupParamArr.push(`group_uuid=${searchObj.uuid}`);
  }
  if (clientUuid) {
    paramValueGroupParamArr.push(`client_uuid=${clientUuid}`);
  }
  if (projectUuid) {
    paramValueGroupParamArr.push(`project_uuid=${projectUuid}`);
  }
  if (locationUuid) {
    paramValueGroupParamArr.push(`location_uuid=${locationUuid}`);
  }
  if (punchType) {
    paramValueGroupParamArr.push(`punch_type=${punchType}`);
  }
  if (skipSupervisors) {
    paramValueGroupParamArr.push("skip_supervisors=true");
  }
  if (onlyNonZeroLines) {
    paramValueGroupParamArr.push("remove_zeros=1");
  }

  if (!showInactiveEmploees) {
    paramValueGroupParamArr.push("active=true");
  }
  if (status) {
    paramValueGroupParamArr.push(`status=${status}`);
  }
  if (onSamePage) {
    paramValueGroupParamArr.push("pdf_one_file=true");
  }
  if (companyUUID) {
    paramValueGroupParamArr.push(`company_uuid=${companyUUID}`);
  }
  if (oldReport) {
    paramValueGroupParamArr.push("old_report=true");
  }
  if (showServicesSummary) {
    paramValueGroupParamArr.push("with_service_summary=true");
  }
  if (selectedColumns) {
    const cols = selectedColumns.replace(/cumulativeHoursBankMinutes,|cumulativeHoursBankMinutes/, "");
    paramValueArr.push(`show_columns=${cols}`);
  }
  if (customFields) {
    paramValueArr.push(`custom_fields=${customFields}`);
  }
  if (background) {
    paramValueArr.push("background=true");
  }
  paramValueArr = paramValueArr.concat(paramValueGroupParamArr);
  const paramsStr = paramValueArr.join("&");
  const url = `/client_reports/generate?${paramsStr}`;

  return sendRequest({}, "GET", url, CONFIG.api_download);
}

export function checkDownloadsUuids(
  data: CheckDownloadsUuidsRequestData,
): APIResponse<CheckDownloadsUuidsResponseData> {
  const url = `/client_reports?uuids=${data.uuids.join(",")}`;

  return sendRequest({}, "GET", url, CONFIG.api_download);
}
