import { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import MyDigitalSignatures from "components/Payroll/MyDigitalSignatures";
import MyDigitalSignatureReport from "components/Payroll/MyDigitalSignatureReport";

const MyDigitalSignaturesRoutes = ({ match: { path } }) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect strict exact from={`${path}`} to={`${path}/`} />
      <Redirect
        strict
        exact
        from={`${path}/:digitalSignatureUuid/:reportHash/`}
        to={`${path}/:digitalSignatureUuid/:reportHash`}
      />
      <Route exact strict path={`${path}/`} component={MyDigitalSignatures} />
      <Route exact strict path={`${path}/:digitalSignatureUuid/:reportHash`} component={MyDigitalSignatureReport} />
    </Switch>
  </Suspense>
);

export default MyDigitalSignaturesRoutes;
