import { WithTranslation } from "react-i18next";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import CheckboxControl from "components/UI/NewCheckbox";
import ButtonAddRemoveSmall from "components/controls/ButtonAddRemoveSmall";
import RichTooltip from "components/UI/RichTooltip";
import AddRemoveLink from "components/controls/AddRemoveLink";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/StyledTextInput";
import { RequestComplianceResetRule, RequestCustomFieldType } from "types/models/request";
import LockAfterDays from "./LockAfterDays";
import PredefinedPeriodsSelection from "./PredefinedPeriodsSelection";
import DaysBeforeRequest from "./DaysBeforeRequest";
import HowManyVacationDays from "./HowManyVacationDays";

const RequestFieldRow = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 40px;

  &:hover {
    .show-on-parent-hover {
      display: flex;
    }
  }
`;

const RequestFieldWrapper = styled.div`
  &:last-of-type {
    margin-bottom: 8px;
  }
`;

const AdditionalFields = styled.div<{ maxWidth?: number }>`
  padding-inline-start: 48px;
  display: flex;
  flex-direction: column;
  padding-inline-start: 48px;
  overflow: visible;
  transition: max-height 0.5s ease;
  max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : "none")};

  &.active {
    overflow: visible;
    transition: overflow 1s;
  }
`;

const Field = styled.div`
  min-width: 175px;
  display: flex;
  align-items: center;
  position: relative;
`;

const RemoveButtonWrapper = styled.div`
  position: absolute;
  ${rtl`
    right: 10px;
  `}
`;

const TooltipWrapper = styled.div`
  margin-inline-start: 12px;
`;

type RequestField = {
  name: string;
  active: boolean;
  required: boolean;
  configurable: boolean;
  attachmentAditionalFields: { lockAfterDays: number | null } | null;
  lockAfterDays: number | null;
  predefinedPeriods: boolean;
  values: number[];
  daysBeforeRequestDate: boolean;
  days: number;
  totalDaysLimit: boolean;
  fieldType: RequestCustomFieldType;
  iconKey: string | null;
  adjustFirstPeriodProportionally: boolean;
  resetRule: RequestComplianceResetRule;
  customField: boolean;
  externalId: string;
};

interface RequestFieldProps<F> {
  t: WithTranslation["t"];
  field: F;
  onChange: (field: F) => void;
  onRemove?: () => void;
  withoutObligatory?: boolean;
  tooltip?: string;
  width?: number;
}

export default <F extends Partial<RequestField>>({
  t,
  field,
  onChange,
  onRemove,
  withoutObligatory,
  tooltip,
  width,
}: RequestFieldProps<F>) => (
  <RequestFieldWrapper>
    <RequestFieldRow>
      <Field style={{ width }}>
        <CheckboxControl
          small
          ios
          checked={field.active}
          label={field.name}
          disabled={typeof field.configurable === "boolean" && !field.configurable}
          onChange={(checked) => {
            onChange({ ...field, active: checked });
          }}
        />
        {!!tooltip && (
          <TooltipWrapper>
            <RichTooltip text={tooltip} />
          </TooltipWrapper>
        )}
        {!!onRemove && (
          <RemoveButtonWrapper>
            <ButtonAddRemoveSmall remove showOnParentHover onClick={onRemove} />
          </RemoveButtonWrapper>
        )}
      </Field>
      {!withoutObligatory && (
        <Field>
          <CheckboxControl
            checked={field.required}
            disabled={!field.active || (typeof field.configurable === "boolean" && !field.configurable)}
            label={t("Mandatory")}
            onChange={(required) => {
              onChange({ ...field, required });
            }}
          />
        </Field>
      )}
      {window.global_store.beta && field.customField && typeof field.externalId === "undefined" && (
        <AddRemoveLink
          label={t("Add External ID")}
          onClick={(ev) => {
            ev.preventDefault();
            onChange({ ...field, externalId: "" });
          }}
        />
      )}
      {field.customField && typeof field.externalId === "string" && (
        <FieldWrapper width="175px" fieldTitleMarginTop={0}>
          <TextInputControl
            placeholder={t("External Id")}
            disabled={!window.global_store.beta || !field.active}
            value={field.externalId}
            onChange={(val: string) => onChange({ ...field, externalId: val })}
          />
        </FieldWrapper>
      )}
    </RequestFieldRow>
    {!!field.attachmentAditionalFields && (
      <AdditionalFields className={field.active ? "active" : ""} maxWidth={270}>
        <LockAfterDays
          value={field.lockAfterDays}
          onChange={(value) => {
            onChange({ ...field, lockAfterDays: value && parseInt(value, 10) });
          }}
        />
      </AdditionalFields>
    )}
    {field.predefinedPeriods && field.active && (
      <AdditionalFields className={field.active ? "active" : ""}>
        <PredefinedPeriodsSelection
          values={field.values || []}
          onChange={(value: number[]) => {
            onChange({ ...field, values: value || [] });
          }}
        />
      </AdditionalFields>
    )}
    {field.daysBeforeRequestDate && (
      <AdditionalFields className={field.active ? "active" : ""}>
        <DaysBeforeRequest
          value={field.days}
          onChange={(value) => {
            onChange({ ...field, days: value });
          }}
        />
      </AdditionalFields>
    )}
    {field.totalDaysLimit && (
      <AdditionalFields className={field.active ? "active" : ""}>
        <HowManyVacationDays
          resetRule={field.resetRule}
          days={field.days}
          adjustFirstPeriodProportionally={field.adjustFirstPeriodProportionally}
          onChange={({ resetRule, days, adjustFirstPeriodProportionally }) => {
            onChange({ ...field, resetRule, days, adjustFirstPeriodProportionally });
          }}
        />
      </AdditionalFields>
    )}
  </RequestFieldWrapper>
);
