import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PermissionRole, PermissionRoleName } from "types/models/permissions";
import {
  RequestApprovalFlow,
  RequestApprovalFlowApproverType,
  RequestApprovalFlowStatus,
  RequestLog,
  RequestLogAction,
  RequestLogActionDetails,
} from "types/models/request";
import FieldsGroup from "components/UI/FieldsGroup";
import EmployeeInfo from "components/UI/EmployeeInfo";
import * as images from "components/svg-images";

const moment = extendMoment(momentTz);

const ApprovalFlow = styled.div`
  margin-top: 25px;
  padding-bottom: 10px;

  .fields-group {
    label,
    .fields > *:not([class*="BypassWrapper"]) {
      margin-inline-start: 12px;
      margin-inline-end: 12px;
    }
  }
`;

const Line = styled.div`
  margin-bottom: 24px;
  margin-inline-start: 12px;
  margin-inline-end: 12px;
  border-top: 1px solid var(--colors-surface-150);
`;

const LevelWrapper = styled.div`
  margin-bottom: 16px;
`;

const BypassWrapper = styled(LevelWrapper)<{ success: boolean }>`
  padding: 12px;
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid ${(p) => (p.success ? "rgba(0, 161, 92, .5)" : "var(--colors-danger-600-p)")};
  background-color: ${(p) => (p.success ? "rgba(0, 161, 92, .07)" : "rgba(254, 102, 100, .2)")};

  .bypass-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: var(--typography-font-weight-bold);
    line-height: 12px;
    letter-spacing: 0.5px;
    color: ${(p) => (p.success ? "var(--colors-success-700)" : "var(--colors-danger-600-p)")};
    text-transform: uppercase;
  }
`;

const ApprovalLevel = styled.div`
  display: flex;
  align-items: center;

  .employee-info {
    padding-bottom: 0;
  }
`;

const ApprovalFlowIcon = styled.div<{ status: RequestApprovalFlowStatus | "skipped" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  padding: 2px;
  border: 1.5px solid;
  border-radius: 50%;
  border-color: ${(p) => {
    switch (p.status) {
      case RequestApprovalFlowStatus.approved:
      case RequestApprovalFlowStatus.declined:
      case "skipped":
        return "transparent";
      case RequestApprovalFlowStatus.pending:
      default:
        return "var(--colors-surface-200)";
    }
  }};
  background-color: ${(p) => {
    switch (p.status) {
      case RequestApprovalFlowStatus.approved:
        return "rgba(0, 161, 92, 0.2)";
      case RequestApprovalFlowStatus.declined:
        return "rgba(254, 102, 100, 0.2)";
      case "skipped":
        return "var(--colors-surface-150)";
      case RequestApprovalFlowStatus.pending:
      default:
        return "var(--colors-surface-0)";
    }
  }};

  svg {
    width: 12px;
    height: 6px;
    stroke: ${(p) => {
      switch (p.status) {
        case RequestApprovalFlowStatus.approved:
          return "var(--colors-success-700)";
        case RequestApprovalFlowStatus.declined:
          return "var(--colors-danger-600-p)";
        case "skipped":
          return "var(--colors-surface-150)";
        case RequestApprovalFlowStatus.pending:
        default:
          return "var(--colors-surface-200)";
      }
    }};
    path {
      fill: ${(p) => {
        switch (p.status) {
          case RequestApprovalFlowStatus.approved:
            return "var(--colors-success-700)";
          case RequestApprovalFlowStatus.declined:
            return "var(--colors-danger-600-p)";
          case "skipped":
            return "var(--colors-surface-150)";
          case RequestApprovalFlowStatus.pending:
          default:
            return "var(--colors-surface-200)";
        }
      }};
    }
  }
`;

const Reason = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ReasonContent = styled.div`
  margin-top: 6px;
  width: 300px;
  padding: 12px;
  background: var(--colors-surface-50);
  border-radius: var(--shapes-border-radius-default);
`;

const ReasonHeader = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-weight: var(--typography-font-weight-medium);
    font-size: 12px;
    line-height: 120%;
    color: var(--colors-surface-700);

    &.date {
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-surface-600);
    }
  }
`;

const ReasonText = styled.div`
  margin-top: 8px;
  color: var(--colors-surface-800);
  font-size: 12px;
  line-height: 145%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface RequestDetailsApprovalFlowProps {
  approvalFlow: RequestApprovalFlow[];
  status: RequestApprovalFlowStatus;
  logs: RequestLog[];
  timezone: string;
}

const RequestDetailsApprovalFlow = ({ approvalFlow, logs, timezone, status }: RequestDetailsApprovalFlowProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [roles, setRoles] = useState<PermissionRole[]>([]);
  const context = useContext(GlobalContext);

  useEffect(() => {
    async function fetchData() {
      const response = await context.getRoles();
      setRoles(response);
    }

    void fetchData();
  }, [context]);

  if (!roles?.length) {
    return null;
  }

  function getApproverTitle(level: RequestApprovalFlow) {
    if (level.approver.type === RequestApprovalFlowApproverType.role) {
      const role = roles.find((r) => r.uuid === level.approver.uuid);

      if (role?.predefined) {
        if (role.name === PermissionRoleName.supervisor) {
          if (level.approver.options?.level) {
            return t(`${PermissionRoleName.supervisor} level {{supervisorLevel}}`, {
              supervisorLevel: level.approver.options.level,
            });
          }

          return t("All supervisors");
        }

        return t(level.title);
      }
    }

    return level.title;
  }

  const bypassLog = logs.find(
    (l) =>
      l.action === RequestLogAction.statusUpdated &&
      l.actionDetails?.approvalStage === 100 &&
      [RequestApprovalFlowStatus.approved, RequestApprovalFlowStatus.declined].includes(l.actionDetails.approvalStatus),
  );
  const stageWithUpdatedStatus =
    approvalFlow.find((af) => af.status !== RequestApprovalFlowStatus.pending)?.stage || -1;

  return (
    <ApprovalFlow>
      <Line />
      <FieldsGroup label={t("Approval Flow")} width="100%" spacerBottom style={{ marginBottom: 0 }}>
        {bypassLog && (
          <BypassWrapper
            key={`bypass${bypassLog.actorUuid}`}
            success={
              (bypassLog.actionDetails as RequestLogActionDetails).approvalStatus === RequestApprovalFlowStatus.approved
            }
          >
            <div className="bypass-title">{t("Approval flow overridden by super approver")}</div>
            <ApprovalLevel>
              <EmployeeInfo
                small
                employeeInfo={{
                  avatarId: "",
                  fullName: bypassLog.actor.fullName,
                  position: bypassLog.actor.jobTitle || "",
                }}
              />
            </ApprovalLevel>

            {(bypassLog.actionDetails as RequestLogActionDetails).approvalStatusReason && (
              <Reason>
                <ReasonContent>
                  <ReasonHeader>
                    <span>{t("Declined reason:")}</span>
                    <span className="date">
                      {moment.tz(bypassLog.createdAt, timezone).format("ddd D MMM, YYYY - HH:mm")}
                    </span>
                  </ReasonHeader>
                  <ReasonText>{(bypassLog.actionDetails as RequestLogActionDetails).approvalStatusReason}</ReasonText>
                </ReasonContent>
              </Reason>
            )}
          </BypassWrapper>
        )}

        {approvalFlow.map((level) => {
          const stageStatus =
            (status !== RequestApprovalFlowStatus.pending && level.status === RequestApprovalFlowStatus.pending) ||
            level.stage < stageWithUpdatedStatus
              ? "skipped"
              : level.status;

          return (
            <LevelWrapper key={level.stage + level.status}>
              <ApprovalLevel>
                <EmployeeInfo
                  small
                  inactive={stageStatus === "skipped"}
                  employeeInfo={{
                    avatarId: "",
                    fullName: getApproverTitle(level),
                    position: level.subtitle || "",
                  }}
                />
                <ApprovalFlowIcon status={stageStatus}>
                  {level.status === RequestApprovalFlowStatus.declined ? images.searchClose : images.check}
                </ApprovalFlowIcon>
              </ApprovalLevel>

              {level.reason && (
                <Reason>
                  <ReasonContent>
                    <ReasonHeader>
                      <span>{t("Declined reason:")}</span>
                      <span className="date">
                        {moment.tz(level.statusUpdatedAt, timezone).format("ddd D MMM, YYYY - HH:mm")}
                      </span>
                    </ReasonHeader>
                    <ReasonText>{level.reason}</ReasonText>
                  </ReasonContent>
                </Reason>
              )}
            </LevelWrapper>
          );
        })}
      </FieldsGroup>
    </ApprovalFlow>
  );
};

export default RequestDetailsApprovalFlow;
