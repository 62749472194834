import { useState } from "react";
import TextInputControl from "components/controls/TextInputControl";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import SwitchableTextField from "components/UI/SwitchableTextField";
import { createHolidaysGroup, updateHolidaysGroup } from "./holidayApiUtils";

const Title = styled.div`
  font-size: 25px;
  color: var(--colors-mainText);
  letter-spacing: -0.55px;
  line-height: 26px;
  margin-bottom: 35px;
  text-align: center;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  button {
    max-width: 155px;
  }
`;

type CreateGroupPopupProps = {
  holidaysGroupUuid: string | null;
  name: string;
  externalId: string | null;
  onClose: () => void;
  onSave: (uuid: string) => void;
};

const CreateGroupPopup = ({ holidaysGroupUuid, name, externalId, onClose, onSave }: CreateGroupPopupProps) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState(name);
  const [groupExternalId, setExternalId] = useState(externalId);
  const [loading, setLoading] = useState(false);
  const title = !holidaysGroupUuid ? t(`Create a new group`) : t(`Edit a group`);
  const saveButtonText = !holidaysGroupUuid ? t(`Create`) : t(`Save`);

  const saveGroup = async () => {
    setLoading(true);
    const requestDetails = {
      companyUuid: window.global_store.company.uuid,
      body: {
        content: {
          name: groupName,
          externalId: groupExternalId,
        },
      },
    };

    let uuid = holidaysGroupUuid;

    if (!holidaysGroupUuid) {
      requestDetails.body.content.createdBy = window.global_store.profile.uuid;
      const res = await createHolidaysGroup(requestDetails);
      uuid = res.content.uuid;
    } else {
      requestDetails.holidaysGroupUuid = holidaysGroupUuid;
      requestDetails.body.content.updatedBy = window.global_store.profile.uuid;
      await updateHolidaysGroup(requestDetails);
    }

    if (uuid) {
      onSave(uuid);
    }
  };

  return (
    <div>
      <Title>{title}</Title>

      <FieldWrapper fieldName={t(`Group Name`)} width="100%">
        <TextInputControl value={groupName} onChange={(value) => setGroupName(value)} />
      </FieldWrapper>
      <br />
      <SwitchableTextField
        value={groupExternalId}
        placeholder="ID A1B2C3"
        label={t(`${TranslationNamespaces.holidays}|external-id-label`)}
        onChange={(val) => {
          setExternalId(val);
        }}
      />

      <ButtonsWrapper>
        <Button value={t(`Cancel`)} disabled={loading} state={ButtonState.secondary} onClick={onClose} />
        <Button
          value={saveButtonText}
          state={ButtonState.primary}
          disabled={!groupName}
          onClick={saveGroup}
          loading={loading}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default CreateGroupPopup;
