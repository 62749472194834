import moment from "moment";
import { useHistory } from "react-router-dom";
import CONFIG from "config";
import { AggregationGroupType } from "types/models/dashboard";
import { PermissionSectionName } from "types/models/permissions";
import { SearchControlItemType } from "components/UI/SearchControlNew";
import { updateUrlWithParams } from "./helpers";
import GroupsSearch from "./GroupsSearch/GroupsSearch";
import { SearchEntity } from "./GroupsSearch/types";

interface GroupSuperpunchSearchProps {
  initialSearch?: SearchEntity;
  startDate: moment.Moment;
  endDate: moment.Moment;
  directReportsOnly?: boolean;
  onEmployeeSelect?: (entity: SearchEntity) => void;
}

const GroupSuperpunchSearch = ({
  initialSearch,
  startDate,
  endDate,
  directReportsOnly,
  onEmployeeSelect,
}: GroupSuperpunchSearchProps) => {
  const history = useHistory();

  const onSearchResultSelect = (entity: SearchEntity) => {
    const groupType = {
      [SearchControlItemType.department]: AggregationGroupType.departments,
      [SearchControlItemType.team]: AggregationGroupType.teams,
      [SearchControlItemType.subsidiary]: AggregationGroupType.subsidiaries,
      [SearchControlItemType.employee]: AggregationGroupType.userProfile,
    }[entity.type];

    // All departments, all teams etc.
    if (entity.uuid === null && entity.id === null && entity.name === null) {
      updateUrlWithParams(
        {
          type: "group-edit",
          startDate,
          endDate,
          group_type: groupType,
        },
        history,
      );
    } else if (entity.type !== SearchControlItemType.employee) {
      // department, teams etc
      updateUrlWithParams(
        {
          type: "group-edit",
          startDate,
          endDate,
          group_type: groupType,
          group_uuid: entity.uuid as string,
          search_value: entity.name as string,
        },
        history,
      );
    } else if (onEmployeeSelect) {
      // employee select on Employee Superpunch
      onEmployeeSelect(entity);
    } else {
      // employee select on Group superpunch
      const startDateStr = startDate.format(CONFIG.apiDateFormat);
      const endDateStr = endDate.format(CONFIG.apiDateFormat);

      history.push(
        `/punches?type=edit&label=${entity.name}&id=${entity.id}&uuid=${entity.uuid}&startDate=${startDateStr}&endDate=${endDateStr}`,
      );
    }
  };

  return (
    <GroupsSearch
      permissionSection={PermissionSectionName.superpunch}
      initialSearch={initialSearch}
      onSearchResultSelect={onSearchResultSelect}
      directReportsOnly={directReportsOnly}
    />
  );
};

export default GroupSuperpunchSearch;
