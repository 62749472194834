import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import Tooltip from "components/UI/Tooltip";
import BEM from "utils/BEM";
import "styles/reports-user-info.scss";
import {
  getCompanyTaxPayerType,
  getCompanyTaxIdTranslation,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
} from "utils/common";
import { ReportUserInfoCompany } from "types/models/company";
import { EmployeeInfo } from "types/models/userProfile";
import Avatar from "../views/Avatar";

const b = BEM.b("reports-user-info");

interface ReportUserInfoState {
  company: ReportUserInfoCompany | null;
}

interface ReportUserInfosProps extends WithTranslation {
  employeeInfo: EmployeeInfo;
}

const UserInfoItem = ({ label, value }: { label: string; value: string }): JSX.Element | null => {
  if (!value) {
    return null;
  }
  const joinData = (data: { label: string; value: string }): JSX.Element => (
    <>
      {data.label ? <span>{data.label}: </span> : null}
      <span data-tip data-for={label} className="value">
        {data.value}
      </span>
    </>
  );

  return (
    <>
      <div className={b("user-info-item")}>{joinData({ label, value })}</div>
      <Tooltip id={label}>{value}</Tooltip>
    </>
  );
};

class ReportUserInfo extends Component<ReportUserInfosProps, ReportUserInfoState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  state = {
    company: null,
  };

  async componentDidMount(): Promise<void> {
    const company = await this.context.getCompany();
    this.setState({ company });
  }

  render(): JSX.Element | null {
    const { t, employeeInfo } = this.props;

    const { avatarId, fullName, department, schedule, matricula, pis, cpf, positionTitle, supervisorFullName } =
      employeeInfo;
    const { company } = this.state;

    if (!company) {
      return null;
    }

    const { cnpj } = company;
    const taxPayerIdType = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    return (
      <div className={b()}>
        <div className={b("avatar")}>
          <Avatar user={{ fullName: fullName || "", avatarId }} modifiers={{ big: true }} />
        </div>
        <div className={b("user-info")}>
          <div className={b("user-info-main")}>
            <div className={b("user-info-title")}>{fullName}</div>
            {matricula && <span>{matricula}</span>}
            {positionTitle && <span> - {positionTitle}</span>}
          </div>

          <div className={b("user-info-details")}>
            {(department || supervisorFullName || schedule) && (
              <div className={b("user-info-column")}>
                <UserInfoItem label={t("Department")} value={department} />
                <UserInfoItem label={t("Supervisor")} value={supervisorFullName} />
                <UserInfoItem label={t("Schedule")} value={schedule} />
              </div>
            )}

            {(pis || cnpj || cpf) && (
              <div className={b("user-info-column")}>
                <UserInfoItem label={t("PIS")} value={pis} />
                <UserInfoItem label={getCompanyTaxIdTranslation(taxPayerIdType, t)} value={cnpj} />
                <UserInfoItem label={employeeTaxIdLabel} value={cpf} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("reports-page")(ReportUserInfo);
