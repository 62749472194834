import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";

const Wrapper = styled.div<{ isRTL?: boolean }>`
  display: inline-block;
  padding: 10px 0 10px 0;
  padding-inline-start: 40px;
  padding-inline-end: 16px;
  background-color: var(--colors-surface-0);
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  position: relative;
  cursor: pointer;
  &:before {
    width: 10px;
    content: "";
    position: absolute;
    top: 0;
    inset-inline-start: 17px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.166 5.166H2.841l2.75-2.742a.837.837 0 0 0-1.183-1.183L.241 5.408a.833.833 0 0 0-.175.275.833.833 0 0 0 0 .633c.04.102.1.196.175.275l4.167 4.167a.833.833 0 0 0 1.183 0 .833.833 0 0 0 0-1.183l-2.75-2.742h6.325a.833.833 0 0 0 0-1.667Z' fill='%238093AC'/%3E%3C/svg%3E");
    ${(p): string => (p.isRTL ? "transform: rotate(180deg);" : "")}
  }
`;

const Label = styled.div`
  color: var(--colors-surface-600);
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 100%;
  text-align: center;
`;

interface NewCheckboxProps {
  onClick: () => void;
}

const NewCheckbox = ({ onClick }: NewCheckboxProps): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.common);
  return (
    <Wrapper
      isRTL={window?.global_store?.isRTL || false}
      onClick={(ev): void => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick();
      }}
    >
      <Label>{t("Back")}</Label>
    </Wrapper>
  );
};

export default NewCheckbox;
