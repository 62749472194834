import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import { useState } from "react";
import cx from "classnames";
import { host } from "utils/host-environment";
import { ProfileCard, ProfileCardProps } from "../ProfileCard";
import { IconDropright, IconExternalLink } from "../styled";
import { Subscreen } from "../Subscreen";
import { ProfileSelector, ProfileSelectorProps } from "../ProfileSelector";
import { LanguageSelector, LanguageSelectorProps } from "../LanguageSelector";
import { ActivityFieldDropdown } from "../ActivityFieldDropdown";

const styles = stylesheet`
  .Wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    > * {
      flex-shrink: 0;
    }

    .Options {
      display: flex;
      flex-direction: column;
      width: 100%;

      .Profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 82px;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        cursor: pointer;
        border-bottom: 1px solid var(--colors-separator);

        &:hover {
          background: #ECF2FA;
        }

        .IconDrop {
          margin-inline-end: 6px;
        }
      }

      .Link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        font-size: 15px;
        font-weight: var(--typography-font-weight-default);
        line-height: 100%;
        color: var(--colors-mainText);
        text-decoration: none;

        &:hover {
          background: #F5F9FC;
        }
      }

      .Section {
        padding: 8px 0;
      }
    }

    .LogoutBtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      width: calc(100% - 15px * 2);
      height: 56px;
      font-size: 13px;
      font-weight: var(--typography-font-weight-bold);
      color: var(--colors-text1);
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      background-color: transparent;
      border-top: 1px solid var(--colors-separator);
    }
  }

  .Wrapper:global(.loading) {
    .Profile {
      cursor: not-allowed;

      .IconDrop {
        display: none;
      }
    }
  }
`;

export interface OptionsViewProps {
  loading?: boolean;
  profile?: ProfileCardProps;
  companies: ProfileSelectorProps["companies"];
  selectedCompanyId: ProfileSelectorProps["selectedCompanyId"] | null;
  onCompanySelected: (company: ProfileSelectorProps["companies"][0]) => void;
  onLogout: () => void;
  languageKey?: string;
  languages: LanguageSelectorProps["languages"];
  onLanguageChange: LanguageSelectorProps["onChange"];
}

export function OptionsView(props: OptionsViewProps) {
  const {
    loading,
    onLogout,
    profile,
    companies,
    selectedCompanyId,
    onCompanySelected,
    languageKey,
    languages,
    onLanguageChange,
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const [showProfileSelector, setShowProfileSelector] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const languageName = languages.find((lang) => lang.key === languageKey)?.name;

  return (
    <div className={cx(styles.Wrapper, { loading })}>
      <div className={styles.Options}>
        <div className={styles.Profile} onClick={() => !loading && setShowProfileSelector(true)}>
          <ProfileCard {...profile} loading={loading} />
          <IconDropright className={styles.IconDrop} />
        </div>
        <div className={styles.Section}>
          <ActivityFieldDropdown
            loading={loading}
            title={t("Language")}
            value={languageName ? t(languageName) : languageKey}
            onClick={() => !loading && setShowLanguageSelector(true)}
            compact
          />
        </div>
        {host.isBrowserExtension && (
          <div className={styles.Section}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a className={styles.Link} href="#/options" target="_blank">
              <div>{t("Integrations")}</div>
              <IconExternalLink />
            </a>
          </div>
        )}
        <div className={styles.Section}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a className={styles.Link} href="https://admin.day.io/" target="_blank">
            <div>{t("Client admin")}</div>
            <IconExternalLink />
          </a>
        </div>
      </div>
      <div className={styles.LogoutBtn} onClick={onLogout}>
        {t("Log out")}
      </div>
      {showProfileSelector && (
        <Subscreen onBackClick={() => setShowProfileSelector(false)}>
          <ProfileSelector
            companies={companies}
            selectedCompanyId={selectedCompanyId!}
            onCompanySelected={(evt) => {
              setShowProfileSelector(false);
              onCompanySelected(evt);
            }}
          />
        </Subscreen>
      )}
      {showLanguageSelector && (
        <Subscreen onBackClick={() => setShowLanguageSelector(false)}>
          <LanguageSelector
            languages={languages}
            selectedLanguageKey={languageKey}
            onChange={(lang) => {
              setShowLanguageSelector(false);
              onLanguageChange(lang);
            }}
          />
        </Subscreen>
      )}
    </div>
  );
}
