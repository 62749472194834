import { Component } from "react";
import BEM from "utils/BEM";
import "styles/schedule-employees.scss";
import NotificationRow from "components/NotificationRow";
import TableCommon from "components/TableCommon";
import Avatar from "components/views/Avatar";
import SchedulePopupMessage from "components/Schedules/SchedulePopupMessage";
import AddEmployeeRow from "components/controls//AddEmployeeRow";
import ModalDialog from "components/UI/ModalDialog";
import SearchInput from "components/UI/SearchInput";
import { TableButtons, TableButton } from "components/styled/Page";
import {
  addEmployeeToSchedule,
  removeEmployeeFromSchedule,
  getSchedules,
  getScheduleEmployees,
} from "utils/apiHelpers";
import { strIncludesCheck } from "utils/common";
import GlobalContext from "context/global-context";
import { withTranslation } from "react-i18next";
import * as moment from "moment";
import * as images from "components/svg-images";
import styled from "styled-components";
import { PermissionSectionName } from "types/models/permissions";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { MassActionLocations } from "utils/ga";

const b = BEM.b("schedule-employees");
const tb = BEM.b("table-common");

const FiltersWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;
class ScheduleEmployees extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      loading: true,
      columns: this.getTableColumns(),
      notification: "",
      notificationType: "success",
      filterStr: "",
      addingEmployees: false,
      addEmployeeRowVisible: this.props.addEmployeesActive,
      schedules: [],
    };
    this.allEmployees = null; // set employees without updating state
  }

  componentDidMount = async () => {
    const { employees } = this.props;

    const company = await this.context.getCompany();
    const companyUuid = company.uuid;

    const schedulesResp = await getSchedules({ companyUuid });
    const employeesResp = await this.getEmployeesByUUIDs({ uuids: employees.map((s) => s.UserProfileUuid) });

    const schedules = schedulesResp.content
      .filter((s) => s.uuid !== this.props.schedule.uuid && s.active)
      .map((s) => ({ value: s.uuid, label: s.name }));

    const res = employeesResp.user_profiles.map((p) => ({
      ...p,
      ...employees.filter((e) => e.UserProfileUuid === p.uuid)[0],
    }));

    this.setState({ employees: res, schedules, loading: employees && employees.length > 0 });

    if (employees && employees.length > 0) {
      const profilesWithUuids = await this.getEmployeesByUUIDs({
        uuids: employees.map((s) => s.UserProfileUuid),
      });

      const resp = profilesWithUuids.user_profiles.map((p) => ({
        ...p,
        ...employees.filter((e) => e.UserProfileUuid === p.uuid)[0],
      }));

      this.setState({ employees: resp, loading: false });
    }
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.addEmployeesActive) {
      this.setState({ addEmployeeRowVisible: nextProps.addEmployeesActive });
    }
  }

  getEmployeesByUUIDs = async ({ uuids }) => {
    if (!this.allEmployees) {
      const { content: employeesResp } = await listUserProfilesWIthFilters(
        window.global_store.company.uuid,
        baseByUuidPayload(window.global_store.profile.uuid, uuids),
      );
      this.allEmployees = employeesResp || [];
    }
    const filteredEmployees = this.allEmployees.filter((emp) => uuids.some((uuid) => uuid === emp.uuid));
    return { user_profiles: filteredEmployees };
  };

  updateEmployeesList(stateToUpdate) {
    const state = { ...stateToUpdate };
    const { schedule } = this.props;
    const companyUuid = window.global_store.company.uuid;

    getScheduleEmployees({
      companyUuid,
      scheduleUuid: schedule.uuid,
      limitDate: moment().format("YYYY-MM-DD"),
    }).then((employees) => {
      if (employees.content && employees.content.length > 0) {
        this.getEmployeesByUUIDs({
          uuids: employees.content.map((s) => s.UserProfileUuid),
        }).then((profilesWithUuids) => {
          const res = profilesWithUuids.user_profiles.map((p) => {
            const scheduleEmployee = employees.content.filter((e) => e.UserProfileUuid === p.uuid)[0];
            return { ...p, ...scheduleEmployee };
          });
          state.employees = res || [];
          this.setState(state);
        });
      } else {
        state.employees = [];
        this.setState(state);
      }
    });
  }

  removeEmployee = (employee) => {
    const { t, schedule } = this.props;
    const companyUuid = window.global_store.company.uuid;
    const currentProfileUuid = window.global_store.profile.uuid;
    this.setState({ loading: true });
    removeEmployeeFromSchedule({
      body: {
        content: {
          userProfileUuids: [employee.UserProfileUuid],
          endDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
          updatedBy: currentProfileUuid,
        },
      },
      companyUuid,
      scheduleUuid: schedule.uuid,
    })
      .then((r) => {
        this.updateEmployeesList({
          loading: false,
          notificationType: "success",
          notification: `${t("You removed")} ${employee.name}`,
          deactivatePopupVisible: false,
          selectedEmployee: null,
        });
      })
      .catch((e) => {
        this.setState({ notificationType: "error", notification: e.message, loading: false });
      });
  };

  changeEmployee = (schedule, employee) => {
    const { t } = this.props;
    const companyUUID = window.global_store.company.uuid;
    const currentProfileUuid = window.global_store.profile.uuid;
    this.setState({ loading: true });
    addEmployeeToSchedule({
      companyUUID,
      scheduleUuid: schedule.value,
      body: {
        content: {
          userProfileUuids: [employee.UserProfileUuid],
          startDate: moment().format("YYYY-MM-DD"),
          createdBy: currentProfileUuid,
        },
      },
    })
      .then((r) => {
        this.updateEmployeesList({
          loading: false,
          notification: `${t("You removed")} ${employee.fullName}`,
          notificationType: "success",
          deactivatePopupVisible: false,
          selectedEmployee: null,
        });
      })
      .catch((e) => {
        this.setState({ notificationType: "error", notification: e.message, loading: false });
      });
  };

  onRemoveEmployeeFromSchedule = (employee) => {
    this.setState({
      deactivatePopupVisible: true,
      selectedEmployee: employee,
      action: "remove",
    });
  };

  onAddEmployee = (input, startDate) => {
    const { t, schedule } = this.props;
    let employee = null;
    let userProfileUuids = [];
    if (!Array.isArray(input)) {
      employee = input.employee ? input.employee : input;
      userProfileUuids = [employee.uuid];
    } else {
      userProfileUuids = input;
    }
    const companyUUID = window.global_store.company.uuid;
    const currentProfileUuid = window.global_store.profile.uuid;
    this.setState({ addingEmployees: true, loading: true });
    addEmployeeToSchedule({
      companyUUID,
      scheduleUuid: schedule.uuid,
      body: {
        content: {
          userProfileUuids,
          startDate: startDate.format("YYYY-MM-DD"),
          createdBy: currentProfileUuid,
        },
      },
    })
      .then((r) => {
        this.updateEmployeesList({
          loading: false,
          addingEmployees: false,
          notification: `${t("You added")} ${
            employee
              ? employee.full_name || employee.name || employee.fullName
              : `${userProfileUuids.length} ${t("employees")}`
          }`,
          notificationType: "success",
          deactivatePopupVisible: false,
          selectedEmployee: null,
        });
      })
      .catch((e) => {
        this.setState({ addingEmployees: false, notificationType: "error", notification: e.message });
      });
  };

  onChangeSchedule = (employee) => {
    this.setState({
      action: "change",
      deactivatePopupVisible: true,
      selectedEmployee: employee,
    });
  };

  filterEmployees = (ev) => {
    this.setState({ filterStr: ev.target.value });
  };

  onNotificationClose = () => {
    this.setState({ notification: "" });
  };

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        Header: t("Employees"),
        accessor: "id",
        sortable: false,
        resizable: false,
        Cell: (row) => (
          <div
            className={tb("full-name")}
            style={{ opacity: (row.original.employeeStatus || row.original.status) !== "active" ? "0.3" : "1" }}
          >
            <Avatar
              user={{
                fullName: row.original.fullName || row.original.name,
                avatarId: row.original.avatarId,
              }}
            />
            <div className={tb("name-position")}>
              <div className={tb("name")}>{row.original.fullName || row.original.name}</div>
              {row.original.position && <div className={tb("position")}>{row.original.position.title}</div>}
            </div>
          </div>
        ),
        minWidth: 178,
      },
      {
        Header: t("Start"),
        accessor: "startDate",
        sortable: false,
        resizable: false,
        Cell: (row) => (
          <div>
            {row.value ? moment(row.value, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
            <TableButtons className="buttons">
              {this.state.schedules.length > 0 && (
                <TableButton
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.onChangeSchedule(row.original);
                  }}
                >
                  {t("common|Edit")}
                </TableButton>
              )}
              <TableButton
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.onRemoveEmployeeFromSchedule(row.original);
                }}
              >
                {images.trashIcon}
              </TableButton>
            </TableButtons>
          </div>
        ),
        minWidth: 150,
      },
    ];
    return columns;
  }

  toggleAddEmployeeRow(visible) {
    this.setState({ addEmployeeRowVisible: visible });
  }

  render() {
    const {
      columns,
      filterStr,
      addEmployeeRowVisible,
      employees,
      notificationType,
      action,
      deactivatePopupVisible,
      schedules,
      selectedEmployee,
      addingEmployees,
      loading,
    } = this.state;
    const { t } = this.props;

    const filteredUsers = employees.filter((u) => {
      let filter = true;
      if (u && (u.fullName || u.name)) {
        filter = strIncludesCheck(u.fullName || u.name, filterStr);
      }
      return filter;
    });
    return (
      <div className={b()}>
        {addEmployeeRowVisible && (
          <AddEmployeeRow
            inProgress={addingEmployees}
            newSearch
            trackingLocation={MassActionLocations.Schedules}
            hideRow={() => this.toggleAddEmployeeRow(false)}
            onAddEmployee={this.onAddEmployee}
            onAddMultipleEmployee={this.onAddEmployee}
            permissionSection={PermissionSectionName.schedules}
          />
        )}
        {this.state.notification && (
          <NotificationRow
            employeesPage
            withCloseButton
            type={notificationType}
            onClose={this.onNotificationClose}
            message={this.state.notification}
          />
        )}
        <FiltersWrapper>
          {employees.length > 0 && (
            <SearchInput
              modifiers={["filter"]}
              onChange={this.filterEmployees}
              placeholder={translateEmployeeTerm(
                t,
                TranslationNamespaces.common,
                "custom-search-employees",
                `${TranslationNamespaces.common}|Search Employees`,
              )}
              value={filterStr}
            />
          )}
          {!addEmployeeRowVisible && (
            <div className={b("add-employee-btn")} onClick={() => this.toggleAddEmployeeRow(true)}>
              {images.plusBig}
              {t("Add Employess")}
            </div>
          )}
        </FiltersWrapper>
        <TableCommon
          loading={loading}
          rows={filteredUsers}
          columns={columns}
          interactive
          className="schedule-employees__table"
        />
        <ModalDialog isOpen={deactivatePopupVisible} onClose={() => this.setState({ deactivatePopupVisible: false })}>
          <SchedulePopupMessage
            employee={selectedEmployee}
            type={action}
            schedules={schedules}
            onClose={() => this.setState({ deactivatePopupVisible: false })}
            onYes={(schedule, employee) => {
              if (action === "change") {
                this.changeEmployee(schedule, employee);
              } else {
                this.removeEmployee(selectedEmployee);
              }
            }}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default withTranslation("schedules")(ScheduleEmployees);
