import { Component } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import Coordinates from "coordinate-parser";
import sentryUtils from "utils/sentryUtils";
import { Address } from "types/common";
import BEM from "../utils/BEM";

const input = BEM.b("input");

interface LocationSearchInputProps {
  address: { formatted: string };
  onChange: (address: Address) => void;
  isValid: boolean;
  isOnboarding?: boolean;
  placeholder?: string;
  className?: string;
}

interface LocationSearchInputState {
  inputStr: string;
  zeroResults: boolean;
}

// https://github.com/kenny-hibino/react-places-autocomplete
class LocationSearchInput extends Component<LocationSearchInputProps, LocationSearchInputState> {
  readonly state: Readonly<LocationSearchInputState> = {
    inputStr: "",
    zeroResults: false,
  };

  componentDidMount() {
    this.setState({ inputStr: this.props.address?.formatted || "" });
  }

  UNSAFE_componentWillReceiveProps(nextProps: LocationSearchInputProps) {
    this.setState({ inputStr: nextProps.address?.formatted || "" });
  }

  handleChange = (inputStr: string) => {
    this.setState({ inputStr, zeroResults: false });
  };

  /**
   * Geocode address string and set the results
   *
   * @param addressStr could be address string from PlacesAutocomplete or coorinates (floats separated by comma) e.g. "24.38693841756937, 54.722222051312656"
   * @param formattedAddressStr if addressStr is coorinates, allow user to save his input as formatted address
   */
  handleSelect = async (addressStr: string, formattedAddressStr?: string) => {
    try {
      const results = await geocodeByAddress(addressStr);

      if (results?.length) {
        const geocodedComponent = results[0];

        const address: Address = {
          formatted: formattedAddressStr || geocodedComponent.formatted_address,
          lat: null,
          lng: null,
          country: this.getFromAddress(geocodedComponent, "country"),
          state: this.getFromAddress(geocodedComponent, "administrative_area_level_1"),
          street: this.getFromAddress(geocodedComponent, "route"),
          city: this.getFromAddress(geocodedComponent, "locality"),
          streetNumber: this.getFromAddress(geocodedComponent, "street_number"),
          zip: this.getFromAddress(geocodedComponent, "postal_code"),
        };

        if (geocodedComponent.geometry?.location) {
          address.lat = geocodedComponent.geometry.location.lat();
          address.lng = geocodedComponent.geometry.location.lng();
        }

        this.props.onChange(address);
      }
    } catch (error) {
      // This part handles "Enter" key.
      // PlacesAutocomplete does not support coordinates as input, so it will throw ZERO_RESULTS in that case
      if (error === "ZERO_RESULTS") {
        this.setState({ zeroResults: true }, this.tryToSelectCoordinates);
        return;
      }

      console.error("Error", error);
      sentryUtils.sendError(error);
    }
  };

  /**
   * This function is triggered during onChange
   * PlacesAutocomplete does not support coordinates as input, so it will throw ZERO_RESULTS in that case
   * Set zero search results error to state.
   *
   * @param status
   * @param clearSuggestions clears suggestion dropdown
   */
  onPlacesAutocompleteError = (status: string, clearSuggestions: () => void) => {
    if (status === "ZERO_RESULTS") {
      clearSuggestions();

      this.setState({ zeroResults: true });
    }
  };

  getFromAddress = (geocodedComponent: google.maps.GeocoderResult, field: string) => {
    const ac = geocodedComponent.address_components.find((a) => a.types.some((t) => t === field));

    return ac?.short_name || "";
  };

  /**
   *  If PlacesAutocomplete has zero search results, check whether user wants to enter coordinates
   */
  tryToSelectCoordinates = () => {
    const { inputStr, zeroResults } = this.state;

    if (zeroResults) {
      const coordinates = this.tryToParseCoordinates(inputStr);

      // if input is valid coordinates - feed it to geocoder
      if (coordinates) {
        // inputStr allows user to save his input as formatted address
        void this.handleSelect(coordinates, inputStr);
      }
    }
  };

  /**
   * Parse different coordinates formats and return string suitable for geocodeByAddress function or null
   *
   * @param address
   * @returns e.g. "24.38693841756937, 54.722222051312656" or null
   */
  tryToParseCoordinates = (address: string): string | null => {
    try {
      const position = new Coordinates(address);

      return `${position.getLatitude()}, ${position.getLongitude()}`;
    } catch (error) {
      return null;
    }
  };

  render() {
    const { isValid, isOnboarding, placeholder, className } = this.props;
    const { inputStr } = this.state;

    return (
      <PlacesAutocomplete
        value={inputStr}
        onChange={this.handleChange}
        onSelect={(addressStr, ..._) => this.handleSelect(addressStr)}
        onError={this.onPlacesAutocompleteError}
        debounce={1000}
        googleCallbackName="googleMapsApiCallback"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const inputProps = getInputProps({ placeholder });

          return (
            <div className={className ?? ""} style={{ position: "relative" }}>
              <input
                className={input({ error: !isValid, onboarding: isOnboarding })}
                {...{
                  ...inputProps,
                  onBlur: this.tryToSelectCoordinates,
                  onChange: (...args) => {
                    inputProps.onChange(...args);
                  },
                }}
              />
              <div className={`autocomplete-dropdown-container ${getInputProps()["aria-expanded"] ? "expanded" : ""}`}>
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

                  return (
                    <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
