// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorText(err: any): string {
  try {
    if (err && err.response && err.response.data) {
      if (err.response.data.indexOf && err.response.data.indexOf("<html>") > -1) {
        return "Unexpected html response";
      }
      if (err.response.data.error) {
        return `Err response: ${getErrorText(err.response.data.error)}`;
      }
      if (err.response.data.errors) {
        return `Err response: ${getErrorText(err.response.data.errors)}`;
      }

      let errorStr = "";
      const keys = Object.keys(err.response.data);
      keys.forEach((key) => {
        errorStr += `${key} ${err.response.data[key]}; `;
      });
      return errorStr;
    }
    if (err.error) {
      return `Err: ${err.error}`;
    }
    const str = err.toString();
    if (str !== "[object Object]") {
      return str;
    }
    const json = JSON.stringify(err);
    if (json !== "{}") {
      return json;
    }
    const names = Object.getOwnPropertyNames(err);
    if (names.length > 0) {
      return `props: ${names.join(", ")}`;
    }
    return `constructor: ${err.constructor.name}`;
  } catch {
    return `e: ${JSON.stringify(err)}`;
  }
}
