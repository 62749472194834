import React from "react";
import styled from "styled-components";

interface SwitchControlProps {
  disabled: boolean;
  onChange: (checked: boolean) => void;
  checked: boolean;
  className?: string;
}

interface SwitchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled: boolean;
}

const Wrapper = styled.div<{ checked: boolean }>`
  label {
    display: block;
    height: var(--component-toggles-switch-height, 16px);
  }

  &[dir="rtl"],
  &[dir="rtl"] * {
    transform: rotate(180deg);
  }
`;

const SwitchInput = styled.input<SwitchInputProps>`
  width: var(--component-toggles-switch-width, 32px);
  height: var(--component-toggles-switch-height, 16px);
  position: absolute;
  display: none;

  & + span {
    display: inline-block;
    position: relative;
    width: var(--component-toggles-switch-width, 32px);
    height: var(--component-toggles-switch-height, 16px);
    line-height: 2em;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      ${({ disabled, checked }) =>
        !disabled &&
        `
      &:before {
        background: ${
          checked
            ? "var(--component-toggles-switch-checked-hover-bg-color, #0780E0)"
            : "var(--component-toggles-switch-unchecked-hover-bg-color, rgba(33, 36, 46, 0.08))"
        };
        ${!checked && "border: 1px solid var(--component-toggles-switch-unchecked-hover-border-color, #757E9C);"}
      }

      &:after {
        background: ${
          checked
            ? "var(--component-toggles-switch-checked-indicator-color, #FFFF)"
            : "var(--component-toggles-switch-unchecked-hover-indicator-color, #3E4357)"
        };
      }
    `}

      ${({ disabled }) => disabled && "cursor: initial;"}
    }

    &:before {
      box-sizing: border-box;
      content: "";
      position: absolute;
      display: block;
      width: var(--component-toggles-switch-width, 32px);
      height: var(--component-toggles-switch-height, 16px);
      border-radius: var(--component-toggles-radio-border-radius, 9999px);
      background: var(--component-toggles-switch-unchecked-default-bg-color, #f7f7f7);
      transition: all 0.1s;

      ${({ disabled, checked }) => {
        if (disabled && !checked) {
          return `
            background: var(--component-toggles-switch-unchecked-disable-bg-color, #FFF);
            border: 1px solid var(--component-toggles-switch-unchecked-disable-border-color, #E1E3EB);
          `;
        }
        if (disabled && checked) {
          return `
            background: var(--component-toggles-switch-checked-disable-bg-color, #F7F7F7);
            border: 1px solid var(--component-toggles-switch-checked-disable-border-color, #E1E3EB);
          `;
        }
        if (checked) {
          return `
            background: var(--component-toggles-switch-checked-default-bg-color, #1E97F7);
          `;
        }
        return `
          background: var(--component-toggles-switch-unchecked-default-bg-color, #F7F7F7);
          border: 1px solid var(--component-toggles-switch-unchecked-default-border-color, #A7ADC0);
        `;
      }}
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 3px;

      ${({ disabled, checked }) => {
        if (disabled && checked) {
          return `
            width: var(--component-toggles-switch-checked-indicator-size, 12px);
            height: var(--component-toggles-switch-checked-indicator-size, 12px);
            top: var(--component-toggles-switch-indicator-checked-offset-size, 2px);
            background: var(--component-toggles-switch-checked-disable-indicator-color, #CDD0DB);
          `;
        }
        if (disabled && !checked) {
          return `
            width: var(--component-toggles-switch-unchecked-indicator-size, 8px);
            height: var(--component-toggles-switch-unchecked-indicator-size, 8px);
            top: var(--component-toggles-switch-indicator-unchecked-offset-size, 2px);
            background: var(--component-toggles-switch-unchecked-disable-indicator-color, #CDD0DB);
          `;
        }
        if (checked) {
          return `
            width: var(--component-toggles-switch-checked-indicator-size, 12px);
            height: var(--component-toggles-switch-checked-indicator-size, 12px);
            top: var(--component-toggles-switch-indicator-checked-offset-size, 2px);
            background: var(--component-toggles-switch-checked-indicator-color, #FFFF);
          `;
        }
        return `
          width: var(--component-toggles-switch-unchecked-indicator-size, 8px);
          height: var(--component-toggles-switch-unchecked-indicator-size, 8px);
          top: var(--component-toggles-switch-indicator-unchecked-offset-size, 2px);
          background: var(--component-toggles-switch-unchecked-indicator-color, #757e9c);
        `;
      }}

      border-radius: var(--component-toggles-switch-indicator-border-radius, 9999px);
      transition: all 0.1s;
    }
  }

  &:checked {
    & + span:after {
      left: 18px;
    }
  }
`;

const SwitchControl = (props: SwitchControlProps) => {
  return (
    <Wrapper checked={props.checked} className={props.className} dir={window.global_store.isRTL ? "rtl" : "ltr"}>
      <label>
        <SwitchInput
          onChange={(event) => props.onChange(event.target.checked)}
          type="checkbox"
          checked={props.checked}
          disabled={props.disabled}
          readOnly
        />
        <span />
      </label>
    </Wrapper>
  );
};

SwitchControl.defaultProps = {
  checked: false,
  disabled: false,
};

export default SwitchControl;
