import styled from "styled-components";
import { Oval } from "react-loader-spinner";

const Wrapper = styled.div`
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: var(--component-menu-secondary-text-color, #757e9c);
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.08px;

  .cell-punch-dropdown_loader-wrapper {
    circle {
      stroke: var(--component-progress-indicator-loader-spinner-track-bg-color, rgba(33, 36, 46, 0.12));
    }

    path {
      stroke: var(--component-progress-indicator-default-progress-bg-color, #1e97f7);
      transition: unset;
    }
  }
`;

type NoPunchesProps = {
  children?: React.ReactNode;
  loading?: boolean;
};

const NoPunches = ({ children, loading }: NoPunchesProps) => (
  <Wrapper>
    {loading ? <Oval visible strokeWidth="4" wrapperClass="cell-punch-dropdown_loader-wrapper" /> : children || null}
  </Wrapper>
);

export default NoPunches;
