import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { SuperpunchTableRows, SuperpunchTableRowPunchKey, SuperpunchPunchCellData } from "types/models/superpunch";
import { minsToHrsMins } from "utils/common";
import { RequestApprovalFlowStatus, RequestTypeName } from "types/models/request";
import { GetTableRowsData, SuperpunchCustomEvents } from "./types";

const moment = extendMoment(momentTz);

export function aggregateTableData({ inputData, t, daysInProgress, lockDate }: GetTableRowsData): SuperpunchTableRows {
  let entriesCount = 0;
  let breakStartsCount = 0;
  let breakEndsCount = 0;
  let exitsCount = 0;
  let hasLockedDays = false;

  const outputData = inputData.map((d) => {
    const rawDate = moment(d.date, "YYYY-MM-DD");
    const today = moment();
    const punchesTypes: SuperpunchTableRowPunchKey = {};
    // PROD-11225
    const absenceRequests = d.requests.filter(
      (r) =>
        ![RequestTypeName.hbConvert, RequestTypeName.overtime, RequestTypeName.scheduleAssignment].includes(
          r.requestType,
        ),
    );
    // check if has at least one approved absence request
    const hasApprovedRequest = absenceRequests.some(
      (r) => r.approvalStatus === RequestApprovalFlowStatus.approved && r.allDay,
    );
    const obligatoryDay = d.shiftEvents.some((se) => se.obligatory);
    //  PROD-12459 non obligatory day with "Allow punches in days outside the planned schedule" enabled
    const nonObligatoryDayWithAllowPunchesEnabled = !!d.shiftEvents.length;
    let entryI = 0;
    let breakStartI = 0;
    let breakEndI = 0;
    let exitI = 0;

    // PROD-11888. Not future date. PROD-12320. Has no approved request (unless punched). PROD-12459
    const showPunchDropdowns =
      rawDate.isSameOrBefore(today) &&
      ((!hasApprovedRequest && obligatoryDay) || nonObligatoryDayWithAllowPunchesEnabled || !!d.punches.length);

    if (showPunchDropdowns) {
      d.shiftEvents.forEach((se) => {
        if (!punchesTypes[se.key]) {
          punchesTypes[se.key] = {
            display: !hasApprovedRequest && obligatoryDay ? t("Missing") : "--:--", // dropdown label (placeholder)
            raw: null,
          };
        }
      });
      d.punches.forEach((p) => {
        const key = p.key || `${p.type}0`;

        punchesTypes[key] = {
          raw: p,
          display: p.time ? momentTz.tz(p.time, p.timezone).format("HH:mm") : "00:00",
        };
      });
    }

    const typesKeys = Object.keys(punchesTypes);
    entryI = typesKeys.filter((key) => key.indexOf("entry") === 0).length;
    breakStartI = typesKeys.filter((key) => key.indexOf("break_start") === 0).length;
    breakEndI = typesKeys.filter((key) => key.indexOf("break_end") === 0).length;
    exitI = typesKeys.filter((key) => key.indexOf("exit") === 0).length;

    if (entryI > entriesCount) {
      entriesCount = entryI;
    }
    if (breakStartI > breakStartsCount) {
      breakStartsCount = breakStartI;
    }
    if (breakEndI > breakEndsCount) {
      breakEndsCount = breakEndI;
    }
    if (exitI > exitsCount) {
      exitsCount = exitI;
    }

    const shiftComps = d.shiftCompilations || [];
    const inProgress =
      daysInProgress.filter((dip) => dip === moment(d.date, "YYYY-MM-DD").format("YYYY-MM-DD")).length > 0;

    const isDayLocked = !!(
      lockDate && moment(d.date, "YYYY-MM-DD").isSameOrBefore(moment(lockDate, "YYYY-MM-DD"), "day")
    );
    if (isDayLocked) {
      hasLockedDays = true;
    }

    const breakKeys = Object.keys(punchesTypes).filter((key) => key.indexOf("break_") === 0);
    const breakEventsWithPunches: Record<string, SuperpunchPunchCellData> = {};
    breakKeys.forEach((key) => {
      breakEventsWithPunches[key] = punchesTypes[key];
    });

    return {
      date: {
        raw: rawDate,
        dayOfWeek: rawDate.clone().format("ddd"),
        dayMonth: rawDate.clone().format("DD/MM"),
        isDayOff: !d.shiftWorkingDay || !!d.holiday || hasApprovedRequest,
      },
      ...punchesTypes,
      breakEventsWithPunches,
      requests: absenceRequests,
      hasApprovedRequest,
      shiftCompilation: shiftComps[0],
      shiftEvents: d.shiftEvents || [],
      schedule: d.schedule,
      lockedDay: isDayLocked,
      workedMinutes: d.workedMinutes ? minsToHrsMins(d.workedMinutes) : "00:00",
      breakMinutes: d.breakMinutes ? minsToHrsMins(d.breakMinutes) : "00:00",
      nightMinutes: d.nightMinutes ? minsToHrsMins(d.nightMinutes) : "00:00",
      totalExtraMinutes: d.totalExtraMinutes ? minsToHrsMins(d.totalExtraMinutes) : "00:00",
      totalHoursBankMinutes: d.totalHoursBankMinutes ? minsToHrsMins(d.totalHoursBankMinutes) : "00:00",
      hoursBankMinutes: d.hoursBankMinutes ? minsToHrsMins(d.hoursBankMinutes) : "00:00",
      extraHoursMinutes: d.extraHoursMinutes ? minsToHrsMins(d.extraHoursMinutes) : "00:00",
      missedMinutes: d.missedMinutes ? minsToHrsMins(d.missedMinutes) : "00:00",
      debitMinutes: d.debitMinutes ? minsToHrsMins(d.debitMinutes) : "00:00",
      holiday: d.holiday,
      scheduleException: d.scheduleException,
      missedDay: d.missedDay,
      observation: d.observation,
      comments: d.comments,
      plannedDayBreakByTypes: d.plannedDayBreakByTypes || [],
      plannedNightBreakByTypes: d.plannedNightBreakByTypes || [],
      inProgress,
    };
  });

  return {
    rows: outputData,
    hasLockedDays,
    punchesCount: { entriesCount, breakStartsCount, breakEndsCount, exitsCount },
  };
}

export const generateEventKey = (event: SuperpunchCustomEvents, value: string): string =>
  event.replace(SuperpunchCustomEvents.placeholder, value);
