import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { TranslationNamespaces } from 'types/translationNamespaces';
import {
  FilenameConfiguration,
  FilenameConfigurationCompanyKey,
  FilenameConfigurationEmployeeKey,
  FilenameConfigurationEntity,
  FilenameConfigurationOption,
  FilenameConfigurationOptionType,
  FilenameConfigurationPayrollLayoutKey,
  FilenameConfigurationPayrollLockKey,
  FilenameConfigurationPayrollProviderKey,
  FilenameConfigurationType,
} from 'types/models/payroll/payrollLayout';
import { getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from 'utils/common';
import SelectControl from 'components/UI/SelectControl';
import FieldWrapper from 'components/UI/FieldWrapper';
import TextInputControl from 'components/controls/StyledTextInput';
import { removeIcon } from 'components/svg-images';
import AddRemoveLink from 'components/controls/AddRemoveLink';
import moment from 'moment';
import { Subsection, RemoveOption, Subtitle, MultiFieldWrapper, AddRemoveLinkWrapper } from './styled';

interface CreatePayrollFilenameConfigProps extends WithTranslation {
  /** original value from payroll to populate initial state */
  originalType: FilenameConfigurationType | null;
  /** original value from payroll to populate initial state */
  originalConfig: FilenameConfiguration | null;
  setFilenameConfig: (type: FilenameConfigurationType | null, config: FilenameConfiguration) => void;
}

interface CreatePayrollFilenameConfigState {
  filenameConfigurationType: FilenameConfigurationType | null;
  filenameConfigurationOptions: FilenameConfigurationOption[]; // required
  separator: string;
}

class CreatePayrollFilenameConfig extends Component<
  CreatePayrollFilenameConfigProps,
  CreatePayrollFilenameConfigState
> {
  constructor(props: CreatePayrollFilenameConfigProps) {
    super(props);
    const { originalType, originalConfig } = props;

    this.state = {
      filenameConfigurationType: originalType || null,
      filenameConfigurationOptions: originalConfig ? originalConfig.format.map((o) => ({ ...o, uuid: uuidv4() })) : [],
      separator: originalConfig?.separator || '_',
    };
  }

  setFilenameConfig = () => {
    const { filenameConfigurationType, filenameConfigurationOptions, separator } = this.state;

    this.props.setFilenameConfig(filenameConfigurationType, {
      format: filenameConfigurationOptions.map(({ uuid, ...option }) => option),
      separator,
    });
  };

  /** uuid for updating existing to fresh one (type has changed) */
  addNewOption = (type = FilenameConfigurationOptionType.static, uuid?: string) => {
    const { filenameConfigurationOptions } = this.state;
    const option: FilenameConfigurationOption = {
      uuid: uuidv4(),
      type,
    };

    switch(type) {
      case FilenameConfigurationOptionType.static:
        option.value = '';
        break;
      case FilenameConfigurationOptionType.date:
        option.format = '';
        break;
      case FilenameConfigurationOptionType.entity:
        option.entity = FilenameConfigurationEntity.employee;
        option.key = this.getOptionKeyOptions(FilenameConfigurationEntity.employee)[0].value;
        break;
      case FilenameConfigurationOptionType.fileSequence:
        option.prefix = '';
        option.fillString = '0';
        option.length = 2;
        break;
      default:
    }

    // change existing or add new in the end
    filenameConfigurationOptions.splice(
      uuid ? filenameConfigurationOptions.findIndex((o) => o.uuid === uuid) : filenameConfigurationOptions.length,
      +!!uuid, // 0 or 1
      option,
    );

    this.setState({ filenameConfigurationOptions: [...filenameConfigurationOptions] }, this.setFilenameConfig);
  };

  removeOption = (optionUuid: string) => {
    const { filenameConfigurationOptions } = this.state;
    const remainingOptions = filenameConfigurationOptions.filter((o) => o.uuid !== optionUuid);

    this.setState({ filenameConfigurationOptions: remainingOptions }, this.setFilenameConfig);
  };

  updateOption = (
    optionUuid: string,
    field: Exclude<keyof FilenameConfigurationOption, 'uuid' | 'type'>,
    value: FilenameConfigurationOption[keyof FilenameConfigurationOption],
  ): Promise<void> =>
    new Promise<void>((resolve) => {
      const { filenameConfigurationOptions } = this.state;

      window.moment = moment;

      const updatedOptions = filenameConfigurationOptions.map(
        (o): FilenameConfigurationOption => (o.uuid === optionUuid ? { ...o, [field]: value } : o),
      );

      this.setState({ filenameConfigurationOptions: updatedOptions }, () => {
        this.setFilenameConfig();
        resolve();
      });
    });

  getOptionKeyOptions = (entity?: FilenameConfigurationEntity) => {
    const { t } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    switch (entity) {
      case FilenameConfigurationEntity.employee:
        return [
          { label: t('ID'), value: FilenameConfigurationEmployeeKey.id },
          { label: t('Matricula'), value: FilenameConfigurationEmployeeKey.matricula },
          { label: employeeTaxIdLabel, value: FilenameConfigurationEmployeeKey.cpf },
          { label: t('External ID'), value: FilenameConfigurationEmployeeKey.externalId },
          { label: t('Full Name'), value: FilenameConfigurationEmployeeKey.fullName },
        ];
      case FilenameConfigurationEntity.company:
        return [
          { label: t('ID'), value: FilenameConfigurationCompanyKey.id },
          { label: t('Name'), value: FilenameConfigurationCompanyKey.name },
        ];
      case FilenameConfigurationEntity.payrollProvider:
        return [{ label: t('Name'), value: FilenameConfigurationPayrollProviderKey.name }];
      case FilenameConfigurationEntity.payrollLayout:
        return [
          { label: t('Name'), value: FilenameConfigurationPayrollLayoutKey.name },
          { label: t('Company Code'), value: FilenameConfigurationPayrollLayoutKey.companyCode },
          { label: t('Payroll Code'), value: FilenameConfigurationPayrollLayoutKey.payrollCode },
          { label: t('Payroll Date'), value: FilenameConfigurationPayrollLayoutKey.payrollDate },
        ];
      case FilenameConfigurationEntity.payrollLock:
        return [
          { label: t('Name'), value: FilenameConfigurationPayrollLockKey.name },
          { label: t('Start Date'), value: FilenameConfigurationPayrollLockKey.startDate },
          { label: t('End Date'), value: FilenameConfigurationPayrollLockKey.endDate },
        ];
      default:
        return [];
    }
  };

  render() {
    const { t } = this.props;
    const { filenameConfigurationType, filenameConfigurationOptions, separator } = this.state;
    const optionTypeOtions = [
      { label: t('Static'), value: FilenameConfigurationOptionType.static },
      { label: t('Date'), value: FilenameConfigurationOptionType.date },
      { label: t('FileSequence'), value: FilenameConfigurationOptionType.fileSequence },
    ];

    if (filenameConfigurationType === FilenameConfigurationType.deactivatedEmployee) {
      optionTypeOtions.push({ label: t('Entity'), value: FilenameConfigurationOptionType.entity });
    }

    return (
      <Subsection>
        <Subtitle>{t('Filename configuration')}</Subtitle>

        <MultiFieldWrapper>
          <FieldWrapper fieldName={t('Configuration for')} width='210px'>
            <SelectControl
              value={filenameConfigurationType}
              onChange={(val: FilenameConfigurationType | null): void => {
                this.setState({ filenameConfigurationType: val, filenameConfigurationOptions: [] }, () => {
                  if (val !== null) {
                    this.addNewOption();
                  } else {
                    this.setFilenameConfig();
                  }
                });
              }}
              options={[
                { label: t('No Configuration'), value: null },
                { label: t('Payroll Group'), value: FilenameConfigurationType.payrollGroup },
                { label: t('Deactivated Employee'), value: FilenameConfigurationType.deactivatedEmployee },
              ]}
            />
          </FieldWrapper>

          {filenameConfigurationType !== null && (
            <FieldWrapper fieldName={t('Separator')} width='90px'>
              <TextInputControl value={separator} onChange={(val: string) => this.setState({ separator: val })} />
            </FieldWrapper>
          )}
        </MultiFieldWrapper>

        {filenameConfigurationOptions.map((option) => {
          const { uuid, type, value, format, entity, key, fillString, prefix, length } = option;

          return (
            <MultiFieldWrapper key={uuid}>
              {filenameConfigurationOptions.length > 1 && (
                <AddRemoveLinkWrapper>
                  <RemoveOption style={{
                    position: "static",
                    marginTop: 12
                  }} onClick={() => this.removeOption(uuid)}>{removeIcon(10)}</RemoveOption>
                </AddRemoveLinkWrapper>
              )}

              <FieldWrapper fieldName={t('Type')} width='150px'>
                <SelectControl
                  value={type}
                  onChange={(val: FilenameConfigurationOptionType) => {
                    this.addNewOption(val, uuid);
                  }}
                  options={optionTypeOtions}
                />
              </FieldWrapper>

              {type === FilenameConfigurationOptionType.static && (
                <FieldWrapper fieldName={t('Value')} width='150px'>
                  <TextInputControl value={value} onChange={(val: string) => this.updateOption(uuid, 'value', val)} />
                </FieldWrapper>
              )}
              
              {type === FilenameConfigurationOptionType.fileSequence && (
                <>
                  <FieldWrapper fieldName={t('Prefix')} width='150px'>
                    <TextInputControl
                      value={prefix}
                      onChange={(val: string) => this.updateOption(uuid, 'prefix', val)}
                    />
                  </FieldWrapper>
                  <FieldWrapper fieldName={t('Fill String')} width='150px'>
                    <TextInputControl
                      value={fillString}
                      onChange={(val: string) => this.updateOption(uuid, 'fillString', val)}
                      placeholder='0'
                    />
                  </FieldWrapper>
                  <FieldWrapper fieldName={t('Length')} width='150px'>
                    <TextInputControl
                      type="number"
                      value={length}
                      onChange={(val: string) => this.updateOption(uuid, 'length', val)}
                      placeholder='2'
                    />
                  </FieldWrapper>
                </>
              )}

              {type === FilenameConfigurationOptionType.date && (
                <FieldWrapper fieldName={t('Format')} width='150px'>
                  <TextInputControl
                    value={format}
                    placeholder='YYYY-MM-DD'
                    onChange={(val: string) => this.updateOption(uuid, 'format', val)}
                  />
                </FieldWrapper>
              )}

              {type === FilenameConfigurationOptionType.entity && (
                <>
                  <FieldWrapper fieldName={t('Entity')} width='150px'>
                    <SelectControl
                      value={entity}
                      options={[
                        { label: t('Employee'), value: FilenameConfigurationEntity.employee },
                        { label: t('Company'), value: FilenameConfigurationEntity.company },
                        { label: t('Payroll Provider'), value: FilenameConfigurationEntity.payrollProvider },
                        { label: t('Payroll Lock'), value: FilenameConfigurationEntity.payrollLock },
                        { label: t('Payroll Layout'), value: FilenameConfigurationEntity.payrollLayout },
                      ]}
                      onChange={async (val: FilenameConfigurationEntity) => {
                        await this.updateOption(uuid, 'entity', val);
                        void this.updateOption(uuid, 'key', this.getOptionKeyOptions(val)[0].value);
                      }}
                    />
                  </FieldWrapper>

                  <FieldWrapper fieldName={t('Key')} width='150px'>
                    <SelectControl
                      value={key}
                      options={this.getOptionKeyOptions(entity)}
                      onChange={(val: FilenameConfigurationOption['key']) => {
                        void this.updateOption(uuid, 'key', val);
                      }}
                    />
                  </FieldWrapper>
                </>
              )}
            </MultiFieldWrapper>
          );
        })}

        {filenameConfigurationType !== null && (
          <AddRemoveLinkWrapper>
            <AddRemoveLink label={t('Add option')} onClick={() => this.addNewOption()} />
          </AddRemoveLinkWrapper>
        )}
      </Subsection>
    );
  }
}

export default withTranslation(TranslationNamespaces.payroll)(CreatePayrollFilenameConfig);
