import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import MaskedInput from "components/styled/MaskedInput";
import TimeControl from "components/controls/TimeControl";
import CheckboxControl from "components/UI/NewCheckbox";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { ErrorLabel } from "components/UI/TextLabels";
import { BusinessRuleNightShift } from "types/models/businessRulesGroup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SettingsBlock } from "../styled";

type ErrorType = Record<string, string> | null;

const Wrapper = styled.div``;
const MultipleFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .time-control {
    margin-top: 0;
  }
`;

type NightShiftConfigProps = {
  config: BusinessRuleNightShift;
  isGroupDisabled: boolean;
  isGroupLocked: boolean;
  onChange: (config: BusinessRuleNightShift) => void;
  errors: ErrorType;
};

const percentageMask = createNumberMask({
  prefix: "",
  suffix: "%",
});

const NightShiftConfig = ({ config, onChange, isGroupDisabled, isGroupLocked, errors }: NightShiftConfigProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const flags = useFlags();

  const {
    active: nightShiftActive,
    name,
    nightShiftStartTime,
    nightShiftEndTime,
    nightShiftApplyEntirePeriod,
  } = config;

  return (
    <Wrapper>
      <SettingsBlock>
        <FieldWrapper>
          <CheckboxControl
            ios
            checked={nightShiftActive}
            disabled={isGroupDisabled || isGroupLocked}
            label={nightShiftActive ? t("Deactivate nightshift") : t("Activate Night Shift")}
            onChange={(checked) => onChange({ ...config, active: checked })}
          />
        </FieldWrapper>

        <MultipleFieldsWrapper>
          <FieldWrapper fieldName={t("Start Time")} width="120px" wrapperWidth="120px">
            <TimeControl
              disabled={!nightShiftActive || isGroupDisabled}
              locked={isGroupLocked}
              value={nightShiftStartTime}
              onTimeChange={(val) => onChange({ ...config, nightShiftStartTime: val.value })}
              error={!!errors?.nightShiftStartTime}
              placeholder="00:00"
            />
          </FieldWrapper>
          <FieldWrapper fieldName={t("End Time")} width="120px" wrapperWidth="120px">
            <TimeControl
              disabled={!nightShiftActive || isGroupDisabled}
              locked={isGroupLocked}
              value={nightShiftEndTime}
              onTimeChange={(val) => onChange({ ...config, nightShiftEndTime: val.value })}
              error={!!errors?.nightShiftEndTime}
              placeholder="00:00"
            />
          </FieldWrapper>
          <FieldWrapper
            fieldName={t("Percentage %")}
            width="120px"
            tooltipText={t("nighshift-name-tooltip-text")}
            wrapperWidth="120px"
          >
            <MaskedInput
              placeholder="%"
              error={!!errors?.name}
              disabled={!nightShiftActive || isGroupDisabled}
              locked={isGroupLocked}
              value={name}
              onChange={(ev) => {
                let value = ev.target.value.replace(/[^\d]/g, "");
                value = value ? parseInt(value, 10) : 0;
                onChange({ ...config, name: value });
              }}
              mask={percentageMask}
            />
          </FieldWrapper>
        </MultipleFieldsWrapper>
        {errors?.startTime && <ErrorLabel>{errors.startTime}</ErrorLabel>}
        {errors?.endTime && <ErrorLabel>{errors.endTime}</ErrorLabel>}
        {errors?.name && <ErrorLabel>{errors.name}</ErrorLabel>}

        {!flags.moveExtendedNightShiftToSchedule && (
          <FieldWrapper>
            <CheckboxControl
              checked={nightShiftApplyEntirePeriod}
              disabled={!nightShiftActive || isGroupDisabled || isGroupLocked}
              label={t("Apply entire period started with Nightshift to nightshifts rules.")}
              onChange={(checked) => {
                onChange({ ...config, nightShiftApplyEntirePeriod: checked });
              }}
            />
          </FieldWrapper>
        )}
      </SettingsBlock>
    </Wrapper>
  );
};

export default NightShiftConfig;
