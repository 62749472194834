import { PropsWithChildren, useEffect, useRef } from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Portal from "@atlaskit/portal";

const Overlay = styled.div<{ $isOpen: boolean; $dark?: boolean }>`
  position: fixed;
  inset: 0;
  background: ${(p) => (p.$dark ? "rgba(0, 0, 0, 0.70);" : "rgb(255 255 255 / 93%)")};
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  overscroll-behavior: contain;
  overflow-y: auto;
`;

const Dialog = styled.div<{ $top?: string; $width: number }>`
  position: absolute;
  width: ${(props) => props.$width}px;
  border-radius: var(--component-modal-border-radius, 8px);
  border: 1px solid var(--colors-gray-150, #e1e3eb);
  background: var(--colors-gray-0, #fff);
  box-shadow: 0px 8px 24px 0px rgba(33, 36, 46, 0.12), 0px 1px 4px 0px rgba(33, 36, 46, 0.04);
  top: ${(props) => (props.$top ? props.$top : "calc(50% - 290px)")};
  left: ${(props) => `calc(50% - ${props.$width / 2}px);`};
`;

export interface ModalDialogProps extends PropsWithChildren {
  onClose: () => void;
  width: number;
  isOpen: boolean;
  top?: string;
  preventOutsideClose?: boolean;
  dark?: boolean;
}

const ModalDialog = ({ onClose, children, isOpen, width, top, dark, preventOutsideClose }: ModalDialogProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleKeyPress = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27 && !preventOutsideClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => document.removeEventListener("keyup", handleKeyPress);
  }, []);

  return (
    <Portal key="HoursBankAdjustPopup" zIndex={250}>
      <TransitionGroup>
        {isOpen && (
          <CSSTransition classNames="modal-transition" timeout={{ enter: 175, exit: 175 }}>
            <Overlay
              $dark={dark}
              className="overlay"
              $isOpen
              key="overlay"
              onMouseDown={() => !preventOutsideClose && onClose()}
            >
              <Dialog
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                $width={width}
                $top={top}
                className="modal-dialog"
                ref={wrapperRef}
              >
                {children}
              </Dialog>
            </Overlay>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Portal>
  );
};

ModalDialog.defaultProps = {
  width: 796,
};

export default ModalDialog;
