import { stylesheet } from "astroturf";
import cx from "classnames";

export const styles = stylesheet`
  .Header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: flex-start;
    height: 58px;
    background: var(--colors-surface-50);
  }
`;

export type HeaderProps = React.HTMLProps<HTMLDivElement>;

export function Header(props: HeaderProps) {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx(styles.Header, className)} {...restProps}>
      {children}
    </div>
  );
}
