import { Component } from "react";
import Tooltip from "components/UI/Tooltip";
import * as images from "components/svg-images";
import moment from "moment";

class CellHoliday extends Component {
  state = {};

  render() {
    const { date, holiday } = this.props;
    const tipId = `holiday-col-${moment(date, "YYYY-MM-DD").format("YYYYMMDD")}`;
    return (
      <div>
        <div data-tip data-for={tipId}>
          {images.holidayIcon}
        </div>
        <Tooltip id={tipId}>{holiday}</Tooltip>
      </div>
    );
  }
}

export default CellHoliday;
