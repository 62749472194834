import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import SelectControl from "components/UI/SelectControl";
import { TranslationNamespaces } from "types/translationNamespaces";
import { GlobalContext } from "context/GlobalContextProvider";

type Option<T> = {
  label: string;
  value: T;
};

interface TeamsDropdownControlProps<T> {
  t: WithTranslation["t"];
  /** Team uuid or id as an option value. Pass string type as type parameter if using uuid  */
  uuid?: boolean;
  value: T;
  /** Team uuid or id */
  onChange: (value: T | null) => void;
}

interface TeamsDropdownControlState<T> {
  isLoading: boolean;
  options: Option<T>[];
}

class TeamsDropdownControl<T = number | string> extends Component<
  TeamsDropdownControlProps<T>,
  TeamsDropdownControlState<T>
> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<TeamsDropdownControlState<T>> = {
    isLoading: true,
    options: [],
  };

  async componentDidMount() {
    const { t, uuid } = this.props;

    const teams = (await this.context.getTeams()) || [];

    let options: Option<T>[] = [{ label: t("none-option"), value: "" as T }];

    if (teams.length > 0) {
      options = options.concat(teams.map((tm) => ({ label: tm.name, value: (uuid ? tm.uuid : tm.id) as T })));
    }

    this.setState({ options, isLoading: false });
  }

  render() {
    const { t, value, onChange } = this.props;
    const { options, isLoading } = this.state;

    return (
      <>
        <SelectControl<T>
          options={options}
          onChange={onChange}
          isLoading={isLoading}
          value={value}
          isSearchable
          placeholder={t("Select team")}
        />
      </>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.teams])(
  TeamsDropdownControl,
);
