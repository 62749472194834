/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import deepEqual from "fast-deep-equal";
import sleep from "sleep-promise";
import { services } from "components/PunchNow/services";
import { Cache } from "services/cache";
import { useAsyncCallback } from "./useAsyncEffect";

const prefix = "useAsyncCallbackCached_";
const cache = new Cache(prefix, services.kvStorageIdb);

/**
 * - return cached data if available
 * - load fresh data in background (or in foreground if no cache available)
 * - updata data when it is loaded
 */
export function useAsyncCallbackCached<F extends (...args: any[]) => Promise<any>>(
  callback: F,
  dependencies: React.DependencyList,
  {
    key,
    vary,
    ttl,
    softTtl = 0,
    delay = 0,
  }: {
    key: string;
    vary: any;
    /**
     * Hard timeout
     * after elapsed - data is not returned even if exists in cache, full load is performed in foreground
     * ms timestamp or [ISO 8601 duration](https://momentjs.com/docs/#:~:text=parsing%20ISO%208601%20durations) (e.g. 'P1Y2M3DT4H5M6S')
     */
    ttl: string | number;
    /**
     * Soft timeout (0 by default)
     * before elapsed - data is returned from cache
     * after elapsed - data is returned from cache while fresh data is loaded in background
     */
    softTtl?: string | number;
    /**
     * Ms delay to start background loading if cache was available
     * This is useful to let more urgent uncached requests to take up the bandwidth/slots first
     */
    delay?: number;
  },
) {
  const [loading, setLoading] = useState(false);
  const [loadingBg, setLoadingBg] = useState(false);
  const [result, setResult] = useState();

  /**
   * Check for value in cache.
   * If there is value in cache and it is fresh then return it and do nothing.
   * if there is value in cache but it is stale then return it but also load updated value in background after delay.
   * If there is no value in cache then load value in foreground immediately.
   */
  const [load] = useAsyncCallback(async (...args) => {
    setLoading(true);
    const [cached, isFresh] = await cache.load(key, vary, ttl, softTtl);
    const hasValue = cached != null;
    if (hasValue) {
      setLoading(false);
      setResult(cached);
    }
    if (isFresh) return;

    if (hasValue) {
      setLoadingBg(true);
      await sleep(delay);
    }

    // at this point either loading or loadingBg is set to true
    const res = await callback(...args);
    await cache.store(key, res, vary);
    const isChanged = !deepEqual(res, cached);
    if (isChanged) {
      setResult(res);
    }

    setLoading(false);
    setLoadingBg(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [load, loading, result, loadingBg] as [
    cb: F,
    loading: boolean,
    res: Awaited<ReturnType<F>>,
    loadingBg: boolean,
  ];
}
