import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import FullPage from "components/Layout/FullPage";
import { GlobalContext } from "context/GlobalContextProvider";
import { dowDict, getWeekStart } from "utils/get-week-start";
import { GlobalStoreCompany } from "types/models/company";
import CONFIG from "config";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { RouteComponentProps, useHistory } from "react-router-dom";
import styled from "styled-components";
import ModalDialog from "components/UI/ModalDialog";
import { Notification } from "types/common";
import NotificationRow from "components/NotificationRow";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import SubmitTimesheetPopup from "components/Timesheets/components/SubmitTimesheetPopup";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { createActivities } from "components/Projects/projectsApiUtils";
import { ActivityInCreatePayload } from "components/Projects/projectsApiTypes";
import { getEmployeesByUUIDs } from "utils/apiHelpers";
import TimesheetSettingsModal from "components/Timesheets/components/TimesheetSettingsModal";
import { hasProjectsAccess } from "utils/common";
import { ActivitiesCalendarPage } from "./ActivitiesCalendarPage";
import * as images from "../svg-images";
import AddActivities from "./AddActivities";
import { TimesheetContext } from "../Timesheets/timesheets.context";

const HeaderActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  & > * {
    flex-shrink: 0;
  }
`;

const SettingsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid var(--colors-surface-200);
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

type ActivitiesCalendarPageProps = {
  from?: string;
  to?: string;
  userProfileUuid?: string;
};

const updateUrlWithParams = (
  {
    from,
    to,
    userProfileUuid,
  }: {
    from: moment.Moment;
    to: moment.Moment;
    userProfileUuid: string;
  },
  history: RouteComponentProps["history"],
) => {
  const params = [];
  params.push(`from=${from.format(CONFIG.apiDateFormat)}`);
  params.push(`to=${to.format(CONFIG.apiDateFormat)}`);
  params.push(`user_profile_uuid=${userProfileUuid}`);
  history.push(`?${params.join("&")}`);
};

export const ActivitiesCalendarPageWrapper = (props: ActivitiesCalendarPageProps) => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.timesheets]);
  const context = useContext(GlobalContext);
  const timesheetContext = useContext(TimesheetContext);
  const history = useHistory();
  const getWeekStartDate = () => {
    const firstDayOfWeek = dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day];
    return getWeekStart(moment(), firstDayOfWeek);
  };
  const startDate = useMemo(
    () => (props.from ? moment(props.from, CONFIG.apiDateFormat) : getWeekStartDate()),
    [props.from],
  );
  const endDate = useMemo(() => startDate.clone().add(1, "week").subtract(1, "day"), [startDate]);
  const [popupSettingsVisible, setPopupSettingsVisible] = useState(false);
  const [notification, setNotification] = useState<Notification>({ notification: null, notificationType: null });
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [popupAddTimeVisible, setPopupAddTimeVisible] = useState(false);

  const [selectEmployee, loading, selectedEmployee] = useAsyncCallback(async () => {
    const { user_profiles } = await getEmployeesByUUIDs({ uuids: [props.userProfileUuid] });
    return user_profiles[0] || window.global_store.profile;
  }, [props.userProfileUuid]);

  const onAddActivities = async (activities: ActivityInCreatePayload[]) => {
    try {
      const company = await context.getCompany();
      await createActivities({
        companyUuid: company.uuid,
        body: {
          content: {
            activities,
            createdBy: window.global_store.profile.uuid,
          },
        },
      });

      // await getActivities();
      setPopupAddTimeVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => void selectEmployee(), [selectEmployee]);
  useEffect(() => {
    if ((!props.from || !props.to || !props.userProfileUuid) && selectedEmployee?.uuid) {
      updateUrlWithParams(
        {
          from: startDate,
          to: endDate,
          userProfileUuid: selectedEmployee.uuid,
        },
        history,
      );
    }
  }, [props.from, props.to, props.userProfileUuid, selectedEmployee]);

  if (!selectedEmployee) return null;

  return (
    <FullPage
      headerAction={
        <HeaderActionWrapper>
          {hasProjectsAccess() && (
            <SettingsButton onClick={() => setPopupSettingsVisible(true)}>{images.gearIcon}</SettingsButton>
          )}
          {timesheetContext.isTimesheetEnabled && (
            <Button
              value={t(`${TranslationNamespaces.timesheets}|Submit timesheet`)}
              state={ButtonState.outline}
              style={{
                width: "auto",
                padding: "0 16px",
                whiteSpace: "nowrap",
                fontSize: 14,
                fontWeight: 500,
                color: "var(--colors-surface-600)",
              }}
              onClick={() => setShowSubmitPopup(true)}
            />
          )}
          <HeaderActionButtonAdd
            title={t(`${TranslationNamespaces.timesheets}|Add time`)}
            state={ButtonState.primary}
            style={{
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "nowrap",
            }}
            onClick={() => setPopupAddTimeVisible(true)}
          />
        </HeaderActionWrapper>
      }
    >
      {notification.notification && (
        <NotificationRow
          message={notification.notification}
          type={notification.notificationType}
          withCloseButton
          onClose={() => setNotification({ notification: null, notificationType: null })}
        />
      )}
      <ActivitiesCalendarPage
        startDate={startDate}
        endDate={endDate}
        selectedEmployee={selectedEmployee as unknown as { uuid: string; full_name: string; id: number }}
      />

      <ModalDialog isOpen={showSubmitPopup} onClose={() => setShowSubmitPopup(false)}>
        <SubmitTimesheetPopup onClose={() => setShowSubmitPopup(false)} />
      </ModalDialog>

      {popupSettingsVisible && (
        <TimesheetSettingsModal
          onClose={() => setPopupSettingsVisible(false)}
          setNotification={(n) =>
            setNotification({ notification: n.notification, notificationType: n.notificationType })
          }
        />
      )}

      {popupAddTimeVisible && (
        <SidePopupOverlay
          width={614}
          header={t("Create Multiple Activities")}
          isOpen={popupAddTimeVisible}
          onClose={() => setPopupAddTimeVisible(false)}
          contentOverflow
          paddingBottom
        >
          <AddActivities
            isTimesheetEnabled={timesheetContext.isTimesheetEnabled}
            prefillProfile={null}
            onYes={onAddActivities}
            onClose={() => setPopupAddTimeVisible(false)}
          />
        </SidePopupOverlay>
      )}
    </FullPage>
  );
};
