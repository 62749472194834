import moment from "moment-timezone";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import { Component } from "react";
import { START_DATE } from "react-dates/constants";
import { stylesheet } from "astroturf";
import { ReactComponent as SelectIcon } from "img/select-arrow.svg";
import styled from "styled-components";
import { dowDict } from "utils/get-week-start";
import { GlobalStoreCompany } from "types/models/company";
import ClickOutside from "react-click-outside";
import { DatePickerCommonOverrides, CalendarInsideNext, CelandarInsidePrev } from "../styled";

const Wrapper = styled(DatePickerCommonOverrides)`
  position: relative;
`;

const styles = stylesheet`
  .SelectBlock {
    border: 1px solid var(--colors-surface-400);
    border-radius: var(--shapes-border-radius-default);
    padding: 10px 16px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.Empty {
      color: var(--colors-surface-500);
    }
  }
`;

const Popup = styled.div`
  top: 0;
  inset-inline-start: 0;
  z-index: 1;
`;

type DateRangeSelectorProps = {
  startDate?: moment.Moment | null;
  endDate?: moment.Moment | null;
  handleDateChange: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  selectPlaceholder?: string;
  isOutsideRange?: (day: moment.Moment) => boolean;
  isDayBlocked?: (day: moment.Moment) => boolean;
  disabled?: boolean;
  numberOfMonths?: number;
  dropOver: boolean;
  smallHeight: boolean;
};
type DateRangeSelectorState = {
  focusedInput: FocusedInputShape | null;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  showDatePicker: boolean;
};

/**
 * Used in places where we date control to look like a dropdown
 * Ex: Createing All Day requests, Submitting Timesheets
 */
export default class DateRangeSelector extends Component<DateRangeSelectorProps, DateRangeSelectorState> {
  constructor(props: DateRangeSelectorProps) {
    super(props);

    this.state = {
      focusedInput: START_DATE,
      startDate: props.startDate || null,
      endDate: props.endDate || null,
      showDatePicker: false,
    };
  }

  onDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    let ed = endDate;
    if (startDate !== this.state.startDate) {
      ed = null;
    }

    this.props.handleDateChange(startDate, ed);
    this.setState({ startDate, endDate: ed });
  };

  onFocusChange = (focusedInput: string | null) => {
    if (focusedInput === null) {
      this.setState({ showDatePicker: false });
    }
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput });
  };

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    const startDateString = startDate && startDate.format("MMM D");
    const endDateString = endDate && endDate.format("MMM D");
    let dateRangeString = this.props.selectPlaceholder || "";

    if (startDate && endDate) {
      if (startDateString === endDateString) {
        dateRangeString = startDateString!;
      } else {
        dateRangeString = `${startDateString} - ${endDateString}`;
      }
    }

    const firstDayOfWeek = dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day];

    return (
      <ClickOutside onClickOutside={() => this.setState({ showDatePicker: false })}>
        <Wrapper>
          <div
            className={`${styles.SelectBlock} ${!startDate || !endDate ? styles.Empty : ""}`}
            style={this.props.smallHeight ? { padding: "7px 10px" } : {}}
            onClick={() => this.setState({ showDatePicker: !this.state.showDatePicker })}
          >
            <span>{dateRangeString}</span>
            <SelectIcon />
          </div>

          {this.state.showDatePicker && (
            <Popup
              style={{
                position: this.props.dropOver ? "absolute" : "relative",
              }}
            >
              <DayPickerRangeController
                firstDayOfWeek={firstDayOfWeek}
                isRTL={window.global_store.isRTL}
                onFocusChange={this.onFocusChange}
                onDatesChange={this.onDatesChange}
                focusedInput={focusedInput}
                startDate={startDate!}
                endDate={endDate!}
                isOutsideRange={this.props.isOutsideRange}
                isDayBlocked={this.props.isDayBlocked}
                keepOpenOnDateSelect={false}
                enableOutsideDays={false}
                hideKeyboardShortcutsPanel
                minimumNights={0}
                numberOfMonths={this.props.numberOfMonths || 2}
                navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
                navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
                initialVisibleMonth={() => moment(startDate?.clone()).add(2, "days")}
                disabled={this.props.disabled || false}
              />
            </Popup>
          )}
        </Wrapper>
      </ClickOutside>
    );
  }
}
