import styled from "styled-components";
import { RequestUserProfile } from "types/models/userProfile";
import { hasPermisionAccess, minsToHrsMins, PermissionSectionName } from "utils/common";
import cx from "classnames";
import { CompanyTimesheetSettings, UserProfileTask } from "components/Projects/projectsApiTypes";
import ActivityCell from "./ActivityCell";
import { ClearRow } from "./styled";
import TaskProjectCell from "./TaskProjectCell";

export const ActivityRow = styled.div`
  height: 45px;
  display: grid;
  cursor: pointer;
  border-top: 1px solid var(--colors-surface-150);
  position: relative;
  align-items: center;
  grid-template-columns: 1fr repeat(8, 93px);
  .task-cell .common-text,
  .total-cell {
    padding-inline-start: 10px;
  }
  &:hover {
    background-color: var(--colors-surface-50);
    .clear-row-button {
      visibility: visible;
    }
  }
`;

const Cell = styled.div<{ joinCols?: number }>`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  ${(props) => (props.joinCols ? `grid-column: ${props.joinCols};` : "")}
`;

export default ({
  dates,
  data,
  tasksList,
  loading,
  onCellTimeChange,
  onRemoveRow,
  userProfile,
  forceRefetchTable,
  isDayPlannedArray,
  unavailableDates = [],
  viewOnly,
  startDayOffset = 0,
  settings,
}: {
  dates: string[];
  userProfile: RequestUserProfile;
  data: {
    taskUuid: string;
    projectUuid: string;
    taskName: string;
    projectName: string;
    dates: number[];
  };
  tasksList: UserProfileTask[];
  loading: boolean;
  onCellTimeChange: (taskUuid: string, date: string, value: number) => void;
  onRemoveRow: (taskUuid: string) => void;
  forceRefetchTable: () => void;
  isDayPlannedArray: boolean[];
  unavailableDates: string[];
  viewOnly?: boolean;
  startDayOffset?: number;
  settings?: CompanyTimesheetSettings;
}) => {
  const hasAvailableDates = dates.some((d) => !unavailableDates.includes(d));
  const showClearRowBtn =
    (!viewOnly && data.dates.every((v) => !v)) ||
    (hasAvailableDates && hasPermisionAccess(PermissionSectionName.approveActivities));

  return (
    <ActivityRow className={cx({ loading })}>
      {showClearRowBtn && (
        <ClearRow
          className="clear-row-button"
          onClick={() => {
            onRemoveRow(data.taskUuid);
          }}
        />
      )}
      <Cell className="task-cell">
        <TaskProjectCell taskName={data.taskName} projectName={data.projectName} />
      </Cell>
      {startDayOffset > 1 ? <Cell joinCols={startDayOffset} className={cx("common-cell")} /> : null}
      {data.dates.map((value, i) => (
        <Cell key={data.taskUuid + dates[i]} className="common-cell common-text">
          <ActivityCell
            editable={!unavailableDates.includes(dates[i])}
            isDayPlanned={isDayPlannedArray[i] === true}
            tasksList={tasksList}
            taskUuid={data.taskUuid}
            taskName={data.taskName}
            projectName={data.projectName}
            projectUuid={data.projectUuid}
            userProfile={userProfile}
            date={dates[i]}
            placeholder="–"
            value={value}
            forceRefetchTable={forceRefetchTable}
            onCellTimeChange={(time: number): void => {
              onCellTimeChange(data.taskUuid, dates[i], time || 0);
            }}
            settings={settings}
          />
        </Cell>
      ))}
      <Cell className="total-cell common-text" style={{ paddingInlineEnd: "13px", gridColumn: "9" }}>
        {minsToHrsMins(data.dates.reduce((partialSum, a) => partialSum + a, 0))}
      </Cell>
    </ActivityRow>
  );
};
