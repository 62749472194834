import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import GlobalContext from "context/global-context";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Break } from "utils/api/types";

const Title = styled.div`
  font-size: 24px;
  color: var(--colors-surface-900-p);
  letter-spacing: -0.55px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 6px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  gap: 12px;
  button {
    flex: 1;
    height: 40px;
  }
  button[state="cancel"] {
    background: var(--colors-danger-600-p);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-700);
  margin-top: 24px;
  margin-bottom: 40px;
`;

interface PopupMessageProps extends WithTranslation {
  breakInfo: Break | null;
  onClose: () => void;
  onYes: () => void;
}

class DeleteBreakModal extends Component<PopupMessageProps> {
  static contextType = GlobalContext;

  render() {
    const { t, breakInfo, onYes, onClose } = this.props;

    const numberOfSchedules = breakInfo?.schedules?.length || 0;
    const isArchiveMode = !!numberOfSchedules;

    const titleText = isArchiveMode ? "Archive Schedule Break" : "Delete Schedule Break";
    const yesBtnLabel = isArchiveMode
      ? `${TranslationNamespaces.common}|Archive`
      : `${TranslationNamespaces.common}|Delete`;
    const descriptionText = isArchiveMode ? t("Archive modal") : t("Delete modal");
    const yesBtnState = isArchiveMode ? ButtonState.primary : ButtonState.cancel;

    return (
      <div>
        <Title>{t(titleText)}</Title>

        <TextWrapper>{descriptionText}</TextWrapper>

        <ButtonsWrapper>
          <Button value={t(`${TranslationNamespaces.common}|Cancel`)} state={ButtonState.secondary} onClick={onClose} />
          <Button value={t(yesBtnLabel)} state={yesBtnState} onClick={onYes} />
        </ButtonsWrapper>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.schedules)(DeleteBreakModal);
