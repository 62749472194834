import { useState } from "react";
import { stylesheet } from "astroturf";
import { ActivityTaskSelector, ActivityTaskSelectorProps } from "../ActivityTaskSelector";
import { ActivityTaskCreate, ActivityTaskCreateProps } from "../ActivityTaskCreate";
import { Modal } from "../Modal";

const styles = stylesheet`

  .Modal {
    & > * {
      height: 100%;

      & > * {
        background: var(--colors-default);
        height: 100%;
      }
    }
  }
`;

export type ActivityTaskSelectorWithCreateProps = Omit<ActivityTaskSelectorProps, "onAddTask"> & {
  onAddTask: ActivityTaskCreateProps["onCreate"];
};
export function ActivityTaskSelectorWithCreate(props: ActivityTaskSelectorWithCreateProps) {
  const { projects, selectedTaskUuid, onChange, onClose, onAddTask } = props;

  const [newTaskProject, setNewTaskProject] = useState<ActivityTaskSelectorProps["projects"][0]>();
  const [newTaskName, setNewTaskName] = useState<string | undefined>("");
  const [showTaskCreate, setShowTaskCreate] = useState(false);

  return (
    <>
      <ActivityTaskSelector
        projects={projects}
        selectedTaskUuid={selectedTaskUuid}
        onChange={onChange}
        onClose={onClose}
        onAddTask={(tsk) => {
          setNewTaskProject(tsk.project);
          setNewTaskName(tsk.name || "");
          setShowTaskCreate(true);
        }}
      />

      {showTaskCreate && (
        <Modal
          className={styles.Modal}
          onBackgroundClick={() => {
            setShowTaskCreate(false);
          }}
        >
          <ActivityTaskCreate
            project={newTaskProject}
            name={newTaskName}
            projects={newTaskProject ? null : projects}
            onClose={() => {
              setShowTaskCreate(false);
            }}
            onCreate={(newTask) => {
              setShowTaskCreate(false);
              onAddTask(newTask);
            }}
          />
        </Modal>
      )}
    </>
  );
}
