import { ReactNode, useState } from "react";
import ClickOutside from "react-click-outside";
import styled from "styled-components";
import * as images from "../../svg-images";

const Dropdown = styled.div`
  position: relative;
  .DropdownControl {
    width: 36px;
    height: 36px;
    padding: 0 10px;
    background: var(--colors-surface-50);
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--surface-colors-surface-800);
    font-size: 15px;
    cursor: pointer;
    border-radius: var(--shapes-border-radius-default);
    .Icon {
      display: flex;
      margin-inline-end: 4px;
      svg path {
        fill: var(--colors-surface-800);
      }
    }
  }
  .DropdownOptions {
    position: absolute;
    background: var(--colors-surface-0);
    z-index: 3;
    top: 40px;
    right: 0;
    width: 240px;
    border-radius: var(--shapes-border-radius-default);
    box-shadow: 0px 10px 30px 0px rgba(129, 147, 171, 0.2);
    .Text {
      padding: 8px;
      margin: 8px;
      color: var(--colors-surface-600);
      font-size: 12px;
      font-weight: var(--typography-font-weight-bold);
      text-transform: uppercase;
    }
    .Option {
      margin: 8px;
      display: flex;
      height: 38px;
      padding: 10px 8px;
      align-items: center;
      gap: 12px;
      justify-content: space-between;
      cursor: pointer;
      font-size: var(--typography-font-size-default);
      svg path {
        fill: var(--colors-surface-600);
      }
      &.Active {
        cursor: default;
        color: var(--colors-primary-500-p);
        svg path {
          fill: var(--colors-primary-500-p);
        }
      }
      &.Danger {
        color: var(--colors-danger-600-p);
      }
    }
    .Divider {
      border-top: 1px solid var(--colors-surface-100);
    }
  }
`;

type ModeControlsDropdownProps = {
  headerComponent: JSX.Element;
  optionsComponent: ReactNode;
  hideDropdownIcon?: boolean;
};

export const ModeControlsDropdown = (props: ModeControlsDropdownProps) => {
  const [opened, setOpen] = useState(false);

  return (
    <ClickOutside onClickOutside={() => setOpen(false)}>
      <Dropdown>
        <div onClick={() => setOpen(!opened)} className="DropdownControl">
          {props.headerComponent}
          {!props.hideDropdownIcon ? images.smallArrowDown() : null}
        </div>
        {opened && <div className="DropdownOptions">{props.optionsComponent}</div>}
      </Dropdown>
    </ClickOutside>
  );
};
