import cookie from "react-cookies";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { fireEvent, getStoredAuthToken } from "utils/common";
import { logOut } from "utils/apiHelpers";
import * as oitchauDb from "utils/oitchauDb";
import sentryUtils from "utils/sentryUtils";
import CONFIG from "../../config";

interface Headers {
  "Content-Type": string;
  Authorization?: string;
  "X-User-Email"?: string;
  "X-User-Token"?: string;
}

export function getHeaders(): Headers {
  const email = cookie.load("userEmail");
  const token = cookie.load("userToken");
  const auth = getStoredAuthToken();

  const obj: Headers = {
    "Content-Type": "application/json",
  };
  if (auth) {
    obj.Authorization = `Bearer ${auth}`;
  } else {
    obj["X-User-Email"] = email;
    obj["X-User-Token"] = token;
  }

  return obj;
}

export type QueryParams = Record<string, string | number>;

export function paramsToUrlParams(params: QueryParams): string {
  let paramsUrl = "";

  if (params) {
    const paramsArray = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [prop, value] of Object.entries(params)) {
      if (value !== null && value !== undefined) {
        paramsArray.push(`${prop}=${value}`);
      }
    }

    paramsUrl = `?${paramsArray.join("&")}`;
  }

  return paramsUrl;
}

type PaginationData = {
  totalCount: number;
  page: number;
  perPage: number;
  total?: string; // get Requests
  totalPages?: number; // get Requests
};

type ResponseData<D> = D & {
  pagingTotalCount?: string;
  errors?: any; // TODO
  metadata?: PaginationData; // TODO
};

export type APIResponse<D> = Promise<ResponseData<D>>;
export type APIEmptyResponse = Promise<void>;

/**  PD - payload data obj, RD - response data obj */
export function sendRequest<PD = Record<string, unknown>, RD = Record<string, unknown>>(
  data: PD = {} as PD,
  method: Method = "get",
  url = "",
  apiUrl = CONFIG.api,
): APIResponse<RD> {
  const startTimer = new Date().getTime();

  return new Promise((res, rej) => {
    axios({
      method,
      url: `${CONFIG.protocol}${apiUrl}${url}`,
      data,
      headers: getHeaders(),
    })
      .then((resp: AxiosResponse<ResponseData<RD>>) => {
        const duration = new Date().getTime() - startTimer;

        if (resp.data && resp.data.errors) {
          rej(resp.data.errors);
        } else {
          const respData = resp.data;
          if (resp.headers && resp.headers["paging-total-count"]) {
            respData.pagingTotalCount = resp.headers["paging-total-count"];
          }

          res(respData);
        }
      })
      .catch(async (err: AxiosError | any) => {
        let errorStr = "";
        const duration = new Date().getTime() - startTimer;

        if (err.response && err.response.status >= 400) {
          // if any endpoint returns 401, call /user_profiles/current to be sure user should be logged out
          if (err.response.status === 401 && !(err as AxiosError).config.url?.includes?.("sessions")) {
            const errUrl = (err as AxiosError)?.config?.url || "";

            // avoid recursion
            if (!errUrl.includes("user_profiles/current") && !errUrl.includes("/password/reset_password")) {
              try {
                // check whether user was logged out of the system
                await sendRequest({}, "get", "/user_profiles/current");
              } catch (errorFromCurrent) {
                // if /user_profiles/current returns 401
                if ((errorFromCurrent as AxiosError)?.response?.status === 401) {
                  fireEvent("LogOut");

                  setTimeout(async () => {
                    try {
                      await logOut();
                      await oitchauDb.clearDb();
                      window.location = "/" as unknown as Location;
                    } catch (errorDuringLogout) {
                      sentryUtils.sendError(`Error during logout on 401: ${errorDuringLogout.message}`);
                    }
                  }, 100);
                  return;
                }
              }
            } else if ((err as AxiosError).config.url) {
              // pass original axios error
              rej(err);
              return;
            }
          }
        }

        if (err && err.response && err.response.data) {
          if (err.response.data.indexOf && err.response.data.indexOf("<html>") > -1) {
            // TODO fix
            // eslint-disable-next-line
            rej("Server Error");
          } else if (err.response.data.error) {
            rej(err.response.data.error);
          } else if (err.response.data.errors) {
            rej(err.response.data.errors);
          } else {
            const keys = Object.keys(err.response.data);

            keys.forEach((key) => {
              errorStr += `${key} ${err.response.data[key]}; `;
            });

            rej(errorStr);
          }
        } else if (err.error) {
          rej(err.error);
        } else {
          // TODO fix
          // eslint-disable-next-line
          rej(`Server Error ${err.message || err}`);
        }
      });
  });
}

export function sendRequestNew<PD = Record<string, unknown>, RD = Record<string, unknown>>(
  data: PD = {} as PD,
  method: Method = "get",
  url = "",
  apiUrl = CONFIG.api,
): APIResponse<RD> {
  return new Promise((res, rej) => {
    axios({
      method,
      url: `${CONFIG.protocol}${apiUrl}${url}`,
      data,
      headers: getHeaders(),
    })
      .then((resp) => {
        if (resp.data && resp.data.errors) {
          rej(resp.data.errors);
        } else {
          const respData = resp.data;

          if (resp.headers && resp.headers["paging-total-count"]) {
            respData.pagingTotalCount = resp.headers["paging-total-count"];
          }

          res(respData);
        }
      })
      .catch((err) => {
        rej(err);
      });
  });
}

interface HSFrormCreateContractData {
  hutk: string;
  email: string;
  firstName: string;
  mobilephone: string;
  refCode: string;
  country?: string;
  trafficSource?: string;
  trafficMedium?: string;
  trafficCampaing?: string;
}

export function submitHSFormCreateContact({
  hutk,
  email,
  firstName,
  mobilephone,
  refCode,
  country,
  trafficSource,
  trafficMedium,
  trafficCampaing,
}: HSFrormCreateContractData): Promise<boolean> {
  // https://api.hsforms.com/submissions/v3/integration/submit/:portalId/:formGuid
  return new Promise((res, rej) => {
    const payload = {
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "mobilephone",
          value: mobilephone,
        },
        {
          name: "promotional_code",
          value: refCode,
        },
      ],
      context: {
        hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        // "pageUri": window.location.href,
        // "pageName": "Criar uma conta"
      },
    };
    if (trafficSource) payload.fields.push({ name: "utm_source", value: trafficSource });
    if (trafficMedium) payload.fields.push({ name: "utm_medium", value: trafficMedium });
    if (trafficCampaing) payload.fields.push({ name: "utm_campaign", value: trafficCampaing });

    axios({
      method: "POST",
      url: "https://api.hsforms.com/submissions/v3/integration/submit/20984162/e2240b5b-cf0e-460f-aa65-210d70025a17",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    })
      .then(() => {
        res(true);
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
}
