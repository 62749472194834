import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import BEM from "utils/BEM";
import { getCompanyRules, getLockGroupsList } from "utils/apiHelpers";
import { getSelectedColumns } from "utils/tableHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PayrollGroupStatus } from "types/models/payrollGroup";
import FieldWrapper from "components/UI/FieldWrapper";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import Select from "components/UI/Select";
import CheckboxControl from "components/UI/NewCheckbox";
import MultiWithSearch from "components/UI/Select/MultiWithSearch";
import Button, { ButtonState } from "components/controls/StyledButton";
import * as images from "components/svg-images";
import "styles/holidays-popup-message.scss";
import { ReportType } from "types/reports";
import { CompanyRuleNames } from "types/models/companyRules";

const b = BEM.b("holidays-popup-message");

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;

const Buttons = styled.div`
  margin-top: 67px;
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  margin-top: 19px;
  margin-bottom: 26px;
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: var(--shapes-border-radius-default);
  background-color: ${(props) => props.theme.colors.rowBackground};
`;

const Image = styled.div`
  margin-inline-end: 15px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: var(--colors-surface-600);
`;

const Text = styled.div`
  line-height: 24px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-text4);
`;

const CheckboxWrapper = styled.div`
  margin-top: 18px;
`;

const ColumnsSelector = styled.div`
  .ui-select__input {
    background-color: var(--colors-surface-0);
    border: solid 1px #a1b2cf;
  }
`;

export type AddLock = {
  payGroupUuid: string;
  startDate: string;
  endDate: string;
  createDigitalSignatures: boolean;
  selectedColumns: string;
};

type NewPreparationPopupMessageGroup = {
  /** uuid */
  value: string;
  label: string;
  nextStartDate: string;
  nextEndDate: string;
};

interface NewPreparationPopupMessageProps extends WithTranslation {
  onClose: () => void;
  onYes: (data: AddLock) => void;
}

interface NewPreparationPopupMessageState {
  loading: boolean;
  groups: NewPreparationPopupMessageGroup[];
  payGroupUuid: string | null;
  createDigitalSignatures: boolean;
  from: moment.Moment;
  to: moment.Moment;
  selectedColumns: string[];
  isNightReducedHoursColumnAllowed: boolean;
}

class NewPreparationPopupMessage extends Component<NewPreparationPopupMessageProps, NewPreparationPopupMessageState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: NewPreparationPopupMessageProps) {
    super(props);

    this.state = {
      loading: false,
      groups: [],
      payGroupUuid: null,
      createDigitalSignatures: false,
      from: moment(),
      to: moment().clone().add(1, "day"),
      selectedColumns: getSelectedColumns(
        "date,punches,worked_hours,break_hours,night_hours,holiday,observation",
        `lockPeriodPopup_${ReportType.detailed}`,
      ).split(","),
      isNightReducedHoursColumnAllowed: false,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    const company = await this.context.getCompany();
    if (!company) {
      return;
    }

    const { business_rules } = await getCompanyRules();

    const nightReducedHoursPolicyRule = business_rules.find(
      (br) => br.name === CompanyRuleNames.ALLOW_NIGHT_REDUCED_HOURS,
    );

    const groupsListResp = await getLockGroupsList({ companyUuid: company.uuid });
    const groupsList = groupsListResp.content || [];

    this.setState({
      isNightReducedHoursColumnAllowed: !!nightReducedHoursPolicyRule?.value,
      groups: groupsList
        .filter((a) => ![PayrollGroupStatus.deactivated, PayrollGroupStatus.deactivating].includes(a.status))
        .map((group) => ({
          value: group.uuid,
          label: group.name === "default" ? t(`${TranslationNamespaces.common}|default`) : group.name,
          nextStartDate: group.nextStartDate,
          nextEndDate: group.nextEndDate,
        })),
    });
  }

  getColumnsSelectorOptions = () => {
    const { t } = this.props;
    const { isNightReducedHoursColumnAllowed } = this.state;

    const nightReducedHoursColumn = isNightReducedHoursColumnAllowed
      ? [
          {
            value: "night_reduced_hours",
            label: t(`${TranslationNamespaces.reportsPage}|ns-col-reduced-hours`),
          },
        ]
      : [];

    const result = [
      { value: "date", label: t(`${TranslationNamespaces.reportsPage}|Date`), disabled: true },
      { value: "schedule", label: t(`${TranslationNamespaces.reportsPage}|Schedule`) },
      { value: "business_rules_group", label: t(`${TranslationNamespaces.reportsPage}|Business Rules Group`) },
      { value: "punches", label: t(`${TranslationNamespaces.reportsPage}|Punches`), disabled: true },
      { value: "planned_hours", label: t(`${TranslationNamespaces.reportsPage}|Planned Hours`) },
      { value: "worked_hours", label: t(`${TranslationNamespaces.reportsPage}|Worked Hours`) },
      { value: "break_hours", label: t(`${TranslationNamespaces.reportsPage}|Break hours`) },
      { value: "night_hours", label: t(`${TranslationNamespaces.reportsPage}|nightShiftMinutes`) },
      ...nightReducedHoursColumn,
      { value: "late_entry_hours", label: t(`${TranslationNamespaces.reportsPage}|Late Arrival`) },
      { value: "early_leave_hours", label: t(`${TranslationNamespaces.reportsPage}|Late Leave`) },
      // double check if works
      { value: "extra_hours_phases", label: t(`${TranslationNamespaces.reportsPage}|HE Phases`) },
      { value: "extra_hours", label: t(`${TranslationNamespaces.reportsPage}|Extra Hours`) },
      // double check if works
      { value: "hours_bank_phases", label: t(`${TranslationNamespaces.reportsPage}|HB Phases`) },
      { value: "hours_bank", label: t(`${TranslationNamespaces.reportsPage}|Hours Bank`) },
      { value: "missed_hours", label: t(`${TranslationNamespaces.reportsPage}|Missed Minutes`) },
      { value: "cumulative_hours_bank", label: t(`${TranslationNamespaces.reportsPage}|Accumulated Hours Bank`) },
      {
        value: "cross_shifts_interval_diff_hours",
        label: t(`${TranslationNamespaces.reportsPage}|Cross Shift Interval`),
      },
      { value: "holiday", label: t(`${TranslationNamespaces.reportsPage}|Holiday`) },
      { value: "observation", label: t(`${TranslationNamespaces.reportsPage}|OBS`) },
      { value: "on_call_hours", label: t(`${TranslationNamespaces.reportsPage}|On Call Minutes`) },
      { value: "on_call_activated_hours", label: t(`${TranslationNamespaces.reportsPage}|On Call Activated Minutes`) },
      // double check if it works
      { value: "on_call_reducing_activated_hours", label: t("On Call Reducing Activated Minutes") },
      { value: "debit_hours", label: t(`${TranslationNamespaces.reportsPage}|Debit Minutes`) },
    ];

    return result;
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem(`customColumns_lockPeriodPopup_${ReportType.detailed}`, selectedColumns.join());
    }

    this.setState({ selectedColumns });
  };

  closePopup = () => {
    this.props.onClose();
  };

  onCancel = () => {
    this.closePopup();
  };

  onYes = () => {
    const { payGroupUuid, from, to, createDigitalSignatures, selectedColumns } = this.state;

    this.setState({ loading: true }, () => {
      if (payGroupUuid) {
        this.props.onYes({
          payGroupUuid,
          startDate: from.format("YYYY-MM-DD"),
          endDate: to.format("YYYY-MM-DD"),
          createDigitalSignatures,
          selectedColumns: createDigitalSignatures ? selectedColumns.join(",") : "",
        });
      }
    });
  };

  render() {
    const { to, from, payGroupUuid, createDigitalSignatures, groups, loading, selectedColumns } = this.state;
    const { t } = this.props;

    return (
      <div className={b()}>
        <div className={b("title")}>{t("New preparation")}</div>
        <Row>
          <Image>{images.lockedPadlock()}</Image>

          <Text>{t("After Lock a Pay Period it will not be possible to add records on these dates.")}</Text>
        </Row>

        {!!groups.length && (
          <FieldWrapper fieldName={t("Select Pay Day Group")} width="100%">
            <Select
              modifiers={{ field: true }}
              value={payGroupUuid || ""}
              onChange={(val) => {
                const group = groups.filter((v) => v.value === val)[0];

                this.setState({
                  payGroupUuid: val,
                  from: moment(group.nextStartDate, "YYYY-MM-DD"),
                  to: moment(group.nextEndDate, "YYYY-MM-DD"),
                });
              }}
              options={groups}
            />
          </FieldWrapper>
        )}

        <div className={b("from-to")}>
          <div className={b("from")}>
            <div className={b("label")}>{t("From")}</div>
            <SingleDatePickerControl value={from} disabled isOutsideRange={() => false} />
          </div>
          <div className={b("to")}>
            <div className={b("label")}>{t("To")}</div>
            <SingleDatePickerControl value={to} disabled isOutsideRange={() => false} />
          </div>
        </div>

        <CheckboxWrapper>
          <CheckboxControl
            label={t("Request a Digital Signature")}
            checked={createDigitalSignatures}
            onChange={(value) => {
              this.setState({ createDigitalSignatures: value });
            }}
          />
        </CheckboxWrapper>

        {createDigitalSignatures && (
          <FieldWrapper fieldName={t("Include columns")} width="100%">
            <ColumnsSelector>
              <MultiWithSearch
                lightUI
                placeholder={t(`${TranslationNamespaces.reportsPage}|Select report columns`)}
                value={selectedColumns}
                onChange={this.onColumnsChange}
                options={this.getColumnsSelectorOptions()}
              />
            </ColumnsSelector>
          </FieldWrapper>
        )}

        <Buttons>
          <Button
            value={t(`${TranslationNamespaces.common}|Cancel`)}
            state={ButtonState.secondary}
            onClick={this.onCancel}
          />
          <Spacer />
          <Button
            value={t(`${TranslationNamespaces.common}|Create`)}
            state={ButtonState.primary}
            disabled={!payGroupUuid}
            loading={loading}
            onClick={this.onYes}
          />
        </Buttons>
      </div>
    );
  }
}

export default withTranslation([TranslationNamespaces.payment, TranslationNamespaces.reportsPage])(
  NewPreparationPopupMessage,
);
