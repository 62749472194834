import styled from "styled-components";

export const ClearRow = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  cursor: pointer;
  margin-inline-start: -20px;
  align-items: center;
  background-position: 0 center;
  background-repeat: no-repeat;
  visibility: hidden;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12Z' fill='%23DE4A53'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.087 5.478a.522.522 0 1 1 0 1.044H3.391a.522.522 0 0 1 0-1.044h4.696Z' fill='%23fff'/%3E%3C/svg%3E");
`;
