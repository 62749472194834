import { UserProfile } from "types/models/userProfile";
import { GlobalStoreCompany } from "types/models/company";
import { NotificationType } from "types/common";
import { TFunction } from "i18next";
import Button, { ButtonState } from "components/controls/StyledButton";
import { stylesheet } from "astroturf";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useTranslation } from "react-i18next";
import * as images from "components/svg-images";
import { HeaderAction } from "components/styled/Page";
import { PermissionRoleName } from "types/models/permissions";
import { getBRGroups, getLockGroupsList, getSchedules } from "utils/api/company";
// eslint-disable-next-line import/no-extraneous-dependencies
import FlatfileImporter from "@flatfile/adapter";
import { useContext, useEffect, useState } from "react";
import { getCompanyUsersExternalIdList } from "utils/api/userProfile";
import { checkHCMIntegration } from "utils/api/integration";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { GlobalContext } from "context/GlobalContextProvider";
import { AvailableLocales, getLocale, translateEmployeeTerm } from "utils/translationHelpers";
import {
  EmployeeFields,
  employeeRecordValidationHook,
  getEmployeeFields,
  getFlatfileImportI18nOverrides,
} from "./employee.schema";
import { listHolidaysGroups } from "../Holidays/holidayApiUtils";
import CONFIG from "../../config";
import ModalDialog from "../UI/ModalDialog";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as HelpIcon } from "./icons/help.svg";
import { ReactComponent as CreateIcon } from "./icons/create-import.svg";
import { ReactComponent as UpdateIcon } from "./icons/update-import.svg";
import { ReactComponent as CrossIcon } from "./icons/close.svg";
import { BillingService } from "../Billing/BillingService";
import { SubscriptionContext, SubscriptionContextValue } from "../Billing/context/subscription-context.provider";

enum ImportFileActionType {
  ImportEmployees = "importEmployees",
  UpdateEmployees = "updateEmployees",
}

const styles = stylesheet`
  .UploadIcon {
    margin-inline-end: 8px;
    path {
      stroke: var(--colors-surface-600);
    }
  }
  .ImportModal {
    .Header {
      display: flex;
      align-items: center;
      .Title {
        font-size: 25px;
        line-height: 25px;
        color: var(--colors-surface-900-p);
        padding-inline-end: 26px;
      }
      .HeaderLink {
        color: var(--colors-primary-500-p);
        font-size: 15px;
        line-height: 15px;
        margin-inline-start: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: var(--typography-font-weight-medium);
        .Icon {
          margin-inline-end: 10px;
        }
        &:hover span {
          text-decoration: underline;
        }
      }
      .closeIcon {
        margin-inline-start: auto;
        cursor: pointer;
        width: 14px;
        height: 14px;
        display: inline-block;
        cursor: pointer;
        padding: 0px;
        position: absolute;
        top: 16px;
        inset-inline-end: 16px;
        color: var(--colors-surface-900-p);
        background-color: transparent;
        border: transparent;
      }
    }
    .Content {
      margin-top: 40px;
      border: 1px solid var(--colors-surface-150);
      border-radius: 8px;
      display: flex;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      .Action {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        color: var(--colors-surface-900-p);
        padding: 32px;
        width: 100%;
        span {
          margin-inline-start: 16px;
        }
        &:hover {
          background: var(--colors-surface-50);
        }
      }
      .Divider {
        content: '';
        display: block;
        height: 112px;
        width: 0;
        margin: 0 16px;
        border-inline-start: 1px solid var(--colors-surface-150);
      }
    }
  }
`;

async function flatfileInit(
  profile: UserProfile,
  company: GlobalStoreCompany,
  isUpdate: boolean,
  cb: (notification: { message: string; type: NotificationType }) => void,
  t: TFunction,
  subscriptionContext: SubscriptionContextValue,
) {
  const secondaryButton = {
    backgroundColor: "white",
    color: "#1e97f7",
    border: "1px solid #1e97f7",
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "500",
    borderRadius: "4px",
    padding: "11px 36px",
    boxShadow: "none",
    ":hover": {
      backgroundColor: "white",
      border: "1px solid #1e97f7",
    },
  };
  const primaryButton = {
    backgroundColor: "#1e97f7",
    color: "#fff",
    border: "none",
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "500",
    borderRadius: "4px",
    padding: "11px 36px",
    boxShadow: "none",
    ":hover": {
      backgroundColor: "#1e97f7",
      border: "none",
    },
  };
  const disabledButton = {
    backgroundColor: "#F7F9FC",
    color: "#B4C4D1",
    border: "none",
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: "500",
    borderRadius: "4px",
    padding: "11px 36px",
    boxShadow: "none",
    ":hover": {
      backgroundColor: "#F7F9FC",
      border: "none",
    },
  };

  const allowedProducts = BillingService.getAllowedItems();

  const importer = new FlatfileImporter("ba61bd82-094b-4f87-b688-2c388c3003d2", {
    type: isUpdate ? ImportFileActionType.UpdateEmployees : ImportFileActionType.ImportEmployees,
    fields: getEmployeeFields(isUpdate, t, allowedProducts),
    i18nOverrides: getFlatfileImportI18nOverrides(t),
    managed: true,
    autoDetectHeaders: true,
    devMode: CONFIG.appEnv !== "production",
    webhookUrl:
      CONFIG.appEnv === "production"
        ? `https://api.day.io/v2/public/actions/webhook/v2`
        : `https://stg-api.day.io/v2/public/actions/webhook/v2`,
    theme: {
      global: {
        backgroundColor: "#ffffff",
        textColor: "#434E6C",
        primaryTextColor: "#434E6C",
        secondaryTextColor: "#8093AC",
        fontFamily: "Circular",
        successColor: "#00A15C",
        warningColor: "#EBA844",
        borderRadius: "8px",
      },
      buttons: {
        primary: primaryButton,
        secondary: secondaryButton,
        success: primaryButton,
        tertiary: secondaryButton,
        headerMatchYes: primaryButton,
        headerMatchNo: secondaryButton,
        columnMatchConfirmWithDupes: disabledButton,
        columnMatchConfirm: primaryButton,
        columnMatchIgnore: secondaryButton,
        columnMatchEdit: secondaryButton,
      },
      header: {
        title: {
          fontSize: "25px",
          lineHeight: "25px",
          fontWeight: "450",
        },
        closeButton: {
          color: "#434E6C",
          ":hover": {
            color: "#434E6C",
          },
        },
      },
      dropzone: {
        root: {
          padding: "0px 40px",
        },
        content: {
          border: "1px solid #DDE5EE",
          padding: "48px",
          borderRadius: "8px",
        },
        fileTypes: {
          fontSize: "15px",
          margin: "0 0 0 25%",
          borderLeft: "1px solid #DDE5EE",
        },
      },
      headerMatch: {
        root: {
          fontSize: "15px",
        },
        content: {
          border: "1px solid #1E97F7",
          borderRadius: "8px",
          padding: "32px 24px",
          fontSize: "20px",
          color: "#434E6C",
        },
        icon: {
          fill: "#707D9E",
        },
        table: {
          th: {
            backgroundColor: "#F7F9FC",
            color: "#434E6C",
            borderColor: "#DDE5EE",
            fontSize: "12px",
            fontWeight: "500",
          },
          td: {
            color: "black",
            ":focus": {
              borderColor: "#1E97F7",
            },
          },
        },
      },
      manualInput: {
        root: {
          padding: "40px 40px 0",
        },
        title: {
          margin: "0 0 24px",
          padding: "0px",
          fontSize: "20px",
          color: "#525F7F",
          fontWeight: "450",
        },
        table: {
          th: {
            backgroundColor: "#F7F9FC",
            color: "#434E6C",
            borderColor: "#DDE5EE",
            fontSize: "12px",
            fontWeight: "500",
          },
          td: {
            color: "black",
            ":focus": {
              borderColor: "#1E97F7",
            },
          },
        },
      },
      columnMatch: {
        root: {},
        content: {
          color: "#434E6C",
          padding: "24px",
          borderRadius: "8px",
          boxShadow: "0px 1px 6px rgba(67, 78, 108, 0.1)",
          margin: "0 0 12px",
        },
        rule: {
          color: "#434E6C",
          margin: "0 0 17px",
        },
        autoMatchRule: {
          description: {
            color: "#8093AC",
            fontSize: "13px",
            lineHeight: "13px",
            fontWeight: "450",
            padding: "4px 0 0 0",
          },
          icon: {
            fill: "#00A15C",
          },
        },
      },
      dialog: {
        root: {
          borderRadius: "8px",
          border: "none",
          boxShadow: "0px 0px 57px rgba(67, 78, 108, 0.2)",
          fontSize: "15px",
          padding: "24px",
          fontWeight: "450",
        },
        content: {
          fontSize: "20px",
          border: "none",
          fontWeight: "450",
          padding: "8px",
        },
        footer: {
          margin: "auto 0 0 0",
          border: "none",
          padding: "8px",
        },
      },
    },
  });
  const groups = await Promise.all([
    getBRGroups({ companyUuid: company.uuid }),
    listHolidaysGroups({ companyUuid: company.uuid }),
    getLockGroupsList({ companyUuid: company.uuid }),
    getSchedules({ companyUuid: company.uuid, params: { perPage: 1000 } }),
    getCompanyUsersExternalIdList(),
    subscriptionContext.getAvailableSeats(),
  ]);
  const [businessRuleGroups, holidayGroups, payrollGroups, schedules, externalIds, usedSeats] = groups;
  importer.setCustomer({
    userId: profile.uuid,
    name: profile.full_name,
    email: profile.email,
    companyName: company.name,
    companyId: company.uuid,
  });

  importer.registerRecordHook((record: EmployeeFields, index: number) =>
    employeeRecordValidationHook(
      record,
      isUpdate,
      (businessRuleGroups as { content: { uuid: string; name: string }[] }).content,
      (holidayGroups as { content: { uuid: string; name: string }[] }).content,
      (payrollGroups as { content: { uuid: string; name: string }[] }).content,
      (schedules as { content: { uuid: string; name: string }[] }).content,
      externalIds,
      t,
      index,
      usedSeats!,
      allowedProducts,
    ),
  );

  importer.requestDataFromUser().then((results) => {
    importer.displaySuccess(t("import_success_message"));
    cb({ message: t("The file was successfully imported."), type: NotificationType.success });
  });
}

type ImportEmployeeProps = {
  notificationCb: (notification: { message: string; type: NotificationType }) => void;
};

export const ImportEmployee = (props: ImportEmployeeProps) => {
  const subscriptionContext = useContext(SubscriptionContext);
  const { t } = useTranslation(TranslationNamespaces.dataManagement);
  const context = useContext(GlobalContext);
  const [importModalOpen, setImportModalOpen] = useState(false);
  useEffect(() => {
    void subscriptionContext.init();
  }, []);

  const hasAdminRole = window.global_store?.profile?.permission_roles?.some((r) =>
    [PermissionRoleName.admin, PermissionRoleName.owner, PermissionRoleName.hr].includes(r.name),
  );

  const [availabilityImportCheckInit, loading, hideImport] = useAsyncCallback(async () => {
    if (!hasAdminRole) return true;
    const company = await context.getCompany();
    let result = true;
    try {
      result = await checkHCMIntegration(company.uuid);
    } catch (e) {
      result = true;
    }
    return result;
  }, []);

  useEffect(() => {
    void availabilityImportCheckInit();
  }, [availabilityImportCheckInit]);

  if (loading || hideImport) {
    return <></>;
  }

  const locale = getLocale();
  const fileTemplateUrl =
    locale === AvailableLocales.pt ? "/templates/file-template-BR.xlsx" : "/templates/file-template.xlsx";
  const helpCenterUrl =
    locale === AvailableLocales.pt ? "https://support.day.io/hc/pt-br/articles/14824873454615" : undefined; // todo after adding help guides just use link from localize as href without this code

  return (
    <>
      <HeaderAction style={{ minWidth: "auto" }}>
        <Button
          state={ButtonState.outline}
          style={{ padding: "0 16px 0 12px", color: "var(--colors-surface-600)" }}
          onClick={async () => {
            const validSeats = await subscriptionContext.checkAvailableSeats(false, {});
            if (validSeats) setImportModalOpen(true);
          }}
          value={
            <>
              <i className={styles.UploadIcon}>{images.upload}</i>
              {t("Import")}
            </>
          }
        />
      </HeaderAction>
      <ModalDialog
        onClose={() => setImportModalOpen(false)}
        isOpen={importModalOpen}
        width={900}
        preventOutsideClose
        padding={40}
        top="80px"
      >
        <div className={styles.ImportModal}>
          <section className={styles.Header}>
            <div className={styles.Title}>
              {translateEmployeeTerm(
                t,
                TranslationNamespaces.dataManagement,
                "custom-import-employees",
                "Import employees",
              )}
            </div>
            <a className={styles.HeaderLink} href={fileTemplateUrl} download>
              <i className={styles.Icon}>
                <DownloadIcon />
              </i>
              <span>{t("download_sample_CTA")}</span>
            </a>
            {helpCenterUrl ? (
              <a className={styles.HeaderLink} href={helpCenterUrl} rel="noreferrer" target="_blank">
                <i className={styles.Icon}>
                  <HelpIcon />
                </i>
                <span>{t("need_help_CTA")}</span>
              </a>
            ) : null}
            <div className={styles.closeIcon} onClick={() => setImportModalOpen(false)}>
              <CrossIcon />
            </div>
          </section>
          <section className={styles.Content}>
            <div
              className={styles.Action}
              onClick={() => {
                flatfileInit(
                  window.global_store.profile,
                  window.global_store.company as GlobalStoreCompany,
                  false,
                  (notification) => props.notificationCb(notification),
                  t,
                  subscriptionContext,
                );
              }}
            >
              <CreateIcon />
              <span>
                {translateEmployeeTerm(
                  t,
                  TranslationNamespaces.dataManagement,
                  "custom-import_new_employees_CTA",
                  "import_new_employees_CTA",
                )}
              </span>
            </div>
            <div className={styles.Divider} />
            <div
              className={styles.Action}
              onClick={() => {
                flatfileInit(
                  window.global_store.profile,
                  window.global_store.company as GlobalStoreCompany,
                  true,
                  (notification) => props.notificationCb(notification),
                  t,
                  subscriptionContext,
                );
              }}
            >
              <UpdateIcon />
              <span>
                {translateEmployeeTerm(
                  t,
                  TranslationNamespaces.dataManagement,
                  "custom-update_employees_CTA",
                  "update_employees_CTA",
                )}
              </span>
            </div>
          </section>
        </div>
      </ModalDialog>
    </>
  );
};
