import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useEffect, useMemo, useState } from "react";
import { normalizeString } from "components/views/Select";
import { Location } from "types/models/location";
import { ActivityEditViewProps } from "components/PunchNow/views/ActivityEditView";
import { StoreApi, UseBoundStore } from "zustand/esm";
import { SearchBar } from "./SearchBar";
import { OverlayDropdown } from "./OverlayDropdown";
import { NothingFound } from "./NothingFound";
import { CancelBtn, CheckIcon } from "./TimerButtons";
import { TrackTimeStore } from "../TrackTime.store";
import { filterLocationsBasedOnProject } from "../time-tracker.utils";

const LocationRow = styled.div`
  display: flex;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  &:hover {
    background: var(--colors-surface-50);
  }
  .Icon {
    opacity: 0;
  }
  &.active .LocationWrapper {
    .Icon {
      opacity: 1;
      svg path {
        fill: var(--colors-primary-500-p);
      }
    }
    .Title {
      color: var(--colors-primary-500-p);
    }
  }
  .LocationWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;
    border-bottom: 1px solid var(--colors-surface-150);
    width: 100%;
    padding-inline-end: 30px;
    position: relative;
    &:last-of-type {
      border: none;
    }
    .Icon {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .Title {
      align-self: stretch;
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-900-p);
      line-height: 130%;
    }
    .Description {
      align-self: stretch;
      font-size: 12px;
      line-height: 120%;
      color: var(--colors-surface-700);
    }
  }
`;

export const LocationSelect = ({
  store,
  setLocation,
  location,
  setShowLocationSelect,
}: {
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
  location: Location | undefined;
  setLocation: (val: Location | undefined) => void;
  setShowLocationSelect: (val: boolean) => void;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const [locationArr, setLocationArr] = useState<ActivityEditViewProps["locations"]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { activity, setShowTaskDetails, projects, locations } = store();

  useEffect(() => {
    const locs = filterLocationsBasedOnProject(projects, locations, activity?.project?.uuid);
    setLocationArr(locs);
  }, [activity, projects, locations]);

  const foundLocations = useMemo(() => {
    const str = normalizeString(searchValue);
    if (str === "") return locationArr;
    return locationArr.filter((l) => l.name.includes(str) || l.address.includes(str));
  }, [searchValue, locationArr]);

  const selectLocation = (l: Location) => {
    setLocation(l);
    setShowLocationSelect(false);
    setShowTaskDetails(true);
  };

  return (
    <OverlayDropdown
      header={<SearchBar placeholder={t("Search tasks")} searchValue={searchValue} setSearchValue={setSearchValue} />}
      content={
        foundLocations.length > 0 ? (
          foundLocations.map((l) => (
            <LocationRow
              key={l.uuid}
              onClick={() => selectLocation(l)}
              className={`${l.uuid === location?.uuid ? "active" : ""}`}
            >
              <div className="LocationWrapper">
                <div className="Title">{l.name}</div>
                <div className="Description">{l.address}</div>
                <div className="Icon">
                  <CheckIcon />
                </div>
              </div>
            </LocationRow>
          ))
        ) : (
          <NothingFound title={t("No items")} description={t("No locations found")} />
        )
      }
      footer={
        <div className="Footer">
          <CancelBtn
            style={{ marginInlineStart: "auto" }}
            onClick={() => {
              setSearchValue("");
              setShowLocationSelect(false);
              setShowTaskDetails(true);
            }}
          />
        </div>
      }
    />
  );
};
