import { FilteredEmployeeProfile } from "types/models/userProfile";
import { strIncludesCheck } from "utils/common";

interface IGroup {
  name?: string;
  title?: string;
}

interface IFilter {
  filterTerm: string;
  group: IGroup;
}

export const hasTerm = ({ filterTerm, group }: IFilter): boolean => {
  if (!filterTerm) {
    return true;
  }

  const isNameMatch = (group.name && strIncludesCheck(group.name, filterTerm)) || false;
  const isTitleMatch = (group.title && strIncludesCheck(group.title, filterTerm)) || false;

  return isNameMatch || isTitleMatch;
};

interface IFilterEmployees {
  companyEmployees: FilteredEmployeeProfile[];
  skipEmployees?: FilteredEmployeeProfile[];
  term?: string;
  filter?: (filter: IFilter) => boolean;
}

export const filterEmployees = ({
  companyEmployees,
  skipEmployees = [],
  term = "",
  filter = hasTerm,
}: IFilterEmployees) => {
  const subsidiaries: Record<string, FilteredEmployeeProfile[]> = {};
  const departments: Record<string, FilteredEmployeeProfile[]> = {};
  const teams: Record<string, FilteredEmployeeProfile[]> = {};
  const positions: Record<string, FilteredEmployeeProfile[]> = {};
  const businessRulesGroups: Record<string, FilteredEmployeeProfile[]> = {};
  const payrollGroups: Record<string, FilteredEmployeeProfile[]> = {};

  const allEmployees = [];
  const rawEmployees = companyEmployees.toSorted((a, b) => a.fullName.localeCompare(b.fullName));

  for (const e of rawEmployees) {
    if (skipEmployees.find((se) => se.uuid === e.uuid)) {
      continue;
    }

    allEmployees.push(e);

    if (e.subsidiary) {
      if (filter({ filterTerm: term, group: e.subsidiary })) {
        if (subsidiaries[e.subsidiary.uuid]) {
          subsidiaries[e.subsidiary.uuid].push(e);
        } else {
          subsidiaries[e.subsidiary.uuid] = [e];
        }
      }
    }

    if (e.department) {
      if (filter({ filterTerm: term, group: e.department })) {
        if (departments[e.department.uuid]) {
          departments[e.department.uuid].push(e);
        } else {
          departments[e.department.uuid] = [e];
        }
      }
    }

    if (e?.businessRulesGroups?.length) {
      if (filter({ filterTerm: term, group: e.businessRulesGroups[0] })) {
        if (businessRulesGroups[e.businessRulesGroups[0].uuid]) {
          businessRulesGroups[e.businessRulesGroups[0].uuid].push(e);
        } else {
          businessRulesGroups[e.businessRulesGroups[0].uuid] = [e];
        }
      }
    }

    if (e?.payrollGroups?.length) {
      if (filter({ filterTerm: term, group: e.payrollGroups[0] })) {
        if (payrollGroups[e.payrollGroups[0].uuid]) {
          payrollGroups[e.payrollGroups[0].uuid].push(e);
        } else {
          payrollGroups[e.payrollGroups[0].uuid] = [e];
        }
      }
    }

    if (e.position) {
      if (filter({ filterTerm: term, group: e.position })) {
        if (positions[e.position.title]) {
          positions[e.position.title].push(e);
        } else {
          positions[e.position.title] = [e];
        }
      }
    }

    if (e.team) {
      if (filter({ filterTerm: term, group: e.team })) {
        if (teams[e.team.uuid]) {
          teams[e.team.uuid].push(e);
        } else {
          teams[e.team.uuid] = [e];
        }
      }
    }
  }

  return {
    allEmployees,
    subsidiaries,
    departments,
    teams,
    positions,
    payrollGroups,
    businessRulesGroups,
  };
};
