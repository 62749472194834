import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import BEM from "utils/BEM";
import { getDateWithTZ } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { EmployeePunchesTableData, Punch } from "types/models/punches";
import { iCellInfo } from "utils/tableHelpers";
import { iColumn } from "components/TableCommon";
import PunchDetails from "components/Punches/PunchDetails";
import TablePage from "components/TablePage";
import SidePopupOverlay, { SidePopupOverlayStyle } from "components/UI/SidePopupOverlay";
import "styles/table-common.scss";
import PaginationNew from "components/PaginationNew";

const b = BEM.b("table-common");

interface EmployeePunchesTableProps extends WithTranslation {
  punches: Punch[];
  page: number;
  perPage: number;
  totalRecords: number;
  onPaginationChange: (perPage: number, page: number) => void;
}

interface EmployeePunchesTableState {
  selectedPunch: EmployeePunchesTableData | null;
}

class EmployeePunchesTable extends Component<EmployeePunchesTableProps, EmployeePunchesTableState> {
  constructor(props: EmployeePunchesTableProps) {
    super(props);

    this.state = {
      selectedPunch: null,
    };
  }

  openPunchDetails = (punch: EmployeePunchesTableData) => {
    this.setState({ selectedPunch: punch });
  };

  getTableData = (): EmployeePunchesTableData[] =>
    this.props.punches.map((e) => ({
      id: e.id,
      type: e.punch_type,
      punch: e.is_manual ? "manual_punch" : "regular_punch",
      when: { time: e.device_datetime, timezone: e.time_zone },
      validation: e.verified_by_type,
      status: e.status,
      options: e.id,
      employee: e.employee,
    }));

  getTableColumns = (): iColumn<EmployeePunchesTableData>[] => {
    const { t } = this.props;

    return [
      {
        label: t("Id"),
        accessor: "id",
        minWidth: 120,
      },
      {
        label: t("Type"),
        accessor: "type",
        Cell: (row: iCellInfo<EmployeePunchesTableData, EmployeePunchesTableData["type"]>) => (
          <span>{t(row.value)}</span>
        ),
        minWidth: 160,
      },
      {
        label: t("Punch"),
        accessor: "punch",
        Cell: (row: iCellInfo<EmployeePunchesTableData, EmployeePunchesTableData["punch"]>) => (
          <span>{t(row.value)}</span>
        ),
        minWidth: 160,
      },
      {
        label: t("When"),
        accessor: "when",
        Cell: (row: iCellInfo<EmployeePunchesTableData, EmployeePunchesTableData["when"]>) => (
          <span>{row.value && getDateWithTZ(row.value.time, row.value.timezone).format("HH:mm ddd DD/MM/YYYY")}</span>
        ),
        minWidth: 243,
      },
      {
        label: t("Validation"),
        accessor: "validation",
        Cell: (row: iCellInfo<EmployeePunchesTableData, EmployeePunchesTableData["validation"]>) => (
          <span>{t(row.value)}</span>
        ),
        minWidth: 160,
      },
      {
        label: t("Status"),
        accessor: "status",
        Cell: (row: iCellInfo<EmployeePunchesTableData, EmployeePunchesTableData["status"]>) => (
          <span className={b("status-button", [row.value])}>{t(row.value)}</span>
        ),
        minWidth: 120,
        align: "center",
      },
    ];
  };

  onValidatePunch = () => {
    this.setState({ selectedPunch: null });
  };

  onApprovePunchPopup = () => {
    this.setState({ selectedPunch: null });
  };

  onDeclinePunchPopup = () => {
    this.setState({ selectedPunch: null });
  };

  render() {
    const { t, page, perPage, totalRecords, onPaginationChange } = this.props;
    const { selectedPunch } = this.state;
    const data = this.getTableData();
    const columns = this.getTableColumns();

    return (
      <div className={b()}>
        <TablePage
          rows={data}
          columns={columns}
          className="employee-table"
          showPagination
          PaginationComponent={(): JSX.Element => (
            <PaginationNew
              unlimited
              totalRecords={totalRecords}
              pageLimit={perPage}
              currentPage={page}
              onPageLimitChange={(pageLimit, currentPage) => onPaginationChange(pageLimit, currentPage)}
              onPageChange={(currentPage) => {
                onPaginationChange(perPage, currentPage);
              }}
            />
          )}
          getTrProps={(_, rowInfo) => ({
            style: {
              height: "52px",
            },
            onClick: () => {
              if (rowInfo?.row) {
                this.openPunchDetails(rowInfo.original);
              }
            },
          })}
        />

        <SidePopupOverlay
          contentOverflow
          header={t("Punch Details")}
          isOpen={!!selectedPunch}
          headerStyle={selectedPunch ? (selectedPunch.status as SidePopupOverlayStyle) : undefined}
          onClose={() => this.setState({ selectedPunch: null })}
        >
          <PunchDetails
            employeePage
            punchId={selectedPunch ? selectedPunch.id : null}
            employeeUuid={selectedPunch ? selectedPunch.employee.uuid : null}
            onApprovePunch={this.onApprovePunchPopup}
            onValidatePunch={() => {}}
            onDeclinePunch={this.onDeclinePunchPopup}
          />
        </SidePopupOverlay>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.employeesPage)(EmployeePunchesTable);
