import styled from "styled-components";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import ClickOutside from "react-click-outside";
import { ClickOutsideType } from "types/common";
import TimeControl from "components/controls/TimeControl";
import IconButton from "components/controls/IconButton/IconButton";
import CheckIcon from "@iconscout/react-unicons/icons/uil-check";
import TrashIcon from "@iconscout/react-unicons/icons/uil-trash-alt";
import { useState } from "react";
import { hrsMinstoMins, minsToHrsMins } from "utils/common";

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  box-shadow: 0px 10px 30px rgba(129, 147, 171, 0.2);
  border-radius: var(--shapes-border-radius-default);
  border: 1px solid var(--colors-surface-150);
  background-color: var(--colors-surface-0);
  position: absolute;
  top: -82px;
  inset-inline-start: -220px;
  z-index: 1;
  align-items: center;
  padding: 12px;
  padding-inline-start: 24px;
`;
const Label = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-600, #8093ac);
  white-space: nowrap;
`;
const Splitter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Divider = styled.div`
  width: 1px;
  height: 24px;
  background: var(--colors-surface-150, #dde5ee);
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

type AdditionalLimitsOverlayProps = {
  onApply: (time: number) => void;
  onDelete: () => void;
  onClose: () => void;
  additionalTime?: number;
};

const AdditionalLimitsOverlay = ({ onApply, onDelete, onClose, additionalTime }: AdditionalLimitsOverlayProps) => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const [time, setTime] = useState(additionalTime);

  return (
    <ClickOutside<ClickOutsideType> onClickOutside={() => onClose()}>
      <Wrapper>
        <Splitter>
          <Label>{t("limits-after")}</Label>
          <TimeControl
            style={{ width: "96px" }}
            value={time ? minsToHrsMins(time) : "00:00"}
            onTimeChange={(val: { value: string }) => {
              setTime(val?.value ? hrsMinstoMins(val.value) : 0);
            }}
            placeholder="00:00"
          />
          <Label>{t("Worked Hours")}</Label>
        </Splitter>
        <Actions>
          <Divider />
          <Buttons>
            <IconButton
              disabled={Boolean(!additionalTime)}
              tooltipText={t(`${TranslationNamespaces.common}|Delete`)}
              id="additional-limits-delete"
              onClick={onDelete}
            >
              <TrashIcon width={20} />
            </IconButton>
            <IconButton
              disabled={Boolean(!time)}
              tooltipText={t(`${TranslationNamespaces.common}|Apply`)}
              id="additional-limits-apply"
              onClick={() => {
                if (time) onApply(time);
              }}
            >
              <CheckIcon width={20} />
            </IconButton>
          </Buttons>
        </Actions>
      </Wrapper>
    </ClickOutside>
  );
};

export default AdditionalLimitsOverlay;
