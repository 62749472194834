import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button, { ButtonState, StyledButtonProps } from "components/controls/StyledButton";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { TranslationNamespaces } from "types/translationNamespaces";

const Row = styled.div`
  inset-inline-start: 0;
  inset-inline-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

const ApproveDeclineRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Spacer = styled.div`
  width: 16px;
`;

interface PunchApproveDeclineRowProps {
  buttonProps: Partial<StyledButtonProps>;
  onDeclineClick: () => void;
  onApproveClick: () => void;
  onDeleteClick?: () => void;
}

const PunchApproveDeclineRow = ({
  buttonProps,
  onDeclineClick,
  onApproveClick,
  onDeleteClick,
}: PunchApproveDeclineRowProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  return (
    <Row>
      {(onApproveClick || onDeclineClick) && (
        <ApproveDeclineRow>
          <Button value={t("Decline")} state={ButtonState.cancel} {...buttonProps} onClick={onDeclineClick} />
          <Spacer />
          <Button value={t("Approve")} state={ButtonState.success} {...buttonProps} onClick={onApproveClick} />
        </ApproveDeclineRow>
      )}
      {onDeleteClick && (
        <>
          <br />
          <AddRemoveLink {...buttonProps} label={t("Remove")} remove onClick={onDeleteClick} />
        </>
      )}
    </Row>
  );
};

export default PunchApproveDeclineRow;
