import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { hasEmployeesAccess } from "utils/common";
import FullPage from "components/Layout/FullPage";
import TableFiltersBackButton from "components/UI/TableFiltersBackButton";
import { AggregationGroupType } from "types/models/dashboard";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { HeaderAction } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import PunchesAddPopup from "components/Punches/PunchesAddPopup";
import { AddPunchPunch } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { addPunch } from "utils/apiHelpers";
import PunchSettingsModal from "components/Punches/PunchSettingsModal";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import { updateUrlWithParams } from "./helpers";
import GroupSuperpunchSearch from "./GroupSuperpunchSearch";
import EmployeesSuperpunchTable from "./EmployeesSuperpunchTable";
import * as images from "../svg-images";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FiltersWrapper = styled.div`
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 25px;
  display: flex;
`;

const PunchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: none;
  border: 1px solid #b4c4d1;
  border-radius: var(--shapes-border-radius-default);
  outline: none;
`;

type EmployeesSuperpunchPageProps = {
  groupUuid: string;
  groupType: Exclude<AggregationGroupType, "userProfile" | "company">;
  startDate: string;
  endDate: string;
  searchValue: string;
  previousGroup: string;
};

const EmployeesSuperpunchPage = ({
  groupUuid,
  groupType,
  startDate,
  endDate,
  searchValue,
  previousGroup,
}: EmployeesSuperpunchPageProps) => {
  const { t } = useTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.employeesPage]);
  const history = useHistory();
  const startDateM = moment(startDate);
  const endDateM = moment(endDate);
  const isSupervisor = window.global_store.profile.permission_roles.some(
    (pr) => pr.name === PermissionRoleName.supervisor,
  );
  const availableDaysCount = 60;
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showAddPunchPopup, setShowAddPunchPopup] = useState(false);

  const punchSettingsEnabled = window.global_store.profile?.permissions?.some(
    (r: string) => r === PermissionSectionName.punchSettings,
  );

  /* eslint camelcase: ["warn", { "allow":
    [shift_compilation, shift_id]
  }] */
  const onAddPunch = async (
    punch: AddPunchPunch,
    _: string | undefined,
    event: AddPunchMappedEvent["event"] | undefined,
  ): Promise<void> => {
    try {
      await addPunch({
        body: {
          ...punch,
          shift_compilation: event
            ? {
                shift_id: event.shiftId,
                date: event.date,
                key: event.key,
              }
            : null,
        },
      });
    } finally {
      setShowAddPunchPopup(false);
    }
  };

  return (
    <FullPage
      headerAction={
        <HeaderAction>
          {punchSettingsEnabled && (
            <PunchSettingsButton onClick={() => setShowSettingsPopup(true)}>{images.gearIcon}</PunchSettingsButton>
          )}

          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t(`${TranslationNamespaces.employeesPage}|Add Punches`)}
            onClick={() => setShowAddPunchPopup(true)}
          />
        </HeaderAction>
      }
    >
      <Wrapper>
        <FiltersWrapper>
          {!isSupervisor && (
            <TableFiltersBackButton
              onClick={() => {
                const [previousGroupType, previousGroupUuuid, groupName] = previousGroup.split("_");
                const groupUuidParam = previousGroupUuuid ? `&group_uuid=${previousGroupUuuid}` : "";
                const searchValueParam = groupName ? `&search_value=${groupName}` : "";

                history.push(
                  `/punches?type=group-edit&startDate=${startDate}&endDate=${endDate}&group_type=${
                    previousGroupType || groupType
                  }${groupUuidParam}${searchValueParam}`,
                );
              }}
            />
          )}
          <GroupSuperpunchSearch
            initialSearch={{ id: null, uuid: groupUuid, name: searchValue, type: groupType }}
            startDate={startDateM}
            endDate={endDateM}
          />
          <DateRangePicker
            newOnChangeApproach
            isAdmin={hasEmployeesAccess()}
            onChange={(startDateL, endDateL) => {
              if (startDateL && endDateL) {
                updateUrlWithParams(
                  {
                    type: "group-edit",
                    startDate: startDateL || moment(),
                    endDate: endDateL || moment().add(10, "day"),
                    group_uuid: groupUuid,
                    group_type: groupType,
                    search_value: searchValue,
                  },
                  history,
                );
              }
            }}
            startDate={startDateM}
            endDate={endDateM}
            availableDaysCount={availableDaysCount}
          />
        </FiltersWrapper>
        <div>
          <EmployeesSuperpunchTable
            startDate={startDate}
            endDate={endDate}
            groupType={groupType}
            groupUuid={groupUuid}
            groupName={searchValue}
          />
        </div>

        <>
          {showSettingsPopup && (
            <PunchSettingsModal onClose={() => setShowSettingsPopup(false)} setNotification={() => {}} />
          )}

          <SidePopupOverlay
            width={400}
            header={t("Add a Punch")}
            isOpen={showAddPunchPopup}
            onClose={() => setShowAddPunchPopup(false)}
          >
            <PunchesAddPopup
              prefillProfile={undefined}
              onYes={onAddPunch}
              onClose={() => setShowAddPunchPopup(false)}
            />
          </SidePopupOverlay>
        </>
      </Wrapper>
    </FullPage>
  );
};

export default EmployeesSuperpunchPage;
