import { Component, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import moment, { Moment } from "moment";
import Button, { ButtonState } from "components/controls/StyledButton";
import DatePicker from "components/controls/SingleDatePickerControl";
import Avatar from "components/views/Avatar";
import FieldWrapper from "components/UI/FieldWrapper";
import CheckboxControl from "components/UI/NewCheckbox";
import RichTooltip from "components/UI/RichTooltip";
import NotificationRow from "components/NotificationRow";
import TextInputControl from "components/controls/TextInputControl";
import { NotificationType } from "types/common";
import { deactivateEmployee } from "utils/api/employee";
import { UserProfile } from "types/models/userProfile";
import { TranslationNamespaces } from "types/translationNamespaces";
import CONFIG from "config";
import { translateEmployeeTerm } from "utils/translationHelpers";

const GRANULARITY_DAY = "day";
const GRANULARITY_YEAR: moment.unitOfTime.DurationConstructor = "year";
const MIN_DEACTIVATION_DISTANCE = {
  value: 1,
  unit: GRANULARITY_YEAR,
};

enum DeactivationSteps {
  deactivation,
  confirmation,
}

const Wrapper = styled.div`
  .deactivation-date {
    div:first-child {
      justify-content: start;
    }
  }

  .rich-tooltip {
    margin-inline-start: 10px;
  }

  .notification-message {
    color: var(--colors-surface-900-p);
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.55px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
`;

const Spacer = styled.div`
  width: 12px;
  min-width: 12px;
`;

const EmployeeInfo = styled.div`
  display: flex;
  margin-top: 32px;
  padding: 24px 0;
  border: 1px solid var(--colors-unknown1);
  border-inline-start: none;
  border-inline-end: none;

  .avatar {
    margin-inline-end: 18px;
  }
`;

const EmployeeTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  color: var(--colors-surface-400);

  .employee-info-fullname {
    font-size: 18px;
    line-height: 26px;
    color: var(--colors-surface-900-p);
  }
`;

const CheckBoxdWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const AreYouSure = styled.div`
  margin: 10px 0 24px;
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-surface-400);

  div:last-child {
    font-weight: var(--typography-font-weight-medium);
  }
`;

interface DeactivateEmployeeProps extends WithTranslation {
  employee: UserProfile | null;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  closePopup: () => void;
}

interface DeactivateEmployeeState {
  step: DeactivationSteps;
  removeFromPayrollGroup: boolean;
  removeDeactivateDateFromSchedule: boolean;
  deactivationDate: moment.Moment;
  employeeNameCheck: string;
  isLoading: boolean;
}

class DeactivateEmployee extends Component<DeactivateEmployeeProps, DeactivateEmployeeState> {
  readonly state = {
    step: DeactivationSteps.deactivation,
    removeFromPayrollGroup: false,
    removeDeactivateDateFromSchedule: true,
    deactivationDate: moment(),
    employeeNameCheck: "",
    isLoading: false,
  };

  onConfirm = async (): Promise<void> => {
    const { employee, closePopup, setNotification } = this.props;
    const { step, deactivationDate, removeFromPayrollGroup, removeDeactivateDateFromSchedule } = this.state;

    if (step === DeactivationSteps.deactivation) {
      this.setState({ step: DeactivationSteps.confirmation });
      return;
    }

    try {
      this.setState({ isLoading: true });

      await deactivateEmployee(
        (employee as UserProfile).id,
        deactivationDate.format(CONFIG.apiDateFormat2),
        removeFromPayrollGroup,
        removeDeactivateDateFromSchedule,
      );

      setNotification("The profile was deactivated", NotificationType.success);
    } catch (e) {
      setNotification(e as string, NotificationType.error);
    } finally {
      this.setState({ isLoading: false }, closePopup);
    }
  };

  getTitle = (): string => {
    const { t } = this.props;
    const { step } = this.state;

    return t(
      step === DeactivationSteps.deactivation
        ? translateEmployeeTerm(
            t,
            TranslationNamespaces.employeesPage,
            "custom-deactivate-employee",
            "Deactivate employee",
          )
        : "Confirm Deactivation",
    );
  };

  getContent = (): ReactNode => {
    const { t, employee } = this.props;
    const { step, removeFromPayrollGroup, deactivationDate, employeeNameCheck, removeDeactivateDateFromSchedule } = this.state;

    if (!employee) {
      return null;
    }

    const lockedDate = employee?.last_lock_date ? moment(employee.last_lock_date).utc() : null;
    const minDeactivationDate = moment()
      .subtract(MIN_DEACTIVATION_DISTANCE.value, MIN_DEACTIVATION_DISTANCE.unit)
      .format(CONFIG.apiDateFormat);

    const isOutsideRange = (day: Moment) =>
      day.isSameOrBefore(minDeactivationDate, GRANULARITY_DAY) ||
      (lockedDate && day.isSameOrBefore(lockedDate, GRANULARITY_DAY));

    switch (step) {
      case DeactivationSteps.deactivation:
        return (
          <>
            <EmployeeInfo>
              <Avatar
                user={{
                  fullName: employee.full_name,
                  avatarId: employee.avatar_id,
                }}
                modifiers={{ big: true }}
              />
              <EmployeeTitle>
                <div className="employee-info-fullname">{employee.full_name}</div>
                {employee.position?.title && <div>{employee.position.title}</div>}
              </EmployeeTitle>
            </EmployeeInfo>
            <FieldWrapper
              className="deactivation-date"
              width="100%"
              fieldTitleMarginTop={24}
              fieldName={t("Deactivation Date")}
              tooltipText={translateEmployeeTerm(
                t,
                TranslationNamespaces.employeesPage,
                "custom-deactivation-date-tooltip",
                "deactivation-date-tooltip",
              )}
            >
              <DatePicker
                value={deactivationDate}
                onChange={(date: moment.Moment): void => this.setState({ deactivationDate: date })}
                isOutsideRange={isOutsideRange}
              />
            </FieldWrapper>
            <CheckBoxdWrapper>
              <CheckboxControl
                label={t("Remove from payroll group")}
                checked={removeFromPayrollGroup}
                onChange={(checked: boolean): void => {
                  this.setState({ removeFromPayrollGroup: checked });
                }}
              />
              <RichTooltip
                text={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.employeesPage,
                  "custom-remove-from-payroll-tooltip",
                  "remove-from-payroll-tooltip",
                )}
              />
            </CheckBoxdWrapper>
            <CheckBoxdWrapper>
              <CheckboxControl
                label={t("Remove the schedule of the deactivation day")}
                checked={removeDeactivateDateFromSchedule}
                onChange={(checked: boolean): void => {
                  this.setState({ removeDeactivateDateFromSchedule: checked });
                }}
              />
              <RichTooltip
                text={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.employeesPage,
                  "custom-remove-deactivate-date-from-schedule-tooltip",
                  "remove-deactivate-date-from-schedule-tooltip",
                )}
              />
            </CheckBoxdWrapper>
          </>
        );
      case DeactivationSteps.confirmation:
        return (
          <>
            <AreYouSure>
              <div>{t("Are you sure you want to deactivate")}</div>
              <div>{employee.full_name}</div>
            </AreYouSure>
            <NotificationRow
              type={NotificationType.attention}
              message={translateEmployeeTerm(
                t,
                TranslationNamespaces.employeesPage,
                "custom-deactivate-employee-warning",
                "deactivate-employee-warning",
              )}
            />
            <FieldWrapper
              width="100%"
              fieldTitleMarginTop={24}
              fieldName={t("Type the {{employeeName}} to complete the action", {
                employeeName: employee.full_name,
              })}
            >
              <TextInputControl
                value={employeeNameCheck}
                onChange={(txt): void => this.setState({ employeeNameCheck: txt })}
              />
            </FieldWrapper>
          </>
        );
      default:
        return null;
    }
  };

  render(): ReactNode {
    const { t, closePopup, employee } = this.props;
    const { step, employeeNameCheck, isLoading } = this.state;

    if (!employee) {
      return null;
    }

    return (
      <Wrapper>
        <Title>{this.getTitle()}</Title>
        {this.getContent()}
        <Buttons>
          <Button
            state={ButtonState.secondary}
            onClick={closePopup}
            onDoubleClick={closePopup}
            value={t(`${TranslationNamespaces.common}|Cancel`)}
          />
          <Spacer />
          <Button
            loading={isLoading}
            onClick={this.onConfirm}
            onDoubleClick={this.onConfirm}
            value={t(
              DeactivationSteps.confirmation
                ? `${TranslationNamespaces.common}|Deactivate`
                : `${TranslationNamespaces.common}|Confirm`,
            )}
            disabled={step === DeactivationSteps.confirmation && employeeNameCheck.trim() !== employee.full_name.trim()}
          />
        </Buttons>
      </Wrapper>
    );
  }
}

export default withTranslation(TranslationNamespaces.employeesPage)(DeactivateEmployee);
