import { useEffect } from "react";
import styled from "styled-components";
import useState from "hooks/useState";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import { services } from "components/PunchNow/services";
import chromeLogo from "./chrome_logo.png";

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colors-surface-50);
  border-radius: var(--shapes-border-radius-default);
  margin-bottom: 37px;
`;

const Img = styled.img`
  width: 36px;
  height: 36px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 24px;
  gap: 4px;
`;

const TextTitle = styled.p`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-900-p);
  margin: 0;
`;

const TextDescription = styled.p`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-700);
  margin: 0;
`;

interface BrowserExtensionRowState {
  isRowVisible: boolean;
  extensionUrl: string | null;
}

const BrowserExtensionRow = () => {
  const { t } = useTranslation(TranslationNamespaces.applications);

  const [state, setState] = useState<BrowserExtensionRowState>({
    isRowVisible: false,
    extensionUrl: null,
  });

  const { isRowVisible, extensionUrl } = state;

  const checkExtensionInstalled = async () => {
    const version = await services.extensionService.getExtensionVersion();
    const extensionDownloadUrl = services.extensionService.getExtensionDownloadUrl();

    setState({ isRowVisible: version === null, extensionUrl: extensionDownloadUrl });
  };

  useEffect(() => {
    void checkExtensionInstalled();
  }, []);

  if (!extensionUrl || !isRowVisible) {
    return null;
  }

  return (
    <Wrapper>
      <Img src={chromeLogo} />
      <Text>
        <TextTitle>{t("download-exntension-title")}</TextTitle>
        <TextDescription>{t("download-exntension-description")}</TextDescription>
      </Text>
      <Button
        value={t("Get extension")}
        style={{ width: "auto", whiteSpace: "nowrap", padding: "11px 16px" }}
        state={ButtonState.primary}
        onClick={() => window.open(extensionUrl, "_blank")}
      />
    </Wrapper>
  );
};

export default BrowserExtensionRow;
