import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getSelectedColumns, getTrPropsClassName } from "utils/tableHelpers";
import * as moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import styled from "styled-components";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { minsToHrsMins } from "utils/common";
import TablePage from "components/TablePage";
import "styles/reports-extra-hour-table.scss";
import { getEmployeeNameColumn, mapTableData } from "utils/reportsHelpers";
import Pagination from "components/Pagination";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import ReportsNoContent from "./ReportsNoContent";
import { sliceReportIntoChunks, getPhasesLabel, PhasesType } from "./helpers";

const b = BEM.b("reports-extra-hour-table");
const Wrapper = styled.div``;
class ReportsExtraHourTableAllEmployees extends Component {
  state = {
    loading: true,
    employees: [[]],
    activePhases: [],
    selectedColumns: getSelectedColumns(
      "name,total_worked_hours,total_missed_hours,total_extra_hours",
      "ReportsExtraHourTableAllEmployees",
    ),
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = () => {
    const { perPage } = this.state;
    const {
      startDate,
      endDate,
      searchObj,
      onlyNonZeroPhases,
      onlyNonZeroLines,
      skipSupervisors,
      showInactiveEmploees,
      oldReport,
      updateRecalculatingMessage,
      setIsLoading,
    } = this.props;
    const companyUUID = window.global_store.company.uuid;
    const selectedColumns = getSelectedColumns(
      "name,total_worked_hours,total_missed_hours,cumulative_hours_bank",
      "ReportsExtraHourTableAllEmployees",
    );

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true, selectedColumns }, async () => {
        setIsLoading(true);

        try {
          const response = await getReport(
            {
              oldReport,
              onlyNonZeroPhases,
              onlyNonZeroLines,
              skipSupervisors,
              showInactiveEmploees,
              groupUUID: searchObj.uuid,
              groupType: searchObj.type,
              startDate,
              endDate,
              companyUUID,
              type: "extra_hours",
            },
            true,
          );

          const resp = response.content;
          const employees = resp.dates || resp;

          if (!oldReport && resp) {
            const allDaysCount = resp.length || 0;
            const completedDaysCount = resp.filter((d) => d.status === "completed").length;

            if (!resp.length) {
              updateRecalculatingMessage(false);
            } else {
              updateRecalculatingMessage(
                allDaysCount !== completedDaysCount || response?.metadata?.status === "pending",
              );
            }
          }

          const chunks = sliceReportIntoChunks(employees, perPage);

          this.setState({
            activePhases: response.metadata.activePhases,
            employees: chunks.length ? chunks : [[]],
            totalRecords: employees.length,
            loading: false,
            error: false,
          });
        } catch (e) {
          console.log("Error", e);

          this.setState({
            loading: false,
            error: this.props.t("Failed to generate report"),
          });
        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  getColumns = () => {
    const { t } = this.props;
    const { activePhases } = this.state;
    let columns = [];

    const phasesColumns = activePhases.length
      ? activePhases.map((phase) => ({
          accessor: phase.uuid,
          rubyAccessor: "phases",
          groupLabel: t("Phases"),
          label: getPhasesLabel(t, PhasesType.extraHours, phase),
          Cell: (row) => {
            const hours = row.original.totalExtraHoursPhases[phase.uuid] || 0;
            return minsToHrsMins(hours);
          },
          style: { fontWeight: "500" },
          align: "end",
          minWidth: 100,
        }))
      : [];

    columns = columns.concat([
      getEmployeeNameColumn(t),
      ...phasesColumns,
      {
        accessor: "totalWorkedMinutes",
        rubyAccessor: "total_worked_hours",
        label: t("Total Worked Hours"),
        style: { fontWeight: "500" },
        minWidth: 130,
        align: "right",
      },
      {
        accessor: "totalMissedMinutes",
        rubyAccessor: "total_missed_hours",
        label: t("Missed Minutes"),
        minWidth: 100,
        align: "right",
      },
      {
        accessor: "totalExtraHoursMinutes",
        rubyAccessor: "total_extra_hours",
        label: t("Extra Hours"),
        minWidth: 130,
        align: "right",
      },
      {
        accessor: "totalDebitMinutes",
        rubyAccessor: "total_debit_hours",
        label: t("Debit Minutes"),
        minWidth: 130,
        align: "end",
      },
    ]);

    columns.push({
      accessor: "totalOnCallMinutes",
      rubyAccessor: "total_on_call_hours",
      label: t("On Call Minutes"),
      minWidth: 130,
      align: "right",
    });
    columns.push({
      accessor: "totalOnCallActivatedMinutes",
      rubyAccessor: "total_on_call_activated_hours",
      label: t("On Call Activated Minutes"),
      minWidth: 130,
      align: "right",
    });

    return columns;
  };

  onColumnsChange = (selectedColumns) => {
    const reportName = "ReportsExtraHourTableAllEmployees";

    if (localStorage) {
      localStorage.setItem(`customColumns_${reportName}`, selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPageLimitChange = (pageLimit) => {
    const { employees } = this.state;

    this.setState({ page: 1, perPage: pageLimit, employees: sliceReportIntoChunks(employees.flat(), pageLimit) });
  };

  render() {
    const { loading, error, employees, selectedColumns, page, perPage, totalRecords } = this.state;
    const {
      t,
      showInactiveEmploees,
      onlyNonZeroPhases,
      onlyNonZeroLines,
      skipSupervisors,
      startDate,
      endDate,
      searchObj,
      oldReport,
    } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    const data = mapTableData(
      [
        "fullName",
        "date",
        "totalExtraHoursPhases",
        "totalMissedMinutes",
        "totalWorkedMinutes",
        "totalExtraHoursMinutes",
        "totalDebitMinutes",
        "totalOnCallMinutes",
        "totalOnCallActivatedMinutes",
        "status",
      ],
      employees[page - 1],
    );

    const columns = this.getColumns();

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj?.type && searchObj?.type !== "all" && searchObj?.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    return (
      <Wrapper className={b()}>
        <TablePage
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          loading={loading}
          rows={data}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  searchObj,
                  startDate,
                  endDate,
                  onlyNonZeroPhases,
                  onlyNonZeroLines,
                  showInactiveEmploees,
                  skipSupervisors,
                  oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: selectedColumns ? "extra_hours_all" : "extra_hours",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className: getTrPropsClassName(this.props, rowInfo),
          })}
          className="reports-hoursbank-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </Wrapper>
    );
  }
}

export default withRouter(withTranslation(["reports-page", "phases"])(ReportsExtraHourTableAllEmployees));
