import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import ProjectsPopupMessage from "components/Projects/ProjectsPopupMessage";
import { useProfile } from "context/user-profile-context";
import { useWidgetProjectsService } from "utils/useServices";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { normalizeString } from "components/views/Select";
import { ProjRes } from "services/oitchau-api";
import { StoreApi, UseBoundStore } from "zustand/esm";
import { Popup } from "@mobiscroll/react";
import { CancelBtn, CreateProjectBtn, DirectoryIcon } from "./TimerButtons";
import { TrackTimeStore } from "../TrackTime.store";

export const CreateProject = ({ onCreate, store }: { onCreate?: (project: ProjRes) => void, store: UseBoundStore<StoreApi<TrackTimeStore>>}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const { setTargetProject, setProjects, setShowSelectProject, setShowTaskPicker, showCreateProject, setShowCreateProject, selectedEmployee } = store();
  const profile = useProfile();
  const widgetProjectsService = useWidgetProjectsService();

  const [loadProjects] = useAsyncCallback(async () => {
    const res = await widgetProjectsService.listProjectsWithTasks(selectedEmployee?.uuid) as unknown as ProjRes[];
    const options = res.map((project) => ({
      project,
      taskOptions: project.tasks.map((t) => ({
        task: t,
        searchTerms: [normalizeString(t.name)],
      })),
    }))
    setProjects(options);
    return options;
  }, [profile]);

  return (<>
    <CreateProjectBtn type="button" onClick={() => setShowCreateProject(true)}>
      <DirectoryIcon />
      <div>{t("Create new project")}</div>
    </CreateProjectBtn>
    <CancelBtn onClick={() => {
      setShowSelectProject(false);
      setShowTaskPicker(false);
      setShowCreateProject(false);
    }} />
      <Popup
        display="center"
        showArrow={false}
        scrollLock={false}
        isOpen={showCreateProject}
        maxHeight={600}
        width={414}
        onClose={() => setShowCreateProject(false)}
        cssClass="PopupWrapper ActivityPopup"
      >
        <div style={{padding: "34px", background: "var(--colors-surface-0)"}}>
          <ProjectsPopupMessage
            onClose={() => {
              setShowCreateProject(false);
            }}
            onYes={async (projectUuid: string) => {
              const projects = await loadProjects();
              const targetProject = projects.find(p => p.project.uuid === projectUuid);
              if (targetProject) await setTargetProject(targetProject.project);
              if (onCreate) onCreate(targetProject!.project!);
              else setShowCreateProject(false);
            }}
          />
        </div>
      </Popup>
  </>);
}
