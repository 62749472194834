import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import BEM from "utils/BEM";
import { getPunchesByEmployee } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Punch } from "types/models/punches";
import EmployeePunchesTable from "./EmployeePunchesTable";
import "components/UI/Page/Page.scss";

const p = BEM.b("page");

interface EmployeePunchesProps extends WithTranslation {
  employeeId: number;
}

interface EmployeePunchesState {
  loaded: boolean;
  punches: Punch[];
  page: number;
  perPage: number;
  totalRecords: number;
}

class EmployeePunches extends Component<EmployeePunchesProps, EmployeePunchesState> {
  readonly state: Readonly<EmployeePunchesState> = {
    loaded: false,
    punches: [],
    totalRecords: 0,
    page: 1,
    perPage: 25,
  };

  async componentDidMount() {
    this.getPunches();
  }

  getPunches = async () => {
    const { page, perPage } = this.state;

    try {
      const response = await getPunchesByEmployee({ employeeId: this.props.employeeId, page, perPage });
      const punches = response.punches || [];
      const nextPage = response?.meta?.next_page || page;
      const responseTotalRecords = nextPage ? nextPage * perPage : punches.length;

      this.setState({
        punches,
        loaded: true,
        totalRecords: responseTotalRecords,
      });
    } catch (err) {
      sentryUtils.sendError(err);

      this.setState({ loaded: true, totalRecords: 0 });
    }
  };

  onPaginationChange = (pageLimit: number, page: number) => {
    this.setState({ page, perPage: pageLimit }, () => this.getPunches());
  };

  render() {
    const { t } = this.props;
    const { punches, loaded, page, perPage, totalRecords } = this.state;

    return (
      <div>
        {loaded ? (
          <div className={p("content")}>
            {punches.length > 0 && (
              <EmployeePunchesTable
                page={page}
                perPage={perPage}
                totalRecords={totalRecords}
                onPaginationChange={this.onPaginationChange}
                punches={punches}
              />
            )}
          </div>
        ) : (
          <div>{t("common|Loading...")}</div>
        )}
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.employeesPage)(EmployeePunches);
