import { Component } from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { AxiosError } from "axios";
import BEM from "utils/BEM";
import { getScheduleEvents, getEmployee, getShiftCompilationsInRangeV2 } from "utils/apiHelpers";
import { hasEmployeesAccess, getLastLockDate, minsToHrsMins } from "utils/common";
import { PermissionSectionName } from "types/models/permissions";
import { Location, LocationInGlobalContextEmployee } from "types/models/location";
import { AddPunchPunch, PunchKey, PunchReason, PunchStatuses, PunchType } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { GlobalContextEmployee, SearchEmployeeUserProfile } from "types/models/userProfile";
import { withLockDateCheck } from "components/HocWithLockDateCheck";
import { TranslationNamespaces } from "types/translationNamespaces";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import EmployeeInfo from "components/UI/EmployeeInfo";
import TimeControl from "components/controls/TimeControl";
import CheckboxControl from "components/UI/NewCheckbox";
import Button, { ButtonState } from "components/controls/StyledButton";
import LocationsDropdown, { LocationDropdownOption } from "components/LocationsDropdown";
import ga, { GaCreatePunchButtonLocation, MassActionLocations } from "utils/ga";
import { translateEmployeeTerm } from "utils/translationHelpers";
import SingleDatePickerControl from "components/controls/DatePicker/SingleDatePickerControl";
import { getCustomBreaksList } from "utils/api/schedule";
import { BreakStatusOptions } from "utils/api/types";
import { getBreakName, getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import PunchTypesDropdownControl, { PunchTypesDropdownOption } from "./PunchTypesDropdownControl";
import ReasonsDropdownControl from "./ReasonsDropdownControl";
import RetroactiveChangeWarning from "./RetroactiveChangeWarning";
import "styles/punches-add-popup.scss";
import { BreakPair, getBreakPairs } from "components/Schedules/helpers";
import { ScheduleDayEvent } from "types/models/schedule";
import { BasedOn } from "components/Schedules/Breaks/BreakModal";

const b = BEM.b("punches-add-popup");

const CheckboxRow = styled.div`
  margin-top: 16px;
`;
const ButtonWrapper = styled.div`
  width: 160px;
`;
const NotificationBar = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

type PunchesAddPrefillProfile = {
  uuid: string;
  id: number;
  full_name?: string;
  label: string;
};

type PunchesAddEmployeeInfo = {
  uuid: string;
  id: number;
  fullName: string;
  last_lock_date?: string;
  avatarId: string;
  position: string;
};

interface PunchesAddPopupProps extends WithTranslation {
  /** SuperpunchOverlay */
  employeeInfo?: PunchesAddEmployeeInfo;
  /** WIP PROD-12360. value should be uuid */
  location?: LocationDropdownOption<string> | null;
  punchDate?: moment.Moment | null;
  superPunch?: boolean;
  eventKeyToSelect?: PunchKey;
  punchTypeToSelect?: PunchType;
  /** PunchesPage */
  prefillProfile?: PunchesAddPrefillProfile;
  /** comes from HocWithLockDateCheck */
  checkLockDate: () => void;
  onYes: (
    punch: AddPunchPunch,
    employeeFullName: string | undefined,
    event: AddPunchMappedEvent["event"] | undefined,
    employeeUUID?: string,
  ) => void;
  onClose: () => void;
}

interface PunchesAddPopupState {
  errors: Record<string, string> | null;
  shiftsLoading: boolean;
  reason: PunchReason | null;
  punchDate: moment.Moment;
  lockedDate: moment.Moment | null;
  employeeUUID: string | null;
  employeeID: number | null;
  employeeFullName: string | null;
  nightShift: boolean;
  events: AddPunchMappedEvent[];
  /** Location is set during initial setup if user has no employees access. LocationInGlobalContextEmployee - after single employee pick */
  employeeLocations: (Location | LocationInGlobalContextEmployee)[];
  preselectedEventKey: PunchKey | null;
  preselectedEventType: PunchType | null;
  /** AddPunchMappedEvent - if "events" prop provided */
  selectedPunchType: AddPunchMappedEvent | PunchTypesDropdownOption | null;
  loading: boolean;
  /** location uuid as value */
  selectedLocation: LocationDropdownOption<string> | null;
  /** not null if only one employee has been selected via SearchControl */
  employee: SearchEmployeeUserProfile | GlobalContextEmployee | null;
  /** not null if multiple employees have been selected via SearchControl */
  employeeUuids: string[] | null;
  /** HH:MM for UI purpose */
  time: string | null;
  customBreaksNamesMap: Record<string, string>;
  allBreakPairs: BreakPair<ScheduleDayEvent>[];
}

class PunchesAddPopup extends Component<PunchesAddPopupProps, PunchesAddPopupState> {
  shiftsRefetchTimeout: NodeJS.Timeout | null = null;
  whenTime: TimeControl | null = null;
  otherReason: HTMLTextAreaElement | null = null;

  constructor(props: PunchesAddPopupProps) {
    super(props);
    const { prefillProfile, punchDate, eventKeyToSelect, punchTypeToSelect, employeeInfo, superPunch, location } =
      props;

    const state: PunchesAddPopupState = {
      errors: null,
      shiftsLoading: false,
      reason: superPunch ? PunchReason.forgotToPunch : null,
      punchDate: punchDate || moment(),
      selectedPunchType: null,
      lockedDate: null,
      employeeUUID: null,
      employeeID: null,
      employeeFullName: null,
      employeeUuids: null,
      nightShift: false,
      events: [],
      allBreakPairs: [],
      employeeLocations: [],
      preselectedEventKey: eventKeyToSelect || null,
      preselectedEventType: punchTypeToSelect || null,
      loading: false,
      selectedLocation: location || null,
      employee: null,
      time: null,
      customBreaksNamesMap: {},
    };

    if (prefillProfile) {
      state.employeeUUID = prefillProfile.uuid;
      state.employeeID = prefillProfile.id;
      state.employeeFullName = prefillProfile.full_name || prefillProfile.label;
    } else if (!hasEmployeesAccess()) {
      state.employeeID = window.global_store.profile.id;
      state.employeeUUID = window.global_store.profile.uuid;
      state.employeeFullName = window.global_store.profile.full_name;
      state.employeeLocations = window.global_store.profile.locations || [];
      state.lockedDate = window.global_store.profile.last_lock_date
        ? moment(window.global_store.profile.last_lock_date).utc()
        : null;
    } else if (employeeInfo) {
      state.employeeUUID = employeeInfo.uuid;
      state.employeeID = employeeInfo.id;
      state.employeeFullName = employeeInfo.fullName;
      state.lockedDate = employeeInfo.last_lock_date ? moment(employeeInfo.last_lock_date).utc() : null;
    }

    this.state = state;
  }

  async componentDidMount() {
    const { employeeID } = this.state;

    const customBreaksResponse = await getCustomBreaksList({
      perPage: 300,
      page: 1,
      statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
      companyUuid: window.global_store.company.uuid,
      requestedBy: window.global_store.profile.uuid,
    });

    this.setState({ customBreaksNamesMap: getBreaksNamesMap(customBreaksResponse.content) });

    if (employeeID) {
      const res = await getEmployee({
        id: employeeID,
        newHierarchyPermissions: true,
      });

      if (res.user_profile) {
        this.setState({
          employeeLocations: res.user_profile.locations,
          lockedDate: res.user_profile.last_lock_date ? moment(res.user_profile.last_lock_date).utc() : null,
        });
      }
    }

    void this.fetchEvents();
  }

  fetchEvents = async () => {
    const { t, punchTypeToSelect } = this.props;
    const {
      employeeUUID,
      punchDate,
      preselectedEventKey,
      preselectedEventType,
      customBreaksNamesMap,
      selectedPunchType,
    } = this.state;

    if (this.shiftsRefetchTimeout) {
      clearTimeout(this.shiftsRefetchTimeout);
    }

    if (!employeeUUID) {
      return;
    }

    try {
      const res = await getScheduleEvents({
        userProfileUUID: employeeUUID,
        supervisorUUID: window.global_store.profile.uuid,
        dateFrom: punchDate.format("YYYY-MM-DD"),
        dateTo: punchDate.format("YYYY-MM-DD"),
      });

      if (!res.content.length) {
        return;
      }

      const shiftDay = res.content[0];

      const entryEvent = shiftDay.events.find((e) => e.key === `${PunchType.entry}0`);
      const otherEvents = shiftDay.events.filter(
        (e) => e.key !== `${PunchType.entry}0` && e.type !== PunchType.breakStart && e.type !== PunchType.breakEnd,
      );
      const allBreakPairs = getBreakPairs(shiftDay.events);
      // first element in pair should have property breakMode = "flexible" for flexible
      const { flexibleBreaksPairs, nonFlexibleBreaksPairs } = allBreakPairs.reduce<{
        flexibleBreaksPairs: BreakPair<ScheduleDayEvent>[];
        nonFlexibleBreaksPairs: BreakPair<ScheduleDayEvent>[];
      }>(
        (acc, pair) => {
          const [breakStart] = pair;
          if (!punchTypeToSelect && shiftDay?.businessRules?.hideBreaks && breakStart?.automatic) {
            return acc;
          }

          if (breakStart?.breakMode === BasedOn.flexible) {
            acc.flexibleBreaksPairs.push(pair);
          } else {
            acc.nonFlexibleBreaksPairs.push(pair);
          }

          return acc;
        },
        { flexibleBreaksPairs: [], nonFlexibleBreaksPairs: [] },
      );

      // ONLY FOR NON FLEXIBLE BREAKS
      // keep only breakPairs where first element's breakTypeUuid is unique
      // in case of multiple pairs with the same breakTypeUuid and preselectedEventKey is provided - keep pair with this key

      const uniqueBreakPairs: Record<string, BreakPair<ScheduleDayEvent>> = {};

      flexibleBreaksPairs.forEach((breakPair) => {
        const [breakStart, breakEnd] = breakPair;
        if (
          breakStart?.breakTypeUuid &&
          (!uniqueBreakPairs[breakStart?.breakTypeUuid] ||
            (preselectedEventKey && (breakStart?.key === preselectedEventKey || breakEnd?.key === preselectedEventKey)))
        ) {
          uniqueBreakPairs[breakStart?.breakTypeUuid] = breakPair;
        }
      });

      const breakPairs = Object.values(uniqueBreakPairs);

      const allEvents = [entryEvent, ...nonFlexibleBreaksPairs.flat(), ...breakPairs.flat(), ...otherEvents];

      // filter out automatic breaks if hideBreaks enabled
      const mappedEvents = allEvents.reduce((acc, e, i) => {
        if (!e) {
          return acc;
        }
        let eventLabel = t(e.type);
        if (e.type === PunchType.breakStart || e.type === PunchType.breakEnd) {
          eventLabel = getBreakName(e.breakTypeUuid, e.type, customBreaksNamesMap);
        }

        const mappedEvent: AddPunchMappedEvent = {
          label: eventLabel,
          value: e.key,
          type: e.type,
          key: e.key,
          time: e.time && e.time % (24 * 60), // mins in one day
          event: {
            breakTypeUuid: e.breakTypeUuid,
            key: e.key,
            shiftId: shiftDay.shiftId,
            date: shiftDay.date,
          },
        };

        return [...acc, mappedEvent];
      }, [] as AddPunchMappedEvent[]);

      let selectedEvent = selectedPunchType;

      if (!selectedPunchType && preselectedEventType) {
        let selectedEvents = [];

        if (preselectedEventKey) {
          selectedEvents = mappedEvents.filter((ev) => ev.key === preselectedEventKey);
        } else {
          selectedEvents = mappedEvents.filter((ev) => ev.type === punchTypeToSelect);
        }

        if (selectedEvents.length) {
          [selectedEvent] = selectedEvents;
        }
      }

      this.setState({
        shiftsLoading: false,
        events: mappedEvents,
        allBreakPairs,
        preselectedEventKey: null,
        preselectedEventType: null,
        selectedPunchType: selectedEvent,
      });
    } catch (e) {
      if ((e as { status: number }).status === 503 || (e as AxiosError)?.code === "503") {
        this.setState({ shiftsLoading: true });

        this.shiftsRefetchTimeout = setTimeout(this.fetchEvents, 10000);
      }
    }
  };

  getFormErrors = () => {
    const { t } = this.props;

    let errors: PunchesAddPopupState["errors"] = {};

    if (!this.state.punchDate) {
      errors.when = t("When can't be blank");
    }
    if (!this.whenTime || !this.whenTime.getValue()) {
      errors.whenTime = t("Time can't be empty");
    }
    if (this.whenTime && this.whenTime.getError()) {
      errors.whenTime = t(this.whenTime.getError());
    }
    if (!this.state.employeeUUID && !this.state.employeeID && !this.state.employeeUuids) {
      errors.employee = t("Please select an employee");
    }
    if (!this.state.selectedPunchType) {
      errors.selectedPunchType = t("Punch type can't be empty");
    }
    if (!this.state.selectedLocation || !this.state.selectedLocation.value) {
      errors.location = t("Location can't be empty");
    }
    if (!this.state.reason) {
      errors.reason = t("Reason can't be empty");
    }

    if (
      this.state.punchDate &&
      this.state.selectedLocation?.value &&
      this.whenTime &&
      this.whenTime.getValue() &&
      !this.whenTime.getError()
    ) {
      const selectedTime = momentTz.tz(
        this.state.punchDate.format("YYYY-MM-DD ") + this.whenTime.getValue(),
        this.state.selectedLocation.timeZone,
      );
      const nowTz = momentTz.tz(this.state.selectedLocation.timeZone);

      if (selectedTime.isAfter(nowTz)) {
        errors.when = `${t("You can't edit future dates. Current time in selected location is")}: ${nowTz.format(
          "DD/MM/YYYY HH:mm",
        )}`;

        errors.whenTime = " ";
      }
    }

    if (Object.keys(errors).length === 0) {
      errors = null;
    }

    return errors;
  };

  onYes = async () => {
    const { checkLockDate, onYes, superPunch, eventKeyToSelect } = this.props;
    const {
      employee,
      punchDate,
      selectedLocation,
      reason,
      employeeUuids,
      employeeID,
      employeeUUID,
      employeeFullName,
      selectedPunchType,
      allBreakPairs,
    } = this.state;

    if (employee && getLastLockDate(employee) && moment(getLastLockDate(employee)).isSameOrAfter(punchDate, "day")) {
      checkLockDate();
      return;
    }

    this.setState({ loading: true });

    const errors = this.getFormErrors();

    if (errors) {
      this.setState({ errors, loading: false });
      return;
    }

    const eventOverride = (selectedPunchType as AddPunchMappedEvent).event;
    const selectedTime = momentTz.tz(
      punchDate.format("YYYY-MM-DD ") + (this.whenTime as TimeControl).getValue(),
      (selectedLocation as LocationDropdownOption<string>).timeZone, // could not be null. see getFormErrors()
    );
    const isAdmin = ["employer", "supervisor"].includes(window.global_store.profile.role);

    const punchTypeToSave =
      (selectedPunchType as AddPunchMappedEvent).type || (selectedPunchType as PunchTypesDropdownOption).value;

    if (
      (punchTypeToSave === PunchType.breakStart || punchTypeToSave === PunchType.breakEnd) &&
      !eventKeyToSelect &&
      employeeUUID &&
      (selectedPunchType as AddPunchMappedEvent)?.event?.breakTypeUuid
    ) {
      const shiftCompilationResp = await getShiftCompilationsInRangeV2({
        employeeUUID: employeeUUID as string,
        companyUUID: window.global_store.company.uuid,
        from: punchDate,
        to: punchDate,
        requestedBy: window.global_store.profile.uuid,
      });

      const shiftCompilation = shiftCompilationResp.content[0];

      // skip if no shiftCompilation, or no punches, or no punch for selected key
      if (
        !shiftCompilation?.punches ||
        !shiftCompilation.punches.find((p) => p.key === (selectedPunchType as AddPunchMappedEvent)?.event?.key)?.id
      ) {
        // NO ACTIONS
        eventOverride.key = (selectedPunchType as AddPunchMappedEvent).event.key;
      } else {
        // get all keys for breaks with the same breakTypeUuid, search for PunchForShiftCompilation with such key that is in the shift compilation and it's id is null
        for (const breakPair of allBreakPairs) {
          if (breakPair[0]?.breakTypeUuid !== (selectedPunchType as AddPunchMappedEvent)?.event?.breakTypeUuid) {
            continue;
          }

          const keyToSelect = breakPair.find(
            (br) => br?.type === (selectedPunchType as AddPunchMappedEvent)?.type,
          )?.key;

          if (keyToSelect) {
            eventOverride.key = keyToSelect;
          }

          if (keyToSelect && !shiftCompilation.punches.find((p) => p.key === keyToSelect)?.id) {
            break;
          }
        }
      }
    }

    const punch: AddPunchPunch = {
      punch: {
        location_id: (selectedLocation as LocationDropdownOption<string>).value,
        punch_type: punchTypeToSave,
        device_datetime: selectedTime.utc().format(),
        reason: reason as PunchReason, // could not be null. see getFormErrors()
        is_manual: "true",
        status: isAdmin ? PunchStatuses.approved : PunchStatuses.pending,
        created_by: window.global_store.profile.uuid,
      },
    };

    if (employeeUuids) {
      punch.punch.employee_uuids = employeeUuids;
    } else {
      punch.punch.employee_profile_id = employeeID as number; // could not be null. see getFormErrors()
    }

    if (reason === PunchReason.other && this.otherReason?.value) {
      punch.punch.comment = this.otherReason.value;
    }

    onYes(punch, employeeFullName || undefined, eventOverride, employeeUUID || undefined);

    ga.trackCreatePunch({
      punch_type:
        (this.state.selectedPunchType as AddPunchMappedEvent).type ||
        (this.state.selectedPunchType as PunchTypesDropdownOption).value,
      button_location: superPunch
        ? GaCreatePunchButtonLocation.superPunch
        : GaCreatePunchButtonLocation.punchAdjustment,
    });
  };

  onCancel = () => {
    this.props.onClose();
  };

  setPunchDate = (date: moment.Moment) => {
    if (date === this.state.punchDate) {
      return;
    }

    this.setState({ punchDate: date, events: [], allBreakPairs: [], selectedPunchType: null }, this.fetchEvents);
  };

  render() {
    const {
      errors,
      selectedPunchType,
      selectedLocation,
      reason,
      punchDate,
      lockedDate,
      employeeLocations,
      loading,
      nightShift,
      employeeUUID,
      shiftsLoading,
      employeeFullName,
      events,
      time,
      customBreaksNamesMap,
    } = this.state;
    const { employeeInfo, t, superPunch } = this.props;
    const buttonProps = { disabled: loading || shiftsLoading, onClick: this.onYes };

    return (
      <div className={b()}>
        {shiftsLoading && (
          <NotificationBar>
            {t("We are generating your schedule. It can take a few seconds. Please wait.")}
          </NotificationBar>
        )}

        {!employeeInfo && hasEmployeesAccess() && (
          <SearchControl
            trackingLocation={MassActionLocations.Punches}
            permissionSection={PermissionSectionName.viewPunches}
            withMultiple
            value={employeeFullName || undefined}
            onChange={(searchObj) => {
              let lockDate = null;
              let employee: SearchEmployeeUserProfile | GlobalContextEmployee | null = null;
              let employeeUuids: string[] | null = null;

              if (!Array.isArray(searchObj)) {
                employee =
                  ((searchObj as SearchControlOnChangeData)?.employee as SearchEmployeeUserProfile) ||
                  (searchObj as GlobalContextEmployee);
              } else if (searchObj.length === 1) {
                [employee] = searchObj;
              } else if (searchObj.length > 1) {
                employeeUuids = searchObj.map((e) => e.uuid);
              }

              lockDate = employee && getLastLockDate(employee) ? moment(getLastLockDate(employee)).utc() : null;
              let date = punchDate;

              if (lockDate && punchDate.isSameOrBefore(lockDate, "day")) {
                date = lockDate.clone().add(1, "day");
              }

              this.setState(
                {
                  employeeUUID: employee ? employee.uuid : null,
                  employeeID: employee ? employee.id : null,
                  employeeFullName: employee
                    ? (employee as SearchEmployeeUserProfile).full_name || employee.name
                    : null,
                  employeeUuids,
                  selectedLocation: null,
                  employeeLocations: employee?.locations || window.global_store.locations || [],
                  lockedDate: lockDate,
                  punchDate: date,
                  events: [],
                  allBreakPairs: [],
                  employee: employee || null,
                  selectedPunchType: null,
                },
                this.fetchEvents,
              );
            }}
            placeholder={translateEmployeeTerm(
              t,
              TranslationNamespaces.common,
              "custom-search-employees",
              `${TranslationNamespaces.common}|Search Employees`,
            )}
          />
        )}
        {errors?.employee && <div className={b("error")}>{errors.employee}</div>}
        {employeeInfo && <EmployeeInfo employeeInfo={employeeInfo} withBorder />}
        <div className={b("date-time", ["40"])}>
          <div className={b("date")}>
            <div className={b("label")} style={{ marginBottom: "5px" }}>
              {t("When")}
            </div>
            <SingleDatePickerControl
              numberOfMonths={1}
              value={punchDate}
              disabled={nightShift}
              error={!!errors?.when}
              onChange={(val) => this.setPunchDate(val)}
              isOutsideRange={(day) =>
                lockedDate
                  ? day.isSameOrBefore(lockedDate, "day")
                  : day.isBefore(moment().subtract(window.global_store.beta ? 24 : 2, "month"))
              }
            />
          </div>
          <div className={b("time")}>
            <div className={b("label", ["hour"])}>{t("Hour")}</div>
            <TimeControl
              value={
                time ||
                (superPunch && (selectedPunchType as AddPunchMappedEvent)?.time
                  ? minsToHrsMins((selectedPunchType as AddPunchMappedEvent)?.time as number)
                  : undefined)
              }
              onTimeChange={({ value }) => this.setState({ time: value })}
              onRef={(ref: TimeControl) => {
                this.whenTime = ref;
              }}
              error={!!errors?.whenTime}
              placeholder="00:00"
            />
          </div>
        </div>
        {errors?.when && <div className={b("error")}>{errors.when}</div>}
        {errors?.whenTime && <div className={b("error")}>{errors.whenTime}</div>}
        <CheckboxRow>
          <CheckboxControl
            checked={nightShift}
            label={t("Night Shift - Add Punch to the Next Day")}
            onChange={(checked) => {
              const pd = checked ? punchDate.clone().add(1, "day") : punchDate.clone().add(-1, "day");

              this.setState({
                nightShift: checked,
                punchDate: pd,
              });
            }}
          />
        </CheckboxRow>
        <div className={b("label", ["reason"])}>{t("Punch Type")}</div>
        <PunchTypesDropdownControl
          events={events}
          value={selectedPunchType}
          customBreaksNamesMap={customBreaksNamesMap}
          onChange={(val) => this.setState({ selectedPunchType: val })}
          placeholder={t("Select a category")}
        />
        {errors?.selectedPunchType && <div className={b("error")}>{errors.selectedPunchType}</div>}
        <div className={b("label", ["reason"])}>{t("Location")}</div>
        <LocationsDropdown<string>
          onlyActive
          uuid
          employeeUuid={employeeUUID}
          employeeLocations={employeeLocations}
          value={selectedLocation ? selectedLocation.value : ""}
          onChange={(_, option) => {
            this.setState({ selectedLocation: option || null });
          }}
          placeholder={t("Select a location")}
        />
        {errors?.location && <div className={b("error")}>{errors.location}</div>}
        <div className={b("label", ["reason"])}>{t("Reason")}</div>
        <ReasonsDropdownControl
          t={t}
          value={reason}
          placeholder={t("Select a reason")}
          onChange={(val) => this.setState({ reason: val })}
        />
        {errors?.reason && <div className={b("error")}>{errors.reason}</div>}
        {reason === PunchReason.other && (
          <textarea
            className={b("textarea")}
            ref={(ref) => {
              this.otherReason = ref;
            }}
          />
        )}
        <RetroactiveChangeWarning style={{ marginTop: "25px" }} />
        <div className={b("buttons")}>
          <ButtonWrapper>
            <Button
              state={ButtonState.secondary}
              value={t(`${TranslationNamespaces.common}|Cancel`)}
              onClick={this.onCancel}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button value={t(`${TranslationNamespaces.common}|Confirm`)} {...buttonProps} />
          </ButtonWrapper>
        </div>
      </div>
    );
  }
}

export default withLockDateCheck(withTranslation(TranslationNamespaces.punchesPage)(PunchesAddPopup));
