import { Component } from "react";
import { withTranslation } from "react-i18next";
import { copyTextToClipboard, getTitle, getSystemName } from "utils/common";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import { TranslationNamespaces } from "types/translationNamespaces";
import BEM from "../utils/BEM";
import * as images from "./svg-images";

const p = BEM.b("page");
const RefCode = styled.div`
  margin-top: 15px;
  font-size: var(--typography-font-size-default);
  color: ${(props) => props.theme.colors.text1};
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px dashed ${(props) => props.theme.colors.inputBorderDefault};
  position: relative;
  padding-inline-start: 15px;
  width: 270px;
`;
const CopyText = styled.div`
  font-size: var(--typography-font-size-default);
  font-weight: var(--typography-font-weight-bold);
  color: ${(props) => props.theme.colors.primary};
  position: absolute;
  ${rtl`
    right: 15px;
  `}
  cursor: pointer;
`;
const CopyButton = styled.button`
  margin-top: 16px;
  outline: none;
  margin-inline-start: 20px;
  background: no-repeat;
  border: none;
  cursor: pointer;
  border-radius: 20px;
`;
const RefWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ShareTitle = styled.div`
  font-size: 15px;
  font-weight: var(--typography-font-weight-bold);
`;

class Recommend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: "",
    };
  }

  render() {
    const { t } = this.props;

    const title = t("Share your love");

    return (
      <div>
        <Helmet>
          <title>{getTitle(t("Recommend {{systemName}} to a Friend", { systemName: getSystemName() }))}</title>
        </Helmet>
        <div className={p("header")}>
          <div className={p("title")}>{title}</div>
        </div>
        {/* {
              notification && <NotificationRow
                style={{marginBottom: "20px"}}
                type={notificationType}
                message={notification} />
            } */}
        {/* <Tabs
              tabs={tabs}
              value={activeTab}
              onChange={tab => this.setState({ activeTab: tab })}
            /> */}

        <div className={p("content")}>
          {/* <div>
                <div>{t("Invite E-mail")}</div>
              </div> */}
          <div>
            <ShareTitle>{t("Share your Invite Code")}</ShareTitle>
            <RefWrapper>
              <RefCode>
                {window.global_store.profile.referral_code}
                <CopyText onClick={() => copyTextToClipboard(window.global_store.profile.referral_code)}>
                  {t("Copy")}
                </CopyText>
              </RefCode>
              <CopyButton onClick={() => copyTextToClipboard(window.global_store.profile.referral_code)}>
                {images.chainButton}
              </CopyButton>
            </RefWrapper>
          </div>
          {/* { this.getContent(activeTab) } */}
        </div>
        {/* <ModalDialog isOpen={popupVisible} onClose={()=>this.setState({popupVisible: false})}>
              <PopupMessage onClose={()=>this.setState({popupVisible: false})} />
            </ModalDialog> */}
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.recommend)(Recommend);
