import React, { Component } from "react";
import "../../styles/code-confirmation.scss";
import BEM from "../../utils/BEM";

const b = BEM.b("code-confirmation");

class CodeConfirmation extends Component {
  static defaultProps = {
    count: 3,
    onFulfilled: () => {},
  };

  constructor(props) {
    super(props);
    let values = [...Array(this.props.count)].map(() => "");
    if (this.props.value) {
      values = this.props.value.toString().split("");
    }
    const state = {
      values,
    };
    this.state = state;
  }

  onChange(ev) {
    const value = ev.target.value.replace(/[^0-9]/g, "").substring(1, 0);
    this.setState({ value });
    // this.props.onChange && this.props.onChange(ev.target.value)
  }

  getValue(ev) {
    let str = ev.target.value;
    if (this.props.onlyDigits) {
      str = str.replace(/[^0-9]/g, "");
    }
    return str.substring(1, 0);
  }

  render() {
    const { values } = this.state;
    const { count, onFulfilled } = this.props;

    return (
      <div className={b()}>
        {values.map((el, i) => (
          <input
            // eslint-disable-next-line react/no-array-index-key
            key={`digit${i}`}
            className={b("input")}
            ref={(input) => {
              this[`digit${i}`] = input;
            }}
            disabled={this.props.disabled}
            type="text"
            maxLength={1}
            size={1}
            onKeyUp={(ev) => {
              if (!values[i] && ev.keyCode === 8 && this[`digit${i - 1}`]) {
                this[`digit${i - 1}`].focus();
              }
            }}
            onChange={(ev) => {
              const value = this.getValue(ev);
              const arr = values;
              arr[i] = value;
              this.setState({ values }, () => {
                if (value && this[`digit${i + 1}`]) {
                  this[`digit${i + 1}`].focus();
                }
                if (arr.filter((e) => e !== "").length === count) {
                  onFulfilled(values.join(""));
                }
              });
            }}
            value={values[i]}
          />
        ))}
      </div>
    );
  }
}
export default CodeConfirmation;
