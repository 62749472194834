import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getSelectedColumns, getTrPropsClassName } from "utils/tableHelpers";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import Pagination from "components/Pagination";
import StatusBadge from "components/controls/StatusBadge";
import { getEmployeeNameColumn, mapTableData } from "utils/reportsHelpers";
import { getEmployeeTaxPayerType } from "utils/common";
import { EmployeeTaxPayerTypes } from "types/common";
import BEM from "utils/BEM";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import ReportsNoContent from "./ReportsNoContent";
import "styles/reports-extra-hour-table.scss";
import { sliceReportIntoChunks } from "./helpers";

const b = BEM.b("reports-extra-hour-table");

class ReportsDigitalSignaturesAllEmployees extends Component {
  state = {
    employees: [[]],
    loading: false,
    selectedColumns: getSelectedColumns(
      "name,department,supervisor,matricula,ds_requested_at,ds_signed_at,ds_status",
      "ReportsDigitalSignaturesAllEmployees",
    ),
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const { perPage } = this.state;
    const {
      t,
      startDate,
      endDate,
      searchObj,
      showInactiveEmploees,
      skipSupervisors,
      onlyNonZeroLines,
      updateRecalculatingMessage,
      setIsLoading,
    } = this.props;

    const companyUUID = window.global_store.company.uuid;
    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, () => setIsLoading(true));

      try {
        const response = await getReport(
          {
            showInactiveEmploees,
            skipSupervisors,
            onlyNonZeroLines,
            groupUUID: searchObj.uuid || null,
            groupType: searchObj.type || null,
            startDate,
            endDate,
            companyUUID,
            oldReport: false,
            type: "digital_signatures_status",
          },
          true,
        );

        const resp = response.content;

        if (resp) {
          const allDaysCount = resp.length || 0;
          const completedDaysCount = resp.filter((d) => d.status === "completed").length;

          if (!resp.length) {
            updateRecalculatingMessage(false);
          } else {
            updateRecalculatingMessage(allDaysCount !== completedDaysCount || response?.metadata?.status === "pending");
          }
        }

        const employees = sliceReportIntoChunks(resp, perPage);

        this.setState({
          employees: employees.length ? employees : [[]],
          totalRecords: resp.length,
          error: false,
          loading: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({
          error: t("Failed to generate report"),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns() {
    const { t } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);

    const columns = [
      getEmployeeNameColumn(t),
      {
        accessor: "department",
        rubyAccessor: "department",
        label: t("Department"),
        minWidth: 100,
      },
      {
        accessor: "supervisorFullName",
        rubyAccessor: "supervisor",
        label: t("Supervisor"),
        minWidth: 100,
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
        minWidth: 100,
      },
      {
        accessor: "ds_requestedAt",
        rubyAccessor: "ds_requested_at",
        label: t("Requested Day"),
        Cell: (r) => <span>{r.value || ""}</span>,
        minWidth: 100,
      },
      {
        accessor: "ds_signedAt",
        rubyAccessor: "ds_signed_at",
        label: t("Signed Day"),
        Cell: (r) => <span>{r.value || ""}</span>,
        minWidth: 100,
      },
      {
        accessor: "ds_status",
        rubyAccessor: "ds_status",
        label: t("common|Status"),
        Cell: (row) => {
          const status = row.value;
          return <StatusBadge value={status === "pending" ? t("common|Pending") : t(status)} type={status} />;
        },
        minWidth: 100,
      },
    ];

    return columns;
  }

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsDigitalSignaturesAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onPageLimitChange = (pageLimit) => {
    const { employees } = this.state;

    this.setState({ page: 1, perPage: pageLimit, employees: sliceReportIntoChunks(employees.flat(), pageLimit) });
  };

  render() {
    const { error, employees, loading, selectedColumns, page, perPage, totalRecords } = this.state;
    const {
      t,
      selectedSignatureType,
      startDate,
      endDate,
      searchObj,
      skipSupervisors,
      onlyNonZeroLines,
      showInactiveEmploees,
      oldReport,
    } = this.props;

    if (error) {
      return <div>{error}</div>;
    }

    let data = mapTableData(
      ["fullName", "department", "supervisorFullName", "matricula", "digitalSignatureMetadata"],
      employees[page - 1],
    );
    const columns = this.getColumns();

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj && searchObj.type && searchObj.type !== "all" && searchObj.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    if (selectedSignatureType) {
      data = data.filter((item) => item.ds_status === selectedSignatureType);
    }

    return (
      <div className={b()}>
        <TablePage
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  searchObj,
                  startDate,
                  endDate,
                  showInactiveEmploees,
                  skipSupervisors,
                  onlyNonZeroLines,
                  oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "digital_signatures_status",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className: getTrPropsClassName(this.props, rowInfo),
          })}
          rows={data}
          columns={columns}
          loading={loading}
          interactive={false}
          className="reports-hoursbank-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("reports-page")(ReportsDigitalSignaturesAllEmployees));
