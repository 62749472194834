import Select from "components/UI/Select";
import { PunchKey, PunchType } from "types/models/punches";
import { AddPunchMappedEvent } from "types/models/shift";
import { TFunction, TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { getBreakName } from "components/Schedules/Breaks/utils";

export type PunchTypesDropdownOption = {
  label: string;
  value: PunchType | PunchKey;
  key: PunchType | PunchKey;
  type?: PunchType;
};

interface PunchTypesDropdownControlProps {
  events?: AddPunchMappedEvent[];
  value: AddPunchMappedEvent | PunchTypesDropdownOption | null;
  onChange: (punchType: PunchTypesDropdownOption | null) => void;
  placeholder: string;
  customBreaksNamesMap: Record<string, string>;
}

const getDefaultEvents = (customBreaksNamesMap: Record<string, string>, t: TFunction): PunchTypesDropdownOption[] => {
  const defaultPunchTypes = [
    { label: t(PunchType.entry), value: PunchType.entry, key: PunchType.entry },
    {
      label: getBreakName(null, PunchType.breakStart, customBreaksNamesMap),
      value: PunchType.breakStart,
      key: PunchType.breakStart,
    },
    {
      label: getBreakName(null, PunchType.breakEnd, customBreaksNamesMap),
      value: PunchType.breakEnd,
      key: PunchType.breakEnd,
    },
    { label: t(PunchType.exit), value: PunchType.exit, key: PunchType.exit },
  ];

  return defaultPunchTypes;
};

const PunchTypesDropdownControl = ({
  value,
  onChange,
  placeholder,
  events,
  customBreaksNamesMap,
}: PunchTypesDropdownControlProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);

  const options = events?.length ? events : getDefaultEvents(customBreaksNamesMap, t);

  return (
    <Select<PunchType | PunchKey | null>
      modifiers={{ field: true }}
      placeholder={placeholder}
      value={value ? value.value : null}
      onChange={(val) => onChange(options.find((pt) => pt.value === val) || null)}
      options={options}
    />
  );
};

export default PunchTypesDropdownControl;
