import { TFunction } from "react-i18next";
import Select from "components/UI/Select";
import { PunchReason } from "types/models/punches";

interface ReasonsDropdownControlProps {
  t: TFunction;
  onChange: (value: PunchReason) => void;
  value: PunchReason | null;
  placeholder: string;
}

const ReasonsDropdownControl = ({ t, value, onChange, placeholder }: ReasonsDropdownControlProps) => {
  const reasons = [
    { label: t("Change early leave"), value: PunchReason.earlyLeave },
    { label: t("Change late arrival"), value: PunchReason.lateArrival },
    { label: t("Forgot to Punch"), value: PunchReason.forgotToPunch },
    { label: t("Technical Problem"), value: PunchReason.technicalProblem },
    { label: t("Other"), value: PunchReason.other },
  ];

  return (
    <Select<PunchReason | null>
      modifiers={{ field: true }}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      options={reasons}
    />
  );
};

export default ReasonsDropdownControl;
