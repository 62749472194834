import styled from "styled-components";
import rtl from "styled-components-rtl";
import CheckboxControl from "components/UI/NewCheckbox";
import * as images from "components/svg-images";
import { iCellInfo } from "utils/tableHelpers";

const DateButton = styled.div`
  position: absolute;
  top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border-radius: 100%;
  background-color: #d7dee8;
  ${rtl`
    left: 10px;
  `}
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  &.checked:before {
    position: absolute;
    top: 0;
    height: 100%;
    content: "";
    border-inline-start: 4px solid var(--colors-primary);
    ${rtl`
      left: 0;
    `}
  }
`;

interface CellCheckboxProps<T = any> {
  row: iCellInfo<T>;
  checked: boolean;
  isCheckboxDisabled?: boolean;
  isRowLocked?: boolean;
  onChange: (checked: boolean, entityId: string, row: iCellInfo<T>) => void;
  entityIdKey?: string | null; // key for value that will be passed to onChange callback
}

// TODO move to TablePage
const CellCheckbox = <T extends Record<string, unknown>>({
  row,
  checked,
  isCheckboxDisabled,
  isRowLocked,
  onChange,
  entityIdKey = "uuid",
}: CellCheckboxProps<T>): JSX.Element => (
  <CheckboxWrapper
    className={checked ? "checked" : ""}
    onClick={(ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (!isRowLocked && !isCheckboxDisabled) {
        onChange(!checked, entityIdKey ? row.value[entityIdKey] : row.value, row);
      }
    }}
  >
    {isRowLocked ? (
      <DateButton>{images.lockedPadlock()}</DateButton>
    ) : (
      <CheckboxControl disabled={isCheckboxDisabled} checked={checked} onChange={() => {}} />
    )}
  </CheckboxWrapper>
);

export default CellCheckbox;
