import { Component } from "react";
import { withTranslation } from "react-i18next";
import { getCompanyRules, updateCompanyRules } from "utils/apiHelpers";
import BEM from "utils/BEM";
import "components/UI/Page/Page.scss";
import "styles/punches-page.scss";
import CodeConfirmation from "components/controls/CodeConfirmation";
import PunchTypeBox from "components/controls/PunchTypeBox";
import styled from "styled-components";
import CheckboxControl from "components/UI/NewCheckbox";
import SelectControl from "components/UI/SelectControl";
import FieldWrapper from "components/UI/FieldWrapper";
import { SpacerVertical } from "components/styled/Page";
import GlobalContext from "context/global-context";
import RichTooltip from "components/UI/RichTooltip";
import { getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import sentryUtils from "utils/sentryUtils";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import * as images from "../svg-images";

const cr = BEM.b("company-rules");

const PunchesTypes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 448px;
`;

const KioskIdSelectorWrapper = styled.div`
  max-width: 320px;
`;
const PunchesTypesTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  color: ${(props) => props.theme.colors.mainText};
  line-height: 26px;
  margin-bottom: 24px;
`;
const CheckboxRowWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`;
const AdvancedSettingBlockTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary};
  margin: 25px 0 10px 0;
`;

class PunchSettings extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      selectedKioskId: "cpf",
    };

    document.title = t("Punches - Punches Settings");
  }

  componentDidMount() {
    this.getState(this.props);

    if (this.props.setOnSaveFn) {
      this.props.setOnSaveFn({ fn: this.onNextClick, disabled: false });
    }
  }

  async getState() {
    const company = await this.context.getCompany();
    const brResponse = await getCompanyRules({ companyId: company.id });

    const businessRulesArray = brResponse.business_rules || [];
    const businessRules = {};
    businessRulesArray.map((br) => {
      businessRules[br.name] = br.value;
      return "";
    });

    this.setState({
      webPunchActive: businessRules.allow_web_punch,
      appPunchActive: businessRules.allow_mobile_punch,
      kioskPunchActive: businessRules.allow_tablet_punch,
      repPunchActive: businessRules.allow_rep_punch,
      askLateReason: businessRules.ask_late_reason,
      allowFaceRecognition: businessRules.allow_face_recognition,
      selectedKioskId: businessRules.tablet_id_mode || "cpf",
      allowOnlyManualPunchOnMobile: businessRules.allow_only_manual_punch_on_mobile,
      allowClearCompilation: businessRules.allow_clear_compilation,
      allowCustomLocation: businessRules.allow_custom_location,
    });
  }

  onNextClick = async () => {
    const {
      webPunchActive,
      kioskPunchActive,
      askLateReason,
      appPunchActive,
      allowFaceRecognition,
      selectedKioskId,
      allowOnlyManualPunchOnMobile,
      allowClearCompilation,
      allowCustomLocation,
    } = this.state;
    const { t, setNotification } = this.props;

    this.setState({ isLoading: true });

    if (this.props.setOnSaveFn) {
      this.props.setOnSaveFn({ fn: this.onNextClick, disabled: true });
    }

    const data = {
      companyId: window.global_store.company.id,
      body: {
        business_rules: {
          allow_web_punch: webPunchActive,
          allow_mobile_punch: appPunchActive,
          allow_tablet_punch: kioskPunchActive,
          ask_late_reason: askLateReason,
          allow_face_recognition: kioskPunchActive && allowFaceRecognition,
          tablet_id_mode: selectedKioskId || "cpf",
          allow_only_manual_punch_on_mobile: allowOnlyManualPunchOnMobile,
          allow_clear_compilation: allowClearCompilation,
          allow_custom_location: allowCustomLocation,
        },
      },
    };

    try {
      await updateCompanyRules(data);

      if (setNotification) {
        setNotification({ notification: t("Punching rules were updated"), notificationType: NotificationType.success });
      }
    } catch (e) {
      sentryUtils.sendError(e);
    } finally {
      this.setState({ isLoading: false });
      if (this.props.setOnSaveFn) {
        this.props.setOnSaveFn({ fn: this.onNextClick, disabled: false });
      }
    }
  };

  render() {
    const {
      isLoading,
      webPunchActive,
      kioskPunchActive,
      appPunchActive,
      repPunchActive,
      repPunchVisible,
      allowFaceRecognition,
      allowOnlyManualPunchOnMobile,
      allowClearCompilation,
      selectedKioskId,
      allowCustomLocation,
    } = this.state;
    const { t, setOnSaveFn } = this.props;

    let buttonProps = {};

    if (isLoading) {
      buttonProps = { disabled: "disabled" };
    }

    const tabletToken = window.global_store.company ? window.global_store.company.tablet_code : "";
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const kioskIdOptions = [
      { label: employeeTaxIdLabel, value: "cpf" },
      { label: "PIS", value: "pis" },
      { label: t("common|Matricula"), value: "matricula" },
    ];

    return (
      <div>
        <div>
          <>
            <PunchesTypesTitle>
              {translateEmployeeTerm(
                t,
                TranslationNamespaces.punchesPage,
                "custom-allow-users-to-punch",
                `Allow users to punch using:`,
              )}
            </PunchesTypesTitle>
            <PunchesTypes>
              <PunchTypeBox
                active={appPunchActive}
                onClick={(appPunchActive) => {
                  this.setState({ appPunchActive: !this.state.appPunchActive });
                }}
                type="app"
                label={t("App")}
              />
              <PunchTypeBox
                active={kioskPunchActive}
                onClick={(kioskPunchActive) => {
                  this.setState({ kioskPunchActive: !this.state.kioskPunchActive });
                }}
                type="tablet"
                label={t("Tablet")}
              />
              <PunchTypeBox
                active={webPunchActive}
                onClick={(webPunchActive) => {
                  this.setState({ webPunchActive: !this.state.webPunchActive });
                }}
                type="web"
                label={t("Web")}
              />
              {false && repPunchVisible ? (
                <PunchTypeBox
                  active={repPunchActive}
                  onClick={(repPunchActive) => {
                    this.setState({ repPunchActive: !this.state.repPunchActive });
                  }}
                  type="rep"
                  label={t("REP")}
                />
              ) : null}
            </PunchesTypes>
          </>

          <div className={cr("settings")}>
            {(kioskPunchActive || appPunchActive) && <div className={cr("separator")} />}
            {(kioskPunchActive || appPunchActive) && <PunchesTypesTitle>{t("Advanced Settings")}</PunchesTypesTitle>}
            {appPunchActive && (
              <>
                <AdvancedSettingBlockTitle>{t("App")}</AdvancedSettingBlockTitle>
                <CheckboxRowWrapper>
                  <CheckboxControl
                    checked={allowOnlyManualPunchOnMobile}
                    label={t("Allow punch adjustments only")}
                    onChange={(checked) => this.setState({ allowOnlyManualPunchOnMobile: checked })}
                  />
                </CheckboxRowWrapper>
                <CheckboxRowWrapper>
                  <CheckboxControl
                    checked={allowClearCompilation}
                    label={t("allow_clear_compilation")}
                    onChange={(checked) => this.setState({ allowClearCompilation: checked })}
                  />
                </CheckboxRowWrapper>
                <CheckboxRowWrapper>
                  <CheckboxControl
                    checked={allowCustomLocation}
                    label={t("allow_custom_location")}
                    onChange={(checked) => this.setState({ allowCustomLocation: checked })}
                  />
                  <RichTooltip text={t("allow_custom_location-tooltip")} />
                </CheckboxRowWrapper>
              </>
            )}

            {kioskPunchActive && <AdvancedSettingBlockTitle>{t("Tablet")}</AdvancedSettingBlockTitle>}
            {kioskPunchActive && tabletToken && (
              <div className={cr("field")}>
                <div className={cr("field-title", { token: true })} style={{ color: "#8093AC" }}>
                  {t("Tablet token")}
                </div>
                <CodeConfirmation disabled value={tabletToken} />
              </div>
            )}
            {kioskPunchActive && (
              <div className={cr("field")}>
                <div className={cr("field-title", { token: true })} style={{ marginBottom: "10px" }}>
                  {t("Download Tablet app")}:
                </div>
                <div className={cr("tablets")}>
                  <a
                    className={cr("download-tablet")}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/br/app/tablet-de-controle-de-ponto/id1457971447"
                  >
                    {images.appleLogo}
                    <span>Apple Store</span>
                  </a>
                  <a
                    className={cr("download-tablet")}
                    href="https://play.google.com/store/apps/details?id=com.oitchau.tablet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {images.googleLogo}
                    <span>Google Play</span>
                  </a>
                </div>
              </div>
            )}
            {kioskPunchActive && <SpacerVertical height={18} />}
            {kioskPunchActive && (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <KioskIdSelectorWrapper>
                  <FieldWrapper fieldName={t("Kiosk Access ID")} width="320px">
                    <SelectControl
                      options={kioskIdOptions}
                      onChange={(val) => {
                        this.setState({ selectedKioskId: val });
                      }}
                      isLoading={isLoading}
                      value={selectedKioskId}
                      isSearchable
                    />
                  </FieldWrapper>
                </KioskIdSelectorWrapper>
                <div style={{ marginBottom: "8px", marginInlineStart: "12px" }}>
                  <RichTooltip text={t("kiosk-access-id-info")} />
                </div>
              </div>
            )}
            {kioskPunchActive && (
              <CheckboxRowWrapper>
                <CheckboxControl
                  checked={allowFaceRecognition}
                  label={t("Enable Facial Biometry")}
                  onChange={(checked) => this.setState({ allowFaceRecognition: checked })}
                />
              </CheckboxRowWrapper>
            )}
          </div>

          {!setOnSaveFn && (
            <>
              <SpacerVertical height={56} />

              <div
                style={{ width: "132px" }}
                className={`button-next ${buttonProps.disabled ? "button-next_disabled" : ""}`}
              >
                <input
                  value={t("Save")}
                  type="button"
                  className={cr("button", { full: true })}
                  onClick={this.onNextClick.bind(this)}
                  {...buttonProps}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.punchesPage)(PunchSettings);
