import { StoreApi, UseBoundStore } from "zustand";
import { Popup, Toast } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useCallback, useEffect, useState } from "react";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import moment from "moment";
import { minsToHrsMins } from "utils/common";
import SafeTimeInput from "components/views/SafeTimeInput";
import styled from "styled-components";
import {
  DatePickerCommonOverrides,
  CalendarInsideNext,
  CelandarInsidePrev,
} from "components/controls/DatePicker/styled";
import {
  isDateAfterToday,
  isDateInLockedTimesheetRanges,
  isLockedByPayroll,
  parseTime,
  validateTimeValue,
} from "../time-tracker.utils";
import { TrackTimeStore } from "../TrackTime.store";
import { DurationInput } from "./DurationInput";

const DateTimeRow = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 0 20px 12px 8px;
  border-bottom: 1px solid var(--colors-surface-150);

  .Date {
    color: var(--colors-surface-900-p);
    font-size: var(--typography-font-size-default);
    padding: 0 12px;
    &.Error {
      color: var(--colors-danger-600-p);
    }
  }
  .TimeSelector {
    display: flex;
    align-items: center;
    gap: 4px;
    .TimeSelect {
      border: none;
      background: none;
      margin: 0;
      width: 80px;
      padding: 0 12px;
      text-align: center;
      &:hover {
        border: 1px solid var(--colors-surface-400);
      }
      &:focus {
        border: 2px solid var(--colors-primary-500-p);
      }
    }
  }
  .Calendar {
    width: 100%;
    .DayPickerNavigation_button {
      border: none;
    }
    .CalendarMonthGrid__horizontal {
      left: -2px;
    }
    .CalendarMonth_caption {
      margin-left: -11px;
    }
    .DayPicker_weekHeader {
      margin-left: -11px;
    }
    .DayPickerNavigation__horizontal {
      left: -11px;
    }
    .CalendarDay__selected {
      border-radius: var(--shapes-border-radius-default);
      background: var(--colors-primary-50);
      color: var(--colors-primary-500-p);
    }
    .CalendarDay__today {
      font-weight: var(--typography-font-weight-bold);
    }
    .DayPicker_weekHeader_li {
      font-size: 11px;
      font-weight: var(--typography-font-weight-default);
      letter-spacing: 0.5px;
    }
  }
`;
export const DatePickerPopup = ({
  isRunning,
  inModal,
  withDurationInput,
  allowFutureActivities,
  store,
}: {
  isRunning: boolean;
  inModal?: boolean;
  withDurationInput?: boolean;
  allowFutureActivities: boolean;
  store: UseBoundStore<StoreApi<TrackTimeStore>>;
}) => {
  const { t } = useTranslation(TranslationNamespaces.activities);
  const [isToastOpen, setToastOpen] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>();
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [anchor, setAnchor] = useState<EventTarget>();
  const [focusedDay, setFocusedDay] = useState<FocusedInputShape | null>("startDate");
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    startDate,
    endDate,
    setEndDate,
    timesheets,
    setStartDate,
    setTouched,
    activity,
    setDuration,
    duration,
    changeDuration,
  } = store();

  const getDate = (date: moment.Moment | null) => {
    if (!date) return "-";
    if (date?.isSame(moment(), "day")) return t("Today");
    return date?.format("DD MMM");
  };

  const closeToast = useCallback(() => {
    setToastOpen(false);
  }, []);

  const isDayBlocked = (date: moment.Moment) =>
    (allowFutureActivities && isDateAfterToday(date)) ||
    isLockedByPayroll(date, window.global_store.profile.last_lock_date) ||
    isDateInLockedTimesheetRanges(date, timesheets);

  const isDateRangeValid = (startTimeV: number | undefined, endTimeV: number | undefined) =>
    startDate &&
    endDate &&
    ((startDate.isBefore(endDate, "day") && Number.isInteger(startTimeV) && Number.isInteger(endTimeV)) ||
      (startDate.isSame(endDate, "day") &&
        Number.isInteger(startTimeV) &&
        Number.isInteger(endTimeV) &&
        startTimeV <= endTimeV));

  const onStartTimeChange = (val: string) => {
    const error = validateTimeValue(val);
    if (error) {
      setStartTime(`${startTime} `);
      setToastText(t(error));
      setToastOpen(true);
      return minsToHrsMins(startTime);
    }

    const startTimeNum = parseTime(val);
    if (startTime && endTime && !isRunning && !isDateRangeValid(startTimeNum, endTime)) {
      setStartTime(startTime);
      return minsToHrsMins(startTime);
    }
    if (startTimeNum !== startTime) {
      setStartTime(startTimeNum);
      setTouched(true);
    }

    return val;
  };

  const onEndTimeChange = (val: string) => {
    const error = validateTimeValue(val);
    if (error) {
      setEndTime(`${endTime} `);
      setToastText(t(error));
      setToastOpen(true);
      return minsToHrsMins(endTime);
    }
    const endTimeNum = parseTime(val);

    if (startTime && !isDateRangeValid(startTime, endTimeNum)) {
      setEndTime(endTime!);
      return minsToHrsMins(endTime);
    }
    if (endTimeNum !== endTime) {
      setEndTime(endTimeNum);
      setTouched(true);
    }

    return val;
  };

  useEffect(() => {
    if (isDateRangeValid(startTime, endTime)) {
      const dur = endDate
        ?.clone()
        .startOf("day")
        .add(endTime, "minutes")
        .diff(startDate?.clone().startOf("day").add(startTime, "minutes"), "minutes");
      setDuration(dur);
    } else if (!isRunning) {
      setTouched(false);
      setDuration(0);
    }
  }, [startDate, startTime, endDate, endTime]);

  return (
    <>
      <DateTimeRow>
        <div
          className={`Date ${!isRunning && inModal ? "with-hover" : ""}`}
          onClick={(event) => {
            if (!isRunning && inModal) {
              setAnchor(event.target);
              setFocusedDay("startDate");
              setShowDatepicker(!showDatepicker);
            }
          }}
        >
          {getDate(startDate!)}
        </div>
        <div className="TimeSelector">
          <SafeTimeInput
            placeholder="00:00"
            className="TimeSelect time-control"
            value={startTime ? minsToHrsMins(startTime) : undefined}
            onChangeFinished={(value: string) => onStartTimeChange(value)}
          />
          <span>-</span>
          {!isRunning && (
            <div
              className={`Date ${inModal ? "with-hover" : ""} ${!endDate ? "Error" : ""}`}
              onClick={(event) => {
                if (!isRunning && inModal) {
                  setAnchor(event.target);
                  setFocusedDay("endDate");
                  setShowDatepicker(!showDatepicker);
                }
              }}
            >
              {getDate(endDate!)}
            </div>
          )}
          <SafeTimeInput
            disabled={isRunning}
            placeholder={isRunning ? "--:--" : "00:00"}
            className="TimeSelect time-control"
            value={endTime && !isRunning ? minsToHrsMins(endTime) : undefined}
            onChangeFinished={(value) => onEndTimeChange(value)}
          />
        </div>
        {withDurationInput ? (
          <DurationInput
            className="DurationSelect time-control"
            placeholder="0:00"
            value={duration ? minsToHrsMins(duration, true) : ""}
            onChange={changeDuration}
          />
        ) : null}
      </DateTimeRow>
      {!isRunning && !inModal && (
        <DatePickerCommonOverrides className="Calendar">
          <DayPickerRangeController
            isRTL={window.global_store.isRTL}
            minimumNights={0}
            startDate={startDate}
            endDate={endDate}
            daySize={40}
            hideKeyboardShortcutsPanel
            navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
            navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
            weekDayFormat="ddd"
            monthFormat="MMMM YYYY"
            noBorder
            isOutsideRange={isDayBlocked}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setTouched(true);
            }}
            focusedInput={focusedDay}
            onFocusChange={(focusedInput) => {
              setFocusedDay(focusedInput || "startDate");
            }}
            initialVisibleMonth={() => (activity?.date ? moment(activity.date) : moment().subtract(1, "months"))}
            numberOfMonths={2}
          />
        </DatePickerCommonOverrides>
      )}
      {inModal && !isRunning && showDatepicker && (
        <Popup
          display="anchored"
          anchor={anchor}
          showArrow={false}
          scrollLock={false}
          isOpen={showDatepicker}
          height={300}
          onClose={() => {
            setShowDatepicker(false);
          }}
          cssClass="mbsc-no-padding PopupWrapper CalendarPopup"
        >
          <DatePickerCommonOverrides className="Calendar">
            <DayPickerRangeController
              isRTL={window.global_store.isRTL}
              minimumNights={0}
              startDate={startDate}
              endDate={endDate}
              daySize={40}
              hideKeyboardShortcutsPanel
              navNext={window.global_store.isRTL ? <CelandarInsidePrev /> : <CalendarInsideNext />}
              navPrev={window.global_store.isRTL ? <CalendarInsideNext /> : <CelandarInsidePrev />}
              weekDayFormat="ddd"
              monthFormat="MMMM YYYY"
              noBorder
              isOutsideRange={isDayBlocked}
              onDatesChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setTouched(true);
              }}
              focusedInput={focusedDay}
              onFocusChange={(focusedInput) => {
                setFocusedDay(focusedInput);
                if (!focusedInput) {
                  setShowDatepicker(false);
                }
              }}
              initialVisibleMonth={() => (activity?.date ? moment(activity.date) : moment())}
              numberOfMonths={1}
            />
          </DatePickerCommonOverrides>
        </Popup>
      )}
      <Toast
        theme="ios"
        themeVariant="light"
        message={toastText}
        isOpen={isToastOpen}
        onClose={closeToast}
        display="top"
      />
    </>
  );
};
