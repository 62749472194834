import { TwoFieldsWrapper } from "components/Reports/styled";
import styled from "styled-components";

// CreateLayout.tsx
export const PageWrapper = styled.div`
  margin-top: 31px;
`;

export const MainFields = styled.div`
  width: 100%;
  max-width: 416px;
`;

export const Subsection = styled.div`
  width: 100%;
  max-width: 516px;
`;

export const MultiFieldWrapper = styled(TwoFieldsWrapper)`
  justify-content: flex-start;

  & > * {
    margin-inline-end: 20px;
  }
`;

export const FieldHalfWidth = styled.div`
  width: 48%;
`;

export const IdFieldWrapper = styled.div`
  width: 28%;
`;

export const DateFieldWrapper = styled.div`
  width: 68%;
`;

export const EventTypeFieldWrapper = styled.div`
  width: 20%;
`;

export const Subtitle = styled.div`
  margin-top: 39px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  color: var(--colors-surface-900-p);
  line-height: 26px;
`;

export const AddRemoveLinkWrapper = styled.div`
  margin-top: 20px;
`;

export const RemoveOption = styled.div`
  cursor: pointer;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 19px;
  inset-inline-end: -30px;
  bottom: 10px;
  &:hover {
    background: var(--colors-buttonBgColor2);
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 63px;
  max-width: 150px;
`;

export const DeleteLink = styled.button`
  margin-top: 63px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary-500-p);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;

export const HoursSeparator = styled.div`
  margin-top: 10px;
  width: 36px;
`;
