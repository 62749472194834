/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
import { ChangeEventHandler, FocusEvent, MouseEventHandler, useContext, useEffect, useState } from "react";
import BEM from "utils/BEM";
import "styles/signup.scss";
import "styles/invite-employees.scss";
import { useTranslation } from "react-i18next";
import { checkForExistingUserProfilesWithinCompany, isPhoneNumberExist, sendInvites } from "utils/apiHelpers";
import styled from "styled-components";
import { getSystemName, validatePhoneNumber } from "utils/common";
import GlobalContext from "context/global-context";
import moment from "moment";
import TypeSwitch from "components/UI/TypeSwitch";
import { TranslationNamespaces } from "types/translationNamespaces";
import { EmployeeExistsCheckResult } from "types/models/employee";
import ga from "utils/ga";
import { translateEmployeeTerm } from "utils/translationHelpers";
import CONFIG from "../config";
import * as images from "./svg-images";
import PhoneNumberField from "./PhoneNumberField";
import NotificationRow from "./NotificationRow";
import AssociateEmployeeToProduct from "./Employees/AssociateEmployeeToProduct";
import { Feature } from "../types/models/subscription";
import { iOption } from "./UI/FormSelect";
import { useAsyncCallback } from "../utils/useAsyncEffect";
import { AllowedAddFreeEmployees } from "./Billing/BillingService";
import { SubscriptionContext } from "./Billing/context/subscription-context.provider";

const b = BEM.b("invite-employyes");
const Wrapper = styled.div`
  .invite-employyes__remove-employee {
    inset-inline-end: 12px;
  }
  .invite-employyes__add-employee svg {
    inset-inline-start: 0;
  }
`;

const input = BEM.b("input");

type Employee = {
  name: { value: string; error: string };
  phoneNumber: { value: string; error: string };
  email: { value: string; error: string };
  associate_products?: Feature[];
  key: number;
};

type EmployeeRowProps = {
  employee: Employee;
  inviteByEmail: boolean;
  onPhoneNumberBlur: (ev: FocusEvent<HTMLInputElement>) => void;
  onEmailBlur: (ev: FocusEvent<HTMLInputElement>) => void;
  onEmailChange: ChangeEventHandler<HTMLInputElement>;
  hasRemoveButton: boolean;
  onRemoveEmployee: MouseEventHandler<HTMLSpanElement>;
  onNameChange: ChangeEventHandler<HTMLInputElement>;
  onPhoneNumberChange: (value: string, country?: string) => void;
  countryCode: string;
};

const EmployeeRow = ({
  employee,
  inviteByEmail,
  onPhoneNumberBlur,
  onEmailBlur,
  onEmailChange,
  hasRemoveButton,
  onRemoveEmployee,
  onNameChange,
  onPhoneNumberChange,
  countryCode,
}: EmployeeRowProps) => {
  const { t } = useTranslation(TranslationNamespaces.signup);

  return (
    <div className={b("row")}>
      <div className={b("col")}>
        <div>
          <label className="label">{t("Full Name")}</label>
        </div>
        <div className={b("input-wrapper")}>
          <input
            autoComplete="off"
            className={input({
              error: employee.name.error,
            })}
            type="text"
            size={50}
            value={employee.name.value}
            onChange={onNameChange}
            placeholder={t("Full Name")}
          />
          {employee.name.error && <span className="field-error">{employee.name.error}</span>}
        </div>
      </div>
      <div className={b("col")}>
        <div>
          <label className="label">{inviteByEmail ? t("E-mail") : t("Mobile phone")}</label>
        </div>
        <div className={b("input-wrapper")}>
          {inviteByEmail ? (
            <input
              className={input({ error: employee.email.error })}
              placeholder={t(`${TranslationNamespaces.common}|email-placeholder`)}
              type="email"
              name="email"
              value={employee.email.value}
              onChange={onEmailChange}
              onBlur={onEmailBlur}
            />
          ) : (
            <PhoneNumberField
              newField
              countryCode={countryCode}
              autoComplete="off"
              isValid={!employee.phoneNumber.error}
              onBlur={onPhoneNumberBlur}
              value={employee.phoneNumber.value}
              onPhoneChange={onPhoneNumberChange}
            />
          )}
          {hasRemoveButton && (
            <span className={b("remove-employee")} onClick={onRemoveEmployee}>
              {images.removeIcon()}
            </span>
          )}
        </div>
        {!inviteByEmail && employee.phoneNumber.error && (
          <span className="field-error">{employee.phoneNumber.error}</span>
        )}
        {inviteByEmail && employee.email.error && <span className="field-error">{employee.email.error}</span>}
      </div>
    </div>
  );
};

const InviteEmployees = () => {
  const context = useContext(GlobalContext);
  const subscriptionContext = useContext(SubscriptionContext);
  const { t } = useTranslation(TranslationNamespaces.signup);
  const [activeType, setActivityType] = useState<"mobile" | "email">("mobile");
  const [associateProducts, setAssociateProducts] = useState<Feature[]>([]);
  const [associatedProductsError, setAssociatedProductsError] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string>(CONFIG.globalMode ? "us" : "br");
  const [responseError, setResponseError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [getSeatsData, loadingSeats] = useAsyncCallback(async () => {
    const company = await context.getCompany();
    if (company?.default_location?.country) {
      setCountryCode(company?.default_location.country.toLowerCase());
    }
    await subscriptionContext.init();
    const result = subscriptionContext.getAvailableSeats();
    const addInitSeats = (res: AllowedAddFreeEmployees) => {
      const minSeats = Math.min(...(Object.values(res).filter((v) => typeof v === "number") as number[])) || 5;
      const localEmployees = [...employees];
      const timestamp = moment().valueOf();

      for (let i = 1; i <= minSeats && i <= 3; i += 1) {
        localEmployees.push({
          name: { value: "", error: "" },
          phoneNumber: { value: "", error: "" },
          email: { value: "", error: "" },
          key: timestamp + i,
        });
      }
      setEmployees(localEmployees);
    };

    if (employees.length === 0) {
      addInitSeats(result!);
    }
    return result;
  }, []);

  useEffect(() => {
    void getSeatsData();
  }, []);

  const onChange = (i: number, field: "email" | "phoneNumber" | "name", value: string) => {
    const localEmployees = [...employees];
    const employee = localEmployees[i];
    employee[field].value = value;
    setEmployees(localEmployees);
  };

  const addEmptyEmployee = () => {
    const localEmployees = [...employees];
    const timestamp = moment().valueOf();
    setEmployees([
      ...localEmployees,
      {
        name: { value: "", error: "" },
        phoneNumber: { value: "", error: "" },
        email: { value: "", error: "" },
        key: timestamp,
      },
    ]);
  };

  const changedSeats = (products: Feature[], seatShift = 0) =>
    products.reduce((acc, item) => {
      acc[item] = employees.length + seatShift;
      return acc;
    }, {} as Record<Feature, number>);

  const onAddEmployee = async () => {
    const valid = await subscriptionContext.checkAvailableSeats(true, changedSeats(associateProducts, 1));
    if (valid) {
      addEmptyEmployee();
    }
    return null;
  };

  const onRemoveEmployee = (employee: Employee) => {
    setEmployees(employees.filter((e) => e.key !== employee.key));
  };

  const submitForm = (data: Employee[]) => {
    setLoading(true);

    const payload = data.map(({ key, ...employee }) => employee);
    sendInvites(payload, activeType)
      .then(() => {
        ga.trackAddEmployees(employees.length);
        window.location.href = "/employees";
      })
      .catch((err) => {
        setResponseError(err);
        setLoading(false);
      });
  };

  const validateEmail = (email: string): string => {
    let error = "";
    const validate = (str: string) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(str);
    };
    if (!email) {
      error = t("Email Can't be Empty");
    } else if (!validate(email)) {
      error = t("Incorrect email");
    } else {
      error = "";
    }
    return error;
  };

  const onNextClick = () => {
    let valid = true;
    setResponseError(null);
    if (associateProducts.length === 0) {
      setAssociatedProductsError(t("select_subscription_products"));
      valid = false;
    }

    const localEmployees = employees.map((e) => {
      if (!e.name.value && !e.email.value && !e.phoneNumber.value) {
        e.name.error = "";
        e.email.error = "";
        e.phoneNumber.error = "";
        return e;
      }

      if (!e.name.value) {
        e.name.error = t("Please Enter Full Name");
        valid = false;
      } else {
        e.name.error = "";
      }
      if (activeType === "mobile") {
        const validationError = validatePhoneNumber(e.phoneNumber.value, false);
        if (validationError) {
          e.phoneNumber.error = t(validationError);
          valid = false;
        } else if (e.phoneNumber.error === t("Phone number is alredy exists")) {
          valid = false;
        } else {
          e.phoneNumber.error = "";
        }
      } else if (activeType === "email" && validateEmail(e.email.value)) {
        e.email.error = validateEmail(e.email.value);
        valid = false;
      } else if (activeType === "email" && e.email.error === t("Employee with this email is already registered")) {
        valid = false;
      }
      e.associate_products = associateProducts;
      return e;
    });

    const validEmployees = employees.filter((e) => e.email.value || e.name.value || e.phoneNumber.value);
    if (validEmployees.length === 0) {
      valid = false;
      setResponseError(
        translateEmployeeTerm(
          t,
          TranslationNamespaces.signup,
          "custom-needs-at-least-one-employee",
          `Need to invite at least 1 employee`,
        ),
      );
    }
    if (valid) {
      setResponseError(null);
      submitForm(validEmployees);
    } else {
      setEmployees(localEmployees);
    }
  };

  const onPhoneNumberBlur = (key: number) => {
    const localEmployees = [...employees];
    const employee = localEmployees.find((e) => e.key === key);

    if (employee && employee.phoneNumber.value.length > 7) {
      void isPhoneNumberExist({
        phoneNumber: employee.phoneNumber.value,
      }).then((r) => {
        // if row still exists
        if (employee) {
          if (r) {
            employee.phoneNumber.error = t("Phone number is alredy exists");
          } else {
            employee.phoneNumber.error = "";
          }
          setEmployees(localEmployees);
        }
      });
    }
  };

  const onEmailBlur = (key: number) => {
    const localEmployees = [...employees];
    const employee = localEmployees.find((e) => e.key === key)!;

    if (!validateEmail(employee.email.value)) {
      void checkForExistingUserProfilesWithinCompany({
        body: {
          emails: [employee.email.value],
        },
      }).then((results) => {
        // if row still exists
        if (employee) {
          if (
            results?.emails?.length &&
            results.emails.some((e) => e[employee.email.value] === EmployeeExistsCheckResult.inUse)
          ) {
            employee.email.error = t("Employee with this email is already registered");
          } else {
            employee.email.error = "";
          }
        }
        setEmployees(localEmployees);
      });
    }
  };

  const inviteTypeChange = (actType: iOption<string, string>) => {
    setActivityType(actType.value as "email" | "mobile");
  };

  const buttonProps = {
    disabled: loading ? "disabled" : false,
  };

  return (
    <Wrapper>
      {responseError && <div className={b("response-error", { visible: true })}>{responseError}</div>}
      <NotificationRow
        onboardingMargin
        message={translateEmployeeTerm(
          t,
          TranslationNamespaces.signup,
          "custom-your-employess-will-be-invited",
          "Your employess will be invited by {{method}} with a link to download the app {{systemName}}",
          {
            method: activeType === "mobile" ? "SMS" : "e-mail",
            systemName: getSystemName(),
          },
        )}
        icon={images.notificationPaperPlane}
      />
      <div style={{ marginBottom: "26px" }}>
        <TypeSwitch
          options={[
            { label: t("Mobile"), value: "mobile" },
            { label: t("E-mail"), value: "email" },
          ]}
          value={activeType}
          onChange={inviteTypeChange}
        />
      </div>
      {!loadingSeats ? (
        <AssociateEmployeeToProduct
          error={associatedProductsError}
          associateProducts={associateProducts}
          setAssociateProducts={async (features) => {
            const valid = await subscriptionContext.checkAvailableSeats(true, changedSeats(features));
            if (valid) setAssociateProducts(features);
          }}
          isUpgrade={false}
        />
      ) : null}
      <div>
        {employees.map((e, i) => (
          <EmployeeRow
            countryCode={countryCode}
            key={e.key}
            inviteByEmail={activeType === "email"}
            hasRemoveButton={employees.length > 1}
            employee={e}
            onRemoveEmployee={() => onRemoveEmployee(e)}
            onNameChange={(ev) => onChange(i, "name", ev.target.value)}
            onPhoneNumberBlur={() => onPhoneNumberBlur(e.key)}
            onPhoneNumberChange={(value) => onChange(i, "phoneNumber", value)}
            onEmailBlur={() => onEmailBlur(e.key)}
            onEmailChange={(ev) => onChange(i, "email", ev.target.value.toLowerCase())}
          />
        ))}
        <div className={b("add-employee")}>
          <span onClick={onAddEmployee}>
            {images.addIcon}{" "}
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.signup,
              "custom-add-another-employee",
              "Add another employee",
            )}
          </span>
        </div>
      </div>
      <div className={`button-next ${buttonProps.disabled ? "button-next_disabled" : ""} button-next_invite-employees`}>
        <input value={t("Send Invites")} type="button" className={b("button")} onClick={onNextClick} {...buttonProps} />
      </div>
    </Wrapper>
  );
};

export default InviteEmployees;
