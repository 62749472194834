import { ComponentType, useState } from "react";
import Lightbox from "components/Lightbox";
import ModalDialog from "components/UI/ModalDialog";
import { useTranslation } from "react-i18next";

export function withLockDateCheck<T>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const { t } = useTranslation("payment");

    return (
      <>
        <WrappedComponent checkLockDate={() => setPopupVisible(true)} {...props} />
        <ModalDialog isOpen={popupVisible} onClose={() => setPopupVisible(false)}>
          <Lightbox
            title={t("User is locked")}
            text={t("You can't make changes because user is locked")}
            buttonYesTitle="Ok"
            noCancelButton
            onClose={() => {
              setPopupVisible(false);
            }}
            onYes={() => {
              setPopupVisible(false);
            }}
          />
        </ModalDialog>
      </>
    );
  };
}
