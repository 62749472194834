import { Component, ReactNode, MouseEventHandler, MouseEvent } from "react";
import { Link } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import Spinner from "@atlaskit/spinner";

export enum ButtonState {
  secondary = "secondary",
  cancel = "cancel",
  outline = "outline",
  recommend = "recommend",
  enableDay = "enable-day",
  success = "success",
  attention = "attention",
  primary = "primary",
}

const Loader = styled.div`
  position: absolute;
  inset-inline-end: 12px;
`;

interface ButtonBaseProps {
  className: string;
  children: ReactNode;
  url?: string;
  type: "submit" | "reset" | "button" | undefined;
  state: ButtonState;
  disabled: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onDoubleClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  style: CSSProperties;
}

const ButtonBase = ({ children, url, ...props }: ButtonBaseProps) => {
  if (url) {
    return (
      <Link to={url} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button onClick={() => {}} {...props}>
      {children}
    </button>
  );
};

const Button = styled(ButtonBase)<{ state: ButtonState }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  background: ${(props) => {
    switch (props.state) {
      case "secondary":
      case "outline":
      case "enable-day":
        return `var(--colors-surface-0)`;
      case "cancel":
        return `var(--colors-danger-500)`;
      case "success":
        return `var(--colors-success-600-p)`;
      case "recommend":
        return "none";
      case "attention":
        return `var(--colors-attention)`;

      default:
        return `var(--colors-primary-500-p)`;
    }
  }};
  border-radius: var(--shapes-border-radius-default);
  font-weight: ${(props) => {
    switch (props.state) {
      case "secondary":
      case "cancel":
        return "500";
      case "outline":
      case "recommend":
      case "enable-day":
        return "400";

      default:
        return "700";
    }
  }};
  font-size: var(--typography-font-size-default);
  letter-spacing: 0;
  text-align: center;
  line-height: var(--typography-line-height-default);
  border-style: solid;
  border-width: var(--shapes-border-width-default);
  outline: none;
  border-color: ${(props) => {
    switch (props.state) {
      case "secondary":
        return `var(--colors-primary-500-p)`;
      case "recommend":
        return `var(--colors-recommend)`;
      case "outline":
        return `var(--colors-surface-200)`;
      case "enable-day":
        return `var(--colors-buttonBorderColor)`;

      default:
        return "transparent";
    }
  }};
  color: ${(props) => {
    switch (props.state) {
      case "secondary":
        return `var(--colors-primary-500-p)`;
      case "recommend":
        return `var(--colors-recommend)`;
      case "enable-day":
        return `var(--colors-surface-800)`;
      case "outline":
        return `var(--colors-outlinetext)`;

      default:
        return `var(--colors-surface-0)`;
    }
  }};
  ${(props) => (props.state === "enable-day" ? "padding: 0px 10px;" : "")}

  ${(props) =>
    !props.disabled
      ? `
        cursor: pointer;
        &:hover{
        ${
          props.state === "primary"
            ? `box-shadow: 0 3px 8px 0 color-mix(in srgb, var(--colors-primary), transparent 62%);`
            : ""
        }
      };
    `
      : "opacity: 0.4;"}
`;

export interface StyledButtonProps {
  state: ButtonState;
  value: ReactNode;
  type: "submit" | "reset" | "button" | undefined;
  style: CSSProperties;
  onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  disabled?: boolean;
  loading?: boolean;
  onboarding?: boolean;
  url?: string;
  className?: string;
}

class StyledButton extends Component<StyledButtonProps> {
  static defaultProps = {
    state: ButtonState.primary,
    value: "",
    type: "",
    style: {},
    onClick: (): void => {},
  };

  onClick = (ev: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
    this.props.onClick(ev);
  };

  render(): JSX.Element {
    const { value, type, state, disabled, url, loading, onboarding, className } = this.props;
    const style: CSSProperties = onboarding
      ? {
          height: "50px",
          borderRadius: "61px",
          textTransform: "uppercase",
          paddingRight: "47px",
          paddingLeft: "47px",
          ...this.props.style,
        }
      : this.props.style;

    return (
      <Button
        className={`styled-button${className ? ` ${className}` : ""}`}
        type={type}
        url={url}
        state={state}
        disabled={!!(disabled || loading)}
        onClick={!disabled && !loading ? this.onClick : undefined}
        onDoubleClick={!disabled && !loading ? this.onClick : undefined}
        style={style}
      >
        {value}
        {loading && (
          <Loader>
            <Spinner size="small" />
          </Loader>
        )}
      </Button>
    );
  }
}

export default StyledButton;
