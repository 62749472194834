import { Component } from "react";
import Select, { CreatableSelect, components, MenuListComponentProps } from "@atlaskit/select";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { FocusEventHandler } from "react-select";
import CheckIcon from "@iconscout/react-unicons/icons/uil-check";
import EyeIcon from "@iconscout/react-unicons/icons/uil-eye";
import ViewPunchIconButton from "components/controls/IconButton/ViewPunchIconButton";

const Wrapper = styled.div`
  .react-select__control,
  .react-select__control:hover {
    background: var(--colors-surface-0);
    border: 1px solid var(--colors-surface-150);
    border-radius: var(--shapes-border-radius-default);
    min-height: 34px;
  }
  .react-select__value-container {
    padding: 2px 16px;
    position: relative;
  }
  .react-select__single-value {
    font-style: normal;
    font-family: var(--typography-font-family-default);
    font-weight: var(--typography-font-weight-default);
    font-size: 15px;
    line-height: 19px;

    color: var(--colors-surface-900-p);
  }
  .react-select__placeholder {
    font-style: normal;
    font-weight: var(--typography-font-weight-default);
    font-size: 15px;
    line-height: 19px;
    color: var(--colors-surface-400);
  }
  .react-select__indicator {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .react-select__option {
    border-radius: var(--shapes-border-radius-default);
    font-size: var(--typography-font-size-default);
    line-height: 24px;
    position: relative;
    &:hover {
      .select-option-view {
        display: flex;
      }
    }
  }
  .react-select__option:hover,
  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background: var(--colors-surface-50);
    cursor: pointer;
  }
  .react-select__menu-notice--no-options {
  }
  .react-select__menu-list {
    padding: 6px 12px;
  }
  &.has-value .react-select__control {
    border: 1px solid var(--colors-surface-400);
  }
  .react-select__control.react-select__control--is-focused {
    border: 1px solid var(--colors-primary-500-p);
  }
  &.onboarding.has-value .react-select__control {
    border: 2px solid var(--colors-surface-400);
  }
  &.onboarding .react-select__control--is-focused {
    border: 2px solid var(--colors-primary-500-p) !important;
  }
  &.onboarding .react-select__control,
  &.onboarding .react-select__control:hover {
    border: 2px solid var(--colors-surface-150);
    min-height: 48px;
  }

  &.error {
    .react-select__control,
    .react-select__control--is-focused {
      border-color: var(--colors-error);
    }
    .react-select__placeholder {
      color: var(--colors-error);
    }
  }
`;

const CreateOption = styled.div`
  padding: 16px 0;
  text-align: center;
  border-top: 1px solid var(--colors-separator);
  color: var(--colors-primary-500-p);
  cursor: pointer;
`;

const CheckMark = styled.div`
  widht: 20px;
  height: 20px;
  position: absolute;
  inset-inline-end: 12px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: var(--colors-primary-500-p);
  }
`;

const ViewWrapper = styled.div`
  position: absolute;
  inset-inline-end: 8px;
  top: 7px;
  display: none;
  align-items: center;
  justify-content: center;
`;

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    {props.data.label}
    {props?.data?.isHighlighted && (
      <CheckMark>
        <CheckIcon />
      </CheckMark>
    )}
    {props?.data?.onViewClick && (
      <ViewWrapper className="select-option-view">
        <ViewPunchIconButton
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            if (props?.data?.onViewClick) {
              props?.data?.onViewClick(props.data.value);
            }
          }}
        >
          <EyeIcon width={20} />
        </ViewPunchIconButton>
      </ViewWrapper>
    )}
  </Option>
);
export type SelectControlOption<T> = {
  label: string;
  value: T;
  mvBgColor?: string;
  mvLabelColor?: string;
  readOnly?: string;
  isHighlighted?: boolean;
  onViewClick?: (value: T) => void;
};

/** TODO: We need to rewrite this component. Very difficult to work with */
interface SelectControlProps<T> extends WithTranslation {
  options: SelectControlOption<T>[];
  value: T | T[];
  onChange: (val: T | T[] | null, label: string | string[] | null) => void;
  onInputChange?: (val: string) => void;
  isMulti?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  onboarding?: boolean;
  innerRef?: any;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  error?: boolean;
  /** Add Create option to the bottom part of the menu */
  withCreateOption?: boolean;
  /** Create Option on click callback */
  onCreateOption?: () => void;
  createOptionText?: string;
  className?: string;
}

interface SelectControlState {
  inputValue: string;
  menuIsOpen: boolean;
}

class SelectControl<T = string> extends Component<SelectControlProps<T>, SelectControlState> {
  readonly state: Readonly<SelectControlState> = {
    inputValue: "",
    menuIsOpen: false,
  };

  getPlaceholder = (selectedOption: SelectControlOption<T> | null) => {
    const { placeholder, isMulti, t } = this.props;
    const { menuIsOpen } = this.state;

    if (!isMulti && menuIsOpen && selectedOption?.label) {
      return selectedOption.label;
    }

    return placeholder || t("Select");
  };

  renderMenu = (props: MenuListComponentProps<SelectControlOption<T>>) => {
    const { t, withCreateOption, onCreateOption, createOptionText } = this.props;

    return (
      <>
        <components.MenuList {...props}>{props.children}</components.MenuList>
        {withCreateOption && <CreateOption onClick={onCreateOption}>{createOptionText || t("Create")}</CreateOption>}
      </>
    );
  };

  render() {
    const { inputValue, menuIsOpen } = this.state;
    const {
      t,
      options,
      value,
      onChange,
      isClearable,
      isMulti,
      isSearchable,
      isLoading,
      onboarding,
      disabled,
      innerRef,
      onBlur,
      onFocus,
      error,
      withCreateOption,
      onInputChange,
      className,
    } = this.props;
    let selectedOption: SelectControlOption<T> | SelectControlOption<T>[] | null = null;
    const SelectComponent = withCreateOption ? CreatableSelect : Select;

    if (isMulti) {
      selectedOption = value && Array.isArray(value) ? options.filter((o) => value.includes(o.value)) : [];
    } else {
      selectedOption = value ? options.find((o) => o.value === value) || null : null;
    }

    const classes = [];
    if (className) {
      classes.push(className);
    }
    if (value) {
      classes.push("has-value");
    }
    if (isSearchable) {
      classes.push("searchable");
    }
    if (onboarding) {
      classes.push("onboarding");
    }
    if (error) {
      classes.push("error");
    }

    return (
      <Wrapper className={classes.join(" ")}>
        <SelectComponent
          className="multi-select"
          classNamePrefix="react-select"
          styles={{
            control: (s) => s,
            option: (s) => s,
            multiValue: (styles, { data }) => ({
              ...styles,
              borderRadius: "4px",
              alignItems: "center",
              backgroundColor: data.mvBgColor || "var(--colors-activitiCellScheduleItemHover)",
            }),
            multiValueLabel: (styles, { data }) => ({
              ...styles,
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "18px",
              color: data.mvLabelColor || "var(--colors-unknown39)",
            }),
            multiValueRemove: (styles, { data }) => ({
              ...styles,
              visibility: data.readOnly ? "hidden" : "visible",
              color: data.readOnly ? "red" : "green",
              padding: "3px 2px",
              borderRadius: "4px",
              fill: data.mvLabelColor,
              backgroundColor: data.mvBgColor || "var(--colors-activitiCellScheduleItemHover)",
              ":hover": {
                backgroundColor: data.mvBgColor || "var(--colors-activitiCellScheduleItemHover)",
              },
            }),
          }}
          isDisabled={disabled}
          noOptionsMessage={() => t("No options")}
          options={options}
          isLoading={isLoading}
          isMulti={isMulti}
          value={!isMulti && menuIsOpen ? null : selectedOption}
          components={{
            MenuList: this.renderMenu,
            Option: IconOption,
          }}
          onChange={(o) => {
            if (isMulti) {
              onChange(
                o ? (o as SelectControlOption<T>[]).map((v) => v.value) : null,
                o ? (o as SelectControlOption<T>[]).map((v) => v.label) : null,
              );
            } else {
              onChange(o ? (o as SelectControlOption<T>).value : null, o ? (o as SelectControlOption<T>).label : null);
            }
          }}
          onInputChange={(val) => {
            this.setState({ inputValue: val });
            if (onInputChange) {
              onInputChange(val);
            }
          }}
          onMenuOpen={() => this.setState({ menuIsOpen: true })}
          onMenuClose={() => this.setState({ menuIsOpen: false })}
          isClearable={isClearable}
          inputValue={inputValue}
          isSearchable={isSearchable}
          placeholder={this.getPlaceholder(selectedOption as SelectControlOption<T>)}
          innerRef={innerRef}
          onBlur={onBlur}
          error={error}
          onFocus={onFocus}
        />
      </Wrapper>
    );
  }
}

export default withTranslation()(SelectControl);
