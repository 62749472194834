import { Component, ContextType, MouseEvent } from "react";
import { TFunction, WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import GlobalContext from "context/global-context";
import { GlobalContextDownload } from "context/GlobalContextProvider/types";
import { ClientReportDownload, ClientReportDownloadStatus } from "types/models/clientReportDownload";

export const NotificationWrapper = styled.div`
  visibility: visible;
  position: fixed;
  z-index: 999;
  inset-inline-start: 0;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 3s;
    animation: fadein 0.5s, fadeout 0.5s 3s;
  }

  &.style-with-close-button {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 0;
    animation: fadein 0.5s, fadeout 0.5s 0;

    .snackbar-close {
      display: flex;
    }
  }

  &.style-notice .snackbar-notification {
    background-color: var(--colors-unknown40);
    color: var(--colors-unknown39);
  }

  @-webkit-keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 30px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      top: 30px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 0;
    }
  }
`;

const Notification = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 4px;
  padding: 9px 24px;
  padding-inline-end: 32px;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  text-align: center;
  border-radius: var(--shapes-border-radius-default);
  background: linear-gradient(0deg, var(--colors-unknown9), var(--colors-unknown9)), var(--colors-surface-0);
  color: var(--colors-surface-0);
`;

const CloseButton = styled.div`
  position: absolute;
  top: calc(50% - 5px);
  inset-inline-end: 12px;
  display: block;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.414 6l4.293-4.293A.999.999 0 1010.293.293L6 4.586 1.707.293A.999.999 0 10.293 1.707L4.586 6 .293 10.293a.999.999 0 101.414 1.414L6 7.414l4.293 4.293a.997.997 0 001.414 0 .999.999 0 000-1.414L7.414 6z' fill='%238093AC'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

const Text = styled.span`
  line-height: 24px;
  font-size: 16px;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-900-p);
`;

const CHECK_FOR_DOWNLOADS_INTERVAL = 5000;

interface NotificationItemProps {
  t: TFunction;
  onClose: () => void;
  uuid: string;
  fileName: string;
  url: string;
}

const NotificationItem = ({ t, fileName, url, onClose, uuid }: NotificationItemProps) => (
  <Notification>
    <Text
      dangerouslySetInnerHTML={{
        __html: t("Your file <a href='{{url}}' data-uuid='{{uuid}}'>{{fileName}}</a> is ready to download.", {
          url,
          fileName,
          uuid,
        }),
      }}
    />
    <CloseButton onClick={onClose} />
  </Notification>
);

interface NotificationItemByEmailProps {
  t: TFunction;
  onClose: () => void;
  uuid: string;
}

const NotificationItemByEmail = ({ t, onClose, uuid }: NotificationItemByEmailProps) => (
  <Notification>
    <Text data-uuid={uuid}>{t("file-will-be-delivered-by-email")}</Text>
    <CloseButton onClick={onClose} />
  </Notification>
);

type DownloadSnacksProps = WithTranslation;

interface DownloadSnacksState {
  items: GlobalContextDownload[];
}

class DownloadSnacks extends Component<DownloadSnacksProps, DownloadSnacksState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  checkInterval: NodeJS.Timer | null = null;

  readonly state: Readonly<DownloadSnacksState> = {
    items: [],
  };

  componentDidMount() {
    void this.checkForDownloads();

    this.checkInterval = setInterval(async () => {
      void this.checkForDownloads();
    }, CHECK_FOR_DOWNLOADS_INTERVAL);
  }

  componentWillUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  checkForDownloads = async () => {
    const downloads = await this.context.getDownloads();

    this.setState({ items: downloads });
  };

  onItemClose = (download: GlobalContextDownload) => {
    this.setState({ items: this.state.items.filter((item) => item.uuid !== download.uuid) });

    void this.context.removeFromDownloads(download.uuid);
  };

  render() {
    const { t } = this.props;
    const { items } = this.state;

    return (
      <NotificationWrapper
        onClick={(ev: MouseEvent) => {
          // TODO
          if (ev.target.tagName === "A" && ev.target.dataset && ev.target.dataset.uuid) {
            this.onItemClose({ uuid: ev.target.dataset.uuid });
          }
        }}
      >
        {items.map((download) => (
          <>
            {download.status === ClientReportDownloadStatus.longTime ? (
              <NotificationItemByEmail
                uuid={download.uuid}
                t={t}
                key={download.uuid}
                onClose={() => this.onItemClose(download)}
              />
            ) : (
              <NotificationItem
                uuid={download.uuid}
                t={t}
                key={download.uuid}
                fileName={(download as ClientReportDownload).filename}
                url={(download as ClientReportDownload).download_url}
                onClose={() => this.onItemClose(download)}
              />
            )}
          </>
        ))}
      </NotificationWrapper>
    );
  }
}

export default withTranslation()(DownloadSnacks);
