import { stylesheet } from "astroturf";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import cx from "classnames";

const styles = stylesheet`
  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .Languages {
      display: flex;
      flex-direction: column;
      width: 100%;

      .Language {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        cursor: pointer;

        &:hover {
          background-color: var(--colors-surface-50);
        }

        &.selected {
          color: var(--colors-primary-500-p);
        }
      }
    }
  }
`;

type Language = {
  key: string;
  name: string;
};

export type LanguageSelectorProps = Omit<React.HTMLProps<HTMLDivElement>, "onChange"> & {
  languages: Language[];
  selectedLanguageKey?: string;
  onChange(language: Language): void;
};

export function LanguageSelector(props: LanguageSelectorProps) {
  const { languages, selectedLanguageKey, onChange, ...restProps } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  return (
    <div className={styles.Wrapper} {...restProps}>
      <div className={styles.Languages}>
        {languages.map((lang) => (
          <div
            className={cx(styles.Language, { [styles.selected]: lang.key === selectedLanguageKey })}
            key={lang.key}
            onClick={() => onChange(lang)}
          >
            {t(lang.name)}
          </div>
        ))}
      </div>
    </div>
  );
}
