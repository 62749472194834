import { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import BEM from "utils/BEM";
import { getSystemName } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import logo from "../../img/oitchau-logo-full.png";
import appStoreButton from "../../img/app-store-button.png";
import googlePlayButton from "../../img/google-play-button.png";
import "../../styles/signup.scss";

const b = BEM.b("signup");

type SignupSuccessProps = WithTranslation;

class Success extends Component<SignupSuccessProps> {
  constructor(props: SignupSuccessProps) {
    super(props);

    const { t } = this.props;
    document.title = t("Registration Success");
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className={b("container")}>
          <div className={b("header", { success: true })}>{t("Success!")}</div>
          <div className={b("header")}>{t("Your registration is done.")}</div>

          <form noValidate action="/register" method="post">
            <div className={b("text", { success: true })}>
              {t("Download {{systemName}} on your mobile.", { systemName: getSystemName() })}
            </div>

            <div className={b("apps")}>
              <a
                className={b("app", ["android"])}
                href="https://play.google.com/store/apps/details?id=com.oitchau"
                rel="noopener noreferrer"
                target="_blank"
              >
                <picture className={b("app-img")}>
                  <img src={googlePlayButton} alt="" />
                </picture>
              </a>
              <a
                className={b("app", ["ios"])}
                href="https://itunes.apple.com/br/app/oitchau-controle-de-ponto/id1227692875?ls=1&mt=8"
                rel="noopener noreferrer"
                target="_blank"
              >
                <picture className={b("app-img")}>
                  <img src={appStoreButton} alt="" />
                </picture>
              </a>
            </div>
          </form>

          <div className={b("success-link")}>
            <Link className="link" to="/login">
              {t("Sign in")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.signup)(Success);
