import { useEffect, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  createIcalCalendar,
  deleteIcalCalendar,
  getIcalCalendars,
  regenerateIcalCalendar,
  updateIcalCalendar,
} from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { showSnackbar } from "utils/common";
import { CreateIcalCalendarRequestData, UpdateIcalCalendarRequestData } from "utils/api/types";
import { TranslationNamespaces } from "types/translationNamespaces";
import { UserProfileRole } from "types/models/userProfile";
import { CalendarGroupType, CalendarRequestType, IcalCalendar } from "types/models/request";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControl";
import Button, { ButtonState } from "components/controls/StyledButton";
import { trashIcon, calendarIllustration } from "components/svg-images";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import CheckboxControl from "components/UI/NewCheckbox";
import { TableButton } from "components/styled/Page";
import SelectControl from "components/UI/SelectControl";

const ListContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 90px;

  button {
    margin-top: 32px;
  }
`;

const DetailsContent = styled.div`
  .ical-url {
    position: relative;
    overflow-wrap: break-word;
    padding: 8px;
    border-radius: var(--shapes-border-radius-default);
    color: var(--colors-surface-900-p);
    background-color: var(--colors-surface-50);

    &:hover {
      .copy-button {
        display: flex;
      }
    }
  }
`;

const CalendarIcon = styled.div`
  position: absolute;
  top: -48px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 25px;
  line-height: 100%;
  letter-spacing: -0.55px;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-900-p);
`;

const Description = styled.div`
  margin: 12px 0 24px;
  font-size: var(--typography-font-size-default);
  line-height: 130%;
  color: var(--colors-surface-700);
`;

const CalendarEntry = styled.div`
  position: relative;
  padding: 16px 8px;
  border-bottom: 1px solid var(--colors-surface-150);
  cursor: pointer;

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--colors-surface-50);

    .copy-button {
      display: flex;
    }
  }
`;

const CalendarName = styled.div`
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 100%;
  font-weight: var(--typography-font-weight-medium);
  color: var(--colors-surface-900-p);
`;

const CalendarUrl = styled.div`
  font-size: var(--typography-font-size-default);
  line-height: 130%;
  font-weight: var(--typography-font-weight-default);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colors-surface-800);
`;

const CopyButton = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  padding: 8px;
  background-color: var(--colors-surface-50);
  z-index: 1;
`;

const SettingsSection = styled.div`
  margin-top: 24px;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-top: 14px;
  cursor: pointer;

  input,
  .request-type-checkbox {
    margin-top: 0;
    margin-inline-end: 12px;
    cursor: pointer;
  }

  span {
    font-size: 15px;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 8px;
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--colors-surface-600);
`;

const SavedValue = styled.div`
  font-size: 15px;
  line-height: 100%;
  color: var(--colors-surface-900-p);
`;

const Hr = styled.hr`
  margin: 24px 0 34px;
  border: none;
  border-top: 1px solid var(--colors-surface-150);
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-size: var(--typography-font-size-default);
  line-height: 130%;
  color: var(--colors-danger-500);
  cursor: pointer;

  svg {
    margin-inline-end: 10px;

    path {
      fill: var(--colors-danger-500);
      mask: none;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;

  button {
    width: auto;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    margin-inline-end: 12px;
    white-space: nowrap;
  }
`;

interface CalendarListViewProps {
  onCreate: () => void;
  onDetails: (calendar: IcalCalendar) => void;
}

const CalendarListView = ({ onCreate, onDetails }: CalendarListViewProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [calendars, setCalendars] = useState<IcalCalendar[]>([]);

  useEffect(() => {
    const companyUUID = window.global_store.company.uuid;
    const userProfileUUID = window.global_store.profile.uuid;

    getIcalCalendars({
      companyUUID,
      userProfileUUID,
    })
      .then(({ content }) => {
        if (content.length) {
          setCalendars(content);
        } else {
          // if no calendars open Create View
          onCreate();
        }
      })
      .catch((e) => {
        sentryUtils.sendError(e);
      });
  }, []);

  const onCopy = async (icalUrl: string) => {
    try {
      await navigator.clipboard.writeText(icalUrl);

      showSnackbar({ text: t("Url copied"), notificationStyle: "notice" });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ListContent>
      <CalendarIcon>{calendarIllustration}</CalendarIcon>
      <Title>{t("My calendars")}</Title>

      <div>
        {calendars.map((c) => (
          <CalendarEntry key={c.uuid} onClick={() => onDetails(c)}>
            <CalendarName>{c.name}</CalendarName>
            <CalendarUrl>{c.icalUrl}</CalendarUrl>
            <CopyButton className="copy-button">
              <TableButton
                onClick={(e: MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  void onCopy(c.icalUrl);
                }}
              >
                {t("Copy link")}
              </TableButton>
            </CopyButton>
          </CalendarEntry>
        ))}
      </div>

      <Buttons>
        <Button value={t("Create new calendar")} onClick={onCreate} />
      </Buttons>
    </ListContent>
  );
};

interface CalendarCreateViewProps {
  afterCreate: () => void;
}

const CalendarCreateView = ({ afterCreate }: CalendarCreateViewProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [isLoading, setIsLoading] = useState(false);
  const [calendarName, setCalendarName] = useState("");
  const [calendarGroupType, setCalendarGroupType] = useState<CalendarGroupType>(CalendarGroupType.self);
  const [calendarRequestTypes, setCalendarRequestTypes] = useState<CalendarRequestType[]>([]);

  const isSupervisor = window.global_store.profile.role === UserProfileRole.supervisor;

  const onCreate = async () => {
    const companyUUID = window.global_store.company.uuid;
    const userProfileUUID = window.global_store.profile.uuid;

    setIsLoading(true);

    const payload: CreateIcalCalendarRequestData = {
      companyUUID,
      userProfileUUID,
      name: calendarName,
      visibleGroupTypes: calendarGroupType,
      visibleRequestTypes: calendarRequestTypes,
    };

    if (calendarGroupType === CalendarGroupType.team) {
      payload.groupUuid = window.global_store.profile.teams[0].uuid;
    }

    try {
      await createIcalCalendar(payload);

      showSnackbar({ text: t("Calendar has been created"), notificationStyle: "notice" });

      afterCreate();
    } catch (e) {
      sentryUtils.sendError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCheck = (requestType: CalendarRequestType) => {
    let updatedCalendarRequestTypes = [];
    if (calendarRequestTypes.includes(requestType)) {
      updatedCalendarRequestTypes = calendarRequestTypes.filter((rt) => rt !== requestType);
    } else {
      updatedCalendarRequestTypes = [...calendarRequestTypes, requestType];
    }

    setCalendarRequestTypes(updatedCalendarRequestTypes);
  };

  return (
    <>
      <Description>
        {t("Export all events information in iCal format link and then add it to a calendar app of your choise.")}
      </Description>

      <FieldWrapper fieldName={t("Name")} width="100%" fieldTitleMarginTop={0}>
        <TextInputControl
          style={{ borderColor: "var(--colors-secondary)" }}
          placeholder={t("Give your calender a name")}
          value={calendarName}
          onChange={setCalendarName}
        />
      </FieldWrapper>

      <SettingsSection>
        <FieldWrapper fieldName={t("Employees")} width="100%" fieldTitleMarginTop={0}>
          <SelectControl<CalendarGroupType>
            options={[
              { label: t("All employees"), value: CalendarGroupType.self },
              isSupervisor ? { label: t("My employees"), value: CalendarGroupType.team } : null,
            ].filter((o) => o !== null)}
            value={calendarGroupType}
            onChange={setCalendarGroupType}
            isSearchable={false}
          />
        </FieldWrapper>
      </SettingsSection>

      <SettingsSection>
        <SectionTitle>{t("Type of request")}</SectionTitle>
        <div>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.vacation)}
              checked={calendarRequestTypes.includes(CalendarRequestType.vacation)}
            />
            <span>{t("Vacation")}</span>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.medical)}
              checked={calendarRequestTypes.includes(CalendarRequestType.medical)}
            />
            <span>{t("Medical")}</span>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.custom)}
              checked={calendarRequestTypes.includes(CalendarRequestType.custom)}
            />
            <span>{t("Other")}</span>
          </CheckboxWrapper>
        </div>
      </SettingsSection>

      <Buttons>
        <Button
          value={t("Create calendar")}
          onClick={onCreate}
          disabled={!calendarName || !calendarRequestTypes.length}
          loading={isLoading}
          style={{ marginTop: 32 }}
        />
      </Buttons>
    </>
  );
};

interface CalendarDetailsViewProps {
  calendar: IcalCalendar;
}

const CalendarDetailsView = ({ calendar: propsCalendar }: CalendarDetailsViewProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [isGenerating, setIsGenerating] = useState(false);
  const [calendar, setCalendar] = useState(propsCalendar);

  useEffect(() => {
    // update calendar data after edit
    if (JSON.stringify(calendar) !== JSON.stringify(propsCalendar)) {
      setCalendar(propsCalendar);
    }
  }, [propsCalendar]);

  const groupType = {
    [CalendarGroupType.self]: t("All employees"),
    [CalendarGroupType.team]: t("My employees"),
    [CalendarGroupType.company]: "", // should be added in future
    [CalendarGroupType.department]: "",
    [CalendarGroupType.subsidiary]: "",
  }[calendar.visibleGroupTypes];
  const requestTypes = calendar.visibleRequestTypes.map((rt) => t(rt)).join(", ");

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(calendar.icalUrl);

      showSnackbar({ text: t("Url copied"), notificationStyle: "notice" });
    } catch (e) {
      console.error(e);
    }
  };

  const onRegenerate = async () => {
    const companyUUID = window.global_store.company.uuid;
    const userProfileUUID = window.global_store.profile.uuid;

    setIsGenerating(true);

    try {
      const { content } = await regenerateIcalCalendar({
        companyUUID,
        userProfileUUID,
        calendarUUID: calendar.uuid,
      });

      setCalendar(content);

      showSnackbar({ text: t("Link has been regenerated"), notificationStyle: "notice" });
    } catch (error) {
      sentryUtils.sendError(error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <DetailsContent>
      <CalendarName>{calendar.name}</CalendarName>

      <SettingsSection>
        <SectionTitle>{t("Employees")}</SectionTitle>
        <SavedValue>{groupType}</SavedValue>
      </SettingsSection>

      <SettingsSection>
        <SectionTitle>{t("Type of request")}</SectionTitle>
        <SavedValue>{requestTypes}</SavedValue>
      </SettingsSection>

      <Hr />

      <FieldWrapper fieldName={t("Calendar link")} width="100%" fieldTitleMarginTop={0} marginBottom={24}>
        <div className="ical-url">
          {calendar.icalUrl}

          <CopyButton className="copy-button">
            <TableButton
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                void onCopy();
              }}
            >
              {t("Copy link")}
            </TableButton>
          </CopyButton>
        </div>
      </FieldWrapper>

      <Buttons>
        <Button
          state={ButtonState.secondary}
          value={t("Regenerate link")}
          onClick={onRegenerate}
          loading={isGenerating}
          disabled={isGenerating}
        />
      </Buttons>
    </DetailsContent>
  );
};

interface CalendarEditViewProps {
  calendar: IcalCalendar;
  afterUpdate: (calendar: IcalCalendar | null) => void;
}

const CalendarEditView = ({ calendar, afterUpdate }: CalendarEditViewProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [isUpdating, setIsUpdating] = useState(false);
  const [calendarName, setCalendarName] = useState(calendar.name);
  const [calendarGroupType, setCalendarGroupType] = useState<CalendarGroupType>(calendar.visibleGroupTypes);
  const [calendarRequestTypes, setCalendarRequestTypes] = useState<CalendarRequestType[]>(calendar.visibleRequestTypes);

  const isSupervisor = window.global_store.profile.role === UserProfileRole.supervisor;

  const onUpdate = async () => {
    const companyUUID = window.global_store.company.uuid;
    const userProfileUUID = window.global_store.profile.uuid;

    setIsUpdating(true);

    const payload: UpdateIcalCalendarRequestData = {
      companyUUID,
      userProfileUUID,
      calendarUUID: calendar.uuid,
      name: calendarName,
      visibleGroupTypes: calendarGroupType,
      visibleRequestTypes: calendarRequestTypes,
    };

    if (calendarGroupType === CalendarGroupType.team) {
      payload.groupUuid = window.global_store.profile.teams[0].uuid;
    }

    try {
      const { content } = await updateIcalCalendar(payload);

      showSnackbar({ text: t("Calendar has been updated"), notificationStyle: "notice" });

      afterUpdate(content);
    } catch (e) {
      sentryUtils.sendError(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const onDelete = async () => {
    const companyUUID = window.global_store.company.uuid;
    const userProfileUUID = window.global_store.profile.uuid;

    setIsUpdating(true);

    try {
      await deleteIcalCalendar({
        companyUUID,
        userProfileUUID,
        calendarUUID: calendar.uuid,
      });

      showSnackbar({ text: t("Calendar has been deleted"), notificationStyle: "notice" });

      afterUpdate(null);
    } catch (e) {
      sentryUtils.sendError(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const onCheck = (requestType: CalendarRequestType) => {
    let updatedCalendarRequestTypes = [];
    if (calendarRequestTypes.includes(requestType)) {
      updatedCalendarRequestTypes = calendarRequestTypes.filter((rt) => rt !== requestType);
    } else {
      updatedCalendarRequestTypes = [...calendarRequestTypes, requestType];
    }

    setCalendarRequestTypes(updatedCalendarRequestTypes);
  };

  return (
    <>
      <FieldWrapper fieldName={t("Name")} width="100%" fieldTitleMarginTop={0}>
        <TextInputControl
          style={{ borderColor: "var(--colors-secondary)" }}
          placeholder={t("Give your calender a name")}
          value={calendarName}
          onChange={setCalendarName}
        />
      </FieldWrapper>

      <SettingsSection>
        <FieldWrapper fieldName={t("Employees")} width="100%" fieldTitleMarginTop={0}>
          <SelectControl<CalendarGroupType>
            options={[
              { label: t("All employees"), value: CalendarGroupType.self },
              isSupervisor ? { label: t("My employees"), value: CalendarGroupType.team } : null,
            ].filter((o) => o !== null)}
            value={calendarGroupType}
            onChange={setCalendarGroupType}
            isSearchable={false}
          />
        </FieldWrapper>
      </SettingsSection>

      <SettingsSection>
        <SectionTitle>{t("Type of request")}</SectionTitle>
        <div>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.vacation)}
              checked={calendarRequestTypes.includes(CalendarRequestType.vacation)}
            />
            <span>{t("Vacation")}</span>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.medical)}
              checked={calendarRequestTypes.includes(CalendarRequestType.medical)}
            />
            <span>{t("Medical")}</span>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <CheckboxControl
              className="request-type-checkbox"
              onChange={() => onCheck(CalendarRequestType.custom)}
              checked={calendarRequestTypes.includes(CalendarRequestType.custom)}
            />
            <span>{t("Other")}</span>
          </CheckboxWrapper>
        </div>
      </SettingsSection>

      <Buttons>
        <Button
          value={t("Save changes")}
          onClick={onUpdate}
          disabled={!calendarName || !calendarRequestTypes.length}
          loading={isUpdating}
          style={{ marginTop: 42 }}
        />
      </Buttons>

      <DeleteButton onClick={!isUpdating ? onDelete : undefined}>
        {trashIcon} <span>{t("Delete integration")}</span>
      </DeleteButton>
    </>
  );
};

interface CalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CalendarModal = ({ isOpen, onClose }: CalendarModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);
  const [view, setView] = useState<"list" | "create" | "edit" | "details">("list");
  const [calendar, setCalendar] = useState<IcalCalendar | null>(null);

  let content;
  let header;
  let onBack;
  let onEdit;

  switch (view) {
    case "details":
      content = <CalendarDetailsView calendar={calendar as IcalCalendar} />;
      header = t("Calendar");
      onBack = () => {
        setView("list");
        setCalendar(null);
      };
      onEdit = () => setView("edit");
      break;
    case "edit":
      content = (
        <CalendarEditView
          calendar={calendar as IcalCalendar}
          afterUpdate={(c: IcalCalendar | null) => {
            setView(c ? "details" : "list");
            setCalendar(c);
          }}
        />
      );
      header = t("Edit calendar");
      onBack = () => setView("details");
      onEdit = undefined;
      break;
    case "create":
      content = <CalendarCreateView afterCreate={() => setView("list")} />;
      header = t("Create new calendar");
      onBack = () => setView("list");
      onEdit = undefined;
      break;
    case "list":
    default:
      content = (
        <CalendarListView
          onCreate={() => setView("create")}
          onDetails={(c) => {
            setCalendar(c);
            setView("details");
          }}
        />
      );
      header = undefined;
      onBack = undefined;
      onEdit = undefined;
      break;
  }

  return (
    <SidePopupOverlay
      isOpen={isOpen}
      header={header}
      onClose={() => {
        setView("list");
        setCalendar(null);
        onClose();
      }}
      onBack={onBack}
      onEdit={onEdit}
    >
      {content}
    </SidePopupOverlay>
  );
};

export default CalendarModal;
