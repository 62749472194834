import { TableButton, TableButtons } from "./Page";
import TableButtonWithDropdown, { TableButtonItems } from "./TableButtonWithDropdown";

interface TableButtonsControlProps {
  dropdownButtons?: TableButtonItems[];
  visibleButtons?: TableButtonItems[];
  dropToTop?: boolean;
}

const TableButtonsControl = ({ dropdownButtons, visibleButtons, dropToTop }: TableButtonsControlProps) => (
  <TableButtons className="buttons">
    {!!visibleButtons &&
      visibleButtons.map((button) => (
        <TableButton
          key={button.label}
          onClick={(ev) => {
            ev.stopPropagation();
            button.onClick();
          }}
        >
          {button.label}
        </TableButton>
      ))}

    {!!(dropdownButtons && dropdownButtons.length) && (
      <TableButtonWithDropdown dropToTop={dropToTop} items={dropdownButtons} />
    )}
  </TableButtons>
);

export default TableButtonsControl;
