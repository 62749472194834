import { useMemo, useState } from "react";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import CheckboxControl from "components/UI/NewCheckbox";
import { useTranslation } from "react-i18next";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  bulkDigitalSignaturesRequest,
  bulkLockPayrollLocksRequest,
  bulkSendToPayrollRequest,
  bulkUnlockPayrollLocksRequest,
} from "utils/api/company";
import PayrollPopupWithLayouts from "components/Reports/PayrollPopupWithLayouts";
import { NotificationType } from "../../types/common";

const ActionsBarRow = styled.div`
  bottom: 5.12%;
  position: fixed;
  display: flex;
  justify-content: center;
  height: 60px;
  inset-inline-start: 50%;
  z-index: 999;
`;

const ActionsBarWrapper = styled.div`
  height: 60px;
  width: 693px;
  background: var(--colors-default);
  box-shadow: 0 5px 30px rgba(129, 147, 171, 0.2);
  border-radius: 58px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;

  .ui-select__wrapper > div {
    margin: 0;
  }
`;

const Badge = styled.div`
  margin-inline-start: 12px;
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--colors-surface-150);
  background: var(--colors-surface-50);
  overflow: hidden;
  color: var(--colors-surface-900-p);
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: var(--typography-font-weight-default);
  line-height: 100%;
`;

const SlecetedCount = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.span`
  font-weight: var(--typography-font-weight-medium);
  line-height: 18px;
  font-size: var(--text-size-default);
  color: var(--colors-text1);
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  .ui-select__dropdown-wrapper {
    max-height: 350px;
  }
`;

type ActionsBarProps = {
  onDone: (data: { notification: string; notificationType: NotificationType }) => void;
  onFail: (data: { notification: string; notificationType: NotificationType }) => void;
  onUncheckAll: () => void;
  toggledLocks: string[];
  disabled: boolean;
  filterMode?: any;
  isSelectAllMode: boolean;
  rowsCount: number;
};

enum ActionType {
  lockAll = "lockAll",
  unlockAll = "unlockAll",
  requestDS = "requestDS",
  sendToPayroll = "sendToPayroll",
  exportReports = "exportReports",
}

const DeactivatedEmployeesTableActionsBar = (props: ActionsBarProps) => {
  const { onUncheckAll, toggledLocks, disabled, onDone, onFail } = props;
  const { t } = useTranslation(TranslationNamespaces.payment);
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [exportDialogVisible, setExportDialogVisible] = useState(false);
  const [recalculateReports, setRecalculateReports] = useState(false);
  const [isSendToPayrollLoading, setIsSendToPayrollLoading] = useState(false);

  const getCommonRequestBody = () => ({
    content: {
      requestedBy: window.global_store.profile.uuid,
      filterModel: props.filterMode,
      includeLocksUuids: props.isSelectAllMode ? [] : toggledLocks,
      excludeLocksUuids: props.isSelectAllMode ? toggledLocks : [],
    },
  });

  const onSendToPayroll = ({ layoutUuid }: { layoutUuid: string }) => {
    setIsSendToPayrollLoading(true);

    const body = {
      content: {
        requestedBy: window.global_store.profile.uuid,
        payrollLayoutUuid: layoutUuid,
        filterModel: props.filterMode,
        includeLocksUuids: props.isSelectAllMode ? [] : toggledLocks,
        excludeLocksUuids: props.isSelectAllMode ? toggledLocks : [],
        forceRecalculate: recalculateReports,
      },
    };

    bulkSendToPayrollRequest({
      body,
      companyUuid: window.global_store.company.uuid,
    })
      .then(() => {
        onDone({
          notification: recalculateReports ? t("Send to payroll request sent") : t("Export reports sent"),
          notificationType: NotificationType.success,
        });
      })
      .catch(() => {
        onFail({
          notification: recalculateReports
            ? t("Send to payroll request failed to be sent")
            : t("Export reports failed to be sent"),
          notificationType: NotificationType.error,
        });
      })
      .finally(() => {
        setActionType(null);
        setIsSendToPayrollLoading(false);
        setExportDialogVisible(false);
      });
  };

  const options = useMemo(
    () => [
      {
        value: ActionType.exportReports,
        label: t("Export payrolls"),
      },
      {
        value: ActionType.sendToPayroll,
        label: t("Send to payroll"),
      },
      {
        value: ActionType.requestDS,
        label: t("Request signature"),
      },
      {
        value: ActionType.lockAll,
        label: t("Lock All"),
      },
      {
        value: ActionType.unlockAll,
        label: t("Unlock All"),
        disabled: true,
      },
    ],
    [t],
  );

  const doAction = async (type: ActionType) => {
    switch (type) {
      case ActionType.lockAll: {
        const body = getCommonRequestBody();

        bulkLockPayrollLocksRequest({
          companyUuid: window.global_store.company.uuid,
          body,
        })
          .then(() => {
            onDone({
              notification: t("Lock all request sent"),
              notificationType: NotificationType.success,
            });
          })
          .catch(() => {
            onFail({
              notification: t("Lock all request failed"),
              notificationType: NotificationType.error,
            });
          })
          .finally(() => {
            setActionType(null);
          });

        break;
      }
      case ActionType.unlockAll: {
        const body = getCommonRequestBody();

        bulkUnlockPayrollLocksRequest({
          companyUuid: window.global_store.company.uuid,
          body,
        })
          .then(() => {
            onDone({
              notification: t("Unlock all request sent"),
              notificationType: NotificationType.success,
            });
          })
          .catch(() => {
            onFail({
              notification: t("Unlock all request failed"),
              notificationType: NotificationType.error,
            });
          })
          .finally(() => {
            setActionType(null);
          });

        break;
      }
      case ActionType.requestDS: {
        const body = getCommonRequestBody();

        bulkDigitalSignaturesRequest({
          body,
          companyUuid: window.global_store.company.uuid,
        })
          .then(() => {
            onDone({
              notification: t("Digital signatures request sent"),
              notificationType: NotificationType.success,
            });
          })
          .catch(() => {
            onFail({
              notification: t("Digital signatures request failed"),
              notificationType: NotificationType.error,
            });
          })
          .finally(() => {
            setActionType(null);
          });
        break;
      }
      default:
        setActionType(null);
        break;
    }
  };

  const howManySelected = props.isSelectAllMode
    ? props.rowsCount - props.toggledLocks.length
    : props.toggledLocks.length;

  return (
    <ActionsBarRow>
      <ActionsBarWrapper>
        <SlecetedCount>
          <CheckboxControl
            disabled={disabled}
            label={
              <CheckboxLabel>
                {t("Selected")}: {howManySelected}
              </CheckboxLabel>
            }
            checked
            onChange={onUncheckAll}
          />
          {howManySelected === props.rowsCount ? <Badge>{t("All members")}</Badge> : null}
        </SlecetedCount>
        <ActionButtons>
          {!!options.length && (
            <SimpleMenu
              disabled={disabled}
              options={options}
              onChange={(val) => {
                switch (val) {
                  case ActionType.lockAll:
                    setActionType(ActionType.lockAll);
                    break;
                  case ActionType.unlockAll:
                    setActionType(ActionType.unlockAll);
                    break;
                  case ActionType.requestDS:
                    setActionType(ActionType.requestDS);
                    break;
                  case ActionType.sendToPayroll:
                    setRecalculateReports(true);
                    setExportDialogVisible(true);
                    break;
                  case ActionType.exportReports:
                    setRecalculateReports(false);
                    setExportDialogVisible(true);
                    break;
                  default: {
                    break;
                  }
                }
              }}
            >
              <Button
                state={ButtonState.secondary}
                value={t("Actions")}
                onClick={() => {}}
                style={{ width: "110px" }}
              />
            </SimpleMenu>
          )}
        </ActionButtons>
      </ActionsBarWrapper>
      <ModalDialog isOpen={exportDialogVisible} onClose={(): void => setExportDialogVisible(false)}>
        <PayrollPopupWithLayouts
          title={recalculateReports ? t("confirm-description-sendToPayroll") : t("confirm-description-exportPayrolls")}
          onClose={() => {
            setExportDialogVisible(false);
          }}
          onYes={(data) => {
            onSendToPayroll(data);
          }}
          isLoading={isSendToPayrollLoading}
          isBulkExport
          withForceRecalculate={recalculateReports}
        />
      </ModalDialog>
      {actionType && (
        <ModalDialog isOpen onClose={() => setActionType(null)}>
          <Lightbox
            title={t(`confirm-title-${actionType}`)}
            text={t(`confirm-description-${actionType}`)}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            showLoading
            onClose={() => {
              setActionType(null);
            }}
            onYes={() => doAction(actionType)}
          />
        </ModalDialog>
      )}
    </ActionsBarRow>
  );
};

export default DeactivatedEmployeesTableActionsBar;
