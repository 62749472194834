import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Prev = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.323 1.05a.989.989 0 00-1.405 0L.71 5.286a1.005 1.005 0 00-.009 1.423l4.216 4.242a.989.989 0 001.405 0 1.005 1.005 0 000-1.414L2.81 6.001l3.513-3.536a1.005 1.005 0 000-1.414z' fill='%238193AB'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
`;

const Next = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.677 1.05a.989.989 0 011.405 0l4.215 4.243a1.005 1.005 0 01-.006 1.42L2.082 10.95a.989.989 0 01-1.405 0 1.005 1.005 0 010-1.414L4.19 6.001.677 2.465a1.005 1.005 0 010-1.414z' fill='%238193AB'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
`;

const Year = styled.div`
  padding-bottom: 2px;
  font-weight: var(--typography-font-weight-medium);
`;

interface YearSelectorProps {
  year: number | undefined;
  years: number[];
  onYearChange: (year: number) => void;
}

const YearSelector = ({ year, years, onYearChange }: YearSelectorProps) => {
  const ArrowPrev = window.global_store.isRTL ? Next : Prev;
  const ArrowNext = window.global_store.isRTL ? Prev : Next;

  const onPrevClick = () => {
    if (years.length > 1 && years.indexOf(year as number) > 0) {
      onYearChange(years[years.indexOf(year as number) - 1]);
    }
  };

  const onNextClick = () => {
    if (years.length > 1 && years.indexOf(year as number) < years.length - 1) {
      onYearChange(years[years.indexOf(year as number) + 1]);
    }
  };

  return (
    <Wrapper>
      {years.length <= 1 || years.indexOf(year as number) === 0 ? (
        <div style={{ width: "24px", height: "24px" }} />
      ) : (
        <ArrowPrev
          className={years.length <= 1 || years.indexOf(year as number) === 0 ? "disabled" : ""}
          onClick={onPrevClick}
        />
      )}

      <Year>{year}</Year>

      {years.length <= 1 || years.indexOf(year as number) === years.length - 1 ? (
        <div style={{ width: "24px", height: "24px" }} />
      ) : (
        <ArrowNext
          className={years.length <= 1 || years.indexOf(year as number) === years.length - 1 ? "disabled" : ""}
          onClick={onNextClick}
        />
      )}
    </Wrapper>
  );
};

export default YearSelector;
