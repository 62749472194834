import ProgressBar from "components/ProgressBar";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";

const ProgressBarWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100px;
  height: 12px;
  margin-inline-end: 12px;
  margin-bottom: 4px;
  margin-inline-start: 0;

  .progress-bar__bar-wrapper {
    width: 100%;
    bottom: -1px;
    margin: 0;
  }
`;

const ProjectsProgressEstimatesRow = styled.div`
  display: flex;
  flex-direction: row;
  fles-wrap: nowrap;
  align-items: center;
  gap: 12px;
`;

const ProjectsProgressIndicatorRow = styled.div`
  display: flex;
  flex-direction: row;
  fles-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const BarWithPercentage = styled.div`
  display: flex;
  flex-direction: row;
  fles-wrap: nowrap;
  align-items: center;
`;

const PercentCompleted = styled.div`
  text-align: start;
  width: 56px;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 100%;
  color: var(--colors-surface-800);

  &.danger {
    color: var(--colors-danger-500);
  }
`;

const AddEstimates = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  text-align: start;
  padding-inline-start: 17px;
  color: var(--colors-surface-600);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 12px;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.667 4.833h-4v-4a.667.667 0 0 0-1.334 0v4h-4a.667.667 0 0 0 0 1.334h4v4a.667.667 0 1 0 1.334 0v-4h4a.667.667 0 1 0 0-1.334Z' fill='%238093AC'/%3E%3C/svg%3E");
    background-position: 0 center;
    background-repeat: no-repeat;
  }
`;

const CompletedEstimated = styled.div`
  width: 95px;
  height: 18px;
  text-align: start;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-500);
  b {
    font-weight: var(--typography-font-weight-default);
    color: var(--colors-surface-800);
  }
`;

const ProjectsProgressIndicator = ({
  actual,
  estimated,
  type,
}: {
  actual: number;
  estimated: number;
  type: "cost" | "hours";
}) => {
  const { t } = useTranslation(TranslationNamespaces.projects);
  let completedPercent = 0;
  const currency = getSymbolFromCurrency(window.global_store.company.currency) || "$";

  if (actual && estimated) {
    completedPercent = parseFloat(((100 * actual) / estimated).toFixed(1));
  }

  if (!estimated) {
    return (
      <ProjectsProgressEstimatesRow>
        <CompletedEstimated>
          <b>{t(`without-estimate-${type}`, { actual, currency })}</b>
        </CompletedEstimated>
        <AddEstimates>{t("Estimate")}</AddEstimates>
      </ProjectsProgressEstimatesRow>
    );
  }

  return (
    <ProjectsProgressIndicatorRow>
      <CompletedEstimated
        className="completed-estimated"
        dangerouslySetInnerHTML={{
          __html: t(`progress-bar-${type}`, {
            actual,
            estimated,
            currency,
          }),
        }}
      />
      <BarWithPercentage>
        <ProgressBarWrapper>
          <ProgressBar
            width={completedPercent >= 100 ? 100 : completedPercent}
            modifiers={{ danger: completedPercent > 100 }}
          />
        </ProgressBarWrapper>
        <PercentCompleted className={completedPercent > 100 ? "danger" : ""}>{completedPercent}%</PercentCompleted>
      </BarWithPercentage>
    </ProjectsProgressIndicatorRow>
  );
};

export default ProjectsProgressIndicator;
