import { Component, createContext, PropsWithChildren } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Feature, SubscriptionStatus } from "types/models/subscription";
import { SubscriptionApi } from "utils/api/subscription";
import { AllowedAddFreeEmployees, BillingService } from "../BillingService";
import { NeedPurchasePlanModal } from "../Modals/NeedPurchasePlanModal";

export type SubscriptionContextValue = {
  getAvailableSeats: () => AllowedAddFreeEmployees | undefined;
  init: () => Promise<void>;
  checkAvailableSeats: (isUpdate: boolean, addedSeats: any) => Promise<boolean>;
  state: SubscriptionContextState;
};
export const SubscriptionContext = createContext({} as SubscriptionContextValue);
interface SubscriptionContextState {
  seats: AllowedAddFreeEmployees | undefined;
  showNeedToUpgradeModal: boolean;
}
interface SubscriptionContextProps extends PropsWithChildren, WithTranslation {};

class SubscriptionContextProvider extends Component<SubscriptionContextProps, SubscriptionContextState> implements SubscriptionContextValue {
  constructor(props: SubscriptionContextProps) {
    super(props);
    this.state = {
      seats: undefined,
      showNeedToUpgradeModal: false,
    };
  }

  getAvailableSeats = () => this.state.seats;

  init = async (): Promise<void> => {
    const freeSubscription = BillingService.findSubscriptions(BillingService.getSubscriptionsFromLocalStorage || [], SubscriptionStatus.Free);

    if (!freeSubscription) {
      this.setState({seats: {
          [Feature.TA]: BillingService.checkFeatureAccess(Feature.TA, false),
          [Feature.Project]: BillingService.checkFeatureAccess(Feature.Project, false),
        }});
      return;
    }
    const usedSeats = await SubscriptionApi.getCompanyAssociatedProducts();
    this.setState({seats: {
      [Feature.TA]: BillingService.checkFeatureAccess(Feature.TA, false) ? 10 - (usedSeats && usedSeats[Feature.TA] || 0) : false,
      [Feature.Project]: BillingService.checkFeatureAccess(Feature.Project, false) ? 5 - (usedSeats && usedSeats[Feature.Project] || 0) : false,
    }});
  };

  checkAvailableSeats = async (isUpdate = false, addedSeats: any = {}) => {
    const seats = this.state.seats!;
    if (!this.state.seats) return true;
    const limits = Object.values(seats).filter((value) => typeof value === "number") as number[];
    if (limits.length === 0) return true;

    const taSeats: number = (typeof seats[Feature.TA] === "number") ? +seats[Feature.TA] - (addedSeats && addedSeats[Feature.TA] || 0) : 0;
    const projectSeats: number = (typeof seats[Feature.Project] === "number") ? +seats[Feature.Project] - (addedSeats && addedSeats[Feature.Project] || 0) : 0;
    if (!isUpdate && (taSeats > 0 || projectSeats > 0)) {
      return true;
    }

    if (isUpdate && taSeats >= 0 && projectSeats >= 0) {
      return true;
    }

    this.setState({showNeedToUpgradeModal: true});
    return false;
  }

  render() {
    return (
      <SubscriptionContext.Provider
        value={{
          state: this.state,
          getAvailableSeats: this.getAvailableSeats,
          init: this.init,
          checkAvailableSeats: this.checkAvailableSeats,
        }}
        >
        {this.props.children}
        { this.state.showNeedToUpgradeModal ? <NeedPurchasePlanModal close={() => {this.setState({ showNeedToUpgradeModal: false})}} /> : null }
      </SubscriptionContext.Provider>
    );
  }
}

export default withTranslation(TranslationNamespaces.subscription)(SubscriptionContextProvider);
