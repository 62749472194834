import { useContext, useEffect } from "react";
import { useAsyncCallback } from "utils/useAsyncEffect";
import GlobalContext from "context/global-context";
import useState from "hooks/useState";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { getProjectAssignees } from "./projectsApiUtils";
import { ProjectTaskAssignee } from "./projectsApiTypes";

type ProjectPageEmployeesState = {
  assignees: ProjectTaskAssignee[];
  loading: boolean;
  isChangeToPrivateProjectPopupVisible: boolean;
  employeeUuidToUnassign: ProjectTaskAssignee | null;
  isAssignDialogOpen: boolean;
  searchValue: string;
};

type ProjectPageEmployeesResponse = [ProjectPageEmployeesState, (state: Partial<ProjectPageEmployeesState>) => void];

export function useProjectPageEmployeesInit({ projectUuid }: { projectUuid: string }): ProjectPageEmployeesResponse {
  const context = useContext(GlobalContext);
  const { t } = useTranslation(TranslationNamespaces.projects);
  const [state, setState] = useState<ProjectPageEmployeesState>({
    employeeUuidToUnassign: null,
    assignees: [],
    isChangeToPrivateProjectPopupVisible: false,
    loading: true,
    isAssignDialogOpen: false,
    searchValue: "",
  });

  const [init] = useAsyncCallback(async () => {
    setState({ loading: true });
    const company = await context.getCompany();
    const response = await getProjectAssignees({
      companyUuid: company.uuid,
      projectUuid,
      requestedBy: window.global_store.profile.uuid,
    });
    const userProfiles = response?.userProfiles || [];
    const services = response?.services || [];

    const userProfilesAssignees: ProjectTaskAssignee[] = userProfiles.map((up) => ({
      uuid: up.uuid,
      title: up.fullName,
      avatarId: up.avatarId,
      subtitle: up.position?.title || up.role || "",
      isServiceGroup: false,
    }));

    const servicesAssignees: ProjectTaskAssignee[] = services.map((service) => ({
      uuid: service.uuid,
      title: service.name,
      subtitle: t("Service"),
      isServiceGroup: true,
    }));

    const assignees = [...userProfilesAssignees, ...servicesAssignees];

    setState({ assignees, loading: false });
  }, [projectUuid]);

  useEffect(() => void init(), [init, projectUuid]);

  return [state, setState];
}
