import { Component } from "react";
import Select from "react-select"; // https://github.com/JedWatson/react-select

/** the value should be an option object !! */
class SelectControl extends Component {
  onChange = (select) => {
    this.props.onChange(select);
  };

  render() {
    const { value, options, error, name, gray, wideBorder } = this.props;
    let controlBorder = "1px solid var(--colors-unknown25);"
    if (error) controlBorder = "1px solid var(--colors-danger-600-p)";
    else if (wideBorder) controlBorder = "2px solid var(--colors-surface-150)";
    else if (gray) controlBorder = "1px solid transparent";

    const customStyles = {
      container: (base) => ({
        ...base,
        marginBottom: "10px",
        height: this.props.onboarding ? "48px" : "auto",
        minHeight: this.props.onboarding ? "48px" : "36px",
      }),
      control: (base, state) => ({
        ...base,
        cursor: "pointer",
        border: controlBorder,
        boxShadow: "none",
        minHeight: "36px",
        height: this.props.onboarding ? "48px" : "100%",
        backgroundColor: gray ? "var(--colors-surface-50)" : state.isDisabled ? "#f2f2f2" : "var(--colors-surface-0)",
        ":active": gray
          ? {
              border: "1px solid transparent",
              boxShadow: "none",
            }
          : {},
      }),
      menu: (base) => ({
        ...base,
        padding: "10px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "14px",
        color: "var(--colors-surface-800)",
        lineHeight: "14px",
      }),
      option: (base, state) => ({
        ...base,
        borderRadius: "4px",
        backgroundColor:
          state.isFocused || state.isSelected
            ? "var(--colors-activitiCellScheduleItemHover)"
            : "var(--colors-surface-0)",
        color: state.isFocused || state.isSelected ? "var(--colors-surface-900-p)" : "var(--colors-surface-900-p)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0 0 0 14px",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        paddingInlineEnd: "8px",
      })
    };

    return (
      <Select
        name={name}
        autoFocus={false}
        value={value}
        isDisabled={this.props.isDisabled}
        isMulti={this.props.isMulti}
        simpleValue
        placeholder={this.props.placeholder}
        styles={customStyles}
        defaultValue={{ value: "", label: "Please Select" }}
        onChange={this.onChange}
        options={options}
        className={this.props.className}
      />
    );
  }
}
export default SelectControl;
