import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import * as momentTz from "moment-timezone";
import moment from "moment";
import GlobalContext from "context/global-context";
import FullPage from "components/Layout/FullPage";
import { PageSearch, TableButton, TableButtons } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { iColumn } from "components/TableCommon";
import NotificationRow from "components/NotificationRow";
import PaginationNew from "components/PaginationNew";
import NoContent from "components/NoContent";
import TablePage from "components/TablePage";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import Select from "components/UI/Select";
import User from "components/User";
import CellCheckbox from "components/controls/CellCheckbox";
import CellCheckboxAll from "components/controls/CellCheckboxAll";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { TranslationNamespaces } from "types/translationNamespaces";
import { NotificationType } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { OnCall, OnCallStatus, OnCallTableData, OnCallType } from "types/models/onCalls";
import { getOnCalls, listUserProfilesWIthFilters, updateOnCallStatus } from "utils/api/company";
import Sentry from "utils/sentryUtils";
import {
  getEmployeeTaxIdTranslation,
  getEmployeeTaxPayerType,
  hasEmployeesAccess,
  hasPermisionAccess,
  isSupervisor,
} from "utils/common";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import ApproveDeclineActionBar from "components/ApproveDeclineActionBar";
import { ButtonState } from "components/controls/StyledButton";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import { translateEmployeeTerm } from "utils/translationHelpers";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import OnCallCreatePopup from "./OnCallCreatePopup";
import OnCallDetailsPopup from "./OnCallDetailsPopup";
import noRequests from "../../img/no-requests.png";

const PageWrapper = styled.div`
  .ui-select__wrapper {
    margin-inline-end: 12px;
  }

  .on-call-row {
    position: relative;
  }
`;

interface OnCallsPageProps extends RouteComponentProps, WithTranslation {}

interface OnCallsPageState {
  isLoading: boolean;
  showAddOnCallPopUp: boolean;
  page: number;
  perPage: number;
  totalRecors: number;
  notification: string;
  notificationType: NotificationType | null;
  onCalls: OnCallTableData[];
  directReportsOnly: boolean;
  selectedType: OnCallType | null;
  selectedStatus: OnCallStatus | null;
  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedColumns: string;
  searchValue: string;
  searchObj: Partial<SearchControlOnChangeData>;
  selectedOnCall: OnCallTableData | null;
  selectAllChecked: boolean;
  selectedOnCalls: string[]; // uuids
}

class OnCallsPage extends Component<OnCallsPageProps, OnCallsPageState> {
  static contextType = GlobalContext;
  context!: React.ContextType<typeof GlobalContext>;

  STATUS_TYPES: { value: OnCallStatus | null; label: string }[];
  ON_CALL_TYPES: { value: OnCallType | null; label: string }[];

  constructor(props: OnCallsPageProps) {
    super(props);
    const { t } = props;

    this.ON_CALL_TYPES = [
      { value: null, label: t("Type") },
      { value: OnCallType.onCall, label: t("On-Call Shift") },
      { value: OnCallType.activation, label: t("On-Call Working Time") },
    ];

    this.STATUS_TYPES = [
      { value: null, label: t("Status") },
      { value: OnCallStatus.pending, label: t("Pending") },
      { value: OnCallStatus.approved, label: t("Approved") },
      { value: OnCallStatus.declined, label: t("Declined") },
    ];

    this.state = {
      isLoading: false,
      showAddOnCallPopUp: false,
      page: 1,
      perPage: 25,
      totalRecors: 0,
      notification: "",
      notificationType: null,
      onCalls: [],
      directReportsOnly: false,
      selectedType: null,
      selectedStatus: null,
      startDate: moment().date(1),
      endDate: moment().endOf("month"),
      selectedColumns: getSelectedColumns("employee,type,startTime,endTime,duration,approver", "OnCallsPage"),
      selectedOnCall: null,
      selectAllChecked: false,
      selectedOnCalls: [],
      ...this.getInitialSearch(),
    };
  }

  componentDidMount() {
    this.getState();
  }

  getState = () => [
    this.setState({ isLoading: true }, async () => {
      const { page, perPage, searchObj, selectedStatus, selectedType, directReportsOnly, startDate, endDate } =
        this.state;
      const company = await this.context.getCompany();

      const requestData = {
        companyUuid: company.uuid,
        params: {
          page: String(page),
          perPage: String(perPage),
          from: startDate.format("YYYY-MM-DD"),
          to: endDate.format("YYYY-MM-DD"),
          requestedBy: window.global_store.profile.uuid,
          userProfileUuid: searchObj?.uuid || undefined,
          status: selectedStatus || undefined,
          type: selectedType || undefined,
          groupType: directReportsOnly ? "team" : undefined,
          groupUuid: directReportsOnly ? window.global_store.profile.teams[0]?.uuid : undefined,
        },
      };

      try {
        const res = await getOnCalls(requestData);

        this.setState({
          onCalls: await this.getTableData(res.content || []),
          totalRecors: +(res.metadata?.total || 0),
          isLoading: false,
        });
      } catch (e) {
        Sentry.sendError(e);

        this.setState({
          isLoading: false,
          notification: (e as Error)?.message || (e as string),
          notificationType: NotificationType.error,
        });
      }
    }),
  ];

  getInitialSearch = (): { searchValue: string; searchObj: Partial<SearchControlOnChangeData> } => ({
    searchValue: "",
    searchObj: hasEmployeesAccess()
      ? {}
      : {
          id: window.global_store.profile.id,
          uuid: window.global_store.profile.uuid,
        },
  });

  getOnCallDuration = (startTime: moment.Moment, endTime: moment.Moment): string => {
    const duration = moment.duration(endTime.diff(startTime));

    const hrs = Math.floor(duration.asHours());
    const mins = duration.minutes();

    return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : mins}`;
  };

  getTableData = async (onCalls: OnCall[]): Promise<OnCallTableData[]> => {
    const onCallProfiles = new Set<string>();
    onCalls.forEach((oc) => {
      oc.userProfileUuid && onCallProfiles.add(oc.userProfileUuid);
      oc.reviewedBy && onCallProfiles.add(oc.reviewedBy);
    });

    const company = await this.context.getCompany();
    const { content } = await listUserProfilesWIthFilters(company.uuid, {
      ...baseByUuidPayload(window.global_store.profile.uuid, [...onCallProfiles]),
      fields: ["id", "uuid", "fullName", "avatarId", "position.title", "matricula", "taxPayerId", "user.active"],
    });

    return onCalls.map((oc): OnCallTableData => {
      const startTime = moment(momentTz.tz(oc.startTime, oc.timezone));
      const endTime = moment(momentTz.tz(oc.endTime, oc.timezone));
      const employee = content.find((e) => e.uuid === oc.userProfileUuid);
      const approver = content.find((e) => e.uuid === oc.reviewedBy);

      return {
        employee: {
          avatarId: employee?.avatarId || "",
          fullName: employee?.fullName || "",
          position: employee?.position?.title || "",
        },
        userProfileUuid: oc.userProfileUuid,
        matricula: employee?.matricula || "",
        cpf: employee.taxPayerId || "",
        type: oc.type,
        startDate: startTime.format("DD/MM/YYYY"),
        startTime: startTime.format("HH:mm"),
        endDate: endTime.format("DD/MM/YYYY"),
        endTime: endTime.format("HH:mm"),
        duration: this.getOnCallDuration(startTime, endTime),
        approver: approver?.fullName || "",
        status: oc.status,
        createdBy: oc.createdBy,
        uuid: oc.uuid,
        locked: oc.locked,
      };
    });
  };

  getColumns = (): iColumn<OnCallTableData>[] => {
    const { t } = this.props;
    const { onCalls, selectAllChecked, selectedOnCalls } = this.state;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);
    const showCheckboxes = hasPermisionAccess(PermissionSectionName.onCallManagement);
    const columns: iColumn<OnCallTableData>[] = [];

    if (showCheckboxes) {
      columns.push({
        accessor: "uuid",
        headerClassName: "checkbox-header",
        Cell: (row: iCellInfo<OnCallTableData, string>): JSX.Element => (
          <CellCheckbox
            row={row}
            entityIdKey={null}
            checked={selectedOnCalls.includes(row.value)}
            onChange={this.onRowCheck}
            isCheckboxDisabled={!this.canUpdateStatus(row.original)}
            isRowLocked={row.original.locked}
          />
        ),
        Header: onCalls?.length ? (
          <CellCheckboxAll
            entities={onCalls}
            checked={selectAllChecked}
            onChange={this.onSelectAllCheck}
            isEntitySelectable={(onCall) => this.canUpdateStatus(onCall)}
          />
        ) : null,
        minWidth: 50,
        style: { fontWeight: "500" },
        align: "center",
        locked: true,
      });
    }

    columns.push(
      ...[
        {
          label: t("Employee"),
          locked: true,
          accessor: "employee",
          Cell: ({ value }: iCellInfo<OnCallTableData, OnCallTableData["employee"]>) => (
            <User
              user={{
                fullName: value.fullName,
                avatarId: value.avatarId,
                position: value.position,
              }}
            />
          ),
        },
        {
          label: t("Matricula"),
          accessor: "matricula",
        },
        {
          label: employeeTaxIdLabel,
          accessor: "cpf",
        },
        {
          label: t("Type"),
          accessor: "type",
          Cell: ({ value }: iCellInfo<OnCallTableData, OnCallType>) => <div>{t(value)}</div>,
        },
        {
          label: t("Start"),
          accessor: "startDate",
          Cell: ({ value, original }: iCellInfo<OnCallTableData, string>) => (
            <div>
              {value} - {original.startTime}
            </div>
          ),
        },
        {
          label: t("End"),
          accessor: "endDate",
          Cell: ({ value, original }: iCellInfo<OnCallTableData, string>) => (
            <div>
              {value} - {original.endTime}
            </div>
          ),
        },
        {
          label: t("Druration"),
          accessor: "duration",
        },
        {
          label: t("Approved"),
          accessor: "approver",
        },
        {
          label: t("Status"),
          locked: true,
          accessor: "status",
          Cell: ({ value, original }: iCellInfo<OnCallTableData, OnCallStatus>) => {
            const statusBadgeType = {
              [OnCallStatus.approved]: StatusBadgeStatuses.approved,
              [OnCallStatus.declined]: StatusBadgeStatuses.declined,
              [OnCallStatus.pending]: StatusBadgeStatuses.pending,
            }[value];

            return (
              <div>
                <StatusBadge value={t(value)} type={statusBadgeType} />

                {this.canUpdateStatus(original) && (
                  <TableButtons className="buttons">
                    <TableButton
                      onClick={async (ev) => {
                        ev.stopPropagation();

                        await this.updateOnCallStatus([original.uuid], OnCallStatus.approved);
                      }}
                    >
                      {t("Approve")}
                    </TableButton>
                    <TableButton
                      onClick={async (ev) => {
                        ev.stopPropagation();

                        await this.updateOnCallStatus([original.uuid], OnCallStatus.declined);
                      }}
                    >
                      {t("Decline")}
                    </TableButton>
                  </TableButtons>
                )}
              </div>
            );
          },
        },
      ],
    );

    return columns;
  };

  canUpdateStatus = (onCall: OnCallTableData | null) => {
    if (!onCall) {
      return false;
    }

    const userProfile = window.global_store.profile;

    const isLocked = onCall.locked;
    const isPending = onCall.status === OnCallStatus.pending;
    const isOwnRequest = onCall.userProfileUuid === userProfile.uuid;

    return !isOwnRequest && !isLocked && isPending && hasPermisionAccess(PermissionSectionName.onCallManagement);
  };

  onSelectAllCheck = (isChecked: boolean, selectedOnCalls: string[]): void => {
    this.setState({ selectAllChecked: isChecked, selectedOnCalls });
  };

  onRowCheck = (isChecked: boolean, onCallUuid: string): void => {
    const { selectAllChecked, selectedOnCalls } = this.state;
    let newSelectedOnCalls;

    if (isChecked) {
      newSelectedOnCalls = [...selectedOnCalls, onCallUuid];
    } else {
      newSelectedOnCalls = selectedOnCalls.filter((uuid) => uuid !== onCallUuid);
    }

    this.setState({
      selectedOnCalls: newSelectedOnCalls,
      selectAllChecked: !newSelectedOnCalls.length ? false : selectAllChecked,
    });
  };

  onColumnsChange = (selectedColumns: string[]) => {
    if (localStorage) {
      localStorage.setItem("customColumns_OnCallsPage", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onCreateOnCall = (err?: Error) => {
    const { t } = this.props;

    this.setState(
      {
        showAddOnCallPopUp: false,
        notification: err ? err?.message || String(err) : t("On Call has been created succesfully"),
        notificationType: err ? NotificationType.error : NotificationType.success,
      },
      () => {
        if (!err) {
          void this.getState();
        }
      },
    );
  };

  onDeleteOnCall = (err?: Error) => {
    const { t } = this.props;

    this.setState(
      {
        selectedOnCall: null,
        selectedOnCalls: [],
        selectAllChecked: false,
        notification: err ? err?.message || String(err) : t("On Call has been deleted succesfully"),
        notificationType: err ? NotificationType.error : NotificationType.success,
      },
      () => {
        if (!err) {
          void this.getState();
        }
      },
    );
  };

  updateOnCallStatus = async (onCallUuids: string[], status: OnCallStatus) => {
    const { t } = this.props;
    const { profile, company } = window.global_store;

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });

      try {
        await updateOnCallStatus({
          companyUuid: company.uuid,
          status,
          body: {
            content: {
              updatedBy: profile.uuid,
              reviewedBy: profile.uuid,
              onCallUuids,
            },
          },
        });

        this.setState({
          notification: t("On Call has been updated succesfully"),
          notificationType: NotificationType.success,
        });
      } catch (e) {
        this.setState({
          notification: (e as Error)?.message || String(e),
          notificationType: NotificationType.error,
        });
      } finally {
        this.setState(
          {
            selectedOnCall: null,
            selectedOnCalls: [],
            selectAllChecked: false,
          },
          this.getState,
        );
      }
    }
  };

  render() {
    const { t } = this.props;
    const {
      onCalls,
      isLoading,
      notification,
      notificationType,
      page,
      perPage,
      totalRecors,
      directReportsOnly,
      selectedStatus,
      selectedType,
      startDate,
      endDate,
      selectedColumns,
      searchValue,
      selectedOnCall,
      showAddOnCallPopUp,
      selectedOnCalls,
    } = this.state;
    const availableDaysCount = 60;

    return (
      <FullPage
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("Add On-Call")}
            onClick={() => this.setState({ showAddOnCallPopUp: true })}
          />
        }
      >
        <PageWrapper>
          {notification && (
            <NotificationRow
              style={{ marginTop: 20 }}
              type={notificationType}
              onClose={() => this.setState({ notification: "", notificationType: NotificationType.success })}
              message={notification}
            />
          )}

          <TablePage<OnCallTableData>
            loading={isLoading}
            rows={onCalls}
            columns={this.getColumns()}
            selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
            onColumnsChange={this.onColumnsChange}
            showPagination
            columnSelectorOnFiltersRow
            filters={
              <>
                {hasEmployeesAccess() && hasPermisionAccess(PermissionSectionName.onCallManagement) && (
                  <PageSearch>
                    <SearchControl
                      value={searchValue}
                      onClear={() => this.setState({ ...this.getInitialSearch() }, this.getState)}
                      onChange={(val: SearchControlOnChangeData) =>
                        this.setState(
                          { searchValue: val.label as string, searchObj: val, page: 1, directReportsOnly: false },
                          this.getState,
                        )
                      }
                      placeholder={translateEmployeeTerm(
                        t,
                        TranslationNamespaces.common,
                        "custom-search-employees",
                        `${TranslationNamespaces.common}|Search Employees`,
                      )}
                      permissionSection={PermissionSectionName.onCallManagement}
                    />
                  </PageSearch>
                )}
                {isSupervisor() && (
                  <AdditionalFiltersControl
                    options={[
                      {
                        label: t("Direct reports only"),
                        checked: directReportsOnly,
                        onChange: (val) =>
                          this.setState({ directReportsOnly: val, ...this.getInitialSearch() }, () => {
                            this.getState();
                          }),
                        // hide: !isSupervisor(),
                      },
                    ]}
                  />
                )}
                <DateRangePicker
                  newOnChangeApproach
                  isAdmin={hasEmployeesAccess()}
                  onChange={(sd, ed) => {
                    if (sd && ed) {
                      this.setState({ startDate: sd, endDate: ed, page: 1 }, () => {
                        this.getState();
                      });
                    }
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  availableDaysCount={availableDaysCount}
                />
                <Select
                  onChange={(v: OnCallType | null) =>
                    this.setState({ selectedType: v, page: 1 }, () => {
                      this.getState();
                    })
                  }
                  value={selectedType}
                  options={this.ON_CALL_TYPES}
                  placeholder={t("Type")}
                />
                <Select
                  onChange={(v: OnCallStatus | null) =>
                    this.setState({ selectedStatus: v, page: 1 }, () => {
                      this.getState();
                    })
                  }
                  value={selectedStatus}
                  options={this.STATUS_TYPES}
                  placeholder={t("Status")}
                />
              </>
            }
            PaginationComponent={() => (
              <PaginationNew
                totalRecords={totalRecors}
                pageLimit={perPage}
                currentPage={page}
                onPageLimitChange={(pageLimit, currentPage) =>
                  this.setState({ perPage: pageLimit, page: currentPage }, this.getState)
                }
                onPageChange={(currentPage) => {
                  this.setState({ page: currentPage }, this.getState);
                }}
              />
            )}
            noContentComponent={<NoContent img={noRequests}>{t("No on-call requests")}</NoContent>}
            getTrProps={(_, rowInfo) => ({
              onClick: () => this.setState({ selectedOnCall: rowInfo?.original || null }),
              className: "on-call-row",
            })}
          />

          <OnCallDetailsPopup
            isOpen={!!selectedOnCall}
            onCall={selectedOnCall}
            onClose={() => this.setState({ selectedOnCall: null })}
            updateStatus={this.updateOnCallStatus}
            onDelete={this.onDeleteOnCall}
            canUpdateStatus={this.canUpdateStatus(selectedOnCall)}
          />

          <OnCallCreatePopup
            isOpen={showAddOnCallPopUp}
            onClose={() => this.setState({ showAddOnCallPopUp: false })}
            onYes={this.onCreateOnCall}
          />

          {!isLoading && (
            <ApproveDeclineActionBar
              selectedItems={selectedOnCalls}
              onApprove={() => this.updateOnCallStatus(selectedOnCalls, OnCallStatus.approved)}
              onDelcine={() => this.updateOnCallStatus(selectedOnCalls, OnCallStatus.declined)}
              onUncheckAll={() => this.onSelectAllCheck(false, [])}
            />
          )}
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withTranslation(TranslationNamespaces.onCalls)(OnCallsPage);
