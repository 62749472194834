import { stylesheet } from "astroturf";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import moment from "moment-timezone";
import { useMemo } from "react";
import cx from "classnames";
import pure from "recompose/pure";
import { ProjRes } from "services/oitchau-api/types";
import { ActivitiesListGroup, ActivitiesListGroupProps } from "../ActivitiesListGroup";
import { InfiniteScroll } from "../InfiniteScroll";
import { IconListEnd, IconNoActivities } from "../styled";
import { AddActivityButtons } from "../AddActivityButtons";

const styles = stylesheet`
.List {
  height: 100%;
  overflow: overlay;

  .NoActsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .NoActsTitle {
      padding-top: 24px;
      padding-bottom: 4px;
      font-size: 20px;
      font-weight: var(--typography-font-weight-default);
      line-height: 100%;
      color: var(--colors-surface-900-p);
      letter-spacing: -0.44px;
    }

    .NoActsSubtitle {
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-default);
      line-height: 130%;
      color: var(--colors-surface-600);
    }
  }

  .Day {
    .DayTitle {
      position: sticky;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 16px;
      font-size: 13px;
      font-weight: var(--typography-font-weight-medium);
      line-height: 100%;
      color: var(--colors-surface-800);
      background: var(--colors-surface-50);
      border-bottom: 1px solid var(--colors-surface-150);

      .Total {
        display: flex;

        .Duration {
          display: inline-block;
          min-width: 39px;
          text-align: end;
        }
      }

    }

    .EmptyToday {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 110px;
      font-size: 13px;
      font-weight: var(--typography-font-weight-default);
      line-height: 17px;
      color: var(--colors-secondary5);
      border-bottom: 1px solid var(--colors-surface-150);;
    }

    .Groups {
      display: flex;
      flex-direction: column;
    }
  }

  .Day.Wireframe {
    @use 'styles/loading';

    .DayTitle {
      & > :first-child {
        @extend .wireframe;
      }

      .Duration {
        @extend .wireframe;

        width: 29px;
        margin-inline-start: 10px;
      }
    }
  }

  .ListEnd {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    font-size: 13px;
    font-weight: var(--typography-font-weight-default);
    line-height: 100%;
    color: var(--colors-surface-800);
    background-color: var(--colors-surface-50);
    cursor: pointer;
  }
}
`;

function DayTitle(props: { date: string; duration: string }) {
  const { date, duration } = props;
  return (
    <div className={styles.DayTitle}>
      <div>{date}</div>
      <div className={styles.Total}>
        <span className={styles.Duration}>{duration}</span>
      </div>
    </div>
  );
}

export interface ActivitesDayProps {
  date: string;
  duration: string;
  groups: ActivitiesListGroupProps[];
  onEditItem?: (item: { id: string }) => void;
  onRepeatItem?: (item: { id: string }) => void;
  wireframe?: boolean;
  onExpandActivitiesGroup: (numItems: number) => void;
}
function ActivitiesListDay(props: ActivitesDayProps) {
  const { date, duration, groups, onEditItem, onRepeatItem, wireframe, onExpandActivitiesGroup } = props;
  return (
    <div key={date} className={cx(styles.Day, { [styles.Wireframe]: wireframe })}>
      <DayTitle date={date} duration={duration} />
      <div className={styles.Groups}>
        {groups.map((group) => (
          <ActivitiesListGroup
            key={group.id}
            {...group}
            onEditItem={onEditItem}
            onRepeatItem={group.isRepeatable ? onRepeatItem : undefined}
            wireframe={wireframe}
            onExpandActivitiesGroup={onExpandActivitiesGroup}
          />
        ))}
      </div>
    </div>
  );
}

const wireframeGroupsData = [
  {
    id: "1",
    client: "ldsjflkdsjfldsj",
    task: "dsljfldsjfldsjfdsljds123214324f",
    project: "lsjfldsjfldsjfdsfds",
    duration: "3:00",
    items: [],
  },
  {
    id: "2",
    client: "client sldfj sdljfds",
    task: "task slfjdslk lsdjfls435 l",
    project: "project slfdsj",
    duration: "3:00",
    items: [],
  },
];

export interface ActivitiesListProps extends React.HTMLProps<HTMLDivElement> {
  /** YYYY-MM-DD */
  today: string;
  days: ActivitesDayProps[];
  projects: ProjRes[];
  onEditItem: (item: { id: string }) => void;
  onRepeatItem: (item: { id: string }) => void;
  loadDays: () => Promise<boolean>;
  hasMoreDays: boolean;
  loading: boolean;
  onStart: () => void;
  onAdd: () => void;
  showAddActivityButtons: boolean;
  onExpandActivitiesGroup: (numItems: number) => void;
}

function formatDateShort(date: string, currentYear: string) {
  const m = moment(date, "YYYY-MM-DD");
  const short = [m.format("ddd"), ...m.format("ll").split(", ")].filter((part) => part !== currentYear).join(", ");
  return short;
}

export const ActivitiesList = pure((props: ActivitiesListProps) => {
  const {
    today,
    days: daysProp,
    projects,
    onEditItem,
    onRepeatItem,
    loadDays,
    hasMoreDays,
    loading,
    onAdd,
    onStart,
    showAddActivityButtons,
    onExpandActivitiesGroup,
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);

  const projectsDict = useMemo(() => {
    const prjs: { [prjUuid: string]: { [tskUuid: string]: true } } = {};
    for (const prj of projects || []) {
      prjs[prj.uuid] = {};
      for (const tsk of prj.tasks) {
        prjs[prj.uuid][tsk.uuid] = true;
      }
    }
    return prjs;
  }, [projects]);

  const days = useMemo(() => {
    const ds = (daysProp || []).map((d) => {
      const grs = d.groups.map((g) => {
        const isRepeatable = projectsDict[g.projectUuid]?.[g.taskUuid] || false;
        return { ...g, isRepeatable };
      });
      return { ...d, groups: grs };
    });
    return ds;
  }, [daysProp, projectsDict]);

  const getDateName = useMemo(() => {
    const yesterday = moment(today, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD");
    return (date: string) => {
      if (date === today) return t("Today");
      if (date === yesterday) return t("Yesterday");
      return formatDateShort(date, moment().format("YYYY"));
    };
  }, [today, t]);

  const hasToday = useMemo(() => days.some((d) => d.date === today), [days, today]);

  return (
    <InfiniteScroll
      className={styles.List}
      loadMore={loadDays}
      hasMore={hasMoreDays}
      preloadScreens={1}
      initialPreloadScreens={1}
      isEmpty={days.length === 0}
      listEmpty={
        <div className={styles.NoActsContainer}>
          <IconNoActivities />
          <div className={styles.NoActsTitle}>{t("No activities this week")}</div>
          <div className={styles.NoActsSubtitle}>{t("Good thing come to those who wait")}</div>
        </div>
      }
      listEnd={
        <div className={styles.ListEnd}>
          <IconListEnd />
          <div>{t("You've reached the end")}</div>
        </div>
      }
    >
      {showAddActivityButtons && <AddActivityButtons onAdd={onAdd} onStart={onStart} />}
      {days.length > 0 && !hasToday && (
        <div key="today" className={cx(styles.Day)}>
          <DayTitle date={t("Today")} duration="00:00" />
          <div className={styles.EmptyToday}>{t("No task activities reported today")}</div>
        </div>
      )}
      {}
      {days.map((day) => (
        <ActivitiesListDay
          onExpandActivitiesGroup={onExpandActivitiesGroup}
          key={day.date}
          duration={day.duration}
          date={getDateName(day.date)}
          groups={day.groups}
          onEditItem={onEditItem}
          onRepeatItem={onRepeatItem}
        />
      ))}
      {loading && (
        <ActivitiesListDay
          wireframe
          key="loading"
          duration="8:00"
          date="ldsjfl lsdjflds dsjf"
          groups={wireframeGroupsData}
        />
      )}
    </InfiniteScroll>
  );
});
