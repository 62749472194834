import { stylesheet } from "astroturf";
import cx from "classnames";
import { IconWarning } from "../styled";
import { Button, ButtonType } from "../Button";
import { Modal } from "../Modal";

export const styles = stylesheet`
.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  > * {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .Popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    padding: 32px 24px 24px;
    background: var(--colors-surface-0);
    border-radius: 8px;

    .Icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: var(--colors-primary-50);
      border-radius: 50%;

      &.warn {
        background: var(--colors-warning);
      }
    }

    .Header {
      margin-top: 16px;
      font-size: 20px;
      font-weight: var(--typography-font-weight-medium);
      line-height: 100%;
      color: var(--colors-surface-900-p);
    }

    .Message {
      margin-top: 12px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-secondary5);
      text-align: center;
      white-space: pre-wrap;
    }

    .Buttons {
      display: flex;
      gap: 13px;
      justify-content: center;
      width: 100%;
      margin-top: 32px;

      .Button {
        padding: 14px;
        font-size: 12px;
        font-weight: var(--typography-font-weight-bold);
        line-height: 100%;
        color: #455;
        color: var(--colors-surface-0);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        background: var(--colors-primary-500-p);

        &.cancel {
          color: var(--colors-surface-900-p);
          background: var(--colors-surface-100);
        }

        &.confirm {
          color: var(--colors-surface-0);
          background: var(--colors-primary-500-p);
        }

        &.warn {
          color: var(--colors-surface-0);
          background: var(--colors-danger-500);
        }
      }

      &.vertical {
        flex-direction: column-reverse;
        padding: 0 8px;

        .Button {
          &.cancel {
            background: transparent;
          }
        }
      }
    }
  }
}
`;

export type ModalMessageProps = React.HTMLProps<HTMLDivElement> & {
  header: string;
  message: string;
  onOk(): void;
  onCancel(): void;
  okText?: string;
  cancelText?: string;
  /** show two buttons, otherwise show only "ok" button */
  choice?: boolean;
  warning?: boolean;
  /** show buttons on top of each other */
  vertical?: boolean;
  icon?: React.Component;
  iconBackground?: string;
  /** ignore background click, insist on clicking a button */
  obligatory?: boolean;
};

export function ModalMessage(props: ModalMessageProps) {
  const {
    header,
    message,
    onOk,
    onCancel,
    okText,
    cancelText,
    choice,
    warning,
    vertical,
    icon: Icon = IconWarning,
    iconBackground: bg,
    obligatory,
  } = props;

  return (
    <Modal className={styles.Wrapper} onBackgroundClick={obligatory ? undefined : onCancel}>
      <div className={styles.Popup}>
        <div className={cx(styles.Icon, { [styles.warn]: warning })} style={{ background: bg }}>
          <Icon />
        </div>
        <div className={styles.Header}>{header}</div>
        {message && <div className={styles.Message}>{message}</div>}
        <div className={cx(styles.Buttons, { [styles.vertical]: vertical })}>
          {choice && (
            <>
              <Button buttonType={ButtonType.regular} className={cx(styles.Button, styles.cancel)} onClick={onCancel}>
                {cancelText}
              </Button>
              <Button
                buttonType={ButtonType.regular}
                className={cx(styles.Button, warning ? styles.warn : styles.confirm)}
                onClick={onOk}
              >
                {okText}
              </Button>
            </>
          )}
          {!choice && (
            <Button className={styles.Button} onClick={onOk}>
              {okText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
