import { Component } from "react";
import BEM from "utils/BEM";
import "components/UI/Page/Page.scss";
import { withTranslation } from "react-i18next";
import { getPayrollReport, getPayrollLayout } from "utils/apiHelpers";
import { urlParam, getPhasesDays, getCrossShiftsPhaseLabel } from "utils/common";
import FullPage from "components/Layout/FullPage";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import styled from "styled-components";
import NoContent from "components/NoContent";
import DownloadControl from "components/DownloadControl";
import DownloadButton from "components/UI/DownloadButton";
import * as images from "components/svg-images";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import { getServicesList } from "components/Projects/projectsApiUtils";
import Button, { ButtonState } from "components/controls/StyledButton";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { TranslationNamespaces } from "types/translationNamespaces";
import { EVENT_REQUESTS_REGEX } from "components/Reports/helpers";
import {
  isNightShiftEventType,
  getNightShiftType,
  PROJECT_SERVICES_KEY,
  PHASE_CUSTOM_NAME_KEY,
  GroupPrefixes,
  getEventTypeByPrefixes,
  getBreakTypesLabel,
  CROSS_SHIFTS_PHASES_KEY,
} from "components/Payroll/GroupedDropdown/helpers";
import { BreakStatusOptions } from "utils/api/types";
import { getCustomBreaksList } from "utils/api/schedule";
import { DEFAULT_BREAK_NAME } from "components/Schedules/Breaks/constants";
import { DSR_PHASE_KEY } from "components/Payroll/types";
import GlobalContext from "../../context/global-context";
import noPayrolls from "../../img/no-schedules.png";
import PayrollInProgressRow from "./PayrollInProgressRow";

const b = BEM.b("payroll");
const p = BEM.b("page");

const Spacer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  .table-page__details-row {
    display: none;
  }
`;

/**
 * Given function will return subtype by it's uuid from requests types tree
 */
const getSubtypeByUuid = (typesTree, uuid) => {
  const result = typesTree.reduce((acc, item) => {
    const subtype = item.subtypes.find((subtype) => subtype.uuid === uuid);
    if (subtype) {
      // eslint-disable-next-line no-param-reassign
      acc = subtype;
    }
    return acc;
  }, null);
  return result;
};

class PayrollReport extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      rows: [],
      loading: true,
      notification: "",
      services: {},
      recalculateButtonEnabled: false,
    };
    document.title = t("Payroll Overview");
  }

  componentDidMount() {
    this.getState();
  }

  async getState(forceRecalculate) {
    const from = urlParam("from");
    const to = urlParam("to");
    const groupType = urlParam("groupType");
    const groupUuid = urlParam("groupUuid");
    const payrollUUID = this.props.id;

    this.setState({ loading: true, inProgress: false, recalculateNotification: false });
    const company = await this.context.getCompany();
    const [reportResponse, payrollLayoutResponse, requestTypes, breakTypesResponse] = await Promise.all([
      getPayrollReport({
        payrollUUID,
        from,
        to,
        groupType,
        groupUuid,
        requestedBy: window.global_store.profile.uuid,
        forceRecalculate,
      }),
      getPayrollLayout({ payrollUUID }),
      this.context.getRequestTypes(),
      getCustomBreaksList({
        perPage: 300,
        page: 1,
        statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
        companyUuid: company.uuid,
        requestedBy: window.global_store.profile.uuid,
      }),
    ]);

    const payrollLayout = payrollLayoutResponse.content || {};

    const rows = reportResponse.content || [];
    const inProgress =
      reportResponse?.metadata?.status === "pending" ||
      (reportResponse?.metadata?.status === "completed" && Object.keys(reportResponse.metadata.fileUrls).length === 0);

    let recalculateNotification = false;
    if (!inProgress && reportResponse?.metadata?.updatedAt) {
      recalculateNotification =
        payrollLayout.updatedAt && moment(payrollLayout.updatedAt).isAfter(moment(reportResponse.metadata.updatedAt));
    }

    let services = {};
    const servicesUuids = [
      ...payrollLayout.payrollEvents.reduce((accSet, event) => {
        if (event.extraOptions.serviceUuid) {
          accSet.add(event.extraOptions.serviceUuid);
        }
        return accSet;
      }, new Set()),
    ];

    if (servicesUuids) {
      const company = await this.context.getCompany();
      let allServices = await getServicesList({ companyUuid: company.uuid });
      allServices = allServices?.content || [];
      // get services names
      services = allServices.reduce((acc, service) => {
        acc[service.uuid] = service.name;
        return acc;
      }, {});
    }

    this.setState({
      rows,
      notification: "",
      inProgress,
      recalculateNotification,
      downloadUrls: this.getDownloadUrls(reportResponse),
      loading: false,
      services,
      recalculateButtonEnabled: !inProgress && !recalculateNotification,
      requestTypes,
      breakTypes: breakTypesResponse.content || [],
    });
  }

  getDownloadUrls(response) {
    const downloadUrls = [];
    if (response?.metadata?.fileUrls) {
      if (urlParam("skipEmptyValues") === "true") {
        if (response.metadata.fileUrls.skipEmptyValues) {
          downloadUrls.push(response.metadata.fileUrls.skipEmptyValues);
        }
        if (response.metadata.fileUrls.skipEmptyValuesCSV) {
          downloadUrls.push(response.metadata.fileUrls.skipEmptyValuesCSV);
        }
      } else {
        if (response.metadata.fileUrls.regular) {
          downloadUrls.push(response?.metadata?.fileUrls.regular);
        }
        if (response.metadata.fileUrls.regularCSV) {
          downloadUrls.push(response.metadata.fileUrls.regularCSV);
        }
      }
    }
    return downloadUrls;
  }
  getTableColumns(rows) {
    const { t } = this.props;
    const { services, requestTypes, breakTypes } = this.state;
    let columns = [];

    if (rows.length) {
      const keys = Object.keys(rows[0]).filter((row) => row !== "externalId" && row !== "id");
      columns = keys.map((key) => {
        let label = key;

        if (label.indexOf(`${DSR_PHASE_KEY}_`) === 0) {
          label = `DSR ${label.substring(DSR_PHASE_KEY.length + 1)}%`;
        } else if (isNightShiftEventType(label)) {
          const eventType = getNightShiftType(label);
          if (eventType?.name) {
            const { name, value } = eventType;
            label = t(`${TranslationNamespaces.payroll}|${name}`, { value });
          } else {
            label = t("Night Shift");
          }
        } else if (label.indexOf(PROJECT_SERVICES_KEY) === 0) {
          const match = label.match(/(projects_services\[)(.*)]/);
          label = `${t("Service")} ${match && services[match[2]] ? services[match[2]] : ""}`;
        } else if (label.indexOf(CROSS_SHIFTS_PHASES_KEY) === 0) {
          const match = label.match(/(cross_shifts_interval_phases\[)(.*)]/);
          const [, limit, name] = match[2].match(/(\d*)_(.*)/);
          label = getCrossShiftsPhaseLabel(name, limit);
        } else if (label.indexOf(PHASE_CUSTOM_NAME_KEY) === 0) {
          label = label.substring(PHASE_CUSTOM_NAME_KEY.length);
        } else if (
          label.indexOf(GroupPrefixes.extra_hours_phases_from_expired_hours_bank) === 0 ||
          label.indexOf(GroupPrefixes.extra_hours_phases_from_punches) === 0
        ) {
          const { name: prefix, value } =
            getEventTypeByPrefixes(label, [
              GroupPrefixes.extra_hours_phases_from_expired_hours_bank,
              GroupPrefixes.extra_hours_phases_from_punches,
            ]) || {};

          if (prefix && value) {
            if (prefix === GroupPrefixes.extra_hours_phases_from_expired_hours_bank) {
              label = `${t("Extra Hours from Expired Hours Bank")} `;
            }
            if (prefix === GroupPrefixes.extra_hours_phases_from_punches) {
              label = `${t("Extra Hours from Punches")} `;
            }
            if (value.match(/(\d{8})_([^_]*)_?(day|night)?/)) {
              const match = value.match(/(\d{8})_([^_]*)_?(day|night)?/);
              label += `${getPhasesDays({ t }).daysMasksObj[match[1]]} ${match[2]}%`;
              if (match[3]) {
                label += ` (${t(match[3])})`;
              }
            } else if (value.match(/([a-zA-Z]+)_(\d+)_?(day|night)?/)) {
              const match = value.match(/([a-zA-Z]+)_(\d+)_?(day|night)?/);
              label += `${getPhasesDays({ t, isDayTypeBasedOnSchedule: true }).daysMasksObj[match[1]]} ${match[2]}%`;
              if (match[3]) {
                label += ` (${t(match[3])})`;
              }
            }
          }
        } else if (label.match(/(\d{8})_([^_]*)_?(day|night)?/)) {
          const match = label.match(/(\d{8})_([^_]*)_?(day|night)?/);
          label = `${getPhasesDays({ t }).daysMasksObj[match[1]]} ${match[2]}%`;
          if (match[3]) {
            label += ` (${t(match[3])})`;
          }
        } else if (label.match(/([a-zA-Z]+)_(\d+)_?(day|night)?/)) {
          const match = label.match(/([a-zA-Z]+)_(\d+)_?(day|night)?/);
          label = `${getPhasesDays({ t, isDayTypeBasedOnSchedule: true }).daysMasksObj[match[1]]} ${match[2]}%`;
          if (match[3]) {
            label += ` (${t(match[3])})`;
          }
        } else if (label.match(EVENT_REQUESTS_REGEX)) {
          const [, type, uuid] = label.match(EVENT_REQUESTS_REGEX) || [];
          let name = uuid;

          if (type === "request_subtypes_days" || type === "request_subtypes_hours") {
            const requestSubType = getSubtypeByUuid(requestTypes, uuid);
            if (requestSubType) {
              name = !requestSubType.translationKey
                ? requestSubType.name
                : `${TranslationNamespaces.requestsPageTmp}|${requestSubType.translationKey}`;
            }
            label = `${t("Request subtype")} - ${name}`;
            if (type === "request_subtypes_days") {
              label += " (days)";
            } else if (type === "request_subtypes_hours") {
              label += " (hours)";
            }
          } else if (type === "request_types_days" || type === "request_types_hours") {
            const requestType = requestTypes.find((requestType) => requestType.uuid === uuid);

            if (requestType) {
              name = !requestType.userDefined
                ? t(`${TranslationNamespaces.requestsPageTmp}|${requestType.name}`)
                : requestType.name;
            }

            label = `${t("Request type")} - ${name}`;

            if (type === "request_types_days") {
              label += ` (${t("days")})`;
            } else if (type === "request_types_hours") {
              label += ` (${t("hours")})`;
            }
          } else {
            label = `UNKNOWN: ${name}`;
          }
        } else if (label.indexOf(GroupPrefixes.break_types) === 0) {
          const { name: prefix, value: breakTypeUuid } =
            getEventTypeByPrefixes(label, [
              GroupPrefixes.break_types,
              GroupPrefixes.break_types_day,
              GroupPrefixes.break_types_night,
              GroupPrefixes.break_types_on_holidays,
              GroupPrefixes.break_types_on_not_holidays,
            ]) || {};

          if (prefix && breakTypeUuid) {
            const breakTypeName =
              breakTypes.find((breakType) => breakType.uuid === breakTypeUuid)?.name || DEFAULT_BREAK_NAME;
            label = getBreakTypesLabel(`${prefix}[${breakTypeName}]`);
          }
        } else if (label.indexOf(GroupPrefixes.hours_distribution) === 0) {
          const { value: hdRuleName } = getEventTypeByPrefixes(label, [GroupPrefixes.hours_distribution]) || {};

          if (hdRuleName) {
            label = t("hours_distribution-col", { name: hdRuleName, interpolation: { escapeValue: false } });
          } else {
            label = t("hours_distribution");
          }
        } else {
          label = t(label);
        }

        return {
          label,
          accessor: key,
          Cell: (row) => row.original[row.column.id],
          locked: true,
          width: 160,
          style: { lineHeight: "36px" },
          align: key === "name" || key === "matricula" ? "start" : "end",
        };
      });
    } else {
      columns.push({
        label: "ID",
      });
    }
    return columns;
  }

  getHeaderAction = () => {
    const { t, flags } = this.props;
    const { inProgress, recalculateNotification, downloadUrls, recalculateButtonEnabled } = this.state;

    if (!inProgress && !recalculateNotification && downloadUrls?.length) {
      return (
        <div className={p("header-action")}>
          {flags.payrollExportRecalculate && (
            <Button
              style={{ marginInlineEnd: "10px" }}
              state={ButtonState.outline}
              value={t("Force Recalculate")}
              disabled={!recalculateButtonEnabled}
              onClick={() => {
                this.setState({ recalculateButtonEnabled: false });
                this.getState(true);
              }}
            />
          )}
          {downloadUrls.length === 1 ? (
            <DownloadButton
              onClick={() => {
                const link = document.createElement("a");
                link.href = downloadUrls[0];
                document.body.appendChild(link);
                link.click();
              }}
            >
              {images.downloadIcon} {t("common|Download")}
            </DownloadButton>
          ) : (
            <DownloadControl
              modifiers={{ filled: true }}
              placeholder={t("common|Download")}
              options={downloadUrls
                .map((url) => {
                  if (url.indexOf(".txt") > -1) {
                    return { label: "TXT", value: url };
                  }
                  if (url.indexOf(".csv") > -1) {
                    return { label: "CSV", value: url };
                  }
                })
                .filter((url) => url)}
              onChange={(value) => {
                const link = document.createElement("a");
                link.href = value;
                document.body.appendChild(link);
                link.click();
              }}
            />
          )}
        </div>
      );
    }

    return null;
  };

  render() {
    const { rows, loading, inProgress, recalculateNotification, notification } = this.state;
    const { t, history } = this.props;

    return (
      <FullPage
        title={t("Payroll Overview")}
        backButtonOnclick={() => history.push("/payroll/lock-periods")}
        backButtonTitle={t("Locking Groups")}
        headerAction={this.getHeaderAction()}
      >
        <Wrapper className={p()}>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={notification} />
          )}

          {inProgress && (
            <PayrollInProgressRow
              title={t("Report is being generated")}
              description={t("Report is still calculating, please refresh")}
              t={t}
              onRefresh={() => this.getState()}
            />
          )}

          {!inProgress && !!recalculateNotification && (
            <PayrollInProgressRow
              t={t}
              title={t("Payroll layout changed")}
              description={t("Payroll layout changed since last report generation. Please refresh the report")}
              onRefresh={() => this.getState(true)}
            />
          )}

          <div className={b()}>
            <TablePage
              withHeaderTooltip
              filters={<Spacer />}
              rows={rows}
              customColumnsAvailable={false}
              columns={this.getTableColumns(rows)}
              className="payroll-table"
              loading={loading}
              noContentComponent={
                <NoContent img={noPayrolls}>{t("You do not have any registered payroll layouts yet")}</NoContent>
              }
              interactive={false}
            />
          </div>
        </Wrapper>
      </FullPage>
    );
  }
}
export default withLDConsumer()(
  withRouter(
    withTranslation([
      TranslationNamespaces.payroll,
      TranslationNamespaces.phases,
      TranslationNamespaces.requestsPageTmp,
    ])(PayrollReport),
  ),
);
