import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Select, { iOption } from "components/UI/FormSelect";
import AddRemoveLink from "components/controls/AddRemoveLink";
import { v4 as uuidv4 } from "uuid";
import { TableButton } from "components/styled/Page";
import { TranslationNamespaces } from "types/translationNamespaces";
import { PayedOvertimePhase } from "types/models/businessRulesGroup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Subheader } from "./styled";
import { getPhaseName } from "./helpers";
import TimeInputWithoutLimits from "./TimeInputWithoutLimits";

const Wrapper = styled.div``;
const FieldContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || "auto"};
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  border-top: 1px solid var(--colors-surface-150);
  padding: 16px 0;
  position: relative;

  &:first-child {
    border-top: none;
  }

  .delete-button {
    display: none;
    position: absolute;
    inset-inline-end: 14px;
    top: 19px;
  }
  &:hover {
    background-color: var(--colors-surface-50);
    .delete-button {
      display: block;
    }
  }
`;

const InnerGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
`;
enum HoursBankLimitPeriodTypes {
  Month = "month",
  Week = "week",
  Cycle = "cycle",
}

enum HoursBankLimitActionTypes {
  ConvertToExtraHours = "convert_to_extra_hours",
  ConvertToExtraHoursPhase = "convert_to_extra_hours_phase",
  SubtractFromPayroll = "subtract_from_payroll",
}

enum HoursBankLimitTypes {
  Positive = "positive",
  Negative = "negative",
}

enum HoursBankLimitSourceModes {
  Total = "total",
  Phase = "phase",
}

interface HoursBankLimit {
  uuid: string;
  limit: number;
  active: boolean;
  type: HoursBankLimitTypes;
  action: HoursBankLimitActionTypes;
  period: HoursBankLimitPeriodTypes;
  periodsNumber: number;
  periodOffsetDays: number;
  sourceMode?: HoursBankLimitSourceModes;
  sourcePhaseUuid?: string;
  targetPhaseUuid?: string;
}

interface RuleProps {
  locked: boolean;
  disabled: boolean;
  rule: HoursBankLimit;
  setRule: (rule: HoursBankLimit) => void;
  onRemoveLimit: (uuid: string) => void;
  phases: PayedOvertimePhase[];
}

const Rule = ({ rule, setRule, onRemoveLimit, locked, disabled, phases }: RuleProps): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);
  const { newHoursBankLimits } = useFlags();
  const { type, period } = rule;
  const sourceValue = rule.sourcePhaseUuid || HoursBankLimitSourceModes.Total;

  const onLimitChange = (value: number): void => {
    setRule({ ...rule, limit: value && Number.isFinite(value) ? value : 0 });
  };

  const actionOptions: Array<iOption<HoursBankLimitActionTypes, string>> = [];
  if (type === HoursBankLimitTypes.Positive) {
    actionOptions.push({
      label: t(HoursBankLimitActionTypes.ConvertToExtraHours),
      value: HoursBankLimitActionTypes.ConvertToExtraHours,
    });
    if (newHoursBankLimits) {
      actionOptions.push({
        label: t(HoursBankLimitActionTypes.ConvertToExtraHoursPhase),
        value: HoursBankLimitActionTypes.ConvertToExtraHoursPhase,
      });
    }
  } else if (type === HoursBankLimitTypes.Negative) {
    actionOptions.push({ label: t("Remove From Payroll"), value: HoursBankLimitActionTypes.SubtractFromPayroll });
  }

  const phasesOptions = newHoursBankLimits
    ? phases.reduce((acc, phase) => {
        acc.push({ label: getPhaseName(phase), value: phase.uuid });

        return acc;
      }, [] as Record<string, string>[])
    : [];

  const sourceOptions = [
    {
      label: t("Total"),
      value: HoursBankLimitSourceModes.Total,
    },
    ...phasesOptions,
  ];

  const periodOptions = useMemo(
    () =>
      newHoursBankLimits
        ? [
            {
              label: t("Weekly"),
              value: `${HoursBankLimitPeriodTypes.Week}_1`,
            },
            ...Array.from({ length: 6 }, (_, i) => ({
              label: t("month-period", { count: i + 1 }),
              value: `${HoursBankLimitPeriodTypes.Month}_${i + 1}`,
            })),
            {
              label: t("Year"),
              value: `${HoursBankLimitPeriodTypes.Month}_12`,
            },
            {
              label: t("Cycle"),
              value: `${HoursBankLimitPeriodTypes.Cycle}_1`,
            },
          ]
        : [
            {
              label: t("Monthly"),
              value: `${HoursBankLimitPeriodTypes.Month}_1`,
            },
          ],
    [newHoursBankLimits],
  );

  return (
    <TableRow>
      <FieldContainer width="208px">
        <Select
          locked={locked}
          disabled={disabled}
          options={sourceOptions}
          value={sourceValue}
          placeholder={t("Select the phase")}
          onChange={(value) => {
            if (value === HoursBankLimitSourceModes.Total) {
              setRule({ ...rule, sourceMode: HoursBankLimitSourceModes.Total, sourcePhaseUuid: undefined });
            } else {
              setRule({ ...rule, sourceMode: HoursBankLimitSourceModes.Phase, sourcePhaseUuid: value });
            }
          }}
        />
      </FieldContainer>
      <InnerGroup>
        <FieldContainer width="120px">
          <Select
            locked={locked}
            disabled={disabled}
            options={[
              { label: t("Positive"), value: HoursBankLimitTypes.Positive },
              { label: t("Negative"), value: HoursBankLimitTypes.Negative },
            ]}
            value={type}
            onChange={(value) => {
              const newRule = { ...rule, type: value };

              if (value === HoursBankLimitTypes.Negative) {
                newRule.action = HoursBankLimitActionTypes.SubtractFromPayroll;
                newRule.targetPhaseUuid = undefined;
              } else {
                newRule.action = HoursBankLimitActionTypes.ConvertToExtraHours;
              }

              setRule(newRule);
            }}
          />
        </FieldContainer>
        <FieldContainer width="80px">
          <TimeInputWithoutLimits
            disabled={disabled}
            locked={locked}
            value={rule.limit}
            onChange={(value: number) => onLimitChange(value)}
          />
          {/* <TextInputControl
            disabled={disabled}
            locked={locked}
            // error={errors && !!errors.toleranceLimit}
            placeholder="00:00"
            value={minsToHrsMins(rule.limit)}
            mask={[
              /[0-9]/,
              /[0-9]/,
              /[0-9]/, // Allow input of up to three digits for hours
              ":",
              /[0-5]/,
              /[0-9]/,
            ]}
            onChange={(ev) => {
              console.log(ev.target.value);
              // this.setState({
              //   toleranceLimit: parseInt(val.target.value) || "",
              // });
            }}
          /> */}
        </FieldContainer>
      </InnerGroup>
      <FieldContainer width="160px">
        <Select
          locked={locked}
          disabled={disabled}
          options={periodOptions}
          placeholder={t("When")}
          value={`${period}_${rule.periodsNumber}`}
          onChange={(value) => {
            const [periodType, periodsNumber] = value.split("_");
            setRule({ ...rule, period: periodType as HoursBankLimitPeriodTypes, periodsNumber: Number(periodsNumber) });
          }}
        />
      </FieldContainer>
      <InnerGroup>
        <FieldContainer width="240px">
          <Select
            locked={locked}
            disabled={disabled}
            options={actionOptions}
            value={rule.action}
            placeholder={t("Select action")}
            onChange={(value) => {
              const newRule = { ...rule, action: value };
              if (value === HoursBankLimitActionTypes.ConvertToExtraHoursPhase) {
                newRule.targetPhaseUuid = phasesOptions[0].value;
              }
              setRule(newRule);
            }}
          />
        </FieldContainer>
        {rule.action === HoursBankLimitActionTypes.ConvertToExtraHoursPhase && (
          <FieldContainer width="176px">
            <Select
              locked={locked}
              disabled={disabled}
              options={phasesOptions}
              value={rule.targetPhaseUuid}
              onChange={(value) => setRule({ ...rule, targetPhaseUuid: value })}
            />
          </FieldContainer>
        )}
      </InnerGroup>
      {!disabled && !locked && !!onRemoveLimit && (
        <TableButton
          onClick={() => {
            onRemoveLimit(rule.uuid);
          }}
          className="delete-button"
        >
          {t(`${TranslationNamespaces.common}|Delete`)}
        </TableButton>
      )}
    </TableRow>
  );
};

interface HbLimitsProps {
  locked: boolean;
  disabled: boolean;
  hoursBankLimits: HoursBankLimit[];
  phases: PayedOvertimePhase[];
  onLimitsChange: (rule: Partial<HoursBankLimit>) => void;
  onLimitsRemove: (uuid: string) => void;
}

const HbLimits = ({
  hoursBankLimits,
  locked,
  disabled,
  onLimitsChange,
  onLimitsRemove,
  phases,
}: HbLimitsProps): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.companyRules);

  if ((locked || disabled) && hoursBankLimits.length === 0) {
    return <div />;
  }

  return (
    <div>
      <Subheader>{t("Hours Bank Limits and Payment")}</Subheader>
      <Wrapper>
        {hoursBankLimits.map((hoursBankLimit) => (
          <Rule
            phases={phases}
            key={hoursBankLimit.uuid}
            setRule={(rule) => onLimitsChange(rule)}
            rule={hoursBankLimit}
            locked={locked}
            disabled={disabled}
            onRemoveLimit={onLimitsRemove}
          />
        ))}
      </Wrapper>
      <br />
      {!locked && !disabled && (
        <AddRemoveLink
          label={t("add-hours-bank-rule")}
          onClick={() => {
            onLimitsChange({
              uuid: uuidv4(),
              limit: 0,
              active: true,
              periodsNumber: 1,
              periodOffsetDays: 0,
              sourceMode: HoursBankLimitSourceModes.Total,
              type: HoursBankLimitTypes.Positive,
              period: HoursBankLimitPeriodTypes.Month,
              action: HoursBankLimitActionTypes.ConvertToExtraHours,
            });
          }}
        />
      )}
    </div>
  );
};

export default HbLimits;
