import { Component } from "react";
import BEM from "utils/BEM";
import styled from "styled-components";
import "styles/schedule-create.scss";
import { withTranslation } from "react-i18next";
import { convertTimeToMinutes } from "utils/scheduleHelpers";
import ClickOutside from "react-click-outside";
import ScheduleAdditionalEntryExit from "components/controls/ScheduleAdditionalEntryExit";
import DayOfWeek from "components/UI/Table/DayOfWeek";
import { getCompanyRules } from "utils/apiHelpers";
import { CompanyRuleNames } from "types/models/companyRules";
import RadioButton from "components/controls/RadioButton";
import TableCommon from "../TableCommon";
import TimeControl from "../controls/TimeControl";
import ScheduleDayButton from "../controls/ScheduleDayButton";
import { BreaksCell } from "./Breaks/BreaksCell";
import * as images from "../svg-images";

const b = BEM.b("schedule-create");

const ScheduleDaysRow = styled.div`
  display: flex;
  gap: 8px;
`;

class ScheduleFlexibleTable extends Component {
  static defaultProps = {
    onChange: () => {},
    onRemoveBreak: () => {},
    onRemoveDay: () => {},
    onToggleDay: () => {},
    onAddAdditionalEntryExit: () => {},
    onRemoveAdditionalEntryExit: () => {},
    onAddDay: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      tableColumns: [],
      isFetching: false,
      selected: 0,
    };
  }

  async componentDidMount() {
    const businessRules = await getCompanyRules();
    const dsrEnabled =
      businessRules?.business_rules?.find((rule) => rule.name === CompanyRuleNames.IS_DSR_ENABLED)?.value || false;

    this.setState({
      tableColumns: this.getTableColumns(dsrEnabled),
    });
  }

  updateDay = (dayId, dayData) => {
    this.props.onChange(dayId, dayData);
  };

  hasData(schedule) {
    return schedule.entry.value || schedule.exit.value || schedule.breaks.length > 0;
  }

  getTableColumns(dsrEnabled) {
    const { t, onRemoveBreak, enableDsrDay, onAddAdditionalEntryExit, onRemoveAdditionalEntryExit, readOnly } =
      this.props;
    const days = [t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
    const colDayOfWeek = {
      Header: "",
      accessor: "id",
      Cell: (r) => {
        if (readOnly) {
          return <ScheduleDayButton value={r.value} selected={r.original.hoursPerDay?.value} />;
        }
        return <DayOfWeek dayName={days[r.value]} />;
      },
      width: 80,
    };

    const colHoursPerDay = {
      Header: t("Hours per day"),
      accessor: "hoursPerDay",
      Cell: (r) => {
        const hoursPerDay = r.value;
        if (readOnly || (hoursPerDay.value && !hoursPerDay.error && r.original.id !== this.state.selected)) {
          return hoursPerDay.value;
        }
        return (
          <TimeControl
            modifiers={{ schedule: true }}
            placeholder={t("from")}
            onTimeChange={(val) => {
              const hrsPerDay = { ...val, raw: val && val.value ? convertTimeToMinutes(val.value) : 0 };
              this.updateDay(r.original.id, {
                hoursPerDay: hrsPerDay,
                daysOfWeek: r.original.daysOfWeek.map((dow) => {
                  if (dow.id === r.original.id + 1) {
                    return { ...dow, working: hrsPerDay.raw > 0 };
                  }

                  return dow;
                }),
                isDsrDay: hrsPerDay.raw === 0 ? r.original.isDsrDay : false,
              });
            }}
            error={hoursPerDay.error}
            value={hoursPerDay.value}
          />
        );
      },
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      width: 130,
    };

    const colDsr = dsrEnabled
      ? {
          Header: "DSR",
          accessor: "isDsrDay",
          style: { textAlign: "center", justifyContent: "center", display: "flex" },
          headerStyle: { textAlign: "center" },
          Cell: (r) => {
            const dayOfWeek = r.original?.daysOfWeek[r.original.id];

            if (dayOfWeek && !dayOfWeek.working) {
              return (
                <RadioButton
                  selected={r.value}
                  disabled={readOnly}
                  onClick={(checked) => enableDsrDay(r.original.id)}
                />
              );
            }

            return null;
          },
          width: 50,
        }
      : null;

    const colBreaks = {
      Header: t("Break"),
      accessor: "breaks",
      Cell: (r) => (
        <BreaksCell
          row={r}
          readOnly={readOnly}
          breaks={r.value}
          selected={this.state.selected}
          onRemoveBreak={onRemoveBreak}
          updateDay={this.updateDay}
        />
      ),
      style: { paddingInlineStart: "8px", paddingInlineEnd: "12px" },
      minWidth: 460,
    };
    const colOptions = {
      Header: "",
      minWidth: 80,
      Cell: (r) => {
        if (!readOnly && r.original.id !== this.state.selected) {
          return (
            <span
              className={b("clear-schedule")}
              onClick={() => {
                this.updateDay(r.original.id, {
                  hoursPerDay: { raw: 0, value: "", error: false },
                  isDsrDay: false,
                  breaks: [],
                  extraEntryExit: [],
                  working: false,
                });
              }}
            >
              {images.clear}
            </span>
          );
        }
        return null;
      },
      style: { textAlign: "center" },
    };
    let extraEntryExit = null;
    if (window.global_store.beta) {
      extraEntryExit = {
        Header: t("Additional Entry/Exit"),
        accessor: "extraEntryExit",
        Cell: (r) => {
          if (r.value && r.value.length > 0) {
            return (
              <div>
                {r.value.map((e, i) => {
                  const entryExit = { ...e };
                  return (
                    <ScheduleAdditionalEntryExit
                      key={i}
                      multiple
                      readOnly={readOnly || r.original.id !== this.state.selected}
                      item={entryExit}
                      onChange={(eNeX) => {
                        const entryExits = Object.assign([], r.value);
                        entryExits[i] = eNeX;
                        this.updateDay(r.original.id, { extraEntryExit: entryExits });
                      }}
                      onAddItem={() => onAddAdditionalEntryExit(r.original.id, i)}
                      onRemoveItem={() => onRemoveAdditionalEntryExit(r.original.id, i)}
                    />
                  );
                })}
              </div>
            );
          }
          return !readOnly ? (
            <div className={b("break-link")} onClick={() => onAddAdditionalEntryExit(r.original.id)}>
              {t("Add extra entry")}
            </div>
          ) : null;
        },
        style: { textAlign: "center", overflow: "visible" },
        headerStyle: { textAlign: "center" },
        minWidth: 460,
      };
    }

    const columns = readOnly
      ? [colDayOfWeek, colHoursPerDay, colDsr, colBreaks]
      : [colDayOfWeek, colHoursPerDay, colDsr, colBreaks, colOptions];
    columns.push(extraEntryExit);

    return columns;
  }
  unselect() {
    this.setState({ selected: null });
  }
  render() {
    const { tableColumns } = this.state;
    const { t, tableData, readOnly } = this.props;

    return (
      <div className={b()}>
        <ClickOutside
          onClickOutside={(e) => {
            // don't unselect row if clicked inside the popup
            if (!e.target.closest(".PopupWrapper")) {
              this.unselect();
            }
          }}
        >
          <TableCommon
            noScroll
            rows={tableData}
            columns={tableColumns}
            showPagination={false}
            pages={1}
            className={`table-common ${readOnly ? "table-common_readonly" : ""}`}
            defaultPageSize={1000}
            loading={this.state.isFetching}
            manual
            showPageSizeOptions={false}
            getTrProps={(s, r) => {
              const selected = this.state.selected === r.original.id;
              const style = {};
              if (selected) {
                style.backgroundColor = "#F7F9FC";
              }
              return {
                style,
                onClick: (e) => {
                  !readOnly && this.setState({ selected: r.original.id });
                },
              };
            }}
          />
        </ClickOutside>
      </div>
    );
  }
}
export default withTranslation("schedules")(ScheduleFlexibleTable);
