const config = {
  protocol: process.env.REACT_APP_PROTOCOL || "//",
  hostname: process.env.REACT_APP_HOSTNAME,
  version: process.env.REACT_APP_VERSION,
  globalMode: window.location.hostname.indexOf("com.br") === -1,
  appEnv: process.env.REACT_APP_ENV,
  api: process.env.REACT_APP_API,
  api_download: process.env.REACT_APP_API,
  api_s: process.env.REACT_APP_API_S,
  api_admin: process.env.REACT_APP_API_V2,
  api_admin_day_io: process.env.REACT_APP_API_DAY_IO || "stg-new.day.io",
  api_extraHours: `${process.env.REACT_APP_API_V2}/v2/public/extra_hours`,
  api_management: `${process.env.REACT_APP_API_V2}/v2/management`,
  api_exporting: `${process.env.REACT_APP_API_V2}/v2/public/exporting`,
  client_admin_api: `${process.env.REACT_APP_API_V2}/v2/public/client_admin_api`,
  client_admin_api_day_io: `${process.env.REACT_APP_API_DAY_IO || "stg-new.day.io"}/v2/public/client_admin_api`,
  api_reporting: process.env.REACT_APP_API_REPORTING,
  googleMapApi: process.env.REACT_APP_GOOGLE_PLACES_TOKEN,
  googleMapApiPunch: process.env.REACT_APP_GOOGLE_PLACES_TOKEN_PUNCH,
  googleMapApiActivity: process.env.REACT_APP_GOOGLE_PLACES_TOKEN_ACTIVITY,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  cloudinaryHost: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_ID}/image/upload/`,
  newAuth: true,
  ldClientId: process.env.REACT_APP_LD_CLIENT_ID,
  fusionChartKey:
    "VnC3bqB-8A1I4A1A7A5C5E2F2C1I2D3A8D8rmeD4A2jlbA-16uB1B3g1paH2B7B2D5B4A4C3H3I3D3B7A4C3E6D4C4tkeC8D5B5mD3B4E3i1cvA4B4C2ptkD1J2B3phhD1B1G4A14A20B7C8B3C6B5G4ogaA9C5TA3wqh1H2C1C5qlE2GE2F1xzkB8C2C7C3B2G2G2H3A3D5E6E2E2D3s==",
  displayShortDateFormat: "MM/DD/YYYY",
  apiDateFormat: "YYYY-MM-DD",
  apiDateFormat2: "DD/MM/YYYY",
  googleOAuthKey: process.env.REACT_APP_GOOGLE_OAUTH_KEY,
  agGridLicense: process.env.REACT_APP_AG_GRID_LICENCE,
};

export default config;
