import { SearchObject } from "types/common";
import { PunchDetailsEmployee } from "./employee";
import { PunchLocation } from "./location";

/**  Punch common */
enum PunchStatuses {
  pending = "pending",
  approved = "approved",
  declined = "declined",
  ignored = "ignored",
  invalid = "invalid",
}

// todo
/**  Punch common */
enum PunchReason {
  other = "Other",
  notValidatedLocation = "Not validated - Location",
  earlyLeave = "Change early leave",
  lateArrival = "Change late arrival",
  forgotToPunch = "Forgot to Punch",
  technicalProblem = "Technical Problem",
}

// todo
/**  Punch common */
enum PunchSource {
  computer = "computer",
  automaticBreak = "automatic_break",
  tablet = "tablet",
}

// todo
enum PunchValidation {
  onTime = "on_time",
  invalid = "invalid",
}

/**  Punch common */
enum PunchType {
  entry = "entry",
  breakStart = "break_start",
  breakEnd = "break_end",
  exit = "exit",
}

enum PunchVerificationMethod {
  none = "none",
  biometric = "biometric",
  location = "location",
  beacon = "beacon",
  wifiPoint = "wifi_point",
}

enum PunchLogActorType {
  userProfile = "userProfile",
}

enum PunchAction {
  created = "created",
  statusUpdated = "statusUpdated",
}

// todo
enum PunchDeviceType {
  tablet = "Tablet",
}

type PunchKey = "entry0" | "break_start0" | "break_end0" | "exit0" | "entry1" | "break_start1" | "break_end1" | "exit1"; // etc.

/** Modal punch, Punch Page */
type PunchEmployee = {
  id: number;
  uuid: string;
  name: string;
  avatar_id: string | null;
  avatar_url: string | null;
  position_id: number | null;
  job_description: string | null;

  cpf?: string;
  pis?: string;
  matricula?: string;
};

// todo expand
type PunchDevice = {
  device_type: PunchDeviceType;
  vendor: string;
  model: string;
};

type PunchLogActionDetails = {
  approvalStatus: PunchStatuses;
  approvalStatusReason: string | null;
};

type PunchLogActor = {
  uuid: string;
  fullName: string;
  avatarId: string | null;
  avatarVersion: unknown | null; // todo
  jobTitle: string;
  avatarUrl: string | null;
};

/** PunchDetails */
type PunchLog = {
  uuid: string;
  actor: PunchLogActor;
  actorType: PunchLogActorType;
  actorUuid: string;
  action: PunchAction;
  actionDetails: PunchLogActionDetails | null;
  createdAt: string;
};

/** Modal punch */
type ModalPunchPunch = {
  attachments: unknown[]; // todo
  comment: string | null;
  device: unknown | null; // todo
  device_datetime: string;
  employee: PunchEmployee;
  id: string | number;
  is_locked: boolean;
  is_manual: boolean;
  is_valid: boolean;
  location: PunchLocation;
  manager: PunchEmployee | null;
  punch_type: PunchType;
  reason: PunchReason;
  source: PunchSource;
  status: PunchStatuses;
  time_zone: string;
  uuid: string;
  verified_by_type: PunchVerificationMethod | null;
};

/** Punch page, Superpunch page, Modal punch */
type Punch = {
  device: unknown | null; // todo
  uuid: string;
  id: number;
  device_datetime: string;
  time_zone: string;
  verified_by_type: PunchVerificationMethod;
  punch_type: PunchType;
  is_locked: boolean;
  is_valid: boolean | null; // ??
  is_manual: boolean;
  status: PunchStatuses;
  reason: PunchReason;
  comment: string;
  source: PunchSource;
  employee: PunchEmployee;
  manager: PunchEmployee | null;
  attachments: unknown[]; // todo
  location: PunchLocation | null;
  /** PROD-12766 new hierarchy permissions */
  has_permission_to_manage?: boolean;
  break_type_uuid: string | null;
};

/** Punch page */
type PucnhesTableData = {
  id: number;
  employee: SearchObject["employee"];
  photo: string;
  type: string;
  when: { time: string; timezone: string };
  validation: string;
  status: string;
  reason: string;
  comment: string;
  position: string;
  reviewedBy: string;
  cpf: string;
  pis: string;
  matricula: string;
  source: string;
  options: string;
  isValid: boolean;
  isManual: boolean;
  /** original punch returned from backend */
  punch: Punch;
};

/** Punch Details */
type PunchDetailsPunch = {
  id: number;
  uuid: string;
  punch_type: PunchType;
  is_manual: boolean;
  device_datetime: string;
  verified_by_type: PunchVerificationMethod;
  status: PunchStatuses;
  reason: PunchReason | null;
  created_at: string;
  time_zone: string;
  is_in_custom_location: boolean;
  comment: string | null;
  status_changed_at: string | null;
  source: PunchSource;
  logs: PunchLog[];
  lat: number | null;
  lng: number | null;
  is_locked: boolean;
  is_valid: boolean;
  request_id: unknown | null; // todo
  is_validated: boolean;
  device: PunchDevice | null;
  location: PunchLocation;
  employee: PunchDetailsEmployee;
  created_by: PunchDetailsEmployee;
  manager: unknown | null; // todo
  attachments: unknown[]; // todo
  /** PROD-12766 new permissions punch */
  has_permission_to_manage?: boolean;
  punch_hash: string | null;
  break_type_uuid: string | null;
};

/** Superunch page */
type DailySummaryPunch = {
  applied: boolean;
  key: PunchKey;
  manual: boolean;
  status: PunchStatuses;
  time: string;
  timezone: string;
  type: PunchType;
  uuid: string;
  validation: PunchValidation;

  // TODO check if exists
  time_zone: string;
  // TODO check if exists
  device_datetime: string;
};

/** PunchesAddPopup, SuperpunchOverlay, SuperpunchContext  */
type AddPunchPunch = {
  punch: {
    /** uuid */
    location_id: string;
    punch_type: PunchType;
    device_datetime: string;
    reason: PunchReason;
    is_manual: "true";
    status: PunchStatuses.approved | PunchStatuses.pending;
    employee_uuids?: string[];
    employee_profile_id?: number;
    comment?: string;
    created_by: string;
  };
};

/** EmployeePunchesTable.tsx */
type EmployeePunchesTableData = {
  id: number;
  type: PunchType;
  punch: "manual_punch" | "regular_punch";
  when: { time: string; timezone: string };
  validation: string; // add enum
  status: PunchStatuses;
  options: number;
  employee: PunchEmployee;
};

export type {
  Punch,
  ModalPunchPunch,
  PunchLocation,
  PucnhesTableData,
  DailySummaryPunch,
  PunchKey,
  AddPunchPunch,
  EmployeePunchesTableData,
  PunchDetailsPunch,
  PunchLog,
  PunchLogActionDetails,
  PunchLogActor,
};

export {
  PunchStatuses,
  PunchType,
  PunchValidation,
  PunchDeviceType,
  PunchSource,
  PunchVerificationMethod,
  PunchReason,
};
