import React, { Component } from "react";
import styled from "styled-components";

const FieldName = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 12px;
  line-height: 15px;
  color: #525f7f;
  margin-bottom: ${(props) => props.fieldTitleMarginBottom}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`;
const Field = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .input_onboarding {
    margin-bottom: 0px;
    border: 2px solid #dde5ee;
  }
  .input_onboarding.input_error {
    border-color: #fe6764;
  }
  .react-select__placeholder {
    font-size: 16px;
    color: #a1b2cf;
  }
`;
const MainWrapper = styled.div`
  width: ${(props) => (props.wrapperWidth ? `${props.wrapperWidth}` : "auto")};
  margin-bottom: 32px;
`;
const Loader = styled.div`
  ${(props) => (props.height ? `height: ${props.height}px;` : "")};
  ${(props) => (props.width ? `width: ${props.width};` : "")}
`;
const SideElement = styled.div``;
class OnboardingFieldWrapper extends Component {
  state = {};
  static defaultProps = {
    fieldTitleMarginTop: 13,
    fieldTitleMarginBottom: 8,
  };

  render() {
    const {
      fieldName,
      children,
      loading,
      fieldTitleMarginBottom,
      className = "",
      fieldTitleMarginTop,
      wrapperWidth,
      sideElement,
    } = this.props;

    return (
      <MainWrapper className={className} marginTop={fieldTitleMarginTop} wrapperWidth={wrapperWidth}>
        {fieldName ? (
          <FieldName fieldTitleMarginBottom={fieldTitleMarginBottom}>
            {loading ? <Loader className="loading" height={15} width="100%" /> : fieldName}
            {sideElement ? <SideElement>{sideElement}</SideElement> : null}
          </FieldName>
        ) : null}
        <Wrapper>
          <Field>{loading ? <Loader className="loading" height={50} width="100%" /> : children}</Field>
        </Wrapper>
      </MainWrapper>
    );
  }
}

export default OnboardingFieldWrapper;
