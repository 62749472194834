import { Component, MouseEvent } from "react";
import styled from "styled-components";
import PaginationPerPageSelector from "components/UI/PaginationPerPageSelector";
import * as images from "./svg-images";

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: var(--component-pagination-control-gap, 24px);
  margin: 0 4px 28px;
  padding-inline-end: 44px;
`;

const PerPageWrapper = styled.div``;

const PaginationNumberList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--component-pagination-control-navigation-gap, 4px);
`;

const BaseItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: var(--component-pagination-page-cell-width, 32px);
  height: var(--component-pagination-page-cell-height, 32px);
  line-height: var(--component-pagination-page-cell-height, 32px);
  font-size: var(--typography-font-size-default);
  border-radius: var(--component-pagination-page-cell-border-radius, 4px);
  cursor: pointer;
  user-select: none;

  svg path {
    fill: #575f79;
  }
`;

const DumbItem = styled(BaseItem)`
  color: var(--component-pagination-page-cell-default-text-color, #3e4357);
  cursor: default;
`;

const BaseItemWithHover = styled(BaseItem)<{ active?: boolean; noAction?: boolean }>`
  &:hover {
    color: ${(p) => (p.active ? "var(--component-pagination-page-cell-hover-text-color, #2A2E3B)" : undefined)};
    background-color: ${(p) =>
      p.noAction ? "transparent" : "var(--component-pagination-page-cell-hover-bg-color, rgba(33, 36, 46, 0.08));"};
    cursor: ${(p) => (p.noAction ? "default" : "pointer")};
  }
`;

const Item = styled(BaseItemWithHover)<{ active: boolean }>`
  color: ${(p) =>
    p.active
      ? "var(--component-pagination-page-cell-selected-text-color)"
      : "var(--component-pagination-page-cell-default-text-color, #3E4357)"};
  background: ${(p) =>
    p.active
      ? "var(--component-pagination-page-cell-selected-bg-color, #1E97F7)"
      : "var(--component-pagination-page-cell-default-bg-color, #FFF)"};
`;

const Arrow = styled(BaseItemWithHover)<{ noAction: boolean }>`
  width: var(--component-icon-button-lg-width, 32px);
  height: var(--component-icon-button-lg-height, 32px);

  svg path {
    fill: ${(p) => (p.noAction ? "#CDD0DB" : undefined)};
  }
`;

const ThreeDots = () => <DumbItem>...</DumbItem>;

type PaginationNewProps = {
  totalRecords: number;
  currentPage: number;
  onPageChange: (currentPage: number) => void;
  showPageLimit: boolean;
  pageLimit: number;
  onPageLimitChange?: (pageLimit: number, currentPage: 1) => void;
  pageLimits: number[];
  unlimited?: boolean;
};

type PaginationNewState = {
  totalPages: number;
};

class PaginationNew extends Component<PaginationNewProps, PaginationNewState> {
  static defaultProps = {
    totalRecords: null,
    pageLimit: 25,
    showPageLimit: true,
    pageLimits: [25, 50, 100],
  };

  constructor(props: PaginationNewProps) {
    super(props);

    const { totalRecords, pageLimit } = props;

    this.state = {
      totalPages: Math.ceil(totalRecords / pageLimit),
    };
  }

  componentDidUpdate(prevProps: Readonly<PaginationNewProps>) {
    const { totalRecords, pageLimit } = this.props;

    if (prevProps.totalRecords !== totalRecords) {
      this.setState({
        totalPages: Math.ceil(totalRecords / pageLimit),
      });
    }
  }

  gotoPage = (page: number) => {
    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleClick = (page: number) => {
    this.gotoPage(page);
  };

  handleMoveLeft = (evt: MouseEvent<HTMLLIElement>) => {
    evt.preventDefault();
    const { currentPage } = this.props;

    if (currentPage > 1) {
      this.gotoPage(currentPage - 1);
    }
  };

  handleMoveRight = (evt: MouseEvent<HTMLLIElement>) => {
    evt.preventDefault();
    const { currentPage } = this.props;
    const { totalPages } = this.state;

    if (currentPage < totalPages) {
      this.gotoPage(currentPage + 1);
    }
  };

  handleLimitChange = (pageLimit: number) => {
    const { onPageLimitChange, totalRecords } = this.props;

    if (onPageLimitChange) {
      onPageLimitChange(pageLimit, 1);
    }

    this.setState({
      totalPages: Math.ceil(totalRecords / pageLimit),
    });
  };

  getPageNumbers = (): JSX.Element[] => {
    const { currentPage, unlimited } = this.props;
    const { totalPages } = this.state;

    if (totalPages <= 5) {
      return [...Array(totalPages)].map((_, i) => {
        const pageNumber = i + 1;
        return (
          <Item key={pageNumber} active={currentPage === pageNumber} onClick={() => this.handleClick(pageNumber)}>
            {pageNumber}
          </Item>
        );
      });
    }

    const first = (
      <Item key={1} active={currentPage === 1} onClick={() => this.handleClick(1)}>
        1
      </Item>
    );
    let second;
    let center;
    let penultimate;
    const last = unlimited ? (
      <ThreeDots key={`dots${3}`} />
    ) : (
      <Item key={totalPages} active={currentPage === totalPages} onClick={() => this.handleClick(totalPages)}>
        {totalPages}
      </Item>
    );

    // insert "..."
    if (currentPage - 2 >= 1) {
      second = <ThreeDots key={`dots${2}`} />;
    } else {
      second = (
        <Item key={2} active={currentPage === 2} onClick={() => this.handleClick(2)}>
          2
        </Item>
      );
      center = (
        <Item key={3} active={currentPage === 3} onClick={() => this.handleClick(3)}>
          3
        </Item>
      );
    }

    // insert "..."
    if (currentPage + 2 < totalPages) {
      penultimate = <ThreeDots key={`dots${4}`} />;
    } else {
      center = (
        <Item
          key={totalPages - 2}
          active={currentPage === totalPages - 2}
          onClick={() => this.handleClick(totalPages - 2)}
        >
          {totalPages - 2}
        </Item>
      );
      penultimate = (
        <Item
          key={totalPages - 1}
          active={currentPage === totalPages - 1}
          onClick={() => this.handleClick(totalPages - 1)}
        >
          {totalPages - 1}
        </Item>
      );
    }

    if (!center) {
      center = (
        <Item key={currentPage} active onClick={() => {}}>
          {currentPage}
        </Item>
      );
    }

    return [first, second, center, penultimate, last];
  };

  render() {
    const { pageLimits, showPageLimit, totalRecords, pageLimit } = this.props;

    if (!totalRecords) {
      return null;
    }

    return (
      <Nav>
        {showPageLimit && (
          <PerPageWrapper>
            <PaginationPerPageSelector
              newPagination
              pageLimit={pageLimit}
              options={pageLimits.map((pl) => ({ value: pl, label: String(pl) }))}
              onChange={this.handleLimitChange}
            >
              <></>
            </PaginationPerPageSelector>
          </PerPageWrapper>
        )}

        <PaginationNumberList>
          <Arrow className="prev-item" onClick={this.handleMoveLeft} noAction={this.props.currentPage <= 1}>
            {window.global_store.isRTL ? images.calArrowRight : images.calArrowLeft}
          </Arrow>

          {this.getPageNumbers()}

          <Arrow
            className="next-item"
            onClick={this.handleMoveRight}
            noAction={this.props.currentPage >= this.state.totalPages}
          >
            {window.global_store.isRTL ? images.calArrowLeft : images.calArrowRight}
          </Arrow>
        </PaginationNumberList>
      </Nav>
    );
  }
}

export default PaginationNew;
