import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import StatusBadge from "components/controls/StatusBadge";
import ModalDialog from "components/UI/ModalDialog";
import TableButtonsControl from "components/styled/TableButtonsControl";
import Lightbox from "components/Lightbox";
import * as moment from "moment";
import * as momentTz from "moment-timezone";
import { getTitle } from "utils/common";
import { deleteHoliday } from "./holidayApiUtils";

const title = "Holidays Management";
const metaTitle = title;

class HolidayGroupTabHolidays extends Component {
  constructor(props) {
    super(props);
    const { t, items } = props;
    this.state = {
      items,
      selectedItem: null,
      deleteConfirmationVisible: false,
      isFetching: false,
    };
    document.title = getTitle(t(metaTitle));
  }

  getTableColumns(rows = []) {
    const { t } = this.props;
    const columns = [
      {
        label: t("common|Name"),
        accessor: "name",
        minWidth: 160,
      },
      {
        label: t("common|Date"),
        accessor: "startTime",
        Cell: (row) => moment(row.value).format("DD/MM/YYYY"),
        minWidth: 100,
        style: { lineHeight: "36px" },
      },
      {
        label: t("common|Recurring"),
        accessor: "repeatAnnually",
        Cell: (row) => (row.value ? t("common|Yes") : t("common|No")),
        minWidth: 60,
        style: { lineHeight: "36px" },
      },
      {
        label: t("common|Period"),
        accessor: "allDay",
        Cell: (row) => (row.value ? t("common|Full Day") : t("common|Event")),
        width: 160,
      },
      {
        label: t("common|Type"),
        accessor: "type",
        minWidth: 150,
        Cell: (row) => (
          <div>
            {t(row.value)}
            <TableButtonsControl
              dropToTop={row.viewIndex === rows.length - 1}
              dropdownButtons={[
                {
                  label: t("common|Delete"),
                  onClick: () => {
                    this.setState({ deleteConfirmationVisible: true, selectedItem: row.original.uuid });
                  },
                },
              ]}
              // visibleButtons={this.getTableButtons({ row, t })}
            />
          </div>
        ),
      },
    ];
    return columns;
  }
  removeHoliday = async () => {
    const { selectedItem } = this.state;
    const { holidaysGroupUuid } = this.props;

    try {
      await deleteHoliday({
        companyUuid: window.global_store.company.uuid,
        holidaysGroupUuid,
        holidayUuid: selectedItem,
        updatedBy: window.global_store.profile.uuid,
      });
      this.props.onUpdate({ message: "You deleted holiday", type: "success" });
    } catch (error) {
      if (error && error.status === 500) {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      } else {
        this.props.onUpdate({ type: "error", message: "Something went wrong" });
      }
    }
    this.setState({ selectedItem: null, deleteConfirmationVisible: false });
  };
  render() {
    const { deleteConfirmationVisible } = this.state;
    const { t, items, loading } = this.props;
    const noItemsTitle = "No holidays are available";

    return (
      <>
        <br />
        <TablePage
          rows={items}
          columns={this.getTableColumns(items)}
          className="groups-table"
          customColumnsAvailable={false}
          columnSelectorOnFiltersRow
          loading={loading}
          noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
        />
        <ModalDialog
          isOpen={deleteConfirmationVisible}
          onClose={() => this.setState({ deleteConfirmationVisible: false, selectedItem: null })}
        >
          <Lightbox
            title={t("Remove holiday")}
            text={t("Are you sure you want to remove holiday?")}
            buttonYesTitle={t("common|Remove")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ deleteConfirmationVisible: false, selectedItem: null });
            }}
            onYes={this.removeHoliday}
          />
        </ModalDialog>
        {/* <ModalDialog
          isOpen={editConfirmationVisible}
          onClose={() => this.setState({ editConfirmationVisible: false, selectedItem: null })}
        >
          <Lightbox
            title={t('Remove holiday')}
            text={t('Are you sure you want to remove holiday?')}
            buttonYesTitle={t('common|Remove')}
            buttonCancelTitle={t('common|Cancel')}
            onClose={() => {
              this.setState({ editConfirmationVisible: false, selectedItem: null });
            }}
            onYes={this.editHoliday}
          />
        </ModalDialog> */}
      </>
    );
  }
}
export default withTranslation("holidays")(HolidayGroupTabHolidays);
