import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import InputFieldIcon from "components/UI/SearchControlNew/InputFieldIcon";
import Tooltip from "components/UI/Tooltip";
import { TranslationNamespaces } from "types/translationNamespaces";

const InputWrapper = styled.div<{ withNavigation: boolean }>`
  position: relative;

  input {
    width: 320px;
    height: 36px;
    padding: 10px;
    padding-inline-start: 44px;
    padding-inline-end: ${(p) => (p.withNavigation ? "73" : "8")}px;
    font-size: 15px;
    text-overflow: ellipsis;
    font-weight: var(--typography-font-weight-default);
    border: 1px solid var(--colors-surface-500);
    border-radius: var(--shapes-border-radius-default);
    color: var(--colors-surface-900-p);

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: var(--colors-primary-500-p);
    }

    &::placeholder {
      font-size: 15px;
      color: var(--colors-surface-700);
    }
  }

  .search-icon {
    inset-inline-start: 9px;
    top: 0;
  }
`;

interface GroupSearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  withNavigation: boolean;
}

const SearchInput = ({ value, onChange, onClick, withNavigation }: GroupSearchInputProps) => {
  const { t } = useTranslation(TranslationNamespaces.punchesPage);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputWrapper withNavigation={withNavigation}>
      <input
        placeholder={t("Select Group")}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        onClick={onClick}
        data-tip
        data-for="search-tooltip"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <InputFieldIcon />

      {value && !isFocused && <Tooltip id="search-tooltip">{value}</Tooltip>}
    </InputWrapper>
  );
};

export default SearchInput;
