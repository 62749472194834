import styled from "styled-components";
import { useTranslation } from "react-i18next";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { TranslationNamespaces } from "types/translationNamespaces";
import { RequestLog } from "types/models/request";
import { PunchLog } from "types/models/punches";

const moment = extendMoment(momentTz);

const Wrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;
  padding-top: 25px;
  border-top: 1px solid var(--colors-surface-150);

  @media print {
    margin-bottom: 16px;
  }
`;

const Row = styled.p`
  margin: 0;
  line-height: 20px;
  font-size: 13px;
  font-weight: var(--typography-font-weight-default);
  color: var(--colors-surface-400);

  b {
    font-weight: var(--typography-font-weight-medium);
  }
`;

const getAction = (log: RequestLog | PunchLog) =>
  `log-action-${
    !log.actionDetails || !Object.keys(log.actionDetails).length ? log.action : log.actionDetails.approvalStatus
  }`;

const getKey = (log: RequestLog | PunchLog) => log.uuid || log.createdAt;

interface RequestDetailsLogEventsProps {
  logs: (RequestLog | PunchLog)[];
  timezone: string;
}

const RequestDetailsLogEvents = ({ logs, timezone }: RequestDetailsLogEventsProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <Wrapper>
      {logs.map((log) => (
        <Row key={getKey(log)}>
          {log.actor.fullName} <b>{t(getAction(log))}</b> {t("log-on")}{" "}
          {moment.tz(log.createdAt, timezone).format("DD/MM/YYYY")} {t("log-at")}{" "}
          {moment.tz(log.createdAt, timezone).format("HH:mm")}
        </Row>
      ))}
    </Wrapper>
  );
};

export default RequestDetailsLogEvents;
