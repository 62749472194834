import ModalDialog from "components/UI/ModalDialog";
import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { useContext, useState } from "react";
import Button, { ButtonState } from "components/controls/StyledButton";
import { useAsyncCallback } from "utils/useAsyncEffect";
import { TimesheetContext } from "../timesheets.context";
import DeclineTimesheetPopup from "./DeclineTimesheetPopup/DeclineTimesheetPopup";

type TimesheetActionsProps = {
  timesheetsUuid: string[];
  reloadData: () => void;
};

export default function TimesheetActions({ timesheetsUuid, reloadData }: TimesheetActionsProps) {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const timesheetContext = useContext(TimesheetContext);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);

  const [approve, loadingApprove] = useAsyncCallback(async () => {
    await timesheetContext.approveTimesheet(timesheetsUuid);
    await reloadData();
  }, [timesheetsUuid]);

  const [decline, loadingDecline] = useAsyncCallback(
    async (declineReason: string) => {
      await timesheetContext.declineTimesheet(timesheetsUuid, declineReason);
      await reloadData();
    },
    [timesheetsUuid],
  );

  const loading = loadingApprove || loadingDecline;
  return (
    <>
      <Button
        style={{ padding: "11px 16px" }}
        value={t("Decline")}
        state={ButtonState.outline}
        onClick={() => setShowDeclinePopup(true)}
        loading={loading}
      />
      <Button
        style={{ padding: "11px 16px" }}
        value={t("Approve")}
        state={ButtonState.primary}
        onClick={() => approve()}
        loading={loading}
      />

      <ModalDialog isOpen={showDeclinePopup} onClose={() => setShowDeclinePopup(false)}>
        <DeclineTimesheetPopup
          numTimesheets={timesheetsUuid.length}
          onClose={() => setShowDeclinePopup(false)}
          onYes={(evt) => decline(evt.reason)}
        />
      </ModalDialog>
    </>
  );
}
