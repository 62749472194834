import { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "./ModalDialog";

const Wrapper = styled.div`
  .modal-dialog {
    border-radius: 8px;
  }
`;

const Header = styled.div`
  height: 64px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--colors-surface-150);

  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.44px;
  color: var(--colors-surface-900-p);
`;

const Content = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding: 0px 32px;
`;

const Footer = styled.div`
  height: 68px;
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-top: 1px solid var(--colors-surface-150);

  & > * {
    width: auto;
  }

  & > button {
    padding: 16px;
  }

  .cancel-btn {
    color: var(--colors-surface-600);
  }
`;

const CloseIcon = (props: HTMLAttributes<HTMLSpanElement>) => (
  <span {...props} style={{ cursor: "pointer" }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M11.1759 10.5005L14.7593 6.92548C14.9162 6.76856 15.0043 6.55573 15.0043 6.33381C15.0043 6.1119 14.9162 5.89907 14.7593 5.74215C14.6023 5.58523 14.3895 5.49707 14.1676 5.49707C13.9457 5.49707 13.7328 5.58523 13.5759 5.74215L10.0009 9.32548L6.42593 5.74215C6.26901 5.58523 6.05618 5.49707 5.83426 5.49707C5.61234 5.49707 5.39951 5.58523 5.24259 5.74215C5.08567 5.89907 4.99752 6.1119 4.99752 6.33381C4.99752 6.55573 5.08567 6.76856 5.24259 6.92548L8.82593 10.5005L5.24259 14.0755C5.16449 14.153 5.10249 14.2451 5.06018 14.3467C5.01788 14.4482 4.99609 14.5571 4.99609 14.6671C4.99609 14.7772 5.01788 14.8861 5.06018 14.9876C5.10249 15.0892 5.16449 15.1813 5.24259 15.2588C5.32006 15.3369 5.41223 15.3989 5.51378 15.4412C5.61533 15.4835 5.72425 15.5053 5.83426 15.5053C5.94427 15.5053 6.05319 15.4835 6.15474 15.4412C6.25629 15.3989 6.34846 15.3369 6.42593 15.2588L10.0009 11.6755L13.5759 15.2588C13.6534 15.3369 13.7456 15.3989 13.8471 15.4412C13.9487 15.4835 14.0576 15.5053 14.1676 15.5053C14.2776 15.5053 14.3865 15.4835 14.4881 15.4412C14.5896 15.3989 14.6818 15.3369 14.7593 15.2588C14.8374 15.1813 14.8994 15.0892 14.9417 14.9876C14.984 14.8861 15.0058 14.7772 15.0058 14.6671C15.0058 14.5571 14.984 14.4482 14.9417 14.3467C14.8994 14.2451 14.8374 14.153 14.7593 14.0755L11.1759 10.5005Z"
        fill="#525F7F"
      />
    </svg>
  </span>
);

interface ConfirmationModalNewProps extends PropsWithChildren {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onYes?: (() => void) | null;
  btnYesTitle?: string;
  btnCancelTitle?: string;
  width?: number;
  yesDisabled?: boolean;
}

const ConfirmationModal = ({
  onClose,
  width,
  isOpen,
  children,
  title,
  btnCancelTitle,
  btnYesTitle,
  onYes,
  yesDisabled,
}: ConfirmationModalNewProps) => (
  <Wrapper>
    <ModalDialog onClose={onClose} width={width || 512} isOpen={isOpen} dark padding={0}>
      <Header>
        <span>{title}</span>
        <CloseIcon onClick={onClose} />
      </Header>
      <Content>{children}</Content>
      {(btnCancelTitle || (onYes && btnYesTitle)) && (
        <Footer>
          {btnCancelTitle && (
            <Button className="cancel-btn" value={btnCancelTitle} onClick={onClose} state={ButtonState.outline} />
          )}
          {onYes && btnYesTitle && <Button disabled={yesDisabled} value={btnYesTitle} onClick={onYes} />}
        </Footer>
      )}
    </ModalDialog>
  </Wrapper>
);

export default ConfirmationModal;
