import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ClickOutside from "react-click-outside";
import styled from "styled-components";
import moment from "moment";
import GlobalContext from "context/global-context";
import { getLockPeriodsForReports } from "utils/apiHelpers";
import { PayPeriod, PayPeriodRange } from "types/models/payrollLocks";
import DropdownWrapper from "./DatePeriodsDropdown";

const Value = styled.div`
  overflow: hidden;
  padding-inline-start: 48px;
  padding-inline-end: 28px;
  line-height: 34px;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;

const SelectControl = styled.div`
  position: relative;
  height: 36px;
  min-width: 220px;
  border: 1px solid #bac7db;
  border-radius: var(--shapes-border-radius-default);
  cursor: pointer;

  &:before {
    position: absolute;
    inset-inline-start: 16px;
    width: 16px;
    height: 100%;
    z-index: 1;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.938 2.15H13.6V1.5a.6.6 0 10-1.2 0v.65H5.6V1.5a.6.6 0 00-1.2 0v.65H3.062C1.87 2.15.9 3.12.9 4.313v10.625c0 1.192.97 2.162 2.163 2.162h11.874c1.193 0 2.163-.97 2.163-2.163V4.313c0-1.193-.97-2.163-2.163-2.163zM3.062 3.35H4.4v.4a.6.6 0 001.2 0v-.4h6.8v.4a.6.6 0 101.2 0v-.4h1.338c.53 0 .962.432.962.962V5.4H2.1V4.312c0-.53.432-.962.962-.962zM14.938 15.9H3.062a.964.964 0 01-.962-.963V6.6h13.8v8.338c0 .53-.432.962-.963.962z' fill='%23BAC7DB' stroke='%23BAC7DB' stroke-width='.2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:after {
    position: absolute;
    top: 0;
    inset-inline-end: 15px;
    height: 100%;
    width: 8px;
    z-index: 1;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.546 4.768a.79.79 0 01-1.133-.015L.237 1.463a.863.863 0 010-1.19.791.791 0 011.148 0L3.987 2.97 6.616.246a.791.791 0 011.146 0 .863.863 0 010 1.19L4.587 4.728a.83.83 0 01-.041.04z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  }

  &.opened:after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.448 1.234a.79.79 0 011.133.011l3.185 3.282c.317.328.318.86.003 1.19a.791.791 0 01-1.148.002L5.01 3.03l-2.62 2.732a.791.791 0 01-1.147.003.863.863 0 01-.004-1.19l3.167-3.3a.834.834 0 01.041-.04z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  }

  &.periods-loading {
    cursor: default;

    &:after {
      position: absolute;
      top: 0;
      inset-inline-end: 23px;
      width: 16px;
      height: 36px;
      background-image: ${(p) => p.theme.svg.loading_small};
      background-repeat: no-repeat;
      background-position: center;
      animation: loaderRoation 1s infinite linear;
    }
  }
`;

interface DatePeriodsSelectorProps extends WithTranslation {
  selectedRange: Partial<PayPeriodRange>;
  onChange: (start: moment.Moment, end: moment.Moment) => void;
}

interface DatePeriodsSelectorState {
  loading: boolean;
  periods: PayPeriod[];
  isOpen: boolean;
}

class DatePeriodsSelector extends Component<DatePeriodsSelectorProps, DatePeriodsSelectorState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<DatePeriodsSelectorState> = {
    loading: true,
    periods: [],
    isOpen: false,
  };

  UNSAFE_componentWillMount() {
    void this.setRanges();
  }

  setRanges = async () => {
    const company = await this.context.getCompany();

    if (company) {
      const periods = await getLockPeriodsForReports({
        companyUuid: company.uuid,
      });

      if (periods.content?.length) {
        this.setState({
          loading: false,
          periods: periods.content,
        });
      }
    }
  };

  getRangeStr = (range: Partial<PayPeriodRange>) => {
    const start = moment(range.start, "YYYY-MM-DD");
    const end = moment(range.end, "YYYY-MM-DD");

    if (start.get("month") === end.get("month")) {
      return `${start.format("MMM DD")} - ${end.format("DD")}`;
    }

    return `${start.format("MMM DD")} - ${end.format("MMM DD")}`;
  };

  toggleOpen = (open?: boolean) => {
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    this.setState({ isOpen });
  };

  getSelectControllClass = () => {
    const { loading, isOpen } = this.state;
    let className = "";

    if (loading) {
      className = "periods-loading";
    } else if (isOpen) {
      className = "opened";
    }

    return className;
  };

  onSelectControlClick = () => {
    const { loading, periods } = this.state;

    if (!loading && periods?.length) {
      this.toggleOpen();
    }
  };

  render() {
    const { selectedRange, t } = this.props;
    const { isOpen, periods } = this.state;

    return (
      <ClickOutside onClickOutside={() => this.toggleOpen(false)} style={{ position: "relative" }}>
        <SelectControl onClick={this.onSelectControlClick} className={this.getSelectControllClass()}>
          {selectedRange ? <Value>{this.getRangeStr(selectedRange)}</Value> : <Value>{t("Select range")}</Value>}
        </SelectControl>

        {!!periods?.length && isOpen && (
          <DropdownWrapper
            t={t}
            periods={periods}
            onChange={(val) => {
              this.props.onChange(moment(val.start, "YYYY-MM-DD"), moment(val.end, "YYYY-MM-DD"));
              this.setState({ isOpen: false });
            }}
          />
        )}
      </ClickOutside>
    );
  }
}

export default withTranslation()(DatePeriodsSelector);
