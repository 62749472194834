import { Component } from "react";
import BEM from "utils/BEM";
import { PermissionSectionName } from "types/models/permissions";
import { getPhasesDays, getLastLockDate } from "utils/common";
import "styles/hours-bank-adjust-popup.scss";
import { getUserBRGroups, getEmployee } from "utils/apiHelpers";
import { withTranslation } from "react-i18next";
import SearchControl from "components/UI/SearchControlNew";
import CheckboxControl from "components/UI/CheckboxControl";
import SingleDatePickerControl from "components/controls/SingleDatePickerControl";
import Select from "components/UI/Select";
import * as moment from "moment";
import styled from "styled-components";
import NotificationRow from "components/NotificationRow";
import Button from "components/controls/StyledButton";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { PhaseType } from "types/models/businessRulesGroup";
import { TranslationNamespaces } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { DSR_PHASE_KEY } from "components/Payroll/types";
import * as images from "../svg-images";
import TimeControl from "../controls/TimeControl";

const NotificationWrapper = styled.div`
  margin-bottom: 25px;
`;

const b = BEM.b("hours-bank-adjust-popup");

class HoursBankAdjustPopup extends Component {
  constructor(props) {
    super(props);
    const { prefillProfile } = props;
    this.state = {
      errors: null,
      employeeUUID: "",
      employeeID: "",
      employeeFullName: "",
      phases: null,
      selectedPhase: null,
      totalHoursState: "plus",
      convertToExtraHours: false,
      totalHours: "01:00",
    };
    if (prefillProfile) {
      this.state.employeeUUID = prefillProfile.uuid;
      this.state.employeeID = prefillProfile.id;
      this.state.employeeFullName = prefillProfile.full_name || prefillProfile.label;
    }
  }

  componentDidMount() {
    this.getContent();
    if (this.state.employeeID) {
      getEmployee({
        id: this.state.employeeID,
        newHierarchyPermissions: true,
      }).then((res) => {
        if (res.user_profile && res.user_profile.last_lock_date) {
          this.setState({
            lockedDate: moment(res.user_profile.last_lock_date).utc(),
          });
        }
      });
    }
  }
  closePopup() {
    this.props.onClose();
  }
  getContent() {
    this.setState({
      when: this.props.when || moment(),
    });
  }
  onCancel() {
    this.closePopup();
  }
  getFormErrors() {
    const { t } = this.props;
    const { totalHours, totalHoursState } = this.state;
    let errors = {};

    if (this.state.when.isAfter(moment())) {
      errors.when = t("You cant edit future dates");
    }
    if (!this.state.employeeUUID) {
      errors.employee = translateEmployeeTerm(
        t,
        TranslationNamespaces.common,
        "custom-select-employee",
        `${TranslationNamespaces.reportsPage}|Please select an employee`,
      );
    }
    if (!this.getTotalMinutes({ totalHours, totalHoursState })) {
      errors.totalHours = t("Please set total hours");
    }
    if (!this.refs.reason.value) {
      errors.reason = t("Reason can't be empty");
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }
  onYes() {
    const { when, employeeUUID, totalHours, totalHoursState, selectedPhase, convertToExtraHours } = this.state;
    const errors = this.getFormErrors();

    if (!errors) {
      this.props.onYes({
        companyUUID: window.global_store.company.uuid,
        userProfileUuid: employeeUUID,
        body: {
          content: {
            createdBy: window.global_store.profile.uuid,
            convertToExtraHours: totalHoursState === "minus" ? convertToExtraHours : false,
            date: when.format("YYYY-MM-DD"),
            adjustment: this.getTotalMinutes({ totalHours, totalHoursState }),
            reason: this.refs.reason.value,
            adjustmentType: selectedPhase,
          },
        },
      });
    }
    this.setState({ errors });
  }
  getPhases() {
    const { when, employeeUUID } = this.state;
    const { t } = this.props;

    if (when && employeeUUID) {
      getUserBRGroups({
        companyUuid: window.global_store.company.uuid,
        userProfileUuid: employeeUUID,
        requestedBy: window.global_store.profile.uuid,
        startDate: when,
      })
        .then((r) => {
          let phases = [];
          const bRgroup = r.content[0];
          if (
            bRgroup &&
            bRgroup.rules.payedOvertime &&
            bRgroup.rules.payedOvertime.hoursBankActive &&
            bRgroup.rules.payedOvertime.phases
          ) {
            phases = bRgroup.rules.payedOvertime.phases.map((p) => {
              if (p.customName) {
                return {
                  value: p.uuid,
                  label: p.customName,
                };
              }
              const isDayTypeBasedOnSchedule = !!p.dayTypeBasedOnSchedule;
              const key = isDayTypeBasedOnSchedule ? "dayTypeBasedOnSchedule" : "daysMask";
              let label =
                p.dayTypeBasedOnSchedule !== DSR_PHASE_KEY
                  ? `${getPhasesDays({ t, isDayTypeBasedOnSchedule }).daysMasksObj[p[key]]} ${p.name}%`
                  : `${t(`${TranslationNamespaces.phases}|${p.dayTypeBasedOnSchedule}`)} ${p.name}%`;
              if (p.type !== PhaseType.combined) {
                label += ` (${t(p.type)})`;
              }
              return {
                value: p.uuid,
                label,
              };
            });
          }
          this.setState({
            phases,
            selectedPhase: phases.length ? phases[0].value : null,
          });
        })
        .catch((e) => {
          this.setState({
            phases: [],
            errors: {
              general: t("Internal server error. Please try again later or contact support."),
            },
          });
        });
    }
  }
  onDateChange(date) {
    this.setState({ when: date }, () => this.getPhases());
  }
  convertTimeToMinutes(time) {
    return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
  }
  getTotalMinutes = ({ totalHoursState, totalHours }) => {
    let minutes = 0;

    if (!totalHours || totalHours === "00:00") {
      minutes = 0;
    } else {
      minutes = this.convertTimeToMinutes(totalHours);
    }
    return totalHoursState === "minus" ? 0 - minutes : minutes;
  };
  render() {
    const { when, employeeUUID, totalHoursState, totalHours, convertToExtraHours, phases, selectedPhase, errors } =
      this.state;

    const { t } = this.props;
    let saveActive = !!(employeeUUID && when);
    if (saveActive && totalHoursState === "plus") {
      saveActive = !!selectedPhase;
    }
    const notificationMessage =
      errors && errors.general
        ? errors.general
        : translateEmployeeTerm(
            t,
            TranslationNamespaces.hoursBank,
            "custom-hours-bank-popup-select-employee-and-date",
            "To adjust hours bank please select employee and date",
          );
    const notificationType = errors && errors.general ? "error" : "warning";

    return (
      <div className={b()}>
        <div className={b("title")}>{t("Banco de Horas")}</div>
        <NotificationWrapper>
          <NotificationRow
            employeesPage
            withCloseButton={false}
            type={notificationType}
            message={notificationMessage}
          />
        </NotificationWrapper>
        <SearchControl
          permissionSection={PermissionSectionName.advancedReports}
          value={this.state.employeeFullName ? this.state.employeeFullName : undefined}
          onChange={(value) => {
            const lockedDate =
              value?.employee && getLastLockDate(value.employee) ? moment(getLastLockDate(value.employee)).utc() : null;
            let sDate = this.state.when;
            if (lockedDate && sDate.isSameOrBefore(lockedDate, "day")) {
              sDate = lockedDate.clone().add(1, "day");
            }
            this.setState(
              {
                errors: null,
                employeeUUID: value.uuid,
                employeeID: value.id,
                employeeFullName: value.label,
                lockedDate,
                when: sDate,
              },
              () => this.getPhases(),
            );
          }}
          placeholder={translateEmployeeTerm(
            t,
            TranslationNamespaces.common,
            "custom-search-employees",
            `${TranslationNamespaces.common}|Search Employees`,
          )}
        />
        {errors && errors.employee && <div className={b("error")}>{errors.employee}</div>}
        {employeeUUID ? (
          <>
            <div className={b("label", ["when"])}>{t("When")}</div>
            <SingleDatePickerControl
              value={when}
              error={errors && errors.when}
              onChange={this.onDateChange.bind(this)}
              isOutsideRange={(day) => {
                if (this.state.lockedDate) {
                  return day.isSameOrBefore(this.state.lockedDate, "day");
                }
                return false;
              }}
            />
            {errors && errors.when && <div className={b("error")}>{errors.when}</div>}
          </>
        ) : null}
        {phases && !phases.length ? (
          <div>
            {translateEmployeeTerm(
              t,
              TranslationNamespaces.hoursBank,
              "custom-hours-bank-popup-no-phases",
              `No phases for selected employee for selected date`,
            )}
          </div>
        ) : phases && phases.length && employeeUUID && when && totalHoursState === "plus" ? (
          <>
            <div className={b("label", ["type-of-hours"])}>{t("Type of Hours")}</div>
            <Select
              value={selectedPhase}
              modifiers={{ left: true, field: true }}
              onChange={(selectedPhase) => this.setState({ selectedPhase })}
              options={phases}
            />
          </>
        ) : null}

        <div className={b("label", ["total-hours"])}>{t("Total hours")}</div>
        <TotalHoursControl
          totalHoursState={totalHoursState}
          totalHours={totalHours}
          onChange={({ totalHoursState, totalHours }) => {
            this.setState({ totalHoursState, totalHours });
          }}
          onRef={(ref) => (this.totalHours = ref)}
        />
        {errors && errors.totalHours && <div className={b("error")}>{errors.totalHours}</div>}
        {totalHoursState === "minus" ? (
          <div className={b("field")} style={{ display: "flex" }}>
            <CheckboxControl
              osx
              checked={convertToExtraHours}
              id="company-settings-apply-convertToExtraHours"
              onChange={(checked) => {
                this.setState({ convertToExtraHours: checked });
              }}
            />
            <label htmlFor="company-settings-apply-convertToExtraHours" className={b("label")}>
              {t("Convert to Extra Hours")}
            </label>
          </div>
        ) : null}
        <div className={b("label", ["reason"])}>{t("Reason")}</div>
        <input
          ref="reason"
          className={b("input", { error: errors && errors.reason })}
          type="text"
          placeholder={t("Reason")}
        />
        {errors && errors.reason && <div className={b("error")}>{errors.reason}</div>}
        <div className={b("buttons")}>
          <Button value={t("Cancel")} state="secondary" onClick={this.onCancel.bind(this)} />
          <Button
            value={t("Adjust hours")}
            state={!saveActive ? "outline" : ""}
            disabled={!saveActive}
            onClick={this.onYes.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(withTranslation(["hours-bank", "phases"])(HoursBankAdjustPopup));

const bth = BEM.b("total-hours");

function TotalHoursControl({ totalHours, totalHoursState, onChange }) {
  return (
    <div className={bth()}>
      <div
        className={bth("button", {
          minus: true,
          active: totalHoursState === "minus",
        })}
        onClick={() => onChange({ totalHoursState: "minus", totalHours })}
      >
        {images.minus}
      </div>
      <div
        className={bth("button", {
          plus: true,
          active: totalHoursState === "plus",
        })}
        onClick={() => onChange({ totalHoursState: "plus", totalHours })}
      >
        {images.plus}
      </div>
      <div className={bth("hours")}>
        <div className={bth("sign")}>{totalHoursState === "plus" ? "+" : "-"}</div>
        <TimeControl
          className={b("input")}
          noHoursLimit
          placeholder="00:00"
          onTimeChange={(ev) => onChange({ totalHours: ev.value, totalHoursState })}
          value={totalHours}
        />
      </div>
    </div>
  );
}
