import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import BillingAddressPage from "components/Billing/BillingDetails/Page";
import FullPage from "components/Layout/FullPage";
import SubscriptionPlansPage from "components/Billing/Pricing/SubscriptionPlansPage";
import PaymentSuccessPage from "components/Billing/Pricing/PaymentSuccessPage";
import BillingSummary from "components/Billing/Summary/BillingSummary";

type RouteBillingProps = RouteComponentProps;

const RouteBilling = ({ match: { path }, location: { pathname } }: RouteBillingProps) => (
  <Suspense fallback={<div />}>
    {/pricing|payment-success/gi.test(pathname) ? (
      <FullPage minimal>
        <Switch>
          <Redirect exact strict from={`${path}/pricing/`} to={`${path}/pricing`} />
          <Redirect exact strict from={`${path}/payment-success/`} to={`${path}/payment-success`} />
          <Redirect
            exact
            strict
            from={`${path}/payment-success/:type/:interval/`}
            to={`${path}/payment-success/:type/:interval`}
          />

          <Route exact strict path={`${path}/pricing`} component={SubscriptionPlansPage} />
          <Route exact strict path={`${path}/payment-success/:type/:interval`} component={PaymentSuccessPage} />
        </Switch>
      </FullPage>
    ) : (
      <Switch>
        <Redirect exact from={path} to={`${path}/summary`} />
        <Redirect exact strict from={`${path}/information/`} to={`${path}/information`} />
        <Redirect exact strict from={`${path}/summary/`} to={`${path}/summary`} />

        <Route exact strict path={`${path}/information`} component={BillingAddressPage} />
        <Route exact strict path={`${path}/summary`} component={BillingSummary} />
      </Switch>
    )}
  </Suspense>
);

export default RouteBilling;
