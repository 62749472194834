import Tooltip from "components/UI/Tooltip";
import { MouseEvent } from "react";
import styled from "styled-components";

const Wrapper = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  svg {
    width: 20px;
    height: 20px;
    fill: var(--colors-surface-600, #8093ac);
  }

  &:disabled svg {
    fill: var(--colors-surface-400, #a1b2cf);
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    background: var(--colors-surface-50, #f7f9fc);
    svg {
      fill: var(--colors-surface-900-p);
    }
  }
`;

type IconButtonProps = {
  onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  id?: string;
  tooltipText?: string;
};

const IconButton = ({ onClick, disabled, className, children, id, tooltipText }: IconButtonProps) => (
  <Wrapper
    data-tip={Boolean(tooltipText && id)}
    data-for={tooltipText && id ? id : null}
    onClick={onClick}
    disabled={disabled}
    className={`icon-button${className ? ` ${className}` : ""}`}
  >
    {children}
    {!disabled && tooltipText && id && <Tooltip id={id}>{tooltipText}</Tooltip>}
  </Wrapper>
);

export default IconButton;
