import React, { Component } from "react";
import BEM from "utils/BEM";
import SettingsLayout from "components/Layout/SettingsLayout";
import { importAfdAfdt, getReps, getCurrentCompany } from "utils/apiHelpers";
import "components/UI/Page/Page.scss";
import "styles/rep-import.scss";
import "styles/afd-dropzone.scss";
import Dropzone from "react-dropzone";
import NotificationRow from "components/NotificationRow";
import Select from "components/UI/Select";
import FieldTabs from "components/controls/FieldTabs";
import uploadImg from "img/upload.png";
import { withTranslation } from "react-i18next";
import * as images from "components/svg-images";
import { getTitle, getSystemName } from "utils/common";
import { TranslationNamespaces } from "types/translationNamespaces";

const b = BEM.b("rep-import");
const dz = BEM.b("afd-dropzone");
const p = BEM.b("page");

class RepPegador extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      activeType: "afd",
      selectedRep: "",
      notification: "",
      files: [],
      errors: null,
    };
    document.title = getTitle(t("Import AFD/AFDT"));
  }

  getFormErrors() {
    const { t } = this.props;
    const { selectedRep, files } = this.state;
    let errors = {};
    if (!selectedRep || !selectedRep.externalId) {
      errors.punchMachine = t("Please select punch machine");
    }
    if (!files || files.length === 0 || !this.afdDropZone.valid()) {
      errors.afdDropZone = true;
    }
    if (Object.keys(errors).length === 0) {
      errors = false;
    }
    return errors;
  }
  onImport() {
    const errors = this.getFormErrors();
    const { t } = this.props;
    if (!errors) {
      const { files, selectedRep } = this.state;
      importAfdAfdt(
        {
          companyUUID: window.global_store.company.uuid,
          companyId: window.global_store.company.id,
          repExternalId: selectedRep.externalId,
          serialNumber: selectedRep.serialNumber,
          attachment: files[0],
        },
        (progress) => console.log(progress),
      )
        .then((res) => {
          this.setState({
            notification: {
              type: "success",
              message: t("Great! Your punches are updated for this REP with success!"),
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            notification: {
              type: "error",
              message: `${t("Ooops! There is an error in request!")} ${t(err.response.data.error.message)}`,
            },
          });
        });
    }
    this.setState({ errors });
  }

  render() {
    const { t } = this.props;
    const { notification, errors, activeType, selectedRep } = this.state;

    return (
      <SettingsLayout title={t(`${TranslationNamespaces.common}|Settings`)}>
        <div className={`${p()} ${b()}`} style={{ marginTop: 20 }}>
          {notification && (
            <NotificationRow
              employeesPage
              onClose={() => this.setState({ notification: "" })}
              type={notification.type}
              message={notification.message}
            />
          )}
          <div className={p("content")}>
            <div className={b("description")}>
              <img className={b("image")} alt="Upload" src={uploadImg} />
              <div className={b("image-description")}>
                {t("Import the AFD or AFDT files from your machine or point system to the {{systemName}}", {
                  systemName: getSystemName(),
                })}
              </div>
            </div>
            <div className={b("label")}>{t("Punch machine")}</div>
            {/* <TextInputControl error={errors && errors.punchMachine} onRef={ref => (this.punchMachine = ref)} /> */}
            <RepsDropdownControl value={selectedRep} onChange={(selectedRep) => this.setState({ selectedRep })} />
            {errors && errors.punchMachine && <div className={b("error")}>{errors.punchMachine}</div>}
            <div className={b("label", ["file-type"])}>{t("File type")}</div>
            <FieldTabs
              active={activeType}
              onChange={(activeType) => this.setState({ activeType })}
              tab1={{
                value: "afd",
                label: "AFD",
              }}
              tab2={{
                value: "afdt",
                label: "AFDT",
              }}
            />

            <AfdDropZone
              t={t}
              fileType={activeType}
              onRef={(ref) => (this.afdDropZone = ref)}
              error={errors ? errors.afdDropZone : false}
              onDrop={(files) => this.setState({ files })}
            />
            <div className={b("buttons")}>
              <button className={b("button")} onClick={this.onImport.bind(this)}>
                {t("Import")}
              </button>
            </div>
          </div>
        </div>
      </SettingsLayout>
    );
  }
}

export default withTranslation([TranslationNamespaces.reps, TranslationNamespaces.sidebar])(RepPegador);

class AfdDropZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      fileType: this.props.fileType,
      fromText: false,
      textAreaValid: true,
    };
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    document.addEventListener("keyup", this.handleKeyPress.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyPress.bind(this));
  }
  valid() {
    let textAreaValid = true;
    if (this.state.fromText && !this.refs.textarea.value) {
      textAreaValid = false;
    } else {
      textAreaValid = true;
    }
    this.setState({ textAreaValid });
    return textAreaValid;
  }
  handleKeyPress(ev) {
    if (ev.keyCode === 27) {
      this.setState({ fromText: false });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ fileType: nextProps.fileType });
  }
  getSelectedReason = () => this.reason.getSelectedOption().value;

  onChange(val) {
    this.props.onChange && this.props.onChange(val);
  }
  onDrop(files) {
    this.setState(
      {
        files,
      },
      () => {
        this.props.onDrop && this.props.onDrop(files);
      },
    );
  }

  removeFile(fileName) {
    this.setState({ files: this.state.files.filter((f) => f.name !== fileName) });
  }
  render() {
    const { files, fromText, fileType, textAreaValid } = this.state;
    const { t, error } = this.props;

    return (
      <div className={dz()}>
        <div className={dz("header")}>
          <div className={dz("title")}>{t("Drop the file here")}</div>
          {/* <div className={dz("from-text")} onClick={() => this.setState({ fromText: true })}>{t("Add file from text")}</div> */}
        </div>
        {fromText ? (
          <textarea ref="textarea" autoFocus className={dz("textarea", { error: !textAreaValid })} />
        ) : files.length === 0 ? (
          <div className={dz("dz-wrapper", { error })}>
            <Dropzone
              multiple={false}
              preventDropOnDocument
              className={dz("zone")}
              activeClassName={dz("zone", ["active"])}
              acceptClassName={dz("zone", ["accept"])}
              rejectClassName={dz("zone", ["reject"])}
              onDrop={this.onDrop.bind(this)}
            >
              {(dropzoneProps) => (
                <>
                  <div className={dz("message", ["default"])}>{t("Drop or Upload your files")}</div>
                  <div className={dz("message", ["reject"])}>{t("Invalid format")}</div>
                </>
              )}
            </Dropzone>
          </div>
        ) : (
          <ul className={dz("dropped-files")}>
            {files.map((f) => (
              <li key={f.name} className={dz("file")}>
                {fileType === "afd" ? images.afdFileIcon : images.afdtFileIcon}
                {f.name}
                <div className={dz("remove-file-icon")} onClick={this.removeFile.bind(this, f.name)}>
                  {images.clear}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

class RepsDropdownControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reps: [],
    };
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    getCurrentCompany().then((r) => {
      getReps({ companyUuid: r.uuid }).then((r) => {
        this.setState({
          reps: r.items
            ? r.items.map((item) => ({
                label: item.name,
                value: item.id,
                externalId: item.externalId,
                serialNumber: item.serialNumber,
              }))
            : [],
        });
      });
    });
  }
  onChange(value) {
    const { reps } = this.state;
    const { onChange } = this.props;
    const selectedRep = reps.find((rep) => rep.value === value);

    onChange(selectedRep);
  }
  render() {
    const { reps } = this.state;
    const { value } = this.props;

    if (reps.length > 0) {
      return (
        <Select
          value={value ? value.value : ""}
          onChange={this.onChange.bind(this)}
          placeholder={this.props.placeholder}
          options={reps}
        />
      );
    }
    return null;
  }
}
