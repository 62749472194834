import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import Chip from "components/UI/Chip";
import Button from "components/UI/Button";
import BEM from "utils/BEM";
import { editIPValidation, getIPValidations } from "utils/apiHelpers";
import { Location } from "types/models/location";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { ColumnAlign } from "components/TableCommon";
import "../Validations.scss";

const b = BEM.b("locations-validations");

interface ValidationsIPProps extends WithTranslation {
  currentLocation: Location;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  onYes: () => void;
}

interface ValidationsIPState {
  ipValidations: unknown[];
}

class ValidationsIP extends Component<ValidationsIPProps, ValidationsIPState> {
  readonly state = {
    ipValidations: [],
  };

  async componentDidMount() {
    const ipValidations = await getIPValidations(this.props.currentLocation.id);

    this.setState({ ipValidations });
  }

  editIPValidation = async (ipValidationId: number, data) => {
    const { t, setNotification, onYes } = this.props;

    try {
      await editIPValidation(ipValidationId, { body: { ip_address: data } });
    } catch (error) {
      const err: any = error;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  render() {
    const { ipValidations } = this.state;
    const { currentLocation, t } = this.props;
    const { id } = currentLocation;

    const columns = [
      {
        label: t("ID"),
        accessor: "id",
        maxWidth: 120,
      },
      {
        label: t("IP"),
        accessor: "ip",
      },
      {
        label: t("Added On"),
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
        maxWidth: 200,
      },
      {
        label: t("Status"),
        accessor: "active",
        Cell: ({ value, original }) => (
          <div>
            <div className={b("item-status")}>{value && <Chip intent="success">{t("Active")}</Chip>}</div>
            <div className={b("item-actions")}>
              <Button
                className={b("item-action-button")}
                onClick={() =>
                  this.editIPValidation(original.id, {
                    active: !original.active,
                  })
                }
              >
                {original.active ? t("Disable") : t("Enable")}
              </Button>
              <Button
                className={b("item-action-button", ["remove"])}
                onClick={() =>
                  this.editIPValidation(original.id, {
                    deleted_at: new Date().toISOString(),
                  })
                }
              />
            </div>
          </div>
        ),
        align: "center" as ColumnAlign,
        maxWidth: 200,
      },
    ];

    return (
      <div className={b()}>
        <h3 className={b("list-header")}>{t("IP")}</h3>
        <TablePage
          rows={ipValidations}
          columns={columns}
          columnSelectorOnFiltersRow
          customColumnsAvailable={false}
          className="departments-table"
          noContentComponent={<NoContent>{t("This location has no IP validations")}</NoContent>}
        />
      </div>
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(ValidationsIP);
