import { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { dowDict } from "utils/get-week-start";
import moment from "moment-timezone";
import { GlobalStoreCompany } from "types/models/company";
import DateHelpers from "utils/date.helpers";
import Config from "config";
import {
  ParsedTimesheetData,
  ParsedTimesheetWeekData,
  TimesheetApprovalStatus,
  TimesheetsListItem,
} from "./timesheet.types";
import { TimesheetResponse } from "./timesheet-api.service";
import { UserProfileTask } from "../Projects/projectsApiTypes";

export function getStatusBadgeStatus(timesheetApprovalStatus: TimesheetApprovalStatus): StatusBadgeStatuses | null {
  if (timesheetApprovalStatus === TimesheetApprovalStatus.approved) return StatusBadgeStatuses.approved;
  if (timesheetApprovalStatus === TimesheetApprovalStatus.declined) return StatusBadgeStatuses.declined;
  if (timesheetApprovalStatus === TimesheetApprovalStatus.pending) return StatusBadgeStatuses.pending;
  if (timesheetApprovalStatus === TimesheetApprovalStatus.canceled) return StatusBadgeStatuses.default;
  if (timesheetApprovalStatus === TimesheetApprovalStatus.revoked) return StatusBadgeStatuses.default;
  return null;
}

const getTasksWithoutTime = (parsedData: ParsedTimesheetData): string[] =>
  parsedData.tasksData.filter((e) => e.dates.reduce((partialSum, a) => partialSum + a, 0) === 0).map((e) => e.taskUuid);

export const populateResponseWithEmptyTasks = (
  inputData: TimesheetResponse,
  parsedData: ParsedTimesheetData,
): TimesheetResponse => {
  let inputDataL = inputData;

  const emptyTasksUuids = getTasksWithoutTime(parsedData);

  if (emptyTasksUuids?.length) {
    inputDataL = {
      ...inputData,
      content: {
        ...inputData.content,
        days: inputData.content.days.map((day) => {
          emptyTasksUuids.forEach((taskUuid) => {
            if (day.activitiesMinutesByTasks[taskUuid]) {
              day.activitiesMinutesByTasks[taskUuid].activitiesMinutes = 0;
            } else {
              day.activitiesMinutesByTasks[taskUuid] = {
                activitiesMinutes: 0,
              };
            }
          });
          return day;
        }),
      },
    };
  }

  return inputDataL;
};

function calculateSummary(days: TimesheetResponse["content"]["days"]) {
  const summary: ParsedTimesheetData["summary"] = {
    actualWorkMinutes: 0,
    dailyActivitiesMinutes: 0,
    plannedWorkMinutes: 0,
    gt24hPerDayNumDays: 0,
    overtimeNumDays: 0,
    activityMoreThanWorkingTimeDays: 0,
    overallActivityMoreThanWorkingTimeDays: 0,
  };
  for (const day of days) {
    summary.actualWorkMinutes += day.actualWorkMinutes;
    summary.dailyActivitiesMinutes += day.dailyActivitiesMinutes;
    summary.plannedWorkMinutes += day.plannedWorkMinutes;
    summary.gt24hPerDayNumDays += day.dailyActivitiesMinutes > 24 * 60 ? 1 : 0;
    summary.overtimeNumDays += day.actualWorkMinutes > day.plannedWorkMinutes ? 1 : 0;
    summary.activityMoreThanWorkingTimeDays +=
      day.actualWorkMinutes > 0 && day.dailyActivitiesMinutes > day.actualWorkMinutes ? 1 : 0;
    summary.overallActivityMoreThanWorkingTimeDays += day.dailyActivitiesMinutes > day.actualWorkMinutes ? 1 : 0;
  }
  return summary;
}

export const parseTimesheet = (inputData: TimesheetResponse, tasksArray: UserProfileTask[] = []) => {
  const result: ParsedTimesheetData = {
    dates: [],
    workingHours: [],
    unassignedMinutes: [],
    isDayPlannedArray: [],
    tasksData: [],
    additionalTasks: [],
    summary: calculateSummary(inputData.content.days),
  };

  const tasksHoursMap: Record<string, number[]> = {};
  inputData.content.days.forEach((day, i) => {
    result.dates.push(day.date);
    result.workingHours.push(day.actualWorkMinutes);
    result.isDayPlannedArray.push(day.isDayPlanned);

    let totalMinutesOnDay = 0;
    Object.keys(day.activitiesMinutesByTasks).forEach((taskUuid) => {
      if (tasksHoursMap[taskUuid]) {
        tasksHoursMap[taskUuid].push(day.activitiesMinutesByTasks[taskUuid].activitiesMinutes);
      } else {
        tasksHoursMap[taskUuid] = [...Array(i)].map((e) => 0);
        tasksHoursMap[taskUuid].push(day.activitiesMinutesByTasks[taskUuid].activitiesMinutes);
      }
      totalMinutesOnDay += day.activitiesMinutesByTasks[taskUuid].activitiesMinutes;
    });

    if (!day.actualWorkMinutes) {
      result.unassignedMinutes.push(0);
    } else {
      result.unassignedMinutes.push(day.actualWorkMinutes - totalMinutesOnDay);
    }
    Object.keys(tasksHoursMap)
      .filter((x) => !Object.keys(day.activitiesMinutesByTasks).includes(x))
      .forEach((missingTaskUuid) => {
        tasksHoursMap[missingTaskUuid].push(0);
      });
  });

  Object.keys(tasksHoursMap).forEach((taskUuid) => {
    // TODO verify why the task is missing
    if (inputData.metadata.tasksByUuid[taskUuid]) {
      result.tasksData.push({
        taskUuid,
        taskName: inputData.metadata.tasksByUuid[taskUuid].name,
        projectName: inputData.metadata.tasksByUuid[taskUuid].project.name,
        projectUuid: inputData.metadata.tasksByUuid[taskUuid].project.uuid,
        dates: tasksHoursMap[taskUuid],
      });
    }
  });

  result.additionalTasks =
    tasksArray.length > 0
      ? tasksArray!
          .filter((t) => !Object.keys(tasksHoursMap).includes(t.uuid))
          .map((task) => ({
            taskUuid: task.uuid,
            taskName: task.name,
            projectName: task.projectName,
            projectUuid: task.projectUuid,
          }))
      : [];

  return result;
};

function getTaskData(data: ParsedTimesheetData, startDay: number, endDay: number) {
  return data.tasksData.map((task) => ({ ...task, dates: task.dates.slice(startDay, endDay) }));
}

export const splitTimesheetToWeeks = (data: ParsedTimesheetData) => {
  const weeks: ParsedTimesheetWeekData[] = [];
  const firstDayNumber = moment(data.dates[0]).weekday(); // 0
  const firstDayOfWeek = dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day] || 0;
  let startDay = 0;
  let shift = firstDayNumber;

  // Adjust shift if week starts on Monday
  if (firstDayOfWeek === 1) {
    shift = shift ? shift - 1 : 6;
  }

  // here we define on which position of the week must be placed first day from timesheet
  let startDayOffset = shift + 1;

  for (let endDay = 7 - shift; endDay < data.dates.length + 7; endDay += 7) {
    weeks.push({
      dates: data.dates.slice(startDay, endDay),
      isDayPlannedArray: data.isDayPlannedArray.slice(startDay, endDay),
      tasksData: getTaskData(data, startDay, endDay),
      startDayOffset,
    });
    startDay = endDay;
    startDayOffset = 0;
  }

  return weeks;
};

export const convertTimesheetsIntoLockedDatesArray = (timesheets: TimesheetsListItem[]): string[] =>
  timesheets.reduce((acc: string[], ts) => acc.concat(DateHelpers.createRangeArray(ts.startDate, ts.endDate)), []);
export const createDatesArrayFromTodayToGivenDate = (endDate: moment.Moment): string[] =>
  DateHelpers.createRangeArray(
    moment().add(1, "day").format(Config.apiDateFormat),
    endDate.format(Config.apiDateFormat),
  );
export const createDatesStartEndDate = (startDate: string, endDate: string): string[] =>
  DateHelpers.createRangeArray(startDate, endDate);
