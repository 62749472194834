import moment from "moment";
import { TimesheetsListItem } from "components/Timesheets";
import { minsToHrsMins } from "utils/common";
import { Location } from "types/models/location";
import { StoredActivity } from "./TrackTime.store";
import { ActivityStatus } from "./HeaderTimeTracker";
import { ProjectOption } from "./components/TaskOptions";

export const isDateInLockedTimesheetRanges = (date: moment.Moment, timesheets: TimesheetsListItem[]) => {
  const inRange = timesheets.find(
    (timesheet) =>
      date.isSameOrBefore(timesheet.endDate, "day") && date.isSameOrAfter(timesheet.startDate, "day"),
  );
  return !!inRange;
};
export const isLockedByPayroll = (date: moment.Moment, lastLockDate: string | null) => (lastLockDate && moment(date).isSameOrBefore(moment(lastLockDate), "day"));
export const isDateAfterToday = (date: moment.Moment) => date.isAfter(moment(), "day");
export const getRunningActivityStartDateTime = (activity: StoredActivity | null) => {
  if (!activity) return null;
  const createdAt = new Date(activity.createdAt!);
  const seconds = createdAt.getSeconds();
  const start = moment(
    `${activity.date} ${minsToHrsMins(activity.startTime!)}:${Number.isNaN(seconds) ? "00" : seconds}`,
    "YYYY-MM-DD HH:mm:ss",
  );

  return start.toDate();
};
export const parseTime = (time: string) => {
  if (time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
    return parseInt(time.split(":")[0], 10) * 60 + parseInt(time.split(":")[1], 10);
  }
  return 0;
};

export const validateTimeValue = (value: string) => {
  let error;

  if (value.indexOf(":") === -1) {
    error = "Incorrect time format";
  } else {
    const [hh, mm] = value.split(":");
    if (value.split(":").length !== 2) {
      error = "Incorrect format";
    } else if (!(Number(hh) >= 0 && Number(hh) < 24 && Number(mm) >= 0 && Number(mm) < 60)) {
      error = "Incorrect time format";
    }
  }

  return error;
};
export const isRunningActivity = (activity: StoredActivity | null) => activity?.status === ActivityStatus.running;
export const getDurationInMinutes = (startTime?: number | null, endTime?: number | null) => {
  if (!startTime || !endTime) return 0;
  return endTime - startTime;
}

export const findParentInTree = (el: HTMLElement, classNames: string[]): boolean => {
  if (typeof el?.className === 'string' && classNames.find(c => el?.className.includes(c))) return true;
  if (!el.parentElement) return false;
  return findParentInTree(el.parentElement, classNames);
}

export const filterLocationsBasedOnProject = (projects: ProjectOption[], locations: Location[], selectedProjectUuid?: string) => {
  const proj = projects?.find((prj) => prj.project?.uuid === selectedProjectUuid);

  const projLocs =
    proj?.project?.locationUuids
      .map((locUuid) => locations?.find((loc) => loc.uuid === locUuid))
      .filter((loc) => loc?.active) || [];
  const availLocs = projLocs.length === 0 ? locations : (projLocs as Location[]);
  const locs = availLocs?.map((loc) => ({
    address: loc.formatted_address,
    id: loc.uuid,
    name: loc.name,
    uuid: loc.uuid,
  })) ?? [];
  return locs;
}
