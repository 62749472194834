import { stylesheet } from "astroturf";
import { useTranslation } from "react-i18next";
import { Subscribe } from "unstated";
import { TranslationNamespaces } from "types/translationNamespaces";
import Button, { ButtonState } from "components/controls/StyledButton";
import { useContext, useEffect, useState } from "react";
import SettingsBillingLayout from "components/Layout/SettingsBillingLayout";
import GlobalContext from "context/global-context";
import { FeatureLabel, Interval } from "types/models/subscription";
import ModalDialog from "components/UI/ModalDialog";
import PricingContainer, { CheckedProduct } from "../PricingContainer";
import { BillingService } from "../BillingService";
import UpgradeSeatsModal from "../Modals/UpgradeSeatsModal";
import NeedOwnerPermissionsModal from "../Modals/NeedOwnerPermissionsModal";
import UpgradePlanModal from "../Modals/UpgradePlanModal";
import SwitchToAnnualModal from "../Modals/SwitchToAnnualModal";
import InvoiceList from "./InvoiceList";

const styles = stylesheet`@import "../billing.scss"`;

type BillingProduct = CheckedProduct & {
  seatsUsed: number;
  currentPeriodEndedAt?: Date;
  maxSeats: number;
};

const ProductCard = ({ product, interval }: { product: BillingProduct; interval: Interval }) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [updateSeatsPopupVisible, setUpdateSeatsPopupVisible] = useState<boolean>(false);
  const [updatePlanPopupVisible, setUpdatePlanPopupVisible] = useState<boolean>(false);
  const [switchAnnualPopupVisible, setSwitchAnnualPopupVisible] = useState<boolean>(false);

  const remainingSeats = product.maxSeats - product.seatsUsed;
  const remainingPercent = (product.seatsUsed / product.maxSeats) * 100;
  const progressWidth = Math.floor(remainingPercent) > 100 ? 100 : Math.floor(remainingPercent);
  const isEnterprise = BillingService.isEnterpriseCustomer();

  const openSwitchToAnnualModal = async () => {
    setSwitchAnnualPopupVisible(true);
  };

  const openUpdateSeatsModal = async () => {
    await PricingContainer.selectProduct(product.features[0]);
    await PricingContainer.selectPrice(
      PricingContainer.state.interval,
      t,
      PricingContainer.state.currentSeatsNumber,
      true,
    );
    setUpdateSeatsPopupVisible(true);
  };
  const openUpdatePlanModal = async () => {
    await PricingContainer.selectProduct(product.features[0]);
    if (!PricingContainer.state.activeSubscription) {
      window.location.href = "/billing/pricing";
    }
    await PricingContainer.selectPrice(
      PricingContainer.state.interval,
      t,
      PricingContainer.state.currentSeatsNumber,
      true,
    );
    setUpdatePlanPopupVisible(true);
  };
  const isOwner = BillingService.checkIsCompanyAdmin(true);
  const getPlanDesctiption = (): string => {
    if (!product.usedInSubscription) return t(`${product.features[0]}_not_purchased_description`);
    if (isEnterprise)
      return t(`enterprise_plan_${interval} {{renewDate}}`, {
        renewDate: BillingService.formatPaymentPeriodDate(product.currentPeriodEndedAt!),
      });
    return t(`professional_plan_${interval} {{renewDate}}`, {
      renewDate: BillingService.formatPaymentPeriodDate(product.currentPeriodEndedAt!),
    });
  };
  return (
    <>
      <div className={styles.ProductCard}>
        <div className={styles.Padding24}>
          <div className={styles.ProductTitle}>{t(FeatureLabel[product.features[0]])}</div>
          <div className={`${styles.TextGrey700} ${styles.Text14}`}>{getPlanDesctiption()}</div>
        </div>
        {product.usedInSubscription ? (
          <>
            <div className={styles.ProductContent}>
              <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
                <div className={`${styles.TextGrey900} ${styles.Text14}`}>
                  {t("seats remaining {{remainingSeats}}", {
                    remainingSeats: remainingSeats >= 0 ? remainingSeats : 0,
                  })}
                </div>
                <div className={`${styles.TextGrey700} ${styles.Text14}`}>
                  {t("seats_used_bar {{used}} {{max}}", { used: product.seatsUsed, max: product.maxSeats })}
                </div>
              </div>
              <div className={styles.ProgressBar}>
                <div className={styles.ProgressActive} style={{ width: `${progressWidth}%` }} />
              </div>
            </div>
            {!isEnterprise && (
              <div className={styles.ProductFooter}>
                {interval === Interval.Month && (
                  <a className={styles.Link} onClick={() => openSwitchToAnnualModal()}>
                    {t("Switch to Annual")}
                  </a>
                )}
                <a className={styles.Link} onClick={() => openUpdateSeatsModal()}>
                  {t("Update Seats")}
                </a>
              </div>
            )}
          </>
        ) : (
          <>
            {!isEnterprise && (
              <div className={styles.ProductFooter}>
                <Button
                  type="button"
                  value={t("Add to subscription")}
                  style={{ width: "auto", marginInlineStart: "auto", padding: "11px 12px" }}
                  state={ButtonState.attention}
                  onClick={() => openUpdatePlanModal()}
                />
              </div>
            )}
          </>
        )}
      </div>

      <ModalDialog
        preventOutsideClose
        onClose={() => setUpdateSeatsPopupVisible(false)}
        isOpen={updateSeatsPopupVisible}
        width={464}
        top="120px"
      >
        {updateSeatsPopupVisible && (
          <>
            {isOwner ? (
              <UpgradeSeatsModal
                companyUuid={PricingContainer.state.companyUuid}
                coupon={PricingContainer.state.coupon}
                close={() => setUpdateSeatsPopupVisible(false)}
                productItem={PricingContainer.productItems[0]}
                currentSeatsNumber={PricingContainer.state.currentSeatsNumber!}
                interval={PricingContainer.state.interval}
                subscription={PricingContainer.state.activeSubscription!}
              />
            ) : (
              <NeedOwnerPermissionsModal close={() => setUpdateSeatsPopupVisible(false)} />
            )}
          </>
        )}
      </ModalDialog>

      <ModalDialog
        preventOutsideClose
        onClose={() => setSwitchAnnualPopupVisible(false)}
        isOpen={switchAnnualPopupVisible}
        width={464}
        top="120px"
      >
        {switchAnnualPopupVisible && (
          <>
            {isOwner ? (
              <SwitchToAnnualModal
                companyUuid={PricingContainer.state.companyUuid}
                coupon={PricingContainer.state.coupon}
                close={() => setSwitchAnnualPopupVisible(false)}
                products={PricingContainer.state.products}
                subscription={PricingContainer.state.activeSubscription!}
              />
            ) : (
              <NeedOwnerPermissionsModal close={() => setSwitchAnnualPopupVisible(false)} />
            )}
          </>
        )}
      </ModalDialog>

      <ModalDialog
        preventOutsideClose
        onClose={() => setUpdatePlanPopupVisible(false)}
        isOpen={updatePlanPopupVisible}
        width={464}
        top="120px"
      >
        {updatePlanPopupVisible && (
          <>
            {isOwner ? (
              <UpgradePlanModal
                companyUuid={PricingContainer.state.companyUuid}
                subscription={PricingContainer.state.activeSubscription!}
                productItem={PricingContainer.productItems[0]}
                interval={PricingContainer.state.interval}
                coupon={PricingContainer.state.coupon}
                close={() => setUpdatePlanPopupVisible(false)}
              />
            ) : (
              <NeedOwnerPermissionsModal close={() => setUpdatePlanPopupVisible(false)} />
            )}
          </>
        )}
      </ModalDialog>
    </>
  );
};

const BillingSummary = () => {
  const context = useContext(GlobalContext);
  const { t } = useTranslation([TranslationNamespaces.subscription, TranslationNamespaces.fullPage]);
  const [productsData, setProductsData] = useState<BillingProduct[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const company = await context.getCompany();
    await BillingService.getInitialCustomerData(company.uuid);
    await PricingContainer.setCompanyUuid(company.uuid);
    await PricingContainer.getSubscriptions();
    await PricingContainer.getProducts();
    await PricingContainer.getSubscriptionCoupon();
    await PricingContainer.getCurrentSeatsUsed();
    await processProducts();
  };

  const processProducts = async () => {
    const { products, usedSeats, activeSubscription } = PricingContainer.state;
    const formatted: BillingProduct[] = products.map((p: CheckedProduct) => ({
      ...p,
      maxSeats: activeSubscription?.items.find((i) => i.feature === p.features[0])?.maxSeats || 0,
      seatsUsed: usedSeats[p.features[0]] ?? 0,
      currentPeriodEndedAt: activeSubscription?.currentPeriodEndedAt,
    }));
    setProductsData(formatted);
  };

  return (
    <Subscribe to={[PricingContainer]}>
      {(pricing: typeof PricingContainer) => (
        <SettingsBillingLayout
          title={t(`${TranslationNamespaces.fullPage}|Billing`)}
          headerAction={
            <Button
              type="button"
              value={t("Contact Support")}
              style={{ marginInlineStart: "24px", width: "auto", fontWeight: "500", padding: "0 16px" }}
              state={ButtonState.secondary}
              onClick={() => pricing.contactSupport()}
            />
          }
        >
          <>
            <div className={styles.BillingSummary}>
              <div className={styles.Details}>
                <div className={styles.BlockTitle}>{t("Plan Details")}</div>
                <div className={styles.ProductsWrapper}>
                  {!!productsData.length &&
                    productsData.map((p) => <ProductCard product={p} key={p.uuid} interval={pricing.state.interval} />)}
                </div>
              </div>
            </div>

            <InvoiceList />
          </>
        </SettingsBillingLayout>
      )}
    </Subscribe>
  );
};

export default BillingSummary;
