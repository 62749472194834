import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import moment from "moment";
import BEM from "utils/BEM";
import { fireEvent, hasEmployeesAccess, hasPermisionAccess, isSupervisor } from "utils/common";
import FullPage from "components/Layout/FullPage";
import TablePage from "components/TablePage";
import NoContent from "components/NoContent";
import NotificationRow from "components/NotificationRow";
import PunchesToggles, { PunchesTogglesType } from "components/Punches/PunchesToggles";
import AdditionalFiltersControl from "components/controls/AdditionalFiltersControl";
import GroupSuperpunchSearch from "components/SuperpunchGroups/GroupSuperpunchSearch";
import { SearchControlItemType, SearchControlOnChangeData } from "components/UI/SearchControlNew";
import { SearchEntity } from "components/SuperpunchGroups/GroupsSearch/types";
import { SP_DATE_FORMAT, SuperpunchContext } from "context/SuperpunchProvider";
import { PermissionSectionName } from "types/models/permissions";
import { NotificationType } from "types/common";
import { TranslationNamespaces } from "types/translationNamespaces";
import { SuperpunchTableRowData, SuperpunchTableRows } from "types/models/superpunch";
import { SuperpunchCustomEvents } from "context/SuperpunchProvider/types";
import PunchSettingsModal from "components/Punches/PunchSettingsModal";
import noPunches from "img/no-punches.png";
import DateRangePicker from "components/controls/DatePicker/DateRangePicker";
import InfoControl from "./InfoControl";
import SuperPunchErrorNotification from "./SuperPunchErrorNotification";
import SuperPunchOverlay from "./SuperPunchOverlay";
import ActionsBar from "./ActionsBar";
import SuperPunchHeader from "./Header";
import { NotificationMessage, DateRangePickerWrapper } from "./styled";
import { getColumns } from "./helpers";
import "./superpunch.scss";

const p = BEM.b("page");

interface SuperPunchProps extends WithTranslation, RouteComponentProps, AllFlagsLDClient {}

interface SuperPunchState {
  loading: boolean;
  selectedColumns: string;
  tableRows: SuperpunchTableRows | null;
  error: string | null;
  showSettingsPopup: boolean;
}

class SuperPunch extends Component<SuperPunchProps, SuperPunchState> {
  static contextType = SuperpunchContext;
  context!: ContextType<typeof SuperpunchContext>;

  readonly state: Readonly<SuperPunchState> = {
    loading: false,
    selectedColumns:
      "date,schedule,entry,break_start,break_end,exit,worked_hours,break,holiday,extra_hours,hours_bank,requests",
    tableRows: null,
    error: null,
    showSettingsPopup: false,
  };

  async UNSAFE_componentWillMount() {
    document.addEventListener(SuperpunchCustomEvents.tableRowsLoaded, this.handleDataLoad);
    await this.getData();
  }

  componentWillUnmount(): void {
    document.removeEventListener(SuperpunchCustomEvents.tableRowsLoaded, this.handleDataLoad);
  }

  handleDataLoad = (ev: CustomEvent<SuperpunchTableRows>): void => {
    this.setState({ tableRows: ev.detail, loading: false });
  };

  getData = async () => {
    await Promise.all([this.getTableRows(), this.getBusinessRulesGroups(), this.getCustomBreaksNamesMap()]);
  };

  getBusinessRulesGroups = async () => {
    const { employeeId, employeeUuid } = this.context;

    if (employeeId && employeeUuid) {
      await this.context.getBusinessRulesGroups();
    }
  };

  getTableRows = async (): Promise<void> => {
    const { employeeId, employeeUuid } = this.context;

    if (employeeId && employeeUuid) {
      await this.context.getTableRows();
    }
  };

  getCustomBreaksNamesMap = async (): Promise<void> => {
    await this.context.getCustomBreaksNamesMap();
  };

  updateHistory = () => {
    this.props.history.push(`/punches?type=edit&${this.getUrlParams()}`);
  };

  onShowOnlyIssuesChange = (checked: boolean) => {
    this.context.setContextState({ showOnlyIssues: checked });

    this.setState({ loading: true }, () => {
      this.updateHistory();

      void this.getTableRows();
    });
  };

  // TODO test with supervisor
  onDirectReportsOnlyChange = (checked: boolean): void => {
    if (checked) {
      const { profile } = window.global_store;

      this.context.setContextState({
        employeeId: profile.id,
        employeeUuid: profile.uuid,
        searchValue: profile.full_name,
        directReportsOnly: checked,
      });

      this.setState({ loading: true }, () => {
        this.updateHistory();

        void this.getData();
      });
    } else {
      this.context.setContextState({ directReportsOnly: checked });

      this.updateHistory();
    }
  };

  onGroupSearchValueChange = (entity: SearchEntity): void => {
    this.context.setContextState({
      employeeId: entity.id as number,
      employeeUuid: entity.uuid as string,
      searchValue: entity.name as string,
    });

    this.setState({ loading: true }, () => {
      this.updateHistory();

      void this.getData();
    });
  };

  onEmployeeSearchValueChange = (searchObj: SearchControlOnChangeData): void => {
    if (searchObj.employee && searchObj.label) {
      this.context.setContextState({
        employeeId: searchObj.employee.id,
        employeeUuid: searchObj.employee.uuid,
        searchValue: searchObj.label,
      });

      this.setState({ loading: true }, () => {
        this.updateHistory();

        void this.getData();
      });
    }
  };

  onSearchDatesChange = (startDate: moment.Moment | null, endDate: moment.Moment | null): void => {
    if (startDate && endDate) {
      this.context.setContextState({ startDate, endDate });

      this.setState({ loading: true }, () => {
        this.updateHistory();

        void this.getData();
      });
    }
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_Activity", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  getUrlParams = (): string => {
    const { employeeId, employeeUuid, startDate, endDate, showOnlyIssues, directReportsOnly, searchValue } =
      this.context;

    let url = "";

    if (employeeId && employeeUuid) {
      const startDateStr = startDate.format(SP_DATE_FORMAT);
      const endDateStr = endDate.format(SP_DATE_FORMAT);

      url = `label=${searchValue}&id=${employeeId}&uuid=${employeeUuid}&startDate=${startDateStr}&endDate=${endDateStr}`;

      if (showOnlyIssues) {
        url += "&showOnlyIssues=true";
      }
      if (directReportsOnly) {
        url += "&directReportsOnly=true";
      }
    }

    return url;
  };

  getDetailedReportUrl = (): string => {
    const { startDate, endDate, employeeId, employeeUuid, searchValue } = this.context;
    let url = "";

    const hasAccess = hasPermisionAccess(PermissionSectionName.basicReports);

    if (hasAccess && searchValue && employeeId && employeeUuid && startDate && endDate) {
      url = `/reports/detailed?${this.getUrlParams()}`;
    }

    return url;
  };

  getSearchComponent = () => {
    const { startDate, endDate, employeeId, employeeUuid, searchValue, directReportsOnly } = this.context;

    return (
      <GroupSuperpunchSearch
        initialSearch={{
          id: employeeId,
          uuid: employeeUuid,
          name: searchValue,
          type: SearchControlItemType.employee,
        }}
        startDate={startDate}
        endDate={endDate}
        directReportsOnly={directReportsOnly}
        onEmployeeSelect={this.onGroupSearchValueChange}
      />
    );
  };

  render(): JSX.Element {
    const { error, loading, selectedColumns, tableRows, showSettingsPopup } = this.state;
    const { t, history, flags } = this.props;
    const {
      employeeInfo,
      startDate,
      endDate,
      employeeId,
      employeeUuid,
      showOnlyIssues,
      directReportsOnly,
      employeeBusinessRuleGroups,
      customBreaksNamesMap,
    } = this.context;

    if (error) {
      return <div>{error}</div>;
    }

    const columns = getColumns({
      t,
      employeeId,
      employeeUuid,
      tableRows,
      employeeInfo,
      debitFlag: flags.debitSuperpunch,
      combinedBreaksFlag: flags.combinedBreaks,
      businessRules: employeeBusinessRuleGroups,
      customBreaksNamesMap,
    });

    return (
      <FullPage
        headerAction={
          <SuperPunchHeader
            newNaviagation
            marginTop={0}
            detailedReportUrl={this.getDetailedReportUrl()}
            setShowSettingsPopup={() => this.setState({ showSettingsPopup: true })}
            setShowAddPunchPopup={() => fireEvent(SuperpunchCustomEvents.openNewPunchOverlay, { employeeInfo })}
          />
        }
      >
        <div className={p()} style={{ position: "relative", paddingBottom: "100px" }}>
          <SuperPunchErrorNotification />

          {tableRows?.hasLockedDays && (
            <NotificationRow
              employeesPage
              withCloseButton={false}
              type={NotificationType.locked}
              message={
                <NotificationMessage>
                  {t("Some days of selected period have been closed. The actions are blocked.")}
                </NotificationMessage>
              }
            />
          )}

          <TablePage<SuperpunchTableRowData>
            superpunchPage
            noPadding
            noScroll={!flags.debitSuperpunch}
            filters={
              <>
                {this.getSearchComponent()}

                <AdditionalFiltersControl
                  options={[
                    {
                      label: t("Show Only Issues"),
                      checked: showOnlyIssues,
                      onChange: this.onShowOnlyIssuesChange,
                    },
                    {
                      label: t("Direct reports only"),
                      checked: directReportsOnly,
                      onChange: this.onDirectReportsOnlyChange,
                      hide: !isSupervisor(),
                    },
                  ]}
                />

                <DateRangePickerWrapper>
                  <DateRangePicker
                    newOnChangeApproach
                    onChange={this.onSearchDatesChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </DateRangePickerWrapper>
              </>
            }
            columnSelectorOnFiltersRow
            infoControl={<InfoControl />}
            punchesToggle={
              hasEmployeesAccess() &&
              employeeId &&
              employeeUuid && (
                <PunchesToggles
                  active={PunchesTogglesType.editor}
                  onListClick={(): void => {
                    history.push(`/punches?${this.getUrlParams()}`);
                  }}
                />
              )
            }
            rows={tableRows?.rows || []}
            columns={columns}
            loading={loading || !tableRows}
            selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
            noContentComponent={<NoContent img={noPunches}>{t("No Punches at this time")}</NoContent>}
            onColumnsChange={this.onColumnsChange}
            interactive={false}
            customColumnsAvailable={false}
            getTrProps={(_, rowInfo): Record<string, string> => {
              const className = rowInfo?.original.date?.raw.isAfter(moment()) ? "future-day " : "";

              return {
                /* eslint no-underscore-dangle: ["warn", { "allow": ["_original"] }] */
                className:
                  !rowInfo?.row._original.lockedDay &&
                  !rowInfo?.row._original.inProgress &&
                  rowInfo?.row._original.schedule &&
                  !rowInfo?.row._original.scheduleException &&
                  (!rowInfo?.row._original.shiftEvents || !rowInfo?.row._original.shiftEvents.length)
                    ? `${className}super-punch-row_enable-day`
                    : `${className}super-punch-row`,
              };
            }}
          />
          <ActionsBar
            allRows={tableRows && tableRows.rows ? tableRows.rows : []}
            onClearPunches={(dates: string[]): void => {
              void this.context.clearPunches(dates);
            }}
            onOrganizePunches={(dates: string[]): void => {
              void this.context.organizePunchesChronologically(dates);
            }}
            onRecalculate={(dates: string[]): void => {
              void this.context.recalculateRange({
                from: dates[0],
                to: dates[dates.length - 1],
              });
            }}
            onRemoveSchedule={(dates: string[]): void => {
              void this.context.removeScheduleOnRange({ dates });
            }}
            onValidateAll={(dates: string[]): void => {
              void this.context.validateAllPunchesInDays({ dates });
            }}
            onApproveAll={(dates: string[]): void => {
              void this.context.approveAllPunchesInDays({ dates });
            }}
          />
          <SuperPunchOverlay t={t} />

          {showSettingsPopup && (
            <PunchSettingsModal
              onClose={() => this.setState({ showSettingsPopup: false })}
              setNotification={() => {}}
            />
          )}
        </div>
      </FullPage>
    );
  }
}

export default withLDConsumer()(
  withTranslation([TranslationNamespaces.punchesPage, TranslationNamespaces.employeesPage])(SuperPunch),
);
