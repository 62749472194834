import { Component, ContextType } from "react";
import styled from "styled-components";
import ClickOutside from "react-click-outside";
import GlobalContext from "context/global-context";
import { searchEmployeesAndGroups } from "utils/apiHelpers";
import sentryUtils from "utils/sentryUtils";
import { ClickOutsideType } from "types/common";
import { GroupSearchDepartment } from "types/models/department";
import { FilteredEmployeePayload, GroupSearchUserProfile, UserProfileStatus } from "types/models/userProfile";
import SearchNav, { SearchNavClicker } from "components/UI/SearchControlNew/SearchNav";
import { GroupSearchTeam } from "types/models/team";
import { SearchControlItem, SearchControlItemType } from "components/UI/SearchControlNew";
import { SearchEmployeesAndGroupsRequestData } from "utils/api/types";
import { PermissionSectionName } from "types/models/permissions";
import { listUserProfilesWIthFilters } from "utils/api/company";
import SearchInput from "./SearchInput";
import GroupsDropdown from "./GroupsDropdown";
import SearchResultsDropdown from "./SearchResultsDropdown";
import { SearchEntity } from "./types";

const MIN_SEARCH_LENGTH = 3;
const REQUEST_DELAY_MS = 600;

const SearchWrapper = styled.div`
  position: relative;
  width: 320px;

  .groups-dropdown,
  .search-results-dropdown {
    inset-inline-start: 0;
    top: 45px;
  }
`;

const paginationLimit = 10000;

interface GroupsSearchProps {
  initialSearch: SearchEntity | undefined;
  onSearchResultSelect: (entity: SearchEntity) => void;
  directReportsOnly?: boolean;
  excludeAllGroupsLevel?: boolean;
  /** permission section for new hierarchy search */
  permissionSection: PermissionSectionName;
}

interface GroupsSearchState {
  searchValue: string;
  isOpen: boolean;
  /** This type is used in order to reuse Item component from SearchControlNew */
  searchResults: SearchControlItem[];
  /** This type is used in Search Navigation */
  cachedEntities: SearchEntity[];
  isResultsLoading: boolean;
  isCacheLoading: boolean;
  total: number;
}

class GroupsSearch extends Component<GroupsSearchProps, GroupsSearchState> {
  timer: null | NodeJS.Timeout = null;
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  constructor(props: GroupsSearchProps) {
    super(props);

    this.state = {
      searchValue: props.initialSearch?.name || "",
      isOpen: false,
      searchResults: [],
      cachedEntities: [],
      isResultsLoading: false,
      isCacheLoading: false,
      total: 0,
    };
  }

  async getEmployees(customOffset = 0): Promise<SearchEntity[]> {
    const company = await this.context.getCompany();
    const payload: FilteredEmployeePayload = {
      requestedBy: window.global_store.profile.uuid,
      skip: customOffset,
      limit: paginationLimit,
      includeSelf: true,
      fields: ["id", "uuid", "fullName", "avatarId", "employeeStatus"],
      externalFields: [],
      filterModel: {
        employee_status: { filterType: "set", values: ["active"] },
      },
    };

    const profileTeam = window.global_store.profile.teams[0]?.uuid;
    if (this.props.directReportsOnly) {
      payload.filterModel["teams.uuid"] = { filterType: "text", type: "equals", filter: profileTeam };
      payload.includeSelf = true;
    }
    const { content: data, metadata } = await listUserProfilesWIthFilters(company.uuid, payload);
    const navEmployees: SearchEntity[] = data.map((e) => ({
      id: e.id,
      name: e.fullName,
      uuid: e.uuid,
      type: SearchControlItemType.employee,
    }));

    const entities = [...this.state.cachedEntities, ...navEmployees];
    this.setState({
      cachedEntities: entities,
      total: metadata.total,
    });

    if (this.props.initialSearch?.type === SearchControlItemType.employee && this.props.initialSearch?.uuid) {
      const initialIndex = entities.findIndex((e) => e.uuid === this.props.initialSearch?.uuid);
      if (initialIndex === -1 && metadata.total > entities.length) {
        return this.getEmployees(entities.length);
      }
    }

    this.setState({ isCacheLoading: false });
    return navEmployees;
  }

  componentDidMount() {
    const { initialSearch } = this.props;
    if (initialSearch?.type === SearchControlItemType.employee) {
      this.setState({ isCacheLoading: true }, async () => await this.getEmployees());
      return;
    }
    let entities: (SearchEntity & { name: string })[] = [];

    this.setState({ isCacheLoading: true }, async () => {
      switch (initialSearch?.type) {
        case SearchControlItemType.department:
          entities = (await this.context.getDepartments()).map((d) => ({
            id: d.id,
            uuid: d.uuid,
            name: d.name,
            type: SearchControlItemType.department,
          }));
          break;
        case SearchControlItemType.team:
          entities = (await this.context.getTeams()).map((t) => ({
            id: t.id,
            uuid: t.uuid,
            name: t.name,
            type: SearchControlItemType.team,
          }));
          break;
        case SearchControlItemType.subsidiary:
          entities = (await this.context.getSubsidiaries()).map((s) => ({
            id: s.id,
            uuid: s.uuid,
            name: s.name,
            type: SearchControlItemType.subsidiary,
          }));
          break;
        default:
          break;
      }
      entities.sort((a, b) => a.name.localeCompare(b.name));

      this.setState({ cachedEntities: entities, isCacheLoading: false });
    });
  }

  componentDidUpdate(prevProps: Readonly<GroupsSearchProps>): void {
    if (prevProps.initialSearch && prevProps.initialSearch.uuid !== this.props.initialSearch?.uuid) {
      this.setState({ searchValue: this.props.initialSearch?.name || "" });
    }
  }

  mapResults = (
    entity: GroupSearchDepartment | GroupSearchUserProfile | GroupSearchTeam,
    type: SearchControlItemType,
  ): SearchControlItem => ({
    value: entity.uuid,
    type,
    obj: entity,
    inactive: (entity as GroupSearchUserProfile)?.employee_status !== UserProfileStatus.active,
  });

  search = () => {
    const { searchValue } = this.state;

    if (this.timer) {
      clearTimeout(this.timer);
      this.setState({ isResultsLoading: false });
    }

    this.timer = setTimeout(async () => {
      if (searchValue.length >= MIN_SEARCH_LENGTH) {
        this.setState({ isResultsLoading: true }, async () => {
          try {
            const { permissionSection } = this.props;
            const payload: SearchEmployeesAndGroupsRequestData = {
              term: searchValue,
            };

            payload.permission_section_name = permissionSection;

            const { departments, employees, teams, subsidiaries } = await searchEmployeesAndGroups(payload);

            this.setState({
              searchResults: [
                ...employees.map((e) => this.mapResults(e, SearchControlItemType.employee)),
                ...departments.map((d) => this.mapResults(d, SearchControlItemType.department)),
                ...teams.map((t) => this.mapResults(t, SearchControlItemType.team)),
                ...subsidiaries.map((s) => this.mapResults(s, SearchControlItemType.subsidiary)),
              ],
            });
          } catch (e) {
            sentryUtils.sendError(e);
          } finally {
            this.setState({ isResultsLoading: false });
          }
        });
      }

      this.timer = null;
    }, REQUEST_DELAY_MS);
  };

  getNeighbors = (): [SearchEntity | null, SearchEntity | null] => {
    const { initialSearch } = this.props;
    const { cachedEntities } = this.state;
    let next: SearchEntity | null = null;
    let prev: SearchEntity | null = null;

    if (initialSearch) {
      cachedEntities.forEach((e, i) => {
        /* eslint eqeqeq: 0 */
        if (e.uuid === initialSearch.uuid) {
          next = i + 1 < cachedEntities.length ? cachedEntities[i + 1] : null;
          prev = i - 1 < 0 ? null : cachedEntities[i - 1];
        }
      });
    } else {
      // All tems, departments etc
      // temporary NOP
    }

    return [prev, next];
  };

  onChange = (searchValue: string) => {
    const searchResults = searchValue.length < MIN_SEARCH_LENGTH ? [] : this.state.searchResults;

    this.setState({ searchValue, searchResults }, this.search);
  };

  onClose = () => {
    this.setState({ isOpen: false, searchResults: [] });
  };

  onSelect = (entity: SearchEntity) => {
    const { onSearchResultSelect } = this.props;

    this.onClose();
    onSearchResultSelect(entity);
  };

  changePosition = (cursorIndex: number) => {
    this.onSelect(this.state.cachedEntities[cursorIndex]);
    const currentLimit = this.state.cachedEntities.length + paginationLimit;
    if (!this.state.isCacheLoading && this.state.total > currentLimit && cursorIndex + 10 > currentLimit) {
      void this.getEmployees(currentLimit);
    }
  };

  render() {
    const { excludeAllGroupsLevel, initialSearch } = this.props;
    const { searchValue, isOpen, searchResults, isResultsLoading, isCacheLoading, cachedEntities } = this.state;
    const [prev, next] = this.getNeighbors();
    const initialIndex = (initialSearch?.uuid && cachedEntities.findIndex((e) => e.uuid === initialSearch?.uuid)) || 0;

    const showGroupsDropdown = !excludeAllGroupsLevel && !searchResults.length;

    return (
      <ClickOutside<ClickOutsideType> onClickOutside={this.onClose} className="group-search-click-outside">
        <SearchWrapper>
          <SearchInput
            value={searchValue}
            onChange={this.onChange}
            onClick={() => this.setState({ isOpen: true })}
            withNavigation
          />

          {initialSearch?.type === SearchControlItemType.employee ? (
            <SearchNavClicker
              total={this.state.total}
              onClick={this.changePosition}
              initialIndex={initialIndex}
              isLoading={isCacheLoading}
            />
          ) : (
            <SearchNav<SearchEntity>
              prev={prev}
              next={next}
              onClick={this.onSelect}
              isLoading={isResultsLoading || isCacheLoading}
            />
          )}

          {showGroupsDropdown && isOpen && (
            <GroupsDropdown
              onClick={(searchGroups) => this.onSelect({ id: null, name: null, uuid: null, type: searchGroups })}
            />
          )}
          {isOpen && searchValue.length >= MIN_SEARCH_LENGTH && !!searchResults.length && (
            <SearchResultsDropdown onClick={this.onSelect} searchResults={searchResults} searchValue={searchValue} />
          )}
        </SearchWrapper>
      </ClickOutside>
    );
  }
}

export default GroupsSearch;
