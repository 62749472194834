import { Component, createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GoogleMap } from "react-google-maps";
import { Formik, Form } from "formik";
import styled from "styled-components";
import { Range as RangeType } from "react-input-range";
import FormikField from "components/UI/FormikHelpers/Field";
import CheckboxControl from "components/UI/CheckboxControl";
import CheckboxControlNew from "components/UI/NewCheckbox";
import StyledButton, { ButtonState } from "components/controls/StyledButton";
import ConfirmButton from "components/UI/Button/Confirm";
import SearchControl, { SearchControlOnChangeData } from "components/UI/SearchControlNew";
import GoogleMapPreview from "components/UI/GoogleMap/Preview";
import LocationSearchInput, { Address } from "components/LocationSearchInput";
import Range from "components/UI/Range";
import Select from "components/UI/FormSelect";
import FieldWrapper from "components/UI/FieldWrapper";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { TranslationNamespaces } from "types/translationNamespaces";
import { Location, LocationValidation, LocationType, LocationClient } from "types/models/location";
import { NotificationType } from "types/common";
import { PermissionRoleName, PermissionSectionName } from "types/models/permissions";
import { latLngToGPS } from "utils/gmapsHelpers";
import BEM from "utils/BEM";
import sentryUtils from "utils/sentryUtils";
import { addLocation, editLocation } from "utils/apiHelpers";
import "./Form.scss";
import { translateEmployeeTerm } from "utils/translationHelpers";

const b = BEM.b("locations-edit-form");
const RowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-top: 32px;
  margin-bottom: 4px;
`;

interface LocationsEditFormProps extends WithTranslation {
  onYes: () => void;
  setNotification: (notification: string, notificationType: NotificationType) => void;
  /** edit mode */
  location?: Location | null;
  /** create mode */
  onCancel?: () => void;
  /** create mode */
  locationsTotalCount?: number;
  /** job sites */
  clients: LocationClient[];
  isJobSites: boolean;
  suggestedCode: number | null;
}

interface LocationsEditFormState {
  showMapCircle: boolean;
  confirmOnSave: boolean;
  confirmationPopupVisible: boolean;
}

class LocationsEditForm extends Component<LocationsEditFormProps, LocationsEditFormState> {
  mapRef = createRef<GoogleMap>();
  constructor(props: LocationsEditFormProps) {
    super(props);

    this.state = {
      showMapCircle: true,
      confirmOnSave: false,
      confirmationPopupVisible: false,
    };
  }

  toggleConfirmationPopup = (confirmationPopupVisible: boolean) => {
    this.setState({ confirmationPopupVisible });
  };

  toggleAskForConfirmation = (confirmOnSave: boolean, callback?: () => void) => {
    this.setState({ confirmOnSave });

    if (callback) {
      callback();
    }
  };

  /** edit mode */
  updateLocation = async (locationId: number, data: unknown) => {
    const { onYes, setNotification, t } = this.props;

    try {
      await editLocation(locationId, {
        body: { location: data },
      });

      setNotification(t("Updates in the location saved successfully."), NotificationType.success);
    } catch (error) {
      const err = error as any;
      setNotification(err?.data ? t(err.data) : t("Failed to update the location"), NotificationType.error);
    } finally {
      onYes();
    }
  };

  /** create mode */
  addLocation = async (data: unknown) => {
    const { setNotification, t, onYes } = this.props;

    try {
      await addLocation({ body: { location: data } });
      setNotification(t("Location has been created successfully."), NotificationType.success);
      onYes();
    } catch (error) {
      const errors: Record<string, string> = {};
      const [key, ...message] = (error as string).split(" ");

      // validation errors
      if (["code", "name"].includes(key)) {
        errors[key] = t(message.join(" ").trim().replace(";", ""));
        throw errors;
      } else {
        setNotification(t("Failed to create location"), NotificationType.error);
        onYes();
      }
    }
  };

  render() {
    const { t, location, onCancel, clients, isJobSites, locationsTotalCount, suggestedCode } = this.props;
    const { showMapCircle, confirmOnSave, confirmationPopupVisible } = this.state;
    const isEditMode = !!location;

    const currentLocation: Partial<Location> & {
      verification_methods: LocationValidation[];
      location_type: LocationType;
    } = location || {
      verification_methods: [],
      location_type: isJobSites ? LocationType.jobSite : LocationType.workplace,
    };

    return (
      <Formik
        validateOnBlur={false}
        key={isEditMode ? `edit-${currentLocation.id}` : "new"}
        initialValues={{
          code: isEditMode ? currentLocation.code : suggestedCode || locationsTotalCount,
          name: currentLocation.name || "",
          location_type: currentLocation.location_type,
          manager: currentLocation.location_type !== LocationType.homeOffice ? currentLocation.manager : null,
          managerName:
            currentLocation.location_type !== LocationType.homeOffice && currentLocation.manager
              ? currentLocation.manager.name
              : "",
          homeOfficeChecked:
            currentLocation.location_type === LocationType.homeOffice && !!currentLocation.home_office_manager,
          homeOfficeOwner:
            currentLocation.location_type === LocationType.homeOffice && currentLocation.home_office_manager
              ? currentLocation.home_office_manager.uuid
              : "",
          homeOfficeOwnerName:
            currentLocation.location_type === LocationType.homeOffice && currentLocation.home_office_manager
              ? currentLocation.home_office_manager.name
              : "",
          address_attributes: {
            formatted: currentLocation.formatted_address || "",
            lat: currentLocation.lat,
            lng: currentLocation.lng,
            country: currentLocation.country,
          },
          gps_verification_enabled: currentLocation.verification_methods.includes(LocationValidation.gps),
          radius: currentLocation.radius || 50,
          client_id: currentLocation.client_id || null,
        }}
        validate={(values) => {
          const errors: Record<string, string> = {};

          if (!values.code) {
            errors.code = t("Required");
          }

          if (!values.name) {
            errors.name = t("Can't be blank");
          }

          if (values.location_type === LocationType.homeOffice && !values.homeOfficeOwner) {
            errors.homeOfficeOwner = t("Please select home office owner");
          }

          if (!values.address_attributes.lat) {
            errors.address_attributes = t("Please select addres from list");
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (confirmOnSave) {
            this.toggleConfirmationPopup(true);
            return;
          }

          const { manager, managerName, homeOfficeOwner, homeOfficeOwnerName, homeOfficeChecked, ...locationValues } =
            values;

          const data = {
            ...locationValues,
            location_type: locationValues.location_type,
            home_office_manager_uuid: homeOfficeOwner || null,
            manager_id: manager || null,
          };

          setSubmitting(true);

          if (isEditMode) {
            try {
              void this.updateLocation(location.id, data);
            } catch (error) {
              sentryUtils.sendError(error);
            }
          } else {
            try {
              await this.addLocation(data);
            } catch (errors) {
              // validation errors only
              setErrors(errors as Record<"code" | "name", string>);
            }
          }

          setSubmitting(false);
        }}
        render={({ values, errors, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form className={b()}>
            <div className={b("fieldset-id-name")}>
              <FormikField name="code" title={t("ID")} placeholder={t("ID")} errors={errors} />
              <FormikField name="name" title={t("Name")} placeholder={t("Name")} errors={errors} />
            </div>

            {isJobSites && (
              <label className={b("form-field-wrapper")}>
                <span className={b("form-label")}>{t("Client")}</span>
                <Select
                  options={(clients || []).map((i) => ({
                    value: i.uuid,
                    label: i.name,
                  }))}
                  disabled={!clients?.length}
                  modifiers={["field"]}
                  value={values.client_id}
                  onChange={(val) => setFieldValue("client_id", val)}
                />
              </label>
            )}

            <label className={b("form-field-wrapper")}>
              <span className={b("form-label")}>{t("Address")}</span>
              <LocationSearchInput
                address={values.address_attributes}
                isValid={!errors.address_attributes}
                onChange={(val: Address) => {
                  setFieldValue("address_attributes", val);

                  if (this.mapRef?.current && values.gps_verification_enabled) {
                    this.mapRef.current.panTo({
                      lat: val.lat as number,
                      lng: val.lng as number,
                    });
                  }
                }}
              />
              {errors.address_attributes && (
                <div className={b("error-message")}>{errors.address_attributes as string}</div>
              )}
            </label>

            {isEditMode && isJobSites && (
              <div className={b("form-field-wrapper")}>
                <CheckboxControlNew
                  checked={values.homeOfficeChecked}
                  label={t("Home Office - Specific employee")}
                  onChange={(checked) => {
                    setFieldValue("homeOfficeChecked", checked, false);
                    if (!checked) {
                      setFieldValue("location_type", LocationType.workplace, false);
                      setFieldValue("homeOfficeOwner", null, false);
                      setFieldValue("homeOfficeOwnerName", "", false);
                    } else {
                      setFieldValue("location_type", LocationType.homeOffice, false);
                      setFieldValue("manager", null, false);
                      setFieldValue("managerName", "", false);
                    }
                  }}
                />
                {values.homeOfficeChecked && (
                  <>
                    <FieldWrapper className="home-office-owner" fieldName={t("Select a employee")} width="100%">
                      <SearchControl
                        onChange={(searchObj) => {
                          setFieldValue("homeOfficeOwner", (searchObj as SearchControlOnChangeData).uuid, false);
                          setFieldValue("homeOfficeOwnerName", (searchObj as SearchControlOnChangeData).label, false);
                        }}
                        value={values.homeOfficeOwnerName}
                        onClear={() => {
                          setFieldValue("homeOfficeOwner", null, false);
                          setFieldValue("homeOfficeOwnerName", "", false);
                        }}
                        placeholder={translateEmployeeTerm(
                          t,
                          TranslationNamespaces.common,
                          "custom-search-employees",
                          `${TranslationNamespaces.common}|Search Employees`,
                        )}
                        permissionSection={PermissionSectionName.locations}
                      />
                    </FieldWrapper>
                    {errors.homeOfficeOwner && <div className={b("error-message")}>{errors.homeOfficeOwner}</div>}
                  </>
                )}
              </div>
            )}
            {!isEditMode && (
              <div className={b("gps-switch")}>
                <span className={b("annual-switch-label", { active: false })}>{t("GPS Validation")}</span>
                <CheckboxControl
                  disabled={!values.address_attributes.lat}
                  checked={values.gps_verification_enabled}
                  onChange={(val: boolean) => setFieldValue("gps_verification_enabled", val)}
                />
              </div>
            )}
            {(values.gps_verification_enabled || !!location) && values.address_attributes.formatted && (
              <div>
                <GoogleMapPreview
                  mapRef={this.mapRef}
                  lat={values.address_attributes.lat}
                  lng={values.address_attributes.lng}
                  radius={values.radius}
                  showCircle={showMapCircle}
                  onMarkerDragStart={() => {
                    this.setState({ showMapCircle: false });
                  }}
                  onMarkerDragEnd={({ latLng }) => {
                    const lat = latLng.lat();
                    const lng = latLng.lng();

                    void new window.google.maps.Geocoder().geocode({ location: { lat, lng } }, (results) => {
                      const country = results && results.find((r) => r.types.includes("country"));

                      setFieldValue("address_attributes", {
                        formatted: latLngToGPS({ lat, lng }),
                        lat,
                        lng,
                        country: country?.formatted_address ?? "",
                      });

                      this.setState({ showMapCircle: true });
                    });
                  }}
                />
                {!isEditMode && (
                  <div className={b("radius-wrapper")}>
                    <div className={b("radius-label")}>
                      <label>{t("Select a range")}</label>
                      <span>{values.radius} m</span>
                    </div>
                    <Range
                      minValue={10}
                      maxValue={2000}
                      step={1}
                      value={values.radius}
                      onChange={(val: number | RangeType) => setFieldValue("radius", val)}
                    />
                    <div className={b("radius-hint")}>
                      <span>10 {t("meters")}</span>
                      <span>2000 {t("meters")}</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {isEditMode && (
              <div className={b("timezone-hint")}>{`${t("Time Zone")}: ${currentLocation.time_zone}`}</div>
            )}
            {isEditMode && values.location_type !== LocationType.homeOffice && (
              <RowTitle>{t("Location Manager")}</RowTitle>
            )}
            {isEditMode && values.location_type !== LocationType.homeOffice && (
              <FieldWrapper
                className="location-manager"
                fieldName={translateEmployeeTerm(
                  t,
                  TranslationNamespaces.common,
                  "custom-employee",
                  `${TranslationNamespaces.common}|Employee`,
                )}
                width="100%"
              >
                <SearchControl
                  onChange={(searchObj) => {
                    setFieldValue("manager", (searchObj as SearchControlOnChangeData).uuid);
                    setFieldValue("managerName", (searchObj as SearchControlOnChangeData).label);

                    this.toggleAskForConfirmation(
                      !(searchObj as SearchControlOnChangeData).employee?.permissionRoles.some(
                        (pr) => pr.name === PermissionRoleName.locationManager,
                      ),
                    );
                  }}
                  value={values.managerName}
                  skipEmployer
                  onlyActive
                  onClear={() => {
                    setFieldValue("manager", null);
                    setFieldValue("managerName", "");

                    this.toggleAskForConfirmation(false);
                  }}
                  placeholder={translateEmployeeTerm(
                    t,
                    TranslationNamespaces.common,
                    "custom-search-employees",
                    `${TranslationNamespaces.common}|Search Employees`,
                  )}
                  permissionSection={PermissionSectionName.locations}
                />
              </FieldWrapper>
            )}

            <div className={b("action-buttons", { "edit-mode": isEditMode })}>
              {typeof onCancel === "function" && (
                <StyledButton
                  state={ButtonState.secondary}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                  }}
                  value={`${t("Cancel")}`}
                />
              )}
              <StyledButton
                type="submit"
                style={isEditMode ? { width: 136 } : {}}
                disabled={isSubmitting}
                loading={isSubmitting}
                value={isEditMode ? t("Save") : t("Confirm")}
              />
            </div>

            {isEditMode && (
              <div className={b("action-disable")}>
                {currentLocation.active ? (
                  <ConfirmButton
                    modifier="text"
                    onConfirm={() =>
                      this.updateLocation(location.id, {
                        active: false,
                      })
                    }
                    title={t("Disable location")}
                    description={`${t("Are you sure to disable")} ${currentLocation.name}?`}
                  >
                    {t("Disable Location")}
                  </ConfirmButton>
                ) : null}
                {!currentLocation.active ? (
                  <ConfirmButton
                    modifier="text"
                    onConfirm={() =>
                      this.updateLocation(location.id, {
                        active: true,
                      })
                    }
                    title={t("Enable location")}
                    description={`${t("Are you sure to enable")} ${currentLocation.name}?`}
                  >
                    {t("Enable Location")}
                  </ConfirmButton>
                ) : null}
                <p>
                  {translateEmployeeTerm(
                    t,
                    TranslationNamespaces.locations,
                    "custom-disable-location",
                    "Disabling the employee will no longer be able to access the app",
                  )}
                </p>
              </div>
            )}
            <ModalDialog isOpen={confirmationPopupVisible} onClose={() => this.toggleConfirmationPopup(false)}>
              <Lightbox
                title={t("location-manager-confirmation")}
                text={t("location-manager-confirmation-text")}
                buttonYesTitle={t(`${TranslationNamespaces.common}|Yes`)}
                buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
                onClose={() => {
                  this.toggleConfirmationPopup(false);
                }}
                onYes={() => {
                  this.toggleConfirmationPopup(false);
                  this.toggleAskForConfirmation(false, handleSubmit);
                }}
              />
            </ModalDialog>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation(TranslationNamespaces.locations)(LocationsEditForm);
