import { ChangeEvent, Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  getPayrollLockReport,
  getLockById,
  unlockPayrollLockForEmployees,
  lockPayrollLockForEmployees,
} from "utils/apiHelpers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FullPage from "components/Layout/FullPage";
import User from "components/User";
import TablePage from "components/TablePage";
import NotificationRow from "components/NotificationRow";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import {
  minsToHrsMins,
  getTitle,
  strIncludesCheck,
  getEmployeeTaxPayerType,
  getEmployeeTaxIdTranslation,
} from "utils/common";
import { PageWrapper, TableButtons, TableButton } from "components/styled/Page";
import { getSelectedColumns, iCellInfo } from "utils/tableHelpers";
import styled from "styled-components";
import moment from "moment";
import { EmployeeTaxPayerTypes, NotificationType } from "types/common";
import { ColumnAlign, iColumn } from "components/TableCommon";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import { PayrollLock, PayrollLockReport, PayrollLockStatus } from "types/models/payrollLocks";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import CellCheckbox from "components/controls/CellCheckbox";
import CellCheckboxAll from "components/controls/CellCheckboxAll";
import { TranslationNamespaces } from "types/translationNamespaces";
import GlobalContext from "../../context/global-context";
import { LockAction, SelectedLockReportEmployee } from "./types";
import LockReportActionsBar from "./LockReportActionsBar";

const title = "View preparation";
const metaTitle = title;

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

const PrepName = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.mainText};
  line-height: 27px;
  margin-bottom: 10px;
`;

const PrepDates = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.secondary};
  text-align: left;
`;

const TableWrapper = styled.div`
  .lock-report-row {
    position: relative;
  }
`;

interface LockReportState {
  items: PayrollLockReport[];
  lock: PayrollLock | null;
  isFetching: boolean;
  notification: string | null;
  notificationType: NotificationType;
  searchValue: string;
  selectedColumns: string;
  confirmationPopupVisible: boolean;
  selectedEmployees: SelectedLockReportEmployee[];
  selectAllChecked: boolean;
}

interface LockReportProps extends WithTranslation, RouteComponentProps {
  lockId: string;
}

class LockReport extends Component<LockReportProps, LockReportState> {
  static contextType = GlobalContext;

  constructor(props: LockReportProps) {
    super(props);
    const { t } = props;

    this.state = {
      items: [],
      lock: null,
      notification: null,
      notificationType: NotificationType.success,
      searchValue: "",
      isFetching: false,
      selectedColumns: getSelectedColumns(
        "fullName,workedDays,supervisorFullName,totalPlannedMinutes,totalWorkedMinutes,totalExtraHoursMinutes,totalHoursBankMinutes,totalNightMinutes,missedDays,lockStatus",
        "LockReport",
      ),
      confirmationPopupVisible: false,
      selectedEmployees: [],
      selectAllChecked: false,
    };

    document.title = getTitle(t(metaTitle));
  }

  componentDidMount(): void {
    void this.getState();
  }

  getState = async (): Promise<void> => {
    if (!this.state.isFetching && this.props.lockId) {
      this.setState({ isFetching: true });

      const company = await this.context.getCompany();
      const promises = [
        getLockById({ companyUuid: company.uuid, payrollLockUuid: this.props.lockId }),
        getPayrollLockReport({
          companyUuid: company.uuid,
          payrollLockUuid: this.props.lockId,
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
          requestedBy: window.global_store.profile.uuid,
        }),
      ];

      void Promise.all(promises).then((resp) => {
        this.setState({
          lock: resp[0].content,
          items: resp[1].content || [],
          isFetching: false,
        });
      });
    }
  };

  getLockAction = (lockStatus: PayrollLockStatus): LockAction =>
    lockStatus === PayrollLockStatus.locked ? LockAction.unlock : LockAction.lock;

  onRowCheck = (checked: boolean, userProfileUuid: string, row: iCellInfo<PayrollLockReport>): void => {
    const { selectedEmployees } = this.state;
    let newSelectedEmployees;

    if (checked) {
      newSelectedEmployees = [
        ...selectedEmployees,
        { userProfileUuid, action: this.getLockAction(row.original.lockStatus) },
      ];
    } else {
      newSelectedEmployees = selectedEmployees.filter((se) => se.userProfileUuid !== userProfileUuid);
    }

    this.setState({ selectedEmployees: newSelectedEmployees });
  };

  onSelectAllCheck = (checked: boolean): void => {
    const { items } = this.state;

    this.setState({
      selectAllChecked: checked,
      selectedEmployees: checked
        ? items.map(({ userProfileUuid, lockStatus }) => ({
            userProfileUuid,
            action: this.getLockAction(lockStatus),
          }))
        : [],
    });
  };
  toggleLock = async (action?: LockAction): Promise<void> => {
    const { selectedEmployees } = this.state;
    const { t, lockId } = this.props;
    let notification: null;

    // multiple or single employee action
    const actionToPerform: LockAction = action || selectedEmployees[0].action;

    const params = {
      companyUuid: window.global_store.company.uuid,
      payrollLockUuid: lockId,
      userProfileUuids: selectedEmployees.filter((e) => e.action === actionToPerform).map((e) => e.userProfileUuid),
      updatedBy: window.global_store.profile.uuid,
    };

    try {
      if (actionToPerform === LockAction.unlock) {
        await unlockPayrollLockForEmployees(params);
        notification = t("You unlocked period");
      } else {
        await lockPayrollLockForEmployees(params);
        notification = t("You locked period");
      }

      this.setState(
        {
          confirmationPopupVisible: false,
          selectedEmployees: [],
          selectAllChecked: false,
          notification,
          notificationType: NotificationType.success,
        },
        this.getState,
      );

      void this.getState();
    } catch (e) {
      const err: any = e;
      let errorMessage = t("common|Something went wrong");

      if (err.message) {
        errorMessage = t(err.message);
      } else if (err.originalRequest?.errors?.length && err.originalRequest.errors[0].message) {
        errorMessage = t(err.originalRequest.errors[0].message);
      }

      this.setState({
        confirmationPopupVisible: false,
        selectedEmployees: [],
        selectAllChecked: false,
        notification: errorMessage,
        notificationType: NotificationType.error,
      });
    }
  };

  onSearch = (ev: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchValue: ev.target.value });
  };

  getTableColumns = (): iColumn<PayrollLockReport>[] => {
    const { t } = this.props;
    const { selectedEmployees, items, selectAllChecked } = this.state;

    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns = [
      {
        accessor: "userProfileUuid",
        headerClassName: "checkbox-header",
        Cell: (row: iCellInfo<PayrollLockReport>): JSX.Element => (
          <CellCheckbox<PayrollLockReport>
            row={row}
            checked={row && selectedEmployees.some((se) => se.userProfileUuid === row.value)}
            onChange={this.onRowCheck}
            entityIdKey=""
          />
        ),
        Header: (
          <CellCheckboxAll
            entities={items}
            checked={selectAllChecked}
            onChange={this.onSelectAllCheck}
            entityIdKey="userProfileUuid"
          />
        ),
        minWidth: 50,
        style: { fontWeight: "500" },
        align: "center" as ColumnAlign,
        locked: true,
      },
      {
        label: t("common|Employee"),
        accessor: "fullName",
        minWidth: 160,
        Cell: (row: iCellInfo<PayrollLockReport>): JSX.Element => (
          <User
            user={{
              fullName: row.value,
              avatarId: row.original.avatarId,
              position: row.original.department,
            }}
          />
        ),
        style: { lineHeight: "36px" },
      },
      {
        label: employeeTaxIdLabel,
        accessor: "cpf",
      },
      {
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
        accessor: "matricula",
      },
      {
        label: t("Department"),
        accessor: "department",
      },
      {
        label: t("Worked Days"),
        accessor: "workedDays",
      },
      {
        label: t("common|Supervisor"),
        accessor: "supervisorFullName",
      },
      {
        label: t("Planned Hours"),
        accessor: "totalPlannedMinutes",
        Cell: (row: iCellInfo<PayrollLockReport>): string => (row.value ? minsToHrsMins(row.value) : "00:00"),
      },
      {
        label: t("Worked Hours"),
        accessor: "totalWorkedMinutes",
        Cell: (row: iCellInfo<PayrollLockReport>): string => (row.value ? minsToHrsMins(row.value) : "00:00"),
      },
      {
        label: t("Extra hours"),
        accessor: "totalExtraHoursMinutes",
        Cell: (row: iCellInfo<PayrollLockReport>): string => (row.value ? minsToHrsMins(row.value) : "00:00"),
      },
      {
        label: t("Hours Bank Total"),
        accessor: "totalHoursBankMinutes",
        Cell: (row: iCellInfo<PayrollLockReport>): string => (row.value ? minsToHrsMins(row.value) : "00:00"),
      },
      {
        label: t("Nightshift"),
        accessor: "totalNightMinutes",
        Cell: (row: iCellInfo<PayrollLockReport>): string => (row.value ? minsToHrsMins(row.value) : "00:00"),
      },
      {
        label: t("Absence"),
        accessor: "missedDays",
      },
      {
        label: t("common|Status"),
        accessor: "lockStatus",
        locked: true,
        minWidth: 75,
        align: "center" as ColumnAlign,
        Cell: (row: iCellInfo<PayrollLockReport>): JSX.Element => {
          const statusBadgeType: StatusBadgeStatuses = {
            [PayrollLockStatus.locked]: StatusBadgeStatuses.success,
            [PayrollLockStatus.unlocked]: StatusBadgeStatuses.default,
          }[row.value as Exclude<PayrollLockStatus, PayrollLockStatus.partial>];

          return (
            <div>
              <StatusBadge value={t(row.value)} type={statusBadgeType} />
              <TableButtons className="buttons">
                <TableButton
                  onClick={(ev): void => {
                    ev.stopPropagation();

                    this.setState({
                      confirmationPopupVisible: true,
                      selectedEmployees: [
                        {
                          userProfileUuid: row.original.userProfileUuid,
                          action: this.getLockAction(row.original.lockStatus),
                        },
                      ],
                    });
                  }}
                >
                  {row.original.lockStatus === PayrollLockStatus.locked ? t("Unlock") : t("Lock")}
                </TableButton>
              </TableButtons>
            </div>
          );
        },
      },
    ];

    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_LockReport", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const {
      items,
      selectedColumns,
      lock,
      searchValue,
      isFetching,
      notification,
      notificationType,
      confirmationPopupVisible,
      selectedEmployees,
    } = this.state;
    const { t, history } = this.props;
    const noItemsTitle = "No rows";

    const filteredItems = items.filter((s) => {
      const filter = strIncludesCheck(s.fullName, searchValue);
      return filter;
    });

    return (
      <FullPage
        title={t(title)}
        backButtonOnclick={() => history.push("/payroll/lock-periods/")}
        backButtonTitle={t("Locking Groups")}
      >
        <PageWrapper>
          <DetailsRow>
            <div>
              {lock && lock.title ? <PrepName>{lock.title}</PrepName> : null}
              {lock ? (
                <PrepDates>
                  From {moment(lock.startDate).format("DD/MM/YYYY")} to {moment(lock.endDate).format("DD/MM/YYYY")}
                </PrepDates>
              ) : null}
            </div>
          </DetailsRow>

          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type={notificationType} message={notification} />
          )}

          <TableWrapper>
            <TablePage<PayrollLockReport>
              rows={filteredItems}
              selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
              onColumnsChange={this.onColumnsChange}
              filters={
                <SearchInput
                  modifiers={["filter"]}
                  onChange={this.onSearch}
                  placeholder={t("Search")}
                  value={searchValue}
                />
              }
              columnSelectorOnFiltersRow
              columns={this.getTableColumns()}
              className="groups-table"
              loading={isFetching}
              noContentComponent={<NoContent>{t(noItemsTitle)}</NoContent>}
              getTrProps={(): { className: string } => ({
                className: "lock-report-row",
              })}
            />
          </TableWrapper>

          {selectedEmployees.length > 0 && (
            <LockReportActionsBar
              selectedEmployees={selectedEmployees}
              onUncheckAll={() => this.onSelectAllCheck(false)}
              onYes={this.toggleLock}
            />
          )}

          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={(): void =>
              this.setState({ confirmationPopupVisible: false, selectedEmployees: [], selectAllChecked: false })
            }
          >
            <Lightbox
              title={t("Are you sure?")}
              text={t("Are you sure you want to {{actionItem}} this period?", {
                actionItem:
                  selectedEmployees[0]?.action === LockAction.lock
                    ? t("Lock").toLowerCase()
                    : t("Unlock").toLowerCase(),
              })}
              buttonYesTitle={selectedEmployees[0]?.action === LockAction.lock ? t("Lock") : t("Unlock")}
              buttonCancelTitle={t("Cancel")}
              onClose={(): void => {
                this.setState({ selectedEmployees: [], confirmationPopupVisible: false, selectAllChecked: false });
              }}
              onYes={this.toggleLock}
            />
          </ModalDialog>
        </PageWrapper>
      </FullPage>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.payment)(LockReport));
