import { sendRequest } from "utils/apiHelpers";
import CONFIG from "config";
import { Project, Service, Task } from "types/models/projects";
import { APIResponse } from "utils/api/common";

import { EmployeeListUserProfile } from "types/models/userProfile";
import {
  CompanyTimesheetSettings,
  CreateActivitiesRequestData,
  GetActivitiesListRequestData,
  GetActivitiesListResponseData,
  CreateServiceRequestData,
  CreateTaskRequestData,
  ProjectAssigneesRequestData,
  BulkAssignEmployeesToProjectRequestData,
  GetCompanyRulesForProjectsRequestData,
  GetCompanyRulesForProjectsResponseData,
  GetServiceProviderSettingsRequestData,
  GetServiceProviderSettingsResponseData,
  ProjectAssigneesResponseData,
  TaskUpdateTaskAssigneesRequestData,
  RemoveAssigneeFromProjectRequestData,
  GetTasksListForUserProfileRequestData,
  GetTasksListForUserProfileResponseData,
  TaskUpdateRequestData,
  SearchEmployeesAndServicesRequestData,
  SearchEmployeesAndServicesResponseData,
} from "./projectsApiTypes";

type TODORequestData = any;
type TODOResponseData = APIResponse<unknown>;

export function getProjectsList(data: { companyUuid: string; status: string }): APIResponse<{ content: Project[] }> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects?status=${data.status || ""}`,
    CONFIG.client_admin_api,
  );
}

export function getLocksList(data: TODORequestData): TODOResponseData {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/holidays/groups`, CONFIG.client_admin_api);
}

export function createProject(data: TODORequestData): TODOResponseData {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/projects`, CONFIG.client_admin_api);
}

export function updateProject(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}`,
    CONFIG.client_admin_api,
  );
}

export function archiveProject(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/archive`,
    CONFIG.client_admin_api,
  );
}

export function deleteProject(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getProject(data: { companyUuid: string; projectUuid: string }): APIResponse<{ content: Project }> {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/projects/${data.projectUuid}`, CONFIG.client_admin_api);
}

export function getServicesList(data: { companyUuid: string; status?: string }): APIResponse<{ content: Service[] }> {
  const params = data?.status ? `?status=${data.status}` : "";

  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/services${params || ""}`,
    CONFIG.client_admin_api,
  );
}

export function createService(data: CreateServiceRequestData): APIResponse<{ content: string }> {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/projects/services`, CONFIG.client_admin_api);
}

export function updateService(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/projects/services/${data.serviceUuid}`,
    CONFIG.client_admin_api,
  );
}

export function deactivateService(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/services/${data.serviceUuid}/archive`,
    CONFIG.client_admin_api,
  );
}

export function getTasksList(data: {
  companyUuid: string;
  projectUuid: string;
}): APIResponse<{ content: Task[]; metadata: { userProfilesByUuid: Record<string, EmployeeListUserProfile> } }> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/tasks`,
    CONFIG.client_admin_api,
  );
}

export function getTasksListForUserProfile(
  data: GetTasksListForUserProfileRequestData,
): APIResponse<GetTasksListForUserProfileResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/tasks/user_profiles/${data.userProfileUuid}?requested_by=${data.requestedBy}${
      data.locationUuid ? `&location_uuid=${data.locationUuid}` : ""
    }`,
    CONFIG.client_admin_api,
  );
}

export function createTask(data: CreateTaskRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/tasks`,
    CONFIG.client_admin_api,
  );
}

export function updateTask(data: TaskUpdateRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/tasks/${data.taskUuid}`,
    CONFIG.client_admin_api,
  );
}

export function updateTaskAssignees(data: TaskUpdateTaskAssigneesRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/tasks/${data.taskUuid}/bulk/assign`,
    CONFIG.client_admin_api,
  );
}

export function deactivateTask(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/tasks/${data.taskUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getServiceProviderSettings(
  data: GetServiceProviderSettingsRequestData,
): APIResponse<GetServiceProviderSettingsResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/service_provider_settings?requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function updateServiceProviderSettings(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/user_profiles/${data.userProfileUuid}/service_provider_settings?requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function createActivities(data: CreateActivitiesRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/activities/bulk`,
    CONFIG.client_admin_api,
  );
}

export function getActivitiesList(data: GetActivitiesListRequestData): APIResponse<GetActivitiesListResponseData> {
  const params = [];

  params.push(`from=${data.from}`);
  params.push(`to=${data.to}`);
  params.push(`requested_by=${data.requestedBy}`);

  if (data.employeeUuid) {
    params.push(`user_profile_uuid=${data.employeeUuid}`);
  }
  if (data.departmentUuid || data.subsidiaryUuid || data.teamUuid) {
    params.push(`group_uuid=${data.departmentUuid || data.subsidiaryUuid || data.teamUuid}`);
    if (data.departmentUuid) {
      params.push("group_type=department");
    }
    if (data.subsidiaryUuid) {
      params.push("group_type=subsidiary");
    }
    if (data.teamUuid) {
      params.push("group_type=team");
    }
  }

  if (data.locationUuid) {
    params.push(`location_uuid=${data.locationUuid}`);
  }
  if (data.taskUuid) {
    params.push(`task_uuid=${data.taskUuid}`);
  }
  if (data.projectUuid) {
    params.push(`project_uuid=${data.projectUuid}`);
  }
  if (data.status) {
    params.push(`status=${data.status}`);
  }
  if (data.clientUuid) {
    params.push(`client_uuid=${data.clientUuid}`);
  }
  if (data.groupUuid) {
    params.push(`group_type=team&group_uuid=${data.groupUuid}`);
  }

  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/activities?${params.join("&")}`,
    CONFIG.client_admin_api,
  );
}

export function getCompanyRulesForProjects(
  data: GetCompanyRulesForProjectsRequestData,
): APIResponse<GetCompanyRulesForProjectsResponseData> {
  return sendRequest({}, "GET", `/companies/${data.companyUuid}/service_provider_settings`, CONFIG.client_admin_api);
}

export function updateCompanyRulesForProjects(data: {
  companyUuid: string;
  body: Partial<CompanyTimesheetSettings>;
}): TODOResponseData {
  return sendRequest(
    { content: data.body },
    "PUT",
    `/companies/${data.companyUuid}/service_provider_settings`,
    CONFIG.client_admin_api,
  );
}

export function approveActivity(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/activities/${data.activityUuid}/approve`,
    CONFIG.client_admin_api,
  );
}

export function declineActivity(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/activities/${data.activityUuid}/decline`,
    CONFIG.client_admin_api,
  );
}

export function bulkChangeActivitiesStatus(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/activities/bulk/${data.status}`,
    CONFIG.client_admin_api,
  );
}

export function removeActivity(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/projects/activities/${data.activityUuid}`,
    CONFIG.client_admin_api,
  );
}

export function updateAtivity(data: TODORequestData): TODOResponseData {
  return sendRequest(
    data.body,
    "PUT",
    `/companies/${data.companyUuid}/projects/activities/${data.activityUuid}`,
    CONFIG.client_admin_api,
  );
}

export function getActivityByUuid(data: TODORequestData): TODOResponseData {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/activities/${data.activityUuid}?requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function getProjectAssignees(data: ProjectAssigneesRequestData): APIResponse<ProjectAssigneesResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/team?requested_by=${data.requestedBy}`,
    CONFIG.client_admin_api,
  );
}

export function bulkAssignEmployeesToProject(data: BulkAssignEmployeesToProjectRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "POST",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/bulk/assign`,
    CONFIG.client_admin_api,
  );
}

export function removeAssigneeFromProject(data: RemoveAssigneeFromProjectRequestData): APIResponse<void> {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/projects/${data.projectUuid}/team`,
    CONFIG.client_admin_api,
  );
}

export function searchEmployeesAndServices(
  data: SearchEmployeesAndServicesRequestData,
): APIResponse<SearchEmployeesAndServicesResponseData> {
  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/projects/team/search?${Object.keys(data.args)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data.args[key])}`)
      .join("&")}`,
    CONFIG.client_admin_api,
  );
}

export { uploadActivityAttachment, deleteActivityAttachment } from "./activityAttachmentsApiUtils";
