/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService } from "services/auth";
import { Injectable } from "services/di";
import { OitchauApi } from "./oitchau-api";

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;

type TakeMethods<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never;
}[keyof T];

type ApiWithAuth = {
  [M in TakeMethods<OitchauApi>]: (...args: DropFirst<Parameters<OitchauApi[M]>>) => ReturnType<OitchauApi[M]>;
};

const defineDynamicClass = (): { new (): ApiWithAuth } => Object as any;

@Injectable()
export class OitchauAuthedApi extends defineDynamicClass() {
  constructor(protected oitchauApi: OitchauApi, protected authService: AuthService) {
    super();
    this.createMethods();
  }

  protected async callOriginalMethod(methodName: keyof ApiWithAuth, ...args: any[]) {
    const authToken = await this.authService.getAuthToken();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.oitchauApi[methodName](authToken, ...args);
  }

  protected createMethods() {
    const ownKeys = Object.getOwnPropertyNames(this.constructor.prototype);
    const keys = Object.getOwnPropertyNames(this.oitchauApi.constructor.prototype);
    for (const key of keys) {
      const doKeysCollide = ownKeys.includes(key);
      if (doKeysCollide) continue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[key] = (...args: any[]) => this.callOriginalMethod(key, ...args);
    }
  }
}
