import { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import * as momentTz from "moment-timezone";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import Pagination from "components/Pagination";
import { getSelectedColumns } from "utils/tableHelpers";
import BEM from "utils/BEM";
import { EmployeeTaxPayerTypes } from "types/common";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import { getEmployeeNameColumn } from "utils/reportsHelpers";
import { minsToHrsMins, getEmployeeTaxPayerType, getEmployeeTaxIdTranslation } from "utils/common";
import { translateEmployeeTerm } from "utils/translationHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import TablePage from "../TablePage";
import ReportsNoContent from "./ReportsNoContent";
import "styles/reports-extra-hour-table.scss";
import { sliceReportIntoChunks } from "./helpers";

const b = BEM.b("reports-extra-hour-table");

class ReportsCheckinAllEmployees extends Component {
  state = {
    checkins: [[]],
    loading: false,
    selectedColumns: getSelectedColumns(
      "employee,cpf,matricula,subsidiary,department,checkin_time,checkout_time,duration,location",
      "ReportsCheckinAllEmployees",
    ),
    page: 1,
    perPage: 500,
    totalRecords: 0,
  };

  componentDidMount() {
    this.getReportData();
  }

  getReportData = async () => {
    const { perPage } = this.state;
    const { startDate, endDate, searchObj, setIsLoading } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      this.setState({ loading: true }, () => setIsLoading(true));

      try {
        const response = await getReport(
          {
            groupUUID: searchObj.uuid || null,
            groupType: searchObj.type || null,
            startDate,
            endDate,
            companyUUID,
            type: "checkins",
          },
          true,
        );

        const data = response.content || [];
        const checkins = sliceReportIntoChunks(data, perPage);

        this.setState({
          checkins: checkins.length ? checkins : [[]],
          totalRecords: data.length,
          error: false,
          loading: false,
        });
      } catch (e) {
        console.log("Error", e);

        this.setState({
          error: this.props.t("Failed to generate report"),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = () => {
    const { t } = this.props;
    const employeeTaxId = getEmployeeTaxPayerType(window.global_store.profile?.company?.country);
    const employeeTaxIdLabel = getEmployeeTaxIdTranslation(employeeTaxId, t);

    const columns = [
      getEmployeeNameColumn(t),
      {
        accessor: "cpf",
        rubyAccessor: "cpf",
        label: employeeTaxIdLabel,
        minWidth: 100,
        align: "right",
      },
      {
        accessor: "matricula",
        rubyAccessor: "matricula",
        label: t(employeeTaxId === EmployeeTaxPayerTypes.tz ? "Employee ID" : "Matricula"),
        minWidth: 100,
      },
      {
        accessor: "subsidiary",
        rubyAccessor: "subsidiary",
        label: t("Subsidiary"),
        minWidth: 100,
      },
      {
        accessor: "department",
        rubyAccessor: "department",
        label: t("Department"),
        minWidth: 100,
      },
      {
        accessor: "startTime",
        rubyAccessor: "checkin_time",
        label: t("Start Time"),
        minWidth: 150,
      },
      {
        accessor: "endTime",
        rubyAccessor: "checkout_time",
        label: t("End Time"),
        minWidth: 150,
      },
      {
        accessor: "duration",
        rubyAccessor: "duration",
        label: t("Duration"),
        align: "right",
      },
      {
        accessor: "location",
        rubyAccessor: "location",
        label: t("Location"),
        minWidth: 150,
      },
    ];

    return columns;
  };

  onColumnsChange = (selectedColumns) => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsCheckinAllEmployees", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  getTime = (time, timeZone) => {
    let formatted = "";

    if (time) {
      if (timeZone) {
        formatted = momentTz.tz(time, timeZone).format("HH:mm ddd DD/MM/YYYY");
      } else {
        formatted = moment(time).format("HH:mm ddd DD/MM/YYYY");
      }
    }
    return formatted;
  };

  onPageLimitChange = (pageLimit) => {
    const { checkins } = this.state;

    this.setState({ page: 1, perPage: pageLimit, checkins: sliceReportIntoChunks(checkins.flat(), pageLimit) });
  };

  render() {
    const { error, checkins, loading, selectedColumns, page, perPage, totalRecords } = this.state;
    const { t, startDate, endDate, searchObj, skipSupervisors, onlyNonZeroLines, showInactiveEmploees, oldReport } =
      this.props;

    if (error) {
      return <div>{error}</div>;
    }

    const data = checkins[page - 1].map((d) => ({
      ...d,
      fullName: d.employee.fullName,
      cpf: d.employee.cpf,
      duration: d.duration ? minsToHrsMins(Math.floor(d.duration / 60)) : "00:00",
      matricula: d.employee.matricula,
      subsidiary: d.employee.subsidiary ? d.employee.subsidiary.name : "",
      department: d.employee.department ? d.employee.department.name : "",
      startTime: this.getTime(d.checkInTime, d.timeZone),
      endTime: this.getTime(d.checkOutTime, d.timeZone),
      location: d.location ? d.location.name : t("Custom location"),
    }));

    const columns = this.getColumns();

    let title = translateEmployeeTerm(
      t,
      TranslationNamespaces.common,
      "custom-all-employees",
      `${TranslationNamespaces.common}|All Employees`,
    );
    if (searchObj && searchObj.type && searchObj.type !== "all" && searchObj.label) {
      title = `${t(searchObj.type)}: ${searchObj.label}`;
    }

    return (
      <div className={b()}>
        <TablePage
          withHeaderTooltip
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <div className={b("title")}>{title}</div>
              <div className={b("title-dates")}>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </div>
            </>
          }
          downloadControl={
            <DownloadControlWithEvents
              placeholder={t("common|Download")}
              onChange={(value) =>
                fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns,
                  searchObj,
                  startDate,
                  endDate,
                  showInactiveEmploees,
                  skipSupervisors,
                  onlyNonZeroLines,
                  oldReport,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "checkins",
                })
              }
            />
          }
          getTrProps={(_, rowInfo) => ({
            className:
              // eslint-disable-next-line no-underscore-dangle
              !oldReport && rowInfo.row._original.status && rowInfo.row._original.status !== "completed"
                ? // eslint-disable-next-line no-underscore-dangle
                  `row-incomplete row-incomplete_${rowInfo.row._original.status}`
                : "",
          })}
          rows={data}
          columns={columns}
          loading={loading}
          interactive={false}
          className="reports-checkin-table"
          noContentComponent={<ReportsNoContent />}
          showPagination
          PaginationComponent={() => (
            <Pagination
              currentPage={page}
              pageNeighbours={2}
              pageLimit={perPage}
              totalRecords={totalRecords}
              onPageChanged={({ currentPage }) => this.setState({ page: currentPage })}
              onPageLimitChange={this.onPageLimitChange}
              pageLimits={[250, 500, 1000]}
            />
          )}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation("reports-page")(ReportsCheckinAllEmployees));
