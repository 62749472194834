import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { translateEmployeeTerm } from "utils/translationHelpers";

const Wrapper = styled.div`
  background-color: var(--colors-surface-50);
  width: 100%;
  position: relative;
  padding-inline-start: 67px;
  padding-inline-end: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    inset-inline-start: 20px;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Crect width='32' height='32' fill='%238093AC' rx='16'/%3E%3Cpath fill='%23fff' d='M16.2 16.146a3.28 3.28 0 0 0 1.133-2.48 3.333 3.333 0 0 0-6.666 0 3.28 3.28 0 0 0 1.133 2.48A5.334 5.334 0 0 0 8.667 21 .667.667 0 1 0 10 21a4 4 0 1 1 8 0 .667.667 0 1 0 1.333 0 5.334 5.334 0 0 0-3.133-4.854Zm-2.2-.48a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm6.493.214A3.332 3.332 0 0 0 18 10.333a.666.666 0 1 0 0 1.333 2 2 0 0 1 2 2 2 2 0 0 1-1 1.727.667.667 0 0 0-.033 1.133l.26.174.086.046A4.667 4.667 0 0 1 21.98 21a.667.667 0 1 0 1.333 0 6 6 0 0 0-2.82-5.12Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const Text = styled.span`
  font-weight: var(--typography-font-weight-default);
  font-size: 15px;
  line-height: 15px;
  color: var(--colors-surface-800);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 25.5px 0;
`;

const AssignProjectTeamRow = ({ onMakeProjectPrivate }: { onMakeProjectPrivate: () => void }) => {
  const { t } = useTranslation(TranslationNamespaces.projects);

  return (
    <Wrapper>
      <Text>
        {translateEmployeeTerm(
          t,
          TranslationNamespaces.projects,
          "custom-assign-project-team-row",
          "assign-project-team-row",
        )}
      </Text>
      <Button
        state={ButtonState.primary}
        onClick={() => onMakeProjectPrivate()}
        value={translateEmployeeTerm(
          t,
          TranslationNamespaces.projects,
          "custom-assign-projects-team",
          "Assign project’s team",
        )}
        style={{ width: "auto" }}
      />
    </Wrapper>
  );
};

export default AssignProjectTeamRow;
