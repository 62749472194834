import axios, { AxiosRequestConfig, CancelToken } from "axios";
import { APIResponse, sendRequest, getHeaders, paramsToUrlParams } from "./common";
import CONFIG from "../../config";
import {
  CreateCustomBreak,
  CreateCustomBreakListResponseData,
  DeleteCustomBreak,
  GetCustomBreakList,
  GetCustomBreakListResponseData,
  GetCustomBreakListWithSchedulesResponseData,
  GetScheduleImportsResponseData,
  GetScheduleTemplatesResponseData,
  ImportScheduleRequestData,
  ImportScheduleResponseData,
} from "./types";
import { Schedule } from "../../types/models/schedule";

export function getScheduleTemplates(): APIResponse<GetScheduleTemplatesResponseData> {
  const locale = window.global_store.profile?.company?.country || "BR";

  return sendRequest<Record<string, never>, GetScheduleTemplatesResponseData>(
    {},
    "GET",
    `/schedules/templates?locale=${locale}`,
    CONFIG.client_admin_api,
  );
}

export function getScheduleImports(): APIResponse<GetScheduleImportsResponseData> {
  return sendRequest<Record<string, never>, GetScheduleImportsResponseData>({}, "GET", "/file_imports", CONFIG.api);
}

export function getSchedulesForUserProfile(
  companyUuid: string,
  userProfileUuid: string,
  params: any = {},
): APIResponse<{ content: { Schedule: Schedule; ScheduleUuid: string; startDate: string }[] }> {
  const query = paramsToUrlParams(params);
  return sendRequest<
    Record<string, never>,
    { content: { Schedule: Schedule; ScheduleUuid: string; startDate: string }[] }
  >({}, "GET", `/companies/${companyUuid}/schedules/user_profiles/${userProfileUuid}${query}`, CONFIG.client_admin_api);
}

export function importSchedule(
  data: ImportScheduleRequestData,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"],
  cancelToken: CancelToken,
): APIResponse<ImportScheduleResponseData> {
  const formData = new FormData();
  formData.append("file", data.file, data.file.name);

  const headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  return axios.post(`${CONFIG.protocol}${CONFIG.api}/file_imports`, formData, {
    headers,
    onUploadProgress,
    cancelToken,
  });
}

export function getCustomBreaksListWithSchedules(
  data: GetCustomBreakList,
): APIResponse<GetCustomBreakListWithSchedulesResponseData> {
  const params = [];
  if (data.page) {
    params.push(`page=${data.page}`);
  }
  if (data.perPage) {
    params.push(`per_page=${data.perPage}`);
  }
  if (data.requestedBy) {
    params.push(`requested_by=${data.requestedBy}`);
  }
  if (data.statusList) {
    data.statusList.forEach((item) => params.push(`status[]=${item}`));
  }

  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/break_types_with_schedules?${params.join("&")}`,
    CONFIG.client_admin_api,
  );
}

export function getCustomBreaksList(data: GetCustomBreakList): APIResponse<GetCustomBreakListResponseData> {
  const params = [];
  if (data.page) {
    params.push(`page=${data.page}`);
  }
  if (data.perPage) {
    params.push(`per_page=${data.perPage}`);
  }
  if (data.requestedBy) {
    params.push(`requested_by=${data.requestedBy}`);
  }
  if (data.statusList) {
    data.statusList.forEach((item) => params.push(`status[]=${item}`));
  }

  return sendRequest(
    {},
    "GET",
    `/companies/${data.companyUuid}/break_types?${params.join("&")}`,
    CONFIG.client_admin_api,
  );
}

export function createCustomBreak(data: CreateCustomBreak): APIResponse<CreateCustomBreakListResponseData> {
  return sendRequest(data.body, "POST", `/companies/${data.companyUuid}/break_types`, CONFIG.client_admin_api);
}

export function deleteBreak(data: DeleteCustomBreak): APIResponse<void> {
  return sendRequest(
    data.body,
    "DELETE",
    `/companies/${data.companyUuid}/break_types/${data.uuid}`,
    CONFIG.client_admin_api,
  );
}
