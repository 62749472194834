import { Component } from "react";
import styled from "styled-components";
import { WithTranslation, withTranslation } from "react-i18next";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import * as images from "components/svg-images";
import { getPermissionRoles, removePermissionRole, updatePermissionRole } from "utils/apiHelpers";
import { PermissionSectionName, showSnackbar, translateRole } from "utils/common";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { PROJECTS_PERMISSIONS } from "utils/appInitHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { BillingService } from "components/Billing/BillingService";
import { PermissionRole, PermissionSection } from "types/models/permissions";
import OwnerPermissionsPage from "./OwnerPermissionsPage";
import NewPermissionRole from "./NewPermissionRole";
import SectionItem from "./SectionItem";
import { ListItem } from "./ListItem";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 80px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 46px;
  &:first-child {
    width: 196px;
    border-right: 2px solid var(--colors-borderPermissions);
    padding: 0 46px 0 0;
  }
`;

const ColumnTitle = styled.div`
  font-weight: var(--typography-font-weight-bold);
  font-size: 15px;
  color: var(--colors-surface-900-p);
  margin-bottom: 25px;
`;

const PermissionsCategory = styled.div`
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

const RemoveButton = styled.button`
  cursor: pointer;
  outline: none;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  inset-inline-end: 8px;
  top: 8px;
  z-index: 1;
  display: none;
`;

type PegadorPageProps = WithTranslation & AllFlagsLDClient;

type PegadorPageState = {
  loading: boolean;
  permissionRoles: PermissionRole[];
  selectedRoleId: number | null;
  permissionSectionsToUpdate: PermissionSection[];
  confirmationPopupVisible: boolean;
};

class EmployeesPermissions extends Component<PegadorPageProps, PegadorPageState> {
  requestTimeout: NodeJS.Timeout | null;

  constructor(props: PegadorPageProps) {
    super(props);
    this.requestTimeout = null;

    this.state = {
      loading: true,
      permissionRoles: [],
      selectedRoleId: null,
      permissionSectionsToUpdate: [],
      confirmationPopupVisible: false,
    };
  }

  componentDidMount() {
    void this.getData();
  }

  getData = async (callBack?: () => void) => {
    const res = await getPermissionRoles();
    const permissionRoles = res.permission_roles ?? [];
    permissionRoles.sort((a, b) =>
      a.predefined === b.predefined ? a.id - b.id : Number(b.predefined) - Number(a.predefined),
    );

    let selectedRoleId = null;

    if (permissionRoles.length) {
      selectedRoleId = permissionRoles[0].id;
    }
    this.setState({ permissionRoles, loading: false, selectedRoleId }, () => {
      callBack && callBack();
    });
  };

  onRoleClick = (selectedRoleId: number) => {
    this.setState({ selectedRoleId });
  };

  onPermissionChange = (roleId: number, changedPermissionSection: PermissionSection) => {
    const { t } = this.props;
    let { permissionRoles } = this.state;
    const _permissionSectionsToUpdate = [...this.state.permissionSectionsToUpdate, changedPermissionSection];
    const role = permissionRoles.find((rl) => rl.id === roleId);
    if (!role) return;

    let permissionSectionsForRole = role.permission_sections;

    permissionSectionsForRole = permissionSectionsForRole.map((section) =>
      section.id === changedPermissionSection.id ? changedPermissionSection : section,
    );
    role.permission_sections = permissionSectionsForRole;

    permissionRoles = permissionRoles.map((r) =>
      r.id === roleId ? { ...role, permission_sections: permissionSectionsForRole } : r,
    );
    this.setState({
      permissionRoles,
      permissionSectionsToUpdate: _permissionSectionsToUpdate,
    });
    this.requestTimeout && clearTimeout(this.requestTimeout);

    this.requestTimeout = setTimeout(async () => {
      try {
        await updatePermissionRole({ role: { ...role, permission_sections: _permissionSectionsToUpdate } });
        this.setState({
          permissionSectionsToUpdate: [],
        });
        showSnackbar({ text: t("permissions-update-success"), notificationStyle: "notice" });
      } catch (error) {
        showSnackbar({ text: t("permissions-update-failed"), notificationStyle: "error" });
      }
    }, 1000);
  };

  render() {
    const { t, flags } = this.props;
    const { loading, permissionRoles, selectedRoleId, confirmationPopupVisible, permissionSectionsToUpdate } =
      this.state;

    const selectedPermissionSections =
      permissionRoles
        .find((role) => role.id === selectedRoleId)
        ?.permission_sections.sort((a, b) => {
          if (b.name > a.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }) || [];

    const permissionCategoriesDict: Record<string, PermissionSection[]> = {};
    const permissionSectionsByPlan = BillingService.getAllowedRolePermissionsByPlan();
    for (const section of selectedPermissionSections) {
      const category = PROJECTS_PERMISSIONS.includes(section.name) ? "projects" : "time-and-attendance";
      permissionCategoriesDict[category] = permissionCategoriesDict[category] || [];

      if (permissionSectionsByPlan.includes(section.name)) {
        permissionCategoriesDict[category].push(section);
      }
    }

    if (loading) {
      return null;
    }

    return (
      <div>
        <div>
          <Wrapper>
            <Column>
              <ColumnTitle>{t("Role")}</ColumnTitle>
              {permissionRoles.map((role) => (
                <ListItem
                  $isRTL={window.global_store.isRTL}
                  key={role.id}
                  $active={selectedRoleId === role.id}
                  onClick={() => this.onRoleClick(role.id)}
                >
                  {translateRole(t, role)}
                  {!role.predefined && (
                    <RemoveButton
                      className="remove-button"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        this.setState({
                          selectedRoleId: role.id,
                          confirmationPopupVisible: true,
                        });
                      }}
                    >
                      {images.roleDelete}
                    </RemoveButton>
                  )}
                </ListItem>
              ))}
              <NewPermissionRole
                key="new"
                allRoles={permissionRoles}
                onRoleAdded={() => {
                  void this.getData();
                }}
              >
                {t("New Role")}
              </NewPermissionRole>
            </Column>

            {selectedPermissionSections?.length &&
            permissionRoles.filter((role) => role.id === selectedRoleId)[0]?.name === "Owner" ? (
              <OwnerPermissionsPage />
            ) : (
              selectedPermissionSections?.length && (
                <Column>
                  {Object.keys(permissionCategoriesDict).map((category) =>
                    permissionCategoriesDict[category].length > 0 ? (
                      <PermissionsCategory key={category}>
                        <ColumnTitle>{t(`category ${category}`)}</ColumnTitle>
                        {permissionCategoriesDict[category].map((permission) => {
                          // hide old permissions for new hierarchy
                          if (
                            [PermissionSectionName.punches, PermissionSectionName.reports].includes(permission.name)
                          ) {
                            return null;
                          }

                          return (
                            <SectionItem
                              disabled={permissionSectionsToUpdate.some((sec) => sec.id === permission.id)}
                              key={permission.id}
                              permission={permission}
                              checked={permission.allow}
                              onChange={(changedPermission) => {
                                if (selectedRoleId) {
                                  this.onPermissionChange(selectedRoleId, changedPermission);
                                }
                              }}
                              label={t(permission.name)}
                            />
                          );
                        })}
                      </PermissionsCategory>
                    ) : null,
                  )}
                </Column>
              )
            )}
          </Wrapper>
        </div>
        <ModalDialog
          isOpen={confirmationPopupVisible}
          onClose={() => this.setState({ confirmationPopupVisible: false })}
        >
          <Lightbox
            title={t("Delete this Role?")}
            text={t("Deleting this function all employees will have access to Basic Acess")}
            buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
            buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
            onClose={() => {
              this.setState({ confirmationPopupVisible: false });
            }}
            onYes={async () => {
              await removePermissionRole({
                uuid: permissionRoles.filter((role) => role.id === selectedRoleId)[0].uuid,
              });
              void this.getData(() => {
                this.setState({ confirmationPopupVisible: false });
              });
            }}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default withLDConsumer()(withTranslation([TranslationNamespaces.employeesPermissions])(EmployeesPermissions));
