import { Component, ContextType } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import * as momentTz from "moment-timezone";
import { extendMoment } from "moment-range";
import { GlobalContext } from "context/GlobalContextProvider";
import { approvePunch, declinePunch, getLocations, getPunchDetails, validatePunch } from "utils/apiHelpers";
import BEM from "utils/BEM";
import { getCompanyTaxIdTranslation, getCompanyTaxPayerType, getDateWithTZ, getTitle } from "utils/common";
import { GMapCircle } from "utils/gmapsHelpers";
import ga, { GaApprovePunchButtonLocation } from "utils/ga";
import sentryUtils from "utils/sentryUtils";
import { TranslationNamespaces } from "types/translationNamespaces";
import {
  PunchDetailsPunch,
  PunchDeviceType,
  PunchLogActionDetails,
  PunchReason,
  PunchSource,
  PunchStatuses,
  PunchType,
  PunchVerificationMethod,
} from "types/models/punches";
import { Location, LocationValidation, PunchLocation } from "types/models/location";
import { GlobalStoreCompany } from "types/models/company";
import Avatar from "components/views/Avatar";
import * as images from "components/svg-images";
import ModalDialog from "components/UI/ModalDialog";
import DeclineReasonRow from "components/UI/DeclineReasonRow";
import DeclineReasonPopup from "components/DeclineReasonPopup";
import RequestDetailsLogEvents from "components/Requests/RequestDetailsLogEvents";
import FieldWrapper from "components/UI/FieldWrapper";
import Button, { ButtonState, StyledButtonProps } from "components/controls/StyledButton";
import { listUserProfilesWIthFilters } from "utils/api/company";
import { baseByUuidPayload } from "utils/employeeFilter.utils";
import CONFIG from "config";
import "styles/punch-details.scss";
import { getCustomBreaksList } from "utils/api/schedule";
import { BreakStatusOptions } from "utils/api/types";
import { getBreakName, getBreaksNamesMap } from "components/Schedules/Breaks/utils";
import { FilteredEmployeeProfile } from "types/models/userProfile";
import ApproveDeclineRow from "./ApproveDeclineRow";

const moment = extendMoment(momentTz);

const b = BEM.b("punch-details");

const Wrapper = styled.div``;

const Text = styled.span`
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
`;

const VerificationIcon = styled.div`
  position: absolute;
  top: 0;
  inset-inline-end: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 23px;
`;

/** Print only */
const CompanyInfo = styled.div`
  @media print {
    display: flex !important;
  }
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: var(--colors-surface-600);

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;

    &.company-info-name {
      svg {
        margin-bottom: -1px;
        margin-inline-end: 8px;
      }
    }
  }
`;

/** Print only */
const Footer = styled.div`
  @media print {
    position: absolute;
    bottom: 0;
  }

  border: 1px solid var(--colors-surface-100);

  & > div {
    padding: 16px;

    &.company-info {
      display: flex;

      .shield-icon {
        margin-inline-end: 16px;
        max-height: 36px;
      }

      .company-number {
        font-size: var(--typography-font-size-default);
        line-height: 14px;
        color: var(--colors-surface-600);
        font-weight: var(--typography-font-weight-medium);

        & div:first-child {
          margin-bottom: 8px;
        }
      }
    }

    &.hash {
      font-size: var(--typography-font-size-default);
      line-height: 18px;
      color: var(--colors-surface-500);
      background: var(--colors-surface-50);
      word-wrap: break-word;
    }
  }
`;

interface PunchDetailsProps extends WithTranslation {
  punchId: number | null;
  employeeUuid: string | null;
  /** Superpunch */
  spApprovePunch?: () => void;
  /** Superpunch */
  spDeclinePunch?: (declineReason: unknown) => void;
  /** Superpunch */
  spValidatePunch?: () => void;
  onApprovePunch?: () => void;
  onDeclinePunch?: () => void;
  onValidatePunch?: () => void;
  employeePage?: boolean;
}

interface PunchDetailsState {
  fetching: boolean;
  punch: PunchDetailsPunch | null;
  customAddress: string | null;
  declineConfirmationPopupVisible: boolean;
  locations: Location[];
  loading: boolean;
  subsidiary: FilteredEmployeeProfile["subsidiary"] | null;
  breakNamesMap: Record<string, string>;
}

class PunchDetails extends Component<PunchDetailsProps, PunchDetailsState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;

  readonly state: Readonly<PunchDetailsState> = {
    fetching: true,
    punch: null,
    customAddress: null,
    declineConfirmationPopupVisible: false,
    locations: [],
    loading: false,
    subsidiary: null,
    breakNamesMap: {},
  };

  async componentDidMount() {
    const { punchId } = this.props;
    if (!punchId) return;

    const [punch, , , customBreaksResp] = await Promise.all([
      await getPunchDetails({ punchId, newHierarchyPermissions: true }),
      this.getLocations(),
      this.getSubsidiary(),
      getCustomBreaksList({
        perPage: 300,
        page: 1,
        statusList: [BreakStatusOptions.active, BreakStatusOptions.archived],
        companyUuid: window.global_store.company.uuid,
        requestedBy: window.global_store.profile.uuid,
      }),
    ]);
    void this.getCustomAddress(punch);
    const breakNamesMap = getBreaksNamesMap(customBreaksResp?.content || []);

    window.addEventListener("beforeprint", this.beforePrint);
    window.addEventListener("afterprint", this.afterPrint);
    this.setState({
      punch,
      fetching: false,
      breakNamesMap,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PunchDetailsProps) {
    if (this.props.punchId !== nextProps.punchId) {
      void this.getState(nextProps);
    }
  }

  componentWillUnmout() {
    window.removeEventListener("beforeprint", this.beforePrint);
    window.removeEventListener("afterprint", this.afterPrint);
  }

  beforePrint = () => {
    document.title = this.props.t("Comprovante de Registro de Ponto do Trabalhador");
  };

  afterPrint = () => {
    document.title = getTitle(this.props.t("Punches"));
  };

  getSubsidiary = async () => {
    const { employeeUuid } = this.props;

    if (employeeUuid) {
      const company = await this.context.getCompany();
      const { content: employees } = await listUserProfilesWIthFilters(company.uuid, {
        ...baseByUuidPayload(window.global_store.profile.uuid, [employeeUuid]),
        fields: ["uuid", "subsidiary.name", "subsidiary.uuid", "subsidiary.taxPayerId", "fullName"],
      });

      this.setState({ subsidiary: employees[0]?.subsidiary });
    } else {
      this.setState({ subsidiary: null });
    }
  };

  getLocations = async () => {
    if (!window.global_store.locations) {
      try {
        const r = await getLocations();

        window.global_store.locations = r.locations || [];
        this.setState({ locations: r.locations || [] });
      } catch (err) {
        sentryUtils.sendError(err);
      }
    } else {
      this.setState({ locations: window.global_store.locations });
    }
  };

  getCustomAddress = (punch: PunchDetailsPunch) => {
    if (punch?.lat && punch.lng) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: punch.lat, lng: punch.lng };

      void geocoder.geocode({ location: latlng }, (r) => {
        if (r?.length) {
          this.setState({ customAddress: r[0].formatted_address });
        }
      });
    }
  };

  getState = async (props: PunchDetailsProps) => {
    const { punchId } = props;

    if (punchId) {
      try {
        const punch = await getPunchDetails({ punchId, newHierarchyPermissions: true });

        this.setState({
          punch,
          fetching: false,
        });
      } catch (err) {
        sentryUtils.sendError(err);
      }
    }
  };

  getLocationVerificationMethods = (location: PunchLocation) => {
    const arr: PunchVerificationMethod[] = [];
    const { locations } = this.state;

    if (locations) {
      const punchLocation = locations.find((l) => l.id === location.id);
      const verificationMethods = punchLocation?.verification_methods || [];

      if (verificationMethods.includes(LocationValidation.gps)) {
        arr.push(PunchVerificationMethod.location);
      }
      if (verificationMethods.includes(LocationValidation.beacons)) {
        arr.push(PunchVerificationMethod.beacon);
      }
      if (verificationMethods.includes(LocationValidation.wifiPoints)) {
        arr.push(PunchVerificationMethod.wifiPoint);
      }
    }

    return arr;
  };

  getIcon = (punch: PunchDetailsPunch) => {
    const { t } = this.props;

    if (punch.device?.device_type === PunchDeviceType.tablet) {
      return (
        <VerificationIcon title={t("Tablet punch")} className={b("verification-icon", ["green"])}>
          {images.punchesTabletPhoto({ width: "19px", height: "20px" })}
        </VerificationIcon>
      );
    }

    const locationVerificationMethods = this.getLocationVerificationMethods(punch.location);
    const verificationMethods = [
      PunchVerificationMethod.biometric,
      PunchVerificationMethod.location,
      PunchVerificationMethod.beacon,
      PunchVerificationMethod.wifiPoint,
    ];

    if (punch.source === PunchSource.computer) {
      return (
        <VerificationIcon className={b("verification-icon", ["green"])}>
          {images.verification.computer}
        </VerificationIcon>
      );
    }

    if (
      !punch.is_manual &&
      (!punch.verified_by_type || punch.verified_by_type === PunchVerificationMethod.none) &&
      locationVerificationMethods.length > 0
    ) {
      return (
        <VerificationIcon
          title={t(`Verified by ${locationVerificationMethods[0]}`)}
          className={b("verification-icon", punch.source === PunchSource.tablet ? "green" : "red")}
        >
          {images.verification[locationVerificationMethods[0] as keyof typeof images.verification]}
        </VerificationIcon>
      );
    }

    const verifiedBy = verificationMethods.find((v) => v === punch.verified_by_type);
    if (verifiedBy) {
      return (
        <VerificationIcon title={t(`Verified by ${verifiedBy}`)} className={b("verification-icon", ["green"])}>
          {images.verification[verifiedBy as keyof typeof images.verification]}
        </VerificationIcon>
      );
    }

    return null;
  };

  generateMap = (punch: PunchDetailsPunch) => {
    const { customAddress, locations } = this.state;

    if (punch.lat && punch.lng) {
      if (punch.is_in_custom_location) {
        return (
          <div className={b("field")}>
            <img src={`${GMapCircle(punch.lat, punch.lng, 0, 0, 0)}&key=${CONFIG.googleMapApiPunch}`} alt="" />
            {customAddress && (
              <div className={b("address")}>
                <div className={b("target-icon")}>{images.locationTarget}</div>
                <div className={b("address-text")}>{customAddress}</div>
              </div>
            )}
          </div>
        );
      }

      if (punch.is_manual && punch.reason === PunchReason.notValidatedLocation) {
        const punchLocation = locations.find((l) => l.id === punch.location.id);

        return (
          <div className={b("field")}>
            <img
              src={`${GMapCircle(
                punch.lat,
                punch.lng,
                punchLocation ? punchLocation.radius : 0,
                punch.location.lat,
                punch.location.lng,
              )}&key=${CONFIG.googleMapApiPunch}`}
              alt=""
            />
            {customAddress && (
              <div className={b("address")}>
                <div className={b("target-icon")}>{images.locationTarget}</div>
                <div className={b("address-text")}>{customAddress}</div>
              </div>
            )}
          </div>
        );
      }
    }

    return null;
  };

  onApproveClick = () => {
    this.setState({ loading: true }, async () => {
      const { spApprovePunch, onApprovePunch, employeePage } = this.props;
      const { punch } = this.state;

      if (spApprovePunch) {
        spApprovePunch();
      } else {
        await approvePunch({ punchUuid: punch?.uuid });

        ga.trackApprovePunch({
          punch_type: punch?.punch_type,
          button_location: employeePage
            ? GaApprovePunchButtonLocation.employeesPunchDetails
            : GaApprovePunchButtonLocation.punchDetails,
        });

        if (onApprovePunch) {
          onApprovePunch();
        } else {
          void this.getState(this.props);
        }
      }
    });
  };

  onDeclineClick = () => {
    this.setState({ declineConfirmationPopupVisible: true });
  };

  onDeclineConfirmationClicked = (declineReason: string) => {
    this.setState({ loading: true }, async () => {
      const { spDeclinePunch, onDeclinePunch } = this.props;
      const { punch } = this.state;

      const body = {
        punch: {
          decline_reason: declineReason || "",
        },
      };

      if (spDeclinePunch) {
        spDeclinePunch(declineReason);
      } else {
        await declinePunch({ punchUuid: punch?.uuid, body });

        if (onDeclinePunch) {
          onDeclinePunch();
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          void this.getState(this.props);
        }
      }
    });
  };

  onValidateClick = () => {
    this.setState({ loading: true }, async () => {
      const { spValidatePunch, onValidatePunch } = this.props;
      const { punch } = this.state;

      if (spValidatePunch) {
        spValidatePunch();
      } else {
        await validatePunch({ punchId: punch?.id });

        if (onValidatePunch) {
          onValidatePunch();
        } else {
          void this.getState(this.props);
        }
      }
    });
  };

  canUpdatePunchStatus = (punch: PunchDetailsPunch) => {
    const isLocked = punch.is_locked;
    const isOwnPunch = punch.employee.uuid === window.global_store.profile.uuid;
    const hasPermission = !!punch.has_permission_to_manage;

    return !isLocked && hasPermission && !isOwnPunch;
  };

  getButtons = (punch: PunchDetailsPunch) => {
    const { t } = this.props;
    const { loading } = this.state;
    let buttonProps: Partial<StyledButtonProps> = {};

    if (loading) {
      buttonProps = { disabled: true };
    }

    if (this.canUpdatePunchStatus(punch)) {
      if (punch.is_manual && punch.status === PunchStatuses.pending) {
        return (
          <div className="no-print">
            <ApproveDeclineRow
              buttonProps={buttonProps}
              onDeclineClick={this.onDeclineClick}
              onApproveClick={this.onApproveClick}
            />
          </div>
        );
      }

      if (punch.is_valid === false) {
        return (
          <div className={`${b("buttons")} no-print`}>
            <div
              style={{ width: "100px" }}
              className={`button-next ${buttonProps.disabled ? "button-next_disabled" : ""}`}
              onClick={this.onValidateClick}
            >
              <input
                value={t("Validate") as string}
                type="button"
                className={b("button", { full: true, primary: true })}
                {...buttonProps}
              />
            </div>
          </div>
        );
      }
    }

    return null;
  };

  getCompanyInfo = () => {
    const { t } = this.props;
    const { subsidiary } = this.state;

    const name = subsidiary ? subsidiary.name : window.global_store.company.name;
    const taxId = subsidiary ? subsidiary.taxPayerId : window.global_store.company.tax_payer_id;
    const companyTaxId = getCompanyTaxPayerType(window.global_store.profile?.company?.country);
    const companyTaxIdLabel = getCompanyTaxIdTranslation(companyTaxId, t);

    return (
      <CompanyInfo className="print-only">
        <div className="company-info-name">
          {images.companyBuilding} <span>{name}</span>
        </div>

        <div className="company-info-tax-id">{taxId ? `${companyTaxIdLabel}: ${taxId}` : ""}</div>
      </CompanyInfo>
    );
  };

  getFooter = () => {
    const { t } = this.props;
    const { punch } = this.state;

    if (punch === null) {
      return null;
    }

    return (
      <Footer className="print-only">
        <div className="company-info">
          <div className="shield-icon">{images.shieldSuccess}</div>
          <div className="company-number">
            <div>Oitchau.com.br</div>
            <div>{`${t("INPI Number")}: 5120220006588`}</div>
          </div>
        </div>
        {punch.punch_hash && <div className="hash">{punch.punch_hash}</div>}
      </Footer>
    );
  };

  render() {
    const { punch, fetching, declineConfirmationPopupVisible, loading, breakNamesMap } = this.state;
    const { t } = this.props;

    if (fetching || !punch) {
      return <div>{t("common|Loading...")}</div>;
    }

    const status = punch.is_valid === false ? "invalid" : punch.status;
    const declineReasons = punch.logs?.length
      ? punch.logs.filter(
          (log) =>
            log.actionDetails &&
            log.actionDetails.approvalStatus === PunchStatuses.declined &&
            log.actionDetails.approvalStatusReason,
        )
      : [];

    const punchType =
      punch.punch_type === PunchType.breakStart || punch.punch_type === PunchType.breakEnd
        ? getBreakName(punch.break_type_uuid, punch.punch_type, breakNamesMap)
        : t(punch.punch_type);

    return (
      <Wrapper className={b({ popup: true })}>
        <div className={b("user-info")}>
          <Avatar
            className="no-print"
            user={{ fullName: punch.employee.name, avatarId: punch.employee.avatar_id }}
            modifiers={{ big: true }}
          />
          <div className={b("name-position")}>
            <div className={b("name")}>
              {punch.employee.name}
              <div className={b("status", [status])}>{t(status)}</div>
            </div>
            {punch.employee.job_description && <div className={b("position")}>{punch.employee.job_description}</div>}
            {punch.employee.cpf && <div className={`${b("cpf")} print-only`}>{punch.employee.cpf}</div>}
          </div>
        </div>
        <FieldWrapper fieldName={t("Type")} width="100%" fieldTitleMarginTop={24}>
          <Text>{punchType}</Text>
        </FieldWrapper>
        <div className={b("header-data")}>
          <div className={b("field")}>
            <div className={b("field-title")}>{t("Time")}</div>
            <div className={b("field-value")}>
              {getDateWithTZ(punch.device_datetime, punch.time_zone).format("HH:mm")}
            </div>
          </div>
          <div className={b("field")}>
            <div className={b("field-title")}>{t("Date")}</div>
            <div className={b("field-value")}>
              {getDateWithTZ(punch.device_datetime, punch.time_zone).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>

        <FieldWrapper fieldName={t("Location")} width="100%" fieldTitleMarginTop={24}>
          {punch.is_in_custom_location ? (
            <div
              className={b("location", {
                "has-map":
                  punch.is_in_custom_location || (punch.is_manual && punch.reason === PunchReason.notValidatedLocation),
              })}
            >
              <div className={b("field-value")}>{t("Custom Location")}</div>
            </div>
          ) : (
            punch.location && (
              <div
                className={b("location", {
                  "has-map": punch.is_in_custom_location || (punch.is_manual && PunchReason.notValidatedLocation),
                })}
              >
                <div className={b("field-value")}>
                  {`${punch.location.code || punch.location.id} - ${punch.location.name}`}
                </div>
                {this.getIcon(punch)}
              </div>
            )
          )}
          {this.generateMap(punch)}
        </FieldWrapper>
        {punch.is_manual && (
          <div className={b("field", { reason: true })}>
            <div className={b("field-title")}>{t("Reason")}</div>
            <div className={b("field-value")}>{t(`punches-page|${punch.reason}`)}</div>
          </div>
        )}
        {punch.is_manual && punch.comment && (
          <div className={b("field", { comment: true })}>
            <div className={b("field-title")}>{t("Comment")}</div>
            <div className={b("field-value")}>{punch.comment}</div>
          </div>
        )}
        {punch.device?.model && (
          <div className={b("field")}>
            <div className={b("field-title")}>{t("Mobile Device")}</div>
            <div className={b("field-value")}>
              {punch.device.vendor} {punch.device.model}
            </div>
          </div>
        )}

        {!!declineReasons.length && (
          <DeclineReasonRow
            employee={declineReasons[0].actor}
            declineReason={(declineReasons[0].actionDetails as PunchLogActionDetails).approvalStatusReason}
            date={moment(declineReasons[0].createdAt).format("DD/MM/YYYY HH:mm")}
          />
        )}

        {!!(punch.logs && punch.logs.length) && (
          <RequestDetailsLogEvents logs={punch.logs} timezone={punch.time_zone} />
        )}

        {punch.status === PunchStatuses.approved && (
          <Button
            className="no-print"
            style={{ marginBottom: 24 }}
            state={ButtonState.outline}
            value={t("Download Receipt")}
            onClick={() => window.print()}
          />
        )}

        {this.getButtons(punch)}

        {this.getCompanyInfo()}

        {this.getFooter()}

        <ModalDialog
          isOpen={declineConfirmationPopupVisible}
          onClose={() => this.setState({ declineConfirmationPopupVisible: false })}
        >
          <DeclineReasonPopup
            t={t}
            loading={loading}
            title={t("punches-page|Decline Punch.")}
            text={t("punches-page|Are you sure to Decline the punch? You should not be able to undo that action.")}
            buttonYesTitle={t("common|Confirm")}
            buttonCancelTitle={t("common|Cancel")}
            onClose={() => {
              this.setState({ declineConfirmationPopupVisible: false });
            }}
            onYes={this.onDeclineConfirmationClicked}
          />
        </ModalDialog>
      </Wrapper>
    );
  }
}

export default withTranslation([TranslationNamespaces.employeesPage, TranslationNamespaces.punchesPage])(PunchDetails);
