import { ChangeEvent, FocusEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "components/UI/FieldWrapper";
import TextInputControl from "components/controls/TextInputControlNew";
import RichTooltip from "components/UI/RichTooltip";
import { TranslationNamespaces } from "types/translationNamespaces";

const Spacer = styled.div`
  width: 16px;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
`;

interface DaysBeforeRequestProps {
  onChange: (value: number | undefined) => void;
  value: number | undefined;
}

const DaysBeforeRequest = ({ onChange, value }: DaysBeforeRequestProps) => {
  const { t } = useTranslation(TranslationNamespaces.requestsPageTmp);

  return (
    <>
      <div style={{ margin: "8px 0" }}>
        <FieldWrapper width="100%" fieldTitleMarginTop={0} fieldTitleMarginBottom={8}>
          <FieldRow>
            <TextInputControl
              placeholder=""
              value={value || 0}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                let val: number | undefined;

                if (ev.target.value) {
                  val = parseInt(ev.target.value.replace(/[^0-9]*/g, ""), 10);

                  if (val > 365) {
                    val = 365;
                  } else if (val < 1) {
                    val = 1;
                  }
                }

                onChange(val);
              }}
            />
            <Spacer />
            <RichTooltip text={t("days-before-request-tooltip")} />
          </FieldRow>
        </FieldWrapper>
      </div>
    </>
  );
};

export default DaysBeforeRequest;
