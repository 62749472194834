import { stylesheet } from "astroturf";
import { Field } from "formik";
import { BaseSyntheticEvent } from "react";
import { ReactComponent as WarningIcon } from "../icons/info_notification_ic.svg";

const styles = stylesheet`
  :global([dir='rtl']) {
    .FormField .FormSelect {
      background-position: center left 10px;
    }
  }
  .FormField {
    width: 100%;
    margin-bottom: 22px;
    .FormSelect {
      appearance: none;
      background-image: url("../icons/select-arrow.svg");
      background-position: center right 10px;
      background-repeat: no-repeat;
      &:focus, &:focus-visible {
        outline: none;
      }
    }
    .FormLabel {
      display: block;
      width: 100%;
      font-size: 13px;
      color: var(--colors-text1);
      margin-bottom: 6px;
    }
    .FormInput {
      display: block;
      width: 100%;
      height: 36px;
      padding: 0 16px;
      border-radius: var(--shapes-border-radius-default);
      background-color: var(--colors-default);
      border: solid 1px var(--colors-secondary);
      font-size: var(--typography-font-size-default);
      color: var(--colors-surface-800);
      &.Error {
        border: solid 2px var(--colors-danger-600-p);
      }
    }
    .FormErrorMessage {
      display: flex;
      align-items: center;
      margin-top: 8px;
      font-size: 13px;
      font-weight: var(--typography-font-weight-default);
      color: var(--colors-danger-600-p);
      svg {
        height: 14px;
        width: 14px;
        path {
          fill: var(--colors-danger-600-p);
        }
      }
      span {
        margin-inline-start: 6px;
      }
    }
  }
`;
type FormFieldProps = {
  name: string;
  title: string;
  type?: string;
  error?: any;
  placeholder?: string;
  options?: { label: string; value: string }[];
  onChange?: (e: BaseSyntheticEvent) => void;
};

const FormField = ({ name, title, type, error, options, ...rest }: FormFieldProps) => (
  <div key={name} className={styles.FormField}>
    <label>
      <span className={styles.FormLabel}>{title}</span>
      {type === "select" ? (
        <Field
          className={`${styles.FormInput} ${styles.FormSelect} ${error ? styles.Error : ""}`}
          name={name}
          as="select"
          {...rest}
        >
          {options?.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Field>
      ) : (
        <Field
          className={`${styles.FormInput} ${error ? styles.Error : ""}`}
          name={name}
          type={type || "text"}
          {...rest}
        />
      )}
    </label>
    {error ? (
      <div className={styles.FormErrorMessage}>
        <WarningIcon />
        <span>{error}</span>
      </div>
    ) : null}
  </div>
);

export default FormField;
