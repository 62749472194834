import React, { CSSProperties, Component, ReactNode } from "react";
import BEM from "utils/BEM";
import styled from "styled-components";
import Option from "./SelectOption";
import DropdownWrapper from "./DropdownWrapper";
import "./Select.scss";

const b = BEM.b("ui-select");
const Wrapper = styled.div`
  margin: 0 20px;
  display: flex;
  text-align: left;
  justify-content: center;
  cursor: pointer;
`;

interface SimpleMenuOption<V> {
  value: V;
  label: string;
}

interface SimpleMenuProps<V> {
  options: SimpleMenuOption<V>[];
  onChange: (value: V) => void;
  /** children receive isOpen boolean prop */
  children?: ReactNode | undefined;
  disabled?: boolean;
  useClick?: boolean;
  style?: CSSProperties;
}

interface SimpleMenuState {
  isOpen: boolean;
}

class SimpleMenu<V = string> extends Component<SimpleMenuProps<V>, SimpleMenuState> {
  timeout: NodeJS.Timeout | null = null;

  constructor(props: SimpleMenuProps<V>) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleOpen(open?: boolean) {
    const { useClick } = this.props;
    const isOpen = typeof open !== "undefined" ? open : !this.state.isOpen;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(
      () => {
        this.setState({ isOpen });
      },
      useClick && open ? 0 : 300,
    );
  }

  selectValue(value: V) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(value);
    }

    this.setState({ isOpen: false });
  }

  render() {
    const { options, children, disabled, useClick, style } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={b("wrapper")}>
        <Wrapper
          onMouseEnter={() => {
            if (!disabled && !useClick) {
              this.toggleOpen(true);
            }
          }}
          onMouseLeave={() => this.toggleOpen(false)}
          onClick={useClick && !disabled ? () => this.toggleOpen(true) : undefined}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              // pass isOpen to children
              return React.cloneElement(child, { isOpen });
            }

            return child;
          })}
        </Wrapper>

        {isOpen && (
          <DropdownWrapper style={style} className={b("dropdown-wrapper")}>
            <div onMouseEnter={() => this.toggleOpen(true)} onMouseLeave={() => this.toggleOpen(false)}>
              {options.map((option) => (
                <Option
                  key={String(option.value)}
                  preselected={false}
                  onSelect={() => this.selectValue(option.value)}
                  label={option.label}
                  active
                />
              ))}
            </div>
          </DropdownWrapper>
        )}
      </div>
    );
  }
}

export default SimpleMenu;
