enum FilenameConfigurationType {
  payrollGroup = "payrollGroup",
  deactivatedEmployee = "deactivatedEmployee",
}

enum FilenameConfigurationOptionType {
  static = "static",
  date = "date",
  entity = "entity",
  fileSequence = "fileSequence",
}

enum FilenameConfigurationEntity {
  employee = "employee",
  company = "company",
  payrollLayout = "payrollLayout",
  payrollProvider = "payrollProvider",
  payrollLock = "payrollLock",
}

enum FilenameConfigurationEmployeeKey {
  id = "id",
  matricula = "matricula",
  cpf = "cpf",
  externalId = "externalId",
  fullName = "fullName",
}

enum FilenameConfigurationCompanyKey {
  id = "id",
  name = "name",
}

enum FilenameConfigurationPayrollProviderKey {
  name = "name",
}

enum FilenameConfigurationPayrollLayoutKey {
  name = "name",
  companyCode = "companyCode",
  payrollCode = "payrollCode",
  payrollDate = "payrollDate",
}

enum FilenameConfigurationPayrollLockKey {
  name = "name",
  startDate = "startDate",
  endDate = "endDate",
}

enum ERPPayrollEventType {
  P = "P",
  D = "D",
  N = "N",
}

type FilenameConfigurationOption = {
  /** uuid is only for FE purposes */
  uuid: string;
  type: FilenameConfigurationOptionType;
  value?: string;
  format?: string;
  length?: number;
  fillString? : string;
  prefix?: string;
  entity?: FilenameConfigurationEntity;
  key?:
    | FilenameConfigurationEmployeeKey
    | FilenameConfigurationCompanyKey
    | FilenameConfigurationPayrollProviderKey
    | FilenameConfigurationPayrollLayoutKey
    | FilenameConfigurationPayrollLockKey;
};

type FilenameConfiguration = {
  format: Omit<FilenameConfigurationOption, "uuid">[];
  separator: string;
};

type PayrollProviderTemplate = {
  name: string;
  type: string; // todo enum ?
  value?: string;
  length?: number | null;
  maxlength?: number | null;
  minlength?: number | null;
  decimalSeparator?: string;
  alphanumeric?: boolean;
  unsigned?: boolean;
  required?: boolean;
  format?: string;
};

type PayrollProviderConfigFields = {
  separator: string;
  template: PayrollProviderTemplate[];
  headerTemplate: PayrollProviderTemplate[];
};

type PayrollProvider = {
  configFields: PayrollProviderConfigFields;
  createdAt: string;
  name: string;
  template: PayrollProviderTemplate[];
  uuid: string;
  validationOptions: unknown[];
  footerTemplate?: PayrollProviderTemplate[];
  headerTemplate?: PayrollProviderTemplate[];
  format?: "xml"; // todo enum
  maxEmployeesPerRecord?: number;
};

// TODO what fields are right?
type PayrollEvent = {
  uuid: string;
  name: string; // todo enum ?
  eventCode: string;
  payrollEventTypeUuid: string;
  eventType?: ERPPayrollEventType;
  extraOptions: {
    customId?: string;
    eventTypeName?: string;
    interval?: string;
    limit?: string;
    name?: string;
    requestSubtypeUuid?: string;
    requestTypeUuid?: string;
    serviceUuid?: string;
    phaseKey?: string;
    key?: string;
    payPolicyType?: string;
    nightShiftName?: number;
    nightShiftOvertimeName?: number;
    nightShiftWithoutOvertimeName?: number;
    breakTypeUuid?: string;
    hoursDistributionName?: string;
  };
};

//* * from getPayrollEventTypes() call */
type PayrollEventType = {
  uuid: string;
  name: string;
  /** Used for request types and request subtypes events */
  userDefined?: boolean;
  /** Used for request subtypes events */
  requestTypeUuid?: string;
  configFields?: {
    sourceUnit: string; // not used on FE
    unit: string; // not used on FE
    valueKey: string; // not used on FE
  }; // not used on FE
  sourceUnit?: string; /// not used on FE
  unit?: string; // not used on FE
  valueKey?: string; // not used on FE
  createdAt?: string; // not used on FE
};

type PayrollLayout = {
  createdBy: string;
  providers: PayrollProvider[];
};

export enum InfotypeOperation {
  INS = "INS",
  MOD = "MOD",
  DEL = "DEL",
}

export enum RunType {
  production = "P",
  testing = "T",
}

// todo is it the same as PayrollLayout ?
type PayrollLayoutFull = {
  active: boolean;
  companyCode: string | null;
  companyName?: string;
  companyUuid: string;
  competence: unknown | null; // todo;
  createdAt: string;
  createdBy: string | null;
  extraOptions: never[] | Record<string, any>; // todo;
  filenameTemplateConfig: {
    payrollGroup?: FilenameConfiguration;
    deactivatedEmployee?: FilenameConfiguration;
  };
  hoursFormat: "sexagesimal" | "centesimal";
  hoursSeparator: string; // todo enum ?
  name: string;
  payrollCode: string | null;
  payrollDate: string | null;
  payrollEvents: (PayrollEvent & {
    // todo are these firlds exist on PayrollEvent as well?
    createdAt: string;
    createdBy: string | null;
    payrollLayoutUuid: string;
    uuid: string;
  })[];
  payrollProvider: PayrollProvider;
  payrollProviderUuid: string;
  updatedAt: string;
  uuid: string;
  subsidiaryCode?: unknown;
  userLoggedInADP?: string;
  payrollCompanyID?: string;
  userRequisitionReturn?: string;
  clientCode?: unknown;
  mescom?: string;
  contactName?: string;
  contactEmail?: string;
  adpUserName?: string;
  nameOfCustomer?: string;
  sequentialNumberItRecord?: string;
  infotypeOperation?: InfotypeOperation;
};

export type {
  PayrollLayout,
  PayrollLayoutFull,
  PayrollProvider,
  PayrollEvent,
  PayrollEventType,
  PayrollProviderTemplate,
  FilenameConfiguration,
  FilenameConfigurationOption,
};

export {
  FilenameConfigurationType,
  FilenameConfigurationOptionType,
  FilenameConfigurationEntity,
  FilenameConfigurationEmployeeKey,
  FilenameConfigurationCompanyKey,
  FilenameConfigurationPayrollLayoutKey,
  FilenameConfigurationPayrollProviderKey,
  FilenameConfigurationPayrollLockKey,
  ERPPayrollEventType,
};
