import { PropsWithChildren, ReactNode, useState } from "react";
import styled from "styled-components";
import * as images from "../svg-images";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 4px;
  padding: 24px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--colors-surface-150);
  height: 64px;
`;

const IconWrapper = styled.div<{isOpened: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  & svg {
    transform: rotate(${(p) => (p.isOpened ? "90deg" : "0")});
    path {
      fill: var(--colors-surface-800);
    }
  }
`;
const Label = styled.div<{isOpened: boolean}>`
  font-size: 16px;
  color: ${(p) => (p.isOpened ? "var(--colors-surface-900-p)" : "var(--colors-surface-700)")}
`

type AccordionProps = PropsWithChildren & {
  label: string;
  customElement?: ReactNode;
  hideElementOnOpen?: boolean;
}

export default function Accordion (props: AccordionProps) {
  const [isOpened, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpened);

  return (<>
    <Wrapper onClick={toggleOpen}>
      <IconWrapper isOpened={isOpened}>{images.calArrowRight}</IconWrapper>
      <Label isOpened={isOpened}>{props.label}</Label>
      { props.customElement && !(props.hideElementOnOpen && isOpened) ? props.customElement : null }
    </Wrapper>
    {isOpened ? props.children : null}
  </>);
}
