import { Injectable, Provider } from "services/di";
import { KvStorage } from "services/kv-storage";

export * from "services/kv-storage";

/**
 * Use window.localStorage API.
 */
@Injectable()
export class KvStorageWapp implements KvStorage {
  async setItem<T = any>(key: string, value: T) {
    const raw = JSON.stringify(value);
    localStorage.setItem(key, raw);
  }

  async getItem<T = any>(key: string): Promise<T> {
    const raw = localStorage.getItem(key);
    const item = JSON.parse(raw);
    return item;
  }
}

export const KvStorageWappProvider: Provider = {
  provide: KvStorage,
  useClass: KvStorageWapp,
};
