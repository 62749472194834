import styled from "styled-components";

const ItemWrapper = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;

  &.new:before {
    content: "";
    position: absolute;
    inset-inline-start: -15px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--colors-attention);
  }
`;

const Text = styled.p`
  margin: 0 0 8px 0;
  line-height: 18px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-surface-400);

  a {
    line-height: 18px;
    font-size: var(--typography-font-size-default);
    color: var(--colors-primary);
  }

  &.new {
    color: #525f7f;
  }
`;

const Date = styled.p`
  margin: 0;
  line-height: 15px;
  font-size: 12px;
  color: var(--colors-surface-400);
`;

interface NotificationItemProps {
  text: string;
  time: string;
  newItem: boolean;
}

const NotificationItem = ({ text, time, newItem }: NotificationItemProps) => (
  <ItemWrapper className={newItem ? "new" : ""}>
    <Text
      className={newItem ? "new" : ""}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
    <Date>{time}</Date>
  </ItemWrapper>
);

export default NotificationItem;
