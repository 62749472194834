import { Component } from "react";
import { withTranslation } from "react-i18next";
import { getMyDigitalSignatures } from "utils/apiHelpers";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment";
import { getTitle } from "utils/common";
import styled from "styled-components";
import { TranslationNamespaces } from "types/translationNamespaces";
import FullPage from "components/Layout/FullPage";
import GlobalContext from "../../context/global-context";

const title = "Digital Signatures";
const metaTitle = title;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Heading = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 30px;
  line-height: 38px;
  color: var(--colors-mainText);
  margin-bottom: 16px;
`;
const Description = styled.div`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--colors-surface-600);
  margin-bottom: 40px;
`;
const PeriodsBlock = styled.div`
  border: 2px solid #dde5ee;
  border-radius: var(--shapes-border-radius-default);
  width: 400px;
`;
const PeriodsRow = styled(Link)`
  width: 100%;
  height: 100px;
  padding: 24px 24px 24px 68px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:before {
    left: 24px;
    top: 24px;
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.846 10.846a.77.77 0 100-1.538.77.77 0 000 1.538z' fill='%23A1B2CF'/%3E%3Cpath d='M18.615 3.538h-1V2.77a.77.77 0 10-1.538 0v.77h-3.5v-.77a.77.77 0 10-1.538 0v.77H7.577v-.77a.77.77 0 10-1.539 0v.77h-.961A3.08 3.08 0 002 6.614v12a3.08 3.08 0 003.077 3.077h5.884a.77.77 0 100-1.538H5.077a1.54 1.54 0 01-1.539-1.539v-12c0-.848.69-1.538 1.539-1.538h.961v.77a.77.77 0 101.539 0v-.77h3.462v.77a.77.77 0 101.538 0v-.77h3.5v.77a.77.77 0 001.538 0v-.77h1c.849 0 1.539.69 1.539 1.538V11a.77.77 0 001.538 0V6.615a3.08 3.08 0 00-3.077-3.077z' fill='%23A1B2CF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.385 17.038a4.66 4.66 0 014.654-4.654 4.66 4.66 0 014.653 4.654 4.66 4.66 0 01-4.653 4.654 4.66 4.66 0 01-4.654-4.654zm1.538 0a3.119 3.119 0 003.116 3.116 3.119 3.119 0 003.115-3.116 3.119 3.119 0 00-3.115-3.115 3.119 3.119 0 00-3.116 3.115z' fill='%23A1B2CF'/%3E%3Cpath d='M18.154 16.27h-.346v-.808a.77.77 0 00-1.539 0v1.576c0 .425.344.77.77.77h1.115a.77.77 0 000-1.539zM13.5 10.846a.77.77 0 100-1.538.77.77 0 000 1.538zM10.154 14.192a.77.77 0 100-1.538.77.77 0 000 1.538zM6.808 10.846a.77.77 0 100-1.538.77.77 0 000 1.538zM6.808 14.192a.77.77 0 100-1.538.77.77 0 000 1.538zM6.808 17.538a.77.77 0 100-1.538.77.77 0 000 1.538zM10.154 17.538a.77.77 0 100-1.538.77.77 0 000 1.538zM10.154 10.846a.77.77 0 100-1.538.77.77 0 000 1.538z' fill='%23A1B2CF'/%3E%3C/svg%3E");
  }
  &.has-border {
    border-bottom: 2px solid #dde5ee;
  }
`;
const PeriodRowTitle = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-surface-600);
  margin-bottom: 6px;
  text-transform: uppercase;
`;
const PeriodRowDates = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 20px;
  line-height: 25px;
  color: var(--colors-surface-600);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
class MyDigitalSignatures extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
    };
    document.title = getTitle(t(metaTitle));
  }
  componentDidMount() {
    this.getState();
  }
  getState = async () => {
    this.setState({ isFetching: true });
    const company = await this.context.getCompany();
    const digitalSignatures = await getMyDigitalSignatures({
      companyUuid: company.uuid,
      userProfileUuid: window.global_store.profile.uuid,
    });

    this.setState({
      items: digitalSignatures ? digitalSignatures.content : [],
      isFetching: false,
    });
  };

  render() {
    const { items, isFetching } = this.state;
    const { t } = this.props;

    return (
      <FullPage minimal>
        <Wrapper>
          {isFetching ? (
            <div>{t("common|Loading...")}</div>
          ) : (
            <Container>
              <Heading>{t("Select your Report")}</Heading>
              <Description>{t("You have few periods avaiable to sign")}</Description>
              <PeriodsBlock>
                {items.map((period, i) => (
                  <PeriodsRow
                    key={period.uuid}
                    className={i < items.length - 1 ? "has-border" : ""}
                    to={`/my-digital-signatures/${period.uuid}/${period.reportHash}`}
                  >
                    <PeriodRowTitle>{t("Period")}</PeriodRowTitle>
                    <PeriodRowDates>
                      {`${moment(period.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} - ${moment(
                        period.endDate,
                        "YYYY-MM-DD",
                      ).format("DD/MM/YYYY")}`}
                    </PeriodRowDates>
                  </PeriodsRow>
                ))}
              </PeriodsBlock>
            </Container>
          )}
        </Wrapper>
      </FullPage>
    );
  }
}
export default withRouter(withTranslation(TranslationNamespaces.payment)(MyDigitalSignatures));
