import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import styled from "styled-components";
import SearchControl from "components/UI/SearchControlNew";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { fireEvent, hasEmployeesAccess, urlParam } from "utils/common";
import { SearchObject } from "types/common";
import { v4 as uuidv4 } from "uuid";
import { TranslationNamespaces } from "types/translationNamespaces";
import CONFIG from "config";
import { RequestUserProfile } from "types/models/userProfile";
import { getEmployeesByUUIDs } from "utils/api/employee";
import UserProfileHelpers from "utils/userProfile.helpers";
import { dowDict } from "utils/get-week-start";
import { GlobalStoreCompany } from "types/models/company";
import { PermissionSectionName } from "types/models/permissions";
import { translateEmployeeTerm } from "utils/translationHelpers";
import TimesheetDateRangePicker from "../components/TimesheetDateRangePicker";
import TimesheetTable from "./TimesheetTable";
import { TimesheetContext } from "../timesheets.context";

const Wrapper = styled.div`
  width: 100%;
  margin: 20px auto 0;
`;

const Filters = styled.div`
  width: 100%;
  margin-bottom: 17px;
`;

export const SearchControlWrapper = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  max-width: 300px;
  min-width: 300px;
`;

const DateRangePickerWrapper = styled.div`
  align-self: center;
  margin-inline-start: 20px;
  display: inline-block;
  vertical-align: middle;
`;

type ParsedUrlParam = string | null;

const updateUrlWithParams = (
  {
    from,
    to,
    userProfileUuid,
  }: {
    from: moment.Moment;
    to: moment.Moment;
    userProfileUuid: string;
  },
  history: RouteComponentProps["history"],
) => {
  const params = [];
  params.push(`from=${from.format(CONFIG.apiDateFormat)}`);
  params.push(`to=${to.format(CONFIG.apiDateFormat)}`);
  params.push(`user_profile_uuid=${userProfileUuid}`);
  history.push(`?${params.join("&")}`);
};

function getWeekStartDate() {
  const firstDayOfWeek = dowDict[(window.global_store.company as GlobalStoreCompany)?.timesheet_start_day];
  const today = moment();
  const dayOfWeek = today.weekday();
  return today.subtract(Math.abs(dayOfWeek - firstDayOfWeek), "days");
}

export default (): JSX.Element => {
  const { t } = useTranslation(TranslationNamespaces.timesheets);
  const history = useHistory();

  const timesheetContext = useContext(TimesheetContext);
  let paramsFromUrl: {
    from?: ParsedUrlParam;
    to?: ParsedUrlParam;
    userProfileUuid?: ParsedUrlParam;
  } = {};

  if (hasEmployeesAccess() || urlParam("user_profile_uuid") === window.global_store.profile.uuid) {
    paramsFromUrl = {
      from: urlParam("from") as ParsedUrlParam,
      to: urlParam("to") as ParsedUrlParam,
      userProfileUuid: urlParam("user_profile_uuid") as ParsedUrlParam,
    };
  }

  const [from, setFrom] = useState(
    paramsFromUrl.from ? moment(paramsFromUrl.from, CONFIG.apiDateFormat) : getWeekStartDate(),
  );
  const [to, setTo] = useState(from.clone().add(1, "week").subtract(1, "day"));

  const [userProfile, setUserProfile] = useState<RequestUserProfile>({
    uuid: paramsFromUrl.userProfileUuid ? paramsFromUrl.userProfileUuid : window.global_store.profile.uuid,
    name: paramsFromUrl.userProfileUuid ? "" : window.global_store.profile.full_name,
    id: paramsFromUrl.userProfileUuid ? "" : window.global_store.profile.id,
  });

  const handleEmployeeSearch = (val: SearchObject) => {
    if (val?.employee?.uuid) {
      setUserProfile(val.employee as RequestUserProfile);
      updateUrlWithParams(
        {
          from,
          to,
          userProfileUuid: val.uuid as string,
        },
        history,
      );
    }
  };

  const handleDatesChange = (startDate: moment.Moment, endDate: moment.Moment) => {
    setFrom(startDate);
    setTo(endDate);
    updateUrlWithParams(
      {
        from: startDate,
        to: endDate,
        userProfileUuid: userProfile.uuid,
      },
      history,
    );
  };

  const getEmployee = async (uuid: string) => {
    try {
      const { user_profiles } = await getEmployeesByUUIDs({ uuids: [uuid] });
      const employee = UserProfileHelpers.formatUserProfile(user_profiles[0]);

      if (!employee) {
        fireEvent("timesheet_message", {
          uuid: uuidv4(),
          message: t("Failed to get employee details"),
          status: "error",
        });
      } else {
        setUserProfile(employee as unknown as RequestUserProfile);
      }
    } catch (error) {
      fireEvent("timesheet_message", { uuid: uuidv4(), message: t("Failed to get employee details"), status: "error" });
    }
  };

  useEffect(() => {
    if (Object.values(paramsFromUrl).indexOf(null) > -1) {
      updateUrlWithParams(
        {
          from,
          to,
          userProfileUuid: userProfile.uuid,
        },
        history,
      );
    }
    if (userProfile?.uuid) {
      void getEmployee(userProfile.uuid);
    }
  }, []);

  useEffect(() => {
    void timesheetContext.setUserProfile(userProfile);
  }, [userProfile]);

  return (
    <Wrapper>
      <Filters>
        <SearchControlWrapper>
          <SearchControl
            permissionSection={PermissionSectionName.activities}
            value={userProfile.fullName}
            id={userProfile?.id as number}
            withNavigateControls
            onChange={handleEmployeeSearch}
            placeholder={translateEmployeeTerm(
              t,
              TranslationNamespaces.common,
              "custom-search-employees",
              `${TranslationNamespaces.common}|Search Employee`,
            )}
          />
        </SearchControlWrapper>
        <DateRangePickerWrapper>
          <TimesheetDateRangePicker onChange={handleDatesChange} startDate={from} endDate={to} />
        </DateRangePickerWrapper>
      </Filters>
      <TimesheetTable startDate={from} endDate={to} userProfile={userProfile} />
    </Wrapper>
  );
};
