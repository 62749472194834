import { useState } from "react";
import { stylesheet } from "astroturf";
import { ReactComponent as CrossIcon } from "./cross.svg";

const styles = stylesheet`@import "./InputTagControl.scss"`;

type InputTagControlProps = {
  placeholder?: string;
  tags: string[];
  setTags: (values: string[]) => void;
};

export const InputTagControl = ({placeholder, tags, setTags}: InputTagControlProps) => {
  const [input, setInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === 'Enter' || key === ',') && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags([...tags, trimmedInput]);
      setInput('');
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag || '');
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const deleteTag = (index: number) => {
    setTags(tags.filter((tag, i) => i !== index))
  }

  return (
    <div className={styles.container}>
      {tags.map((tag, index) => (
        <div key={tag} className={styles.tag}>
          {tag}
          <button type="button" onClick={() => deleteTag(index)}><CrossIcon /></button>
        </div>
      ))}
      <input
        value={input}
        placeholder={placeholder || ''}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  );
}
