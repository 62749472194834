import { useState } from "react";
import { TranslationNamespaces, useTranslation } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import cx from "classnames";
import { PunchTypeObject } from "services/punch-service/types";
import TimeText from "../TimeText";
import LocationText from "../LocationText";
import { IconSuccessBig, IconExclamationBig, SpinnerSmall, AnimationConfetti } from "../styled";
import { Button, ButtonType } from "../Button";
import DropdownButton from "../DropdownButton";
import { Modal } from "../Modal";
import PunchTypeSelector, { PunchTypeSelectorProps } from "../PunchTypeSelector";

const styles = stylesheet`
  .Wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    .Top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: inherit;
      max-height: 222px;
      padding-top: 32px;

      * {
        transition: none;
      }

      svg {
        position: relative;
      }

      .Anim {
        position: absolute;
        top: -110px;
        transition: none;
      }

      .Header {
        font-size: 26px;
        font-weight: var(--typography-font-weight-medium);
        color: #525f7f;
        text-align: center;
      }
    }
  }

  .Middle {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow:1;
    align-items: center;
    justify-content: center;
    max-height: 270px;
    padding-top: 15px;
    padding-bottom: 12px;

    .PunchType {
      min-width: 130px;
      height: 30px;
      font-size: var(--typography-font-size-default);
      font-weight: var(--typography-font-weight-medium);
      text-transform: uppercase;
      letter-spacing: +0.03em;
    }

    .Time {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  .Bottom {
    display: flex;
    flex-direction: column;
    flex-grow:1;
    justify-content: flex-start;
    width: 300px;
    max-height: 90px;
    padding-bottom: 20px;

    button {
      height: 40px;
    }

  }

  .PunchTypeSelectorModal  {
    > * {
      display: flex;
      max-height: 80%;
    }
  }

  @use 'styles/loading';

  .Wrapper:global(.wireframe) {
    .Top {
      > svg {
        @extend .wireframe;

        border-radius: 50%;
      }

      .Header {
        @extend .wireframe;

        width: 200px;
      }
    }

    .Middle {
      > * {
        @extend .wireframe;

        &:first-child {
          width: 60px;
          border-radius: 50px;
        }

        &:last-child {
          width: 190px;
          height: 36px;
        }
      }
    }

    .Bottom {
      button {
        @extend .wireframe;

        border-radius: 50px;
      }
    }
  }
`;

export interface PunchFinishedProps extends React.HTMLProps<HTMLDivElement> {
  loading: boolean;
  wireframe: boolean;
  employeeName: string;
  needsApproval: boolean;
  createdAt: {
    date: Date | number;
    timezone: string;
  };
  location: {
    name: string;
    address: string;
  };
  selectedPunchType: PunchTypeSelectorProps["selectedOption"];
  punchTypes: PunchTypeSelectorProps["options"];
  /** emit null if the dialog was opened and closed with no option selected for tracking purposes */
  onPunchTypeSelected: (option: PunchTypeObject | null) => void;
  onConfirm: () => void;
}

export function PunchFinished(props: PunchFinishedProps) {
  const {
    loading,
    wireframe,
    employeeName,
    selectedPunchType,
    punchTypes,
    onPunchTypeSelected,
    needsApproval,
    createdAt,
    location,
    onConfirm,
    className,
    ...restProps
  } = props;
  const [t] = useTranslation(TranslationNamespaces.modalPunch);
  const [showSelectPunchType, setShowSelectPunchType] = useState<boolean>(false);
  return (
    <div {...restProps} className={cx(styles.Wrapper, { wireframe }, className)}>
      {needsApproval ? (
        <div className={styles.Top}>
          <IconExclamationBig />
          <div className={styles.Header}>{t("Your request was sent")}</div>
        </div>
      ) : (
        <div className={styles.Top}>
          {/* 
          TODO: fix this properly. temp fix for PB-1114 to disable animation if modal is shown
          {!wireframe && (
            <div className={styles.Anim}>
              <AnimationConfetti />
            </div>
          )} */}
          <IconSuccessBig />
          <div className={styles.Header}>{`${t("You rock,")} ${employeeName}!`}</div>
        </div>
      )}

      <div className={styles.Middle}>
        <DropdownButton
          disabled={loading}
          noOptions={punchTypes.length <= 1}
          className={styles.PunchType}
          onClick={() => setShowSelectPunchType(true)}
        >
          {t(`punch-type:${selectedPunchType?.type}`)}
        </DropdownButton>
        <TimeText className={styles.Time} {...createdAt} />
        <LocationText {...location} />
      </div>
      <div className={styles.Bottom}>
        {loading ? (
          <Button disabled buttonType={ButtonType.neutral}>
            <SpinnerSmall />
          </Button>
        ) : (
          <Button buttonType={needsApproval ? ButtonType.warning : ButtonType.regular} onClick={onConfirm}>
            {t("I'm done")}
          </Button>
        )}
      </div>
      {showSelectPunchType && (
        <Modal
          className={styles.PunchTypeSelectorModal}
          onBackgroundClick={() => {
            setShowSelectPunchType(false);
            onPunchTypeSelected(null);
          }}
        >
          <PunchTypeSelector
            options={punchTypes}
            selectedOption={selectedPunchType}
            onOptionSelected={(opt) => {
              setShowSelectPunchType(false);
              onPunchTypeSelected(opt);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
