import { Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import OnCallsPage from "components/OnCalls/OnCallsPage";

const RouteOnCalls = ({ match: { path } }: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Redirect exact strict from={path} to={`${path}/`} />

      <Route exact strict path={`${path}/`} component={OnCallsPage} />
    </Switch>
  </Suspense>
);

export default RouteOnCalls;
