import { Suspense } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import SsoLoginPage from "components/Onboarding/SsoLoginPage";

export default (props: RouteComponentProps) => (
  <Suspense fallback={<div />}>
    <Switch>
      <Route path="/sso-login" component={SsoLoginPage} />
    </Switch>
  </Suspense>
);
