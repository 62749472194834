import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import BEM from "utils/BEM";
import "./Button.scss";
import Button from ".";
import Overlay from "../Overlay";

const b = BEM.b("ui-button-confirm");

class ConfirmButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  showDialog(show = true) {
    this.setState({ isDialogOpen: show });
  }

  actionCancel() {
    const { onCancel } = this.props;
    if (typeof onCancel === "function") {
      onCancel();
    }
    this.showDialog(false);
  }

  actionConfirm() {
    const { onConfirm } = this.props;
    if (typeof onConfirm === "function") {
      onConfirm();
    }
    this.showDialog(false);
  }

  render() {
    const { title, onCancel, onConfirm, description, okLabel, cancelLabel, t, i18n, lng, tReady, ...props } =
      this.props;
    const { isDialogOpen } = this.state;
    return (
      <>
        <Button {...props} onClick={() => this.showDialog()} />
        <Overlay isOpen={isDialogOpen}>
          <div className={b("dialog-wrapper")}>
            {title && <h2 className={b("dialog-title")}>{title}</h2>}
            {description && <p className={b("dialog-description")}>{description}</p>}
            <div className={b("dialog-actions")}>
              <Button modifier="bordered" onClick={() => this.actionCancel()}>
                {cancelLabel || t("Cancel")}
              </Button>
              <Button onClick={() => this.actionConfirm()}>{okLabel || t("OK")}</Button>
            </div>
          </div>
        </Overlay>
      </>
    );
  }
}

export default withTranslation()(ConfirmButton);
