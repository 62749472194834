import StatusTag from "components/UI/StatusTag";

import BEM from "utils/BEM";
import "./Chip.scss";

const b = BEM.b("ui-chip");

/**
 * This component is depracated. Please use {@link StatusTag} instead.
 * @param {*} param0
 * @returns
 * @deprecated
 */
const Chip = ({ children, intent, ...props }) => (
  <span className={b("chip", { [intent]: intent })} {...props}>
    {children}
  </span>
);

export default Chip;
