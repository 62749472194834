import { Component, ContextType } from "react";
import styled from "styled-components";
import Button, { ButtonState } from "components/controls/StyledButton";
import CheckboxControl from "components/UI/NewCheckbox";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  addEmployeesToDepartment,
  addEmployeesToPosition,
  addEmployeesToSubsidiary,
  addEmployeesToTeam,
  addEmployeeToSchedule,
  assignEmployeeToPayrollGroup,
  assignUserToBRGroup,
  removeEmployeeFromAllSchedules,
} from "utils/apiHelpers";
import { assignUserProfiles } from "components/Holidays/holidayApiUtils";
import SimpleMenu from "components/UI/Select/SimpleMenu";
import GlobalContext from "context/global-context";
import { hasPermisionAccess, PermissionSectionName } from "utils/common";
import rtl from "styled-components-rtl";
import { assignDeviceSettingsToEmployees, assignLocationsToEmployees } from "utils/api/company";
import moment from "moment";
import { AssociateLocationsPopup } from "./components/AssociateLocationsPopup";
import OrganizationUnitChangePopup from "./OrganizationUnitChangePopup";
import GroupsChangePopup from "./GroupsChangePopup";
import { AssociateDeviceSettingsPopup } from "./components/AssociateDeviceSettingsPopup";
import { NotificationType } from "../../types/common";

const ActionsBarRow = styled.div`
  bottom: 5.12%;
  position: fixed;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  ${rtl`
    left: 0;
  `}
`;

const ActionsBarWrapper = styled.div`
  height: 60px;
  max-width: 693px;
  width: 100%;
  background: var(--colors-default);
  box-shadow: 0 5px 30px rgba(129, 147, 171, 0.2);
  border-radius: 58px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px;

  .ui-select__wrapper > div {
    margin: 0;
  }
`;

const Badge = styled.div`
  margin-inline-start: 12px;
  display: flex;
  height: 24px;
  padding: 0 8px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--colors-surface-150);
  background: var(--colors-surface-50);
  overflow: hidden;
  color: var(--colors-surface-900-p);
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: var(--typography-font-weight-default);
  line-height: 100%;
`;

const SlecetedCount = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.span`
  font-weight: var(--typography-font-weight-medium);
  line-height: 18px;
  font-size: var(--text-size-default);
  color: var(--colors-text1);
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ui-select__dropdown-wrapper {
    max-height: 350px;
  }
`;

interface ActionsBarProps extends WithTranslation {
  onDone: (data: { notification: string; notificationType: NotificationType }) => void;
  onUncheckAll: () => void;
  selectedEmployees: string[];
  isAllCompany: boolean;
  disabled: boolean;
}

interface ActionsBarState {
  actionType: string;
}

enum ActionType {
  Schedules = "schedules",
  BusinessRuleGroups = "businessRulesGroups",
  PayrollGroups = "payrollGroups",
  HolidayGroups = "holidayGroups",
  Teams = "teams",
  Locations = "locations",
  OrgUnit = "organizationUnit",
  PunchSettings = "punchSettings",
}

interface BasePayload {
  selectedGroup: string;
  selectedEmployees: string[];
  actionType: ActionType;
}

enum AssignActionType {
  assignBusinessRules = "assignBusinessRules",
  assignPayrollGroup = "assignPayrollGroup",
  assignHolidayGroup = "assignHolidayGroup",
  assignSchedule = "assignSchedule",
  assignOrganizationUnit = "assignOrganizationUnit",
  locations = "locations",
  punchSettings = "punchSettings",
  assignTeam = "assignTeam",
}

interface BasePayloadWithDate extends BasePayload {
  startDate: moment.Moment;
}

class ActionsBar extends Component<ActionsBarProps, ActionsBarState> {
  static contextType = GlobalContext;
  context!: ContextType<typeof GlobalContext>;
  state = {
    actionType: "",
  };

  sendNotification = (notification: string, notificationType: NotificationType) => {
    const { onDone } = this.props;
    onDone({ notification, notificationType });
  };

  changeGroup = async ({ selectedGroup, startDate, selectedEmployees, actionType }: BasePayloadWithDate) => {
    const { t } = this.props;
    switch (actionType) {
      case ActionType.Schedules:
        try {
          await this.changeSchedule({ selectedGroup, startDate, selectedEmployees });
          this.sendNotification(
            t("multi-action-schedule-success", { count: selectedEmployees.length }),
            NotificationType.progress,
          );
        } catch (error) {
          this.sendNotification(
            t("multi-action-schedule-failed", { count: selectedEmployees.length }),
            NotificationType.error,
          );
        }
        break;
      case ActionType.BusinessRuleGroups:
        try {
          await this.changeBusinessRulesGroup({ selectedGroup, startDate, selectedEmployees });
          this.sendNotification(
            t("multi-action-br-success", { count: selectedEmployees.length }),
            NotificationType.progress,
          );
        } catch (error) {
          this.sendNotification(
            t("multi-action-br-failed", { count: selectedEmployees.length }),
            NotificationType.error,
          );
        }
        break;
      case ActionType.PayrollGroups:
        try {
          await this.changePayrollGroup({ selectedGroup, selectedEmployees });
          this.sendNotification(
            t("multi-action-payroll-success", { count: selectedEmployees.length }),
            NotificationType.progress,
          );
        } catch (error) {
          this.sendNotification(
            t("multi-action-payroll-failed", { count: selectedEmployees.length }),
            NotificationType.error,
          );
        }
        break;
      case ActionType.HolidayGroups:
        try {
          await this.changeHolidayGroup({ selectedGroup, startDate, selectedEmployees });
          this.sendNotification(
            t("multi-action-holiday-success", { count: selectedEmployees.length }),
            NotificationType.progress,
          );
        } catch (error) {
          this.sendNotification(
            t("multi-action-holiday-failed", { count: selectedEmployees.length }),
            NotificationType.error,
          );
        }
        break;
      case ActionType.Teams:
        try {
          await this.changeTeam({ selectedGroup, selectedEmployees });
          this.sendNotification(
            t("multi-action-team-success", { count: selectedEmployees.length }),
            NotificationType.progress,
          );
        } catch (error) {
          this.sendNotification(
            t("multi-action-team-failed", { count: selectedEmployees.length }),
            NotificationType.error,
          );
        }
        break;
      default:
        break;
    }
  };

  changeSchedule = async ({ selectedGroup, selectedEmployees, startDate }: Omit<BasePayloadWithDate, "actionType">) => {
    const company = await this.context.getCompany();
    if (selectedGroup === "remove") {
      await removeEmployeeFromAllSchedules({
        body: {
          content: {
            userProfileUuids: selectedEmployees,
            endDate: startDate.format("YYYY-MM-DD"),
            updatedBy: window.global_store.profile.uuid,
          },
        },
        companyUuid: company.uuid,
      });
    } else {
      await addEmployeeToSchedule({
        companyUUID: company.uuid,
        scheduleUuid: selectedGroup,
        body: {
          content: {
            userProfileUuids: selectedEmployees,
            startDate: startDate.format("YYYY-MM-DD"),
            createdBy: window.global_store.profile.uuid,
          },
        },
      });
    }
  };

  changeLocations = async (locationsUuids: string[]) => {
    const company = await this.context.getCompany();
    const { t } = this.props;
    try {
      await assignLocationsToEmployees(company.uuid, {
        userProfilesUuids: this.props.selectedEmployees,
        requestedBy: window.global_store.profile.uuid,
        locationsUuids,
      });
      this.sendNotification(
        t("multi-action-locations-success", { count: this.props.selectedEmployees.length }),
        NotificationType.progress,
      );
    } catch (error) {
      this.sendNotification(
        t("multi-action-locations-failed", { count: this.props.selectedEmployees.length }),
        NotificationType.error,
      );
    }
  };

  changePunchSettings = async (settings: any) => {
    const company = await this.context.getCompany();
    const { t } = this.props;
    try {
      await assignDeviceSettingsToEmployees(company.uuid, {
        userProfilesUuids: this.props.selectedEmployees,
        requestedBy: window.global_store.profile.uuid,
        settings,
      });
      this.sendNotification(
        t("multi-action-punch-settings-success", { count: this.props.selectedEmployees.length }),
        NotificationType.progress,
      );
    } catch (error) {
      this.sendNotification(
        t("multi-action-punch-settings-failed", { count: this.props.selectedEmployees.length }),
        NotificationType.error,
      );
    }
  };

  changeBusinessRulesGroup = async ({
    selectedGroup,
    selectedEmployees,
    startDate,
  }: Omit<BasePayloadWithDate, "actionType">) => {
    const company = await this.context.getCompany();
    await assignUserToBRGroup({
      companyUuid: company.uuid,
      brGroupUuid: selectedGroup,
      startDate,
      body: {
        content: {
          userProfileUuids: selectedEmployees,
          createdBy: window.global_store.profile.uuid,
        },
      },
    });
  };

  changePayrollGroup = async ({ selectedGroup, selectedEmployees }: Omit<BasePayload, "actionType">) => {
    const company = await this.context.getCompany();
    await assignEmployeeToPayrollGroup({
      companyUuid: company.uuid,
      payrollGroupUuid: selectedGroup,
      body: {
        content: {
          userProfileUuids: selectedEmployees,
          createdBy: window.global_store.profile.uuid,
        },
      },
    });
  };

  changeHolidayGroup = async ({
    selectedGroup,
    selectedEmployees,
    startDate,
  }: Omit<BasePayloadWithDate, "actionType">) => {
    const company = await this.context.getCompany();
    await assignUserProfiles({
      companyUuid: company.uuid,
      holidaysGroupUuid: selectedGroup,
      body: {
        content: {
          startDate: startDate.format("YYYY-MM-DD"),
          userProfileUuids: selectedEmployees,
          createdBy: window.global_store.profile.uuid,
        },
      },
    });
  };

  changeOrganizationUnitGroup = async (
    selectedUnit: "department" | "position" | "subsidiary",
    selectedNameUuid: string,
  ) => {
    const { t, selectedEmployees } = this.props;
    const data = {
      uuid: selectedNameUuid,
      body: {
        employee_uuids: selectedEmployees,
      },
    };

    const factoryMethod = {
      department: addEmployeesToDepartment,
      position: addEmployeesToPosition,
      subsidiary: addEmployeesToSubsidiary,
    };

    try {
      await factoryMethod[selectedUnit](data);
      this.sendNotification(
        t("multi-action-ou-success", { count: selectedEmployees.length }),
        NotificationType.progress,
      );
    } catch (error) {
      this.sendNotification(t("multi-action-ou-failed", { count: selectedEmployees.length }), NotificationType.error);
    }
  };

  changeTeam = async ({ selectedGroup, selectedEmployees }: Omit<BasePayload, "actionType">) => {
    await addEmployeesToTeam({
      teamId: selectedGroup,
      body: {
        member_uuids: selectedEmployees,
      },
    });
  };

  getOptions = () => {
    const { t } = this.props;
    const options = [];

    if (hasPermisionAccess(PermissionSectionName.rulesAndLimits)) {
      options.push({
        value: AssignActionType.assignBusinessRules,
        label: t("Assign Business Rules"),
      });
    }
    if (hasPermisionAccess(PermissionSectionName.payroll)) {
      options.push({ value: AssignActionType.assignPayrollGroup, label: t("Assign Payroll Groups") });
    }
    if (hasPermisionAccess(PermissionSectionName.holidays)) {
      options.push({ value: AssignActionType.assignHolidayGroup, label: t("Assign Holiday Groups") });
    }
    if (hasPermisionAccess(PermissionSectionName.schedules)) {
      options.push({ value: AssignActionType.assignSchedule, label: t("Assign Schedules") });
    }
    if (
      hasPermisionAccess(PermissionSectionName.departments) ||
      hasPermisionAccess(PermissionSectionName.subsidiaries) ||
      hasPermisionAccess(PermissionSectionName.positions)
    ) {
      options.push({ value: AssignActionType.assignOrganizationUnit, label: t("Assign Organization Unit") });
    }
    if (hasPermisionAccess(PermissionSectionName.locations)) {
      options.push({ value: AssignActionType.locations, label: t("Assign Locations") });
    }

    options.push({ value: AssignActionType.punchSettings, label: t("Set device settings") });

    options.push({ value: AssignActionType.assignTeam, label: t("Assign Team") });

    return options;
  };

  render = () => {
    const { t, onUncheckAll, selectedEmployees, isAllCompany, disabled } = this.props;
    const { actionType } = this.state;
    if (!selectedEmployees.length) {
      return null;
    }
    const options = this.getOptions();

    return (
      <ActionsBarRow>
        <ActionsBarWrapper>
          <SlecetedCount>
            <CheckboxControl
              disabled={disabled}
              label={
                <CheckboxLabel>
                  {t("Selected")} {selectedEmployees.length}
                </CheckboxLabel>
              }
              checked
              onChange={onUncheckAll}
            />
            {isAllCompany ? <Badge>{t("All members")}</Badge> : null}
          </SlecetedCount>
          <ActionButtons>
            {!!options.length && (
              <SimpleMenu
                disabled={disabled}
                options={options}
                onChange={(val) => {
                  switch (val) {
                    case AssignActionType.assignBusinessRules:
                      this.setState({ actionType: ActionType.BusinessRuleGroups });
                      break;
                    case AssignActionType.assignPayrollGroup:
                      this.setState({ actionType: ActionType.PayrollGroups });
                      break;
                    case AssignActionType.assignHolidayGroup:
                      this.setState({ actionType: ActionType.HolidayGroups });
                      break;
                    case AssignActionType.assignSchedule:
                      this.setState({ actionType: ActionType.Schedules });
                      break;
                    case AssignActionType.assignOrganizationUnit:
                      this.setState({ actionType: ActionType.OrgUnit });
                      break;
                    case AssignActionType.assignTeam:
                      this.setState({ actionType: ActionType.Teams });
                      break;
                    case AssignActionType.locations:
                      this.setState({ actionType: ActionType.Locations });
                      break;
                    case AssignActionType.punchSettings:
                      this.setState({ actionType: ActionType.PunchSettings });
                      break;
                    default: {
                      break;
                    }
                  }
                }}
              >
                <Button
                  state={ButtonState.secondary}
                  value={t("Actions")}
                  onClick={() => {}}
                  style={{ width: "110px" }}
                />
              </SimpleMenu>
            )}
          </ActionButtons>
        </ActionsBarWrapper>
        {actionType && actionType !== ActionType.OrgUnit && (
          <GroupsChangePopup
            type={actionType}
            onClose={(uncheck: boolean) => {
              this.setState({ actionType: "" });
              if (uncheck) {
                onUncheckAll();
              }
            }}
            onYes={async ({ selectedGroup, startDate }: BasePayloadWithDate) => {
              await this.changeGroup({
                selectedGroup,
                startDate,
                selectedEmployees,
                actionType,
              } as BasePayloadWithDate);
            }}
          />
        )}
        {actionType === ActionType.OrgUnit && (
          <OrganizationUnitChangePopup
            onClose={(uncheck: boolean) => {
              this.setState({ actionType: "" });
              if (uncheck) {
                onUncheckAll();
              }
            }}
            onYes={this.changeOrganizationUnitGroup}
          />
        )}
        {actionType === ActionType.Locations && (
          <AssociateLocationsPopup
            onClose={(uncheck) => {
              this.setState({ actionType: "" });
              if (uncheck) {
                onUncheckAll();
              }
            }}
            onYes={this.changeLocations}
          />
        )}
        {actionType === ActionType.PunchSettings && (
          <AssociateDeviceSettingsPopup
            onClose={(uncheck) => {
              this.setState({ actionType: "" });
              if (uncheck) {
                onUncheckAll();
              }
            }}
            onYes={this.changePunchSettings}
          />
        )}
      </ActionsBarRow>
    );
  };
}

export default withTranslation("employees-page")(ActionsBar);
