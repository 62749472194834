import { Component, Ref } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import Dropzone, { DropFilesEventHandler } from "react-dropzone";
import styled from "styled-components";
import axios, { CancelTokenSource } from "axios";
import { AllFlagsLDClient, withLDConsumer } from "launchdarkly-react-client-sdk";
import SettingsLayout from "components/Layout/SettingsLayout";
import { PageWrapper, TableButton, TableButtons } from "components/styled/Page";
import HeaderActionButton from "components/controls/HeaderActionButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import { iColumn } from "components/TableCommon";
import { ScheduleFileImport, ScheduleFileImportStatus } from "types/models/schedule";
import SearchInput from "components/UI/SearchInput";
import NoContent from "components/NoContent";
import TablePage from "components/TablePage";
import sentryUtils from "utils/sentryUtils";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { iCellInfo, getSelectedColumns } from "utils/tableHelpers";
import ProgressBar from "components/ProgressBar";
import { downloadScheduleImportErrorLog, getScheduleImports, importSchedule } from "utils/apiHelpers";
import NotificationRow from "components/NotificationRow";
import { NotificationType } from "types/common";
import noImportedFiles from "../../img/no-imported-files.png";
import dropScheduleImport from "../../img/drop-schedule-import.png";

const StatusCell = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 0;

  .buttons {
    margin: 0;
  }
`;

const ModalContent = styled.div<{ uploadCompleted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .schedule-import-dropzone {
    margin-top: 24px;
    width: 100%;
    background-color: ${(p) => (p.uploadCompleted ? "var(--colors-success-600-p)" : "var(--colors-surface-50)")};
    border: 2px solid ${(p) => (p.uploadCompleted ? "var(--colors-success-600-p)" : "var(--colors-surface-150)")};

    cursor: pointer;
  }
`;

const ModalSubtitle = styled.div`
  width: 180px;
  text-align: center;
  color: var(--colors-surface-400);
`;

const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 287px;
  color: var(--colors-surface-900-p);

  img {
    margin-bottom: 24px;
  }
`;

const Filename = styled.div`
  width: 270px;
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 270px;
  margin: 12px 0 4px;

  .progress-bar__bar-wrapper {
    width: 100%;
    bottom: 0;
    margin: 0;
  }
`;

const UploadMessage = styled.div`
  width: 270px;
  font-size: 12px;
  line-height: 26px;
`;

const ClickableText = styled.span`
  color: var(--colors-primary-500-p);
  cursor: pointer;
`;

interface ScheduleImportProps extends WithTranslation, RouteComponentProps, AllFlagsLDClient {}

interface ScheduleImportState {
  showImportModal: boolean;
  files: ScheduleFileImport[];
  isLoading: boolean;
  selectedColumns: string;
  searchValue: string;
  fileToUpload: File | null;
  cancelToken: CancelTokenSource | null;
  uploadPercentCompleted: number | null;
  notification: string | null;
  notificationType: NotificationType | null;
  isSomeFileProcessing: boolean;
}

class ScheduleImport extends Component<ScheduleImportProps, ScheduleImportState> {
  dropzoneRef: Ref<Dropzone> = null;

  constructor(props: ScheduleImportProps) {
    super(props);

    if (!props.flags.importShifts) {
      props.history.push("/");
    }

    this.state = {
      showImportModal: false,
      files: [],
      isLoading: true,
      selectedColumns: getSelectedColumns("file_name,created_at,manager,import_type,status", "ScheduleImport"),
      searchValue: "",
      fileToUpload: null,
      cancelToken: null,
      uploadPercentCompleted: null,
      notification: null,
      notificationType: null,
      isSomeFileProcessing: false,
    };
  }

  componentDidMount() {
    void this.getState();
  }

  handleAPIError = (e: unknown) => {
    sentryUtils.sendError(e);

    this.setState({
      notification: (e as Error).message || (e as any),
      notificationType: NotificationType.error,
    });
  };

  getState = async () => {
    try {
      const { file_imports } = await getScheduleImports();

      this.setState({
        files: file_imports,
        isSomeFileProcessing: file_imports.some((fi) => fi.status === ScheduleFileImportStatus.inProgress),
      });
    } catch (e) {
      this.handleAPIError(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getTableColumns = (): iColumn<ScheduleFileImport>[] => {
    const { t } = this.props;

    return [
      { label: t("File name"), accessor: "file_name", locked: true },
      {
        label: t(`${TranslationNamespaces.common}|Date`),
        accessor: "created_at",
        Cell: ({ value }: iCellInfo<ScheduleFileImport, string>) =>
          value ? moment(value).format("DD/MM/YYYY HH:mm") : "",
      },
      {
        label: t(`${TranslationNamespaces.common}|User`),
        accessor: "manager",
        Cell: ({ value }: iCellInfo<ScheduleFileImport, ScheduleFileImport["manager"]>) => value.name,
      },
      { label: t(`${TranslationNamespaces.common}|Type`), accessor: "import_type" },
      {
        label: t(`${TranslationNamespaces.common}|Status`),
        accessor: "status",
        locked: true,
        Cell: ({ value, original }: iCellInfo<ScheduleFileImport, ScheduleFileImportStatus>) => {
          const statusBarType = {
            [ScheduleFileImportStatus.imported]: StatusBadgeStatuses.success,
            [ScheduleFileImportStatus.inProgress]: StatusBadgeStatuses.warn,
            [ScheduleFileImportStatus.failed]: StatusBadgeStatuses.declined,
          }[value];
          return (
            <StatusCell>
              <StatusBadge value={t(value)} type={statusBarType} />

              {value === ScheduleFileImportStatus.failed && (
                <TableButtons className="buttons">
                  <TableButton
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();

                      void this.onTableButtonClick(original);
                    }}
                  >
                    {t("View Errors")}
                  </TableButton>
                </TableButtons>
              )}
            </StatusCell>
          );
        },
      },
    ];
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ScheduleImport", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  onTableButtonClick = async (fileImport: ScheduleFileImport) => {
    try {
      await downloadScheduleImportErrorLog({
        fileImportUuid: fileImport.uuid,
        filename: `${fileImport.file_name}_error_log`,
      });
    } catch (e) {
      this.handleAPIError(e);
    }
  };

  onCloseModal = () => {
    const { cancelToken } = this.state;
    if (cancelToken) {
      cancelToken.cancel();
    }

    this.setState({ showImportModal: false, fileToUpload: null, cancelToken: null, uploadPercentCompleted: null });
  };

  onDrop: DropFilesEventHandler = (droppedFiles) => {
    const { fileToUpload } = this.state;

    droppedFiles.forEach((file) => {
      if (!fileToUpload) {
        const cancelToken = axios.CancelToken.source();

        this.setState({ fileToUpload: file, uploadPercentCompleted: 0, cancelToken }, async () => {
          try {
            await importSchedule({ file }, this.onUploadProgress, cancelToken.token);
          } catch (e) {
            this.handleAPIError(e);
          } finally {
            this.setState({ cancelToken: null });
          }
        });
      }
    });
  };

  onUploadProgress = (progressEvent: { loaded: number; total: number }) => {
    const uploadPercentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

    this.setState({ uploadPercentCompleted });
  };

  filterBySearch = (f: ScheduleFileImport) => {
    const { searchValue } = this.state;

    return [f.file_name, f.manager.name].join(" ").toLowerCase().includes(searchValue.trim().toLowerCase());
  };

  render() {
    const { t, history } = this.props;
    const {
      fileToUpload,
      files,
      isLoading,
      selectedColumns,
      searchValue,
      showImportModal,
      uploadPercentCompleted,
      notification,
      notificationType,
      isSomeFileProcessing,
    } = this.state;

    const filesToShow = searchValue ? files.filter(this.filterBySearch) : files;

    return (
      <SettingsLayout
        title={t("Import Files")}
        headerAction={
          <HeaderActionButton
            title={t("Import")}
            onClick={() => {
              this.setState({ showImportModal: true });
            }}
          />
        }
        backButtonOnclick={() => history.push("/schedules/")}
        backButtonTitle={t("Schedules")}
      >
        <PageWrapper>
          {(notification || isSomeFileProcessing) && (
            <NotificationRow
              message={
                notification ||
                (isSomeFileProcessing
                  ? t("We are importing your file. This process might take a few minutes. Please refresh this page.")
                  : null)
              }
              type={notificationType || (isSomeFileProcessing ? NotificationType.warning : null)}
            />
          )}

          <TablePage<ScheduleFileImport>
            columnSelectorOnFiltersRow
            selectedColumns={selectedColumns.split(",")}
            onColumnsChange={this.onColumnsChange}
            filters={
              <SearchInput
                modifiers={["filter"]}
                onChange={(ev) => this.setState({ searchValue: ev.target.value })}
                placeholder={t("Search")}
                value={searchValue}
              />
            }
            manual
            rows={filesToShow}
            columns={this.getTableColumns()}
            loading={isLoading}
            getTrProps={(_, rowInfo) => ({
              style: {
                cursor: "default",
                background: "inherit",
              },
            })}
            noContentComponent={<NoContent img={noImportedFiles}>{t("No files have been imported yet")}</NoContent>}
          />

          <ModalDialog isOpen={showImportModal} onClose={this.onCloseModal}>
            <Lightbox
              onClose={this.onCloseModal}
              yesDisabled={uploadPercentCompleted !== 100}
              onYes={() => this.setState({ isSomeFileProcessing: true }, this.onCloseModal)}
              buttonYesTitle={uploadPercentCompleted === 100 ? t("Ok") : t("Import")}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              title={t("Import shift")}
              noCancelButton={!!uploadPercentCompleted}
            >
              <ModalContent uploadCompleted={uploadPercentCompleted === 100}>
                <ModalSubtitle>
                  {`${t("Please prepare your xlsx file in the")} `}
                  <ClickableText>{t("correct format.")}</ClickableText>
                </ModalSubtitle>

                <Dropzone
                  ref={this.dropzoneRef}
                  multiple={false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  className="schedule-import-dropzone"
                  onDrop={this.onDrop}
                  preventDropOnDocument
                  disableClick={!!uploadPercentCompleted}
                >
                  <DropzoneContent>
                    {uploadPercentCompleted === null ? (
                      <>
                        <img src={dropScheduleImport} />
                        <ModalSubtitle>
                          <ClickableText>{t("Click to browse")}</ClickableText>
                          {` ${t("or drag and drop your files")} `}
                        </ModalSubtitle>
                      </>
                    ) : (
                      <>
                        <Filename>{fileToUpload?.name}</Filename>
                        <ProgressBarWrapper>
                          <ProgressBar width={uploadPercentCompleted} />
                        </ProgressBarWrapper>
                        {uploadPercentCompleted === 100 && (
                          <UploadMessage>{t("Your file was successfully uploaded")}</UploadMessage>
                        )}
                      </>
                    )}
                  </DropzoneContent>
                </Dropzone>
              </ModalContent>
            </Lightbox>
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}

export default withLDConsumer()(withTranslation(TranslationNamespaces.schedules)(ScheduleImport));
