import styled from "styled-components";
import moment, * as momentTz from "moment-timezone";
import * as images from "components/svg-images";
import { SuperpunchPunchCellData } from "types/models/superpunch";
import { PunchStatuses, PunchValidation } from "types/models/punches";

export enum SuperpunchCellStatus {
  valid = "valid",
  pending = "pending",
  error = "error",
}

interface CellWrapperProps {
  disabled: boolean;
  requestFailed: boolean;
  inactive: boolean;
  status: SuperpunchCellStatus | "";
}

const getBgColor = (status: SuperpunchCellStatus | "") => {
  let bgColor = "none";

  switch (status) {
    case SuperpunchCellStatus.valid:
      bgColor = "var(--colors-activitiCellActive)";
      break;
    case SuperpunchCellStatus.pending:
      bgColor = "var(--colors-activitiCellPending)";
      break;
    case SuperpunchCellStatus.error:
      bgColor = "var(--colors-activitiCellError)";
      break;
    default:
      break;
  }
  return bgColor;
};

const getBgColorHover = (status: SuperpunchCellStatus | "") => {
  let bgColor = "none";

  switch (status) {
    case SuperpunchCellStatus.valid:
      bgColor = `var(--colors-activitiCellActiveHover)`;
      break;
    case SuperpunchCellStatus.pending:
      bgColor = `var(--colors-activitiCellPendingHover)`;
      break;
    case SuperpunchCellStatus.error:
      bgColor = `var(--colors-activitiCellErrorHover)`;
      break;
    default:
      bgColor = `var(--colors-activitiCellScheduleHover)`;
      break;
  }
  return bgColor;
};

const getCellWrapperColor = (status: SuperpunchCellStatus | "", inactive: boolean) => {
  if (inactive) {
    return `var(--colors-secondary3)`;
  }
  if (status) {
    return `var(--colors-surface-400)`;
  }
  return `var(--colors-surface-800)`;
};

const CellWrapper = styled.div<CellWrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: var(--typography-font-size-default);
  position: relative;
  ${(props) => (props.requestFailed ? "border: 1px solid red!important" : "")};
  color: ${(props) => getCellWrapperColor(props.status, props.inactive)};
  background: ${(props) => getBgColor(props.status)};
  ${(props) =>
    !props.disabled
      ? `
    &:hover{
      background: ${getBgColorHover(props.status)};
      .dropdown-icon{
        display: flex;
      }
      .error-icon{
        opacity: 1
      }
    }
  `
      : ""}
`;

const DopdownIcon = styled.div`
  display: none;
  line-height: 5px;
  margin-inline-start: 3px;
  position: absolute;
  inset-inline-end: 10px;
`;

const ErrorIcon = styled.div`
  opacity: 0.5;
  position: absolute;
  inset-inline-end: 7px;
  top: 5px;
`;

const OtherDay = styled.div`
  font-size: 10px;
`;

interface SuperpunchCellProps {
  punch: SuperpunchPunchCellData;
  onClick: () => void;
  date: moment.Moment;
  disabled: boolean;
  inactive: boolean;
  requestFailed: boolean;
  customStatus?: SuperpunchCellStatus;
}

const Cell = ({ punch, onClick, date, disabled, inactive, requestFailed, customStatus }: SuperpunchCellProps) => {
  let otherDay = false;
  let status: SuperpunchCellStatus | "" = "";
  const punchRaw = punch?.raw;

  if (customStatus) {
    // when the cell is used for combined breaks cell
    status = customStatus;
  } else if (punchRaw) {
    otherDay = momentTz.tz(punchRaw.time, punchRaw.timezone).format("DD-MM-YYYY") !== date.format("DD-MM-YYYY");

    if (
      punchRaw.validation !== PunchValidation.invalid &&
      (punchRaw.status === PunchStatuses.pending || !punchRaw.status)
    ) {
      status = SuperpunchCellStatus.pending;
    } else if (punchRaw.status === PunchStatuses.declined) {
      status = SuperpunchCellStatus.error;
    } else if (punchRaw.validation !== PunchValidation.invalid) {
      status = SuperpunchCellStatus.valid;
    } else {
      status = SuperpunchCellStatus.error;
    }
  }

  return (
    <CellWrapper
      requestFailed={requestFailed}
      status={status}
      onClick={onClick}
      disabled={disabled}
      inactive={inactive}
    >
      {status === SuperpunchCellStatus.error && <ErrorIcon className="error-icon">{images.activityError}</ErrorIcon>}
      <div>{punch.display}</div>
      <DopdownIcon className="dropdown-icon">{images.smallArrowDown()}</DopdownIcon>
      {otherDay && !!punchRaw && (
        <OtherDay>{momentTz.tz(punchRaw.time, punchRaw.timezone).format("DD-MM-YYYY")}</OtherDay>
      )}
    </CellWrapper>
  );
};

export default Cell;
