import styled from "styled-components";

export const Title = styled.div`
  font-weight: var(--typography-font-weight-medium);
  font-size: 15px;
  line-height: 19px;
  color: var(--colors-mainText);
  margin-bottom: 24px;
  margin-top: 32px;
`;

export const CardTitle = styled.div`
  height: 54px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 10px 19px 16px;
`;

export const FieldTitle = styled.div`
  font-size: 13px;
  line-height: 13px;
  color: var(--colors-surface-800);
  margin-bottom: 7px;
`;

export const Content = styled.div`
  margin-top: 32px;
`;

export const GenerateLink = styled.a`
  margin-top: 8px;
  padding: 11px 16px 11px 40px;
  background: var(--colors-surface-0);
  border: 1px solid #b4c4d1;
  border-radius: var(--shapes-border-radius-default);
  font-weight: var(--typography-font-weight-medium);
  font-size: var(--typography-font-size-default);
  line-height: 14px;
  color: var(--colors-surface-600);
  position: relative;
  cursor: pointer;
  width: fit-content;
  &:hover {
    box-shadow: 0px 3px 5px rgba(129, 147, 171, 0.2);
  }
  &:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%238093AC' d='M15.592 11.925h-3.775a.833.833 0 0 0 0 1.666h2A6.667 6.667 0 0 1 2.333 9 .833.833 0 1 0 .667 9a8.333 8.333 0 0 0 14.066 6.025V16.5a.833.833 0 1 0 1.667 0v-3.75a.833.833 0 0 0-.808-.825ZM9 .667a8.333 8.333 0 0 0-5.733 2.308V1.5a.833.833 0 1 0-1.667 0v3.75a.833.833 0 0 0 .833.833h3.75a.833.833 0 1 0 0-1.667h-2A6.667 6.667 0 0 1 15.667 9a.833.833 0 1 0 1.666 0A8.333 8.333 0 0 0 9 .667Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 17px;
    inset-inline-start: 13px;
    top: 0;
  }
`;

export const LinkWithIcon = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: var(--typography-font-weight-default);
  font-size: var(--typography-font-size-default);
  line-height: 18px;
  color: var(--colors-primary-500-p);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-inline-start: 32px;
  padding-inline-end: 8px;
  position: relative;
  cursor: pointer;
  border-radius: var(--shapes-border-radius-default);
  &:hover {
    background-color: var(--colors-primary-50);
  }
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 14px;
    background-position: center;
    background-repeat: no-repeat;
    inset-inline-start: 9px;
    top: 0;
  }
  &.explore-apps {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%231E97F7' d='M5.667 7.667H1a.667.667 0 0 0-.667.667V13a.667.667 0 0 0 .667.667h4.667A.667.667 0 0 0 6.333 13V8.334a.667.667 0 0 0-.666-.667ZM5 12.334H1.667V9H5v3.334Zm8-12H8.333A.667.667 0 0 0 7.667 1v4.667a.667.667 0 0 0 .666.667H13a.667.667 0 0 0 .667-.667V1A.667.667 0 0 0 13 .333ZM12.333 5H9V1.667h3.333V5ZM13 7.667H8.333a.667.667 0 0 0-.666.667V13a.667.667 0 0 0 .666.667H13a.667.667 0 0 0 .667-.667V8.334A.667.667 0 0 0 13 7.667Zm-.667 4.667H9V9h3.333v3.334Zm-6.666-12H1A.667.667 0 0 0 .333 1v4.667A.667.667 0 0 0 1 6.334h4.667a.667.667 0 0 0 .666-.667V1a.667.667 0 0 0-.666-.667ZM5 5H1.667V1.667H5V5Z'/%3E%3C/svg%3E");
    }
  }
  &.app-access {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%231E97F7' d='M9.333 5V3.667a3.333 3.333 0 1 0-6.666 0V5a2 2 0 0 0-2 2v4.667a2 2 0 0 0 2 2h6.666a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2ZM4 3.667a2 2 0 1 1 4 0V5H4V3.667Zm6 8a.667.667 0 0 1-.667.667H2.667A.666.666 0 0 1 2 11.667V7a.667.667 0 0 1 .667-.667h6.666A.667.667 0 0 1 10 7v4.667Z'/%3E%3C/svg%3E");
    }
  }
  &.api {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='14' fill='none'%3E%3Cpath fill='%231E97F7' d='M11.647 3.913V3.86l-.04-.1a.485.485 0 0 0-.047-.06.624.624 0 0 0-.06-.08l-.06-.047-.107-.053-5-3.087a.667.667 0 0 0-.706 0L.667 3.52l-.06.053-.06.047a.627.627 0 0 0-.06.08.473.473 0 0 0-.047.06l-.04.1v.053a.767.767 0 0 0 0 .174v5.826a.667.667 0 0 0 .313.567l5 3.087c.031.019.065.032.1.04h.054a.573.573 0 0 0 .346 0h.054a.314.314 0 0 0 .1-.04l4.966-3.087a.666.666 0 0 0 .314-.567V4.087a.773.773 0 0 0 0-.174Zm-6.314 7.894L1.667 9.54V5.287l3.666 2.26v4.26ZM6 6.393 2.267 4.087 6 1.787l3.733 2.3L6 6.393Zm4.333 3.147-3.666 2.267v-4.26l3.666-2.26V9.54Z'/%3E%3C/svg%3E");
    }
  }
`;

export const SectionTitle = styled.div`
  font-weight: var(--typography-font-weight-default);
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.44px;
  color: var(--colors-surface-900-p);
`;
