import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSelectedColumns, iRow } from "utils/tableHelpers";
import moment from "moment";
import DownloadControlWithEvents from "components/DownloadControlWithEvents";
import { getReport, fireDownloadReport } from "utils/apiHelpers";
import TablePage from "components/TablePage";
import { ActivitiesReportSingleEmployee } from "types/models/projects";
import { iColumn } from "components/TableCommon";
import { OnError, SetIsLoading } from "types/reports";
import { SearchObject } from "types/common";
import { PermissionSectionName } from "types/models/permissions";
import { getValueWithCurrency, hasPermisionAccess, minsToHrsMins } from "utils/common";
import { getMinsToHoursDecimal } from "utils/reportsHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { TableDetailsTitle, TableDetailsTitleDates } from "./reportsStyledComponents";
import ReportsDetailedServicesSummary, { ServicesSummary } from "./ReportsDetailedServicesSummary";
import { ReportActvitiesStatus } from "./helpers";
import ReportsNoContent from "./ReportsNoContent";
import { translateEmployeeTerm } from "utils/translationHelpers";

interface ReportsActivitiesSingleEmployeeProps extends RouteComponentProps, WithTranslation {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  setIsLoading: SetIsLoading;
  onError: OnError;
  searchObj: SearchObject | null;
  showServicesSummary: boolean;
  activityStatus: ReportActvitiesStatus;
}

type ProjectActivityRow = {
  date: string;
  schedule: string;
  startTime: string;
  endTime: string;
  task: string;
  projectName: string;
  projectId: string;
  service: string;
  location: string;
  client: string;
  duration: number;
  durationDecimal: string;
  employeeCost: string;
  customFieldsMap: {
    [key: string]: {
      name: string;
      value: string | number;
      fieldType: "numeric" | "input";
    };
  };
};

interface ReportsActivitiesSingleEmployeeState {
  loading: boolean;
  activities: ProjectActivityRow[];
  selectedColumns: string;
  metadata: {
    servicesSummary: ServicesSummary | null;
  } | null;
}

const mapActivityTableData = (inputData: ActivitiesReportSingleEmployee[]): ProjectActivityRow[] =>
  inputData.map((row) => ({
    date: moment(row.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    schedule: row.schedule?.name || "",
    startTime: moment(row.date).add(row.startTime, "minutes").format("HH:mm"),
    endTime: moment(row.date).add(row.endTime, "minutes").format("HH:mm"),
    projectName: row.project?.name || "",
    projectId: row.project?.id || "",
    service: row.service?.name || "",
    location: row.location?.name || "",
    client: row.client?.name || "",
    task: row.task?.name || "",
    duration: row.duration || 0,
    durationDecimal: getMinsToHoursDecimal(row.duration || 0),
    customFieldsMap: row.customFieldsMap || {},
    employeeCost: getValueWithCurrency(row.employeeCost) || "0",
  }));

const defaultColumns =
  "date,schedule,start,end,activity_name,project_name,service_name,location_name,client_name,duration,duration_decimal";
const tableName = "ReportsActivitiesSingleEmployee";

class ReportsActivitiesSingleEmployee extends Component<
  ReportsActivitiesSingleEmployeeProps,
  ReportsActivitiesSingleEmployeeState
> {
  constructor(props: ReportsActivitiesSingleEmployeeProps) {
    super(props);
    this.state = {
      metadata: null,
      loading: true,
      activities: [],
      selectedColumns: getSelectedColumns(defaultColumns, tableName),
    };
  }

  componentDidMount(): void {
    void this.getReportData();
  }

  getReportData = async (): Promise<void> => {
    const { t, startDate, endDate, searchObj, setIsLoading, onError, activityStatus } = this.props;
    const companyUUID = window.global_store.company.uuid;

    if (startDate && endDate && companyUUID) {
      setIsLoading(true);
      try {
        const response = await getReport(
          {
            startDate,
            endDate,
            companyUUID,
            UserProfileUUID: searchObj?.uuid || "",
            status: activityStatus,
            type: "employee_activities",
          },
          true,
        );

        const data: ActivitiesReportSingleEmployee[] = response?.content || [];
        const activities: ProjectActivityRow[] = mapActivityTableData(data);

        if (activities.length > 0) {
          const custFields = Object.keys(activities[0].customFieldsMap)
            .map((id) => `customFieldsMap[${id}]`)
            .join(",");
          const selectedColumns = getSelectedColumns(`${defaultColumns},${custFields}`, tableName);
          this.setState({ selectedColumns });
        }
        this.setState({
          metadata: response?.metadata || null,
          activities,
          loading: false,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error", e);

        onError(t("Failed to generate report"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  getColumns = (): iColumn<ProjectActivityRow>[] => {
    const { t } = this.props;
    const { metadata, activities } = this.state;

    const columns: iColumn<ProjectActivityRow>[] = [
      {
        Header: t("Date"),
        locked: true,
        accessor: "date",
        rubyAccessor: "date",
        minWidth: 115,
        style: { lineHeight: "22px" },
      },
      {
        accessor: "schedule",
        rubyAccessor: "schedule",
        label: t("Schedule"),
        minWidth: 130,
      },
      {
        accessor: "startTime",
        rubyAccessor: "start",
        label: t("Start"),
        minWidth: 100,
      },
      {
        accessor: "endTime",
        rubyAccessor: "end",
        label: t("End"),
        minWidth: 100,
      },
      {
        accessor: "task",
        rubyAccessor: "activity_name",
        label: t("Activity"),
        minWidth: 100,
      },
      {
        accessor: "projectName",
        rubyAccessor: "project_name",
        label: t("Project"),
        minWidth: 100,
      },
      {
        accessor: "projectId",
        rubyAccessor: "project_id",
        label: t("Project Id"),
        minWidth: 100,
      },
      {
        accessor: "service",
        rubyAccessor: "service_name",
        label: t("Service"),
        minWidth: 100,
      },
      {
        accessor: "location",
        rubyAccessor: "location_name",
        label: t("Location"),
        minWidth: 100,
      },
      {
        accessor: "client",
        rubyAccessor: "client_name",
        label: t("Client"),
        minWidth: 100,
      },
      {
        accessor: "duration",
        rubyAccessor: "duration",
        label: t("Hours"),
        Cell: (row: iRow): string => minsToHrsMins(row.value),
        Footer: metadata?.servicesSummary?.metadata
          ? minsToHrsMins(metadata?.servicesSummary?.metadata.totalMinutes || 0)
          : "",
        minWidth: 100,
        align: "end",
      },
      {
        accessor: "durationDecimal",
        rubyAccessor: "duration_decimal",
        label: t("Hours (Decimal)"),
        Footer: metadata?.servicesSummary?.metadata
          ? getMinsToHoursDecimal(metadata?.servicesSummary?.metadata.totalMinutes || 0)
          : "",
        minWidth: 120,
        align: "end",
      },
    ];

    if (activities.length > 0) {
      const customFieldsColumns: iColumn<ProjectActivityRow>[] = Object.entries(activities[0].customFieldsMap).map(
        ([id, cf]) => ({
          accessor: `customFieldsMap[${id}].value`,
          rubyAccessor: `customFieldsMap[${id}]`,
          Footer: String(metadata?.servicesSummary?.metadata.customFieldsMap[id]?.value || ""),
          label: cf.name,
          align: "end",
          Cell: (row) => (
            <div
              style={{
                textAlign: row.original.customFieldsMap[id].fieldType === "numeric" ? "right" : "left",
              }}
            >
              {row.value != null ? row.value : ""}
            </div>
          ),
        }),
      );
      columns.push(...customFieldsColumns);
    }

    if (hasPermisionAccess(PermissionSectionName.projectEmployeeCost)) {
      columns.push({
        accessor: "employeeCost",
        rubyAccessor: "employee_cost",
        label: translateEmployeeTerm(t, TranslationNamespaces.reportsPage, "custom-employee-cost", `Employee Cost`),
        Footer: getValueWithCurrency(metadata?.servicesSummary?.metadata?.totalEmployeeCost),
        minWidth: 100,
        align: "end",
      });
    }

    return columns;
  };

  onColumnsChange = (selectedColumns: string[]): void => {
    if (localStorage) {
      localStorage.setItem("customColumns_ReportsActivitiesSingleEmployee", selectedColumns.join());
    }

    this.setState({ selectedColumns: selectedColumns.join() });
  };

  render(): JSX.Element {
    const { loading, activities, selectedColumns, metadata } = this.state;
    const { t, searchObj, startDate, endDate, showServicesSummary, activityStatus } = this.props;
    const columns = this.getColumns();
    const title = searchObj?.label;

    return (
      <div>
        <TablePage<ProjectActivityRow>
          selectedColumns={selectedColumns ? selectedColumns.split(",") : ""}
          onColumnsChange={this.onColumnsChange}
          tableDetails={
            <>
              <TableDetailsTitle>{title}</TableDetailsTitle>
              <TableDetailsTitleDates>
                {moment(startDate).format("DD-MM-YYYY")} - {moment(endDate).format("DD-MM-YYYY")}
              </TableDetailsTitleDates>
            </>
          }
          loading={loading}
          rows={activities}
          columns={columns}
          interactive={false}
          downloadControl={
            <DownloadControlWithEvents
              withNotification
              placeholder={t(`${TranslationNamespaces.common}|Download`)}
              onChange={(value: string): Promise<Record<string, unknown>> => {
                const cols = selectedColumns.split(",");
                const cfRe = /^customFieldsMap\[(.*)\]$/;
                const regularColumns = cols.filter((c) => c.match(cfRe) == null).join(",");
                const customFields = cols
                  .map((c) => c.match(cfRe))
                  .filter((m) => m != null)
                  .map((m) => m![1])
                  .join(",");
                return fireDownloadReport({
                  format: value || "pdf",
                  selectedColumns: regularColumns,
                  customFields,
                  startDate,
                  endDate,
                  searchObj,
                  showServicesSummary,
                  status: activityStatus,
                  companyUUID: window.global_store.company ? window.global_store.company.uuid : "",
                  reportType: "project_employee_activities",
                });
              }}
            />
          }
          noContentComponent={<ReportsNoContent />}
        />
        {showServicesSummary && metadata?.servicesSummary && (
          <ReportsDetailedServicesSummary
            servicesSummary={metadata?.servicesSummary}
            currency={window.global_store.company.currency}
            summaryWithCosts
          />
        )}
      </div>
    );
  }
}

export default withRouter(withTranslation(TranslationNamespaces.reportsPage)(ReportsActivitiesSingleEmployee));
