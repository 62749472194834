import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "types/translationNamespaces";
import { stylesheet } from "astroturf";
import Button, { ButtonState } from "components/controls/StyledButton";
import ModalDialog from "components/UI/ModalDialog";
import { useState } from "react";

const styles = stylesheet`@import "../billing.scss"`;

type NeedPurchasePlanModalProps = {
  close: () => void;
};

export const NeedPurchasePlanModal = (props: NeedPurchasePlanModalProps) => {
  const { t } = useTranslation(TranslationNamespaces.subscription);
  const [modalVisible, setModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigateToPricingPage = () => {
    setLoading(true);
    window.location.href = '/billing/pricing';
  }

  return (
    <ModalDialog
      top="120px"
      preventOutsideClose
      onClose={() => {
        props.close();
        setModalVisible(false);
      } }
      isOpen={modalVisible}
      width={464}
    >
      <div className={styles.UpgradePlanModal}>
        <div className={styles.UpgradeTitle}>{t("upgrade_plan_modal title")}</div>
        <div className={styles.UpgradePopupTerms}>{t("upgrade_plan_modal description")}</div>
        <div className={`${styles.Flex} ${styles.JustifyBetween}`}>
          <Button value={t("upgrade_plan_modal cancel-CTA")} style={{ width: "48%" }} state={ButtonState.secondary} onClick={() => {
            props.close();
            setModalVisible(false);
          }} />
          <Button value={t("upgrade_plan_modal upgrade-CTA")} loading={loading} style={{ width: "48%" }} state={ButtonState.primary} onClick={navigateToPricingPage} />
        </div>
      </div>
    </ModalDialog>
  );
}

