import { Field, FieldAttributes } from "formik";
import { TFunction } from "react-i18next";
import BEM from "utils/BEM";
import "./FormikField.scss";

const b = BEM.b("formik-field");

interface FormikFieldProps {
  name: string;
  title: string;
  type: unknown;
  errors: Record<string, string>;
  t: TFunction;
}

const FormikField = ({ name, title, type, errors = {}, t, ...rest }: FormikFieldProps & FieldAttributes<any>) => (
  <div key={name} className={b()}>
    <label>
      <span className={b("label")}>{t ? t(title) : title}</span>
      <Field className={b("input", { error: errors[name] })} name={name} type={type || "text"} {...rest} />
    </label>
    <div className={b("error-message")}>{errors && errors[name]}</div>
  </div>
);

export default FormikField;
