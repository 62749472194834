import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import SidePopupOverlay from "components/UI/SidePopupOverlay";
import EmployeeInfo from "components/UI/EmployeeInfo";
import StatusBadge, { StatusBadgeStatuses } from "components/controls/StatusBadge";
import Button, { ButtonState } from "components/controls/StyledButton";
import { hasPermisionAccess } from "utils/common";
import { deleteOnCall } from "utils/apiHelpers";
import { TranslationNamespaces } from "types/translationNamespaces";
import { OnCallStatus, OnCallTableData, OnCallType } from "types/models/onCalls";
import { PermissionSectionName } from "types/models/permissions";
import { ignoreRequest } from "../svg-images";

const Content = styled.div``;

const UserInfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  ${(p) => `border-bottom: 1px solid ${p.theme.colors.inputBorderDefault};`}

  .employee-info {
    border: none;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 24px;

  & > div {
    margin-inline-end: 30px;
  }
`;

const Label = styled.div`
  line-height: 16px;
  font-size: 13px;
  color: var(--colors-secondary);
`;

const Value = styled.div`
  margin-top: 8px;
  line-heightL 19px;
  color: var(--colors-mainText);
`;

const ActionButtons = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  width: 350px;
`;

const DeleteButton = styled.div`
  display: flex;
  align-intems: center;
  justify-content: center;
  line-height: 15px;
  font-size: var(--typography-font-size-default);
  color: var(--colors-primary);
  cursor: pointer;

  span {
    margin-inline-start: 10px;
  }
`;

const UpdateStatusButtons = styled.div<{ isRTL: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;

  button {
    max-width: 155px;
  }

  ${(p) => (p.isRTL ? "flex-direction: row-reverse;" : "")}
`;

interface OnCallPopupProps extends WithTranslation {
  isOpen: boolean;
  canUpdateStatus: boolean;
  onCall: OnCallTableData | null;
  onClose: () => void;
  updateStatus: (onCallUuids: string[], status: OnCallStatus) => void;
  onDelete: (err?: Error) => void;
}

interface OnCallPopupState {
  isLoading: boolean;
}

class OnCallPopup extends Component<OnCallPopupProps, OnCallPopupState> {
  readonly state: Readonly<OnCallPopupState> = {
    isLoading: false,
  };

  componentDidUpdate(prevProps: OnCallPopupProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      this.setState({ isLoading: false });
    }
  }

  onDelete = async () => {
    const { onCall, onDelete } = this.props;
    const { profile, company } = window.global_store;

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });

      try {
        await deleteOnCall({
          companyUuid: company.uuid,
          onCallUuid: (onCall as OnCallTableData).uuid,
          body: {
            content: {
              updatedBy: profile.uuid,
              deletedBy: profile.uuid,
            },
          },
        });

        onDelete();
      } catch (e) {
        onDelete(e as Error);
      }
    }
  };

  render() {
    const { t, isOpen, onClose, onCall, canUpdateStatus, updateStatus } = this.props;

    if (!onCall) {
      return null;
    }

    const headerTitle = onCall.type === OnCallType.onCall ? t("On-Call Shift") : t("On-Call Working Time");
    const statusBadgeType = {
      [OnCallStatus.approved]: StatusBadgeStatuses.approved,
      [OnCallStatus.declined]: StatusBadgeStatuses.declined,
      [OnCallStatus.pending]: StatusBadgeStatuses.pending,
    }[onCall.status];
    const canDeleteOnCall =
      onCall.status !== OnCallStatus.declined &&
      (onCall.createdBy === window.global_store.profile.uuid ||
        hasPermisionAccess(PermissionSectionName.onCallManagement));

    return (
      <SidePopupOverlay isOpen={isOpen} header={headerTitle} onClose={onClose}>
        <Content>
          <UserInfoWrapper>
            <EmployeeInfo employeeInfo={onCall.employee} />
            <StatusBadge type={statusBadgeType} value={t(onCall.status)} />
          </UserInfoWrapper>

          <Row>
            <div>
              <Label>{t("Start Date")}</Label>
              <Value>{onCall.startDate}</Value>
            </div>
            <div>
              <Label>{t("Time")}</Label>
              <Value>{onCall.startTime}</Value>
            </div>
          </Row>

          <Row>
            <div>
              <Label>{t("End Date")}</Label>
              <Value>{onCall.endDate}</Value>
            </div>
            <div>
              <Label>{t("Time")}</Label>
              <Value>{onCall.endTime}</Value>
            </div>
          </Row>

          <Row>
            <div>
              <Label>{t("Category")}</Label>
              <Value>{t(onCall.type)}</Value>
            </div>
          </Row>

          <ActionButtons>
            {canDeleteOnCall && (
              <DeleteButton onClick={this.onDelete}>
                {ignoreRequest}
                <span>{t("Delete On-Call")}</span>
              </DeleteButton>
            )}

            {canUpdateStatus && (
              <UpdateStatusButtons isRTL={window.global_store.isRTL}>
                <Button
                  value={t("Decline")}
                  state={ButtonState.cancel}
                  onClick={() => updateStatus([onCall.uuid], OnCallStatus.declined)}
                />
                <Button
                  value={t("Approve")}
                  state={ButtonState.success}
                  onClick={() => updateStatus([onCall.uuid], OnCallStatus.approved)}
                />
              </UpdateStatusButtons>
            )}
          </ActionButtons>
        </Content>
      </SidePopupOverlay>
    );
  }
}

export default withTranslation(TranslationNamespaces.onCalls)(OnCallPopup);
