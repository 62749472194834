import { Component } from "react";
import BEM from "utils/BEM";
import "../UI/Page/Page.scss";
import "./styles/departments-page.scss";
import { withTranslation } from "react-i18next";
import GlobalContext from "context/global-context";
import SettingsLayout from "components/Layout/SettingsLayout";
import { addDepartment, updateDepartment, deleteDepartment } from "utils/apiHelpers";
import SearchInput from "components/UI/SearchInput";
import ModalDialog from "components/UI/ModalDialog";
import Lightbox from "components/Lightbox";
import { getTitle, strIncludesCheck } from "utils/common";
import { PageWrapper, Header, Title, HeaderAction, TableButtons, TableButton } from "components/styled/Page";
import HeaderActionButtonAdd from "components/controls/HeaderActionButtonAdd";
import { ButtonState } from "components/controls/StyledButton";
import { TranslationNamespaces } from "types/translationNamespaces";
import PopupMessage from "./DepartmentsPopupMessage";
import NotificationRow from "../NotificationRow";
import TablePage from "../TablePage";
import NoContent from "../NoContent";
import * as images from "../svg-images";

const b = BEM.b("departments-page");

class DepartmentsPage extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      items: [],
      notification: "",
      searchValue: "",
      selectedItem: null,
      popupVisible: false,
      confirmationPopupVisible: false,
      isFetching: false,
    };
    document.title = getTitle(t("Departments"));
  }
  componentDidMount() {
    this.getState();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getState();
  }
  getState(props) {
    if (!this.state.isFetching) {
      this.context.getDepartments(true).then((departments) => {
        this.setState({
          loaded: true,
          items: departments || [],
          isFetching: false,
          confirmationPopupVisible: false,
          selectedItem: null,
          popupVisible: false,
        });
      });
      this.setState({ isFetching: true, loaded: false });
    }
  }
  onRemoveItemClick = (item) => {
    this.setState({ confirmationPopupVisible: true, selectedItem: item });
  };
  removeItem(item) {
    const { t } = this.props;
    deleteDepartment({ id: item.id })
      .then(() => {
        this.setState({ notification: `${t("You deleted")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  onEditItemClick = (item) => {
    this.setState({ popupVisible: true, selectedItem: item });
  };
  saveItem(item) {
    const { t } = this.props;
    updateDepartment({
      id: item.id,
      body: {
        department: {
          name: item.name,
          code: item.code,
        },
      },
    })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  addItem(item) {
    const { t } = this.props;
    addDepartment({ body: { department: { name: item.name, code: item.code } } })
      .then(() => {
        this.setState({ notification: `${t("You saved")} ${item.name}` });
        this.getState();
      })
      .catch((e) => console.log(e));
  }
  addItemClick = () => {
    this.setState({ popupVisible: true, selectedItem: null });
  };
  onSearch(ev) {
    this.setState({ searchValue: ev.target.value });
  }

  getTableColumns() {
    const { t } = this.props;
    const columns = [
      {
        label: t("Id"),
        accessor: "code",
        width: 100,
        style: { lineHeight: "50px" },
      },
      {
        label: t("Departments"),
        accessor: "name",
        minWidth: 100,
      },
      {
        label: t("number of employees"),
        accessor: "employees_count",
        minWidth: 50,
      },
      // TODO fix options
      {
        Header: "",
        accessor: "options",
        minWidth: 50,
        className: b("options-td"),
        Cell: (row) => (
          <TableButtons className="buttons">
            <TableButton
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.onEditItemClick(row.original);
              }}
            >
              {t(`${TranslationNamespaces.common}|Edit`)}
            </TableButton>
            <TableButton
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                this.onRemoveItemClick(row.original);
              }}
            >
              {images.trashIcon}
            </TableButton>
          </TableButtons>
        ),
      },
    ];
    return columns;
  }
  render() {
    const { items, searchValue, selectedItem, popupVisible, confirmationPopupVisible, notification } = this.state;
    const { t } = this.props;

    const filteredItems = items.filter((s) => {
      const filter = strIncludesCheck(s.name, searchValue) || strIncludesCheck(s.code, searchValue);
      return filter;
    });

    return (
      <SettingsLayout
        title={t(`${TranslationNamespaces.sidebar}|Company Structure`)}
        headerAction={
          <HeaderActionButtonAdd
            state={ButtonState.primary}
            title={t("Add a department")}
            onClick={this.addItemClick}
          />
        }
      >
        <PageWrapper className={b()}>
          {notification && (
            <NotificationRow employeesPage withCloseButton={false} type="success" message={notification} />
          )}

          <TablePage
            columnSelectorOnFiltersRow
            filters={
              <SearchInput
                modifiers={["filter"]}
                onChange={this.onSearch.bind(this)}
                placeholder={t("Search")}
                value={searchValue}
              />
            }
            rows={filteredItems}
            columns={this.getTableColumns()}
            className="departments-table"
            loading={this.state.isFetching}
            noContentComponent={<NoContent>{t("You don’t have Departments")}</NoContent>}
          />

          <ModalDialog isOpen={popupVisible} onClose={() => this.setState({ popupVisible: false })}>
            <PopupMessage
              title={selectedItem ? t("Edit a Department") : t("Create a Department")}
              item={selectedItem || {}}
              allItems={items}
              buttonYesTitle={selectedItem ? t("Save") : t("Create")}
              onClose={() => {
                this.setState({ selectedItem: null, popupVisible: false });
              }}
              onYes={selectedItem ? this.saveItem.bind(this) : this.addItem.bind(this)}
            />
          </ModalDialog>
          <ModalDialog
            isOpen={confirmationPopupVisible}
            onClose={() => this.setState({ confirmationPopupVisible: false })}
          >
            <Lightbox
              title={t("Remove Department.")}
              text={t("Are you sure to remove a department?")}
              buttonYesTitle={t(`${TranslationNamespaces.common}|Confirm`)}
              buttonCancelTitle={t(`${TranslationNamespaces.common}|Cancel`)}
              onClose={() => {
                this.setState({ selectedItem: null, confirmationPopupVisible: false });
              }}
              onYes={this.removeItem.bind(this, selectedItem)}
            />
          </ModalDialog>
        </PageWrapper>
      </SettingsLayout>
    );
  }
}
export default withTranslation([TranslationNamespaces.departments, TranslationNamespaces.sidebar])(DepartmentsPage);
