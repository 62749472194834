import { Component, createContext } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import {
  checkDownloadsUuids,
  getActiveUserProfiles,
  getCurrentCompany,
  getDefaultLockPeriodsForReports,
  getDepartments,
  getOperations,
  getPermissionRoles,
  getRequestTypesNew,
  getSubsidiaries,
  getTeams,
} from "utils/apiHelpers";
import { showSnackbar } from "utils/common";
import * as oitchauDb from "utils/oitchauDb";
import { getSocketIo } from "utils/get-socket-io";
import { GlobalStoreCompany } from "types/models/company";
import { PermissionRole } from "types/models/permissions";
import { ActiveUserProfile, GlobalContextEmployee } from "types/models/userProfile";
import { RequestType } from "types/models/request";
import { ClientReportDownload, ClientReportDownloadStatus } from "types/models/clientReportDownload";
import { Operation } from "types/models/operations";
import sentryUtils from "utils/sentryUtils";
import { Team } from "types/models/team";
import { Department } from "types/models/department";
import { Subsidiary } from "types/models/subsidiary";
import {
  GlobalContextDefaultPayPeriod,
  GlobalContextDownload,
  GlobalContextInterface,
  GlobalContextProviderProps,
  GlobalContextProviderState,
  GlobalContextValue,
  StoreItem,
} from "./types";

export const GlobalContext = createContext<GlobalContextValue>({} as GlobalContextValue);

class GlobalContextProvider
  extends Component<GlobalContextProviderProps, GlobalContextProviderState>
  implements GlobalContextInterface
{
  private backgroundFetchTimeout: NodeJS.Timeout | null = null;
  private operationsFetchTimeout: NodeJS.Timeout | null = null;
  private companyFetchingPromise: Promise<GlobalStoreCompany | false> | null = null;
  private employeeFetchingPromise: Promise<GlobalContextEmployee[]> | null = null;
  private operationsFetchingPromise: Promise<Operation[]> | null = null;
  private teamsFetchingPromise: Promise<Team[]> | null = null;
  private departmentsFetchingPromise: Promise<Department[]> | null = null;
  private subsidiariesFetchingPromise: Promise<Subsidiary[]> | null = null;
  private globalState: GlobalContextProviderState;
  private socket: SocketIOClient.Socket | null = null;
  private storeItems: StoreItem[];

  constructor(props: GlobalContextProviderProps) {
    super(props);

    this.globalState = {};
    this.socket = null;
    this.storeItems = [
      StoreItem.roles,
      StoreItem.profiles,
      StoreItem.downloads,
      StoreItem.defaultPayPeriod,

      StoreItem.employees,
      StoreItem.loginAsMode,
    ];

    this.initStoreFromLocalStorage();
  }

  componentDidMount() {
    document.addEventListener("LogOut", this.clear);

    window.onbeforeunload = () => {
      if (this.socket) {
        this.socket.emit("commands", [{ name: "leave", args: { scope: "superpunch" } }]);
      }
    };
  }

  componentWillUnmount() {
    document.removeEventListener("LogOut", this.clear);
  }

  private initStoreFromLocalStorage = () => {
    this.storeItems.forEach((item) => {
      if (item === StoreItem.company || item === StoreItem.employees) {
        return;
      }

      const localStorageItem = localStorage.getItem(`gs_${item}`);

      if (localStorageItem) {
        this.globalState[item] = JSON.parse(localStorageItem);
      } else {
        this.globalState[item] = null;
      }
    });

    setTimeout(() => {
      window.requestIdleCallback(this.prefetchData, { timeout: 10000 });
    }, 3000);
  };

  private prefetchData = () => {
    if (window?.global_store?.profile) {
      this.backgroundFetch();
    }

    // TODO: implement que https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API#Example
    // if (!!localStorage.getItem('gs_profile') || !!cookie.load('userEmail')) {
    //   this.getCompany();
    //   this.getRoles();
    //   this.getActiveProfiles();
    // }
  };

  private updateData = <K extends keyof GlobalContextProviderState>(
    key: K,
    data: GlobalContextProviderState[K],
  ): GlobalContextProviderState[K] => {
    this.globalState[key] = data;

    if (key !== "employees") {
      localStorage.setItem(`gs_${key}`, JSON.stringify(data));
    }

    return data;
  };

  /**
   * Log out callback
   */
  private clear = () => {
    if (this.backgroundFetchTimeout) {
      clearTimeout(this.backgroundFetchTimeout);
    }
    if (this.operationsFetchTimeout) {
      clearTimeout(this.operationsFetchTimeout);
    }

    this.globalState = {};

    if (this.socket) {
      this.socket.emit("commands", [{ name: "leave", args: { scope: "superpunch" } }]);
    }

    void this.clearCachedStorage();
  };

  private async clearCachedStorage() {
    await oitchauDb.clearDb();

    this.storeItems.forEach((item) => {
      localStorage.removeItem(`gs_${item}`);
    });

    localStorage.removeItem("gs_lastNotificationUuid");
  }

  /**
   * Refetch company, profile and projectsettings data and cache it
   */
  private backgroundFetch = () => {
    const DEFAULT_CACHE_TTL = 1000 * 60 * 10; // 10 minutes

    this.backgroundFetchTimeout = setTimeout(async () => {
      // refetch company in background
      const companyCachedTime = await oitchauDb.system.getCompanyCachedTime();
      if (!companyCachedTime || Date.now() - companyCachedTime > DEFAULT_CACHE_TTL) {
        await this.getCompany(true);
      }
    }, 7000);
  };

  /**
   * Fetching operations and updating global state in bg mode.
   *
   * @returns undefined
   */
  private operationsBgFetch = async () => {
    this.operationsFetchingPromise = new Promise((resolve) => {
      void this.getCompany()
        .then((company) => {
          if (!company) {
            throw new Error("GlobalContext: Did not get the company. Skipping operations background fetching");
          }
          return getOperations({ companyUuid: company.uuid });
        })
        .then(({ content: operations }) => {
          this.updateData("operations", operations) as Operation[];

          this.operationsFetchingPromise = null;

          resolve(operations);
        })
        .catch((e) => {
          console.error(e);
          sentryUtils.sendError(e);
        });
    });

    if (this.operationsFetchTimeout) {
      clearTimeout(this.operationsFetchTimeout);
    }

    this.operationsFetchTimeout = setTimeout(this.operationsBgFetch, 10000);
  };

  getSocket = async () => {
    const socketIo = await getSocketIo();
    this.socket = socketIo;

    return socketIo;
  };

  refetchCompany = async () => {
    const company = await this.getCompany(true);

    window.global_store.company = company;
  };

  getCompany = async (force?: boolean) => {
    const company = await oitchauDb.system.getCompany();

    if (!force && company) {
      return new Promise<GlobalStoreCompany>((resolve) => {
        resolve(company);
      });
    }

    if (this.companyFetchingPromise) {
      // fetch in progress
      return this.companyFetchingPromise;
    }

    this.companyFetchingPromise = new Promise((resolve) => {
      void getCurrentCompany(true).then(async (response) => {
        if (response) {
          await oitchauDb.system.setCompany(response);

          window.global_store.company = company;
        }

        this.companyFetchingPromise = null;
        resolve(response);
      });
    });

    return this.companyFetchingPromise;
  };

  getRoles = async () => {
    if (this.globalState.roles) {
      return this.globalState.roles;
    }

    const roles = await getPermissionRoles();

    return this.updateData("roles", roles.permission_roles) as PermissionRole[];
  };

  getDefaultPayPeriod = async () => {
    if (this.globalState.defaultPayPeriod) {
      return this.globalState.defaultPayPeriod;
    }

    const company = await this.getCompany();

    if (company) {
      const resp = await getDefaultLockPeriodsForReports({ companyUuid: company.uuid });

      if (resp?.content[0]?.months[0]?.ranges?.length) {
        const { ranges } = resp.content[0].months[0];
        let range = ranges[ranges.length - 1];

        if (ranges.length > 1) {
          ranges.forEach((r) => {
            // last range that includes today
            if (
              moment(r.start, "YYYY-MM-DD").isSameOrBefore(moment(), "day") &&
              moment(r.end, "YYYY-MM-DD").isSameOrAfter(moment(), "day")
            ) {
              range = r;
            }
          });
        }

        return this.updateData("defaultPayPeriod", {
          startDate: moment(range.start, "YYYY-MM-DD"),
          endDate: moment(range.end, "YYYY-MM-DD"),
        }) as GlobalContextDefaultPayPeriod;
      }
    }

    return this.updateData("defaultPayPeriod", null) as null;
  };

  getActiveProfiles = async () => {
    if (this.globalState?.profiles?.length) {
      return this.globalState.profiles;
    }

    const profiles = await getActiveUserProfiles();

    return this.updateData("profiles", profiles || []) as ActiveUserProfile[];
  };

  getRequestTypes = async ({ force }: { force?: boolean } = {}) => {
    if (!force && this.globalState.requestTypes) {
      return this.globalState.requestTypes;
    }

    const company = await this.getCompany();
    if (!company) {
      throw new Error("No company. Can't fetch request types");
    }

    const { content: requestTypes } = await getRequestTypesNew({
      companyUUID: company.uuid,
      requestedBy: window.global_store.profile.uuid,
    });
    if (!requestTypes || !requestTypes.length) {
      throw new Error("No request types received");
    }

    return this.updateData("requestTypes", requestTypes) as RequestType[];
  };

  getDownloads = async () => {
    if (this.globalState.downloads?.length) {
      const pendingDownloads = this.globalState.downloads.filter(
        (download) => download.status === ClientReportDownloadStatus.inProgress,
      );
      if (pendingDownloads.length) {
        const resp = await checkDownloadsUuids({ uuids: pendingDownloads.map((download) => download.uuid) });

        if (resp?.generated_reports?.length) {
          let hasError = false;
          let { downloads } = this.globalState;

          downloads = downloads
            .map((download) => {
              const respDownload = (resp.generated_reports as ClientReportDownload[]).find(
                (item) => item.uuid === download.uuid,
              );

              if (respDownload) {
                if (respDownload.status === ClientReportDownloadStatus.done) {
                  return respDownload;
                }
                if (respDownload.status === ClientReportDownloadStatus.longTime) {
                  return respDownload;
                }
                if (respDownload.status === ClientReportDownloadStatus.error) {
                  hasError = true;
                  return null;
                }

                return download;
              }

              return download;
            })
            .filter((item) => !!item) as GlobalContextDownload[];

          if (hasError) {
            showSnackbar({ text: this.props.t("Report generation failed") });
          }

          return (this.updateData("downloads", downloads) as GlobalContextDownload[]).filter(
            (download) =>
              download.status === ClientReportDownloadStatus.done ||
              download.status === ClientReportDownloadStatus.longTime,
          );
        }
      }

      return this.globalState.downloads.filter(
        (download) =>
          download.status === ClientReportDownloadStatus.done ||
          download.status === ClientReportDownloadStatus.longTime,
      ) as GlobalContextDownload[];
    }

    return this.updateData("downloads", []) as GlobalContextDownload[];
  };

  addToDownloads = async (uuid: string) => {
    let { downloads } = this.globalState;

    if (downloads && Array.isArray(downloads)) {
      downloads.push({ uuid, status: ClientReportDownloadStatus.inProgress });
    } else {
      downloads = [{ uuid, status: ClientReportDownloadStatus.inProgress }];
    }

    return this.updateData("downloads", downloads) as GlobalContextDownload[];
  };

  removeFromDownloads = async (uuid: string) => {
    let { downloads } = this.globalState;

    if (downloads && Array.isArray(downloads)) {
      downloads = downloads.filter((download) => download.uuid !== uuid);
    } else {
      downloads = [];
    }

    return this.updateData("downloads", downloads) as GlobalContextDownload[];
  };

  getOperations = async () => {
    if (this.globalState.operations) {
      return this.globalState.operations;
    }

    // Start bg fetch if no operations data in globalState. This will initialise this.operationsFetchingPromise
    if (!this.operationsFetchingPromise) {
      await this.operationsBgFetch();
    }

    if (this.operationsFetchingPromise) {
      return await this.operationsFetchingPromise;
    }

    return [];
  };

  getTeams = async (force?: boolean) => {
    if (this.globalState.teams && !force) {
      return this.globalState.teams;
    }

    if (!this.teamsFetchingPromise) {
      this.teamsFetchingPromise = new Promise((res) =>
        getTeams()
          .then(({ teams }) => {
            this.globalState.teams = teams;
            res(teams);
          })
          .finally(() => {
            this.teamsFetchingPromise = null;
          }),
      );
    }

    return await this.teamsFetchingPromise;
  };

  getDepartments = async (force?: boolean) => {
    if (this.globalState.departments && !force) {
      return this.globalState.departments;
    }

    if (!this.departmentsFetchingPromise) {
      this.departmentsFetchingPromise = new Promise((res) =>
        getDepartments()
          .then(({ departments }) => {
            this.globalState.departments = departments;
            res(departments);
          })
          .finally(() => {
            this.departmentsFetchingPromise = null;
          }),
      );
    }

    return await this.departmentsFetchingPromise;
  };

  getSubsidiaries = async (force?: boolean) => {
    if (this.globalState.subsidiaries && !force) {
      return this.globalState.subsidiaries;
    }

    if (!this.subsidiariesFetchingPromise) {
      this.subsidiariesFetchingPromise = new Promise((res) =>
        getSubsidiaries()
          .then(({ subsidiaries }) => {
            this.globalState.subsidiaries = subsidiaries;
            res(subsidiaries);
          })
          .finally(() => {
            this.subsidiariesFetchingPromise = null;
          }),
      );
    }

    return await this.subsidiariesFetchingPromise;
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          getCompany: this.getCompany,
          getRoles: this.getRoles,
          getActiveProfiles: this.getActiveProfiles,
          getRequestTypes: this.getRequestTypes,
          getDownloads: this.getDownloads,
          addToDownloads: this.addToDownloads,
          removeFromDownloads: this.removeFromDownloads,
          getSocket: this.getSocket,
          refetchCompany: this.refetchCompany,
          getDefaultPayPeriod: this.getDefaultPayPeriod,
          getOperations: this.getOperations,
          getTeams: this.getTeams,
          getDepartments: this.getDepartments,
          getSubsidiaries: this.getSubsidiaries,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

export default withTranslation()(GlobalContextProvider);
