import { ActivityStatuses } from "types/models/activity";
import { CustomFieldType, Project, ProjectSettings, Service, ServiceSettings, Task } from "types/models/projects";
import { Location } from "types/models/location";
import {
  EmployeeListUserProfile,
  RequestUserProfile,
  SearchEmployeeUserProfile,
  UserProfile,
} from "types/models/userProfile";

export type GetActivitiesListRequestData = {
  page?: number;
  employeeUuid?: string;
  departmentUuid?: string;
  subsidiaryUuid?: string;
  teamUuid?: string;
  from: string;
  to: string;
  clientUuid?: string;
  projectUuid?: string;
  locationUuid?: string;
  status: string; // , separated string of ActivityStatuses
  requestedBy: string;
  companyUuid: string;
  groupUuid?: string;
  taskUuid?: string;
};

export type ActivityCustomField = {
  uuid: string;
  name: string;
  value: string | null;
  required: boolean;
  active: boolean;
  deletedAt: string;
  fieldType: CustomFieldType;
};

export type Activity = {
  uuid: string;
  userProfileUuid: string;
  status: ActivityStatuses;
  date: string;
  task: Task;
  location: Location;
  project: Project;
  duration: number;
  startTime: number;
  endTime: number;
  locationUuid: string;
  startedAt?: string;
  endedAt?: string;
};

export type GetActivitiesListResponseData = {
  content: Activity[];
  pagingTotalCount: string;
};

export enum LocationVerificationStatus {
  skipped = "skipped",
  none = "none",
}

export type ActivityCustomFieldsInCreatePayload = {
  uuid: string;
  value: string;
};

export type ActivityInCreatePayload = {
  date: string;
  startTime: number;
  endTime: number;
  locationUuid: string;
  locationVerification?: {
    status: LocationVerificationStatus;
  };
  taskUuid: string;
  projectUuid: string;
  userProfileUuid: string;
  customFields?: ActivityCustomFieldsInCreatePayload[];
  uuid?: string;
  status?: ActivityStatuses;
};

export type CreateActivitiesRequestData = {
  companyUuid: string;
  body: {
    content: {
      activities: ActivityInCreatePayload[];
      createdBy: string;
    };
  };
};

export type GetTasksListForUserProfileRequestData = {
  companyUuid: string;
  requestedBy: string;
  locationUuid?: string;
  userProfileUuid: string;
};

export type UserProfileTask = {
  project: {
    customFieldsSettings: ActivityCustomField[] | null;
    locationUuids: string[];
  };
  projectUuid: string;
  projectName: string;
  name: string;
  uuid: string;
};

export type GetTasksListForUserProfileResponseData = {
  content: UserProfileTask[];
};

export type TaskUpdateRequestData = {
  companyUuid: string;
  projectUuid: string;
  taskUuid: string;
  body: {
    content: {
      name: string;
      serviceUuids: string[];
      updatedBy: string;
    };
  };
};

export type CreateServiceRequestDataContent = {
  name: string;
  costPerHour: number;
  createdBy?: string;
  updatedBy?: string;
  /** YYYY-MM-DD */
  startDate?: string;
};

export type CreateServiceRequestData = {
  companyUuid: string;
  serviceUuid?: string;
  body: {
    content: CreateServiceRequestDataContent;
  };
};

export type CreateTaskRequestDataContent = {
  name: string;
  serviceUuids: string[];
  createdBy: string;
};

export type CreateTaskRequestData = {
  companyUuid: string;
  projectUuid: string;

  body: {
    content: CreateTaskRequestDataContent;
  };
};

export type ProjectAssigneesRequestData = {
  companyUuid: string;
  projectUuid: string;
  requestedBy: string;
};

export type ProjectAssigneesResponseData = {
  services: Service[];
  userProfiles: RequestUserProfile[];
};

export type BulkAssignEmployeesToProjectRequestData = {
  projectUuid: string;
  companyUuid: string;
  body: {
    content: {
      requestedBy: string;
      userProfilesUuids: string[];
      servicesUuids: string[];
    };
  };
};

export type GetCompanyRulesForProjectsRequestData = {
  companyUuid: string;
};

export type CompanyTimesheetSettings = {
  timesheetWorkingHoursLock: boolean;
  timesheetDailyLock: boolean;
  stopActivitiesWithoutTaskBySchedule: boolean;
  restrictActivitiesBasedOnSchedule: boolean;
  restrictActivitiesBasedOnPunchIn: boolean;
  suggestStartActivityBasedOnPunchIn: boolean;
  allowFutureActivities: boolean;
  enabled: boolean;
};

export type GetCompanyRulesForProjectsResponseData = {
  content: CompanyTimesheetSettings & {
    companyUuid: string;
    timesheetWorkingHoursLock: boolean;
    stopActivitiesWithoutTaskBySchedule: boolean;
    restrictActivitiesBasedOnSchedule: boolean;
    restrictActivitiesBasedOnPunchIn: boolean;
    suggestStartActivityBasedOnPunchIn: boolean;
    allowFutureActivities: boolean;
    timesheetDailyLock: boolean;
  };
};

export type GetServiceProviderSettingsRequestData = {
  companyUuid: string;
  userProfileUuid: string;
  requestedBy: string;
};

export type GetServiceProviderSettingsResponseData = {
  content: {
    enabled: boolean;
    projects: ProjectSettings[];
    services: ServiceSettings[];
  };
};

export type TaskUpdateTaskAssigneesRequestData = {
  body: {
    content: {
      addAssignees: {
        userProfilesUuids: [];
        servicesUuids: [];
      };
      removeAssignees: {
        userProfilesUuids: [];
        servicesUuids: [];
      };
      requestedBy: string;
    };
  };
  taskUuid: string;
  companyUuid: string;
};

export type ProjectTaskAssignee = {
  title: string;
  subtitle: string;
  avatarId?: string | null;
  isServiceGroup: boolean;
  uuid: string;
};

export type RemoveAssigneeFromProjectRequestData = {
  projectUuid: string;
  companyUuid: string;
  body: {
    content: {
      requestedBy: string;
      userProfileUuid?: string;
      serviceUuid?: string;
    };
  };
};

export type SearchEmployeesAndServicesRequestData = {
  companyUuid: string;
  args: {
    term: string;
    requestedBy: string;
  };
};

export type SearchEmployeesAndServicesResponseData = {
  employees: SearchEmployeeUserProfile[];
  services: Service[];
};
